import { shallow } from 'enzyme';
import React from 'react';
import { vi } from 'vitest';

import { Card } from './CardLeftPanel';

vi.mock('redux-form', async (importOriginal) => {
  const mod = await importOriginal();

  return {
    ...mod,
    reduxForm: vi.fn(() => (Component) => Component),
  };
});

vi.mock('react-redux', () => ({
  connect: vi.fn(
    (mapStateToProps, mapDispatchToProps) => (Component) => (props) =>
      <Component {...mapStateToProps} {...mapDispatchToProps} {...props} />,
  ),
}));

describe('🐛 spec CardLeftPanel', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)

    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(<Card />);

    //❓ Assert
    expect(wrapper.text()).toBe('<WithShowControls(CardLeftPanel) />');
  });
});
