import { GRBS_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/GrbsId';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { GrbsIdRFWithReduxForm } from './GrbsIdRFWithReduxForm';

/* eslint-disable */
/**
 * Select React Form. Учредитель/ГРБС использует словарь grbs.
 *
 * @param {object} params - Parameters.
 * @param {string} params.name - Name of the input field.
 * @param {object} params.otherProps - Пропсы.
 * @returns {JSX.Element} - JSX.
 * @example ---
 */
export const GrbsIdRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={GRBS_ID_NAME}
      component={GrbsIdRFWithReduxForm}
    />
  );
};

GrbsIdRF.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
};
GrbsIdRF.defaultProps = {
  disabled: false,
};
