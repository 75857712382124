import PropTypes from 'prop-types';
/* eslint-disable  */
import React from 'react';

/**
 *
 */
export default class SummaryCell extends React.Component {
  static propTypes = {
    column: PropTypes.object,
  };

  /**
   *
   */
  render() {
    const { column, ...other } = this.props;
    let summary = column.summary || '';
    return <cell {...other}>{summary}</cell>;
  }
}
