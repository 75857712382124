import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { print } from 'app/api/requests/print';
import { getVersionsTable } from 'app/pages/cardsOgh/components/tables/Versions/api';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { useParamsTable } from 'core/uiKit/components/tables/Table/useParamsTable';
import { preparationValues } from 'core/utils/helpers/preparationValues';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';
import { useForm, useFormState } from 'react-final-form';

import { useFilterQueryParamsVersionDefault } from '../Filters/useFilterQueryParamsVersionDefault';

/**
 * Хук useTableVersion.
 *
 * @returns {{isLoading: boolean, submitSearch: Function, table: Array, resetTable: Function}} - Возвращает данные таблицы флаг и функции управления.
 * @example useTableVersion()
 */
export const useTableVersion = () => {
  const { rootId, typeId } = useGetCurrentCard();
  const { values, initialValues } = useFormState();
  const { initialize } = useForm();
  const { prepareFilters, setFilters } = useFilterQueryParamsVersionDefault();
  const { queryTable } = useParamsTable();
  const { editMode } = useEditMode();

  const dataQueryTable = {
    dateFrom: prepareFilters?.dateFromVersionTable,
    dateTo: prepareFilters?.dateToVersionTable,
    rootId,
    stateId: prepareFilters?.stateIdVersionsTable,
    typeId,
    ...queryTable,
  };

  const {
    data: { data: table = [] } = {},
    remove,
    isLoading,
  } = useQueryAdaptor(
    ['getVersionsTankTable', dataQueryTable],
    getVersionsTable,
    {
      enabled: !!rootId,
    },
  );

  /**
   * Функция сброса таблицы.
   *
   * @returns {void} - Nothing.
   * @example
   * const {submitSearch} = useTableVersion(); submitSearch()
   */
  const resetTable = () => {
    const { dateFromVersionTable, dateToVersionTable, stateIdVersionsTable } =
      initialValues;

    initialize({
      ...values,
      dateFromVersionTable,
      dateToVersionTable,
      stateIdVersionsTable,
    });
    setFilters({ edit: Number(editMode) || undefined }, 'replace');
    remove();
  };

  /**
   * Функция сабмита таблицы.
   *
   * @returns {void} - Nothing.
   * @example ----
   */
  const submitSearch = () => {
    const { dateFromVersionTable, dateToVersionTable, stateIdVersionsTable } =
      values;
    setFilters(
      {
        dateFromVersionTable,
        dateToVersionTable,
        edit: Number(editMode) || undefined,
        stateIdVersionsTable,
      },
      'push',
    );
  };

  /**
   * Печать версии.
   *
   * @returns {void}
   */
  const printVersions = () => {
    const { dateFromVersionTable, dateToVersionTable, stateIdVersionsTable } =
      values;

    return print(
      'different_list',
      preparationValues({
        dateFromVersionTable,
        dateToVersionTable,
        edit: Number(editMode) || undefined,
        stateIdVersionsTable,
      }),
    );
  };

  return {
    isLoading,
    printVersions,
    resetTable,
    submitSearch,
    table,
  };
};
