import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { parseDate } from 'app/components/card/common/parse';
import { useGetInitialValuesTablesDocuments } from 'app/pages/cardsOgh/components/tables/Documents/hooks/useGetInitialValuesTables';
import { useFilterQueryParamsVersionDefault } from 'app/pages/cardsOgh/components/tables/Versions/Filters/useFilterQueryParamsVersionDefault';
import { NO_CALC_NAME } from 'core/form/finalForm/fields/prepared/checkboxes/NoCalc.FF';
import { useMemo } from 'react';

/**
 * Хук установки изначального состояния полей для Элементов сопряжения поверхностей.
 *
 * @returns {object} - Возвращаем объект полей по умолчанию.
 * @example useInitialValues()
 */
export const useInitialValueAbutment = () => {
  const { startDate, endDate, attribute } = useGetCurrentCard();

  const objTables = useGetInitialValuesTablesDocuments();
  const { filters: filtersVersionTable } = useFilterQueryParamsVersionDefault();
  return useMemo(() => {
    return {
      [NO_CALC_NAME]: attribute?.no_calc,
      abutment_type_id: attribute?.abutment_type_id?.abutment_type,
      distance: attribute?.distance,
      endDate: parseDate(endDate),
      is_diff_height_mark: attribute?.is_diff_height_mark,
      material_id: attribute?.material_id?.material,
      startDate: parseDate(startDate),
      ...objTables,
      ...filtersVersionTable,
    };

    // eslint-disable-next-line
  }, [attribute, startDate, endDate]);
};
