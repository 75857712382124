import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { MultiSelect } from 'core/uiKit/inputs/selects/MultiSelect';
import React from 'react';

/**
 * @typedef {"sendReason"} SEND_REASON_NAME
 */
export const SEND_REASON_NAME = 'sendReason';

/**
 * @typedef {"reason"} REASON_NAME
 */
export const REASON_NAME = 'reason';

/**
 * ## Причина.
 *
 * @param {*} props - Properties.
 * @returns {JSX.Element}
 */
export const SendReason = (props) => {
  const dict = 'reason';
  const { data = [] } = useGetDictionary(dict);

  const { filter = (_) => _ } = props;

  const options = filter(data);
  return (
    <MultiSelect
      label={'Причина'}
      name={SEND_REASON_NAME}
      {...props}
      options={options}
    />
  );
};
