import {
  ENGINEER_BUILDING,
  VERTICAL_LANDSCAPING,
  WATER_BUILDING,
} from 'app/constants/oghTypes';
import { IS_DIFF_HEIGHT_MARK_NAME } from 'core/form/reduxForm/fields/prepared/checkboxes/IsDiffHeightMark.RF';

const attributesCard = {
  [ENGINEER_BUILDING]: [IS_DIFF_HEIGHT_MARK_NAME],
  [VERTICAL_LANDSCAPING]: [IS_DIFF_HEIGHT_MARK_NAME],
  [WATER_BUILDING]: [IS_DIFF_HEIGHT_MARK_NAME],
};

/**
 * Функция получения аттрибутов для проверки пересечения геометрии.
 *
 * @param {number} typeId - Тип ОГХ.
 * @param {object} formValues - Значения формы.
 * @returns {object|null}
 */
export const getAttributeForm = (typeId, formValues) => {
  return attributesCard[typeId]
    ? attributesCard[typeId].reduce((obj, item) => {
        return { ...obj, [item]: formValues[item] ? 1 : 0 };
      }, {})
    : null;
};
