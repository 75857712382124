import __get from 'lodash/get';

/**
 * GetParseAttribute.
 *
 * @param {*} props - The properties.
 * @returns {function(*): unknown}
 */
export const getParseAttribute = (props) => (source) => {
  // todo: переделать на свой гет
  return __get(props, `card.attribute.${source}`);
};
