import { TextField as TextFieldD } from './TextField';

export * from './CheckBox';
export * from './datePickers';
export * from './FileSignatureCriptoPro';
export * from './fileUpload';
export * from './NumberField';
export * from './TextField';

/**
 * @deprecated
 * @see core/uiKit/inputs/TextField
 */
const TextField = TextFieldD;

export { TextField };
