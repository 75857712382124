/* eslint-disable  */
import { useGetVisibilityButtonsOgh } from 'app/api/hooks';
import { Button } from 'core/uiKit/components/buttons/Button';
import { Icon } from 'core/uiKit/material-ui';
import PropTypes from 'prop-types';

/**
 * Кнопка "На согласование границ" Огх.
 *
 * ```js
 import SendMatchingBoundariesOghButton from 'core/uiKit/components/buttons/SendMatchingBoundariesOgh.Button';
 * ```
 * или
 *
 * ```js
 import  { SendMatchingBoundariesOghButton } from 'cit';
 * ```
 */

/**
 *
 * @param root0
 * @param root0.objectId
 */
export const SendMatchingBoundariesOghButton = ({ objectId, ...props }) => {
  const {
    data: { send_matching_boundaries: sendMatchingBoundaries = false } = {},
  } = useGetVisibilityButtonsOgh(objectId);

  return sendMatchingBoundaries ? (
    <Button
      {...props}
      color={'success'}
      variant={'contained'}
      startIcon={<Icon>ios_share</Icon>}
    >
      На согласование границ
    </Button>
  ) : null;
};

export default SendMatchingBoundariesOghButton;

SendMatchingBoundariesOghButton.propTypes = {
  /**
   * Id версии ОГХ.
   */
  objectId: PropTypes.number.isRequired,
};
