/* eslint-disable  */
import getFormValues from 'app/selectors/form/getFormValues';
import toArray from 'app/utils/toArray';
import { createSelector } from 'reselect';

import getDict from '../getDict';

const getOoptMeaningId = (state) => getFormValues(state).oopt_meaning_id;

export default createSelector(
  [getDict('ooptCategories'), getOoptMeaningId],
  (dictCategories, meaningId) => {
    let listCategories = toArray(dictCategories);
    let result;
    if (meaningId) {
      result = listCategories.filter((item) =>
        item.oopt_meaning_list.find((i) => i.oopt_meaning_id === meaningId),
      );
    } else {
      result = listCategories;
    }
    return result;
  },
);
