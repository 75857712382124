import { usePreparationAbutmentTypeNameFF } from 'core/form/finalForm/fields/prepared/selects/simple/AbutmentTypeName.FF/usePreparation.AbutmentTypeName.FF';
import { withFinalForm } from 'core/form/finalForm/helpers/withFinalForm';
import {
  ABUTMENT_TYPE_ID_NAME,
  AbutmentTypeName,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/AbutmentTypeName';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * Select FF __Тип элемента сопряжения__.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const AbutmentTypeNameFF = (props) => {
  usePreparationAbutmentTypeNameFF();
  return (
    <Field
      {...props}
      name={ABUTMENT_TYPE_ID_NAME}
      component={withFinalForm(AbutmentTypeName)}
    />
  );
};
