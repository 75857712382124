import { Button } from 'core/uiKit/components';
import React from 'react';

import { DialogApproveButton } from './DIalogSignActOfCompleted';
import { useSetupSignActOfCompletedButton } from './useSetup.SignActOfCompleted.Button';

/**
 * Компонент кнопки __Подписать ЭП__.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const SignActOfCompletedButton = (props) => {
  const { hidden, open, setOpen } = useSetupSignActOfCompletedButton();

  return (
    <>
      {open ? <DialogApproveButton setOpen={setOpen} /> : null}
      <Button
        hidden={hidden}
        color={'primary'}
        variant={'contained'}
        onClick={() => setOpen(true)}
        {...props}
      >
        Подписать ЭП
      </Button>
    </>
  );
};
