import {
  DrawerShapeOnChangeEvent,
  GenericCoordinates,
  GeometryTypes,
} from '../../../types';
import Vertex from '../Vertex';

/**
 * Вершина Маркера. На карте отображается точкой.
 * Указывает конкретную точку на карте, где находится маркер.
 */
export default class MarkerVertex extends Vertex {

  /**
   * Обработчик перемещения вершины.
   *
   * @param {DrawerShapeOnChangeEvent} ev - Аргументы.
   * @param ev.geometry - Вся геометрия.
   * @param ev.coordinates - Новые координаты вершины.
   * @returns {GenericCoordinates<GeometryTypes.Polyline> | GenericCoordinates<GeometryTypes.Polygon>}
   */
  public onChange({ coordinates }: DrawerShapeOnChangeEvent) {
    this.shape.updateCoordinates({ lngLat: coordinates });

    return coordinates;
  }
}
