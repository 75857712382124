import './style.scss';

import Link from 'app/components/common/misc/Link';
import { useFetchNotifications } from 'app/components/common/NavBar/components/NotificationIcon/useFetchNotifications';
import dateFormatService from 'core/services/dateFormatService';
import { Badge } from 'core/uiKit/material-ui';
import queryString from 'query-string';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Icon } from './Icon';

const style = {
  cursor: 'pointer',
  display: 'inline-block',
};

/**
 * NotificationIcon.
 *
 * @returns {JSX.Element|null}
 */
const NotificationIcon = () => {
  const navigate = useNavigate();

  const { delayRequestCount, allRequestCount } = useFetchNotifications();

  return allRequestCount ? (
    <Link
      style={style}
      onClick={() => {
        const data = {
          dateFrom: dateFormatService.format(new Date(), 'DD.MM.YYYY HH:00'),
          technicRequestCode: 'technic_req_processed',
        };
        const result = queryString.stringify(data);

        navigate(`/r/tech/matching?${result}`);
      }}
    >
      <div className="notification-container">
        <Badge badgeContent={delayRequestCount} color="error">
          <Icon />
        </Badge>

        <div className="notification-hint">
          <ul className="notification-hint__content">
            <li className="notification-hint__item">
              Просроченные заявки на согласование: {delayRequestCount}
            </li>
            <li className="notification-hint__item">
              Всего ожидают согласования: {allRequestCount}
            </li>
          </ul>
        </div>
      </div>
    </Link>
  ) : null;
};

export default NotificationIcon;
