/* eslint-disable */
import React from 'react';
import { StringParam } from 'use-query-params';

import { TextFieldFF } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
export const NAME_NAME = 'name';

 
export const nameParams = {
  [NAME_NAME]: StringParam,
};

 
/* eslint-disable */
/**
 * Наименование Name
 * @param label
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
 
export const NameFilter = ({ label = 'Наименование', ...props }) => {
  return (
    <TextFieldFF
      {...props}
      className={filterInput}
      label={label}
      name={NAME_NAME}
    />
  );
};
