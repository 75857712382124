import { useFetchOghTable } from 'app/pages/registry/ogh/api';
import { useParamsTable } from 'core/uiKit/components/tables/Table';

import { useFilterQueryParamsLittleForm } from './Filter.LittleForm';

/**
 * Хук таблцицы __Малые архитектурные формы и элементы благоустройства__.
 *
 * @param {object} props - Property.
 * @param {number} props.typeId - Type id.
 * @param {number} props.parentTypeId - Parent Type id.
 * @returns {{count: number, isLoading: boolean, table: Array}}
 */
export const useTableLittleForm = ({ typeId, parentTypeId }) => {
  const { prepareFilters } = useFilterQueryParamsLittleForm();
  const { queryTable } = useParamsTable();

  const queryKey = [
    'TableLittleForm',
    {
      ...queryTable,
      ...prepareFilters,
      parentTypeId,
      typeId,
    },
  ];

  const { table, isLoading, count } = useFetchOghTable(queryKey);

  return {
    count,
    isLoading,
    table,
  };
};
