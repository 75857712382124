/* eslint-disable */
import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import { OghTypeSelect, OGH_TYPE_SELECT_NAME } from 'core/uiKit/preparedInputs';
 
export { OGH_TYPE_SELECT_NAME };

 
 
/**
 * ## Тип
 *
 * ### React Final Form Field
 * ```js
 * import { OghTypeSelectFF, OGH_TYPE_SELECT_NAME } from 'core/form/finalForm/fields';
 * ```
 *
 * * `type = Select`
 * * `name = OGH_TYPE_SELECT_NAME`
 */
/* eslint-disable */
export const OghTypeSelectFF = ({ name, ...otherProps }) => {
  return (
    <Field name={OGH_TYPE_SELECT_NAME}>
      {(props) => {
        return <OghTypeSelect {...props} {...props.input} {...otherProps} />;
      }}
    </Field>
  );
};

OghTypeSelectFF.propTypes = {
   
   
/**
   * выбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * функция для фильтрации options
   */
  filter: PropTypes.func,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
/**
   * placeholder
   */
  placeholder: PropTypes.string,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
};

OghTypeSelectFF.defaultProps = {
  dark: false,
  disabled: false,
};
