import { useGetFilesForSignature } from 'app/api/hooks/signs/useGetFilesForSignature';
import { useSignOghSave } from 'app/api/hooks/signs/useSignOghSave';
import { UPDATE } from 'app/api/requests/signs/signOghSave';
import { ContinueFFDialog } from 'core/form/finalForm/components/dialogs/ContinueFF.Dialog';
import { RANDOM_BOOL, RANDOM_ID } from 'core/forTesting/constants';
import { Dialog } from 'core/uiKit/components/dialogs/Dialog';
import { LoaderCreateFiles } from 'core/uiKit/components/loaders/LoaderCreateFiles';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import { shallow } from 'enzyme';

import { DialogSigningOnRenewalOfApprovalDate } from './Dialog.SigningOnRenewalOfApprovalDate';
import { FormSigningOnRenewalOfApprovalDate } from './Form.SigningOnRenewalOfApprovalDate';
import { formValidationDialogSigningOnRenewalOfApprovalDate } from './formValidation.DialogSigningOnRenewalOfApprovalDate';

jest.mock('core/utils/hooks/useGetObjectId');
jest.mock('core/uiKit/components/dialogs/Dialog');
jest.mock('core/form/finalForm/utils/validate');
jest.mock('core/uiKit/components/loaders/LoaderCreateFiles');
jest.mock('core/form/finalForm/components/dialogs/ContinueFF.Dialog');
jest.mock('app/api/hooks/signs/useGetFilesForSignature');
jest.mock('app/api/hooks/signs/useSignOghSave');
jest.mock('./Form.SigningOnRenewalOfApprovalDate');

describe('file Dialog.DialogSigningOnRenewalOfApprovalDate', () => {
  it('simple text', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    useGetFilesForSignature.mockReturnValue({
      data: [RANDOM_ID],
    });
    useSignOghSave.mockReturnValue({ data: [RANDOM_ID] });
    const onCloseSpy = jest.fn();
    // Act
    const wrapper = shallow(
      <DialogSigningOnRenewalOfApprovalDate onClose={onCloseSpy} />,
    );

    // Assert
    expect(wrapper.text()).toBe('<Dialog /><ContinueFFDialog />');
  });

  it('useGetFilesForSignature was called with', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    useGetFilesForSignature.mockReturnValue({
      data: [RANDOM_ID],
      isLoading: false,
    });
    useSignOghSave.mockReturnValue({});
    const onCloseSpy = jest.fn();
    // Act
    shallow(<DialogSigningOnRenewalOfApprovalDate onClose={onCloseSpy} />);

    // Assert
    expect(useGetFilesForSignature).toHaveBeenCalledWith(RANDOM_ID, UPDATE, {
      onErrorCallback: onCloseSpy,
    });
  });

  it('useSignOghSave was called with', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const objectId = RANDOM_ID + 42;
    useGetObjectId.mockReturnValue({ objectId });
    useGetFilesForSignature.mockReturnValue({
      data: [RANDOM_ID],
    });
    useSignOghSave.mockReturnValue({});
    const onCloseSpy = jest.fn();
    // Act
    shallow(<DialogSigningOnRenewalOfApprovalDate onClose={onCloseSpy} />);

    // Assert
    expect(useSignOghSave).toHaveBeenCalledWith({
      objectCardFiles: [RANDOM_ID],
      objectId,
      onErrorCallback: onCloseSpy,
      onSuccessCallback: onCloseSpy,
      signType: UPDATE,
    });
  });

  it('props ContinueFFDialog isOpen true', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const objectId = RANDOM_ID + 42;
    useGetObjectId.mockReturnValue({ objectId });
    useGetFilesForSignature.mockReturnValue({
      data: [{ file: RANDOM_ID }],
    });
    const sendSignSpy = jest.fn();
    useSignOghSave.mockReturnValue({
      isLoadingSignOghSave: RANDOM_BOOL,
      sendSign: sendSignSpy,
    });
    const onCloseSpy = jest.fn();

    // Act
    const wrapper = shallow(
      <DialogSigningOnRenewalOfApprovalDate onClose={onCloseSpy} />,
    );

    // Assert
    expect(wrapper.find(ContinueFFDialog).props()).toStrictEqual({
      Body: <FormSigningOnRenewalOfApprovalDate />,
      closeDialog: onCloseSpy,
      isLoading: RANDOM_BOOL,
      isOpen: true,
      onSubmit: sendSignSpy,
      textHeader: 'Форма подписания файлов',
      validate: formValidationDialogSigningOnRenewalOfApprovalDate,
    });
  });
  it('props ContinueFFDialog isOpen false', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const objectId = RANDOM_ID + 42;
    useGetObjectId.mockReturnValue({ objectId });
    useGetFilesForSignature.mockReturnValue({});
    const sendSignSpy = jest.fn();
    useSignOghSave.mockReturnValue({
      isLoadingSignOghSave: RANDOM_BOOL,
      sendSign: sendSignSpy,
    });
    const onCloseSpy = jest.fn();

    // Act
    const wrapper = shallow(
      <DialogSigningOnRenewalOfApprovalDate onClose={onCloseSpy} />,
    );

    // Assert
    expect(wrapper.find(ContinueFFDialog).props()).toStrictEqual({
      Body: <FormSigningOnRenewalOfApprovalDate />,
      closeDialog: onCloseSpy,
      isLoading: RANDOM_BOOL,
      isOpen: false,
      onSubmit: sendSignSpy,
      textHeader: 'Форма подписания файлов',
      validate: formValidationDialogSigningOnRenewalOfApprovalDate,
    });
  });

  it('props Dialog isOpen false', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const objectId = RANDOM_ID + 42;
    useGetObjectId.mockReturnValue({ objectId });
    useGetFilesForSignature.mockReturnValue({
      data: [{ file: RANDOM_ID }],
    });
    const sendSignSpy = jest.fn();
    useSignOghSave.mockReturnValue({
      isLoadingSignOghSave: RANDOM_BOOL,
      sendSign: sendSignSpy,
    });
    const onCloseSpy = jest.fn();

    // Act
    const wrapper = shallow(
      <DialogSigningOnRenewalOfApprovalDate onClose={onCloseSpy} />,
    );

    // Assert
    expect(wrapper.find(Dialog).props()).toStrictEqual({
      Body: <LoaderCreateFiles />,
      isOpen: false,
      maxWidth: 'xs',
      textHeader: 'Формируются файлы для подписания',
    });
  });

  it('props Dialog isOpen true', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const objectId = RANDOM_ID + 42;
    useGetObjectId.mockReturnValue({ objectId });
    useGetFilesForSignature.mockReturnValue({ isLoading: true });
    const sendSignSpy = jest.fn();
    useSignOghSave.mockReturnValue({
      isLoadingSignOghSave: RANDOM_BOOL,
      sendSign: sendSignSpy,
    });
    const onCloseSpy = jest.fn();

    // Act
    const wrapper = shallow(
      <DialogSigningOnRenewalOfApprovalDate onClose={onCloseSpy} />,
    );

    // Assert
    expect(wrapper.find(Dialog).props()).toStrictEqual({
      Body: <LoaderCreateFiles />,
      isOpen: true,
      maxWidth: 'xs',
      textHeader: 'Формируются файлы для подписания',
    });
  });
});
