import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { DXF_EXPORT_API_URL } from 'config/env';
import { MenuItem } from 'core/uiKit/components/Menu/MenuItem';
import React from 'react';

/**
 * MenuItem __Сформировать в .dxf__.
 *
 * @returns {JSX.Element}
 */
export const ToFormDxfMenuItem = () => {
  const { recordId } = useGetCurrentCard();

  return (
    <MenuItem disabled={false}>
      <a href={`${DXF_EXPORT_API_URL}/${recordId}`}>Сформировать в .dxf</a>
    </MenuItem>
  );
};
