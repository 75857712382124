/* eslint-disable */
import React from 'react';
import { BooleanParam } from 'use-query-params';

import { CheckboxFF } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
export const IS_NOT_INSTALLED_NAME = 'isNotInstalled';

 
export const IsNotInstalledFilter = (props) => (
  <CheckboxFF
    {...props}
    className={filterInput}
    label={'Не установлено на технику'}
    name={IS_NOT_INSTALLED_NAME}
  />
);

 
export const isNotInstalledParams = {
  [IS_NOT_INSTALLED_NAME]: BooleanParam,
};
