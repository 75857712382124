/* eslint-disable */
import { shallow } from 'enzyme';
import { BodyDialogAccept } from './Body.Dialog.Accept';
/* eslint-disable */
describe('file Body.Dialog.Reject.spec', () => {
  it('simple test', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const wrapper = shallow(<BodyDialogAccept />);

    // Assert
    expect(wrapper.text()).toBe(
      'Уведомление будет отправлено пользователю, создавшему объект<CommentFF /><DndFileUploadFF />',
    );
  });

  it('props DndFileUploadFF', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const wrapper = shallow(<BodyDialogAccept />);
    const component = wrapper.find('DndFileUploadFF');

    // Assert
    expect(component.props()).toMatchObject({
      text: 'Переместите файлы или нажмите для выбора',
      name: 'files',
    });
  });
});
