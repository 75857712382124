/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import getDisplayName from 'core/hocs/getDisplayName';

const propTypes = {
  className: PropTypes.string,
};

function buttonAmber(Component) {
  class ButtonAmber extends React.Component {
    render() {
      const { className, ...otherProps } = this.props;
      return (
        <Component className={cn(className, 'Button_Amber')} {...otherProps} />
      );
    }
  }

  ButtonAmber.propTypes = propTypes;
  ButtonAmber.displayName = `buttonAmber(${getDisplayName(Component)})`;

  return ButtonAmber;
}

 
export default buttonAmber;
