/* eslint-disable */

import React from 'react';

import { _AutocompleteAsync as AutocompleteJs } from './_AutocompleteAsync';
import { AutocompleteAsyncProps, CommonParams } from './types';

/**
 * Обертка тс для Autocomplete.
 *
 * @param props - Properties.
 * @returns {JSX.Element}
 */
export const AutocompleteAsync = <T extends CommonParams>(
  props: AutocompleteAsyncProps<T>,
) => {
  const propsA: AutocompleteAsyncProps<
    Parameters<typeof props.fetchFunction>[0]
  > = {
    ...props,
    additionalDataForRequest: props.additionalDataForRequest,
    fetchFunction: props.fetchFunction,
  };

  return <AutocompleteJs {...propsA} />;
};
