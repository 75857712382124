import { initialValuesImprovementObject } from './initialValues.ImprovementObject';

describe('🐛 spec initialValues.ImprovementObject.spec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)

    // 🧹 clear mock

    //🔥 Act
    const res = initialValuesImprovementObject(
      { initialValues: { initial: 'initial' }, result: 'result' },
      {},
      {
        card: {
          attribute: {
            create_type: 'create_type',
            reservoir_area: 'reservoir_area',
          },
          repairs_info_list: 'repairs_info_list',
          repairs_info_list_plan: 'repairs_info_list_plan',
        },
      },
    );

    //❓ Assert
    expect(res).toStrictEqual({
      initialValues: {
        createType: 'create_type',
        initial: 'initial',
        repairs_info_list: 'repairs_info_list',
        repairs_info_list_plan: 'repairs_info_list_plan',
        reservoir_area: 'reservoir_area',
      },
      result: 'result',
    });
  });
});
