import { Validator } from 'core/form/Validator';

/**
 * Валидация добавления файлов в таблицу документов.
 *
 * @param {object} values - Значения формы.
 * @param {Array<string>} filesFormat - Разрешенные файлы.
 * @returns {Record<string, string> | null}
 */
export const validate = (values, filesFormat) => {
  const validator = new Validator(values);
  validator.setRequired('files');
  validator.setFilesValidate('files', {
    byExtensions: filesFormat,
    maxFiles: 1,
    maxGb: 1,
    maxMb: 1024,
  });

  if (values.comment?.length > 255) {
    validator.addError('comment', 'Можно ввести не более 255 символов');
  }

  return validator.getErrors();
};
