import IndexCard from './IndexCard';
import { useCheckingAccessSaveWithoutGeometry } from './useCheckingAccessSaveWithoutGeometry';

 
/**
 * Индексный file карточки.
 * Тут используем все хуки.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.card - Пропсы.
 * @param {object} props.parentInfo - Информация по родителю.
 * @returns {JSX.Element} - JSX.
 * @example
 * -------
 */
const Index = (props) => {
  const { card, parentInfo } = props;

  const allowedWithoutGeometry = useCheckingAccessSaveWithoutGeometry(
    card?.type_id,
    card?.parent_info?.type_id || parentInfo?.typeId,
  );

  return <IndexCard {...props} {...{ allowedWithoutGeometry }} />;
};

export default Index;
