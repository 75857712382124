import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import get from 'lodash/get';
import snakeCase from 'lodash/snakeCase';
import React from 'react';

export const OGH_TYPE_SELECT_NAME = 'typeId';

/**
 * ## Тип обьекта.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const OghTypeSelect = (props) => {
  const dict = 'oghTypes';

  const { value, code, id } = props;

  const codeSnakeCase = snakeCase(code);

  let newValue = value;
  const { data = [] } = useGetDictionary(dict);

  // что бы передавать сразу в пропсы  либо `code` либо `id`
  if (!value) {
    const valueOptions = data.find(
      (item) => item.id === id || item.code === codeSnakeCase,
    );
    newValue = get(valueOptions, 'id');
  }
  return (
    <Select
      label={'Тип'}
      name={OGH_TYPE_SELECT_NAME}
      withAll={false}
      {...props}
      options={data}
      value={newValue}
    />
  );
};
