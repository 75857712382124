/**
 * Получение значения из объекта по ключу.
 *
 * @param {object} hint - Объект значений.
 * @param {string} key - Ключ.
 * @returns {string}
 */
function getHintValue(hint, key) {
  const result = hint.find((item) => item.key === key);
  return result ? result.value : '';
}

/**
 * Функция получения данных для отрисовки сообщения.
 *
 * @param {Array<*>} geometries - Геометрия.
 * @returns {string}
 */
export function getIntersections(geometries) {
  return geometries.slice(0, 100000000000).map((item) => {
    const { object_attribute_hint } = item.hint;
    const title = getHintValue(object_attribute_hint, 'title');
    const id_ogh = getHintValue(object_attribute_hint, 'id_ogh');
    const name = getHintValue(object_attribute_hint, 'name');
    const owner_name = getHintValue(object_attribute_hint, 'owner_name');
    const createType = getHintValue(object_attribute_hint, 'create_type');
    const intersection_area_percent = getHintValue(
      object_attribute_hint,
      'intersection_area_percent',
    );
    const startDate = getHintValue(object_attribute_hint, 'start_date');
    const endDate = getHintValue(object_attribute_hint, 'end_date');

    return [
      title,
      id_ogh,
      name,
      owner_name,
      intersection_area_percent,
      createType,
      startDate,
      endDate,
    ]
      .filter(Boolean)
      .join(', ');
  });
}
