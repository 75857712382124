import {
  formatDate,
  parseDate,
} from 'app/components/card/ogh/BasePgmCard/utils/dateTime';
import getDict from 'app/selectors/getDict';
import { dateFromMaxDate } from 'app/utils/dateFromMaxDate';
import { dateToMinDate } from 'app/utils/dateToMinDate';
import Select from 'core/newComponents/Select';
import KeyboardDatePicker from 'core/uiKit/inputs/datePickers/KeyboardDatePicker';
import { get } from 'lodash';
import PropTypes from 'prop-types';
/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';

import DialogAction from '../../common/DialogAction';

const mandatoryFields = ['dateFrom', 'dateTo', 'reagentKind', 'reagent'];

const defaultDate = new Date();

const propTypes = {
  cardEndDate: PropTypes.string.isRequired,
  cardStartDate: PropTypes.string.isRequired,
  reagentKinds: PropTypes.array.isRequired,
  reagents: PropTypes.array.isRequired,
  showCondition: PropTypes.bool,
  title: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

const defaultProps = {
  title: 'Добавление графика перемещения реагентов',
};

class DialogAddExport extends React.Component {
  state = {
    dateFrom: defaultDate,
    dateTo: defaultDate,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.showCondition !== this.props.showCondition) {
      this.setState({
        dateFrom: defaultDate,
        dateTo: defaultDate,
        reagentKind: null,
        reagent: null,
      });
    }
  }

  onChange = (id) => (value) => {
    this.setState({
      [id]: value,
    });
  };

  onClickContinue = () => {
    const { dateTo, dateFrom } = this.state;
    const { baseNum, baseName, baseRecordId } = this.props;
    const data = {
      ...this.state,
      dateFrom: formatDate(dateFrom),
      dateTo: formatDate(dateTo),
      baseNum,
      baseName,
      baseRecordId,
    };

    this.props.onSubmit(data);
  };

  isDisabledContinueButton() {
    const allMandatoryFieldsFilled = mandatoryFields.reduce(
      (result, item) => result && !!this.state[item],
      true,
    );

    return !allMandatoryFieldsFilled;
  }

  renderDateFrom() {
    const { dateTo, dateFrom } = this.state;
    return (
      <KeyboardDatePicker
        label="Дата c"
        maxDate={dateFromMaxDate(dateFrom, dateTo)}
        minDate={parseDate(this.props.cardStartDate)}
        name={'dateFrom'}
        value={dateFrom}
        width="270"
        onChange={this.onChange('dateFrom')}
      />
    );
  }

  renderDateTo() {
    const { dateTo, dateFrom } = this.state;
    return (
      <KeyboardDatePicker
        label="Дата по"
        maxDate={parseDate(this.props.cardEndDate)}
        minDate={dateToMinDate(dateFrom)}
        name={'dateTo'}
        value={dateTo}
        width="270"
        onChange={this.onChange('dateTo')}
      />
    );
  }

  renderReagent() {
    const { reagents } = this.props;
    const { reagentKind: currentReagentKind } = this.state;
    const reagentsForReagentKind =
      currentReagentKind &&
      reagents.filter((item) => item.reagent_kind_id === currentReagentKind);

    return (
      <div style={{ width: 270 }}>
        <Select
          label={'Наименование ПГР'}
          name="reagent"
          options={reagentsForReagentKind || reagents}
          value={this.state.reagent || ''}
          width="270"
          onChange={(value) => {
            const selectedReagent = reagents.find((item) => item.id === value);
            const newReagentKindId =
              selectedReagent && selectedReagent.reagent_kind_id;

            this.onChange('reagentKind')(newReagentKindId);
            this.onChange('reagent')(value);
          }}
        />
      </div>
    );
  }

  renderReagentKind() {
    return (
      <Select
        label={'Вид ПГР'}
        name={'reagentKind'}
        options={this.props.reagentKinds}
        value={this.state.reagentKind || ''}
        width="270"
        onChange={this.onChange('reagentKind')}
      />
    );
  }

  render() {
    const { showCondition, title, onCancel } = this.props;

    return (
      <DialogAction
        className="Dialog-Add-Export"
        isDisabledOk={this.isDisabledContinueButton()}
        showCondition={showCondition}
        title={title}
        onBeforeOk={this.onClickContinue}
        onCancel={onCancel}
      >
        <Wrapper>
          {this.renderDateFrom()}
          {this.renderDateTo()}
        </Wrapper>
        <Wrapper>
          {this.renderReagentKind()}
          {this.renderReagent()}
        </Wrapper>
      </DialogAction>
    );
  }
}

DialogAddExport.propTypes = propTypes;
DialogAddExport.defaultProps = defaultProps;

function Wrapper({ children }) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 16,
      }}
    >
      {children}
    </div>
  );
}

const mapStateToProps = (state) => ({
  reagentKinds: getDict('reagentKinds')(state),
  reagents: getDict('reagents')(state),
  cardStartDate: get(state, 'card.object.start_date'),
  cardEndDate: get(state, 'card.object.end_date'),
  baseNum: get(state, 'card.object.attribute.base_num'),
  baseName: get(state, 'card.object.name'),
  baseRecordId: get(state, 'card.object.root_id'),
});

 
export { Wrapper };

 
export default connect(mapStateToProps)(DialogAddExport);
