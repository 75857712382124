import { Quantity } from 'core/uiKit/preparedInputs/textField/Quantity';
import React from 'react';

 
/**
 * Компонент обертка для Quantity.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.input - Значения инпута и метаданные.
 * @returns {JSX.Element} - QuantityReduxForm.
 * @example  <Field
 *       {...props}
 *       component={QuantityReduxForm}
 *       name={QUANTITY_NAME}
 *     />
 */
export const QuantityWithReduxForm = ({ input, ...props }) => {
  return <Quantity {...input} {...props} />;
};
