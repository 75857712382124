import { decodeDelimitedArray, encodeDelimitedArray } from 'use-query-params';
/* eslint-disable */
/**
 * Хук для работы с объектами в массиве.
 *
 * @param {Array} array
 */
export const objectInArrayParam = {
  /**
   *
   * @param arrayStr
   */
  decode: (arrayStr) => {
    const array = decodeDelimitedArray(arrayStr, '|');
    if (array && array.length) {
      return array.map((item) => JSON.parse(item));
    }
    return [];
    // return decodeDelimitedArray(arrayStr, '|').map((item) => JSON.parse(item));
  },

  /**
   *
   * @param array
   */
  encode: (array) => {
    return encodeDelimitedArray(
      array.map((item) => JSON.stringify(item)),
      '|',
    );
  },
};
