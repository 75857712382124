import { cellsMainMap } from 'core/uiKit/components/cells/cellsMainMap';

/**
 * @typedef {import('src/core/uiKit/components/tables/Table/Table').Columns} Columns
 */

/**
 * Получение Columns __Объекты озеленения__.
 *
 * @returns {Columns}
 */
export const getColumnsOzn = () => [
  cellsMainMap.short_root_id, // ID
  cellsMainMap.okrug_name, // Округ
  cellsMainMap.grbs_short_name, // ГРБС
  cellsMainMap.district_name, // Район
  cellsMainMap.object_name, // Наименование
  cellsMainMap.owner_name, // Балансодержатель
  cellsMainMap.department_name, // Отраслевой ОИВ
  cellsMainMap.improvement_object_cat_name, // Категория озеленения
  cellsMainMap.total_area, // Общая площадь, кв.м.
  cellsMainMap.total_clean_area, // Уборочная площадь, кв.м.
  cellsMainMap.ozn_type_name, // Тип объекта
  cellsMainMap.create_type, // Тип создания объекта
  cellsMainMap.object_status_name, // Статус
  cellsMainMap.passport_draft_org, // Исполнитель/Исполнители (по разработке актуализации паспорта)
  cellsMainMap.start_date, // Дата начала
  cellsMainMap.end_date, // Дата окончания
  cellsMainMap.greening_addition, // Требует дополнения по зелёным насаждениям
  cellsMainMap.reapprove_required, // Требует повторного утверждения
  cellsMainMap.update_required, // Требует актуализации
  cellsMainMap.sign_date, // Дата подписания
  cellsMainMap.sign_person, // ФИО подписанта
];
