 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Ширина в конце, м__.
 *
 * @type {{ accessor: 'width_end',  Header: 'Ширина в конце, м' }}
 * @augments Cell
 */
export const width_end = {
  accessor: 'width_end',
  Header: 'Ширина в конце, м',
  sortable: true,
};
