import { reducer as formReducer } from 'redux-form';

import * as types from '../constants/actionTypes';

/* eslint-disable */
export default formReducer.plugin({
  editorCard: (state, action) => {
    switch (action.type) {
      case types.VIEW_CARD:
        return state ? { ...state, values: { ...state.initial } } : state;
      case types.EDIT_CARD:
        return {
          ...state,
          values: {
            ...state.values,
            startDate: action.startDate,
            endDate: action.endDate,
          },
        };
      case types.SET_START_DATE: {
        return {
          ...state,
          values: { ...state.values, startDate: action.date },
        };
      }
      case types.CHANGE_TREE_JOURNAL: {
        return {
          ...state,
          values: {
            ...state.values,
            ...action.re_calc_attribute,
          },
        };
      }
      default:
        return state;
    }
  },
  editCardForm: (state, action) => {
    function setEndDateToState(endDate) {
      let result = null;
      if (endDate) {
        const date = endDate.split('.');
        result = new Date(date[2], date[1] - 1, date[0]);
      }
      return { ...state, values: { ...state.values, endDate: result } };
    }
    switch (action.type) {
      case types.VIEW_CARD:
        return state ? { ...state, values: { ...state.initial } } : state;
      case types.RECEIVE_END_DATE:
        return setEndDateToState(action.endDate);
      default:
        return state;
    }
  },
  deleteCard: (state) => {
    return state;
  },
  editCardDialogForm: (state, action) => {
    switch (action.type) {
      case types.EDIT_CARD_CLEAR_DIALOG:
        return undefined;
      default:
        return state;
    }
  },
  addTitlelist: (state, action) => {
    switch (action.type) {
      case types.REQUEST_CREATE_TITLELIST:
        return { ...state, error: undefined, done: false };
      case types.RECEIVE_CREATE_TITLELIST_ERROR:
        return { ...state, error: action.error };
      case types.RECEIVE_CREATE_TITLELIST:
        return {
          ...state,
          error: undefined,
          done: true,
          location: action.location,
        };
      default:
        return state;
    }
  },
});
