import './Panel.scss';

import { Dialogs } from 'app/pages/cardsOgh/components/DIalogs/Dialogs';
import { NeighboursButton } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/buttons/NeighboursButton';
import { ShowChildrenButton } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/buttons/ShowChildrenButton';
import { ShowDistrictBoundaries } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/buttons/ShowDistrictBoundaries/ShowDistrictBoundaries';

interface MapEditPanelProps {
  showAlert: (message: string) => void;
}
import { GeometryTypes } from 'core/uiKit/components/DTW/contexts/utils/types.d';
import React from 'react';

import { CheckGeometryButton } from './buttons/CheckGeometry.Button';
import DeleteSelectedButton from './buttons/DeleteSelectedButton';
import DownloadGeometryButton from './buttons/DownloadGeometryButton';
import DrawButton from './buttons/DrawButton';
import ReonButton from './buttons/ReonButton';
import { useSetupMapEditPanelDTW } from './useSetup.MapEditPanel.DTW';

/**
 * Панель редактирования карты.
 *
 * @param props - Пропсы.
 * @param props.showAlert - Функция показа диалогового окна.
 * @returns JSX.
 */
export const MapEditPanelDTW: React.FC<MapEditPanelProps> = ({ showAlert }) => {
  const {
    deleteGeometry,
    editable,
    getGeometry,
    lineAllowed,
    onDrawClick,
    pointAllowed,
    polygonAllowed,
    currentCard,
    context,
    activeGeometryType,
  } = useSetupMapEditPanelDTW();

  return (
    <div className="map-edit">
      <ShowDistrictBoundaries />
      <DownloadGeometryButton
        currentCard={currentCard}
        getGeometry={getGeometry}
        isMapLoaded={!!context?.mapService?.isMapLoaded}
      />
      <NeighboursButton />
      <ReonButton currentCard={currentCard} showAlert={showAlert} />
      <Dialogs>
        <ShowChildrenButton />
      </Dialogs>
      <CheckGeometryButton />
      <DrawButton
        active={activeGeometryType === GeometryTypes.Point}
        disabled={!editable || !pointAllowed}
        geometryType={GeometryTypes.Point}
        onClick={onDrawClick}
      />
      <DrawButton
        active={activeGeometryType === GeometryTypes.Polyline}
        disabled={!editable || !lineAllowed}
        geometryType={GeometryTypes.Polyline}
        onClick={onDrawClick}
      />
      <DrawButton
        active={activeGeometryType === GeometryTypes.Polygon}
        disabled={!editable || !polygonAllowed}
        geometryType={GeometryTypes.Polygon}
        onClick={onDrawClick}
      />
      <DrawButton
        active={activeGeometryType === GeometryTypes.Hole}
        disabled={!editable || !polygonAllowed}
        geometryType={GeometryTypes.Hole}
        onClick={onDrawClick}
      />
      <DeleteSelectedButton
        callMapFunction={deleteGeometry}
        currentCard={currentCard}
        disabled={!editable}
      />
    </div>
  );
};
