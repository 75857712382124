import * as api from 'app/api';
import * as types from 'app/constants/actionTypes';
import getSearchFormValues from 'app/utils/getSearchFormValues';
import _ from 'lodash';
import { actions as formActions } from 'react-redux-form';
import { useNavigate } from 'react-router-dom';
import { createAction } from 'redux-actions';

import * as loaderActions from './loader';

 
export const apiGetTemplateRegistry = createAction(
  types.API_GET_TEMPLATE_REGISTRY,
  function (data) {
    return {
      promise: api.getTemplateRegistry(data),
    };
  },
);

/* eslint-disable */
export function onSubmit(values) {
  return function (dispatch) {
    dispatch(loaderActions.show());
    dispatch(formActions.change('rrf.templateRegistry.page', 0));

    return dispatch(
      apiGetTemplateRegistry(getSearchFormValues(values)),
    ).finally(function () {
      dispatch(loaderActions.hide());
    });
  };
}

/* eslint-disable */
export function onReset() {
  return function (dispatch) {
    dispatch(formActions.change('rrf.templateRegistry.name', ''));
    dispatch(formActions.reset('rrf.templateRegistry.entity'));
    dispatch(formActions.change('rrf.templateRegistry.entityCode', ''));
    dispatch(formActions.change('rrf.templateRegistry.oghTypes', ''));
    dispatch(formActions.change('rrf.templateRegistry.changeTypes', ''));
    dispatch(formActions.change('rrf.templateRegistry.customer', ''));
    dispatch(formActions.change('rrf.templateRegistry.owner', ''));
    dispatch(formActions.change('rrf.templateRegistry.technicTypeId', ''));
    dispatch(formActions.change('rrf.templateRegistry.equipmentOwners', ''));
    dispatch(formActions.change('rrf.templateRegistry.carOwners', ''));
    dispatch(formActions.change('rrf.templateRegistry.providers', ''));
  };
}

/* eslint-disable */
export function fetchData() {
  return function (dispatch) {
    dispatch(formActions.reset('rrf.templateRegistryOut.rows'));
    return dispatch(formActions.submit('rrf.templateRegistry'));
  };
}

/* eslint-disable */
export function onSort(values) {
  return function (dispatch) {
    dispatch(formActions.merge('rrf.templateRegistry', values));
    return dispatch(fetchData());
  };
}

/* eslint-disable */
export function onLimit(value) {
  return function (dispatch) {
    dispatch(formActions.change('rrf.templateRegistry.maxRows', value));
    dispatch(formActions.change('rrf.templateRegistry.page', 0));
    return dispatch(fetchData());
  };
}

/* eslint-disable */
export function onPagination(value) {
  return function (dispatch) {
    dispatch(formActions.change('rrf.templateRegistry.page', value));
    return dispatch(fetchData());
  };
}

function getCheckedRows(state) {
  const rows = _.get(state, ['rrf', 'templateRegistryOut', 'rows']);
  const checkedRows = [];
  _.forEach(rows, function (value, key) {
    if (value) {
      checkedRows.push(key);
    }
  });
  return checkedRows;
}

/* eslint-disable */
export function editCheckedRows() {
  return function (dispatch, getState) {
    const navigate = useNavigate();
    const checkedRows = getCheckedRows(getState());
    if (!checkedRows.length) {
      return dispatch(
        formActions.change('rrf.templateRegistryOut.dialogs.editNull', true),
      );
    } else if (checkedRows.length === 1) {
      return dispatch(navigate(`/a/matching/${checkedRows[0]}`));
    } else {
      return dispatch(
        formActions.change('rrf.templateRegistryOut.dialogs.editMany', true),
      );
    }
  };
}

/* eslint-disable */
export function deleteCheckedRows() {
  return function (dispatch, getState) {
    const checkedRows = getCheckedRows(getState());
    if (!checkedRows.length) {
      return dispatch(
        formActions.change('rrf.templateRegistryOut.dialogs.deleteNull', true),
      );
    } else if (checkedRows.length === 1) {
      return dispatch(
        formActions.change('rrf.templateRegistryOut.dialogs.deleteOne', true),
      );
    } else {
      return dispatch(
        formActions.change('rrf.templateRegistryOut.dialogs.deleteMany', true),
      );
    }
  };
}

 
export const apiDeleteTemplate = createAction(
  types.API_DELETE_TEMPLATE,
  function (data) {
    return {
      promise: api.deleteTemplate(data),
    };
  },
);

/* eslint-disable */
export function closeDialogs() {
  return formActions.change('rrf.templateRegistryOut.dialogs', {});
}

/* eslint-disable */
export function deleteTemplateAndCloseDialogsAndFetchData() {
  return function (dispatch, getState) {
    dispatch(loaderActions.show());
    const checkedRows = getCheckedRows(getState());

    return dispatch(apiDeleteTemplate({ ids: checkedRows }))
      .then(function () {
        dispatch(closeDialogs());
        return dispatch(fetchData());
      })
      .finally(function () {
        dispatch(loaderActions.hide());
      });
  };
}
