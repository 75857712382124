import { Cell } from 'core/uiKit/components/tables/Table';

/**
 * Колонка __Количество посадочных мест, шт__.
 *
 * @type {{
 * accessor: 'places_quantity',
 * Header: 'Количество посадочных мест, шт',
 * sortable: true,
 * }}
 * @augments Cell
 */
export const places_quantity: Cell = {
  Header: 'Количество посадочных мест, шт',
  accessor: 'places_quantity',
  sortable: true,
};
