import { withFinalForm } from 'core/form/finalForm/helpers/withFinalForm';
import {
  COATING_TYPE_ID_NAME,
  CoatingTypeId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingTypeId';
import React from 'react';
import { Field } from 'react-final-form';

import { usePreparationCoatingTypeIdFF } from './usePreparation.CoatingTypeId.FF';

/**
 * Select FF __Вид покрытия (уточнение)__.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const CoatingTypeIdFF = (props) => {
  usePreparationCoatingTypeIdFF();
  return (
    <Field
      {...props}
      name={COATING_TYPE_ID_NAME}
      component={withFinalForm(CoatingTypeId)}
    />
  );
};
