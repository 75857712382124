import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useFetchOghTable } from 'app/pages/registry/ogh/api/useFetchOghTable';

/**
 * Хук получения данных для таблицы исключения дочерних паспортов.
 *
 * @param {object} params - Входящие параметры.
 * @param {object} params.listData - Данные списка.
 * @param {Array<number>} params.ids - Значения выбранных паспортов для фильтрации.
 * @param {object} params.valuesFilter - Значения фильтрации.
 * @returns {{isLoading: boolean, count: number, table: Array<*>}} - Возвращает данные таблицы.
 */
export const useDataTableDelete = ({ ids, listData, valuesFilter }) => {
  const rootCard = useGetParentCard();

  const queryKey = [
    'postTableDelete',
    {
      dateFrom: rootCard.startDate,
      dateTo: rootCard.endDate,
      ids: [0, ...ids],
      massiveChange: true,
      parId: rootCard.recordId,
      rootStartDate: rootCard.startDate,
      rootType: rootCard.typeId,
      typeId: listData.typeId,
      typePath: listData.typeId,
      ...valuesFilter,
    },
  ];

  const { count, isLoading, table } = useFetchOghTable(queryKey);

  return { count, isLoading, table };
};
