import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { MultiSelect } from 'core/uiKit/inputs/selects/MultiSelect';
import React from 'react';

export const CAR_CONDITIONS_NAME = 'carConditions';

/**
 * ## Техническое состояние.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const CarConditions = (props) => {
  const dict = 'carCondition';
  const { data: options = [] } = useGetDictionary(dict);

  return (
    <MultiSelect
      {...props}
      {...{ options }}
      label={'Техническое состояние'}
      name={CAR_CONDITIONS_NAME}
    />
  );
};
