import { Polyline } from '../Polyline';
import { GeometryTypes, RawGeometry } from '../types.d';

/**
 * Является ли геометрия полилинией.
 *
 * @param geometry - Геометрия.
 * @returns {boolean} Является ли геометрия полилинией.
 */
export const isGeometryPolyline = (
  geometry: RawGeometry,
): geometry is Polyline => geometry?.userData?.type === GeometryTypes.Polyline;
