import { toast } from 'core/uiKit/components/Toast';

/**
 * ParseError .
 *
 * @param {*} response - Response.
 * @returns {Promise<void>}
 */
const toastError = async (response) => {
  const { status, message: defaultMessage = 'Неизвестная ошибка' } =
    await response;

  const { errors = {}, message = defaultMessage } = await translateError(
    response,
  );
  if ('message' in errors && 'status' in errors) {
    return toast.errorDying(`${errors.status}: ${errors.message}`);
  }
  toast.errorDying(`${status}: ${message}`);
};

/**
 * HandleErrors .
 *
 * @param {*} response - Response object.
 * @returns {Promise<void>}
 */
export const handleErrors = async (response) => {
  const { status, ok } = await response.clone();

  if (!ok) {
    switch (status) {
      case 401:
        if (window.location.pathname !== '/auth/login') {
          window.location = '/auth/login';
        }
        throw await translateError(response);
      case 400:
        throw await translateError(response);
      default:
        await toastError(response);
        throw await translateError(response);
    }
  }
};

/**
 * Перевод английский  ошибок. Или Кастомизация ошибок.
 *
 * @param {*} response - Response.
 * @returns {Promise<void>}
 */
const translateError = async (response) => {
  let errorMessage = { message: 'Неожиданная ошибка' };

  try {
    errorMessage = await response.clone().json();
  } catch (err) {
    errorMessage = err;
    if (err.message === 'Unexpected end of JSON input') {
      errorMessage.message = 'Неожидаемый ответ от сервера';
    }
  }

  return errorMessage;
};
