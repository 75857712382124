import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
/* eslint-disable */
import { useGetCardModeSelector } from 'app/selectors/useSelectors';
import { OghStatus } from 'core/uiKit/preparedInputs';

/**
 *
 */
export const OghStatusComponent = () => {
  const { editMode } = useGetCardModeSelector();
  const { logicalStateId } = useGetCurrentCard();
  return (
    <OghStatus
      {...{
        disabled: true,
        value: editMode ? 2 : logicalStateId || 2,
      }}
    />
  );
};
