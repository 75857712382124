import * as api from 'app/api';
import * as types from 'app/constants/actionTypes';
import getSearchFormValues from 'app/utils/getSearchFormValues';
import * as tableActions from 'app/utils/table/navigation';
import { actions as formActions } from 'react-redux-form';
import { createAction } from 'redux-actions';

import * as loaderActions from './loader';

 
export const apiGetRegistry = createAction(
  types.API_GET_TITLELIST_DETAILS_REGISTRY,
  function (data) {
    return {
      promise: api.getTitlelistDetailsRegistry(data),
    };
  },
);

const registryName = 'rrf.titlelistDetailsRegistry';

/* eslint-disable */
export function fetchData() {
  return formActions.submit(registryName);
}

/* eslint-disable */
export function onSubmit(values) {
  return function (dispatch) {
    dispatch(loaderActions.show());

    return dispatch(apiGetRegistry(getSearchFormValues(values))).finally(
      function () {
        dispatch(loaderActions.hide());
      },
    );
  };
}

/* eslint-disable */
export function onReset() {
  return function (dispatch) {
    dispatch(formActions.change('rrf.titlelistDetailsRegistry.idOrName', ''));
    dispatch(formActions.reset('rrf.titlelistDetailsRegistry.grouping'));
  };
}

/* eslint-disable */
export function formReset() {
  return function (dispatch) {
    dispatch(formActions.change('rrf.titlelistDetailsRegistry.idOrName', ''));
    dispatch(formActions.reset('rrf.titlelistDetailsRegistry.grouping'));
    dispatch(formActions.reset('rrf.titlelistDetailsRegistry.withTotal'));
    dispatch(formActions.reset('rrf.titlelistDetailsRegistry.maxRows'));
    dispatch(formActions.reset('rrf.titlelistDetailsRegistry.page'));
  };
}

 
export const onLimit = tableActions.limit(registryName, fetchData);
 
export const onPagination = tableActions.paginate(registryName, fetchData);
