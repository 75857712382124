import { get, post } from 'app/api/crud';
import * as apiOgh from 'app/api/ogh';
import * as types from 'app/constants/actionTypes';
// import { receiveAddObjectToTree } from '../odsObjectTreeActions';
import { getApiPath } from 'app/utils/api/config';
import { log } from 'core/utils/log';
import forEach from 'lodash/forEach';
import lodashGet from 'lodash/get';
import moment from 'moment';

import { checkObjectParent as checkObjectParentD } from './checkObjectParent';
import { deleteCardAllow as deleteCardAllowD } from './deleteCardAllow';
import { editCardDidNotLock as editCardDidNotLockD } from './editCardDidNotLock';
import { fetchOgh as fetchOghDepricated } from './fetchOgh';
import { receiveErrorDeleteCard as receiveErrorDeleteCardD } from './receiveErrorDeleteCard';
import { receiveErrorRootCard as receiveErrorRootCardD } from './receiveErrorRootCard';
import { receiveRootCard as receiveRootCardD } from './receiveRootCard';
import { requestRootCard as requestRootCardD } from './requestRootCard';
import { startDeleteCard as startDeleteCardD } from './startDeleteCard';
import { startEditCardRequest as startEditCardRequestD } from './startEditCardRequest';

/**
 * Запрос на просмотр паспорта.
 *
 * @param {number} id - Id.
 * @param {string} startDate - Дата начала.
 * @param {Function} resetTree - Функция сброса.
 * @returns {Function}
 */
export function fetchPreviewCard(id, startDate, resetTree = true) {
  return (dispatch) => {
    dispatch(requestRootCard(null));
    return get(`/card/preview/${id}/${startDate}`).then((json) => {
      dispatch(receiveRootCard(json, resetTree));
    });
  };
}

/**
 * Функция редактирования пасспорта.
 *
 * @param {string} startDate - Дата начала.
 * @param {string} endDate - Конец даты.
 * @returns {object}
 */
export function editCard(startDate, endDate) {
  return {
    endDate,
    startDate,
    type: types.EDIT_CARD,
  };
}

/**
 * Функция continueEditCard.
 *
 * @returns {object}
 */
export function continueEditCard() {
  return {
    type: types.CONTINUE_EDIT_CARD,
  };
}

/**
 * Функция editCardAllow.
 *
 * @param {object} versionInfo - Информация о версии.
 * @param {object} cardInfo - Информация о паспорте.
 * @param {boolean} root_edit - Флаг.
 * @returns {object}
 */
export function editCardAllow(versionInfo, cardInfo, root_edit) {
  return {
    end_date: versionInfo.end_date,
    object: cardInfo,
    restrict_dates: (versionInfo.restrict_dates || []).map((item) => {
      const date = item.split('.');
      return new Date(date[2], date[1] - 1, date[0]);
    }),
    root_edit,
    start_date: versionInfo.start_date,
    type: types.RECEIVE_EDIT_CARD_ALLOW,
  };
}

/**
 * Экшен запуска диалогового окна editCardClearDialog.
 *
 * @returns {object}
 */
export function editCardClearDialog() {
  return {
    type: types.EDIT_CARD_CLEAR_DIALOG,
  };
}

/**
 * Экшен editCardDeny.
 *
 * @param {string} otherId - Остальной ID.
 * @returns {object}
 */
export function editCardDeny(otherId) {
  return {
    id: otherId,
    type: types.RECEIVE_EDIT_CARD_DENY,
  };
}

/**
 * Экшен requestUpdateCard.
 *
 * @returns {object}
 */
export function requestUpdateCard() {
  return {
    type: types.REQUEST_UPDATE_CARD,
  };
}

/**
 * Экшен resolveErrorMessage.
 *
 * @param {object} badAnswer - Объект badAnswer.
 * @returns {object}
 */
export function resolveErrorMessage(badAnswer) {
  return {
    isAllow: false,
    message: badAnswer.message,
  };
}

/**
 * Экшен receiveErrorUpdateCard.
 *
 * @param {object} addition - Дополнение.
 * @returns {object}
 */
export function receiveErrorUpdateCard(addition) {
  return {
    type: types.RECEIVE_ERROR_UPDATE_CARD,
    ...addition,
  };
}

/**
 * Экшен receiveUpdateCard.
 *
 * @param {string} from - Откуда.
 * @param {string} to - Куда перейти.
 * @returns {object}
 */
export function receiveUpdateCard(from, to) {
  return {
    from,
    relocate: to,
    type: types.RECEIVE_UPDATE_CARD,
  };
}

/**
 * Экшен receiveCreateCard.
 *
 * @returns {object}
 */
export function receiveCreateCard() {
  return {
    type: types.RECEIVE_CREATE_CARD,
  };
}

/**
 * Экшен receiveCreateChildCard.
 *
 * @returns {object}
 */
export function receiveCreateChildCard() {
  return {
    type: types.RECEIVE_CREATE_CHILD_CARD,
  };
}

/**
 * Экшен receiveCreateChildCard.
 *
 * @param {object} geometry - Объект с геометрий.
 * @returns {object}
 */
export function requestSaveGeometry(geometry) {
  return {
    geometry,
    type: types.REQUEST_SAVE_GEOMETRY,
  };
}

/**
 * Экшен receiveSaveGeometry.
 *
 * @param {object} object - Объект с геометрий.
 * @returns {object}
 */
export function receiveSaveGeometry(object) {
  return {
    object,
    type: types.RECEIVE_SAVE_GEOMETRY,
  };
}

// todo: пока так. так как надо переводить время для отправки на сервер в дальнейшем выработать единое решение

/**
 * Функция prepare.
 *
 * @param {object} values - Значения.
 * @returns {object}
 */
const prepare = (values) => {
  const actualSchemaDate = lodashGet(values, 'attribute.actual_schema_date');
  if (actualSchemaDate) {
    if (moment(actualSchemaDate).isValid()) {
      values.attribute.actual_schema_date =
        moment(actualSchemaDate).format('DD.MM.YYYY');
    }
  }
  const newAttribute = {};
  const { attribute } = values;

  forEach(attribute, function (value, key) {
    if (value !== undefined && value !== null) {
      switch (key) {
        case 'reservoir_area': {
          if (isNaN(value)) {
            break;
          }
        }
        // eslint-disable-next-line no-fallthrough
        default:
          newAttribute[key] = value;
      }
    }
  });
  values.attribute = { ...newAttribute };
  values.pathObjectTypeId = values.type;
  return values;
};

/**
 * Функция sendUpdateCard.
 *
 * @param {string} cardType - Тип карты.
 * @returns {function(*, boolean=): function(*): Promise<*>}
 */
export const sendUpdateCard =
  (cardType) =>
  (objectAttributes, isChild = false) => {
    return (dispatch) => {
      dispatch(requestUpdateCard());
      return post(
        `/${cardType === 'tech' ? 'tech/' : ''}update`,
        prepare(objectAttributes),
      )
        .then((response) => {
          if (cardType === 'tech') {
            dispatch(receiveCreateCard(`/r/tech/${objectAttributes.code}`));
          } else if (isChild) {
            // dispatch(
            //   receiveAddObjectToTree(
            //     objectAttributes,
            //     JSON.parse(response.add_to_object_tree),
            //     response.id,
            //   ),
            // );
          } else {
            dispatch(
              receiveUpdateCard(
                objectAttributes.id,
                response.id != null ? response.id : objectAttributes.id,
              ),
            );
          }

          return response.id;
        })
        .catch(async (errors) => {
          dispatch(
            receiveErrorUpdateCard(
              resolveErrorMessage(errors, objectAttributes),
            ),
          );
        })
        .finally(() => dispatch(receiveCreateChildCard()));
    };
  };

/**
 * Функция sendCreateCard.
 *
 * @param {string} cardType - Тип карты.
 * @returns {function(*, boolean=): function(*): Promise<*>}
 */
export const sendCreateCard =
  (cardType) =>
  (data, isChild = false) => {
    return (dispatch) => {
      dispatch(requestCreateCard());
      return post(`/create${cardType === 'tech' ? '/tech' : ''}`, prepare(data))
        .then((response) => {
          if (cardType === 'tech') {
            dispatch(receiveCreateCard(`/r/tech/${data.code}`));
          } else {
            if (isChild) {
              // dispatch(
              //   receiveAddObjectToTree(
              //     data,
              //     JSON.parse(response.add_to_object_tree),
              //     response.id,
              //   ),
              // );
            } else {
              dispatch(receiveCreateCard());
            }
          }
          return response.id;
        })
        .catch(async (errors) => {
          dispatch(receiveErrorCreateCard(resolveErrorMessage(errors, data)));
        })
        .finally(() => dispatch(receiveCreateChildCard()));
    };
  };

/**
 * Экшен requestCreateCard.
 *
 * @returns {object}
 */
export function requestCreateCard() {
  return {
    type: types.REQUEST_CREATE_CARD,
  };
}

/**
 * Экшен receiveErrorCreateCard.
 *
 * @param {object} addition - Дополнения.
 * @returns {object}
 */
export function receiveErrorCreateCard(addition) {
  return {
    type: types.RECEIVE_ERROR_CREATE_CARD,
    ...addition,
  };
}

/**
 * Экшен switchPreviewCreateDialog.
 *
 * @param {boolean} show - Дополнения.
 * @returns {object}
 */
export function switchPreviewCreateDialog(show) {
  return {
    show,
    type: types.PREVIEW_CREATE_CARD,
  };
}

/**
 * @deprecated
 * @see app/actions/odsObjectActions/checkObjectParent
 * @param id
 * @param date
 * @returns {function(): Promise<*>}
 */
export const checkObjectParent = checkObjectParentD;

/**
 * Экшен rootTreeStateChanged.
 *
 * @returns {object}
 */
function rootTreeStateChanged() {
  return {
    type: types.ROOT_TREE_STATE_CHANGED,
  };
}

/**
 * Экшен startEditCard.
 *
 * @param {number} root_id - Рут Id.
 * @param {number} record_id - Рекорд Id.
 * @param {number} type_id - Тип ОГХ.
 * @param {number} root_tree_id - Id правого списка.
 * @param {number} root_tree_logical_state_id - Логический Id.
 * @returns {object}
 */
export const startEditCard =
  (root_id, record_id, type_id, root_tree_id, root_tree_logical_state_id) =>
  async (dispatch) => {
    dispatch(startEditCardRequestD());

    const data = {
      record_id,
      root_id,
      type_id,
    };

    const response = await apiOgh.fetchStartEdit(data);
    const { errors, message } = response;
    const { cardInfo, versionInfo, lockInfo } = response;

    let result;

    if (errors && errors.length > 0) {
      result = dispatch(receiveErrorStartUpdateCard(message || errors[0]));
    } else if (versionInfo.project_id !== null) {
      result = dispatch(editCardDeny(versionInfo.project_id));
    } else if (lockInfo.lock) {
      result = dispatch(
        checkRootTreeStateChanged(
          record_id,
          root_tree_id,
          root_tree_logical_state_id,
          versionInfo,
          cardInfo,
        ),
      );
    } else {
      result = dispatch(editCardDidNotLock(lockInfo));
    }

    return result;
  };

/**
 * Проверка на изменение дерева.
 *
 * @param {number} recordId - Рекорд Id.
 * @param {number} rootTreeId - Id списка.
 * @param {number} rootTreeLogicalStateId - Логический Id.
 * @param {object} versionInfo - Объект версий.
 * @param {object} cardInfo - Объект информация о карте.
 * @returns {function(*): Promise<*>}
 */
const checkRootTreeStateChanged =
  (recordId, rootTreeId, rootTreeLogicalStateId, versionInfo, cardInfo) =>
  async (dispatch) => {
    const oghResponse = await apiOgh.fetchOgh(rootTreeId);
    let result;

    if (oghResponse.status === 200) {
      const json = await oghResponse.json();
      if (json.logical_state_id !== rootTreeLogicalStateId) {
        result = dispatch(rootTreeStateChanged());
      } else {
        const isRootTreeEdit = recordId === rootTreeId;
        result = dispatch(editCardAllow(versionInfo, cardInfo, isRootTreeEdit));
      }
    } else {
      result = dispatch(rootTreeStateChanged());
    }

    return result;
  };

/**
 * Экшен receiveErrorStartUpdateCard.
 *
 * @param {string} message - Сообщение.
 * @returns {object}
 */
export function receiveErrorStartUpdateCard(message) {
  return {
    message,
    type: types.RECEIVE_ERROR_START_UPDATE_CARD,
  };
}

/**
 * Функция setStartDateCard.
 *
 * @param {number} id - Id.
 * @param {string} type - Тип.
 * @param {string} startDate - Стартовая дата.
 * @returns {*}
 */
export function setStartDateCard(id, type, startDate) {
  return (dispatch) => {
    const argDate = Intl.DateTimeFormat('ru').format(startDate);
    return fetch(
      `${getApiPath()}/card/calc/endDate?root_id=${id}&type_id=${type}&start_date=${argDate}`,
      {
        credentials: 'include',
      },
    )
      .then((res) => {
        if (res.status === 200) {
          return res;
        }
        return null;
      })
      .then((response) => response.text())
      .then((text) => {
        return dispatch(setEndDate(text));
      })
      .catch(() => {
        return dispatch(setEndDate('01.01.3000'));
      });
  };
}

/**
 * Экшен setEndDate.
 *
 * @param {string} text - Сообщение.
 * @returns {object}
 */
export function setEndDate(text) {
  return {
    endDate: text,
    type: types.RECEIVE_END_DATE,
  };
}

/**
 * Экшен saveCard.
 *
 * @returns {object}
 */
export function saveCard() {
  return {
    type: types.SAVE_CARD,
  };
}

/**
 * Экшен createCard.
 *
 * @param {string} typeId - Тип Id.
 * @param {string} startDate - Стартовая дата.
 * @param {Array} options - Опшины.
 * @returns {object}
 */
export function createCard(typeId, startDate, options) {
  return {
    options,
    startDate,
    type: types.CREATE_CARD,
    typeId,
  };
}

/**
 * Экшен setMode.
 *
 * @param {boolean} mode - Мод.
 * @returns {object}
 */
export function setMode(mode) {
  return {
    mode,
    type: types.SET_MODE,
  };
}

/**
 * Экшен viewCard.
 *
 * @returns {object}
 */
export function viewCard() {
  return {
    type: types.VIEW_CARD,
  };
}

/**
 * Экшен cancelEditCardRequest.
 *
 * @returns {object}
 */
export function cancelEditCardRequest() {
  return {
    type: types.REQUEST_CANCEL_EDIT_CARD,
  };
}

/**
 * Экшен requestRollbackTreeChanges.
 *
 * @param {object} object - Объект.
 * @returns {object}
 */
export function requestRollbackTreeChanges(object) {
  return {
    object,
    type: types.REQUEST_ROLLBACK_TREE_CHANGES,
  };
}

/**
 * Экшен receiveRollbackTreeChanges.
 *
 * @param {object} object - Объект.
 * @returns {object}
 */
export function receiveRollbackTreeChanges(object) {
  return {
    object,
    type: types.RECEIVE_ROLLBACK_TREE_CHANGES,
  };
}

/**
 * Экшен rollbackTreeChanges.
 *
 * @param {object} data - Объект.
 * @returns {*}
 */
export function rollbackTreeChanges(data) {
  return (dispatch) => {
    dispatch(requestRollbackTreeChanges(data));
    return fetch(`${getApiPath()}/ogh/rollback_tree_changes`, {
      body: JSON.stringify(data),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      method: 'POST',
    })
      .then((response) =>
        response.json().catch((err) => {
          log.error(`'${err}' happened, but no big deal!`);
          return [];
        }),
      )
      .then((json) => {
        return dispatch(receiveRollbackTreeChanges(json));
      });
  };
}

/**
 * Экшен cancelEditCard.
 *
 * @param {number} root_id - Рутовый Id.
 * @param {number} record_id - Рекордный Id.
 * @returns {*}
 */
export function cancelEditCard(root_id, record_id) {
  if (
    root_id === null &&
    record_id === null &&
    window.location.href.indexOf('create') === -1
  ) {
    window.location.reload();
  }

  if (typeof record_id === 'undefined') {
    return viewCard();
  }

  if (record_id !== null && root_id !== null) {
    return (dispatch) => {
      dispatch(viewCard());
      return fetch(
        `${getApiPath()}/card/edit/cancel?root_id=${root_id}&record_id=${record_id}`,
        {
          credentials: 'include',
        },
      );
    };
  }

  return () => {};
}

/**
 * Экшен cancelDeleteCard.
 *
 * @param {number} record_id - Рекордный Id.
 * @returns {*}
 */
export function cancelDeleteCard(record_id) {
  return typeof record_id === 'undefined'
    ? viewCard()
    : (dispatch) => {
        dispatch(viewCard());
        return fetch(
          `${getApiPath()}/card/delete/cancel?record_id=${record_id}`,
          {
            credentials: 'include',
          },
        );
      };
}

/**
 * Экшен afterReceiving.
 *
 * @returns {object}
 */
export function afterReceiving() {
  return {
    type: types.AFTER_RECEIVING,
  };
}

/**
 * Экшен readyForUpdateCard.
 *
 * @returns {object}
 */
export function readyForUpdateCard() {
  return {
    type: types.READY_FOR_UPDATE_CARD,
  };
}

/**
 * Экшен requestDeleteCard.
 *
 * @returns {object}
 */
export function requestDeleteCard() {
  return {
    type: types.REQUEST_DELETE_CARD,
  };
}

/**
 * Экшен receiveDeleteCard.
 *
 * @param {string} address - Адрес.
 * @param {string} text - Текст.
 * @returns {object}
 */
export function receiveDeleteCard(address, text) {
  return {
    message: text,
    relocate: address,
    type: types.RECEIVE_DELETE_CARD,
  };
}

/**
 * Экшен showSendMatchCard.
 *
 * @param {object} object - Объект.
 * @returns {object}
 */
export function showSendMatchCard(object) {
  return {
    object,
    type: types.RECEIVE_START_SEND_MATCH_CARD,
  };
}

/**
 * Экшен receiveErrorMatchCard.
 *
 * @param {string} text - Текст.
 * @returns {object}
 */
export function receiveErrorMatchCard(text) {
  return {
    message: text,
    type: types.RECEIVE_ERROR_MATCH_CARD,
  };
}

/**
 * Экшен receiveErrorMatchCard.
 *
 * @param {number} record_id - Рекорд Id.
 * @returns {object}
 */
export function cancelMatchCard(record_id) {
  return typeof record_id === 'undefined'
    ? viewCard()
    : (dispatch) => {
        dispatch(viewCard());
        return fetch(
          `${getApiPath()}/card/matching/cancel?record_id=${record_id}`,
          {
            credentials: 'include',
          },
        );
      };
}

/**
 * @deprecated
 * @see app/actions/odsObjectActions/useStartMatchingCard
 * @param record_id
 * @param type_id
 * @returns {function(*): Promise<any>}
 */

/**
 * Экшен startMatchingCard.
 *
 * @param {number} record_id - Рекорд Id.
 * @param {number} type_id - Тип ОГХ.
 * @returns {object}
 */
export function startMatchingCard(record_id, type_id) {
  return (dispatch) => {
    dispatch(startEditCardRequestD());
    return fetch(
      `${getApiPath()}/card/matching/start?record_id=${record_id}&type_id=${type_id}`,
      {
        credentials: 'include',
      },
    )
      .then((response) => response.json())
      .then((json) => {
        return json.errors && json.errors.length > 0
          ? dispatch(receiveErrorMatchCard(json.message || json.errors[0]))
          : !json.lockInfo || json.lockInfo.lock
          ? dispatch(showSendMatchCard(json.cardInfo))
          : dispatch(editCardDidNotLock(json.lockInfo));
      });
  };
}

/**
 * Экшен cancelEditCurrentCard.
 *
 * @returns {object}
 */
export function cancelEditCurrentCard() {
  return {
    type: types.CANCEL_EDIT_CURRENT_CARD,
  };
}

/**
 * Экшен cancelMode.
 *
 * @returns {object}
 */
export function cancelMode() {
  return {
    type: types.CANCEL_MODE,
  };
}

/**
 * Экшен sendDeleteCard.
 *
 * @param {string} cardType - Тип карты.
 * @returns {object}
 */
export const sendDeleteCard = (cardType) => (data, parentTypeId) => {
  return (dispatch) => {
    dispatch(requestDeleteCard());
    return fetch(`${getApiPath()}/delete`, {
      body: JSON.stringify(data),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      method: 'POST',
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.errors && json.errors.length > 0) {
          return dispatch(
            receiveErrorDeleteCard(json.message || json.errors[0]),
          );
        } else {
          const urlCommonPath =
            parentTypeId && Number(parentTypeId) !== -1
              ? `${cardType}/${parentTypeId}`
              : cardType;

          return dispatch(
            receiveDeleteCard(
              json.deletedInDatabase
                ? `/r/${urlCommonPath}/${data.type}`
                : parentTypeId !== undefined
                ? `/${urlCommonPath}/${parentTypeId}/${json.id}`
                : `/${urlCommonPath}/${json.id}`,
              json.deletedInDatabase
                ? 'Удаление версии объекта успешно произведено'
                : 'Запрос на удаление версии объекта отправлен на согласование',
            ),
          );
        }
      });
  };
};

/**
 * Экшен saveEffectCalcAttribute.
 *
 * @param {object} effect_attributes - Хех.
 * @returns {object}
 */
export function saveEffectCalcAttribute(effect_attributes) {
  return {
    effect_attributes,
    type: types.UPDATE_EFFECT_ATTRIBUTE_CARD,
  };
}

/**
 * Экшен changeCalcAttributes.
 *
 * @param {object} data - Данные.
 * @returns {object}
 */
export function changeCalcAttributes(data) {
  return {
    calc_attribute: data.calc_attribute,
    type: types.CHANGE_CALC_ATTRIBUTES,
  };
}

/**
 * Экшен recalculateAttributes.
 *
 * @param {number} root_id - Рутовый Id.
 * @param {number} root_type - Рутовый тип.
 * @param {*} effect_attributes - Эффекты.
 * @param {*} journal - Журнал.
 * @param {*} newValue - Новое значение.
 * @returns {object}
 */
export function recalculateAttributes(
  root_id,
  root_type,
  effect_attributes,
  journal,
  newValue,
) {
  return (dispatch) => {
    const newEffectAttributes = { ...effect_attributes, ...newValue };
    dispatch(saveEffectCalcAttribute(newEffectAttributes));
    return fetch(`${getApiPath()}/tree/calc`, {
      body: JSON.stringify({
        cnt_action: journal !== null ? journal.cnt_action : 0,
        effect_attributes: JSON.stringify(newEffectAttributes),
        root_id: root_id,
        root_type: root_type,
        tree_journal: journal !== null ? JSON.stringify(journal.actions) : '{}',
      }),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      method: 'POST',
    })
      .then((response) => {
        return response.json().catch((err) => {
          log.error(`'${err}' happened, but no big deal!`);
          return [];
        });
      })
      .then((json) => dispatch(changeCalcAttributes(json)));
  };
}

/**
 * Экшен requestOghChoiceAddressList.
 *
 * @param {number} id - Id.
 * @returns {object}
 */
function requestOghChoiceAddressList(id) {
  return {
    id,
    type: types.REQUEST_OGH_CHOICE_ADDRESS_LIST,
  };
}

/**
 * Экшен receiveOghChoiceAddressList.
 *
 * @param {number} object - Id.
 * @returns {object}
 */
function receiveOghChoiceAddressList(object) {
  return {
    object,
    type: types.RECEIVE_OGH_CHOICE_ADDRESS_LIST,
  };
}

/**
 * Экшен fetchOghChoiceAddressList.
 *
 * @param {number} id - Id.
 * @returns {object}
 */
export function fetchOghChoiceAddressList(id) {
  return (dispatch) => {
    dispatch(requestOghChoiceAddressList(id));
    return fetch(`${getApiPath()}/ogh/get/choice/address/list/${id}`, {
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((json) => dispatch(receiveOghChoiceAddressList(json)));
  };
}

/**
 * Экшен resetOghChoiceAddressList.
 *
 * @returns {object}
 */
export function resetOghChoiceAddressList() {
  return {
    type: types.RESET_OGH_CHOICE_ADDRESS_LIST,
  };
}

/**
 * Экшен resetMatching.
 *
 * @returns {object}
 */
export function resetMatching() {
  return {
    type: types.RESET_MATCHING,
  };
}

/**
 * @deprecated
 * @see src/app/actions/odsObjectActions/fetchOgh
 * @type {(function(*=): function(*): Promise<boolean>)}
 */
export const fetchOgh = fetchOghDepricated;

/**
 * @deprecated
 * @see src/app/actions/odsObjectActions/requestRootCard
 * @type {(function(*=): function(*): Promise<boolean>)}
 */
export const requestRootCard = requestRootCardD;

/**
 * @deprecated
 * @see src/app/actions/odsObjectActions/receiveRootCard
 * @type {(function(*=): function(*): Promise<boolean>)}
 */
export const receiveRootCard = receiveRootCardD;

/**
 * @deprecated
 * @see src/app/actions/odsObjectActions/receiveErrorRootCard
 * @type {(function(*=): function(*): Promise<boolean>)}
 */
export const receiveErrorRootCard = receiveErrorRootCardD;

/**
 * @deprecated
 * @see src/app/actions/odsObjectActions/startDeleteCard
 * @type {(function(*=): function(*): Promise<boolean>)}
 */
export const startDeleteCard = startDeleteCardD;
/**
 * @deprecated
 * @see src/app/actions/odsObjectActions/startEditCardRequest
 * @type {(function(*=): function(*): Promise<boolean>)}
 */
export const startEditCardRequest = startEditCardRequestD;

/**
 * @deprecated
 * @see src/app/actions/odsObjectActions/receiveErrorDeleteCard
 * @type {(function(*=): function(*): Promise<boolean>)}
 */
export const receiveErrorDeleteCard = receiveErrorDeleteCardD;

/**
 * @deprecated
 * @see src/app/actions/odsObjectActions/editCardDidNotLock
 * @type {(function(*=): function(*): Promise<boolean>)}
 */
export const editCardDidNotLock = editCardDidNotLockD;

/**
 * @deprecated
 * @see src/app/actions/odsObjectActions/deleteCardAllow
 * @type {(function(*=): function(*): Promise<boolean>)}
 */
export const deleteCardAllow = deleteCardAllowD;
