import { AutocompleteWithChipAsync } from 'core/uiKit/inputs/selects/AutocompleteWithChipAsync';
import React from 'react';

import { fetchAddress } from './api';

export const ADDRESS_NAME = 'address_id';

/**
 * ## Address.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const Address = (props) => {
  return (
    <AutocompleteWithChipAsync
      {...props}
      fetchFunction={fetchAddress}
      label={'Адрес'}
      name={ADDRESS_NAME}
    />
  );
};
