import { Table } from 'core/uiKit/components/tables/Table';

import { getColumnsPpi } from './columns.Ppi';
import { useSetupTablePpi } from './useSetup.Table.Ppi';

/**
 * Компонент таблицы __Элементы благоустройства для маломобильных групп населения__.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - Id типа огх.
 * @param {number} props.parentTypeId - Id типа родителя.
 * @returns {JSX.Element} - JSX.
 * @example -----
 * <TablePpi
 *  typeId={typeId}
 *  parentTypeId={3}
 * />
 */
export const TablePpi = ({ typeId, parentTypeId }) => {
  const { table, isLoading, count } = useSetupTablePpi({
    parentTypeId,
    typeId,
  });

  return (
    <Table
      columns={getColumnsPpi()}
      data={table || []}
      isLoading={isLoading}
      total={count}
    />
  );
};
