import { BasePgmRouteLazy } from 'app/pages/registry/ogh/basePgm';
import { pathBasePgm } from 'app/pages/registry/ogh/basePgm/BasePgm/path.BasePgm';
import { ImprovementObjectRouteLazy } from 'app/pages/registry/ogh/improvementObject';
import { pathImprovementObject } from 'app/pages/registry/ogh/improvementObject/ImprovementObject/path.ImprovementObject';
import { MafRfidLazy } from 'app/pages/registry/ogh/MafRfid';
import { pathMafRfid } from 'app/pages/registry/ogh/MafRfid/path.MafRfid';
import { OdhRouteLazy } from 'app/pages/registry/ogh/odh';
import { pathOdh } from 'app/pages/registry/ogh/odh/Odh/path.Odh';
import { OghLazy } from 'app/pages/registry/ogh/Ogh';
import { OznRouteLazy } from 'app/pages/registry/ogh/ozn';
import { pathOzn } from 'app/pages/registry/ogh/ozn/Ozn/path.Ozn';
import { SpaRouteLazy } from 'app/pages/registry/ogh/spa';
import { pathSpa } from 'app/pages/registry/ogh/spa/Spa/path.Spa';
import { YardRouteLazy } from 'app/pages/registry/ogh/yard';
import { pathYard } from 'app/pages/registry/ogh/yard/Yard/path.Yard';
import { pathContainer } from 'app/pages/registry/ogh/сontainer/Container/path.Container';
import { lazy, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

const ContainerRoute = lazy(() =>
  import('app/pages/registry/ogh/сontainer/ContainerRoute'),
);

/**
 * Ogh route.
 *
 * @returns {JSX.Element}
 */
const OghRoute = () => {
  useEffect(() => {
    BasePgmRouteLazy.preload();
    ImprovementObjectRouteLazy.preload();
    MafRfidLazy.preload();
    OdhRouteLazy.preload();
    OznRouteLazy.preload();
    OghLazy.preload();
    SpaRouteLazy.preload();
    YardRouteLazy.preload();
  });

  return (
    <Routes>
      <Route element={<OghLazy />} path={'/'} exact={true} />
      <Route element={<BasePgmRouteLazy />} path={`${pathBasePgm.path}/*`} />
      <Route element={<ContainerRoute />} path={`${pathContainer.path}/*`} />
      <Route
        element={<ImprovementObjectRouteLazy />}
        path={`${pathImprovementObject.path}/*`}
      />
      <Route element={<OdhRouteLazy />} path={`${pathOdh.path}/*`} />
      <Route element={<OznRouteLazy />} path={`${pathOzn.path}/*`} />
      <Route element={<YardRouteLazy />} path={`${pathYard.path}/*`} />
      <Route element={<SpaRouteLazy />} path={`${pathSpa.path}/*`} />
      <Route element={<MafRfidLazy />} path={`${pathMafRfid.path}/*`} />
    </Routes>
  );
};

export default OghRoute;
