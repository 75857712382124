/* eslint-disable */

import { createAttribute as createDocumentsAttribute } from 'app/components/card/ogh/DocumentsTab';

import { createAttribute as createAddressListFieldAttribute } from '../components/AddressListField';
import { createAttribute as createAquaAreaAttribute } from '../components/AquaAreaTab';
import { createDistrictListFieldAttribute } from '../components/DistrictListField';
import { createAttribute as improvementCategoriesCreateAttribute } from '../components/ImprovementCategories';
import { createdOkrugListFieldAttribute } from '../components/OkrugListField';
import { createAttribute as createOwnerAndCustomerFieldsAttribute } from '../components/OwnerAndCustomerFields';
import { createdOznTypeAttribute } from '../components/OznTypeField';
import { createAttribute } from './createAttribute';

jest.mock('app/components/card/ogh/DocumentsTab');
jest.mock('../components/AddressListField');
jest.mock('../components/AquaAreaTab');
jest.mock('../components/DistrictListField');
jest.mock('../components/ImprovementCategories');
jest.mock('../components/OkrugListField');
jest.mock('../components/OwnerAndCustomerFields');
jest.mock('../components/OznTypeField');

describe('spec createAttribute', function () {
  it('should', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    createdOznTypeAttribute.mockReturnValue({ q: 1 });
    createdOkrugListFieldAttribute.mockReturnValue({ w: 2 });
    createDistrictListFieldAttribute.mockReturnValue({ e: 3 });
    createAddressListFieldAttribute.mockReturnValue({ r: 4 });
    createOwnerAndCustomerFieldsAttribute.mockReturnValue({ t: 5 });
    improvementCategoriesCreateAttribute.mockReturnValue({ y: 6 });
    createDocumentsAttribute.mockReturnValue({ u: 7 });
    createAquaAreaAttribute.mockReturnValue({ i: 8 });
    // Act

    // Assert
    expect(createAttribute({ a: 23 })).toStrictEqual({
      e: 3,
      i: 8,
      q: 1,
      r: 4,
      t: 5,
      u: 7,
      w: 2,
      y: 6,
    });

    expect(createdOznTypeAttribute).toHaveBeenCalledWith({ a: 23 });
    expect(createdOkrugListFieldAttribute).toHaveBeenCalledWith({ a: 23 });
    expect(createDistrictListFieldAttribute).toHaveBeenCalledWith({ a: 23 });
    expect(createAddressListFieldAttribute).toHaveBeenCalledWith({ a: 23 });
    expect(createOwnerAndCustomerFieldsAttribute).toHaveBeenCalledWith({
      a: 23,
    });
    expect(improvementCategoriesCreateAttribute).toHaveBeenCalledWith({
      a: 23,
    });
    expect(createDocumentsAttribute).toHaveBeenCalledWith({ a: 23 });
    expect(createAquaAreaAttribute).toHaveBeenCalledWith({ a: 23 });
  });
});
