/* eslint-disable */
import React from 'react';

import messages from './messages';
import IntersectionsMessage from './IntersectionsMessage';
import GeometryCSVLink from './GeometryCSVLink';

import toArray from 'app/utils/toArray';

/* eslint-disable */
export async function onCheckGeometryReonButtonPress(params) {
  const { hasReonIntersections, resetReonIntersections } = params;

  if (hasReonIntersections) {
    resetReonIntersections();
  } else {
    await getGeometryReonIntersections(params);
  }
}

async function getGeometryReonIntersections(params) {
  const { data, draw, showAlert, fetchReonIntersectionsGeometry } = params;
  const object = await fetchReonIntersectionsGeometry(data);
  const intersections = toArray(object);

  if (intersections.length > 0) {
    showAlert(createMessage(intersections, messages.reonIntersections));
    const items = intersections.map((item) => createObjectForMap(item));
    draw(items);
  } else {
    showAlert(messages.success);
  }
}

function createMessage(array, message) {
  const intersections = array.map((item) => formatMessage(item));
  const GeometryCSV = () => (
    <GeometryCSVLink fileName={'ReonGeometry.csv'} objects={array} />
  );

  return (
    <IntersectionsMessage
      GeometryCSVLink={GeometryCSV()}
      intersections={intersections}
      message={message}
      orderedList={true}
    />
  );
}

function formatMessage(data) {
  const descr = data['descr'];
  const prizn_sobstv = data['prizn_sobstv'];
  const prizn_prava = data['prizn_prava'];
  const address = data['address'];
  return `Кадастровый номер: ${descr};
          Признак собственности: ${prizn_sobstv};
          Признак оформленного права: ${prizn_prava};
          Адрес: ${address}`;
}

function createObjectForMap(item) {
  const hintFields = [
    {
      key: 'title',
      rawValue: 'Объект РЕОН',
      value: 'Объект РЕОН',
    },
    {
      key: 'descr',
      rawValue: item.descr,
      value: `Кадастровый номер: ${item.descr}`,
    },
    {
      key: 'prizn_sobstv',
      rawValue: item.prizn_sobstv,
      value: `Признак собственности: ${item.prizn_sobstv}`,
    },
    {
      key: 'prizn_prava',
      rawValue: item.prizn_prava,
      value: `Признак оформленного права: ${item.prizn_prava}`,
    },
    {
      key: 'Адрес',
      rawValue: item.address,
      value: `Адрес: ${item.address}`,
    },
  ];

  const hint = hintFields.filter((item) => item.rawValue);

  return {
    hint: {
      object_attribute_hint: hint,
      object_attribute_hint_short: [
        {
          key: 'name',
          value: item.descr,
        },
      ],
    },
    ...item.geometry,
  };
}
