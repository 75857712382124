import { todayDateAndHours } from 'app/utils/dateUtils';
import { filterInputDataWithHours } from 'core/form/filterForm/components/filterInputsClasses';
import { KeyboardDateTimePickerFF } from 'core/form/finalForm/fields/default/datePickers/KeyboardDateTimePickerFF';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import moment from 'moment';
import React from 'react';
import { DateTimeParam, StringParam, withDefault } from 'use-query-params';

export const DATE_FROM_WITH_HOURS_NAME = 'dateFrom';

/**
 * Фильтр по дате изм с (план).
 *
 * @param {*} root - Properties.
 * @param {string} root.label - Label.
 * @param {object} root.props - Properties.
 * @returns {JSX.Element}
 */
export const DateFromWithHoursFilter = ({
  label = 'Дата изм с (план)',
  ...props
}) => (
  <KeyboardDateTimePickerFF
    {...props}
    className={filterInputDataWithHours}
    label={label}
    name={DATE_FROM_WITH_HOURS_NAME}
  />
);

/**
 * DateFromWithHoursParamsDefault.
 *
 * @returns {*}
 */
export const dateFromWithHoursParamsDefault = () => {
  addPreparation(DATE_FROM_WITH_HOURS_NAME, (value) => {
    let newValue = value;
    value = new Date(value);
    if (typeof value === 'object' && moment(value).isValid()) {
      newValue = moment(value).format('DD.MM.YYYY HH:mm');
    }
    return newValue;
  });
  return {
    [DATE_FROM_WITH_HOURS_NAME]: StringParam,
  };
};

/**
 * DateFromWithHoursParams.
 *
 * @returns {*}
 */
export const dateFromWithHoursParams = () => {
  addPreparation(DATE_FROM_WITH_HOURS_NAME, (value) => {
    let newValue = value;
    if (typeof value === 'object' && moment(value).isValid()) {
      newValue = moment(value).format('DD.MM.YYYY HH:mm');
    }
    return newValue;
  });

  return {
    [DATE_FROM_WITH_HOURS_NAME]: withDefault(DateTimeParam, todayDateAndHours),
  };
};
