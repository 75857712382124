import union from '@turf/union';
import unkinkPolygon from '@turf/unkink-polygon';
import { LINESTRING, POINT, POLYGON } from 'app/constants/geometryTypes';
import { ol } from 'app/utils/helpers/ol';
import { log } from 'core/utils/log';

import geometryTypeDict, { LINES, POINTS, POLYGONS } from './geometryTypeDict';

const RING_MIN_VERTICES_NUMBER = 4;

/* eslint-disable */
export default class GeometryService {
  constructor() {
    this.geoJSON = new ol.format.GeoJSON();
  }

  transformRing(ring) {
    const ringWithoutNeighbourDuplicatePoints =
      this.getRingWithoutNeighbourDuplicatePoints(ring);

    if (ringWithoutNeighbourDuplicatePoints.length < RING_MIN_VERTICES_NUMBER) {
      return null;
    }

    const geojson = toGeojson([ringWithoutNeighbourDuplicatePoints]);
    const unkinkedFeatures = unkinkPolygon(geojson).features;
    const transformedRings = unkinkedFeatures.reduce((result, item) => {
      result.push(...geojsonToRings(item.geometry));
      return result;
    }, []);
    return transformedRings;
  }

  transformPolygon(rings) {
    if (rings.length === 1) {
      return this.transformRing(rings[0]);
    } else {
      return rings;
    }
  }

  getRingWithoutNeighbourDuplicatePoints(ring) {
    let result = [];
    for (let i = 0; i < ring.length - 1; i++) {
      const pointCoordinates1 = ring[i];
      const pointCoordinates2 = ring[i + 1];

      const found = this.checkPointCoordinatesAreDuplicates(
        pointCoordinates1,
        pointCoordinates2,
      );

      if (found) {
        continue;
      } else {
        result.push(pointCoordinates1);
      }
    }
    result.push(ring[ring.length - 1]);
    return result;
  }

  checkPointsAreDuplicates(point1, point2) {
    const coordinates1 = point1.getGeometry().getCoordinates();
    const coordinates2 = point2.getGeometry().getCoordinates();
    return this.checkPointCoordinatesAreDuplicates(coordinates1, coordinates2);
  }

  checkPointCoordinatesAreDuplicates(pointCoordinates1, pointCoordinates2) {
    return (
      Math.abs(pointCoordinates1[0] - pointCoordinates2[0]) <= 1e-9 &&
      Math.abs(pointCoordinates1[1] - pointCoordinates2[1]) <= 1e-9
    );
  }

  getGeometryObject(features) {
    return features
      .map((feature) => this.geoJSON.writeFeatureObject(feature))
      .reduce(
        (result, featureObject) => {
          const { geometry, properties } = featureObject;
          if (geometry.type === POINT) {
            result.points.push({ ...geometry, ...properties });
          } else if (geometry.type === LINESTRING) {
            result.lines.push({ ...geometry, ...properties });
          } else if (geometry.type === POLYGON) {
            result.polygons.push({ ...geometry, ...properties });
          } else {
            log.warn('Unexpected geometry type: ' + geometry.type);
          }
          return result;
        },
        {
          points: [],
          lines: [],
          polygons: [],
        },
      );
  }

  getLinesObject = (geometry, oghObjectId) =>
    getSingleGeometryObject(LINES, geometry, oghObjectId);

  getPointsObject = (geometry, oghObjectId) =>
    getSingleGeometryObject(POINTS, geometry, oghObjectId);

  getPolygonsObject = (geometry, oghObjectId) =>
    getSingleGeometryObject(POLYGONS, geometry, oghObjectId);

  isGeometryMissing(geometry) {
    return (
      !geometry || (!geometry.lines && !geometry.points && !geometry.polygons)
    );
  }

  toFeatures(object) {
    const { points, lines, polygons, ...rest } = object;
    const features = [];

    points &&
      points.forEach((point) =>
        features.push(
          new ol.Feature({
            geometry: new ol.geom.Point(point.coordinates),
            ...rest,
          }),
        ),
      );

    lines &&
      lines.forEach((line) =>
        features.push(
          new ol.Feature({
            geometry: new ol.geom.LineString(line.coordinates),
            ...rest,
          }),
        ),
      );

    polygons &&
      polygons.forEach((polygon) =>
        features.push(
          new ol.Feature({
            geometry: new ol.geom.Polygon(polygon.coordinates),
            ...rest,
          }),
        ),
      );

    return features;
  }
}

// Transforms array of geometries to single geometry object (i.e. Polygon or MultiPolygon)
const getSingleGeometryObject = (type, geometry, filterCondition) => {
  let geomItems = geometry && geometry[type];
  if (!geomItems) {
    return null;
  }
  if (filterCondition) {
    geomItems = geomItems.filter(filterCondition);
  }
  if (geomItems.length === 0) {
    return null;
  }
  if (geomItems.length === 1) {
    return {
      type: geometryTypeDict[type].single,
      coordinates: geomItems[0].coordinates,
    };
  } else {
    if (type === POLYGONS) {
      const geojsonFeatures = geomItems.map((item) =>
        toGeojson([item.coordinates]),
      );

      const unionResultFeature = geojsonFeatures.reduce((result, item) =>
        union(result, item),
      );
      return unionResultFeature.geometry;
    } else {
      return {
        type: geometryTypeDict[type].multi,
        coordinates: geomItems.map((item) => item.coordinates),
      };
    }
  }
};

const toGeojson = (rings) => {
  return {
    type: 'Feature',
    geometry: {
      type: POLYGON,
      coordinates: rings,
    },
  };
};

const geojsonToRings = (geometry) => {
  if (geometry.type === 'Polygon') {
    return geometry.coordinates;
  } else if (geometry.type === 'MultiPolygon') {
    return geometry.coordinates.reduce((result, item) => {
      result.push(...item);
      return result;
    }, []);
  }
};
