import { useFetchOghTable } from 'app/pages/registry/ogh/api';
import { useParamsTable } from 'core/uiKit/components/tables/Table';

import { TableOghChildrenProps } from '../../types';
import { useFilterQueryParamsRelief } from './Filter.Relief';

/**
 * Setup Компонент таблицы __Элементы организации рельефа__.
 *
 * @param {TableOghChildrenProps} props - Пропсы.
 * @returns Данные для таблицы.
 */
export const useSetupTableRelief = ({
  typeId,
  parentTypeId,
}: TableOghChildrenProps) => {
  const { prepareFilters } = useFilterQueryParamsRelief();
  const { queryTable } = useParamsTable();

  const queryKey = [
    'TableRelief',
    {
      ...queryTable,
      ...prepareFilters,
      parentTypeId,
      typeId,
    },
  ];

  const { table, isLoading, count } = useFetchOghTable(queryKey);

  return {
    count,
    isLoading,
    table,
  };
};
