import { useMapContext } from 'app/components/map/useMapContext';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import { log } from 'core/utils/log';

import { createObjectForMapDTW } from '../helpers/createObjectForMapDTW';
import { postDistrictBoundaries } from './postDistrictBoundaries';

/**
 * Хук для получения геометрии и описания.
 *
 * @param {boolean} setHasDistrictBoundaries - Функция изменения состояния hasDistrictBoundaries.
 * @returns {{getDistrictBoundaries: Function}} - Метод для получения геометрии и описания.
 * @example
 *  const [hasDistrictBoundaries, setHasDistrictBoundaries] = useState(false);
 *
 *   const { getDistrictBoundaries } = useGetDistrictBoundaries(
 *     setHasDistrictBoundaries,
 *   );
 */
export const useGetDistrictBoundariesDTW = (setHasDistrictBoundaries) => {
  const { zoomToGeometries, mapService } = useMapContext();
  const [getDistrictBoundaries] = useMutationAdaptor(postDistrictBoundaries, {
    //

    /**
     * OnError..
     *
     * @returns {void}
     */
    onError: () => {
      log.info('postDistrictBoundaries не прошел');
    },

    /**
     * OnSuccess.
     *
     * @param {object} data - Данные.
     * @returns {void}
     */
    onSuccess: (data) => {
      const intersections = Object.entries(data);
      if (intersections.length > 0) {
        const items = intersections
          .map((item) => createObjectForMapDTW(item, mapService))
          .flat(1);

        mapService.updateGeometriesData({
          districts: items,
        });
        const instances = mapService.drawGeometries({ method: 'replaceAll' });

        zoomToGeometries(instances.districts);
        setHasDistrictBoundaries(true);
      }
    },
  });

  return { getDistrictBoundaries };
};
