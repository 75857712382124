
/* eslint-disable */
export default {
  intersections: {
    on: {
      title: 'Скрыть пересечения со смежными ОГХ',
      className: 'geom-button check-geometry-on material-icons',
    },
    off: {
      title: 'Показать пересечения со смежными ОГХ',
      className: 'geom-button check-geometry-off material-icons',
    },
  },
  neighbours: {
    on: {
      title: 'Скрыть смежные объекты',
      className: 'geom-button neighbours-on material-icons',
    },
    off: {
      title: 'Показать смежные объекты',
      className: 'geom-button neighbours-off material-icons',
    },
  },
  reon: {
    on: {
      title: 'Скрыть пересечения с данными ИС РЕОН',
      className: 'geom-button check-geometry-reon-on material-icons',
    },
    off: {
      title: 'Показать пересечения с данными ИС РЕОН',
      className: 'geom-button check-geometry-reon-off material-icons',
    },
  },
  children: {
    on: {
      title: 'Скрыть геометрию элементов текущего ОГХ',
      className: 'geom-button show-children-on material-icons',
    },
    off: {
      title: 'Показать геометрию элементов текущего ОГХ',
      className: 'geom-button show-children-off material-icons',
    },
  },
};
