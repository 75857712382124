import './EquipmentTechnikList.scss';

import * as grid from 'app/components/card/common/grid/index';
import { WithOldValueCheckbox } from 'app/components/common/CheckboxField';
import { WithOldValueSelect } from 'app/components/common/SelectField';
import DialogEditEquipmentTechnicList from 'app/components/dialogs/technicsRegistry/technics/DialogEditEquipmentTechnicList';
import { EDIT_TREE } from 'app/constants/techRequestTypes';
import { hasCapacity } from 'app/utils/tech/equipment';
import { ReactTable } from 'core/components';
import { ButtonGreen, ButtonRed } from 'core/newComponents/Button';
import IconButton, {
  iconButtonStyle,
  iconStyle,
} from 'core/newComponents/IconButton';
import { ChevronRightIcon, ExpandMoreIcon } from 'core/uiKit/material-ui';
import PropTypes from 'prop-types';
import React from 'react';

import {
  cellStyles,
  equipmentStyle,
  h2Style,
  headerColumnStyles,
  tableStyles,
  wrapperStyle,
} from './styles';

const columns = [
  {
    id: 'car_equipment_type_bu',
    name: 'Тип навесного оборудования',
    width: '70%',
  },
  {
    id: 'car_equipment_amount_bu',
    name: 'Количество',
    width: '30%',
  },
];

const propTypes = {
  carOwnership: PropTypes.array.isRequired,
  isAtributesBuPresent: PropTypes.bool,
  links_bu: PropTypes.array,
  list: PropTypes.array,
  mode: PropTypes.object,
  setList: PropTypes.func,
};

/**
 * Компонент EquipmentTechnikList.
 *
 * @returns {JSX.Element}
 */
export default class EquipmentTechnikList extends React.Component {
  state = {
    addDialogVisible: false,
    visible: new Set(),
  };

  /**
   * Метод toggleDialogVisible.
   *
   * @returns {void}
   */
  toggleDialogVisible = () => {
    this.setState({
      addDialogVisible: !this.state.addDialogVisible,
    });
  };

  /**
   * Метод addEquipment.
   *
   * @param {*} equipmentList - Any.
   * @returns {void}
   */
  addEquipment = (equipmentList) => {
    const { setList } = this.props;

    setList(equipmentList);
  };

  /**
   * Метод isEditable.
   *
   * @returns {boolean}
   */
  isEditable() {
    const { mode } = this.props;

    return mode && mode.requestType === EDIT_TREE && !mode.createMode;
  }

  /**
   * Метод toggleUaisTable.
   *
   * @returns {void}
   */
  toggleUaisTable = () => {
    const { isTableVisible } = this.state;

    this.setState({
      isTableVisible: !isTableVisible,
    });
  };

  /**
   * Метод deleteEquipment.
   *
   * @param {*} uuid - Any.
   * @returns {void}
   */
  deleteEquipment = (uuid) => {
    const { list, setList } = this.props;

    const filteredList = list.filter((item) => item.uuid !== uuid);
    setList(filteredList);
  };

  /**
   * Метод toggleElement.
   *
   * @param {*} uuid - Any.
   * @param {*} isVisible - Any.
   * @returns {void}
   */
  toggleElement = (uuid, isVisible) => {
    if (isVisible) {
      let visible = new Set(...this.state.visible);
      visible.delete(uuid);
      this.setState({ visible });
    } else {
      let visible = new Set(...this.state.visible);
      visible.add(uuid);
      this.setState({ visible });
    }
  };

  /**
   * Метод renderEquipmentItem.
   *
   * @param {*} equipment - Any.
   * @returns {JSX.Element}
   */
  renderEquipmentItem(equipment) {
    const { carOwnership } = this.props;
    const { name, uuid } = equipment;
    const editable = this.isEditable();
    const isVisible = this.state.visible.has(uuid);
    const deleteBtnStyle = {
      ...(!editable && { display: 'none' }),
      marginLeft: 20,
    };

    return (
      <div key={uuid}>
        <div style={equipmentStyle}>
          <IconButton
            iconClassName="material-icons"
            iconStyle={iconStyle}
            style={iconButtonStyle}
            onClick={() => this.toggleElement(uuid, isVisible)}
          >
            {isVisible ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          </IconButton>

          <h2 style={h2Style}>{name}</h2>

          <ButtonRed
            className="equipment-delete"
            style={deleteBtnStyle}
            type="button"
            onClick={() => this.deleteEquipment(uuid)}
          >
            Удалить
          </ButtonRed>
        </div>

        <div style={wrapperStyle}>
          {isVisible ? (
            <EquipmentTechnik
              carOwnership={carOwnership}
              equipment={equipment}
            />
          ) : null}
        </div>

        <hr />
      </div>
    );
  }

  /**
   * Метод renderUaisTable.
   *
   * @returns {JSX.Element}
   */
  renderUaisTable() {
    const { isTableVisible } = this.state;
    const { links_bu } = this.props;

    return (
      <div>
        <div style={equipmentStyle}>
          <IconButton
            iconClassName="material-icons"
            iconStyle={iconStyle}
            style={iconButtonStyle}
            onClick={this.toggleUaisTable}
          >
            {isTableVisible ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          </IconButton>

          <h2 style={h2Style}>Сведения УАИС БУ</h2>
        </div>

        <div style={wrapperStyle}>
          {isTableVisible ? (
            <ReactTable
              className="-striped -highlight"
              columns={columns.map(({ id, name, ...rest }) => ({
                Header: name,
                accessor: id,
                headerStyle: headerColumnStyles,
                style: cellStyles,
                ...rest,
              }))}
              data={links_bu || []}
              defaultPageSize={15}
              keyField={'id'}
              showPagination={false}
              stickHeader={true}
              style={tableStyles}
            />
          ) : null}
        </div>

        <hr />
      </div>
    );
  }

  /**
   * Метод renderEquipments.
   *
   * @returns {JSX.Element}
   */
  renderEquipments() {
    const { list } = this.props;

    return list.map((item) => this.renderEquipmentItem(item));
  }

  /**
   * Метод жизненyого цикла render.
   *
   * @returns {JSX.Element}
   */
  render() {
    const { list, isAtributesBuPresent } = this.props;
    const editable = this.isEditable();
    const addBtnStyle = {
      ...(!editable && { display: 'none' }),
      marginLeft: 20,
      marginTop: 20,
    };

    return (
      <div>
        <ButtonGreen
          className="equipment-add"
          style={addBtnStyle}
          type="button"
          onClick={this.toggleDialogVisible}
        >
          Добавить
        </ButtonGreen>

        <DialogEditEquipmentTechnicList
          list={list}
          showCondition={this.state.addDialogVisible}
          onAddEquipment={this.addEquipment}
          onCancel={this.toggleDialogVisible}
        />

        {isAtributesBuPresent && this.renderUaisTable()}

        {list && this.renderEquipments()}
      </div>
    );
  }
}

/**
 * Функция getStyles.
 *
 * @param {boolean} isShow - Флаг.
 * @returns {object}
 */
const getStyles = (isShow) => {
  return { display: isShow ? 'inline-block' : 'none' };
};

const DEFAULT_STYLES = {
  height: 'auto',
};

/**
 * Функция EquipmentTechnik.
 *
 * @param {*} props - Пропсы.
 * @param {*} props.equipment - Any.
 * @param {*} props.carOwnership - Any.
 * @returns {*}
 */
function EquipmentTechnik({ equipment, carOwnership }) {
  const elements = [
    {
      addition: {
        input: { value: equipment.car_equipment_ownership_id },
        options: carOwnership,
      },
      component: WithOldValueSelect,
      formValue: true,
      id: 'car_equipment_ownership_id',
      label: 'Тип принадлежности',
      name: 'car_equipment_ownership_id',
    },
    {
      addition: {
        input: { value: equipment.inventory_number },
        type: 'text',
      },
      component: grid.renderCustomTextField,
      formValue: true,
      id: 'inventory_number',
      label: 'Инвентарный номер',
      style: {
        ...DEFAULT_STYLES,
        marginTop: 4,
      },
    },
    {
      addition: {
        input: { value: equipment.equipment_type_name },
        type: 'text',
      },
      component: grid.renderCustomTextField,
      formValue: true,
      id: 'equipment_type_name',
      label: 'Тип навесного оборудования',
      style: {
        ...DEFAULT_STYLES,
        marginTop: 4,
      },
    },
    {
      addition: {
        digits: 2,
        input: { value: equipment.capacity },
        positive: true,
        type: 'decimal',
        zero: false,
      },
      component: grid.renderCustomTextField,
      formValue: true,
      id: 'capacity',
      label: 'Вместимость, куб. м.',
      style: {
        ...getStyles(hasCapacity(equipment.equipment_type_code)),
        ...DEFAULT_STYLES,
      },
    },
    {
      addition: {
        input: {
          value: equipment.car_equipment_owner_id
            ? equipment.car_equipment_owner_id.label
            : '',
        },
        type: 'text',
      },
      component: grid.renderCustomTextField,
      formValue: true,
      id: 'car_equipment_owner_name',
      label: 'Владелец',
      style: { ...DEFAULT_STYLES },
    },
    {
      addition: {
        label: 'Не подлежит оснащению ДУТ',
        type: 'text',
      },
      component: WithOldValueCheckbox,
      defaultValue: equipment.not_need_dut,
      id: 'not_need_dut',
      style: {
        ...DEFAULT_STYLES,
        minWidth: 224,
        width: 'auto',
      },
    },
    {
      addition: {
        label: 'Не подлежит оснащению ДРНО',
        type: 'text',
      },
      component: WithOldValueCheckbox,
      defaultValue: equipment.not_need_drno,
      id: 'not_need_drno',
      style: {
        ...DEFAULT_STYLES,
        minWidth: 224,
        width: 'auto',
      },
    },
  ];
  return grid.elementFormGrid(elements, 3, '', 1);
}

EquipmentTechnikList.propTypes = propTypes;
