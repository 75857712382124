import { useMapglContext } from 'core/uiKit/components/DTW/contexts/MapglContextProvider';
import { MapglContextValue } from 'core/uiKit/components/DTW/contexts/utils/types.d';
import { switchServisMap } from 'core/utils/map/switchServisMap';
import { useContext } from 'react';

import { WithMapContext } from './withMap';

interface MapType {
  drawCurrentObjectsGeometry: (items: unknown[]) => void;
  drawParentGeometry: (items: GType[]) => void;
  drawIntersectionsGeometry: (items: unknown[]) => void;
  drawOrdersGeometry: (items: unknown[]) => void;
  drawChildrenGeometry: (items: unknown[]) => void;
  drawReonGeometry: (items: unknown[]) => void;
  drawNeighboursGeometry: (items: unknown[]) => void;
  drawDistrictBoundaries: (items: unknown[]) => void;
  clearLayer: (layerId: string) => void;
  // getGeometry: (recordId: number) => void;
  getRawGeometry: (recordId: number) => void;
  callMapFunction: (functionName: string, currentCard: object) => void;
  drawDiffCopyObjectGeometry: (items: unknown[]) => void;
  drawDiffOriginalObjectGeometry: (items: unknown[]) => void;
  zoomToDiff: () => void;
  clearDiff: () => void;
  isMapLoaded: boolean;
}

export interface GType {
  id: number;
  hint: {
    object_attribute_hint: {
      key: string;
      value: string;
    }[];
    object_attribute_hint_short: [
      {
        key: string;

        value: string;
      },
    ];
  };
  lines: null;
  points: null;
  root_id: number;
  type_id: number;
  polygons: [
    {
      crs: {
        type: string;
        properties: {
          name: string;
        };
      };
      type: string;
      coordinates: [[[number, number]]];
    },
  ];
}

/**
 * Хук получения данных карты.
 *
 * @returns MapType.
 */
export const useMapContext = (): MapType & MapglContextValue => {
  // @ts-ignore
  return switchServisMap({
    dtw: useMapglContext(),
    egip: useContext(WithMapContext),
  });
};
