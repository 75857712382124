import 'app/components/card/tech/car/components/Badges/WarningBadgeProcessApproving/WarningBadge.scss';

import { useGetRegistryApprovalsTechTable } from 'app/components/card/tech/car/components/Tables/ProcessApprovingTable/api';
import { Badge } from 'core/uiKit/material-ui';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

/**
 * Компонент WarningBadge.
 *
 * @param {object} props - Дети.
 * @param {React.ReactNode} props.children - Дети.
 * @returns {JSX.Element|*}
 */
export const WarningBadge = ({ children }) => {
  const root_id = useSelector((state) => state?.card.root.root_id);

  const { data: { data: { table = [] } = {} } = {} } =
    useGetRegistryApprovalsTechTable({ root_id });

  const count = useMemo(() => {
    return table.filter(({ state_name }) => state_name === 'На согласовании')
      .length;
  }, [table]);

  return count ? (
    <Badge badgeContent={count} color={'error'} className={'warning-badge'}>
      {children}
    </Badge>
  ) : (
    children
  );
};
