import { Cell } from 'types/table';

/**
 * Колонка __ИД статуса__.
 *
 * @type {{
 * accessor: 'state_id',
 * Header: 'ИД статуса',
 * sortable: true,
 * }}
 * @augments Cell
 */
export const state_id: Cell = {
  Header: 'ИД статуса',
  accessor: 'state_id',
  sortable: true,
};
