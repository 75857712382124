import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { RANDOM_BOOL } from 'core/forTesting/constants';
import { vi } from 'vitest';

import { useSetupImprovementObjectCategoryIdFieldOzn } from './useSetup.ImprovementObjectCategoryId.Field.Ozn';

vi.mock('app/api/hooks/useGetCurrentCard');
vi.mock('app/pages/cardsOgh/hooks/useMode');

/**
 * Mock Default.
 *
 * @returns {void}
 */
const mockDefault = () => {
  useGetCurrentCard.mockReturnValue({});
  useMode.mockReturnValue({});
};

describe('🐛 spec useSetup.ImprovementObjectCategoryId.Field.Ozn', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock

    //🔥 Act
    const res = useSetupImprovementObjectCategoryIdFieldOzn();

    //❓ Assert
    expect(res).toStrictEqual({
      editMode: undefined,
      filter: expect.any(Function),
    });
  });
  it('🧪 editMode', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();

    useMode.mockReturnValue({ editMode: RANDOM_BOOL });
    // 🧹 clear mock

    //🔥 Act
    const res = useSetupImprovementObjectCategoryIdFieldOzn();

    //❓ Assert
    expect(res).toStrictEqual({
      editMode: RANDOM_BOOL,
      filter: expect.any(Function),
    });
  });

  it('🧪 filter', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock
    useMode.mockReturnValue({ editMode: RANDOM_BOOL });
    useGetCurrentCard.mockReturnValue({ type_id: 33 });

    const { filter } = useSetupImprovementObjectCategoryIdFieldOzn();
    const data = [
      {
        id: 1,
        parent_ogh_object_type_list: [33],
      },

      {
        id: 2,
        parent_ogh_object_type_list: [33, 34],
      },
      {
        id: 3,
        parent_ogh_object_type_list: [3, 34],
      },
    ];

    //🔥 Act
    const res = filter(data);

    //❓ Assert
    expect(res).toStrictEqual([
      {
        id: 1,
        parent_ogh_object_type_list: [33],
      },
      {
        id: 2,
        parent_ogh_object_type_list: [33, 34],
      },
    ]);
  });
});
