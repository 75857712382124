/* eslint-disable */
import NavItem from 'app/components/common/NavBar/NavItem';
import { lazy, useEffect } from 'react';
import { useUser } from 'app/pages/providers';
import { pathChangeLogRegistry } from 'app/pages/report/ChangeLogRegistry/path.ChangeLogRegistry';

function lazyWithPreload(factory) {
  const Component = lazy(factory);
  Component.preload = factory;
  return Component;
}

const ChangeLogRegistry = lazyWithPreload(() =>
  import('app/pages/report/ChangeLogRegistry'),
);

 
export const ReportNavChangelog = () => {
  const { hasPermission } = useUser();

  const isShow = hasPermission(pathChangeLogRegistry.privilege);

  useEffect(() => {
    isShow && ChangeLogRegistry.preload();
  }, [isShow]);

  return isShow ? (
    <NavItem link={`/report/${pathChangeLogRegistry.path}`}>
      {pathChangeLogRegistry.name}
    </NavItem>
  ) : null;
};
