import { toDate } from 'app/utils/date/toDate';
import { useFieldRF } from 'core/form/reduxForm/hooks/useField.RF';
import { toast } from 'core/uiKit/components/Toast';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery/useMutationAdaptor';

import { sendRepairsInfoListPlan } from '../api/sendRepairsInfoListPlan';
import { REPAIRS_INFO_LIST_PLAN_NAME } from '../RepairsPlan.Tab';

/**
 * Функция получения id файла.
 *
 * @returns {number}
 */
const getIdRepairsInfoListPlan = () => {
  return (
    Math.floor(Math.random() * 10000000000000) +
    Math.floor(Math.random() * 10000000000000)
  );
};

/**
 * Хук получения id файла.
 *
 * @param {Function} params - Функция закрыть диалог.
 * @param {Function} params.closeDialog - Функция закрыть диалог.
 * @param {Function} params.value - Значение таблицы.
 * @param {Function} params.onChange - Функция изменения формы.
 * @returns {{isLoading: boolean, sendSubmit: Function}}
 */
export const useSendRepairsInfoListPlan = ({ closeDialog }) => {
  const { value, onChange } = useFieldRF(REPAIRS_INFO_LIST_PLAN_NAME);

  const [sendSubmit, { isLoading }] = useMutationAdaptor(
    sendRepairsInfoListPlan,
    {

      /**
       * Ошибочный кейс.
       *
       * @param {Error} error - Ошибка.
       */
      onError: (error) => {
        toast.errorDying(error?.message ?? 'Ошибка при добавлении данных');
      },
      // onSuccess: (value, dataRequest) => {}

      /**
       * Успешный кейс.
       *
       * @param {object} res - Ответ.
       */
      onSuccess: (res) => {
        closeDialog();
        onChange([
          ...value,
          {
            date_end: toDate(res.date_end),
            date_start: toDate(res.date_start),
            file_list: [
              {
                files: {
                  file_id: res.resFile.fileId,
                  file_name: res.file.name,
                },
              },
            ],
            id: getIdRepairsInfoListPlan(),
            name: res.name,
            property: res.property,
          },
        ]);
      },
    },
  );

  return { isLoading, sendSubmit };
};
