import {
  adjacentOptions,
  allChildrenOptions,
  changingOptions,
  childOptions,
  clickedOptions,
  copyDiffOptions,
  districtOptions,
  editingOptions,
  hoveredOptions,
  intersectionOptions,
  originalDiffOptions,
  parentOptions,
  reonAreaOptions,
  selectedOptions,
} from '../../GeometryFactory/utils';

export const filters = {
  adjacent: [
    'all',
    ['match', ['sourceAttr', 'isEditing'], [false], true, false],
    ['match', ['get', 'layerType'], ['adjacent'], true, false],
    ['match', ['get', 'isSelected'], [false], true, false],
    ['match', ['get', 'isChanging'], [false], true, false],
  ],
  allChildren: [
    'all',
    ['match', ['sourceAttr', 'isEditing'], [false], true, false],
    ['match', ['get', 'layerType'], ['allChildren'], true, false],
    ['match', ['get', 'isChanging'], [false], true, false],
  ],
  changing: [
    'all',
    ['match', ['sourceAttr', 'isEditing'], [true], true, false],
    ['match', ['get', 'layerType'], ['children'], true, false],
    ['match', ['get', 'isChanging'], [true], true, false],
  ],
  children: [
    'all',
    ['match', ['sourceAttr', 'isEditing'], [false], true, false],
    ['match', ['get', 'layerType'], ['children'], true, false],
    ['match', ['get', 'isSelected'], [false], true, false],
    ['match', ['get', 'isChanging'], [false], true, false],
  ],
  clicked: [
    'all',
    ['match', ['sourceAttr', 'isEditing'], [false], true, false],
    ['match', ['get', 'isClicked'], [true], true, false],
    ['match', ['get', 'isChanging'], [false], true, false],
  ],
  copyDiff: [
    'all',
    ['match', ['sourceAttr', 'isEditing'], [false], true, false],
    ['match', ['get', 'layerType'], ['copyDiff'], true, false],
    ['match', ['get', 'isSelected'], [false], true, false],
    ['match', ['get', 'isChanging'], [false], true, false],
  ],
  districts: [
    'all',
    ['match', ['sourceAttr', 'isEditing'], [false], true, false],
    ['match', ['get', 'layerType'], ['districts'], true, false],
    ['match', ['get', 'isSelected'], [false], true, false],
    ['match', ['get', 'isChanging'], [false], true, false],
  ],
  editing: [
    'all',
    ['match', ['sourceAttr', 'isEditing'], [true], true, false],
    ['match', ['get', 'layerType'], ['children', 'parent'], true, false],
    ['match', ['get', 'isChanging'], [false], true, false],
  ],
  hovered: [
    'all',
    ['match', ['sourceAttr', 'isEditing'], [false], true, false],
    ['match', ['get', 'isClicked'], [false], true, false],
    ['match', ['get', 'isHovered'], [true], true, false],
    ['match', ['get', 'isChanging'], [false], true, false],
  ],
  intersections: [
    'all',
    ['match', ['sourceAttr', 'isEditing'], [false], true, false],
    ['match', ['get', 'layerType'], ['intersections'], true, false],
    ['match', ['get', 'isSelected'], [false], true, false],
    ['match', ['get', 'isChanging'], [false], true, false],
  ],
  originalDiff: [
    'all',
    ['match', ['sourceAttr', 'isEditing'], [false], true, false],
    ['match', ['get', 'layerType'], ['originalDiff'], true, false],
    ['match', ['get', 'isSelected'], [false], true, false],
    ['match', ['get', 'isChanging'], [false], true, false],
  ],
  parent: [
    'all',
    ['match', ['sourceAttr', 'isEditing'], [false], true, false],
    ['match', ['get', 'layerType'], ['parent'], true, false],
    ['match', ['get', 'isSelected'], [false], true, false],
    ['match', ['get', 'isChanging'], [false], true, false],
  ],
  reonArea: [
    'all',
    ['match', ['sourceAttr', 'isEditing'], [false], true, false],
    ['match', ['get', 'layerType'], ['reonArea'], true, false],
    ['match', ['get', 'isSelected'], [false], true, false],
    ['match', ['get', 'isChanging'], [false], true, false],
  ],
  selected: [
    'all',
    ['match', ['sourceAttr', 'isEditing'], [false], true, false],
    ['match', ['get', 'isHovered'], [false], true, false],
    ['match', ['get', 'isChanging'], [false], true, false],
    ['match', ['get', 'isSelected'], [true], true, false],
  ],
};

export const layerStyles = {
  adjacent: {
    filter: filters.adjacent,
    id: 'adjacent',
    style: {
      color: adjacentOptions.Polyline.color,
      width: 3,
    },
    type: 'line',
  },

  allChildren: {
    filter: filters.allChildren,
    id: 'allChildren',
    style: {
      color: allChildrenOptions.Polyline.color,
      width: 3,
    },
    type: 'line',
  },

  children: {
    filter: filters.children,
    id: 'children-!editing',
    style: {
      color: childOptions.Polyline.color,
      width: 3,
    },
    type: 'line',
  },
  childrenChanging: {
    filter: filters.changing,
    id: 'children-changing',
    style: {
      color: changingOptions.Polyline.color,
      width: 3,
    },
    type: 'line',
  },

  childrenEditing: {
    filter: filters.editing,
    id: 'children-editing',
    style: {
      color: editingOptions.Polyline.color,
      width: 3,
    },
    type: 'line',
  },

  clicked: {
    filter: filters.clicked,
    id: 'clicked',
    style: {
      color: clickedOptions.Polyline.color,
      width: 3,
    },
    type: 'line',
  },

  copyDiff: {
    filter: filters.copyDiff,
    id: 'copyDiff',
    style: {
      color: copyDiffOptions.Polyline.color,
      width: 3,
    },
    type: 'line',
  },
  districts: {
    filter: filters.districts,
    id: 'districts',
    style: {
      color: districtOptions.Polyline.color,
      width: 3,
    },
    type: 'line',
  },

  hovered: {
    filter: filters.hovered,
    id: 'hovered',
    style: {
      color: hoveredOptions.Polyline.color,
      width: 3,
    },
    type: 'line',
  },

  intersections: {
    filter: filters.intersections,
    id: 'intersections',
    style: {
      color: intersectionOptions.Polyline.color,
      width: 3,
    },
    type: 'line',
  },

  originalDiff: {
    filter: filters.originalDiff,
    id: 'originalDiff',
    style: {
      color: originalDiffOptions.Polyline.color,
      width: 3,
    },
    type: 'line',
  },

  parent: {
    filter: filters.parent,
    id: 'parent-!editing',
    style: {
      color: parentOptions.Polyline.color,
      width: 3,
    },
    type: 'line',
  },

  parentChanging: {
    filter: filters.changing,
    id: 'parent-changing',
    style: {
      color: changingOptions.Polyline.color,
      width: 3,
    },
    type: 'line',
  },

  parentEditing: {
    filter: filters.editing,
    id: 'parent-editing',
    style: {
      color: selectedOptions.Polyline.color,
      width: 3,
    },
    type: 'line',
  },

  reonArea: {
    filter: filters.reonArea,
    id: 'reonArea',
    style: {
      color: reonAreaOptions.Polyline.color,
      width: 3,
    },
    type: 'line',
  },

  selected: {
    filter: filters.selected,
    id: 'selected',
    style: {
      color: selectedOptions.Polyline.color,
      width: 3,
    },
    type: 'line',
  },
};
