import { INSTALL_DRNO_NAME, InstallDrnoFF } from 'core/form/finalForm/fields';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';
import { StringParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

/**
 * Не подлежит оснащению ДРНО
 * installDrno.
 *
 * @param {*} props - Properties.
 * @returns {JSX.Element}
 */
export const InstallDrnoReportFilter = (props) => (
  <InstallDrnoFF {...props} className={filterInput} />
);

export { INSTALL_DRNO_NAME };

/**
 * InstallDrnoParams..
 *
 * @returns {*}
 */
export const installDrnoReportParams = () => {
  addPreparation(INSTALL_DRNO_NAME, (value) => value, 'notNeedDrno');
  return {
    [INSTALL_DRNO_NAME]: StringParam,
  };
};
