import { OKRUG_LIST_NAME } from 'core/uiKit/preparedInputs/selects/multiSelect/OkrugList';

 
/**
 * Добавление initialState к полю OkrugListField.
 *
 * @param {object} result - Параметры в редьюсере.
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {*} - Возвращает объект и записывает в initialValues значение поля OkrugListField.
 * @example -
 *  mapStateToPropsReducers: [
 *     okrugListFieldReducer,
 *   ],
 */
export function okrugListFieldReducer(
  result,
  state,
  props,
) {
  const { card } = props;
  return {
    ...result,
    initialValues: {
      ...result.initialValues,
      [OKRUG_LIST_NAME]: (
        card?.attribute?.[OKRUG_LIST_NAME] || []
      ).map(({ bti_object }) => bti_object),
    },
  };
}
