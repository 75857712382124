import ToggleButton from 'app/components/card/common/MapEditPanel/buttons/ToggleButton';
import toggleContent from 'app/components/card/common/MapEditPanel/content/toggle';
import { CHILDREN } from 'app/constants/layers';
import React from 'react';

import { DialogShowChildrenButton } from './Dialog.ShowChildrenButton';
import { useShowChildrenButton } from './useShowChildrenButton';

/**
 * Кнопка отображения геометрии дочерних элементов.
 *
 * @returns {JSX.Element}
 */
export const ShowChildrenButton = () => {
  const {
    active,
    children,
    clearLayer,
    closeDialog,
    getGeometryLoad,
    isMapLoaded,
    isOpen,
    openDialog,
    recordId,
    setActive,
  } = useShowChildrenButton();

  /**
   * Функция отображения геометрии.
   *
   * @returns {Promise<void>}
   */
  const showChildrenByButton = async () => {
    if (children) {
      if (active) {
        clearLayer(CHILDREN);
        setActive(false);
      } else {
        await getGeometryLoad({ par_id: recordId });
        setActive(true);
      }
    } else {
      openDialog();
    }
  };

  return (
    <>
      <ToggleButton
        disabled={!isMapLoaded}
        active={active}
        content={toggleContent.children}
        onClick={showChildrenByButton}
      />
      <DialogShowChildrenButton isOpen={isOpen} closeDialog={closeDialog} />
    </>
  );
};
