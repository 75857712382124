/* eslint-disable */
import React from 'react';

const styleWrapperTable = {
  overflow: 'auto',
  maxHeight: '50vh',
};

 
export const TableWrapper = ({ children }) => {
  return <div style={styleWrapperTable}>{children}</div>;
};
