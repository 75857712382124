import moment from 'moment';

/**
 * Получение даты за вычетом дней в формате Fri May 12 2023 10:13:35 GMT+0400 (GMT+04:00).
 *
 * @param {number} day - День (вычитает дни из даты).
 * @returns - Вчерашняя дата или дата за вычетом дней.
 */
export const getDateMinusDays = (day = 1): Date => {
  return moment().subtract(day, 'day').toDate();
};
