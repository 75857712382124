import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';
import { SelectPreparedProps } from 'types/inputs';

export const PLANAR_STRUCTURE_TYPE_ID_NAME = 'planar_structure_type_id';

export interface IPlanarStructureTypeIdData {
  code?: string;
  id?: number;
  name?: string;
  parent_ogh_object_type_list?: number[];
}

export interface IPlanarStructureTypeIdProps extends SelectPreparedProps {
  filter?: (data: IPlanarStructureTypeIdData[]) => IPlanarStructureTypeIdData[];
}

/**
 * Назначение  Select.
 *
 * `type = Select`.
 *
 * @param props - Пропсы.
 * @returns JSX.Element.
 */
export const PlanarStructureTypeId = (props: IPlanarStructureTypeIdProps) => {
  const dict = 'planar_structure_type';
  const { data = [] } = useGetDictionary<IPlanarStructureTypeIdData[]>(dict);

  return (
    <Select
      {...props}
      options={data}
      label={'Назначение'}
      name={PLANAR_STRUCTURE_TYPE_ID_NAME}
    />
  );
};
