/* eslint-disable */
import React from 'react';

import { filterInput } from '../filterInputsClasses';
import { ENTITY_CODE_NAME, EntityCodeFF } from 'core/form/finalForm/fields';
import { StringParam } from 'use-query-params';

 
export { ENTITY_CODE_NAME };
 
 
/**
 *     Сущность
 *     entityCode
 */
 
export const EntityCodeFilter = (props) => (
  <EntityCodeFF {...props} className={filterInput} />
);

 
export const entityCodeParams = () => {
  return {
    [ENTITY_CODE_NAME]: StringParam,
  };
};
