import { AbutmentTypeNameFF } from 'core/form/finalForm/fields/prepared/selects/simple/AbutmentTypeName.FF';

import { useSetupAbutmentTypeNameFFField } from './useSetup.AbutmentTypeName.FF.Field';

/**
 * Setup Тип элемента сопряжения"  "Элементы сопряжения поверхностей".
 *
 * @returns {JSX.Element}
 */
export const AbutmentTypeNameFFField = () => {
  const { editMode, filter } = useSetupAbutmentTypeNameFFField();

  return (
    <AbutmentTypeNameFF disabled={!editMode} filter={filter} withAll={false} />
  );
};
