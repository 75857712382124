import './FilterForm.scss';

import { Button } from 'core/uiKit/components/buttons/Button';
import React from 'react';

 
/**
 * Фильтры.
 *
 * @param {object} props - Пропсы.
 * @param {Function} props.submitSearch - Функция сабмит.
 * @param {Function} props.resetTable - Функция сброса формы.
 * @param {React.ReactComponentElement} props.children - Реакт компонент.
 * @returns {JSX.Element} - Обертка формы.
 * @example ----------
 */
export const FilterForm = ({ submitSearch, resetTable, children }) => {
  return (
    <fieldset className={'filter-card__wrapper'}>
      <div className={'filter-card-fields__wrapper'}>{children}</div>

      <div className="filter-card-btn__wrapper">
        <Button variant={'contained'} color={'primary'} onClick={submitSearch}>
          Поиск
        </Button>
        <Button
          className="ms-3"
          color={'primary'}
          id={'clear-form'}
          variant={'outlined'}
          onClick={resetTable}
        >
          Сбросить
        </Button>
      </div>
    </fieldset>
  );
};
