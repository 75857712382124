import { fetchIntersections } from 'app/utils/card/checkGeometry/api/fetchIntersections';
import { getLengthGeometry } from 'app/utils/card/checkGeometry/helpers/getLengthGeometry';
import messages from 'app/utils/card/checkGeometry/messages';

/**
 * Функция getIntersectionsGeometries.
 *
 * @param {object} params - Параметры.
 * @returns {*}
 */
export async function getIntersectionsGeometries(params) {
  const { data, fetchCheckGeomContains, showAlert, draw } = params;
  if (fetchCheckGeomContains) {
    const object = await fetchCheckGeomContains(data);
    const length = getLengthGeometry(object);

    if (10 < length) {
      showAlert(messages.errorMore);
    } else if (length) {
      showAlert(messages.errorOther);
      draw(object.geometry);
    } else {
      const length = await fetchIntersections(params);
      if (!length) {
        showAlert(messages.success);
      }
    }
  } else {
    await fetchIntersections(params);
  }
}
