import { get } from 'app/api/crud/get';

/**
 * Автокомплит запрос на Район.
 *
 * @param data - Данные.
 * @param data.maxRows - Maximum number of rows.
 * @param data.startsWith - Start with.
 * @param data.okrug_id - Округ. Дополнительный параметр для запроса.
 * @returns Словарь.
 */
export const fetchDistrictDetails = (data: {
  maxRows: number;
  okrug_id: number;
  startsWith: string;
}): Promise<{ id: number; name: string }[]> =>
  get<{ id: number; district_name: string }[]>(
    '/autocomplete/district_details',
    data,
  ).then((res) =>
    res.map((item) => ({ id: item.id, name: item.district_name })),
  );
