/* eslint-disable */
import React from 'react';

import {
  CAR_FUNC_TYPES_NAME,
  CarFuncTypesFF,
} from 'core/form/finalForm/fields';
import { filterInput } from '../filterInputsClasses';
import { ArrayParam, withDefault } from 'use-query-params';

 
 
/**
 * Тип техники
 * car_func_type
 */

 
export const CarFuncTypesFilter = () => (
  <CarFuncTypesFF className={filterInput} />
);

 
export { CAR_FUNC_TYPES_NAME };

 
export const carFuncTypeParams = {
  [CAR_FUNC_TYPES_NAME]: withDefault(ArrayParam, []),
};
