import { getValidator } from 'core/form/finalForm/utils/validate';
/* eslint-disable */
import { SEND_REASON_NAME } from 'core/uiKit/preparedInputs/selects/multiSelect/SendReason';

/**
 *
 * @param values
 */
export const formValidation = (values) => {
  const { setRequired, hasErrors, getErrors } = getValidator(values);

  setRequired(SEND_REASON_NAME);

  return hasErrors() ? getErrors() : null;
};
