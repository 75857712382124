 
/* eslint-disable */
/**
 * генерим случайное слово заданной длины. по умолчанию 6
 * @param length
 * @returns {string}
 */
 
export const generateRandomWord = (length = 6) => {
  const abc = 'abcdefghijklmnopqrstuvwxyz';
  let rs = '';
  while (rs.length < length) {
    rs += abc[Math.floor(Math.random() * abc.length)];
  }

  return rs;
};
