import { NO_CALC_NAME } from 'core/form/finalForm/fields/prepared/checkboxes/NoCalc.FF';
import { IS_DIFF_HEIGHT_MARK_NAME } from 'core/form/reduxForm/fields';
import { FACE_AREA_NAME } from 'core/uiKit/preparedInputs/numberField/FaceArea';
import { CLEAN_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CleaningTypeId';
import { COATING_FACE_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingFaceTypeId';
import { COATING_GROUP_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingGroupId';
import { COATING_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingTypeId';
import { ENGINEER_BUILDING_TYPE_ID } from 'core/uiKit/preparedInputs/selects/simpleSelect/EngineerBuildingTypeId';
import { MATERIAL_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/MaterialId';
import { UNIT_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/UnitId';
import { QUANTITY_NAME } from 'core/uiKit/preparedInputs/textField/Quantity';

import { getAttributeSubmit } from '../helpers/getAttributeSubmit';
import { useInitialValueEngineerBuilding } from './hooks/useInitialValue.EngineerBuilding';

const mapAttribute = [
  ENGINEER_BUILDING_TYPE_ID,
  QUANTITY_NAME,
  UNIT_ID_NAME,
  COATING_GROUP_ID_NAME,
  COATING_TYPE_ID_NAME,
  COATING_FACE_TYPE_ID_NAME,
  FACE_AREA_NAME,
  CLEAN_TYPE_ID_NAME,
  MATERIAL_ID_NAME,
  NO_CALC_NAME,
  IS_DIFF_HEIGHT_MARK_NAME,
];

/**
 * Setup Инженерные сооружения.
 *
 * @returns {JSX.Element}
 */
export const useSetupEngineerBuilding = () => {
  const initialValues = useInitialValueEngineerBuilding();

  /**
   * Функция подготовка данных для Атрибутов.
   *
   * @param {*} values - Значения формы.
   * @returns {*} Атрибуты.
   */
  const preparingDataForAttributes = (values) => {
    return getAttributeSubmit(values, mapAttribute);
  };

  return { initialValues, preparingDataForAttributes };
};
