import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useMapContext } from 'app/components/map/useMapContext';
import { LINESTRING, POINT, POLYGON } from 'app/constants/geometryTypes';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useState } from 'react';

/**
 * Setup for Компонент PanelRightCard.
 *
 * @param {{allowedGeometryTypes: object}} props - Жопа.
 * @returns {JSX.Element} - JSX.
 */
export const useSetupPanelRightCard = ({ allowedGeometryTypes }) => {
  const [activeGeometry, setActiveGeometry] = useState(null);
  const { editMode } = useMode();
  const context = useMapContext();
  const currentCard = useGetCurrentCard();

  const polygonAllowed = allowedGeometryTypes.includes(POLYGON);
  const pointAllowed = allowedGeometryTypes.includes(POINT);
  const lineAllowed = allowedGeometryTypes.includes(LINESTRING);

  /**
   * Функция onDrawClick.
   *
   * @param {boolean} active - Флаг активности.
   * @param {*} geometryType - Тип геометрии.
   * @param {Function} drawFunction - Функция отрисовки.
   * @returns {void} - Nothing.
   * @example  <DrawButton
   *         active={activeGeometry === POINT}
   *         disabled={!editMode || !pointAllowed}
   *         geometryType={POINT}
   *         onClick={onDrawClick}
   */
  const onDrawClick = (active, geometryType, drawFunction) => {
    if (active) {
      stopDrawing();
    } else {
      context.callMapFunction(drawFunction, currentCard);
      changeActiveGeometry(geometryType);
    }
  };

  /**
   * Функция changeActiveGeometry.
   *
   * @param {*} activeGeometry - Геометрия.
   * @returns {void} - Nothing.
   */
  const changeActiveGeometry = (activeGeometry) => {
    setActiveGeometry(activeGeometry);
  };

  /**
   * Функция stopDrawing.
   *
   * @returns {void} - Nothing.
   */
  const stopDrawing = () => {
    context.callMapFunction('stopDrawing', currentCard);
    changeActiveGeometry(null);
  };

  /**
   * Функция получения текущей геометрии.
   *
   * @returns {*} Геометрия.
   */
  const getGeometry = () => {
    return context.getGeometry(currentCard.recordId);
  };

  return {
    activeGeometry,
    context,
    currentCard,
    editMode,
    getGeometry,
    lineAllowed,
    onDrawClick,
    pointAllowed,
    polygonAllowed,
  };
};
