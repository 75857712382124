/* eslint-disable */
import React from 'react';

import { OoptMeaningFF, OOPT_MEANING_NAME } from 'core/form/finalForm/fields';
import { NumberParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

 
 
/**
 *  field: 'ooptMeaning',
 *  label: 'Значение ООПТ',
 */
 
export const OoptMeaningFilter = () => (
  <OoptMeaningFF className={filterInput} />
);

 
export const ooptMeaningParams = {
  [OOPT_MEANING_NAME]: NumberParam,
};
