 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */

//  во всех дочерних реестрах ОДХ "Наименование объекта ОДХ",
//  в дочерних элементах ДТ/ОО - "Наименование родительского объекта"
 
/* eslint-disable */
/**
 * Колонка __Наименование родительского объекта__.
 *
 * @type {{  accessor: 'parent_object_name',  Header: 'Наименование родительского объекта'}}
 * @augments Cell
 */
export const parent_object_name = {
  accessor: 'parent_object_name',
  Header: 'Наименование родительского объекта',
  sortable: true,
};
