import './style.scss';

import { Paper } from 'core/uiKit/components/Paper';
import { TypeNavigationBar } from 'core/uiKit/components/TypeNavigationBar';
import React, { useState } from 'react';

import { useGetBreadcrumbs } from './useGetBreadcrumbs';

/**
 * Шапка реестров.
 *
 * @param {*} props - Properties.
 * @param {JSX.Element} props.Filter - Фильтр.
 * @param {Array} props.breadcrumbs - Хлебные крошки.
 * @returns {JSX.Element}
 */
export const Header = ({ Filter, breadcrumbs: breadcrumbsTmp }) => {
  const [isShowFilter, setShowFilter] = useState(true);
  const breadcrumbs = useGetBreadcrumbs(breadcrumbsTmp);

  /**
   * Функция скрытия фильтра.
   *
   * @returns {void}
   */
  const toggleShowFilter = () => {
    setShowFilter(!isShowFilter);
  };

  const MyFilter = typeof Filter === 'function' ? Filter() : Filter;

  return (
    <Paper className={'header-control px-5'} dark={true}>
      <TypeNavigationBar
        isHaveFilter={Boolean(MyFilter)}
        breadcrumbs={breadcrumbs}
        isShowFilter={isShowFilter}
        toggleShowFilter={toggleShowFilter}
      />
      <div style={{ width: '100%' }}>{isShowFilter ? MyFilter : null}</div>
    </Paper>
  );
};
