/* eslint-disable  */
import {
  ODH_SIDE_ID_NAME,
  OdhSideId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/OdhSideId';
import { Field } from 'redux-form';

/**
 *
 * @param root0
 * @param root0.input
 */
const OdhSideIdWithReduxForm = ({ input, ...props }) => {
  return <OdhSideId {...input} {...props} />;
};

/* eslint-disable */
export const OdhSideIdRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={ODH_SIDE_ID_NAME}
      component={OdhSideIdWithReduxForm}
    />
  );
};
