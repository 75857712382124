import { isArray } from 'lodash';
/* eslint-disable */
export function getRottenTypeMessage(elements) {
  let message = '';
  if (isArray(elements)) {
    elements.forEach((item) => {
      message = `${message}<li>${item.typeName}</li>`;
    });
  }
  return message;
}
