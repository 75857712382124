/* eslint-disable  */
import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import PropTypes from 'prop-types';
import React from 'react';

import { mapCoatingType } from './mapCoatingType';

export const COATING_TYPE_ID_NAME = 'coating_type_id';

/**
 * Вид покрытия на путях (уточнение).
 *
 * 'type = Select`.
 * `name = coating_type_id`
 * `dict = oghStatuses`.
 *
 * @param {object} props - Properties.
 * @param {string} [props.label] - Label.
 * @param {object} [props.otherProps] - Other properties.
 * @returns {JSX.Element}
 */
export const CoatingTypeId = ({
  label = 'Вид покрытия (уточнение)',
  ...otherProps
}) => {
  const dict = 'coatingType';
  const { data = [] } = useGetDictionary(dict);

  const options = mapCoatingType(data) || [];

  return (
    <Select
      {...otherProps}
      {...{ label, options }}
      name={COATING_TYPE_ID_NAME}
    />
  );
};

CoatingTypeId.propTypes = {
  /**
   * Выбор темной темы.
   */
  dark: PropTypes.bool,

  /**
   * Заблокировать на редактирование.
   */
  disabled: PropTypes.bool,

  /**
   * Вывод ошибки.
   */
  errorText: PropTypes.string,

  /**
   * Функция для фильтрации options.
   */
  filter: PropTypes.func,

  /**
   * Вывод вспомогательного текста.
   */
  helperText: PropTypes.string,

  /**
   * Функция вызова когда выберется итем.
   */
  onChange: PropTypes.func,

  /**
   * Вывод информации об обязательном поле.
   */
  required: PropTypes.bool,

  /**
   * Значение.
   */
  value: PropTypes.number,
};

CoatingTypeId.defaultProps = {
  dark: false,
  disabled: false,
  errorText: '',

  /**
   *
   * @param _
   */
  filter: (_) => _,
  helperText: '',

  /**
   *
   * @param _
   */
  onChange: (_) => _,
  required: false,
  value: [],
};
