import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { IsOrphanObjectRF } from 'core/form/reduxForm/fields/prepared/checkboxes/IsOrphanObject.RF';
import React from 'react';

/**
 * Чек бокс __Объект переданный в эксплуатацию.
 *
 * @returns {JSX.Element}
 */
export const IsOrphanObjectRfField = () => {
  const { editMode } = useMode();
  const card = useGetCurrentCard();
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();
  const isCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();

  const editable = isCurrentObjectIsExternalSystem
    ? isEditCurrentObjectIsExternalSystem && editMode
    : editMode && !card?.rootId;

  return <IsOrphanObjectRF disabled={!editable} />;
};
