import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { UnitIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/UnitId.FF';
import { QUANTITY_NAME } from 'core/uiKit/preparedInputs/textField/Quantity';
import React from 'react';
import { useField } from 'react-final-form';

/**
 * Select __Ед измерения__.
 *
 * @returns {JSX.Element}
 * @class
 */
export const UnitIdField = () => {
  const { editMode } = useEditMode();

  const {
    input: { onChange },
  } = useField(QUANTITY_NAME);

  return (
    <UnitIdFF
      withAll={false}
      disabled={!editMode}
      required={true}
      filter={(dict) => {
        return dict.filter(({ code }) => ['things'].includes(code));
      }}
      withChange={() => {
        onChange(1);
      }}
    />
  );
};
