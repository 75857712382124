import { post } from 'app/api';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';

/**
 * GetRegistryApprovalsTech.
 *
 * @param {*} obj - A.
 * @returns {*}
 */
export const getRegistryApprovalsTech = (obj) => {
  return post('/registry/approvals/tech', obj);
};

/**
 * UseGetRegistryApprovalsTechTable.
 
 * @param {*} query - A.
 * @returns {*}
 */
export const useGetRegistryApprovalsTechTable = (query) => {
  return useQueryAdaptor(
    ['getRegistryApprovalsTechTable', query],
    getRegistryApprovalsTech,
  );
};
