import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useEffect, useState } from 'react';

import { ISubTypeTree } from '../Tree';

/**
 * Сетап для Одного элемента типа подгруппы дерева элементов.
 *
 * @param {ISubTypeTree} props - Props.
 * @returns Функции и флаги.
 */
export const useSetupSubTypeItemTree = ({
  elements,
  openSubTypes,
}: ISubTypeTree) => {
  const { rootId: rootIdCurrent } = useGetCurrentCard();

  const isCurrentInElements = elements?.some(
    ({ rootId }) => String(rootId) === String(rootIdCurrent),
  );

  const [open, setOpen] = useState<boolean | undefined>(
    isCurrentInElements || openSubTypes,
  );

  useEffect(() => {
    setOpen(openSubTypes);
  }, [openSubTypes]);

  useEffect(() => {
    setOpen(isCurrentInElements);
  }, [isCurrentInElements]);

  return { open, setOpen };
};
