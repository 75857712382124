import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { CharacteristicStateGardeningIdRF } from 'core/form/reduxForm/fields/prepared/selects/multiSelect/CharacteristicStateGardeningId.RF';
import { DETAILED_STATE_GARDENING_ID } from 'core/uiKit/preparedInputs/selects/simpleSelect/DetailedStateGardeningId';
import React, { FC } from 'react';

/**
 * Характеристика состояния.
 *
 * @param props - Property.
 * @param props.changeFieldValue - Функция изменения полей формы.
 * @param props.editMode - Флаг изменения.
 * @param props.currentValues - Значения формы.
 * @returns {JSX}
 */
export const CharacteristicStateGardeningIdRFTreesShrubs: FC<{
  changeFieldValue: (nameField: string, value: unknown) => void;
  editMode?: boolean;
  currentValues: {
    state_gardening_id?: number;
    [DETAILED_STATE_GARDENING_ID]?: number;
  };
}> = ({ currentValues = {}, editMode, changeFieldValue }) => {
  const isCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();

  return (
    // @ts-ignore
    <CharacteristicStateGardeningIdRF
      required={currentValues.state_gardening_id !== 1}
      filter={(dict: { detailed_state_gardening_ids: number[] }[]) =>
        dict.filter(({ detailed_state_gardening_ids }) => {
          return (
            currentValues[DETAILED_STATE_GARDENING_ID] &&
            detailed_state_gardening_ids?.includes(
              currentValues[DETAILED_STATE_GARDENING_ID],
            )
          );
        })
      }
      disabled={
        !(editMode && currentValues.detailed_state_gardening_id) ||
        isCurrentObjectIsExternalSystem
      }
      withChange={() => {
        changeFieldValue('plant_service_recomendations_id', ['']);
      }}
    />
  );
};
