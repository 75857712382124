import { LinkShortIdCell } from 'core/uiKit/components/cells/LinkShortId.Cell/index';

export const lawnColumns = [
  {
    Cell: LinkShortIdCell,
    Header: 'ID',
    accessor: 'root_id',
    minWidth: 120,
  },
  {
    Header: 'Тип газона',
    accessor: 'lawn_type_name',
  },
  {
    Header: 'Номер участка',
    accessor: 'section_num',
  },
  {
    Header: 'Площадь кв.м',
    accessor: 'total_lawn_area',
  },
  {
    Header: 'Площадь за вычетом насаждений, кв.м',
    accessor: 'lawn_area_without',
  },
  {
    Header: 'Статус',
    accessor: 'object_status_name',
    minWidth: 110,
  },
  {
    Header: 'Дата начала',
    accessor: 'start_date',
    minWidth: 90,
  },
  {
    Header: 'Дата окончания',
    accessor: 'end_date',
    minWidth: 120,
  },
];
