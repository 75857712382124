/* eslint-disable */
import { shallow } from 'enzyme';

import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';

import { AddRowRF } from './AddRow.RF';
import { AddRow } from './AddRow';

jest.mock('./AddRow');
jest.mock('app/pages/cardsOgh/hooks/useEditMode');
jest.mock('app/api/hooks/useIsCurrentObjectIsExternalSystem');

/* eslint-disable */
describe('file EditRowRFCell', () => {
  it('editMode useIsCurrentObjectIsExternalSystem - props', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    AddRow.mockReturnValue((_) => _);
    useEditMode.mockReturnValue({ editMode: true });
    useIsCurrentObjectIsExternalSystem.mockReturnValue(false);
    // Act
    const wrapper = shallow(<AddRowRF />);
    wrapper.text();
    // Assert
    expect(wrapper.props()).toMatchObject({
      name: 'trafficCharacterList',
      component: expect.any(Function),
    });
  });

  it('editMode false useIsCurrentObjectIsExternalSystem true', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useEditMode.mockReturnValue({ editMode: false });
    useIsCurrentObjectIsExternalSystem.mockReturnValue(true);

    // Act
    const wrapper = shallow(<AddRowRF />);

    // Assert
    expect(wrapper.props()).toStrictEqual({});
    expect(AddRow).toHaveBeenCalledTimes(0);
  });
  it('editMode true useIsCurrentObjectIsExternalSystem true', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useEditMode.mockReturnValue({ editMode: true });
    useIsCurrentObjectIsExternalSystem.mockReturnValue(true);

    // Act
    const wrapper = shallow(<AddRowRF />);

    // Assert
    expect(wrapper.props()).toStrictEqual({});
    expect(AddRow).toHaveBeenCalledTimes(0);
  });

  it('editMode true useIsCurrentObjectIsExternalSystem false', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useEditMode.mockReturnValue({ editMode: false });
    useIsCurrentObjectIsExternalSystem.mockReturnValue(false);

    // Act
    const wrapper = shallow(<AddRowRF />);

    // Assert
    expect(wrapper.props()).toStrictEqual({});
    expect(AddRow).toHaveBeenCalledTimes(0);
  });
});
