import { fetchNeighboursGeometry } from 'app/api/requests/geometry/fetchNeighboursGeometry';
import messages from 'app/utils/card/checkGeometry/messages';
import toArray from 'app/utils/toArray';

import { createMessage } from './createMessage';
import { createObjectForMap } from './createObjectForMap';

/**
 * Обработка нажатия кнопки Показать смежные объекты.
 *
 * @param {object} params - Параметры.
 * @returns {Promise<{headerData: null, bodyData: null}|{headerData: JSX.Element, bodyData: JSX.Element}|{headerData: string, bodyData: string}>} - Ф.
 * @example
 * const { bodyData, headerData } = await onGetNeighboursButtonPress({
 *   data,
 *   hasNeighbours,
 *   resetNeighboursGeometry,
 *   fetchNeighboursGeometry,
 *   showAlert,
 *   draw: this.context.drawNeighboursGeometry,
 * });
 */
export const onGetNeighboursButtonPress = async (params) => {
  const { data, draw, setHasNeighbours } = params;
  const obj = await fetchNeighboursGeometry(data);
  const neighbours = toArray(obj.data);
  const total = obj.count;
  if (neighbours && neighbours.length > 0) {
    const items = neighbours.map((item) => createObjectForMap(item));
    draw(items);
    const { headerData, bodyData } = createMessage(
      neighbours,
      total,
      messages.neighbours,
      data,
    );
    setHasNeighbours(true);
    return { bodyData, headerData };
  } else {
    return {
      bodyData: `Смежные объекты на расстоянии до ${obj.radius} м. отсутствуют`,
      headerData: '',
    };
  }
};
