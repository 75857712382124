/* eslint-disable */
import React from 'react';
import cn from 'classnames';
import Panel from './Panel';
import DialogAlert from 'app/components/dialogs/common/DialogAlert';
import DialogAction from 'app/components/dialogs/common/DialogAction';
import formatRootId from 'app/utils/formatRootId';
import { useLocation, useParams } from 'react-router-dom';

class TechCard extends React.Component {
  state = {
    showGeometryMissing: false,
    showPolygonIsLineError: false,
    geometry: [],
  };

  componentDidMount() {
    const { params, fetchRootCard } = this.props;
    if (params.id) {
      fetchRootCard(params.id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { action } = this.props;
    if (nextProps.action && nextProps.action !== action) {
      nextProps.action();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      card,
      fetchTreeSelectedElement,
      fetchFewTree,
      afterReceiving,
      viewCard,
      mode,
      params,
      fetchRootCard,
    } = this.props;

    if (prevProps.params.id !== params.id) {
      fetchRootCard(params.id);
    }

    if (
      window.location.href.indexOf('/create/') === -1 &&
      mode &&
      mode.didReceived &&
      params &&
      params.splat
    ) {
      const parents = params.splat.split('/');
      const object = parents.pop();
      parents.unshift(card.record_id);
      fetchTreeSelectedElement({
        id: object,
        parents: parents.join('.'),
      });
      const active = params.splat.split('/');
      active.unshift(card.record_id);
      fetchFewTree(
        {
          rootId: card.record_id,
          typeId: card.type_id,
          pathId: params.splat.split('/').join('.'),
        },
        active.join('.'),
      );
    }

    if (mode && mode.didReceived) {
      if (mode.editMode) {
        afterReceiving();
      } else {
        viewCard();
      }
    }
  }

  alert = (message) => {
    this.props.showAlert(message);
  };

  createObject = (objectAttributes) => {
    this.createOrUpdateObject(
      this.props.sendCreateCard,
      objectAttributes,
      () => {},
    );
  };

  createOrUpdateObject = (fetchAction, objectAttributes, callback) => {
    fetchAction(
      {
        ...objectAttributes,
        treeActions: {},
        treeCountActions: 0,
      },
      callback,
    );
  };

  deleteObject = (objectAttributes) => {
    const { sendDeleteCard, parentTypeId } = this.props;

    sendDeleteCard(objectAttributes, parentTypeId);
  };

  deleteTechnology = (objectAttributes) => {
    if (objectAttributes.is_in_car_tree || objectAttributes.has_connections) {
      this.props.showNotification(
        'При удалении объекта будут удалены все его связи',
        () => {
          this.props.deleteTechnology({ ...objectAttributes });
        },
      );
    } else {
      this.props.deleteTechnology({ ...objectAttributes });
    }
  };

  updateObject = (objectAttributes) => {
    this.createOrUpdateObject(
      this.props.sendUpdateCard,
      objectAttributes,
      () => {},
    );
  };

  render() {
    const {
      card,
      mode,
      versionsModal,
      matching,
      matchingRegistryColumns,
      toggleCompareReportVisibility,
      cancelEditCard,
      cancelLeaveCurrentElement,
    } = this.props;
    const { showGeometryMissing, showPolygonIsLineError } = this.state;
    const currentCard = card;

    const closeCompareReport = () => {
      toggleCompareReportVisibility();
    };

    return (
      <div className={cn('h-100 position-relative', this.props.className)}>
        {card && (
          <Panel
            alert={this.alert}
            card={card}
            createObject={this.createObject}
            deleteTechnology={this.deleteTechnology}
            matching={matching}
            matchingRegistryColumns={matchingRegistryColumns}
            mode={mode}
            style={{ width: '96%' }}
            updateObject={this.updateObject}
            viewMatchingTechMode={this.props.viewMatchingTechMode}
          />
        )}

        <DialogAlert
          showCondition={versionsModal}
          title={`Сравнение версий объекта ${
            currentCard && currentCard.type_name_rus
          } ${currentCard && formatRootId(currentCard.root_id)}`}
          onAfterHiding={closeCompareReport}
        />
        <DialogAlert
          showCondition={showPolygonIsLineError}
          title="Один из полигонов указан не правильно"
          onAfterHiding={() => {
            this.setState({ showPolygonIsLineError: false });
          }}
        >
          Каждый полигон должен содержать минимум 3 точки
        </DialogAlert>
        <DialogAlert
          showCondition={showGeometryMissing}
          title="Не указана геометрия объекта"
          onAfterHiding={() => {
            this.setState({ showGeometryMissing: false });
          }}
        >
          Отобразите геометрию ОГХ перед сохранением.
        </DialogAlert>
        <DialogAction
          showCondition={mode && mode.editMode && mode.cancelMode}
          onCancel={() => {
            cancelLeaveCurrentElement();
          }}
          onOk={() => {
            const recordId = currentCard.record_id;
            const rootId = currentCard.root_id;
            cancelEditCard(rootId, recordId);
            if (mode.leavePageHandler) {
              mode.leavePageHandler();
            }
          }}
        />
      </div>
    );
  }
}

// todo: костыль на время. создан когда была переделка на новый роут.
//   нужно переделать на переменые из роута


/* eslint-disable */
export default (props) => {
  const params = useParams();
  let location = useLocation();
  return (
    <TechCard {...props} {...{ params, location, currentLocation: location }} />
  );
};
