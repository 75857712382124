import { pathAbutment } from 'app/pages/registry/ogh/yard/Abutment/path.Abutment';
import { pathBuilding } from 'app/pages/registry/ogh/yard/Building/path.Building';
import { pathDts } from 'app/pages/registry/ogh/yard/Dts/path.Dts';
import { pathEngineerBuilding } from 'app/pages/registry/ogh/yard/EngineerBuilding/path.EngineerBuilding';
import { pathFlowersGarden } from 'app/pages/registry/ogh/yard/FlowersGarden/path.FlowersGarden';
import { pathFunctionality } from 'app/pages/registry/ogh/yard/Functionality/path.Functionality';
import { pathLawn } from 'app/pages/registry/ogh/yard/Lawn/path.Lawn';
import { pathLittleForm } from 'app/pages/registry/ogh/yard/LittleForm/path.LittleForm';
import { pathOtherOks } from 'app/pages/registry/ogh/yard/OtherOks/path.OtherOks';
import { pathOtherTechPlace } from 'app/pages/registry/ogh/yard/OtherTechPlace/path.OtherTechPlace';
import { pathPlanarStructure } from 'app/pages/registry/ogh/yard/PlanarStructure/path.PlanarStructure';
import { pathPpi } from 'app/pages/registry/ogh/yard/Ppi/path.Ppi';
import { pathRedBookPlant } from 'app/pages/registry/ogh/yard/RedBookPlant/path.RedBookPlant';
import { pathRelief } from 'app/pages/registry/ogh/yard/Relief/path.Relief';
import { pathStoragePlace } from 'app/pages/registry/ogh/yard/StoragePlace/path.StoragePlace';
import { pathTechPlace } from 'app/pages/registry/ogh/yard/TechPlace/path.TechPlace';
import { pathTreesShrubs } from 'app/pages/registry/ogh/yard/TreesShrubs/path.TreesShrubs';
import { pathWaterBuilding } from 'app/pages/registry/ogh/yard/WaterBuilding/path.WaterBuilding';
import { pathContainer } from 'app/pages/registry/ogh/сontainer/Container/path.Container';
import * as env from 'config/env';

import { pathVerticalLandscaping } from './VerticalLandscaping/path.VerticalLandscaping';

const currentVersion = Number(env.FRONTEND_VERSION.replaceAll('.', ''));
export const tree = [
  pathBuilding,
  {
    children: [pathContainer, pathPlanarStructure, pathStoragePlace],
    name: 'Плоскостные сооружения',
  },
  pathTechPlace,
  pathOtherTechPlace,
  pathDts,
  pathPpi,
  pathRelief,
  pathFunctionality,
  pathLittleForm,
  {
    children: [pathLawn, pathFlowersGarden, pathTreesShrubs],
    name: 'Объекты озеленения',
  },
  pathOtherOks,
  pathWaterBuilding,
  pathRedBookPlant,
  pathVerticalLandscaping,
  pathEngineerBuilding,
  pathAbutment,
]
  // TODO: удалить этот фильтр после 50 релиза . сделали для задачи ODS-11900 .
  .filter((item) => {
    if (currentVersion < 2051) {
      return item.code !== pathVerticalLandscaping.code;
    }
    return item;
  });
