import { get } from 'app/api/crud/get';
import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { queryClient } from 'app/routes/QueryClientProvider';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';

/**
 * Запрос на получения файлов для подписи и отображения.
 *
 * @param {object} params - Параметры.
 * @returns {Promise<unknown>}
 */
const getActOfCompletedWorkElectronicSignature = (params) =>
  get('sign/get_files_info', params);

/**
 * Хук получения файлов для подписи и отображения.
 *
 * @returns {object}
 */
export const useGetActOfCompletedWorkElectronicSignature = () => {
  const { recordId } = useGetCurrentCard();
  return useQueryAdaptor(
    [
      'getActOfCompletedWorkElectronicSignature',
      { objectId: recordId, typeId: 20 },
    ],
    getActOfCompletedWorkElectronicSignature,
  );
};

/**
 * Функция очистки кеша хука useGetCurrentCard.
 *
 * @returns {void}
 */
export const clearActOfCompletedWorkElectronicSignature = () => {
  queryClient.invalidateQueries({
    queryKey: ['getActOfCompletedWorkElectronicSignature'],
  });
};
