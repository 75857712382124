import './FieldsSplitColumns.scss';

import cn from 'classnames';
import React, { FC, ReactNode } from 'react';

interface FieldsSplitColumnsProps {
  countColumns?: 2 | 3 | 4;
  children: ReactNode;
}

/**
 * Компонент разделяет поля на равные колонки максимум 4.
 *
 * @param props - Params.
 * @param props.children - Children.
 * @param props.countColumns - Число колонок, по умолчанию 2.
 * @returns - Контейнер для инпутов.
 */
export const FieldsSplitColumns: FC<FieldsSplitColumnsProps> = ({
  children,
  countColumns = 2,
}) => {
  const className = cn('fields-split-columns', {
    four: countColumns === 4,
    tree: countColumns === 3,
    two: countColumns === 2,
  });

  return <div className={className}>{children}</div>;
};
