import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import isMandatory from 'app/utils/card/isMandatory';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import React from 'react';

export const ACCESSORY_ID_NAME = 'accessory_id';

/**
 * Select Field RF __Принадлежность__.
 *
 * @returns
 */
export const AccessoryIdField = () => {
  const { typeId, accessory } = useGetCurrentCard();
  const { editMode } = useMode();
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();
  return (
    <SelectRF
      name={ACCESSORY_ID_NAME}
      options={accessory}
      label={'Принадлежность'}
      disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
      required={isMandatory(typeId, ACCESSORY_ID_NAME, editMode)}
    />
  );
};
