/* eslint-disable  */
import moment from 'moment';

import formats from './formats';

/**
 *
 */
export default class DateFormatService {
  /**
   *
   * @param date
   * @param dateFormat
   */
  format(date, dateFormat) {
    return this._formatMomentDate(date, dateFormat);
  }

  /**
   *
   * @param date
   */
  formatDate(date) {
    return this._formatMomentDate(date, DateFormatService.formats.dateFormat);
  }

  /**
   *
   * @param date
   */
  formatTime(date) {
    return this._formatMomentDate(date, DateFormatService.formats.timeFormat);
  }

  /**
   *
   * @param date
   */
  formatDateTime(date) {
    return this._formatMomentDate(
      date,
      DateFormatService.formats.dateTimeFormat,
    );
  }

  /**
   *
   * @param date
   */
  parseDate(date) {
    return this._parseMomentDate(
      date,
      DateFormatService.formats.dateFormat,
    ).toDate();
  }

  /**
   *
   * @param date
   * @param format
   */
  _formatMomentDate(date, format) {
    const dateMoment = moment(date);
    if (dateMoment.date()) {
      return moment(date).format(format);
    }
    return '';
  }

  /**
   *
   * @param date
   * @param format
   */
  _parseMomentDate(date, format) {
    return moment(date, format);
  }
}

DateFormatService.formats = formats;
