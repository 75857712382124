import * as types from '../constants/actionTypes';

/* eslint-disable */

/**
 *
 */
export const clearCard = () => {
  return {
    type: types.CLEAR_CARD,
  };
};
