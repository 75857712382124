import { useChangeFieldValueRF } from 'app/actions/odsObjectActions/useChangeFieldValueRF';
import { useFormValuesCardRF } from 'app/actions/odsObjectActions/useFormValuesCardRF';
import { QuantityField } from 'app/components/card/ogh/components/selects/Quantity.Field/Quantity.Field';
import { RANDOM_BOOL } from 'core/forTesting/constants';
import { QUANTITY_NAME } from 'core/uiKit/preparedInputs/textField/Quantity';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

vi.mock('app/actions/odsObjectActions/useChangeFieldValueRF');
vi.mock('app/actions/odsObjectActions/useFormValuesCardRF');
vi.mock('react', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,

    /**
     * Мок эффекта.
     *
     * @param {Function} fn - Функция.
     * @returns {()=> void}
     */
    useEffect: (fn) => fn(),
  };
});

/**
 * Дефолтные моки.
 *
 * @returns {void}
 */
const defaultMock = () => {
  useChangeFieldValueRF.mockReturnValue(vi.fn());
  useFormValuesCardRF.mockReturnValue({});
};

describe('🐛 QuantityFieldSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    defaultMock();
    // 🔥 Act
    const wrapper = shallow(
      <QuantityField
        disabled={RANDOM_BOOL}
        required={RANDOM_BOOL}
        digits={true}
      />,
    );
    // ❓ Assert
    expect(wrapper.text()).toBe('<QuantityRf />');
    expect(wrapper.props()).toStrictEqual({
      digits: true,
      disabled: RANDOM_BOOL,
      required: RANDOM_BOOL,
    });
  });
  it('🧪 useEffect quantity=5 unit_id=5', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const onChangeFieldSpy = vi.fn();
    useChangeFieldValueRF.mockReturnValue(onChangeFieldSpy);
    useFormValuesCardRF.mockReturnValue({ quantity: 5, unit_id: 5 });
    // 🔥 Act
    shallow(
      <QuantityField
        disabled={RANDOM_BOOL}
        required={!RANDOM_BOOL}
        digits={true}
      />,
    );
    // ❓ Assert
    expect(onChangeFieldSpy).toHaveBeenCalledWith(QUANTITY_NAME, 1);
  });
  it('🧪 useEffect quantity=1 unit_id=5', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const onChangeFieldSpy = vi.fn();
    useChangeFieldValueRF.mockReturnValue(onChangeFieldSpy);
    useFormValuesCardRF.mockReturnValue({ quantity: 1, unit_id: 5 });
    // 🔥 Act
    shallow(
      <QuantityField
        disabled={RANDOM_BOOL}
        required={!RANDOM_BOOL}
        digits={true}
      />,
    );
    // ❓ Assert
    expect(onChangeFieldSpy).toHaveBeenCalledTimes(0);
  });
  it('🧪 useEffect quantity===4 unit_id!==5', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const onChangeFieldSpy = vi.fn();
    useChangeFieldValueRF.mockReturnValue(onChangeFieldSpy);
    useFormValuesCardRF.mockReturnValue({ quantity: 4, unit_id: 2 });
    // 🔥 Act
    shallow(
      <QuantityField
        disabled={RANDOM_BOOL}
        required={!RANDOM_BOOL}
        digits={true}
      />,
    );
    // ❓ Assert
    expect(onChangeFieldSpy).toHaveBeenCalledTimes(0);
  });
});
