import { ActOfCompletedWork } from 'app/components/card/ogh/ImprovementTerritoryCard/components/ActOfCompletedWork/index';
import { DocumentsConfirmingTheChangesRF } from 'app/pages/cardsOgh/components/tables/Documents/FieldsRF/preparedFields/DocumentsConfirmingTheChanges.RF';
import { useEditorCardSelector } from 'app/selectors/useSelectors/useEditorCard.Selector';
import { ObjectDiagramRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/ObjectDiagram.RF';
import { OtherDocumentsRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/OtherDocuments.RF';
import { SymbolsForTheLayoutPlanRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/SymbolsForTheLayoutPlan.RF';
import { TopographicPlanRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/TopographicPlan.RF';
import React from 'react';

/**
 * YardDocsTab.
 *
 * @returns JSX.Element.
 */
export const OdhDocsTab = () => {
  const { is_orphan_object: isOrphanObject } = useEditorCardSelector();

  return (
    <>
      <ActOfCompletedWork />
      <OtherDocumentsRF
        title={
          'Иные документы (Схема и материалы фотофиксации объекта, распоряжения и др.)'
        }
      />
      {!isOrphanObject ? <TopographicPlanRF /> : null}
      <ObjectDiagramRF />
      {!isOrphanObject ? <SymbolsForTheLayoutPlanRF /> : null}
      <DocumentsConfirmingTheChangesRF />
    </>
  );
};
