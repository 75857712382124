import { AutocompleteAsync } from 'core/uiKit/inputs/selects/AutocompleteAsync';
import { fetchCustomers } from 'core/uiKit/preparedInputs/selects/api';
import { removePreparation } from 'core/utils/hooks/usePreparation/preparation';
import React, { useEffect } from 'react';

export const CUSTOMER_OZN_NAME = 'customer_id';

/**
 * ## Заказчик.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const CustomerOzn = (props) => {
  useEffect(() => {
    return () => {
      removePreparation(CUSTOMER_OZN_NAME);
    };
  }, []);

  return (
    <AutocompleteAsync
      {...props}
      fetchFunction={fetchCustomers('ozn')}
      label={'Заказчик'}
      name={CUSTOMER_OZN_NAME}
    />
  );
};
