 
export const operation = {
  APPROVED: 'approved',
  REJECTED: 'rejected',
};

 
export const entityCode = {
  OGH: 'ogh',
  TECH: 'tech',
  TITLE_LIST: 'title',
};
