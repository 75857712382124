import { withFinalForm } from 'core/form/finalForm/helpers/withFinalForm';
import {
  DISTRICT_ID_NAME,
  DistrictId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/DistrictId';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * Select FF __Район__.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const DistrictIdFF = (props) => {
  return (
    <Field
      {...props}
      name={DISTRICT_ID_NAME}
      component={withFinalForm(DistrictId)}
    />
  );
};
