 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Дата состояния (из ЕРЗ)__.
 *
 * @type {{ accessor: 'erz_date',  Header: 'Дата состояния (из ЕРЗ)' }}
 * @augments Cell
 */
export const erz_date = {
  accessor: 'erz_date',
  Header: 'Дата состояния (из ЕРЗ)',
  sortable: true,
};
