import { getNameFile } from 'app/pages/cardsOgh/components/tables/Documents/helpers/getNameFile';
import React from 'react';
import { Field } from 'redux-form';

import { DocumentWithReduxForm } from '../DocumentWithReduxForm';

const ID = 19;
const NAME = getNameFile(ID);
const TITLE = 'Документы, подтверждающие внесение изменений';

/**
 * Таблица документов __Документы подтверждающие внесение изменений__.
 *
 * @returns {JSX.Element}
 * @example
 * <DocumentsConfirmingTheChangesRF />
 */
export const DocumentsConfirmingTheChangesRF = () => {
  return (
    <Field
      title={TITLE}
      id={ID}
      name={NAME}
      component={DocumentWithReduxForm}
    />
  );
};
