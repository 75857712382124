import { DialogRefill } from 'app/components/card/ogh/BasePgmCard/components/OperationsTab/components/RefillButton/Dialog.Refill';
/* eslint-disable */
import { Button } from 'core/uiKit/components';
import React, { useState } from 'react';

/**
 *
 */
export const RefillButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Button
        color={'success'}
        variant={'contained'}
        onClick={() => setIsOpen(true)}
      >
        Приход
      </Button>
      {isOpen ? (
        <DialogRefill isOpen={isOpen} onClose={() => setIsOpen(false)} />
      ) : null}
    </>
  );
};
