 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */

import { YesOrNoCell } from 'core/uiKit/components/cells/YesOrNo.Cell';
 
/* eslint-disable */
/**
 * Колонка __Прилегает к проезжей части__.
 *
 * @type {{ accessor: 'near_roadway', Header: 'Прилегает к проезжей части', Cell: YesOrNoCell }}
 * @augments Cell
 */
export const near_roadway = {
  accessor: 'near_roadway',
  Header: 'Прилегает к проезжей части',
  Cell: YesOrNoCell,
  sortable: true,
};
