 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Площадь павильонов, кв.м__.
 *
 * @type {{ accessor: 'pavilion_area', Header: 'Площадь павильонов, кв.м' }}
 * @augments Cell
 */
export const pavilion_area = {
  accessor: 'pavilion_area',
  Header: 'Площадь павильонов, кв.м',
  sortable: true,
};
