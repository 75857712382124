import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { MultiSelect } from 'core/uiKit/inputs/selects/MultiSelect';
import React from 'react';

export const OGH_STATUSES_NAME = 'stateId';

/**
 * ## Статус ОГХ.
 *
 * @param {*} args - The props.
 * @param {*} args.filter - The props.
 * @param {*} args.props - The props.
 * @returns {JSX.Element}
 */
export const OghStatuses = ({ filter = (_) => _, ...props }) => {
  const dict = 'oghStatuses';
  const { data = [] } = useGetDictionary(dict);

  const options = filter(data);

  return (
    <MultiSelect
      {...props}
      {...{ options }}
      label={'Статус'}
      name={OGH_STATUSES_NAME}
    />
  );
};
