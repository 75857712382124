/* eslint-disable */
import React from 'react';
import TableAddButton from 'app/components/common/TableAddButton';
import TableDeleteButton from 'app/components/common/TableDeleteButton';
import TableErrorText from 'app/components/common/TableErrorText';
import Table from 'app/components/common/table/Table';
import Checkbox from 'core/newComponents/Checkbox';
import DialogAddMafRecord from 'app/components/dialogs/oghRegistry/common/DialogAddMafRecord';

const dictFields = {
  maf_type_level1_id: {
    id: 'maf_type_level1',
    dictKey: 'mafTypeLevel1',
  },
  maf_type_level2_id: {
    id: 'maf_type_level2',
    dictKey: 'mafTypeLevel2',
  },
  maf_type_level3_id: {
    id: 'maf_type_level3',
    dictKey: 'mafTypeLevel3',
  },
};

const dictKeys = Object.keys(dictFields);

function getTableData(table, props) {
  const results = {
    table: {
      columns: [
        {
          key: 'maf_type_level1_id',
          screenName: 'Вид МАФ',
          sortable: false,
          databaseName: null,
          cells: [],
        },
        {
          key: 'maf_type_level2_id',
          screenName: 'Уточнение',
          sortable: false,
          databaseName: null,
          cells: [],
        },
        {
          key: 'maf_type_level3_id',
          screenName: 'Детализация',
          sortable: false,
          databaseName: null,
          cells: [],
        },
        {
          key: 'maf_quantity_characteristics',
          screenName: 'Количество, шт.',
          sortable: false,
          databaseName: null,
          cells: [],
        },
        {
          key: 'quantity',
          screenName: 'Количество МАФ, шт.',
          sortable: false,
          databaseName: null,
          cells: [],
        },
      ],
    },
  };

  table.forEach((item) => {
    results.table.columns.forEach((column) => {
      const { key } = column;
      if (key in item) {
        if (dictKeys.includes(key)) {
          const { id, dictKey } = dictFields[key];
          const typeId = item[key][id];
          const name = typeId
            ? props[dictKey].find((item) => item.id === typeId).name
            : null;
          column.cells.push(name);
        } else {
          column.cells.push(item[key]);
        }
      }
    });
  });
  return results;
}

const getContainerStyle = (props) => ({
  marginBottom: '15px',
  ...props.style,
});

/* eslint-disable */
export default function renderMafList(props) {
  const table = (props.input.value && props.input.value.table) || [];
  const showDialog =
    (props.input.value && props.input.value.showDialog) || false;
  const results = getTableData(table, props);

  if (!props.disabled) {
    results.table.columns.unshift({
      key: 'delete',
      screenName: '',
      sortable: false,
      databaseName: null,
      cells: [],
    });
    table.forEach((item, index) => {
      results.table.columns[0].cells.push(
        <Checkbox
          checked={item.checked || false}
          onChange={function (event, value) {
            const character = {
              ...this.table[this.index],
              checked: value,
            };
            const newTable = this.table.slice();
            newTable.splice(this.index, 1, character);
            this.onChange({
              table: newTable,
              showDialog: false,
            });
          }.bind({
            index: index,
            table: table,
            onChange: props.input.onChange,
          })}
        />,
      );
    });
  }

  const headerHeight = 60;
  const isDeleteDisabled = !table.some((item) => item.checked);

  return (
    <div style={getContainerStyle(props)}>
      <DialogAddMafRecord
        showCondition={showDialog}
        table={table}
        onCancel={() => {
          props.input.onChange({
            table,
            showDialog: false,
          });
        }}
        onSubmit={(record) => {
          props.input.onChange({
            table: [...table, record],
            showDialog: false,
          });
        }}
      />
      <TableAddButton
        isError={props.meta && props.meta.error}
        title="Добавление записи"
        visible={!props.disabled}
        onAdd={() => {
          props.input.onChange({
            table,
            showDialog: true,
          });
        }}
      />
      <TableDeleteButton
        disabled={isDeleteDisabled}
        visible={!props.disabled}
        onRemove={() => {
          const newTable = table.filter((item) => {
            return !item.checked;
          });
          props.input.onChange({
            table: newTable,
            showDialog: false,
          });
        }}
      />
      <TableErrorText
        errorText={(props.meta && props.meta.error) || null}
        visible={!props.disabled}
      />
      <Table
        {...results}
        headerHeight={headerHeight}
        height={table.length * headerHeight + headerHeight}
        width={1150 + (props.disabled ? 0 : 50)}
        widths={(name) => (name === '' ? 50 : 575)}
      />
    </div>
  );
}
