import { useChangeFieldValueRF } from 'app/actions/odsObjectActions/useChangeFieldValueRF';
/* eslint-disable */
import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { CLEAN_SUB_CATEGORY_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CleanSubCategoryId/index';
import { shallow } from 'enzyme';
import { useSelector } from 'react-redux';

import { CleanSubCategoryIdField } from './CleanSubCategoryIdField';

jest.mock('app/api/hooks/useGetCurrentCard');
jest.mock('app/pages/cardsOgh/hooks/useEditMode');
jest.mock('app/actions/odsObjectActions/useChangeFieldValueRF');
jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useSelector: jest.fn(),
}));

jest.mock('react', () => ({
  ...jest.requireActual('react'),

  /**
   *
   * @param fn
   */
  useEffect: (fn) => fn(),
}));
describe('CleanSubCategoryIdField', function () {
  it.each`
    clean_category_id | requiredExpect
    ${2}              | ${false}
    ${6}              | ${true}
    ${7}              | ${true}
  `(
    'test required clean_category_id $clean_category_id',
    ({ clean_category_id, requiredExpect }) => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      const changeFieldValue = jest.fn();
      useSelector.mockImplementation((fn) =>
        fn({ form: { editorCard: { values: { clean_category_id } } } }),
      );
      useChangeFieldValueRF.mockReturnValue(changeFieldValue);
      useEditMode.mockReturnValue({ editMode: true });
      useGetCurrentCard.mockReturnValue({
        cleanSubcategory,
      });
      // Act
      const wrapper = shallow(<CleanSubCategoryIdField />);
      const { required } = wrapper.props();
      // Assert
      expect(required).toBe(requiredExpect);
    },
  );
  it('test filter', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const changeFieldValue = jest.fn();
    useSelector.mockImplementation((fn) =>
      fn({ form: { editorCard: { values: { clean_category_id: 6 } } } }),
    );
    useChangeFieldValueRF.mockReturnValue(changeFieldValue);
    useEditMode.mockReturnValue({ editMode: true });
    useGetCurrentCard.mockReturnValue({
      cleanSubcategory,
    });
    // Act
    const wrapper = shallow(<CleanSubCategoryIdField />);
    const { filter } = wrapper.props();
    // Assert
    expect(filter(cleanSubcategory)).toStrictEqual([
      {
        category_id: '6',
        id: 337063024,
        name: 'а (МКАД)',
      },
      {
        category_id: '6',
        id: 337063025,
        name: 'б (ТТК, ЧТК)',
      },
      {
        category_id: '6',
        id: 337063026,
        name: 'в (Бульварное и Садовое кольцо)',
      },
    ]);
  });
  it('test called Effect', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const changeFieldValue = jest.fn();
    useSelector.mockImplementation((fn) =>
      fn({ form: { editorCard: { values: { clean_category_id: 1 } } } }),
    );
    useChangeFieldValueRF.mockReturnValue(changeFieldValue);
    useEditMode.mockReturnValue({ editMode: true });
    useGetCurrentCard.mockReturnValue({
      cleanSubcategory,
    });
    // Act
    shallow(<CleanSubCategoryIdField />);
    // Assert
    expect(changeFieldValue).toBeCalledWith(CLEAN_SUB_CATEGORY_ID_NAME, null);
  });
});

const cleanSubcategory = [
  {
    category_id: '6',
    id: 337063024,
    name: 'а (МКАД)',
  },
  {
    category_id: '6',
    id: 337063025,
    name: 'б (ТТК, ЧТК)',
  },
  {
    category_id: '6',
    id: 337063026,
    name: 'в (Бульварное и Садовое кольцо)',
  },
  {
    category_id: '7',
    id: 337063017,
    name: 'Кремлевское кольцо и объекты дорожного хозяйства внутри него',
  },
  {
    category_id: '7',
    id: 337063018,
    name: 'Магистрали (направления "Внуковское", "Рублевское", "Шереметьевское")',
  },
  {
    category_id: '7',
    id: 337063022,
    name: 'ОДХ на территории ТиНАО, не отнесенные к иным категориям на территории',
  },
  {
    category_id: '7',
    id: 337063020,
    name: 'Переулки и проезды внутри Садового кольца',
  },
  {
    category_id: '7',
    id: 15198947,
    name: 'Площади перед ж/д вокзалами',
  },
  {
    category_id: '7',
    id: 337063021,
    name: 'Территории парков – мемориальных, культуры и отдыха',
  },
  {
    category_id: '7',
    id: 337063019,
    name: 'Территории перед ГАО "ВВЦ"',
  },
  {
    category_id: '7',
    id: 15198952,
    name: 'Территории, прилегающие к Александровскому саду, включая Манежную площадь',
  },
  {
    category_id: '7',
    id: 13190311,
    name: 'Территория МГУ на Воробьевых горах',
  },
  {
    category_id: '7',
    id: 13190316,
    name: 'Территория памятника природы "Серебряный бор"',
  },
  {
    category_id: '7',
    id: 337063023,
    name: 'Территория, прилегающая к Московскому Кремлю',
  },
  {
    category_id: '7',
    id: 337063027,
    name: 'Территория, прилегающая к Храму Христа Спасителя',
  },
];
