import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const OKRUG_NAME = 'okrug';

/**
 * ## Округ.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const Okrug = (props) => {
  const dict = 'okrugs';

  const { data = [] } = useGetDictionary(dict);

  return <Select {...props} options={data} label={'Округ'} name={OKRUG_NAME} />;
};
