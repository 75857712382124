 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Вазоны, шт.__.
 *
 * @type {{
 * accessor: 'vase_qty',
 * Header: 'Вазоны, шт.',
 * }}
 * @augments Cell
 */
export const vase_qty = {
  accessor: 'vase_qty',
  Header: 'Вазоны, шт.',
  sortable: true,
};
