import {
  BASE_PGM,
  IMPROVEMENT_OBJECT,
  ODH,
  OOPT,
  OZN,
  SPA,
  YARD,
} from 'app/constants/oghTypes';

const parentCards = [ODH, IMPROVEMENT_OBJECT, YARD, OZN, OOPT, SPA, BASE_PGM];

/**
 * Функция проверки на тип родителя.
 *
 * @param {number} typeId - Тип карточки, полученный из хука useGetParentCard.
 * @returns {boolean} - Возвращает флаг, является родителем или нет.
 * @example checkParent(39);
 */
export const checkParent = (typeId = 0) => {
  return parentCards.includes(typeId);
};
