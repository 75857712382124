/* eslint-disable  */
/**
 *
 * @param values
 */
export const validateDialog = (values) => {
  let errors = {};
  if (!values.reason || !values.reason[0]) {
    errors.reason = 'Обязательное поле';
  }
  return errors;
};
