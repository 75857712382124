 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Площадь по типу покрытия на сопряжении с ПЧ, кв.ма__.
 *
 * @type {{ accessor: 'coating_between_road_area', Header: 'Площадь по типу покрытия на сопряжении с ПЧ, кв.ма'}}
 * @augments Cell
 */
export const coating_between_road_area = {
  accessor: 'coating_between_road_area',
  Header: 'Площадь по типу покрытия на сопряжении с ПЧ, кв.м',
  sortable: true,
};
