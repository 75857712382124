import { parseDate } from 'app/components/card/common/parse';
import buildMandatoriesMessage from 'app/components/card/common/validation/buildMandatoriesMessage';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import getFormValues from 'app/selectors/form/getFormValues';
import { toDate } from 'app/utils/date/toDate';
import { log } from 'core/utils/log';
import { get } from 'lodash';
import { SubmissionError } from 'redux-form';

const enableReinitializeReduxForm = true;

/**
 * Свой mapStateToPropsReducer.
 *
 * @param {object} root0 - Properties.
 * @param {Function} root0.validate - Функция валидации.
 * @param {Function} root0.createAttribute - Функция создания атрибутов.
 * @param {Function} root0.asyncValidate - Функция валидации после сабмита.
 * @param {object} root0.tabToFields - Пока не известно.
 * @param {object} root0.mandatoryFieldNames - Пока тоже не известно.
 * @returns {object}
 */
function mapStateToPropsReducer({
  validate,
  createAttribute,
  asyncValidate,
  tabToFields,
  mandatoryFieldNames,
}) {
  return (result, state, props) => {
    const { card } = props;

    const editMode = isEditMode(state, props);
    const currentValues = getFormValues(state);
    return {
      calcAttributes: state.calcAttributes,
      createMode: isCreateMode(state, props),
      currentValues,
      editMode,
      editable: editMode,
      enableReinitialize: enableReinitializeReduxForm,
      initialValues: {
        endDate: parseDate(card.end_date),
        greeningAddition: card?.attribute?.greening_addition,
        startDate: parseDate(card.start_date),
        totalAreaGeo: card?.attribute?.total_area_geo || 0,
      },
      isOrphanObject: !!get(currentValues, 'is_orphan_object', false),
      onSubmit: onSubmit.bind(this, {
        createAttribute,
        customAsyncValidate: asyncValidate,
        mandatoryFieldNames,
        props,
        state,
        tabToFields,
      }),
      required: isRequired(state, props),
      typeId: card.type_id,

      validate,
    };
  };
}

/**
 * Функция сабмит формы.
 *
 * @param {object} root0 - Property.
 * @param {object} root0.state - State.
 * @param {object} root0.props - Props.
 * @param {Function} root0.createAttribute - Функция создания атрибутов.
 * @param {Function} root0.customAsyncValidate - Функция валидации после сабмита.
 * @param {object} root0.tabToFields - Пока.
 * @param {object} root0.mandatoryFieldNames - Пока.
 * @returns {void}
 */
function onSubmit({
  state,
  props,
  createAttribute,
  customAsyncValidate,
  tabToFields,
  mandatoryFieldNames,
}) {
  const createMode = isCreateMode(state, props);
  const formValues = getFormValues(state);
  const { card, updateObject, createObject, alert } = props;

  const mapCalcAttributes = card?.calc_attribute
    ? Object.keys(card?.calc_attribute)
    : [];

  let formValuesCopy = { ...formValues };
  if (formValues.re_calc_address_list === null && formValues.name_description) {
    formValuesCopy['name'] = formValues.name_description;
  }

  // форматирование в число - пока так
  ['snow_clean_area', 'meadow_lowland_area', 'spread'].forEach((property) => {
    formValuesCopy[property] = formValuesCopy[property]
      ? Number(formValuesCopy[property])
      : formValuesCopy[property];
  });

  asyncValidate({
    alert,
    customAsyncValidate,
    formValues,
    mandatoryFieldNames,
    tabToFields,
  });

  if (formValuesCopy.department_id !== null) {
    formValuesCopy['department_id'] = {
      legal_person: parseInt(formValuesCopy.department_id, 10),
    };
  }

  const attribute = deleteCalcAttributes(
    filterAttribute({
      ...formValuesCopy,
      ...createAttribute(formValuesCopy),
    }),
    mapCalcAttributes,
  );

  const interval = {
    endDate: toDate(formValuesCopy.endDate),
    startDate: toDate(formValuesCopy.startDate),
  };

  if (createMode) {
    createObject({
      type: card.type_id,
      ...interval,
      attribute,
    });
  } else {
    updateObject({
      id: card.record_id,
      ...(card.root_id && { rootId: card.root_id }),
      type: card.type_id,
      ...interval,
      attribute,
    });
  }
}

/**
 * Функция _удаления атрибутов.
 *
 * @param {object} attributes - Attributes.
 * @param {object} mapCalcAttributes - Map attributes.
 * @returns {{}}
 */
const deleteCalcAttributes = (attributes, mapCalcAttributes) => {
  const result = {};
  for (let key in attributes) {
    if (!mapCalcAttributes.includes(key)) {
      result[key] = attributes[key];
    }
  }
  return result;
};

/**
 * Функция фильтрации атрибутов.
 *
 * @param {object} attribute - Atributes.
 * @returns {{}}
 */
function filterAttribute(attribute) {
  return Object.entries(attribute)
    .filter(([key, value]) => {
      let result;
      if (key.indexOf('file') !== -1 && value !== null) {
        if (value.table && value.table.length === 0) {
          value = null;
        }
      }
      if (
        key.indexOf('improvement_category_id') !== -1 &&
        value?.improvement_category < 0
      ) {
        value = null;
      }
      if (value === null || value === '') {
        result = false;
      } else result = !(key === 'startDate' || key === 'endDate');
      return result;
    })
    .reduce((result, [key, value]) => {
      // В рамках задачи ODS-9339 не нужно передавать атрибут re_calc_red_book_list
      if (key === 're_calc_red_book_list') {
        return result;
      }
      result[key] = value;
      return result;
    }, {});
}

/**
 * Функция Валидации после сабмита.
 *
 * @param {object} props - Properties.
 * @param {object} props.formValues - Данные формы.
 * @param {Function} props.alert - Функция всплывашки.
 * @param {Function} props.customAsyncValidate - Функция валидации.
 * @param {object} props.mandatoryFieldNames - Пока не совсем понятно что это.
 * @param {object} props.tabToFields - Тоже не понятно.
 * @returns {void}
 */
function asyncValidate({
  formValues,
  alert,
  customAsyncValidate,
  mandatoryFieldNames,
  tabToFields,
}) {
  const errors = customAsyncValidate(formValues);
  if (Object.keys(errors).length) {
    log.table(errors);
    if (alert) {
      alert(buildMandatoriesMessage(errors, tabToFields, mandatoryFieldNames));
      throw new SubmissionError(errors);
    }
  }
}

export default mapStateToPropsReducer;
