/* eslint-disable */
import React from 'react';

import { filterInput } from '../filterInputsClasses';
import {
  PLANTATION_TYPE_NAME,
  PlantationTypeFF,
} from 'core/form/finalForm/fields';
import { NumberParam } from 'use-query-params';

 
export { PLANTATION_TYPE_NAME };
 
 
/**
 *     Тип насаждения
 *     plantationTypeId
 */
 
export const PlantationTypeFilter = (props) => (
  <PlantationTypeFF {...props} className={filterInput} />
);
 
export const plantationTypeParams = {
  [PLANTATION_TYPE_NAME]: NumberParam,
};
