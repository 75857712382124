// в комментарии название со справочника, наименование

/**
 * Объект Дорожного Хозяйства.
 */
export const ODH = 1; // odh ОДХ - Объект Дорожного Хозяйства

export const STOPS = 5; // stops	Остановки общественного транспорта

export const LAMPPOSTS = 6; // lampposts	Опоры освещения и контактных сетей

export const MAF = 7; // maf	МАФ

export const MANHOLES = 8; // manholes	Люки и решетки

export const OTHER_ENGIN_CONSTRUCT = 9; // other_engin_construct	Прочие инженерные сооружения

export const ROAD_SIGNS = 10; // road_signs	Дорожные знаки, указатели и информационные щиты

export const FENCING = 11; // fencing	Ограждающие и направляющие устройства

export const TRAFFIC_LIGHT = 12; // traffic_light	Светофорные объекты

export const CARRIAGEWAY = 13; // carriageway	Проезжие части

export const SIDEWALKS = 14; // sidewalks	Тротуары и пешеходные зоны, дорожки

export const BOARD_STONE = 15; // board_stone	Бортовые камни

export const OTHER_FLAT_BUILDING = 16; // other_flat_building	Прочие плоскостные элементы

export const TRAM_RAILS = 20; // tram_rails	Трамвайные пути

export const TROLLEYBUS_CONTACT_NETWORK = 21; // trolleybus_contact_network	Трамвайные и троллейбусные контактные сети

export const MARGIN = 23; // margin	Обочины

export const EMERGENCY_PARKING = 24; // emergency_parking Специальная площадка для аварийной остановки автомобилей *

export const BUILDING = 25; // building	Объекты капитального строительства

export const CONTAINER = 26; // container	Места сбора отходов

export const PLANAR_STRUCTURE = 27; // planar_structure	Плоскостные сооружения

export const TECH_PLACE = 28; // tech_place	Некапитальные объекты для обеспечения производственной деятельности по содержанию и ремонту территорий (в том числе места хранения ПГМ на дворовых территориях)

export const STORAGE_PLACE = 29; // storage_place	Закрытые велопарковки для долгосрочного хранения велосипедов

export const DTS = 30; // dts	Дорожно-тропиночные сети

export const LAWN = 31; // lawn	Газоны

export const FLOWERS_GARDEN = 32; // flowers_garden	Цветники

export const PPI = 33; // ppi	Элементы благоустройства для маломобильных групп населения

export const RELIEF = 34; // relief	Элементы организации рельефа

export const FUNCTIONALITY = 35; // functionality	Системы функционального обеспечения

export const LITTLE_FORM = 37; // little_form	Малые архитектурные формы и элементы благоустройства

export const YARD = 38; // yard	Дворовые территории

export const IMPROVEMENT_OBJECT = 39; // improvement_object	Иные объекты благоустройства

/**
 * Type_id для Объекты озеленения.
 *
 * @type {40}
 */
export const OZN = 40; // ozn

export const OTHER_TECH_PLACE = 41; // other_tech_place	Иные некапитальные объекты

export const BIN = 42; // bin	Контейнеры

export const TREES_SHRUBS = 43; // trees_shrubs	Деревья/Кустарники

export const BASE_PGM = 44; // base_pgm	Базы хранения ПГМ

export const TANK = 45; // tank	Емкости для хранения ПГМ

export const OOPT = 49; // oopt Особо Охраняемые Природные Территории

export const BRIDGE = 47; // bridge	Мосты

export const OTHER_OKS = 48; // other_oks	Иной объект капитального строительства

export const SPA = 49; // Особо охраняемая природная территория ООПТ

export const WATER_BUILDING = 50; // Водные сооружения

export const RED_BOOK_PLANT = 51; // Растения красной книги

export const MAF_RFID = 52; //Малые архитектурные формы реестр

export const ENGINEER_BUILDING = 53; // Инженерные сооружения для ОО и ДТ
export const ABUTMENT = 54; // Элементы сопряжения

export const VERTICAL_LANDSCAPING = 55; // Вертикальные сооружения
