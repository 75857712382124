import useDrawer from 'app/components/common/NavBar/components/Drawer/hooks/useDrawer';

import { useGetOghProjectsCount } from './api/useGetOghProjectsCount';

/* eslint-disable */
export const useMain = () => {
  const { data, isLoading } = useGetOghProjectsCount();

  const [, setOpen] = useDrawer();

  const openDrawer = (value) => {
    return setOpen(value);
  };

  return !isLoading
    ? {
        openDrawer,
        count: data?.count || 0,
        branches: data?.branches || [],
      }
    : {
        openDrawer,
        count: 0,
        branches: [],
      };
};
