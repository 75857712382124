import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import getFormValues from 'app/selectors/form/getFormValues';
import { useSelector } from 'react-redux';

/**
 * Хук получения логики для компонента CoatingTypeRoadIdRFTramRails.
 *
 * @returns {{formValues: object, isCurrentObjectIsExternalSystem: boolean, editMode, typeId: number}}
 */
export const useCoatingTypeRoadIdRFTramRails = () => {
  const { editMode } = useEditMode();
  const isCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();
  const { typeId } = useGetParentCard();

  const formValues = useSelector((state) => getFormValues(state));

  /**
   * Функция фильтрации справочника.
   *
   * @param {object} dict - Справочник.
   * @returns {Array} - Отфильтрованный справочник.
   */
  const filter = (dict = []) => {
    return dict.filter((item) => {
      const ids = item.ogh_object_type_list.map(
        ({ ogh_object_type_id }) => ogh_object_type_id,
      );
      return (
        item.group_id === formValues?.coating_group_road_id &&
        ids.includes(typeId)
      );
    });
  };

  return {
    editMode,
    filter,
    formValues,
    isCurrentObjectIsExternalSystem,
  };
};
