import { OKRUG_LIST_NAME } from 'core/uiKit/preparedInputs/selects/multiSelect/OkrugList';

/**
 * Перевод данных для поля OkrugField.
 *
 * @param {{okrug_list:Array}} formValues - Значение поля из formValues.
 * @returns {{OKRUG_LIST_NAME: Array<number>}} - Переведенные данные массив с числами [{ bti_object: id }, { bti_object: id }].
 * @example -
 * function createAttribute(formValues) {
 *   return {
 *     ...createdOkrugFieldAttribute(formValues),
 *   };
 * }
 */
export function createdOkrugListFieldAttribute({ okrug_list }) {
  return {
    [OKRUG_LIST_NAME]: okrug_list.map((id) => ({
      bti_object: id,
    })),
  };
}
