import { showAlert as showAlertAction } from 'app/actions/dialog';
import {
  fetchCheckGeomContains as fetchCheckGeomContainsAction,
  fetchCheckGeometryIntersections as fetchCheckGeometryIntersectionsAction,
  resetIntersectionsGeometry as resetIntersectionsGeometryAction,
} from 'app/actions/geometryActions';
import getTreeJournal from 'app/selectors/card/getTreeJournal';
import hasIntersectionsSelect from 'app/selectors/card/hasIntersections';
import getFormValues from 'app/selectors/form/getFormValues';
import { ResponseGeometryObjects } from 'core/uiKit/components/DTW/contexts/utils/types';
import { useDispatch, useSelector } from 'react-redux';

/**
 * Redux hкнопки CheckGeometryButton.
 *
 * @returns Состояние redux кнопки.
 */
export const useReduxCheckGeometryButton = () => {
  const dispatch = useDispatch();

  const hasIntersections = useSelector(hasIntersectionsSelect);
  const treeJournal = useSelector(getTreeJournal);
  const card = useSelector(
    (state: { card: { object: object } }) => state?.card.object,
  );
  const selected = useSelector(
    (state: { tree: { object: object } }) => state.tree.object,
  );
  const currentFormValues = useSelector(getFormValues);

  /**
   * Function fetchCheckGeomContains.
   *
   * @param value - Value.
   * @returns D.
   */
  const fetchCheckGeomContains = (value: object) =>
    // @ts-ignore
    dispatch(fetchCheckGeomContainsAction(value));

  /**
   * Function fetchCheckGeometryIntersections.
   *
   * @param value - Value.
   * @returns D.
   */
  const fetchCheckGeometryIntersections = (
    value: object,
  ): Promise<Intersection> =>
    // @ts-ignore
    dispatch(fetchCheckGeometryIntersectionsAction(value));

  /**
   * Function resetIntersectionsGeometry.
   *
   * @returns D.
   */
  const resetIntersectionsGeometry = () =>
    // @ts-ignore
    dispatch(resetIntersectionsGeometryAction());

  /**
   * Function showAlert.
   *
   * @param value - Value.
   * @returns D.
   */
  const showAlert = (value: object) =>
    // @ts-ignore
    dispatch(showAlertAction(value));

  return {
    card,
    currentCard: selected || card || {},
    currentFormValues,
    fetchCheckGeomContains,
    fetchCheckGeometryIntersections,
    hasIntersections,
    resetIntersectionsGeometry,
    showAlert,
    treeJournal,
  };
};

interface Intersection {
  isallow: boolean;
  intersected: boolean;
  geometry: ResponseGeometryObjects[];
  target: {
    lines: null;
    points: null;
    polygons: {
      type: string;
      coordinates: [number, number][][];
    }[];
  };
}
