import { useFetchOghDispatch } from 'app/actions/odsObjectActions/useFetchOgh.Dispatch';
import { uploadFileReturnObj } from 'app/api/requests/signs/signOghSave/uploadFileReturnObj';
import { RANDOM_BOOL, RANDOM_ID, RANDOM_WORD } from 'core/forTesting/constants';
import { toast } from 'core/uiKit/components/Toast';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

import { useSignOghSaveDialogApproveElectronicSignature } from './useSignOghSave.DialogApproveElectronicSignature';

jest.mock('app/actions/odsObjectActions/useFetchOgh.Dispatch');
jest.mock('core/utils/hooks/useReactQuery');
jest.mock('app/api/requests/signs/signOghSave/uploadFileReturnObj');
jest.mock('core/uiKit/components/Toast');

describe('UseSignOghSaveDialogApproveElectronicSignature', function () {
  it('test return hook', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    const objectId = RANDOM_ID;
    const signType = RANDOM_WORD;
    const objectCardFiles = [];
    const onSuccessCallback = jest.fn();
    const onErrorCallback = jest.fn();
    const sendSign = jest.fn();

    useMutationAdaptor.mockImplementation(() => {
      return [sendSign, { isLoading: RANDOM_BOOL }];
    });

    // Act

    // Assert
    expect(
      useSignOghSaveDialogApproveElectronicSignature({
        objectCardFiles,
        objectId,
        onErrorCallback,
        onSuccessCallback,
        signType,
      }),
    ).toStrictEqual({ isLoadingSignOghSave: RANDOM_BOOL, sendSign });
    expect(useFetchOghDispatch).toBeCalledWith(RANDOM_ID);
  });

  it('test onSuccess', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    const objectId = RANDOM_ID;
    const signType = RANDOM_WORD;
    const objectCardFiles = [];
    const onSuccessCallback = jest.fn();
    const onErrorCallback = jest.fn();
    const sendSign = jest.fn();
    const documentJustification = [{ storageId: RANDOM_ID }];
    const fetchOgh = jest.fn();
    const success = jest.spyOn(toast, 'success');

    useFetchOghDispatch.mockReturnValue(fetchOgh);
    uploadFileReturnObj.mockReturnValue(documentJustification);
    useMutationAdaptor.mockImplementation((fn, { onSuccess }) => {
      fn({});
      onSuccess();
      return [sendSign, { isLoading: RANDOM_BOOL }];
    });

    // Act

    // Assert
    expect(
      useSignOghSaveDialogApproveElectronicSignature({
        objectCardFiles,
        objectId,
        onErrorCallback,
        onSuccessCallback,
        signType,
      }),
    ).toStrictEqual({ isLoadingSignOghSave: RANDOM_BOOL, sendSign });
    expect(success).toBeCalledWith('Подписано');
    expect(fetchOgh).toBeCalledTimes(1);
    expect(onSuccessCallback).toBeCalledTimes(1);
  });

  it('test onError', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    const objectId = RANDOM_ID;
    const signType = RANDOM_WORD;
    const objectCardFiles = [];
    const onSuccessCallback = jest.fn();
    const onErrorCallback = jest.fn();
    const sendSign = jest.fn();
    const documentJustification = [{ storageId: RANDOM_ID }];
    const fetchOgh = jest.fn();
    const error = jest.spyOn(toast, 'error');

    useFetchOghDispatch.mockReturnValue(fetchOgh);
    uploadFileReturnObj.mockReturnValue(documentJustification);
    useMutationAdaptor.mockImplementation((fn, { onError }) => {
      fn({});
      onError({ message: RANDOM_WORD });
      return [sendSign, { isLoading: RANDOM_BOOL }];
    });

    // Act

    // Assert
    expect(
      useSignOghSaveDialogApproveElectronicSignature({
        objectCardFiles,
        objectId,
        onErrorCallback,
        onSuccessCallback,
        signType,
      }),
    ).toStrictEqual({ isLoadingSignOghSave: RANDOM_BOOL, sendSign });
    expect(error).toBeCalledWith(RANDOM_WORD);
    expect(onErrorCallback).toBeCalledTimes(1);
  });
});
