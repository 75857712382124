import {
  /* eslint-disable  */
  BUNKER,
  CARCASE,
  FUEL_TANK,
  TANK,
  WATER_TANK,
} from './constantsCapacity';

const withCapacity = [FUEL_TANK, TANK, BUNKER, CARCASE, WATER_TANK];

/**
 *
 * @param code
 */
export const hasCapacity = (code) => withCapacity.includes(code);
