/* eslint-disable */
import React from 'react';
import TerritoryCharacteristicTable from '../TerritoryCharacteristicTable';
import { WithReduxFormSelect } from 'app/components/common/SelectField';

import {
  elementFormGrid,
  renderCustomTextField,
} from 'app/components/card/common/grid';

function getElements(editable, required, props) {
  return [
    {
      id: 'oopt_meaning_id',
      name: 'oopt_meaning_id',
      formValue: true,
      editable,
      required,
      component: WithReduxFormSelect,
      addition: {
        label: 'Значение ООПТ',
        options: props.ooptMeanings,
        required,
      },
    },
    {
      id: 'oopt_category_id',
      name: 'oopt_category_id',
      formValue: true,
      editable,
      required,
      component: WithReduxFormSelect,
      addition: {
        label: 'Категория ООПТ',
        options: props.ooptCategories,
        required,
      },
    },
    {
      id: 'oopt_profile_id',
      name: 'oopt_profile_id',
      formValue: true,
      editable,
      component: WithReduxFormSelect,
      addition: {
        label: 'Профиль ООПТ',
        options: props.ooptProfiles,
        required,
      },
    },
    {
      id: 'clustering',
      name: 'clustering',
      label: 'Кластерность',
      editable,
      formValue: true,
      component: renderCustomTextField,
      addition: {
        type: 'text',
        digits: 0,
        mask: '9?',
        maxValue: 10,
        formatChars: {
          9: '[1-9]',
          '?': '[0]',
        },
        required,
      },
    },
    {
      id: 'interstatus_id',
      name: 'interstatus_id',
      formValue: true,
      editable: false,
      component: WithReduxFormSelect,
      disabled: true,
      addition: {
        label: 'Международный статус',
        placeholder: '',
        options: props.ooptInterstatuses,
      },
    },
    {
      id: 'category_msop_id',
      name: 'category_msop_id',
      formValue: true,
      editable: false,
      component: WithReduxFormSelect,
      disabled: true,
      addition: {
        label: 'Категория согласно МСОП',
        placeholder: '',
        options: props.categoryMsops,
      },
    },
  ];
}
function getTableElement(editable, required) {
  return [
    {
      id: 'explication_list',
      name: 'explication_list',
      label: '',
      editable,
      style: {
        overflow: 'auto',
      },
      formValue: true,
      component: TerritoryCharacteristicTable,
      addition: {
        required,
      },
    },
  ];
}
const TerritoryCharacteristicTab = (props) => {
  const { typeId, editable, required } = props;
  return (
    <div id="territory_characteristic">
      {elementFormGrid(getElements(editable, required, props), 2)}
      <div
        id="territory_explication"
        style={{ position: 'relative', zIndex: 0 }}
      >
        {elementFormGrid(
          getTableElement(editable, required),
          1,
          'Экспликация земель',
          typeId,
          {
            required: true,
          },
        )}
      </div>
    </div>
  );
};

 
export default TerritoryCharacteristicTab;
