import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useFieldRF } from 'core/form/reduxForm/hooks/useField.RF';
import { shallow } from 'enzyme';
import React, { useState } from 'react';
import { Mock, vi } from 'vitest';

import { AddButtonRepairsTab } from './Add.Button.Repairs.Tab';

vi.mock('react', async (importOriginal) => {
  const mod = (await importOriginal()) as Record<string, unknown>; // Явное приведение к объекту;
  return {
    ...mod,
    useState: vi.fn(() => [false, vi.fn()]),
  };
});

vi.mock('app/pages/cardsOgh/hooks/useMode');
vi.mock('core/form/reduxForm/hooks/useField.RF');

/**
 * Mock Default.
 *
 * @returns {void}
 */
const mockDefault = () => {
  (useMode as Mock).mockReturnValue({});
  (useFieldRF as Mock).mockReturnValue({});
};

describe('🐛 spec Add.Button.Repairs.Tab.spec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(<AddButtonRepairsTab />);

    //❓ Assert
    expect(wrapper.text()).toBe('');
    expect(useState).toHaveBeenCalledWith(false);
  });

  it('🧪 editable', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    mockDefault();
    (useMode as Mock).mockReturnValue({ editMode: true });

    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(<AddButtonRepairsTab />);

    //❓ Assert
    expect(wrapper.text()).toBe('<AddButton /><SaveFFDialog />');
  });
});
