import { pathImprovementObject } from 'app/pages/registry/ogh/improvementObject/ImprovementObject/path.ImprovementObject';
import { useGetOghType } from 'core/utils/hooks/useGetOghType';

 
/**
 * Проверка того что является ли этот дочерний элемент, дочерним объектом "Иные объекты благоустройства".
 *
 * @param {number} parentTypeId - Id типа родителя объекта.
 * @returns {{isChildOfImprovementObject: boolean}} - Флаг отвечающий условию.
 * @example ----
 * const { isChildOfImprovementObject } =
 *     useCheckingItIsChildOfImprovementObject(444)
 * isChildOfImprovementObject // false
 */
export const useCheckingItIsChildOfImprovementObject = (
  parentTypeId,
) => {
  const { id: improvementObjectId } = useGetOghType(
    pathImprovementObject.code,
  );

  const isChildOfImprovementObject =
    improvementObjectId === parentTypeId;

  return { isChildOfImprovementObject };
};
