import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useFieldRF } from 'core/form/reduxForm/hooks/useField.RF';
import { vi } from 'vitest';

import { useSetupUnitIdField } from './useSetup.UnitId.Field';

vi.mock('app/api/hooks/autocomplete/useGetDictionary');
vi.mock('app/api/hooks/useIsEditCurrentObjectIsExternalSystem');
vi.mock('app/pages/cardsOgh/hooks/useMode');
vi.mock('core/form/reduxForm/hooks/useField.RF');

vi.mock('react', async (importOriginal) => {
  const mod = await importOriginal(); // Явное приведение к объекту;
  return {
    ...mod,
    useEffect: vi.fn((cb) => cb()),
    useRef: vi.fn((fn) => ({
      current: fn,
    })),
  };
});

/**
 * Mock Default.
 *
 * @returns {void}
 */
const mockDefault = () => {
  useMode.mockReturnValue({ editMode: true });
  useIsEditCurrentObjectIsExternalSystem.mockReturnValue(false);
  useMode.mockReturnValue({});
  useFieldRF.mockReturnValue({ onChange: vi.fn() });
};

describe('🐛 spec useSetup.UnitId.Field', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock

    //🔥 Act
    const res = useSetupUnitIdField();

    //❓ Assert
    expect(res).toStrictEqual({
      disabled: true,
      units: [],
    });
  });

  it('🧪 unit_id onChange просмотр и один элемент', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    mockDefault();
    const onChangeUnitIdSpy = vi.fn();
    useFieldRF.mockImplementation((name) => {
      switch (name) {
        case 'unit_id':
          return { onChange: onChangeUnitIdSpy };
        case 'arrange_element_type_id': {
          return { onChange: vi.fn(), value: 101001 };
        }
        default:
          return { onChange: vi.fn() };
      }
    });

    // 🧹 clear mock

    //🔥 Act
    useSetupUnitIdField();

    //❓ Assert
    expect(onChangeUnitIdSpy).toHaveBeenCalledTimes(0);
  });

  it('🧪 unit_id onChange редактирование и один элемент', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    mockDefault();

    useMode.mockReturnValue({ editMode: true });
    const onChangeUnitIdSpy = vi.fn();
    useFieldRF.mockImplementation((name) => {
      switch (name) {
        case 'unit_id':
          return { onChange: onChangeUnitIdSpy };
        case 'arrange_element_type_id': {
          return { onChange: vi.fn(), value: 101001 };
        }
        default:
          return { onChange: vi.fn() };
      }
    });

    // 🧹 clear mock

    //🔥 Act
    useSetupUnitIdField();

    //❓ Assert
    expect(onChangeUnitIdSpy).toHaveBeenCalledWith(5);
  });
});
