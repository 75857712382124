/* eslint-disable */
import React, { useState } from 'react';
import { NavigationBar } from 'core/uiKit/components/NavigationBar';
import { Paper } from 'core/uiKit/components/Paper';
import './style.scss';

 
export const HeaderWithChildren = ({ children }) => {
  const [isShowFilter, setShowFilter] = useState(true);

  const toggleShowFilter = () => {
    setShowFilter(!isShowFilter);
  };

  return (
    <Paper className={'header-control px-5'} dark={true}>
      <NavigationBar
        children={<></>}
        isShowFilter={isShowFilter}
        toggleShowFilter={toggleShowFilter}
      />
      {isShowFilter && <div style={{ width: '100%' }}>{children}</div>}
    </Paper>
  );
};
