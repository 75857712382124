import { TREES_SHRUBS } from 'app/constants/oghTypes';

export const mandatory_fields = {
  [TREES_SHRUBS]: [
    'section_num',
    'plant_type_id',
    'plantation_type_id',
    'life_form_type_id',
    'plant_type_id',
    'area',
    'state_gardening_id',
    'detailed_state_gardening_id',
    'green_num',
    'bio_group_num',
  ],
};
