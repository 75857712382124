import { useFormValuesCardRF } from 'app/actions/odsObjectActions/useFormValuesCardRF';
import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { IMPROVEMENT_OBJECT, ODH, OZN, YARD } from 'app/constants/oghTypes';
import { Button } from 'core/uiKit/components/index';
import { Menu } from 'core/uiKit/components/Menu';
import { Icon } from 'core/uiKit/material-ui';
import React, { useState } from 'react';

import { UploadToPDFMenuItem } from './items/UploadToPDF.MenuItem';
import { UploadToXLSXMenuItem } from './items/UploadToXLSX.MenuItem';

/**
 * Кнопка Экспорт данных.
 *
 * @returns {JSX.Element|null}
 */
export const DataExportButton = () => {
  const currentCard = useGetCurrentCard();

  const [anchorEl, setAnchorEl] = useState(null);

  /**
   * Функция handleClick.
   *
   * @param {Event} event - Event.
   * @returns {void}
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Функция handleClose.
   *
   * @returns {void}
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  const formValues = useFormValuesCardRF();
  const isOrphanObject = formValues?.is_orphan_object;

  if ([ODH].includes(currentCard.typeId) && isOrphanObject) {
    return null;
  }

  const isShow = [YARD, IMPROVEMENT_OBJECT, ODH, OZN].includes(
    currentCard.typeId,
  );

  const toolTip =
    currentCard.typeId === ODH
      ? 'Экспорт данных доступен для паспортизированных объектов. Выгрузка согласно утвержденной форме паспорта ОДХ (№ 501-ПП в редакции от 04.04.2023). Графические материалы в печатную форму не экспортируются.'
      : '';

  return (
    <>
      <Button
        toolTip={toolTip}
        hidden={!(isShow && currentCard.rootId)}
        color={'info'}
        variant={'contained'}
        startIcon={<Icon>file_download</Icon>}
        onClick={handleClick}
      >
        Экспорт данных
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="simple-menu"
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <UploadToXLSXMenuItem />
        <UploadToPDFMenuItem />
      </Menu>
    </>
  );
};
