import { createMessageResponse } from 'app/utils/createMessageResponse';
import {
  ButtonMUI,
  DialogActionsMUI,
  DialogContentMUI,
  DialogMUI,
  DialogTitleMUI,
} from 'core/uiKit/material-ui';
import React, { useEffect, useState } from 'react';

/**
 * Компонент DialogErrors.
 *
 * @param {object} props - Пропсы.
 * @param {string} props.errors - Ошибка.
 * @returns {JSX.Element}
 */
export const DialogErrors = ({ errors }) => {
  const [open, setOpen] = useState(false);

  /**
   * Функция handleClose.
   *
   * @returns {void}
   */
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen('errors' in errors);
  }, [errors]);

  return (
    <DialogMUI
      aria-labelledby="max-width-dialog-title"
      fullWidth={true}
      maxWidth={'sm'}
      open={open}
      onClose={handleClose}
    >
      <DialogTitleMUI id="max-width-dialog-title">
        Ошибка(и) сохранения
      </DialogTitleMUI>
      <DialogContentMUI>
        {'errors' in errors && createMessageResponse(errors)}
      </DialogContentMUI>
      <DialogActionsMUI>
        <ButtonMUI color="primary" onClick={handleClose}>
          Закрыть
        </ButtonMUI>
      </DialogActionsMUI>
    </DialogMUI>
  );
};
