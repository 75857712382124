/* eslint-disable */
import { pathAdmin } from 'app/pages/admin/path.Admin';
import { pathRoles } from 'app/pages/admin/Roles/path.Roles';
import get from 'lodash/get';
import map from 'lodash/map';
import cloneDeep from 'lodash/cloneDeep';
import fpGet from 'lodash/fp/get';
import flow from 'lodash/fp/flow';
import { actions as formActions } from 'react-redux-form';

import * as loaderActions from './loader';
import * as api from 'app/api';
import { showError } from 'app/actions/dialog';

function createLevels(privilege, levels = []) {
  privilege.forEach(function (el, i) {
    levels[i] = {};
    if (el.element_list) {
      levels[i].element_list = [];
      const checkedItem = el.element_list.find(function (el2) {
        return el2.is_check;
      });
      if (checkedItem) {
        levels[i].isOpened = false;
      }
      createLevels(el.element_list, levels[i].element_list);
    }
  });
  return levels;
}

/* eslint-disable */
export function fetchFormData(data) {
  return function (dispatch) {
    dispatch(formActions.reset('rrf.admin.role'));
    dispatch(loaderActions.show());

    return api
      .getAdminRole(data)
      .then(function (formData) {
        const { name, description: comment, privilege } = formData;
        let createDate = '';
        if (formData.create_date) {
          const date = formData.create_date.split('.');
          createDate = new Date(date[2], date[1] - 1, date[0]);
        }
        dispatch(
          formActions.change('rrf.admin.role.properties', {
            name: name || '',
            comment: comment || '',
            createDate,
          }),
        );
        dispatch(
          formActions.change('rrf.admin.role.levels', createLevels(privilege)),
        );
        dispatch(formActions.change('rrf.admin.role.functions', privilege));
        return null;
      })
      .catch(flow(showErrorMessage, dispatch))
      .finally(function () {
        dispatch(loaderActions.hide());
      });
  };
}

/* eslint-disable */
export function onCreateSubmit(values) {
  return postRole(values, api.postAdminRole);
}

/* eslint-disable */
export function onCopySubmit(values) {
  return postRole(values, api.postCopyRole);
}

function postRole(values, apiCall) {
  return function (dispatch) {
    dispatch(loaderActions.show());
    const { functions, properties } = values;
    const { name, comment } = properties;

    return apiCall({ name, comment, privilege: JSON.stringify(functions) })
      .then(function (data) {
        dispatch(() => (window.location = `/a/role/${data.id}`));
        return null;
      })
      .catch(flow(showErrorMessage, dispatch))
      .finally(dispatch(loaderActions.hide()));
  };
}

/* eslint-disable */
export function onUpdateSubmit(values) {
  return function (dispatch) {
    dispatch(loaderActions.show());
    const { functions, properties, roleId } = values;
    const { name, comment } = properties;

    return api
      .putAdminRole({
        name,
        comment,
        roleId,
        privilege: JSON.stringify(functions),
      })
      .then(function (data) {
        if (data.error) {
          dispatch(
            formActions.change('rrf.admin.role.errorDialog', data.error),
          );
        } else {
          dispatch(() => (window.location = `/a/role/${roleId}`));
        }
        return null;
      })
      .catch(flow(showErrorMessage, dispatch))
      .finally(dispatch(loaderActions.hide()));
  };
}

const getMessage = fpGet('message');
const showErrorMessage = flow(getMessage, showError);

// TODO общая система диалогов (см. ModalRoot - MODAL_TYPE_CONFIRMATION)
/* eslint-disable */
export function closeRemoveDialog() {
  return function (dispatch) {
    dispatch(formActions.change('rrf.admin.role.removeDialog', ''));
  };
}

/* eslint-disable */
export function removeRoleFromDialog() {
  return function (dispatch, getState) {
    dispatch(loaderActions.show());
    const roleId = getState().rrf.admin.role.removeDialog;

    return api
      .deleteAdminRole({ id: roleId })
      .then(function (data) {
        dispatch(closeRemoveDialog());
        if (data.error) {
          dispatch(
            formActions.change('rrf.admin.role.errorDialog', data.error),
          );
        } else {
          dispatch(
            () => (window.location = `/${pathAdmin.path}/${pathRoles.path}`),
          );
        }
        return null;
      })
      .catch(function (err) {
        dispatch(closeRemoveDialog());
        dispatch(showErrorMessage(err));
        // dispatch(formActions.change('rrf.admin.role.errorDialog', err.message))
      })
      .finally(function () {
        dispatch(loaderActions.hide());
      });
  };
}

function setAllChilds(privilege, value) {
  privilege.forEach(function (el) {
    el.is_check = value;
    if (el.element_list) {
      setAllChilds(el.element_list, value);
    }
  });
  return privilege;
}

function findUncheckedChild(privilege) {
  return privilege.find(function (el) {
    return !el.is_check;
  });
}

function findIndexCodeElem(parentItem, code) {
  return parentItem.element_list.findIndex(function (el) {
    return el.code === code;
  });
}

const deps = {
  view_ogh: ['diff_ogh', 'create_ogh', 'edit_ogh', 'del_ogh'],
  view_title: ['create_title', 'del_title'],
  view_matching_ogh: ['send_matching_ogh', 'matching_ogh'],
  view_matching_title: ['send_matching_title', 'matching_title'],
  view_user: ['reg_user', 'edit_user', 'lock_unlock_user'],
  view_group: ['edit_group', 'create_group', 'del_group', 'copy_group'],
};

/* eslint-disable */
export function formCheckboxChange(values) {
  return function (dispatch, getState) {
    const { isStep = false } = values;
    dispatch(
      formActions.change(
        'rrf.admin.role.functions' + values.model,
        values.value,
      ),
    );

    const functions = getState().rrf.admin.role.functions;
    const modelObj = values.model.split('.').slice(1, -1);
    const item = get(functions, modelObj);
    const parentModelObj = modelObj.slice(0, -2);
    const parentItem = get(functions, parentModelObj);

    if (parentItem) {
      if (findUncheckedChild(parentItem.element_list)) {
        dispatch(
          formCheckboxChange({
            model: `.${parentModelObj.join('.')}.is_check`,
            value: 0,
            isStep: true,
          }),
        );
      } else {
        dispatch(
          formCheckboxChange({
            model: `.${parentModelObj.join('.')}.is_check`,
            value: 1,
            isStep: true,
          }),
        );
      }
    }

    if (!isStep) {
      let newPrivilege;
      if (values.value) {
        newPrivilege = setAllChilds(cloneDeep(item.element_list), 1);
        // отмечать зависимости
        if (parentItem) {
          map(deps, function (val, code) {
            if (deps[code].includes(item.code)) {
              const depIndex = findIndexCodeElem(parentItem, code);
              if (depIndex !== -1) {
                dispatch(
                  formCheckboxChange({
                    model: `.${parentModelObj.join(
                      '.',
                    )}.element_list.${depIndex}.is_check`,
                    value: 1,
                  }),
                );
              }
            }
          });
        }
      } else {
        newPrivilege = setAllChilds(cloneDeep(item.element_list), 0);
      }
      dispatch(
        formActions.change(
          `rrf.admin.role.functions.${modelObj.join('.')}.element_list`,
          newPrivilege,
        ),
      );
    }
  };
}
