/* eslint-disable  */
import {
  useFetchOghTable,
  useFetchOghTableCount,
} from 'app/pages/registry/ogh/api';
import { columnsBasePgm } from 'app/pages/registry/ogh/basePgm/BasePgm/columns.BasePgm';
import { useFilterQueryParamsBasePgm } from 'app/pages/registry/ogh/basePgm/BasePgm/Filter.BasePgm';
import { Table, useParamsTable } from 'core/uiKit/components/tables/Table';
import React from 'react';

/**
 *
 * @param root0
 * @param root0.typeId
 */
export const TableBasePgm = ({ typeId }) => {
  const { prepareFilters } = useFilterQueryParamsBasePgm();
  const { queryTable } = useParamsTable();

  const queryKey = [
    'TableBasePgm',
    { ...queryTable, ...prepareFilters, typeId },
  ];

  const { data: table, isLoading } = useFetchOghTable(queryKey);
  const { data: { count } = {} } = useFetchOghTableCount(queryKey);

  return (
    <Table
      columns={columnsBasePgm}
      data={table || []}
      isLoading={isLoading}
      total={count}
    />
  );
};
