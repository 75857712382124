import { DeleteButton } from 'core/uiKit/components/buttons/actionsButtons/Delete.Button';
import React from 'react';

import { useDeleteOghButton } from './useDeleteOgh.Button';

/**
 * Кнопка __Удалить__.
 *
 * @returns {React.ReactElement}
 */
export const DeleteOghButton = () => {
  const { onDelete, isShow } = useDeleteOghButton();
  return isShow ? <DeleteButton onClick={onDelete} /> : null;
};
