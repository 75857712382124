import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useFieldRF } from 'core/form/reduxForm/hooks/useField.RF';
import { RANDOM_BOOL } from 'core/forTesting/constants';
import { COATING_GROUP_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingGroupId';
import { COATING_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingTypeId';
import { useSkipFirstRender } from 'core/utils/hooks/useSkipFirstRender';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import { CoatingTypeIdFiled } from './CoatingTypeId.Filed';

vi.mock('app/api/hooks/useGetParentCard');
vi.mock('app/pages/cardsOgh/hooks/useMode');
vi.mock('core/form/reduxForm/hooks/useField.RF');
vi.mock('core/utils/hooks/useSkipFirstRender', () => ({
  useSkipFirstRender: vi.fn((fn) => fn()),
}));

/**
 * Mock Default.
 *
 * @returns {void}
 */
const mockDefault = () => {
  useGetParentCard.mockReturnValue({});
  useMode.mockReturnValue({});
  useFieldRF.mockReturnValue({ onChange: vi.fn(), value: 1 });
};

describe('spec CoatingtypeIdFiled', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    mockDefault();

    //🔥 Act
    const wrapper = shallow(<CoatingTypeIdFiled />);

    //❓ Assert
    expect(wrapper.text()).toBe('<CoatingTypeIdRF />');
  });

  it('🧪 props', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    mockDefault();
    useMode.mockReturnValue({ editMode: RANDOM_BOOL });

    //🔥 Act
    const wrapper = shallow(<CoatingTypeIdFiled />);

    //❓ Assert
    expect(wrapper.props()).toStrictEqual({
      disabled: !RANDOM_BOOL,
      filter: expect.any(Function),
    });
  });

  it('🧪 filter пустой', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    mockDefault();
    useMode.mockReturnValue({ editMode: RANDOM_BOOL });

    const wrapper = shallow(<CoatingTypeIdFiled />);
    //🔥 Act
    const res = wrapper.prop('filter')([]);
    //❓ Assert
    expect(res).toStrictEqual([]);
  });

  it('🧪 filter просмотр. доступны все поля без фильтрации', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    mockDefault();

    useGetParentCard.mockReturnValue({ typeId: 38 });

    const wrapper = shallow(<CoatingTypeIdFiled />);
    //🔥 Act
    const res = wrapper.prop('filter')(data);
    //❓ Assert
    expect(res).toStrictEqual([
      {
        code: '76',
        group_id: 3,
        id: 76,
        name: 'TerraWay',
        ogh_object_type_list: [
          {
            ogh_object_type_id: 49,
            other_improvement_object_type_list: [],
          },
          {
            ogh_object_type_id: 38,
            other_improvement_object_type_list: [],
          },
          {
            ogh_object_type_id: 40,
            other_improvement_object_type_list: [],
          },
        ],
      },
      {
        code: 'acryl',
        group_id: 2,
        id: 40,
        name: 'Акриловое (хард)',
        ogh_object_type_list: [
          {
            ogh_object_type_id: 49,
            other_improvement_object_type_list: [],
          },
          {
            ogh_object_type_id: 40,
            other_improvement_object_type_list: [],
          },
          {
            ogh_object_type_id: 39,
            other_improvement_object_type_list: [],
          },
        ],
      },
      {
        code: 'asphalt_crumb',
        group_id: 1,
        id: 45,
        name: 'Асфальтная крошка',
        ogh_object_type_list: [
          {
            ogh_object_type_id: 49,
            other_improvement_object_type_list: [],
          },
          {
            ogh_object_type_id: 40,
            other_improvement_object_type_list: [],
          },
          {
            ogh_object_type_id: 38,
            other_improvement_object_type_list: [],
          },
          {
            ogh_object_type_id: 39,
            other_improvement_object_type_list: [],
          },
        ],
      },
    ]);
  });

  it('🧪 filter редактирование', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    mockDefault();
    useMode.mockReturnValue({ editMode: true });
    useGetParentCard.mockReturnValue({ typeId: 38 });
    useFieldRF.mockReturnValue({ onChange: vi.fn(), value: 3 });

    const wrapper = shallow(<CoatingTypeIdFiled />);
    //🔥 Act
    const res = wrapper.prop('filter')(data);
    //❓ Assert
    expect(res).toStrictEqual([
      {
        code: '76',
        group_id: 3,
        id: 76,
        name: 'TerraWay',
        ogh_object_type_list: [
          {
            ogh_object_type_id: 49,
            other_improvement_object_type_list: [],
          },
          {
            ogh_object_type_id: 38,
            other_improvement_object_type_list: [],
          },
          {
            ogh_object_type_id: 40,
            other_improvement_object_type_list: [],
          },
        ],
      },
    ]);
  });

  it('🧪 очищаем инпут при изменении Вид покрытия при редактировании', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    mockDefault();
    useMode.mockReturnValue({ editMode: true });

    const onChangeCoatingTypeIdSpy = vi.fn();
    useFieldRF.mockImplementation((name) => {
      switch (name) {
        case COATING_TYPE_ID_NAME:
          return { onChange: onChangeCoatingTypeIdSpy, value: 3 };
        default:
          return { onChange: vi.fn(), value: 3 };
      }
    });

    //🔥 Act
    shallow(<CoatingTypeIdFiled />);

    //❓ Assert
    expect(onChangeCoatingTypeIdSpy).toHaveBeenCalledWith(null);
  });

  it('🧪 не очищаем инпут при изменении Вид покрытия при просмотре', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    mockDefault();
    useMode.mockReturnValue({ editMode: false });

    const onChangeCoatingTypeIdSpy = vi.fn();
    useFieldRF.mockImplementation((name) => {
      switch (name) {
        case COATING_TYPE_ID_NAME:
          return { onChange: onChangeCoatingTypeIdSpy, value: 3 };
        default:
          return { onChange: vi.fn(), value: 3 };
      }
    });

    //🔥 Act
    shallow(<CoatingTypeIdFiled />);

    //❓ Assert
    expect(onChangeCoatingTypeIdSpy).not.toHaveBeenCalledWith();
  });

  it('🧪 useSkipFirstRender вызываем с нужными параметрами', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    mockDefault();
    useMode.mockReturnValue({ editMode: false });

    useFieldRF.mockImplementation((name) => {
      switch (name) {
        case COATING_GROUP_ID_NAME:
          return { onChange: vi.fn(), value: 'COATING_GROUP_ID_Value' };
        default:
          return { onChange: vi.fn(), value: 3 };
      }
    });

    //🔥 Act
    shallow(<CoatingTypeIdFiled />);

    //❓ Assert
    expect(useSkipFirstRender).toHaveBeenCalledWith(expect.any(Function), [
      'COATING_GROUP_ID_Value',
    ]);
  });
});

const data = [
  {
    code: '76',
    group_id: 3,
    id: 76,
    name: 'TerraWay',
    ogh_object_type_list: [
      {
        ogh_object_type_id: 49,
        other_improvement_object_type_list: [],
      },
      {
        ogh_object_type_id: 38,
        other_improvement_object_type_list: [],
      },
      {
        ogh_object_type_id: 40,
        other_improvement_object_type_list: [],
      },
    ],
  },
  {
    code: 'acryl',
    group_id: 2,
    id: 40,
    name: 'Акриловое (хард)',
    ogh_object_type_list: [
      {
        ogh_object_type_id: 49,
        other_improvement_object_type_list: [],
      },
      {
        ogh_object_type_id: 40,
        other_improvement_object_type_list: [],
      },
      {
        ogh_object_type_id: 39,
        other_improvement_object_type_list: [],
      },
    ],
  },
  {
    code: 'asphalt_crumb',
    group_id: 1,
    id: 45,
    name: 'Асфальтная крошка',
    ogh_object_type_list: [
      {
        ogh_object_type_id: 49,
        other_improvement_object_type_list: [],
      },
      {
        ogh_object_type_id: 40,
        other_improvement_object_type_list: [],
      },
      {
        ogh_object_type_id: 38,
        other_improvement_object_type_list: [],
      },
      {
        ogh_object_type_id: 39,
        other_improvement_object_type_list: [],
      },
    ],
  },
];
