import {
  IImprovementObjectCategoryIdProps,
  IMPROVEMENT_OBJECT_CATEGORY_ID,
  ImprovementObjectCategoryId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/ImprovementObjectCategoryId';
import React from 'react';
// @ts-ignore
import { Field, WrappedFieldProps } from 'redux-form';

interface IImprovementObjectCategoryIdRFWithReduxFormProps
  extends React.HTMLAttributes<HTMLSelectElement> {
  input?: WrappedFieldProps<unknown>; // явно указываем тип для input
}

/**
 * Форма для выбора категории озеленения.
 *
 * @param root0 - Props по умолчанию.
 * @param root0.input - Поле ввода.
 * @returns JSX.Element.
 */
const ImprovementObjectCategoryIdRFWithReduxForm = ({
  input,
  ...props
}: IImprovementObjectCategoryIdRFWithReduxFormProps) => {
  return <ImprovementObjectCategoryId {...input} {...props} />;
};

/**
 * Категория озеленения RF.
 *
 * @param props - Props.
 * @returns JSX.Element.
 */
export const ImprovementObjectCategoryIdRF = (
  props: IImprovementObjectCategoryIdProps,
) => {
  return (
    <Field
      {...props}
      name={IMPROVEMENT_OBJECT_CATEGORY_ID}
      component={ImprovementObjectCategoryIdRFWithReduxForm}
    />
  );
};
