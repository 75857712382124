import { CoatingFaceTypeId } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingFaceTypeId';
import React from 'react';

/**
 * Обертка final Form для поля __Вид покрытия облицовка__.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.input - Значения поля предоставляет FF.
 * @param {object} props.otherProps - Остальные пропсы.
 * @returns {JSX.Element}
 */
export const CoatingFaceTypeIdWithFF = ({ input, ...otherProps }) => {
  return <CoatingFaceTypeId {...input} {...otherProps} />;
};
