// 30 Дорожно-тропиночные сетиimport CardAbstract from 'app/components/card/common/CardAbstract';
import CardAbstract from 'app/components/card/common/CardAbstract';
import {
  elementFormGrid,
  renderAbutmentList,
} from 'app/components/card/common/grid';
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { objectTabs } from 'app/components/card/common/tabs';
import {
  isOneNotZero,
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation/index';
import { IsDiffHeightMarkField } from 'app/components/card/ogh/components/fields/checkBoxFields/IsDiffHeightMark.Field';
import {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import { SpreadDts } from 'app/components/card/ogh/dts/components/SpreadDts';
import printOghCardHeader from 'app/components/card/ogh/header/oghHeader';
import submitCard from 'app/components/card/ogh/submit';
import VersionsTemplate from 'app/components/card/ogh/versions/VersionsTemplate';
import { DTS } from 'app/constants/oghTypes';
import getCoatingTypes from 'app/selectors/card/getCoatingTypes';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import getFormValues from 'app/selectors/form/getFormValues';
import getDict from 'app/selectors/getDict';
import { transformValues } from 'app/utils/form/transformation';
import { NumberFieldRF } from 'core/form/reduxForm/fields';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import { NoCalcRF } from 'core/form/reduxForm/fields/prepared/checkboxes/NoCalc.RF';
import { CleaningTypeRF } from 'core/form/reduxForm/fields/prepared/selects/simple/CleaningType.RF';
import { validateWithTypeOfCoating } from 'core/form/reduxForm/fields/prepared/selects/simple/CleaningType.RF/validateWithTypeOfCoating';
import { CoatingGroupIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/CoatingGroupId.RF';
import { CoatingTypeIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/CoatingTypeId.RF';
import { OtherDocumentsRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/OtherDocuments.RF';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/textField/TotalAreaGeo.RF';
import { CardContainer } from 'core/uiKit/components/card.components/CardContainer';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { TabsCardOGH } from 'core/uiKit/components/card.components/TabsCardOGH';
import { TabsCardOGHItem } from 'core/uiKit/components/card.components/TabsCardOGH/TabsCardOGH.Item';
import {
  AUTO_CLEANING_TYPE,
  MANUAL_CLEANING_TYPE,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/CleaningType/cleaningTypes';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';

const types = ['dts'];
const Versions = VersionsTemplate(types);

const propTypes = {
  abutmentTypes: PropTypes.array,
  card: PropTypes.object,
  changeFieldValue: PropTypes.func,
  coatingTypes: PropTypes.array,
  createMode: PropTypes.bool,
  documents: PropTypes.array,
  dtsTypes: PropTypes.array,
  editMode: PropTypes.bool,
  formValues: PropTypes.object,
  mode: PropTypes.object,
  required: PropTypes.bool,
};

/**
 * Карточка Дорожно-тропиночные сети.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
const DtsCard = (props) => {
  const {
    card,
    mode,
    formValues,
    changeFieldValue,
    required,
    editMode,
    isEditCurrentObjectIsExternalSystem,
  } = props;
  const { type_id: typeId } = card;

  return (
    <CardContainer>
      <CardAbstract>
        <div id="editor-card">
          {printOghCardHeader(card, mode)}
          {card && objectTabs(typeId)}
          <div id="props">
            <FieldsSplitColumns>
              <SelectRF
                label={'Назначение'}
                name={'dts_type_id'}
                options={props.dtsTypes}
                required={required}
                disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
              />
              <ParentInfo />
              <NoCalcRF disabled={!editMode} />
              <IsDiffHeightMarkField />
            </FieldsSplitColumns>

            <TabsCardOGH activeTab={'Документы'}>
              <TabsCardOGHItem tabTitle={'Исходные данные'}>
                <FieldsSplitColumns>
                  <CoatingGroupIdRF
                    disabled={
                      !(editMode && isEditCurrentObjectIsExternalSystem)
                    }
                    label={'Вид покрытия'}
                    withAll={false}
                    required
                    withChange={() => changeFieldValue('coating_type_id', null)}
                  />
                  <CoatingTypeIdRF
                    withAll={false}
                    required={true}
                    label={'Вид покрытия (уточнение)'}
                    disabled={
                      !(
                        editMode &&
                        get(formValues, 'coating_group_id') &&
                        isEditCurrentObjectIsExternalSystem
                      )
                    }
                    filter={(dict) => {
                      if (props.coatingTypes) {
                        return props.coatingTypes;
                      }
                      return dict;
                    }}
                  />
                  <CleaningTypeRF disabled={!editMode} required />
                  <SpreadDts
                    editMode={
                      !(editMode && isEditCurrentObjectIsExternalSystem)
                    }
                  />
                  <NumberFieldRF
                    label={'Площадь, кв.м'}
                    name={'total_area'}
                    disabled={
                      !(editMode && isEditCurrentObjectIsExternalSystem)
                    }
                    decimalScale={2}
                  />
                  <TotalAreaGeoRF disabled={true} decimalScale={2} />
                </FieldsSplitColumns>
              </TabsCardOGHItem>
              <TabsCardOGHItem tabTitle={'Элемент сопряжения'} hide={editMode}>
                {elementFormGrid(
                  [
                    {
                      addition: {
                        abutmentTypes: props.abutmentTypes,
                      },
                      component: renderAbutmentList,
                      editable: editMode,
                      formValue: true,
                      id: 'abutment_type_list',
                      name: 'abutment_type_list',
                      style: {
                        overflow: 'auto',
                      },
                    },
                  ],
                  1,
                  '',
                  typeId,
                )}
              </TabsCardOGHItem>
              <TabsCardOGHItem tabTitle={'Документы'}>
                <OtherDocumentsRF
                  disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
                />
              </TabsCardOGHItem>
            </TabsCardOGH>
          </div>
          <div hidden={true} id="versions">
            <Versions
              card={card}
              disabled={props.createMode}
              key={`versions-${card.root_id}`}
            />
          </div>
        </div>
      </CardAbstract>
    </CardContainer>
  );
};

DtsCard.propTypes = propTypes;

const getAbutmentTypes = getDict('abutmentTypes');

const dtsDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

/**
 * Функция mapStateToProps.
 *
 * @param {object} state - State redux.
 * @param {object} props - Props component.
 * @returns {object} - Стейт паспорта.
 * @example
 * export default connect(
 *   mapStateToProps,
 *   mapDispatchToProps,
 * )(reduxForm({ form: 'editorCard' })(TreesAndShrubsCard));
 */
const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);
  const result = {
    abutmentTypes: getAbutmentTypes(state),
    coatingTypes: getCoatingTypes(state, props),
    createMode: isCreateMode(state, props),
    editMode: isEditMode(state, props),
    enableReinitialize: true,
    formValues: getFormValues(state),
    initialValues: {
      ...props.card.attribute,
      // abutment_type_list: {
      //   showDialog: false,
      //   table: parse('abutment_type_list'),
      // },
      cleaning_type: parse('cleaning_type'),
      coating_group_id: parse('coating_group_id.coating_group'),
      coating_type_id: parse('coating_type_id.coating_type'),
      dts_type_id: parse('dts_type_id.dts_type'),
      endDate: parseDate(props.card.end_date),
      is_diff_height_mark: parse('is_diff_height_mark'),
      no_calc: parse('no_calc'),
      startDate: parseDate(props.card.start_date),
      total_area: parse('total_area'),
      totalAreaGeo: parse('total_area_geo'),
    },
    onSubmit: onSubmit.bind({ props }),
    required: isRequired(state, props),
    validate,
  };

  return dtsDocumentsReducer(result, state, props);
};

/**
 * Функция валидации формы.
 *
 * @param {object} values - Значения формы.
 * @returns {object} - Ошибку.
 * @example
 * const mapStateToProps = (state, props) => {
 *   return {
 *     enableReinitialize: true,
 *     initialValues: {
 *       startDate: parseDate(props.card.start_date),
 *       endDate: parseDate(props.card.end_date),
 *     onSubmit: onSubmit.bind({ props }),
 *     validate,
 *   };
 * };
 */
function validate(values) {
  return {
    ...validateNumeric(values, numericFields),
    ...isOneNotZero(values),
  };
}

/**
 * Функция создания атрибутов (наверное).
 *
 * @param {object} formValues - Значения формы.
 * @returns {{file_list: *}} - Атрибуты.
 * @example
 * const formValuesWithAttribute = preparationValues({
 *   ...values,
 *   ...createAttribute(values),
 * });
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

const numericFields = [
  {
    name: 'total_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'manual_clean_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'auto_clean_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
];

const dictFields = [
  'dts_type_id',
  'coating_type_id',
  'abutment_type_id',
  'coating_group_id',
];

const mandatoryFields = {
  [DTS]: [
    'dts_type_id',
    'coating_group_id',
    'coating_type_id',
    'total_area',
    'cleaningType',
  ],
};

/**
 * Функция Submit.
 *
 * @param {object} formValues - Значения формы.
 * @returns {void} - Жопа.
 * @example
 * const mapStateToProps = (state, props) => {
 *   return {
 *     enableReinitialize: true,
 *     initialValues: {
 *       startDate: parseDate(props.card.start_date),
 *       endDate: parseDate(props.card.end_date),
 *     onSubmit: onSubmit.bind({ props }),
 *     validate,
 *   };
 * };
 */
export function onSubmit(formValues) {
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };

  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[this.props.card.type_id],
    validateWithTypeOfCoating,
  );

  // форматирование в число - пока так
  ['snow_clean_area', 'meadow_lowland_area', 'spread'].forEach((property) => {
    formValuesWithAttribute[property] = Number(
      formValuesWithAttribute[property],
    );
  });

  if (
    formValuesWithAttribute.total_area &&
    formValuesWithAttribute.total_area > 0
  ) {
    if (formValuesWithAttribute.cleaningType === MANUAL_CLEANING_TYPE) {
      formValuesWithAttribute.manual_clean_area =
        formValuesWithAttribute.total_area;
    }
    if (formValuesWithAttribute.cleaningType === AUTO_CLEANING_TYPE) {
      formValuesWithAttribute.auto_clean_area =
        formValuesWithAttribute.total_area;
    }
    delete formValuesWithAttribute.cleaningType;
  }

  if (get(formValuesWithAttribute, 'abutment_type_list.table[0]')) {
    formValuesWithAttribute.abutment_type_list =
      formValuesWithAttribute.abutment_type_list.table;
  } else {
    delete formValuesWithAttribute.abutment_type_list;
  }

  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, {
      dictFields,
      numericFields,
    }),
  );
}

/**
 * MapDispatchToProps Деревья/Кустарники.
 *
 * @param {Function} dispatch - Функция dispatch.
 * @returns {{changeFieldValue: Function}} - Экшены.
 */
function mapDispatchToProps(dispatch) {
  return {

    /**
     * Изменения поля.
     *
     * @param {string} field - Имя поля.
     * @param {*} value - Значение.
     */
    changeFieldValue: (field, value) => {
      dispatch(change('editorCard', field, value));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'editorCard' })(DtsCard));
