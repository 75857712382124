import { get as getProjection, transform } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import proj4 from 'proj4';

const def =
  '+proj=tmerc +lat_0=55.66666666667 +lon_0=37.5 +k=1 +x_0=12 +y_0=14 +ellps=bessel +towgs84=316.151,78.924,589.65,-1.57273,2.69209,2.34693,8.4507 +units=m +no_defs';
proj4.defs('EPSG:MSK77', def);
register(proj4);

const msk77Projection = getProjection('EPSG:MSK77');
const wgs84Projection = getProjection('EPSG:4326');

/**
 * Преобразование точки из мск77 в geoJSON.
 *
 * @param coordinate - Точка.
 * @returns Array.
 */
export const toGeoJSON = (coordinate: Array<number>) => {
  return transform(coordinate, msk77Projection!, wgs84Projection!);
};
