import { getCurrentDate } from 'app/utils/date/getCurrentDate';
import { START_DATE_NAME } from 'core/form/finalForm/fields/prepared/datePickers/keyboardDatePickerFF/StartDate/StartDate';
import { DndFileUploadFF } from 'core/form/finalForm/fields/prepared/fileUpload/DndFileUploadFF/index';
import React from 'react';
import { useField } from 'react-final-form';

export const DOCUMENT_FILE_NAME = 'documentFile';

/**
 * Компонент получения документов Документ обоснование .
 *
 * @returns {JSX.Element}
 */
export const DocumentApproveElectronicSignatureField = () => {
  const { input: { value: startDate = [] } = {} } =
    useField(START_DATE_NAME) || {};

  const disabled = +startDate <= +getCurrentDate();

  return (
    // @ts-ignore
    <DndFileUploadFF
      accept={['png', 'jpg', 'jpeg', 'pdf']}
      name={DOCUMENT_FILE_NAME}
      disabled={disabled}
      allowedMessage={'Допустимые форматы - '}
      text={'Требуется документ-основание'}
    />
  );
};
