import { filterInput } from 'core/form/filterForm/components/filterInputsClasses';
import {
  RED_BOOK_PLANT_ID_NAME,
  RedBookPlantIdFF,
} from 'core/form/finalForm/fields/prepared/selects/simple/RedBookPlantId.FF';
import { NumberParam } from 'use-query-params';

import { usePreparationRedBookPlantIdFilter } from './usePreparation.RedBookPlantIdFilter';

 
/* eslint-disable */
/**
 * Вид растения
 * name = red_book_plant
 * @returns {JSX.Element}
 * @constructor
 */
 
export const RedBookPlantIdFilter = () => {
  usePreparationRedBookPlantIdFilter();
  return <RedBookPlantIdFF className={filterInput} />;
};

 
export const redBookPlantIdParams = {
  [RED_BOOK_PLANT_ID_NAME]: NumberParam,
};
