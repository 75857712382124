/* eslint-disable */
import { useGetOghTypes } from 'core/utils/hooks';
import { get, snakeCase } from 'lodash';
import { useLocation } from 'react-router-dom';

export const usePrepareTree = (tree = [], root = '') => {
  const oghTypes = useGetOghTypes();
  const { pathname } = useLocation();

  const activePath = pathname.split('/').pop();
  const pr = (arr) =>
    arr.reduce((acc, curr) => {
      const { path, url, name: nameTreeItem, children = false } = curr || {};

      const name = get(
        oghTypes,
        `${snakeCase(path)}.name`,
        nameTreeItem || '___',
      );

      let link = path;

      if (path && root) {
        link = `${root}/${path}`;
      } else if (url) {
        link = url;
      }

      if (Array.isArray(children)) {
        acc.push({
          name,
          link,
          children: pr(children),
          active: path === activePath,
        });
        return acc;
      }

      acc.push({ name, link, active: path === activePath });
      return acc;
    }, []);
  return pr(tree);
};
