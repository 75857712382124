import { useEffect } from 'react';

import { getFlagMultiSelect } from './helpers/getFlagMultiSelect';
import { getFlagSelect } from './helpers/getFlagSelect';

/**
 * Хук очищение полей при несоотвествии значений из фильтра.
 *
 * @param {object} params - Входящие параметры.
 * @param {Array} params.options - Массив.
 * @param {Function} params.filter - Функция фильтрации.
 * @param {boolean} params.editMode - Флаг изменения.
 * @param {Array | number} params.value - Значение.
 * @param {Function | undefined} params.withChange - Функция сайд эффекта при изменение.
 * @param {Function} params.handleChange - Функция изменения значения value.
 * @param {string} params.optionKey - Ключ по которому достаем id.
 * @returns {void} - Жопа.
 * @example
 * useEditOnchangeEffect = ({
 *   options,
 *   filter,
 *   editMode,
 *   value,
 *   withChange,
 *   handleChange,
 *   optionKey,
 * })
 */
export const useEditOnchangeEffect = ({
  options,
  filter,
  editMode,
  value,
  withChange,
  handleChange,
  optionKey,
}) => {
  useEffect(() => {
    if (typeof filter === 'function') {
      const result = filter(options);
      if (editMode) {
        // В режиме редактирования ищем хотябы одно совпадение со значением из массива фильтра
        let flag;
        // Делаем проверку потому что в select и multiSelect лежат разные значения
        if (Array.isArray(value)) {
          // Если значение массив проверяем на все совпадения его значений из фильтра
          flag = getFlagMultiSelect(value, result, optionKey);
        } else {
          // Если значение число находим хотябы одно совпадения из фильтра.
          flag = getFlagSelect(value, result, optionKey);
        }

        // Если совпадение не найдено очищаем интпут в режиме редактирования
        if (!flag && !withChange) {
          handleChange({
            target: {
              value: undefined,
            },
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [editMode, options?.length]);
};
