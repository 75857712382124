import { get } from 'app/api/crud/get';
import { DTW_API_URL, DTW_LOGIN, DTW_PASSWORD } from 'config/env';

import { AuthTokensDTW_Type } from '../../../types';

/**
 * Функция получения данных карточки
 * на вход ид карточки
 * на выходе данные полей карточки
 * по ним можешь получить файлы для подписи.
 *
 * @returns Токены.
 */
export const loginToDTW = () => {
  return get<AuthTokensDTW_Type>(
    'auth/login',
    {
      login: DTW_LOGIN,
      password: DTW_PASSWORD,
    },
    {
      credentials: 'same-origin',
      host: DTW_API_URL,
    },
  );
};
// https://dtwapi.mos.ru/app/auth/login\?login=asu_ods\&password=i%2AG7p%408X
