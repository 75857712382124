import {
  CAR_EQUIPMENT_OWNER_NAME,
  CarEquipmentOwnerFF,
} from 'core/form/finalForm/fields';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';
import { ObjectParam } from 'use-query-params';

import { filterInputAutocomplete } from '../filterInputsClasses';

/**
 * CarEquipmentOwnerFilter.
 *
 * @param {object} props - Properties.
 * @returns {*}
 */
export const CarEquipmentOwnerFilter = (props) => (
  <CarEquipmentOwnerFF className={filterInputAutocomplete} {...props} />
);

/**
 * CarEquipmentOwnerParams..
 *
 * @returns {*}
 */
export const carEquipmentOwnerParams = () => {
  addPreparation(CAR_EQUIPMENT_OWNER_NAME, (value) => value.name);
  return {
    [CAR_EQUIPMENT_OWNER_NAME]: ObjectParam,
  };
};
