import { CheckboxFF } from 'core/form/finalForm/fields/default';
import { usePreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';

export const NO_CALC_NAME = 'noCalc';

/**
 * ## Разновысотные отметки.
 *
 * `type = CheckboxFF`
 * `name = is_diff_height_mark`.
 *
 * @param {*} props - Props.
 * @returns {JSX.Element}
 */
export const NoCalcFF = (props) => {
  usePreparation(NO_CALC_NAME, (value) => {
    if (value) {
      return Number(value);
    }
  });

  return (
    <CheckboxFF
      {...props}
      name={NO_CALC_NAME}
      label={props.label || 'Не учитывать'}
    />
  );
};
