import { CoatingTypeRoadIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/CoatingTypeRoadId.RF';
import React from 'react';

import { useCoatingTypeRoadIdRFTramRails } from './useCoatingTypeRoadIdRFTramRails';

/**
 * Обертка для фильтрации поля __Вид покрытия на сопряжении с проезжей частью (уточнение)__.
 *
 * @returns {JSX.Element}
 */
export const CoatingTypeRoadIdRFTramRails = () => {
  const { editMode, filter, formValues, isCurrentObjectIsExternalSystem } =
    useCoatingTypeRoadIdRFTramRails();
  return (
    <CoatingTypeRoadIdRF
      disabled={
        !(editMode && formValues?.coating_group_road_id) ||
        isCurrentObjectIsExternalSystem
      }
      required={true}
      filter={filter}
    />
  );
};
