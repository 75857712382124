/* eslint-disable */

import { USER_LOGOUT } from 'app/constants/actionTypes';
import { logout as l } from 'app/pages/auth/Login/api';
import { createAction } from 'redux-actions';

/**
 * ManualLogout.
 *
 * @returns {(function(*): void)|*}
 */
export const manualLogout = () => (dispatch) => {
  l().then(() => {
    dispatch({ type: USER_LOGOUT });
  });
};

export const logout = createAction(USER_LOGOUT);
