import { Card } from 'core/uiKit/components/Card';

import {
  FlowersGardenList,
  LawnList,
  RedBookList,
  TreesShrubsList,
  VerticalLandscapingList,
} from './tables';
import { usePlantationTab } from './usePlantationTab';

/**
 * Tab PlantationTab.
 *
 * @returns {JSX.Element}
 */
const PlantationTab = () => {
  const { expanded, handleChange } = usePlantationTab();

  return (
    <Card hidden={true} id={'plantation'} className={'p-3 mb-3'}>
      <TreesShrubsList expanded={expanded} onChange={handleChange} />
      <LawnList expanded={expanded} onChange={handleChange} />
      <FlowersGardenList expanded={expanded} onChange={handleChange} />
      <RedBookList expanded={expanded} onChange={handleChange} />
      <VerticalLandscapingList expanded={expanded} onChange={handleChange} />
    </Card>
  );
};

export default PlantationTab;
