/* eslint-disable */
export const placementDict = [
  {
    id: 20041,
    code: 'roadway_park_t',
    name: 'Между ПЧ и Парковочным пространством.ТПУ',
    fz_spr: [],
  },
  {
    id: 1,
    code: 'lawn_sidewalk',
    name: 'Между газоном  и тротуаром',
    fz_spr: [],
  },
  {
    id: 2,
    code: 'sidewalk_sidewalk',
    name: 'Между тротуаром и тротуаром',
    fz_spr: [],
  },
  {
    id: 3,
    code: 'roadway_divid',
    name: 'Между ПЧ и раделительной полосой',
    fz_spr: [],
  },
  {
    id: 4,
    code: 'roadway_sidewalk',
    name: 'Между ПЧ и тротуаром',
    fz_spr: [],
  },
  {
    id: 5,
    code: 'lawn',
    name: 'На газоне',
    fz_spr: [],
  },
  {
    id: 6,
    code: 'on_roadway',
    name: 'На проезжей части',
    fz_spr: [],
  },
  {
    id: 7,
    code: 'own',
    name: 'На своей территории',
    fz_spr: [],
  },
  {
    id: 8,
    code: 'on_sidewalk',
    name: 'На тротуаре',
    fz_spr: [],
  },
  {
    id: 9,
    code: 'other',
    name: 'Иное',
    fz_spr: [],
  },
  {
    id: 10,
    code: 'roadway_lawn',
    name: 'Между ПЧ и газоном',
    fz_spr: [],
  },
  {
    id: 11,
    code: 'roadway_entrance',
    name: 'Между ПЧ и въездом',
    fz_spr: [],
  },
  {
    id: 12,
    code: 'overpass',
    name: 'Эстакада',
    fz_spr: [],
  },
  {
    id: 13,
    code: 'bridge',
    name: 'Мост',
    fz_spr: [],
  },
  {
    id: 14,
    code: 'tunnel',
    name: 'Тоннель',
    fz_spr: [],
  },
  {
    id: 15,
    code: 'flyover',
    name: 'Путепровод',
    fz_spr: [],
  },
  {
    id: 16,
    code: 'tunnel_ramp',
    name: 'Рампа тоннеля',
    fz_spr: [],
  },
  {
    id: 17,
    code: 'substack',
    name: 'Подэстакадное пространство',
    fz_spr: [],
  },
  {
    id: 18,
    code: 'roadway',
    name: 'Проезжая часть',
    fz_spr: [],
  },
  {
    id: 19,
    code: 'sidewalk',
    name: 'Тротуар',
    fz_spr: [],
  },
  {
    id: 20,
    code: 'technical',
    name: 'Техническая площадка',
    fz_spr: [],
  },
  {
    id: 10001,
    code: 'parking_sidewalk',
    name: 'Между автостоянкой и тротуаром',
    fz_spr: [],
  },
  {
    id: 10002,
    code: 'roadway_parking',
    name: 'Между ПЧ и автостоянкой',
    fz_spr: [],
  },
  {
    id: 10003,
    code: 'parking_entrance',
    name: 'Между автостоянкой и въездом',
    fz_spr: [],
  },
  {
    id: 19999,
    code: 'curb',
    name: 'На обочине',
    fz_spr: [],
  },
  {
    id: 20000,
    code: 'stopping',
    name: 'На остановочной площадке',
    fz_spr: [],
  },
  {
    id: 20001,
    code: 'parking_lawn',
    name: 'Между автостоянкой и газоном',
    fz_spr: [],
  },
  {
    id: 20002,
    code: 'curb_lawn',
    name: 'Между обочиной и газоном',
    fz_spr: [],
  },
  {
    id: 20003,
    code: 'roadway_cycle',
    name: 'Между ПЧ и велодорожкой',
    fz_spr: [],
  },
  {
    id: 20004,
    code: 'sidewalk_cycle',
    name: 'Между тротуаром и велодорожкой',
    fz_spr: [],
  },
  {
    id: 20005,
    code: 'cycle_lawn',
    name: 'Между велодорожкой и газоном',
    fz_spr: [],
  },
  {
    id: 20006,
    code: 'roadway_park',
    name: 'Между ПЧ и Парковочным пространством',
    fz_spr: [],
  },
  {
    id: 20007,
    code: 'sidewalk_park',
    name: 'Между тротуаром и Парковочным пространством',
    fz_spr: [],
  },
  {
    id: 20008,
    code: 'cycle_park',
    name: 'Между велодорожкой и Парковочным пространством',
    fz_spr: [],
  },
  {
    id: 20009,
    code: 'park_lawn_t',
    name: 'Между Парковочным пространством и газоном',
    fz_spr: [],
  },
  {
    id: 20010,
    code: 'lawn_sidewalk_t',
    name: 'Между газоном  и тротуаром.ТПУ',
    fz_spr: [],
  },
  {
    id: 20011,
    code: 'sidewalk_sidewalk_t',
    name: 'Между тротуаром и тротуаром.ТПУ',
    fz_spr: [],
  },
  {
    id: 20012,
    code: 'roadway_divid_t',
    name: 'Между ПЧ и раделительной полосой.ТПУ',
    fz_spr: [],
  },
  {
    id: 20013,
    code: 'roadway_sidewalk_t',
    name: 'Между ПЧ и тротуаром.ТПУ',
    fz_spr: [],
  },
  {
    id: 20014,
    code: 'lawn_t',
    name: 'На газоне.ТПУ',
    fz_spr: [],
  },
  {
    id: 20015,
    code: 'on_roadway_t',
    name: 'На проезжей части.ТПУ',
    fz_spr: [],
  },
  {
    id: 20016,
    code: 'own_t',
    name: 'На своей территории.ТПУ',
    fz_spr: [],
  },
  {
    id: 20017,
    code: 'on_sidewalk_t',
    name: 'На тротуаре.ТПУ',
    fz_spr: [],
  },
  {
    id: 20018,
    code: 'other_t',
    name: 'Иное.ТПУ',
    fz_spr: [],
  },
  {
    id: 20019,
    code: 'roadway_lawn_t',
    name: 'Между ПЧ и газоном.ТПУ',
    fz_spr: [],
  },
  {
    id: 20020,
    code: 'roadway_entrance_t',
    name: 'Между ПЧ и въездом.ТПУ',
    fz_spr: [],
  },
  {
    id: 20021,
    code: 'overpass_t',
    name: 'Эстакада.ТПУ',
    fz_spr: [],
  },
  {
    id: 20022,
    code: 'bridge_t',
    name: 'Мост.ТПУ',
    fz_spr: [],
  },
  {
    id: 20023,
    code: 'tunnel_t',
    name: 'Тоннель.ТПУ',
    fz_spr: [],
  },
  {
    id: 20024,
    code: 'flyover_t',
    name: 'Путепровод.ТПУ',
    fz_spr: [],
  },
  {
    id: 20025,
    code: 'tunnel_ramp_t',
    name: 'Рампа тоннеля.ТПУ',
    fz_spr: [],
  },
  {
    id: 20026,
    code: 'substack_t',
    name: 'Подэстокадное пространство.ТПУ',
    fz_spr: [],
  },
  {
    id: 20027,
    code: 'roadway_t',
    name: 'Проезжая часть.ТПУ',
    fz_spr: [],
  },
  {
    id: 20028,
    code: 'sidewalk_t',
    name: 'Тротуар.ТПУ',
    fz_spr: [],
  },
  {
    id: 20029,
    code: 'technical_t',
    name: 'Техническая площадка.ТПУ',
    fz_spr: [],
  },
  {
    id: 20030,
    code: 'not_t',
    name: 'Нет данных.ТПУ',
    fz_spr: [],
  },
  {
    id: 20031,
    code: 'parking_sidewalk_t',
    name: 'Между автостоянкой и тротуаром.ТПУ',
    fz_spr: [],
  },
  {
    id: 20032,
    code: 'roadway_parking_t',
    name: 'Между ПЧ и автостоянкой.ТПУ',
    fz_spr: [],
  },
  {
    id: 20033,
    code: 'parking_entrance_t',
    name: 'Между автостоянкой и въездом.ТПУ',
    fz_spr: [],
  },
  {
    id: 20034,
    code: 'curb_t',
    name: 'На обочине.ТПУ',
    fz_spr: [],
  },
  {
    id: 20035,
    code: 'stopping_t',
    name: 'На остановочной площадке.ТПУ',
    fz_spr: [],
  },
  {
    id: 20036,
    code: 'parking_lawn_t',
    name: 'Между автостоянкой и газоном.ТПУ',
    fz_spr: [],
  },
  {
    id: 20037,
    code: 'curb_lawn_t',
    name: 'Между обочиной и газоном.ТПУ',
    fz_spr: [],
  },
  {
    id: 20038,
    code: 'roadway_cycle_t',
    name: 'Между ПЧ и велодорожкой.ТПУ',
    fz_spr: [],
  },
  {
    id: 20039,
    code: 'sidewalk_cycle_t',
    name: 'Между тротуаром и велодорожкой.ТПУ',
    fz_spr: [],
  },
  {
    id: 20040,
    code: 'cycle_lawn_t',
    name: 'Между велодорожкой и газоном.ТПУ',
    fz_spr: [],
  },
  {
    id: 0,
    code: 'not',
    name: 'Нет данных',
    fz_spr: [],
  },
  {
    id: 20042,
    code: 'sidewalk_park_t',
    name: 'Между тротуаром и Парковочным пространством.ТПУ',
    fz_spr: [],
  },
  {
    id: 20043,
    code: 'cycle_park_t',
    name: 'Между велодорожкой и Парковочным пространством.ТПУ',
    fz_spr: [],
  },
  {
    id: 20044,
    code: 'park_lawn_t',
    name: 'Между Парковочным пространством и газоном.ТПУ',
    fz_spr: [],
  },
  {
    id: 20045,
    code: 'other_terr',
    name: 'Прочие территории',
    fz_spr: ['spr13'],
  },
  {
    id: 20070,
    code: 'outside',
    name: 'За границей ОДХ',
    fz_spr: [],
  },
  {
    id: 20080,
    code: 'own_terr',
    name: 'На своей территории (в границе ОДХ)',
    fz_spr: [],
  },
  {
    id: 20046,
    code: '1',
    name: 'На газоне',
    fz_spr: ['spr23'],
  },
  {
    id: 20047,
    code: '2',
    name: 'На цоколе (на своей территории)',
    fz_spr: ['spr23'],
  },
  {
    id: 20048,
    code: '3',
    name: 'Обочина',
    fz_spr: ['spr23', 'spr13'],
  },
  {
    id: 20049,
    code: '4',
    name: 'Проезжая часть',
    fz_spr: ['spr23', 'spr13'],
  },
  {
    id: 20050,
    code: '5',
    name: 'Проезжая часть.Мост',
    fz_spr: ['spr23'],
  },
  {
    id: 20051,
    code: '6',
    name: 'Подпорная стенка',
    fz_spr: ['spr23'],
  },
  {
    id: 20052,
    code: '7',
    name: 'Проезжая часть.Путепровод',
    fz_spr: ['spr23'],
  },
  {
    id: 20053,
    code: '8',
    name: 'Проезжая часть.Рампа тоннеля',
    fz_spr: ['spr23'],
  },
  {
    id: 20054,
    code: '9',
    name: 'Проезжая часть.Эстакада',
    fz_spr: ['spr23'],
  },
  {
    id: 20055,
    code: '10',
    name: 'Тротуар',
    fz_spr: ['spr23', 'spr13'],
  },
  {
    id: 20056,
    code: '11',
    name: 'Тротуар.Мост',
    fz_spr: ['spr23'],
  },
  {
    id: 20058,
    code: '13',
    name: 'Тротуар.Путепровод',
    fz_spr: ['spr23'],
  },
  {
    id: 20059,
    code: '14',
    name: 'Тротуар.Рампа тоннеля',
    fz_spr: ['spr23'],
  },
  {
    id: 20060,
    code: '15',
    name: 'Тротуар.Эстакада',
    fz_spr: ['spr23'],
  },
  { id: 20090, code: 'fence', name: 'Ограждение', fz_spr: [] },
  {
    id: 20100,
    code: 'engin_construct',
    name: 'Инженерное сооружение',
    fz_spr: [],
  },
];
