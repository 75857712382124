import withDarkTheme from 'core/hocs/withDarkTheme';
import withInputMask from 'core/hocs/withInputMask';

import TextField from './TextField';

const DarkTextField = withDarkTheme(TextField);
const TextFieldWithInputMask = withInputMask(TextField);
const DarkTextFieldWithInputMask = withInputMask(DarkTextField);

 
export { DarkTextField, DarkTextFieldWithInputMask, TextFieldWithInputMask };

 
export default TextField;
