/* eslint-disable  */

import 'assets/styles/common.table.multiTable.scss';

import cn from 'classnames';
import $ from 'jquery';
import _ from 'lodash';
import PropTypes from 'prop-types';
/* eslint-disable */
import React from 'react';

class MultiTable extends React.Component {
  static defaultProps = {
    isAutoScrollTop: true,
    isAutoScrollLeft: true,
  };

  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.element,
    ]),
    className: PropTypes.string,
    baseClassName: PropTypes.string,
    isAutoScrollTop: PropTypes.bool,
    isAutoScrollLeft: PropTypes.bool,
  };

  componentDidMount() {
    // TODO нужно дожидаться загрузки всех вложенных картинок
    this.onResizeThrottle = _.throttle(this.onResize.bind(this), 10);
    this.onScrollXThrottle = _.throttle(this.onScrollX.bind(this), 10);
    this.onResizeThrottle();
    this.onScrollXThrottle();
    const $element = $(this.element);
    $(window).on('resize', this.onResizeThrottle);
    $element
      .find('[data-multitable-scroll-x]')
      .on('scroll', this.onScrollXThrottle);
  }

  componentDidUpdate() {
    this.onResizeThrottle();
    const $element = $(this.element);
    const $x = $element.find('[data-multitable-scroll-x]');
    const $y = $element.find('[data-multitable-scroll-y]');
    if (this.props.isAutoScrollTop) {
      $y.scrollTop(0);
    }
    if (this.props.isAutoScrollLeft) {
      $x.scrollLeft(0);
      setTimeout(function () {
        $(window).resize();
      }, 1);
    }
  }

  componentWillUnmount() {
    const $element = $(this.element);
    $(window).off('resize', this.onResizeThrottle);
    $element
      .find('[data-multitable-scroll-x]')
      .off('scroll', this.onScrollXThrottle);
  }

  onResize() {
    const $element = $(this.element);
    const $headTable = $element.find('[data-multitable-header] table');
    const $footTable = $element.find('[data-multitable-footer] table');
    const $bodyTable = $element.find('[data-multitable-main] table');
    const $tableBodyRow = $element.find(
      '[data-multitable-main] table tbody tr',
    );
    const bodyRowCellsSizesByNum = [];
    const bodyRowCellsSizesById = {};

    if ($tableBodyRow.length) {
      const bodyTableWidth = $bodyTable[0].getBoundingClientRect().width;
      $headTable.css('width', bodyTableWidth + 'px');
      $footTable.css('width', bodyTableWidth + 'px');

      $bodyTable
        .find('tbody > tr:first-child')
        .children()
        .each(function (i, el) {
          const width = el.getBoundingClientRect().width;
          bodyRowCellsSizesByNum[i] = width;
          const id = (el.getAttribute('data-multitable-id') || '').trim();
          if (id) {
            bodyRowCellsSizesById[id] = width;
          }
        });
    }

    if ($headTable) {
      this.updateHeadSize($headTable, {
        bodyRowCellsSizesByNum,
        bodyRowCellsSizesById,
      });
    }

    if ($footTable) {
      $footTable
        .find('tfoot > tr:first-child')
        .children()
        .each(function (i, el) {
          $(el).css('width', bodyRowCellsSizesByNum[i] + 'px');
        });
    }
  }

  onScrollX() {
    const $element = $(this.element);
    const $x = $element.find('[data-multitable-scroll-x]');
    const $y = $element.find('[data-multitable-scroll-y]');
    $y.css('margin-right', 0 - $x[0].scrollLeft + 'px');
  }

  updateHeadSize(
    $headTable,
    { bodyRowCellsSizesByNum, bodyRowCellsSizesById },
  ) {
    const $tr = $headTable.find('thead > tr');
    if ($tr.length === 1) {
      // если одна строка
      $tr.children().each(function (i, el) {
        $(el).css('width', (bodyRowCellsSizesByNum[i] || 150) + 'px');
      });
    } else {
      // если много строк то используем data-multitable-ids
      $tr.children().each(function (i, el) {
        const $el = $(el);
        const ids = _.compact(
          ($el.attr('data-multitable-ids') || '').split(' '),
        );
        let width = 0;
        ids.forEach(function (id) {
          width = width + (bodyRowCellsSizesById[id] || 150);
        });
        $(el).css('width', width + 'px');
      });
    }
  }

  render() {
    return (
      <div
        className={cn('multiTable', this.props.className)}
        ref={(element) => (this.element = element)}
      >
        <div
          className={cn('multiTable__base', this.props.baseClassName)}
          data-multitable-scroll-x={true}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

class Header extends React.Component {
  static propTypes = {
    children: PropTypes.element,
    className: PropTypes.string,
  };

  render() {
    return (
      <div
        className={cn('multiTable__header', this.props.className)}
        data-multitable-header={true}
      >
        {this.props.children}
      </div>
    );
  }
}

class Main extends React.Component {
  static propTypes = {
    children: PropTypes.element,
    className: PropTypes.string,
  };

  render() {
    return (
      <div
        className={cn('multiTable__main', this.props.className)}
        data-multitable-main={true}
        data-multitable-scroll-y={true}
      >
        {this.props.children}
      </div>
    );
  }
}

class Footer extends React.Component {
  static propTypes = {
    children: PropTypes.element,
    className: PropTypes.string,
  };

  render() {
    return (
      <div
        className={cn('multiTable__footer', this.props.className)}
        data-multitable-footer={true}
      >
        {this.props.children}
      </div>
    );
  }
}

MultiTable.Header = Header;
MultiTable.Main = Main;
MultiTable.Footer = Footer;

export default MultiTable;
