/* eslint-disable */
import React from 'react';

const renderHiddenInput = ({ input, meta, ...custom }) => {
  return (
    <input name={input.name} type="hidden" value={input.value} {...custom} />
  );
};

 
export default renderHiddenInput;
