import { validateRepairsInfoListPlan } from './validate.RepairsInfoListPlan';

describe('🐛 ValidateRepairsInfoListPlanSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act
    const res = validateRepairsInfoListPlan({});

    // ❓ Assert
    expect(res).toStrictEqual({
      date_end: 'обязательное поле',
      date_start: 'обязательное поле',
      files: 'обязательное поле',
      name: 'обязательное поле',
    });
  });

  it('🧪 required fields заполнены', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act
    const res = validateRepairsInfoListPlan({
      date_end: ' поле',
      date_start: ' поле',
      files: ' поле',
      name: ' поле',
    });

    // ❓ Assert
    expect(res).toBeNull();
  });
});
