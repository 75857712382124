import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const OOPT_CATEGORY_NAME = 'ooptCategory';

/**
 * ## Категория ООПТ.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const OoptCategory = (props) => {
  const dict = 'ooptCategory';

  const { data = [] } = useGetDictionary(dict);

  return (
    <Select
      {...props}
      options={data}
      label={'Категория ООПТ'}
      name={OOPT_CATEGORY_NAME}
    />
  );
};
