import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
/* eslint-disable  */
import { toast } from 'core/uiKit/components/Toast';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

import { clearActOfCompletedWorkElectronicSignature } from '../useGetActOfCompletedWorkElectronicSignature';
import { signActOfCompletedSave } from './signActOfCompletedSave';

/**
 * Хук получения функции подписания.
 *
 * @param {Function} setOpen - Функция изменения отображения диалогового окна подписания документа.
 * @returns {*}
 */
export const useSignActOfCompletedSave = (setOpen) => {
  const { recordId } = useGetCurrentCard();

  const [sendSignActOfCompletedSave, { ...otherParams }] = useMutationAdaptor(
    (values) => signActOfCompletedSave({ objectId: recordId, ...values }),
    {
      /**
       *
       */
      onError: () => {
        setOpen(false);
      },

      /**
       *
       */
      onSuccess: () => {
        toast.successDying('Документ успешно подписан');
        clearActOfCompletedWorkElectronicSignature();
        setOpen(false);
      },
    },
  );

  return { sendSignActOfCompletedSave, ...otherParams };
};
