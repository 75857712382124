import { usePermissionsTypesOgh } from 'app/components/MainPage/hooks/usePermissionsTypesOgh';
/* eslint-disable */
import { ODH } from 'app/constants/oghTypes';
import {
  AddressFilter,
  addressParams,
  CustomerFilter,
  customersParams,
  FilterFF,
  OGH_TYPES_NAME,
  OghTypesFilter,
  oghTypesParams,
  OwnerIdFilter,
  ownerIdParams,
} from 'core/form/filterForm';
import {
  IdOrNameFilter,
  idOrNameParams,
} from 'core/form/filterForm/components/text/IdOrName.Filter';
import { Paper } from 'core/uiKit/components/Paper';
import {
  ButtonMUI,
  ExpandLessIcon,
  ExpandMoreIcon,
} from 'core/uiKit/material-ui';
import { preparationValues } from 'core/utils/helpers/preparationValues';
import { useQueryParams } from 'core/utils/hooks/useQueryParams';
import _get from 'lodash/get';
import React, { useState } from 'react';

/**
 *
 */
export const SearchPanel = () => {
  const [isShowFilter, setShowFilter] = useState(true);
  const types = usePermissionsTypesOgh();

  /**
   *
   */
  const toggleShowFilter = () => {
    setShowFilter(!isShowFilter);
  };

  const { setFilters, filters } = useFilterQueryParamsMain();

  /**
   *
   * @param values
   */
  const onSubmit = async (values) => {
    setFilters(values, 'push');
  };

  /**
   *
   * @param form
   */
  const reset = (form) => {
    form.reset();
    setFilters({}, 'replace');
  };

  /**
   *
   * @param data
   */
  const filterOgh = (data) => {
    return data.filter((item) => types.includes(item.id));
  };

  return (
    <Paper className={'header-control px-3'} dark={true} id="search-panel">
      <div className="type-navigation-bar">
        <div className="type-navigation-bar-wrapper" />
        <ButtonMUI
          endIcon={isShowFilter ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          onClick={toggleShowFilter}
        >
          {isShowFilter ? 'Скрыть' : 'Показать'} Фильтр
        </ButtonMUI>
      </div>
      {isShowFilter && (
        <FilterFF {...{ initialValues: filters, onSubmit, reset }}>
          <IdOrNameFilter />
          <OghTypesFilter filter={filterOgh} />
          <CustomerFilter />
          <OwnerIdFilter />
          <AddressFilter />
        </FilterFF>
      )}
    </Paper>
  );
};

/**
 *
 */
export const useFilterQueryParamsMain = () => {
  const [filters, setFilters] = useQueryParams([
    idOrNameParams,
    oghTypesParams,
    customersParams,
    addressParams,
    ownerIdParams(),
  ]);
  const types = usePermissionsTypesOgh();

  /**
   *
   */
  const preparationLocal = () => {
    const pr = preparationValues(filters) || {};
    // если нет ничего в типах огх делаем выбор из заготовленных.. за место всех. такая логика
    if (!_get(pr, `${OGH_TYPES_NAME}.length`)) {
      pr[OGH_TYPES_NAME] = types.length ? types : [ODH];
    }

    return pr;
  };

  return {
    filters,
    prepareFilters: preparationLocal(),
    setFilters,
  };
};
