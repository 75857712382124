import { LinkShortIdCell } from 'core/uiKit/components/cells/LinkShortId.Cell';

 
export /* eslint-disable */
const columnsBasePgm = [
  {
    accessor: 'short_root_id',
    Cell: LinkShortIdCell,
    Header: 'ID',
    sortable: true,
  },
  {
    accessor: 'reagent_base_type_name',
    Header: 'Тип базы',
    sortable: true,
  },
  {
    accessor: 'okrug_short_name',
    Header: 'Округ',
    sortable: true,
  },
  {
    accessor: 'district_short_name',
    Header: 'Район',
    sortable: true,
  },
  {
    accessor: 'base_num',
    Header: '№ базы',
    sortable: true,
  },
  {
    accessor: 'object_name',
    Header: 'Наименование базы',
    sortable: true,
  },
  {
    accessor: 'legal_person_short_name',
    Header: 'Эксплуатирующая организация',
    sortable: true,
  },
  {
    accessor: 'reagent_kind_list',
    Header: 'Виды ПГР (макс.объем, т.)',
    sortable: true,
  },
  {
    accessor: 'reagent_balance',
    Header: 'Остатки, т.',
    sortable: true,
  },
  {
    accessor: 'object_status_name',
    Header: 'Статус',
    sortable: true,
  },
  {
    accessor: 'start_date',
    Header: 'Дата начала',
    sortable: true,
    width: 90,
  },
  {
    accessor: 'end_date',
    Header: 'Дата окончания',
    sortable: true,
    width: 120,
  },
];
