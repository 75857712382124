import { useInitialValueAbutment } from 'app/pages/cardsOgh/Abutment/hooks/useInitialValue.Abutment';
import { getFileList } from 'app/pages/cardsOgh/components/tables/Documents/helpers/getFileList';
import { getAttributeSubmit } from 'app/pages/cardsOgh/helpers/getAttributeSubmit';
import { IS_DIFF_HEIGHT_MARK_NAME } from 'core/form/finalForm/fields/prepared/checkboxes/IsDiffHeightMark.FF';
import { NO_CALC_NAME } from 'core/form/finalForm/fields/prepared/checkboxes/NoCalc.FF';
import { ABUTMENT_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/AbutmentTypeName';
import { MATERIAL_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/MaterialId';

const mapAttribute = [
  IS_DIFF_HEIGHT_MARK_NAME,
  NO_CALC_NAME,
  MATERIAL_ID_NAME,
  ABUTMENT_TYPE_ID_NAME,
];

/**
 * Setup __Элементы сопряжения поверхностей__.
 *
 * @returns
 */
export const useSetupAbutment = () => {
  const initialValues = useInitialValueAbutment();

  /**
   * Функция подготовка данных для Атрибутов.
   *
   * @param values - Значения формы.
   * @returns Атрибуты.
   */
  const preparingDataForAttributes = (values: Record<string, unknown>) => {
    return {
      file_list: getFileList(values),
      ...getAttributeSubmit(values, mapAttribute),
    };
  };

  return { initialValues, preparingDataForAttributes };
};
