import {
  BOARD_STONE,
  FENCING,
  MAF,
  OTHER_ENGIN_CONSTRUCT,
  OTHER_FLAT_BUILDING,
  ROAD_SIGNS,
  TRAFFIC_LIGHT,
} from 'app/constants/oghTypes';
import { filter } from 'lodash';

const ownerDict = {
  [BOARD_STONE]: 'spr06',
  [FENCING]: {
    equipment_kind: 'spr22',
    placement: 'spr23',
  },
  [MAF]: 'spr19',
  [OTHER_ENGIN_CONSTRUCT]: 'spr16',
  [OTHER_FLAT_BUILDING]: 'spr08',
  [ROAD_SIGNS]: {
    equipment_type: 'spr20',
    mounting_mode: 'spr21',
  },
  [TRAFFIC_LIGHT]: 'spr24',
};

/**
 * Функция фильтрации поля Тип.
 *
 * @param {object} dict - Справочник.
 * @param {number} cardTypeId - Тип ОГХ.
 * @param {string} dictName - Имя справочника.
 * @returns {Array<string>}
 */
const filterDictBySprNumber = (dict, cardTypeId, dictName) => {
  return filter(dict, (item) => {
    if (cardTypeId === ROAD_SIGNS) {
      switch (item.name) {
        case 'Навигационный указатель (ГУ)': {
          return false;
        }
        case 'Навигационный указатель (ГУ).ТПУ': {
          return false;
        }
        default:
      }
    }

    const sprNumber = dictName
      ? ownerDict[cardTypeId][dictName]
      : ownerDict[cardTypeId];
    return item.fz_spr.includes(sprNumber);
  });
};

export default filterDictBySprNumber;
