import { useRefState } from 'core/utils/hooks/useRefState';
import { useMemo } from 'react';

import { GeometryTypes, MappedGeometries } from '../types.d';

/**
 * Хук для создания хранилища данных геометрий распределенных по слоям.
 *
 * @returns Объект-хранилище данных геометрий. Геометрии распределены по слоям. Так же есть метод getAll - возвращает все геометрии слоями.
 */
export const useGeometryStorage = () => {
  const [
    intersectionGeometries,
    getIntersectionGeometries,
    setIntersectionGeometries,
  ] = useRefState<MappedGeometries>({
    [GeometryTypes.Point]: [],
    [GeometryTypes.Polygon]: [],
    [GeometryTypes.Polyline]: [],
  });
  const [districtsGeometries, getDistrictsGeometries, setDistrictsGeometries] =
    useRefState<MappedGeometries>({
      [GeometryTypes.Point]: [],
      [GeometryTypes.Polygon]: [],
      [GeometryTypes.Polyline]: [],
    });
  const [parentGeometries, getParentGeometries, setParentGeometries] =
    useRefState<MappedGeometries>({
      [GeometryTypes.Point]: [],
      [GeometryTypes.Polygon]: [],
      [GeometryTypes.Polyline]: [],
    });
  const [selectedGeometries, getSelectedGeometries, setSelectedGeometries] =
    useRefState<MappedGeometries>({
      [GeometryTypes.Point]: [],
      [GeometryTypes.Polygon]: [],
      [GeometryTypes.Polyline]: [],
    });
  const [
    currentChildGeometries,
    getCurrentChildGeometries,
    setCurrentChildGeometries,
  ] = useRefState<MappedGeometries>({
    [GeometryTypes.Point]: [],
    [GeometryTypes.Polygon]: [],
    [GeometryTypes.Polyline]: [],
  });
  const [
    allChildrenGeometries,
    getAllChildrenGeometries,
    setAllChildrenGeometries,
  ] = useRefState<MappedGeometries>({
    [GeometryTypes.Point]: [],
    [GeometryTypes.Polygon]: [],
    [GeometryTypes.Polyline]: [],
  });
  const [adjacentGeometries, getAdjacentGeometries, setAdjacentGeometries] =
    useRefState<MappedGeometries>({
      [GeometryTypes.Point]: [],
      [GeometryTypes.Polygon]: [],
      [GeometryTypes.Polyline]: [],
    });
  const [reonAreaGeometries, getReonAreaGeometries, setReonAreaGeometries] =
    useRefState<MappedGeometries>({
      [GeometryTypes.Point]: [],
      [GeometryTypes.Polygon]: [],
      [GeometryTypes.Polyline]: [],
    });
  const [copyDiffGeometries, getCopyDiffGeometries, setCopyDiffGeometries] =
    useRefState<MappedGeometries>({
      [GeometryTypes.Point]: [],
      [GeometryTypes.Polygon]: [],
      [GeometryTypes.Polyline]: [],
    });
  const [
    oringinalDiffGeometries,
    getOriginalDiffGeometries,
    setOriginalDiffGeometries,
  ] = useRefState<MappedGeometries>({
    [GeometryTypes.Point]: [],
    [GeometryTypes.Polygon]: [],
    [GeometryTypes.Polyline]: [],
  });

  return useMemo(() => {
    const data = {
      adjacent: {
        geometries: adjacentGeometries,
        get: getAdjacentGeometries,
        set: setAdjacentGeometries,
      },
      allChildren: {
        geometries: allChildrenGeometries,
        get: getAllChildrenGeometries,
        set: setAllChildrenGeometries,
      },
      children: {
        geometries: currentChildGeometries,
        get: getCurrentChildGeometries,
        set: setCurrentChildGeometries,
      },
      copyDiff: {
        geometries: copyDiffGeometries,
        get: getCopyDiffGeometries,
        set: setCopyDiffGeometries,
      },
      districts: {
        geometries: districtsGeometries,
        get: getDistrictsGeometries,
        set: setDistrictsGeometries,
      },
      intersections: {
        geometries: intersectionGeometries,
        get: getIntersectionGeometries,
        set: setIntersectionGeometries,
      },
      originalDiff: {
        geometries: oringinalDiffGeometries,
        get: getOriginalDiffGeometries,
        set: setOriginalDiffGeometries,
      },
      parent: {
        geometries: parentGeometries,
        get: getParentGeometries,
        set: setParentGeometries,
      },
      reonArea: {
        geometries: reonAreaGeometries,
        get: getReonAreaGeometries,
        set: setReonAreaGeometries,
      },
      selected: {
        geometries: selectedGeometries,
        get: getSelectedGeometries,
        set: setSelectedGeometries,
      },
    };

    /**
     * Получение всех геометрий.
     *
     * @returns Объект с геометриями.
     */
    const getAll = () => {
      return Object.entries(data).reduce((acc, [key, value]) => {
        acc[key as keyof typeof data] = value.get();
        return acc;
      }, {} as { [key in keyof typeof data]: MappedGeometries });
    };

    return {
      ...data,
      getAll,
    };
  }, [
    adjacentGeometries,
    getAdjacentGeometries,
    setAdjacentGeometries,
    allChildrenGeometries,
    getAllChildrenGeometries,
    setAllChildrenGeometries,
    currentChildGeometries,
    getCurrentChildGeometries,
    setCurrentChildGeometries,
    copyDiffGeometries,
    getCopyDiffGeometries,
    setCopyDiffGeometries,
    districtsGeometries,
    getDistrictsGeometries,
    setDistrictsGeometries,
    intersectionGeometries,
    getIntersectionGeometries,
    setIntersectionGeometries,
    oringinalDiffGeometries,
    getOriginalDiffGeometries,
    setOriginalDiffGeometries,
    parentGeometries,
    getParentGeometries,
    setParentGeometries,
    reonAreaGeometries,
    getReonAreaGeometries,
    setReonAreaGeometries,
    selectedGeometries,
    getSelectedGeometries,
    setSelectedGeometries,
  ]);
};
