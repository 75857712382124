import { NumberFieldFF } from 'core/form/finalForm/fields';
import React from 'react';

export const DISTANCE_NAME = 'distance';

/**
 * Number Field __Длина__.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const DistanceFF = (props) => {
  return <NumberFieldFF {...props} name={DISTANCE_NAME} label={'Длина'} />;
};
