import { Validator } from 'core/form/Validator';

/**
 * Валидация таба инженерных сооружения.
 *
 * @param {object} values - Значения формы.
 * @returns {Record<string, string>}
 */
export const validateEngineeringStructuresTab = (values) => {
  const validator = new Validator(values);

  if (values?.is_avd) {
    validator.setRequired('bridge_psc');
    validator.setRequired('bridge_qty');
    validator.setRequired('bridge_sqr');
    validator.setRequired('cable_fancing_psc');
    validator.setRequired('decor_psc');
    validator.setRequired('lamp_qty');
    validator.setRequired('light_psc');
    validator.setRequired('light_qty');
    validator.setRequired('pavilion_qty');
    validator.setRequired('pedestrian_psc');
    validator.setRequired('pedestrian_qty');
    validator.setRequired('pedestrian_sqr');
    validator.setRequired('police_qty');
    validator.setRequired('police_sqr');
    validator.setRequired('sandbase_qty');
    validator.setRequired('sandbase_sqr');
    validator.setRequired('snow_psc');
    validator.setRequired('support_qty');
    validator.setRequired('tlight_qty');
    validator.setRequired('transform_qty');
    validator.setRequired('tube_psc');
    validator.setRequired('tube_qty');
    validator.setRequired('urn_qty');
    validator.setRequired('wall_psc');
    validator.setRequired('water_psc');
    validator.setRequired('сase_outside_light_qty');
  }

  return validator.getErrors();
};
