/**
 * Функция получения подсказки.
 *
 * @param {object} item - Берется из getDistrictBoundaries.
 * @returns {*} - Преобразованные данные.
 * @example
 * const intersections = toArray(data)
 *  intersections.map(createObjectForMap)
 */
export const createObjectForMapEgip = (item) => {
  const hintFields = [
    {
      key: 'title',
      rawValue: 'Границы районов',
      value: 'Границы районов',
    },
    {
      key: 'full_name',
      rawValue: item.full_name,
      value: `Наименование Района: ${item.full_name}`,
    },
    {
      key: 'name_ao',
      rawValue: item.name_ao,
      value: `Наименование округа: ${item.name_ao}`,
    },
  ];

  const hint = hintFields.filter((item) => item.rawValue);

  return {
    hint: {
      object_attribute_hint: hint,
      object_attribute_hint_short: [
        {
          key: 'name',
          value: item.full_name,
        },
      ],
    },
    ...item.geometry,
  };
};
