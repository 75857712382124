import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const TYPE_ID_TECHNIC_NAME = 'typeId';

/**
 * ## Тип запроса.
 *
 * @param {*} props - Properties.
 * @param {string} props.label - Properties.
 * @param {*} props.otherProps - Properties.
 * @returns {JSX.Element}
 */
export const TypeIdTechnic = ({ label = 'Тип запроса', ...otherProps }) => {
  const dict = 'technicRequestType';

  const { data = [] } = useGetDictionary(dict);

  return (
    <Select
      {...otherProps}
      options={data}
      label={label}
      name={TYPE_ID_TECHNIC_NAME}
    />
  );
};
