 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Балансодержатель родительского объекта__.
 *
 * @type {{ accessor: 'parent_owner_name', Header: 'Балансодержатель родительского объекта' }}
 * @augments Cell
 */
export const parent_owner_name = {
  accessor: 'parent_owner_name',
  Header: 'Балансодержатель родительского объекта',
  sortable: true,
};
