import './dialog.SigningOnRenewalOfApprovalDate.scss';

import { useGetFilesForSignature } from 'app/api/hooks/signs/useGetFilesForSignature';
import { useSignOghSave } from 'app/api/hooks/signs/useSignOghSave';
import { UPDATE } from 'app/api/requests/signs/signOghSave';
import { ContinueFFDialog } from 'core/form/finalForm/components/dialogs/ContinueFF.Dialog';
import { Dialog } from 'core/uiKit/components/dialogs/Dialog';
import { LoaderCreateFiles } from 'core/uiKit/components/loaders/LoaderCreateFiles';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';

import { FormSigningOnRenewalOfApprovalDate } from './Form.SigningOnRenewalOfApprovalDate';
import { formValidationDialogSigningOnRenewalOfApprovalDate } from './formValidation.DialogSigningOnRenewalOfApprovalDate';

/**
 * Диалоговое окно обновления даты ЭП.
 *
 * @param {object} params - Параметры.
 * @param {Function} params.onClose - Функция скрытия окна.
 * @returns {JSX.Element}
 */
export const DialogSigningOnRenewalOfApprovalDate = ({ onClose }) => {
  const { objectId } = useGetObjectId();
  const { data = [], isLoading: isLoadingFiles } = useGetFilesForSignature(
    objectId,
    UPDATE,
    {
      onErrorCallback: onClose,
    },
  );

  const files = data.map(({ file }) => file);

  const { sendSign, isLoadingSignOghSave } = useSignOghSave({
    objectCardFiles: data,
    objectId,
    onErrorCallback: onClose,
    onSuccessCallback: onClose,
    signType: UPDATE,
  });

  return (
    <>
      <Dialog
        isOpen={!files?.length && isLoadingFiles}
        maxWidth={'xs'}
        textHeader={'Формируются файлы для подписания'}
        Body={<LoaderCreateFiles />}
      />

      <ContinueFFDialog
        {...{
          Body: <FormSigningOnRenewalOfApprovalDate />,
          closeDialog: onClose,
          isLoading: isLoadingSignOghSave,
          isOpen: !!files?.length,
          onSubmit: sendSign,
          textHeader: 'Форма подписания файлов',
          validate: formValidationDialogSigningOnRenewalOfApprovalDate,
        }}
      />
    </>
  );
};
