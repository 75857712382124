/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { pick, uniqBy, isEmpty, isArray, flow, reduce, get } from 'lodash';
import { connect } from 'react-redux';

import { ButtonGreen } from 'core/newComponents/Button';

import getDict from 'app/selectors/getDict';
import KeyboardDateTimePicker from 'core/uiKit/inputs/datePickers/KeyboardDateTimePicker';

import FileDownload from 'core/components/FileDownload';

import withCheckBeforeRenderDialog from 'core/hocs/withCheckBeforeRenderDialog';
import service from 'core/services/dateFormatService';

import { fetchPgmActualBases } from 'app/actions/basePgmActions';
import { exportRegistry } from 'app/actions/registryActions';

import Dialog from 'core/newComponents/Dialog';
import Select from 'core/newComponents/Select';

import { dateToMinDate } from 'app/utils/dateToMinDate';
import { dateFromMaxDate } from 'app/utils/dateFromMaxDate';
import { validateMandatoryProperties } from 'app/utils/validation';

import { ACTIVE } from 'app/constants/oghStatus.js';
import { BASE_PGM } from 'app/constants/oghTypes.js';

const defaultDateFrom = new Date();
// eslint-disable-next-line jest/require-hook
defaultDateFrom.setDate(defaultDateFrom.getDate() - 1);
// eslint-disable-next-line jest/require-hook
defaultDateFrom.setHours(9, 0, 0, 0);

const defaultDateTo = new Date();
// eslint-disable-next-line jest/require-hook
defaultDateTo.setHours(8, 59, 0, 0);

function ExportButton({ onClick }) {
  return (
    <ButtonGreen size="medium" onClick={onClick}>
      Сформировать
    </ButtonGreen>
  );
}

const propTypes = {
  fetchData: PropTypes.func,
  handleClose: PropTypes.func,
  reagentKindIdList: PropTypes.array,
  reagentBaseTypeList: PropTypes.array,
  reagentIdList: PropTypes.array,
  handleExportComplete: PropTypes.func,
  exportPath: PropTypes.string,
  startExport: PropTypes.func,
  open: PropTypes.bool,
};

class DialogExportPgmFullness extends React.Component {
  state = {
    type: 'fullness',
    dateFrom: defaultDateFrom,
    dateTo: defaultDateTo,
    reagentBaseTypeList: 1,
    pgmRegistryTable: [''],
    objectRootIdList: '',
    reagentKindIdList: '',
    reagentIdList: '',
    errors: {},
  };

  componentDidMount() {
    this.fetchPgmBasesData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { dateFrom, dateTo, reagentBaseTypeList } = this.state;

    if (
      String(dateFrom) !== String(prevState.dateFrom) ||
      String(dateTo) !== String(prevState.dateTo) ||
      reagentBaseTypeList !== prevState.reagentBaseTypeList
    ) {
      this.fetchPgmBasesData();
    }
  }

  getDataForPgmBasesFetch() {
    const { dateFrom, dateTo, reagentBaseTypeList } = this.state;
    const reagentBaseType = reagentBaseTypeList;
    return {
      dateFrom: service.formatDateTime(dateFrom),
      dateTo: service.formatDateTime(dateTo),
      typeId: BASE_PGM,
      status: ACTIVE,
      ...(reagentBaseType ? { reagentBaseType } : {}),
    };
  }

  getValuesObj = (formValues) => {
    const formFields = Object.keys(formValues);

    return formFields.reduce((result, item) => {
      let key = item;
      let value = formValues[key];

      if (key === 'dateFrom' || key === 'dateTo') {
        result[key] = service.formatDateTime(value);
      }

      if (key === 'reagentBaseTypeList' && value !== 0) {
        result[key] = value;
      }

      if (Array.isArray(value) && value[0] !== '') {
        result[key] = value;
      }

      return result;
    }, {});
  };

  handleClose = () => {
    const { onHide } = this.props;
    onHide();
  };

  fetchPgmBasesData() {
    const { fetchData } = this.props;
    const dataForPgmBasesFetch = this.getDataForPgmBasesFetch();

    this.setState({ isLoading: true }, async () => {
      const response = await fetchData(dataForPgmBasesFetch);

      this.setState({
        isLoading: false,
        pgmRegistryTable: response.data && response.data.table,
      });
    });
  }

  renderDatePicker = ({ label, id, value, onChange, ...rest }) => {
    return (
      <KeyboardDateTimePicker
        errorText={this.state.errors[id]}
        label={label}
        value={this.state[id] || ''}
        onChange={(value) => {
          onChange(value);
        }}
        {...rest}
      />
    );
  };

  renderSelectField({ id, label, onChange, items, ...rest }) {
    return (
      <div
        style={{
          marginTop: 16,
        }}
      >
        <Select
          errorText={this.state.errors[id]}
          id={id}
          label={label}
          options={[
            {
              id: id === 'reagentBaseTypeList' ? 0 : '',
              name: 'Все',
            },
            ...(id === 'objectRootIdList'
              ? items.map((item) => ({
                  id: item.object_id,
                  name: `${item.base_num} ${item.object_name}`,
                }))
              : items || []),
          ]}
          value={this.state[id]}
          onChange={(value) => {
            onChange(value);
          }}
          {...rest}
        />
      </div>
    );
  }

  renderReagent() {
    const { reagentIdList } = this.props;
    const { pgmRegistryTable, reagentKindIdList, isLoading } = this.state;

    const allReagentIdsForSelectedBases =
      !isLoading &&
      pgmRegistryTable &&
      pgmRegistryTable.length &&
      pgmRegistryTable
        .map((base) => base.reagent_id_list || base)
        .reduce((acc, val) => [...acc, ...val]);

    const uniqReagents = uniqBy(allReagentIdsForSelectedBases, (e) => e);

    const reagentsForSelectedBases =
      uniqReagents && uniqReagents.length
        ? reagentIdList.filter((item) => uniqReagents.includes(item.id))
        : reagentIdList;

    const filtredReagents =
      reagentsForSelectedBases &&
      reagentsForSelectedBases.length &&
      reagentKindIdList &&
      reagentKindIdList[0] !== '' &&
      reagentsForSelectedBases.filter((reagent) =>
        reagentKindIdList.includes(reagent.reagent_kind_id),
      );

    return this.renderSelectField({
      id: 'reagentIdList',
      label: 'Наименование ПГР',
      onChange: (value) => {
        this.handleChange(value, 'reagentIdList');
      },
      items: filtredReagents || [],
      multiple: true,
    });
  }

  renderReagentKinds() {
    const { reagentKindIdList } = this.props;
    const { pgmRegistryTable, isLoading } = this.state;

    const allReagentKindsIdsForSelectedBases =
      !isLoading &&
      pgmRegistryTable[0] !== '' &&
      pgmRegistryTable
        .map((base) => base.reagent_kind_id_list)
        .reduce((acc, value) => acc.concat(value), []);

    const uniqKinds = uniqBy(allReagentKindsIdsForSelectedBases, (e) => e);

    const reagentsKindsForSelectedBases =
      uniqKinds && uniqKinds.length
        ? reagentKindIdList.filter((item) => uniqKinds.includes(item.id))
        : reagentKindIdList;

    return this.renderSelectField({
      id: 'reagentKindIdList',
      label: 'Вид ПГР',
      onChange: (value) => {
        this.handleChange(value, 'reagentKindIdList');
      },
      items: reagentsKindsForSelectedBases || [],
      multiple: true,
    });
  }

  handleChange = (value, name) => {
    const state = {
      [name]: value,
    };
    this.setState(state);
  };

  handleSubmit = (values) => (event) => {
    event.preventDefault();

    const formatedValues = pick(values, [
      'type',
      'dateFrom',
      'dateTo',
      'reagentBaseTypeList',
      'objectRootIdList',
      'reagentKindIdList',
      'reagentIdList',
    ]);
    const result = this.getValuesObj(formatedValues);

    const valuesToCheck = getValuesToCheck(values);
    const errors = validateMandatoryProperties(valuesToCheck, mandatoryFields);
    this.setState({ errors });

    if (isEmpty(errors) && !isEmpty(result)) {
      this.setState({ queryParams: { type: 'fullness', ...result } }, () => {
        this.props.startExport();
      });
    }
  };

  render() {
    const {
      showCondition,
      reagentBaseTypeList,
      exportPath,
      handleExportComplete,
    } = this.props;
    const { dateTo, dateFrom, queryParams } = this.state;

    return (
      <Dialog
        actions={<ExportButton onClick={this.handleSubmit(this.state)} />}
        show={showCondition}
        title="Заполненность баз ПГР"
        onHide={this.handleClose}
      >
        <form>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {this.renderDatePicker({
              id: 'dateFrom',
              label: 'Дата с',
              value: this.state.dateFrom,
              maxDate: dateFromMaxDate(dateFrom, dateTo),
              onChange: (value) => {
                this.handleChange(value, 'dateFrom');
              },
              width: '276',
            })}

            {this.renderDatePicker({
              id: 'dateTo',
              label: 'Дата по',
              minDate: dateToMinDate(dateFrom),
              value: this.state.dateTo,
              onChange: (value) => {
                this.handleChange(value, 'dateTo');
              },
              width: '276',
            })}
          </div>

          {this.renderSelectField({
            label: 'Тип базы',
            id: 'reagentBaseTypeList',
            items: reagentBaseTypeList,
            onChange: (value) => {
              this.handleChange(value, 'reagentBaseTypeList');
            },
          })}

          {this.state.pgmRegistryTable &&
            this.renderSelectField({
              label: 'Базы хранения ПГР',
              id: 'objectRootIdList',
              items: this.state.pgmRegistryTable,
              multiple: true,
              onChange: (value) => {
                this.handleChange(value, 'objectRootIdList');
              },
            })}

          {this.renderReagentKinds()}
          {this.renderReagent()}
        </form>

        {exportPath && (
          <FileDownload
            actionPath={exportPath}
            queryParams={queryParams}
            onDownloadComplete={handleExportComplete}
          />
        )}
      </Dialog>
    );
  }
}

DialogExportPgmFullness.propTypes = propTypes;

const mandatoryFields = [
  'dateFrom',
  'dateTo',
  'reagentBaseTypeList',
  'objectRootIdList',
  'reagentKindIdList',
  'reagentIdList',
];

const getValuesToCheck = (values) => {
  return reduce(
    values,
    (result, value, key) => {
      const isAllOptionId =
        value === '' || (isArray(value) && value.length === 0);
      result[key] = isAllOptionId ? [''] : value;

      return result;
    },
    {},
  );
};

const mapStateToProps = (state) => {
  return {
    reagentBaseTypeList: getDict('reagentBaseTypes')(state),
    reagentKindIdList: getDict('reagentKinds')(state),
    exportPath: get(state, 'registry.exportPath'),
    reagentIdList: getDict('reagents')(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchData: function (data) {
    return dispatch(fetchPgmActualBases(data));
  },
  startExport: () => {
    dispatch(exportRegistry('base_pgm'));
  },
  handleExportComplete: () => {
    dispatch(exportRegistry());
  },
});

 
export { getValuesToCheck };

 
export default flow(
  withCheckBeforeRenderDialog,
  connect(mapStateToProps, mapDispatchToProps),
)(DialogExportPgmFullness);
