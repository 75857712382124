/* eslint-disable */
export const iconStyle = {
  width: 40,
  height: 40,
  fontSize: '38px',
};

/* eslint-disable */
export const iconButtonStyle = {
  width: 60,
  height: 60,
  margin: 20,
  outline: 'none',
};
