/* eslint-disable  */

/**
 *
 * @param root0
 * @param root0.children
 * @param root0.show
 */
export const TabPanel = ({ children, show }) => {
  return show ? children : null;
};
