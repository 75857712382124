import { AccordionPlantationTab } from '../Accordion.PlantationTab';
import { TableRedBookList } from './Table.RedBookList';

 
/* eslint-disable */
/**
 * Ведомость растений Красной книги
 * @returns {JSX.Element}
 * @constructor
 */
 
export const RedBookList = ({ expanded, onChange }) => {
  return (
    <AccordionPlantationTab
      code={'red_book_list'}
      title={'Ведомость растений Красной книги'}
      Table={TableRedBookList}
      expanded={expanded}
      onChange={onChange}
    />
  );
};
