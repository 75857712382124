 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */

import { LinkShortIdCell } from 'core/uiKit/components/cells/LinkShortId.Cell';
 
/* eslint-disable */
/**
 * Колонка __ID__.
 *
 * @type {{ accessor: 'short_root_id',
 * Cell: LinkShortIdCell,
 * Header: 'ID',
 * sortable: true, }}
 * @augments Cell
 */
export const short_root_id = {
  accessor: 'short_root_id',
  Cell: LinkShortIdCell,
  Header: 'ID',
  sortable: true,
};
