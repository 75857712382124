import { SkeletonTreeRightPanel } from 'app/pages/cardsOgh/components/RightPanelCard/components/TreeRightPanel/SkeletonTreeRightPanel';
import formatRootId from 'app/utils/formatRootId';
import { CopyToClipboard } from 'core/uiKit/components/CopyToClipboard';
import { Icon } from 'core/uiKit/components/Icon';
import { Link } from 'core/uiKit/components/Link';
import React from 'react';

import { Accordion } from './Accordion';
import { GroupsTree } from './Groups.Tree';
import { useSetupTreeRightPanel } from './useSetup.TreeRightPanel';

/**
 * Дерево __Список объектов__.
 *
 * @returns {JSX.Element}
 */
export const TreeRightPanel = () => {
  const {
    openAll,
    setOpenAll,
    open,
    setOpen,
    isLoadingRender,
    treeData,
    parentId,
    setLoadingRender,
    isLoading,
    drawGeometry,
  } = useSetupTreeRightPanel();

  const clOpen = openAll ? 'fa fa-caret-up' : 'fa fa-caret-down';
  return (
    <SkeletonTreeRightPanel loading={isLoadingRender || isLoading}>
      {treeData?.id ? (
        <Accordion
          accordionOpen={open}
          setAccordionOpen={setOpen}
          key={treeData.id}
          onClick={() => drawGeometry({ typeId: treeData.typeId })}
          HeaderComponent={
            <>
              <CopyToClipboard
                textForCopy={`${formatRootId(treeData.id)} ${
                  treeData.title || ''
                }`}
              >
                <Link to={`/ogh/${parentId}`}>
                  {`${formatRootId(treeData.id)} ${treeData.title || ''}`}
                </Link>
              </CopyToClipboard>
              <Icon
                onClick={() => setOpenAll(!openAll)}
                baseClassName="fa"
                className={`ms-1 fa-solid ${clOpen}`}
              />
            </>
          }
          active={true}
        >
          <GroupsTree
            setLoadingRender={setLoadingRender}
            subgroups={treeData.items}
            openAll={openAll}
          />
        </Accordion>
      ) : null}
    </SkeletonTreeRightPanel>
  );
};
