import messages from 'app/utils/card/checkGeometry/messages';

import { createIntersectionsMessage } from '../helpers/createIntersectionsMessage';
import { getLengthGeometry } from '../helpers/getLengthGeometry';

/**
 * Функция fetchIntersections.
 *
 * @param {object} params - Параметры.
 * @param {object} params.data - Данные.
 * @param {Function} params.fetchCheckGeometryIntersections - Функция.
 * @param {Function} params.showAlert - Показ сообщения.
 * @param {Function} params.draw - Отрисовка.
 * @returns {Promise}
 */
export async function fetchIntersections({
  data,
  fetchCheckGeometryIntersections,
  showAlert,
  draw,
}) {
  const object = await fetchCheckGeometryIntersections(data);

  const length = getLengthGeometry(object);
  if (length) {
    showAlert(
      createIntersectionsMessage(object.geometry, messages.errorIntersections),
    );
    draw(object.geometry);
  }
  return length;
}
