export const PREFIX_DOCUMENT_TABLE = 'file_list_';
/* eslint-disable  */

/**
 *
 * @param id
 */
export const getNameFile = (id) => {
  return `${PREFIX_DOCUMENT_TABLE}${id}`;
};
