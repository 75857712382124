import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { AddButton } from 'core/uiKit/components/buttons';
import React, { useState } from 'react';

import { DialogRepairsInfoListPlan } from './Dialog.RepairsInfoListPlan';

/**
 * Кнопка добавления __Проекта__.
 *
 * @returns Element.
 */
export const AddButtonRepairsPlanTab = () => {
  const [isOpen, setOpen] = useState(false);
  const { editMode } = useMode();

  return editMode ? (
    <>
      <AddButton onClick={() => setOpen(true)} />
      <DialogRepairsInfoListPlan
        closeDialog={() => setOpen(false)}
        isOpen={isOpen}
      />
    </>
  ) : null;
};
