import './DialogSendMatching.scss';

import { Dialog } from 'core/uiKit/components/dialogs/Dialog';
import React from 'react';

import { DialogSendMatchingBody } from './DialogSendMatching.Body';
import { useSetupDialogSendMatching } from './useSetup.DialogSendMatching';

/**
 * DialogSendMatching.
 *
 * @param {*} props - The props.
 * @param {boolean} props.open - Open.
 * @param {Function} props.setOpen - F.
 * @returns {JSX.Element}
 */
export const DialogSendMatching = ({ open, setOpen, showAlert }) => {
  const { closeDialog, isLoading, onSubmit } = useSetupDialogSendMatching({
    setOpen,
    showAlert,
  });

  return (
    <Dialog
      maxWidth={'sm'}
      isOpen={open}
      textHeader={'Отправить объект на согласование'}
      Body={
        <DialogSendMatchingBody {...{ closeDialog, isLoading, onSubmit }} />
      }
    />
  );
};
