import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import PropTypes from 'prop-types';
import React from 'react';

export const LIST_TYPE_ID_NAME = 'listTypeId';

/* eslint-disable */
/**
 * Тип списка.
 *
 * @param {object} props - Пропсы.
 * ```js
 * import { ListTypeId, LIST_TYPE_ID_NAME } from 'core/uiKit/preparedInputs';
 * ```
 *
 * `type = Select`
 * `name = listTypeId = OGH_TYPE_SELECT_NAME`
 * `dict = oghTypes`.
 * @returns {JSX.Element}
 */
export const ListTypeId = (props) => {
  const tlTypes = 'tl_types';

  const { data = [] } = useGetDictionary(tlTypes);

  const options = data.sort((a, b) => a.id - b.id);

  return (
    <Select
      label={'Тип списка'}
      name={LIST_TYPE_ID_NAME}
      options={options}
      {...props}
    />
  );
};

ListTypeId.propTypes = {
  /**
   * Выбор темной темы.
   */
  dark: PropTypes.bool,

  /**
   * Заблокировать на редактирование.
   */
  disabled: PropTypes.bool,

  /**
   * Вывод ошибки.
   */
  errorText: PropTypes.string,

  /**
   * Вывод вспомогательного текста.
   */
  helperText: PropTypes.string,

  /**
   * Функция вызова когда выберется итем.
   */
  onChange: PropTypes.func,

  /**
   * Вывод информации об обязательном поле.
   */
  required: PropTypes.bool,

  /**
   * Значение.
   */
  value: PropTypes.any,
};
