/* eslint-disable  */
import getFormValues from 'app/selectors/form/getFormValues';
import getDict from 'app/selectors/getDict';
import { createSelector } from 'reselect';

/**
 *
 * @param state
 */
const getLifeFormTypeId = (state) => getFormValues(state).life_form_type_id;

/**
 *
 * @param state
 */
const getQuantity = (state) => getFormValues(state).quantity;

/**
 *
 * @param state
 */
const getArea = (state) => getFormValues(state).area;

export default createSelector(
  [getLifeFormTypeId, getDict('lifeFormTypes'), getQuantity, getArea],
  (lifeFormTypeId, lifeFormTypes, quantity, area) => {
    if (lifeFormTypeId) {
      const currentLifeForm = lifeFormTypes.find(
        (singleForm) => singleForm.id === lifeFormTypeId,
      );

      if (quantity && currentLifeForm.code === 'tree') {
        return parseFloat(quantity * 0.5).toFixed(2);
      } else {
        return area;
      }
    }

    return null;
  },
);
