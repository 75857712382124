import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const OOPT_PROFILE_NAME = 'ooptProfile';

/**
 * ## Профиль ООПТ.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const OoptProfile = (props) => {
  const dict = 'ooptProfile';

  const { data = [] } = useGetDictionary(dict);

  return (
    <Select
      {...props}
      options={data}
      label={'Профиль ООПТ'}
      name={OOPT_PROFILE_NAME}
    />
  );
};
