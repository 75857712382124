import { LinkIdCell } from 'core/uiKit/components/cells/LinkId.Cell';
import { shallow } from 'enzyme';
import React from 'react';
import { vi } from 'vitest';

import { TableVersions } from './Table.Versions';
import { useSetupTableVersions } from './useSetup.Table.Versions';
vi.mock('./useSetup.Table.Versions');
describe('Table.Versions.spec', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    useSetupTableVersions.mockReturnValue({});

    //🔥 Act
    const wrapper = shallow(<TableVersions />);

    //❓ Assert
    expect(wrapper.text()).toBe('<Button /><TableSimple />');
  });

  it('rows.length === 2', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    useSetupTableVersions.mockReturnValue({
      rows: [{}, {}],
    });

    //🔥 Act
    const wrapper = shallow(<TableVersions />);

    //❓ Assert
    expect(wrapper.text()).toBe(
      '<DialogComparison /><Button /><TableSimple />',
    );
  });

  it('columns', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    useSetupTableVersions.mockReturnValue({});

    const wrapper = shallow(<TableVersions />);

    //🔥 Act
    const columns = wrapper.find('TableSimple').prop('columns');

    //❓ Assert
    expect(columns).toStrictEqual([
      {
        Cell: LinkIdCell,
        Header: 'ID версии объекта',
        accessor: 'id',
      },
      {
        Header: 'Наименование',
        accessor: 'name',
      },
      {
        Header: 'Дата начала',
        accessor: 'start_date',
      },
      {
        Header: 'Дата окончания',
        accessor: 'end_date',
      },
      {
        Header: 'Балансодержатель',
        accessor: 'owner_name',
      },
      {
        Header: 'Заказчик',
        accessor: 'customer_name',
      },
      {
        Header: 'Статус',
        accessor: 'object_status_name',
      },
      {
        Header: 'Причины утверждения (утверждение ЭП)',
        accessor: 'reason_list_final',
      },
      {
        Header: 'Причины изменения',
        accessor: 'reason_list',
      },
      {
        Header: 'Комментарий',
        accessor: 'change_comment',
      },
      {
        Header: 'Экспорт в ОДОПМ',
        accessor: 'date_send_odopm',
      },
    ]);
  });
});
