import { useGetFilesForSignature } from 'app/api/hooks/signs/useGetFilesForSignature';
import { CLOSE } from 'app/api/requests/signs/signOghSave';
import { getYesterdayDate } from 'app/utils/date/getYesterdayDate';
import { ContinueFFDialog } from 'core/form/finalForm/components/dialogs/ContinueFF.Dialog';
import { SEND_REASON_NAME } from 'core/form/finalForm/fields/prepared/selects/multiSelect/SendReasonFF';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';

import { FormDialogSignatureFiles } from './Form.Dialog.SignatureFiles';
import { formValidationCloseElectronicSignature } from './formValidation.CloseElectronicSignature';
import { useSignCloseElectronicSignature } from './useSignClose.ElectronicSignature';

/**
 * Диалог подписания файлов.
 *
 * @param {object} root0 - Properties.
 * @param {boolean} root0.isOpen - Flag is open dialoa.
 * @param {Function} root0.onClose - Callback on close dialog.
 * @returns {React.ReactElement}
 */
export const DialogSignatureFiles = ({ isOpen, onClose }) => {
  const { objectId } = useGetObjectId();
  const { data = [] } = useGetFilesForSignature(objectId, CLOSE);

  const { sendSign, isLoadingSignOghSave } = useSignCloseElectronicSignature({
    objectCardFiles: data,
    objectId,
    onErrorCallback: onClose,
    onSuccessCallback: onClose,
    signType: CLOSE,
  });

  return (
    <ContinueFFDialog
      {...{
        Body: <FormDialogSignatureFiles />,
        closeDialog: onClose,
        initialValues: { [SEND_REASON_NAME]: [], date: getYesterdayDate() },
        isLoading: isLoadingSignOghSave,
        isOpen: isOpen,
        onSubmit: sendSign,
        textHeader: 'Форма подписания файлов',
        validate: formValidationCloseElectronicSignature,
      }}
    />
  );
};
