import { useEffect, useRef } from 'react';

/**
 * This hook returns the previous value of a given value.
 *
 * @param {*} value - The value to watch.
 * @returns {*} - The previous value.
 */
export const usePrevious = <Type = unknown>(value: Type): Type => {
  const prevRef = useRef<Type>(value);

  useEffect(() => {
    prevRef.current = value;
  }, [value]);

  return prevRef.current;
};

export default usePrevious;
