/* eslint-disable  */
import { TextField } from 'core/uiKit/inputs/TextField';
import PropTypes from 'prop-types';
import React from 'react';

export const OLD_PASSWORD_NAME = 'oldPassword';

/**
 * Старый пароль.
 *
 * @description - `
 * type = password
 * name = oldPassword`.
 * @param {object} props - Пропсы.
 * @returns {*}
 */
export const OldPassword = (props) => {
  return (
    <TextField
      {...props}
      type={'password'}
      label={'Старый пароль'}
      inputProps={{ autoComplete: 'off', 'data-testid': 'oldPassword' }}
      name={OLD_PASSWORD_NAME}
    />
  );
};

OldPassword.propTypes = {
  /**
   * Выбор темной темы.
   */
  dark: PropTypes.bool,

  /**
   * Заблокировать на редактирование.
   */
  disabled: PropTypes.bool,

  /**
   * Вывод ошибки.
   */
  errorText: PropTypes.string,

  /**
   * Функция вызова когда выберется итем.
   */
  onChange: PropTypes.func,

  /**
   * Вывод информации об обязательном поле.
   */
  required: PropTypes.bool,

  /**
   * Значение.
   */
  value: PropTypes.any,
};
