import { usePreparation } from 'core/utils/hooks/usePreparation';

import { ID_OR_NAME_PARENT_NAME } from './ID_OR_NAME_PARENT_NAME';

/**
 * Хук подкотовки (форматирования) данных для отправки на бэк.
 *
 * @returns {void} - Жопа.
 * @example ----
 * export const Filter = () => {
 *   usePreparationIdOrNameFilter();
 *   return (
 *     <TextFieldFF
 *       label={label}
 *       name={ID_OR_NAME_PARENT_NAME}
 *     />
 *   );
 * };
 */
export const usePreparationIdOrNameFilter = () => {
  usePreparation(
    ID_OR_NAME_PARENT_NAME,
    (value) => value,
    (val) => {
      if (isNaN(Number(val))) {
        return 'par_name';
      }
      return 'par_root_id';
    },
  );
};
