import filterDictBySprNumber from 'app/utils/card/filterDictBySprNumber';
import { IsDiffHeightMarkRF } from 'core/form/reduxForm/fields/prepared/checkboxes/IsDiffHeightMark.RF';
import { RANDOM_NUMBER, RANDOM_WORD } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import MafComponent from './MafComponent';

vi.mock('app/utils/card/filterDictBySprNumber');

describe('MafComponentJestSpec', function () {
  it('test', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const props = {
      card: {},
      editMode: true,
      formValues: { bord_begin: '' },
    };
    // Act
    const wrapper = shallow(<MafComponent {...props} />);

    // Assert
    expect(wrapper.find(IsDiffHeightMarkRF).props()).toStrictEqual({
      disabled: false,
    });
  });
  it('test getConvElementTypeOptions', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const props = {
      card: {
        conv_element_type: RANDOM_WORD,
        type_id: RANDOM_NUMBER,
      },
      editMode: true,
      formValues: { bord_begin: '' },
    };
    // Act
    shallow(<MafComponent {...props} />);

    // Assert
    expect(filterDictBySprNumber).toBeCalledWith(RANDOM_WORD, RANDOM_NUMBER);
  });
  it('test getConvElementTypeOptions viewing', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const props = {
      card: {
        conv_element_type: RANDOM_WORD,
        type_id: RANDOM_NUMBER,
        viewing: true,
      },
      editMode: true,
      formValues: { bord_begin: '', installation_date: '' },
    };
    // Act
    shallow(<MafComponent {...props} />);

    // Assert
    expect(filterDictBySprNumber).toBeCalledTimes(0);
  });
});
