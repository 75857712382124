// 42 контейнер
/* eslint-disable  */

import CardAbstract from 'app/components/card/common/CardAbstract';
import {
  elementFormGrid,
  renderCustomTextField,
} from 'app/components/card/common/grid/index';
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import { WithReduxFormCheckbox } from 'app/components/common/CheckboxField';
import { WithReduxFormSelect } from 'app/components/common/SelectField';
import { REQUIRED_FIELD_MESSAGE } from 'app/constants/messages';
import hasWasteType from 'app/selectors/card/hasWasteType';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import getFormValues from 'app/selectors/form/getFormValues';
import getDict from 'app/selectors/getDict';
import { transformValues } from 'app/utils/form/transformation';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm, SubmissionError } from 'redux-form';

import printOghCardHeader from './header/oghHeader';
import submitCard from './submit';
import VersionsTemplate from './versions/VersionsTemplate';

const types = ['container'];
const Versions = VersionsTemplate(types);

const checkboxStyle = {
  height: 50,
  paddingTop: 0,
};

/**
 *
 */
class Card extends React.Component {
  /**
   *
   */
  render() {
    const { props } = this;

    const {
      card,
      mode,
      formValues,
      required,
      editMode,
      binTypes,
      hasWasteTypeParam,
    } = props;
    const capacity_list =
      (formValues &&
        formValues.bin_type_id !== null &&
        (binTypes.find((item) => item.id === formValues.bin_type_id) || {})
          .allowable_list) ||
      [];
    const area =
      formValues && formValues.capacity !== null
        ? (capacity_list[formValues.capacity] || {}).area
        : '';
    return (
      <CardAbstract>
        <div id="editor-card">
          {printOghCardHeader(card, mode)}
          {card && objectTabs(card.type_id)}
          <div id="props">
            {elementFormGrid(
              [
                {
                  addition: {
                    /**
                     *
                     * @param value
                     */
                    insteadOfOnChange: (value) => {
                      this.binTypeComponent.handleChange(value);
                      this.capacityComponent.handleChange(null);
                      if (hasWasteTypeParam) {
                        props.changeFieldValue('waste_type_id', null);
                      }
                    },
                    label: 'Тип контейнера',
                    options: binTypes,

                    /**
                     *
                     * @param ref
                     */
                    ref: (ref) => {
                      this.binTypeComponent = _.get(ref, 'ref.current');
                    },
                    required,
                  },
                  component: WithReduxFormSelect,
                  editable: editMode,
                  formValue: true,
                  id: 'bin_type_id',
                  name: 'bin_type_id',
                },
                {
                  addition: {
                    label: 'Тип отходов',
                    options: card.waste_type,
                  },
                  component: WithReduxFormSelect,
                  editable: editMode,
                  formValue: true,
                  id: 'waste_type_id',
                  name: 'waste_type_id',
                  style: {
                    display: hasWasteTypeParam ? 'inline-block' : 'none',
                  },
                },
                <div>
                  <ParentInfo />
                </div>,
              ],
              2,
              '',
              card.type_id,
            )}

            {elementFormGrid(
              [
                {
                  component: WithReduxFormCheckbox,
                  editable: editMode,
                  formValue: true,
                  id: 'no_calc',
                  label: 'Не учитывать',
                  name: 'no_calc',
                  style: checkboxStyle,
                },
              ],
              2,
              '',
              card.typeId,
            )}

            <Tabs
              tabs={[
                {
                  id: 'characteristics-tab',
                  name: 'Исходные данные',
                },
                {
                  id: 'docs-tab',
                  name: 'Документы',
                },
              ]}
            />

            <div id="characteristics">
              {elementFormGrid(
                [
                  {
                    addition: {
                      label: 'Объем, куб. м',
                      options: capacity_list.map((item, index) => ({
                        id: index,
                        name: item.capacity,
                      })),

                      /**
                       *
                       * @param ref
                       */
                      ref: (ref) => {
                        this.capacityComponent = _.get(ref, 'ref.current');
                      },
                      required,
                    },
                    component: WithReduxFormSelect,
                    editable: editMode,
                    formValue: true,
                    id: 'capacity',
                    name: 'capacity',
                  },
                  {
                    addition: {
                      input: { value: area },
                    },
                    component: renderCustomTextField,
                    defaultValue: area,
                    editable: false,
                    formValue: true,
                    id: 'area',
                    label: 'Площадь, кв. м',
                    name: 'area',
                  },
                ],
                2,
                '',
                card.type_id,
              )}
            </div>
            <div id="docs" />
          </div>
          <div hidden={true} id="versions">
            <Versions
              card={card}
              disabled={props.createMode}
              key={`versions-${card.root_id}`}
            />
          </div>
        </div>
      </CardAbstract>
    );
  }
}
const getBinTypes = getDict('binTypes');

/**
 *
 * @param state
 * @param props
 */
const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);
  const bin_type_id = parse('bin_type_id.bin_type');
  const waste_type_id = parse('waste_type_id.waste_type');
  const capacity = parse('capacity');
  const capacity_list =
    (props.card.bin_type.find((item) => item.id === bin_type_id) || {})
      .allowable_list || [];
  const capacityIndex =
    capacity !== null
      ? _.findIndex(capacity_list, (value) => value.capacity === capacity)
      : null;
  const hasWasteTypeParam = hasWasteType(state);
  return {
    binTypes: getBinTypes(state),
    createMode: isCreateMode(state, props),
    editMode: isEditMode(state, props),
    enableReinitialize: true,
    formValues: getFormValues(state),
    hasWasteTypeParam: hasWasteTypeParam,
    initialValues: {
      area: capacityIndex !== null ? capacity_list[capacityIndex].area : null,
      bin_type_id,
      capacity: capacityIndex,
      endDate: parseDate(props.card.end_date),
      no_calc: parse('no_calc'),
      startDate: parseDate(props.card.start_date),
      waste_type_id,
    },
    onSubmit: onSubmit.bind({ props }),

    required: isRequired(state, props),
  };
};

/**
 * Обертка для вызова mapDispatchToProps.
 *
 * @param {*} dispatch - Диспатч.
 * @returns {*}
 */
function mapDispatchToProps(dispatch) {
  return {
    /**
     *
     * @param field
     * @param value
     */
    changeFieldValue: (field, value) => {
      dispatch(change('editorCard', field, value));
    },
  };
}
const dictFields = ['bin_type_id', 'waste_type_id'];

/**
 * Обработчик сабмита карточки.
 *
 * @param {*} formValues - Значения из формы.
 * @returns {void}
 */
function onSubmit(formValues) {
  const values = { ...formValues };

  delete values.parent_name;

  const errors = {};
  if (_.isNull(values.bin_type_id) || _.isNaN(values.bin_type_id)) {
    errors.bin_type_id = REQUIRED_FIELD_MESSAGE;
  }
  if (_.isNull(values.capacity) || _.isNaN(values.capacity)) {
    errors.capacity = REQUIRED_FIELD_MESSAGE;
  }
  if (!_.isEmpty(errors)) {
    throw new SubmissionError(errors);
  }
  const capacity_list =
    (
      this.props.card.bin_type.find((item) => item.id === values.bin_type_id) ||
      {}
    ).allowable_list || [];
  const transformed = transformValues(values, {
    custom: {
      capacity: {
        /**
         *
         * @param index
         */
        transformer: (index) => capacity_list[index].capacity,
      },
    },
    dictFields,
  });

  submitCard(this.props, transformed);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'editorCard' })(Card));
