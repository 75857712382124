import getCurrentObject from 'app/selectors/card/getCurrentObject';
/* eslint-disable  */
import getOghTypeId from 'app/selectors/card/getOghTypeId';
import { isThisViewing } from 'app/selectors/card/isThisViewing';
import { isEmergencyParking, isMaf, isOdh } from 'app/utils/checkOghTypeId';
import { createSelector } from 'reselect';

const codes = ['sidewalk', 'roadway', 'other_terr', '3'];

/**
 *
 * @param state
 */
const getDict = (state) => getCurrentObject(state).placement;

/**
 *
 * @param placement_id
 */
const selector = (placement_id) =>
  createSelector(
    [getDict, getOghTypeId, isThisViewing],
    (dict, typeId, viewing) => {
      let result;
      if (viewing) {
        return dict || [];
      }
      if (
        dict &&
        (isOdh(typeId) || isMaf(typeId) || isEmergencyParking(typeId))
      ) {
        result = dict.filter(
          (item) => codes.includes(item.code) || item.id === placement_id,
        );
      } else {
        result = dict;
      }

      return result;
    },
  );

export default selector;
