import { QUANTITY_NAME } from 'core/uiKit/preparedInputs/textField/Quantity';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { QuantityWithReduxForm } from './QuantityWithReduxForm';

/**
 * Компонент QuantityRf.
 *
 * @param {object} props - Пропсы компонента.
 * @returns {JSX.Element} - Возвращает компонент Quantity обернутый в redux form.
 * @example <QuantityRf {...props}/>
 */
export const QuantityRf = (props) => {
  return (
    <Field {...props} component={QuantityWithReduxForm} name={QUANTITY_NAME} />
  );
};

QuantityRf.propTypes = {

  /**
   * Быбор темной темы.
   */
  dark: PropTypes.bool,

  /**
   * Заблокировать на редактирование.
   */
  disabled: PropTypes.bool,

  /**
   * Вывод ошибки.
   */
  errorText: PropTypes.string,

  /**
   * Вывод вспомогательного текста.
   */
  helperText: PropTypes.string,

  /**
   * Placeholder.
   */
  placeholder: PropTypes.string,

  /**
   * Вывод информации об обязательном поле.
   */
  required: PropTypes.bool,
};
QuantityRf.defaultProps = {
  disabled: false,
};
