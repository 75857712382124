 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __ИД УАИС БУ__.
 *
 * @type {{ accessor: 'eais_id',  Header: 'ИД УАИС БУ' }}
 * @augments Cell
 */
export const eais_id = {
  accessor: 'eais_id',
  Header: 'ИД УАИС БУ',
  sortable: true,
};
