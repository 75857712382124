import { createAttribute as createDocumentsAttribute } from 'app/components/card/ogh/DocumentsTab';

import { createAttribute as createAddressListFieldAttribute } from '../components/AddressListField';
import { createAttribute as createAquaAreaAttribute } from '../components/AquaAreaTab';
import { createDistrictListFieldAttribute } from '../components/DistrictListField';
import { createAttribute as improvementCategoriesCreateAttribute } from '../components/ImprovementCategories';
import { createdOkrugListFieldAttribute } from '../components/OkrugListField';
import { createAttribute as createOwnerAndCustomerFieldsAttribute } from '../components/OwnerAndCustomerFields';
import { createdOznTypeAttribute } from '../components/OznTypeField';

/**
 * Функция комбайн создания атрибутов из одного в другое.
 *
 * @param {object} formValues - Значения валидации.
 * @returns {object} - Возвращает атрибуты.
 * @example - createAttribute(formValues)
 */
export function createAttribute(formValues) {
  return {
    ...createdOznTypeAttribute(formValues),
    ...createdOkrugListFieldAttribute(formValues),
    ...createDistrictListFieldAttribute(formValues),
    ...createAddressListFieldAttribute(formValues),
    ...createOwnerAndCustomerFieldsAttribute(formValues),
    ...improvementCategoriesCreateAttribute(formValues),
    ...createDocumentsAttribute(formValues),
    ...createAquaAreaAttribute(formValues),
  };
}
