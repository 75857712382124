import * as ordersActions from 'app/actions/orders';
/* eslint-disable  */
import { handleActions } from 'redux-actions';

export default handleActions(
  {
    /**
     *
     * @param state
     */
    [`${ordersActions.apiGetOghOrders}_PENDING`]: function (state) {
      return state;
    },

    /**
     *
     * @param state
     */
    [`${ordersActions.apiGetOghOrders}_REJECTED`]: function (state) {
      state = {
        ...state,
        data: {},
      };
      return state;
    },

    /**
     *
     * @param state
     * @param action
     */
    [`${ordersActions.apiGetOghOrders}_FULFILLED`]: function (state, action) {
      state = {
        ...state,
        data: action.payload.data,
      };
      return state;
    },
  },
  { data: {} },
);
