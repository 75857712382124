import { fetchGlonassModel, fetchProviders } from 'app/api/typeahead';
import CardAbstract from 'app/components/card/common/CardAbstract';
import { elementFormGrid } from 'app/components/card/common/grid/index';
import { parseDate } from 'app/components/card/common/parse';
import { Tabs } from 'app/components/card/common/tabs';
import Versions from 'app/components/card/tech/versions';
import { WithOldValueSelect } from 'app/components/common/SelectField';
import getDict from 'app/selectors/getDict';
import KeyboardDatePickerRF from 'core/form/reduxForm/fields/default/datePickers/KeyboardDatePickerRF';
import { AutocompleteAsyncRF } from 'core/form/reduxForm/fields/default/selects/AutocompleteAsyncRF';
import { TextFieldRF } from 'core/form/reduxForm/fields/default/TextFieldRF';
import { has } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import LinkageList from './glonass/LinkageList';
import printTechnikCardHeader from './header/technikHeader';

const getGpsStates = getDict('gpsStates');

const tabs = [
  {
    id: 'linkage-tab',
    name: 'Подключение',
  },
];

/**
 * GpsCard.
 */
class GpsCard extends React.Component {
  //

  /**
   * Render.
   *
   * @returns {JSX.Element}
   */
  render() {
    const { card, mode } = this.props;
    const editable = (mode && mode.editMode) || false;

    return (
      <CardAbstract>
        <div id="editor-card">
          {printTechnikCardHeader({
            card,
            mode,
          })}
          {card && (
            <div className="tabs" style={{ paddingLeft: '24px' }}>
              <ul>
                <li className="active" id="props-tab">
                  Свойства
                </li>
                <li id="versions-tab">Версии</li>
              </ul>
            </div>
          )}
          <div id="props">
            {elementFormGrid(
              [
                {
                  addition: { maxLength: 11, type: 'text' },
                  component: TextFieldRF,
                  editable,
                  formValue: true,
                  id: 'sim_number',
                  label: 'Номер SIM-карты',
                  name: 'sim_number',
                },
                {
                  addition: {
                    label: 'Дата ввода в эксплуатацию',
                  },
                  component: KeyboardDatePickerRF,
                  editable,
                  formValue: true,
                  id: 'use_date',
                  name: 'use_date',
                },
                {
                  addition: {
                    fetchFunction: fetchProviders,
                    label: 'Поставщик телеметрических услуг',
                  },
                  component: AutocompleteAsyncRF,
                  editable,
                  formValue: true,
                  id: 'provider_id',
                  name: 'provider_id',
                },
                {
                  addition: {
                    fetchFunction: fetchGlonassModel,
                    label: 'Марка и модель оборудования',
                  },
                  component: AutocompleteAsyncRF,
                  editable,
                  formValue: true,
                  id: 'gps_model_id',
                  name: 'gps_model_id',
                },
                {
                  addition: {
                    maxLength: 20,
                    type: 'text',
                  },
                  component: TextFieldRF,
                  editable,
                  formValue: true,
                  id: 'serial_number',
                  label: 'Серийный номер',
                  name: 'serial_number',
                },
                {
                  addition: {
                    label: 'Состояние',
                    options: this.props.gpsStates,
                  },
                  component: WithOldValueSelect,
                  editable,
                  formValue: true,
                  id: 'gps_state_id',
                  name: 'gps_state_id',
                },
                {
                  addition: {
                    maxLength: 8,
                    type: 'text',
                  },
                  component: TextFieldRF,
                  editable,
                  formValue: true,
                  id: 'gps_code',
                  label: 'Код устройства БНСО',
                  name: 'gps_code',
                },
              ],
              2,
              '',
            )}
            <Tabs tabs={tabs} />
            <div id="linkage">
              <LinkageList list={this.props.initialValues.connect_list} />
            </div>
          </div>

          <div hidden={true} id="versions">
            <Versions card={card} />
          </div>
        </div>
      </CardAbstract>
    );
  }
}

/**
 * Mo.
 *
 * @param {*} state - A.
 * @param {*} props - Props.
 * @returns {*}
 */
const mapStateToProps = (state, props) => {
  let provider_id = props && props.card && props.card.provider_id;
  if (has(provider_id, 'value') && has(provider_id, 'label')) {
    provider_id = {
      id: provider_id.value,
      name: provider_id.label,
    };
  }

  let gps_model_id = props && props.card && props.card.gps_model_id;
  if (has(gps_model_id, 'value') && has(gps_model_id, 'label')) {
    gps_model_id = {
      id: gps_model_id.value,
      name: gps_model_id.label,
    };
  }

  return {
    enableReinitialize: true,

    gpsStates: getGpsStates(state),
    initialValues: {
      connect_list: props.card.connect_list,
      endDate: props.card.end_date,
      gps_code: props.card.gps_code,
      gps_model_id,
      gps_state_id: props.card.gps_state_id,
      id: props.card.id,
      name: props.card.name,
      provider_id,
      root_id: props.card.id,
      serial_number: props.card.serial_number,
      sim_number: props.card.sim_number,
      startDate: props.card.start_date,
      use_date: parseDate(props.card.use_date),
      uuid: props.card.uuid,
    },
    onSubmit: onSubmit(props, state.technologyObject.techTreePaths),
  };
};

/**
 * OnSubmit..
 *
 * @returns {*}
 */
const onSubmit = () => () => {
  throw 'Любая модификация БНСО через UI не поддерживается';
};

export default connect(mapStateToProps)(
  reduxForm({ form: 'editorCard' })(GpsCard),
);
