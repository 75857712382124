import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import {
  LIFE_FORM_TYPE_NAME,
  PLANTATION_TYPE_NAME,
} from 'core/uiKit/preparedInputs';
import { useField } from 'react-final-form';

/**
 * A.
 *
 * @returns {*}
 */
export const useFilterShrubs = () => {
  const { input: { value: lifeFormType } = {} } = useField(LIFE_FORM_TYPE_NAME);
  const { input: { value: plantationTypeName } = {} } =
    useField(PLANTATION_TYPE_NAME);

  const dictPlantationType = 'plantationType';
  const { data: plantationTypeDict = [] } =
    useGetDictionary(dictPlantationType);

  const dictLifeFormType = 'LifeFormType';
  const { data: lifeDict = [] } = useGetDictionary(dictLifeFormType);

  /**
   * Фильтрация "Жизненная форма" в зависимости от "Тип насаждения".
   *
   * @param {*} data - A.
   * @returns {*}
   */
  const filterLifeForm = (data) => {
    const filtered = plantationTypeDict.find(
      (type) => type.id === plantationTypeName,
    );
    if (filtered !== undefined) {
      return data.filter((type) => {
        return filtered.life_form_type_ids.includes(type.id);
      });
    }
    return data;
  };

  /**
   * Фильтрация "Вид растения" в зависимости от "Жизненная форма".
   *
   * @param {*} data - A.
   * @returns {*}
   */
  const filterPlantForm = (data) => {
    const filtered = lifeDict.find((type) => type.id === lifeFormType);

    if (filtered !== undefined) {
      return data.filter((type) => {
        return type.life_form_type_ids.includes(filtered.id);
      });
    }
    return data;
  };

  return { filterLifeForm, filterPlantForm };
};
