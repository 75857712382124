import { get } from 'app/api/crud/get';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';

/**
 * Хук получения данных.
 *
 * @param {Array} queryKey - Данные для запроса.
 * @returns {*}
 */
export const useGet = (queryKey) => {
  const [url, queryParams] = queryKey;
  return useQueryAdaptor(queryKey, () => get(url, queryParams));
};
