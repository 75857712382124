import React from 'react';

 
/**
 * Иконка границ районов с возможнотью задать активный цвет.
 *
 * @param {{active: boolean}} active - Флаг true false.
 * @returns {React.ReactComponentElement} - Жопа.
 * @example  <Icon active={active} />
 */
const Icon = ({ active }) => {
  return (
    <svg
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="23"
        height="22"
        stroke="#6A6A6A"
        stroke-dasharray="2 2"
      />
      <rect
        x="1.59094"
        y="1.54546"
        width="20.8182"
        height="19.9091"
        fill="url(#paint0_linear_706_48)"
        stroke="white"
      />
      <path
        d="M15.7785 6.90279L4.43768 14.6356C3.66796 15.1604 2.66193 14.4403 2.91061 13.5424L5.28033 4.98701C5.40944 4.52086 5.85369 4.21395 6.33536 4.25812L15.3065 5.08076C16.2402 5.16638 16.5532 6.37452 15.7785 6.90279Z"
        fill={active ? '#FF9700' : '#1680AE'}
      />
      <path
        d="M17.6414 7.7817L11.0076 12.3387C10.392 12.7616 10.4403 13.6854 11.0966 14.0418L19.8704 18.806C20.6272 19.2169 21.5149 18.5428 21.3222 17.7034L19.1823 8.3822C19.0244 7.69444 18.223 7.38215 17.6414 7.7817Z"
        fill={active ? '#FF9700' : '#1680AE'}
      />
      <defs>
        <linearGradient
          id="paint0_linear_706_48"
          x1="12"
          y1="1.04546"
          x2="12"
          y2="21.9545"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C0C2C4" />
          <stop offset="1" stop-color="#DEDFE0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Icon;
