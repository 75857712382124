import IconButton from 'core/newComponents/IconButton';
import { BackspaceIcon } from 'core/uiKit/material-ui';
import { useNavigateRouter } from 'core/utils/router';
import React from 'react';

/**
 * Компонент ReturnBack.
 *
 * @returns {JSX.Element}
 */
export const ReturnBack = () => {
  const navigate = useNavigateRouter();

  /**
   * Функция вернуться на страницу назад.
   *
   * @returns {void}
   */
  const handleCancelButtonClick = () => {
    navigate(-1);
  };

  return (
    <IconButton
      className="return-back"
      title="Вернуться на предыдущую страницу"
      onClick={handleCancelButtonClick}
    >
      <BackspaceIcon />
    </IconButton>
  );
};
