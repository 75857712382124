import { withFinalForm } from 'core/form/finalForm/helpers/withFinalForm';
import {
  RELATED_ELEMENTS_TREES_SHRUBS_ID_NAME,
  RelatedElementsTreesShrubsId,
} from 'core/uiKit/preparedInputs/numberField/RelatedElementsTreesShrubsId';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * NumberField FF Связанные элементы ЗН (ID) - Деревья/Кустарники.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const RelatedElementsTreesShrubsIdFF = (props) => {
  return (
    <Field
      {...props}
      name={RELATED_ELEMENTS_TREES_SHRUBS_ID_NAME}
      component={withFinalForm(RelatedElementsTreesShrubsId)}
    />
  );
};
