/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

import { setTableParam } from 'app/actions/tableParamsActions';

import PagingSelect from './PagingSelect';
import './Pagination.scss';
import PaginationData from './PaginationData';

function Pagination(props) {
  const pagination = new PaginationData(props.paginationData);

  return pagination.total ? (
    <div className="navigation flex-shrink-0">
      <div className="nav-label">
        {pagination.total !== 0 &&
          `Показаны с ${pagination.from} по ${pagination.to} из ${pagination.total}`}
        {pagination.total === 0 && 'Результаты не найдены'}
      </div>
      {pagination.total !== 0 && (
        <div className="nav-rows">
          <PagingSelect
            handleOnChange={(e) => {
              if (props.onLimit) {
                props.onLimit(e.target.value);
              } else {
                props.dispatch(
                  setTableParam(props.id, 'max_rows', e.target.value),
                );
                props.dispatch(submit(props.id));
              }
            }}
            limit={pagination.limit}
          />
        </div>
      )}
      <div className="nav">
        {pagination.hasPrevious() && (
          <span
            className="first p-1 active"
            title="Перейти на первую страницу"
            to="#"
            onClick={(event) => {
              event.preventDefault();
              if (props.onPagination) {
                props.onPagination(0);
              } else {
                props.dispatch(setTableParam(props.id, 'page', 0));
                props.dispatch(submit(props.id));
              }
            }}
          >
            &lt;&lt;
          </span>
        )}
        {!pagination.hasPrevious() && (
          <span className="first" title="Перейти на первую страницу">
            <em>&lt;&lt;</em>
          </span>
        )}
        {pagination.hasPrevious() && (
          <span
            className="prev p-1 active"
            title="Перейти на предыдущую страницу"
            onClick={(event) => {
              event.preventDefault();
              if (props.onPagination) {
                props.onPagination(pagination.page - 1);
              } else {
                props.dispatch(
                  setTableParam(props.id, 'page', pagination.page - 1),
                );
                props.dispatch(submit(props.id));
              }
            }}
          >
            &lt;
          </span>
        )}
        {!pagination.hasPrevious() && (
          <span className="prev" title="Перейти на предыдущую страницу">
            <em>&lt;</em>
          </span>
        )}
        {pagination.getRange(9).map((item) => {
          return pagination.page === item ? (
            <span className="goto" key={item + 1}>
              <em>
                <span>{item + 1}</span>
              </em>
            </span>
          ) : (
            <span className="goto" key={item + 1}>
              <span
                className={' p-1 active'}
                onClick={(event) => {
                  event.preventDefault();
                  if (props.onPagination) {
                    props.onPagination(item);
                  } else {
                    props.dispatch(setTableParam(props.id, 'page', item));
                    props.dispatch(submit(props.id));
                  }
                }}
              >
                <span>{item + 1}</span>
              </span>
            </span>
          );
        })}
        {pagination.hasNext() && (
          <span
            className="next p-1 active"
            title="Перейти на следующую страницу"
            onClick={(event) => {
              event.preventDefault();
              if (props.onPagination) {
                props.onPagination(pagination.page + 1);
              } else {
                props.dispatch(
                  setTableParam(props.id, 'page', pagination.page + 1),
                );
                props.dispatch(submit(props.id));
              }
            }}
          >
            &gt;
          </span>
        )}
        {!pagination.hasNext() && (
          <span className="next" title="Перейти на следующую страницу">
            <em>&gt;</em>
          </span>
        )}
        {pagination.hasNext() && (
          <span
            className="last p-1 active"
            title="Перейти на последнюю страницу"
            onClick={(event) => {
              event.preventDefault();
              if (props.onPagination) {
                props.onPagination(pagination.lastPage);
              } else {
                props.dispatch(
                  setTableParam(props.id, 'page', pagination.lastPage),
                );
                props.dispatch(submit(props.id));
              }
            }}
          >
            &gt;&gt;
          </span>
        )}
        {!pagination.hasNext() && (
          <span className="last" title="Перейти на последнюю страницу">
            <em>&gt;&gt;</em>
          </span>
        )}
      </div>
    </div>
  ) : (
    <div className="navigation flex-shrink-0">
      <div className="nav-label"> Результаты не найдены </div>
      <div className="nav">
        <span className="first">
          <em>&lt;&lt;</em>
        </span>
        <span className="prev">
          <em>&lt;</em>
        </span>
        <span className="next">
          <em>&gt;</em>
        </span>
        <span className="last">
          <em>&gt;&gt;</em>
        </span>
      </div>
    </div>
  );
}

 
export default connect()(Pagination);
