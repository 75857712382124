import {
  AddButtonOgh,
  Body,
  DialogCreateSimple,
  Header,
  Main,
  RegistryToolbar,
} from 'app/pages/components';
import { pathBasePgm } from 'app/pages/registry/ogh/basePgm/BasePgm/path.BasePgm';
import { pathOgh } from 'app/pages/registry/ogh/Ogh/path.Ogh';
import { pathRegistry } from 'app/pages/registry/path.Registry';
import { useGetOghTypes } from 'core/utils/hooks';

import { FilterBasePgm } from './Filter.BasePgm';
import { PrintButtonBasePgm } from './PrintButton.BasePgm';
import { TableBasePgm } from './Table.BasePgm';
import { TreePanelBasePgm } from './TreePanelBasePgm';

 
/* eslint-disable */
/**
 * Базы хранения ПГР
 * @returns {JSX.Element}
 * @constructor
 */
const BasePgm = () => {
  const oghType = useGetOghTypes('basePgm');
  const typeId = oghType.id;
  const path = `/${pathRegistry.path}/${pathOgh.path}/${pathBasePgm.path}`;

  return typeId ? (
    <Main>
      <Header Filter={<FilterBasePgm typeId={typeId} />} />
      <Body>
        <RegistryToolbar title={oghType.name}>
          <AddButtonOgh typeCode={oghType.code} />
          <PrintButtonBasePgm typeId={typeId} />
        </RegistryToolbar>
        <div className={'d-flex h-100 overflow-hidden-x'}>
          <TreePanelBasePgm root={path} />
          <TableBasePgm typeId={typeId} />
        </div>
        <DialogCreateSimple typeId={typeId} />
      </Body>
    </Main>
  ) : null;
};

 
export default BasePgm;
