const logLevel = {
   
   
/**
 * @constant {number} LOG_LEVEL_DEBUG Уровень ведения логов DEBUG.
 */
  LOG_LEVEL_DEBUG: 4,
   
   
/**
 * @constant {number} LOG_LEVEL_INFO Уровень ведения логов INFO.
 */
  LOG_LEVEL_INFO: 2,
   
  /* eslint-disable */
/**
   * @constant {Number} LOG_LEVEL_ERROR Уровень ведения логов ERROR.
   */
  LOG_LEVEL_ERROR: 1,
};

 
export default logLevel;
