/* eslint-disable */
import { shallow } from 'enzyme';

import { RANDOM_WORD, RANDOM_ID } from 'core/forTesting/constants';

import { TableFlowersGardenList } from './Table.FlowersGardenList';
import { columns } from './columns';

jest.mock('../Table.List');

 
describe('file Table.FlowersGardenList.spec', () => {
  it('text', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const wrapper = shallow(<TableFlowersGardenList />);
    // Assert
    expect(wrapper.text()).toBe('<TableList />');
  });

  it('props', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const wrapper = shallow(
      <TableFlowersGardenList code={RANDOM_WORD} RANDOM_Id={RANDOM_ID} />,
    );
    // Assert
    expect(wrapper.props()).toStrictEqual({
      code: RANDOM_WORD,
      columns,
    });
  });
});
