import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { ENGINEER_BUILDING_TYPE_ID } from 'core/uiKit/preparedInputs/selects/simpleSelect/EngineerBuildingTypeId';
import { UNIT_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/UnitId';
import { useField } from 'react-final-form';

/**
 * Хук редактирования и обязательности полей Вид покрытия.
 *
 * @returns {{disabled: boolean, required: boolean}}
 */
export const useEditableCoatingFields = () => {
  const { editMode } = useMode();

  const {
    input: { value: engineerBuildingTypeIdValue },
  } = useField(ENGINEER_BUILDING_TYPE_ID);

  const {
    input: { value: unitIdValue },
  } = useField(UNIT_ID_NAME);

  const disabled =
    editMode &&
    unitIdValue === 2 &&
    ![2, 9, 3].includes(engineerBuildingTypeIdValue);

  const required =
    unitIdValue === 2 && ![2, 9, 3].includes(engineerBuildingTypeIdValue);

  return { disabled, required };
};
