import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { usePreloaderCard } from 'app/pages/cardsOgh/components/PreloadedCard/usePreloaderCard';
import { useCreateForm } from 'app/pages/cardsOgh/hooks/useCreateForm';
import {
  CREATE_CARD,
  CREATE_FROM_PARENT,
  UPDATE_CARD,
  UPDATE_FROM_PARENT,
  useTypeActionCard,
} from 'app/pages/cardsOgh/hooks/useTypeActionCard';
import { useUpdateForm } from 'app/pages/cardsOgh/hooks/useUpdateForm';
import { preparationValues } from 'core/utils/helpers/preparationValues';
import { useCallback } from 'react';

/**
 * Хук получения функции сохранения паспортов.
 *
 * @returns {Function}
 */
export const useSaveCardWithoutGeometry = () => {
  const card = useGetCurrentCard();
  const parent = useGetParentCard();

  const { updateForm } = useUpdateForm();
  const { fetchCreateForm } = useCreateForm();
  const typeAction = useTypeActionCard();

  const { startPreload } = usePreloaderCard();

  /**
   * Функция, сохранения с проверкой пересечений.
   *
   * @param {object} params - Параметры.
   * @param {object} params.values - Значения формы.
   * @param {object} params.attribute - Атрибуты.
   * @param {object} params.otherParams - Остальные пропсы.
   * @returns {Promise<{attribute, otherParams: {}}>}
   */
  return useCallback(
    ({ values, attribute, otherParams = {} }) => {
      startPreload();

      const valuesPreparation = preparationValues(values);

      const prepareData = {
        attribute,
        childObjectTypeId: card.typeId,
        end_date: card.endDate,
        id: card.recordId,
        record_id: card.recordId,
        root_id: card.rootId,
        start_date: card.startDate,
        type: card.typeId,
        ...valuesPreparation,
        ...otherParams,
      };

      switch (typeAction) {
        case CREATE_FROM_PARENT: {
          const dataCreate = {
            is_child: true,
            parent_id: parent.recordId,
            root_object_id: parent.recordId,
            root_object_type_id: parent.typeId,
          };

          fetchCreateForm({ ...prepareData, ...dataCreate });
          break;
        }
        case UPDATE_FROM_PARENT: {
          const dataUpdate = {
            is_child: true,
            old_child_object_id: card.rootId,
            parent_id: parent.recordId,
            root_object_id: parent.recordId,
            root_object_type_id: parent.typeId,
          };
          updateForm({ ...prepareData, ...dataUpdate });
          break;
        }
        case CREATE_CARD: {
          const dataCreate = {
            is_child: false,
            root_object_id: card.rootId,
            root_object_type_id: card.typeId,
          };
          fetchCreateForm({ ...prepareData, ...dataCreate });
          break;
        }
        case UPDATE_CARD: {
          const dataUpdate = {
            is_child: false,
            old_child_object_id: card.rootId,
            root_object_id: card.rootId,
            root_object_type_id: card.typeId,
          };
          updateForm({ ...prepareData, ...dataUpdate });
          break;
        }
        default:
          return null;
      }
    },

    // eslint-disable-next-line
    [parent, card],
  );
};
