import { FormCardFF } from 'app/pages/cardsOgh/components/FormCard.FF';
import { validateVerticalLandscaping } from 'app/pages/cardsOgh/VerticalLandscaping/validate.VerticalLandscaping';
import React from 'react';

import { LeftPanelVerticalLandscaping } from './LeftPanel.VerticalLandscaping';
import { useSetupVerticalLandscaping } from './useSetup.VerticalLandscaping';

/**
 * Инженерные сооружения.
 *
 * @returns {JSX.Element}
 */
export const VerticalLandscaping = () => {
  const { initialValues, preparingDataForAttributes } =
    useSetupVerticalLandscaping();

  return (
    <FormCardFF
      validate={validateVerticalLandscaping}
      initialValues={initialValues}
      preparingDataForAttributes={preparingDataForAttributes}
    >
      <LeftPanelVerticalLandscaping />
    </FormCardFF>
  );
};
