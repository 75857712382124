import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { OghCardHeaderFF } from 'app/pages/cardsOgh/components/headerFF/OghHeaderFF';
import Versions from 'app/pages/cardsOgh/components/tables/Versions/Versions';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { ReagentIdFFTank } from 'app/pages/cardsOgh/Tank/components/ReagentIdFF.Tank';
import { ReagentKindIdFFTank } from 'app/pages/cardsOgh/Tank/components/ReagentKindIdFF.Tank';
import { TonnageFFTank } from 'app/pages/cardsOgh/Tank/components/TonnageFF.Tank';
import { NumberFieldFF, TankNumFF } from 'core/form/finalForm/fields';
import { TextFieldFF } from 'core/form/finalForm/fields/default/TextFieldFF';
import { OtherDocumentsFf } from 'core/form/finalForm/fields/prepared/tables/documentTables/OtherDocuments.FF';
import { CardContainer } from 'core/uiKit/components/card.components/CardContainer';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { TabsCardOGH } from 'core/uiKit/components/card.components/TabsCardOGH';
import { TabsCardOGHItem } from 'core/uiKit/components/card.components/TabsCardOGH/TabsCardOGH.Item';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import React from 'react';

/**
 * TankLeftPanel компонент.
 *
 * @returns {JSX.Element} - JSX.
 * @example <TankLeftPanel />
 */
export const TankLeftPanel = () => {
  const { editMode } = useMode();
  const { rootId = 0 } = useGetCurrentCard();
  const isShow = Boolean(rootId);

  return (
    <CardContainer>
      <OghCardHeaderFF />
      <TabsCardOGH>
        <TabsCardOGHItem tabTitle={'Свойства'}>
          <FieldsSplitColumns>
            <ReagentKindIdFFTank />
            <ReagentIdFFTank />
          </FieldsSplitColumns>

          <ParentInfo />
          <TextFieldFF
            label={'Описание емкости'}
            name={'descr'}
            disabled={!editMode}
          />

          <TabsCardOGH>
            <TabsCardOGHItem tabTitle={'Исходные данные'}>
              <FieldsSplitColumns>
                <TankNumFF required={true} disabled={!editMode} />
                <NumberFieldFF
                  name={'quantity'}
                  label={'Объем, куб.м.'}
                  maxLength={10}
                  decimalScale={1}
                  required={true}
                  disabled={!editMode}
                />
                <NumberFieldFF
                  name={'minimumBalancePercent'}
                  label={'Неснижаемый остаток, %'}
                  maxLength={3}
                  defaultValue={5}
                  required={true}
                  disabled={!editMode}
                />
                <TonnageFFTank />
              </FieldsSplitColumns>
            </TabsCardOGHItem>

            <TabsCardOGHItem tabTitle={'Документы'}>
              <OtherDocumentsFf />
            </TabsCardOGHItem>
          </TabsCardOGH>
        </TabsCardOGHItem>
        <TabsCardOGHItem tabTitle={'Версии'} hide={!isShow}>
          <Versions />
        </TabsCardOGHItem>
      </TabsCardOGH>
    </CardContainer>
  );
};
