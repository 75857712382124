import { REPAIRS_INFO_LIST_NAME } from './components/tabs/Repairs.Tab/Table.Repairs.Tab';
import { REPAIRS_INFO_LIST_PLAN_NAME } from './components/tabs/RepairsPlan.Tab';
import { improvementObjectCardTabs, oznCardTabs, yardCardTabs } from './tabs';

/**
 * Mock Default.
 *
 * @returns {void}
 */
const mockDefault = () => {};

describe('🐛 spec tabs.spec', () => {
  it('🧪 improvementObjectCardTabs', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock

    //🔥 Act
    const res = improvementObjectCardTabs;

    //❓ Assert
    expect(res).toStrictEqual([
      {
        id: 'squares-tab',
        name: 'Площади',
      },
      {
        id: 'plantation-tab',
        name: 'Озеленение',
      },
      {
        id: REPAIRS_INFO_LIST_NAME + '-tab',
        name: 'Ремонты',
      },
      {
        id: REPAIRS_INFO_LIST_PLAN_NAME + '-tab',
        name: 'Проекты',
      },
      {
        id: 'docs-tab',
        name: 'Документы',
      },
      {
        id: 'orders-tab',
        name: 'Ордера на работы',
      },
    ]);
  });

  it('🧪 yardCardTabs', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock

    //🔥 Act
    const res = yardCardTabs;

    //❓ Assert
    expect(res).toStrictEqual([
      {
        id: 'squares-tab',
        name: 'Площади',
      },
      {
        id: 'plantation-tab',
        name: 'Озеленение',
      },
      {
        id: REPAIRS_INFO_LIST_NAME + '-tab',
        name: 'Ремонты',
      },
      {
        id: REPAIRS_INFO_LIST_PLAN_NAME + '-tab',
        name: 'Проекты',
      },
      {
        id: 'docs-tab',
        name: 'Документы',
      },
      {
        id: 'orders-tab',
        name: 'Ордера на работы',
      },
    ]);
  });

  it('🧪 oznCardTabs', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock

    //🔥 Act
    const res = oznCardTabs;

    //❓ Assert
    expect(res).toStrictEqual([
      {
        id: 'squares-tab',
        name: 'Площади',
      },
      {
        id: 'plantation-tab',
        name: 'Озеленение',
      },
      {
        id: 'docs-tab',
        name: 'Документы',
      },
      {
        id: 'orders-tab',
        name: 'Ордера на работы',
      },
      {
        id: 'additional-information-tab',
        name: 'Дополнительная информация',
      },
    ]);
  });
});
