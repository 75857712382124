import { OWNER_NAME, OwnerFF } from 'core/form/finalForm/fields';
import React from 'react';
import { StringParam } from 'use-query-params';

/* eslint-disable */
export { OWNER_NAME };

/**
 * Балансодержатель.
 *
 * @param {*} props - Props.
 * @returns {JSX.Element}
 */
export const OwnerFilter = (props) => (
  <OwnerFF {...props} className={filterInput} />
);

/**
 * Params Балансодержатель .
 *
 * @returns {*}
 */
export const addOwnerParams = () => {
  return {
    [OWNER_NAME]: StringParam,
  };
};
