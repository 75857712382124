import { useGetNews } from 'app/api/hooks/news/useGet.News';
import { BodyNewsDialog } from 'app/components/dialogs/News.Dialog/Body.News.Dialog';
import { Dialog } from 'core/uiKit/components/dialogs/Dialog';
import React, { useEffect, useState } from 'react';
import { DataNews } from 'types/data.News';

/**
 * Оповещение Пользователя о Новой новости.
 *
 * @returns {JSX.Element}
 */
export const NewsDialog = () => {
  // todo: вынести хуки
  const { data = [] } = useGetNews();
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setIsOpen(Boolean((data as DataNews).length));
  }, [data]);

  return isOpen ? (
    <Dialog
      data-testid={'news-dialog'}
      className={'dialog__title_underline'}
      textHeader={'Важная информация!'}
      isOpen={true}
      Body={<BodyNewsDialog />}
    />
  ) : null;
};
