import { RANDOM_ID } from 'core/forTesting/constants';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import { shallow } from 'enzyme';
import { useState } from 'react';
import { vi } from 'vitest';

import { ApproveElectronicSignature } from './ApproveElectronicSignature';
import { ApproveElectronicSignatureButton } from './ApproveElectronicSignature.Button';
import { DialogApproveElectronicSignature } from './Dialog.ApproveElectronicSignature';

vi.mock('core/utils/hooks/useGetObjectId');
vi.mock('./Dialog.ApproveElectronicSignature');
vi.mock('./ApproveElectronicSignature.Button');

vi.mock('react', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    useState: vi.fn(),
  };
});
describe('file ApproveElectronicSignature', () => {
  it('simple test', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const spySet = vi.fn();
    useState.mockImplementation((value) => [value, spySet]);
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    // Act
    const wrapper = shallow(<ApproveElectronicSignature />);
    // Assert
    expect(wrapper.text()).toBe('<ApproveElectronicSignatureButton />');
  });
  it('simple test isOpen', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const spySet = vi.fn();
    useState.mockImplementation(() => [true, spySet]);
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    // Act
    const wrapper = shallow(<ApproveElectronicSignature />);
    // Assert
    expect(wrapper.text()).toBe(
      '<ApproveElectronicSignatureButton /><DialogApproveElectronicSignature />',
    );
  });

  it('props ApproveElectronicSignatureButton', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const spySet = vi.fn();
    useState.mockImplementation((value) => [value, spySet]);
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    // Act
    const wrapper = shallow(<ApproveElectronicSignature />);
    const Button = wrapper.find(ApproveElectronicSignatureButton);
    // Assert

    expect(Button.props()).toStrictEqual({
      objectId: RANDOM_ID,
      onClick: expect.any(Function),
    });

    // Act
    Button.prop('onClick')();
    // Assert
    expect(spySet).toHaveBeenCalledWith(true);
  });

  it('props is Open', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const spySet = vi.fn();
    useState.mockImplementation(() => [true, spySet]);
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    // Act
    const wrapper = shallow(<ApproveElectronicSignature />);
    const Button = wrapper.find(ApproveElectronicSignatureButton);
    const Dialog = wrapper.find(DialogApproveElectronicSignature);

    // Assert
    expect(Button.props()).toStrictEqual({
      objectId: RANDOM_ID,
      onClick: expect.any(Function),
    });
    expect(Dialog.props()).toStrictEqual({
      onClose: expect.any(Function),
    });

    // Act
    Button.prop('onClick')();

    // Assert
    expect(spySet).toHaveBeenCalledWith(true);

    // Act
    Dialog.prop('onClose')();

    // Assert
    expect(spySet).toHaveBeenCalledWith(false);
  });
});
