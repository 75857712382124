import { RegistrySkeleton } from 'core/uiKit/components';
import React, { Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { DictionaryViewLazy } from './DictionaryView';
import { pathDictionaryView } from './DictionaryView/path.DictionaryView';
import { NSILazy } from './NSI';
import { NSIDirectoryChangeLogLazy } from './NSIDirectoryChangeLog';
import { pathNSIDirectoryChangeLog } from './NSIDirectoryChangeLog/path.NSIDirectoryChangeLog';

/**
 * Роут NSI.
 *
 * @returns {JSX.Element} - JSX.
 */
export const NSIRoute = () => {
  useEffect(() => {
    DictionaryViewLazy.preload();
    NSILazy.preload();
    NSIDirectoryChangeLogLazy.preload();
  });

  return (
    <Suspense fallback={<RegistrySkeleton />}>
      <Routes>
        <Route element={<NSILazy />} path={`/`} />
        <Route
          element={<NSIDirectoryChangeLogLazy />}
          path={`${pathNSIDirectoryChangeLog.path}`}
        />
        <Route
          element={<DictionaryViewLazy />}
          path={`${pathDictionaryView.path}/:code`}
        />
      </Routes>
    </Suspense>
  );
};
