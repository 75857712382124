import { approveRequest, objectMatching } from 'app/actions/adminActions';
/* eslint-disable  */
import { useGetVisibilityButtonsOgh } from 'app/api/hooks/useGetVisibilityButtonsOgh';
import { formValidation } from 'app/components/dialogs/common/RejectObject';
import { entityCode, operation } from 'app/constants/matching';
import { useFetchRegistryMatchingHistory } from 'app/pages/titleList/TitleList/api';
import { queryClient } from 'app/routes/QueryClientProvider';
import { ContinueFFDialog } from 'core/form/finalForm/components/dialogs/ContinueFF.Dialog';
import { ApproveObjectOghButton } from 'core/uiKit/components/buttons/actionsButtons/ApproveObjectOgh.Button';
import { TextFieldMUI } from 'core/uiKit/material-ui';
import { COMMENT_NAME } from 'core/uiKit/preparedInputs/textField/Comment';
import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

/**
 * Компонент ApproveObject.
 *
 * @param {object} props - Пропсы.
 * @param {*} props.editMode - Any.
 * @param {*} props.typeId - Any.
 * @param {*} props.approveRequest - Any.
 * @returns {JSX.Element|null}
 */
const ApproveObject = ({ editMode, typeId, approveRequest }) => {
  const approved = operation.APPROVED;

  const [isOpen, setOpen] = useState(false);
  const { childId, id } = useParams();
  const objectId = parseInt(childId || id, 10);
  const oghCode = entityCode.OGH;
  const { data: { approve_object: approveObject = false } = {} } =
    useGetVisibilityButtonsOgh(objectId);
  const show = !editMode && objectId && approveObject;

  const { data: table = [], isLoading } = useFetchRegistryMatchingHistory(
    {
      id: id,
      type: oghCode,
      typeId: typeId,
    },
    { enabled: Boolean(show) },
  );

  const beforeLastEntry = table[table.length - 2];
  const beforeLastEntryComment = beforeLastEntry?.comment;

  /**
   * Метод handleClickOpen.
   *
   * @returns {void}
   */
  const handleClickOpen = () => {
    setOpen(true);
  };

  /**
   * Метод handleClose.
   *
   * @returns {void}
   */
  const handleClose = () => {
    setOpen(false);
    queryClient.invalidateQueries({ queryKey: ['getVisibilityButtonsOgh'] });
  };

  /**
   * Метод handleClose.
   *
   * @param {object} values - Значения.
   * @returns {void}
   */
  const send = async (values) => {
    await approveRequest({
      ...{
        entityCode: oghCode,
        id: objectId,
        operation: approved,
      },
      typeId,
      ...values,
    });
    handleClose();
  };

  return show ? (
    <>
      <ApproveObjectOghButton objectId={objectId} onClick={handleClickOpen} />
      <ContinueFFDialog
        {...{
          Body: (
            <>
              <Field
                name={COMMENT_NAME}
                render={({ input }) => (
                  <TextFieldMUI
                    label={'Комментарий'}
                    multiline={true}
                    rowsMax={13}
                    maxLength={1000}
                    rows={4}
                    className={'mt-3'}
                    variant={'outlined'}
                    {...input}
                  />
                )}
              />
            </>
          ),
          closeDialog: handleClose,
          initialValues: {
            [COMMENT_NAME]: beforeLastEntryComment,
          },
          isLoading,
          isOpen,
          onSubmit: send,
          textHeader: 'Утвердить добавление объекта',
          validate: formValidation,
        }}
      />
    </>
  ) : null;
};

/**
 * Функция mapStateToProps.
 *
 * @param {object} state - Стейт.
 * @returns {object}
 */
function mapStateToProps(state) {
  return {
    editMode: state.card?.mode?.editMode,
    typeId: state.card?.object?.type_id,
  };
}

/**
 * Функция mapDispatchToProps.
 *
 * @param {Function} dispatch - Диспатч.
 * @returns {{objectMatching: (function(*): *), approveRequest: (function(*): *)}}
 */
function mapDispatchToProps(dispatch) {
  return {
    /**
     *
     * @param data
     */
    approveRequest: (data) => {
      return dispatch(approveRequest(data));
    },

    /**
     *
     * @param data
     */
    objectMatching: (data) => {
      return dispatch(objectMatching(data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApproveObject);
