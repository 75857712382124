import {
  FolderIcon,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from 'core/uiKit/material-ui';
import PropTypes from 'prop-types';

/**
 * Компонент список файлов.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX}
 */
export const FileListView = (props) => {
  const files = props.files
    ? Array.isArray(props.files)
      ? props.files
      : [props.files]
    : [];
  return (
    <List dense>
      {files?.map((file) => (
        <ListItem key={file.name} className={'dnd-file-upload__list'}>
          <ListItemIcon>
            <FolderIcon />
          </ListItemIcon>
          <ListItemText primary={file.name} />
          <IconButton
            disabled={props.disabled}
            title="Удалить файл"
            size={'small'}
            onClick={() => {
              props.remove(file);
            }}
          >
            <Icon>delete</Icon>
          </IconButton>
        </ListItem>
      ))}
    </List>
  );
};

FileListView.propTypes = {
  files: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  remove: PropTypes.func.isRequired,
};
