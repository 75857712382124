import { renderCustomTextField } from 'app/components/card/common/grid/index';

/* eslint-disable */
export default function getElements({ editable }) {
  return [
    {
      id: 'snow_clean_area',
      name: 'snow_clean_area',
      label: 'Площадь вывоза снега, кв. м',
      editable,
      formValue: true,
      component: renderCustomTextField,
      addition: {
        FormHelperTextProps: {
          error: true,
        },
        type: 'decimal',
        digits: 2,
        required: true,
      },
    },
  ];
}
