/**
 *
 * @type {{fillColor: string, color: string, legendColor: string}}
 */
export const selfColors = {
  color: 'rgba(220, 20, 60, 0.5)',
  fillColor: 'rgba(178, 34, 34, 0.25)',
  legendColor: 'rgba(178, 34, 34, 1)',
};

/**
 *
 * @type {{fillColor: string, color: string, legendColor: string}}
 */
export const parentsColors = {
  color: 'rgba(199, 21, 133, 0.5)',
  fillColor: 'rgba(255, 192, 203, 0.25)',
  legendColor: 'rgba(255, 192, 203, 1)',
};

/**
 *
 * @type {{fillColor: string, color: string, legendColor: string}}
 */
export const intersectedColors = {
  color: 'rgba(105, 105, 105, 0.5)',
  fillColor: 'rgba(148, 0, 211, 0.25)',
  legendColor: 'rgba(148, 0, 211, 1)',
};

/**
 *
 * @type {{fillColor: string, color: string, legendColor: string}}
 */
export const ordersColors = {
  color: 'rgba(30, 144, 255, 0.5)',
  fillColor: 'rgba(135, 206, 250, 0.25)',
  legendColor: 'rgba(135, 206, 250, 1)',
};

/**
 *
 * @type {{fillColor: string, color: string, legendColor: string}}
 */
export const reonColors = {
  color: 'rgba(255, 215, 0, 0.5)',
  fillColor: 'rgba(255, 255, 0, 0.25)',
  legendColor: 'rgba(255, 255, 0, 1)',
};

/**
 *
 * @type {{fillColor: string, color: string, legendColor: string}}
 */
export const districtBoundariesColors = {
  color: 'rgba(255,166,0,0.5)',
  fillColor: 'rgba(255,145,0,0.25)',
  legendColor: 'rgb(255,166,0)',
};

/**
 *
 * @type {{fillColor: string, color: string, legendColor: string}}
 */
export const childrenColors = {
  color: 'rgba(255, 69, 0, 0.5)',
  fillColor: 'rgba(250, 128, 114, 0.25)',
  legendColor: 'rgba(250, 128, 114, 1)',
};

/**
 *
 * @type {{fillColor: string, color: string, legendColor: string}}
 */
export const originalColors = {
  color: 'rgba(0, 0, 255, 0.85)',
  fillColor: 'rgba(0, 0, 255, 0.25)',
  legendColor: 'rgba(0, 0, 255, 1)',
};

/**
 *
 * @type {{fillColor: string, color: string, legendColor: string}}
 */
export const copyColors = {
  color: 'rgba(0, 0, 255, 0.85)',
  fillColor: 'rgba(255, 0, 0, 0.25)',
  legendColor: 'rgba(255, 0, 0, 1)',
};

/**
 *
 * @type {{fillColor: string, color: string, legendColor: string}}
 */
export const neighboursColors = {
  color: 'rgba(46, 139, 87, 0.5)',
  fillColor: 'rgba(152, 251, 152, 0.25)',
  legendColor: 'rgba(152, 251, 152, 1)',
};
