/* eslint-disable */
import React from 'react';
import { NumberParam } from 'use-query-params';

import {
  CarFuncTypeIdFF,
  CAR_FUNC_TYPE_ID_NAME,
} from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
 
/**
 * Тип техники car_func_type_id int
 */
 
export const CarFuncTypeIdFilter = (props) => (
  <CarFuncTypeIdFF {...props} className={filterInput} />
);

 
export { CAR_FUNC_TYPE_ID_NAME };

 
export const carFuncTypeIdParams = {
  [CAR_FUNC_TYPE_ID_NAME]: NumberParam,
};
