/* eslint-disable */
import React from 'react';
import { StringParam } from 'use-query-params';

import { TextFieldFF } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
export const FIO_NAME = 'fio';

 
export const fioParams = {
  [FIO_NAME]: StringParam,
};

 
/* eslint-disable */
/**
 * ФИО
 * @param label
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
 
export const FIOFilter = ({ label = 'ФИО', ...props }) => {
  return (
    <TextFieldFF
      {...props}
      className={filterInput}
      label={label}
      name={FIO_NAME}
    />
  );
};
