import { shallow } from 'enzyme';
import React from 'react';
import { vi } from 'vitest';

import { ReliefCard, ReliefComponent } from './Relief.Component';

vi.mock('app/api/hooks/autocomplete/useGetDictionary');

vi.mock('core/utils/hooks/useReactQuery', () => ({

  /**
   * Мок useQueryAdaptor.
   *
   * @param {Function} fn - Функция.
   * @returns {*}
   */
  useMutationAdaptor: (fn) => [fn],

  /**
   * Мок useQueryAdaptor.
   *
   * @param {object} keys - Массив ключей.
   * @param {Function} fn - Функция.
   * @param {object} options - Опции.
   * @returns {*}
   */
  useQueryAdaptor: (keys, fn, options) => ({
    data: options?.onSuccess ? options.onSuccess(fn(keys[1])) : fn(keys[1]),
  }),
}));

describe('🐛 spec Relief.Component', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)

    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(<ReliefComponent />);

    //❓ Assert
    expect(wrapper.text()).toBe('<ReliefCard />');
  });

  it('🧪 changeFieldValue default', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    const changeFieldValueSpy = vi.fn();
    // 🧹 clear mock

    const reliefCard = new ReliefCard();
    reliefCard.props = {
      changeFieldValue: changeFieldValueSpy,
      formValues: { unit_id: 3 },
      mode: {},
      quantityLock: '',
    };

    //🔥 Act
    reliefCard.componentDidUpdate({
      formValues: { unit_id: 3 },
      mode: {},
    });

    //❓ Assert
    expect(changeFieldValueSpy).toHaveBeenCalledTimes(0);
  });

  it('🧪 changeFieldValue !quantityLock', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    const changeFieldValueSpy = vi.fn();
    // 🧹 clear mock

    const reliefCard = new ReliefCard();
    reliefCard.props = {
      changeFieldValue: changeFieldValueSpy,
      formValues: { unit_id: 3 },
      mode: {},
      quantityLock: '',
    };

    //🔥 Act
    reliefCard.componentDidUpdate({
      formValues: { unit_id: 33 },
      mode: {},
    });

    //❓ Assert
    expect(changeFieldValueSpy).toHaveBeenCalledWith('quantity', '');
  });

  it('🧪 changeFieldValue quantityLock', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    const changeFieldValueSpy = vi.fn();
    // 🧹 clear mock

    const reliefCard = new ReliefCard();
    reliefCard.props = {
      changeFieldValue: changeFieldValueSpy,
      formValues: { unit_id: 3 },
      mode: {},
      quantityLock: true,
    };

    //🔥 Act
    reliefCard.componentDidUpdate({
      formValues: { unit_id: 33 },
      mode: {},
    });

    //❓ Assert
    expect(changeFieldValueSpy).toHaveBeenCalledWith('quantity', 1);
  });

  it('🧪 render', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    const changeFieldValueSpy = vi.fn();
    // 🧹 clear mock

    const reliefCard = new ReliefCard();
    reliefCard.props = {
      changeFieldValue: changeFieldValueSpy,
      formValues: { unit_id: 3 },
      mode: {},
      quantityLock: '',
    };

    //🔥 Act
    const wrapper = shallow(reliefCard.render());

    //❓ Assert
    expect(wrapper.text()).toBe('<CardAbstract />');
  });
});
