import { useLocation } from 'react-router-dom';

/**
 * Хук проверки нахождения в паспорте ОГХ.
 *
 * @returns {boolean}
 */
export const useCheckCard = () => {
  const { pathname } = useLocation();
  const regex = /^\/ogh\/[0-9a-z/]+$/;
  return regex.test(pathname);
};
