import { log } from 'core/utils/log';

/**
 * Хук получения функции фильтрации.
 *
 * @param {object} params - Параметры.
 * @param {Function} params.filter - Функция фильтрации.
 * @param {Array} params.options - Опшины.
 * @param {string} params.value - Значение.
 * @param {boolean} params.disabled - Редактирование.
 * @param {string} params.optionKey - Ключи опшина.
 * @param {string} params.optionName - Имя Опшина.
 * @returns {Function}
 */
export const useGetFilteredOptions = ({
  filter,
  options,
  value,
  disabled,
  optionKey,
  optionName,
}) => {
  return () => {
    if (!Array.isArray(options)) {
      log.warn('options не массив', options);
      return [];
    }
    if (typeof filter === 'function') {
      const result = filter(options);
      // делаем так потому что надо показывать валуе даже если нет в этого в фильтре но только при просмотре.
      if (
        value === undefined ||
        result.find((item) => item[optionKey] === value) ||
        !disabled
      ) {
        return result;
      }
      return options;
    } else {
      return options.filter(
        (item) =>
          (item[optionKey] === 0 || item[optionKey]) && item[optionName],
      );
    }
  };
};
