import { REQUIRED_FIELD_MESSAGE } from 'app/constants/messages';
import { CATEGORY_SP_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CategorySpId/index';

import { validateCleanSpCategoryIdField } from './validateCleanSpCategoryIdField';

describe('ValidateCleanSpCategoryIdField', function () {
  it('test validate isOrphanObject true, categorySpId value undefined', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const errors = {};
    // Act
    validateCleanSpCategoryIdField(errors, true, undefined);
    // Assert
    expect(errors).toStrictEqual({});
  });
  it('atest validate isOrphanObject true, categorySpId value undefined', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const errors = {};
    // Act
    validateCleanSpCategoryIdField(errors, false, undefined);
    // Assert
    expect(errors).toStrictEqual({
      [CATEGORY_SP_ID_NAME]: REQUIRED_FIELD_MESSAGE,
    });
  });
});
