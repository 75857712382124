import { post } from 'app/api/crud/post';

/**
 * Метод rollbackTreeChanges.
 *
 * @param data - Данные для отправки.
 * @returns {*}
 */
export const postRollbackTreeChanges = (data: object) => {
  return post('/ogh/rollback_tree_changes', data, {
    convertIntoSnakeCase: false,
  });
};
