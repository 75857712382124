import { post } from 'app/api/crud/post';
import { uploadFilesReturnArrayFileIds } from 'app/api/requests/uploadFilesReturnArrayFileIds';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { queryClient } from 'app/routes/QueryClientProvider';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

/**
 * Хук Мутации Update for Card Методические материалы.
 *
 * @returns Мутация.
 */
export const useUpdateKnowledgeBaseMutation = () => {
  const { clearEditMode } = useMode();
  return useMutationAdaptor(
    async (options: {
      files_for_upload?: File[];
      file_list?: { file_id: string; file_name: string }[];
      id: number;
    }) => {
      const filesIdUpload = await uploadFilesReturnArrayFileIds(
        options?.files_for_upload || [],
      );

      const filesId = options?.file_list?.map(({ file_id }) => file_id) || [];

      const file_list = [...filesId, ...filesIdUpload].map((fileId) => ({
        id: fileId,
      }));

      return post(
        'knowledge_base/update',
        {
          attribute: { file_list },
          id: options.id,
        },
        { parseResponse: false },
      );
    },
    {

      /**
       * OnSuccess.
       */
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['knowledge_base'],
        });
        clearEditMode();
      },
    },
  );
};
