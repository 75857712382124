import { OwnerIdOdh } from 'core/uiKit/preparedInputs/selects/autocompleteAsync/OwnerIdOdh';

/**
 * Обертка РФ для балансодержателя.
 *
 * @param {object} params - Пропсы.
 * @param {object} params.input - Значения из формы РФ.
 * @returns {JSX.Element}
 */
export const OwnerIdOdhRFWithReduxForm = ({ input, ...props }) => {
  return <OwnerIdOdh {...input} {...props} />;
};
