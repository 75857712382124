import { fetchGlonassModel, fetchProviders } from 'app/api/typeahead';
import CardAbstract from 'app/components/card/common/CardAbstract';
import { elementFormGrid } from 'app/components/card/common/grid';
import {
  getParseAttribute,
  getParseOldAttribute,
  parseDate,
} from 'app/components/card/common/parse';
import { validateRequiredFieldsAsync } from 'app/components/card/common/validation';
import { transformValues } from 'app/utils/form/transformation';
import KeyboardDatePickerRF from 'core/form/reduxForm/fields/default/datePickers/KeyboardDatePickerRF';
import { AutocompleteAsyncRF } from 'core/form/reduxForm/fields/default/selects/AutocompleteAsyncRF';
import { TextFieldRF } from 'core/form/reduxForm/fields/default/TextFieldRF';
import _, { has } from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import printTechnikCardHeader from './header/technikHeader';
import submitCardTech from './submit';

/**
 * Card.
 */
class Card extends React.Component {
  //

  /**
   * Render.
   *
   * @returns {*}
   */
  render() {
    const card = this.props.card;
    const mode = this.props.mode;
    return (
      <CardAbstract>
        <div id="editor-card">
          {printTechnikCardHeader({
            card,
            mode,
          })}
          {card && (
            <div className="tabs" style={{ paddingLeft: '24px' }}>
              <ul>
                <li className="active" id="props-tab">
                  Свойства
                </li>
              </ul>
            </div>
          )}
          <div id="props">
            {elementFormGrid(
              [
                {
                  addition: {
                    maxLength: 10,
                    oldValue: this.props.old_sim_number,
                    type: 'text',
                  },
                  component: TextFieldRF,
                  editable: (mode && mode.editMode) || false,
                  formValue: true,
                  id: 'sim_number',
                  label: 'Номер SIM-карты',
                  name: 'sim_number',
                },
                {
                  addition: {
                    label: 'Дата ввода в эксплуатацию',
                    oldValue: this.props.old_use_date,
                  },
                  component: KeyboardDatePickerRF,
                  editable: (mode && mode.editMode) || false,
                  formValue: true,
                  id: 'use_date',
                  name: 'use_date',
                },
                {
                  addition: {
                    fetchFunction: fetchProviders,
                    label: 'Поставщик телеметрических услуг',
                    oldValue: this.props.old_provider_id,
                  },
                  component: AutocompleteAsyncRF,
                  editable: (mode && mode.editMode) || false,
                  formValue: true,
                  id: 'provider_id',
                  name: 'provider_id',
                },
                {
                  addition: {
                    fetchFunction: fetchGlonassModel,
                    label: 'Марка и модель оборудования',
                    oldValue: this.props.old_gps_model_id,
                  },
                  component: AutocompleteAsyncRF,
                  editable: (mode && mode.editMode) || false,
                  formValue: true,
                  id: 'gps_model_id',
                  name: 'gps_model_id',
                },
                {
                  addition: {
                    maxLength: 19,
                    oldValue: this.props.old_gps_code,
                    type: 'text',
                  },
                  component: TextFieldRF,
                  editable: (mode && mode.editMode) || false,
                  formValue: true,
                  id: 'gps_code',
                  label: 'Код ГЛОНАСС',
                  name: 'gps_code',
                },
              ],
              2,
              '',
            )}
          </div>
        </div>
      </CardAbstract>
    );
  }
}

/**
 * MapStateToProps.
 *
 * @param {*} state - State.
 * @param {*} props - Props.
 * @returns {*}
 */
const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);
  const parseOld = getParseOldAttribute(props);

  let provider_id = parse('provider');
  if (has(provider_id, 'value') && has(provider_id, 'label')) {
    provider_id = {
      id: provider_id.value,
      name: provider_id.label,
    };
  }

  let gps_model_id = parse('gps_model');
  if (has(gps_model_id, 'value') && has(gps_model_id, 'label')) {
    gps_model_id = {
      id: gps_model_id.value,
      name: gps_model_id.label,
    };
  }

  return {
    enableReinitialize: true,
    initialValues: {
      endDate: props.card.end_date,
      gps_code: props.card.gps_code,
      gps_model_id,
      provider_id,
      sim_number: props.card.sim_number,
      startDate: props.card.start_date,
      use_date: parseDate(props.card.use_date),
    },
    old_gps_code: props.card.old_gps_code,
    old_gps_model_id: parseOld('gps_model'),
    old_provider_id: parseOld('provider'),
    old_sim_number: props.card.old_sim_number,

    old_use_date: parseDate(props.card.old_use_date),
    onSubmit: onSubmit(props, state.technologyObject.techTreePaths),
  };
};

const mandatoryFields = [
  'use_date',
  'provider_id',
  'gps_model_id',
  'gps_code',
  'sim_number',
];
const dictFields = ['provider_id', 'gps_model_id'];
const numericFields = [];

/**
 * OnSubmit..
 *
 * @param {*} props - Props.
 * @param {*} techTreePaths - TechTreePaths.
 * @returns {*}
 */
const onSubmit = (props, techTreePaths) => (formValues) => {
  const technic_id = props.card.id;
  const technic_root_id = props.card.root_id;
  const technic_update = props.card.update_date;
  const formValuesCopy = { ...formValues };
  validateRequiredFieldsAsync(formValuesCopy, mandatoryFields);
  if (formValuesCopy['gps_model_id'].value !== null) {
    formValuesCopy['gps_model_id'] = parseInt(
      formValuesCopy['gps_model_id'].value,
      10,
    );
  }

  /**
   * IsDate..
   *
   * @param {*} key - A.
   * @returns {*}
   */
  const isDate = (key) => {
    return _.includes(key.toLowerCase(), 'date');
  };
  Object.keys(formValuesCopy).forEach((key) => {
    if (isDate(key)) {
      formValuesCopy[key] = parseDate(formValuesCopy[key]);
    }
  });

  const startDate = moment().add(1, 'm').format('YYYY-MM-DDTHH:mm:ssZ');
  const { interval: dates, attribute: technic_attribute } = transformValues(
    formValuesCopy,
    { dictFields, numericFields },
  );
  technic_attribute.use_date = moment(dates.use_date, 'DD.MM.YYYY').format(
    'YYYY-MM-DD',
  );
  if (
    formValuesCopy['provider_id'] &&
    formValuesCopy['provider_id'].id !== null
  ) {
    technic_attribute.provider_id = {
      legal_person: parseInt(formValuesCopy['provider_id'].id, 10),
    };
  }
  const sensor = {
    attribute: {
      description: 'Тест',
      file_list_id: [],
      technic_name: formValuesCopy.gps_code,
      technic_request_reason_id: { technic_request_reason: 10 },
    },
    planDate: startDate,
    tech: [
      {
        technic_attribute,
        technic_id: technic_id || null,
        technic_name: formValuesCopy.gps_code,
        technic_root_id: technic_root_id || null,
        technic_tree: techTreePaths,
        technic_type_id: 2,
        technic_update: technic_update || null,
      },
    ],
    technic_type_id: 2,
  };
  submitCardTech(props, sensor, requestIdsMapping);
};

const requestIdsMapping = {
  create: 7,
  delete: 8,
  edit: 9,
};

export default connect(mapStateToProps)(
  reduxForm({ form: 'editorCard' })(Card),
);
