import { LinkShortIdCell } from 'core/uiKit/components/cells/LinkShortId.Cell/index';

export const redBookPlantColumns = [
  {
    Cell: LinkShortIdCell,
    Header: 'ID',
    accessor: 'root_id',
    minWidth: 120,
  },
  {
    Header: 'Вид растения',
    accessor: 'object_type_name',
  },
  {
    Header: 'Статус',
    accessor: 'object_status_name',
    minWidth: 110,
  },
  {
    Header: 'Дата начала',
    accessor: 'start_date',
    minWidth: 90,
  },
  {
    Header: 'Дата окончания',
    accessor: 'end_date',
    minWidth: 120,
  },
];
