import { get } from 'app/api/crud/get';

/**
 * Fetch usernames.
 *
 * @param {string} startWith - Start with.
 * @returns {Promise}
 */
export const fetchUsernames = async (startWith) => {
  const res = await get('/diff/config/users/', { maxRows: 12, startWith });
  return res?.map((value) => {
    return { id: value.username, name: value.username };
  });
};
