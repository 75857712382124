import { ACTIVE, MATCHING_DEL } from 'app/constants/oghStatus';
import { ODH } from 'app/constants/oghTypes';
import {
  getChangeCarDate,
  getFirstDayOfYear,
  getLastDayOfYear,
  getYesterdayDate,
} from 'app/utils/dateUtils';
import getDefaultCarDate from 'app/utils/tech/getDefaultCarDate';
import getDefaultDateValue from 'app/utils/tech/getDefaultDateValue';
import moment from 'moment';
import { combineForms } from 'react-redux-form';

 
export { default as admin } from './admin';
export { default as api } from './api';
export { default as auth } from './auth';
export { default as calcAttributes } from './calcAttributes';
export { default as card } from './card';
export { default as dialog } from './dialog';
export { default as forms } from './forms';
export { default as isFetching } from './isFetching';
export { default as mapReducer } from './map';
export { default as matching } from './matching';
export { default as modals } from './modals';
export { default as navbar } from './navbar';
export { default as odsDicts } from './odsDicts';
export { default as oghOrders } from './oghOrders';
export { default as form } from './reduxForms';
export { default as registry } from './registry';
export { default as roleRegistry } from './roleRegistry';
export { default as tableColumns } from './tableColumns';
export { default as tables } from './tables';
export { default as technologyObject } from './technologyObject';
export { default as technologyRegistry } from './technologyRegistry';
export { default as technologyVersions } from './technologyVersions';
export { default as techReport } from './techReport';
export { default as templateRegistry } from './templateRegistry';
export { default as titlelistDetailsRegistry } from './titlelistDetailsRegistry';
export { default as tl } from './tl';
export { default as tree } from './tree';
/* eslint-disable */
export { default as serverInfo } from './serverInfo';

const startTime = {
  minute: 0,
  seconds: 0,
  milliseconds: 0,
};

/* eslint-disable */
export const rrf = combineForms(
  {
    oghRegistry: {
      isSubobjectPanelShow: true,
    },
    tlRegistry: {
      dateFrom: getFirstDayOfYear(new Date()),
      dateTo: getLastDayOfYear(new Date()),
      status: [ACTIVE, MATCHING_DEL],
      typeId: ODH,
      listTypeId: '',
      maxRows: 50,
      page: 0,
      sort: 'date_from.asc',
      withTotal: true,
    },
    ooptRegistry: {
      status: '',
      maxRows: 50,
      page: 0,
    },
    userRegistry: {
      name: '',
      fio: '',
      status: '',
      organization: '',
      role: '',
      maxRows: 50,
      page: 0,
      sort: 'username.asc',
    },
    roleRegistry: {
      maxRows: 50,
      page: 0,
      sort: 'create_date.asc',
    },
    changelogRegistry: {
      status: 2,
      entity: 'ogh',
      matchingChangeTypes: [],
      maxRows: 50,
      page: 0,
      sort: ['send_date.asc', 'last_date.asc'],
      dateFrom: getYesterdayDate(),
    },
    techRegistry: {
      technology: {
        code: 'car',
        date: getDefaultCarDate(),
        maxRows: 50,
        page: 0,
      },
      bnso: {
        code: 'gps',
        date: getDefaultDateValue(),
        maxRows: 50,
        page: 0,
      },
      equipment: {
        code: 'equipment',
        requiredTechEquipment: false,
        date: getDefaultDateValue(),
        maxRows: 50,
        page: 0,
      },
    },
    techReport: {
      date: getDefaultDateValue(),
      sensorKind: 1,
      maxRows: 50,
      page: 0,
      sort: 'root_id.asc',
    },
    technologyRegistry: {
      date: getDefaultDateValue(),
      sensorKind: 1,
      maxRows: 50,
      page: 0,
      sort: 'root_id.asc',
    },
    templateRegistry: {
      entity: '',
      maxRows: 50,
      page: 0,
    },
    templateRegistryOut: {
      rows: {},
      dialogs: {},
    },
    titlelistDetailsRegistry: {
      maxRows: 50,
      page: 0,
      grouping: 'none',
      withTotal: true,
    },
    report: {
      technology: {
        date: getDefaultDateValue(),
        typeId: '1',
        maxRows: 50,
        page: 0,
      },
      bnso: {
        date: getDefaultDateValue(),
        typeId: '2',
        maxRows: 50,
        page: 0,
      },
      equipment: {
        date: getDefaultDateValue(),
        typeId: '3',
        maxRows: 50,
        page: 0,
      },
      sensors: {
        date: getDefaultDateValue(),
        typeId: '4',
        sensorKind: '',
        maxRows: 50,
        page: 0,
      },
    },
    admin: {
      login: {},
      forgotPassword: {},
      resetPassword: {},
      role: {},
    },
    feedback: {
      phone: '',
      email: '',
      fio: '',
      subject: '',
      body: '',
    },
    changeCar: {
      files: [],
      dateTime: getChangeCarDate(),
    },
    changeEquipment: {
      files: [],
      dateTime: getChangeCarDate(),
    },
    treeEditSearch: {},
    technologyVersions: {
      maxRows: 50,
      page: 0,
    },
    nestedTableCard: {
      maxRows: 50,
      page: 0,
    },
    card: {
      ogh: {
        orders: {
          objectId: 0,
          workstartdate: getDefaultDateValue(),
          workenddate: getDefaultDateValue(),
          page: 0,
          maxRows: 50,
          progressStatus: 'in_progress',
        },
        basePgmOperations: {
          rootObjectId: 0,
          dateFrom: moment().set(startTime).toDate(),
          dateTo: moment().set(startTime).add(1, 'days').toDate(),
          page: 0,
          maxRows: 50,
        },
      },
      basePgmExport: {
        plan: {
          prefecture: '',
          prefChild: '',
          page: 0,
          maxRows: 50,
        },
      },
    },
  },
  'rrf',
);
