/* eslint-disable */
export function mapStateToProps(state) {
  return {
    privileges: state?.auth?.user?.privileges,
    oghTypes: state?.odsDicts?.oghTypes,
    techTypes: state?.odsDicts?.techTypes,
    curUsername: state?.auth?.user?.username,
    /* privileges: ['view_ogh_odh'],
    oghTypes: {
      '1': { // id
        "id": 1,  "code": "odh",  "name": "ОДХ",
      },
     '2': {
       "id": 2,  "code": 'yard',  "name": "ДТ",
      }
    }, */
  };
}
