import Link from 'app/components/common/misc/Link';
import { DropDown } from 'app/components/common/NavBar/DropDown/DropDown';
import PropTypes from 'prop-types';
import React from 'react';

/* eslint-disable */
export class DropDownLink extends DropDown {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    link: PropTypes.string,
    text: PropTypes.string,
    style: PropTypes.string,
    id: PropTypes.string,
    linkStyle: PropTypes.object,
  };

  render() {
    const { className, link, text, style, id, linkStyle } = this.props;

    return (
      <div className="dropdown-container" id={id}>
        <div className={className} style={style}>
          {link ? (
            <Link to={link}>{text}</Link>
          ) : (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a className={'nav-link'} style={linkStyle}>
              {text}
            </a>
          )}
        </div>
        <div className="dropdown-content">{this.props.children}</div>
      </div>
    );
  }
}
