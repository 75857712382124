import { tomorrowDateAndHours } from 'app/utils/dateUtils';
import moment from 'moment';

/**
 * Validates.
 *
 * @param {*} value - Value.
 * @returns {{}|null}
 */
export const validationForm = (value) => {
  let errors = {};

  const dateCurrent = moment(value.startDate).toDate();

  const tomorrowDate = new Date(new Date(tomorrowDateAndHours).toDateString());
  const checkedDate = new Date(new Date(dateCurrent).toDateString());

  if (new Date(value.startDate).toString() === 'Invalid Date') {
    errors.startDate = 'Невалидная дата';
  } else if (tomorrowDate > checkedDate) {
    errors.startDate = `Действует с не может быть раньше ${moment(
      tomorrowDateAndHours,
    ).format('DD.MM.YYYY')}`;
  }
  return Object.keys(errors).length > 0 ? errors : null;
};
