 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Категория озеленения__.
 *
 * @type {{
 * accessor: 'improvement_object_cat_name',
 * Header: 'Категория озеленения',
 * sortable: true,
 * }}
 * @augments Cell
 */
export const improvement_object_cat_name = {
  accessor: 'improvement_object_cat_name',
  Header: 'Категория озеленения',
  sortable: true,
};
