import { useState } from 'react';
import { vi } from 'vitest';

import { useSetupTableVersions } from './useSetup.Table.Versions';

vi.mock('react');

describe('useSetupTableVersions', function () {
  it('test', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    useState.mockImplementation((value) => [value, vi.fn()]);

    //🔥 Act

    //❓ Assert
    expect(useSetupTableVersions()).toStrictEqual({
      closeDialog: expect.any(Function),
      openDialog: false,
      rows: [],
      selectedRow: expect.any(Function),
      startComparison: expect.any(Function),
      warningMessage: '',
    });
  });
});
