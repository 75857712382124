 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */

import { CheckboxCell } from 'core/uiKit/components/cells/Checkbox.Cell';
 
/* eslint-disable */
/**
 * Колонка __Площадь УТН, кв.м__.
 *
 * @type {{ accessor: 'greening_addition', Header: 'Площадь УТН, кв.м', sortable: true }}
 * @augments Cell
 */
export const greening_addition = {
  accessor: 'greening_addition',
  Cell: CheckboxCell,
  Header: 'Требует дополнения по зелёным насаждениям',
  sortable: true,
};
