import { get } from 'lodash';

/**
 * Функция getLengthGeometry.
 *
 * @param {object} object - Объект.
 * @returns {number}
 */
export function getLengthGeometry(object) {
  return get(object, 'geometry.length', 0);
}
