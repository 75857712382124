import useDrawer from 'app/components/common/NavBar/components/Drawer/hooks/useDrawer';
/* eslint-disable  */

/**
 *
 */
export const useMain = () => {
  const [open, setOpen] = useDrawer();

  /**
   *
   * @param open
   */
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen(open);
  };

  return [open, toggleDrawer];
};
