 
/* eslint-disable */
/**
 * Функция необходимости проверки геометрии
 * @param geometry {obj} - параметр получаем из функции getGeometry(card.recordId) см. WithMapContext
 * @param typeIdParent {number} - парметр получаем из хука const {typeId} = useGetParentCard()
 * @param exceptionsArr {arr} - массив исключений геометрии [SPA, TANK] app/constants/oghTypes
 * @returns {boolean}
 */

 
export const checkGeometry = (
  geometry,
  typeIdParent = 0,
  exceptionsArr = [],
) => {
  if (exceptionsArr.includes(typeIdParent)) {
    return false;
  }
  return !Object.keys(geometry).length;
};
