/* eslint-disable */
import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { REASON_DELETE_NAME, ReasonDelete } from 'core/uiKit/preparedInputs';

const ReasonDeleteRFWithReduxForm = ({ input, ...props }) => {
  return <ReasonDelete {...input} {...props} />;
};

/* eslint-disable */
export const ReasonRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={REASON_DELETE_NAME}
      component={ReasonDeleteRFWithReduxForm}
    />
  );
};

ReasonRF.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
};
ReasonRF.defaultProps = {
  disabled: false,
};
