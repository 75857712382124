
 
export default [
  {
    id: 'root_id',
    name: 'ID',
  },
  {
    id: 'cad_number',
    name: 'Кадастровый номер ООПТ',
  },
  {
    id: 'status_name',
    name: 'Статус ООПТ',
  },
  {
    id: 'object_name',
    name: 'Наименование ООПТ',
  },
  {
    id: 'full_area_calculated',
    name: 'Общая площадь ООПТ, Га',
  },
  {
    id: 'meaning_name',
    name: 'Значение ООПТ',
  },
  {
    id: 'category_name',
    name: 'Категория ООПТ',
  },
  {
    id: 'profile_name',
    name: 'Профиль ООПТ',
  },
  {
    id: 'object_status_name',
    name: 'Статус',
  },
  {
    id: 'start_date',
    name: 'Дата начала',
    width: 90,
  },
  {
    id: 'end_date',
    name: 'Дата окончания',
    width: 120,
  },
];
