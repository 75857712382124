 
import React, { useState } from 'react';

 
export const ContextMapEdit = React.createContext(null);

/* eslint-disable */
export const EditMap = ({ children }) => {
  const [hasIntersections, setHasIntersections] = useState(false);
  const [hasReonIntersections, setHasReonIntersections] = useState(false);
  return (
    <ContextMapEdit.Provider
      value={{
        hasIntersections,
        setHasIntersections,
        hasReonIntersections,
        setHasReonIntersections,
      }}
    >
      {children}
    </ContextMapEdit.Provider>
  );
};
