import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';

import { checkRootCard } from '../../helpers/checkRootCard';

/**
 * Проверка на показ кнопки редактироания.
 *
 * @returns {*|boolean}
 */
export const useCheckShowChangeBtn = () => {
  const { typeId } = useGetCurrentCard();
  const { editMode } = useEditMode();

  return checkRootCard(typeId) && editMode;
};
