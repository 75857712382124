/* eslint-disable  */
import { Button } from 'core/uiKit/components/buttons/Button';
import { Dialog } from 'core/uiKit/components/dialogs/Dialog';
import React, { FC } from 'react';

export interface ConfirmDialogProps {
  /**
   * Тело диалога.
   */
  Body: JSX.Element | string;

  /**
   * Функция закрытия окна.
   */
  closeDialog: () => void;

  /**
   * Внешний флаг - блокировка кнопок.
   */
  isLoading?: boolean;

  /**
   * Внешний флаг - отобразить диалоговое окно, по умолчанию - нет.
   */
  isOpen: boolean;

  /**
   * При нажатии на Продолжить - вызвать функцию.
   */
  send: () => void;

  /**
   * Текст кнопки продолжить.
   */
  textContinueButton?: string;

  /**
   * Текст заголовка.
   */
  textHeader: string;
}

/**
 * Диалог Подтверждения действия.
 *
 * @param {object} root0 - Property.
 * @param {JSX.Element} root0.Body - Тело диалога.
 * @param {Function} root0.closeDialog - Функция закрытия окна.
 * @param {boolean} [root0.isLoading] - Внешний флаг - блокировка кнопок.
 * @param {boolean} root0.isOpen - Внешний флаг - отобразить диалоговое окно, по умолчанию - нет.
 * @param {Function} root0.send - При нажатии на Продолжить - вызвать функцию.
 * @param {string} root0.textHeader - Текст заголовка.
 * @param {string} [root0.textContinueButton] - Текст кнопки продолжить.
 * @returns {JSX}
 */
export const ContinueDialog: FC<ConfirmDialogProps> = ({
  Body,
  closeDialog,
  isLoading,
  isOpen,
  send,
  textContinueButton,
  textHeader,
}) => (
  <Dialog
    className={'dialog__title_underline'}
    maxWidth="sm"
    textHeader={textHeader}
    isOpen={isOpen}
    Body={Body}
    ButtonsActions={
      <>
        <Button disabled={isLoading} variant={'outlined'} onClick={closeDialog}>
          Отменить
        </Button>
        <Button
          color={'info'}
          loading={isLoading}
          variant={'contained'}
          type="submit"
          onClick={send}
        >
          {textContinueButton || ' Продолжить'}
        </Button>
      </>
    }
  />
);
