export const columnsElementOwnershipTable = [
  {
    Header: 'ID',
    accessor: 'short_root_id',
  },
  {
    Header: 'Группа элементов',
    accessor: 'ogh_object_type',
  },
  {
    Header: 'Назначение',
    accessor: 'ogh_object_name',
  },
];
