import './registryToolbar.scss';

import { BodyContext } from 'app/pages/providers';
import {
  ButtonMUI,
  ExpandLessIcon,
  ExpandMoreIcon,
} from 'core/uiKit/material-ui';
import React, { useContext } from 'react';

/**
 * Компонент RegistryToolbar.
 *
 * @param {object} props - Пропсы.
 * @param {React.ReactNode} [props.children] - Дети.
 * @param {string} props.title - Заголовок.
 * @param {boolean} [props.showToggle] - Флаг.
 * @returns {JSX.Element}
 */
export const RegistryToolbar = ({
  children,
  title = '',
  showToggle = false,
}) => {
  const { isSubobjectPanelShow = true, toggleSubobjectPanelShow } =
    useContext(BodyContext);
  return (
    <div className={'registry-table-toolbar'}>
      <div>
        <h1>{title}</h1>

        {showToggle && (
          <ButtonMUI
            color="primary"
            endIcon={
              isSubobjectPanelShow ? <ExpandLessIcon /> : <ExpandMoreIcon />
            }
            onClick={toggleSubobjectPanelShow}
          >
            {isSubobjectPanelShow ? 'Скрыть' : 'Показать'} подобъекты
          </ButtonMUI>
        )}
      </div>
      <div className="btn-group registry-table-toolbar__btn-group">
        {children}
      </div>
    </div>
  );
};
