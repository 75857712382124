import { get } from 'lodash';

 
/**
 * Fn getParentTypeId.
 *
 * @deprecated
 * @param {object} state - State.
 * @returns {undefined}
 * @example --------
 */
const getParentTypeId = (state) => {
  let result;
  const rootTypeId = get(state, 'card.root.type_id');
  const typeIdForEditChildren = get(state, 'card.object.parent_info.type_id');
  const parentTypeIdForCreateCard = get(
    state,
    'card.mode.options.parentTypeId',
  );

  //id реестра при создании нового объекта
  if (parentTypeIdForCreateCard && Number(parentTypeIdForCreateCard) !== -1) {
    result = Number(parentTypeIdForCreateCard);
  } else {
    // в другом случае, проверяем является ли объект дочкой или устанавливаем typeId создаваемого объекта
    result = typeIdForEditChildren || rootTypeId;
  }

  return result;
};
export default getParentTypeId;
