import { cellsMainMap } from 'core/uiKit/components/cells/cellsMainMap';

/**
 * @typedef {import('src/core/uiKit/components/tables/Table/Table').Columns} Columns
 */

/**
 * Получение Columns __Элементы организации рельефа__.
 *
 * @returns {Columns} - Жопа.
 * @example ---
 */
export const getColumnsRelief = () => [
  cellsMainMap.short_root_id, // ID
  cellsMainMap.object_type_name, // Тип
  cellsMainMap.object_status_name, // Статус
  cellsMainMap.sign_date, // Дата подписания
  cellsMainMap.sign_person, // ФИО подписанта
  cellsMainMap.start_date, // Дата начала
  cellsMainMap.end_date, // Дата окончания
  cellsMainMap.parent_object_name, // Наименование родительского объекта
  cellsMainMap.parent_short_root_id, // Идентификатор родительского объекта
  cellsMainMap.parent_okrug_name, // Округ родительского объекта
  cellsMainMap.parent_owner_name, // Балансодержатель родительского объекта
  cellsMainMap.parent_grbs_name, // ГРБС
  cellsMainMap.quantity_sqr, // Площадь, кв.м
  cellsMainMap.total_area_geo, // Площадь объекта по пространственным координатам, кв.м
  cellsMainMap.quantity_pcs, // Количество, шт.
  { ...cellsMainMap.quantity_rm, Header: 'Длина, п.м' }, // Длина, п.м
  cellsMainMap.material_name, // Материал
  cellsMainMap.zone_name, // Принадлежность элемента к зоне территории
  cellsMainMap.is_diff_height_mark, // Разновысотные отметки
  cellsMainMap.included_in_tl, // Входит в ТС
  cellsMainMap.coating_group_name,
  cellsMainMap.coating_type_name,
  cellsMainMap.coating_face_type_name,
  cellsMainMap.face_area,
];
