import { FACE_AREA_NAME } from 'core/uiKit/preparedInputs/numberField/FaceArea';
import React from 'react';
import { Field } from 'react-final-form';

import { FaceAreaWithFF } from './FaceArea.withFF';

/**
 * NumberField FF __Площадь покрытия (облицовка), кв м__.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 * @class
 */
export const FaceAreaFF = (props) => {
  return <Field {...props} name={FACE_AREA_NAME} component={FaceAreaWithFF} />;
};
