import { AutocompleteAsync } from 'core/uiKit/inputs/selects/AutocompleteAsync';
import React from 'react';
import { Field } from 'redux-form';

/**
 * AutocompleteAsyncFF.
 *
 * @param {*} props - The props.
 * @param {*} props.input - The name of the input field.
 * @param {*} props.otherProps - The other properties of the input field.
 * @returns {JSX.Element}
 */
const AutocompleteAsyncWithReduxForm = ({ input, ...otherProps }) => {
  return <AutocompleteAsync {...otherProps} {...input} />;
};

/**
 * AutocompleteAsyncFF.
 *
 * @param {*} props - The props.
 * @param {string} props.name - The name of the input field.
 * @param {*} props.otherProps - The other properties of the input field.
 * @returns {JSX.Element}
 */
export const AutocompleteAsyncRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={name}
      component={AutocompleteAsyncWithReduxForm}
    />
  );
};
