import { generateRandomWord } from './generateRandomWord';

/**
 * Случайное число похоже на id.
 *
 * @type {number}
 */
export const RANDOM_ID = Math.round(Math.random() * 10000000);

/**
 * Случайное слово.
 *
 * @type {string}
 */
export const RANDOM_WORD = generateRandomWord();

/**
 * Случайное булево значение.
 *
 * @type {boolean}
 */
export const RANDOM_BOOL = Math.round(Math.random() * 10) % 2 === 0;

/**
 * Случайное число от 1-101, но всегда > 0.
 *
 * @type {number}
 */
export const RANDOM_NUMBER = Math.round(Math.random() * 100) + 1;
