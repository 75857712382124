import getDict from 'app/selectors/getDict';
/* eslint-disable  */
import { filter, get, map } from 'lodash';
import { createSelector } from 'reselect';

const getPrefChildren = getDict('prefChildren');

/**
 *
 * @param state
 */
const getOrgTypeId = (state) =>
  get(state, 'form.exportPgmShipments.values.oivId');

export default createSelector(
  [getOrgTypeId, getPrefChildren],
  (orgTypeId, prefChildren) => {
    if (orgTypeId && prefChildren) {
      const [id] = orgTypeId;
      const filteredPrefChildren = filter(prefChildren, (pref) =>
        pref.pref_list.includes(id),
      );

      return map(filteredPrefChildren, (pref) => ({
        ...pref,
        id: parseInt(pref.id, 10),
      }));
    }
  },
);
