 
export const FUEL_TANK = '1';
 
export const TANK = '9';
 
export const BUNKER = '10';
 
export const CARCASE = '11';
 
export const WATER_TANK = '12';
