import intersectionGeometryAttributesService from 'app/services/intersectionGeometryAttributesService';

import { fetchCheckGeometryIntersectionsSimple } from './api/fetchCheckGeometryIntersectionsSimple';
import { getIntersectionsGeometries } from './api/getIntersectionsGeometries';
import { getLengthGeometry } from './helpers/getLengthGeometry';
import messages from './messages';

/**
 * Функция onCheckGeometryButtonPress.
 *
 * @param {object} params - Параметры.
 * @returns {Promise<void>}
 */
export async function onCheckGeometryButtonPress(params) {
  const { hasIntersections, resetIntersectionsGeometry } = params;

  if (hasIntersections) {
    resetIntersectionsGeometry();
  } else {
    await getIntersectionsGeometries(params);
  }
}

/**
 * Функция onSave.
 *
 * @param {*} data - Данные.
 * @param {object} props - Пропсы.
 * @param {Function} draw - Отрисовка.
 * @param {Function} showIgnoreIntersectionAlert - Игнорировать алерт.
 * @param {Function} saveObject - Сохранить объект.
 * @returns {void}
 */
export async function onSave(
  data,
  props,
  draw,
  showIgnoreIntersectionAlert,
  saveObject,
) {
  if (props.fetchCheckGeomContains) {
    const object = await props.fetchCheckGeomContains(data);
    const length = getLengthGeometry(object);
    if (10 < length) {
      props.showAlert(messages.errorMore);
    } else if (length) {
      props.showAlert(messages.errorOther);
      draw(object.geometry);
    } else {
      fetchCheckGeometryIntersectionsSimple(
        data,
        props,
        draw,
        showIgnoreIntersectionAlert,
        saveObject,
      );
    }
  } else {
    fetchCheckGeometryIntersectionsSimple(
      data,
      props,
      draw,
      showIgnoreIntersectionAlert,
      saveObject,
    );
  }
}

/**
 * Функция getCheckGeometryIntersectionAttributes.
 *
 * @param {number} typeId - Тип ОГХ.
 * @param {object} currentFormValues - Текущие значения.
 * @param {*} attribute - Атрибуты.
 * @returns {*}
 */
export function getCheckGeometryIntersectionAttributes(
  typeId,
  currentFormValues,
  attribute,
) {
  return intersectionGeometryAttributesService.getAttributes(
    typeId,
    currentFormValues,
    attribute,
  );
}
