import { RANDOM_NUMBER } from 'core/forTesting/constants';
import { shallow } from 'enzyme';

import { ScheduleStatusIdLinkCell } from './ScheduleStatusIdLink.Cell';

describe('ScheduleStatusIdLinkCellJestSpec', function () {
  it('test render', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const wrapper = shallow(<ScheduleStatusIdLinkCell value={RANDOM_NUMBER} />);
    // Assert
    expect(wrapper.props()).toStrictEqual({
      children: RANDOM_NUMBER,
      to: `/certification-of-ogh/certificate-passport/${RANDOM_NUMBER}`,
    });
    expect(wrapper.text()).toBe(`${RANDOM_NUMBER}`);
  });
  it('value undefined', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const wrapper = shallow(<ScheduleStatusIdLinkCell />);
    // Assert
    expect(wrapper.text()).toBe('');
  });
});
