import {
  TYPE_ID_TECHNIC_NAME,
  TypeIdTechnicFF,
} from 'core/form/finalForm/fields';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';
import { NumberParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

/**
 * Статус.
 *
 * @param {*} props - Properties.
 * @returns {JSX.Element}
 */
export const TypeIdTechnicFilter = (props) => (
  <TypeIdTechnicFF {...props} className={filterInput} />
);

export { TYPE_ID_TECHNIC_NAME };

/**
 * Создание параметров фильтрации.
 *
 * @returns {object}
 */
export const typeIdTechnicPreparation = () => {
  addPreparation(TYPE_ID_TECHNIC_NAME, (value) => value, 'request_type_id');
  return {
    [TYPE_ID_TECHNIC_NAME]: NumberParam,
  };
};

export const typeIdTechnicParams = {
  [TYPE_ID_TECHNIC_NAME]: NumberParam,
};
