import * as PropTypes from 'prop-types';

/**
 * Cell Является частью составного объекта.
 *
 * @param {{row:{original:{group_id:(number|string), parent_name:string }}}} props - Props.
 * @returns {JSX.Element} - JSX.
 * @example ----
 */
export const TypeIdCell = ({
  row: {
    original: { group_id, parent_name },
  },
}) => <span>{[group_id, parent_name].filter(Boolean).join(', ')}</span>;

TypeIdCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      group_id: PropTypes.number,
      parent_name: PropTypes.string,
    }).isRequired,
  }).isRequired,
};
