import { INVALID_EXPORT_DATE_ALERT } from 'app/constants/messages';
/* eslint-disable */
import { isEmpty, max } from 'lodash';

import { formatDate, getNextDay, parseDate } from './dateTime';

/**
 *
 * @param dateFromStr
 * @param data
 */
export const validateDateFrom = (dateFromStr, data) => {
  if (data && !isEmpty(data)) {
    const dateFrom = parseDate(dateFromStr);
    const maxDateTo = max(data.map((item) => parseDate(item.doc_end_date)));
    if (maxDateTo) {
      if (dateFrom <= maxDateTo) {
        return INVALID_EXPORT_DATE_ALERT + formatDate(getNextDay(maxDateTo));
      }
    }
  }

  return null;
};
