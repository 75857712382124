import { Button } from 'core/uiKit/components/buttons/Button';
import { CircularProgress, Icon } from 'core/uiKit/material-ui';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import { log } from 'core/utils/log';
import React, { useEffect } from 'react';

/**
 * Кнопка печати.
 *
 * @param {object} props - Пропсы.
 * @param {boolean} [props.disabled] - Флаг активности кнопки.
 * @param {boolean} [props.isLoading] - Флаг загрузки (у функции есть свой флаг когда происходит запрос функции).
 * @param {Function} [props.onClick] - Функция нажатия.
 * @param {string} [props.text] - Текст кнопки.
 * @param {object} props.prepareFilters - Подготовленные данные для бэка.
 * @param {Function} props.printFn - Функция (fetch) запроса печати.
 * @returns {JSX.Element} - JSX.
 * @example -----
 */
export const PrintButton = ({
  disabled = false,
  isLoading: isLoadingPr = false,
  onClick: onClickFn,
  text = 'Печать',
  prepareFilters = {},
  printFn = (_) => _,
}) => {
  useEffect(() => {
    if (typeof onClickFn === 'function') {
      log.info('пересмотрите вызов PrintButton');
    }
  }, [onClickFn]);

  const [sendReport, { isLoading }] = useMutationAdaptor(printFn);

  const onClick =
    typeof onClickFn === 'function'
      ? onClickFn
      : () => sendReport(prepareFilters);

  return (
    <Button
      color={'primary'}
      disabled={disabled}
      loading={isLoading || isLoadingPr}
      startIcon={
        isLoading ? <CircularProgress size={20} /> : <Icon>print</Icon>
      }
      variant={'contained'}
      onClick={onClick}
    >
      {text || 'Печать'}
    </Button>
  );
};
