/* eslint-disable  */
import React from 'react';

/**
 *
 * @param root0
 * @param root0.children
 */
export const Main = ({ children }) => {
  return <>{children}</>;
};
