import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
/* eslint-disable */
import { useState } from 'react';

import { ButtonSigningOnRenewalOfApprovalDate } from './Button.SigningOnRenewalOfApprovalDate';
import { DialogSigningOnRenewalOfApprovalDate } from './Dialog.SigningOnRenewalOfApprovalDate';

/**
 *
 */
export const SigningOnRenewalOfApprovalDate = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { objectId } = useGetObjectId();

  return (
    <div>
      <ButtonSigningOnRenewalOfApprovalDate
        objectId={objectId}
        onClick={() => setIsOpen(true)}
      />
      {isOpen ? (
        <DialogSigningOnRenewalOfApprovalDate
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        />
      ) : null}
    </div>
  );
};
