import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import PropTypes from 'prop-types';
import React from 'react';

export const GRBS_SHORT_NAME = 'grbsShortName';

/**
 * GrbsShortName.
 *
 * @param {*} props - Properties.
 * @returns {JSX.Element}
 */
export const GrbsShortName = (props) => {
  const dict = 'grbs';

  const { data = [] } = useGetDictionary(dict);

  // так сделано по тому что grbsShortName на сервере ожидает имя
  const options = data.map(({ name }) => ({ id: name, name }));

  return (
    <Select {...props} {...{ options }} label={'ГРБС'} name={GRBS_SHORT_NAME} />
  );
};
GrbsShortName.propTypes = {
  dark: PropTypes.bool,
  value: PropTypes.any,
};
