import * as types from '../constants/actionTypes';

/* eslint-disable */
export default function admin(state = {}, action) {
  switch (action.type) {
    case types.RECEIVE_ROLE:
      return {
        ...state,
        role: action.object,
      };
    case types.RECEIVE_CREATE_ROLE: {
      return {
        ...state,
        error: action.object && action.object.error,
      };
    }
    case types.RECEIVE_UPDATE_ROLE: {
      return {
        ...state,
        error: action.object && action.object.error,
      };
    }
    case types.CLEAR_ADMIN_ERROR: {
      return {
        ...state,
        error: '',
        error_id: null,
      };
    }
    case types.RECEIVE_USER_REGISTRY:
      return {
        ...state,
        userRegistry: action.object,
      };
    case types.RECEIVE_USER:
      return {
        ...state,
        user: action.object,
      };
    case types.RECEIVE_UPDATE_USER:
    case types.RECEIVE_CREATE_USER:
      return {
        ...state,
        error:
          action.object &&
          action.object.status !== 200 &&
          action.object.message,
        error_ids:
          action.object && action.object.code !== 400 && action.object.errors,
      };
    case types.RECEIVE_CHANGE_PASSWORD:
      return {
        ...state,
        changePassResult: action.object,
      };
    case types.RECEIVE_MATCHING:
      return {
        ...state,
        matching: action.object,
      };

    case types.CHANGE_EDIT_MODE:
      return {
        ...state,
        matching: { ...state.matching, editMode: action.editMode },
      };

    case types.CLEAR_MATCHING:
      return {
        ...state,
        matching: { editMode: true },
      };

    case types.RECEIVE_TEMPLATE_REGISTRY:
      return {
        ...state,
        templateRegistry: action.object,
      };
    case types.RECEIVE_CREATE_TEMPLATE:
      return {
        ...state,
        error: action.object && action.object.error,
      };
    case types.RECEIVE_EDIT_TEMPLATE:
      return {
        ...state,
        error: action.object && action.object.error,
      };
    default:
      return state;
  }
}
