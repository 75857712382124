const disable = true;


/* eslint-disable */
export default {
  change_car_func_type: {
    car_customer_list: disable,
    car_ownership_id: disable,
    gov_number: disable,
    car_season_id: disable,
    vin: disable,
    factory_number: disable,
    car_owner_id: disable,
    car_condition_id: disable,
    not_need_glonass: disable,
    car_special_model_id: disable,
    car_base_id: disable,
    legal_car_registration_id: disable,
    car_motor_type_id: disable,
    car_ecology_class_id: disable,
    pts: disable,
    sts: disable,
    pts_date: disable,
    car_create_date: disable,
    weight_not_load: disable,
    horse_power: disable,
    electro_power: disable,
    leasing_end_date: disable,
    car_model_id: disable,
  },
  change_car_person: {
    vin: disable,
    factory_number: disable,
    car_func_type_group_id: disable,
    car_func_type_id: disable,
  },
  actual_car: {
    vin: disable,
    factory_number: disable,
    car_func_type_group_id: disable,
    car_func_type_id: disable,
  },
  add_car_equipment: {
    vin: disable,
    factory_number: disable,
    car_func_type_group_id: disable,
    car_func_type_id: disable,
  },
  remove_car_equipment: {
    vin: disable,
    factory_number: disable,
    car_func_type_group_id: disable,
    car_func_type_id: disable,
  },
  remove_car_season: {
    vin: disable,
    factory_number: disable,
    car_func_type_group_id: disable,
    car_func_type_id: disable,
  },
  modernization_car: {
    vin: disable,
    factory_number: disable,
    car_func_type_group_id: disable,
    car_func_type_id: disable,
  },
  send_for_storage: {
    vin: disable,
    factory_number: disable,
    car_func_type_group_id: disable,
    car_func_type_id: disable,
  },
};
