import { get } from 'lodash';

 
/**
 * Fn getTypeId.
 *
 * @deprecated
 * @param {object} state - State.
 * @param {object} props - Props.
 * @returns {undefined}
 * @example --------
 */
const getTypeId = (state, props) => get(props, 'card.type_id');
export default getTypeId;
