/* eslint-disable */
import React from 'react';
import { Skeleton } from 'core/uiKit/components/skeletons/Skeleton';

 
export const Total = ({ from, to, total }) => {
  if (total === 0) {
    return 'Результаты не найдены';
  }
  if (Number(total)) {
    return `Показаны с ${from} по ${to} из ${total}`;
  }

  return <Skeleton width={175} />;
};
