import './Accordion.scss';

import cn from 'classnames';
import React from 'react';

import { IconAccordion } from './IconAccordion';
import { toggle } from './toggle';

/**
 * Кастомный аккордеон для дерева элементов правой панели.
 *
 * @param {{}} props - Props.
 * @param {string} [props.title] - Заголовок.
 * @param {React.ReactNode} props.children - Дочерние элементы.
 * @param {JSX.Element} [props.HeaderComponent] - Заглавный комм.
 * @param {boolean} props.isActive - Флаг отвечающий за активность элемента (выделен особо).
 * @param {Function} [props.onClick] - Функция клика.
 * @param {boolean} props.accordionOpen - Флаг открытого аккордеона.
 * @param {boolean} [props.containsChild] - Флаг белой стрелочки, что есть дети.
 * @param {Function} props.setAccordionOpen - Функция переключения отк/зак аккордеона.
 * @returns {JSX.Element}
 */
export const Accordion = ({
  title,
  children,
  HeaderComponent,
  isActive,
  onClick = () => {},
  accordionOpen,
  setAccordionOpen = () => {},
  containsChild,
}) => {
  const classHeader = cn('accordion-header-card', {
    'active-accordion-header-card': isActive,
  });

  const classAccordionBody = cn('accordion-body-card__list', {
    'open-accordion': !accordionOpen,
  });

  /**
   * Функция Обработчик для открытия скрытия аккордеона.
   *
   * @returns {*}
   */
  const toggleAccordion = () => setAccordionOpen(toggle);

  return (
    <ul className={'accordion-card__container'}>
      <li
        className={classHeader}
        onClick={() => {
          onClick();
        }}
      >
        <IconAccordion
          containsChild={containsChild}
          isActive={children ? accordionOpen : false}
          onClick={toggleAccordion}
        />
        {HeaderComponent ? HeaderComponent : <p>{title}</p>}
      </li>
      {children ? <ul className={classAccordionBody}>{children}</ul> : null}
    </ul>
  );
};

/**
 * Кастомный аккордеон для листа дерева элементов правой панели.
 *
 * @param {*} props - Props.
 * @param {Function} props.children - Дочерние элементы.
 * @param {boolean} props.isActive - Флаг отвечающий за активность элемента (выделен особо).
 * @returns {*}
 */
export const AccordionContent = ({ children, isActive }) => {
  const classItem = cn('accordion-body-card__item', {
    'active-accordion-item': isActive,
  });

  return <li className={classItem}>{children}</li>;
};
