import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { VerticalLandscapingClassTypeIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/VerticalLandscapingClassTypeId.FF';
import { VERTICAL_LANDSCAPING_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/VerticalLandscapingTypeId';
import React from 'react';
import { useField } from 'react-final-form';

import { usePreparationVerticalLandscapingClassTypeId } from './usePreparationVerticalLandscapingClassTypeId';

/**
 * Select Детализация.
 *
 * @returns {JSX.Element}
 */
export const VerticalLandscapingClassTypeIdField = () => {
  const { editMode } = useEditMode();
  usePreparationVerticalLandscapingClassTypeId();

  const { input: { value: verticalLandscapingTypeId } = {} } = useField(
    VERTICAL_LANDSCAPING_TYPE_ID_NAME,
  );

  /**
   * Функция фильтрации полей справочника.
   *
   * @param {Array} dict - Справочные значения.
   * @returns {Array}
   */
  const filter = (dict) => {
    return dict.filter(({ vertical_landscaping_type_list }) =>
      vertical_landscaping_type_list.includes(verticalLandscapingTypeId),
    );
  };

  return (
    <VerticalLandscapingClassTypeIdFF
      filter={filter}
      disabled={!(editMode && verticalLandscapingTypeId)}
      withAll={false}
    />
  );
};
