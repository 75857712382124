import {
  Comment,
  COMMENT_NAME,
} from 'core/uiKit/preparedInputs/textField/Comment';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * Текстовое поле комментарий.
 *
 * @param {*} props - Properties.
 * @returns {JSX.Element}
 */
export const CommentFF = (props) => {
  return (
    <Field name={props.name || COMMENT_NAME}>
      {({ input: { value, onChange }, meta }) => {
        return (
          <Comment
            {...props}
            {...{ name: props.name || COMMENT_NAME, onChange, value }}
            {...{ meta }}
          />
        );
      }}
    </Field>
  );
};

export { COMMENT_NAME };
