/**
 * Компонент табы, вилка скрыть показать (при этом объект будет перирендериваться).
 *
 * @param {object} props - Пропсы.
 * @param {JSX.Element} props.children - Реакт компонент.
 * @param {boolean} props.show - Флаг скрывать или показывать.
 * @returns {JSX.Element} - Возвращает выбраную табу.
 */
export const TabsCardOGHPanel = ({ children, show }) => {
  return show ? children : null;
};
