import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { CancelButton } from 'core/uiKit/components/buttons/actionsButtons/Cancel.Button';
import React from 'react';
import { useForm } from 'react-final-form';

/**
 * Кнопка отмены для Формы.
 *
 * @param props - Параметры.
 * @param props.isLoading - Флаг Загрузки.
 * @returns Кнопка отмены для Формы.
 */
export const CancelButtonFF = ({ isLoading }: { isLoading?: boolean }) => {
  const { editMode, clearEditMode } = useMode();
  const form = useForm();

  return editMode ? (
    <CancelButton
      onClick={() => {
        clearEditMode();
        form.reset();
      }}
      isLoading={isLoading}
    />
  ) : null;
};
