import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const WATER_TYPE_ID_NAME = 'waterTypeId';

/**
 * ## Тип объекта.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const WaterTypeId = (props) => {
  const dict = 'waterType';
  const { data = [] } = useGetDictionary(dict);

  return <Select {...props} options={data} label={'Тип объекта'} />;
};
