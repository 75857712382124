/* eslint-disable */
import React from 'react';
import { CheckboxFF } from 'core/form/finalForm/fields/default';
 
export const IS_DIFF_HEIGHT_MARK_NAME = 'is_diff_height_mark';

 
 
/**
 * ## Разновысотные отметки
 *
 * ### Final Form Field
 * ```js
 * import { IsDiffHeightMarkFF, IS_DIFF_HEIGHT_MARK_NAME } from 'core/form//fields';
 * ```
 *
 * * `type = CheckboxFF`
 * * `name = is_diff_height_mark`
 */

 
export const IsDiffHeightMarkFf = (props) => {
  return (
    <CheckboxFF
      {...props}
      name={IS_DIFF_HEIGHT_MARK_NAME}
      label={props.label || 'Разновысотные отметки'}
    />
  );
};
