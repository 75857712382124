 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */

import { YesOrNoCell } from 'core/uiKit/components/cells/YesOrNo.Cell';
 
/* eslint-disable */
/**
 * Колонка __Акция «Миллион деревьев»__.
 *
 * @type {{
 * Cell: YesOrNoCell,
 * accessor: 'million_trees',
 * Header: 'Акция «Миллион деревьев»',
 * sortable: true,
 * }}
 * @augments Cell
 */
export const million_trees = {
  Cell: YesOrNoCell,
  accessor: 'million_trees',
  Header: 'Акция «Миллион деревьев»',
  sortable: true,
};
