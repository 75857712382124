import './RightPanelCard.scss';

import { cardToggleStyles } from 'app/components/card/ogh/index/card/styles';
import MapEditPanelWrapper from 'app/pages/cardsOgh/components/Map/MapEditPanel/MapEditPanelWrapper';
import { ArrowHeader } from 'app/pages/cardsOgh/components/RightPanelCard/components/icons/ArrowHeader';
import { TreeRightPanel } from 'app/pages/cardsOgh/components/RightPanelCard/components/TreeRightPanel/TreeRightPanel';
import cn from 'classnames';
import IconButton from 'core/newComponents/IconButton';
import React, { useState } from 'react';

/**
 * Правая панель.
 *
 * @param {object} props - Пропсы.
 * @param {Function} props.toggleLeftPanel - Функция показа/скрытия левой панели.
 * @returns {JSX.Element}
 */
export const RightPanelCard = ({ toggleLeftPanel }) => {
  const [activeTree, setActiveTree] = useState(true);

  /**
   * Функция показа/скрытия правой панели.
   *
   * @returns {void}
   */
  const toggleTree = () => {
    setActiveTree((pre) => !pre);
  };

  const classTreeBody = cn('right-panel-card-container__body', {
    'right-panel-card-container__body__hide': !activeTree,
  });

  const classCardContainer = cn('right-panel-card-container', {
    'right-panel-card-container__hide': !activeTree,
  });

  return (
    <div className={classCardContainer}>
      <div className={'right-panel-card-container__header '}>
        <div className={'right-panel-card-container-header__top'}>
          <h4>Список объектов</h4>
          <IconButton
            onClick={toggleLeftPanel}
            className="right-panel__card-toggle card-toggle fa fa-angle-double-right boxArea"
            style={cardToggleStyles}
          />
          <ArrowHeader active={activeTree} onClick={toggleTree} />
        </div>
        <MapEditPanelWrapper />
      </div>
      <div id={'object-tree'} className={classTreeBody}>
        <TreeRightPanel />
      </div>
    </div>
  );
};
