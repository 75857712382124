// 11 Огражденияimport CardAbstract from 'app/components/card/common/CardAbstract';
import CardAbstract from 'app/components/card/common/CardAbstract';
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { objectTabs } from 'app/components/card/common/tabs';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import { IsDiffHeightMarkField } from 'app/components/card/ogh/components/fields/checkBoxFields/IsDiffHeightMark.Field';
import { BordBeginField } from 'app/components/card/ogh/components/fields/numberFields/BordBegin.Field';
import { BordEndField } from 'app/components/card/ogh/components/fields/numberFields/BordEnd.Field';
import { OdhSideIdField } from 'app/components/card/ogh/components/fields/selectFields/OdhSideId.Field';
import { DescriptionField } from 'app/components/card/ogh/components/fields/textFields/Description.Field';
import { OdhAxisField } from 'app/components/card/ogh/components/fields/textFields/OdhAxis.Field';
import { PlacementIdRFWrapper } from 'app/components/card/ogh/components/selects/PlacementIdRFWrapper';
import {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import { FENCING } from 'app/constants/oghTypes';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import { isThisViewing } from 'app/selectors/card/isThisViewing';
import getFormValues from 'app/selectors/form/getFormValues';
import filterDictBySprNumber from 'app/utils/card/filterDictBySprNumber';
import isMandatory, {
  mandatoryFields as staticMandatoryFields,
} from 'app/utils/card/isMandatory';
import { transformValues } from 'app/utils/form/transformation';
import { CheckboxRF, NumberFieldRF } from 'core/form/reduxForm/fields';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import { IS_DIFF_HEIGHT_MARK_NAME } from 'core/form/reduxForm/fields/prepared/checkboxes/IsDiffHeightMark.RF';
import { OtherDocumentsRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/OtherDocuments.RF';
import { QuantityPcs } from 'core/form/reduxForm/fields/prepared/textField/QuantityPcs.RF';
import { CardContainer } from 'core/uiKit/components/card.components/CardContainer';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { GroupContainer } from 'core/uiKit/components/card.components/GroupContainer';
import { TabsCardOGH } from 'core/uiKit/components/card.components/TabsCardOGH';
import { TabsCardOGHItem } from 'core/uiKit/components/card.components/TabsCardOGH/TabsCardOGH.Item';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import { find, pull, uniq } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';

import printOghCardHeader from './header/oghHeader';
import submitCard from './submit';
import VersionsTemplate from './versions/VersionsTemplate';

const types = ['fencing'];
const Versions = VersionsTemplate(types);

const propTypes = {
  card: PropTypes.object,
  createMode: PropTypes.bool,
  documents: PropTypes.array,
  editMode: PropTypes.bool,
  equipment_kind_id: PropTypes.number,
  mode: PropTypes.object,
  required: PropTypes.bool,
};

/**
 * Компонент паспорта __Ограждения__.
 *
 * @param {string} fieldName - Имя поля.
 * @returns {JSX}
 */
class FencingCard extends React.Component {

  /**
   * Метод жизненного цикла componentDidUpdate.
   *
   * @param {object} prevProps - Предыдущие пропсы.
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    const { editMode, equipment_kind_id } = this.props;
    if (
      equipment_kind_id &&
      prevProps.equipment_kind_id !== equipment_kind_id
    ) {
      const { changeFieldValue } = this.props;

      this.changeMandatoryFields('quantity_rm');
      this.changeMandatoryFields('quantity_pcs');
      this.changeMandatoryFields('quantity_sqr');

      if (editMode && this.isLockedField('gutters_length')) {
        changeFieldValue('gutters_length', null);
      }
    }
  }

  /**
   * Метод getEquipmentKindOptions.
   *
   * @returns {*}
   */
  getEquipmentKindOptions() {
    const { type_id, equipment_kind, viewing } = this.props.card;

    if (viewing) {
      return equipment_kind;
    }

    return filterDictBySprNumber(equipment_kind, type_id, 'equipment_kind');
  }

  /**
   * Метод addMandatoryField.
   *
   * @param {string} fieldName - Имя поля.
   * @returns {void}
   */
  addMandatoryField = (fieldName) => {
    mandatoryFields.push(fieldName);
    mandatoryFields = uniq(mandatoryFields);
  };

  /**
   * Метод removeMandatoryField.
   *
   * @param {string} fieldName - Имя поля.
   * @returns {void}
   */
  removeMandatoryField = (fieldName) => {
    const { editMode, changeFieldValue } = this.props;
    if (editMode) {
      changeFieldValue(fieldName, null);
    }
    pull(mandatoryFields, fieldName);
  };

  /**
   * Метод changeMandatoryFields.
   *
   * @param {string} fieldName - Имя поля.
   * @returns {void}
   */
  changeMandatoryFields(fieldName) {
    if (!this.isLockedField(fieldName)) {
      this.addMandatoryField(fieldName);
    } else {
      this.removeMandatoryField(fieldName);
    }
  }

  /**
   * Метод isRequiredField.
   *
   * @param {string} fieldName - Имя поля.
   * @returns {boolean}
   */
  isRequiredField = (fieldName) => {
    const { required } = this.props;
    return required && mandatoryFields.includes(fieldName);
  };

  /**
   * Метод isRequiredField.
   *
   * @param {string} fieldName - Имя поля.
   * @returns {boolean}
   */
  isLockedField = (fieldName) => {
    const { equipment_kind_id, card } = this.props;
    const { equipment_kind } = card;

    const currentEquipmentKind = find(equipment_kind, {
      id: equipment_kind_id,
    });

    /**
     * Функция isFound.
     *
     * @param {*} equipmentKind - EquipmentKind.
     * @param {*} unitType - Тип.
     * @returns {*}
     */
    const isFound = (equipmentKind, unitType) => {
      return (
        equipmentKind &&
        equipmentKind.unit_type &&
        equipmentKind.unit_type.includes(unitType)
      );
    };

    switch (fieldName) {
      case 'quantity_rm': {
        return !isFound(currentEquipmentKind, 'len');
      }
      case 'quantity_pcs': {
        return !isFound(currentEquipmentKind, 'qty');
      }
      case 'quantity_sqr': {
        return !isFound(currentEquipmentKind, 'area');
      }
      case 'gutters_length': {
        return !isFound(currentEquipmentKind, 'area');
      }
      default: {
        return false;
      }
    }
  };

  /**
   * Метод жизненного render.
   *
   * @returns {JSX}
   */
  render() {
    const {
      mode,
      card,
      createMode,
      editMode,
      required,
      isEditCurrentObjectIsExternalSystem,
    } = this.props;

    return (
      <CardContainer>
        <CardAbstract>
          <div id="editor-card">
            {printOghCardHeader(card, mode)}
            {card && objectTabs(card.type_id)}

            <div id="props">
              <FieldsSplitColumns>
                <SelectRF
                  name={'equipment_kind_id'}
                  label={'Тип'}
                  options={this.getEquipmentKindOptions()}
                  disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
                  required={isMandatory(
                    card.type_id,
                    'equipment_kind_id',
                    required,
                  )}
                />
                <ParentInfo />
                <IsDiffHeightMarkField />
              </FieldsSplitColumns>

              <TabsCardOGH>
                <TabsCardOGHItem tabTitle={'Исходные данные'}>
                  <GroupContainer title={'Местоположение'}>
                    <FieldsSplitColumns>
                      <PlacementIdRFWrapper disabled={true} withAll={false} />
                      <OdhAxisField />
                      <OdhSideIdField />
                      <BordBeginField />
                      <BordEndField />
                      <CheckboxRF
                        name={'is_title'}
                        label={'Включать в ТС'}
                        disabled={
                          !(editMode && isEditCurrentObjectIsExternalSystem)
                        }
                      />
                    </FieldsSplitColumns>
                  </GroupContainer>
                  <FieldsSplitColumns>
                    <NumberFieldRF
                      name={'quantity_rm'}
                      label={'Протяженность, п.м'}
                      disabled={
                        !(
                          editMode &&
                          !this.isLockedField('quantity_rm') &&
                          isEditCurrentObjectIsExternalSystem
                        )
                      }
                      decimalScale={2}
                    />
                    <NumberFieldRF
                      name={'quantity_sqr'}
                      label={'Площадь, кв.м'}
                      disabled={
                        !(
                          editMode &&
                          !this.isLockedField('quantity_sqr') &&
                          isEditCurrentObjectIsExternalSystem
                        )
                      }
                      decimalScale={2}
                    />

                    <QuantityPcs
                      required={true}
                      disabled={
                        !(editMode && !this.isLockedField('quantity_pcs')) &&
                        isEditCurrentObjectIsExternalSystem
                      }
                    />
                    <NumberFieldRF
                      name={'gutters_length'}
                      label={'Двухметровая прилотковая зона, п.м.'}
                      disabled={
                        !(
                          editMode &&
                          !this.isLockedField('gutters_length') &&
                          isEditCurrentObjectIsExternalSystem
                        )
                      }
                      decimalScale={2}
                    />
                  </FieldsSplitColumns>
                  <DescriptionField />
                </TabsCardOGHItem>
                <TabsCardOGHItem tabTitle={'Документы'}>
                  <OtherDocumentsRF />
                </TabsCardOGHItem>
              </TabsCardOGH>
            </div>
            <div hidden={true} id="versions">
              <Versions
                card={card}
                disabled={createMode}
                key={`versions-${card.root_id}`}
              />
            </div>
          </div>
        </CardAbstract>
      </CardContainer>
    );
  }
}

FencingCard.propTypes = propTypes;

const dictFields = ['odh_side_id', 'placement_id', 'equipment_kind_id'];

const numericFields = [
  { name: 'bord_begin', type: 'decimal', zero: true },
  { name: 'bord_end', type: 'decimal', zero: true },
  { name: 'gutters_length', positive: true, type: 'decimal' },
  { name: 'quantity_rm', positive: true, type: 'decimal', zero: false },
  { name: 'quantity_sqr', positive: true, type: 'decimal', zero: true },
  { name: 'quantity_pcs', positive: true, type: 'integer', zero: true },
];

let mandatoryFields = [...staticMandatoryFields[FENCING]];

/**
 * Валидация.
 *
 * @param {object} values - Значения формы.
 * @returns {*}
 */
const validate = (values) => validateNumeric(values, numericFields);

const fencingCardDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

/**
 * Функция создания аттрибутов.
 *
 * @param {object} formValues - Значения формы.
 * @returns {object}
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

/**
 * Функция сохранения изменений формы.
 *
 * @param {object} formValues - Значения формы.
 * @returns {void}
 */
function onSubmit(formValues) {
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };

  // Закоментировано в рамках задачи ODS-8965
  // if (
  //   formValuesWithAttribute.quantity_pcs === 0 ||
  //   formValuesWithAttribute.quantity_pcs === null
  // ) {
  //   formValuesWithAttribute.quantity_pcs = 1;
  // }
  formValuesWithAttribute.is_title
    ? (formValuesWithAttribute.is_title = 1)
    : (formValuesWithAttribute.is_title = 0);

  validateRequiredFieldsAsync(formValuesWithAttribute, mandatoryFields);
  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, { dictFields, numericFields }),
  );
}

/**
 * Функция получения состояния из redux.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {*}
 */
export const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);
  const formValues = getFormValues(state);

  const result = {
    createMode: isCreateMode(state, props),
    editMode: isEditMode(state, props),
    enableReinitialize: true,
    equipment_kind_id: formValues && formValues.equipment_kind_id,
    formValues: getFormValues(state),
    initialValues: {
      [IS_DIFF_HEIGHT_MARK_NAME]: parse(IS_DIFF_HEIGHT_MARK_NAME),
      bord_begin: parse('bord_begin'),
      bord_end: parse('bord_end'),
      customer_id: parse('customer_id'),
      description: parse('description'),
      endDate: parseDate(props.card.end_date),
      equipment_kind_id: parse('equipment_kind_id.equipment_kind'),
      gutters_length: parse('gutters_length'),
      is_title: parse('is_title'),
      odh_axis: parse('odh_axis'),
      odh_side_id: parse('odh_side_id.odh_side'),
      owner_id: parse('owner_id'),
      parent_name: props.card.parent_info,
      placement_id: parse('placement_id.placement'),
      quantity_pcs: parse('quantity_pcs'),
      quantity_rm: parse('quantity_rm'),
      quantity_sqr: parse('quantity_sqr') || 0,
      startDate: parseDate(props.card.start_date),
    },
    onSubmit: onSubmit.bind({ props }),
    required: isRequired(state, props),
    validate,
    viewing: isThisViewing(state),
  };

  return fencingCardDocumentsReducer(result, state, props);
};

/**
 * Функция экшенов.
 *
 * @param {Function} dispatch - Диспатч.
 * @returns {void}
 */
const mapDispatchToProps = (dispatch) => ({

  /**
   * Функция изменения поля.
   *
   * @param {string} field - Имя поля.
   * @param {*} value - Значение.
   */
  changeFieldValue: (field, value) => {
    dispatch(change('editorCard', field, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'editorCard' })(FencingCard));
