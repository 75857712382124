vi.mock('app/api/hooks/useGetCurrentCard');
vi.mock('core/utils/hooks/useGetObjectId');
vi.mock('app/actions/odsObjectActions/useFormValuesCardRF');

import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { shallow } from 'enzyme';
import React from 'react';
import { vi } from 'vitest';

import { DataExportButton } from './DataExport.Button';

vi.mock('app/api/hooks/useGetCurrentCard');

vi.mock('react', async (importOriginal) => {
  const mod = await importOriginal();

  return {
    ...mod,
    useState: vi.fn(() => [true, vi.fn()]),
  };
});

/**
 * Mock Default.
 *
 * @returns {void}
 */
const mockDefault = () => {
  useGetCurrentCard.mockReturnValue({});
};

describe('🐛 spec DataExport.Button', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(<DataExportButton />);

    //❓ Assert
    expect(wrapper.text()).toBe(
      '<Button /><UploadToXLSXMenuItem /><UploadToPDFMenuItem />',
    );
  });
});
