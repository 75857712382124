import { LinkShortIdCell } from 'core/uiKit/components/cells/LinkShortId.Cell';


/* eslint-disable */
export default [
  {
    id: 'root_id',
    name: 'ID',
    Cell: LinkShortIdCell,
    width: 80,
  },
  {
    id: 'buildings_type',
    name: 'Назначение',
  },
  {
    id: 'buildings_type_spec',
    name: 'Уточнение назначения',
  },
  {
    id: 'address_name',
    name: 'Адрес',
  },
  {
    id: 'area',
    name: 'Площадь, кв.м.',
  },
  {
    id: 'property',
    name: 'Характеристика',
  },
  {
    id: 'object_status_name',
    name: 'Статус',
  },
  {
    id: 'start_date',
    name: 'Дата начала действия',
  },
  {
    id: 'end_date',
    name: 'Дата окончания действия',
  },
];
