import { TextField } from 'core/uiKit/inputs';
import { log } from 'core/utils/log';
import PropTypes from 'prop-types';

import getDisplayName from './getDisplayName';
/* eslint-disable */
const propTypes = {
  alwaysShowMask: PropTypes.bool,
  disabled: PropTypes.bool,
  mask: PropTypes.string,
  maskChar: PropTypes.string,
};

const defaultProps = {
  alwaysShowMask: true,
  maskChar: '',
};

function withInputMask(Component) {
  function WithInputMask(props) {
    log.dev(
      `WithInputMask(${getDisplayName(Component)})` +
        ' можно заменить на просто TextField',
    );
    return <TextField {...props} />;
  }

  WithInputMask.displayName = `WithInputMask(${getDisplayName(Component)})`;
  WithInputMask.propTypes = propTypes;
  WithInputMask.defaultProps = defaultProps;

  return WithInputMask;
}

 
export default withInputMask;
