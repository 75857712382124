/* eslint-disable */
import React from 'react';

import { filterInput } from '../filterInputsClasses';
import { PLANT_TYPE_NAME, PlantTypeFF } from 'core/form/finalForm/fields';
import { NumberParam } from 'use-query-params';

 
export { PLANT_TYPE_NAME };
 
 
/**
 *     Вид растения
 *     plantTypeId
 */
 
export const PlantTypeFilter = (props) => (
  <PlantTypeFF {...props} className={filterInput} />
);
 
export const plantTypeParams = {
  [PLANT_TYPE_NAME]: NumberParam,
};
