import { VerticalLandscapingRefTypeId } from 'core/uiKit/preparedInputs/selects/simpleSelect/VerticalLandscapingRefTypeId/index';
import React from 'react';

/**
 * Компонент обертка для поля VerticalLandscapingRefTypeId.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.input - Данные поля приходит из final form.
 * @param {object} props.otherProps - Остальные пропсы.
 * @returns {JSX.Element} - JSX.
 * @example
 */
export const VerticalLandscapingRefTypeIdWithFf = ({
  input,
  ...otherProps
}) => {
  return <VerticalLandscapingRefTypeId {...input} {...otherProps} />;
};
