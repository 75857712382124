import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import { useState } from 'react';

import { ApproveElectronicSignatureButton } from './ApproveElectronicSignature.Button';
import { DialogApproveElectronicSignature } from './Dialog.ApproveElectronicSignature';

/**
 * Компонент Утвердить (ЭП).
 *
 * @returns {JSX}
 */
export const ApproveElectronicSignature = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { objectId } = useGetObjectId();

  return (
    <>
      <ApproveElectronicSignatureButton
        objectId={objectId}
        onClick={() => setIsOpen(true)}
      />
      {isOpen ? (
        <DialogApproveElectronicSignature onClose={() => setIsOpen(false)} />
      ) : null}
    </>
  );
};
