import { turnIntoCamelCase } from 'app/utils/turnIntoCamelCase';
import { useSelector } from 'react-redux';

 
/* eslint-disable */
/**
 * Selector получения tree object из state
 * @function
 * @category useSelector
 * @returns {{}}
 */
 
export const useGetTreeSelector = () => {
  const objectValue = useSelector((state) => state?.tree?.object);
  const objectValueCamel = turnIntoCamelCase(objectValue);
  return {
    ...objectValue,
    ...objectValueCamel,
  };
};
