import { get } from 'app/api/crud/get';

/**
 * Автокомплит запрос на Улицу.
 *
 * @param data - Данные.
 * @param data.maxRows - Maximum number of rows.
 * @param data.startsWith - Start with.
 * @param data.district_id - Район. Дополнительный параметр для запроса.
 * @returns Словарь.
 */
export const fetchStreetDetails = (data: {
  district_id: number;
  maxRows: number;
  startsWith: string;
}): Promise<{ id: number; name: string }[]> =>
  get<{ id: number; street_name: string }[]>(
    '/autocomplete/street_details',
    data,
  ).then((res) => res.map((item) => ({ id: item.id, name: item.street_name })));
