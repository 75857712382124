// 34 Элементы организации рельефа

import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import CardAbstract from 'app/components/card/common/CardAbstract';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import { CardContainer } from 'app/pages/cardsOgh/components/CardContainer';
import { OtherDocumentsRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/OtherDocuments.RF';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import React from 'react';

import { ArrangeElementTypeIdField } from '../../components/selects/ArrangeElementTypeId.Field';
import printOghCardHeader from '../../header/oghHeader';
import VersionsTemplate from '../../versions/VersionsTemplate';
import { CharacteristicsTabRelief } from '../tabs/Characteristics.Tab.Relief';

const types = ['relief'];
const Versions = VersionsTemplate(types);

const tabs = [
  {
    id: 'characteristics-tab',
    name: 'Исходные данные',
  },
  {
    id: 'docs-tab',
    name: 'Документы',
  },
];

/**
 * Классовый компонет - 34 Элементы организации рельефа.
 
 */
export class ReliefCard extends React.Component {

  /**
   * Компонент жизненого цикла componentDidUpdate.
   *
   * @param {object} prevProps - Предыдущие пропсы.
   * @returns {void} - Nothing.
   * @example --------
   */
  componentDidUpdate(prevProps) {
    const {
      formValues: { unit_id },
      mode,
    } = this.props;
    if (prevProps.formValues.unit_id !== unit_id && !mode.viewMode) {
      this.setDefaultQuantity();
    }
  }

  /**
   * Функция setDefaultQuantity.
   *
   * @returns {void} - Nothing.
   * @example ----
   */
  setDefaultQuantity() {
    const { changeFieldValue, quantityLock } = this.props;
    if (quantityLock) {
      changeFieldValue('quantity', 1);
    } else {
      changeFieldValue('quantity', '');
    }
  }

  /**
   * Метод жизненого цикла render().
   *
   * @returns {JSX.Element} - Реакт компонент.
   * @example ----
   */
  render() {
    const { card, createMode, mode } = this.props;

    return (
      <CardContainer>
        <CardAbstract>
          <div id="editor-card">
            {printOghCardHeader(card, mode)}
            {card && objectTabs(card?.type_id)}

            <div id="props">
              <FieldsSplitColumns>
                <ArrangeElementTypeIdField />
                <ParentInfo />
              </FieldsSplitColumns>

              <Tabs tabs={tabs} />
              <CharacteristicsTabRelief {...this.props} />

              <div hidden={true} id={'docs'}>
                <OtherDocumentsRF />
              </div>
            </div>
            <div hidden={true} id="versions">
              <Versions
                card={card}
                disabled={createMode}
                key={`versions-${card?.root_id}`}
              />
            </div>
          </div>
        </CardAbstract>
      </CardContainer>
    );
  }
}

/**
 * Обертка для использования хуков.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - Реакт Компонент.
 * @example -----
 */
export const ReliefComponent = (props) => {
  const { typeId } = useGetParentCard();
  return <ReliefCard parentTypeId={typeId} {...props} />;
};
