import { shallow } from 'enzyme';
import React from 'react';
import { vi } from 'vitest';

import { MaterialIdField } from './MaterialId.Field';
import { useSetupMaterialIdField } from './useSetup.MaterialId.Field';

vi.mock('./useSetup.MaterialId.Field');

describe('🐛 spec MaterialId.Field', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    useSetupMaterialIdField.mockReturnValue({});

    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(<MaterialIdField />);

    //❓ Assert
    expect(wrapper.text()).toBe('<MaterialIdRF />');
  });
});
