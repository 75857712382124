import { RANDOM_ID } from 'core/forTesting/constants';
import { generateRandomWord } from 'core/forTesting/generateRandomWord';
import { toast } from 'core/uiKit/components/Toast';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import { shallow } from 'enzyme';

import { externalReject } from './api';
import { BodyDialogReject } from './Body.Dialog.Reject';
import { DialogReject } from './Dialog.Reject';
import { formValidation } from './formValidation';

jest.mock('core/utils/hooks/useGetObjectId');
jest.mock('core/utils/hooks/useReactQuery');
jest.mock('core/uiKit/components/Toast');

/* eslint-disable */
describe('file Dialog.Reject.spec', () => {
  it('name', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    useMutationAdaptor.mockReturnValue([1, 3]);
    // Act
    const wrapper = shallow(<DialogReject />);
    // Assert
    expect(wrapper.text()).toBe('<ContinueFFDialog />');
  });

  it('props ContinueFFDialog', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    useMutationAdaptor.mockReturnValue([1, 3]);

    // Act
    const wrapper = shallow(<DialogReject />);

    // Assert
    expect(wrapper.props()).toMatchObject({
      textHeader: 'Отклонить проект, созданный внешней системой',
      validate: formValidation,
      isOpen: undefined,
      isLoading: undefined,
    });
  });

  it('без props DialogReject', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    useMutationAdaptor.mockReturnValue([1, 3]);

    // Act
    const wrapper = shallow(<DialogReject />);

    // Assert
    expect(wrapper.props()).toMatchObject({
      textHeader: 'Отклонить проект, созданный внешней системой',
      validate: formValidation,
      isOpen: undefined,
      closeDialog: expect.any(Function),
      isLoading: undefined,
      Body: <BodyDialogReject />,
    });
  });

  it('prop [closeDialog] fn _=>_ DialogReject', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    useMutationAdaptor.mockReturnValue([1, 3]);

    // Act
    const wrapper = shallow(<DialogReject />);

    // Assert
    expect(wrapper.props().closeDialog(1)).toBe(1);
  });

  it('prop [onSubmit]  DialogReject', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    const send = jest.fn();
    useMutationAdaptor.mockReturnValue([send, 3]);
    const wrapper = shallow(<DialogReject />);

    // Act
    wrapper.props().onSubmit({ newValue: RANDOM_ID });

    // Assert
    expect(send).toHaveBeenCalledWith({
      newValue: RANDOM_ID,
      objectId: RANDOM_ID,
    });
  });

  it('props DialogReject', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    useMutationAdaptor.mockReturnValue([1, 3]);

    const isOpen = RANDOM_ID % 2 === 0;
    const onClose = jest.fn();

    // Act
    const wrapper = shallow(<DialogReject {...{ isOpen, onClose }} />);

    // Assert
    expect(wrapper.props()).toMatchObject({
      textHeader: 'Отклонить проект, созданный внешней системой',
      validate: formValidation,
      isOpen: isOpen,
      isLoading: undefined,
      closeDialog: onClose,
    });
  });

  it('should useMutationAdaptor toHaveBeenCalledWith', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    useMutationAdaptor.mockReturnValue([1, 3]);

    // Act
    shallow(<DialogReject />);

    // Assert
    expect(useMutationAdaptor).toHaveBeenCalledWith(externalReject, {
      onError: expect.anything(),
      onSuccess: expect.anything(),
    });
  });

  it('action onSuccess', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    useMutationAdaptor.mockImplementation((_, { onSuccess, onError }) => {
      onSuccess();
      // onError({ message: 'asdf' });
      return [1, 3];
    });
    const onClose = jest.fn();
    const spyToastSuccess = jest.spyOn(toast, 'success');

    // Act
    shallow(<DialogReject onClose={onClose} />);

    // Assert
    expect(onClose).toHaveBeenCalledTimes(1);
    expect(spyToastSuccess).toHaveBeenCalledWith(
      'Уведомление будет отправлено пользователю, создавшему объект',
    );
  });

  it('action onError', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    const message = generateRandomWord();
    useMutationAdaptor.mockImplementation((_, { onSuccess, onError }) => {
      onError({ message });
      return [1, 3];
    });
    const onClose = jest.fn();
    const spyToastError = jest.spyOn(toast, 'error');

    // Act
    shallow(<DialogReject onClose={onClose} />);

    // Assert
    expect(onClose).toHaveBeenCalledTimes(1);
    expect(spyToastError).toHaveBeenCalledWith(message);
  });
});
