import { get } from 'lodash';
/* eslint-disable */

/**
 *
 * @param params
 */
export const IsDiffHeightMarkReducer = (params = {}) => {
  const { id = 'is_diff_height_mark', path = `attribute.${id}` } = params;
  return (result, state, props) => {
    const { card } = props;
    return {
      ...result,
      initialValues: {
        ...result.initialValues,
        [id]: get(card, path, null),
      },
    };
  };
};
