import { RANDOM_ID, RANDOM_WORD } from 'core/forTesting/constants';
import { useParamsTable } from 'core/uiKit/components/tables/Table';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';

import { useList } from './useGetList';

jest.mock('core/uiKit/components/tables/Table');
jest.mock('core/utils/hooks/useGetObjectId');
jest.mock('core/utils/hooks/useReactQuery');

describe('file useGetList.spec', () => {
  it('should', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const NAME = 'name__' + RANDOM_ID;
    const ID = 'id_' + RANDOM_ID;
    const QUERY_TABLE = 'queryTable_' + RANDOM_WORD;
    const IS_LOADING = 'IS_LOADING_' + RANDOM_WORD;
    const DATA_TABLE = 'dataTable_' + RANDOM_WORD;
    const DATA_COUNT = 'data_count' + RANDOM_WORD;

    useGetObjectId.mockReturnValue({ objectId: ID });
    useParamsTable.mockReturnValue({ QUERY_TABLE });
    useQueryAdaptor.mockReturnValueOnce({
      data: DATA_TABLE,
      isLoading: IS_LOADING,
    });
    useQueryAdaptor.mockReturnValueOnce({
      data: {
        data: {
          count: DATA_COUNT,
        },
      },
    });

    // Act
    const answer = useList(NAME);

    // Assert
    expect(answer).toStrictEqual({
      count: DATA_COUNT,
      isLoadingTable: IS_LOADING,
      table: DATA_TABLE,
    });
  });
});
