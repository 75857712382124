// 7 МАФ

import { getParseAttribute } from 'app/components/card/common/getParseAttribute';
import { parseDate } from 'app/components/card/common/parse';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import MafComponent from 'app/components/card/ogh/maf/MafComponent';
import submitCard from 'app/components/card/ogh/submit';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import { isThisViewing } from 'app/selectors/card/isThisViewing';
import getFormValues from 'app/selectors/form/getFormValues';
import { mandatoryFields } from 'app/utils/card/isMandatory';
import { transformValues } from 'app/utils/form/transformation';
import { IS_DIFF_HEIGHT_MARK_NAME } from 'core/form/reduxForm/fields/prepared/checkboxes/IsDiffHeightMark.RF';
import dateFormatService from 'core/services/dateFormatService/index';
import { ID_RFID } from 'core/uiKit/preparedInputs';
import { preparationValues } from 'core/utils/helpers/preparationValues';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

const propTypes = {
  card: PropTypes.object,
  createMode: PropTypes.bool,
  documents: PropTypes.array,
  editMode: PropTypes.bool,
  mode: PropTypes.object,
  required: PropTypes.bool,
};

/**
 * MafCardContainer.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - Компонент MafComponent.
 * @example ----
 */
const MafCardContainer = (props) => {
  return <MafComponent {...props} />;
};

MafCardContainer.propTypes = propTypes;

const dictFields = ['placement_id', 'conv_element_type_id', 'odh_side_id'];

const numericFields = [
  { name: 'bord_begin', type: 'decimal', zero: true },
  { name: 'bord_end', type: 'decimal', zero: true },
  {
    name: 'area',
    positive: true,
    type: 'decimal',
    zero: true,
  },
];

/**
 * Функция валидации.
 *
 * @param {object} values - Значения из формы.
 * @returns {*} - Жопа.
 * @example ----
 */
const validate = (values) => validateNumeric(values, numericFields);

const mafCardDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

/**
 * Функция создания атрибутов.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {object} - Возвращают атрибуты.
 * @example ----
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

/**
 * Функция сабмита.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {void} - Жопа.
 * @example ----
 */
function onSubmit(formValues) {
  const { card } = this.props;
  const values = { ...formValues };

  delete values.parent_name;

  // для перевода одного в другое
  addPreparation('is_object_area', (value) => (value ? 1 : 0));

  const formValuesWithAttribute = preparationValues({
    ...values,
    ...createAttribute(values),
  });

  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[card.type_id],
  );

  submitCard(
    this.props,

    transformValues(formValuesWithAttribute, {
      custom: {
        guarantee_period: {
          transformer,
        },
        installation_date: {
          transformer,
        },
        lifetime: {
          transformer,
        },
      },
      dictFields,
      numericFields,
    }),
  );
}

/**
 * Функция transformer.
 *
 * @param {*} value - Жопа.
 * @returns {*} - Жопа.
 * @example ----
 */
function transformer(value) {
  return value === null ? null : dateFormatService.formatDate(value);
}

/**
 * Функция получения и установки изначальных значений из State.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {object} - Поля.
 * @example ----
 */
const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);

  const result = {
    createMode: isCreateMode(state, props),
    editMode: isEditMode(state, props),
    enableReinitialize: true,
    formValues: getFormValues(state),
    initialValues: {
      [ID_RFID]: parse(ID_RFID),
      [IS_DIFF_HEIGHT_MARK_NAME]: parse(IS_DIFF_HEIGHT_MARK_NAME),
      area: parse('area'),
      bord_begin: parse('bord_begin'),
      bord_end: parse('bord_end'),
      conv_element_type_id: parse('conv_element_type_id.conv_element_type'),
      customer_id: parse('customer_id'),
      description: parse('description'),
      eais_id: parse('eais_id'),
      endDate: parseDate(props.card.end_date),
      erz_date: parseDate(props.card.attribute.erz_date),
      erz_state_id: parse('erz_state_id.erz_state'),
      guarantee_period: parseDate(props.card.attribute.guarantee_period),
      installation_date: parseDate(props.card.attribute.installation_date),
      is_filled: Boolean(parse('is_filled')),
      is_object_area: parse('is_object_area') || 0,
      lifetime: parseDate(props.card.attribute.lifetime),
      odh_axis: parse('odh_axis'),
      odh_side_id: parse('odh_side_id.odh_side'),
      owner_id: parse('owner_id'),
      parent_name: props.card.parent_info,
      placement_id: parse('placement_id.placement'),
      property: parse('property'),
      startDate: parseDate(props.card.start_date),
      totalAreaGeo: parse('total_area_geo'),
    },
    onSubmit: onSubmit.bind({ props }),
    required: isRequired(state, props),
    validate,
    viewing: isThisViewing(state),
  };
  return mafCardDocumentsReducer(result, state, props);
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'editorCard',
  })(MafCardContainer),
);
