/* eslint-disable */
/**
 *
 */
export default class ReagentMaxStorageCapacityService {
  /**
   *
   * @param reagentKindId
   * @param reagentId
   * @param reagentBalanceList
   */
  calculateBalance(reagentKindId, reagentId, reagentBalanceList) {
    let result;
    if (reagentBalanceList) {
      if (reagentKindId) {
        if (reagentId) {
          const balance = reagentBalanceList.find(
            (item) => item.reagent_id === reagentId,
          );
          result = balance ? balance.quantity : 0;
        } else {
          result = reagentBalanceList
            .filter((item) => item.reagent_kind_id === reagentKindId)
            .reduce((sum, item) => sum + item.quantity, 0);
        }
      } else {
        result = reagentBalanceList.reduce(
          (sum, item) => sum + item.quantity,
          0,
        );
      }
    } else {
      result = null;
    }
    return result;
  }
}
