import './newsNotes.scss';

import { useGetNewsAll } from 'app/api/hooks/news/useGet.NewsAll';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  CardHeader,
  ExpandMoreIcon,
} from 'core/uiKit/material-ui';
import React from 'react';

import { pathNewsNotes } from './path.NewsNotes';

/**
 *
 * Страница Список оповещений.
 *
 * @returns {JSX.Element} - Страница Примечания к оповещений.
 */
export const NewsNotes = () => {
  const { data: table } = useGetNewsAll();

  return (
    <Card className={'m-5'}>
      <CardHeader title={pathNewsNotes.name} />
      <CardContent>
        <div className={'news-notes__content'}>
          {table?.map((news) => (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id={'panel' + news.id}
              >
                {news.create_date}: <b className={'ms-3'}>{news.title}</b>
              </AccordionSummary>
              <AccordionDetails>
                <pre className={'m-3 news-notes__text'}>{news.text}</pre>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};
