import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { WithMapContext } from 'app/components/map/withMap';
import { cards } from 'app/pages/cardsOgh/cards';
import { Layout } from 'app/pages/cardsOgh/components/Layout/Layout';
import React, { useContext } from 'react';

/**
 * Cоздание карточки.
 *
 * @param {object} props - Пропсы компонента.
 * @returns {JSX.Element}
 */
const CreateCardOgh = (props) => {
  const { typeId } = useGetCurrentCard();
  const Component = cards[typeId]?.component;

  const { startEditing = () => {} } = useContext(WithMapContext);
  startEditing();

  return Component ? (
    <Layout map={cards[typeId].map}>
      <Component {...props} />
    </Layout>
  ) : null;
};

export default CreateCardOgh;
