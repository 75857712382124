import { pathAdmin } from 'app/pages/admin/path.Admin';
import { pathRoles } from 'app/pages/admin/Roles/path.Roles';
import cn from 'classnames';
import {
  ButtonAmber,
  ButtonBlue,
  ButtonGreen,
  ButtonRed,
} from 'core/newComponents/Button';
import { useNavigateRouter } from 'core/utils/router';
/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { actions as formActions } from 'react-redux-form';

const Controls = (props) => {
  const { isReadOnly, isErrorMsgShown, save, remove, cancel, roleId } = props;
  const navigate = useNavigateRouter();
  const close = () => navigate(`/${pathAdmin.path}/${pathRoles.path}`);
  const edit = () => navigate(`/a/edit/role/${roleId}`);
  return (
    <div className={cn('text-center', props.className)}>
      {isErrorMsgShown && (
        <div className="color-red text-center">Исправьте ошибки в форме</div>
      )}
      {isReadOnly && (
        <ButtonBlue className="align-bottom my-2 mx-2" onClick={edit}>
          Редактировать
        </ButtonBlue>
      )}
      {!isReadOnly && (
        <ButtonGreen className="align-bottom my-2 mx-2" onClick={save}>
          Сохранить
        </ButtonGreen>
      )}
      {isReadOnly && (
        <ButtonRed className="align-bottom my-2 mx-2" onClick={remove}>
          Удалить
        </ButtonRed>
      )}
      {isReadOnly && (
        <ButtonAmber className="align-bottom my-2 mx-2" onClick={close}>
          Закрыть
        </ButtonAmber>
      )}
      {!isReadOnly && (
        <ButtonRed className="align-bottom my-2 mx-2" onClick={cancel}>
          Отмена
        </ButtonRed>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isErrorMsgShown:
      state.rrf.forms.admin.role.$form.submitFailed &&
      !state.rrf.forms.admin.role.$form.valid,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    // edit: function() {
    //   return dispatch(push(`/a/edit/role/${ownProps.roleId}`));
    // },
    save: function () {
      return dispatch(formActions.submit('rrf.admin.role'));
    },
    remove: function () {
      return dispatch(
        formActions.change('rrf.admin.role.removeDialog', ownProps.roleId),
      );
    },
    // close: function() {
    //   return dispatch(push('/a/role'));
    // },
    cancel: function () {
      return dispatch(formActions.change('rrf.admin.role.cancelDialog', true));
    },
  };
}

 
export default connect(mapStateToProps, mapDispatchToProps)(Controls);
