/* eslint-disable */
import React from 'react';

import { CustomerImprovementObjectFF } from 'core/form/finalForm/fields';

import { filterInput } from '../../filterInputsClasses';

 
 
/**
 * Заказчик   improvement_object   customer
 * place=improvement_object
 */
 
export const CustomerImprovementObjectFilter = (props) => {
  return <CustomerImprovementObjectFF {...props} className={filterInput} />;
};
