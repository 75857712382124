/* eslint-disable */
export default class Pts {
  static mask = '99aa999999';

  static formatHint = '«12АА345678»';

  static formatChars = {
    9: '[0-9]',
    a: '[А-Я]',
  };
}
