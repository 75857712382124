import { useCallback, useRef } from 'react';
import useLocalStorage from 'use-local-storage';

export type UseGetDTWTokens = typeof useGetDTWTokens;
export type UseGetDTWTokensResult = ReturnType<UseGetDTWTokens>;

export type DLocalStorageToken = {
  accessToken: string;
  refreshToken: string;
  expiresIn?: string;
  refreshExpiresIn?: string;
};

/**
 * Хук хранения токенов для цифрового двойника.
 *
 * @returns {*} Методы управления состояниями токенов.
 */
export const useGetDTWTokens = () => {
  const [accessToken, setAccessToken] = useLocalStorage('dt_token', '', {
    syncData: true,
  });
  const [refreshToken, setRefreshToken] = useLocalStorage(
    'dt_refresh_token',
    '',
    { syncData: true },
  );

  const accessTokenRef = useRef(accessToken);
  accessTokenRef.current = accessToken;
  const getAccessToken = useCallback(() => accessTokenRef.current, []);

  const refreshTokenRef = useRef(refreshToken);
  refreshTokenRef.current = refreshToken;
  const getRefreshToken = useCallback(() => refreshTokenRef.current, []);

  const setTokens = useCallback((tokens: DLocalStorageToken) => {
    setAccessToken(tokens.accessToken);

    setRefreshToken(tokens.refreshToken);
    // eslint-disable-next-line
  }, []);

  const clearTokens = useCallback(() => {
    setAccessToken('');
    setRefreshToken('');
    // eslint-disable-next-line
  }, []);

  return {
    accessToken,
    clearTokens,
    getAccessToken,
    getRefreshToken,
    refreshToken,
    setAccessToken,
    setRefreshToken,
    setTokens,
  };
};
