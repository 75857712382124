import { useGetCardModeSelector } from 'app/selectors/useSelectors';
/* eslint-disable */
import { Field } from 'redux-form';

import { renderEditRowCell } from './renderEditRow.Cell';

/**
 *
 * @param root0
 * @param root0.row
 */
export const EditRowRFCell = ({ row = {} }) => {
  const { editMode } = useGetCardModeSelector();

  return editMode ? (
    <Field name="trafficCharacterList" component={renderEditRowCell(row)} />
  ) : null;
};
