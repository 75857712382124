import { CoatingTypeRfTramRails } from 'app/components/card/ogh/tram_rails/fields/CoatingTypeRf.TramRails/CoatingTypeRf.TramRails';
import { CoatingTypeIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/CoatingTypeId.RF';
import { shallow } from 'enzyme';
import React from 'react';

import { useCoatingTypeRfTramRails } from './useCoatingTypeRfTramRails';

jest.mock('./useCoatingTypeRfTramRails');

describe('CoatingTypeRfTramRailsJestSpec', function () {
  it('test isCurrentObjectIsExternalSystem false', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const editMode = true;
    const filter = jest.fn();
    const formValues = { coating_group_id: 1 };
    const isCurrentObjectIsExternalSystem = false;
    useCoatingTypeRfTramRails.mockReturnValue({
      editMode,
      filter,
      formValues,
      isCurrentObjectIsExternalSystem,
    });
    // Act
    const wrapper = shallow(<CoatingTypeRfTramRails />);

    //?
    // Assert
    expect(
      wrapper.contains(
        <CoatingTypeIdRF disabled={false} required={true} filter={filter} />,
      ),
    ).toBeTruthy();
  });
  it('test isCurrentObjectIsExternalSystem true', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const editMode = true;
    const filter = jest.fn();
    const formValues = { coating_group_id: 1 };
    const isCurrentObjectIsExternalSystem = true;
    useCoatingTypeRfTramRails.mockReturnValue({
      editMode,
      filter,
      formValues,
      isCurrentObjectIsExternalSystem,
    });
    // Act
    const wrapper = shallow(<CoatingTypeRfTramRails />);

    //?
    // Assert
    expect(
      wrapper.contains(
        <CoatingTypeIdRF disabled={true} required={true} filter={filter} />,
      ),
    ).toBeTruthy();
  });
  it('test formValues not have field coating_group_id', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const editMode = true;
    const filter = jest.fn();
    const formValues = {};
    const isCurrentObjectIsExternalSystem = false;
    useCoatingTypeRfTramRails.mockReturnValue({
      editMode,
      filter,
      formValues,
      isCurrentObjectIsExternalSystem,
    });
    // Act
    const wrapper = shallow(<CoatingTypeRfTramRails />);

    //?
    // Assert
    expect(
      wrapper.contains(
        <CoatingTypeIdRF disabled={true} required={true} filter={filter} />,
      ),
    ).toBeTruthy();
  });
});
