 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Уточнение__.
 *
 * @type {{ accessor: 'planar_structure_type_ref_name', Header: 'Уточнение', sortable: true, }}
 * @augments Cell
 */
export const planar_structure_type_ref_name = {
  accessor: 'planar_structure_type_ref_name',
  Header: 'Уточнение',
  sortable: true,
};
