import getFormValues from 'app/selectors/form/getFormValues';
/* eslint-disable  */
import getDict from 'app/selectors/getDict';
import { createSelector } from 'reselect';

/**
 *
 * @param state
 */
const getCarOwnershipId = (state) => getFormValues(state).car_ownership_id;

export default createSelector(
  [getDict('carOwnership'), getCarOwnershipId],
  (carOwnerships, carOwnershipId) => {
    let result;
    if (carOwnershipId) {
      const { code } = carOwnerships.find((item) => item.id === carOwnershipId);
      result = code;
    } else {
      result = null;
    }
    return result;
  },
);
