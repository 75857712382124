import {
  addressParams,
  buildingsTypeSpecParams,
  customerParams,
  okrugParams,
  ownerIdParams,
} from 'core/form/filterForm';
import { dateFromParams } from 'core/form/filterForm/components/dateFilters/DateFromFilter';
import { dateToParams } from 'core/form/filterForm/components/dateFilters/DateToFilter';
import { oghStatusesParams } from 'core/form/filterForm/components/miltiSelect/OghStatusesFilter';
import { grbsIdParams } from 'core/form/filterForm/components/selects/GrbsId.Filter';
import { idOrNameParams } from 'core/form/filterForm/components/text/IdOrName.Filter';
import { idOrNameParentParams } from 'core/form/filterForm/components/text/IdOrNameParent.Filter';
import { useFilterQueryParams } from 'core/form/filterForm/hooks/useFilterQueryParams';

 
/**
 * @typedef {object} FilterQueryParamsType - Данные QueryParams для фильтра формы FF.
 * @property {object} prepareFilters - Отформатированые данные для бэка.
 * @property {Function} setFilters - Функция для переноса состояния формы в урл.
 * @property {object} filters - Обычные данные формы.
 */

 
/**
 * Хук QueryParams для фильтра Иные некапитальные объекты.
 *
 * @returns {FilterQueryParamsType} - Жопа.
 * @example --------
 * const { setFilters, filters, prepareFilters } = useFilterQueryParamsOtherTechPlace()
 */
export const useFilterQueryParamsOtherTechPlace = () => {
  return useFilterQueryParams([
    addressParams,
    buildingsTypeSpecParams,
    customerParams,
    dateFromParams,
    dateToParams,
    grbsIdParams,
    idOrNameParams,
    idOrNameParentParams,
    oghStatusesParams,
    okrugParams,
    ownerIdParams,
  ]);
};
