import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { IMPROVEMENT_OBJECT, OZN, SPA, YARD } from 'app/constants/oghTypes';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { GroupContainer } from 'core/uiKit/components/card.components/GroupContainer';
import { NumberField } from 'core/uiKit/inputs/NumberField';
import React from 'react';

/**
 * Проверяем является ли обьект ОО или ДТ по typeId обьекта.
 *
 * @param typeId - Id типа обьекта.
 * @returns {boolean}
 */
const isYardOrOzn = (typeId: number | string): boolean =>
  [OZN, YARD].includes(+typeId);

/**
 * Уборочная площадь по покрытиям.
 *
 * @returns
 */
export const CleanCoverArea = () => {
  const { typeId, calc_attribute = {} } = useGetCurrentCard();
  return (
    <>
      <NumberField
        name={'total_cover_clean_area'}
        value={calc_attribute?.total_cover_clean_area}
        label={'Общая уборочная площадь по покрытиям, кв. м'}
        decimalScale={2}
        disabled={true}
      />
      <GroupContainer
        title={'Уборочная площадь по покрытиям'}
        isAccordion={true}
        initShow={false}
      >
        <FieldsSplitColumns>
          <NumberField
            name={'asphalt_clean_area'}
            value={calc_attribute.asphalt_clean_area}
            label={'Асфальтобетонное'}
            decimalScale={2}
            disabled={true}
          />
          <NumberField
            name={'cover_improve_usual_tiles_area'}
            value={calc_attribute.cover_improve_usual_tiles_area}
            label={'Плиточное'}
            decimalScale={2}
            disabled={true}
          />
          <NumberField
            name={'soil_clean_area'}
            value={calc_attribute.soil_clean_area}
            label={'Грунтовое'}
            decimalScale={2}
            disabled={true}
          />
          <NumberField
            name={'rubber_clean_area'}
            value={calc_attribute.rubber_clean_area}
            label={
              typeId === IMPROVEMENT_OBJECT
                ? 'Мягкое из резиновой крошки'
                : typeId === SPA
                ? 'Резиновое и иное искусственное кв. м'
                : 'Резиновая крошка'
            }
            decimalScale={2}
            disabled={true}
          />
          <NumberField
            name={'sand_clean_area'}
            value={calc_attribute.sand_clean_area}
            label={'Мягкое из песка'}
            decimalScale={2}
            hidden={isYardOrOzn(typeId)}
            disabled={true}
          />
          <NumberField
            decimalScale={2}
            disabled={true}
            hidden={isYardOrOzn(typeId)}
            label={'Мягкое из гранитной высевки'}
            name={'granite_clean_area'}
            value={calc_attribute.granite_clean_area}
          />
          <NumberField
            decimalScale={2}
            disabled={true}
            label={'Искусственная трава'}
            name={'grass_clean_area'}
            value={calc_attribute.grass_clean_area}
          />
          <NumberField
            decimalScale={2}
            disabled={true}
            label={'Пластиковое'}
            name={'plastic_clean_area'}
            value={calc_attribute.plastic_clean_area}
          />
          <NumberField
            decimalScale={2}
            disabled={true}
            hidden={isYardOrOzn(typeId)}
            label={'На ячеистом основании / экопарковки'}
            name={'grass_paver_clean_area'}
            value={calc_attribute.grass_paver_clean_area}
          />
          <NumberField
            decimalScale={2}
            disabled={true}
            hidden={!isYardOrOzn(typeId)}
            label={'Акриловое (хард)'}
            name={
              typeId === OZN ? 'oo_cover_acryl_area' : 'yard_cover_acryl_area'
            }
            value={
              typeId === OZN
                ? calc_attribute.oo_cover_acryl_area
                : calc_attribute.yard_cover_acryl_area
            }
          />
          <NumberField
            decimalScale={2}
            disabled={true}
            hidden={!isYardOrOzn(typeId)}
            label={'Асфальтная крошка'}
            name={
              typeId === OZN
                ? 'oo_cover_asphalt_crumb_area'
                : 'yard_cover_asphalt_crumb_area'
            }
            value={
              typeId === OZN
                ? calc_attribute.oo_cover_asphalt_crumb_area
                : calc_attribute.yard_cover_asphalt_crumb_area
            }
          />
          <NumberField
            decimalScale={2}
            disabled={true}
            hidden={!isYardOrOzn(typeId)}
            label={'Цементобетонное'}
            name={
              typeId === OZN
                ? 'oo_cover_cement_concrete_area'
                : 'yard_cover_cement_concrete_area'
            }
            value={
              typeId === OZN
                ? calc_attribute.oo_cover_cement_concrete_area
                : calc_attribute.yard_cover_cement_concrete_area
            }
          />
          <NumberField
            decimalScale={2}
            disabled={true}
            hidden={!isYardOrOzn(typeId)}
            label={'Бетонное на ячеистом основании (экопарковка)'}
            name={'concrete_cell_clean_area'}
            value={calc_attribute.concrete_cell_clean_area}
          />
          <NumberField
            decimalScale={2}
            disabled={true}
            hidden={!isYardOrOzn(typeId)}
            label={'Пластиковое на ячеистом основании (экопарковка)'}
            name={'plastic_cell_clean_area'}
            value={calc_attribute.plastic_cell_clean_area}
          />
          <NumberField
            decimalScale={2}
            disabled={true}
            hidden={!isYardOrOzn(typeId)}
            label={'Брусчатка'}
            name={
              typeId === OZN
                ? 'oo_cover_paving_stones_area'
                : 'yard_cover_paving_stones_area'
            }
            value={
              typeId === OZN
                ? calc_attribute.oo_cover_paving_stones_area
                : calc_attribute.yard_cover_paving_stones_area
            }
          />
          <NumberField
            decimalScale={2}
            disabled={true}
            hidden={!isYardOrOzn(typeId)}
            label={'Гравийное'}
            name={
              typeId === OZN ? 'oo_cover_gravel_area' : 'yard_cover_gravel_area'
            }
            value={
              typeId === OZN
                ? calc_attribute.oo_cover_gravel_area
                : calc_attribute.yard_cover_gravel_area
            }
          />
          <NumberField
            decimalScale={2}
            disabled={true}
            hidden={!isYardOrOzn(typeId)}
            label={'Гранитное'}
            name={
              typeId === OZN
                ? 'oo_cover_noimprove_granite_area'
                : 'yard_cover_noimprove_granite_area'
            }
            value={
              typeId === OZN
                ? calc_attribute.oo_cover_noimprove_granite_area
                : calc_attribute.yard_cover_noimprove_granite_area
            }
          />
          <NumberField
            decimalScale={2}
            disabled={true}
            hidden={!isYardOrOzn(typeId)}
            label={'Гранитная высевка'}
            name={
              typeId === OZN
                ? 'oo_granite_sowing_clean_area'
                : 'yard_granite_sowing_clean_area'
            }
            value={
              typeId === OZN
                ? calc_attribute.oo_granite_sowing_clean_area
                : calc_attribute.yard_granite_sowing_clean_area
            }
          />
          <NumberField
            decimalScale={2}
            disabled={true}
            label={'Гранитная плитка'}
            name={
              typeId === OZN
                ? 'oo_granite_tiles_clean_area'
                : 'yard_granite_tiles_clean_area'
            }
            value={
              typeId === OZN
                ? calc_attribute.oo_granite_tiles_clean_area
                : calc_attribute.yard_granite_tiles_clean_area
            }
          />
          <NumberField
            decimalScale={2}
            disabled={true}
            hidden={!isYardOrOzn(typeId)}
            label={'Деревянное'}
            name={
              typeId === OZN ? 'oo_cover_wood_area' : 'yard_cover_wood_area'
            }
            value={
              typeId === OZN
                ? calc_attribute.oo_cover_wood_area
                : calc_attribute.yard_cover_wood_area
            }
          />
          <NumberField
            decimalScale={2}
            disabled={true}
            hidden={!isYardOrOzn(typeId)}
            label={'Искусственный лед'}
            name={'artificial_ice_clean_area'}
            value={calc_attribute.artificial_ice_clean_area}
          />
          <NumberField
            decimalScale={2}
            disabled={true}
            hidden={!isYardOrOzn(typeId)}
            label={'Естественный лед'}
            name={'natural_ice_clean_area'}
            value={calc_attribute.natural_ice_clean_area}
          />
          <NumberField
            decimalScale={2}
            disabled={true}
            hidden={!isYardOrOzn(typeId)}
            label={'Клинкерный кирпич'}
            name={'clinker_brick_area'}
            value={calc_attribute.clinker_brick_area}
          />
          <NumberField
            decimalScale={2}
            disabled={true}
            hidden={!isYardOrOzn(typeId)}
            label={'Металлическое'}
            name={
              typeId === OZN ? 'oo_cover_steel_area' : 'yard_cover_steel_area'
            }
            value={
              typeId === OZN
                ? calc_attribute.oo_cover_steel_area
                : calc_attribute.yard_cover_steel_area
            }
          />
          <NumberField
            decimalScale={2}
            disabled={true}
            hidden={!isYardOrOzn(typeId)}
            label={'Мраморные плиты'}
            name={
              typeId === OZN
                ? 'oo_cover_marble_slab_area'
                : 'yard_cover_marble_slab_area'
            }
            value={
              typeId === OZN
                ? calc_attribute.oo_cover_marble_slab_area
                : calc_attribute.yard_cover_marble_slab_area
            }
          />
          <NumberField
            decimalScale={2}
            disabled={true}
            hidden={!isYardOrOzn(typeId)}
            label={'Песчаное'}
            name={'sand_clean_area'}
            value={calc_attribute.sand_clean_area}
          />
          <NumberField
            decimalScale={2}
            disabled={true}
            hidden={!isYardOrOzn(typeId)}
            label={'Песчано-гравийная смесь'}
            name={
              typeId === OZN
                ? 'oo_cover_sand_gravel_area'
                : 'yard_cover_sand_gravel_area'
            }
            value={
              typeId === OZN
                ? calc_attribute.oo_cover_sand_gravel_area
                : calc_attribute.yard_cover_sand_gravel_area
            }
          />
          <NumberField
            decimalScale={2}
            disabled={true}
            hidden={!isYardOrOzn(typeId)}
            label={'Решётчатый настил'}
            name={
              typeId === OZN
                ? 'oo_cover_trellised_flooring_area'
                : 'yard_cover_trellised_flooring_area'
            }
            value={
              typeId === OZN
                ? calc_attribute.oo_cover_trellised_flooring_area
                : calc_attribute.yard_cover_trellised_flooring_area
            }
          />
          <NumberField
            decimalScale={2}
            disabled={true}
            hidden={!isYardOrOzn(typeId)}
            label={'Тактильная плитка'}
            name={
              typeId === OZN
                ? 'oo_cover_improve_tactile_tiles_area'
                : 'yard_cover_improve_tactile_tiles_area'
            }
            value={
              typeId === OZN
                ? calc_attribute.oo_cover_improve_tactile_tiles_area
                : calc_attribute.yard_cover_improve_tactile_tiles_area
            }
          />
          <NumberField
            decimalScale={2}
            disabled={true}
            hidden={!isYardOrOzn(typeId)}
            label={'Щебеночное'}
            name={
              typeId === OZN
                ? 'oo_cover_macadam_area'
                : 'yard_cover_macadam_area'
            }
            value={
              typeId === OZN
                ? calc_attribute.oo_cover_macadam_area
                : calc_attribute.yard_cover_macadam_area
            }
          />
          <NumberField
            decimalScale={2}
            disabled={true}
            hidden={!isYardOrOzn(typeId)}
            label={'TerraWay'}
            name={
              typeId === OZN
                ? 'oo_terraway_clean_area'
                : 'yard_terraway_clean_area'
            }
            value={
              typeId === OZN
                ? calc_attribute.oo_terraway_clean_area
                : calc_attribute.yard_terraway_clean_area
            }
          />
          <NumberField
            decimalScale={2}
            disabled={true}
            hidden={typeId !== OZN}
            label={'Древесная щепа (кора)'}
            name={'oo_wood_chip_clean_area'}
            value={calc_attribute.oo_wood_chip_clean_area}
          />
          <NumberField
            decimalScale={2}
            disabled={true}
            hidden={!isYardOrOzn(typeId)}
            label={'Деревянный настил из террасной доски'}
            name={
              typeId === OZN
                ? 'oo_cover_wood_flooring_area'
                : 'yard_cover_wood_flooring_area'
            }
            value={
              typeId === OZN
                ? calc_attribute.oo_cover_wood_flooring_area
                : calc_attribute.yard_cover_wood_flooring_area
            }
          />
          <NumberField
            decimalScale={2}
            disabled={true}
            hidden={!isYardOrOzn(typeId)}
            label={'Натуральный камень'}
            name={
              typeId === OZN ? 'oo_cover_stone_area' : 'yard_cover_stone_area'
            }
            value={
              typeId === OZN
                ? calc_attribute.oo_cover_stone_area
                : calc_attribute.yard_cover_stone_area
            }
          />
          <NumberField
            decimalScale={2}
            disabled={true}
            hidden={!isYardOrOzn(typeId)}
            label={'Настил ДПК (древесно-полимерный композит)'}
            name={
              typeId === OZN
                ? 'oo_wpc_flooring_clean_area'
                : 'yard_wpc_flooring_clean_area'
            }
            value={
              typeId === OZN
                ? calc_attribute.oo_wpc_flooring_clean_area
                : calc_attribute.yard_wpc_flooring_clean_area
            }
          />
        </FieldsSplitColumns>
      </GroupContainer>
    </>
  );
};
