import React from 'react';

import { useGetTreeMainPage } from '../hooks/useGetTreeMainPage';
import { SkeletonItemTreeMainPage } from './SkeletonItem.Tree.MainPage';

/**
 * Скелетон из трех элементов для списка по скроллу.
 *
 * @returns {React.JSX.Element|null}
 */
export const SkeletonTreeMainPage = () => {
  const { isFetchingNextPage } = useGetTreeMainPage();

  return isFetchingNextPage ? (
    <>
      <SkeletonItemTreeMainPage />
      <SkeletonItemTreeMainPage />
      <SkeletonItemTreeMainPage />
    </>
  ) : null;
};
