import {
  PROVIDER_LIST_ID_NAME,
  ProviderListIdFF,
} from 'core/form/finalForm/fields';
import { objectInArrayParam } from 'core/utils/hooks';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';
import { withDefault } from 'use-query-params';

import { filterInputAutocomplete } from '../filterInputsClasses';

/**
 * ## ID составного объекта
 * Provider_list_id..
 *
 * @returns {*}
 */
export const ProviderListIdFilter = () => (
  <ProviderListIdFF className={filterInputAutocomplete} />
);

export { PROVIDER_LIST_ID_NAME };

/**
 * ## ID составного объекта
 * Provider_list_id..
 *
 * @returns {*}
 */
export const providerListIdParams = () => {
  addPreparation(PROVIDER_LIST_ID_NAME, (value) => {
    return value.map((item) => item.id).join(',');
  });
  return {
    [PROVIDER_LIST_ID_NAME]: withDefault(objectInArrayParam, [{}]),
  };
};
