import GeometryCSVLink from 'app/utils/card/checkGeometry/GeometryCSVLink';
import { IntersectionTable } from 'app/utils/card/checkGeometry/IntersectionTable';
import React from 'react';

/**
 * Создание сообщения.
 *
 * @param {Array} array - Массив.
 * @param {number} total - Всего.
 * @param {string} message - Сообщение.
 * @param {Array} data - Данные для таблицы.
 * @returns {{headerData: JSX.Element, bodyData: JSX.Element}} - Возвращаемые значения.
 * @example
 *  const { headerData, bodyData } = createMessage(
 *       neighbours,
 *       total,
 *       messages.neighbours,
 *       data,
 *     );
 */
export function createMessage(array, total, message, data) {
  return {
    bodyData: (
      <div style={{ height: '420px' }}>
        <IntersectionTable params={data} />
      </div>
    ),
    headerData: (
      <>
        {message}
        <p>
          <GeometryCSVLink
            fileName={'NeighboursGeometry.csv'}
            objects={array}
          />
        </p>
      </>
    ),
  };
}
