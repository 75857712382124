import { Button } from 'core/uiKit/components';
import { Menu, MenuItem } from 'core/uiKit/components/Menu';
import { CircularProgress, ExpandMoreIcon, Icon } from 'core/uiKit/material-ui';
import React, { useState } from 'react';

import * as api from './api';
import { useFilterQueryParamsYard } from './Filter.Yard';
import { ListItemYard } from './ListItemYard';
import { MenuItemYard } from './MenuItemYard';

/**
 * Компонент ReportsYard.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - Тип ОГХ.
 * @returns {JSX.Element}
 */
export const ReportsYard = ({ typeId }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const { prepareFilters } = useFilterQueryParamsYard();

  /**
   * Функция handleClick.
   *
   * @param {Event} event - Event.
   * @returns {void}
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Функция handleClose.
   *
   * @returns {void}
   */
  const handleClose = () => {
    setAnchorEl(null);
  };
  const {
    sendSummerCleaning,
    loadingSummerCleaning,
    sendSummerCleaningWithSummary,
    loadingSummerCleaningWithSummary,
    sendWinterCleaning,
    loadingWinterCleaning,
    sendWinterCleaningWithSummary,
    loadingWinterCleaningWithSummary,
    sendDtClassification,
    loadingDtClassification,
    sendDtClassificationWithSummary,
    loadingDtClassificationWithSummary,
    sendMaf,
    loadingMaf,
    sendMafWithSummary,
    loadingMafWithSummary,
    sendGreening,
    loadingGreening,
    sendGreeningWithSummary,
    loadingGreeningWithSummary,

    sendCover,
    loadingCover,
    sendCoverWithSummary,
    loadingCoverWithSummary,
    sendDt,
    loadingDt,
    sendDtWithSummary,
    loadingDtWithSummary,

    sendFullExport,
    loadingFullExport,
  } = api.usePrintReport(typeId);

  return (
    <>
      <Button
        onClick={handleClick}
        variant="contained"
        color="primary"
        endIcon={<ExpandMoreIcon />}
      >
        Отчёты
      </Button>

      <Menu
        anchorEl={anchorEl}
        id="simple-menu"
        keepMounted={true}
        open={Boolean(anchorEl)}
        PaperProps={{
          style: {
            maxHeight: 450,
          },
        }}
        onClose={handleClose}
      >
        <MenuItemYard text={'Летняя уборка'}>
          <ListItemYard
            text={'Сводный'}
            onClick={() => sendSummerCleaningWithSummary(prepareFilters)}
            loading={loadingSummerCleaningWithSummary}
          />
          <ListItemYard
            text={'Подробный'}
            onClick={() => sendSummerCleaning(prepareFilters)}
            loading={loadingSummerCleaning}
          />
        </MenuItemYard>

        <MenuItemYard text={'Зимняя уборка'}>
          <ListItemYard
            text={'Сводный'}
            onClick={() => sendWinterCleaningWithSummary(prepareFilters)}
            loading={loadingWinterCleaningWithSummary}
          />
          <ListItemYard
            text={'Подробный'}
            onClick={() => sendWinterCleaning(prepareFilters)}
            loading={loadingWinterCleaning}
          />
        </MenuItemYard>

        <MenuItemYard text={'Классификация ДТ'}>
          <ListItemYard
            text={'Сводный'}
            onClick={() => sendDtClassificationWithSummary(prepareFilters)}
            loading={loadingDtClassificationWithSummary}
          />
          <ListItemYard
            text={'Подробный'}
            onClick={() => sendDtClassification(prepareFilters)}
            loading={loadingDtClassification}
          />
        </MenuItemYard>

        <MenuItemYard text={'МАФы'}>
          <ListItemYard
            text={'Сводный'}
            onClick={() => sendMafWithSummary(prepareFilters)}
            loading={loadingMafWithSummary}
          />
          <ListItemYard
            text={'Подробный'}
            onClick={() => sendMaf(prepareFilters)}
            loading={loadingMaf}
          />
        </MenuItemYard>
        <MenuItemYard text={'Озеленение'}>
          <ListItemYard
            text={'Сводный'}
            onClick={() => sendGreeningWithSummary(prepareFilters)}
            loading={loadingGreeningWithSummary}
          />
          <ListItemYard
            text={'Подробный'}
            onClick={() => sendGreening(prepareFilters)}
            loading={loadingGreening}
          />
        </MenuItemYard>

        <MenuItemYard text={'Покрытие и элементы сопряжения'}>
          <ListItemYard
            text={'Сводный'}
            onClick={() => sendCoverWithSummary(prepareFilters)}
            loading={loadingCoverWithSummary}
          />
          <ListItemYard
            text={'Подробный'}
            onClick={() => sendCover(prepareFilters)}
            loading={loadingCover}
          />
        </MenuItemYard>

        <MenuItemYard text={'Общий отчёт по форме ТС'}>
          <ListItemYard
            text={'Сводный'}
            onClick={() => sendDtWithSummary(prepareFilters)}
            loading={loadingDtWithSummary}
          />
          <ListItemYard
            text={'Подробный'}
            onClick={() => sendDt(prepareFilters)}
            loading={loadingDt}
          />
        </MenuItemYard>

        <MenuItem
          disabled={loadingFullExport}
          onClick={() => sendFullExport(prepareFilters)}
        >
          <span className={'mr-2'}>
            {loadingFullExport ? (
              <CircularProgress size={20} />
            ) : (
              <Icon>print</Icon>
            )}
          </span>
          Полный реестр
        </MenuItem>
      </Menu>
    </>
  );
};
