import moment from 'moment';

 
/**
 * Получение Вчерашней даты в формате 'DD.MM.YYYY'.
 *
 * @returns {string} - Жопа.
 * @example -------
 * getYesterdayDateSting() === 15.11.2022
 */
export const getYesterdayDateSting = () => {
  return moment().subtract(1, 'day').format('DD.MM.YYYY');
};
