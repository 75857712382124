/**
 * Функция парсинга Асинхронных селектов.
 *
 * @param value - Значение.
 * @param value.id - Id.
 * @param value.label - Лейбл.
 * @returns {object}
 */
export const parseAsyncAutocomplete = (value: {
  id?: number | string | null;
  label?: string | null;
}): { id: string | number; name: string } => {
  return {
    id: value?.id ?? '-1',
    name: value?.label ?? 'пусто',
  };
};
