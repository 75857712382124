/* eslint-disable */
import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import { CreateType, CREATE_TYPE_NAME } from 'core/uiKit/preparedInputs';

 
export { CREATE_TYPE_NAME };

/* eslint-disable */
export const CreateTypeFF = ({ name, ...otherProps }) => {
  return (
    <Field name={CREATE_TYPE_NAME}>
      {(props) => {
        return <CreateType {...props} {...props.input} {...otherProps} />;
      }}
    </Field>
  );
};

CreateTypeFF.propTypes = {
   
   
/**
   Выбор темной темы
   */
  dark: PropTypes.bool,
  disabled: PropTypes.bool,
};
CreateTypeFF.defaultProps = {
  dark: false,
  disabled: false,
};
