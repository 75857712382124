/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import { TextFieldWithInputMask } from 'core/newComponents/TextField';
import withReduxForm from 'core/hocs/withReduxForm';

const propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
 
/* eslint-disable */
/**
 * @deprecated
 * @param Component
 * @returns {function(*)}
 */
function CustomTextField({
  digits,
  disabled,
  formatChars,
  mask,
  defaultValue = '', // undefined makes input uncontrolled
  value: v,
  errorText,
  maxLength,
  onChange,
  withChange,
  maxValue,
  style,
  zero = true, // omit validation rule field
  positive, // omit validation rule field
  decimal, // omit validation rule field
  ...rest
}) {
  const value = v === 0 ? '0' : v;

  const handleChange = (value) => {
    const hasDigits = digits === 0 || digits;
    if (hasDigits) {
      if (isNumber(value) && digitsCheck(value, digits)) {
        let number = value === '' ? '' : replaceCommaWithDot(value);
        if (!zero && +value === 0) {
          number = '';
        }
        onChange(number);
      }
    } else {
      if (!maxLength || `${value}`.length <= maxLength) {
        onChange(value);
      }
    }
    if (maxValue) {
      const getValue = value > maxValue ? maxValue : value;
      onChange(getValue);
    }
    if (withChange) {
      withChange(value);
    }
  };

  const def = defaultValue === 0 ? '0' : defaultValue || '';

  return (
    <div
      style={{
        marginBottom: '23px',
        ...style,
      }}
    >
      <TextFieldWithInputMask
        disabled={disabled}
        error={!!errorText}
        maxLength={maxLength}
        formatChars={formatChars}
        helperText={errorText}
        mask={mask}
        maskChar=""
        {...rest}
        value={!value ? def : value}
        onChange={handleChange}
      />
    </div>
  );
}

CustomTextField.propTypes = propTypes;

function isNumber(value) {
  return /^-{0,1}\d*[,.]{0,1}\d*$/.test(`${value}`);
}

function digitsCheck(value, digits) {
  let check;
  if (Number(digits) === 0) {
    check = new RegExp('^-{0,1}\\d*$');
  } else {
    check = new RegExp(`^-{0,1}\\d*[,.]{0,1}\\d{0,${digits}}$`);
  }
  return check.test(`${value}`);
}

function replaceCommaWithDot(value) {
  return `${value}`.replace(',', '.');
}
 
 
/**
 * @deprecated
 * @param Component
 * @returns {function(*)}
 */
 
export default withReduxForm(CustomTextField);
