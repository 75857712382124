/* eslint-disable */
export const pathReagentNorm = {
  path: 'reagent-norm',
  name: 'Реестр нормативных расчетов потребности ПГР',
  privilegeView: 'view_doc_reagent_norm',
  privilegeEdit: 'edit_doc_reagent_norm',
  privilegeCreate: 'create_doc_reagent_norm',
  privilegeDel: 'del_doc_reagent_norm',
  privilegeMatchingDoc: 'matching_doc_reagent_norm',
};
