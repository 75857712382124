import { CheckboxRF } from 'core/form/reduxForm/fields';
/* eslint-disable */

/**
 *
 */
export const IsFilled = () => {
  return (
    <CheckboxRF name={'is_filled'} label={'Данные заполнены'} disabled={true} />
  );
};
