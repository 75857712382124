import { REQUIRED_FIELD_MESSAGE } from 'app/constants/messages';

/**
 * Функция валидация поля __Площадь вывоза снега, кв. М__..
 *
 * @param {object} formValues - Значения формы.
 * @returns {{}|null}
 */
function validate(formValues) {
  const { snow_clean_area } = formValues;
  let result = {};

  if (snow_clean_area === undefined) {
    result['snow_clean_area'] = REQUIRED_FIELD_MESSAGE;
  }

  return Object.keys(result).length > 0 ? result : null;
}

export default validate;
