/* eslint-disable  */
import { get } from 'app/api';

/**
 *
 * @param place
 */
export const fetchCustomers = (place) => async (data) => {
  const res = await get('/autocomplete/customers', { ...data, place });
  return res.map(({ id, shortname }) => ({ id, name: shortname }));
};
