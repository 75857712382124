const message =
  'Выбранный тип техники не соответствует установленному навесному оборудованию';

/* eslint-disable */
export default function validateCarFuncType(formValues, carEquipmentTypes) {
  const carEquipmentTypeCodes = carEquipmentTypes.map((item) => item.code);
  const equipmentIsAllowed = (item) =>
    carEquipmentTypeCodes.includes(item.equipment_type_code);

  const equipmentList = formValues.equipmentList || [];
  const installedEquipmentIsAllowed = equipmentList.every(equipmentIsAllowed);

  let error;
  if (installedEquipmentIsAllowed) {
    error = {};
  } else {
    error = {
      car_func_type_id: message,
    };
  }
  return error;
}
