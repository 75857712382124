import { ENGINEER_BUILDING_TYPE_ID } from 'core/uiKit/preparedInputs/selects/simpleSelect/EngineerBuildingTypeId';
import { usePreparation } from 'core/utils/hooks/usePreparation/preparation';

/**
 * Хук перевода значений при сохранении для __Тип (наименование)__.
 *
 * @returns {void}
 */
export const usePreparationEngineerBuildingTypeIdFF = () => {
  usePreparation(ENGINEER_BUILDING_TYPE_ID, (value) => {
    if (value) {
      return { engineer_building_type: value };
    }
  });
};
