/* eslint-disable */
import React from 'react';
import cn from 'classnames';
import { useParams } from 'react-router-dom';

import CardForm from './CardForm';

const CardView = (props) => {
  let { roleId } = useParams();

  return (
    <CardForm
      className={cn('container my-4', props.className)}
      heading="Просмотр роли"
      isReadOnly={true}
      roleId={roleId}
    />
  );
};

 
export default CardView;
