import IconButton from 'core/newComponents/IconButton';
import { BackspaceIcon } from 'core/uiKit/material-ui';
import { useNavigateRouter } from 'core/utils/router';

/**
 * Компонент IconButtonLocal.
 *
 * @returns {JSX.Element}
 */
export const IconButtonLocal = () => {
  const navigate = useNavigateRouter();
  return (
    <IconButton
      className="return-back"
      title="Вернуться на предыдущую страницу"
      onClick={() => {

        /**
         * Функция goLeaveBack.
         *
         * @returns {void}
         */
        const goLeaveBack = () => {
          navigate(-1);
        };
        goLeaveBack();
      }}
    >
      <BackspaceIcon />
    </IconButton>
  );
};
