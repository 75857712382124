import SortBtn from 'app/components/common/SortBtn';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/* eslint-disable */
export default class Empty extends React.Component {
  static defaultProps = {
    sort: '',
  };

  static propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    sort: PropTypes.string,
    onSort: PropTypes.func,
  };

  onSortClick({ orderBy, sortDirection }) {
    const { onSort } = this.props;
    sortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    onSort({ sort: `${orderBy}.${sortDirection}` });
  }

  getSortBtnProps() {
    const { sort, id } = this.props;
    const sortDirection = getSortDirection(sort || [], id);
    return {
      onClick: this.onSortClick.bind(this, { orderBy: id, sortDirection }),
      direction: sortDirection,
      className: cn('btn btn-sm btn-secondary', this.props.className),
    };
  }

  render() {
    return <SortBtn {...this.getSortBtnProps()} />;
  }
}

// Если приходит массив или строка в sortProp, то ищет из этого направление сортировки (sortDirection) для тек. столбца (columnId)
// Если направление сортировки найти не удалось возвращает ''
function getSortDirection(sortProp, columnId) {
  if (sortProp instanceof Array) {
    const sortData = sortProp.find((s) => columnId === s.split('.')[0]);
    if (sortData) {
      return _getSortDirection(sortData, columnId);
    }
  } else {
    return _getSortDirection(sortProp, columnId);
  }
}

function _getSortDirection(sort, columnId) {
  const sortArr = sort.split('.');
  const sortColumn = sortArr[0];
  const sortDirection = sortArr[1];
  return columnId === sortColumn ? sortDirection : '';
}
