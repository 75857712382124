import { Paper } from 'core/uiKit/components/Paper';

/* eslint-disable */
import { CloseElectronicSignature } from './CloseElectronicSignature';

export default {
  title: 'Example/signatures/CloseElectronicSignature',
};

/**
 *
 * @param args
 */
export const Basic = (args) => {
  return (
    <Paper className={'p-5 display-flex flex-wrap gap-2'}>
      <CloseElectronicSignature {...args} />
    </Paper>
  );
};

Basic.args = {};
