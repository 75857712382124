import {
  AddButtonOgh,
  Body,
  DialogCreateSimple,
  Header,
  Main,
  RegistryToolbar,
} from 'app/pages/components';
import { pathOgh } from 'app/pages/registry/ogh/Ogh/path.Ogh';
import { TreePanelContainer } from 'app/pages/registry/ogh/сontainer/TreePanel.Container';
import { pathRegistry } from 'app/pages/registry/path.Registry';
import { useGetOghType } from 'core/utils/hooks/useGetOghType';

import { FilterContainer } from './Filter.Container';
import { pathContainer } from './path.Container';
import { PrintButtonContainer } from './PrintButton.Container';
import { TableContainer } from './Table.Container';

/**
 * Реестр Места сбора отходов.
 *
 * @param {object} props - Пропсы.
 * @param {JSX.Element} props.TreePanel - Боковое дерево.
 * @param {number} props.parentTypeId - Id типа родителя.
 * @returns {JSX.Element} - JSX.
 * @example ----
 */
const Container = ({ TreePanel, parentTypeId }) => {
  const oghType = useGetOghType('container');
  const typeId = oghType.id;
  const path = `/${pathRegistry.path}/${pathOgh.path}/${pathContainer.path}`;

  return (
    <Main>
      <Header Filter={<FilterContainer parentTypeId={parentTypeId} />} />
      <Body>
        <RegistryToolbar showToggle title={oghType.name}>
          {!TreePanel && <AddButtonOgh typeCode={oghType.code} />}
          <PrintButtonContainer typeId={typeId} parentTypeId={parentTypeId} />
        </RegistryToolbar>

        <div className={'d-flex h-100 overflow-hidden-x'}>
          {TreePanel || <TreePanelContainer root={path} />}
          <TableContainer typeId={typeId} parentTypeId={parentTypeId} />
        </div>
        <DialogCreateSimple typeId={typeId} />
      </Body>
    </Main>
  );
};

export default Container;
