/* eslint-disable */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Link from 'app/components/common/misc/Link';
import { DropDownLink } from './DropDown';

const linkStyle = {
  cursor: 'pointer',
};

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  link: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
};

const defaultProps = {
  link: '#',
};

const NavItem = ({
  className,
  id,
  link,
  style,
  title,
  type,
  children,
  onClick,
}) => (
  <li>
    {type === 'dropdown' ? (
      <DropDownLink
        className={className}
        id={id}
        linkStyle={linkStyle}
        text={title}
      >
        <ul>{children}</ul>
      </DropDownLink>
    ) : (
      <Link
        className={className}
        style={style}
        title={title}
        to={link}
        onClick={onClick}
      >
        {children}
      </Link>
    )}
  </li>
);

NavItem.propTypes = propTypes;
NavItem.defaultProps = defaultProps;

 
export default memo(NavItem);
