/* eslint-disable */
import { getFieldError } from 'app/utils/getFieldError';
import { DndFileUpload } from 'core/uiKit/inputs/fileUpload/DndFileUpload';
import { Field } from 'react-final-form';

const exts = {
  csv: {
    key: 'image/csv',
    value: ['.csv'],
  },
  dgn: {
    key: 'application/x-dgn',
    value: ['.dgn'],
  },
  doc: {
    key: 'application/msword',
    value: ['.doc'],
  },
  docx: {
    key: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    value: ['.doc'],
  },
  dwg: {
    key: 'image/vnd.dxf',
    value: ['.dwg'],
  },
  'dwg+sig': {
    value: ['.dwg+sig'],
  },
  dxf: {
    key: 'application/dxf',
    value: ['.dxf'],
  },
  jpeg: {
    key: 'image/jpeg',
    value: ['.jpeg'],
  },
  jpg: {
    key: 'image/jpg',
    value: ['.jpg'],
  },
  json: {
    key: 'application/json',
    value: ['.json'],
  },
  pdf: {
    key: 'application/pdf',
    value: ['.pdf'],
  },
  png: {
    key: 'image/png',
    value: ['.png'],
  },
  shp: {
    key: 'application/octet-stream',
    value: ['.shp'],
  },
  tiff: {
    key: 'image/tiff',
    value: ['.tiff'],
  },
  txt: {
    key: 'text/plain',
    value: ['.txt'],
  },
  xls: {
    key: 'image/xls',
    value: ['.xls'],
  },
  xlsx: {
    key: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    value: ['.xlsx'],
  },
  xml: {
    key: 'image/xml',
    value: ['.xml'],
  },
  zip: {
    key: 'application/zip',
    value: ['.zip'],
  },
  'zip-архив с dxf':
    'application/x-compressed,application/x-zip-compressed,application/zip',
};

/**
 * Функция получения ошибки доступных форматов для сохранения.
 *
 * @param {Array} arr - Массив.
 * @param {string} message - Сообщение.
 * @returns {string | undefined}
 */
export const getAllowedFormatsMessage = (
  arr = [],
  message = 'Доступные форматы для сохранения: ',
) => {
  if (arr?.length) {
    return message + arr.join(', ').toUpperCase();
  }
};

/**
 * Компонент DndFileUploadFF.
 *
 * @param {object} props - Пропсы.
 * @param {string} props.name - Имя поля.
 * @param {string} [props.errorText] - Текст ошибки.
 * @param {boolean} [props.required] - Флаг обязательности.
 * @param {string} [props.text] - Текст.
 * @param {Array<string>} [props.accept] - Массив форматов.
 * @param {boolean} [props.disabled] - Флаг активности.
 * @param {boolean} [props.multiple] - Флаг множественного выбора файлов.
 * @param {*} [props.allowedMessage] - Any.
 * @param {string} [props.description] - Дополнительное описание.
 * @returns {JSX.Element}
 */
export const DndFileUploadFF = ({
  name,
  errorText,
  required,
  text = 'Переместите файлы-основания или нажмите для выбора',
  accept = [],
  disabled = false,
  multiple = true,
  description = '',
  allowedMessage,
}) => {
  return (
    <Field name={name || 'files'}>
      {({ input, meta }) => {
        const error = getFieldError(meta);

        const { onChange, value } = input;

        const acceptCreate = accept.reduce((obj, str) => {
          const { key = '', value = '' } = exts?.[str];

          if (key && value) {
            obj[key] = value;
          }
          return obj;
        }, {});

        return (
          <DndFileUpload
            add={(files) => {
              if (multiple) {
                const newValue = [...value, ...files].filter((item) => !!item);
                return onChange(newValue);
              }
              onChange(files);
            }}
            multiple={multiple}
            errorText={error || errorText}
            files={value || []}
            accept={acceptCreate}
            allowedFormatsMessage={getAllowedFormatsMessage(
              accept,
              allowedMessage,
            )}
            required={required}
            remove={(file) => {
              const newValue = value.filter((f) => f !== file);
              onChange(newValue);
            }}
            text={text}
            description={description}
            disabled={disabled}
          />
        );
      }}
    </Field>
  );
};
