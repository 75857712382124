// эмуляция условий для поля Балансодержатель в ОДХ
/* eslint-disable */

/**
 *
 * @param root0
 * @param root0.is_orphan_object
 * @param root0.editMode
 * @param root0.card
 */
export const editable = ({ is_orphan_object, editMode, card }) => {
  return (
    !is_orphan_object &&
    editMode &&
    'record_id' in card &&
    card.record_id == null
  );
};
