/* eslint-disable  */
/**
 * Загрузка файлов.
 *
 * @param [filesNotUploaded] - Файлы.
 * @returns Массив ИД файлов.
 */

import { toast } from 'core/uiKit/components/Toast';

import { uploadFiles } from './uploadFiles';

/**
 * Загрузка файлов на сервер и получение массива ids.
 *
 * @param {Array<File>} [filesNotUploaded] - Файлы.
 * @returns {Promise< Array<string>>} Массив ИД файлов.
 */
export const uploadFilesReturnArrayFileIds = async (filesNotUploaded) => {
  if (!filesNotUploaded?.length) return [];

  try {
    const whenCompleted = await uploadFiles(filesNotUploaded);
    const fileIds = whenCompleted.map((item) => item.fileId);

    return fileIds;
  } catch (e) {
    toast.errorDying(e.message || 'Неизвестная ошибка');
    throw Error;
  }
};
