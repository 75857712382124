import { Customer, CUSTOMER_NAME } from 'core/uiKit/preparedInputs';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * Заказчик.
 *
 * @param {*} root0 - Props.
 * @returns {JSX.Element} The JSX element representing the Customer field.
 */
export const CustomerFF = ({ ...otherProps }) => {
  return (
    <Field name={CUSTOMER_NAME}>
      {(props) => {
        return <Customer {...props} {...props.input} {...otherProps} />;
      }}
    </Field>
  );
};

export { CUSTOMER_NAME };

/**
 * Function to define customer ID parameters.
 *
 * @returns {void}
 */
export const customerIdParams = () => {
  addPreparation(CUSTOMER_NAME, (value) => value.id);
};

CustomerFF.propTypes = {

  /**
   * Очищаем инпут с сохранением значения.
   */
  clear: PropTypes.bool,

  /**
   * Быбор темной темы.
   */
  dark: PropTypes.bool,

  /**
   * Заблокировать на редактирование.
   */
  disabled: PropTypes.bool,

  /**
   * Вывод ошибки.
   */
  errorText: PropTypes.string,

  /**
   * Вывод вспомогательного текста.
   */
  helperText: PropTypes.string,

  /**
   * Placeholder.
   */
  placeholder: PropTypes.string,

  /**
   * Вывод информации об обязательном поле.
   */
  required: PropTypes.bool,
};
CustomerFF.defaultProps = {
  disabled: false,
};
