/* eslint-disable  */
/**
 *
 * @param values
 */
export const validateForm = (values) => {
  const errors = {};
  if (!values.waterTypeId) {
    errors.waterTypeId = 'обязательное поле';
  }
  if (!values.area) {
    errors.area = 'обязательное поле';
  }
  return Object.keys(errors).length ? errors : null;
};
