import { AccordionPlantationTab } from '../Accordion.PlantationTab';
import { TableTreesShrubsList } from './Table.TreesShrubsList';

 
/* eslint-disable */
/**
 * Перечётная ведомость
 * @returns {JSX.Element}
 * @constructor
 */
 
export const TreesShrubsList = ({ expanded, onChange }) => {
  return (
    <AccordionPlantationTab
      code={'trees_shrubs_list'}
      title={'Перечётная ведомость'}
      Table={TableTreesShrubsList}
      expanded={expanded}
      onChange={onChange}
    />
  );
};
