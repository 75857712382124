/* eslint-disable  */
import { showAlert } from 'app/actions/dialog';
//import { validateDateFrom } from 'app/components/card/ogh/BasePgmCard/utils/validate';
import DialogAddExport from 'app/components/dialogs/oghRegistry/basePgr/DialogAddExport';
import { GO_TO_EXPORT_ALERT } from 'app/constants/messages';
import { ButtonGreen } from 'core/newComponents/Button';
import { useNavigateRouter } from 'core/utils/router';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { stringify } from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import ExportsTable from './ExportsTable';
import SearchForm from './SearchForm';

const tabStyle = {
  marginLeft: 20,
  marginTop: 20,
};
/**
 * ExportsTab.
 */
class ExportsTab extends React.PureComponent {
  static propTypes = {
    createMode: PropTypes.bool.isRequired,
    editMode: PropTypes.bool.isRequired,
    rootId: PropTypes.number,
  };

  /**
   * Constructor.
   *
   * @param {*} props - A.
   * @returns {void}
   */
  constructor(props) {
    super(props);
    this.state = {
      addExport: false,
      data: {},
    };
  }

  /**
   * Render.
   *
   * @param {*} data - A.
   * @returns {*}
   */
  updateData = (data) =>
    this.setState({
      ...data,
    });

  /**
   * Render.
   *
   * @param {*} dialogName - A.
   * @returns {*}
   */
  handleOpen = (dialogName) => {
    this.setState({ [dialogName]: true });
  };

  /**
   * Render.
   *
   * @returns {*}
   */
  openAddExportDialog = () => this.setState({ addExport: true });

  /**
   * Render.
   *
   * @returns {*}
   */
  closeAddExportDialog = () => this.setState({ addExport: false });

  /**
   * Render.
   *
   * @param {*} formValues - A.
   * @returns {*}
   */
  onAddExportSubmit = (formValues) => {
    const { showAlert, history } = this.props;
    const { data } = this.state;

    const exportsForCurrentReagent = data.table.filter(
      (item) => item.reagent_id === formValues.reagent,
    );

    /**
     * V.
     *
     * @param {*} a - A.
     * @returns {*}
     */
    const validateDateFrom = (a) => a;

    /**
     * Render.
     *
     * @returns {*}
     */
    const error = validateDateFrom(
      formValues.dateFrom,
      exportsForCurrentReagent,
    );

    if (error) {
      showAlert(error);
    } else {
      history.push(`/pgm/docs/export/create?${stringify(formValues)}`);
    }
  };
  /**
   * Render.
   *
   * @returns {*}
   */

  handleExportClick = (id) => {
    const { editMode, showAlert } = this.props;
    if (editMode) {
      showAlert(GO_TO_EXPORT_ALERT);
    } else {
      window.location.history.push(`/pgm/docs/export/${id}`);
    }
  };

  /**
   * Render.
   *
   * @returns {*}
   */
  render() {
    const { createMode, editMode } = this.props;
    if (createMode) {
      return null;
    } else {
      return (
        <div style={tabStyle}>
          <SearchForm
            className="ods-head-form"
            dark={false}
            ref={(form) => {
              if (form) {
                this.form = form;
              }
            }}
            rootId={this.props.rootId}
            updateData={this.updateData}
          />

          <ButtonGreen
            className="mr-3 mb-3 mt-2"
            disabled={editMode}
            onClick={this.openAddExportDialog}
          >
            Добавить
          </ButtonGreen>

          <ExportsTable
            data={this.state.data}
            onExportClick={this.handleExportClick}
          />

          <DialogAddExport
            showCondition={this.state.addExport}
            onCancel={this.closeAddExportDialog}
            onSubmit={this.onAddExportSubmit}
          />
        </div>
      );
    }
  }
}

/**
 * MapStateToProps.
 *
 * @param {*} state - A.
 * @returns {*}
 */
const mapStateToProps = (state) => ({
  createMode: get(state, 'card.mode.createMode', false),
  editMode: get(state, 'card.mode.editMode', false),
  rootId: state.card.object.root_id,
});

const Card = connect(mapStateToProps, { showAlert })(ExportsTab);

// todo: костыль на время. создан когда была переделка на новый роут.
// нужно переделать на переменые из роута

export default (props) => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigateRouter();

  /**
   * @deprecated
   * @see используй navigate
   */
  const history = { push: navigate };

  return <Card {...{ history, location, navigate, params }} {...props} />;
};
