import { useFormValuesCardRF } from 'app/actions/odsObjectActions/useFormValuesCardRF';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { RANDOM_BOOL, RANDOM_ID } from 'core/forTesting/constants';
import { shallow } from 'enzyme';

import { IntensityNumField } from './IntensityNumField';

jest.mock('app/actions/odsObjectActions/useFormValuesCardRF');
jest.mock('app/pages/cardsOgh/hooks/useEditMode');

describe('IntensityNumFieldJestSpec', function () {
  it('test render', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useEditMode.mockReturnValue({ editMode: RANDOM_BOOL });
    useFormValuesCardRF.mockReturnValue({ clean_category_id: RANDOM_ID });
    // Act
    const wrapper = shallow(<IntensityNumField />);
    // Assert
    expect(wrapper.text()).toBe('<IntensityNumRF />');
    expect(wrapper.props()).toStrictEqual({
      disabled: !RANDOM_BOOL,
      helpTitle:
        'Приведенная интенсивность движения к легковому автомобилю (в соответствии с СП 34.13330.2021 «СНиП 2.05.02-85»), автомобилей в час пик суммарно в обоих направлениях (по фактическим измерениям). Заполняется составителем паспорта только для объектов 1-й и 2-й категории для целей уборки или по отдельной заявке Инициатора для объектов других категорий, которые планируется перевести в эти категории.',
      maxLength: 6,
      required: false,
    });
  });
  it.each`
    clean_category_id | expected
    ${1}              | ${true}
    ${2}              | ${true}
    ${3}              | ${false}
  `(
    'test required clean_category_id=$clean_category_id',
    ({ clean_category_id, expected }) => {
      expect.hasAssertions();
      // Arrange (всякие моки)

      useEditMode.mockReturnValue({ editMode: RANDOM_BOOL });
      useFormValuesCardRF.mockReturnValue({ clean_category_id });
      // Act
      const wrapper = shallow(<IntensityNumField />);

      const { required } = wrapper.props();

      // Assert
      expect(required).toBe(expected);
    },
  );
});
