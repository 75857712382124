import { Button } from 'core/uiKit/components/buttons/Button';
import React from 'react';

/**
 * Кнопка разблокировать.
 *
 * @param {object} props - Пропсы.
 * @param {boolean} props.text - Текст Разблокировать.
 * @returns {JSX.Element}
 */
export const UnlockButton = ({ text = 'Разблокировать', ...props }) => {
  return (
    <Button {...props} color="warning" variant={'contained'}>
      {text}
    </Button>
  );
};
