import { DistrictIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/DistrictId.FF';
import { DISTRICT_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/DistrictId';
import React from 'react';
import { NumberParam } from 'use-query-params';

import { filterInput } from '../../filterInputsClasses';

/**
 * Фильтр __Район__.
 *
 * @returns {JSX.Element}
 * @class
 */
export const DistrictIdFilter = () => {
  return <DistrictIdFF className={filterInput} />;
};

export const districtIdParams = {
  [DISTRICT_ID_NAME]: NumberParam,
};
