import { NumberField } from 'core/uiKit/inputs';
import PropTypes from 'prop-types';
import React from 'react';

export const TOTAL_AREA_GEO_NAME = 'totalAreaGeo';

/**
 * Площадь объекта по пространственным координатам, кв.м..
 *
 * @param {*} props - The properties.
 * @returns {JSX.Element}
 */
export const TotalAreaGeo = (props) => {
  return (
    <NumberField
      label={'Площадь объекта по пространственным координатам, кв.м.'}
      {...props}
    />
  );
};

TotalAreaGeo.propTypes = {

  /**
   * Выбор темной темы.
   */
  dark: PropTypes.bool,

  /**
   * Заблокировать на редактирование.
   */
  disabled: PropTypes.bool,

  /**
   * Вывод ошибки.
   */
  errorText: PropTypes.string,

  /**
   * Функция вызова когда выберется итем.
   */
  onChange: PropTypes.func,

  /**
   * Вывод информации об обязательном поле.
   */
  required: PropTypes.bool,

  /**
   * Значение.
   */
  value: PropTypes.any,
};
