import { get, includes } from 'lodash';

const dictFields = ['reagent_kind_id', 'reagent_id'];
const excludeFields = ['balance'];


/* eslint-disable */
export default function ({ reagent_maxstorage_capacity_list: attribute }) {
  let result;
  if (get(attribute, 'table.length')) {
    result = attribute.table.map(({ checked, ...item }) => dictMapper(item));
  } else {
    result = null;
  }
  return {
    reagent_maxstorage_capacity_list: result,
  };
}

const dictMapper = (item) => {
  const mappedItem = {};

  Object.keys(item).forEach((key) => {
    if (includes(dictFields, key)) {
      const dictKey = key.replace('_id', '');
      mappedItem[key] = { [dictKey]: item[key] };
    } else if (!includes(excludeFields, key)) {
      mappedItem[key] = item[key];
    }
  });

  return mappedItem;
};
