import 'assets/styles/registry.out.scss';

import MultiTable from 'app/components/common/table/MultiTable';
import Pagination from 'app/components/common/table/Pagination';
import SortTableBtn from 'app/components/common/table/SortTableBtn';
import cn from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
/* eslint-disable */
import React from 'react';

class OdsRegistryTable extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    main: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.element,
    ]),
    footer: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.element,
    ]),
    columns: PropTypes.array,
    data: PropTypes.object,
    tableConfig: PropTypes.object,
    sort: PropTypes.string,
    onSort: PropTypes.func,
    onLimit: PropTypes.func,
    onPagination: PropTypes.func,
    prependedHeaders: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.element,
    ]),
    isAutoScrollTop: PropTypes.bool,
  };

  render() {
    const {
      columns,
      data,
      tableConfig,
      sort,
      onSort,
      onLimit,
      onPagination,
      isAutoScrollTop,
    } = this.props;

    return (
      <div
        className={cn(
          'd-flex flex-column h-100 overflow-hidden out',
          this.props.className,
        )}
      >
        <MultiTable className="bg-white mb-3" isAutoScrollTop={isAutoScrollTop}>
          <MultiTable.Header>
            <table className="table table-bordered table-striped table-sm m-0">
              <thead>
                <tr>
                  {this.props.prependedHeaders}
                  {columns.map((col) => {
                    return (
                      <th key={`th-${col.id}`}>
                        <div className="d-flex align-items-end">
                          {col.name}
                          {(_.isUndefined(col.isSort) || col.isSort) && (
                            <div className="ml-auto pl-1">
                              <SortTableBtn
                                id={col.id}
                                sort={sort || (tableConfig && tableConfig.sort)}
                                onSort={onSort}
                              />
                            </div>
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
            </table>
          </MultiTable.Header>

          <MultiTable.Main>
            <table className="table table-bordered table-striped table-sm m-0">
              <tbody>{this.props.main}</tbody>
            </table>
          </MultiTable.Main>

          {this.props.footer && (
            <MultiTable.Footer>
              <table className="table table-bordered table-striped table-sm m-0">
                <tfoot>
                  <tr>{this.props.footer}</tr>
                </tfoot>
              </table>
            </MultiTable.Footer>
          )}
        </MultiTable>

        <Pagination
          paginationData={data.meta}
          onLimit={onLimit}
          onPagination={onPagination}
        />
      </div>
    );
  }
}

 
export default OdsRegistryTable;
