import { PrintButton } from 'app/pages/components';
import { printOgh } from 'app/pages/registry/ogh/api';
import { useGetOghType } from 'core/utils/hooks/useGetOghType';
import React from 'react';

 
/**
 * Кнопка печати реестра огх.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - Id типа огх.
 * @param {object} props.prepareFilters - Подготовленные данные для бэка.
 * @returns {JSX.Element} - JSX.
 * @example -----
 */
export const PrintButtonOgh = ({
  typeId,
  prepareFilters,
}) => {
  const oghType = useGetOghType(typeId);

  return (
    <PrintButton
      printFn={printOgh(oghType?.code)}
      prepareFilters={prepareFilters}
    />
  );
};
