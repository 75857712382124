 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
   
/* eslint-disable */
/**
 * Колонка __Ширина в начале, м__.
 *
 * @type {{ accessor: 'width_begin', Header: 'Ширина в начале, м' }}
 * @augments Cell
 */
export const width_begin = {
  accessor: 'width_begin',
  Header: 'Ширина в начале, м',
  sortable: true,
};
