 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Вид покрытия, уточнение__.
 *
 * @type {{ accessor: 'coating_type_name', Header: 'Вид покрытия, уточнение', }}
 * @augments Cell
 */
export const coating_type_name = {
  accessor: 'coating_type_name',
  Header: 'Вид покрытия (уточнение)',
  sortable: true,
};
