import { SIGNATURES_NAME } from 'core/form/finalForm/fields/prepared/FileSignatureCryptoProFF';
import { Validator } from 'core/form/Validator';

/**
 * Функция валидации ЭП.
 *
 * @param {object} values - Значения.
 * @returns {object | null} - Возвращает объект с сообщением об ошибке или null.
 */
export const validateDialogSignActOfCompleted = (values) => {
  const validate = new Validator(values);
  validate.setRequired(
    SIGNATURES_NAME,
    'Выберите сертификат для подписи и нажмите подписать',
  );
  return validate.getErrors();
};
