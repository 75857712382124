/* eslint-disable  */
import get from 'lodash/get';

/**
 *
 * @param value
 */
export const handler = (value = []) => {
  if (!get(value, 'length')) {
    return [''];
  }
  // если выбираем ВСЕ - сбрасываем все остальные выбранные значения
  if (value.indexOf('') > 0) {
    return [''];
  }
  if (Array.isArray(value)) {
    return value.filter((item) => item);
  }
  return value;
};
