import { FieldRF } from 'core/form/reduxForm/helpers/FieldRF';
import {
  IMPROVEMENT_CATEGORY_ID,
  ImprovementCategoryId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/ImprovementCategoryId';

/**
 * ## Жизненная форма.
 *
 * @param {object} otherProps - Пропсы.
 * @returns {JSX.Element}
 */
export const ImprovementCategoryIdRF = (otherProps) => {
  return (
    <FieldRF
      {...otherProps}
      name={IMPROVEMENT_CATEGORY_ID}
      component={ImprovementCategoryId}
    />
  );
};
