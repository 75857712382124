import { filterInput } from 'core/form/filterForm/components/filterInputsClasses';
import { SelectFF } from 'core/form/finalForm/fields/index';
import React from 'react';

import { useGetOptionsSubTypeIdFilter } from './useGetOptions.SubTypeIdFilter';

const SUB_TYPE_ID_NAME = 'sub_type_id';

/**
 * Фильтр __Тип__.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - Тип ОГХ.
 * @param {string} props.label - Лейбл.
 * @returns {JSX.Element}
 */
export const SubTypeIdFilter = ({ typeId, label = 'Тип' }) => {
  const { data } = useGetOptionsSubTypeIdFilter(typeId);

  return (
    <SelectFF
      name={SUB_TYPE_ID_NAME}
      label={label}
      className={filterInput}
      options={data || []}
    />
  );
};
