import { hideDialog, showError } from 'app/actions/dialog';
import { post } from 'app/api/crud/post';
// import * as registryApi from 'app/api/registry';
import * as techApi from 'app/api/tech';
import * as types from 'app/constants/actionTypes';
import { getApiPath } from 'app/utils/api/config';
import { log } from 'core/utils/log';
import DateFormat from 'dateformat';
// import { createAction } from 'redux-actions';

// export const apiGetTechnologyRegistry = createAction(
//   types.API_GET_TECHNOLOGY_REGISTRY,
//   function (data) {
//     return {
//       promise: registryApi.fetchTechnologyRegistry(data),
//     };
//   },
// );

/**
 * Функция changeTree.
 *
 * @param {*} selectIds - Неизвестные параметры.
 * @param {*} pathObjectRootId - Неизвестные параметры.
 * @param {*} treePaths - Неизвестные параметры.
 * @returns {*}
 */
export function changeTree(selectIds, pathObjectRootId, treePaths) {
  return function (dispatch) {
    // const part1 = treePaths.filter(p => !p.startsWith(`${pathObjectRootId}.`)).map(i => `${i}`)
    const part2 = selectIds.map((item) => `${pathObjectRootId}.${item}`);
    // const newTreePaths = [...part1, ...part2]
    dispatch(
      mergeTechTree({
        pathRoot: pathObjectRootId,
        treePathRoot: JSON.stringify(treePaths),
        treePathRootBranch: JSON.stringify(part2),
      }),
    );
  };
}

/**
 * Функция.
 *
 * @param {*} newTreePaths - Неизвестные параметры.
 * @returns {*}
 */
export function setTechTreePaths(newTreePaths) {
  return {
    object: newTreePaths,
    type: types.SET_TECH_TREE_PATHS,
  };
}

/**
 * Функция.
 *
 * @param {*} object - Неизвестные параметры.
 * @returns {*}
 */
export function requestRootCard(object) {
  return {
    object,
    type: types.REQUEST_CARD,
  };
}

/**
 * Функция.
 *
 * @param {*} date - Неизвестные параметры.
 * @returns {*}
 */
export function setStartDate(date) {
  return {
    date,
    type: types.SET_START_DATE,
  };
}

/**
 * Функция.
 *
 * @param {*} requestType - Неизвестные параметры.
 * @param {*} reasonCode - Неизвестные параметры.
 * @returns {*}
 */
export function setTechEditType(requestType, reasonCode) {
  return {
    reasonCode,
    requestType,
    type: types.SET_TECH_EDIT_TYPE,
  };
}

/**
 * Функция.
 *
 * @param {*} object - Неизвестные параметры.
 * @returns {*}
 */
export function receiveRootCard(object) {
  return {
    object,
    type: types.RECEIVE_CARD,
  };
}

/**
 * Функция.
 *
 * @param {*} addition - Неизвестный параметр.
 * @returns {*}
 */
function receiveErrorRootCard(addition) {
  return {
    type: types.RECEIVE_ERROR_CARD,
    ...addition,
  };
}

/**
 * Функция.
 *
 * @param {*} code - Код.
 * @param {*} startDate - Стартовая дата.
 * @returns {*}
 */
export function fetchPreviewTechCard(code, startDate) {
  const date = DateFormat(startDate, 'ddmmyyyyHHMM');
  return (dispatch) => {
    dispatch(requestRootCard(null));
    return fetch(`${getApiPath()}/card/preview/tech/${code}/${date}`, {
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((json) => {
        dispatch(receiveRootCard(json));
        dispatch(setTechTreePaths(json.tree_paths));
      });
  };
}

/**
 * Функция.
 *
 * @param {*} id - Айди.
 * @returns {function(*): Promise<*>}
 */
export const fetchTechnik = (id) => async (dispatch) => {
  dispatch(requestRootCard(id));

  const response = await techApi.fetchTech(id);
  const json = await response.json();

  if (response.status === 403) {
    const errorMessage = json.errors.toString();
    dispatch(
      showError(errorMessage, () => {
        log.banana('обработать dispatch(goBack())');
        // dispatch(goBack());
        dispatch(hideDialog());
      }),
    );
    dispatch(receiveErrorRootCard(json));
  } else {
    dispatch(receiveRootCard(json));
    dispatch(setTechTreePaths(json.tree_paths));
  }

  return json;
};

/**
 * Функция.
 *
 * @param {*} operationId - Неизвестный параметр.
 * @returns {(function(*): Promise<void>)|*}
 */
export const fetchMatchingTechnik = (operationId) => async (dispatch) => {
  dispatch(requestRootCard(operationId));
  const response = await techApi.fetchMatchingTech(operationId);
  const json = await response.json();

  if (response.status === 403) {
    const errorMessage = json.errors.toString();
    dispatch(
      showError(errorMessage, () => {
        log.banana('обработать dispatch(goBack())');
        // dispatch(goBack());
        dispatch(hideDialog());
      }),
    );
    dispatch(receiveErrorRootCard(json));
  } else {
    dispatch(receiveRootCard(json));
    dispatch(setTechTreePaths(json.tree_paths));
  }
};

/**
 * Функция.
 *
 * @param {*} data - Неизвестный параметр.
 * @returns {*}
 */
export function mergeTechTree(data) {
  return (dispatch) => {
    return fetch(`${getApiPath()}/tech/tree/merge`, {
      body: JSON.stringify(data),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      method: 'POST',
    })
      .then((response) => {
        return response.json().catch((err) => {
          log.error(`'${err}' happened, but no big deal!`);
          return [];
        });
      })
      .then((paths) => {
        dispatch(setTechTreePaths([...paths]));
      });
  };
}

/**
 * Функция.
 *
 * @param {*} data - Неизвестный параметр.
 * @returns {*}
 */
export function fetchTechTree(data) {
  return (dispatch) => {
    dispatch(requestTree(data));
    return fetch(`${getApiPath()}/tech/tree/`, {
      body: JSON.stringify(data),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      method: 'POST',
    })
      .then((response) => {
        return response.json().catch((err) => {
          log.error(`'${err}' happened, but no big deal!`);
          return [];
        });
      })
      .then((json) => {
        const tree = json;
        dispatch(receiveTechTree(tree));
        // При получении дерева нам нужно определить объекты, которые в него входят. Запоминающая функция для таких объектов это setTechTreePaths
        const paths = new Set();

        /**
         * Функция.
         *
         * @param {*} ev - Неизвестный параметр.
         * @returns {*}
         */
        function dfs(ev) {
          (ev.element_list || []).forEach((it) => {
            dfs(it);
          });
          if (ev.path_object_root_id) {
            paths.add(ev.path_object_root_id);
          }
        }
        dfs(tree);
        dispatch(setTechTreePaths([...paths]));
      });
  };
}

/**
 * Функция.
 *
 * @param {*} object - Объект.
 * @returns {*}
 */
export function receiveTechTree(object) {
  return {
    object,
    type: types.RECEIVE_TECH_TREE,
  };
}

/**
 * Функция.
 *
 * @param {*} data - Объект.
 * @returns {*}
 */
export function requestTree(data) {
  return {
    data,
    type: types.REQUEST_TREE,
  };
}

/**
 * Функция.
 *
 * @returns {*}
 */
export function requestDeleteCard() {
  return {
    type: types.REQUEST_DELETE_CARD,
  };
}

/**
 * Функция.
 *
 * @param {*} text - Text.
 * @returns {*}
 */
export function receiveErrorDeleteCard(text) {
  return {
    message: text,
    type: types.RECEIVE_ERROR_DELETE_CARD,
  };
}

/**
 * Функция.
 *
 * @param {*} address - Адрес.
 * @param {*} text - Текст.
 * @returns {*}
 */
export function receiveDeleteCard(address, text) {
  return {
    message: text,
    relocate: address,
    type: types.RECEIVE_DELETE_CARD,
  };
}

/**
 * Функция.
 *
 * @param {*} data - Данные.
 * @returns {function(*): Promise<*>}
 */
export const deleteTechnology = (data) => {
  return (dispatch) => {
    dispatch(requestDeleteCard());
    return post(`/tech/delete`, data)
      .then((res) => {
        return dispatch(
          receiveErrorDeleteCard(`Заявка №${res} отправлена на согласование`),
        );
      })
      .catch((err) => {
        return dispatch(receiveErrorDeleteCard(err.message || err.errors[0]));
      });
  };
};
