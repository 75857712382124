/* eslint-disable */

import { objectMatching } from 'app/actions/adminActions';
import { store } from 'app/configure';
import { IMPROVEMENT_OBJECT, ODH, OZN, YARD } from 'app/constants/oghTypes';
import { showIfViewOghMatchingRegistryGranted } from 'app/grants';
import { isBasePgm } from 'app/utils/checkOghTypeId';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import $ from 'jquery';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const tabsStyle = {
  paddingLeft: '24px',
};

/**
 * Таб для свойств .
 *
 * @param {object} props - Тип ОГХ.
 * @returns {JSX.Element}
 */
export function Tabs(props) {
  return (
    <div className="tabs" key="inner-prop-tabs" style={tabsStyle}>
      <ul>
        {props.tabs.map(({ id, name, Component }, index) => {
          return (
            <li className={!index ? 'active' : ''} id={id} key={id}>
              {Component ? <Component>{name}</Component> : name}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
};

// NOTE: Скрыт BASE_PGM в рамках задачи ODS-5963
const typesWithApproving = [ODH, YARD, IMPROVEMENT_OBJECT, OZN];

/**
 * Таб для всех объектов.
 *
 * @param {number} typeId - Тип ОГХ.
 * @returns {JSX.Element}
 */
export function objectTabs(typeId) {
  const withApprovingTab = typesWithApproving.some((i) => i === typeId);

  const withOperationsTab = isBasePgm(typeId);
  const withExportsTab = isBasePgm(typeId);
  const withLandscapingTab = [YARD, OZN, ODH].includes(typeId);

  return (
    <div className="tabs" key="block-props-tab" style={tabsStyle}>
      <ul>
        <li className="active" id="props-tab">
          Свойства
        </li>
        <VersionsTab />
        {withApprovingTab && <ApprovingTab typeId={typeId} />}
        {withOperationsTab && <OperationsTab />}
        {withExportsTab && <ExportsTab />}
        {withApprovingTab && <li id="passportization-tab">Паспортизация</li>}
        {withApprovingTab && withLandscapingTab && (
          <li id="ods-landscaping-tab">Благоустройство</li>
        )}
      </ul>
    </div>
  );
}

/**
 * Таб Версий.
 *
 * @returns {JSX.Element|null}
 */
export const VersionsTab = () => {
  const { objectId } = useGetObjectId();
  return objectId ? <li id="versions-tab">версии</li> : null;
};

export const ApprovingTab = showIfViewOghMatchingRegistryGranted(function () {
  const { objectId } = useGetObjectId();
  return objectId ? <li id="approving-tab">Согласование</li> : null;
});

/**
 * Таб Журнала прихода.
 *
 * @returns {JSX.Element|null}
 */
export const OperationsTab = () => {
  const { objectId } = useGetObjectId();
  return objectId ? (
    <li id="operations-tab">Журнал прихода / отпуска</li>
  ) : null;
};

/**
 * Таб Графики перемещения.
 *
 * @returns {JSX.Element|null}
 */
export const ExportsTab = () => {
  const { objectId } = useGetObjectId();
  return objectId ? <li id="exports-tab">Графики перемещения</li> : null;
};

/**
 * Таб mountTabs.
 *
 * @returns {void}
 */
export function mountTabs() {
  $('.tabs')
    .css('cursor', 'pointer')
    .map(function () {
      return $(this).find('li');
    })
    .each((i, tabs) => {
      tabs.each((j, tab) => {
        onTabClick(j, tab, tabs);
      });
    });
}

/**
 * Получить контент таба.
 *
 * @param {*} $tab - $.
 * @returns {string}
 */
export function getTabContentByTab($tab) {
  const tabId = $tab.attr('id');
  return $(`#${tabId.substr(0, tabId.length - 4)}`);
}

/**
 * Скрыть таб.
 *
 * @param {object} tabs - Табы.
 * @returns {void}
 */
export function hideTabs(tabs) {
  tabs.each((i, tab) => {
    const $tab = $(tab);
    $tab.removeClass('active');
    const tabW = getTabContentByTab($tab);
    tabW.addClass('d-none');
    tabW.removeClass('d-block');
  });
}

/**
 * Показать таб.
 *
 * @param {*} $tab - Таб.
 * @returns {void}
 */
export function activateSelectedTab($tab) {
  $tab.addClass('active');
  const tabW = getTabContentByTab($tab);
  tabW.addClass('d-block');
  tabW.removeClass('d-none');
  $(window).resize(); // чтобы сработали события для скрытых элементов
}

/**
 * Клик по табу.
 *
 * @param {number} i - Индекс.
 * @param {*} tab - Таб.
 * @param {object} tabs - Табы.
 * @returns {void}
 */
export function onTabClick(i, tab, tabs) {
  const $tab = $(tab);

  $tab.click(() => {
    if (tab.id === 'approving-tab') {
      const state = store.getState();
      const card = get(state, 'card.object');
      const selected = get(state, 'tree.object');
      const createMode = get(state, 'card.mode.createMode');

      if (!createMode) {
        store.dispatch(
          objectMatching({
            id: (selected || card).record_id,
            type: 'ogh',
            typeId: (selected || card).type_id,
          }),
        );
      }
    }
    hideTabs(tabs);
    activateSelectedTab($tab);
  });
}

/**
 * Сбросить табы.
 *
 * @returns {void}
 */
export function resetTabs() {
  $('.tabs').each((i, tabs) => {
    $(tabs)
      .find('li')
      .each((j, tab) => {
        const $tab = $(tab);
        if (j === 0) {
          activateSelectedTab($tab);
        } else {
          hideTabs($tab);
        }
      });
  });
}
