import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { OZN, YARD } from 'app/constants/oghTypes';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { CheckboxRF } from 'core/form/reduxForm/fields';
import React from 'react';

/**
 * Checkbox от хедера __Требует дополнения по зелёным насаждениям__.
 *
 * @returns
 */
export const CheckboxGreeningComponent = () => {
  const { typeId } = useGetCurrentCard();
  const { editMode } = useMode();
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();

  return [OZN, YARD].includes(typeId) ? (
    <CheckboxRF
      label={'Требует дополнения по зелёным насаждениям'}
      name={'greeningAddition'}
      disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
    />
  ) : null;
};
