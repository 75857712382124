import { editCardClearDialog } from 'app/actions/odsObjectActions';
import { uploadFile } from 'app/actions/uploadActions';
import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { elementFormGrid } from 'app/components/card/common/grid';
import { parseDate } from 'app/components/card/common/parse';
import Table from 'app/components/common/table/Table';
import DialogWithTableControls from 'app/components/dialogs/common/DialogWithTableControls';
import { REQUIRED_FIELD_MESSAGE } from 'app/constants/messages';
import Checkbox from 'core/newComponents/Checkbox';
import DateFormat from 'dateformat';
import { get } from 'lodash';
import PropTypes from 'prop-types';
/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';

import dialogFields from './dialogFields';

class RepairsPlanTable extends React.Component {
  static propTypes = {
    input: PropTypes.object,
  };

  onAdd = () => {
    const table = this.getTable();
    this.props.input.onChange({
      table,
      showDialog: true,
    });
  };

  onCancel = () => {
    const table = this.getTable();
    this.props.input.onChange({
      table,
      showDialog: false,
    });
    this.clearDialog();
  };

  onCheckCheckbox(event, value, index) {
    const table = this.getTable();
    const character = {
      ...table[index],
      checked: value,
    };

    const newTable = table.slice();
    newTable.splice(index, 1, character);

    this.props.input.onChange({
      table: newTable,
      showDialog: false,
    });
  }

  onRemove = () => {
    const table = this.getTable();
    const newTable = table.filter((item) => !item.checked);
    this.props.input.onChange({
      table: newTable,
      showDialog: false,
    });
  };

  onSubmit = (formValues) => {
    const { props } = this;
    const { file, ...values } = formValues;

    Promise.resolve(file)
      .then((file) => {
        return file ? uploadFile(file) : null;
      })
      .then((result) => {
        const files = result
          ? {
              file_id: result.fileId,
              file_name: file.name,
            }
          : null;

        ['date_start', 'date_end'].forEach((key) => {
          if (values[key]) {
            values[key] = DateFormat(values[key], 'yyyy-mm-dd');
          }
        });

        props.input.onChange({
          table: this.getTable().concat({
            ...values,
            file_list: [
              {
                files,
              },
            ],
          }),
          showDialog: false,
        });

        this.clearDialog();
      });
  };

  getContainerStyle() {
    return {
      marginBottom: '15px',
      ...this.props.style,
    };
  }

  getTable() {
    const { input } = this.props;
    return (input.value && input.value.table) || [];
  }

  clearDialog() {
    this.props.dispatch(editCardClearDialog());
  }

  createColumns(cols) {
    return cols.map((item) => ({
      sortable: false,
      databaseName: null,
      cells: [],
      ...item,
    }));
  }

  customValidate = ({ name, date_start, date_end }) => {
    const errors = {};
    if (!name) {
      errors.name = REQUIRED_FIELD_MESSAGE;
    }
    if (date_start && date_end && date_end < date_start) {
      errors.date_end = 'меньше даты начала работ';
    }
    return errors;
  };

  render() {
    const { props } = this;
    const headerHeight = 60;

    const resultTable = {
      columns: this.createColumns([
        {
          key: 'name',
          screenName: 'Наименование',
        },
        {
          key: 'date_start',
          screenName: 'Дата начала работ',
        },
        {
          key: 'date_end',
          screenName: 'Дата окончания работ',
        },
        {
          key: 'property',
          screenName: 'Характеристика (объемы)',
        },
        {
          key: 'file_list[0].files.file_name',
          screenName: 'Техническое задание / проект благоустройства',
        },
      ]),
    };

    const table = this.getTable();
    const showDialog =
      (props.input.value && props.input.value.showDialog) || false;
    const dateFormatter = new Intl.DateTimeFormat('ru');

    table.forEach((item) => {
      resultTable.columns.forEach((column) => {
        if (column.key === 'date_start' || column.key === 'date_end') {
          const value =
            column.key in item &&
            item[column.key] !== null &&
            item[column.key] !== ''
              ? dateFormatter.format(parseDate(item[column.key]))
              : '';
          column.cells.push(value);
        } else {
          column.cells.push(get(item, column.key));
        }
      });
    });

    if (!props.disabled) {
      resultTable.columns.unshift({
        key: 'delete',
        screenName: '',
        sortable: false,
        databaseName: null,
        cells: [],
      });
      table.forEach((item, index) => {
        resultTable.columns[0].cells.push(
          <Checkbox
            checked={item.checked || false}
            onChange={(event, value) =>
              this.onCheckCheckbox(event, value, index)
            }
          />,
        );
      });
    }

    return (
      <div style={this.getContainerStyle()}>
        <DialogWithTableControls
          arrayFields={dialogFields}
          className="Dialog-Add-Repairs-Plan-Info_Odh"
          customValidate={this.customValidate}
          enableRemove={table.some((item) => item.checked)}
          inEdit={!props.disabled}
          nameDialog="add_repairs_plan_info"
          renderFunction={elementFormGrid}
          showCondition={showDialog}
          title="Добавить сведения о проектах"
          onAdd={this.onAdd}
          onCancel={this.onCancel}
          onRemove={this.onRemove}
          onSubmit={this.onSubmit}
        />
        <Table
          headerHeight={headerHeight}
          height={table.length * 50 + headerHeight}
          table={resultTable}
          width={1500 + (props.disabled ? 0 : 50)}
          widths={(name) => (name === '' ? 50 : 300)}
        />
      </div>
    );
  }
}

const Comp = connect()(RepairsPlanTable);


 
export default ({ disabled, ...props }) => {
  const isCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();
  return (
    <Comp {...props} disabled={disabled || isCurrentObjectIsExternalSystem} />
  );
};
