import { post } from 'app/api/crud/post';
import { uploadFileReturnArrStorageId } from 'app/api/requests/uploadFileReturnArrStorageId';
import { RANDOM_ID } from 'core/forTesting/constants';

import { externalReject } from './api';

jest.mock('app/api/crud/post');
jest.mock('app/api/requests/uploadFileReturnArrStorageId');

 
describe('api RejectExternalObject', () => {
   
  describe('uploadFileReturnArrStorageId', () => {
    it('вызывается uploadFileReturnArrStorageId при files:[id]', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      const storageId = RANDOM_ID;

      // Act
      externalReject({ files: [storageId] });
      // Assert
      expect(uploadFileReturnArrStorageId).toHaveBeenCalledWith([storageId]);
    });

    it('вызывается uploadFileReturnArrStorageId при files:[]', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)

      // Act
      externalReject({ files: [] });
      // Assert
      expect(uploadFileReturnArrStorageId).toHaveBeenCalledWith([]);
    });

    it('вызывается uploadFileReturnArrStorageId при нет files', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)

      // Act
      externalReject({});
      // Assert
      expect(uploadFileReturnArrStorageId).toHaveBeenCalledWith([]);
    });

    it('вызывается post', async () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      const storageId = RANDOM_ID;
      uploadFileReturnArrStorageId.mockResolvedValue([storageId]);
      // Act

      await externalReject({ other: 2 });
      // Assert
      expect(post).toHaveBeenCalledWith('/external/reject', {
        fileIds: [storageId],
        other: 2,
      });
    });

    it('вызывается post когда fileIds пустой []', async () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      uploadFileReturnArrStorageId.mockResolvedValue([]);
      // Act

      await externalReject({ other: 2 });
      // Assert
      expect(post).toHaveBeenCalledWith('/external/reject', {
        other: 2,
      });
    });
    it('вызывается post когда fileIds нету', async () => {
      expect.hasAssertions();
      // Arrange (всякие моки)

      uploadFileReturnArrStorageId.mockResolvedValue();
      // Act

      await externalReject({ other: 2 });
      // Assert
      expect(post).toHaveBeenCalledWith('/external/reject', {
        other: 2,
      });
    });
  });
});
