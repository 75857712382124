/* eslint-disable  */
import { Select } from 'core/uiKit/inputs/selects/Select';
import { log } from 'core/utils/log';
import PropTypes from 'prop-types';
import React from 'react';

import { handler } from './handler';

/**
 * Компонент МультиСелекта.
 *
 * @param {object} props - Пропсы.
 * @param {string} [props.className] - Класс.
 * @param {boolean} [props.dark] - Флаг переключения темя.
 * @param {string} [props.errorText] - Текст ошибки.
 * @param {string} [props.helperText] - Текс помощи.
 * @param {string} [props.label] - Лейбл.
 * @param {boolean} [props.multiple] - Хопа.
 * @param {*} [props.optionKey] - Ключ.
 * @param {*} [props.optionName] - Жопа.
 * @param {Array} [props.options] - Жопа.
 * @param {string} [props.value] - Значение.
 * @param {*} [props.meta] - Жопа.
 * @param {boolean} [props.required] - Флаг обязательности поля.
 * @param {Function} [props.onChange] - Функция изменения значения.
 * @param {Function} [props.withChange] - Функция изменения других полей.
 * @param {boolean} [props.disabled] - Флаг редактирования.
 * @param {*} [props.native] - Жопа.
 * @param {*} [props.withAll] - Жопа.
 * @param {Function} [props.filter] - Функция фильтрации.
 * @returns {React.ReactComponentElement} - Жопа.
 */
export const MultiSelect = ({ onChange, value, ...props }) => {
  /**
   * FN.
   *
   * @param {Array} value - Value.
   * @returns {void}
   */
  const handleChange = (value = []) => {
    onChange(handler(value));
  };

  /**
   * Функция проверки на массив.
   *
   * @returns {Array}
   */
  const checkArray = () => {
    if (Array.isArray(value)) {
      return value.filter(Boolean);
    }
    log.warn('value is not Array ', value);
    return [];
  };

  return (
    <Select
      MenuProps={
        {
          // anchorOrigin: {
          //   horizontal: 'left',
          //   vertical: 'bottom',
          // },
          // getContentAnchorEl: null,
        }
      }
      {...props}
      _multiple={true}
      value={checkArray()}
      onChange={handleChange}
    />
  );
};

MultiSelect.propTypes = {
  /**
   * Выбор темной темы.
   */
  dark: PropTypes.bool,

  /**
   * Заблокировать на редактирование.
   */
  disabled: PropTypes.bool,

  /**
   * Вывод ошибки.
   */
  errorText: PropTypes.string,

  /**
   * Вывод вспомогательного текста.
   */
  helperText: PropTypes.string,
  label: PropTypes.string,

  /**
   * Имя селекта в форме.
   */
  name: PropTypes.string,

  /**
   * Функция вызова когда выберется итем.
   */
  onChange: PropTypes.func,

  /**
   * Имя ключа по которому будет выбираться значение.
   */
  optionKey: PropTypes.string,

  /**
   * Имя ключа по которому будет отображаться значение.
   */
  optionName: PropTypes.string,

  /**
   * Массив итемов.
   */
  options: PropTypes.array,

  /**
   * Placeholder.
   */
  placeholder: PropTypes.string,

  /**
   * Вывод информации об обязательном поле.
   */
  required: PropTypes.bool,

  /**
   * Значение.
   */
  value: PropTypes.array,
};

MultiSelect.defaultProps = {
  dark: false,
  disabled: false,
  errorText: '',
  helperText: '',
  label: 'select label',
  name: 'select',

  /**
   *
   * @param _
   */
  onChange: (_) => _,
  optionKey: 'id',
  optionName: 'name',
  options: [],
  placeholder: '',
  required: false,
  value: [],
};
