/* eslint-disable */
import React from 'react';

import { filterInput } from '../filterInputsClasses';
import {
  LIFE_FORM_TYPE_NAME,
  LifeFormTypeFF,
} from 'core/form/finalForm/fields';
import { NumberParam } from 'use-query-params';

 
export { LIFE_FORM_TYPE_NAME };
 
 
/**
 *     Жизненная форма
 *     plantationTypeId
 */
 
export const LifeFormTypeFilter = (props) => (
  <LifeFormTypeFF {...props} className={filterInput} />
);
 
export const lifeFormTypeParams = {
  [LIFE_FORM_TYPE_NAME]: NumberParam,
};
