/* eslint-disable */
import React from 'react';
import { StringParam } from 'use-query-params';

import { TextFieldFF } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
export const GPS_CODE_NAME = 'gpsCodeName';

 
 
/**
 * Код устройства БНСО -> gps_code [поле строка, или если пусто - все]
 */
 
export const GpsCodeNameFilter = () => {
  return (
    <TextFieldFF
      className={filterInput}
      label={'Код устройства БНСО'}
      name={GPS_CODE_NAME}
    />
  );
};

 
export const gpsCodeNameParams = {
  [GPS_CODE_NAME]: StringParam,
};
