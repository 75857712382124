export const geometry_type = [
  {
    attribute: [
      {
        geometry_type: 'POLYGON',
        template: {},
      },
      {
        geometry_type: 'MULTIPOLYGIN',
        template: {},
      },
    ],
    ogh_object_type_id: 1,
  },
  {
    attribute: [
      {
        geometry_type: 'POLYGON',
        template: {},
      },
      {
        geometry_type: 'MULTIPOLYGON',
        template: {},
      },
    ],
    ogh_object_type_id: 5,
  },
  {
    attribute: [
      {
        geometry_type: 'POINT',
        template: {},
      },
    ],
    ogh_object_type_id: 6,
  },
  {
    attribute: [
      {
        geometry_type: 'POLYGON',
        template: {},
      },
      {
        geometry_type: 'POINT',
        template: {},
      },
      {
        geometry_type: 'MULTIPOLYGON',
        template: {},
      },
    ],
    ogh_object_type_id: 7,
  },
  {
    attribute: [
      {
        geometry_type: 'POINT',
        template: {},
      },
    ],
    ogh_object_type_id: 8,
  },
  {
    attribute: [
      {
        geometry_type: 'POLYGON',
        template: {},
      },
      {
        geometry_type: 'POINT',
        template: {},
      },
    ],
    ogh_object_type_id: 9,
  },
  {
    attribute: [
      {
        geometry_type: 'POINT',
        template: {},
      },
    ],
    ogh_object_type_id: 10,
  },
  {
    attribute: [
      {
        geometry_type: 'LINESTRING',
        template: {},
      },
      {
        geometry_type: 'MULTILINESTRING',
        template: {},
      },
      {
        geometry_type: 'POINT',
        template: {},
      },
    ],
    ogh_object_type_id: 11,
  },
  {
    attribute: [
      {
        geometry_type: 'POINT',
        template: {},
      },
    ],
    ogh_object_type_id: 12,
  },
  {
    attribute: [
      {
        geometry_type: 'POLYGON',
        template: {},
      },
      {
        geometry_type: 'MULTIPOLYGON',
        template: {},
      },
    ],
    ogh_object_type_id: 13,
  },
  {
    attribute: [
      {
        geometry_type: 'POLYGON',
        template: {},
      },
      {
        geometry_type: 'MULTIPOLYGON',
        template: {},
      },
    ],
    ogh_object_type_id: 14,
  },
  {
    attribute: [
      {
        geometry_type: 'LINESTRING',
        template: {},
      },
    ],
    ogh_object_type_id: 15,
  },
  {
    attribute: [
      {
        geometry_type: 'POLYGON',
        template: {},
      },
      {
        geometry_type: 'MULTIPOLYGON',
        template: {},
      },
    ],
    ogh_object_type_id: 16,
  },
  {
    attribute: [
      {
        geometry_type: 'POLYGON',
        template: {},
      },
      {
        geometry_type: 'MULTIPOLYGON',
        template: {},
      },
      {
        geometry_type: 'POINT',
        parent_ogh_object_type_list: [42],
        template: {},
      },
    ],
    ogh_object_type_id: 17,
  },
  {
    attribute: [
      {
        geometry_type: 'POLYGON',
        template: {},
      },
    ],
    ogh_object_type_id: 18,
  },
  {
    attribute: [
      {
        geometry_type: 'POLYGON',
        template: {},
      },
    ],
    ogh_object_type_id: 19,
  },
  {
    attribute: [
      {
        geometry_type: 'POLYGON',
        template: {},
      },
      {
        geometry_type: 'MULTIPOLYGON',
        template: {},
      },
    ],
    ogh_object_type_id: 20,
  },
  {
    attribute: [
      {
        geometry_type: 'LINESTRING',
        template: {},
      },
      {
        geometry_type: 'MULTILINESTRING',
        template: {},
      },
    ],
    ogh_object_type_id: 21,
  },
  {
    attribute: [
      {
        geometry_type: 'LINESTRING',
        template: {},
      },
    ],
    ogh_object_type_id: 22,
  },
  {
    attribute: [
      {
        geometry_type: 'POLYGON',
        template: {},
      },
      {
        geometry_type: 'MULTIPOLYGON',
        template: {},
      },
    ],
    ogh_object_type_id: 23,
  },
  {
    attribute: [
      {
        geometry_type: 'POLYGON',
        template: {},
      },
      {
        geometry_type: 'MULTIPOLYGON',
        template: {},
      },
    ],
    ogh_object_type_id: 24,
  },
  {
    attribute: [
      {
        geometry_type: 'POLYGON',
        template: {},
      },
      {
        geometry_type: 'MULTIPOLIGON',
        template: {},
      },
    ],
    ogh_object_type_id: 25,
  },
  {
    attribute: [
      {
        geometry_type: 'POLYGON',
        template: {
          container_type_id: '5545526',
        },
      },
      {
        geometry_type: 'POLYGON',
        template: {
          container_type_id: '1987641',
        },
      },
      {
        geometry_type: 'POINT',
        template: {
          container_type_id: '3',
        },
      },
      {
        geometry_type: 'POINT',
        template: {
          container_type_id: '4',
        },
      },
      {
        geometry_type: 'POLYGON',
        template: {
          container_type_id: '1987642',
        },
      },
    ],
    ogh_object_type_id: 26,
  },
  {
    attribute: [
      {
        geometry_type: 'POLYGON',
        template: {},
      },
      {
        geometry_type: 'MULTIPOLYGON',
        template: {},
      },
    ],
    ogh_object_type_id: 27,
  },
  {
    attribute: [
      {
        geometry_type: 'POLYGON',
        template: {},
      },
      {
        geometry_type: 'MULTIPOLYGON',
        template: {},
      },
    ],
    ogh_object_type_id: 28,
  },
  {
    attribute: [
      {
        geometry_type: 'POLYGON',
        template: {},
      },
      {
        geometry_type: 'MULTIPOLYGON',
        template: {},
      },
    ],
    ogh_object_type_id: 29,
  },
  {
    attribute: [
      {
        geometry_type: 'POLYGON',
        template: {},
      },
      {
        geometry_type: 'MULTIPOLYGON',
        template: {},
      },
    ],
    ogh_object_type_id: 30,
  },
  {
    attribute: [
      {
        geometry_type: 'POLYGON',
        template: {},
      },
      {
        geometry_type: 'MULTIPOLYGON',
        template: {},
      },
    ],
    ogh_object_type_id: 31,
  },

  {
    attribute: [
      {
        geometry_type: 'POLYGON',
        template: {},
      },
    ],
    ogh_object_type_id: 32,
  },
  {
    attribute: [
      {
        geometry_type: 'POINT',
        template: {
          maf_type_level1_id: 13,
          maf_type_level2_id: 1,
          maf_type_level3_id: 6,
          unit_id: 5,
        },
      },
      {
        geometry_type: 'LINESTRING',
        template: {
          maf_type_level1_id: 29,
          maf_type_level2_id: 1,
          maf_type_level3_id: 30,
          unit_id: 3,
        },
      },
    ],
    ogh_object_type_id: 37,
  },

  {
    attribute: [
      {
        geometry_type: 'POINT',
        template: {
          engineer_building_type_id: '10',
        },
      },
      {
        geometry_type: 'POINT',
        template: {
          engineer_building_type_id: '3',
        },
      },
      {
        geometry_type: 'POLYGON',
        template: {
          engineer_building_type_id: '1',
        },
      },
      {
        geometry_type: 'POLYGON',
        template: {
          engineer_building_type_id: '2',
        },
      },
      {
        geometry_type: 'POLYGON',
        template: {
          engineer_building_type_id: '4',
        },
      },
      {
        geometry_type: 'POLYGON',
        template: {
          engineer_building_type_id: '5',
        },
      },
      {
        geometry_type: 'POLYGON',
        template: {
          engineer_building_type_id: '6',
        },
      },
      {
        geometry_type: 'POLYGON',
        template: {
          engineer_building_type_id: '7',
        },
      },
      {
        geometry_type: 'POLYGON',
        template: {
          engineer_building_type_id: '8',
        },
      },
      {
        geometry_type: 'POLYGON',
        template: {
          engineer_building_type_id: '10',
        },
      },
      {
        geometry_type: 'POLYGON',
        template: {
          engineer_building_type_id: '9',
        },
      },
      {
        geometry_type: 'POLYGON',
        template: {
          engineer_building_type_id: '3',
        },
      },
    ],
    ogh_object_type_id: 53,
  },
  {
    attribute: [
      {
        geometry_type: 'LINESTRING',
        template: {},
      },
    ],
    ogh_object_type_id: 54,
  },
  {
    attribute: [
      {
        geometry_type: 'LINESTRING',
        template: {
          unit_id: '5',
          vertical_landscaping_ref_type_id: '2',
        },
      },
      {
        geometry_type: 'POINT',
        template: {},
      },
      {
        geometry_type: 'LINESTRING',
        template: {
          unit_id: '5',
          vertical_landscaping_ref_type_id: '1',
        },
      },
    ],
    ogh_object_type_id: 55,
  },
];
