import { get } from 'app/api/crud/get';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';
import React from 'react';

/**
 * Виджет погода.
 *
 * @returns {JSX}
 */
const Weather = () => {
  const { data: weatherData } = useQueryAdaptor(['weather'], () =>
    get('/weather/get'),
  );

  const hint = weatherData?.error
    ? {
        'data-rh': 'Погода. Сервис временно не доступен.',
        'data-rh-at': 'bottom',
      }
    : weatherData?.desc
    ? {
        'data-rh': weatherData?.desc,
        'data-rh-at': 'bottom',
      }
    : {};

  return (
    <div className="nav-weather" {...hint}>
      {weatherData?.icon && !weatherData?.error && (
        <img
          alt="Погода"
          src={`/static/images/weather/${weatherData?.icon}`}
          style={{ paddingRight: '0' }}
        />
      )}
      {weatherData?.temp && !weatherData?.error && (
        <span>{Math.round(parseFloat(weatherData?.temp))}°C</span>
      )}
    </div>
  );
};

export default Weather;
