/* eslint-disable */
import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import { Address, ADDRESS_NAME } from 'core/uiKit/preparedInputs';

const AddressWithReduxForm = ({ input, ...props }) => {
  return <Address {...input} {...props} />;
};

/* eslint-disable */
export const AddressRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={ADDRESS_NAME}
      component={AddressWithReduxForm}
    />
  );
};

AddressRF.propTypes = {
   
   
/**
   * быбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
/**
   * placeholder
   */
  placeholder: PropTypes.string,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
};
AddressRF.defaultProps = {
  disabled: false,
};
