/* eslint-disable  */
import {
  STATE_GARDENING_ID_NAME,
  StateGardeningId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/StateGardeningId';
import { Field } from 'redux-form';

/**
 *
 * @param root0
 * @param root0.input
 */
const StateGardeningIdWithReduxForm = ({ input, ...props }) => {
  return <StateGardeningId {...input} {...props} />;
};

/* eslint-disable */
export const StateGardeningIdRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={STATE_GARDENING_ID_NAME}
      component={StateGardeningIdWithReduxForm}
    />
  );
};
