import { useGetAllowedGeometryTypesRF } from 'app/pages/cardsOgh/components/Map/MapEditPanel/hooks/useGetAllowedGeometryTypes';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import ContainerCard, { getInitialCoatingGroupId } from './container';

vi.mock(
  'app/pages/cardsOgh/components/Map/MapEditPanel/hooks/useGetAllowedGeometryTypes',
);
vi.mock('app/components/card/common/validation');
vi.mock('./submit');
vi.mock('redux-form', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    reduxForm: vi.fn(() => (Component) => {
      return Component;
    }),
  };
});
vi.mock('react-redux', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    connect: vi.fn(() => (ba) => {
      return ba;
    }),
  };
});

describe('🐛 ContainerCard', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useGetAllowedGeometryTypesRF.mockReturnValue(['LineString', 'Point']);
    const props = { card: { type_id: 1 }, containerTypes: [] };
    // 🔥 Act
    const wrapper = shallow(<ContainerCard {...props} />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<ContainerCard />');
  });

  it('🧪 getInitialCoatingGroupId', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    const props = { card: { attribute: {} } };
    // 🔥 Act

    const res = getInitialCoatingGroupId(props, (_) => _);
    // ❓ Assert

    expect(res).toBe(1);
  });
  it('🧪 getInitialCoatingGroupId parse called', () => {
    expect.hasAssertions();

    // ☣️ Arrange (всякие моки)
    const props = {
      card: { attribute: { coating_group_id: { coating_group: 123 } } },
    };
    // 🔥 Act

    const res = getInitialCoatingGroupId(props, (_) => _);
    // ❓ Assert

    expect(res).toBe('coating_group_id.coating_group');
  });
});
