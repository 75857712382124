import * as geometryApi from 'app/api/requests/geometry';
import { fetchNeighboursGeometry as getFetchNeighboursGeometry } from 'app/api/requests/geometry/fetchNeighboursGeometry';

import * as types from '../../constants/actionTypes';

/**
 * Reset neighbours geometry.
 *
 * @returns {object}
 */
export function resetNeighboursGeometry() {
  return {
    type: types.RESET_NEIGHBOURS_GEOMETRY,
  };
}

/**
 * Reset intersections geometry.
 *
 * @returns {object}
 */
export function resetReonIntersections() {
  return {
    type: types.RESET_REON_INTERSECTIONS,
  };
}

/**
 * Fetch geometry of objects.
 *
 * @param {object} data - Data.
 * @returns {object}
 */
export const fetchObjectsGeometry = (data) => async (dispatch) => {
  dispatch(requestGeometry());
  try {
    const response = await geometryApi.fetchObjectsGeometry(data);
    return response;
  } finally {
    dispatch(receiveGeometry());
  }
};

/**
 * Fetch geometry of objects.
 *
 * @returns {object}
 */
function requestGeometry() {
  return {
    type: types.REQUEST_GEOMETRY,
  };
}

/**
 * Fetch geometry of objects.
 *
 * @returns {object}
 */
function receiveGeometry() {
  return {
    type: types.RECEIVE_GEOMETRY,
  };
}

/**
 * Toggle geometry of children.
 *
 * @returns {object}
 */
export function toggleGeometryOfChildren() {
  return {
    type: types.TOGGLE_GEOMETRY_OF_CHILDREN,
  };
}

/**
 * Fetch geometry of group.
 *
 * @param {object} element - Element.
 * @param {object} group - Group.
 * @param {number} rootId - Root id.
 * @returns {object}
 */
export const fetchGroupGeometry =
  (element, group, rootId) => async (dispatch) => {
    const data = {
      id: element.root ? rootId : element.id,
      typeId: group.type_id,
      ...(element.path_object_id
        ? { pathId: element.path_object_id }
        : { pathTypeId: group.type_path }),
      rootId: rootId,
    };
    dispatch(requestGroupGeometry());

    try {
      const response = await geometryApi.fetchGroupGeometry(data);
      return response;
    } finally {
      dispatch(receiveGroupGeometry());
    }
  };

/**
 * Fetch geometry of group.
 *
 * @returns {object}
 */
function requestGroupGeometry() {
  return {
    type: types.REQUEST_GROUP_GEOMETRY,
  };
}

/**
 * Fetch geometry of group.
 *
 * @returns {object}
 */
function receiveGroupGeometry() {
  return {
    type: types.RECEIVE_GROUP_GEOMETRY,
  };
}

/**
 * Fetch neighbours geometry.
 *
 * @param {object} data - Data.
 * @returns {object}
 */
export const fetchNeighboursGeometry = (data) => async (dispatch) => {
  dispatch(requestNeighboursGeometry());
  const response = await getFetchNeighboursGeometry(data);
  dispatch(receiveNeighboursGeometry(response.data));
  return response;
};

/**
 * Fetch neighbours geometry.
 *
 * @returns {object}
 */
function requestNeighboursGeometry() {
  return {
    type: types.REQUEST_NEIGHBOURS_GEOMETRY,
  };
}

/**
 * Fetch neighbours geometry.
 *
 * @param {object} object - Object.
 * @returns {object}
 */
function receiveNeighboursGeometry(object) {
  return {
    object,
    type: types.RECEIVE_NEIGHBOURS_GEOMETRY,
  };
}

/**
 * Fetch intersections geometry.
 *
 * @param {object} data - Data.
 * @returns {object}
 */
export const fetchReonIntersectionsGeometry = (data) => async (dispatch) => {
  dispatch(requestReonIntersectionsGeometry(data));
  const response = await geometryApi.fetchReonIntersectionsGeometry(data);
  dispatch(receiveReonIntersectionsGeometry(response));
  return response;
};

/**
 * Fetch intersections geometry.
 *
 * @param {object} object - Object.
 * @returns {object}
 */
function requestReonIntersectionsGeometry(object) {
  return {
    object,
    type: types.REQUEST_REON_INTERSECTIONS_GEOMETRY,
  };
}

/**
 * Fetch intersections geometry.
 *
 * @param {object} object - Object.
 * @returns {object}
 */
function receiveReonIntersectionsGeometry(object) {
  return {
    object,
    type: types.RECEIVE_REON_INTERSECTIONS_GEOMETRY,
  };
}

/**
 * Fetch intersections geometry.
 *
 * @param {object} data - Data.
 * @returns {object}
 */
export const fetchCheckGeomContains = (data) => async (dispatch) => {
  dispatch(requestCheckGeomContains(data));
  const response = await geometryApi.fetchCheckGeomContains(data);
  dispatch(receiveCheckGeomContains(response));
  return response;
};

/**
 * Fetch intersections geometry.
 *
 * @param {object} object - Object.
 * @returns {object}
 */
function requestCheckGeomContains(object) {
  return {
    object,
    type: types.REQUEST_CHECK_GEOM_CONTAINS,
  };
}

/**
 * Fetch intersections geometry.
 *
 * @param {object} object - Object.
 * @returns {object}
 */
function receiveCheckGeomContains(object) {
  return {
    object,
    type: types.RECEIVE_CHECK_GEOM_CONTAINS,
  };
}

/**
 * Reset.
 *
 * @returns {object}
 */
export function resetIntersectionsGeometry() {
  return {
    type: types.RESET_INTERSECTIONS_GEOMETRY,
  };
}

/**
 * A.
 *
 * @param {object} data - Data.
 * @returns {object}
 */
export const fetchBtiGeometry = (data) => async (dispatch) => {
  dispatch(requestBtiGeometry(data));

  const response = await geometryApi.fetchBtiGeometry(data);
  dispatch(receiveBtiGeometry(response));
  return response;
};

/**
 * A.
 *
 * @param {object} data - Data.
 * @returns {object}
 */
function requestBtiGeometry(data) {
  return {
    data,
    type: types.REQUEST_BTI_GEOMETRY,
  };
}

/**
 * A.
 *
 * @param {object} object - Data.
 * @returns {object}
 */
function receiveBtiGeometry(object) {
  return {
    object,
    type: types.RECEIVE_BTI_GEOMETRY,
  };
}

/**
 * A.
 *
 * @returns {object}
 */
export function resetBtiGeometry() {
  return {
    type: types.RESET_BTI_GEOMETRY,
  };
}

/**
 * A.
 *
 * @param {object} data - Data.
 * @returns {object}
 */
export const fetchGeometryForMainPage = (data) => async (dispatch) => {
  dispatch(requestGeometryForMainPage(data));
  const response = await geometryApi.fetchFullOghGeometry(data);
  dispatch(receiveGeometryForMainPage(response));
  return response;
};

/**
 * A.
 *
 * @param {object} data - Data.
 * @returns {object}
 */
export const fetchGeometryGroupForMainPage = (data) => async (dispatch) => {
  dispatch(requestGeometryForMainPage(data));
  const response = await geometryApi.fetchGroupGeometry(data);
  dispatch(receiveGeometryForMainPage(response));
  return response;
};

/**
 * Request.
 *
 * @param {object} object - Object.
 * @returns {object}
 */
function requestGeometryForMainPage(object) {
  return {
    object,
    type: types.REQUEST_GEOMETRY_FOR_MAIN_PAGE,
  };
}

/**
 * A.
 *
 * @param {object} object - Data.
 * @returns {object}
 */
function receiveGeometryForMainPage(object) {
  return {
    object,
    type: types.RECEIVE_GEOMETRY_FOR_MAIN_PAGE,
  };
}
