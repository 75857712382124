import { shallow } from 'enzyme';
import { vi } from 'vitest';

import MafType from './MafType';

describe('🐛 MafTypeSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const changeFieldValueSpy = vi.fn();
    const props = {
      changeFieldValue: changeFieldValueSpy,
      editable: true,
      isEditCurrentObjectIsExternalSystem: true,
      parentTypeId: 1,
      required: true,
    };
    // 🔥 Act
    const wrapper = shallow(<MafType {...props} />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<FieldsSplitColumns />');
  });
});
