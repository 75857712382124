import * as api from 'app/api/navbar';

import * as types from '../constants/actionTypes';
import * as loaderActions from './loader';

/* eslint-disable */
export function receiveInfo(object) {
  return {
    type: types.REQUEST_SERVER_INFO,
    object: object,
  };
}

/* eslint-disable */
export function getServerInfo() {
  return (dispatch) => {
    return api
      .fetchSystemData()
      .then((response) => dispatch(receiveInfo(response)))
      .finally(function () {
        dispatch(loaderActions.hide());
      });
  };
}
