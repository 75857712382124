import { parseDate } from 'app/components/card/common/parse';
import getDict from 'app/selectors/getDict';
import toArrayAndSort from 'app/utils/toArrayAndSort';
import { get, has } from 'lodash';

const getCarOwnership = getDict('carOwnership');
const getCarFuncTypeGroups = getDict('carFuncTypeGroups');
const getCarFuncTypes = getDict('carFuncTypes');


/* eslint-disable */
export default function (result, state, props) {
  const { card, mode } = props;
  const { oldValues } = result;
  const carOwnership = getCarOwnership(state);

  let car_model_id = card && card.car_model_id;
  if (has(car_model_id, 'value') && has(car_model_id, 'label')) {
    car_model_id = {
      id: car_model_id.value,
      name: car_model_id.label,
    };
  }

  let car_special_model_id = card && card.car_special_model_id;
  if (
    has(car_special_model_id, 'value') &&
    has(car_special_model_id, 'label')
  ) {
    car_special_model_id = {
      id: car_special_model_id.value,
      name: car_special_model_id.label,
    };
  }

  let car_owner_id = card && card.car_owner_id;
  if (has(car_owner_id, 'value') && has(car_owner_id, 'label')) {
    car_owner_id = {
      id: car_owner_id.value,
      name: car_owner_id.label,
    };
  }

  return {
    ...result,

    initialValues: {
      ...result.initialValues,
      car_special_model_id,
      car_model_id,
      car_owner_id,
      car_ownership_id:
        mode && mode.createMode
          ? getCarOwnershipInitialValue(card.car_ownership_id, carOwnership)
          : card.car_ownership_id,
      gov_number: card.gov_number,
      car_func_type_group_id: card.car_func_type_group_id,
      car_func_type_id: card.car_func_type_id,
      pts: card.pts,
      sts: card.sts,
      pts_date: parseDate(card.pts_date),
      weight_not_load: card.weight_not_load,
      leasing_end_date: parseDate(card.leasing_end_date),
      car_create_date: card.car_create_date,
      load_capacity: card.load_capacity,
      body_capacity: card.body_capacity,
      vin: card.vin,
      factory_number: card.factory_number,
      horse_power: parseFloatValue(card.horse_power),
      electro_power: parseFloatValue(card.electro_power),
      car_motor_type_id: card.car_motor_type_id,
      legal_car_registration_id: card.legal_car_registration_id,
      car_ecology_class_id: card.car_ecology_class_id,
      car_model_producer: card.car_model_producer,
    },

    oldValues: {
      ...oldValues,
      pts_date: parseDate(get(oldValues, 'pts_date')),
      leasing_end_date: parseDate(get(oldValues, 'leasing_end_date')),
      car_create_date: card.old_car_create_date,
      car_ownership_id: get(oldValues, 'car_ownership_id.car_ownership'),
      car_special_model_id: card.old_car_special_model_id,
      car_func_type_group_id: get(
        oldValues,
        'car_func_type_group_id.car_func_type_group',
      ),
      car_func_type_id: get(oldValues, 'car_func_type_id.car_func_type'),
      horse_power: parseFloatValue(get(oldValues, 'horse_power')),
      electro_power: parseFloatValue(get(oldValues, 'electro_power')),
      car_owner_id: card.old_car_owner_id,
      car_motor_type_id: get(oldValues, 'car_motor_type_id.car_motor_type'),
      legal_car_registration_id: get(
        oldValues,
        'legal_car_registration_id.legal_car_registration',
      ),
      car_ecology_class_id: get(
        oldValues,
        'car_ecology_class_id.car_ecology_class',
      ),
      car_model_id: card.old_car_model_id,
    },

    carOwnership,

    carFuncTypes: getCarFuncTypes(state),
    carFuncTypeGroups: toArrayAndSort(getCarFuncTypeGroups(state)),

    carFuncTypeGroupId: get(
      state,
      'form.editorCard.values.car_func_type_group_id',
      null,
    ),
    vinDisabled: !!get(state, 'form.editorCard.values.factory_number', false),
    factoryNumberDisabled: !!get(state, 'form.editorCard.values.vin', false),
  };
}

function getCarOwnershipInitialValue(carOwnershipId, carOwnership) {
  let result;
  if (carOwnershipId) {
    result = carOwnershipId;
  } else {
    const attractedCode = '2';
    result = carOwnership.find((item) => item.code === attractedCode).id;
  }
  return result;
}

function parseFloatValue(value) {
  let result;
  if (value) {
    result = parseFloat(value);
  } else {
    result = null;
  }
  return result;
}
