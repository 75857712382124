import { GROUP_ID_NAME, GroupIdFF } from 'core/form/finalForm/fields';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';
import { ObjectParam } from 'use-query-params';

import { filterInputAutocomplete } from '../filterInputsClasses';

/**
 * ## ID составного объекта
 * Group_id..
 *
 * @returns {*}
 */
export const GroupIdFilter = () => (
  <GroupIdFF className={filterInputAutocomplete} />
);

/**
 * GroupIdParams..
 *
 * @returns {object}
 */
export const groupIdParams = () => {
  addPreparation(GROUP_ID_NAME, (value) => value.name);
  return { [GROUP_ID_NAME]: ObjectParam };
};
