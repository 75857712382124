/* eslint-disable */

import { get } from 'app/api/crud/get';

/**
 *
 * @param data
 */
export const fetchPrivileges = (data) => get('/autocomplete/privileges', data);

/**
 *
 * @param data
 */
export const fetchOrganisations = (data) =>
  get('/autocomplete/organizations', data);

/**
 *
 * @param data
 */
export const fetchRoles = (data) => get('/autocomplete/roles', data);

/**
 *
 * @param data
 */
export const fetchGovNumberTechShipment = (data) =>
  get('/autocomplete/gov_number_tech_shipment', data);

/**
 *
 * @param data
 */
export const fetchUser = (data) => get('/autocomplete/user', data);

/**
 *
 * @param data
 */
export const fetchProviders = (data) => get('/autocomplete/providers', data);

/**
 *
 * @param data
 */
export const fetchCarOwners = (data) => get('/autocomplete/car_owners', data);

/**
 *
 * @param data
 */
export const fetchCustomers = (data) => get('/autocomplete/customers', data);

/**
 *
 * @param data
 */
export const fetchCustomersForName = async (data) => {
  const res = await get('/autocomplete/customers', data);
  return res.map((item) => ({ id: item.id, name: item.shortname }));
};

/**
 *
 * @param data
 */
export const fetchCarEquipmentOwners = (data) =>
  get('/autocomplete/car_equipment_owners', data);

/**
 *
 * @param data
 */
export const fetchAddress = (data) => get('/autocomplete/address', data);

/**
 *
 * @param data
 */
export const fetchOwners = (data) => get('/autocomplete/owners', data);

/**
 *
 * @param data
 */
export const fetchOwnersForName = async (data) => {
  const res = await get('/autocomplete/owners', data);
  return res.map((item) => ({ id: item.id, name: item.shortname }));
};

/**
 *
 * @param data
 */
export const fetchOghTypes = (data) => get('/autocomplete/ogh_types', data);

/**
 *
 * @param data
 */
export const fetchGovNumberTech = (data) =>
  get('/autocomplete/gov_number_tech', data);

/**
 *
 * @param data
 */
export const fetchCarCustomers = (data) =>
  get('/autocomplete/car_customers', data);

/**
 *
 * @param data
 */
export const fetchBtiAddress = (data) => get('/autocomplete/bti_address', data);

/**
 *
 * @param data
 */
export const fetchCarModels = (data) => get('/autocomplete/car_models', data);

/**
 *
 * @param data
 */
export const fetchCarSpecialModels = (data) =>
  get('/autocomplete/car_special_models', data);

/**
 *
 * @param data
 */
export const fetchTrafficSignsObject = (data) =>
  get('/autocomplete/traffic_signs_object', data);

/**
 *
 * @param data
 */
export const fetchGlonassModel = (data) =>
  get('/autocomplete/glonass_model', data);
