import { turnIntoCamelCase } from 'app/utils/turnIntoCamelCase';

import { useGetCardSelector } from './useGetCard.Selector';

 
/* eslint-disable */
/**
 * Selector получения карточки attribute из state
 * @function
 * @category useSelector
 * @returns {{}}
 */
 
export const useGetCardAttribute = () => {
  const { attribute } = useGetCardSelector();
  const attributeValueCamel = turnIntoCamelCase(attribute);
  return {
    ...attribute,
    ...attributeValueCamel,
  };
};
