import { uploadFile } from 'app/api/requests/uploadFiles';
import { toast } from 'core/uiKit/components/Toast';
/* eslint-disable  */
import {
  MutateOptions,
  useMutationAdaptor,
} from 'core/utils/hooks/useReactQuery';

/**
 * Хук для отправки документов в базу данных.
 *
 * @param {*} cancelDialog - Функция закрытия диалога.
 * @param {Function} onchange - Функция обновления данных.
 * @param {Array} currentDataTable - Текущие данные.
 * @returns {{isLoading: boolean, sendDocumentData: (variables: void, options?: MutateOptions<unknown, unknown, void, unknown>) => void}}
 */
export const useSendDocumentData = (
  cancelDialog,
  onchange,
  currentDataTable,
) => {
  const [sendDocumentData, { isLoading }] = useMutationAdaptor(
    ({ file, additional }) => uploadFile(file, additional),
    {
      /**
       *
       * @param error
       */
      onError(error) {
        toast.errorDying(
          error?.message ? error.message : 'Ошибка при добавлении данных',
        );
      },

      /**
       *
       * @param value
       * @param dataRequest
       */
      onSuccess(value, dataRequest) {
        const { additional, file } = dataRequest;

        const data = {
          comment: additional.comment,
          create_date: additional.create_date,
          download_path: value.downloadPath,
          file_id: value.fileId,
          file_name: file.path,
          type_id: additional.type_id,
        };
        onchange([...currentDataTable, data]);
        cancelDialog();
        toast.successDying(value.message);
      },
    },
  );

  return { isLoading, sendDocumentData };
};
