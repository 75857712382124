/* eslint-disable  */

import { fetchDictionary as fetchDictionaryD } from 'app/api/autocomplete/fetchDictionary';
import { get } from 'app/api/crud';

/**
 * @deprecated
 * @see src/app/api/hooks/autocomplete/useGetDictionary
 * @param dict
 * @returns {*}
 */
export const fetchDictionary = fetchDictionaryD;

/**
 *
 * @param data
 */
export const fetchOghTypes = async (data) => {
  if (data?.typeId) {
    return await get('/autocomplete/ogh_types', data);
  }
  return [];
};
