import { ContinueDialog } from 'core/uiKit/components';
/* eslint-disable  */
import React, { useState } from 'react';

type ContinueDialogHocType = {
  children: React.ReactElement;
};

type DataDialogType = {
  message: string;
  isOpen: boolean;
  send: () => void;
};

const initial = {
  isOpen: false,
  message: '',

  /**
   *
   */
  send: () => {},
};

/**
 * Fn.
 *
 * @param root0 - Parameters.
 * @param root0.children - Children.
 * @returns {*}
 */
export const ContinueDialogHoc = ({ children }: ContinueDialogHocType) => {
  const [dataDialog, setDataDialog] = useState<DataDialogType>(initial);

  /**
   * Fn.
   *
   * @param message - The message.
   * @param send - The send.
   * @returns {void}
   */
  const openContinueDialog = (message: string, send: () => void) => {
    setDataDialog({
      isOpen: true,
      message,
      send,
    });
  };

  /**
   * Fn.
   *
   * @returns {void}
   */
  const closeContinueDialog = () => {
    setDataDialog(initial);
  };

  /**
   * Fn.
   *
   * @returns {void}
   */
  const send = () => {
    dataDialog.send();
    closeContinueDialog();
  };

  return (
    <>
      {dataDialog.isOpen && (
        <ContinueDialog
          isOpen={dataDialog.isOpen}
          closeDialog={closeContinueDialog}
          send={send}
          textHeader={'Подтверждение'}
          Body={<p>{dataDialog.message}</p>}
        />
      )}

      {React.cloneElement(children, {
        closeContinueDialog,
        openContinueDialog,
      })}
    </>
  );
};
