// Component
import { MovementCharacteristics } from './MovementCharacteristics';


/* eslint-disable */
export default {
  title: 'Example/MovementCharacteristics',
  component: MovementCharacteristics,
};

 
export const Basic = () => {
  return <MovementCharacteristics />;
};
