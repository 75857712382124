import isCreateMode from 'app/selectors/card/isCreateMode';
/* eslint-disable  */
import isEditMode from 'app/selectors/card/isEditMode';

/**
 *
 * @param state
 */
export const isThisViewing = (state) =>
  [isEditMode(state) || isCreateMode(state)].every((item) => !item);
