import { TextField } from 'core/uiKit/inputs/TextField';
import React, { FC } from 'react';

export const COMMENT_NAME = 'comment';

interface CommentProps {

  /**
   * Выбор темной темы.
   */
  dark?: boolean;

  /**
   * Заблокировать на редактирование.
   */
  disabled?: boolean;

  /**
   * Вывод ошибки.
   */
  errorText?: string;

  /**
   * Функция вызова когда выберется итем.
   */
  onChange?: (ev: unknown) => void;

  /**
   * Вывод информации об обязательном поле.
   */
  required?: boolean;

  /**
   * Значение.
   */
  value?: string;

  /**
   * Максимальная длина комментария.
   */
  maxLength?: number;
}

/**
 * Комментарий.
 *
 * @param props - Properties.
 * @returns - CommentField.
 */
export const Comment: FC<CommentProps> = (props) => {
  return (
    <TextField
      minRows={3}
      variant={'outlined'}
      {...props}
      label={'Комментарий'}
      multiline={true}
      //@ts-ignore
      maxRows={13}
      maxLength={props.maxLength ?? 1000}
      name={COMMENT_NAME}
    />
  );
};
