import { HIDE_DIALOG, SHOW_DIALOG } from 'app/constants/actionTypes';
/* eslint-disable */
const initialState = {
  type: null,
  props: {},
};

/* eslint-disable */
export default function dialog(state = initialState, action) {
  switch (action.type) {
    case SHOW_DIALOG: {
      const { type, props } = action.payload;
      return {
        ...state,
        type,
        props,
      };
    }
    case HIDE_DIALOG: {
      return initialState;
    }
    default:
      return state;
  }
}
