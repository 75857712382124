import { AutocompleteMultiAsync } from 'core/uiKit/inputs/selects/AutocompleteMultiAsync';

import { fetchUsernames } from './api';

export const USERNAME_NAME = 'username';

/**
 * ## Пользователи.
 *
 * @param {*} props - The props.
 * @param {*} props.value - The props.
 * @param {*} props.otherProps - The props.
 * @returns {JSX.Element}
 */
export const Username = ({ value, ...otherProps }) => {
  return (
    <AutocompleteMultiAsync
      {...otherProps}
      value={value || []}
      fetchFunction={fetchUsernames}
      label={'Пользователи'}
      name={USERNAME_NAME}
    />
  );
};
