import _ from 'lodash';

/* eslint-disable */
export default function validateCleanArea(formValues) {
  if (
    !_.isNil(formValues.manual_clean_area) &&
    !_.isNil(formValues.auto_clean_area)
  ) {
    if (!(formValues.manual_clean_area > 0 || formValues.auto_clean_area > 0)) {
      return {
        manual_clean_area:
          'Площадь ручн. уборки или площадь мех. уборки должна быть больше нуля',
        auto_clean_area:
          'Площадь ручн. уборки или площадь мех. уборки должна быть больше нуля',
      };
    }
  }
  return {};
}
