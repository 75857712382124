import { NumberField } from 'core/uiKit/inputs/NumberField';
import PropTypes from 'prop-types';
import React from 'react';

export const INTENSITY_NUM_NAME = 'intensity';

/**
 * Поле __Интенсивность движения__.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 * @class
 */
export const IntensityNum = (props) => {
  return <NumberField label={'Интенсивность движения'} {...props} />;
};

IntensityNum.propTypes = {

  /**
   * Выбор темной темы.
   */
  dark: PropTypes.bool,

  /**
   * Заблокировать на редактирование.
   */
  disabled: PropTypes.bool,

  /**
   * Вывод ошибки.
   */
  errorText: PropTypes.string,

  /**
   * Функция вызова когда выберется итем.
   */
  onChange: PropTypes.func,

  /**
   * Вывод информации об обязательном поле.
   */
  required: PropTypes.bool,

  /**
   * Значение.
   */
  value: PropTypes.any,
};
