/* eslint-disable */
import React from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import cn from 'classnames';

import { onCopySubmit } from 'app/actions/adminRoles';
import CardForm from './CardForm';

const CardCopy = (props) => {
  const { onSubmit } = props;
  let { roleId } = useParams();

  return (
    <CardForm
      className={cn('container my-4', props.className)}
      heading="Копирование роли"
      roleId={roleId}
      onSubmit={onSubmit}
    />
  );
};

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmit: function (values) {
      return dispatch(onCopySubmit(values));
    },
  };
}

 
export default connect(mapStateToProps, mapDispatchToProps)(CardCopy);
