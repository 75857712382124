/* eslint-disable  */
import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { MultiSelect } from 'core/uiKit/inputs/selects/MultiSelect/MultiSelect';
import PropTypes from 'prop-types';
import React from 'react';

export const DISTRICT_LIST_NAME = 'district_list';

/**
 * ## Район.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - JSX.
 * @example
 * <DistrictMulty {...props}/>
 */
export const DistrictList = (props) => {
  const dict = 'districts';

  const { data: options = [] } = useGetDictionary(dict);

  return (
    <MultiSelect
      options={options}
      label={'Район'}
      name={DISTRICT_LIST_NAME}
      {...props}
    />
  );
};

DistrictList.propTypes = {
  /**
   * Очищаем инпут с сохранением значения.
   */
  clear: PropTypes.bool,

  /**
   * Выбор темной темы.
   */
  dark: PropTypes.bool,

  /**
   * Заблокировать на редактирование.
   */
  disabled: PropTypes.bool,

  /**
   * Вывод ошибки.
   */
  errorText: PropTypes.string,

  /**
   * Вывод вспомогательного текста.
   */
  helperText: PropTypes.string,

  /**
   * Функция вызова когда выберется итем.
   */
  onChange: PropTypes.func,

  /**
   * Вывод информации об обязательном поле.
   */
  required: PropTypes.bool,

  /**
   * Значение.
   */
  value: PropTypes.any,
};
