import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Редиректим на урл с родителем __/ogh/_${recordIdParent}_/_${recordId}___.
 *
 * @returns {never}
 */
export const useRedirectToParent = () => {
  const navigate = useNavigate();
  const { recordId } = useGetCurrentCard();
  const { recordId: recordIdParent } = useGetParentCard();

  useEffect(() => {
    if (recordIdParent && recordId && +recordIdParent !== +recordId) {
      navigate(`/ogh/${recordIdParent}/${recordId}`, {
        replace: true,
      });
    }

    // eslint-disable-next-line
  }, [recordId, recordIdParent]);
};
