import { AutocompleteMultiAsync } from 'core/uiKit/inputs/selects/AutocompleteMultiAsync';

import { fetchCarCustomers } from './api';

export const CAR_CUSTOMERS_LIST_NAME = 'car_customer_list';

/**
 * ## Заказчики.
 *
 * @param {*} args - The props.
 * @param {*} args.value - The props.
 * @param {*} args.props - The props.
 * @returns {JSX.Element}
 */
export const CarCustomerList = ({ value, ...props }) => {
  const filterValue = (Array.isArray(value) ? value : [value]).filter(Boolean);
  return (
    <AutocompleteMultiAsync
      label={'Заказчик'}
      {...props}
      fetchFunction={fetchCarCustomers}
      name={CAR_CUSTOMERS_LIST_NAME}
      value={filterValue}
    />
  );
};
