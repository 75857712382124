import {
  MAPTIP_FULL,
  MAPTIP_SHORT,
} from 'app/services/mapService/constants/popUpTypes';

import { ol } from '../../../utils/helpers/ol';
/* eslint-disable */
const createMapTipShort = (mapId) => {
  const element = document.createElement('div');
  element.innerHTML = `<div class="popup">
                         <div id="maptip-short-hint-${mapId}"></div>
                       </div>`;
  return element;
};

const createMapTipFull = (mapId) => {
  const element = document.createElement('div');
  element.innerHTML = `<div class="popup">
                         <a href="#" id="maptip-full-closer-${mapId}" class="popup-closer"></a>
                         <div id="maptip-full-title-${mapId}" class="popup-title"></div>
                         <div id="maptip-full-hint-${mapId}" class="popup-content"></div>
                       </div>`;
  return element;
};

const overlays = {
  [MAPTIP_SHORT]: {
    id: 'maptip-short',
    createElement: createMapTipShort,
    hintId: 'maptip-short-hint',
  },
  [MAPTIP_FULL]: {
    id: 'maptip-full',
    createElement: createMapTipFull,
    titleId: 'maptip-full-title',
    hintId: 'maptip-full-hint',
    closerId: 'maptip-full-closer',
  },
};

/* eslint-disable */
export default class PopUp {
  constructor(map) {
    this[MAPTIP_SHORT] = this.createOverlay(map, MAPTIP_SHORT);
    this[MAPTIP_FULL] = this.createOverlay(map, MAPTIP_FULL);
  }

  createOverlay(map, type) {
    const { id, createElement } = overlays[type];

    const target = map.getTarget();
    this.mapId = target && target.id;

    const overlay = new ol.Overlay({
      id,
      element: createElement.call(null, this.mapId),
      autoPan: true,
      positioning: 'bottom-center',
      className: 'popup-overlay',
    });

    map.addOverlay(overlay);

    return overlay;
  }

  isActive(type) {
    return this.activeOverlay === type;
  }

  show(type, data, coordinate, handleClose) {
    const { titleId, hintId, closerId } = overlays[type];
    const { title, html } = data;

    if (titleId) {
      const titleElement = document.getElementById(`${titleId}-${this.mapId}`);
      titleElement.innerHTML = title;
    }

    if (hintId) {
      const hintElement = document.getElementById(`${hintId}-${this.mapId}`);
      hintElement.innerHTML = html;
    }

    if (closerId) {
      const closerElement = document.getElementById(
        `${closerId}-${this.mapId}`,
      );
      closerElement.onclick = () => {
        this.hide(type);
        closerElement.blur();

        handleClose && handleClose();
        return false;
      };
    }

    this[type].setPosition(coordinate);
    this.activeOverlay = type;
  }

  hide(type) {
    if (this.isActive(type)) {
      this[type].setPosition(undefined);
      this.activeOverlay = null;
    }
  }
}
