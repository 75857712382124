import { todayDateAndHours } from 'app/utils/dateUtils';
import { filterInputDataWithHours } from 'core/form/filterForm/components/filterInputsClasses';
import { KeyboardDateTimePickerFF } from 'core/form/finalForm/fields/default/datePickers/KeyboardDateTimePickerFF';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import moment from 'moment';
import React from 'react';
import { useField } from 'react-final-form';
import { DateTimeParam, StringParam, withDefault } from 'use-query-params';

export const DATE_TO_WITH_HOURS_NAME = 'dateTo';

/**
 * DateToWithHoursFilter.
 *
 * @param {*} root - Properties.
 * @param {string} root.label - Label.
 * @param {*} root.props - Properties.
 * @returns {JSX.Element}
 */
export const DateToWithHoursFilter = ({
  label = 'Дата изм по (план)',
  ...props
}) => {
  const dateFromInput = useField('dateFrom');
  return (
    <KeyboardDateTimePickerFF
      minDate={dateFromInput?.input?.value}
      {...props}
      className={filterInputDataWithHours}
      label={label}
      name={DATE_TO_WITH_HOURS_NAME}
    />
  );
};

/**
 * DateToWithHoursParamsDefault..
 *
 * @returns {object}
 */
export const dateToWithHoursParamsDefault = () => {
  addPreparation(DATE_TO_WITH_HOURS_NAME, (value) => {
    let newValue = value;
    value = new Date(value);
    if (typeof value === 'object' && moment(value).isValid()) {
      newValue = moment(value).format('DD.MM.YYYY HH:mm');
    }
    return newValue;
  });
  return {
    [DATE_TO_WITH_HOURS_NAME]: StringParam,
  };
};

/**
 * DateToWithHoursParams.
 *
 * @returns {object}
 */
export const dateToWithHoursParams = () => {
  addPreparation(DATE_TO_WITH_HOURS_NAME, (value) => {
    let newValue = value;
    if (typeof value === 'object' && moment(value).isValid()) {
      newValue = moment(value).format('DD.MM.YYYY HH:mm');
    }
    return newValue;
  });

  return {
    [DATE_TO_WITH_HOURS_NAME]: withDefault(DateTimeParam, todayDateAndHours),
  };
};
