import { NSIRoute } from 'app/pages/admin/nsi/NSIRoute';
import { RegistrySkeleton } from 'core/uiKit/components';
import lazyWithPreload from 'lazy-with-preload';
import React, { Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { pathNews } from './News/path.News';
import { pathNsi } from './nsi/NSI/path.Nsi';
import { pathPatternMatching } from './PatternMatching/path.PatternMatching';
import { pathRoles } from './Roles/path.Roles';
import { pathSubscriptions } from './Subscriptions/path.Subscriptions';
import { pathUsers } from './Users/path.Users';

const PatternMatching = lazyWithPreload(() => import('./PatternMatching'));
const Roles = lazyWithPreload(() => import('./Roles'));
const Subscriptions = lazyWithPreload(() => import('./Subscriptions'));
const Users = lazyWithPreload(() => import('./Users'));
const User = lazyWithPreload(() => import('./User'));
const UserEditor = lazyWithPreload(() => import('./User.Editor'));
const News = lazyWithPreload(() => import('./News'));
const NewsItem = lazyWithPreload(() => import('./NewsItem'));

/**
 * Роут Админки.
 *
 * @returns {JSX.Element} - JSX.
 */
export const AdminRoute = () => {
  useEffect(() => {
    PatternMatching.preload();
    Roles.preload();
    Subscriptions.preload();
    Users.preload();
    User.preload();
    User.preload();
    News.preload();
    NewsItem.preload();
  });

  return (
    <Suspense fallback={<RegistrySkeleton />}>
      <Routes>
        <Route
          element={<PatternMatching />}
          path={`${pathPatternMatching.path}`}
        />
        <Route element={<Subscriptions />} path={`${pathSubscriptions.path}`} />
        <Route element={<NSIRoute />} path={`${pathNsi.path}/*`} />

        <Route element={<Roles />} path={`${pathRoles.path}`} />

        <Route element={<Users />} path={`${pathUsers.path}`} exact />
        <Route element={<UserEditor />} path={`${pathUsers.path}/create`} />
        <Route element={<UserEditor />} path={`${pathUsers.path}/add`} exact />
        <Route
          element={<UserEditor />}
          path={`${pathUsers.path}/:userId/edit`}
        />
        <Route element={<User />} path={`${pathUsers.path}/:userId`} />

        <Route element={<News />} path={`${pathNews.path}/*`} />
      </Routes>
    </Suspense>
  );
};
