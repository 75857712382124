/* eslint-disable */
import React, { useEffect } from 'react';
import fetchMock from 'fetch-mock';

import { Paper } from 'core/uiKit/components/Paper';

// Component
import { SendMatchingOghButton } from './SendMatchingOgh.Button';


/* eslint-disable */
export default {
  title:
    'UiKit/Components/Buttons/Ogh/SendMatchingOghButton',
  component: SendMatchingOghButton,
};

const FetchWrapper = ({ children }) => {
  useEffect(() => {
    fetchMock.restore();
    fetchMock.get(
      'https://dev.ods.test.intermb.ru/api/ogh/visibility/buttons?object_id=42',
      {
        send_matching: true,
      },
    );
    return () => fetchMock.restore();
  }, []);
  return children;
};

 
export const Basic = (args) => {
  return (
    <FetchWrapper>
      <Paper
        dark={args.dark}
        style={{ width: '100%' }}
        className={'p-5'}
      >
        <SendMatchingOghButton {...args} />
      </Paper>
    </FetchWrapper>
  );
};
Basic.args = { objectId: 42 };
