import React from 'react';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';

import { ContinueDialog } from '../dialogs/Continue.Dialog';

interface PromptProps {
  when: boolean;
  message: string;
}

/**
 * Используется для подсказки пользователю перед уходом со страницы.
 *
 * @param {PromptProps} props - Properties.
 * @returns React component.
 */
export const Prompt = ({ when, message }: PromptProps) => {
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (when) {
      return currentLocation.pathname !== nextLocation.pathname;
    }

    return false;
  });

  const isShow = blocker.state === 'blocked';

  return isShow ? (
    <ContinueDialog
      closeDialog={() => {
        if (typeof blocker?.reset === 'function') {
          blocker.reset();
        }
      }}
      isOpen={isShow}
      send={() => {
        if (typeof blocker?.reset === 'function') {
          blocker.proceed();
        }
      }}
      textHeader={'Подтвердите действие'}
      Body={<div>{message}</div>}
    />
  ) : null;
};
