import './TitleButton.scss';

import React from 'react';

import { AddButton } from './components/AddButton';
import { ChangeButton } from './components/ChangeButton';

/**
 * @typedef {import('app/pages/cardsOgh/components/RightPanelCard/components/DialogEditButton/listDataType').ListDataType} ListDataType
 */

/**
 * Title button.
 *
 * @param {object} props - Property.
 * @param {ListDataType} props.data - Data.
 * @param {any} props.children - Children.
 * @param {any} props.onClick - OnClick.
 * @param {any} props.activeButton - A.
 * @param {any} props.typeId - TypeId.
 * @param {any} props.typeCode - TypeCode.
 * @returns {JSX}
 */
export const TitleButton = ({
  data,
  children,
  onClick,
  activeButton,
  typeId,
  typeCode,
}) => {
  return (
    <div className={'wrapper-text__right-panel'}>
      <p onClick={onClick}>{children}</p>

      <div className={'wrapper-buttons__right-panel'}>
        <AddButton
          {...{
            activeButton,
            typeCode,
            typeId,
          }}
        />
        <ChangeButton
          columnTree={data}
          activeButton={activeButton}
          typeCode={typeCode}
        />
      </div>
    </div>
  );
};
