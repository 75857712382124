import { useNavigateRouter } from 'core/utils/router';
/* eslint-disable  */
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { actions as formActions } from 'react-redux-form';

import DialogAction from '../../../common/DialogAction';

const propTypes = {
  closeCancelDialog: PropTypes.func,
  showCondition: PropTypes.bool,
};

/**
 *
 * @param props
 */
const DialogCancelRole = (props) => {
  const navigate = useNavigateRouter();

  const { showCondition, closeCancelDialog } = props;

  return (
    <DialogAction
      showCondition={Boolean(showCondition)}
      onCancel={closeCancelDialog}
      onOk={() => {
        closeCancelDialog();
        navigate(-1);
      }}
    />
  );
};

DialogCancelRole.propTypes = propTypes;

/**
 *
 * @param state
 */
const mapStateToProps = (state) => ({
  showCondition: state.rrf.admin.role.cancelDialog,
});

/**
 *
 * @param dispatch
 */
const mapDispatchToProps = (dispatch) => ({
  /**
   *
   */
  closeCancelDialog: () =>
    dispatch(formActions.change('rrf.admin.role.cancelDialog', '')),
});

export default connect(mapStateToProps, mapDispatchToProps)(DialogCancelRole);
