/* eslint-disable  */
import DxfFileUpload from 'app/components/common/DxfFileUpload';

import { DialogCreateOGH } from 'app/pages/components/modals/DialogCreateOGH';
import { Field } from 'react-final-form';

/**
 *
 * @param root0
 * @param root0.typeId
 * @param root0.children
 */
export const DialogCreateWithDXF = ({ typeId, children }) => {
  return (
    <DialogCreateOGH typeId={typeId}>
      {children}
      <Field component={DxfFileUpload} name={'file'} />
    </DialogCreateOGH>
  );
};
