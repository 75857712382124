/* eslint-disable */
import React from 'react';
import { StringParam } from 'use-query-params';

import { TextFieldFF } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
export const PROVIDER_NAME = 'provider';

 
 
/**
 * Поставщик телеметрических услуг–> provider [поле строка, или если пусто - все]
 */
 
export const ProviderFilter = () => {
  return (
    <TextFieldFF
      className={filterInput}
      label={'Поставщик тел-их услуг'}
      name={PROVIDER_NAME}
    />
  );
};

 
export const providerParams = {
  [PROVIDER_NAME]: StringParam,
};
