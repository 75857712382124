/* eslint-disable */
import React from 'react';
import { NumberParam } from 'use-query-params';

import { TextFieldFF } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
export const SIM_NUMBER_NAME = 'simNumber';

 
 
/**
 * Номер SIM-карты
 */
 
export const SimNumberFilter = () => {
  return (
    <TextFieldFF
      className={filterInput}
      label={'Номер SIM-карты'}
      name={SIM_NUMBER_NAME}
      type="number"
    />
  );
};

 
export const simNumberParams = {
  [SIM_NUMBER_NAME]: NumberParam,
};
