import { shallow } from 'enzyme';
import React from 'react';
import { vi } from 'vitest';

import { AddButtonRepairsPlanTab } from './Add.Button.RepairsPlan.Tab';
import { useSetupAddButtonRepairsPlanTab } from './useSetup.Add.Button.RepairsPlan.Tab';

vi.mock('./useSetup.Add.Button.RepairsPlan.Tab');

describe('🐛 spec Add.Button.RepairsPlan.Tab.spec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    useSetupAddButtonRepairsPlanTab.mockReturnValue({});
    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(<AddButtonRepairsPlanTab />);

    //❓ Assert
    expect(wrapper.text()).toBe('');
  });
});
