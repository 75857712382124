import { post } from 'app/api/crud/post';
import { getLengthGeometry } from 'app/pages/cardsOgh/api/helpers/getLengthGeometry';
import { getAttributeForm } from 'app/pages/cardsOgh/helpers/getAttributeForm';

/**
 * Метод получения пересечения со смежными дочерними объектами.
 *
 * @param {object} data - Данные.
 * @returns {*}
 */
export const postIntersection = async (data) =>
  await post('/ogh/geometry/intersection', data);

/**
 * Функция получения пересечения со смежными дочерними объектами.
 *
 * @param {string} geometry - Текущая геометрия.
 * @param {object} card - Информация о карточке.
 * @param {object} values - Значения формы.
 * @param {string} parentId - Id родителя.
 * @returns {Error<object> | Promise<string>}
 */
export const postCheckIntersection = async (
  geometry,
  card,
  values,
  parentId,
) => {
  const data = {
    attribute: getAttributeForm(card.typeId, values),
    cnt: null,
    geometry,
    id: card?.rootId,
    journal: null,
    parentId: card.parentId,
    startDate: card.startDate,
    treeParentId: parentId ? Number(parentId) : null,
    typeId: Number(card.typeId),
  };

  const res = await postIntersection(data);
  const length = getLengthGeometry(res);

  if (length) {
    throw Object({ errorKey: 'errorIntersections', res });
  }
  return 'Success';
};
