import { get } from 'app/api/crud/get';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';

/**
 * A.
 *
 * @param {string} rootId - Root id.
 * @returns {Promise<*>}
 */
const getBoundaryMatchingHistory = async (rootId) => {
  const response = await get(`/ogh/boundary/matching/history/${rootId}`);
  return response.data;
};

/**
 * A.
 *
 * @param {string} rootId - Root id.
 * @returns {Promise<*>}
 */
export const useGetBoundaryMatchingHistory = (rootId) =>
  useQueryAdaptor(
    ['getBoundaryMatchingHistory', rootId],
    getBoundaryMatchingHistory,
  );
