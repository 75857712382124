import * as queryString from 'query-string';
import { useLocation } from 'react-router-dom';

/**
 * Получение ошибки авторизации.
 *
 * @param error - Сообщение об ошибке.
 * @param error.message - Сообщение об ошибке.
 * @param error.status - Статус ошибки.
 * @returns Если есть ошибка то текст ошибки иначе null.
 */
export const useGetErrorMessage = (error: {
  message: string;
  status: number;
}) => {
  const queryParams = queryString.parse(useLocation().search);

  if (queryParams['error']) {
    return 'ошибка аутентификации';
  }

  if (error) {
    if (error?.message === 'Authentication Failed: User is disabled') {
      return 'пользователь заблокирован';
    }

    if (error?.status === 403) {
      return null;
    }
    return error?.message;
  }
  return null;
};
