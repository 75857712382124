import { NumberParam, useQueryParams, withDefault } from 'use-query-params';

export type ParamsPagination = {
  queryPagination: {
    maxRows: number | undefined;
    page: number | undefined;
  };
  setQuery: (changes: {
    maxRows: number | undefined;
    page: number | undefined;
  }) => void;
};

/**
 * Хук пагинации таблицы.
 *
 * @returns - Параметры пагинации.
 */
export const useParamsPagination = (): ParamsPagination => {
  const [queryPagination, setQuery] = useQueryParams({
    maxRows: withDefault(NumberParam, 50),
    page: withDefault(NumberParam, 0),
  });
  return { queryPagination, setQuery };
};
