import { get } from 'app/api/crud/get';
import { DataNews } from 'types/data.News';

/**
 * Функция получения всех новостей из БД.
 *
 * @returns {Promise<DataNews>} - Новости.
 */
export const getNewsAll = (): Promise<DataNews> =>
  get('news/all', { sort: 'create_date.desc' });
