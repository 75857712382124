/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */

/**
 * Колонка __ID__.
 *
 * @type {{ accessor: 'abutment_friends_short_root_id',  Header: 'ID', rowSpan: 1, sortable: true }}
 * @augments Cell
 */
export const abutment_friends_short_root_id = {
  Header: 'ID',
  accessor: 'abutment_friends_short_root_id',
  rowSpan: 1,
  sortable: true,
};
