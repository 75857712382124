import { uploadFile, uploadFiles } from 'app/api/requests/uploadFiles';
import { fetchDxfFileLink, fetchDxfFileUpdateLink } from 'app/api/upload';
import * as types from 'app/constants/actionTypes';
import dateFormatService from 'core/services/dateFormatService';
import { log } from 'core/utils/log';

/**
 * Экшен requestSendDxfFile.
 *
 * @returns {{type: string}}
 */
const requestSendDxfFile = () => {
  return {
    type: types.REQUEST_SEND_DXF_FILE,
  };
};

/**
 * Экшен receiveSendDxfFile.
 *
 * @returns {{type: string}}
 */
const receiveSendDxfFile = () => {
  return {
    type: types.RECEIVE_SEND_DXF_FILE,
  };
};

/**
 * Функция linkDxfFile.
 *
 * @param {string} startDate - Стартовая дата.
 * @param {File} file - Файл.
 * @param {number} typeId - Тип ОГХ.
 * @returns {(function(*): Promise<{fileId: string}|undefined>)|*}
 */
export const linkDxfFile = (startDate, file, typeId) => async (dispatch) => {
  dispatch(requestSendDxfFile());
  try {
    // чтобы с UI при загрузке DXF для ДТ, ОО и ИОБ в
    // https://test-ods.elocont.ru/api/files/upload
    // передавался type_id=7 (это раздел Инвентаризационный план на вкладке Документы),
    // а для ОДХ type_id =18 (это раздел ПЛАН-СХЕМА ОБЪЕКТА М 1:500).

    let typeIdCusotm;

    switch (typeId) {
      case 38:
      case 39:
      case 40: {
        typeIdCusotm = 7;
        break;
      }
      case 1: {
        typeIdCusotm = 18;
        break;
      }
      default:
    }

    const response = await uploadFile(file, { type_id: typeIdCusotm });
    const { fileId } = response;

    let result;

    if (fileId) {
      result = await fetchDxfFileLink({
        fileId,
        startDate: dateFormatService.format(startDate, 'YYYY-MM-DD'),
      }).catch((error) => {
        dispatch(receiveSendDxfFile());
        throw error;
      });
    } else {
      result = response;
    }
    return result;
  } finally {
    dispatch(receiveSendDxfFile());
  }
};

/**
 * Функция linkDxfFileForUpdate.
 *
 * @param {string} startDate - Дата начала.
 * @param {string} endDate - Дата конца.
 * @param {number} recordId - Record Id.
 * @param {File} file - Файл.
 * @param {number} typeId - Тип ОГХ.
 * @returns {function(*): Promise<{fileId: string}>}
 */
export const linkDxfFileForUpdate =
  (startDate, endDate, recordId, file, typeId) => async (dispatch) => {
    dispatch(requestSendDxfFile());
    const response = await uploadFile(file, { type_id: typeId }).catch(
      (error) => {
        log.info(error);
      },
    );
    const { fileId } = response;

    let result;

    if (fileId) {
      result = await fetchDxfFileUpdateLink({
        endDate: dateFormatService.format(endDate, 'YYYY-MM-DD'),
        fileId,
        rootId: recordId,
        startDate: dateFormatService.format(startDate, 'YYYY-MM-DD'),
      }).catch((error) => {
        dispatch(receiveSendDxfFile());
        throw error;
      });
    } else {
      result = response;
    }

    dispatch(receiveSendDxfFile());
    return result;
  };

/**
 * Старый метод загрузки файла на сервер.
 *
 * @param {object} args - Аргументы.
 * @deprecated
 * @see src/app/api/requests/uploadFiles
 * @returns {Promise<*>}
 */
const uploadFileOld = (...args) => {
  log.banana("заюзай от сюда import { uploadFile } from 'app/api/requests';");
  return uploadFile(...args);
};

/**
 * Старый метод загрузки файлов на сервер.
 *
 * @deprecated
 * @param {object} args - Аргументы.
 * @see src/app/api/requests/uploadFiles
 * @returns {Promise<*>}
 */
const uploadFilesOld = (...args) => {
  log.banana("заюзай от сюда import {  uploadFiles } from 'app/api/requests';");
  return uploadFiles(...args);
};

export { uploadFileOld as uploadFile, uploadFilesOld as uploadFiles };
