import * as api from 'app/api';
import * as types from 'app/constants/actionTypes';
import _ from 'lodash';
import moment from 'moment';
import { actions as formActions } from 'react-redux-form';
import { createAction } from 'redux-actions';

import * as loaderActions from './loader';

 
export const apiGetTechnologyVersions = createAction(
  types.API_GET_TECHNOLOGY_VERSIONS,
  function (data) {
    return {
      promise: api.getTechnologyVersions(data),
    };
  },
);

/* eslint-disable */
export function onSubmit(values) {
  return function (dispatch) {
    dispatch(loaderActions.show());
    dispatch(formActions.change('rrf.technologyVersions.page', 0));

    const requestValues = getSearchFormValues(values);
    const promise = apiGetTechnologyVersions(requestValues);

    return dispatch(promise).finally(function () {
      dispatch(loaderActions.hide());
    });
  };
}

/* eslint-disable */
export function onSort(values) {
  return function (dispatch) {
    dispatch(formActions.merge('rrf.technologyVersions', values));
    dispatch(formActions.submit('rrf.technologyVersions'));
  };
}

/* eslint-disable */
export function onLimit(value) {
  return function (dispatch) {
    dispatch(formActions.change('rrf.technologyVersions.maxRows', value));
    dispatch(formActions.change('rrf.technologyVersions.page', 0));
    dispatch(formActions.submit('rrf.technologyVersions'));
  };
}

/* eslint-disable */
export function onPagination(value) {
  return function (dispatch) {
    dispatch(formActions.change('rrf.technologyVersions.page', value));
    dispatch(formActions.submit('rrf.technologyVersions'));
  };
}

/* eslint-disable */
export function onReset() {
  return function (dispatch) {
    dispatch(formActions.change('rrf.technologyVersions.provider', ''));
    dispatch(formActions.reset('rrf.technologyVersions.startDate'));
    dispatch(formActions.reset('rrf.technologyVersions.endDate'));
    dispatch(formActions.change('rrf.technologyVersions.userCreate', ''));
    dispatch(formActions.change('rrf.technologyVersions.carOwner', ''));
    dispatch(formActions.change('rrf.technologyVersions.carCustomer', ''));
    dispatch(
      formActions.change('rrf.technologyVersions.carEquipmentOwner', ''),
    );
  };
}

function getSearchFormValues(values) {
  const formValues = {};

  _.forEach(values, function (value, key) {
    let newKey, newValue;
    if (value) {
      newValue = value;
      newKey = key;
      if (key === 'startDate' || key === 'endDate') {
        newValue = moment(value).format('DD.MM.YYYY HH:mm');
      } else if (
        key === 'provider' ||
        key === 'carOwner' ||
        key === 'carCustomer' ||
        key === 'carEquipmentOwner'
      ) {
        newValue = value.id;
      }
      formValues[newKey] = newValue;
    }
  });

  return formValues;
}
