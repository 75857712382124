export const grbs = [
  {
    id: 10216105,
    name: 'ДЖКХ',
  },
  {
    id: 10004261,
    name: 'Префектура ВАО',
  },
  {
    id: 10004262,
    name: 'Префектура ЗАО',
  },
  {
    id: 10004263,
    name: 'Префектура ЗелАО',
  },
  {
    id: 10004264,
    name: 'Префектура САО',
  },
  {
    id: 10004265,
    name: 'Префектура СВАО',
  },
  {
    id: 10004266,
    name: 'Префектура СЗАО',
  },
  {
    id: 10226345,
    name: 'Префектура ТиНАО',
  },
  {
    id: 10004267,
    name: 'Префектура ЦАО',
  },
  {
    id: 10004270,
    name: 'Префектура ЮАО',
  },
  {
    id: 10004268,
    name: 'Префектура ЮВАО',
  },
  {
    id: 10004269,
    name: 'Префектура ЮЗАО',
  },
];
