 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */

import { YesOrNoInvertCell } from 'core/uiKit/components/cells/YesOrNoInvert.Cell';
 
/* eslint-disable */
/**
 * Колонка __Не учитывать при расчете уборочных площадей__.
 *
 * @type {{
 * accessor: 'included_in_tl',
 * Header: 'Входит в ТС',
 * sortable: true,
 * Cell: YesOrNoInvertCell,
 * }}
 * @augments Cell
 */
export const included_in_tl = {
  accessor: 'included_in_tl',
  Header: 'Входит в ТС',
  sortable: true,
  Cell: YesOrNoInvertCell,
};
