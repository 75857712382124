import * as adminRolesActions from 'app/actions/adminRoles';
import cn from 'classnames';
import PropTypes from 'prop-types';
/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { actions as formActions, Fieldset } from 'react-redux-form';

import List from './List';

class Functions extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isReadOnly: PropTypes.bool,
    form: PropTypes.array,
    levels: PropTypes.array,
    formCheckboxChange: PropTypes.func,
    levelsChange: PropTypes.func,
  };

  render() {
    const { isReadOnly, form, levels, formCheckboxChange, levelsChange } =
      this.props;

    if (!form) {
      return null;
    }

    return (
      <Fieldset className={cn(this.props.className)} model=".functions">
        <h2>Функции</h2>

        <List
          className="mt-3"
          form={form}
          formCheckboxChange={formCheckboxChange}
          isReadOnly={isReadOnly}
          levels={levels}
          levelsChange={levelsChange}
        />
      </Fieldset>
    );
  }
}

function mapStateToProps(state) {
  return {
    form: state.rrf.admin.role.functions,
    levels: state.rrf.admin.role.levels,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    formCheckboxChange: function (values) {
      return dispatch(adminRolesActions.formCheckboxChange(values));
    },
    levelsChange: function (values) {
      return dispatch(
        formActions.change(
          'rrf.admin.role.levels' + values.model,
          values.value,
        ),
      );
    },
  };
}

 
export default connect(mapStateToProps, mapDispatchToProps)(Functions);
