import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useGetParamsUrl } from 'core/utils/hooks/useGetParamsUrl';
import { useMemo } from 'react';

export const CREATE_FROM_PARENT = 'createFromParent';

export const UPDATE_FROM_PARENT = 'updateFromParent';

export const CREATE_CARD = 'createCard';

export const UPDATE_CARD = 'updateCard';

/**
 * Хук получения типа действия в карточке.
 *
 * @returns {string}
 */
export const useTypeActionCard = () => {
  const {
    childId = false,
    oghRegistry: { createFromParent = false },
  } = useGetParamsUrl();

  const { rootId } = useGetCurrentCard();

  const memo = useMemo(() => {
    switch (true) {
      case createFromParent: {
        return CREATE_FROM_PARENT;
      }
      case Boolean(childId && Number(childId)): {
        return UPDATE_FROM_PARENT;
      }
      case !rootId: {
        return CREATE_CARD;
      }
      default: {
        return UPDATE_CARD;
      }
    }
  }, [rootId, childId, createFromParent]);

  return memo;
};
