import { NumberFieldFF } from 'core/form/finalForm/fields';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';
import { StringParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

export const ID_NUMBER_NAME = 'IdNumber';

/**
 * ID   IdNumber.
 *
 * @param {object} root - Properties.
 * @param {string} root.label - Label.
 * @param {object} root.props - Properties.
 * @returns {JSX.Element}
 */
export const IdNumberFilter = ({ label = 'ID', ...props }) => {
  return (
    <NumberFieldFF
      {...props}
      className={filterInput}
      label={label}
      name={ID_NUMBER_NAME}
    />
  );
};

/**
 * Подготовка параметров для фильтра IdNumber.
 *
 * @returns {object}
 */
export const idNumbersParamsPreparation = () => {
  addPreparation(ID_NUMBER_NAME, (value) => value, 'id');
  return {
    [ID_NUMBER_NAME]: StringParam,
  };
};

export const idNumbersParams = {
  [ID_NUMBER_NAME]: StringParam,
};
