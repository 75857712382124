/* eslint-disable  */
import {
  CONV_ELEMENT_TYPE_ID_NAME,
  ConvElementTypeId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/ConvElementTypeId';
import { Field } from 'redux-form';

/**
 *
 * @param root0
 * @param root0.input
 */
const ConvElementTypeIdWithReduxForm = ({ input, ...props }) => {
  return <ConvElementTypeId {...input} {...props} />;
};

/* eslint-disable */
export const ConvElementTypeIdRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={CONV_ELEMENT_TYPE_ID_NAME}
      component={ConvElementTypeIdWithReduxForm}
    />
  );
};
