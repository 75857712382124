/* eslint-disable */
import { useGetRegistry } from 'app/utils/api';
import { currentDate, dayPlusMonth } from 'app/utils/dateUtils';
import { DATE_FROM_NAME } from 'core/form/finalForm/fields/prepared/datePickers/keyboardDatePickerFF/DateFromFF';
import { DATE_TO_NAME } from 'core/form/finalForm/fields/prepared/datePickers/keyboardDatePickerFF/DateToFF';
import { useParamsTable } from 'core/uiKit/components/tables/Table';
import { preparationValues } from 'core/utils/helpers/preparationValues';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import {
  addPreparation,
  removePreparation,
} from 'core/utils/hooks/usePreparation/preparation';
import moment from 'moment';
import { useEffect, useState } from 'react';

/**
 * Custom hook for handling body operations.
 *
 * @returns {object} Object containing table, isLoading, submit, cancel, and search properties.
 */
export const useBodyOperations = () => {
  const { objectId } = useGetObjectId();
  const { queryTable } = useParamsTable();

  const initialSearch = {
    dateFrom: currentDate,
    dateTo: dayPlusMonth,
    rootObjectId: objectId,
  };

  const [search, setSearch] = useState(initialSearch);

  addPreparation(DATE_FROM_NAME, (value) => {
    let newValue = value;
    if (typeof value === 'object' && moment(value).isValid()) {
      newValue = moment(value).format('DD.MM.YYYY');
    }
    return newValue;
  });
  addPreparation(DATE_TO_NAME, (value) => {
    let newValue = value;
    if (typeof value === 'object' && moment(value).isValid()) {
      newValue = moment(value).format('DD.MM.YYYY');
    }
    return newValue;
  });

  useEffect(() => {
    return () => {
      removePreparation(DATE_FROM_NAME);
      removePreparation(DATE_TO_NAME);
    };
  }, []);

  /**
   * Cancels the current search and resets it to the initial search state.
   *
   * @returns {void}
   */
  const cancel = () => setSearch(initialSearch);
  /**
   * Submits the search query.
   *
   * @param {any} v - The value to set the search to.
   * @returns {void}
   */
  const submit = (v) => setSearch(v);

  const { data: table = [], isLoading } = useGetRegistry(
    ['basePgmOperations', preparationValues({ ...search, ...queryTable })],
    { enabled: Boolean(objectId) },
  );

  return { cancel, isLoading, search, submit, table };
};
//
// const table = [
//   {
//     id: 291,
//     operation_kind: 'shipment',
//     operation_kind_name: 'Отпуск',
//     reagent_kind_id: 2,
//     reagent_kind_name: 'твердые реагенты',
//     reagent_id: 2,
//     reagent_name: 'МРКтв',
//     quantity: 1,
//     target_date: '17.02.2022',
//     ui_target_date: '17.02.2022 20:12',
//   },
//   {
//     id: 298,
//     operation_kind: 'refill',
//     operation_kind_name: 'Приход ',
//     reagent_kind_id: 2,
//     reagent_kind_name: 'твердые реагенты',
//     reagent_id: 2,
//     reagent_name: 'МРКтв',
//     quantity: 1,
//     target_date: '17.02.2022',
//     ui_target_date: '17.02.2022 20:01',
//   },
//   {
//     id: 299,
//     operation_kind: 'refill',
//     operation_kind_name: 'Приход ',
//     reagent_kind_id: 2,
//     reagent_kind_name: 'твердые реагенты',
//     reagent_id: 2,
//     reagent_name: 'МРКтв',
//     quantity: 1,
//     target_date: '17.02.2022',
//     ui_target_date: '17.02.2022 20:01',
//   },
//   {
//     id: 300,
//     operation_kind: 'refill',
//     operation_kind_name: 'Приход ',
//     reagent_kind_id: 2,
//     reagent_kind_name: 'твердые реагенты',
//     reagent_id: 2,
//     reagent_name: 'МРКтв',
//     quantity: 1,
//     target_date: '17.02.2022',
//     ui_target_date: '17.02.2022 20:01',
//   },
//   {
//     id: 304,
//     operation_kind: 'refill',
//     operation_kind_name: 'Приход ',
//     reagent_kind_id: 1,
//     reagent_kind_name: 'жидкие реагенты',
//     reagent_id: 1,
//     reagent_name: 'ХКНж',
//     quantity: 0.01,
//     target_date: '25.02.2022',
//     ui_target_date: '25.02.2022 14:15',
//     comment: 'My comment',
//   },
//   {
//     id: 305,
//     operation_kind: 'refill',
//     operation_kind_name: 'Приход ',
//     reagent_kind_id: 2,
//     reagent_kind_name: 'твердые реагенты',
//     reagent_id: 2,
//     reagent_name: 'МРКтв',
//     quantity: 0.01,
//     target_date: '04.02.2022',
//     ui_target_date: '04.02.2022 13:15',
//   },
//   {
//     id: 309,
//     operation_kind: 'refill',
//     operation_kind_name: 'Приход ',
//     reagent_kind_id: 2,
//     reagent_kind_name: 'твердые реагенты',
//     reagent_id: 2,
//     reagent_name: 'МРКтв',
//     quantity: 0.01,
//     target_date: '25.02.2022',
//     ui_target_date: '25.02.2022 18:49',
//     comment: 'мой камент',
//   },
//   {
//     id: 84,
//     operation_kind: 'refill',
//     operation_kind_name: 'Приход ',
//     reagent_kind_id: 2,
//     reagent_kind_name: 'твердые реагенты',
//     reagent_id: 2,
//     reagent_name: 'МРКтв',
//     quantity: 0.01,
//     target_date: '25.02.2022',
//     ui_target_date: '25.02.2022 18:49',
//     comment: 'мой камент',
//   },
//   {
//     id: 98,
//     operation_kind: 'refill',
//     operation_kind_name: 'Приход ',
//     reagent_kind_id: 2,
//     reagent_kind_name: 'твердые реагенты',
//     reagent_id: 3,
//     reagent_name: 'МРтв',
//     quantity: 50,
//     target_date: '17.06.2019',
//     ui_target_date: '17.06.2019 07:29',
//     comment: 'Мой камент',
//   },
// ];
