import { get } from 'lodash';


/* eslint-disable */
export default function () {
  return (result, state, props) => {
    const { card } = props;
    return {
      ...result,
      initialValues: {
        ...result.initialValues,
        owner_id: {
          id: get(card.attribute, 'owner_id.legal_person', null),
          name: card.owner_name,
        },
        customer_id: {
          id: get(card.attribute, 'customer_id.legal_person', null),
          name: card.customer_name,
        },
      },
    };
  };
}
