import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';

import { getDataMapDiff } from './getData.MapDiff';

/* eslint-disable */
/**
 * Хук получения данных для таба "Карта".
 *
 * @param {object} etalon - Объект с которым сравниваем.
 * @param {object} copy - Новая версия.
 * @returns {{isLoading: boolean, table: Array}} - Массив таблицы свойств.
 * @example const { data, isLoading } = useGetMapTable(etalon,copy);
 */
export const useGetDataMapDiff = (etalon, copy) => {
  const { typeId } = useGetCurrentCard();
  const { data = [], isLoading } = useQueryAdaptor(
    [
      'getDataMapDiff',
      {
        etalon: etalon.id,
        copy: copy.id,
        typeId,
      },
    ],
    getDataMapDiff,
  );
  return { data, isLoading };
};
