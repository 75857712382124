/* eslint-disable */
import React from 'react';
import { NumberParam } from 'use-query-params';

import { GPS_STATE_ID_NAME, GpsStateIdFF } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
 
/**
 * Состояние       gps_state_id    int
 */

 
export const GpsStateIdFilter = () => <GpsStateIdFF className={filterInput} />;

 
export const gpsStateIdParams = {
  [GPS_STATE_ID_NAME]: NumberParam,
};
