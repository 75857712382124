import { VERTICAL_LANDSCAPING_REF_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/VerticalLandscapingRefTypeId/VerticalLandscapingRefTypeId';
import { usePreparation } from 'core/utils/hooks/usePreparation/preparation';

/**
 * Хук перевода значений.
 *
 * @returns {void}
 */
export const usePreparationVerticalLandscapingRefTypeId = () => {
  usePreparation(VERTICAL_LANDSCAPING_REF_TYPE_ID_NAME, (value) => {
    if (value) {
      return { vertical_landscaping_ref_type: value };
    }
  });
};
