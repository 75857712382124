import { Paper } from 'core/uiKit/components/Paper';
import {
  FolderIcon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from 'core/uiKit/material-ui';
import PropTypes from 'prop-types';

/**
 * Файлы.
 *
 * @param {object} props - Пропсы.
 * @param {Array} props.files - Файлы.
 * @param {React.ReactNode} [props.children] - Дети.
 * @returns {JSX.Element}
 */
export const FileListSign = ({ files, children }) => {
  return (
    <Paper className={'my-2 p-2'}>
      <Typography variant="h6">Файлы для подписи</Typography>
      <List dense>
        {files?.map((item) => (
          <ListItem key={item?.name}>
            <ListItemIcon>
              <FolderIcon />
            </ListItemIcon>
            <ListItemText primary={item?.name} />
          </ListItem>
        ))}
      </List>
      {children}
    </Paper>
  );
};

FileListSign.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
    }),
  ),
};
