import { useGetVisibilityButtonsOgh } from 'app/api/hooks/useGetVisibilityButtonsOgh';
import { shallow } from 'enzyme';
import React from 'react';
import { vi } from 'vitest';

import { ButtonCloseElectronicSignature } from './Button.CloseElectronicSignature';

vi.mock('app/api/hooks/useGetVisibilityButtonsOgh');

/**
 * Мок createMock.
 *
 */
const createMock = () => {
  useGetVisibilityButtonsOgh.mockImplementation((objectId) => ({
    data: { close_eds: objectId === 42 },
  }));
};

/**
 * WrapperComponent подготавливаем компонент для теста.
 *
 * @param {number} objectId - Id объекта.
 * @returns {*}
 */
function wrapperComponent(objectId) {
  createMock();
  return shallow(<ButtonCloseElectronicSignature objectId={objectId} />);
}

describe('component ButtonCloseElectronicSignature', () => {
  it('useGetVisibilityButtonsOgh был вызван с Random Number', () => {
    expect.hasAssertions();
    const objectId = Math.round(Math.random() * 10000000);
    wrapperComponent(objectId);
    expect(useGetVisibilityButtonsOgh).toHaveBeenCalledWith(objectId);
  });

  it('есть кнопка', () => {
    expect.hasAssertions();
    const button = wrapperComponent(42);
    expect(button.text()).toBe('<Button />');
  });

  it('есть кнопка с пропсами', () => {
    expect.hasAssertions();
    const button = wrapperComponent(42);
    expect(button.props()).toMatchObject({
      children: 'Закрыть (ЭП)',
      color: 'error',
      variant: 'contained',
    });
  });

  it('нет кнопки', () => {
    expect.hasAssertions();
    const button = wrapperComponent(2);
    expect(button.text()).toBe('');
  });
});
