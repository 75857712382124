/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';

import TextField from 'core/newComponents/TextField';
import DialogAlert from './DialogAlert';
import DialogAction from './DialogAction';

const propTypes = {
  alertResult: PropTypes.object,
  closeWarning: PropTypes.func,
  entityCode: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  matching: PropTypes.func,
  operation: PropTypes.string,
  requestTypeId: PropTypes.string,
  showCondition: PropTypes.bool,
  title: PropTypes.string,
  typeId: PropTypes.number,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
};

class DialogMatching extends React.Component {
  state = {
    comment: '',
    errorText: null,
  };

  handleMatching = (afterAction) => {
    // const {
    //   entityCode,
    //   id,
    //   matching,
    //   operation,
    //   requestTypeId,
    //   typeId,
    // } = this.props;
    // const { comment } = this.state;
    // matching(
    //   {
    //     id,
    //     entityCode,
    //     operation,
    //     requestTypeId,
    //     ...(typeId && { typeId: typeId }),
    //     comment,
    //   },
    //   afterAction,
    // );
  };

  handleChange = (event) => {
    this.setState({
      comment: event.target.value,
      errorText: null,
    });
  };

  handleCancel = () => {
    const { onCancel } = this.props;
    this.setState({ errorText: null });
    onCancel();
  };

  handleOk = () => {
    const { operation, onOk } = this.props;
    const { comment } = this.state;
    if (operation === 'rejected') {
      comment.length
        ? this.handleMatching(onOk)
        : this.setState({ errorText: 'Обязательное поле' });
    } else {
      this.handleMatching(onOk);
    }
  };

  render() {
    const {
      alertResult,
      closeWarning,
      operation,
      showCondition,
      title,
      multiline,
    } = this.props;
    const { comment, errorText } = this.state;

    return (
      <React.Fragment>
        <DialogAlert
          showCondition={get(alertResult, 'isDone', false)}
          onAfterHiding={closeWarning}
        >
          {get(alertResult, 'message', '')}
        </DialogAlert>

        <DialogAction
          className="Dialog-Matching"
          showCondition={showCondition}
          title={title}
          onCancel={this.handleCancel}
          onOk={this.handleOk}
        >
          {operation === 'rejected' && (
            <TextField
              autoFocus={true}
              fullWidth={true}
              helperText={errorText}
              label="Комментарий"
              multiline={multiline}
              name="comment"
              required={true}
              rows={4}
              value={comment}
              onChange={this.handleChange}
            />
          )}
        </DialogAction>
      </React.Fragment>
    );
  }
}

DialogMatching.propTypes = propTypes;

const mapDispatchToProps = {};

 
export default connect(null, mapDispatchToProps)(DialogMatching);
