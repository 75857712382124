import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';

import { loginToDTW } from './api/loginToDTW';
import { useGetDTWTokens } from './useGetDTWTokens';

export const LOGIN_TO_DIGITAL_TWIN_KEY = 'loginToDigitalTwin';

/**
 * Hook получения токенов авторизации
 * в цифровом двойнике.
 *
 * @returns Результат логина в цифровом двойнике { accessToken?: string, refreshToken?: string }.
 */
export const useLoginToDTW = () => {
  const { accessToken, setTokens, clearTokens } = useGetDTWTokens();

  return useQueryAdaptor([LOGIN_TO_DIGITAL_TWIN_KEY], loginToDTW, {
    enabled: !accessToken,

    /**
     * Обработчик ошибки.
     */
    onError: () => {
      clearTokens();
    },

    /**
     * Обработчик успеха.
     *
     * @param loginData - Данные логина.
     */
    onSuccess: (loginData) => {
      const { accessToken = '', refreshToken = '' } = loginData || {};
      setTokens({ accessToken, refreshToken });
    },
  });
};
//
// /**
//  * Тип, представляющий токены аутентификации.
//  */
// type AuthTokensDTW_Type = {
//   // Ваши свойства
//   name: string;
// };
//
// /**
//  * Условный тип, который извлекает тип, возвращаемый промисом.
//  * Если переданный тип является промисом, возвращает его разрешенный тип.
//  *
//  * @template Tt - Тип, который может быть промисом.
//  */
// type UnwrapPromise<Tt> = Tt extends Promise<infer Uu> ? Uu : Tt;
//
// // Пример использования
// type ResultType = UnwrapPromise<Promise<AuthTokensDTW_Type>>; // Теперь ResultTyp
