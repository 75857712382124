import * as searchForms from './searchForms/index';

/* eslint-disable */
export function codeToSearchForm(code) {
  switch (code) {
    case 'car':
      return searchForms.Car;
    case 'bnso':
    case 'gps':
      return searchForms.Bnso;
    case 'equipment':
      return searchForms.Equipment;
    default:
      throw new Error(`Неизвестный код ${code}`);
  }
}
/* eslint-disable */
export function codeToColumns(code, tableColumns) {
  switch (code) {
    case 'car':
      return tableColumns.technologyVersionsCar;
    case 'bnso':
    case 'gps':
      return tableColumns.technologyVersionsBnso;
    case 'equipment':
      return tableColumns.technologyVersionsEquipment;
    default:
      throw new Error(`Неизвестный код ${code}`);
  }
}
