import { useMain } from 'app/components/common/NavBar/components/Drawer/hooks/useMain';
import Title from 'app/components/common/NavBar/components/Drawer/Title';
import {
  Box,
  createTheme,
  Drawer,
  ThemeProvider,
} from 'core/uiKit/material-ui';
import * as React from 'react';

const theme = createTheme({
  palette: {
    background: {
      paper: '#1D1D27',
    },
    shape: {
      border: 'solid 1px #FFFFFF',
    },
    text: {
      primary: '#FFFFFF',
    },
  },
});

const boxTheme = {
  width: 300,
};

/**
 * Компонент TemporaryDrawer.
 *
 * @param {React.ReactChildren} children - Дети.
 * @returns {JSX.Element} - JSX.
 */
export default function TemporaryDrawer({ children }) {
  const [open, toggleDrawer] = useMain();
  return (
    <ThemeProvider theme={theme}>
      <Drawer anchor={'right'} open={open} onClose={toggleDrawer(false)}>
        <Box sx={boxTheme} role="presentation" onKeyDown={toggleDrawer(false)}>
          <Title onClick={toggleDrawer(false)}>Новые уведомления</Title>
          {children}
        </Box>
      </Drawer>
    </ThemeProvider>
  );
}
