import { FormCardFF } from 'app/pages/cardsOgh/components/FormCard.FF';
import React from 'react';

import { LeftPanelWaterBuilding } from './LeftPanel.WaterBuilding';
import { useSetupWaterBuilding } from './useSetup.WaterBuilding';
import { validateForm } from './validateForm';

/**
 * Водных сооружений.
 *
 * @returns {JSX.Element}
 */
export const WaterBuilding = () => {
  const { initialValues, preparingDataForAttributes } = useSetupWaterBuilding();

  return (
    <FormCardFF
      validate={validateForm}
      preparingDataForAttributes={preparingDataForAttributes}
      initialValues={initialValues}
    >
      <LeftPanelWaterBuilding />
    </FormCardFF>
  );
};
