/**
 * @callback FnPreparation - Функия форматирования свойства.
 * @param {*} value - Value.
 * @returns {*} - Форматированое Value.
 */

/**
 * @callback NewKeyFn - Функия форматирования нового имени свойства.
 * @param {*} value - Value.
 * @returns {string} - Форматированое Имя свойства.
 */

/**
 * @typedef {{ fn: FnPreparation, newKey: string|NewKeyFn}} Formatter - Обьект для форматирования значения и имени своойства
 */

/**
 * Обьекты форматирования инпутов где ключ это имя инпута.
 *
 * @type {{[key: string]: Formatter}}
 */
export const functions = {};
