import formatRootId from 'app/utils/formatRootId';
import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Компонент LinkIdCell для таблиц.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.value - Данные колонки.
 * @returns {JSX.Element} - Возвращают ссылку на версию паспорта.
 * @example {
 *     accessor: 'id',
 *     Header: 'ID версии объекта',
 *     sortable: true,
 *     Cell: LinkIdCell
 *     },
 */
export const LinkIdCell = ({ value }) => {
  if (value) {
    return <Link to={`/ogh/${value}`}>{formatRootId(value)}</Link>;
  }
  return null;
};
