import { preparationValues } from 'core/utils/helpers/preparationValues';
import { useQueryParams } from 'core/utils/hooks/useQueryParams';

/**
 * @typedef {object} FilterQueryParamsType - Данные QueryParams для фильтра формы FF.
 * @property {object} prepareFilters - Отформатированые данные для бэка.
 * @property {Function} setFilters - Функция для переноса состояния формы в урл.
 * @property {object} filters - Обычные данные формы.
 */

/**
 * @typedef {import('use-query-params').QueryParamConfig} QueryParamConfig
 */

/**
 * @callback QueryParamFnType - Функия возвращает тип query-params.
 * @returns {{[key:string]: QueryParamConfig}} - Форматированое Имя свойства.
 */

/**
 * Общий Хук QueryParams для фильтра формы FF.
 *
 * @param {Array<QueryParamFnType>} params - Массив функций.
 * @returns {FilterQueryParamsType}
 * @example --------
 * export const useFilterQueryParamsFencing = () => {
 *  return useFilterQueryParams([
 *    customerOznParams,
 *    dateFromParams,
 *    dateToParams,
 *    grbsIdParams,
 *    idOrNameParams,
 *    idOrNameParentParams,
 *    oghStatusesParams,
 *    ownerIdParams,
 *    subTypeIdParams,
 *  ]);
 * };
 */
export const useFilterQueryParams = (params) => {
  const [filters, setFilters] = useQueryParams(params);

  return {
    filters,
    prepareFilters: preparationValues(filters),
    setFilters,
  };
};
