// TODO: все зависимости от app перенести в ядро
import Table from 'app/components/common/table/Table';
import TableAddButton from 'app/components/common/TableAddButton';
import TableDeleteButton from 'app/components/common/TableDeleteButton';
import TableErrorText from 'app/components/common/TableErrorText';
import { INSURANCE_FILE_NAME } from 'app/constants/messages';
import addFileToList from 'app/utils/addFileToList';
import getDownloadUrl from 'app/utils/getDownloadUrl';
import FieldLabel from 'core/components/FieldLabel';
import SimpleLink from 'core/components/SimpleLink';
import Checkbox from 'core/newComponents/Checkbox';
import { CommentsWithFilesCell } from 'core/uiKit/components/cells/CommentsWithFiles.Cell';
import PropTypes from 'prop-types';
import React from 'react';

import OldValueTable from './components/OldValueTable';
import getTableData from './getTableData';

/* eslint-disable */
export default class FileListField extends React.Component {
  static defaultProps = {
    renderAddFileDialog: () => null,
  };

  static propTypes = {
    columns: PropTypes.array,
    labelStyle: PropTypes.object,
    renderAddFileDialog: PropTypes.func,
  };

  onAdd = () => {
    this.props.input.onChange({
      table: this.getTable(),
      showDialog: true,
    });
  };

  onCancel = () => {
    this.props.input.onChange({
      table: this.getTable(),
      showDialog: false,
    });
  };

  onRemove = () => {
    const tableCheckedItems = this.getTable().filter((item) => item.checked);
    const tableItems = this.getTable().filter((item) => !item.checked);
    const insurancePolicy = this.props.technicFileTypes
      ? this.props.technicFileTypes.find(
          (i) => i.name.trim().toLowerCase() === INSURANCE_FILE_NAME,
        )
      : {};
    const insuredDocs = tableItems.find(
      (i) => i.type_id === insurancePolicy.id,
    );
    const insuredDocsChecked = tableCheckedItems.find(
      (i) => i.type_id === insurancePolicy.id,
    );
    if (insuredDocsChecked && !insuredDocs) {
      this.props.changeFieldValue('insured', false);
      this.props.changeFieldValue('insurance_end_date', null);
    }

    const tableItemsSort = tableItems.sort((a, b) => a.index - b.index);
    const tableItemsnewNumbering = tableItemsSort.map((item, index) => {
      return {
        ...item,
        index: index + 1,
      };
    });

    this.props.input.onChange({
      table: tableItemsnewNumbering,
      showDialog: false,
    });
  };

  onSubmit = (newFile) => {
    if (newFile) {
      const table = this.getTable().slice();
      addFileToList(table, newFile);
      this.props.input.onChange({
        table,
        showDialog: false,
      });
    }
  };

  getTable() {
    const { value } = this.props.input;
    return (value && value.table) || [];
  }

  renderCheckbox(item, index) {
    return (
      <Checkbox
        checked={item.checked || false}
        onChange={(event, value) => {
          const table = this.getTable().slice();
          table[index] = {
            ...table[index],
            checked: value,
          };
          this.props.input.onChange({
            table,
            showDialog: false,
          });
        }}
      />
    );
  }

  renderLabel() {
    const { props } = this;
    return (
      <FieldLabel required={props.required} style={props.labelStyle}>
        {props.label}
      </FieldLabel>
    );
  }

  render() {
    const { props } = this;
    const { label } = props;
    const showDialog =
      (props.input.value && props.input.value.showDialog) || false;

    const defaultColWidth = 280;
    const checkboxColWidth = 50;

    const table = this.getTable();
    const results = getTableData(table, props.columns);

    if (!props.disabled) {
      const { columns } = results.table;
      columns.unshift({
        key: 'delete',
        screenName: '',
        sortable: false,
        databaseName: null,
        cells: [],
        width: checkboxColWidth,
      });
      table.forEach((item, index) => {
        columns[0].cells.push(this.renderCheckbox(item, index));
      });
    }

    const width = results.table.columns.reduce(
      (result, { width = defaultColWidth }) => result + width,
      0,
    );

    return (
      <div
        style={{
          marginBottom: '5px',
          ...props.style,
        }}
      >
        {label && this.renderLabel()}
        <OldValueTable
          {...props}
          getTableData={getTableData}
          oldValue={props.oldValue}
          table={table}
        />
        {props.renderAddFileDialog({
          open: showDialog,
          accept: props.accept,
          onSubmit: this.onSubmit,
          onCancel: this.onCancel,
          allowedFormatsMessage: props.allowedFormatsMessage,
        })}
        <TableAddButton
          isError={Boolean(props.meta && props.meta.error)}
          title="Добавление файла"
          visible={!props.disabled}
          onAdd={this.onAdd}
        />
        <TableDeleteButton
          disabled={!table.some((item) => item.checked)}
          visible={!props.disabled}
          onRemove={this.onRemove}
        />
        <TableErrorText
          errorText={(props.meta && props.meta.error) || null}
          visible={!props.disabled}
        />
        <Table
          {...results}
          dataTransformer={transformNameToLink}
          height={table.length * 50 + 100}
          width={width}
          widths={(name) => {
            const col = results.table.columns.find(
              (item) => item.screenName === name,
            );
            return col && col.width ? col.width : defaultColWidth;
          }}
        />
      </div>
    );
  }
}

function renderLink(name, href) {
  return <SimpleLink download={true} href={href} name={name} />;
}

function transformNameToLink(key, value) {
  switch (key) {
    case 'file_name':
      return renderLink(value.name, getDownloadUrl(value.fileId));
    case 'comment':
      return value.length > 20 ? (
        <CommentsWithFilesCell
          row={{ original: { comment: value, file_list: [] } }}
        />
      ) : (
        value
      );
    default:
      return value;
  }
}
