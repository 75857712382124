import { Document } from 'app/pages/cardsOgh/components/tables/Documents/Document';
import React from 'react';

/**
 * Обертка для таблиц redux-form.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.input - Свойства поля приходит из redux-form.
 * @param {string} props.title - Заголовок.
 * @param {string} props.id - Id.
 * @returns {JSX.Element} - JSX.
 * @example
 * <Field
 *         title={TITLE}
 *         id={ID}
 *         name={NAME}
 *         disabled={isCurrentObjectIsExternalSystem}
 *         component={DocumentWithReduxForm}
 *       />
 */
export const DocumentWithReduxForm = ({ input, title, id, ...otherProps }) => {
  const { value, onChange } = input;

  /**
   * Функция изменения значения в таблице.
   *
   * @param {Array} dataTable - Массив таблиц.
   * @returns {void} - Nothing.
   * @example ---
   */
  const changeHandler = (dataTable) => {
    onChange({ ...value, table: dataTable });
  };

  return (
    <Document
      titleDialog={`Добавление файла (${title})`}
      title={title}
      dataTable={value ? value.table : []}
      typeId={id}
      onChange={changeHandler}
      {...otherProps}
    />
  );
};
