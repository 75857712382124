import { CardOgh } from 'types/Card';

import { REPAIRS_INFO_LIST_NAME } from '../components/tabs/Repairs.Tab/Table.Repairs.Tab';
import { REPAIRS_INFO_LIST_PLAN_NAME } from '../components/tabs/RepairsPlan.Tab';

/**
 * Инициализация значений для формы Дворовые територии.
 *
 * @param result - Результирующий объект.
 * @param result.initialValues - Инициализация значений.
 * @param _ - Состояние.
 * @param props - Пропсы.
 * @param props.card - Карточка.
 * @returns {object}
 */
export const initialValuesYard = (
  result: { initialValues: object },
  _: object,
  props: { card: CardOgh },
) => {
  const { card } = props;
  return {
    ...result,
    initialValues: {
      ...result.initialValues,
      [REPAIRS_INFO_LIST_NAME]: card?.repairs_info_list || [],
      [REPAIRS_INFO_LIST_PLAN_NAME]: card?.repairs_info_list_plan || [],
      reservoir_area: card?.attribute.reservoir_area,
    },
  };
};
