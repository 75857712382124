import { useParamsTable } from 'core/uiKit/components/tables/Table';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';

import { fetchList } from './fetchList';

/**
 * Функция получения ведомостей.
 *
 * @param {string} name - Название.
 * @returns {Promise<*>}
 */
export const useList = (name) => {
  const { objectId: id } = useGetObjectId();
  const { queryTable } = useParamsTable();

  const queryKey = [name, { ...queryTable, id, ...queryTable, key: name }];

  const { data: table, isLoading: isLoadingTable } = useQueryAdaptor(
    queryKey,
    fetchList,
  );

  const queryKeyCount = [
    name + '/count',
    { ...queryTable, id, ...queryTable, key: name + '/count' },
  ];
  const { data: dataCount } = useQueryAdaptor(queryKeyCount, fetchList);

  return { count: dataCount?.data?.count, isLoadingTable, table };
};
