import { withSnakeCase } from 'app/api/crud/withSnakeCase';
import * as api from 'app/api/versions';

import * as types from '../constants/actionTypes';

/**
 * Получить результаты поиска версий.
 *
 * @param {object} data - Данные для запроса.
 * @returns {function(*): Promise<*|Response>} - Жопа.
 * @example
 * -----
 */
export const fetchSearchVersionsResults = (data) => async (dispatch) => {
  dispatch(requestVersions(data));
  const response = await api.fetchVersions(data);
  dispatch(receiveVersions({ table: response.data }));
  return response;
};

/**
 * Action запроса версий.
 *
 * @param {object} data - Данные для запроса.
 * @returns {{data, type: string}} - Жопа.
 * @example
 * -----
 */
export function requestVersions(data) {
  return {
    data,
    type: types.REQUEST_VERSIONS,
  };
}

/**
 * Action получения версий.
 *
 * @param {object} object - Данные для запроса.
 * @returns {{type: string, object}} - Жопа.
 * @example
 * -----
 */
export function receiveVersions(object) {
  return {
    object,
    type: types.RECEIVE_VERSIONS,
  };
}

/**
 * Action сброса версий.
 *
 * @returns {{type: string}} - Жопа.
 * @example
 * -----
 */
export function resetVersions() {
  return {
    type: types.RESET_VERSIONS,
  };
}

/**
 * Action toggle Сравнение видимости отчетов.
 *
 * @returns {{type: string}} - Жопа.
 * @example
 * -----
 */
export function toggleCompareReportVisibility() {
  return {
    type: types.TOGGLE_VERSIONS_COMPARE_REPORT,
  };
}

/**
 * Action выбора версий.
 *
 * @param {object} object - Данные.
 * @returns {{type: string, object}} - Жопа.
 * @example
 * -----
 */
export function selectVersion(object) {
  return {
    object,
    type: types.SELECT_VERSION,
  };
}

/**
 * Action отмены выбора версий.
 *
 * @param {object} object - Данные.
 * @returns {{type: string, object}} - Жопа.
 * @example
 * -----
 */
export function deselectVersion(object) {
  return {
    object,
    type: types.DESELECT_VERSION,
  };
}

/**
 * Action requestDiffVersionsProperties.
 *
 * @param {object} object - Object.
 * @returns {{type: string, object}} - Жопа.
 * @example
 * -----
 */
export function requestDiffVersionsProperties(object) {
  return {
    object,
    type: types.REQUEST_DIFF_VERSIONS_PROPERTIES,
  };
}

/**
 * Action receiveDiffVersionsProperties.
 *
 * @param {object} object - Object.
 * @returns {{type: string, object}} - Жопа.
 * @example
 * -----
 */
export function receiveDiffVersionsProperties(object) {
  return {
    object,
    type: types.RECEIVE_DIFF_VERSIONS_PROPERTIES,
  };
}

/**
 * Action fetchDiffVersionsProperties.
 *
 * @param {*} type_id - Atype_id.
 * @param {*} etalon - Aetalon.
 * @param {*} copy - Acopy.
 * @returns {function(*): Promise<unknown>} - Жопа.
 * @example
 * -----
 */
export const fetchDiffVersionsProperties =
  (type_id, etalon, copy) => async (dispatch) => {
    const data = { copy, etalon, type_id };
    dispatch(requestDiffVersionsProperties(data));
    const response = await api.fetchPropsDiff(withSnakeCase(data));
    dispatch(receiveDiffVersionsProperties(response));
    return response;
  };

/**
 * Action requestDiffGeometries.
 *
 * @param {object} object - Object.
 * @returns {{type: string, object}} - Жопа.
 * @example
 * -----
 */
export function requestDiffGeometries(object) {
  return {
    object,
    type: types.REQUEST_DIFF_GEOMETRY,
  };
}

/**
 * Action receiveDiffGeometries.
 *
 * @param {object} object - Object.
 * @returns {{type: string, object}} - Жопа.
 * @example
 * -----
 */
export function receiveDiffGeometries(object) {
  return {
    object,
    type: types.RECEIVE_DIFF_GEOMETRY,
  };
}

/**
 * Action fetchDiffGeometries.
 *
 * @param {*} type_id - Atype_id.
 * @param {*} etalon - Aetalon.
 * @param {*} copy - Acopy.
 * @returns {function(*): Promise<unknown>} - Жопа.
 * @example
 * -----
 */
export const fetchDiffGeometries =
  (type_id, etalon, copy) => async (dispatch) => {
    const data = { copy, etalon, type_id };
    dispatch(requestDiffGeometries(data));
    const response = await api.fetchGeoDiff(data);
    dispatch(receiveDiffGeometries(response));
    return response;
  };

/**
 * Action requestDiffObjectTrees.
 *
 * @param {object} object - Object.
 * @returns {{type: string, object}} - Жопа.
 * @example
 * -----
 */
export function requestDiffObjectTrees(object) {
  return {
    object,
    type: types.REQUEST_DIFF_OBJECT_TREES,
  };
}

/**
 * Action receiveDiffObjectTrees.
 *
 * @param {object} object - Object.
 * @returns {{type: string, object}} - Жопа.
 * @example
 * -----
 */
export function receiveDiffObjectTrees(object) {
  return {
    object,
    type: types.RECEIVE_DIFF_OBJECT_TREES,
  };
}

/**
 * Action fetchDiffObjectTrees.
 *
 * @param {*} type_id - Atype_id.
 * @param {*} etalon - Aetalon.
 * @param {*} copy - Acopy.
 * @returns {function(*): Promise<unknown>} - Жопа.
 * @example
 * -----
 */
export const fetchDiffObjectTrees =
  (type_id, etalon, copy) => async (dispatch) => {
    const data = { copy, etalon, type_id };
    dispatch(requestDiffObjectTrees(data));
    const response = await api.fetchTreeDiff(data);
    dispatch(receiveDiffObjectTrees(response));
    return response;
  };
