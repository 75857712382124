/* eslint-disable */

/**
 * Функция havePrefixedOghPrivilege.
 *
 * @param {string} typeId - Тип ОГХ.
 * @param {*} prefix - Префикс.
 * @param {object} props - Пропсы.
 * @returns {boolean}
 */
const havePrefixedOghPrivilege = (typeId, prefix, props) => {
  const { privileges, oghTypes = [] } = props;
  let requirement = prefix;
  const currentOgh = oghTypes.find(
    (elem) => Number(elem.id) === Number(typeId),
  );

  if (currentOgh) {
    requirement = requirement + currentOgh.code;
  }

  return (privileges || []).indexOf(requirement) !== -1;
};

/**
 * Функция havePrefixedTechPrivilege.
 *
 * @param {string} typeId - Тип ОГХ.
 * @param {*} prefix - Префикс.
 * @param {object} props - Пропсы.
 * @returns {boolean}
 */
const havePrefixedTechPrivilege = (typeId, prefix, props) => {
  const { privileges, techTypes } = props;
  const techType = (techTypes || []).find((item) => item.id === typeId);
  let result;
  if (techType) {
    result = prefix + techType.code;
  } else {
    result = prefix;
  }
  return (privileges || []).indexOf(result) !== -1;
};

/**
 * Функция haveAnyPrivilegeWithPrefix.
 *
 * @param {*} prefix - Префикс.
 * @param {object} props - Пропсы.
 * @returns {boolean}
 */
const haveAnyPrivilegeWithPrefix = (prefix, props) => {
  const { privileges } = props;
  return (privileges || []).some((p) => p.startsWith(prefix));
};

/**
 * Функция havePrivilege.
 *
 * @param {object} paramPrivilege - Привилегия.
 * @returns {boolean}
 */
export const havePrivilege = (paramPrivilege) => (props) => {
  return (props?.privileges || []).indexOf(paramPrivilege) !== -1;
};

/**
 * Функция haveAnyPrivilege.
 *
 * @param {object} paramPrivileges - Массив привилегий.
 * @returns {boolean}
 */
export const haveAnyPrivilege = (paramPrivileges) => (props) => {
  return (props?.privileges || []).some(
    (item) => paramPrivileges.indexOf(item) !== -1,
  );
};

/**
 * Функция havePrivilegeToViewOghRegistry.
 *
 * @param {object} props - Пропсы.
 * @returns {boolean}
 */
export const havePrivilegeToViewOghRegistry = (props) => {
  let routeTypeId;
  if (props.routeParams && props.routeParams.splat) {
    const path = props.routeParams.splat.split('/');
    routeTypeId = path[path.length - 1];
  }
  return (
    props.typeId < 0 ||
    routeTypeId < 0 ||
    havePrefixedOghPrivilege(props.typeId || routeTypeId, 'view_ogh_', props)
  );
};

/**
 * Функция havePrivelegeToViewApiSubscriptions.
 *
 * @returns {boolean}
 */
export const havePrivelegeToViewApiSubscriptions = havePrivilege(
  'edit_setup_subscription',
);

/**
 * Функция havePrivilegeToViewMatchingRegistry.
 *
 * @param {object} props - Пропсы.
 * @returns {boolean}
 */
export const havePrivilegeToViewMatchingRegistry = (props) => {
  return haveAnyPrivilegeWithPrefix('view_matching_ogh_', props);
};

/**
 * Функция havePrivilegeToViewChangelogOgh.
 *
 * @param {object} props - Пропсы.
 * @returns {boolean}
 */
export const havePrivilegeToViewChangelogOgh = (props) => {
  return haveAnyPrivilegeWithPrefix('changelog_ogh', props);
};

/**
 * Функция havePrivilegeToViewMatchingTechRegistry.
 *
 * @param {object} props - Пропсы.
 * @returns {boolean}
 */
export const havePrivilegeToViewMatchingTechRegistry = (props) => {
  return haveAnyPrivilegeWithPrefix('view_matching_technic_', props);
};

/**
 * Функция havePrivilegeToViewMatching.
 *
 * @param {object} props - Пропсы.
 * @returns {boolean}
 */
export const havePrivilegeToViewMatching = (props) => {
  return (
    havePrivilegeToViewMatchingRegistry(props) ||
    havePrivilegeToViewMatchingTechRegistry(props)
  );
};

/**
 * Функция filterTypesToMainPage.
 *
 * @param {object} props - Пропсы.
 * @returns {boolean}
 */
export const filterTypesToMainPage = (props) => {
  return props.grantedTypes.filter((id) => {
    if (id instanceof String) {
      return havePrivilege('view_ogh_' + id);
    } else {
      return havePrefixedOghPrivilege(id, 'view_ogh_', props);
    }
  });
};

/**
 * Функция canViewUserProfile.
 *
 * @param {object} props - Пропсы.
 * @returns {boolean}
 */
export const canViewUserProfile = (props) => {
  return (
    (props?.privileges || []).some((p) => p === 'view_user') ||
    props.routeParams.username === props.curUsername
  ); // пользователь может просмотреть свой собственный профиль
};

/**
 * Функция havePrivilegeToViewSomeOghRegistry.
 *
 * @param {object} props - Пропсы.
 * @returns {boolean}
 */
export const havePrivilegeToViewSomeOghRegistry = (props) => {
  return (props.privileges || []).some((item) => item.startsWith('view_ogh_'));
};

/**
 * Функция havePrivilegeToCreateOghObject.
 *
 * @param {object} props - Пропсы.
 * @returns {boolean}
 */
export const havePrivilegeToCreateOghObject = (props) => {
  return havePrefixedOghPrivilege(
    props.typeId || props.routeParams.typeId,
    'create_ogh_',
    props,
  );
};

/**
 * Функция havePrivilegeToCreateTechObject.
 *
 * @param {object} props - Пропсы.
 * @returns {boolean}
 */
export const havePrivilegeToCreateTechObject = (props) => {
  const requiredPrivilege =
    'create_technic_' + (props.code || props.routeParams.code);
  return (props.privileges || []).some((item) => item === requiredPrivilege);
};

/**
 * Функция havePrivilegeToEditOghObject.
 *
 * @param {object} props - Пропсы.
 * @returns {boolean}
 */
export const havePrivilegeToEditOghObject = (props) => {
  return havePrefixedOghPrivilege(props.typeId, 'edit_ogh_', props);
};

/**
 * Проверка возможности создания МСО объекта.
 *
 * @param {object} props - Пропсы.
 * @returns {boolean}
 */
export const havePrivilegeToCreateMso = (props) => {
  const privilegeForCreateMso = ['create_ogh_outside_container'];

  return haveAnyPrivilege(privilegeForCreateMso)(props);
};

/**
 * Функция havePrivilegeToEditTechObject.
 *
 * @param {object} props - Пропсы.
 * @returns {boolean}
 */
export const havePrivilegeToEditTechObject = (props) => {
  return havePrefixedTechPrivilege(props.typeId, 'edit_technic_', props);
};

/**
 * Функция havePrivilegeToDeleteOghObject.
 *
 * @param {object} props - Пропсы.
 * @returns {boolean}
 */
export const havePrivilegeToDeleteOghObject = (props) => {
  return havePrefixedOghPrivilege(props.typeId, 'del_ogh_', props);
};

/**
 * Функция havePrivilegeToDeleteTechObject.
 *
 * @param {object} props - Пропсы.
 * @returns {boolean}
 */
export const havePrivilegeToDeleteTechObject = (props) => {
  return havePrefixedTechPrivilege(props.typeId, 'del_technic_', props);
};

/**
 * Функция havePrivilegeToSendMatchingOgh.
 *
 * @param {object} props - Пропсы.
 * @returns {boolean}
 */
export const havePrivilegeToSendMatchingOgh = (props) => {
  return havePrefixedOghPrivilege(props.typeId, 'send_matching_ogh_', props);
};

/**
 * Функция havePrivilegeToSendMatchingTech.
 *
 * @param {object} props - Пропсы.
 * @returns {boolean}
 */
export const havePrivilegeToSendMatchingTech = (props) => {
  return havePrefixedTechPrivilege(
    props.typeId,
    'send_matching_technic_',
    props,
  );
};

/**
 * Функция havePrivilegeToMatchOgh.
 *
 * @param {object} props - Пропсы.
 * @returns {boolean}
 */
export const havePrivilegeToMatchOgh = (props) => {
  return havePrefixedOghPrivilege(props.typeId, 'matching_ogh_', props);
};

/**
 * Функция havePrivilegeToMatchTech.
 *
 * @param {object} props - Пропсы.
 * @returns {boolean}
 */
export const havePrivilegeToMatchTech = (props) => {
  return havePrefixedTechPrivilege(props.typeId, 'matching_technic_', props);
};

/**
 * Функция havePrivilegeToMatchTitle.
 *
 * @param {object} props - Пропсы.
 * @returns {boolean}
 */
export const havePrivilegeToMatchTitle = (props) => {
  return havePrefixedOghPrivilege(props.typeId, 'matching_title_', props);
};

/**
 * Функция havePrivilegeToSendMatchingTitle.
 *
 * @param {object} props - Пропсы.
 * @returns {boolean}
 */
export const havePrivilegeToSendMatchingTitle = (props) => {
  return havePrefixedOghPrivilege(props.typeId, 'send_matching_title_', props);
};

/**
 * Функция havePrivilegeToDeleteTitle.
 *
 * @param {object} props - Пропсы.
 * @returns {boolean}
 */
export const havePrivilegeToDeleteTitle = (props) => {
  return havePrefixedOghPrivilege(props.typeId, 'del_title_', props);
};

/**
 * Функция havePrivilegeToViewAnyTitle.
 *
 * @param {object} props - Пропсы.
 * @returns {boolean}
 */
export const havePrivilegeToViewAnyTitle = (props) => {
  return (props.privileges || []).some((item) => item.startsWith('view_title'));
};

/**
 * Функция grantedViewTitleMatchingRegistry.
 *
 * @param {object} props - Пропсы.
 * @returns {boolean}
 */
export const grantedViewTitleMatchingRegistry = (props) => {
  return havePrefixedOghPrivilege(props.typeId, 'view_matching_title_', props);
};

/**
 * Функция grantedViewOghMatchingRegistry.
 *
 * @param {object} props - Пропсы.
 * @returns {boolean}
 */
export const grantedViewOghMatchingRegistry = (props) => {
  return havePrefixedOghPrivilege(props.typeId, 'view_matching_ogh_', props);
};

/**
 * Функция havePrivilegeToViewTechRegistry.
 *
 * @param {object} props - Пропсы.
 * @returns {boolean}
 */
export const havePrivilegeToViewTechRegistry = (props) => {
  let routeTypeId = props.routeParams && props.routeParams.typeId;
  return havePrefixedTechPrivilege(
    props.typeId || parseInt(routeTypeId, 10),
    'view_technic_',
    props,
  );
};

/**
 * Функция havePrivilegeToViewSomeTechRegistry.
 *
 * @param {object} props - Пропсы.
 * @returns {boolean}
 */
export const havePrivilegeToViewSomeTechRegistry = (props) => {
  return (props.privileges || []).some((item) =>
    item.startsWith('view_technic_'),
  );
};

/**
 * Функция havePrivilegeToViewSomeAdministration.
 *
 * @param {object} props - Пропсы.
 * @returns {boolean}
 */
export const havePrivilegeToViewSomeAdministration = (props) => {
  const privilegeForAdministrationPage = [
    'nsi',
    'view_group',
    'view_user',
    'template_matching',
  ];

  return haveAnyPrivilege(privilegeForAdministrationPage)(props);
};

/**
 * Функция havePrivilegeToViewReportRegistry.
 *
 * @param {object} props - Пропсы.
 * @returns {boolean}
 */
export const havePrivilegeToViewReportRegistry = (props) => {
  const privilegeForReportPage = [
    'reports_technic_car',
    'reports_technic_gps',
    'reports_technic_equipment',
    'reports_technic_sensor',
    'changelog_ogh',
  ];

  return haveAnyPrivilege(privilegeForReportPage)(props);
};

/**
 * Функция havePrivilegeToViewSomePgmRegistry.
 *
 * @param {object} props - Пропсы.
 * @returns {boolean}
 */
export const havePrivilegeToViewSomePgmRegistry = (props) => {
  const privilegeForPgmPage = [
    'fullness_base_pgm',
    'export_reagent_base_pgm',
    'view_doc_reagent_norm',
  ];

  return haveAnyPrivilege(privilegeForPgmPage)(props);
};
