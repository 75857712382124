import 'assets/styles/card.versions.scss';

import {
  fetchSearchVersionsResults,
  resetVersions,
} from 'app/actions/odsObjectVersionsActions';
import DialogMatchingRegistryComment from 'app/components/common/modal-windows/DialogMatchingRegistryComment';
import RegistryForm from 'app/components/registry/form/index';
import getVersionUrlFunction from 'app/utils/card/getVersionUrlFunction';
import cn from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import { TableVersions } from './Table.Versions';

/**
 * Versions.
 *
 * @returns {JSX.ElementClass} - Жопа.
 */
class Versions extends React.Component {

  /**
   * Method constructor.
   *
   * @param {object} props - Props.
   * @example
   * ---------
   */
  constructor(props) {
    super(props);
    this.state = {
      comment: '',
      isDialogMatchingRegistryCommentOpen: false,
      versionCountError: false,
    };
  }

  /**
   * Method componentDidMount.
   *
   * @returns {void} - Nothing.
   * @example
   * ---------
   */
  componentDidMount() {
    this.props.dispatch(resetVersions());
    // рисуем таблицу по умолчанию
    if (this.props.card.root_id) {
      this.props.dispatch(
        fetchSearchVersionsResults({
          root_id: this.props.card.root_id,
          type_id: this.props.card.type_id,
          ...this.props.tablesConfig[this.tableName],
        }),
      );
    }
  }

  /**
   * Method componentDidUpdate.
   *
   * @param {object} prevProps - PrevProps.
   * @returns {void} - Nothing.
   * @example
   * ---------
   */
  componentDidUpdate = (prevProps) => {
    const { card } = this.props;
    if (card.type_id !== prevProps.card.type_id) {
      this._searchForm.form.reset();
      this.props.dispatch(resetVersions());
    }
  };
  formName = 'versions-compare-form';
  tableName = 'versionsSearch';
  //
  // /**
  //  * Метод showComment.
  //  *
  //  * @param {Event} event - Event.
  //  * @param {string} comment - Комментарий.
  //  * @returns {void} - Nothing.
  //  * @example ----.
  //  */
  // showComment = (event, comment) => {
  //   event.preventDefault();
  //   this.setState({
  //     comment,
  //     isDialogMatchingRegistryCommentOpen: true,
  //   });
  // };
  //
  // hideComment = () => {
  //   this.setState({
  //     isDialogMatchingRegistryCommentOpen: false,
  //   });
  // };
  //

  /**
   * PrepareFormValues.
   *
   * @param {object} formValues - FormValues.
   * @returns {{state_id: *}} - Жопа.
   * @example
   * ----
   */
  prepareFormValues(formValues) {
    return {
      ...formValues,
      state_id: formValues.state_id[0] ? formValues.state_id : null,
    };
  }

  /**
   * Render.
   *
   * @returns {JSX.Element} - JSX.
   * @example
   * -----
   */
  render() {
    const { card, disabled, dispatch, odsDicts } = this.props;
    const { isDialogMatchingRegistryCommentOpen, comment } = this.state;

    /**
     * Function getComponents.
     *
     * @returns {Array<string>} - Жопа.
     * @example
     * ---------
     */
    const getComponents = () => {
      const components = [
        'dateFrom',
        'dateTo',
        'status',
        'submitButton',
        'resetButton',
      ];
      components.unshift('customer');
      components.unshift('owner');
      return components;
    };

    let formValuesLocal = {
      date_from: '01.01.1900',
      date_to: '01.01.3000',
      root_id: card.root_id,
      type_id: card.type_id,
      ...this.props.tablesConfig[this.tableName],
    };

    return (
      <div
        className={cn(
          'd-flex flex-column h-100',
          'versions',
          this.props.className,
        )}
        id="versions"
      >
        {isDialogMatchingRegistryCommentOpen && (
          <DialogMatchingRegistryComment
            comment={comment}
            hideComment={this.hideComment}
            open={isDialogMatchingRegistryCommentOpen}
          ></DialogMatchingRegistryComment>
        )}

        <h4 className="mt-4">Версии объекта</h4>

        <RegistryForm
          components={getComponents()}
          custom={{
            address: {
              disabled,
            },
            customer: {
              disabled,
              id: 'u_customer_id',
              name: 'customer_id',
              placeholder: 'Все',
            },
            dateFrom: {
              disabled,
              name: 'date_from',
            },
            dateTo: {
              disabled,
              name: 'date_to',
            },
            district: {
              disabled,
            },
            listTypeId: {
              disabled,
            },
            okrug: {
              disabled,
            },
            owner: {
              disabled,
              id: 'u_owner_id',
              name: 'owner_id',
            },
            resetButton: {
              disabled,
              primary: true,
            },
            status: {
              disabled,
              name: 'state_id',
            },
            subTypeId: {
              disabled,
            },
            submitButton: {
              disabled,
              primary: true,
            },
            typeId: {
              disabled,
            },
          }}
          dark={false}
          id={this.formName}
          oghStatuses={odsDicts?.oghStatuses}
          ref={(form) => {
            this._searchForm = form;
          }}
          typeId={card.type_id}
          onSubmit={(formValues) => {
            formValuesLocal = {
              date_from: '01.01.1900',
              date_to: '01.01.3000',
              root_id: card.root_id,
              type_id: card.type_id,
              ...this.prepareFormValues(formValues),
              ...this.props.tablesConfig[this.tableName],
            };
            dispatch(fetchSearchVersionsResults(formValuesLocal));
          }}
        />

        <TableVersions
          data={this.props.data}
          tableName={this.props.tableName}
          formName={this.props.formName}
        />
      </div>
    );
  }
}

const getVersionUrl = getVersionUrlFunction('ogh');

/**
 * Function mapStateToProps.
 *
 * @param {object} state - State.
 * @returns {{odsDicts: object, data: object, columns: Array, selectedVersions: Array, tablesConfig: object}} - Жопа.
 * @example
 * --------
 */
function mapStateToProps(state) {
  return {
    // columns: getOghVersionsColumns(state),
    data: state.card.versions,
    getVersionUrl,
    odsDicts: state.odsDicts,
    selectedVersions: state.card.selectedVersions,
    tablesConfig: state.tables,
  };
}

export default connect(mapStateToProps)(Versions);
