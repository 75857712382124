import NavItem from 'app/components/common/NavBar/NavItem';
import { useUser } from 'app/pages/providers';
import { pathMatching } from 'app/pages/registry/Matching/path.Matching';
/* eslint-disable */
import React, { lazy, useEffect } from 'react';

function lazyWithPreload(factory) {
  const Component = lazy(factory);
  Component.preload = factory;
  return Component;
}

const Matching = lazyWithPreload(() => import('app/pages/registry/Matching'));

 
export const OghMatchingRegistryNav = () => {
  const { hasPermission } = useUser();

  const isShow = hasPermission(pathMatching.privilege);

  useEffect(() => {
    isShow && Matching.preload();
  }, [isShow]);

  return isShow ? (
    <NavItem link={`/r/${pathMatching.path}`}>{pathMatching.name}</NavItem>
  ) : null;
};
