// 21 Троллейбусные контактные сети
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import { BordBeginField } from 'app/components/card/ogh/components/fields/numberFields/BordBegin.Field';
import { BordEndField } from 'app/components/card/ogh/components/fields/numberFields/BordEnd.Field';
import { DistanceField } from 'app/components/card/ogh/components/fields/numberFields/Distance.Field';
import { OdhSideIdField } from 'app/components/card/ogh/components/fields/selectFields/OdhSideId.Field';
import { DescriptionField } from 'app/components/card/ogh/components/fields/textFields/Description.Field';
import { OdhAxisField } from 'app/components/card/ogh/components/fields/textFields/OdhAxis.Field';
import {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import isMandatory, { mandatoryFields } from 'app/utils/card/isMandatory';
import { transformValues } from 'app/utils/form/transformation';
import { NumberFieldRF } from 'core/form/reduxForm/fields';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import { OtherDocumentsRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/OtherDocuments.RF';
import { CardContainer } from 'core/uiKit/components/card.components/CardContainer';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { GroupContainer } from 'core/uiKit/components/card.components/GroupContainer';
import { TabsCardOGH } from 'core/uiKit/components/card.components/TabsCardOGH';
import { TabsCardOGHItem } from 'core/uiKit/components/card.components/TabsCardOGH/TabsCardOGH.Item';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import printOghCardHeader from './header/oghHeader';
import submitCard from './submit';
import VersionsTemplate from './versions/VersionsTemplate';

const types = ['trolleybus_contact_network'];
const Versions = VersionsTemplate(types);

const propTypes = {
  card: PropTypes.object,
  createMode: PropTypes.bool,
  documents: PropTypes.array,
  editMode: PropTypes.bool,
  mode: PropTypes.object,
  required: PropTypes.bool,
};

/**
 * Карточка __Троллейбусные контактные сети__.
 *
 * @param {object} props - Пропсы.
 * @returns {React.ReactElement}
 */
const TrolleybusContactNetworkCard = (props) => {
  const {
    mode,
    card,
    createMode,
    editMode,
    required,
    isEditCurrentObjectIsExternalSystem,
  } = props;

  return (
    <CardContainer>
      {printOghCardHeader(card, mode)}
      <TabsCardOGH>
        <TabsCardOGHItem tabTitle={'Свойства'}>
          <FieldsSplitColumns>
            <SelectRF
              name={'net_element_type_id'}
              label={'Тип'}
              options={card.net_element_type}
              required={isMandatory(
                card.type_id,
                'net_element_type_id',
                required,
              )}
              disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
            />

            <ParentInfo />
          </FieldsSplitColumns>

          <TabsCardOGH>
            <TabsCardOGHItem tabTitle={'Исходные данные'}>
              <GroupContainer title={'Местоположение'}>
                <FieldsSplitColumns>
                  <OdhAxisField />
                  <OdhSideIdField />
                  <BordBeginField />
                  <BordEndField />
                </FieldsSplitColumns>
              </GroupContainer>

              <GroupContainer title={'Характеристики'}>
                <FieldsSplitColumns>
                  <NumberFieldRF
                    name={'quantity'}
                    label={'Количество контактных сетей, шт.'}
                    disabled={
                      !(editMode && isEditCurrentObjectIsExternalSystem)
                    }
                    required={isMandatory(card.type_id, 'quantity', required)}
                  />
                  <DistanceField label={'Длина контактных сетей, п.м'} />
                </FieldsSplitColumns>
              </GroupContainer>

              <DescriptionField />
            </TabsCardOGHItem>
            <TabsCardOGHItem tabTitle={'Документы'}>
              <OtherDocumentsRF />
            </TabsCardOGHItem>
          </TabsCardOGH>
        </TabsCardOGHItem>
        <TabsCardOGHItem tabTitle={'Версии'}>
          <Versions
            card={card}
            disabled={createMode}
            key={`versions-${card.root_id}`}
          />
        </TabsCardOGHItem>
      </TabsCardOGH>
    </CardContainer>
  );
};

TrolleybusContactNetworkCard.propTypes = propTypes;

/**
 * Функция валидации.
 *
 * @param {object} values - Значения формы.
 * @returns {object | null}
 */
const validate = (values) => validateNumeric(values, numericFields);

const dictFields = ['odh_side_id', 'net_element_type_id'];

const numericFields = [
  { name: 'quantity', positive: true, type: 'integer', zero: false },
  { name: 'bord_begin', type: 'decimal', zero: true },
  { name: 'bord_end', type: 'decimal', zero: true },
  { name: 'distance', positive: true, type: 'decimal', zero: false },
];

const trolleybusContactNetworkCardDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

/**
 * Create attribute from form.
 *
 * @param {*} formValues - Form values.
 * @returns {*}
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

/**
 * On submit handler.
 *
 * @param {*} formValues - Form values.
 * @returns {void}
 */
export function onSubmit(formValues) {
  const { card } = this.props;
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };
  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[card.type_id],
  );
  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, { dictFields, numericFields }),
  );
}

/**
 * Функция mapStateToProps.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {*}
 */
const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);

  const result = {
    createMode: isCreateMode(state, props),

    editMode: isEditMode(state, props),

    enableReinitialize: true,
    initialValues: {
      bord_begin: parse('bord_begin') || '0.00',
      bord_end: parse('bord_end') || '0.00',
      customer_id: parse('customer_id'),
      description: parse('description'),
      distance: parse('distance'),
      endDate: parseDate(props.card.end_date),
      net_element_type_id: parse('net_element_type_id.net_element_type'),
      odh_axis: parse('odh_axis'),
      odh_side_id: parse('odh_side_id.odh_side'),
      owner_id: parse('owner_id'),
      quantity: parse('quantity'),
      startDate: parseDate(props.card.start_date),
    },
    onSubmit: onSubmit.bind({ props }),

    required: isRequired(state, props),
    validate,
  };
  return trolleybusContactNetworkCardDocumentsReducer(result, state, props);
};

export default connect(mapStateToProps)(
  reduxForm({ form: 'editorCard' })(TrolleybusContactNetworkCard),
);
