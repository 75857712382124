import { printReport } from 'app/pages/registry/ogh/api';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

/* eslint-disable */
export const usePrintReport = () => {
  const mutationOptions = {
    onSuccess: () => {},
  };
  // summercleaning
  const [
    sendSummerCleaning,
    { isLoading: loadingSummerCleaning },
  ] = useMutationAdaptor(
    printReport('dt_cleaning_summer'),
    mutationOptions,
  );

  const [
    sendSummerCleaningWithSummary,
    { isLoading: loadingSummerCleaningWithSummary },
  ] = useMutationAdaptor(
    printReport('dt_cleaning_summer_summary'),
    mutationOptions,
  );

  // wintercleaning
  const [
    sendWinterCleaning,
    { isLoading: loadingWinterCleaning },
  ] = useMutationAdaptor(
    printReport('dt_cleaning_winter'),
    mutationOptions,
  );

  const [
    sendWinterCleaningWithSummary,
    { isLoading: loadingWinterCleaningWithSummary },
  ] = useMutationAdaptor(
    printReport('dt_cleaning_winter_summary'),
    mutationOptions,
  );

  // dtclassification
  const [
    sendDtClassification,
    { isLoading: loadingDtClassification },
  ] = useMutationAdaptor(
    printReport('dt_classification'),
    mutationOptions,
  );

  const [
    sendDtClassificationWithSummary,
    { isLoading: loadingDtClassificationWithSummary },
  ] = useMutationAdaptor(
    printReport('dt_classification_summary'),
    mutationOptions,
  );

  // maf
  const [sendMaf, { isLoading: loadingMaf }] =
    useMutationAdaptor(
      printReport('dt_maf'),
      mutationOptions,
    );

  const [
    sendMafWithSummary,
    { isLoading: loadingMafWithSummary },
  ] = useMutationAdaptor(
    printReport('dt_maf_summary'),
    mutationOptions,
  );

  // greening
  const [sendGreening, { isLoading: loadingGreening }] =
    useMutationAdaptor(
      printReport('dt_greening'),
      mutationOptions,
    );

  const [
    sendGreeningWithSummary,
    { isLoading: loadingGreeningWithSummary },
  ] = useMutationAdaptor(
    printReport('dt_greening_summary'),
    mutationOptions,
  );

  // cover
  const [sendCover, { isLoading: loadingCover }] =
    useMutationAdaptor(
      printReport('dt_cover'),
      mutationOptions,
    );

  const [
    sendCoverWithSummary,
    { isLoading: loadingCoverWithSummary },
  ] = useMutationAdaptor(
    printReport('dt_cover_summary'),
    mutationOptions,
  );

  // tl/dt
  const [sendDt, { isLoading: loadingDt }] =
    useMutationAdaptor(
      printReport('dt_tl'),
      mutationOptions,
    );

  const [
    sendDtWithSummary,
    { isLoading: loadingDtWithSummary },
  ] = useMutationAdaptor(
    printReport('dt_tl_summary'),
    mutationOptions,
  );

  // fullexport
  const [sendFullExport, { isLoading: loadingFullExport }] =
    useMutationAdaptor(
      printReport('dt_full_registry'),
      mutationOptions,
    );

  return {
    sendSummerCleaning,
    loadingSummerCleaning,
    sendSummerCleaningWithSummary,
    loadingSummerCleaningWithSummary,
    sendWinterCleaning,
    loadingWinterCleaning,
    sendWinterCleaningWithSummary,
    loadingWinterCleaningWithSummary,

    sendDtClassification,
    loadingDtClassification,
    sendDtClassificationWithSummary,
    loadingDtClassificationWithSummary,

    sendMaf,
    loadingMaf,
    sendMafWithSummary,
    loadingMafWithSummary,

    sendGreening,
    loadingGreening,
    sendGreeningWithSummary,
    loadingGreeningWithSummary,

    sendCover,
    loadingCover,
    sendCoverWithSummary,
    loadingCoverWithSummary,

    sendDt,
    loadingDt,
    sendDtWithSummary,
    loadingDtWithSummary,

    sendFullExport,
    loadingFullExport,
  };
};
