import { vi } from 'vitest';

import { ImprovementObjectCard } from './ImprovementObjectCard';

vi.mock('./ImprovementTerritoryCardTemplate', () => ({
  default: vi.fn((param) => param),
}));
vi.mock('./components/RepairsPlanTable');

describe('spec ImprovementObjectCard', function () {
  it('ImprovementTerritoryCardTemplate', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act

    // Assert
    expect(ImprovementObjectCard).toStrictEqual({
      mapDispatchToPropsReducers: expect.any(Array),
      mapStateToPropsReducers: expect.any(Array),
      propsTabElementRenders: expect.any(Array),
    });
  });
});
