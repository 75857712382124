 
export const METERS = 'meters';
 
export const METERS_SQUARE = 'square_meters';
 
export const METERS_LONG = 'running_meters';
 
export const NUMBERS = 'units';
 
export const PIECES = 'things';
 
export const TREE = 'tree';
 
export const BUSH = 'bush';
 
export const HECTARE = 'hectare';
 
export const RUBLES = 'rubles';
 
export const TONS = 'tons';
 
export const METERS_CUBIC = 'cubic_meters';
 
export const LITRES = 'liters';
