import { ENGINEER_BUILDING } from 'app/constants/oghTypes';
import { useEditableCoatingFields } from 'app/pages/cardsOgh/EngineerBuilding/fields/coatingFields/useEditable.CoatingFields';
import { COATING_GROUP_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingGroupId';
import { COATING_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingTypeId';
import { useEffect } from 'react';
import { useField } from 'react-final-form';

/**
 * Setup Вид покрытия (уточнение).
 *
 * @returns {{disabled: boolean, coatingGroupIdValue: any, required: boolean}}
 */
export const useSetupCoatingTypeIdFFField = () => {
  const { disabled, required } = useEditableCoatingFields();
  const {
    input: { value: coatingGroupIdValue },
  } = useField(COATING_GROUP_ID_NAME);

  const {
    input: { onChange },
  } = useField(COATING_TYPE_ID_NAME);

  useEffect(() => {
    if (!required) {
      onChange('');
    }

    // eslint-disable-next-line
  }, [required]);

  /**
   * Функция фильтрации.
   *
   * @param {object} dict - Справочные значения.
   * @returns {*}
   */
  const filter = (dict) => {
    return dict.filter(
      (item) =>
        item.group_id === coatingGroupIdValue &&
        (item?.allowed_object_type_list || []).includes(ENGINEER_BUILDING),
    );
  };

  return { coatingGroupIdValue, disabled, filter, required };
};
