import NavItem from 'app/components/common/NavBar/NavItem';
import { useUser } from 'app/pages/providers/UserContext';
import { pathCertificatePassportOGHApplication } from 'app/pages/registry/certificationOfOgh/CertificatePassportOGH.Application/path.CertificatePassportOGHApplication';
import { pathCertificationOfOgh } from 'app/pages/registry/certificationOfOgh/path.CertificationOfOgh';
import { pathRegistry } from 'app/pages/registry/path.Registry';
import React from 'react';

/**
 * Link Админки новостей в шапке сайта.
 *
 * @returns {JSX.Element | null} - JSX.
 */
export const CertificatePassportOGHApplicationLink = () => {
  const { hasPermission } = useUser();

  const isShow = hasPermission(
    pathCertificatePassportOGHApplication.privilegeView,
  );

  return isShow ? (
    <NavItem
      link={`/${pathRegistry.path}/${pathCertificationOfOgh.path}/${pathCertificatePassportOGHApplication.path}`}
    >
      {pathCertificatePassportOGHApplication.name}
    </NavItem>
  ) : null;
};
