import { useGetCardSelector } from 'app/selectors/useSelectors/useGetCard.Selector';
import KeyboardDateTimePickerRF from 'core/form/reduxForm/fields/default/datePickers/KeyboardDateTimePickerRF';

/**
 * Поле __Действует с__.
 *
 * @returns {JSX.Element}
 */
export const KeyboardDateTimeStartComponent = () => {
  const { id } = useGetCardSelector();
  return (
    <KeyboardDateTimePickerRF
      {...{
        disabled: true,
        label: 'Действует с',
        name: id ? 'startDate' : null,
      }}
    />
  );
};
