/* eslint-disable  */
import { resetTableMafRfidCache } from 'app/pages/registry/ogh/MafRfid/useSetup.Table.MafRfid';
import { toast } from 'core/uiKit/components/Toast';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import { useState } from 'react';

import { setMafLink } from './setMafLink';

/**
 *
 * @param root0
 * @param root0.rootId
 */
export const useIdRfidCell = ({ rootId }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [sendSave, { isLoading: isLoadingSave } = {}] = useMutationAdaptor(
    setMafLink({ rootId }),
    {
      /**
       *
       * @param value
       */
      onError: (value) => {
        toast.error(value?.message || 'не известная ошибка');
      },

      /**
       *
       */
      onSuccess: () => {
        toast.success('Изменения внесены');
        setIsOpen(false);
        return resetTableMafRfidCache();
      },
    },
  );

  return { isLoadingSave, isOpen, sendSave, setIsOpen };
};
