// import getBasePgmExportsColumns from 'app/utils/getBasePgmExportsColumns';

// import getBasePgmOperationsColumns from 'app/utils/getBasePgmOperationsColumns';
// import getChangeLogColumns from 'app/utils/getChangeLogColumns';
// import getMatchingRegistryColumns from 'app/utils/getMatchingRegistryColumns';
// import getMatchingTechRegistryColumns from 'app/utils/getMatchingTechRegistryColumns';
import getOghRegistryColumns from 'app/utils/getOghRegistryColumns';
// import getOghVersionsColumns from 'app/utils/getOghVersionsColumns';
// import getOrderDetailsColumns from 'app/utils/getOrderDetailsColumns';
// import getOrdersColumns from 'app/utils/getOrdersColumns';
// import * as reportColumns from 'app/utils/getReportColumns';
// import getRoleRegistryColumns from 'app/utils/getRoleRegistryColumns';
// import * as technologyRegistryColumns from 'app/utils/getTechnologyRegistryColumns'; // DEPRECATED
// import * as techRegistryColumns from 'app/utils/getTechRegistryColumns';
// import getTemplateRegistryColumns from 'app/utils/getTemplateRegistryColumns';
// import getTlHistoryColumns from 'app/utils/getTlHistoryColumns';
// import getTlRegistryColumns from 'app/utils/getTlRegistryColumns';
// import {
//   defaultColumns,
//   improvementObjectColumns,
//   odhColumns,
//   oznColumns,
//   yardColumns,
// } from 'app/utils/getTlViewColumns';
// import getUserRegistryColumns from 'app/utils/getUserRegistryColumns';
// import getUserRolesColumns from 'app/utils/getUserRolesColumns';

const oghRegistryColumns = getOghRegistryColumns();
// const tlRegistryColumns = getTlRegistryColumns();
// const roleRegistryColumns = getRoleRegistryColumns();
// const userRegistryColumns = getUserRegistryColumns();
// const oghVersionsColumns = getOghVersionsColumns();
// const templateRegistryColumns = getTemplateRegistryColumns();
// const objectMatchingColumns = getObjectMatchingColumns();
// const userRolesColumns = getUserRolesColumns();
// const matchingRegistryColumns = getMatchingRegistryColumns();
// const matchingTechRegistryColumns = getMatchingTechRegistryColumns();
// const changeLogColumns = getChangeLogColumns();
// const tlHistoryColumns = getTlHistoryColumns();
// const ordersColumns = getOrdersColumns();
// const orderDetailsColumns = getOrderDetailsColumns();
// const basePgmOperationsColumns = getBasePgmOperationsColumns();
// const basePgmExportsColumns = getBasePgmExportsColumns();

// TODO: удалить все в 52 версии
//

/**
 * Get table columns.
 *
 * @param {{}} state - State.
 * @returns {{}}
 */
export default function tableColumns(
  state = {

    /**
     * @deprecated Удалять смело.
     */
    objectMatchingRegistry: [],
    oghRegistry: oghRegistryColumns,
    // tlRegistry: tlRegistryColumns,
    // tlView: defaultColumns,
    // tlViewOdh: odhColumns,
    // tlViewYard: yardColumns,
    // tlViewImprovementObject: improvementObjectColumns,
    // tlViewOzn: oznColumns,
    // roleRegistry: roleRegistryColumns,
    // userRegistry: userRegistryColumns,
    // oghVersions: oghVersionsColumns,
    // templateRegistry: templateRegistryColumns,
    // changeLogRegistry: changeLogColumns,
    // // {{{ DEPRECATED
    // technologyVersionsCar:
    //   technologyRegistryColumns.getTechnologyVersionsCarColumns(),
    // technologyVersionsBnso:
    //   technologyRegistryColumns.getTechnologyVersionsBnsoColumns(),
    // technologyVersionsEquipment:
    //   technologyRegistryColumns.getTechnologyVersionsEquipmentColumns(),
    // //technologyRegistryCar: technologyRegistryColumns.getTechnologyRegistryCarColumns(),
    // technologyRegistryGlonass:
    //   technologyRegistryColumns.getTechnologyRegistryGlonassColumns(),
    // technologyRegistrySensor:
    //   technologyRegistryColumns.getTechnologyRegistrySensorColumns(),
    // technologyRegistryCarTreeEdit:
    //   technologyRegistryColumns.getTechnologyTreeEditRegistryCarColumns(),
    // technologyRegistryGlonassTreeEdit:
    //   technologyRegistryColumns.getTechnologyTreeEditRegistryGlonassColumns(),
    // technologyRegistrySensorTreeEdit:
    //   technologyRegistryColumns.getTechnologyTreeEditRegistrySensorColumns(),
    // // }}}
    // technologyReport: reportColumns.getTechnologyReportColumns(),
    // equipmentReport: reportColumns.getEquipmentReportColumns(),
    // bnsoReport: reportColumns.getBnsoReportColumns(),
    // sensorsReport: reportColumns.getSensorsReportColumns(),
    // technologyTechRegistry:
    //   techRegistryColumns.getTechnologyTechRegistryColumns(),
    // bnsoTechRegistry: techRegistryColumns.getBnsoTechRegistryColumns(),
    // equipmentTechRegistry:
    //   techRegistryColumns.getEquipmentTechRegistryColumns(),

    // userRoles: userRolesColumns,
    // matchingRegistry: matchingRegistryColumns,
    // matchingTechRegistry: matchingTechRegistryColumns,
    // tlHistory: tlHistoryColumns,
    // orders: ordersColumns,
    // orderDetails: orderDetailsColumns,
    // basePgmOperations: basePgmOperationsColumns,
    // basePgmExports: basePgmExportsColumns,
  },
) {
  return state;
}
