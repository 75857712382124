/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { elementFormGrid } from 'app/components/card/common/grid';
import DialogAddCarFile from 'app/components/dialogs/technicsRegistry/common/DialogAddCarFile';

import FileListField from 'core/components/FileListField';

import Files from 'app/domain/Files';
import { TECH } from 'app/domain/Files/Files';
import { column } from 'app/constants/tables';

const propTypes = {
  typeId: PropTypes.number,
  oldValues: PropTypes.object,
  technicFileTypes: PropTypes.array,
  isEditable: PropTypes.func.isRequired,
  changeFieldValue: PropTypes.func.isRequired,
};

/* eslint-disable */
export default function CarDocs(props) {
  const getColumns = () => {
    return [
      {
        key: 'file_name',
        screenName: 'Наименование файла',
      },
      {
        key: 'type_id',
        screenName: 'Тип файла',
        mapper: (value) => {
          const result = props.technicFileTypes.find(
            (item) => item.id === value,
          );
          return result ? result.name : null;
        },
      },
      {
        key: 'comment',
        screenName: 'Комментарий',
      },
      {
        key: 'create_date',
        screenName: 'Дата присоединения',
      },
    ];
  };

  const getElements = () => {
    const { isEditable, changeFieldValue } = props;

    return [
      {
        id: 'file_list',
        name: 'file_list',
        label: '',
        editable: isEditable('file_list'),
        style: {
          overflow: 'auto',
          width: 'calc(100% - 24px)',
        },
        formValue: true,
        component: FileListField,
        addition: {
          oldValue: props.oldValues.file_list,
          accept: Files.getAllowedMIMETypes(TECH),
          allowedFormatsMessage: Files.getAllowedFormatsMessage(TECH),
          technicFileTypes: props.technicFileTypes,
          columns: getColumns(),
          changeFieldValue: changeFieldValue,
          renderAddFileDialog: ({
            accept,
            allowedFormatsMessage,
            open,
            onCancel,
            onSubmit,
          }) => (
            <DialogAddCarFile
              accept={accept}
              allowedFormatsMessage={allowedFormatsMessage}
              changeFieldValue={changeFieldValue}
              showCondition={open}
              technicFileTypes={props.technicFileTypes}
              onCancel={onCancel}
              onSubmit={onSubmit}
            />
          ),
        },
      },
    ];
  };

  return elementFormGrid(getElements(), column.TWO);
}

CarDocs.propTypes = propTypes;
