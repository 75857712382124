import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { CleaningTypeIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/CleaningTypeId.FF';
import { CLEAN_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CleaningTypeId';
import { ENGINEER_BUILDING_TYPE_ID } from 'core/uiKit/preparedInputs/selects/simpleSelect/EngineerBuildingTypeId';
import { useDictEngineerBuildingTypeId } from 'core/uiKit/preparedInputs/selects/simpleSelect/EngineerBuildingTypeId/useDict.EngineerBuildingTypeId';
import { UNIT_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/UnitId';
import React, { useEffect } from 'react';
import { useField } from 'react-final-form';

/**
 * Select __Способ уборки__.
 *
 * @returns {JSX.Element}
 * @class
 */
export const CleaningTypeIdFFField = () => {
  const { data } = useDictEngineerBuildingTypeId();
  const { editMode } = useEditMode();

  const {
    input: { value: engineerBuildingTypeIdValue },
  } = useField(ENGINEER_BUILDING_TYPE_ID);

  const {
    input: { onChange },
  } = useField(CLEAN_TYPE_ID_NAME);

  const {
    input: { value: unitIdValue },
  } = useField(UNIT_ID_NAME);

  /**
   * Функция фильтрации.
   *
   * @param {object} dict - Справочные значения Способа уборки.
   * @returns {object}
   */
  const filter = (dict) => {
    const engineerBuildingTypeIdObject = (data || []).find(
      (item) => item.id === engineerBuildingTypeIdValue,
    );

    return dict.filter((item) =>
      (engineerBuildingTypeIdObject?.clean_type_list || []).includes(item.id),
    );
  };

  const editable =
    editMode &&
    engineerBuildingTypeIdValue &&
    // Если поле "Единица измерения" не Штуки,
    unitIdValue !== 5;

  useEffect(() => {
    if (unitIdValue === 5) {
      onChange();
    }

    // eslint-disable-next-line
  }, [unitIdValue]);

  return (
    <CleaningTypeIdFF
      disabled={!editable}
      required={unitIdValue !== 5}
      filter={filter}
      withAll={false}
    />
  );
};
