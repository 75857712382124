import { printReport } from 'app/pages/registry/ogh/api';
/* eslint-disable  */
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

/**
 * Print report.
 *
 * @returns {object}
 */
export const usePrintReport = () => {
  const mutationOptions = {
    /**
     *
     */
    onSuccess: () => {},
  };

  // tl/Ozn
  const [sendOzn, { isLoading: loadingOzn }] = useMutationAdaptor(
    printReport('ozn_tl_full'),
    mutationOptions,
  );

  const [sendOznSummery, { isLoading: loadingSendOznSummery }] =
    useMutationAdaptor(printReport('ozn_tl_full_summary'), mutationOptions);

  const [sendOznShort, { isLoading: loadingSendOznShort }] = useMutationAdaptor(
    printReport('ozn_tl_short'),
    mutationOptions,
  );

  // fullexport
  const [sendFullExport, { isLoading: loadingFullExport }] = useMutationAdaptor(
    printReport('dt_full_registry'),
    mutationOptions,
  );

  return {
    loadingFullExport,
    loadingOzn,
    loadingSendOznShort,
    loadingSendOznSummery,
    sendFullExport,
    sendOzn,
    sendOznShort,
    sendOznSummery,
  };
};
