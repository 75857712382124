import { ODS_API_URL } from 'config/env';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';
import { useParams } from 'react-router-dom';

const apiPath = ODS_API_URL;

/**
 * Запрос на проверку токена.
 *
 * @param {string} tokenNumber - Номер токена.
 * @returns {Promise<Response>}
 */
const fetchCheckToken = async (tokenNumber) => {
  const res = await fetch(apiPath + '/auth/tokens/' + tokenNumber);
  return res.ok;
};

/**
 * Хук проверки токена.
 *
 * @returns {{successToken: boolean, message: string}}
 */
export const useFetchResetPassword = () => {
  const params = useParams();
  const { data: successToken, isLoading } = useQueryAdaptor(
    ['checkToken', params.token],
    fetchCheckToken,
  );

  const message = isLoading
    ? 'Проверка токена...'
    : successToken
    ? ''
    : 'Токен не действителен';

  return { isLoading, message, successToken };
};
