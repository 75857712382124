import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { joinIds } from 'app/components/card/ogh/index/card/components/SendMatchingOgh.Button/joinIds';
import { PROJECT } from 'app/constants/oghStatus';
import { toast } from 'core/uiKit/components/Toast';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import { log } from 'core/utils/log';

import { fetchCheckObjectParent } from '../api/fetchCheckObjectParent';

/**
 * Хук useCheckObjectParent.
 *
 * @param params - Параметры хука.
 * @param params.setDialog - Функция инициализации диалогового окна.
 * @returns
 */
export const useCheckObjectParent = ({
  setDialog,
}: {
  setDialog: (obj: object) => void;
}) => {
  const card = useGetCurrentCard();

  const [checkObjectParent] = useMutationAdaptor(fetchCheckObjectParent, {

    /**
     * Ошибочный кейс.
     */
    onError() {
      log.info('fetchCheckObjectParent не прошел');
    },

    /**
     * Успешный кейс.
     *
     * @param obj - Объект.
     */
    onSuccess(obj) {
      const rootIsProject = card.logicalStateId === PROJECT;
      if (Object.keys(card) && rootIsProject) {
        const ids = joinIds(obj);
        if (ids) {
          toast.errorDying(
            `Данная версия объекта является элементом объекта(ов) с id ${ids}. Согласование невозможно`,
          );
        } else {
          setDialog({ dataRequest: obj, open: true });
        }
      } else setDialog({ dataRequest: obj, open: true });
    },
  });

  return {
    checkObjectParent,
  };
};
