 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __По оси, м__.
 *
 * @type {{ accessor: 'endwise', Header: 'По оси, м' }}
 * @augments Cell
 */
export const endwise = {
  accessor: 'endwise',
  Header: 'По оси, м',
  sortable: true,
};
