/* eslint-disable */
import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import { CustomerOdh, CUSTOMER_ODH_NAME } from 'core/uiKit/preparedInputs';

 
export { CUSTOMER_ODH_NAME };

const CustomerOdhWithReduxForm = ({ input, ...props }) => {
  return <CustomerOdh {...input} {...props} />;
};

 
 
/**
 * ## Заказчик
 * ### Redux Form Field
 *
 * ###### type = `AutocompleteAsync`
 * ###### name = `customer`
 *
 * ```
 * Заказчик   odh   customer
 * place=odh
 * ```
 *
 * ```js
 * import {
  CustomerOdhRF,
  CUSTOMER_ODH_NAME,
} from 'core/form/finalForm/fields';
 * ```
 *
 *
 */
/* eslint-disable */
export const CustomerOdhRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={CUSTOMER_ODH_NAME}
      component={CustomerOdhWithReduxForm}
    />
  );
};

CustomerOdhRF.propTypes = {
   
   
/**
   * очищаем инпут с сохранением значения
   */
  clear: PropTypes.bool,
   
   
/**
   * быбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
/**
   * placeholder
   */
  placeholder: PropTypes.string,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
};
CustomerOdhRF.defaultProps = {
  disabled: false,
};
