import { useFetchOghTable } from 'app/pages/registry/ogh/api/useFetchOghTable';
import { useParamsTable } from 'core/uiKit/components/tables/Table';

import { TableOghChildrenProps } from '../../types';
import { useFilterQueryParamsLawn } from './Filter.Lawn';

/**
 * Setup Таблицы реестров __Газоны__.
 *
 * @param {TableOghChildrenProps} props - Properties.
 * @returns
 */
export const useSetupTableLawn = ({
  typeId,
  parentTypeId,
}: TableOghChildrenProps) => {
  const { prepareFilters } = useFilterQueryParamsLawn();
  const { queryTable } = useParamsTable();

  const queryKey = [
    'TableLawn',
    {
      ...queryTable,
      ...prepareFilters,
      parentTypeId,
      typeId,
    },
  ];

  const { table, isLoading, count } = useFetchOghTable(queryKey);

  return { count, isLoading, table };
};
