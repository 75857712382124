/**
 * Создает объект для карты когда по геометрии щелкают и появляется хинт.
 *
 * @param {object} item - Объект пересечения.
 * @returns {{oghTypeId: number, hint: {object_attribute_hint_short: Array<{value: string, key: string}>, object_attribute_hint: Array<{rawValue: number, value: string, key: string}>}, stateId: number, oghObjectId: number}} - Объект.
 */
export function createObjectForMap(item) {
  const hintFields = [
    {
      key: 'title',
      rawValue: item.object_type_name,
      value: `${item.object_type_name}`,
    },
    {
      key: 'object_link',
      rawValue: item.object_link,
      value: item.object_link,
    },
    {
      key: 'state_name',
      rawValue: item.state_name,
      value: `Статус: ${item.state_name}`,
    },
    {
      key: 'start_date',
      rawValue: item.start_date,
      value: `Дата c: ${item.start_date}`,
    },
    {
      key: 'end_date',
      rawValue: item.end_date,
      value: `Дата по: ${item.end_date}`,
    },
    {
      key: 'owner_short_name',
      rawValue: item.owner_short_name,
      value: `Балансодержатель: ${item.owner_short_name}`,
    },
    {
      key: 'customer_short_name',
      rawValue: item.customer_short_name,
      value: `Заказчик: ${item.customer_short_name}`,
    },
    {
      key: 'passport_date',
      rawValue: item.passport_date,
      value: `Дата составления/согласования паспорта: ${item.passport_date}`,
    },
    {
      key: 'create_type_name',
      rawValue: item.create_type_name,
      value: `Тип загрузки: ${item.create_type_name}`,
    },
  ];

  const hint = hintFields.filter((item) => item.rawValue);

  return {
    hint: {
      object_attribute_hint: hint,
      object_attribute_hint_short: [
        {
          key: 'name',
          value: item.object_name,
        },
      ],
    },
    ...item.geometry,
    oghObjectId: item.object_root_id,
    oghTypeId: item.object_type_id,
    stateId: item.state_id,
  };
}
