/* eslint-disable */
import { log } from 'core/utils/log';
import _ from 'lodash';
import moment from 'moment';
import { INSURANCE_FILE_NAME } from 'app/constants/messages';
import { transformValues } from 'app/utils/form/transformation';
import { parseDate } from 'app/components/card/common/parse';
import validateCarRequiredFieldsAsync from 'app/components/card/common/validation/validateCarRequiredFieldsAsync';
import validateGovNumber from 'app/components/card/common/validation/validateGovNumber';
import buildMandatoriesMessage from 'app/components/card/common/validation/buildMandatoriesMessage';
import { validateCarMandatoryProperties } from 'app/utils/validation';
import { CAR_OWNERSHIP_ATTRACTED } from 'app/constants/carOwnershipCodes';

import submitCardTech from './index';

const mandatoryFields = {
  gov_number: 'Регистрационный номер',
  // 'car_func_type_group_id',
  car_func_type_id: 'Тип техники',
  car_season_id: 'Сезонность',
  car_condition_id: 'Техническое состояние',
  car_owner_id: 'Владелец техники',
  car_ownership_id: 'Тип принадлежности',
  car_model_id: 'Марка базового шасси',
  car_base_id: 'Автобаза',
  pts: '№ ПТС/ПСМ',
  pts_date: 'Дата выдачи ПТС/ПСМ',
  car_create_date: 'Год выпуска',
  weight_not_load: 'Масса без нагрузки, кг',
  horse_power: 'Мощность двигателя, л.с.',
  // electro_power: 'Мощность двигателя, кВт/ч',
  legal_car_registration_id: 'Орган постановки на учет',
  car_motor_type_id: 'Тип двигателя',
  car_ecology_class_id: 'Экологический класс',
  car_special_model_id: 'Модель спец. техники',
};

const dictFields = [
  // 'car_func_type_group_id',
  'car_func_type_id',
  'car_season_id',
  'car_condition_id',
  'car_owner_id',
  'car_model_id',
  'car_base_id',
  'legal_car_registration_id',
  'car_motor_type_id',
  'car_ecology_class_id',
  'gps_state_id',
  'car_special_model_id',
  'car_ownership_id',
];

const dateFields = ['pts_date', 'leasing_end_date', 'insurance_end_date'];

const numericFields = ['car_create_date', 'horse_power', 'electro_power'];

const onSubmit =
  (props, techTreePaths, changeCarForm, has_connections) => (formValues) => {
    const technic_id = props.card.id;
    const technic_root_id = props.card.root_id;
    const technic_update = props.card.update_date;
    const formValuesCopy = { ...formValues };

    let nonEmptyCustomers = 0;

    if (Array.isArray(formValuesCopy.car_customer_list)) {
      nonEmptyCustomers = formValuesCopy.car_customer_list.filter(
        (e) => !!e.id,
      );
    } else if (typeof formValuesCopy.car_customer_list === 'object') {
      nonEmptyCustomers = formValuesCopy.car_customer_list;
    }
    const editMode = props.mode && props.mode.editMode;
    const editTree = props.mode && props.mode.requestType === 'edit_tree';
    const createMode = props.mode && props.mode.createMode;

    if ((editMode || createMode) && !editTree) {
      validateCarRequiredFieldsAsync(
        formValuesCopy,
        mandatoryFields,
        (formValuesCopy) => {
          const errors = {};
          // Проверка прикрепленных файлов
          const insurancePolicy = props.technicFileTypes.find(
            (i) => i.name.trim().toLowerCase() === INSURANCE_FILE_NAME,
          );
          const message =
            'Техника застрахована, но не прикреплен файл с типом «Полис КАСКО/ОСАГО»';
          const f = props.technicFileTypes.find(
            (f) => f.name.trim().toLowerCase() === 'птс/псм',
          );
          if (
            !(
              formValuesCopy.file_list &&
              formValuesCopy.file_list.table &&
              formValuesCopy.file_list.table.length > 0 &&
              formValuesCopy.file_list.table.some(
                (i) => i.type_id === _.get(f, 'id', 'default'),
              )
            )
          ) {
            errors.file_list =
              'Пользователь не прикрепил файл с типом «ПТС/ПСМ». ';
          }
          formValuesCopy.insured = Boolean(formValuesCopy.insured);
          if (formValuesCopy.insured) {
            if (!formValuesCopy.insurance_end_date) {
              errors.insurance_end_date =
                'Техника застрахована, но не проставлена дата';
            }

            if (
              !(
                formValuesCopy.file_list &&
                formValuesCopy.file_list.table &&
                formValuesCopy.file_list.table.length > 0 &&
                formValuesCopy.file_list.table.some(
                  (i) => i.type_id === insurancePolicy.id,
                )
              )
            ) {
              errors.file_list = errors.file_list
                ? errors.file_list + message
                : message;
            }
          }
          // Проверка поля "Заказчик"
          if (!nonEmptyCustomers || nonEmptyCustomers.length === 0) {
            errors.car_customer_list = mandatoryFieldNames.car_customer_list;
          } else {
            const ownershipError = getCarOwnershipError(
              props.carOwnershipCode,
              nonEmptyCustomers,
            );
            if (ownershipError) {
              errors.car_customer_list = ownershipError;
            }
          }
          // Проверка VIN и "Заводской номер"
          if (!formValuesCopy.vin && !formValuesCopy.factory_number) {
            errors.vin =
              'Необходимо заполнить или "VIN (ИД ТС)" или "Заводской номер"';
            errors.factory_number =
              'Необходимо заполнить или "VIN (ИД ТС)" или "Заводской номер"';
          }
          // проверка обязательных к заполнению полей
          const errorFields = validateCarMandatoryProperties(
            formValuesCopy,
            mandatoryFields,
          );

          if (
            Object.keys(errorFields).length > 0 ||
            Object.keys(errors).length > 0
          ) {
            if (props.alert) {
              log.info('errorFields', errorFields);
              log.info('tabToFields', tabToFields);
              log.info('errors', errors);
              props.alert(
                buildMandatoriesMessage(
                  Object.assign(errorFields, errors),
                  tabToFields,
                  getMandatoryFieldNames(errors),
                ),
              );
            }
          }
          const checkGovNumber = govNumberHasMask(
            props.carFuncTypes,
            formValuesCopy.car_func_type_id,
          );
          return {
            ...errors,
            ...(checkGovNumber
              ? validateGovNumber(formValuesCopy.gov_number)
              : ''),
          };
        },
      );
    }

    if (
      formValuesCopy['file_list'] &&
      formValuesCopy['file_list'].table &&
      formValuesCopy['file_list'].table.length > 0
    ) {
      const fileList = formValuesCopy['file_list'].table.map((item) => ({
        files: item.file_id,
      }));
      formValuesCopy['file_list'] = fileList;
    }

    if (
      formValuesCopy['car_model_id'] &&
      formValuesCopy['car_model_id'].id !== null
    ) {
      formValuesCopy['car_model_id'] = parseInt(
        formValuesCopy['car_model_id'].id,
        10,
      );
    }

    if (
      formValuesCopy['car_special_model_id'] &&
      formValuesCopy['car_special_model_id'].id !== null
    ) {
      formValuesCopy['car_special_model_id'] = parseInt(
        formValuesCopy['car_special_model_id'].id,
        10,
      );
    }

    const isDate = (key) => {
      return _.includes(key.toLowerCase(), 'date');
    };

    Object.keys(formValuesCopy).forEach((key) => {
      if (isDate(key) && formValuesCopy[key]) {
        formValuesCopy[key] = parseDate(formValuesCopy[key]);
      }
    });

    const technic_attribute = transformValues(formValuesCopy, {
      dictFields,
      numericFields,
    }).attribute;

    dateFields.forEach((k) => {
      if (formValuesCopy[k]) {
        technic_attribute[k] = moment(formValuesCopy[k]).format(
          'YYYY-MM-DD HH:mm',
        );
      }
    });

    if (
      formValuesCopy['car_owner_id'] &&
      formValuesCopy['car_owner_id'].id !== null
    ) {
      technic_attribute.car_owner_id = {
        legal_person: parseInt(formValuesCopy['car_owner_id'].id, 10),
      };
    }

    // if (formValuesCopy.car_customer_list) {
    //   technic_attribute.car_customer_list =
    //     nonEmptyCustomers &&
    //     nonEmptyCustomers.map((e) => ({ legal_person: e.id }));
    // }

    const {
      fileIds = [],
      startDate,
      dateTime,
      comment,
      reason,
    } = changeCarForm;
    const date = startDate || dateTime;
    const planDate = moment(date).format('YYYY-MM-DD HH:mm:ss');

    const template = getTemplate();

    if (technic_attribute.not_need_glonass === 1) {
      technic_attribute.not_need_glonass = true;
    } else if (technic_attribute.not_need_glonass === 0) {
      technic_attribute.not_need_glonass = false;
    }

    technic_attribute.car_create_date = parseCarCreateDate(
      formValuesCopy.car_create_date,
    );
    technic_attribute.car_base_id = packCarBaseId(
      technic_attribute.car_base_id,
    );

    let tech = {
      technic_type_code: props.card.code,
      uuid: formValuesCopy.uuid,
      technic_attribute: _.pick(
        _.merge({ ...template }, technic_attribute),
        Object.keys(template),
      ),
      technic_tree: techTreePaths,
      technic_id: technic_id || null,
      technic_root_id: technic_root_id || null,
      technic_update: technic_update || null,
    };
    let requestTypeCode = 'del_car';
    if (props.mode && props.mode.createMode) {
      requestTypeCode = 'add_car';
    } else if (props.mode && props.mode.editMode) {
      requestTypeCode = props.mode.requestType
        ? props.mode.requestType
        : 'edit_car';
      if (requestTypeCode === 'edit_tree') {
        tech = calculateEquipmentListChange(
          props.card.uuid,
          formValuesCopy.equipmentList,
          props.card.equipment_list,
        );
      }
    }
    const car = {
      requestTypeCode,
      code: props.card.code,
      planDate,
      has_connections,
      attribute: {
        technic_request_reason_id: {
          technic_request_reason: reason || null,
        },
        description: comment || '',
        file_list_id: fileIds.map((fileId) => ({
          files: fileId,
        })),
      },
      tech,
    };
    submitCardTech(props, car);
  };

function parseCarCreateDate(date) {
  let result;
  if (date) {
    result = date.getFullYear();
  } else {
    result = null;
  }
  return result;
}

function packCarBaseId({ car_base }) {
  return {
    bti_object: car_base?.id || car_base?.value,
  };
}

function calculateEquipmentListChange(uuid_car, newList, oldList) {
  const newSet = (newList || []).map((e) => e.uuid);
  const oldSet = (oldList || []).map((e) => e.uuid);
  const addedEquipment = newSet.filter((x) => !oldSet.includes(x));
  const deletedEquipment = oldSet.filter((x) => !newSet.includes(x));

  return {
    uuid_car,
    add: {
      equipment_list: addedEquipment.map((uuid) => ({ uuid_equipment: uuid })),
    },
    del: {
      equipment_list: deletedEquipment.map((uuid) => ({
        uuid_equipment: uuid,
      })),
    },
  };
}

const getCarOwnershipError = (ownershipType, customers = []) => {
  // Если у техники "Тип принадлежности" !== "Привлеченная", то техника может иметь не более 1-ого заказчика
  if (
    CAR_OWNERSHIP_ATTRACTED !== parseInt(ownershipType, 10) &&
    customers.length > 1
  ) {
    return 'У не привлеченной техники может быть только один Заказчик';
  }

  return null;
};

const getTemplate = () => ({
  gov_number: null,
  not_need_glonass: false,
  factory_number: null,
  car_func_type_group_id: {
    car_func_type_group: null,
  },
  file_list: [
    {
      files: null,
    },
  ],
  insured: false,
  weight_not_load: null,
  insurance_end_date: null,
  pts: null,
  sts: null,
  car_season_id: {
    car_season: null,
  },
  car_base_id: {
    bti_object: null,
  },
  car_model_id: {
    car_model: null,
  },
  car_ownership_id: {
    car_ownership: null,
  },
  car_owner_id: {
    legal_person: null,
  },
  car_ecology_class_id: {
    car_ecology_class: null,
  },
  car_special_model_id: {
    car_special_model: null,
  },
  gps_state_id: {
    gps_state: null,
  },
  legal_car_registration_id: {
    legal_car_registration: null,
  },
  vin: null,
  leasing_end_date: null,
  pts_date: null,
  car_motor_type_id: {
    car_motor_type: null,
  },
  horse_power: null,
  car_create_date: null,
  car_customer_list: [
    {
      legal_person: null,
    },
  ],
  electro_power: null,
  car_condition_id: {
    car_condition: null,
  },
  car_func_type_id: {
    car_func_type: null,
  },
});

const mandatoryFieldNames = {
  insurance_end_date: 'Дата окончания договора страхования',
  car_customer_list: 'Заказчик',
  gov_number: 'Регистрационный номер',
  car_func_type_id: 'Тип техники',
  car_ownership_id: 'Тип принадлежности',
  // car_func_type_group_id: 'Группа техники',
  car_season_id: 'Сезонность',
  vin: 'Идентификационный номер (VIN)',
  factory_number: 'Заводской номер',
  car_owner_id: 'Владелец техники',
  car_condition_id: 'Техническое состояние',
  car_special_model_id: 'Модель спец. техники',
  car_base_id: 'Автобаза',
  car_motor_type_id: 'Тип двигателя',
  legal_car_registration_id: 'Орган постановки на учет',
  car_ecology_class_id: 'Экологический класс',
  pts: '№ ПТС/ПСМ',
  pts_date: 'Дата выдачи ПТС/ПСМ',
  car_create_date: 'Год выпуска',
  weight_not_load: 'Масса без нагрузки, кг',
  horse_power: 'Мощность двигателя, л.с.',
  electro_power: 'Мощность двигателя, кВт/ч',
  car_model_id: 'Марка базового шасси',
};

const getMandatoryFieldNames = (customErrors = {}) =>
  Object.keys(mandatoryFieldNames).reduce((fieldNames, field) => {
    const messagePrefix =
      field === 'vin' || field === 'factory_number'
        ? `${mandatoryFieldNames[field]}: `
        : '';

    fieldNames[field] = `${messagePrefix}${
      customErrors[field] || mandatoryFieldNames[field]
    }`;

    return fieldNames;
  }, {});

const tabToFields = {
  Свойства: [
    'vin',
    'factory_number',
    'car_model_id',
    'car_special_model_id',
    'car_create_date',
    'horse_power',
    'car_motor_type_id',
    'car_ecology_class_id',
    'weight_not_load',
    'car_owner_id',
    'car_ownership_id',
    // 'car_func_type_group_id',
    'car_func_type_id',
    'legal_car_registration_id',
    'gov_number',
    'pts',
    'pts_date',
  ],
  'Общие сведения': [
    'car_base_id',
    'car_condition_id',
    'car_customer_list',
    'car_season_id',
  ],
  Документы: ['file_list'],
};
function govNumberHasMask(carFuncTypes, carFuncTypeId) {
  if (carFuncTypeId) {
    const item = carFuncTypes.find((item) => item.id === carFuncTypeId);
    return item && item.is_check_gov_number;
  }
}
 
export default onSubmit;
