import CardAbstract from 'app/components/card/common/CardAbstract';
import {
  elementFormGrid,
  renderCustomTextField,
} from 'app/components/card/common/grid/index';
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import { validateNumeric } from 'app/components/card/common/validation';
import { validateRequiredFieldsAsync } from 'app/components/card/common/validation';
import submitCard from 'app/components/card/ogh/submit';
import { WithReduxFormSelect } from 'app/components/common/SelectField';
import { WithMapContext } from 'app/components/map/withMap';
import { POLYGON } from 'app/constants/geometryTypes';
import { types } from 'app/constants/versionTypes';
import getOoptCategories from 'app/selectors/card/getOoptCategories';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import getDict from 'app/selectors/getDict';
import { transformValues } from 'app/utils/form/transformation';
import getHint from 'app/utils/getHint';
import { log } from 'core/utils/log';
import get from 'lodash/get';
import PropTypes from 'prop-types';
/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';

import { ol } from '../../../../utils/helpers/ol';
import printOghCardHeader from '../header/oghHeader';
import VersionsTemplate from '../versions/VersionsTemplate';
import TerritoryCharacteristicTab, {
  createAttribute as createExplicationAttribute,
  mapStateToPropsReducer as explicationReducer,
} from './components/TerritoryCharacteristicTab';

const tabs = [
  { id: 'territory_characteristic-tab', name: 'Характеристики территории' },
];

const Versions = VersionsTemplate(types);
class OoptCard extends React.Component {
  static propTypes = {
    card: PropTypes.object,
    mode: PropTypes.object,
    changeFieldValue: PropTypes.func,
  };
  static contextType = WithMapContext;
  state = {
    fullAreaTitle: getHint('oopt_full_area'),
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.editMode) {
      this.onChangeGeometry(nextProps);
    } else {
      this.setFullAreaTitleFromCard(nextProps);
    }
  }

  setFullAreaTitleFromCard(nextProps) {
    const fullAreaCardValue = get(nextProps, 'card.full_area');
    const areaSquareMeters = Math.floor(fullAreaCardValue * 10000 * 100) / 100;

    this.setState({
      fullAreaTitle: fullAreaCardValue
        ? `${areaSquareMeters} м² = ${fullAreaCardValue} Га (${getHint(
            'oopt_full_area',
          )})`
        : getHint('oopt_full_area'),
    });
  }

   
   
  /**
   * Обработка событий изменения геометрии на карте
   */
  onChangeGeometry = (nextProps) => {
    const { mapLastEvent: newMapLastEvent } = nextProps;
    const { mapLastEvent: oldMapLastEvent } = this.props;
    const eventType = get(newMapLastEvent, 'eventType', null);
    const eventGeometryType = get(newMapLastEvent, 'data.type', null);
    const oldCoordinates = get(oldMapLastEvent, 'data.coordinates', null);
    const newCoordinates = get(newMapLastEvent, 'data.coordinates', null);

    if (
      newMapLastEvent &&
      eventGeometryType === POLYGON &&
      newCoordinates &&
      newCoordinates !== oldCoordinates
    ) {
      switch (eventType) {
        case 'removefeature':
          this.resetFullArea();
          break;

        case 'addfeature':
        case 'changefeature':
          this.setFullAreaFromMap(newMapLastEvent);
          break;

        default:
          break;
      }
    }
  };

   
  /* eslint-disable */
  /**
   * Пересчет общей площади при изменении геометрии
   */
  setFullAreaFromMap(event) {
    const { changeFieldValue } = this.props;

    try {
      const feature = new ol.Feature({
        geometry: new ol.geom.Polygon(JSON.parse(event.data.coordinates)),
      });
      const areaSquareMeters =
        Math.floor(ol.sphere.getArea(feature.getGeometry()) * 100) / 100;
      const areaHectare = Number(areaSquareMeters / 10000);

      changeFieldValue('full_area', areaHectare);

      this.setState({
        fullAreaTitle: areaSquareMeters
          ? `${areaSquareMeters} м² = ${areaHectare} Га (${getHint(
              'oopt_full_area',
            )})`
          : getHint('oopt_full_area'),
      });
    } catch (err) {
      log.error('OOPT object: can not parse geometry');
    }
  }

  resetFullArea() {
    const { changeFieldValue } = this.props;

    changeFieldValue('full_area', 0);
    this.setState({
      fullAreaTitle: getHint('oopt_full_area'),
    });
  }

  render() {
    const { props } = this;
    const { card, mode, editMode, required } = props;
    const { type_id: typeId } = card;
    return (
      <CardAbstract>
        <div>
          <div id="editor-card">
            {printOghCardHeader(card, mode)}
            {card && objectTabs(typeId)}

            <div id="props">
              {elementFormGrid(
                [
                  {
                    id: 'cad_number',
                    name: 'cad_number',
                    label: 'Кадастровый номер ООПТ',
                    editable: editMode,
                    formValue: true,
                    component: renderCustomTextField,
                    addition: {
                      type: 'text',
                      required,
                      mask: 'ООПТ.9899',
                      formatChars: {
                        9: '[0-9]',
                        8: '[.]',
                      },
                    },
                  },
                  {
                    id: 'oopt_status_id',
                    name: 'oopt_status_id',
                    editable: editMode,
                    formValue: true,
                    component: WithReduxFormSelect,
                    addition: {
                      label: 'Статус ООПТ',
                      required,
                      options: props.ooptStatuses,
                    },
                  },
                ],
                2,
                '',
                typeId,
              )}
              {elementFormGrid(
                [
                  {
                    id: 'name',
                    name: 'name',
                    label: 'Наименование ООПТ',
                    editable: editMode,
                    formValue: true,
                    component: renderCustomTextField,
                    addition: {
                      type: 'text',
                      required,
                      maxLength: 200,
                    },
                  },
                ],
                1,
                '',
                typeId,
              )}

              {elementFormGrid(
                [
                  {
                    id: 'full_area',
                    name: 'full_area',
                    label: 'Общая площадь ООПТ, Га',
                    editable: false,
                    formValue: true,
                    component: renderCustomTextField,
                    addition: {
                      helpTitle: this.state.fullAreaTitle,
                      type: 'decimal',
                      positive: true,
                      zero: false,
                      digits: 2,
                    },
                  },
                ],
                2,
                '',
                typeId,
              )}

              <Tabs tabs={tabs} />
              <TerritoryCharacteristicTab
                categoryMsops={props.categoryMsops}
                editable={editMode}
                explicationTypes={props.explicationTypes}
                ooptCategories={props.ooptCategories}
                ooptInterstatuses={props.ooptInterstatuses}
                ooptMeanings={props.ooptMeanings}
                ooptProfiles={props.ooptProfiles}
                required={required}
                typeId={typeId}
              />
            </div>
          </div>
        </div>
        <div className="d-none" id="versions">
          <Versions card={card} disabled={(mode && mode.createMode) || false} />
        </div>
      </CardAbstract>
    );
  }
}

const getOoptStatuses = getDict('ooptStatuses');
const getOoptInterstatuses = getDict('ooptInterstatuses');
const getOoptProfiles = getDict('ooptProfiles');
const getExplicationTypes = getDict('explicationTypes');
const getCategoryMsops = getDict('categoryMsops');
const getOoptMeanings = getDict('ooptMeanings');

const mapStateToProps = (state, props) => {
  const { card } = props;
  const parse = getParseAttribute(props);
  const result = {
    enableReinitialize: true,
    initialValues: {
      oopt_category_id: parse('oopt_category_id.oopt_category'),
      clustering: parse('clustering'),
      category_msop_id: parse('category_msop_id.category_msop'),
      oopt_meaning_id: parse('oopt_meaning_id.oopt_meaning'),
      oopt_profile_id: parse('oopt_profile_id.oopt_profile'),
      startDate: parseDate(card.start_date),
      endDate: parseDate(card.end_date),
      cad_number: parse('cad_number'),
      interstatus_id: parse('interstatus_id.oopt_interstatus'),
      oopt_status_id: parse('oopt_status_id.oopt_status'),
      name: parse('name'),
      full_area: card.full_area,
    },
    required: isRequired(state, props),
    ooptInterstatuses: getOoptInterstatuses(state),
    editMode: isEditMode(state, props),
    ooptProfiles: getOoptProfiles(state),
    explicationTypes: getExplicationTypes(state),
    categoryMsops: getCategoryMsops(state),
    ooptMeanings: getOoptMeanings(state),
    ooptCategories: getOoptCategories(state),
    ooptStatuses: getOoptStatuses(state),
    onSubmit: onSubmit.bind({ props }),
    validate,
    mapLastEvent: state.mapReducer.lastEvent,
  };
  return explicationReducer(result, state, props);
};

function validate(values) {
  return validateNumeric(values, numericFields);
}

const numericFields = [
  { name: 'clustering', type: 'integer', positive: true, zero: false },
];

const dictFields = [
  'oopt_category_id',
  'category_msop_id',
  'oopt_meaning_id',
  'oopt_profile_id',
  'interstatus_id',
  'oopt_status_id',
];

const mandatoryFields = [
  'cad_number',
  'oopt_status_id',
  'name',
  'oopt_meaning_id',
  'oopt_category_id',
  'oopt_profile_id',
  'clustering',
  'explication_list',
];

function createAttribute(formValues) {
  return {
    ...createExplicationAttribute(formValues),
  };
}
function isExplicationListExists(formValues) {
  return (
    formValues.explication_list &&
    formValues.explication_list.table &&
    formValues.explication_list.table.length > 0
  );
}
function onSubmit(formValues) {
  validateRequiredFieldsAsync(formValues, mandatoryFields, (formValues) => {
    const errors = {};
    const message = 'Не заполнена информация об экспликации земель';
    const cad_number_message = 'Номер не должен оканчиваться точкой';
    if (formValues.cad_number.endsWith('.')) {
      errors.cad_number = cad_number_message;
    }
    if (!isExplicationListExists(formValues)) {
      errors.explication_list = message;
    }
    return {
      ...errors,
    };
  });

  const formValuesWithAttribute = {
    ...formValues,
    ...createAttribute(formValues),
  };

  let submitValues = transformValues(formValuesWithAttribute, {
    dictFields,
    numericFields,
  });

  submitCard(this.props, submitValues);
}

const mapDispatchToProps = (dispatch) => ({
  changeFieldValue: (field, value) => {
    dispatch(change('editorCard', field, value));
  },
});

 
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'editorCard' })(OoptCard));
