/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { actions as formActions, Control } from 'react-redux-form';
import { flow, has, get, isUndefined } from 'lodash';

import Select from 'core/newComponents/Select';

import { renderCustomTextField } from 'app/components/card/common/grid/index';

import KeyboardDateTimePicker from 'core/uiKit/inputs/datePickers/KeyboardDateTimePicker';

import { uploadFiles } from 'app/actions/uploadActions';
import Form from 'app/components/common/KeyForm';
import DndFileUpload from 'app/components/common/DndFileUpload';
import DialogAction from '../../common/DialogAction';

import withCheckBeforeRenderDialog from 'core/hocs/withCheckBeforeRenderDialog';

import { REQUIRED_FIELD_MESSAGE } from 'app/constants/messages';

import getDict from 'app/selectors/buildDictSelector';
import PropTypes from 'prop-types';
import { currentDateLoshak } from 'app/utils/dateUtils';

const propTypes = {
  edit: PropTypes.bool,
  form: PropTypes.object,
  formChange: PropTypes.func,
  formSubmit: PropTypes.func,
  formValidity: PropTypes.object,
  name: PropTypes.string,
  noFiles: PropTypes.bool,
  reasonDefault: PropTypes.string,
  showCondition: PropTypes.bool,
  technicRequestReason: PropTypes.arrayOf(PropTypes.object),
  technicRequestType: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

class DialogChangeEquipment extends React.Component {
  componentDidMount() {
    this.props.formChange({
      key: 'dateTime',
      value: currentDateLoshak(),
    });
    this.props.formChange({
      key: 'comment',
      value: '',
    });
    this.props.formChange({
      key: 'requestType',
      value: '',
    });
    this.props.formChange({
      key: 'reason',
      value: '',
    });
    if (has(this.props, 'reasonDefault')) {
      const reason = this.props.technicRequestReason.find(
        (r) => r.code === this.props.reasonDefault,
      ).id;
      this.props.formChange({
        key: 'reason',
        value: reason,
      });
    }
    if (has(this.props, 'requestTypeDefault')) {
      const type = this.props.technicRequestType.find(
        (r) => r.code === this.props.requestTypeDefault,
      ).id;
      this.props.formChange({
        key: 'requestType',
        value: type,
      });
    }
  }

  onCancel = () => {
    this.removeAllFiles();
    this.props.onCancel();
  };

  onSubmit = (values) => {
    const { formChange, onSubmit } = this.props;
    const files = values.files;
    const promises = uploadFiles(files);
    for (let i = 0; i < files.length; ++i) {
      promises[i].then(() => {
        const file = files[i];
        this.removeFile(file);
      });
    }
    const whenCompleted = Promise.all(promises);
    whenCompleted.then((responses) => {
      const fileIds = responses.map((i) => i.fileId);
      formChange({
        key: 'fileIds',
        value: fileIds,
      });
      onSubmit(values.dateTime, values.requestType);
    });
  };

  removeAllFiles = () => {
    const { formChange } = this.props;
    formChange({
      key: 'files',
      value: [],
    });
  };

  removeFile = (file) => {
    const { form, formChange } = this.props;
    const value = form.files.filter((f) => f !== file);
    formChange({
      key: 'files',
      value,
    });
  };

  render() {
    const {
      form,
      formChange,
      formSubmit,
      formValidity,
      name,
      noFiles,
      reasonDefault,
      requestTypeDefault,
      showCondition,
      technicRequestReason,
      title,
    } = this.props;

    return (
      <DialogAction
        className="Dialog-Change-Equipment"
        isDisabledOk={form.files && form.files.length === 0}
        showCondition={showCondition}
        title={title}
        onBeforeOk={formSubmit}
        onCancel={this.onCancel}
      >
        <Form
          model="rrf.changeEquipment"
          validators={{
            '': {
              files: function ({ files }) {
                return noFiles === true || (files && files.length > 0);
              },
              reason: function ({ reason }) {
                return !!reason;
              },
            },
          }}
          onSubmit={this.onSubmit}
        >
          {!this.props.edit ? (
            <div style={{ marginBottom: 16 }}>
              <Select
                disabled={!isUndefined(requestTypeDefault)}
                errorText={
                  (formValidity.requestType === false &&
                    REQUIRED_FIELD_MESSAGE) ||
                  ''
                }
                label="Тип запроса"
                options={this.props.technicRequestType}
                value={form.requestType}
                onChange={(value) => {
                  formChange({ key: 'requestType', value });
                  formChange({ key: 'reason', value: '' });
                }}
              />
            </div>
          ) : (
            <TextField
              disabled={true}
              fullWidth={true}
              label="Тип запроса"
              value={name}
            />
          )}
          <div style={{ marginTop: 16, marginBottom: 16 }}>
            <Control
              component={Select}
              controlProps={{
                label: 'Причина изменения',
                options: (technicRequestReason || []).filter((r) => {
                  if (!form.requestType) {
                    return true;
                  }
                  const attribute = r.attribute;
                  const types = (
                    get(attribute, 'technic_request_type_id') || []
                  ).map((e) => e.technic_request_type);
                  return types.includes(form.requestType);
                }),
                errorText:
                  (formValidity.reason === false && REQUIRED_FIELD_MESSAGE) ||
                  '',
                disabled: !isUndefined(reasonDefault),
              }}
              model=".reason"
            />
          </div>
          <Control
            component={DateTimePickerField}
            controlProps={{
              label: 'Дата применения изменений',
              minDate: getMinDate(),
              disabledMinutes: true,
            }}
            model=".dateTime"
          />
          <Control
            component={TextField}
            controlProps={{
              label: 'Комментарий',
              fullWidth: true,
            }}
            model=".comment"
          />
          {noFiles !== true && (
            <DndFileUpload
              add={(files) => {
                const value = [...form.files, ...files];
                formChange({ key: 'files', value });
              }}
              errorText={
                formValidity.files === false
                  ? 'Должен быть прикреплен хотя бы один файл'
                  : ''
              }
              files={form.files}
              remove={this.removeFile}
              text="Переместите файлы-основания или нажмите для выбора"
            />
          )}
        </Form>
      </DialogAction>
    );
  }
}

DialogChangeEquipment.propTypes = propTypes;

function DateTimePickerField({ value, name, error, onChange, ...props }) {
  return (
    <KeyboardDateTimePicker
      meta={{ error: error || null }}
      name={name}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
}

function TextField({ value, name, error, onChange, ...props }) {
  return renderCustomTextField({
    input: { value, name, onChange },
    meta: { error: error || null },
    ...props,
  });
}

const getMinDate = () => {
  const minDate = new Date();
  minDate.setHours(minDate.getHours() + 1);
  return minDate;
};

const getTechnicRequestType = getDict('technicRequestType');
const getTechnicRequestReason = getDict('technicRequestReason');

const mapStateToProps = (state) => ({
  form: state.rrf.changeEquipment,
  formValidity: state.rrf.forms.changeEquipment.$form.validity,
  technicRequestReason: getTechnicRequestReason(state),
  technicRequestType: getTechnicRequestType(state),
});

const mapDispatchToProps = (dispatch) => ({
  formChange: (values) =>
    dispatch(
      formActions.change(`rrf.changeEquipment.${values.key}`, values.value),
    ),
  formSubmit: () => dispatch(formActions.submit('rrf.changeEquipment')),
});

 
export default flow(
  withCheckBeforeRenderDialog,
  connect(mapStateToProps, mapDispatchToProps),
)(DialogChangeEquipment);
