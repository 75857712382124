/* eslint-disable */
import React from 'react';
import { NumberParam } from 'use-query-params';

import { ReagentFF, REAGENT_NAME } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
export { REAGENT_NAME };

 
 
/**
 * Наименование ПГР
 * reagent
 *
 * @returns {JSX.Element}
 * @constructor
 */
 
export const ReagentFilter = (props) => (
  <ReagentFF {...props} className={filterInput} />
);

 
export const reagentParams = {
  [REAGENT_NAME]: NumberParam,
};
