// 12 Светофорные объектыimport { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import CardAbstract from 'app/components/card/common/CardAbstract';
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import { EndwiseField } from 'app/components/card/ogh/components/fields/numberFields/Endwise.Field/Endwise.Field';
import { OdhSideIdField } from 'app/components/card/ogh/components/fields/selectFields/OdhSideId.Field';
import { DescriptionField } from 'app/components/card/ogh/components/fields/textFields/Description.Field';
import { OdhAxisField } from 'app/components/card/ogh/components/fields/textFields/OdhAxis.Field';
import { PlacementIdRFWrapper } from 'app/components/card/ogh/components/selects/PlacementIdRFWrapper';
import {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import { isThisViewing } from 'app/selectors/card/isThisViewing';
import filterDictBySprNumber from 'app/utils/card/filterDictBySprNumber';
import isMandatory, { mandatoryFields } from 'app/utils/card/isMandatory';
import { transformValues } from 'app/utils/form/transformation';
import { NumberFieldRF } from 'core/form/reduxForm/fields';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import { OtherDocumentsRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/OtherDocuments.RF';
import { CardContainer } from 'core/uiKit/components/card.components/CardContainer';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { GroupContainer } from 'core/uiKit/components/card.components/GroupContainer';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';

import printOghCardHeader from './header/oghHeader';
import submitCard from './submit';
import VersionsTemplate from './versions/VersionsTemplate';

const types = ['road_signs', 'fencing', 'traffic_light'];
const Versions = VersionsTemplate(types);

const tabs = [
  { id: 'characteristics-tab', name: 'Исходные данные' },
  { id: 'docs-tab', name: 'Документы' },
];

const propTypes = {
  card: PropTypes.object,
  createMode: PropTypes.bool,
  documents: PropTypes.array,
  editMode: PropTypes.bool,
  mode: PropTypes.object,
  required: PropTypes.bool,
};

/**
 * Компонент __Светофорные объекты__.
 *
 * @param {object} props - Пропсы.
 * @returns {React.ReactElement}
 */
const TrafficLightCard = (props) => {
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();

  /**
   * Функция getMountingModeOptions.
   *
   * @returns {*}
   */
  const getMountingModeOptions = () => {
    const { type_id, mounting_mode, viewing } = props.card;
    if (viewing) {
      return mounting_mode;
    }

    return filterDictBySprNumber(mounting_mode, type_id);
  };

  const { mode, card, createMode, editMode, required } = props;

  return (
    <CardAbstract>
      <CardContainer>
        {printOghCardHeader(card, mode)}
        {card && objectTabs(card.type_id)}

        <div id="props">
          <FieldsSplitColumns>
            <SelectRF
              name={'equipment_type_id'}
              options={card.equipment_type}
              label={'Тип'}
              required={isMandatory(
                card.type_id,
                'equipment_type_id',
                required,
              )}
              disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
            />
            <ParentInfo />
          </FieldsSplitColumns>

          <Tabs tabs={tabs} />
          <div id="characteristics">
            <div>
              <GroupContainer title={'Местоположение'}>
                <FieldsSplitColumns>
                  <PlacementIdRFWrapper
                    disabled={!editMode || isEditCurrentObjectIsExternalSystem}
                    required={true}
                    withAll={false}
                  />
                  <OdhAxisField />
                  <OdhSideIdField />
                  <EndwiseField />
                </FieldsSplitColumns>
              </GroupContainer>
            </div>
            <GroupContainer title={'Характеристики'}>
              <FieldsSplitColumns>
                <SelectRF
                  options={getMountingModeOptions()}
                  label={'Тип установки'}
                  name={'mounting_mode_id'}
                  disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
                />
                <NumberFieldRF
                  name={'traff_light_car'}
                  label={'Состав оборудования. Светофор транспортный, шт.'}
                  disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
                />
                <NumberFieldRF
                  name={'traff_light_men'}
                  label={'Состав оборудования. Светофор пешеходный, шт.'}
                  disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
                />
                <NumberFieldRF
                  name={'section'}
                  label={'Состав оборудования. Секция поворотная, шт.'}
                  disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
                />
                <DescriptionField />
              </FieldsSplitColumns>
            </GroupContainer>
          </div>
          <div hidden={true} id={'docs'}>
            <OtherDocumentsRF />
          </div>
        </div>
        <div hidden={true} id="versions">
          <Versions
            card={card}
            disabled={createMode}
            key={`versions-${card.root_id}`}
          />
        </div>
      </CardContainer>
    </CardAbstract>
  );
};

TrafficLightCard.propTypes = propTypes;

const dictFields = [
  'odh_side_id',
  'equipment_type_id',
  'mounting_mode_id',
  'placement_id',
];

const numericFields = [
  { name: 'endwise', type: 'decimal', zero: true },
  { name: 'traff_light_car', positive: true, type: 'integer' },
  { name: 'traff_light_men', positive: true, type: 'integer' },
  { name: 'section', positive: true, type: 'integer' },
];

/**
 * Валидация.
 *
 * @param {object} values - Значения.
 * @returns {*}
 */
const validate = (values) => validateNumeric(values, numericFields);

const trafficLightCardDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

/**
 * Create attribute from form.
 *
 * @param {*} formValues - Form values.
 * @returns {*}
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

/**
 * Submit handler.
 *
 * @param {*} formValues - Form values.
 * @returns {void}
 */
export function onSubmit(formValues) {
  const { card } = this.props;
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };
  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[card.type_id],
  );
  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, { dictFields, numericFields }),
  );
}

/**
 * Функция mapStateToProps.
 *
 * @param {object} state - Состояние.
 * @param {object} props - Пропсы.
 * @returns {*}
 */
const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);

  const result = {
    createMode: isCreateMode(state, props),

    editMode: isEditMode(state, props),

    enableReinitialize: true,
    initialValues: {
      customer_id: parse('customer_id'),
      description: parse('description'),
      endDate: parseDate(props.card.end_date),
      endwise: parse('endwise'),
      equipment_type_id: parse('equipment_type_id.equipment_type'),
      mounting_mode_id: parse('mounting_mode_id.mounting_mode'),
      odh_axis: parse('odh_axis'),
      odh_side_id: parse('odh_side_id.odh_side'),
      owner_id: parse('owner_id'),
      parent_name: props.card.parent_info,
      placement_id: parse('placement_id.placement'),
      section: parse('section'),
      startDate: parseDate(props.card.start_date),
      traff_light_car: parse('traff_light_car'),
      traff_light_men: parse('traff_light_men'),
    },
    onSubmit: onSubmit.bind({ props }),
    required: isRequired(state, props),

    validate,
    viewing: isThisViewing(state),
  };

  return trafficLightCardDocumentsReducer(result, state, props);
};

/**
 * Функция mapDispatchToProps.
 *
 * @param {Function} dispatch - Функция диспатч.
 * @returns {{changeFieldValue: Function}}
 */
const mapDispatchToProps = (dispatch) => ({

  /**
   *Функция изменения значения.
   *
   * @param {string} field - Имя поля.
   * @param {*} value - Значение на которое меняем.
   * @returns {void}
   */
  changeFieldValue: (field, value) => {
    dispatch(change('editorCard', field, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'editorCard' })(TrafficLightCard));
