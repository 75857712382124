/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { INSURANCE_FILE_NAME } from 'app/constants/messages';

import Select from 'core/newComponents/Select';
import TextField from 'core/newComponents/TextField';

import { uploadFile } from 'app/actions/uploadActions';
import DialogAction from '../../common/DialogAction';
import DndFileUpload from 'app/components/common/DndFileUpload';
import { Comment } from 'core/uiKit/preparedInputs/textField/Comment';

import getDict from 'app/selectors/buildDictSelector';

const propTypes = {
  accept: PropTypes.string,
  allowedFormatsMessage: PropTypes.string,
  changeFieldValue: PropTypes.func.isRequired,
  showCondition: PropTypes.bool,
  technicFileTypes: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

const defaultProps = {
  title: 'Добавление файла',
};

class DialogAddCarFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      file: null,
      fileTypeId: null,
      comment: '',
    };
  }

  handleCancel = () => {
    const { onCancel } = this.props;
    this.initState();
    onCancel();
  };

  handleOk = () => {
    const { onSubmit, changeFieldValue } = this.props;
    const { state } = this;

    const additional = {
      create_date: new Intl.DateTimeFormat('ru').format(new Date()),
      comment: state.comment,
      type_id: state.fileTypeId,
    };
    const insurancePolicy = this.props.technicFileTypes.find(
      (i) => i.name.trim().toLowerCase() === INSURANCE_FILE_NAME,
    );
    if (state.fileTypeId === insurancePolicy.id) {
      changeFieldValue('insured', true);
    }
    uploadFile(state.file, additional).then((res) => {
      const fileId = res.fileId;
      onSubmit({
        file_id: fileId,
        file_name: state.file.name,
        ...additional,
      });
      this.initState();
    });
  };

  initState() {
    const state = this.getInitialState();
    this.setState(state);
  }

  render() {
    const { showCondition, title, technicFileTypes } = this.props;
    const { file, fileTypeId } = this.state;

    return (
      <DialogAction
        className="Dialog-Add-Car-File"
        isDisabledOk={!file || !fileTypeId}
        showCondition={showCondition}
        title={title}
        onCancel={this.handleCancel}
        onOk={this.handleOk}
      >
        <div style={{ marginBottom: 16 }}>
          <Select
            label="Тип файла"
            options={technicFileTypes}
            value={fileTypeId || ''}
            onChange={(value) => {
              this.setState({
                fileTypeId: value,
              });
            }}
          />
        </div>
        <Comment
          value={this.state.comment}
          onChange={(value) => {
            this.setState({
              comment: value,
            });
          }}
        />
        <TextField fullWidth={true} label="Комментарий" />
        <DndFileUpload
          accept={this.props.accept}
          add={(file) => {
            this.setState({
              file: file[0],
            });
          }}
          allowedFormatsMessage={this.props.allowedFormatsMessage}
          errorText={
            this.state.file ? '' : 'Необходимо выбрать хотя бы один файл'
          }
          files={this.state.file}
          multiple={false}
          remove={() => {
            this.setState({
              file: null,
            });
          }}
          text="Переместите файл или нажмите для выбора"
        />
      </DialogAction>
    );
  }
}

DialogAddCarFile.propTypes = propTypes;
DialogAddCarFile.defaultProps = defaultProps;

const getTechnicFileTypes = getDict('technicFileTypes');

const mapStateToProps = (state) => ({
  technicFileTypes: getTechnicFileTypes(state),
});

 
export default connect(mapStateToProps)(DialogAddCarFile);
