import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';

/**
 * Флаг определения какую таблицу выводить.
 *
 * @returns {boolean}
 */
export const useActOfCompletedElectronicSignatureFlag = () => {
  const card = useGetCurrentCard();

  return ['external_system'].includes(card?.attribute?.create_type);
};
