/* eslint-disable  */
import { getReport } from 'app/utils/api/getReport';

/**
 *
 * @param templateName
 * @param queryParams
 */
export const getReportCount = (templateName, queryParams) =>
  getReport(`${templateName}/count`, queryParams);
