import { CAR_OWNERS_NAME, CarOwnersFF } from 'core/form/finalForm/fields';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';
import { StringParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

export { CAR_OWNERS_NAME };

/**
 * Список владельцев техники
 * owner.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const CarOwnersFilter = (props) => (
  <CarOwnersFF {...props} className={filterInput} />
);

/**
 * AddCarOwnersParams..
 *
 * @returns {object}
 */
export const addCarOwnersParams = () => {
  addPreparation(CAR_OWNERS_NAME, (value) => value, 'owner');
  return {
    [CAR_OWNERS_NAME]: StringParam,
  };
};
