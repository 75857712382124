import {
  CHARACTERISTICS_STATE_GARDENING_ID_NAME,
  CharacteristicStateGardeningId,
} from 'core/uiKit/preparedInputs/selects/multiSelect/CharacteristicStateGardeningId';
import { Field } from 'redux-form';

/* eslint-disable */
const CharacteristicStateGardeningIdRFIdWithReduxForm = ({
  input,
  ...props
}) => {
  return <CharacteristicStateGardeningId {...input} {...props} />;
};

export const CharacteristicStateGardeningIdRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={CHARACTERISTICS_STATE_GARDENING_ID_NAME}
      component={CharacteristicStateGardeningIdRFIdWithReduxForm}
    />
  );
};
