/**
 * Функция получения атрибутов из form values по мапе атрибутов.
 *
 * @param {object} values - Заполненные поля формы.
 * @param {Array} mapAttribute - Массив атрибутов.
 * @returns {{}} Получаем на выходе отсортированные по переданной мапе атрибуты.
 */
export const getAttributeSubmit = (values = {}, mapAttribute = []) => {
  // TODO: переделать на мапу
  let result = {};
  for (const key in values) {
    if (mapAttribute.includes(key)) {
      result[key] = values[key];
    }
  }
  return result;
};
