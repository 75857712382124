import CardHeader from 'app/components/card/common/CardHeader';
import { CheckboxLandscapingMark } from 'app/components/card/ogh/header/inputs/CheckboxLandscapingMark';
import formatRootId from 'app/utils/formatRootId';
import React from 'react';

import {
  CheckboxGreeningComponent,
  CheckboxReapprovalComponent,
  CheckboxUpdatingComponent,
  KeyboardDateEndComponent,
  KeyboardDateStartComponent,
  OghStatusComponent,
  SignDataComponent,
  StageResolutionComponent,
} from './inputs';

/**
 * Хедр карточек ODH.
 *
 * @param {object} card - Данные карты.
 * @returns {JSX.Element}
 */
const printOghCardHeader = (card = {}) => {
  const { name, attribute, root_id, type_name_rus } = card;
  const cardName = name || attribute?.name || 'не определено';
  const rootId = formatRootId(root_id);
  return (
    <CardHeader subtitle={type_name_rus} title={`${rootId} ${cardName}`}>
      <KeyboardDateStartComponent />
      <KeyboardDateEndComponent />
      <OghStatusComponent />
      <StageResolutionComponent />
      <SignDataComponent />
      <CheckboxGreeningComponent />
      <CheckboxUpdatingComponent />
      <CheckboxReapprovalComponent />
      <CheckboxLandscapingMark />
    </CardHeader>
  );
};

export default printOghCardHeader;
