import { NumberField } from 'core/uiKit/inputs/NumberField';
import React from 'react';

export const RELATED_ELEMENTS_TREES_SHRUBS_ID_NAME =
  'related_elements_trees_shrubs_id';

/**
 * NumberField __Связанные элементы ЗН (ID) - Деревья/Кустарники__.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const RelatedElementsTreesShrubsId = (props) => {
  return (
    <NumberField
      {...props}
      label={'Связанные элементы ЗН (ID) - Деревья/Кустарники'}
      name={RELATED_ELEMENTS_TREES_SHRUBS_ID_NAME}
    />
  );
};
