import { REQUIRED_FIELD_MESSAGE } from 'app/constants/messages';


/* eslint-disable */
export default function (formValues) {
  const { categorySpaId } = formValues;
  let result = {};
  if (!categorySpaId) {
    result.categorySpaId = REQUIRED_FIELD_MESSAGE;
  }
  return Object.keys(result).length > 0 ? result : null;
}
