import {
  AddressFilter,
  addressParams,
} from 'core/form/filterForm/components/autocompleteAsync/AddressFilter';
import { BuildingTypeFilter } from 'core/form/filterForm/components/selects/BuildingTypeFilter';
import { IdFilter } from 'core/form/filterForm/components/text/ID.Filter';
import React, { useEffect } from 'react';

/**
 * Фильтр Объекты капитального строительства.
 *
 * @returns {JSX.Element}
 */
export const BuildingFilter = () => {
  useEffect(() => {
    addressParams();
  }, []);
  return (
    <>
      <IdFilter />
      <BuildingTypeFilter />
      <AddressFilter />
    </>
  );
};
