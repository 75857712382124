/* eslint-disable */
import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import {
  BuildingsTypeSpec,
  BUILDINGS_TYPE_SPEC_NAME,
} from 'core/uiKit/preparedInputs';

 
 
/**
 * ## Уточнение типа
 * ### React Final Form Field
 *
 * ###### type = `Select`
 * ###### name = `BUILDINGS_TYPE_SPEC_NAME`
 *
 *
 * ```js
 * import {
  BuildingsTypeSpecFF,
  BUILDINGS_TYPE_SPEC_NAME,
} from 'core/form/finalForm/fields';
 * ```
 *
 *
 */
/* eslint-disable */
export const BuildingsTypeSpecFF = ({ name, ...otherProps }) => {
  return (
    <Field name={BUILDINGS_TYPE_SPEC_NAME}>
      {(props) => {
        return (
          <BuildingsTypeSpec {...props} {...props.input} {...otherProps} />
        );
      }}
    </Field>
  );
};

 
export { BUILDINGS_TYPE_SPEC_NAME };

BuildingsTypeSpecFF.propTypes = {
   
   
/**
   * очищаем инпут с сохранением значения
   */
  clear: PropTypes.bool,
   
   
/**
   * быбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * функция для фильтрации options
   */
  filter: PropTypes.func,
   
  /* eslint-disable */
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
/**
   * placeholder
   */
  placeholder: PropTypes.string,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
};
BuildingsTypeSpecFF.defaultProps = {
  disabled: false,
};
