/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */

/**
 * Колонка __Общая площадь, кв.м__.
 *
 * @type {{ accessor: 'total_area',  Header: 'Общая площадь, кв.м', sortable: true }}
 * @augments Cell
 */
export const total_area = {
  Header: 'Общая площадь, кв.м',
  accessor: 'total_area',
  sortable: true,
};
