import { isDate, isObject } from 'lodash';

/* eslint-disable */
export default function isEqualValue(value, oldValue) {
  let result;
  if (Array.isArray(value)) {
    result = isEqualArray(value, oldValue);
  } else if (isDate(value)) {
    result = isEqualDate(value, oldValue);
  } else if (isObject(value)) {
    result = isValidObject(oldValue) ? value.value === oldValue.value : false;
  } else if (value === '' && oldValue === null) {
    result = true;
  } else {
    result = value === oldValue;
  }
  return result;
}

function isEqualArray(value, oldValue) {
  let result;
  if (Array.isArray(oldValue)) {
    if (value.length === oldValue.length) {
      result = value.every(
        (item, index) => item.value === oldValue[index].value,
      );
    } else {
      result = false;
    }
  } else {
    result = false;
  }
  return result;
}

function isEqualDate(value, oldValue) {
  let result;
  if (isDate(oldValue)) {
    result = value.getTime() === oldValue.getTime();
  } else {
    result = false;
  }
  return result;
}

function isValidObject(value) {
  return isObject(value) && value.value;
}
