/* eslint-disable  */
import {
  elementFormGrid,
  renderCustomTextField,
} from 'app/components/card/common/grid';
import { WithOldValueSelect } from 'app/components/common/SelectField';
import { column } from 'app/constants/tables';
import LinkListField from 'core/components/LinkListField';
import PropTypes from 'prop-types';

const propTypes = {
  technicRequestReason: PropTypes.array,
  technicRequestType: PropTypes.array,
};

/**
 * Функция OperationInfo.
 *
 * @param {object} props - Пропсы.
 * @returns {*} - XZ.
 */
export default function OperationInfo(props) {
  /**
   * Функция getElement.
   *
   * @param {object} params - Параметры.
   * @param {number} params.id - Идентификатор.
   * @param {object} params.rest - XZ.
   * @returns {object} - XZ.
   */
  const getElement = ({ id, ...rest }) => {
    return {
      editable: false,
      formValue: true,
      ...rest,
      id,
      name: id,
    };
  };

  /**
   * Функция getElements.
   *
   * @returns {*} - XZ.
   */
  const getElements = () => {
    return [
      getElement({
        addition: {
          label: 'Тип запроса',
          options: props.technicRequestType,
        },
        component: WithOldValueSelect,
        id: 'technic_request_type_id',
      }),
      getElement({
        addition: {
          label: 'Причина изменения',
          options: props.technicRequestReason,
        },
        component: WithOldValueSelect,
        id: 'technic_request_reason_id',
      }),
      getElement({
        addition: {
          type: 'text',
        },
        component: renderCustomTextField,
        id: 'technic_request_create_date',
        label: 'Дата формирования запроса',
      }),
      getElement({
        addition: {
          type: 'text',
        },
        component: renderCustomTextField,
        id: 'technic_request_description',
        label: 'Комментарий',
      }),
      getElement({
        addition: {
          type: 'text',
        },
        component: LinkListField,
        id: 'technic_request_file_list',
        label: 'Файлы-основания:',
      }),
    ];
  };

  return elementFormGrid(getElements(), column.TWO);
}

OperationInfo.propTypes = propTypes;
