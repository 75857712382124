/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { WithReduxFormSelect } from 'app/components/common/SelectField';
import get from 'lodash/get';
import Table from './table.component';
import DialogWithTableControls from 'app/components/dialogs/common/DialogWithTableControls';
import { editCardClearDialog } from 'app/actions/odsObjectActions';
import {
  renderCustomTextField,
  elementFormGrid,
} from 'app/components/card/common/grid';

import getDict from 'app/selectors/getDict';

import getReagentKindsToAdd from '../../selectors/getReagentKindsToAdd';
import getReagentsToAdd from '../../selectors/getReagentsToAdd';

import {
  validateNumeric,
  validateRequiredFieldsAsync,
  validateQuantityStepForSolidReagent,
} from 'app/components/card/common/validation';

import getMaxStorageCapacityQuantity from '../../selectors/getMaxStorageCapacityQuantity';
import isMaxStorageCapacityQuantityWithStep from '../../selectors/isMaxStorageCapacityQuantityWithStep';

const mandatoryFields = ['reagent_kind_id', 'reagent_id', 'quantity'];

 
/* eslint-disable */
/**
 * Настройка полей формы диалога добавления в таблицу
 */
const dialogFields = (props) => [
  {
    id: 'reagent_kind_id',
    name: 'reagent_kind_id',
    editable: true,
    component: WithReduxFormSelect,
    addition: {
      label: 'Вид ПГР',
      options: props.selectableReagentKinds,
      required: true,
      autoSelectForOneOption: true,
      withChange: () => props.changeFieldValue('reagent_id', null),
    },
  },
  {
    id: 'reagent_id',
    name: 'reagent_id',
    editable: true,
    component: WithReduxFormSelect,
    addition: {
      label: 'Наименование ПГР',
      options: props.selectableReagents,
      required: true,
      autoSelectForOneOption: true,
    },
  },
  {
    id: 'quantity',
    name: 'quantity',
    label: 'Максимальный объем, т.',
    editable: !props.defaultQuantity,
    component: renderCustomTextField,
    addition: {
      type: 'integer',
      digits: 2,
      positive: true,
      zero: false,
      required: true,
    },
  },
];

const MaxStorageCapacityTable = (props) => {
  const {
    style,
    input,
    defaultQuantity,
    changeFieldValue,
    editCardClearDialog,
    disabled,
  } = props;
  const { onChange } = input;
  const showDialog = (input.value && input.value.showDialog) || false;
  const [oldDefaultQuantity, setOldDefaultQuantity] = useState(undefined);
  const [checkedRows, setCheckedRows] = useState([]);
  const getTable = () => [...(get(input, 'value.table', []) || [])];

  useEffect(() => {
    if (defaultQuantity !== oldDefaultQuantity) {
      changeFieldValue('quantity', defaultQuantity);
      setOldDefaultQuantity(defaultQuantity);
    }
  }, [changeFieldValue, defaultQuantity, oldDefaultQuantity]);

  return (
    <div style={{ marginBottom: '15px', ...style }}>
      <DialogWithTableControls
        arrayFields={dialogFields(props)}
        asyncCustomValidate={(formValues) => validate(formValues, props)}
        className="Dialog-Add-Reagent-Kind-Info"
        countColumnForm={1}
        enableRemove={checkedRows.length > 0}
        inEdit={!disabled}
        nameDialog="add_reagent_kind_info"
        renderFunction={elementFormGrid}
        showCondition={showDialog}
        title="Добавление нового вида ПГР"
        onAdd={() =>
          onChange({
            table: getTable(),
            showDialog: true,
          })
        }
        onCancel={() => {
          onChange({
            table: getTable(),
            showDialog: false,
          });
          editCardClearDialog();
        }}
        onRemove={() => {
          onChange({
            table: getTable().filter(
              (item) =>
                !checkedRows.includes(
                  `${item.reagent_id}-${item.reagent_kind_id}`,
                ),
            ),
            showDialog: false,
          });
          setCheckedRows([]);
        }}
        onSubmit={(formValues) => {
          const table = getTable();
          const value = table.slice();

          if (formValues.quantity) {
            formValues.quantity = Number(formValues.quantity);
          }
          validateRequiredFieldsAsync(formValues, mandatoryFields);

          if (Object.keys(formValues).length) {
            value.push(formValues);
          }
          onChange({
            table: value,
            showDialog: false,
          });

          editCardClearDialog();
        }}
      />

      <Table
        checkedRows={checkedRows}
        data={getTable()}
        disabled={props.disabled}
        isCheckedRow={(item) =>
          checkedRows.includes(`${item.reagent_id}-${item.reagent_kind_id}`)
        }
        reagentKinds={props.reagentKinds}
        reagents={props.reagents}
        onSelectRow={(item) => {
          // был ли выбран ранее
          const isChecked = checkedRows.includes(
            `${item.reagent_id}-${item.reagent_kind_id}`,
          );

          if (isChecked) {
            // если был выбран ранее, то это отмена выбора
            setCheckedRows(
              checkedRows.filter(
                (id) => id !== `${item.reagent_id}-${item.reagent_kind_id}`,
              ),
            );
          } else {
            // не был выбран ранее, значит это выбор
            setCheckedRows([
              ...checkedRows,
              `${item.reagent_id}-${item.reagent_kind_id}`,
            ]);
          }
        }}
      />
    </div>
  );
};

const validate = (formValues, props) =>
  validateNumeric(formValues, props.numericFields);

const mapStateToProps = (state, ownProps) => {
  const withStep = isMaxStorageCapacityQuantityWithStep(state);
  const numericFields = [
    {
      name: 'quantity',
      type: 'decimal',
      positive: true,
      zero: false,
      checkSteps: withStep ? validateQuantityStepForSolidReagent : null,
    },
  ];

  return {
    reagentKinds: getDict('reagentKinds')(state),
    reagents: getDict('reagents')(state),
    selectableReagentKinds: getReagentKindsToAdd(state, ownProps),
    selectableReagents: getReagentsToAdd(state, ownProps),
    defaultQuantity: getMaxStorageCapacityQuantity(state),
    numericFields,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeFieldValue: (field, value) => {
      dispatch(change('editCardDialogForm', field, value));
    },
    editCardClearDialog: () => {
      dispatch(editCardClearDialog());
    },
  };
};

 
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MaxStorageCapacityTable);
