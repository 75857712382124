import { usePreparationMaterialId } from 'core/form/finalForm/fields/prepared/selects/simple/MaterialId.FF/usePreparationMaterialId.FF';
import {
  MATERIAL_ID_NAME,
  MaterialId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/MaterialId';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * Select FF Материал.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const MaterialIdFF = (props) => {
  usePreparationMaterialId();

  return (
    <Field name={MATERIAL_ID_NAME}>
      {(otherProps) => {
        return <MaterialId {...otherProps} {...otherProps.input} {...props} />;
      }}
    </Field>
  );
};
