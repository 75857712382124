import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useFieldRF } from 'core/form/reduxForm/hooks/useField.RF';
import { shallow } from 'enzyme';
import React from 'react';
import { Mock, vi } from 'vitest';

vi.mock('react', async (importOriginal) => {
  const mod = (await importOriginal()) as Record<string, unknown>; // Явное приведение к объекту;
  return {
    ...mod,
    useState: vi.fn(() => [false, vi.fn()]),
  };
});

vi.mock('app/pages/cardsOgh/hooks/useMode');
vi.mock('core/form/reduxForm/hooks/useField.RF');

import { DeleteButtonCellRepairsTab } from './Delete.Button.Cell.Repairs.Tab';

/**
 * Mock Default.
 *
 * @returns {void}
 */
const mockDefault = () => {
  (useMode as Mock).mockReturnValue({});
  (useFieldRF as Mock).mockReturnValue({});
};

describe('🐛 spec Delete.Button.Repairs.Tab.spec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock

    //🔥 Act
    // @ts-ignore
    const wrapper = shallow(<DeleteButtonCellRepairsTab />);

    //❓ Assert
    expect(wrapper.text()).toBe('delete');
  });
});
