 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Принадлежность элемента к зоне территории__.
 *
 * @type {{ accessor: 'zone_name',  Header: 'Принадлежность элемента к зоне территории' }}
 * @augments Cell
 */
export const zone_name = {
  accessor: 'zone_name',
  Header: 'Принадлежность элемента к зоне территории',
  sortable: true,
};
