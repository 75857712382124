import { RegistrySkeleton } from 'core/uiKit/components';
import { Empty } from 'core/uiKit/components/Empty';
import lazyWithPreload from 'lazy-with-preload';
import React, { Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { ForgotPassword } from './ForgotPassword';
import { Login } from './Login';
import ResetPassword from './ResetPassword';
import SuccessfulSendingPage from './SuccessfulSendingPage';

const EditPasswordUser = lazyWithPreload(() => import('./EditPasswordUser'));

/**
 * AuthRoute.
 *
 * @returns {JSX.Element}
 */
export const AuthRoute = () => {
  useEffect(() => {
    EditPasswordUser.preload();
  });

  return (
    <Empty>
      <Suspense fallback={<RegistrySkeleton />}>
        <Routes>
          <Route path={'login'} element={<Login />} />
          <Route
            exact={true}
            path={'forgotPassword'}
            element={<ForgotPassword />}
          />
          <Route
            path={`forgotPassword/:email`}
            element={<SuccessfulSendingPage />}
          />
          <Route path={`resetPassword/:token`} element={<ResetPassword />} />
          <Route
            element={<EditPasswordUser />}
            path={`:userId/edit-password`}
          />
        </Routes>
      </Suspense>
    </Empty>
  );
};
