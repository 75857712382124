import { useQueryParams as useQueryParamsUQP } from 'use-query-params';

/**
 * Хук, useQueryParams .
 *
 * @param {Array | object} params - Параметры .
 * @returns {*}
 */
export const useQueryParams = (params) => {
  let paramConfigMap = params || {};

  if (Array.isArray(params)) {
    paramConfigMap = params.reduce((acc, current) => {
      if (typeof current === 'function') {
        return { ...acc, ...current() };
      }
      return { ...acc, ...current };
    }, {});
  }

  return useQueryParamsUQP(paramConfigMap);
};
