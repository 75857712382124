import './NavigationBar.scss';

import {
  ButtonMUI,
  ExpandLessIcon,
  ExpandMoreIcon,
} from 'core/uiKit/material-ui';
import PropTypes from 'prop-types';
import React from 'react';

/* eslint-disable */
/**
 * NavigationBar.
 *
 * ```js
 * import { NavigationBar } from 'core/uiKit/components';
 * ```в.
 */

export const NavigationBar = ({ isShowFilter, toggleShowFilter, children }) => {
  return (
    <div className="type-navigation-bar">
      <div className="type-navigation-bar-wrapper">{children}</div>
      {toggleShowFilter && (
        <div>
          <ButtonMUI
            endIcon={isShowFilter ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            onClick={toggleShowFilter}
          >
            {isShowFilter ? 'Скрыть' : 'Показать'} Фильтр
          </ButtonMUI>
        </div>
      )}
    </div>
  );
};

NavigationBar.propTypes = {
  isShowFilter: PropTypes.bool,
  toggleShowFilter: PropTypes.func,
  children: PropTypes.any,
};
