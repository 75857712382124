import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { Button } from 'core/uiKit/components/buttons/Button';
import { Icon } from 'core/uiKit/material-ui';
import React from 'react';

/**
 * Компонент EditButton.
 *
 * @param {object} props - Пропсы.
 * @param {boolean} [props.isLoading] - Флаг Загрузки.
 * @returns {JSX.Element}
 */
export const EditButton = (props) => {
  const { editMode, startEditMode } = useMode();
  return (
    <Button
      onClick={startEditMode}
      {...props}
      color="info"
      hidden={editMode}
      variant={'contained'}
      startIcon={<Icon>mode_edit</Icon>}
    >
      Редактировать
    </Button>
  );
};
