import './LeafTree.scss';

/* eslint-disable  */
import { TreeItemMUI, Typography } from 'core/uiKit/material-ui';
import React from 'react';

/**
 *
 * @param root0
 * @param root0.id
 * @param root0.title
 * @param root0.onClick
 */
const LeafTree = ({ id, title, onClick }) => {
  return (
    <TreeItemMUI
      nodeId={id + title}
      label={
        <Typography
          className={'leaf-tree__item'}
          variant="body2"
          onClick={onClick}
        >
          {title}
        </Typography>
      }
    />
  );
};

export default LeafTree;
