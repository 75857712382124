/* eslint-disable  */
import {
  CarEquipmentOwnerIdFilter,
  carEquipmentOwnerIdParams,
  CarEquipmentOwnershipIdFilter,
  carEquipmentOwnershipIdParams,
  CarEquipmentTypeIdFilter,
  carEquipmentTypeIdParams,
  DateWithHoursFilter,
  dateWithHoursParams,
  FilterFF,
  InstallDrnoReportFilter,
  installDrnoReportParams,
  InstallDutReportFilter,
  installDutReportParams,
  InventoryNumberFilter,
  inventoryNumberParams,
} from 'core/form/filterForm';
import {
  GovNumberNameFilter,
  govNumberNameParams,
} from 'core/form/filterForm/components/autocompleteAsync/GovNumberName.Filter';
import { preparationSearchFormValues } from 'core/utils/helpers';
import { useQueryParams } from 'core/utils/hooks';
import React from 'react';

/**
 *
 */
export const useFilterQueryParamsEquipmentReport = () => {
  const [filters, setFilters] = useQueryParams([
    govNumberNameParams(),
    carEquipmentOwnerIdParams,
    carEquipmentOwnershipIdParams,
    carEquipmentTypeIdParams,
    dateWithHoursParams,
    installDrnoReportParams,
    installDutReportParams,
    inventoryNumberParams,
  ]);

  return {
    filters,
    prepareFilters: preparationSearchFormValues(filters),
    setFilters,
  };
};

/* eslint-disable */
export const FilterEquipmentReport = () => {
  const { setFilters, filters } = useFilterQueryParamsEquipmentReport();

  const onSubmit = async (values) => {
    setFilters(values, 'push');
  };

  const reset = (form) => {
    form.reset();
    setFilters({}, 'replace');
  };
  return (
    <FilterFF {...{ onSubmit, reset, initialValues: filters }}>
      <DateWithHoursFilter />
      <InventoryNumberFilter />
      <CarEquipmentTypeIdFilter />
      <CarEquipmentOwnershipIdFilter />
      <CarEquipmentOwnerIdFilter />
      <GovNumberNameFilter label={'Рег. Номер техники'} />
      <InstallDrnoReportFilter />
      <InstallDutReportFilter />
    </FilterFF>
  );
};
