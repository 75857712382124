/* eslint-disable */
import React, { useMemo } from 'react';
import { QuantityPcs } from 'core/uiKit/preparedInputs';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';

 
export const QuantityPCSReduxForm = ({ input, meta, ...props }) => {
  const { editMode } = useEditMode();
  const { error, ...otherMeta } = meta;
  const editError = useMemo(() => {
    return editMode ? error : '';
  }, [editMode, error]);

  return (
    <QuantityPcs {...input} {...props} meta={{ error: editError, otherMeta }} />
  );
};
