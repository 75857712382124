/* eslint-disable */
import React from 'react';
import {
  DateToWithHoursFilter,
  dateToWithHoursParamsDefault,
  FilterFF,
  IdNumberFilter,
  idNumbersParamsPreparation,
  TechnicStatusFilter,
  technicStatusParams,
  TypeIdTechnicFilter,
  typeIdTechnicPreparation,
} from 'core/form/filterForm';

import {
  DateFromWithHoursFilter,
  dateFromWithHoursParamsDefault,
} from 'core/form/filterForm/components/dateFilters';

import { useQueryParams } from 'core/utils/hooks/useQueryParams';
import { preparationSearchFormValues } from 'core/utils/helpers/preparationSearchFormValues';
import {
  UserCreateFilter,
  userCreateParams,
} from 'core/form/filterForm/components/text/UserCreateFilter';

import {
  ReasoneFilter,
  reasonePreparation,
} from 'core/form/filterForm/components/selects/ReasoneFilter';

 
export const useFilterQueryParamsProcessApproving = () => {
  const [filters, setFilters] = useQueryParams([
    idNumbersParamsPreparation,
    dateFromWithHoursParamsDefault,
    dateToWithHoursParamsDefault,
    technicStatusParams,
    typeIdTechnicPreparation,
    reasonePreparation,
    userCreateParams,
  ]);

  return {
    filters,
    prepareFilters: preparationSearchFormValues(filters),
    setFilters,
  };
};

/* eslint-disable */
export const ProcessApprovingFilter = () => {
  const { setFilters, filters } = useFilterQueryParamsProcessApproving();

  const onSubmit = async (values) => {
    setFilters(values, 'push');
  };

  const reset = (form) => {
    form.reset();
    setFilters({}, 'replace');
  };

  const typeIdTechnicFilter = (params) => {
    return params.filter((item) =>
      ['add_car', 'del_car', 'edit_car'].includes(item.code),
    );
  };
  return (
    <FilterFF {...{ onSubmit, reset, initialValues: filters }}>
      <IdNumberFilter />
      <DateFromWithHoursFilter />
      <DateToWithHoursFilter />
      <TechnicStatusFilter />
      <TypeIdTechnicFilter label={'Тип заявки'} filter={typeIdTechnicFilter} />
      <ReasoneFilter />
      <UserCreateFilter />
    </FilterFF>
  );
};
