import IconButton from 'core/newComponents/IconButton';
import { BackspaceIcon } from 'core/uiKit/material-ui';
import { useNavigateRouter } from 'core/utils/router';
import React from 'react';

/**
 * Кнопка назад.
 *
 * @returns {JSX.Element}
 */
export const ReturnBack = () => {
  const navigate = useNavigateRouter();

  /**
   * Функция goBack.
   *
   * @returns {void}
   */
  const goBack = () => navigate(-1);

  return (
    <IconButton
      className="return-back"
      title="Вернуться на предыдущую страницу"
      onClick={goBack}
    >
      <BackspaceIcon />
    </IconButton>
  );
};
