/* eslint-disable */
import React, { useContext } from 'react';

import { TreePanel } from 'core/uiKit/components/TreePanel';
import { BodyContext } from 'app/pages/providers';
import { usePrepareTree } from 'app/pages/registry/ogh/yard/utils';

 
export const TreePanelOgh = ({ root, tree }) => {
  const { isSubobjectPanelShow = true } =
    useContext(BodyContext);
  const prepareTree = usePrepareTree(tree, root);
  return isSubobjectPanelShow ? (
    <TreePanel tree={prepareTree} />
  ) : null;
};
