/* eslint-disable */
import React from 'react';
import formatRootId from 'app/utils/formatRootId';

import CardHeader from 'app/components/card/common/CardHeader';
import {
  SignDataComponent,
  KeyboardDateStartComponent,
  KeyboardDateEndComponent,
  OghStatusComponent,
} from 'app/pages/cardsOgh/components/headerFF/inputs';
import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';

 
export const OghCardHeaderFF = () => {
  const card = useGetCurrentCard();
  const { name, attribute, root_id, type_name_rus, type_name } = card;
  const cardName = name || attribute?.name || 'не определено';
  const rootId = formatRootId(root_id);
  return (
    <CardHeader
      subtitle={type_name_rus || type_name}
      title={`${rootId} ${cardName}`}
    >
      <KeyboardDateStartComponent />
      <KeyboardDateEndComponent />
      <OghStatusComponent />
      <SignDataComponent />
    </CardHeader>
  );
};
