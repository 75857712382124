import TemporaryDrawer from 'app/components/common/NavBar/components/Drawer/Drawer';
import { List } from 'core/uiKit/material-ui';
import React from 'react';

import { ProjectsTree } from './ProjectsTree';

/**
 * Компонент DrawerContainer.
 *
 * @returns {JSX.Element}
 */
export const DrawerContainer = () => {
  return (
    <TemporaryDrawer>
      <List>
        <ProjectsTree />
      </List>
    </TemporaryDrawer>
  );
};
