/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DialogAction from '../../../common/DialogAction';

import * as adminRolesActions from 'app/actions/adminRoles';

const propTypes = {
  closeRemoveDialog: PropTypes.func,
  removeRoleFromDialog: PropTypes.func,
  roleId: PropTypes.string,
};

class DialogDeleteRole extends React.Component {
  render() {
    const { roleId, closeRemoveDialog, removeRoleFromDialog } = this.props;

    return (
      <DialogAction
        cancelText="Отмена"
        okText="Удалить"
        showCondition={Boolean(roleId)}
        title="Удалить роль?"
        onCancel={closeRemoveDialog}
        onOk={removeRoleFromDialog}
      >
        {null}
      </DialogAction>
    );
  }
}

DialogDeleteRole.propTypes = propTypes;

const mapStateToProps = (state) => ({
  roleId: state.rrf.admin.role.removeDialog,
});

const mapDispatchToProps = (dispatch) => ({
  removeRoleFromDialog: () =>
    dispatch(adminRolesActions.removeRoleFromDialog()),
  closeRemoveDialog: () => dispatch(adminRolesActions.closeRemoveDialog()),
});

 
export default connect(mapStateToProps, mapDispatchToProps)(DialogDeleteRole);
