import { OWNER_ID_NAME, OwnerId } from 'core/uiKit/preparedInputs';
import { Field } from 'redux-form';

/* eslint-disable  */
/**
 *
 * @param root0
 * @param root0.input
 */
const OwnerIdRFWithReduxForm = ({ input, ...props }) => {
  return <OwnerId {...input} {...props} />;
};

/* eslint-disable */
export const OwnerIdRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={OWNER_ID_NAME}
      component={OwnerIdRFWithReduxForm}
    />
  );
};
