import { TOTAL_AREA_GEO_NAME } from 'core/uiKit/preparedInputs/textField/TotalAreaGeo';
import React from 'react';
import { Field } from 'redux-form';

import { TotalAreaGeoReduxForm } from './TotalAreaGeoReduxForm';

/**
 * RF input - Площадь объекта по пространственным координатам, кв.м.
 *
 * @param {object} props - Properties.
 * @returns {JSX}
 */
export const TotalAreaGeoRF = (props) => {
  return (
    <Field
      {...props}
      component={TotalAreaGeoReduxForm}
      name={TOTAL_AREA_GEO_NAME}
    />
  );
};
