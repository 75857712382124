import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
 
/* eslint-disable */
/**
 * Selector получения текущей карточки object из state или дочерней карточки
 * Использовать useGetCurrentCard напрямую
 * @deprecated
 * @see app/api/hooks/useGetCurrentCard
 * @function
 * @category useSelector
 * @returns {{}}
 */
 
export const useGetCurrentCardSelector = () => {
  return useGetCurrentCard();
};
