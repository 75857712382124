import { PlanarStructureTypeRefIdWithFF } from 'core/form/finalForm/fields/prepared/selects/simple/PlanarStructureTypeRefId.FF/PlanarStructureTypeRefIdWithFF';
import { PLANAR_STRUCTURE_TYPE_REF_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/PlanarStructureTypeRefId';
import { Field } from 'react-final-form';

/**
 * @typedef {import('node_modules/react-final-form/typescript/index.d.ts').Field} Field
 * @typedef {JSX.Element} SelectFF - Select Final Form.
 */

/**
 * @typedef {object} Props
 * @property {boolean} clear - Очищаем инпут с сохранением значения.
 * @property {boolean} dark - Функция для фильтрации options.
 */

/**
 * ## Уточнение Select FF.
 *
 * @param {object} props - Пропсы.
 * @returns {React.ReactElement} - Компонент.
 * @example ---
 */
export const PlanarStructureTypeRefIdFF = (props) => {
  return (
    <Field
      {...props}
      name={PLANAR_STRUCTURE_TYPE_REF_ID_NAME}
      component={PlanarStructureTypeRefIdWithFF}
    />
  );
};
