/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';

import './SortingColumn.scss';

const sortingClass = {
  null: 'order-none',
  asc: 'order-down',
  desc: 'order-up',
};

/* eslint-disable */
export default function SortingColumn(props) {
  const {
    handleOnClick,
    screenName,
    databaseName,
    sortDirection,
    sortable,
    cells,
    ...cellProps
  } = props;
  return (
    <cell
      className={`${sortingClass[sortDirection]} sorting-column`}
      {...cellProps}
    >
      <Link
        to={'#'}
        onClick={() => {
          handleOnClick({
            orderBy: databaseName,
            sortDirection: sortDirection === 'asc' ? 'desc' : 'asc',
          });
        }}
      >
        <span>{screenName}</span>
      </Link>
    </cell>
  );
}
