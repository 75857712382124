import * as types from '../constants/actionTypes';

/* eslint-disable */
export default function serverInfo(state = {}, action) {
  switch (action.type) {
    case types.REQUEST_SERVER_INFO: {
      return action.object;
    }
    default:
      return state;
  }
}
