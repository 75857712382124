import { SelectFF } from 'core/form/finalForm/fields';
import { stringWithAllParam } from 'core/utils/hooks';
import {
  addPreparation,
  usePreparation,
} from 'core/utils/hooks/usePreparation/preparation';
import { withDefault } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

export const IS_ORPHAN_OBJECT_NAME = 'isOrphanObject';

const options = [
  { id: 1, name: 'Объекты, переданные в эксплуатацию' },
  { id: -1, name: 'Паспортизированные объекты' },
];

/**
 * Выпадающий список «Тип реестра ОДХ».
 *
 * @returns {JSX.Element} - JSX.
 */
export const IsOrphanObjectFilter = () => {
  usePreparation(IS_ORPHAN_OBJECT_NAME, preparationOrphanObject);
  return (
    <SelectFF
      className={filterInput}
      {...{ options }}
      label={'Тип реестра ОДХ'}
      name={IS_ORPHAN_OBJECT_NAME}
    />
  );
};

/**
 * @typedef {import('use-query-params').QueryParamConfig} QueryParamConfig
 */

/**
 * QueryParam Выпадающий список «Тип реестра ОДХ».
 *
 * @returns {{IS_ORPHAN_OBJECT_NAME: QueryParamConfig<unknown, Exclude<string|*, undefined> | number>}} - Параметр.
 */
export const isOrphanObjectParams = () => {
  // костыль потому что таблица запрашивает данные, а usePreparation еще не отработал.
  addPreparation(IS_ORPHAN_OBJECT_NAME, preparationOrphanObject);
  return {
    [IS_ORPHAN_OBJECT_NAME]: withDefault(stringWithAllParam, -1),
  };
};

/**
 * Функция форматирования данных для бэка.
 *
 * @param {1 | -1 | ''} value - Значение.
 * @returns {null|boolean}
 */
const preparationOrphanObject = (value) => {
  if (value) {
    return value > 0;
  }
  return null;
};
