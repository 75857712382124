import KeyboardDatePickerRF from 'core/form/reduxForm/fields/default/datePickers/KeyboardDatePickerRF';

/**
 * Поле __Действует с__.
 *
 * @returns {JSX.Element}
 */
export const KeyboardDateStartComponent = () => {
  return (
    <KeyboardDatePickerRF
      {...{
        disabled: true,
        label: 'Действует с',
        name: 'startDate',
      }}
    />
  );
};
