/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */

import { abutment_friends_ogh_object_name } from './accessors/abutment_friends_ogh_object_name';
import { abutment_friends_ogh_object_type } from './accessors/abutment_friends_ogh_object_type';
import { abutment_friends_short_root_id } from './accessors/abutment_friends_short_root_id ';
import { accessory_name } from './accessors/accessory_name';
import { address_name } from './accessors/address_name';
import { age } from './accessors/age';
import { annuals_area } from './accessors/annuals_area';
import { area } from './accessors/area';
import { biennials_area } from './accessors/biennials_area';
import { bike_ref_type_name } from './accessors/bike_ref_type_name';
import { bio_group_num } from './accessors/bio_group_num';
import { bord_begin } from './accessors/bord_begin';
import { bord_end } from './accessors/bord_end';
import { bound_stone_mark_name } from './accessors/bound_stone_mark_name';
import { build_area } from './accessors/build_area';
import { building_klas } from './accessors/building_klas';
import { buildings_type } from './accessors/buildings_type';
import { buildings_type_spec } from './accessors/buildings_type_spec';
import { buildings_type_spec_name } from './accessors/buildings_type_spec_name';
import { bulbous_area } from './accessors/bulbous_area';
import { category_spa_name } from './accessors/category_spa_name';
import { characteristic_state_gardening_name } from './accessors/characteristic_state_gardening_name';
import { clean_type } from './accessors/clean_type';
import { clean_type_name } from './accessors/clean_type_name';
import { coating_between_road_area } from './accessors/coating_between_road_area';
import { coating_face_type_name } from './accessors/coating_face_type_name';
import { coating_group_name } from './accessors/coating_group_name';
import { coating_group_road_name } from './accessors/coating_group_road_name';
import { coating_tracks_area } from './accessors/coating_tracks_area';
import { coating_type_name } from './accessors/coating_type_name';
import { coating_type_road_name } from './accessors/coating_type_road_name';
import { create_type } from './accessors/create_type';
import { department_name } from './accessors/department_name';
import { description } from './accessors/description';
import { detached } from './accessors/detached';
import { detailed_state_gardening_name } from './accessors/detailed_state_gardening_name';
import { diameter } from './accessors/diameter';
import { distance } from './accessors/distance';
import { district_name } from './accessors/district_name';
import { eais_id } from './accessors/eais_id';
import { end_date } from './accessors/end_date';
import { endwise } from './accessors/endwise';
import { erz_date } from './accessors/erz_date';
import { erz_state } from './accessors/erz_state';
import { erz_state_id } from './accessors/erz_state_id';
import { face_area } from './accessors/face_area';
import { flinders_area } from './accessors/flinders_area';
import { floor_qty } from './accessors/floor_qty';
import { floor_vertical_design_area } from './accessors/floor_vertical_design_area';
import { floor_vertical_design_qty } from './accessors/floor_vertical_design_qty';
import { flowers_vertical_design_area } from './accessors/flowers_vertical_design_area';
import { flowers_vertical_design_qty } from './accessors/flowers_vertical_design_qty';
import { grbs_short_name } from './accessors/grbs_short_name';
import { green_num } from './accessors/green_num';
import { greening_addition } from './accessors/greening_addition';
import { group_id } from './accessors/group_id';
import { guarantee_period } from './accessors/guarantee_period';
import { gutters_length } from './accessors/gutters_length';
import { height } from './accessors/height';
import { id_rfid } from './accessors/id_rfid';
import { improvement_category_name } from './accessors/improvement_category_name';
import { improvement_object_cat_name } from './accessors/improvement_object_cat_name';
import { in_yard } from './accessors/in_yard';
import { included_in_tl } from './accessors/included_in_tl';
import { inert_area } from './accessors/inert_area';
import { installation_date } from './accessors/installation_date';
import { is_actual } from './accessors/is_actual';
import { is_diff_height_mark } from './accessors/is_diff_height_mark';
import { is_filled } from './accessors/is_filled';
import { is_footway_area } from './accessors/is_footway_area';
import { is_gutter_zone } from './accessors/is_gutter_zone';
import { is_isolated } from './accessors/is_isolated';
import { is_matching } from './accessors/is_matching';
import { is_object_area } from './accessors/is_object_area';
import { is_roadway_area } from './accessors/is_roadway_area';
import { is_title } from './accessors/is_title';
import { jopa } from './accessors/jopa';
import { lawn_area } from './accessors/lawn_area';
import { lawn_area_without } from './accessors/lawn_area_without';
import { lawn_type_name } from './accessors/lawn_type_name';
import { life_form_type_name } from './accessors/life_form_type_name';
import { lifetime } from './accessors/lifetime';
import { logical_state_id } from './accessors/logical_state_id';
import { maf_type_level2_name } from './accessors/maf_type_level2_name';
import { maf_type_level3_name } from './accessors/maf_type_level3_name';
import { margin_streng_name } from './accessors/margin_streng_name';
import { material_name } from './accessors/material_name';
import { million_trees } from './accessors/million_trees';
import { mounting_mode_name } from './accessors/mounting_mode_name';
import { name } from './accessors/name';
import { near_roadway } from './accessors/near_roadway';
import { no_calc } from './accessors/no_calc/no_calc';
import { object_name } from './accessors/object_name';
import { object_status_name } from './accessors/object_status_name';
import { object_type_name } from './accessors/object_type_name';
import { odh_axis } from './accessors/odh_axis';
import { odh_id } from './accessors/odh_id';
import { odh_name } from './accessors/odh_name';
import { odh_side_name } from './accessors/odh_side_name';
import { okrug_name } from './accessors/okrug_name';
import { owner_name } from './accessors/owner_name';
import { owner_short_name } from './accessors/owner_short_name';
import { ozn_type_name } from './accessors/ozn_type_name';
import { parent_customer_name } from './accessors/parent_customer_name';
import { parent_department_name } from './accessors/parent_department_name';
import { parent_district_name } from './accessors/parent_district_name';
import { parent_grbs_name } from './accessors/parent_grbs_name';
import { parent_id } from './accessors/parent_id';
import { parent_name } from './accessors/parent_name';
import { parent_object_name } from './accessors/parent_object_name';
import { parent_okrug_name } from './accessors/parent_okrug_name';
import { parent_owner_name } from './accessors/parent_owner_name';
import { parent_short_root_id } from './accessors/parent_short_root_id';
import { parent_type_id } from './accessors/parent_type_id';
import { passport_draft_org } from './accessors/passport_draft_org';
import { pavilion_area } from './accessors/pavilion_area';
import { perennials_area } from './accessors/perennials_area';
import { placement_name } from './accessors/placement_name';
import { places_area } from './accessors/places_area';
import { places_quantity } from './accessors/places_quantity';
import { planar_structure_type_ref_name } from './accessors/planar_structure_type_ref_name';
import { plant_service_recomendations_name } from './accessors/plant_service_recomendations_name';
import { plant_type_name } from './accessors/plant_type_name';
import { plantation_type_name } from './accessors/plantation_type_name';
import { pocket_area } from './accessors/pocket_area';
import { property } from './accessors/property';
import { quantity } from './accessors/quantity';
import { quantity_pcs } from './accessors/quantity_pcs';
import { quantity_rm } from './accessors/quantity_rm';
import { quantity_sqr } from './accessors/quantity_sqr';
import { reapprove_required } from './accessors/reapprove_required';
import { related_elements_flowers_garden_id } from './accessors/related_elements_flowers_garden_id';
import { related_elements_trees_shrubs_id } from './accessors/related_elements_trees_shrubs_id';
import { rockery_area } from './accessors/rockery_area';
import { routes } from './accessors/routes';
import { season_shift_area } from './accessors/season_shift_area';
import { section } from './accessors/section';
import { section_num } from './accessors/section_num';
import { short_root_id } from './accessors/short_root_id';
import { shrubs_area } from './accessors/shrubs_area';
import { sign_date } from './accessors/sign_date';
import { sign_person } from './accessors/sign_person';
import { start_date } from './accessors/start_date';
import { state_gardening_name } from './accessors/state_gardening_name';
import { state_id } from './accessors/state_id';
import { station_clean_area } from './accessors/station_clean_area';
import { susp_height } from './accessors/susp_height';
import { suspend_vertical_design_area } from './accessors/suspend_vertical_design_area';
import { suspend_vertical_design_qty } from './accessors/suspend_vertical_design_qty';
import { total_area } from './accessors/total_area';
import { total_area_geo } from './accessors/total_area_geo';
import { total_clean_area } from './accessors/total_clean_area';
import { total_lawn_area } from './accessors/total_lawn_area';
import { traff_light_car } from './accessors/traff_light_car';
import { traff_light_men } from './accessors/traff_light_men';
import { traffic_signs_name } from './accessors/traffic_signs_name';
import { tulip_area } from './accessors/tulip_area';
import { unad } from './accessors/unad';
import { unom } from './accessors/unom';
import { update_required } from './accessors/update_required';
import { utn_area } from './accessors/utn_area';
import { valuable_plants_name } from './accessors/valuable_plants_name';
import { vase_qty } from './accessors/vase_qty';
import { vertical_landscaping_class_type_name } from './accessors/vertical_landscaping_class_type_name';
import { vertical_landscaping_ref_type_name } from './accessors/vertical_landscaping_ref_type_name';
import { width_begin } from './accessors/width_begin';
import { width_end } from './accessors/width_end';
import { zone_name } from './accessors/zone_name';

const map = {
  abutment_friends_ogh_object_name,
  abutment_friends_ogh_object_type,
  abutment_friends_short_root_id,
  accessory_name,
  address_name,
  age,
  annuals_area,
  area,
  biennials_area,
  bike_ref_type_name,
  bio_group_num,
  bord_begin,
  bord_end,
  bound_stone_mark_name,
  build_area,
  building_klas,
  buildings_type,
  buildings_type_spec,
  buildings_type_spec_name,
  bulbous_area,
  category_spa_name,
  characteristic_state_gardening_name,
  clean_type,
  clean_type_name,
  coating_between_road_area,
  coating_face_type_name,
  coating_group_name,
  coating_group_road_name,
  coating_tracks_area,
  coating_type_name,
  coating_type_road_name,
  create_type,
  department_name,
  description,
  detached,
  detailed_state_gardening_name,
  diameter,
  distance,
  district_name,
  eais_id,
  end_date,
  endwise,
  erz_date,
  erz_state,
  erz_state_id,
  face_area,
  flinders_area,
  floor_qty,
  floor_vertical_design_area,
  floor_vertical_design_qty,
  flowers_vertical_design_area,
  flowers_vertical_design_qty,
  grbs_short_name,
  green_num,
  greening_addition,
  group_id,
  guarantee_period,
  gutters_length,
  height,
  id_rfid,
  improvement_category_name,
  improvement_object_cat_name,
  in_yard,
  included_in_tl,
  inert_area,
  installation_date,
  is_actual,
  is_diff_height_mark,
  is_filled,
  is_footway_area,
  is_gutter_zone,
  is_isolated,
  is_matching,
  is_object_area,
  is_roadway_area,
  is_title,
  jopa,
  lawn_area,
  lawn_area_without,
  lawn_type_name,
  life_form_type_name,
  lifetime,
  logical_state_id,
  maf_type_level2_name,
  maf_type_level3_name,
  margin_streng_name,
  material_name,
  million_trees,
  mounting_mode_name,
  name,
  near_roadway,
  no_calc,
  object_name,
  object_status_name,
  object_type_name,
  odh_axis,
  odh_id,
  odh_name,
  odh_side_name, //
  okrug_name,
  owner_name,
  owner_short_name,
  ozn_type_name, //
  parent_customer_name, //
  parent_department_name, //
  parent_district_name, //
  parent_grbs_name, //
  parent_id, //
  parent_name,
  parent_object_name, //
  parent_okrug_name, //
  parent_owner_name,
  parent_short_root_id, //
  parent_type_id, //
  passport_draft_org,
  pavilion_area,
  perennials_area,
  placement_name,
  places_area,
  places_quantity,
  planar_structure_type_ref_name,
  plant_service_recomendations_name,
  plant_type_name,
  plantation_type_name,
  pocket_area,
  property,

  quantity,
  quantity_pcs,
  quantity_rm,
  quantity_sqr,
  reapprove_required,
  related_elements_flowers_garden_id,
  related_elements_trees_shrubs_id,
  rockery_area,
  routes,
  season_shift_area,
  section, //
  section_num, //
  short_root_id, //
  shrubs_area, //
  sign_date, //
  sign_person,
  start_date, //
  state_gardening_name, //
  state_id, //
  station_clean_area, //
  susp_height, //
  suspend_vertical_design_area,
  suspend_vertical_design_qty,
  total_area,
  total_area_geo,
  total_clean_area,
  total_lawn_area,
  traff_light_car,
  traff_light_men,
  traffic_signs_name,
  tulip_area,
  unad,
  unom,
  update_required,
  utn_area,
  valuable_plants_name,
  vase_qty,
  vertical_landscaping_class_type_name,
  vertical_landscaping_ref_type_name, // Тип
  width_begin,
  width_end,
  zone_name,
};

/**
 * Ищем по имени. Только для посмотреть. В коде такого не должно быть.
 *
 * @param {string} name - Header name.
 * @returns {Array<Cell|*>} A.
 * @example ----
 * getByName('Конец, м') === [{ accessor: 'bord_end', Header: 'Конец, м' }]
 */
map.getByName = (name) => {
  //

  /**
   * Filter.
   *
   * @param {Cell} item - Item.
   * @returns {boolean} - Жопа.
   * @example ---
   */
  const filterHeaderByName = (item) => {
    if (typeof item.Header === 'string') {
      return item.Header.toLowerCase().includes(name.toLowerCase());
    }
    return false;
  };
  return Object.values(map).filter(filterHeaderByName);
};

window.getCellByName = map.getByName;

for (const newMapElement in map) {
  map[newMapElement] = new Proxy(map[newMapElement], {
    //

    /**
     * Getter.
     *
     * @param {string} target - Target.
     * @param {string} prop - Prop.
     * @returns {Cell|*} A.
     */
    get: (target, prop) => {
      if (prop === 'redefine') {
        //

        /**
         * Функция для переопределения.
         *
         * @param {object} newProperty - Имя свойства для переопределения.
         * @returns {Cell} A.
         * @example ----
         */
        return (newProperty) => {
          if (
            newProperty?.accessor &&
            newProperty.accessor !== target.accessor
          ) {
            throw new Error('нельзя переопределить accessor');
          }
          return {
            [target.accessor]: {
              ...target,
              ...newProperty,
              accessor: target.accessor,
            },
          };
        };
      }
      return target[prop];
    },
  });
}

export const cellsMainMap = new Proxy(map, {
  //

  /**
   * Getter.
   *
   * @param {string} _ - Target.
   * @param {object} prop - Prop.
   * @returns {Cell|*} A.
   */
  get: (_, prop) => {
    if (prop === 'length') {
      return Object.keys(map).length;
    }

    if (prop in map) {
      return map[prop];
    } else {
      throw new Error(
        `нет такого свойства: ${prop} . Добавьте если надо в карту cellsMainMap, по адресу src/core/uiKit/components/cells/cellsMainMap/cellsMainMap.js`,
      );
    }
  },

  /**
   * Setter.
   *
   * @param {string} _ - Target.
   * @param {object} prop - Prop.
   * @param {Cell} value - Value.
   * @returns {void}
   */
  set: (_, prop, value) => {
    throw new Error(`нельзя записывать [${value}] в ${prop}.`);
  },
});

/**
 * Функция создания Нового переопределенного cellsMainMap.
 *
 * @param {object} properties - Новые свойства.
 * @returns { { string: {Cell}} } - Жопа.
 * @example ---
 * const newCellsMainMap = createNewCellsMainMap({
 *  traff_light_car : {
 *   accessor: 'dddd',
 *   Header: 'asdfsdf'
 *   }
 * });
 */
export const createNewCellsMainMap = (properties) => {
  const newMap = { ...map, ...properties };

  return new Proxy(newMap, {
    //

    /**
     * Getter.
     *
     * @param {string} target - Target.
     * @param {object} prop - Prop.
     * @returns {Cell|*}
     */
    get: (target, prop) => {
      if (prop === 'length') {
        return Object.keys(newMap).length;
      }
      if (prop in newMap) {
        return newMap[prop];
      } else {
        throw new Error(
          `нет такого свойства: ${prop} . Добавьте если надо в карту cellsMainMap или createNewCellsMainMap`,
        );
      }
    },

    /**
     * Setter.
     *
     * @param {string} _ - Target.
     * @param {object} prop - Prop.
     * @param {Cell} value - Value.
     * @returns {void}
     */
    set: (_, prop, value) => {
      throw new Error(`нельзя записывать [${value}] в ${prop}.`);
    },
  });
};
