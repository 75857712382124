/* eslint-disable  */
import { mapValues } from 'lodash';

/**
 *
 */
export default class ValidateService {
  /**
   *
   * @param root0
   * @param root0.validate
   */
  constructor({ validate }) {
    this._validate = validate;
  }

  /**
   *
   * @param attributes
   * @param constraints
   * @param options
   */
  validateForm(attributes, constraints, options) {
    const result = this.validate(attributes, constraints, options);
    return mapValues(result, (value, key) =>
      this.getFirstMessageResult(result, key),
    );
  }

  /**
   *
   * @param attributes
   * @param constraints
   * @param options
   */
  validate(attributes, constraints, options) {
    const validateOptions = this._getValidateOptions(options);
    return this._validate.validate(attributes, constraints, validateOptions);
  }

  /**
   *
   * @param value
   * @param constraints
   * @param options
   */
  single(value, constraints, options) {
    const validateOptions = this._getValidateOptions(options);
    return this._validate.single(value, constraints, validateOptions);
  }

  /**
   *
   * @param validateResult
   */
  isValidResult(validateResult) {
    return !validateResult;
  }

  /**
   *
   * @param validateResult
   * @param attrName
   */
  getFirstMessageResult(validateResult, attrName) {
    let result;
    if (validateResult) {
      if (attrName) {
        const messages = validateResult[attrName];
        if (messages) {
          result = messages[0];
        } else {
          result = null;
        }
      } else {
        result = validateResult[0];
      }
    } else {
      result = null;
    }
    return result;
  }

  /**
   *
   * @param options
   */
  _getValidateOptions(options = {}) {
    return Object.assign(
      {
        fullMessages: false,
      },
      options,
    );
  }
}
