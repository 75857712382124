import { Cell } from 'types/table';

/**
 * Колонка __Рекомендации по уходу__.
 *
 * @type {{
 * accessor: 'plant_service_recomendations_name',
 * Header: 'Рекомендации по уходу',
 * sortable: true,
 * }}
 * @augments Cell
 */
export const plant_service_recomendations_name: Cell = {
  Header: 'Рекомендации по уходу',
  accessor: 'plant_service_recomendations_name',
  sortable: true,
};
