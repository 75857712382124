import { SelectFF } from 'core/form/finalForm/fields';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';
import { NumberParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

export const NOT_NEED_GLONASS_NAME = 'notNeedGlonass';

/**
 * Не подлежит оснащению БНСО –> not_need_glonass [выпадающий список из 3-х значений. Все: true+false+null, Да - true, Нет- false].
 *
 * @returns {JSX.Element}
 */
export const NotNeedGlonassFilter = () => {
  const options = [
    { id: 1, name: 'Да' },
    { id: -1, name: 'Нет' },
  ];
  return (
    <SelectFF
      className={filterInput}
      {...{ options }}
      label={'Не подлежит оснащению БНСО'}
      name={NOT_NEED_GLONASS_NAME}
    />
  );
};

/**
 * Params.
 *
 * @returns {*}
 */
export const notNeedGlonassParams = () => {
  addPreparation(NOT_NEED_GLONASS_NAME, (value) => value > 0);
  return {
    [NOT_NEED_GLONASS_NAME]: NumberParam,
  };
};
