import { turnIntoCamelCase } from 'app/utils/turnIntoCamelCase';
import { useSelector } from 'react-redux';

/**
 * Selector получения карточки object из state.
 *
 * @function
 * @category useSelector
 * @returns {{calcAttribute:{}}}
 */
export const useGetCardSelector = () => {
  const objectValue = useSelector((state) => state?.card?.object);
  const objectValueCamel = turnIntoCamelCase(objectValue);
  return {
    ...objectValue,
    ...objectValueCamel,
  };
};
