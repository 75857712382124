import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';

import { fetchOghTable } from './fetchOghTable';
import { useFetchOghTableCount } from './useFetchOghTableCount';

/**
 * Хук получения данный для таблиц реестров ОГХ.
 *
 * @param {Array} queryKey - Данные для запроса.
 * @returns {{table: Array, isLoading: boolean, count: number }}
 * @example ----
 *   const queryKey = [
 *     'TableLittleForm',
 *     {
 *       ...queryTable,
 *       ...prepareFilters,
 *       parentTypeId,
 *       typeId,
 *     },
 *   ];
 *
 *   const {
 *     table,
 *     isLoading,
 *     count,
 *   } = useFetchOghTable(queryKey);
 */
export const useFetchOghTable = (queryKey) => {
  const { data: { count } = {} } = useFetchOghTableCount(queryKey);
  const res = useQueryAdaptor(queryKey, fetchOghTable);
  return { ...res, count, table: res.data };
};
