import * as env from 'config/env';
import qs from 'query-string';

import { getUrlWithParams } from './getUrlWithParams';
import { handleErrors } from './handleErrors';
import { toJson } from './middlewares';
import { withSnakeCase } from './withSnakeCase';

/**
 * Метод-запрос обновления.
 *
 * @param {string} methodUrl - Метод запроса он же урл.
 * @param {object} body - Параметры.
 * @param {object} [options] - Дополнительные параметры.
 * @param {string} [options.host] - Хост, (начальный урл).
 * @param {string} [options.credentials] - Опция, credentials.
 * @param {boolean} [options.parseResponse] - Флаг, что нужно parseResponse.
 * @param {object} [options.headers] - Дополнительные параметры для хедера.
 * @param {boolean} [options.urlencoded] - Нужно ли делать urlencoded.
 * @param {boolean} [options.jsonBody] - Флаг что jsonBody.
 * @param {boolean} [options.convertIntoSnakeCase] - Флаг, нужно ли конвертировать в CamelCase.
 * @returns {Promise<*|Response>}
 */
export const put = async (methodUrl, body, options = {}) => {
  const {
    host = env.ODS_API_URL,
    credentials = 'include',
    parseResponse = true,
    jsonBody = true,
    urlencoded = false,
    headers,
    convertIntoSnakeCase = true,
    ...fetchOptions
  } = options;

  const url = getUrlWithParams(host, methodUrl);

  const bodyWithSnakeCase = convertIntoSnakeCase ? withSnakeCase(body) : body;

  let fetchBody;
  let contentType;
  if (jsonBody && typeof body === 'object') {
    fetchBody = JSON.stringify(bodyWithSnakeCase);
    contentType = 'application/json; charset=utf-8';
  } else if (urlencoded) {
    fetchBody = qs.stringify(bodyWithSnakeCase);
    contentType = 'application/x-www-form-urlencoded';
  } else {
    fetchBody = body;
    contentType = null;
  }

  const queryOptions = {
    body: fetchBody,
    credentials,
    headers: {
      ...(contentType ? { 'Content-Type': contentType } : {}),
      ...headers,
    },
    method: 'PUT',
    ...fetchOptions,
  };

  const resp = await fetch(url, queryOptions);

  await handleErrors(resp);

  return parseResponse ? toJson(resp) : resp;
};
