import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';

/**
 * Setup Link Методические материалы.
 *
 * @returns KnowledgeBaseDict.
 */
export const useSetupKnowledgeBaseLink = () => {
  const { data } = useGetDictionary('knowledge_base');

  const knowledgeBaseDict = (data || []).sort((one, two) =>
    one?.name > two?.name ? 1 : -1,
  );

  return { knowledgeBaseDict };
};
