import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { ButtonSendMatchingOgh } from 'app/components/card/ogh/index/card/components/SendMatchingOgh.Button/Button.SendMatchingOgh';
import { DialogSendMatching } from 'app/pages/cardsOgh/components/buttonsCard/components/SendMatching/DialogSendMatching';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { AlertDialog } from 'core/uiKit/components/dialogs/Alert.Dialog';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import React, { useState } from 'react';

import { useCheckObjectParent } from './hooks/useCheckObjectParent';

/**
 * Компонент SendMatching.
 *
 * @returns {React.ReactElement}
 */
export const SendMatching = () => {
  const { objectId } = useGetObjectId();
  const card = useGetCurrentCard();
  const [dialog, setDialog] = useState({
    dataRequest: null,
    open: false,
  });
  const { editMode } = useMode();

  const { checkObjectParent } = useCheckObjectParent({ setDialog });

  /**
   *  Функция проверки.
   *
   */
  const checkObjectParentHandler = () => {
    checkObjectParent({
      date: card.startDate,
      id: card.rootId,
    });
  };

  return (
    <>
      <AlertDialog>
        <DialogSendMatching open={dialog.open} setOpen={setDialog} />
      </AlertDialog>
      {!editMode ? (
        <ButtonSendMatchingOgh
          objectId={+objectId}
          onClick={checkObjectParentHandler}
        />
      ) : null}
    </>
  );
};
