import nestedStyles from 'assets/styles/card.nestedTable.scss';
import IconButton, {
  iconButtonStyle,
  iconStyle,
} from 'core/newComponents/IconButton';
import { ChevronRightIcon, ExpandMoreIcon } from 'core/uiKit/material-ui';
import React from 'react';

import NestedTable from '../../../common/table/NestedTable';
/* eslint-disable */

class LinkageList extends React.Component {
  state = {
    visible: new Set(),
  };

  render() {
    return (
      <div>
        {this.props.list &&
          this.props.list.map((linkage, index) => {
            const gpsName = linkage.gps_name;
            const uuid = linkage.gps_uuid;
            const isVisible = this.state.visible.has(uuid);
            return (
              <div key={index}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <IconButton
                    iconClassName="material-icons"
                    iconStyle={iconStyle}
                    style={iconButtonStyle}
                    onClick={() => {
                      if (isVisible) {
                        let visible = new Set(...this.state.visible);
                        visible.delete(uuid);
                        this.setState({ visible });
                      } else {
                        let visible = new Set(...this.state.visible);
                        visible.add(uuid);
                        this.setState({ visible });
                      }
                    }}
                  >
                    {isVisible ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                  </IconButton>
                  <h2>{gpsName}</h2>
                </div>
                <div
                  style={{
                    paddingLeft: 57,
                    marginTop: -10,
                  }}
                >
                  <Linkage visible={isVisible} {...linkage} />
                  <div
                    style={{
                      content: '',
                      display: 'table',
                      clear: 'both',
                    }}
                  />
                </div>
                <hr />
              </div>
            );
          })}
      </div>
    );
  }
}

const columns = [
  {
    id: 'port',
    name: 'Порт БНСО',
  },
  {
    id: 'sens_name',
    name: 'Датчик',
  },
  {
    id: 'equipment_name',
    name: 'Навесное оборудование',
  },
];

/**
 *
 * @param props
 */
function Linkage(props) {
  if (!props.visible) {
    return null;
  }
  return (
    <NestedTable
      className={nestedStyles.renderLinkageTable}
      columns={columns}
      isAutoScrollLeft={false}
      isAutoScrollTop={false}
      main={props.connect_attr.map((row, index) => {
        return (
          <tr key={`linkage-tr-${index}`}>
            {columns.map(function (col) {
              const rowVal = row[col.id];
              return (
                <td key={`td-${index}-${col.id}`}>
                  {rowVal || <span style={{ paddingLeft: '10px' }} />}
                </td>
              );
            })}
          </tr>
        );
      })}
    />
  );
}

export default LinkageList;
