/* eslint-disable */
import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import {
  SendReason,
  SEND_REASON_NAME,
  REASON_NAME,
} from 'core/uiKit/preparedInputs/selects/multiSelect/SendReason';

 
/* eslint-disable */
/**
 * ## Причина
 * ### React Final Form Field
 * @param {object} props
 * @param {(SEND_REASON_NAME|REASON_NAME)} props.name name MultiSelect - Причина
 *
 *
 * ###### type = `MultiSelect`
 * ###### name = `SEND_REASON_NAME` or  `REASON_NAM`
 */
 
export const SendReasonFF = ({ name = SEND_REASON_NAME, ...otherProps }) => {
  return (
    <Field name={name}>
      {(props) => {
        return <SendReason {...props} {...props.input} {...otherProps} />;
      }}
    </Field>
  );
};

 
export { SEND_REASON_NAME };
 
export { REASON_NAME };

SendReasonFF.propTypes = {
   
   
/**
   * очищаем инпут с сохранением значения
   */
  clear: PropTypes.bool,
   
   
/**
   * быбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * функция для фильтрации options
   */
  filter: PropTypes.func,
   
  /* eslint-disable */
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
/**
   * placeholder
   */
  placeholder: PropTypes.string,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
};
SendReasonFF.defaultProps = {
  disabled: false,
};
