import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { MultiSelect } from 'core/uiKit/inputs/selects/MultiSelect';
import React from 'react';

export const CHANGE_LOG_TYPE_NAME = 'change_log_type';

/**
 * ## Операция.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const ChangeLogType = (props) => {
  const dict = 'changeLogType';

  const { data: options = [] } = useGetDictionary(dict);

  return (
    <MultiSelect
      {...props}
      options={options}
      optionKey={'code'}
      label={'Операция'}
      name={CHANGE_LOG_TYPE_NAME}
    />
  );
};
