import { AutocompleteMultiAsync } from 'core/uiKit/inputs/selects/AutocompleteMultiAsync';
import React from 'react';

import { fetchCarCustomers } from './api';

export const CAR_CUSTOMERS_NAME = 'customers';

/**
 * ## Заказчик.
 *
 * @param {*} args - The props.
 * @param {*} args.value - The props.
 * @param {*} args.props - The props.
 * @returns {JSX.Element}
 */
export const CarCustomers = ({ value, ...props }) => {
  return (
    <AutocompleteMultiAsync
      {...props}
      value={value || []}
      fetchFunction={fetchCarCustomers}
      label={'Заказчик'}
      name={CAR_CUSTOMERS_NAME}
    />
  );
};
