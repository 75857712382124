import * as techActions from 'app/actions/tech';
/* eslint-disable  */
import DialogAction from 'app/components/dialogs/common/DialogAction';
import { Table } from 'core/uiKit/components/tables/Table';
import { get, has, keys, values } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { actions as formActions } from 'react-redux-form';
const propTypes = {
  columns: PropTypes.array,
  data: PropTypes.object,
  onAddEquipment: PropTypes.func,
  onCancel: PropTypes.func,
  onLimit: PropTypes.func,
  onPagination: PropTypes.func,
  onSort: PropTypes.func,
  reset: PropTypes.func,
  showCondition: PropTypes.bool,
};

/**
 * F.
 *
 * @param {*} list - A.
 * @returns {*}
 */
const updateCheckedRows = (list) =>
  (list || []).reduce((result, item) => {
    result[item.uuid] = item;

    return result;
  }, {});

/**
 * Преобразование массива колонок таблицы в понятный компоненту таблицы формат.
 *
 * @param {*} source - A.
 * @returns {*}
 */
const makeColumns = (source = []) =>
  source.map((column) => ({
    Header: column.name,
    accessor: column.id,
  }));

/**
 * Возвращает массив столбцов таблицы, по которым возможна сортировка.
 *
 * @param {*} source - A.
 * @returns {*}
 */
const getSortableColumns = (source = []) =>
  source.filter((col) => col.isSort).map((col) => col.id);

/**
 * A.
 *
 * @param {*} root0 - A.
 * @param {*} root0.columns - A.
 * @param {*} root0.data - A.
 * @param {*} root0.list - A.
 * @param {*} root0.reset - A.
 * @param {*} root0.showCondition - A.
 * @param {*} root0.onAddEquipment - A.
 * @param {*} root0.onCancel - A.
 * @param {*} root0.onLimit - A.
 * @param {*} root0.onPagination - A.
 * @param {*} root0.onSort - A.
 * @returns {*}
 */
const DialogEditEquipmentTechnicList = ({
  columns,
  data,
  list = [],
  reset,
  showCondition,
  onAddEquipment,
  onCancel,
  onLimit,
  onPagination,
  onSort,
}) => {
  // выбранные строки таблицы
  const [checkedRows, setCheckedRows] = useState({});

  // сброс формы поиска при закрытии модалки
  useEffect(() => () => reset(), [reset]);

  // обновление списка выбранных строк когда приходят новый список из родительского компонента
  useEffect(() => {
    setCheckedRows(updateCheckedRows(list));
  }, [list?.length]);

  return (
    <DialogAction
      className="Dialog-Equipment-Technik-List-Edit"
      okText="Готово"
      showCondition={showCondition}
      title="Изменение связи с навесным оборудованием"
      onCancel={onCancel}
      onOk={() => {
        if (keys(checkedRows).length > 0) {
          onAddEquipment(values(checkedRows));
        }
        onCancel();
      }}
    >
      <>
        <span>Список объектов</span>
        <Table
          checkedRows={checkedRows}
          columns={makeColumns(columns)}
          data={get(data, 'table', [])}
          fetchData={onPagination}
          isCheckedRow={(item) => has(checkedRows, item.uuid)}
          pageCount={data}
          sortable_columns={getSortableColumns(columns)}
          stickHeader={false}
          onLimit={onLimit}
          onSelectRow={(item) => {
            const rows = { ...checkedRows };

            if (has(rows, item.uuid)) {
              delete rows[item.uuid];
            } else {
              rows[item.uuid] = item;
            }
            setCheckedRows(rows);
          }}
          onSort={onSort}
        />
      </>
    </DialogAction>
  );
};

DialogEditEquipmentTechnicList.propTypes = propTypes;

/**
 * MapStateToProps.
 *
 * @param {*} state - A.
 * @returns {*}
 */
const mapStateToProps = (state) => ({
  columns: state.tableColumns.equipmentTechRegistry || [],
  data: state.technologyRegistry.data,
  sort: state.rrf.techRegistry.equipment.sort,
});

/**
 * MapDispatchToProps.
 *
 * @param {*} dispatch - A.
 * @returns {*}
 */
const mapDispatchToProps = (dispatch) => ({
  /**
   *
   * @param value
   */
  onLimit: (value) => dispatch(techActions.onLimit(value, '.equipment')),

  /**
   *
   * @param value
   */
  onPagination: (value) =>
    dispatch(techActions.onPagination(value, '.equipment')),

  /**
   *
   * @param values
   */
  onSort: (values) => dispatch(techActions.onSort(values, '.equipment')),

  /**
   *
   */
  reset: () => dispatch(formActions.reset('rrf.techRegistry.equipment')),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DialogEditEquipmentTechnicList);
