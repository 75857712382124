import { post } from 'app/api/crud/post';
import { prepareDataForSaveSign } from 'app/api/requests/signs/signOghSave/prepareDataForSaveSign';
import { uploadFileReturnObj } from 'app/api/requests/signs/signOghSave/uploadFileReturnObj';
import { toDate } from 'app/utils/date/toDate';

/**
 * Функция закрытия.
 *
 * @param {object} params - Параметры.
 * @returns {function(*): Promise<*|Response>}
 */
export const signCloseElectronicSignature =
  (params) =>
  async ({ date: endDate, ...values }) => {
    const {
      certificate = {},
      documentCloseFile,
      files = [],
      objectCardFiles = [],
      signatures = [],
      signType,
      date,
      ...signInfo
    } = { ...values, ...params };

    const signPerson = certificate.subjectInfo;
    const filesClose = await uploadFileReturnObj(documentCloseFile, {
      create_date: date,
      type_id: 19,
    });

    const signsIds = await uploadFileReturnObj(signatures);
    const newFilesIds = await uploadFileReturnObj(files);

    const filesSrc = prepareDataForSaveSign({
      files: [...objectCardFiles, ...(newFilesIds || [])],
      signatures: signsIds,
    });

    return post(`/sign/ogh/${signType}`, {
      ...signInfo,
      endDate: toDate(endDate),
      file_list: filesClose.map(({ storageId }) => ({ files: storageId })),
      files: filesSrc,
      signPerson,
    });
  };
