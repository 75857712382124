import { withFinalForm } from 'core/form/finalForm/helpers/withFinalForm';
import {
  RELATED_ELEMENTS_FLOWERS_GARDEN_ID_NAME,
  RelatedElementsFlowersGardenId,
} from 'core/uiKit/preparedInputs/numberField/RelatedElementsFlowersGardenId';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * NumberField FF Связанные элементы ЗН (ID) - Цветники.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const RelatedElementsFlowersGardenIdFF = (props) => {
  return (
    <Field
      {...props}
      name={RELATED_ELEMENTS_FLOWERS_GARDEN_ID_NAME}
      component={withFinalForm(RelatedElementsFlowersGardenId)}
    />
  );
};
