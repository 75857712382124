export const REQUIRED_FIELD_MESSAGE = 'обязательное поле';

export const REQUIRED_ADDRESS_BTI = 'добавьте адрес БТИ';

export const FLOOR_QTY_MESSAGE = 'Укажите ненулевую этажность здания';

export const CUSTOMER_HINT =
  'Организация, ответственная за санитарное и техническое содержание объекта городского хозяйства';

export const SHRUBS_HINT =
  'Считается только в том случае, если цветник имеет жесткое обрамление (например, бортовой камень) и кустарник идет как элемент цветника';

export const INSURANCE_FILE_NAME = 'полис каско/осаго';

export const GO_TO_EXPORT_ALERT =
  'Перед переходом на карточку графика перемещения сохраните паспорт Базы хранения ПГР';

export const INVALID_EXPORT_DATE_ALERT =
  'Интервал действия нового графика перемещения не может перекрывать существующие графики. Минимальная дата начала действия: ';

export const INVALID_AREAS_ALERT =
  'Сохранение проекта невозможно. Сумма значений "Площадь вывоза снега, кв.м" и "Площадь роторной перекидки, кв.м" не должна превышать значение "Площадь, кв.м". Предварительно измените одно или несколько этих значений.';

export const INVALID_REAGENT_AREA_ALERT = {
  COMMON:
    'Сохранение проекта невозможно. Значение параметра "Площадь обработки реагентами, кв.м" не должно превышать значение "Площадь, кв.м". Предварительно измените одно или оба этих значения.',
  OTHER_TERRITORY:
    'Сохранение проекта невозможно. Значение "Площадь обработки реагентами, кв.м" не должно превышать сумму значений "Площадь уборки мех., кв.м" и "Площадь уборки ручн., кв.м". Предварительно измените одно или несколько этих значений.',
};

export const INVALID_SNOW_AREA_ALERT =
  'Сохранение проекта невозможно. Значение параметра "Площадь вывоза снега, кв.м" не должно превышать значение "Площадь, кв.м". Предварительно измените одно или оба этих значений.';

export const INVALID_ROTOR_AREA_ALERT =
  'Сохранение проекта невозможно. Значение параметра "Площадь роторной перекидки, кв.м" не должно превышать значение "Площадь, кв.м". Предварительно измените одно или оба этих значений.';

export const ODH_AXIS_TOOLTIP =
  'Ввод названия оси из одного или двух символов. Например: АА, Б, в1, gH';

export const EMPTY_CAR_TYPE_ID =
  'Изменение связи с навесным оборудованием не возможно без указания типа техники';
export const CAR_DATE_ALLOWED = 'Год не раньше 1920 и не позже текущего';
