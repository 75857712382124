import { AutocompleteAsync } from 'core/uiKit/inputs/selects/AutocompleteAsync';
import { fetchCustomers } from 'core/uiKit/preparedInputs/selects/api';
import React from 'react';

export const CUSTOMER_IMPROVEMENT_OBJECT_NAME = 'customerId';

/**
 * Заказчик.
 *
 * @param {*} props - The properties.
 * @returns {JSX.Element}
 */
export const CustomerImprovementObject = (props) => {
  return (
    <AutocompleteAsync
      {...props}
      fetchFunction={fetchCustomers('improvement_object')}
      label={'Заказчик'}
      name={CUSTOMER_IMPROVEMENT_OBJECT_NAME}
    />
  );
};
