/* eslint-disable */
import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import {
  GovNumberName,
  GOV_NUMBER_NAME_NAME,
} from 'core/uiKit/preparedInputs/selects/autocompleteAsync/GovNumberName';

/* eslint-disable */

/**
 * Рег. номер
 * ### React Final Form Field
 *
 * ###### type = `AutocompleteAsync`
 * ###### name = `govNumber`
 */
export const GovNumberNameFF = ({ name, ...otherProps }) => {
  return (
    <Field name={GOV_NUMBER_NAME_NAME}>
      {(props) => {
        return <GovNumberName {...props} {...props.input} {...otherProps} />;
      }}
    </Field>
  );
};

export { GOV_NUMBER_NAME_NAME };

GovNumberNameFF.propTypes = {
  /**
   * очищаем инпут с сохранением значения
   */
  clear: PropTypes.bool,

  /**
   * быбор темной темы
   */
  dark: PropTypes.bool,

  /**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,

  /**
   * вывод ошибки
   */
  errorText: PropTypes.string,

  /**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,

  /**
   * placeholder
   */
  placeholder: PropTypes.string,

  /**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
};
GovNumberNameFF.defaultProps = {
  disabled: false,
};
