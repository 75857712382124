/* eslint-disable */
import React from 'react';

import { PrintButtonOgh } from 'app/pages/components';

import { useFilterQueryParamsImprovementObject } from './Filter.ImprovementObject';

/* eslint-disable */
export const PrintButtonImprovementObject = ({ typeId }) => {
  const { prepareFilters = {} } = useFilterQueryParamsImprovementObject();

  return <PrintButtonOgh {...{ typeId, prepareFilters }} />;
};
