import * as types from '../constants/actionTypes';
/* eslint-disable */
const initialState = {
  feedbackSubmitted: false,
};

function navbar(state = initialState, action) {
  switch (action.type) {
    case types.RECEIVE_FEED_BACK:
      return {
        ...state,
        feedbackSubmitted: true,
      };
    default:
      return state;
  }
}

 
export default navbar;
