import { getNews } from 'app/api/news/get.News';
import { queryClient } from 'app/routes/QueryClientProvider';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';
import { DataNews } from 'types/data.News';

/**
 * Хук получения всех __непросмотренных__ новостей.
 *
 * @returns Новости.
 */
export const useGetNews = () => useQueryAdaptor<DataNews>(['getNews'], getNews);

/**
 * Очищаем кэш query всех __непросмотренных__ новостей.
 *
 * @returns Void.
 */
export const clearCasheNews = () =>
  queryClient.invalidateQueries({ queryKey: ['getNews'] });
