import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { RANDOM_WORD } from 'core/forTesting/constants';
import { vi } from 'vitest';

import { useGetDataLandscapingTable } from './useGetDataLandscaping.Table';

vi.mock('app/api/hooks/useGetCurrentCard');

describe('UseGetDataLandscapingTableSpec', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useGetCurrentCard.mockReturnValue({});
    // 🔥 Act

    // ❓ Assert
    expect(useGetDataLandscapingTable()).toStrictEqual([]);
  });
  it('useGetCurrentCard have attributes', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useGetCurrentCard.mockReturnValue({
      landscaping: [RANDOM_WORD],
    });
    // 🔥 Act

    // ❓ Assert
    expect(useGetDataLandscapingTable()).toStrictEqual([RANDOM_WORD]);
  });
});
