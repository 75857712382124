/* eslint-disable */
import React, { useState } from 'react';
import cn from 'classnames';
import { PreloadedCardContext } from 'app/pages/cardsOgh/components/PreloadedCard/contextPrelouder';

/* eslint-disable */
export const PreloadedCard = ({ className, children }) => {
  const [preloaded, setPreloaded] = useState(false);

  const startPreload = () => {
    setPreloaded(true);
  };

  const endPreload = () => {
    setPreloaded(false);
  };

  return (
    <PreloadedCardContext.Provider value={{ startPreload, endPreload }}>
      {preloaded ? (
        <div className={cn(className)}>
          <div className={'ajax-loading-indicator__background'} />
          <div className={'ajax-loading-indicator__content'} />
        </div>
      ) : null}
      {children}
    </PreloadedCardContext.Provider>
  );
};
