import { okrugListAsyncValidate } from 'app/components/card/ogh/ImprovementTerritoryCard/components/OkrugListField/asyncValidate.OkrugListField';
import { REQUIRED_FIELD_MESSAGE } from 'app/constants/messages';
import { RANDOM_NUMBER } from 'core/forTesting/constants';
import { OKRUG_LIST_NAME } from 'core/uiKit/preparedInputs/selects/multiSelect/OkrugList';

 
describe('title asyncValidate.OkrugListField.spec', () => {
  it.each`
    value              | description                    | expected
    ${[]}              | ${'Пустой массив'}             | ${{ [OKRUG_LIST_NAME]: REQUIRED_FIELD_MESSAGE }}
    ${['']}            | ${'Массив с пустой строкой'}   | ${{ [OKRUG_LIST_NAME]: REQUIRED_FIELD_MESSAGE }}
    ${[RANDOM_NUMBER]} | ${'Массив с рандомным числом'} | ${null}
  `('тест валидации $description', ({ value, expected }) => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const formValues = {
      [OKRUG_LIST_NAME]: value,
    };
    // Act
    // Assert
    expect(okrugListAsyncValidate(formValues)).toStrictEqual(expected);
  });
});
