import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { CoatingTypeIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/CoatingTypeId.RF';
import { useFieldRF } from 'core/form/reduxForm/hooks/useField.RF';
import { COATING_GROUP_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingGroupId';
import { COATING_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingTypeId';
import { useSkipFirstRender } from 'core/utils/hooks/useSkipFirstRender';

/**
 * Вид покрытия (уточнение).
 *
 * @returns {JSX.Element}
 */
export const CoatingTypeIdFiled = () => {
  const { editMode } = useMode();
  const { typeId: typeIdParent } = useGetParentCard();
  const { value: coating_group_id } = useFieldRF(COATING_GROUP_ID_NAME);
  const { onChange } = useFieldRF(COATING_TYPE_ID_NAME);

  useSkipFirstRender(() => {
    if (editMode) {
      onChange(null);
    }
  }, [coating_group_id]);

  return (
    <CoatingTypeIdRF
      disabled={!editMode}
      filter={(data) => {
        if (editMode) {
          if (typeIdParent) {
            return data?.filter(
              ({ ogh_object_type_list, group_id }) =>
                ogh_object_type_list.some(
                  ({ ogh_object_type_id }) =>
                    +ogh_object_type_id === +typeIdParent,
                ) && +coating_group_id === +group_id,
            );
          }
        }

        return data;
      }}
    />
  );
};
