import { Cell } from 'core/uiKit/components/tables/Table';

/**
 * Колонка __Вид покрытия (облицовка)__.
 *
 * @type {{ accessor: 'coating_face_type_name', Header: 'Вид покрытия (облицовка)' }}
 * @augments Cell
 */
export const coating_face_type_name: Cell = {
  Header: 'Вид покрытия (облицовка)',
  accessor: 'coating_face_type_name',
  sortable: true,
};
