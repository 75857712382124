import { SelectFF } from 'core/form/finalForm/fields/default/selects/SelectFF';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';
import { NumberParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

export const GREENING_ADDITION_NAME = 'greeningAddition';

/**
 * Требует дополнения по зелёным насаждениям
 * greeningAddition.
 *
 * @returns {JSX.Element}
 */
export const GreeningAdditionFilter = () => {
  const options = [
    { id: 1, name: 'Да' },
    { id: -1, name: 'Нет' },
  ];
  return (
    <SelectFF
      className={filterInput}
      {...{ options }}
      label={'Требует доп-я по насаждениям'}
      name={GREENING_ADDITION_NAME}
    />
  );
};

/**
 * Добавляет форматирование
 * тип для query-string.
 *
 * @function
 * @returns {object}
 */
export const greeningAdditionParams = () => {
  addPreparation(GREENING_ADDITION_NAME, (value) => value > 0);
  return {
    [GREENING_ADDITION_NAME]: NumberParam,
  };
};
