/* eslint-disable  */
import { Button } from 'core/uiKit/components/buttons/Button';
import { Icon } from 'core/uiKit/material-ui';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Кнопка "Отклонить" Огх.
 *
 * ```js
 import RejectObjectOghButton from 'core/uiKit/components/buttons/RejectObjectOgh.Button';
 * ```
 * или
 *
 * ```js
 import  { RejectObjectOghButton } from 'core/uiKit/components';
 * ```
 *
 * (позже будет удалена из карточки ОГХ, т.к. Не будет согласования по шаблону).
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const RejectObjectOghButton = (props) => {
  return (
    <Button
      {...props}
      color={'error'}
      variant={'contained'}
      startIcon={<Icon>not_interested</Icon>}
    >
      Отклонить
    </Button>
  );
};

export default RejectObjectOghButton;

RejectObjectOghButton.propTypes = {
  /**
   * Id версии ОГХ.
   */
  objectId: PropTypes.number.isRequired,
};
