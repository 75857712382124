import { useFormValuesCardRF } from 'app/actions/odsObjectActions/useFormValuesCardRF';
import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useState } from 'react';

/**
 * Select RF  уточнение.
 *
 * @returns
 */
export const useSetupMafTypeLevel2IdField = () => {
  const [haveOptions, setHaveOptions] = useState(false);
  const { data } = useGetDictionary<
    {
      attribute: {
        name: string;
        maf_type_list: {
          maf_type_level1_id: {
            maf_type_level1: number;
          };
        }[];
      };
      id: number;
    }[]
  >('maf_type_level2');

  const { editMode } = useMode();
  const { maf_type_level1_id = 0 } = useFormValuesCardRF<{
    maf_type_level1_id: number;
    maf_type_level2_id: number | string;
  }>();

  const options = (data || []).map((item) => {
    const listLevel1 = (item.attribute?.maf_type_list || []).map(
      (item) => item.maf_type_level1_id.maf_type_level1,
    );
    return {
      id: item.id,
      listLevel1,
      name: item.attribute.name,
    };
  });

  /**
   * Фильтрация Уточнения.
   *
   * @param dict - Справочные значения.
   * @returns {*}
   */
  const filter = (
    dict: { id: number; listLevel1: number[] }[],
  ): { id: number; listLevel1: number[] }[] => {
    const options = (dict || []).filter((item) =>
      item.listLevel1.includes(maf_type_level1_id),
    );
    setHaveOptions(Boolean(options.length));
    return options;
  };

  const disabled = !(editMode && haveOptions);

  return { disabled, filter, options };
};
