import {
  fetchCarModels,
  fetchCarOwners,
  fetchCarSpecialModels,
} from 'app/api/typeahead';
import { elementFormGrid } from 'app/components/card/common/grid';
import { WithOldValueSelect } from 'app/components/common/SelectField';
import { CAR_DATE_ALLOWED } from 'app/constants/messages';
import { column } from 'app/constants/tables';
import Sts from 'app/domain/Sts';
import getHint from 'app/utils/getHint';
import { VinRF } from 'core/form/reduxForm/fields';
import KeyboardDatePickerRF from 'core/form/reduxForm/fields/default/datePickers/KeyboardDatePickerRF';
import { AutocompleteAsyncRF } from 'core/form/reduxForm/fields/default/selects/AutocompleteAsyncRF';
import { TextFieldRF } from 'core/form/reduxForm/fields/default/TextFieldRF';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import govNumberHint from './govNumberHint';
import ptsHint from './ptsHint';

/**
 * A.
 *
 * @param {*} value - A.
 * @returns {*}
 */
const parseFloatWithComma = (value) => parseFloat(`${value}`.replace(',', '.'));

/**
 * A.
 *
 * @param {*} value - A.
 * @returns {*}
 */
const kvhPowerFromHp = (value) =>
  value ? (parseFloatWithComma(value) / 1.36).toFixed(1) : null;

/**
 * A.
 *
 * @param {*} value - A.
 * @returns {*}
 */
const horsePowerFromKvh = (value) =>
  value ? (parseFloatWithComma(value) / 1.36).toFixed(1) : null;
const MAX_POWER = 9999;
const maxPowerLimitErrorMessage = `Максимальное значение поля ${MAX_POWER}`;

/**
 * A.
 *
 */
class PropsFields extends React.Component {
  static propTypes = {
    carCreateYears: PropTypes.array,
    carEcologyClasses: PropTypes.array,
    carFuncTypeGroupId: PropTypes.number,
    carFuncTypeGroups: PropTypes.array,
    carFuncTypes: PropTypes.array,
    carMotorTypes: PropTypes.array,
    carOwnership: PropTypes.array,
    changeFieldValue: PropTypes.func.isRequired,
    currentValues: PropTypes.object.isRequired,
    factoryNumberDisabled: PropTypes.bool,
    isEditable: PropTypes.func.isRequired,
    isEditableLeasingEndDate: PropTypes.func.isRequired,
    legalCarRegistration: PropTypes.array,
    oldValues: PropTypes.object,
    onChangeCarOwnership: PropTypes.func.isRequired,
    required: PropTypes.bool,
    typeId: PropTypes.number,
    vinDisabled: PropTypes.bool,
  };

  state = {
    electroPowerHint: getHint('electro_power'),
  };

  /**
   * A.
   *
   * @param {*} horsePower - A.
   * @param {*} kvhPower - A.
   * @returns {*}
   */
  setElectroPowerHint(horsePower, kvhPower) {
    this.setState((state) => ({
      ...state,
      electroPowerHint:
        !horsePower || !kvhPower
          ? getHint('electro_power')
          : `${kvhPower} кВт/ч = ${horsePower} л.с. (${getHint(
              'electro_power',
            )})`,
    }));
  }

  /**
   * A.
   *
   * @param {*} prevProps - A.
   * @returns {*}
   */
  componentDidUpdate(prevProps) {
    const { isLinkedWithGlonass, changeFieldValue } = this.props;
    if (
      prevProps.isLinkedWithGlonass !== isLinkedWithGlonass &&
      isLinkedWithGlonass
    ) {
      changeFieldValue('not_need_glonass', false);
    }
  }

  /**
   * A.
   *
   * @param {*} nextProps - A.
   * @returns {*}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { horse_power = null, electro_power = null } =
      nextProps.currentValues;

    const kvhPower = electro_power || kvhPowerFromHp(horse_power);

    this.setElectroPowerHint(horse_power, kvhPower);
  }

  /**
   * A.
   *
   * @param {*} root0 - A.
   * @param {*} root0.id - A.
   * @param {*} root0.label - A.
   * @param {*} root0.editable - A.
   * @param {*} root0.formValue - A.
   * @param {*} root0.component - A.
   * @param {*} root0.addition - A.
   * @param {*} root0.disabled - A.
   * @returns {*}
   */
  getElement({
    id,
    label,
    editable = this.isEditable(id),
    formValue = true,
    component,
    addition,
    disabled,
  }) {
    const { oldValues } = this.props;
    return {
      addition: {
        oldValue: oldValues[id],
        ...addition,
      },
      component,
      disabled,
      editable,
      formValue,
      id,
      label,
      name: id,
    };
  }

  /**
   * A.
   *
   * @returns {*}
   */
  getElements() {
    const { props } = this;
    const {
      isEditable,
      changeFieldValue,
      carFuncTypeGroupId,
      required,
      isEditableLeasingEndDate,
      currentValues,
      editCar,
      legalCarRegistration,
    } = props;

    const legalCarRegistrationId = currentValues?.legal_car_registration_id;
    const GIBDD_CODE = 2;
    const as = legalCarRegistration.find(
      (item) => item.id === legalCarRegistrationId,
    );
    const isGibdd = ~~as?.code === ~~GIBDD_CODE;

    const disableVinHint =
      'Редактирование возможно только при выборе причины изменения «Ошибочное внесение информации»';
    return [
      this.getElement({
        addition: {
          helpTitle: !isEditable('vin') ? disableVinHint : '',
          required: required && !props.vinDisabled,
        },
        component: VinRF,
        disabled: !(
          (!props.vinDisabled && isEditable('vin')) ||
          editCar.isErrorInputCar
        ),
        id: 'vin',
      }),
      this.getElement({
        addition: {
          FormHelperTextProps: {
            error: true,
          },
          required: required && !props.factoryNumberDisabled,
        },
        component: TextFieldRF,
        editable: !props.factoryNumberDisabled && isEditable('factory_number'),
        id: 'factory_number',
        label: 'Заводской номер',
      }),
      this.getElement({
        addition: {
          fetchFunction: fetchCarModels,

          /**
           * A.
           *
           * @param {*} value - Value.
           * @returns {*}
           */
          insteadOfOnChange: (value) => {
            changeFieldValue('car_model_id', value);
            changeFieldValue('body_capacity', value.body_capacity);
            changeFieldValue('max_speed', value.max_speed);
            changeFieldValue('load_capacity', value.load_capacity);
            changeFieldValue('car_model_producer', value.car_model_producer);
          },

          label: 'Марка базового шасси',
          required,
        },
        component: AutocompleteAsyncRF,
        editable: Boolean(
          editCar.isActualCar ||
            editCar.isModernizationCar ||
            editCar.isErrorInputCar,
        ),
        id: 'car_model_id',
      }),
      this.getElement({
        addition: {
          fetchFunction: fetchCarSpecialModels,
          label: 'Модель спец. техники',
          required,
        },
        component: AutocompleteAsyncRF,
        editable: Boolean(
          editCar.isActualCar ||
            editCar.isModernizationCar ||
            editCar.isErrorInputCar,
        ),
        id: 'car_special_model_id',
      }),
      this.getElement({
        addition: {
          label: 'Год выпуска',
          options: props.carCreateYears,
          placeholder: CAR_DATE_ALLOWED,
          required,
          searchable: true,
        },
        component: WithOldValueSelect,
        editable: Boolean(
          editCar.isActualCar ||
            editCar.isModernizationCar ||
            editCar.isErrorInputCar,
        ),
        id: 'car_create_date',
      }),
      this.getElement({
        addition: {
          digits: 2,
          errorText:
            MAX_POWER < parseInt(currentValues.horse_power, 10)
              ? maxPowerLimitErrorMessage
              : null,
          helpTitle: getHint('horse_power'),
          mask: Array(4).fill(/\d/),
          oldValue: undefined, //'9999',

          positive: true,
          required,

          /**
           * A.
           *
           * @param {*} value - Value.
           * @returns {*}
           */
          withChange: (value) => {
            const kvhPower = kvhPowerFromHp(value);

            changeFieldValue('electro_power', kvhPower);
            this.setElectroPowerHint(value, kvhPower);
          },
          zero: false,
        },
        component: TextFieldRF,
        editable: Boolean(
          editCar.isActualCar ||
            editCar.isModernizationCar ||
            editCar.isErrorInputCar,
        ),
        id: 'horse_power',
        label: 'Мощность двигателя, л.с.',
      }),
      this.getElement({
        addition: {
          digits: 1,
          formatChars: {
            9: '[0-9]',
          },
          helpTitle: this.state.electroPowerHint,
          maxLength: 5,
          oldValue: undefined,
          positive: true,
          type: 'decimal',

          /**
           * A.
           *
           * @param {*} value - Value.
           * @returns {*}
           */
          withChange: (value) => {
            changeFieldValue('horse_power', horsePowerFromKvh(value));
          },

          zero: false,
        },
        component: TextFieldRF,
        editable: false,
        id: 'electro_power',
        label: 'Мощность двигателя, кВт/ч',
      }),
      this.getElement({
        addition: {
          label: 'Тип двигателя',
          options: props.carMotorTypes,
          required,
        },
        component: WithOldValueSelect,
        editable: Boolean(editCar.isActualCar || editCar.isModernizationCar),
        id: 'car_motor_type_id',
      }),
      this.getElement({
        addition: {
          label: 'Экологический класс',
          options: props.carEcologyClasses,
          required,
        },
        component: WithOldValueSelect,
        editable: Boolean(
          editCar.isActualCar ||
            editCar.isModernizationCar ||
            editCar.isErrorInputCar,
        ),
        id: 'car_ecology_class_id',
      }),
      this.getElement({
        addition: {
          formatChars: {
            9: '[0-9]',
          },
          mask: '999999',
        },
        component: TextFieldRF,
        editable: false,
        id: 'load_capacity',
        label: 'Разрешенная максимальная масса, кг',
      }),
      this.getElement({
        addition: {
          digits: 2,
          formatChars: {
            9: '[0-9]',
          },
          mask: '999999',
          required,
        },
        component: TextFieldRF,
        editable: Boolean(
          editCar.isActualCar ||
            editCar.isModernizationCar ||
            editCar.isErrorInputCar,
        ),
        id: 'weight_not_load',
        label: 'Масса без нагрузки, кг',
      }),
      this.getElement({
        component: TextFieldRF,
        editable: false,
        formValue: true,
        id: 'car_model_producer',
        label: 'Производитель',
      }),
      this.getElement({
        addition: {
          fetchFunction: fetchCarOwners,
          label: 'Владелец техники',
          required,
        },
        component: AutocompleteAsyncRF,
        editable: Boolean(
          editCar.isActualCar ||
            editCar.isChangeCarPerson ||
            editCar.isModernizationCar ||
            editCar.isErrorInputCar,
        ),
        id: 'car_owner_id',
      }),
      this.getElement({
        addition: {
          label: 'Тип принадлежности',
          options: props.carOwnership,
          required,
          withChange: props.onChangeCarOwnership,
        },
        component: WithOldValueSelect,
        editable: Boolean(
          editCar.isActualCar ||
            editCar.isModernizationCar ||
            editCar.isErrorInputCar,
        ),
        id: 'car_ownership_id',
      }),
      this.getElement({
        addition: {
          label: 'Дата окончания договора лизинга',
          maxDate: moment('01.01.3000'),
          minDate: isEditableLeasingEndDate('leasing_end_date')
            ? moment()
            : null,
        },
        component: KeyboardDatePickerRF,
        editable: Boolean(
          isEditableLeasingEndDate('leasing_end_date') &&
            (editCar.isActualCar ||
              editCar.isModernizationCar ||
              editCar.isErrorInputCar),
        ),
        id: 'leasing_end_date',
      }),
      this.getElement({
        addition: {
          label: 'Группа техники',
          //

          /**
           * A.
           *
           * @param {*} value - Value.
           * @returns {*}
           */
          onChange: (value) => {
            changeFieldValue('car_func_type_id', null);
            changeFieldValue('car_func_type_group_id', value);
          },

          options: props.carFuncTypeGroups,
          required,
        },
        component: WithOldValueSelect,
        editable: Boolean(editCar.isChangeCarFuncType),

        id: 'car_func_type_group_id',
      }),
      this.getElement({
        addition: {
          filterOptionsCallback: carFuncTypeGroupId
            ? (item) => item.car_group_id === carFuncTypeGroupId
            : null,
          label: 'Тип техники',
          //

          /**
           * A.
           *
           * @param {*} value - Value.
           * @returns {*}
           */
          onChange: (value) => {
            changeFieldValue('car_func_type_id', value);
            const { car_group_id } =
              props.carFuncTypes.find((item) => item.id === value) || {};
            changeFieldValue('car_func_type_group_id', car_group_id);
          },

          options: props.carFuncTypes,
          required,
        },
        component: WithOldValueSelect,
        editable: Boolean(editCar.isChangeCarFuncType),

        id: 'car_func_type_id',
      }),
      this.getElement({
        addition: {
          label: 'Орган постановки на учет',
          options: props.legalCarRegistration,
          required,

          /**
           * A.
           *
           * @returns {*}
           */
          withChange: () => {
            changeFieldValue('pts', null);
          },
        },
        component: WithOldValueSelect,
        editable: Boolean(
          editCar.isActualCar ||
            editCar.isModernizationCar ||
            editCar.isErrorInputCar,
        ),
        id: 'legal_car_registration_id',
      }),
      this.getElement({
        addition: {
          formatChars: {
            9: '[а-яА-Я0-9]',
          },
          helpTitle: this.getGovNumberHint(),
          mask: '99999999999999999999999999999999999',
          required,
        },
        component: TextFieldRF,
        editable: Boolean(
          editCar.isActualCar ||
            editCar.isModernizationCar ||
            editCar.isErrorInputCar,
        ),
        id: 'gov_number',
        label: 'Регистрационный номер',
      }),
      this.getElement({
        addition: {
          formatChars: {
            9: '[0-9]',
            as: '[A-ZА-Я]',
          },
          helpTitle: ptsHint,
          mask: isGibdd ? '99aa999999' : 'aa999999',
          required,
        },
        component: TextFieldRF,
        editable: Boolean(
          currentValues.legal_car_registration_id &&
            isEditable('pts') &&
            (editCar.isActualCar ||
              editCar.isModernizationCar ||
              editCar.isErrorInputCar),
        ),
        id: 'pts',
        label: '№ПТС/ПСМ',
      }),
      this.getElement({
        addition: {
          required,
        },
        component: KeyboardDatePickerRF,
        editable: Boolean(
          editCar.isActualCar ||
            editCar.isModernizationCar ||
            editCar.isErrorInputCar,
        ),
        id: 'pts_date',
        label: 'Дата выдачи ПТС/ПСМ',
      }),
      this.getElement({
        addition: {
          formatChars: Sts.formatChars,
          helpTitle: Sts.formatHint,
          mask: Sts.mask,
        },
        component: TextFieldRF,
        editable: Boolean(
          editCar.isActualCar ||
            editCar.isModernizationCar ||
            editCar.isErrorInputCar,
        ),
        id: 'sts',
        label: '№СТС',
      }),
    ];
  }

  /**
   * A.
   *
   * @returns {*}
   */
  getGovNumberHint() {
    const { carFuncTypes, currentValues } = this.props;
    const { car_func_type_id } = currentValues;
    let result;
    if (car_func_type_id) {
      const item = carFuncTypes.find((item) => item.id === car_func_type_id);
      if (item && item.is_check_gov_number) {
        result = govNumberHint;
      } else {
        result = null;
      }
    } else {
      result = null;
    }
    return result;
  }

  /**
   * A.
   *
   * @param {*} id - Id.
   * @returns {*}
   */
  isEditable(id) {
    return this.props.isEditable(id);
  }

  /**
   * A.
   *
   * @returns {*}
   */
  render() {
    return elementFormGrid(this.getElements(), column.TWO);
  }
}

/**
 * A.
 *
 * @param {*} state - S.
 * @returns {*}
 */
function mapStateToProps(state) {
  // const { mode: { reasonCode, requestType } = {} } = state.card;
  // создание техники
  // const isEditCar = requestType === 'edit_car';
  const editCar = {
    // Актуализация техники
    isActualCar: true, //isEditCar && reasonCode === 'actual_car',
    // Изменение владельца/заказчика/подрядчика (пользователя) ТС
    isChangeCarFuncType: true, //isEditCar && reasonCode === 'change_car_person',
    // Изменение типа ТС, подтверждаемое фотоизображением(-ями) ТС, позволяющим однозначно определить регистрационный номер ТС и вид установленного на нем оборудования
    isChangeCarPerson: true, //isEditCar && reasonCode === 'change_car_func_type',
    // Модернизация
    isErrorInputCar: true, //isEditCar && reasonCode === 'modernization_car',
    // Отправка на сезонное хранение
    isModernizationCar: true, //isEditCar && reasonCode === 'send_for_storage',
    // Ошибочное внесение информации
    isSendForStorage: true, //isEditCar && reasonCode === 'error_input_car',
  };

  // Изменение связи
  // const isEditTree = requestType === 'edit_tree';
  const editTree = {
    // Добавление оборудования
    isAddCarEquipment: true, //isEditTree && reasonCode === 'add_car_equipment',
    // Снятие оборудования
    isRemoveCarEquipment: true, //isEditTree && reasonCode === 'remove_car_equipment',
  };

  const legalCarRegistration = state?.odsDicts.legalCarRegistration;

  return {
    editCar,
    editTree,
    legalCarRegistration,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PropsFields);
