/* eslint-disable */
export function get() {
  if (!isOgh() && !isTech()) {
    throw new Error('Не удалось определить тип карточки');
  }
  return isOgh() ? 'ogh' : isTech() ? 'tech' : undefined;
}

/* eslint-disable */
export function isTech() {
  const url = window.location.href;
  return url.indexOf('/tech/') > -1;
}

/* eslint-disable */
export function isOgh() {
  const url = window.location.href;
  return url.indexOf('/ogh/') > -1;
}

/* eslint-disable */
export function defineEntity(url) {
  return url.indexOf('/ogh/') > -1
    ? 'ogh'
    : url.indexOf('/tech/') > -1
    ? 'tech'
    : null;
}
