import { IconButton } from 'core/uiKit/material-ui';
import { DeleteIcon } from 'core/uiKit/material-ui';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Компонент DeleteSelectedButton.
 *
 * @param {object} props - Пропсы.
 * @param {*} props.currentCard - Текущая карточка.
 * @param {*} props.callMapFunction - Any.
 * @param {boolean} props.disabled - Флаг редактирования.
 * @returns {JSX.Element}
 */
const DeleteSelectedButton = ({ currentCard, callMapFunction, disabled }) => {
  return (
    <IconButton
      className={'boxArea button-toggle'}
      disabled={disabled}
      title="Удалить объект с карты"
      onClick={() => {
        callMapFunction('deleteSelected', currentCard);
      }}
    >
      <DeleteIcon />
    </IconButton>
  );
};

DeleteSelectedButton.propTypes = {
  callMapFunction: PropTypes.func.isRequired,
  currentCard: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
};

export default DeleteSelectedButton;
