import { get, post } from 'app/api/crud';
/* eslint-disable */

/**
 *
 * @param id
 * @param data
 */
export const fetchOgh = (id, data) => {
  if (!isNaN(id)) {
    return get(`/ogh/${id}`, data, { parseResponse: false });
  } else {
    return false;
  }
};

/**
 *
 * @param data
 */
export const fetchStartEdit = (data) => get('/card/edit/start', data);

/**
 *
 * @param data
 */
export const fetchCancelEdit = (data) => get('/card/edit/cancel', data);

/**
 *
 * @param data
 */
export const fetchUpdate = (data) => post('/update', data);

/**
 *
 * @param data
 */
export const fetchCreate = (data) => post('/create', data);

/**
 *
 * @param data
 */
export const fetchStartDeleteCard = (data) => get('/card/delete/start', data);

/**
 *
 * @param data
 */
export const fetchDeleteCard = (data) => post('/delete', data);

/**
 *
 * @param data
 */
export const fetchHistory = (data) => post('/registry/matching/history', data);
