import { GrbsId } from 'core/uiKit/preparedInputs/selects/simpleSelect/GrbsId';

 
/**
 * Обертка input для Redux Form.
 *
 * @param {object} params - Parameters.
 * @param {object} params.input - Input object.
 * @param {object} params.props - Properties.
 * @returns {JSX.Element} - JSX.
 * @example ---
 */
export const GrbsIdRFWithReduxForm = ({ input, ...props }) => {
  return <GrbsId {...input} {...props} />;
};
