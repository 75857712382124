import { Button } from 'core/uiKit/components/buttons/Button';
import { Icon } from 'core/uiKit/material-ui';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Кнопка "Утвердить" Огх.
 *
 * ```js
 import ApproveObjectOghButton from 'core/uiKit/components/buttons/ApproveObjectOgh.Button';
 * ```
 * или
 *
 * ```js
 import  { ApproveObjectOghButton } from 'core/uiKit/components';
 * ```
 *
 * Позже будет удалена из карточки ОГХ, т.к.
 * Не будет согласования по шаблону, будет сразу отображена "Утвердить".
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const ApproveObjectOghButton = (props) => {
  return (
    <Button
      {...props}
      color={'success'}
      variant={'contained'}
      startIcon={<Icon>done</Icon>}
    >
      Утвердить
    </Button>
  );
};

export default ApproveObjectOghButton;

ApproveObjectOghButton.propTypes = {

  /**
   * Id версии ОГХ.
   */
  objectId: PropTypes.number.isRequired,
};
