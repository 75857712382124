/* eslint-disable  */
import hints from './helpers/addressListHints';

/**
 *
 * @param key
 */
const getHint = (key) => hints[key];

export default getHint;
