import { get } from 'app/api/crud/get';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';

interface UseFetchNotifications {
  allRequestCount: number;
  delayRequestCount: number;
}

const FETCH_NOTIFICATION_INTERVAL = 60 * 1000; // 1 minute

/**
 * Загрузка счетчиков уведомлений о заявках.
 *
 * @returns {UseFetchNotifications}
 */
export const useFetchNotifications = (): UseFetchNotifications => {
  // const [delayRequestCount, setDelayRequestCount] = useState(null);
  // const [allRequestCount, setAllRequestCount] = useState(null);

  const {
    data = {
      all_request_count: 0,
      delay_request_count: 0,
    },
  } = useQueryAdaptor<{
    all_request_count: number;
    delay_request_count: number;
  }>(['notification'], () => get('/notification/get'), {
    cacheTime: FETCH_NOTIFICATION_INTERVAL,
    staleTime: FETCH_NOTIFICATION_INTERVAL,
  });

  return {
    allRequestCount: data.all_request_count,
    delayRequestCount: data.delay_request_count,
  };
};
