import { get } from 'app/api';
import { KeyboardDateTimePickerFF } from 'core/form/finalForm/fields/default/datePickers/KeyboardDateTimePickerFF';
import { NumberFieldFF } from 'core/form/finalForm/fields/default/NumberField.FF';
import { SelectFF } from 'core/form/finalForm/fields/default/selects/SelectFF';
import { TextFieldFF } from 'core/form/finalForm/fields/default/TextFieldFF';
import { ConsigneeFF } from 'core/form/finalForm/fields/prepared/selects/autocompleteAsync/Consignee.FF/Consignee.FF';
import { GovNumberFF } from 'core/form/finalForm/fields/prepared/selects/autocompleteAsync/GovNumber.FF';
import { REAGENT_NAME } from 'core/form/finalForm/fields/prepared/selects/simple/ReagentFF';
import { ReagentFF } from 'core/form/finalForm/fields/prepared/selects/simple/ReagentFF';
import {
  REAGENT_KIND_NAME,
  ReagentKindFF,
} from 'core/form/finalForm/fields/prepared/selects/simple/ReagentKindFF';
import { CommentFF } from 'core/form/finalForm/fields/prepared/textField/CommentFF';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';
import React from 'react';
import { useField } from 'react-final-form';

/**
 * Приход.
 *
 * @returns {JSX}
 */
export const BodyDialogShipment = () => {
  const {
    input: { value: reagentKindId },
  } = useField(REAGENT_KIND_NAME);
  const {
    input: { onChange: reagentChange },
  } = useField(REAGENT_NAME);
  return (
    <>
      <KeyboardDateTimePickerFF name={'targetDateTime'} label={'Дата'} />
      <div className={'container__two_column'}>
        <ReagentKindFF required={true} withChange={() => reagentChange(null)} />
        <ReagentFF
          required={true}
          filter={(data) =>
            data.filter((item) => item.reagent_kind_id === reagentKindId)
          }
        />
        <NumberFieldFF
          required={true}
          decimalScale={2}
          name={'quantity'}
          label={'Количество, т.'}
        />
        <ConsigneeFF />
        <GovNumberFF />
        <TextFieldFF name={'carFuncType'} label={'Тип ТС'} disabled />
        <CarCustomer />
        <TextFieldFF name={'documentNum'} label={'Документ'} required />
      </div>
      <CommentFF fullWidth rows={4} />
    </>
  );
};

/**
 * Получение.
 *
 * @param {any} id - Id.
 * @returns {any}
 */
const fetchTech = (id) => get(`/tech/${id}`);

/**
 * CarCustomer.
 *
 * @returns {JSX}
 */
const CarCustomer = () => {
  const { input } = useField('govNumber');
  const govNumberId = input?.value?.id;
  const { data } = useQueryAdaptor(
    ['carCustomer', govNumberId],
    () => fetchTech(govNumberId),
    {
      enabled: Boolean(govNumberId),
    },
  );

  return (
    <SelectFF
      name={'carCustomer'}
      optionName={'label'}
      optionKey={'value'}
      withAll={false}
      label={'Организация – заказчик техники'}
      options={data?.car_customer_list || []}
    />
  );
};
