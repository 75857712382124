import { get } from 'app/api/crud/get';

/**
 * Fetch group id.
 *
 * @param {object} data - Data.
 * @returns {Promise}
 */
export const fetchGroupId = async (data) => {
  const response = await get('/autocomplete/group_id', data);
  return response.map((item) => ({
    id: item.group_id,
    name: `${item.group_id}`,
  }));
};
