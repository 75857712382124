import { asyncValidate as ownerAndCustomerFieldsAsyncValidate } from 'app/components/card/ogh/ImprovementTerritoryCard/components/OwnerAndCustomerFields';
import { asyncValidate as snowAreaAsyncValidate } from 'app/components/card/ogh/ImprovementTerritoryCard/components/SnowAreaTab';
import { vi } from 'vitest';

import { asyncValidate } from './asyncValidate';
vi.mock(
  'app/components/card/ogh/ImprovementTerritoryCard/components/OwnerAndCustomerFields',
);
vi.mock(
  'app/components/card/ogh/ImprovementTerritoryCard/components/SnowAreaTab',
);

describe('🐛 asyncValidate', () => {
  it('🧪 should', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    ownerAndCustomerFieldsAsyncValidate.mockReturnValue({
      bb: 4,
    });
    snowAreaAsyncValidate.mockReturnValue({
      cb: 3,
    });

    //🔥 Act
    const res = asyncValidate({ aa: 23 });

    //❓ Assert
    expect(res).toStrictEqual({ bb: 4, cb: 3 });
    expect(ownerAndCustomerFieldsAsyncValidate).toHaveBeenCalledWith({
      aa: 23,
    });
    expect(snowAreaAsyncValidate).toHaveBeenCalledWith({ aa: 23 });
  });
});
