import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useMapContext } from 'app/components/map/useMapContext';
import { INTERSECTIONS } from 'app/constants/layers';
import { getCheckGeometryIntersectionAttributes } from 'app/utils/card/checkGeometry';
import { getIntersectionsGeometries } from 'app/utils/card/checkGeometry/api/getIntersectionsGeometries';
import { useGetParamsUrl } from 'core/utils/hooks/useGetParamsUrl';

import { useReduxCheckGeometryButton } from './useRedux.CheckGeometry.Button';

/**
 * Action checkGeometryByButton.
 *
 * @returns {void} - Nothing.
 */
export const useCheckGeometryByButtonEgip = () => {
  const context = useMapContext();

  const parent = useGetParentCard();
  const { actions } = useGetParamsUrl();

  const parentInfo = actions?.haveParent ? parent : null;

  const {
    currentFormValues,
    fetchCheckGeomContains,
    fetchCheckGeometryIntersections,
    hasIntersections,
    resetIntersectionsGeometry,
    showAlert,
    treeJournal,
    currentCard,
    card,
  } = useReduxCheckGeometryButton();

  if (!currentCard.record_id) return () => {};

  const geometry = context.getGeometry(currentCard.record_id);

  return () => {
    const data = {
      attribute: getCheckGeometryIntersectionAttributes(
        currentCard.type_id,
        currentFormValues,
        card.attribute,
      ),
      cnt: treeJournal !== null ? treeJournal.cnt_action : 0,
      geometry,
      id: currentCard.root_id,
      journal:
        treeJournal !== null ? JSON.stringify(treeJournal.actions) : null,
      parentId: currentCard.parent_id,
      startDate: currentCard.start_date,
      treeParentId: parentInfo ? parentInfo.recordId : null,
      typeId: currentCard.type_id,
    };

    context.clearLayer(INTERSECTIONS);

    if (hasIntersections) {
      resetIntersectionsGeometry();
      return;
    }

    getIntersectionsGeometries({
      data,
      draw: context.drawIntersectionsGeometry,
      fetchCheckGeomContains: fetchCheckGeomContains,
      fetchCheckGeometryIntersections: fetchCheckGeometryIntersections,
      showAlert: showAlert,
    });
  };
};
