import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useSetupCharacteristicsTab } from 'app/components/card/ogh/FlowersGarden/components/CharacteristicsTab/useSetup.CharacteristicsTab';
import { ANNUALS_AREA, TOTAL_AREA } from 'app/constants/fieldNames';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { RANDOM_ID } from 'core/forTesting/constants';
import { vi } from 'vitest';

vi.mock('app/pages/cardsOgh/hooks/useMode');
vi.mock('app/api/hooks/useGetParentCard');

describe('UseSetupCharacteristicsTabSpec', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = {
      changeFieldValue: vi.fn(),
      isCurrentObjectIsExternalSystem: false,
      required: true,
      values: {},
    };

    useMode.mockReturnValue({ editMode: true });
    useGetParentCard.mockReturnValue({ typeId: RANDOM_ID });
    // 🔥 Act

    // ❓ Assert
    expect(useSetupCharacteristicsTab(props)).toStrictEqual({
      editMode: true,
      isCurrentObjectIsExternalSystem: false,
      isEditCurrentObjectIsExternalSystem: true,
      parentTypeId: RANDOM_ID,
      required: true,
      setCalculatedField: expect.any(Function),
    });
  });
  it('setCalculatedField', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const changeFieldValueSpy = vi.fn();
    const props = {
      changeFieldValue: changeFieldValueSpy,
      isCurrentObjectIsExternalSystem: false,
      required: true,
      values: { [ANNUALS_AREA]: 2 },
    };

    useMode.mockReturnValue({ editMode: true });
    useGetParentCard.mockReturnValue({ typeId: RANDOM_ID });
    // 🔥 Act
    const { setCalculatedField } = useSetupCharacteristicsTab(props);
    setCalculatedField(TOTAL_AREA, ANNUALS_AREA, 2);
    // ❓ Assert
    expect(changeFieldValueSpy).toHaveBeenCalledWith(TOTAL_AREA, 2);
  });
});
