import {
  AutocompleteMUI,
  FormControl,
  TextFieldMUI,
} from 'core/uiKit/material-ui';
import PropTypes from 'prop-types';
import React from 'react';

import { useSetupAutocomplete } from './useSetup.Autocomplete';

/**
 * Автокомплит.
 *
 * @param {object} props - Пропсы.
 * @param {string} props.className - Класс.
 * @param {boolean} props.dark - Флаг переключения темной темы.
 * @param {boolean} props.disabled - Флаг активности редактирования.
 * @param {string} props.errorText - Текст ошибки.
 * @param {string} props.helperText - Вспомогательные текст.
 * @param {string} props.label - Лейбл.
 * @param {object} props.meta - Мета данные.
 * @param {Function} props.onChange - Функция изменения.
 * @param {string} props.optionKey - Any.
 * @param {string} props.optionName - Any.
 * @param {Array} props.options - Опшины.
 * @param {boolean} props.required - Флаг обязательности поля.
 * @param {string} props.value - Значение поля.
 * @param {string} props.variant - Вариант стилизации поля.
 * @returns {JSX.Element}
 */
export const Autocomplete = ({
  className,
  disabled,
  errorText,
  helperText,
  label,
  meta = {},
  onChange,
  optionKey,
  optionName,
  options,
  required,
  value,
  variant = 'standard',
  ...otherProps
}) => {
  const { getByName, handleChange, isError, item, optionsFiltered } =
    useSetupAutocomplete({
      errorText,
      meta,
      onChange,
      optionKey,
      optionName,
      options,
      value,
    });

  return (
    <FormControl
      {...{
        className,
        style: { width: '100%' },
      }}
      disabled={disabled}
      error={isError}
      required={required}
    >
      <AutocompleteMUI
        {...otherProps}
        {...{
          style: { width: '100%' },
        }}
        noOptionsText={'не найдено'}
        disabled={disabled}
        getOptionLabel={(option) => getByName(option)}
        options={optionsFiltered || []}
        renderInput={(params) => (
          <TextFieldMUI
            {...params}
            error={isError}
            variant={variant}
            label={label}
            helperText={errorText || helperText || <span>&nbsp;</span>}
          />
        )}
        value={item}
        onChange={handleChange}
      />
    </FormControl>
  );
};

Autocomplete.propTypes = {

  /**
   * Быбор темной темы.
   */
  dark: PropTypes.bool,

  /**
   * Заблокировать на редактирование.
   */
  disabled: PropTypes.bool,

  /**
   * Вывод ошибки.
   */
  errorText: PropTypes.string,

  /**
   * Вывод вспомогательного текста.
   */
  helperText: PropTypes.string,
  label: PropTypes.string,

  /**
   * Имя селекта в форме.
   */
  name: PropTypes.string,

  /**
   * Функция вызова когда выберется итем.
   */
  onChange: PropTypes.func,

  /**
   * Имя ключа по которому будет выбираться значение.
   */
  optionKey: PropTypes.string,

  /**
   * Имя ключа по которому будет отображаться значение.
   */
  optionName: PropTypes.string,

  /**
   * Массив итемов.
   */
  options: PropTypes.array,

  /**
   * Placeholder.
   */
  placeholder: PropTypes.string,

  /**
   * Вывод информации об обязательном поле.
   */
  required: PropTypes.bool,

  /**
   * Значение.
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  value: PropTypes.any,
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),

  /**
   * Делать ли элемент "Все".
   */
  withAll: PropTypes.bool,
};

Autocomplete.defaultProps = {
  dark: false,
  disabled: false,
  errorText: '',
  helperText: '',
  label: 'Autocomplete label',
  name: 'autocomplete',

  /**
   * OnChange.
   *
   * @param {object} value - Value.
   * @returns {void} - Nothing.
   */
  onChange: (value) => value,
  optionKey: 'id',
  optionName: 'name',
  options: [],
  placeholder: '',
  required: false,
  value: '',
};
