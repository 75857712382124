import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const MATERIAL_ID_NAME = 'material_id';

/**
 * Материал.
 *
 * @param {*} props - Пропсы.
 * @returns {JSX.Element}
 */
export const MaterialId = (props) => {
  const dict = 'material';

  const { data = [] } = useGetDictionary(dict);

  return (
    <Select
      label={'Материал'}
      {...props}
      name={MATERIAL_ID_NAME}
      options={data}
    />
  );
};
