import ToggleButton from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/buttons/ShowDistrictBoundaries/ToggleButton';
import React from 'react';

import { useSetupShowDistrictBoundaries } from './useSetup.ShowDistrictBoundaries';

/**
 * Компонент кнопки для edit панели в списке объектов.
 *
 * @returns Компонет кнопки для edit.
 * @example <ShowDistrictBoundaries/>
 */
export const ShowDistrictBoundaries = () => {
  const { hasDistrictBoundaries, checkDistrictBoundaries } =
    useSetupShowDistrictBoundaries();

  return (
    <ToggleButton
      active={hasDistrictBoundaries}
      onClick={checkDistrictBoundaries}
    />
  );
};
