import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { RANDOM_NUMBER } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import PpiCard, { onSubmit } from './ppi';

vi.mock('app/components/card/common/validation');
vi.mock('app/api/hooks/useGetParentCard');
vi.mock('./submit');
vi.mock('redux-form', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    reduxForm: vi.fn(() => (Component) => {
      return Component;
    }),
  };
});

vi.mock('react-redux', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    connect: vi.fn(() => (ba) => {
      return ba;
    }),
  };
});

describe('PpiCard', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = {
      card: { bord_begin: 1, flat_element_type: [], type_id: RANDOM_NUMBER },
      card_type: 'PpiCard',
      editMode: true,
      formValues: {
        bord_begin: 1,
      },
      isCurrentObjectIsExternalSystem: false,

      mode: {},
    };
    useGetParentCard.mockReturnValue({ typeId: 1 });
    // 🔥 Act

    const wrapper = shallow(<PpiCard {...props} />);
    // ❓ Assert
    expect(wrapper.text()).toBe('<PpiCard />');
  });
});

describe('🐛 onSubmit', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = { card: { type_id: 31 } };
    const onSubmitProps = onSubmit.bind({ props });
    // 🔥 Act

    onSubmitProps();
    // ❓ Assert
    expect(1).toBe(1);
  });
});
