import { shallow } from 'enzyme';
import React from 'react';
import { vi } from 'vitest';

import { BtnGroupOGH } from './BtnGroup.OGH';

describe('🐛 spec BtnGroup.OGH', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)

    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(<BtnGroupOGH />);

    //❓ Assert
    expect(wrapper.text()).toBe(
      '<EditOghButton /><ExportingBusinessPlanButton /><DeleteOghButton /><CancelButtonHOC /><CloseOghButton /><SendMatchingOghButton /><CloseElectronicSignature /><SigningOnRenewalOfApprovalDate /><RejectExternalObject /><AcceptExternalObject /><ApproveElectronicSignature /><DataExportButton /><CreateApplicationExaminationButton /><ReturnBack />',
    );
  });

  it('🧪 props EditOghButton', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    const handleEditButtonClickSpy = vi.fn();

    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(
      <BtnGroupOGH handleEditButtonClick={handleEditButtonClickSpy} />,
    );

    //❓ Assert
    expect(wrapper.find('EditOghButton').props()).toStrictEqual({
      onClick: handleEditButtonClickSpy,
    });
  });
});
