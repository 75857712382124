import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard/index';
import { DXF_EXPORT_API_URL } from 'config/env';
import { MenuItem } from 'core/uiKit/components/Menu/MenuItem';
import React from 'react';

/**
 * MenuItem __Сформировать в .pdf__.
 *
 * @returns {JSX.Element}
 */
export const ToFormPDFMenuItem = () => {
  const { recordId } = useGetCurrentCard();
  return (
    <MenuItem disabled={false}>
      <a href={`${DXF_EXPORT_API_URL}/pdf/${recordId}`}>Сформировать в .pdf</a>
    </MenuItem>
  );
};
