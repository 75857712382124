import { ContainerTypeId } from 'core/uiKit/preparedInputs/selects/simpleSelect/ContainerTypeId';
import React from 'react';

 
/**
 * ContainerTypeIdRFWithReduxForm.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.input - Объект инпута приходит из redux form.
 * @param {object} props.otherProps - Остальные пропсы.
 * @returns {JSX.Element} - JSX.
 * @example ----
 */
export const ContainerTypeIdRFWithReduxForm = ({
  input,
  ...otherProps
}) => {
  return <ContainerTypeId {...input} {...otherProps} />;
};
