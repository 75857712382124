import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { ReagentKindIdFF } from 'core/form/finalForm/fields';
import React from 'react';

 
/**
 * Компонент фильтрации поля ReagentKindIdFFTank.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - Отфильтрованный компонент.
 * @example <ReagentKindIdFFTank />
 */
export const ReagentKindIdFFTank = (props) => {
  const { editMode } = useEditMode();

   
  /**
   * Функция фильтрации.
   *
   * @param {Array} dict - Массив опшинов.
   * @returns {Array} - Возвращает отфильтрованнй массив.
   * @example <ReagentKindIdFF
   *       filter={filter}
   *     />
   */
  const filter = (dict) => {
    return dict.filter((item) => item.code === 'liquid_reagent');
  };
  return (
    <ReagentKindIdFF
      withAll={false}
      filter={filter}
      label={'Вид ПГМ'}
      required={true}
      disabled={!editMode}
      {...props}
    />
  );
};
