import { createAttribute as createDocumentsAttribute } from 'app/components/card/ogh/DocumentsTab';
import { createAttribute as createAddressListFieldAttribute } from 'app/components/card/ogh/ImprovementTerritoryCard/components/AddressListField';
import { createAttribute as createAquaAreaAttribute } from 'app/components/card/ogh/ImprovementTerritoryCard/components/AquaAreaTab';
import { createAttribute as improvementCategoriesCreateAttribute } from 'app/components/card/ogh/ImprovementTerritoryCard/components/ImprovementCategories';
import { createAttribute as createOwnerAndCustomerFieldsAttribute } from 'app/components/card/ogh/ImprovementTerritoryCard/components/OwnerAndCustomerFields';
import { CardOgh } from 'types/Card';

import { REPAIRS_INFO_LIST_NAME } from '../components/tabs/Repairs.Tab';
import { REPAIRS_INFO_LIST_PLAN_NAME } from '../components/tabs/RepairsPlan.Tab';

const ids = {
  address: 're_calc_address_list',
  name: 're_calc_name',
};

/**
 * CreateAttribute.
 *
 * @param formValues - The form values.
 * @returns Возвращает атрибуты.
 */
export function createAttribute(formValues: CardOgh) {
  return {
    // @ts-ignore
    ...createAddressListFieldAttribute(formValues),
    // @ts-ignore
    ...improvementCategoriesCreateAttribute(formValues),
    // @ts-ignore
    ...createOwnerAndCustomerFieldsAttribute(formValues),
    // @ts-ignore
    ...createDocumentsAttribute(formValues),
    // @ts-ignore
    ...createAquaAreaAttribute(formValues),
    // @ts-ignore
    [ids.name]: null,
    [ids.address]: null,
    [REPAIRS_INFO_LIST_NAME]: formValues[REPAIRS_INFO_LIST_NAME],
    [REPAIRS_INFO_LIST_PLAN_NAME]: formValues[REPAIRS_INFO_LIST_PLAN_NAME],
  };
}
