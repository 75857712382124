import './TypeNavigationBar.scss';

import {
  ButtonMUI,
  ExpandLessIcon,
  ExpandMoreIcon,
} from 'core/uiKit/material-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Breadcrumbs.
 *
 * ```js
 * import { TypeNavigationBar } from 'core/uiKit/components';
 * ```
 */

/**
 * Компонент TypeNavigationBar.
 *
 * @param {object} props - Пропсы.
 * @param {*} props.breadcrumbs - Any.
 * @param {*} props.isShowFilter - Any.
 * @param {*} props.toggleShowFilter - Any.
 * @param {boolean} props.isHaveFilter - Флаг есть ли компонент фильтра.
 * @returns {JSX.Element}
 */
export const TypeNavigationBar = ({
  breadcrumbs = [{ name: '.', url: '#' }],
  isShowFilter,
  toggleShowFilter,
  isHaveFilter,
}) => {
  return (
    <div className="type-navigation-bar">
      <div className="type-navigation-bar-wrapper">
        <Link className="type-navigation-bar__breadcrumb" to="/">
          <i className="material-icons home-icon">&#xE88A;</i>
        </Link>
        {breadcrumbs.map(({ path, url, name }) => {
          const to = path || url;

          return (
            <Link
              className="type-navigation-bar__breadcrumb"
              key={name}
              to={to}
            >
              {name}
            </Link>
          );
        })}
      </div>
      {toggleShowFilter && isHaveFilter && (
        <div>
          <ButtonMUI
            endIcon={isShowFilter ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            onClick={toggleShowFilter}
          >
            {isShowFilter ? 'Скрыть' : 'Показать'} Фильтр
          </ButtonMUI>
        </div>
      )}
    </div>
  );
};
TypeNavigationBar.propTypes = {

  /**
   * Массив объектов с урлом и именем.
   */
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
};
