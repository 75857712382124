import './Tree.MainPage.scss';

import React from 'react';

import { MAX_ROWS } from '../hooks/useGetTreeMainPage';
import { TreeMainPageItem } from './Item.Tree.MainPage';
import { SkeletonTreeMainPage } from './Skeleton.Tree.MainPage';
import { useSetupTreeMainPage } from './useSetupTreeMainPage';

/**
 * Список Родительских элементов.
 *
 * @param {Function} getGeometryFull - Запрос на геометрию из списка родительских объектов.
 * @returns {Element}
 */
export const TreeMainPage = ({ getGeometryFull }) => {
  const { fetchTreeChildren, hash, isLoadingHash, ref, treeData } =
    useSetupTreeMainPage();

  return (
    <ul className="tree-main-page__list">
      {treeData.map((item, index) => {
        const refObserver =
          treeData.length - MAX_ROWS / 2 === index ? ref : null;

        const id = `${item?.root_id}${item?.object_name}${item?.object_id}${index}`;

        return (
          <TreeMainPageItem
            id={id}
            key={id}
            refObserver={refObserver}
            getGeometryFull={getGeometryFull}
            isLoading={isLoadingHash}
            item={item}
            fetchTreeChildren={fetchTreeChildren}
            hash={hash}
          />
        );
      })}
      <SkeletonTreeMainPage />
    </ul>
  );
};
