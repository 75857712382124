/* eslint-disable */
import React from 'react';

import 'assets/styles/common.misc.ajaxLoadingIndicator.scss';

 
export const FullScreenLoading = ({ className = '', isFetching = false }) => {
  if (!isFetching) {
    return null;
  }

  return (
    <div className={className}>
      <div className={'ajax-loading-indicator__background'} />
      <div className={'ajax-loading-indicator__content'} />
    </div>
  );
};
