export const department_type = [
  {
    id: 10213545,
    name: 'ГУ Дирекция по эксплуатац.движению и учету основных фондов ДО г.Москвы (ГКУ Дирекция ДОНМ)',
  },
  {
    id: 10216105,
    name: 'Департамент жилищно-коммунального хозяйства города Москвы (ДЖКХ)',
  },
  {
    id: 10226929,
    name: 'Департамент здравоохранения города Москвы (Департамент здравоохранения города Москвы)',
  },
  {
    id: 10218522,
    name: 'Департамент Культуры г.Москвы (Департамент культуры города Москвы)',
  },
  {
    id: 48051485,
    name: 'Департамент природопользования и охраны окружающей среды города Москвы (ДПиООС города Москвы)',
  },
  {
    id: 48051322,
    name: 'Департамент спорта города Москвы (Москомспорт)',
  },
  {
    id: 10224879,
    name: 'Департамент транспорта и развития дорожно-транспортной инфраструктуры г.Москвы (ДТиРДТИ города Москвы)',
  },
  {
    id: 10231049,
    name: 'ДЕПАРТАМЕНТ ТРУДА И СОЦИАЛЬНОЙ ЗАЩИТЫ НАСЕЛЕНИЯ ГОРОДА МОСКВЫ (Департамент труда и социальной защиты населения города Москвы)',
  },
];
