import * as ordersActions from 'app/actions/orders';
/* eslint-disable */
import getOrdersData from 'app/selectors/card/orders/getOrdersData';
import { Table } from 'core/uiKit/components/tables/Table';
import React from 'react';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';

const SelectHeaderComponent = (
  <div style={{ textAlign: 'center', whiteSpace: 'normal' }}>
    Показать геометрию
  </div>
);

/**
 * OrdersTable.
 *
 * @param {*} root0 - A.
 * @param {*} root0.columns - A.
 * @param {*} root0.data - A.
 * @param {*} root0.selectRow - A.
 * @param {*} root0.isSelected - A.
 * @param {*} root0.toggleDetails - A.
 * @param {*} root0.onLimit - A.
 * @param {*} root0.onPagination - A.
 * @param {*} root0.onSortedChange - A.
 * @returns {JSX.Element}
 */
const OrdersTable = ({
  columns = [],
  data,
  selectRow,
  isSelected,
  toggleDetails,
  onLimit,
  onPagination,
  onSortedChange,
}) => {
  return (
    <Table
      checkedRows={(objectId) => isSelected(objectId)}
      columns={columns?.map(({ id, name, sort, ...rest }) => ({
        Header: name,
        accessor: id,
        sortable: sort,
        toggleDetails: toggleDetails,
        ...rest,
      }))}
      data={
        data && data.table
          ? data.table.map((item) => ({
              _id: uuid(),
              ...item,
            }))
          : []
      }
      fetchData={onPagination}
      pageCount={data}
      selectHeader={SelectHeaderComponent}
      sortable_columns={[]}
      stickHeader={true}
      onLimit={onLimit}
      onSelectRow={(key, shift, row) => selectRow(row)}
      onSort={onSortedChange}
    />
  );
};

/**
 * MapStateToProps.
 *
 * @param {*} state - A.
 * @returns {*}
 */
const mapStateToProps = (state) => {
  return {
    columns: state.tableColumns?.orders,
    data: getOrdersData(state),
  };
};

const mapDispatchToProps = {
  onLimit: ordersActions.onLimit,
  onPagination: ordersActions.onPagination,

  /**
   *
   * @param newSorted
   */
  onSortedChange: (newSorted) => {
    const columnSort = newSorted[0];
    const sortDirection = columnSort.desc ? 'desc' : 'asc';

    const settings = {
      sort: `${columnSort.id}.${sortDirection}`,
    };

    return ordersActions.onSort(settings);
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersTable);
