/* eslint-disable */
import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import {
  MatchingStatuses,
  MATCHING_STATUSES_NAME,
} from 'core/uiKit/preparedInputs';
 
export { MATCHING_STATUSES_NAME };

 
 
/**
 * ## Состояние
 *
 * ### React Final Form Field
 * ```js
 * import { MatchingStatusesFF, MATCHING_STATUSES_NAME } from 'core/form/finalForm/fields';
 * ```
 *
 * ```
 * type = Select
 * name = state_id
 * dict = matchingStatus
 * ```
 */
/* eslint-disable */
export const MatchingStatusesFF = ({ name, ...otherProps }) => {
  return (
    <Field name={MATCHING_STATUSES_NAME}>
      {(props) => {
        return <MatchingStatuses {...props} {...props.input} {...otherProps} />;
      }}
    </Field>
  );
};

MatchingStatusesFF.propTypes = {
   
   
/**
   * выбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
/**
   * placeholder
   */
  placeholder: PropTypes.string,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
};

MatchingStatusesFF.defaultProps = {
  dark: false,
  disabled: false,
};
