import { useCallback, useMemo, useRef, useState } from 'react';

/**
 *
 * Хранит состояние с помощью useState и useRef. Значение рефа равно значению стейта;
 * state - state возвращаемый из useState;
 * getRefState - возвращает значение из stateRef.current;
 * setRefState - устанавливает стаейт из useState, а так же сразу в stateRef.current;
 * setState - устанавливает стейт из useState, значеиние рефа обновляется во время ререндера.
 *
 * @param initialState - Любое значение, которое принимает useState;.
 * @returns [ state, getRefState, setState, setRefState ] - кортеж со стейтом и фукнциями возвращающими и устанавливающими состояние;.
 */
export const useRefState = <State>(
  initialState: State | (() => State),
): [
  State,
  () => State,
  (newValue: State | ((prevState: State) => State)) => void,
  React.Dispatch<React.SetStateAction<State>>,
] => {
  const [state, setState] = useState(initialState);
  const stateRef = useRef(state);

  const getRefState = useCallback(() => stateRef.current, []);

  const setRefState = useCallback(
    (newValue: State | ((prevState: State) => State)) => {
      setState(newValue);
      stateRef.current =
        typeof newValue === 'function'
          ? (newValue as (prevState: State | undefined) => State)(
              stateRef.current,
            )
          : newValue;
    },
    [],
  );

  return useMemo(
    () => [state, getRefState, setRefState, setState],
    [state, getRefState, setRefState],
  );
};

export type UseRefState<State> = typeof useRefState<State>;
export type UseRefStateResult<State> = ReturnType<UseRefState<State>>;
