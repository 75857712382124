import './Map.scss';

import { setLastMapEvent } from 'app/actions/mapActions';
import { FOREGROUND } from 'app/constants/layers';
import { useGetAllowedGeometryTypesRF } from 'app/pages/cardsOgh/components/Map/MapEditPanel/hooks/useGetAllowedGeometryTypes';
import geometryService from 'app/services/geometryService';
import mapService from 'app/services/mapService';
import cn from 'classnames';
import Mapgl from 'core/uiKit/components/DTW';
import { isEmpty, isEqual } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { v4 } from 'uuid';

import { MapglContext } from '../contexts/MapglContextProvider';

/**
 *
 */
class Map extends React.Component {
  static contextType = MapglContext;

  /**
   * Constructor.
   *
   * @param {object} props - React props.
   * @returns {void}
   */
  constructor(props) {
    super(props);
    this.state = {
      id: v4(),
    };
  }

  /**
   * Component did mount.
   *
   * @returns {void}
   */
  componentDidMount() {
    if (document.readyState === 'complete') {
      // this.initMapEditor();
    } else {
      window.addEventListener('load', () => {
        // this.initMapEditor();
      });
    }
  }

  /**
   * Component should update.
   *
   * @param {object} nextProps - React props.
   * @param {object} nextState - React state.
   * @returns {boolean}
   */
  shouldComponentUpdate(nextProps, nextState) {
    const { editable, allowedGeometryTypes } = this.props;
    const { id } = this.state;
    return (
      editable !== nextProps.editable ||
      (!isEmpty(nextProps.allowedGeometryTypes) &&
        !isEqual(allowedGeometryTypes, nextProps.allowedGeometryTypes)) ||
      id !== nextState.id
    );
  }

  /**
   * Component did update.
   *
   * @param {object} prevProps - React props.
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    const { editable, allowedGeometryTypes } = this.props;

    // case when map loads earlier than editable changes
    if (prevProps.editable !== editable) {
      if (this.mapEditor) {
        this.mapEditor.setEditable(editable);
      }
    }

    if (editable) {
      if (
        !isEmpty(allowedGeometryTypes) &&
        !isEqual(prevProps.allowedGeometryTypes, allowedGeometryTypes)
      ) {
        this.clearInappropriateGeometry(allowedGeometryTypes);
      }
    }
  }

  /**
   * Get geometry.
   *
   * @param {string} oghObjectId - OGH object ID.
   * @returns {object}
   */
  getGeometry = (oghObjectId) => {
    const geometry = this.mapEditor && this.mapEditor.getGeometry(FOREGROUND);

    /**
     * Условие для фильтрации.
     *
     * @param {object} item - Огх объект.
     * @returns {boolean}
     */
    const filterCondition = (item) =>
      oghObjectId ? item.oghObjectId === oghObjectId : true;

    return {
      lines: geometryService.getLinesObject(geometry, filterCondition),
      points: geometryService.getPointsObject(geometry, filterCondition),
      polygons: geometryService.getPolygonsObject(geometry, filterCondition),
    };
  };

  /**
   * Clear inappropriate geometry.
   *
   * @param {Array} allowedGeometryTypes - Allowed geometry types.
   * @returns {void}
   */
  clearInappropriateGeometry = (allowedGeometryTypes) => {
    if (this.mapEditor) {
      this.mapEditor.clearInappropriateGeometry(allowedGeometryTypes);
    }
  };

  /**
   * Clear layer.
   *
   * @param {string} layerId - Layer ID.
   * @returns {void}
   */
  clearLayer = (layerId) => {
    if (this.mapEditor) {
      this.mapEditor.clearLayer(layerId);
    }
  };

  /**
   * Delete selected.
   *
   * @returns {void}
   */
  deleteSelected = () => this.mapEditor.deleteSelected();

  /**
   * Draw to layer.
   *
   * @param {string} layerId - Layer ID.
   * @param {Array} objects - Objects.
   * @param {boolean} autoZoom - Auto zoom.
   */
  drawToLayer = (layerId, objects, autoZoom) => {
    if (this.mapEditor) {
      const features = objects
        .map((object) => geometryService.toFeatures(object))
        .reduce((result, features) => {
          result.push(...features);
          return result;
        }, []);

      this.mapEditor.drawFeatures(layerId, features);
      autoZoom && this.zoomToFeatures([layerId]);
    }
  };

  /**
   * Zoom to features.
   *
   * @param {Array} layerIds - Layer IDs.
   * @returns {void}
   */
  zoomToFeatures = (layerIds) =>
    this.mapEditor && this.mapEditor.zoomToFeatures(layerIds);

  /**
   * Start drawing hole.
   *
   * @param {string} oghObjectId - OGH object ID.
   * @param {string} oghTypeId - OGH type ID.
   * @returns {void}
   */
  hole = (oghObjectId, oghTypeId) =>
    this.mapEditor.startDrawingHole(oghObjectId, oghTypeId);

  /**
   * Init map editor.
   *
   * @returns {void}
   */
  initMapEditor = async () => {
    const { setIsMapLoaded } = this.props;
    const { id } = this.state;

    this.mapEditor = await mapService.createMapEditor(id);

    // case when map loads later than editable changes
    // it's important to get "this.props.editable" value after map creation
    this.mapEditor.setEditable(this.props.editable);

    setIsMapLoaded();

    // handle geometry changes
    this.mapEditor.onFeaturesChange(this.mapGeometryChangeHandle.bind(this));
  };

  /**
   * Handle map geometry change.
   *
   * @param {object} event - Event.
   * @returns {void}
   */
  mapGeometryChangeHandle = (event) => {
    const { setMapEvent } = this.props;

    if (event.feature) {
      const geometry = event.feature.getGeometry();

      setMapEvent({
        data: {
          coordinates: JSON.stringify(geometry.getCoordinates()),
          type: geometry.getType(),
        },
        eventType: event.type,
      });
    }
  };

  /**
   * Start drawing.
   *
   * @param {string} oghObjectId - OGH object ID.
   * @param {string} oghTypeId - OGH type ID.
   * @returns {void}
   */
  line = (oghObjectId, oghTypeId) =>
    this.mapEditor.startDrawingLine(oghObjectId, oghTypeId);

  /**
   * Start drawing.
   *
   * @param {string} oghObjectId - OGH object ID.
   * @param {string} oghTypeId - OGH type ID.
   * @returns {void}
   */
  point = (oghObjectId, oghTypeId) =>
    this.mapEditor.startDrawingPoint(oghObjectId, oghTypeId);

  /**
   * Start drawing.
   *
   * @param {string} oghObjectId - OGH object ID.
   * @param {string} oghTypeId - OGH type ID.
   * @returns {void}
   */
  polygon = (oghObjectId, oghTypeId) =>
    this.mapEditor.startDrawingPolygon(oghObjectId, oghTypeId);

  /**
   * Stop drawing.
   *
   * @returns {void}
   */
  stopDrawing = () => this.mapEditor.stopDrawing();

  /**
   * Render.
   *
   * @returns {JSX.Element}
   */
  render() {
    const { className } = this.props;
    const { id } = this.state;
    return (
      <div
        className={cn(className, 'dtw__map')}
        onClick={() => {
          if (document.activeElement !== document.body) {
            document.activeElement.blur();
          }
        }}
      >
        <Mapgl id={id} />
      </div>
    );
  }
}

/**
 * Обертка для хуков.
 *
 * @param {object} props - Props.
 * @returns {JSX.Element}
 */
export default React.forwardRef((props, ref) => {
  const dispatch = useDispatch();

  /**
   * Set map event.
   *
   * @param {object} event - Event.
   * @returns {void}
   */
  const setMapEvent = (event) => {
    dispatch(setLastMapEvent(event));
  };
  const allowedGeometryTypes = useGetAllowedGeometryTypesRF();
  return (
    <Map
      {...props}
      allowedGeometryTypes={allowedGeometryTypes}
      setMapEvent={setMapEvent}
      ref={ref}
    />
  );
});
