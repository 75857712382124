import { uploadFile } from 'app/api/requests/uploadFiles';

/**
 * Метод получения файла __Техническое задание / проект благоустройства__.
 *
 * @param {object} params - Параметры запроса.
 * @returns {Promise<*&{file: *, resFile: {fileId: string}}>}
 */
export const sendRepairsInfoListPlan = async (params) => {
  const file = params.files[0];
  delete params.files;
  const resFile = await uploadFile(file);

  return {
    file,
    resFile,
  };
};
