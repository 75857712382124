import { useGetOghTypes } from 'core/utils/hooks/useGetOghTypes';
import { snakeCase } from 'lodash';

export interface UseGetBreadcrumbsParams {
  name: string;
  url: string;
}

/**
 * Делаем хлебные крошки.
 *
 * @param breadcrumbs - Массив данных.
 * @returns {Array<UseGetBreadcrumbsParams>}
 */
export const useGetBreadcrumbs = (
  breadcrumbs?: UseGetBreadcrumbsParams[],
): UseGetBreadcrumbsParams[] => {
  const oghTypes = useGetOghTypes();
  const path = window.location.pathname;
  oghTypes.ogh = {
    name: 'Реестр ОГХ',
  };

  if (!breadcrumbs) {
    breadcrumbs = [];
    const urlArr = path.split('/');

    urlArr.forEach((path, idx) => {
      const oghType = oghTypes[snakeCase(path)];

      if (oghType) {
        breadcrumbs?.push({
          name: oghType.name,
          url: urlArr.slice(0, idx + 1).join('/'),
        });
      }
    });
  }

  return breadcrumbs;
};
