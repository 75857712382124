// 35 Системы функционального обеспечения

import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { getParseAttribute } from 'app/components/card/common/getParseAttribute';
import { parseDate } from 'app/components/card/common/parse';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import { createAttribute as createDocumentsAttribute } from 'app/components/card/ogh/DocumentsTab';
import documentsReducer from 'app/components/card/ogh/DocumentsTab/mapStateToPropsReducer';
import getQuantityDigits from 'app/selectors/card/getQuantityDigits';
import hasQuantityLock from 'app/selectors/card/hasQuantityLock';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import getFormValues from 'app/selectors/form/getFormValues';
import { mandatoryFields } from 'app/utils/card/isMandatory';
import { transformValues } from 'app/utils/form/transformation';
import { COATING_FACE_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingFaceTypeId';
import React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';

import { FunctionalityCard } from './Functionality/FunctionalityCard';
import submitCard from './submit';

const dictFields = [
  'arrange_element_type_id',
  'unit_id',
  'material_id',
  COATING_FACE_TYPE_ID_NAME,
];

const numericFields = [
  {
    maxLength: 11,
    name: 'quantity',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  { name: 'face_area', positive: true, type: 'decimal', zero: true },
];

/**
 * Валидация.
 *
 * @param {object} values - Значения из формы.
 * @returns {object} - Возвращает ошибку.
 */
const validate = (values) => validateNumeric(values, numericFields);

/**
 * Функция создания атрибутов.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {{file_list: Array, attributes: {}}} - Возвращает атрибуты.
 * @example --------
 */
const createAttribute = (formValues) => ({
  ...createDocumentsAttribute(formValues),
});

/**
 * Функция сабмит.
 *
 * @param {object} formValues - Значения формы.
 * @returns {void} - Жопа.
 * @example -------------
 */
function onSubmit(formValues) {
  const { card } = this.props;
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };

  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[card.type_id],
  );

  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, {
      dictFields,
      numericFields,
    }),
  );
}

/**
 * Получение данных из стейта.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {*} - Жопа.
 * @example ---------
 */
export const mapStateToProps = (state, props) => {
  // в функции потому что мок не работает
  const improvementElementDocumentsReducer = documentsReducer({
    oghGroupCode: 'yard_subobject',
  });

  const parse = getParseAttribute(props);

  const result = {
    createMode: isCreateMode(state, props),
    editMode: isEditMode(state, props),
    enableReinitialize: true,
    formValues: getFormValues(state),

    initialValues: {
      arrange_element_type_id: parse(
        'arrange_element_type_id.arrange_element_type',
      ),
      coating_face_type_id: parse('coating_face_type_id.coating_type'),
      endDate: parseDate(props.card.end_date),
      face_area: parse('face_area'),
      is_diff_height_mark: parse('is_diff_height_mark'),
      material_id: parse('material_id.material'),
      no_calc: parse('no_calc') ? parse('no_calc') : 0,
      parent_name: props.card.parent_info,
      quantity: parse('quantity'),
      startDate: parseDate(props.card.start_date),
      totalAreaGeo: parse('total_area_geo'),
      unit_id: parse('unit_id.unit'),
    },

    onSubmit: onSubmit.bind({ props }),
    quantityDigits: getQuantityDigits(state),
    quantityLock: hasQuantityLock(state),
    required: isRequired(state, props),
    validate,
  };

  return improvementElementDocumentsReducer(result, state, props);
};

/**
 * Получаем экшены.
 *
 * @param {Function} dispatch - Функция передающая экшены в редьюсер.
 * @returns {{changeFieldValue: mapDispatchToProps.changeFieldValue}} Получаем экшен  changeFieldValue.
 * @example --
 */
const mapDispatchToProps = (dispatch) => ({

  /**
   * Меняет значение в форме.
   *
   * @param {string} field - Название поля.
   * @param {*} value - Значение.
   * @returns {void}
   */
  changeFieldValue: (field, value) => {
    dispatch(change('editorCard', field, value));
  },
});

/**
 * Обертка для получения значений из хуков.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - JSX.
 * @example ----------
 */
const FunctionalityCardContainer = (props) => {
  const { typeId } = useGetParentCard();
  return <FunctionalityCard parentTypeId={typeId} {...props} />;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'editorCard' })(FunctionalityCardContainer));
