import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import isMandatory from 'app/utils/card/isMandatory';
import { RANDOM_BOOL, RANDOM_NUMBER } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import { ENDWISE_NAME, EndwiseField } from './Endwise.Field';

vi.mock('app/api/hooks/useGetCurrentCard');
vi.mock('app/api/hooks/useIsEditCurrentObjectIsExternalSystem');
vi.mock('app/pages/cardsOgh/hooks/useMode');
vi.mock('app/utils/card/isMandatory');

describe('🐛 EndwiseField', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useMode.mockReturnValue({ editMode: true });
    useIsEditCurrentObjectIsExternalSystem.mockReturnValue(true);
    useGetCurrentCard.mockReturnValue({ typeId: RANDOM_NUMBER });
    // 🔥 Act
    const wrapper = shallow(<EndwiseField />);
    // ❓ Assert
    expect(wrapper.text()).toBe('<TextFieldRF />');
  });
  it('🧪 props', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useMode.mockReturnValue({ editMode: true });
    useIsEditCurrentObjectIsExternalSystem.mockReturnValue(true);
    useGetCurrentCard.mockReturnValue({ accessory: [], typeId: RANDOM_NUMBER });
    isMandatory.mockReturnValue(RANDOM_BOOL);
    // 🔥 Act
    const wrapper = shallow(<EndwiseField />);
    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      dark: false,
      digits: 2,
      disabled: false,
      label: 'По оси, м',
      name: ENDWISE_NAME,
      required: RANDOM_BOOL,
    });
  });
  it('🧪 isMandatory called params', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useMode.mockReturnValue({ editMode: false });
    useIsEditCurrentObjectIsExternalSystem.mockReturnValue(true);
    useGetCurrentCard.mockReturnValue({ accessory: [], typeId: RANDOM_NUMBER });
    isMandatory.mockReturnValue(RANDOM_BOOL);
    // 🔥 Act
    shallow(<EndwiseField />);
    // ❓ Assert
    expect(isMandatory).toHaveBeenCalledWith(
      RANDOM_NUMBER,
      ENDWISE_NAME,
      false,
    );
  });
});
