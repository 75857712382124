import { Map } from '@2gis/mapgl/types';

import { Polyline } from '../Polyline';
import {
  GenericMapglGeometry,
  GeometryConstructor,
  GeometryConstructorName,
  GeometryTypes,
  Layers,
  Mapgl,
  OptionsType,
  RawGeometry,
  RawGeometryConstructors,
} from '../types.d';
import { changingOptions, editingOptions } from './utils';
import {
  adjacentOptions,
  allChildrenOptions,
  childOptions,
  clickedOptions,
  colors,
  copyDiffOptions,
  defaultOptions,
  districtOptions,
  hoveredOptions,
  intersectionOptions,
  originalDiffOptions,
  parentOptions,
  reonAreaOptions,
  selectedOptions,
} from './utils';

const X_SHIFT = 11;
const Y_SHIFT = 32;
const EXTRA_X_SHIFT = 1.5;

/**
 * Создает геометрию и помещает ее на карту.
 */
export default class GeometryFactory {
  private static constructors: RawGeometryConstructors = {
    Marker: undefined,
    Polygon: undefined,
    Polyline: undefined,
  };

  static colors = colors;

  static defaultOptions = defaultOptions;

  static adjacentOptions = adjacentOptions;

  static allChildrenOptions = allChildrenOptions;

  static childOptions = childOptions;

  static clickedOptions = clickedOptions;

  static copyDiffOptions = copyDiffOptions;

  static districtOptions = districtOptions;

  static hoveredOptions = hoveredOptions;

  static intersectionOptions = intersectionOptions;

  static originalDiffOptions = originalDiffOptions;

  static parentOptions = parentOptions;

  static reonAreaOptions = reonAreaOptions;

  static selectedOptions = selectedOptions;

  static editingOptions = editingOptions;

  static changingOptions = changingOptions;

  /**
   * Get options by layer.
   *
   * @param layerMapType - Layer type.
   * @returns Options.
   */
  static getOptions(layerMapType: Layers) {
    let optionsName: OptionsType = 'defaultOptions';
    switch (layerMapType) {
      case 'intersections':
        optionsName = 'intersectionOptions';
        break;
      case 'districts':
        optionsName = 'districtOptions';
        break;
      case 'parent':
        optionsName = 'parentOptions';
        break;
      case 'selected':
        optionsName = 'selectedOptions';
        break;
      case 'children':
        optionsName = 'childOptions';
        break;
      case 'allChildren':
        optionsName = 'allChildrenOptions';
        break;
      case 'adjacent':
        optionsName = 'adjacentOptions';
        break;
      case 'reonArea':
        optionsName = 'reonAreaOptions';
        break;
      case 'copyDiff':
        optionsName = 'copyDiffOptions';
        break;
      case 'originalDiff':
        optionsName = 'originalDiffOptions';
        break;
      default:
        optionsName = 'defaultOptions';
        break;
    }
    return GeometryFactory[optionsName];
  }

  /**
   * Initialize constructors.
   *
   * @param mapgl - Mapgl library.
   */
  static initializeConstructors(mapgl: Mapgl) {
    GeometryFactory.constructors.Marker = mapgl.Marker;
    GeometryFactory.constructors.Polygon = mapgl.Polygon;
    GeometryFactory.constructors.Polyline = Polyline;
  }

  /**
   * Create factory.
   *
   * @param mapgl - Mapgl library.
   * @param map - Map.
   * @param type - Geometry type.
   * @returns Factory.
   */
  static createFactory(mapgl: Mapgl, map: Map, type: GeometryConstructorName) {
    return new GeometryFactory(mapgl, map, type);
  }

  /**
   * Create geometry.
   *
   * @param mapgl - Mapgl library.
   * @param map - Map.
   * @param type - Geometry type.
   */
  constructor(
    public mapgl: Mapgl,
    public map: Map,
    public type: 'Polyline' | 'Marker' | 'Polygon',
  ) {}

  /**
   * Create geometry.
   *
   * @param options - Geometry options.
   * @returns Geometry.
   */
  createGeometry<Type extends RawGeometry>(
    options: GenericMapglGeometry<Type>,
  ): Type {
    const map = this.map;
    const geometry = new (GeometryFactory.constructors[
      this.type
    ] as GeometryConstructor)(this.map, {
      ...GeometryFactory.defaultOptions[this.type],
      ...options,
      userData: {
        isChanging: false,
        isClicked: false,
        isEditing: false,
        isHovered: false,
        isSelected: false,
        ...GeometryFactory.defaultOptions[this.type].userData,
        ...options.userData,

        /**
         * Преобразование размера иконки в географические координаты.
         *
         */
        recalcHoverArea() {
          // @ts-ignore
          if (geometry.userData.type !== GeometryTypes.Point) {
            // @ts-ignore
            geometry.userData.coordinatesToCheckMouseEvent =
              // @ts-ignore
              geometry.userData.coordinates;
            return;
          }
          // Преобразование размера иконки в географические координаты
          const [pointXInPixels, pointYInPixels] = map.project(
            // @ts-ignore
            geometry.userData.coordinates as number[],
          );
          const southWest = map.unproject([
            pointXInPixels - X_SHIFT + EXTRA_X_SHIFT,
            pointYInPixels,
          ]);
          const northWest = map.unproject([
            pointXInPixels - X_SHIFT + EXTRA_X_SHIFT,
            pointYInPixels - Y_SHIFT + EXTRA_X_SHIFT,
          ]);
          const northEast = map.unproject([
            pointXInPixels + X_SHIFT + EXTRA_X_SHIFT,
            pointYInPixels - Y_SHIFT + EXTRA_X_SHIFT,
          ]);
          const southEast = map.unproject([
            pointXInPixels + X_SHIFT + EXTRA_X_SHIFT,
            pointYInPixels,
          ]);

          // @ts-ignore
          geometry.userData.coordinatesToCheckMouseEvent = [
            [southWest, northWest, northEast, southEast, southWest],
          ];
        },
      },
    }) as Type;

    return geometry;
  }
}
