import { post } from 'app/api/crud/post';
import { useMapContext } from 'app/components/map/useMapContext';
import { useMapEdit } from 'app/pages/cardsOgh/components/Map/useMapEdit';
import { createMessage } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/buttons/ReonButton/createMessage';
import { message } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/helpers/dialogMessage';
import toArray from 'app/utils/toArray';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

import { createObjectForMap } from './createObjectForMap';

/**
 * Метод postReonIntersection.
 *
 * @param {object} data - Данные.
 * @returns {object}
 */
const postReonIntersection = (data) =>
  post('/ogh/geometry/reon/intersection', data);

/**
 * Хук useGetReonIntersections.
 *
 * @param {object} props - Пропсы.
 * @returns {object}
 */
export const useGetReonIntersections = (props) => {
  const { showAlert } = props;
  const { drawReonGeometry } = useMapContext();
  const { setHasReonIntersections } = useMapEdit();
  const [getReonIntersections] = useMutationAdaptor(postReonIntersection, {

    /**
     * Успешный кейс.
     *
     * @param {object} data - Ответ.
     */
    onSuccess: (data) => {
      const intersections = toArray(data);
      if (intersections.length > 0) {
        showAlert(createMessage(intersections, message.reonIntersections));
        const items = intersections.map((item) => createObjectForMap(item));
        drawReonGeometry(items);
        setHasReonIntersections(true);
      } else {
        showAlert(message.success);
      }
    },
  });

  return { getReonIntersections };
};
