import { showIfHavePrivilege } from 'app/grants';
import React from 'react';

import DownloadDocumentation from './DownloadDocumentation';

const SetManualsMenu = showIfHavePrivilege('set_manuals_menu')(function () {
  return (
    <DownloadDocumentation fileName="set_manuals_menu" title="Руководства" />
  );
});

 
export default SetManualsMenu;
