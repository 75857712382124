import React, { useCallback } from 'react';
// @ts-ignore
import { Field, FieldProps } from 'redux-form';

interface FieldRFProps {
  component: React.ComponentType<FieldProps>; // тип компонента
  name: string; // имя поля
  [key: string]: unknown; // остальные пропсы
}

/**
 * Обертка для Field.
 *
 * @param props - Пропсы.
 * @param props.component - Компонент.
 * @param props.name - Имя.
 * @returns JSX.Element.
 */
export const FieldRF: React.FC<FieldRFProps> = ({
  component: Component,
  name,
  ...otherProps
}) => {
  const ComponentMemo = useCallback(
    ({ input, ...props }: FieldProps) => <Component {...input} {...props} />,
    [Component],
  );

  return <Field {...otherProps} name={name} component={ComponentMemo} />;
};
