import { NumberField } from 'core/uiKit/inputs';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Количество.
 */
export const QUANTITY_NAME = 'quantity';

/**
 * Компонент Quantity.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - Возвращает компонент NumberField.
 * @example <Quantity name={QUANTITY_NAME} {...props}/>
 */
export const Quantity = (props) => {
  return <NumberField label={'Количество'} {...props} />;
};

Quantity.propTypes = {

  /**
   * Выбор темной темы.
   */
  dark: PropTypes.bool,

  /**
   * Заблокировать на редактирование.
   */
  disabled: PropTypes.bool,

  /**
   * Вывод ошибки.
   */
  errorText: PropTypes.string,

  /**
   * Функция вызова когда выберется итем.
   */
  onChange: PropTypes.func,

  /**
   * Вывод информации об обязательном поле.
   */
  required: PropTypes.bool,

  /**
   * Значение.
   */
  value: PropTypes.any,
};
