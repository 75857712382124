/* eslint-disable */
import React from 'react';

import { filterInput } from '../filterInputsClasses';
import { OoptProfileFF, OOPT_PROFILE_NAME } from 'core/form/finalForm/fields';
import { NumberParam } from 'use-query-params';

 
export { OOPT_PROFILE_NAME };
 
 
/**
 *     field: 'ooptProfile',
 *     label: 'Профиль ООПТ',
 */
 
export const OoptProfileFilter = () => (
  <OoptProfileFF className={filterInput} />
);
 
export const ooptProfileParams = {
  [OOPT_PROFILE_NAME]: NumberParam,
};
