import { showAlert } from 'app/actions/dialog';
import {
  INVALID_AREAS_ALERT,
  INVALID_REAGENT_AREA_ALERT,
  INVALID_ROTOR_AREA_ALERT,
  INVALID_SNOW_AREA_ALERT,
} from 'app/constants/messages';
import { queryClient } from 'app/routes/QueryClientProvider';
import getInventoryPlanTypeId from 'app/selectors/registry/getInventoryPlanTypeId';
import { isOtherFlatBuilding } from 'app/utils/checkOghTypeId';
import { Button } from 'core/uiKit/components/buttons/Button';
import { Icon } from 'core/uiKit/components/Icon';
import { log } from 'core/utils/log';
import { connect } from 'react-redux';
import { getFormValues, submit } from 'redux-form';

// В LeftPanel.js пропсы беруться отсюда через 33 пропсы )
// card - state.card.object
// selected - state.tree.object

/**
 * Кнопка CreateOgh.
 *
 * @param {object} props - Пропсы.
 * @param {Function} props.submit - Функция submit Redux form.
 * @param {boolean} props.editMode - Флаг editMode.
 * @param {object} props.card - Данные паспорта.
 * @param {string} props.cardType - Тип карточки.
 * @param {object} props.formValues - Значения формы.
 * @returns {JSX.Element|null}
 */
const CreateOgh = ({ submit, editMode, card, cardType, formValues }) => {
  const { type_id } = card;

  /**
   * Функция клика по кнопке.
   *
   * @returns {void}
   */
  const handleSaveButtonClick = () => {
    if (cardType === 'flat_building') {
      const {
        area,
        snow_area,
        rotor_area,
        reagent_area,
        auto_clean_area,
        manual_clean_area,
      } = formValues;

      if (snow_area + rotor_area > area) {
        showAlert(INVALID_AREAS_ALERT);
        return;
      }

      if (
        isOtherFlatBuilding(type_id) &&
        reagent_area > auto_clean_area + manual_clean_area
      ) {
        showAlert(INVALID_REAGENT_AREA_ALERT.OTHER_TERRITORY);
        return;
      }

      if (reagent_area > area) {
        showAlert(INVALID_REAGENT_AREA_ALERT.COMMON);
        return;
      }
    }

    if (cardType === 'ground_main_element') {
      const { area, snow_area, rotor_area, reagent_area } = formValues;

      if (snow_area > area) {
        showAlert(INVALID_SNOW_AREA_ALERT);
        return;
      }

      if (rotor_area > area) {
        showAlert(INVALID_ROTOR_AREA_ALERT);
        return;
      }

      if (reagent_area > area) {
        showAlert(INVALID_REAGENT_AREA_ALERT.COMMON);
        return;
      }
    }

    submit('editorCard');
    queryClient.invalidateQueries('getVisibilityButtonsOgh');
  };

  return editMode ? (
    <Button
      color={'success'}
      variant={'contained'}
      startIcon={<Icon>published_with_changes</Icon>}
      onClick={handleSaveButtonClick}
    >
      Сохранить
    </Button>
  ) : null;
};

/**
 * Функция получения состояния Redux.
 *
 * @param {object} state - Стейт.
 * @returns {{formValues: *, inventoryPlanTypeId: unknown, currentCard: object, editMode: boolean, cardType: (string|*|string), card}}
 */
const mapStateToProps = (state) => {
  const currentCard = state.tree.object || state.card.object;
  const cardType =
    currentCard.card_type && currentCard.card_type.trim().toLowerCase();
  if (cardType === 'flat_building' || cardType === 'ground_main_element') {
    return {
      card: state.card.object,
      cardType,
      currentCard,
      editMode: state.card?.mode?.editMode,
      formValues: getFormValues('editorCard')(state),
    };
  }

  log.dev('cardType', cardType);

  const inventoryPlanTypeId =
    cardType === 'odh' ? 18 : getInventoryPlanTypeId(state);

  return {
    card: state.card.object,
    cardType,
    currentCard,
    editMode: state.card?.mode?.editMode,
    formValues: getFormValues('editorCard')(state),
    inventoryPlanTypeId,
  };
};

const mapDispatchToProps = {
  submit,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateOgh);
