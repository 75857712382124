/* eslint-disable  */
import {
  BASE_PGM,
  BOARD_STONE,
  CARRIAGEWAY,
  CONTAINER,
  EMERGENCY_PARKING,
  FENCING,
  IMPROVEMENT_OBJECT,
  LAMPPOSTS,
  MAF,
  MARGIN,
  ODH,
  OOPT,
  OTHER_ENGIN_CONSTRUCT,
  OTHER_FLAT_BUILDING,
  OTHER_TECH_PLACE,
  OZN,
  PPI,
  ROAD_SIGNS,
  SIDEWALKS,
  TRAFFIC_LIGHT,
  TRAM_RAILS,
  YARD,
} from 'app/constants/oghTypes';

export const improvementTerritoryTypes = [YARD, IMPROVEMENT_OBJECT, OZN];

/**
 *
 * @param typeId
 */
export const isImprovementTerritory = (typeId) => {
  return improvementTerritoryTypes.includes(typeId);
};

/**
 *
 * @param typeId
 */
export const isImprovementTerritoryOrOdh = (typeId) => {
  return isOdh(typeId) || isImprovementTerritory(typeId);
};

/**
 *
 * @param typeId
 */
export const isOdh = (typeId) => typeId === ODH;

/**
 *
 * @param typeId
 */
export const isLampposts = (typeId) => typeId === LAMPPOSTS;

/**
 *
 * @param typeId
 */
export const isMaf = (typeId) => typeId === MAF;

/**
 *
 * @param typeId
 */
export const isEmergencyParking = (typeId) => typeId === EMERGENCY_PARKING;

/**
 *
 * @param typeId
 */
export const isOtherEnginConstruct = (typeId) =>
  typeId === OTHER_ENGIN_CONSTRUCT;

/**
 *
 * @param typeId
 */
export const isRoadSigns = (typeId) => typeId === ROAD_SIGNS;

/**
 *
 * @param typeId
 */
export const isFencing = (typeId) => typeId === FENCING;

/**
 *
 * @param typeId
 */
export const isTrafficLight = (typeId) => typeId === TRAFFIC_LIGHT;

/**
 *
 * @param typeId
 */
export const isCarriageway = (typeId) => typeId === CARRIAGEWAY;

/**
 *
 * @param typeId
 */
export const isSidewalks = (typeId) => typeId === SIDEWALKS;

/**
 *
 * @param typeId
 */
export const isBoardStone = (typeId) => typeId === BOARD_STONE;

/**
 *
 * @param typeId
 */
export const isOtherFlatBuilding = (typeId) => typeId === OTHER_FLAT_BUILDING;

/**
 *
 * @param typeId
 */
export const isTramRails = (typeId) => typeId === TRAM_RAILS;

/**
 *
 * @param typeId
 */
export const isMargin = (typeId) => typeId === MARGIN;

/**
 *
 * @param typeId
 */
export const isPpi = (typeId) => typeId === PPI;

/**
 *
 * @param typeId
 */
export const isYard = (typeId) => typeId === YARD;

/**
 *
 * @param typeId
 */
export const isImprovementObject = (typeId) => typeId === IMPROVEMENT_OBJECT;

/**
 *
 * @param typeId
 */
export const isOzn = (typeId) => typeId === OZN;

/**
 *
 * @param typeId
 */
export const isOtherTechPlace = (typeId) => typeId === OTHER_TECH_PLACE;

/**
 *
 * @param typeId
 */
export const isBasePgm = (typeId) => typeId === BASE_PGM;

/**
 *
 * @param typeId
 */
export const isOopt = (typeId) => typeId === OOPT;

/**
 *
 * @param id
 */
export const isMainOgh = (id) =>
  [YARD, IMPROVEMENT_OBJECT, OZN, ODH, CONTAINER, BASE_PGM, OOPT].includes(id);
