/* eslint-disable  */

/**
 *
 */
export const getEnvironment = () => process.env.NODE_ENV;

/**
 *
 */
export const isDevelopment = () => getEnvironment() === 'development';

/**
 *
 */
export const isTesting = () => getEnvironment() === 'test';
