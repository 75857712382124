import * as oghTypes from 'app/constants/oghTypes';

import { checkField } from './checkField';

export const mandatoryFields = {
  [oghTypes.STOPS]: [
    'conv_element_type_id',
    'odh_side_id',
    'name',
    'area',
    'bord_begin',
    'bord_end',
    'station_clean_area',
  ],
  [oghTypes.LAMPPOSTS]: [
    'conv_element_type_id',
    'odh_side_id',
    'endwise',
    'material_id',
    'placement_id',
  ],
  [oghTypes.MAF]: [
    'conv_element_type_id',
    'odh_side_id',
    'area',
    'bord_begin',
    'bord_end',
  ],
  [oghTypes.MANHOLES]: [
    'engin_struct_type_id',
    'odh_side_id',
    'endwise',
    'accessory_id',
  ],
  [oghTypes.OTHER_ENGIN_CONSTRUCT]: [
    'engin_struct_type_id',
    'odh_side_id',
    'bord_begin',
    'bord_end',
  ],
  [oghTypes.CARRIAGEWAY]: [
    'flat_element_type_id',
    'odh_side_id',
    'coating_group_id',
    'coating_type_id',
    'distance',
    'area',
    'bord_begin',
    'bord_end',
    'cleaning_type',
  ],
  [oghTypes.SIDEWALKS]: [
    'flat_element_type_id',
    'odh_side_id',
    'distance',
    'coating_group_id',
    'coating_type_id',
    'area',
    'bord_begin',
    'bord_end',
    'cleaning_type',
  ],
  [oghTypes.BOARD_STONE]: [
    'flat_element_type_id',
    'odh_side_id',
    'distance',
    'bound_stone_mark_id',
    'is_gutter_zone',
    'bord_begin',
    'bord_end',
    'is_gutter_zone',
  ],
  [oghTypes.OTHER_FLAT_BUILDING]: [
    'flat_element_type_id',
    'odh_side_id',
    'coating_group_id',
    'coating_type_id',
    'area',
    'bord_begin',
    'bord_end',
    'cleaning_type',
  ],
  [oghTypes.MARGIN]: [
    'flat_element_type_id',
    'odh_side_id',
    'distance',
    'area',
    'bord_begin',
    'bord_end',
    'cleaning_type',
    'coating_group_id',
    'margin_streng_type_id',
  ],
  [oghTypes.ROAD_SIGNS]: [
    'equipment_type_id',
    'odh_side_id',
    'endwise',
    'area',
    'mounting_mode_id',
  ],
  [oghTypes.FENCING]: [
    'odh_side_id',
    'equipment_kind_id',
    'bord_begin',
    'bord_end',
  ],
  [oghTypes.TRAFFIC_LIGHT]: [
    'equipment_type_id',
    'odh_side_id',
    'endwise',
    'mounting_mode_id',
    'placement_id',
  ],
  [oghTypes.STORAGE_PLACE]: [
    'material_id',
    'area',
    'bike_park_type_id',
    'bike_ref_type_id',
  ],
  [oghTypes.OTHER_TECH_PLACE]: [
    'buildings_type_id',
    'buildingsTypeSpecId',
    'material_id',
    'area',
  ],
  [oghTypes.TRAM_RAILS]: [
    'net_element_type_id',
    'odh_side_id',
    // 'quantity',
    'distance',
    // 'susp_height',
    // 'coating_type_road_id',
    'coating_group_id',
    // 'coating_group_road_id',
    'coating_type_id',
    'area',
    'bord_begin',
    'bord_end',
  ],
  [oghTypes.TROLLEYBUS_CONTACT_NETWORK]: [
    'net_element_type_id',
    'odh_side_id',
    'quantity',
    'distance',
    'bord_begin',
    'bord_end',
  ],
  [oghTypes.EMERGENCY_PARKING]: [
    'area',
    'bord_begin',
    'bord_end',
    'coating_group_id',
    'coating_type_id',
    'name',
    'odh_side_id',
    'placement_id',
  ],
  [oghTypes.PPI]: [
    'arrange_element_type_id',
    'quantity',
    'unit_id',
    'material_id',
  ],
  [oghTypes.RELIEF]: [
    'arrange_element_type_id',
    'quantity',
    'unit_id',
    'material_id',
  ],
  [oghTypes.FUNCTIONALITY]: ['arrange_element_type_id', 'quantity', 'unit_id'],
  [oghTypes.OTHER_OKS]: [
    'buildings_type_id',
    'buildings_type_spec_id',
    'area',
    'property',
  ],
  [oghTypes.TREES_SHRUBS]: [
    'life_form_type_id',
    'plantation_type_id',
    'state_gardening_id',
    'detailed_state_gardening_id',
    'characteristic_state_gardening_id',
    'plant_type_id',
  ],
};

/**
 * Функция isMandatory.
 *
 * @param {number} cardTypeId - TypeId паспорта.
 * @param {string} fieldName - Имя поля (name: 'plant_type_id').
 * @param {boolean} required - Обязательное или нет.
 * @returns {boolean} - Результата true или false.
 * @example isMandatory(cardTypeId, fieldName, required)
 */
const isMandatory = (cardTypeId, fieldName, required) => {
  if (cardTypeId) {
    return checkField(mandatoryFields[cardTypeId], fieldName, required);
  }
  return required;
};

export default isMandatory;
