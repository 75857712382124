export const columns = [
  {
    Header: 'Номер участка',
    accessor: 'section_num',
    rowSpan: 4,
  },
  {
    Header: 'Номер цветника',
    accessor: 'green_num',
    rowSpan: 4,
  },
  {
    Header: 'Площадь цветника, кв.м.',
    accessor: 'flowers_garden_area',
    columns: [
      {
        Header: 'Всего',
        accessor: 'total_area',
        rowSpan: 3,
      },
      {
        Header: 'из них по составу:',
        accessor: 'flowers_garden_includes',
        columns: [
          {
            Header: 'Однолетники',
            accessor: 'annuals_area',
            rowSpan: 2,
          },
          {
            Header: 'Многолетники и розы',
            accessor: 'perennials_area',
            rowSpan: 2,
          },
          {
            Header: 'Инертный материал (отсыпка)',
            accessor: 'inert_area',
            rowSpan: 2,
          },
          {
            Header: 'Газоны (как элемент цветника)',
            accessor: 'lawn_area',
            rowSpan: 2,
          },
          {
            Header: 'Декоративные кустарники (как элемент цветника)',
            accessor: 'shrubs_area',
            rowSpan: 2,
          },
          {
            Header: 'Рокарий (многолетние, однолетние)',
            accessor: 'rockery_area',
            rowSpan: 2,
          },
        ],
      },
      {
        Header: 'С учетом сезонной сменности',
        accessor: 'season_shift_area',
        rowSpan: 3,
      },
      {
        Header: 'Декорирование щепой',
        accessor: 'flinders_area',
        rowSpan: 3,
      },
      {
        Header: 'сезонная сменность:',
        accessor: 'season_shift',
        columns: [
          {
            Header: 'Луковичные и клубнелуковичные',
            accessor: 'onions_and_corms',
            columns: [
              {
                Header: 'Кроме  тюльпанов, нарциссов',
                accessor: 'bulbous_area',
              },
              {
                Header: 'Тюльпаны, нарциссы',
                accessor: 'tulip_area',
              },
            ],
          },
          {
            Header: 'Двулетники (виола)',
            accessor: 'biennials_area',
            rowSpan: 2,
          },
        ],
      },
    ],
  },
];
