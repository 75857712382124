import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const REAGENT_NAME = 'reagent';

/**
 * ## Наименование ПГР.
 *
 * @param {*} props - Properties.
 * @returns {JSX.Element}
 */
export const Reagent = (props) => {
  const dict = 'reagent';

  const { data = [] } = useGetDictionary(dict);

  return (
    <Select
      {...props}
      label={'Наименование ПГР'}
      name={REAGENT_NAME}
      options={data}
    />
  );
};
