import cn from 'classnames';
import TextField from 'core/newComponents/TextField';
import KeyboardDatePicker from 'core/uiKit/inputs/datePickers/KeyboardDatePicker';
import PropTypes from 'prop-types';
/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { Control, Fieldset } from 'react-redux-form';

class Properties extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isReadOnly: PropTypes.bool,
    isCreateDateHidden: PropTypes.bool,
    form: PropTypes.object,
    formProps: PropTypes.object,
  };

  getErrorText = (model) => {
    const { formProps } = this.props;
    if (formProps[model].touched) {
      if (model === 'name') {
        if (!formProps[model].validity.required) {
          return 'Необходимо заполнить имя роли';
        }
      }
      if (model === 'comment') {
        if (!formProps[model].validity.required) {
          return 'Необходимо заполнить описание роли';
        }
      }
    }
    return '';
  };

  render() {
    const { form, isReadOnly, isCreateDateHidden } = this.props;

    if (!form) {
      return null;
    }

    return (
      <Fieldset className={cn(this.props.className)} model=".properties">
        <h2 className="mb-3">Свойства</h2>
        <div className={'d-flex'}>
          <div
            className="align-bottom ms-3 mb-3 d-inline-block"
            style={{ width: 256 }}
          >
            <Control
              component={TextField}
              controlProps={{
                helperText: this.getErrorText('name'),
                label: 'Наименование роли',
              }}
              disabled={isReadOnly}
              model=".name"
              type="text"
            />
          </div>
          {!isCreateDateHidden && (
            <div
              className="align-bottom ms-3 mb-3 d-inline-block"
              style={{ width: 256 }}
            >
              <KeyboardDatePicker
                disabled={true}
                label="Дата добавления"
                value={form.createDate}
              />
            </div>
          )}
          <div
            className="align-bottom ms-3 mb-3 d-inline-block"
            style={{ width: 256 }}
          >
            <Control
              component={TextField}
              controlProps={{
                helperText: this.getErrorText('comment'),
                label: 'Комментарий',
              }}
              disabled={isReadOnly}
              model=".comment"
              type="text"
            />
          </div>
        </div>
      </Fieldset>
    );
  }
}

function mapStateToProps(state) {
  return {
    form: state.rrf.admin.role.properties,
    formProps: state.rrf.forms.admin.role.properties,
  };
}

 
export default connect(mapStateToProps)(Properties);
