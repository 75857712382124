import React from 'react';

import { FormResetPassword } from './Form.ResetPassword';
import { MessageToken } from './MessageToken';
import { useFetchResetPassword } from './useFetchReset.Password';

/**
 * Хок Форма Сброса пароля из почты.
 *
 * @returns {JSX.Element}
 */
const FormResetPasswordHOC = () => {
  const { successToken, message, isLoading } = useFetchResetPassword();

  return successToken ? (
    <FormResetPassword />
  ) : (
    <MessageToken message={message} isLoading={isLoading} />
  );
};

export default FormResetPasswordHOC;
