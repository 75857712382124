/* eslint-disable */
import React from 'react';
import { NumberParam } from 'use-query-params';

import {
  CarFuncTypeGroupFF,
  CAR_FUNC_TYPE_GROUP_NAME,
} from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
 
/**
 * Группа техники car_func_type_group_id int
 */
 
export const CarFuncTypeGroupFilter = (props) => (
  <CarFuncTypeGroupFF {...props} className={filterInput} />
);

 
export { CAR_FUNC_TYPE_GROUP_NAME };

 
export const carFuncTypeGroupParams = {
  [CAR_FUNC_TYPE_GROUP_NAME]: NumberParam,
};
