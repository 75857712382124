const defaultOptions = {
  emptyText: 'не определено',
};

/* eslint-disable */
export default function formatRootId(value, options = defaultOptions) {
  let result;
  if (value) {
    const str = value.toString();
    if (isLongRootId(str)) {
      result = str.slice(0, -5);
    } else {
      result = str;
    }
  } else {
    result = options.emptyText;
  }
  return result;
}

function isLongRootId(value) {
  return new RegExp(/^[0-9]+[0]{3}[0-9]{2}$/).test(value);
}
