/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */ /**
     * Колонка __Высота, м.__.
     *
     * @type {{
     * accessor: 'height',
     * Header: 'Высота, м.',
     * sortable: true,
     * }}
     * @augments Cell
     */
export const height = {
  Header: 'Высота, м',
  accessor: 'height',
  sortable: true,
};
