import { get } from 'app/api/crud/get';
/* eslint-disable  */
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';

import { prepareData } from './prepareData';

/**
 *
 */
export const getOghProjectsCount = async () => {
  const data = await get('/ogh/projects/count');
  return await prepareData(data);
};

/**
 *
 */
export const useGetOghProjectsCount = () => {
  return useQueryAdaptor(['getOghProjectsCount'], getOghProjectsCount);
};
