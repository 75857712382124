/* eslint-disable */
import React from 'react';
import { StringParam } from 'use-query-params';

import { TextFieldFF } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
export const CAR_CUSTOMER_LIST_NAME = 'carCustomerList';

 
 
/**
 * Владелец техники –> car_customer_list [поле строка, может быть введено несколько значений. Если пусто - все]
 */
 
export const CarCustomerListFilter = () => {
  return (
    <TextFieldFF
      className={filterInput}
      label={'Владелец техники'}
      name={CAR_CUSTOMER_LIST_NAME}
    />
  );
};

 
export const carCustomerListParams = {
  [CAR_CUSTOMER_LIST_NAME]: StringParam,
};
