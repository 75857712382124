 
export const AUTO_CLEANING_TYPE = 0;
 
export const MANUAL_CLEANING_TYPE = 1;
 
export const NO_CLEANING_TYPE = 2;

 
export const cleaningTypes = [
  {
    id: AUTO_CLEANING_TYPE,
    name: 'Механизированная уборка',
  },
  {
    id: MANUAL_CLEANING_TYPE,
    name: 'Ручная уборка',
  },
];

 
export const cleaningTypesWithNoCleaning = [
  ...cleaningTypes,
  {
    id: NO_CLEANING_TYPE,
    name: 'Без уборки',
  },
];
