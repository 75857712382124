import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const DEPARTMENT_ID_NAME = 'department_id';

/**
 * ## Отраслевой ОИВ.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const DepartmentId = (props) => {
  const dict = 'departmentType';

  const { data: options = [] } = useGetDictionary(dict);

  return (
    <Select
      {...props}
      options={options}
      label={'Отраслевой ОИВ'}
      name={DEPARTMENT_ID_NAME}
    />
  );
};
