import NoMatch from 'app/components/NoMatch';
import { RegistrySkeleton } from 'core/uiKit/components';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { pathExaminationOGHApplication } from './examinationOGH.Application/path.ExaminationOGH.Application';
import { pathCertificatePassportOghApplication } from './сertificatePassportOGH.Application/path.CertificatePassportOgh.Application';

/**
 * G.
 *
 * @param {*} factory - F.
 * @returns {*} - Comp.
 */
function lazyWithPreload(factory) {
  const Component = lazy(factory);
  Component.preload = factory;
  return Component;
}
export const ExaminationOGHApplication = lazyWithPreload(() =>
  import('./examinationOGH.Application/ExaminationOGH.Application'),
);

export const CreateExaminationOGHApplication = lazyWithPreload(() =>
  import('./examinationOGH.Application/Create.ExaminationOGH.Application'),
);

export const CertificatePassportOghApplication = lazyWithPreload(() =>
  import(
    './сertificatePassportOGH.Application/CertificatePassportOGH.Application'
  ),
);

/**
 * Роутинг для заявок.
 *
 * @returns {*} - Comp.
 */
export const CertificationOfOghRoute = () => {
  useEffect(() => {
    ExaminationOGHApplication.preload();
  }, []);

  return (
    <Suspense fallback={<RegistrySkeleton />}>
      <Routes>
        <Route
          element={<CreateExaminationOGHApplication />}
          path={`${pathExaminationOGHApplication.path}/create`}
        />
        <Route
          element={<CreateExaminationOGHApplication />}
          path={`${pathExaminationOGHApplication.path}/create/:objectId`}
        />
        <Route
          element={<ExaminationOGHApplication />}
          path={`${pathExaminationOGHApplication.path}/:applicationId`}
        />
        <Route
          element={<CertificatePassportOghApplication />}
          path={`${pathCertificatePassportOghApplication.path}/:scheduleId`}
        />
        <Route element={<NoMatch />} path={'*'} />
      </Routes>
    </Suspense>
  );
};
