import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import React from 'react';

import { useSetupMafTypeLevel2IdField } from './useSetup.MafTypeLevel2Id.Field';

export const MAF_TYPE_LEVEL_2_ID_NAME = 'maf_type_level2_id';

/**
 * Select RF  уточнение.
 *
 * @returns {React.ReactElement}
 */
export const MafTypeLevel2IdField = () => {
  const { disabled, filter, options } = useSetupMafTypeLevel2IdField();

  return (
    <SelectRF
      name={MAF_TYPE_LEVEL_2_ID_NAME}
      label={'Уточнение'}
      filter={filter}
      options={options}
      disabled={disabled}
    />
  );
};
