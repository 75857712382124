/* eslint-disable */
import React from 'react';

import PaginationData from './PaginationData';
import { PagingSelect } from './PagingSelect';
import { Nav } from './Nav';
import { useParamsPagination } from './useParamsPagination';
import { Total } from './Total';

import './Pagination.scss';

 
export const Pagination = ({ total }) => {
  const { queryPagination, setQuery } = useParamsPagination();

  const changeQuery = (params) => setQuery(params);

  const pagination = new PaginationData({ total, ...queryPagination });
  return (
    <div className={'navigation-table'}>
      <div className={'nav-header'}>
        <div className="nav-label">
          <Total {...pagination} />
        </div>
        {pagination.total !== 0 && (
          <div className="nav-rows">
            <PagingSelect
              handleOnChange={(e) => {
                changeQuery({
                  maxRows: e.target.value,
                  page: 0,
                });
              }}
              limit={pagination.limit}
            />
          </div>
        )}
      </div>
      <Nav pagination={pagination} />
    </div>
  );
};
