import { useGetVisibilityButtonsOgh } from 'app/api/hooks/useGetVisibilityButtonsOgh';
import { Button } from 'core/uiKit/components/buttons/Button';
import { Icon } from 'core/uiKit/material-ui';
import PropTypes from 'prop-types';

/**
 * Кнопка "Отклонить" Внешний проект.
 *
 * (отклонение проекта, созданного внешней системой, после упразднения шаблонов согл-я ОГХ дублирования не будет, т.к. Reject_object будет удалена).
 *
 * @param {object} props - Пропсы.
 * @param {number} props.objectId - Id объекта.
 * @returns {JSX.Element}
 */
export const RejectExternalObjectOghButton = ({ objectId, ...props }) => {
  const { data: { action_external_object: actionExternalObject } = {} } =
    useGetVisibilityButtonsOgh(objectId);

  return actionExternalObject ? (
    <Button
      {...props}
      color={'warning'}
      variant={'contained'}
      startIcon={<Icon>not_interested</Icon>}
    >
      Отклонить
    </Button>
  ) : null;
};

RejectExternalObjectOghButton.propTypes = {
  //

  /**
   * Id версии ОГХ.
   */
  objectId: PropTypes.number.isRequired,
};
