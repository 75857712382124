import { GeometryTypes, Polygon, RawGeometry } from '../types.d';

/**
 * Является ли геометрия полигоном.
 *
 * @param geometry - Геометрия.
 * @returns {boolean} Является ли геометрия полигоном.
 */
export const isGeometryPolygon = (geometry: RawGeometry): geometry is Polygon =>
  geometry?.userData?.type === GeometryTypes.Polygon;
