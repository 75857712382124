/* eslint-disable  */
import getFormValues from 'app/selectors/form/getFormValues';
import getDict from 'app/selectors/getDict';
import { createSelector } from 'reselect';

/**
 *
 * @param state
 */
const getPlantationTypeId = (state) => getFormValues(state).plantation_type_id;

export default createSelector(
  [getPlantationTypeId, getDict('plantationTypes'), getDict('lifeFormTypes')],
  (plantationTypeId, plantationTypes, lifeFormTypes) => {
    let result;
    if (plantationTypeId) {
      const currentPlantationType = plantationTypes.find(
        (item) => item.id === plantationTypeId,
      );
      const lifeFormTypeIds =
        currentPlantationType && currentPlantationType.life_form_type_ids;
      if (lifeFormTypeIds && lifeFormTypeIds.length > 0) {
        result = lifeFormTypes.filter((item) =>
          currentPlantationType.life_form_type_ids.includes(item.id),
        );
      } else {
        result = lifeFormTypes;
      }
    } else {
      result = lifeFormTypes;
    }
    return result;
  },
);
