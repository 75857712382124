import destroyMiddleware from 'app/middlewares/destroyMiddleware';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
// import { routerMiddleware, routerReducer } from 'react-router-redux';
import thunkMiddleware from 'redux-thunk';

import * as reducers from './reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/* eslint-disable */
export default function configureStore(initialState) {
  const enhancer = composeEnhancers(
    applyMiddleware(thunkMiddleware, promiseMiddleware, destroyMiddleware),
  );

  const store = createStore(
    combineReducers({
      ...reducers,
    }),
    initialState,
    enhancer,
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers/index');
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
