/* eslint-disable  */
import { Icon, Tooltip } from 'core/uiKit/material-ui';
import React from 'react';

/**
 *
 * @param root0
 * @param root0.label
 * @param root0.helpingElement
 */
export const HelpTooltip = ({ label, helpingElement }) => {
  return helpingElement ? (
    <>
      {label}
      <Tooltip title={helpingElement}>
        <Icon className={'ml-1'} style={{ fontSize: 14 }}>
          help_outline
        </Icon>
      </Tooltip>
    </>
  ) : (
    label
  );
};
