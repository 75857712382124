import { shallow } from 'enzyme';
import React from 'react';
import { vi } from 'vitest';

import { UnitIdField } from './UnitId.Field';
import { useSetupUnitIdField } from './useSetup.UnitId.Field';

vi.mock('./useSetup.UnitId.Field');

describe('🐛 spec UnitId.Field', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    useSetupUnitIdField.mockReturnValue({ disabled: false, units: [] });
    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(<UnitIdField />);

    //❓ Assert
    expect(wrapper.text()).toBe('<SelectRF />');
  });

  it('🧪 props', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    useSetupUnitIdField.mockReturnValue({ disabled: false, units: [] });
    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(<UnitIdField />);

    //❓ Assert
    expect(wrapper.props()).toStrictEqual({
      disabled: false,
      label: 'Единица измерения',
      name: 'unit_id',
      options: [],
      required: true,
      withAll: false,
    });
  });
});
