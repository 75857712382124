import getFormValues from 'app/selectors/form/getFormValues';
import { createSelector } from 'reselect';

 
export default createSelector([getFormValues], (currentValues) => {
  const addressList = currentValues.address_list;

  let result;
  if (addressList && addressList.length > 0) {
    result = addressList[0].id || addressList[0].address_id || null;
  } else {
    result = null;
  }
  return result;
});
