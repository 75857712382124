import { getValidator } from 'core/form/finalForm/utils/validate';
import { RANDOM_ID, RANDOM_WORD } from 'core/forTesting/constants';

import { requiredFields, validate } from './validate';

jest.mock('core/form/finalForm/utils/validate');
/* eslint-disable */
const mocked = () => {
  const hasErrorsSpy = jest.fn();
  const setRequiredSpy = jest.fn();
  const getErrorsSpy = jest.fn();
  const setErrorSpy = jest.fn();

  getValidator.mockReturnValue({
    hasErrors: hasErrorsSpy,
    setRequired: setRequiredSpy,
    getErrors: getErrorsSpy,
    setError: setErrorSpy,
  });
  return {
    hasErrorsSpy,
    setRequiredSpy,
    getErrorsSpy,
    setErrorSpy,
  };
};

 
describe('file validate', () => {
  it('getValidator', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    mocked();
    // Act
    validate({ RANDOM_ID })({ RANDOM_WORD });

    // Assert
    expect(getValidator).toHaveBeenCalledWith({
      RANDOM_ID,
      RANDOM_WORD,
    });
  });

  it('обязательные поля', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const { setRequiredSpy } = mocked();

    requiredFields.push(RANDOM_WORD);
    requiredFields.push(RANDOM_ID);

    // Act
    validate({})();

    // Assert
    expect(setRequiredSpy).toHaveBeenNthCalledWith(1, RANDOM_WORD);
    expect(setRequiredSpy).toHaveBeenNthCalledWith(2, RANDOM_ID);
  });

  /* eslint-disable */
  describe('параметр «Местоположение створа по основной оси (в точке максимальной загрузки), м» должен быть не больше параметра «Протяженность объекта по всем осям, п.м»', () => {
    it.each`
      layoutLength          | odh_placement_axis    | expected
      ${'' + RANDOM_ID}     | ${'' + RANDOM_ID + 1} | ${1}
      ${RANDOM_ID}          | ${1 + RANDOM_ID}      | ${1}
      ${RANDOM_ID + 1}      | ${RANDOM_ID}          | ${0}
      ${'' + RANDOM_ID + 1} | ${'' + RANDOM_ID}     | ${0}
      ${RANDOM_ID}          | ${RANDOM_ID}          | ${0}
      ${'' + RANDOM_ID}     | ${'' + RANDOM_ID}     | ${0}
    `(
      '$layoutLength > $odh_placement_axis = $expected',
      ({ layoutLength, odh_placement_axis, expected }) => {
        expect.hasAssertions();
        // Arrange (всякие моки)
        const hasErrorsSpy = jest.fn();
        const setRequiredSpy = jest.fn();
        const getErrorsSpy = jest.fn();
        const setErrorSpy = jest.fn();

        getValidator.mockReturnValue({
          hasErrors: hasErrorsSpy,
          setRequired: setRequiredSpy,
          getErrors: getErrorsSpy,
          setError: setErrorSpy,
        });

        // Act
        validate({ layoutLength, odh_placement_axis })();

        // Assert
        expect(setErrorSpy).toHaveBeenCalledTimes(expected);
      },
    );

    it('error', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      const hasErrorsSpy = jest.fn();
      const setRequiredSpy = jest.fn();
      const getErrorsSpy = jest.fn();
      const setErrorSpy = jest.fn();

      getValidator.mockReturnValue({
        hasErrors: hasErrorsSpy,
        setRequired: setRequiredSpy,
        getErrors: getErrorsSpy,
        setError: setErrorSpy,
      });

      // Act
      validate({
        layoutLength: 1,
        odh_placement_axis: 3,
      })();

      // Assert
      expect(setErrorSpy).toHaveBeenCalledTimes(1);
    });
  });

  /* eslint-disable */
  describe('сумма процентов фактического состава движения должна не равна 100,00% или 0%', () => {
    it('не равна 100,00% или 0%', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      const textErr =
        'Сумма процентов показателей фактического состава движения должна быть равна 0,00% либо 100,00%.';
      const actualCompositionOfMovementFields = [
        'fsd_la',
        'fsd_ga_befor_2',
        'fsd_ga_2_6',
        'fsd_ga_6_8',
        'fsd_ga_8_14',
        'fsd_ga_after_14',
        'fsd_road_trainsga_before_12',
        'fsd_road_trainsga_12_20',
        'fsd_road_trainsga_20_30',
        'fsd_road_trainsga_after_30',
        'fsd_ot_low_capacity',
        'fsd_ot_medium_capacity',
        'fsd_ot_large_capacity',
        'fsd_ot_articulated_buses_trolleybuses',
      ];
      const values = actualCompositionOfMovementFields.reduce(
        (acc, curr, _, arr) => {
          acc[curr] = RANDOM_ID;
          return acc;
        },
        {},
      );

      const { setErrorSpy } = mocked();

      // Act
      validate({})(values);

      // Assert
      actualCompositionOfMovementFields.forEach((item, idx) => {
        expect(setErrorSpy).toHaveBeenNthCalledWith(idx + 1, item, textErr);
      });
    });
    it('равна  0%', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      const textErr =
        'сумма процентов фактического состава движения должна  быть равна 100,00%, либо 0,00 %';
      const actualCompositionOfMovementFields = [
        'fsd_la',
        'fsd_ga_befor_2',
        'fsd_ga_2_6',
        'fsd_ga_6_8',
        'fsd_ga_8_14',
        'fsd_ga_after_14',
        'fsd_road_trainsga_before_12',
        'fsd_road_trainsga_12_20',
        'fsd_road_trainsga_20_30',
        'fsd_road_trainsga_after_30',
        'fsd_ot_low_capacity',
        'fsd_ot_medium_capacity',
        'fsd_ot_large_capacity',
        'fsd_ot_articulated_buses_trolleybuses',
      ];
      const values = actualCompositionOfMovementFields.reduce((acc, curr) => {
        acc[curr] = 0;
        return acc;
      }, {});

      const { setErrorSpy } = mocked();

      // Act
      validate({})(values);

      // Assert
      actualCompositionOfMovementFields.forEach((item, idx) => {
        expect(setErrorSpy).not.toHaveBeenNthCalledWith(idx + 1, item, textErr);
      });
    });
    it('100%', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      const textErr = ' равна 100,00%';
      const actualCompositionOfMovementFields = [
        'fsd_la',
        'fsd_ga_befor_2',
        'fsd_ga_2_6',
        'fsd_ga_6_8',
        'fsd_ga_8_14',
        'fsd_ga_after_14',
        'fsd_road_trainsga_before_12',
        'fsd_road_trainsga_12_20',
        'fsd_road_trainsga_20_30',
        'fsd_road_trainsga_after_30',
        'fsd_ot_low_capacity',
        'fsd_ot_medium_capacity',
        'fsd_ot_large_capacity',
        'fsd_ot_articulated_buses_trolleybuses',
      ];
      const values = actualCompositionOfMovementFields.reduce(
        (acc, curr, _, arr) => {
          acc[curr] = ((Math.random() * 100) / arr.length).toFixed(2);
          return acc;
        },
        {},
      );
      const sum = Object.values(values).reduce((acc, curr) => acc + +curr, 0);
      const r = 100 - sum;

      values[actualCompositionOfMovementFields[0]] =
        +values[actualCompositionOfMovementFields[0]] + +r;

      const { setErrorSpy } = mocked();

      // Act
      validate({})(values);

      // Assert
      actualCompositionOfMovementFields.forEach((item, idx) => {
        expect(setErrorSpy).not.toHaveBeenNthCalledWith(idx + 1, item, textErr);
      });
    });
  });

  it.each`
    hasErrors | getErrors      | expected
    ${true}   | ${RANDOM_WORD} | ${RANDOM_WORD}
    ${false}  | ${RANDOM_WORD} | ${null}
  `(
    'hasErrors $hasErrors return $expected',
    ({ hasErrors, getErrors, expected }) => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      const { hasErrorsSpy, getErrorsSpy } = mocked();
      hasErrorsSpy.mockReturnValue(hasErrors);
      getErrorsSpy.mockReturnValue(getErrors);
      // Act
      const result = validate()();

      // Assert
      expect(result).toBe(expected);
    },
  );
});
