import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
/* eslint-disable */
import { uploadFile } from 'app/api/requests/uploadFiles';
import DialogAddFile from 'app/components/dialogs/common/DialogAddFile';

import DocumentsTable from './components/DocumentsTable';

const mapEnabledEditingDocument = ['plan_flower', 'sketch_flower'];

/**
 *
 * @param props
 */
const DocumentsTab = (props) => {
  const isCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();

  /**
   *
   * @param code
   */
  const checkEnabledEditable = (code) => {
    if (isCurrentObjectIsExternalSystem) {
      return props.editable && mapEnabledEditingDocument.includes(code);
    }
    return props.editable;
  };

  /**
   *
   * @param root0
   * @param root0.id
   * @param root0.name
   */
  const getRenderAddFileDialog = ({ id, name }) => {
    const title = `Добавление файла (${name})`;
    const additional = {
      type_id: id,
    };
    return (props) => (
      <DialogAddFile
        {...props}
        additional={additional}
        title={title}
        uploadFile={uploadFile}
      />
    );
  };

  /**
   *
   * @param item
   */
  const renderDocument = (item) => {
    return (
      <DocumentsTable
        key={item.code}
        {...item}
        editable={checkEnabledEditable(item.code)}
        renderAddFileDialog={getRenderAddFileDialog(item)}
        typeId={props.typeId}
      />
    );
  };

  const { documents } = props;
  return (
    <div className="d-none btn-group-other" id="docs">
      {documents && documents.map((item) => renderDocument(item))}
    </div>
  );
};

/* eslint-disable */
export default function (options) {
  return (props) => <DocumentsTab {...props} options={options} />;
}
