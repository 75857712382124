 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Количество, шт.__.
 *
 * @type {{ accessor: 'quantity_pcs', Header: 'Количество, шт.' }}
 * @augments Cell
 */
export const quantity_pcs = {
  accessor: 'quantity_pcs',
  Header: 'Количество, шт.',
  sortable: true,
};
