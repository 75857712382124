import { editCardDidNotLock } from 'app/actions/odsObjectActions/editCardDidNotLock';
import {
  receiveErrorMatchCard,
  showSendMatchCard,
} from 'app/actions/odsObjectActions/odsObjectActions';
import { startEditCardRequest } from 'app/actions/odsObjectActions/startEditCardRequest';
import { get } from 'app/api/crud/get';
import { useDispatch } from 'react-redux';

/* eslint-disable */

/**
 *
 */
export const useStartMatchingCard = () => {
  const dispatch = useDispatch();
  return (record_id, type_id) => {
    dispatch(startEditCardRequest());

    return get('/card/matching/start', { record_id, type_id }).then((json) => {
      return json.errors && json.errors.length > 0
        ? dispatch(receiveErrorMatchCard(json.message || json.errors[0]))
        : !json.lockInfo || json.lockInfo.lock
        ? dispatch(showSendMatchCard(json.cardInfo))
        : dispatch(editCardDidNotLock(json.lockInfo));
    });
  };
};
