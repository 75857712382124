import { SAVE_TREE_ACTIONS } from 'app/constants/actionTypes';
import { ListDataType } from 'app/pages/cardsOgh/components/RightPanelCard/components/DialogEditButton/listDataType';
import { useDispatch } from 'react-redux';

type UseSaveType = {
  listData: ListDataType;
  ids: number[];
  closeDialog: () => void;
};

/**
 * Fn useSaveDialogEditButton.
 *
 * @param root0 - Properties.
 * @param root0.closeDialog - Fn Close dialog.
 * @param root0.ids - Ids.
 * @param root0.listData - List data.
 * @returns {*}
 */
export const useSaveDialogEditButton = ({
  closeDialog,
  ids,
  listData,
}: UseSaveType) => {
  const dispatch = useDispatch();

  return () => {
    if (listData.items.length !== ids.length) {
      const dataList = {
        [listData.typeId]: {
          ...listData,
          items: listData.items.filter(({ id }) => ids.includes(id)),
        },
      };

      dispatch({
        dataList,
        type: SAVE_TREE_ACTIONS,
      });
    }

    closeDialog();
  };
};
