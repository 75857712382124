import { HOLE, LINESTRING, POINT, POLYGON } from 'app/constants/geometryTypes';
import { FOREGROUND } from 'app/constants/layers';
import { EGKO, SPUTNIK } from 'app/constants/tileLayers';
import geometryService from 'app/services/geometryService';

import { egip } from '../../utils/helpers/egip';
import { ol } from '../../utils/helpers/ol';
import Draw from './interactions/Draw';
import Modify from './interactions/Modify';
import Select from './interactions/Select';
import Transform from './interactions/Transform';
import createCustomLayers from './mapLayers';
import multipointTypes from './multipointTypes';
/* eslint-disable */
const setProperties = (feature, oghObjectId, oghTypeId) => {
  feature.set('oghObjectId', oghObjectId);
  feature.set('oghTypeId', oghTypeId);
};

const setPointerCursor = (map, pixel, editable) =>
  (map.getTargetElement().style.cursor = map.hasFeatureAtPixel(pixel, {
    layerFilter: (layer) => (editable ? layer.get('id') === FOREGROUND : true),
  })
    ? 'pointer'
    : '');

class MapEditor {
  constructor(fieldId) {
    // every map editor should have its own custom layers object
    const customLayers = createCustomLayers();

    this.map = egip.layers.createMap({
      target: document.getElementById(fieldId),
      controls: [],
      layers: [egip.layers.createTiles77WMSC(), ...customLayers],
      view: egip.layers.createView77({
        zoom: 11,
      }),
    });

    this.editable = false;

    this.map.on('pointermove', this.handlePointerMove);

    this.foregroundLayer = egip.layers.getLayerById(this.map, FOREGROUND);

    this.select = new Select(this.map, this.foregroundLayer, customLayers);
    this.draw = new Draw(this.map, this.foregroundLayer);
    this.modify = new Modify(this.map, this.select.getSelectedFeatures());
    this.transform = new Transform(this.map, this.foregroundLayer);

    this.modify.onModifyStart(() => this.transform.clearSelection());
  }

  onFeaturesChange = (onChangeHandler) => {
    this.foregroundLayer
      .getSource()
      .on(
        ['addfeature', 'changefeature', 'removefeature', 'clear'],
        onChangeHandler,
      );
  };

  switchTilesTo(key) {
    if (key === SPUTNIK) {
      egip.layers.setTileLayer(this.map, egip.layers.createTilesSatellite77());
    }
    if (key === EGKO) {
      egip.layers.setTileLayer(this.map, egip.layers.createTiles77WMSC());
    }
  }

  handlePointerMove = (event) => {
    setPointerCursor(this.map, event.pixel, this.editable);
  };

  setEditable(editable) {
    this.editable = editable;
    this.select.turnOff();
    this.select.turnOn(editable);

    if (editable) {
      this.modify.turnOn();
      this.transform.turnOn();
    } else {
      this.modify.turnOff();
      this.transform.turnOff();
    }
  }

  getFeatures(layerId) {
    const layer = egip.layers.getLayerById(this.map, layerId);
    return layer.getSource().getFeatures();
  }

  removeFeature(feature, layerId) {
    const layer = egip.layers.getLayerById(this.map, layerId);
    layer.getSource().removeFeature(feature);
  }

  startDrawing(type, handlers) {
    this.draw.turnOffTools();
    this.transform.turnOff();
    this.select.turnOff();
    this.draw.setActiveTool(type);

    const { drawStartHandler, drawEndHandler } = handlers;

    drawStartHandler && this.draw.onDrawStart(type, drawStartHandler);
    drawEndHandler && this.draw.onDrawEnd(type, drawEndHandler);
  }

  startDrawingPoint(oghObjectId, oghTypeId, onEndDrawing) {
    this.startDrawing(POINT, {
      drawEndHandler: (event) => {
        const isMultiPoint = multipointTypes.includes(oghTypeId);
        this.removeUnnecessaryPoints(event.feature, isMultiPoint);
        setProperties(event.feature, oghObjectId, oghTypeId);
        onEndDrawing && onEndDrawing(event);
      },
    });
  }

  removeUnnecessaryPoints(feature, isMultiPoint) {
    const features = this.getFeatures(FOREGROUND);
    let pointToBeRemoved;
    if (isMultiPoint) {
      // check if new point is a duplicate of some existing point and remove the duplicate
      pointToBeRemoved = features.find(
        (item) =>
          item.getGeometry().getType() === POINT &&
          geometryService.checkPointsAreDuplicates(item, feature),
      );
    } else {
      // remove another point if multiple points are not allowed for current oghType
      pointToBeRemoved = features.find(
        (item) => item.getGeometry().getType() === POINT,
      );
    }

    if (pointToBeRemoved) {
      this.removeFeature(pointToBeRemoved, FOREGROUND);
    }
  }

  startDrawingLine(oghObjectId, oghTypeId, onEndDrawing) {
    this.startDrawing(LINESTRING, {
      drawEndHandler: (event) => {
        setProperties(event.feature, oghObjectId, oghTypeId);
        onEndDrawing && onEndDrawing(event);
      },
    });
  }

  startDrawingPolygon(oghObjectId, oghTypeId, onEndDrawing) {
    this.startDrawing(POLYGON, {
      drawStartHandler: () =>
        this.foregroundLayer
          .getSource()
          .once('addfeature', this.handleAddPolygon),
      drawEndHandler: (event) => {
        setProperties(event.feature, oghObjectId, oghTypeId);
        onEndDrawing && onEndDrawing(event);
      },
    });
  }

  handleAddPolygon = (event) => {
    const oghObjectId = event.feature.get('oghObjectId');
    const oghTypeId = event.feature.get('oghTypeId');

    const exteriorRing = event.feature.getGeometry().getCoordinates()[0];

    const transformed = geometryService.transformRing(exteriorRing);

    this.foregroundLayer.getSource().removeFeature(event.feature);

    if (transformed) {
      const transformedFeatures = transformed.map((ring) => {
        const feature = new ol.Feature({
          geometry: new ol.geom.Polygon([ring]),
        });

        setProperties(feature, oghObjectId, oghTypeId);

        return feature;
      });

      this.foregroundLayer.getSource().addFeatures(transformedFeatures);
    }
  };

  handleAddHole = (event) => {
    const interiorRing = event.hole.getGeometry().getCoordinates()[0];

    const transformed = geometryService.transformRing(interiorRing);

    const featureCoordinates = event.feature.getGeometry().getCoordinates();
    const transformedFeatureCoordinates = featureCoordinates.slice(
      0,
      featureCoordinates.length - 1,
    );

    if (transformed) {
      transformedFeatureCoordinates.push(...transformed);
    }

    event.feature.getGeometry().setCoordinates(transformedFeatureCoordinates);
  };

  startDrawingHole(oghObjectId, oghTypeId) {
    this.startDrawing(HOLE, {
      drawStartHandler: (event) => {
        setProperties(event.feature, oghObjectId, oghTypeId);
        this.draw[HOLE].once('drawend', this.handleAddHole);
      },
    });
  }

  stopDrawing() {
    this.draw.turnOffTools();
    this.transform.turnOn();
    this.select.turnOn(this.editable);
  }

  deleteSelected() {
    const selectedFeature = this.select.getSelectedFeature();
    if (selectedFeature) {
      this.removeFeature(selectedFeature, FOREGROUND);
      this.select.clearSelection();
      this.transform.clearSelection();
    }
  }

  getGeometry(layerId) {
    const features = this.getFeatures(layerId);
    return geometryService.getGeometryObject(features);
  }

  drawFeatures(layerId, features) {
    const layer = egip.layers.getLayerById(this.map, layerId);
    layer.getSource().addFeatures(features);
  }

  clearLayer(layerId) {
    const layer = egip.layers.getLayerById(this.map, layerId);
    const clearedFeatures = layer.getSource().getFeatures();
    this.select.deselect(clearedFeatures);
    layer.getSource().clear();
  }

  clearInappropriateGeometry(allowedGeometryTypes) {
    const features = this.foregroundLayer.getSource().getFeatures();

    features
      .filter((feature) => {
        const featureType = feature.getGeometry().getType();
        return !allowedGeometryTypes.includes(featureType);
      })
      .forEach((inappropriateFeature) =>
        this.foregroundLayer.getSource().removeFeature(inappropriateFeature),
      );
  }

  zoomToFeatures(layerIds) {
    const features = layerIds
      .map((layerId) => egip.layers.getLayerById(this.map, layerId))
      .reduce((result, layer) => {
        const features = layer.getSource().getFeatures();
        result.push(...features);
        return result;
      }, []);

    if (features.length > 0) {
      const boundingExtent = features.reduce((result, feature) => {
        ol.extent.extend(result, feature.getGeometry().getExtent());
        return result;
      }, ol.extent.createEmpty());

      this.map.getView().fit(boundingExtent, { minResolution: 0.3 });
    }
  }
}

 
export default MapEditor;
