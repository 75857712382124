import { useCallback, useEffect, useMemo, useState } from 'react';

import { usePrevious } from './usePrevious';

export type Callback<Type = unknown> = (prev: Type, value: Type) => unknown;
export type Comparator<Type = unknown> = (prev: Type, value: Type) => boolean;

/**
 * Default comparator.
 *
 * @param prev - Предыдущее значение.
 * @param curr - Текущее значение.
 * @returns Boolean.
 */
const defaultComparator: Comparator = <Type>(prev: Type, curr: Type) =>
  prev !== curr;

/**
 * Call a callback when the value changes.
 *
 * @param {*} value - The value to watch.
 * @param {Function} cb - The callback to call.
 * @param {Function} comparator - The comparator to use.
 * @returns A.
 */
export const useOnPreviousChange = <Type = unknown>(
  value: Type,
  cb: Callback<Type>,
  {
    callInUseEffect = false,
    comparator = defaultComparator,
  }: {
    callInUseEffect?: boolean;
    comparator?: Comparator<Type>;
  } = {},
) => {
  const [prev, setPrev] = useState(value);
  const isPrevChanged = useMemo(
    () => comparator(prev, value),
    [comparator, prev, value],
  );

  const onChange = useCallback(
    (prev: Type, value: Type, cb: Callback<Type>) => {
      cb(prev, value);
      setPrev(value);
    },
    [],
  );

  if (!callInUseEffect && isPrevChanged) {
    onChange(prev, value, cb);
  }

  useEffect(() => {
    if (callInUseEffect && isPrevChanged) {
      onChange(prev, value, cb);
    }
  }, [callInUseEffect, prev, value, isPrevChanged, onChange, cb]);

  return usePrevious(value);
};

export default useOnPreviousChange;
