/* eslint-disable */
//  прокси объект для автоматического заполнения свойств
function Folder(
  obj = {
    setConfig: function () {},
  },
) {
  return new Proxy(obj, {
    get: (target, property) => {
      if (!(property in target)) {
        target[property] = new Folder();
      }
      return target[property];
    },
  });
}

 
export default Folder;
 
export { Folder };

 
export const createFolder = (obj) => {
  return function () {
    return new Proxy(obj, {
      get: (target, property) => {
        if (!(property in target)) {
          target[property] = () => new Folder();
        }
        return target[property];
      },
    });
  };
};
