/* eslint-disable */

import './RightPanel.scss';

import { ArrowHeader } from 'app/pages/cardsOgh/components/RightPanelCard/components/icons/ArrowHeader';
import { TreeRightPanel } from 'app/pages/cardsOgh/components/RightPanelCard/components/TreeRightPanel/TreeRightPanel';
import cn from 'classnames';
import IconButton from 'core/newComponents/IconButton';
import $ from 'jquery';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { cardToggleStyles } from './styles';

const propTypes = {
  Tree: PropTypes.object,
  mapPanel: PropTypes.element,
};
//import Button from 'core/newComponents/Button';

/**
 * Компонент правой панели.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
const RightPanel = (props) => {
  const { mapPanel } = props;
  const [activeTree, setActiveTree] = useState(true);

  /**
   * Функция тогл дерева.
   *
   * @returns {void}
   */
  const toggleTree = () => {
    setActiveTree((pre) => !pre);
    $('#right-panel').toggleClass('bottom-auto');
  };

  const classTreeBody = cn('right-panel-card-container__body', {
    'right-panel-card-container__body__hide': !activeTree,
  });

  /**
   * Функция тогл правой панели.
   *
   * @returns {void}
   */
  const toggleLeftPanel = () => {
    $('.ods-core-left-panel').toggle();
    $(window).resize(); // чтобы сработали события для скрытых элементов
  };

  return (
    <div id="right-panel" className="right-panel">
      <div className="right-panel__header">
        <div className="right-panel__tree-toggle">
          <h4>Список объектов</h4>
          <ArrowHeader active={activeTree} onClick={toggleTree} />
        </div>
        {mapPanel}
        <IconButton
          className="right-panel__card-toggle card-toggle fa fa-angle-double-right boxArea"
          style={cardToggleStyles}
          onClick={toggleLeftPanel}
        />
      </div>
      <div id={'object-tree'} className={classTreeBody}>
        <TreeRightPanel />
      </div>
    </div>
  );
};

RightPanel.propTypes = propTypes;

export default RightPanel;
