import { AccordionPlantationTab } from '../Accordion.PlantationTab';
import { TableFlowersGardenList } from './Table.FlowersGardenList';

 
/* eslint-disable */
/**
 * Ведомость учета зеленых насаждений (цветники)
 * @returns {JSX.Element}
 * @constructor
 */
 
export const FlowersGardenList = ({ expanded, onChange }) => {
  return (
    <AccordionPlantationTab
      code={'flowers_garden_list'}
      title={'Ведомость учета зеленых насаждений (цветники)'}
      Table={TableFlowersGardenList}
      expanded={expanded}
      onChange={onChange}
    />
  );
};
