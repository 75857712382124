import { AutocompleteAsync } from 'core/uiKit/inputs/selects/AutocompleteAsync';
import React, { FC } from 'react';
import { SelectProps } from 'types/inputs';

import { fetchTrafficSignsObject } from './api';

export const TRAFFIC_SIGNS_ID_NAME = 'traffic_signs_id';

interface TrafficSignsIdProps extends SelectProps {}

/**
 * Дом, корпус, строение.
 *
 * @param props - Properties.
 * @returns {JSX}
 */
export const TrafficSignsId: FC<TrafficSignsIdProps> = (props) => {
  return (
    <AutocompleteAsync
      {...props}
      additionalDataForRequest={{}}
      fetchFunction={fetchTrafficSignsObject}
      label={'Номер по ГОСТ и наименование знака'}
      name={TRAFFIC_SIGNS_ID_NAME}
    />
  );
};
