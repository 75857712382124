/* eslint-disable */
import React from 'react';
import { showIfHavePrivilege } from 'app/grants';
import NotificationIcon from './NotificationIcon';

const NotificationPrivileged = showIfHavePrivilege('view_matching_technic_car')(
  function () {
    return <NotificationIcon />;
  },
);

 
export default NotificationPrivileged;
