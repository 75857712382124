import { MafTypeLevel2FF } from 'core/form/finalForm/fields/prepared/selects/simple/MafTypeLevel2.FF';
import { MAF_TYPE_LEVEL_2_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/MafTypeLevel2';
import { NumberParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

/**
 * @typedef {import('use-query-params').NumberParam} NumberParam
 */

/**
 * Детализация.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - JSX.
 */
export const MafTypeLevel2Filter = (props) => (
  <MafTypeLevel2FF className={filterInput} withAll={false} {...props} />
);

/**
 * QueryParam Уточнение.
 *
 * @type {{"[MAF_TYPE_LEVEL_2_ID_NAME]": NumberParam}}
 * @example -----
 *  const [filters, setFilters] = useQueryParams([
 *     ownerIdParams,
 *     idOrNameParentParams,
 *   ]);
 */
export const mafTypeLevel2Params = {
  [MAF_TYPE_LEVEL_2_ID_NAME]: NumberParam,
};
