import { getYesterdayDateSting } from 'app/utils/date/getYesterdayDateSting';
import { KeyboardDatePickerFF } from 'core/form/finalForm/fields/default/datePickers/KeyboardDatePickerFF';
import React from 'react';

import { useGetDisabledDateCloseField } from './useGetDisabled.DateCloseField';

export const DATE_CLOSE_FIELD_NAME = 'date';

/**
 * Поле даты закрытия.
 *
 * @returns {JSX.Element}
 */
export const DateCloseField = () => {
  const disabled = useGetDisabledDateCloseField();

  return (
    <KeyboardDatePickerFF
      minDate={getYesterdayDateSting()}
      disabled={disabled}
      label={'Дата закрытия'}
      name={DATE_CLOSE_FIELD_NAME}
    />
  );
};
