import { getFilesId } from 'app/api/requests/getFilesId';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';

import { useGetActOfCompletedWorkElectronicSignature } from './useGetActOfCompletedWorkElectronicSignature';

/**
 * Хук получения файлов для подписи.
 *
 * @returns {object}
 */
export const useGetFilesActOfCompletedWorkElectronicSignature = () => {
  const { data, isSuccess } = useGetActOfCompletedWorkElectronicSignature();

  const fileList = (data.data || [])?.reduce((arr, item) => {
    const regex = /.json$/g;
    if (regex.test(item.file_name)) {
      arr.push(item.file_id);
      return arr;
    }
    return arr;
  }, []);

  const { data: files, ...otherParams } = useQueryAdaptor(
    ['getFilesActOfCompletedWorkElectronicSignature', { fileList }],
    getFilesId,
    { enabled: isSuccess },
  );

  return { files, ...otherParams };
};
