import metadata from './metadata';


/* eslint-disable */
export default function (formValues) {
  return Object.values(metadata).reduce((result, item) => {
    const value = formValues[item.id];
    if (value) {
      result[item.id] = {
        [item.dictionary]: value,
      };
    }
    return result;
  }, {});
}
