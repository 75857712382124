import { objectMatching } from 'app/actions/adminActions';
/* eslint-disable */
import { clearCard } from 'app/actions/clearCardStore';
import { hideDialog, showAlert, showNotification } from 'app/actions/dialog';
import {
  fetchCheckGeomContains,
  fetchCheckGeometryIntersections,
  fetchObjectsGeometry,
} from 'app/actions/geometryActions';
import {
  afterReceiving,
  cancelEditCard,
  cancelEditCurrentCard,
  cancelLeaveCurrentElement,
  cancelMode,
  createCard,
  fetchOgh,
  fetchPreviewCard,
  rollbackTreeChanges,
  sendCreateCard,
  sendDeleteCard,
  sendUpdateCard,
  viewCard,
} from 'app/actions/odsObjectActions';
import { clearActionsTree } from 'app/actions/odsObjectActions/clearActionsTree';
import {
  fetchFewTree,
  fetchPreviewTreeSelectedElement,
  fetchTreeSelectedElement,
  treeDataRecalculate,
  treeSelectRoot,
} from 'app/actions/odsObjectTreeActions';
import { toggleCompareReportVisibility } from 'app/actions/odsObjectVersionsActions';
import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import withMap from 'app/components/map/withMap';
import { useActionsMap } from 'app/pages/cardsOgh/components/Map/useActionsMap';
import { useGetTreePanelData } from 'app/pages/cardsOgh/components/RightPanelCard/components/TreeRightPanel/hooks/useGetTreePanelData/useGetTreePanelData';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import getTreeJournal from 'app/selectors/card/getTreeJournal';
import hasGeometry from 'app/selectors/card/hasGeometry';
import getFormValues from 'app/selectors/form/getFormValues';
import { useGetParamsUrl } from 'core/utils/hooks/useGetParamsUrl';
import { useNavigateRouter } from 'core/utils/router';
import { flow, get } from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import OghCard from './card';

/**
 * MapStateToProps.
 *
 * @param {object} state - State.
 * @param {object} props - Props.
 * @returns {{parentTypeId: undefined, matchingRegistryColumns: *, treeModal: *, currentFormValues: *, treeOghModal: *, parentId: *, matching: *, currentLocation: Array, mode, treeJournal: unknown, hasGeometry: unknown, versionsModal, treeParams: *, action: *, card: *, selected: *, goToRegistry: *, addChildInfo: *, treeData: (Array)}} - Жопа.
 * @example
 * ---
 */
function mapStateToProps(state, props) {
  const { parentId } = props;
  return {
    action: state.matching?.afterAction,
    actionsTree: state.tree?.actionsTree || {},
    addChildInfo: state.tree?.addChildInfo,
    card: state.card?.object,
    currentFormValues: getFormValues(state),
    currentLocation: [],
    goToRegistry: state.matching?.goToRegistry,
    hasGeometry: hasGeometry(state),
    matching: state.matching?.data,
    matchingRegistryColumns: state.tableColumns?.objectMatchingRegistry,
    mode: state.card.mode,
    parentId: get(state, 'card.object.parent_info.parent_id', parentId),
    parentTypeId: get(state, 'registry.data.meta.parentTypeId'),
    selected: state.tree?.object,
    treeData: get(state, 'card.mode.editMode')
      ? state.tree.dataForModify || []
      : state.tree.data || [],
    treeJournal: getTreeJournal(state),
    treeModal: state.modals?.treeEdit,
    treeOghModal: state.modals?.treeEdit,
    treeParams: state.card?.current,
    versionsModal: state.modals.versions,
  };
}

const mapDispatchToProps = {
  afterReceiving,
  cancelEditCard,
  cancelEditCurrentCard,
  cancelLeaveCurrentElement,
  cancelMode,
  clearActionsTree,
  clearCard,
  createCard,
  fetchCheckGeomContains,
  fetchCheckGeometryIntersections,
  fetchFewTree,
  fetchObjectsGeometry,
  fetchPreviewCard,
  fetchPreviewTreeSelectedElement,
  fetchRootCard: fetchOgh,
  fetchTreeSelectedElement,
  hideDialog,
  objectMatching,
  rollbackTreeChanges,
  sendCreateCard: sendCreateCard('ogh'),
  sendDeleteCard: sendDeleteCard('ogh'),
  sendUpdateCard: sendUpdateCard('ogh'),
  showAlert,
  showNotification,
  toggleCompareReportVisibility,
  treeDataRecalculate,
  treeSelectRoot,
  viewCard,
};

/**
 * OghCardHoc.
 *
 * @param {object} props - Props.
 * @returns {JSX.Element} - JSX.
 * @example
 * -----
 */
const OghCardHoc = (props) => {
  useActionsMap();
  const { recordId } = useGetCurrentCard();
  const paramsUrl = useGetParamsUrl();
  const parentCard = useGetParentCard();
  const { removeTree } = useGetTreePanelData();

  const parentInfo = {
    ...parentCard,
    isChild: paramsUrl.actions.haveParent,
  };

  useEffect(() => {
    props.fetchRootCard(recordId);
  }, [recordId]);

  return <OghCard {...props} parentInfo={parentInfo} updateTree={removeTree} />;
};

const Card = flow(
  connect(mapStateToProps, mapDispatchToProps),
  withMap,
)(OghCardHoc);

// todo: костыль на время. создан когда была переделка на новый роут.
//   нужно переделать на переменые из роута

/**
 *
 */
export default () => {
  const params = useParams();

  // todo: А оно тут надо . Ведь типа это для просмотра
  // todo: пока такой костыль.
  //  надо переделать создание дочерней карточки через роут create
  //  и через src/app/components/card/ogh/index/create.js:111
  let parentId = null;
  let parentTypeId = null;
  if (params.childId === 'create') {
    parentId = +params.id;
    parentTypeId = +params.typeId;
  }
  const { startLoading } = useMode();

  let location = useLocation();
  const navigate = useNavigateRouter();

  /**
   * @deprecated
   * @see используй navigate
   */
  const history = { push: navigate };
  const isCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();

  /**
   * Навигация с запуском лоудера для отображения уведомления о не сохраненных данных.
   *
   * @param {string} url - Url.
   * @returns {void}
   */
  const navigateWithLading = (url) => {
    startLoading();
    setTimeout(() => {
      navigate(url);
    }, 0);
  };

  return (
    <Card
      {...{
        currentLocation: location,
        history,
        isCurrentObjectIsExternalSystem,
        location,
        navigate,
        navigateWithLading,
        params,
        parentId,
        parentTypeId,
      }}
    />
  );
};
