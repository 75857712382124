import {
  cancelEditCard,
  leaveCurrentElement,
  rollbackTreeChanges,
  setMode,
  viewCard,
} from 'app/actions/odsObjectActions';
import { treeSelectRoot } from 'app/actions/odsObjectTreeActions';
import { FOREGROUND, PARENTS } from 'app/constants/layers';
import getTreeJournal from 'app/selectors/card/getTreeJournal';
import * as treeSelectors from 'app/selectors/tree';
import { log } from 'core/utils/log';
import { flow, get } from 'lodash';

/* eslint-disable */
export const leaveOghCard =
  (currentCard, mode, mapActions, goBack, push) => (dispatch, getState) => {
    const state = getState();
    const card = state.card.object;
    const treeData = state.tree.data;
    const recordId = currentCard.record_id;
    const { editMode, viewMode, createMode } = mode;

    const redrawMap = () => {
      const {
        fetchObjectsGeometry,
        drawCurrentObjectsGeometry,
        drawParentGeometry,
        clearLayer,
      } = mapActions;
      const treeParams = state.card.current;

      if (!createMode) {
        fetchObjectsGeometry({ ids: [recordId] }).then((json) => {
          clearLayer(FOREGROUND);
          drawCurrentObjectsGeometry(json);
        });

        const element = get(treeParams, 'element', {});

        const parentIds = [
          card.record_id,
          ...(element.path_object_id ? element.path_object_id.split('.') : []),
        ];

        parentIds.pop();

        if (parentIds.length > 0) {
          fetchObjectsGeometry({ ids: parentIds }).then((json) => {
            clearLayer(PARENTS);
            drawParentGeometry(json);
          });
        }
      }
    };
    const goLeaveBack = goBack;
    const goToRoot = () => dispatch(treeSelectRoot());
    const rollbackTree = () => {
      const treeJournal = getTreeJournal(state);

      dispatch(
        rollbackTreeChanges({
          id: card.root_id,
          type_id: card.type_id,
          journal:
            treeJournal !== null ? JSON.stringify(treeJournal.actions) : null,
        }),
      );
    };

    const isChild = Boolean(treeSelectors.getObject(state));

    // Просмотр дочернего объекта
    if (viewMode && isChild) {
      goToRoot();
      return;
    }

    // Добавление дочернего объекта
    if (createMode && isChild) {
      const callback = flow(rollbackTree, goToRoot, () => {
        dispatch(setMode('view'));
        // Костыль сделано для того чтобы был переход на родительскую карточку
        if (typeof push === 'function') {
          push(`/ogh/${card.root_id}`);
        }
      });

      dispatch(leaveCurrentElement(callback));
      return;
    }

    // Редактирование сохранённого объекта (дочернего или корневого)
    if (editMode && !createMode) {
      const callback = flow(redrawMap, rollbackTree, () =>
        dispatch(cancelEditCard(currentCard.root_id, recordId)),
      );
      dispatch(
        leaveCurrentElement(
          callback,
          treeData && state.card.current
            ? treeData[card.record_id].find(
                (singleGroup) =>
                  singleGroup.type_id === state.card.current.group.type_id,
              )
            : null,
        ),
      );
      return;
    }

    if (viewMode) {
      goLeaveBack();
      return;
    }

    // Создание нового родительского объекта
    if (createMode && !isChild) {
      const callback = flow(rollbackTree, goLeaveBack);
      dispatch(leaveCurrentElement(callback));
      return;
    }
  };

 
export const leaveBasePgmDocCard = (mode) => (dispatch) => {
  const { editMode, viewMode, createMode } = mode;

  // const goLeaveBack = history.goBack;
  const setViewMode = () => dispatch(viewCard());

  // Редактирование сохранённого документа
  if (editMode && !createMode) {
    dispatch(leaveCurrentElement(setViewMode));
    return;
  }

  // Просмотр сохранённого документа
  if (viewMode) {
    log.banana('goLeaveBack()');
    return;
  }

  // Создание нового документа
  if (createMode) {
    // const callback = flow(goLeaveBack, setViewMode);
    // dispatch(leaveCurrentElement(callback));
    log.banana('goLeaveBack()');

    return;
  }
};
