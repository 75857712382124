import { get } from 'app/api/crud/get';
import { post } from 'app/api/crud/post';

/**
 * Запрос на пересечение с данными ИС РЕОН.
 *
 * @param {object} data - Данные запроса.
 * @returns {*}
 */
export const fetchReonIntersectionsGeometry = (data) =>
  post('/ogh/geometry/reon/intersection', data);

/**
 * Метод fetchCheckGeomContains.
 *
 * @param {object} data - Данные запроса.
 * @returns {*}
 */
export const fetchCheckGeomContains = (data) =>
  post('/ogh/geometry/check_geom_contains', data);

/**
 * Запрос на получение элементов текущего ОГХ.
 *
 * @param {{ids: Array<number>}} data - Данные запроса.
 * @returns {*}
 */
export const fetchObjectsGeometry = (data) => {
  return post('/ogh/geometry/load', data);
};

/**
 * Метод fetchGroupGeometry.
 *
 * @param {object} data - Данные запроса.
 * @returns {*}
 */
export const fetchGroupGeometry = (data) => post('/ogh/geometry/group', data);

/**
 * Метод fetchBtiGeometry.
 *
 * @param {object} data - Данные запроса.
 * @returns {*}
 */
export const fetchBtiGeometry = (data) => get('/ogh/geometry/bti', data);

/**
 * Метод fetchFullOghGeometry.
 *
 * @param {object} data - Данные запроса.
 * @returns {*}
 */
export const fetchFullOghGeometry = (data) => get('/ogh/geometry/full', data);
