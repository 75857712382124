import {
  filterInput,
  filterInputData,
} from 'core/form/filterForm/components/filterInputsClasses';
import { FinalForm } from 'core/form/finalForm/components/FinalForm';
import {
  ReagentFF,
  ReagentKindFF,
  SelectFF,
} from 'core/form/finalForm/fields';
import { DateFromFF } from 'core/form/finalForm/fields/prepared/datePickers/keyboardDatePickerFF/DateFromFF';
import { DateToFF } from 'core/form/finalForm/fields/prepared/datePickers/keyboardDatePickerFF/DateToFF';
import { Button } from 'core/uiKit/components';

const operationKinds = [
  { id: 'refill', name: 'Приход' },
  { id: 'shipment', name: 'Отпуск' },
];

/* eslint-disable */
export const FilterOperations = ({
  search,
  submit,
  cancel,
}) => {
  return (
    <FinalForm initialValues={search} onSubmit={submit}>
      <div className={'filter__form'}>
        <DateFromFF className={filterInputData} />
        <DateToFF className={filterInputData} />
        <SelectFF
          label="Операция"
          name="operationKind"
          options={operationKinds}
          className={filterInput}
        />
        <ReagentKindFF className={filterInput} />
        <ReagentFF className={filterInput} />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 15,
        }}
      >
        <Button variant={'outlined'} onClick={cancel}>
          Отмена
        </Button>
        <Button
          type="submit"
          color={'primary'}
          variant={'contained'}
        >
          Поиск
        </Button>
      </div>
    </FinalForm>
  );
};
