import * as api from 'app/components/card/ogh/index/card/components/Bound/api';
import { queryClient } from 'app/routes/QueryClientProvider';
import {
  ContinueDialog,
  toast,
} from 'core/uiKit/components';
import { ApproveBoundariesOghButton } from 'core/uiKit/components/buttons/actionsButtons/ApproveBoundariesOgh.Button';
import { TextField } from 'core/uiKit/inputs';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import { useNavigateRouter } from 'core/utils/router';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

 
/* eslint-disable */
/**
 * Утвердить границы
 * @param editMode
 * @returns {JSX.Element|null}
 * @constructor
 */
const ApproveBoundaries = ({ editMode }) => {
  const navigate = useNavigateRouter();
  const [isOpen, setIsOpen] = useState(false);
  const [comment, setComment] = useState('');
  const { childId, id } = useParams();
  const objectId = childId || id;

  const show = !editMode && objectId;

  const openDialog = () => setIsOpen(true);
  const closeDialog = () => {
    queryClient.invalidateQueries(
      'getVisibilityButtonsOgh',
    );
    setIsOpen(false);
  };

  const [send, { isLoading }] = useMutationAdaptor(
    () => api.approveMatchingBounds({ objectId, comment }),
    {
      onSuccess: () => {
        toast.success('Согласование границ утверждено');
        navigate('/r/matching-boundaries');
        queryClient.invalidateQueries(
          'fetchMatchingBoundariesTable',
        );
        queryClient.invalidateQueries(
          'fetchMatchingBoundariesTableCount',
        );
        closeDialog();
      },
    },
    { enabled: show },
  );

  return show ? (
    <>
      <ContinueDialog
        {...{
          textHeader: 'Утвердить границы объекта',
          isLoading,
          isOpen,
          send: () => {
            send();
          },
          closeDialog,
          Body: (
            <TextField
              rows={4}
              multiline
              variant="outlined"
              label={'Комментарий'}
              maxLength={1000}
              value={comment}
              onChange={setComment}
            />
          ),
        }}
      />
      <ApproveBoundariesOghButton
        objectId={objectId}
        onClick={openDialog}
      />
    </>
  ) : null;
};

const mapStateToProps = (state) => ({
  editMode: state.card?.mode?.editMode,
});

 
export default connect(mapStateToProps)(ApproveBoundaries);

ApproveBoundaries.propTypes = {
  /*
   * показывать целиком компоненту или нет
   */
  editMode: PropTypes.bool,
};
