import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import formatRootId from 'app/utils/formatRootId';
import TableSimple from 'core/uiKit/components/tables/TableSimple/TableSimple';
import * as React from 'react';

import { getMapColumnsTableDelete } from './getMapColumns.TableDelete';

/**
 * Таблица удаления.
 *
 * @param {object} props - Пропсы.
 * @param {Function} props.setSelectRowsTableDelete - Функция выбора столбцов.
 * @param {string} props.title - Заголовок.
 * @param {object} props.dataTable - Данные таблицы.
 * @param {number} props.count - Остаток.
 * @param {boolean} props.isLoading - Флаг загрузки.
 * @param {number} props.typeId - Тип ОГХ.
 * @returns {JSX.Element} - Возвращает таблицу удаления.
 */
export const TableDelete = ({
  count,
  dataTable,
  isLoading,
  setSelectRowsTableDelete,
  title,
  typeId,
}) => {
  const columns = getMapColumnsTableDelete(typeId);
  const parent = useGetParentCard();
  const current = useGetCurrentCard();

  const { typeNameRus, name, recordId } = parent.isSuccessParent
    ? parent
    : current;

  /**
   * Функция выбора колонки.
   *
   * @param {Array<*>} selectedFlatRows - Выбранные колонки.
   * @returns {void}
   */
  const selectedRow = ({ selectedFlatRows }) => {
    setSelectRowsTableDelete(
      selectedFlatRows.map(
        ({ original }) => original?.id || original?.object_id,
      ),
    );
  };

  return (
    <div style={{ width: '100%' }}>
      <p>
        {`Список объектов ${title} в ${typeNameRus}${
          name ? name : ''
        } ID ${formatRootId(recordId)}`}
      </p>

      <div style={{ height: '50vh', overflow: 'auto' }}>
        <TableSimple
          isLoading={isLoading}
          columns={columns}
          data={dataTable || []}
          total={count}
          checkboxSelection={true}
          selectedRows={selectedRow}
        />
      </div>
    </div>
  );
};
