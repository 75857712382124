import { fetchBtiAddress } from 'app/api/typeahead';
import { elementFormGrid } from 'app/components/card/common/grid';
import { WithOldValueCheckbox } from 'app/components/common/CheckboxField';
import { WithOldValueSelect } from 'app/components/common/SelectField';
import { column } from 'app/constants/tables';
import KeyboardDatePickerRF from 'core/form/reduxForm/fields/default/datePickers/KeyboardDatePickerRF';
import { AutocompleteAsyncRF } from 'core/form/reduxForm/fields/default/selects/AutocompleteAsyncRF';
import { TextFieldRF } from 'core/form/reduxForm/fields/default/TextFieldRF';
import { CarCustomerListRF } from 'core/form/reduxForm/fields/prepared/selects/autocompleteMultiAsync/CarCustomerList.RF';
import { log } from 'core/utils/log';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

/**
 *
 */
class Characteristics extends React.Component {
  static propTypes = {
    carConditions: PropTypes.array,
    carSeasons: PropTypes.array,
    changeFieldValue: PropTypes.func.isRequired,
    currentValues: PropTypes.object,
    isEditable: PropTypes.func.isRequired,
    isLinkedWithGlonass: PropTypes.bool,
    oldValues: PropTypes.object,
    required: PropTypes.bool,
  };

  /**
   * A.
   *
   * @returns {JSX.Element}
   */
  getCustomer() {
    const { props } = this;
    const { editCar } = props;
    log.info(props);
    return [
      <div>
        <CarCustomerListRF
          {...{
            disabled: !(
              props.isEditable('car_customer_list') &&
              (editCar.isActualCar ||
                editCar.isChangeCarPerson ||
                editCar.isModernizationCar ||
                editCar.isErrorInputCar)
            ),
            required: props.required,
          }}
        />
      </div>,
    ];
  }

  /**
   * A.
   *
   * @param {*} root0 - A.
   * @param {*} root0.id - A.
   * @param {*} root0.label - A.
   * @param {*} root0.editable - A.
   * @param {*} root0.formValue - A.
   * @param {*} root0.component - A.
   * @param {*} root0.addition - A.
   * @returns {*}
   */
  getElement({
    id,
    label,
    editable = this.isEditable(id),
    formValue = true,
    component,
    addition,
  }) {
    const { oldValues } = this.props;
    return {
      addition: {
        ...addition,
        oldValue: oldValues[id],
      },
      component,
      editable,
      formValue,
      id,
      label,
      name: id,
    };
  }

  /**
   * A.
   *
   * @returns {JSX.Element}
   */
  getElements() {
    const { props } = this;
    const { isEditable, required, changeFieldValue, currentValues, editCar } =
      props;
    return [
      this.getElement({
        addition: {
          label: 'Сезонность',
          options: props.carSeasons,
          required,
        },
        component: WithOldValueSelect,
        editable:
          editCar.isActualCar ||
          editCar.isModernizationCar ||
          editCar.isSendForStorage ||
          editCar.isErrorInputCar,
        id: 'car_season_id',
      }),
      this.getElement({
        addition: {
          formatChars: {
            9: '[0-9]',
          },
          mask: '999',
          type: 'text',
        },
        component: TextFieldRF,
        editable: false,
        id: 'max_speed',
        label: 'Максимальная скорость, км/ч',
      }),
      this.getElement({
        addition: {
          label: 'Застраховано',

          /**
           * A.
           *
           * @returns {void}
           */
          onChange: () => {
            if (currentValues.insured) {
              changeFieldValue('insurance_end_date', null);
              changeFieldValue('file_list', currentValues.file_list);
            }
          },
        },
        component: WithOldValueCheckbox,
        editable:
          isEditable('insured') &&
          (editCar.isActualCar ||
            editCar.isModernizationCar ||
            editCar.isErrorInputCar),
        id: 'insured',
      }),
      this.getElement({
        addition: {
          label: 'Дата окончания договора страхования',
          minDate: new Date(),

          /**
           * A.
           *
           * @returns {void}
           */
          onChange: () => {
            changeFieldValue('insured', true);
          },
        },
        component: KeyboardDatePickerRF,
        editable:
          isEditable('insurance_end_date') &&
          (editCar.isActualCar ||
            editCar.isModernizationCar ||
            editCar.isErrorInputCar),
        id: 'insurance_end_date',
      }),
      this.getElement({
        addition: {
          label: 'Не подлежит оснащению БНСО',
        },
        component: WithOldValueCheckbox,
        editable:
          !props.isLinkedWithGlonass &&
          isEditable('not_need_glonass') &&
          (editCar.isActualCar ||
            editCar.isModernizationCar ||
            editCar.isErrorInputCar),
        id: 'not_need_glonass',
      }),
    ];
  }

  /**
   * A.
   *
   * @returns {*}
   */
  getFirstRow() {
    const { props } = this;
    const { required, editCar } = props;
    return [
      this.getElement({
        addition: {
          fetchFunction: fetchBtiAddress,
          label: 'Автобаза',
          required,
        },
        component: AutocompleteAsyncRF,
        editable:
          editCar.isActualCar ||
          editCar.isModernizationCar ||
          editCar.isErrorInputCar,
        id: 'car_base_id',
      }),
      this.getElement({
        addition: {
          label: 'Техническое состояние',
          options: props.carConditions,
          required,
        },
        component: WithOldValueSelect,
        editable:
          editCar.isActualCar ||
          editCar.isModernizationCar ||
          editCar.isErrorInputCar,
        id: 'car_condition_id',
      }),
    ];
  }

  /**
   * A.
   *
   * @param {number} id - Id.
   * @returns {boolean}
   */
  isEditable(id) {
    return this.props.isEditable(id);
  }

  /**
   * A.
   *
   * @returns {JSX.Element}
   */
  render() {
    return (
      <div>
        {elementFormGrid(this.getFirstRow(), column.TWO)}
        {elementFormGrid(this.getCustomer(), column.ONE)}
        {elementFormGrid(this.getElements(), column.TWO)}
      </div>
    );
  }
}

/**
 * A.
 *
 * @returns {object}
 */
const mapStateToProps = () => {
  // const { mode: { reasonCode, requestType } = {} } = state.card;

  // // Редактирование техники
  // const isEditCar = requestType === 'edit_car';

  const editCar = {
    // Актуализация техники
    isActualCar: true, // isEditCar && reasonCode === 'actual_car',

    /**
     * Изменение типа ТС, подтверждаемое фотоизображением(-ями) ТС, позволяющим однозначно определить регистрационный номер ТС и вид установленного на нем оборудования.
     */
    isChangeCarFuncType: true, // isEditCar && reasonCode === 'change_car_person',

    /**
     *  Изменение владельца/заказчика/подрядчика (пользователя) ТС.
     */
    isChangeCarPerson: true, // isEditCar && reasonCode === 'change_car_func_type',

    /**
     * Ошибочное внесение информации.
     */
    isErrorInputCar: true, // isEditCar && reasonCode === 'modernization_car',

    /**
     *Модернизация.
     */
    isModernizationCar: true, // isEditCar && reasonCode === 'send_for_storage',

    /**
     * Отправка на сезонное хранение.
     */
    isSendForStorage: true, // isEditCar && reasonCode === 'error_input_car',
  };

  // Изменение связи
  // const isEditTree = requestType === 'edit_tree';
  const editTree = {
    // Добавление оборудования
    isAddCarEquipment: true, //isEditTree && reasonCode === 'add_car_equipment',
    // Снятие оборудования
    isRemoveCarEquipment: true, //isEditTree && reasonCode === 'remove_car_equipment',
  };

  return {
    editCar,
    editTree,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Characteristics);
