import { useEditorCardSelector } from 'app/selectors/useSelectors/useEditorCard.Selector';
/* eslint-disable */
import { RANDOM_WORD } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { create } from 'react-test-renderer';

import { columnsMovementCharacteristics } from './columns.MovementCharacteristics';
import { MovementCharacteristics } from './MovementCharacteristics';

jest.mock('app/selectors/useSelectors/useEditorCard.Selector');
jest.mock('./AddRow.RF', () => ({
  /**
   *
   */
  AddRowRF: () => <div>AddRowRF</div>,
}));

describe('file MovementCharacteristics', () => {
  it('simple test text', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useEditorCardSelector.mockReturnValue({});
    // Act
    const wrapper = shallow(<MovementCharacteristics />);

    // Assert
    expect(wrapper.text()).toBe('<TabBody />');
  });

  it('[snapshot]', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useEditorCardSelector.mockReturnValue({});

    // Act
    const root = create(<MovementCharacteristics />);

    // Assert
    expect(root.toJSON()).toMatchSnapshot();
  });

  it('trafficCharacterList props', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useEditorCardSelector.mockReturnValue({
      trafficCharacterList: [{ RANDOM_WORD }],
    });

    // Act
    const wrapper = shallow(<MovementCharacteristics />);

    // Assert
    expect(wrapper.childAt(0).childAt(0).props()).toMatchObject({
      columns: columnsMovementCharacteristics,
      data: [{ RANDOM_WORD }],
    });
  });

  it('trafficCharacterList props null', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useEditorCardSelector.mockReturnValue({});

    // Act
    const wrapper = shallow(<MovementCharacteristics />);

    // Assert
    expect(wrapper.childAt(0).childAt(0).props()).toMatchObject({
      columns: columnsMovementCharacteristics,
      data: [],
    });
  });
});
