import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { IPlanarStructureTypeIdData } from 'core/uiKit/preparedInputs/selects/simpleSelect/PlanarStructureTypeId';

/**
 * Setup Назначение для типа планарной структуры.
 *
 * @returns Data.
 */
export const useSetupPlanarStructureTypeIdField = () => {
  const parentCard = useGetParentCard();
  const { editMode } = useMode();
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();

  /**
   * Функция фильтрации.
   *
   * @param data - Данные.
   * @returns Данные.
   */
  const filter = (data: IPlanarStructureTypeIdData[]) => {
    return data.filter((item) =>
      item?.parent_ogh_object_type_list?.includes(+parentCard?.type_id),
    );
  };

  return {
    filter,
    isEdit: editMode && isEditCurrentObjectIsExternalSystem,
  };
};
