/* eslint-disable  */
import {
  OZN_TYPE_ID_NAME,
  OznTypeID,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/OznTypeID';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

/**
 *
 * @param root0
 * @param root0.input
 */
const ReasonDeleteRFWithReduxForm = ({ input, ...props }) => {
  return <OznTypeID {...input} {...props} />;
};
/* eslint-disable */
export const OznTypeIDRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={OZN_TYPE_ID_NAME}
      component={ReasonDeleteRFWithReduxForm}
    />
  );
};

OznTypeIDRF.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
};
OznTypeIDRF.defaultProps = {
  disabled: false,
};
