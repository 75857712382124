/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */

/**
 * Колонка __Группа элементов__.
 *
 * @type {{ accessor: 'abutment_friends_ogh_object_type',  Header: 'Группа элементов', rowSpan: 1, sortable: true }}
 * @augments Cell
 */
export const abutment_friends_ogh_object_type = {
  Header: 'Группа элементов',
  accessor: 'abutment_friends_ogh_object_type',
  rowSpan: 1,
  sortable: true,
};
