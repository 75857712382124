import { WithMapContext } from 'app/components/map/withMap';
/* eslint-disable */
import { ORDERS } from 'app/constants/layers';
import toArray from 'app/utils/toArray';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import LegendPanel from './LegendPanel';
import OrderDetails from './OrderDetails';
import OrdersTable from './OrdersTable';
import SearchForm from './SearchForm';

const tabStyle = {
  marginLeft: 20,
  marginTop: 20,
};

/**
 * OrdersTab.
 *
 */
class OrdersTab extends PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    endDate: PropTypes.string.isRequired,
    ordersTableConfig: PropTypes.object,
    recordId: PropTypes.number,
    selectedOghOrders: PropTypes.object,
    startDate: PropTypes.string.isRequired,
  };
  static contextType = WithMapContext;

  /**
   * Компонент жизненого цикла componentDidUpdate.
   *
   * @returns {void} - Nothing.
   * @param {object} prevProps - Предыдущие пропсы.
   * @example --------
   */
  componentDidUpdate(prevProps) {
    const prevSelectedOrders = toArray(prevProps.selectedOghOrders);
    const selectedOrders = toArray(this.props.selectedOghOrders);

    if (
      prevSelectedOrders &&
      prevSelectedOrders.length !== selectedOrders.length
    ) {
      this.context.clearLayer(ORDERS);
      this.context.drawOrdersGeometry(selectedOrders);
    }
  }

  /**
   * Компонент жизненого цикла componentWillUnmount.
   *
   * @returns {void} - Nothing.
   * @example ----
   */
  componentWillUnmount() {
    this.props.resetOrdersGeometry();
  }

  /**
   * OnGeometryToggle .
   *
   * @param {*} order - Order.
   * @returns {void}
   */
  onGeometryToggle = (order) => {
    const hintFields = [
      {
        key: 'title',
        rawValue: 'Ордер на работы',
        value: 'Ордер на работы',
      },
      {
        key: 'global_id',
        rawValue: order.global_id,
        value: `ID: ${order.global_id}`,
      },
      {
        key: 'ordernumber',
        rawValue: order.ordernumber,
        value: `Номер: ${order.ordernumber}`,
      },
      {
        key: 'workstartdate',
        rawValue: order.workstartdate,
        value: `Дата начала: ${order.workstartdate}`,
      },
      {
        key: 'workenddate',
        rawValue: order.workenddate,
        value: `Дата окончания: ${order.workenddate}`,
      },
      {
        key: 'worktype',
        rawValue: order.worktype,
        value: `Виды работ: ${order.worktype}`,
      },
    ];

    const hint = hintFields.filter((item) => item.rawValue);

    this.props.toggleGeometry({
      global_id: order.global_id,
      hint: {
        object_attribute_hint: hint,
        object_attribute_hint_short: [
          {
            key: 'name',
            value: `Ордер на работы, ID = ${order.global_id}`,
          },
        ],
      },
      ...order.geometry,
      workstartdate: order.workstartdate,
    });
  };

  /**
   * IsSelected.
   *
   * @param {*} orderGlobalId - The global.
   * @returns {boolean}
   */
  isSelected = (orderGlobalId) => !!this.props.selectedOghOrders[orderGlobalId];

  /**
   * RenderContent().
   *
   * @returns {JSX.Element} - Реакт компонент.
   */
  renderContent() {
    const { toggleDetails } = this.props;
    return [
      <div key="orderTab-block" style={tabStyle}>
        <p>
          Данные ордеров на производство работ (ОДОПМ, идентификатор набора
          данных 2855)
        </p>

        <SearchForm />

        <OrdersTable
          isSelected={this.isSelected}
          selected={this.props.selectedOghOrders}
          selectRow={this.onGeometryToggle}
          tableConfig={this.props.ordersTableConfig}
          toggleDetails={toggleDetails}
        />

        <LegendPanel />
      </div>,
      <OrderDetails key="orderTab-details" toggleDetails={toggleDetails} />,
    ];
  }

  /**
   * Метод жизненого цикла render().
   *
   * @returns {JSX.Element} - Реакт компонент.
   * @example ----
   */
  render() {
    const { disabled } = this.props;
    return (
      <div className="" id="orders">
        {!disabled && this.renderContent()}
      </div>
    );
  }
}

/**
 *
 * @param options
 */
export default (options) => {
  return (props) => <OrdersTab {...props} options={options} />;
};
