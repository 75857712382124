import { print } from 'app/api/requests/print';
import { RANDOM_ID } from 'core/forTesting/constants';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import { shallow } from 'enzyme';
import React from 'react';
import { vi } from 'vitest';

import { UploadToXLSXMenuItem } from './UploadToXLSX.MenuItem';

vi.mock('core/utils/hooks/useGetObjectId');
vi.mock('core/utils/hooks/useReactQuery');
vi.mock('app/api/requests/print');

/**
 * Mock Default.
 *
 * @returns {void}
 */
const mockDefault = () => {
  useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
  useMutationAdaptor.mockImplementation((fn) => [fn, {}]);
};

describe('ToFormDxfMenuItem', function () {
  it('🧪 default', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(<UploadToXLSXMenuItem />);

    //❓ Assert
    expect(wrapper.text()).toBe('printВыгрузить в .xlsx');
  });

  it('🧪 print called with correct params', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock

    const wrapper = shallow(<UploadToXLSXMenuItem />);
    const onClick = wrapper.prop('onClick');

    //🔥 Act
    onClick();

    //❓ Assert
    expect(print).toHaveBeenCalledWith('passport/' + RANDOM_ID);
  });
});
