import amber from 'core/hocs/button/buttonAmber';
import black from 'core/hocs/button/buttonBlack';
import blue from 'core/hocs/button/buttonBlue';
import green from 'core/hocs/button/buttonGreen';
import outlined from 'core/hocs/button/buttonOutlined';
import red from 'core/hocs/button/buttonRed';

import Button from './Button';

const ButtonAmber = amber(Button);
const ButtonBlack = black(Button);
const ButtonBlue = blue(Button);
const ButtonGreen = green(Button);
const ButtonRed = red(Button);

// При необходимости добавить другие цвета и описать в theme.js для MuiButton.outlined
const ButtonOutlinedBlue = blue(outlined(Button));

/* eslint-disable */
export {
  ButtonOutlinedBlue,
  ButtonAmber,
  ButtonBlack,
  ButtonBlue,
  ButtonGreen,
  ButtonRed,
};

 
export default Button;
