import * as api from 'core/uiKit/components/dialogs/ChangeUserPassword/api';
import { toast } from 'core/uiKit/components/Toast';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

/* eslint-disable */
export const useSendDialog = (closeDialog) => {
  const [send, { isLoading }] = useMutationAdaptor(
    api.changePassword,
    {
      onSuccess: () => {
        closeDialog();
        toast.success('Пароль обновлен');
      },
      onError: () => {
        closeDialog();
        toast.error('Ошибка смены пароля');
      },
    },
  );

  return [send, { isLoading }];
};
