/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { change, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { actions as formActions } from 'react-redux-form';
import { values, isEmpty } from 'lodash';

import { Tabs } from 'app/components/card/common/tabs';
import CardAbstract from 'app/components/card/common/CardAbstract';
import { validateCarFuncType } from 'app/components/card/common/validation';

import getDict from 'app/selectors/buildDictSelector';
import isRequired from 'app/selectors/card/isRequired';
import isCreateMode from 'app/selectors/card/isCreateMode';
import getFormValues from 'app/selectors/form/getFormValues';
import isGibdd from 'app/selectors/card/tech/isGibdd';
import isAddCarRequest from 'app/selectors/card/tech/isAddCarRequest';
import getRequestReasonCodeById from 'app/selectors/card/getRequestReasonCodeById';
import getCarOwnershipCode from 'app/selectors/card/tech/getCarOwnershipCode';
import getCarEquipmentTypes from 'app/selectors/registry/getCarEquipmentTypes';

import validateService from 'core/services/validateService';
import techFormFieldDisabledService from 'app/services/techFormFieldDisabledService';

import Vin from 'app/domain/Vin';

import applyHocs from 'core/utils/applyHocs';

import BnsoList from './components/BnsoList';
import EquipmentTechnikList from './components/EquipmentTechnikList';
import LinkageList from './components/LinkageList';
import PropsFields, {
  mapStateToPropsReducer as propsFieldsMapStateToPropsReducer,
} from './components/PropsFields';
import Characteristics, {
  mapStateToPropsReducer as characteristicsMapStateToPropsReducer,
} from './components/Characteristics';
import CarDocs, {
  mapStateToPropsReducer as carDocsMapStateToPropsReducer,
} from './components/CarDocs';
import OperationInfo, {
  mapStateToPropsReducer as operationInfoMapStateToPropsReducer,
} from './components/OperationInfo';

import printTechnikCardHeader from '../header/technikHeader';
import onSubmit from '../submit/car';
import Versions from '../versions';
import Uaisbu from './components/Uaisbu/Uaisbu';
import dateFormatService from 'core/services/dateFormatService';
import { setRoundDate } from 'app/utils/dateUtils';
import { ProcessApprovingTable } from 'app/components/card/tech/car/components/Tables/ProcessApprovingTable';

const mainTabsStyle = {
  paddingLeft: '24px',
};

const tabs = [
  {
    id: 'characteristics-tab',
    name: 'Общие сведения',
  },
  {
    id: 'uaisbu-tab',
    name: 'УАИС БУ',
  },
  {
    id: 'docs-tab',
    name: 'Документы',
  },
];

const equipmentTabs = [
  {
    id: 'bnso-tab',
    name: 'БНСО',
  },
  {
    id: 'equipment-technic-tab',
    name: 'Навесное оборудование',
  },
  {
    id: 'linkage-tab',
    name: 'Подключение',
  },
];

const emptyOldValues = {};

const propTypes = {
  carOwnership: PropTypes.array.isRequired,
  carOwnershipCode: PropTypes.string,
  carFuncTypeId: PropTypes.number,
  mode: PropTypes.object,
  fetchEquipmentData: PropTypes.func,
  changeFieldValue: PropTypes.func,
  required: PropTypes.bool,
  card: PropTypes.object,
  isAtributesBuPresent: PropTypes.bool,
  currentValues: PropTypes.object,
  isAddCarRequest: PropTypes.bool,
  oldValues: PropTypes.object,
  technicRequestType: PropTypes.array,
  technicRequestReason: PropTypes.array,
  carMotorTypes: PropTypes.array,
  legalCarRegistration: PropTypes.array,
  carEcologyClasses: PropTypes.array,
  carFuncTypeGroups: PropTypes.array,
  carFuncTypes: PropTypes.array,
  carFuncTypeGroupId: PropTypes.number,
  vinDisabled: PropTypes.bool,
  factoryNumberDisabled: PropTypes.bool,
  carCreateYears: PropTypes.array,
  carSeasons: PropTypes.array,
  carConditions: PropTypes.array,
  isLinkedWithGlonass: PropTypes.bool,
  technicFileTypes: PropTypes.array,
  createMode: PropTypes.bool,
  initialValues: PropTypes.object,
};

/* eslint-disable */
export default function carCardTemplate({ mainTabs, tabsCar = [] }) {
  class CarCard extends React.Component {
    componentDidUpdate(prevProps) {
      const { carFuncTypeId, mode } = this.props;
      if (prevProps.carFuncTypeId !== carFuncTypeId && !mode.editMode) {
        this.props.fetchEquipmentData();
      }
    }

    onChangeCarOwnership = (id) => {
      const { props } = this;
      const { code } = props.carOwnership.find((item) => item.id === id);
      if (this.isBalanceOfInstitutionCode(code)) {
        this.changeFieldValue('leasing_end_date', null);
      }
    };

    isEditable = (fieldId) => {
      const { mode, requestReasonCode } = this.props;
      const disabledByReason = techFormFieldDisabledService.isDisabled(
        mode && mode.requestType,
        requestReasonCode,
        fieldId,
      );
      return (
        (mode &&
          mode.editMode &&
          mode.requestType !== 'edit_tree' &&
          !disabledByReason) ||
        false
      );
    };

    changeFieldValue = (field, value) => {
      this.props.changeFieldValue(field, value);
    };

    isEditableLeasingEndDate = (fieldId) => {
      return (
        !this.isBalanceOfInstitutionCode(this.props.carOwnershipCode) &&
        this.isEditable(fieldId)
      );
    };

    isBalanceOfInstitutionCode(code) {
      const balanceOfInstitutionCode = '1';
      return balanceOfInstitutionCode === code;
    }

    filterMainTabs(props) {
      return mainTabs.filter((item) =>
        item.isVisible ? item.isVisible(props) : true,
      );
    }

    isInitialHiddenTab(id) {
      const item = mainTabs.find((item) => item.id === `${id}-tab`);
      return !item || !item.active;
    }

    renderMainTabs(list) {
      return (
        <div className="tabs" style={mainTabsStyle}>
          <ul>
            {list.map((item) => (
              <li
                className={item.active ? 'active' : null}
                id={item.id}
                key={item.id}
              >
                {item.label}
              </li>
            ))}
          </ul>
        </div>
      );
    }

    render() {
      const { props } = this;
      const { mode, card, required, createMode, isAtributesBuPresent } = props;

      // Если карточка не имеет root_id то не отрисовываем таблицу процесс согласования измененений
      if (!card.root_id) {
        tabsCar = [];
      }
      const equipmentList =
        props.currentValues.equipmentList || props.initialValues.equipmentList;
      const oldValues = props.isAddCarRequest
        ? emptyOldValues
        : props.oldValues;

      const list = this.filterMainTabs({
        createMode,
      });

      return (
        <CardAbstract>
          <div id="editor-card">
            {printTechnikCardHeader({
              card,
              mode,
              oldValues,
            })}

            {card && this.renderMainTabs(list)}

            <div hidden={this.isInitialHiddenTab('admin')} id="admin">
              <OperationInfo
                technicRequestReason={props.technicRequestReason}
                technicRequestType={props.technicRequestType}
              />
            </div>

            <div hidden={this.isInitialHiddenTab('props')} id="props">
              <PropsFields
                carCreateYears={props.carCreateYears}
                carEcologyClasses={props.carEcologyClasses}
                carFuncTypeGroupId={props.carFuncTypeGroupId}
                carFuncTypeGroups={props.carFuncTypeGroups}
                carFuncTypes={props.carFuncTypes}
                carMotorTypes={props.carMotorTypes}
                carOwnership={props.carOwnership}
                changeFieldValue={this.changeFieldValue}
                currentValues={props.currentValues}
                factoryNumberDisabled={props.factoryNumberDisabled}
                isEditable={this.isEditable}
                isEditableLeasingEndDate={this.isEditableLeasingEndDate}
                legalCarRegistration={props.legalCarRegistration}
                oldValues={oldValues}
                required={required}
                vinDisabled={props.vinDisabled}
                onChangeCarOwnership={this.onChangeCarOwnership}
              />

              <Tabs tabs={[...tabs, ...tabsCar]} />

              {!!tabsCar.length ? (
                <div hidden={true} id="approval-process">
                  <ProcessApprovingTable />
                </div>
              ) : null}

              <div id="characteristics">
                <Characteristics
                  carConditions={props.carConditions}
                  carOwnershipCode={props.carOwnershipCode}
                  carSeasons={props.carSeasons}
                  changeFieldValue={this.changeFieldValue}
                  currentValues={props.currentValues}
                  isEditable={this.isEditable}
                  isLinkedWithGlonass={props.isLinkedWithGlonass}
                  oldValues={oldValues}
                  required={required}
                />
              </div>

              <div hidden={this.isInitialHiddenTab('uaisbu')} id="uaisbu">
                <Uaisbu currentValues={props.currentValues} />
              </div>

              <div hidden={true} id="docs">
                <CarDocs
                  changeFieldValue={this.changeFieldValue}
                  currentValues={props.currentValues}
                  isEditable={this.isEditable}
                  oldValues={oldValues}
                  technicFileTypes={props.technicFileTypes}
                />
              </div>
            </div>

            <div hidden={true} id="equipment">
              <Tabs tabs={equipmentTabs} />

              <div id="bnso">
                <BnsoList list={props.initialValues.bnsoList} />
              </div>

              <div hidden={true} id="equipment-technic">
                <EquipmentTechnikList
                  carOwnership={props.carOwnership}
                  isAtributesBuPresent={isAtributesBuPresent}
                  links_bu={props.links_bu}
                  list={equipmentList}
                  mode={mode}
                  setList={(list) => {
                    props.changeFieldValue('equipmentList', list);
                  }}
                />
              </div>

              <div hidden={true} id="linkage">
                <LinkageList list={props.initialValues.connectList} />
              </div>
            </div>

            <div hidden={true} id="versions">
              {createMode ? null : <Versions card={card} />}
            </div>
          </div>
        </CardAbstract>
      );
    }
  }

  CarCard.propTypes = propTypes;

  return applyHocs(CarCard, [
    connect(reducedMapStateToProps, mapDispatchToProps),
    reduxForm({
      form: 'editorCard',
    }),
  ]);
}

const mapStateToPropsReducers = [
  mapStateToPropsReducer,
  propsFieldsMapStateToPropsReducer,
  characteristicsMapStateToPropsReducer,
  carDocsMapStateToPropsReducer,
  operationInfoMapStateToPropsReducer,
];

function reducedMapStateToProps(state, props) {
  return mapStateToPropsReducers.reduce(
    (result, reducer) => reducer(result, state, props),
    {},
  );
}

const getTechnicFileTypes = getDict('technicFileTypes');
const getCarFuncTypes = getDict('carFuncTypes');
const getTechnicRequestType = getDict('technicRequestType');
const getTechnicRequestReason = getDict('technicRequestReason');
function mapStateToPropsReducer(result, state, props) {
  const { card } = props;
  const oldValues = card.old_technic_attribute || {};
  const technicFileTypes = getTechnicFileTypes(state);
  const carFuncTypes = getCarFuncTypes(state);
  const isAtributesBuPresent = values(card.attributes_bu).some(
    (attribute) => !!attribute,
  );

  const changeCarForm = isEmpty(props.createFormValue)
    ? state.rrf.changeCar
    : props.createFormValue;
  return {
    enableReinitialize: true,

    isAtributesBuPresent,

    initialValues: {
      id: card.id,
      uuid: card.uuid,
      startDate: setRoundDate(card.start_date),
      endDate: card.end_date,
      name: card.name,

      bnsoList: card.gps_list,
      equipmentList: card.equipment_list,
      connectList: card.connect_list,

      ...(isAtributesBuPresent
        ? {
            registration_date_bu: dateFormatService.formatDate(
              card.attributes_bu.registration_date_bu,
            ),
            deregistration_date_bu: dateFormatService.formatDate(
              card.attributes_bu.deregistration_date_bu,
            ),
            car_model_bu: card.attributes_bu.car_model_bu,
            inventory_number_bu: card.attributes_bu.inventory_number_bu,
            chassis_number_bu: card.attributes_bu.chassis_number_bu,
            body_number_bu: card.attributes_bu.body_number_bu,
            engine_model_bu: card.attributes_bu.engine_model_bu,
            name_asset_bu: card.attributes_bu.name_asset_bu,
            car_special_model_bu: card.attributes_bu.car_special_model_bu,
            car_func_type_bu: card.attributes_bu.car_func_type_bu,
            engine_number_bu: card.attributes_bu.engine_number_bu,
          }
        : null),
    },

    oldValues: {
      ...oldValues,
      startDate: card.old_start_date,
      endDate: card.old_end_date,
    },

    currentValues: getFormValues(state),

    isGibdd: isGibdd(state),
    isAddCarRequest: isAddCarRequest(state),

    required: isRequired(state, props),
    createMode: isCreateMode(state, props),

    carOwnershipCode: getCarOwnershipCode(state),
    requestReasonCode: getRequestReasonCodeById(state),

    technicFileTypes,
    technicRequestType: getTechnicRequestType(state),
    technicRequestReason: getTechnicRequestReason(state),

    carFuncTypeId: getFormValues(state).car_func_type_id,
    carEquipmentTypes: getCarEquipmentTypes(state),

    ...(card.attributes_bu ? { links_bu: card.attributes_bu.links_bu } : null),

    onSubmit: onSubmit(
      {
        ...props,
        technicFileTypes,
        carFuncTypes,
        carOwnershipCode: getCarOwnershipCode(state),
      },
      state.technologyObject.techTreePaths,
      changeCarForm,
      props.card.has_connections,
    ),

    validate,
  };
}

const formConstraints = {
  vin: Vin.constraints,
};

function validate(values, { isGibdd, initialized, carEquipmentTypes, mode }) {
  const skipValidation =
    mode && mode.editMode && mode.requestType === 'edit_tree';

  if (skipValidation) {
    return {};
  }

  const validateCarFuncTypeResult = validateCarFuncType(
    values,
    carEquipmentTypes,
  );
  const validateFormConstraintsResult = initialized
    ? validateService.validateForm(values, formConstraints, {
        isGibdd,
      })
    : {};

  return {
    ...validateCarFuncTypeResult,
    ...validateFormConstraintsResult,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeFieldValue: (field, value) => {
      dispatch(change('editorCard', field, value));
    },
    fetchEquipmentData: function () {
      return dispatch(formActions.submit('rrf.techRegistry.equipment'));
    },
  };
}
