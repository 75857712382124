import * as types from '../constants/actionTypes';

/* eslint-disable */
export default function tl(state = {}, action) {
  switch (action.type) {
    case types.RECEIVE_TITLE_LIST: {
      return { ...state, object: action.object };
    }
    case types.RECEIVE_START_EDIT_TL: {
      return { ...state, object: { ...state.object, ...action.object } };
    }
    case types.RECEIVE_CALC_DATE_TO_TITLE_LIST: {
      return {
        ...state,
        object: { ...state.object, nextDateTo: action.nextDateTo },
      };
    }
    case types.CLEAR_TITLE_LIST: {
      return {};
    }
    case types.RECEIVE_TITLE_LIST_DIFF: {
      return { ...state, diff: action.object };
    }
    case types.RECEIVE_TITLE_LIST_HISTORY: {
      return { ...state, history: action.object };
    }
    default:
      return state;
  }
}
