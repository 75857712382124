import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useGetCardModeSelector } from 'app/selectors/useSelectors';
import isMandatory from 'app/utils/card/isMandatory';
import { Select } from 'core/uiKit/inputs/selects/Select';

export const CONV_ELEMENT_TYPE_ID_NAME = 'conv_element_type_id';

/**
 * ConvElementTypeId.
 *
 * @param {*} props - Properties.
 * @returns {JSX.Element}
 */
export const ConvElementTypeId = (props) => {
  const { convElementType, typeId } = useGetCurrentCard();
  const { editMode } = useGetCardModeSelector();

  return (
    <Select
      {...props}
      label={'Тип'}
      disabled={!editMode || props.disabled}
      name={CONV_ELEMENT_TYPE_ID_NAME}
      options={convElementType}
      required={isMandatory(typeId, CONV_ELEMENT_TYPE_ID_NAME, true)}
    />
  );
};
