import { useParamsTable } from 'core/uiKit/components/tables/Table';
/* eslint-disable */
import { useState } from 'react';

/**
 *
 * @returns {{expanded: boolean, handleChange: (function(*): function(*, *): void)}}
 */

/**
 *
 */
export const usePlantationTab = () => {
  const [expanded, setExpanded] = useState(false);
  const { resetPagination } = useParamsTable();

  /**
   *
   * @param panel
   */
  const handleChange = (panel) => (event, isExpanded) => {
    resetPagination();
    setExpanded(isExpanded ? panel : false);
  };

  return { expanded, handleChange };
};
