import { turnIntoCamelCase } from 'app/utils/api';
/* eslint-disable */

/**
 *
 * @param formValues
 */
export const marginAreaValidation = (formValues) => {
  const { marginArea, marginAutoCleanArea, marginManualCleanArea } =
    turnIntoCamelCase(formValues);

  if (+marginArea < +marginAutoCleanArea + +marginManualCleanArea) {
    return {
      margin_area:
        '«Обочины, кв.м» должно быть не меньше суммы значений параметров «Площадь уборки обочин мех., кв.м» и «Площадь уборки обочин руч., кв.м»',
    };
  }
};
