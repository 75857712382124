/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import { elementFormGrid } from 'app/components/card/common/grid/index';


/* eslint-disable */
export default function ({ elements, id, className = 'd-none' }) {
  class PropsTab extends React.Component {
    static propTypes = {
      typeId: PropTypes.number.isRequired,
    };

    renderElements() {
      const { typeId } = this.props;
      return elements.map((item) => {
        const { cols = 1, title = '' } = item;
        return (
          <React.Fragment key={id}>
            {elementFormGrid(item.getElements(this.props), cols, title, typeId)}
          </React.Fragment>
        );
      });
    }

    render() {
      return (
        <div className={className} id={id} key={id}>
          {this.renderElements()}
        </div>
      );
    }
  }

  return (params) => (props) => <PropsTab {...props} {...params} />;
}
