import { CATEGORY_SP_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CategorySpId/CategorySpId';
import { Field } from 'redux-form';

import { CategorySpIdWithReduxForm } from './CategorySpIdWithReduxForm';

/**
 * Поле __Категория по уборке RF__.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const CleanSpCategoryIdRf = ({ ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={CATEGORY_SP_ID_NAME}
      component={CategorySpIdWithReduxForm}
    />
  );
};
