import __camelCase from 'lodash/camelCase';
/* eslint-disable  */
import __set from 'lodash/set';

/**
 *
 * @param arr
 */
export const arrStrToObjTypesOgh = (arr) => (str) => {
  const folder = {};

  const filtered = (arr || []).filter((item) => item.indexOf(str) === 0);
  if (!filtered.length) {
    return folder;
  }

  const pathNameTmp =
    filtered.find((item) => item.indexOf(`${str}_`) !== 0) || str;

  const pathName = pathNameTmp.replace(/_/g, '.');

  __set(folder, pathName + '.main', 1);

  const typesOgh = filtered
    .filter((item) => item.indexOf(`${str}_`) === 0)
    .map((item) => item.replace(`${str}_`, ''));

  typesOgh.forEach((item) => {
    __set(folder, `${pathName}.${item}`, 1);
    __set(folder, `${pathName}.${__camelCase(item)}`, 1);
  });

  return folder;
};
