/* eslint-disable  */
/**
 * Хелпер для загрузки или печати файла..
 *
 * @param {*} response - Response.
 * @returns {Promise<void>}
 */
export const handleBrowserDownload = async (response) => {
  let filename = 'Печать.csv';
  let blob = new Blob([]);

  if (response.status === 200) {
    filename = getFilename(response);
    blob = await response.blob();
  }
  const element = document.createElement('a');
  element.href = URL.createObjectURL(blob);
  element.download = filename;

  document.body.appendChild(element);

  element.click();
  element.remove('a');
};

/**
 *
 * @param response
 */
const getFilename = (response) => {
  const disposition = response.headers.get('Content-Disposition');

  let filename;

  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[*][^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);

    if (matches !== null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
      filename = filename.replace(/utf-8/g, '');
      filename = decodeURIComponent(filename);
    }
  } else {
    filename = 'default_filename';
  }

  return filename;
};
