import React from 'react';

import toggleContent from '../../content/toggle';
import ToggleButton from '../ToggleButton';
import { useSetupCheckGeometryButton } from './useSetup.CheckGeometry.Button';

/**
 * Компонент кнопки CheckGeometryButton .
 *
 * @returns JSX.
 */
export const CheckGeometryButton = () => {
  const { active, isLoading, onClick } = useSetupCheckGeometryButton();
  return (
    <ToggleButton
      disabled={isLoading}
      isLoading={isLoading}
      active={active}
      content={toggleContent.intersections}
      onClick={onClick}
    />
  );
};
