import getDict from 'app/selectors/getDict';
import { get } from 'lodash';

const getDepartmentTypes = getDict('departmentTypes');


/* eslint-disable */
export default function (params = {}) {
  return (result, state, props) => {
    const { card } = props;
    const {
      id = 'department_id',
      path = `attribute.${id}.department`,
      department_id = null,
    } = params;

    return {
      ...result,
      initialValues: {
        ...result.initialValues,
        [id]: get(card, path, department_id),
      },
      departmentTypes: getDepartmentTypes(state),
    };
  };
}
