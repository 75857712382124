import { get, includes } from 'lodash';

const dictFields = ['explication_type_id'];


/* eslint-disable */
export default function ({ explication_list }) {
  let result;
  if (get(explication_list, 'table.length')) {
    result = explication_list.table.map(({ checked, ...item }) =>
      dictMapper(item),
    );
  } else {
    result = null;
  }
  return {
    explication_list: result,
  };
}

const dictMapper = (item) => {
  const mappedItem = {};
  const onlyViewKey = 'explication_area_percent';
  Object.keys(item).forEach((key) => {
    if (includes(dictFields, key)) {
      const dictKey = key.replace('_id', '');
      mappedItem[key] = { [dictKey]: item[key] };
    } else {
      if (key.indexOf(onlyViewKey) === -1) {
        mappedItem[key] = parseFloat(item[key]);
      }
    }
  });
  return mappedItem;
};
