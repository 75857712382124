/* eslint-disable  */
import { get } from 'app/api';
import { handleBrowserDownload } from 'core/utils/helpers';

/**
 *
 * @param templateName
 * @param queryParams
 */
export const printReportsOld = async (templateName, queryParams) => {
  const res = await getReportsOld(templateName, queryParams);
  return handleBrowserDownload(res);
};

/**
 *
 * @param templateName
 * @param queryParams
 */
const getReportsOld = (templateName, queryParams) =>
  get(`/export/xlsx/${templateName}`, queryParams, {
    parseResponse: false,
  });
