import { RANDOM_WORD } from 'core/forTesting/constants';

import { passDevInitiatorValidation } from './pass_dev_initiator.validation';

describe('PassDevInitiatorValidationSpec', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act

    // ❓ Assert
    expect(passDevInitiatorValidation({})).toStrictEqual({
      pass_dev_initiator: 'обязательное поле',
    });
  });
  it('have value', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act

    // ❓ Assert
    expect(
      passDevInitiatorValidation({ pass_dev_initiator: RANDOM_WORD }),
    ).toBe(null);
  });
});
