import './СomparisonLink.scss';

/* eslint-disable  */
import { useMain } from 'app/components/common/NavBar/components/BadgeBell/useMain';
import { pathMafRfid } from 'app/pages/registry/ogh/MafRfid/path.MafRfid';
import { pathOgh } from 'app/pages/registry/ogh/Ogh/path.Ogh';
import { pathRegistry } from 'app/pages/registry/path.Registry';
import { ChevronRightIcon } from 'core/uiKit/material-ui';
import { useNavigateRouter } from 'core/utils/router';
import React from 'react';

const path = `/${pathRegistry.path}/${pathOgh.path}/${pathMafRfid.path}`;

/**
 *
 * @param root0
 * @param root0.count
 */
export const СomparisonLink = ({ count }) => {
  const navigate = useNavigateRouter();
  const { openDrawer } = useMain();

  /**
   *
   */
  const onClick = () => {
    openDrawer(false);
    navigate(path);
  };

  return (
    <div onClick={onClick} className={'comparison-link__container'}>
      <div className={'comparison-link__left'}>
        <ChevronRightIcon className={'comparison-link__icon'} />
        <p className={'comparison-link__text'}>Сопоставление данных</p>
      </div>
      <span className={'comparison-link__count'}>{count}</span>
    </div>
  );
};
