import { getRegistryTable } from './getRegistryTable';

/**
 * Получение Таблицы-Журнала ОГХ.
 *
 * @param {string} templateName - Имя шаблона.
 * @param {object} queryParams - Параметры запроса.
 * @returns {Promise<*>}
 */
export const getRegistryOgh = async (templateName, queryParams) => {
  const url = templateName ? `/${templateName}` : '';

  return getRegistryTable(`ogh${url}`, queryParams);
};
