 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Округ__.
 *
 * @type {{ accessor: 'okrug_name',  Header: 'Округ' }}
 * @augments Cell
 */
export const okrug_name = {
  accessor: 'okrug_name',
  Header: 'Округ',
  sortable: true,
};
