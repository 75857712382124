/* eslint-disable */
import React from 'react';
import { StringParam } from 'use-query-params';

import { INSTALL_DRNO_NAME, InstallDrnoFF } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
 
/**
 * Не подлежит оснащению ДРНО
 * installDrno
 */
 
export const InstallDrnoFilter = (props) => (
  <InstallDrnoFF {...props} className={filterInput} />
);

 
export { INSTALL_DRNO_NAME };

 
export const installDrnoParams = {
  [INSTALL_DRNO_NAME]: StringParam,
};
