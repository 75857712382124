import getDict from 'app/selectors/getDict';
import { Select } from 'core/uiKit/inputs/selects/Select';
import { TextField } from 'core/uiKit/inputs/TextField';
import { log } from 'core/utils/log';
import { get, parseInt } from 'lodash';
import PropTypes from 'prop-types';
/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';

import DialogAction from '../../common/DialogAction';

const propTypes = {
  showCondition: PropTypes.bool,
  table: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

const defaultProps = {
  title: 'Добавление записи',
};

class DialogAddAbutmentRecord extends React.Component {
  state = {
    abutment_type_id: '',
    distance: '',
    abutment_type_id_error: '',
    distance_error: '',
  };

  componentDidUpdate(prevProps) {
    const { showCondition } = this.props;
    if (prevProps.showCondition !== showCondition) {
      this.setState({
        abutment_type_id: '',
        distance: '',
        abutment_type_id_error: '',
        distance_error: '',
      });
    }
  }

  handleBeforeOk = () => {
    const { abutment_type_id, distance } = this.state;

    if (!abutment_type_id) {
      this.setState({
        abutment_type_id_error: 'Поле обязательно для заполнения',
      });
      return false;
    }

    if (parseInt(distance) === 0) {
      this.setState({
        distance_error: 'значение должно быть больше нуля',
      });
      return false;
    }

    if (!distance) {
      this.setState({
        distance_error: 'Поле обязательно для заполнения',
      });
      return false;
    }

    return true;
  };

  handleCancel = () => {
    const { onCancel } = this.props;
    onCancel();
  };

  handleOk = () => {
    const { onSubmit } = this.props;
    const { abutment_type_id, distance } = this.state;
    if (abutment_type_id && distance) {
      this.setState({ abutment_type_id: null, distance: null });
      onSubmit({
        abutment_type_id: { abutment_type: abutment_type_id },
        distance,
      });
    }
  };

  render() {
    const { abutmentTypes, showCondition, title } = this.props;
    return (
      <DialogAction
        className="Dialog-Add-Abutment-Record"
        showCondition={showCondition}
        title={title}
        onBeforeOk={this.handleBeforeOk}
        onCancel={this.handleCancel}
        onOk={this.handleOk}
      >
        <div style={{ width: '100%', marginBottom: '10px' }}>
          <Select
            withAll={false}
            errorText={this.state.abutment_type_id_error}
            id="abutment"
            label="Элемент сопряжения"
            options={
              abutmentTypes &&
              abutmentTypes.filter((item) => {
                const selectedAbutmentTypes = (this.props.table || []).map(
                  (e) => get(e, 'abutment_type_id.abutment_type'),
                );
                return selectedAbutmentTypes.indexOf(item.id) === -1;
              })
            }
            value={this.state.abutment_type_id || ''}
            onChange={(value) => {
              this.setState({
                abutment_type_id: value,
                abutment_type_id_error: '',
              });
            }}
          />
        </div>

        <TextField
          errorText={this.state.distance_error}
          id="quantity"
          label="Количество, п.м"
          value={this.state.distance}
          maxLength={15}
          onBlur={(event) => {
            const value = event.target.value;
            log.info(event);
            const number =
              value === '' || value === '-' ? '' : parseFloatWithComma(value);
            this.setState({ distance: number, distance_error: '' });
          }}
          onChange={(distance) => {
            if (isNumber(distance) && digitsCheck(distance, 2)) {
              this.setState({ distance, distance_error: '' });
            }
          }}
        />
      </DialogAction>
    );
  }
}

DialogAddAbutmentRecord.propTypes = propTypes;
DialogAddAbutmentRecord.defaultProps = defaultProps;

function digitsCheck(value, digits) {
  let check;
  if (digits === 0) {
    check = new RegExp('^\\d*$');
  } else {
    check = new RegExp(`^\\d*[,.]{0,1}\\d{0,${digits}}$`);
  }
  return check.test(`${value}`);
}

function parseFloatWithComma(value) {
  return parseFloat(`${value}`.replace(',', '.'));
}

function isNumber(value) {
  return /^\d*[,.]{0,1}\d*$/.test(`${value}`);
}

const getAbutmentTypes = getDict('abutmentTypes');

const mapStateToProps = (state) => ({
  abutmentTypes: getAbutmentTypes(state),
});

 
export default connect(mapStateToProps)(DialogAddAbutmentRecord);
