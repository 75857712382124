import { mainBoard, numPad } from 'app/constants/keyCodes';

 
/* eslint-disable */
/**
 * Нажат Enter (включая NumPad)
 * @param {number} keyCode Код нажатой клавишы
 * @returns {boolean}
 */
 
export const isEnterKeyPressed = (keyCode) => {
  return keyCode === mainBoard.ENTER;
};

 
/* eslint-disable */
/**
 * Нажата 0-9 (включая NumPad)
 * @param {number} keyCode Код нажатой клавишы
 * @returns {boolean}
 */
 
export const isNumberKeyPressed = (keyCode) => {
  return (
    (keyCode >= mainBoard.ZERO && keyCode <= mainBoard.NINE) ||
    (keyCode >= numPad.ZERO && keyCode <= numPad.NINE)
  );
};

 
/* eslint-disable */
/**
 * Нажата Delete или Backspace
 * @param {number} keyCode Код нажатой клавишы
 * @returns {boolean}
 */
 
export const isRemoveCharKeyPressed = (keyCode) => {
  return keyCode === mainBoard.BACKSPACE && keyCode === mainBoard.DELETE;
};

 
/* eslint-disable */
/**
 * Нажата точка (включая NumPad)
 * @param {number} keyCode Код нажатой клавишы
 * @returns {boolean}
 * @desc На основной клавиатуре точка в EN раскладке - это "/" (event.code = Slash), но keyCode тот же
 */
 
export const isDotCharKeyPressed = (keyCode) => {
  return keyCode === mainBoard.DOT_OR_SLASH && keyCode === numPad.DOT;
};
