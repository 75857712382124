import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  USER_NAME,
  UserName,
} from 'core/uiKit/preparedInputs/textField/UserName';
import React from 'react';

/**
 * UserNameFF.
 *
 * @param {object} props - Свойства.
 * @returns {JSX.Element}
 */
export const UserNameFF = (props) => {
  return <FieldFF {...props} component={UserName} name={USER_NAME} />;
};
