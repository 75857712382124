import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { useEditByStateIdAndCreatedFromExternalSystem } from 'app/components/card/ogh/index/card/hooks/useEditByStateIdAndCreatedFromExternalSystem';
import { useEditCheckStatus } from 'app/pages/cardsOgh/hooks/useEditStatus';
import { vi } from 'vitest';

vi.mock('app/api/hooks/useIsCurrentObjectIsExternalSystem');
vi.mock('app/api/hooks/useGetParentCard');
vi.mock('app/pages/cardsOgh/hooks/useEditStatus');

describe('🐛 UseEditByStateIdAndCreatedFromExternalSystemSpec', () => {
  it.each`
    IsCurrentObjectIsExternalSystem | stateId | editStatus | expected
    ${false}                        | ${2}    | ${false}   | ${true}
    ${true}                         | ${2}    | ${false}   | ${false}
    ${true}                         | ${1}    | ${false}   | ${true}
  `(
    '🧪 IsCurrentObjectIsExternalSystem $IsCurrentObjectIsExternalSystem stateId $stateId editStatus $editStatus',
    ({ IsCurrentObjectIsExternalSystem, editStatus, expected, stateId }) => {
      expect.hasAssertions();
      // ☣️ Arrange (всякие моки)
      useIsCurrentObjectIsExternalSystem.mockReturnValue(
        IsCurrentObjectIsExternalSystem,
      );
      useGetParentCard.mockReturnValue({ stateId });
      useEditCheckStatus.mockReturnValue(editStatus);
      // 🔥 Act

      // ❓ Assert
      expect(useEditByStateIdAndCreatedFromExternalSystem()).toBe(expected);
    },
  );
});
