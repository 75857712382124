import { getRegistryTable } from 'app/api/requests/getRegistryTable';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';

/**
 * Хук получения количества данных для таблиц реестров.
 *
 * @param {Array} queryKey - Данные для запроса.
 * @returns {*}
 */
export const useGetRegistryCount = (queryKey) => {
  const [key, params] = queryKey;
  return useQueryAdaptor([`${key}/count`, params], () =>
    getRegistryTable(`${key}/count`, params),
  );
};
