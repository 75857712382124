import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const ERZ_STATE_ID_NAME = 'erz_state_id';

/**
 * ## Состояние (из ЕРЗ).
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const ErzStateId = (props) => {
  // const dict = 'erzState';
  // const { data = [] } =useGetDictionary(dict);

  return (
    <Select
      {...props}
      label={'Состояние (из ЕРЗ)'}
      name={ERZ_STATE_ID_NAME}
      options={[]}
    />
  );
};
