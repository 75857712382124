import { get } from 'app/api/crud';

 
/* eslint-disable */
/**
 * получение Юзера по id
 * @param userId
 * @returns {Promise<*>}
 */
 
export const getUser = (userId) => get(`/users/${userId}`);
