import { SelectFF } from 'core/form/finalForm/fields';
import { usePreparation } from 'core/utils/hooks/usePreparation/preparation';
import { NumberParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

export const IN_YARD_NAME = 'inYard';

/**
 * Внутри дворовое inYard.
 *
 * @returns {JSX.Element} - JSX.
 */
export const InYardFilter = () => {
  const options = [
    { id: 1, name: 'Да' },
    { id: -1, name: 'Нет' },
  ];
  usePreparation(IN_YARD_NAME, (value) => value > 0);
  return (
    <SelectFF
      className={filterInput}
      {...{ options }}
      label={'Внутридворовое'}
      name={IN_YARD_NAME}
    />
  );
};

/**
 * @typedef {import('use-query-params').NumberParam} NumberParam
 */

/**
 * QueryParam __Внутри дворовое__.
 *
 * @returns {{}}
 */
export const inYardParams = () => {
  return {
    [IN_YARD_NAME]: NumberParam,
  };
};
