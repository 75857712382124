import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { RANDOM_WORD } from 'core/forTesting/constants';
import { TableSimple } from 'core/uiKit/components/tables/TableSimple/index';
import { shallow } from 'enzyme';
import React from 'react';
import { vi } from 'vitest';

import { BrIdLinkCell } from './cells/BrIdLink.Cell';
import { BrStatusIdCell } from './cells/BrStatusId.Cell';
import { ScheduleStatusIdLinkCell } from './cells/ScheduleStatusIdLink.Cell';
import { PassportizationTab } from './Passportization.Tab';

vi.mock('app/api/hooks/useGetCurrentCard');

describe('PassportizationTab', function () {
  it('test render passportization array', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetCurrentCard.mockReturnValue({ passportization: [RANDOM_WORD] });
    // Act
    const wrapper = shallow(<PassportizationTab />);

    // Assert
    expect(wrapper.props()).toStrictEqual({
      children: (
        <TableSimple
          data={[RANDOM_WORD]}
          columns={[
            {
              Cell: BrIdLinkCell,
              Header: 'Id заявки на обследование ОГХ',
              accessor: 'br_id',
            },
            {
              Cell: BrStatusIdCell,
              Header: 'Статус заявки на обследование ОГХ',
              accessor: 'br_status_id',
            },
            {
              Cell: ScheduleStatusIdLinkCell,
              Header: 'Id графика паспортизации ОГХ',
              accessor: 'schedule_id',
            },
            {
              Header: 'Статус графика паспортизации ОГХ',
              accessor: 'schedule_status_name',
            },
            {
              Header: 'Год паспортизации',
              accessor: 'schedule_passportization_year',
            },
          ]}
        />
      ),
      title: 'Паспортизация',
    });
  });
  it('test render passportization null', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetCurrentCard.mockReturnValue({ passportization: null });
    // Act
    const wrapper = shallow(<PassportizationTab />);

    // Assert
    expect(wrapper.props()).toStrictEqual({
      children: (
        <TableSimple
          columns={[
            {
              Cell: BrIdLinkCell,
              Header: 'Id заявки на обследование ОГХ',
              accessor: 'br_id',
            },
            {
              Cell: BrStatusIdCell,
              Header: 'Статус заявки на обследование ОГХ',
              accessor: 'br_status_id',
            },
            {
              Cell: ScheduleStatusIdLinkCell,
              Header: 'Id графика паспортизации ОГХ',
              accessor: 'schedule_id',
            },
            {
              Header: 'Статус графика паспортизации ОГХ',
              accessor: 'schedule_status_name',
            },
            {
              Header: 'Год паспортизации',
              accessor: 'schedule_passportization_year',
            },
          ]}
          data={[]}
        />
      ),
      title: 'Паспортизация',
    });
  });
});
