import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';

import { getObjectTypeCntOld } from '../api';
import { usePermissionsTypesOgh } from '../hooks/usePermissionsTypesOgh';
import { useFilterQueryParamsMain } from '../SearchPanel';
import { mapTypesOdh } from './mapTypesOdh';

/**
 * Хук получения табов типов в Списке объектов.
 *
 * @returns {{listOdhTypes: (*&{count})[]}}
 */
export const useSetupInterceptedTypes = () => {
  const { prepareFilters } = useFilterQueryParamsMain();
  const { data: groupCounts = [] } = useQueryAdaptor(
    ['Types', prepareFilters],
    getObjectTypeCntOld,
  );
  const typesPermissions = usePermissionsTypesOgh();
  const listOdhTypes = (typesPermissions || []).map((typeId) => {
    return {
      ...mapTypesOdh[typeId],
      count:
        (groupCounts || []).find(
          (item) => item.object_type_code === mapTypesOdh[typeId].code,
        )?.cnt || 0,
    };
  });

  return { listOdhTypes };
};
