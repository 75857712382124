import { actionTypes } from 'redux-form';
/* eslint-disable  */

/*
 * При создании дочерней карточки из родительской карточки,
 * при срабатывании экшна @@redux-form/DESTROY удалялись initialValues,
 * созданные для дочерней карточки (конфликт вызван тем, что родительская
 * и дочерняя карточки имеют одинаковое имя формы)
 *
 * Источник: https://github.com/redux-form/redux-form/issues/3435
 * */

const state = {};

/**
 *
 */
export default () => (next) => (action) => {
  switch (action.type) {
    case actionTypes.DESTROY:
      state[action.meta.form] = (state[action.meta.form] || 0) - 1;
      if (state[action.meta.form] <= 0) {
        return next(action);
      } else {
        // Drop the action
        return false;
      }
    case actionTypes.INITIALIZE:
      state[action.meta.form] = (state[action.meta.form] || 0) + 1;
      return next(action);
    default:
      return next(action);
  }
};
