/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */

import { CheckboxCell } from 'core/uiKit/components/cells/Checkbox.Cell';

/**
 * Колонка __Не учитывать при расчете уборочных площадей__.
 *
 * @type {{
 * accessor: 'no_calc',
 * Header: 'Не учитывать при расчете уборочных площадей',
 * sortable: true,
 * Cell: CheckboxCell
 * }}
 * @augments Cell
 */
export const no_calc = {
  Cell: CheckboxCell,
  Header: 'Не учитывать при расчете уборочных площадей',
  accessor: 'no_calc',
  sortable: true,
};
