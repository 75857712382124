import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';

/**
 * Справочник поля __Тип (наименование)__.
 *
 * @returns {{data: Array<{id: number, name: string, code: string, full_name: string}>}}
 */
export const useDictEngineerBuildingTypeId = () => {
  const dict = 'engineer_building_type';
  const { data } = useGetDictionary(dict);

  return { data };
};
