import { useCreateTheme } from 'core/uiKit/helpers';
/* eslint-disable */
const overrides = {
  MuiTableCell: {
    head: {
      color: '#000000',
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '1.25rem',
      backgroundColor: '#cbcbcb',
      position: 'sticky',
      top: 0,
    },
  },
  MuiTableContainer: {
    root: {
      overflowX: 'initial',
    },
  },
};

 
export const useCreateReactTableHeadTheme = () => {
  return useCreateTheme({ overrides });
};
