import { Validator } from 'core/form/Validator';
import { CLEANING_TYPE_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CleaningType/index';
import { COATING_GROUP_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingGroupId/index';

/**
 * Валидация в зависимости от выбора покрытия.
 *
 * @param {object} formValues - Значения формы.
 * @returns {object}
 */
export const validateWithTypeOfCoating = (formValues) => {
  // const errors = {};

  const validator = new Validator(formValues);

  if (
    formValues[COATING_GROUP_ID_NAME] === 2 &&
    formValues[CLEANING_TYPE_NAME] === 0
  ) {
    validator.addError(
      CLEANING_TYPE_NAME,
      'Механизированная уборка разрешена только для покрытий вида "Усовершенствованное"',
    );
  }

  validator.setRequired(CLEANING_TYPE_NAME);

  return validator.getErrors();
};
