import { AutocompleteAsync } from 'core/uiKit/inputs/selects/AutocompleteAsync';
import {
  addPreparation,
  removePreparation,
} from 'core/utils/hooks/usePreparation/preparation';
import React, { useEffect } from 'react';

import { fetchConsignee } from './api';

export const CONSIGNEE_NAME = 'consignee';

/**
 * ## Получатель ПГР.
 *
 * @param {*} props - Properties.
 * @returns {JSX.Element}
 */
export const Consignee = (props) => {
  useEffect(() => {
    addPreparation(CONSIGNEE_NAME, (value) => value.id);
    return () => removePreparation(CONSIGNEE_NAME);
  });

  return (
    <AutocompleteAsync
      label={'Получатель ПГР'}
      {...props}
      fetchFunction={fetchConsignee}
      name={CONSIGNEE_NAME}
    />
  );
};
