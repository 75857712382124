import { dialog } from 'app/constants/elementNames';
import { pathNewsNotes } from 'app/pages/newsNotes/NewsNotes/path.NewsNotes';
// @ts-ignore
import logo from 'assets/images/logo.png';
import React, { FC, memo } from 'react';

import DownloadDocumentation from './components/DownloadDocumentation';
import SetManualsMenu from './components/SetManualsMenu';
import SystemVersion from './components/SystemVersion';
import { DropDownLogo } from './DropDown';

interface LogoProps {
  isAuth: boolean;
  onShow: () => void;
  systemData: {
    backend_build_date: string;
    backend_version: string;
    db_execution_date: string;
    db_version: string;
  };
}

/**
 * Тултип для Логотипа в шапке страницы.
 *
 * @param props - Properties.
 * @param props.systemData - Системная информация.
 * @param props.isAuth - Флаг Авторизации.
 * @param props.onShow - Функция показать.
 * @returns JSX.Element.
 */
const Logo: FC<LogoProps> = ({
  systemData = {
    backend_build_date: '',
    backend_version: '',
    db_execution_date: '',
    db_version: '',
    execution_date: '12.01.2020',
  },
  isAuth,
  onShow,
}) => (
  <DropDownLogo
    className="brand-logo"
    id="nav-logo"
    img={logo}
    isAuth={isAuth}
    link="/"
    text="АСУ ОДС - Реестр объектов"
  >
    <div>
      <div className="helper-caption">
        Телефон горячей линии:&#xa0;<span>+7 (499) 281-69-42</span>
      </div>
      <div className="helper-caption">
        Е-mail:&#xa0;
        <a className="helper-caption" href="mailto:ods_support@mos.ru">
          ods_support@mos.ru
        </a>
      </div>
      <SystemVersion
        dbVersion={systemData.db_version}
        dbExecutionDate={systemData.db_execution_date}
        backendVersion={systemData.backend_version}
        backendBuildDate={systemData.backend_build_date}
      />
      {isAuth && (
        <a
          className="helper-caption"
          href="#"
          title="Сообщение в техподдержку"
          data-testid={'message_in_support'}
          onClick={(evnt) => {
            evnt.preventDefault();
            // @ts-ignore
            onShow(dialog.FEED_BACK)();
          }}
        >
          Сообщение в техподдержку
        </a>
      )}
    </div>
    <div>
      {isAuth && <SetManualsMenu />}

      <DownloadDocumentation
        fileName="template_menu"
        title="Шаблоны электронных паспортов"
      />

      {isAuth ? (
        <a className={'helper-caption'} href={'/' + pathNewsNotes.path}>
          {pathNewsNotes.name}
        </a>
      ) : null}
    </div>
  </DropDownLogo>
);

export default memo(Logo);
