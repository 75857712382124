/* eslint-disable */
import React from 'react';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

import { TextField } from 'core/uiKit/inputs';

 
export const CAD_NUMBER_NAME = 'cadNumber';

 
/* eslint-disable */
/**
 * Кадастровый номер ООП
 *
 * * `type = TextField`
 * * `name = cadNumber`
 * @param props
 * @returns {*}
 * @constructor
 */
/* eslint-disable */
export const CadNumber = (props) => {
  return (
    <TextField
      {...props}
      InputProps={{
        inputComponent: TextMaskCustom,
      }}
      label={'Кадастровый номер ООП'}
      name={CAD_NUMBER_NAME}
    />
  );
};

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      mask={['О', 'О', 'П', 'Т', '.', /\d/, '.', /\d/, /\d/]}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
    />
  );
}

CadNumber.propTypes = {
   
   
/**
   * выбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * функция вызова когда выберется итем
   */
  onChange: PropTypes.func,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
   
   
/**
   * значение
   */
  value: PropTypes.any,
};
