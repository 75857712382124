import './Tabs.Odh.scss';

import { useEditorCardSelector } from 'app/selectors/useSelectors/useEditorCard.Selector';
import { Tabs } from 'core/uiKit/components/Tabs';
import React from 'react';

import { tabs } from './tabs';

/**
 * TabsOdh.
 *
 * @param {object} props - Propertie.
 * @param {string} props.propertyTab - Активная таба.
 * @param {Function} props.onPropertyTab - Функция для изменения активной табы.
 * @returns {JSX.Element}
 */
export const TabsOdh = ({
  propertyTab,
  onPropertyTab,
}: {
  propertyTab: string;
  onPropertyTab: (tab: string) => void;
}) => {
  const { is_avd, is_orphan_object: isOrphanObject } = useEditorCardSelector();

  /**
   * Фильтр Показывать табу если выбран чекбокс Автомобильная дорога.
   *
   * @param {Array<{id: string, name:string}>} tabs - Табы.
   * @returns {Array<{id: string, name:string}>} - Фильтрованные табы.
   */
  const filterIsAvd = (tabs: { label: string; value: string }[]) => {
    return tabs.filter((tab) => {
      if (!is_avd) {
        return tab.value !== 'engineering-structures';
      }
      return true;
    });
  };

  const filterTabs = filterIsAvd(
    tabs.filter((item) => (isOrphanObject ? item?.isOrphanObject : true)),
  );

  return (
    <div className={'tabs-card'}>
      <Tabs
        value={propertyTab}
        onChange={onPropertyTab}
        tabs={filterTabs.slice(0, 5)}
      />
      <Tabs
        value={propertyTab}
        onChange={onPropertyTab}
        tabs={filterTabs.slice(5)}
      />
    </div>
  );
};
