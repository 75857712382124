import { useFilterQueryParamsYard } from 'app/pages/registry/ogh/ozn/Ozn/Filter.Ozn';
import { Button } from 'core/uiKit/components/buttons/Button';
import { Menu } from 'core/uiKit/components/Menu/Menu';
import { ExpandMoreIcon } from 'core/uiKit/material-ui';
import React, { useState } from 'react';

import * as api from './api';
import { ListItemOzn } from './ListItemOzn';
import { MenuItemOzn } from './MenuItemOzn';

/**
 * Компонент ReportsOzn.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - Тип ОГХ.
 * @returns {JSX.Element}
 */
export const ReportsOzn = ({ typeId }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const { prepareFilters } = useFilterQueryParamsYard();

  /**
   * Функция handleOpen.
   *
   * @param {Event} event - Event.
   * @returns {void}
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Функция handleClose.
   *
   * @returns {void}
   */
  const handleClose = () => {
    setAnchorEl(null);
  };
  const {
    sendOzn,
    loadingOzn,
    sendOznSummery,
    loadingSendOznSummery,
    sendOznShort,
    loadingSendOznShort,
  } = api.usePrintReport(typeId);

  return (
    <>
      <Button
        onClick={handleClick}
        variant="contained"
        color="primary"
        endIcon={<ExpandMoreIcon />}
      >
        Отчёты
      </Button>

      <Menu
        anchorEl={anchorEl}
        id="simple-menu"
        keepMounted={true}
        open={Boolean(anchorEl)}
        PaperProps={{
          style: {
            maxHeight: 450,
          },
        }}
        onClose={handleClose}
      >
        <MenuItemOzn text={'Общий отчёт по форме ТС'}>
          <ListItemOzn
            text={'Подробный'}
            onClick={() => sendOzn(prepareFilters)}
            loading={loadingOzn}
          />
          <ListItemOzn
            text={'Сводный'}
            onClick={() => sendOznSummery(prepareFilters)}
            loading={loadingSendOznSummery}
          />
          <ListItemOzn
            text={'Сокращённый'}
            onClick={() => sendOznShort(prepareFilters)}
            loading={loadingSendOznShort}
          />
        </MenuItemOzn>
      </Menu>
    </>
  );
};
