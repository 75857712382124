/**
 * Функция createObjGeoData.
 *
 * @param {object} params - Параметры.
 * @param {object} params.points - Точки.
 * @param {object} params.lines - Линии.
 * @param {object} params.polygons - Полигоны.
 * @param {object} params.hint - Хинты.
 * @param {Array} params.hint.object_attribute_hint - Описание.
 * @returns {*}
 */
export function createObjGeoData({
  points,
  lines,
  polygons,
  hint: { object_attribute_hint },
}) {
  const newValue = {
    geometry: {
      lines,
      points,
      polygons,
    },
  };

  object_attribute_hint.forEach(function (item) {
    newValue[item.key] = item.value;
  });
  return newValue;
}
