export const SEASON_SHIFT_AREA = 'season_shift_area';

export const SECTION_NUM = 'section_num';

export const GREEN_NUM = 'green_num';

export const TOTAL_AREA = 'total_area';

export const ANNUALS_AREA = 'annuals_area';
export const ANNUAL_VASE_AREA = 'annual_vase_area';
export const INERT_STONE_CHIPS_AREA = 'inert_stone_chips_area';
export const INERT_OTHER_AREA = 'inert_other_area';
export const INERT_TREE_BARK_AREA = 'inert_tree_bark_area';

export const PERENNIALS_AREA = 'perennials_area';

export const INERT_AREA = 'inert_area';

export const SHRUBS_AREA = 'shrubs_area';

export const APOTHECARY_GARDEN = 'apothecary_garden';

export const CARPET_PLANT_AREA = 'carpet_plant_area';

export const WITH_SHRUBS_AREA = 'with_shrubs_area';

export const BULBOUS_AREA = 'bulbous_area';

export const ONIONS_AND_CORMS = 'onions_and_corms';

export const TULIP_AREA = 'tulip_area';

export const BIENNIALS_AREA = 'biennials_area';
export const ROCKERY_AREA = 'rockery_area';
export const LAWN_AREA = 'lawn_area';

export const VASE_QTY = 'vase_qty';

export const FLOOR_VERTICAL_DESIGN_QTY = 'floor_vertical_design_qty';

export const FLOOR_VERTICAL_DESIGN_AREA = 'floor_vertical_design_area';

export const SUSPEND_VERTICAL_DESIGN_QTY = 'suspend_vertical_design_qty';

export const SUSPEND_VERTICAL_DESIGN_AREA = 'suspend_vertical_design_area';

export const FLOWERS_VERTICAL_DESIGN_QTY = 'flowers_vertical_design_qty';

export const FLOWERS_VERTICAL_DESIGN_AREA = 'flowers_vertical_design_area';

export const FLINDERS_AREA = 'flinders_area';
