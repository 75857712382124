import Link from 'app/components/common/misc/Link';
import { DropDown } from 'app/components/common/NavBar/DropDown/DropDown';
import PropTypes from 'prop-types';
import React from 'react';

/* eslint-disable */
export class DropDownLogo extends DropDown {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    img: PropTypes.string,
    link: PropTypes.string,
    isAuth: PropTypes.bool,
    text: PropTypes.string,
    style: PropTypes.object,
    id: PropTypes.string,
  };

  render() {
    const { className, img, link, isAuth, text, style, id } = this.props;

    return (
      <div className="dropdown-container" id={id}>
        <div className={className} style={style}>
          {isAuth ? (
            <Link to={link}>
              <img alt={text} src={img} />
              {text}
            </Link>
          ) : (
            <div style={{ cursor: 'default', userSelect: 'none' }}>
              <img alt={text} src={img} />
              {text}
            </div>
          )}
        </div>
        <div className="dropdown-content">{this.props.children}</div>
      </div>
    );
  }
}
