import { post } from 'app/api/crud/post';
import { GeoJSONCoordinatesFormatter } from 'core/uiKit/components/DTW/contexts/utils';
import { switchServisMap } from 'core/utils/map/switchServisMap';

/**
 * Конвертирует координаты в геожсон.
 * TODO: такая функция есть надо посмотреть чем отличается.
 *
 * @param {object} diffData - Ответ с бэка.
 * @returns {object} Ответ с бэка с форматированными координатами.
 */
export function formatResponseToGeoJSON(diffData) {
  const data = {
    ...diffData,
    copy_lines:
      diffData.copy_lines?.map((line) => ({
        ...line,
        coordinates: GeoJSONCoordinatesFormatter.polylineToGeoJSON(
          line.coordinates,
        ),
      })) || null,
    copy_points:
      diffData.copy_points?.map((point) => ({
        ...point,
        coordinates: GeoJSONCoordinatesFormatter.pointToGeoJSON(
          point.coordinates,
        ),
      })) || null,
    copy_polygons:
      diffData.copy_polygons?.map((polygon) => ({
        ...polygon,
        coordinates: GeoJSONCoordinatesFormatter.polygonToGeoJSON(
          polygon.coordinates,
        ),
      })) || null,
    original_lines:
      diffData.original_lines?.map((line) => ({
        ...line,
        coordinates: GeoJSONCoordinatesFormatter.polylineToGeoJSON(
          line.coordinates,
        ),
      })) || null,
    original_points:
      diffData.original_points?.map((point) => ({
        ...point,
        coordinates: GeoJSONCoordinatesFormatter.pointToGeoJSON(
          point.coordinates,
        ),
      })) || null,
    original_polygons:
      diffData.original_polygons?.map((polygon) => ({
        ...polygon,
        coordinates: GeoJSONCoordinatesFormatter.polygonToGeoJSON(
          polygon.coordinates,
        ),
      })) || null,
  };

  if (diffData && 'child_object' in diffData) {
    data.child_object = diffData.child_object
      ? formatResponseToGeoJSON(diffData.child_object)
      : undefined;
  }

  return data;
}

/**
 * Метод получения данных таба "Карта".
 *
 * @param {object} query - Параметры запроса.
 * @returns {Promise<*>} - Возвращает данные таба Карты.
 * @example
 * const { data = [], isLoading } = useQueryAdaptor(
 *     [
 *       'getMapTable',
 *       {
 *         etalon: etalon.id,
 *         copy: copy.id,
 *         typeId,
 *       },
 *     ],
 *     getMapTable,
 *   );
 */
export const getDataMapDiff = async (query) => {
  const response = await post('/versions/geodiff', query);
  const formatResponse = switchServisMap({
    dtw: formatResponseToGeoJSON,

    /**
     * Заглушка длля египа.
     *
     * @param {object} _ - Данные.
     * @returns {object}
     */
    egip: (_) => _,
  });
  return formatResponse(response);
};
