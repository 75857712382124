import isCreateMode from 'app/selectors/card/isCreateMode';
/* eslint-disable  */
import isEditMode from 'app/selectors/card/isEditMode';
import { filter } from 'lodash';
import { createSelector } from 'reselect';

/**
 *
 * @param state
 * @param props
 */
const getPlacementOptions = (state, props) => props.card.placement;

/**
 *
 * @param state
 */
const isThisViewing = (state) =>
  [isEditMode(state) || isCreateMode(state)].every((item) => !item);

export default createSelector(
  [getPlacementOptions, isThisViewing],
  (placement, viewing) => {
    let result;

    if (viewing) {
      return placement || [];
    }

    if (placement) {
      result = filter(
        placement,
        ({ code, id }) =>
          code === 'on_roadway' ||
          code === 'on_sidewalk' ||
          id === 20048 ||
          id === 20045,
      );
    } else {
      result = [];
    }

    return result;
  },
);
