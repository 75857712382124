export const columnsMovementCharacteristics = [
  {
    Header:
      'Местоположение створа по основной оси (в точке максимальной загрузки), м',
    accessor: 'odh_placement_axis',
    decimalScale: 2,
    name: 'odhPlacementAxis',
    rowSpan: 3,
  },
  {
    Header: 'Фактический состав движения, проценты:',
    columns: [
      {
        Header: 'Легковые автомобили и мотоциклы, микроавтобусы',
        accessor: 'fsd_la',
        decimalScale: 2,
        name: 'fsdLa',
        rowSpan: 2,
      },
      {
        Header: 'Грузовые автомобили грузоподъемностью (включительно), т',
        columns: [
          {
            Header: 'до 2',
            accessor: 'fsd_ga_befor_2',
            decimalScale: 2,
          },
          {
            Header: 'от 2 до 6',
            accessor: 'fsd_ga_2_6',
            decimalScale: 2,
          },
          {
            Header: 'от 6 до 8',
            accessor: 'fsd_ga_6_8',
            decimalScale: 2,
          },
          {
            Header: 'от 8 до 14',
            accessor: 'fsd_ga_8_14',
            decimalScale: 2,
          },
          {
            Header: 'свыше 14',
            accessor: 'fsd_ga_after_14',
            decimalScale: 2,
          },
        ],
        name: 'trucks',
      },
      {
        Header: 'Автопоезда грузоподъемностью (включительно), т',
        columns: [
          {
            Header: 'до 12',
            accessor: 'fsd_road_trainsga_before_12',
            decimalScale: 2,
          },
          {
            Header: 'от 12 до 20',
            accessor: 'fsd_road_trainsga_12_20',
            decimalScale: 2,
          },
          {
            Header: 'от 20 до 30',
            accessor: 'fsd_road_trainsga_20_30',
            decimalScale: 2,
          },
          {
            Header: 'свыше 30',
            accessor: 'fsd_road_trainsga_after_30',
            decimalScale: 2,
          },
        ],
        name: 'roadTrains',
      },
      {
        Header: 'Общественный транспорт',
        columns: [
          {
            Header: 'автобусы малой вместимости',
            accessor: 'fsd_ot_low_capacity',
            decimalScale: 2,
          },
          {
            Header: 'автобусы средней вместимости',
            accessor: 'fsd_ot_medium_capacity',
            decimalScale: 2,
          },
          {
            Header: 'автобусы большой вместимости',
            accessor: 'fsd_ot_large_capacity',
            decimalScale: 2,
          },
          {
            Header: 'автобусы сочлененные и троллейбусы',
            accessor: 'fsd_ot_articulated_buses_trolleybuses',
            decimalScale: 2,
          },
        ],
        name: 'publicTransport',
      },
    ],
    decimalScale: 2,
    name: 'actualCompositionOfMovement',
  },
  {
    Header:
      'Приведенная интенсивность движения к легковому автомобилю (в соответствии с СП 34.13330.2012, таблица 4.2), автомобилей в час пик суммарно в обоих направлениях (по фактическим измерениям)',
    accessor: 'intensity_la',
    decimalScale: 2,
    name: 'intensityLa',
    rowSpan: 3,
  },
  {
    Header: 'Примечание',
    accessor: 'traffic_character_list_note',
    name: 'trafficCharacterListNote',
    rowSpan: 3,
  },
  // Закомментировано в рамках ODS-11624
  // {
  //   accessor: 'edit',
  //   Header: '',
  //   rowSpan: 3,
  //   Cell: EditRowRFCell,
  // },
];

export const columnsMovementCharacteristicsMap =
  columnsMovementCharacteristics.reduce((acc, curr) => {
    return { ...acc, [curr.name]: curr };
  }, {});
