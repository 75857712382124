import { turnIntoCamelCase } from 'app/utils/api';
/* eslint-disable */

/**
 *
 * @param formValues
 */
export const snowAreaValidation = (formValues) => {
  const { snowArea, inboundArea } = turnIntoCamelCase(formValues);

  if (+snowArea > +inboundArea) {
    return {
      snow_area:
        '«Площадь вывоза снега, кв.м» не должно превышать значения параметра «Общая площадь в границах ОДХ, кв.м.»',
    };
  }
};
