import { PrintButton } from 'app/pages/components';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import { log } from 'core/utils/log';
import React from 'react';

import * as api from './api';
import { useFilterQueryParamsEquipmentReport } from './Filter.EquipmentReport';

/**
 * Кнопка печать.
 *
 * @returns {JSX.Element}
 */
export const PrintButtonEquipmentReport = () => {
  const { prepareFilters } = useFilterQueryParamsEquipmentReport();
  const [sendReport, { isLoading }] = useMutationAdaptor(
    api.printEquipmentReport,
  );

  log.info('isLoading ', isLoading);
  return (
    <PrintButton
      isLoading={isLoading}
      onClick={() => sendReport(prepareFilters)}
    >
      Печать
    </PrintButton>
  );
};
