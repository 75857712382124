import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { BuildingsTypeSpecRF } from 'core/form/reduxForm/fields/prepared/selects/simple/BuildingsTypeSpec.RF';
import React from 'react';

 
/**
 * Компонент фильтрации по по параметру parent_ogh_object_type_list.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - Возвращает компонет <BuildingsTypeSpecRF/>.
 * @example <BuildingsTypeSpecFilter/>
 */
export const BuildingsTypeSpecCard = (props) => {
  const parent = useGetParentCard();
  const { editMode } = useEditMode();
  const currentCard = useGetCurrentCard();

   
  /**
   * Фильрация по parent_ogh_object_type_list.
   *
   * @param {Array} dict - Массив опшинов.
   * @returns {Array} - Возвращает отфильтрованные опшины если есть typeId родителя.
   * @example <BuildingsTypeSpecRF
   *       filter={filter}
   *       withAll={false}
   *       required={true}
   *       {...props}
   *     />
   */
  const filter = (dict) => {
    const options = dict.filter(
      ({ ogh_object_type_id }) => ogh_object_type_id === currentCard.typeId,
    );

    if (parent.typeId) {
      return options.filter(({ parent_ogh_object_type_list }) =>
        parent_ogh_object_type_list.includes(parent.typeId),
      );
    }

    return options;
  };

  return (
    <BuildingsTypeSpecRF
      filter={filter}
      withAll={false}
      required={true}
      label={'Уточнение назначения'}
      disabled={!editMode}
      {...props}
    />
  );
};
