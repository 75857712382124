/* eslint-disable */
export function fetchWrapper(url, init) {
  // TODO: check response status first, return full response object if status == 201
  // TODO: check content-type to determine which promise to return: response.json() or response.text()
  return fetch(url, init).then((response) => {
    return response.json().then((json) => {
      return response.ok ? json : Promise.reject(json);
    });
  });
}
