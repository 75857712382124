import lazyWithPreload from 'lazy-with-preload';

const ImprovementObjectLazy = lazyWithPreload(() =>
  import('./ImprovementObject'),
);

 
export { ImprovementObjectLazy };
 
export { default } from './ImprovementObject';
