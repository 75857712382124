import { useFetchOghDispatch } from 'app/actions/odsObjectActions/useFetchOgh.Dispatch';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { queryClient } from 'app/routes/QueryClientProvider';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import { useState } from 'react';

import { DialogReject } from './Dialog.Reject';
import { RejectExternalObjectOghButton } from './RejectExternalObjectOgh.Button';

/**
 * Компонент "Отклонить внешний объект".
 *
 * @returns {JSX.Element}
 */
export const RejectExternalObject = () => {
  const { objectId } = useGetObjectId();

  const [isOpen, setOpen] = useState(false);
  const { editMode } = useEditMode();
  const fetchOgh = useFetchOghDispatch(objectId);

  /**
   * A.
   *
   * @returns {void}
   */
  const handleClickOpen = () => {
    setOpen(true);
  };

  /**
   * B.
   *
   * @returns {void}
   */
  const handleClose = () => {
    fetchOgh();
    setOpen(false);
    queryClient.invalidateQueries({ queryKey: ['getVisibilityButtonsOgh'] });
  };

  const show = !editMode && objectId;

  return show ? (
    <>
      <RejectExternalObjectOghButton
        objectId={objectId}
        onClick={handleClickOpen}
      />
      <DialogReject isOpen={isOpen} onClose={handleClose} />
    </>
  ) : null;
};
