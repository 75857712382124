/* eslint-disable  */
import { SkeletonMUI } from 'core/uiKit/material-ui';
import React from 'react';

/**
 * Подключение.
 *
 * ```js
 * import { Skeleton } from 'core/sadfa';
 * ```
 */

/**
 *
 * @param props
 */
export const Skeleton = (props) => <SkeletonMUI {...props} />;
