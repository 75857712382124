import { CardOgh } from 'types/Card';
import { Mock, vi } from 'vitest';

import { validate as validateAquaArea } from '../components/AquaAreaTab';
import { validate as validateSnowArea } from '../components/SnowAreaTab';
import { validateImprovementObject } from './validate.ImprovementObject';

vi.mock('../components/AquaAreaTab');
vi.mock('../components/SnowAreaTab');

/**
 * Mock Default.
 *
 * @returns {void}
 */
const mockDefault = () => {
  (validateAquaArea as Mock).mockReturnValue({ aa: 1 });
  (validateSnowArea as Mock).mockReturnValue({ bb: 1 });
};

describe('🐛 spec validate.ImprovementObject.spec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock

    //🔥 Act
    const res = validateImprovementObject({} as CardOgh);

    //❓ Assert
    expect(res).toStrictEqual({
      aa: 1,
      bb: 1,
    });
  });

  it('🧪 toCalled with', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock

    //🔥 Act
    // @ts-ignore
    validateImprovementObject({ ss: 'ss' });

    //❓ Assert
    expect(validateAquaArea).toHaveBeenCalledWith({ ss: 'ss' });
    expect(validateSnowArea).toHaveBeenCalledWith({ ss: 'ss' });
  });
});
