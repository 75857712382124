import './dialog.ApproveElectronicSignature.scss';

import { useGetFilesForSignature } from 'app/api/hooks/signs/useGetFilesForSignature/useGetFilesForSignature';
import { SAVE } from 'app/api/requests/signs/signOghSave';
import { DOCUMENT_FILE_NAME } from 'app/components/card/ogh/index/card/components/ApproveElectronicSignature/fields/Document.ApproveElectronicSignature.Field';
import { getCurrentDate } from 'app/utils/date/getCurrentDate';
import { ContinueFFDialog } from 'core/form/finalForm/components/dialogs/ContinueFF.Dialog';
import { SIGNATURES_NAME } from 'core/form/finalForm/fields/prepared/FileSignatureCryptoProFF';
import { Validator } from 'core/form/Validator';
import { Dialog } from 'core/uiKit/components/dialogs/Dialog';
import { LoaderCreateFiles } from 'core/uiKit/components/loaders/LoaderCreateFiles';
import { toast } from 'core/uiKit/components/Toast';
import { SEND_REASON_NAME } from 'core/uiKit/preparedInputs/selects/multiSelect/SendReason';
import { preparationValues } from 'core/utils/helpers/preparationValues';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import moment from 'moment';
import { useState } from 'react';

import { DialogIntersectionsApproveElectronicSignature } from './Dialog.Intersections.ApproveElectronicSignature';
import { FormApproveElectronicSignature } from './Form.ApproveElectronicSignature';
import { useSignOghSaveDialogApproveElectronicSignature } from './useSignOghSave.DialogApproveElectronicSignature';

/**
 * Validation fn.
 *
 * @param {object} values - Значения формы.
 * @returns {object|null}
 */
export const formValidation = (values) => {
  const validator = new Validator(values);
  validator.setCheckMinDateRelativeCurrentDate('startDate');
  validator.setRequired(SEND_REASON_NAME);

  validator.setRequired(
    SIGNATURES_NAME,
    'Выберите сертификат для подписи и нажмите подписать',
  );

  if (
    moment(values.startDate).startOf('day') >
    moment(getCurrentDate()).startOf('day')
  ) {
    validator.setRequired(DOCUMENT_FILE_NAME);
  }
  return validator.getErrors();
};

/**
 * Диалог подписания файлов.
 *
 * @param {object} root0 - Properties.
 * @param {Function} root0.onClose - Функция закрытия диалога.
 * @returns {React.ReactElement}
 */
export const DialogApproveElectronicSignature = ({ onClose }) => {
  const { objectId } = useGetObjectId();
  const [isIntersectionsOpen, setIntersectionsOpen] = useState(true);
  const [isIntersections, setIntersections] = useState(false);

  const { data = [] } = useGetFilesForSignature(objectId, SAVE, {
    enabled: isIntersections,

    /**
     * Калбек ошибки.
     *
     * @param {{message: string}} value - Ответ.
     * @returns {void}
     */
    onErrorCallback: (value) => {
      onClose();
      toast.error(value.message);
    },
  });

  const files = data?.map(({ file }) => file).filter((item) => !!item);

  const { sendSign, isLoadingSignOghSave } =
    useSignOghSaveDialogApproveElectronicSignature({
      objectCardFiles: data,
      objectId,
      onErrorCallback: onClose,
      onSuccessCallback: onClose,
      signType: SAVE,
    });

  /**
   * Fn.
   *
   * @returns {void}
   */
  const onCloseDialogIntersections = () => {
    setIntersectionsOpen(false);
    onClose();
  };

  /**
   * Fn.
   *
   * @returns {void}
   */
  const onContinueDialogIntersections = () => {
    setIntersections(true);
    setIntersectionsOpen(false);
  };

  /**
   * Функция сабмита.
   *
   * @param {object} values - Значения формы.
   * @returns {Promise}
   */
  const onSubmit = (values) => sendSign(preparationValues(values));

  return (
    <>
      <DialogIntersectionsApproveElectronicSignature
        onContinueDialogIntersections={onContinueDialogIntersections}
        onCloseDialogIntersections={onCloseDialogIntersections}
        isOpen={isIntersectionsOpen}
      />

      <Dialog
        isOpen={isIntersections && !files?.length}
        maxWidth={'xs'}
        textHeader={'Формируются файлы для подписания'}
        Body={<LoaderCreateFiles />}
      />

      <ContinueFFDialog
        {...{
          Body: <FormApproveElectronicSignature />,
          closeDialog: onClose,
          initialValues: { startDate: getCurrentDate() },
          isLoading: isLoadingSignOghSave,
          isOpen: !!files?.length && isIntersections,
          onSubmit,
          textHeader: 'Форма подписания файлов',
          validate: formValidation,
        }}
      />
    </>
  );
};
