import { shallow } from 'enzyme';
import React from 'react';
import { vi } from 'vitest';

import { ImprovementObjectCategoryIdFiledYard } from './ImprovementObjectCategoryId.Filed.Yard';
import { useSetupImprovementObjectCategoryIdFiledYard } from './useSetup.ImprovementObjectCategoryId.Filed.Yard';

vi.mock('./useSetup.ImprovementObjectCategoryId.Filed.Yard');
describe('🐛 spec ImprovementObjectCategoryId.Filed.Yard', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    useSetupImprovementObjectCategoryIdFiledYard.mockReturnValue({});

    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(<ImprovementObjectCategoryIdFiledYard />);

    //❓ Assert
    expect(wrapper.text()).toBe('<ImprovementObjectCategoryIdRF />');
  });
});
