import './ChangeButton.scss';

import { ContinueDialogHoc } from 'app/pages/cardsOgh/components/DIalogs/ContinueDialogHOC';
import { DialogEditButton } from 'app/pages/cardsOgh/components/RightPanelCard/components/DialogEditButton';
import { ListDataType } from 'app/pages/cardsOgh/components/RightPanelCard/components/DialogEditButton/listDataType';
import { useSetupChangeButton } from 'app/pages/cardsOgh/components/RightPanelCard/components/TreeRightPanel/TitleButton/components/ChangeButton/useSetupChangeButton';
import { Button } from 'core/uiKit/components/buttons/Button';
import React from 'react';

interface ChangeButtonType {
  columnTree: ListDataType;

  /**
   * Flag active button.
   */
  activeButton: boolean;

  /**
   * Code type.
   */
  typeCode: number | string;
}

/**
 * Кнопка изменения состава паспортов дочерних реестров.
 *
 * @param {object} props - Пропсы.
 * @param {ListDataType} props.columnTree - Данные списка.
 * @param props.activeButton - Active Button.
 * @param props.typeCode - Code type.
 * @returns
 */
export const ChangeButton = ({
  columnTree,
  activeButton,
  typeCode,
}: ChangeButtonType) => {
  const { closeDialogHandler, isShow, openDialog, openDialogHandler } =
    useSetupChangeButton({
      activeButton,
      typeCode,
    });

  return isShow ? (
    <>
      <Button
        onClick={openDialogHandler}
        color={'success'}
        variant={'contained'}
        className={'card__right_panel edit_button_tree'}
      >
        Изменить
      </Button>
      {openDialog ? (
        <ContinueDialogHoc>
          <DialogEditButton
            isOpen={openDialog}
            closeDialog={closeDialogHandler}
            listData={columnTree}
          />
        </ContinueDialogHoc>
      ) : null}
    </>
  ) : null;
};
