/* eslint-disable */
export default function submitCard(props, transformed) {
  const { mode, card, updateObject, createObject } = props;
  if ((mode && mode.createMode) || false) {
    createObject({
      type: card.type_id,
      ...transformed.interval,
      attribute: transformed.attribute,
    });
  } else {
    updateObject({
      id: card.record_id,
      record_id: card.record_id,
      ...(card.root_id && { rootId: card.root_id }),
      type: card.type_id,
      ...transformed.interval,
      attribute: transformed.attribute,
    });
  }
}
