/* eslint-disable */
import { shallow } from 'enzyme';

import { RANDOM_BOOL, RANDOM_ID, RANDOM_WORD } from 'core/forTesting/constants';
import Table from 'core/uiKit/components/tables/Table/Table';

import { ProcessApprovingTable } from './ProcessApprovingTable';
import { UseGetDataApprovalsTechTable } from './hooks/useGetDataApprovalsTechTable';
import { columnsProcessApproving } from './columnsProcessApproving';
import { ProcessApprovingFilter } from './ProcessApprovingTable.Filter';
import { WrapperCard } from './WrapperCard';

jest.mock('./hooks/useGetDataApprovalsTechTable');

/* eslint-disable */
describe('title ProcessApprovingTable', () => {
  it('render component', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    const TABLE_DATA = [{ id: RANDOM_ID, state_name: RANDOM_WORD }];
    const LOADING = RANDOM_BOOL;

    UseGetDataApprovalsTechTable.mockReturnValue({
      table: TABLE_DATA,
      total: 1,
      isLoading: LOADING,
    });

    // Act
    const wrapper = shallow(<ProcessApprovingTable />);

    // Assert
    expect(
      wrapper.contains(
        <WrapperCard>
          <ProcessApprovingFilter />
          <Table
            columns={columnsProcessApproving}
            data={TABLE_DATA}
            isLoading={LOADING}
            total={1}
          />
        </WrapperCard>,
      ),
    ).toBeTruthy();
  });
});
