import './movementCharacteristics.scss';

import { useEditorCardSelector } from 'app/selectors/useSelectors/useEditorCard.Selector';
import { TableSimple } from 'core/uiKit/components/tables/TableSimple';

import { TabBody } from '../TabBody';
import { columnsMovementCharacteristics } from './columns.MovementCharacteristics';

/**
 * Tab ХАРАКТЕРИСТИКИ ДВИЖЕНИЯ.
 *
 * @param {object} props - Tab properties.
 * @param {string} props.propertyTab - Активная таба.
 * @returns {JSX.Element}
 */
export const MovementCharacteristics = ({ propertyTab }) => {
  const { trafficCharacterList } = useEditorCardSelector();

  return (
    <TabBody
      show={propertyTab === 'movement-characteristics'}
      id={'movement-characteristics'}
    >
      <div className="overflow-auto">
        <TableSimple
          columns={columnsMovementCharacteristics}
          data={trafficCharacterList || []}
        />
        {/*Закомментировано в рамках ODS-11624*/}
        {/*<AddRowRF />*/}
      </div>
    </TabBody>
  );
};
