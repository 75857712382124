 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Дата подписания__.
 *
 * @type {{
 * accessor: 'sign_date',
 * Header: 'Дата подписания',
 * sortable: true,
 * }}
 * @augments Cell
 */
export const sign_date = {
  accessor: 'sign_date',
  Header: 'Дата подписания',
  sortable: true,
};
