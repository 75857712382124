import { Map } from '@2gis/mapgl/types';
import { circle } from 'app/services/mapService/pointMarkers';

import { Control, ShapeOptions } from '../../../../types.d';
import Shape from '../Shape';

/**
 * Иконка круга на карте.
 */
export default class Circle<Ctype extends Control> extends Shape<Ctype> {
  //

  /**
   * Конструктор.
   *
   * @param map - Карта.
   * @param options - Опции для маркера.
   */
  constructor(map: Map, options: ShapeOptions<Ctype>) {
    super(map, {
      icon: circle,
      ...options,
    });
  }
}
