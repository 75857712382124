import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const BUILDINGS_TYPE_SPEC_NAME = 'buildingsTypeSpecId';

/**
 * Селект BuildingsTypeSpec.
 *
 * `type = Select`.
 *
 * `name = buildingsTypeSpecId`
 * `name = buildings_type_spec_id`
 * `name = BUILDINGS_TYPE_SPEC_NAME`
 * `dict = 'buildingsTypeSpec'`.
 *
 *
 * ```js
 * import { BuildingsTypeSpec, BUILDINGS_TYPE_SPEC_NAME } from 'core/uiKit/preparedInputs';
 * ```.
 *
 * @param {object} props - Пропсы.
 * @returns {React.ReactComponentElement} - Жопа.
 * @example
 * <BuildingsTypeSpec {...props} />
 */
export const BuildingsTypeSpec = (props) => {
  const dict = 'buildingsTypeSpec';
  const { data = [] } = useGetDictionary(dict);

  return (
    <Select
      {...props}
      options={data}
      label={props.label || 'Уточнение типа'}
      name={BUILDINGS_TYPE_SPEC_NAME}
    />
  );
};
