/* eslint-disable */
import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import { EaisIdWithReduxForm } from './EaisIdWithReduxForm';
import { EAIS_ID_NAME } from 'core/uiKit/preparedInputs/textField/EaisId/index';

 
/* eslint-disable */
/**
 * Комментарий
 *
 * * `name = EAIS_ID_NAME`
 * @param props
 * @returns <Field {...otherProps} component={EaisIdWithReduxForm} name={EAIS_ID_NAME} />
 * @constructor
 */
/* eslint-disable */
export const EaisIdRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      component={EaisIdWithReduxForm}
      name={EAIS_ID_NAME}
    />
  );
};

EaisIdRF.propTypes = {
   
   
/**
   * быбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
/**
   * placeholder
   */
  placeholder: PropTypes.string,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
};
EaisIdRF.defaultProps = {
  disabled: false,
};
