import formatRootId from 'app/utils/formatRootId';
import { CreateTypeCell } from 'app/utils/getOghRegistryColumns/cells';


/* eslint-disable */
export default [
  {
    id: 'root_id',
    name: 'ID',
    Cell: (row) => formatRootId(row.value),
    width: 80,
  },
  {
    id: 'object_name',
    name: 'Наименование',
  },
  {
    id: 'customer_name',
    name: 'Заказчик',
  },
  {
    id: 'grbs_short_name',
    name: 'ГРБС',
  },
  {
    id: 'clean_category_name',
    name: 'Категория',
  },
  {
    id: 'clean_subcategory_name',
    name: 'Подкатегория',
  },
  {
    id: 'total_area',
    name: 'Общая площадь, кв.м.',
  },
  {
    id: 'is_orphan_object',
    name: 'Бесхозный',
  },
  {
    id: 'object_status_name',
    name: 'Статус',
    width: 110,
  },
  {
    id: 'create_type',
    name: 'Тип создания объекта',
    Cell: CreateTypeCell,
  },
  {
    id: 'start_date',
    name: 'Дата начала',
    width: 90,
  },
  {
    id: 'end_date',
    name: 'Дата окончания',
    width: 120,
  },
];
