interface IValues {
  maf_type_level1_id?: number | null;
  maf_type_level2_id?: number | null;
  maf_type_level3_id?: number | null;
  unit_id?: number | null;
  [key: string]: unknown;
}

interface ITemplate {
  maf_type_level1_id?: number | string;
  maf_type_level2_id?: number | string;
  maf_type_level3_id?: number | string;
  unit_id?: number | string;
}

/**
 * Проверить на соответствие шаблону.
 *
 * @param values - Значения полей формы.
 * @param template - Шаблон.
 * @returns Boolean.
 */
export function checkByTemplate(values: IValues, template: ITemplate) {
  let geometryTypeFound = true;

  for (const key in template) {
    // @ts-ignore
    if (Number(values[key]) === Number(template[key])) {
      continue;
    } else {
      geometryTypeFound = false;
      break;
    }
  }

  return geometryTypeFound;
}
