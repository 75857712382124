import { MapPointerEvent } from '@2gis/mapgl/types';
import { MouseEventHandler, useEffect } from 'react';

import { GeometryUtils } from '..';
import { MapService } from '../MapService';
import { RawGeometry } from '../types';

/**
 * Вешает обработчик клика мыши на карту, но функциональность заключается
 * в определении был ли клик по геометрии.
 *
 * @param mapUtils - Объект с методами и объектами для работы с картой.
 * @param mapUtils.isDisabled - Флаг отключения показа подсказки при клике.
 * @param mapUtils.mapService - Объект с методами для работы с картой.
 * @param mapUtils.onClickHintElementRef - Ссылка на элемент подсказки при клике.
 * @param mapUtils.onHoverHintElementRef - Ссылка на элемент подсказки при наведении.
 * @param mapUtils.currentHoveredGeometryRef - Ссылка на наведенную геометрию.
 * @param mapUtils.geometryUtils - Объект с методами для работы с геометриями.
 */
export const useShowHintOnClick = ({
  isDisabled = false,
  mapService,
  onClickHintElementRef,
  onHoverHintElementRef,
  geometryUtils,
  currentHoveredGeometryRef,
}: {
  isDisabled: boolean;
  mapService: MapService | null;
  onClickHintElementRef: React.MutableRefObject<{
    closeHint: VoidFunction;
    element: HTMLDivElement | null;
    setOnCloseClickHandler(cb: MouseEventHandler | null): void;
    // eslint-disable-next-line
    setPosition(clickedObject: RawGeometry | null, x: number, y: number): void;
  } | null>;
  onHoverHintElementRef: React.MutableRefObject<{
    closeHint: VoidFunction;
    element: HTMLDivElement | null;
    // eslint-disable-next-line
    setPosition(hoveredObject: RawGeometry | null, x: number, y: number): void;
  } | null>;
  currentHoveredGeometryRef: React.MutableRefObject<RawGeometry | null>;
  geometryUtils: GeometryUtils | null;
}) => {
  useEffect(() => {
    if (!mapService) return;

    if (isDisabled) {
      const clickedObject = mapService.clickedObject;
      mapService.setClickedObject(null);
      mapService?.setClickedGeometries(null);
      mapService?.drawClicked(null, clickedObject);
      onClickHintElementRef.current?.closeHint();
      return;
    }

    if (mapService.isMapLoaded && geometryUtils) {
      let shouldBlockClickedObjectChanging = false;

      onClickHintElementRef.current?.setOnCloseClickHandler(() => {
        const clickedObject = mapService.clickedObject;
        mapService.setClickedObject(null);
        mapService.setClickedGeometries(null);
        mapService.drawClicked(null, clickedObject);
      });

      // eslint-disable-next-line
      let [x, y] = [0, 0];
      let [coordsX, coordsY] = [0, 0];

      /**
       * Устанавливает позицию для подсказки.
       *
       * @param event - Объект события.
       * @param event.lngLat - Массив [x, y].
       */
      const setClickHintPosition = ({ lngLat }: MapPointerEvent) => {
        if (!geometryUtils) return;

        [coordsX, coordsY] = lngLat;
        const hovered = shouldBlockClickedObjectChanging
          ? mapService.clickedObject
          : mapService.hoveredObject;

        mapService.setClickedObject(hovered);

        if (!hovered) {
          // eslint-disable-next-line
          [x, y] = [0, 0];
        } else {
          // eslint-disable-next-line
          [x, y] = geometryUtils.coordinatesToPixels(lngLat);
        }

        // eslint-disable-next-line
        onClickHintElementRef.current?.setPosition(hovered, x, y);

        if (!shouldBlockClickedObjectChanging)
          currentHoveredGeometryRef.current = hovered;
      };

      /**
       * Обработчик.
       *
       */
      const handleMapHover = () => {
        setBlockingStateTrue();
        setClickHintPosition({ lngLat: [coordsX, coordsY] } as MapPointerEvent);
      };

      const currentClickHint = onClickHintElementRef.current;

      /**
       * Обработчик.
       *
       */
      const setBlockingStateTrue = () => {
        shouldBlockClickedObjectChanging = true;
      };

      /**
       * Обработчик.
       *
       */
      const setBlockingStateFalse = () => {
        shouldBlockClickedObjectChanging = false;
      };

      /**
       * Обработчик.
       *
       * @param evt - Объект события.
       */
      const handleClick = (evt: MapPointerEvent) => {
        // eslint-disable-next-line
        onHoverHintElementRef.current?.setPosition(null, x, y);
        setClickHintPosition(evt);
      };

      mapService.map.on('mousedown', setBlockingStateTrue);
      mapService.map.on('mouseup', setBlockingStateFalse);

      mapService.map.on('click', handleClick);
      mapService.map.on('move', handleMapHover);
      mapService.map.on('zoomend', handleMapHover);

      /**
       * Обработчик.
       *
       */
      const handleHintMouseEnter = () => {
        const hoveredObject = mapService.hoveredObject;
        mapService.setHoveredObject(null);
        mapService.setHoveredGeometries(null);
        mapService.drawHovered(null, hoveredObject);

        // eslint-disable-next-line
        onHoverHintElementRef.current?.setPosition(null, x, y);
      };

      const currentHint = onClickHintElementRef.current;
      currentHint?.element?.addEventListener(
        'mouseenter',
        handleHintMouseEnter,
      );

      return () => {
        currentClickHint?.setOnCloseClickHandler(null);
        currentHint?.element?.removeEventListener(
          'mouseenter',
          handleHintMouseEnter,
        );

        mapService.map.off('mousedown', setBlockingStateTrue);
        mapService.map.off('mouseup', setBlockingStateFalse);

        mapService.map.off('click', handleClick);
        mapService.map.off('move', handleMapHover);
        mapService.map.off('zoomend', handleMapHover);
      };
    }
  }, [
    isDisabled,
    geometryUtils,
    currentHoveredGeometryRef,
    mapService,
    onClickHintElementRef,
    onHoverHintElementRef,
  ]);
};
