/* eslint-disable */
import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { GROUP_ID_NAME, GroupId } from 'core/uiKit/preparedInputs';

 
 
/**
 * ## ## ID составного объекта
 *
 * ### React Final Form Field
 *
 * * `type = AutocompleteAsync`
 * * `name = groupId`
 */
/* eslint-disable */
export const GroupIdFF = ({ name, input, ...otherProps }) => {
  return (
    <Field name={GROUP_ID_NAME}>
      {({ input, ...props }) => {
        return <GroupId {...props} {...otherProps} {...input} />;
      }}
    </Field>
  );
};

 
export { GROUP_ID_NAME };

GroupIdFF.propTypes = {
   
   
/**
   *
   * Выбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
/**
   * placeholder
   */
  placeholder: PropTypes.string,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
};
GroupIdFF.defaultProps = {
  disabled: false,
};
