import { fetchTree } from 'app/pages/cardsOgh/components/RightPanelCard/components/TreeRightPanel/api/fetchTree';
/* eslint-disable */
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery/useMutationAdaptor';
import { useState } from 'react';

/**
 *
 */
export const useGetChildrenTreeMutation = () => {
  const [hash, setHash] = useState(null);

  const [fetch, data] = useMutationAdaptor(fetchTree, {
    /**
     *
     * @param _
     * @param params
     */
    onError: (_, params) => {
      setHash((pre) => ({ ...pre, [params.id]: { error: true } }));
    },

    /**
     *
     * @param data
     * @param params
     */
    onSuccess: (data, params) => {
      setHash((pre) => ({
        ...pre,
        [params.id]: { data, error: false, open: true },
      }));
    },
  });

  /**
   *
   * @param params
   */
  const fetchTreeChildren = (params) => {
    if (hash?.[params.id]) {
      setHash((pre) => ({
        ...pre,
        [params.id]: {
          data: pre[params.id].data,
          open: !pre[params.id].open,
        },
      }));
    } else {
      fetch(params);
    }
  };

  return { fetchTreeChildren, hash, ...data };
};
