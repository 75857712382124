import { filterInputData } from 'core/form/filterForm/components/filterInputsClasses';
import { KeyboardDatePickerFF } from 'core/form/finalForm/fields/default/datePickers/KeyboardDatePickerFF';
import {
  addPreparation,
  removePreparation,
} from 'core/utils/hooks/usePreparation/preparation';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useField } from 'react-final-form';

export const DATE_TO_NAME = 'dateTo';

/**
 * Date to.
 *
 * @param {object} root - Props.
 * @param {string} [root.label] - Label.
 * @param {object} [root.props] - Props.
 * @returns {Element}
 */
export const DateToFF = ({ label = 'Дата по', ...props }) => {
  const dateFromInput = useField('dateFrom');

  useEffect(() => {
    addPreparation(DATE_TO_NAME, (value) => {
      let newValue = value;
      if (typeof value === 'object' && moment(value).isValid()) {
        newValue = moment(value).format('DD.MM.YYYY');
      }
      return newValue;
    });
    return () => removePreparation(DATE_TO_NAME);
  });

  return (
    <KeyboardDatePickerFF
      minDate={dateFromInput?.input?.value}
      {...props}
      className={filterInputData}
      label={label}
      name={DATE_TO_NAME}
    />
  );
};
