/* eslint-disable  */
import Table from 'app/components/common/table/Table';
import TableAddButton from 'app/components/common/TableAddButton';
import TableDeleteButton from 'app/components/common/TableDeleteButton';
import TableErrorText from 'app/components/common/TableErrorText';
import DialogAddAbutmentRecord from 'app/components/dialogs/oghRegistry/common/DialogAddAbutmentRecord';
import Checkbox from 'core/newComponents/Checkbox';
import React from 'react';

/**
 * Функция getTableData.
 *
 * @param {Array} table - Таблица.
 * @param {string} abutmentTypes - Тип.
 * @returns {object}
 */
function getTableData(table, abutmentTypes) {
  const results = {
    table: {
      columns: [
        {
          cells: [],
          databaseName: null,
          key: 'abutment_type_id',
          screenName: 'Элемент сопряжения',
          sortable: false,
        },
        {
          cells: [],
          databaseName: null,
          key: 'distance',
          screenName: 'Количество, п.м',
          sortable: false,
        },
      ],
    },
  };
  table.forEach((item) => {
    results.table.columns.forEach((column) => {
      const columnKey = column.key;
      if (columnKey in item) {
        if (columnKey === 'abutment_type_id') {
          const abutmentTypeId = item.abutment_type_id.abutment_type;
          const abutmentTypeTmp =
            abutmentTypes.find((e) => e.id === abutmentTypeId) || {};

          column.cells.push(abutmentTypeTmp.name);
        } else {
          column.cells.push(item[columnKey]);
        }
      }
    });
  });
  return results;
}

/**
 * Таблица __Элементы сопряжения__.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export default function renderAbutmentList(props) {
  const table = (props.input.value && props.input.value.table) || [];
  const showDialog =
    (props.input.value && props.input.value.showDialog) || false;
  const results = getTableData(table, props.abutmentTypes);
  if (!props.disabled) {
    results.table.columns.unshift({
      cells: [],
      databaseName: null,
      key: 'delete',
      screenName: '',
      sortable: false,
    });
    table.forEach((item, index) => {
      results.table.columns[0].cells.push(
        <Checkbox
          checked={item.checked || false}
          onChange={function (event, value) {
            const character = { ...this.table[this.index], checked: value };
            const newTable = this.table.slice();
            newTable.splice(this.index, 1, character);
            this.onChange({ showDialog: false, table: newTable });
          }.bind({
            index: index,
            onChange: props.input.onChange,
            table: table,
          })}
        />,
      );
    });
  }
  return (
    <div style={{ marginBottom: '5px', ...props.style }}>
      <DialogAddAbutmentRecord
        showCondition={showDialog}
        table={table}
        onCancel={() => {
          props.input.onChange({ showDialog: false, table });
        }}
        onSubmit={(record) => {
          props.input.onChange({
            showDialog: false,
            table: [...table, record],
          });
        }}
      />
      <TableAddButton
        isError={props.meta && props.meta.error}
        title="Добавление записи"
        visible={!props.disabled}
        onAdd={() => {
          props.input.onChange({ showDialog: true, table });
        }}
      />
      <TableDeleteButton
        disabled={
          !table.some((item) => {
            return item.checked;
          })
        }
        visible={!props.disabled}
        onRemove={() => {
          const newTable = table.filter((item) => {
            return !item.checked;
          });
          props.input.onChange({ showDialog: false, table: newTable });
        }}
      />
      <TableErrorText
        errorText={(props.meta && props.meta.error) || null}
        visible={!props.disabled}
      />
      <Table
        {...results}
        height={table.length * 50 + 60}
        width={1150 + (props.disabled ? 0 : 50)}
        widths={(name) => {
          return name === '' ? 50 : 575;
        }}
      />
    </div>
  );
}
