import './noMatch.scss';

import error from 'assets/images/error.png';
import { Button, Card } from 'core/uiKit/components';
import { CardContent } from 'core/uiKit/material-ui';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

/* eslint-disable */
/**
 *
 */
export default function NoMatch() {
  const r = useMemo(() => document.querySelector(':root'), []);

  const contHandler = (e) => {
    let x = (e.clientX - window.innerWidth / 2) / 200;
    let y = (e.clientY - window.innerHeight / 2) / 300;
    r.style.setProperty('--posX', Math.round(-x));
    r.style.setProperty('--posY', Math.round(-y));
  };

  return (
    <div onMouseMove={contHandler} className={'no-match__container'}>
      <div className={'mouse-parallax__bg'} />

      <Card id="scene" className="no-match mouse-parallax__body" elevation={24}>
        <CardContent className="no-match__wrapper mx-5 my-3">
          <img
            className={'hidden-lg-down'}
            alt="Страница не найдена"
            src={error}
          />
          <noscript>
            <img src={error} alt="Страница не найдена" />
          </noscript>
          <div className="no-match__content">
            <h1 className="no-match__title">404</h1>
            <h2 className="no-match__subtitle">
              Вы попали на несуществующую страницу
            </h2>
            <p className="no-match__descr">
              К сожалению, запрашиваемая Вами страница не найдена. Вероятно, Вы
              указали несуществующий адрес, страница была удалена, перемещена
              или сейчас она временно недоступна!
            </p>
            <Button
              component={Link}
              to={'/'}
              className={''}
              variant={'contained'}
              color={'primary'}
              size={'large'}
            >
              На главную
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
