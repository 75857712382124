import { SaveFFDialog } from 'core/form/finalForm/components';
import { KeyboardDatePickerFF } from 'core/form/finalForm/fields/default/datePickers/KeyboardDatePickerFF';
import { TextFieldFF } from 'core/form/finalForm/fields/default/TextFieldFF';
import { AddButton } from 'core/uiKit/components/buttons';
import React from 'react';

import { useSetupAddButtonRepairsPlanTab } from './useSetup.Add.Button.RepairsPlan.Tab';

/**
 * Кнопка добавления __Проекта__.
 *
 * @returns Element.
 */
export const AddButtonRepairsPlanTab = () => {
  const { editMode, isOpen, onSubmit, setIsOpen } =
    useSetupAddButtonRepairsPlanTab();

  return editMode ? (
    <>
      <AddButton onClick={() => setIsOpen(true)} />
      <SaveFFDialog
        isOpen={isOpen}
        closeDialog={() => setIsOpen(false)}
        textHeader="Добавить сведение о ремонтах"
        Body={
          <>
            <TextFieldFF name="name" label="Наименование" />
            <KeyboardDatePickerFF name="date" label="Дата" />
            <TextFieldFF name="property" label="Характеристика (объемы)" />
            <KeyboardDatePickerFF
              name="plan_time"
              label="Планируемое время проведения работ"
            />
          </>
        }
        initialValues={{}}
        onSubmit={onSubmit}
      />
    </>
  ) : null;
};
