import { useDecorateOnload } from './useDecorateOnload';
import { useGetDTWTokens } from './useGetDTWTokens';
import { useLoginToDTW } from './useLoginToDTW';
import { useRefreshDTWTokens } from './useRefreshDTWTokens';

export type UseGetDTWTokens = typeof useDTWAuth;
export type UseDTWAuthResult = ReturnType<UseGetDTWTokens>;

/**
 * Хук для авторизации в цифровом двойнике.
 *
 * @returns Результаты запросов логина и рефреша токенов в цифровом двойнике.
 */
export const useDTWAuth = () => {
  const { accessToken, getAccessToken, refreshToken, getRefreshToken } =
    useGetDTWTokens();

  const {
    refetch,
    isLoading: isLoginLoading,
    isError: isLoginError,
    error: loginError,
  } = useLoginToDTW();

  const [
    ,
    {
      mutateAsync,
      isLoading: isRefreshLoading,
      isError: isRefreshError,
      error: refreshError,
    },
  ] = useRefreshDTWTokens();

  // @ts-ignore
  useDecorateOnload(async (token) => {
    try {
      return await mutateAsync(token);
    } catch {
      return await refetch();
    }
  });

  return {
    accessToken,
    getAccessToken,
    getRefreshToken,
    isLoginError,
    isLoginLoading,
    isRefreshError,
    isRefreshLoading,
    loginError,
    refreshError,
    refreshToken,
  };
};
