/* eslint-disable  */
import { IdRfid } from 'core/uiKit/preparedInputs/textField/IdRfid';
import React from 'react';

/**
 *
 * @param root0
 * @param root0.input
 */
export const IdRfidWithReduxForm = ({ input, ...props }) => {
  return <IdRfid {...input} {...props} />;
};
