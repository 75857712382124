import { MenuItem } from 'core/uiKit/components/Menu/MenuItem';
import {
  Collapse,
  ExpandLessIcon,
  ExpandMoreIcon,
  List,
} from 'core/uiKit/material-ui';
import React, { useState } from 'react';

/**
 * Компонент MenuItemOzn.
 *
 * @returns {JSX.Element}
 */
export const MenuItemOzn = React.forwardRef(({ text, children }) => {
  const [open, setOpen] = useState(false);

  /**
   * Функция handleOpen.
   *
   * @returns {void}
   */
  const handleOpen = () => setOpen((pre) => !pre);

  return (
    <>
      <MenuItem onClick={handleOpen}>
        <span className={'mr-2'}>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </span>
        {text}
      </MenuItem>
      <Collapse in={open}>
        <List>{children}</List>
      </Collapse>
    </>
  );
});
