import { createTheme } from 'core/uiKit/material-ui';

/**
 * Создаем стили в js.
 *
 * @param {*} params - Parameters.
 * @param {boolean} params.dark - Выбор темной темы.
 * @returns {{}}
 */
const createPaperTheme = ({ dark }) => {
  const palette = {};

  if (dark) {
    palette.mode = 'dark';
  }

  return createTheme({
    overrides: {
      MuiIconButton: {
        root: {},
      },
    },
    palette,
  });
};

/**
 * Хук получения стилей для Paper.
 *
 * @param {*} params - Parameters.
 * @param {boolean} params.dark - Выбор темной темы.
 * @returns {{}}
 */
export const useCreatePaperTheme = ({ dark }) => {
  return createPaperTheme({ dark });
};
