import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useFieldRF } from 'core/form/reduxForm/hooks/useField.RF';
import { RANDOM_NUMBER } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import {
  MARGIN_STRENG_TYPE_ID_NAME,
  MarginStrengTypeIdField,
} from './MarginStrengTypeId.Field';

vi.mock('app/api/hooks/useGetCurrentCard');
vi.mock('app/api/hooks/useIsEditCurrentObjectIsExternalSystem');
vi.mock('core/form/reduxForm/hooks/useField.RF');
vi.mock('app/pages/cardsOgh/hooks/useMode');

describe('🐛 MarginStrengTypeIdFieldSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useGetCurrentCard.mockReturnValue({
      odh_side: [],
      typeId: RANDOM_NUMBER,
    });
    useMode.mockReturnValue({ editMode: false });
    useIsEditCurrentObjectIsExternalSystem.mockReturnValue(true);
    useFieldRF.mockReturnValue({ value: undefined });

    // 🔥 Act
    const wrapper = shallow(<MarginStrengTypeIdField />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<SelectRF />');
  });
  it('🧪 props', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useGetCurrentCard.mockReturnValue({
      odh_side: [],
      typeId: RANDOM_NUMBER,
    });
    useMode.mockReturnValue({ editMode: false });
    useIsEditCurrentObjectIsExternalSystem.mockReturnValue(true);
    useFieldRF.mockReturnValue({ value: undefined });

    // 🔥 Act
    const wrapper = shallow(<MarginStrengTypeIdField />);

    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      disabled: true,
      label: 'Тип укрепления (уточнение)',
      name: MARGIN_STRENG_TYPE_ID_NAME,
    });
  });
});
