import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import PropTypes from 'prop-types';

/**
 * @typedef {'maf_type_level2_id'} MafTypeLevel2Name
 */

/**
 * Name inputs. Уточнение.
 *
 * @constant
 * @type {MafTypeLevel2Name}
 */
export const MAF_TYPE_LEVEL_2_ID_NAME = 'maf_type_level2_id';

/**
 * Select Учредитель/ГРБС использует словарь grbs.
 * `name` - grbsId.
 *
 * @param {object} props - Properties.
 * @param {string} [props.label] - Лейбл.
 * @param {object} props.otherProps - Properties.
 * @returns {JSX.Element} - JSX.
 * @example --
 */
export const MafTypeLevel2 = ({ label = 'Уточнение', ...otherProps }) => {
  const dict = 'mafTypeLevel2';
  const { data = [], isLoading } = useGetDictionary(dict);
  const options = data.map((item) => ({
    id: item.id,
    mafTypeLevel1: (item?.attribute?.maf_type_list || []).map(
      (item) => item.maf_type_level1_id.maf_type_level1,
    ),
    name: item.attribute.name,
  }));
  return (
    <Select
      {...otherProps}
      {...{
        isLoading,
        label,
        name: MAF_TYPE_LEVEL_2_ID_NAME,
        options,
      }}
    />
  );
};

MafTypeLevel2.propTypes = {
  dark: PropTypes.bool,

  /**
   * Лейбл.
   */
  label: PropTypes.string,
  value: PropTypes.any,
};
