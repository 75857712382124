/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import { MUITable } from './MUITable';
import { Pagination, Paper } from 'core/uiKit/components';

import './style.scss';

 
/* eslint-disable */
/**
 * ## Таблица
 *  @deprecated
 *  @see import { Table, useParamsTable } from 'core/uiKit/components/tables/Table';
 * #### подключение
 * ```js
 * import { ReactTable } from 'core/sdfaf';
 * ```
 */
 
export const ReactTable = ({
  checkedAll,
  checkedRows,
  columns = [],
  data = [],
  divClassName = 'd-flex flex-column h-100 relative common-table-multiTable_multiTable_3fYcL',
  isCheckedRow,
  isLoading,
  onRowDblClick,
  onSelectAllClick,
  onSelectRow,
  pageCount = 0,
  sortableColumns: sortable = [],
  total,
  trClassName = '',
}) => {
  let sortableColumns = sortable;
  if (sortable.length === 0) {
    sortableColumns = columns
      .filter((column) => column.sortable)
      .map((item) => item.accessor);
  }

  return (
    <div
      className={
        'd-flex flex-column h-100 overflow-hidden w-100'
      }
    >
      <div className={divClassName}>
        <Paper style={{ overflowX: 'auto' }}>
          <MUITable
            isLoading={isLoading}
            checkedAll={checkedAll}
            checkedRows={checkedRows}
            columns={columns}
            data={data}
            isCheckedRow={isCheckedRow}
            pageCount={pageCount}
            sortableColumns={sortableColumns}
            trClassName={trClassName}
            onRowDblClick={onRowDblClick}
            onSelectAllClick={onSelectAllClick}
            onSelectRow={onSelectRow}
          />
        </Paper>
      </div>
      <Pagination total={total} />
    </div>
  );
};
ReactTable.propTypes = {
   
   
/**
   * Данные для формирования заголовка таблицы и колонок
   */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
       
       
/**
       * Вывод этого в качестве заголовка
       */
      Header: PropTypes.string,
       
       
/**
       * Имя поля по которому формируется столбец
       */
      accessor: PropTypes.string,
       
       
/**
       * разрешает сортировку по столбцу
       */
      sortable: PropTypes.bool,
    }),
  ),
   
   
/**
   * Сами данные таблицы
   */
  data: PropTypes.arrayOf(PropTypes.object),
   
   
/**
   * Общее количество записей
   */
  total: PropTypes.number,
   
  /* eslint-disable */
/**
   * Массив названий полей по которым разрешить сортировку
   */
  sortableColumns: PropTypes.arrayOf(PropTypes.string),
   
  /* eslint-disable */
/**
   * Флаг указывающий что выполняется запрос на сервер
   */
  isLoading: PropTypes.bool,
};
