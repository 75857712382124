import './Panel.scss';

import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { LINESTRING, POINT, POLYGON } from 'app/constants/geometryTypes';
import { useGetAllowedGeometryTypesRF } from 'app/pages/cardsOgh/components/Map/MapEditPanel/hooks/useGetAllowedGeometryTypes';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useMapglEditorContext } from 'core/uiKit/components/DTW/contexts/MapglEditorContextProvider';
import {
  GeometryTypes,
  Layers,
} from 'core/uiKit/components/DTW/contexts/utils/types.d';
import { useState } from 'react';

/**
 * Setup for Панель редактирования карты.
 *
 * @returns JSX.
 */
export const useSetupMapEditPanelDTW = () => {
  const context = useMapglEditorContext();
  const [activeGeometryType, setActiveGeometryType] = useState(null);
  const isCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();
  const currentCard = useGetCurrentCard();
  const { editMode } = useMode();
  const allowedGeometryTypes = useGetAllowedGeometryTypesRF();

  const editable = editMode && isCurrentObjectIsExternalSystem;

  // useEffect(() => {
  //   const activeGeometryNotAllowed =
  //     activeGeometryType &&
  //     !isEmpty(allowedGeometryTypes) &&
  //     !allowedGeometryTypes.includes(activeGeometryType);
  //
  //   const exitEditMode = editable && !context.editable;
  //
  //   if (activeGeometryNotAllowed || exitEditMode) {
  //     const isCanceled = activeGeometryType !== GeometryTypes.Point;
  //     stopDrawing(isCanceled);
  //
  //     if (exitEditMode) {
  //       context.cancelEditing();
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [allowedGeometryTypes, editable, activeGeometryType, context]);
  //

  /**
   * Стоп редактирование.
   *
   * @param isCanceled - IsCanceled.
   */
  const stopDrawing = (isCanceled = true) => {
    // @ts-ignore
    context.setIsDrawing(false);
    if (isCanceled) {
      // @ts-ignore
      context.mapService.cancelDrawing();
    } else {
      // @ts-ignore
      context?.mapService?.drawService?.finishDrawingHandler();
    }
    setActiveGeometryType(null);
    // context.cancelEditing();
  };

  /**
   * Функция обработцик старта редактирования.
   *
   * @param active - Активный.
   * @param geometryType - Тип геоментии.
   * @param layerType - Тип лайера.
   */
  const onDrawClick = (
    active: boolean,
    geometryType: GeometryTypes,
    layerType: Layers,
  ) => {
    if (!active) {
      if (geometryType !== activeGeometryType) {
        const isCanceled = activeGeometryType !== GeometryTypes.Point;
        stopDrawing(isCanceled);
        // @ts-ignore
        context.mapService.enableEditing({}, false);
      }

      let coordinates = [];
      let id = 'drawing';
      if (geometryType === GeometryTypes.Point) {
        const point =
          // @ts-ignore
          context.mapService.geometriesData[
            // @ts-ignore
            context.mapService.selectedLayerType
          ].point[0];
        if (point) {
          coordinates = point.options.userData.coordinates;
          id = point.options.userData.id;
        }
      }

      // @ts-ignore
      setActiveGeometryType(geometryType);

      // @ts-ignore
      context.mapService.finishEditing();
      // @ts-ignore
      context.mapService.editService.disableEditing();
      // @ts-ignore
      context.setIsDrawing(true);

      // @ts-ignore
      context.startDrawingGeometry(geometryType, {
        coordinates,
        // @ts-ignore
        mapService: context.mapService,

        /**
         * OnDrawFinish.
         *
         */
        onDrawFinish: () => {
          stopDrawing();
          // @ts-ignore
          context.mapService.enableEditing({}, false);
        },

        // @ts-ignore
        userData: {
          coordinates,
          id,
          layerType: layerType || 'parent',
          // @ts-ignore
          mapService: context.mapService,
          // @ts-ignore
          type: geometryType,
        },
      });
    } else {
      const isCanceled = activeGeometryType !== GeometryTypes.Point;
      stopDrawing(isCanceled);
      // @ts-ignore
      context.mapService.enableEditing({}, false);
    }
  };

  /**
   * GetGeometry.
   *
   * @returns Geometry.
   */
  const getGeometry = () => {
    // @ts-ignore
    return context.getGeometries(currentCard.record_id);
  };

  /**
   * DeleteGeometry.
   *
   */
  const deleteGeometry = () => {
    // @ts-ignore
    context.deleteGeometry();
  };

  // @ts-ignore
  const polygonAllowed = allowedGeometryTypes.includes(POLYGON);
  // @ts-ignore
  const pointAllowed = allowedGeometryTypes.includes(POINT);
  // @ts-ignore
  const lineAllowed = allowedGeometryTypes.includes(LINESTRING);
  return {
    activeGeometryType,
    context,
    currentCard,
    deleteGeometry,
    editable,
    getGeometry,
    lineAllowed,
    onDrawClick,
    pointAllowed,
    polygonAllowed,
  };
};
