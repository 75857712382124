import { DISTRICT_AL_NAME } from 'core/uiKit/preparedInputs/selects/autocompleteAsync/DistrictAl';
import { OKRUG_AL_NAME } from 'core/uiKit/preparedInputs/selects/autocompleteAsync/OkrugAl';
import {
  STREET_AL_NAME,
  StreetAl,
} from 'core/uiKit/preparedInputs/selects/autocompleteAsync/StreetAl';
import React, { FC, useCallback } from 'react';

import { AddressFieldProps } from '../../types';

/**
 * Улица для renderAddressList2.
 *
 * @param props - Properties.
 * @param props.address - Значение формы.
 * @param props.setAddress - Функция изменения глобальной формы.
 * @param props.errors - Объект ошибок.
 * @returns {JSX}
 */
export const StreetAlRAL2: FC<AddressFieldProps> = ({
  address,
  setAddress,
  errors,
}) => {
  const onChangeDistrictAl = useCallback(
    (value: { id: number; name: string } | undefined) => {
      setAddress({
        [DISTRICT_AL_NAME]: address[DISTRICT_AL_NAME],
        [OKRUG_AL_NAME]: address[OKRUG_AL_NAME],
        [STREET_AL_NAME]: value,
      });
    },

    // eslint-disable-next-line
    [
      // eslint-disable-next-line
      address[DISTRICT_AL_NAME],
      // eslint-disable-next-line
      address[OKRUG_AL_NAME],
      // eslint-disable-next-line
      address[STREET_AL_NAME],
    ],
  );

  return (
    <StreetAl
      required={true}
      errorText={errors[STREET_AL_NAME]}
      district_id={address[DISTRICT_AL_NAME]?.id || 0}
      value={address[STREET_AL_NAME]}
      onChange={onChangeDistrictAl}
      disabled={!address[DISTRICT_AL_NAME]}
    />
  );
};
