import { MapPointerEvent } from '@2gis/mapgl/types';
import { useEffect } from 'react';

import { GeometryUtils } from '..';
import { MapService } from '../MapService';
import {
  GeometryTypes,
  Layers,
  Marker,
  Polygon,
  Polyline,
  RawGeometry,
} from '../types.d';

export const ORDERED_LAYERS: Layers[] = [
  'parent',
  'selected',
  'children',
  'allChildren',
  'intersections',
  'adjacent',
  'reonArea',
  'districts',
  'copyDiff',
  'originalDiff',
];

const REVERSED_ORDERED_LAYERS = [...ORDERED_LAYERS].reverse();

/**
 * Вешает обработчик ховера на карту.
 *
 * @param utilParams - Параметры для хука.
 * @param utilParams.isDisabled - Флаг отключения клик эвента.
 * @param utilParams.mapService - Объект управления картой.
 * @param utilParams.geometryUtils - Объект с методами для работы с геометриями.
 */
export const useHandleGeometryClickEvent = ({
  isDisabled = false,
  mapService,
  geometryUtils,
}: {
  isDisabled: boolean;
  mapService: MapService | null;
  geometryUtils: GeometryUtils | null;
}) => {
  useEffect(() => {
    if (isDisabled || !mapService?.isMapLoaded) return;

    if (mapService && mapService.isMapLoaded) {
      let currentClickedObjects: (RawGeometry | null)[];

      /**
       * Обработчик движения мыши по карте. Не срабатывает при наведении на маркеры.
       *
       * @param evt - Объект события.
       * @returns {void} Void.
       */
      const handleMouseClick = (evt: MapPointerEvent): void => {
        if (!geometryUtils) return;

        let isClicked = false;
        const { lngLat } = evt;
        const { layers } = mapService;

        currentClickedObjects = [];

        for (const layer of REVERSED_ORDERED_LAYERS) {
          // Проверяем наведен ли курсор на маркер

          layers[layer][GeometryTypes.Marker].forEach((marker) => {
            if (!marker) return false;

            const clicked = geometryUtils.isPointWithinPolygon(
              lngLat,
              marker.userData.coordinatesToCheckMouseEvent,
            );
            if (clicked) currentClickedObjects.push(marker);
          });

          isClicked = !!currentClickedObjects.length;
          if (isClicked) break;

          // Проверяем наведен ли курсор на линию

          layers[layer][GeometryTypes.Polyline].forEach((line) => {
            if (!line) return false;

            const clicked = geometryUtils.isPointOnLine(
              lngLat,
              line.userData.coordinates,
            );
            if (clicked) currentClickedObjects.push(line);
          });

          isClicked = !!currentClickedObjects.length;
          if (isClicked) break;

          // Проверяем наведен ли курсор на полигон

          layers[layer][GeometryTypes.Polygon].forEach((polygon) => {
            if (!polygon) return false;

            const clicked = geometryUtils.isPointWithinPolygon(
              lngLat,
              polygon.userData.coordinates,
            );
            if (clicked) {
              currentClickedObjects.push(polygon);
            }
          });

          isClicked = !!currentClickedObjects.length;

          if (isClicked) break;
        }

        const prevClickedObject = [
          ...(mapService.clickedGeometries?.[GeometryTypes.Point] || []),
          ...(mapService.clickedGeometries?.[GeometryTypes.Polyline] || []),
          ...(mapService.clickedGeometries?.[GeometryTypes.Polygon] || []),
        ][0];

        if (isClicked) {
          if (
            !prevClickedObject ||
            prevClickedObject.userData.id !==
              currentClickedObjects[0]?.userData.id
          ) {
            mapService.setClickedObject(currentClickedObjects[0]);
            mapService.setClickedGeometries({
              [GeometryTypes.Point]:
                currentClickedObjects[0]?.userData.type === GeometryTypes.Point
                  ? [currentClickedObjects[0] as Marker]
                  : [],
              [GeometryTypes.Polyline]:
                currentClickedObjects[0]?.userData.type ===
                GeometryTypes.Polyline
                  ? [currentClickedObjects[0] as Polyline]
                  : [],
              [GeometryTypes.Polygon]:
                currentClickedObjects[0]?.userData.type ===
                GeometryTypes.Polygon
                  ? [currentClickedObjects[0] as Polygon]
                  : [],
            });

            if (currentClickedObjects[0])
              mapService.drawClicked(
                currentClickedObjects[0],
                prevClickedObject,
              );
          }
          currentClickedObjects = [];
          return;
        }

        if (
          !currentClickedObjects[0] ||
          !currentClickedObjects[0].userData.layerType
        ) {
          mapService.drawGeometries({ method: 'replaceAll' });
          mapService.setClickedObject(null);
          mapService.setClickedGeometries(null);
        }
      };

      mapService.map.on('click', handleMouseClick);

      return () => {
        mapService.map.off('click', handleMouseClick);
      };
    }
  }, [isDisabled, geometryUtils, mapService, mapService?.isMapLoaded]);
};
