import { dayWeekAgo } from 'app/utils/dateUtils';
import { KeyboardDatePickerFF } from 'core/form/finalForm/fields/default/datePickers/KeyboardDatePickerFF';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import moment from 'moment';
import React from 'react';
import { DateTimeParam, withDefault } from 'use-query-params';

import { filterInputData } from '../filterInputsClasses';

export const DATE_NAME = 'date';

/**
 * Input Filter __Дата__.
 *
 * @param {object} props - Properties.
 * @returns {JSX.Element}
 */
export const DateFilter = (props) => (
  <KeyboardDatePickerFF
    label="Дата"
    {...props}
    className={filterInputData}
    name={DATE_NAME}
  />
);

/**
 * DateParams.
 *
 * @returns {*}
 */
export const dateParams = () => {
  addPreparation(DATE_NAME, (value) => {
    let newValue = value;
    if (moment(value).isValid()) {
      newValue = moment(value).format('DD.MM.YYYY');
    }
    return newValue;
  });
  return {
    [DATE_NAME]: withDefault(DateTimeParam, dayWeekAgo),
  };
};
