 
export const filterInputAutocomplete = 'filter__input-autocomplete';
 
export const filterInput = 'filter__input';
 
export const wideInput = 'w100';
 
export const filterInputData = 'filter__input-date';
 
export const filterInputDataWithHours = 'filter__input-date-with-hours';
