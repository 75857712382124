/* eslint-disable */
import React from 'react';

import {
  CarGroupTypeFF,
  CAR_GROUP_TYPE_NAME,
} from 'core/form/finalForm/fields';
import { filterInput } from '../filterInputsClasses';
import { NumberParam } from 'use-query-params';

 
 
/**
 * Группа техники
 * carGroupType
 */
 
export const CarGroupTypeFilter = () => (
  <CarGroupTypeFF className={filterInput} />
);

 
export const carGroupTypeParams = {
  [CAR_GROUP_TYPE_NAME]: NumberParam,
};
