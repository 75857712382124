import service from 'core/services/dateFormatService';
/* eslint-disable */
import format from 'core/services/dateFormatService/formats';
import moment from 'moment';

/**
 *
 * @param dateStr
 */
export const getEndOfDay = (dateStr) => {
  const date = service.parseDate(dateStr);
  date.setHours(23);
  date.setMinutes(59);
  return date;
};

/**
 *
 * @param dateStr
 */
export const getStartOfDay = (dateStr) => {
  const date = service.parseDate(dateStr);
  date.setHours(0);
  date.setMinutes(0);
  return date;
};

/* eslint-disable */
export const hasDatePassed = (dateStr) =>
  service.parseDate(dateStr) < new Date();

const startTime = {
  minute: 0,
  seconds: 0,
  milliseconds: 0,
};

export const getDefaultDateFrom = () => moment().set(startTime).toDate();

export const getDefaultDateTo = () =>
  moment().set(startTime).add(1, 'days').toDate();

export const getNextDay = (date) => moment(date).add(1, 'days');

export const formatDateTime = (dateTime) =>
  service.format(dateTime, 'DD.MM.YYYY HH:mm');

export const parseDate = (dateStr) => service.parseDate(dateStr);

export const formatDate = (date) => {
  if (moment.isMoment(date)) {
    return date.format(format.dateFormat);
  } else if (date instanceof Date) {
    return service.formatDate(date);
  } else {
    throw new Error('Date or momentDate expected as argument');
  }
};
