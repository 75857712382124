/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import ReactHintFactory from 'react-hint';
import get from 'lodash/get';
import cn from 'classnames';
import { connect } from 'react-redux';
import Logo from './Logo';
import Weather from './Weather';
import Navigation from './Navigation';
import User from './User';
import FeedBack from './FeedBack';

import DialogExportPgmFullness from 'app/components/dialogs/pgr/DialogExportPgmFullness';
import DialogExportPgmShipments from 'app/components/dialogs/pgr/DialogExportPgmShipments';

import withShowControls from 'core/hocs/withShowControls';
import { dialog } from 'app/constants/elementNames';

import './NavBar.scss';
import './Hint.scss';
import NotificationPrivileged from './components/NotificationPrivileged';
import { BadgeBell } from './components/BadgeBell';

const propTypes = {
  className: PropTypes.string,
  loggedIn: PropTypes.bool.isRequired,
  isShow: PropTypes.func,
  location: PropTypes.string,
  onHide: PropTypes.func,
  onShow: PropTypes.func,
};

const ReactHint = ReactHintFactory(React);

const NavBar = ({
  className,
  loggedIn,
  isShow,
  onHide,
  onShow,
  serverInfo,
}) => {
  return (
    <nav className={cn('ods-nav', className)}>
      <ReactHint delay={100} events={true} />

      <div className="nav-wrapper">
        {loggedIn ? (
          <>
            <Logo isAuth={loggedIn} systemData={serverInfo} onShow={onShow} />
            <Weather />
            <Navigation onShow={onShow} />
            <div className={'d-flex align-items-center'}>
              <NotificationPrivileged />
              <BadgeBell />
              <User />
            </div>
          </>
        ) : (
          <div className="">
            <Logo isAuth={loggedIn} systemData={serverInfo} onShow={onShow} />
          </div>
        )}
      </div>

      <FeedBack
        showCondition={isShow(dialog.FEED_BACK)}
        onHide={onHide(dialog.FEED_BACK)}
      />

      <DialogExportPgmFullness
        showCondition={isShow(dialog.EXPORT_PGM_FULLNESS)}
        onHide={onHide(dialog.EXPORT_PGM_FULLNESS)}
      />

      <DialogExportPgmShipments
        showCondition={isShow(dialog.EXPORT_PGM_SHIPMENTS)}
        onHide={onHide(dialog.EXPORT_PGM_SHIPMENTS)}
      />
    </nav>
  );
};

const mapStateToProps = (state) => ({
  serverInfo: get(state, 'serverInfo'),
  loggedIn: get(state, 'auth.loggedIn'),
});

NavBar.propTypes = propTypes;

const connectedNavBar = connect(mapStateToProps)(NavBar);

 
export default withShowControls(
  dialog.EXPORT_PGM_SHIPMENTS,
  dialog.EXPORT_PGM_FULLNESS,
  dialog.FEED_BACK,
)(connectedNavBar);
