import nestedStyles from 'assets/styles/card.nestedTable.scss';
import IconButton, {
  iconButtonStyle,
  iconStyle,
} from 'core/newComponents/IconButton';
import { ChevronRightIcon, ExpandMoreIcon } from 'core/uiKit/material-ui';
import React from 'react';

import NestedTable from '../../common/table/NestedTable';

/* eslint-disable */
class LinkageList extends React.Component {
  state = {
    visible: new Set(),
  };

  render() {
    return (
      <div>
        {this.props.list &&
          this.props.list.map((linkage) => {
            const carName = linkage.car_name;
            const uuid = linkage.uuid;
            const isVisible = this.state.visible.has(uuid);
            return (
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton
                    iconClassName="material-icons"
                    iconStyle={iconStyle}
                    style={iconButtonStyle}
                    onClick={() => {
                      if (isVisible) {
                        let visible = new Set(...this.state.visible);
                        visible.delete(uuid);
                        this.setState({ visible });
                      } else {
                        let visible = new Set(...this.state.visible);
                        visible.add(uuid);
                        this.setState({ visible });
                      }
                    }}
                  >
                    {isVisible ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                  </IconButton>
                  <h2>{carName}</h2>
                </div>
                <div style={{ paddingLeft: 57, marginTop: -10 }}>
                  <Linkage visible={isVisible} {...linkage} />
                  <div
                    style={{
                      content: '',
                      display: 'table',
                      clear: 'both',
                    }}
                  />
                </div>
                <hr />
              </div>
            );
          })}
      </div>
    );
  }
}

/**
 *
 * @param props
 */
function Linkage(props) {
  if (!props.visible) {
    return null;
  }

  const columns = [
    { id: 'port', name: 'Порт БНСО' },
    { id: 'sens_name', name: 'Датчик' },
  ];
  return (
    <NestedTable
      className={nestedStyles.renderLinkageTable}
      columns={columns}
      isAutoScrollLeft={false}
      isAutoScrollTop={false}
      main={props.connect_attr.map((row, index) => {
        return (
          <tr key={`tr-${index}`}>
            {columns.map(function (col) {
              const rowVal = row[col.id];
              return (
                <td key={`td-${index}-${col.id}`}>
                  {rowVal || <span style={{ paddingLeft: '10px' }} />}
                </td>
              );
            })}
          </tr>
        );
      })}
    />
  );
}

export default LinkageList;
