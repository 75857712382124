import { RANDOM_ID, RANDOM_WORD } from 'core/forTesting/constants';
import { useGetOghType } from 'core/utils/hooks/useGetOghType';

import { useCheckingAccessSaveWithoutGeometry } from './useCheckingAccessSaveWithoutGeometry';

jest.mock('core/utils/hooks/useGetOghType');

 
describe('file useCheckingAccessSaveWithoutGeometry', () => {
   
  /**
   * Хелпер для создания обьекта по коду типа огх. Для тестов.
   *
   * @param {string} code - Код типа огх.
   * @returns {{code: string, id: number}} - Жопа.
   * @example
   * getObjectTypeByCode('spa') // {code: 'spa', id: 11511297}
   */
  const getObjectTypeByCode = (code) => ({
    code,
    id: +[...code].map((item) => item.charCodeAt(0)).join(''),
  });

  it('simple', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetOghType.mockReturnValue({
      code: RANDOM_WORD,
      id: RANDOM_ID,
    });
    // Act
    const result = useCheckingAccessSaveWithoutGeometry(
      RANDOM_ID + 2,
      RANDOM_ID + 42,
    );
    // Assert
    expect(result).toBeFalsy();
  });

  it('useGetOghType вызывается с spa', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetOghType.mockReturnValue({
      code: RANDOM_WORD,
      id: RANDOM_ID,
    });

    // Act
    useCheckingAccessSaveWithoutGeometry(RANDOM_ID + 2, RANDOM_ID + 42);

    // Assert
    expect(useGetOghType).toHaveBeenNthCalledWith(1, 'spa');
    expect(useGetOghType).toHaveBeenCalledTimes(1);
  });

  it.each`
    n    | currentTypeId                     | parentTypeId                     | isAccessSaveWithoutGeometry
    ${1} | ${undefined}                      | ${undefined}                     | ${false}
    ${2} | ${getObjectTypeByCode('spa').id}  | ${RANDOM_ID}                     | ${true}
    ${3} | ${getObjectTypeByCode('spa').id}  | ${undefined}                     | ${true}
    ${4} | ${getObjectTypeByCode('none').id} | ${undefined}                     | ${false}
    ${5} | ${getObjectTypeByCode('none').id} | ${RANDOM_ID}                     | ${false}
    ${6} | ${undefined}                      | ${getObjectTypeByCode('spa').id} | ${true}
    ${7} | ${getObjectTypeByCode('none').id} | ${getObjectTypeByCode('spa').id} | ${true}
    ${8} | ${getObjectTypeByCode('spa').id}  | ${getObjectTypeByCode('spa').id} | ${true}
  `(
    'test #$n: currentTypeId[$currentTypeId] || parentTypeId[$parentTypeId] == $isAccessSaveWithoutGeometry',
    ({ currentTypeId, parentTypeId, isAccessSaveWithoutGeometry }) => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      useGetOghType.mockImplementation(getObjectTypeByCode);
      // Act
      const result = useCheckingAccessSaveWithoutGeometry(
        currentTypeId,
        parentTypeId,
      );
      // Assert
      expect(result).toBe(isAccessSaveWithoutGeometry);
    },
  );
});
