import { PropertiesDiffLegend } from 'app/pages/cardsOgh/components/tables/Versions/DialogComparison/PropertiesDiff/PropertiesDiffLegend';
import { useGetPropsDiffTable } from 'app/pages/cardsOgh/components/tables/Versions/DialogComparison/PropertiesDiff/useGetPropsDiffTable';
import formatRootId from 'app/utils/formatRootId';
import { IndexCell } from 'core/uiKit/components/cells/Index.Cell/Index.Cell';
import { LegendCell } from 'core/uiKit/components/cells/Legend.Cell/Legend.Cell';
import { TableSimple } from 'core/uiKit/components/tables/TableSimple';
import React, { useMemo } from 'react';

/**
 * Таблица свойств с легендой.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.etalon - Объект сравниваемый.
 * @param {object} props.copy - C чем сравниваем.
 * @returns {JSX.Element} - JSX.
 * @example <PropertiesDiff etalon={etalon} copy={copy}/>
 */
export const PropertiesDiff = ({ etalon, copy }) => {
  const { table, isLoading } = useGetPropsDiffTable(etalon, copy);

  const columns = useMemo(() => {
    return [
      {
        Cell: IndexCell,
        Header: '№ п/п',
        accessor: 'index',
        align: 'center',
        minWidth: 60,
      },
      {
        Header: 'Свойство',
        accessor: 'name',
      },
      {
        Header: `${formatRootId(etalon.id)} (${etalon.object_status_name})`,
        accessor: 'original_value',
      },
      {
        Header: `${formatRootId(copy.id)} (${copy.object_status_name})`,
        accessor: 'copy_value',
      },
      {
        Cell: LegendCell,
        Header: 'Легенда',
        accessor: 'prop_operation',
        align: 'center',
      },
    ];
  }, [etalon, copy]);

  return (
    <>
      <div className={'dialog-comparison-version-card__dialog-table'}>
        <TableSimple columns={columns} data={table} isLoading={isLoading} />
      </div>
      <PropertiesDiffLegend />
    </>
  );
};
