/* eslint-disable */
import React from 'react';
import { ArrayParam, withDefault } from 'use-query-params';

import { SensorTypeFF, SENSOR_TYPE_NAME } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
 
/**
 * Датчики
 *
 * `type = MultiSelect`
 * `name = sensorType`
 */
 
export const SensorTypeFilter = () => <SensorTypeFF className={filterInput} />;

 
export { SENSOR_TYPE_NAME };

 
export const sensorTypeParams = {
  [SENSOR_TYPE_NAME]: withDefault(ArrayParam, []),
};
