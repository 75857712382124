export const clean_category = [
  {
    attribute: {
      code: 'cat1',
      is_avd: null,
      name: '1 категория',
      sort_order: '001',
    },
    id: 1,
  },
  {
    attribute: {
      code: 'cat2',
      is_avd: null,
      name: '2 категория',
      sort_order: '002',
    },
    id: 2,
  },
  {
    attribute: {
      code: 'cat3',
      is_avd: null,
      name: '3 категория',
      sort_order: '003',
    },
    id: 3,
  },
  {
    attribute: {
      code: 'cat4',
      is_avd: null,
      name: '4 категория',
      sort_order: '004',
    },
    id: 4,
  },
  {
    attribute: {
      code: 'cat5',
      is_avd: null,
      name: '5 категория',
      sort_order: '005',
    },
    id: 5,
  },
  {
    attribute: {
      code: 'cat6',
      is_avd: null,
      name: '6 категория',
      sort_order: '006',
    },
    id: 6,
  },
  {
    attribute: {
      code: 'cat7a',
      is_avd: null,
      name: '7а категория',
      sort_order: '007',
    },
    id: 8,
  },
  {
    attribute: {
      code: 'cat7b',
      is_avd: null,
      name: '7б категория',
      sort_order: '008',
    },
    id: 9,
  },
  {
    attribute: {
      code: 'cat8a',
      is_avd: null,
      name: '8а категория',
      sort_order: '009',
    },
    id: 10,
  },
  {
    attribute: {
      code: 'cat8b',
      is_avd: null,
      name: '8б категория',
      sort_order: '010',
    },
    id: 11,
  },
  {
    attribute: {
      code: 'cat8c',
      is_avd: null,
      name: '8в категория',
      sort_order: '011',
    },
    id: 12,
  },
  {
    attribute: {
      code: 'cat9',
      is_avd: null,
      name: '9 категория',
      sort_order: '012',
    },
    id: 13,
  },
  {
    attribute: {
      code: 'cat0',
      is_avd: null,
      name: 'Внекатегорийная',
      sort_order: '013',
    },
    id: 7,
  },
  {
    attribute: {
      code: 'cat1a_avd',
      is_avd: 1,
      name: 'IА',
      sort_order: '014',
    },
    id: 14,
  },
  {
    attribute: {
      code: 'cat1b_avd',
      is_avd: 1,
      name: 'IБ',
      sort_order: '015',
    },
    id: 15,
  },
  {
    attribute: {
      code: 'cat1v_avd',
      is_avd: 1,
      name: 'IВ',
      sort_order: '016',
    },
    id: 16,
  },
  {
    attribute: {
      code: 'cat2_avd',
      is_avd: 1,
      name: 'II',
      sort_order: '017',
    },
    id: 17,
  },
  {
    attribute: {
      code: 'cat3_avd',
      is_avd: 1,
      name: 'III',
      sort_order: '018',
    },
    id: 18,
  },
  {
    attribute: {
      code: 'cat4_avd',
      is_avd: 1,
      name: 'IV',
      sort_order: '019',
    },
    id: 19,
  },
  {
    attribute: {
      code: 'cat5_avd',
      is_avd: 1,
      name: ' V',
      sort_order: '020',
    },
    id: 20,
  },
];
