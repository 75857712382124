import { DownloadLinkCell } from 'app/pages/cardsOgh/components/tables/Documents/components/DownloadLink.Cell';

 
export /* eslint-disable */
const columnsTankDocuments = [
  {
    accessor: 'file_name',
    Header: 'Наименование файла',
    Cell: DownloadLinkCell,
  },
  {
    accessor: 'comment',
    Header: 'Комментарий',
  },
  {
    accessor: 'create_date',
    Header: 'Дата присоединения',
  },
];
