/* eslint-disable */
/**
 * Колонка __ГРБС__.
 *
 * @type {{ accessor: 'grbs_short_name', Header: 'ГРБС'}}
 * @augments Cell
 */
export const grbs_short_name = {
  accessor: 'grbs_short_name',
  Header: 'ГРБС',
  sortable: true,
};
