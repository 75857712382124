/* eslint-disable  */
import {
  AddressFilter,
  addressParams,
  CreateTypeFilter,
  createTypeParams,
  CustomerImprovementObjectFilter,
  customerYardParams,
  DateFromFilter,
  dateFromParams,
  DateToFilter,
  dateToParams,
  FilterFF,
  IdOrNameFilter,
  idOrNameParams,
  OtherImprovementObjectTypeFilter,
  otherImprovementObjectTypeParams,
} from 'core/form/filterForm';
import {
  OghStatusesFilter,
  oghStatusesParams,
} from 'core/form/filterForm/components/miltiSelect/OghStatusesFilter';
import {
  ReApproveRequiredFilter,
  reApproveRequiredParams,
} from 'core/form/filterForm/components/yesOrNot/ReApproveRequired.Filter';
import {
  UpdateRequiredFilter,
  updateRequiredParams,
} from 'core/form/filterForm/components/yesOrNot/UpdateRequired.Filter';
import { preparationSearchFormValues } from 'core/utils/helpers';
import { useQueryParams } from 'core/utils/hooks';
import React from 'react';

/**
 *
 */
export const useFilterQueryParamsImprovementObject = () => {
  const [filters, setFilters] = useQueryParams([
    addressParams,
    createTypeParams,
    customerYardParams,
    dateFromParams,
    dateToParams,
    idOrNameParams,
    oghStatusesParams,
    otherImprovementObjectTypeParams,
    reApproveRequiredParams,
    updateRequiredParams,
  ]);

  return {
    filters,
    prepareFilters: preparationSearchFormValues(filters),
    setFilters,
  };
};

/* eslint-disable */
export const FilterImprovementObject = () => {
  const { setFilters, filters } = useFilterQueryParamsImprovementObject();

  const onSubmit = async (values) => {
    setFilters(values, 'push');
  };

  const reset = (form) => {
    form.reset();
    setFilters({}, 'replace');
  };
  return (
    <FilterFF {...{ onSubmit, reset, initialValues: filters }}>
      <IdOrNameFilter />
      <OghStatusesFilter />
      <OtherImprovementObjectTypeFilter />
      <DateFromFilter />
      <DateToFilter />
      <AddressFilter />
      <CustomerImprovementObjectFilter />
      <CreateTypeFilter />
      <UpdateRequiredFilter />
      <ReApproveRequiredFilter />
    </FilterFF>
  );
};
