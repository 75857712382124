import { useGetVisibilityButtonsOgh } from 'app/api/hooks/useGetVisibilityButtonsOgh';
/* eslint-disable */
import { shallow } from 'enzyme';
import React from 'react';

import { ButtonSigningOnRenewalOfApprovalDate } from './Button.SigningOnRenewalOfApprovalDate';

jest.mock('app/api/hooks/useGetVisibilityButtonsOgh');

const createMock = () => {
  useGetVisibilityButtonsOgh.mockImplementation((objectId) => ({
    data: { reapprove_eds: objectId === 42 },
  }));
};

function wrapperComponent(objectId) {
  createMock();
  return shallow(<ButtonSigningOnRenewalOfApprovalDate objectId={objectId} />);
}

 
describe('component ButtonSigningOnRenewalOfApprovalDate', () => {
  it('useGetVisibilityButtonsOgh был вызван с Random Number', () => {
    expect.hasAssertions();
    const objectId = Math.round(Math.random() * 10000000);
    wrapperComponent(objectId);
    expect(useGetVisibilityButtonsOgh).toHaveBeenCalledWith(objectId);
  });

  it('есть кнопка', () => {
    expect.hasAssertions();
    const button = wrapperComponent(42);
    expect(button.text()).toBe('<Button />');
  });

  it('есть кнопка с пропсами', () => {
    expect.hasAssertions();
    const button = wrapperComponent(42);
    expect(button.props()).toMatchObject({
      children: 'Обновить дату утверждения (ЭП)',
      color: 'success',
      variant: 'contained',
    });
  });

  it('нет кнопки', () => {
    expect.hasAssertions();
    const button = wrapperComponent(2);
    expect(button.text()).toBe('');
  });
});
