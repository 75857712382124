import { fetchPgmActualBases } from 'app/actions/basePgmActions';
import { exportRegistry } from 'app/actions/registryActions';
import { WithReduxFormSelect } from 'app/components/common/SelectField';
import { ACTIVE } from 'app/constants/oghStatus';
import { BASE_PGM } from 'app/constants/oghTypes';
import getOrganisations from 'app/selectors/form/getOrganisations';
import getDict from 'app/selectors/getDict';
import { dateFromMaxDate } from 'app/utils/dateFromMaxDate';
import { dateToMinDate } from 'app/utils/dateToMinDate';
import { validateMandatoryProperties } from 'app/utils/validation';
import FileDownload from 'core/components/FileDownload';
import { KeyboardDateTimePickerFF } from 'core/form/finalForm/fields';
import withCheckBeforeRenderDialog from 'core/hocs/withCheckBeforeRenderDialog';
import { ButtonGreen } from 'core/newComponents/Button';
import Checkbox from 'core/newComponents/Checkbox';
import Dialog from 'core/newComponents/Dialog';
import service from 'core/services/dateFormatService';
import { flow, get, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change, Field, Form, reduxForm, reset, submit } from 'redux-form';

import { getValuesToCheck } from './DialogExportPgmFullness';

const defaultDateFrom = new Date();
defaultDateFrom.setDate(defaultDateFrom.getDate() - 1);
defaultDateFrom.setHours(9, 0, 0, 0);

const defaultDateTo = new Date();
defaultDateTo.setHours(8, 59, 0, 0);

function ExportButton({ onClick }) {
  return (
    <ButtonGreen size="medium" type="submit" onClick={onClick}>
      Сформировать
    </ButtonGreen>
  );
}

const propTypes = {
  exportPath: PropTypes.string,
  handleExportComplete: PropTypes.func,
  personRootIdList: PropTypes.array,
  reagentBaseTypeList: PropTypes.array,
  formValues: PropTypes.object,
  reagentIdList: PropTypes.array,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  oivId: PropTypes.array,
  handleSubmit: PropTypes.func,
  reagentKindIdList: PropTypes.array,
  open: PropTypes.bool,
  resetFormValues: PropTypes.func,
  changeFieldValue: PropTypes.func,
  fetchData: PropTypes.func,
  startExport: PropTypes.func,
  handleClose: PropTypes.func,
};

class DialogExportPgmShipments extends Component {
  state = {
    isCustormersChecked: false,
    pgmRegistryTable: [],
  };

  componentDidMount() {
    this.fetchPgmBasesData();
  }

  componentDidUpdate(prevProps) {
    const {
      formValues: { dateFrom, dateTo, reagentBaseTypeList },
      open,
      resetFormValues,
      changeFieldValue,
    } = this.props;

    const { isCustormersChecked } = this.state;

    if (
      String(dateFrom) !== String(prevProps.formValues.dateFrom) ||
      String(dateTo) !== String(prevProps.formValues.dateTo) ||
      reagentBaseTypeList !== prevProps.formValues.reagentBaseTypeList
    ) {
      this.fetchPgmBasesData();
    }

    if (prevProps.open !== open) {
      return resetFormValues();
    }

    if (!isCustormersChecked) {
      changeFieldValue('oivId', 0);
      changeFieldValue('personRootIdList', '');
    }
  }

  getDataForPgmBasesFetch() {
    const { dateFrom, dateTo, reagentBaseTypeList } = this.props.formValues;
    const reagentBaseType = reagentBaseTypeList;
    return {
      dateFrom: service.formatDateTime(dateFrom),
      dateTo: service.formatDateTime(dateTo),
      typeId: BASE_PGM,
      status: ACTIVE,
      ...(reagentBaseType ? { reagentBaseType } : { t: 1 }),
    };
  }

  getValuesObj = () => {
    const { formValues } = this.props;
    const formFields = Object.keys(formValues);

    return formFields.reduce((result, item) => {
      let key = item;
      let value = formValues[key];

      if (key === 'dateFrom' || key === 'dateTo') {
        result[key] = service.formatDateTime(value);
      }

      if (key === 'reagentBaseTypeList' && value !== 0) {
        result[key] = value;
      }

      if (key === 'oivId' && value !== 0) {
        result[key] = value;
      }

      if (Array.isArray(value) && value[0] !== '') {
        result[key] = value;
      }
      return result;
    }, {});
  };

  fetchPgmBasesData() {
    const { fetchData } = this.props;
    const dataForPgmBasesFetch = this.getDataForPgmBasesFetch();

    this.setState({ isLoading: true }, async () => {
      const response = await fetchData(dataForPgmBasesFetch);

      this.setState({
        isLoading: false,
        pgmRegistryTable: response.data && response.data.table,
      });
    });
  }

  handleCheck = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  handleClose = () => {
    const { onHide } = this.props;
    this.setState({ isCustormersChecked: false });
    onHide();
  };

  submitForm = () => {
    const { startExport } = this.props;

    const result = this.getValuesObj();
    this.setState({ queryParams: { type: 'shipment', ...result } }, () => {
      startExport();
    });
  };

  render() {
    const {
      handleSubmit,
      reagentBaseTypeList,
      reagentKindIdList,
      reagentIdList,
      showCondition,
      oivId,
      personRootIdList,
      formValues,
      exportPath,
      handleExportComplete,
      submit,
    } = this.props;

    const { pgmRegistryTable, isLoading, queryParams, isCustormersChecked } =
      this.state;

    //Виды ПГР
    const allReagentKindsIdsForSelectedBases =
      !isLoading &&
      pgmRegistryTable &&
      pgmRegistryTable.length &&
      pgmRegistryTable
        .map((base) => base.reagent_kind_id_list || base)
        .reduce((acc, val) => [...acc, ...val]);

    const uniqKinds = uniqBy(allReagentKindsIdsForSelectedBases, (e) => e);

    const reagentsKindsForSelectedBases =
      uniqKinds && uniqKinds.length
        ? reagentKindIdList.filter((item) => uniqKinds.includes(item.id))
        : reagentKindIdList;

    //Наименования ПГР
    const allReagentIdsForSelectedBases =
      !isLoading &&
      pgmRegistryTable &&
      pgmRegistryTable.length &&
      pgmRegistryTable
        .map((base) => base.reagent_id_list || base)
        .reduce((acc, val) => [...acc, ...val]);

    const uniqReagents = uniqBy(allReagentIdsForSelectedBases, (e) => e);

    const reagentsForSelectedBases =
      uniqReagents && uniqReagents.length
        ? reagentIdList.filter((item) => uniqReagents.includes(item.id))
        : reagentIdList;

    const filtredReagents =
      reagentsForSelectedBases &&
      reagentsForSelectedBases.length &&
      reagentsKindsForSelectedBases &&
      reagentsKindsForSelectedBases[0] !== '' &&
      formValues.reagentKindIdList &&
      formValues.reagentKindIdList[0] !== '' &&
      reagentsForSelectedBases.filter((reagent) =>
        formValues.reagentKindIdList.includes(reagent.reagent_kind_id),
      );

    return (
      <Dialog
        actions={<ExportButton onClick={submit} />}
        show={showCondition}
        title="Вывоз реагентов с баз ПГР"
        onHide={this.handleClose}
      >
        <Form id="exportPgmShipments" onSubmit={handleSubmit(this.submitForm)}>
          <div
            style={{ display: 'flex', justifyContent: 'space-between' }}
            className="flex-nowrap"
          >
            <KeyboardDateTimePickerFF
              id="dateFrom"
              label="Дата с"
              maxDate={dateFromMaxDate(formValues.dateFrom, formValues.dateTo)}
              name="dateFrom"
            />
            <KeyboardDateTimePickerFF
              id="dateTo"
              label="Дата по"
              minDate={dateToMinDate(formValues.dateFrom)}
              name="dateTo"
            />
          </div>

          <div style={{ margin: '16px 0' }}>
            <Field
              component={WithReduxFormSelect}
              id="reagentBaseTypeList"
              label="Тип базы"
              name="reagentBaseTypeList"
              options={[{ id: 0, name: 'Все' }, ...(reagentBaseTypeList || [])]}
            />
          </div>

          <Field
            component={WithReduxFormSelect}
            id="objectRootIdList"
            label="Базы хранения ПГР"
            multiple={true}
            name="objectRootIdList"
            options={[
              { id: '', name: 'Все' },
              ...(pgmRegistryTable &&
                pgmRegistryTable.map((item) => ({
                  id: item.object_id,
                  name: item.object_name,
                }))),
            ]}
          />

          <Checkbox
            checked={this.state.isCustormersChecked}
            label="В разрезе учреждений"
            value="isCustormersChecked"
            onChange={this.handleCheck('isCustormersChecked')}
          />

          <Field
            component={WithReduxFormSelect}
            disabled={!isCustormersChecked}
            id="oivId"
            label="Учредитель"
            multiple={true}
            name="oivId"
            options={[{ id: 0, name: 'Все' }, ...(oivId || [])]}
          />

          <Field
            component={WithReduxFormSelect}
            disabled={!isCustormersChecked}
            id="personRootIdList"
            label="Учреждения"
            multiple={true}
            name="personRootIdList"
            options={[{ id: '', name: 'Все' }, ...(personRootIdList || [])]}
          />

          <Field
            component={WithReduxFormSelect}
            id="reagentKindIdList"
            label="Вид ПГР"
            multiple={true}
            name="reagentKindIdList"
            options={[
              { id: '', name: 'Все' },
              ...(reagentsKindsForSelectedBases || []),
            ]}
          />

          <Field
            component={WithReduxFormSelect}
            id="reagentIdList"
            label="Наименование ПГР"
            multiple={true}
            name="reagentIdList"
            options={[{ id: '', name: 'Все' }, ...(filtredReagents || [])]}
          />
        </Form>

        {exportPath && (
          <FileDownload
            actionPath={exportPath}
            queryParams={queryParams}
            onDownloadComplete={handleExportComplete}
          />
        )}
      </Dialog>
    );
  }
}

DialogExportPgmShipments.propTypes = propTypes;

const mandatoryFields = [
  'dateFrom',
  'dateTo',
  'reagentBaseTypeList',
  'objectRootIdList',
  'reagentKindIdList',
  'reagentIdList',
];

const validate = (values) => {
  const valuesForCheck = getValuesToCheck(values);
  validateMandatoryProperties(valuesForCheck, mandatoryFields);
};

const mapStateToProps = (state) => ({
  enableReinitialize: true,

  reagentBaseTypeList: getDict('reagentBaseTypes')(state),
  reagentKindIdList: getDict('reagentKinds')(state),
  reagentIdList: getDict('reagents')(state),
  oivId: getDict('prefectures')(state),
  personRootIdList: getOrganisations(state),
  exportPath: get(state, 'registry.exportPath'),
  formValues: state.form.exportPgmShipments
    ? state.form.exportPgmShipments.values
    : {},

  initialValues: {
    dateFrom: defaultDateFrom,
    dateTo: defaultDateTo,
    reagentBaseTypeList: 1,
    objectRootIdList: '',
    oivId: 0,
    personRootIdList: '',
    reagentKindIdList: '',
    reagentIdList: '',
  },
});

const mapDispatchToProps = (dispatch) => ({
  fetchData: function (data) {
    return dispatch(fetchPgmActualBases(data));
  },
  startExport: () => {
    dispatch(exportRegistry('base_pgm'));
  },
  handleExportComplete: () => {
    dispatch(exportRegistry());
  },
  changeFieldValue: (field, value) =>
    dispatch(change('exportPgmShipments', field, value)),
  resetFormValues: () => dispatch(reset('exportPgmShipments')),
  submit: () => dispatch(submit('exportPgmShipments')),
});

 
export default flow(
  reduxForm({
    form: 'exportPgmShipments',
    validate,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withCheckBeforeRenderDialog,
)(DialogExportPgmShipments);
