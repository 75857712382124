import './dialog.ApproveElectronicSignature.scss';

import { useGetFilesForSignature } from 'app/api/hooks/signs/useGetFilesForSignature/useGetFilesForSignature';
import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { SAVE } from 'app/api/requests/signs/signOghSave';
import { ReasonFilter } from 'app/components/dialogs/common/filters/Reason.Filter';
import { getCurrentDate } from 'app/utils/date/getCurrentDate';
import { StartDate } from 'core/form/finalForm/fields/prepared/datePickers/keyboardDatePickerFF/StartDate';
import {
  CERTIFICATE_NAME,
  FileSignatureCryptoProFF,
  SIGNATURES_NAME,
} from 'core/form/finalForm/fields/prepared/FileSignatureCryptoProFF';
import { SendReasonFF } from 'core/form/finalForm/fields/prepared/selects/multiSelect/SendReasonFF';
import { CommentFF } from 'core/form/finalForm/fields/prepared/textField/CommentFF';
import { CertInfo } from 'core/uiKit/components/CertInfo';
import { FileListSign } from 'core/uiKit/components/FileList.Sign';
import { Paper } from 'core/uiKit/components/Paper';
import { SignatureList } from 'core/uiKit/components/SignatureList';
import { Alert } from 'core/uiKit/material-ui';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import React, { useMemo, useState } from 'react';
import { useField } from 'react-final-form';

import { DocumentApproveElectronicSignatureField } from './fields/Document.ApproveElectronicSignature.Field';

/**
 *
 * Form Dialog.
 *
 * @returns {JSX}
 */
export const FormApproveElectronicSignature = () => {
  const { input: { value: certificate } = {} } = useField(CERTIFICATE_NAME);
  const { input: { value: signatures, onChange: onSignaturesChange } = {} } =
    useField(SIGNATURES_NAME);
  const [error, setError] = useState();

  const { objectId } = useGetObjectId();
  const { data = [] } = useGetFilesForSignature(objectId, SAVE);

  const files = data?.map(({ file }) => file);

  /**
   * Error.
   *
   * @param { Error } event - Error.
   * @returns { string }
   */
  const callbackError = (event) =>
    setError(event?.toString().replace(/Error: /g, ''));

  const { recordId, rootId } = useGetCurrentCard();

  const typeChange = useMemo(() => {
    return recordId === rootId ? 'create_ogh' : 'edit_ogh';
  }, [recordId, rootId]);

  return (
    <>
      <Paper className={'p-2'}>
        <ReasonFilter
          privilegeCode={typeChange}
          required
          Component={SendReasonFF}
          withAll={false}
        />

        <StartDate minDate={getCurrentDate()} />
        <DocumentApproveElectronicSignatureField />
        <CommentFF fullWidth />
      </Paper>

      <CertInfo certificate={certificate} />
      {error ? (
        <Paper className={'my-2'} elevation={6}>
          <Alert severity="error" variant={'filled'}>
            {error}
          </Alert>
        </Paper>
      ) : null}
      <Paper className={'p-2'}>
        <FileSignatureCryptoProFF
          {...{
            callbackError,
            files, // самм файлы для подписи
          }}
        />
      </Paper>
      <FileListSign files={files} />
      <SignatureList
        clearSignatures={() => onSignaturesChange()}
        signatures={signatures}
      />
    </>
  );
};
