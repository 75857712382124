 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Уточнение типа укрепления__.
 *
 * @type {{ accessor: 'margin_streng_name',  Header: 'Уточнение типа укрепления'}}
 * @augments Cell
 */
export const margin_streng_name = {
  accessor: 'margin_streng_name',
  Header: 'Уточнение типа укрепления',
  sortable: true,
};
