import { DistrictList } from 'core/uiKit/preparedInputs/selects/multiSelect/DistrictList';

 
/**
 * DistrictListWithReduxForm.
 *
 * @param {{input: object, "...props": object}} props - Параметры инпута из redux form в переданные пропсы.
 * @returns {React.ReactElement} - Возвращает <DistrictList {...input} {...props} />.
 * @class
 * @example
 * <Field name={DISTRICT_NAME} component={OdhSideIdWithReduxForm} {...props} />
 */
export const DistrictListWithReduxForm = ({
  input,
  ...props
}) => {
  return <DistrictList {...input} {...props} />;
};
