import { useUser } from 'app/pages/providers/UserContext/UserContext';
import { ButtonBlue } from 'core/newComponents/Button';
import React from 'react';

type ExcludeButtonProps = {
  onClick: () => void;
  disabled: boolean;
  typeCode: string;
};

/**
 * Кнопка __Исключить __.
 *
 * @param {ExcludeButtonProps} props - Пропсы.
 * @returns {JSX.Element} - Возвращает кнопку.
 */
export const ExcludeButton = ({
  onClick,
  disabled,
  typeCode,
}: ExcludeButtonProps) => {
  const user = useUser();

  const permission = user.hasPermission(`del_ogh_${typeCode}`);

  return (
    <>
      {permission ? (
        <ButtonBlue disabled={disabled} onClick={onClick}>
          ↑ Исключить
        </ButtonBlue>
      ) : null}
    </>
  );
};
