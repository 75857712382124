/* eslint-disable */
import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import { KeyboardDateTimePicker } from 'core/uiKit/inputs/datePickers/KeyboardDateTimePicker';

 
 
/**
 * ## Выбор даты и времени c клавиатуры
 *
 * ### React Final Form Field
 */
 
export const KeyboardDateTimePickerFF = ({ name, ...otherProps }) => {
  return (
    <Field name={name}>
      {(props) => {
        return (
          <KeyboardDateTimePicker {...props} {...props.input} {...otherProps} />
        );
      }}
    </Field>
  );
};

KeyboardDateTimePickerFF.propTypes = {
   
   
/**
   * Выбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
/**
   * placeholder
   */
  placeholder: PropTypes.string,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
   
  /* eslint-disable */
/**
   name input
   */
  name: PropTypes.string,
   
  /* eslint-disable */
/**
   label input
   */
  label: PropTypes.string,
};
KeyboardDateTimePickerFF.defaultProps = {
  disabled: false,
  name: 'KeyboardDateTimePickerFF',
  label: 'label for KeyboardDateTimePickerFF',
};
