import { PLACES_QUANTITY_NAME } from 'core/uiKit/preparedInputs/textField/PlacesQuantity/PlacesQuantity';
import React from 'react';
import { Field } from 'react-final-form';

import { PlacesQuantityWithFF } from './QuantityWithFF';

/**
 * Числовое поле __Количество посадочных мест, шт__.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const PlacesQuantityFF = ({ ...otherProps }) => {
  return (
    <Field
      component={PlacesQuantityWithFF}
      name={PLACES_QUANTITY_NAME}
      {...otherProps}
    />
  );
};
