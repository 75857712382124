 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Площадь уширения (кармана), кв.м__.
 *
 * @type {{ accessor: 'pocket_area', Header: 'Площадь уширения (кармана), кв.м' }}
 * @augments Cell
 */
export const pocket_area = {
  accessor: 'pocket_area',
  Header: 'Площадь уширения (кармана), кв.м',
  sortable: true,
};
