import { REQUIRED_FIELD_MESSAGE } from 'app/constants/messages';
import { OKRUG_LIST_NAME } from 'core/uiKit/preparedInputs/selects/multiSelect/OkrugList';

/**
 * Валидация поля OkrugListField.
 *
 * @param {object} formValues - Значения формы.
 * @returns {{OKRUG_LIST_NAME: string}} - Возвращает объект с текстом ошибки или null.
 * @example
 * function asyncValidate(formValues) {
 *   return {
 *     ...okrugListAsyncValidate(formValues),
 *   };
 * }
 */
export function okrugListAsyncValidate(formValues) {
  const value = formValues[OKRUG_LIST_NAME];

  let result;
  if (value.length === 0 || value[0] === '') {
    result = {
      [OKRUG_LIST_NAME]: REQUIRED_FIELD_MESSAGE,
    };
  } else {
    result = null;
  }
  return result;
}
