import { ButtonBlue } from 'core/newComponents/Button';
/* eslint-disable  */
import { Dialog } from 'core/uiKit/components/dialogs/Dialog';
import { ButtonMUI } from 'core/uiKit/material-ui';
import { isFunction } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  cancelText: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  isDisabledOk: PropTypes.bool,
  okText: PropTypes.string,
  onBeforeOk: PropTypes.func,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  showCondition: PropTypes.bool,
  title: PropTypes.string,
};

const defaultProps = {
  cancelText: 'Отменить',
  children: 'Все несохраненные изменения будут потеряны.',
  okText: 'Продолжить',
};

/**
 * Компонент DialogAction.
 *
 * @returns {JSX.ElementClass} - Жопа.
 */
class DialogAction extends React.Component {
  /**
   * Коструктор компонента DialogAction.
   *
   * @param {object} props - Пропсы.
   * @example .
   */
  constructor(props) {
    super(props);
    this.state = {
      show: Boolean(props.showCondition),
    };
  }

  //todo: Разобраться в каких диалоговых окнах применяется чтобы удалить этот метод

  /**
   * Метод жизненого цикла getDerivedStateFromProps.
   *
   * @description - getDerivedStateFromProps вызывается непосредственно перед вызовом метода render(),
   * как при начальном монтировании, так и при последующих обновлениях.
   * Он должен вернуть объект для обновления состояния или null, чтобы ничего не обновлять.
   * @param {object} nextProps - Пропсы.
   * @param {object} prevState - Стейт.
   * @returns {null|{show: boolean}} - Возвращает объект {show: true | false} или null.
   * @example .
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.showCondition !== prevState.show) {
      return {
        show: nextProps.showCondition,
      };
    }
    return null;
  }

  /**
   * Метод жизненого цикла componentDidUpdate.
   *
   * @param {object} prevProps - Предыдущие пропсы.
   * @returns {void} - Nothing.
   * @example .
   */
  componentDidUpdate(prevProps) {
    const { showCondition } = this.props;
    if (prevProps.showCondition !== showCondition && showCondition === true) {
      this.setState({
        show: true,
      });
    }
  }

  /**
   *
   */
  handleHide = () => {
    this.setState({
      show: false,
    });
  };

  /**
   *
   */
  handleCancel = () => {
    const { onCancel } = this.props;
    this.handleHide();
    if (isFunction(onCancel)) {
      onCancel();
    }
  };

  /**
   *
   */
  handleOk = async () => {
    const { onBeforeOk, onOk } = this.props;
    if (onBeforeOk && isFunction(onBeforeOk)) {
      const isOk = await onBeforeOk();
      if (isOk) {
        if (isFunction(onOk)) {
          onOk();
        }
        this.handleHide();
      }
    } else {
      this.handleHide();
      if (isFunction(onOk)) {
        onOk();
      }
    }
  };

  /**
   * Рендер диалогового окна.
   *
   * @returns {JSX.Element|null} - Возвращает либо компонент либо null.
   * @example .
   */
  render() {
    const {
      cancelText,
      className,
      children,
      isDisabledOk,
      okText,
      isLoading,
      title,
    } = this.props;
    const { show } = this.state;

    return show ? (
      <Dialog
        maxWidth={this.props.maxWidth || 'sm'}
        ButtonsActions={
          <React.Fragment>
            <CancelButton
              disabled={isLoading}
              title={cancelText}
              onClick={this.handleCancel}
            />
            <OkButton
              disabled={isDisabledOk || isLoading}
              title={okText}
              onClick={this.handleOk}
            />
          </React.Fragment>
        }
        className={className ? className : 'Dialog-Action'}
        isOpen={show}
        textHeader={title}
        onClose={this.handleCancel}
        Body={children}
      />
    ) : null;
  }
}

DialogAction.propTypes = propTypes;
DialogAction.defaultProps = defaultProps;

/**
 * Компонент кнопки.
 *
 * @param {object} props - Пропсы.
 * @param {boolean} props.disabled - Хопа.
 * @param {string} props.title - Заголовок.
 * @param {Function} props.onClick - Функция клика.
 * @returns {React.ReactComponentElement} - Компонент кнопки.
 * @example <OkButton
 *               disabled={isDisabledOk || isLoading}
 *               title={okText}
 *               onClick={this.handleOk}
 *             />
 */
function OkButton({ disabled, title, onClick }) {
  return (
    <ButtonBlue
      disabled={disabled}
      size="medium"
      type="submit"
      onClick={onClick}
    >
      {title}
    </ButtonBlue>
  );
}

/**
 * Компонент кнопки.
 *
 * @param {object} props - Пропсы.
 * @param {string} props.title - Заголовок.
 * @param {Function} props.onClick - Функция клика.
 * @returns {React.ReactComponentElement} - Компонент кнопки.
 * @example  <CancelButton
 *               disabled={isLoading}
 *               title={cancelText}
 *               onClick={this.handleCancel}
 *             />
 */
function CancelButton({ title, onClick }) {
  return (
    <ButtonMUI size="medium" variant="outlined" onClick={onClick}>
      {title}
    </ButtonMUI>
  );
}

export default DialogAction;
