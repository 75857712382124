import { createTheme, useTheme } from '@mui/material/styles';
import { useMemo } from 'react';
/* eslint-disable */
const createThemeCustom = ({ dark, palette, overrides }) => {
  let { type, mode } = palette;
  if (dark) {
    type = 'dark';
    mode = 'dark';
  }

  return createTheme({
    palette: {
      type,
      mode,
    },
    overrides,
  });
};

 
export const useCreateTheme = ({ dark = false, overrides = {} }) => {
  const globalTheme = useTheme();
  return useMemo(
    () => createThemeCustom({ ...globalTheme, dark, overrides }),
    [dark, globalTheme, overrides],
  );
};
