import { getCurrentDate } from 'app/utils/date/getCurrentDate';
import { KeyboardDatePickerFF } from 'core/form/finalForm/fields/default/datePickers/KeyboardDatePickerFF';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import moment from 'moment';
import React from 'react';
import { useField } from 'react-final-form';
import { DateTimeParam, withDefault } from 'use-query-params';

import { filterInputData } from '../filterInputsClasses';

export const DATE_FROM_NAME = 'dateFrom';

/**
 * Input Filter __Дата с__.
 *
 * @param {object} props - Properties.
 * @returns {JSX.Element}
 */
export const DateFromFilter = (props) => {
  const dateToInput = useField('dateTo');
  return (
    <KeyboardDatePickerFF
      label="Дата с"
      maxDate={dateToInput?.input?.value}
      {...props}
      className={filterInputData}
      name={DATE_FROM_NAME}
    />
  );
};

/**
 * Prepare and QueryParams dateFromParams.
 *
 * @param {*} initDate - Initial date.
 * @returns {*}
 */
export const dateFromParams = (initDate = getCurrentDate()) => {
  addPreparation(DATE_FROM_NAME, (value) => {
    let newValue = value;
    if (typeof value === 'object' && moment(value).isValid()) {
      newValue = moment(value).format('DD.MM.YYYY');
    }
    return newValue;
  });
  return {
    [DATE_FROM_NAME]: withDefault(DateTimeParam, initDate),
  };
};
