/* eslint-disable */
export const mainBoard = {
  BACKSPACE: 8,
  ENTER: 13, // тот же keyCode на Numpad
  ESC: 27,
  DELETE: 46,
  ZERO: 48,
  NINE: 57,
  DOT_OR_SLASH: 191, // event.code = Slash (EN раскладка), но в русской - "."
};

/* eslint-disable */
export const numPad = {
  ZERO: 96,
  NINE: 105,
  DOT: 110,
};
