import formatRootId from 'app/utils/formatRootId';
import { CreateTypeCell } from 'app/utils/getOghRegistryColumns/cells';


/* eslint-disable */
export default [
  {
    id: 'root_id',
    name: 'ID',
    Cell: (row) => formatRootId(row.value),
    width: 80,
  },
  {
    id: 'okrug_name',
    name: 'Округ',
  },
  {
    id: 'district_name',
    name: 'Район',
  },
  {
    id: 'object_name',
    name: 'Наименование',
  },
  {
    id: 'owner_name',
    name: 'Балансодержатель',
  },
  {
    id: 'improvement_object_cat_name',
    name: 'Категория озеленения',
  },
  {
    id: 'improvement_category_name',
    name: 'Категория благоустройства',
  },
  {
    id: 'total_area',
    name: 'Общая площадь, кв.м.',
  },
  {
    id: 'total_clean_area',
    name: 'Уборочная площадь, кв.м.',
  },
  {
    id: 'create_type',
    name: 'Тип создания объекта',
    Cell: CreateTypeCell,
  },
  {
    id: 'object_status_name',
    name: 'Статус',
    width: 110,
  },
  {
    id: 'start_date',
    name: 'Дата начала',
    width: 90,
  },
  {
    id: 'end_date',
    name: 'Дата окончания',
    width: 120,
  },
];
