/* eslint-disable */
import React from 'react';

import { CAR_TYPES_NAME, CarTypesFF } from 'core/form/finalForm/fields';
import { filterInput } from '../filterInputsClasses';
import { ArrayParam, withDefault } from 'use-query-params';

 
 
/**
 * Тип техники
 * carTypes
 *
 * @returns {JSX.Element}
 * @constructor
 */
 
export const CarTypesFilter = () => <CarTypesFF className={filterInput} />;

 
export { CAR_TYPES_NAME };

 
export const carTypesParams = {
  [CAR_TYPES_NAME]: withDefault(ArrayParam, []),
};
