import { ContextDialog } from 'app/pages/cardsOgh/components/DIalogs/Dialogs';
import { useContext } from 'react';

/* eslint-disable  */
/**
 *
 */
export const useDialogs = () => {
  return useContext(ContextDialog);
};
