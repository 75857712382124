import { toast } from 'core/uiKit/components/Toast';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

import { postSendNoCalc } from '../api/postSendNoCalc';

/**
 * Получение метода sendNoCalc.
 *
 * @returns {{isLoadingSend: boolean, sendNoCalc: Function, params: object}}
 */
export const useSendNoCalc = () => {
  const [sendNoCalc, params] = useMutationAdaptor(postSendNoCalc, {

    /**
     * Fn.
     *
     * @param res - Response.
     * @returns {void}
     */
    onSuccess(res) {
      toast.successDying(res.message);
    },
  });

  return { isLoadingSend: params.isLoading, sendNoCalc, ...params };
};
