/* eslint-disable  */
import {
  PLANTATION_TYPE_ID_NAME,
  PlantationTypeId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/PlantationTypeId';
import { Field } from 'redux-form';

/**
 *
 * @param root0
 * @param root0.input
 */
const PlantationTypeIdWithReduxForm = ({ input, ...props }) => {
  return <PlantationTypeId {...input} {...props} />;
};

/* eslint-disable */
export const PlantationTypeIdRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={PLANTATION_TYPE_ID_NAME}
      component={PlantationTypeIdWithReduxForm}
    />
  );
};
