import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';

export const CATEGORY_SP_ID_NAME = 'category_sp_id';

/**
 * Категория.
 *
 * @param {*} props - Properties.
 * @returns {JSX.Element}
 */
export const CategorySpId = (props) => {
  const dict = 'category_sp';

  const { data: options = [] } = useGetDictionary(dict);

  return (
    <Select
      {...props}
      options={options}
      label={'Категория по СП 42.13330.2016'}
      name={CATEGORY_SP_ID_NAME}
    />
  );
};
