 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Площадь УТН, кв.м__.
 *
 * @type {{ accessor: 'utn_area', Header: 'Площадь УТН, кв.м', sortable: true }}
 * @augments Cell
 */
export const utn_area = {
  accessor: 'utn_area',
  Header: 'Площадь УТН, кв.м',
  sortable: true,
};
