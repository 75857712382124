import { log } from 'core/utils/log';
import _ from 'lodash';

/* eslint-disable */
export default function validateNumeric(values, masks) {
  const errors = {};
  const regInteger = /(^(-)[1-9]+[0-9]*$)|(^[0-9]+$)/;
  const regNumeric =
    /(^(-?)[0-9]+(\.|,)[0-9]+$)|(^(-)[1-9]+[0-9]*$)|(^[0-9]+$)/;
  const regNumericPos = /(^[0-9]+$)|(^[0-9]+(\.|,)[0-9]+$)/;
  const regZero = /(^[0]+$)|(^[0]+(\.|,)[0]+$)/;

  masks
    .filter(
      (field) =>
        (_.isString(values[field.name]) && values[field.name] !== '') ||
        _.isNumber(values[field.name]),
    )
    .forEach((field) => {
      const value = String(values[field.name]).trim();

      if (field.type === 'decimal' && !regNumeric.test(value)) {
        errors[field.name] = 'введите число';
      } else if (field.type === 'integer' && !regInteger.test(value)) {
        errors[field.name] = 'введите целое число';
      } else if (field.positive && !regNumericPos.test(value)) {
        errors[field.name] = 'число не может быть отрицательным';
      } else if (field.checkSteps) {
        const stepError = field.checkSteps(value);
        if (stepError) {
          errors[field.name] = stepError;
        }
      } else if (
        field.positive &&
        _.has(field, 'zero') &&
        !field.zero &&
        regZero.test(value)
      ) {
        errors[field.name] = 'значение должно быть больше нуля';
      } else if (values[field.name].length > field.maxLength) {
        errors[
          field.name
        ] = `можно ввести не более ${field.maxLength} символов`;
      }
    });

  if (Object.keys(errors).length) {
    log.banana(errors);
  }
  return errors;
}
