import { cellsMainMap } from 'core/uiKit/components/cells/cellsMainMap';

/**
 * @typedef {import('src/core/uiKit/components/tables/Table/Table').Columns} Columns
 */

/**
 * Columns В реестре __Места накопления отходов__.
 *
 * @returns {Columns} - Жопа.
 * @example
 * getColumnsContainer()
 */
export const getColumnsContainer = () => [
  cellsMainMap.okrug_name, // Округ
  cellsMainMap.district_name, // Район
  { ...cellsMainMap.short_root_id, Header: 'ID МНО' },
  { ...cellsMainMap.address_name, Header: 'Адрес МНО' },
  cellsMainMap.start_date, // Дата начала
  cellsMainMap.end_date, // Дата окончания
  { ...cellsMainMap.object_type_name, Header: 'Тип МНО' },
  cellsMainMap.in_yard, // Внутридворовое
  cellsMainMap.area, // Площадь, кв.м
  cellsMainMap.total_area_geo, // Площадь объекта по пространственным координатам, кв.м
  cellsMainMap.parent_type_id, // Тип объекта городского хозяйства (родительского объекта)
  cellsMainMap.parent_short_root_id, // Идентификатор родительского объекта
  cellsMainMap.parent_object_name, // Наименование родительского объекта
  cellsMainMap.parent_okrug_name, // Округ родительского объекта
  cellsMainMap.parent_owner_name, // Балансодержатель родительского объекта
  cellsMainMap.parent_grbs_name, // ГРБС
  cellsMainMap.object_status_name, // Статус
  cellsMainMap.no_calc, // Не учитывать при расчете уборочных площадей,
  cellsMainMap.coating_group_name, // Вид покрытия
  {
    ...cellsMainMap.coating_type_name,
    Header: 'Вид покрытия, уточнение',
  },
  cellsMainMap.parent_customer_name, // Сокращённое наименование заказчика
  cellsMainMap.sign_date, // Дата подписания
  cellsMainMap.sign_person, // ФИО подписанта
  cellsMainMap.parent_department_name, // ОИВ
  cellsMainMap.is_diff_height_mark, // Разновысотные отметки
];
