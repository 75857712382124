import CardAbstract from 'app/components/card/common/CardAbstract';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import { IsDiffHeightMarkField } from 'app/components/card/ogh/components/fields/checkBoxFields/IsDiffHeightMark.Field';
import { QuantityField } from 'app/components/card/ogh/components/selects/Quantity.Field';
import VersionsTemplate from 'app/components/card/ogh/versions/VersionsTemplate';
import { SPA } from 'app/constants/oghTypes';
import isMandatory from 'app/utils/card/isMandatory';
import { NumberFieldRF } from 'core/form/reduxForm/fields';
import { NoCalcRF } from 'core/form/reduxForm/fields/prepared/checkboxes/NoCalc.RF';
import { CoatingFaceTypeIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/CoatingFaceTypeId.RF';
import { OtherDocumentsRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/OtherDocuments.RF';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/textField/TotalAreaGeo.RF';
import { CardContainer } from 'core/uiKit/components/card.components/CardContainer';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { TextField } from 'core/uiKit/inputs/TextField';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';

import { MaterialIdField } from '../components/fields/selectFields/MaterialId.Field';
import { ArrangeElementTypeIdField } from '../components/selects/ArrangeElementTypeId.Field';
import { UnitIdField } from '../components/selects/UnitId.Field';
import printOghCardHeader from '../header/oghHeader';

const types = ['functionality'];
const Versions = VersionsTemplate(types);

const tabs = [
  {
    id: 'characteristics-tab',
    name: 'Исходные данные',
  },
  {
    id: 'docs-tab',
    name: 'Документы',
  },
];

/**
 * Компонент FunctionalityCard.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const FunctionalityCard = (props) => {
  //

  /**
   * Метод render.
   *
   * @returns {JSX.Element} Компонент с полями.
   * @example -----------
   */

  const {
    card,
    mode,
    createMode,
    editMode,
    required,
    quantityLock,
    parentTypeId,
    quantityDigits,
    isEditCurrentObjectIsExternalSystem,
  } = props;

  const parentSPA = parentTypeId === SPA;

  return (
    <CardAbstract>
      <CardContainer>
        <div id="editor-card">
          {printOghCardHeader(card, mode)}
          {card && objectTabs(card.type_id)}

          <div id="props">
            <FieldsSplitColumns>
              <ArrangeElementTypeIdField />
              <ParentInfo />
              <NoCalcRF disabled={!editMode} />
              <IsDiffHeightMarkField />
            </FieldsSplitColumns>
            <Tabs tabs={tabs} />
            <div id="characteristics">
              <FieldsSplitColumns>
                <QuantityField
                  digits={quantityDigits}
                  disabled={
                    !(parentSPA
                      ? editMode
                      : editMode &&
                        !quantityLock &&
                        isEditCurrentObjectIsExternalSystem)
                  }
                  required={isMandatory(card.type_id, 'quantity', required)}
                />
                <TotalAreaGeoRF disabled={true} decimalScale={2} />
                <UnitIdField />
                <MaterialIdField />
                <TextField
                  label={'Принадлежность элемента к зоне территории'}
                  disabled={true}
                  value={card.zone_name || ''}
                  name={'zone_name'}
                />
                <CoatingFaceTypeIdRF disabled={!editMode} />
                <NumberFieldRF
                  disabled={!editMode}
                  name={'face_area'}
                  decimalScale={2}
                  label={'Площадь облицовки, кв.м.'}
                />
              </FieldsSplitColumns>
            </div>
            <div hidden={true} id="docs">
              <OtherDocumentsRF />
            </div>
          </div>
          <div hidden={true} id="versions">
            <Versions
              card={card}
              disabled={createMode}
              key={`versions-${card.root_id}`}
            />
          </div>
        </div>
      </CardContainer>
    </CardAbstract>
  );
};
