import { IPath } from 'types/pages';

interface IPathNsi extends IPath {
  privilegeChangelog: string;
}

export const pathNsi: IPathNsi = {
  name: 'Список справочников НСИ',
  path: 'nsi',
  privilegeChangelog: 'changelog_nsi',
  privilegeEdit: 'edit_nsi',
  privilegeView: 'view_nsi',
};
