import { validate as validateAquaArea } from 'app/components/card/ogh/ImprovementTerritoryCard/components/AquaAreaTab';
import { validate as validateSnowArea } from 'app/components/card/ogh/ImprovementTerritoryCard/components/SnowAreaTab';
import { vi } from 'vitest';

import { validate } from './validate';
vi.mock(
  'app/components/card/ogh/ImprovementTerritoryCard/components/AquaAreaTab',
);
vi.mock(
  'app/components/card/ogh/ImprovementTerritoryCard/components/SnowAreaTab',
);

describe('🐛 spec validate', function () {
  it('🧪 should', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    validateAquaArea.mockReturnValue({ cc: 3 });
    validateSnowArea.mockReturnValue({ bb: 3 });

    //🔥 Act
    const res = validate({ aa: 4 });

    //❓ Assert
    expect(validateAquaArea).toHaveBeenCalledWith({ aa: 4 });
    expect(validateSnowArea).toHaveBeenCalledWith({ aa: 4 });

    expect(res).toStrictEqual({ bb: 3, cc: 3 });
  });
});
