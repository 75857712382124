/* eslint-disable */

import { Field } from 'redux-form';
import {
  PlantServiceRecomendationsId,
  PLANT_SERVICE_RECOMENDATIONS_ID_NAME,
} from 'core/uiKit/preparedInputs/selects/multiSelect/PlantServiceRecomendationsId';

const PlantServiceRecomendationsIdRFWithReduxForm = ({ input, ...props }) => {
  return <PlantServiceRecomendationsId {...input} {...props} />;
};

export const PlantServiceRecomendationsIdRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={PLANT_SERVICE_RECOMENDATIONS_ID_NAME}
      component={PlantServiceRecomendationsIdRFWithReduxForm}
    />
  );
};
