import { Table } from 'core/uiKit/components/tables/Table';
import React from 'react';

import { TableOghChildrenProps } from '../../types';
import { getColumnsFlowersGarden } from './columns.FlowersGarden';
import { useSetupTableFlowersGarden } from './useSetup.Table.FlowersGarden';

/**
 * Компонент таблицы реестра __Цветники__.
 *
 * @param {TableOghChildrenProps} props - Пропсы.
 * @returns JSX.
 */
export const TableFlowersGarden = (props: TableOghChildrenProps) => {
  const { table, isLoading, count } = useSetupTableFlowersGarden(props);

  return (
    <Table
      columns={getColumnsFlowersGarden()}
      data={table || []}
      isLoading={isLoading}
      total={count}
    />
  );
};
