import { MafTypeLevel3Filter } from 'core/form/filterForm/components/selects/MafTypeLevel3.Filter';
import React from 'react';

import { useSetupMafTypeLevel3FilterField } from './useSetupMafTypeLevel3FilterField';

/**
 * Фильтр Детализация для реестра МАФ.
 *
 * @returns {JSX.Element}
 */
export const MafTypeLevel3FilterField = () => {
  const { filter, disabled } = useSetupMafTypeLevel3FilterField();

  return <MafTypeLevel3Filter filter={filter} disabled={disabled} />;
};
