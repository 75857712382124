export const mapEngineeringStructuresTab = [
  'bridge_psc',
  'bridge_qty',
  'bridge_sqr',
  'cable_fancing_psc',
  'decor_psc',
  'lamp_qty',
  'light_psc',
  'light_qty',
  'pavilion_qty',
  'pedestrian_psc',
  'pedestrian_qty',
  'pedestrian_sqr',
  'police_qty',
  'police_sqr',
  'sandbase_qty',
  'sandbase_sqr',
  'snow_psc',
  'support_qty',
  'tlight_qty',
  'transform_qty',
  'tube_psc',
  'tube_qty',
  'urn_qty',
  'wall_psc',
  'water_psc',
  'сase_outside_light_qty',
];

/**
 * Функция очистки таба __Инженерные сооружения для ТС__.
 *
 * @param {object} values - Значения формы.
 * @param {Function} changeField - Функция изменения поля.
 * @returns {void}
 */
export const resetEngineeringStructuresTab = (values, changeField) => {
  Object.keys(values).forEach((key) => {
    if (mapEngineeringStructuresTab.includes(key)) {
      changeField(key, null);
    }
  });
};
