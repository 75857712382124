import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { TableSimple } from 'core/uiKit/components/tables/TableSimple';
import React from 'react';

import { columnsElementOwnershipTable } from './columns.ElementOwnershipTable';

/**
 * Таблица __Принадлежность элемента__.
 *
 * @returns {JSX.Element}
 */
export const ElementOwnershipTable = () => {
  const { abutmentFriends } = useGetCurrentCard();

  return (
    <TableSimple
      data={abutmentFriends || []}
      columns={columnsElementOwnershipTable}
    />
  );
};
