// import * as types from '../constants/actionTypes';

const initialState = {
  lastEvent: null,
};

/**
 * Mop.
 *
 * @param {object} state - State.
 * @param {string} action - Action.
 * @returns {object}
 */
export default function mapReducer(state = initialState, action) {
  switch (action.type) {
    // case types.MAP_LAST_EVENT: {
    // return { ...state, lastEvent: action.payload };
    // }
    default:
      return state;
  }
}
