/* eslint-disable */
import { shallow } from 'enzyme';
import { create } from 'react-test-renderer';

import { AddRow } from './AddRow';

jest.mock('../DialogEdit.MovementCharacteristics', () => ({
  /**
   *
   * @param props
   */
  DialogEditMovementCharacteristics: (props) => <div {...props} />,
}));

/* eslint-disable */
describe('file AddRow', () => {
  it('simple test', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    // Act
    const wrapper = shallow(<AddRow />);

    // Assert
    expect(wrapper.text()).toBe(
      '<AddButton /><DialogEditMovementCharacteristics />',
    );
  });

  it('props', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const root = create(<AddRow />);

    // Assert
    expect(root.toJSON()).toMatchSnapshot();
  });

  it('isOpen', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const onChangeSpy = jest.fn();

    const wrapper = shallow(
      <AddRow input={{ value: [0, 1, 4], onChange: onChangeSpy }} />,
    );

    // Assert
    expect(wrapper.childAt(1).props()).toMatchObject({
      isOpen: undefined,
    });

    // Act
    wrapper.childAt(0).props().onClick();

    // Assert
    expect(wrapper.childAt(1).props()).toMatchObject({
      isOpen: true,
    });
  });
});
