import { LeftPanelAbutment } from 'app/pages/cardsOgh/Abutment/LeftPanel.Abutment';
import { validateAbutment } from 'app/pages/cardsOgh/Abutment/validate.Abutment';
import { FormCardFF } from 'app/pages/cardsOgh/components/FormCard.FF';
import React from 'react';

import { useSetupAbutment } from './useSetup.Abutment';

/**
 * Электронная форма паспорта __Элементы сопряжения поверхностей__.
 *
 * @returns {JSX.Element}
 */
export const Abutment = () => {
  const { initialValues, preparingDataForAttributes } = useSetupAbutment();

  return (
    <FormCardFF
      validate={validateAbutment}
      preparingDataForAttributes={preparingDataForAttributes}
      initialValues={initialValues}
    >
      <LeftPanelAbutment />
    </FormCardFF>
  );
};
