/* eslint-disable  */

import {
  remoteSubmitDone,
  resetFormDone,
} from 'app/actions/registryFormActions';
import { componentFactory } from 'app/components/registry/form/helpers/componentFactory';
import {
  BASE_PGM,
  BUILDING,
  CONTAINER,
  EMERGENCY_PARKING,
  FLOWERS_GARDEN,
  IMPROVEMENT_OBJECT,
  LAWN,
  ODH,
  OOPT,
  OTHER_OKS,
  OTHER_TECH_PLACE,
  OZN,
  STORAGE_PLACE,
  TECH_PLACE,
  TREES_SHRUBS,
  YARD,
} from 'app/constants/oghTypes';
import {
  isImprovementObject,
  isImprovementTerritoryOrOdh,
} from 'app/utils/checkOghTypeId';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import FIELDS, { fieldName } from './constants/fieldNames';
import Form from './Form';

const propTypes = {
  custom: PropTypes.object,
  dark: PropTypes.bool,
  form: PropTypes.object,
  id: PropTypes.string,
  parentTypeId: PropTypes.number,
  remoteSubmitDone: PropTypes.func,
  resetFormDone: PropTypes.func,
  typeId: PropTypes.number,
};

const defaultProps = {
  dark: true,
};

/**
 * B.
 *
 * @class
 */
class RegistryForm extends React.Component {
  /**
   * B.
   *
   * @returns {void}
   */
  componentDidUpdate() {
    const { form, id, remoteSubmitDone, resetFormDone } = this.props;

    if (form && form.isSubmitting) {
      this.form.submit();
      remoteSubmitDone(id);
    }

    if (form && form.isResetting) {
      this.form.reset();
      resetFormDone(id);
    }
  }
  /**
   * B.
   *
   * @param {*} typeId - Typew.
   * @returns {*}
   */
  getComponentsByTypeId = (typeId) => {
    if (!_get(fieldName, 'baseNumOrName', false)) {
      return [];
    }

    // todo надо потихоньку переделывать это добро на componentFactory

    const arr = [];
    if (BASE_PGM !== typeId) {
      arr.push(fieldName.idOrName);
    }
    if (BASE_PGM === typeId) {
      arr.push(fieldName.baseNumOrName);
      arr.push(fieldName.reagentBaseType);
      arr.push(fieldName.okrug);
      arr.push(fieldName.district);
      arr.push(fieldName.reagentKind);
      arr.push(fieldName.reagent);
    }
    if (OOPT === typeId) {
      arr.push(fieldName.categoryMsop);
      arr.push(fieldName.explicationType);
      arr.push(fieldName.cadNumber);
      arr.push(fieldName.ooptStatus);
      arr.push(fieldName.ooptMeaning);
      arr.push(fieldName.ooptCategory);
      arr.push(fieldName.ooptProfile);
    }

    if ([OTHER_TECH_PLACE, OTHER_OKS].includes(typeId)) {
      arr.push(fieldName.buildingsTypeSpecId);
    }

    if (isImprovementTerritoryOrOdh(typeId)) {
      arr.push(fieldName.customer);
    }

    if (
      [
        TECH_PLACE,
        STORAGE_PLACE,
        OTHER_TECH_PLACE,
        YARD,
        IMPROVEMENT_OBJECT,
        OZN,
        CONTAINER,
        BUILDING,
        OTHER_OKS,
      ].includes(typeId)
    ) {
      arr.push(fieldName.address);
    }

    if (
      ![
        ODH,
        2,
        3,
        4,
        EMERGENCY_PARKING,
        BUILDING,
        CONTAINER,
        TECH_PLACE,
        LAWN,
        FLOWERS_GARDEN,
        OTHER_TECH_PLACE,
        YARD,
        IMPROVEMENT_OBJECT,
        OZN,
        TREES_SHRUBS,
        BASE_PGM,
        OOPT,
        OTHER_OKS,
      ].includes(typeId)
    ) {
      arr.push(fieldName.subTypeId);
    }

    if ([LAWN, FLOWERS_GARDEN].includes(typeId)) {
      arr.push(fieldName.sectionNum);
    }

    if (LAWN === typeId) {
      arr.push(fieldName.lawnType);
    }

    if (TREES_SHRUBS === typeId) {
      arr.push(fieldName.plantationType);
      arr.push(fieldName.lifeFormType);
      arr.push(fieldName.plantType);
    }

    if (CONTAINER === typeId) {
      arr.push(fieldName.containerType);
      arr.push(fieldName.isSeparateGarbageCollection);
      arr.push(fieldName.parentName);
    }

    if (isImprovementObject(typeId)) {
      arr.push(fieldName.otherImprovementObjectType);
    }

    if ([BUILDING, TECH_PLACE, OTHER_TECH_PLACE, OTHER_OKS].includes(typeId)) {
      arr.push(fieldName.buildingTypeId);
    }

    if (OZN === typeId) {
      arr.push(fieldName.department);
    }

    return [
      ...arr,
      fieldName.status,
      fieldName.dateFrom,
      fieldName.dateTo,

      ...componentFactory(typeId),
    ];
  };

  /**
   * B.
   *
   * @param {*} components - Компоненты.
   * @returns {*}
   */
  filterNoComponents = (components) => {
    const { custom } = this.props;

    return components.reduce((result, component) => {
      if (!(custom && custom[component] && custom[component].no)) {
        result.push(component);
      }
      return result;
    }, []);
  };
  /**
   * B.
   *
   * @param {*} components - Компоненты.
   * @returns {*}
   */
  sortComponents = (components) => {
    return components.sort((a, b) => FIELDS.indexOf(a) - FIELDS.indexOf(b));
  };

  /**
   * B.
   *
   * @returns {*}
   */
  render() {
    const { typeId, dark } = this.props;
    return (
      <Form
        components={this.sortComponents(
          this.filterNoComponents(this.getComponentsByTypeId(typeId)),
        )}
        dark={dark}
        ref={(form) => (this.form = form)}
        {...this.props}
      />
    );
  }
}

RegistryForm.propTypes = propTypes;
RegistryForm.defaultProps = defaultProps;

/**
 * B.
 *
 * @param {*} state - State.
 * @param {*} ownProps - Own props.
 * @returns {*}
 */
const mapStateToProps = (state, ownProps) => ({
  form: state.forms[ownProps.id],
});

const mapDispatchToProps = {
  remoteSubmitDone,
  resetFormDone,
};
/**
 * A.
 *
 * @deprecated Нужно нужно или уже в задаче ODS-11860 вынести весь фильтр в отдельные фильтыю .
 * @returns {*}
 */
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(RegistryForm);
