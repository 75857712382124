import { post } from 'app/api/crud/post';
/* eslint-disable */
import { useInfiniteQueryAdaptor } from 'core/utils/hooks/useReactQuery/useInfiniteQueryAdaptor';

import { useGetTypeIdMainPage } from '../hooks/useGetTypeId.MainPage';
import { useFilterQueryParamsMain } from '../SearchPanel';

export const MAX_ROWS = 50;

/**
 * Запроса на получение счетыика и данных списка объектов.
 *
 * @param {object} params - Параметры.
 * @returns {Promise<{data: *, count: number}>}
 */
const getMainPageOgh = async (params) => {
  const { data } = await post('registry/ogh', params);
  const {
    data: { count },
  } = await post('registry/ogh/count', params);

  return { count: Math.floor(count / params.maxRows), data };
};

/**
 * Хук получения списка объектов по фильтру, типу и номеру страницы.
 *
 * @returns {object}
 */
export const useGetTreeMainPage = () => {
  const { typeId } = useGetTypeIdMainPage();
  const { prepareFilters } = useFilterQueryParamsMain();

  const params = {
    main_page: true,
    maxRows: MAX_ROWS,
    parent_type_id: -1,
    sort: 'root_id.asc',
    typeId,
    ...prepareFilters,
  };

  return useInfiniteQueryAdaptor({
    gcTime: 900000,

    /**
     *
     * @param lastPage
     * @param allPages
     * @param lastPageParam
     */
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.count > lastPageParam) {
        return ++lastPageParam;
      }
    },
    initialPageParam: 0,

    /**
     *
     * @param queryParams
     */
    queryFn: (queryParams) => {
      const { pageParam, queryKey } = queryParams;
      const [, params] = queryKey;
      return getMainPageOgh({ ...params, page: pageParam });
    },
    queryKey: ['getTreeMainPage', params],
    retry: false,
    retryOnMount: false,
    staleTime: 840000,
  });
};
