import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { EngineerBuildingTypeIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/EngineerBuildingTypeId.FF';
import { usePreparationEngineerBuildingTypeIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/EngineerBuildingTypeId.FF/usePreparation.EngineerBuildingTypeId.FF';
import { UNIT_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/UnitId';
import React from 'react';
import { useField } from 'react-final-form';

/**
 * Field FF __Тип (наименование)__.
 *
 * `name: engineer_building_type_id`.
 *
 * @param {object} props - Пропсы.
 * @param {boolean} props.disabled - Флаг редактирования.
 * @returns {JSX.Element}
 */
export const EngineerBuildingTypeIdFfField = ({ disabled }) => {
  const { typeId: typeIdParent } = useGetParentCard();
  usePreparationEngineerBuildingTypeIdFF();

  const {
    input: { onChange: onChangeUnitId },
  } = useField(UNIT_ID_NAME);

  /**
   * Функция фильтрации.
   *
   * @param {Array} dict - Справочные значения.
   * @returns {Array}
   */
  const filter = (dict) => {
    return (dict || []).filter(({ parent_ogh_object_type_list }) =>
      parent_ogh_object_type_list.includes(typeIdParent),
    );
  };

  /**
   * Функция очистки связанных полей при изменении.
   *
   * @returns {void}
   */
  const withChange = () => {
    onChangeUnitId('');
  };

  return (
    <EngineerBuildingTypeIdFF
      disabled={disabled}
      required={true}
      filter={filter}
      withChange={withChange}
      withAll={false}
    />
  );
};
