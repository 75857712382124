import { DateFromVersionFilter } from 'app/pages/cardsOgh/components/tables/Versions/Filters/Fields/DateFromVersionFilter';
import { DateToVersionFilter } from 'app/pages/cardsOgh/components/tables/Versions/Filters/Fields/DateToVersionFilter';
import { OghStatusesVersionFilter } from 'app/pages/cardsOgh/components/tables/Versions/Filters/Fields/OghStatusesVersionFilter';
import React from 'react';
 
/**
 * Фильтры по умолчанию.
 *
 * @returns {JSX.Element} - Возвращает компонент даты с, даты по и статус.
 * @example <DefaultFilters />
 */
export const DefaultFilters = () => {
  return (
    <>
      <DateFromVersionFilter />
      <DateToVersionFilter />
      <OghStatusesVersionFilter />
    </>
  );
};
