import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const CAR_EQUIPMENT_OWNER_ID_NAME = 'carEquipmentOwnerId';

/**
 * ## Владелец НО.
 *
 * @param {*} props - Properties.
 * @returns {JSX.Element}
 */
export const CarEquipmentOwnerId = (props) => {
  const dict = 'carEquipmentOwner';

  const { data = [] } = useGetDictionary(dict);

  return (
    <Select
      {...props}
      options={data}
      label={'Владелец НО'}
      name={CAR_EQUIPMENT_OWNER_ID_NAME}
    />
  );
};
