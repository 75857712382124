import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const STATE_GARDENING_ID_NAME = 'state_gardening_id';

/**
 * ## Состояние.
 *
 * @param {*} props - Properties.
 * @returns {JSX.Element}
 */
export const StateGardeningId = (props) => {
  const dict = 'stateGardening';
  const { data = [] } = useGetDictionary(dict);
  return (
    <Select
      {...props}
      label={'Состояние'}
      name={STATE_GARDENING_ID_NAME}
      options={data}
    />
  );
};
