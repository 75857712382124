 
/**
 *
 * @type {string}
 */
export const DIFF_ORIGINAL = 'diffOriginal';
 
/**
 *
 * @type {string}
 */
export const DIFF_COPY = 'diffCopy';
 
/**
 *
 * @type {string}
 */
export const FOREGROUND = 'foreground';
 
/**
 *
 * @type {string}
 */
export const INTERSECTIONS = 'intersections';
 
/**
 *
 * @type {string}
 */
export const PARENTS = 'parents';
 
/**
 *
 * @type {string}
 */
export const CHILDREN = 'children';
 
/**
 *
 * @type {string}
 */
export const ORDERS = 'orders';
 
/**
 *
 * @type {string}
 */
export const REON = 'reon';
 
/**
 *
 * @type {string}
 */
export const NEIGHBOURS = 'neighbours';
 
/**
 *
 * @type {string}
 */
export const DISTRICT_BOUNDARIES = 'districtBoundaries';
