import { ReasonFF } from 'core/form/finalForm/fields/prepared/selects/autocompleteAsync/ReasonFF';
import { REASON_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/Reason';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';
import { NumberParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

/**
 * Статус.
 *
 * @param {*} props - Properties.
 * @returns {JSX.Element}
 */
export const ReasoneFilter = (props) => (
  <ReasonFF {...props} className={filterInput} />
);

/**
 * Создание параметров фильтрации.
 *
 * @returns {*}
 */
export const reasonePreparation = () => {
  addPreparation(REASON_NAME, (value) => value, 'request_reason_id');
  return {
    [REASON_NAME]: NumberParam,
  };
};

export const typeIdTechnicParams = {
  [REASON_NAME]: NumberParam,
};
