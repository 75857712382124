import { fetchCarOwners, fetchCustomers } from 'app/api/typeahead';
import Form from 'app/components/common/KeyForm';
import { dateFromMaxDate } from 'app/utils/dateFromMaxDate';
import { dateToMinDate } from 'app/utils/dateToMinDate';
import dateValidator from 'app/utils/dateValidator';
import cn from 'classnames';
import { AutocompleteAsyncRF } from 'core/form/reduxForm/fields/default/selects/AutocompleteAsyncRF';
import { ButtonBlue } from 'core/newComponents/Button';
import TextField from 'core/newComponents/TextField';
import KeyboardDateTimePicker from 'core/uiKit/inputs/datePickers/KeyboardDateTimePicker';
import PropTypes from 'prop-types';
import React from 'react';
import { Control } from 'react-redux-form';

import searchFormWrapper from './SearchForm';

/**
 *
 */
class SearchForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    form: PropTypes.object,
    formChange: PropTypes.func,
    formValidity: PropTypes.object,
    labelStyle: PropTypes.object,
    onSubmit: PropTypes.func,
    onSubmitClick: PropTypes.func,
    reset: PropTypes.func,
  };

  /**
   * ComponentWillUnmount.
   *
   * @returns {void}
   */
  componentWillUnmount() {
    this.props.reset();
  }

  /**
   * Render.
   *
   * @returns {JSX.Element}
   */
  render() {
    const { form, onSubmit, onSubmitClick, formChange } = this.props;

    return (
      <Form
        className={cn(this.props.className)}
        model="rrf.technologyVersions"
        validators={dateValidator}
        onSubmit={onSubmit}
      >
        <div
          className="align-top mr-3 d-inline-block"
          style={{ marginTop: 10, width: 270 }}
        >
          <AutocompleteAsyncRF
            fetchFunction={fetchCarOwners}
            label="Владелец"
            placeholder="Все"
            value={form.carOwner || ''}
            onChange={(value) => {
              formChange({ key: 'carOwner', value });
            }}
          />
        </div>

        <div
          className="align-top mr-3 d-inline-block"
          style={{ marginTop: 10, width: 270 }}
        >
          <AutocompleteAsyncRF
            fetchFunction={fetchCustomers}
            label="Заказчик"
            optionKeyForMap="shortname"
            placeholder="Все"
            value={form.carCustomer || ''}
            onChange={(value) => {
              formChange({ key: 'carCustomer', value });
            }}
          />
        </div>

        <div
          className="align-top mr-3 d-inline-block"
          style={{ marginTop: 10, width: 270 }}
        >
          <KeyboardDateTimePicker
            disabledMinutes={true}
            label="Дата с"
            maxDate={dateFromMaxDate(form.startDate, form.endDate)}
            value={form.startDate}
            onChange={(dateTime) => {
              formChange({ key: 'startDate', value: dateTime });
            }}
          />
        </div>
        <div
          className="align-top mr-3 d-inline-block"
          style={{ marginTop: 10, width: 270 }}
        >
          <KeyboardDateTimePicker
            disabledMinutes={true}
            label="Дата по"
            minDate={dateToMinDate(form.startDate)}
            value={form.endDate}
            onChange={(dateTime) => {
              formChange({ key: 'endDate', value: dateTime });
            }}
          />
        </div>

        <div
          className="align-top mr-3 d-inline-block"
          style={{ marginTop: 10, width: 270 }}
        >
          <Control
            component={TextField}
            controlProps={{
              className: 'align-top mr-3 ods-mui-text-field',
              label: 'Пользователь, создавший запрос',
            }}
            model=".userCreate"
            type="text"
          />
        </div>

        <ButtonBlue
          className="align-bottom my-2"
          type="submit"
          onClick={onSubmitClick}
        >
          Поиск
        </ButtonBlue>
      </Form>
    );
  }
}

export default searchFormWrapper(SearchForm);
