/* eslint-disable  */
import {
  readyForUpdateCard,
  setEndDate,
  setStartDateCard,
} from 'app/actions/odsObjectActions';
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import DxfFileUpload from 'app/components/common/DxfFileUpload';
import { REQUIRED_FIELD_MESSAGE } from 'app/constants/messages';
import {
  APPROVED_EXTERNAL_SYSTEM,
  CANCEL,
  PROJECT,
} from 'app/constants/oghStatus';
import getDict from 'app/selectors/getDict';
import hasFileField from 'app/selectors/registry/hasFileField';
import { getServerTime } from 'app/utils/dateUtils';
import KeyboardDatePickerRF from 'core/form/reduxForm/fields/default/datePickers/KeyboardDatePickerRF';
import Select from 'core/newComponents/Select';
import { useNavigateRouter, withRouter } from 'core/utils/router';
import { find, get, isFunction } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import DialogAction from './DialogAction';
import DialogAlert from './DialogAlert';

const propTypes = {
  card: PropTypes.object,
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func,
  isMatching: PropTypes.bool,
  mode: PropTypes.object,
  oghTypes: PropTypes.arrayOf(PropTypes.object),
  onCancel: PropTypes.func,
  onCloseWarning: PropTypes.func,
  onOk: PropTypes.func,
  root: PropTypes.object,
  title: PropTypes.string,
};

const defaultProps = {
  title: 'Редактирование',
};

/**
 * DatePicker.
 *
 * @param {{onUpdate: Function}} props - Пропсы компонента.
 * @returns {JSX.Element} - Компоненты даты.
 * @example
 * <DatePicker {...props}/>
 */
const DatePicker = ({ onUpdate, ...otherProps }) => (
  <KeyboardDatePickerRF
    {...otherProps}
    onChange={(date) =>
      isFunction(onUpdate) && date?._isValid && onUpdate(date)
    }
  />
);

/**
 * DialogUpdate.
 *
 * @class
 */
class DialogUpdate extends React.Component {
  /**
   * ComponentDidUpdate.
   *
   * @returns {void} - Nothing.
   * @example componentDidUpdate
   */
  componentDidUpdate() {
    const { mode, navigate, dispatch } = this.props;

    const cardPath =
      window.location.href.indexOf('/tech/') !== -1 ? '/tech' : '/ogh';
    if ((mode && mode.doneUpdateMode) || false) {
      if (mode && mode.relocate) {
        navigate(`${cardPath}/${mode.relocate}`);
      }
      dispatch(readyForUpdateCard());
    }
  }

  /**
   *
   * @param root0
   * @param root0.input
   * @param root0.options
   */
  PreloadedDictionary = ({ input, options, ...custom }) => {
    const selectedOption = find(options, {
      id: input.value,
    });

    return (
      <Select
        name={input.name}
        options={selectedOption ? [selectedOption] : []}
        value={input.value}
        onChange={(value) => {
          return input.onChange(value);
        }}
        {...custom}
      />
    );
  };

  /**
   * Render.
   *
   * @returns {React.ReactComponentElement} Компонент реакта.
   * @example render() { return <div/>}
   */
  render() {
    const {
      card,
      dispatch,
      handleSubmit,
      isMatching,
      mode,
      navigate,
      oghTypes,
      onCancel,
      onCloseWarning,
      onOk,
      // root,
      title,
      rootCard,
    } = this.props;

    const rootIsProject =
      rootCard &&
      (!rootCard.logical_state_id ||
        rootCard.logical_state_id === PROJECT ||
        rootCard.logical_state_id === APPROVED_EXTERNAL_SYSTEM ||
        rootCard.logical_state_id === CANCEL);
    const cardPath =
      window.location.href.indexOf('/tech/') !== -1 ? '/tech' : '/ogh';

    return (
      <React.Fragment>
        <DialogAction
          className="Dialog-Update"
          showCondition={
            (!rootIsProject && !isMatching && mode && mode.editModeAllow) ||
            false
          }
          title={title}
          onCancel={onCancel}
          onOk={handleSubmit(onOk)}
        >
          <form id="edit-form" name="editForm">
            <div className="field-group flex-nowrap">
              <DatePicker
                id="startDate"
                label={'Действует с'}
                minDate={minDate()}
                name="startDate"
                className={'m-1'}
                onUpdate={(value) => {
                  const restrict_dates = (mode && mode.restrict_dates) || [];
                  if (!restrict_dates.some((item) => item - value === 0)) {
                    dispatch(
                      setStartDateCard(card.root_id, card.type_id, value),
                    );
                  } else {
                    dispatch(setEndDate(null));
                  }
                }}
              />
              <DatePicker
                disabled={true}
                label="Действует по"
                id="endDate"
                className={'m-1'}
                name="endDate"
              />
            </div>
            <div className="field-group">
              <Field
                component={this.PreloadedDictionary}
                disabled={true}
                label="Тип"
                name="type"
                options={oghTypes}
              />
            </div>
            {hasFileField(card.type_id) && (
              <Field component={DxfFileUpload} name="file" />
            )}
          </form>
        </DialogAction>

        <DialogAlert
          showCondition={
            mode && mode.errorUpdateMode && !mode.isAllow && !mode.geometry
          }
          onAfterHiding={onCloseWarning}
        >
          {get(mode, 'message', '')}
        </DialogAlert>

        <DialogAlert
          showCondition={mode && mode.errorStartUpdateMode}
          onAfterHiding={onCancel}
        >
          {get(mode, 'message', '')}
        </DialogAlert>

        <DialogAction
          cancelText="Закрыть"
          okText="Перейти"
          showCondition={mode && mode.editModeDenyOnVersion}
          title="Редактирование запрещено"
          onCancel={onCancel}
          onOk={() => {
            navigate(`${cardPath}/${mode.version}`);
          }}
        >
          Данный объект имеет версию в статусе «Проект».
        </DialogAction>

        <DialogAlert
          showCondition={isMatching && mode && mode.editModeAllow}
          onAfterHiding={onCancel}
        >
          Объект на согласовании
        </DialogAlert>
      </React.Fragment>
    );
  }
}

DialogUpdate.propTypes = propTypes;
DialogUpdate.defaultProps = defaultProps;

/**
 * MinDate.
 *
 * @returns {object} - Дата.
 * @example  <DatePicker minDate={minDate()}/>
 */
const minDate = () => {
  return getServerTime()
    .set({
      hour: 0,
      milliseconds: 0,
      minute: 0,
      seconds: 0,
    })
    .add({
      days: 1,
    })
    .toDate();
};

/**
 * ParseDate.
 *
 * @param {string} string - Строка.
 * @returns {object} - Дата.
 * @example parseDate(mode.end_date)
 */
const parseDate = (string) => {
  let result = null;
  if (string) {
    const date = string.split('.');
    result = new Date(date[2], date[1] - 1, date[0]);
  }
  return result;
};

/**
 * Валидация диалога update.
 *
 * @param {object} values - Значения формы.
 * @param {object} props - Пропсы формы.
 * @returns {object} - Объект с ошибками.
 * @example reduxForm({
 *     form: 'editCardForm',
 *     validate,
 *   })(withRouter(DialogUpdate)),
 */
const validate = (values, props) => {
  const errors = {};
  const restrict_dates = (props.mode && props.mode.restrict_dates) || [];

  if (!moment(values.startDate).isValid()) {
    errors.startDate = 'Недопустимый формат даты';
  }
  if (values.startDate === null) {
    errors.startDate = REQUIRED_FIELD_MESSAGE;
  } else if (restrict_dates.some((item) => item - values.startDate === 0)) {
    errors.startDate = 'дата занята утверждённой версией';
  }
  return errors;
};

const getOghTypes = getDict('oghTypes');

/**
 * MapStateToProps.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {{initialValues: {file: null, endDate: ({start_date}|*|string|null), type: null, startDate: (string|*|null)}, enableReinitialize: boolean, oghTypes: *}} - Параметры которые берем из стейта.
 * @example connect(mapStateToProps)(<Comp/>)
 */
const mapStateToProps = (state, props) => {
  const { mode } = props;
  return {
    enableReinitialize: true,

    initialValues: {
      endDate: (mode && mode.end_date && parseDate(mode.end_date)) || null,
      file: null,
      startDate:
        (mode && mode.start_date && parseDate(mode.start_date)) || null,
      type: props.card.type_id || null,
    },

    oghTypes: getOghTypes(state),
  };
};

const Comp = connect(mapStateToProps)(
  reduxForm({
    form: 'editCardForm',
    validate,
  })(withRouter(DialogUpdate)),
);

/**
 * Обертка чтобы прокинуть значения из хуков.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - Компонент.
 * @example -
 */
const DialogUpdateDefault = (props) => {
  const navigate = useNavigateRouter();
  const rootCard = useGetParentCard();
  return <Comp {...props} {...{ navigate, rootCard }} />;
};

export default DialogUpdateDefault;
