/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import getDisplayName from 'core/hocs/getDisplayName';

const propTypes = {
  className: PropTypes.string,
};

function buttonOutlined(Component) {
  class ButtonOutlined extends React.Component {
    render() {
      return <Component {...this.props} variant="outlined" />;
    }
  }

  ButtonOutlined.propTypes = propTypes;
  ButtonOutlined.displayName = `buttonOutlined(${getDisplayName(Component)})`;

  return ButtonOutlined;
}

 
export default buttonOutlined;
