import { TabsCardItem } from 'core/uiKit/components/TabsCard/TabsCardItem';
import React from 'react';

import { AddButtonRepairsTab } from './Add.Button.Repairs.Tab';
import { TableRepairsTab } from './Table.Repairs.Tab';

export const REPAIRS_INFO_LIST_NAME = 'repairs_info_list';

/**
 * Таб Ремонты.
 *
 * @returns JSX.Element.
 */
export const RepairsTab = () => {
  return (
    <TabsCardItem tabValue={REPAIRS_INFO_LIST_NAME}>
      <div className={'d-flex justify-content-end'}>
        <AddButtonRepairsTab />
      </div>
      <TableRepairsTab />
    </TabsCardItem>
  );
};
