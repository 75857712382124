import { RANDOM_ID } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import { MafTypeLevel1RFWrapper } from './MafTypeLevel1RFWrapper';

describe('MafTypeLevel1RfWrapperSpec', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act

    const wrapper = shallow(<MafTypeLevel1RFWrapper />);
    // ❓ Assert
    expect(wrapper.text()).toBe('<MafTypeLevel1RF />');
  });

  it('withChange', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const changeFieldValue = vi.fn();
    const mafTypeLevel1 = [{ id: 1, unit_id: RANDOM_ID }];
    const getQuantityCharacteristicsProps = vi.fn(() => ({ disabled: true }));
    // 🔥 Act

    const wrapper = shallow(
      <MafTypeLevel1RFWrapper
        changeFieldValue={changeFieldValue}
        mafTypeLevel1={mafTypeLevel1}
        getQuantityCharacteristicsProps={getQuantityCharacteristicsProps}
      />,
    );
    const { withChange } = wrapper.props();
    withChange(1);
    // ❓ Assert
    expect(changeFieldValue).toHaveBeenCalledWith('maf_type_level2_id', null);
    expect(changeFieldValue).toHaveBeenCalledWith('maf_type_level3_id', null);
    expect(changeFieldValue).toHaveBeenCalledWith('unit_id', RANDOM_ID);
    expect(changeFieldValue).toHaveBeenCalledWith(
      'maf_quantity_characteristics',
      null,
    );
  });
  it('filter', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    // 🔥 Act

    const wrapper = shallow(<MafTypeLevel1RFWrapper parentTypeId={1} />);
    const { filter } = wrapper.props();
    const expected = filter([
      {
        parent_ogh_object_type_list: [1, 2, 3],
      },
      {
        parent_ogh_object_type_list: [4, 5, 6],
      },
    ]);
    // ❓ Assert
    expect(expected).toStrictEqual([
      {
        parent_ogh_object_type_list: [1, 2, 3],
      },
    ]);
  });
});
