import { rowSpanCreate } from './rowSpanCreate';

/* eslint-disable  */
/**
 *
 * @param сolumns
 */
export const preparationColumns = (сolumns) => {
  return сolumns.map((column) => {
    let newCol = { ...column };

    if (newCol.columns) {
      newCol.columns = preparationColumns(column.columns);
    }

    if (newCol.rowSpan) {
      newCol = {
        ...newCol,
        ...rowSpanCreate(column.rowSpan, newCol),
      };
    }

    return newCol;
  });
};
