export const arrange_element_type = [
  {
    attribute: {
      code: 'solar_power_plant',
      migr_code: [],
      name: 'Автономная электростанция на солнечной энергии',
      ogh_object_type_id: {
        ogh_object_type: 35,
      },
      parent_ogh_object_type_list: [49],
      unit_id: {
        unit: 5,
      },
    },
    id: 101001,
  },
  {
    attribute: {
      code: 'wireless_internet_module',
      migr_code: [],
      name: 'Автономный модуль для организации беспроводного доступа в интернет',
      ogh_object_type_id: {
        ogh_object_type: 35,
      },
      parent_ogh_object_type_list: [49],
      unit_id: {
        unit: 5,
      },
    },
    id: 101000,
  },
  {
    attribute: {
      code: 'overall_column',
      migr_code: [],
      name: 'Габаритный столбик',
      ogh_object_type_id: {
        ogh_object_type: 35,
      },
      parent_ogh_object_type_list: [38, 39, 40, 49],
      unit_id: {
        unit: 5,
      },
    },
    id: 100848,
  },
  {
    attribute: {
      code: 'gabion',
      migr_code: [],
      name: 'Габион',
      ogh_object_type_id: {
        ogh_object_type: 34,
      },
      parent_ogh_object_type_list: [38, 39, 40, 49],
      unit_id: {
        unit: 2,
      },
    },
    id: 49,
  },
];
