import { GeometryUtils } from '../../..';
import Vertex from '../../../DrawService/utils/Vertex';

/**
 * Класс для работы с отрезками линий и полигонов.
 */
export default class Side {
  //

  /**
   * Начальные координаты линии.
   */
  public start: number[];

  /**
   * Конечные координаты линии.
   */
  public end: number[];

  /**
   * Наведен ли на линию курсор мыши.
   */
  public isHovered: boolean = false;

  /**
   * Индекс начальной точки линии в родительской геометрии.
   */
  public startIndex: number;

  /**
   * Индекс конечной точки линии в родительской геометрии.
   */
  public endIndex: number;

  public geometryUtils: GeometryUtils;

  public subGeometryIndex: number;

  /**
   * Конструктор.
   *
   * @param coordinates - Координаты геометрии.
   * @param startIndex - Индекс начальной точки линии.
   * @param endIndex - Индекс конечной точки линии.
   * @param subGeometryIndex - Индекс подгеометрии в геометрии.
   * @param geometryUtils - Утилиты для геометрий.
   */
  constructor(
    coordinates: number[][],
    startIndex: number,
    endIndex: number,
    subGeometryIndex: number,
    geometryUtils: GeometryUtils,
  ) {
    [this.start, this.end] = coordinates;
    this.startIndex = startIndex;
    this.endIndex = endIndex;
    this.subGeometryIndex = subGeometryIndex;
    this.geometryUtils = geometryUtils;
  }

  /**
   * Наведена ли на линию курсор мыши.
   *
   * @param event - Событие.
   * @param event.lngLat - Координаты курсора мыши.
   * @returns Наведена ли на линию курсор мыши.
   */
  public isMouseHovered({ lngLat }: { lngLat: number[] }) {
    const isHovered = this.geometryUtils.isPointOnLine(lngLat, [
      this.start,
      this.end,
    ]);
    this.isHovered = isHovered;

    return isHovered;
  }

  /**
   * Добавляет новую вершину в отрезок и возвращает два новых отрезка.
   *
   * @param {Vertex} vertex - Объект вершины.
   * @returns Координаты новых отрезков.
   */
  public appendNewVertex(vertex: Vertex) {
    const newSideA = new Side(
      [this.start, vertex.coordinates],
      this.startIndex,
      this.endIndex,
      vertex.subGeometryIndex,
      this.geometryUtils,
    );
    const newSideB = new Side(
      [vertex.coordinates, this.end],
      this.endIndex,
      this.endIndex + 1,
      vertex.subGeometryIndex,
      this.geometryUtils,
    );

    return [newSideA, newSideB];
  }
}
