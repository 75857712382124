import { ButtonMUI } from 'core/uiKit/material-ui';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  component: PropTypes.elementType,
  disableFocusRipple: PropTypes.bool,
  disableRipple: PropTypes.bool,
  disabled: PropTypes.bool,
  endIcon: PropTypes.node,
  fullWidth: PropTypes.bool,
  href: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  startIcon: PropTypes.node,
  style: PropTypes.object,
  title: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
};

const defaultProps = {
  disabled: false,
  variant: 'contained',
};

/**
 * Кнопка.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
function Button(props) {
  const {
    className,
    children,
    color,
    component,
    disabled,
    disableFocusRipple,
    disableRipple,
    endIcon,
    fullWidth,
    href,
    id,
    size,
    startIcon,
    style,
    title,
    type,
    variant,
    onClick,
    ...rest
  } = props;
  return (
    <ButtonMUI
      className={className}
      color={color}
      component={component}
      disabled={disabled}
      disableFocusRipple={disableFocusRipple}
      disableRipple={disableRipple}
      endIcon={endIcon}
      fullWidth={fullWidth}
      href={href}
      id={id}
      size={size}
      startIcon={startIcon}
      style={style}
      title={title}
      type={type}
      variant={variant}
      onClick={onClick}
      {...rest}
    >
      {children}
    </ButtonMUI>
  );
}

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
