import { Tabs } from 'app/components/card/common/tabs';
import DocumentsTab from 'app/components/card/ogh/DocumentsTab';
import PropTypes from 'prop-types';
/* eslint-disable */
import React from 'react';

import CharacteristicsTab from './CharacteristicsTab';

const tabs = [
  {
    id: 'characteristics-tab',
    name: 'Исходные данные',
  },
  {
    id: 'docs-tab',
    name: 'Документы',
  },
];

const propTypes = {
  card: PropTypes.object,
  editMode: PropTypes.bool,
  required: PropTypes.bool,
  documents: PropTypes.object,
};

function PropertiesTabs({ card, editMode, required, documents }) {
  return (
    <div>
      <Tabs tabs={tabs} />
      <CharacteristicsTab card={card} editMode={editMode} required={required} />
      {DocumentsTab()({
        typeId: card.type_id,
        documents: documents,
        editable: editMode,
      })}
    </div>
  );
}

PropertiesTabs.propTypes = propTypes;

 
export default PropertiesTabs;
