import './dialogTitle.scss';

import { CloseIcon, DialogTitleMUI, IconButton } from 'core/uiKit/material-ui';

/**
 * Компонент DialogTitle.
 *
 * @param {object} props - Функция закрытия.
 * @param {React.ReactNode} props.children - Дети.
 * @param {Function} props.onClose - Функция закрытия.
 * @returns {JSX.Element}
 */
export const DialogTitle = ({ children, onClose }) => {
  return (
    <DialogTitleMUI id={'draggable-dialog-title'} className={'dialog__title'}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          className={'close-button'}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitleMUI>
  );
};
