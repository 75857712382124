 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */

import { YesOrNoCell } from 'core/uiKit/components/cells/YesOrNo.Cell';
 
/* eslint-disable */
/**
 * Колонка __Входит в общую площадь ОДХ__.
 *
 * @type {{ accessor: 'is_object_area', Header: 'Входит в общую площадь ОДХ' }}
 * @augments Cell
 */
export const is_object_area = {
  accessor: 'is_object_area',
  Header: 'Входит в общую площадь ОДХ',
  Cell: YesOrNoCell,
  sortable: true,
};
