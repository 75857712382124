import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { ODH, OZN, YARD } from 'app/constants/oghTypes';
import { Button } from 'core/uiKit/components/buttons/Button';
import { Menu } from 'core/uiKit/components/Menu';
import { ExpandMoreIcon } from 'core/uiKit/material-ui';
import React, { useState } from 'react';

import { ToFormDxfMenuItem } from './itemsMenu/ToFormDXF.MenuItem';
import { ToFormPDFMenuItem } from './itemsMenu/ToFormPDF.MenuItem';

/**
 * Кнопка __Экспорт плана-схемы__.
 *
 * @returns {JSX.Element|null}
 */
export const ExportingBusinessPlanButton = () => {
  const { typeId } = useGetCurrentCard();
  const [anchorEl, setAnchorEl] = useState(null);

  /**
   * Функция handleClick.
   *
   * @param {Event} event - Event.
   * @returns {void}
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Функция handleClose.
   *
   * @returns {void}
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toolTip =
    typeId === ODH
      ? 'Экспорт данных доступен для паспортизированных объектов. Выгрузка согласно утвержденной форме паспорта ОДХ (№ 501-ПП в редакции от 04.04.2023)'
      : '';

  const titleButton = [YARD, OZN].includes(typeId)
    ? 'Экспорт инв.плана'
    : 'Экспорт плана-схемы';

  return [ODH, YARD, OZN].includes(typeId) ? (
    <>
      <Button
        toolTip={toolTip}
        onClick={handleClick}
        variant="contained"
        color="primary"
        endIcon={<ExpandMoreIcon />}
      >
        {titleButton}
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="simple-menu"
        keepMounted={true}
        open={Boolean(anchorEl)}
        PaperProps={{
          style: {
            maxHeight: 450,
          },
        }}
        onClose={handleClose}
      >
        <ToFormDxfMenuItem />
        <ToFormPDFMenuItem />
      </Menu>
    </>
  ) : null;
};
