import { useFetchOghTable } from 'app/pages/registry/ogh/api';
/* eslint-disable  */
import { queryClient } from 'app/routes/QueryClientProvider';
import { useParamsTable } from 'core/uiKit/components/tables/Table';

import { useFilterQueryParamsMafRfid } from './Filter.MafRfid';

const CACHE_KEY = 'fetchTableMafRfid';

/**
 *
 */
export const resetTableMafRfidCache = () =>
  queryClient.invalidateQueries(CACHE_KEY);

/**
 *
 * @param root0
 * @param root0.typeId
 */
export const useSetupTableMafRfid = ({ typeId }) => {
  const { prepareFilters } = useFilterQueryParamsMafRfid();
  const { queryTable } = useParamsTable();

  const queryKey = [CACHE_KEY, { ...queryTable, ...prepareFilters, typeId }];

  const { table = [], isLoading, count = 0 } = useFetchOghTable(queryKey);

  return { count, isLoading, table };
};
