import { Table } from 'core/uiKit/components/tables/Table';
import React from 'react';

import { TableOghChildrenProps } from '../../types';
import { getColumnsFunctionality } from './columns.Functionality';
import { useSetupTableFunctionality } from './useSetup.Table.Functionality';

/**
 * Компонент таблицы __Системы функционального обеспечения__.
 *
 * @param {TableOghChildrenProps} props - Пропсы.
 * @returns JSX.
 * @example -----
 * <TableFunctionality
 *  typeId={typeId}
 *  parentTypeId={3}
 * />
 */
export const TableFunctionality = (props: TableOghChildrenProps) => {
  const { table, isLoading, count } = useSetupTableFunctionality(props);

  return (
    <Table
      columns={getColumnsFunctionality()}
      data={table || []}
      isLoading={isLoading}
      total={count}
    />
  );
};
