import { Body } from 'app/pages/components/Body';
import { Header } from 'app/pages/components/Header';
import { Main } from 'app/pages/components/Main';
import { DialogCreateSimple } from 'app/pages/components/modals/DialogCreateSimple';
import { RegistryToolbar } from 'app/pages/components/RegistryToolbar';
import { TableRedBookPlant } from 'app/pages/registry/ogh/yard/RedBookPlant/Table.RedBookPlant';
import { TreePanelYard } from 'app/pages/registry/ogh/yard/TreePanelYard';
import { useGetOghTypes } from 'core/utils/hooks/useGetOghTypes';
import React from 'react';

import { FilterRedBookPlant } from './Filter.RedBookPlant';
import { PrintButtonRedBookPlant } from './PrintButton.RedBookPlant';

/**
 * Реестр __Растения Красной книги__.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.parentTypeId - TypeId родителя.
 * @returns {JSX.Element}
 */
const RedBookPlant = ({ parentTypeId }) => {
  const oghType = useGetOghTypes('redBookPlant');
  const typeId = oghType?.id;

  return oghType?.id ? (
    <Main>
      <Header Filter={<FilterRedBookPlant parentTypeId={parentTypeId} />} />
      <Body>
        <RegistryToolbar showToggle={true} title={oghType.name}>
          <PrintButtonRedBookPlant
            typeId={typeId}
            parentTypeId={parentTypeId}
          />
        </RegistryToolbar>

        <div className={'d-flex h-100 overflow-hidden-x'}>
          <TreePanelYard />
          <TableRedBookPlant typeId={typeId} parentTypeId={parentTypeId} />
        </div>

        <DialogCreateSimple typeId={typeId} />
      </Body>
    </Main>
  ) : null;
};

export default RedBookPlant;
