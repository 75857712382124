import { post } from 'app/api/crud/post';
/* eslint-disable */
import { uploadFileReturnArrStorageId } from 'app/api/requests/uploadFileReturnArrStorageId';

/**
 *
 * @param root0
 * @param root0.files
 */
export const externalAccept = async ({ files = [], ...queryParams }) => {
  let fileIds = await uploadFileReturnArrStorageId(files);

  const params = { ...queryParams };
  if (fileIds?.length) {
    params.fileIds = fileIds;
  }

  return post('/external/accept', params);
};
