import {
  Body,
  DialogCreateSimple,
  Header,
  Main,
  RegistryToolbar,
} from 'app/pages/components';
import { TreePanelYard } from 'app/pages/registry/ogh/yard/TreePanelYard';
import { useGetOghType } from 'core/utils/hooks/useGetOghType';
import React from 'react';

import { FilterFunctionality } from './Filter.Functionality';
import { PrintButtonFunctionality } from './PrintButton.Functionality';
import { TableFunctionality } from './Table.Functionality';

/**
 * Реестр __Системы функционального обеспечения__.
 *
 * @param {object} props - Пропсы.
 * @param {Array} props.tree - Дерево.
 * @param {number} props.parentTypeId - TypeId родителя.
 * @returns {JSX.Element}
 */
const Functionality = ({ tree, parentTypeId }) => {
  const oghType = useGetOghType('functionality');
  const typeId = oghType.id;

  return (
    <Main>
      <Header
        Filter={() => (
          <FilterFunctionality typeId={typeId} parentTypeId={parentTypeId} />
        )}
      />
      <Body>
        <RegistryToolbar showToggle={true} title={oghType.name}>
          <PrintButtonFunctionality
            typeId={typeId}
            parentTypeId={parentTypeId}
          />
        </RegistryToolbar>

        <div className={'d-flex h-100 overflow-hidden-x'}>
          <TreePanelYard tree={tree} />
          <TableFunctionality typeId={typeId} parentTypeId={parentTypeId} />
        </div>
        <DialogCreateSimple typeId={typeId} />
      </Body>
    </Main>
  );
};

export default Functionality;
