import './IconAccordion.scss';

import cn from 'classnames';
import React from 'react';

/**
 * Иконка кастомного аккордиона для дерева элементов.
 *
 * @param {*} props - Props.
 * @param {Function} props.onClick - Function click.
 * @param {string} props.className - Class name.
 * @param {boolean} props.isActive - Flag active.
 * @param {boolean} props.containsChild - Flag.
 * @returns {JSX.Element}
 */
export const IconAccordion = ({
  onClick,
  className,
  isActive,
  containsChild,
}) => {
  return (
    <i
      className={cn('icon-arrow-card', className, {
        'icon-arrow-card__active': isActive,
        'icon-arrow-card__contains-child': containsChild,
      })}
      onClick={onClick}
    >
      <svg
        width="10"
        height="7"
        viewBox="0 0 9 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M4.5 6L0 7.0663e-07L9 0L4.5 6Z" />
      </svg>
    </i>
  );
};
