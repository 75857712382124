import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { parseDate } from 'app/components/card/common/parse';
import { useFilterQueryParamsVersionDefault } from 'app/pages/cardsOgh/components/tables/Versions/Filters/useFilterQueryParamsVersionDefault';
import { useMemo } from 'react';

/**
 * Хук useInitialValues.
 *
 * @returns {object} - Возвращает параметры полей по умолчанию.
 * @example
 * useInitialValues()
 */
export const useInitialValues = () => {
  const { startDate, endDate, attribute } = useGetCurrentCard();
  const { filters } = useFilterQueryParamsVersionDefault();

  return useMemo(() => {
    return {
      ...filters,
      area: attribute?.area,
      createTypeId: attribute?.create_type_id?.create_type,
      endDate: parseDate(endDate),
      is_diff_height_mark: !!attribute?.is_diff_height_mark,
      noCalc: attribute?.no_calc,
      startDate: parseDate(startDate),
      totalAreaGeo: attribute?.total_area_geo,
      waterTypeId: attribute?.water_type_id?.water_type,
    };
    // eslint-disable-next-line
  }, [startDate, endDate, attribute]);
};
