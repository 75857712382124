import { getValidator } from 'core/form/finalForm/utils/validate';
/* eslint-disable  */

/**
 *
 * @param values
 */
export const validateForm = (values) => {
  const { setRequired, hasErrors, getErrors, setNotZero } =
    getValidator(values);

  setRequired('redBookPlantId');
  setRequired('sectionNum');
  setRequired('greenNum');
  setNotZero('sectionNum');
  setNotZero('greenNum');

  return hasErrors() ? getErrors() : null;
};
