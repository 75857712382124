import {
  DEPARTMENT_ID_NAME,
  DepartmentId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/DepartmentId';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * ## Отраслевой ОИВ
 *
 * ### React Final Form Field
 *
 * ###### type = `Select`
 * ###### name = `department_iD`
 *
 * @param {object} propsMain - Parameters.
 * @returns {JSX.Element}
 */
export const DepartmentIdFF = (propsMain) => {
  return (
    <Field name={DEPARTMENT_ID_NAME}>
      {(props) => {
        return <DepartmentId {...props} {...props.input} {...propsMain} />;
      }}
    </Field>
  );
};

export { DEPARTMENT_ID_NAME };
