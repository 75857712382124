import { CreateTypeCell } from 'app/utils/getOghRegistryColumns/cells/CreateTypeCell';
import { CheckboxCell } from 'core/uiKit/components/cells/Checkbox.Cell';
import { LinkShortIdCell } from 'core/uiKit/components/cells/LinkShortId.Cell';

 
export /* eslint-disable */
const columnsImprovementObject = [
  {
    accessor: 'short_root_id',
    Cell: LinkShortIdCell,
    Header: 'ID',
    sortable: true,
  },
  {
    accessor: 'okrug_name',
    Header: 'Округ',
    sortable: true,
  },
  {
    accessor: 'district_name',
    Header: 'Район',
    sortable: true,
  },
  {
    accessor: 'object_name',
    Header: 'Наименование',
    sortable: true,
  },
  {
    accessor: 'owner_name',
    Header: 'Балансодержатель',
    sortable: true,
  },
  {
    accessor: 'other_improv_object_type_name',
    Header: 'Тип ИОБ',
    sortable: true,
  },
  {
    accessor: 'improvement_category_name',
    Header: 'Категория благоустройства',
    sortable: true,
  },
  {
    accessor: 'improvement_object_cat_name',
    Header: 'Категория озеленения',
    sortable: true,
  },
  {
    accessor: 'total_area',
    Header: 'Общая площадь, кв.м.',
    sortable: true,
  },
  {
    accessor: 'total_clean_area',
    Header: 'Уборочная площадь, кв.м.',
    sortable: true,
  },
  {
    accessor: 'create_type',
    Header: 'Тип создания объекта',
    sortable: true,
    Cell: CreateTypeCell,
  },
  {
    accessor: 'object_status_name',
    Header: 'Статус',
    sortable: true,
    width: 110,
  },
  {
    accessor: 'start_date',
    Header: 'Дата начала',
    sortable: true,
    width: 90,
  },
  {
    accessor: 'end_date',
    Header: 'Дата окончания',
    sortable: true,
    width: 120,
  },
  {
    accessor: 'reapprove_required',
    Header: 'Требует повторного утверждения',
    sortable: true,
    Cell: CheckboxCell,
  },
  {
    accessor: 'update_required',
    Header: 'Требует актуализации',
    sortable: true,
    Cell: CheckboxCell,
  },
  {
    accessor: 'sign_date',
    Header: 'Дата подписания',
    sortable: true,
  },
  {
    accessor: 'sign_person',
    Header: 'ФИО подписанта',
    sortable: true,
  },
];
