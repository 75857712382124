import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { clearCurrentCardCash } from 'app/api/hooks/useGetCurrentCard/clearCurrentCardCash';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

import { fetchMatchingSend } from './api/fetchMatchingSend';
import { validateDialog } from './validateDialog';

/**
 * DialogSendMatching.
 *
 * @param {*} props - The props.
 * @param {boolean} props.open - Open.
 * @param {Function} props.setOpen - F.
 * @returns {JSX.Element}
 */
export const useSetupDialogSendMatching = ({ setOpen, showAlert }) => {
  const { rootId, startDate, parentId, typeId } = useGetCurrentCard();

  /**
   * Close the dialog.
   *
   * @returns {void}
   */
  const closeDialog = () => {
    setOpen((pre) => ({ ...pre, open: false }));
  };

  const [matchingSend, { isLoading }] = useMutationAdaptor(fetchMatchingSend, {

    /**
     * Кейс с ошибкой.
     *
     * @param {object} err - Ошибка.
     */
    onError: (err) => {
      showAlert(err.message);
      closeDialog();
    },

    /**
     * Успешный кейс.
     *
     */
    onSuccess: () => {
      showAlert('Объект отправлен на согласование.');
      closeDialog();
      clearCurrentCardCash();
    },
  });

  /**
   * OnSubmit.
   *
   * @param {*} values - Values.
   * @returns {*}
   */
  const onSubmit = (values) => {
    const errors = validateDialog(values);
    if (Object.values(errors).length) return errors;
    matchingSend({
      ...values,
      id: rootId,
      lastMatchDate: startDate,
      typeChange: parentId ? 'edit_ogh' : 'create_ogh',
      typeId,
    });
  };

  return { closeDialog, isLoading, onSubmit };
};
