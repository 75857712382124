import { fetchCancelEdit } from 'app/api/ogh';

import { postRollbackTreeChanges } from './postRollbackTreeChanges';

/**
 * Метод старта отмены.
 *
 * @param params - Параметры.
 * @param params.recordId - Рекорд ID.
 * @param params.rootId - Root ID.
 * @param params.typeId - Тип ОГХ.
 * @returns {*}
 */
export const startFetchCansel = async ({
  recordId,
  rootId,
  typeId,
}: {
  recordId: number | string;
  rootId: number | string;
  typeId: number | string;
}) => {
  await postRollbackTreeChanges({
    id: recordId,
    journal: null,
    type_id: typeId,
  });
  return await fetchCancelEdit({ recordId, rootId });
};
