import { LinkShortIdCell } from 'core/uiKit/components/cells/LinkShortId.Cell/index';

export const otherOksColumns = [
  {
    Cell: LinkShortIdCell,
    Header: 'ID',
    accessor: 'root_id',
    minWith: 120,
  },
  {
    Header: 'Назначение',
    accessor: 'buildings_type',
  },
  {
    Header: 'Уточнение назначения',
    accessor: 'buildings_type_spec',
  },
  {
    Header: 'Адрес',
    accessor: 'address_name',
  },
  {
    Header: 'Площадь, кв.м.',
    accessor: 'area',
  },
  {
    Header: 'Характеристика',
    accessor: 'property',
  },
  {
    Header: 'Статус',
    accessor: 'object_status_name',
  },
  {
    Header: 'Дата начала действия',
    accessor: 'start_date',
  },
  {
    Header: 'Дата окончания действия',
    accessor: 'end_date',
  },
];
