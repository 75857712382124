import {
  Body,
  DialogCreateSimple,
  Header,
  Main,
  RegistryToolbar,
} from 'app/pages/components';
import { TreePanelYard } from 'app/pages/registry/ogh/yard/TreePanelYard';
import { useGetOghTypes } from 'core/utils/hooks/useGetOghTypes';

import { FilterWaterBuilding } from './Filter.WaterBuilding';
import { PrintButtonWaterBuilding } from './PrintButton.WaterBuilding';
import { TableWaterBuilding } from './Table.WaterBuilding';

/**
 * Реестр __Водные сооружения__.
 *
 * @param {object} props - Пропсы.
 * @param {Array} props.tree - Дерево.
 * @param {number} props.parentTypeId - TypeId родителя.
 * @returns {JSX.Element}
 */
const WaterBuilding = ({ tree, parentTypeId }) => {
  const oghType = useGetOghTypes('waterBuilding');
  const typeId = oghType.id;

  return (
    <Main>
      <Header
        Filter={
          <FilterWaterBuilding typeId={typeId} parentTypeId={parentTypeId} />
        }
      />
      <Body>
        <RegistryToolbar showToggle={true} title={oghType.name}>
          <PrintButtonWaterBuilding
            typeId={typeId}
            parentTypeId={parentTypeId}
          />
        </RegistryToolbar>

        <div className={'d-flex h-100 overflow-hidden-x'}>
          <TreePanelYard tree={tree} />
          <TableWaterBuilding typeId={typeId} parentTypeId={parentTypeId} />
        </div>
        <DialogCreateSimple typeId={typeId} />
      </Body>
    </Main>
  );
};

export default WaterBuilding;
