/* eslint-disable */
import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import {
  CoatingGroupId,
  COATING_GROUP_ID_NAME,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingGroupId';

const CoatingGroupIdRFWithReduxForm = ({ input, ...props }) => {
  return <CoatingGroupId {...input} {...props} />;
};

/* eslint-disable */
export const CoatingGroupIdRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={COATING_GROUP_ID_NAME}
      component={CoatingGroupIdRFWithReduxForm}
    />
  );
};

CoatingGroupIdRF.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
};
CoatingGroupIdRF.defaultProps = {
  disabled: false,
};
