import { getFieldsMandatoryMessage } from 'app/components/card/ogh/trees_shrubs/getFieldsMandatoryMessage';
import { PLANT_SERVICE_RECOMENDATIONS_ID_NAME } from 'core/uiKit/preparedInputs/selects/multiSelect/PlantServiceRecomendationsId/index';
import { STATE_GARDENING_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/StateGardeningId/index';

describe('GetFieldsMandatoryMessageJestSpec', function () {
  it('test', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const currentValues = {
      [STATE_GARDENING_ID_NAME]: 2,
    };
    // Act

    // Assert
    expect(getFieldsMandatoryMessage(currentValues)).toStrictEqual({
      'Исходные данные': [
        'age',
        'area',
        'bio_group_num',
        'characteristic_state_gardening_id',
        'detailed_state_gardening_id',
        'diameter',
        'green_num',
        'height',
        'life_form_type_id',
        'plant_type_id',
        'plantation_type_id',
        'quantity',
        'section_num',
        'distance',
        'state_gardening_id',
        PLANT_SERVICE_RECOMENDATIONS_ID_NAME,
      ],
    });
  });
});
