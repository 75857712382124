import { Button } from 'core/uiKit/components/buttons/Button';
import { Icon } from 'core/uiKit/material-ui';
import React from 'react';

/**
 * Компонент AddButton.
 *
 * @param {object} props - Пропсы.
 * @param {Function} props.onClick - Callback function.
 * @returns {JSX.Element}
 */
export const AddButton = (props) => {
  return (
    <Button
      {...props}
      color="success"
      startIcon={<Icon>library_add</Icon>}
      variant={'contained'}
    >
      Добавить
    </Button>
  );
};
