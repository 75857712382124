/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable */
export default class FileImage extends React.Component {
  static propTypes = {
    file: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.img.file = this.props.file;
    const reader = new FileReader();
    reader.onload = (e) => {
      this.img.src = e.target.result;
    };
    reader.readAsDataURL(this.props.file);
  }

  render() {
    const { file, key, ...other } = this.props;
    return <img ref={(img) => (this.img = img)} {...other} alt={''} />;
  }
}
