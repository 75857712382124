/* eslint-disable */
import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import { Checkbox } from 'core/uiKit/inputs';

const CheckboxWithReduxForm = ({ input, ...props }) => {
  return <Checkbox {...props} {...input} />;
};

 
export const CheckboxRF = ({ name, ...otherProps }) => {
  return (
    <Field
      type={'checkbox'}
      {...otherProps}
      name={name}
      component={CheckboxWithReduxForm}
    />
  );
};

CheckboxRF.propTypes = {
   
   
/**
   Выбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   name input
   */
  name: PropTypes.string,
   
  /* eslint-disable */
/**
   label input
   */
  label: PropTypes.string,
  disabled: PropTypes.bool,
};
CheckboxRF.defaultProps = {
  name: 'CheckboxRF',
  dark: false,
  disabled: false,
  label: 'CheckboxRF label',
};
