import formatRootId from 'app/utils/formatRootId';
import React, { useState } from 'react';
import { CSVLink } from 'react-csv';

import { DownloadGeometryButtonIcon } from './DownloadGeometryButtonIcon';

/**
 * Кнопка __Скачать текущую геометрию csv__.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.currentCard - Текущий паспорт ОГХ.
 * @param {object} props.getGeometry - Геометрия.
 * @param {boolean} props.isMapLoaded - Флаг загрузки карты.
 * @returns {JSX.Element}
 */
const DownloadGeometryButton = ({ currentCard, getGeometry, isMapLoaded }) => {
  const [dataDownload, setDataDownload] = useState([]);

  /**
   * Функция onclick.
   *
   * @returns {boolean}
   */
  const handlerClick = () => {
    if (!isMapLoaded) {
      return false;
    }

    const geometry = getGeometry();

    const {
      type_id,
      object_type_name,
      name,
      root_id,
      owner_name,
      customer_name,
      logical_state_id,
      object_status_name,
      start_date,
      end_date,
    } = currentCard;
    setDataDownload([
      {
        customer_short_name: customer_name || '',
        end_date: end_date,
        geo_line: geometry.lines ? JSON.stringify(geometry.lines) : '',
        geo_point: geometry.points ? JSON.stringify(geometry.points) : '',
        geo_poly: geometry.polygons ? JSON.stringify(geometry.polygons) : '',
        object_name: `${name} ID ${formatRootId(root_id)}`,
        object_root_id: root_id,
        object_type_id: type_id,
        object_type_name: object_type_name || '',
        owner_short_name: owner_name || '',
        short_object_id: formatRootId(root_id),
        start_date: start_date,
        state_id: logical_state_id,
        state_name: object_status_name || '',
      },
    ]);

    return !!Object.keys(geometry).length;
  };

  return (
    <CSVLink
      data={dataDownload}
      filename={'CurrentGeometry.csv'}
      separator={'$'}
      onClick={handlerClick}
    >
      <button
        className={'button-toggle'}
        disabled={!isMapLoaded}
        type="button"
        title={'Скачать текущую геометрию .csv'}
      >
        <DownloadGeometryButtonIcon />
      </button>
    </CSVLink>
  );
};

export default DownloadGeometryButton;
