import { print } from 'app/api/requests/print';
import { PrintButton } from 'app/pages/components/buttons/PrintButton';

/**
 * Prints operations.
 *
 * @param {object} data - The data.
 * @returns {Promise<*|Response>}
 */
const printOperations = (data) =>
  print('operation_pgm', { ...data, type: 'operations' });

/**
 * Print operation.
 *
 * @param {object} params - The params.
 * @param {object} params.data - The data.
 * @returns {JSX}
 */
export const PrintOperation = ({ data }) => {
  return <PrintButton printFn={printOperations} prepareFilters={data} />;
};
