import 'assets/styles/common.misc.ajaxLoadingIndicator.scss';

import cn from 'classnames';
import PropTypes from 'prop-types';
/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';

class AjaxLoadingIndicator extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isFetching: PropTypes.bool,
  };

  render() {
    const { isFetching } = this.props;

    if (!isFetching) {
      return null;
    }

    return (
      <div className={cn(this.props.className)}>
        <div className={'ajax-loading-indicator__background'} />
        <div className={'ajax-loading-indicator__content'} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.isFetching,
  };
}

 
export default connect(mapStateToProps)(AjaxLoadingIndicator);
