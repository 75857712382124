import { useChangeFieldValueRF } from 'app/actions/odsObjectActions/useChangeFieldValueRF';
import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { CleanSubCategoryIdRf } from 'core/form/reduxForm/fields/prepared/selects/simple/CleanSubCategoryId.RF/index';
import { CLEAN_SUB_CATEGORY_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CleanSubCategoryId/index';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

/**
 * Поле __Подкатегория по уборке__.
 *
 * @returns {JSX.Element}
 */
export const CleanSubCategoryIdField = () => {
  const { cleanSubcategory = [] } = useGetCurrentCard();
  const { editMode } = useEditMode();
  const changeFieldValue = useChangeFieldValueRF();

  const cleanCategoryId = useSelector((state) => {
    return state.form.editorCard.values.clean_category_id;
  });

  // Проверка на обязательность поля
  const required = cleanSubcategory.some(
    ({ category_id }) => +category_id === +cleanCategoryId,
  );

  // Очищаем значение если изменилось поле категория по уборке
  useEffect(() => {
    if (!required) {
      changeFieldValue(CLEAN_SUB_CATEGORY_ID_NAME, null);
    }

    // eslint-disable-next-line
  }, [cleanCategoryId, required]);

  /**
   * Функция фильтрации.
   *
   * @param {Array} dict - Опшины.
   * @returns {*}
   */
  const filter = (dict) => {
    return dict.filter(({ category_id }) => +category_id === +cleanCategoryId);
  };

  return (
    <CleanSubCategoryIdRf
      filter={filter}
      required={required}
      options={cleanSubcategory}
      disabled={!(editMode && required)}
      withAll={false}
    />
  );
};
