/* eslint-disable */
/**
 * Колонка __Идентификатор родительского объекта__.
 *
 * @deprecated
 * @see parent_short_root_id
 * @type {{accessor: 'parent_id', Header: 'Идентификатор родительского объекта' }}
 * @augments Cell
 */
export const parent_id = {
  accessor: 'parent_id',
  Header: 'Идентификатор родительского объекта',
  sortable: true,
};
