import { FORMAT_DATE } from 'app/utils/date/format';
import moment from 'moment';

 
/**
 * Функция конвертации даты строки в Момент.
 *
 * @param {string} data - Дата строкой формата DD.MM.YYYY.
 * @param {string} [formatDate] - Маска для форматирования даты.
 * @returns {moment.Moment} - Жопа.
 * @example -----
 */
export const parseDateToMoment = (
  data,
  formatDate = FORMAT_DATE,
) => {
  return moment(data, formatDate);
};
