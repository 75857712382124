import { Paper } from 'core/uiKit/components/Paper';
import fetchMock from 'fetch-mock';
import React, { useEffect } from 'react';

// Component
import { DeleteOghButton } from './DeleteOghButton';


/* eslint-disable */
export default {
  title: 'Buttons/Ogh/DeleteOghButton',
  component: DeleteOghButton,
};

const FetchWrapper = ({ children }) => {
  useEffect(() => {
    fetchMock.restore();
    fetchMock.get(
      'https://dev.ods.test.intermb.ru/api/ogh/visibility/buttons?object_id=42',
      {
        delete: true,
      },
    );
    return () => fetchMock.restore();
  }, []);
  return children;
};
 
export const Basic = (args) => (
  <FetchWrapper>
    <Paper
      dark={args.dark}
      style={{ width: '100%' }}
      className={'p-5'}
    >
      <DeleteOghButton {...args} />
    </Paper>
  </FetchWrapper>
);
Basic.args = { objectId: 42 };
