export const dts_type = [
  {
    attribute: {
      code: 'bicycle_lane',
      migr_code: ['BYKE_WAYS'],
      name: 'Велосипедная дорожка',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 38,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 40,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 49,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 39,
          },
          other_improvement_object_type_list: [
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 1,
              },
            },
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 2,
              },
            },
          ],
        },
      ],
    },
    id: 32,
  },
  {
    attribute: {
      code: 'artificial_road_roughness',
      migr_code: [],
      name: 'Искусственная дорожная неровность',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 38,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 40,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 49,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 39,
          },
          other_improvement_object_type_list: [
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 1,
              },
            },
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 2,
              },
            },
          ],
        },
      ],
    },
    id: 42,
  },
  {
    attribute: {
      code: '101',
      migr_code: [],
      name: 'Мост пешеходный',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 49,
          },
        },
      ],
    },
    id: 101,
  },
  {
    attribute: {
      code: 'footpath',
      migr_code: ['FOOT_WAYS'],
      name: 'Пешеходная дорожка',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 38,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 40,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 49,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 39,
          },
          other_improvement_object_type_list: [
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 1,
              },
            },
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 2,
              },
            },
          ],
        },
      ],
    },
    id: 27,
  },
  {
    attribute: {
      code: 'passage',
      migr_code: ['WAYS', 'DRIVE_WAYS'],
      name: 'Проезд',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 38,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 40,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 49,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 39,
          },
          other_improvement_object_type_list: [
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 1,
              },
            },
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 2,
              },
            },
          ],
        },
      ],
    },
    id: 25,
  },
  {
    attribute: {
      code: 'technical_sidewalk',
      migr_code: [],
      name: 'Технический (технологический) тротуар',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 38,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 40,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 49,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 39,
          },
          other_improvement_object_type_list: [
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 1,
              },
            },
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 2,
              },
            },
          ],
        },
      ],
    },
    id: 43,
  },
  {
    attribute: {
      code: 'sidewalk',
      migr_code: ['SIDE_WAYS'],
      name: 'Тротуар',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 38,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 40,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 49,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 39,
          },
          other_improvement_object_type_list: [
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 1,
              },
            },
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 2,
              },
            },
          ],
        },
      ],
    },
    id: 26,
  },
  {
    attribute: {
      code: 'eco_ways',
      migr_code: [],
      name: 'Экологические тропы',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 49,
          },
        },
      ],
    },
    id: 100,
  },
];
