import { showAlert, showNotification } from 'app/actions/dialog';
/* eslint-disable  */
import {
  afterReceiving,
  cancelEditCard,
  cancelLeaveCurrentElement,
  createCard,
  sendCreateCard,
  sendDeleteCard,
  sendUpdateCard,
  viewCard,
} from 'app/actions/odsObjectActions';
import {
  fetchFewTree,
  fetchTreeSelectedElement,
} from 'app/actions/odsObjectTreeActions';
import { toggleCompareReportVisibility } from 'app/actions/odsObjectVersionsActions';
import {
  fetchPreviewTechCard,
  fetchTechnik,
} from 'app/actions/technologyObject';
import * as queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import TechCard from './card';

/**
 * Функция mapStateToProps.
 *
 * @param {object} state - Стейт.
 * @param {object} root0 - Данные.
 * @param {string} root0.startDate - Стартовая дата.
 * @returns {object}
 */
function mapStateToProps(state = {}, { startDate }) {
  return {
    action: state.matching?.afterAction,
    card: state.card?.object,
    goToRegistry: state.matching?.goToRegistry,
    groupParent: state.modals?.groupParent,
    matching: state.matching?.data,
    matchingRegistryColumns: state.tableColumns?.objectMatchingRegistry,
    mode: state.card?.mode,
    startDate: startDate || state.rrf.changeCar?.dateTime,
    treeTechModal: state.modals?.treeEdit,
    versionsModal: state.modals?.versions,
  };
}

const mapDispatchToProps = {
  afterReceiving,
  cancelEditCard,
  cancelLeaveCurrentElement,
  createCard,
  fetchFewTree,
  fetchPreviewCard: fetchPreviewTechCard,
  fetchRootCard: fetchTechnik,
  fetchTreeSelectedElement,
  sendCreateCard: sendCreateCard('tech'),
  sendDeleteCard: sendDeleteCard('tech'),
  sendUpdateCard: sendUpdateCard('tech'),
  showAlert,
  showNotification,
  toggleCompareReportVisibility,
  viewCard,
};

/**
 * Класс Create.
 *
 * @returns {JSX.Element}
 */
class Create extends React.Component {
  /**
   * Компонент жизненного цикла componentDidMount.
   *
   * @returns {void}
   */
  componentDidMount() {
    const { params, createCard, fetchPreviewCard, startDate } = this.props;
    createCard(params.typeId, startDate);
    fetchPreviewCard(params.code, startDate);
  }

  /**
   * Метод render.
   *
   * @returns {JSX.Element}
   */
  render() {
    return <TechCard {...this.props} />;
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Create);

const Card = connect(mapStateToProps, mapDispatchToProps)(Create);

// todo: костыль на время. создан когда была переделка на новый роут.
//   нужно переделать на переменые из роута

/**
 *
 */
export default () => {
  const params = useParams();
  let location = useLocation();
  const { startDate } = queryString.parse(useLocation().search, {
    arrayFormat: 'bracket',
  });
  return (
    <Card {...{ currentLocation: location, location, params, startDate }} />
  );
};
