import * as types from '../constants/actionTypes';

/* eslint-disable */
export function remoteSubmitDone(object) {
  return {
    type: types.REMOTE_SUBMIT_DONE,
    object,
  };
}

/* eslint-disable */
export function remoteSubmitStart(object) {
  return {
    type: types.REMOTE_SUBMIT_START,
    object,
  };
}

/* eslint-disable */
export function resetFormStart(object) {
  return {
    type: types.RESET_FORM_START,
    object,
  };
}

/* eslint-disable */
export function resetFormDone(object) {
  return {
    type: types.RESET_FORM_DONE,
    object,
  };
}

/* eslint-disable */
export function saveMapsForm(values) {
  return {
    type: types.SAVE_MAPS_FORM,
    values,
  };
}
