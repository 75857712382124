import { ADDRESS_NAME, AddressFF } from 'core/form/finalForm/fields';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';
import { ObjectParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

/**
 * Адрес.
 *
 * @returns {JSX.Element}
 */
export const AddressFilter = () => <AddressFF className={filterInput} />;

export { ADDRESS_NAME };

/**
 * AddressParams.
 *
 * @returns {object}
 */
export const addressParams = () => {
  addPreparation(ADDRESS_NAME, (value) => value.id);

  // под старые инпуты
  addPreparation('address', (value) => value.id, ADDRESS_NAME);
  return {
    [ADDRESS_NAME]: ObjectParam,
  };
};
