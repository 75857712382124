import { TableList } from '../Table.List';
/* eslint-disable */
import { columns } from './columns';

/**
 *
 * @param root0
 * @param root0.code
 */
export const TableFlowersGardenList = ({ code }) => {
  return <TableList columns={columns} code={code} />;
};
