import { useFetchOghDispatch } from 'app/actions/odsObjectActions/useFetchOgh.Dispatch';
import { signOghSave } from 'app/api/requests/signs/signOghSave';
import { toDateTime } from 'app/utils/dateUtils';
import { toast } from 'core/uiKit/components/Toast';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
 
 
/* eslint-disable */
/**
 * хук для подписи
 *
 * @function
 *
 * @category hooks
 *
 * @param {object} params
 * @param {number} params.objectId id объекта огх
 * @param {SAVE|CLOSE|UPDATE}  params.signType тип подписи
 * @param {array}  params.objectCardFiles  id файлов в файловом хранилище
 * @param {function} params.onSuccessCallback callback вызовется при success
 * @param {function} params.onErrorCallback callback вызовется при onError
 * @returns {{isLoadingSignOghSave: boolean, sendSign: function }}
 *
 * @example
 * import { useSignOghSave } from 'app/api/hooks/useSignOghSave';
 *
 * const { sendSign, isLoadingSignOghSave } = useSignOghSave({
 *    objectCardFiles: data,
 *    objectId,
 *    signType: UPDATE,
 *    onSuccessCallback: onClose,
 *    onErrorCallback: onClose,
 * });
 *
 * // isLoadingSignOghSave - флаг показывающий выполнение запроса мутации
 * // sendSign - метод вызова мутации
 */
/* eslint-disable */
export const useSignOghSave = ({
  objectId,
  signType,
  objectCardFiles,
  onSuccessCallback,
  onErrorCallback,
}) => {
  const fetchOgh = useFetchOghDispatch(objectId);

  const [sendSign, { isLoading: isLoadingSignOghSave }] =
    useMutationAdaptor(
      signOghSave({
        date: toDateTime(),
        objectCardFiles,
        objectId,
        signType,
      }),
      {
        onSuccess: () => {
          toast.success('Подписано');
          fetchOgh();
          onSuccessCallback();
        },
        onError: (value) => {
          toast.error(value?.message);
          onErrorCallback();
        },
      },
    );
  return { sendSign, isLoadingSignOghSave };
};
