/* eslint-disable  */
import { useCreateTheme } from 'core/uiKit/helpers';

const overrides = {
  MuiTableContainer: {
    root: {
      overflowX: 'initial',
    },
  },
};

/**
 *
 */
export const useCreateMUITableTheme = () => {
  return useCreateTheme({ overrides });
};
