/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import { renderCustomTextField } from 'app/components/card/common/grid/index';

/* eslint-disable */
export default class AreaContainer extends React.Component {
  static propTypes = {
    editMode: PropTypes.bool.isRequired,
    isPointGeometry: PropTypes.bool.isRequired,
    changeFieldValue: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps) {
    const { editMode, isPointGeometry, changeFieldValue } = this.props;
    if (editMode) {
      if (prevProps.isPointGeometry !== isPointGeometry) {
        if (isPointGeometry) {
          changeFieldValue('area', 0);
        } else {
          changeFieldValue('area', null);
        }
      }
    }
  }

  render() {
    const { isPointGeometry, editMode, changeFieldValue, ...rest } = this.props;
    return renderCustomTextField(rest);
  }
}
