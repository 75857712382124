import './DialogSendMatching.scss';

import { SendReasonFilter } from 'app/pages/cardsOgh/components/buttonsCard/components/SendMatching/fields/SendReason.Filter';
import { FinalForm } from 'core/form/finalForm/components/FinalForm';
import { TextFieldFF } from 'core/form/finalForm/fields/default/TextFieldFF';
import { Button } from 'core/uiKit/components/buttons/Button';
import React from 'react';

/**
 * DialogSendMatching.
 *
 * @param {object} props - The props.
 * @param {Function} props.onSubmit - Функция onSubmit.
 * @param {Function} props.closeDialog - Закрыть диалоговое окно.
 * @param {boolean} props.isLoading - Флаг ожидания.
 * @returns {React.ReactElement}
 */
export const DialogSendMatchingBody = ({
  onSubmit,
  closeDialog,
  isLoading,
}) => {
  return (
    <FinalForm onSubmit={onSubmit}>
      <div className={'dialog-send-matching-card'}>
        <div>
          <SendReasonFilter />
          <TextFieldFF
            name={'comment'}
            label={'Комментарий'}
            variant={'outlined'}
            maxLength={1000}
          />
        </div>
        <div className={'dialog-send-matching-card__wrapper-button'}>
          <Button color={'info'} variant={'outlined'} onClick={closeDialog}>
            Закрыть
          </Button>
          <Button
            color={'primary'}
            variant={'contained'}
            type="submit"
            isLoading={isLoading}
          >
            Продолжить
          </Button>
        </div>
      </div>
    </FinalForm>
  );
};
