import './DialogComparison.scss';

import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { formatDateTime } from 'app/components/card/ogh/BasePgmCard/utils/dateTime';
import { PrintButtonDialogComparison } from 'app/pages/cardsOgh/components/tables/Versions/DialogComparison/PrintButton.DialogComparison';
import getEtalonCopy from 'app/utils/compareReport';
import formatRootId from 'app/utils/formatRootId';
import { Button } from 'core/uiKit/components/buttons/Button';
import { Dialog } from 'core/uiKit/components/dialogs/Dialog';
import { TabsCard } from 'core/uiKit/components/TabsCard';
import { TabsCardItem } from 'core/uiKit/components/TabsCard/TabsCardItem';
import React from 'react';

import { MapDiffWrapper } from './MapDIff';
import { NestedObjectsDiff } from './NestedObjectsDiff';
import { PropertiesDiff } from './PropertiesDiff';

export const tabs = [
  { label: 'Свойства', value: 'properties' },
  { label: 'Вложенные объекты', value: 'nested_objects' },
  { label: 'Карта', value: 'map' },
];

/**
 * Компонент диалогового окна таблицы версий.
 *
 * @param {object} props - Пропсы.
 * @param {boolean} props.open - Флаг.
 * @param {Function} props.closeDialog - Функция закрытия окна.
 * @param {Array} props.rows - Массив колонок.
 * @returns {JSX.Element} - Реакт компонент.
 * @example  <DialogComparison
 *         open={openDialog}
 *         closeDialog={closeDialog}
 *         rows={rows}
 *       />
 */
export const DialogComparison = ({ open, closeDialog, rows }) => {
  const { rootId, typeNameRus, typeId } = useGetCurrentCard();

  const { etalon, copy } = getEtalonCopy(rows[0], rows[1]);
  return (
    <Dialog
      textHeader={`Сравнение версий объекта ${typeNameRus} ${formatRootId(
        rootId,
      )}`}
      isOpen={open}
      maxWidth={'xl'}
      Body={
        <div className={'dialog-comparison-version-card__body'}>
          <div className={'dialog-comparison-version-card__header'}>
            {rows.map((values, index) => {
              const createDate = formatDateTime(values.create_date);
              return (
                <div
                  key={values.id + index}
                  className={'dialog-comparison-version-card__list'}
                >
                  <dl className={'dialog-comparison-version-card__item'}>
                    <dt>Дата формирования:</dt>
                    <dd>{createDate}</dd>
                  </dl>
                  <dl className={'dialog-comparison-version-card__item'}>
                    <dt>ID:</dt>
                    <dd>{formatRootId(values.id)}</dd>
                  </dl>
                  <dl className={'dialog-comparison-version-card__item'}>
                    <dt>Период действия:</dt>
                    <dd>{`${values.start_date} - ${values.end_date}`}</dd>
                  </dl>
                  <dl className={'dialog-comparison-version-card__item'}>
                    <dt>Статус:</dt>
                    <dd>{values.object_status_name}</dd>
                  </dl>
                </div>
              );
            })}
          </div>
          <PrintButtonDialogComparison {...{ copy, etalon, typeId }} />
          <TabsCard tabs={tabs} preloading={false}>
            <TabsCardItem tabValue={'properties'}>
              <PropertiesDiff etalon={etalon} copy={copy} />
            </TabsCardItem>
            <TabsCardItem tabValue={'nested_objects'}>
              <NestedObjectsDiff etalon={etalon} copy={copy} />
            </TabsCardItem>
            <TabsCardItem tabValue={'map'}>
              <MapDiffWrapper etalon={etalon} copy={copy} />
            </TabsCardItem>
          </TabsCard>
        </div>
      }
      ButtonsActions={
        <Button variant={'contained'} color={'primary'} onClick={closeDialog}>
          Закрыть
        </Button>
      }
    />
  );
};
