import { get } from 'lodash';

import metadata from './metadata';


/* eslint-disable */
export default function (params = {}) {
  return (result, state, props) => {
    const { card } = props;
    const attributes = Object.entries(metadata).reduce(
      (result, [key, item]) => {
        const { defaultValue = null, isAlwaysDefault = false } =
          params[key] || {};
        result[item.id] = get(
          card,
          `attribute.${item.id}.${item.dictionary}`,
          defaultValue,
        );

        // Помимо несуществующих значений, для Категории Благоустройства всегда подcтавляем значение по-умолчанию [ODS-6458]
        if (result[item.id] === null || isAlwaysDefault) {
          result[item.id] = defaultValue;
        }
        return result;
      },
      {},
    );
    const options = Object.entries(metadata).reduce((result, [key, item]) => {
      const { defaultOption } = params[key] || {};
      const selector = item.getSelector({ defaultOption, card });
      result[item.id] = selector(state, props);
      return result;
    }, {});
    return {
      ...result,
      options,
      initialValues: {
        ...result.initialValues,
        ...attributes,
      },
    };
  };
}
