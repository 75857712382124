/* eslint-disable */
class GeoJSON {}

export const ol = window.ol || {
  style: {
    Style: function () {},
    Circle: function () {},
    Fill: function () {},
    Stroke: function () {},
  },
  format: { GeoJSON },
};
