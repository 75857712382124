import {
  CREATE_TYPE_NAME,
  CreateTypeRF,
} from 'core/form/reduxForm/fields/prepared/selects/simple/CreateTypeRF';
import React from 'react';

/**
 * Select RF __Тип создания объекта__.
 *
 * @returns
 */
export const CreateTypeField = () => {
  return <CreateTypeRF name={CREATE_TYPE_NAME} disabled={true} />;
};
