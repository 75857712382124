import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { fetchIntersectionsGeometry } from 'app/api/requests/geometry';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';

import { useDrawDrawIntersectionsGeometry } from './useDrawDrawIntersectionsGeometry';

/**
 * Хук проверки пересечений.
 *
 * @returns {{isLoading: boolean, objGeoData: *, data: unknown, messages: (*|string)}}
 */
export const useFetchIntersectionsGeometry = () => {
  const currentCard = useGetCurrentCard();

  const { geometry, drawIntersections } = useDrawDrawIntersectionsGeometry();
  const request = {
    attribute: {
      createTypeId: currentCard?.attribute?.create_type_id,
      isDiffHeightMark: currentCard?.attribute?.is_diff_height_mark ? 1 : 0,
    },
    geometry,
    id: currentCard.root_id,
    parentId: currentCard.parent_id,
    startDate: currentCard?.startDate,
    typeId: currentCard.type_id,
  };

  // получить геометрию
  const { data, isLoading } = useQueryAdaptor(
    ['FetchIntersectionsGeometry', request],
    fetchIntersectionsGeometry,
    {

      /**
       * OnSuccess.
       *
       * @param {object} response - Ответ.
       */
      onSuccess: (response) => {
        if (response?.intersected && response?.geometry) {
          // отрисовать геометрию если есть
          drawIntersections(response?.geometry);
        }
      },
    },
  );

  // собираем hint
  const objectAttributeHint = data?.geometry?.map((item) => {
    const objectHint = {};
    item?.hint?.object_attribute_hint?.forEach(({ key, value }) => {
      objectHint[key] = value;
    });

    return objectHint;
  });

  // // подготовить сообщение
  // const messages =
  //   data?.geometry?.map((item) => ({
  //     id: item.id,
  //     name: item?.hint?.object_attribute_hint_short[0].value,
  //   })) || '';
  //  подготовить сообщение

  const messages =
    objectAttributeHint?.map((item = {}) => ({
      id: item.id_ogh,
      text: [
        item.id_ogh,
        item.title,
        item.name,
        item.owner_name,
        item.intersection_area_percent,
        item.create_type,
        item.start_date,
        item.end_date,
      ]
        .filter(Boolean)
        .join(', '),
    })) || '';

  // подготавливаем для файла
  const objGeoData = data?.geometry?.map(createObjGeoData);

  return {
    data,
    isLoading,
    messages,
    objGeoData,
  };
};

/**
 * Функция createObjGeoData.
 *
 * @param {object} params - Параметры.
 * @param {object} params.points - Точки.
 * @param {object} params.lines - Линии.
 * @param {object} params.polygons - Полигоны.
 * @param {object} params.hint - Подсказки.
 * @param {object} params.hint.object_attribute_hint - Объект подсказки.
 * @returns {object}
 */
function createObjGeoData({
  points,
  lines,
  polygons,
  hint: { object_attribute_hint },
}) {
  const newValue = {
    geometry: {
      lines,
      points,
      polygons,
    },
  };

  object_attribute_hint.forEach(function (item) {
    newValue[item.key] = item.value;
  });
  return newValue;
}
