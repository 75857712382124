import { getHintAutoCleanArea } from 'app/components/card/ogh/ImprovementTerritoryCard/components/MainParamsTab/hints/hint.autoCleanArea';
import { OZN, YARD } from 'app/constants/oghTypes';
import { RANDOM_ID } from 'core/forTesting/constants';
import { vi } from 'vitest';

vi.mock('app/utils/createNumberedList', () => ({

  /**
   * Create number list.
   *
   * @param {object} obj - Object.
   * @returns {object}
   */
  createNumberedList: (obj) => obj,
}));

describe('HintAutoCleanAreaSpec', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act

    // ❓ Assert
    expect(getHintAutoCleanArea(RANDOM_ID)).toStrictEqual({
      header: 'Рассчитывается как сумма значений',
      list: [
        {
          children: ['"Плоскостные сооружения".', '"Дорожно-тропиночные сети"'],
          item: '"Площадь, кв.м." ("Способ уборки": "Механизированная уборка")',
        },
      ],
    });
  });

  it('typeId OZN', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act

    // ❓ Assert
    expect(getHintAutoCleanArea(OZN)).toStrictEqual({
      header: 'Рассчитывается как сумма значений',
      list: [
        {
          children: [
            'Автопарковка',
            'Площадка для пожарной техники',
            'Площадка для отдыха',
            'Площадка иного типа',
            'Техническая площадка',
            'Велопарковка открытого типа',
          ],
          item: '«Уборочная площадь плоскостных сооружений, кв.м»',
        },
        {
          children: [
            'Проезд',
            'Тротуар',
            'Пешеходная дорожка',
            'Беговая дорожка',
            'Велосипедная дорожка',
          ],
          item: '«Уборочная площадь дорожно-тропиночной сети, кв.м»',
        },
        {
          children: ['Сухой фонтан', 'Променад'],
          item: '«Уборочная площадь инженерных сооружений, кв. м»',
        },
      ],
    });
  });

  it('typeId YARD', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act

    // ❓ Assert
    expect(getHintAutoCleanArea(YARD)).toStrictEqual({
      header: 'Рассчитывается как сумма значений',
      list: [
        {
          children: [
            '"Плоскостные сооружения".',
            '"Дорожно-тропиночные сети"',
            {
              children: ['Пешеходный мост', 'Пирс', 'Cухой фонтан (ОО)'],
              item: '"Инженерные сооружения"',
            },
          ],
          item: '"Площадь, кв.м." ("Способ уборки": "Механизированная уборка")',
        },
      ],
    });
  });
});
