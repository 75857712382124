/* eslint-disable */

import { asyncValidate as departmentTypeFieldAsyncValidate } from '../components/DepartmentTypeField';
import { districtListAsyncValidate } from '../components/DistrictListField';
import { asyncValidate as improvementCategoriesAsyncValidate } from '../components/ImprovementCategories';
import { asyncValidate as nameFieldAsyncValidate } from '../components/NameField';
import { okrugListAsyncValidate } from '../components/OkrugListField';
import { asyncValidate as ownerAndCustomerFieldsAsyncValidate } from '../components/OwnerAndCustomerFields';
import { oznTypeAsyncValidate } from '../components/OznTypeField';
import { asyncValidate as snowAreaAsyncValidate } from '../components/SnowAreaTab';
import { asyncValidate } from './asyncValidate';

jest.mock('../components/DepartmentTypeField');
jest.mock('../components/DistrictListField');
jest.mock('../components/ImprovementCategories');
jest.mock('../components/NameField');
jest.mock('../components/OkrugListField');
jest.mock('../components/OwnerAndCustomerFields');
jest.mock('../components/OznTypeField');
jest.mock('../components/SnowAreaTab');

describe('asyncValidate.jestSpec', () => {
  it('should', () => {
    expect.hasAssertions();

    // Arrange (всякие моки)
    oznTypeAsyncValidate.mockReturnValue({ q: 1 });
    okrugListAsyncValidate.mockReturnValue({ w: 2 });
    districtListAsyncValidate.mockReturnValue({ e: 3 });
    snowAreaAsyncValidate.mockReturnValue({ r: 4 });
    departmentTypeFieldAsyncValidate.mockReturnValue({ t: 5 });
    ownerAndCustomerFieldsAsyncValidate.mockReturnValue({ y: 6 });
    nameFieldAsyncValidate.mockReturnValue({ u: 7 });
    improvementCategoriesAsyncValidate.mockReturnValue({ i: 9 });

    // Act
    const res = asyncValidate({ a: 23 });

    // Assert
    expect(res).toStrictEqual({
      e: 3,
      i: 9,
      q: 1,
      r: 4,
      t: 5,
      u: 7,
      w: 2,
      y: 6,
    });

    expect(oznTypeAsyncValidate).toBeCalledWith({ a: 23 });
    expect(okrugListAsyncValidate).toBeCalledWith({ a: 23 });
    expect(districtListAsyncValidate).toBeCalledWith({ a: 23 });
    expect(snowAreaAsyncValidate).toBeCalledWith({ a: 23 });
    expect(departmentTypeFieldAsyncValidate).toBeCalledWith({ a: 23 });
    expect(ownerAndCustomerFieldsAsyncValidate).toBeCalledWith({ a: 23 });
    expect(nameFieldAsyncValidate).toBeCalledWith({ a: 23 });
    expect(improvementCategoriesAsyncValidate).toBeCalledWith({ a: 23 });
  });
});
