import { pathBoardStone } from 'app/pages/registry/ogh/odh/BoardStone/path.BoardStone';
import { pathEmergencyParking } from 'app/pages/registry/ogh/odh/EmergencyParking/path.EmergencyParking';
import { pathFencing } from 'app/pages/registry/ogh/odh/Fencing/path.Fencing';
import { pathLampposts } from 'app/pages/registry/ogh/odh/Lampposts/path.Lampposts';
import { pathManholes } from 'app/pages/registry/ogh/odh/Manholes/path.Manholes';
import { pathMargin } from 'app/pages/registry/ogh/odh/Margin/path.Margin';
import { pathOtherEnginConstruct } from 'app/pages/registry/ogh/odh/OtherEnginConstruct/path.OtherEnginConstruct';
import { pathOtherFlatBuilding } from 'app/pages/registry/ogh/odh/OtherFlatBuilding/path.OtherFlatBuilding';
import { pathRoadSigns } from 'app/pages/registry/ogh/odh/RoadSigns/path.RoadSigns';
import { pathSidewalks } from 'app/pages/registry/ogh/odh/Sidewalks/path.Sidewalks';
import { pathStops } from 'app/pages/registry/ogh/odh/Stops/path.Stops';
import { pathTrafficLight } from 'app/pages/registry/ogh/odh/TrafficLight/path.TrafficLight';
import { pathTramRails } from 'app/pages/registry/ogh/odh/TramRails/path.TramRails';
import { pathWaterBuilding } from 'app/pages/registry/ogh/yard/WaterBuilding/path.WaterBuilding';
import lazyWithPreload from 'lazy-with-preload';
import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { pathCarriageway } from './Carriageway/path.Carriageway';
import { pathMaf } from './Maf/path.Maf';
import { pathTrolleybusContactNetwork } from './TrolleybusContactNetwork/path.TrolleybusContactNetwork';

const TrolleybusContactNetwork = lazyWithPreload(() =>
  import('./TrolleybusContactNetwork'),
);
const Odh = lazyWithPreload(() => import('./Odh'));
const Maf = lazyWithPreload(() => import('./Maf'));
const BoardStone = lazyWithPreload(() =>
  import('./BoardStone'),
);
const Sidewalks = lazyWithPreload(() =>
  import('./Sidewalks'),
);
const Margin = lazyWithPreload(() => import('./Margin'));
const Carriageway = lazyWithPreload(() =>
  import('./Carriageway'),
);
const OtherEnginConstruct = lazyWithPreload(() =>
  import('./OtherEnginConstruct'),
);
const OtherFlatBuilding = lazyWithPreload(() =>
  import('./OtherFlatBuilding'),
);

const Stops = lazyWithPreload(() => import('./Stops'));
const EmergencyParking = lazyWithPreload(() =>
  import('./EmergencyParking'),
);
const TramRails = lazyWithPreload(() =>
  import('./TramRails'),
);
const Manholes = lazyWithPreload(() =>
  import('./Manholes'),
);
const Lampposts = lazyWithPreload(() =>
  import('./Lampposts'),
);
const RoadSigns = lazyWithPreload(() =>
  import('./RoadSigns'),
);
const Fencing = lazyWithPreload(() => import('./Fencing'));
const TrafficLight = lazyWithPreload(() =>
  import('./TrafficLight'),
);
const WaterBuilding = lazyWithPreload(() =>
  import('app/pages/registry/ogh/yard/WaterBuilding'),
);

 
/**
 * Роут ОДХ.
 *
 * @returns {JSX.Element} - JSX.
 * @example ---
 */
const OdhRoute = () => {
  useEffect(() => {
    BoardStone.preload();
    Carriageway.preload();
    EmergencyParking.preload();
    Lampposts.preload();
    Maf.preload();
    Manholes.preload();
    Margin.preload();
    Odh.preload();
    OtherEnginConstruct.preload();
    OtherFlatBuilding.preload();
    Sidewalks.preload();
    Stops.preload();
    TramRails.preload();
    TrolleybusContactNetwork.preload();
    RoadSigns.preload();
    Fencing.preload();
    TrafficLight.preload();
    WaterBuilding.preload();
  }, []);

  return (
    <Routes>
      <Route element={<Odh />} path={'/'} exact={true} />
      <Route
        element={<Carriageway />}
        path={pathCarriageway.path}
      />
      <Route element={<Margin />} path={pathMargin.path} />
      <Route
        element={<Sidewalks />}
        path={pathSidewalks.path}
      />
      <Route element={<Maf />} path={pathMaf.path} />
      <Route
        element={<BoardStone />}
        path={pathBoardStone.path}
      />
      <Route
        element={<OtherFlatBuilding />}
        path={pathOtherFlatBuilding.path}
      />
      <Route element={<Stops />} path={pathStops.path} />
      <Route
        element={<EmergencyParking />}
        path={pathEmergencyParking.path}
      />
      <Route
        element={<TramRails />}
        path={pathTramRails.path}
      />
      <Route
        element={<TrolleybusContactNetwork />}
        path={pathTrolleybusContactNetwork.path}
      />
      <Route
        element={<Manholes />}
        path={pathManholes.path}
      />
      <Route
        element={<OtherEnginConstruct />}
        path={pathOtherEnginConstruct.path}
      />
      <Route
        element={<Lampposts />}
        path={pathLampposts.path}
      />
      <Route
        element={<RoadSigns />}
        path={pathRoadSigns.path}
      />
      <Route
        element={<Fencing />}
        path={pathFencing.path}
      />
      <Route
        element={<TrafficLight />}
        path={pathTrafficLight.path}
      />
      <Route
        element={<WaterBuilding />}
        path={pathWaterBuilding.path}
      />
    </Routes>
  );
};

export default OdhRoute;
