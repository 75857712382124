import { NSILazy } from 'app/pages/admin/nsi/NSI';
import { useUser } from 'app/pages/providers/UserContext';
import { pathNsiBid } from 'app/pages/registry/nsiBid/path.NsiBid';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Setup __Реестр изменений справочников НСИ__.
 *
 * @returns {{link: string, className: (string), isShow: boolean}}
 */
export const useSetupNSIBidLink = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    NSILazy.preload();
  }, []);

  const { user } = useUser();
  const isShow = (user.privileges || []).some((privilege) =>
    ['agreement_nsi_bid', 'management_nsi_bid'].includes(privilege),
  );

  const link = `/r/${pathNsiBid.path}`;

  const className = pathname.includes(link) ? 'nav-link-active' : 'nav-link';

  return { className, isShow, link };
};
