import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FORMAT_DATE_TIME } from 'app/utils/date/format';
import { parseDateToMoment } from 'app/utils/date/parseDateToMoment';
import { TextField } from 'core/uiKit/inputs/TextField';
import PropTypes from 'prop-types';

/* eslint-disable */
export const KeyboardDateTimePicker = ({
  value,
  onBlur,
  maxDate = '01.01.3000 00:00',
  ...props
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'ru'}>
      <DateTimePicker
        ampm={false}
        inputFormat={'DD.MM.YYYY HH:mm'}
        renderInput={(params) => <TextField {...params} />}
        views={['year', 'month', 'day', 'hours']}
        maxDate={parseDateToMoment(maxDate, FORMAT_DATE_TIME)}
        {...props}
        value={value || null}
      />
    </LocalizationProvider>
  );
};

KeyboardDateTimePicker.propTypes = {
   
   
  /**
   * быбор темной темы
   */
  dark: PropTypes.bool,
   
   
  /**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
  /**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
  /**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
  /**
   * подпись
   */
  label: PropTypes.string,
   
   
  /**
   * имя селекта в форме
   */
  name: PropTypes.string,
   
   
  /**
   * функция вызова когда выберется итем
   */
  onChange: PropTypes.func,

   
   
  /**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
   
   
  /**
   * значение ParsableDate
   */
  value: PropTypes.any,
   
  /* eslint-disable */
  /**
   * максимальная дата формата DD.MM.YYYY HH:mm
   */
  maxDate: PropTypes.string,
};

KeyboardDateTimePicker.defaultProps = {
  label: 'KeyboardDateTimePicker',
  name: 'KeyboardDateTimePicker',
};
