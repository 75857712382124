import { DynamicObjectEventTable, Map } from '@2gis/mapgl/types';
import { Evented } from '@2gis/mapgl/types/utils/evented';
import LayerPolyline from 'core/uiKit/components/Mapgl/contexts/utils/Polyline';
import { Coordinate } from 'ol/coordinate';
import { MouseEventHandler } from 'react';
import { ReactSetState } from 'react-app-env';

import { GeometryUtils } from '.';
import AreaOrPerimeterHint from './AreaOrPerimeterHint';
import Vertex from './DrawService/utils/Vertex';
import useGeometryInstances from './hooks/useGeometryInstances';
import useGeometryStorage from './hooks/useGeometryStorage';
import { MapService } from './MapService';
import Box from './ShapeEditor/utils/Box';
import Shape from './ShapeEditor/utils/Controls/Shape';
import Mover from './ShapeEditor/utils/Mover';
import Resizer from './ShapeEditor/utils/Resizer';
import Rotator from './ShapeEditor/utils/Rotator';

export type Polyline = Evented<DynamicObjectEventTable> &
  Omit<LayerPolyline, 'userData' | 'map' | 'options' | 'mapService'> & {
    userData: UserData<GeometryTypes.Polyline>;
    destroy: VoidFunction;
  };
export type Marker = Evented<DynamicObjectEventTable> &
  Omit<(typeof mapgl)['Marker'], 'userData'> & {
    userData: UserData<GeometryTypes.Marker>;
    destroy: VoidFunction;
    setCoordinates: (coordinates: Coordinate) => void;
    getCoordinates: () => Coordinate;
    setIcon: (icon: object) => void;
    hide: VoidFunction;
    show: VoidFunction;
  };
export type Polygon = Evented<DynamicObjectEventTable> &
  Omit<(typeof mapgl)['Polygon'], 'userData'> & {
    userData: UserData<GeometryTypes.Polygon>;
    destroy: VoidFunction;
  };

export type Control = Resizer | Mover | Rotator | Vertex;

export type ShapeOptions<Cc extends Control> = Omit<
  MarkerOptions,
  'userData'
> & {
  userData: Pick<
    UserData<GeometryTypes.Point>,
    'coordinates' | 'coordinatesToCheckMouseEvent' | 'type'
  > & { control: Cc };
  cursor?: string;
  iconSize?: [number, number];
  iconExtraShift?: number;
};

export type Controls = {
  resizer: Resizer;
  mover: Mover;
  rotator: Rotator;
};

export type ShapeEditorOnChangeEvent = {
  geometry: Polygon | LayerPolyline | Marker;
  coordinates: GenericCoordinates<GeometryTypes.Point>;
};

export type EditorShapeOnChangeEvent<Cd extends Control> = {
  interactingShape: { shape: Shape<Cd> | null };
  controls: {
    resizer: Resizer;
    mover: Mover;
    rotator: Rotator;
  };
  box: Box;
  geometry: Polygon | LayerPolyline;
  coordinates: GenericCoordinates<GeometryTypes.Point>;
};

export type DrawerShapeOnChangeEvent = {
  coordinates: GenericCoordinates<GeometryTypes.Point>;
};

export type TMarker = typeof mapgl.Marker;
export type TPolygon = typeof mapgl.Polygon;
export type TPolyline = typeof mapgl.Polyline;

export type GenericRawGeometry<Tt> = Tt extends GeometryTypes.Point
  ? Marker
  : Tt extends GeometryTypes.Marker
  ? Marker
  : Tt extends GeometryTypes.Polyline
  ? LayerPolyline
  : Tt extends GeometryTypes.Polygon
  ? Polygon
  : never;

export type GenericMapglGeometry<Td> = Td extends Marker
  ? MarkerOptions
  : Td extends LayerPolyline
  ? PolylineOptions
  : Td extends Polygon
  ? PolygonOptions
  : never;

export type Hint = {
  [key: string]: {
    key: string;
    value: string;
  }[];
};

export type ResponseChildObject = {
  hint: Hint;
  lines: ResponseGeometry<ResponseGeometryTypes.Line>[];
  points: ResponseGeometry<ResponseGeometryTypes.Point>[];
  polygons: ResponseGeometry<ResponseGeometryTypes.Polygon>[];
  type_id?: number | null;
  object_id?: number | null;
};

export enum ResponseGeometryTypes {
  Polygon = 'Polygon',
  Line = 'Line',
  Point = 'Point',
}

export type ResponseGeometry<Td extends ResponseGeometryTypes> = {
  crs: {
    type: string;
    properties: {
      name: string;
    };
  };
  type: Td;
  coordinates: Td extends ResponseGeometryTypes.Polygon
    ? GenericCoordinates<GeometryTypes.Polygon>
    : Td extends ResponseGeometryTypes.Line
    ? GenericCoordinates<GeometryTypes.Polyline>
    : Td extends ResponseGeometryTypes.Point
    ? GenericCoordinates<GeometryTypes.Point>
    : never;
};

export type UserData<Td extends GeometryTypes> = {
  type: Td;
  coordinates: GenericCoordinates<Td>;
  layerType: Layers;
  coordinatesToCheckMouseEvent: Td extends GeometryTypes.Polyline
    ? GenericCoordinates<GeometryTypes.Polyline>
    : GenericCoordinates<GeometryTypes.Polygon>;
  mapService: MapService;
  id: string | number;
  areaOrPerimeterHint?: AreaOrPerimeterHint;
  isChanging?: boolean;
  recalcHoverArea?: Td extends GeometryTypes.Marker ? () => void : undefined;
  currentColor?: string;
  currentStrokeColor?: string;
  hidden?: boolean;
  hovered?: boolean;
  type_id?: number;
  [key: string]: unknown;
};

export type GenericCoordinates<Td extends GeometryTypes> =
  Td extends GeometryTypes.Point
    ? number[]
    : Td extends GeometryTypes.Polyline
    ? number[][]
    : Td extends GeometryTypes.Polygon
    ? number[][][]
    : never;

export type GeoJsonProperties = { [name: string]: unknown } | null;

export type RawGeometryConstructors = {
  Polyline?: typeof LayerPolyline;
  Marker?: TMarker;
  Polygon?: TPolygon;
};

export type GeometryConstructorName = 'Polyline' | 'Marker' | 'Polygon';

export type RawGeometry = LayerPolyline | Marker | Polygon;

export type Mapgl = typeof mapgl;

export type GeometryOptions = ConstructorParameters<
  TPolyline | TMarker | TPolygon
>[1];

export type GeometryConstructor = {
  new (map: typeof mapgl.Map, options: GeometryOptions): RawGeometry;
};

export type MarkerOptions = Omit<
  ConstructorParameters<TMarker>[1],
  'userData'
> & {
  mapService: MapService;
  userData: UserData<GeometryTypes.Point>;
};
export type PolylineOptions = Omit<
  ConstructorParameters<TPolyline>[1],
  'userData'
> & {
  mapService: MapService;
  userData: UserData<GeometryTypes.Polyline>;
};
export type PolygonOptions = Omit<
  ConstructorParameters<TPolygon>[1],
  'userData'
> & {
  mapService: MapService;
  userData: UserData<GeometryTypes.Polygon>;
};

export enum GeometryTypes {
  Polyline = 'polyline',
  Point = 'point',
  // eslint-disable-next-line
  Marker = 'point',
  Polygon = 'polygon',
  Hole = 'hole',
}

export type ResponseGeometryObject<Tt extends GeometryTypes> = {
  coordinates: GenericCoordinates<Tt>;
  id: string | number;
  hint?: Hint;
  type_id?: number;
};

export type ResponseGeometryObjects = {
  lines: ResponseGeometry<ResponseGeometryTypes.Line>[] | null;
  points: ResponseGeometry<ResponseGeometryTypes.Point>[] | null;
  polygons: ResponseGeometry<ResponseGeometryTypes.Polygon>[] | null;
  layerType?: Layers;
  hint?: Hint;
  id?: number;
  type_id?: number;
  child_object?: ResponseGeometryObjects[];
};

export type GenericGeometryOptionsFromMapglGeometry<Tt> = Tt extends Marker
  ? GeometryTypes.Point
  : Tt extends LayerPolyline
  ? GeometryTypes.Polyline
  : Tt extends Polygon
  ? GeometryTypes.Polygon
  : never;

export type Geometry<Tt extends GeometryTypes = GeometryTypes> = {
  type: Tt;
  object: { id: string };
  geometry: RawGeometry;
};

export type TT = {
  [GeometryTypes.Point]: GenericGeometryOptions<GeometryTypes.Point>[];
  [GeometryTypes.Polygon]: GenericGeometryOptions<GeometryTypes.Polygon>[];
  [GeometryTypes.Polyline]: GenericGeometryOptions<GeometryTypes.Polyline>[];
};
export type MappedGeometries = {
  [GeometryTypes.Point]: GeometryToDraw<GeometryTypes.Point>[];
  [GeometryTypes.Polygon]: GeometryToDraw<GeometryTypes.Polygon>[];
  [GeometryTypes.Polyline]: GeometryToDraw<GeometryTypes.Polyline>[];
};

export type AllGeometries = {
  [GeometryTypes.Polyline]: Geometry<GeometryTypes.Polyline>[];
  [GeometryTypes.Point]: Geometry<GeometryTypes.Point>[];
  [GeometryTypes.Polygon]: Geometry<GeometryTypes.Polygon>[];
};

export interface MapglEditorContextValue extends MapglContextValue {
  startDrawingGeometry?: <Tt extends GeometryTypes>(
    type: Tt,
    options: GenericGeometryOptions<GeometryTypes>,
  ) => GenericRawGeometry<Tt> | null;

  addGeometry?: (geometry: Geometry | Geometry[]) => void;
  removeGeometry?: (geometry: Geometry | Geometry[]) => void;

  getChangingGeometry?: () => RawGeometry | null;
  getAllGeometries: () => unknown;
  getSelectedGeometry: () => unknown;
  onGeometryChange: () => void;
  cancelEditing: () => void;
  finishEditing: () => void;
}

export enum FeatureGeometryTypes {
  Point = 'Point',
  Polyline = 'Polyline',
  Polygon = 'Polygon',
}

export type GenericGeometryOptions<Tt> = Tt extends GeometryTypes.Point
  ? MarkerOptions
  : Tt extends GeometryTypes.Polyline
  ? PolylineOptions
  : Tt extends GeometryTypes.Polygon
  ? PolygonOptions
  : never;

export type OptionsType =
  | 'defaultOptions'
  | 'intersectionOptions'
  | 'parentOptions'
  | 'hoveredOptions'
  | 'selectedOptions'
  | 'clickedOptions'
  | 'childOptions'
  | 'allChildrenOptions'
  | 'districtOptions'
  | 'adjacentOptions'
  | 'reonAreaOptions'
  | 'originalDiffOptions'
  | 'copyDiffOptions';

export type GeometryToDraw<Td extends GeometryTypes> = {
  type: Td;
  options: GenericGeometryOptions<Td>;
};

export type MappedGeometryInstances = {
  [GeometryTypes.Polygon]: Polygon[];
  [GeometryTypes.Polyline]: LayerPolyline[];
  [GeometryTypes.Point]: Marker[];
};

export type HoveredObject<Td extends GeometryTypes = GeometryTypes> = Omit<
  Geometry<Td>,
  'geometry'
> & {
  geometry: {
    body: RawGeometry;
    visible: RawGeometry;
  };
};

export type DrawMethod = 'replace' | 'replaceAll' | 'add' | 'remove';

export type DrawGeometryObjects =
  | GeometryToDraw<GeometryTypes.Polygon>[]
  | GeometryToDraw<GeometryTypes.Point>[]
  | GeometryToDraw<GeometryTypes.Polyline>[]
  | (
      | GeometryToDraw<GeometryTypes.Polygon>
      | GeometryToDraw<GeometryTypes.Point>
      | GeometryToDraw<GeometryTypes.Polyline>
    )[];

export type Layers =
  | 'intersections'
  | 'districts'
  | 'parent'
  | 'selected'
  | 'children'
  | 'allChildren'
  | 'adjacent'
  | 'reonArea'
  | 'copyDiff'
  | 'originalDiff';

export type DrawGeometriesObject = Record<
  Layers,
  | GeometryToDraw<GeometryTypes.Point>[]
  | GeometryToDraw<GeometryTypes.Polygon>[]
  | GeometryToDraw<GeometryTypes.Polyline>[]
  | MappedGeometries
>;

export interface MapContextState {
  id: string;
  mapgl: typeof mapgl | null;
}

export type MapglContextValue = MapContextState & {
  setMapglContext: ReactSetState<MapContextState>;
  $mapElement: React.MutableRefObject<HTMLDivElement | null>;
  geometryUtils: GeometryUtils | null;
  drawGeometries: (
    newGeometries?: Partial<Record<Layers, MappedGeometries>>,
    method?: DrawMethod,
  ) => Record<Layers, MappedGeometryInstances>;
  drawLayers: (
    newLayers: Partial<Record<Layers, DrawGeometryObjects>>,
  ) => Record<Layers, MappedGeometryInstances>;
  getGeometry: (oghObjectId?: number | string) => {
    [key: string]: {
      coordinates: Coordinate | (Coordinate | Coordinate[] | Coordinate[][])[];
      type: string;
    };
  };

  /**
   * @deprecated
   * Use getGeometry instead.
   */
  getGeometries: () => {
    [key: string]: {
      coordinates: Coordinate | (Coordinate | Coordinate[] | Coordinate[][])[];
      type: string;
    };
  };
  drawIntersections: (
    items: ResponseGeometryObjects | ResponseGeometryObjects[],
  ) => Record<Layers, MappedGeometryInstances>;
  instances: ReturnType<typeof useGeometryInstances> | null;
  loadedGeometries: ReturnType<typeof useGeometryStorage> | null;
  mapService: MapService | null;
  onClickHintElementRef: React.MutableRefObject<{
    element: HTMLDivElement | null;
    setOnCloseClickHandler(cb: MouseEventHandler | null): void;
    // eslint-disable-next-line
    setPosition(clickedObject: RawGeometry | null, x: number, y: number): void;
  } | null>;
  onHoverHintElementRef: React.MutableRefObject<{
    element: HTMLDivElement | null;
    // eslint-disable-next-line
    setPosition(hoveredObject: RawGeometry | null, x: number, y: number): void;
  } | null>;
  setOnClickHintElement: ReactSetState<HTMLDivElement | null>;
  setOnHoverHintElement: ReactSetState<HTMLDivElement | null>;
  updateLoadedGeometries: (
    geometries: Partial<Record<Layers, DrawGeometryObjects>>,
  ) => Record<Layers, MappedGeometries>;
  zoomToGeometries: (
    geometries: MappedGeometryInstances,
    options?: Parameters<Map['fitBounds']>[1],
  ) => void;
};
