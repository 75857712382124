import getCurrentObject from 'app/selectors/card/getCurrentObject';
/* eslint-disable  */
import { hasIn } from 'lodash';
import { createSelector } from 'reselect';

/**
 *
 * @param state
 */
const getAccessoryDict = (state) => getCurrentObject(state).accessory;

/**
 *
 * @param state
 */
const returnLId = (state) => {
  return getCurrentObject(state).accessory.find(
    (accessory) => accessory.code === 'l',
  ).id;
};

/**
 *
 * @param state
 * @param createMode
 */
const getCurrentAccessoryId = (state, createMode) => {
  if (createMode) {
    return returnLId(state);
  }

  if (hasIn(getCurrentObject(state).attribute, 'accessory_id.accessory')) {
    return getCurrentObject(state).attribute.accessory_id.accessory;
  } else {
    return null;
  }
};

export default createSelector(
  [getAccessoryDict, getCurrentAccessoryId],
  (accessories, currentAccessoryId) => {
    let result;

    if (accessories) {
      result = currentAccessoryId;
    } else {
      result = null;
    }

    return result;
  },
);
