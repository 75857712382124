import { TREES_SHRUBS } from 'app/constants/oghTypes';
import getTypeId from 'app/selectors/card/getTypeId';
import getFormValues from 'app/selectors/form/getFormValues';
import getDict from 'app/selectors/getDict';
import getIdCodeMap from 'app/utils/card/getIdCodeMapFromDict';
import { createSelector } from 'reselect';

import { mandatory_fields } from './mandatoryFields';

const formFieldsRules = {
  [TREES_SHRUBS]: {
    control_fields: {
      life_form_type: {
        bush: [],
        liana: ['bio_group_num'],
        tree: ['height', 'age'],
      },
      state_gardening: {
        good: [],
        normal: ['characteristic_state_gardening_id'],
        not_normal: ['characteristic_state_gardening_id'],
      },
    },
  },
};

/**
 * Fn.
 *
 * @param {*} param - Parameters.
 * @returns {*}
 */
export default createSelector(
  [
    getTypeId,
    getDict('lifeFormTypes'),
    getDict('stateGardening'),
    getFormValues,
  ],
  (typeId, lifeFormTypes, stateGardening, formValues) => {
    const lifeFormTypeMap = getIdCodeMap(lifeFormTypes);
    const stateGardeningMap = getIdCodeMap(stateGardening);

    let result;

    switch (typeId) {
      case TREES_SHRUBS: {
        const mandatoryFields = mandatory_fields[typeId];
        const controlFields = formFieldsRules[typeId].control_fields;

        const lifeFormTypeCode = lifeFormTypeMap[formValues.life_form_type_id];
        const stateGardeningCode =
          stateGardeningMap[formValues.state_gardening_id];
        const lifeFormType = controlFields.life_form_type[lifeFormTypeCode];
        const stateGardening =
          controlFields.state_gardening[stateGardeningCode];

        if (lifeFormType && stateGardening) {
          result = [
            ...mandatory_fields[TREES_SHRUBS],
            ...lifeFormType,
            ...stateGardening,
          ];
        } else if (lifeFormType) {
          result = [...mandatory_fields[TREES_SHRUBS], ...lifeFormType];
        } else if (stateGardening) {
          result = [...mandatory_fields[TREES_SHRUBS], ...stateGardening];
        } else {
          result = [
            ...mandatoryFields,
            'bio_group_num',
            'height',
            'age',
            'characteristic_state_gardening_id',
          ];
        }

        break;
      }
      default: {
        result = [];
        break;
      }
    }

    return result;
  },
);
