import { get } from 'lodash';
/* eslint-disable  */

/**
 *
 * @param state
 * @param props
 */
export default (state, props) =>
  get(props, 'mode.createMode', false) ||
  get(state, 'card.mode.createMode', false);
