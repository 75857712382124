import { turn_into_snake_case } from 'app/api/crud/turn_into_snake_case';
import * as env from 'config/env';

import { getUrlWithParams } from './getUrlWithParams';
import { handleErrors } from './handleErrors';
import { toJson } from './middlewares';

/**
 * Метод-запрос удаления.
 *
 * @param {string} methodUrl - Метод ⚡ запроса он же урл.
 * @param {object} [queryParams] - Параметры.
 * @param {object} [options] - Дополнительные параметры.
 * @param {string} [options.host] - Хост, начальный урл.
 * @param {string} [options.credentials] - Опция credentials.
 * @param {boolean} [options.parseResponse] - Флаг, что нужно parseResponse.
 * @param {object} [options.headers] - Дополнительные параметры для хедера.
 * @param {boolean} [options.jsonBody] - Флаг, что jsonBody.
 * @returns {Promise<*|Response>}
 */
export const del = async (methodUrl, queryParams, options = {}) => {
  const {
    host = env.ODS_API_URL,
    credentials = 'include',
    parseResponse = true,
    headers,
    jsonBody = false,
    ...fetchOptions
  } = options;

  const url = getUrlWithParams(host, methodUrl, jsonBody ? null : queryParams);

  const bodyWithSnakeCase = turn_into_snake_case(queryParams);

  const queryOptions = {
    credentials,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      ...headers,
    },
    method: 'DELETE',
    ...fetchOptions,
  };

  if (jsonBody && typeof queryParams === 'object') {
    queryOptions.body = JSON.stringify(bodyWithSnakeCase);
  }

  const resp = await fetch(url, queryOptions);

  await handleErrors(resp);

  return parseResponse ? toJson(resp) : resp;
};
