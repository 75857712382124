/* eslint-disable */
import React from 'react';
import { TextFieldFF } from 'core/form/finalForm/fields/default/TextFieldFF/TextFieldFF';
import { DndFileUploadFF } from 'core/form/finalForm/fields/prepared/fileUpload/DndFileUploadFF';
import { Button } from 'core/uiKit/components/buttons/Button';
import { FinalForm } from 'core/form/finalForm/components/FinalForm';

import './DialogBody.scss';

 
export const DialogBody = ({
  onSubmit,
  cancelDialog,
  isLoading,
  filesFormat,
}) => {
  return (
    <FinalForm onSubmit={onSubmit}>
      <TextFieldFF
        name={'comment'}
        label={'Комментарий'}
        style={{ width: '100%' }}
      />
      <DndFileUploadFF accept={filesFormat} required={true} />
      <div className={'dialog-body__button-container'}>
        <Button variant={'outlined'} onClick={cancelDialog}>
          Отмена
        </Button>
        <Button
          loading={isLoading}
          type="submit"
          color={'primary'}
          variant={'contained'}
        >
          Продолжить
        </Button>
      </div>
    </FinalForm>
  );
};
