import { CheckboxCell } from 'core/uiKit/components/cells/Checkbox.Cell';
import { Cell } from 'types/table';
/**
 * Колонка __Признак согласования__.
 *
 * @type {{
 * accessor: 'is_matching',
 * Header: 'Признак согласования',
 * sortable: true,
 * }}
 * @augments Cell
 */
export const is_matching: Cell = {
  Cell: CheckboxCell,
  Header: 'Признак согласования',
  accessor: 'is_matching',
  sortable: true,
};
