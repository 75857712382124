import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

import { useDictEngineerBuildingTypeId } from './useDict.EngineerBuildingTypeId';

export const ENGINEER_BUILDING_TYPE_ID = 'engineer_building_type_id';

/**
 * Select __Тип (наименование)__.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const EngineerBuildingTypeId = (props) => {
  const { data } = useDictEngineerBuildingTypeId();

  return (
    <Select
      {...props}
      label={props.label || 'Тип (наименование)'}
      name={ENGINEER_BUILDING_TYPE_ID}
      options={data || []}
    />
  );
};
