/* eslint-disable */
import 'assets/styles/card.orders.legendPanel.scss';

import Legend from 'app/components/common/Legend';
import PropTypes from 'prop-types';
import React from 'react';

const legend = [
  {
    className: 'legend_in_progress',
    value: 'По ордеру ведутся работы',
  },
  {
    className: 'legend_finished',
    value: 'Работы по ордеру завершены',
  },
  {
    className: 'legend_planned',
    value: 'Работы по ордеру запланированы',
  },
];

/**
 *
 */
export default class LegendPanel extends React.Component {
  static propTypes = {
    className: PropTypes.string,
  };

  /**
   *
   */
  render() {
    return <Legend legend={legend} />;
  }
}
