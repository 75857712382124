import { CAR_OWNER_ID_NAME, CarOwnerIdFF } from 'core/form/finalForm/fields';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';
import { ObjectParam } from 'use-query-params';

import { filterInputAutocomplete } from '../filterInputsClasses';

/**
 * CarOwnerIdFilter.
 *
 * @param {object} props - Properties.
 * @returns {*}
 */
export const CarOwnerIdFilter = (props) => (
  <CarOwnerIdFF className={filterInputAutocomplete} {...props} />
);

export { CAR_OWNER_ID_NAME };

/**
 * CarOwnerParams.
 *
 * @returns {object}
 */
export const carOwnerParams = () => {
  addPreparation(CAR_OWNER_ID_NAME, (value) => value.id);
  return { [CAR_OWNER_ID_NAME]: ObjectParam };
};
