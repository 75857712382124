import { getCreatedCurrentCard } from 'app/api/requests/getCreatedCurrentCard';
import { getOghCard } from 'app/api/requests/getOghCard';
import { turnIntoCamelCase } from 'app/utils/turnIntoCamelCase';
import { useGetCreatedObjectId } from 'core/utils/hooks/useGetCreatedObjectId';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';
import { CardOgh } from 'types/Card';

/**
 * Функция получения текущих методов, создания или редактирования.
 *
 * @param {number} objectId - ObjectId.
 * @param {number} typeId - Тип объекта.
 * @param {string | undefined} startDate - Текущая дата.
 * @returns Object.
 */
const getCreatedCard = (
  objectId: number,
  typeId: number,
  startDate: string,
) => {
  const getCurrentFn = startDate ? getCreatedCurrentCard : getOghCard;
  const queryKey = startDate
    ? ['getOghCard', { startDate, typeId }]
    : ['getOghCard', objectId];
  return { getCurrentFn, queryKey };
};

/**
 * Хук, получения текущего паспорта ОГХ.
 *
 * @returns {CardOgh}
 * @example
 * const card = useGetCurrentCard();
 */
export const useGetCurrentCard = (): CardOgh & {
  //

  /**
   * Функцию перезапросить данные карточки.
   */
  refetch: () => void;

  /**
   * Флаг успешного получения данных.
   */
  isSuccess: boolean;

  /**
   * Флаг загрузки данных.
   */
  isLoading: boolean;
} => {
  const { objectId } = useGetObjectId();
  const { typeId, startDate } = useGetCreatedObjectId();
  const { queryKey, getCurrentFn } = getCreatedCard(
    objectId,
    typeId,
    startDate,
  );

  const { data, refetch, isSuccess, isLoading } = useQueryAdaptor(
    // @ts-ignore
    queryKey,
    getCurrentFn,
  );

  const dataCamel = turnIntoCamelCase(data);
  return {
    ...data,
    ...dataCamel,
    isLoading,
    refetch,
    successCard: isSuccess,
  };
};
