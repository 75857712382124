import { useGetGeometryFullMutation } from 'app/pages/cardsOgh/components/Map/api/geometryLoad';
/* eslint-disable */
import { useRef, useState } from 'react';

import { useGetTreeMainPage } from './hooks/useGetTreeMainPage';
import { useGetTypeIdMainPage } from './hooks/useGetTypeId.MainPage';

/**
 * Setup Главной страницы.
 *
 * @returns {{isLoading: boolean, ref: {current: null}, open: boolean, typeId: number, onClickToggle: Function, getGeometryFull: Function}}
 */
export const useSetupMainPage = () => {
  const [open, setOpen] = useState(true);
  const { typeId, changeTypeId } = useGetTypeIdMainPage();

  const ref = useRef(null);

  const { isLoading, isFetchingNextPage } = useGetTreeMainPage();

  const {
    getGeometryFull,
    getGeometryFullData: { isLoading: isLoadingGeometry },
  } = useGetGeometryFullMutation();

  /**
   *
   */
  const onClickToggle = () => {
    setOpen((pre) => !pre);
  };

  /**
   *
   * @param id
   */
  const onChangeTypeId = (id) => {
    changeTypeId(id);
    if (ref.current) {
      ref.current.scrollTop = 0;
    }
  };

  return {
    getGeometryFull,
    isLoading: isLoading || isFetchingNextPage || isLoadingGeometry,
    onChangeTypeId,
    onClickToggle,
    open,
    ref,
    typeId,
  };
};
