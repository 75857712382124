import { useFormValuesCardRF } from 'app/actions/odsObjectActions/useFormValuesCardRF';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { CleanSpCategoryIdRf } from 'core/form/reduxForm/fields/prepared/selects/simple/CategorySpIdRf/index';
import React from 'react';

/**
 * Select Категория по СП 42.13330.2016.
 *
 * @returns {JSX.Element|null}
 */
export const CleanSpCategoryIdField = () => {
  const { editMode } = useEditMode();
  const { is_orphan_object } = useFormValuesCardRF();

  if (is_orphan_object) {
    return null;
  }

  return (
    <CleanSpCategoryIdRf
      helpTitle={
        'П.11.4 СП 42.13330.2016. Свод правил. Градостроительство. Планировка и застройка городских и сельских поселений. Актуализированная редакция СНиП 2.07.01-89.'
      }
      required={true}
      disabled={!editMode}
      withAll={false}
    />
  );
};
