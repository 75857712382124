import getFormValues from 'app/selectors/form/getFormValues';
/* eslint-disable  */
import getDict from 'app/selectors/getDict';
import { createSelector } from 'reselect';

/**
 *
 * @param state
 */
const getTechnicRequestTypeId = (state) =>
  getFormValues(state).technic_request_type_id;

export default createSelector(
  [getDict('technicRequestType'), getTechnicRequestTypeId],
  (technicRequestTypes, technicRequestTypeId) => {
    let result;
    if (technicRequestTypeId) {
      const item = technicRequestTypes.find(
        (item) => item.id === technicRequestTypeId,
      );
      if (item) {
        result = item.code === 'add_car';
      } else {
        result = false;
      }
    } else {
      result = false;
    }
    return result;
  },
);
