/* eslint-disable */
import React from 'react';

import {
  CarConditionsFF,
  CAR_CONDITIONS_NAME,
} from 'core/form/finalForm/fields';
import { ArrayParam, withDefault } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

 
 
/**
 * Техническое состояние
 * carConditions
 *
 * @returns {JSX.Element}
 * @constructor
 */
 
export const CarConditionsFilter = () => (
  <CarConditionsFF className={filterInput} />
);

 
export const carConditionsParams = {
  [CAR_CONDITIONS_NAME]: withDefault(ArrayParam, []),
};
