import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { useEditCheckStatus } from 'app/pages/cardsOgh/hooks/useEditStatus';

/**
 * Хук проверки на Статус и на создание из внешней системы.
 *
 * @returns
 */
export const useEditByStateIdAndCreatedFromExternalSystem = (): boolean => {
  const IsCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();
  const { stateId } = useGetParentCard();
  const editStatus = useEditCheckStatus();

  if (!IsCurrentObjectIsExternalSystem) {
    return !editStatus;
  }
  return IsCurrentObjectIsExternalSystem && stateId === 1;
};
