// 26 Места сбора отходов

import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { validateNumeric } from 'app/components/card/common/validation';
import { mapStateToPropsReducer as documentsReducer } from 'app/components/card/ogh/DocumentsTab';
import { POINT } from 'app/constants/geometryTypes';
import { useGetAllowedGeometryTypesRF } from 'app/pages/cardsOgh/components/Map/MapEditPanel/hooks/useGetAllowedGeometryTypes';
import getCoatingTypes from 'app/selectors/card/getCoatingTypes';
import hasSeparateGarbageCollection from 'app/selectors/card/hasSeparateGarbageCollection';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import getFormValues from 'app/selectors/form/getFormValues';
import getDict from 'app/selectors/getDict';
import { has } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';

import { ContainerCard } from './Container/ContainerCard';
import { onSubmit } from './Container/onSubmit';

const getMafTypeLevel1 = getDict('mafTypeLevel1');
const getMafTypeLevel2 = getDict('mafTypeLevel2');

const getAbutmentTypes = getDict('abutmentTypes');
const getContainerTypes = getDict('containerTypes');

const containerDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

/**
 * Функция получения и установки изначальных значений из State.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {object} - Поля.
 * @example ----
 */
const mapStateToProps = (state, props) => {
  const { card } = props;

  const isPointGeometry = props?.allowedGeometryTypes.includes(POINT);

  const numericFields = [
    {
      name: 'area',
      positive: !isPointGeometry,
      type: 'decimal',
      zero: isPointGeometry,
    },
  ];

  const parse = getParseAttribute(props);

  const address_list = card.address_list
    ? card.address_list.map(({ attributes, ...item }) => ({
        ...item,
        ...attributes,
      }))
    : null;

  const result = {
    abutmentTypes: getAbutmentTypes(state),
    calcAttributes: state.calcAttributes,
    coatingTypes: getCoatingTypes(state, props),
    containerTypes: getContainerTypes(state),
    createMode: isCreateMode(state, props),
    editMode: isEditMode(state, props),
    enableReinitialize: true,
    formValues: getFormValues(state),
    hasSeparateGarbageCollection: hasSeparateGarbageCollection(state),
    inYard: !!parse('in_yard'),
    initialValues: {
      // abutment_type_list: {
      //   showDialog: false,
      //   table: parse('abutment_type_list'),
      // },
      address_list,
      area: parse('area'),
      coating_group_id: getInitialCoatingGroupId(props, parse),
      coating_type_id: parse('coating_type_id.coating_type'),
      container_type_id: parse('container_type_id.container_type'),
      endDate: parseDate(props.card.end_date),
      hasAddressBti: card.address_list,
      is_diff_height_mark: parse('is_diff_height_mark'),
      maf_type_list: {
        showDialog: false,
        table: parse('maf_type_list'),
      },
      no_calc: parse('no_calc'),
      startDate: parseDate(props.card.start_date),
      totalAreaGeo: parse('total_area_geo'),
    },
    isPointGeometry,
    mafTypeLevel1: getMafTypeLevel1(state),
    mafTypeLevel2: getMafTypeLevel2(state),
    numericFields,
    onSubmit: onSubmit(props, numericFields),
    required: isRequired(state, props),

    /**
     * Валидация числовых полей.
     *
     * @param {object} values - Поля.
     * @param {object} props - Пропсы.
     * @returns {*}
     */
    validate: (values, props) => validateNumeric(values, props.numericFields),
  };

  return containerDocumentsReducer(result, state, props);
};

/**
 * Функция getInitialCoatingGroupId.
 *
 * @param {object} props - Пропсы.
 * @param {Function} parse - Функция.
 * @returns {*} - Жопа.
 * @example ----
 */
export function getInitialCoatingGroupId(props, parse) {
  const improved = 1;
  return has(props.card.attribute, 'coating_group_id.coating_group')
    ? parse('coating_group_id.coating_group')
    : improved;
}

/**
 * Функция получения экшенов.
 *
 * @param {Function} dispatch - Функция диспатч.
 * @returns {{changeFieldValue: mapDispatchToProps.changeFieldValue}} - Экшен changeFieldValue.
 * @example ----
 */
function mapDispatchToProps(dispatch) {
  return {

    /**
     * Функция изменения поля.
     *
     * @param {string} field - Поле.
     * @param {*} value - Значение.
     */
    changeFieldValue: (field, value) => {
      dispatch(change('editorCard', field, value));
    },
  };
}

const Comp = connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'editorCard' })(ContainerCard));

/**
 * Контейнерная карточка. Обертка для хуков.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - Контейнерная карточка.
 */
export default (props) => {
  const allowedGeometryTypes = useGetAllowedGeometryTypesRF();
  return <Comp {...props} allowedGeometryTypes={allowedGeometryTypes} />;
};
