import { get } from 'app/api/crud/get';
import { DataNewsItem } from 'types/data.News';

/**
 * Функция послать серверу, что новость была прочитана .
 *
 * @param {number} id - Id новости.
 * @returns {Promise<DataNewsItem>} - Новость если есть id или пустой объект.
 */
export const makeNewsHasBeenRead = (id: number): Promise<DataNewsItem> => {
  return get('news/' + id);
};
