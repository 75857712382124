/* eslint-disable  */
/* Компонент для загрузки файлов. Поддерживает как непосредственный выбор, так и Drag and Drop */
import './dndFileUpload.scss';

import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Dropzone from 'react-dropzone';

import { FileListView } from './FileListView';

/**
 * Компонент для загрузки файлов.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const DndFileUpload = (props) => {
  /**
   * Удалить файл.
   *
   * @param {File} file - Файл.
   * @returns {void}
   */
  const onRemove = (file) => {
    props.remove(file);
  };

  /**
   * Отрисовка формата.
   *
   * @returns {JSX}
   */
  const renderAllowedFormatsMessage = () => {
    return [
      props.allowedFormatsMessage && <span>{props.allowedFormatsMessage}</span>,
    ];
  };

  /**
   * Отрисовка текста ошибки.
   *
   * @returns {JSX}
   */
  const renderErrorText = () => {
    return (
      props.errorText && (
        <p
          style={{
            color: 'red',
            fontWeight: 600,
          }}
        >
          {props.errorText}
        </p>
      )
    );
  };

  const textClass = cn({
    'dnd-file-upload__disabled': props?.disabled,
  });

  const { required } = props;
  return (
    <div className={props.className}>
      <Dropzone
        disabled={props.disabled}
        multiple={props.multiple}
        accept={props.accept}
        onDropAccepted={(file) => {
          props.add(file);
        }}
        onDrop={(acceptedFiles) => {
          // просто заглушка
          return acceptedFiles;
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div className={'dnd-file-upload__dropzone'} {...getRootProps()}>
            <input {...getInputProps()} />
            <p className={textClass}>
              {props.text || 'Переместите файлы или нажмите для выбора'}
              {required ? (
                <span style={{ color: 'rgb(244, 67, 54)' }}>&nbsp;*</span>
              ) : null}
            </p>
          </div>
        )}
      </Dropzone>

      {renderErrorText()}
      {renderAllowedFormatsMessage()}
      <div>{props.description}</div>

      <FileListView
        files={props.files}
        remove={onRemove}
        edit={props.disabled}
      />
    </div>
  );
};

DndFileUpload.defaultProps = {
  disabled: false,
  files: [],
  multiple: true,
};

DndFileUpload.propTypes = {
  accept: PropTypes.object,
  add: PropTypes.func.isRequired,
  allowedFormatsMessage: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  files: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  multiple: PropTypes.bool,
  remove: PropTypes.func.isRequired,
  required: PropTypes.bool,
  style: PropTypes.object,
  text: PropTypes.string,
};
