/* eslint-disable */
/**
 *
 * @param value
 */
const isIntegerNumber = (value) => {
  return new RegExp(/^[0-9]+$/).test(value);
};

export default isIntegerNumber;
