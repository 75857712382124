/* eslint-disable  */
/**
 *
 * @param diff
 */
export const filterTreeDiff = (diff = []) => {
  let treediff = [];
  if (Array.isArray(diff)) {
    treediff = diff.filter((row) => {
      return (
        row.is_equal === 0 || (row.is_equal === 1 && row.operation === 'move')
      );
    });
  }

  return treediff;
};

/* eslint-disable */
export const getNowDate = () => {
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  return new Intl.DateTimeFormat('ru', options).format(new Date());
};
