import { mapEngineeringStructuresTab } from 'app/components/card/ogh/Odh/components/tabs/EngineeringStructures.Tab/resetEngineeringStructuresTab';
import { DATE_SURVEY_NAME } from 'core/form/reduxForm/fields/prepared/datePickers/DateSurvey.RF';
import { INTENSITY_NUM_NAME } from 'core/uiKit/preparedInputs/numberField/IntensityNum';
import { CATEGORY_SP_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CategorySpId';

export const simpleMandatoryFieldNames = {
  /* declare_length: 'Протяженность суммарная по пикетажам, п.м',*/
  axis_length: 'Протяженность осевой разделительной линии, п.м',
  distance: 'Протяженность по оси, п.м',
  layout_length: 'Протяженность объекта по всем осям, п.м',
  name: 'Наименование',
  reagent_area: 'Площадь обработки реагентами, кв.м',
  rotor_area: 'Площадь роторной перекидки, кв.м',
  snow_area: 'Площадь вывоза снега, кв.м',
};

export const simpleMandatoryFieldNamesForOrphanObject = {
  asperity: 'ИДН, ед.',
  auto_footway_area: 'площадь уборки тротуаров мех., кв.м',
  bar_antinoise: 'защитная стенка, п.м',
  bar_concrete: 'бетонный парапет, ед.',
  bar_foot: 'пешеходные ограждения, п.м.',
  bar_fracasso: 'металлические барьерные ограждения Фракассо, п.м',
  bar_new_jersey: 'стенка Нью-Джерси, п.м',
  bar_pipe: 'металлическое барьерное ограждение Труба, п.м',
  bar_trans: 'металлическое барьерное ограждение Трансэкострой, п.м',
  bar_wave: 'металлическое барьерное ограждение Волна, п.м',
  buffer: 'буфер безопасности, ед.',
  distance: 'Протяженность по оси, п.м',
  file_list_19: 'документы подтверждающие внесение изменений',
  footway_area: 'тротуары, кв.м',
  footway_pole: 'тротуарные столбики, п.м',
  gutters_length: 'протяженность лотков, п.м',
  inbound_area: 'общая площадь в границах ОДХ, кв.м',
  info: 'информационные щиты, ед. ',
  layout_length: 'Протяженность объекта по всем осям, п.м',
  manual_footway_area: 'площадь уборки тротуаров ручн., кв.м',
  margin_area: 'обочины, кв.м',
  margin_auto_clean_area: 'площадь уборки обочин мех., кв.м',
  margin_manual_clean_area: 'площадь уборки обочин руч., кв.м',
  name: 'Наименование',
  pointer: 'дорожные указатели, ед. ',
  // reagent_area: 'Площадь обработки реагентами, кв.м',
  roadway_area: 'проезжая часть всего, кв.м',
  roadway_noprkg_auto_clean_area:
    'площадь механизированной уборки проезжей части',
  roadway_noprkg_manual_clean_area: 'площадь ручной уборки проезжей части',
  roadway_prkg_auto_clean_area:
    'площадь механизированной уборки парковочного пространства, кв.м',
  roadway_prkg_manual_clean_area:
    'площадь ручной уборки парковочного пространства, кв.м',
  rotor_area: 'площадь роторной перекидки, кв.м',
  sign: 'знаки, ед.',
  snow_area: 'площадь вывоза снега, кв.м',
  station_clean_area: 'площадь убираемых остановок, кв.м',
  station_number: 'количество убираемых остановок, ед.',
};

const mandatoryEngineeringStructuresTabFieldsNames = {
  bridge_psc: 'Мосты и путепроводы, п.м',
  bridge_qty: 'Мосты и путепроводы, шт.',
  bridge_sqr: 'Мосты и путепроводы, кв.м',
  cable_fancing_psc: 'Тросовое ограждение, п.м.',
  decor_psc: 'Декоративные посадки, п.м',
  lamp_qty: 'Линии наружного освещения, плафоны/ светильники, шт.',
  light_psc: 'Линии наружного освещения, протяженность, п.м',
  light_qty: 'Линии наружного освещения, опоры, шт.',
  pavilion_qty: 'Павильоны ООТ, шт.',
  pedestrian_psc: 'Надземные пешеходные переходы, п.м',
  pedestrian_qty: 'Надземные пешеходные переходы, шт.',
  pedestrian_sqr: 'Надземные пешеходные переходы, кв.м',
  police_qty: 'Пост ГИБДД, шт.',
  police_sqr: 'Пост ГИБДД, кв.м',
  sandbase_qty: 'Пескобаза, шт.',
  sandbase_sqr: 'Пескобаза, кв.м',
  snow_psc: 'Снегозащитные посадки, п.м',
  support_qty: 'Опоры (тросовые под знаки индивидуального проектирования), шт.',
  tlight_qty: 'Светофорные объекты, шт.',
  transform_qty: 'Трансформаторная подстанция, шт.',
  tube_psc: 'Трубы, длина по лотку, п.м',
  tube_qty: 'Трубы, шт.',
  urn_qty: 'Урны, шт.',
  wall_psc: 'Подпорная стенка, п.м.',
  water_psc: 'Водопропускные лотки, п.м.',
  сase_outside_light_qty: 'Шкаф наружного освещения, шт.',
};

export const mandatoryFieldNames = {
  ...simpleMandatoryFieldNames,
  ...mandatoryEngineeringStructuresTabFieldsNames,
  [CATEGORY_SP_ID_NAME]: 'Категория по СП 42.13330.2016',
  [INTENSITY_NUM_NAME]: 'Интенсивность движения',
  actual_schema_date: 'Дата актуализации плана-схемы',
  clean_category_id: 'Категория по уборке',
  clean_subcategory_id: 'Подкатегория по уборке',
  customer_id: 'Заказчик',
  date_survey: 'Дата обследования территории',
  grbs_id: 'Учредитель/ГРБС',
  owner_id: 'Балансодержатель',
  pass_dev_initiator: 'Инициатор разработки паспорта',
  passport_date: 'Дата составления/согласования паспорта',
};

export const mandatoryFieldNamesForOrphanObject = {
  ...simpleMandatoryFieldNames,
  ...simpleMandatoryFieldNamesForOrphanObject,
  ...mandatoryEngineeringStructuresTabFieldsNames,
  clean_category_id: 'Категория по уборке',
  clean_subcategory_id: 'Подкатегория по уборке',
};

export const notIsOrphanField = [
  'actual_schema_date',
  'description',
  'passport_draft_org',
  'passport_num',
  'passport_date',
  'owner_id',
  'pass_dev_initiator',
  CATEGORY_SP_ID_NAME,
  INTENSITY_NUM_NAME,
  DATE_SURVEY_NAME,
];

export const tabToFields = {
  'Инженерные сооружения для ТС': mapEngineeringStructuresTab,
  'Исходные данные': [
    'is_orphan_object',
    'category',
    'clean_category_id',
    'clean_subcategory_id',
    'passport_date',
    'actual_schema_date',
    'pass_dev_initiator',
    [CATEGORY_SP_ID_NAME],
    [DATE_SURVEY_NAME],
    [INTENSITY_NUM_NAME],
  ],

  'Основные параметры': [
    'distance',
    // 'declare_length',
    'axis_length',
    'road_mark_distance',
    'layout_length',
    'margin_area',
    'roadway_area',
    'footway_area',
  ],
  Свойства: ['owner_id', 'customer_id', 'name', 'grbs_id'],
  'Уборочные данные': ['reagent_area', 'snow_area', 'rotor_area'],
};

export const tabToFieldsForOrphanObject = {
  Документы: ['file_list_19'],
  'Инженерные сооружения для ТС': mapEngineeringStructuresTab,
  'Исходные данные': [
    'is_orphan_object',
    'category',
    'clean_category_id',
    'clean_subcategory_id',
    'pass_dev_initiator',
  ],
  'Основные параметры': [
    'inbound_area',
    'total_area',
    'distance',
    'layout_length',
    'layout_length',
    'gutters_length',
    'roadway_area',
    'margin_area',
    'footway_area',
    'sign',
    'pointer',
    'info',
  ],
  Свойства: ['name'],
  'Уборочные данные': [
    'asperity',
    'auto_footway_area',
    'bar_antinoise',
    'bar_concrete',
    'bar_foot',
    'bar_fracasso',
    'bar_new_jersey',
    'bar_pipe',
    'bar_trans',
    'bar_wave',
    'buffer',
    'footway_pole',
    'manual_footway_area',
    'margin_auto_clean_area',
    'margin_manual_clean_area',
    // 'reagent_area',
    'roadway_noprkg_auto_clean_area',
    'roadway_noprkg_manual_clean_area',
    'roadway_prkg_auto_clean_area',
    'roadway_prkg_manual_clean_area',
    'rotor_area',
    'snow_area',
    'station_clean_area',
    'station_number',
  ],
};

export const numericFields = [
  // {
  //   name: 'declare_length',
  //   type: 'decimal',
  //   positive: true,
  //   zero: false,
  // },
  {
    name: 'distance',
    positive: true,
    type: 'decimal',
    zero: true,
  },
  {
    name: 'layout_length',
    positive: true,
    type: 'decimal',
    zero: true,
  },
];

export const datesField = ['passport_date', DATE_SURVEY_NAME];
