import 'assets/styles/base.scss';
import '../services/autoFAQ.Service/autoFAQ.Service.scss';

import AjaxLoadingIndicator from 'app/components/common/misc/AjaxLoadingIndicator';
import NavBar from 'app/components/common/NavBar';
import DialogRoot from 'app/components/dialogs/common/DialogRoot';
import { NewsDialog } from 'app/components/dialogs/News.Dialog';
import { UserProvider } from 'app/pages/providers';
import { useInitFAQService } from 'app/services/autoFAQ.Service';
import cn from 'classnames';
import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { RoutesArr } from 'types/routes';

import { NewMapAlert } from './components/NewMap.Alert/NewMap.Alert';
import { PromptEditMode } from './components/PromptEditMode';
import FetchPreloadData from './FetchPreloadData';

interface BaseProps {
  routes: RoutesArr;
}

/**
 * Родительский компонент.
 *
 * @param props - Property.
 * @param props.routes - Роуты.
 * @returns JSX.Element.
 */
export const Base: FC<BaseProps> = ({ routes }) => {
  useInitFAQService();

  return (
    <UserProvider>
      <div className={cn('base')}>
        <NewsDialog />
        <PromptEditMode />
        <FetchPreloadData>
          <NewMapAlert />
          <NavBar className={cn('base__header')} isAuth={true} />
          <Routes>
            {routes.map((route, idx) => (
              <Route
                key={idx + route.path + '_Base'}
                path={route.path}
                element={<route.component routes={route.routes} />}
              />
            ))}
          </Routes>
          <DialogRoot />
          <AjaxLoadingIndicator />
        </FetchPreloadData>
      </div>
    </UserProvider>
  );
};
