import { getCurrentDate } from 'app/utils/date/getCurrentDate';
import { isValidMoment } from 'app/utils/date/isValidMoment';
import { toDate } from 'app/utils/date/toDate';
import { dayPlusMonth } from 'app/utils/dateUtils';
import { KeyboardDatePickerFF } from 'core/form/finalForm/fields/default/datePickers/KeyboardDatePickerFF';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';
import { useField } from 'react-final-form';
import { DateTimeParam, withDefault } from 'use-query-params';

import { filterInputData } from '../filterInputsClasses';

export const DATE_TO_NAME = 'dateTo';

/**
 * Input filter __Дата по__.
 *
 * @param {object} props - Properties.
 * @returns {JSX.Element}
 */
export const DateToFilter = (props) => {
  const dateFromInput = useField('dateFrom');

  return (
    <KeyboardDatePickerFF
      label={'Дата по'}
      minDate={dateFromInput?.input?.value}
      {...props}
      className={filterInputData}
      name={DATE_TO_NAME}
    />
  );
};

/**
 * Fn dateToparamsAddMonth.
 *
 * @returns {*}
 */
export const dateToParamsAddMonth = () => {
  addPreparation(DATE_TO_NAME, (value) => {
    let newValue = value;
    if (isValidMoment(value)) {
      newValue = toDate(value);
    }
    return newValue;
  });

  return { [DATE_TO_NAME]: withDefault(DateTimeParam, dayPlusMonth) };
};

/**
 * Fn dateFromParams .
 *
 * @param {*} initDate - Initial date.
 * @returns {*}
 */
export const dateToParams = (initDate = getCurrentDate()) => {
  addPreparation(DATE_TO_NAME, (value) => {
    let newValue = value;
    if (typeof value === 'object' && isValidMoment(value)) {
      newValue = toDate(value);
    }
    return newValue;
  });

  return {
    [DATE_TO_NAME]: withDefault(DateTimeParam, initDate),
  };
};
