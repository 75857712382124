 
/**
 * Дата.
 *
 * @typedef {Array} Data - Массив значений.
 * @property {string} end_date - Дата окончания.
 * @property {string} customer_short_name - Короткое имя заказчика.
 * @property {number} passport_date - Дата паспорта.
 * @property {number} object_root_id - Рут id объекта.
 * @property {string} owner_short_name - Короткое имя владельца.
 * @property {string} state_name - Имя состояния.
 * @property {number} short_object_id - Короткий id объекта.
 * @property {string} object_name - Имя объекта.
 * @property {Geometry} geometry - Геометрия.
 * @property {number} object_type_id - Id типа объекта.
 * @property {string} object_type_name - Имя типа объекта.
 * @property {number} state_id - Id состояния.
 * @property {string} start_date - Дата начала.
 */

 
/**
 * Объект геометрии.
 *
 * @typedef {object} Geometry - Геометрия.
 * @property {Array<{crs: { type: string, properties: {name: string}}, coordinates, type: string }>} [polygons] - Полигоны.
 * @property {Array<{crs: { type: string, properties: {name: string}}, coordinates, type: string }>} [lines] - Линии.
 * @property {Array<{crs: { type: string, properties: {name: string}}, coordinates, type: string }>} [points] - Точки..
 */

import { post } from 'app/api/crud/post';

 
/**
 * Запрос на получение смежных объектов.
 *
 * @param {object} data - Параметры запроса.
 * @param {string} data.endDate - Дата окончания в формате ДД.ММ.ГГГГ.
 * @param {string} data.startDate - Дата начала в формате ДД.ММ.ГГГГ.
 * @param {number} data.rootId - Дата начала в формате ДД.ММ.ГГГГ.
 * @param {Geometry} data.geometry - Геометрия объекта для которого ищется смежные объекты.
 * @returns {Promise<{data: Data,count: number,radius: number}>} - Ответ от сервера.
 * @example
 * const res = await fetchNeighboursGeometry(
 * {
 *  endDate "01.01.3000",
 *  geometry:{},
 *  maxRows: 50,
 *  page : 0,
 *  rootId: 27721897300038,
 *  startDate: "01.04.2021"
 * })
 *
 * res //?
 * {
 *  count: 5,
 *  data: [{object_type_id: 1, object_type_name: "Объекты дорожного хозяйства",…},…],
 *  radius: 35,
 * }
 */
export const fetchNeighboursGeometry = (data) =>
  post('/ogh/geometry/neighbours', data);
