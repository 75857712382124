import { LinkShortIdCell } from 'core/uiKit/components/cells/LinkShortId.Cell';


/* eslint-disable */
export default [
  {
    id: 'root_id',
    name: 'ID',
    Cell: LinkShortIdCell,
    width: 80,
  },
  {
    id: 'section_num',
    name: 'Номер участка',
  },
  {
    id: 'green_num',
    name: 'Номер растения',
  },
  {
    id: 'plantation_type_name',
    name: 'Тип насаждения',
  },
  {
    id: 'life_form_type_name',
    name: 'Жизненная форма',
  },
  {
    id: 'plant_type_name',
    name: 'Вид растения',
  },
  {
    id: 'quantity',
    name: 'Количество, шт.',
  },
  {
    id: 'area',
    name: 'Площадь, кв.м',
  },
  {
    id: 'distance',
    name: 'Количество, п.м',
  },
  {
    id: 'object_status_name',
    name: 'Статус',
    width: 110,
  },
  {
    id: 'start_date',
    name: 'Дата начала',
    width: 90,
  },
  {
    id: 'end_date',
    name: 'Дата окончания',
    width: 90,
  },
];
