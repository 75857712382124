import _ from 'lodash';

import * as types from '../constants/actionTypes';

/* eslint-disable */
export default function tables(
  state = {
    reagentNormCard: {},
    roleRegistry: { maxRows: 50, page: 0 },
    userRegistry: { maxRows: 50, page: 0 },
    templateRegistry: { maxRows: 50, page: 0 },
    titleListSearch: { maxRows: 50, page: 0 },
    nestedObjects: { maxRows: 50, page: 0 },
    registryObjects: { maxRows: 50, page: 0 },
    tlHistory: { maxRows: 50, page: 0 },
    userRoles: { maxRows: 50, page: 0 },
    orders: { maxRows: 50, page: 0 },
    basePgmOperations: { maxRows: 50, page: 0 },
    basePgmExports: { maxRows: 10, page: 0 },
    basePgmExportReagentPlans: { maxRows: 10, page: 0 },
    versionsSearch: {
      maxRows: 50,
      page: 0,
      sort: 'start_date.asc',
    },
  },
  action,
) {
  switch (action.type) {
    case types.SET_TABLE_PARAMETERS: {
      const { tableId, parameters } = action;
      return { ...state, [tableId]: { ...state[tableId], ...parameters } };
    }
    case types.SET_TABLE_PARAM: {
      const { tableId, paramName, paramValue } = action;
      return {
        ...state,
        [tableId]: { ...state[tableId], [paramName]: paramValue },
      };
    }
    case types.RESET_TABLE_PARAM: {
      const { tableId, parameters } = action;
      return { ...state, [tableId]: { ..._.omit(state[tableId], parameters) } };
    }
    default:
      return state;
  }
}
