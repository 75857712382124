import { FieldRF } from 'core/form/reduxForm/helpers/FieldRF';
import { OWNER_ID_NAME } from 'core/uiKit/preparedInputs/selects/autocompleteAsync/OwnerIdOdh';
import { OwnerIdPlace } from 'core/uiKit/preparedInputs/selects/autocompleteAsync/OwnerIdPlace';

/**
 * Балансодержатель для ОДХ.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 * @class
 */
export const OwnerIdPlaceRF = ({ ...otherProps }) => {
  return (
    <FieldRF {...otherProps} name={OWNER_ID_NAME} component={OwnerIdPlace} />
  );
};
