/* eslint-disable  */

import * as actions from 'app/actions/basePgmExports';
import { Table } from 'core/uiKit/components/tables/Table';
import get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

/**
 * Преобразование массива колонок таблицы в понятный компоненту таблицы формат.
 *
 * @param {*} source - A.
 * @param {*} onExportClick - A.
 * @returns {*}
 */
const makeColumns = (source = [], onExportClick) =>
  source.map((column) => {
    const col = {
      Header: column.name,
      accessor: column.id,
    };

    // ссылка на детальную информацию по графику перемещения реагентов
    if (column.id === 'id') {
      col.Cell = ({ row }) => (
        <Link
          to={`/pgm/docs/export/${row.original.id}`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onExportClick(row.original.id);
          }}
        >
          {row.original.id}
        </Link>
      );
    }

    return col;
  });

/**
 * Преобразование массива записей таблицы в понятный компоненту таблицы формат.
 *
 * @param {*} source - J.
 * @returns {*}
 */
const makeValues = (source = []) =>
  source.map((row) => ({
    _id: uuid(),
    ...row,
  }));
/**
 * A.
 *
 * @param {*} root0 - A.
 * @param {*} root0.data - A.
 * @param {*} root0.columns - A.
 * @param {*} root0.onExportClick - A.
 * @param {*} root0.onLimit - A.
 * @param {*} root0.onSortedChange - A.
 * @param {*} root0.onPagination - A.
 * @returns {*}
 */
const ExportsTable = ({
  data,
  columns,
  onExportClick,
  onLimit,
  onSortedChange,
  onPagination,
}) => (
  <Table
    columns={makeColumns(columns, onExportClick)}
    data={makeValues(get(data, 'table') || [])}
    fetchData={onPagination}
    pageCount={data}
    sortable_columns={columns.filter((col) => col.sort)}
    onLimit={onLimit}
    onSort={onSortedChange}
  />
);
/**
 * J.
 *
 * @param {*} state - J.
 * @returns {*}
 */
const mapStateToProps = (state) => {
  return {
    columns: state?.tableColumns?.basePgmExports || [],
  };
};

const mapDispatchToProps = {
  onLimit: actions.onLimit,
  onPagination: actions.onPagination,
  onSortedChange: (data) => {
    if (data && data.sort) {
      return actions.onSort(data);
    }
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportsTable);
