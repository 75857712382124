import { hideDialog, showError } from 'app/actions/dialog';
import { fetchOgh as fetchOghCustom } from 'app/api/ogh';

import { receiveErrorRootCard } from './receiveErrorRootCard';
import { receiveRootCard } from './receiveRootCard';
import { requestRootCard } from './requestRootCard';

/* eslint-disable */

/**
 *
 * @param id
 */
export const fetchOgh = (id) => async (dispatch) => {
  if (isNaN(id)) {
    return false;
  }

  dispatch(requestRootCard(id));
  const response = await fetchOghCustom(id);
  const json = await response.json();

  if (response.status === 403) {
    const errorMessage = json.errors.toString();
    dispatch(
      showError(errorMessage, () => {
        // dispatch(goBack());
        dispatch(hideDialog());
      }),
    );
    dispatch(receiveErrorRootCard(json));
  } else {
    dispatch(receiveRootCard(json, true));
  }

  return json;
};
