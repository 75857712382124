import * as api from 'app/api';
import * as types from 'app/constants/actionTypes';
import dateFormatService from 'core/services/dateFormatService';
import _ from 'lodash';
import { actions as formActions } from 'react-redux-form';
import { createAction } from 'redux-actions';

import * as loaderActions from './loader';

 
export const apiGetOghOrders = createAction(
  types.API_GET_OGH_ORDERS,
  function (data) {
    return {
      promise: api.getOghOrders(data),
    };
  },
);

/* eslint-disable */
export function toggleOghOrderGeometry(value) {
  return {
    type: types.TOGGLE_OGH_ORDER_GEOMETRY,
    payload: value,
  };
}

/* eslint-disable */
export function resetOrdersGeometry() {
  return {
    type: types.RESET_OGH_ORDERS_GEOMETRY,
  };
}

/* eslint-disable */
export function toggleOghOrderDetails(value) {
  return {
    type: types.TOGGLE_OGH_ORDER_DETAILS,
    orderId: value,
  };
}

/* eslint-disable */
export function formChange(values) {
  return function (dispatch) {
    dispatch(
      formActions.change('rrf.card.ogh.orders' + values.model, values.value),
    );
  };
}

/* eslint-disable */
export function onSubmit(values) {
  return function (dispatch) {
    dispatch(loaderActions.show());
    dispatch(resetOrdersGeometry());
    dispatch(formActions.change('rrf.card.ogh.orders.page', 0));

    const requestValues = getSearchFormValues(values);
    const promise = apiGetOghOrders(requestValues);

    return dispatch(promise).finally(function () {
      dispatch(loaderActions.hide());
    });
  };
}

/* eslint-disable */
export function onSort(values) {
  return function (dispatch) {
    dispatch(formActions.merge('rrf.card.ogh.orders', values));
    dispatch(formActions.submit('rrf.card.ogh.orders'));
  };
}

/* eslint-disable */
export function onLimit(value) {
  return function (dispatch) {
    dispatch(formActions.change('rrf.card.ogh.orders.maxRows', value));
    dispatch(formActions.change('rrf.card.ogh.orders.page', 0));
    dispatch(formActions.submit('rrf.card.ogh.orders'));
  };
}

/* eslint-disable */
export function onPagination(value) {
  return function (dispatch) {
    dispatch(formActions.change('rrf.card.ogh.orders.page', value));
    dispatch(formActions.submit('rrf.card.ogh.orders'));
  };
}

/* eslint-disable */
export function onReset(values) {
  return function (dispatch) {
    dispatch(
      formActions.change('rrf.card.ogh.orders.objectId', values.objectId),
    );
    dispatch(
      formActions.change(
        'rrf.card.ogh.orders.workstartdate',
        values.workstartdate,
      ),
    );
    dispatch(
      formActions.change('rrf.card.ogh.orders.workenddate', values.workenddate),
    );
    dispatch(formActions.change('rrf.card.ogh.orders.maxRows', 50));
    dispatch(formActions.change('rrf.card.ogh.orders.page', 0));
    dispatch(
      formActions.change('rrf.card.ogh.orders.progressStatus', 'in_progress'),
    );
    dispatch(formActions.submit('rrf.card.ogh.orders'));
  };
}

function getSearchFormValues(values) {
  const formValues = {};

  _.forEach(values, function (value, key) {
    if (value) {
      if (value instanceof Date) {
        formValues[key] = dateFormatService.formatDate(value);
      } else {
        formValues[key] = value;
      }
    }
  });

  return formValues;
}
