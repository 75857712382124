/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */

/**
 * Колонка __Рокарий (многолетние, однолетние), кв.м__.
 *
 * @type {{
 * accessor: 'rockery_area',
 * Header: 'Рокарий (многолетние, однолетние), кв.м',
 * }}
 * @augments Cell
 */
export const rockery_area = {
  Header: 'Рокарий (многолетние, однолетние), кв.м',
  accessor: 'rockery_area',
  sortable: true,
};
