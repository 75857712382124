import {
  ABUTMENT,
  ENGINEER_BUILDING,
  RED_BOOK_PLANT,
  TANK,
  VERTICAL_LANDSCAPING,
  WATER_BUILDING,
} from 'app/constants/oghTypes';

export const bridgeMap = [
  WATER_BUILDING,
  TANK,
  RED_BOOK_PLANT,
  VERTICAL_LANDSCAPING,
  ENGINEER_BUILDING,
  ABUTMENT,
];

/**
 * Проверка на новый паспорт.
 *
 * @param {number} typeId - Тип ОГХ.
 * @returns {boolean}
 */
export const checkIsNewCard = (typeId) => bridgeMap.includes(+typeId);
