import { get } from 'app/api/crud';

/**
 * Зарос на доступы показа кнопок.
 *
 * @param {*} param1 - Парамс.
 * @param {*} param1.objectId - Id _Обьекта.
 * @param {*} param1.parentId - Id _родителя.
 * @returns {*}
 */
export const getVisibilityButtonsOgh = ({ objectId, parentId }) => {
  // todo Костыль сделано так чтобы при создании новой дочерний карточки запрос не отправлялся
  if (objectId && objectId !== 'create') {
    return get('/ogh/visibility/buttons', { objectId, parentId });
  }
};
