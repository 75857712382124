import { shallow } from 'enzyme';
import { vi } from 'vitest';

import { OdhCard } from './OdhCard';

vi.mock('dateformat', () => ({
  DateFormat: vi.fn(),
}));

vi.mock('redux-form', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    reduxForm: vi.fn(() => (Component) => {
      return Component;
    }),
  };
});

vi.mock('react-redux', () => ({
  ...vi.importActual('react-redux'),
  connect: vi.fn(() => (ba) => {
    return ba;
  }),
}));

describe('Odh', function () {
  it('default', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const props = {
      calcAttributes: {
        inbound_area: '',
      },
      card: {
        name: '',
        parent_info: {},
      },
      currentValues: { is_orphan_object: false },
      editMode: true,
      isEditCurrentObjectIsExternalSystem: true,
      mode: {
        disabled: false,
      },
    };
    // Act
    const wrapper = shallow(<OdhCard {...props} />);

    // Assert
    expect(wrapper.text()).toBe('<CardContainer />');
  });

  it('TabsOdh', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const props = {
      calcAttributes: {
        inbound_area: '',
      },
      card: {
        name: '',
        parent_info: {},
      },
      currentValues: { is_orphan_object: false },
      editMode: true,
      isEditCurrentObjectIsExternalSystem: true,
      mode: {
        disabled: false,
      },
      onPropertyTab: 'onPropertyTab',
      propertyTab: 'propertyTab',
    };
    // Act
    const wrapper = shallow(<OdhCard {...props} />);

    // Assert
    expect(wrapper.find('CardContainer').find('TabsOdh').props()).toStrictEqual(
      {
        onPropertyTab: 'onPropertyTab',
        propertyTab: 'propertyTab',
      },
    );
  });

  it('children tabs', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const props = {
      calcAttributes: {
        inbound_area: '',
      },
      card: {
        name: '',
        parent_info: {},
      },
      currentValues: { is_orphan_object: false },
      editMode: true,
      isEditCurrentObjectIsExternalSystem: false,
      mode: {
        disabled: false,
      },
      propertyTab: 'propertyTab',
    };
    // Act
    const wrapper = shallow(<OdhCard {...props} />);

    // Assert
    expect(wrapper.find('BasicParameters').prop('propertyTab')).toBe(
      'propertyTab',
    );
    expect(wrapper.find('IsOrphanObjectRfField').length).toBe(1);
    expect(wrapper.find('Cleaning').prop('propertyTab')).toBe('propertyTab');
    expect(wrapper.find('MovementCharacteristics').prop('propertyTab')).toBe(
      'propertyTab',
    );
    expect(wrapper.find('EngineeringStructuresTab').prop('propertyTab')).toBe(
      'propertyTab',
    );
  });
});
