import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';

/**
 * Setup тип ОГХ.
 *
 * @returns {object}
 */
export const useSetupTypeIdOgh = () => {
  const dict = 'ogh_types';
  const { data } = useGetDictionary(dict);

  return { data };
};
