const dateFormat = 'DD.MM.YYYY';
const timeFormat = 'HH:mm:ss';
const dateTimeFormat = `${dateFormat} ${timeFormat}`;


/* eslint-disable */
export default {
  dateFormat,
  timeFormat,
  dateTimeFormat,
};
