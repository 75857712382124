import { useChangeFieldValueRF } from 'app/actions/odsObjectActions/useChangeFieldValueRF';
import { useFormValuesCardRF } from 'app/actions/odsObjectActions/useFormValuesCardRF';
import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useState } from 'react';
import { vi } from 'vitest';

import { useSetupMafTypeLevel2IdField } from './useSetup.MafTypeLevel2Id.Field';

vi.mock('app/actions/odsObjectActions/useChangeFieldValueRF');
vi.mock('app/actions/odsObjectActions/useFormValuesCardRF');
vi.mock('app/api/hooks/autocomplete/useGetDictionary', () => ({
  useGetDictionary: vi.fn(),
}));
vi.mock('app/pages/cardsOgh/hooks/useMode');
vi.mock('react', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    useState: vi.fn(),
  };
});

/**
 * Дефолтные моки.
 *
 */
const defaultMock = () => {
  useGetDictionary.mockReturnValue({ data: undefined });
  useMode.mockReturnValue({ editMode: false });
  useFormValuesCardRF.mockReturnValue({});
  useChangeFieldValueRF.mockReturnValue(vi.fn());
  useState.mockReturnValue([]);
};

describe('🐛 UseSetupMafTypeLevel2IdFieldSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    defaultMock();
    // 🔥 Act
    const res = useSetupMafTypeLevel2IdField();

    // ❓ Assert
    expect(res).toStrictEqual({
      disabled: true,
      filter: expect.any(Function),
      options: [],
    });
  });
  it('🧪 filter called', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const setHaveOptionsSpy = vi.fn();
    defaultMock();
    useState.mockReturnValue([false, setHaveOptionsSpy]);
    // 🔥 Act
    const { filter } = useSetupMafTypeLevel2IdField();
    const res = filter();

    // ❓ Assert
    expect(res).toStrictEqual([]);
    expect(setHaveOptionsSpy).toHaveBeenCalledWith(false);
  });
});
