import { turnIntoCamelCase } from 'app/utils/api';

/**
 * Функция валидации полей roadwayAreaValidation.
 *
 * @param {object} formValues - Значения из redux form.
 * @param {boolean} isOrphanObject - Флаг isOrphanObject.
 * @returns {{roadway_area: string} | undefined} - Возвращает ошибку для поля roadway_area.
 * @example roadwayAreaValidation(formValues, isOrphanObject)
 */
export const roadwayAreaValidation = (formValues, isOrphanObject) => {
  let {
    roadwayArea,
    totalArea,
    roadwayNoprkgManualCleanArea,
    roadwayNoprkgAutoCleanArea,
    roadwayPrkgManualCleanArea,
    roadwayPrkgAutoCleanArea,
  } = turnIntoCamelCase(formValues);

  roadwayArea = Number(roadwayArea);
  totalArea = Number(totalArea);
  roadwayNoprkgManualCleanArea = Number(roadwayNoprkgManualCleanArea);
  roadwayNoprkgAutoCleanArea = Number(roadwayNoprkgAutoCleanArea);
  roadwayPrkgManualCleanArea = Number(roadwayPrkgManualCleanArea);
  roadwayPrkgAutoCleanArea = Number(roadwayPrkgAutoCleanArea);

  if (isOrphanObject && roadwayArea > totalArea) {
    return {
      roadway_area:
        'значение параметра «Проезжая часть всего, кв.м» должно быть не больше значения параметра «Общая площадь в ТС, кв.м»',
    };
  }

  const sum =
    roadwayNoprkgManualCleanArea +
    roadwayNoprkgAutoCleanArea +
    roadwayPrkgManualCleanArea +
    roadwayPrkgAutoCleanArea;

  if (roadwayArea && sum && +roadwayArea.toFixed(2) < +sum.toFixed(2)) {
    return {
      roadway_area:
        'значение параметра «Проезжая часть всего, кв.м» должно быть не больше значения параметра «Общая площадь в ТС, кв.м» и не больше суммы значений параметров «Площадь ручной уборки проезжей части (без парковочного пространства), кв.м», «Площадь механизированной уборки проезжей части (без парковочного пространства), кв.м», «Площадь ручной уборки парковочного пространства, кв.м» и «Площадь механизированной уборки парковочного пространства, кв.м»',
    };
  }
};
