/* eslint-disable */
/**
 * Соединяем подпись и файлы.
 *
 * @param {object} param - Параметры.
 * @param {Array<{name: string, storageId: number}>} param.files - Id файлов в файловом хранилище для подписи.
 * @param {Array<{name: string, storageId: number}>} param.signatures - Id подписи в файловом хранилище для файлов.
 * @returns {Array<{signinfo_storage_id: number, source_file_storage_id: number}>}
 */
export const prepareDataForSaveSign = ({
  files = [],
  signatures = [],
} = {}) => {
  return files.map((item) => {
    const sign = signatures.find((s) => s.name === item.name + '.sig');
    return {
      signinfo_storage_id: sign?.storageId,
      source_file_storage_id: item?.storageId,
    };
  });
};
