import { GeometryTypes } from '../types.d';
import { pointToMsk77 } from './pointToMsk77';
import { polygonToMsk77 } from './polygonToMsk77';
import { polylineToMsk77 } from './polylineToMsk77';
import { toMsk77 } from './toMsk77';

/**
 *  Функция со статическими методами для трансформации координат из  geoJSON в мск77.
 *
 * @param type - Тип геометрии.
 * @returns Function - Функция со статическими методами для трансформации координат из  geoJSON в мск77.
 */
export const getGeoJSONCoordinatesFormatterToMsk77 = (
  type: GeometryTypes.Polyline | GeometryTypes.Polygon | GeometryTypes.Point,
) => {
  switch (type) {
    case GeometryTypes.Point:
      return pointToMsk77;
    case GeometryTypes.Polyline:
      return polylineToMsk77;
    case GeometryTypes.Polygon:
      return polygonToMsk77;
    default:
      return toMsk77;
  }
};
