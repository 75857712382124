import { get } from 'app/api/crud/get';

/**
 * Fetch car customers.
 *
 * @param {object} data - Data.
 * @returns {Promise}
 */
export const fetchCarCustomers = async (data) => {
  const res = get('/autocomplete/car_customers', data);
  return res || [];
};
