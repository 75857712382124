export const owners = [
  {
    id: -1,
    name: 'Без балансодержателя',
  },
  {
    id: 0,
    name: 'Все балансодержатели',
  },
  {
    id: 9000018,
    name: 'АвД',
  },
  {
    id: 9000019,
    name: 'АвД ВАО',
  },
  {
    id: 10002230,
    name: 'АвД ЗАО',
  },
  {
    id: 9000021,
    name: 'АвД ЗелАО',
  },
  {
    id: 9000026,
    name: 'АвД САО',
  },
  {
    id: 9000020,
    name: 'АвД СВАО',
  },
  {
    id: 9000023,
    name: 'АвД СЗАО',
  },
  {
    id: 9000022,
    name: 'АвД ЦАО',
  },
  {
    id: 9000024,
    name: 'АвД ЮАО',
  },
  {
    id: 10002210,
    name: 'АвД ЮВАО',
  },
  {
    id: 9000025,
    name: 'АвД ЮЗАО',
  },
  {
    id: 9000029,
    name: 'Администрация городского округа Троицк',
  },
  {
    id: 9000030,
    name: 'Администрация городского округа Щербинка',
  },
  {
    id: 9000031,
    name: 'Администрация поселения Внуковское',
  },
  {
    id: 9000043,
    name: 'Администрация поселения Вороновское',
  },
  {
    id: 9000032,
    name: 'Администрация поселения Воскресенское',
  },
  {
    id: 9000033,
    name: 'Администрация поселения Десёновское',
  },
  {
    id: 9000038,
    name: 'Администрация поселения Киевский',
  },
  {
    id: 9000044,
    name: 'Администрация поселения Кленовское',
  },
  {
    id: 9000039,
    name: 'Администрация поселения Кокошкино',
  },
  {
    id: 9000045,
    name: 'Администрация поселения Краснопахорское',
  },
  {
    id: 9000040,
    name: 'Администрация поселения Марушкинское',
  },
  {
    id: 9000046,
    name: 'Администрация поселения Михайлово-Ярцевское',
  },
  {
    id: 9000034,
    name: 'Администрация поселения Московский',
  },
  {
    id: 9000035,
    name: 'Администрация поселения Мосрентген',
  },
  {
    id: 9000041,
    name: 'Администрация поселения Новофедоровское',
  },
  {
    id: 9000047,
    name: 'Администрация поселения Роговское',
  },
  {
    id: 9000048,
    name: 'Администрация поселения Рязановское',
  },
  {
    id: 9000036,
    name: 'Администрация поселения Сосенское',
  },
  {
    id: 9000037,
    name: 'Администрация поселения Филимонковское',
  },
  {
    id: 9000049,
    name: 'Администрация поселения Щаповское',
  },
  {
    id: 9000042,
    name: 'Администрация п.Первомайское',
  },
  {
    id: 10232026,
    name: 'ГБУ «ДО ТЗФ ТИНАО»',
  },
  {
    id: 48051074,
    name: 'ГПБУ Мосприрода',
  },
  {
    id: 9000001,
    name: 'ДЗ ВАО',
  },
  {
    id: 9000008,
    name: 'ДЗ ЗАО',
  },
  {
    id: 10004253,
    name: 'ДЗ ЗелАО',
  },
  {
    id: 9000005,
    name: 'ДЗ САО',
  },
  {
    id: 9000006,
    name: 'ДЗ СВАО',
  },
  {
    id: 9000007,
    name: 'ДЗ СЗАО',
  },
  {
    id: 9000009,
    name: 'ДЗ ЦАО',
  },
  {
    id: 9000012,
    name: 'ДЗ ЮАО',
  },
  {
    id: 9000010,
    name: 'ДЗ ЮВАО',
  },
  {
    id: 9000011,
    name: 'ДЗ ЮЗАО',
  },
  {
    id: 48051485,
    name: 'ДПиООС города Москвы',
  },
  {
    id: 102266300,
    name: 'Жилищник Академический',
  },
  {
    id: 10231438,
    name: 'Жилищник Алексеевский',
  },
  {
    id: 10231440,
    name: 'Жилищник Алтуфьевский',
  },
  {
    id: 10231424,
    name: 'Жилищник Арбат',
  },
  {
    id: 102266400,
    name: 'Жилищник Аэропорт',
  },
  {
    id: 10228749,
    name: 'Жилищник Бабушкинский',
  },
  {
    id: 10227244,
    name: 'Жилищник Басманный',
  },
  {
    id: 10233594,
    name: 'Жилищник Беговой',
  },
  {
    id: 10233578,
    name: 'Жилищник Бескудниковский',
  },
  {
    id: 10233544,
    name: 'Жилищник Бибирево',
  },
  {
    id: 10231482,
    name: 'Жилищник Бирюлево Восточное',
  },
  {
    id: 10231480,
    name: 'Жилищник Бирюлево Западное',
  },
  {
    id: 10228750,
    name: 'Жилищник Богородское',
  },
  {
    id: 10234091,
    name: 'Жилищник Братеево',
  },
  {
    id: 10233515,
    name: 'Жилищник Бутырский',
  },
  {
    id: 10231461,
    name: 'Жилищник Вешняки',
  },
  {
    id: 102266920,
    name: 'Жилищник Внуково',
  },
  {
    id: 10231436,
    name: 'Жилищник Войковский',
  },
  {
    id: 10233562,
    name: 'Жилищник Восточное Дегунино',
  },
  {
    id: 10231453,
    name: 'Жилищник Восточное Измайлово',
  },
  {
    id: 102266600,
    name: 'Жилищник Восточный',
  },
  {
    id: 102266700,
    name: 'Жилищник Выхино Выхино-Жулебино',
  },
  {
    id: 10235057,
    name: 'Жилищник Гагаринский',
  },
  {
    id: 10231430,
    name: 'Жилищник Головинский',
  },
  {
    id: 10226005,
    name: 'Жилищник Гольяново',
  },
  {
    id: 10228991,
    name: 'Жилищник Даниловский',
  },
  {
    id: 10233583,
    name: 'Жилищник Дмитровский',
  },
  {
    id: 102266760,
    name: 'Жилищник Донской',
  },
  {
    id: 10226963,
    name: 'Жилищник Дорогомилово',
  },
  {
    id: 10228760,
    name: 'Жилищник Замоскворечье',
  },
  {
    id: 10228568,
    name: 'Жилищник Западное Дегунино',
  },
  {
    id: 102267100,
    name: 'Жилищник ЗелАО',
  },
  {
    id: 10231488,
    name: 'Жилищник Зюзино',
  },
  {
    id: 10234026,
    name: 'Жилищник Зябликово',
  },
  {
    id: 10231457,
    name: 'Жилищник Ивановское',
  },
  {
    id: 10231450,
    name: 'Жилищник Измайлово',
  },
  {
    id: 10231465,
    name: 'Жилищник Капотня',
  },
  {
    id: 10231484,
    name: 'Жилищник Коньково',
  },
  {
    id: 10231432,
    name: 'Жилищник Коптево',
  },
  {
    id: 102266580,
    name: 'Жилищник Косино-Ухтомский',
  },
  {
    id: 10231486,
    name: 'Жилищник Котловка',
  },
  {
    id: 102266360,
    name: 'Жилищник Красносельский',
  },
  {
    id: 10231494,
    name: 'Жилищник Крылатское',
  },
  {
    id: 10233686,
    name: 'Жилищник Кузьминки',
  },
  {
    id: 10231496,
    name: 'Жилищник Кунцево',
  },
  {
    id: 10231510,
    name: 'Жилищник Куркино',
  },
  {
    id: 10233599,
    name: 'Жилищник Левобережный',
  },
  {
    id: 10234258,
    name: 'Жилищник Лефортово',
  },
  {
    id: 10233474,
    name: 'Жилищник Лианозово',
  },
  {
    id: 102266840,
    name: 'Жилищник Ломоносовский',
  },
  {
    id: 10231444,
    name: 'Жилищник Лосиноостровский',
  },
  {
    id: 102266640,
    name: 'Жилищник Люблино',
  },
  {
    id: 10231448,
    name: 'Жилищник Марфино',
  },
  {
    id: 102266500,
    name: 'Жилищник Марьина Роща',
  },
  {
    id: 10233574,
    name: 'Жилищник Марьино',
  },
  {
    id: 10234046,
    name: 'Жилищник Метрогородок',
  },
  {
    id: 10231418,
    name: 'Жилищник Мещанский',
  },
  {
    id: 10231508,
    name: 'Жилищник Митино',
  },
  {
    id: 10234263,
    name: 'Жилищник Можайский',
  },
  {
    id: 10233906,
    name: 'Жилищник Молжаниновский',
  },
  {
    id: 102266800,
    name: 'Жилищник Москворечье Сабурово',
  },
  {
    id: 10226722,
    name: 'Жилищник Нагатино-Садовники',
  },
  {
    id: 10234110,
    name: 'Жилищник Нагатинский затон',
  },
  {
    id: 10228800,
    name: 'Жилищник Нагорный',
  },
  {
    id: 10231468,
    name: 'Жилищник Некрасовка',
  },
  {
    id: 10231470,
    name: 'Жилищник Нижегородский',
  },
  {
    id: 10231459,
    name: 'Жилищник Новогиреево',
  },
  {
    id: 102266620,
    name: 'Жилищник Новокосино',
  },
  {
    id: 10234273,
    name: 'Жилищник Ново-Переделкино',
  },
  {
    id: 10234775,
    name: 'Жилищник Обручевский',
  },
  {
    id: 10234467,
    name: 'Жилищник Орехово-Борисово Северное',
  },
  {
    id: 10228535,
    name: 'Жилищник Останкинский',
  },
  {
    id: 10233530,
    name: 'Жилищник Отрадное',
  },
  {
    id: 10231498,
    name: 'Жилищник Очаково-Матвеевское',
  },
  {
    id: 10233819,
    name: 'Жилищник Перово',
  },
  {
    id: 102266660,
    name: 'Жилищник Печатники',
  },
  {
    id: 102267040,
    name: 'Жилищник Покровское-Стрешнево',
  },
  {
    id: 10233570,
    name: 'Жилищник Преображенское',
  },
  {
    id: 10231420,
    name: 'Жилищник Пресненский',
  },
  {
    id: 10231500,
    name: 'Жилищник Проспект Вернадского',
  },
  {
    id: 10234054,
    name: 'Жилищник района Орехово-Борисово Южное',
  },
  {
    id: 102266960,
    name: 'Жилищник Раменки',
  },
  {
    id: 102266520,
    name: 'Жилищник Ростокино',
  },
  {
    id: 10233841,
    name: 'Жилищник Рязанский',
  },
  {
    id: 10233590,
    name: 'Жилищник Савеловский',
  },
  {
    id: 102266540,
    name: 'Жилищник Свиблово',
  },
  {
    id: 10231490,
    name: 'Жилищник Северное Бутово',
  },
  {
    id: 10231455,
    name: 'Жилищник Северное Измайлово',
  },
  {
    id: 10226011,
    name: 'Жилищник Северное Медведково',
  },
  {
    id: 10231504,
    name: 'Жилищник Северное Тушино',
  },
  {
    id: 10231442,
    name: 'Жилищник Северный',
  },
  {
    id: 102266420,
    name: 'Жилищник Сокол',
  },
  {
    id: 10234265,
    name: 'Жилищник Соколиная гора',
  },
  {
    id: 10233566,
    name: 'Жилищник Сокольники',
  },
  {
    id: 10234252,
    name: 'Жилищник Солнцево',
  },
  {
    id: 10228522,
    name: 'Жилищник Строгино',
  },
  {
    id: 10231426,
    name: 'Жилищник Таганский',
  },
  {
    id: 102266340,
    name: 'Жилищник Тверской',
  },
  {
    id: 102266720,
    name: 'Жилищник Текстильщики',
  },
  {
    id: 10234512,
    name: 'Жилищник Теплый Стан',
  },
  {
    id: 10231434,
    name: 'Жилищник Тимирязевский',
  },
  {
    id: 102266940,
    name: 'Жилищник Тропарево-Никулино',
  },
  {
    id: 102266900,
    name: 'Жилищник Филевский парк',
  },
  {
    id: 10233698,
    name: 'Жилищник Фили-Давыдково',
  },
  {
    id: 10231382,
    name: 'Жилищник Хамовники',
  },
  {
    id: 10226763,
    name: 'Жилищник Ховрино',
  },
  {
    id: 10226559,
    name: 'Жилищник Хорошево-Мневники',
  },
  {
    id: 10233603,
    name: 'Жилищник Хорошевский',
  },
  {
    id: 10234479,
    name: 'Жилищник Царицыно',
  },
  {
    id: 10235040,
    name: 'Жилищник Черемушки',
  },
  {
    id: 10231472,
    name: 'Жилищник Чертаново Северное',
  },
  {
    id: 10231474,
    name: 'Жилищник Чертаново Центральное',
  },
  {
    id: 10231478,
    name: 'Жилищник Чертаново Южное',
  },
  {
    id: 102267020,
    name: 'Жилищник Щукино',
  },
  {
    id: 10231492,
    name: 'Жилищник Южное Бутово',
  },
  {
    id: 10233524,
    name: 'Жилищник Южное Медведково',
  },
  {
    id: 10231502,
    name: 'Жилищник Южное Тушино',
  },
  {
    id: 10231463,
    name: 'Жилищник Южнопортовый',
  },
  {
    id: 10231422,
    name: 'Жилищник Якиманка',
  },
  {
    id: 10231446,
    name: 'Жилищник Ярославский',
  },
  {
    id: 102266860,
    name: 'Жилищник Ясенево',
  },
];
