import { Validator } from 'core/form/Validator';
import { ABUTMENT_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/AbutmentTypeName';
import { MATERIAL_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/MaterialId';

/**
 * Функция валидации __Элементы сопряжения поверхностей__.
 *
 * @param {object} values - Значения формы.
 * @returns {object}
 */
export const validateAbutment = (values) => {
  const validate = new Validator(values);

  validate.setRequired(ABUTMENT_TYPE_ID_NAME);
  validate.setRequired(MATERIAL_ID_NAME);

  return validate.getErrors();
};
