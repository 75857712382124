import { VERTICAL_LANDSCAPING_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/VerticalLandscapingTypeId';
import React from 'react';
import { Field } from 'react-final-form';

import { VerticalLandscapingTypeIdWithFF } from './VerticalLandscapingTypeIdWithFF';

/**
 * ## Вид ЭВО Select FF.
 *
 * @param {object} props - Пропсы.
 * @param {string} props.className - Класс.
 * @returns {JSX.Element}
 */
export const VerticalLandscapingTypeIdFF = (props) => {
  return (
    <Field
      {...props}
      name={VERTICAL_LANDSCAPING_TYPE_ID_NAME}
      component={VerticalLandscapingTypeIdWithFF}
    />
  );
};
