import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';

/**
 * Проверка редактирования объекта созданного из внешней системы.
 *
 * @description Если объект не создан из внешней системы.
 * @returns
 */
export const useIsEditCurrentObjectIsExternalSystem = (): boolean => {
  const IsCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();
  const { stateId } = useGetParentCard();

  if (!IsCurrentObjectIsExternalSystem) {
    return true;
  }
  return IsCurrentObjectIsExternalSystem && stateId === 1;
};
