import { FileList } from 'core/uiKit/components/FileList';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * Список файлов ссылкой.
 *
 * @param {object} props - Пропсы.
 * @param {string} props.name - Имя.
 * @param {boolean} [props.disabled] - Флаг редактирования.
 * @returns {React.ReactElement}
 */
export const FileListFF = ({ name, disabled }) => {
  return (
    <Field name={name}>
      {({ input }) => {
        return (
          <FileList
            files={input?.value || []}
            disabled={disabled}
            onChange={input.onChange}
          />
        );
      }}
    </Field>
  );
};
