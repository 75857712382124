import documentsReducer from 'app/components/card/ogh/DocumentsTab/mapStateToPropsReducer';
import { RANDOM_BOOL, RANDOM_ID } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import Functionality, { mapStateToProps } from './functionality';

vi.mock('app/api/hooks/useGetParentCard');
vi.mock('app/components/card/ogh/DocumentsTab/mapStateToPropsReducer');
vi.mock('app/selectors/card/getArrangeElementTypes');
vi.mock('app/selectors/card/getUnitsByArrangeElementType');
vi.mock('app/selectors/card/getQuantityDigits');
vi.mock('app/selectors/card/hasQuantityLock');

vi.mock('redux-form', async (importOriginal) => {
  const mod = await importOriginal();

  return {
    ...mod,
    reduxForm: vi.fn(() => (Component) => Component),
  };
});

vi.mock('react-redux', () => ({
  connect: vi.fn(
    (mapStateToProps, mapDispatchToProps) => (Component) => (props) =>
      <Component {...mapStateToProps} {...mapDispatchToProps} {...props} />,
  ),
}));

describe('🐛 FunctionalityCardContainer', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    // 🔥 Act
    const wrapper = shallow(
      <Functionality mode={{ editMode: true }} card={{ typeId: 1 }} />,
    );

    // ❓ Assert
    expect(wrapper.text()).toBe('<FunctionalityCardContainer />');
  });
});

describe('file functionality', () => {
  it('should initialValues form', () => {
    expect.hasAssertions();

    // Arrange (всякие моки)
    documentsReducer.mockReturnValue((_) => _);

    const card = {
      attribute: {
        is_diff_height_mark: true,
      },
    };

    // Act
    const result = mapStateToProps({}, { card });

    // Assert
    expect(result).toMatchObject({
      initialValues: {
        is_diff_height_mark: true,
      },
    });
  });

  it('should initialValues', () => {
    expect.hasAssertions();

    // Arrange (всякие моки)
    documentsReducer.mockReturnValue((_) => _);

    const card = {
      attribute: {
        is_diff_height_mark: RANDOM_BOOL,
        material_id: {
          material: RANDOM_ID,
        },
      },
    };

    // Act
    const result = mapStateToProps({}, { card });

    // Assert
    expect(result).toMatchObject({
      initialValues: {
        is_diff_height_mark: RANDOM_BOOL,
        material_id: RANDOM_ID,
      },
    });
  });
});
