import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { toDate } from 'app/utils/date/toDate';
import { SaveFFDialog } from 'core/form/finalForm/components/dialogs/SaveFF.Dialog';
import { KeyboardDatePickerFF } from 'core/form/finalForm/fields/default/datePickers/KeyboardDatePickerFF';
import { TextFieldFF } from 'core/form/finalForm/fields/default/TextFieldFF';
import { useFieldRF } from 'core/form/reduxForm/hooks/useField.RF';
import { AddButton } from 'core/uiKit/components/buttons';
import { preparationValues } from 'core/utils/helpers/preparationValues';
import { usePreparation } from 'core/utils/hooks/usePreparation';
import React, { useState } from 'react';

import { REPAIRS_INFO_LIST_NAME } from './Table.Repairs.Tab';

/**
 * Добавление записи в Ремонты.
 *
 * @returns Кнопка.
 */
export const AddButtonRepairsTab = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { editMode } = useMode();
  const { onChange, value: repairsInfoList } = useFieldRF(
    REPAIRS_INFO_LIST_NAME,
  );

  usePreparation('date', toDate);
  usePreparation('guarantee_date', toDate);

  return editMode ? (
    <>
      <AddButton onClick={() => setIsOpen(true)} />
      <SaveFFDialog
        isOpen={isOpen}
        closeDialog={() => setIsOpen(false)}
        textHeader="Добавить сведение о ремонтах"
        Body={
          <>
            <TextFieldFF name="name" label="Наименование" />
            <KeyboardDatePickerFF name="date" label="Дата" />
            <TextFieldFF name="property" label="Характеристика (объемы)" />
            <TextFieldFF
              name="contract_organization"
              label="Организация, проводившая работы"
            />
            <KeyboardDatePickerFF
              name="guarantee_date"
              label="Гарантийные сроки"
            />
          </>
        }
        initialValues={{}}
        onSubmit={(value) => {
          // @ts-ignore
          onChange([...(repairsInfoList || []), preparationValues(value)]);
          setIsOpen(false);
        }}
      />
    </>
  ) : null;
};
