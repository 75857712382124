import { ShowByPermissions } from 'app/routes/ShowByPermissions';
import { SaveFFDialog } from 'core/form/finalForm/components';
import { IdRfidDialogBody } from 'core/uiKit/components/cells/IdRfid.Cell/IdRfid.Dialog.Body';
import { useIdRfidCell } from 'core/uiKit/components/cells/IdRfid.Cell/useIdRfid.Cell';
import { Edit, IconButton, Tooltip } from 'core/uiKit/material-ui';

/**
 * Компонент IdRfidCell.
 *
 * @param {object} props - Пропсы.
 * @param {*} props.row - Ряды.
 * @param {*} props.cell - Any.
 * @param {*} props.cell.value - Значение.
 * @returns {JSX.Element}
 */
export const IdRfidCell = ({ row, cell: { value = ' ' } = {} }) => {
  const { setIsOpen, isOpen, isLoadingSave, sendSave } = useIdRfidCell({
    rootId: row?.original?.root_id,
  });
  return (
    <div>
      <ShowByPermissions privilege={'edit_ogh_maf_rfid'}>
        <Tooltip title="Редактировать">
          <IconButton size="small" onClick={() => setIsOpen(true)}>
            <Edit fontSize="inherit" />
          </IconButton>
        </Tooltip>
        <SaveFFDialog
          isOpen={isOpen}
          isLoading={isLoadingSave}
          onSubmit={sendSave}
          textHeader={'Укажите ID УАИС БУ'}
          Body={<IdRfidDialogBody />}
          closeDialog={() => setIsOpen(false)}
        />
      </ShowByPermissions>
      <span>{value}</span>
    </div>
  );
};
