import { get } from 'app/api/crud/get';
import { useGetParamsUrl } from 'core/utils/hooks/useGetParamsUrl';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';

/**
 * Хук запроса карточки Методические материалы.
 *
 * @returns Карточка Методические материалы.
 */
export const useQueryKnowledgeBase = () => {
  const { knowledgeBaseCode } = useGetParamsUrl();

  return useQueryAdaptor(
    ['knowledge_base', knowledgeBaseCode],
    (): Promise<{
      id: number;
      code: string;
      name: string;
      description: string;
      attribute: {
        file_list?: {
          id: string;
          file_name?: string;
        }[];
      };
    }> => get('knowledge_base/code/' + knowledgeBaseCode),
  );
};
