// Файлы основание добавляются в другом месте. Здесь только файлы отображающиеся на подвкладке "Файлы" карточки машин
/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import TextField from 'core/newComponents/TextField';
import DialogAction from '../../common/DialogAction';

import { REQUIRED_FIELD_MESSAGE } from 'app/constants/messages';

const propTypes = {
  showCondition: PropTypes.bool,
  title: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

const defaultProps = {
  title: 'Добавление файла',
};

class DialogAddCalibrationRecord extends React.Component {
  state = {};

  handleCancel = () => {
    const { onCancel } = this.props;
    onCancel();
  };

  handleOk = () => {
    const { onSubmit } = this.props;
    const { dut_value, liter_value } = this.state;

    if (dut_value && liter_value) {
      this.setState({ dut_value: null, liter_value: null });
      onSubmit({ dut_value, liter_value });
    } else {
      if (!dut_value) {
        this.setState({ dut_value_error: REQUIRED_FIELD_MESSAGE });
      }
      if (!liter_value) {
        this.setState({ liter_value_error: REQUIRED_FIELD_MESSAGE });
      }
    }
  };

  render() {
    const { showCondition, title } = this.props;

    return (
      <DialogAction
        className="Dialog-Add-Calibration-Record"
        showCondition={showCondition}
        title={title}
        onCancel={this.handleCancel}
        onOk={this.handleOk}
      >
        <TextField
          fullWidth={true}
          helperText={this.state.dut_value_error}
          label="Показания датчика"
          min={0} // TODO: TextField escape from min
          type="number"
          value={this.state.dut_value}
          onChange={(e) => {
            this.setState({ dut_value: parseInt(e.target.value, 10) });
          }}
        />
        <TextField
          fullWidth={true}
          helperText={this.state.liter_value_error}
          label="Уровень топлива, л"
          min={0}
          type="number"
          value={this.state.liter_value}
          onChange={(e) => {
            this.setState({
              liter_value: parseInt(e.target.value, 10),
            });
          }}
        />
      </DialogAction>
    );
  }
}

DialogAddCalibrationRecord.propTypes = propTypes;
DialogAddCalibrationRecord.defaultProps = defaultProps;

 
export default DialogAddCalibrationRecord;
