 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Этажность__.
 *
 * @type {{  accessor: 'floor_qty',  Header: 'Этажность'}}
 * @augments Cell
 */
export const floor_qty = {
  accessor: 'floor_qty',
  Header: 'Этажность',
  sortable: true,
};
