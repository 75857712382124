import { LinearProgress, SkeletonMUI } from 'core/uiKit/material-ui';
import React, { useRef } from 'react';

/**
 * Прелоудер Дерева.
 *
 * @param {object} props - Пропсы.
 * @param {boolean} props.loading - Флаг ожидания.
 * @param {React.ReactNode} props.children - Дети.
 * @returns {JSX.Element}
 */
export const SkeletonTreeRightPanel = ({ loading, children }) => {
  const wrapper = useRef(children);

  const height = wrapper.current?.scrollHeight || '100%';

  return (
    <div ref={wrapper}>
      {loading ? (
        <>
          <LinearProgress color={'inherit'} />
          <SkeletonMUI
            sx={{
              backgroundColor: 'rgba(30,30,44,0.78)',
              position: 'absolute',
              top: 0,
              transform: 'none',
              zIndex: 10,
            }}
            width={'100%'}
            height={height}
          />
        </>
      ) : null}
      {children}
    </div>
  );
};
