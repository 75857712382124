import { REQUIRED_FIELD_MESSAGE } from 'app/constants/messages';
import { OZN_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/OznTypeID';

/**
 * Валидация поля OznTypeField.
 *
 * @param {object} formValues - Значения формы.
 * @returns {{OZN_TYPE_ID_NAME: string}} - Возвращает объект с текстом ошибки или null.
 * @example
 * function asyncValidate(formValues) {
 *   return {
 *     ...oznTypeAsyncValidate(formValues),
 *   };
 * }
 */
export function oznTypeAsyncValidate(formValues) {
  const name = formValues[OZN_TYPE_ID_NAME];
  let result;
  if (name.length === 0) {
    result = {
      [OZN_TYPE_ID_NAME]: REQUIRED_FIELD_MESSAGE,
    };
  } else {
    result = null;
  }
  return result;
}
