import { put } from 'app/api/crud/put';
/* eslint-disable */

// convertIntoSnakeCase: false нужен потому что присутствует e-mail

/**
 *
 * @param data
 */
export const updateUser = (data) =>
  put('/users/update', data, { convertIntoSnakeCase: false });
