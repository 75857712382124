import {
  MAPTIP_FULL,
  MAPTIP_SHORT,
} from 'app/services/mapService/constants/popUpTypes';
import { ol } from 'app/utils/helpers/ol';

import PopUp from './PopUp';
/* eslint-disable */
const defaultSelectStyle = new ol.style.Style({
  image: new ol.style.Circle({
    fill: new ol.style.Fill({
      color: '#3399CC',
    }),
    radius: 5,
  }),
  fill: new ol.style.Fill({
    color: 'rgba(255,255,255,0.4)',
  }),
  stroke: new ol.style.Stroke({
    color: '#3399CC',
    width: 2,
  }),
});

/* eslint-disable */
export default class Select {
  constructor(map, layer, customLayers) {
    this.popUp = new PopUp(map);

    this.SelectSimple = createSelectSimpleInteraction(map, layer);
    this.SelectClick = createSelectClickInteraction(
      map,
      customLayers,
      this.popUp,
    );
    this.SelectPointerMove = createSelectPointerMoveInteraction(
      map,
      customLayers,
      this.popUp,
    );
  }

  turnOn(editable) {
    if (editable) {
      this.SelectSimple.setActive(true);
    } else {
      this.SelectClick.setActive(true);
      this.SelectPointerMove.setActive(true);
    }
  }

  turnOff() {
    this.SelectSimple.getFeatures().clear();
    this.SelectSimple.setActive(false);

    this.popUp.hide(MAPTIP_FULL);
    this.SelectClick.getFeatures().clear();
    this.SelectClick.setActive(false);

    this.popUp.hide(MAPTIP_SHORT);
    this.SelectPointerMove.getFeatures().clear();
    this.SelectPointerMove.setActive(false);
  }

  deselect(features) {
    features.forEach((feature) => {
      this.SelectSimple.getFeatures().remove(feature);

      this.SelectClick.getFeatures().remove(feature) &&
        this.popUp.hide(MAPTIP_FULL);
      this.SelectPointerMove.getFeatures().remove(feature) &&
        this.popUp.hide(MAPTIP_SHORT);
    });
  }

  getSelectedFeatures() {
    return this.SelectSimple.getFeatures();
  }

  getSelectedFeature() {
    return this.SelectSimple.getFeatures().item(0);
  }

  clearSelection() {
    this.SelectSimple.getFeatures().clear();
  }

  onSelect(handler) {
    this.SelectSimple.on('select', handler);
  }
}

const createSelectSimpleInteraction = (map, layer) => {
  const select = new ol.interaction.Select({
    condition: ol.events.condition.singleClick,
    layers: [layer],
    style: (feature) => {
      const layer = select.getLayer(feature);
      const layerStyle = layer.getStyleFunction().call(null, feature);
      return [...layerStyle, defaultSelectStyle];
    },
  });

  select.setActive(false);
  select.getOverlay().setZIndex(0);

  map.addInteraction(select);

  return select;
};

const createSelectClickInteraction = (map, layers, popUp) => {
  const select = new ol.interaction.Select({
    condition: ol.events.condition.singleClick,
    toggleCondition: handleClick(map, popUp, () =>
      select.getFeatures().clear(),
    ),
    layers,
    style: (feature) => {
      const layer = select.getLayer(feature);
      const layerStyle = layer.getStyleFunction().call(null, feature);
      return [...layerStyle, defaultSelectStyle];
    },
  });

  select.setActive(false);
  select.getOverlay().setZIndex(0);

  map.addInteraction(select);

  return select;
};

const createSelectPointerMoveInteraction = (map, layers, popUp) => {
  const select = new ol.interaction.Select({
    condition: ol.events.condition.pointerMove,
    toggleCondition: handlePointerMove(map, popUp),
    layers,
    style: (feature) => {
      const layer = select.getLayer(feature);
      const layerStyle = layer.getStyleFunction().call(null, feature);
      return [...layerStyle, defaultSelectStyle];
    },
  });

  select.setActive(false);
  select.getOverlay().setZIndex(0);

  map.addInteraction(select);

  return select;
};

const handleClick = (map, popUp, handleClose) => (event) => {
  const coordinate = event.coordinate;
  const features = map.getFeaturesAtPixel(event.pixel);
  if (features) {
    const selectedFeature = features[0];
    const hint = selectedFeature.get('hint').object_attribute_hint;
    const title = hint.find((item) => item.key === 'title');
    const content = hint.filter((item) => item.key !== 'title');

    const data = {
      title: title ? title.value : '',
      html: getHtmlHint(content),
    };

    popUp.hide(MAPTIP_SHORT);
    popUp.show(MAPTIP_FULL, data, coordinate, handleClose);
  } else {
    popUp.hide(MAPTIP_FULL);
  }
};

const handlePointerMove = (map, popUp) => (event) => {
  const coordinate = event.coordinate;
  const features = map.getFeaturesAtPixel(event.pixel);
  if (features && !popUp.isActive(MAPTIP_FULL)) {
    const selectedFeature = features[0];
    const hint = selectedFeature.get('hint').object_attribute_hint_short;
    const name = hint.find((item) => item.key === 'name');

    const data = {
      html: name ? name.value : '',
    };

    popUp.show(MAPTIP_SHORT, data, coordinate);
  } else {
    popUp.hide(MAPTIP_SHORT);
  }
};

const getHtmlHint = (content) =>
  content.map((item) => `<div>${item.value}</div>`).join('');
