import validate from 'app/components/card/ogh/ImprovementTerritoryCard/components/SnowAreaTab/asyncValidate';

describe('AsyncValidateJestSpec', function () {
  it('поле не заполнено', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act

    // Assert
    expect(validate({})).toStrictEqual({
      snow_clean_area: 'обязательное поле',
    });
  });
  it('поле содержит 0', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act

    // Assert
    expect(validate({ snow_clean_area: 0 })).toStrictEqual(null);
  });
  it('поле содержит 2', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act

    // Assert
    expect(validate({ snow_clean_area: 2 })).toStrictEqual(null);
  });
});
