import {
  COATING_TYPE_ID_NAME,
  CoatingTypeId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingTypeId';
import React from 'react';
import { Field } from 'redux-form';

/**
 * Обертка для RF inputa.
 *
 * @param {object} props - Properties.
 * @param {object} props.input - Input.
 * @param {object} props.otherProps - Other properties.
 * @returns {JSX.Element}
 */
const CoatingTypeIdRFWithReduxForm = ({ input, ...otherProps }) => {
  return <CoatingTypeId {...input} {...otherProps} />;
};

/**
 * RF input - Вид покрытия на путях (уточнение).
 *
 * @param {object} props - Properties.
 * @param {boolean} [props.withAll] - Показывать ли все.
 * @param {string} [props.label] - Лейбл инпута.
 * @param {Function} [props.filter] - Функция фильтра.
 * @param {object} [props.otherProps] - Other properties.
 * @returns {JSX.Element}
 */
export const CoatingTypeIdRF = ({ ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={COATING_TYPE_ID_NAME}
      component={CoatingTypeIdRFWithReduxForm}
    />
  );
};
