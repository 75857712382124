import Box from '@mui/material/Box';
import { log } from 'core/utils/log';
import {
  forwardRef,
  MouseEventHandler,
  useImperativeHandle,
  useRef,
} from 'react';
import React from 'react';

import { RawGeometry } from '../../contexts/utils/types';
import { crossImg } from './utils';

export const HintOnClick = forwardRef((_props, ref) => {
  const $container = useRef<HTMLDivElement | null>(null);
  const $content = useRef<HTMLDivElement | null>(null);
  const hintSizeRef = useRef<[number, number]>([0, 0]);
  const contentRef = useRef<string>('');
  const onCloseHandlerRef = useRef<MouseEventHandler | null>(null);

  const prevClickedObjectRef = useRef<RawGeometry | null>(null);
  const prevXYRef = useRef([0, 0]);

  /**
   * Close hint.
   */
  const closeHint = () => {
    if ($content.current) $content.current.innerHTML = '';
    if ($container.current) $container.current.style.display = 'none';
  };

  /**
   * Close hint on click.
   *
   * @param evt - Click event.
   */
  const handleCloseHintClick: MouseEventHandler = (evt) => {
    closeHint();

    if (!onCloseHandlerRef.current) {
      log.warn('HintOnClick: onCloseHandlerRef.current is undefined');
      return;
    }
    onCloseHandlerRef.current(evt);
  };

  useImperativeHandle(
    ref,
    () => ({
      closeHint,
      element: $container.current,

      /**
       * Set position of the hint.
       *
       * @param cb - Callback.
       */
      setOnCloseClickHandler(cb: MouseEventHandler) {
        onCloseHandlerRef.current = cb;
      },

      /**
       * Set position of the hint.
       *
       * @param clickedObject - Clicked object.
       * @param x - X coordinate.
       * @param y - Y coordinate.
       */
      // eslint-disable-next-line
      setPosition(clickedObject: RawGeometry | null, x: number, y: number) {
        let hintContent = '';

        if (clickedObject && prevClickedObjectRef.current !== clickedObject) {
          if ($container.current) $container.current.style.display = 'block';

          clickedObject.userData.hint?.object_attribute_hint?.forEach(
            ({ key, value }: { key: string; value: string }) => {
              hintContent += `<p style="padding: 0; margin: 0;">${value}</p>`;
              if (key === 'title')
                hintContent +=
                  '<br style="background-color: white; display: block; margin: 6px 0 5px 0; height: 1px; content:\'\'; width: 100%;">';
            },
          );
          if ($container.current) $container.current.style.width = '';
          const newContent = `<div>${hintContent}</div>`;
          contentRef.current = newContent;

          if ($content.current) $content.current.innerHTML = newContent;

          prevClickedObjectRef.current = clickedObject;

          const { height, width } =
            $container.current?.getBoundingClientRect() || {
              height: 0,
              width: 0,
            };
          hintSizeRef.current = [height, width];

          if ($container.current) $container.current.style.width = `${width}px`;
        }

        // eslint-disable-next-line
        prevXYRef.current = [x, y];

        if ($container.current) {
          const [height, width] = hintSizeRef.current;
          // eslint-disable-next-line
          $container.current.style.top = `${y - (height + 12)}px`;
          // eslint-disable-next-line
          $container.current.style.left = `${x - width / 2}px`;
        }

        if (!clickedObject && prevClickedObjectRef.current) {
          if ($content.current) $content.current.innerHTML = '';
          if ($container.current) $container.current.style.display = 'none';
        }

        prevClickedObjectRef.current = clickedObject;
      },
    }),
    [],
  );

  return (
    <Box
      ref={$container}
      display="none"
      position="absolute"
      id="hover-hint"
      zIndex={1000000}
      padding={2}
      borderRadius={2}
      maxWidth="600px"
      bgcolor="#1c1c1ce8"
      sx={{
        '&:after': {
          borderLeft: '10px solid transparent',
          borderRight: '10px solid transparent',
          borderTop: '10px solid #1c1c1ce8',
          bottom: '0',
          content: '""',
          height: 0,
          left: '50%',
          pointerEvents: 'none',
          position: 'absolute',
          transform: 'translate(-50%, 100%)',
          width: 0,
        },
      }}
    >
      <Box
        component="button"
        padding={0}
        margin={0}
        marginLeft="auto"
        display="block"
        border="none"
        onClick={handleCloseHintClick}
        width="14px"
        height="14px"
        sx={{ background: `url(${crossImg})` }}
      />
      <Box color="white" padding={0} ref={$content} />
    </Box>
  );
});

export default HintOnClick;
