import { DatePicker } from 'core/uiKit/inputs/datePickers/DatePicker';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * Выбор даты.
 * ### React Final Form Field.
 *
 * @param {*} props - Props.
 * @param {string} props.name - Name of the field.
 * @param {*} props.otherProps - Other properties.
 * @returns {JSX.Element}
 */
export const DatePickerFF = ({ name, ...otherProps }) => {
  return (
    <Field name={name}>
      {(props) => {
        const { meta } = props;
        return (
          <DatePicker
            error={meta.error || meta.submitError}
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};
