import { Table } from 'core/uiKit/components/tables/Table';

import { columnsMafRfid } from './columns.MafRfid';
import { useSetupTableMafRfid } from './useSetup.Table.MafRfid';

/**
 * Реестр ОГХ > Малые архитектурные формы.
 *
 * @param {object} props - Параметры.
 * @param {number} props.typeId - Идентификатор типа.
 * @returns {JSX.Element}
 */
export const TableMafRfid = ({ typeId }) => {
  const { count, table, isLoading } = useSetupTableMafRfid({ typeId });

  return (
    <Table
      columns={columnsMafRfid}
      data={table || []}
      isLoading={isLoading}
      total={count}
    />
  );
};
