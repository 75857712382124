import './BadgeBell.scss';

import { Badge } from 'core/uiKit/material-ui';

import { DrawerContainer } from './DrawerContainer';
import Icon from './Icon';
import { useMain } from './useMain';

/**
 * Компонент иконки уведомления.
 *
 * @returns {JSX.Element|null}
 * @class
 */
export const BadgeBell = () => {
  let { count, openDrawer } = useMain();

  return count ? (
    <div className={'badge-bell__container'}>
      <Badge
        badgeContent={count}
        color="primary"
        onClick={() => openDrawer(true)}
      >
        <Icon />
      </Badge>
      <DrawerContainer />
    </div>
  ) : null;
};
