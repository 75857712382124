 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Тип объекта__.
 *
 * @type {{ accessor: 'ozn_type_name', Header: 'Тип объекта' , sortable: true,}}
 * @augments Cell
 */
export const ozn_type_name = {
  accessor: 'ozn_type_name',
  Header: 'Тип объекта',
  sortable: true,
};
