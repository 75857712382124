import { uploadFile } from 'app/api/requests/uploadFiles';

/**
 * Функция загрузки файлов на сервер в ответ id и имя в массиве.
 *
 * @param {Array<File>} files - Массив файлов.
 * @param {object} additional - Дополнительные параметры.
 * @returns {Promise <Array <{name: string, storageId: number} >>} Имя и id в сторадже.
 */
export const uploadFileReturnObj = (files = [], additional = null) => {
  return Promise.all(
    files.map(async (file) => {
      const res = await uploadFile(file, additional);
      const { fileId: storageId } = res || {};

      return {
        name: file.name,
        storageId,
      };
    }),
  );
};
