import { PlacesArea } from 'core/uiKit/preparedInputs/textField/PlacesArea/index';
import React from 'react';

/**
 * Компонент обертка PlacesQuantity для Final Form.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.input - Пропсы поля.
 * @param {object} props.otherProps - Остальные пропсы.
 * @returns {JSX.Element}
 */
export const PlacesAreaWithFF = ({ input, ...otherProps }) => {
  return <PlacesArea {...input} {...otherProps} />;
};
