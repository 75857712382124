 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Балансодержатель__.
 *
 * @type {{ accessor: 'owner_short_name', Header: 'Балансодержатель'}}
 * @augments Cell
 */
export const owner_short_name = {
  accessor: 'owner_short_name',
  Header: 'Балансодержатель',
  sortable: true,
};
