import { MafTypeLevel3FF } from 'core/form/finalForm/fields/prepared/selects/simple/MafTypeLevel3.FF';
import { MAF_TYPE_LEVEL_3_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/MafTypeLevel3';
import { NumberParam } from 'use-query-params';

import { filterInput } from '../../filterInputsClasses';

/**
 * @typedef {import('use-query-params').NumberParam} NumberParam
 */

/**
 * Детализация.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - JSX.
 * @example --------
 */
export const MafTypeLevel3Filter = (props) => (
  <MafTypeLevel3FF className={filterInput} withAll={false} {...props} />
);

/**
 * QueryParam Детализация.
 *
 * @type {{"[MAF_TYPE_LEVEL_3_ID_NAME]": NumberParam}}
 * @example -----
 *  const [filters, setFilters] = useQueryParams([
 *     mafTypeLevel3Params,
 *   ]);
 */
export const mafTypeLevel3Params = {
  [MAF_TYPE_LEVEL_3_ID_NAME]: NumberParam,
};
