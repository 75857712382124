import React from 'react';

/**
 * Функция получения массива табов из дочерних TabsCardOGHItem.
 *
 * @param children - Дети, обязательно должен быть передана дочка с названием TabsCardOGHItem, иначе таб не отобразится.
 * @returns
 */
export const getTabsFromChildren = (children: React.ReactElement): string[] => {
  if (Array.isArray(children)) {
    return children.reduce((arr, child) => {
      if (child.props?.tabTitle && !child?.props.hide) {
        arr.push(child.props.tabTitle);
      }
      return arr;
    }, []);
  }
  const titleOneTub = children.props.tabTitle || '';
  return [titleOneTub];
};
