import { ODS_API_URL } from 'config/env';
import React from 'react';

/**
 * Cell отображение и скачивание несколько файлов.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.value - Значение.
 * @returns {JSX.Element}
 */
export const DownLoadFilesListCell = ({ value }) => {
  return (
    <>
      {(value || []).map((item) => {
        return (
          <a
            href={`${ODS_API_URL}/files/download/${item.file_id}`}
            style={{ display: 'block' }}
            download
          >
            {item.file_name}
          </a>
        );
      })}
    </>
  );
};
