import React from 'react';

import { LandscapingTable } from './Landscaping.Table/Landscaping.Table';

/**
 * Таб __Благоустройство__.
 *
 * @returns {JSX.Element}
 */
export const LandscapingTab = () => {
  return (
    <div hidden={true} id={'ods-landscaping'}>
      <LandscapingTable />
    </div>
  );
};
