/* eslint-disable */

import { createSelector } from 'reselect';
const getImprovementOptions = (state, props) => props.card.improvement_category;

/**
 * A.
 *
 * @param {*} params - Params.
 * @returns {*}
 */
export default function (params) {
  const { defaultOption } = params;

  return createSelector([getImprovementOptions], (options) => {
    if (defaultOption && !options.find((i) => i.id < 0)) {
      options.push(defaultOption);
    }
    return options;
  });
}
