import { useGetVisibilityButtonsOgh } from 'app/api/hooks/useGetVisibilityButtonsOgh';
import { Button } from 'core/uiKit/components/buttons/Button';
import { Icon } from 'core/uiKit/material-ui';
import PropTypes from 'prop-types';

/**
 * Кнопка "На утверждение" Огх.
 *
 * Позже будет удалена из карточки ОГХ, т.к.
 * Не будет согласования по шаблону, будет сразу отображена "Утвердить".
 *
 * @param {object} props - Пропсы.
 * @param {number} props.objectId - Id объекта.
 * @returns {JSX.Element}
 */
export const ButtonSendMatchingOgh = ({ objectId, ...props }) => {
  const { data: { send_matching: sendMatching = false } = {} } =
    useGetVisibilityButtonsOgh(objectId);

  return sendMatching ? (
    <Button
      {...props}
      color={'success'}
      variant={'contained'}
      startIcon={<Icon>forward_to_inbox</Icon>}
    >
      на утверждение
    </Button>
  ) : null;
};

ButtonSendMatchingOgh.propTypes = {

  /**
   * Id версии ОГХ.
   */
  objectId: PropTypes.number.isRequired,
};
