import {
  OKRUG_AL_NAME,
  OkrugAl,
} from 'core/uiKit/preparedInputs/selects/autocompleteAsync/OkrugAl';
import React, { FC, useCallback } from 'react';

import { AddressFieldProps } from '../../types';

/**
 * Округ для renderAddressList2.
 *
 * @param props - Properties.
 * @param props.address - Значение формы.
 * @param props.setAddress - Функция изменения глобальной формы.
 * @param props.errors - Объект ошибок.
 * @returns {JSX}
 */
export const OkrugAlRAL2: FC<AddressFieldProps> = ({
  address,
  setAddress,
  errors,
}) => {
  const onChangeOkrugAl = useCallback(
    (value: { id: number; name: string } | undefined) => {
      setAddress({ [OKRUG_AL_NAME]: value });
    },

    // eslint-disable-next-line
    [address[OKRUG_AL_NAME]],
  );

  return (
    <OkrugAl
      required={true}
      errorText={errors[OKRUG_AL_NAME]}
      value={address[OKRUG_AL_NAME] || undefined}
      onChange={onChangeOkrugAl}
    />
  );
};
