import NavItem from 'app/components/common/NavBar/NavItem';
import { useUser } from 'app/pages/providers';
import { pathMatchingBoundaries } from 'app/pages/registry/MatchingBoundaries/path.MatchingBoundaries';
/* eslint-disable */
import React, { lazy, useEffect } from 'react';

function lazyWithPreload(factory) {
  const Component = lazy(factory);
  Component.preload = factory;
  return Component;
}

const MatchingBoundaries = lazyWithPreload(() =>
  import('app/pages/registry/MatchingBoundaries'),
);

 
export const MatchingBoundariesLink = () => {
  const { hasPermission } = useUser();

  const isShow = hasPermission(pathMatchingBoundaries.privilege);

  useEffect(() => {
    isShow && MatchingBoundaries.preload();
  }, [isShow]);

  return isShow ? (
    <NavItem link={`/r/${pathMatchingBoundaries.path}`}>
      {pathMatchingBoundaries.name}
    </NavItem>
  ) : null;
};

// const MatchingBoundariesLink = () => {
//   const { hasPermission } = useUser();
//
//   return hasPermission('registry_matching_boundary_ogh') ? (
//     <NavItem link={`/r/${pathMatchingBoundaries.path}`}>
//       {pathMatchingBoundaries.name}
//     </NavItem>
//   ) : null;
// };
