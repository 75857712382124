/* eslint-disable */
import React from 'react';
import { withDefault } from 'use-query-params';

import {
  StageResolutionFF,
  STAGE_RESOLUTION_NAME,
} from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';
import { stringWithAllParam } from 'core/utils/hooks';

 
 
/**
 * Границы
 */
 
export const StageResolutionFilter = (props) => (
  <StageResolutionFF {...props} className={filterInput} />
);

 
export { STAGE_RESOLUTION_NAME };

 
export const stageResolutionParams = {
  [STAGE_RESOLUTION_NAME]: withDefault(stringWithAllParam, 'on_matching'),
};
