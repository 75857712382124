import { usePreparation } from 'core/utils/hooks/usePreparation/preparation';

import { UNIT_ID_NAME } from './UnitId';

/**
 * Хук перевода значений при сохранении для __Вид ЭВО__.
 *
 * @returns {void}
 */
export const usePreparationUnitId = () => {
  usePreparation(UNIT_ID_NAME, (value) => {
    if (value) {
      return { unit: value };
    }
  });
};
