/**
 * Функция сборки данных для селекта CoatingFaceTypeId.
 *
 * @param {Array} dict - List.
 * @returns {Array<{ogh_object_type_list: Array<({ogh_object_type_id: *, other_improvement_object_type_list: Array<(null|number|*)>}|*)>, group_id: *, class_id: *, name: *, id: *}>}
 */
export function mapCoatingFaceTypeId(dict = []) {
  return dict.map((item) => {
    const {
      id,
      attribute: { code, name, ogh_object_type_list, allowed_object_type_list },
    } = item;
    return {
      allowed_object_type_list,
      code,
      id,
      name,
      ogh_object_type_ids:
        ogh_object_type_list
          ?.map(({ ogh_object_type_id }) => ogh_object_type_id?.ogh_object_type)
          .filter(Boolean) || [],
    };
  });
}
