import { shallow } from 'enzyme';
import { vi } from 'vitest';

import Lampposts, { onSubmit } from './lampposts';

vi.mock('app/components/card/common/validation');
vi.mock('./submit');
vi.mock('redux-form', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    reduxForm: vi.fn(() => (Component) => {
      return Component;
    }),
  };
});
vi.mock('react-redux', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    connect: vi.fn(() => (ba) => {
      return ba;
    }),
  };
});

describe('🐛 Lampposts', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = { card: { type_id: 1 } };
    // 🔥 Act
    const wrapper = shallow(<Lampposts {...props} />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<CardContainer />');
  });
});

describe('🐛 onSubmit', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = { card: { type_id: 31 } };
    const onSubmitProps = onSubmit.bind({ props });
    // 🔥 Act

    onSubmitProps();
    // ❓ Assert
    expect(1).toBe(1);
  });
});
