// 1 Объект дорожного хозяйства
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { validateNumeric } from 'app/components/card/common/validation';
import { mapStateToPropsReducer as documentsReducer } from 'app/components/card/ogh/DocumentsTab';
import { initEngineeringTab } from 'app/components/card/ogh/Odh/components/tabs/EngineeringStructures.Tab/initEngineeringTab';
import { OdhCard } from 'app/components/card/ogh/Odh/OdhCard';
import { numericFields } from 'app/components/card/ogh/Odh/odhFields';
import onSubmit from 'app/components/card/ogh/Odh/submit';
import {
  mapDispatchToPropsReducer as ordersMapDispatchToPropsReducer,
  mapStateToPropsReducer as ordersMapStateToPropsReducer,
} from 'app/components/card/ogh/OrdersTab';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import getFormValues from 'app/selectors/form/getFormValues';
import getDict from 'app/selectors/getDict';
import { CREATE_TYPE_NAME } from 'core/form/reduxForm/fields';
import { IS_AVD_NAME } from 'core/form/reduxForm/fields/prepared/checkboxes/IsAvd.RF';
import { INTENSITY_NUM_NAME } from 'core/uiKit/preparedInputs/numberField/IntensityNum';
import { CATEGORY_SP_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CategorySpId';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { REPAIRS_INFO_LIST_NAME } from './ImprovementTerritoryCard/components/tabs/Repairs.Tab/Table.Repairs.Tab';
import { REPAIRS_INFO_LIST_PLAN_NAME } from './Odh/components/tabs/RepairsPlan.Odh.Tab';

const getGrbs = getDict('grbs');

/**
 * Функция валидации формы.
 *
 * @param {object} values - Значения формы.
 * @returns {object} - Жопа.
 * @example
 * const mapStateToProps = (state, props) => {
 *   return {
 *     enableReinitialize: true,
 *     initialValues: {
 *       startDate: parseDate(props.card.start_date),
 *       endDate: parseDate(props.card.end_date),
 *     onSubmit: onSubmit.bind({ props }),
 *     validate,
 *   };
 * };
 */
function validate(values) {
  return {
    ...validateNumeric(values, numericFields),
    // ...validateOdh(values),
    // ...validateLayoutLength(values),
  };
}

/**
 * Функция mapStateToProps.
 *
 * @param {object} result - Result component.
 * @param {object} state - State redux.
 * @param {object} props - Props component.
 * @returns {object} - Жопа.
 * @example
 * export default connect(
 *   mapStateToProps,
 *   mapDispatchToProps,
 * )(reduxForm({ form: 'editorCard' })(TreesAndShrubsCard));
 */
function mapStateToPropsReducer(result, state, props) {
  const { card } = props;
  const parse = getParseAttribute(props);
  const editMode = isEditMode(state, props);
  const currentValues = getFormValues(state);
  const calcAttributes = state.calcAttributes;

  return {
    calcAttributes,
    createMode: isCreateMode(state, props),
    currentValues,
    departments: getGrbs(state),
    editMode,
    editable: editMode,
    enableReinitialize: true,

    initialValues: {
      ...calcAttributes,
      ...initEngineeringTab(parse),
      [CATEGORY_SP_ID_NAME]: parse('category_sp_id.category_sp'),
      [CREATE_TYPE_NAME]: parse('create_type'),
      [INTENSITY_NUM_NAME]: parse(INTENSITY_NUM_NAME),
      [IS_AVD_NAME]: parse(IS_AVD_NAME),
      [REPAIRS_INFO_LIST_NAME]: card?.[REPAIRS_INFO_LIST_NAME] || [],
      [REPAIRS_INFO_LIST_PLAN_NAME]: (card.repairs_info_list_plan || []).map(
        (item) => ({
          ...item,
          file_list: (item.file_list || []).map(({ files }) => files),
        }),
      ),
      actual_schema_date: parseDate(card.attribute.actual_schema_date),
      axis_length: parse('axis_length'),
      clean_category_id: parse('clean_category_id.clean_category'),
      clean_subcategory_id: parse('clean_subcategory_id.clean_subcategory'),
      customer_id: {
        id: parse('customer_id.legal_person'),
        name: card.customer_name,
      },
      date_survey: parseDate(card.attribute.date_survey),
      description: parse('description'),
      distance: parse('distance'),
      endDate: parseDate(card.end_date),
      grbs_id: parse('grbs_id.legal_person'),
      // declare_length: parse('declare_length'),
      // passport_num: parse('passport_num'),
      is_diff_height_mark: parse('is_diff_height_mark') || false,
      is_orphan_object: parse('is_orphan_object'),
      layout_length: parse('layout_length'),
      name: parse('name'),
      owner_id: {
        id: parse('owner_id.legal_person'),
        name: card.owner_name,
      },
      pass_dev_initiator: parse('pass_dev_initiator'),
      passport_date: parseDate(card?.attribute?.passport_date),
      passport_draft_org: parse('passport_draft_org'),
      reagent_area: parse('reagent_area') || '0',
      rotor_area: parse('rotor_area') || '0',
      snow_area: parse('snow_area') || '0',
      startDate: parseDate(card.start_date),
      totalAreaGeo: parse('total_area_geo') || 0,
      trafficCharacterList: parse('traffic_character_list'),
    },

    onSubmit: onSubmit.bind(state)({
      props: {
        ...props,
        ...currentValues,
      },
    }),
    required: isRequired(state, props),
    typeId: card.type_id,

    validate,
  };
}

const odhDocumentsReducer = documentsReducer({
  oghGroupCode: 'odh',
});

/**
 * Функция mapStateToProps.
 *
 * @param {object} state - State redux.
 * @param {object} props - Props component.
 * @returns {object}
 */
function reducedMapStateToProps(state, props) {
  return [
    mapStateToPropsReducer,
    ordersMapStateToPropsReducer,
    odhDocumentsReducer,
  ].reduce((result, reducer) => reducer(result, state, props), {});
}

const reducedMapDispatchToProps = [ordersMapDispatchToPropsReducer].reduce(
  (result, reducer) => reducer(result),
  {},
);

/**
 * Обертка компонента для добавления хуков.
 *
 * @param {object} props - Properties.
 * @returns {JSX.Element}
 */
const OdhCardUse = (props) => {
  const [propertyTab, setPropertyTab] = useState('characteristics');

  /**
   * Функция для выбора активной табы В табе Свойствах.
   *
   * @param {string} newValue - Id табы.
   * @returns {void}
   */
  const onPropertyTab = (newValue) => {
    setPropertyTab(newValue);
  };
  return <OdhCard {...props} {...{ onPropertyTab, propertyTab }} />;
};

export default connect(
  reducedMapStateToProps,
  reducedMapDispatchToProps,
)(reduxForm({ form: 'editorCard' })(OdhCardUse));
