/* eslint-disable  */
import dict from './dict';

/**
 *
 */
export default class TechFormFieldDisabledService {
  /**
   *
   * @param requestType
   * @param requestReasonCode
   * @param fieldId
   */
  isDisabled(requestType, requestReasonCode, fieldId) {
    let result;
    if (requestType === 'edit_car') {
      result = this._getDisabledFromDict(requestReasonCode, fieldId);
    } else {
      result = false;
    }
    return result;
  }

  /**
   *
   * @param reasonCode
   * @param fieldId
   */
  _getDisabledFromDict(reasonCode, fieldId) {
    return !!dict[reasonCode] && !!dict[reasonCode][fieldId];
  }
}
