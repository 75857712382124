import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const ENTITY_CODE_NAME = 'entityCode';

/**
 * ## Сущность.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const EntityCode = (props) => {
  const dict = 'Entity';

  const { data = [] } = useGetDictionary(dict);

  return (
    <Select
      {...props}
      options={data}
      label={'Сущность'}
      name={ENTITY_CODE_NAME}
    />
  );
};
