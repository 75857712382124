import { fetchCarEquipmentOwners } from 'app/api/typeahead';
import { elementFormGrid } from 'app/components/card/common/grid';
import { WithOldValueCheckbox } from 'app/components/common/CheckboxField';
import { WithOldValueSelect } from 'app/components/common/SelectField';
import { NumberFieldRF } from 'core/form/reduxForm/fields';
import { AutocompleteAsyncRF } from 'core/form/reduxForm/fields/default/selects/AutocompleteAsyncRF';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * PropsFields.
 */
export default class PropsFields extends React.PureComponent {
  static propTypes = {
    carEquipmentType: PropTypes.array.isRequired,
    carOwnership: PropTypes.array.isRequired,
    card: PropTypes.object.isRequired,
    editable: PropTypes.bool,
    editableNotNeedDrno: PropTypes.bool,
    editableNotNeedDut: PropTypes.bool,
    modifyField: PropTypes.func.isRequired,
    notNeedDrno: PropTypes.bool,
    notNeedDut: PropTypes.bool,
    onChangeCarEquipmentTypeId: PropTypes.func.isRequired,
    required: PropTypes.bool,
    typeId: PropTypes.number,
    visibleCapacity: PropTypes.bool,
  };

  /**
   * Get elements.
   *
   * @returns {*}
   */
  getElements() {
    const { props } = this;
    const { editable, required, card } = props;

    const { notNeedDut, notNeedDrno, editableNotNeedDut, editableNotNeedDrno } =
      props;

    /**
     * A.
     *
     * @param {*} not_need_drno - A.
     * @returns {*}
     */
    const modifyDrno = (not_need_drno) => {
      if (editableNotNeedDrno) {
        props.modifyField(card, 'not_need_drno', not_need_drno);
      } else {
        props.modifyField(card, 'not_need_drno', not_need_drno);
      }
    };

    /**
     * A.
     *
     * @param {*} not_need_dut - A.
     * @returns {*}
     */
    const modifyDut = (not_need_dut) => {
      if (editableNotNeedDut) {
        props.modifyField(card, 'not_need_dut', not_need_dut);
      } else {
        props.modifyField(card, 'not_need_dut', not_need_dut);
      }
    };

    return [
      {
        addition: {
          label: 'Тип принадлежности',
          options: props.carOwnership,
          required,
        },
        component: WithOldValueSelect,
        editable,
        formValue: true,
        id: 'car_equipment_ownership_id',
        name: 'car_equipment_ownership_id',
      },
      {
        addition: {
          label: 'Тип навесного оборудования',
          options: props.carEquipmentType,

          required,

          /**
           * A.
           *
           * @param {*} value - Value.
           * @returns {*}
           */
          withChange: (value) => {
            props.modifyField(card, 'car_equipment_type_id', value);

            const { not_need_dut, not_need_drno } =
              props.onChangeCarEquipmentTypeId(
                value,
                notNeedDut || editableNotNeedDut,
                notNeedDrno || editableNotNeedDrno,
              );

            modifyDrno(not_need_drno);
            modifyDut(not_need_dut);
          },
        },
        component: WithOldValueSelect,
        editable,
        formValue: true,
        id: 'car_equipment_type_id',
        name: 'car_equipment_type_id',
      },
      {
        addition: {
          formatChars: {
            9: '[0-9]',
          },
          mask: '999999999999999999999999999999',
          type: 'text',
        },
        component: NumberFieldRF,
        editable,
        formValue: true,
        id: 'inventory_number',
        label: 'Инвентарный номер',
        name: 'inventory_number',
      },
      {
        addition: {
          fetchFunction: fetchCarEquipmentOwners,
          label: 'Владелец',
          required,
        },
        component: AutocompleteAsyncRF,
        editable,
        formValue: true,
        id: 'car_equipment_owner_id',
        name: 'car_equipment_owner_id',
      },
      {
        addition: {
          defaultChecked: props.notNeedDrno,
          label: 'Не подлежит оснащению ДРНО',
        },
        component: WithOldValueCheckbox,
        editable: editable && props.editableNotNeedDrno,
        formValue: true,
        id: 'not_need_drno',
        name: 'not_need_drno',
      },
      {
        addition: {
          defaultChecked: props.notNeedDut,
          label: 'Не подлежит оснащению ДУТ',
        },
        component: WithOldValueCheckbox,
        editable: editable && props.editableNotNeedDut,
        formValue: true,
        id: 'not_need_dut',
        name: 'not_need_dut',
      },
      {
        addition: {
          digits: 2,
          labelStyle: {
            height: '26px',
          },
          positive: true,
          required,
          type: 'decimal',
          zero: false,
        },
        component: NumberFieldRF,
        editable,
        formValue: true,
        id: 'capacity',
        label: 'Вместимость, куб. м.',
        name: 'capacity',
        style: {
          display: props.visibleCapacity ? 'inline-block' : 'none',
        },
      },
    ];
  }

  /**
   * Render.
   *
   * @returns {*}
   */
  render() {
    const { props } = this;
    const title = '';
    const columns = 2;
    return elementFormGrid(this.getElements(), columns, title, props.typeId);
  }
}
