/* eslint-disable  */
import { Pagination } from 'core/uiKit/components/Pagination';
import { TableSimple } from 'core/uiKit/components/tables/TableSimple';
import PropTypes from 'prop-types';

import { useParamsTable } from './useParamsTable';

/**
 * @typedef {import('./types.Table.d').Columns} Columns - Массив Cell.
 */

/**
 * Компонент таблицы с пагинацией.
 *
 * @param {object} props - Пропсы.
 * @param {Columns} props.columns - Колонки.
 * @param {Array<Record<string, any>> | Array} [props.data] - Массив таблицы.
 * @param {number} props.total - Остаток.
 * @param {boolean} [props.isLoading] - Флаг таблицы.
 * @param {Function} [props.selectedRows] - Функция выбора колонок.
 * @param {boolean} [props.checkboxSelection] - Флаг checkboxSelection.
 * @returns {JSX.Element} - Возвращает компонент таблицы.
 * @example <Table
 *       columns={columns}
 *       data={table || []}
 *       isLoading={isLoadingTable}
 *       total={count}
 *     />
 */
const Table = ({
  columns,
  data = [],
  total,
  isLoading,
  selectedRows,
  checkboxSelection,
}) => {
  const { query, setQuery } = useParamsTable();
  const { sort = '' } = query;

  const [nameSort, direction] = sort.split('.');

  /**
   * Функция сортировки.
   *
   * @param {string} name - Имя.
   * @returns {void} - Nothing.
   * @example ----
   */
  const sortHandler = (name) => {
    if (nameSort === name) {
      switch (direction) {
        case 'desc':
          setQuery({ sort: `${name}.asc` });
          return;
        case 'asc':
          setQuery({ sort: undefined });
          return;
        default:
      }
    }
    setQuery({ sort: `${name}.desc` });
  };

  return (
    <div className={'d-flex flex-column overflow-hidden w-100'}>
      <div className={'h-100 relative'}>
        <TableSimple
          {...{
            checkboxSelection,
            columns,
            data,
            direction,
            isLoading,
            nameSort,
            selectedRows,
            sortHandler,
          }}
        />
      </div>
      <Pagination total={total} />
    </div>
  );
};

export default Table;

Table.propTypes = {
  /**
   * Флаг показать чекбокс выбора заданых по умолчанию.
   */
  checkboxSelection: PropTypes.bool,

  /**
   * Данные для формирования заголовка таблицы и колонок.
   */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Node ячейки.
       */
      Cell: PropTypes.elementType,

      /**
       * Вывод этого в качестве заголовка.
       */
      Header: PropTypes.string.isRequired,

      /**
       * Имя поля по которому формируется столбец.
       */
      accessor: PropTypes.string.isRequired,

      /**
       * Расположение текста
       * Установите выравнивание текста для содержимого ячейки таблицы.
       * Денежные или числовые поля должны быть выровнены по правому краю,
       * так как это позволяет вам быстро складывать их в уме, не беспокоясь о десятичных дробях.
       */
      align: PropTypes.oneOf(['center', 'inherit', 'justify', 'left', 'right']),

      /**
       * Минимальная ширина столбца по дефолту 100px.
       */
      minWidth: PropTypes.string,

      /**
       * Разрешает сортировку по столбцу.
       */
      sortable: PropTypes.bool,
    }).isRequired,
  ),

  /**
   * Сами данные таблицы.
   */
  data: PropTypes.arrayOf(PropTypes.object),

  /**
   * Флаг указывающий, что выполняется запрос на сервер.
   */
  isLoading: PropTypes.bool,

  /**
   * Функция для выбора чекбоксом строчек таблицы
   * на вход параметры selectedRowIds {object} and selectedFlatRows {array}
   * ({selectedRowIds, selectedFlatRows })=>_.
   */
  selectedRows: PropTypes.func,

  /**
   * Общее количество записей.
   */
  total: PropTypes.number,
};
