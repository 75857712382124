import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const REAGENT_KIND_NAME = 'reagentKind';

/**
 * ## Вид ПГР.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const ReagentKind = ({ label = 'Вид ПГР', ...otherProps }) => {
  const dict = 'reagentKind';
  const { data = [] } = useGetDictionary(dict);

  return (
    <Select
      {...otherProps}
      options={data}
      label={label}
      name={REAGENT_KIND_NAME}
    />
  );
};
