export const improvement_object_category = [
  {
    code: 'first_category',
    id: 1,
    name: '1 категория',
    parent_ogh_object_type_list: [],
  },
  {
    code: 'second_category',
    id: 2,
    name: '2 категория',
  },
  {
    code: 'third_category',
    id: 3,
    name: '3 категория',
  },
  {
    code: 'extra_category',
    id: 4,
    name: 'Внекатегорийный',
  },
];
