import { DialogMUI, Icon, IconButton, Toolbar } from 'core/uiKit/material-ui';
/* eslint-disable  */
import React, { Component } from 'react';

const dialogStyle = {
  fontFamily: 'Arial, sans-serif',
  fontWeight: 'bold',
};

const toolbarStyle = {
  alignItems: 'center',
  backgroundColor: 'none',
  padding: '0',
};

/**
 * Компонент DialogMatchingRegistryComment.
 *
 * @returns {JSX.Element}
 */
export default class DialogMatchingRegistryComment extends Component {
  /**
   * Метод render.
   *
   * @returns {JSX.Element}
   */
  render() {
    const { comment, hideComment } = this.props;

    return (
      <DialogMUI modal={true} open={true} titleStyle={dialogStyle}>
        <Toolbar style={toolbarStyle}>
          Комментарий:
          <IconButton aria-label="Close" color="inherit" onClick={hideComment}>
            <Icon className="material-icons">clear</Icon>
          </IconButton>
        </Toolbar>

        <div
          style={{
            maxHeight: '500px',
            overflowY: 'auto',
            whiteSpace: 'pre-wrap',
          }}
        >
          {comment}
        </div>
      </DialogMUI>
    );
  }
}
