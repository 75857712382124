/* eslint-disable */
import React from 'react';
import { ArrayParam } from 'use-query-params';

import {
  ChangeLogTypeFF,
  CHANGE_LOG_TYPE_NAME,
} from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
export const ChangeLogTypeFilter = () => (
  <ChangeLogTypeFF className={filterInput} />
);

 
export { CHANGE_LOG_TYPE_NAME };

 
export const changeLogTypeParams = {
  [CHANGE_LOG_TYPE_NAME]: ArrayParam,
};
