import { AutocompleteAsync } from 'core/uiKit/inputs/selects/AutocompleteAsync';
import { fetchCustomers } from 'core/uiKit/preparedInputs/selects/api';
import React from 'react';

export const CUSTOMER_PLACE_NAME = 'customer_id';

/**
 * Заказчик с возможностью прокинуть place.
 *
 * @param {object} props - Пропсы.
 * @param {string} props.place - Место откуда идет запрос.
 * @returns {JSX.Element}
 */
export const CustomerPlace = ({ place = '', ...otherProps }) => {
  return (
    <AutocompleteAsync
      {...otherProps}
      fetchFunction={fetchCustomers(place)}
      label={'Заказчик'}
      name={CUSTOMER_PLACE_NAME}
    />
  );
};
