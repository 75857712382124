export const redBookPlant = [
  {
    id: 10,
    name: '216105',
  },
  {
    id: 1,
    name: 'atra',
  },
];
