import { pathExaminationOGHApplication } from 'app/pages/certificationOfOgh/examinationOGH.Application/path.ExaminationOGH.Application';
import { pathCertificationOfOgh } from 'app/pages/certificationOfOgh/path.CertificationOfOgh';
import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Cell для __Id заявки на обследование ОГХ__.
 *
 * @param {object} props - Пропсы Cell.
 * @param {string} props.value - Значение поля.
 * @returns {JSX.Element}
 */
export const BrIdLinkCell = ({ value }) => {
  if (value) {
    return (
      <Link
        to={`/${pathCertificationOfOgh.path}/${pathExaminationOGHApplication.path}/${value}`}
      >
        {value}
      </Link>
    );
  }
  return null;
};
