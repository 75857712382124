import { createAttribute as createDocumentsAttribute } from 'app/components/card/ogh/DocumentsTab';
import { CardOgh } from 'types/Card';
import { Mock, vi } from 'vitest';

import { createAttribute as createAddressListFieldAttribute } from '../components/AddressListField';
import { createAttribute as createAquaAreaAttribute } from '../components/AquaAreaTab';
import { createAttribute as improvementCategoriesCreateAttribute } from '../components/ImprovementCategories';
import { createAttribute as createOwnerAndCustomerFieldsAttribute } from '../components/OwnerAndCustomerFields';
import { REPAIRS_INFO_LIST_NAME } from '../components/tabs/Repairs.Tab/Table.Repairs.Tab';
import { REPAIRS_INFO_LIST_PLAN_NAME } from '../components/tabs/RepairsPlan.Tab';
import { createAttribute as createTypeOtherTerritoryAttribute } from '../components/TypeOtherTerritory';

vi.mock('app/components/card/ogh/DocumentsTab');
vi.mock('../components/AddressListField');
vi.mock('../components/AquaAreaTab');
vi.mock('../components/ImprovementCategories');
vi.mock('../components/OwnerAndCustomerFields');
vi.mock('../components/TypeOtherTerritory');

/**
 * Функция создания атрибутов. Иные объекты благоустройства.
 *
 * @param formValues - Значения из формы.
 * @returns Возвращают атрибуты.
 */
export const createAttributeImprovementObject = (formValues: CardOgh) => {
  return {
    ...createAddressListFieldAttribute(formValues),
    ...createAquaAreaAttribute(formValues),
    ...createDocumentsAttribute(formValues),
    ...createOwnerAndCustomerFieldsAttribute(formValues),
    ...createTypeOtherTerritoryAttribute(formValues),
    ...improvementCategoriesCreateAttribute(formValues),
    [REPAIRS_INFO_LIST_NAME]: formValues.repairs_info_list,
    [REPAIRS_INFO_LIST_PLAN_NAME]: formValues[REPAIRS_INFO_LIST_PLAN_NAME],
    re_calc_address_list: null,
    re_calc_name: null,
  };
};

/**
 * Mock Default.
 *
 * @returns {void}
 */
const mockDefault = () => {
  (createAddressListFieldAttribute as Mock).mockReturnValue({ aa: 1 });
  (createAquaAreaAttribute as Mock).mockReturnValue({ ba: 1 });
  (improvementCategoriesCreateAttribute as Mock).mockReturnValue({ ca: 1 });
  (createOwnerAndCustomerFieldsAttribute as Mock).mockReturnValue({ da: 1 });
  (createTypeOtherTerritoryAttribute as Mock).mockReturnValue({ qa: 1 });
  (createDocumentsAttribute as Mock).mockReturnValue({ sa: 1 });
  (createDocumentsAttribute as Mock).mockReturnValue({ ga: 1 });
};

describe('🐛 spec createAttribute.ImprovementObject.spec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock

    //🔥 Act
    const res = createAttributeImprovementObject({} as CardOgh);

    //❓ Assert
    expect(res).toStrictEqual({
      aa: 1,
      ba: 1,
      ca: 1,
      da: 1,
      ga: 1,
      qa: 1,
      re_calc_address_list: null,
      re_calc_name: null,
      repairs_info_list: undefined,
      repairs_info_list_plan: undefined,
    });
  });
});
