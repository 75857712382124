/* eslint-disable */
import React from 'react';
import { ArrayParam, withDefault } from 'use-query-params';

import { CarSeasonsFF, CAR_SEASONS_NAME } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
 
/**
 * Сезонность
 * сarSeasons
 */
 
export const CarSeasonsFilter = () => <CarSeasonsFF className={filterInput} />;

 
export const carSeasonsParams = {
  [CAR_SEASONS_NAME]: withDefault(ArrayParam, []),
};
