import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { formatDict } from 'app/components/card/ogh/dts/formatDict';
import filterByOtherImprovementObjectTypeId from 'app/selectors/card/utils/filterByOtherImprovementObjectTypeId';
import filterByParentTypeId from 'app/selectors/card/utils/filterByParentTypeId';
import { useGetParamsUrl } from 'core/utils/hooks/useGetParamsUrl';

/**
 * Хук для селекта Назначение.
 *
 * @returns {Array} - Значения для селекта Назначение.
 * @example --------
 */
export const useGetDtsTypes = () => {
  const { data = [] } = useGetDictionary('dtsType');
  const { actions } = useGetParamsUrl();
  const currentCard = useGetCurrentCard();
  const parent = useGetParentCard();

  const dict = data.map(({ id, attribute }) => ({
    id: id,
    name: attribute?.name,
    parent_ogh_object_type_list: attribute?.parent_ogh_object_type_list,
  }));

  const parentTypeId =
    currentCard.parentInfo?.type_id || actions?.haveParent
      ? parent?.typeId // при создании
      : null;

  if (!parentTypeId) {
    return dict;
  }

  // форматируем словарь
  const formatedDict = formatDict(dict);

  // для ИОБ дочек
  let otherImprovementObjectTypeId =
    currentCard?.parentInfo?.other_improvement_object_type_id ||
    parent?.attribute?.other_improvement_object_type_id
      ?.other_improvement_object_type;

  if (otherImprovementObjectTypeId) {
    // TODO: переработать filterByOtherImprovementObjectTypeId. сделано в спешке
    return filterByOtherImprovementObjectTypeId({
      dict: formatedDict,
      listKey: 'parent_ogh_object_type_list',
      otherImprovementObjectTypeId,
      parentTypeId,
    });
  }

  // TODO: переработать filterByParentTypeId. сделано в спешке
  return filterByParentTypeId({
    dict,
    listKey: 'parent_ogh_object_type_list',
    parentTypeId,
  });
};
