import { getRegistryOghCount } from 'app/api/requests/getRegistryOghCount';

/**
 * A.
 *
 * @param {*} queryParams - Query params.
 * @returns {number}
 */
export const fetchOghTableCount = (queryParams) => {
  // проверяем что нет typeId огх
  if (!queryParams?.typeId) {
    return [];
  }
  return getRegistryOghCount(null, queryParams);
};
