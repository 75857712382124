/* eslint-disable */
const vinConstraints = {
  format: {
    pattern: /[^QIOqio\W]*/,
    message: 'Недопустимый символ',
  },
  length: {
    is: 17,
    wrongLength: 'Необходимо ввести %{count} символов',
  },
};

 
export default class Vin {
  static constraints = function (value) {
    let result;
    if (value) {
      result = vinConstraints;
    } else {
      result = null;
    }
    return result;
  };
}
