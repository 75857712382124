import { DeleteButtonCellRepairsPlan } from './Delete.Button.Cell.RepairsPlan';

/**
 * Колонки таблицы проектов.
 *
 * @returns Columns.
 */
export const getColumnsRepairsPlanTab = () => [
  {
    Cell: DeleteButtonCellRepairsPlan,
    Header: '',
    accessor: 'id',
  },
  {
    Header: 'Наименование',
    accessor: 'name',
  },
  {
    Header: 'Дата',
    accessor: 'date',
  },
  {
    Header: 'Характеристика (объемы)',
    accessor: 'property',
  },
  {
    Header: 'Планируемое время проведения работ',
    accessor: 'plan_time',
  },
];
