import { downloadFile } from 'app/api/requests/downloadFile';
import { getFilesForSignature } from 'app/api/requests/getFilesForSignature';
import { getFilename } from 'app/utils/getFilename';

/**
 * Загружаем на клиент файлы для подписания.
 *
 * @param {object} params - Параметры запроса.
 * @param {string} params.oghId - ID.
 * @param {string} params.signType - Тип метода запроса на подписание.
 * @returns {Promise<Array<File>>}
 */
export const getFiles = async ({ oghId, signType }) => {
  const res = await getFilesForSignature({ oghId, signType });

  if (res?.length) {
    return Promise.all(
      res?.map(async (fileId) => {
        const response = await downloadFile(fileId);
        // let name = 'Печать.csv';
        // let blob = new Blob([]);

        const name = getFilename(response);
        const blob = await response.blob();
        return {
          file: new File([blob], name),
          name,
          storageId: fileId,
        };
      }),
    );
  }
};
