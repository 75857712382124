import { ContinueDialog } from 'core/uiKit/components';
import React from 'react';

 
/**
 * Диалоговое окно __Подтверждение удаление__.
 *
 * @param {object} props - Пропсы.
 * @param {boolean} props.isOpen - Флаг isOpen.
 * @param {Function} props.removeRow - Функция удаления колонки.
 * @param {Function} props.setContinueDialog - Функция изменения состояния диалога.
 * @returns {JSX.Element} - Возвращает компонент диалогового окна.
 */
export const ContinueDialogDeleteTable = ({
  isOpen,
  removeRow,
  setContinueDialog,
}) => {
   
  /**
   * Функция отмены.
   *
   * @returns {void} - Nothing.
   */
  const cancelRemoveRow = () => {
    setContinueDialog(false);
  };

  return (
    <ContinueDialog
      isOpen={isOpen}
      closeDialog={cancelRemoveRow}
      send={removeRow}
      textHeader={'Подтверждение'}
      Body={
        <p>
          Выбранные элементы будут исключены из текущего ОГХ. Подтвердите
          операцию.
        </p>
      }
    />
  );
};
