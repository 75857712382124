import { ErzStateId } from 'core/uiKit/preparedInputs/selects/simpleSelect/ErzStateId';
import React from 'react';

/**
 * Компонент обертка Quantity для Final Form.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.input - Пропсы поля.
 * @param {object} props.otherProps - Остальные пропсы.
 * @returns {JSX.Element} - Возвращает компонет TotalAreaGeo.
 */
export const ErzStateIdWithFF = ({ input, ...otherProps }) => {
  return <ErzStateId {...input} {...otherProps} />;
};
