/* eslint-disable */
import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import { ContainerType, CONTAINER_TYPE_NAME } from 'core/uiKit/preparedInputs';
 
export { CONTAINER_TYPE_NAME };

 
 
/**
 * ## Тип МСО
 *
 * ### React Final Form Field
 * ```js
 * import { ContainerTypeFF, CONTAINER_TYPE_NAME } from 'core/form/finalForm/fields';
 * ```
 * * `type = Select`
 * * `name = containerType`
 * * `dict = containerType`
 */
/* eslint-disable */
export const ContainerTypeFF = ({ name, ...otherProps }) => {
  return (
    <Field name={CONTAINER_TYPE_NAME}>
      {(props) => {
        return <ContainerType {...props} {...props.input} {...otherProps} />;
      }}
    </Field>
  );
};

ContainerTypeFF.propTypes = {
   
   
/**
   * выбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
/**
   * placeholder
   */
  placeholder: PropTypes.string,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
};

ContainerTypeFF.defaultProps = {
  dark: false,
  disabled: false,
};
