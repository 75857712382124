import { ContinueFFDialog } from 'core/form/finalForm/components/dialogs/ContinueFF.Dialog';
/* eslint-disable  */
import { CommentFF } from 'core/form/finalForm/fields/prepared/textField/CommentFF';
import { Dialog } from 'core/uiKit/components/dialogs/Dialog';
import { LoaderCreateFiles } from 'core/uiKit/components/loaders/LoaderCreateFiles';
import React from 'react';

import { DialogBodySignActOfCompleted } from './Dialog.Body.SignActOfCompleted';
import { useGetFilesActOfCompletedWorkElectronicSignature } from './hooks/useGetFilesActOfCompletedWorkElectronicSignature';
import { useSignActOfCompletedSave } from './hooks/useSignActOfCompletedSave';
import { validateDialogSignActOfCompleted } from './validate.DIalogSignActOfCompleted';

/**
 * Диалоговое окно кнопки Согласование графика паспортизации ОГХ.
 *
 * @param {object} props - Пропсы.
 * @param {Function} props.setOpen - Функция изменения состояния показа диалогового окна.
 * @returns {JSX.Element}
 */
export const DialogApproveButton = ({ setOpen }) => {
  const { isLoading, isSuccess, files } =
    useGetFilesActOfCompletedWorkElectronicSignature();
  const { sendSignActOfCompletedSave } = useSignActOfCompletedSave(setOpen);

  return (
    <>
      <Dialog
        isOpen={isLoading}
        maxWidth={'xs'}
        textHeader={'Формируются файлы для подписания'}
        Body={<LoaderCreateFiles />}
      />
      <ContinueFFDialog
        {...{
          Body: (
            <DialogBodySignActOfCompleted files={files}>
              <CommentFF name={'comment'} required={false} />
            </DialogBodySignActOfCompleted>
          ),

          /**
           *
           */
          closeDialog: () => setOpen(false),
          isLoading: false,
          isOpen: isSuccess,
          onSubmit: sendSignActOfCompletedSave,
          textHeader: 'Подписание документа',
          validate: validateDialogSignActOfCompleted,
        }}
      />
    </>
  );
};
