import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const OWNER_NAME = 'owner';

/**
 * ## Балансодержатель.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const Owner = (props) => {
  const dict = 'owners';

  const { data = [] } = useGetDictionary(dict);

  return (
    <Select
      {...props}
      options={data}
      label={'Балансодержатель'}
      name={OWNER_NAME}
    />
  );
};
