import { Map, MapPointerEvent } from '@2gis/mapgl/types';
import transformRotate from '@turf/transform-rotate';
import {
  bbox,
  center,
  lineString,
  polygon,
  polygon as turfPolygon,
} from '@turf/turf';

import { GeometryUtils, sizeInPixelsToCoords } from '../../..';
import {
  Controls,
  EditorShapeOnChangeEvent,
  GeometryTypes,
} from '../../../types.d';
import ShapeEditor, { isGeometryPolygon } from '../..';
import Circle from '../Controls/Circle';
import Shape from '../Controls/Shape';

const CURSOR_TYPE = 'all-scroll';

/**
 * Контрол для вращения геометрии.
 *
 * @param map - Карта.
 * @param coordinates - Координаты точки.
 * @returns Объект с методами для перемещения геометрии.
 */
export default class Rotator {
  public shape: Shape<Rotator>;

  public map: Map;

  public geometryUtils: GeometryUtils;

  public shapeEditor: ShapeEditor;

  public isHovered: boolean = false;

  private pivot: number[] | undefined;

  private zoomEndHandler: () => void;

  /**
   * Создание иконки для перемещения геометрии.
   *
   * @param map - Эксземпляр карты.
   * @param coordinates - Координаты точки.
   * @param geometryUtils - Утилиты геометрии.
   * @param shapeEditor - Редактор геометрий.
   */
  constructor(
    map: Map,
    coordinates: number[],
    geometryUtils: GeometryUtils,
    shapeEditor: ShapeEditor,
  ) {
    const iconSize: [number, number] = [12, 12];

    this.map = map;
    this.geometryUtils = geometryUtils;
    this.shapeEditor = shapeEditor;
    this.shape = new Circle(map, {
      coordinates: coordinates,
      cursor: CURSOR_TYPE,
      iconSize,
      size: [12, 12],
      userData: {
        control: this,
        coordinates,
        coordinatesToCheckMouseEvent: sizeInPixelsToCoords(
          map,
          coordinates,
          iconSize,
        ),
        type: GeometryTypes.Point,
      },
    });

    this.zoomEndHandler = this.onZoomEnd.bind(this);

    map.on('zoomend', this.zoomEndHandler);
  }

  /**
   * Получить угол поворота геометрии.
   *
   * @param shapeCoordinates - Предыдущие координаты курсора (берутся из координаты ротатора).
   * @param coordinates - Новые координаты курсора.
   * @param controls - Конролы.
   * @returns A.
   */
  getAngle(
    shapeCoordinates: number[],
    coordinates: number[],
    controls: Controls,
  ) {
    const shapePosX = shapeCoordinates[0];
    const shapePosY = shapeCoordinates[1];

    this.pivot = !this.pivot
      ? controls.mover.shape.getGeometry()?.getCoordinates() || undefined
      : this.pivot;

    if (!this.pivot) return;

    const pivotX = this.pivot[0];
    const pivotY = this.pivot[1];

    const mousePosX = coordinates[0];
    const mousePosY = coordinates[1];

    const pivotShapeAngle =
      (Math.atan2(shapePosX - pivotX, shapePosY - pivotY) * 180) / Math.PI;
    const angle =
      (Math.atan2(mousePosX - pivotX, mousePosY - pivotY) * 180) / Math.PI -
      pivotShapeAngle;

    return angle;
  }

  /**
   * Обработчик изменения позиции мувера.
   *
   * @param args - Аргументы.
   * @param args.box - Границы геометрии.
   * @param args.controls - Объект контроллеров изменения геометрии.
   * @param args.geometry - Геометрия.
   * @param args.coordinates - Координаты точки.
   * @returns Экземпляр Mover.
   */
  onChange({
    box,
    controls,
    geometry,
    coordinates,
  }: EditorShapeOnChangeEvent<Rotator>) {
    const shapeGeometry = this.shape.getGeometry();
    if (!shapeGeometry) return geometry.userData.coordinates;

    const turfGeometry = isGeometryPolygon(geometry)
      ? turfPolygon(geometry.userData.coordinates)
      : lineString(geometry.userData.coordinates);

    if (!shapeGeometry) return geometry.userData.coordinates;

    const angle = this.getAngle(
      shapeGeometry.getCoordinates(),
      coordinates,
      controls,
    );

    if (!angle) return geometry.userData.coordinates;

    const rotatedGeometry = transformRotate(turfGeometry, angle, {
      pivot: this.pivot,
    });
    const newBounds = bbox(turfGeometry);

    box.update(newBounds);
    this.shape.updateCoordinates({ lngLat: coordinates });
    controls.resizer.updateShapesCoordinates(newBounds);

    return rotatedGeometry.geometry.coordinates;
  }

  /**
   * Обработчик нажатия на контрол.
   *
   * @param args - Аргументы.
   * @param args.controls - Объект контроллеров изменения геометрии.
   */
  onClick({ controls }: Pick<EditorShapeOnChangeEvent<Rotator>, 'controls'>) {
    this.pivot = controls.mover.shape.getGeometry()?.getCoordinates();
    this.shape.hide();
    controls.resizer.hide();
  }

  /**
   * Обработчик отпускания кнопки мыши.
   *
   * @param args - Аргументы.
   * @param args.box - Границы геометрии.
   * @param args.controls - Объект контроллеров изменения геометрии.
   */
  onMoveEnd({
    box,
    controls,
  }: Pick<EditorShapeOnChangeEvent<Rotator>, 'controls' | 'box'>) {
    this.shape.show();
    controls.resizer.show();

    const newBounds = bbox(lineString(box.geometry.userData.coordinates[0]));
    this.shape.updateCoordinates({
      lngLat: ShapeEditor.getRotatorCoordinates(this.map, newBounds),
    });

    const boxCenter = center(lineString(box.geometry.userData.coordinates[0]))
      .geometry.coordinates;
    controls.mover.updateCoordinates(boxCenter);
  }

  /**
   * Устанавливает стиль курсора при наведении на мувер.
   *
   */
  setCursorStyle() {
    this.shape.setCursorStyle();
  }

  /**
   * Сбрасывает стиль курсора при наведении на мувер.
   *
   */
  resetCursorStyle() {
    this.shape.setCursorStyle(null);
  }

  /**
   * Обработчик изменения размера геометрии
   * после того, как был зум был завершен.
   *
   */
  onZoomEnd() {
    if (this.shapeEditor.box) {
      const newBounds = bbox(
        polygon(this.shapeEditor.box.geometry.userData.coordinates),
      );

      const coordinates = ShapeEditor.getRotatorCoordinates(
        this.map,
        newBounds,
      );
      this.shape.updateCoordinates({ lngLat: coordinates });

      if (coordinates) {
        this.shape.updateCoordinatesToCheckMouseEvent(coordinates);
      }
    }
  }

  /**
   * Проверяет находится ли курсор над геометрией.
   *
   * @param event - Событие мыши.
   * @param event.lngLat - Координаты мыши.
   * @returns Boolean.
   */
  public isMouseHovered({ lngLat }: MapPointerEvent) {
    this.isHovered = this.geometryUtils.isPointWithinPolygon(
      lngLat,
      this.shape.coordinatesToCheckMouseEvent,
    );
    return this.isHovered;
  }

  /**
   * Уничтожает контрол.
   *
   */
  destroy() {
    this.shape.destroy();
  }
}
