import { turnIntoCamelCase } from 'app/utils/api';
/* eslint-disable */

/**
 *
 * @param formValues
 */
export const inboundAreaValidation = (formValues) => {
  const { snowArea, rotorArea, inboundArea } = turnIntoCamelCase(formValues);

  if (+snowArea + +rotorArea > +inboundArea) {
    return {
      inbound_area:
        'сумма значений параметров «Площадь вывоза снега, кв.м», «Площадь роторной перекидки, кв.м» не должна превышать значения параметра «Общая площадь в границах ОДХ, кв.м»',
    };
  }
};
