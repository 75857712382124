/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */

/**
 * Колонка __Дата установки__.
 *
 * @type {{ accessor: 'installation_date',  Header: 'Дата установки' }}
 * @augments Cell
 */
export const installation_date = {
  Header: 'Дата установки',
  accessor: 'installation_date',
  sortable: true,
};
