import formatRootId from 'app/utils/formatRootId';
import { IndexCell } from 'core/uiKit/components/cells/Index.Cell/Index.Cell';
import { LegendCell } from 'core/uiKit/components/cells/Legend.Cell/Legend.Cell';
import { TableSimple } from 'core/uiKit/components/tables/TableSimple';
import React from 'react';

import { LegendNestedObjectsDiff } from './Legend.NestedObjectsDiff';
import { useGetNestedObjectsDiffTable } from './useGetNestedObjectsDiffTable';

/**
 * Таблица "Вложенные объекты" с легендой.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.etalon - Объект сравниваемый.
 * @param {object} props.copy - C чем сравниваем.
 * @returns {JSX.Element} - JSX.
 * @example <NestedObjectsDiff etalon={etalon} copy={copy}/>
 */
export const NestedObjectsDiff = ({ etalon, copy }) => {
  const columns = [
    {
      Cell: IndexCell,
      Header: '№ п/п',
      accessor: 'index',
      align: 'center',
      minWidth: 60,
    },
    {
      Header: 'Объект',
      accessor: 'type_name',
    },
    {
      Header: `${formatRootId(copy.id)} (${copy.object_status_name})`,
      accessor: 'copy_object',
      align: 'center',
    },
    {
      Header: `${formatRootId(etalon.id)} (${etalon.object_status_name})`,
      accessor: 'original_object',
      align: 'center',
    },
    {
      Cell: LegendCell,
      Header: 'Легенда',
      accessor: 'prop_operation',
      align: 'center',
    },
  ];

  const { table, isLoading } = useGetNestedObjectsDiffTable(etalon, copy);

  return (
    <>
      <div className={'dialog-comparison-version-card__dialog-table'}>
        <TableSimple columns={columns} data={table} isLoading={isLoading} />
      </div>
      <LegendNestedObjectsDiff />
    </>
  );
};
