import { useFetchTitleList } from 'app/pages/titleList/TitleList/api';
import { isImprovementTerritory, isOdh } from 'app/utils/checkOghTypeId';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React, { useMemo } from 'react';

export const GROUPING_NAME = 'grouping';

/**
 * ## Детализация.
 *
 * @param {*} props - Properties.
 * @returns {JSX.Element}
 */
export const Grouping = (props) => {
  const { data: { object_type_id: titleListTypeId } = {} } =
    useFetchTitleList();

  let data = useMemo(() => {
    if (isImprovementTerritory(titleListTypeId)) {
      return [
        { id: 'none', name: 'Полный список' },
        { id: 'object_classification', name: 'Классификация' },
        { id: 'object_maf', name: 'МАФ' },
        { id: 'object_covering', name: 'Покрытия' },
        { id: 'object_gardening', name: 'Озеленение' },
        { id: 'object_cleaning', name: 'Уборка' },
        { id: 'object_other_elements', name: 'Прочие элементы' },
      ];
    } else if (isOdh(titleListTypeId)) {
      return [
        { id: 'none', name: 'ОДХ' },
        { id: 'clean_category', name: 'Категория по уборке' },
      ];
    }

    return [
      { id: 'none', name: 'ОГХ' },
      { id: 'object_group', name: 'Группа' },
      { id: 'object_category', name: 'Категория/класс' },
      { id: 'clean_category', name: 'Категория по уборке' },
    ];
  }, [titleListTypeId]);

  return (
    <Select
      {...props}
      options={data}
      label={'Детализация'}
      name={GROUPING_NAME}
      withAll={false}
    />
  );
};
