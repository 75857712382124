import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { TextField } from 'core/uiKit/inputs/TextField';
import React from 'react';

/**
 * TextField __Входит в__.
 *
 * @returns {JSX.Element}
 */
export const ParentInfo = () => {
  const { shortObjectRootId: shortObjectRootId = '', name: parentName = '' } =
    useGetParentCard();

  return (
    <TextField
      value={`${shortObjectRootId}_${parentName}`}
      id={'parent_name'}
      name={'parent_name'}
      label={'Входит в'}
      disabled={true}
    />
  );
};
