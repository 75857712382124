import 'assets/styles/common.legend.scss';

import cn from 'classnames';
import PropTypes from 'prop-types';
/* eslint-disable */
import React from 'react';

class LegendItem extends React.Component {
  static propTypes = {
    classN: PropTypes.string.isRequired,
    style: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
    checkboxValue: PropTypes.bool.isRequired,
    action: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      checkBoxCopy: true,
      checkBoxOriginal: true,
    };
  }

  render() {
    const { classN, style, value, checkboxValue, action } = this.props;
    return (
      <li className="list-inline-item">
        <input
          defaultChecked={checkboxValue}
          style={{ marginRight: '5px' }}
          type="checkbox"
          onChange={action}
        />
        <div className={classN} style={style} /> {value}
      </li>
    );
  }
}

/* eslint-disable */
export default class LegendMapDiff extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    legend: PropTypes.array,
    action: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      checkBoxCopy: true,
      checkBoxOriginal: true,
    };
    this.changeMap = this.changeMap.bind(this);
    this.originalChange = this.originalChange.bind(this);
    this.copyChange = this.copyChange.bind(this);
  }
  changeMap(copyValue, originValue) {
    let args = { copy: copyValue, original: originValue };
    this.props.action(args);
  }
  copyChange() {
    this.setState({ checkBoxCopy: !this.state.checkBoxCopy });
    this.changeMap(!this.state.checkBoxCopy, this.state.checkBoxOriginal);
  }
  originalChange() {
    this.setState({ checkBoxOriginal: !this.state.checkBoxOriginal });
    this.changeMap(this.state.checkBoxCopy, !this.state.checkBoxOriginal);
  }

  render() {
    const { legend } = this.props;
    let self = this;
    return (
      <div className={cn('legend', this.props.className)}>
        <h6>Легенда</h6>
        <ul className="list-inline mb-0">
          <LegendItem
            action={self.copyChange}
            checkboxValue={this.state.checkBoxCopy}
            classN={cn(legend[0].className)}
            key="0"
            style={legend[0].style}
            value={legend[0].value}
          />
          <LegendItem
            action={self.originalChange}
            checkboxValue={this.state.checkBoxOriginal}
            classN={cn(legend[1].className)}
            key="1"
            style={legend[1].style}
            value={legend[1].value}
          />
        </ul>
      </div>
    );
  }
}
