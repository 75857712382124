import 'assets/styles/common.sortBtn.scss';

/* eslint-disable  */
import FaIcon from 'app/components/common/FaIcon';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**
 *
 */
export default class SortBtn extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    direction: PropTypes.string,
    onClick: PropTypes.func,
  };

  /**
   *
   */
  render() {
    const { direction, onClick } = this.props;

    let icon = <FaIcon icon="sort" />;
    if (direction === 'asc') {
      icon = <FaIcon icon="sort-amount-asc" />;
    }
    if (direction === 'desc') {
      icon = <FaIcon icon="sort-amount-desc" />;
    }

    return (
      <button className={cn('sortBtn', this.props.className)} onClick={onClick}>
        {icon}
      </button>
    );
  }
}
