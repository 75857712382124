import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import getHint from 'app/utils/getHint';
import { IsDiffHeightMarkRF } from 'core/form/reduxForm/fields';
import { CustomerOznRF } from 'core/form/reduxForm/fields/prepared/selects/autocompleteAsync/CustomerOzn.RF';
import { OwnerIdRF } from 'core/form/reduxForm/fields/prepared/selects/autocompleteAsync/OwnerId.RF';
import { DepartmentIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/DepartmentId.RF';
import { ImprovementCategoryIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/ImprovementCategoryId.RF';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { CreateType } from 'core/uiKit/preparedInputs/selects/simpleSelect/CreateType';
import { GrbsId } from 'core/uiKit/preparedInputs/selects/simpleSelect/GrbsId';

import { AddressListField } from '../../components/AddressListField';
import { DistrictListField } from '../../components/DistrictListField';
import { NameField } from '../../components/NameField';
import { OkrugListField } from '../../components/OkrugListField';
import { ImprovementObjectCategoryIdFieldOzn } from '../fields.Ozn/ImprovementObjectCategoryId.Field.Ozn';

const ids = {
  address: 're_calc_address_list',
  name: 're_calc_name',
};

/**
 * Свойства Объекты Озеленения. Ozn.
 *
 * @param {object} props - Parameters.
 * @returns {JSX.Element}
 */
export const PropertiesOznTab = (props) => {
  const card = useGetCurrentCard();

  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();

  const { editMode } = useMode();

  return (
    <>
      <FieldsSplitColumns>
        <CreateType disabled={true} value={card.attribute?.create_type} />
        <GrbsId
          disabled={true}
          value={card.attribute?.grbs_id?.legal_person}
          label={'Учредитель/ГРБС'}
        />
      </FieldsSplitColumns>
      <DepartmentIdRF
        disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
        withAll={false}
      />

      <FieldsSplitColumns>
        <OwnerIdRF
          helpTooltip={getHint('owner_id')}
          disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
          required={true}
        />
        <CustomerOznRF
          helpTooltip={getHint('customer_id_OZN')}
          disabled={
            !(props.editable && props.isEditCurrentObjectIsExternalSystem)
          }
          required={true}
        />

        <ImprovementCategoryIdRF disabled={true} />
        <ImprovementObjectCategoryIdFieldOzn />
        <NameField {...props} />
        <OkrugListField {...props} />
        <DistrictListField {...props} />
        <IsDiffHeightMarkRF
          label={'Разновысотный ОГХ'}
          disabled={
            !(props.editable && props.isEditCurrentObjectIsExternalSystem)
          }
        />
      </FieldsSplitColumns>
      <AddressListField
        {...props}
        {...{
          editable: false,
          id: ids.address,
          name: ids.address,
        }}
      />
    </>
  );
};
