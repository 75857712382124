// TODO
// Очистка стейта. Надо ли, нажали f5 и всё в порядке, потому что стэйт в локал сторэдж пока весь не хранится, и вообще очищать так немного странно1
// очистку надо решить комплексно, чтобы под конец рабочего дня браузер пользователя не сжирал полностью оперативку
// Например, при переходе на другие страницы выпиливаем стэйт, когда можно

/* eslint-disable */
export function clearTree(action, state) {
  const { from, to } = getFromTo(action, state);
  if (
    (from === 'map' && to === 'map') ||
    (from === 'card' && to === 'map') ||
    ((from === 'map' || from === 'card') && to === 'card') ||
    (!from && (to !== 'card' || to !== 'map'))
  ) {
    return {
      ...state,
      activePage: to,
    };
  } else {
    if (state.count) {
      return { count: state.count };
    }
    return {};
  }
}

/* eslint-disable */
export function clearForms(action, state) {
  const { from, to } = getFromTo(action, state);
  if (
    (from === 'card' && to === 'map') ||
    ((from === 'map' || from === 'card') && to === 'card') ||
    (!from && (to === 'card' || to === 'map'))
  ) {
    return {
      ...state,
      activePage: to,
    };
  } else {
    return {
      ...state,
      mapsForm: null,
    };
  }
}

/* eslint-disable */
export function clearRegistry(action, state) {
  const { from, to } = getFromTo(action, state);
  let filterValues;
  if (
    (from === 'registry' && to === 'card') ||
    (from === 'card' && to === 'registry')
  ) {
    filterValues = state.filterValues;
  }
  return {
    ...state,
    filterValues,
    activePage: to,
  };
}

const getFromTo = (action, state) => ({
  from: state.activePage,
  to: defineGoToLocation(action.payload.pathname),
});

const defineGoToLocation = (pathname) =>
  pathname.startsWith('/ogh/') ||
  pathname.startsWith('/tech/') ||
  pathname.startsWith('/pgm/')
    ? 'card'
    : pathname.startsWith('/') || pathname === '/'
    ? 'map'
    : /\/r\/ogh\/[0-9]+$/.test(pathname)
    ? 'registry'
    : 'other';
