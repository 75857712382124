import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const OGH_STATUS_NAME = 'stateId';

/**
 * ## Статус ОГХ.
 *
 * @param {*} props - The props.
 * @param {Function} props.filter - The props.filter.
 * @returns {JSX.Element}
 */
export const OghStatus = ({ filter = (_) => _, ...props }) => {
  const dict = 'oghStatuses';
  const { data = [] } = useGetDictionary(dict);

  const options = filter(data);

  return (
    <Select
      {...props}
      {...{ options }}
      label={'Статус'}
      name={OGH_STATUS_NAME}
    />
  );
};
