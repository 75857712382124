/* eslint-disable  */
import { fetchFileUpload } from 'app/api/upload';

/**
 * Загрузка файла на сервер.
 *
 * @param {File} file - Файл для загрузки на сервер.
 * @param {{}} [additional] - Дополнительные параметры.
 * @returns {Promise<{fileId: string}>}
 */
export const uploadFile = async (file, additional) => {
  if (!file) {
    return {};
  }
  const formData = new FormData();
  if (additional) {
    formData.append('additional', JSON.stringify(additional));
  }
  formData.append('body', file);

  return await fetchFileUpload(formData);
};

/**
 * Загрузка файлов на сервер.
 *
 * @param {Array<File>} files - Файлы для загрузки на сервер.
 * @returns {Promise<Array<{fileId: string}>>}
 * @example ------
 *  try {
 *     const whenCompleted = await uploadFiles(filesNotUploaded);
 *     const fileIds = whenCompleted.map(
 *       (item: { fileId: string }) => item.fileId,
 *     );
 *
 *     return fileIds;
 *   } catch (e) {
 *     log.error(e);
 *   }
 */
export const uploadFiles = (files) =>
  Promise.all(files.map((f) => uploadFile(f)));
