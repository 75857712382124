import { RANDOM_ID } from 'core/forTesting/constants';
/* eslint-disable */
import { shallow } from 'enzyme';

import { LawnList } from './LawnList';
import { TableLawnList } from './Table.LawnList';

jest.mock('./Table.LawnList', () => ({
  /**
   *
   */
  TableLawnList: () => <div />,
}));
jest.mock('../Accordion.PlantationTab');

describe('file LawnList.spec', () => {
  it('simple text', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const wrapper = shallow(<LawnList />);
    // Assert
    expect(wrapper.text()).toBe('<AccordionPlantationTab />');
  });

  it('should props', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const EXPANDED = 'EXPANDED_' + RANDOM_ID;
    const ON_CHANGE = jest.fn();
    // Act
    const wrapper = shallow(
      <LawnList
        onChange={ON_CHANGE}
        expanded={EXPANDED}
        RANDOM_ID={RANDOM_ID}
      />,
    );

    // Assert
    expect(wrapper.prop('code')).toBe('lawn_list');
    expect(wrapper.prop('title')).toBe(
      'Ведомость учета зеленых насаждений (газон)',
    );
    expect(wrapper.prop('Table')).toBe(TableLawnList);
    expect(wrapper.prop('expanded')).toBe(EXPANDED);
    expect(wrapper.prop('onChange')).toBe(ON_CHANGE);
    expect(wrapper.prop('RANDOM_ID')).not.toBe(RANDOM_ID);
  });
});
