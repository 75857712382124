import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { getNameFile } from 'app/pages/cardsOgh/components/tables/Documents/helpers/getNameFile';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { Field } from 'redux-form';

import { DocumentWithReduxForm } from './DocumentWithReduxForm';

const ID = 14;
const NAME = getNameFile(ID);
const TITLE = 'Иные документы';

/**
 * Таблица документов __Иные документы__.
 *
 * @param {object} props - Пропсы.
 * @param {string} [props.title] - Заголовок.
 * @returns {JSX.Element} - JSX.
 * @example
 * <OtherDocumentsRf />
 */
export const OtherDocumentsRF = ({ title = TITLE }) => {
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();
  const { editMode } = useMode();

  return (
    <Field
      title={title}
      id={ID}
      name={NAME}
      component={DocumentWithReduxForm}
      disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
    />
  );
};
