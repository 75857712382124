/* eslint-disable */
/**
 *
 * @param numbers
 * @param numbersAfterComma
 */
const sumFractionalNumbers = (numbers, numbersAfterComma = 1) => {
  return numbers.reduce((acc, it) => {
    const multiplier = Math.pow(10, numbersAfterComma);
    const sum = acc * multiplier;
    const number = parseFloat(it) * multiplier;

    acc = (sum + number) / multiplier;

    return acc.toFixed(numbersAfterComma);
  }, 0);
};

export default sumFractionalNumbers;
