/* eslint-disable */
import React from 'react';
import {
  KeyboardDateTimeStartComponent,
  KeyboardDateTimeEndComponent,
} from './inputs';

import CardHeader from 'app/components/card/common/CardHeader';

const printTechnikCardHeader = ({ card }) => {
  const name =
    card.name || (card.attribute && card.attribute.name) || 'не определено';
  return (
    <CardHeader title={name}>
      <KeyboardDateTimeStartComponent />
      <KeyboardDateTimeEndComponent />
    </CardHeader>
  );
};

 
export default printTechnikCardHeader;
