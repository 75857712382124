import { AutocompleteMultiAsync } from 'core/uiKit/inputs/selects/AutocompleteMultiAsync';
import React from 'react';

import { fetchProviders } from './api';

export const PROVIDER_LIST_ID_NAME = 'providerListId';

/**
 * ## Поставщик телеметрических услуг.
 *
 * @param {*} args - Args.
 * @param {*} args.value - Value.
 * @param {*} args.props - Properties..
 * @returns {JSX.Element}
 */
export const ProviderListId = ({ value, ...props }) => {
  return (
    <AutocompleteMultiAsync
      label={'Поставщик тел. услуг'}
      {...props}
      fetchFunction={fetchProviders}
      name={PROVIDER_LIST_ID_NAME}
      value={value || []}
    />
  );
};
