/* eslint-disable */
import React from 'react';
import { ODS_API_URL } from 'config/env';

 
export const DownloadLinkCell = ({
  row: { original: { file_id = '' } = {} } = {},
  value = '',
}) => {
  return (
    <a href={`${ODS_API_URL}/files/download/${file_id}`} download>
      {value}
    </a>
  );
};
