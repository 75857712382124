import { PlacesQuantity } from 'core/uiKit/preparedInputs/textField/PlacesQuantity/PlacesQuantity';
import React from 'react';

/**
 * Компонент обертка PlacesQuantity для Final Form.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.input - Пропсы поля.
 * @param {object} props.otherProps - Остальные пропсы.
 * @returns {JSX.Element}
 */
export const PlacesQuantityWithFF = ({ input, ...otherProps }) => {
  return <PlacesQuantity {...input} {...otherProps} />;
};
