import { toMsk77 } from './toMsk77';

/**
 * Преобразование координат точки из geoJSON в мск77.
 *
 * @param coordinates - Координаты точки.
 * @returns Координаты точки Array<number>.
 */
export const pointToMsk77 = (coordinates: Array<number>) => {
  if (!coordinates) {
    throw new Error('coordinates is null or undefined');
  }

  const length = coordinates?.length;

  if (length !== 2) {
    throw new Error(`Invalid coordinates length: ${length}`);
  }

  return toMsk77(coordinates);
};
