import { TextFieldFF } from 'core/form/finalForm/fields/default/TextFieldFF';
/* eslint-disable */

/**
 *
 * @param root0
 * @param root0.map
 */
export const TextFieldFFWrapper = ({ map }) => (
  <TextFieldFF label={map.Header} name={map.accessor} />
);
