import { useChangeFieldValueRF } from 'app/actions/odsObjectActions/useChangeFieldValueRF';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { RANDOM_BOOL } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { useSelector } from 'react-redux';

import { CleanCategoryIdField } from './CleanCategoryIdField';

jest.mock('app/pages/cardsOgh/hooks/useEditMode');
jest.mock('app/actions/odsObjectActions/useChangeFieldValueRF');
jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useSelector: jest.fn(),
}));

describe('CleanCategoryIdField', function () {
  it('test filter', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const dict = [
      { id: 1, isAvd: null, name: '1 категория' },
      { id: 2, isAvd: null, name: '2 категория' },
      { id: 3, isAvd: null, name: '3 категория' },
      { id: 4, isAvd: null, name: '4 категория' },
      { id: 5, isAvd: null, name: '5 категория' },
      { id: 6, isAvd: null, name: '6 категория' },
      { id: 8, isAvd: null, name: '7а категория' },
      { id: 9, isAvd: null, name: '7б категория' },
      { id: 10, isAvd: null, name: '8а категория' },
      { id: 11, isAvd: null, name: '8б категория' },
      { id: 12, isAvd: null, name: '8в категория' },
      { id: 13, isAvd: null, name: '9 категория' },
      { id: 7, isAvd: null, name: 'Внекатегорийная' },
      { id: 14, isAvd: 1, name: 'IА' },
      { id: 15, isAvd: 1, name: 'IБ' },
      { id: 16, isAvd: 1, name: 'IВ' },
      { id: 17, isAvd: 1, name: 'II' },
      { id: 18, isAvd: 1, name: 'III' },
      { id: 19, isAvd: 1, name: 'IV' },
      { id: 20, isAvd: 1, name: ' V' },
    ];

    useChangeFieldValueRF.mockReturnValue(jest.fn());
    useEditMode.mockReturnValue({ editMode: true });
    useSelector.mockImplementation((fn) => {
      return fn({ form: { editorCard: { values: { is_avd: false } } } });
    });
    // Act
    const wrapper = shallow(<CleanCategoryIdField />);

    const { filter } = wrapper.props();

    // Assert
    expect(wrapper.props()).toStrictEqual({
      disabled: false,
      filter,
      required: true,
    });
    expect(filter(dict)).toStrictEqual([
      { id: 1, isAvd: null, name: '1 категория' },
      { id: 2, isAvd: null, name: '2 категория' },
      { id: 3, isAvd: null, name: '3 категория' },
      { id: 4, isAvd: null, name: '4 категория' },
      { id: 5, isAvd: null, name: '5 категория' },
      { id: 6, isAvd: null, name: '6 категория' },
      { id: 8, isAvd: null, name: '7а категория' },
      { id: 9, isAvd: null, name: '7б категория' },
      { id: 10, isAvd: null, name: '8а категория' },
      { id: 11, isAvd: null, name: '8б категория' },
      { id: 12, isAvd: null, name: '8в категория' },
      { id: 13, isAvd: null, name: '9 категория' },
      { id: 7, isAvd: null, name: 'Внекатегорийная' },
    ]);
  });
  it('test filter is_avd true', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const dict = [
      { id: 1, isAvd: null, name: '1 категория' },
      { id: 2, isAvd: null, name: '2 категория' },
      { id: 3, isAvd: null, name: '3 категория' },
      { id: 4, isAvd: null, name: '4 категория' },
      { id: 5, isAvd: null, name: '5 категория' },
      { id: 6, isAvd: null, name: '6 категория' },
      { id: 8, isAvd: null, name: '7а категория' },
      { id: 9, isAvd: null, name: '7б категория' },
      { id: 10, isAvd: null, name: '8а категория' },
      { id: 11, isAvd: null, name: '8б категория' },
      { id: 12, isAvd: null, name: '8в категория' },
      { id: 13, isAvd: null, name: '9 категория' },
      { id: 7, isAvd: null, name: 'Внекатегорийная' },
      { id: 14, isAvd: 1, name: 'IА' },
      { id: 15, isAvd: 1, name: 'IБ' },
      { id: 16, isAvd: 1, name: 'IВ' },
      { id: 17, isAvd: 1, name: 'II' },
      { id: 18, isAvd: 1, name: 'III' },
      { id: 19, isAvd: 1, name: 'IV' },
      { id: 20, isAvd: 1, name: ' V' },
    ];

    useChangeFieldValueRF.mockReturnValue(jest.fn());
    useEditMode.mockReturnValue({ editMode: true });
    useSelector.mockImplementation((fn) => {
      return fn({ form: { editorCard: { values: { is_avd: true } } } });
    });
    // Act
    const wrapper = shallow(<CleanCategoryIdField />);

    const { filter } = wrapper.props();

    // Assert
    expect(wrapper.props()).toStrictEqual({
      disabled: false,
      filter,
      required: true,
    });
    expect(filter(dict)).toStrictEqual([
      { id: 14, isAvd: 1, name: 'IА' },
      { id: 15, isAvd: 1, name: 'IБ' },
      { id: 16, isAvd: 1, name: 'IВ' },
      { id: 17, isAvd: 1, name: 'II' },
      { id: 18, isAvd: 1, name: 'III' },
      { id: 19, isAvd: 1, name: 'IV' },
      { id: 20, isAvd: 1, name: ' V' },
    ]);
  });
  it('test render', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    useEditMode.mockReturnValue({ editMode: RANDOM_BOOL });
    // Act
    const wrapper = shallow(<CleanCategoryIdField />);

    const { filter } = wrapper.props();

    // Assert
    expect(wrapper.props()).toStrictEqual({
      disabled: !RANDOM_BOOL,
      filter,
      required: true,
    });
    expect(wrapper.text()).toBe('<CleanCategoryIdRf />');
  });
});
