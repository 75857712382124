/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import formatRootId from 'app/utils/formatRootId';

import 'assets/styles/common.versionProps.scss';

 
export default class VersionProps extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    versionId: PropTypes.number,
    versionPeriod: PropTypes.string,
    versionStatus: PropTypes.string,
  };

  render() {
    const { versionId, versionPeriod, versionStatus } = this.props;

    return (
      <dl className={classnames('versionProps', this.props.className)}>
        <div className="ods-version-props">
          <dt>ID:</dt>
          <dd>{formatRootId(versionId)}</dd>
        </div>
        <div className="ods-version-props">
          <dt>Период действия:</dt>
          <dd>{versionPeriod}</dd>
        </div>
        <div className="ods-version-props">
          <dt>Статус:</dt>
          <dd>{versionStatus}</dd>
        </div>
      </dl>
    );
  }
}
