import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { RANDOM_BOOL } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import { CoatingFaceTypeIdField } from './CoatingFaceTypeId.Field';

vi.mock('app/api/hooks/useGetParentCard');
vi.mock('app/pages/cardsOgh/hooks/useEditMode');

/**
 * Mock Default.
 *
 * @returns {void}
 */
const mockDefault = () => {
  useGetParentCard.mockReturnValue({});
  useEditMode.mockReturnValue({});
};

describe('spec CoatingFaceTypeIdField', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    mockDefault();

    //🔥 Act
    const wrapper = shallow(<CoatingFaceTypeIdField />);

    //❓ Assert
    expect(wrapper.text()).toBe('<CoatingFaceTypeIdRF />');
  });

  it('🧪 props', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    mockDefault();
    useEditMode.mockReturnValue({ editMode: RANDOM_BOOL });

    //🔥 Act
    const wrapper = shallow(<CoatingFaceTypeIdField />);

    //❓ Assert
    expect(wrapper.props()).toStrictEqual({
      disabled: !RANDOM_BOOL,
      filter: expect.any(Function),
    });
  });

  it('🧪 filter пустой', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    mockDefault();
    useEditMode.mockReturnValue({ editMode: RANDOM_BOOL });

    const wrapper = shallow(<CoatingFaceTypeIdField />);
    //🔥 Act
    const res = wrapper.prop('filter')([]);
    //❓ Assert
    expect(res).toStrictEqual([]);
  });

  it('🧪 filter просмотр. доступны все поля без фильтрации', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    mockDefault();

    useGetParentCard.mockReturnValue({ typeId: 38 });

    const wrapper = shallow(<CoatingFaceTypeIdField />);
    //🔥 Act
    const res = wrapper.prop('filter')(data);
    //❓ Assert
    expect(res).toStrictEqual([
      {
        code: '76',
        id: 76,
        name: 'TerraWay',
        ogh_object_type_ids: [49, 38, 40],
      },
      {
        code: 'acryl',
        id: 40,
        name: 'Акриловое (хард)',
        ogh_object_type_ids: [49, 40, 38, 39],
      },
      {
        code: 'asphalt_concrete_crumb',
        id: 79,
        name: 'Асфальтобетонная крошка',
        ogh_object_type_ids: [1],
      },
      {
        code: 'metal_grid',
        id: 73,
        name: 'Металлическая решётка',
        ogh_object_type_ids: [49],
      },
    ]);
  });

  it('🧪 filter редактирование', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    useEditMode.mockReturnValue({ editMode: true });
    useGetParentCard.mockReturnValue({ typeId: 38 });

    const wrapper = shallow(<CoatingFaceTypeIdField />);
    //🔥 Act
    const res = wrapper.prop('filter')(data);
    //❓ Assert
    expect(res).toStrictEqual([
      {
        code: '76',
        id: 76,
        name: 'TerraWay',
        ogh_object_type_ids: [49, 38, 40],
      },
      {
        code: 'acryl',
        id: 40,
        name: 'Акриловое (хард)',
        ogh_object_type_ids: [49, 40, 38, 39],
      },
    ]);
  });
});

const data = [
  {
    code: '76',
    id: 76,
    name: 'TerraWay',
    ogh_object_type_ids: [49, 38, 40],
  },
  {
    code: 'acryl',
    id: 40,
    name: 'Акриловое (хард)',
    ogh_object_type_ids: [49, 40, 38, 39],
  },
  {
    code: 'asphalt_concrete_crumb',
    id: 79,
    name: 'Асфальтобетонная крошка',
    ogh_object_type_ids: [1],
  },
  {
    code: 'metal_grid',
    id: 73,
    name: 'Металлическая решётка',
    ogh_object_type_ids: [49],
  },
];
