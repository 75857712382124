import { queryClient } from 'app/routes/QueryClientProvider';
/* eslint-disable  */
import React from 'react';

import DialogSendMatching from './DialogSendMatching';

/**
 * Компонент обертка.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - Реакт компонент.
 * @example ----
 */
const DialogSendMatchingHOC = (props) => {
  /**
   * Функция clearCashVisibilityButtonsOgh.
   *
   * @returns {Promise<void>} - Промис.
   * @example ----
   */
  const clearCashVisibilityButtonsOgh = () =>
    queryClient.invalidateQueries({ queryKey: ['getVisibilityButtonsOgh'] });
  return (
    <DialogSendMatching {...props} {...{ clearCashVisibilityButtonsOgh }} />
  );
};

export default DialogSendMatchingHOC;
