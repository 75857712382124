import { useInfiniteQuery } from '@tanstack/react-query';

// :todo Разработать полноценный адаптор.

/**
 * Адаптер Бесконечно запроса.
 *
 * @param {object} params - Параметры.
 * @returns {object}
 */
export const useInfiniteQueryAdaptor = (params) => {
  return useInfiniteQuery(params);
};
