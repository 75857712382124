import { Button } from 'core/uiKit/components';
/* eslint-disable */
import React, { useState } from 'react';

import { DialogShipment } from './Dialog.Shipment';

/**
 *
 */
export const ShipmentButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Button
        color={'success'}
        variant={'contained'}
        onClick={() => setIsOpen(true)}
      >
        Отпуск
      </Button>

      {isOpen ? (
        <DialogShipment isOpen={isOpen} onClose={() => setIsOpen(false)} />
      ) : null}
    </>
  );
};
