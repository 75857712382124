import { getRequiredDocumentCloseField } from './getRequiredDocumentCloseField';

describe('getRequiredDocumentCloseField.jestSpec', () => {
  it('should return true if SendReason contains at least one number from arr', () => {
    // Arrange
    const sendReason = [44, 50];

    // Act
    const result = getRequiredDocumentCloseField(sendReason);

    // Assert
    expect(result).toBe(true);
  });

  it('should return false if SendReason does not contain any number from arr', () => {
    // Arrange
    const sendReason = [51, 52];

    // Act
    const result = getRequiredDocumentCloseField(sendReason);

    // Assert
    expect(result).toBe(false);
  });

  it('should return false if SendReason is not provided', () => {
    // Arrange

    // Act
    const result = getRequiredDocumentCloseField();

    // Assert
    expect(result).toBe(false);
  });

  it('should return false if SendReason array is empty', () => {
    // Arrange
    const sendReason = [1];

    // Act
    const result = getRequiredDocumentCloseField(sendReason);

    // Assert
    expect(result).toBe(false);
  });
});
