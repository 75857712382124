import { YesOrNoCell } from 'core/uiKit/components/cells/YesOrNo.Cell';

 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __В одном уровне__.
 *
 * @type {{ accessor: 'is_isolated', Header: 'В одном уровне', Cell: YesOrNoCell }}
 * @augments Cell
 */
export const is_isolated = {
  accessor: 'is_isolated',
  Header: 'В одном уровне',
  Cell: YesOrNoCell,
  sortable: true,
};
