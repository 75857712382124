import {
  DynamicObjectEventTable,
  Map,
  MapPointerEvent,
} from '@2gis/mapgl/types';
import { Evented } from '@2gis/mapgl/types/utils/evented';
import bbox from '@turf/bbox';
import center from '@turf/center';
import {
  BBox,
  Feature,
  LineString,
  lineString,
  polygon,
  Position,
} from '@turf/helpers';
import {
  booleanPointInPolygon,
  nearestPointOnLine,
  polygonToLineString,
} from '@turf/turf';
import { getMarkerIcons } from 'app/services/mapService/pointMarkers';
import { log } from 'core/utils/log';

import { findEditableGeometries } from '../../MapglEditorContextProvider';
import { GeometryUtils, getGeometriesToSave } from '..';
import MarkerVertex from '../DrawService/utils/MarkerVertex';
import Vertex from '../DrawService/utils/Vertex';
import GeometryFactory from '../GeometryFactory';
import { MapService } from '../MapService';
import { Polyline } from '../Polyline';
import {
  DrawGeometryObjects,
  GeometryTypes,
  Marker,
  Polygon,
  RawGeometry,
  ShapeEditorOnChangeEvent,
  UserData,
} from '../types.d';
import { isGeometryPolygon } from './isGeometryPolygon';
import { isGeometryPolyline } from './isGeometryPolyline';
import { EditorChangeEventHandler, EditorFinishHandler } from './ShapeEditor.d';
import Box from './utils/Box';
import Shape from './utils/Controls/Shape';
import Mover from './utils/Mover';
import Resizer from './utils/Resizer';
import Rotator from './utils/Rotator';
import Side from './utils/Side';

type Polyline_T = Evented<DynamicObjectEventTable> &
  Omit<Polyline, 'userData' | 'map' | 'options' | 'mapService'> &
  Evented<DynamicObjectEventTable> & {
    userData: UserData<GeometryTypes.Polyline>;
    destroy: VoidFunction;
  };

/**
 * Редактор геометрий.
 */
export default class ShapeEditor {
  //

  /**
   * Получает координаты ротатора со смещением от правого нижнего угла.
   *
   * @param map - Карта.
   * @param bounds - Границы геометрии.
   * @returns Array.
   */
  public static getRotatorCoordinates(map: Map, bounds: BBox) {
    const rotatorAnchor = [bounds[2], bounds[1]];
    const rotatorAnchorInPixels = map.project(rotatorAnchor);
    const rotatorXShift = 14;
    // Нижний правый угол + смещение вправо
    const rotatorShiftedAnchor = map.unproject([
      rotatorAnchorInPixels[0] + rotatorXShift,
      rotatorAnchorInPixels[1],
    ]);

    return rotatorShiftedAnchor;
  }

  public map: Map;

  public mapService: MapService;

  public resizer: Resizer | null = null;

  public mover: Mover | null = null;

  public box: Box | null = null;

  public rotator: Rotator | null = null;

  /**
   * Контрол новое вершины. При ее перетягивании создается новая вершина.
   */
  public vertex: Vertex | null = null;

  public markerVertex: MarkerVertex | null = null;

  public geometry: Polyline | Polygon | Marker | null = null;

  public geometryUtils: GeometryUtils;

  public hoveredControl: Rotator | Mover | Resizer | Vertex | null = null;

  public hoveredSide: Side | null = null;

  public appendedVertexes: Vertex[] = [];

  public sides: Side[] = [];

  public isChanging: boolean = false;

  public interactingShape: { shape: Shape | null } = {
    shape: null,
  };

  public isEditingAllowed: boolean = true;

  public isVertexMoving: boolean = false;

  public editableGeometries: {
    point: Marker[];
    polygon: Polygon[];
    polyline: Polyline_T[];
  } | null = null;

  private mouseMoveHandler: ((event: MapPointerEvent) => void) | null = null;
  private vertexMoveHandler: ((event: MapPointerEvent) => void) | null = null;
  private vertexMouseDownHandler: ((event: MapPointerEvent) => void) | null =
    null;

  private activeHandlers: {
    // eslint-disable-next-line
    handler: (...args: any[]) => any;
    // eslint-disable-next-line
    target: any;
    type: string;
  }[] = [];

  /**
   * Конструктор.
   *
   * @param map - Карта.
   * @param mapService - Сервис для работы с картой.
   * @param geometryUtils - Утилиты для работы с геометрией.
   */
  constructor(map: Map, mapService: MapService, geometryUtils: GeometryUtils) {
    this.map = map;
    this.mapService = mapService;
    this.geometryUtils = geometryUtils;
  }

  /**
   * Разрешает редактирование геометрий.
   *
   */
  public allowEditing() {
    this.isEditingAllowed = true;
  }

  /**
   * Запрещает редактирование геометрий.
   *
   */
  public disallowEditing() {
    this.isEditingAllowed = false;
  }

  /**
   * Обновляет стороны геометрий.
   *
   * @param geometry - Геометрия.
   * @returns Array.
   */
  public updateSides(geometry: Polygon | Polyline) {
    const isPolygon = isGeometryPolygon(geometry);

    const coordinates = isPolygon
      ? geometry.userData.coordinates.reduce((acc, polygon) => {
          return [...acc, ...polygon];
        }, [] as number[][])
      : geometry.userData.coordinates;

    this.sides = isPolygon
      ? (geometry as Polygon).userData.coordinates.reduce(
          (acc, polygon, subGeometryIndex) => {
            polygon.forEach((coordinates, index) => {
              const endIndex = index + 1;
              if (endIndex !== polygon.length) {
                acc.push(
                  new Side(
                    [coordinates, polygon[endIndex]],
                    index,
                    endIndex,
                    subGeometryIndex,
                    this.geometryUtils,
                  ),
                );
              }
            });

            return acc;
          },
          [] as Side[],
        )
      : coordinates.reduce((acc, coords, index, arr) => {
          const endIndex = index + 1;
          if (endIndex !== arr.length) {
            acc.push(
              new Side(
                [coords, arr[endIndex]],
                index,
                endIndex,
                0,
                this.geometryUtils,
              ),
            );
          }

          return acc;
        }, [] as Side[]);

    return this.sides;
  }

  /**
   * Обновляет вершины геометрий.
   *
   * @param geometry - Геометрия.
   * @returns Array.
   */
  public updateVertexes(geometry?: Polygon | Polyline | Marker) {
    if (!geometry && this.geometry) geometry = this.geometry;

    if (!geometry) return this.appendedVertexes;

    const isPolygon = isGeometryPolygon(geometry);
    const isPolyline = !isPolygon && isGeometryPolyline(geometry);

    const coordinates = isPolygon
      ? (geometry as Polygon).userData.coordinates.reduce((acc, polygon) => {
          return [...acc, ...polygon];
        }, [] as number[][])
      : geometry.userData.coordinates;

    if (!isPolygon && !isPolyline) {
      this.appendedVertexes = [
        new MarkerVertex(
          geometry,
          geometry.userData.coordinates as number[][],
          geometry.userData.coordinates as number[],
          this.map,
          0,
          0,
          this.mapService,
          this.geometryUtils,
          {
            isAppended: true,
            isVisible: false,
          },
        ),
      ];

      return this.appendedVertexes;
    }

    this.appendedVertexes = isPolygon
      ? (geometry as Polygon).userData.coordinates.reduce(
          (acc, polygonCoords, subGeometryIndex) => {
            const vertexes = polygonCoords.map(
              (coords, coordIndex) =>
                new Vertex(
                  geometry,
                  // @ts-ignore
                  geometry.userData.coordinates as number[][],
                  coords,
                  this.map,
                  coordIndex,
                  subGeometryIndex,
                  this.mapService,
                  this.geometryUtils,
                  {
                    isAppended: true,
                    isVisible: false,
                  },
                ),
            );
            return [...acc, ...vertexes];
          },
          [] as Vertex[],
        )
      : geometry.userData.coordinates.map(
          (coords, index) =>
            new Vertex(
              geometry,
              coordinates as number[][],
              coords as number[],
              this.map,
              index,
              0,
              this.mapService,
              this.geometryUtils,
              {
                isAppended: true,
                isVisible: false,
              },
            ),
        );

    return this.appendedVertexes;
  }

  /**
   * Обновляет контролы геометрий.
   *
   * @param geometry - Геометрия.
   */
  public updateControls(geometry: Polyline | Polygon) {
    const isPolygon = isGeometryPolygon(geometry);

    const coordinates = isPolygon
      ? geometry.userData.coordinates.reduce((acc, polygon) => {
          return [...acc, ...polygon];
        }, [] as number[][])
      : geometry.userData.coordinates;

    const bounds = bbox(lineString(coordinates as Array<number>[]));

    this.box = new Box(this.map, bounds);
    this.resizer = new Resizer(this.map, bounds, this.geometryUtils, this);

    const moveCenterPolygon = isPolygon
      ? geometry.userData.coordinates
      : coordinates.length < 3
      ? [[...coordinates, coordinates[1], coordinates[0]]]
      : [[...coordinates, coordinates[0]]];

    this.mover = new Mover(
      this.map,
      center(polygon(moveCenterPolygon)).geometry.coordinates,
      this.geometryUtils,
      this,
    );

    const rotatorAnchor = ShapeEditor.getRotatorCoordinates(this.map, bounds);

    this.rotator = new Rotator(
      this.map,
      rotatorAnchor,
      this.geometryUtils,
      this,
    );

    // const geometryCoordinates
    this.vertex = new Vertex(
      geometry || this.geometry,
      (isPolygon
        ? geometry.userData.coordinates[0]
        : geometry.userData.coordinates) as number[][],
      [0, 0],
      this.map,
      0,
      0,
      this.mapService,
      this.geometryUtils,
      {
        isAppended: false,
        isVisible: false,
      },
    );
  }

  /**
   * Start editing the geometry.
   *
   * @param geometry - The geometry to edit.
   */
  public startEditingGeometry(geometry: Polygon | Polyline | Marker) {
    this.geometry = geometry;

    if (this.geometry.userData.type === GeometryTypes.Point) {
      (this.geometry as Marker).setIcon({
        ...GeometryFactory.hoveredOptions.Marker,
        icon: getMarkerIcons(this.geometry.userData.type_id).hoverIcon,
      });

      this.updateVertexes(geometry);
    } else {
      this.updateControls(geometry as Polygon | Polyline);
      this.updateVertexes(geometry as Polygon | Polyline);
      this.updateSides(geometry as Polygon | Polyline);

      if (Shape.mapgl) {
        geometry.destroy();
        this.geometry = this.createGeometry(
          geometry,
          geometry.userData.coordinates,
          Shape.mapgl,
        ) as Polygon | Polyline;
      }
    }

    if (this.mouseMoveHandler) {
      this.map.off('mousemove', this.mouseMoveHandler);
      this.activeHandlers = this.activeHandlers.filter(
        ({ handler }) => handler !== this.mouseMoveHandler,
      );
    }

    this.mouseMoveHandler = this.onMouseMoveOnMap.bind(this);

    this.map.on('mousemove', this.mouseMoveHandler);
    this.activeHandlers.push({
      handler: this.mouseMoveHandler,
      target: this.map,
      type: 'mousemove',
    });

    // }
  }

  /**
   * Enable editing.
   *
   * @param options - Options.
   * @param [options.onChange] - On change handler.
   * @param [options.onPointAdded] - On point added handler.
   * @param [options.onStepFinish] - On step finish handler.
   * @param [options.onEditFinish] - On edit finish handler.
   */
  public enableEditing({
    onChange,
    onEditFinish,
  }: {
    onChange?: EditorChangeEventHandler;
    onPointAdded?: EditorFinishHandler;
    onStepFinish?: EditorFinishHandler;
    onEditFinish?: EditorFinishHandler;
  } = {}) {
    if (!this.isEditingAllowed) {
      log.warn('Editing is not allowed');
      return;
    }

    let handleChangeGeometry: ((event: MapPointerEvent) => void) | null = null;
    let handleMouseUp: VoidFunction | null = null;

    const activeHandlers = [];

    if (this.geometryUtils) {
      //

      /**
       * Handle mouse down event on the map.
       *
       * @param {MapPointerEvent} lngLat - The longitude and latitude of the mouse pointer event on the map.
       */
      const handleMouseDown = ({ lngLat }: MapPointerEvent) => {
        const editableGeometries = findEditableGeometries(
          this.mapService,
          this.mapService.isEditing,
        )();
        if (!editableGeometries) return;

        const clickedGeometry =
          editableGeometries.point?.find((point) =>
            this.geometryUtils.isPointWithinPolygon(
              lngLat,
              point.userData.coordinatesToCheckMouseEvent,
            ),
          ) ||
          editableGeometries.polyline?.find((polyline) =>
            this.geometryUtils.isPointOnLine(
              lngLat,
              polyline.userData.coordinatesToCheckMouseEvent,
            ),
          ) ||
          editableGeometries.polygon?.find((polygon) =>
            this.geometryUtils.isPointWithinPolygon(
              lngLat,
              polygon.userData.coordinatesToCheckMouseEvent,
            ),
          );

        const clickedControl = this.hoveredControl;

        // если клик произошел на контроле редактора, то отключаем перетаскивание карты мышкой
        if (clickedControl) {
          if ('onClick' in clickedControl) {
            clickedControl.onClick?.({
              controls: {
                mover: this.mover!,
                resizer: this.resizer!,
                rotator: this.rotator!,
              },
            });
          }

          if (this.hoveredControl === this.vertex) {
            this.isVertexMoving = true;
          }

          this.mapService.map.setOption('disableDragging', true);
          this.interactingShape.shape =
            ('hoveredShape' in clickedControl
              ? (clickedControl.hoveredShape || [])[1]
              : clickedControl.shape) || null;

          const clickedGeometryLayer = this.geometry?.userData.layerType;
          const clickedGeometryType = this.geometry?.userData.type;

          if (!clickedGeometryLayer || !clickedGeometryType) return;

          const geometryId = this.geometry?.userData.id;
          const currentGeometry = this.mapService.layers[clickedGeometryLayer][
            clickedGeometryType
          ].find((geometry) => geometry.userData.id === geometryId);

          /**
           * Handle mouse move event on the map.
           *
           * @param root0 - Event.
           * @param root0.lngLat - Long and lat.
           */
          handleChangeGeometry = ({ lngLat }: MapPointerEvent) => {
            if (!this.interactingShape.shape) return;

            if (currentGeometry?.userData.type !== GeometryTypes.Point) {
              currentGeometry?.userData.areaOrPerimeterHint?.destroy();
              currentGeometry?.destroy();
            }

            this.geometry = this.onChange({
              coordinates: lngLat,
              geometry: this.geometry!,
            }) as RawGeometry;

            onChange?.(this.geometry);
          };

          this.mapService.map.on('mousemove', handleChangeGeometry);
          this.activeHandlers.push({
            handler: handleChangeGeometry,
            target: this.map,
            type: 'mousemove',
          });

          /**
           * Handle mouse up event on the map.
           *
           */
          handleMouseUp = () => {
            this.isChanging = false;
            this.isVertexMoving = false;

            if (handleChangeGeometry) {
              this.mapService.map.off('mousemove', handleChangeGeometry);
            }

            let newGeometry = this.geometry!;
            const { type, id } = newGeometry.userData;
            const currentEditableGeometries = findEditableGeometries(
              this.mapService,
              this.mapService.isEditing,
            )();
            const newEditableGeometries = {
              point: [],
              polygon: [],
              polyline: [],
              ...currentEditableGeometries,
              [type]: editableGeometries[type].map((geometry) =>
                geometry.userData.id === id ? newGeometry : geometry,
              ),
            };

            newGeometry.userData.areaOrPerimeterHint?.destroy();

            if (handleMouseUp) {
              document.removeEventListener('mouseup', handleMouseUp);
              this.activeHandlers = this.activeHandlers.filter(
                (handler) => handler.handler !== handleMouseUp,
              );
            }

            this.mapService.map.setOption('disableDragging', false);

            if ('onMoveEnd' in clickedControl) {
              clickedControl.onMoveEnd?.({
                box: this.box!,
                controls: {
                  mover: this.mover!,
                  resizer: this.resizer!,
                  rotator: this.rotator!,
                },
              });
            }

            if (newGeometry.userData.type !== GeometryTypes.Marker) {
              newGeometry.destroy();
            }

            this.saveChangedGeometries(newEditableGeometries);
            this.destroyControls(false);

            if (this.geometry) {
              newGeometry = this.geometry;
            }

            const clickedGeometryAfterMapRerendered = findEditableGeometries(
              this.mapService,
              this.mapService.isEditing,
            )()?.[newGeometry.userData.type].find(
              (geometry) => geometry.userData.id === newGeometry.userData.id,
            );

            if (!clickedGeometryAfterMapRerendered) return;

            this.startEditingGeometry(clickedGeometryAfterMapRerendered);
          };

          this.isChanging = true;

          document.addEventListener('mouseup', handleMouseUp);
          activeHandlers.push({
            handler: handleMouseUp,
            target: document,
            type: 'mouseup',
          });

          /**
           * Удаление вершины из геометрии.
           *
           */
          const handleDblClick = () => {
            const currentZoom = this.map.getZoom();
            this.map.setMaxZoom(currentZoom!);

            if (
              this.geometry &&
              this.hoveredControl &&
              this.hoveredControl instanceof Vertex &&
              this.hoveredControl.isAppended
            ) {
              const newGeometryCoordinates = this.hoveredControl.remove();
              this.hoveredControl.destroy();
              this.setHoveredControl(null);

              const geometry = this.geometry;

              if (Shape.mapgl) {
                geometry?.destroy();
                const newGeometry = this.createGeometry(
                  geometry,
                  newGeometryCoordinates as (number | Position)[],
                  Shape.mapgl,
                );

                this.geometry = newGeometry as Polyline | Polygon;

                if (this.vertex) {
                  this.vertex.geometry = newGeometry as RawGeometry;
                  this.vertex.geometryCoordinates =
                    newGeometry.userData.coordinates;
                }

                this.destroyControls();
                this.updateControls(newGeometry as Polyline | Polygon);
                this.updateSides(newGeometry as Polyline | Polygon);
                this.updateVertexes(newGeometry as RawGeometry);
              }

              handleMouseUp?.();
            }

            this.map?.setMaxZoom(30);
          };

          const mapContainer = this.map.getContainer();
          mapContainer.addEventListener('dblclick', handleDblClick);
          this.activeHandlers.push({
            handler: handleDblClick,
            target: mapContainer,
            type: 'dblclick',
          });

          return;
        }

        // если клик произошел на контроле редактора или текущей выделенной геометрии, то не делаем ничего
        if (
          clickedControl ||
          (clickedGeometry &&
            this.geometry &&
            clickedGeometry?.userData.id === this.geometry?.userData.id)
        ) {
          return;
        }

        // создаем редактор, если клик произошел на геометрии
        if (clickedGeometry && !this.geometry) {
          clickedGeometry.userData.isChanging = true;
          clickedGeometry.userData.isEditing = true;

          this.startEditingGeometry(clickedGeometry);

          return;
        }

        // удаляем редактор, если клик произошел не на геометрии и не на контроле редактора
        if (!clickedControl && !clickedGeometry && this.geometry) {
          const newGeometry = this.geometry;

          if (newGeometry) {
            newGeometry.userData.isChanging = false;
            newGeometry.userData.isEditing = false;
            const { type, id } = newGeometry.userData;
            const currentEditableGeometries = findEditableGeometries(
              this.mapService,
              this.mapService.isEditing,
            )();
            const newEditableGeometries = {
              point: [],
              polygon: [],
              polyline: [],
              ...currentEditableGeometries,
              [type]: currentEditableGeometries?.[type].map((geometry) =>
                geometry.userData.id === id ? newGeometry : geometry,
              ),
            };

            onEditFinish?.(newEditableGeometries);
          }

          this.mapService.finishEditing();

          if (handleChangeGeometry) {
            this.mapService.map.off('mousemove', handleChangeGeometry);
            this.activeHandlers = this.activeHandlers.filter(
              (handler) => handler.handler !== handleChangeGeometry,
            );
            handleChangeGeometry = null;
          }
          return;
        }

        // пересоздаем контролы, если кликнута не текущая выделенная геометрия
        if (clickedGeometry && this.geometry) {
          const newGeometry = this.geometry;
          newGeometry.userData.isChanging = false;
          newGeometry.userData.isEditing = false;

          const { type, id } = newGeometry.userData;
          const currentEditableGeometries = findEditableGeometries(
            this.mapService,
            this.mapService.isEditing,
          )();
          const newEditableGeometries = {
            point: [],
            polygon: [],
            polyline: [],
            ...currentEditableGeometries,
            [type]: currentEditableGeometries?.[type].map((geometry) =>
              geometry.userData.id === id ? newGeometry : geometry,
            ),
          };

          onEditFinish?.(newEditableGeometries);

          this.finishEditingGeometry();
          this.saveChangedGeometries(newEditableGeometries);

          // @ts-ignore
          const clickedGeometryAfterMapRerendered = findEditableGeometries(
            this.mapService,
            this.mapService.isEditing,
          )()?.[clickedGeometry.userData.type].find(
            // @ts-ignore
            (geometry) => geometry.userData.id === clickedGeometry.userData.id,
          );

          if (!clickedGeometryAfterMapRerendered) return;

          clickedGeometryAfterMapRerendered.userData.isChanging = true;
          clickedGeometryAfterMapRerendered.userData.isEditing = true;

          this.startEditingGeometry(clickedGeometryAfterMapRerendered);
        }
      };

      this.mapService.map.on('mousedown', handleMouseDown);
      this.activeHandlers.push({
        handler: handleMouseDown,
        target: this.mapService.map,
        type: 'mousedown',
      });
    }
  }

  /**
   * Уничтожает все контролы редактора.
   *
   * @param shouldResetCursorStyle - Нужно ли сбросить стиль курсора.
   */
  public destroyControls(shouldResetCursorStyle = true) {
    this.mover?.destroy();
    this.resizer?.destroy();
    this.rotator?.destroy();
    this.box?.destroy();
    // this.vertex?.destroy();

    if (shouldResetCursorStyle) {
      this.mover?.resetCursorStyle();
      this.resizer?.resetCursorStyle();
      this.rotator?.resetCursorStyle();
    }
  }

  /**
   * Завершает редактирование геометрии.
   *
   * @param options - Опции.
   * @param options.shouldDestroyGeometry - Нужно ли уничтожить геометрию.
   */
  public finishEditingGeometry(
    options: { shouldDestroyGeometry?: boolean } = {
      shouldDestroyGeometry: true,
    },
  ) {
    const { shouldDestroyGeometry } = options;

    this.destroyControls();

    if (shouldDestroyGeometry) {
      this.geometry?.destroy();
      this.geometry = null;
    }

    this.mapService.map.off('mousemove', this.onMouseMoveOnMap);
    this.activeHandlers = this.activeHandlers.filter(
      (handler) => handler.handler !== this.onMouseMoveOnMap,
    );

    if (this.vertexMoveHandler) {
      this.map.off('mousemove', this.vertexMoveHandler);
      this.activeHandlers = this.activeHandlers.filter(
        (handler) => handler.handler !== this.vertexMoveHandler,
      );
      this.vertexMoveHandler = null;
    }

    if (this.vertexMouseDownHandler) {
      this.map.off('mouseup', this.vertexMouseDownHandler);
      this.activeHandlers = this.activeHandlers.filter(
        (handler) => handler.handler !== this.vertexMouseDownHandler,
      );
      this.vertexMouseDownHandler = null;
    }

    if (this.mouseMoveHandler) {
      this.map.off('mousemove', this.mouseMoveHandler);
      this.activeHandlers = this.activeHandlers.filter(
        (handler) => handler.handler !== this.mouseMoveHandler,
      );
    }
  }

  /**
   * Отключает редактирование.
   *
   
   */
  public disableEditing() {
    this.finishEditingGeometry();
    this.removeActiveHandlers();
  }

  /**
   * Удаляет геометрию.
   *
   * @param selectedId - Идентификатор геометрии.
   */
  public deleteGeometry(selectedId?: string | number) {
    selectedId = selectedId || this.geometry?.userData.id;

    if (!selectedId) return;

    const editableGeometries = findEditableGeometries(
      this.mapService,
      this.mapService.isEditing,
    )();
    if (!editableGeometries) return;

    const geometryToDelete =
      editableGeometries.polygon.find(
        (polygon) => polygon.userData.id === selectedId,
      ) ||
      editableGeometries.polyline.find(
        (polyline) => polyline.userData.id === selectedId,
      ) ||
      editableGeometries.point.find(
        (point) => point.userData.id === selectedId,
      );

    if (!geometryToDelete) return;

    const { type, id, layerType } = geometryToDelete.userData;
    geometryToDelete.destroy();
    geometryToDelete.userData.areaOrPerimeterHint?.destroy();

    if (this.geometry && selectedId === this.geometry.userData.id) {
      this.mapService.erase(this.geometry);
    }

    const newEditableGeometries = {
      ...editableGeometries,
      layerType,
      // @ts-ignore
      [type]: editableGeometries[type].filter(
        // @ts-ignore
        (geometry) => geometry.userData.id !== id,
      ),
    };

    this.finishEditingGeometry();
    this.saveChangedGeometries(newEditableGeometries);

    this.geometry = null;
  }

  /**
   * Устанавливает наведенный контрол.
   *
   * @param hoveredControl - Наведенный контрол.
   */
  public setHoveredControl(
    hoveredControl: Rotator | Mover | Resizer | Vertex | null,
  ) {
    this.hoveredControl = hoveredControl;
  }

  /**
   * Преобразование координат полигона в координаты линии.
   *
   * @param polygonCoordinates - Координаты полигона.
   * @returns Координаты линии.
   */
  public static polygonToLineCoordinates(
    polygonCoordinates: Array<number>[][],
  ): Array<number>[] {
    return polygonCoordinates.reduce((acc, polygon) => {
      return [...acc, ...polygon];
    }, [] as Array<number>[]);
  }

  /**
   * Обработчик.
   *
   * @param event - Аргументы.
   * @param event.geometry - Геометрия.
   * @param event.coordinates - Координаты точки.
   * @returns Обновленная геометрия.
   */
  public onChange({ geometry, coordinates }: ShapeEditorOnChangeEvent) {
    if (!this.interactingShape.shape) return geometry;

    const { shape } = this.interactingShape;
    const control = shape.getControl();

    const newGeometryCoordinates = control.onChange({
      box: this.box!,
      controls: {
        mover: this.mover!,
        resizer: this.resizer!,
        rotator: this.rotator!,
      },
      coordinates,
      geometry,
      // @ts-expect-error
      interactingShape: this.interactingShape!,
    });

    if (geometry.userData.type === GeometryTypes.Point) {
      (geometry as Marker).setCoordinates(newGeometryCoordinates as number[]);
      geometry.userData.coordinates = newGeometryCoordinates as number[];
      geometry.userData.recalcHoverArea?.();

      return geometry;
    }

    if (Shape.mapgl) {
      geometry.destroy();
      const newGeometry = this.createGeometry(
        geometry,
        newGeometryCoordinates as (number | Position)[],
        Shape.mapgl,
      );

      if (this.vertex) {
        this.vertex.geometry = newGeometry as RawGeometry;
        this.vertex.geometryCoordinates = newGeometry.userData.coordinates;
      }

      return newGeometry;
    }

    return geometry;
  }

  /**
   * Создание геометрии.
   *
   * @param geometry - Изменяемая геометрия.
   * @param newGeometryCoordinates - Новые координаты геометрии.
   * @param mapglApi - Апи карты.
   * @returns Обновленная геометрия.
   */
  createGeometry(
    geometry: ShapeEditorOnChangeEvent['geometry'],
    newGeometryCoordinates: (number | Position)[][] | (number | Position)[],
    // @ts-ignore
    mapglApi: typeof mapgl,
  ) {
    const isPolygon = isGeometryPolygon(geometry);

    const newGeometry = isPolygon
      ? new mapglApi.Polygon(this.map, {
          ...GeometryFactory.changingOptions.Polygon,
          coordinates: newGeometryCoordinates as Array<number>[][],
          userData: {
            ...GeometryFactory.changingOptions.Polygon.userData,
            ...geometry.userData,
            coordinates: newGeometryCoordinates,
            coordinatesToCheckMouseEvent: newGeometryCoordinates,
            type: GeometryTypes.Polygon,
          },
        })
      : new Polyline(this.map, {
          ...GeometryFactory.changingOptions.Polyline,
          coordinates: newGeometryCoordinates as Array<number>[],
          mapService: this.mapService,
          userData: {
            ...GeometryFactory.changingOptions.Polyline.userData,
            ...(geometry as Polyline).userData,
            coordinates: newGeometryCoordinates as Array<number>[],
            coordinatesToCheckMouseEvent:
              newGeometryCoordinates as Array<number>[],
            type: GeometryTypes.Polyline,
          },
        });

    const { areaOrPerimeterHint } = geometry.userData;

    if (areaOrPerimeterHint) {
      if (isPolygon) {
        const geometryArea = MapService.getPolygonArea(
          geometry.userData.coordinates,
        );
        const polygonCenter = MapService.getPolygonCenter(
          geometry.userData.coordinates,
        );
        const polygonFeature = polygon(geometry.userData.coordinates);
        const polygonAsLineString = polygonToLineString(
          polygonFeature,
        ) as Feature<LineString>;

        let hintPointFeature = polygonCenter;

        // Если хинт расположен не внутри полигона, то помещаем
        // его в ближайшую точку полигона
        if (!booleanPointInPolygon(polygonCenter, polygonFeature)) {
          const nearestPoint = nearestPointOnLine(
            polygonAsLineString,
            polygonCenter,
          );
          hintPointFeature = nearestPoint;
        }

        // eslint-disable-next-line
        const [x, y] = this.map.project(hintPointFeature.geometry.coordinates);

        areaOrPerimeterHint.setText(
          `${Math.round(geometryArea * 100) / 100} кв. м`,
        );
        // eslint-disable-next-line
        areaOrPerimeterHint.setPosition(x, y - 16);
      } else {
        const polylineLengthInMeters = MapService.getLinePerimeter(
          geometry.userData.coordinates as number[][],
        );
        const lineCenter = MapService.getLineCenter(
          geometry.userData.coordinates as number[][],
        );
        // eslint-disable-next-line
        const [x, y] = this.map.project(lineCenter);

        areaOrPerimeterHint.setText(
          `${Math.round(polylineLengthInMeters * 100) / 100} м`,
        );
        // eslint-disable-next-line
        areaOrPerimeterHint.setPosition(x + 10, y - 15 - 16);
      }

      areaOrPerimeterHint.restore(this.map.getContainer());
    }

    return newGeometry;
  }

  /**
   * Сохраняет измененные геометрии.
   *
   * @param newGeometries - Новые геометрии.
   * @param newGeometries.point - Точки.
   * @param newGeometries.polygon - Полигоны.
   * @param newGeometries.polyline - Полилинии.
   * @param newGeometries.layerType - Тип слоя.
   * @param shouldDrawNewGeometries - Нужно ли рисовать новые геометрии.
   */
  public saveChangedGeometries(
    newGeometries: {
      point: Marker[];
      polygon: Polygon[];
      polyline: Polyline[];
      layerType?: 'parent' | 'children';
    },
    shouldDrawNewGeometries = true,
  ) {
    const layerType =
      newGeometries.layerType || this.mapService.selectedLayerType;

    if (!layerType) {
      log.error('Selected layer type is null');
      return;
    }

    const currentParentLoadedGeometries = {
      ...this.mapService.geometriesData.parent,
    };
    const currentChildrenLoadedGeometries = {
      ...this.mapService.geometriesData.children,
    };

    const { parent: parentPoints, children: childrenPoints } =
      getGeometriesToSave<GeometryTypes.Point>(
        this.mapService,
        newGeometries.point,
        GeometryTypes.Point,
        currentChildrenLoadedGeometries,
        currentParentLoadedGeometries,
        layerType,
      );

    const { parent: parentPolylines, children: childrenPolylines } =
      getGeometriesToSave<GeometryTypes.Polyline>(
        this.mapService,
        newGeometries.polyline,
        GeometryTypes.Polyline,
        currentChildrenLoadedGeometries,
        currentParentLoadedGeometries,
        layerType,
      );

    const { parent: parentPolygons, children: childrenPolygons } =
      getGeometriesToSave<GeometryTypes.Polygon>(
        this.mapService,
        newGeometries.polygon,
        GeometryTypes.Polygon,
        currentChildrenLoadedGeometries,
        currentParentLoadedGeometries,
        layerType,
      );

    this.mapService.updateGeometriesData({
      children: [...childrenPoints, ...childrenPolylines, ...childrenPolygons],
      parent: [...parentPoints, ...parentPolylines, ...parentPolygons],
    } as { parent: DrawGeometryObjects; children: DrawGeometryObjects });

    if (shouldDrawNewGeometries) {
      this.mapService.drawGeometries({ method: 'replaceAll' });
    }
  }

  /**
   * Обработчик, который вешает стиль курсора в зависимости от того, был ли он наведен на контрол.
   *
   * @param event - Событие.
   */
  private onMouseMoveOnMap(event: MapPointerEvent) {
    if (this.isVertexMoving || this.isChanging) return;

    /**
     * Удаляет обработчики для Vertex.
     *
     
     */
    const turnAppendedVertexOff = () => {
      if (
        this.hoveredControl instanceof Vertex &&
        this.hoveredControl !== this.vertex
      ) {
        this.hoveredControl.setIsVisible(false);
      }
    };

    /**
     * Удаляет обработчики для Vertex.
     *
     
     */
    const turnNotAppendedVertexOff = () => {
      this.vertex?.setIsVisible(false);

      if (this.vertexMoveHandler) {
        this.map.off('mousemove', this.vertexMoveHandler);
        this.activeHandlers = this.activeHandlers.filter(
          (handler) => handler.handler !== this.vertexMoveHandler,
        );
        this.vertexMoveHandler = null;
      }
      if (this.vertexMouseDownHandler) {
        this.map.off('mousedown', this.vertexMouseDownHandler);
        this.activeHandlers = this.activeHandlers.filter(
          (handler) => handler.handler !== this.vertexMouseDownHandler,
        );
        this.vertexMouseDownHandler = null;
      }
    };

    const isMoverHovered = !!this.mover?.isMouseHovered(event);
    if (isMoverHovered && this.hoveredControl !== this.mover) {
      this.hoveredSide = null;
      turnNotAppendedVertexOff();
      turnAppendedVertexOff();
      this.setHoveredControl(this.mover);
      this.mover?.setCursorStyle();
      return;
    }

    if (isMoverHovered) return;

    const isRotatorHovered = !!this.rotator?.isMouseHovered(event);
    if (isRotatorHovered && this.hoveredControl !== this.rotator) {
      this.hoveredSide = null;
      turnNotAppendedVertexOff();
      turnAppendedVertexOff();
      this.setHoveredControl(this.rotator);
      this.rotator?.setCursorStyle();
      return;
    }

    if (isRotatorHovered) return;

    const hoveredResizerShape = this.resizer?.getHoveredShape(event);
    if (hoveredResizerShape && this.hoveredControl !== this.resizer) {
      this.hoveredSide = null;
      turnNotAppendedVertexOff();
      turnAppendedVertexOff();
      this.setHoveredControl(this.resizer);
      this.resizer?.setCursorStyle(hoveredResizerShape);
      return;
    }

    if (hoveredResizerShape) return;

    const hoveredAppendedVertex = this.appendedVertexes.find((vertex) =>
      vertex.isMouseHovered(event),
    );
    if (
      hoveredAppendedVertex &&
      this.hoveredControl !== hoveredAppendedVertex
    ) {
      this.hoveredSide = null;
      turnAppendedVertexOff();
      turnNotAppendedVertexOff();
      this.setHoveredControl(hoveredAppendedVertex);
      hoveredAppendedVertex.setIsVisible(true);
      hoveredAppendedVertex.setCursorStyle();
      return;
    }

    if (hoveredAppendedVertex) return;

    const hoveredSide = this.sides.find((side) => side.isMouseHovered(event));
    this.hoveredSide = hoveredSide || null;

    if (hoveredSide && this.hoveredControl !== this.vertex) {
      turnAppendedVertexOff();
      this.setHoveredControl(this.vertex);
      this.vertex?.setIsVisible(true);
      this.vertex?.setCursorStyle();

      /**
       * Обработчик для события mousemove на вершине.
       *
       * @param param0 - Событие.
       * @param param0.lngLat - Координаты точки.
       */
      const handleMouseMove = ({ lngLat }: MapPointerEvent) => {
        if (this.hoveredSide) {
          const point = nearestPointOnLine(
            lineString([this.hoveredSide.start, this.hoveredSide.end]),
            lngLat,
          );
          this.vertex?.onChange({ coordinates: point.geometry.coordinates });
        }
      };
      this.vertexMoveHandler = handleMouseMove;

      handleMouseMove(event);

      /**
       * Обработчик для события mousedown на вершине.
       *
       * @param param0 - Событие.
       * @param param0.lngLat - Координаты точки.
       */
      const handleMouseDown = ({ lngLat }: MapPointerEvent) => {
        if (this.geometry) {
          const newCoordinates = this.vertex?.append(
            this.geometry,
            this.geometry.userData.coordinates as number[][] | number[][][],
            {
              coordinates: lngLat,
              index: this.hoveredSide?.endIndex ?? -1,
              subGeometryIndex: this.hoveredSide?.subGeometryIndex ?? -1,
            },
          );

          this.geometry.destroy();

          if (newCoordinates && Shape.mapgl) {
            this.geometry = this.createGeometry(
              this.geometry,
              newCoordinates as (number | Position)[],
              Shape.mapgl,
            ) as Polygon | Polyline;

            if (this.vertex) {
              this.vertex.geometry = this.geometry;
              this.vertex.geometryCoordinates =
                this.geometry.userData.coordinates;
            }

            this.updateSides(this.geometry);
            this.destroyControls();
            this.updateControls(this.geometry);
          }
        }

        this.map.off('mousemove', handleMouseMove);
        this.map.off('mousedown', handleMouseDown);

        this.activeHandlers = this.activeHandlers.filter(
          (handler) =>
            handler.handler !== handleMouseMove &&
            handler.handler !== handleMouseDown,
        );
      };
      this.vertexMouseDownHandler = handleMouseDown;

      this.map.on('mousemove', handleMouseMove);
      this.map.on('mousedown', handleMouseDown);

      this.activeHandlers.push(
        {
          handler: handleMouseDown,
          target: this.map,
          type: 'mousedown',
        },
        {
          handler: handleMouseMove,
          target: this.map,
          type: 'mousemove',
        },
      );

      return;
    }

    if (hoveredSide) return;

    const isControlHovered =
      isMoverHovered ||
      isRotatorHovered ||
      !!hoveredResizerShape ||
      !!hoveredAppendedVertex ||
      !!hoveredSide;
    if (!isControlHovered && this.hoveredControl) {
      this.hoveredSide = null;
      turnNotAppendedVertexOff();
      turnAppendedVertexOff();
      this.hoveredControl.resetCursorStyle();
      this.setHoveredControl(null);
      return;
    }
  }

  /**
   * Уничтожает все элементы редактора на карте.
   *
   * @param onDestroy - Функция вызываемая при уничножении ShapeEditor'a.
   */
  public destroy(onDestroy?: VoidFunction) {
    this.mover?.destroy();
    this.mover?.resetCursorStyle();

    this.resizer?.destroy();
    this.resizer?.resetCursorStyle();

    this.rotator?.destroy();
    this.rotator?.resetCursorStyle();

    this.vertex?.destroy();
    this.vertex = null;

    this.box?.destroy();

    this.geometry?.destroy();

    if (this.vertexMoveHandler) {
      this.map.off('mousemove', this.vertexMoveHandler);
    }

    this.removeActiveHandlers();
    this.activeHandlers = this.activeHandlers.filter((handler) => {
      return handler.handler !== this.mouseMoveHandler;
    });

    onDestroy?.();
  }

  /**
   * Уничтожает все активные обработчики.
   *
   */
  private removeActiveHandlers() {
    this.activeHandlers.forEach(({ target, type, handler }) => {
      if (target.removeEventListener) {
        target.removeEventListener(type, handler);
      } else if (target.off) {
        target.off(type, handler);
      }
    });
  }
}
