/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

// TODO: все зависимости от app перенести в ядро
import Table from 'app/components/common/table/Table';
import HoverInformatrionIcon from 'app/components/common/HoverInformatrionIcon';

/* eslint-disable */
export default class OldValueTable extends React.Component {
  static propTypes = {
    table: PropTypes.array,
    oldValue: PropTypes.array,
    technicFileTypes: PropTypes.array,
    getTableData: PropTypes.func.isRequired,
  };

  renderOldTable() {
    const { getTableData, technicFileTypes, oldValue } = this.props;
    return (
      <HoverInformatrionIcon>
        <Table
          {...getTableData(oldValue, technicFileTypes)}
          height={oldValue.length * 50 + 50}
          width={1150}
          widths={(name) => (name === '' ? 50 : 280)}
        />
      </HoverInformatrionIcon>
    );
  }

  render() {
    const { table, oldValue } = this.props;
    let result;
    if (oldValue) {
      result = isEqual(table, oldValue) ? null : this.renderOldTable();
    } else {
      result = null;
    }
    return result;
  }
}
