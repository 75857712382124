import { FieldRF } from 'core/form/reduxForm/helpers/FieldRF';
import {
  CUSTOMER_OZN_NAME,
  CustomerOzn,
} from 'core/uiKit/preparedInputs/selects/autocompleteAsync/CustomerOzn';

/**
 * ## Заказчик. RF.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const CustomerOznRF = (props) => {
  return (
    <FieldRF {...props} name={CUSTOMER_OZN_NAME} component={CustomerOzn} />
  );
};
