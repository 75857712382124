/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import cn from 'classnames';
import { get, keys } from 'lodash';

import TableAddButton from 'app/components/common/TableAddButton';
import TableDeleteButton from 'app/components/common/TableDeleteButton';
import TableErrorText from 'app/components/common/TableErrorText';
import DialogAction from './DialogAction';

const propTypes = {
  arrayFields: PropTypes.array.isRequired,
  asyncCustomValidate: PropTypes.func,
  className: PropTypes.string,
  countColumnForm: PropTypes.number,
  customValidate: PropTypes.func,
  enableRemove: PropTypes.bool,
  handleSubmit: PropTypes.func,
  inEdit: PropTypes.bool,
  isDisabledOk: PropTypes.bool.isRequired,
  nameDialog: PropTypes.string,
  renderFunction: PropTypes.func,
  showCondition: PropTypes.bool,
  title: PropTypes.string,
  onAdd: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onRemove: PropTypes.func,
  onSubmit: PropTypes.func,
};

class DialogWithTableControls extends React.Component {
  onSubmit = (formValues) => {
    const { onSubmit, customValidate } = this.props;
    const errors = customValidate && customValidate(formValues);
    if (errors && Object.keys(errors).length) {
      throw new SubmissionError(errors);
    } else {
      onSubmit(formValues);
    }
  };

  render() {
    const {
      arrayFields,
      className,
      isDisabledOk,
      enableRemove,
      errorText,
      inEdit,
      nameDialog,
      handleSubmit,
      renderFunction,
      showCondition,
      title,
      onAdd,
      onCancel,
      onRemove,
    } = this.props;

    arrayFields.forEach((item) => {
      item.formValue = showCondition;
    });

    return (
      <React.Fragment>
        <DialogAction
          className={cn('Nested-Dialog-Form', className)}
          isDisabledOk={isDisabledOk}
          showCondition={showCondition}
          title={title}
          onCancel={onCancel}
          onOk={handleSubmit(this.onSubmit)}
        >
          <form id={nameDialog} name={nameDialog}>
            {renderFunction(arrayFields, 1, '')}
          </form>
        </DialogAction>
        <TableAddButton
          isError={!!errorText}
          title={title}
          visible={inEdit}
          onAdd={onAdd}
        />
        <TableDeleteButton
          disabled={!enableRemove}
          visible={inEdit}
          onRemove={onRemove}
        />
        <TableErrorText errorText={errorText} visible={inEdit} />
      </React.Fragment>
    );
  }
}

DialogWithTableControls.propTypes = propTypes;

const mapStateToProps = (state, props) => {
  const { asyncCustomValidate } = props;
  const values = get(state, 'form.editCardDialogForm.values', {});
  return {
    isDisabledOk: !keys(values).length,
    validate: asyncCustomValidate,
  };
};

 
export { DialogWithTableControls };

 
export default connect(mapStateToProps)(
  reduxForm({ form: 'editCardDialogForm' })(DialogWithTableControls),
);
