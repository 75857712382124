/* eslint-disable  */
import * as technologyVersionsActions from 'app/actions/technologyVersions';
import { handleActions } from 'redux-actions';

export default handleActions(
  {
    /**
     *
     * @param state
     */
    [`${technologyVersionsActions.apiGetTechnologyVersions}_PENDING`]:
      function (state) {
        return state;
      },

    /**
     *
     * @param state
     */
    [`${technologyVersionsActions.apiGetTechnologyVersions}_REJECTED`]:
      function (state) {
        state = {
          ...state,
          data: {},
        };
        return state;
      },

    /**
     *
     * @param state
     * @param action
     */
    [`${technologyVersionsActions.apiGetTechnologyVersions}_FULFILLED`]:
      function (state, action) {
        state = {
          ...state,
          data: action.payload.data,
        };
        return state;
      },
  },
  { data: {} },
);
