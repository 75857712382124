import * as oghTypes from 'app/constants/oghTypes';

import {
  buildingColumns,
  containerColumns,
  flowersGardenColumns,
  getDefaultColumns,
  lawnColumns,
  littleForm,
  odhColumns,
  otherOksColumns,
  otherTechPlaceColumns,
  redBookPlantColumns,
  treesAndShrubsColumns,
} from './columns.TableDelete';

/**
 * Функция получения колонок по типу ОГХ.
 *
 * @param {number} typeId - Тип ОГХ.
 * @returns {Array}
 */
export const getMapColumnsTableDelete = (typeId) => {
  switch (typeId) {
    case oghTypes.STOPS:
    case oghTypes.LAMPPOSTS:
    case oghTypes.MAF:
    case oghTypes.MANHOLES:
    case oghTypes.OTHER_ENGIN_CONSTRUCT:
    case oghTypes.ROAD_SIGNS:
    case oghTypes.FENCING:
    case oghTypes.TRAFFIC_LIGHT:
    case oghTypes.CARRIAGEWAY:
    case oghTypes.SIDEWALKS:
    case oghTypes.BOARD_STONE:
    case oghTypes.OTHER_FLAT_BUILDING:
    case oghTypes.TRAM_RAILS:
    case oghTypes.TROLLEYBUS_CONTACT_NETWORK:
    case oghTypes.MARGIN: {
      return odhColumns;
    }
    case oghTypes.BUILDING: {
      return buildingColumns;
    }
    case oghTypes.LAWN: {
      return lawnColumns;
    }
    case oghTypes.CONTAINER: {
      return containerColumns;
    }

    case oghTypes.FLOWERS_GARDEN: {
      return flowersGardenColumns;
    }

    case oghTypes.TREES_SHRUBS: {
      return treesAndShrubsColumns;
    }

    case oghTypes.OTHER_OKS: {
      return otherOksColumns;
    }

    case oghTypes.OTHER_TECH_PLACE: {
      return otherTechPlaceColumns;
    }

    case oghTypes.LITTLE_FORM: {
      return littleForm;
    }

    case oghTypes.RED_BOOK_PLANT: {
      return redBookPlantColumns;
    }

    default: {
      return getDefaultColumns(typeId);
    }
  }
};
