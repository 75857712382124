import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { mapCoatingType } from 'app/reducers/odsDicts';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const COATING_TYPE_ROAD_ID_NAME = 'coating_type_road_id';

/**
 * ## Вид покрытия на сопряжении с проезжей частью (уточнение).
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const CoatingTypeRoadId = (props) => {
  const dict = 'coatingType';
  const { data = [] } = useGetDictionary(dict);

  const options = mapCoatingType(data) || [];

  return (
    <Select
      {...props}
      {...{ options }}
      label={'Вид покрытия на сопряжении с проезжей частью (уточнение)'}
      name={COATING_TYPE_ROAD_ID_NAME}
    />
  );
};
