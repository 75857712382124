export const coating_group = [
  {
    code: 'unimproved',
    id: 2,
    name: 'Неусовершенствованное',
  },
  {
    code: 'advanced',
    id: 1,
    name: 'Усовершенствованное',
  },
];
