/* eslint-disable */
import React from 'react';
import { StringParam } from 'use-query-params';

import { TextFieldFF } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
export const VIN_NAME = 'vin';

 
 
/**
 *  VIN (ИД ТС)  vin
 */
 
export const VinFilter = (props) => {
  return (
    <TextFieldFF
      label={'VIN (ИД ТС)'}
      {...props}
      className={filterInput}
      name={VIN_NAME}
    />
  );
};

 
export const vinParams = {
  [VIN_NAME]: StringParam,
};
