import { post } from 'app/api/crud/post';
/* eslint-disable  */
import { toast } from 'core/uiKit/components/Toast';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import { useNavigate } from 'react-router-dom';

type resetPasswordInterface = {
  data: { newPassport: string };
  tokenNumber: string;
};

interface useResetPasswordInterface {
  isLoading: boolean;
  resetPassword: ({ data, tokenNumber }: resetPasswordInterface) => void;
}

/**
 * Запрос на сброс паспорта.
 *
 * @param {resetPasswordInterface} params - Параметры.
 * @returns {*}
 */
const postResetPassword = ({ data, tokenNumber }: resetPasswordInterface) =>
  post(`/auth/changePassword/${tokenNumber}`, data, {
    // @ts-ignore
    jsonBody: false,
    parseResponse: false,
    urlencoded: true,
  });

/**
 * Хук получения метода resetPassword.
 *
 * @returns { useResetPasswordInterface }
 */
export const useResetPassword = () => {
  const navigate = useNavigate();
  const [resetPassword, { isLoading }] = useMutationAdaptor(postResetPassword, {
    /**
     *
     * @param error
     * @param error.message
     */
    onError: (error: { message: string }) => {
      toast.errorDying(error?.message);
    },

    /**
     *
     */
    onSuccess: () => {
      navigate('/auth/login');
    },
  });

  return {
    isLoading,
    resetPassword,
  };
};
