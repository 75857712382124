import { CleanSubCategoryId } from 'core/uiKit/preparedInputs/selects/simpleSelect/CleanSubCategoryId/index';
import React from 'react';

interface CleanSubCategoryIdWithReduxFormInterface {
  input: { value: string };
  options: { name: string; id: number }[];
}

/**
 * Обертка поля Категория по уборке для redux form.
 *
 * @param props - Пропсы.
 * @param props.input - Параметры Redux form.
 * @param props.options - Опшины.
 * @returns {JSX.Element}
 */
export const CleanSubCategoryIdWithReduxForm = ({
  input,
  options,
  ...otherProps
}: CleanSubCategoryIdWithReduxFormInterface) => {
  return <CleanSubCategoryId {...input} {...{ options, ...otherProps }} />;
};
