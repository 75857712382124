import { FormCardFF } from 'app/pages/cardsOgh/components/FormCard.FF';
import { GREEN_NUM_NAME } from 'core/uiKit/preparedInputs/numberField/GreenNum';
import { SECTION_NUM_NAME } from 'core/uiKit/preparedInputs/numberField/SectionNum';
import { RED_BOOK_PLANT_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/RedBookPlantId';
import React from 'react';

import { RedBookPlantLeftPanel } from './RedBookPlantLeftPanel';
import { useSetupRedBookPlant } from './useSetup.RedBookPlant';
import { validateForm } from './validateForm';

export const mapAttribute = [
  RED_BOOK_PLANT_ID_NAME,
  SECTION_NUM_NAME,
  GREEN_NUM_NAME,
  'noCalc',
];

/**
 * Карточка Растения красной книги.
 *
 * @returns {JSX}
 */
export const RedBookPlant = () => {
  const { initialValues, preparingDataForAttributes } = useSetupRedBookPlant();

  return (
    <FormCardFF
      preparingDataForAttributes={preparingDataForAttributes}
      validate={validateForm}
      initialValues={initialValues}
    >
      <RedBookPlantLeftPanel />
    </FormCardFF>
  );
};
