import { useGetFilesForSignature } from 'app/api/hooks/signs/useGetFilesForSignature';
import { CLOSE } from 'app/api/requests/signs/signOghSave';
import { Dialog } from 'core/uiKit/components/dialogs/Dialog';
import { LoaderCreateFiles } from 'core/uiKit/components/loaders/LoaderCreateFiles';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';

import { DialogSignatureFiles } from './Dialog.SignatureFiles';

/**
 * Диалоговое окно закрытия ЭП.
 *
 * @param {object} params - Параметры.
 * @param {Function} params.onClose - Функция скрытия окна.
 * @returns {React.ReactElement}
 */
export const DialogCloseElectronicSignature = ({ onClose }) => {
  const { objectId } = useGetObjectId();
  const { data = [], isLoading } = useGetFilesForSignature(objectId, CLOSE, {
    onErrorCallback: onClose,
  });

  return (
    <>
      <Dialog
        isOpen={!data?.length && isLoading}
        maxWidth={'xs'}
        textHeader={'Формируются файлы для подписания'}
        Body={<LoaderCreateFiles />}
      />
      <DialogSignatureFiles isOpen={!!data?.length} onClose={onClose} />
    </>
  );
};
