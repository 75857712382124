import { load } from '@2gis/mapgl';
import { Map } from '@2gis/mapgl/types';
import React, { useEffect, useRef } from 'react';

import MapWrapper from './components/MapWrapper';
import { useDTWContext } from './contexts/ContextProvider.DTW';
import { useMapglContext } from './contexts/MapglContextProvider';
import GeometryFactory from './contexts/utils/GeometryFactory';

export const MAP_CENTER = [37.630634, 55.707146];

/**
 * Компонент карты.
 *
 * @param {{ id }} пропсы - Id карты.
 * @returns ReactComponent.
 */
const Mapgl = ({ id }: { id: string }) => {
  const { accessToken, getAccessToken, isRefreshLoading, isLoginLoading } =
    useDTWContext();
  const { setMapglContext } = useMapglContext();

  const mapRef = useRef<Map | undefined>();

  useEffect(() => {
    // if (mapRef.current) return;
    //
    // if (
    //   !accessToken &&
    //   (isRefreshLoading ||
    //     isLoginLoading ||
    //     (!isRefreshLoading && !isLoginLoading))
    // )
    //   return;

    load().then((mapgl) => {
      GeometryFactory.initializeConstructors(mapgl);
      setMapglContext({ id, mapgl });
    });
  }, [
    accessToken,
    isRefreshLoading,
    isLoginLoading,

    id,
    setMapglContext,
    getAccessToken,
  ]);

  useEffect(() => {
    // Destroy the mapRef.current, if Map component is going to be unmounted
    const currentMapInstance = mapRef.current;
    return () => {
      currentMapInstance?.destroy();
    };
  }, []);

  return <MapWrapper id={id} />;
};

export default Mapgl;
