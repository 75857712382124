import NavItem from 'app/components/common/NavBar/NavItem';
import { pathNsiBid } from 'app/pages/registry/nsiBid/path.NsiBid';
import React from 'react';

import { useSetupNSIBidLink } from './useSetup.NSIBid.Link';

/**
 * Link __Реестр изменений справочников НСИ__.
 *
 * @returns {JSX.Element | null}
 */
export const NSIBidLink = () => {
  const { className, isShow, link } = useSetupNSIBidLink();

  return isShow ? (
    <NavItem className={className} link={link}>
      {pathNsiBid.name}
    </NavItem>
  ) : null;
};
