/* eslint-disable */
import React, { useState } from 'react';
import { GeometryWarningMessage } from 'app/pages/cardsOgh/components/DIalogs/components/GeometryWarningMessage';
import { ShowAlert } from 'app/pages/cardsOgh/components/DIalogs/components/ShowAlert';

 
export const ContextDialog = React.createContext(null);

/* eslint-disable */
export const Dialogs = ({ children }) => {
  const [geometryWarningDialog, setGeometryDialog] = useState(false);

  const [alert, setAlert] = useState({ message: '', show: false });

  const showGeometryWarningDialog = () => {
    setGeometryDialog(true);
  };

  const showAlert = (message) => {
    setAlert({ message, show: true });
  };

  return (
    <ContextDialog.Provider
      value={{
        showGeometryWarningDialog,
        geometryWarningDialog,
        setGeometryDialog,
        alert,
        setAlert,
        showAlert,
      }}
    >
      <ShowAlert />
      <GeometryWarningMessage />
      {children}
    </ContextDialog.Provider>
  );
};
