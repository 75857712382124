import { useGetSignDate } from 'app/components/card/ogh/header/inputs/SignDataComponent/hooks/useGetSignDate';
/* eslint-disable  */
import { TextField } from 'core/uiKit/inputs';

/**
 *
 */
export const SignDataComponent = () => {
  const { signDate, signPerson } = useGetSignDate();
  return (
    <TextField
      disabled={true}
      label={'Подпись'}
      value={
        signDate ? 'Подписан ' + signDate + ', ' + signPerson : 'Не подписан'
      }
    />
  );
};
