import { createSelector } from 'reselect';

import getReagentStorageCapacityList from './getReagentStorageCapacityList';

/* eslint-disable */
export default createSelector(
  [getReagentStorageCapacityList],
  (storageCapacityList) =>
    storageCapacityList.map((item) => ({
      reagent_kind_id: item.reagent_kind_id,
      reagent_id: item.reagent_id,
      quantity: item.quantity_max,
      balance: item.quantity,
    })),
);
