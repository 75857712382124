import DtsCard from './Dts';
import { useGetDtsTypes } from './useGetDtsTypes';

 
/**
 * Индексный файл комп 30 Дорожно-тропиночные сети.
 * Все хуки ходят от сюда.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - JSX.
 * @example -------
 */
const Dts = (props) => {
  const dtsTypes = useGetDtsTypes();
  return <DtsCard {...props} dtsTypes={dtsTypes} />;
};

export default Dts;
