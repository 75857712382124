import { useGetVisibilityButtonsOgh } from 'app/api/hooks/useGetVisibilityButtonsOgh';
import { pathExaminationOGHApplication } from 'app/pages/certificationOfOgh/examinationOGH.Application/path.ExaminationOGH.Application';
import { pathCertificationOfOgh } from 'app/pages/certificationOfOgh/path.CertificationOfOgh';
import { Button } from 'core/uiKit/components/buttons/Button';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import React from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Кнопка __Создать заявку на обследование__.
 *
 * @returns {JSX}
 */
export const CreateApplicationExaminationButton = () => {
  const navigate = useNavigate();
  const { data: { create_bid: isShow = false } = {} } =
    useGetVisibilityButtonsOgh();

  const { objectId } = useGetObjectId();
  return isShow ? (
    <Button
      variant="contained"
      color="info"
      onClick={() =>
        navigate(
          `/${pathCertificationOfOgh.path}/${pathExaminationOGHApplication.path}/create/${objectId}`,
        )
      }
    >
      Создать заявку на обследование
    </Button>
  ) : null;
};
