import { post, put } from 'app/api/crud';
import * as env from 'config/env';

/**
 * Загрузка Dxf файла на сервер.
 *
 * @param {*} data - Данные.
 * @returns {Promise}
 */
export const fetchDxfFileLink = (data) =>
  post('/upload/link', data, {
    host: env.DXF_API_URL,
  });

/**
 * Загрузка Dxf файла на сервер.
 *
 * @param {*} data - Данные.
 * @returns {Promise}
 */
export const fetchDxfFileUpdateLink = (data) =>
  put('/update/link', data, {
    host: env.DXF_API_URL,
    isFile: true,
  });

/**
 * Загрузка файла на сервер.
 *
 * @param {*} data - Данные.
 * @returns {Promise}
 */
export const fetchFileUpload = async (data) =>
  await post('/files/upload', data, {
    isFile: true,
  });
