/**
 * A.
 *
 * @param {Array} dict - List.
 * @returns {Array<{
 *   ogh_object_type_list: Array<{
 *     ogh_object_type_id: *,
 *     other_improvement_object_type_list: Array<(null|number|*)>
 *   }>,
 *   group_id: *,
 *   class_id: *,
 *   name: *,
 *   id: *
 * }>}
 */
export function mapCoatingType(dict = []) {
  return dict.map((item) => {
    const {
      id,
      attribute: {
        code,
        name,
        coating_group_id,
        ogh_object_type_list,
        allowed_object_type_list,
      },
    } = item;
    return {
      allowed_object_type_list,
      code,
      group_id: coating_group_id.coating_group,
      id,
      name,
      ogh_object_type_list: mapOghOjectTypeList(ogh_object_type_list),
    };
  });
}

/**
 * A.
 *
 * @param {Array} list - List.
 * @returns {Array<unknown>}
 */
function mapOghOjectTypeList(list = []) {
  return list.map((params) => {
    const { ogh_object_type_id, other_improvement_object_type_list } = params;
    if (ogh_object_type_id) {
      return {
        ogh_object_type_id: ogh_object_type_id.ogh_object_type,
        other_improvement_object_type_list: mapOtherImprovementObjectTypeList(
          other_improvement_object_type_list,
        ),
      };
    }
    return params;
  });
}

/**
 * A.
 *
 * @param {Array} list - List.
 * @returns {Array<(null|number|*)>}
 */
function mapOtherImprovementObjectTypeList(list = []) {
  return list.map(({ other_improvement_object_type_id }) => {
    return other_improvement_object_type_id?.other_improvement_object_type;
  });
}
