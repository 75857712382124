/* eslint-disable */
import React from 'react';
import { DelimitedNumericArrayParam, withDefault } from 'use-query-params';

import { OghTypesFF, OGH_TYPES_NAME } from 'core/form/finalForm/fields';
import { filterInput } from '../filterInputsClasses';

 
 
/**
 * Тип объекта ГХ
 * oghTypes
 */
 
export const OghTypesFilter = (props) => (
  <OghTypesFF {...props} className={filterInput} />
);

 
export { OGH_TYPES_NAME };

 
export const oghTypesParams = {
  [OGH_TYPES_NAME]: withDefault(DelimitedNumericArrayParam, []),
};
