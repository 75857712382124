import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { getNameFile } from 'app/pages/cardsOgh/components/tables/Documents/helpers/getNameFile';

/**
 * Хук получения изначального состояния таблиц документов.
 *
 * @returns {object}
 */
export const useGetInitialValuesTablesDocuments = () => {
  const { fileList = [] } = useGetCurrentCard();

  return (fileList || []).reduce((obj, item) => {
    const NAME = getNameFile(item.type_id);
    return {
      ...obj,
      [NAME]: obj[NAME] ? [...obj[NAME], item] : [item],
    };
  }, {});
};
