import { REQUIRED_FIELD_MESSAGE } from 'app/constants/messages';

const reg = /[\dа-яА-Я]+$/;
const regTemplates = [
  /[а-яА-я]{1}[0-9]{3}[[а-яА-я]{2}[0-9]{2}/, //А123АА77
  /[а-яА-я]{1}[0-9]{3}[[а-яА-я]{2}[0-9]{3}/, //А123АА177
  /[а-яА-я]{2}[0-9]{5}/, //АА12377
  /[а-яА-я]{2}[0-9]{6}/, //АА123177
  /[а-яА-я]{2}[0-9]{7}/, //АА1234177
  /[0-9]{4}[а-яА-я]{2}[0-9]{2}/, //1234АА77
  /[0-9]{4}[а-яА-я]{2}[0-9]{3}/, //1234АА177
];

/* eslint-disable */
export default function validateGovNumber(value) {
  let message;
  if (!value) {
    message = REQUIRED_FIELD_MESSAGE;
  } else if (!reg.test(value)) {
    message =
      'Регистрационный номер может содержать только цифры и русские буквы';
  } else if (!isValidGovNumber(value, regTemplates)) {
    message = 'Некорректный регистрационный номер';
  }
  return message
    ? {
        gov_number: message,
      }
    : null;
}

function isValidGovNumber(value, templatesList) {
  return templatesList.some((item) => item.test(value));
}
