/* eslint-disable */
export const redirects = [
  {
    from: '/main',
    exact: true,
    to: '/',
  },
  {
    from: '/report/1',
    exact: true,
    to: '/report/technology',
  },
  {
    from: '/report/2',
    exact: true,
    to: '/report/bnso',
  },
  {
    from: '/report/3',
    exact: true,
    to: '/report/equipment',
  },
  {
    from: '/report/4',
    exact: true,
    to: '/report/sensors',
  },
  {
    from: '/report/5',
    exact: true,
    to: '/report/change-log',
  },
];
