import './textField.scss';

import cn from 'classnames';
import { Icon, TextFieldMUI, Tooltip } from 'core/uiKit/material-ui';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  FormHelperTextProps: PropTypes.object,
  InputLabelProps: PropTypes.object,
  InputProps: PropTypes.object,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  dark: PropTypes.bool,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.node,
  // NOTE: InputProps и inputProps - это разные свойства!
  id: PropTypes.string,
  inputProps: PropTypes.object,
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  label: PropTypes.node,
  multiline: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rowsMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  variant: PropTypes.oneOf(['standart', 'outlined', 'filled']),
};

const defaultProps = {
  FormHelperTextProps: {},
  InputLabelProps: { shrink: true },
  autoFocus: false,
  dark: false,
  disabled: false,
  error: false,
  fullWidth: false,
};

/**
 * Компонент HelpTooltip.
 *
 * @param {object} props - Пропсы.
 * @param {*} props.label - Any.
 * @param {*} props.helpingElement - Any.
 * @returns {JSX.Element}
 */
const HelpTooltip = ({ label, helpingElement }) => {
  return helpingElement ? (
    <>
      {label}
      <Tooltip title={helpingElement}>
        <Icon className={'ml-1'} style={{ fontSize: 14 }}>
          help_outline
        </Icon>
      </Tooltip>
    </>
  ) : (
    label
  );
};

/**
 * Компонент TextField.
 *
 * @deprecated Используйте TextField из uiKit.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export default function TextField(props) {
  const {
    autoFocus,
    className,
    defaultValue,
    dark,
    disabled,
    error,
    FormHelperTextProps,
    fullWidth,
    helperText,
    id,
    InputLabelProps,
    InputProps,
    inputProps,
    inputRef,
    label,
    multiline,
    name,
    placeholder,
    required,
    rows,
    rowsMax,
    title,
    type,
    value,
    variant,
    onBlur,
    onChange,
    onClick,
    helpTitle,
    ...rest
  } = props;
  return (
    <TextFieldMUI
      autoFocus={autoFocus}
      className={cn(className, { TextField_Dark: dark })}
      defaultValue={defaultValue}
      disabled={disabled}
      error={error}
      FormHelperTextProps={FormHelperTextProps}
      fullWidth={fullWidth}
      helperText={helperText}
      id={id}
      InputLabelProps={InputLabelProps}
      InputProps={InputProps}
      inputProps={inputProps}
      inputRef={inputRef}
      label={<HelpTooltip helpingElement={helpTitle} label={label} />}
      multiline={multiline}
      name={name}
      placeholder={placeholder}
      required={!!required}
      rows={rows}
      rowsMax={rowsMax}
      title={title}
      type={type}
      value={value}
      variant={variant}
      onBlur={onBlur}
      onChange={onChange}
      onClick={onClick}
      data-testid={id}
      {...rest}
    />
  );
}

TextField.propTypes = propTypes;
TextField.defaultProps = defaultProps;
