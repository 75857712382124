import { currentDate } from 'app/utils/dateUtils';
import { filterInputData } from 'core/form/filterForm/components/filterInputsClasses';
import { KeyboardDatePickerFF } from 'core/form/finalForm/fields/default/datePickers/KeyboardDatePickerFF';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import moment from 'moment';
import React from 'react';
import { useField } from 'react-final-form';
import { DateTimeParam, withDefault } from 'use-query-params';

export const DATE_FROM_VERSION_FILTER_NAME = 'dateFromVersionTable';

/**
 * DateFromVersionFilter.
 *
 * @param {*} props - Props.
 * @returns {JSX.Element}
 */
export const DateFromVersionFilter = (props) => {
  const dateToInput = useField('dateToVersionTable');
  return (
    <KeyboardDatePickerFF
      label="Дата с"
      maxDate={dateToInput?.input?.value}
      {...props}
      className={filterInputData}
      name={DATE_FROM_VERSION_FILTER_NAME}
    />
  );
};

/**
 * Params.
 *
 * @param {*} initDate - Init date.
 * @returns {*}
 */
export const dateFromParamsVersionFilter = (initDate = currentDate) => {
  addPreparation(DATE_FROM_VERSION_FILTER_NAME, (value) => {
    let newValue = value;
    if (typeof value === 'object' && moment(value).isValid()) {
      newValue = moment(value).format('DD.MM.YYYY');
    }
    return newValue;
  });
  return {
    [DATE_FROM_VERSION_FILTER_NAME]: withDefault(DateTimeParam, initDate),
  };
};
