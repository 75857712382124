import { CheckboxRF } from 'core/form/reduxForm/fields';
/* eslint-disable */

/**
 *
 * @param root0
 * @param root0.editable
 */
export const IsDiffHeightMark = ({ editable }) => {
  return (
    <CheckboxRF
      name={'is_diff_height_mark'}
      label={'Разновысотный ОГХ'}
      disabled={!editable}
    />
  );
};
