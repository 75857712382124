import './LeftPanel.scss';

import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { Card } from 'app/components/card/ogh/index/card/CardLeftPanel';
import { queryClient } from 'app/routes/QueryClientProvider';
import { useNavigateRouter } from 'core/utils/router';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

// todo: костыль на время. создан когда была переделка на новый роут.
// нужно переделать на переменые из роута

/**
 * Компонент обертка чтобы прокинуить хуки.
 *
 * @param {object} props - Пропсы.
 * @returns {React.ReactComponentElement} - Обертка для.
 * @example -
 */
const LeftPanelDefault = (props) => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigateRouter();

  /**
   * Функция clearCacheVisibilityButtonsOgh.
   *
   * @returns {Promise<void>} - Жопа.
   * @example -
   */
  const clearCacheVisibilityButtonsOgh = () =>
    queryClient.invalidateQueries({ queryKey: ['getVisibilityButtonsOgh'] });

  /**
   * @deprecated
   * @see используй navigate
   */
  const history = { push: navigate };

  const isCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();
  const rootCard = useGetParentCard();

  return (
    <Card
      {...{
        clearCacheVisibilityButtonsOgh,
        history,
        isCurrentObjectIsExternalSystem,
        isEditCurrentObjectIsExternalSystem,
        location,
        navigate,
        params,
        rootCard,
      }}
      {...props}
    />
  );
};

export default LeftPanelDefault;
