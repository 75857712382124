/* eslint-disable */
import React from 'react';
import {
  activateSelectedTab,
  getTabContentByTab,
  hideTabs,
  mountTabs,
  onTabClick,
  resetTabs,
} from 'app/components/card/common/tabs';

/* eslint-disable */
export default class CardAbstract extends React.Component {
  static ManageTabs = {
    mountTabs,
    getTabContentByTab,
    hideTabs,
    activateSelectedTab,
    onTabClick,
    resetTabs,
  };

  componentDidMount() {
    CardAbstract.ManageTabs.mountTabs();
  }

  componentDidUpdate(prevProps) {
    const record_id =
      (this.props && this.props.card && this.props.card.record_id) || null;
    const prevRecord_id =
      (prevProps && prevProps.card && prevProps.card.record_id) || null;
    if (record_id && prevRecord_id && record_id !== prevRecord_id) {
      CardAbstract.ManageTabs.resetTabs();
    }
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}
