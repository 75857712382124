/* eslint-disable */
import React from 'react';
import cn from 'classnames';
import MapCard from 'app/pages/cardsOgh/components/Map/MapCard';
import { Dialogs } from 'app/pages/cardsOgh/components/DIalogs/Dialogs';
import { PreloadedCard } from 'app/pages/cardsOgh/components/PreloadedCard/PreloadedCard';

import './Layout.scss';
import { EditMap } from 'app/pages/cardsOgh/components/Map/EditMapContext';
import { ActionTree } from 'app/pages/cardsOgh/components/RightPanelCard/components/ActionTree/ActionTree';

 
export const Layout = ({ children, map }) => {
  const className = cn('h-100 position-relative card-ogh-global-container', {
    'card-ogh-global-container__white': !!map,
  });

  return (
    <section className={className}>
      <ActionTree>
        <PreloadedCard>
          <Dialogs>
            <EditMap>
              <MapCard active={map}>{children}</MapCard>
            </EditMap>
          </Dialogs>
        </PreloadedCard>
      </ActionTree>
    </section>
  );
};
