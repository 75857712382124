import { get } from 'app/api/crud/get';
import * as types from 'app/constants/actionTypes';

/**
 * A.
 *
 * @param {string} actionPath - An action path.
 * @param {object} queryParams - A query params.
 * @returns {Promise}
 */
export const downloadFile = (actionPath, queryParams) => async (dispatch) => {
  dispatch(requestFileDownload());
  const response = await get(actionPath, queryParams, { parseResponse: false });
  await handleBrowserDownload(response);

  dispatch(receiveFileDownload());
};

/**
 * A.
 *
 * @param {Response} response - A response.
 */
const handleBrowserDownload = async (response) => {
  let filename = 'Печать.csv';
  let blob = new Blob([]);

  if (response.status === 200) {
    filename = getFilename(response);
    blob = await response.blob();
  }
  const element = document.createElement('a');
  element.href = URL.createObjectURL(blob);
  element.download = filename;

  document.body.appendChild(element);

  element.click();
  element.remove('a');
};

/**
 * A.
 *
 * @param {Response} response - Response.
 * @returns {string}
 */
const getFilename = (response) => {
  const disposition = response.headers.get('Content-Disposition');

  let filename;

  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[*][^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);

    if (matches !== null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
      filename = filename.replace(/utf-8/g, '');
      filename = decodeURIComponent(filename);
    }
  } else {
    filename = 'default_filename';
  }

  return filename;
};

/**
 * A.
 *
 * @returns {{type: string}}
 */
const requestFileDownload = () => ({
  type: types.REQUEST_FILE_DOWNLOAD,
});

/**
 * A.
 *
 * @returns {{type: string}}
 */
const receiveFileDownload = () => ({
  type: types.RECEIVE_FILE_DOWNLOAD,
});
