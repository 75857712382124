////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// NOTE Object create
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 *Объект, в котором собираются данные о сертификате.
 *
 * @description Объект, в котором собираются данные о сертификате и методы по работе с этими данными.
 */
const CertificateAdjuster = Object.create(null);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// NOTE Methods
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Метод для инициализации.
 *
 * @function init
 * @param {object} currentCert - Объект с данными о сертификате.
 * @description Конструктор.
 */
CertificateAdjuster.init = function init(currentCert) {
  const {
    certApi,
    issuerInfo,
    privateKey,
    serialNumber,
    thumbprint,
    subjectInfo,
    validPeriod,
  } = currentCert;

  this.certApi = certApi;
  this.issuerInfo = issuerInfo;
  this.privateKey = privateKey;
  this.serialNumber = serialNumber;
  this.thumbprint = thumbprint;
  this.subjectInfo = subjectInfo;
  this.validPeriod = validPeriod;
};

/**
 * A.
 *
 * @function friendlyInfo
 * @param {string} subjectIssuer - Раздел информации 'issuerInfo' или 'subjectInfo'.
 * @returns {object}
 * @throws {Error}
 * @description Возврящает объект из сформированных значений.
 */
CertificateAdjuster.friendlyInfo = function friendlyInfo(subjectIssuer) {
  if (!this[subjectIssuer]) {
    throw new Error('Не верно указан аттрибут');
  }

  const subjectIssuerArr = this[subjectIssuer].split(', ');
  const _possibleInfo = this.possibleInfo(subjectIssuer);
  const formedSubjectIssuerInfo = subjectIssuerArr.map((tag) => {
    const tagArr = tag.split('=');
    tagArr[0] = `${tagArr[0]}=`;

    return {
      text: tagArr[1],
      value: _possibleInfo[tagArr[0]],
    };
  });

  return formedSubjectIssuerInfo;
};

/**
 * A.
 *
 * @function friendlySubjectInfo
 * @returns {Array}
 * @description Возвращает распаршенную информацию о строке subjectInfo.
 */
CertificateAdjuster.friendlySubjectInfo = function friendlySubjectInfo() {
  return this.friendlyInfo('subjectInfo');
};

/**
 * A.
 *
 * @function friendlyIssuerInfo
 * @returns {Array}
 * @description Возвращает распаршенную информацию о строке issuerInfo.
 */
CertificateAdjuster.friendlyIssuerInfo = function friendlyIssuerInfo() {
  return this.friendlyInfo('issuerInfo');
};

/**
 * A.
 *
 * @function friendlyValidPeriod
 * @returns {object}
 * @description Возвращает распаршенную информацию об объекте validPeriod.
 */
CertificateAdjuster.friendlyValidPeriod = function friendlyValidPeriod() {
  const { from, to } = this.validPeriod;

  return {
    from: this.friendlyDate(from),
    to: this.friendlyDate(to),
  };
};

/**
 * A.
 *
 * @function possibleInfo
 * @param {string} subjectIssuer - Раздел информации 'issuerInfo' или 'subjectInfo'.
 * @returns {object}
 * @throws {Error}
 * @description Функция формирует ключи и значения в зависимости от переданного параметра.
 */
CertificateAdjuster.possibleInfo = function possibleInfo(subjectIssuer) {
  const attrs = {
    'C=': 'Страна',
    'E=': 'Email',
    'INN=': 'ИНН',
    'L=': 'Город',
    'O=': 'Компания',
    'OGRN=': 'ОГРН',
    'OGRNIP=': 'ОГРНИП',
    'S=': 'Регион',
    'SNILS=': 'СНИЛС',
    'STREET=': 'Адрес',
    'T=': 'Должность',
    'UnstructuredName=': 'Неструктурированное имя',
    'ИНН=': 'ИНН',
    'ОГРН=': 'ОГРН',
    'ОГРНИП=': 'ОГРНИП',
    'СНИЛС=': 'СНИЛС',
  };

  switch (subjectIssuer) {
    case 'subjectInfo':
      attrs['SN='] = 'Фамилия';
      attrs['G='] = 'Имя/Отчество';
      attrs['CN='] = 'Владелец';
      attrs['OU='] = 'Отдел/подразделение';

      return attrs;
    case 'issuerInfo':
      attrs['CN='] = 'Удостоверяющий центр';
      attrs['OU='] = 'Тип';

      return attrs;

    default:
      throw new Error('Не верно указан кейс получаемых данных');
  }
};

/**
 * A.
 *
 * @function friendlyDate
 * @param {string} date - Строка с датой.
 * @returns {object}
 * @description Формирует дату от переданного пареметра.
 */
CertificateAdjuster.friendlyDate = function friendlyDate(date) {
  const newDate = new Date(date);
  const [day, month, year] = [
    newDate.getDate(),
    newDate.getMonth() + 1,
    newDate.getFullYear(),
  ];
  const [hours, minutes, seconds] = [
    newDate.getHours(),
    newDate.getMinutes(),
    newDate.getSeconds(),
  ];

  return {
    ddmmyy: `${day}/${month}/${year}`,
    hhmmss: `${hours}:${minutes}:${seconds}`,
  };
};

/**
 * A.
 *
 * @async
 * @function isValid
 * @returns {boolean} Возвращает валидность сертификата.
 * @throws {Error} Возвращает сообщение об ошибке.
 * @description Прозиводит проверку на валидность сертификата.
 */
CertificateAdjuster.isValid = async function isValid() {
  try {
    const isValid = await this.certApi.IsValid();

    return await isValid.Result;
  } catch (error) {
    throw new Error(
      `Произошла ошибка при проверке валидности сертификата: ${error.message}`,
    );
  }
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// NOTE Exports
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default CertificateAdjuster;
