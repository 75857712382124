import getDict from 'app/selectors/getDict';
import { createSelector } from 'reselect';

import getLegalCarRegistrationId from './getLegalCarRegistrationId';

 
export default createSelector(
  [getDict('legalCarRegistration'), getLegalCarRegistrationId],
  (dict, legalCarRegistrationId) => {
    let result;
    if (legalCarRegistrationId) {
      const GIBDD_CODE = 2;
      const { code } = dict.find((item) => item.id === legalCarRegistrationId);
      result = parseInt(code, 10) === GIBDD_CODE;
    } else {
      result = false;
    }
    return result;
  },
);
