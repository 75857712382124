import { Button } from 'core/uiKit/components/buttons/Button';
import { Icon } from 'core/uiKit/material-ui';
import React from 'react';

/**
 * Компонент CancelButton.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.onClick - Функция обработки нажатия.
 * @param {boolean} [props.isLoading] - Флаг Загрузки.
 * @returns {JSX.Element}
 */
export const CancelButton = (props) => {
  return (
    <Button
      {...props}
      color="error"
      variant={'contained'}
      startIcon={<Icon>not_interested</Icon>}
    >
      Отмена
    </Button>
  );
};
