import { TableList } from '../Table.List';
import { columns } from './columns';

 
/**
 * Компонент TableTreesShrubsList.
 *
 * @param {string} code - Код.
 * @returns {JSX.Element} - Возвращает TableList.
 * @example <TableTreesShrubsList code={code} />
 */
export const TableTreesShrubsList = ({ code }) => {
  return <TableList columns={columns} code={code} />;
};
