/* eslint-disable */
import React from 'react';
import { NumberParam } from 'use-query-params';

import { TextFieldFF } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
export const INVENTORY_NUMBER_NAME = 'inventoryNumber';

 
 
/**
 * Инвентарный номер
 */
 
export const InventoryNumberInputFilter = () => {
  return (
    <TextFieldFF
      className={filterInput}
      label={'Инвентарный номер'}
      name={INVENTORY_NUMBER_NAME}
      type="number"
    />
  );
};

 
export const inventoryNumberInputParams = {
  [INVENTORY_NUMBER_NAME]: NumberParam,
};
