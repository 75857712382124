import './CleanCategory.scss';

import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { validateCleanSubcategory } from 'app/components/card/common/validation/index';
import Select from 'core/newComponents/Select';
import React from 'react';

/**
 * E.
 *
 * @param {*} props - Properyies.
 * @returns {JSX}
 */
export default function RenderCleanCategory(props) {
  const { input, disabled } = props;
  const clean_category_id = input.value && input.value.clean_category_id;
  const clean_subcategory_id = input.value && input.value.clean_subcategory_id;
  const isCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();

  const oldLabel1 =
    props.oldValue &&
    (props.oldValue.clean_category_id ||
      props.oldValue.clean_category_id === 0) &&
    props.options &&
    props.options.filter(
      (item) => item.id === props.oldValue.clean_category_id.value,
    );

  const oldLabel2 =
    props.oldValue &&
    (props.oldValue.clean_subcategory_id ||
      props.oldValue.clean_subcategory_id === 0) &&
    props.options &&
    props.options.filter(
      (item) => item.id === props.oldValue.clean_subcategory_id.value,
    );

  const cleanSubcategoryList = props.options.clean_subcategory.filter(
    (item) => parseInt(item.category_id, 10) === clean_category_id,
  );
  const disabledSubcategory = disabled || !cleanSubcategoryList.length;
  const requiredSubcategory =
    !isCurrentObjectIsExternalSystem && props.required && !disabledSubcategory;

  return (
    <div className={props.oldValue ? 'ods-value--old' : 'clean__wrapper'}>
      <div className="clean__wrapper_select">
        <Select
          disabled={disabled || isCurrentObjectIsExternalSystem}
          errorText={
            (props.meta &&
              props.meta.error &&
              props.meta.error.clean_category_id) ||
            ''
          }
          id="clean_category_id"
          label="Категория по уборке"
          name="clean_category_id"
          options={props.options.clean_category}
          required={props.required}
          title={oldLabel1 && `Предыдущее значение: ${oldLabel1}`}
          value={clean_category_id}
          onChange={(value) => {
            return props.input.onChange({
              clean_category_id: value,
              clean_subcategory_id: props.options.clean_subcategory.some(
                (item) => {
                  return (
                    item.id === clean_subcategory_id &&
                    item.category_id === value
                  );
                },
              )
                ? clean_subcategory_id
                : null,
            });
          }}
        />
      </div>
      <div className="clean__wrapper_select">
        <Select
          disabled={disabledSubcategory || isCurrentObjectIsExternalSystem}
          errorText={
            disabled
              ? ''
              : validateCleanSubcategory(
                  clean_category_id,
                  clean_subcategory_id,
                  props.options.clean_subcategory,
                  isCurrentObjectIsExternalSystem,
                )
          }
          id="clean_subcategory_id"
          label="Подкатегория по уборке"
          name="clean_subcategory_id"
          options={cleanSubcategoryList}
          required={requiredSubcategory}
          title={oldLabel2 && `Предыдущее значение: ${oldLabel2}`}
          value={clean_subcategory_id}
          onChange={(value) => {
            return props.input.onChange({
              clean_category_id,
              clean_subcategory_id: value,
            });
          }}
        />
      </div>
    </div>
  );
}
