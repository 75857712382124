import { log } from 'core/utils/log';
import moment from 'moment';

const START_TIME = {
  milliseconds: 0,
  minute: 0,
  seconds: 0,
};

/**
 * Fn.
 *
 * @deprecated
 * @param {*} date - Жопа.
 * @returns {*} - Жопа.
 * @example --------
 */
export function stripTime(date) {
  date.setHours(0, 0, 0, 0);
  return date;
}

/**
 * Fn.
 *
 * @deprecated
 * @param {*} date - Жопа.
 * @returns {Date} - Жопа.
 * @example --------
 */
export function getLastDayOfYear(date) {
  return new Date(date.getFullYear(), 11, 31);
}

/**
 * Fn.
 *
 * @deprecated
 * @param {*} date - Жопа.
 * @returns {Date} - Жопа.
 * @example --------
 */
export function getFirstDayOfYear(date) {
  return new Date(date.getFullYear(), 0, 1);
}

/**
 * Fn.
 *
 * @deprecated
 * @param {*} date - Жопа.
 * @returns {Date} - Жопа.
 * @example --------
 */
export function getFirstDayOfCurrentMonth(date) {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

/**
 * Fn.
 *
 * @deprecated
 * @param {*} date - Жопа.
 * @returns {Date} - Жопа.
 * @example --------
 */
export function getLastDayOfCurrentMonth(date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

/**
 * Fn.
 *
 * @deprecated
 * @returns {moment} - Жопа.
 * @example --------
 */
export function getServerTime() {
  return moment().add(0, 'seconds');
}

/**
 * Fn.
 *
 * @deprecated
 * @returns {Date} - Жопа.
 * @example --------
 */
export function getChangeCarDate() {
  const date = new Date();
  date.setHours(date.getHours() + 1 + (date.getMinutes() > 0 ? 1 : 0));
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}

/**
 * Fn.
 *
 * @deprecated
 * @returns {string} - Жопа.
 * @example --------
 */
export function getStartDate() {
  const date = Date.now();
  return moment(date).format('DD.MM.YYYY HH:mm');
}

/**
 * Fn.
 *
 * @deprecated
 * @returns {string} - Жопа.
 * @example --------
 */
export function getNowDate() {
  const date = Date.now();
  return moment(date).format('DD.MM.YYYY');
}

/**
 * Fn.
 *
 * @deprecated
 * @returns {Date} - Жопа.
 * @example --------
 */
export function getYesterdayDate() {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  return date;
}

/**
 * Округляет минуты до часа в большую сторону, и прибавляет час  * пример: для времени 20:05 вернет 22:00.
 *
 * @deprecated
 * @param {*} date - Жопа.
 * @param {*} format - Жопа.
 * @returns {Date} - Жопа.
 * @example --------
 */
export function setRoundDate(date, format = 'DD.MM.YYYY HH:mm') {
  let convetedDateToMomentObject = moment();

  if (typeof date === 'string') {
    convetedDateToMomentObject = moment(date, format);
  }

  if (convetedDateToMomentObject.minutes() !== 0) {
    convetedDateToMomentObject.add(1, 'hours');
    convetedDateToMomentObject.set(START_TIME);
  }

  return convetedDateToMomentObject.toDate();
}

const todayDateAndHoursMoment = moment().set(START_TIME);

/**
 * Fn.
 *
 * @deprecated
 * @see используйget CurrentDate
 * @type {Date}
 * @example --------
 */
export const todayDateAndHours = todayDateAndHoursMoment.toDate();

/**
 * Fn.
 *
 * @deprecated
 * @type {Date}
 * @example --------
 */
export const tomorrowDateAndHours = todayDateAndHoursMoment
  .add(1, 'days')
  .toDate();

/**
 * Fn.
 *
 * @deprecated
 * @type {string}
 * @example --------
 */
export const tomorrowDate = moment().add(1, 'days').format('DD.MM.YYYY');

/**
 * Fn.
 *
 * @deprecated
 * @type {string}
 * @example --------
 */
export const tomorrowDateString =
  moment(tomorrowDateAndHours).format('DD.MM.YYYY');

/**
 * Fn.
 *
 * @deprecated
 * @type {Date}
 * @example --------
 */
export const dayWeekAgo = todayDateAndHoursMoment.weekday(-1).toDate();

/**
 * Fn.
 *
 * @deprecated
 * @type {Date}
 * @example --------
 */
export const dayPlusMonth = moment().add(1, 'month').toDate();

/**
 * Fn.
 *
 * @deprecated
 * @see import { getCurrentDate } from 'app/utils/date/getCurrentDate';
 * @type {Date}
 * @example --------
 */
export const currentDate = moment().set(START_TIME).toDate();

/**
 * F.
 *
 * @deprecated
 * @see src/app/utils/date/toDate
 * @param {*} date - Жопа.
 * @returns {string} - Жопа.
 * @example --------
 */
export const toDate = (date) => moment(date).format('DD.MM.YYYY');

/**
 * Fn.
 *
 * @deprecated
 * @param {*} date - Жопа.
 * @returns {string} - Жопа.
 * @example --------
 */
export const toDateTime = (date) =>
  date
    ? moment(date).format('DD.MM.YYYY HH:mm')
    : moment().format('DD.MM.YYYY HH:mm');

/**
 * Fn.
 *
 * @deprecated
 * @type {string}
 * @example --------
 */
export const currentDateWithTime = moment().format('DD.MM.YYYY HH:mm');

/**
 * Fn
 * 16.00 - прибавляем только 1 час = 17.00
 * 16:01 - округляем до 17.00 + 1 час=18.00
 * https://jira.elocont.ru/browse/ODS-7849?focusedCommentId=496175&page=com.atlassian.jira.plugin.system.issuetabpanels:comment-tabpanel#comment-496175 .
 *
 * @deprecated
 * @returns {Date} - Жопа.
 * @example --------
 */
export const currentDateLoshak = () => {
  log.banana(
    'эта функция currentDateLoshak устарела, заюзай функцию setRoundDate',
  );
  const date = new Date();
  date.setHours(date.getHours() + 1 + (date.getMinutes() > 0 ? 1 : 0));
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
};

/**
 * Fn.
 *
 * @deprecated
 * @param {*} date - Жопа.
 * @returns {boolean} - Жопа.
 * @example --------
 */
export const isFuture = (date) => {
  return moment(date).isAfter(moment());
};

/**
 * Fn.
 *
 * @deprecated
 * @param {*} date - Жопа.
 * @returns {boolean} - Жопа.
 * @example --------
 */
export const isPast = (date) => !isFuture(date);
