/* eslint-disable */
import React from 'react';
import { useField } from 'react-final-form';
import { KeyboardDatePickerFF } from 'core/form/finalForm/fields/default/datePickers/KeyboardDatePickerFF/KeyboardDatePickerFF';
import { WORK_START_DATE } from './WorkStartDate';

 
export const WORK_END_DATE = 'workenddate';

const WorkEndDate = ({ ...props }) => {
  const dateFromInput = useField(WORK_START_DATE);
  return (
    <KeyboardDatePickerFF
      minDate={dateFromInput?.input?.value}
      {...props}
      label="Дата окончания работ"
      name={WORK_END_DATE}
    />
  );
};

 
export default WorkEndDate;
