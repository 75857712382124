import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useGetDtsTypes } from 'app/components/card/ogh/dts/useGetDtsTypes';
import { useGetParamsUrl } from 'core/utils/hooks/useGetParamsUrl';
import { vi } from 'vitest';

vi.mock(
  'app/api/hooks/autocomplete/useGetDictionary',
  async (importOriginal) => {
    const mod = await importOriginal();
    return {
      ...mod,
      useGetDictionary: vi.fn((dict) => {
        switch (dict) {
          case 'dtsType': {
            return { data: dts_type };
          }
          default:
            return { data: [22] };
        }
      }),
    };
  },
);
vi.mock('core/utils/hooks/useGetParamsUrl');
vi.mock('app/api/hooks/useGetParentCard');
vi.mock('app/api/hooks/useGetCurrentCard');

/**
 * MockDefault.
 *
 * @returns {void}
 */
const mockDefault = () => {
  useGetParamsUrl.mockReturnValue({});
  useGetCurrentCard.mockReturnValue({});
  useGetParentCard.mockReturnValue({});
};

describe('spec useGetDtsTypes', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    mockDefault();
    useGetDictionary.mockReturnValue({});
    // 🧹 clear mock

    // 🔥 Act
    const res = useGetDtsTypes();

    // ❓ Assert
    expect(res).toStrictEqual([]);
  });

  it('🧪 подготовка словаря и нет parentTypeId', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    mockDefault();
    // 🧹 clear mock

    // 🔥 Act
    const res = useGetDtsTypes();

    // ❓ Assert
    expect(res).toStrictEqual(preparedDict);
  });

  it('🧪 format словаря есть parentTypeId', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    mockDefault();
    useGetCurrentCard.mockReturnValue({ parentInfo: { type_id: 39 } });
    useGetParentCard.mockReturnValue({ typeId: 32 });
    // 🧹 clear mock

    // 🔥 Act
    const res = useGetDtsTypes();

    // ❓ Assert
    expect(res).toStrictEqual([
      {
        id: 32,
        name: 'Велосипедная дорожка',
        parent_ogh_object_type_list: [
          { ogh_object_type_id: { ogh_object_type: 32 } },
          { ogh_object_type_id: { ogh_object_type: 40 } },
          { ogh_object_type_id: { ogh_object_type: 49 } },
          {
            ogh_object_type_id: { ogh_object_type: 39 },
            other_improvement_object_type_list: [
              {
                other_improvement_object_type_id: {
                  other_improvement_object_type: 1,
                },
              },
              {
                other_improvement_object_type_id: {
                  other_improvement_object_type: 2,
                },
              },
            ],
          },
        ],
      },
    ]);
  });

  it('🧪 otherImprovementObjectTypeId 1 if', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    mockDefault();
    useGetCurrentCard.mockReturnValue({
      parentInfo: { other_improvement_object_type_id: 39, type_id: 39 },
    });
    useGetParentCard.mockReturnValue({ typeId: 39 });
    // 🧹 clear mock

    // 🔥 Act
    const res = useGetDtsTypes();

    // ❓ Assert
    expect(res).toStrictEqual([
      {
        id: 32,
        name: 'Велосипедная дорожка',
        parent_ogh_object_type_list: [
          { ogh_object_type_id: 32 },
          { ogh_object_type_id: 40 },
          { ogh_object_type_id: 49 },
          {
            ogh_object_type_id: 39,
            other_improvement_object_type_list: [1, 2],
          },
        ],
      },
      {
        id: 42,
        name: 'Искусственная дорожная неровность',
        parent_ogh_object_type_list: [
          { ogh_object_type_id: 38 },
          { ogh_object_type_id: 40 },
          { ogh_object_type_id: 49 },
          {
            ogh_object_type_id: 39,
            other_improvement_object_type_list: [1, 2],
          },
        ],
      },
      {
        id: 27,
        name: 'Пешеходная дорожка',
        parent_ogh_object_type_list: [
          { ogh_object_type_id: 38 },
          { ogh_object_type_id: 40 },
          { ogh_object_type_id: 49 },
          {
            ogh_object_type_id: 39,
            other_improvement_object_type_list: [1, 2],
          },
        ],
      },
      {
        id: 25,
        name: 'Проезд',
        parent_ogh_object_type_list: [
          { ogh_object_type_id: 38 },
          { ogh_object_type_id: 40 },
          { ogh_object_type_id: 49 },
          {
            ogh_object_type_id: 39,
            other_improvement_object_type_list: [1, 2],
          },
        ],
      },
      {
        id: 43,
        name: 'Технический (технологический) тротуар',
        parent_ogh_object_type_list: [
          { ogh_object_type_id: 38 },
          { ogh_object_type_id: 40 },
          { ogh_object_type_id: 49 },
          {
            ogh_object_type_id: 39,
            other_improvement_object_type_list: [1, 2],
          },
        ],
      },
      {
        id: 26,
        name: 'Тротуар',
        parent_ogh_object_type_list: [
          { ogh_object_type_id: 38 },
          { ogh_object_type_id: 40 },
          { ogh_object_type_id: 49 },
          {
            ogh_object_type_id: 39,
            other_improvement_object_type_list: [1, 2],
          },
        ],
      },
    ]);
  });
});

/**
 * Когда просто подготовили словарь через мап.
 */
const preparedDict = [
  {
    id: 32,
    name: 'Велосипедная дорожка',
    parent_ogh_object_type_list: [
      { ogh_object_type_id: { ogh_object_type: 32 } },
      { ogh_object_type_id: { ogh_object_type: 40 } },
      { ogh_object_type_id: { ogh_object_type: 49 } },
      {
        ogh_object_type_id: { ogh_object_type: 39 },
        other_improvement_object_type_list: [
          {
            other_improvement_object_type_id: {
              other_improvement_object_type: 1,
            },
          },
          {
            other_improvement_object_type_id: {
              other_improvement_object_type: 2,
            },
          },
        ],
      },
    ],
  },
  {
    id: 42,
    name: 'Искусственная дорожная неровность',
    parent_ogh_object_type_list: [
      { ogh_object_type_id: { ogh_object_type: 38 } },
      { ogh_object_type_id: { ogh_object_type: 40 } },
      { ogh_object_type_id: { ogh_object_type: 49 } },
      {
        ogh_object_type_id: { ogh_object_type: 39 },
        other_improvement_object_type_list: [
          {
            other_improvement_object_type_id: {
              other_improvement_object_type: 1,
            },
          },
          {
            other_improvement_object_type_id: {
              other_improvement_object_type: 2,
            },
          },
        ],
      },
    ],
  },
  {
    id: 101,
    name: 'Мост пешеходный',
    parent_ogh_object_type_list: [
      { ogh_object_type_id: { ogh_object_type: 49 } },
    ],
  },
  {
    id: 27,
    name: 'Пешеходная дорожка',
    parent_ogh_object_type_list: [
      { ogh_object_type_id: { ogh_object_type: 38 } },
      { ogh_object_type_id: { ogh_object_type: 40 } },
      { ogh_object_type_id: { ogh_object_type: 49 } },
      {
        ogh_object_type_id: { ogh_object_type: 39 },
        other_improvement_object_type_list: [
          {
            other_improvement_object_type_id: {
              other_improvement_object_type: 1,
            },
          },
          {
            other_improvement_object_type_id: {
              other_improvement_object_type: 2,
            },
          },
        ],
      },
    ],
  },
  {
    id: 25,
    name: 'Проезд',
    parent_ogh_object_type_list: [
      { ogh_object_type_id: { ogh_object_type: 38 } },
      { ogh_object_type_id: { ogh_object_type: 40 } },
      { ogh_object_type_id: { ogh_object_type: 49 } },
      {
        ogh_object_type_id: { ogh_object_type: 39 },
        other_improvement_object_type_list: [
          {
            other_improvement_object_type_id: {
              other_improvement_object_type: 1,
            },
          },
          {
            other_improvement_object_type_id: {
              other_improvement_object_type: 2,
            },
          },
        ],
      },
    ],
  },
  {
    id: 43,
    name: 'Технический (технологический) тротуар',
    parent_ogh_object_type_list: [
      { ogh_object_type_id: { ogh_object_type: 38 } },
      { ogh_object_type_id: { ogh_object_type: 40 } },
      { ogh_object_type_id: { ogh_object_type: 49 } },
      {
        ogh_object_type_id: { ogh_object_type: 39 },
        other_improvement_object_type_list: [
          {
            other_improvement_object_type_id: {
              other_improvement_object_type: 1,
            },
          },
          {
            other_improvement_object_type_id: {
              other_improvement_object_type: 2,
            },
          },
        ],
      },
    ],
  },
  {
    id: 26,
    name: 'Тротуар',
    parent_ogh_object_type_list: [
      { ogh_object_type_id: { ogh_object_type: 38 } },
      { ogh_object_type_id: { ogh_object_type: 40 } },
      { ogh_object_type_id: { ogh_object_type: 49 } },
      {
        ogh_object_type_id: { ogh_object_type: 39 },
        other_improvement_object_type_list: [
          {
            other_improvement_object_type_id: {
              other_improvement_object_type: 1,
            },
          },
          {
            other_improvement_object_type_id: {
              other_improvement_object_type: 2,
            },
          },
        ],
      },
    ],
  },
  {
    id: 100,
    name: 'Экологические тропы',
    parent_ogh_object_type_list: [
      { ogh_object_type_id: { ogh_object_type: 49 } },
    ],
  },
];
const dts_type = [
  {
    attribute: {
      code: 'bicycle_lane',
      migr_code: ['BYKE_WAYS'],
      name: 'Велосипедная дорожка',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 32,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 40,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 49,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 39,
          },
          other_improvement_object_type_list: [
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 1,
              },
            },
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 2,
              },
            },
          ],
        },
      ],
    },
    id: 32,
  },
  {
    attribute: {
      code: 'artificial_road_roughness',
      migr_code: [],
      name: 'Искусственная дорожная неровность',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 38,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 40,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 49,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 39,
          },
          other_improvement_object_type_list: [
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 1,
              },
            },
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 2,
              },
            },
          ],
        },
      ],
    },
    id: 42,
  },
  {
    attribute: {
      code: '101',
      migr_code: [],
      name: 'Мост пешеходный',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 49,
          },
        },
      ],
    },
    id: 101,
  },
  {
    attribute: {
      code: 'footpath',
      migr_code: ['FOOT_WAYS'],
      name: 'Пешеходная дорожка',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 38,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 40,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 49,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 39,
          },
          other_improvement_object_type_list: [
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 1,
              },
            },
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 2,
              },
            },
          ],
        },
      ],
    },
    id: 27,
  },
  {
    attribute: {
      code: 'passage',
      migr_code: ['WAYS', 'DRIVE_WAYS'],
      name: 'Проезд',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 38,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 40,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 49,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 39,
          },
          other_improvement_object_type_list: [
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 1,
              },
            },
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 2,
              },
            },
          ],
        },
      ],
    },
    id: 25,
  },
  {
    attribute: {
      code: 'technical_sidewalk',
      migr_code: [],
      name: 'Технический (технологический) тротуар',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 38,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 40,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 49,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 39,
          },
          other_improvement_object_type_list: [
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 1,
              },
            },
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 2,
              },
            },
          ],
        },
      ],
    },
    id: 43,
  },
  {
    attribute: {
      code: 'sidewalk',
      migr_code: ['SIDE_WAYS'],
      name: 'Тротуар',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 38,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 40,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 49,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 39,
          },
          other_improvement_object_type_list: [
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 1,
              },
            },
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 2,
              },
            },
          ],
        },
      ],
    },
    id: 26,
  },
  {
    attribute: {
      code: 'eco_ways',
      migr_code: [],
      name: 'Экологические тропы',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 49,
          },
        },
      ],
    },
    id: 100,
  },
];
