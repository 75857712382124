import NavItem from 'app/components/common/NavBar/NavItem';
import { pathNews } from 'app/pages/admin/News/path.News';
import { pathAdmin } from 'app/pages/admin/path.Admin';
import { useUser } from 'app/pages/providers';
import lazyWithPreload from 'lazy-with-preload';
import React, { useEffect } from 'react';

const PatternMatching = lazyWithPreload(() => import('app/pages/admin/News'));

/**
 * Link Админки новостей в шапке сайта.
 *
 * @returns {JSX.Element | null} - JSX.
 */
export const NewsLink = () => {
  const { hasPermission } = useUser();

  const isShow = hasPermission(pathNews.privilegeView ?? '');

  useEffect(() => {
    isShow && PatternMatching.preload();
  }, [isShow]);

  return isShow ? (
    <NavItem link={`/${pathAdmin.path}/${pathNews.path}`}>
      {pathNews.name}
    </NavItem>
  ) : null;
};
