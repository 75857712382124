import { BodyContext } from 'app/pages/providers/BodyContext';
import { pathOgh } from 'app/pages/registry/ogh/Ogh/path.Ogh';
import { pathRegistry } from 'app/pages/registry/path.Registry';
import { getValidator } from 'core/form/finalForm/utils/validate';
import dateFormatService from 'core/services/dateFormatService';
import { FullScreenLoading } from 'core/uiKit/components/loaders/FullScreenLoading';
import React, { useContext, useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';

import { DialogErrors } from '../DialogErrors';
import * as api from './api';
import { FormDialogCreateOGH } from './Form.DialogCreateOGH';
import { createFileDXF } from './helpers/createFileDXF';

/**
 * Валидация.
 *
 * @param {object} values - Значения формы.
 * @returns {{}|null}
 */
const validate = (values) => {
  const { hasErrors, getErrors } = getValidator(values);

  return hasErrors() ? getErrors() : null;
};

/**
 * Пулл ошибок - показать диалогом.
 *
 * @param {object} error - Ошибка.
 * @returns {Promise<{}|*|boolean>}
 */
const exceptionBehaviour = async (error = {}) => {
  switch (error.status) {
    // пулл ошибок - показать диалогом
    case 400:
      if ('json' in error) {
        return await error.json();
      }
      return error;
    default:
  }
  return false;
};

/**
 * Компонент создания диалогового окна DialogCreateOGH.
 *
 @param {object} props - Пропсы.
 @param {React.ReactChildren} props.children - Дети.
 @param {number} props.typeId - Тип ОГХ.
 * @returns {JSX.Element}
 */
export const DialogCreateOGH = ({ children, typeId }) => {
  const navigate = useNavigate();
  const { toggleShowDialogCreate } = useContext(BodyContext);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const matchWithChild = useMatch(
    `/${pathRegistry.path}/${pathOgh.path}/:parent/:child`,
  );
  const matchOnlyParent =
    useMatch(`/${pathRegistry.path}/${pathOgh.path}/:parent`) || {};

  const { params = {} } = matchWithChild || matchOnlyParent;

  const path = [params.parent, params.child].filter(Boolean).join('/');

  /**
   * Функция submit.
   *
   * @param {object} formValues - Значения формы.
   * @returns {void}
   */
  const onSubmit = async (formValues) => {
    const errors = validate(formValues);
    if (errors) return errors;

    const { startDate, file } = formValues;
    const formattedDate = dateFormatService.format(startDate, 'DDMMYYYY');

    const createUrl = `/${pathOgh.path}/${path}/create/${formattedDate}?edit=1`;
    if (file) {
      setLoading(true);
      let response;
      try {
        response = await api.linkDxfFile(
          startDate,
          createFileDXF(file),
          typeId,
        );
        toggleShowDialogCreate(false);
        navigate({
          pathname: `/ogh/${response.id}`,
          state: response.id,
        });
      } catch (error) {
        const eb = await exceptionBehaviour(error);
        if (eb) {
          setErrors(eb);
        }
      } finally {
        setLoading(false);
      }
    } else {
      navigate(createUrl);
    }
  };
  return (
    <>
      <FormDialogCreateOGH typeId={typeId} onSubmit={onSubmit}>
        {children}
      </FormDialogCreateOGH>
      <DialogErrors errors={errors} />
      <FullScreenLoading isFetching={loading} />
    </>
  );
};
