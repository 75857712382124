import { cardToggleStyles } from 'app/components/card/ogh/index/card/styles';
import { ArrowHeader } from 'app/pages/cardsOgh/components/RightPanelCard/components/icons/ArrowHeader';
import IconButton from 'core/newComponents/IconButton';
import { shallow } from 'enzyme';
import jquery from 'jquery';
import { useState } from 'react';
import { vi } from 'vitest';

import RightPanel from './RightPanel';

vi.mock('jquery');

vi.mock('react', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    useState: vi.fn(),
  };
});

/**
 * Компонент для теста.
 *
 * @returns {JSX.Element}
 */
const PanelMap = () => {
  return <div>Панель управления</div>;
};

describe('RightPanel', function () {
  it('test click and render ArrowHeader', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const setStateSpy = vi.fn();
    useState.mockReturnValue([false, setStateSpy]);

    const toggleClassSpy = vi.fn();
    jquery.mockReturnValue({ toggleClass: toggleClassSpy });

    // Act
    const wrapper = shallow(<RightPanel panel={PanelMap} />);

    const buttonArrowHeader = wrapper.find(ArrowHeader).props();

    buttonArrowHeader.onClick();

    // Assert
    expect(jquery).toHaveBeenCalledWith('#right-panel');
    expect(toggleClassSpy).toHaveBeenCalledWith('bottom-auto');
    expect(setStateSpy).toHaveBeenCalledTimes(1);
    expect(buttonArrowHeader).toStrictEqual({
      active: false,
      onClick: expect.any(Function),
    });
  });
  it('test click and render IconButton', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const setStateSpy = vi.fn();
    useState.mockReturnValue([false, setStateSpy]);

    const toggleSpy = vi.fn();
    const resizeSpy = vi.fn();
    jquery.mockReturnValue({ resize: resizeSpy, toggle: toggleSpy });

    // Act
    const wrapper = shallow(<RightPanel panel={PanelMap} />);

    const buttonIcon = wrapper.find(IconButton).props();

    buttonIcon.onClick();

    // Assert
    expect(jquery).toHaveBeenCalledWith('.ods-core-left-panel');
    expect(toggleSpy).toHaveBeenCalledTimes(1);
    expect(resizeSpy).toHaveBeenCalledTimes(1);
    expect(buttonIcon).toStrictEqual({
      className:
        'right-panel__card-toggle card-toggle fa fa-angle-double-right boxArea',
      edge: false,
      onClick: buttonIcon.onClick,
      style: cardToggleStyles,
    });
  });
});
