import { validateEngineeringStructuresTab } from 'app/components/card/ogh/Odh/components/tabs/EngineeringStructures.Tab/validateEngineeringStructuresTab';

describe('ValidateEngineeringStructuresTabJestSpec', function () {
  it('Валидация поля без выбранного чекбокса автомобильная дорога', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    //?
    // Assert
    expect(validateEngineeringStructuresTab()).toBe(null);
  });
  it('Валидация поля с выбранным чекбоксом автомобильная дорога', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    //?
    // Assert
    expect(validateEngineeringStructuresTab({ is_avd: true })).toStrictEqual({
      bridge_psc: 'обязательное поле',
      bridge_qty: 'обязательное поле',
      bridge_sqr: 'обязательное поле',
      cable_fancing_psc: 'обязательное поле',
      decor_psc: 'обязательное поле',
      lamp_qty: 'обязательное поле',
      light_psc: 'обязательное поле',
      light_qty: 'обязательное поле',
      pavilion_qty: 'обязательное поле',
      pedestrian_psc: 'обязательное поле',
      pedestrian_qty: 'обязательное поле',
      pedestrian_sqr: 'обязательное поле',
      police_qty: 'обязательное поле',
      police_sqr: 'обязательное поле',
      sandbase_qty: 'обязательное поле',
      sandbase_sqr: 'обязательное поле',
      snow_psc: 'обязательное поле',
      support_qty: 'обязательное поле',
      tlight_qty: 'обязательное поле',
      transform_qty: 'обязательное поле',
      tube_psc: 'обязательное поле',
      tube_qty: 'обязательное поле',
      urn_qty: 'обязательное поле',
      wall_psc: 'обязательное поле',
      water_psc: 'обязательное поле',
      сase_outside_light_qty: 'обязательное поле',
    });
  });
});
