/* eslint-disable  */
import { Select as SelectD } from 'core/uiKit/inputs/selects/Select';
import { log } from 'core/utils/log';

/**
 * Select.
 *
 * @deprecated
 */
const Select = SelectD;
export default Select;
/**
 * Action.
 *
 * @deprecated
 * @param {*} a - A.
 * @returns {void }
 */
export const action = (...a) => log.info(a);
/**
 * OnChangeWith.
 *
 * @deprecated
 * @param {*} a - A.
 * @returns {void }
 */
export const onChangeWith = (...a) => log.info(a);
