/* eslint-disable */
import * as U from 'app/constants/unitCodes';
import getFormValues from 'app/selectors/form/getFormValues';
import getDict from 'app/selectors/getDict';
import { createSelector } from 'reselect';

export const unitsDigitsDict = {
  [U.METERS]: 2,
  [U.METERS_SQUARE]: 2,
  [U.METERS_LONG]: 1,
  [U.NUMBERS]: 0,
  [U.PIECES]: 0,
  [U.TREE]: 0,
  [U.BUSH]: 0,
  [U.HECTARE]: 2,
  [U.RUBLES]: 2,
  [U.TONS]: 2,
  [U.METERS_CUBIC]: 2,
  [U.LITRES]: 2,
};

const getCurrentUnitId = (state) => getFormValues(state).unit_id;

export default createSelector(
  [getDict('units'), getCurrentUnitId],
  (units, currentUnitId) => {
    const currentUnit = units.find((item) => item.id === currentUnitId);
    return currentUnit ? unitsDigitsDict[currentUnit.code] : 0;
  },
);
