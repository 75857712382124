import { OkrugListRF } from 'core/form/reduxForm/fields/prepared/selects/multiSelect/OkrugList.RF';
import { RANDOM_BOOL } from 'core/forTesting/constants';
import { DISTRICT_LIST_NAME } from 'core/uiKit/preparedInputs/selects/multiSelect/DistrictList';
import { shallow } from 'enzyme';
import React from 'react';

import { OkrugListField } from './OkrugListField';

/* eslint-disable */
describe('title OkrugListField', () => {
  it('test props', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const props = {
      editable: RANDOM_BOOL,
    };
    // Act
    const wrapper = shallow(<OkrugListField {...props} />);
    const { withChange } = wrapper.props();
    // Assert
    expect(
      wrapper.contains(
        <OkrugListRF
          disabled={!RANDOM_BOOL}
          required
          withChange={withChange}
        />,
      ),
    ).toBeTruthy();
  });
  it('test withChange', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const change = jest.fn();
    const props = {
      editable: RANDOM_BOOL,
      change,
    };
    // Act
    const wrapper = shallow(<OkrugListField {...props} />);
    const { withChange } = wrapper.props();
    withChange();
    // Assert
    expect(change).toBeCalledWith(DISTRICT_LIST_NAME, []);
  });
});
