import { columns } from './columns';

/* eslint-disable */
const columnsExpanded = [
  {
    accessor: 'section_num',
    Header: 'Номер участка',
    rowSpan: 2,
  },
  {
    accessor: 'total_lawn_area',
    Header: 'Площадь газона, кв. м.',
    rowSpan: 2,
  },
  {
    accessor: 'lawn_type',
    Header: 'Тип газона, кв. м',
    columns: [
      {
        accessor: 'lawn_type_plain_area',
        Header: 'Обыкновенный, кв.м.',
      },
      {
        accessor: 'lawn_type_meadow_area',
        Header: 'Луговой / разнотравный, кв.м.',
      },
      {
        accessor: 'lawn_type_grid_area',
        Header: 'На ячеистом основании / экопарковки, кв.м.',
      },
      {
        accessor: 'lawn_type_parterre_area',
        Header: 'Партерный, кв.м.',
      },
      {
        accessor: 'lawn_type_slope_area',
        Header: 'На откосе, кв.м.',
      },
      {
        accessor: 'lawn_type_other_area',
        Header: 'Иного типа, кв.м.',
      },
    ],
  },
  {
    accessor: 'state_gardening',
    Header: 'Состояние газона',
    columns: [
      {
        accessor: 'state_gardening_1',
        Header: 'Хорошее',
      },
      {
        accessor: 'state_gardening_2',
        Header: 'Удовлетворительное',
      },
      {
        accessor: 'state_gardening_3',
        Header: 'Неудовлетворительное',
      },
    ],
  },
];

 
describe('file columns.spec', () => {
  it('should', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act

    // Assert
    expect(columns).toStrictEqual(columnsExpanded);
  });
});
