import { Link as LinkD } from 'core/uiKit/components/Link';

/**
 * Компонент Link.
 *
 * @deprecated
 * @see Link core/uiKit/components/Link
 */
const Link = LinkD;

export default Link;
