import {
  CONTAINER,
  DTS,
  FLOWERS_GARDEN,
  FUNCTIONALITY,
  LAWN,
  LITTLE_FORM,
  OTHER_OKS,
  PLANAR_STRUCTURE,
  PPI,
  RED_BOOK_PLANT,
  RELIEF,
  STORAGE_PLACE,
  TREES_SHRUBS,
  WATER_BUILDING,
} from 'app/constants/oghTypes';

const passports = [
  CONTAINER,
  PLANAR_STRUCTURE,
  STORAGE_PLACE,
  DTS,
  PPI,
  RELIEF,
  FUNCTIONALITY,
  LITTLE_FORM,
  LAWN,
  FLOWERS_GARDEN,
  TREES_SHRUBS,
  OTHER_OKS,
  WATER_BUILDING,
  RED_BOOK_PLANT,
];

/**
 * Функция на получения разрешения отображения кнопок "Учитывать" "Не учитывать".
 *
 * @param {number} typeId - Тип ОГХ.
 * @returns {boolean}
 */
export const getPermissionNoCalc = (typeId: number): boolean => {
  return passports.includes(typeId);
};
