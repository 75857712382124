import * as api from 'app/components/card/ogh/index/card/components/Bound/api';
/* eslint-disable */
import { queryClient } from 'app/routes/QueryClientProvider';
import { ContinueFFDialog } from 'core/form/finalForm/components/dialogs/ContinueFF.Dialog';
import { TextFieldFF } from 'core/form/finalForm/fields/default/TextFieldFF';
import { DndFileUploadFF } from 'core/form/finalForm/fields/prepared/fileUpload/DndFileUploadFF';
import { getValidator } from 'core/form/finalForm/utils/validate';
import { toast } from 'core/uiKit/components';
import FixProblemsBoundariesOghButton from 'core/uiKit/components/buttons/actionsButtons/FixProblemsBoundariesOgh.Button';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import { useNavigateRouter } from 'core/utils/router';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

/**
 *
 * @param values
 */
const validate = (values) => {
  const { setRequired, hasErrors, getErrors } = getValidator(values);
  setRequired('comment');
  return hasErrors() ? getErrors() : null;
};

/* eslint-disable */
/**
 * Отклонить границы
 * @param editMode
 * @returns {JSX.Element|null}
 * @constructor
 */
const FixProblemsBoundaries = ({ editMode }) => {
  const navigate = useNavigateRouter();
  const { childId, id } = useParams();

  const [isOpen, setIsOpen] = useState(false);

  const objectId = childId || id;
  const openDialog = () => setIsOpen(true);
  const closeDialog = () => {
    queryClient.invalidateQueries('getVisibilityButtonsOgh');
    setIsOpen(false);
  };

  const [send, { isLoading }] = useMutationAdaptor(api.rejectMatchingBounds, {
    onSuccess: () => {
      toast.success('Согласование границ отклонено');
      closeDialog();
      queryClient.invalidateQueries('fetchMatchingBoundariesTable');
      queryClient.invalidateQueries('fetchMatchingBoundariesTableCount');
      navigate('/r/matching-boundaries');
    },
  });

  const show = !editMode && objectId;

  return show ? (
    <>
      <ContinueFFDialog
        {...{
          textHeader: 'Отклонить границы объекта',
          isLoading,
          isOpen,
          closeDialog,
          validate,
          Body: (
            <>
              <TextFieldFF
                rows={4}
                multiline
                variant="outlined"
                label={'Комментарий'}
                required
                maxLength={1000}
                name={'comment'}
              />
              <DndFileUploadFF
                text={'Переместите файлы или нажмите для выбора'}
                name={'files'}
              />
            </>
          ),
          onSubmit: (value) => send({ ...value, objectId }),
        }}
      />
      <FixProblemsBoundariesOghButton
        objectId={objectId}
        onClick={openDialog}
      />
    </>
  ) : null;
};

const mapStateToProps = (state) => ({
  editMode: state.card?.mode?.editMode,
});

export default connect(mapStateToProps)(FixProblemsBoundaries);

FixProblemsBoundaries.propTypes = {
  /*
   * показывать целиком компоненту или нет
   */
  editMode: PropTypes.bool,
};
