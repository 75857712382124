/* eslint-disable */
import React from 'react';
import { NumberParam } from 'use-query-params';

import { TextFieldFF } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
export const SENSOR_CODE_NAME = 'sensorCode';

 
 
/**
 * Код закрепленного датчика       sensor_code     int
 */
 
export const SensorCodeFilter = () => {
  return (
    <TextFieldFF
      className={filterInput}
      label={'Код закрепленного датчика'}
      name={SENSOR_CODE_NAME}
      type="number"
    />
  );
};

 
export const sensorCodeParams = {
  [SENSOR_CODE_NAME]: NumberParam,
};
