/* eslint-disable */
import React from 'react';
import { StringParam } from 'use-query-params';

import { EntityFF, ENTITY_NAME } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
/* eslint-disable */
/**
 * Тип согласования
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

 
export const EntityFilter = (props) => (
  <EntityFF {...props} className={filterInput} />
);

 
export const entityParams = {
  [ENTITY_NAME]: StringParam,
};
