import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { RANDOM_ID } from 'core/forTesting/constants';
import { vi } from 'vitest';

import { useSetupArrangeElementTypeIdField } from './useSetup.ArrangeElementTypeId.Field';

vi.mock('app/pages/cardsOgh/hooks/useMode');
vi.mock('app/api/hooks/useIsEditCurrentObjectIsExternalSystem');
vi.mock('app/api/hooks/autocomplete/useGetDictionary');
vi.mock('app/api/hooks/useGetParentCard');
vi.mock('app/api/hooks/useGetCurrentCard');

/**
 * Mock Default.
 *
 * @returns {void}
 */
const mockDefault = () => {
  useMode.mockReturnValue({ editMode: true });
  useIsEditCurrentObjectIsExternalSystem.mockReturnValue(false);
  useGetParentCard.mockReturnValue({ typeId: RANDOM_ID });
  useGetCurrentCard.mockReturnValue({ typeId: RANDOM_ID });
};

describe('🐛 spec useSetup.ArrangeElementTypeId.Field', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock

    //🔥 Act
    const res = useSetupArrangeElementTypeIdField();

    //❓ Assert
    expect(res).toStrictEqual({ disabled: true, options: [] });
  });

  it('🧪 options', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();
    useGetParentCard.mockReturnValue({ typeId: 38 });
    useGetCurrentCard.mockReturnValue({ typeId: 34 });

    // 🧹 clear mock

    //🔥 Act
    const res = useSetupArrangeElementTypeIdField();

    //❓ Assert
    expect(res).toStrictEqual({
      disabled: true,
      options: [
        {
          code: 'gabion',
          id: 49,
          migr_code: [],
          name: 'Габион',
          ogh_object_type_id: {
            ogh_object_type: 34,
          },
          parent_ogh_object_type_list: [38, 39, 40, 49],
          unit_id: {
            unit: 2,
          },
        },
      ],
    });
  });
});
