import * as types from '../constants/actionTypes';

/* eslint-disable */
export default function technologyObject(state = {}, action) {
  switch (action.type) {
    case types.SET_TECH_TREE_PATHS:
      return { ...state, techTreePaths: action.object };
    case types.LOCATION_CHANGE:
      return { ...state, techTreePaths: undefined };
    default:
      return state;
  }
}
