import Box from '@mui/material/Box';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import React from 'react';

import { RawGeometry } from '../../contexts/utils/types';

export const HintOnHover = forwardRef((_props, ref) => {
  const $container = useRef<HTMLDivElement | null>(null);
  const $content = useRef<HTMLDivElement | null>(null);
  const hintSizeRef = useRef<[number, number]>([0, 0]);
  const contentRef = useRef<string>('');

  const prevHoveredObjectRef = useRef<RawGeometry | null>(null);
  const prevXYRef = useRef([0, 0]);

  /**
   * Close hint.
   *
   */
  const closeHint = () => {
    if ($content.current) $content.current.innerHTML = '';
    if ($container.current) $container.current.style.display = 'none';
  };

  useImperativeHandle(
    ref,
    () => ({
      closeHint,
      element: $container.current,

      /**
       * Set position of the hint.
       *
       * @param hoveredObject - Hovered object.
       * @param x - X coordinate.
       * @param y - Y coordinate.
       */
      // eslint-disable-next-line
      setPosition(hoveredObject: RawGeometry | null, x: number, y: number) {
        let hintContent = '';

        // Делаем ховер
        if (hoveredObject && prevHoveredObjectRef.current !== hoveredObject) {
          if ($container.current) $container.current.style.display = 'block';

          hoveredObject.userData.hint?.object_attribute_hint_short?.forEach(
            ({ value }: { key: string; value: string }) => {
              hintContent += `<p style="padding: 0; margin: 0;">${value}</p>`;
            },
          );
          if ($container.current) $container.current.style.width = '';
          const newContent = `<div>${hintContent}</div>`;
          contentRef.current = newContent;

          if ($content.current) $content.current.innerHTML = newContent;

          prevHoveredObjectRef.current = hoveredObject;

          const { height, width } =
            $container.current?.getBoundingClientRect() || {
              height: 0,
              width: 0,
            };
          hintSizeRef.current = [height, width];

          if ($container.current) $container.current.style.width = `${width}px`;
        }
        // eslint-disable-next-line
        prevXYRef.current = [x, y];

        if ($container.current) {
          const [height, width] = hintSizeRef.current;
          // eslint-disable-next-line
          $container.current.style.top = `${y - (height + 12)}px`;
          // eslint-disable-next-line
          $container.current.style.left = `${x - width / 2}px`;
        }

        if (!hoveredObject && prevHoveredObjectRef.current) {
          closeHint();
        }

        prevHoveredObjectRef.current = hoveredObject;
      },
    }),
    [],
  );

  return (
    <Box
      ref={$container}
      display="none"
      position="absolute"
      id="hover-hint"
      zIndex={1000000}
      padding={2}
      borderRadius={2}
      bgcolor="#1c1c1ce8"
      sx={{
        '& *': {
          pointerEvents: 'none',
        },
        '&:after': {
          borderLeft: '10px solid transparent',
          borderRight: '10px solid transparent',
          borderTop: '10px solid #1c1c1ce8',
          bottom: '0',
          content: '""',
          height: 0,
          left: '50%',
          pointerEvents: 'none',
          position: 'absolute',
          transform: 'translate(-50%, 100%)',
          width: 0,
        },
        pointerEvents: 'none',
      }}
    >
      <Box color="white" padding={0} ref={$content} />
    </Box>
  );
});
