import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const REASON_NAME = 'reason';

/**
 * ## Причина изменения.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const Reason = (props) => {
  const dict = 'technicRequestReason';
  const { data = [] } = useGetDictionary(dict);

  return (
    <Select
      label={'Причина изменения'}
      name={REASON_NAME}
      {...props}
      options={data}
    />
  );
};
