import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
/* eslint-disable */
import { getCurrentCard } from 'app/api/requests/getCurrentCard';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';
import { useMemo } from 'react';

/**
 *
 */
export const useGetSignDate = () => {
  const { parentInfo, signDate, signPerson } = useGetCurrentCard();
  const { data } = useQueryAdaptor(
    ['getCardParent', parentInfo?.parent_id],
    getCurrentCard,
    {
      enabled: !!parentInfo,
    },
  );

  return useMemo(() => {
    return data
      ? {
          signDate: data.sign_date,
          signPerson: data.sign_person,
        }
      : {
          signDate,
          signPerson,
        };
  }, [data, signDate, signPerson]);
};
