import * as grid from 'app/components/card/common/grid';
import { WithOldValueSelect } from 'app/components/common/SelectField';
import IconButton, {
  iconButtonStyle,
  iconStyle,
} from 'core/newComponents/IconButton';
import { ChevronRightIcon, ExpandMoreIcon } from 'core/uiKit/material-ui';
import React from 'react';

/* eslint-disable */
class SensorList extends React.Component {
  state = {
    visible: new Set(),
  };

  render() {
    return (
      <div>
        {this.props.list &&
          this.props.list.map((sensor) => {
            const sensorName = sensor.name;
            const uuid = sensor.uuid;
            const isVisible = this.state.visible.has(uuid);
            return (
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton
                    iconClassName="material-icons"
                    iconStyle={iconStyle}
                    style={iconButtonStyle}
                    onClick={() => {
                      if (isVisible) {
                        let visible = new Set(...this.state.visible);
                        visible.delete(uuid);
                        this.setState({ visible });
                      } else {
                        let visible = new Set(...this.state.visible);
                        visible.add(uuid);
                        this.setState({ visible });
                      }
                    }}
                  >
                    {isVisible ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                  </IconButton>
                  <h2>{sensorName}</h2>
                </div>
                <div style={{ paddingLeft: 57, marginTop: -10 }}>
                  <Sensor
                    visible={isVisible}
                    {...sensor}
                    mode={this.props.mode}
                    sensorStates={this.props.sensorStates}
                  />
                  <div
                    style={{
                      content: '',
                      display: 'table',
                      clear: 'both',
                    }}
                  />
                </div>
                <hr />
              </div>
            );
          })}
      </div>
    );
  }
}

/**
 *
 * @param props
 */
function Sensor(props) {
  const { mode } = props;

  if (!props.visible) {
    return null;
  }
  return (
    <div>
      {[
        grid.elementFormGrid(
          [
            {
              id: 'sensor_type_id',
              name: 'sensor_type_id',
              label: 'Тип датчика',
              editable: false,
              formValue: false,
              defaultValue: props.sensor_type_name,
              component: grid.renderCustomTextField,
            },
            {
              id: 'sensor_kind_id',
              name: 'sensor_kind_id',
              label: 'Вид датчика',
              editable: false,
              formValue: false,
              defaultValue: props.sensor_kind_name,
              component: grid.renderCustomTextField,
            },
            {
              id: 'sensor_code',
              name: 'sensor_code',
              label: 'Код датчика',
              editable: false,
              formValue: false,
              defaultValue: props.sensor_code,
              component: grid.renderCustomTextField,
              addition: { type: 'number', number: true },
            },
            {
              id: 'sensor_state_name',
              name: 'sensor_state_name',
              editable: (mode && mode.editMode) || false,
              formValue: true,
              component: WithOldValueSelect,
              addition: {
                label: 'Состояние',
                options: props.sensorStates,
                // input: { value: props.sensor_state_name },
              },
            },
          ],
          2,
          '',
          1,
        ),
        grid.elementFormGrid(
          [
            {
              id: 'sensor_calibration_table',
              name: 'sensor_calibration_table',
              label: '',
              style: { overflow: 'auto', height: 200 },
              formValue: false,
              editable: false,
              defaultValue: { table: props.sensor_calibration_table },
              component: grid.renderCalibrationTable,
            },
          ],
          1,
          '',
        ),
      ]}
    </div>
  );
}

export default SensorList;
