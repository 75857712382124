/* eslint-disable */
import React from 'react';
import { filterInput } from '../filterInputsClasses';
import { GROUPING_NAME, GroupingFF } from 'core/form/finalForm/fields';
import { StringParam, withDefault } from 'use-query-params';

 
export { GROUPING_NAME };

 
 
/**
 *     Детализация
 *     grouping
 */
 
export const GroupingFilter = (props) => (
  <GroupingFF {...props} className={filterInput} />
);

 
export const groupingParams = () => {
  return {
    [GROUPING_NAME]: withDefault(StringParam, 'none'),
  };
};
