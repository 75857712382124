import moment from 'moment';

/* eslint-disable */
export function parse() {
  const source = arguments[0];
  const typeId = arguments[1];
  const path = arguments[2];
  const allowedTypes = Array.prototype.slice.call(arguments, 3);
  if (allowedTypes.length === 0 || allowedTypes.indexOf(typeId) !== -1) {
    const result = path.split('.').reduce((result, pathElement) => {
      if (result === null) {
        return undefined;
      }
      return result === undefined ? result : result[pathElement];
    }, source);
    if (result !== undefined) {
      return result;
    }
  }
  return null;
}

 
 
/**
 * @deprecated
 * @see app/components/card/common/getParseAttribute
 * @param props
 * @returns {any}
 */
/* eslint-disable */
export function getParseAttribute(props) {
  return parse.bind(null, props.card.attribute, props.card.type_id);
}

/* eslint-disable */
export function getParseOldAttribute(props) {
  return parse.bind(null, props.card.old_attribute, props.card.type_id);
}

/* eslint-disable */
export function getParseCalcAttribute(props) {
  return parse.bind(null, props.card.calc_attribute, props.card.type_id);
}

/* eslint-disable */
export function parseDate(date) {
  let result = null;
  if (date instanceof Date) {
    return date;
  } else if (/^\d{2}\.\d{2}\.\d{4}$/.test(date || '')) {
    const dateArray = date.split('.');
    result = new Date(dateArray[2], dateArray[1] - 1, dateArray[0]);
  } else if (/^\d{2}\.\d{2}\.\d{4} \d{2}:\d{2}$/.test(date || '')) {
    result = moment(date || '', 'DD.MM.YYYY HH:mm').toDate();
  } else if (date) {
    result = new Date(Date.parse(date));
  }
  return result;
}
