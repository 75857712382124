import mafTypeService from 'app/services/mafTypeService';
import { createSelector } from 'reselect';

import getDict from '../getDict';
import getMafTypeLevel1Id from './getMafTypeLevel1Id';

 
export default createSelector(
  [getDict('mafTypeLevel3'), getMafTypeLevel1Id],
  (dict, mafTypeLevel1Id) => {
    let result;
    if (mafTypeLevel1Id) {
      result = mafTypeService.filterMafTypeLevel3(dict, mafTypeLevel1Id);
    } else {
      result = null;
    }
    return result;
  },
);
