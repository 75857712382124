import './Prelouder.scss';

import React from 'react';

/**
 * Компонент прелоудера ToggleButton.
 *
 * @param {boolean} isLoading - Флаг загрузки.
 * @returns {JSX.Element|null}
 */
export const PreLouder = ({ isLoading }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div className={'pre-louder__background'}>
      <div className={'pre-louder'}></div>
    </div>
  );
};
