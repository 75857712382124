import getDict from 'app/selectors/getDict';
/* eslint-disable  */
import { filter } from 'lodash';
import { createSelector } from 'reselect';

/**
 *
 * @param typeId
 */
export default (typeId) =>
  createSelector([getDict('buildingsTypeSpec')], (buildingsTypeSpec) => {
    return filter(buildingsTypeSpec, { ogh_object_type_id: typeId });
  });
