import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import { useEffect, useMemo, useState } from 'react';

import { useGetGroupId } from './useGetGroupId';

/**
 * Select __Является частью составного объекта__.
 *
 * @param {object} props - Properties.
 * @param {boolean} props.editMode - Edit mode.
 * @returns {JSX.Element}
 */
export const GroupIdRFPlanarStructureCard = ({ editMode }) => {
  const { recordId = 0, listGroupId = [] } = useGetCurrentCard();
  const checkedGroupId = useGetGroupId();
  const [includeId, setIncludeId] = useState(
    (listGroupId || []).some((item) => item.group_id === recordId),
  );

  useEffect(() => {
    if (recordId === checkedGroupId) {
      setIncludeId(true);
    }
    if (checkedGroupId === null) {
      setIncludeId(false);
    }
    return () => {
      setIncludeId(false);
    };
  }, [recordId, checkedGroupId, listGroupId]);

  const options = useMemo(() => {
    return [
      ...(listGroupId || [])
        .map(({ group_id }) => ({
          id: group_id,
          name: group_id,
        }))
        .filter(({ id }) => id !== recordId),
      {
        id: recordId,
        name: includeId ? recordId : '<cоздать новый group_id>',
      },
      { id: null, name: '<удалить group_id>' },
    ].filter(({ id }) => !(id === null && !includeId));
  }, [recordId, listGroupId, includeId]);

  return (
    <SelectRF
      disabled={!editMode}
      label={'Является частью составного объекта'}
      name={'group_id'}
      options={options}
      withAll={false}
      filter={(dict) => dict}
    />
  );
};
