import {
  MutationFunction,
  UseMutateFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';

/**
 * Хук, запроса мутации.
 *
 * @param mutationFn - Функция запроса мутации.
 * @param mutationOptions - Options.
 * @returns Массив Первый параметр функция вызова мутации; Второй параметр дополнительные свойства.
 */
export function useMutationAdaptor<
  TData = unknown,
  TError = unknown,
  TVariables = void,
>(
  mutationFn: MutationFunction<TData, TVariables>,
  mutationOptions?: Omit<
    UseMutationOptions<TData, TError, TVariables>,
    'mutationFn'
  >,
): [
  UseMutateFunction<TData, TError, TVariables>,
  UseMutationResult<TData, TError, TVariables> & { isLoading: boolean },
] {
  const mutation = useMutation({
    ...mutationOptions,
    mutationFn,
  });

  return [mutation.mutate, { ...mutation, isLoading: mutation?.isPending }];
}
