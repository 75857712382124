import { AutocompleteAsync } from 'core/uiKit/inputs/selects/AutocompleteAsync';
import React from 'react';

import { fetchRoles } from './api';

export const ROLE_NAME = 'role';

/**
 * ## Группа.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const Role = (props) => {
  return (
    <AutocompleteAsync
      {...props}
      fetchFunction={fetchRoles}
      label={'Группа'}
      name={ROLE_NAME}
    />
  );
};
