 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Номер по ГОСТ и наименование знака__.
 *
 * @type {{ accessor: 'traffic_signs_name',  Header: 'Номер по ГОСТ и наименование знака' }}
 * @augments Cell
 */
export const traffic_signs_name = {
  accessor: 'traffic_signs_name',
  Header: 'Номер по ГОСТ и наименование знака',
  sortable: true,
};
