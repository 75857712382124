import { withFinalForm } from 'core/form/finalForm/helpers/withFinalForm';
import {
  ENGINEER_BUILDING_TYPE_ID,
  EngineerBuildingTypeId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/EngineerBuildingTypeId';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * Select FF __Тип (наименование)__.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const EngineerBuildingTypeIdFF = (props) => {
  return (
    <Field
      {...props}
      name={ENGINEER_BUILDING_TYPE_ID}
      component={withFinalForm(EngineerBuildingTypeId)}
    />
  );
};
