 
import './Document.scss';

import { columnsTankDocuments } from 'app/pages/cardsOgh/components/tables/Documents/columns';
import { AddFileDocumentDialog } from 'app/pages/cardsOgh/components/tables/Documents/components/AddFileDocumentDialog';
import { useDialogDocuments } from 'app/pages/cardsOgh/components/tables/Documents/hooks/useDialogDocuments';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { Button } from 'core/uiKit/components';
import { TableSimple } from 'core/uiKit/components/tables/TableSimple';
import React, { useState } from 'react';

/**
 * Таблица документов.
 *
 * @param {object} props - Property.
 * @param {string} props.titleDialog - Title dialog.
 * @param {string} props.title - Title.
 * @param {Array} props.dataTable - Данные таблицы.
 * @param {Function} props.onChange - Function.
 * @param {number} props.typeId - Тип ОГХ.
 * @param {boolean} props.disabled - Disabled flag.
 * @returns {JSX}
 */
export const Document = ({
  titleDialog,
  title,
  dataTable = [],
  onChange,
  typeId,
  disabled,
  disabledAdding,
  ...otherProps
}) => {
  const { editMode } = useEditMode();

  const [selectedRow, setSelectedRow] = useState([]);
  const { openDialog, cancelDialog, open } = useDialogDocuments();

  /**
   * Select.
   *
   * @param {object} props - Property.
   * @param {Array} props.selectedFlatRows - Selected row.
   * @returns {void}
   */
  const selectRow = ({ selectedFlatRows }) => {
    const SelectRowId = selectedFlatRows.map(
      ({ original: { file_id } = {} }) => file_id,
    );
    setSelectedRow(SelectRowId);
  };

  /**
   * RemoveRow.
   *
   * @returns {void}
   */
  const removeRow = () => {
    onChange(dataTable.filter(({ file_id }) => !selectedRow.includes(file_id)));
  };

  /**
   * Check.
   *
   * @returns {boolean}
   */
  const checkEnabledEditableDocument = () => {
    if (disabled || disabledAdding) {
      return false;
    }
    return editMode;
  };

  /**
   * Проверка активности кнопки удалить Если disabled имеет значение и в таблице выбран чекбокс, даем пользователю удалить колонку.
   *
   * @returns {boolean}
   */
  const checkEnabledDeleteButton = () => {
    if (disabled && !selectedRow.length) {
      return false;
    }
    return editMode;
  };

  return (
    <div className="table-documents">
      <AddFileDocumentDialog
        textHeader={titleDialog}
        typeId={typeId}
        {...{
          cancelDialog,
          dataTable,
          onChange,
          open,
        }}
        {...otherProps}
      />

      <div className={'table-documents-header__container'}>
        <h5 className={'mt-3'}>{title}</h5>
        <div className={'table-documents-header__button-container'}>
          <Button
            disabled={!checkEnabledDeleteButton()}
            color={'error'}
            variant={'contained'}
            onClick={removeRow}
          >
            Удалить
          </Button>
          <Button
            disabled={!checkEnabledEditableDocument()}
            color={'success'}
            variant={'contained'}
            onClick={openDialog}
          >
            Добавить
          </Button>
        </div>
      </div>

      <TableSimple
        columns={columnsTankDocuments}
        data={dataTable}
        pagination={false}
        checkboxSelection={true}
        selectedRows={selectRow}
      />
    </div>
  );
};
