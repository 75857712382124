/* eslint-disable  */
import * as React from 'react';

/**
 *
 */
const Table = () => {
  return (
    <p>Нужно подключать подходящую таблицу из src/app/components/react-table</p>
  );
};

export default Table;
