import { TextFieldFF } from 'core/form/finalForm/fields/default/TextFieldFF';
import { StringParam } from 'use-query-params';

import { filterInput } from '../../filterInputsClasses';
import { ID_OR_NAME_PARENT_NAME } from './ID_OR_NAME_PARENT_NAME';
import { usePreparationIdOrNameFilter } from './usePreparation.IdOrNameFilter';

/**
 * @typedef {import('use-query-params').StringParam} StringParam
 * @typedef {import('use-query-params').QueryParamConfigMapWithInherit} QueryParamConfigMapWithInherit
 */

/**
 * QueryParam Наименование/ID родительского ОГХ .
 *
 * @returns {{ID_OR_NAME_PARENT_NAME: StringParam}} - Параметр.
 * @example -----
 *  const [filters, setFilters] = useQueryParams([
 *     ownerIdParams,
 *     idOrNameParentParams,
 *   ]);
 */
export const idOrNameParentParams = () => {
  return {
    [ID_OR_NAME_PARENT_NAME]: StringParam,
  };
};

/**
 * Наименование/ID родительского ОГХ.
 *
 * @param {object} [props] - Properties.
 * @param {string} [props.label] - Label.
 * @returns {JSX.Element} - JSX.
 * @example --------
 */
export const IdOrNameParentFilter = ({
  label = 'Наименование/ID родительского ОГХ',
}) => {
  usePreparationIdOrNameFilter();
  return (
    <TextFieldFF
      className={filterInput}
      label={label}
      name={ID_OR_NAME_PARENT_NAME}
    />
  );
};
