import { PlacesAreaWithFF } from 'core/form/finalForm/fields/prepared/numberField/PlacesArea.FF/PlacesAreaWithFF';
import { PLACES_AREA_NAME } from 'core/uiKit/preparedInputs/textField/PlacesArea/index';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * Количество посадочных мест, шт.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const PlacesAreaFF = (props) => {
  return (
    <Field component={PlacesAreaWithFF} name={PLACES_AREA_NAME} {...props} />
  );
};
