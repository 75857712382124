import { sendMatching } from 'app/actions/adminActions';
import DialogAction from 'app/components/dialogs/common/DialogAction';
import DialogAlert from 'app/components/dialogs/common/DialogAlert';
import { REQUIRED_FIELD_MESSAGE } from 'app/constants/messages';
import KeyboardDatePicker from 'core/uiKit/inputs/datePickers/KeyboardDatePicker';
import { MultiSelect } from 'core/uiKit/inputs/selects/MultiSelect';
import { TextField } from 'core/uiKit/inputs/TextField';
import { get, includes, isArray } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

/* eslint-disable */

const propTypes = {
  alertResult: PropTypes.shape({
    isDone: PropTypes.bool,
    message: PropTypes.string,
  }),
  alertStart: PropTypes.shape({
    errorMessage: PropTypes.string,
    errorStart: PropTypes.bool,
    restrictions: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  }),
  clearCashVisibilityButtonsOgh: PropTypes.func,
  id: PropTypes.number,
  onCancel: PropTypes.func,
  onHideOnFailed: PropTypes.func,
  onHideOnSuccess: PropTypes.func,
  onOk: PropTypes.func,
  showCondition: PropTypes.bool,
  startDate: PropTypes.any,
  typeChange: PropTypes.oneOf(['edit_ogh', 'create_ogh', 'create_title']),
  typeId: PropTypes.number,
};

const defaultProps = {
  title: 'Отправить объект на согласование',
};

/**
 * Компонент DialogSendMatching.
 *
 * @class
 * @returns {JSX.ElementClass} - Жопа.
 */
export class DialogSendMatching extends React.Component {
  /**
   * Конструктор.
   *
   * @param {object} props - Пропсы.
   * @example ----
   */
  constructor(props) {
    super(props);
    const date =
      typeof props.startDate === 'string' ? props.startDate.split('.') : null;
    const lastMatchDate =
      date == null ? props.startDate : new Date(date[2], date[1] - 1, date[0]);

    this.state = {
      comment: '',
      error: {},
      lastMatchDate: lastMatchDate,
      reason: '',
      reasons: [],
      showMessageBeforeOk: false,
    };
  }

  /**
   * ComponentDidMount.
   *
   * @returns {void} - Nothing.
   * @example -----
   */
  componentDidMount() {
    this.updateReasons(this.props.typeChange);
  }

  /**
   * UNSAFE_componentWillReceiveProps.
   *
   * @param {object} nextProps - Следующие пропсы.
   * @returns {void} - Nothing.
   * @example ----
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.typeChange &&
      nextProps.typeChange !== this.props.typeChange
    ) {
      this.updateReasons(nextProps.typeChange);
    }
  }

  /**
   * ComponentDidUpdate.
   *
   * @param {object} prevProps - Предыдущие пропсы.
   * @returns {void} - Nothing.
   * @example ----.
   */
  componentDidUpdate(prevProps) {
    if (prevProps.startDate !== this.props.startDate) {
      const date =
        typeof this.props.startDate === 'string'
          ? this.props.startDate.split('.')
          : null;
      const lastMatchDate =
        date == null
          ? this.props.startDate
          : new Date(date[2], date[1] - 1, date[0]);
      this.setState({ lastMatchDate });
    }
    if (prevProps.showCondition && !this.props.showCondition) {
      this.setState({ showMessageBeforeOk: false });
    }
    if (prevProps.showCondition !== this.props.showCondition) {
      this.setState({ reason: '' });
      this.setState({ comment: '' });
    }
  }

  /**
   * Метод onChangeComment.
   *
   * @param {string} e - Комментарий.
   * @returns {void} - Nothing.
   * @example ---
   */
  onChangeComment = (e) => {
    this.setState({
      comment: e,
      error: {},
    });
  };

  /**
   * Метод onChangeDateEnd.
   *
   * @param {*} value - Значение.
   * @returns {void} - Nothing.
   * @example ---
   */
  onChangeDateEnd = (value) => {
    return this.setState({
      error: {},
      lastMatchDate: value,
    });
  };

  /**
   * Метод onChangeReason.
   *
   * @param {*} value - Значение.
   * @returns {void} - Nothing.
   * @example ---
   */
  onChangeReason = (value) => {
    if (isArray(value) && !value[0]) {
      value = '';
    }

    this.setState({
      error: {},
      reason: value,
    });
  };

  handleCancel = () => {
    this.setState({ submitDisabled: false });
    this.props.onCancel();
  };

  handleOk = () => {
    const { showMessageBeforeOk } = this.state;
    const { onOk, messageBeforeOk } = this.props;
    if (this.check()) {
      if (messageBeforeOk && !showMessageBeforeOk) {
        this.setState({ showMessageBeforeOk: true });
      } else {
        this.setState({ submitDisabled: true });
        this.sendMatching(onOk);
        this.setState({ submitDisabled: false });
      }
    }
  };

  /**
   * Метод check.
   *
   * @returns {*} - XZ.
   * @example -----
   */
  check() {
    if (!this.state.lastMatchDate) {
      this.setState({
        error: {
          lastMatchDate: REQUIRED_FIELD_MESSAGE,
        },
      });
    }
    if (!this.state.reason) {
      this.setState({
        error: {
          reason: REQUIRED_FIELD_MESSAGE,
        },
      });
    }
    return this.state.lastMatchDate && this.state.reason;
  }

  /**
   * Метод sendMatching.
   *
   * @param {*} afterAction - Жопа.
   * @returns {Promise<void>} - Жопа.
   * @example ----
   */
  sendMatching = async (afterAction) => {
    const { id, typeChange, typeId } = this.props;
    const { comment, reason, lastMatchDate } = this.state;
    await this.props.sendMatching(
      {
        comment,
        id,
        typeChange,
        ...(typeId && { typeId: typeId }),
        lastMatchDate: Intl.DateTimeFormat('ru').format(lastMatchDate),
        reason,
      },
      typeChange.includes('title') ? 'tl' : 'ogh',
      afterAction,
    );

    this.props.clearCashVisibilityButtonsOgh();
  };

  /**
   * Метод updateReasons.
   *
   * @param {string} typeChange - Тип изменения.
   * @returns {void} - Nothing.
   * @example ---
   */
  updateReasons = (typeChange) => {
    const reasons = this.props.allReasons.reduce((arr, item) => {
      const { privilege_code, ogh_object_type_list } = item;

      if (privilege_code === typeChange && ogh_object_type_list) {
        const mapOgh = ogh_object_type_list.map(
          (item) => item.ogh_object_type_id.ogh_object_type,
        );
        return mapOgh.includes(this.props?.typeId) ? [...arr, item] : arr;
      }
      return arr;
    }, []);

    this.setState({ reasons });
  };

  /**
   * Метод render.
   *
   * @returns {JSX.Element} - Рект компонент.
   * @example ----
   */
  render() {
    const {
      alertResult,
      alertStart,
      messageBeforeOk,
      showCondition,
      title,
      typeChange,
      onHideOnSuccess,
      onHideOnFailed,
    } = this.props;

    const {
      comment,
      reason,
      lastMatchDate,
      showMessageBeforeOk,
      reasons,
      submitDisabled,
    } = this.state;

    const isDeletion = includes(typeChange, 'del');

    return (
      <>
        <DialogAlert
          showCondition={alertResult && alertResult.isDone}
          onAfterHiding={onHideOnSuccess}
        >
          {get(alertResult, 'message', '')}
        </DialogAlert>

        <DialogAlert
          showCondition={
            alertStart &&
            (alertStart.errorStart ||
              (showCondition && Boolean(alertStart.restrictions)))
          }
          onAfterHiding={onHideOnFailed}
        >
          {get(alertStart, 'errorMessage', '') ||
            get(alertStart, 'restrictions', '')}
        </DialogAlert>

        <DialogAction
          className="Dialog-Send-Matching_With-Message-Before-Ok"
          isDisabledOk={submitDisabled}
          showCondition={
            showCondition &&
            showMessageBeforeOk &&
            (alertStart ? !alertStart.restrictions : true)
          }
          title={title}
          onCancel={this.handleCancel}
          onOk={this.handleOk}
        >
          {messageBeforeOk}
        </DialogAction>

        <DialogAction
          className="Dialog-Send-Matching"
          isDisabledOk={submitDisabled}
          showCondition={
            showCondition &&
            !showMessageBeforeOk &&
            (alertStart ? !alertStart.restrictions : true)
          }
          title={title}
          onCancel={this.handleCancel}
          onOk={this.handleOk}
        >
          <React.Fragment>
            {isDeletion && (
              <KeyboardDatePicker
                disabled={true}
                errorText={this.state.error.lastMatchDate}
                label="Дата завершения действия"
                value={lastMatchDate}
                onChange={this.onChangeDateEnd}
              />
            )}
            <MultiSelect
              errorText={this.state.error.reason}
              withAll={false}
              label="Причина"
              multiple={true}
              name="changeTypes"
              required={true}
              options={
                reasons && reasons.sort((a, b) => a.name.localeCompare(b.name))
              }
              value={reason}
              onChange={this.onChangeReason}
            />
            <TextField
              label="Комментарий"
              name="comment"
              title="Комментарий"
              value={comment}
              variant={'outlined'}
              maxLength={1000}
              onChange={this.onChangeComment}
            />
          </React.Fragment>
        </DialogAction>
      </>
    );
  }
}

DialogSendMatching.propTypes = propTypes;
DialogSendMatching.defaultProps = defaultProps;

/**
 * Функция получения значений из state.
 *
 * @param {object} state - State.
 * @returns {{allReasons: (Array<unknown>|{})}} - AllReasons.
 * @example ----
 */
const mapStateToProps = (state) => ({
  allReasons: Object.values(state?.odsDicts?.reasons || {}) || {},
});

const mapDispatchToProps = { sendMatching };

export default connect(mapStateToProps, mapDispatchToProps)(DialogSendMatching);
