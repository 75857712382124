import * as types from 'app/constants/actionTypes';

/* eslint-disable */
export default function card(state = { childrenOnMap: false }, action) {
  switch (action.type) {
    case types.RECEIVE_CARD:
      return {
        ...state,
        mode: {
          ...state.mode,
          didReceived: true,
        },
        object: {
          ...action.object,
          default_calc_attribute: action.object.calc_attribute,
        },
        root: action.object,
      };
    case types.RECEIVE_ERROR_CARD:
      return {
        ...state,
        mode: {
          errorViewMode: true,
          status: action.status,
          message: action.errors.toString(),
        },
      };
    case types.RECEIVE_VERSIONS:
      return {
        ...state,
        versions: action.object,
      };
    case types.AFTER_RECEIVING:
      return {
        ...state,
        mode: {
          ...state.mode,
          didReceived: undefined,
        },
      };
    case types.RECEIVE_TREE_SELECTED_ELEMENT:
      return {
        ...state,
        mode: {
          ...state.mode,
          createMode: false,
        },
      };
    case types.REQUEST_DIFF_VERSIONS_PROPERTIES:
      return {
        ...state,
        propsdiff: {
          isFetched: false,
        },
      };
    case types.RECEIVE_DIFF_VERSIONS_PROPERTIES:
      return {
        ...state,
        propsdiff: {
          isFetched: true,
          payload: action.object,
        },
      };
    case types.REQUEST_DIFF_OBJECT_TREES:
      return {
        ...state,
        treediff: {
          isFetched: false,
        },
      };
    case types.RECEIVE_DIFF_OBJECT_TREES:
      return {
        ...state,
        treediff: {
          isFetched: true,
          payload: action.object,
        },
      };
    case types.REQUEST_DIFF_GEOMETRY: {
      return {
        ...state,
        geodiff: {
          isFetched: false,
        },
      };
    }
    case types.RECEIVE_DIFF_GEOMETRY:
      return {
        ...state,
        geodiff: {
          isFetched: true,
          payload: action.object,
        },
      };
    case types.SELECT_VERSION: {
      const versions = state.selectedVersions;
      return {
        ...state,
        selectedVersions: {
          ...versions,
          [action.object.id]: action.object,
        },
      };
    }
    case types.DESELECT_VERSION: {
      return {
        ...state,
        selectedVersions: Object.keys(state.selectedVersions).reduce(
          (result, key) => {
            if (parseInt(key, 10) !== action.object.id) {
              result[key] = state.selectedVersions[key];
            }
            return result;
          },
          {},
        ),
      };
    }
    case types.RESET_VERSIONS:
      return {
        ...state,
        selectedVersions: [],
        versions: {},
      };
    case types.READY_FOR_UPDATE_CARD:
      return {
        ...state,
        mode: { readyUpdate: true },
      };
    case types.VIEW_CARD:
      return {
        ...state,
        object: {
          ...state.object,
          calc_attribute: state.object.default_calc_attribute,
        },
        mode: { viewMode: true },
      };
    case types.RECEIVE_DELETE_CARD_ALLOW: {
      return action.delete_root
        ? {
            ...state,
            object: {
              ...state.object,
              ...action.object,
            },
            mode: { deleteMode: true },
          }
        : {
            ...state,
            mode: { deleteMode: true },
          };
    }
    case types.EDIT_CARD: {
      const fillEffectAttributes = (effects = {}, attrs) => {
        const filled = {};
        Object.keys(effects).forEach((key) => {
          if (key in attrs && attrs[key] !== null) {
            filled[key] = attrs[key];
          }
        });
        return filled;
      };
      return {
        ...state,
        object: {
          ...state.object,
          effect_attributes:
            'effect_attributes' in state.object &&
            state.object.effect_attributes !== null
              ? fillEffectAttributes(
                  state.object.effect_attributes,
                  state.object.attribute,
                )
              : null,
        },
        mode: { editMode: true },
      };
    }
    case types.SET_TECH_EDIT_TYPE: {
      return {
        ...state,
        mode: {
          editMode: true,
          requestType: action.requestType,
          reasonCode: action.reasonCode,
        },
      };
    }
    case types.CONTINUE_EDIT_CARD:
      return {
        ...state,
        mode: {
          editMode: true,
          createMode: (state && state.mode && state.mode.createMode) || false,
          requestType:
            (state && state.mode && state.mode.requestType) || undefined,
        },
      };
    case types.SAVE_CARD:
      return {
        ...state,
        mode: {
          saveMode: true,
        },
      };
    case types.SET_MODE:
      return {
        ...state,
        mode:
          action.mode === 'view'
            ? { viewMode: true }
            : action.mode === 'create'
            ? { createMode: true }
            : action.mode === 'edit'
            ? { editMode: true }
            : state.mode,
      };
    case types.CREATE_CARD: {
      return {
        ...state,
        parentInfo: action.parentInfo,
        mode: {
          createModePreview: false,
          createMode: true,
          editMode: true,
          viewMode: false,
          typeId: action.typeId,
          startDate: action.startDate,
          doneCreateMode: false,
          errorCreateMode: false,
          options: action.options,
        },
      };
    }
    case types.PREVIEW_CREATE_CARD:
      return {
        ...state,
        mode: {
          createModePreview: action.show,
        },
      };
    case types.RECEIVE_DELETE_CARD:
      return {
        ...state,
        mode: {
          doneDeleteMode: true,
          relocate: action.relocate,
          message: action.message,
        },
      };
    case types.RECEIVE_ERROR_DELETE_CARD:
      return {
        ...state,
        mode: {
          errorDeleteMode: true,
          message: action.message,
        },
      };
    case types.RECEIVE_EDIT_CARD_DENY:
      return {
        ...state,
        mode: {
          editModeDenyOnVersion: true,
          version: action.id,
        },
      };
    case types.RECEIVE_EDIT_CARD_DID_NOT_LOCK:
      return {
        ...state,
        mode: {
          editModeDenyOnLock: true,
          lockInfo: action.object,
        },
      };
    case types.RECEIVE_EDIT_CARD_ALLOW: {
      return action.root_edit
        ? {
            ...state,
            object: {
              ...state.object,
              ...action.object,
            },
            mode: {
              editModeAllow: true,
              start_date: action.start_date,
              end_date: action.end_date,
              restrict_dates: action.restrict_dates,
            },
          }
        : {
            ...state,
            mode: {
              editModeAllow: true,
              start_date: action.start_date,
              end_date: action.end_date,
              restrict_dates: action.restrict_dates,
            },
          };
    }
    case types.REQUEST_UPDATE_CARD:
      return state;
    case types.RECEIVE_UPDATE_CARD:
      return {
        ...state,
        mode: {
          doneUpdateMode: true,
          relocate: action.relocate,
        },
      };
    case types.RECEIVE_ERROR_UPDATE_CARD:
      const { mode } = state;

      return {
        ...state,
        mode: {
          ...mode,
          errorUpdateMode: true,
          createMode: false,
          editMode: true,
          message: action.message || null,
        },
      };
    case types.RECEIVE_START_SEND_MATCH_CARD:
      return {
        ...state,
        object: {
          ...state.object,
          ...action.object,
        },
        mode: {
          startMatchingMode: true,
        },
      };
    case types.RECEIVE_ERROR_MATCH_CARD:
      return {
        ...state,
        mode: {
          errorStartMatchingMode: true,
          message: action.message,
        },
      };
    case types.RECEIVE_ERROR_START_UPDATE_CARD:
      return {
        ...state,
        mode: {
          errorStartUpdateMode: true,
          message: action.message,
        },
      };
    case types.ROOT_TREE_STATE_CHANGED:
      return {
        ...state,
        mode: {
          rootTreeStateChanged: true,
        },
      };
    case types.RECEIVE_CREATE_CHILD_CARD:
      return {
        ...state,
      };
    case types.RECEIVE_CREATE_CARD:
      return {
        ...state,
        mode: {
          createMode: false,
          editMode: false,
          viewMode: true,
          relocate: action.relocate,
          doneCreateMode: true,
          errorCreateMode: false,
        },
      };
    case types.RECEIVE_ERROR_CREATE_CARD: {
      return {
        ...state,
        mode: {
          ...state.mode,
          createMode: true,
          editMode: true,
          viewMode: false,
          message: action.message || null,
          doneCreateMode: false,
          errorCreateMode: true,
        },
      };
    }
    case types.HIDE_DIALOG: {
      return {
        ...state,
        mode: {
          ...state.mode,
          errorCreateMode: false,
        },
      };
    }
    case types.RECEIVE_MATCHING_OPERATION:
      return {
        ...state,
        mode: { readyUpdate: true },
      };
    case types.RELOAD_CARD: {
      return {
        ...state,
        mode: { readyUpdate: true },
      };
    }
    case types.RECEIVE_SEND_MATCHING:
      return {
        ...state,
        mode: {
          sentMatching: true,
          message: 'Объект отправлен на согласование.',
        },
      };
    case types.SET_TREE_EDIT_OBJECT:
      return {
        ...state,
        current: action.object,
      };
    case types.UPDATE_EFFECT_ATTRIBUTE_CARD:
      return {
        ...state,
        object: {
          ...state.object,
          effect_attributes: {
            ...action.effect_attributes,
          },
        },
      };
    case types.CHANGE_TREE_JOURNAL: {
      const { children } = state.root;
      const { type_id, element_list } = action.object_tree_branch;

      let newChildren = [];

      if (Array.isArray(children)) {
        newChildren = children.filter((item) => item.typeId !== type_id);
      }

      element_list
        .map((item) => {
          return {
            recordId: item.id,
            rootId: item.root_id,
            typeId: type_id,
          };
        })
        .forEach((item) => newChildren.push(item));

      return {
        ...state,
        current: {
          ...state.current,
          group: action.object_tree_branch,
        },
        object: {
          ...state.object,
          children: newChildren,
        },
        root: {
          ...state.root,
          children: newChildren,
        },
      };
    }
    case types.RECEIVE_ADD_OBJECT_TO_TREE: {
      const { children } = state.root;

      const typeId = action.object_tree_branch.type_id;
      const addedRecordId = action.payload.objectId;

      const addedElement = action.object_tree_branch.element_list.find(
        (item) => item.id === addedRecordId,
      );
      const addedRootId =
        (addedElement && addedElement.root_id) || addedRecordId;

      const addedObject = {
        recordId: addedRecordId,
        rootId: addedRootId,
        typeId,
      };

      let newChildren;
      if (Array.isArray(children)) {
        newChildren = children.filter((item) => item.rootId !== addedRootId);
        newChildren.push(addedObject);
      } else {
        newChildren = [addedObject];
      }

      return {
        ...state,
        object: {
          ...state.object,
          ...action.re_calc_attribute,
          children: newChildren,
        },
        root: {
          ...state.root,
          ...action.re_calc_attribute,
          children: newChildren,
        },
        current: {
          ...state.current,
          group: action.object_tree_branch,
        },
      };
    }
    case types.LEAVE_CURRENT_CARD:
      if (state.current) {
        return {
          ...state,
          mode: {
            ...state.mode,
            leavePageHandler: action.leavePageHandler,
            cancelMode: true,
          },
          current: {
            ...state.current,
            group: {
              ...action.currentGroup,
            },
          },
        };
      }

      return {
        ...state,
        mode: {
          ...state.mode,
          leavePageHandler: action.leavePageHandler,
          cancelMode: true,
        },
      };

    case types.CANCEL_LEAVE_CURRENT_CARD:
      return {
        ...state,
        mode: {
          ...state.mode,
          leavePageHandler: undefined,
          cancelMode: false,
          editMode: false,
        },
      };

    case types.CANCEL_EDIT_CURRENT_CARD:
      return {
        ...state,
        mode: {
          ...state.mode,
          leavePageHandler: undefined,
          cancelMode: false,
          editMode: true,
        },
      };

    case types.CANCEL_MODE:
      return {
        ...state,
        mode: {
          ...state.mode,
          cancelMode: false,
        },
      };

    case types.RECEIVE_OGH_CHOICE_ADDRESS_LIST:
      return {
        ...state,
        choiceAddressList: action.object,
      };
    case types.TOGGLE_OGH_ORDER_GEOMETRY:
      let cloned = {
        ...state.selectedOghOrders,
      };

      const id = action.payload.global_id;
      const remove = !!(state.selectedOghOrders && state.selectedOghOrders[id]);

      if (remove) {
        delete cloned[id];
      } else {
        cloned[id] = action.payload;
      }

      return {
        ...state,
        selectedOghOrders: cloned,
      };
    case types.RESET_OGH_ORDERS_GEOMETRY:
      return {
        ...state,
        selectedOghOrders: {},
      };
    case types.RECEIVE_REON_INTERSECTIONS_GEOMETRY:
      return {
        ...state,
        reonIntersections: action.object,
      };
    case types.RESET_REON_INTERSECTIONS:
      return {
        ...state,
        reonIntersections: {},
      };
    case types.RECEIVE_BTI_GEOMETRY:
      // ODS-8678 - решено убрать вообще обрисовку геометрии
      // if (!isEmpty(action.object)) {
      //   newState.btiGeometry = {
      //     ...action.object,
      //     hint: {
      //       object_attribute_hint: {},
      //     },
      //   };
      // }

      return { ...state };
    case types.RESET_BTI_GEOMETRY:
      return {
        ...state,
        btiGeometry: null,
      };
    case types.TOGGLE_GEOMETRY_OF_CHILDREN:
      return {
        ...state,
        childrenOnMap: !state.childrenOnMap,
      };
    case types.RECEIVE_NEIGHBOURS_GEOMETRY:
      return {
        ...state,
        neighbours: action.object,
      };
    case types.RESET_NEIGHBOURS_GEOMETRY:
      return {
        ...state,
        neighbours: [],
      };
    case types.RECEIVE_CHECK_INTERSECTIONS:
      return {
        ...state,
        intersections: action.object.geometry || [],
      };
    case types.RESET_INTERSECTIONS_GEOMETRY:
      return {
        ...state,
        intersections: [],
      };
    case types.RESET_OGH_CHOICE_ADDRESS_LIST:
      return {
        ...state,
        choiceAddressList: [],
      };
    case types.CLEAR_CARD:
      return {
        mode: {},
        object: {},
        root: {},
      };
    default:
      return state;
  }
}
