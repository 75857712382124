import './CardHeader.scss';

import React from 'react';

/**
 * Компонент заголовка.
 *
 * @param {object} props - Подзаголовок.
 * @param {string} props.subtitle - Подзаголовок.
 * @param {string} props.title - Заголовок.
 * @param {object} props.children - Поля.
 * @returns {React.JSX.Element}
 */
const CardHeader = ({ subtitle, title, children }) => (
  <div className="header-card">
    {subtitle && <div className="header-card__subtitle">{subtitle}</div>}
    <h1 className="header-card__title">{title}</h1>
    <div className="header-card__inputs">{children}</div>
  </div>
);

export default CardHeader;
