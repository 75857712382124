import { useEffect } from 'react';

const script = document.createElement('script');
script.id = 'autofaqWidget';
script.src = 'https://ai.eaist.mos.ru/widget/static/js/main.js';
script.dataset.widgetHost = 'https://ai.eaist.mos.ru';
script.dataset.widgetServiceId = '2f0147f5-63c6-4f1b-8f67-aeeff5b95143';
script.dataset.widgetChannelId = '4ba7ac09-69d4-4be5-bd03-98e33d0fd7b7';
script.dataset.widgetUserLogin = 'asuods';
script.dataset.widgetUserName = 'asuods';
script.dataset.widgetSizes =
  '{"resizeable": true,"width": "30%","height": "80%","minWidth": 400,"minHeight": 300}';

/**
 * Хук инициализации помощника autoFAQ.
 *
 * @returns {void}
 */
export const useInitFAQService = () => {
  useEffect(() => {
    document.body.append(script);
    return () => {
      script.remove();
    };
  }, []);
};
