import { TextFieldRF } from 'core/form/reduxForm/fields/default/TextFieldRF';
import { ReCalcNameRF } from 'core/form/reduxForm/fields/prepared/textField/ReCalcName.RF';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import React from 'react';

/**
 * Форма имени объекта.
 *
 * @param {object} props - Пропсы.
 * @returns {React.ReactElement}
 */
export default function (props) {
  return (
    <FieldsSplitColumns>
      <ReCalcNameRF />
      <TextFieldRF
        name={'name_description'}
        label={'Уточнение наименования'}
        disabled={!props.editable}
      />
    </FieldsSplitColumns>
  );
}
