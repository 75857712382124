import { Cell } from 'core/uiKit/components/tables/Table';

/**
 * Колонка __Площадь посадочных мест, кв.м.__.
 *
 * @type {{
 * accessor: 'places_area',
 * Header: 'Площадь посадочных мест, кв.м.',
 * sortable: true,
 * }}
 * @augments Cell
 */
export const places_area: Cell = {
  Header: 'Площадь посадочных мест, кв.м.',
  accessor: 'places_area',
  sortable: true,
};
