import React from 'react';

import Icon from './Icon';

interface ToggleButtonProps {
  onClick: () => void;
  active: boolean;
}

/**
 * Кнопка ShowDistrictBoundaries.
 *
 * @param props - Все пропсы.
 * @param props.onClick - Обработчик нажатия.
 * @param props.active - Состояние кнопки.
 * @returns Компонет кнопки.
 * @example ----------
 * <ToggleButton
 *       active={hasDistrictBoundaries}
 *       onClick={checkDistrictBoundaries}
 *     />
 */
const ToggleButton = ({ onClick, active }: ToggleButtonProps) => {
  const title = active ? 'Скрыть границы районов' : 'Показать границы районов';

  return (
    <button
      className={'button-toggle'}
      title={title}
      type={'button'}
      onClick={onClick}
    >
      <Icon active={active} />
    </button>
  );
};

export default ToggleButton;
