import getCurrentObject from 'app/selectors/card/getCurrentObject';
/* eslint-disable  */
import getFormValues from 'app/selectors/form/getFormValues';
import { get } from 'lodash';
import { createSelector } from 'reselect';

/**
 *
 * @param state
 */
const getEditMode = (state) => get(state, 'card.mode.editMode');

/**
 *
 * @param state
 */
const getPlanarStructureTypeId = (state) =>
  getFormValues(state).planar_structure_type_id;

/**
 *
 * @param state
 */
const getPlanarStructureTypeRef = (state) => {
  return getCurrentObject(state).planar_structure_type_ref;
};

/**
 *
 * @param arr
 */
export const getAcessPlanarTypes = (arr) => {
  if (Array.isArray(arr)) {
    let result = arr.map((item) => item.typeId);
    result = new Set(result);
    return Array.from(result);
  }
  return [];
};

/**
 *
 * @param editMode
 * @param currentPlanarTypeId
 * @param planarStructureTypeRef
 */
export const selectorResult = (
  editMode,
  currentPlanarTypeId,
  planarStructureTypeRef,
) => {
  let result;

  if (editMode && currentPlanarTypeId && planarStructureTypeRef) {
    result = getAcessPlanarTypes(planarStructureTypeRef).includes(
      currentPlanarTypeId,
    );
  } else {
    result = false;
  }

  return result;
};

export default createSelector(
  [getEditMode, getPlanarStructureTypeId, getPlanarStructureTypeRef],
  (editMode, currentPlanarTypeId, planarStructureTypeRef) => {
    return selectorResult(
      editMode,
      currentPlanarTypeId,
      planarStructureTypeRef,
    );
  },
);
