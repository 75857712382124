/* eslint-disable */
import React from 'react';
import { NumberParam } from 'use-query-params';

import { CarConditionFF, CAR_CONDITION_NAME } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
 
/**
 * Техническое состояние техники –> car_condition [поле справочник, или если пусто - все]
 */
 
export const CarConditionFilter = (props) => (
  <CarConditionFF {...props} className={filterInput} />
);

 
export { CAR_CONDITION_NAME };

 
export const carConditionParams = {
  [CAR_CONDITION_NAME]: NumberParam,
};
