import { RANDOM_WORD } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import { TableVerticalLandscapingList } from './Table.VerticalLandscapingList';
import { VerticalLandscapingList } from './VerticalLandscaping.List';

describe('VerticalLandscapingListSpec', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act
    const wrapper = shallow(<VerticalLandscapingList />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<AccordionPlantationTab />');
  });
  it('props', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    const onChangeSpy = vi.fn();

    // 🔥 Act
    const wrapper = shallow(
      <VerticalLandscapingList expanded={RANDOM_WORD} onChange={onChangeSpy} />,
    );

    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      Table: TableVerticalLandscapingList,
      code: 'vertical_landscaping_list',
      expanded: RANDOM_WORD,
      onChange: onChangeSpy,
      title:
        'Ведомость учета зеленых насаждений (элементы вертикального озеленения)',
    });
  });
});
