import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import React, { useState } from 'react';

import { ButtonCloseElectronicSignature } from './Button.CloseElectronicSignature';
import { DialogCloseElectronicSignature } from './Dialog.CloseElectronicSignature';

/**
 * Компонент Кнопки с диалоговым окном __Закрыть (ЭП)__.
 *
 * @returns {React.ReactElement}
 */
export const CloseElectronicSignature = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { objectId } = useGetObjectId();
  return (
    <>
      <ButtonCloseElectronicSignature
        objectId={objectId}
        onClick={() => setIsOpen(true)}
      />
      {isOpen ? (
        <DialogCloseElectronicSignature
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        />
      ) : null}
    </>
  );
};
