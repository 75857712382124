/* eslint-disable  */

/**
 *
 * @param response
 */
export const getFilename = (response) => {
  const disposition = response.headers.get('Content-Disposition');

  let filename;

  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[*][^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);

    if (matches !== null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
      filename = filename.replace(/utf-8/g, '');
      filename = decodeURIComponent(filename);
    }
  } else {
    filename = 'default_filename';
  }

  return filename;
};
