import { SelectFF } from 'core/form/finalForm/fields';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';
import { NumberParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

export const PARENT_NAME_NAME = 'parentName';

/**
 * Входит в    - parentName.
 *
 * @returns {JSX.Element}
 */
export const ParentNameFilter = () => {
  const options = [
    { id: 1, name: 'Да' },
    { id: -1, name: 'Нет' },
  ];
  return (
    <SelectFF
      className={filterInput}
      {...{ options }}
      label={'Входит в'}
      name={PARENT_NAME_NAME}
    />
  );
};

/**
 * Params.
 *
 * @returns {*}
 */
export const parentNameParams = () => {
  addPreparation(PARENT_NAME_NAME, (value) => value > 0);
  return {
    [PARENT_NAME_NAME]: NumberParam,
  };
};
