/* eslint-disable */
const fieldName = {
  baseNumOrName: 'baseNumOrName',
  idOrName: 'idOrName',
  dateFrom: 'dateFrom',
  dateTo: 'dateTo',
  status: 'status',
  department: 'department',
  plantationType: 'plantationType',
  subTypeId: 'subTypeId',
  buildingTypeId: 'buildingTypeId',
  isSeparateGarbageCollection: 'isSeparateGarbageCollection',
  parentName: 'parentName',
  plantType: 'plantType',
  lifeFormType: 'lifeFormType',
  sectionNum: 'sectionNum',
  lawnType: 'lawnType',
  containerType: 'containerType',
  otherImprovementObjectType: 'otherImprovementObjectType',
  reagentBaseType: 'reagentBaseType',
  okrug: 'okrug',
  district: 'district',
  reagentKind: 'reagentKind',
  buildingsTypeSpecId: 'buildingsTypeSpecId',
  ooptMeaning: 'ooptMeaning',
  ooptProfile: 'ooptProfile',
  reagent: 'reagent',
  ooptCategory: 'ooptCategory',
  explicationType: 'explicationType',
  ooptStatus: 'ooptStatus',
  cadNumber: 'cadNumber',
  customer: 'customer',
  owner: 'owner',
  address: 'address',
  categoryMsop: 'categoryMsop',
  grbsShortName: 'grbsShortName',
};

const FIELDS = [
  fieldName.idOrName,
  fieldName.sectionNum,
  fieldName.plantationType,
  fieldName.lifeFormType,
  fieldName.plantType,
  fieldName.buildingTypeId,
  fieldName.lawnType,
  fieldName.okrug,
  fieldName.district,
  fieldName.status,
  fieldName.otherImprovementObjectType,
  fieldName.owner,
  fieldName.reagentKind,
  fieldName.reagent,
  fieldName.categoryMsop,
  fieldName.ooptStatus,
  fieldName.explicationType,
  fieldName.cadNumber,
  fieldName.ooptMeaning,
  fieldName.ooptProfile,
  fieldName.ooptCategory,
  fieldName.dateFrom,
  fieldName.dateTo,
  fieldName.address,
  fieldName.buildingsTypeSpecId,
  fieldName.customer,
  fieldName.department,
  fieldName.subTypeId,
  fieldName.containerType,
  fieldName.isSeparateGarbageCollection,
  fieldName.parentName,
  'typeId',
  'listTypeId',
  fieldName.grbsShortName,
  'submitButton',
  'resetButton',
];

 
export { fieldName };

 
export default FIELDS;
