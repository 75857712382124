import { KeyboardDatePickerFF } from 'core/form/finalForm/fields/default/datePickers/KeyboardDatePickerFF/KeyboardDatePickerFF';
import { shallow } from 'enzyme';

import WorkEndDate, { WORK_END_DATE } from './WorkEndDate';

jest.mock('react-final-form');

 
describe('title WorkEndDate', () => {
  it('constant WORK_END_DATE should be workenddate', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act

    // Assert
    expect(WORK_END_DATE).toBe('workenddate');
  });
  it('workEndDate props', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const wrapper = shallow(<WorkEndDate />);

    // Assert
    expect(
      wrapper.contains(
        <KeyboardDatePickerFF
          {...wrapper.props()}
          label="Дата окончания работ"
          name={WORK_END_DATE}
        />,
      ),
    ).toBeTruthy();
  });
});
