import { get } from 'app/api/crud/get';

/**
 * Fetch gov number tech.
 *
 * @param {object} data - Data.
 * @returns {Promise}
 */
export const fetchGovNumberTech = (data) =>
  get('/autocomplete/gov_number_tech', data);
