export const districts = [
  { id: 10, name: 'ВАО' },
  { id: 5, name: 'ЗАО' },
  { id: 2, name: 'ЗелАО' },
  { id: 6, name: 'НАО' },
  { id: 3, name: 'САО' },
  { id: 9, name: 'СВАО' },
  { id: 12, name: 'СЗАО' },
  { id: 11, name: 'ТАО' },
  { id: 8, name: 'ЦАО' },
  { id: 1, name: 'ЮАО' },
  { id: 7, name: 'ЮВАО' },
  { id: 4, name: 'ЮЗАО' },
];
