/* eslint-disable */
import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import { SubTypeId, SUB_TYPE_ID_NAME } from 'core/uiKit/preparedInputs';
 
export { SUB_TYPE_ID_NAME };

 
 
/**
 * ## Тип
 *
 * ### React Final Form Field
 * ```js
 * import { SubTypeIdFF, SUB_TYPE_ID_NAME } from 'core/form/finalForm/fields';
 * ```
 *
 * `@param odsType`
 *
 * ```js
 * <SubTypeIdFF odsType={odsType}/>
 * ```
 *
 * * `type = Autocomplete`
 * * `name = SUB_TYPE_ID_NAME`
 */
/* eslint-disable */
export const SubTypeIdFF = ({ name, ...otherProps }) => {
  return (
    <Field name={SUB_TYPE_ID_NAME}>
      {(props) => {
        return <SubTypeId {...props} {...props.input} {...otherProps} />;
      }}
    </Field>
  );
};

SubTypeIdFF.propTypes = {
   
   
/**
   * выбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
/**
   * placeholder
   */
  placeholder: PropTypes.string,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
};

SubTypeIdFF.defaultProps = {
  dark: false,
  disabled: false,
};
