import { REQUIRED_FIELD_MESSAGE } from 'app/constants/messages';

import metadata from './metadata';


/* eslint-disable */
export default function (formValues) {
  const result = {};
  Object.values(metadata).forEach(({ id }) => {
    if (!formValues[id]) {
      result[id] = REQUIRED_FIELD_MESSAGE;
    }
  });
  return result;
}
