/* eslint-disable */
import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import { Select } from 'core/uiKit/inputs/selects/Select/Select';

 
 
/**
 * ## Статус выполнения работ
 *
 * ### React Final Form Field
 *
 *
 * * `type = Select`
 * * `name = PROGRESS_STATUS_NAME
 */

const progressStatusList = [
  {
    id: 'in_progress',
    name: 'Работы ведутся',
  },
  {
    id: 'finished',
    name: 'Работы завершены',
  },
  {
    id: 'planned',
    name: 'Работы запланированы',
  },
];

 
export const PROGRESS_STATUS_NAME = 'progressStatus';

 
export const SelectWrapper = (props) => {
  return (
    <Select
      style={{ minWidth: 250 }}
      {...props}
      options={progressStatusList}
      label={'Статус выполнения работ'}
    />
  );
};

 
export const ProgressStatusFF = ({ ...otherProps }) => {
  return (
    <Field name={PROGRESS_STATUS_NAME}>
      {(props) => {
        return <SelectWrapper {...props} {...otherProps} {...props.input} />;
      }}
    </Field>
  );
};

ProgressStatusFF.propTypes = {
   
   
/**
   * очищаем инпут с сохранением значения
   */
  clear: PropTypes.bool,
   
   
/**
   * выбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
/**
   * placeholder
   */
  placeholder: PropTypes.string,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
};

ProgressStatusFF.defaultProps = {
  dark: false,
  disabled: false,
};
