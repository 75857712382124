import { mapValues, pick } from 'lodash';

const pickItems = [
  'okrug_id',
  'address_id',
  'street_id',
  'district_id',
  'description',
];


/* eslint-disable */
export default function ({ address_list }) {
  let result;
  if (address_list) {
    result = address_list.map((item) => {
      return mapValues(pick(item, pickItems), (id) => {
        return {
          bti_object: id,
        };
      });
    });
  } else {
    result = null;
  }
  return {
    address_list: result,
  };
}
