
/* eslint-disable */
export default function (formValues) {
  const items = Object.entries(formValues).filter((item) =>
    filterFileList(item),
  );
  const fileList = items.reduce(
    (result, item) => reduceFileList(result, item),
    [],
  );
  const emptyKeys = items.reduce((result, [key]) => {
    result[key] = null;
    return result;
  }, {});

  return {
    file_list: fileList,
    ...emptyKeys,
  };
}

function filterFileList([key, value]) {
  return key.includes('file_list_') && value.table && value.table.length;
}

function reduceFileList(result, [, { table }]) {
  const items = table.map((file) => ({
    files: file.file_id,
  }));
  return result.concat(items);
}
