/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import { TextField } from 'core/uiKit/inputs';

 
export const VIN_NAME = 'vin';

 
/* eslint-disable */
/**
 * Идентификационный номер (VIN)
 *
 * * `type = TextField`
 * * `name = vin`
 * @returns {*}
 * @constructor
 */
 
export const Vin = ({ onChange = (_) => _, ...props }) => {
  const handleChange = (value) => {
    onChange(value.toString().toUpperCase());
  };

  return (
    <TextField
      {...props}
      label={'Идентификационный номер (VIN)'}
      name={VIN_NAME}
      onChange={handleChange}
      maxLength={17}
    />
  );
};

Vin.propTypes = {
   
   
  /**
   * выбор темной темы
   */
  dark: PropTypes.bool,
   
   
  /**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
  /**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
  /**
   * функция вызова когда выберется итем
   */
  onChange: PropTypes.func,
   
   
  /**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
   
   
  /**
   * значение
   */
  value: PropTypes.any,
};
