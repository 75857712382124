import {
  ID_OR_NAME_NAME as ID_OR_NAME_NAME_D,
  IdOrNameFilter as IdOrNameFilter_D,
  idOrNameParams as idOrNameParams_D,
} from './IdOrName.Filter';

 
/**
 * @deprecated
 * @see src/core/form/filterForm/components/text/IdOrName.Filter
 */
const IdOrNameFilter = IdOrNameFilter_D;
 
/**
 * @deprecated
 * @see src/core/form/filterForm/components/text/IdOrName.Filter
 */
const idOrNameParams = idOrNameParams_D;
 
/**
 * @deprecated
 * @see src/core/form/filterForm/components/text/IdOrName.Filter
 */
const ID_OR_NAME_NAME = ID_OR_NAME_NAME_D;

export * from './CadNumberFilter';
export * from './CarCustomerListFilter';
export * from './CommentFilter';
export * from './FactoryNumber';
export * from './FactoryNumberFilter';
export * from './FIO.Filter';
export * from './GpsCodeNameFilter';
export * from './GpsModelNameFilter';
export * from './IdNumberFilter';
export * from './InventoryNumberFilter';
export * from './NameFilter';
export * from './ProviderFilter';
export * from './SectionNumFilter';
export * from './SerialNumberFilter';
export * from './Vin';
export * from './VinFilter';

export { ID_OR_NAME_NAME, IdOrNameFilter, idOrNameParams };
