import { ABUTMENT_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/AbutmentTypeName';
import { usePreparation } from 'core/utils/hooks/usePreparation';

/**
 * Хук перевода значений при сохранении для __Тип элемента сопряжения__.
 *
 * @returns {void}
 */
export const usePreparationAbutmentTypeNameFF = () => {
  usePreparation(ABUTMENT_TYPE_ID_NAME, (value) => {
    if (value) {
      return { abutment_type: value };
    }
  });
};
