import { DataCoordOrganizationRF } from 'app/pages/cardsOgh/components/tables/Documents/FieldsRF/preparedFields/DataCoordOrganization.RF';
import { MarkingDrawingRF } from 'app/pages/cardsOgh/components/tables/Documents/FieldsRF/preparedFields/MarkingDrawing.RF';
import { PlanFlowerRF } from 'app/pages/cardsOgh/components/tables/Documents/FieldsRF/preparedFields/PlanFlower.RF';
import { SituationPlanRF } from 'app/pages/cardsOgh/components/tables/Documents/FieldsRF/preparedFields/SituationPlan.RF';
import { SketchFlowerRF } from 'app/pages/cardsOgh/components/tables/Documents/FieldsRF/preparedFields/SketchFlower.RF';
import { SolutionObjectRF } from 'app/pages/cardsOgh/components/tables/Documents/FieldsRF/preparedFields/SolutionObject.RF';
import { SymbolsInventoryPlanRF } from 'app/pages/cardsOgh/components/tables/Documents/FieldsRF/preparedFields/SymbolsInventoryPlan.RF';
import { InventoryPlanRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/InventoryPlan.RF';
import { OtherDocumentsRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/OtherDocuments.RF';
import { shallow } from 'enzyme';
import React from 'react';

import { DocumentTab } from './index';

describe('🐛 DocumentTabSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act
    const wrapper = shallow(<DocumentTab />);

    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      children: [
        <SituationPlanRF />,
        <InventoryPlanRF />,
        <SymbolsInventoryPlanRF />,
        <PlanFlowerRF />,
        <SketchFlowerRF />,
        <MarkingDrawingRF />,
        <SolutionObjectRF />,
        <DataCoordOrganizationRF />,
        <OtherDocumentsRF />,
      ],
      className: 'd-none',
      id: 'docs',
    });
  });
});
