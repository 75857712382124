import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import isMandatory from 'app/utils/card/isMandatory';
import { NumberFieldRF } from 'core/form/reduxForm/fields';
import React from 'react';

export const DISTANCE_NAME = 'distance';

/**
 * Number FieldRF __Длина, п.м__.
 *
 * @param props - Пропсы.
 * @param props.label - Заголовок поля, если есть, по умолчанию Длина, п.м.
 * @param props.required - Обязательность поля.
 * @param props.decimalScale - Количество знаков после запятой.
 * @returns
 */
export const DistanceField = (props: {
  label?: string;
  required?: boolean;
  decimalScale?: number;
}) => {
  const { typeId } = useGetCurrentCard();
  const { editMode } = useMode();
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();
  return (
    <NumberFieldRF
      decimalScale={props.decimalScale || 1}
      name={DISTANCE_NAME}
      label={props.label || 'Длина, п.м'}
      disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
      required={props?.required ?? isMandatory(typeId, DISTANCE_NAME, true)}
    />
  );
};
