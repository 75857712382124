import { CheckboxRF } from 'core/form/reduxForm/fields/default/CheckboxRF';
import React from 'react';

export const IS_DIFF_HEIGHT_MARK_NAME = 'is_diff_height_mark';

/**
 *  Чек бокс Разновысотные отметки.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const IsDiffHeightMarkRF = (props) => {
  return (
    <CheckboxRF
      {...props}
      label={props.label || 'Разновысотные отметки'}
      name={IS_DIFF_HEIGHT_MARK_NAME}
    />
  );
};
