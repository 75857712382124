/* eslint-disable */
import React from 'react';

import { OOPT_STATUS_NAME, OoptStatusFF } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';
import { NumberParam } from 'use-query-params';

 
 
/**
 * Статус ООПТ      - ooptStatus
 */
 
export const OoptStatusFilter = () => <OoptStatusFF className={filterInput} />;

 
export const ooptStatusParams = {
  [OOPT_STATUS_NAME]: NumberParam,
};
