/* eslint-disable */
import React from 'react';
import { StringParam } from 'use-query-params';

import { CommentFF } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
export const COMMENT_NAME = 'comment';

 
 
/**
 * Комментарий
 */
 
export const CommentFilter = () => {
  return (
    <CommentFF
      className={filterInput}
      label={'Комментарий'}
      name={COMMENT_NAME}
    />
  );
};

 
export const commentParams = {
  [COMMENT_NAME]: StringParam,
};
