import moment from 'moment';

/* eslint-disable */
export function parseDate(string) {
  let result = null;
  if (/^\d{2}\.\d{2}\.\d{4}$/.test(string || '')) {
    const date = string.split('.');
    result = new Date(date[2], date[1] - 1, date[0]);
  } else if (/^\d{2}\.\d{2}\.\d{4} \d{2}:\d{2}$/.test(string || '')) {
    result = moment(string || '', 'DD.MM.YYYY HH:mm').toDate();
  } else if (string) {
    result = new Date(Date.parse(string));
  }
  return result;
}
