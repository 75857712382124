import { isImprovementTerritoryOrOdh } from 'app/utils/checkOghTypeId';

/* eslint-disable */
export default function haveName(typeId) {
  let result;
  if (typeId) {
    if (typeId instanceof Array) {
      result = typeId.some((t) => isImprovementTerritoryOrOdh(parseInt(t, 10)));
    } else {
      result = isImprovementTerritoryOrOdh(parseInt(typeId, 10));
    }
  } else {
    result = true;
  }
  return result;
}
