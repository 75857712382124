/* eslint-disable */
import React from 'react';
import { DialogCreateWithDXF } from 'app/pages/components';
import { OtherImprovementObjectTypeFF } from 'core/form/finalForm/fields/prepared/selects/simple/OtherImprovementObjectTypeFF';

 
export const DialogCreateImprovementObject = ({ typeId }) => {
  return (
    <>
      <DialogCreateWithDXF typeId={typeId}>
        <OtherImprovementObjectTypeFF />
      </DialogCreateWithDXF>
    </>
  );
};
