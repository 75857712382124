import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const VERTICAL_LANDSCAPING_REF_TYPE_ID_NAME =
  'vertical_landscaping_ref_type_id';

/**
 * Тип для Элементов вертикального озеленения.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const VerticalLandscapingRefTypeId = (props) => {
  const dict = 'vertical_landscaping_ref_type';

  const { data = [] } = useGetDictionary(dict);

  return (
    <Select
      {...props}
      options={data}
      label={'Тип'}
      name={VERTICAL_LANDSCAPING_REF_TYPE_ID_NAME}
    />
  );
};
