import { get } from 'lodash';


/* eslint-disable */
export default function (params = {}) {
  const { id = 'equipment_index', path = `calc_attribute.${id}` } = params;
  return (result, state, props) => {
    const { card } = props;
    return {
      ...result,
      initialValues: {
        ...result.initialValues,
        [id]: get(card, path, null),
      },
    };
  };
}
