import VersionsTemplate from 'app/components/card/ogh/versions/VersionsTemplate';
import PropTypes from 'prop-types';
/* eslint-disable */
import React from 'react';

const types = ['other_oks'];
const PrivilegedVersions = VersionsTemplate(types);

const propTypes = {
  card: PropTypes.object,
  createMode: PropTypes.bool,
};

function Versions({ card, createMode }) {
  return (
    <div className="d-none" id="versions">
      <PrivilegedVersions card={card} disabled={createMode} />
    </div>
  );
}

Versions.propTypes = propTypes;

 
export default Versions;
