import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import withMap from 'app/components/map/withMap';
import { useActionsMap } from 'app/pages/cardsOgh/components/Map/useActionsMap';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import cn from 'classnames';
import { flow } from 'lodash';
/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';

const MapCard = (props) => {
  const className = cn('h-100 position-relative card-ogh-global-container', {
    'card-ogh-global-container__white': !!props.active,
  });

  const { editMode } = useEditMode();

  useActionsMap();

  const isCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();

  return (
    <section className={className}>
      {props.active
        ? props.Map({
            editable: editMode && !isCurrentObjectIsExternalSystem,
            style: {
              position: 'absolute',
              top: 0,
              bottom: 0,
              width: '100%',
              zIndex: 0,
            },
          })
        : null}
      {props.children}
    </section>
  );
};

 
export default flow(connect(''), withMap)(MapCard);
