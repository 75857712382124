import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { QuantityFf } from 'core/form/finalForm/fields/prepared/numberField/Quantity.FF';
import { UNIT_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/UnitId';
import { QUANTITY_NAME } from 'core/uiKit/preparedInputs/textField/Quantity';
import React, { useEffect } from 'react';
import { useField, useFormState } from 'react-final-form';

/**
 * Количество.
 *
 * @returns {JSX.Element}
 */
export const QuantityFFField = () => {
  const { editMode } = useEditMode();
  const { initialValues } = useFormState();

  const {
    input: { value: unitIdValue },
  } = useField(UNIT_ID_NAME);

  const {
    input: { onChange: onChangeQuantityValue },
  } = useField(QUANTITY_NAME);

  useEffect(() => {
    if (unitIdValue === 5) {
      onChangeQuantityValue(1);
    } else if (initialValues[UNIT_ID_NAME] !== unitIdValue) {
      onChangeQuantityValue('');
    }

    // eslint-disable-next-line
  }, [unitIdValue]);

  return (
    <QuantityFf
      decimalScale={2}
      disabled={!(editMode && unitIdValue !== 5 && unitIdValue)}
      required={true}
    />
  );
};
