import { queryClientDefaultForTesting } from 'core/forTesting';
import { Paper } from 'core/uiKit/components';
import fetchMock from 'fetch-mock';
import React, { useEffect } from 'react';

// Component
import { ButtonCloseElectronicSignature } from './Button.CloseElectronicSignature';


/* eslint-disable */
export default {
  title:
    'Example/signatures/Buttons/CloseElectronicSignature',
  component: ButtonCloseElectronicSignature,
};

const FetchWrapper = ({ children }) => {
  useEffect(() => {
    queryClientDefaultForTesting.invalidateQueries(
      'getVisibilityButtonsOgh',
    );

    fetchMock.restore();
    fetchMock.get(
      'https://dev.ods.test.intermb.ru/api/ogh/visibility/buttons?object_id=42',
      {
        close_eds: true,
      },
    );
    return () => fetchMock.restore();
  }, []);
  return children;
};

 
export const Basic = (args) => {
  return (
    <FetchWrapper>
      <Paper
        dark={args.dark}
        style={{ width: '100%' }}
        className={'p-5'}
      >
        <ButtonCloseElectronicSignature {...args} />
      </Paper>
    </FetchWrapper>
  );
};
Basic.args = { objectId: 42 };
