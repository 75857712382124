import { BOARD_STONE, CARRIAGEWAY, ROAD_SIGNS } from 'app/constants/oghTypes';
import getCardTypeId from 'app/selectors/card/getTypeId';
import isCreateMode from 'app/selectors/card/isCreateMode';
import { createSelector } from 'reselect';
/* eslint-disable */
const defaultMap = {};

const fieldNameToDefaultValueMap = {
  [ROAD_SIGNS]: {
    mounting_mode_id: 100117, // Стойка // TODO сделать привязку к code
  },
  [CARRIAGEWAY]: {
    flat_element_type_id: 1, // Основная // TODO сделать привязку к code
  },
  [BOARD_STONE]: {
    bound_stone_mark_id: 10139, // БР100.30.15 // TODO сделать привязку к code
  },
};

 
export default createSelector(
  [isCreateMode, getCardTypeId],
  (isCreateMode, cardTypeId) => {
    let result;

    if (isCreateMode && fieldNameToDefaultValueMap[cardTypeId]) {
      result = fieldNameToDefaultValueMap[cardTypeId];
    } else {
      result = defaultMap;
    }

    return result;
  },
);
