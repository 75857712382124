import { arrStrToObjTypesOgh } from 'app/pages/providers/UserContext/arrStrToObjTypesOgh';
import { userFetchDataType } from 'app/pages/providers/UserContext/fetchUserData';

/**
 * Функция подготовки данных пользователя.
 *
 * @param {userFetchDataType} data - Данные пользователя.
 * @returns {*}
 */
export const preparedUserData = (data) => {
  const permissionToObjTypesOgh = arrStrToObjTypesOgh(data.privileges || []);

  const {
    first_name: firstName = '',
    sur_name: surName = '',
    org_name: orgName = '',
    patronymic = '',
    username: userName = '',
  } = data;

  return {
    ...data,
    firstName,
    nameShown: `${surName} ${firstName.slice(0, 1)}. ${patronymic.slice(
      0,
      1,
    )}.`,
    orgName,
    permission: {
      ...permissionToObjTypesOgh('view_ogh'),
      create: {
        ...permissionToObjTypesOgh('create_ogh').create,
        ...permissionToObjTypesOgh('create_technic').create,
      },
      ...[...(data.privileges || [])].reduce((acc, cur) => {
        acc[cur] = true;
        return acc;
      }, {}),
    },
    surName,
    userName,
  };
};
