import countBy from 'lodash/countBy';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';
import sumBy from 'lodash/sumBy';

import { getFileExtension, int2str } from './common';
import { getFieldError as getFieldErrorD } from './getFieldError';

/* eslint-disable */

export const required = (_v, message = 'Поле обязательно для заполнения') => {
  let v = typeof _v === 'number' ? int2str(_v) : _v;
  v = typeof v === 'string' ? v.trim() : v;
  return v ? undefined : message;
};

export const allFieldsHaveValues = (values, fields = []) => {
  const hasRequiredError = fields.map((f) => get(values, f)).some(required);
  return !hasRequiredError;
};

export const hasError = (errors) => {
  if (!errors) {
    return false;
  }
  if (isEmpty(errors)) {
    return false;
  }

  let anyError = false;

  JSON.stringify(errors, (k, v) => {
    if (typeof v === 'string' || typeof v === 'number') {
      anyError = true;
    }
    return v;
  });
  return anyError;
};

/* eslint-disable */
export const getValidator = (values) => {
  const errors = {};

  const setError = (field, nextError) => {
    const error = get(errors, field);
    if (!error) {
      if (nextError) {
        set(errors, field, nextError);
      }
    }
  };

  const setRequired = (field, message = undefined) => {
    const value = get(values, field);
    setError(field, required(value, message));
  };

  const setNotZero = (field) => {
    const value = get(values, field);
    if (parseFloat(value) === 0) {
      setError(field, 'Поле не может быть равно 0');
    }
  };

  const setMaxLengthError = (field, maxLength) => {
    const value = get(values, field);
    if (value && value.length > maxLength) {
      setError(field, `Максимальная длина поля - ${maxLength}`);
    }
  };

  const setFileError = (field, options = {}) => {
    const { maxMb = 20, maxKb = null, maxFiles = 2, byExtensions } = options;
    const files = get(values, field);

    if (!files) {
      return;
    }

    if (files.length > maxFiles) {
      setError(
        field,
        `Максимальное количество файлов не должно превышать ${maxFiles}`,
      );
      return;
    }

    const extensions = countBy(files, (f) => getFileExtension(f.name));
    const totalSize = sumBy(files, 'size');

    if (maxMb && totalSize > maxMb * 1024 * 1024) {
      setError(
        field,
        `Совокупный размер прикрепленных файлов не должен превышать ${maxMb} Мб`,
      );
      return;
    }
    if (maxKb && totalSize > maxKb * 1024) {
      setError(
        field,
        `Совокупный размер прикрепленных файлов не должен превышать ${maxKb} Kб`,
      );
      return;
    }

    if (typeof byExtensions === 'function') {
      const setFieldError = (msg) => setError(field, msg);
      byExtensions({ setFieldError, extensions, files });
    }
  };

  // нет проверки, что valueWithoutTemplate содержит только цифры, потому что её выполняет PhoneInput
  const setPhoneError = (field) => {
    const valueWithoutTemplate = get(values, field, '').replace(
      /[+ ()_-]/g,
      '',
    );
    const isLengthValid = valueWithoutTemplate.length === 11;
    const isCountryCodeValid = valueWithoutTemplate.startsWith('7');

    if (!isLengthValid || !isCountryCodeValid) {
      setError(field, 'Поле должно соответствовать шаблону');
    }
  };

  return {
    hasErrors: () => hasError(errors),
    getErrors: () => errors,

    setError,
    setRequired,
    setNotZero,
    setMaxLengthError,
    setFileError,
    setPhoneError,
  };
};

/**
 * @deprecated
 * @see src/app/utils/getFieldError
 */

export const getFieldError = getFieldErrorD;
