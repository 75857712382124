import { useFieldRF } from 'core/form/reduxForm/hooks/useField.RF';
import { TableSimple } from 'core/uiKit/components/tables/TableSimple';
import { TabsCardItem } from 'core/uiKit/components/TabsCard/TabsCardItem';
import React from 'react';

import { AddButtonRepairsPlanTab } from './Add.Button.RepairsPlan.Tab';
import { getColumnsRepairsPlanTab } from './columns.RepairsPlanTab';

export const REPAIRS_INFO_LIST_PLAN_NAME = 'repairs_info_list_plan';

/**
 * Таб таблицы __Проекты__.
 *
 * @returns Element.
 */
export const RepairsPlanTab = () => {
  const { value } = useFieldRF(REPAIRS_INFO_LIST_PLAN_NAME);

  return (
    <TabsCardItem tabValue={REPAIRS_INFO_LIST_PLAN_NAME}>
      <div className="d-flex justify-content-end gap-1 my-2">
        <AddButtonRepairsPlanTab />
      </div>
      <TableSimple columns={getColumnsRepairsPlanTab()} data={value || []} />
    </TabsCardItem>
  );
};
