import oldValueConsumer from 'core/hocs/oldValueConsumer';
import React from 'react';

import CustomTextField from './CustomTextField';

// const ExtendedCustomTextField = oldValueConsumer(CustomTextField);
const ExtendedCustomTextField = oldValueConsumer(CustomTextField);

 
/**
 * 'Старый компонент переделать на NumberFieldRF если это числовое поле если
 * текстовое то TextFieldRF'.
 *
 * @deprecated
 * @param {object} props - Props.
 * @returns {JSX.Element} - JSX.
 * @example --------
 */
const renderCustomTextField = (props) => {
  return <ExtendedCustomTextField {...props} />;
};
export default renderCustomTextField;
