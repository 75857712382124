import { Cell } from 'core/uiKit/components/tables/Table';

/**
 * Колонка __Площадь объекта по пространственным координатам, кв.м__.
 *
 * @type {{ accessor: 'jopa', Header: 'Площадь объекта по пространственным координатам, кв.м' }}
 * @augments Cell
 */
export const jopa: Cell = {
  Header: 'Площадь объекта по пространственным координатам, кв.м',
  accessor: 'jopa',
};
