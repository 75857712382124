/* eslint-disable */
import React from 'react';
import classnames from 'classnames';
import { isObject } from 'lodash';

import isEqualValue from './isEqualValue';
import getFormattedTitle from './getFormattedTitle';


/* eslint-disable */
export default (Component) => {
  return (props) => {
    const getOldValueProps = () => {
      let result;
      if (
        props.input &&
        props.oldValue &&
        isEqualValue(props.input.value, props.oldValue)
      ) {
        result = {};
      } else {
        result = {
          className: getClassName(),
          title: getTitle(),
        };
      }
      return result;
    };

    const getClassName = () => {
      return classnames(props.className, {
        'ods-value--old': props.oldValue,
        'ods-value--deleted':
          props.oldValue && isDeletedValue(props.input.value),
      });
    };

    const isDeletedValue = (value) => {
      let result;
      if (value === '' || value === null) {
        result = true;
      } else if (isObject(value)) {
        result = value.value === null;
      } else {
        result = false;
      }
      return result;
    };

    const getTitle = () => {
      const { oldValue, title, options } = props;
      const result = getFormattedTitle(oldValue, {
        dictionary: Array.isArray(options) ? options : null,
      });
      return result || title;
    };
    // componentWillReceiveProps(nextProps) {
    //   if (nextProps.label) {
    //     log.info(nextProps.label);
    //     this.setState({ label: nextProps.label });
    //   }
    // }

    const { oldValue, ...propsOther } = props;
    let label = props.label;

    // todo костыль так как перетираются пропсы
    if (!label) {
      label = props.children[0].props.children;
    }
    const { className, title } = getOldValueProps();
    return (
      <Component
        {...propsOther}
        className={className}
        title={title}
        label={label}
      />
    );
  };
};
