import { Table } from 'core/uiKit/components/tables/Table';
import React from 'react';

import { getColumnsStoragePlace } from './columns.StoragePlace';
import type { TableStoragePlaceProps } from './Table.StoragePlace.d';
import { useSetupTableStoragePlace } from './useSetup.Table.StoragePlace';

/**
 * Компонент таблицы __Велопарковки__.
 *
 * @param props - Пропсы.
 * @param props.typeId - Id типа огх.
 * @param props.parentTypeId - Id типа родителя.
 * @returns JSX.
 * @example -----
 * <TableStoragePlace
 *  typeId={typeId}
 *  parentTypeId={3}
 * />
 */
export const TableStoragePlace = (props: TableStoragePlaceProps) => {
  const { table, isLoading, count } = useSetupTableStoragePlace(props);

  return (
    <Table
      columns={getColumnsStoragePlace()}
      data={table || []}
      isLoading={isLoading}
      total={count}
    />
  );
};
