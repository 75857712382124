import CardAbstract from 'app/components/card/common/CardAbstract';
import {
  elementFormGrid,
  renderAddressList2,
  renderCustomTextField,
} from 'app/components/card/common/grid/index';
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import { OperationsTab } from 'app/components/card/ogh/BasePgmCard/components/OperationsTab';
import submitCard from 'app/components/card/ogh/submit';
import VersionsTemplate from 'app/components/card/ogh/versions/VersionsTemplate';
import { WithReduxFormSelect } from 'app/components/common/SelectField';
import { GOROD_BASE } from 'app/constants/basePgmTypes';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import getDict from 'app/selectors/getDict';
import { transformValues } from 'app/utils/form/transformation';
import { OwnerIdRF } from 'core/form/reduxForm/fields/prepared/selects/autocompleteAsync/OwnerId.RF';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import _, { has } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import printOghCardHeader from '../header/oghHeader';
import Exports from './components/ExportsTab';
import MaxStorageCapacityTab, {
  createAttribute as createMaxStorageCapacityAttribute,
  mapStateToPropsReducer as maxStorageCapacityReducer,
} from './components/MaxStorageCapacityTab';

const tabs = [{ id: 'reagent_maxstorage_capacity-tab', name: 'Виды ПГР' }];

const types = ['base_pgm'];
const Versions = VersionsTemplate(types);

/**
 * BasePgm.
 *
 * @param {*} props - A.
 * @returns {*}
 */
const BasePgm = (props) => {
  const { objectId } = useGetObjectId();

  const { card, mode, editMode, required } = props;
  const { type_id: typeId } = card;

  /**
   * Return.
   *
   * @returns {JSX.Element}
   */
  return (
    <CardAbstract>
      <div>
        <div id="editor-card">
          {printOghCardHeader(card, mode)}
          {card && objectTabs(typeId)}

          <div id="props">
            {elementFormGrid(
              [
                {
                  addition: {
                    required,
                    type: 'text',
                  },
                  component: renderCustomTextField,
                  editable: editMode,
                  formValue: true,
                  id: 'base_num',
                  label: '№ базы',
                  name: 'base_num',
                },
                {
                  addition: {
                    label: 'Тип',
                    options: props.reagentBaseTypes,
                    required,
                  },
                  component: WithReduxFormSelect,
                  editable: editMode,
                  formValue: true,
                  id: 'reagent_base_type_id',
                  name: 'reagent_base_type_id',
                },
              ],
              2,
              '',
              typeId,
            )}
            {elementFormGrid(
              [
                {
                  addition: {
                    address_required: true,
                    onlyOne: true,
                  },
                  component: renderAddressList2,
                  editable: editMode,
                  formValue: true,
                  id: 'address_list',
                  label: null,
                  name: 'address_list',
                },
              ],
              1,
              'Адрес БТИ',
              typeId,
              {
                required,
              },
            )}
            {elementFormGrid(
              [
                <div>
                  <OwnerIdRF
                    disabled={!editMode}
                    label={'Эксплуатирующая организация'}
                    required={true}
                  />
                </div>,
              ],
              2,
              '',
              typeId,
            )}

            <Tabs tabs={tabs} />
            <MaxStorageCapacityTab
              editable={editMode}
              required={required}
              typeId={typeId}
            />
          </div>
          {objectId ? (
            <div className="d-none" id="versions">
              <Versions
                card={card}
                disabled={props.createMode}
                key={`versions-${card.root_id}`}
              />
            </div>
          ) : null}

          {objectId ? <OperationsTab /> : null}

          {objectId ? (
            <div className="d-none" id="exports">
              <Exports />
            </div>
          ) : null}
        </div>
      </div>
    </CardAbstract>
  );
};

const getReagentBaseTypes = getDict('reagentBaseTypes');

/**
 * MapStateToProps.
 *
 * @param {*} state - A.
 * @param {*} props - B.
 * @returns {*}
 */
const mapStateToProps = (state, props) => {
  const { card, mode } = props;

  const parse = getParseAttribute(props);

  const address_list = card.address_list
    ? card.address_list.map(({ attributes, ...item }) => ({
        ...item,
        ...attributes,
      }))
    : null;

  let owner_id = parse('owner_id');
  if (has(owner_id, 'value')) {
    owner_id = {
      id: owner_id.value,
      name: owner_id.label,
    };
  }

  const result = {
    editMode: isEditMode(state, props),

    enableReinitialize: true,

    initialValues: {
      address_list,
      base_num: parse('base_num'),
      endDate: parseDate(card.end_date),
      owner_id,
      reagent_base_type_id:
        mode && mode.createMode ? GOROD_BASE : parse('reagent_base_type_id'),
      solid_reagent_area: parse('solid_reagent_area'),
      startDate: parseDate(card.start_date),
    },
    onSubmit: onSubmit.bind({ props }),
    reagentBaseTypes: getReagentBaseTypes(state),

    required: isRequired(state, props),
    validate,
  };

  return maxStorageCapacityReducer(result, state, props);
};

/**
 * Validate.
 *
 * @param {*} values - The values to validate.
 * @returns {*}
 */
function validate(values) {
  return validateNumeric(values, numericFields);
}

const numericFields = [
  { name: 'solid_reagent_area', positive: true, type: 'decimal', zero: true },
];

const dictFields = ['reagent_base_type_id', 'owner_id'];

const mandatoryFields = [
  'base_num',
  'reagent_base_type_id',
  'address_list',
  'owner_id',
  'solid_reagent_area',
];

/**
 * CreateAttribute.
 *
 * @param {*} formValues - Form values.
 * @returns {*}
 */
function createAttribute(formValues) {
  return {
    ...createMaxStorageCapacityAttribute(formValues),
  };
}

/**
 * OnSubmit.
 *
 * @param {*} formValues - Form values.
 * @returns {*}
 */
function onSubmit(formValues) {
  validateRequiredFieldsAsync(formValues, mandatoryFields);

  const formValuesWithAttribute = {
    ...formValues,
    ...createAttribute(formValues),
  };

  let submitValues = transformValues(formValuesWithAttribute, {
    custom: {
      address_list: {
        //

        /**
         * Transformer.
         *
         * @param {*} value - Value.
         * @returns {*}
         */
        transformer: (value) => {
          return value == null || value.length === 0
            ? null
            : value.map((item) => {
                return _.mapValues(
                  _.pick(item, [
                    'okrug_id',
                    'address_id',
                    'street_id',
                    'district_id',
                    'description',
                  ]),
                  (id) => {
                    return { bti_object: id };
                  },
                );
              });
        },
      },
    },
    dictFields,
    numericFields,
  });

  submitCard(this.props, submitValues);
}

export default connect(mapStateToProps)(
  reduxForm({ form: 'editorCard' })(BasePgm),
);
