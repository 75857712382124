import {
  CAD_NUMBER_NAME as CAD_NUMBER_NAME_D,
  CadNumberFF as CadNumberFFD,
} from './CadNumberFF';
import {
  COMMENT_NAME as COMMENT_NAME_D,
  CommentFF as CommentFFD,
} from './CommentFF';

 
 
/**
 * @deprecated
 */
 
export const CadNumberFF = CadNumberFFD;

 
 
/**
 * @deprecated
 */
 
export const CAD_NUMBER_NAME = CAD_NUMBER_NAME_D;

 
 
/**
 * @deprecated
 */
 
export const CommentFF = CommentFFD;

 
 
/**
 * @deprecated
 */
 
export const COMMENT_NAME = COMMENT_NAME_D;

 
export * from './ConfirmPasswordFF';
export * from './IdRfid.FF';
export * from './OldPasswordFF';
export * from './PasswordFF';
export * from './TankNumFF';
