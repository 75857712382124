import * as oghTypes from 'app/constants/oghTypes';

import {
  BuildingFilter,
  ContainerFilter,
  DefaultFilter,
  FlowersGardenFilter,
  LawnFilter,
  LittleFormFilter,
  OdhRegistryFilter,
  OtherOksFilter,
  OtherTechPlaceFilter,
  RedBookPlant,
  TreesShrubsFilter,
} from './filters';

/**
 * Функция определения фильтра по типу ОГХ.
 *
 * @param {number} typeId - Тип ОГХ.
 * @returns {Function}
 */
export const filtersMap = (typeId) => {
  switch (typeId) {
    case oghTypes.STOPS:
    case oghTypes.LAMPPOSTS:
    case oghTypes.MAF:
    case oghTypes.MANHOLES:
    case oghTypes.OTHER_ENGIN_CONSTRUCT:
    case oghTypes.ROAD_SIGNS:
    case oghTypes.FENCING:
    case oghTypes.TRAFFIC_LIGHT:
    case oghTypes.CARRIAGEWAY:
    case oghTypes.SIDEWALKS:
    case oghTypes.BOARD_STONE:
    case oghTypes.OTHER_FLAT_BUILDING:
    case oghTypes.TRAM_RAILS:
    case oghTypes.TROLLEYBUS_CONTACT_NETWORK:
    case oghTypes.MARGIN: {
      return () => <OdhRegistryFilter typeId={typeId} />;
    }
    case oghTypes.BUILDING: {
      return BuildingFilter;
    }
    case oghTypes.CONTAINER: {
      return ContainerFilter;
    }
    case oghTypes.LAWN: {
      return LawnFilter;
    }
    case oghTypes.FLOWERS_GARDEN: {
      return FlowersGardenFilter;
    }
    case oghTypes.OTHER_OKS: {
      return () => <OtherOksFilter typeId={typeId} />;
    }
    case oghTypes.TREES_SHRUBS: {
      return TreesShrubsFilter;
    }
    case oghTypes.OTHER_TECH_PLACE: {
      return () => <OtherTechPlaceFilter typeId={typeId} />;
    }
    case oghTypes.LITTLE_FORM: {
      return () => <LittleFormFilter typeId={typeId} />;
    }
    case oghTypes.RED_BOOK_PLANT: {
      return RedBookPlant;
    }
    default: {
      return () => <DefaultFilter typeId={typeId} />;
    }
  }
};
