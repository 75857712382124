import {
  elementFormGrid,
  renderCustomTextField,
} from 'app/components/card/common/grid';
import PropertiesTabs from 'app/components/card/ogh/EmergencyParkingCard/components/PropertiesTabs';
import { column } from 'app/constants/tables';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  card: PropTypes.object,
  documents: PropTypes.object,
  editMode: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
};

/**
 * Properties.
 *
 * @param {*} props - Properties.
 * @param {*} props.card - Card.
 * @param {*} props.editMode - Edit mode.
 * @param {*} props.required - Required.
 * @param {*} props.documents - Documents.
 * @returns {JSX.Element}
 */
function Properties({ card, editMode, required, documents }) {
  const { type_id: typeId } = card;
  return (
    <div id="props">
      {elementFormGrid(
        [
          {
            addition: { required, type: 'text' },
            component: renderCustomTextField,
            editable: editMode,
            formValue: true,
            id: 'name',
            label: 'Описание территории',
            name: 'name',
          },

          <div>
            <ParentInfo />
          </div>,
        ],
        column.TWO,
        '',
        typeId,
      )}

      <PropertiesTabs
        card={card}
        documents={documents}
        editMode={editMode}
        required={required}
      />
    </div>
  );
}

Properties.propTypes = propTypes;

export default Properties;
