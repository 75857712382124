import { get } from 'lodash';
/* eslint-disable  */

/**
 *
 * @param state
 * @param props
 */
export default (state, props) => {
  return (
    get(props, 'mode.editMode', false) ||
    get(state, 'card.mode.editMode', false)
  );
};
