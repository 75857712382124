export const PRELOAD_DATA = 'PRELOAD_DATA';
export const REQUEST_SERVER_INFO = 'REQUEST_SERVER_INFO';

export const REQUEST_FEED_BACK = 'REQUEST_FEED_BACK';
export const RECEIVE_FEED_BACK = 'RECEIVE_FEED_BACK';
export const REQUEST_CARD = 'REQUEST_CARD';
export const RECEIVE_CARD = 'RECEIVE_CARD';
export const RECEIVE_ERROR_CARD = 'RECEIVE_ERROR_CARD';
export const REQUEST_DELETE_CARD = 'REQUEST_DELETE_CARD';
export const RECEIVE_DELETE_CARD = 'RECEIVE_DELETE_CARD';
export const RECEIVE_ERROR_DELETE_CARD = 'RECEIVE_ERROR_DELETE_CARD';
export const VIEW_CARD = 'VIEW_CARD';
export const SET_MODE = 'SET_MODE';
export const RECEIVE_DELETE_CARD_ALLOW = 'RECEIVE_DELETE_CARD_ALLOW';
export const EDIT_CARD = 'EDIT_CARD';
export const RECEIVE_REGISTRY_ACTUAL_GROUP = 'RECEIVE_REGISTRY_ACTUAL_GROUP';
export const RECEIVE_EDIT_CARD_ALLOW = 'RECEIVE_EDIT_CARD_ALLOW';
export const RECEIVE_EDIT_CARD_DENY = 'RECEIVE_EDIT_CARD_DENY';
export const EDIT_CARD_CLEAR_DIALOG = 'EDIT_CARD_CLEAR_DIALOG';
export const REQUEST_CANCEL_EDIT_CARD = 'REQUEST_CANCEL_EDIT_CARD';
export const RECEIVE_EDIT_CARD_DID_NOT_LOCK = 'RECEIVE_EDIT_CARD_DID_NOT_LOCK';
export const CONTINUE_EDIT_CARD = 'CONTINUE_EDIT_CARD';
export const SET_TECH_EDIT_TYPE = 'SET_TECH_EDIT_TYPE';
export const SAVE_CARD = 'SAVE_CARD';
export const CREATE_CARD = 'CREATE_CARD';
export const REQUEST_CREATE_CARD = 'REQUEST_CREATE_CARD';
export const RECEIVE_END_DATE = 'RECEIVE_END_DATE';
export const REQUEST_UPDATE_CARD = 'REQUEST_UPDATE_CARD';
export const RECEIVE_UPDATE_CARD = 'RECEIVE_UPDATE_CARD';
export const RECEIVE_ERROR_UPDATE_CARD = 'RECEIVE_ERROR_UPDATE_CARD';
export const RECEIVE_ERROR_START_UPDATE_CARD =
  'RECEIVE_ERROR_START_UPDATE_CARD';
export const PREVIEW_CREATE_CARD = 'PREVIEW_CREATE_CARD';
export const RECEIVE_CREATE_CARD = 'RECEIVE_CREATE_CARD';
export const RECEIVE_CREATE_CHILD_CARD = 'RECEIVE_CREATE_CHILD_CARD';
export const RECEIVE_ERROR_CREATE_CARD = 'RECEIVE_ERROR_CREATE_CARD';
export const LEAVE_CURRENT_CARD = 'LEAVE_CURRENT_CARD';
export const CANCEL_LEAVE_CURRENT_CARD = 'CANCEL_LEAVE_CURRENT_CARD';
export const CANCEL_EDIT_CURRENT_CARD = 'CANCEL_EDIT_CURRENT_CARD';
export const CANCEL_MODE = 'CANCEL_MODE';

export const UPDATE_EFFECT_ATTRIBUTE_CARD = 'UPDATE_EFFECT_ATTRIBUTE_CARD';
export const CHANGE_CALC_ATTRIBUTES = 'CHANGE_CALC_ATTRIBUTES';
export const READY_FOR_UPDATE_CARD = 'READY_FOR_UPDATE_CARD';
export const AFTER_RECEIVING = 'AFTER_RECEIVING';
export const REQUEST_START_EDIT_CARD = 'REQUEST_START_EDIT_CARD';
export const RECEIVE_START_SEND_MATCH_CARD = 'RECEIVE_START_SEND_MATCH_CARD';
export const RECEIVE_ERROR_MATCH_CARD = 'RECEIVE_ERROR_MATCH_CARD';

export const ROOT_TREE_STATE_CHANGED = 'ROOT_TREE_STATE_CHANGED';

export const TOGGLE_OGH_ORDER_DETAILS = 'TOGGLE_OGH_ORDER_DETAILS';
export const TOGGLE_OGH_ORDER_GEOMETRY = 'TOGGLE_OGH_ORDER_GEOMETRY';
export const RESET_OGH_ORDERS_GEOMETRY = 'RESET_OGH_ORDERS_GEOMETRY';

export const CLEAR_TREE_ELEMENTS = 'CLEAR_TREE_ELEMENTS';
export const SET_ACTIVE_MAP_TREE_ELEMENT = 'SET_ACTIVE_MAP_TREE_ELEMENT';
export const SET_ACTIVE_OGH_TREE_ELEMENT = 'SET_ACTIVE_OGH_TREE_ELEMENT';
export const SET_MAIN_PAGE_TREE_DATA = 'SET_MAIN_PAGE_TREE_DATA';
export const SET_MAP_TREE_EXPANDED = 'SET_MAP_TREE_EXPANDED';
export const SET_OGH_TREE_EXPANDED = 'SET_OGH_TREE_EXPANDED';
export const TREE_SELECT_ROOT = 'TREE_SELECT_ROOT';
export const REQUEST_TREE_SELECTED_ELEMENT = 'REQUEST_TREE_SELECTED_ELEMENT';
export const RECEIVE_TREE_SELECTED_ELEMENT = 'RECEIVE_TREE_SELECTED_ELEMENT';
export const REQUEST_ADD_OBJECT_TO_TREE = 'REQUEST_ADD_OBJECT_TO_TREE';
export const RECEIVE_ADD_OBJECT_TO_TREE = 'RECEIVE_ADD_OBJECT_TO_TREE';
export const REQUEST_FEW_TREE = 'REQUEST_FEW_TREE';
export const RECEIVE_FEW_TREE = 'RECEIVE_FEW_TREE';
export const CLEAR_EXPAND_AFTER_FEW_TREE = 'CLEAR_EXPAND_AFTER_FEW_TREE';
export const RECEIVE_DIFF_OBJECT_TREES = 'RECEIVE_DIFF_OBJECT_TREES';
export const REQUEST_DIFF_OBJECT_TREES = 'REQUEST_DIFF_OBJECT_TREES';
export const TOGGLE_TREE_EDIT_FORM = 'TOGGLE_TREE_EDIT_FORM';
export const REQUEST_TREE = 'REQUEST_TREE';
export const RECEIVE_TREE = 'RECEIVE_TREE';
export const SET_TREE_EDIT_OBJECT = 'SET_TREE_EDIT_OBJECT';
export const REQUEST_SAVE_TREE = 'REQUEST_SAVE_TREE';
export const RECEIVE_SAVE_TREE = 'RECEIVE_SAVE_TREE';
export const ADD_TO_TREE_JOURNAL = 'ADD_TO_TREE_JOURNAL';
export const CHANGE_TREE_JOURNAL = 'CHANGE_TREE_JOURNAL';
export const CHANGE_EDIT_MODE = 'CHANGE_EDIT_MODE';
export const CHANGE_CREATE_MODE = 'CHANGE_CREATE_MODE';
export const REQUEST_FILE_DOWNLOAD = 'REQUEST_FILE_DOWNLOAD';
export const RECEIVE_FILE_DOWNLOAD = 'RECEIVE_FILE_DOWNLOAD';

export const REQUEST_VERSIONS = 'REQUEST_VERSIONS';
export const RECEIVE_VERSIONS = 'RECEIVE_VERSIONS';
export const SELECT_VERSION = 'SELECT_VERSION';
export const DESELECT_VERSION = 'DESELECT_VERSION';
export const RESET_VERSIONS = 'RESET_VERSIONS';
export const RESET_MATCHING = 'RESET_MATCHING';
export const TOGGLE_VERSIONS_COMPARE_REPORT = 'TOGGLE_VERSIONS_COMPARE_REPORT';
export const REQUEST_DIFF_VERSIONS_PROPERTIES =
  'REQUEST_DIFF_VERSIONS_PROPERTIES';
export const RECEIVE_DIFF_VERSIONS_PROPERTIES =
  'RECEIVE_DIFF_VERSIONS_PROPERTIES';
export const REQUEST_DIFF_GEOMETRY = 'REQUEST_DIFF_GEOMETRY';
export const RECEIVE_DIFF_GEOMETRY = 'RECEIVE_DIFF_GEOMETRY';

export const REQUEST_SEARCH_IN_REGISTRY = 'REQUEST_SEARCH_IN_REGISTRY';
export const REQUEST_SEND_DXF_FILE = 'REQUEST_SEND_DXF_FILE';
export const RECEIVE_SEND_DXF_FILE = 'RECEIVE_SEND_DXF_FILE';
export const RECEIVE_SEARCH_IN_REGISTRY_RESULTS =
  'RECEIVE_SEARCH_IN_REGISTRY_RESULTS';
export const SET_ODS_REGISTRY_TYPE_NAVIGATION_MODE =
  'SET_ODS_REGISTRY_TYPE_NAVIGATION_MODE';
export const ODS_REGISTRY_RESET = 'ODS_REGISTRY_RESET';
export const RECEIVE_REGISTRY_FOR_TREE = 'RECEIVE_REGISTRY_FOR_TREE';
export const PUSH_TO_ROTTEN_MAP = 'PUSH_TO_ROTTEN_MAP';
export const RECEIVE_REGISTRY_FOR_TREE_GROUP =
  'RECEIVE_REGISTRY_FOR_TREE_GROUP';
export const STORE_FILTER_STATE = 'STORE_FILTER_STATE';

export const REMOTE_SUBMIT_DONE = 'REMOTE_SUBMIT_DONE';
export const REMOTE_SUBMIT_START = 'REMOTE_SUBMIT_START';
export const RESET_FORM_START = 'RESET_FORM_START';
export const RESET_FORM_DONE = 'RESET_FORM_DONE';
export const SAVE_MAPS_FORM = 'SAVE_MAPS_FORM';

export const SET_TABLE_PARAM = 'SET_TABLE_PARAM';
export const RESET_TABLE_PARAM = 'RESET_TABLE_PARAM';
export const SET_TABLE_PARAMETERS = 'SET_TABLE_PARAMETERS';

export const REQUEST_TITLE_LIST = 'REQUEST_TITLE_LIST';
export const RECEIVE_TITLE_LIST = 'RECEIVE_TITLE_LIST';
export const TOGGLE_ADD_TITLELIST_FORM = 'TOGGLE_ADD_TITLELIST_FORM';
export const REQUEST_CREATE_TITLELIST = 'REQUEST_CREATE_TITLELIST';
export const RECEIVE_CREATE_TITLELIST = 'RECEIVE_CREATE_TITLELIST';
export const RECEIVE_CREATE_TITLELIST_ERROR = 'RECEIVE_CREATE_TITLELIST_ERROR';

export const REQUEST_API_SUBSCRIPTIONS = 'REQUEST_API_SUBSCRIPTIONS';
export const RECEIVE_API_SUBSCRIPTIONS = 'RECEIVE_API_SUBSCRIPTIONS';
export const REQUEST_API_USERS = 'REQUEST_API_USERS';
export const RECEIVE_API_USERS = 'RECEIVE_API_USERS';
export const REQUEST_SAVE_API_USERS = 'REQUEST_SAVE_API_USERS';
export const RECEIVE_SAVE_API_USERS = 'RECEIVE_SAVE_API_USERS';
export const REQUEST_API_SUBSCRIPTION_TYPES = 'REQUEST_API_SUBSCRIPTION_TYPES';
export const RECEIVE_API_SUBSCRIPTION_TYPES = 'RECEIVE_API_SUBSCRIPTION_TYPES';

export const REQUEST_SAVE_GEOMETRY = 'REQUEST_SAVE_GEOMETRY';
export const RECEIVE_SAVE_GEOMETRY = 'RECEIVE_SAVE_GEOMETRY';

export const REQUEST_DELETE_TITLELIST = 'REQUEST_DELETE_TITLELIST';
export const RECEIVE_DELETE_TITLELIST = 'RECEIVE_DELETE_TITLELIST';

export const EXPORT_REGISTRY = 'EXPORT_REGISTRY';
export const REQUEST_ROLE = 'REQUEST_ROLE';
export const RECEIVE_ROLE = 'RECEIVE_ROLE';
export const REQUEST_UPDATE_ROLE = 'REQUEST_UPDATE_ROLE';
export const RECEIVE_UPDATE_ROLE = 'RECEIVE_UPDATE_ROLE';
export const REQUEST_CREATE_ROLE = 'REQUEST_CREATE_ROLE';
export const RECEIVE_CREATE_ROLE = 'RECEIVE_CREATE_ROLE';
export const CLEAR_ADMIN_ERROR = 'CLEAR_ADMIN_ERROR';
export const REQUEST_USER_REGISTRY = 'REQUEST_USER_REGISTRY';
export const RECEIVE_USER_REGISTRY = 'RECEIVE_USER_REGISTRY';
export const RECEIVE_CREATE_USER = 'RECEIVE_CREATE_USER';
export const REQUEST_UPDATE_USER = 'REQUEST_UPDATE_USER';
export const RECEIVE_UPDATE_USER = 'RECEIVE_UPDATE_USER';
export const RECEIVE_USER = 'RECEIVE_USER';
export const REQUEST_CHANGE_USER_STATUS = 'REQUEST_CHANGE_USER_STATUS';
export const RECEIVE_CHANGE_USER_STATUS = 'RECEIVE_CHANGE_USER_STATUS';
export const REQUEST_CHANGE_PASSWORD = 'REQUEST_CHANGE_PASSWORD';
export const RECEIVE_CHANGE_PASSWORD = 'RECEIVE_CHANGE_PASSWORD';

export const API_POST_USER_SIGNIN = 'API_POST_USER_SIGNIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const API_GET_TL_REGISTRY = 'API_GET_TL_REGISTRY';
export const API_GET_TL_COMBINED = 'API_GET_TL_COMBINED';
export const API_GET_USER_REGISTRY = 'API_GET_USER_REGISTRY';
export const API_GET_ROLE_REGISTRY = 'API_GET_ROLE_REGISTRY';
export const API_GET_MATCHING_REGISTRY = 'API_GET_MATCHING_REGISTRY';
export const API_GET_MATCHING_TECH_REGISTRY = 'API_GET_MATCHING_TECH_REGISTRY';
export const API_GET_TEMPLATE_REGISTRY = 'API_GET_TEMPLATE_REGISTRY';
export const API_DELETE_TEMPLATE = 'API_DELETE_TEMPLATE';
export const API_GET_TITLELIST_DETAILS_REGISTRY =
  'API_GET_TITLELIST_DETAILS_REGISTRY';
export const API_DELETE_TITLELIST = 'API_DELETE_TITLELIST';
export const API_POST_FORGOT_PASSWORD = 'API_POST_FORGOT_PASSWORD';
export const API_POST_RESET_PASSWORD = 'API_POST_RESET_PASSWORD';
export const API_CHECK_TOKEN = 'API_CHECK_TOKEN';
export const API_GET_OGH_ORDERS = 'API_GET_OGH_ORDERS';
export const API_GET_OOPT_REGISTRY = 'API_GET_OOPT_REGISTRY';

export const REQUEST_CALC_DATE_TO_TITLE_LIST =
  'REQUEST_CALC_DATE_TO_TITLE_LIST';
export const RECEIVE_CALC_DATE_TO_TITLE_LIST =
  'RECEIVE_CALC_DATE_TO_TITLE_LIST';

export const LOADER_SHOW = 'LOADER_SHOW';
export const LOADER_HIDE = 'LOADER_HIDE';

export const REQUEST_TEMPLATE_REGISTRY = 'REQUEST_TEMPLATE_REGISTRY';
export const RECEIVE_TEMPLATE_REGISTRY = 'RECEIVE_TEMPLATE_REGISTRY';

export const REQUEST_MATCHING = 'REQUEST_MATCHING';
export const RECEIVE_MATCHING = 'RECEIVE_MATCHING';

export const REQUEST_CREATE_TEMPLATE = 'REQUEST_CREATE_TEMPLATE';
export const RECEIVE_CREATE_TEMPLATE = 'RECEIVE_CREATE_TEMPLATE';

export const REQUEST_EDIT_TEMPLATE = 'REQUEST_EDIT_TEMPLATE';
export const RECEIVE_EDIT_TEMPLATE = 'RECEIVE_EDIT_TEMPLATE';

export const RECEIVE_DELETE_TEMPLATE = 'RECEIVE_DELETE_TEMPLATE';
export const REQUEST_DELETE_TEMPLATE = 'REQUEST_DELETE_TEMPLATE';

export const REQUEST_SEND_MATCHING = 'REQUEST_SEND_MATCHING';
export const RECEIVE_SEND_MATCHING = 'RECEIVE_SEND_MATCHING';
export const RECEIVE_SEND_MATCHING_ERROR = 'RECEIVE_SEND_MATCHING_ERROR';

export const SEND_TO_MATCHING = 'SEND_TO_MATCHING';

export const REQUEST_MATCHING_OPERATION = 'REQUEST_MATCHING_OPERATION';
export const RECEIVE_MATCHING_OPERATION = 'RECEIVE_MATCHING_OPERATION';

export const REQUEST_OBJECT_MATCHING = 'REQUEST_OBJECT_MATCHING';
export const RECEIVE_OBJECT_MATCHING = 'RECEIVE_OBJECT_MATCHING';

export const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';

export const SHOW_DIALOG = 'SHOW_DIALOG';
export const HIDE_DIALOG = 'HIDE_DIALOG';

export const REQUEST_OBJECT_TYPE_CNT = 'REQUEST_OBJECT_TYPE_CNT';
export const RECEIVE_OBJECT_TYPE_CNT = 'RECEIVE_OBJECT_TYPE_CNT';

export const REQUEST_GEOMETRY_FOR_MAIN_PAGE = 'REQUEST_GEOMETRY_FOR_MAIN_PAGE';
export const RECEIVE_GEOMETRY_FOR_MAIN_PAGE = 'RECEIVE_GEOMETRY_FOR_MAIN_PAGE';

export const REQUEST_TITLE_LIST_HISTORY = 'REQUEST_TITLE_LIST_HISTORY';
export const RECEIVE_TITLE_LIST_HISTORY = 'RECEIVE_TITLE_LIST_HISTORY';

export const REQUEST_TITLE_LIST_DIFF = 'REQUEST_TITLE_LIST_DIFF';
export const RECEIVE_TITLE_LIST_DIFF = 'RECEIVE_TITLE_LIST_DIFF';

export const CLEAR_TITLE_LIST = 'CLEAR_TITLE_LIST';
export const CLEAR_MATCHING = 'CLEAR_MATCHING';

export const API_GET_TECHNOLOGY_REGISTRY = 'API_GET_TECHNOLOGY_REGISTRY';
export const API_GET_TECHNOLOGY_VERSIONS = 'API_GET_TECHNOLOGY_VERSIONS';
export const RECEIVE_TECH_TREE = 'RECEIVE_TECH_TREE';

export const API_GET_TECHNOLOGY_REPORT = 'API_GET_TECHNOLOGY_REPORT';

export const REQUEST_START_EDIT_TL = 'REQUEST_START_EDIT_TL';
export const RECEIVE_START_EDIT_TL = 'RECEIVE_START_EDIT_TL';
export const RECEIVE_CANCEL_EDIT_TL = 'RECEIVE_CANCEL_EDIT_TL';

export const SET_TECH_TREE_PATHS = 'SET_TECH_TREE_PATHS';

export const REQUEST_SYSTEM_DATA = 'REQUEST_SYSTEM_DATA';
export const RECEIVE_SYSTEM_DATA = 'RECEIVE_SYSTEM_DATA';

export const SET_START_DATE = 'SET_START_DATE';

export const REQUEST_CHANGE_TREE = 'REQUEST_CHANGE_TREE';

export const REQUEST_CHECK_INTERSECTIONS = 'REQUEST_CHECK_INTERSECTIONS';
export const RECEIVE_CHECK_INTERSECTIONS = 'RECEIVE_CHECK_INTERSECTIONS';
export const RESET_INTERSECTIONS_GEOMETRY = 'RESET_INTERSECTIONS_GEOMETRY';

export const REQUEST_PREVIEW_TREE_SELECTED_ELEMENT =
  'REQUEST_PREVIEW_TREE_SELECTED_ELEMENT';
export const RECEIVE_PREVIEW_TREE_SELECTED_ELEMENT =
  'RECEIVE_PREVIEW_TREE_SELECTED_ELEMENT';

export const REQUEST_CHECK_GEOM_CONTAINS = 'REQUEST_CHECK_GEOM_CONTAINS';
export const RECEIVE_CHECK_GEOM_CONTAINS = 'RECEIVE_CHECK_GEOM_CONTAINS';

export const REQUEST_REON_INTERSECTIONS_GEOMETRY =
  'REQUEST_REON_INTERSECTIONS_GEOMETRY';
export const RECEIVE_REON_INTERSECTIONS_GEOMETRY =
  'RECEIVE_REON_INTERSECTIONS_GEOMETRY';
export const RESET_REON_INTERSECTIONS = 'RESET_REON_INTERSECTIONS';

export const REQUEST_BTI_GEOMETRY = 'REQUEST_BTI_GEOMETRY';
export const RECEIVE_BTI_GEOMETRY = 'RECEIVE_BTI_GEOMETRY';
export const RESET_BTI_GEOMETRY = 'RESET_BTI_GEOMETRY';

export const REQUEST_ROLLBACK_TREE_CHANGES = 'REQUEST_ROLLBACK_TREE_CHANGES';
export const RECEIVE_ROLLBACK_TREE_CHANGES = 'RECEIVE_ROLLBACK_TREE_CHANGES';

export const CLEAR_API_LAST_ERROR_ACTION_TYPE =
  'CLEAR_API_LAST_ERROR_ACTION_TYPE';
export const RELOAD_CARD = 'RELOAD_CARD';

export const REQUEST_OGH_CHOICE_ADDRESS_LIST =
  'REQUEST_OGH_CHOICE_ADDRESS_LIST';
export const RECEIVE_OGH_CHOICE_ADDRESS_LIST =
  'RECEIVE_OGH_CHOICE_ADDRESS_LIST';
export const RESET_OGH_CHOICE_ADDRESS_LIST = 'RESET_OGH_CHOICE_ADDRESS_LIST';

export const TOGGLE_GEOMETRY_OF_CHILDREN = 'TOGGLE_GEOMETRY_OF_CHILDREN';

export const REQUEST_NEIGHBOURS_GEOMETRY = 'REQUEST_NEIGHBOURS_GEOMETRY';
export const RECEIVE_NEIGHBOURS_GEOMETRY = 'RECEIVE_NEIGHBOURS_GEOMETRY';
export const RESET_NEIGHBOURS_GEOMETRY = 'RESET_NEIGHBOURS_GEOMETRY';

export const REQUEST_GEOMETRY = 'REQUEST_GEOMETRY';
export const RECEIVE_GEOMETRY = 'RECEIVE_GEOMETRY';

export const REQUEST_GROUP_GEOMETRY = 'REQUEST_GROUP_GEOMETRY';
export const RECEIVE_GROUP_GEOMETRY = 'RECEIVE_GROUP_GEOMETRY';

export const REQUEST_BASE_PGM_OPERATIONS = 'REQUEST_BASE_PGM_OPERATIONS';
export const RECEIVE_BASE_PGM_OPERATIONS = 'RECEIVE_BASE_PGM_OPERATIONS';

export const REQUEST_ACTUAL_PGM_BASES = 'REQUEST_ACTUAL_PGM_BASES';
export const RECEIVE_ACTUAL_PGM_BASES = 'RECEIVE_ACTUAL_PGM_BASES';

export const REQUEST_CREATE_BASE_PGM_OPERATION =
  'REQUEST_CREATE_BASE_PGM_OPERATION';
export const RECEIVE_CREATE_BASE_PGM_OPERATION =
  'RECEIVE_CREATE_BASE_PGM_OPERATION';

export const REQUEST_DELETE_BASE_PGM_OPERATIONS =
  'REQUEST_DELETE_BASE_PGM_OPERATIONS';
export const RECEIVE_DELETE_BASE_PGM_OPERATIONS =
  'RECEIVE_DELETE_BASE_PGM_OPERATIONS';

export const REQUEST_BASE_PGM_EXPORTS = 'REQUEST_BASE_PGM_EXPORTS';
export const RECEIVE_BASE_PGM_EXPORTS = 'RECEIVE_BASE_PGM_EXPORTS';

export const REQUEST_REAGENT_NORM_REGISTRY = 'REQUEST_REAGENT_NORM_REGISTRY';
export const RECEIVE_REAGENT_NORM_REGISTRY = 'RECEIVE_REAGENT_NORM_REGISTRY';

export const MAP_LAST_EVENT = 'MAP_LAST_EVENT';
export const CLEAR_CARD = 'CLEAR_CARD';

export const SAVE_TREE_ACTIONS = 'SAVE_TREE_ACTIONS';
export const CLEAR_TREE_ACTIONS = 'CLEAR_TREE_ACTIONS';
