import { useCheckObjectParent } from 'app/actions/odsObjectActions/useCheckObjectParent';
import { useStartMatchingCard } from 'app/actions/odsObjectActions/useStartMatchingCard';
import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { PROJECT } from 'app/constants/oghStatus';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { queryClient } from 'app/routes/QueryClientProvider';
import { useGetCardSelector } from 'app/selectors/useSelectors/useGetCard.Selector';
import { useGetTreeSelector } from 'app/selectors/useSelectors/useGetTree.Selector';
import { toast } from 'core/uiKit/components/Toast';
import { useParams } from 'react-router-dom';

import { ButtonSendMatchingOgh } from './Button.SendMatchingOgh';
import { joinIds } from './joinIds';

/**
 * A.
 *
 * @returns {*}
 */
export const SendMatchingOghButton = () => {
  const { childId, id } = useParams();
  const objectId = +(childId || id);
  const { typeId, recordId } = useGetCurrentCard();
  const { logical_state_id, startDate } = useGetCardSelector();
  const selected = useGetTreeSelector();
  const { editMode } = useEditMode();
  const startMatchingCard = useStartMatchingCard();
  const checkObjectParent = useCheckObjectParent(recordId, startDate);

  /**
   * A.
   *
   * @returns {*}
   */
  const handleSendMatchingButtonClick = () => {
    const rootIsProject = logical_state_id === PROJECT;
    if (Object.keys(selected) && rootIsProject) {
      checkObjectParent().then((parentInfo) => {
        const ids = joinIds(parentInfo);
        if (ids) {
          toast.errorDying(
            `Данная версия объекта является элементом объекта(ов) с id ${ids}. Согласование невозможно`,
          );
        } else {
          startMatchingCard(recordId, typeId).then(() =>
            queryClient.invalidateQueries({
              queryKey: ['getVisibilityButtonsOgh'],
            }),
          );
        }
      });
    } else {
      startMatchingCard(recordId, typeId).then(() =>
        queryClient.invalidateQueries({
          queryKey: ['getVisibilityButtonsOgh'],
        }),
      );
    }
  };

  const show = !editMode && objectId;

  return show ? (
    <ButtonSendMatchingOgh
      objectId={objectId}
      onClick={handleSendMatchingButtonClick}
    />
  ) : null;
};
