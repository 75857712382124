import { COMMENT_NAME } from 'core/form/finalForm/fields/prepared/textField/CommentFF';
/* eslint-disable */
import { getValidator } from 'core/form/finalForm/utils/validate';

/**
 *
 * @param values
 */
export const formValidation = (values) => {
  const { hasErrors, getErrors, setRequired } = getValidator(values);
  setRequired(COMMENT_NAME);
  return hasErrors() ? getErrors() : null;
};
