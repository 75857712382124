import './formDialogCreateOGH.scss';
import 'assets/styles/dialogs.form.scss';

import { BodyContext } from 'app/pages/providers';
import { tomorrowDateAndHours } from 'app/utils/dateUtils';
import { ContinueFFDialog } from 'core/form/finalForm/components/dialogs/ContinueFF.Dialog';
import { KeyboardDatePickerFF } from 'core/form/finalForm/fields/default/datePickers/KeyboardDatePickerFF';
import { OghTypeSelectFF } from 'core/form/finalForm/fields/prepared/selects/simple/OghTypeSelectFF';
import React, { useContext } from 'react';

import { validationForm } from './validationForm';

/**
 * Компонент FormDialogCreateOGH.
 *
 * @param {object} props - Пропсы.
 * @param {React.ReactNode} props.children - Any.
 * @param {Function} props.onSubmit - Any.
 * @param {number} props.typeId - Тип ОГХ.
 * @returns {JSX.Element}
 */
export const FormDialogCreateOGH = ({
  children = () => null,
  onSubmit,
  typeId,
}) => {
  const { isShowDialogCreate, toggleShowDialogCreate } =
    useContext(BodyContext);

  /**
   * Функция closeDialog.
   *
   * @returns {void}
   */
  const closeDialog = () => {
    toggleShowDialogCreate(false);
  };

  const initialValues = {
    startDate: tomorrowDateAndHours,
    typeId,
  };

  return (
    <ContinueFFDialog
      textHeader={'Добавление'}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validationForm}
      isOpen={isShowDialogCreate}
      closeDialog={closeDialog}
      Body={
        <>
          <div className={'form-dialog-create-ogh__row'}>
            <KeyboardDatePickerFF
              label={'Действует с'}
              name={'startDate'}
              minDate={tomorrowDateAndHours}
            />

            <OghTypeSelectFF disabled={true} />
          </div>

          {children}
        </>
      }
    />
  );
};
