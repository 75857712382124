import { get } from 'app/api/crud/get';

export type userFetchDataType = {
  email?: string;
  fio?: string;
  first_name?: string;
  org_id?: string;
  org_name?: string;
  password_change_message?: string;
  patronymic?: string;
  phone?: string;
  privileges?: string[];
  sur_name?: string;
  username?: string;
};

/**
 * Fn fetchUserData.
 *
 * @returns {*}
 */
export const fetchUserData = async () =>
  await get<userFetchDataType>('/preload/user_data');
