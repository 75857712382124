import { fetchOghTableCount } from 'app/pages/registry/ogh/api/fetchOghTableCount';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';

/**
 * @typedef {import('core/utils/hooks/useReactQuery').UseQueryResult} UseQueryResult
 */

/**
 * Хук получения количества данных для таблиц реестров ОГХ.
 *
 * @deprecated
 * @see используй useFetchOghTable там все есть.
 * @param {Array} queryKey - Данные для запроса.
 * @returns {UseQueryResult} - Жопа.
 * @example ----
 */
export const useFetchOghTableCount = (queryKey) => {
  const [key, params] = queryKey;
  return useQueryAdaptor([`${key}Count`, params], fetchOghTableCount, {
    enabled: Boolean(params?.typeId),
  });
};
