import { REQUIRED_FIELD_MESSAGE } from 'app/constants/messages';
import { INTENSITY_NUM_NAME } from 'core/uiKit/preparedInputs/numberField/IntensityNum';

/**
 * Функция для записи ошибки поля __Интенсивность движения__.
 *
 * @param {object} errors - Объект с ошибками.
 * @param {number} cleanCategoryId - Текущая категория по уборке.
 * @param {number} intensity - Текущее значение поля Интенсивность движения.
 * @param {boolean} isOrphanObject - Флаг объект переданный в эксплуатацию.
 * @returns {void}
 */
export const validateIntensityNumField = (
  errors,
  cleanCategoryId = 0,
  intensity,
  isOrphanObject,
) => {
  if (!isOrphanObject && !intensity && [1, 2].includes(cleanCategoryId)) {
    errors[INTENSITY_NUM_NAME] = REQUIRED_FIELD_MESSAGE;
  }
};
