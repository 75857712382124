import { FORMAT_DATE } from 'app/utils/date/format';
import moment from 'moment';
import { Moment } from 'moment';

import { isValidMoment } from './isValidMoment';

/**
 * Форматирует дату формата '2020-10-20T00:00:00.000Z' в '20.10.2020'.
 *
 * @category dateUtils
 * @param {string|Moment} [date] - The date to convert.
 * @param {string} [formatDate] - Не обязательный формат принемаемой строки.
 * @returns {string}
 * @example
 * toDate('2020-10-20T00:00:00.000Z') //? 20.10.2020
 * toDate('2020-10-20T01:01:00.000Z', 'DD.MM.YYYY HH:mm') //? 20.10.2020 01:01
 */
export const toDate = (date, formatDate = FORMAT_DATE) => {
  const momentDate = moment(date);

  return isValidMoment(momentDate) ? momentDate.format(formatDate) : '';
};
