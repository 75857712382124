/**
 * Fn filterByOtherImprovementObjectTypeId.
 *
 * @deprecated
 * @param {{}} props - Жопа.
 * @param {Array} props.dict - Жопа.
 * @param {*} props.parentTypeId - B.
 * @param {*} props.otherImprovementObjectTypeId - B.
 * @param {*} props.listKey - B.
 * @returns {Array} - Array.
 * @example --------
 */
function filterByOtherImprovementObjectTypeId({
  dict,
  parentTypeId,
  otherImprovementObjectTypeId,
  listKey = 'ogh_object_type_list',
}) {
  return dict.filter((item) => {
    const list = item[listKey] || [];
    let result;
    if (list.length) {
      result = list.some((item) => {
        // Если список other_improvement_object_type_list пуст - подходят все доступные типы для реестра
        if (item?.other_improvement_object_type_list?.length) {
          return item?.ogh_object_type_id === parentTypeId;
        }
        // TODO: по моему это не надо.
        if (item?.ogh_object_type_id) {
          return (
            item.ogh_object_type_id === parentTypeId &&
            item?.other_improvement_object_type_list?.includes(
              otherImprovementObjectTypeId,
            )
          );
        }
        return item === parentTypeId;
      });
    } else {
      result = true;
    }
    return result;
  });
}
export default filterByOtherImprovementObjectTypeId;
