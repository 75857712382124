import { useState } from 'react';

/* eslint-disable */
export const useDialogDocuments = () => {
  const [open, setOpen] = useState(false);

  const cancelDialog = () => {
    setOpen(false);
  };

  const openDialog = () => {
    setOpen(true);
  };

  return {
    openDialog,
    cancelDialog,
    open,
  };
};
