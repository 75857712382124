/* eslint-disable */
import React from 'react';
import { StringParam } from 'use-query-params';

import { TextFieldFF } from 'core/form/finalForm/fields/default/TextFieldFF';

import { filterInput } from '../filterInputsClasses';

 
export const EAIS_ID_NAME = 'eaisId';

 
 
/**
 * ID УАИС БУ
 *
 */
 
export const EaisIdFilter = () => {
  return (
    <TextFieldFF
      className={filterInput}
      label={'ID УАИС БУ'}
      name={EAIS_ID_NAME}
    />
  );
};

 
export const eaisIdParams = {
  [EAIS_ID_NAME]: StringParam,
};
