import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
/* eslint-disable */
import { print } from 'app/api/requests/print';
import { PrintButton } from 'app/pages/components';

/**
 *
 * @param root0
 * @param root0.code
 */
export const PrintPlantationTab = ({ code }) => {
  const { recordId: id, rootId, typeId } = useGetCurrentCard();

  /**
   *
   * @param params
   */
  const printFn = (params) => print(code, params);
  return (
    <PrintButton printFn={printFn} prepareFilters={{ id, rootId, typeId }} />
  );
};
