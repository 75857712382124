import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { DatePickerFF } from 'core/form/finalForm/fields/default/datePickers/DatePickerFF';
import { usePreparation } from 'core/utils/hooks/usePreparation/preparation';
import moment from 'moment';
import React from 'react';

export const INSTALLATION_DATE_NAME = 'installation_date';

/**
 * Дата __Срок эксплуатации__.
 *
 * @returns {JSX.Element}
 */
export const InstallationDate = () => {
  const { editMode } = useEditMode();

  usePreparation(INSTALLATION_DATE_NAME, (value) => {
    let newValue = value;

    if (moment(value).isValid()) {
      newValue = moment(value).format('DD.MM.YYYY');
    }
    return newValue;
  });

  return (
    <DatePickerFF
      name={INSTALLATION_DATE_NAME}
      label={'Дата установки'}
      disabled={!editMode}
    />
  );
};
