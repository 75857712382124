import { shallow } from 'enzyme';
import React from 'react';
import { vi } from 'vitest';

import { PlanarStructureTypeRefIdField } from './PlanarStructureTypeRefId.Field';
import { useSetupPlanarStructureTypeRefIdField } from './useSetup.PlanarStructureTypeRefId.Field';

vi.mock('./useSetup.PlanarStructureTypeRefId.Field');

describe('🐛 spec PlanarStructureTypeRefId.Field', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    useSetupPlanarStructureTypeRefIdField.mockReturnValue({});

    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(<PlanarStructureTypeRefIdField />);

    //❓ Assert
    expect(wrapper.text()).toBe('<PlanarStructureTypeRefIdRF />');
  });

  it('🧪 props', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    useSetupPlanarStructureTypeRefIdField.mockReturnValue({
      filter: 'filter',
      isEdit: 'isEdit',
    });

    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(<PlanarStructureTypeRefIdField />);

    //❓ Assert
    expect(wrapper.props()).toStrictEqual({
      disabled: false,
      filter: 'filter',
    });
  });
});
