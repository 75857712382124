import { OZN, YARD } from 'app/constants/oghTypes';
import { createNumberedList } from 'app/utils/createNumberedList';

/**
 * Площадь механизированной уборки.
 *
 * @param {number} typeId - Тип ОГХ.
 * @returns {*}
 */
export const getHintAutoCleanArea = (typeId) => {
  const cleaningMethodChildren = [
    '"Плоскостные сооружения".',
    '"Дорожно-тропиночные сети"',
  ];

  switch (typeId) {
    case YARD: {
      cleaningMethodChildren.push({
        children: ['Пешеходный мост', 'Пирс', 'Cухой фонтан (ОО)'],
        item: '"Инженерные сооружения"',
      });
      break;
    }
    case OZN: {
      return createNumberedList({
        header: 'Рассчитывается как сумма значений',
        list: [
          {
            children: [
              'Автопарковка',
              'Площадка для пожарной техники',
              'Площадка для отдыха',
              'Площадка иного типа',
              'Техническая площадка',
              'Велопарковка открытого типа',
            ],
            item: '«Уборочная площадь плоскостных сооружений, кв.м»',
          },
          {
            children: [
              'Проезд',
              'Тротуар',
              'Пешеходная дорожка',
              'Беговая дорожка',
              'Велосипедная дорожка',
            ],
            item: '«Уборочная площадь дорожно-тропиночной сети, кв.м»',
          },
          {
            children: ['Сухой фонтан', 'Променад'],
            item: '«Уборочная площадь инженерных сооружений, кв. м»',
          },
        ],
      });
    }
    default: {
      break;
    }
  }

  return createNumberedList({
    header: 'Рассчитывается как сумма значений',
    list: [
      {
        children: cleaningMethodChildren,
        item: '"Площадь, кв.м." ("Способ уборки": "Механизированная уборка")',
      },
    ],
  });
};
