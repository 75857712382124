import { Table } from 'core/uiKit/components/tables/Table';

/* eslint-disable  */
import { columnsProcessApproving } from './columnsProcessApproving';
import { UseGetDataApprovalsTechTable } from './hooks/useGetDataApprovalsTechTable';
import { ProcessApprovingFilter } from './ProcessApprovingTable.Filter';
import { WrapperCard } from './WrapperCard';

/**
 *
 */
export const ProcessApprovingTable = () => {
  const { table, total, isLoading } = UseGetDataApprovalsTechTable();

  return (
    <WrapperCard>
      <ProcessApprovingFilter />
      <Table
        columns={columnsProcessApproving}
        data={table}
        isLoading={isLoading}
        total={total}
      />
    </WrapperCard>
  );
};
