import {
  CircularProgress,
  Icon,
  ListItem,
  ListItemIcon,
  ListItemText,
} from 'core/uiKit/material-ui';
import React from 'react';

/**
 * Компонент ListItemOzn.
 *
 * @param {object} props - Пропсы.
 * @param {string} props.text - Текст.
 * @param {boolean} props.loading - Флаг загрузки.
 * @param {Function} props.onClick - Клик.
 * @returns {JSX.Element}
 */
export const ListItemOzn = ({ text, loading = false, onClick = (_) => _ }) => (
  <ListItem
    className={'ml-4'}
    button={true}
    disabled={loading}
    onClick={onClick}
  >
    <ListItemIcon>
      {loading ? <CircularProgress size={20} /> : <Icon>print</Icon>}
    </ListItemIcon>
    <ListItemText primary={text} />
  </ListItem>
);
