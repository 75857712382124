/* eslint-disable */
import NavItem from 'app/components/common/NavBar/NavItem';
import { pathAdmin } from 'app/pages/admin/path.Admin';
import { pathUsers } from 'app/pages/admin/Users/path.Users';

import { lazy, useEffect } from 'react';
import { useUser } from 'app/pages/providers';

function lazyWithPreload(factory) {
  const Component = lazy(factory);
  Component.preload = factory;
  return Component;
}

const Users = lazyWithPreload(() => import('app/pages/admin/Users'));

 
export const ViewUsersLink = () => {
  const { hasPermission } = useUser();

  const isShow = hasPermission(pathUsers.privilegeView);

  useEffect(() => {
    isShow && Users.preload();
  }, [isShow]);

  return isShow ? (
    <NavItem link={`/${pathAdmin.path}/${pathUsers.path}`}>
      {pathUsers.name}
    </NavItem>
  ) : null;
};
