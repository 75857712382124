// import { CopyToClipboard } from 'core/uiKit/components/CopyToClipboard';
import 'react-toastify/dist/ReactToastify.css';
import './toastStyle.scss';

import { log } from 'core/utils/log';
import { renderA } from 'core/utils/renders/renderA';
import React from 'react';
import { toast as toastRT, ToastContainer } from 'react-toastify';

/**
 * Провайдер для Toast.
 *
 * @returns {unknown}
 */
export const ToastProvider = () => (
  <ToastContainer
    toastClassName={'toasty__toast'}
    style={{ width: '400px' }}
    theme="colored"
    position="top-right"
    autoClose={999666}
    hideProgressBar={true}
    newestOnTop={true}
    closeOnClick={false}
    rtl={false}
    pauseOnVisibilityChange={true}
    draggable={false}
    pauseOnHover={true}
    closeButton
  />
);

// react-toastify добавляет к элементу prop 'closeToast', поэтому происходит
// Warning: React does not recognize the `closeToast` prop on a DOM element

/**
 * Формирование уведомение 📣 в формате JSX.
 *
 * @param {string} text - Текст ошибки.
 * @returns {unknown}
 */
const GetComponentToast = (text) => () => {
  const textBr = text.split('\n') || [];

  return (
    //  класс error-test для тестировщиков
    <div className={'error-test'}>
      <div className={'toasty-text'}>
        {/*<CopyToClipboard textForCopy={text}>*/}
        <div className={'toasty__text-to-clipboard'}>
          {textBr.map((item, idx) => {
            const result = [];

            // Получаем только ссылки
            const links = item.match(/<a\b[^>]*>(.*?)<\/a>/g);

            // Получаем текст без ссылок на место ссылки ставим $link
            const title = item
              .replace(/<a\b[^>]*>(.*?)<\/a>/gi, '$link')
              .replace(/,/g, '');

            // Проверка на наличие ссылок
            if (links) {
              // Генерим ссылки React
              const linksReact = links.map((item, idx) => {
                return (
                  <>
                    <span className={'m-0'} key={item + idx}>
                      {renderA(item)}&nbsp;
                    </span>{' '}
                  </>
                );
              });

              result.push([
                // Разбиваем строку, используя $link
                title.split('$link').map((item, idx) => {
                  // Если ссылка есть добавляем её между строкой
                  if (linksReact[idx]) {
                    return (
                      <>
                        {item}
                        {linksReact[idx]}
                      </>
                    );
                  }
                  return item;
                }),
              ]);
            } else {
              result.push(item);
            }

            // Рендер
            return result.map((text) => {
              return (
                <p className={'m-0'} key={idx}>
                  {text}
                </p>
              );
            });
          })}
        </div>
        {/*</CopyToClipboard>*/}
      </div>
    </div>
  );
};

/**
 * Уведомление об успешном выполнении.
 *
 * @param {string} text - Текст уведомления.
 * @param {{autoClose: number, closeOnClick: boolean, hideProgressBar: boolean}} options - Options.
 * @returns {string}
 */
const success = (text, options = {}) =>
  toastRT.success(GetComponentToast(text), options);

/**
 * Уведомление об успешном выполнении с автозакрытием через 4200млс.
 *
 * @param {string} text - Текст уведомления.
 * @param {{autoClose: number, closeOnClick: boolean, hideProgressBar: boolean}} options - Options.
 * @returns {string}
 */
const successDying = (
  text,
  options = {
    autoClose: 4200,
    closeOnClick: true,
    hideProgressBar: false,
  },
) => toastRT.success(GetComponentToast(text), options);

/**
 * Уведомление об успешном выполнении с автозакрытием через 42000млс.
 *
 * @param {string} text - Текст уведомления.
 * @param {{autoClose: number, closeOnClick: boolean, hideProgressBar: boolean}} options - Options.
 * @returns {string}
 */
const successDyingLong = (
  text,
  options = {
    autoClose: 42000,
    closeOnClick: true,
    hideProgressBar: false,
  },
) => toastRT.success(GetComponentToast(text), options);

/**
 * Уведомление об ошибке.
 *
 * @param {string} text - Текст уведомления.
 * @param {{autoClose: number, closeOnClick: boolean, hideProgressBar: boolean}} options - Options.
 * @returns {string}
 */
const error = (text, options = {}) => {
  log.banana(text);
  return toastRT.error(GetComponentToast(text), options);
};

/**
 * Уведомление об ошибке с автозакрытием через 4200млс.
 *
 * @param {string} text - Текст уведомления.
 * @param {{autoClose: number, closeOnClick: boolean, hideProgressBar: boolean}} options - Options.
 * @returns {string}
 */
const errorDying = (
  text,
  options = {
    autoClose: 4200,
    closeOnClick: true,
    hideProgressBar: false,
  },
) => {
  log.banana(text);
  return toastRT.error(GetComponentToast(text), options);
};

/**
 * Уведомление об ошибке с автозакрытием через 42000млс.
 *
 * @param {string} text - Текст уведомления.
 * @param {{autoClose: number, closeOnClick: boolean, hideProgressBar: boolean}} options - Options.
 * @returns {string}
 */
const errorDyingLong = (
  text,
  options = {
    autoClose: 42000,
    closeOnClick: true,
    hideProgressBar: false,
  },
) => {
  log.banana(text);
  return toastRT.error(GetComponentToast(text), options);
};

export const toast = {
  error,
  errorDying,
  errorDyingLong,
  success,
  successDying,
  successDyingLong,
};
