/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { SPUTNIK, EGKO } from 'app/constants/tileLayers';

import './TileLayerRadioButton.scss';

const propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  mapId: PropTypes.string.isRequired,
  className: PropTypes.string,
};

function TileLayerRadioButton({ onChange, value, mapId, className }) {
  return (
    <div className={cn('tile-layer-wrap', className)}>
      <div className="tile-layer-input">
        <input
          checked={value === EGKO}
          id={`${EGKO}-${mapId}`}
          name={`tileLayer-${mapId}`}
          type="radio"
          value={EGKO}
          onChange={onChange}
        />
        <label className="tile-layer-label" htmlFor={`${EGKO}-${mapId}`}>
          ЕГКО
        </label>
      </div>
      <div className="tile-layer-input">
        <input
          checked={value === SPUTNIK}
          id={`${SPUTNIK}-${mapId}`}
          name={`tileLayer-${mapId}`}
          type="radio"
          value={SPUTNIK}
          onChange={onChange}
        />
        <label className="tile-layer-label" htmlFor={`${SPUTNIK}-${mapId}`}>
          Спутник
        </label>
      </div>
    </div>
  );
}

TileLayerRadioButton.propTypes = propTypes;

 
export default TileLayerRadioButton;
