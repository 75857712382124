 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Уборочная площадь, кв.м.__.
 *
 * @type {{
 * accessor: 'total_clean_area',
 * Header: 'Уборочная площадь, кв.м.',
 * sortable: true,
 * }}
 * @augments Cell
 */
export const total_clean_area = {
  accessor: 'total_clean_area',
  Header: 'Уборочная площадь, кв.м.',
  sortable: true,
};
