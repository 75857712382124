 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Площадь по типу покрытия на путях, кв.м__.
 *
 * @type {{ accessor: 'coating_tracks_area',  Header: 'Площадь по типу покрытия на путях, кв.м' }}
 * @augments Cell
 */
export const coating_tracks_area = {
  accessor: 'coating_tracks_area',
  Header: 'Площадь по типу покрытия на путях, кв.м',
  sortable: true,
};
