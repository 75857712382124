/* eslint-disable  */
import { useParamsPagination } from 'core/uiKit/components/Pagination';
import { StringParam, useQueryParams } from 'use-query-params';

/**
 *
 */
export const useParamsReactTable = () => {
  const { queryPagination } = useParamsPagination();
  const [query, setQuery] = useQueryParams({
    sort: StringParam,
  });
  return {
    query,
    queryReactTable: { ...queryPagination, ...query },
    setQuery,
  };
};
