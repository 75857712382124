import { DndFileUploadFF } from 'core/form/finalForm/fields/prepared/fileUpload/DndFileUploadFF';
import { CommentFF } from 'core/form/finalForm/fields/prepared/textField/CommentFF';
import { DialogContentTextMUI } from 'core/uiKit/material-ui';

/**
 * Компонент BodyDialogReject.
 *
 * @returns {JSX.Element}
 */
export const BodyDialogReject = () => (
  <>
    <DialogContentTextMUI>
      Уведомление будет отправлено пользователю, создавшему объект
    </DialogContentTextMUI>
    <CommentFF required />
    <DndFileUploadFF
      text={'Переместите файлы или нажмите для выбора'}
      name={'files'}
    />
  </>
);
