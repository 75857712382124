 
export /* eslint-disable */
const columnsEquipmentReport = [
  {
    accessor: 'root_id',
    Header: 'Корневой ID',
    sortable: true,
  },
  {
    accessor: 'inventory_number_name',
    Header: 'Инвентарный номер',
    sortable: true,
  },
  {
    accessor: 'car_equipment_type_name',
    Header: 'Тип навесного оборудования',
    sortable: true,
  },
  {
    accessor: 'car_equipment_ownership_name',
    Header: 'Тип принадлежности',
    sortable: true,
  },
  {
    accessor: 'car_equipment_owner_name',
    Header: 'Владелец НО',
    sortable: true,
  },
  {
    accessor: 'sensor_code',
    Header: 'Код датчика',
    sortable: true,
  },
  {
    accessor: 'sensor_state_name',
    Header: 'Состояние',
    sortable: true,
  },
  {
    accessor: 'gov_number_name',
    Header: 'Рег. номер техники',
    sortable: true,
  },
  {
    accessor: 'not_need_drno',
    Header: 'Не подлежит оснащению ДРНО',
    sortable: true,
  },
  {
    accessor: 'not_need_dut',
    Header: 'Не подлежит оснащению ДУТ',
    sortable: true,
  },
  {
    accessor: 'end_date',
    Header: 'Срок действия НО',
    sortable: true,
  },
];
