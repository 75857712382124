import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { parseDate } from 'app/components/card/common/parse';
import { useFilterQueryParamsVersionDefault } from 'app/pages/cardsOgh/components/tables/Versions/Filters/useFilterQueryParamsVersionDefault';
import { useMemo } from 'react';

/* eslint-disable */
/**
 * Хук useInitialValues.
 *
 * @returns {object} - Возвращает параметры полей по умолчанию.
 * @example
 * useInitialValues()
 */
export const useInitialValues = () => {
  const { startDate, endDate, attribute } = useGetCurrentCard();
  const { filters } = useFilterQueryParamsVersionDefault();

  return useMemo(() => {
    return {
      startDate: parseDate(startDate),
      endDate: parseDate(endDate),
      redBookPlantId: attribute?.red_book_plant_id?.red_book_plant,
      noCalc: Boolean(attribute?.no_calc),
      sectionNum: attribute?.section_num,
      greenNum: attribute?.green_num,
      ...filters,
    };
    
  }, [startDate, endDate, attribute]);
};
