/* eslint-disable */
import React from 'react';
import { NumberParam } from 'use-query-params';

import {
  CarEquipmentOwnershipIdFF,
  CAR_EQUIPMENT_OWNERSHIP_ID_NAME,
} from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
 
/**
 * Тип принадлежности
 * car_equipment_ownership_id
 */
 
export const CarEquipmentOwnershipIdFilter = (props) => (
  <CarEquipmentOwnershipIdFF {...props} className={filterInput} />
);

 
export { CAR_EQUIPMENT_OWNERSHIP_ID_NAME };

 
export const carEquipmentOwnershipIdParams = {
  [CAR_EQUIPMENT_OWNERSHIP_ID_NAME]: NumberParam,
};
