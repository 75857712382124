import { Table } from 'core/uiKit/components/tables/Table';
import React from 'react';

/**
 * Колонки таблицы.
 */

const columns = [
  {
    Header: 'Вид ПГР',
    accessor: 'reagent_kind_name',
  },
  {
    Header: 'Наименование ПГР',
    accessor: 'reagent_name',
  },
  {
    Header: 'Максимальный объем, т.',
    accessor: 'quantity',
  },
  {
    Header: 'Текущее заполнение, т.',
    accessor: 'balance',
  },
];

/**
 * Преобразование массива записей таблицы для отображения.
 *
 * @param {*} data - Массив записей таблицы.
 * @param {*} reagents - Массив ПГР.
 * @param {*} reagentKinds - Массив Видов ПГР.
 * @returns {*}
 */
const makeValues = (data = [], reagents = [], reagentKinds = []) =>
  data.map((item) => {
    const reagent = reagents.find((value) => value.id === item.reagent_id);
    const reagentKind = reagentKinds.find(
      (value) => value.id === item.reagent_kind_id,
    );

    return {
      ...item,
      reagent_kind_name: (reagentKind && reagentKind.name) || '',
      reagent_name: (reagent && reagent.name) || '',
    };
  });

/**
 * Таблица с данными.
 *
 * @param {*} root0 - Параметры компонента.
 * @param {*} root0.checkedRows - A.
 * @param {*} root0.isCheckedRow - A.
 * @param {*} root0.onSelectRow - A.
 * @param {*} root0.data - A.
 * @param {*} root0.reagents - A.
 * @param {*} root0.reagentKinds - A.
 * @param {*} root0.disabled - A.
 * @returns {*}
 */
const Tabled = ({
  checkedRows,
  isCheckedRow,
  onSelectRow,
  data = [],
  reagents,
  reagentKinds,
  disabled = false,
}) => (
  <Table
    checkedRows={disabled ? undefined : checkedRows}
    columns={columns}
    data={makeValues(data, reagents, reagentKinds)}
    has_footer={false}
    isCheckedRow={isCheckedRow}
    sortable_columns={[]}
    stickHeader={true}
    onSelectRow={onSelectRow}
  />
);

export default Tabled;
