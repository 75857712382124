/* eslint-disable */
import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import {
  ConfirmPassword,
  CONFIRM_PASSWORD_NAME,
} from 'core/uiKit/preparedInputs/textField/ConfirmPassword';

/* eslint-disable */
export const ConfirmPasswordFF = ({ name, ...otherProps }) => {
  return (
    <Field name={CONFIRM_PASSWORD_NAME}>
      {({ input: { name, value, onChange }, meta }) => {
        return (
          <ConfirmPassword
            {...otherProps}
            {...{ name, value, onChange }}
            {...{ meta }}
          />
        );
      }}
    </Field>
  );
};

 
export { CONFIRM_PASSWORD_NAME };

ConfirmPasswordFF.propTypes = {
   
   
/**
   * быбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
/**
   * placeholder
   */
  placeholder: PropTypes.string,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
};
ConfirmPasswordFF.defaultProps = {
  disabled: false,
};
