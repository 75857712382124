import NoMatch from 'app/components/NoMatch';
import { pathBnsoReport } from 'app/pages/report/BnsoReport/path.BnsoReport';
import { pathChangeLogRegistry } from 'app/pages/report/ChangeLogRegistry/path.ChangeLogRegistry';
import { EquipmentReport } from 'app/pages/report/EquipmentReport';
import { RegistrySkeleton } from 'core/uiKit/components';
/* eslint-disable */
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { pathCarReport } from './CarReport/path.CarReport';
import { pathSensorsReport } from './SensorsReport/path.SensorsReport';

function lazyWithPreload(factory) {
  const Component = lazy(factory);
  Component.preload = factory;
  return Component;
}

const CarReport = lazyWithPreload(() =>
  import('./CarReport'),
);
const SensorsReport = lazyWithPreload(() =>
  import('./SensorsReport'),
);
const BnsoReport = lazyWithPreload(() =>
  import('./BnsoReport'),
);
const ChangeLogRegistry = lazyWithPreload(() =>
  import('./ChangeLogRegistry'),
);

 
export const ReportRoute = () => {
  useEffect(() => {
    CarReport.preload();
    SensorsReport.preload();
    ChangeLogRegistry.preload();
    BnsoReport.preload();
  }, []);
  return (
    <Suspense fallback={<RegistrySkeleton />}>
      <Routes>
        <Route
          element={<CarReport />}
          path={`${pathCarReport.path}`}
        />
        <Route
          element={<BnsoReport />}
          path={`${pathBnsoReport.path}`}
        />
        <Route
          element={<EquipmentReport />}
          path={`equipment`}
        />
        <Route
          element={<SensorsReport />}
          path={`${pathSensorsReport.path}`}
        />
        <Route
          element={<ChangeLogRegistry />}
          path={`${pathChangeLogRegistry.path}`}
        />
        <Route element={<NoMatch />} path={'*'} />
      </Routes>
    </Suspense>
  );
};
