import { CheckboxCell } from 'core/uiKit/components/cells/Checkbox.Cell';
import { LinkShortIdCell } from 'core/uiKit/components/cells/LinkShortId.Cell';


/* eslint-disable */
export default [
  {
    id: 'root_id',
    name: 'ID',
    Cell: LinkShortIdCell,
    width: 80,
  },
  {
    id: 'object_type_name',
    name: 'Тип',
  },
  {
    id: 'in_yard',
    name: 'Внутридворовое',
    Cell: CheckboxCell,
  },
  {
    id: 'address_name',
    name: 'Адрес',
    width: 250,
  },
  {
    id: 'object_status_name',
    name: 'Статус',
    width: 110,
  },
  {
    id: 'start_date',
    name: 'Дата начала',
    width: 90,
  },
  {
    id: 'end_date',
    name: 'Дата окончания',
    width: 120,
  },
];
