import { shallow } from 'enzyme';
import React from 'react';

import { DocumentsTabSpa } from './DocumentsTab.Spa';

describe('DocumentsTabSpa', () => {
  it('renders all document fields', () => {
    // Arrange (всякие моки)

    // Act
    const wrapper = shallow(<DocumentsTabSpa />);

    // Assert
    expect(wrapper.find('SituationPlanRF')).toHaveLength(1);
    expect(wrapper.find('InventoryPlanRF')).toHaveLength(1);
    expect(wrapper.find('SymbolsInventoryPlanRF')).toHaveLength(1);
    expect(wrapper.find('PlanFlowerRF')).toHaveLength(1);
    expect(wrapper.find('SketchFlowerRF')).toHaveLength(1);
    expect(wrapper.find('MarkingDrawingRF')).toHaveLength(1);
    expect(wrapper.find('DataCoordOrganizationRF')).toHaveLength(1);
    expect(wrapper.find('OtherDocumentsRF')).toHaveLength(1);
    expect(wrapper.find('FellingTicketRF')).toHaveLength(1);
  });
});
