import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';

/**
 * Select RF.
 *
 * @param {object} props - Пропсы.
 * @returns {React.ReactElement}
 */
const TypeOtherTerritory = (props) => {
  return (
    <SelectRF
      name={'other_improvement_object_type_id'}
      label={'Тип'}
      disabled={true}
      options={props.otherImprovementObjectType}
    />
  );
};

export default TypeOtherTerritory;
