/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import { AutocompleteAsync } from 'core/uiKit/inputs/selects/AutocompleteAsync';
import { fetchGroupId } from './api';

 
export const GROUP_ID_NAME = 'groupId';

 
 
/**
 * ## ID составного объекта
 *
 * * `type = AutocompleteAsync`
 * * `name = groupId`
 *
 * ```js
 * import { GroupId, GROUP_ID_NAME } from 'core/uiKit/preparedInputs';
 * ```
 *
 *
 */
 
export const GroupId = (props) => {
  return (
    <AutocompleteAsync
      label={'ID составного объекта'}
      {...props}
      fetchFunction={fetchGroupId}
      name={GROUP_ID_NAME}
    />
  );
};

GroupId.propTypes = {
   
   
  /**
   * очищаем инпут с сохранением значения
   */
  clear: PropTypes.bool,
   
   
  /**
   * быбор темной темы
   */
  dark: PropTypes.bool,
   
   
  /**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
  /**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
  /**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
  /**
   * функция вызова когда выберется итем
   */
  onChange: PropTypes.func,
   
   
  /**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
   
   
  /**
   * значение
   */
  value: PropTypes.any,
};
