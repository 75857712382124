import { OZN_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/OznTypeID';

 
/**
 * Добавление initialState к полю OznTypeField.
 *
 * @param {object} result - Параметры в редьюсере.
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {*} - Возвращает объект и записывает в initialValues значение поля OznTypeField.
 * @example -
 *  mapStateToPropsReducers: [
 *     oznTypeFieldReducer,
 *   ],
 */
export function oznTypeFieldReducer(result, state, props) {
  const { card } = props;

  return {
    ...result,
    initialValues: {
      ...result.initialValues,
      [OZN_TYPE_ID_NAME]: card?.attribute?.[OZN_TYPE_ID_NAME]?.ozn_type || [],
    },
  };
}
