import { PrintButtonOgh } from 'app/pages/components';
import React from 'react';

import { useFilterQueryParamsPlanarStructure } from './FilterPlanarStructure';

/* eslint-disable */
/**
 * Кнопка печати реестра "Плоскостные сооружения".
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - Id типа огх.
 * @param {number} props.parentTypeId - Id типа родителя огх.
 * @returns {JSX.Element} - JSX.
 * @example -----
 */
export const PrintButtonPlanarStructure = ({
  typeId,
  parentTypeId,
}) => {
  const { prepareFilters } =
    useFilterQueryParamsPlanarStructure();

  return (
    <PrintButtonOgh
      {...{
        typeId,
        prepareFilters: { ...prepareFilters, parentTypeId },
      }}
    />
  );
};
