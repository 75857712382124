import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';

/**
 * ## CreateTypeCell.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const CreateTypeCell = ({ cell: { value } = {} }) => {
  const dict = 'createType';
  const { data = [] } = useGetDictionary(dict);

  const mapData = data.reduce((acc, cur) => {
    acc[cur.code] = cur.name;
    return acc;
  }, {});

  return <span>{mapData[value] || '-'}</span>;
};
