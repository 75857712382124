import { downloadFile } from 'app/api/requests/downloadFile';
import { getFilename } from 'app/utils/getFilename';

/**
 * Получение файлов из массива файлов.
 *
 * @param {Array<string>} fileList - Массив идентификаторов файлов.
 * @returns {Promise}
 */
export const getFilesId = async ({ fileList }) => {
  return await Promise.all(
    fileList.map(async (fileId) => {
      const res = await downloadFile(fileId);
      const name = getFilename(res);
      const blob = await res.blob();

      const file = new File([blob], name);

      return {
        file,
        name,
        storageId: fileId,
      };
    }),
  );
};
