import './TabsCard.scss';

import { Tabs } from 'core/uiKit/components/Tabs';
import React, { useEffect, useState } from 'react';

import { TabsCardPanel, TabsCardPanelPreload } from './TabsCardPanel';

/**
 * Таб карточки.
 *
 * @param {object} props - Пропсы.
 * @param {Array<*>} props.tabs - Массив табов.
 * @param {string} props.activeTab - Значение активного таба.
 * @param {JSX.ElementChildrenAttribute} props.children - Реакт компонент.
 * @param {boolean} props.preloading - Загружать данные табов пока они закрыты или нет.
 * @returns {JSX.Element} - TabsCard.
 */
export const TabsCard = ({ tabs, activeTab, children, preloading = true }) => {
  const [value, setValue] = useState(activeTab ?? tabs[0].value);

  const filteredTabs = tabs.filter(({ show = true }) => show);

  useEffect(() => {
    const mapTabValues = filteredTabs.map(({ value }) => value);
    if (!mapTabValues.includes(value)) {
      setValue(filteredTabs[0].value);
    }
  }, [setValue, value, filteredTabs]);

  /**
   * Изменение активного таба.
   *
   * @param {string} newValue - Новое значение.
   * @returns {void} - Nothing.
   * @example -------
   */
  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className={'tabs-card-header__container'}>
        <Tabs onChange={handleChange} value={value} tabs={filteredTabs} />
      </div>

      <div>
        {Array.isArray(children) ? (
          children.map((child) => {
            const { tabValue } = child.props;
            return preloading ? (
              <TabsCardPanelPreload
                key={`body-${tabValue}`}
                show={value === tabValue}
              >
                {child}
              </TabsCardPanelPreload>
            ) : (
              <TabsCardPanel key={`body-${tabValue}`} show={value === tabValue}>
                {child}
              </TabsCardPanel>
            );
          })
        ) : (
          <TabsCardPanel show={value === children?.props.tabValue}>
            {children}
          </TabsCardPanel>
        )}
      </div>
    </>
  );
};
