import * as env from 'config/env';
import { log } from 'core/utils/log';
import PropTypes from 'prop-types';
/* eslint-disable */
import React from 'react';
const propTypes = {
  dbVersion: PropTypes.string,
  dbExecutionDate: PropTypes.string,
  frontendVersion: PropTypes.string,
  frontendBuildDate: PropTypes.string,
  frontendExecutionDate: PropTypes.string,
  backendVersion: PropTypes.string,
  backendBuildDate: PropTypes.string,
};

const defaultProps = {
  dbVersion: '...',
  dbExecutionDate: '...',
  frontendVersion: '...',
  frontendBuildDate: '...',
  frontendExecutionDate: '...',
  backendVersion: '...',
  backendBuildDate: '...',
};

function SystemVersion({
  dbVersion = '',
  dbExecutionDate = '',
  backendVersion = '',
  backendBuildDate = '',
}) {
  const frontendVersion = env.FRONTEND_VERSION;
  const frontendVersionWithBuild = env.FRONTEND_VERSION_WITH_BUILD;
  const frontendBuildDate = env.FRONTEND_BUILD_DATE;
  const frontendEnvironment = env.FRONTEND_ENVIRONMENT;

  const sysInfo = {
    UI: {
      версия: frontendVersion,
      'версия сборки': frontendVersionWithBuild?.split('-')[1] || 'нет',
      'дата сборки': frontendBuildDate,
      контур: frontendEnvironment,
    },
    API: {
      версия: backendVersion?.split('-')[0] || 'нет',
      'версия сборки': backendVersion?.split('-')[1] || 'нет',
      'дата сборки': backendBuildDate,
      контур: backendVersion?.split('-')[2] || 'нет',
    },
    BD: {
      версия: dbVersion?.split('-')[0] || 'нет',
      'версия сборки': dbVersion?.split('-')[1] || 'нет',
      'дата сборки': dbExecutionDate,
      контур: dbVersion?.split('-')[2] || 'нет',
    },
  };

  window.systemInfo = () => {
    log.table(sysInfo);
  };

  return (
    <div>
      <div className="helper-caption">
        Версия UI:&#xa0;
        <span>
          {frontendVersion}
          {/*от {frontendBuildDate}*/}
        </span>
      </div>
      <div className="helper-caption">
        Версия API:&#xa0;
        <span>
          {backendVersion?.split('-')[0] || 'нету'}
          {/*от {backendBuildDate}*/}
        </span>
      </div>
      <div className="helper-caption">
        Версия DB:&#xa0;
        <span>
          {dbVersion}
          {/*от {dbExecutionDate}*/}
        </span>
      </div>
    </div>
  );
}

SystemVersion.propTypes = propTypes;
SystemVersion.defaultProps = defaultProps;

 
export default SystemVersion;
