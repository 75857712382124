import { CUSTOMER_YARD_NAME, CustomerYardFF } from 'core/form/finalForm/fields';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';
import { ObjectParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

/**
 * Заказчик  yard   customer
 * place=yard.
 *
 * @param {object} params - Параметры компонента.
 * @returns {JSX.Element}
 */
export const CustomerYardFilter = (params) => {
  return <CustomerYardFF {...params} className={filterInput} />;
};

export { CUSTOMER_YARD_NAME };

/**
 * CustomerYardParams.
 *
 * @returns {object}
 */
export const customerYardParams = () => {
  addPreparation(CUSTOMER_YARD_NAME, (value) => value.id);
  return {
    [CUSTOMER_YARD_NAME]: ObjectParam,
  };
};
