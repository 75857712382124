import { getRegistryOgh } from 'app/api/requests/getRegistryOgh';

/**
 * Получение данных для таблицы реестров ОГХ.
 *
 * @param {object} queryParams - Query params.
 * @returns {Array}
 */
export const fetchOghTable = (queryParams = {}) => {
  // HACK: проверяем что нет typeId огх
  if (!queryParams?.typeId) {
    return [];
  }
  return getRegistryOgh(null, queryParams);
};
