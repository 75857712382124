/* eslint-disable */
import React from 'react';

import {
  MATCHING_STATUSES_NAME,
  MatchingStatusesFF,
} from 'core/form/finalForm/fields';
import { filterInput } from '../filterInputsClasses';
import { NumberParam, withDefault } from 'use-query-params';

 
 
/**
 * name = stateId
 * dict = matchingStatus
 */
 
export const MatchingStatusesFilter = () => (
  <MatchingStatusesFF className={filterInput} />
);

 
export { MATCHING_STATUSES_NAME };

 
export const matchingStatusParams = {
  [MATCHING_STATUSES_NAME]: withDefault(NumberParam, 1),
};
