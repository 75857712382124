import { get } from 'app/api/crud/get';
/* eslint-disable  */
import { post } from 'app/api/crud/post';
import { useMapContext } from 'app/components/map/useMapContext';
import { FOREGROUND } from 'app/constants/layers';
import {
  useMutationAdaptor,
  useQueryAdaptor,
} from 'core/utils/hooks/useReactQuery';
import { log } from 'core/utils/log';

/**
 *
 * @param id
 */
export const geometryLoad = (id) => post('/ogh/geometry/load', id);

/**
 *
 * @param params
 */
export const geometryFull = (params) => get('/ogh/geometry/full', params);

/**
 *
 * @param id
 */
export const useGetGeometryLoad = (id) =>
  useQueryAdaptor(['geometryLoad', id], geometryLoad);

/**
 *
 */
export const useGetGeometryLoadMutation = () => {
  const { drawChildrenGeometry } = useMapContext();

  const [getGeometryLoad, data] = useMutationAdaptor(geometryLoad, {
    /**
     *
     */
    onError: () => {
      log.info('Данные не пришли useGetGeometryLoadMutation');
    },

    /**
     *
     * @param data
     */
    onSuccess: (data) => {
      drawChildrenGeometry(data);
    },
  });

  return { getGeometryLoad, getGeometryLoadData: data };
};

/**
 *
 */
export const useGetGeometryFullMutation = () => {
  const { drawCurrentObjectsGeometry, clearLayer } = useMapContext();

  const [getGeometryFull, data] = useMutationAdaptor(geometryFull, {
    /**
     *
     */
    onError: () => {
      log.info('Данные не пришли useGetGeometryFullMutation');
    },

    /**
     *
     * @param data
     */
    onSuccess: (data) => {
      clearLayer(FOREGROUND);
      drawCurrentObjectsGeometry(data);
    },
  });

  return { getGeometryFull, getGeometryFullData: data };
};
