import { KeyboardDatePickerFF } from 'core/form/finalForm/fields/default/datePickers/KeyboardDatePickerFF/KeyboardDatePickerFF';
import { shallow } from 'enzyme';

import WorkStartDate, { WORK_START_DATE } from './WorkStartDate';

jest.mock('react-final-form');

 
describe('title WorkStartDate', () => {
  it('constant WORK_END_DATE should be workenddate', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act

    // Assert
    expect(WORK_START_DATE).toBe('workstartdate');
  });
  it('workStartDate props', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const wrapper = shallow(<WorkStartDate />);

    // Assert
    expect(
      wrapper.contains(
        <KeyboardDatePickerFF
          {...wrapper.props()}
          label="Дата начала работ"
          name={WORK_START_DATE}
        />,
      ),
    ).toBeTruthy();
  });
});
