/* eslint-disable */
import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import {
  GovNumber,
  GOV_NUMBER_NAME,
} from 'core/uiKit/preparedInputs/selects/autocompleteAsync/GovNumber';

 
 
/**
 * ## Рег. номер
 * ### React Final Form Field
 *
 * ###### type = `AutocompleteAsync`
 * ###### name = `govNumber`
 *
 */
/* eslint-disable */
export const GovNumberFF = ({ name, ...otherProps }) => {
  return (
    <Field name={GOV_NUMBER_NAME}>
      {(props) => {
        return <GovNumber {...props} {...props.input} {...otherProps} />;
      }}
    </Field>
  );
};

 
export { GOV_NUMBER_NAME };

GovNumberFF.propTypes = {
   
   
/**
   * очищаем инпут с сохранением значения
   */
  clear: PropTypes.bool,
   
   
/**
   * быбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
/**
   * placeholder
   */
  placeholder: PropTypes.string,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
};
GovNumberFF.defaultProps = {
  disabled: false,
};
