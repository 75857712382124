import { FormCardFF } from 'app/pages/cardsOgh/components/FormCard.FF';
import { LeftPanelEngineerBuilding } from 'app/pages/cardsOgh/EngineerBuilding/LeftPanel.EngineerBuilding';
import { validateEngineerBuilding } from 'app/pages/cardsOgh/EngineerBuilding/validate.EngineerBuilding';
import React from 'react';

import { useSetupEngineerBuilding } from './useSetup.EngineerBuilding';

/**
 * Инженерные сооружения.
 *
 * @returns {JSX.Element}
 */
export const EngineerBuilding = () => {
  const { initialValues, preparingDataForAttributes } =
    useSetupEngineerBuilding();

  return (
    <FormCardFF
      validate={validateEngineerBuilding}
      initialValues={initialValues}
      preparingDataForAttributes={preparingDataForAttributes}
    >
      <LeftPanelEngineerBuilding />
    </FormCardFF>
  );
};
