import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { getNameFile } from 'app/pages/cardsOgh/components/tables/Documents/helpers/getNameFile';
import React from 'react';
import { Field } from 'redux-form';

import { DocumentWithReduxForm } from '../DocumentWithReduxForm';

const ID = 8;
const NAME = getNameFile(ID);
const TITLE = 'Условные обозначения к инвентаризационному плану';

/**
 * Таблица документов __Условные обозначения к инвентаризационному плану__.
 *
 * @returns {JSX.Element} - JSX.
 * @example
 * <SymbolsInventoryPlanRF />
 */
export const SymbolsInventoryPlanRF = () => {
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();

  return (
    <Field
      title={TITLE}
      id={ID}
      name={NAME}
      component={DocumentWithReduxForm}
      disabled={!isEditCurrentObjectIsExternalSystem}
    />
  );
};
