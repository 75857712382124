import { AutocompleteMultiAsync } from 'core/uiKit/inputs/selects/AutocompleteMultiAsync';
import React from 'react';

import { fetchCarCustomers } from './api';

export const CAR_CUSTOMERS_LIST_ID_NAME = 'carCustomerListId';

/**
 * ## Заказчики техники.
 *
 * @param {*} props - The props.
 * @param {*} props.value - The props.
 * @param {*} props.otherProps - The props.
 * @returns {JSX.Element}
 */
export const CarCustomerListId = ({ value, ...otherProps }) => {
  return (
    <AutocompleteMultiAsync
      label={'Заказчики техники'}
      {...otherProps}
      fetchFunction={fetchCarCustomers}
      name={CAR_CUSTOMERS_LIST_ID_NAME}
      value={value || []}
    />
  );
};
