import { useEditorCardSelector } from 'app/selectors/useSelectors/useEditorCard.Selector';
import { shallow } from 'enzyme';
import React from 'react';
import { Mock, vi } from 'vitest';

import { OdhDocsTab } from './Odh.Docs.Tab';
vi.mock('app/selectors/useSelectors/useEditorCard.Selector');

describe('spec OdhDocsTab', function () {
  it('нет нихто', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    (useEditorCardSelector as Mock).mockReturnValue({});
    // Act
    const wrapper = shallow(<OdhDocsTab />);
    // Assert
    expect(wrapper.text()).toBe(
      '<ActOfCompletedWork /><OtherDocumentsRF /><TopographicPlanRF /><ObjectDiagramRF /><SymbolsForTheLayoutPlanRF /><DocumentsConfirmingTheChangesRF />',
    );
  });

  it('is_orphan_object', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    (useEditorCardSelector as Mock).mockReturnValue({ is_orphan_object: true });
    // Act
    const wrapper = shallow(<OdhDocsTab />);

    // Assert
    expect(wrapper.text()).toBe(
      '<ActOfCompletedWork /><OtherDocumentsRF /><ObjectDiagramRF /><DocumentsConfirmingTheChangesRF />',
    );
  });
});
