import './Prelouder.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { PreLouder } from './Prelouder';

/**
 * Компонент обертка кнопки панели.
 *
 * @param {object} props - Пропсы.
 * @param {boolean} props.active - Флаг активной иконки.
 * @param {*} props.content - Контент.
 * @param {Function} props.onClick - Клик по кнопке.
 * @param {boolean} props.disabled - Флаг disabled.
 * @param {boolean} props.isLoading - Загрузка.
 * @returns {JSX.Element}
 */
const ToggleButton = ({ active, content, onClick, disabled, isLoading }) => {
  const current = active ? content.on : content.off;
  return (
    <Button
      isLoading={isLoading}
      content={current}
      disabled={disabled}
      onClick={onClick}
    />
  );
};

ToggleButton.propTypes = {
  active: PropTypes.bool.isRequired,
  content: PropTypes.shape({
    off: PropTypes.object.isRequired,
    on: PropTypes.object.isRequired,
  }),
  onClick: PropTypes.func.isRequired,
};

/**
 * Компонент кнопки панели с прелоудером.
 *
 * @param {object} props - Пропсы.
 * @param {*} props.content - Контент.
 * @param {Function} props.onClick - Клик по кнопке.
 * @param {boolean} props.disabled - Флаг disabled.
 * @param {boolean} props.isLoading - Загрузка.
 * @returns {JSX.Element}
 */
const Button = ({ content, onClick, disabled, isLoading }) => {
  const { title, className } = content;

  return (
    <button
      className={'button-toggle'}
      type="button"
      disabled={disabled}
      title={title}
      onClick={onClick}
    >
      <PreLouder isLoading={isLoading} />
      <i className={className} />
    </button>
  );
};

Button.propTypes = {
  content: PropTypes.shape({
    className: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ToggleButton;
