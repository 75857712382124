import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const MATCHING_STATUSES_NAME = 'stateId';

/**
 * Состояние.
 *
 * @param {*} props - Properties.
 * @returns {JSX.Element}
 */
export const MatchingStatuses = (props) => {
  const dict = 'matchingStatus';

  const { data: options = [] } = useGetDictionary(dict);

  return (
    <Select
      {...props}
      options={options}
      withAll={false}
      label={'Состояние'}
      name={MATCHING_STATUSES_NAME}
    />
  );
};
