import { IdFilter } from 'core/form/filterForm/components/text/ID.Filter';
import { SectionNumFilter } from 'core/form/filterForm/components/text/SectionNumFilter';
import { LawnTypeFilter } from 'core/form/filterForm/index';
import React from 'react';

/**
 * Фильтр __Места накопления отходов__.
 *
 * @returns {JSX.Element}
 */
export const LawnFilter = () => {
  return (
    <>
      <IdFilter />
      <SectionNumFilter />
      <LawnTypeFilter />
    </>
  );
};
