import { ODH, OZN, YARD } from 'app/constants/oghTypes';
/* eslint-disable  */
import {
  useGetCardAttribute,
  useGetCardSelector,
} from 'app/selectors/useSelectors';
import { StageResolution } from 'core/uiKit/preparedInputs';

/**
 *
 */
export const StageResolutionComponent = () => {
  const { boundary } = useGetCardAttribute();
  const { typeId } = useGetCardSelector();
  return (
    [YARD, OZN, ODH].includes(typeId) && (
      <StageResolution value={boundary || ''} disabled={true} />
    )
  );
};
