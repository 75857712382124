import {
  LINESTRING,
  MULTI_LINESTRING,
  MULTI_POINT,
  MULTI_POLYGON,
  POINT,
  POLYGON,
} from 'app/constants/geometryTypes';

export const LINES = 'lines';

export const POLYGONS = 'polygons';

export const POINTS = 'points';

export default {
  [LINES]: {
    multi: MULTI_LINESTRING,
    single: LINESTRING,
  },
  [POINTS]: {
    multi: MULTI_POINT,
    single: POINT,
  },
  [POLYGONS]: {
    multi: MULTI_POLYGON,
    single: POLYGON,
  },
};
