import { post } from 'app/api/crud';
import { log } from 'core/utils/log';
import { snakeCase } from 'lodash';

/**
 * Получение таблицы реестра.
 *
 * @param {string} url - Url запроса.
 * @param {object} queryParams - Параметры запроса.
 * @returns {Promise<*>}
 */
export const getRegistryTable = async (url, queryParams) => {
  const arr = url.split('/').filter((item) => !!item);
  if (arr[0] === 'registry') {
    log.banana('можно убрать из начало пути registry');
    arr.shift();
  }
  const path = arr.reduce((acc, curr) => {
    return `${acc}/${snakeCase(curr)}`;
  }, '/registry');
  const response = await post(path, queryParams, {
    jsonBody: true,
  });

  return response.data;
};
