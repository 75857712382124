import oldValueConsumer from 'core/hocs/oldValueConsumer';
import withReduxForm from 'core/hocs/withReduxForm';

import SelectField from './SelectField';

const WithReduxFormSelect = withReduxForm(SelectField);
const WithOldValueSelect = oldValueConsumer(WithReduxFormSelect);
/* eslint-disable */
export { WithReduxFormSelect, WithOldValueSelect };

 
export default SelectField;
