import getUrl from 'app/utils/getDownloadUrl';


/* eslint-disable */
export default function (result, state, props) {
  const { card } = props;

  return {
    ...result,

    initialValues: {
      ...result.initialValues,

      technic_request_type_id: card.technic_request_type_id,
      technic_request_reason_id: card.technic_request_reason_id,
      technic_request_create_date: card.technic_request_create_date,
      technic_request_description: card.technic_request_description,
      technic_request_file_list: getTechnicRequestFileList(card),
    },
  };
}

function getTechnicRequestFileList({
  technic_request_file_list,
  technic_request_file_list_raw,
}) {
  let result;
  if (technic_request_file_list) {
    result = technic_request_file_list.map((item) => ({
      name: item.file_name,
      href: getUrl(item.file_id),
    }));
  } else if (technic_request_file_list_raw) {
    result = technic_request_file_list_raw
      .filter((item) => item.files)
      .map((item) => ({
        name: item.files,
        href: item.files,
        target: '_blank',
        rel: 'noopener noreferrer',
      }));
  } else {
    result = null;
  }
  return result;
}
