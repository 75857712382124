/* eslint-disable */
import React from 'react';
import { NumberParam } from 'use-query-params';

import {
  CarEquipmentOwnerIdFF,
  CAR_EQUIPMENT_OWNER_ID_NAME,
} from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
 
/**
 * Тип техники car_func_type_id int
 */
 
export const CarEquipmentOwnerIdFilter = (props) => (
  <CarEquipmentOwnerIdFF {...props} className={filterInput} />
);

 
export { CAR_EQUIPMENT_OWNER_ID_NAME };

 
export const carEquipmentOwnerIdParams = {
  [CAR_EQUIPMENT_OWNER_ID_NAME]: NumberParam,
};
