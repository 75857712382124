// import { WithMapContext } from 'app/components/map/withMap';
import { shallow } from 'enzyme';
import React from 'react';
import { vi } from 'vitest';

import IndexCard from './IndexCard';

vi.mock('app/components/map/withMap', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,

    WithMapContext: {},
  };
});

describe('🐛 spec IndexCard', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    const props = {
      Map: vi.fn(),
    };
    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(<IndexCard {...props} />);

    //❓ Assert
    expect(wrapper.text()).toBe(
      '<RightPanel /><DialogAlert /><DialogAlert /><DialogAction />',
    );
  });
});
