import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { CoatingFaceTypeIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/CoatingFaceTypeId.RF';

/**
 * @typedef {import('types/inputs').SelectProps} SelectProps
 */

/**
 * Field Вид покрытия (облицовка).
 *
 * @returns {JSX.Element}
 */
export const CoatingFaceTypeIdField = () => {
  const { editMode } = useEditMode();
  const { typeId: typeIdParent } = useGetParentCard();

  return (
    <CoatingFaceTypeIdRF
      disabled={!editMode}
      filter={(data) => {
        if (editMode) {
          if (typeIdParent) {
            return data?.filter(({ ogh_object_type_ids = [] }) => {
              return ogh_object_type_ids?.includes(typeIdParent);
            });
          }
        }
        return data;
      }}
    />
  );
};
