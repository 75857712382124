import { useMode } from './useMode';

/**
 * Хук, проверки состояния редактирования из url или redux.
 *
 * @deprecated
 * @see useMode
 * @returns {{editMode:boolean,clearEditMode: Function,startEditMode:Function, viewMode: boolean}}
 */
export const useEditMode = () => {
  return useMode();
};
