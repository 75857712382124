import {
  CLEAN_TYPE_ID_NAME as CLEAN_TYPE_ID_NAME_D,
  CleanTypeId,
} from '../CleanTypeId';

/**
 * CLEAN_TYPE_ID_NAME.
 *
 * @deprecated
 * @see используй из CleanTypeId
 */
export const CLEAN_TYPE_ID_NAME = CLEAN_TYPE_ID_NAME_D;

/**
 * CleanTypeId.
 *
 * @deprecated
 * @see используй CleanTypeId
 */
export const CleaningTypeId = CleanTypeId;
