import { mapStateToPropsReducer as documentsReducer } from 'app/components/card/ogh/DocumentsTab';
import { CreateTypeField } from 'app/components/card/ogh/ImprovementTerritoryCard/components/CreateTypeField';
import { IsDiffHeightMarkReducer } from 'app/components/card/ogh/ImprovementTerritoryCard/components/IsDiffHeightMark';
import NameField, {
  mapStateToPropsReducer as nameFieldReducer,
} from 'app/components/card/ogh/ImprovementTerritoryCard/ImprovementObjectCard/components/NameField';
import { DocumentTab } from 'app/components/card/ogh/ImprovementTerritoryCard/ImprovementObjectCard/Document.Tab';
import OrdersTab, {
  mapDispatchToPropsReducer as ordersDispatchReducer,
  mapStateToPropsReducer as ordersReducer,
} from 'app/components/card/ogh/OrdersTab';
import { UNTENANTABLE } from 'app/constants/addressKlKods';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import { IsDiffHeightMarkRF } from 'core/form/reduxForm/fields/prepared/checkboxes/IsDiffHeightMark.RF';
import { ImprovementObjectCategoryIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/ImprovementObjectCategoryId.RF';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import React from 'react';

import AddressListField, {
  mapStateToPropsReducer as addressListFieldReducer,
} from './components/AddressListField';
import { mapStateToPropsReducer as aquaAreaReducer } from './components/AquaAreaTab';
import DepartmentField, {
  mapStateToPropsReducer as DepartmentFieldReducer,
} from './components/DepartmentTypeField';
import {
  EquipmentIndex,
  mapStateToPropsReducer as equipmentIndexReducer,
} from './components/EquipmentIndex';
import {
  mapStateToPropsReducer as improvementCategoriesReducer,
  metadata as improvementCategoriesMetadata,
} from './components/ImprovementCategories';
import { MainParamsTab } from './components/MainParamsTab';
import OwnerAndCustomerFields, {
  mapStateToPropsReducer as ownerAndCustomerReducer,
} from './components/OwnerAndCustomerFields';
import PlantationTab from './components/PlantationTab';
import PropsTabs from './components/PropsTabs';
import { mapStateToPropsReducer as snowAreaReducer } from './components/SnowAreaTab';
import { RepairsTab } from './components/tabs/Repairs.Tab';
import { RepairsPlanTab } from './components/tabs/RepairsPlan.Tab';
import TypeOtherTerritory, {
  mapStateToPropsReducer as typeOtherTerritoryReducer,
  metadata as typeOtherTerritoryMetadata,
} from './components/TypeOtherTerritory';
import { asyncValidateImprovementObject } from './ImprovementObjectCard/asyncValidate.ImprovementObject';
import { createAttributeImprovementObject } from './ImprovementObjectCard/createAttribute.ImprovementObject';
import { initialValuesImprovementObject } from './ImprovementObjectCard/initialValues.ImprovementObject';
import { validateImprovementObject } from './ImprovementObjectCard/validate.ImprovementObject';
import ImprovementTerritoryCardTemplate from './ImprovementTerritoryCardTemplate';
import mapStateToPropsReducer from './mapStateToPropsReducer';
import { improvementObjectCardTabs } from './tabs';

const mandatoryFieldNames = {
  customer_id: 'Заказчик',
  name: 'Наименование',
  name_description: 'Уточнение наименования',
  owner_id: 'Балансодержатель',
  snow_clean_area: 'Площадь вывоза снега',
  [improvementCategoriesMetadata.improvement.id]:
    improvementCategoriesMetadata.improvement.label,
  [improvementCategoriesMetadata.landscaping.id]:
    improvementCategoriesMetadata.landscaping.label,
  [typeOtherTerritoryMetadata.key]: typeOtherTerritoryMetadata.label,
};

const tabToFields = {
  Свойства: [
    improvementCategoriesMetadata.improvement.id,
    improvementCategoriesMetadata.landscaping.id,
    typeOtherTerritoryMetadata.key,
    'owner_id',
    'name_description',
    'customer_id',
    'snow_clean_area',
  ],
};

const idForRecalcField = {
  address: 're_calc_address_list',
  name: 're_calc_name',
};

/**
 * Иные объекты благоустройства.
 * ImprovementObjectCard.
 */
export const ImprovementObjectCard = ImprovementTerritoryCardTemplate({
  mapDispatchToPropsReducers: [ordersDispatchReducer],
  mapStateToPropsReducers: [
    mapStateToPropsReducer({
      asyncValidate: asyncValidateImprovementObject,
      createAttribute: createAttributeImprovementObject,
      mandatoryFieldNames,
      tabToFields,
      validate: validateImprovementObject,
    }),
    improvementCategoriesReducer({
      improvement: {
        defaultOption: {
          id: -2,
          name: 'Не определена (уборочная площадь = 0)',
        },
        defaultValue: -2,
      },
    }),
    IsDiffHeightMarkReducer(),
    ownerAndCustomerReducer(),
    equipmentIndexReducer(),
    nameFieldReducer(),
    addressListFieldReducer({
      id: idForRecalcField.address,
    }),
    DepartmentFieldReducer({
      department_id: 10216105,
    }),
    snowAreaReducer,
    aquaAreaReducer,
    typeOtherTerritoryReducer(),
    documentsReducer({
      oghGroupCode: 'yard_object',
    }),
    ordersReducer,
    initialValuesImprovementObject,
  ],
  propsTabElementRenders: [
    <CreateTypeField />,
    DepartmentField({ editable: false }),
    OwnerAndCustomerFields,
    (props) => (
      <FieldsSplitColumns>
        <SelectRF
          name={'improvement_category_id'}
          label={'Категория благоустройства'}
          options={props.options.improvement_category_id}
          disabled={true}
        />
        <ImprovementObjectCategoryIdRF disabled={true} />
      </FieldsSplitColumns>
    ),
    TypeOtherTerritory,
    NameField,
    (props) => (
      <FieldsSplitColumns>
        <IsDiffHeightMarkRF
          label={'Разновысотный ОГХ'}
          disabled={!props.editable}
        />
        <EquipmentIndex />
      </FieldsSplitColumns>
    ),
    // ImprovementObjectCardAttributes(),
    AddressListField({
      addition: {
        data: {
          address: {
            kl_kod: UNTENANTABLE,
          },
        },
      },
      editable: false,
      id: idForRecalcField.address,
      name: idForRecalcField.address,
      required: false,
    }),
    PropsTabs(improvementObjectCardTabs),
    <MainParamsTab />,
    <PlantationTab />,
    <RepairsTab />,
    <RepairsPlanTab />,
    <DocumentTab />,
    OrdersTab(),
  ],
});
