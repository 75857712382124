import { Button } from '@mui/material';
import FaIcon from 'app/components/common/FaIcon';
import { AuthUrlsFF } from 'core/form/finalForm/fields/prepared/selects/simple/AuthUrls.FF';
import { PasswordFF } from 'core/form/finalForm/fields/prepared/textField/PasswordFF';
import { UserNameFF } from 'core/form/finalForm/fields/prepared/textField/UserNameFF';
import { Link } from 'react-router-dom';

import { FormAuthFF } from '../components/Form.Auth.FF';
import { useMutationAuth } from './hooks/useMutationAuth';
import { validateForm } from './utils/validateForm';

/**
 * Login.
 *
 * @returns {JSX.Element}
 */
export const Login = () => {
  const { loginSubmit, isLoading, errorMessage } = useMutationAuth();
  return (
    <FormAuthFF
      submit={loginSubmit}
      isLoading={isLoading}
      errorMessage={errorMessage}
      validate={validateForm}
      Header={'Вход'}
      Footer={
        <>
          <Button
            loading={isLoading}
            dark={true}
            variant={'contained'}
            color={'primary'}
            type={'submit'}
          >
            {isLoading ? null : (
              <FaIcon className={'submitIcon'} icon={'sign-in'} />
            )}
            Войти
          </Button>

          <Link to="/auth/forgotPassword">Восстановить пароль</Link>
          {errorMessage && (
            <div className={'errorMessage'}>
              <span className={'error-message-details'}>
                {`Вход невозможен: ${errorMessage}`}
              </span>
            </div>
          )}
        </>
      }
    >
      <AuthUrlsFF />
      <UserNameFF autoFocus={true} />
      <PasswordFF />
    </FormAuthFF>
  );
};
