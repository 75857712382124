import { AutocompleteWithChipAsync } from 'core/uiKit/inputs/selects/AutocompleteWithChipAsync';
import React from 'react';

import { fetchPrivileges } from './api';

export const PRIVILEGE_NAME = 'privilege';

/**
 * ## Privileges.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const Privileges = (props) => {
  return (
    <AutocompleteWithChipAsync
      {...props}
      fetchFunction={fetchPrivileges}
      label={'Привилегия'}
      name={PRIVILEGE_NAME}
    />
  );
};
