/* eslint-disable  */
import hints from 'app/constants/hints';

/**
 *
 * @param key
 */
const getHint = (key) => hints[key];

export default getHint;
