/* eslint-disable */
import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import {
  RED_BOOK_PLANT_ID_NAME,
  RedBookPlantId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/RedBookPlantId';

/**
 * Вид растения
 * ### React Final Form Field
 *
 * ###### type = `Select`
 * ###### name = `RED_BOOK_PLANT_ID_NAME`
 *
 *
 */
export const RedBookPlantIdFF = ({ name, ...otherProps }) => {
  return (
    <Field
      name={RED_BOOK_PLANT_ID_NAME}
      component={RedBookPlantId}
      {...otherProps}
    />
  );
};

export { RED_BOOK_PLANT_ID_NAME };

RedBookPlantIdFF.propTypes = {
  /**
   * очищаем инпут с сохранением значения
   */
  clear: PropTypes.bool,

  /**
   * быбор темной темы
   */
  dark: PropTypes.bool,

  /**
   * функция для фильтрации options
   */
  filter: PropTypes.func,

  /* eslint-disable */
  /**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,

  /**
   * вывод ошибки
   */
  errorText: PropTypes.string,

  /**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,

  /**
   * placeholder
   */
  placeholder: PropTypes.string,

  /**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
};
RedBookPlantIdFF.defaultProps = {
  disabled: false,
};
