import { Cell } from 'core/uiKit/components/tables/Table';

/**
 * Колонка __ИД статуса логический__.
 *
 * @type {{
 * accessor: 'logical_state_id',
 * Header: 'ИД статуса логический',
 * sortable: true,
 * }}
 * @augments Cell
 */
export const logical_state_id: Cell = {
  Header: 'ИД статуса логический',
  accessor: 'logical_state_id',
  sortable: true,
};
