import { get, isEmpty } from 'lodash';
/* eslint-disable  */
import { createSelector } from 'reselect';

/**
 *
 * @param state
 */
const getIntersections = (state) => get(state, 'card.intersections', []);

export default createSelector(
  [getIntersections],
  (intersections) => !isEmpty(intersections),
);
