import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { OghCardHeaderFF } from 'app/pages/cardsOgh/components/headerFF/OghHeaderFF';
import { OtherDocumentsFf } from 'app/pages/cardsOgh/components/tables/Documents/FieldsFF/preparedFields/OtherDocuments.FF';
import { Versions } from 'app/pages/cardsOgh/components/tables/Versions';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { IsDiffHeightMarkFf } from 'core/form/finalForm/fields/prepared/checkboxes/IsDiffHeightMark.FF';
import { NoCalcFF } from 'core/form/finalForm/fields/prepared/checkboxes/NoCalc.FF';
import { DistanceFF } from 'core/form/finalForm/fields/prepared/numberField/Distance.FF/Distance.FF';
import { MaterialIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/MaterialId.FF/MaterialId.FF';
import { CardContainer } from 'core/uiKit/components/card.components/CardContainer';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { TabsCardOGH } from 'core/uiKit/components/card.components/TabsCardOGH';
import { TabsCardOGHItem } from 'core/uiKit/components/card.components/TabsCardOGH/TabsCardOGH.Item';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import React from 'react';

import { AbutmentTypeNameFFField } from './fields/AbutmentTypeName.FF.Field/AbutmentTypeName.FF.Field';
import { ElementOwnershipTable } from './tables/ElementOwnership.Table/ElementOwnership.Table';

/**
 * Электронная форма паспорта __Элементы сопряжения поверхностей__.
 *
 * @returns {JSX.Element}
 */
export const LeftPanelAbutment = () => {
  const { rootId = 0 } = useGetCurrentCard();
  const { editMode } = useMode();
  const isCreatedPassport = Boolean(rootId);

  return (
    <CardContainer>
      <OghCardHeaderFF />
      <TabsCardOGH>
        <TabsCardOGHItem tabTitle={'Свойства'}>
          <AbutmentTypeNameFFField />
          <ParentInfo />
          <TabsCardOGH>
            <TabsCardOGHItem tabTitle={'Исходные данные'}>
              <FieldsSplitColumns>
                <DistanceFF disabled={true} decimalScale={2} />
                <MaterialIdFF withAll={false} disabled={!editMode} />
                <NoCalcFF disabled={!editMode} />
                <IsDiffHeightMarkFf disabled={!editMode} />
              </FieldsSplitColumns>
            </TabsCardOGHItem>
            <TabsCardOGHItem tabTitle={'Принадлежность элемента'}>
              <ElementOwnershipTable />
            </TabsCardOGHItem>
            <TabsCardOGHItem tabTitle={'Документы'}>
              <OtherDocumentsFf />
            </TabsCardOGHItem>
          </TabsCardOGH>
        </TabsCardOGHItem>
        <TabsCardOGHItem tabTitle={'Версии'} hide={!isCreatedPassport}>
          <Versions />
        </TabsCardOGHItem>
      </TabsCardOGH>
    </CardContainer>
  );
};
