import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { Button } from 'core/uiKit/components/buttons/Button';
import { Icon } from 'core/uiKit/material-ui';
import React from 'react';

/**
 * Кнопка сохранения.
 *
 * @param {*} props - Параметры.
 * @returns {JSX.Element}
 */
export const SaveButton = (props) => {
  const { editMode } = useMode();

  return (
    <Button
      {...props}
      color={'success'}
      type="submit"
      variant={'contained'}
      hidden={!editMode}
      startIcon={<Icon>published_with_changes</Icon>}
    >
      Сохранить
    </Button>
  );
};
