import { MaterialIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/MaterialId.RF';

import { useSetupMaterialIdField } from './useSetup.MaterialId.Field';

/**
 * Материал. Field.
 *
 * @returns {JSX.Element}
 */
export const MaterialIdField = () => {
  const { editMode, filter } = useSetupMaterialIdField();

  return <MaterialIdRF disabled={!editMode} filter={filter} />;
};
