import { useFetchOghTable } from 'app/pages/registry/ogh/api';
import { useParamsTable } from 'core/uiKit/components/tables/Table';

import { TableOghChildrenProps } from '../../types';
import { useFilterQueryParamsWaterBuilding } from './Filter.WaterBuilding';

/**
 * Setup компонента таблицы __Водных сооружений__.
 *
 * @param {TableOghChildrenProps} props - Пропсы.
 * @returns
 */
export const useSetupTableWaterBuilding = ({
  typeId,
  parentTypeId,
}: TableOghChildrenProps) => {
  const { prepareFilters } = useFilterQueryParamsWaterBuilding();
  const { queryTable } = useParamsTable();

  const queryKey = [
    'TableWaterBuilding',
    {
      ...queryTable,
      ...prepareFilters,
      parentTypeId,
      typeId,
    },
  ];

  const { table, isLoading, count } = useFetchOghTable(queryKey);

  return { count, isLoading, table };
};
