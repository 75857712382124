/* eslint-disable */
import React from 'react';

import {
  OtherImprovementObjectTypeFF,
  OTHER_IMPROVEMENT_OBJECT_TYPE_NAME,
} from 'core/form/finalForm/fields';
import { NumberParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

 
 
/**
 * Тип ИОБ otherImprovementObjectType
 */
 
export const OtherImprovementObjectTypeFilter = () => (
  <OtherImprovementObjectTypeFF className={filterInput} />
);

 
export const otherImprovementObjectTypeParams = {
  [OTHER_IMPROVEMENT_OBJECT_TYPE_NAME]: NumberParam,
};
