import './BranchTree.scss';

import { TreeItemMUI, Typography } from 'core/uiKit/material-ui';
import { useGetOghTypes } from 'core/utils/hooks/useGetOghTypes';
import PropTypes from 'prop-types';
import React from 'react';

import LeafTree from './LeafTree';

/**
 * Компонент BranchTree.
 *
 * @param {object} props - Пропсы.
 * @param {*} props.links - Any.
 * @param {*} props.typeOgh - Any.
 * @param {*} props.count - Any.
 * @param {*} props.onClickLink - Any.
 * @param {*} props.title - Any.
 * @returns {JSX.Element}
 */
export const BranchTree = ({
  links = [],
  typeOgh,
  count,
  onClickLink,
  title,
}) => {
  const { name } = useGetOghTypes(typeOgh);

  const titleBranch = title ? title : name;

  return (
    <TreeItemMUI
      nodeId={typeOgh}
      className={'branch branch__item '}
      label={
        <div className={'wrapper-branch__item'}>
          <Typography variant="body2" className={'wrapper-branch__title'}>
            {titleBranch}
          </Typography>
          <Typography variant="caption" color="inherit">
            {count}
          </Typography>
        </div>
      }
    >
      {Array.isArray(links)
        ? links.map(({ id, title }) => {
            return (
              <LeafTree
                key={title + id}
                nodeId={title + id}
                title={title}
                id={id}
                onClick={() => onClickLink(id)}
              />
            );
          })
        : null}
    </TreeItemMUI>
  );
};

const propTypesItems = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
});

BranchTree.propTypes = {
  count: PropTypes.number,
  links: PropTypes.arrayOf(propTypesItems) || null,
  typeOgh: PropTypes.string,
};
