import './Tree.MainPage.scss';

import { GroupsTree } from 'app/pages/cardsOgh/components/RightPanelCard/components/TreeRightPanel/Groups.Tree';
import { Icon } from 'core/uiKit/components/Icon';
import { LinearProgress } from 'core/uiKit/material-ui';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

/**
 * Элемент списка родительских элементов.
 *
 * @param {object} props - Значения списка.
 * @param {object} props.item - Значения списка.
 * @param {Function} props.getGeometryFull - Функция получения геометрии списка.
 * @param {React.ref} props.refObserver - Рефка для скролла.
 * @param {Function} props.fetchTreeChildren - Метод получения дочернего списка.
 * @param {object} props.hash - Хранилище полученных дочерних списков.
 * @param {string} props.id - Идентификатор.
 * @param {boolean} props.isLoading - Флаг загрузки.
 *
 * @returns {Element}
 */
export const TreeMainPageItem = ({
  item,
  getGeometryFull,
  refObserver,
  fetchTreeChildren,
  hash,
  id,
  isLoading,
}) => {
  const hasHash = Boolean(hash?.[id]);
  const hasError = Boolean(hash?.[id]?.error);
  const open = Boolean(hash?.[id]?.open);
  const clOpen = open ? 'fa fa-caret-down' : 'fa fa-caret-right';
  const [touched, setTouched] = useState(false);

  return (
    <>
      <li ref={refObserver} key={id}>
        <div style={{ alignItems: 'center', display: 'flex' }}>
          {!hasError ? (
            <Icon
              onClick={() => {
                setTouched(true);
                fetchTreeChildren({
                  id,
                  logicalStateId: 1,
                  rootId: item?.object_id || item.root_id,
                  typeId: item.type_id,
                });
              }}
              baseClassName="fa"
              className={`fa-solid ${clOpen}`}
            />
          ) : null}
          <div className={'ms-2'}>
            <Link to={`/ogh/${item.root_id}`} key={item.root_id}>
              {item.short_root_id}
            </Link>
            <span
              className={'ms-1'}
              onClick={() =>
                getGeometryFull({
                  id: item.id,
                  rootId: item?.object_id || item.root_id,
                  typeId: item.type_id,
                })
              }
            >
              {item.object_name}
            </span>
          </div>
        </div>
        {open ? (
          <GroupsTree setLoadingRender={(_) => _} subgroups={hash?.[id].data} />
        ) : null}
      </li>
      {!hasHash && touched && isLoading && !open ? (
        <div>
          <LinearProgress color={'inherit'} />
        </div>
      ) : null}
    </>
  );
};
