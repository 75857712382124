import './Body.News.Dialog.scss';

import { clearCasheNews, useGetNews } from 'app/api/hooks/news/useGet.News';
import { useMakeNewsHasBeenRead } from 'app/api/hooks/news/useMake.NewsHasBeenRead';
import { useState } from 'react';
import { DataNews } from 'types/data.News';

/**
 * Setup Диалоговое окно Оповещения Пользователя о Новой новости.
 *
 * @returns Flags and functions.
 */
export const useSetupBodyNewsDialog = () => {
  // todo: вынести хуки
  const { data = [] } = useGetNews();
  const [activeStep, setActiveStep] = useState(0);

  const [makeHasBeenRead] = useMakeNewsHasBeenRead({
    //

    /**
     * OnSuccess обработчик.
     */
    onSuccess: () => {
      if (activeStep + 1 > (data as DataNews).length) {
        clearCasheNews();
      }
    },
  });

  /**
   * Обработчик действия Следующий шаг.
   *
   * @param id - Id новости.
   * @returns {void}
   */
  const handleNext = (id: number): void => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    makeHasBeenRead(id);
  };

  return {
    activeStep,
    data,
    handleNext,
  };
};
