/* eslint-disable */
import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import {
  OldPassword,
  OLD_PASSWORD_NAME,
} from 'core/uiKit/preparedInputs/textField/OldPassword';

/* eslint-disable */
export const OldPasswordFF = ({ name, ...otherProps }) => {
  return (
    <Field name={OLD_PASSWORD_NAME}>
      {({ input: { name, value, onChange }, meta }) => {
        return (
          <OldPassword
            {...otherProps}
            {...{ name, value, onChange }}
            {...{ meta }}
          />
        );
      }}
    </Field>
  );
};

 
export { OLD_PASSWORD_NAME };

OldPasswordFF.propTypes = {
   
   
/**
   * быбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
/**
   * placeholder
   */
  placeholder: PropTypes.string,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
};
OldPasswordFF.defaultProps = {
  disabled: false,
};
