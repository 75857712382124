import { validate as validateAquaArea } from 'app/components/card/ogh/ImprovementTerritoryCard/components/AquaAreaTab';
import { validate as validateSnowArea } from 'app/components/card/ogh/ImprovementTerritoryCard/components/SnowAreaTab';

/**
 * Валидация.
 *
 * @param {object} values - Values to validate.
 * @returns {object|null} - Возвращает объект с ошибками.
 * @example ---
 */
export function validate(values: object) {
  return {
    ...validateSnowArea(values),
    ...validateAquaArea(values),
  };
}
