/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import Select from 'core/newComponents/Select';
import TextField from 'core/components/TextField';

import DialogAction from '../../common/DialogAction';

import getDict from 'app/selectors/getDict';
import mafTypeService from 'app/services/mafTypeService';

const propTypes = {
  showCondition: PropTypes.bool,
  table: PropTypes.array,
  title: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

const defaultProps = {
  title: 'Добавление записи',
};

class DialogAddMafRecord extends React.Component {
  state = {};

  componentDidUpdate(prevProps) {
    if (prevProps.showCondition !== this.props.showCondition) {
      this.setState({
        maf_type_level1_id: null,
        maf_type_level2_id: null,
        maf_type_level3_id: null,
        maf_quantity_characteristics: null,
        quantity: null,
      });
    }
  }

  getQuantityCharacteristicsProps(mafTypeLevel1Id) {
    const { mafTypeLevel1 } = this.props;
    return mafTypeService.getQuantityCharacteristicsProps(
      mafTypeLevel1,
      mafTypeLevel1Id,
    );
  }

  handleCancel = () => {
    const { onCancel } = this.props;
    onCancel();
  };

  handleOk = () => {
    const {
      maf_type_level1_id,
      maf_type_level2_id,
      maf_type_level3_id,
      maf_quantity_characteristics,
      quantity,
    } = this.state;
    const { onSubmit } = this.props;
    onSubmit({
      maf_type_level1_id: {
        maf_type_level1: maf_type_level1_id,
      },
      maf_type_level2_id: {
        maf_type_level2: maf_type_level2_id,
      },
      maf_type_level3_id: {
        maf_type_level3: maf_type_level3_id,
      },
      maf_quantity_characteristics,
      quantity,
    });
  };

  isDisabledContinueButton() {
    const { state } = this;
    let result;
    if (state.maf_type_level1_id && state.quantity) {
      result = false;
    } else {
      result = true;
    }
    return result;
  }

  renderMafQuantityCharacteristics() {
    const { label, disabled } = this.getQuantityCharacteristicsProps(
      this.state.maf_type_level1_id,
    );
    return this.renderTextField({
      id: 'maf_quantity_characteristics',
      label,
      disabled,
    });
  }

  renderMafTypeLevel1() {
    const { props } = this;
    const table = props.table || [];
    const selectedMafTypes = table.map((item) =>
      _.get(item, 'maf_type_level1_id.maf_type_level1'),
    );
    const items = props.mafTypeLevel1.filter(
      (item) => !selectedMafTypes.includes(item.id),
    );

    return this.renderSelectField({
      id: 'maf_type_level1_id',
      label: 'Вид МАФ',
      onChange: (value) => {
        const { disabled } = this.getQuantityCharacteristicsProps(value);
        const state = {
          maf_type_level1_id: value,
          maf_type_level2_id: null,
          maf_type_level3_id: null,
        };
        if (disabled) {
          state.maf_quantity_characteristics = null;
        }
        this.setState(state);
      },
      items,
      width: '276',
    });
  }

  renderMafTypeLevel2() {
    const { mafTypeLevel2 } = this.props;
    const { maf_type_level1_id } = this.state;
    let items;

    if (mafTypeLevel2) {
      items = mafTypeService.filterMafTypeLevel2(
        mafTypeLevel2,
        maf_type_level1_id,
      );
    } else {
      items = [];
    }

    return this.renderSelectField({
      id: 'maf_type_level2_id',
      label: 'Уточнение',
      onChange: (value) => {
        this.setState({
          maf_type_level2_id: value,
        });
      },
      items,
      width: '276',
    });
  }

  renderMafTypeLevel3() {
    const { mafTypeLevel3 } = this.props;
    const { maf_type_level1_id } = this.state;
    let items;

    if (mafTypeLevel3) {
      items = mafTypeService.filterMafTypeLevel3(
        mafTypeLevel3,
        maf_type_level1_id,
      );
    } else {
      items = [];
    }

    return this.renderSelectField({
      id: 'maf_type_level3_id',
      label: 'Детализация',
      onChange: (value) => {
        this.setState({
          maf_type_level3_id: value,
        });
      },
      items,
      width: '100%',
    });
  }

  renderQuantity() {
    return this.renderTextField({
      id: 'quantity',
      label: 'Количество МАФ, шт.',
    });
  }

  renderSelectField({ id, label, onChange, items, width }) {
    return (
      <div style={{ marginBottom: 10 }}>
        <Select
          disabled={!items || !items.length}
          id={id}
          label={label}
          options={items || []}
          value={this.state[id] || ''}
          width={width}
          onChange={(value) => {
            onChange(value);
          }}
        />
      </div>
    );
  }

  renderTextField({ id, label, ...otherProps }) {
    const onChange = (value) => {
      this.setState({
        [id]: value,
      });
    };
    return (
      <TextField
        id={id}
        label={label}
        value={this.state[id] || ''}
        onBlur={(event) => {
          const { value } = event.target;
          const number =
            value === '' || value === '-' ? '' : parseFloatWithComma(value);
          onChange(number);
        }}
        onChange={({ target: { value } }) => {
          if (isNumber(value) && digitsCheck(value)) {
            onChange(value);
          }
        }}
        {...otherProps}
      />
    );
  }

  render() {
    const { showCondition, title } = this.props;

    return (
      <DialogAction
        className="Dialog-Add-Maf-Record"
        isDisabledOk={this.isDisabledContinueButton()}
        showCondition={showCondition}
        title={title}
        onCancel={this.handleCancel}
        onOk={this.handleOk}
      >
        {this.renderMafTypeLevel1()}
        {this.renderMafTypeLevel2()}
        {this.renderMafTypeLevel3()}
        {this.renderMafQuantityCharacteristics()}
        {this.renderQuantity()}
      </DialogAction>
    );
  }
}

DialogAddMafRecord.propTypes = propTypes;
DialogAddMafRecord.defaultProps = defaultProps;

const getMafTypeLevel1 = getDict('mafTypeLevel1');
const getMafTypeLevel2 = getDict('mafTypeLevel2');
const getMafTypeLevel3 = getDict('mafTypeLevel3');

const mapStateToProps = (state) => {
  return {
    mafTypeLevel1: getMafTypeLevel1(state),
    mafTypeLevel2: getMafTypeLevel2(state),
    mafTypeLevel3: getMafTypeLevel3(state),
  };
};

function digitsCheck(value) {
  const check = new RegExp('^-{0,1}\\d*$');
  return check.test(`${value}`);
}

function parseFloatWithComma(value) {
  return parseFloat(`${value}`.replace(',', '.'));
}

function isNumber(value) {
  return /^\d*[,.]{0,1}\d*$/.test(`${value}`);
}

 
export default connect(mapStateToProps)(DialogAddMafRecord);
