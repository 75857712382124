import React from 'react';

/**
 * Ссылка на план в СОК Благоустройство.
 *
 * @param {object} props - Пропсы.
 * @param {string} props.value - Ссылка.
 * @returns {JSX.Element}
 */
export const LinkLandscapingCell = ({ value }) => {
  if (value) {
    return (
      <a target={'_blank'} href={value} rel="noreferrer">
        План благоустройства
      </a>
    );
  }
  return null;
};
