import { filterInput } from 'core/form/filterForm/components/filterInputsClasses';
import { GrbsIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/GrbsId.FF';
import { GRBS_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/GrbsId';
import React from 'react';
import { FC } from 'react';
import { StringParam } from 'use-query-params';

interface GrbsIdFilterProps {
  hidden?: boolean;
  label?: string;
}

/**
 * Select от Filter __ГРБС__.
 *
 * @param props - Prorerties.
 * @param props.label - Name of the label.
 * @param props.hidden - Флаг скрытия элемента.
 * @returns {JSX.Element} - JSX.
 */
export const GrbsIdFilter: FC<GrbsIdFilterProps> = ({ hidden, label }) =>
  // @ts-ignore
  hidden ? null : <GrbsIdFF className={filterInput} label={label} />;

/**
 * Свой QueryParam от __GrbsIdFilter__.
 *
 * @type {{GRBS_ID_NAME: StringParam}}
 */
export const grbsIdParams = {
  [GRBS_ID_NAME]: StringParam,
};
