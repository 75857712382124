import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { MultiSelect } from 'core/uiKit/inputs/selects/MultiSelect';
import React from 'react';

export const PLANT_SERVICE_RECOMENDATIONS_ID_NAME =
  'plant_service_recomendations_id';

/**
 * ## Рекомендации по уходу.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const PlantServiceRecomendationsId = (props) => {
  const dict = 'PlantServiceRecomendations';
  const { data = [] } = useGetDictionary(dict);

  return (
    <MultiSelect
      {...props}
      label={'Рекомендации по уходу'}
      options={data}
      name={PLANT_SERVICE_RECOMENDATIONS_ID_NAME}
    />
  );
};
