import { asyncValidate as nameFieldAsyncValidate } from 'app/components/card/ogh/ImprovementTerritoryCard/ImprovementObjectCard/components/NameField';
import { CardOgh } from 'types/Card';
import { Mock, vi } from 'vitest';

import { asyncValidate as improvementCategoriesAsyncValidate } from '../components/ImprovementCategories';
import { asyncValidate as ownerAndCustomerFieldsAsyncValidate } from '../components/OwnerAndCustomerFields';
import { asyncValidate as snowAreaAsyncValidate } from '../components/SnowAreaTab';
import { asyncValidate as typeOtherTerritoryAsyncValidate } from '../components/TypeOtherTerritory';
import { asyncValidateImprovementObject } from './asyncValidate.ImprovementObject';

vi.mock('../components/ImprovementCategories');
vi.mock('../components/OwnerAndCustomerFields');
vi.mock('../components/SnowAreaTab');
vi.mock('../components/TypeOtherTerritory');
vi.mock(
  'app/components/card/ogh/ImprovementTerritoryCard/ImprovementObjectCard/components/NameField',
);

/**
 * Mock Default.
 *
 * @returns {void}
 */
const mockDefault = () => {
  (ownerAndCustomerFieldsAsyncValidate as Mock).mockReturnValue({ aa: 1 });
  (nameFieldAsyncValidate as Mock).mockReturnValue({ ab: 1 });
  (typeOtherTerritoryAsyncValidate as Mock).mockReturnValue({ ac: 1 });
  (snowAreaAsyncValidate as Mock).mockReturnValue({ ad: 1 });
  (improvementCategoriesAsyncValidate as Mock).mockReturnValue({ ae: 1 });
};

describe('🐛 spec asyncValidate.ImprovementObject.spec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock

    //🔥 Act
    // @ts-ignore
    const res = asyncValidateImprovementObject({
      formValues: 'formValues',
    });

    //❓ Assert
    expect(res).toStrictEqual({
      aa: 1,
      ab: 1,
      ac: 1,
      ad: 1,
      ae: 1,
    });
  });
  it('🧪 called with', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock

    //🔥 Act
    // @ts-ignore
    asyncValidateImprovementObject({
      formValues: 'formValues',
    } as CardOgh);

    //❓ Assert
    expect(ownerAndCustomerFieldsAsyncValidate).toHaveBeenCalledWith({
      formValues: 'formValues',
    });
    expect(nameFieldAsyncValidate).toHaveBeenCalledWith({
      formValues: 'formValues',
    });
    expect(typeOtherTerritoryAsyncValidate).toHaveBeenCalledWith({
      formValues: 'formValues',
    });
    expect(snowAreaAsyncValidate).toHaveBeenCalledWith({
      formValues: 'formValues',
    });
    expect(improvementCategoriesAsyncValidate).toHaveBeenCalledWith({
      formValues: 'formValues',
    });
  });
});
