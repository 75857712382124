import { get, isEmpty } from 'lodash';
/* eslint-disable  */
import { createSelector } from 'reselect';

/**
 *
 * @param state
 */
const getReonIntersections = (state) =>
  get(state, 'card.reonIntersections', {});

export default createSelector(
  [getReonIntersections],
  (reonIntersections) => !isEmpty(reonIntersections),
);
