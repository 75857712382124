/* eslint-disable */
import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import {
  ProviderListId,
  PROVIDER_LIST_ID_NAME,
} from 'core/uiKit/preparedInputs';

 
 
/**
 * ## Поставщик телеметрических услуг
 * ### React Final Form Field
 *
 * ###### type = `AutocompleteMultiAsync`
 * ###### name = `providerListId`

 * ```js
 * import {
  ProviderListIdFF,
  PROVIDER_LIST_ID_NAME,
} from 'core/form/finalForm/fields';
 * ```
 *
 *
 */
/* eslint-disable */
export const ProviderListIdFF = ({ name, ...otherProps }) => {
  return (
    <Field name={PROVIDER_LIST_ID_NAME}>
      {(props) => {
        return <ProviderListId {...props} {...props.input} {...otherProps} />;
      }}
    </Field>
  );
};

 
export { PROVIDER_LIST_ID_NAME };

ProviderListIdFF.propTypes = {
   
   
/**
   * очищаем инпут с сохранением значения
   */
  clear: PropTypes.bool,
   
   
/**
   * быбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
/**
   * placeholder
   */
  placeholder: PropTypes.string,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
};
ProviderListIdFF.defaultProps = {
  disabled: false,
};
