import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { parseDate } from 'app/components/card/common/parse';
import { useGetInitialValuesTablesDocuments } from 'app/pages/cardsOgh/components/tables/Documents/hooks/useGetInitialValuesTables';
import { useFilterQueryParamsVersionDefault } from 'app/pages/cardsOgh/components/tables/Versions/Filters/useFilterQueryParamsVersionDefault';
import { useMemo } from 'react';

/**
 * Хук установки изначального состояния паспорта __Элементы вертикального озеленения__.
 *
 * @returns {object}
 */
export const useInitialValueVerticalLandscaping = () => {
  const { startDate, endDate, zone_name, attribute } = useGetCurrentCard();

  const objTables = useGetInitialValuesTablesDocuments();
  const { filters: filtersVersionTable } = useFilterQueryParamsVersionDefault();

  return useMemo(() => {
    return {
      create_type_id: attribute?.create_type_id?.create_type,
      eais_id: attribute?.eais_id,
      endDate: parseDate(endDate),
      erz_date: parseDate(attribute?.erz_date),
      erz_state_id: attribute?.erz_state_id?.erz_state,
      guarantee_period: parseDate(attribute?.guarantee_period),
      id_rfid: attribute?.id_rfid,
      installation_date: parseDate(attribute.installation_date),
      is_diff_height_mark: true,
      is_filled: attribute?.is_filled,
      lifetime: parseDate(attribute?.lifetime),
      material_id: attribute?.material_id?.material,
      noCalc: attribute?.no_calc,
      places_area: attribute.places_area,
      places_quantity: attribute.places_quantity,
      quantity: attribute.quantity,
      related_elements_flowers_garden_id:
        attribute?.related_elements_flowers_garden_id,
      related_elements_trees_shrubs_id:
        attribute?.related_elements_trees_shrubs_id,
      startDate: parseDate(startDate),
      unit_id: attribute?.unit_id?.unit,
      vertical_landscaping_class_type_id:
        attribute.vertical_landscaping_class_type_id
          ?.vertical_landscaping_class_type,
      vertical_landscaping_ref_type_id:
        attribute?.vertical_landscaping_ref_type_id
          ?.vertical_landscaping_ref_type,
      vertical_landscaping_type_id:
        attribute?.vertical_landscaping_type_id?.vertical_landscaping_type,
      zone_name,
      ...objTables,
      ...filtersVersionTable,
    };

    // eslint-disable-next-line
  }, [startDate, endDate, attribute]);
};
