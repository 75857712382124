import { shallow } from 'enzyme';

/* eslint-disable */
import { BrStatusIdCell } from './BrStatusId.Cell';

jest.mock('app/api/hooks/autocomplete/useGetDictionary', () => ({
  /**
   *
   */
  useGetDictionary: () => ({
    data: [
      {
        id: 1,
        name: 'Проект',
      },
      {
        id: 2,
        name: 'Отправлена на согласование',
      },
      {
        id: 3,
        name: 'Отклонена',
      },
      {
        id: 4,
        name: 'Согласована ОИВ',
      },
      {
        id: 5,
        name: 'Согласована МКА',
      },
      {
        id: 6,
        name: 'Включена в график паспортизации',
      },
      {
        id: 7,
        name: 'Аннулирована',
      },
      {
        id: 8,
        name: 'Исполнена',
      },
    ],
  }),
}));

describe('BrStatusIdCellJestSpec', function () {
  it('test render', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const wrapper = shallow(<BrStatusIdCell value={4} />);
    // Assert
    expect(wrapper.props()).toStrictEqual({
      children: 'Согласована ОИВ',
    });
  });
  it('atest render', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const wrapper = shallow(<BrStatusIdCell value={10} />);
    // Assert
    expect(wrapper.props()).toStrictEqual({
      children: '',
    });
  });
});
