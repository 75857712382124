import { ol } from '../../../utils/helpers/ol';

/* eslint-disable */
export default class Modify {
  constructor(map, layer) {
    this.Modify = createModifyInteraction(map, layer);
  }

  turnOn() {
    this.Modify.setActive(true);
  }

  turnOff() {
    this.Modify.setActive(false);
  }

  onModifyStart(handler) {
    this.Modify.on('modifystart', handler);
  }

  onModifyEnd(handler) {
    this.Modify.on('modifyend', handler);
  }
}

const createModifyInteraction = (map, features) => {
  const modify = new ol.interaction.Modify({
    features,
    deleteCondition: ol.events.condition.doubleClick,
  });
  modify.setActive(false);

  map.addInteraction(modify);

  return modify;
};
