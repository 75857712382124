import { log } from 'core/utils/log';
/* eslint-disable */
const urlFields = [
  {
    name: 'owner_link',
  },
  {
    name: 'park_link',
  },
  {
    name: 'oper_link',
  },
];

function validateUrl(values, items) {
  const errors = {};

  items
    .filter((field) => {
      const value = values[field.name];
      if (typeof value !== 'string') {
        return false;
      }
      return value !== '';
    })
    .forEach((field) => {
      const value = String(values[field.name]).trim();

      if (
         
        !/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g.test(
          value,
        )
      ) {
        errors[field.name] = 'введите валидный url';
      }
    });

  if (Object.keys(errors).length) {
    log.banana(errors);
  }

  return errors;
}

 
export const validateAdditionalInformation = (values) => {
  return validateUrl(values, urlFields);
};
