import { todayDateAndHours } from 'app/utils/dateUtils';
import { filterInputDataWithHours } from 'core/form/filterForm/components/filterInputsClasses';
import { KeyboardDateTimePickerFF } from 'core/form/finalForm/fields/default/datePickers/KeyboardDateTimePickerFF';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import moment from 'moment';
import React from 'react';
import { DateTimeParam, withDefault } from 'use-query-params';

export const DATE_WITH_HOURS_NAME = 'date';

/**
 * DateWithHoursFilter.
 *
 * @param {*} props - Properties.
 * @returns {JSX.Element}
 */
export const DateWithHoursFilter = (props) => (
  <KeyboardDateTimePickerFF
    {...props}
    className={filterInputDataWithHours}
    label="Дата"
    name={DATE_WITH_HOURS_NAME}
  />
);

/**
 * DateWithHoursParams.
 *
 * @returns {object}
 */
export const dateWithHoursParams = () => {
  addPreparation(
    DATE_WITH_HOURS_NAME,
    (value) => {
      let newValue = value;
      if (moment(value).isValid()) {
        newValue = moment(value).format('DD.MM.YYYY HH:mm');
      }
      return newValue;
    },
    'date',
  );

  return {
    [DATE_WITH_HOURS_NAME]: withDefault(DateTimeParam, todayDateAndHours),
  };
};
