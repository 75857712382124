// 8 Люки смотровых колодцев и решетки водоприемных колодцевimport CardAbstract from 'app/components/card/common/CardAbstract';
import CardAbstract from 'app/components/card/common/CardAbstract';
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import { EndwiseField } from 'app/components/card/ogh/components/fields/numberFields/Endwise.Field/Endwise.Field';
import { AccessoryIdField } from 'app/components/card/ogh/components/fields/selectFields/AccessoryId.Field/AccessoryId.Field';
import { OdhSideIdField } from 'app/components/card/ogh/components/fields/selectFields/OdhSideId.Field';
import { DescriptionField } from 'app/components/card/ogh/components/fields/textFields/Description.Field';
import { OdhAxisField } from 'app/components/card/ogh/components/fields/textFields/OdhAxis.Field';
import {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import getAccessory from 'app/selectors/card/getAccesorry';
import getEnginConstructPlacement from 'app/selectors/card/getEnginConstructPlacement';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import isMandatory, { mandatoryFields } from 'app/utils/card/isMandatory';
import { transformValues } from 'app/utils/form/transformation';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import {
  IS_DIFF_HEIGHT_MARK_NAME,
  IsDiffHeightMarkRF,
} from 'core/form/reduxForm/fields/prepared/checkboxes/IsDiffHeightMark.RF';
import { OtherDocumentsRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/OtherDocuments.RF';
import { CardContainer } from 'core/uiKit/components/card.components/CardContainer';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { GroupContainer } from 'core/uiKit/components/card.components/GroupContainer';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';

import printOghCardHeader from './header/oghHeader';
import submitCard from './submit';
import VersionsTemplate from './versions/VersionsTemplate';

const types = ['manholes'];
const Versions = VersionsTemplate(types);

const tabs = [
  { id: 'characteristics-tab', name: 'Исходные данные' },
  { id: 'docs-tab', name: 'Документы' },
];

const propTypes = {
  card: PropTypes.object,
  createMode: PropTypes.bool,
  documents: PropTypes.array,
  editMode: PropTypes.bool,
  mode: PropTypes.object,
  required: PropTypes.bool,
};

/**
 * @returns {JSX}
 */
class ManholesCard extends React.Component {

  /**
   * Метод жизненного цикла рендер.
   *
   * @returns {JSX.Element}
   */
  render() {
    const {
      card,
      mode,
      createMode,
      editMode,
      required,
      placement,
      isEditCurrentObjectIsExternalSystem,
    } = this.props;

    return (
      <CardContainer>
        <CardAbstract>
          <div id="editor-card">
            {printOghCardHeader(card, mode)}
            {card && objectTabs(card.type_id)}

            <div id="props">
              <FieldsSplitColumns>
                <SelectRF
                  name={'engin_struct_type_id'}
                  required={isMandatory(
                    card.type_id,
                    'engin_struct_type_id',
                    required,
                  )}
                  label={'Тип'}
                  options={card.engin_struct_type}
                  disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
                />
                <ParentInfo />
                <IsDiffHeightMarkRF disabled={!editMode} />
              </FieldsSplitColumns>

              <Tabs tabs={tabs} />

              <div id="characteristics">
                <GroupContainer title={'Местоположение'}>
                  <FieldsSplitColumns>
                    <OdhAxisField />
                    <OdhSideIdField />
                    <EndwiseField />
                    <SelectRF
                      name={'placement_id'}
                      options={placement}
                      label={'Местоположение'}
                      disabled={true}
                    />
                  </FieldsSplitColumns>
                </GroupContainer>
                <GroupContainer title={'Характеристики'}>
                  <FieldsSplitColumns>
                    <AccessoryIdField />
                    <DescriptionField />
                  </FieldsSplitColumns>
                </GroupContainer>
              </div>
              <div hidden={true} id={'docs'}>
                <OtherDocumentsRF />
              </div>
            </div>
            <div hidden={true} id="versions">
              <Versions
                card={card}
                disabled={createMode}
                key={`versions-${card.root_id}`}
              />
            </div>
          </div>
        </CardAbstract>
      </CardContainer>
    );
  }
}

ManholesCard.propTypes = propTypes;

const dictFields = [
  'engin_struct_type_id',
  'placement_id',
  'odh_side_id',
  'accessory_id',
];
const numericFields = [{ name: 'endwise', type: 'decimal', zero: true }];

/**
 * Функция валидации.
 *
 * @param { object} values - Значения формы.
 * @returns {*}
 */
const validate = (values) => validateNumeric(values, numericFields);

const manholesCardDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

/**
 * Функция для создания атрибутов.
 *
 * @param {object} formValues - Значения формы.
 * @returns {object}
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

/**
 * Функция сохранения / создания паспорта.
 *
 * @param {object} formValues - Значения формы.
 * @returns {void}
 */
export function onSubmit(formValues) {
  const { card } = this.props;
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };

  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[card.type_id],
  );

  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, {
      dictFields,
      numericFields,
    }),
  );
}

/**
 * Получение значений из стейта.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {*}
 */
function mapStateToProps(state, props) {
  const parse = getParseAttribute(props);

  const result = {
    createMode: isCreateMode(state, props),
    editMode: isEditMode(state, props),
    enableReinitialize: true,
    initialValues: {
      [IS_DIFF_HEIGHT_MARK_NAME]: parse(IS_DIFF_HEIGHT_MARK_NAME),
      accessory_id: getAccessory(state, props.mode.createMode),
      customer_id: parse('customer_id'),
      description: parse('description'),
      endDate: parseDate(props.card.end_date),
      endwise: parse('endwise'),
      engin_struct_type_id: parse('engin_struct_type_id.engin_struct_type'),
      odh_axis: parse('odh_axis'),
      odh_side_id: parse('odh_side_id.odh_side'),
      owner_id: parse('owner_id'),
      parent_name: props.card.parent_info,
      placement_id: parse('placement_id.placement'),
      startDate: parseDate(props.card.start_date),
    },
    onSubmit: onSubmit.bind({ props }),
    placement: getEnginConstructPlacement(state, props),
    required: isRequired(state, props),
    validate,
  };
  return manholesCardDocumentsReducer(result, state, props);
}

/**
 * Получение значений из стейта.
 *
 * @param {object} dispatch - Dispatch.
 * @returns {void}
 */
const mapDispatchToProps = (dispatch) => ({

  /**
   * Функция изменения значения.
   *
   * @param {string} field - Имя поля.
   * @param {*} value - Значение на которое поменять.
   * @returns {void}
   */
  changeFieldValue: (field, value) => {
    dispatch(change('editorCard', field, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'editorCard' })(ManholesCard));
