import { AutocompleteAsync } from 'core/uiKit/inputs/selects/AutocompleteAsync';

import { fetchOwners } from './api';

export const OWNER_ID_NAME = 'owner_id';

/**
 * ## Балансодержатель.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const OwnerId = (props) => {
  return (
    <AutocompleteAsync
      label={'Балансодержатель'}
      {...props}
      fetchFunction={fetchOwners}
      name={OWNER_ID_NAME}
    />
  );
};
