import { Table } from 'core/uiKit/components/tables/Table';
import React from 'react';

import { TableOghChildrenProps } from '../../types';
import { getColumnsRelief } from './columns.Relief';
import { useSetupTableRelief } from './useSetup.Table.Relief';

/**
 * Компонент таблицы __Элементы организации рельефа__.
 *
 * @param {TableOghChildrenProps} props - Пропсы.
 * @returns JSX.
 * @example -----
 * <TableRelief typeId={typeId}/>
 */
export const TableRelief = (props: TableOghChildrenProps) => {
  const { table, isLoading, count } = useSetupTableRelief(props);

  return (
    <Table
      columns={getColumnsRelief()}
      data={table || []}
      isLoading={isLoading}
      total={count}
    />
  );
};
