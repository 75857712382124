import validateNumeric from 'app/components/card/common/validation/validateNumeric';

import { numericFields, validate } from './validate';
/* eslint-disable */
jest.mock('app/components/card/common/validation/validateNumeric');

const ARR = [
  { name: 'bord_begin', type: 'decimal', zero: true },
  { name: 'bord_end', type: 'decimal', zero: true },
  {
    name: 'area',
    type: 'decimal',
    positive: true,
    zero: false,
  },
  {
    name: 'distance',
    type: 'decimal',
    positive: true,
    zero: false,
  },
  {
    name: 'width_begin',
    type: 'decimal',
    positive: true,
    zero: false,
  },
  {
    name: 'width_end',
    type: 'decimal',
    positive: true,
    zero: false,
  },
  {
    name: 'auto_clean_area',
    type: 'decimal',
    positive: true,
    zero: false,
  },
  {
    name: 'manual_clean_area',
    type: 'decimal',
    positive: true,
    zero: false,
  },
  {
    name: 'no_clean_area',
    type: 'decimal',
    positive: true,
    zero: true,
  },
  { name: 'utn_area', type: 'decimal', positive: true },
];

 
describe('validate sidewalks', () => {
   
  describe('проверка numericFields', () => {
    it('должно быть равно этому массиву', () => {
      expect.hasAssertions();

      expect(numericFields).toStrictEqual(ARR);
    });
  });

   
  describe('validate', () => {
    it('validate должно вызватся с параметрами', () => {
      expect.hasAssertions();

      const NAME_FAKE = 'BOO';
      validate({ name: NAME_FAKE });

      expect(validateNumeric).toHaveBeenCalledWith({ name: NAME_FAKE }, ARR);
    });
  });
});
