import NoMatch from 'app/components/NoMatch';
import { RegistrySkeleton } from 'core/uiKit/components';
/* eslint-disable */
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

function lazyWithPreload(factory) {
  const Component = lazy(factory);
  Component.preload = factory;
  return Component;
}

const TitleList = lazyWithPreload(() =>
  import('./TitleList'),
);

 
export const TitleLIstRoute = () => {
  useEffect(() => {
    TitleList.preload();
  }, []);
  return (
    <Suspense fallback={<RegistrySkeleton />}>
      <Routes>
        <Route element={<TitleList />} path={`:id`} />
        <Route element={<NoMatch />} path={'*'} />
      </Routes>
    </Suspense>
  );
};
