import { Edit, IconButton, Tooltip } from 'core/uiKit/material-ui';
/* eslint-disable */
import React, { useState } from 'react';

import { DialogEditMovementCharacteristics } from '../DialogEdit.MovementCharacteristics';

/**
 *
 * @param root0
 * @param root0.id
 * @param root0.original
 */
export const renderEditRowCell =
  ({ id, original }) =>
  ({ input }) => {
    /**
     *
     * @param value
     */
    const onSave = (value) => {
      const arr = [...input.value];
      arr[id] = Object.fromEntries(
        // преобразовать в массив, затем map, затем fromEntries обратно объект
        Object.entries(value).map(([key, value]) => {
          if (key === 'traffic_character_list_note') {
            return [key, value];
          }
          return [key, +value];
        }),
      );

      input.onChange([...arr]);
    };
    const [isOpen, setIsOpen] = useState();

    return (
      <div>
        <Tooltip title="Редактировать">
          <IconButton size="small" onClick={() => setIsOpen(true)}>
            <Edit fontSize="inherit" />
          </IconButton>
        </Tooltip>
        <DialogEditMovementCharacteristics
          closeDialog={() => setIsOpen(false)}
          isOpen={isOpen}
          onClick={onSave}
          textHeader={'Редактирование'}
          value={original}
        />
      </div>
    );
  };
