import './Panel.scss';

import { switchServisMap } from 'core/utils/map/switchServisMap';

import { MapEditPanelDTW } from './MapEditPanel.DTW';
import { MapEditPanelEgip } from './MapEditPanel.Egip';

export default switchServisMap({
  dtw: MapEditPanelDTW,
  egip: MapEditPanelEgip,
});
