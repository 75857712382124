import React from 'react';

interface TabsCardItemProps {
  children: React.ReactNode;

  /**
   * Текст таба.
   */
  tabTitle: string;

  /**
   * Флаг скрывает таб, если это нужно (функционал скрытия происходит в функции getTabsFromChildren).
   */
  hide?: boolean;
}

/**
 * Item Таба.
 *
 * @param {TabsCardItemProps} props - Пропсы.
 * @returns JSX.Element.
 */
export const TabsCardOGHItem = (props: TabsCardItemProps) => {
  const { children, tabTitle } = props;
  return (
    <div className={'my-4'} id={tabTitle}>
      {children}
    </div>
  );
};
