import { useSetupMafTypeFilterField } from 'app/pages/registry/ogh/MafRfid/filters/MafType.Filter/useSetupMafTypeFilterField';
import { SubTypeIdFilter } from 'core/form/filterForm/components/autocomplete/SubTypeIdFilter';
import React from 'react';

/**
 * Фильтр __Тип__.
 *
 * @returns {JSX.Element}
 */
export const MafTypeFilterField = () => {
  const { disabled, odsType } = useSetupMafTypeFilterField();
  return <SubTypeIdFilter odsType={odsType} disabled={disabled} />;
};
