import CardAbstract from 'app/components/card/common/CardAbstract';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import { AreaField } from 'app/components/card/ogh/components/fields/numberFields/Area.Field';
import { BordBeginField } from 'app/components/card/ogh/components/fields/numberFields/BordBegin.Field';
import { BordEndField } from 'app/components/card/ogh/components/fields/numberFields/BordEnd.Field';
import { OdhSideIdField } from 'app/components/card/ogh/components/fields/selectFields/OdhSideId.Field';
import { DescriptionField } from 'app/components/card/ogh/components/fields/textFields/Description.Field';
import { OdhAxisField } from 'app/components/card/ogh/components/fields/textFields/OdhAxis.Field';
import { PlacementIdRFWrapper } from 'app/components/card/ogh/components/selects/PlacementIdRFWrapper';
import printOghCardHeader from 'app/components/card/ogh/header/oghHeader';
import { IsFilled } from 'app/components/card/ogh/ImprovementTerritoryCard/components/IsFilled';
import VersionsTemplate from 'app/components/card/ogh/versions/VersionsTemplate';
import filterDictBySprNumber from 'app/utils/card/filterDictBySprNumber';
import isMandatory from 'app/utils/card/isMandatory';
import { CheckboxRF } from 'core/form/reduxForm/fields/default/CheckboxRF';
import { KeyboardDatePickerRF } from 'core/form/reduxForm/fields/default/datePickers/KeyboardDatePickerRF';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import { TextFieldRF } from 'core/form/reduxForm/fields/default/TextFieldRF';
import { IsDiffHeightMarkRF } from 'core/form/reduxForm/fields/prepared/checkboxes/IsDiffHeightMark.RF';
import { ErzStateIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/ErzStateId.RF';
import { OtherDocumentsRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/OtherDocuments.RF';
import { EaisIdRF } from 'core/form/reduxForm/fields/prepared/textField/EaisId.RF';
import { IdRfidRF } from 'core/form/reduxForm/fields/prepared/textField/IdRfid.RF';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/textField/TotalAreaGeo.RF';
import { CardContainer } from 'core/uiKit/components/card.components/CardContainer';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { GroupContainer } from 'core/uiKit/components/card.components/GroupContainer';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import React from 'react';

const types = ['maf'];
const Versions = VersionsTemplate(types);

const tabs = [
  {
    id: 'characteristics-tab',
    name: 'Исходные данные',
  },
  {
    id: 'docs-tab',
    name: 'Документы',
  },
];

/**
 * Компонент MafComponent.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - Реакт компонент.
 * @example ----
 */
const MafComponent = (props) => {

  /**
   * Компонент getConvElementTypeOptions.
   *
   * @returns {*} - Жопа.
   * @example ----
   */
  function getConvElementTypeOptions() {
    const { type_id, conv_element_type, viewing } = props.card;
    if (viewing) {
      return conv_element_type;
    }
    return filterDictBySprNumber(conv_element_type, type_id);
  }

  const {
    mode,
    card,
    createMode,
    editMode,
    required,
    formValues,
    isEditCurrentObjectIsExternalSystem,
  } = props;

  return (
    <CardContainer>
      <CardAbstract>
        <div id="editor-card">
          {printOghCardHeader(card, mode)}
          {card && objectTabs(card.type_id)}
          <FieldsSplitColumns>
            <SelectRF
              label={'Тип'}
              name={'conv_element_type_id'}
              options={getConvElementTypeOptions()}
              required={isMandatory(
                card.type_id,
                'conv_element_type_id',
                required,
              )}
              disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
            />
            <IsDiffHeightMarkRF disabled={!editMode} />
            <ParentInfo />
          </FieldsSplitColumns>
          <div id="props">
            <Tabs tabs={tabs} />

            <div id="characteristics">
              <GroupContainer title={'Местоположение'}>
                <FieldsSplitColumns>
                  <PlacementIdRFWrapper disabled={true} withAll={false} />
                  <OdhAxisField />
                  <OdhSideIdField />
                  <BordBeginField />
                  <BordEndField />
                </FieldsSplitColumns>
              </GroupContainer>

              <GroupContainer title={'Характеристики'}>
                <FieldsSplitColumns>
                  <TextFieldRF
                    label={'Краткая характеристика'}
                    name={'property'}
                    disabled={!editMode}
                  />
                  <AreaField />
                  <TotalAreaGeoRF disabled={true} decimalScale={2} />
                  <IdRfidRF disabled={!editMode} />
                  <KeyboardDatePickerRF
                    label={'Дата установки'}
                    name={'installation_date'}
                    disabled={!editMode}
                  />
                  <KeyboardDatePickerRF
                    label={'Срок эксплуатации'}
                    name={'lifetime'}
                    disabled={!editMode}
                  />
                  <KeyboardDatePickerRF
                    label={'Гарантийный срок'}
                    name={'guarantee_period'}
                    disabled={!editMode}
                    minDate={formValues?.installation_date || ''}
                  />
                  <EaisIdRF disabled={true} />
                  <IsFilled />
                  <ErzStateIdRF disabled={true} />
                  <KeyboardDatePickerRF
                    label={'Дата состояния (из ЕРЗ)'}
                    name={'erz_date'}
                    disabled={true}
                  />
                </FieldsSplitColumns>
              </GroupContainer>
              <CheckboxRF
                label={'Включать в общую площадь ОДХ'}
                name={'is_object_area'}
                disabled={true}
              />
              <DescriptionField />
            </div>
            <div hidden={true} id={'docs'}>
              <OtherDocumentsRF />
            </div>
          </div>
          <div hidden={true} id="versions">
            <Versions
              card={card}
              disabled={createMode}
              key={`versions-${card.root_id}`}
            />
          </div>
        </div>
      </CardAbstract>
    </CardContainer>
  );
};

export default MafComponent;
