import { functions } from './functions';

/**
 * Функция форматирования данных для отправки на бэк.
 *
 * @param {string} key - Имя-ключ инпута.
 * @param {*} value - Значения ипнута.
 * @returns {{value: *, key: string}} - Жопа.
 * @example
 * const { value: newValue, key: newKey } = preparation(key, value);
 */
export const preparation = (key, value) => {
  const obj = functions[key];

  if (!obj) {
    return { key, value };
  }

  const { fn, newKey } = obj;
  let newValue = value;

  if (typeof fn === 'function') {
    newValue = fn(value);
  }

  return {
    key: typeof newKey === 'function' ? newKey(value) : newKey,
    value: newValue,
  };
};
