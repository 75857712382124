import { RANDOM_WORD } from 'core/forTesting/constants';

import { additionalInformationReducer } from './additionalInformation.Reducer';
 

/* eslint-disable */
describe('file additionalInformation.Reducer.spec', () => {
  it('should', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const r = {
      ['result_' + RANDOM_WORD]: 'result_' + RANDOM_WORD,
      initialValues: {
        ['initialValues_result_' + RANDOM_WORD]:
          'initialValues_result_' + RANDOM_WORD,
        ['initialValues_result_2__' + RANDOM_WORD]:
          'initialValues_result_2__' + RANDOM_WORD,
      },
    };
    const state = { ['state_' + RANDOM_WORD]: RANDOM_WORD };
    const props = {
      jopa: { RANDOM_WORD },
      card: {
        ['values_result_' + RANDOM_WORD]: 'values_result_' + RANDOM_WORD,
        ['values_result_2__' + RANDOM_WORD]: 'values_result_2__' + RANDOM_WORD,
        attribute: {
          ['valuesAttribute_result_' + RANDOM_WORD]:
            'values_result_2__' + RANDOM_WORD,
          place_descr: 'place_descr_' + RANDOM_WORD,
          owner_inn: 'owner_inn_' + RANDOM_WORD,
          owner_kpp: 'owner_kpp_' + RANDOM_WORD,
          owner_ogrn: 'owner_ogrn_' + RANDOM_WORD,
          owner_phone: 'owner_phone_' + RANDOM_WORD,
          owner_link: 'owner_link_' + RANDOM_WORD,
          park_descr: 'park_descr_' + RANDOM_WORD,
          park_link: 'park_link_' + RANDOM_WORD,
          park_phone: 'park_phone_' + RANDOM_WORD,
          oper_fullname: 'oper_fullname_' + RANDOM_WORD,
          oper_phone: 'oper_phone_' + RANDOM_WORD,
          oper_link: 'oper_link_' + RANDOM_WORD,
          schedule: 'schedule_' + RANDOM_WORD,
          schedule_days: 'schedule_days_' + RANDOM_WORD,
          schedule_hours: 'schedule_hours_' + RANDOM_WORD,
          schedule_comm: 'schedule_comm_' + RANDOM_WORD,
          park_geo: 'park_geo_' + RANDOM_WORD,
          add_inform: 'add_inform_' + RANDOM_WORD,
        },
      },
    };
    // Act
    const result = additionalInformationReducer(r, state, props);
    // Assert
    expect(result).toStrictEqual({
      ['result_' + RANDOM_WORD]: 'result_' + RANDOM_WORD,
      initialValues: {
        ['initialValues_result_' + RANDOM_WORD]:
          'initialValues_result_' + RANDOM_WORD,
        ['initialValues_result_2__' + RANDOM_WORD]:
          'initialValues_result_2__' + RANDOM_WORD,
        place_descr: 'place_descr_' + RANDOM_WORD,
        owner_inn: 'owner_inn_' + RANDOM_WORD,
        owner_kpp: 'owner_kpp_' + RANDOM_WORD,
        owner_ogrn: 'owner_ogrn_' + RANDOM_WORD,
        owner_phone: 'owner_phone_' + RANDOM_WORD,
        owner_link: 'owner_link_' + RANDOM_WORD,
        park_descr: 'park_descr_' + RANDOM_WORD,
        park_link: 'park_link_' + RANDOM_WORD,
        park_phone: 'park_phone_' + RANDOM_WORD,
        oper_fullname: 'oper_fullname_' + RANDOM_WORD,
        oper_phone: 'oper_phone_' + RANDOM_WORD,
        oper_link: 'oper_link_' + RANDOM_WORD,
        schedule: 'schedule_' + RANDOM_WORD,
        schedule_days: 'schedule_days_' + RANDOM_WORD,
        schedule_hours: 'schedule_hours_' + RANDOM_WORD,
        schedule_comm: 'schedule_comm_' + RANDOM_WORD,
        park_geo: 'park_geo_' + RANDOM_WORD,
        add_inform: 'add_inform_' + RANDOM_WORD,
      },
    });
  });
});
