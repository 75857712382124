import { get } from 'app/api/crud/get';
/* eslint-disable */
import { uploadFile } from 'app/api/requests/uploadFiles';
import { SaveFFDialog } from 'core/form/finalForm/components';
import { getValidator } from 'core/form/finalForm/utils/validate';
import { toast } from 'core/uiKit/components';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import { Field } from 'react-final-form';

import FileUpload from './FileUpload';

/**
 *
 * @param values
 */
const validate = (values) => {
  const { setRequired, hasErrors, getErrors } = getValidator(values);
  setRequired('file');
  return hasErrors() ? getErrors() : null;
};

/* eslint-disable */
export const DialogDownloadOperation = ({ onClose, isOpen }) => {
  const { objectId: basePgmRootId } = useGetObjectId();

  const [
    sendOperationsIncomingAndOutgoingPGR,
    { isLoading: isLoadingOperationsIncomingAndOutgoingPGR },
  ] = useMutationAdaptor((values) => get('pgm/import', values), {
    onSuccess: () => {
      toast.successDyingLong('Данные успешно загружены');
      onClose();
    },
    onError: (value) => {
      toast.errorDyingLong(value.message);
    },
  });

  const [send, { isLoading }] = useMutationAdaptor(
    ({ file }) => uploadFile(file),
    {
      onSuccess: ({ message, fileId }) => {
        sendOperationsIncomingAndOutgoingPGR({
          basePgmRootId,
          fileId,
        });
      },
      onError: (value) => {
        toast.errorDyingLong(value.message);
      },
    },
  );

  return (
    <>
      <SaveFFDialog
        textHeader={'Загрузить операции из csv-файла'}
        isOpen={isOpen}
        onSubmit={send}
        validate={validate}
        closeDialog={onClose}
        Body={<Field component={FileUpload} name={'file'} />}
        isLoading={isLoading || isLoadingOperationsIncomingAndOutgoingPGR}
        textButtonSave={'Загрузить'}
      />
    </>
  );
};
