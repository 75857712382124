import { MutableRefObject, useEffect } from 'react';

import { GeometryUtils } from '..';
import { MapService } from '../MapService';
import { Marker, Polygon, Polyline, RawGeometry } from '../types.d';

/**
 * Вешает обработчик событий движения мыши на карту, но функциональность заключается
 * в определении была ли мышь наведена на геометрию.
 *
 * @param mapUtils - Объект с методами и объектами для работы с картой.
 * @param mapUtils.isDisabled - Флаг отключения показа подсказки при клике.
 * @param mapUtils.mapService - Объект с методами для работы с картой.
 * @param mapUtils.onHoverHintElementRef - *.
 * @param mapUtils.geometryUtils - Объект с методами для работы с геометриями.
 */
export const useShowHintOnHover = ({
  isDisabled = false,
  mapService,
  onHoverHintElementRef,
  geometryUtils,
}: {
  isDisabled: boolean;
  mapService: MapService | null;
  onHoverHintElementRef: MutableRefObject<{
    closeHint: VoidFunction;
    element: HTMLDivElement | null;
    setPosition(
      hoveredObject: RawGeometry | null,
      x0: number,
      y0: number,
    ): void;
  } | null>;
  geometryUtils: GeometryUtils | null;
}) => {
  useEffect(() => {
    if (!mapService) return;

    if (isDisabled) {
      const hoveredObject = mapService.hoveredObject;
      mapService?.setHoveredGeometries(null);
      mapService?.drawHovered(null, hoveredObject);
      onHoverHintElementRef.current?.closeHint();
      return;
    }

    if (mapService?.isMapLoaded) {
      let [x0, y0] = [0, 0];
      const [coordsX, coordsY] = [0, 0];
      let currentHovered: Polygon | Polyline | Marker | null = null;

      /**
       * Устанавливает позицию для подсказки.
       *
       * @param evt - Объект события.
       */
      const setHintOnHoverPosition = (evt: MouseEvent) => {
        if (!geometryUtils) return;

        const { offsetX, offsetY } = evt;
        const hovered = mapService.hoveredObject;
        const clicked = mapService.clickedObject;
        const isHoveredEqualClicked =
          hovered && hovered.userData.id === clicked?.userData.id;
        if (!hovered || isHoveredEqualClicked) {
          [x0, y0] = [0, 0];
        } else {
          [x0, y0] = [offsetX, offsetY];
        }

        if (currentHovered) {
          onHoverHintElementRef.current?.setPosition(
            isHoveredEqualClicked ? null : hovered,
            x0,
            y0,
          );
        }

        // @ts-ignore
        currentHovered = hovered;
        return;
      };

      /**
       * Обработчик.
       *
       */
      const mapMoveEventHandler = () => {
        setHintOnHoverPosition({
          offsetX: coordsX,
          offsetY: coordsY,
        } as MouseEvent);
      };

      const canvas = mapService.map.getCanvas();

      canvas.addEventListener('mousemove', setHintOnHoverPosition);
      canvas.addEventListener('move', mapMoveEventHandler);
      canvas.addEventListener('zoomend', mapMoveEventHandler);

      return () => {
        canvas.removeEventListener('mousemove', setHintOnHoverPosition);
        canvas.removeEventListener('move', mapMoveEventHandler);
        canvas.removeEventListener('zoomend', mapMoveEventHandler);
      };
    }
  }, [isDisabled, geometryUtils, mapService, onHoverHintElementRef]);
};
