import { validateMandatoryProperties } from 'app/utils/validation';
import { log } from 'core/utils/log';
import { SubmissionError } from 'redux-form';

/* eslint-disable */
export default function validateRequiredFieldsAsync(
  formValues,
  mandatoryFields,
  customValidation,
  customValidation2,
  options,
) {
  const errors = validateMandatoryProperties(formValues, mandatoryFields);
  if (mandatoryFields.includes('address_list')) {
    Object.assign(errors, validateFullAddress(formValues.address_list));
  }
  if (customValidation) {
    Object.assign(errors, customValidation(formValues));
  }
  if (customValidation2) {
    Object.assign(errors, customValidation2(formValues));
  }
  if (Object.keys(errors).length) {
    log.banana('ошибка валидации', () => log.table(errors));
    if (options && options.alertErrors) {
      options.alertErrors(errors);
    }
    throw new SubmissionError(errors);
  }
}

function validateFullAddress(value) {
  const errors = {};
  const reqAddress = Array.isArray(value) ? value[0] : value;
  if (
    reqAddress === null ||
    reqAddress === undefined ||
    (reqAddress.street_id === undefined &&
      reqAddress.address_id === undefined &&
      reqAddress.district_id === undefined &&
      reqAddress.okrug_id === undefined)
  ) {
    errors.address_list = 'обязательный список';
  }
  return errors;
}
