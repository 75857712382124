import { QUANTITY_NAME } from 'core/uiKit/preparedInputs/textField/Quantity';
import React from 'react';
import { Field } from 'react-final-form';

import { QuantityWithFF } from './QuantityWithFF';

/**
 * Количество.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const QuantityFf = ({ ...otherProps }) => {
  return (
    <Field component={QuantityWithFF} name={QUANTITY_NAME} {...otherProps} />
  );
};
