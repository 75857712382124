import { useGetParamsUrl } from 'core/utils/hooks/useGetParamsUrl';

/**
 * Получаем из урла id карточки objectId  или и id и id children.
 *
 * @category hooks
 * @description `Получаем из урла id карточки - objectId
 * или и id и id children также флаг
 * что есть children objectId = = childId || id.
 * @returns {{id: {Number}, childId: {Number}, objectId: number, isChild: {Boolean}}}
 * @example
 * const {id: number, childId: number, objectId: number, isChild: boolean} = useGetObjectId();
 */
export const useGetObjectId = () => {
  const { childId, id } = useGetParamsUrl();

  const objectId = childId || id;
  const isChild =
    typeof objectId !== 'undefined' ? objectId === childId : false;
  return {
    childId,
    id,
    isChild,
    objectId,
  };
};
