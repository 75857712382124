/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import './itemStyle.scss';

 
export const Item = ({ link = '', name = '', active }) => {
  const pathRoute = window.location.pathname;
  if (!link) {
    return (
      <p className={'m-0 subobject-panel__item'} key={link + name}>
        {name}
      </p>
    );
  }

  const isActive = active || link === pathRoute;
  return (
    <li className={'subobject-panel__item'} key={link + '_li_'}>
      <Link
        className={cn('subobject-panel__item', {
          'subobject-panel__item-active': isActive,
        })}
        key={link + '_link_'}
        to={'../' + link || '#'}
      >
        {name}
      </Link>
    </li>
  );
};
