import { BIN } from 'app/constants/oghTypes';
import getOghTypeId from 'app/selectors/card/getOghTypeId';
import { createSelector } from 'reselect';

const withoutGeometry = [BIN];

 
export default createSelector([getOghTypeId], (id) => {
  return id && !withoutGeometry.includes(id);
});
