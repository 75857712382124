import { Table } from 'core/uiKit/components/tables/Table';
import React from 'react';

import { TableOghChildrenProps } from '../../types';
import { getColumnsOtherOks } from './columns.OtherOks';
import { useSetupTableOtherOks } from './useSetup.Table.OtherOks';

/**
 * Компонент таблицы __Иной объект капитального строительства__.
 *
 * @param {TableOghChildrenProps} props - Пропсы.
 * @returns JSX.
 * @example -----
 * <TableFunctionality
 *  typeId={typeId}
 *  parentTypeId={3}
 * />
 */
export const TableOtherOks = (props: TableOghChildrenProps) => {
  const { table, isLoading, count } = useSetupTableOtherOks(props);

  return (
    <Table
      columns={getColumnsOtherOks()}
      data={table || []}
      isLoading={isLoading}
      total={count}
    />
  );
};
