import { useGetObjectId as useGetObjectIdD } from './useGetObjectId';
import { useQueryParams as useQueryParams_D } from './useQueryParams';

/**
 * @deprecated
 * @see заюзай другой импорт
 * @type {*}
 */
const useQueryParams = useQueryParams_D;

export * from './objectInArrayParam';
export * from './stringWithAllParam';
export * from './useDownloadFile';
export * from './useGetOghTypes';

/**
 * @deprecated
 * @see src/core/utils/hooks/useGetObjectId
 */

export const useGetObjectId = useGetObjectIdD;

export { useQueryParams };
