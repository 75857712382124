import { withFinalForm } from 'core/form/finalForm/helpers/withFinalForm';
import {
  CLEAN_TYPE_ID_NAME,
  CleaningTypeId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/CleaningTypeId';
import React from 'react';
import { Field } from 'react-final-form';

import { usePreparationCleaningTypeIdFF } from './usePreparation.CleaningTypeId.FF';

/**
 * Select FF __Способ уборки__.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const CleaningTypeIdFF = (props) => {
  usePreparationCleaningTypeIdFF();
  return (
    <Field
      {...props}
      name={CLEAN_TYPE_ID_NAME}
      component={withFinalForm(CleaningTypeId)}
    />
  );
};
