import {
  childrenColors,
  copyColors,
  districtBoundariesColors,
  intersectedColors,
  neighboursColors,
  ordersColors,
  originalColors,
  parentsColors,
  reonColors,
  selfColors,
} from 'app/constants/colors';
import {
  LINESTRING,
  POINT,
  POLYGON,
} from 'app/constants/geometryTypes';
import * as layerNames from 'app/constants/layers';

import { egip } from '../../utils/helpers/egip';
import { ol } from '../../utils/helpers/ol';
import { BLUE } from './constants/markerTypes';
import { getPointStyle } from './pointMarkers';

 
/**
 * Функция получения стилей diffCopyStyle.
 *
 * @returns {object} - Объект стилей.
 * @example -
 */
const diffCopyStyle = () => getStyle(copyColors);

 
/**
 * Функция получения стилей diffOriginalStyle.
 *
 * @returns {object} - Объект стилей.
 * @example -
 */
const diffOriginalStyle = () =>
  getStyle(originalColors, null, BLUE);

/* eslint-disable */
/**
 * Функция getAreaTextStyle.
 *
 * @param {*} feature - Жопа.
 * @returns {object} - Объект стилей.
 * @example -
 */
const getAreaTextStyle = (feature) => {
  const geometry = feature.getGeometry();

  const area = formatArea(geometry);

  return new ol.style.Style({
    text: new ol.style.Text({
      text: area,
      font: '12px sans-serif',
      offsetX: 20,
      offsetY: -16,
      fill: new ol.style.Fill({
        color: '#000',
      }),
      stroke: new ol.style.Stroke({
        width: 1,
        color: '#fff',
      }),
      backgroundStroke: new ol.style.Stroke({
        color: 'lightgrey',
        width: 2,
      }),
      backgroundFill: new ol.style.Fill({
        color: 'white',
      }),
    }),
  });
};

/* eslint-disable */
/**
 * Функция getLengthTextStyle.
 *
 * @param {*} feature - Жопа.
 * @returns {object} - Объект стилей.
 * @example -
 */
const getLengthTextStyle = (feature) => {
  const geometry = feature.getGeometry();

  const length = formatLength(geometry);

  return new ol.style.Style({
    text: new ol.style.Text({
      text: length,
      textAlign: 'left',
      textBaseline: 'right',
      font: '12px sans-serif',
      offsetX: 20,
      offsetY: -16,
      fill: new ol.style.Fill({
        color: '#000',
      }),
      stroke: new ol.style.Stroke({
        width: 1,
        color: '#fff',
      }),
      backgroundStroke: new ol.style.Stroke({
        color: 'lightgrey',
        width: 2,
      }),
      backgroundFill: new ol.style.Fill({
        color: 'white',
      }),
    }),
  });
};

 
/**
 * FormatArea.
 *
 * @param {*} polygon - Полигон.
 * @returns {string} - Жопа.
 * @example - Жопа.
 */
const formatArea = (polygon) => {
  const area = ol.sphere.getArea(polygon);
  return `${Math.round(area * 100) / 100} кв.м`;
};

 
/**
 * Функция formatLength.
 *
 * @param {*} line - Линия.
 * @returns {string} - Жопа.
 * @example - Жопа.
 */
const formatLength = (line) => {
  const length = ol.sphere.getLength(line);
  return `${Math.round(length * 100) / 100} м`;
};

 
/**
 * Функция getTextStyle.
 *
 * @param {*} feature - Линия.
 * @returns {* | null} - Жопа.
 * @example - Жопа.
 */
const getTextStyle = (feature) => {
  const geometryType = feature.getGeometry().getType();

  if (geometryType === LINESTRING) {
    return getLengthTextStyle(feature);
  } else if (geometryType === POLYGON) {
    return getAreaTextStyle(feature);
  } else {
    return null;
  }
};

/* eslint-disable */
/**
 * Функция getDrawingStyles.
 *
 * @returns {object} - Жопа.
 * @example - Жопа.
 */
const getDrawingStyles = () => {
  const white = [255, 255, 255, 1];
  const blue = [0, 153, 255, 1];
  const width = 3;

  return {
    [POINT]: [
      new ol.style.Style({
        image: new ol.style.Circle({
          radius: width * 2,
          fill: new ol.style.Fill({
            color: blue,
          }),
          stroke: new ol.style.Stroke({
            color: white,
            width: width / 2,
          }),
        }),
        zIndex: 1000,
      }),
    ],
    [LINESTRING]: [
      new ol.style.Style({
        stroke: new ol.style.Stroke({
          color: white,
          width: width + 2,
        }),
      }),
      new ol.style.Style({
        stroke: new ol.style.Stroke({
          color: blue,
          width: width,
        }),
      }),
    ],
    [POLYGON]: [
      new ol.style.Style({
        fill: new ol.style.Fill({
          color: [255, 255, 255, 0.5],
        }),
      }),
    ],
  };
};

 
/**
 * Функция drawingStyle.
 *
 * @param {*} feature - Жопа.
 * @returns {* | null} - Жопа.
 * @example - Жопа.
 */
export const drawingStyle = (feature) => {
  const geometryType = feature.getGeometry().getType();
  const drawingStyles = getDrawingStyles();

  const result = [...drawingStyles[geometryType]];

  const textStyle = getTextStyle(feature);

  textStyle && result.push(textStyle);

  return result;
};

 
/**
 * Функция foregroundStyle.
 *
 * @param {*} feature - Жопа.
 * @returns {*} - Жопа.
 * @example - Жопа.
 */
const foregroundStyle = (feature) => {
  const oghTypeId = feature.get('oghTypeId');

  const result = [...getStyle(selfColors, oghTypeId)];

  const textStyle = getTextStyle(feature);

  textStyle && result.push(textStyle);

  return result;
};

 
/**
 * Функция intersectionsStyle.
 *
 * @param {*} feature - Жопа.
 * @returns {*} - Жопа.
 * @example - Жопа.
 */
const intersectionsStyle = (feature) => {
  const oghTypeId = feature.get('oghTypeId');
  return getStyle(intersectedColors, oghTypeId);
};

 
/**
 * Функция neighboursStyle.
 *
 * @param {*} feature - Жопа.
 * @returns {*} - Жопа.
 * @example - Жопа.
 */
const neighboursStyle = (feature) => {
  const oghTypeId = feature.get('oghTypeId');
  return getStyle(neighboursColors, oghTypeId, BLUE);
};

 
/**
 * Функция reonStyle отвечает за стили полигона реон.
 *
 * @returns {object} - Жопа.
 * @example - Жопа.
 */
const reonStyle = () => getStyle(reonColors);

 
/**
 * Функция districtBoundariesStyle отвечает за стили полигона district.
 *
 * @returns {object} - Жопа.
 * @example - Жопа.
 */
const districtBoundariesStyle = () =>
  getStyle(districtBoundariesColors);

 
/**
 * Функция parentsStyle.
 *
 * @param {*} feature - Жопа.
 * @returns {*} - Жопа.
 * @example - Жопа.
 */
const parentsStyle = (feature) => {
  const oghTypeId = feature.get('oghTypeId');
  return getStyle(parentsColors, oghTypeId, BLUE);
};

 
/**
 * Функция childrenStyle.
 *
 * @param {*} feature - Жопа.
 * @returns {*} - Жопа.
 * @example - Жопа.
 */
const childrenStyle = (feature) => {
  const oghTypeId = feature.get('oghTypeId');
  return getStyle(childrenColors, oghTypeId, BLUE);
};

 
/**
 * Функция ordersStyle.
 *
 * @returns {*} - Жопа.
 * @example - Жопа.
 */
const ordersStyle = () =>
  getStyle(ordersColors, null, BLUE);

 
/**
 * Функция getStyle.
 *
 * @param {string} colors - Цвет.
 * @param {string | number} oghTypeId - Тайп id.
 * @param {*} markerType - Жопа.
 * @returns {Array} - Жопа.
 * @example const ordersStyle = () => getStyle(ordersColors, null, BLUE);
 */
const getStyle = (colors, oghTypeId, markerType) => {
  const pointStyle = getPointStyle(oghTypeId, markerType);
  const baseStyle = new ol.style.Style({
    fill: new ol.style.Fill({
      color: colors.fillColor,
    }),
    stroke: new ol.style.Stroke({
      color: colors.color,
      width: 2,
    }),
  });
  return [baseStyle, pointStyle];
};

 
/**
 * Функция sortOrders.
 *
 * @param {*} feature1 - Жопа.
 * @param {*} feature2 - Жопа.
 * @returns {number} - Жопа.
 * @example
 * sortOrders(feature1, feature2)
 */
const sortOrders = (feature1, feature2) => {
  const date1 = feature1.get('workstartdate');
  const date2 = feature2.get('workstartdate');
  return date1 > date2 ? 1 : -1;
};

/* eslint-disable */
/**
 * Функция sortNeighbours.
 *
 * @param {*} feature1 - Жопа.
 * @param {*} feature2 - Жопа.
 * @returns {number} - Жопа.
 * @example
 * sortNeighbours(feature1, feature2)
 */
const sortNeighbours = (feature1, feature2) => {
  const stateId1 = feature1.get('stateId');
  const stateId2 = feature2.get('stateId');
  return stateId1 < stateId2 ? 1 : -1;
};

const mapLayers = [
  {
    id: layerNames.DIFF_ORIGINAL,
    zIndex: 100,
    style: diffOriginalStyle,
  },
  {
    id: layerNames.DIFF_COPY,
    zIndex: 100,
    style: diffCopyStyle,
  },
  {
    id: layerNames.FOREGROUND,
    zIndex: 100,
    style: foregroundStyle,
  },
  {
    id: layerNames.CHILDREN,
    zIndex: 100,
    style: childrenStyle,
  },
  {
    id: layerNames.NEIGHBOURS,
    zIndex: 100,
    style: neighboursStyle,
    sort: sortNeighbours,
  },
  {
    id: layerNames.ORDERS,
    zIndex: 100,
    style: ordersStyle,
    sort: sortOrders,
  },
  { id: layerNames.REON, zIndex: 100, style: reonStyle },
  {
    id: layerNames.DISTRICT_BOUNDARIES,
    zIndex: 100,
    style: districtBoundariesStyle,
  },
  {
    id: layerNames.PARENTS,
    zIndex: 20,
    style: parentsStyle,
  },
  {
    id: layerNames.INTERSECTIONS,
    zIndex: 10,
    style: intersectionsStyle,
  },
];

/* eslint-disable */
/**
 * Функция createCustomLayers.
 *
 * @returns {*} - Жопа.
 * @example -
 */
const createCustomLayers = () =>
  mapLayers.map((item) => {
    return egip.layers.createVectorLayer({
      id: item.id,
      zIndex: item.zIndex,
      style: item.style,
      type: 'custom',
      source: egip.layers.createVectorSource({}),
      ...(item.sort && { renderOrder: item.sort }),
    });
  });

export default createCustomLayers;
