import Legend from 'app/components/common/Legend';
import formatRootId from 'app/utils/formatRootId';
import cn from 'classnames';
import { Table } from 'core/uiKit/components/tables/Table';
import PropTypes from 'prop-types';
import React from 'react';

import { filterTreeDiff } from './utils';

/**
 * NestedObjectsDiff.
 *
 * @param {*} root0 - A.
 * @param {*} root0.treediff - A.
 * @param {*} root0.etalon - A.
 * @param {*} root0.copy - A.
 * @returns {*}
 */
const NestedObjectsDiff = ({ treediff, etalon, copy }) => {
  const columns = [
    {
      Header: '№ п/п',
      accessor: 'index',
    },
    {
      Header: 'Объект',
      accessor: 'type_name',
    },
    {
      Header: `${formatRootId(copy.id)} (${copy.object_status_name})`,
      accessor: 'copy_object',
    },
    {
      Header: `${formatRootId(etalon.id)} (${etalon.object_status_name})`,
      accessor: 'original_object',
    },
    {
      Header: '',
      accessor: 'prop_operation',
    },
  ];

  const ftd = filterTreeDiff(treediff) || [];

  const data = ftd.map(({ operation, ...props }, index) => ({
    index: index + 1,
    prop_operation: (
      <div
        dangerouslySetInnerHTML={{
          __html: '<div class=legend_' + operation + '></div>',
        }}
      />
    ),
    ...props,
  }));

  return (
    <div className={cn('d-flex flex-column')}>
      <Table
        columns={columns}
        data={data}
        sortable_columns={[]}
        stickHeader={true}
      />

      <Legend
        legend={[
          {
            className: 'legend_add',
            value: 'новое',
          },
          {
            className: 'legend_del',
            value: 'удаленное',
          },
          {
            className: 'legend_update',
            value: 'отличается',
          },
          {
            className: 'legend_move',
            value: 'перемещено',
          },
        ]}
      />
    </div>
  );
};

NestedObjectsDiff.propTypes = {
  className: PropTypes.string,
  copy: PropTypes.object,
  etalon: PropTypes.object,
  treediff: PropTypes.array,
};

export default NestedObjectsDiff;
