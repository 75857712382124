import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const IMPROVEMENT_CATEGORY_ID = 'improvement_category_id';

/**
 * ## Жизненная форма.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const ImprovementCategoryId = (props) => {
  const { improvementCategory = [] } = useGetCurrentCard();
  return (
    <Select
      {...props}
      options={improvementCategory}
      label={'Категория благоустройства'}
      name={IMPROVEMENT_CATEGORY_ID}
    />
  );
};
