import { Map, MapPointerEvent } from '@2gis/mapgl/types';
import bbox from '@turf/bbox';
import {
  bearing as turfBearing,
  center,
  distance as turfDistance,
  lineString,
  point,
  polygon,
  transformTranslate,
} from '@turf/turf';

import { GeometryUtils } from '../../..';
import { EditorShapeOnChangeEvent, GeometryTypes } from '../../../types.d';
import ShapeEditor, { isGeometryPolygon } from '../..';
import Shape from '../Controls/Shape';
import Square from '../Controls/Square';

const CURSOR_TYPE = 'all-scroll';

/**
 * Коньрол для перемещения геометрии.
 *
 * @param map - Карта.
 * @param coordinates - Координаты точки.
 * @returns Объект с методами для перемещения геометрии.
 */
export default class Mover {
  public shape: Shape<Mover>;

  public map: Map;

  public geometryUtils: GeometryUtils;

  public shapeEditor: ShapeEditor;

  public isHovered: boolean = false;

  private zoomEndHandler: () => void;

  /**
   * Создание иконки для перемещения геометрии.
   *
   * @param map - Эксземпляр карты.
   * @param coordinates - Координаты точки.
   * @param geometryUtils - Утилиты геометрии.
   * @param shapeEditor - Редактор геометрий.
   */
  constructor(
    map: Map,
    coordinates: number[],
    geometryUtils: GeometryUtils,
    shapeEditor: ShapeEditor,
  ) {
    const iconSize: [number, number] = [8, 8];

    this.map = map;
    this.geometryUtils = geometryUtils;
    this.shapeEditor = shapeEditor;
    this.shape = new Square(map, {
      coordinates,
      cursor: CURSOR_TYPE,
      iconExtraShift: 0,
      iconSize,
      mapService: this.shapeEditor.mapService,
      size: iconSize,
      userData: {
        control: this,
        coordinates,
        coordinatesToCheckMouseEvent: [],
        type: GeometryTypes.Point,
      },
    });

    this.zoomEndHandler = this.onZoomEnd.bind(this);

    map.on('zoomend', this.zoomEndHandler);
  }

  /**
   * Устанавливает стиль курсора при наведении на мувер.
   *
   * @returns {void}
   */
  setCursorStyle() {
    this.shape.setCursorStyle();
  }

  /**
   * Сбрасывает стиль курсора при наведении на мувер.
   *
   * @returns {void}
   */
  resetCursorStyle() {
    this.shape.setCursorStyle(null);
  }

  /**
   * Обработчик изменения размера геометрии.
   *
   * @returns {void}
   */
  onZoomEnd() {
    const coordinates = this.shape.getGeometry()?.getCoordinates();

    if (coordinates) {
      this.shape.updateCoordinatesToCheckMouseEvent(coordinates);
    }
  }

  /**
   * Обработчик изменения позиции мувера.
   *
   * @param args - Аргументы.
   * @param args.box - Границы геометрии.
   * @param args.controls - Объект контроллеров изменения геометрии.
   * @param args.geometry - Геометрия.
   * @param args.coordinates - Координаты точки.
   * @returns {Array<number>|Array<Array<number>>} Обновленная геометрия.
   */
  onChange({
    box,
    controls,
    geometry,
    coordinates,
  }: EditorShapeOnChangeEvent<Mover>) {
    this.shape.updateCoordinates({ lngLat: coordinates });

    const isPolygon = isGeometryPolygon(geometry);
    const turfGeometry = isPolygon
      ? polygon(geometry.userData.coordinates)
      : lineString(geometry.userData.coordinates);
    const centerPoint = center(turfGeometry);
    const distance = turfDistance(centerPoint, coordinates);
    const bearing = turfBearing(centerPoint, coordinates);

    const movedGeometry = transformTranslate(turfGeometry, distance, bearing);

    const turfBox = polygon(box.geometry.userData.coordinates);
    const movedBox = transformTranslate(turfBox, distance, bearing);
    box.update(bbox(movedBox));

    Object.values(controls.resizer.shapes).forEach((shape) => {
      if (shape instanceof Shape) {
        const shapeGeometry = shape.getGeometry();
        if (shapeGeometry) {
          const turfShape = point(shapeGeometry.userData.coordinates);
          const movedShape = transformTranslate(turfShape, distance, bearing);
          shape.updateCoordinates({ lngLat: movedShape.geometry.coordinates });
        }
      }
    });

    const rotatorGeometry = controls.rotator.shape.getGeometry();

    if (rotatorGeometry) {
      const turfRotator = point(rotatorGeometry.userData.coordinates);
      const movedRotator = transformTranslate(turfRotator, distance, bearing);
      controls.rotator.shape.updateCoordinates({
        lngLat: movedRotator.geometry.coordinates,
      });
    }

    return movedGeometry.geometry.coordinates;
  }

  /**
   * Определяет наведена ли мышь на мувер.
   *
   * @param event - Событие.
   * @param event.lngLat - Координаты курсора.
   * @returns {boolean} Наведенали мышь на мувер.
   */
  public isMouseHovered({ lngLat }: MapPointerEvent) {
    this.isHovered = this.geometryUtils.isPointWithinPolygon(
      lngLat,
      this.shape.coordinatesToCheckMouseEvent,
    );
    return this.isHovered;
  }

  /**
   * Обновляет координаты мувера.
   *
   * @param coordinates - Координаты.
   * @returns {void}
   */
  public updateCoordinates(coordinates: number[]) {
    this.shape.updateCoordinates({ lngLat: coordinates });
  }

  /**
   * Скрывает мувер.
   *
   * @returns {void}
   */
  hide() {
    this.shape.hide();
  }

  /**
   * Показывает мувер.
   *
   * @returns {void}
   */
  show() {
    this.shape.show();
  }

  /**
   * Уничтожает контрол.
   *
   * @returns {void} Экземпляр Mover.
   */
  destroy() {
    this.map.off('zoomend', this.zoomEndHandler);
    this.shape.destroy();
  }
}
