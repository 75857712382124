import { useFetchOghDispatch } from 'app/actions/odsObjectActions/useFetchOgh.Dispatch';
import { RANDOM_BOOL, RANDOM_ID, RANDOM_WORD } from 'core/forTesting/constants';
import { toast } from 'core/uiKit/components/Toast';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import { vi } from 'vitest';

import { signCloseElectronicSignature } from './signCloseElectronicSignature';
import { useSignCloseElectronicSignature } from './useSignClose.ElectronicSignature';

vi.mock('app/actions/odsObjectActions/useFetchOgh.Dispatch');
vi.mock('core/utils/hooks/useReactQuery');
vi.mock('./signCloseElectronicSignature');
vi.mock('core/uiKit/components/Toast');

describe('UseSignCloseElectronicSignatureJestSpec', function () {
  it('Успешный кейс', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const objectId = RANDOM_ID;
    const signType = RANDOM_WORD;
    const objectCardFiles = {};
    const onSuccessCallback = vi.fn();
    const onErrorCallback = vi.fn();
    const fetchOgh = vi.fn();
    const sign = vi.fn();

    const spyToastSuccess = vi.spyOn(toast, 'success');
    useFetchOghDispatch.mockReturnValue(fetchOgh);
    signCloseElectronicSignature.mockReturnValue(sign);
    useMutationAdaptor.mockImplementation((fn, { onSuccess }) => {
      fn();
      onSuccess();
      return [vi.fn(), { isLoading: RANDOM_BOOL }];
    });

    // Act
    useSignCloseElectronicSignature({
      objectCardFiles,
      objectId,
      onErrorCallback,
      onSuccessCallback,
      signType,
    });
    // Assert
    expect(sign).toBeCalledTimes(1);
    expect(onSuccessCallback).toBeCalledTimes(1);
    expect(fetchOgh).toBeCalledTimes(1);
    expect(spyToastSuccess).toBeCalledWith('Подписано');
  });
  it('Негативный кейс', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const objectId = RANDOM_ID;
    const signType = RANDOM_WORD;
    const objectCardFiles = {};
    const onSuccessCallback = vi.fn();
    const onErrorCallback = vi.fn();
    const fetchOgh = vi.fn();
    const sign = vi.fn();

    const spyToastError = vi.spyOn(toast, 'error');
    useFetchOghDispatch.mockReturnValue(fetchOgh);
    signCloseElectronicSignature.mockReturnValue(sign);
    useMutationAdaptor.mockImplementation((fn, { onError }) => {
      fn();
      onError({ message: RANDOM_WORD });
      return [vi.fn(), { isLoading: RANDOM_BOOL }];
    });

    // Act
    useSignCloseElectronicSignature({
      objectCardFiles,
      objectId,
      onErrorCallback,
      onSuccessCallback,
      signType,
    });
    // Assert
    expect(spyToastError).toBeCalledWith(RANDOM_WORD);
    expect(onErrorCallback).toBeCalledTimes(1);
  });
});
