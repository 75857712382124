import { useState } from 'react';

/**
 * Хук управления диалоговым окном.
 *
 * @returns {object}
 */
export const useDialogShowChildrenButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  /**
   * Fn.
   *
   * @returns {void}
   */
  const openDialog = () => {
    setIsOpen(true);
  };

  /**
   * Fn.
   *
   * @returns {void}
   */
  const closeDialog = () => {
    setIsOpen(false);
  };

  return { closeDialog, isOpen, openDialog };
};
