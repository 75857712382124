import { get, post } from 'app/api/crud';
import { getRegistryTable } from 'app/api/requests/getRegistryTable';
import { printReport } from 'app/pages/registry/ogh/api/printReport';
import { queryClient } from 'app/routes/QueryClientProvider';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';
import { useParams } from 'react-router-dom';

export const printRegistry = printReport('registryTl');

/**
 * A.
 *
 * @param {*} queryParams - A.
 * @returns {*}
 */
export const createTitleListRegistry = (queryParams) =>
  post('/tl', queryParams);

/**
 * A.
 *
 * @param {*} idTl - A.
 * @returns {*}
 */
const fetchTitleList = (idTl) => {
  if (idTl) {
    return get(`/tl/${idTl}`);
  }
  return {};
};

/**
 * A.
 *
 * @returns {*}
 */
export const useFetchTitleList = () => {
  let { id } = useParams();
  return useQueryAdaptor(['title-list', id], () => fetchTitleList(id));
};

/**
 * A.
 *
 * @param {*} queryParams - A.
 * @param {*} options - A.
 * @returns {*}
 */
export const useFetchRegistryMatchingHistory = (queryParams, options) =>
  useQueryAdaptor(
    ['fetchRegistryMatchingHistory', queryParams],
    () => {
      if (queryParams.id) {
        return getRegistryTable('/matching/history', queryParams);
      }
      return [];
    },
    options,
  );

/**
 * A.
 *
 * @returns {void}
 */
export const clearRegistryMatchingHistoryCash = () => {
  queryClient.invalidateQueries({ queryKey: ['fetchRegistryMatchingHistory'] });
};

/**
 * A.
 *
 * @returns {void}
 */
export const clearFetchTitleListCash = () => {
  queryClient.invalidateQueries({ queryKey: ['title-list'] });
};
