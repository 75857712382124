import { CardContainer } from 'app/pages/cardsOgh/components/CardContainer';
import { Body, Header } from 'app/pages/components';
import { Main } from 'app/pages/components/Main';
import { FinalForm } from 'core/form/finalForm/components/FinalForm';
import { FileListFF } from 'core/form/finalForm/fields/prepared/FileList.FF';
import { DndFileUploadFF } from 'core/form/finalForm/fields/prepared/fileUpload/DndFileUploadFF';
import { Card, CardContent } from 'core/uiKit/material-ui';
import React from 'react';

import { ButtonGroupKnowledgeBase } from './ButtonGroup.KnowledgeBase/ButtonGroup.KnowledgeBase';
import { useSetupKnowledgeBase } from './useSetup.KnowledgeBase';

/**
 * Card Методические материалы.
 *
 * @returns Card Методические материалы.
 */
export const KnowledgeBase = () => {
  const { breadcrumbs, isEdit, files, id, onSubmit, isLoading } =
    useSetupKnowledgeBase();
  return (
    <Main>
      <Header breadcrumbs={breadcrumbs} />

      <Body isLoading={isLoading}>
        <Card className={''}>
          <CardContent>
            <FinalForm
              noMeno={true}
              // @ts-ignore
              onSubmit={onSubmit}
              initialValues={{ file_list: files, id: id }}
            >
              <ButtonGroupKnowledgeBase isLoading={isLoading} />
              {isEdit ? (
                <CardContainer>
                  <DndFileUploadFF
                    accept={[]}
                    text={
                      'Добавить файлы. Переместите файлы или нажмите для выбора'
                    }
                    name={'files_for_upload'}
                    disabled={!isEdit}
                  />
                </CardContainer>
              ) : null}
              <FileListFF name={'file_list'} disabled={!isEdit} />
            </FinalForm>
          </CardContent>
        </Card>
      </Body>
    </Main>
  );
};
