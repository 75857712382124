/* eslint-disable  */
import PropTypes from 'prop-types';
import React from 'react';
import Draggable from 'react-draggable';

/**
 *
 * @param root0
 * @param root0.children
 * @param root0.axis
 * @param root0.handle
 * @param root0.grid
 */
export const DraggableElement = ({ children, axis, handle, grid }) => {
  return (
    <Draggable axis={axis} handle={handle} grid={grid}>
      {children}
    </Draggable>
  );
};

DraggableElement.propTypes = {
  /**
   * Ось перемещения для перескивания.
   */
  axis: PropTypes.oneOf(['x', 'y', 'both', 'none']).isRequired,

  /**
   * Содержимое в Draggable.
   */
  children: PropTypes.any,

  /**
   * Коэффициент перемещения окна при перетаскивании, чем меньше числа в массиве, тем плавнее перемещается окно, пример: [5, 5].
   */
  grid: PropTypes.array,

  /**
   * Название класса в чилдрене, за что 'схватить' мышкой, чтобы перетащить окно, например: '.dragClass'.
   */
  handle: PropTypes.string.isRequired,
};
