/* eslint-disable  */
import React from 'react';

import { IGroupTree } from '../Tree';
import { GroupItemTree } from './Group.Item.Tree';

interface SubgroupsTreeProps {
  /**
   * Флаг открытия всех элментов.
   */
  openAll: boolean;
  /**
   * Массив групп.
   */
  subgroups: IGroupTree[];
  /**
   * Функция с задержкой лоудер что бы отложить изменния флага как прекратиться пререрндер в течении 3 секунд.
   */
  setLoadingRender: (v: boolean) => void;
}

/**
 * Группы дерева элементов.
 *
 * @param props - Props.
 * @param props.subgroups - Массив групп.
 * @param props.openAll - Флаг открытия всех элментов.
 * @param props.setLoadingRender - Функция изменения лоудера дерева.
 * @returns Подкатегории дерева элементов.
 */
export const GroupsTree = ({
  subgroups,
  openAll,
  setLoadingRender,
}: SubgroupsTreeProps) => {
  return subgroups?.map((item) => {
    return (
      <GroupItemTree
        {...item}
        openAll={openAll}
        key={item.id}
        setLoadingRender={setLoadingRender}
      />
    );
  });
};
