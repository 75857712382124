import { startDeleteCard } from 'app/actions/odsObjectActions/startDeleteCard';
/* eslint-disable */
import { useGetVisibilityButtonsOgh } from 'app/api/hooks/useGetVisibilityButtonsOgh';
import { useGetCardSelector } from 'app/selectors/useSelectors/useGetCard.Selector';
import { useGetCardModeSelector } from 'app/selectors/useSelectors/useGetCardMode.Selector';
import { useGetTreeSelector } from 'app/selectors/useSelectors/useGetTree.Selector';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import { useDispatch } from 'react-redux';

/**
 *
 * @returns {{onClose: (function(): function(*): Promise<*>), isShow: boolean}}
 */

/**
 *
 */
export const useCloseOghButton = () => {
  const dispatch = useDispatch();
  const { objectId } = useGetObjectId();
  const { typeId } = useGetCardSelector();

  // todo не совсем понятно зачем это надо.
  // если тут будет тру то карточка стирается до диалогового окна
  const selected = useGetTreeSelector();
  const isSelected = selected && Object.keys(selected).length;

  /**
   *
   */
  const onClose = () =>
    dispatch(startDeleteCard(objectId, typeId, !isSelected));

  // видимость
  const { data: { close } = {} } = useGetVisibilityButtonsOgh(objectId);
  const { editMode } = useGetCardModeSelector();

  const isShow = !editMode && close;

  return { isShow, onClose };
};
