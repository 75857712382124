import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { MultiSelect } from 'core/uiKit/inputs/selects/MultiSelect';
import React from 'react';

export const CAR_FUNC_TYPES_NAME = 'carFuncType';

/**
 * ## Тип техники.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const CarFuncTypes = (props) => {
  const dict = 'carFuncType';
  const { data: options = [] } = useGetDictionary(dict);

  return (
    <MultiSelect
      {...props}
      {...{ options }}
      label={'Тип техники'}
      name={CAR_FUNC_TYPES_NAME}
    />
  );
};
