import { useFieldRF } from 'core/form/reduxForm/hooks/useField.RF';
import { shallow } from 'enzyme';
import React from 'react';
import { vi } from 'vitest';

import { RepairsPlanTab } from './RepairsPlan.Tab';

vi.mock('core/form/reduxForm/hooks/useField.RF');
describe('RepairsPlanTab', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useFieldRF.mockReturnValue({});
    // 🔥 Act
    const wrapper = shallow(<RepairsPlanTab />);
    // ❓ Assert
    expect(wrapper.text()).toBe('<TabsCardItem />');
  });
});
