import isCreateMode from './isCreateMode';
/* eslint-disable  */
import isEditMode from './isEditMode';

/**
 *
 * @param state
 * @param props
 */
export default (state, props) =>
  isEditMode(state, props) || isCreateMode(state, props);
