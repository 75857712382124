import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'core/uiKit/components/Accordion';
import { ExpandMoreIcon } from 'core/uiKit/material-ui';

import { PrintPlantationTab } from './Print.PlantationTab';

/**
 * Компонент AccordionPlantationTab.
 *
 * @param {object} props - Пропсы.
 * @param {*} props.Table - Any.
 * @param {*} props.title - Any.
 * @param {*} props.code - Any.
 * @param {*} props.onChange - Any.
 * @param {*} props.expanded - Any.
 * @returns {JSX.Element}
 */
export const AccordionPlantationTab = ({
  Table,
  title,
  code,
  onChange,
  expanded,
}) => {
  const show = expanded === code;
  return (
    <Accordion className={'my-1'} expanded={show} onChange={onChange(code)}>
      <AccordionSummary
        id={code}
        aria-controls={code}
        expandIcon={<ExpandMoreIcon />}
      >
        <h5>{title}</h5>
      </AccordionSummary>
      <AccordionDetails>
        <div className={'d-flex justify-content-end mb-2'}>
          <PrintPlantationTab code={code} />
        </div>
        <div className="overflow-auto registry-table-max-height d-flex flex-column">
          {show ? <Table code={code} /> : null}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
