/* eslint-disable */
import React from 'react';
import { filterInput } from '../filterInputsClasses';
import {
  REAGENT_KIND_ID_NAME,
  ReagentKindIdFF,
} from 'core/form/finalForm/fields';
import { NumberParam } from 'use-query-params';

 
export { REAGENT_KIND_ID_NAME };

 
 
/**
 *    Вид ПГР
 */
 
export const ReagentKindIdFilter = (props) => (
  <ReagentKindIdFF {...props} className={filterInput} />
);

 
export const reagentKindIdParams = () => {
  return {
    [REAGENT_KIND_ID_NAME]: NumberParam,
  };
};
