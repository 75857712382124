import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { PlantServiceRecomendationsIdRF } from 'core/form/reduxForm/fields/prepared/selects/multiSelect/PlantServiceRecomendationsId.RF';
import { STATE_GARDENING_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/StateGardeningId';
import React, { FC } from 'react';

import { getPlantServiceRecomendationsIdRequired } from './getPlantServiceRecomendationsIdRequired';

/**
 * Поле - Рекомендации по уходу. __Деревья/Кустарники__.
 *
 * @param props - Property.
 * @param props.editMode - Флаг изменения.
 * @param props.currentValues - Значения формы.
 * @returns {JSX}
 */
export const PlantServiceRecomendationsIdRfTreesShrubs: FC<{
  currentValues: {
    characteristic_state_gardening_id?: number[];
    [STATE_GARDENING_ID_NAME]?: number | string;
  };
  editMode?: boolean;
}> = ({ editMode, currentValues }) => {
  const isCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();

  return (
    // @ts-ignore
    <PlantServiceRecomendationsIdRF
      required={getPlantServiceRecomendationsIdRequired(currentValues)}
      disabled={
        !(
          editMode &&
          currentValues?.characteristic_state_gardening_id?.filter(Boolean)
            .length
        ) || isCurrentObjectIsExternalSystem
      }
      filter={(dict: { characteristic_state_gardening_ids: number[] }[]) => {
        return dict.filter(({ characteristic_state_gardening_ids }) => {
          return characteristic_state_gardening_ids?.some((id) =>
            currentValues?.characteristic_state_gardening_id?.includes(id),
          );
        });
      }}
    />
  );
};
