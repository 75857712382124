import { makeNewsHasBeenRead } from 'app/api/news/make.NewsHasBeenRead';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

/**
 * @typedef {import('core/utils/hooks/useReactQuery').UseQueryResult} UseQueryResult
 */

type Options = Parameters<typeof useMutationAdaptor>[1];

/**
 * Хук функции послать серверу, что новость была прочитана.
 *
 * @param {Options} options - Options.
 * @returns {UseQueryResult} - Новость.
 */
export const useMakeNewsHasBeenRead = (options?: Options) =>
  useMutationAdaptor(makeNewsHasBeenRead, options);
