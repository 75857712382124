/* eslint-disable  */
import { useGetCurrentCardSelector } from 'app/selectors/useSelectors/useGetCurrentCard.Selector';
import { Select } from 'core/uiKit/inputs/selects/Select';

export const ODH_SIDE_ID_NAME = 'odh_side_id';

/**
 *
 * @param props
 */
export const OdhSideId = (props) => {
  const { odhSide } = useGetCurrentCardSelector();
  return (
    <Select
      {...props}
      label={'Сторона'}
      name={ODH_SIDE_ID_NAME}
      options={odhSide}
    />
  );
};
