import * as api from 'app/api';
import { post } from 'app/api/crud/post';
import { put } from 'app/api/crud/put';
import { turn_into_snake_case as withSnakeCase } from 'app/api/crud/turn_into_snake_case';
import { getRegistryTable } from 'app/api/requests/getRegistryTable';
import { fetchWrapper } from 'app/utils/fetchWrapper';
import * as env from 'config/env';
import { toast } from 'core/uiKit/components/Toast';
import { log } from 'core/utils/log';
import qs from 'query-string';
import { useNavigate } from 'react-router-dom';

import * as types from '../constants/actionTypes';
import { showAlert } from './dialog';
import * as loaderActions from './loader';

/**
 * A.
 *
 * @param {number} id - IDa.
 * @returns {*}
 */
export function requestRole(id) {
  return {
    id,
    type: types.REQUEST_ROLE,
  };
}

/**
 * A.
 *
 * @param {object} object - A.
 * @returns {object}
 */
export function receiveRole(object) {
  return {
    object,
    type: types.RECEIVE_ROLE,
  };
}

/**
 * Get.
 *
 * @param {number} id - A.
 * @returns {*}
 */
export function getRole(id) {
  return (dispatch) => {
    dispatch(requestRole(id));
    return fetch(`${env.ODS_API_URL}/roles${id ? `/${id}` : ''}`, {
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((json) => dispatch(receiveRole(json)));
  };
}

/**
 * A.
 *
 * @param {object} role - A.
 * @returns {object}
 */
export function requestUpdateRole(role) {
  return {
    role,
    type: types.REQUEST_UPDATE_ROLE,
  };
}

/**
 * A.
 *
 * @param {object} object - A.
 * @returns {object}
 */
export function receiveUpdateRole(object) {
  return {
    object,
    type: types.RECEIVE_UPDATE_ROLE,
  };
}

/**
 * Update.
 *
 * @param {object} data - A.
 * @returns {object}
 */
export function updateRole(data) {
  return (dispatch) => {
    const navigate = useNavigate();

    dispatch(requestUpdateRole(data));
    return fetch(`${env.ODS_API_URL}/roles`, {
      body: JSON.stringify(data),
      credentials: 'include',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      method: 'PUT',
    })
      .then((response) => response.json())
      .then((json) => {
        dispatch(receiveUpdateRole(json));
        if (json && !json.error) {
          dispatch(navigate(`/a/role/${data.roleId}`));
        }
      });
  };
}

/**
 * A.
 *
 * @param {object} role - A.
 * @returns {object}
 */
export function requestCreateRole(role) {
  return {
    role,
    type: types.REQUEST_CREATE_ROLE,
  };
}

/**
 * A.
 *
 * @param {object} object - A.
 * @returns {object}
 */
export function receiveCreateRole(object) {
  return {
    object,
    type: types.RECEIVE_CREATE_ROLE,
  };
}

/**
 * Create.
 *
 * @param {object} data - A.
 * @returns {object}
 */
export function createRole(data) {
  return (dispatch) => {
    const navigate = useNavigate();

    dispatch(requestCreateRole(data));
    return fetch(`${env.ODS_API_URL}/roles`, {
      body: JSON.stringify(data),
      credentials: 'include',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      method: 'POST',
    })
      .then((response) => response.json())
      .then((json) => {
        dispatch(receiveCreateRole(json));
        if (json && json.id) {
          dispatch(navigate(`/a/role/${json.id}`));
        }
      });
  };
}

/**
 * Delete.
 *
 * @param {number} id - A.
 * @param {Function} afterDelete - A.
 * @returns {object}
 */
export function deleteRole(id, afterDelete) {
  return (dispatch) => {
    loaderActions.show();
    return api
      .deleteAdminRole({ id })
      .then(() => {
        if (afterDelete) {
          afterDelete();
        }
      })
      .catch((error) => {
        dispatch(showAlert(error.message));
      })
      .finally(function () {
        dispatch(loaderActions.hide());
      });
  };
}

/**
 *A.
 *
 * @returns {object}
 */
export function clearAdminError() {
  return {
    type: types.CLEAR_ADMIN_ERROR,
  };
}

/**
 * A.
 *
 * @param {object} object - A.
 * @returns {object}
 */
export function requestUserRegistry(object) {
  return {
    object,
    type: types.REQUEST_USER_REGISTRY,
  };
}

/**
 * A.
 *
 * @param {object} object - A.
 * @returns {object}
 */
export function receiveUserRegistry(object) {
  return {
    object,
    type: types.RECEIVE_USER_REGISTRY,
  };
}

/**
 * A.
 *
 * @param {object} data - A.
 * @returns {object}
 */
export function fetchUserRegistry(data) {
  return (dispatch) => {
    dispatch(requestUserRegistry(data));
    return fetch(`${env.ODS_API_URL}/registry/user?${qs.stringify(data)}`, {
      credentials: 'include',
    })
      .then((response) => response.json())
      .then(({ data }) => dispatch(receiveUserRegistry(data)));
  };
}

/**
 * A.
 *
 * @param {object} object - A.
 * @returns {object}
 */
export function requestTemplateRegistry(object) {
  return {
    object,
    type: types.REQUEST_TEMPLATE_REGISTRY,
  };
}

/**
 * A.
 *
 * @param {object} object - A.
 * @returns {object}
 */
export function receiveTemplateRegistry(object) {
  return {
    object,
    type: types.RECEIVE_TEMPLATE_REGISTRY,
  };
}

/**
 * A.
 *
 * @param {object} data - A.
 * @returns {object}
 */
export function fetchTemplateRegistry(data) {
  return (dispatch) => {
    dispatch(requestTemplateRegistry(data));
    return fetch(`${env.ODS_API_URL}/registry/matching?${qs.stringify(data)}`, {
      credentials: 'include',
    })
      .then((response) => response.json())
      .then(({ data }) => dispatch(receiveTemplateRegistry(data)));
  };
}

/**
 * A.
 *
 * @param {number} id - A.
 * @returns {object}
 */
export function requestMatching(id) {
  return {
    id,
    type: types.REQUEST_MATCHING,
  };
}

/**
 * A.
 *
 * @param {object} object - A.
 * @returns {object}
 */
export function receiveMatching(object) {
  return {
    object,
    type: types.RECEIVE_MATCHING,
  };
}

/**
 * A.
 *
 * @param {boolean} editMode - A.
 * @returns {object}
 */
export function changeEditMode(editMode) {
  return {
    editMode,
    type: types.CHANGE_EDIT_MODE,
  };
}

/**
 * A.
 *
 * @param {number} id - A.
 * @param {Function} push - A.
 * @returns {object}
 */
export function getMatching(id, push = (_) => _) {
  return (dispatch) => {
    dispatch(requestMatching(id));
    return fetch(`${env.ODS_API_URL}/matching/${id}`, {
      credentials: 'include',
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        dispatch(push('/a/matching'));
      })
      .then((json) => dispatch(receiveMatching(json)));
  };
}

/**
 * A.
 *
 * @param {object} object - A.
 * @returns {object}
 */
export function requestCreateTemplate(object) {
  return {
    object,
    type: types.REQUEST_CREATE_TEMPLATE,
  };
}

/**
 * A.
 *
 * @param {object} object - A.
 * @returns {object}
 */
export function receiveCreateTemplate(object) {
  return {
    object,
    type: types.RECEIVE_CREATE_TEMPLATE,
  };
}

/**
 * A.
 *
 * @param {object} data - A.
 * @param {Function} push - A.
 * @returns {object}
 */
export function createTemplate(data, push = (_) => _) {
  return (dispatch) => {
    dispatch(requestCreateTemplate(data));
    return post(
      '/matching/add',
      {
        ...data,
        attribute: JSON.stringify(data.attribute),
        dimension: JSON.stringify(data.dimension),
      },
      {
        jsonBody: true,
        urlencoded: false,
      },
    )
      .then((json) => {
        dispatch(receiveCreateTemplate(json));
        if (!json.error) {
          dispatch(push(`/a/matching/${json.create_matching_template}`));
          dispatch(getMatching(json.create_matching_template, push));
          dispatch(changeEditMode(false));
        }
      })
      .catch(() => {
        dispatch(receiveCreateTemplate(data));
      });
  };
}

/**
 * A.
 *
 * @returns {object}
 */
export function clearMatching() {
  return {
    type: types.CLEAR_MATCHING,
  };
}

/**
 * A.
 *
 * @param {object} object - A.
 * @returns {object}
 */
export function receiveEditTemplate(object) {
  return {
    object,
    type: types.RECEIVE_EDIT_TEMPLATE,
  };
}

/**
 * A.
 *
 * @param {object} object - A.
 * @returns {object}
 */
export function requestEditTemplate(object) {
  return {
    object,
    type: types.REQUEST_EDIT_TEMPLATE,
  };
}

/**
 * A.
 *
 * @param {object} data - A.
 * @param {number} id - A.
 * @param {Function} push - A.
 * @returns {object}
 */
export function editTemplate(data, id, push = (_) => _) {
  return (dispatch) => {
    dispatch(requestEditTemplate(data));
    return put(
      `/matching/${id}`,
      {
        ...data,
        attribute: JSON.stringify(data.attribute),
        dimension: JSON.stringify(data.dimension),
      },
      {
        jsonBody: true,
      },
    )
      .then((json) => {
        dispatch(receiveEditTemplate(json));
        if (!json.error) {
          log.banana('push тут не работает нужно прокинуть');

          dispatch(push(`/a/matching/${id}`));
          dispatch(getMatching(id, push));
        }
      })
      .catch(() => {
        dispatch(receiveEditTemplate(data));
      });
  };
}

/**
 * A.
 *
 * @param {object} object - A.
 * @returns {object}
 */
export function receiveDeleteTemplate(object) {
  return {
    object,
    type: types.RECEIVE_DELETE_TEMPLATE,
  };
}

/**
 * A.
 *
 * @param {object} object - A.
 * @returns {object}
 */
export function requestDeleteTemplate(object) {
  return {
    object,
    type: types.REQUEST_DELETE_TEMPLATE,
  };
}

/**
 * A.
 *
 * @param {number} id - A.
 * @param {Function} afterDelete - A.
 * @returns {object}
 */
export function deleteTemplate(id, afterDelete) {
  return (dispatch) => {
    dispatch(requestDeleteTemplate(id));
    return fetch(`${env.ODS_API_URL}/matching/${id}`, {
      credentials: 'include',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      method: 'DELETE',
    })
      .then((response) => response.text())
      .then((json) => {
        dispatch(receiveDeleteTemplate(json));
        if (afterDelete) {
          afterDelete();
        }
      });
  };
}

/**
 * A.
 *
 * @param {object} object - A.
 * @returns {object}
 */
export function receiveSendMatching(object) {
  return {
    object,
    type: types.RECEIVE_SEND_MATCHING,
  };
}

/**
 * A.
 *
 * @param {object} object - A.
 * @returns {object}
 */
export function requestSendMatching(object) {
  return {
    object,
    type: types.REQUEST_SEND_MATCHING,
  };
}

/**
 * A.
 *
 * @returns {object}
 */
export function receiveSendMatchingError() {
  return {
    type: types.RECEIVE_SEND_MATCHING_ERROR,
  };
}

/**
 * A.
 *
 * @param {object} data - A.
 * @param {string} entityType - A.
 * @param {Function} afterAction - A.
 * @returns {object}
 */
export function sendMatching(data, entityType, afterAction) {
  return (dispatch) => {
    dispatch(requestSendMatching());

    return fetchWrapper(`${env.ODS_API_URL}/${entityType}/matching/send`, {
      body: JSON.stringify(withSnakeCase(data)),
      credentials: 'include',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      method: 'POST',
    })
      .then((json) => {
        if (afterAction) {
          afterAction();
        }
        return dispatch(receiveSendMatching(json));
      })
      .catch((error) => {
        if (error.status && (error.status === 500 || error.status === 400)) {
          const errMsg = 'message' in error ? error.message : error;
          dispatch(showAlert(errMsg));
        }
        dispatch(receiveSendMatchingError());
      });
  };
}

/**
 * A.
 *
 * @param {object} object - A.
 * @returns {object}
 */
export function receiveMatchingOperation(object) {
  return {
    type: types.RECEIVE_MATCHING_OPERATION,
    ...object,
  };
}

/**
 * A.
 *
 * @param {object} object - A.
 * @returns {object}
 */
export function requestMatchingOperation(object) {
  return {
    object,
    type: types.REQUEST_MATCHING_OPERATION,
  };
}

/**
 * A.
 *
 * @param {object} data - A.
 * @returns {object}
 */
export function approveRequest(data) {
  return (dispatch) => {
    dispatch(requestMatchingOperation(data));
    const entityCode = data.entityCode;
    return post(`/${entityCode}/matching`, withSnakeCase(data), {
      jsonBody: true,
      parseResponse: false,
      urlencoded: true,
    })
      .then((resp) => {
        dispatch(loaderActions.hide());
        return resp;
      })
      .catch((error) => {
        const errMsg = 'message' in error ? error.message : error;
        dispatch(reloadCard());
        dispatch(loaderActions.hide());
        toast.error(errMsg);
      });
  };
}

/**
 * A.
 *
 * @returns {object}
 */
export function reloadCard() {
  return {
    type: types.RELOAD_CARD,
  };
}

/**
 * A.
 *
 * @param {object} object - A.
 * @returns {object}
 */
export function receiveObjectMatching(object) {
  return {
    object,
    type: types.RECEIVE_OBJECT_MATCHING,
  };
}

/**
 * A.
 *
 * @param {object} object - A.
 * @returns {object}
 */
export function requestObjectMatching(object) {
  return {
    object,
    type: types.REQUEST_OBJECT_MATCHING,
  };
}

/**
 * A.
 *
 * @param {object} queryParams - A.
 * @returns {object}
 */
export function objectMatching(queryParams) {
  return async (dispatch) => {
    dispatch(requestObjectMatching(queryParams));

    try {
      const table = getRegistryTable('matching/history', queryParams);
      const { count: total } = await getRegistryTable(
        'matching/history/count',
        queryParams,
      );
      const data = {
        meta: { ...queryParams, total },
        table: await table,
      };

      return dispatch(receiveObjectMatching(data));
    } catch (error) {
      switch (error.status) {
        case 403: {
          log.error(error);
          break;
        }
        default: {
          const errMsg = 'message' in error ? error.message : error;
          dispatch(showAlert(errMsg));
        }
      }
      dispatch(loaderActions.hide());
    }
  };
}
