/* eslint-disable  */
import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { MultiSelect } from 'core/uiKit/inputs/selects/MultiSelect';
import { Tooltip } from 'core/uiKit/material-ui';
import React from 'react';

export const MATCHING_CHANGE_TYPES_NAME = 'matchingChangeTypes';

/**
 * ## Операция.
 *
 * @param {*} props - Properties.
 * @param {*} props.filter - Filter.
 * @param {*} props.tooltip - Tooltip.
 * @param {*} props.otherProps - Properties.
 * @returns {JSX.Element}
 */
export const MatchingChangeTypes = ({
  filter = (a) => a,
  tooltip = '',
  ...props
}) => {
  const dict = 'matchingChangeType';
  const { data = [] } = useGetDictionary(dict);

  const options = typeof filter === 'function' ? filter(data) : data;

  return (
    <Tooltip title={tooltip}>
      <MultiSelect
        {...props}
        optionKey={'code'}
        {...{ options }}
        label={'Операция'}
        name={MATCHING_CHANGE_TYPES_NAME}
      />
    </Tooltip>
  );
};
