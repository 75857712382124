/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import { TextField } from 'core/uiKit/inputs/TextField';

 
export const CONFIRM_PASSWORD_NAME = 'confirmPassword';

 
/* eslint-disable */
/**
 * Повторите пароль
 *
 * * `type = password`
 * * `name = confirmPassword`
 * @param props
 * @returns {*}
 * @constructor
 */
/* eslint-disable */
export const ConfirmPassword = (props) => {
  return (
    <TextField
      {...props}
      type={'password'}
      label={'Повторите пароль'}
      inputProps={{ 'data-testid': 'confirmPassword', autoComplete: 'off' }}
      name={CONFIRM_PASSWORD_NAME}
    />
  );
};

ConfirmPassword.propTypes = {
   
   
/**
   * выбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * функция вызова когда выберется итем
   */
  onChange: PropTypes.func,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
   
   
/**
   * значение
   */
  value: PropTypes.any,
};
