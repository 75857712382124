import {
  FilterFF,
  OkrugFilter,
  okrugParams,
  ReagentBaseTypeFilter,
  reagentBaseTypeParams,
  ReagentFilter,
  ReagentKindFilter,
  reagentKindParams,
  reagentParams,
} from 'core/form/filterForm';
import {
  DateFromFilter,
  dateFromParams,
} from 'core/form/filterForm/components/dateFilters/DateFromFilter';
import {
  DateToFilter,
  dateToParams,
} from 'core/form/filterForm/components/dateFilters/DateToFilter';
import {
  OghStatusesFilter,
  oghStatusesParams,
} from 'core/form/filterForm/components/miltiSelect/OghStatusesFilter';
import {
  DistrictIdFilter,
  districtIdParams,
} from 'core/form/filterForm/components/selects/DistrictId.Filter';
import {
  IdOrNameFilter,
  idOrNameParams,
} from 'core/form/filterForm/components/text/IdOrName.Filter';
import { useFilterQueryParams } from 'core/form/filterForm/hooks/useFilterQueryParams';
import React from 'react';
import { useFormState } from 'react-final-form';

/**
 * Хук QueryParams для фильтра ____.
 *
 * @returns {object} - Объект с фильтрами и функцию изменения фильтра.
 */
export const useFilterQueryParamsBasePgm = () => {
  return useFilterQueryParams([
    dateFromParams,
    dateToParams,
    districtIdParams,
    idOrNameParams,
    oghStatusesParams,
    okrugParams,
    reagentBaseTypeParams,
    reagentParams,
    reagentKindParams,
  ]);
};

/**
 * Filter FF реестров ____.
 *
 * @returns {JSX.Element} - JSX.
 */
export const FilterBasePgm = () => {
  const { setFilters, filters } = useFilterQueryParamsBasePgm();

  /**
   * Функция submit фильтра формы FF.
   *
   * @param {object} values - Values.
   * @returns {void}
   */
  const onSubmit = async (values) => {
    setFilters(values, 'push');
  };

  /**
   * Функция сброса формы FF.
   *
   * @param {object} form - Параметры формы.
   * @param {Function} form.reset - Функция сброса формы FF.
   * @returns {void}
   */
  const reset = (form) => {
    form.reset();
    setFilters({}, 'replace');
  };

  return (
    <FilterFF {...{ initialValues: filters, onSubmit, reset }}>
      <Form />
    </FilterFF>
  );
};

/**
 * Filter Fonrm реестров ____.
 *
 * @returns {JSX.Element} - JSX.
 */
const Form = () => {
  const { values } = useFormState();
  const { okrug } = values;

  /**
   * Функция фильтрации районов.
   *
   * @param {Array} districts - Районы.
   * @returns {Array} - Районы.
   */
  const filterDistrict = (districts) =>
    districts.filter((item) => !okrug || item.okrug.find((ok) => ok === okrug));

  return (
    <>
      <IdOrNameFilter />
      <ReagentBaseTypeFilter />
      <OkrugFilter />
      <DistrictIdFilter filter={filterDistrict} />
      <OghStatusesFilter />
      <ReagentKindFilter />
      <ReagentFilter />
      <DateFromFilter />
      <DateToFilter />
    </>
  );
};
