 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */

import { OghTypeNameCell } from 'core/uiKit/components/cells/OghTypeName.Cell';
 
/* eslint-disable */
/**
 * Колонка __Тип объекта городского хозяйства (родительского объекта)__.
 *
 * @type {{ accessor: 'parent_type_id',  Header: 'Тип объекта городского хозяйства (родительского объекта)',  sortable: true, }}
 * @augments Cell
 */
export const parent_type_id = {
  Cell: OghTypeNameCell,
  accessor: 'parent_type_id',
  Header: 'Тип объекта городского хозяйства (родительского объекта)',
  sortable: true,
};
