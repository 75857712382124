import React from 'react';

interface ICreateNumberedList {
  header: string;
  list: ICreateNumberedListItem[] | string[];
}

interface ICreateNumberedListItem {
  item: string;
  children?: string[];
}

/**
 * Create number list.
 *
 * @param params - Params.
 * @param params.header - Header.
 * @param params.list - List.
 * @returns JSX.Element.
 */
export function createNumberedList({ header, list }: ICreateNumberedList) {
  return (
    <article className="section-hint">
      <header className="section-hint__header">{header}:</header>
      <hr className="section-hint__hr" />
      <section className="section-hint__container">
        {numberedList(list)}
      </section>
    </article>
  );
}

/**
 * Create number list.
 *
 * @param list - List.
 * @param type - Type.
 * @returns JSX.Element.
 */
function numberedList(
  list: ICreateNumberedListItem[] | string[],
  type: '1' | 'a' | 'A' | 'i' | 'I' | undefined = '1',
) {
  return (
    <ol className="section-hint__list" type={type}>
      {list.map((elem) => (
        <li className="section-hint__item" key={elem?.toString()}>
          {/* @ts-ignore */}
          {elem?.item ? (
            <>
              {/* @ts-ignore */}
              {elem.item}
              {/* @ts-ignore */}
              {numberedList(elem?.children, 'a')}
            </>
          ) : (
            elem
          )}
        </li>
      ))}
    </ol>
  );
}
