import { CardContainer as CardContainerD } from 'app/pages/cardsOgh/components/CardContainer';

 
 
/**
 * @deprecated
 * @see app/pages/cardsOgh/components/CardContainer
 * @type {(function({children: *, title: *}): *)|*}
 */
 
export const CardContainer = CardContainerD;
