/* eslint-disable */
import React, { useContext } from 'react';

import { BodyContext } from 'app/pages/providers';
import { AddButton as AddButtonUIKit } from 'core/uiKit/components/buttons/actionsButtons/Add.Button';

 
/* eslint-disable */
/**
 * Кнопка открывает диалоговое окно создания объекта
 * @returns {JSX.Element}
 * @constructor
 */
 
export const AddButton = () => {
  const { toggleShowDialogCreate } =
    useContext(BodyContext);
  return (
    <AddButtonUIKit
      onClick={() => toggleShowDialogCreate(true)}
    >
      Добавить
    </AddButtonUIKit>
  );
};
