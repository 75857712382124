 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Уточнение типа__.
 *
 * @type {{
 * accessor: 'buildings_type_spec_name',
 * Header: 'Уточнение типа',
 * sortable: true
 * }}
 * @augments Cell
 */
export const buildings_type_spec_name = {
  accessor: 'buildings_type_spec_name',
  Header: 'Уточнение типа',
  sortable: true,
};
