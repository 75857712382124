import { DndFileUploadFF } from 'core/form/finalForm/fields/prepared/fileUpload/DndFileUploadFF';
import { CommentFF } from 'core/form/finalForm/fields/prepared/textField/CommentFF';
import { DialogContentTextMUI } from 'core/uiKit/material-ui';

/**
 * Компонент BodyDialogAccept.
 *
 * @returns {JSX.Element}
 */
export const BodyDialogAccept = () => {
  return (
    <>
      <DialogContentTextMUI>
        Уведомление будет отправлено пользователю, создавшему объект
      </DialogContentTextMUI>
      <CommentFF />
      <DndFileUploadFF
        text={'Переместите файлы или нажмите для выбора'}
        name={'files'}
      />
    </>
  );
};
