import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

//
export const DUT_TYPE_NAME = 'installDut';

export const INSTALL_DUT_NAME = 'installDut';

/**
 * ## Тип навесного оборудования.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const InstallDut = ({ ...props }) => {
  const options = [
    { id: 'true', name: 'Да' },
    { id: 'false', name: 'Нет' },
  ];

  return (
    <Select
      {...props}
      options={options}
      label={'Не подлежит оснащению ДУТ'}
      name={INSTALL_DUT_NAME}
    />
  );
};
