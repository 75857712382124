import {
  COATING_FACE_TYPE_ID_NAME,
  CoatingFaceTypeId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingFaceTypeId';
import React from 'react';
// @ts-ignore
import { Field, WrappedFieldProps } from 'redux-form';
import { SelectRFProps } from 'types/inputs';

interface CoatingFaceTypeIdRFProps
  extends React.HTMLAttributes<HTMLSelectElement> {
  input?: WrappedFieldProps<unknown>; // явно указываем тип для input
}

/**
 * Обертка для RF inputa.
 *
 * @param {object} props - Properties.
 * @param {object} props.input - Input.
 * @param {object} props.otherProps - Other properties.
 * @returns {JSX.Element}
 */
const CoatingFaceTypeIdWithReduxForm: React.FC<CoatingFaceTypeIdRFProps> = ({
  input,
  ...otherProps
}) => {
  return <CoatingFaceTypeId {...input} {...otherProps} />;
};

/**
 * ReduxForm Вид покрытия (облицовка).
 *
 * @param props - Properties.
 * @returns Вид покрытия.
 */
export const CoatingFaceTypeIdRF: React.FC<SelectRFProps> = (props) => {
  return (
    <Field
      {...props}
      name={COATING_FACE_TYPE_ID_NAME}
      component={CoatingFaceTypeIdWithReduxForm}
    />
  );
};
