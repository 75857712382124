/* eslint-disable */
import React from 'react';
import { DelimitedNumericArrayParam, withDefault } from 'use-query-params';

import { OghStatusesFF, OGH_STATUSES_NAME } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
 
/**
 * name = state_id
 *
 */
 
export const OghStatusesFilter = () => (
  <OghStatusesFF className={filterInput} />
);

 
export { OGH_STATUSES_NAME };

 
export const oghStatusesParams = (initValue = [1, 4]) => {
  return {
    [OGH_STATUSES_NAME]: withDefault(DelimitedNumericArrayParam, initValue),
  };
};
