/* eslint-disable */
import React from 'react';
import { StringParam } from 'use-query-params';

import { TextFieldFF } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
export const FACTORY_NUMBER_NAME = 'factoryNumber';

 
 
/**
 *  Заводской номер  factoryNumber
 */
 
export const FactoryNumberFilter = (props) => (
  <TextFieldFF
    label={'Заводской номер'}
    {...props}
    className={filterInput}
    name={FACTORY_NUMBER_NAME}
  />
);

 
export const factoryNumberParams = {
  [FACTORY_NUMBER_NAME]: StringParam,
};
