import { createCard } from 'app/actions/odsObjectActions';
import DxfFileUpload from 'app/components/common/DxfFileUpload';
import hasFileField from 'app/selectors/registry/hasFileField';
import { isImprovementObject } from 'app/utils/checkOghTypeId';
import KeyboardDatePickerRF from 'core/form/reduxForm/fields/default/datePickers/KeyboardDatePickerRF';
import Select from 'core/newComponents/Select';
import { useNavigateRouter } from 'core/utils/router';
import { get, isFunction, values } from 'lodash';
import PropTypes from 'prop-types';
/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import DialogAction from './DialogAction';
import DialogAlert from './DialogAlert';

const propTypes = {
  handleSubmit: PropTypes.func,
  mode: PropTypes.object,
  oghTypeId: PropTypes.number,
  oghTypes: PropTypes.arrayOf(PropTypes.object),
  otherTerritoryTypes: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.any,
  ]),
  title: PropTypes.string,
  onCancel: PropTypes.func,
  onError: PropTypes.func,
  onOk: PropTypes.func,
};

const defaultProps = {
  title: 'Добавление',
};

class DialogCreate extends React.Component {
  state = {
    file: null,
  };

  componentDidUpdate() {
    const { mode, history } = this.props;
    if (mode && mode.doneCreateMode) {
      if (mode.relocate) {
        history.push(mode.relocate);
      }
    }
  }

  handleCancel = () => {
    this.setState({ file: null }, () => {
      const { onCancel } = this.props;
      if (isFunction(onCancel)) {
        onCancel();
      }
    });
  };

  PreloadedDictionary = (props) => {
    const { input, meta, modeStyle, options, ...rest } = props;
    return (
      <Select
        name={input.name}
        options={options}
        value={input.value}
        onChange={(value) => {
          return input.onChange(value);
        }}
        {...rest}
      />
    );
  };

  renderDatePicker = (props) => {
    return (
      <div style={{ width: 276 }}>
        <KeyboardDatePickerRF {...props} />
      </div>
    );
  };

  renderOtherTerritoryTypesSelect() {
    const { otherTerritoryTypes } = this.props;
    return (
      <div style={{ width: '100%', marginBottom: 30 }}>
        <Field
          component={this.PreloadedDictionary}
          id="otherImprovementObjectType"
          label="Детализация"
          name="otherImprovementObjectType"
          options={values(otherTerritoryTypes)}
        />
      </div>
    );
  }

  render() {
    const { handleSubmit, mode, oghTypes, oghTypeId, title, onOk, createCard } =
      this.props;

    return (
      <React.Fragment>
        <DialogAlert
          showCondition={Boolean(mode && mode.errorCreateMode)}
          onAfterHiding={() => createCard(null, null)}
        >
          {get(mode, 'message', '')}
        </DialogAlert>
        <DialogAction
          className="Dialog-Create"
          showCondition={get(mode, 'createModePreview')}
          title={title}
          onCancel={this.handleCancel}
          onOk={onOk && handleSubmit(onOk)}
        >
          <form id="edit-form" name="editForm">
            <div className="field-group">
              <Field
                component={this.renderDatePicker}
                id="startDate"
                label="Действует с"
                minDate={minDate()}
                name="startDate"
              />
              <div style={{ width: 276, marginTop: 2 }}>
                <Field
                  component={this.PreloadedDictionary}
                  disabled={true}
                  hintText=""
                  label="Тип"
                  name="type"
                  options={oghTypes}
                />
              </div>
            </div>
            {isImprovementObject(oghTypeId) &&
              this.renderOtherTerritoryTypesSelect()}
            {hasFileField(oghTypeId) && (
              <Field component={DxfFileUpload} name="file" />
            )}
          </form>
        </DialogAction>
      </React.Fragment>
    );
  }
}

DialogCreate.propTypes = propTypes;
DialogCreate.defaultProps = defaultProps;

const minDate = () => {
  let today = new Date();
  today.setDate(today.getDate() + 1);
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);
  return today;
};

const mapStateToProps = (state, props) => {
  const { oghTypeId } = props;

  const result = {
    initialValues: {
      startDate: minDate(),
      type: get(props, 'oghTypeId', null),
      file: null,
    },
    mode: state.card.mode,
    enableReinitialize: true,
  };

  if (isImprovementObject(oghTypeId)) {
    const defaultOtherImprovementObjectType = 2;
    result.initialValues.otherImprovementObjectType =
      defaultOtherImprovementObjectType;
  }

  return result;
};

const mapDispatchToProps = {
  createCard,
};

const Comp = connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'createCardForm' })(DialogCreate));

// todo: костыль на время. создан когда была переделка на новый роут.
//   нужно переделать на переменые из роута


 
export default (props) => {
  const navigate = useNavigateRouter();

   
   
/**
   * @deprecated
   * @see используй navigate
   */
  const history = { push: navigate };

  return <Comp {...props} history={history} navigate={navigate} />;
};
