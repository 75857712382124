import KeyboardDatePickerRF from 'core/form/reduxForm/fields/default/datePickers/KeyboardDatePickerRF';

/* eslint-disable */
export const KeyboardDateEndComponent = () => {
  return (
    <KeyboardDatePickerRF
      {...{
        name: 'endDate',
        label: 'Действует по',
        disabled: true,
      }}
    />
  );
};
