import NavItem from 'app/components/common/NavBar/NavItem';
import { pathAdmin } from 'app/pages/admin/path.Admin';
import { pathSubscriptions } from 'app/pages/admin/Subscriptions/path.Subscriptions';
import { useUser } from 'app/pages/providers';
/* eslint-disable */
import React, { lazy, useEffect } from 'react';

function lazyWithPreload(factory) {
  const Component = lazy(factory);
  Component.preload = factory;
  return Component;
}

const Subscriptions = lazyWithPreload(() =>
  import('app/pages/admin/Subscriptions'),
);

 
export const ViewApiSubscriptionsLink = () => {
  const { hasPermission } = useUser();

  const isShow = hasPermission(pathSubscriptions.privilegeView);

  useEffect(() => {
    isShow && Subscriptions.preload();
  }, [isShow]);

  return isShow ? (
    <NavItem link={`/${pathAdmin.path}/${pathSubscriptions.path}`}>
      {pathSubscriptions.name}
    </NavItem>
  ) : null;
};
