import { columnsProcessApproving } from 'app/components/card/tech/car/components/Tables/ProcessApprovingTable/columnsProcessApproving';
import { LinkTechMatchingIdCell } from 'core/uiKit/components/cells/LinkTechMatchingId.Cell';

/* eslint-disable */
describe('title columnsProcessApproving', () => {
  it('simple test', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act

    // Assert
    expect(columnsProcessApproving).toStrictEqual([
      {
        accessor: 'id',
        Header: 'Id (UI, Bus) запроса',
        sortable: true,
        Cell: LinkTechMatchingIdCell,
      },
      {
        accessor: 'create_date',
        Header: 'Дата формирования запроса',
        sortable: true,
      },
      {
        accessor: 'plan_date',
        Header: 'Дата изменения (план)',
        sortable: true,
      },
      {
        accessor: 'fact_date',
        Header: 'Дата изм. факт',
        sortable: true,
      },
      {
        accessor: 'state_name',
        Header: 'Статус запроса',
        sortable: true,
        Cell: expect.any(Function),
      },
      {
        accessor: 'request_type_name',
        Header: 'Тип заявки',
        sortable: true,
      },
      {
        accessor: 'request_reason_name',
        Header: 'Причина изменения',
        sortable: true,
      },
      {
        accessor: 'user_create',
        Header: 'Пользователь',
        sortable: true,
      },
    ]);
  });
});
