import { validateIntensityNumField } from 'app/components/card/ogh/Odh/components/CleanCategory/IntensityNumField/validateIntensityNumField';
import { REQUIRED_FIELD_MESSAGE } from 'app/constants/messages';
import { INTENSITY_NUM_NAME } from 'core/uiKit/preparedInputs/numberField/IntensityNum';

describe('ValidateIntensityNumField', function () {
  it.each`
    cleanCategory | intensity    | isOrphanObject | expected
    ${1}          | ${undefined} | ${false}       | ${{ [INTENSITY_NUM_NAME]: REQUIRED_FIELD_MESSAGE }}
    ${2}          | ${undefined} | ${false}       | ${{ [INTENSITY_NUM_NAME]: REQUIRED_FIELD_MESSAGE }}
    ${2}          | ${undefined} | ${true}        | ${{}}
  `(
    'test validate cleanCategory $cleanCategory $isOrphanObject',
    ({ cleanCategory, expected, intensity, isOrphanObject }) => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      const errors = {};

      // Act
      validateIntensityNumField(
        errors,
        cleanCategory,
        intensity,
        isOrphanObject,
      );
      // Assert
      expect(errors).toStrictEqual(expected);
    },
  );
});
