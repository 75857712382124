 
/**
 *
 * @typedef {object} Cell
 * @property {string} accessor - Идентификатор колонки.
 * @property {string} Header - Хедр.
 * @property {boolean} [sortable] - Сортировка.
 * @property {string} [minWidth] - Минимальная ширина колонки. По дефолту 100px.
 * @property {['center', 'inherit', 'justify', 'left', 'right']} [align='center'] - Выравнивание текста. По дефолту 'center'.
 */
 
/* eslint-disable */
/**
 * Колонка __Входит в__. Так надо =).
 *
 * @type {{ accessor: 'parent_name',
 * Header: 'Входит в',
 * sortable: true}}
 * @augments Cell
 */
export const parent_name = {
  accessor: 'parent_name',
  Header: 'Входит в',
  sortable: true,
};
