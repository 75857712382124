import {
  BASE_PGM,
  IMPROVEMENT_OBJECT,
  ODH,
  OZN,
  SPA,
  YARD,
} from 'app/constants/oghTypes';

/**
 * Функция проверки на родительский паспорт.
 *
 * @param {number} typeId - Тип ОГХ.
 * @returns {boolean}
 */
export const checkRootCard = (typeId) => {
  return [OZN, ODH, SPA, YARD, IMPROVEMENT_OBJECT, BASE_PGM].includes(typeId);
};
