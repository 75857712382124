import { preparationValues } from 'core/utils/helpers/preparationValues';

/**
 *
 * Функция подготовки обьектов для отправки на бэк.
 *
 * @deprecated Заюзай другой импорт core/utils/helpers/preparationValues.
 * @param {object} values - The values.
 * @returns {{}}
 */
export const preparationSearchFormValues = preparationValues;
