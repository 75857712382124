import { useFetchOghTable } from 'app/pages/registry/ogh/api';
import { useParamsTable } from 'core/uiKit/components/tables/Table';

import { useFilterQueryParamsPpi } from './Filter.Ppi';

/**
 * Setup Компонент таблицы __Элементы благоустройства для маломобильных групп населения__.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - Id типа огх.
 * @param {number} props.parentTypeId - Id типа родителя.
 * @returns {object} - Объект с данными.
 */
export const useSetupTablePpi = ({ typeId, parentTypeId }) => {
  const { prepareFilters } = useFilterQueryParamsPpi();
  const { queryTable } = useParamsTable();

  const queryKey = [
    'TablePpi',
    {
      ...queryTable,
      ...prepareFilters,
      parentTypeId,
      typeId,
    },
  ];

  const { table, isLoading, count } = useFetchOghTable(queryKey);

  return {
    count,
    isLoading,
    table,
  };
};
