// 14 Тротуары
/* eslint-disable  */

import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { validateRequiredFieldsAsync } from 'app/components/card/common/validation';
import {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import {
  AUTO_CLEANING_TYPE,
  MANUAL_CLEANING_TYPE,
} from 'app/constants/cleaningTypes';
import getCleaningType from 'app/selectors/card/getCleaningType';
import getCoatingTypes from 'app/selectors/card/getCoatingTypes';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import getFormValues from 'app/selectors/form/getFormValues';
import { mandatoryFields } from 'app/utils/card/isMandatory';
import { transformValues } from 'app/utils/form/transformation';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';

import submitCard from '../submit';
import SidewalksComponent from './SidewalksComponent';
import { numericFields, validate } from './validate';

const propTypes = {
  card: PropTypes.object,
  changeFieldValue: PropTypes.func,
  coatingTypes: PropTypes.array,
  createMode: PropTypes.bool,
  documents: PropTypes.array,
  editMode: PropTypes.bool,
  formValues: PropTypes.object,
  mode: PropTypes.object,
  required: PropTypes.bool,
};

/**
 * SidewalksCardWraper.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - JSX.
 * @example ----
 */
const SidewalksCardWraper = (props) => {
  return <SidewalksComponent {...props} />;
};

SidewalksCardWraper.propTypes = propTypes;

const sidewalksDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

/**
 * Функция получения и установки изначальных значений из State.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {object} - Поля.
 * @example ----
 */
const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);

  const result = {
    coatingTypes: getCoatingTypes(state, props),

    createMode: isCreateMode(state, props),

    editMode: isEditMode(state, props),
    enableReinitialize: true,

    formValues: getFormValues(state),
    initialValues: {
      area: parse('area'),
      auto_clean_area: parse('auto_clean_area'),
      bord_begin: parse('bord_begin'),
      bord_end: parse('bord_end'),
      cleaning_type: getCleaningType(state, props),
      coating_group_id: parse('coating_group_id.coating_group'),
      coating_type_id: parse('coating_type_id.coating_type'),
      customer_id: parse('customer_id'),
      description: parse('description'),
      distance: parse('distance'),
      endDate: parseDate(props.card.end_date),
      flat_element_type_id: parse('flat_element_type_id.flat_element_type'),
      is_diff_height_mark: parse('is_diff_height_mark'),
      manual_clean_area: parse('manual_clean_area'),
      no_clean_area: parse('no_clean_area'),
      odh_axis: parse('odh_axis'),
      odh_side_id: parse('odh_side_id.odh_side'),
      owner_id: parse('owner_id'),
      parent_name: props.card.parent_info,
      startDate: parseDate(props.card.start_date),
      totalAreaGeo: parse('total_area_geo'),
      utn_area: parse('utn_area'),
      width_begin: parse('width_begin'),
      width_end: parse('width_end'),
    },
    onSubmit: onSubmit.bind({ props }),

    required: isRequired(state, props),
    validate,
  };

  return sidewalksDocumentsReducer(result, state, props);
};

/**
 * Функция создания атрибутов.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {object} - Возвращают атрибуты.
 * @example ----
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

const dictFields = [
  'odh_side_id',
  'flat_element_type_id',
  'coating_group_id',
  'coating_type_id',
];

/**
 * Функция сабмита.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {void}
 */
function onSubmit(formValues) {
  const { card } = this.props;
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };

  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[card.type_id],
  );
  formValuesWithAttribute.is_diff_height_mark = Boolean(
    formValuesWithAttribute.is_diff_height_mark,
  );

  formValuesWithAttribute.manual_clean_area = 0;
  formValuesWithAttribute.auto_clean_area = 0;
  formValuesWithAttribute.no_clean_area = 0;

  if (formValuesWithAttribute.cleaning_type === MANUAL_CLEANING_TYPE) {
    formValuesWithAttribute.manual_clean_area = formValuesWithAttribute.area;
  } else if (formValuesWithAttribute.cleaning_type === AUTO_CLEANING_TYPE) {
    formValuesWithAttribute.auto_clean_area = formValuesWithAttribute.area;
  } else {
    formValuesWithAttribute.no_clean_area = formValuesWithAttribute.area;
  }
  delete formValuesWithAttribute.cleaning_type;

  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, {
      dictFields,
      numericFields,
    }),
  );
}

/**
 * Функция получения экшенов.
 *
 * @param {Function} dispatch - Функция диспатч.
 * @returns {{changeFieldValue: mapDispatchToProps.changeFieldValue}} - Экшен changeFieldValue.
 * @example ----
 */
const mapDispatchToProps = (dispatch) => ({
  /**
   *
   * @param field
   * @param value
   */
  changeFieldValue: (field, value) => {
    dispatch(change('editorCard', field, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'editorCard' })(SidewalksCardWraper));
