/* eslint-disable  */
import { AutocompleteAsync } from 'core/uiKit/inputs/selects/AutocompleteAsync';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { SelectProps } from 'types/inputs';

import { fetchAddressDetails } from './api';

export const ADDRESS_AL_NAME = 'address_al';

interface AddressAlProps extends SelectProps {
  value: { id: number; name: string } | undefined;
  onChange: (value: { id: number; name: string } | undefined) => void;
  street_id?: number;
  setOptions: Dispatch<
    SetStateAction<
      {
        id: number;
        name: string;
        address_name: string;
        is_actual: boolean;
        kl: string;
        unad: string;
        unom: string;
      }[]
    >
  >;
}

/**
 * Дом, корпус, строение.
 *
 * @param props - Properties.
 * @returns {JSX}
 */
export const AddressAl: FC<AddressAlProps> = (props) => {
  /**
   * Обертка для функции. Дабы положить street_id.
   *
   * @param data - Данные.
   * @param data.maxRows - Maximum number of rows.
   * @param data.startsWith - Start with.
   * @param data.street_id - ID улицы.
   * @returns Словарь.
   */
  const fetchAddressDetailsWrapper = async (data: {
    maxRows: number;
    startsWith: string;
    street_id: number;
  }) => {
    const res = await fetchAddressDetails(data);

    // костыли так как значения для других инпутов приходить в этом options.
    props.setOptions(res || []);

    return res;
  };

  return (
    <AutocompleteAsync
      {...props}
      fetchFunction={fetchAddressDetailsWrapper}
      additionalDataForRequest={{ street_id: props.street_id || 0 }}
      fetchFunctionName={ADDRESS_AL_NAME}
      label={'Дом, корпус, строение'}
      name={ADDRESS_AL_NAME}
    />
  );
};
