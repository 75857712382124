import { GeometryTypes } from 'core/uiKit/components/DTW/contexts/utils/types.d';

import content from '../content/draw';
type GeometryTypesEgip = keyof typeof content;
type GeometryTypesValues = `${GeometryTypes}`;

/**
 * Получение контента для египа.
 *
 * @param geometryType - Тип геометрии.
 * @returns Контент.
 */
export const getContent = (
  geometryType: GeometryTypesEgip | GeometryTypesValues,
) => {
  const key = geometryType.charAt(0).toUpperCase() + geometryType.slice(1);
  return content[key as GeometryTypesEgip] || {};
};
