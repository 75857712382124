import { PrintButtonOgh } from 'app/pages/components';

import { useFilterQueryParamsBuilding } from './Filter.Building';

/* eslint-disable */
/**
 * Кнопка печати реестра "Объекты капитального строительства".
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - Id типа огх.
 * @param {number} props.parentTypeId - Id типа родителя огх.
 * @returns {JSX.Element} - JSX.
 * @example -----
 */
export const PrintButtonBuilding = ({
  typeId,
  parentTypeId,
}) => {
  const { prepareFilters = {} } =
    useFilterQueryParamsBuilding();

  return (
    <PrintButtonOgh
      {...{
        typeId,
        prepareFilters: { ...prepareFilters, parentTypeId },
      }}
    />
  );
};
