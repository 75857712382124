import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { MultiSelect } from 'core/uiKit/inputs/selects/MultiSelect';
import React from 'react';

export const CAR_SEASONS_NAME = 'carSeasons';

/**
 * ## Сезонность.
 *
 * @param {*} props - Properties.
 * @returns {JSX.Element}
 */
export const CarSeasons = (props) => {
  const dict = 'carSeason';
  const { data: options = [] } = useGetDictionary(dict);

  return (
    <MultiSelect
      {...props}
      {...{ options }}
      label={'Сезонность'}
      name={CAR_SEASONS_NAME}
    />
  );
};
