/**
 * Функция createObjectForMap.
 *
 * @param {object} item - Item.
 * @returns {object}
 */
export const createObjectForMap = (item) => {
  const hintFields = [
    {
      key: 'title',
      rawValue: 'Объект РЕОН',
      value: 'Объект РЕОН',
    },
    {
      key: 'descr',
      rawValue: item.descr,
      value: `Кадастровый номер: ${item.descr}`,
    },
    {
      key: 'prizn_sobstv',
      rawValue: item.prizn_sobstv,
      value: `Признак собственности: ${item.prizn_sobstv}`,
    },
    {
      key: 'prizn_prava',
      rawValue: item.prizn_prava,
      value: `Признак оформленного права: ${item.prizn_prava}`,
    },
    {
      key: 'Адрес',
      rawValue: item.address,
      value: `Адрес: ${item.address}`,
    },
  ];

  const hint = hintFields.filter((item) => item.rawValue);

  return {
    hint: {
      object_attribute_hint: hint,
      object_attribute_hint_short: [
        {
          key: 'name',
          value: item.descr,
        },
      ],
    },
    ...item.geometry,
  };
};
