import { post } from 'app/api/crud/post';
import { DTW_API_URL } from 'config/env';

import { AuthTokensDTW_Type } from '../../../types';

/**
 * Функция обновления токенов
 * авторизации в цифровом двойнике.
 *
 * @param refreshToken - Рефреш токен.
 * @returns A.
 */
export const refreshDTWToken = (refreshToken: string) => {
  return post<AuthTokensDTW_Type>(
    'auth/refresh',
    { refreshToken },
    {
      host: DTW_API_URL,
    },
  );
};
//
// /**
//  * Функция обновления токенов
//  * авторизации в цифровом двойнике.
//  *
//  * @param refreshToken - Рефреш токен.
//  * @returns A.
//  */
// export const refreshDTWToken = (refreshToken: string) => {
//   return fetch(`${DIGITAL_TWIN_URLS.HOST}${DIGITAL_TWIN_URLS.REFRESH_TOKEN}`, {
//     body: JSON.stringify({ refreshToken }),
//     method: 'POST',
//   }).then((res) => res.json() as Promise<AuthTokensDTW_Type>);
// };
