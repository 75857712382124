import { Map } from '@2gis/mapgl/types';
import { MarkerIconOptions } from '@2gis/mapgl/types';

import {
  Control,
  GenericCoordinates,
  GeometryTypes,
  Marker,
  ShapeOptions,
} from '../../../../types.d';

/**
 *
 */
export default class Shape<Ctype extends Control = Control> {
  // @ts-ignore
  public static mapgl: typeof mapgl | null = null;

  /**
   * Инициализация библиотеки.
   *
   * @param loadedMapgl - Загруженная библиотека.
   */
  // @ts-ignore
  public static initMapgl(loadedMapgl: typeof mapgl) {
    Shape.mapgl = loadedMapgl;
  }

  protected cursor: string;

  protected options: ShapeOptions<Ctype>;

  protected map: Map;

  protected geometry: Marker | null = null;

  protected control: Ctype;

  public coordinatesToCheckMouseEvent: number[][][] = [[]];

  /**
   * Создает контрол для изменения размера геометрии.
   *
   * @param map - Экземпляр карты.
   * @param options - Настройки.
   */
  constructor(map: Map, options: ShapeOptions<Ctype>) {
    const { cursor, coordinates, ...markerOptions } = options;

    this.map = map;
    this.cursor = cursor || '';
    this.options = options;
    this.control = markerOptions.userData.control;
    this.coordinatesToCheckMouseEvent =
      this.iconSizeToPolygonCoordinates(coordinates);
    this.create({
      interactive: false,
      zIndex: 10001,
      ...markerOptions,
      coordinates,
      userData: {
        ...markerOptions.userData,
        coordinates,
        coordinatesToCheckMouseEvent: this.coordinatesToCheckMouseEvent,
        type: GeometryTypes.Marker,
      },
    });
  }

  /**
   * GetGeometry.
   *
   * @returns Marker.
   */
  getGeometry() {
    return this.geometry;
  }

  /**
   * GetControl.
   *
   * @returns Marker.
   */
  getControl() {
    return this.control;
  }

  /**
   * Создание иконки для перемещения геометрии.
   *
   * @param options - Настройки.
   * @returns Marker.
   */
  create(options: ShapeOptions<Ctype>) {
    options ??= this.options;

    if (Shape.mapgl && this.map) {
      this.geometry = new Shape.mapgl.Marker(
        this.map,
        options,
      ) as unknown as Marker;
      return this.geometry;
    }

    return null;
  }

  /**
   * Обновление координат.
   *
   * @param root0 - Координаты.
   * @param root0.lngLat - Координаты.
   * @returns This.
   */
  updateCoordinates({
    lngLat,
  }: {
    lngLat: GenericCoordinates<GeometryTypes.Point>;
  }) {
    if (this.geometry) {
      this.geometry.setCoordinates(lngLat);
      this.geometry.userData.coordinates = lngLat;
    }
    this.updateCoordinatesToCheckMouseEvent(lngLat);
    return this;
  }

  /**
   * Обновляет координаты для проверки событий мыши.
   *
   * @param lngLat - Координаты.
   */
  updateCoordinatesToCheckMouseEvent(lngLat: number[]) {
    this.coordinatesToCheckMouseEvent =
      this.iconSizeToPolygonCoordinates(lngLat);
    if (this.geometry)
      this.geometry.userData.coordinatesToCheckMouseEvent =
        this.coordinatesToCheckMouseEvent;
  }

  /**
   * Обновление иконки.
   *
   * @param icon - Новая иконка.
   */
  updateIcon(icon: MarkerIconOptions) {
    this.geometry?.setIcon(icon);
    return;
  }

  /**
   * Скрытие контрола.
   *
   * @returns This.
   */
  hide() {
    this.geometry?.hide();

    if (this.geometry) {
      // Поскольку проверка событий мыши выполняется
      // в событии mousemove на карте нужно сбросить
      // координаты для проверки.
      this.geometry.userData.coordinatesToCheckMouseEvent = [[]];
    }

    return this;
  }

  /**
   * Координаты для проверки событий мыши.
   *
   * @param coordinates - Координаты.
   * @returns Координаты для проверки событий мыши.
   */
  iconSizeToPolygonCoordinates(coordinates: number[]) {
    const [width, height] = this.options.iconSize || [];

    if (!width || !height) {
      return [];
    }

    const [pointXInPixels, pointYInPixels] = this.map.project(coordinates);
    const southWest = this.map.unproject([
      pointXInPixels - width / 2,
      pointYInPixels + height / 2 + 0.5,
    ]);
    const northWest = this.map.unproject([
      pointXInPixels - width / 2,
      pointYInPixels - (height / 2 - 0.5),
    ]);
    const northEast = this.map.unproject([
      pointXInPixels + width / 2,
      pointYInPixels - (height / 2 - 0.5),
    ]);
    const southEast = this.map.unproject([
      pointXInPixels + width / 2,
      pointYInPixels + height / 2 + 0.5,
    ]);

    return [[southWest, northWest, northEast, southEast, southWest]];
  }

  /**
   * Показ контрола.
   *
   * @returns This.
   */
  show() {
    this.geometry?.show();

    if (this.geometry) {
      this.geometry.userData.coordinatesToCheckMouseEvent =
        this.coordinatesToCheckMouseEvent;
    }

    return this;
  }

  /**
   * Установка курсора.
   *
   * @param cursor - Курсор.
   */
  setCursorStyle(cursor?: string | null) {
    const mapCanvas = this.map.getContainer().querySelector('canvas');
    if (mapCanvas) {
      mapCanvas.style.cursor = cursor === null ? '' : cursor || this.cursor;
    }
  }

  /**
   * Уничтожает контрол.
   *
   * @returns This.
   */
  destroy() {
    this.geometry?.destroy();
    return this;
  }
}
