import React, { useMemo } from 'react';
import { Form } from 'react-final-form';

import { FinalFormT } from './types.d';

/**
 * Final form обертка.
 *
 * @param props - Properties.
 * @returns {JSX}
 */
export const FinalForm: FinalFormT = (props) => {
  const initialValuesRef = useMemo(
    () => props.initialValues,

    // eslint-disable-next-line
    [Object.keys(props?.initialValues || {}).length],
  );

  return (
    <Form
      {...props}
      initialValues={props.noMeno ? props?.initialValues : initialValuesRef}
      render={({ handleSubmit }) => {
        return (
          <form noValidate onSubmit={handleSubmit}>
            {props.children}
          </form>
        );
      }}
    />
  );
};
