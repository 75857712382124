import { Cell } from 'types/table';

/**
 * Колонка __Состояние (из ЕРЗ)__.
 *
 * @type {{
 * accessor: 'erz_state_id',
 * Header: 'Состояние (из ЕРЗ)',
 * sortable: true,
 * }}
 * @augments Cell
 */
export const erz_state_id: Cell = {
  Header: 'Состояние (из ЕРЗ)',
  accessor: 'erz_state_id',
  sortable: true,
};
