import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import isMandatory from 'app/utils/card/isMandatory';
import { CleaningTypeRF } from 'core/form/reduxForm/fields/prepared/selects/simple/CleaningType.RF';
import { CLEANING_TYPE_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CleaningType';
import React from 'react';

/**
 * Select Field RF __Способ уборки__.
 *
 * @param props - Пропсы.
 * @returns
 */
export const CleaningTypeField = (props: object) => {
  const { typeId } = useGetCurrentCard();
  const { editMode } = useMode();
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();
  return (
    // @ts-ignore
    <CleaningTypeRF
      {...props}
      disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
      required={isMandatory(typeId, CLEANING_TYPE_NAME, editMode)}
    />
  );
};
