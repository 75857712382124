/* eslint-disable  */
import { Link } from 'react-router-dom';

/**
 *
 * @param root0
 * @param root0.value
 */
export const LinkTechMatchingIdCell = ({ value = '...' }) => {
  return <Link to={`/tech/matching/${value}`}>{value}</Link>;
};
