// optionally use the query-string parse / stringify functions to
// handle more advanced cases than URLSearchParams supports.
import { parse, stringify } from 'query-string';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

/**
 * Провайдер для use-query-params.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const QueryParamProviderODS = (props) => (
  <QueryParamProvider
    {...props}
    adapter={ReactRouter6Adapter}
    options={{
      objectToSearchString: stringify,
      searchStringToObject: parse,
    }}
  >
    {props.children}
  </QueryParamProvider>
);
