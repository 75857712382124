import './Panel.scss';

import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { WithMapContext } from 'app/components/map/withMap';
import { HOLE, LINESTRING, POINT, POLYGON } from 'app/constants/geometryTypes';
import { Dialogs } from 'app/pages/cardsOgh/components/DIalogs/Dialogs';
import { useGetAllowedGeometryTypesRF } from 'app/pages/cardsOgh/components/Map/MapEditPanel/hooks/useGetAllowedGeometryTypes';
import { NeighboursButton } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/buttons/NeighboursButton';
import { ShowChildrenButton } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/buttons/ShowChildrenButton';
import { ShowDistrictBoundaries } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/buttons/ShowDistrictBoundaries/ShowDistrictBoundaries';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { isEmpty } from 'lodash';
import React from 'react';

import { CheckGeometryButton } from './buttons/CheckGeometry.Button';
import DeleteSelectedButton from './buttons/DeleteSelectedButton';
import DownloadGeometryButton from './buttons/DownloadGeometryButton';
import DrawButton from './buttons/DrawButton';
import ReonButton from './buttons/ReonButton';

/**
 * Класс MapEditPanel.
 *
 * @class MapEditPanel
 * @returns {JSX.ElementClass} - Жопа.
 */
class MapEditPanelEgipComp extends React.Component {
  //

  /**
   * Конструктор.
   *
   * @param {object} props - Хопа.
   * @example -
   */
  constructor(props) {
    super(props);

    /**
     *
     * @type {{activeGeometry: null}}
     */
    this.state = {
      activeGeometry: null,
    };
  }

  static contextType = WithMapContext;

  /**
   * UNSAFE_componentWillReceiveProps.
   *
   * @param {object} nextProps - Следующие пропсы.
   * @returns {void} - Nothing.
   * @example - Жопа.
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { editable } = this.props;
    const { activeGeometry } = this.state;

    const activeGeometryNotAllowed =
      activeGeometry &&
      !isEmpty(nextProps.allowedGeometryTypes) &&
      !nextProps.allowedGeometryTypes.includes(activeGeometry);

    const exitEditMode = editable && !nextProps.editable;

    if (activeGeometryNotAllowed || exitEditMode) {
      this.stopDrawing();
    }
  }

  /**
   * Метод onDrawClick.
   *
   * @param {boolean} active - Флаг.
   * @param {*} geometryType - Тип геометрии.
   * @param {Function} drawFunction - Функция отрисовки.
   * @returns {void} - Nothing.
   * @example ---
   */
  onDrawClick = (active, geometryType, drawFunction) => {
    if (active) {
      this.stopDrawing();
    } else {
      this.context.callMapFunction(drawFunction, this.props.currentCard);
      this.changeActiveGeometry(geometryType);
    }
  };

  /**
   * Функция changeActiveGeometry.
   *
   * @param {object} activeGeometry - Активная геометрия.
   * @returns {void}
   */
  changeActiveGeometry = (activeGeometry) => {
    this.setState({ activeGeometry });
  };

  /**
   * Метод stopDrawing.
   *
   * @returns {void} - Nothing.
   */
  stopDrawing = () => {
    this.context.callMapFunction('stopDrawing', this.props.currentCard);
    this.changeActiveGeometry(null);
  };

  /**
   * Метод getGeometry.
   *
   * @returns {object} - Геометрия.
   */
  getGeometry = () => {
    const { currentCard } = this.props;
    return this.context.getGeometry(currentCard.record_id);
  };

  /**
   * Метод render.
   *
   * @returns {JSX.Element} - JSX.
   * @example -
   */
  render() {
    const { editable, allowedGeometryTypes, currentCard } = this.props;
    const { activeGeometry } = this.state;

    const polygonAllowed = allowedGeometryTypes.includes(POLYGON);
    const pointAllowed = allowedGeometryTypes.includes(POINT);
    const lineAllowed = allowedGeometryTypes.includes(LINESTRING);

    return (
      <div className="map-edit">
        <ShowDistrictBoundaries />
        <DownloadGeometryButton
          currentCard={currentCard}
          getGeometry={this.getGeometry}
          isMapLoaded={this.context.isMapLoaded}
        />
        <NeighboursButton />
        <ReonButton
          currentCard={currentCard}
          showAlert={this.props.showAlert}
        />
        <Dialogs>
          <ShowChildrenButton />
        </Dialogs>
        <CheckGeometryButton showAlert={this.props.showAlert} />
        <DrawButton
          active={activeGeometry === POINT}
          disabled={!editable || !pointAllowed}
          geometryType={POINT}
          onClick={this.onDrawClick}
        />
        <DrawButton
          active={activeGeometry === LINESTRING}
          disabled={!editable || !lineAllowed}
          geometryType={LINESTRING}
          onClick={this.onDrawClick}
        />
        <DrawButton
          active={activeGeometry === POLYGON}
          disabled={!editable || !polygonAllowed}
          geometryType={POLYGON}
          onClick={this.onDrawClick}
        />
        <DrawButton
          active={activeGeometry === HOLE}
          disabled={!editable || !polygonAllowed}
          geometryType={HOLE}
          onClick={this.onDrawClick}
        />
        <DeleteSelectedButton
          callMapFunction={this.context.callMapFunction}
          currentCard={currentCard}
          disabled={!(editable && this.context.isMapLoaded)}
        />
      </div>
    );
  }
}

/**
 * Обертка для класса для хуков.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const MapEditPanelEgip = (props) => {
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();
  const currentCard = useGetCurrentCard();
  const { editMode } = useMode();
  const allowedGeometryTypes = useGetAllowedGeometryTypesRF();

  return (
    <MapEditPanelEgipComp
      {...props}
      {...{
        allowedGeometryTypes,
        currentCard,
        editable: editMode && isEditCurrentObjectIsExternalSystem,
      }}
    />
  );
};
