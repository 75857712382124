import { useFetchOghTable } from 'app/pages/registry/ogh/api';
import { useParamsTable } from 'core/uiKit/components/tables/Table';

import { useFilterQueryParamsStoragePlace } from './Filter.StoragePlace';
import type { TableStoragePlaceProps } from './Table.StoragePlace.d';

/**
 * Setup Компонентa таблицы __Велопарковки__.
 *
 * @param params - Параметры.
 * @param params.typeId - Id типа огх.
 * @param params.parentTypeId - Id типа родителя огх.
 * @returns Object.
 */
export const useSetupTableStoragePlace = ({
  typeId,
  parentTypeId,
}: TableStoragePlaceProps) => {
  const { prepareFilters } = useFilterQueryParamsStoragePlace();
  const { queryTable } = useParamsTable();

  const queryKey = [
    'TableStoragePlace',
    {
      ...queryTable,
      ...prepareFilters,
      parentTypeId,
      typeId,
    },
  ];

  const { table, isLoading, count } = useFetchOghTable(queryKey);

  return {
    count,
    isLoading,
    table,
  };
};
