import * as adminRolesActions from 'app/actions/adminRoles';
import Form from 'app/components/common/KeyForm';
import DialogCancelRole from 'app/components/dialogs/admin/roles/roleCard/DialogCancelRole';
import DialogDeleteRole from 'app/components/dialogs/admin/roles/roleCard/DialogDeleteRole';
import cardFormValidators from 'app/utils/admin/cardFormValidators';
import cn from 'classnames';
import PropTypes from 'prop-types';
/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';

import Controls from './Controls';
import Functions from './Functions';
import Properties from './Properties';

class CardForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    heading: PropTypes.string,
    roleId: PropTypes.string,
    fetchFormData: PropTypes.func,
    isReadOnly: PropTypes.bool,
    isCreateDateHidden: PropTypes.bool,
    onSubmit: PropTypes.func,
  };

  componentDidMount() {
    this.props.fetchFormData(this.props.roleId);
  }

  render() {
    const { heading, roleId, isReadOnly, isCreateDateHidden, onSubmit } =
      this.props;

    return (
      <Form
        className={cn(this.props.className)}
        model="rrf.admin.role"
        validators={cardFormValidators}
        onSubmit={onSubmit}
      >
        <h1>{heading}</h1>
        <Properties
          className="my-4"
          isCreateDateHidden={isCreateDateHidden}
          isReadOnly={isReadOnly}
        />
        <Functions className="my-4" isReadOnly={isReadOnly} />
        <Controls className="my-4" isReadOnly={isReadOnly} roleId={roleId} />
        <DialogDeleteRole />
        <DialogCancelRole />
      </Form>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    fetchFormData: function (roleId) {
      return dispatch(adminRolesActions.fetchFormData({ id: roleId }));
    },
  };
}

 
export default connect(mapStateToProps, mapDispatchToProps)(CardForm);
