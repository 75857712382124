/* eslint-disable */
import { shallow } from 'enzyme';

import { RANDOM_ID, RANDOM_WORD } from 'core/forTesting/constants';
import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { PrintPlantationTab } from 'app/components/card/ogh/ImprovementTerritoryCard/components/PlantationTab/tables/Print.PlantationTab';
import { print } from 'app/api/requests/print';

jest.mock('app/api/hooks/useGetCurrentCard');
jest.mock('app/api/requests/print');

/* eslint-disable */
describe('file Print.PlantationTab', () => {
  it('text', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetCurrentCard.mockReturnValue({});
    // Act
    const wrapper = shallow(<PrintPlantationTab code={RANDOM_ID} />);

    // Assert
    expect(wrapper.text()).toBe('<PrintButton />');
  });

  it('props', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const RECORD_ID = 'recordId' + RANDOM_ID;
    const ROOT_ID = 'rootId' + RANDOM_ID;
    const TYPE_ID = 'typeId' + RANDOM_ID;
    useGetCurrentCard.mockReturnValue({
      recordId: RECORD_ID,
      rootId: ROOT_ID,
      typeId: TYPE_ID,
    });
    // Act
    const wrapper = shallow(<PrintPlantationTab code={RANDOM_ID} />);

    // Assert
    expect(wrapper.prop('prepareFilters')).toStrictEqual({
      id: RECORD_ID,
      rootId: ROOT_ID,
      typeId: TYPE_ID,
    });
    expect(wrapper.prop('printFn')).toStrictEqual(expect.any(Function));
  });

  it('printFn', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const RECORD_ID = 'recordId' + RANDOM_ID;
    const ROOT_ID = 'rootId' + RANDOM_ID;
    const TYPE_ID = 'typeId' + RANDOM_ID;
    const CODE = 'code_' + RANDOM_ID;
    useGetCurrentCard.mockReturnValue({
      recordId: RECORD_ID,
      rootId: ROOT_ID,
      typeId: TYPE_ID,
    });
    // Act
    const wrapper = shallow(<PrintPlantationTab code={CODE} />);
    wrapper.prop('printFn')({ RECORD_ID, RANDOM_WORD });
    // Assert
    expect(print).toHaveBeenCalledWith(CODE, {
      RECORD_ID,
      RANDOM_WORD,
    });
  });
});
