import { useTableLittleForm } from 'app/pages/registry/ogh/yard/LittleForm/useTable.LittleForm';
import { Table } from 'core/uiKit/components/tables/Table';

import { getColumnsLittleForm } from './columns.LittleForm';

/**
 * Таблица реестров __Малые архитектурные формы__.
 *
 * @param {object} props - Properties.
 * @param {number} props.typeId - Id типа огх.
 * @param {number} props.parentTypeId - Id типа родителя.
 * @returns {JSX.Element} - JSX.
 * @example ---
 */
export const TableLittleForm = ({ typeId, parentTypeId }) => {
  const { table, isLoading, count } = useTableLittleForm({
    parentTypeId,
    typeId,
  });

  return (
    <Table
      columns={getColumnsLittleForm()}
      data={table || []}
      isLoading={isLoading}
      total={count}
    />
  );
};
