import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { useEditorCardSelector } from 'app/selectors/useSelectors/useEditorCard.Selector';
import { LifeFormTypeIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/LifeFormTypeId.RF';
import React from 'react';

/**
 * Wrapper for LifeFormTypeIdRF.
 *
 * @param {*} props - Props.
 * @returns {*}
 */
export const LifeFormTypeIdRfWrapper = (props) => {
  const { plantation_type_id } = useEditorCardSelector();

  const { data: plantationTypeDict = [] } = useGetDictionary('plantationType');

  /**
   * Filter life form type.
   *
   * @param {*} dict - A.
   * @returns {*}
   */
  const filter = (dict) => {
    const filtered = plantationTypeDict.find(
      (type) => type.id === plantation_type_id,
    );

    if (filtered) {
      return dict.filter((type) => {
        return filtered.life_form_type_ids.includes(type.id);
      });
    }

    return dict;
  };

  return <LifeFormTypeIdRF filter={filter} {...props} />;
};
