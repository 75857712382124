export const tl_types = [
  {
    id: 1,
    name: 'Объекты, переданные в эксплуатацию',
  },
  {
    id: 3,
    name: 'Объекты, переданные в эксплуатацию (автомобильные дороги)',
  },
  {
    id: 2,
    name: 'Паспортизированные объекты',
  },
  {
    id: 4,
    name: 'Паспортизированные объекты (автомобильные дороги)',
  },
];
