import {
  MATERIAL_ID_NAME,
  MaterialId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/MaterialId';
import { Field } from 'redux-form';

/* eslint-disable */
const MaterialIdWithReduxForm = ({ input, ...props }) => {
  return <MaterialId {...input} {...props} />;
};

export const MaterialIdRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={MATERIAL_ID_NAME}
      component={MaterialIdWithReduxForm}
    />
  );
};
