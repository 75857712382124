import { isFuture, isPast, toDateTime } from 'app/utils/dateUtils';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';
/* eslint-disable */
const getInfoCert = async ({
  subjectInfo,
  issuerInfo,
  validPeriod: { to, from } = {},
  certApi = {},
  privateKey = {},
} = {}) => {
  let uniqueContainerName;
  let uniqueContainerNameError;
  // const publicKey = await PublicKey;
  let algorithmName;
  let status;
  let statusError;
  let IsValid;

  const providerName = await privateKey.ProviderName;

  if (typeof certApi?.PublicKey === 'function') {
    algorithmName = await certApi
      ?.PublicKey()
      .then((e) => e.Algorithm)
      .then((e) => e.FriendlyName);
  }
  try {
    const { UniqueContainerName } = privateKey;
    uniqueContainerName = await UniqueContainerName;
  } catch (e) {
    uniqueContainerName = e.message;
    uniqueContainerNameError = true;
  }

  try {
    IsValid = await certApi.IsValid().then((res) => res.Result);
  } catch (e) {}

  if (isFuture(from)) {
    status = 'Срок действия не наступил';
    statusError = true;
  } else if (isPast(to)) {
    status = 'Срок действия истек';
    statusError = true;
  } else if (
    typeof certApi?.HasPrivateKey === 'function' &&
    !certApi.HasPrivateKey()
  ) {
    status = 'Нет привязки к закрытому ключу';
    statusError = true;
  } else if (!IsValid) {
    status =
      'Ошибка при проверке цепочки сертификатов. Возможно на компьютере не установлены сертификаты УЦ, выдавшего ваш сертификат';
    statusError = true;
  } else {
    status = 'Действителен';
  }

  return {
    // publicKey,
    subjectInfo: subjectInfo
      ?.split(',')
      .find((item) => item.includes('CN'))
      .replace(/CN=/g, ''),
    issuerInfo: issuerInfo
      ?.split(',')
      .find((item) => item.includes('CN'))
      .replace(/CN=/g, ''),
    providerName,
    uniqueContainerNameError,
    uniqueContainerName,
    validPeriod: { from: toDateTime(from), to: toDateTime(to) },
    algorithmName,
    status,
    statusError,
  };
};

 
export const useGetInfoCert = (certificate) =>
  useQueryAdaptor([certificate], () => getInfoCert(certificate), {
    enabled: !!certificate,
  });
