import { columns } from './columns';

/* eslint-disable */
const columnsExpanded = [
  {
    accessor: 'section_num',
    Header: 'Номер участка',
    rowSpan: 1,
  },
  {
    accessor: 'green_num',
    Header: 'Номер п/п',
    rowSpan: 1,
  },
  {
    accessor: 'name_rus',
    Header: 'Вид (русское название)',
    rowSpan: 1,
  },
  {
    accessor: 'name_lat',
    Header: 'Вид (латинское название)',
    rowSpan: 1,
  },
];

 
describe('file columns.spec', () => {
  it('should', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act

    // Assert
    expect(columns).toStrictEqual(columnsExpanded);
  });
});
