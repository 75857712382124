import {
  fetchReonIntersectionsGeometry,
  resetReonIntersections,
} from 'app/actions/geometryActions';
import { WithMapContext } from 'app/components/map/withMap';
import { REON } from 'app/constants/layers';
import hasReonIntersections from 'app/selectors/card/hasReonIntersections';
import { onCheckGeometryReonButtonPress } from 'app/utils/card/checkGeometry';
import PropTypes from 'prop-types';
/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';

import toggleContent from '../content/toggle';
import ToggleButton from './ToggleButton';

class ReonButton extends React.Component {
  static propTypes = {
    hasReonIntersections: PropTypes.bool.isRequired,
    resetReonIntersections: PropTypes.func.isRequired,
    fetchReonIntersectionsGeometry: PropTypes.func.isRequired,
    showAlert: PropTypes.func.isRequired,
    currentCard: PropTypes.object,
  };

  static contextType = WithMapContext;

  componentWillUnmount() {
    this.props.resetReonIntersections();
  }

  checkGeometryReonByButton = () => {
    const data = {
      geometry: this.context.getGeometry(this.props.currentCard.record_id),
    };

    this.context.clearLayer(REON);
    onCheckGeometryReonButtonPress({
      data,
      hasReonIntersections: this.props.hasReonIntersections,
      resetReonIntersections: this.props.resetReonIntersections,
      fetchReonIntersectionsGeometry: this.props.fetchReonIntersectionsGeometry,
      showAlert: this.props.showAlert,
      draw: this.context.drawReonGeometry,
    });
  };

  render() {
    return (
      <ToggleButton
        active={this.props.hasReonIntersections}
        content={toggleContent.reon}
        onClick={this.checkGeometryReonByButton}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hasReonIntersections: hasReonIntersections(state),
  };
};

const mapDispatchToProps = {
  resetReonIntersections,
  fetchReonIntersectionsGeometry,
};

 
export default connect(mapStateToProps, mapDispatchToProps)(ReonButton);
