import { AutocompleteAsync } from 'core/uiKit/inputs/selects/AutocompleteAsync';
import { fetchOwners } from 'core/uiKit/preparedInputs/selects/api/fetchOwners';

export const OWNER_ID_NAME = 'owner_id';

/**
 * Балансодержатель с возможностью прокикуть place.
 *
 * @param {object} props - Пропсы.
 * @param {string} props.place - Место в котором находится балансодержатель.
 * @returns {JSX.Element}
 */
export const OwnerIdPlace = ({ place = '', ...otherProps }) => {
  return (
    <AutocompleteAsync
      {...otherProps}
      fetchFunction={fetchOwners(place)}
      label={'Балансодержатель'}
      name={OWNER_ID_NAME}
    />
  );
};
