import pickBy from 'lodash/pickBy';

/* eslint-disable */
export const int2str = (v) => (typeof v === 'number' ? v.toString() : v);

export const str2int = (v) => (typeof v === 'string' ? parseInt(v, 10) : v);

export const omitNullable = (obj, options = { emptyStrings: false }) => {
  const { emptyStrings = false } = options;
  return pickBy(
    obj,
    (v) => v !== undefined && v !== null && (emptyStrings ? v !== '' : true),
  );
};

export const formatTotal = (sum) => {
  if (typeof sum !== 'string') {
    return sum;
  }

  return sum.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const formatSum = (x) => {
  if (typeof x !== 'string') {
    return x;
  }

  let [rubles, pennies] = x.toString().split('.');
  rubles = formatTotal(rubles);

  const noPennies = pennies === '00' || !pennies;
  if (noPennies && rubles === '0') {
    return '-';
  }

  return `${noPennies ? rubles : [rubles, pennies].join('.')} ₽`;
};

export const formatMoney = (x = '0', displayPennies = false) => {
  if (!x) {
    return displayPennies ? '0.00 ₽' : '0 ₽';
  }

  let [rubles, pennies = '00'] = String(x).toString().split('.');
  rubles = formatTotal(rubles);
  pennies =
    pennies.length === 2
      ? pennies
      : pennies.length < 2
      ? `${pennies}0`
      : pennies.slice(0, 2);

  return `${displayPennies ? [rubles, pennies].join('.') : rubles} ₽`;
};

// предполагается, что phone содержит 11 цифр

export const formatPhone = (phone) => {
  if (typeof phone !== 'string') {
    return phone;
  }

  const match = phone.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);

  if (!match) {
    return phone;
  }

  const [, ...phoneParts] = match;

  return `+${phoneParts.join(' ')}`;
};

export const printFio = (...strings) => strings.filter((s) => s).join(' ');

export const getFileExtension = (filename) => {
  const parts = filename.split('.');
  return parts.length > 1 ? parts[parts.length - 1] : '';
};
