import { FieldRF } from 'core/form/reduxForm/helpers/FieldRF';
import {
  NO_CALC_NAME,
  NoCalc,
} from 'core/uiKit/preparedInputs/checkboxes/NoCalc';
import React from 'react';

export { NO_CALC_NAME };

/**
 * Checkbox RF __Не учитывать__.
 *
 * @param props - Пропсы.
 * @param props.disabled - Флаг возможности редактирования.
 * @param props.label - Лейбл если есть, по умолчанию "Не учитывать".
 * @returns
 */
export const NoCalcRF = (props: { disabled: boolean; label?: string }) => {
  return <FieldRF component={NoCalc} {...props} name={NO_CALC_NAME} />;
};
