import cn from 'classnames';
import { CheckboxMUI, FormControlLabel } from 'core/uiKit/material-ui';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  checked: PropTypes.bool,
  checkedIcon: PropTypes.element,
  className: PropTypes.string,
  color: PropTypes.string,
  dark: PropTypes.bool,
  disableRipple: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  id: PropTypes.string,
  indeterminate: PropTypes.bool,
  indeterminateIcon: PropTypes.element,
  inputProps: PropTypes.object,
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  labelPlacement: PropTypes.string,
  labelStyle: PropTypes.object,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
};

const defaultProps = {
  color: 'primary',
  disableRipple: true,
  labelPlacement: 'end',
};

/**
 * Чекбокс.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
function Checkbox(props) {
  const {
    checked,
    checkedIcon,
    className,
    color,
    dark,
    disabled,
    disableRipple,
    icon,
    id,
    indeterminate,
    indeterminateIcon,
    inputProps,
    inputRef,
    label,
    labelClassName,
    labelPlacement,
    labelStyle,
    name,
    onChange,
    style,
    ...rest
  } = props;
  return label ? (
    <FormControlLabel
      checked={checked}
      className={cn(labelClassName, { Checkbox_Dark: dark })}
      control={
        <CheckboxMUI
          checked={checked}
          checkedIcon={checkedIcon}
          className={className}
          color={color}
          disabled={disabled}
          disableRipple={disableRipple}
          icon={icon}
          id={id}
          indeterminate={indeterminate}
          indeterminateIcon={indeterminateIcon}
          inputProps={inputProps}
          inputRef={inputRef}
          name={name}
          style={style}
          type="checkbox"
          onChange={onChange}
          {...rest}
        />
      }
      disabled={disabled}
      label={label}
      labelPlacement={labelPlacement}
      style={labelStyle}
    />
  ) : (
    <CheckboxMUI
      checked={checked}
      checkedIcon={checkedIcon}
      className={cn(className, { Checkbox_Dark: dark })}
      color={color}
      disabled={disabled}
      disableRipple={disableRipple}
      icon={icon}
      id={id}
      indeterminate={indeterminate}
      indeterminateIcon={indeterminateIcon}
      inputProps={inputProps}
      inputRef={inputRef}
      name={name}
      style={style}
      type="checkbox"
      onChange={onChange}
      {...rest}
    />
  );
}

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

export default Checkbox;
