import { NumberField } from 'core/uiKit/inputs/NumberField';
import React from 'react';

export const RELATED_ELEMENTS_FLOWERS_GARDEN_ID_NAME =
  'related_elements_flowers_garden_id';

/**
 * NumberField Связанные элементы ЗН (ID) - Цветники.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const RelatedElementsFlowersGardenId = (props) => {
  return (
    <NumberField
      {...props}
      label={'Связанные элементы ЗН (ID) - Цветники'}
      name={RELATED_ELEMENTS_FLOWERS_GARDEN_ID_NAME}
    />
  );
};
