import IconButton from 'core/newComponents/IconButton';
import { AddIcon } from 'core/uiKit/material-ui';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Компонент TableAddButton.
 *
 * @returns {JSX.Element}
 */
export default class TableAddButton extends React.Component {
  static propTypes = {
    disabled: PropTypes.bool,
    isError: PropTypes.bool,
    onAdd: PropTypes.func.isRequired,
    title: PropTypes.string,
    visible: PropTypes.bool,
  };

  /**
   * Метод жизненного цикла render.
   *
   * @returns {JSX.Element}
   */
  render() {
    const { title, onAdd, visible, disabled } = this.props;
    const iconButtonStyle = {
      display: visible ? 'inline-block' : 'none',
      height: '50px',
      width: '50px',
      zIndex: '50',
    };

    return (
      <IconButton
        aria-label="add"
        className="tableButton"
        disabled={disabled}
        style={iconButtonStyle}
        title={title}
        onClick={onAdd}
      >
        <AddIcon />
      </IconButton>
    );
  }
}
