/* eslint-disable  */
import { isThisViewing } from 'app/selectors/card/isThisViewing';
import getFormValues from 'app/selectors/form/getFormValues';
import { createSelector } from 'reselect';

/**
 *
 * @param state
 */
const getCoatingGroup = (state) => getFormValues(state).coating_group_id;

/**
 *
 * @param state
 * @param props
 */
const getMarginStrengTypes = (state, props) => props.card.margin_streng_type;

export default createSelector(
  [getCoatingGroup, getMarginStrengTypes, isThisViewing],
  (coatingGroupId, marginStrengTypes, viewing) => {
    let result;

    if (viewing) {
      return marginStrengTypes;
    }

    if (coatingGroupId) {
      result = marginStrengTypes.filter(
        (type) => type.coating_group_id === coatingGroupId,
      );
    } else {
      result = marginStrengTypes;
    }
    return result;
  },
);
