/* eslint-disable */
import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import {
  CarCustomerList,
  CAR_CUSTOMERS_LIST_NAME,
} from 'core/uiKit/preparedInputs/selects/autocompleteMultAsync/CarCustomerList';

const CarCustomerListWithReduxForm = ({ input, ...props }) => {
  return <CarCustomerList {...input} {...props} />;
};

 
export { CAR_CUSTOMERS_LIST_NAME };

 
 
/**
 * ## Заказчик
 *
 * ### Redux Form Field
 *
 * * `type = AutocompleteMultiAsync`
 * * `name = car_customer_list``
 */
/* eslint-disable */
export const CarCustomerListRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={CAR_CUSTOMERS_LIST_NAME}
      component={CarCustomerListWithReduxForm}
    />
  );
};

CarCustomerListRF.propTypes = {
   
   
/**
   * быбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
/**
   * placeholder
   */
  placeholder: PropTypes.string,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
};
CarCustomerListRF.defaultProps = {
  disabled: false,
};
