/**
 * Компонент ссылки.
 *
 * @description Генерирует ссылку если такая есть и текст, что ссылкой не является.
 * @param {string} message - Сообщение.
 * @returns {JSX.Element}
 */
export const renderA = (message) => {
  let reactMessage = message;
  if (/<\/a>/.test(message)) {
    const finding = message.match(/<a ([^>]+)>([^<]+)<\/a>/);

    if (!finding) {
      return message;
    }

    const { index } = finding;
    const [str, attrs, text] = finding;

    const [target, href] = (attrs.split` ` || []).map((item) =>
      item.split`=`[1]?.replace(/"/g, ''),
    );

    reactMessage = (
      <>
        {message.slice(0, index)}
        <a target={target} href={href} children={text} />
        {renderA(message.slice(index + str.length))}
      </>
    );
  }

  return reactMessage;
};
