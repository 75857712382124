import { TextField } from 'core/uiKit/inputs/TextField';
import React from 'react';

export const USER_NAME = 'username';

/**
 * Комментарий.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const UserName = (props) => {
  return <TextField label={props.label || 'Пользователь'} {...props} />;
};
