/**
 * Переводим строку CamelCase в snake_case.
 *
 * @param {string} str - Строка которую надо перевести.
 * @returns {string} - Жопа.
 * @example ---
 *  snake_case('AB3CD56') // => 'a_b3_c_d56'
 *  snake_case('AsaNBda') // => 'asa_n_bda'
 */
export const snake_case = (str = '') =>
  str?.replace(/[A-Z]/g, (s_, i_) => (i_ ? '_' : '') + s_.toLowerCase());
