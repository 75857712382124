/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import { AutocompleteAsync } from 'core/uiKit/inputs/selects/AutocompleteAsync';
import { fetchCarOwners } from './api';

 
export const CAR_OWNER_ID_NAME = 'carOwnerId';

 
 
/**
 * ## Владелец техники
 *
 * ###### type = `AutocompleteAsync`
 * ###### name = `carOwnerId`
 * ###### name = `car_owner_id`
 *
 * ```js
 * import { CarOwnerId, CAR_OWNER_ID_NAME } from 'core/uiKit/preparedInputs';
 * ```
 *
 *
 */
 
export const CarOwnerId = (props) => {
  return (
    <AutocompleteAsync
      label={'Владелец'}
      {...props}
      fetchFunction={fetchCarOwners}
      name={CAR_OWNER_ID_NAME}
    />
  );
};

CarOwnerId.propTypes = {
   
   
/**
   * очищаем инпут с сохранением значения
   */
  clear: PropTypes.bool,
   
   
/**
   * быбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
/**
   * функция вызова когда выберется итем
   */
  onChange: PropTypes.func,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
   
   
/**
   * значение
   */
  value: PropTypes.any,
};
