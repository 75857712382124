/* eslint-disable  */
import getCurrentObject from 'app/selectors/card/getCurrentObject';
import getFormValues from 'app/selectors/form/getFormValues';
import { createSelector } from 'reselect';

import { UNIMPROVED } from './dict';

/**
 *
 * @param state
 */
const getCoatingGroupById = (state) => {
  const coatingGroups = getCurrentObject(state).coating_group || [];
  const coatingGroupId = getFormValues(state).coating_group_id;
  return coatingGroups.find((group) => group.id === coatingGroupId);
};

export default createSelector([getCoatingGroupById], (coatingGroup) => {
  if (coatingGroup && coatingGroup.code) {
    return coatingGroup.code === UNIMPROVED;
  }
  return false;
});
