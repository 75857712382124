import { renderCustomTextField } from 'app/components/card/common/grid/index';

/* eslint-disable */
export default function getElements(props) {
  const element = getElement(props);
  return [
    element({
      id: 'total_lawn_area',
      label: 'Общая площадь газонов, кв.м',
    }),
    element({
      id: 'lawn_area_without',
      label: 'Площадь газонов за вычетом насаждений, кв.м',
    }),
    element({
      id: 'usial_lawn_area',
      label: 'Обыкновенный, кв.м',
    }),
    element({
      id: 'lawn_lawn_area',
      label: 'Луговой / разнотравный, кв.м',
    }),
    element({
      id: 'parterre_lawn_area',
      label: 'Партерный, кв.м',
    }),
    element({
      id: 'slope_lawn_area',
      label: 'На откосе, кв.м',
    }),
    element({
      id: 'lawn_grid_area',
      label: 'На ячеистом основании / экопарковки, кв.м',
    }),
    element({
      id: 'lawn_other_area',
      label: 'Иного типа, кв.м',
    }),
  ];
}

function getElement({ calcAttributes = {}, editable }) {
  return (params) => {
    return {
      name: params.id,
      editable: params.id === 'meadow_lowland_area' ? editable : false,
      formValue: true,
      defaultValue: calcAttributes[params?.id] || 0,
      component: renderCustomTextField,
      addition: {
        type: 'decimal',
        positive: true,
        zero: false,
        digits: 2,
      },
      ...params,
    };
  };
}
