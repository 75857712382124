/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import getDisplayName from 'core/hocs/getDisplayName';

const propTypes = {
  className: PropTypes.string,
};

function buttonGreen(Component) {
  class ButtonGreen extends React.Component {
    render() {
      const { className, ...otherProps } = this.props;
      return (
        <Component className={cn(className, 'Button_Green')} {...otherProps} />
      );
    }
  }

  ButtonGreen.propTypes = propTypes;
  ButtonGreen.displayName = `buttonGreen(${getDisplayName(Component)})`;

  return ButtonGreen;
}

 
export default buttonGreen;
