import { post } from 'app/api/crud/post';
import { getApiPath } from 'app/utils/api/config';

/**
 * Fetch geo diff.
 *
 * @param {object} data - The data.
 * @returns {*}
 */
export const fetchGeoDiff = (data) =>
  fetch(`${getApiPath()}/versions/geodiff`, data);

/**
 * Fetch props diff.
 *
 * @param {object} data - The data.
 * @returns {*}
 */
export const fetchPropsDiff = (data) =>
  fetch(`${getApiPath()}/versions/propsdiff`, data);

/**
 * Fetch tree diff.
 *
 * @param {object} data - The data.
 * @returns {*}
 */
export const fetchTreeDiff = (data) =>
  post(`${getApiPath()}/versions/treediff`, data);

/**
 * Fetch versions.
 *
 * @param {object} data - The data.
 * @returns {*}
 */
export const fetchVersions = (data) => post('/registry/versions/ogh', data);
