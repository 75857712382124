/* eslint-disable */
import React from 'react';

import { StringParam } from 'use-query-params';

import { TextFieldFF } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
export const INVENTORY_NUMBER_NAME_NAME = 'inventoryNumberName';

 
 
/**
 * Инвентарный № НО–>inventory_number [поле строка, или если пусто - все]
 */
 
export const InventoryNumberFilter = () => {
  return (
    <TextFieldFF
      className={filterInput}
      label={'Инвентарный № НО'}
      name={INVENTORY_NUMBER_NAME_NAME}
    />
  );
};

 
export const inventoryNumberParams = {
  [INVENTORY_NUMBER_NAME_NAME]: StringParam,
};
