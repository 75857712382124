import {
  TRAFFIC_SIGNS_ID_NAME,
  TrafficSignsId,
} from 'core/uiKit/preparedInputs/selects/autocompleteAsync/TrafficSignsId';
import React, { FC } from 'react';
// @ts-ignore
import { Field } from 'redux-form';
import { SelectProps, SelectPropsWithRedux } from 'types/inputs';

/**
 * RF обертка для Номер по ГОСТ и наименование знака.
 *
 * @param root - Root props.
 * @param root.input - Input props.
 * @param root.props - Properties.
 * @returns TrafficSignsId.
 */
const TrafficSignsIdWithReduxForm: FC<SelectPropsWithRedux> = ({
  input,
  ...props
}) => {
  // @ts-ignore
  return <TrafficSignsId {...input} {...props} />;
};

/**
 * RF autocompletion Улица.
 *
 * @param props - Properties.
 * @returns {JSX.Element}
 */
export const TrafficSignsIdRF: FC<SelectProps> = (props): JSX.Element => {
  return (
    <Field
      {...props}
      name={TRAFFIC_SIGNS_ID_NAME}
      component={TrafficSignsIdWithReduxForm}
    />
  );
};
