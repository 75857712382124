import { currentDate } from 'app/utils/dateUtils';
import { filterInputData } from 'core/form/filterForm/components/filterInputsClasses';
import { KeyboardDatePickerFF } from 'core/form/finalForm/fields/default/datePickers/KeyboardDatePickerFF';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import moment from 'moment';
import React from 'react';
import { useField } from 'react-final-form';
import { DateTimeParam, withDefault } from 'use-query-params';

export const DATE_TO_VERSION_FILTER_NAME = 'dateToVersionTable';

/**
 * DateToVersionFilter.
 *
 * @param {*} props - Props.
 * @returns {JSX.Element}
 */
export const DateToVersionFilter = (props) => {
  const dateFromInput = useField('dateFromVersionTable');
  return (
    <KeyboardDatePickerFF
      minDate={dateFromInput?.input?.value}
      {...props}
      className={filterInputData}
      label={'Дата по'}
      name={DATE_TO_VERSION_FILTER_NAME}
    />
  );
};

/**
 * Params.
 *
 * @param {*} initDate - Init date.
 * @returns {*}
 */
export const dateToParams = (initDate = currentDate) => {
  addPreparation(DATE_TO_VERSION_FILTER_NAME, (value) => {
    let newValue = value;
    if (typeof value === 'object' && moment(value).isValid()) {
      newValue = moment(value).format('DD.MM.YYYY');
    }
    return newValue;
  });

  return {
    [DATE_TO_VERSION_FILTER_NAME]: withDefault(DateTimeParam, initDate),
  };
};
