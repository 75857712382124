import {
  BLUE,
  BLUE_HOVERED,
  RED,
  RED_HOVERED,
} from 'app/services/mapService/constants/markerTypes';
import { defaultMarker } from 'app/services/mapService/pointMarkers';

import { GeometryTypes, UserData } from '../types.d';

export const colors = {
  adjacent: '#74c874',
  allChildren: '#fa8172',
  changing: '#1680ae',
  child: '#fa8172',
  clicked: '#794bff',
  copy: '#ff0000',
  district: '#ffa602',
  hovered: '#003aa8',
  intersected: '#9500d3',
  original: '#7c00ff',
  parent: '#ffc1cb',
  reonArea: '#ffff00',
  selected: '#b22222',
  workOrders: '#87cefa',
};

export const defaultOptions = {
  Marker: {
    anchor: [19, 32],
    hoverAnchor: [19, 32],
    // @ts-ignore
    hoverIcon: defaultMarker[RED_HOVERED].source,
    hoverSize: [37, 37],
    // @ts-ignore
    icon: defaultMarker[RED].source,
    interactive: false,
    size: [37, 37],
    userData: { type: GeometryTypes.Point } as UserData<GeometryTypes.Point>,
    zIndex: 3,
  },
  Polygon: {
    color: '#b2222233',
    interactive: false,
    strokeColor: '#b22222',
    strokeWidth: 2,
    userData: {
      type: GeometryTypes.Polygon,
    } as UserData<GeometryTypes.Polygon>,
    zIndex: 1,
  },
  Polyline: {
    color: '#b22222',
    interactive: false,
    userData: {
      type: GeometryTypes.Polyline,
    } as UserData<GeometryTypes.Polyline>,
    width: 3,
    zIndex: 2,
  },
};

export const intersectionOptions = {
  Marker: {
    ...defaultOptions.Marker,
    // @ts-ignore
    hoverIcon: defaultMarker[RED_HOVERED].source,
    // @ts-ignore
    icon: defaultMarker[RED].source,
    userData: { type: GeometryTypes.Point } as UserData<GeometryTypes.Point>,
    zIndex: 120,
  },
  Polygon: {
    ...defaultOptions.Polygon,
    color: `${colors.intersected}33`,
    strokeColor: colors.intersected,
    userData: {
      type: GeometryTypes.Polygon,
    } as UserData<GeometryTypes.Polygon>,
    zIndex: 100,
  },
  Polyline: {
    ...defaultOptions.Polyline,
    color: colors.intersected,
    userData: {
      type: GeometryTypes.Polyline,
    } as UserData<GeometryTypes.Polyline>,
    zIndex: 110,
  },
};

export const parentOptions = {
  Marker: {
    ...defaultOptions.Marker,
    zIndex: 3,
  },
  Polygon: {
    ...defaultOptions.Polygon,
    color: `${colors.parent}77`,
    strokeColor: colors.parent,
    zIndex: 1,
  },
  Polyline: {
    ...defaultOptions.Polyline,
    color: colors.parent,
    userData: {
      type: GeometryTypes.Polyline,
    } as UserData<GeometryTypes.Polyline>,
    zIndex: 2,
  },
};

export const hoveredOptions = {
  Marker: {
    anchor: defaultOptions.Marker.hoverAnchor,
    icon: defaultOptions.Marker.hoverIcon,
    size: defaultOptions.Marker.hoverSize,
  },
  Polygon: {
    strokeColor: `${colors.hovered}99`,
  },
  Polyline: {
    color: colors.hovered,
  },
};

export const selectedOptions = {
  Marker: {
    // @ts-ignore
    hoverIcon: defaultMarker[RED_HOVERED].source,
    // @ts-ignore
    icon: defaultMarker[RED].source,
    zIndex: 60,
  },
  Polygon: {
    color: `${colors.selected}66`,
    strokeColor: `${colors.selected}ee`,
    zIndex: 40,
  },
  Polyline: {
    color: colors.selected,
    zIndex: 50,
  },
};

export const clickedOptions = {
  Marker: {
    // @ts-ignore
    hoverIcon: defaultMarker[RED_HOVERED].source,

    // @ts-ignore
    icon: defaultMarker[RED].source,
  },
  Polygon: {
    ...defaultOptions.Polygon,
  },
  Polyline: {
    ...defaultOptions.Polyline,
    color: colors.clicked,
  },
};

export const childOptions = {
  Marker: {
    ...defaultOptions.Marker,
    zIndex: 60,
  },
  Polygon: {
    ...defaultOptions.Polygon,
    color: `${colors.child}88`,
    strokeColor: colors.child,
    zIndex: 40,
  },
  Polyline: {
    ...defaultOptions.Polyline,
    color: colors.child,
    zIndex: 50,
  },
};

export const allChildrenOptions = {
  Marker: {
    ...defaultOptions.Marker,
    zIndex: 90,
  },
  Polygon: {
    ...defaultOptions.Polygon,
    color: `${colors.allChildren}55`,
    strokeColor: `${colors.allChildren}aa`,
    zIndex: 80,
  },
  Polyline: {
    ...defaultOptions.Polyline,
    color: `${colors.allChildren}aa`,
    zIndex: 70,
  },
};

export const districtOptions = {
  Marker: {
    ...defaultOptions.Marker,
    zIndex: 210,
  },
  Polygon: {
    ...defaultOptions.Polygon,
    color: `${colors.district}33`,
    strokeColor: colors.district,
    zIndex: 190,
  },
  Polyline: {
    ...defaultOptions.Polyline,
    color: colors.district,
    zIndex: 200,
  },
};

export const adjacentOptions = {
  Marker: {
    ...defaultOptions.Marker,
    zIndex: 150,
  },
  Polygon: {
    ...defaultOptions.Polygon,
    color: `${colors.adjacent}33`,
    strokeColor: colors.adjacent,
    zIndex: 130,
  },
  Polyline: {
    ...defaultOptions.Polyline,
    color: colors.adjacent,
    zIndex: 140,
  },
};

export const reonAreaOptions = {
  Marker: {
    ...defaultOptions.Marker,
    // @ts-ignore
    hoverIcon: defaultMarker[RED_HOVERED].source,
    // @ts-ignore
    icon: defaultMarker[RED].source,
    zIndex: 180,
  },
  Polygon: {
    ...defaultOptions.Polygon,
    color: `${colors.reonArea}33`,
    strokeColor: `${colors.reonArea}cc`,
    zIndex: 160,
  },
  Polyline: {
    ...defaultOptions.Polyline,
    color: colors.reonArea,
    zIndex: 170,
  },
};

export const originalDiffOptions = {
  Marker: {
    ...defaultOptions.Marker,
    // @ts-ignore
    hoverIcon: defaultMarker[BLUE_HOVERED].source,
    // @ts-ignore
    icon: defaultMarker[BLUE].source,
    zIndex: 270,
  },
  Polygon: {
    ...defaultOptions.Polygon,
    color: `${colors.original}44`,
    strokeColor: colors.original,
    zIndex: 250,
  },
  Polyline: {
    ...defaultOptions.Polyline,
    color: colors.original,
    zIndex: 260,
  },
};

export const copyDiffOptions = {
  Marker: {
    ...defaultOptions.Marker,
    zIndex: 240,
  },
  Polygon: {
    ...defaultOptions.Polygon,
    color: `${colors.copy}44`,
    strokeColor: colors.copy,
    zIndex: 220,
  },
  Polyline: {
    ...defaultOptions.Polyline,
    color: colors.copy,
    zIndex: 230,
  },
};

/**
 * Геометрия в режиме редактирования, но над которой не производится никаких действий.
 */
export const editingOptions = {
  Marker: {
    ...defaultOptions.Marker,
    zIndex: 300,
  },
  Polygon: {
    ...defaultOptions.Polygon,
    color: `${colors.hovered}44`,
    strokeColor: colors.changing,
    zIndex: 280,
  },
  Polyline: {
    ...defaultOptions.Polyline,
    color: colors.changing,
    zIndex: 290,
  },
};

/**
 * Геометрия в режиме редактирования, и над которой производятся изменения.
 */
export const changingOptions = {
  Marker: {
    ...defaultOptions.Marker,
    zIndex: 330,
  },
  Polygon: {
    ...defaultOptions.Polygon,
    color: `${colors.selected}44`,
    strokeColor: colors.changing,
    zIndex: 310,
  },
  Polyline: {
    ...defaultOptions.Polyline,
    color: colors.changing,
    zIndex: 320,
  },
};

/**
 * Hex to RGB.
 *
 * @param hex - Hex.
 * @returns Null if hex is invalid.
 */
const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{0,2})$/i.exec(
    hex,
  );

  if (result) {
    // eslint-disable-next-line
    const [, r, g, b, a] = result;

    return {
      // eslint-disable-next-line
      a: a ? parseInt(a, 16) : null,
      // eslint-disable-next-line
      b: parseInt(b, 16),
      // eslint-disable-next-line
      g: parseInt(g, 16),
      // eslint-disable-next-line
      r: parseInt(r, 16),
    };
  }

  return null;
};

/**
 * Transparentize color.
 *
 * @param color - Color.
 * @param opacity - Opacity.
 * @returns Null if color is invalid.
 */
export const transparentize = (
  color:
    | string
    | {
        // eslint-disable-next-line
        r: number;
        // eslint-disable-next-line
        g: number;
        // eslint-disable-next-line
        b: number;
        // eslint-disable-next-line
        a: number | null;
      },
  opacity: number,
) => {
  const fixedOpacity = 255 * Math.min(opacity, 1);

  return rgbaToHex({
    ...(typeof color === 'string'
      ? // eslint-disable-next-line
        hexToRgb(color) || { b: 0, g: 0, r: 0 }
      : color),
    // eslint-disable-next-line
    a: fixedOpacity,
  });
};

/**
 * Convert to hex.
 *
 * @param c - Color.
 * @returns Hex.
 */
// eslint-disable-next-line
const toHex = (c: number | null) => {
  // eslint-disable-next-line
  if (c === null) return '';

  // eslint-disable-next-line
  const hex = c.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
};

/**
 * Convert RGBA to hex.
 *
 * @param color - Color.
 * @param color.r - Red.
 * @param color.g - Green.
 * @param color.b - Blue.
 * @param color.a - Alpha.
 * @returns Hex.
 */
const rgbaToHex = (color: {
  // eslint-disable-next-line
  r: number;
  // eslint-disable-next-line
  g: number;
  // eslint-disable-next-line
  b: number;
  // eslint-disable-next-line
  a: number | null;
}) => {
  // eslint-disable-next-line
  return `#${toHex(color.r)}${toHex(color.g)}${toHex(color.b)}${toHex(
    // eslint-disable-next-line
    color.a ? Math.round(color.a) : null,
  )}`;
};

/**
 * Lighten color.
 *
 * @param color - Color.
 * @param value - Value.
 * @returns Null if color is invalid.
 */
export const lighten = (
  color:
    | string
    | {
        // eslint-disable-next-line
        r: number;
        // eslint-disable-next-line
        g: number;
        // eslint-disable-next-line
        b: number;
        // eslint-disable-next-line
        a: number | null;
      },
  value: number,
) => {
  const rgba = typeof color === 'string' ? hexToRgb(color) : color;

  if (!rgba) return null;

  return rgbaToHex({
    ...rgba,
    // eslint-disable-next-line
    b: Math.min(255, Math.max(Math.round(rgba.b * value + rgba.b)), 255),
    // eslint-disable-next-line
    g: Math.min(255, Math.max(Math.round(rgba.g * value + rgba.g)), 255),
    // eslint-disable-next-line
    r: Math.min(255, Math.max(Math.round(rgba.r * value + rgba.r)), 255),
  });
};

/**
 * Darken color.
 *
 * @param color - Color.
 * @param value - Value.
 * @returns Null if color is invalid.
 */
export const darken = (
  color:
    | string
    | {
        // eslint-disable-next-line
        r: number;
        // eslint-disable-next-line
        g: number;
        // eslint-disable-next-line
        b: number;
        // eslint-disable-next-line
        a: number | null;
      },
  value: number,
) => {
  const rgba = typeof color === 'string' ? hexToRgb(color) : color;

  if (!rgba) return null;

  return rgbaToHex({
    ...rgba,
    // eslint-disable-next-line
    b: Math.max(0, Math.round(rgba.b - rgba.b * value)),
    // eslint-disable-next-line
    g: Math.max(0, Math.round(rgba.g - rgba.g * value)),
    // eslint-disable-next-line
    r: Math.max(0, Math.round(rgba.r - rgba.r * value)),
  });
};
