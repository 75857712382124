import { get } from 'app/api/crud/get';
/* eslint-disable */

/**
 *
 */
export const fetchOdsDicts = () => get('/preload/ods_dicts');

/**
 *
 */
export const fetchUserData = () => get('/preload/user_data');
