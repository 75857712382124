import { LinkIdCell } from 'core/uiKit/components/cells/LinkId.Cell/LinkId.Cell';

/**
 * Колонки таблицы версий по умолчанию.
 */
export const defaultColumns = [
  {
    Cell: LinkIdCell,
    Header: 'ID версии объекта',
    accessor: 'id',
    sortable: true,
  },
  {
    Header: 'Дата начала',
    accessor: 'start_date',
    sortable: true,
  },
  {
    Header: 'Дата окончания',
    accessor: 'end_date',
    sortable: true,
  },
  {
    Header: 'Статус',
    accessor: 'object_status_name',
    sortable: true,
  },
  {
    Header: 'Комментарий',
    accessor: 'change_comment',
  },
];
