import React from 'react';

/**
 * Cell Нумерация по индексу.
 *
 * @param props - Properties.
 * @param props.row - Row.
 * @param props.row.index - Index.
 * @returns {JSX.Element}
 */
export const IndexCell = ({ row: { index } }: { row: { index: number } }) => {
  return <span>{index + 1}</span>;
};
