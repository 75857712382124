/* eslint-disable */
export const stringWithAllParam = {
  encode(string) {
    if (string) {
      return string;
    }
    return 'all';
  },

  decode(string) {
    if (string === 'all') {
      return '';
    }
    return string;
  },
};
