import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import {
  addPreparation,
  removePreparation,
} from 'core/utils/hooks/usePreparation/preparation';
import { useEffect } from 'react';

export const CATEGORY_SPA_ID_NAME = 'categorySpaId';

/**
 * Категория.
 *
 * @param {*} props - Properties.
 * @returns {JSX.Element}
 */
export const CategorySpaId = (props) => {
  usePreparation();
  const dict = 'categorySpa';

  const { data: options = [] } = useGetDictionary(dict);

  return (
    <Select
      options={options}
      {...props}
      label={'Категория'}
      name={CATEGORY_SPA_ID_NAME}
    />
  );
};

/**
 * UsePreparation.
 *
 * @returns {*}
 */
const usePreparation = () => {
  useEffect(() => {
    addPreparation(CATEGORY_SPA_ID_NAME, (value) => {
      if (value) {
        return { category_spa: value };
      }
    });
    return () => {
      removePreparation(CATEGORY_SPA_ID_NAME);
    };
  }, []);
};
