import { VerticalLandscapingTypeId } from 'core/uiKit/preparedInputs/selects/simpleSelect/VerticalLandscapingTypeId';
import React from 'react';

/**
 * Компонент обертка для поля VerticalLandscapingTypeId.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.input - Данные поля приходит из final form.
 * @param {object} props.otherProps - Остальные пропсы.
 * @returns {JSX.Element} - JSX.
 * @example
 */
export const VerticalLandscapingTypeIdWithFF = ({ input, ...otherProps }) => {
  return <VerticalLandscapingTypeId {...input} {...otherProps} />;
};
