import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useMemo, useState } from 'react';

import { useGetActOfCompletedWorkElectronicSignature } from './hooks/useGetActOfCompletedWorkElectronicSignature';

/**
 * Setup Подписание документа.
 *
 * @returns {{data: object, selectRow: Function, dataTable: Array}}
 */
export const useSetupActOfCompletedWorkElectronicSignature = () => {
  const { data = {} } = useGetActOfCompletedWorkElectronicSignature();
  const [selectedRow, setSelectedRow] = useState([]);
  const { logicalStateId = 0 } = useGetCurrentCard();

  // Вот тут данные получить из метода и заполнить столбцы
  const dataTable = useMemo(
    () => [
      {
        comment: data?.comment,
        create_date: data?.json_created_date,
        file_name: data?.data,
        fio: data?.sign_user,
        sign_date: data?.sign_data,
        status: data?.sign_status ? 'Подписан' : 'Не подписан',
      },
    ],
    [data],
  );

  /**
   * Функция выбора колонки.
   *
   * @param {object} params - Выбранные колонки.
   * @param {Array} params.selectedFlatRows - Массив выбранных колонок.
   * @returns {void}
   */
  const selectRow = ({ selectedFlatRows }) => {
    const selectRowId = selectedFlatRows.map(({ original: { id } = {} }) => id);
    setSelectedRow(selectRowId);
  };

  const disabled =
    selectedRow.length !== 1 ||
    data?.sign_status ||
    ![5, 2].includes(logicalStateId);

  return {
    data,
    dataTable,
    disabled,
    selectRow,
  };
};
