/* eslint-disable */
import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import { GrbsShortName, GRBS_SHORT_NAME } from 'core/uiKit/preparedInputs';

 
export { GRBS_SHORT_NAME };

/* eslint-disable */
export const GrbsShortNameFF = ({ name, ...otherProps }) => {
  return (
    <Field name={GRBS_SHORT_NAME}>
      {(props) => {
        return <GrbsShortName {...props} {...props.input} {...otherProps} />;
      }}
    </Field>
  );
};

GrbsShortNameFF.propTypes = {
   
   
/**
   Выбор темной темы
   */
  dark: PropTypes.bool,
  disabled: PropTypes.bool,
};
GrbsShortNameFF.defaultProps = {
  dark: false,
  disabled: false,
};
