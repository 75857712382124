/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { submit } from 'redux-form';
import moment from 'moment';

import {
  showIfHavePrivilegeToDeleteTechObject,
  showIfHavePrivilegeToEditTechObject,
  showIfHavePrivilegeToMatchTech,
} from 'app/grants/index';

import { getServerTime } from 'app/utils/dateUtils';

import { continueEditCard } from 'app/actions/odsObjectActions';

import { showAlert } from 'app/actions/dialog';
import { setStartDate, setTechEditType } from 'app/actions/technologyObject';
import { parseDate } from 'app/components/common/misc/ConverterFunctions';

import CarActionButton from './components/CarActionButton';
import EquipmentActionButton from './components/EquipmentActionButton';

import { ButtonGreen, ButtonBlue, ButtonRed } from 'core/newComponents/Button';
import { IconButtonLocal } from './IconButtonLocal';

const EditButton = (props) => {
  const { cardType } = props;
  let result;
  if (cardType === 'car') {
    const Component = showIfHavePrivilegeToEditTechObject(EditTechButton);
    result = <Component {...props} />;
  } else if (cardType === 'equipment') {
    const Component = showIfHavePrivilegeToEditTechObject(EditEquipmentButton);
    result = <Component {...props} />;
  } else {
    result = null;
  }
  return result;
};

const EditTechButton = (props) => {
  return (
    <CarActionButton
      className="show"
      date={props.date}
      dialogTitle="Редактирование"
      edit={true}
      id="edit"
      name="Редактирование техники"
      onSubmit={(planDate, requestType, reasonCode) => {
        if (props.endDate < new Date()) {
          props.dispatch(
            showAlert(
              `Техника удалена с ${moment(props.endDate).format(
                'DD.MM.YYYY HH:mm',
              )}, редактирование невозможно`,
            ),
          );
        } else {
          if (
            (props.versions_start_dates || []).some((s) => {
              return s === moment(planDate).format('DD.MM.YYYY HH:mm');
            })
          ) {
            props.dispatch(
              showAlert(
                'На указанную дату уже существует версия. Измените значение в поле Дата применения изменений.',
              ),
            );
          } else {
            props.dispatch(
              setStartDate(moment(planDate).format('DD.MM.YYYY HH:mm')),
            );
            props.dispatch(setTechEditType(requestType, reasonCode));
            props.dispatch(continueEditCard());
          }
        }
      }}
    >
      Редактировать
    </CarActionButton>
  );
};

const EditEquipmentButton = (props) => {
  return (
    <EquipmentActionButton
      className="show"
      component={ButtonBlue}
      date={props.date}
      dialogTitle="Редактирование"
      edit={true}
      id="edit"
      name="Редактирование навесного оборудования"
      requestTypeDefault="edit_equipment"
      onSubmit={(planDate) => {
        if (props.endDate < new Date()) {
          props.dispatch(
            showAlert(
              `Навесное оборудование удалено с ${moment(props.endDate).format(
                'DD.MM.YYYY HH:mm',
              )}, редактирование невозможно`,
            ),
          );
        } else {
          if (
            props.versions_start_dates.some((s) => {
              return s === moment(planDate).format('DD.MM.YYYY HH:mm');
            })
          ) {
            props.dispatch(
              showAlert(
                'На указанную дату уже существует версия. Измените значение в поле Дата применения изменений.',
              ),
            );
          } else {
            props.dispatch(
              setStartDate(moment(planDate).format('DD.MM.YYYY HH:mm')),
            );
            props.dispatch(continueEditCard());
          }
        }
      }}
    >
      Редактировать
    </EquipmentActionButton>
  );
};

const DeleteButton = (props) => {
  if (props.cardType === 'gps') {
    return <div />;
  }
  if (props.cardType === 'car') {
    const Component = showIfHavePrivilegeToDeleteTechObject(DeleteCarButton);
    return <Component {...props} />;
  } else {
    const Component = showIfHavePrivilegeToEditTechObject(
      DeleteEquipmentButton,
    );
    return <Component {...props} />;
  }
};

const DeleteCarButton = (props) => {
  return (
    <CarActionButton
      buttonType="delete"
      className={props.inShow}
      dialogTitle="Удаление"
      id="delete"
      name="Удаление техники"
      reasonDefault="remove_registration"
      onSubmit={() => {
        props.dispatch(submit('editorCard'));
      }}
    >
      Удалить
    </CarActionButton>
  );
};

const DeleteEquipmentButton = (props) => {
  return (
    <EquipmentActionButton
      className={props.inShow}
      component={ButtonRed}
      dialogTitle="Удаление"
      id="delete"
      name="Удаление навесного оборудования"
      reasonDefault="remove_registration"
      onSubmit={() => {
        props.dispatch(submit('editorCard'));
      }}
    >
      Удалить
    </EquipmentActionButton>
  );
};

const MatchingConfirmButton = createMatchingButton({
  label: 'Утвердить',
  grantChecker: showIfHavePrivilegeToMatchTech,
});

const MatchingRejectButton = createMatchingButton({
  label: 'Отклонить',
  grantChecker: showIfHavePrivilegeToMatchTech,
});

function createMatchingButton({ label, grantChecker }) {
  class Button extends React.Component {
    render() {
      return (
        <ButtonBlue className="show" {...this.props}>
          {label}
        </ButtonBlue>
      );
    }
  }

  return class MatchingButton extends React.Component {
    render() {
      const { props } = this;
      let result;
      if (props.cardType === 'gps') {
        result = null;
      } else {
        const Component = grantChecker(Button);
        result = <Component {...props} />;
      }
      return result;
    }
  };
}

/* eslint-disable */
export default class BtnGroup extends React.Component {
  static propTypes = {
    currentCard: PropTypes.object,
    mode: PropTypes.object,
    cardType: PropTypes.string,
    today: PropTypes.object,
    tomorrow: PropTypes.object,
    startDate: PropTypes.object,
    isMatching: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    onClickCancelButton: PropTypes.func.isRequired,
    onClickMatchingConfirmButton: PropTypes.func.isRequired,
    onClickMatchingRejectButton: PropTypes.func.isRequired,
  };
  inShowButton() {
    const { cardType, viewMatchingTechMode, today, tomorrow, currentCard } =
      this.props;
    const endDate = currentCard && parseDate(currentCard.end_date);
    const dateCarCheck = (cardType === 'car' ? today : tomorrow).toDate();
    const isEditMode = this.isEditMode();
    let result = false;

    if (endDate && endDate > dateCarCheck && !viewMatchingTechMode) {
      result = !isEditMode;
    }

    return result;
  }

  isEditMode() {
    const { mode } = this.props;
    return mode && mode.editMode;
  }

  renderButtons() {
    const { isMatching } = this.props;
    const isEditMode = this.isEditMode();
    const inShowButton = this.inShowButton();

    const isVisibleMatchingButton = isMatching && !isEditMode;

    return [
      {
        visible: inShowButton,
        component: this.renderEditButton,
      },
      {
        visible: isEditMode,
        component: this.renderSaveButton,
      },
      {
        visible: isEditMode,
        component: this.renderCancelButton,
      },
      {
        visible: inShowButton,
        component: this.renderDeleteButton,
      },
      {
        visible: isVisibleMatchingButton,
        component: this.renderMatchingConfirmButton,
      },
      {
        visible: isVisibleMatchingButton,
        component: this.renderMatchingRejectButton,
      },
    ]
      .filter((item) => item.visible)
      .map((item) => item.component());
  }

  renderCancelButton = () => {
    return (
      <ButtonRed
        className="show"
        id="cancel"
        onClick={this.props.onClickCancelButton}
      >
        Отмена
      </ButtonRed>
    );
  };

  renderDeleteButton = () => {
    const { props } = this;
    const { dispatch, currentCard, cardType } = props;
    const typeId = currentCard.type_id;
    const recordId = currentCard.id;

    return (
      <DeleteButton
        cardType={cardType}
        dispatch={dispatch}
        recordId={recordId}
        typeId={typeId}
      />
    );
  };

  renderEditButton = () => {
    const { props } = this;
    const { dispatch, currentCard, cardType } = props;
    const endDate = currentCard && parseDate(currentCard.end_date);
    const recordId = currentCard.id;
    const typeId = currentCard.type_id;
    return (
      <EditButton
        cardType={cardType}
        date={moment
          .max(
            currentCard.start_date
              ? moment(currentCard.start_date, 'DD.MM.YYYY HH:mm')
                  .set({
                    seconds: 0,
                    milliseconds: 0,
                    minute: 0,
                  })
                  .add({ hours: 1 })
              : null,
            getServerTime()
              .set({
                seconds: 0,
                milliseconds: 0,
                minute: 0,
              })
              .add({ hours: 1 }),
          )
          .toDate()}
        dispatch={dispatch}
        endDate={endDate}
        recordId={recordId}
        rootId={currentCard.root_id}
        startDate={props.startDate}
        typeId={typeId}
        versions_start_dates={currentCard.versions_start_dates}
      />
    );
  };

  renderMatchingConfirmButton = () => {
    const { currentCard, cardType, onClickMatchingConfirmButton } = this.props;
    return (
      <MatchingConfirmButton
        cardType={cardType}
        id="approve"
        typeId={currentCard.type_id}
        onClick={onClickMatchingConfirmButton}
      />
    );
  };

  renderMatchingRejectButton = () => {
    const { currentCard, cardType, onClickMatchingRejectButton } = this.props;
    return (
      <MatchingRejectButton
        cardType={cardType}
        id="reject"
        typeId={currentCard.type_id}
        onClick={onClickMatchingRejectButton}
      />
    );
  };

  renderSaveButton = () => {
    return (
      <ButtonGreen
        className="show"
        id="save"
        onClick={() => {
          this.props.dispatch(submit('editorCard'));
        }}
      >
        Сохранить
      </ButtonGreen>
    );
  };

  render() {
    const { dispatch } = this.props;
    const isEditMode = this.isEditMode();

    return (
      <div className="btn-group">
        {this.renderButtons()}
        <IconButtonLocal {...{ dispatch, isEditMode }} />
      </div>
    );
  }
}
