import React from 'react';

// Нет - true, Да - false, Да - null
 
/**
 * Инвертированный компонент Да или Нет.
 *
 * @param {object} props - Пропсы.
 * @param {boolean | null} props.value - Значение.
 * @returns {JSX.Element} - JSX.
 * @example
 * {
 *   Cell: YesOrNoInvertCell,
 * };
 */
export const YesOrNoInvertCell = ({ value }) => {
  const text = !value ? 'да' : 'нет';

  return <span>{text}</span>;
};
