import 'assets/styles/admin.out.scss';

import MultiTable from 'app/components/common/table/MultiTable';
import Pagination from 'app/components/common/table/Pagination';
import SortTableBtn from 'app/components/common/table/SortTableBtn';
import cn from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

/* eslint-disable */
export default class Out extends React.Component {
  static defaultProps = {
    headerFirstCell: <th> </th>,
  };

  static propTypes = {
    className: PropTypes.string,
    main: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.element,
    ]),
    headerFirstCell: PropTypes.element,
    columns: PropTypes.array,
    data: PropTypes.object,
    tableConfig: PropTypes.object,
    sort: PropTypes.string,
    onSort: PropTypes.func,
    onLimit: PropTypes.func,
    onPagination: PropTypes.func,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.isAutoScrollTop = this.props.data !== nextProps.data;
  }

  render() {
    const {
      headerFirstCell,
      columns,
      data,
      tableConfig,
      sort,
      onSort,
      onLimit,
      onPagination,
    } = this.props;

    const className = cn(
      'd-flex flex-column h-100 overflow-hidden out',
      {
        noHeaderFirstCellOut: !headerFirstCell,
      },
      this.props.className,
    );

    return (
      <div className={className}>
        <MultiTable
          className="bg-white mb-3"
          isAutoScrollTop={this.isAutoScrollTop}
        >
          <MultiTable.Header>
            <table className="table table-bordered table-striped table-sm m-0">
              <thead>
                <tr>
                  {headerFirstCell}
                  {columns.map((col) => {
                    return (
                      <th key={`th-${col.id}`}>
                        <div className="d-flex align-items-end">
                          {col.name}
                          {(_.isUndefined(col.isSort) || col.isSort) && (
                            <div className="ml-auto pl-1">
                              <SortTableBtn
                                id={col.id}
                                sort={sort || (tableConfig && tableConfig.sort)}
                                onSort={onSort}
                              />
                            </div>
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
            </table>
          </MultiTable.Header>

          <MultiTable.Main>
            <table className="table table-bordered table-striped table-sm m-0">
              <tbody>{this.props.main}</tbody>
            </table>
          </MultiTable.Main>
        </MultiTable>

        <Pagination
          paginationData={data.meta}
          onLimit={onLimit}
          onPagination={onPagination}
        />
      </div>
    );
  }
}
