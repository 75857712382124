import { FilterFF, subTypeIdParams } from 'core/form/filterForm';
import {
  DateFromFilter,
  dateFromParams,
} from 'core/form/filterForm/components/dateFilters/DateFromFilter';
import {
  DateToFilter,
  dateToParams,
} from 'core/form/filterForm/components/dateFilters/DateToFilter';
import {
  OghStatusesFilter,
  oghStatusesParams,
} from 'core/form/filterForm/components/miltiSelect/OghStatusesFilter';
import { mafTypeLevel2Params } from 'core/form/filterForm/components/selects/MafTypeLevel2.Filter';
import { mafTypeLevel3Params } from 'core/form/filterForm/components/selects/MafTypeLevel3.Filter';
import {
  EaisIdFilter,
  eaisIdParams,
} from 'core/form/filterForm/components/text/EaisId.Filter';
import {
  IdOrNameFilter,
  idOrNameParams,
} from 'core/form/filterForm/components/text/IdOrName.Filter';
import { preparationValues } from 'core/utils/helpers/preparationValues';
import { useQueryParams } from 'core/utils/hooks/useQueryParams';
import PropTypes from 'prop-types';
import React from 'react';

import { MafTypeFilterField } from './filters/MafType.Filter';
import { MafTypeLevel2FilterField } from './filters/MafTypeLevel2Filter.Field';
import { MafTypeLevel3FilterField } from './filters/MafTypeLevel3.Filter.Field';
import {
  parentTypeIdParams,
  TypeIdOghFilter,
} from './filters/TypeIdOgh.Filter';

/**
 * Хук получения изначального состояния и изменения фильтров _Малые архитектурные формы__.
 *
 * @returns {{prepareFilters: object, setFilters: Function, filters: object}}
 */
export const useFilterQueryParamsMafRfid = () => {
  const [filters, setFilters] = useQueryParams([
    dateFromParams,
    dateToParams,
    eaisIdParams,
    idOrNameParams,
    oghStatusesParams,
    subTypeIdParams,
    mafTypeLevel2Params,
    mafTypeLevel3Params,
    parentTypeIdParams,
  ]);

  return {
    filters,
    prepareFilters: preparationValues(filters),
    setFilters,
  };
};

/**
 * Форма фильтрации _Малые архитектурные формы__.
 *
 * @returns {JSX.Element}
 */
export const FilterMafRfid = () => {
  const { setFilters, filters } = useFilterQueryParamsMafRfid();

  /**
   * Функция сброса значений формы.
   *
   * @param {object} values - Значения формы.
   * @returns {void}
   */
  const onSubmit = async (values) => {
    setFilters(values, 'push');
  };

  /**
   * Функция сброса значений формы.
   *
   * @param {object} form - Форма предоставляет FF.
   * @returns {void}
   */
  const reset = (form) => {
    form.reset();
    setFilters({}, 'replace');
  };

  return (
    <FilterFF {...{ initialValues: filters, onSubmit, reset }}>
      <IdOrNameFilter />
      <OghStatusesFilter />
      <DateFromFilter />
      <DateToFilter />
      <EaisIdFilter />
      <TypeIdOghFilter />
      <MafTypeFilterField />
      <MafTypeLevel2FilterField />
      <MafTypeLevel3FilterField />
    </FilterFF>
  );
};

FilterMafRfid.propTypes = {
  typeId: PropTypes.number.isRequired,
};
