import { LinkShortIdCell } from 'core/uiKit/components/cells/LinkShortId.Cell/index';

export const treesAndShrubsColumns = [
  {
    Cell: LinkShortIdCell,
    Header: 'ID',
    accessor: 'root_id',
    minWidth: 120,
  },
  {
    Header: 'Номер участка',
    accessor: 'section_num',
  },
  {
    Header: 'Номер растения',
    accessor: 'green_num',
  },
  {
    Header: 'Тип насаждения',
    accessor: 'plantation_type_name',
  },
  {
    Header: 'Жизненная форма',
    accessor: 'life_form_type_name',
  },
  {
    Header: 'Вид растения',
    accessor: 'plant_type_name',
  },
  {
    Header: 'Количество, шт.',
    accessor: 'quantity',
  },
  {
    Header: 'Площадь, кв.м',
    accessor: 'area',
  },
  {
    Header: 'Количество, п.м',
    accessor: 'distance',
  },
  {
    Header: 'Статус',
    accessor: 'object_status_name',
    minWidth: 110,
  },
  {
    Header: 'Дата начала',
    id: 'start_date',
    minWidth: 90,
  },
  {
    Header: 'Дата окончания',
    id: 'end_date',
    minWidth: 90,
  },
];
