/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */

/**
 * Колонка __Связанные элементы ЗН (ID) - Деревья/Кустарники__.
 *
 * @type {{ accessor: 'related_elements_trees_shrubs_id',Header: 'Связанные элементы ЗН (ID) - Деревья/Кустарники' }}
 * @augments Cell
 */
export const related_elements_trees_shrubs_id = {
  Header: 'Связанные элементы ЗН (ID) - Деревья/Кустарники',
  accessor: 'related_elements_trees_shrubs_id',
  sortable: true,
};
