import { clearCurrentCardCash } from 'app/api/hooks/useGetCurrentCard/clearCurrentCardCash';
import { fetchUpdate } from 'app/api/ogh';
/* eslint-disable  */
import { useDialogs } from 'app/pages/cardsOgh/components/DIalogs/useDialogs';
import { usePreloaderCard } from 'app/pages/cardsOgh/components/PreloadedCard/usePreloaderCard';
import { useGetTreePanelData } from 'app/pages/cardsOgh/components/RightPanelCard/components/TreeRightPanel/hooks/useGetTreePanelData';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import {
  UPDATE_FROM_PARENT,
  useTypeActionCard,
} from 'app/pages/cardsOgh/hooks/useTypeActionCard';
import { toast } from 'core/uiKit/components/Toast';
import { useGetParamsUrl } from 'core/utils/hooks/useGetParamsUrl';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import { useNavigateRouter } from 'core/utils/router';

/**
 * Hooks обновления карточки.
 *
 * @returns {{updateForm: Function, updateFormLoading: boolean }}
 */
export const useUpdateForm = () => {
  const { clearEditMode } = useEditMode();
  const { removeTree } = useGetTreePanelData();
  const { endPreload } = usePreloaderCard();
  const { showAlert } = useDialogs();
  const typeAction = useTypeActionCard();
  const navigate = useNavigateRouter();
  const { parentId } = useGetParamsUrl();

  const [updateForm, { isLoading }] = useMutationAdaptor(fetchUpdate, {
    /**
     * OnError.
     *
     * @param {object} err - Error.
     * @param {string} err.message - Message Error.
     * @returns {void}
     */
    onError(err) {
      if (err?.message?.match(/рассчитанная по геометрии/g)) {
        showAlert(err.message);
      } else {
        showAlert(err.message ? err.message : 'Ошибка при создании паспорта');
      }
      endPreload();
    },

    /**
     * OnSuccess.
     *
     * @param {object} value - Ответ.
     * @returns {void}
     */
    onSuccess(value) {
      endPreload();
      removeTree();
      clearEditMode();
      toast.successDying('Изменения сохранены', {
        autoClose: 2000,
      });
      switch (typeAction) {
        case UPDATE_FROM_PARENT: {
          navigate(`/ogh/${parentId}/${value.id}`);
          break;
        }
        default: {
          navigate(`/ogh/${value.id}`);
          break;
        }
      }
      clearCurrentCardCash();
    },
  });
  return { updateForm, updateFormLoading: isLoading };
};
