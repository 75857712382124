import { cellsMainMap } from 'core/uiKit/components/cells/cellsMainMap';

/**
 * @typedef {import('src/core/uiKit/components/tables/Table/Table').Columns} Columns
 */

/**
 * Получение Columns таблицы В реестре Малые архитектурные формы и элементы благоустройства.
 *
 * @returns {Columns} - Жопа.
 * @example ---
 * getColumnsLittleForm()
 */
export const getColumnsLittleForm = () => [
  cellsMainMap.short_root_id, //inkShortIdCell,
  {
    ...cellsMainMap.object_type_name, // 'Тип (вид МАФ)',
    Header: 'Тип (вид МАФ)',
  },
  cellsMainMap.maf_type_level2_name, // 'Уточнение',
  cellsMainMap.maf_type_level3_name, // 'Детализация',
  cellsMainMap.object_status_name, // 'Статус',
  cellsMainMap.sign_date, // 'Дата подписания',
  cellsMainMap.sign_person, // 'ФИО подписанта',
  cellsMainMap.start_date, // 'Дата начала',
  cellsMainMap.end_date, // 'Дата окончания',
  cellsMainMap.parent_object_name, // 'Наименование родительского объекта',
  cellsMainMap.parent_short_root_id, // 'Идентификатор родительского объекта',
  cellsMainMap.parent_okrug_name, // 'Округ родительского объекта',
  cellsMainMap.parent_owner_name, // 'Балансодержатель родительского объекта',
  cellsMainMap.parent_grbs_name, // ГРБС
  cellsMainMap.quantity_pcs, // 'Количество, шт.',
  { ...cellsMainMap.quantity_rm, Header: 'Длина, п.м' }, // 'Длина, п.м',
  cellsMainMap.material_name, // 'Материал',
  cellsMainMap.zone_name, // 'Принадлежность элемента к зоне территории',
  cellsMainMap.is_diff_height_mark, // 'Разновысотные отметки',
  cellsMainMap.included_in_tl, // 'Входит в ТС',
  cellsMainMap.id_rfid, // 'ID RFID метки',
  cellsMainMap.installation_date, // 'Дата установки',
  cellsMainMap.lifetime, // 'Срок эксплуатации',
  cellsMainMap.guarantee_period, // 'Гарантийный срок',
  cellsMainMap.eais_id, // 'ИД УАИС БУ',
  cellsMainMap.erz_state, // 'Состояние (из ЕРЗ)',
  cellsMainMap.erz_date, // 'Дата состояния (из ЕРЗ)',
  cellsMainMap.is_filled, // 'Данные заполнены',
];
