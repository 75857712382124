import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import isMandatory from 'app/utils/card/isMandatory';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import React from 'react';

export const FLAT_ELEMENT_TYPE_ID_NAME = 'flat_element_type_id';

/**
 * Select Field RF Тип.
 *
 * @returns
 */
export const FlatElementTypeIdField = () => {
  const { typeId, flat_element_type } = useGetCurrentCard();
  const { editMode } = useMode();
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();
  return (
    <SelectRF
      name={FLAT_ELEMENT_TYPE_ID_NAME}
      options={flat_element_type || []}
      label={'Тип'}
      required={isMandatory(typeId, FLAT_ELEMENT_TYPE_ID_NAME, editMode)}
      disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
    />
  );
};
