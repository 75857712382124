import { useFetchOghTable } from 'app/pages/registry/ogh/api';
import { useParamsTable } from 'core/uiKit/components/tables/Table';

import { TableOghChildrenProps } from '../../types';
import { useFilterQueryParamsRedBookPlant } from './Filter.RedBookPlant';

/**
 * Setup Таблицы __Растения Красной книги__.
 *
 * @param {TableOghChildrenProps} props - Пропсы.
 * @returns Данные для таблицы.
 */
export const useSetupTableRedBookPlant = ({
  typeId,
  parentTypeId,
}: TableOghChildrenProps) => {
  const { prepareFilters = {} } = useFilterQueryParamsRedBookPlant();
  const { queryTable = {} } = useParamsTable();

  const queryKey = [
    'TableRedBookPlant',
    {
      ...queryTable,
      ...prepareFilters,
      parentTypeId,
      typeId,
    },
  ];

  const { table, isLoading, count } = useFetchOghTable(queryKey);

  return {
    count,
    isLoading,
    table,
  };
};
