import 'assets/styles/card.nestedTable.scss';

import MultiTable from 'app/components/common/table/MultiTable';
import Pagination from 'app/components/common/table/Pagination';
import SortTableBtn from 'app/components/common/table/SortTableBtn';
import cn from 'classnames';
import PropTypes from 'prop-types';
/* eslint-disable */
import React from 'react';

class NestedTable extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    main: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.element,
    ]),
    footer: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.element,
    ]),
    columns: PropTypes.array,
    data: PropTypes.object,
    header2: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.element,
    ]),
    isAutoScrollTop: PropTypes.bool,
    isAutoScrollLeft: PropTypes.bool,
    onLimit: PropTypes.func,
    onPagination: PropTypes.func,
    onSort: PropTypes.func,
  };

  render() {
    const {
      columns,
      isAutoScrollTop,
      data,
      onLimit,
      onPagination,
      onSort,
      isAutoScrollLeft,
    } = this.props;

    return (
      <div className={cn('d-flex flex-column', 'out', this.props.className)}>
        <MultiTable
          className="bg-white mb-3"
          isAutoScrollLeft={isAutoScrollLeft}
          isAutoScrollTop={isAutoScrollTop}
        >
          <MultiTable.Header>
            <table className="table table-bordered table-striped table-sm m-0">
              <thead>
                <tr>
                  {columns.map((col) => {
                    return (
                      <th className={cn('cell', col.id)} key={`th-${col.id}`}>
                        <div className="d-flex align-items-end">
                          {col.name}
                          {col.isSort ? (
                            <div className="ml-auto pl-1">
                              <SortTableBtn
                                id={col.id}
                                sort={data && data.meta && data.meta.sort}
                                onSort={onSort}
                              />
                            </div>
                          ) : null}
                        </div>
                      </th>
                    );
                  })}
                </tr>
                {this.props.header2}
              </thead>
            </table>
          </MultiTable.Header>

          <MultiTable.Main>
            <table className="table table-bordered table-striped table-sm m-0">
              <tbody
                onWheel={(e) => {
                  e.stopPropagation();
                }}
              >
                {this.props.main}
              </tbody>
            </table>
          </MultiTable.Main>

          {this.props.footer && (
            <MultiTable.Footer>
              <table className="table table-bordered table-striped table-sm m-0">
                <tfoot>
                  <tr>{this.props.footer}</tr>
                </tfoot>
              </table>
            </MultiTable.Footer>
          )}
        </MultiTable>
        {data && data.meta && (
          <Pagination
            paginationData={data.meta}
            onLimit={onLimit}
            onPagination={onPagination}
          />
        )}
      </div>
    );
  }
}

 
export default NestedTable;
