import { SelectFF } from 'core/form/finalForm/fields/default/selects/SelectFF';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';
import { NumberParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

export const RE_APPROVE_REQUIRED_NAME = 'reapproveRequired';

/**
 * Требует повторного утверждения reapproveRequired.
 *
 * @returns {JSX.Element}
 */
export const ReApproveRequiredFilter = () => {
  const options = [
    { id: 1, name: 'Да' },
    { id: -1, name: 'Нет' },
  ];
  return (
    <SelectFF
      className={filterInput}
      {...{ options }}
      label={'Требует повторного утверждения'}
      name={RE_APPROVE_REQUIRED_NAME}
    />
  );
};

/**
 * QueryParam __Требует повторного утверждения__.
 *
 * @returns {{RE_APPROVE_REQUIRED_NAME: NumberParam}} - Параметр.
 */
export const reApproveRequiredParams = () => {
  addPreparation(RE_APPROVE_REQUIRED_NAME, (value) => value > 0);
  return {
    [RE_APPROVE_REQUIRED_NAME]: NumberParam,
  };
};
