import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
/* eslint-disable */
import { elementFormGrid } from 'app/components/card/common/grid/index';
import {
  ANNUALS_AREA,
  APOTHECARY_GARDEN,
  BIENNIALS_AREA,
  BULBOUS_AREA,
  CARPET_PLANT_AREA,
  FLINDERS_AREA,
  FLOOR_VERTICAL_DESIGN_AREA,
  FLOOR_VERTICAL_DESIGN_QTY,
  FLOWERS_VERTICAL_DESIGN_AREA,
  FLOWERS_VERTICAL_DESIGN_QTY,
  GREEN_NUM,
  INERT_AREA,
  INERT_OTHER_AREA,
  INERT_STONE_CHIPS_AREA,
  INERT_TREE_BARK_AREA,
  PERENNIALS_AREA,
  SEASON_SHIFT_AREA,
  SECTION_NUM,
  SHRUBS_AREA,
  SUSPEND_VERTICAL_DESIGN_AREA,
  SUSPEND_VERTICAL_DESIGN_QTY,
  TOTAL_AREA,
  TULIP_AREA,
  VASE_QTY,
} from 'app/constants/fieldNames';
import { SHRUBS_HINT } from 'app/constants/messages';
import { IMPROVEMENT_OBJECT, OZN, SPA, YARD } from 'app/constants/oghTypes';
import { column } from 'app/constants/tables';
import { isInteger } from 'app/utils/mathUtils';
import { NumberFieldRF } from 'core/form/reduxForm/fields';
import { TOTAL_AREA_GEO_NAME } from 'core/uiKit/preparedInputs/textField/TotalAreaGeo';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const areaCalcDict = {
  [INERT_AREA]: [
    INERT_STONE_CHIPS_AREA,
    INERT_OTHER_AREA,
    INERT_TREE_BARK_AREA,
  ],
  [SEASON_SHIFT_AREA]: [
    ANNUALS_AREA,
    PERENNIALS_AREA,
    INERT_AREA,
    SHRUBS_AREA,
    BULBOUS_AREA,
    TULIP_AREA,
    BIENNIALS_AREA,
  ],
  [TOTAL_AREA]: [
    ANNUALS_AREA,
    PERENNIALS_AREA,
    INERT_AREA,
    SHRUBS_AREA,
    APOTHECARY_GARDEN,
    CARPET_PLANT_AREA,
  ],
};
const propTypes = {
  changeFieldValue: PropTypes.func,
  editable: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  typeId: PropTypes.number.isRequired,
  values: PropTypes.object.isRequired,
};

/**
 * Класс CharacteristicsTab.
 */
class CharacteristicsTab extends React.Component {
  /**
   * Функция получения полей getCharacteristicsElements.
   *
   * @returns {Array} - Возвращает отфильтрованные поля.
   * @example ----
   */
  getCharacteristicsElements() {
    const {
      editable,
      required,
      isParent,
      parentTypeId,
      isCurrentObjectIsExternalSystem,
    } = this.props;

    const fields = [
      <div>
        <NumberFieldRF
          disabled={!editable || isCurrentObjectIsExternalSystem}
          label={'№ участка'}
          name={SECTION_NUM}
          required={true}
          maxLength={3}
        />
      </div>,
      {
        addition: {
          maxLength: 15,
          required,
        },
        component: NumberFieldRF,
        editable: editable && !isCurrentObjectIsExternalSystem,
        formValue: true,
        id: GREEN_NUM,
        label: '№ цветника',
        name: GREEN_NUM,
        showPassports: [IMPROVEMENT_OBJECT, OZN, SPA, YARD],
      },
      {
        addition: {
          decimalScale: 2,
          maxLength: 16,
        },
        component: NumberFieldRF,
        editable: false,
        formValue: true,
        id: TOTAL_AREA,
        label: 'Общая площадь цветника, кв.м',
        name: TOTAL_AREA,
        showPassports: [IMPROVEMENT_OBJECT, OZN, SPA, YARD],
      },
      {
        addition: {
          decimalScale: 2,
          maxLength: 16,
        },
        component: NumberFieldRF,
        editable: false,
        formValue: true,
        id: TOTAL_AREA_GEO_NAME,
        label: 'Площадь объекта по пространственным координатам, кв.м.',
        name: TOTAL_AREA_GEO_NAME,
        showPassports: [IMPROVEMENT_OBJECT, OZN, SPA, YARD],
      },
      {
        addition: {
          decimalScale: 2,
          maxLength: 11,

          /**
           *
           * @param value
           */
          onChange: (value) => {
            this.setCalculatedField(TOTAL_AREA, ANNUALS_AREA, value);
            this.setCalculatedField(SEASON_SHIFT_AREA, ANNUALS_AREA, value);
          },
        },
        component: NumberFieldRF,
        editable: editable && !isCurrentObjectIsExternalSystem,
        formValue: true,
        id: ANNUALS_AREA,
        label: 'Однолетники, кв.м',
        name: ANNUALS_AREA,
        showPassports: [IMPROVEMENT_OBJECT, OZN, SPA, YARD],
      },
      {
        addition: {
          decimalScale: 2,
          maxLength: 11,
        },
        component: NumberFieldRF,
        editable,
        formValue: true,
        id: 'annual_vase_area',
        label: 'Однолетники в вазонах, кв.м',
        name: 'annual_vase_area',
        showPassports: [SPA],
      },
      {
        addition: {
          decimalScale: 2,
          maxLength: 11,

          /**
           *
           * @param value
           */
          onChange: (value) => {
            this.setCalculatedField(TOTAL_AREA, PERENNIALS_AREA, value);
            this.setCalculatedField(SEASON_SHIFT_AREA, PERENNIALS_AREA, value);
          },
        },
        component: NumberFieldRF,
        editable: editable && !isCurrentObjectIsExternalSystem,
        formValue: true,
        id: PERENNIALS_AREA,
        label:
          SPA === this.props.parentTypeId
            ? 'Кустовые и корневищные многолетники, кв.м'
            : 'Многолетники и розы, кв.м',
        name: PERENNIALS_AREA,
        showPassports: [IMPROVEMENT_OBJECT, OZN, SPA, YARD],
      },
      {
        addition: {
          decimalScale: 2,
          maxLength: 11,

          /**
           *
           * @param value
           */
          onChange: (value) => {
            this.setCalculatedField(TOTAL_AREA, INERT_AREA, value);
            this.setCalculatedField(SEASON_SHIFT_AREA, INERT_AREA, value);
          },
        },
        component: NumberFieldRF,
        editable: [YARD].includes(parentTypeId) && editable,
        formValue: true,
        id: INERT_AREA,
        label: 'Инертный материал (отсыпка), кв.м',
        name: INERT_AREA,
        showPassports: [IMPROVEMENT_OBJECT, OZN, SPA, YARD],
      },
      {
        addition: {
          decimalScale: 2,
          maxLength: 11,

          /**
           *
           * @param value
           */
          onChange: (value) => {
            switch (parentTypeId) {
              case OZN: {
                this.setCalculatedField(
                  INERT_AREA,
                  INERT_STONE_CHIPS_AREA,
                  value,
                );
                break;
              }
              default: {
                break;
              }
            }
          },
        },
        component: NumberFieldRF,
        editable: [OZN].includes(parentTypeId) && editable,
        formValue: true,
        id: INERT_STONE_CHIPS_AREA,
        label: 'Инертный материал (отсыпка), каменная крошка, кв.м',
        name: INERT_STONE_CHIPS_AREA,
        showPassports: [OZN, YARD],
      },
      {
        addition: {
          decimalScale: 2,
          maxLength: 11,

          /**
           *
           * @param value
           */
          onChange: (value) => {
            switch (parentTypeId) {
              case OZN: {
                this.setCalculatedField(
                  INERT_AREA,
                  INERT_TREE_BARK_AREA,
                  value,
                );
                break;
              }
              default: {
                break;
              }
            }
          },
        },
        component: NumberFieldRF,
        editable: [OZN].includes(parentTypeId) && editable,
        formValue: true,
        id: INERT_TREE_BARK_AREA,
        label: 'Инертный материал (отсыпка), древесная кора (щепа), кв.м',
        name: INERT_TREE_BARK_AREA,
        showPassports: [OZN, YARD],
      },
      {
        addition: {
          decimalScale: 2,
          maxLength: 11,

          /**
           *
           * @param value
           */
          onChange: (value) => {
            switch (parentTypeId) {
              case OZN: {
                this.setCalculatedField(INERT_AREA, INERT_OTHER_AREA, value);
                break;
              }
              default: {
                break;
              }
            }
          },
        },
        component: NumberFieldRF,
        editable: [OZN].includes(parentTypeId) && editable,
        formValue: true,
        id: INERT_OTHER_AREA,
        label: 'Инертный материал (отсыпка), иное, кв.м',
        name: INERT_OTHER_AREA,
        showPassports: [OZN, YARD],
      },

      {
        addition: {
          decimalScale: 2,
          maxLength: 11,

          /**
           *
           * @param value
           */
          onChange: (value) => {
            this.setCalculatedField(TOTAL_AREA, CARPET_PLANT_AREA, value);
          },
        },
        component: NumberFieldRF,
        editable: editable && !isCurrentObjectIsExternalSystem,
        formValue: true,
        id: CARPET_PLANT_AREA,
        label: 'Ковровые растения, кв.м',
        name: CARPET_PLANT_AREA,
        showPassports: [SPA],
      },
      {
        addition: {
          decimalScale: 2,
          helperText: SHRUBS_HINT,
          maxLength: 11,

          /**
           *
           * @param value
           */
          onChange: (value) => {
            this.setCalculatedField(TOTAL_AREA, SHRUBS_AREA, value);
            this.setCalculatedField(SEASON_SHIFT_AREA, SHRUBS_AREA, value);
          },
        },
        component: NumberFieldRF,
        editable,
        formValue: true,
        id: SHRUBS_AREA,
        label: 'Декоративные кустарники (как элемент цветника), кв.м',
        name: SHRUBS_AREA,
        showPassports: [IMPROVEMENT_OBJECT, OZN, SPA, YARD],
      },
      {
        addition: {
          decimalScale: 2,
          maxLength: 11,

          /**
           *
           * @param value
           */
          onChange: (value) => {
            this.setCalculatedField(TOTAL_AREA, 'apothecary_garden', value);
          },
        },
        component: NumberFieldRF,
        editable: editable && !isCurrentObjectIsExternalSystem,
        formValue: true,
        id: 'apothecary_garden',
        label: 'Аптекарский огород, кв.м',
        name: APOTHECARY_GARDEN,
        showPassports: [SPA],
      },
      {
        addition: {
          decimalScale: 2,
          maxLength: 16,
        },
        component: NumberFieldRF,
        defaultValue: this.calcArea(SEASON_SHIFT_AREA),
        editable: false,
        formValue: true,
        id: SEASON_SHIFT_AREA,
        label: 'С учетом сезонной сменности, кв.м',
        name: SEASON_SHIFT_AREA,
        showPassports: [IMPROVEMENT_OBJECT, OZN, SPA, YARD],
      },
      {
        addition: {
          decimalScale: 2,
          maxLength: 11,

          /**
           *
           * @param value
           */
          onChange: (value) => {
            this.setCalculatedField(SEASON_SHIFT_AREA, BULBOUS_AREA, value);
          },
        },
        component: NumberFieldRF,
        editable,
        formValue: true,
        id: BULBOUS_AREA,
        label: 'Луковичные и клубнелуковичные кроме тюльпанов, нарциссов, кв.м',
        name: BULBOUS_AREA,
        showPassports: [IMPROVEMENT_OBJECT, OZN, SPA, YARD],
      },
      {
        addition: {
          decimalScale: 2,
          maxLength: 11,

          /**
           *
           * @param value
           */
          onChange: (value) => {
            this.setCalculatedField(SEASON_SHIFT_AREA, TULIP_AREA, value);
          },
        },
        component: NumberFieldRF,
        editable,
        formValue: true,
        id: TULIP_AREA,
        label: 'Тюльпаны, нарциссы, кв.м',
        name: TULIP_AREA,
        showPassports: [IMPROVEMENT_OBJECT, OZN, SPA, YARD],
      },
      {
        addition: {
          decimalScale: 2,
          maxLength: 11,

          /**
           *
           * @param value
           */
          onChange: (value) => {
            this.setCalculatedField(SEASON_SHIFT_AREA, BIENNIALS_AREA, value);
          },
        },
        component: NumberFieldRF,
        editable,
        formValue: true,
        id: BIENNIALS_AREA,
        label: 'Двулетники (виола), кв.м',
        name: BIENNIALS_AREA,
        showPassports: [IMPROVEMENT_OBJECT, OZN, SPA, YARD],
      },
      {
        addition: {
          decimalScale: 2,
          maxLength: 11,
        },
        component: NumberFieldRF,
        editable: editable && !isCurrentObjectIsExternalSystem,
        formValue: true,
        id: VASE_QTY,
        label: 'Вазоны, шт.',
        name: VASE_QTY,
        showPassports: [IMPROVEMENT_OBJECT, SPA],
      },
      {
        addition: {
          decimalScale: 2,
          maxLength: 11,
        },
        component: NumberFieldRF,
        editable: editable && !isCurrentObjectIsExternalSystem,
        formValue: true,
        id: FLOOR_VERTICAL_DESIGN_AREA,
        label: 'Напольные вертикальные конструкции, кв.м',
        name: FLOOR_VERTICAL_DESIGN_AREA,
        showPassports: [IMPROVEMENT_OBJECT, SPA],
      },
      {
        addition: {
          maxLength: 11,
        },
        component: NumberFieldRF,
        editable: editable && !isCurrentObjectIsExternalSystem,
        formValue: true,
        id: FLOOR_VERTICAL_DESIGN_QTY,
        label: 'Напольные вертикальные конструкции, шт.',
        name: FLOOR_VERTICAL_DESIGN_QTY,
        showPassports: [IMPROVEMENT_OBJECT, SPA],
      },
      {
        addition: {
          decimalScale: 2,
          maxLength: 11,
        },
        component: NumberFieldRF,
        editable: editable && !isCurrentObjectIsExternalSystem,
        formValue: true,
        id: SUSPEND_VERTICAL_DESIGN_AREA,
        label: 'Подвесные вертикальные конструкции, кв.м',
        name: SUSPEND_VERTICAL_DESIGN_AREA,
        showPassports: [IMPROVEMENT_OBJECT, SPA],
      },
      {
        addition: {
          maxLength: 11,
        },
        component: NumberFieldRF,
        editable: editable && !isCurrentObjectIsExternalSystem,
        formValue: true,
        id: SUSPEND_VERTICAL_DESIGN_QTY,
        label: 'Подвесные вертикальные конструкции, шт.',
        name: SUSPEND_VERTICAL_DESIGN_QTY,
        showPassports: [IMPROVEMENT_OBJECT, SPA],
      },
      {
        addition: {
          decimalScale: 2,
          maxLength: 11,
        },
        component: NumberFieldRF,
        editable: editable && !isCurrentObjectIsExternalSystem,
        formValue: true,
        id: FLOWERS_VERTICAL_DESIGN_AREA,
        label: 'Вертикальные конструкции. Иные элементы, кв.м',
        name: FLOWERS_VERTICAL_DESIGN_AREA,
        showPassports: [IMPROVEMENT_OBJECT, SPA],
      },
      {
        addition: {
          maxLength: 11,
        },
        component: NumberFieldRF,
        editable: editable && !isCurrentObjectIsExternalSystem,
        formValue: true,
        id: FLOWERS_VERTICAL_DESIGN_QTY,
        label: 'Вертикальные конструкции. Иные элементы, шт.',
        name: FLOWERS_VERTICAL_DESIGN_QTY,
        showPassports: [IMPROVEMENT_OBJECT, SPA],
      },
      {
        addition: {
          decimalScale: 2,
          maxLength: 11,
        },
        component: NumberFieldRF,
        editable: editable && !isCurrentObjectIsExternalSystem,
        formValue: true,
        id: FLINDERS_AREA,
        label: 'Декорирование щепой, кв.м',
        name: FLINDERS_AREA,
        showPassports: [IMPROVEMENT_OBJECT, OZN, SPA, YARD],
      },
      {
        addition: {
          decimalScale: 2,
          maxLength: 11,
        },
        component: NumberFieldRF,
        editable: [YARD].includes(parentTypeId) && editable,
        formValue: true,
        id: 'rockery_area',
        label: 'Рокарий (многолетние, однолетние), кв.м',
        name: 'rockery_area',
        showPassports: [OZN, YARD],
      },
      {
        addition: {
          decimalScale: 2,
          maxLength: 11,
        },
        component: NumberFieldRF,
        editable,
        formValue: true,
        id: 'lawn_area',
        label: 'Газоны (как элемент цветника), кв.м',
        name: 'lawn_area',
        showPassports: [OZN, YARD],
      },
    ];
    return parentTypeId && isParent
      ? fields?.filter(({ showPassports } = {}) => {
          return showPassports ? showPassports?.includes(parentTypeId) : true;
        })
      : fields;
  }

  /**
   * Функция установки вычисляемых значений setCalculatedField.
   *
   * @param {string} calculatedFieldName - Имя.
   * @param {string} changedFieldName - Имя изменяемого поля.
   * @param {string | *} value - Значение.
   * @returns {void} - Nothing.
   * @example ----
   */
  setCalculatedField(calculatedFieldName, changedFieldName, value) {
    const { changeFieldValue } = this.props;

    changeFieldValue(
      calculatedFieldName,
      this.calcArea(calculatedFieldName, {
        [changedFieldName]: value,
      }),
    );
  }

  /**
   * Функция calcArea.
   *
   * @param {string} fieldName - Имя.
   * @param {Function} changedValue - Функция изменения значения.
   * @returns {null|*|string} - Жопа.
   * @example ----
   */
  calcArea(fieldName, changedValue = {}) {
    if (!areaCalcDict[fieldName]) {
      return null;
    }

    const values = Object.assign({}, this.props.values, changedValue);
    const total = areaCalcDict[fieldName].reduce((result, key) => {
      return +result + +get(values, key, 0);
    }, 0);

    return isInteger(total) ? total : total.toFixed(2);
  }

  /**
   * Метод жизненого цикла render().
   *
   * @returns {JSX.Element} - Реакт компонент.
   * @example ----
   */
  render() {
    const { id, typeId } = this.props;
    const elements = this.getCharacteristicsElements();
    return (
      <div id={id}>{elementFormGrid(elements, column.TWO, '', typeId)}</div>
    );
  }
}

CharacteristicsTab.propTypes = propTypes;

/**
 * Компонент обертка для использования хуков.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - Реакт Компонент.
 * @example -----
 */
const CharacteristicsTabContainer = (props) => {
  const { typeId } = useGetParentCard();

  const isParent = [IMPROVEMENT_OBJECT, OZN, SPA, YARD].includes(typeId);
  return (
    <CharacteristicsTab parentTypeId={typeId} isParent={isParent} {...props} />
  );
};

export default CharacteristicsTabContainer;
