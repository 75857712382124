/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './style.scss';

class FieldTooltip extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.node,
  };

  render() {
    const { className, style, children } = this.props;
    const classes = cn('ods-field-tooltip', className);

    return (
      <span className={classes} style={style}>
        {children}
      </span>
    );
  }
}

 
export default FieldTooltip;
