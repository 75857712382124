import { AddButton as AddButtonD } from 'core/uiKit/components/buttons/actionsButtons/Add.Button';
import {
  Table as TableD,
  useParamsTable as useParamsTableD,
} from 'core/uiKit/components/tables/Table';
import { TableSimple as TableSimpleD } from 'core/uiKit/components/tables/TableSimple';

import { Button as ButtonD } from './buttons';
import { CertInfo as CertInfoD } from './CertInfo';
import { Paper as PaperD } from './Paper';
import { toast as toastD, ToastProvider as ToastProviderD } from './Toast';

export * from './buttons';

/**
 * @deprecated
 */
export const Paper = PaperD;

/**
 * @deprecated
 */
export const CertInfo = CertInfoD;

/**
 * @deprecated
 * @see import { Table, useParamsTable } from 'core/uiKit/components/tables/Table';
 */
export const Table = TableD;

/**
 *
 * @deprecated
 * @see import { Table, useParamsTable } from 'core/uiKit/components/tables/Table';
 */
export const useParamsTable = useParamsTableD;

/**
 * @deprecated
 * @see import { TableSimple } from 'core/uiKit/components/tables/TableSimple';
 */
export const TableSimple = TableSimpleD;

/**
 * @deprecated
 * @see core/uiKit/components/buttons/actionsButtons/Add.Button
 */
export const AddButton = AddButtonD;

/**
 * @deprecated
 */
export const toast = toastD;

/**
 * @deprecated
 */
export const ToastProvider = ToastProviderD;

/**
 * @deprecated
 */
export const Button = ButtonD;

export * from './Card';
export * from './dialogs';
export * from './DraggableElement';
export * from './Empty';
export * from './Grid';
export * from './HelpTooltip';
export * from './InfoPanel';
export * from './loaders';
export * from './NavigationBar';
export * from './Pagination';
export * from './skeletons';
export * from './tables/ReactTable';
export * from './tables/ReactTable.Simple';
export * from './TreePanel';
export * from './TypeNavigationBar';
