import { useCheckObjectParent } from 'app/actions/odsObjectActions/useCheckObjectParent';
import { useStartMatchingCard } from 'app/actions/odsObjectActions/useStartMatchingCard';
import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { PROJECT } from 'app/constants/oghStatus';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { useGetCardSelector } from 'app/selectors/useSelectors/useGetCard.Selector';
import { useGetTreeSelector } from 'app/selectors/useSelectors/useGetTree.Selector';
import { RANDOM_ID } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { useParams } from 'react-router-dom';
import { vi } from 'vitest';

import { SendMatchingOghButton } from './SendMatchingOgh.Button';

vi.mock('react-router-dom', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    useParams: vi.fn(),
  };
});
vi.mock('app/actions/odsObjectActions/useCheckObjectParent');
vi.mock('app/actions/odsObjectActions/useStartMatchingCard');
vi.mock('app/selectors/useSelectors/useGetCard.Selector');
vi.mock('app/api/hooks/useGetCurrentCard');
vi.mock('app/selectors/useSelectors/useGetTree.Selector');
vi.mock('app/pages/cardsOgh/hooks/useEditMode');

/**
 * Default mock.
 *
 * @returns {void}
 */
const defaultMock = () => {
  useGetCardSelector.mockReturnValue({});
  useGetCurrentCard.mockReturnValue({});
  useGetTreeSelector.mockReturnValue({});
  useEditMode.mockReturnValue({});
  useStartMatchingCard.mockReturnValue((_) => _);
  useCheckObjectParent.mockReturnValue((_) => _);
};

describe('file SendMatchingOghButton', () => {
  it.each`
    text                           | objectId            | editMode | result
    ${'id & edit mode true'}       | ${{ id: '2' }}      | ${true}  | ${''}
    ${'childId & edit mode true'}  | ${{ childId: '2' }} | ${true}  | ${''}
    ${'none & edit mode true'}     | ${{}}               | ${true}  | ${''}
    ${'id & edit mode false'}      | ${{ id: '2' }}      | ${false} | ${'<ButtonSendMatchingOgh />'}
    ${'childId & edit mode false'} | ${{ childId: '2' }} | ${false} | ${'<ButtonSendMatchingOgh />'}
    ${'none & edit mode false'}    | ${{}}               | ${false} | ${''}
  `('$text', ({ objectId, editMode, result }) => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    defaultMock();
    useParams.mockReturnValue(objectId);
    useEditMode.mockReturnValue({ editMode });

    // Act
    const wrapper = shallow(<SendMatchingOghButton />);

    // Assert
    expect(wrapper.text()).toBe(result);
  });

  it.each`
    text              | objectId                       | result
    ${'id'}           | ${{ id: RANDOM_ID }}           | ${RANDOM_ID}
    ${'id text'}      | ${{ id: '' + RANDOM_ID }}      | ${RANDOM_ID}
    ${'childId'}      | ${{ childId: RANDOM_ID }}      | ${RANDOM_ID}
    ${'childId text'} | ${{ childId: '' + RANDOM_ID }} | ${RANDOM_ID}
  `('$text', ({ objectId, result }) => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    defaultMock();
    useParams.mockReturnValue(objectId);

    // Act
    const wrapper = shallow(<SendMatchingOghButton />);

    // Assert
    expect(wrapper.prop('objectId')).toBe(result);
  });

  it.each`
    text                   | logical_state_id                   | startMatchingCardTimes | checkObjectParentTimes
    ${'rootIsProject'}     | ${{ logical_state_id: PROJECT }}   | ${0}                   | ${1}
    ${'нет rootIsProject'} | ${{ logical_state_id: RANDOM_ID }} | ${1}                   | ${0}
  `(
    '$text',
    ({ logical_state_id, startMatchingCardTimes, checkObjectParentTimes }) => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      defaultMock();
      useParams.mockReturnValue({ id: 2 });
      useGetCardSelector.mockReturnValue(logical_state_id);

      const startMatchingCardSpy = vi.fn().mockResolvedValue(42);
      const checkObjectParentSpy = vi.fn().mockResolvedValue(42);

      useStartMatchingCard.mockReturnValue(startMatchingCardSpy);
      useCheckObjectParent.mockReturnValue(checkObjectParentSpy);

      const wrapper = shallow(<SendMatchingOghButton />);

      // Act
      wrapper.simulate('click');
      wrapper.props();

      // Assert
      expect(startMatchingCardSpy).toHaveBeenCalledTimes(
        startMatchingCardTimes,
      );
      expect(checkObjectParentSpy).toHaveBeenCalledTimes(
        checkObjectParentTimes,
      );
    },
  );
});
