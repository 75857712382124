/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DialogAlert from 'app/components/dialogs/common/DialogAlert';
import MultiTable from 'app/components/common/table/MultiTable';

import getOrderDetails from 'app/selectors/card/orders/getOrderDetails';

const propTypes = {
  orderDetails: PropTypes.object,
  showModal: PropTypes.bool.isRequired,
  toggleDetails: PropTypes.func.isRequired,
};

class OrderDetails extends React.PureComponent {
  handleClose = () => {
    this.props.toggleDetails(null);
  };

  render() {
    const { showModal, orderDetails, columns } = this.props;

    return (
      <DialogAlert
        showCondition={showModal}
        title="Информация об ордере"
        onAfterHiding={this.handleClose}
      >
        <MultiTable className="bg-white mb-3">
          <MultiTable.Header>
            <table className="table table-bordered table-striped table-sm m-0">
              <thead>
                <tr>
                  <th>
                    <div className="d-flex align-items-end">Атрибут</div>
                  </th>
                  <th>
                    <div className="d-flex align-items-end">
                      Значение атрибута
                    </div>
                  </th>
                </tr>
              </thead>
            </table>
          </MultiTable.Header>

          <MultiTable.Main>
            <table className="table table-bordered table-striped table-sm m-0">
              <tbody>
                {orderDetails &&
                  columns.map((col) => {
                    return (
                      <tr key={`tr-${col.id}`}>
                        <td>{col.name}</td>
                        <td>{orderDetails[col.id]}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </MultiTable.Main>
        </MultiTable>
      </DialogAlert>
    );
  }
}

OrderDetails.propTypes = propTypes;

const mapStateToProps = (state) => ({
  columns: state.tableColumns?.orderDetails,
  showModal: state.modals.oghOrderDetails?.active,
  orderDetails: getOrderDetails(state),
});

 
export default connect(mapStateToProps)(OrderDetails);
