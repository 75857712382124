/* eslint-disable  */
import * as registryActions from 'app/actions/titlelistDetailsRegistry';
import { CLEAR_TITLE_LIST } from 'app/constants/actionTypes';
import { handleActions } from 'redux-actions';

export default handleActions(
  {
    /**
     *
     * @param state
     */
    [`${registryActions.apiGetRegistry}_PENDING`]: function (state) {
      return state;
    },

    /**
     *
     * @param state
     */
    [`${registryActions.apiGetRegistry}_REJECTED`]: function (state) {
      state = {
        ...state,
        data: {},
      };
      return state;
    },

    /**
     *
     * @param state
     * @param action
     */
    [`${registryActions.apiGetRegistry}_FULFILLED`]: function (state, action) {
      state = {
        ...state,
        data: action.payload.data,
      };
      return state;
    },

    /**
     *
     * @param state
     */
    [`${CLEAR_TITLE_LIST}`]: function (state) {
      return {
        ...state,
        data: {},
      };
    },
  },
  { data: {} },
);
