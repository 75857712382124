/* eslint-disable  */
import { useUser } from 'app/pages/providers';

/**
 *
 * @param root0
 * @param root0.children
 * @param root0.privilege
 * @param root0.accessCheck
 */
export const ShowByPermissions = ({ children, privilege, accessCheck }) => {
  const { hasPermission } = useUser();
  const localAccessCheck =
    typeof accessCheck === 'function'
      ? accessCheck
      : () => hasPermission(privilege);

  return localAccessCheck() ? children : null;
};
