import { shallow } from 'enzyme';
import React from 'react';
import { vi } from 'vitest';

import { ContainerTypeIdFieldContainer } from './ContainerTypeId.Field.Container';
import { useSetupContainerTypeIdFieldContainer } from './useSetup.ContainerTypeId.Field.Container';

vi.mock('./useSetup.ContainerTypeId.Field.Container');

describe('🐛 spec ContainerTypeId.Field.Container', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    useSetupContainerTypeIdFieldContainer.mockReturnValue({});

    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(<ContainerTypeIdFieldContainer />);

    //❓ Assert
    expect(wrapper.text()).toBe('<ContainerTypeIdRF />');
  });

  it('🧪 props', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    useSetupContainerTypeIdFieldContainer.mockReturnValue({
      filter: 'filter',
      isEdit: 'isEdit',
    });

    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(<ContainerTypeIdFieldContainer />);

    //❓ Assert
    expect(wrapper.props()).toStrictEqual({
      disabled: false,
      filter: 'filter',
    });
  });
});
