import { Select } from 'core/uiKit/inputs/selects/Select';
import PropTypes from 'prop-types';
import React from 'react';

import { cleaningTypes, cleaningTypesWithNoCleaning } from './cleaningTypes';

export const CLEANING_TYPE_NAME = 'cleaning_type';

/**
 * Select Способ уборки.
 *
 * @param {object} props - Пропсы.
 * @param {boolean} props.withNoCleaning - Флаг на присутствие опшина отсутствия уборки.
 * @returns {React.ReactElement}
 */
export const CleaningType = ({ withNoCleaning = false, ...props }) => {
  return (
    <Select
      label={'Способ уборки'}
      withAll={false}
      {...props}
      name={CLEANING_TYPE_NAME}
      options={withNoCleaning ? cleaningTypesWithNoCleaning : cleaningTypes}
    />
  );
};

CleaningType.propTypes = {

  /**
   * Очищаем инпут с сохранением значения.
   */
  clear: PropTypes.bool,

  /**
   * Выбор темной темы.
   */
  dark: PropTypes.bool,

  /**
   * Заблокировать на редактирование.
   */
  disabled: PropTypes.bool,

  /**
   * Вывод ошибки.
   */
  errorText: PropTypes.string,

  /**
   * Вывод вспомогательного текста.
   */
  helperText: PropTypes.string,

  /**
   * Функция вызова когда выберется итем.
   */
  onChange: PropTypes.func,

  /**
   * Вывод информации об обязательном поле.
   */
  required: PropTypes.bool,

  /**
   * Значение.
   */
  value: PropTypes.any,
};
