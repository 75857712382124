import { getAttributeSubmit } from 'app/pages/cardsOgh/helpers/getAttributeSubmit';
import { GREEN_NUM_NAME } from 'core/uiKit/preparedInputs/numberField/GreenNum';
import { SECTION_NUM_NAME } from 'core/uiKit/preparedInputs/numberField/SectionNum';
import { RED_BOOK_PLANT_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/RedBookPlantId';

import { useInitialValues } from './hooks/useInitialValue';

export const mapAttribute = [
  RED_BOOK_PLANT_ID_NAME,
  SECTION_NUM_NAME,
  GREEN_NUM_NAME,
  'noCalc',
];

/**
 * Setup Карточка Растения красной книги.
 *
 * @returns {JSX}
 */
export const useSetupRedBookPlant = () => {
  const initialValues = useInitialValues();

  /**
   * Функция подготовка данных для Атрибутов.
   *
   * @param {*} values - Значения формы.
   * @returns {*} Атрибуты.
   */
  const preparingDataForAttributes = (values) => {
    return getAttributeSubmit(values, mapAttribute);
  };

  return { initialValues, preparingDataForAttributes };
};
