 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
   
/* eslint-disable */
/**
 * Колонка __Площадь, кв.м__.
 *
 * @type {{
 * accessor: 'total_lawn_area',
 * Header: 'Площадь, кв.м',
 * sortable: true,
 * }}
 * @augments Cell
 */
export const total_lawn_area = {
  accessor: 'total_lawn_area',
  Header: 'Площадь, кв.м',
  sortable: true,
};
