import { turnIntoCamelCase } from 'app/utils/turnIntoCamelCase';

/* eslint-disable */
export default function validateMaterial(values) {
  const { materialId } = turnIntoCamelCase(values);

  const errors = {};

  if (!materialId) {
    errors.material_id = 'обязательно для заполнения';
  }
  return errors;
}
