import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import getAccessToPlanarStructureTypeRefId from 'app/selectors/card/getAccessToPlanarStructureTypeRefId';
import { IPlanarStructureTypeRefIdData } from 'core/uiKit/preparedInputs/selects/simpleSelect/PlanarStructureTypeRefId';
import { useSelector } from 'react-redux';

/**
 * Setup Уточнение типа планарной структуры.
 *
 * @returns JSX.Element.
 */
export const useSetupPlanarStructureTypeRefIdField = () => {
  const isEdit = useSelector(getAccessToPlanarStructureTypeRefId);

  const parentCard = useGetParentCard();

  /**
   * Функция фильтрации.
   *
   * @param data - Данные.
   * @returns Данные.
   */
  const filter = (data: IPlanarStructureTypeRefIdData[]) => {
    return data.filter((item) =>
      item?.parent_ogh_object_type_list?.includes(+parentCard?.type_id),
    );
  };

  return { filter, isEdit };
};
