/* eslint-disable */
import React from 'react';

import { filterInput } from '../filterInputsClasses';
import {
  ReagentBaseTypeFF,
  REAGENT_BASE_TYPE_NAME,
} from 'core/form/finalForm/fields';
import { NumberParam } from 'use-query-params';

 
 
/**
 *
 * Тип
 * reagentBaseType
 *
 * @returns {JSX.Element}
 * @constructor
 */
 
export const ReagentBaseTypeFilter = () => (
  <ReagentBaseTypeFF className={filterInput} />
);

 
export const reagentBaseTypeParams = {
  [REAGENT_BASE_TYPE_NAME]: NumberParam,
};
