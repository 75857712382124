/* eslint-disable  */
import * as reportActions from 'app/actions/report';
import { handleActions } from 'redux-actions';

export default handleActions(
  {
    /**
     *
     * @param state
     */
    [`${reportActions.apiGetTechReport}_PENDING`]: function (state) {
      return state;
    },

    /**
     *
     * @param state
     */
    [`${reportActions.apiGetTechReport}_REJECTED`]: function (state) {
      state = {
        ...state,
        data: {},
      };
      return state;
    },

    /**
     *
     * @param state
     * @param action
     */
    [`${reportActions.apiGetTechReport}_FULFILLED`]: function (state, action) {
      state = {
        ...state,
        data: action.payload.data,
      };
      return state;
    },
  },
  { data: {} },
);
