 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Район__.
 *
 * @type {{ accessor: 'district_name',  Header: 'Район',sortable: true, }}
 * @augments Cell
 */
export const district_name = {
  accessor: 'district_name',
  Header: 'Район',
  sortable: true,
};
