import { manualLogout } from 'app/actions/manualLogoutAction';
import { pathAdmin } from 'app/pages/admin/path.Admin';
import { pathUsers } from 'app/pages/admin/Users/path.Users';
import { useUser } from 'app/pages/providers/UserContext';
import { useNavigateRouter } from 'core/utils/router';
import { useDispatch } from 'react-redux';

/**
 * Навигация, пользователя.
 *
 * @returns {{goToChangePasswordPage: Function, handleManualLogout: Function, nameShown: string, handleGoToUser: Function}}
 */
export const useUserNav = () => {
  const { user: { nameShown = '', userName = '' } = {} } = useUser();

  const navigate = useNavigateRouter();
  const dispatch = useDispatch();

  /**
   * Функция handleGoToUser.
   *
   * @returns {void}
   */
  const handleGoToUser = () => {
    navigate(`/${pathAdmin.path}/${pathUsers.path}/${userName}`);
  };

  /**
   * Функция goToChangePasswordPage.
   *
   * @returns {void}
   */
  const goToChangePasswordPage = () => {
    navigate(`/auth/${userName}/edit-password`);
  };

  /**
   * Функция handleManualLogout.
   *
   * @returns {void}
   */
  const handleManualLogout = () => {
    dispatch(manualLogout());
  };

  return {
    goToChangePasswordPage,
    handleGoToUser,
    handleManualLogout,
    nameShown,
  };
};
