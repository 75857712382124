import { NumberField } from 'core/uiKit/inputs';
import PropTypes from 'prop-types';
import React from 'react';

export const PLACES_AREA_NAME = 'places_area';

/**
 * Числовое поле Количество посадочных мест, шт..
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - Возвращает компонент NumberField.
 * @example <Quantity name={QUANTITY_NAME} {...props}/>
 */
export const PlacesArea = (props) => {
  return (
    <NumberField
      {...props}
      label={'Площадь посадочных мест, кв.м.'}
      name={PLACES_AREA_NAME}
      decimalScale={2}
    />
  );
};

PlacesArea.propTypes = {

  /**
   * Выбор темной темы.
   */
  dark: PropTypes.bool,

  /**
   * Заблокировать на редактирование.
   */
  disabled: PropTypes.bool,

  /**
   * Вывод ошибки.
   */
  errorText: PropTypes.string,

  /**
   * Функция вызова когда выберется итем.
   */
  onChange: PropTypes.func,

  /**
   * Вывод информации об обязательном поле.
   */
  required: PropTypes.bool,

  /**
   * Значение.
   */
  value: PropTypes.any,
};
