import { NumberFieldFF } from 'core/form/finalForm/fields';
import React, { useLayoutEffect } from 'react';
import { useField } from 'react-final-form';

 
/**
 * Компонент TonnageFFTank.
 *
 * @returns {JSX.Element} - Возвращает <NumberFieldFF /> с полями и изменяет поле tonnage при изменении quantity.
 * @example <TonnageFFComponent/>
 */
export const TonnageFFTank = () => {
  const { input: { value } = {} } = useField('quantity');
  const { input: { onChange } = {} } = useField('tonnage');

  useLayoutEffect(() => {
    onChange(Number(value) * 1.2);
  }, [value, onChange]);

  return (
    <NumberFieldFF
      name={'tonnage'}
      label={'Тоннаж, т.'}
      maxLength={10}
      decimalScale={1}
      disabled={true}
    />
  );
};
