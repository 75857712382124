import { GeometryTypes, Marker, RawGeometry } from '../types.d';

/**
 * Является ли геометрия точкой.
 *
 * @param geometry - Геометрия.
 * @returns {boolean} Является ли геометрия точкой.
 */
export const isGeometryPoint = (geometry: RawGeometry): geometry is Marker =>
  geometry?.userData?.type === GeometryTypes.Point;
