import { PlanarStructureTypeRefIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/PlanarStructureTypeRefId.RF';
import React from 'react';

import { useSetupPlanarStructureTypeRefIdField } from './useSetup.PlanarStructureTypeRefId.Field';

/**
 * Уточнение типа планарной структуры.
 *
 * @returns JSX.Element.
 */
export const PlanarStructureTypeRefIdField = () => {
  const { filter, isEdit } = useSetupPlanarStructureTypeRefIdField();

  return <PlanarStructureTypeRefIdRF filter={filter} disabled={!isEdit} />;
};
