/* eslint-disable  */
import MapEditor from 'app/services/mapService/MapEditor';
import { egip } from 'app/utils/helpers/egip';
import * as env from 'config/env';

/**
 *
 */
export default class MapService {
  /**
   *
   */
  constructor() {
    egip.setConfig({
      baseURL: env.EGIP_API_URL,
      withCredentials: true,
    });
  }

  /**
   *
   */
  login = async () => {
    await egip.api.login({
      login: env.EGIP_USER,
      password: env.EGIP_PASSWORD,
    });
    this.loggedIn = true;
  };

  /**
   *
   * @param fieldId
   */
  createMapEditor = async (fieldId) => {
    if (!this.loggedIn) {
      await this.login();
    }

    await egip.updateSettings();
    return new MapEditor(fieldId);
  };
}
