import dateFormatService from 'core/services/dateFormatService';
import { isDate, isObject } from 'lodash';

/* eslint-disable */
export const texts = {
  one: (value) =>
    `Предыдущее значение: ${value === null ? 'не установлено' : value}`,
  multiple: (value) => `Предыдущие значения: ${value}`,
};

/* eslint-disable */
export default function getFormattedTitle(oldValue, options = {}) {
  let result;
  if (oldValue) {
    result = getTextByType(oldValue, options);
  } else if (oldValue === null) {
    result = texts.one(oldValue);
  } else {
    result = null;
  }
  return result;
}

function getTextByType(value, { dictionary }) {
  let result;
  if (Array.isArray(value)) {
    result = getTextByArray(value);
  } else if (isDate(value)) {
    result = getTextByDate(value);
  } else if (isObject(value)) {
    result = getTextByObject(value);
  } else if (dictionary) {
    result = getTextByDictionary(dictionary, value);
  } else {
    result = texts.one(value);
  }
  return result;
}

function getTextByArray(value) {
  let result;
  if (0 === value.length) {
    result = null;
  } else if (1 === value.length) {
    result = texts.one(value[0].label);
  } else {
    result = texts.multiple(value.map((item) => item.label).join(', '));
  }
  return result;
}

function getTextByDate(value) {
  return texts.one(dateFormatService.formatDate(value));
}

function getTextByObject(value) {
  return texts.one(value.label);
}

function getTextByDictionary(dictionary, value) {
  const result = dictionary.find((item) => item.id === value);
  return result ? texts.one(result.name) : null;
}
