import { CardOgh } from 'types/Card';

import { validate as validateAquaArea } from '../components/AquaAreaTab';
import { validate as validateSnowArea } from '../components/SnowAreaTab';

/**
 * Функция валидации.Иные объекты благоустройства.
 *
 * @param values - Значения формы.
 * @returns Отвалидированные поля.
 */
export const validateImprovementObject = (values: CardOgh) => {
  return {
    ...validateAquaArea(values),
    ...validateSnowArea(values),
  };
};
