import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { IImprovementObjectCategoryData } from 'core/uiKit/preparedInputs/selects/simpleSelect/ImprovementObjectCategoryId';

/**
 *
 * Setup Категория озеленения. Ozn.
 *
 * @returns JSX.Element.
 */
export const useSetupImprovementObjectCategoryIdFieldOzn = () => {
  const currentCard = useGetCurrentCard();

  const { editMode } = useMode();

  /**
   * Функция фильтрации.
   *
   * @param data - Данные.
   * @returns Данные.
   */
  const filter = (data: IImprovementObjectCategoryData[]) => {
    return data.filter((item) =>
      item?.parent_ogh_object_type_list?.includes(+currentCard?.type_id),
    );
  };

  return { editMode, filter };
};
