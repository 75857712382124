/* eslint-disable */
import React, { useState } from 'react';
import DialogChangeCar from 'app/components/dialogs/technicsRegistry/technics/DialogChangeCar';
import { ButtonBlue, ButtonRed } from 'core/newComponents/Button';

const style = {
  display: 'inline-block',
};

const CarActionButton = ({ children, buttonType, onSubmit, ...props }) => {
  const [opened, setOpened] = useState(false);
  const ButtonComponent = buttonType === 'delete' ? ButtonRed : ButtonBlue;

  return (
    <div style={style}>
      <ButtonComponent onClick={() => setOpened(true)}>
        {children}
      </ButtonComponent>

      <DialogChangeCar
        {...props}
        showCondition={opened}
        onCancel={() => setOpened(false)}
        onSubmit={(planDate, requestType, reasonCode) => {
          setOpened(false);
          onSubmit(planDate, requestType, reasonCode);
        }}
      />
    </div>
  );
};

 
export default CarActionButton;
