import { validateAdditionalInformation } from '../components/AdditionalInformation.Tab';
import { validate as validateAquaArea } from '../components/AquaAreaTab';
import { validate as validateSnowArea } from '../components/SnowAreaTab';

/**
 * Функция валидации OZN.
 *
 * @param {object} values - Значения валидации.
 * @returns {object} - Возвращает валидированные поля.
 * @example - validate(values)
 */
export function validate(values) {
  return {
    ...validateSnowArea(values),
    ...validateAquaArea(values),
    ...validateAdditionalInformation(values),
  };
}
