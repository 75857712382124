import getDict from 'app/selectors/getDict';
/* eslint-disable */
import { get } from 'lodash';
import { createSelector } from 'reselect';

const getReagentKinds = getDict('reagentKinds');

/**
 *
 * @param state
 */
const getCurrentReagentKind = (state) => {
  const currentReagentKindId = get(
    state,
    'form.editCardDialogForm.values.reagent_kind_id',
  );
  return (
    currentReagentKindId &&
    getReagentKinds(state).find((item) => item.id === currentReagentKindId)
  );
};

/**
 *
 * @param state
 */
const getCurrentReagentId = (state) =>
  get(state, 'form.editCardDialogForm.values.reagent_id');

/**
 *
 * @param state
 */
const getChildren = (state) => get(state, 'card.object.children') || [];

export default createSelector(
  [getCurrentReagentKind, getCurrentReagentId, getChildren],
  (currentReagentKind, currentReagentId, children) => {
    let result;

    if (currentReagentKind) {
      if (currentReagentKind.code === 'liquid_reagent') {
        let childrenForSum;
        if (currentReagentId) {
          childrenForSum = children.filter(
            (child) => child.reagentId === currentReagentId,
          );
        } else {
          childrenForSum = children.filter(
            (child) => child.reagentKindId === currentReagentKind.id,
          );
        }
        if (childrenForSum.length > 0) {
          const sum = childrenForSum.reduce(
            (result, child) => result + child.quantity,
            0,
          );
          result = sum.toString();
        } else {
          result = null;
        }
      } else {
        result = null;
      }
    } else {
      result = null;
    }

    return result;
  },
);
