/* eslint-disable */
export const prepareData = (arr = []) => {
  return arr.reduce(
    (ac, branch) => {
      if (branch.type_id) {
        const { count, object, type_id } = branch;
        return {
          ...ac,
          branches: [
            ...ac.branches,
            {
              typeOgh: type_id.toString(),
              count,
              links: object.map((link) => {
                const { id, name, short_id } = link;
                return {
                  id: id,
                  title: `${short_id} ${name ? name : ''}`,
                };
              }),
            },
          ],
        };
      }
      return { ...ac, count: branch.count };
    },
    { branches: [], count: 0 },
  );
};
