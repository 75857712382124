import { Validator } from 'core/form/Validator';

/**
 * Валидация диалогового окна таблицы Проектов.
 *
 * @param {object} values - Значения формы.
 * @returns {Record<string, string>}
 */
export const validateRepairsInfoListPlan = (values) => {
  const validator = new Validator(values);

  validator.setRequired('date_start');
  validator.setRequired('date_end');
  validator.setRequired('files');
  validator.setRequired('name');

  return validator.getErrors();
};
