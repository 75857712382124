import { PREFIX_DOCUMENT_TABLE } from 'app/pages/cardsOgh/components/tables/Documents/helpers/getNameFile';
/* eslint-disable  */

/**
 *
 * @param values
 */
export const getFileList = (values) => {
  return Object.keys(values).reduce((arr, key) => {
    const regexp = new RegExp(`${PREFIX_DOCUMENT_TABLE}`, 'gi');
    if (regexp.test(key)) {
      const arrFiles = values[key].map(({ file_id }) => ({ files: file_id }));
      return [...arr, ...arrFiles];
    }
    return arr;
  }, []);
};
