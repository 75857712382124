import * as types from '../constants/actionTypes';

/* eslint-disable */
export default function matching(state = {}, action) {
  switch (action.type) {
    case types.RECEIVE_OBJECT_MATCHING: {
      return { ...state, data: action.object };
    }
    case types.RECEIVE_MATCHING_OPERATION: {
      return {
        ...state,
        goToRegistry: action.goToRegistry,
        afterAction: action.afterAction,
      };
    }
    case types.CLEAR_TITLE_LIST: {
      return {};
    }
    case types.RESET_MATCHING:
      return {
        ...state,
        data: {},
      };
    default:
      return state;
  }
}
