import { ImprovementObjectCategoryIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/ImprovementObjectCategoryId.RF';
import React from 'react';

import { useSetupImprovementObjectCategoryIdFieldOzn } from './useSetup.ImprovementObjectCategoryId.Field.Ozn';

/**
 *
 * Категория озеленения. Ozn.
 *
 * @returns JSX.Element.
 */
export const ImprovementObjectCategoryIdFieldOzn = () => {
  const { filter, editMode } = useSetupImprovementObjectCategoryIdFieldOzn();

  return <ImprovementObjectCategoryIdRF filter={filter} disabled={!editMode} />;
};
