import {
  DataItem,
  ElementListItem,
  ElementTree,
  IGroupTree,
  ISubTypeTree,
  SubTypeElementList,
} from '../Tree';

/**
 * Подготовка данных для дерева.
 *
 * @param {Array<DataItem>} arr - Данные из запроса.
 * @returns Массив элементов групп дерева объектов.
 */
export const prepareData = (
  arr: DataItem[],
): Omit<IGroupTree, 'setLoadingRender'>[] => {
  return arr.map(
    ({
      element_list,
      group_id,
      recalc_path_object_id,
      type_code,
      type_id,
      type_name,
    }) => {
      return {
        elements: getElement(element_list),
        id: group_id,
        pathObjectId: recalc_path_object_id,
        subTypes: getSubTypes(element_list),
        title: type_name,
        typeCode: type_code,
        typeId: type_id,
      };
    },
  );
};

/**
 * Фильтруем и собираем подгруппы.
 *
 * @param arr - Массив групп или элементов.
 * @returns {Array<IGroupTree>}
 */
const getSubTypes = (
  arr: SubTypeElementList[] | ElementListItem[],
): ISubTypeTree[] => {
  return (
    arr
      .filter((item): item is SubTypeElementList => 'subgroup_code' in item)
      ?.map(({ subgroup_name, elements, subgroup_code }) => {
        return {
          elements: getElement(elements),
          id: subgroup_code,
          subTypeName: subgroup_name,
        };
      }) || []
  );
};

/**
 * Фильтруем и собираем элементы.
 *
 * @param arr - Массив групп или элементов.
 * @returns {Array<ElementTree>}
 */
const getElement = (
  arr: SubTypeElementList[] | ElementListItem[],
): ElementTree[] => {
  return arr
    .filter((item): item is ElementListItem => !('subgroup_code' in item))
    ?.map(({ id, object_type_name, root_id, path_object_id, no_calc }) => {
      return {
        id,
        noCalc: no_calc,
        pathObjectId: path_object_id,
        rootId: root_id,
        title: object_type_name,
      };
    });
};
