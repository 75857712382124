/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import { AutocompleteAsync } from 'core/uiKit/inputs/selects/AutocompleteAsync';

import { fetchOrganization } from './api';

 
export const ORGANIZATION_NAME = 'organization';

 
 
/**
 * ## Организация
 * ###### type = `AutocompleteAsync`
 * ###### name = `Organization`
 *
 * ```js
 * import { OwnerId, OWNER_ID_NAME } from 'core/uiKit/preparedInputs';
 * ```
 *
 */
 
export const Organization = (props) => {
  return (
    <AutocompleteAsync
      {...props}
      fetchFunction={fetchOrganization}
      label={'Организация'}
      name={ORGANIZATION_NAME}
    />
  );
};

Organization.propTypes = {
   
   
/**
   * очищаем инпут с сохранением значения
   */
  clear: PropTypes.bool,
   
   
/**
   * быбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
/**
   * функция вызова когда выберется итем
   */
  onChange: PropTypes.func,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
   
   
/**
   * значение
   */
  value: PropTypes.any,
};
