import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { getNameFile } from 'app/pages/cardsOgh/components/tables/Documents/helpers/getNameFile';
import React from 'react';
import { Field } from 'redux-form';

import { DocumentWithReduxForm } from './DocumentWithReduxForm';

const ID = 18;
const NAME = getNameFile(ID);
const TITLE = 'План-схема объекта М 1:500';

/**
 * Таблица документов __План-схема объекта М 1:500__.
 *
 * @returns {JSX.Element} - JSX.
 * @example
 * <ObjectDiagramRF />
 */
export const ObjectDiagramRF = () => {
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();

  return (
    <Field
      title={TITLE}
      id={ID}
      name={NAME}
      disabled={!isEditCurrentObjectIsExternalSystem}
      component={DocumentWithReduxForm}
    />
  );
};
