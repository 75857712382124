import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { checkObjectParent } from './checkObjectParent';

/**
 * UseCheckObjectParent.
 *
 * @category useDispatch
 * @param {number} id - ID объекта.
 * @param {string} date - Дата в формате строки 'DD.MM.YYYY'.
 * @returns {function(*=, *=): function(): Promise<*>}
 */
export const useCheckObjectParent = (id, date) => {
  const dispatch = useDispatch();
  const idLocal = id;
  const dateLocal = date;

  return useCallback(
    (id = idLocal, date = dateLocal) => dispatch(checkObjectParent(id, date)),
    // eslint-disable-next-line
    [dispatch, id, date],
  );
};
