
/* eslint-disable */
export default function (result, state, props) {
  const { card } = props;
  return {
    ...result,

    initialValues: {
      ...result.initialValues,
      car_equipment_ownership_id: card.car_equipment_ownership_id,
      car_equipment_type_id: card.car_equipment_type_id,
      inventory_number: card.inventory_number,
      not_need_drno: props.card.not_need_drno,
      not_need_dut: props.card.not_need_dut,
      car_equipment_owner_id: card.car_equipment_owner_id,
      capacity: card.capacity,
    },
  };
}
