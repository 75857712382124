import { CategorySpId } from 'core/uiKit/preparedInputs/selects/simpleSelect/CategorySpId';
import React from 'react';

/**
 * Обертка поля Категория по СП 42.13330.2016 для redux form.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.input - Параметры Redux form.
 * @returns {JSX.Element}
 */
export const CategorySpIdWithReduxForm = ({ input, ...otherProps }) => {
  return <CategorySpId {...input} {...otherProps} />;
};
