import { pathKnowledgeBase } from 'app/pages/knowledgeBase/KnowledgeBase/path.KnowledgeBase';
import React from 'react';

import NavItem from '../../NavItem';
import { useSetupKnowledgeBaseLink } from './useSetup.KnowledgeBase.Link';

/**
 * Link Методические материалы.
 *
 * @returns {JSX.Element}
 */
export const KnowledgeBaseLink = () => {
  const { knowledgeBaseDict } = useSetupKnowledgeBaseLink();

  return (
    <NavItem
      title={pathKnowledgeBase.name}
      type={'dropdown'}
      id={'knowledgeBase'}
    >
      {knowledgeBaseDict?.map((item) => (
        <NavItem key={item.id} link={`/${pathKnowledgeBase.path}/${item.code}`}>
          {item.name}
        </NavItem>
      ))}
    </NavItem>
  );
};
