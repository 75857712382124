/* eslint-disable  */
import { createSelector } from 'reselect';

/**
 *
 * @param state
 */
export const getTree = (state) => state.tree;

export const getObject = createSelector(getTree, (tree) => tree.object);
