import {
  QueryClient,
  QueryClientProvider as QueryClientProviderRQ,
} from '@tanstack/react-query';

export const queryClient = new QueryClient();

/**
 * QueryClientProvider.
 *
 * @param {*} props - Properties.
 * @returns {*}
 */
export const QueryClientProvider = (props) => {
  return (
    <QueryClientProviderRQ client={queryClient}>
      {props.children}
    </QueryClientProviderRQ>
  );
};
