import { COATING_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingTypeId';
import { usePreparation } from 'core/utils/hooks/usePreparation/preparation';

/**
 * Хук перевода значений при сохранении для __Вид покрытия (уточнение)__.
 *
 * @returns {void}
 */
export const usePreparationCoatingTypeIdFF = () => {
  usePreparation(COATING_TYPE_ID_NAME, (value) => {
    if (value) {
      return { coating_type: value };
    }
  });
};
