/* eslint-disable */
import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import { WithOldValueCheckbox } from 'app/components/common/CheckboxField';
import KeyboardDatePickerRF from 'core/form/reduxForm/fields/default/datePickers/KeyboardDatePickerRF';

import { WithOldValueSelect } from 'app/components/common/SelectField';

import printTechnikCardHeader from './header/technikHeader';

import { Tabs } from 'app/components/card/common/tabs';
import { transformValues } from 'app/utils/form/transformation';
import CardAbstract from 'app/components/card/common/CardAbstract';
import {
  elementFormGrid,
  renderCalibrationTable,
  renderCustomTextField,
  renderFileList2,
} from 'app/components/card/common/grid/index';
import {
  getParseAttribute,
  getParseOldAttribute,
  parseDate,
} from 'app/components/card/common/parse';
import { validateRequiredFieldsAsync } from 'app/components/card/common/validation';
import submitCardTech from './submit';

class Card extends React.Component {
  render() {
    const { props } = this;
    const { card, mode, currentValues } = this.props;
    return (
      <CardAbstract>
        <div id="editor-card">
          {printTechnikCardHeader({
            card,
            mode,
          })}
          {card && (
            <div className="tabs" style={{ paddingLeft: '24px' }}>
              <ul>
                <li className="active" id="props-tab">
                  Свойства
                </li>
              </ul>
            </div>
          )}
          <div id="props">
            {elementFormGrid(
              [
                {
                  id: 'sensor_type_id',
                  name: 'sensor_type_id',
                  editable:
                    (mode &&
                      mode.editMode &&
                      _.get(currentValues, 'sensor_kind_id')) ||
                    false,
                  formValue: true,
                  component: WithOldValueSelect,
                  addition: {
                    label: 'Тип датчика',
                    options:
                      this.props.sensorTypes &&
                      this.props.sensorTypes.filter(
                        (t) =>
                          t.sensor_kind ===
                          _.get(currentValues, 'sensor_kind_id'),
                      ),
                    oldValue: this.props.old_sensor_type_id,
                  },
                },
                {
                  id: 'sensor_kind_id',
                  name: 'sensor_kind_id',
                  editable: (mode && mode.editMode) || false,
                  formValue: true,
                  component: WithOldValueSelect,
                  addition: {
                    label: 'Вид датчика',
                    options: this.props.sensorKinds,
                    oldValue: this.props.old_sensor_kind_id,
                  },
                },
                {
                  id: 'sensor_input',
                  name: 'sensor_input',
                  label: 'Вход',
                  editable: (mode && mode.editMode) || false,
                  formValue: true,
                  component: renderCustomTextField,
                  addition: {
                    type: 'text',
                    oldValue: this.props.old_sensor_input,
                  },
                },
                {
                  id: 'sensor_code',
                  name: 'sensor_code',
                  label: 'Код / № датчика',
                  editable: (mode && mode.editMode) || false,
                  formValue: true,
                  component: renderCustomTextField,
                  addition: {
                    type: 'text',
                    oldValue: this.props.old_sensor_code,
                  },
                },
                {
                  id: 'sensor_kbm_state',
                  name: 'sensor_kbm_state',
                  editable: (mode && mode.editMode) || false,
                  formValue: true,
                  component: WithOldValueCheckbox,
                  addition: {
                    label: 'Состояние',
                    oldValue: this.props.old_sensor_kbm_state,
                  },
                },
                {
                  id: 'sensor_kbm_limit_value',
                  name: 'sensor_kbm_limit_value',
                  label: 'Граничные значения',
                  editable: (mode && mode.editMode) || false,
                  formValue: true,
                  component: renderCustomTextField,
                  addition: {
                    type: 'text',
                    oldValue: this.props.old_sensor_kbm_limit_value,
                  },
                },
                {
                  id: 'data_fuel_error',
                  name: 'data_fuel_error',
                  editable:
                    (mode &&
                      mode.editMode &&
                      _.get(currentValues, 'sensor_kind_id') === 2) ||
                    false,
                  formValue: true,
                  component: WithOldValueCheckbox,
                  addition: {
                    label: 'Данные о топливе некорректны',
                    action: (value) => {
                      props.changeFieldValue('data_fuel_error', value);
                      props.changeFieldValue('defect_date', null);
                    },
                    oldValue: this.props.old_data_fuel_error,
                  },
                },
                {
                  id: 'defect_date',
                  name: 'defect_date',
                  editable:
                    (mode &&
                      mode.editMode &&
                      _.get(currentValues, 'sensor_kind_id') === 2 &&
                      _.get(currentValues, 'data_fuel_error')) ||
                    false,
                  formValue: true,
                  component: KeyboardDatePickerRF,
                  addition: {
                    label: 'Дата обнаружения неисправности',
                    oldValue: this.props.old_defect_date,
                  },
                },
              ],
              2,
              '',
            )}

            <div>
              <Tabs
                hidden={!(currentValues && currentValues.sensor_kind_id === 2)}
                tabs={[
                  { id: 'calibration-tab', name: 'Тарировочная таблица' },
                  { id: 'docs-tab', name: 'Документы' },
                ]}
              />
              <div id="calibration">
                {currentValues &&
                  currentValues.sensor_kind_id === 2 &&
                  elementFormGrid(
                    [
                      {
                        id: 'sensor_calibration_table',
                        name: 'sensor_calibration_table',
                        label: '',
                        editable: (mode && mode.editMode) || false,
                        style: { overflow: 'auto' },
                        formValue:
                          currentValues && currentValues.sensor_kind_id === 2,
                        component: renderCalibrationTable,
                        addition: {
                          oldValue: this.props.old_sensor_calibration_table,
                        },
                      },
                    ],
                    1,
                    '',
                  )}
              </div>
              <div hidden={true} id="docs">
                {currentValues &&
                  currentValues.sensor_kind_id === 2 &&
                  elementFormGrid(
                    [
                      {
                        id: 'file_list',
                        name: 'file_list',
                        label: '',
                        editable: (mode && mode.editMode) || false,
                        style: { overflow: 'auto' },
                        formValue:
                          currentValues && currentValues.sensor_kind_id === 2,
                        component: renderFileList2,
                        addition: {
                          oldValue: this.props.old_file_list,
                        },
                      },
                    ],
                    1,
                    '',
                  )}
              </div>
            </div>
          </div>
        </div>
      </CardAbstract>
    );
  }
}

const mapStateToProps = (state, props) => {
  function toArray(object) {
    return object && Object.keys(object).map((item) => object[item]);
  }
  const parse = getParseAttribute(props);
  const parseOld = getParseOldAttribute(props);
  return {
    initialValues: {
      startDate: props.card.start_date,
      endDate: props.card.end_date,
      sensor_kind_id: parse('sensor_kind'),
      sensor_type_id: parse('sensor_type'),
      data_fuel_error: props.card.data_fuel_error,
      defect_date: props.card.defect_date,
      sensor_kbm_limit_value: props.card.sensor_kbm_limit_value,
      sensor_input: props.card.sensor_input,
      sensor_kbm_state: props.card.sensor_kbm_state,
      sensor_code: props.card.sensor_code,
      file_list: {
        table: props.card.file_list ? props.card.file_list : null,
        showDialog: false,
      },
      sensor_calibration_table: {
        table: props.card.sensor_calibration_table
          ? props.card.sensor_calibration_table
          : null,
        showDialog: false,
      },
    },
    old_sensor_kbm_limit_value: props.card.old_sensor_kbm_limit_value,
    old_sensor_input: props.card.old_sensor_input,
    old_sensor_kbm_state: props.card.old_sensor_kbm_state,
    old_sensor_code: props.card.old_sensor_code,
    sensor_kind_id: parseOld('sensor_kind'),
    sensor_type_id: parseOld('sensor_type'),
    old_data_fuel_error: props.card.old_data_fuel_error,
    old_defect_date: props.card.old_defect_date,
    old_file_list: {
      table: props.card.old_file_list ? props.card.old_file_list : null,
      showDialog: false,
    },
    old_sensor_calibration_table: {
      table: props.card.old_sensor_calibration_table
        ? props.card.old_sensor_calibration_table
        : null,
    },
    enableReinitialize: true,
    sensorKinds: toArray(_.get(state.odsDicts, 'sensorKinds')),
    sensorTypes: toArray(_.get(state.odsDicts, 'sensorTypes')),
    technic_id: props.card.id,
    technic_root_id: props.card.root_id,
    technic_update: props.card.update_date,
    currentValues:
      (state.form.editorCard && state.form.editorCard.values) || null,

    onSubmit,
  };
};

const mandatoryFields = ['sensor_code', 'sensor_kind_id', 'sensor_type_id'];
const dictFields = ['sensor_kind_id', 'sensor_type_id'];
const numericFields = [];

const onSubmit = (props, techTreePaths) => (formValues) => {
  const technic_id = props.card.id;
  const technic_root_id = props.card.root_id;
  const technic_update = props.card.update_date;

  let formValuesCopy = { ...formValues };

  validateRequiredFieldsAsync(formValuesCopy, mandatoryFields);

  if (
    formValuesCopy['sensor_kind_id'] === 2 &&
    formValuesCopy['file_list'] &&
    formValuesCopy['file_list'].table &&
    formValuesCopy['file_list'].table.length > 0
  ) {
    const fileList = formValuesCopy['file_list'].table.map((item) => ({
      files: item.file_id,
    }));
    formValuesCopy['file_list'] = fileList;
  } else {
    formValuesCopy['file_list'] = [];
  }

  const isDate = (key) => {
    return _.includes(key.toLowerCase(), 'date');
  };

  Object.keys(formValuesCopy).forEach((key) => {
    if (isDate(key)) {
      formValuesCopy[key] = parseDate(formValuesCopy[key]);
    }
  });

  const startDate = moment().add(1, 'm').format('YYYY-MM-DDTHH:mm:ssZ');
  const technic_attribute = transformValues(formValuesCopy, {
    dictFields,
    numericFields,
  }).attribute;

  const sensor = {
    technic_type_id: 3,
    planDate: startDate,
    attribute: {
      technic_request_reason_id: { technic_request_reason: 10 },
      description: 'Тест',
      technic_name: formValuesCopy.sensor_code,
      file_list_id: [],
    },
    tech: [
      {
        technic_type_id: 3,
        technic_name: formValuesCopy.sensor_code,
        technic_attribute,
        technic_tree: techTreePaths,
        technic_id: technic_id || null,
        technic_root_id: technic_root_id || null,
        technic_update: technic_update || null,
      },
    ],
  };
  submitCardTech(props, sensor, requestIdsMapping);
};

const requestIdsMapping = {
  create: 11,
  delete: 12,
  edit: 13,
};

 
export default connect(mapStateToProps)(
  reduxForm({ form: 'editorCard' })(Card),
);
