import { DownLoadFilesListCell } from 'core/uiKit/components/cells/DownLoadFilesList.Cell/DownLoadFilesList.Cell';

export const columnsActOfCompletedWorkElectronicSignature = [
  {
    Cell: DownLoadFilesListCell,
    Header: 'Наименование',
    accessor: 'file_name',
  },
  {
    Header: 'Статус',
    accessor: 'status',
  },
  {
    Header: 'ФИО подписанта',
    accessor: 'fio',
  },
  {
    Header: 'Дата подписания',
    accessor: 'sign_date',
  },
  {
    Header: 'Комментарий',
    accessor: 'comment',
  },
  {
    Header: 'Дата присоединения',
    accessor: 'create_date',
  },
];
