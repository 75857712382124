/* eslint-disable */
import React from 'react';

import { filterInput } from '../filterInputsClasses';
import { CadNumberFF, CAD_NUMBER_NAME } from 'core/form/finalForm/fields';
import { StringParam } from 'use-query-params';

 
 
/**
 *
 * field: fieldName.cadNumber,
 * label: 'Кадастровый номер ООП',
 *
 * @returns {JSX.Element}
 * @constructor
 */
 
export const CadNumberFilter = () => <CadNumberFF className={filterInput} />;

 
export const cadNumberParams = {
  [CAD_NUMBER_NAME]: StringParam,
};
