/**
 * Вытаскивает контент ссылки.
 *
 * @param {string} str - Строка.
 * @returns {string}
 */
export const getIdFromLink = (str = '') => {
  if (/<\/a>/.test(str)) {
    const text = str.match(/>(\d+)</)[0];
    return text.slice(1, -1);
  }
  return str;
};
