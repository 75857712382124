import { createAttribute as createDocumentsAttribute } from 'app/components/card/ogh/DocumentsTab';
import { CardOgh } from 'types/Card';

import { createAttribute as createAddressListFieldAttribute } from '../components/AddressListField';
import { createAttribute as createAquaAreaAttribute } from '../components/AquaAreaTab';
import { createAttribute as improvementCategoriesCreateAttribute } from '../components/ImprovementCategories';
import { createAttribute as createOwnerAndCustomerFieldsAttribute } from '../components/OwnerAndCustomerFields';
import { REPAIRS_INFO_LIST_NAME } from '../components/tabs/Repairs.Tab/Table.Repairs.Tab';
import { REPAIRS_INFO_LIST_PLAN_NAME } from '../components/tabs/RepairsPlan.Tab';
import { createAttribute as createTypeOtherTerritoryAttribute } from '../components/TypeOtherTerritory';

/**
 * Функция создания атрибутов. Иные объекты благоустройства.
 *
 * @param formValues - Значения из формы.
 * @returns Возвращают атрибуты.
 */
export const createAttributeImprovementObject = (formValues: CardOgh) => {
  return {
    ...createAddressListFieldAttribute(formValues),
    ...createAquaAreaAttribute(formValues),
    ...createDocumentsAttribute(formValues),
    ...createOwnerAndCustomerFieldsAttribute(formValues),
    ...createTypeOtherTerritoryAttribute(formValues),
    ...improvementCategoriesCreateAttribute(formValues),
    [REPAIRS_INFO_LIST_NAME]: formValues.repairs_info_list,
    [REPAIRS_INFO_LIST_PLAN_NAME]: formValues[REPAIRS_INFO_LIST_PLAN_NAME],
    re_calc_address_list: null,
    re_calc_name: null,
  };
};
