import { print } from 'app/api/requests/print';
/* eslint-disable  */
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

/**
 *
 * @param params
 */
const printMafEAIS = async (params) => print('maf_eais', params);

/* eslint-disable */
export const usePrintMafEais = () => {
  const [printMafEais, { isLoading }] = useMutationAdaptor(printMafEAIS);

  return { printMafEais, isLoading };
};
