import { shallow } from 'enzyme';

import { FunctionalityCard } from './FunctionalityCard';

const props = {
  card: {},
  createMode: true,
  mode: {},
};

describe('🐛 FunctionalityCardSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act

    const wrapper = shallow(<FunctionalityCard {...props} />);
    // ❓ Assert
    expect(wrapper.text()).toBe('<CardAbstract />');
  });
});
