import { formatResponseToGeoJSON } from 'app/actions/geometryActions/fetchCheckGeometryIntersections.DTW';
import { useGeometryMapCurrent } from 'app/pages/cardsOgh/components/Map/MapEditPanel/hooks/useGeometryMapCurrent';
import { useGeometryMapParent } from 'app/pages/cardsOgh/components/Map/MapEditPanel/hooks/useGeometryMapParent';
import { useMapglContext } from 'core/uiKit/components/DTW/contexts/MapglContextProvider';
import { formatGeometryObjectToUpdate } from 'core/uiKit/components/DTW/contexts/utils';
import { useGetParamsUrl } from 'core/utils/hooks/useGetParamsUrl';
import { useEffect, useMemo } from 'react';

/**
 * Хук инициализации карты DTW.
 *
 * @description - Вызывается при изменение url родительского и дочернего id.
 * @returns {void}
 */
export const useActionsMapDTW = () => {
  const {
    drawGeometries,
    updateLoadedGeometries,
    zoomToGeometries,
    mapService,
    ...restMapgl
  } = useMapglContext();

  const { actions, childId } = useGetParamsUrl();

  useGeometryMapParent();

  const {
    dataMapCurrent = [],
    currentSuccess,
    isLoading,
  } = useGeometryMapCurrent();

  const isParent = !childId;

  /**
   * Format geometry to draw.
   *
   * @param {object} geometry - Geometry.
   * @returns {void}
   */
  const formatGeometryToDraw = (geometry) => {
    if (!mapService?.isMapLoaded) return;

    const layerType = isParent ? 'parent' : 'children';
    const data = {
      [layerType]: formatGeometryObjectToUpdate(
        geometry,
        layerType,
        mapService,
      ),
    };

    if (!actions.haveParent && geometry.child_object) {
      data.children = geometry.child_object?.map(formatGeometriesToDraw) || [];
    }

    return data;
  };

  /**
   * Format geometries to draw.
   *
   * @param {object} geometries - Geometries.
   * @returns {void}
   */
  const formatGeometriesToDraw = (geometries) => {
    return Array.isArray(geometries)
      ? geometries.map((geometry) => formatGeometryToDraw(geometry)).flat(1)
      : formatGeometryToDraw(geometries);
  };

  useEffect(() => {
    if (!isLoading && currentSuccess && mapService?.isMapLoaded) {
      const [data] = formatGeometriesToDraw(
        formatResponseToGeoJSON(dataMapCurrent),
      );

      const currentLoadedGeometries = { ...mapService.geometriesData };
      const { children } = currentLoadedGeometries;

      if (!data) {
        const instances = mapService.drawGeometries({
          method: 'replaceAll',
          newGeometries: {
            ...currentLoadedGeometries,
            children: [],
            parent: currentLoadedGeometries.parent,
          },
        });

        if (!instances.parent) return;

        zoomToGeometries(instances.parent);
        return;
      }

      mapService.updateGeometriesData({
        ...data,
        children: isParent
          ? []
          : mapService.isEditMode
          ? children
          : data.children,
      });

      const instances = mapService.drawGeometries({
        method: 'replaceAll',
      });

      if (!instances.parent) return;

      zoomToGeometries(instances.parent);
    }
    // eslint-disable-next-line
  }, [
    mapService,
    mapService?.isMapLoaded,
    currentSuccess,
    dataMapCurrent,
    isLoading,
    childId,
  ]);

  return useMemo(() => {
    return {
      formatGeometriesToDraw,
      formatGeometryToDraw,
      geometries: currentSuccess
        ? formatGeometriesToDraw(formatResponseToGeoJSON(dataMapCurrent))
        : {},
      initialMapData: dataMapCurrent,
      mapApi: {
        drawGeometries,
        mapService,
        updateLoadedGeometries,
        zoomToGeometries,
        ...restMapgl,
      },
    };
    // eslint-disable-next-line
  }, [dataMapCurrent]);
};
