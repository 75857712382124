/**
 * Функция, которая «откладывает» вызов другой функции до того момента, когда с последнего вызова пройдёт определённое количество времени.
 *
 * @param {Function} func - Функция.
 * @param {number} delay - Время задержки.
 * @returns {Function} Сама функция которая бует откладыватся по времени выполнения.
 */
export function debounce(func, delay) {
  let timeoutId;

  return function (...args) {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
}
