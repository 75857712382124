import { get } from 'app/api/crud/get';

/**
 * Fetch owners.
 *
 * @param {object} data - Data.
 * @returns {Promise}
 */
export const fetchOwners = async (data) => {
  const response = await get('/autocomplete/owners', data);
  return response.map((item) => ({ id: item.id, name: item.shortname }));
};
