/* eslint-disable */
import React from 'react';
import { StringParam } from 'use-query-params';

import { TextFieldFF } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
export const GPS_MODEL_NAME_NAME = 'gpsModelName';

 
 
/**
 * Марка и модель оборудования     gps_model_name  text
 */
 
export const GpsModelNameFilter = () => {
  return (
    <TextFieldFF
      className={filterInput}
      label={'Марка и модель оборудования'}
      name={GPS_MODEL_NAME_NAME}
    />
  );
};

 
export const gpsModelNameParams = {
  [GPS_MODEL_NAME_NAME]: StringParam,
};
