/* eslint-disable */
import React from 'react';

import { Body, Header, Main, RegistryToolbar } from 'app/pages/components';
import { useGetOghTypes } from 'core/utils/hooks/useGetOghTypes';

import { FilterMafRfid } from './Filter.MafRfid';
import { TableMafRfid } from './Table.MafRfid';
import { PrintButtonMafRfid } from 'app/pages/registry/ogh/MafRfid/buttons/PrintButton.MafRfid';
import { DownloadUaisbu } from 'app/pages/registry/ogh/MafRfid/buttons/DownloadUAISBU';

const MafRfid = () => {
  const oghType = useGetOghTypes('maf_rfid');
  const typeId = oghType?.id;

  return typeId ? (
    <Main>
      <Header Filter={() => <FilterMafRfid typeId={typeId} />} />
      <Body>
        <RegistryToolbar title={oghType.name}>
          <DownloadUaisbu />
          <PrintButtonMafRfid {...{ typeId }} />
        </RegistryToolbar>
        <TableMafRfid typeId={typeId} />
      </Body>
    </Main>
  ) : null;
};

 
export default MafRfid;
