import { shallow } from 'enzyme';

import { Characteristics } from './Characteristics';

describe('🐛 CharacteristicsSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = {
      card: {
        coating_group: 1,
      },
    };
    // 🔥 Act

    const wrapper = shallow(<Characteristics {...props} />);
    // ❓ Assert
    expect(wrapper.text()).toBe('<FieldsSplitColumns />');
  });
  it('🧪 CoatingTypeIdRF', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = {
      card: {
        coating_group: 1,
      },
      editMode: true,
      isEditCurrentObjectIsExternalSystem: true,
    };
    const dict = [
      { code: 'paving_stones', id: 1 },
      { code: 'foo', id: 2 },
    ];
    // 🔥 Act

    const wrapper = shallow(<Characteristics {...props} />);
    const propsCoatingTypeIdRF = wrapper.find('CoatingTypeIdRF').props();
    const { filter } = propsCoatingTypeIdRF;
    const filterExpected = filter(dict);

    // ❓ Assert
    expect(propsCoatingTypeIdRF).toStrictEqual({
      disabled: false,
      filter: expect.any(Function),
      label: 'Вид покрытия (уточнение)',
      required: true,
      withAll: false,
    });
    expect(filterExpected).toStrictEqual([{ code: 'paving_stones', id: 1 }]);
  });
});
