import React, { memo } from 'react';

import { useMapglContext } from '../../contexts/MapglContextProvider';
import { HintOnClick } from '../HintOnClick';
import { HintOnHover } from '../HintOnHover';

export default memo(
  function MapWrapper({ id }: { id: string }) {
    const { onHoverHintElementRef, onClickHintElementRef } = useMapglContext();
    return (
      <div style={{ height: '100%', position: 'relative', width: '100%' }}>
        <div id={id} style={{ height: '100%', width: '100%' }} />
        <HintOnClick ref={onClickHintElementRef} />
        <HintOnHover ref={onHoverHintElementRef} />
      </div>
    );
  },
  (prev, curr) => prev.id === curr.id,
);
