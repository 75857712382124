import getDict from 'app/selectors/getDict';
import { createSelector } from 'reselect';

 
export default createSelector([getDict('oghFileTypes')], (fileTypes) => {
  const inventoryPlan = fileTypes.find(
    (item) => item.code === 'inventory_plan',
  );
  return inventoryPlan && inventoryPlan.id;
});
