import { Cell } from 'types/table';

/**
 * Колонка __Площадь объекта по пространственным координатам, кв.м__.
 *
 * @type {{
 * accessor: 'total_area_geo',
 * Header: 'Площадь объекта по пространственным координатам, кв.м',
 * sortable: true,
 * }}
 * @augments Cell
 */
export const total_area_geo: Cell = {
  Header: 'Площадь объекта по пространственным координатам, кв.м',
  accessor: 'total_area_geo',
  sortable: true,
};
