/* eslint-disable  */
/**
 *
 * @param state
 */
export const getPropsDiff = (state) => state.card.propsdiff || {};

/**
 *
 * @param state
 */
export const getTreediff = (state) => state.card.treediff || {};

/**
 *
 * @param state
 */
export const getGeodiff = (state) => state.card.geodiff || {};

/**
 *
 * @param state
 */
export const getPropsDiffPayload = (state) => getPropsDiff(state).payload || [];

/**
 *
 * @param state
 */
export const getTreediffPayload = (state) => getTreediff(state).payload || [];

/**
 *
 * @param state
 */
export const getGeodiffPayload = (state) => getGeodiff(state).payload || [];
