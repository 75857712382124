import React from 'react';

type changeIdsType = {
  selectRowsTableDelete: number[];
  setIds: React.Dispatch<React.SetStateAction<number[]>>;
  ids: number[];
};

/**
 * Fn.
 *
 * @param root0 - Root.
 * @param root0.ids - Ids.
 * @param root0.selectRowsTableDelete - Select rows.
 * @param root0.setIds - Set.
 * @returns {*}
 */
export const changeIds =
  ({ ids, selectRowsTableDelete, setIds }: changeIdsType) =>
  () => {
    setIds(ids.filter((id) => !selectRowsTableDelete.includes(id)));
  };
