import React from 'react';

import { AccordionPlantationTab } from '../Accordion.PlantationTab';
import { TableVerticalLandscapingList } from './Table.VerticalLandscapingList';

/**
 * Ведомость учета зеленых насаждений (элементы вертикального озеленения).
 *
 * @param {object} props - Пропсы.
 * @param {*} props.expanded - Расширенный.
 * @param {Function} props.onChange - Функция изменения.
 * @returns {JSX.Element}
 */
export const VerticalLandscapingList = ({ expanded, onChange }) => {
  return (
    <AccordionPlantationTab
      code={'vertical_landscaping_list'}
      title={
        'Ведомость учета зеленых насаждений (элементы вертикального озеленения)'
      }
      expanded={expanded}
      onChange={onChange}
      Table={TableVerticalLandscapingList}
    />
  );
};
