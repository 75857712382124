import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import isMandatory from 'app/utils/card/isMandatory';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import React from 'react';

export const ODH_SIDE_ID_NAME = 'odh_side_id';

/**
 * Select Field RF __Сторона__.
 *
 * @returns
 */
export const OdhSideIdField = () => {
  const { typeId, odh_side = [] } = useGetCurrentCard();
  const { editMode } = useMode();
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();
  return (
    <SelectRF
      name={ODH_SIDE_ID_NAME}
      options={odh_side}
      label={'Сторона'}
      required={isMandatory(typeId, ODH_SIDE_ID_NAME, true)}
      disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
    />
  );
};
