import {
  DTS,
  FUNCTIONALITY,
  PLANAR_STRUCTURE,
  PPI,
  RELIEF,
  STORAGE_PLACE,
  TECH_PLACE,
  WATER_BUILDING,
} from 'app/constants/oghTypes';
import ids from 'app/utils/getOghRegistryColumns/ids';
import { LinkShortIdCell } from 'core/uiKit/components/cells/LinkShortId.Cell';

const objectTypeName = {
  Header: 'Тип',
  accessor: 'object_type_name',
};

const addressName = {
  Header: 'Адрес',
  accessor: ids.address,
  minWidth: 250,
};

const defaultColumns = [
  {
    Header: 'Статус',
    accessor: 'object_status_name',
    minWidth: 110,
  },
  {
    Header: 'Дата начала',
    accessor: 'start_date',
    minWidth: 90,
  },
  {
    Header: 'Дата окончания',
    accessor: 'end_date',
    minWidth: 120,
  },
];

/**
 * Функция получения дефолтной колонки.
 *
 * @param {number} typeId - Тип ОГХ.
 * @returns {object}
 */
export const getDefaultColumns = (typeId) => {
  const columns = [
    {
      Cell: LinkShortIdCell,
      Header: 'ID',
      accessor: 'root_id',
      minWidth: 120,
    },
  ];

  if (
    [
      STORAGE_PLACE,
      WATER_BUILDING,
      DTS,
      PLANAR_STRUCTURE,
      TECH_PLACE,
      FUNCTIONALITY,
      PPI,
      RELIEF,
    ].includes(typeId)
  ) {
    columns.push(objectTypeName);
  }

  if ([STORAGE_PLACE, TECH_PLACE].includes(typeId)) {
    columns.push(addressName);
  }

  return [...columns, ...defaultColumns];
};
