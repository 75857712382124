/* eslint-disable  */
import * as oghTypes from 'app/constants/oghTypes';
import { forEach, pick } from 'lodash';

import basePgmColumns from './basePgmColumns';
import binColumns from './binColumns';
import buildingColumns from './buildingColumns';
import containerColumns from './containerColumns';
import emergencyParkingColumns from './emergencyParkingColumns';
import flowersGardenColumns from './flowersGardenColumns';
import forAll from './forAll';
import forOdhChildren from './forOdhChildren';
import ids from './ids';
import improvementObjectColumns from './improvementObjectColumns';
import improvementTerritoryColumns from './improvementTerritoryColumns';
import lawnColumns from './lawnColumns';
import odhColumns from './odhColumns';
import ooptColumns from './ooptColumns';
import otherOksColumns from './otherOksColumns';
import oznColumns from './oznColumns';
import treesAndShrubsColumns from './treesAndShrubsColumns';

const defType = {
  id: 'object_type_name',
  name: 'Тип',
};

const buildingsTypeSpec = {
  id: 'buildings_type_spec_name',
  name: 'Уточнение типа',
};

/**
 *
 * @param columns
 */
const setDefType = (columns) => columns.splice(1, 0, defType);

/**
 *
 * @param columns
 */
const setBuildingsTypeSpec = (columns) =>
  columns.splice(2, 0, buildingsTypeSpec);

/**
 *
 * @param oghColumns
 * @param types
 */
const removeAddressName = (oghColumns, types) => {
  forEach(pick(oghColumns, types), (columns) => {
    const addressNameIndex = columns.findIndex(({ id }) => id === ids.address);
    columns.splice(addressNameIndex, 1);
  });
};

/**
 *
 */
const getOghRegistryColumns = () => {
  const oghColumns = {
    _forAll: [...forAll],
    [oghTypes.ODH]: [...odhColumns],
    [oghTypes.STOPS]: [...forOdhChildren],
    [oghTypes.LAMPPOSTS]: [...forOdhChildren],
    [oghTypes.MAF]: [...forOdhChildren],
    [oghTypes.MANHOLES]: [...forOdhChildren],
    [oghTypes.OTHER_ENGIN_CONSTRUCT]: [...forOdhChildren],
    [oghTypes.ROAD_SIGNS]: [...forOdhChildren],
    [oghTypes.FENCING]: [...forOdhChildren],
    [oghTypes.TRAFFIC_LIGHT]: [...forOdhChildren],
    [oghTypes.CARRIAGEWAY]: [...forOdhChildren],
    [oghTypes.SIDEWALKS]: [...forOdhChildren],
    [oghTypes.BOARD_STONE]: [...forOdhChildren],
    [oghTypes.OTHER_FLAT_BUILDING]: [...forOdhChildren],
    [oghTypes.TRAM_RAILS]: [...forOdhChildren],
    [oghTypes.TROLLEYBUS_CONTACT_NETWORK]: [...forOdhChildren],
    [oghTypes.MARGIN]: [...forOdhChildren],
    [oghTypes.EMERGENCY_PARKING]: [...emergencyParkingColumns],
    [oghTypes.BUILDING]: [...buildingColumns],
    [oghTypes.CONTAINER]: [...containerColumns],
    [oghTypes.PLANAR_STRUCTURE]: [...forAll],
    [oghTypes.TECH_PLACE]: [...forAll],
    [oghTypes.STORAGE_PLACE]: [...forAll],
    [oghTypes.DTS]: [...forAll],
    [oghTypes.LAWN]: [...lawnColumns],
    [oghTypes.FLOWERS_GARDEN]: [...flowersGardenColumns],
    [oghTypes.PPI]: [...forAll],
    [oghTypes.RELIEF]: [...forAll],
    [oghTypes.FUNCTIONALITY]: [...forAll],
    36: [...forAll],
    [oghTypes.LITTLE_FORM]: [...forAll],
    [oghTypes.YARD]: [...improvementTerritoryColumns],
    [oghTypes.IMPROVEMENT_OBJECT]: [...improvementObjectColumns],
    [oghTypes.OZN]: [...oznColumns],
    [oghTypes.OTHER_TECH_PLACE]: [...forAll],
    [oghTypes.BIN]: [...binColumns],
    [oghTypes.TREES_SHRUBS]: [...treesAndShrubsColumns],
    [oghTypes.BASE_PGM]: [...basePgmColumns],
    [oghTypes.OOPT]: [...ooptColumns],
    [oghTypes.OTHER_OKS]: [...otherOksColumns],
  };

  forEach(
    pick(oghColumns, [
      `${oghTypes.STOPS}`,
      `${oghTypes.LAMPPOSTS}`,
      `${oghTypes.MAF}`,
    ]),
    setDefType,
  );

  forEach(
    pick(oghColumns, [
      `${oghTypes.MANHOLES}`,
      `${oghTypes.OTHER_ENGIN_CONSTRUCT}`,
    ]),
    setDefType,
  );

  forEach(
    pick(oghColumns, [
      `${oghTypes.CARRIAGEWAY}`,
      `${oghTypes.SIDEWALKS}`,
      `${oghTypes.BOARD_STONE}`,
      `${oghTypes.OTHER_FLAT_BUILDING}`,
      `${oghTypes.MARGIN}`,
    ]),
    setDefType,
  );

  forEach(
    pick(oghColumns, [
      `${oghTypes.TRAM_RAILS}`,
      `${oghTypes.TROLLEYBUS_CONTACT_NETWORK}`,
    ]),
    setDefType,
  );

  forEach(
    pick(oghColumns, [
      `${oghTypes.ROAD_SIGNS}`,
      `${oghTypes.FENCING}`,
      `${oghTypes.TRAFFIC_LIGHT}`,
    ]),
    setDefType,
  );

  forEach(pick(oghColumns, [`${oghTypes.PLANAR_STRUCTURE}`]), setDefType);

  forEach(
    pick(oghColumns, [
      `${oghTypes.TECH_PLACE}`,
      `${oghTypes.STORAGE_PLACE}`,
      `${oghTypes.OTHER_TECH_PLACE}`,
    ]),
    setDefType,
  );

  forEach(pick(oghColumns, [`${oghTypes.DTS}`]), setDefType);

  forEach(
    pick(oghColumns, [
      `${oghTypes.PPI}`,
      `${oghTypes.RELIEF}`,
      `${oghTypes.FUNCTIONALITY}`,
      '36',
    ]),
    setDefType,
  );

  forEach(pick(oghColumns, [`${oghTypes.LITTLE_FORM}`]), setDefType);

  forEach(
    pick(oghColumns, [`${oghTypes.OTHER_TECH_PLACE}`]),
    setBuildingsTypeSpec,
  );

  removeAddressName(oghColumns, [
    `${oghTypes.PLANAR_STRUCTURE}`,
    `${oghTypes.DTS}`,
    `${oghTypes.PPI}`,
    `${oghTypes.RELIEF}`,
    `${oghTypes.FUNCTIONALITY}`,
    `${oghTypes.LITTLE_FORM}`,
  ]);

  return oghColumns;
};

export default getOghRegistryColumns;
