import { columnsLandscapingTable } from 'app/components/card/ogh/ImprovementTerritoryCard/components/Landscaping.Tab/Landscaping.Table/columns.Landscaping.Table';
import { LinkLandscapingCell } from 'core/uiKit/components/cells/Link.Landscaping.Cell';

const expected = [
  {
    Header: 'Год',
    accessor: 'year',
  },
  {
    Cell: LinkLandscapingCell,
    Header: 'Ссылка на план в СОК Благоустройство',
    accessor: 'url',
  },
  {
    Header: 'Плановая дата окончания работ',
    accessor: 'end_date_plan',
  },
  {
    Header: 'Фактическая дата окончания работ',
    accessor: 'end_date_fact',
  },
];

describe('ColumnsLandscapingTableSpec', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act

    // ❓ Assert
    expect(columnsLandscapingTable).toStrictEqual(expected);
  });
});
