import validateNumeric from 'app/components/card/common/validation/validateNumeric';

/* eslint-disable */
export const numericFields = [
  { name: 'bord_begin', type: 'decimal', zero: true },
  { name: 'bord_end', type: 'decimal', zero: true },
  { name: 'area', type: 'decimal', positive: true, zero: false },
  { name: 'distance', type: 'decimal', positive: true, zero: false },
  { name: 'width_begin', type: 'decimal', positive: true, zero: false },
  { name: 'width_end', type: 'decimal', positive: true, zero: false },
  { name: 'auto_clean_area', type: 'decimal', positive: true, zero: false },
  { name: 'manual_clean_area', type: 'decimal', positive: true, zero: false },
  { name: 'no_clean_area', type: 'decimal', positive: true, zero: true },
  { name: 'utn_area', type: 'decimal', positive: true },
];

 
export const validate = (values) => validateNumeric(values, numericFields);
