import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { parseDateToISOString } from 'app/utils/date/parseDateToISOString';

/**
 * Хук получения флага disabled для даты закрытия.
 *
 * @returns {boolean}
 */
export const useGetDisabledDateCloseField = (): boolean => {
  const { startDate = '' } = useGetCurrentCard();

  return !(new Date(parseDateToISOString(startDate)) <= new Date());
};
