import { useChangeFieldValueRF } from 'app/actions/odsObjectActions/useChangeFieldValueRF';
import { MAF_TYPE_LEVEL_2_ID_NAME } from 'app/components/card/ogh/LittleForm/components/MafType/fields/MafTypeLevel2Id.Field';
import { MAF_TYPE_LEVEL_3_ID_NAME } from 'app/components/card/ogh/LittleForm/components/MafType/fields/MafTypeLevel3.Field';
import { RANDOM_NUMBER } from 'core/forTesting/constants';
import { fetchOghTypes } from 'core/uiKit/preparedInputs/api';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import {
  MAF_TYPE_LEVEL_1_ID_NAME,
  MafTypeLevel1IdField,
} from './MafTypeLevel1.Field';

vi.mock('core/utils/hooks/useReactQuery');
vi.mock('app/actions/odsObjectActions/useChangeFieldValueRF');

describe('🐛 MafTypeLevel1Spec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useQueryAdaptor.mockReturnValue({ data: [] });
    useChangeFieldValueRF.mockReturnValue(vi.fn());
    // 🔥 Act
    const wrapper = shallow(
      <MafTypeLevel1IdField parentTypeId={RANDOM_NUMBER} />,
    );

    // ❓ Assert
    expect(wrapper.text()).toStrictEqual('<SelectRF />');
  });
  it('🧪 props', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useQueryAdaptor.mockReturnValue({ data: [] });
    useChangeFieldValueRF.mockReturnValue(vi.fn());
    // 🔥 Act
    const wrapper = shallow(
      <MafTypeLevel1IdField parentTypeId={RANDOM_NUMBER} />,
    );

    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      label: 'Вид МАФ',
      name: MAF_TYPE_LEVEL_1_ID_NAME,
      options: [],
      withChange: expect.any(Function),
    });
  });
  it('🧪 useQueryAdaptor called params', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useQueryAdaptor.mockReturnValue({ data: [] });
    useChangeFieldValueRF.mockReturnValue(vi.fn());
    // 🔥 Act
    shallow(<MafTypeLevel1IdField parentTypeId={RANDOM_NUMBER} />);

    // ❓ Assert
    expect(useQueryAdaptor).toHaveBeenCalledWith(
      [
        'optionsMafTypeLevel1AutoComplete',
        { parentTypeId: RANDOM_NUMBER, typeId: 37 },
      ],
      fetchOghTypes,
      {
        enabled: true,
      },
    );
  });
  it('🧪 withChange', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const changeFieldValueRFSpy = vi.fn();
    useQueryAdaptor.mockReturnValue({ data: [] });
    useChangeFieldValueRF.mockReturnValue(changeFieldValueRFSpy);
    const wrapper = shallow(
      <MafTypeLevel1IdField parentTypeId={RANDOM_NUMBER} />,
    );
    const withChange = wrapper.prop('withChange');

    // 🔥 Act

    withChange();
    // ❓ Assert

    expect(changeFieldValueRFSpy).toHaveBeenCalledWith(
      MAF_TYPE_LEVEL_2_ID_NAME,
      '',
    );
    expect(changeFieldValueRFSpy).toHaveBeenCalledWith(
      MAF_TYPE_LEVEL_3_ID_NAME,
      '',
    );
  });
});
