import { fetchCarEquipmentOwners } from 'app/api/typeahead';
import Form from 'app/components/common/KeyForm';
import { dateFromMaxDate } from 'app/utils/dateFromMaxDate';
import { dateToMinDate } from 'app/utils/dateToMinDate';
import dateValidator from 'app/utils/dateValidator';
import cn from 'classnames';
import { AutocompleteAsyncRF } from 'core/form/reduxForm/fields/default/selects/AutocompleteAsyncRF';
import { ButtonBlue } from 'core/newComponents/Button';
import TextField from 'core/newComponents/TextField';
import KeyboardDateTimePicker from 'core/uiKit/inputs/datePickers/KeyboardDateTimePicker';
import PropTypes from 'prop-types';
import React from 'react';
import { Control } from 'react-redux-form';

import searchFormWrapper from './SearchForm';

/**
 * SearchForm.
 */
class SearchForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    form: PropTypes.object,
    formChange: PropTypes.func,
    formValidity: PropTypes.object,
    labelStyle: PropTypes.object,
    onSubmit: PropTypes.func,
    onSubmitClick: PropTypes.func,
    reset: PropTypes.func,
  };
  arst;

  /**
   * ComponentWillUnmount.
   *
   * @returns {void}
   */
  componentWillUnmount() {
    this.props.reset();
  }

  /**
   *  OnChangeEndDate.
   *
   * @param {*} value - A.
   * @returns {void}
   */
  onChangeEndDate = (value) => {
    this.props.formChange({
      key: 'endDate',
      value,
    });
  };

  /**
   * OnChangeStartDate..
   *
   * @param {*} value - A.
   * @returns {void}
   */
  onChangeStartDate = (value) => {
    this.props.formChange({
      key: 'startDate',
      value,
    });
  };

  /**
   * Onchangecarequipmentowner.
   *
   * @param {*} value - A.
   * @returns {void}
   */
  onchangecarequipmentowner = (value) => {
    this.props.formChange({
      key: 'carEquipmentOwner',
      value,
    });
  };

  /**
   * Render.
   *
   * @returns {JSX.Element}
   */
  render() {
    const { form, onSubmit, onSubmitClick } = this.props;
    return (
      <Form
        className={cn(this.props.className)}
        model="rrf.technologyVersions"
        validators={dateValidator}
        onSubmit={onSubmit}
      >
        <div
          className="align-top mr-3 d-inline-block"
          style={{ marginTop: 10, width: 270 }}
        >
          <AutocompleteAsyncRF
            fetchFunction={fetchCarEquipmentOwners}
            label="Владелец НО"
            placeholder="Все"
            value={form.carEquipmentOwner}
            onChange={this.onChangeCarEquipmentOwner}
          />
        </div>

        <div
          className="align-top mr-3 d-inline-block"
          style={{ marginTop: 8, width: 270 }}
        >
          <KeyboardDateTimePicker
            label="Дата с"
            maxDate={dateFromMaxDate(form.startDate, form.endDate)}
            value={form.startDate}
            onChange={this.onChangeStartDate}
          />
        </div>

        <div
          className="align-top mr-3 d-inline-block"
          style={{ marginTop: 8, width: 270 }}
        >
          <KeyboardDateTimePicker
            label="Дата по"
            minDate={dateToMinDate(form.startDate)}
            value={form.endDate}
            onChange={this.onChangeEndDate}
          />
        </div>

        <div
          className="align-top mr-3 d-inline-block"
          style={{ marginTop: 8, width: 270 }}
        >
          <Control
            component={TextField}
            controlProps={{
              className: 'align-top mr-3 ods-mui-text-field',
              label: 'Пользователь, создавший запрос',
            }}
            model=".userCreate"
            type="text"
          />
        </div>

        <ButtonBlue
          className="align-bottom my-2"
          type="submit"
          onClick={onSubmitClick}
        >
          Поиск
        </ButtonBlue>
      </Form>
    );
  }
}

export default searchFormWrapper(SearchForm);
