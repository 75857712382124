/* eslint-disable */
export default function submitCardTech(props, data) {
  const { mode, updateObject, createObject, deleteTechnology } = props;

  if (mode && mode.createMode) {
    createObject(data);
  } else if (mode && mode.editMode) {
    updateObject(data);
  } else {
    deleteTechnology(data);
  }
}
