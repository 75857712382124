import { get } from 'app/api/crud';

/**
 * Функция получения данных карточки.
 *
 * `на вход ид карточки
 * на выходе данные полей карточки
 * по ним можешь получить файлы для подписи`.
 *
 * @param {object} params - Параметры.
 * @param {number} params.typeId - Тип ОГХ.
 * @param {string} params.startDate - Дата создания карточки.
 * @returns {*} - Получаем данные созданного паспорта.
 */
export const getCreatedCurrentCard = ({ typeId, startDate }) => {
  return get(`/card/preview/${typeId}/${startDate}`);
};
