import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { parseDate } from 'app/components/card/common/parse';
import { OZN, YARD } from 'app/constants/oghTypes';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { GroupContainer } from 'core/uiKit/components/card.components/GroupContainer';
import { DatePicker } from 'core/uiKit/inputs/datePickers/DatePicker';
import { TextField } from 'core/uiKit/inputs/TextField';
import React from 'react';

/**
 * Компонент __Данные паспорта__.
 *
 * @returns {JSX.Element}
 */
export const DataPassport = () => {
  const {
    typeId,
    passportDraftOrg = '',
    passportDate = '',
    attribute: { date_survey = '', date_survey_green_zones = '' } = {},
  } = useGetCurrentCard();

  return (
    <>
      {[OZN, YARD].includes(typeId) ? (
        <GroupContainer title={'Данные паспорта'}>
          <FieldsSplitColumns>
            <TextField
              label={
                'Исполнитель/Исполнители работ (по разработке, актуализации паспорта)'
              }
              disabled={true}
              value={passportDraftOrg}
            />
            <DatePicker
              label={'Дата составления'}
              value={parseDate(passportDate)}
              disabled={true}
            />
            <DatePicker
              label={'Дата обследования территории'}
              value={parseDate(date_survey)}
              disabled={true}
            />
            <DatePicker
              label={'Дата обследования территории (дополнение сведений по ЗН)'}
              value={parseDate(date_survey_green_zones)}
              disabled={true}
            />
          </FieldsSplitColumns>
        </GroupContainer>
      ) : null}
    </>
  );
};
