import {
  CONTAINER_TYPE_ID_NAME,
  IContainerTypeIdProps,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/ContainerTypeId';
import React from 'react';
// @ts-ignore
import { Field } from 'redux-form';
import { SelectPreparedProps } from 'types/inputs';

import { ContainerTypeIdRFWithReduxForm } from './ContainerTypeIdRFWithReduxForm';

/**
 * ContainerTypeIdRF.
 *
 * @param {SelectPreparedProps} props - Пропсы.
 * @returns JSX.
 */
export const ContainerTypeIdRF = (
  props: SelectPreparedProps & IContainerTypeIdProps,
) => {
  return (
    <Field
      {...props}
      name={CONTAINER_TYPE_ID_NAME}
      component={ContainerTypeIdRFWithReduxForm}
    />
  );
};
