import moment from 'moment';

/**
 * Получение вчерашней даты в формате Fri May 12 2023 10:13:35 GMT+0400 (GMT+04:00).
 *
 * @returns - Вчерашняя дата.
 */
export const getYesterdayDate = (): Date => {
  return moment().subtract(1, 'day').toDate();
};
