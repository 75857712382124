import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const ORG_TYPE_NAME = 'orgType';

export const FOUNDER_TYPE = -1;

export const INSTITUTION_TYPE = 1;

/**
 * ## Тип.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const OrgType = (props) => {
  return (
    <Select
      {...props}
      label="Тип"
      name={ORG_TYPE_NAME}
      withAll={false}
      options={[
        {
          id: FOUNDER_TYPE,
          name: 'По учредителям',
        },
        {
          id: INSTITUTION_TYPE,
          name: 'По учреждениям',
        },
      ]}
    />
  );
};
