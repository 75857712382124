import formatRootId from 'app/utils/formatRootId';
/* eslint-disable */
import { uniqBy } from 'lodash';

/**
 *
 * @param parentInfo
 */
export const joinIds = (parentInfo) => {
  if (parentInfo) {
    const sortedParentInfo = uniqBy(parentInfo, 'parent_root_id');
    return sortedParentInfo
      .map((item) => formatRootId(item.parent_root_id))
      .join(', ');
  }
  return null;
};
