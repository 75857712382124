import isCreateMode from './isCreateMode';
/* eslint-disable  */

/**
 *
 * @param state
 * @param props
 */
export default (state, props) => {
  let treeParentId;
  if (isCreateMode(state, props)) {
    const mode = state.card.mode;
    const options = mode && mode.options;
    treeParentId = options && options.treeParentId;
  } else {
    treeParentId = props.card.tree_parent_id;
  }
  return treeParentId;
};
