import { BodyProvider } from 'app/pages/providers';
import { LinearProgress } from 'core/uiKit/material-ui';
import React from 'react';

/**
 * Body component.
 *
 * @param {object} props - Props.
 * @param {JSX.Element | Array<JSX.Element>} props.children - Body content.
 * @param {boolean} [props.isLoading] - Loading indicator.
 * @returns {JSX.Element}
 */
export const Body = ({ children, isLoading }) => {
  return (
    <div
      className={
        'ods-registry-bg px-5 pb-6 d-flex flex-column h-100 overflow-hidden'
      }
    >
      {isLoading ? <LinearProgress /> : null}
      <BodyProvider>{children}</BodyProvider>
    </div>
  );
};
