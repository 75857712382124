import './dialog.scss';

import {
  DialogActionsMUI,
  DialogContentMUI,
  DialogMUI,
} from 'core/uiKit/material-ui';

import { DialogTitle } from './DialogTitle';
import { PaperComponent } from './PaperComponent';

/**
 * Dialog.
 *
 * @param {object} props - Properties.
 * @param {string} [props.className] - Classname.
 * @param {'xs'|'sm'|'md'|'lg'|'xl'} [props.maxWidth] - Maxwidth.
 * @param {boolean} [props.fullWidth] - Fullwidth.
 * @param {*} [props.textHeader] - Текст заголовка.
 * @param {JSX.Element|string} props.Body - Текст тела диалогового окна.
 * @param {JSX.Element} [props.ButtonsActions] - Кастомные кнопки со своей логикой.
 * @param {boolean} props.isOpen - Внешний флаг - отобразить диалоговое окно, по умолчанию - нет.
 * @param {Function} [props.onClose] - Функция срабатывания при закрытии крестиком, она же флаг показа оного.
 * @param {boolean} [props.draggable] - Внешний флаг - включить функионал draggable.
 * @returns {JSX.Element} - Dialog element.
 */
export const Dialog = ({
  className = '',
  maxWidth = 'md',
  fullWidth = true,
  textHeader,
  Body,
  ButtonsActions,
  isOpen = false,
  onClose,
  draggable = true,
  ...props
}) => (
  <DialogMUI
    {...props}
    className={className}
    maxWidth={maxWidth}
    fullWidth={fullWidth}
    open={isOpen}
    PaperComponent={draggable ? PaperComponent : null}
    aria-labelledby={'draggable-dialog-title'}
  >
    {textHeader && <DialogTitle onClose={onClose}>{textHeader}</DialogTitle>}

    {Body && <DialogContentMUI className={'p-3'}>{Body}</DialogContentMUI>}

    <DialogActionsMUI>{ButtonsActions || ''}</DialogActionsMUI>
  </DialogMUI>
);
