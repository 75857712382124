import { useFetchOghDispatch } from 'app/actions/odsObjectActions/useFetchOgh.Dispatch';
/* eslint-disable */
import { signOghSave } from 'app/api/requests/signs/signOghSave';
import { uploadFileReturnObj } from 'app/api/requests/signs/signOghSave/uploadFileReturnObj';
import { toCurrentDateTime } from 'app/utils/date/toCurrentDateTime';
import { toast } from 'core/uiKit/components/Toast';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

/**
 * Хук для подписи.
 *
 * @function
 * @category hooks
 * @param {object} params - Properties.
 * @param {number} params.objectId - Id объекта огх.
 * @param {'SAVE'|'CLOSE'|'UPDATE'} params.signType - Тип подписи.
 * @param {Array} params.objectCardFiles - Id файлов в файловом хранилище.
 * @param {Function} params.onSuccessCallback - Callback вызовется при success.
 * @param {Function} params.onErrorCallback - Callback вызовется при onError.
 * @returns {{isLoadingSignOghSave: boolean, sendSign: Function}}
 */
export const useSignOghSaveDialogApproveElectronicSignature = ({
  objectId,
  signType,
  objectCardFiles,
  onSuccessCallback,
  onErrorCallback,
}) => {
  const fetchOgh = useFetchOghDispatch(objectId);

  const [sendSign, { isLoading: isLoadingSignOghSave }] = useMutationAdaptor(
    async ({ documentFile, ...otherValues }) => {
      const documentJustification = await uploadFileReturnObj(documentFile, {
        create_date: toCurrentDateTime(),
        typeId: 19,
      });

      return signOghSave({
        date: toCurrentDateTime(),
        fileList: documentJustification.map(({ storageId }) => ({
          files: storageId,
        })),
        objectCardFiles,
        objectId,
        signType,
      })(otherValues);
    },
    {
      /**
       *
       * @param value
       */
      onError: (value) => {
        toast.error(value?.message);
        onErrorCallback();
      },

      /**
       *
       */
      onSuccess: () => {
        toast.success('Подписано');
        fetchOgh();
        onSuccessCallback();
      },
    },
  );
  return { isLoadingSignOghSave, sendSign };
};
