import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const CAR_EQUIPMENT_TYPE_ID_NAME = 'carEquipmentTypeId';

/**
 * ## Тип навесного оборудования.
 *
 * @param {*} props - Properties.
 * @returns {JSX.Element}
 */
export const CarEquipmentTypeId = (props) => {
  const dict = 'carEquipmentType';

  const { data: options = [] } = useGetDictionary(dict);

  return (
    <Select
      {...props}
      options={options}
      label={'Тип навесного оборудования'}
      name={CAR_EQUIPMENT_TYPE_ID_NAME}
    />
  );
};
