/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import {
  get,
  isArray,
  isObject,
  isFunction,
  isEmpty,
  isNaN,
  isNull,
  filter,
} from 'lodash';

import Select from 'core/newComponents/Select';

const propTypes = {
  autoSelectForOneOption: PropTypes.bool,
  filterOptionsCallback: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  style: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.array,
    PropTypes.object,
  ]),
  withChange: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
};

class SelectField extends React.Component {
  componentDidMount() {
    this.setAutoOneOption();
  }

  componentDidUpdate(prevProps) {
    const { options, autoSelectForOneOption, value } = this.props;
    if (
      (prevProps.value !== value && !this.isEmptyValue(value)) ||
      (isArray(options) &&
        isArray(prevProps.options) &&
        prevProps.options.length !== options.length &&
        autoSelectForOneOption)
    ) {
      this.setAutoOneOption();
    }
  }

  isEmptyValue = (value) => {
    if (isArray(value) || isObject(value) || isNull(value)) {
      return isEmpty(value);
    }

    return isNaN(parseInt(value, 10));
  };

  getOptions() {
    const { filterOptionsCallback, options = [] } = this.props;
    if (isArray(options) && isFunction(filterOptionsCallback)) {
      return filter(options, filterOptionsCallback);
    }
    return options;
  }

  setAutoOneOption() {
    const { autoSelectForOneOption, options } = this.props;
    if (isArray(options) && options.length === 1 && autoSelectForOneOption) {
      this.handleChange(get(options, '[0].id', null));
    }
  }

  handleChange = (value) => {
    const { onChange, withChange } = this.props;

    const selectValue =
      isArray(value) || isObject(value) || isNull(value)
        ? value
        : parseInt(value, 10);

    const val = isNaN(selectValue) ? value : selectValue;

    onChange(val);

    if (isFunction(withChange)) {
      withChange(selectValue);
    }
  };

  render() {
    const { style, onBlur, onFocus, ...otherProps } = this.props;

    return (
      <div
        style={{
          marginBottom: '23px',
          ...style,
          height: 'auto',
          maxHeight: '71px',
        }}
      >
        <Select
          {...otherProps}
          options={this.getOptions()}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

SelectField.propTypes = propTypes;

 
export default SelectField;
