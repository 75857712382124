import { get } from 'app/api/crud';

 
/* eslint-disable */
/**
 * Функция получения данных карточки
 *
 * на вход ид карточки
 * на выходе данные полей карточки
 * по ним можешь получить файлы для подписи
 *
 * @deprecated
 * @see используй getOghCard
 * @param oghId ид карточки
 * @returns {Promise<*>}
 */
 
export const getCurrentCard = (oghId) => get(`/ogh/${oghId}`);
