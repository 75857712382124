import { IMPROVEMENT_OBJECT, ODH, OZN, YARD } from 'app/constants/oghTypes';
/* eslint-disable  */
import { connect } from 'react-redux';

import * as behaviour from './behaviour';
import * as condition from './conditions';
import { mapStateToProps } from './privilegeProvider';

const enabled = true;

/**
 *
 * @param behaviour
 * @param condition
 * @param WrappedComponent
 */
const wrap = (behaviour, condition, WrappedComponent) => {
  const wrapper = behaviour(enabled ? condition : () => true);
  const wrapped = wrapper(WrappedComponent);
  return connect(mapStateToProps, null, null, { forwardRef: true })(wrapped);
};

/**
 *
 * @param privilege
 */
export const showIfHavePrivilege = (privilege) => (WrappedComponent) => {
  return wrap(
    behaviour.visibleIf,
    condition.havePrivilege(privilege),
    WrappedComponent,
  );
};

/**
 *
 * @param privileges
 */
export const showIfHaveAnyPrivilege = (privileges) => (WrappedComponent) => {
  return wrap(
    behaviour.visibleIf,
    condition.haveAnyPrivilege(privileges),
    WrappedComponent,
  );
};

/**
 *
 * @param privilege
 */
export const accessDeniedIfDoNotHavePrivilege =
  (privilege) => (WrappedComponent) => {
    return wrap(
      behaviour.accessDeniedIfNot,
      condition.havePrivilege(privilege),
      WrappedComponent,
    );
  };

/**
 *
 * @param privileges
 */
export const accessDeniedIfDoNotHaveAnyPrivileges =
  (privileges) => (WrappedComponent) => {
    return wrap(
      behaviour.accessDeniedIfNot,
      condition.haveAnyPrivilege(privileges),
      WrappedComponent,
    );
  };

/**
 *
 * @param WrappedComponent
 */
export const accessDeniedIfCanNotViewUser = (WrappedComponent) => {
  return wrap(
    behaviour.accessDeniedIfNot,
    condition.canViewUserProfile,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const haveAccessToOghRegistry = (WrappedComponent) => {
  return wrap(
    behaviour.accessDeniedIfNot,
    condition.havePrivilegeToViewOghRegistry,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const haveAccessToApiSubscriptions = (WrappedComponent) => {
  return wrap(
    behaviour.accessDeniedIfNot,
    condition.havePrivelegeToViewApiSubscriptions,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const haveAccessToMatchingRegistry = (WrappedComponent) => {
  return wrap(
    behaviour.accessDeniedIfNot,
    condition.havePrivilegeToViewMatchingRegistry,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const haveAccessToViewChangelogOgh = (WrappedComponent) => {
  return wrap(
    behaviour.accessDeniedIfNot,
    condition.havePrivilegeToViewChangelogOgh,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const haveAccessToMatchingTechRegistry = (WrappedComponent) => {
  return wrap(
    behaviour.accessDeniedIfNot,
    condition.havePrivilegeToViewMatchingTechRegistry,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const haveAccessToMainOghRegistry = (WrappedComponent) => {
  return wrap(
    behaviour.accessDeniedIfNot,
    condition.havePrivilegeToViewSomeOghRegistry,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const showIfHavePrivilegeToViewRegistry = (WrappedComponent) => {
  return wrap(
    behaviour.visibleIf,
    condition.havePrivilegeToViewOghRegistry,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const haveAccessToTechRegistry = (WrappedComponent) => {
  // TODO
  return wrap(
    behaviour.accessDeniedIfNot,
    condition.havePrivilegeToViewTechRegistry,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const showIfHavePrivilegeToTechRegistry = (WrappedComponent) => {
  // TODO
  return wrap(
    behaviour.visibleIf,
    condition.havePrivilegeToViewTechRegistry,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const showIfHaveSomePrivilegeToTechRegistry = (WrappedComponent) => {
  // TODO
  return wrap(
    behaviour.visibleIf,
    condition.havePrivilegeToViewSomeTechRegistry,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const showIfHaveSomePrivilegeToAdministration = (WrappedComponent) => {
  return wrap(
    behaviour.visibleIf,
    condition.havePrivilegeToViewSomeAdministration,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const showIfHaveSomePrivilegeToPgmRegistry = (WrappedComponent) => {
  return wrap(
    behaviour.visibleIf,
    condition.havePrivilegeToViewSomePgmRegistry,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const showIfHaveSomePrivilegeToReportRegistry = (WrappedComponent) => {
  return wrap(
    behaviour.visibleIf,
    condition.havePrivilegeToViewReportRegistry,
    WrappedComponent,
  );
};

/**
 * Проверка возможности создания МСО объекта.
 */

/**
 *
 * @param WrappedComponent
 */
export const showIfHavePrivilegeToCreateMsoObject = (WrappedComponent) => {
  return wrap(
    behaviour.visibleIf,
    condition.havePrivilegeToCreateMso,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const haveAccessToCreateTechObject = (WrappedComponent) => {
  return wrap(
    behaviour.accessDeniedIfNot,
    condition.havePrivilegeToCreateTechObject,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const showOghRegistryNavBarItem = (WrappedComponent) => {
  return wrap(
    behaviour.visibleIf,
    condition.havePrivilegeToViewSomeOghRegistry,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const interceptedShowOghTypes = (WrappedComponent) => {
  return wrap(
    behaviour.filterTypesMainPage,
    condition.filterTypesToMainPage,
    WrappedComponent,
  );
};

/**
 *
 * @param privileges
 */
export const addCompareEnabledPropIfHaveAnyPrivilege =
  (privileges) => (WrappedComponent) => {
    return wrap(
      behaviour.addProps({ compareEnabled: true }),
      condition.haveAnyPrivilege(privileges),
      WrappedComponent,
    );
  };

/**
 *
 * @param WrappedComponent
 */
export const addOghTitleListTypes = (WrappedComponent) => {
  return wrap(behaviour.addOghTitleListTypes, () => true, WrappedComponent);
};

/**
 *
 * @param WrappedComponent
 */
export const showIfHavePrivilegeToCreateOghObject = (WrappedComponent) => {
  return wrap(
    behaviour.visibleIf,
    condition.havePrivilegeToCreateOghObject,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const showIfHavePrivilegeToEditOghObject = (WrappedComponent) => {
  return wrap(
    behaviour.visibleIf,
    condition.havePrivilegeToEditOghObject,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const showIfHavePrivilegeToEditTechObject = (WrappedComponent) => {
  return wrap(
    behaviour.visibleIf,
    condition.havePrivilegeToEditTechObject,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const showIfHavePrivilegeToDeleteOghObject = (WrappedComponent) => {
  return wrap(
    behaviour.visibleIf,
    condition.havePrivilegeToDeleteOghObject,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const showIfHavePrivilegeToDeleteTechObject = (WrappedComponent) => {
  return wrap(
    behaviour.visibleIf,
    condition.havePrivilegeToDeleteTechObject,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const haveAccessToCreateOghObject = (WrappedComponent) => {
  return wrap(
    behaviour.accessDeniedIfNot,
    condition.havePrivilegeToCreateOghObject,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const showIfHavePrivilegeToSendMatchingOgh = (WrappedComponent) => {
  return wrap(
    behaviour.visibleIf,
    condition.havePrivilegeToSendMatchingOgh,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const showIfHavePrivilegeToSendMatchingTech = (WrappedComponent) => {
  return wrap(
    behaviour.visibleIf,
    condition.havePrivilegeToSendMatchingTech,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const showIfHavePrivilegeToViewAnyMatchingRegistry = (
  WrappedComponent,
) => {
  return wrap(
    behaviour.visibleIf,
    condition.havePrivilegeToViewMatching,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const showIfHavePrivilegeToViewAnyOghMatchingRegistry = (
  WrappedComponent,
) => {
  return wrap(
    behaviour.visibleIf,
    condition.havePrivilegeToViewMatchingRegistry,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const showIfHavePrivilegeToViewAnyTechMatchingRegistry = (
  WrappedComponent,
) => {
  return wrap(
    behaviour.visibleIf,
    condition.havePrivilegeToViewMatchingTechRegistry,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const showIfHavePrivilegeToMatchOgh = (WrappedComponent) => {
  return wrap(
    behaviour.visibleIf,
    condition.havePrivilegeToMatchOgh,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const showIfHavePrivilegeToMatchTech = (WrappedComponent) => {
  return wrap(
    behaviour.visibleIf,
    condition.havePrivilegeToMatchTech,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const showIfHavePrivilegeToMatchTitle = (WrappedComponent) => {
  return wrap(
    behaviour.visibleIf,
    condition.havePrivilegeToMatchTitle,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const showIfHavePrivilegeToSendMatchingTitle = (WrappedComponent) => {
  return wrap(
    behaviour.visibleIf,
    condition.havePrivilegeToSendMatchingTitle,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const showIfHavePrivilegeToDeleteTitle = (WrappedComponent) => {
  return wrap(
    behaviour.visibleIf,
    condition.havePrivilegeToDeleteTitle,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const showIfHavePrivilegeToViewAnyTitle = (WrappedComponent) => {
  return wrap(
    behaviour.visibleIf,
    condition.havePrivilegeToViewAnyTitle,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const showIfViewTitleMatchingRegistryGranted = (WrappedComponent) => {
  return wrap(
    behaviour.visibleIf,
    condition.grantedViewTitleMatchingRegistry,
    WrappedComponent,
  );
};

/**
 *
 * @param WrappedComponent
 */
export const showIfViewOghMatchingRegistryGranted = (WrappedComponent) => {
  return wrap(
    behaviour.visibleIf,
    condition.grantedViewOghMatchingRegistry,
    WrappedComponent,
  );
};

/**
 *
 * @param privileges
 */
export const getTitleListTypesGrantedForCreate = (privileges) => {
  const map = {
    create_title_improvement_object: IMPROVEMENT_OBJECT,
    create_title_odh: ODH,
    create_title_ozn: OZN,
    create_title_yard: YARD,
  };
  return (privileges || []).reduce((result, item) => {
    if (map[item]) {
      result = [...result, map[item]];
    }
    return result;
  }, []);
};
