 
import { shallow } from 'enzyme';

import {
  PROGRESS_STATUS_NAME,
  ProgressStatusFF,
  SelectWrapper,
} from './ProgressStatusFF';

jest.mock('react-final-form');

/* eslint-disable */
describe('title ProgressStatusFF', () => {
  it('test name', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const wrapper = shallow(<ProgressStatusFF />);
    // Assert
    expect(wrapper.props().name).toBe(PROGRESS_STATUS_NAME);
  });

  it('test SelectWrapper props', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const wrapper = shallow(<SelectWrapper />);
    const progressStatusList = [
      {
        id: 'in_progress',
        name: 'Работы ведутся',
      },
      {
        id: 'finished',
        name: 'Работы завершены',
      },
      {
        id: 'planned',
        name: 'Работы запланированы',
      },
    ];
    // Assert
    expect(wrapper.props()).toMatchObject({
      style: { minWidth: 250 },
      label: 'Статус выполнения работ',
      options: progressStatusList,
    });
  });
});
