import { preparationValues } from 'core/utils/helpers/preparationValues';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import { pick } from 'lodash';

import { convertValue } from './utils';

/**
 * Сервис для получения атрибутов для пересечения.
 *
 * @param {object} options - Опции.
 * @param {object} options.attributes - Атрибуты.
 * @param {object} options.emptyResult - Пустой результат.
 */
export default class IntersectionGeometryAttributesService {

  /**
   * Конструктор.
   *
   * @param {object} options - Опции.
   * @param {object} options.attributes - Атрибуты.
   * @param {object} options.emptyResult - Пустой результат.
   */
  constructor({ attributes, emptyResult }) {
    this.attributes = attributes;
    this.emptyResult = emptyResult;
  }

  /**
   * Получить атрибуты.
   *
   * @param {string} cardTypeId - Идентификатор типа карточки.
   * @param {object} formValues - Значения формы.
   * @param {object} attributesAll - Атрибуты.
   * @returns {object}
   */
  getAttributes(cardTypeId, formValues, attributesAll) {
    const paths = this.attributes[cardTypeId];
    let result;
    if (paths) {
      result = pick({ ...attributesAll, ...formValues }, paths);
    } else {
      result = this.emptyResult;
    }

    // для перевода одного в другое
    addPreparation('is_isolated', convertValue.zero);
    addPreparation('is_object_area', convertValue.zero);
    addPreparation('is_diff_height_mark', convertValue.zero);
    return preparationValues(result);
  }
}
