import { TextField } from 'core/uiKit/inputs/TextField';
import React, { FC } from 'react';
import { NumericFormat } from 'react-number-format';
import { NumberProps } from 'types/inputs';

/**
 * Кастомизация чеслового инпута.
 *
 * @param props - Properties.
 * @param [props.decimalScale] - Если он определен, он ограничивается заданной десятичной шкалой.
 * @param [props.digits] - Поддержка старого функционала используй decimalScale.Если он определен, он ограничивается заданной десятичной шкалой.
 * @param [props.maxLength] - Максимальная длина символов включительно.
 * @param props.inputRef - Input ref.
 * @param props.name - Имя инпута.
 * @param props.onChange - Фгнкция изменения.
 * @param props.fixedDecimalScale - Флаг фиксированный десятичный масштаб, сам масштаб задается в decimalScale.
 * @param ref
 * @returns {JSX}
 */
const NumberFormatCustom = React.forwardRef(
  (
    props: {
      inputRef?: React.ForwardedRef<HTMLInputElement>;
      onChange?: (value: {
        target: {
          name: string;
          value: string;
        };
      }) => void;
      decimalScale?: number;
      name: string;
      digits?: number;
      maxLength?: number;
      fixedDecimalScale?: boolean;
    },
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const {
      fixedDecimalScale,
      maxLength,
      onChange,
      decimalScale,
      digits,
      ...other
    } = props;
    return (
      <NumericFormat
        {...other}
        fixedDecimalScale={fixedDecimalScale}
        allowNegative={false}
        getInputRef={ref}
        decimalScale={digits || decimalScale || 0}
        isAllowed={(values) => {
          if (maxLength) {
            return values.value.length <= maxLength;
          }
          return true;
        }}
        onValueChange={(values) => {
          // @ts-ignore
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
      />
    );
  },
);

/**
 * Числовой инпут.
 *
 * @param props - Properties.
 * @param [props.decimalScale] - Если он определен, он ограничивается заданной десятичной шкалой.
 * @param [props.allowNegative] - Разрешить отрицательные числа.
 * @param [props.defaultValue] - Разрешить отрицательные числа.
 * @param props.value - Значения.
 * @param [props.prefix] - Добавляет префикс перед номером.
 * @param [props.suffix] - Добавляет суффикс после числа.
 * @param [props.format] - Если формат задан как строка хеша, можно вводить числа вместо хеша. Если формат задан как функция, компонент вызывает функцию с неформатированным числом и ожидает форматированного числа. Строка: пример на основе хэша (#### #### #### ####).
 * @param [props.mask] - Если маска определена, компонент будет показывать невведенные, помещенные с замаскированным значением.
 * @param [props.thousandSeparator] - Добавьте разделители тысяч на число.
 * @param [props.digits] - Поддержка старого функционала используй decimalScale. Если он определен, он ограничивается заданной десятичной шкалой.
 * @param [props.hidden] - Спрятать элемент.
 * @param [props.maxLength] - Максимальная длина символов включительно.
 * @param [props.fixedDecimalScale] - Добавляет отображение десятичной шкалы заданной decimalScale.
 * @param [props.required] - Флаг обязательности поля.
 * @returns JSX.Element.
 */
export const NumberField: FC<NumberProps> = ({
  decimalScale,
  allowNegative,
  prefix,
  suffix,
  fixedDecimalScale,
  format,
  mask,
  thousandSeparator,
  digits,
  value,
  hidden,
  defaultValue,
  maxLength,
  required,
  ...propsOther
}) => {
  return hidden ? null : (
    <TextField
      {...propsOther}
      required={required}
      // @ts-ignore
      inputProps={{
        allowNegative,
        decimalScale,
        defaultValue,
        digits,
        fixedDecimalScale,
        format,
        mask,
        maxLength,
        prefix,
        suffix,
        thousandSeparator,
        value,
      }}
      inputLabelProps={{
        // @ts-ignore
        shrink: value !== '' || propsOther?.meta?.active,
      }}
      InputProps={{
        inputComponent: NumberFormatCustom,
      }}
    />
  );
};

NumberField.defaultProps = {
  allowNegative: false,
  dark: false,
  decimalScale: 0,
  disabled: false,
  label: 'NumberField label',
  name: 'NumberField',
};
