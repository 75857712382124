import { useContext } from 'react';

import { ContextMapEdit } from './EditMapContext';

/**
 * Fn.
 *
 * @returns {*}
 */
export const useMapEdit = () => {
  return useContext(ContextMapEdit);
};
