import { useGetRegistryCount as useGetRegistryCountD } from 'app/api/hooks/useGetRegistryCount';

/**
 * Хук получения количества данных для таблиц реестров.
 *
 * @deprecated
 *
 * TODO: удалить в 54 версии.
 *
 * @param {Array} queryKey - Данные для запроса.
 * @returns {*}
 */
export const useGetRegistryCount = useGetRegistryCountD;
