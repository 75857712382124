import getReagents from 'app/selectors/card/getReagents';
/* eslint-disable */
import { get } from 'lodash';
import { createSelector } from 'reselect';

/**
 *
 * @param state
 */
const getCurrentReagentKind = (state) =>
  get(state, 'form.editCardDialogForm.values.reagent_kind_id');

const getReagentsForKind = getReagents(getCurrentReagentKind);

/**
 *
 * @param state
 * @param ownProps
 */
const getTableRecords = (state, ownProps) => get(ownProps, 'input.value.table');

export default createSelector(
  [getTableRecords, getReagentsForKind],
  (tableRecords, reagentsForKind) => {
    const reagentIdsToExclude =
      (tableRecords && tableRecords.map((item) => item.reagent_id)) || [];

    return reagentsForKind.filter(
      (item) => !reagentIdsToExclude.includes(item.id),
    );
  },
);
