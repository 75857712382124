import getDict from 'app/selectors/getDict';
import { createSelector } from 'reselect';

/* eslint-disable */
export default function buildGetDocuments(oghGroupCode) {
  return createSelector(
    [getDict('oghGroups'), getDict('oghFileTypes')],
    (oghGroups, oghFileTypes) => {
      let result;
      if (oghGroupCode) {
        const group = oghGroups.find((item) => item.code === oghGroupCode);
        if (group) {
          result = group.oghFileTypeList.map((item) =>
            oghFileTypes.find(
              (fileType) => fileType.id === item.ogh_file_type_id,
            ),
          );
        } else {
          result = null;
        }
      } else {
        result = null;
      }
      return result;
    },
  );
}
