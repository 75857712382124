import './InterceptedTypes.scss';

/* eslint-disable */
import { useSetupInterceptedTypes } from 'app/components/MainPage/InterceptedTypes/useSetup.InterceptedTypes';
import { LinearProgress } from 'core/uiKit/material-ui';
import React from 'react';

import { InterceptedTypeItem } from './InterceptedTypeItem';

/**
 *
 * @param root0
 * @param root0.onChangeTypeId
 * @param root0.typeId
 * @param root0.isLoading
 */
export const InterceptedTypes = ({ onChangeTypeId, typeId, isLoading }) => {
  const { listOdhTypes } = useSetupInterceptedTypes();

  return (
    <div className={'intercepted-type'}>
      <ul className={'intercepted-type__list'}>
        {listOdhTypes.map((item) => {
          return (
            <InterceptedTypeItem
              key={item.id}
              src={item.src}
              title={`${item.title} (${item.count})`}
              active={typeId === item.id}
              onClick={() => onChangeTypeId(item.id)}
            />
          );
        })}
      </ul>
      {isLoading ? <LinearProgress color={'inherit'} /> : null}
    </div>
  );
};
