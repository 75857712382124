/* eslint-disable */
/**
 * Места накопления отходов.
 *
 * @type {{path: 'container', code: 'container'}}
 */
export const pathContainer = {
  path: 'container',
  code: 'container',
};
