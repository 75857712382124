/* eslint-disable */
import {
  PLACEMENT_ID_NAME,
  PlacementId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/PlacementId/PlacementId';
import { Field } from 'redux-form';

export const PlacementIdRFWithReduxForm = ({
  input,
  ...props
}) => {
  return <PlacementId {...input} {...props} />;
};

export const PlacementIdRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={PLACEMENT_ID_NAME}
      component={PlacementIdRFWithReduxForm}
    />
  );
};
