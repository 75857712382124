import { Table } from 'core/uiKit/components/tables/Table';

import { getColumnsTechPlace } from './columns.TechPlace';
import { useSetupTableTechPlace } from './useSetup.Table.TechPlace';

/**
 * Компонет таблицы __Производственные некапитальные объекты__.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - Id объекта ОГX текущего.
 * @param {number} props.parentTypeId - Id объекта ОГX родительского.
 * @returns {JSX.Element} - JSX.
 * @example
 * <TableTechPlace typeId={typeId} parentTypeId={parentTypeId}/>
 */
export const TableTechPlace = ({ typeId, parentTypeId }) => {
  const { count, isLoading, table } = useSetupTableTechPlace({
    parentTypeId,
    typeId,
  });

  return (
    <Table
      columns={getColumnsTechPlace()}
      data={table || []}
      isLoading={isLoading}
      total={count}
    />
  );
};
