import { getNameFile } from 'app/pages/cardsOgh/components/tables/Documents/helpers/getNameFile';
import { DocumentWithFinalForm } from 'core/form/finalForm/fields/prepared/tables/documentTables/DocumentWithFinalForm';
import React from 'react';
import { Field } from 'react-final-form';

const ID = 14;
const NAME = getNameFile(14);
const TITLE = 'Иные документы';

/**
 * Таблица документов __Иные документы__.
 *
 * @returns {JSX.Element}
 */
export const OtherDocumentsFf = () => {
  return (
    <Field
      name={NAME}
      component={DocumentWithFinalForm}
      id={ID}
      title={TITLE}
    />
  );
};
