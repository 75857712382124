import { useGetFilesForSignature } from 'app/api/hooks/signs/useGetFilesForSignature';
import { ContinueFFDialog } from 'core/form/finalForm/components/dialogs/ContinueFF.Dialog';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import { DialogSignatureFiles } from './Dialog.SignatureFiles';
import { useSignCloseElectronicSignature } from './useSignClose.ElectronicSignature';

vi.mock('core/utils/hooks/useGetObjectId');
vi.mock('app/api/hooks/signs/useGetFilesForSignature');
vi.mock('./useSignClose.ElectronicSignature');
vi.mock('./Form.Dialog.SignatureFiles', () => ({

  /**
   * Мок FormDialogSignatureFiles.
   *
   * @returns {React.ReactElement}
   */
  FormDialogSignatureFiles: () => <div>form</div>,
}));

describe('DialogSignatureFiles', () => {
  const mockOnClose = vi.fn();

  it('renders a ContinueFFDialog component', () => {
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({});
    useGetFilesForSignature.mockReturnValue({});
    useSignCloseElectronicSignature.mockReturnValue({});
    // Act
    const wrapper = shallow(
      <DialogSignatureFiles isOpen={false} onClose={mockOnClose} />,
    );

    // Assert

    expect(wrapper.find(ContinueFFDialog)).toHaveLength(1);
  });

  it('passes the correct props to the ContinueFFDialog component', () => {
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({});
    useGetFilesForSignature.mockReturnValue({});
    useSignCloseElectronicSignature.mockReturnValue({});

    const mockOnClose = vi.fn();
    // Act
    const wrapper = shallow(
      <DialogSignatureFiles isOpen={false} onClose={mockOnClose} />,
    );

    const dialog = wrapper.find(ContinueFFDialog);
    expect(dialog.prop(' closeDialog')).toBe();
    expect(wrapper.prop('initialValues')).toEqual({
      date: expect.any(Date),
      sendReason: [],
    });
    expect(wrapper.prop('isLoading')).toBeUndefined();
    expect(wrapper.prop('isOpen')).toBeFalsy();
    expect(wrapper.prop('onSubmit')).toBeUndefined();
    expect(wrapper.prop('textHeader')).toEqual('Форма подписания файлов');
    expect(wrapper.prop('validate')).toEqual(expect.any(Function));
  });
});
