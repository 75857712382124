import { REQUIRED_FIELD_MESSAGE } from 'app/constants/messages';
import { isEmpty } from 'app/utils/validation';

/* eslint-disable */
export default function validateDistance(formValues) {
  if (
    formValues.planar_structure_type_id === 42 &&
    isEmpty(formValues.planar_structure_type_ref_id)
  ) {
    return { planar_structure_type_ref_id: REQUIRED_FIELD_MESSAGE };
  }
  return {};
}
