import { fetchOgh } from 'app/actions/odsObjectActions/fetchOgh';
import { queryClient } from 'app/routes/QueryClientProvider';
import { SendMatchingBoundariesOghButton } from 'core/uiKit/components/buttons';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { DialogBound } from './Dialog.Bound';

/**
 * На согласование границ.
 *
 * @param {*} props - A.
 * @param {*} props.editMode.editMode - A.
 * @param {*} props.editMode - A.
 * @param {*} props.fetchOgh - A.
 * @param {*} props.editMode.fetchOgh - A.
 * @returns {JSX.Element|null}
 */
const SendMatchingBoundaries = ({ editMode, fetchOgh }) => {
  const { childId, id } = useParams();
  const objectId = childId || id;

  const [isOpen, setOpen] = useState(false);

  /**
   * AcceptExternalObject.
   *
   * @returns {void}
   */
  const handleClickOpen = () => {
    setOpen(true);
  };

  /**
   * AcceptExternalObject.
   *
   * @returns {void}
   */
  const handleClose = () => {
    fetchOgh(objectId);
    setOpen(false);
    queryClient.invalidateQueries({ queryKey: ['getVisibilityButtonsOgh'] });
  };

  const show = !editMode && objectId;

  return show ? (
    <>
      <SendMatchingBoundariesOghButton
        objectId={objectId}
        onClick={handleClickOpen}
      />
      <DialogBound isOpen={isOpen} onClose={handleClose} />
    </>
  ) : null;
};

/**
 * A.
 *
 * @param {*} state - A.
 * @returns {*}
 */
function mapStateToProps(state) {
  return {
    editMode: state.card?.mode?.editMode,
  };
}

const mapDispatchToProps = {
  fetchOgh,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SendMatchingBoundaries);
