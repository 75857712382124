import { fetchNeighboursGeometry } from 'app/api/requests/geometry/fetchNeighboursGeometry';
import { PrintButton } from 'app/pages/components/buttons/PrintButton';
import { getColumnsIntersectionTable } from 'app/utils/card/checkGeometry/getColumns.IntersectionTable';
import { Table } from 'core/uiKit/components/tables/Table';
import { useParamsTable } from 'core/uiKit/components/tables/Table/useParamsTable';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery/useQueryAdaptor';

import { printNeighboursTable } from './api/printNeighboursTable';

/**
 * Таблица IntersectionTable.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.params - Параметры.
 * @returns {JSX.Element} - Таблица.
 */
export const IntersectionTable = ({ params }) => {
  const { queryTable } = useParamsTable();

  const { data = [], isLoading } = useQueryAdaptor(
    ['fetchNeighboursGeometry', { ...params, ...queryTable }],
    fetchNeighboursGeometry,
  );

  const { data: neighbors, count } = data;

  return (
    <>
      <div
        className={'mb-2'}
        style={{ display: 'flex', justifyContent: 'end' }}
      >
        <PrintButton printFn={() => printNeighboursTable(params)} />
      </div>
      <div className={'d-flex h-100 '}>
        <Table
          columns={getColumnsIntersectionTable()}
          data={neighbors}
          total={count}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};
