import { TreePanelYard } from 'app/pages/registry/ogh/yard/TreePanelYard';
import Container from 'app/pages/registry/ogh/сontainer/Container';

 
/**
 * Места сбора отходов для Одх.
 *
 * @param {object} props - Props.
 * @param {Array} props.tree - Боковое дерево.
 * @param {number} props.parentTypeId - Id типа огх родителя.
 * @returns {JSX.Element} - JSX.
 * @example -------
 */
const ContainerOdh = ({ tree, parentTypeId }) => {
  return (
    <Container
      TreePanel={<TreePanelYard tree={tree} />}
      parentTypeId={parentTypeId}
    />
  );
};

export default ContainerOdh;
