import { OZN, YARD } from 'app/constants/oghTypes';
import { createNumberedList } from 'app/utils/createNumberedList';

/**
 * Получение подсказки по typeID Площадь ручной уборки.
 *
 * @param {number} typeId - Тип ОГХ.
 * @returns {JSX.Element}
 */
export const getHintManualCleanArea = (typeId) => {
  const cleaningMethodChildren = [
    '"Плоскостные сооружения".',
    '"Дорожно-тропиночные сети"',
  ];

  switch (typeId) {
    case YARD: {
      cleaningMethodChildren.push({
        children: ['Пешеходный мост', 'Пирс', 'Cухой фонтан'],
        item: '"Инженерные сооружения"',
      });
      break;
    }
    case OZN: {
      return createNumberedList({
        header: 'Рассчитывается как сумма значений',
        list: [
          {
            children: ['Отмостка'],
            item: '«Уборочная площадь объектов капитального строительства, кв.м.»',
          },
          {
            children: [
              'Автопарковка',
              'Детская площадка',
              'Площадка для пожарной техники',
              'Площадка для выгула животных',
              'Площадка для отдыха',
              'Смотровая площадка',
              'Площадка иного типа',
              'Спортивная площадка',
              'Техническая площадка',
              'Экопарковка',
              'Ролледром',
              'Скейтпарк',
              'Амфитеатр',
              'Уличный вольер',
              'Велопарковка открытого типа',
            ],
            item: '«Уборочная площадь плоскостных сооружений, кв.м.»',
          },
          {
            children: [
              'Контейнерная площадка',
              'Бункерная площадка',
              'Площадка стационарного павильона для РСО',
            ],
            item: '«Уборочная площадь мест накопления отходов, кв.м.»',
          },
          {
            children: [
              'Проезд',
              'Тротуар',
              'Технический (технологический) тротуар',
              'Пешеходная дорожка',
              'Беговая дорожка',
              'Велосипедная дорожка',
            ],
            item: '«Уборочная площадь дорожно-тропиночной сети, кв.м»',
          },
          {
            children: [
              'Газон обыкновенный',
              'Газон луговой, разнотравный',
              'Газон партерный',
              'Газон на откосе',
              'Незапечатанная поверхность без дернового покрытия',
            ],
            item: '«Уборочная площадь газонов, кв.м.»',
          },
          {
            children: [
              'Однолетники',
              'Многолетники и розы',
              'Двулетники (виола)',
              'Луковичные и клубнелуковичные кроме тюльпанов, нарциссов',
              'Тюльпаны, нарциссы',
              'Инертный материал (отсыпка)',
              'Декоративные кустарники (как элемент цветника)',
              'Газоны (как элемент цветника)',
            ],
            item: '«Уборочная площадь цветников, кв.м.»',
          },
          {
            children: [
              'Общая площадь посадочных мест в элементах цветочного оформления',
              'Общая площадь посадочных мест в элементах озеленения',
            ],
            item: '«Уборочная площадь посадочных мест в элементах вертикального озеленения, кв.м»',
          },
          {
            children: ['Пандус'],
            item: '«Уборочная площадь элементов благоустройства для маломобильных групп населения, кв.м»',
          },
          {
            children: ['Лестница'],
            item: '«Уборочная площадь элементов организации рельефа, кв.м»',
          },
          {
            children: ['Лоток водосточный', 'Желоб ливневой'],
            item: '«Уборочная площадь элементов СФО, кв.м»',
          },
          {
            children: [
              'Фонтан',
              'Пирс (причал)',
              'Сухой фонтан',
              'Пешеходный мост',
              'Бассейн',
              'Променад',
            ],
            item: '«Уборочная площадь инженерных сооружений, кв. м»',
          },
          {
            children: [
              'Открытый с искусственным льдом',
              'Открытый с естественным льдом',
            ],
            item: '«Уборочная площадь Катков, кв.м»',
          },
        ],
      });
    }
    default: {
      break;
    }
  }

  return createNumberedList({
    header: 'Рассчитывается как сумма значений',
    list: [
      {
        children: cleaningMethodChildren,
        item: '"Площадь, кв.м." ("Способ уборки": "Ручная уборка")',
      },
      {
        children: [
          '"Закрытые велопарковки"',
          '"Места сборов отходов"',
          '"Газоны"',
        ],
        item: '"Площадь, кв.м."',
      },
      {
        children: ['"ИОКС типа Отмостка"'],
        item: '"Площадь, кв.м."',
      },
      {
        children: ['"Цветники"'],
        item: '"Общая площадь цветника, кв.м"',
      },
      {
        children: [
          'Элементы организации рельефа типа Лестницы',
          'Элементы благоустройства для маломобильных групп населения типа Пандус',
        ],
        item: 'Количество, кв.м.',
      },
    ],
  });
};
