import KeyboardDateTimePickerRF from 'core/form/reduxForm/fields/default/datePickers/KeyboardDateTimePickerRF';

/* eslint-disable */
export const KeyboardDateTimeEndComponent = () => {
  return (
    <KeyboardDateTimePickerRF
      {...{
        name: 'endDate',
        label: 'Действует по',
        disabled: true,
      }}
    />
  );
};
