/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */

/**
 * Колонка __Связанные элементы ЗН (ID) - Цветники__.
 *
 * @type {{ accessor: 'related_elements_flowers_garden_id',Header: 'Связанные элементы ЗН (ID) - Цветники' }}
 * @augments Cell
 */
export const related_elements_flowers_garden_id = {
  Header: 'Связанные элементы ЗН (ID) - Цветники',
  accessor: 'related_elements_flowers_garden_id',
  sortable: true,
};
