import { DataExportButton } from 'app/components/card/ogh/index/card/components/DataExport.Button';
import { SaveButton } from 'core/uiKit/components/buttons/actionsButtons/Save.Button';
import React from 'react';
import { useForm } from 'react-final-form';

import { useMode } from '../../hooks/useMode';
import { CancelButton } from './components/CancelButton';
import { DeleteButtonCard } from './components/DeleteButtonCard';
import { EditButton } from './components/EditButton';
import { SendMatching } from './components/SendMatching';

/**
 * Группа кнопок паспортов ОГХ.
 *
 * @param {*} props - Properties.
 * @param {boolean} props.loading - Loading.
 * @returns {JSX.Element}
 */
export const ButtonsCard = ({ loading }) => {
  const { reset } = useForm();

  const { editMode } = useMode();
  return (
    <>
      {editMode && <SaveButton loading={loading} />}
      <EditButton />
      <CancelButton resetForm={reset} />
      <DeleteButtonCard />
      <SendMatching />
      <DataExportButton />
    </>
  );
};
