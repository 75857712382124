 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __ID RFID метки__.
 *
 * @type {{ accessor: 'id_rfid', Header: 'ID RFID метки' }}
 * @augments Cell
 */
export const id_rfid = {
  accessor: 'id_rfid',
  Header: 'ID RFID метки',
  sortable: true,
};
