import {
  DEPARTMENT_ID_NAME,
  DepartmentIdFF,
} from 'core/form/finalForm/fields/prepared/selects/simple/DepartmentId.FF';
import React from 'react';
import { NumberParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

export { DEPARTMENT_ID_NAME };

/**
 * Filter. Отраслевой ОИВ
 * department.
 *
 * @returns {JSX.Element}
 * @class
 */
export const DepartmentFilter = () => {
  return <DepartmentIdFF className={filterInput} />;
};

export const departmentParams = {
  [DEPARTMENT_ID_NAME]: NumberParam,
};
