/* eslint-disable */
import React from 'react';
import { filterInput } from '../filterInputsClasses';
import { CUSTOMERS_NAME, CustomersFF } from 'core/form/finalForm/fields';
import { StringParam } from 'use-query-params';
 
export { CUSTOMERS_NAME };
 
 
/**
 *     Заказчик
 *     customer
 */
 
export const CustomersFilter = (props) => (
  <CustomersFF {...props} className={filterInput} />
);

 
export const customersesParams = () => {
  return {
    [CUSTOMERS_NAME]: StringParam,
  };
};
