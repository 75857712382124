import 'moment/locale/ru';
import 'assets/styles/index.scss';

import { AppRoutes } from 'app/routes/appRoutes';
import { StyledEngineProvider } from 'core/uiKit/material-ui';
import { Provider } from 'react-redux';

import { store } from './configure';

/**
 * Root.
 *
 * @returns {JSX.Element}
 */
const Root = () => (
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <AppRoutes />
    </StyledEngineProvider>
  </Provider>
);

export default Root;
