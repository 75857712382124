import { Validator } from 'core/form/Validator';
import { CLEAN_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CleanTypeId';
import { COATING_GROUP_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingGroupId';
import { COATING_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingTypeId';
import { ENGINEER_BUILDING_TYPE_ID } from 'core/uiKit/preparedInputs/selects/simpleSelect/EngineerBuildingTypeId';
import { MATERIAL_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/MaterialId';
import { UNIT_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/UnitId';
import { QUANTITY_NAME } from 'core/uiKit/preparedInputs/textField/Quantity';

/**
 * Функция валидации __Инженерных сооружений__.
 *
 * @param {object} values - Значения формы.
 * @returns {object}
 */
export const validateEngineerBuilding = (values) => {
  const validate = new Validator(values);

  validate.setRequired(ENGINEER_BUILDING_TYPE_ID);
  validate.setRequired(MATERIAL_ID_NAME);

  // Если поле "Единица измерения" не Штуки,
  if (values[UNIT_ID_NAME] !== 5) {
    validate.setRequired(CLEAN_TYPE_ID_NAME);
  }

  // TODO:  Удалить Условие Оставить просто валидацию
  if (values[ENGINEER_BUILDING_TYPE_ID]) {
    validate.setRequired(UNIT_ID_NAME);
  }

  // TODO:  Удалить Условие Оставить просто валидацию
  if (values[UNIT_ID_NAME]) {
    validate.setRequired(QUANTITY_NAME);
  }

  if (
    // TODO:  Удалить строчку
    values[COATING_GROUP_ID_NAME] &&
    // TODO:  Удалить строчку
    values[CLEAN_TYPE_ID_NAME] &&
    values[COATING_GROUP_ID_NAME] === 2 &&
    values[CLEAN_TYPE_ID_NAME] === 2
  ) {
    validate.addError(
      CLEAN_TYPE_ID_NAME,
      'Механизированная уборка разрешена только для покрытий вида "Усовершенствованное"',
    );
  }

  if (
    values[UNIT_ID_NAME] === 2 &&
    ![2, 9, 3].includes(values[ENGINEER_BUILDING_TYPE_ID])
  ) {
    validate.setRequired(COATING_GROUP_ID_NAME);
    validate.setRequired(COATING_TYPE_ID_NAME);
  }

  return validate.getErrors();
};
