 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Идентификатор родительского объекта__.
 *
 * @type {{
 * accessor: 'parent_short_root_id',
 * Header: 'Идентификатор родительского объекта',
 * sortable: true,
 * }}
 * @augments Cell
 */
export const parent_short_root_id = {
  accessor: 'parent_short_root_id',
  Header: 'Идентификатор родительского объекта',
  sortable: true,
};
