import { COATING_FACE_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingFaceTypeId';
import React from 'react';
import { Field } from 'react-final-form';

import { CoatingFaceTypeIdWithFF } from './CoatingFaceTypeIdWithFF';
import { usePreparationCoatingFaceTypeId } from './usePreparation.CoatingFaceTypeId.FF';

/**
 * Select FF __Вид покрытия облицовка__.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const CoatingFaceTypeIdFF = (props) => {
  usePreparationCoatingFaceTypeId();

  return (
    <Field
      {...props}
      name={COATING_FACE_TYPE_ID_NAME}
      component={CoatingFaceTypeIdWithFF}
    />
  );
};
