import carCardTemplate from './car/carCardTemplate';
/* eslint-disable */
const mainTabs = [
  {
    id: 'admin-tab',
    label: 'Администрирование',
  },
  {
    id: 'props-tab',
    label: 'Свойства',
    active: true,
  },
  {
    id: 'equipment-tab',
    label: 'Оборудование',
  },
  {
    id: 'versions-tab',
    label: 'Версии',
  },
];

 
export default carCardTemplate({
  mainTabs,
});
