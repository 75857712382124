import { useCheckingItIsChildOfImprovementObject } from 'app/pages/registry/ogh/hooks/useCheckingItIsChildOfImprovementObject';
import { FilterFF } from 'core/form/filterForm';
import {
  AddressFilter,
  addressParams,
} from 'core/form/filterForm/components/autocompleteAsync/AddressFilter';
import {
  CustomerFilter,
  customerParams,
} from 'core/form/filterForm/components/autocompleteAsync/CustomerFilter';
import {
  OwnerIdFilter,
  ownerIdParams,
} from 'core/form/filterForm/components/autocompleteAsync/OwnerIdFilter';
import {
  DateFromFilter,
  dateFromParams,
} from 'core/form/filterForm/components/dateFilters/DateFromFilter';
import {
  DateToFilter,
  dateToParams,
} from 'core/form/filterForm/components/dateFilters/DateToFilter';
import {
  OghStatusesFilter,
  oghStatusesParams,
} from 'core/form/filterForm/components/miltiSelect/OghStatusesFilter';
import {
  ContainerTypeFilter,
  containerTypeParams,
} from 'core/form/filterForm/components/selects/ContainerTypeFilter';
import {
  GrbsIdFilter,
  grbsIdParams,
} from 'core/form/filterForm/components/selects/GrbsId.Filter';
import {
  OkrugFilter,
  okrugParams,
} from 'core/form/filterForm/components/selects/OkrugFilter';
import {
  IdOrNameFilter,
  idOrNameParams,
} from 'core/form/filterForm/components/text/IdOrName.Filter';
import {
  IdOrNameParentFilter,
  idOrNameParentParams,
} from 'core/form/filterForm/components/text/IdOrNameParent.Filter';
import {
  InYardFilter,
  inYardParams,
} from 'core/form/filterForm/components/yesOrNot/InYardFilter';
import {
  ParentNameFilter,
  parentNameParams,
} from 'core/form/filterForm/components/yesOrNot/ParentNameFilter';
import { useFilterQueryParams } from 'core/form/filterForm/hooks/useFilterQueryParams';
import React from 'react';

/**
 * @typedef {object} FilterQueryParamsType - Данные QueryParams для фильтра формы FF.
 * @property {object} prepareFilters - Отформатированые данные для бэка.
 * @property {Function} setFilters - Функция для переноса состояния формы в урл.
 * @property {object} filters - Обычные данные формы.
 */

/**
 * Хук QueryParams для фильтра __Места сбора отходов__.
 *
 * @returns {FilterQueryParamsType} Фильтры.
 * @example --------
 * const { setFilters, filters, prepareFilters } = useFilterQueryParamsBoardStone()
 */
export const useFilterQueryParamsContainer = () => {
  return useFilterQueryParams([
    addressParams,
    containerTypeParams,
    customerParams,
    dateFromParams,
    dateToParams,
    grbsIdParams,
    idOrNameParams,
    idOrNameParentParams,
    inYardParams,
    oghStatusesParams,
    okrugParams,
    ownerIdParams,
    parentNameParams,
  ]);
};

/**
 * Filter реестров __Места сбора отходов__.
 *
 * @param {object} params - Parameters.
 * @param {number} params.parentTypeId - Id типа родителя реестра.
 * @returns {JSX.Element} - JSX.
 * @example ---
 */
export const FilterContainer = ({ parentTypeId }) => {
  const { setFilters, filters } = useFilterQueryParamsContainer();
  const { isChildOfImprovementObject } =
    useCheckingItIsChildOfImprovementObject(parentTypeId);

  /**
   * Функция submit фильтра формы FF.
   *
   * @param {object} values - Values.
   * @returns {void} - Nothing.
   * @example ---
   */
  const onSubmit = async (values) => {
    setFilters(values, 'push');
  };

  /**
   * Функция сброса формы FF.
   *
   * @param {object} form - Параметры формы.
   * @param {Function} form.reset - Функция сброса формы FF.
   * @returns {void} - Nothing.
   * @example ---------
   */
  const reset = (form) => {
    form.reset();
    setFilters({}, 'replace');
  };
  return (
    <FilterFF {...{ initialValues: filters, onSubmit, reset }}>
      <IdOrNameFilter label={'ID'} />
      <IdOrNameParentFilter />
      <OghStatusesFilter />
      <DateFromFilter />
      <DateToFilter />
      <AddressFilter />
      <ContainerTypeFilter />
      <ParentNameFilter />
      <InYardFilter />
      <GrbsIdFilter hidden={isChildOfImprovementObject} />
      <OkrugFilter />
      <CustomerFilter />
      <OwnerIdFilter />
    </FilterFF>
  );
};
