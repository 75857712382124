import { getCurrentDate } from 'app/utils/date/getCurrentDate';
import { ContinueFFDialog } from 'core/form/finalForm/components/dialogs/ContinueFF.Dialog';
import { Validator } from 'core/form/Validator';
import { REAGENT_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/Reagent';
import { REAGENT_KIND_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/ReagentKind';

import { useSend } from '../api';
import { BodyDialogShipment } from './Body.Dialog.Shipment';

/**
 * Validates the Отпуск ПГР.
 *
 * @param {Record<string, *>} values - The values to validate.
 * @returns {Record<string, string>}
 */
export const formValidation = (values) => {
  const validator = new Validator(values);

  validator.setRequired('documentNum');
  validator.setRequired('quantity');

  validator.setRequired(REAGENT_NAME);
  validator.setRequired(REAGENT_KIND_NAME);

  return validator.getErrors();
};

/**
 * Dialog the Отпуск ПГР.
 *
 * @param {Record<string, *>} props - The Property.
 * @param {boolean} props.isOpen - Флаг отвечающий за показ диалогового окна.
 * @param {Function} props.onClose - Функция закрытия.
 * @param {Record<string, *>} [props.initialValues] - Начальные данные для формы.
 * @returns {JSX.Element}
 */
export const DialogShipment = ({ isOpen, onClose, initialValues = {} }) => {
  const { onSubmit, isLoading } = useSend('shipment', onClose);

  return (
    <ContinueFFDialog
      isOpen={isOpen}
      textHeader={'Отпуск ПГР'}
      onSubmit={onSubmit}
      validate={formValidation}
      closeDialog={onClose}
      isLoading={isLoading}
      initialValues={{
        carCustomer: null,
        consignee: {
          id: initialValues.consigneeId,
          name: initialValues.consigneeName,
        },
        ...initialValues,
        govNumber: {
          id: initialValues.govNumberId,
          name: initialValues.govNumber,
        },
        targetDateTime: getCurrentDate(),
      }}
      Body={<BodyDialogShipment />}
    />
  );
};
