import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { OghStatus } from 'core/uiKit/preparedInputs';

/**
 * Input status.
 *
 * @returns {JSX}
 */
export const OghStatusComponent = () => {
  const { logicalStateId } = useGetCurrentCard();
  const { editMode } = useEditMode();

  const value = editMode ? 2 : logicalStateId || 2;
  return (
    <OghStatus
      {...{
        disabled: true,
        value,
      }}
    />
  );
};
