import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import ToggleButton from 'app/components/card/common/MapEditPanel/buttons/ToggleButton';
import toggleContent from 'app/components/card/common/MapEditPanel/content/toggle';
import { useMapContext } from 'app/components/map/useMapContext';
import { INTERSECTIONS } from 'app/constants/layers';
import { useMapEdit } from 'app/pages/cardsOgh/components/Map/useMapEdit';
import { usePreloaderCard } from 'app/pages/cardsOgh/components/PreloadedCard/usePreloaderCard';
import { getAttributeForm } from 'app/pages/cardsOgh/helpers/getAttributeForm';
import { useContains } from 'app/pages/cardsOgh/hooks/useContains';
import { useFormState } from 'react-final-form';

/**
 * Кнопка проверки пересечения геометрии.
 *
 * @returns {JSX.Element}
 */
export const CheckGeometryButton = () => {
  const { hasIntersections, setHasIntersections } = useMapEdit();
  const { startPreload } = usePreloaderCard();
  const { checkGeometryContains } = useContains();
  const { getGeometry, isMapLoaded, clearLayer } = useMapContext();
  const { parentId, typeId, startDate, recordId, rootId } = useGetCurrentCard();
  const form = useFormState();
  const parent = useGetParentCard();

  /**
   * Функция проверки.
   *
   * @returns {void}
   */
  const checkGeometryButton = async () => {
    if (!isMapLoaded) {
      return;
    }
    const geometry = getGeometry(recordId);

    const data = {
      attribute: getAttributeForm(typeId, form.values),
      cnt: null,
      geometry,
      id: rootId,
      journal: null,
      parentId: parentId ? Number(parentId) : null,
      startDate,
      treeParentId: parent.recordId,
      typeId: Number(typeId),
    };

    if (hasIntersections) {
      clearLayer(INTERSECTIONS);
      setHasIntersections(false);
    } else {
      startPreload();
      await checkGeometryContains(data);
    }
  };

  return (
    <ToggleButton
      disabled={!isMapLoaded}
      active={hasIntersections}
      content={toggleContent.intersections}
      onClick={checkGeometryButton}
    />
  );
};
