import { renderHiddenInput } from 'app/components/card/common/grid/index';
import { ReasonFilter } from 'app/components/dialogs/common/filters/Reason.Filter';
import KeyboardDatePickerRF from 'core/form/reduxForm/fields/default/datePickers/KeyboardDatePickerRF';
import { TextFieldRF } from 'core/form/reduxForm/fields/default/TextFieldRF';
import { ReasonRF } from 'core/form/reduxForm/fields/prepared/selects/simple/Reason.RF/ReasonRF';
import { TextField } from 'core/uiKit/inputs/TextField';
import { useNavigateRouter } from 'core/utils/router';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { reset } from 'redux-form';

import { readyForUpdateCard } from '../../../actions/odsObjectActions';
import DialogAction from './DialogAction';
import DialogAlert from './DialogAlert';

const propTypes = {
  // иногда не надо показывать DialogAlert после операции удаления, по умолчанию - нужно
  handleSubmit: PropTypes.func,
  isMatching: PropTypes.bool,
  mode: PropTypes.object,
  onAlertHide: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onSubmitDraft: PropTypes.func,
  showDialogAlert: PropTypes.func,
  statusId: PropTypes.number,
};

/**
 * DialogDelete.
 *
 */
class DialogDelete extends React.Component {
  //

  /**
   * RenderTextField..
   *
   * @param {*} props - The props.
   * @returns {JSX.Element}
   */
  renderTextField = (props) => <TextField {...props} {...props.input} />;

  /**
   * Render.
   *
   * @returns {JSX.Element}
   */
  render() {
    const {
      dispatch,
      endDate,
      handleSubmit,
      isMatching,
      mode,
      statusId,
      onCancel,
      onAlertHide,
      onSubmit,
      onSubmitDraft,
      history,
      fetchOgh = (_) => _,
      onResetForm,
      showDialogAlert = true,
    } = this.props;

    return (
      <React.Fragment>
        <DialogAlert
          showCondition={
            showDialogAlert &&
            mode &&
            (mode.errorDeleteMode || mode.doneDeleteMode)
          }
          onAfterHiding={
            mode && mode.doneDeleteMode
              ? () => {
                  fetchOgh();
                  if (mode && mode.relocate) {
                    history.push(mode.relocate);
                  }
                  dispatch(readyForUpdateCard());
                }
              : () => {
                  fetchOgh();
                  onAlertHide();
                }
          }
        >
          {mode?.message ?? ''}
        </DialogAlert>

        <DialogAlert
          showCondition={isMatching && mode && mode.deleteMode}
          onAfterHiding={onAlertHide}
        >
          Объект на согласовании
        </DialogAlert>

        <DialogAction
          className="Dialog-Form-Delete"
          okText="Удалить"
          showCondition={(!isMatching && mode && mode.deleteMode) || false}
          title={statusId === 1 ? 'Удаление' : undefined}
          onCancel={() => {
            onCancel();
            onResetForm();
          }}
          onOk={handleSubmit(statusId === 1 ? onSubmit : onSubmitDraft)}
        >
          <form id="delete-form" name="deleteForm">
            <ShowDetails show={statusId === 1}>
              <React.Fragment>
                <KeyboardDatePickerRF
                  id="startDelete"
                  label="Дата завершения"
                  maxDate={maxDate(endDate)}
                  minDate={minDate(endDate)}
                  name="startDate"
                />

                <ReasonFilter
                  privilegeCode={'del_ogh'}
                  Component={ReasonRF}
                  withAll={false}
                  className={'mt-3'}
                />

                <TextFieldRF
                  id="comment"
                  key={'comment'}
                  label="Комментарий"
                  name="comment"
                />
              </React.Fragment>
            </ShowDetails>
            <div className="hidden-block">
              <Field
                component="input"
                hidden={true}
                name="statusId"
                type="number"
                value={statusId}
              />
              <Field component={renderHiddenInput} id="record_id" name="id" />
              <Field component={renderHiddenInput} id="type_id" name="type" />
            </div>
          </form>
        </DialogAction>
      </React.Fragment>
    );
  }
}

DialogDelete.propTypes = propTypes;

/**
 * ShowDetails.
 *
 * @param {*} root0 - A.
 * @param {*} root0.show - A.
 * @param {*} root0.children - A.
 * @returns {*}
 */
function ShowDetails({ show, children }) {
  return show ? (
    children
  ) : (
    <div className="message-block">{'Удалить версию объекта?'}</div>
  );
}

/**
 * MinDate.
 *
 * @returns {*}
 */
const minDate = () => {
  const today = new Date();
  today.setDate(today.getDate() + 1);
  const minDate = removeHoursAndLess(today);
  return minDate;
};

/**
 * MaxDate.
 *
 * @param {*} endDateString - A.
 * @returns {*}
 */
const maxDate = (endDateString) => {
  if (endDateString) {
    const endDate = parseDate(endDateString);
    endDate.setDate(endDate.getDate() - 1);
    const maxDate = removeHoursAndLess(endDate);
    return maxDate;
  }
  return new Date();
};

/**
 * RemoveHoursAndLess.
 *
 * @param {*} date - A.
 * @returns {Date}
 */
const removeHoursAndLess = (date) => {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

/**
 * ParseDate.
 *
 * @param {*} string - A.
 * @returns {Date}
 */
const parseDate = (string) => {
  let result = null;
  if (string) {
    const date = string.split('.');
    result = new Date(date[2], date[1] - 1, date[0]);
  }
  return result;
};

/**
 * MapDispatchToProps.
 *
 * @param {*} dispatch - A.
 * @returns {*}
 */
const mapDispatchToProps = (dispatch) => ({
  //

  /**
   * OnResetForm.
   *
   * @returns {*}
   */
  onResetForm: () => dispatch(reset('deleteCard')),
});

/**
 * MapStateToProps.
 *
 * @param {*} state - State.
 * @param {*} props - Props.
 * @returns {*}
 */
const mapStateToProps = (state, props) => ({
  enableReinitialize: true,
  endDate: (state.tree.object || state.card.object).end_date,
  initialValues:
    props.statusId === 1
      ? {
          id: (state.tree.object || state.card.object).record_id,
          reason: null,
          startDate: minDate((state.tree.object || state.card.object).end_date),
          type: (state.tree.object || state.card.object).type_id,
        }
      : {
          id: (state.tree.object || state.card.object).record_id,
          type: (state.tree.object || state.card.object).type_id,
        },
  reason: (state.tree.object || state.card.object).reason,
});

const Card = connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'deleteCard' })(DialogDelete));

// todo: костыль на время. создан когда была переделка на новый роут.
//   нужно переделать на переменые из роута

/**
 * A.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export default (props) => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigateRouter();

  /**
   * @deprecated
   * @see используй navigate
   */
  const history = { push: navigate };
  return (
    <Card
      {...{ currentLocation: location, history, location, navigate, params }}
      {...props}
    />
  );
};
