import getDict from 'app/selectors/getDict';
import { uniq } from 'lodash';
import { createSelector } from 'reselect';

import getReagentStorageCapacityList from './getReagentStorageCapacityList';

 
export default createSelector(
  [getReagentStorageCapacityList, getDict('reagentKinds')],
  (tableRecords, reagentKinds) => {
    const reagentKindIdsToInclude = uniq(
      tableRecords.map((item) => item.reagent_kind_id),
    );

    return reagentKinds.filter((item) =>
      reagentKindIdsToInclude.includes(item.id),
    );
  },
);
