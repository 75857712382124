import { DeleteButtonCellRepairsTab } from './Delete.Button.Cell.Repairs.Tab';
import { getColumnsTableRepairsTab } from './getColumns.Table.Repairs.Tab';

/**
 * Mock Default.
 *
 * @returns {void}
 */
const mockDefault = () => {};

describe('🐛 spec getColumns.Table.Repairs.Tab.spec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock

    //🔥 Act
    const res = getColumnsTableRepairsTab();

    //❓ Assert
    expect(res).toStrictEqual(cells);
  });
});

const cells = [
  {
    Cell: DeleteButtonCellRepairsTab,
    Header: '',
    accessor: 'id_delete',
  },
  {
    Header: '№ п/п',
    accessor: 'end_to_end',
  },
  {
    Header: 'Наименование',
    accessor: 'name',
  },
  {
    Header: 'Дата',
    accessor: 'date',
  },
  {
    Header: 'Характеристика (объемы)',
    accessor: 'property',
  },
  {
    Header: 'Организация, проводившая работы',
    accessor: 'contract_organization',
  },
  {
    Header: 'Гарантийные сроки',
    accessor: 'guarantee_date',
  },
];
