/* eslint-disable */
import React from 'react';

import { YardCard } from './YardCard';
import { ImprovementObjectCard } from './ImprovementObjectCard';
import { OznCard } from './OznCard';
import { SpaCard } from './SpaCard';

import { IMPROVEMENT_OBJECT, OZN, SPA, YARD } from 'app/constants/oghTypes';

const map = {
  [IMPROVEMENT_OBJECT]: ImprovementObjectCard,
  [YARD]: YardCard,
  [OZN]: OznCard,
  [SPA]: SpaCard,
};

const ImprovementTerritoryCard = (props) => {
  const { type_id: typeId } = props.card;
  const Component = map[typeId];

  return Component ? <Component {...props} /> : null;
};

 
export default ImprovementTerritoryCard;
