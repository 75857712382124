import * as ordersActions from 'app/actions/orders';


/* eslint-disable */
export default function (result) {
  return {
    ...result,
    toggleDetails: ordersActions.toggleOghOrderDetails,
    toggleGeometry: ordersActions.toggleOghOrderGeometry,
    resetOrdersGeometry: ordersActions.resetOrdersGeometry,
  };
}
