import { faFileSignature } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/* eslint-disable */
import { useGetVisibilityButtonsOgh } from 'app/api/hooks/useGetVisibilityButtonsOgh';
import { Button } from 'core/uiKit/components/buttons/Button';
import PropTypes from 'prop-types';

/**
 * Кнопка Обновить дату утверждения (ЭП).
 *
 * @param {*} root0 - Параметры.
 * @param {*} root0.objectId - Id версии ОГХ.
 * @returns {JSX.Element}
 */
export const ButtonSigningOnRenewalOfApprovalDate = ({
  objectId,
  ...props
}) => {
  const { data: { reapprove_eds: reApproveEds } = {} } =
    useGetVisibilityButtonsOgh(objectId);

  return reApproveEds ? (
    <Button
      {...props}
      color={'success'}
      variant={'contained'}
      startIcon={<FontAwesomeIcon icon={faFileSignature} />}
    >
      Обновить дату утверждения (ЭП)
    </Button>
  ) : null;
};

ButtonSigningOnRenewalOfApprovalDate.propTypes = {
  /**
   * Id версии ОГХ.
   */
  objectId: PropTypes.number.isRequired,
};
