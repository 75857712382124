import {
  GOV_NUMBER_NAME_NAME,
  GovNumberNameFF,
} from 'core/form/finalForm/fields/prepared/selects/autocompleteAsync/GovNumberName.FF';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';
import { ObjectParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

/**
 * Рег. Номер
 * govNumberName.
 *
 * @param {object} props - Параметры компонента.
 * @returns {JSX.Element}
 */
export const GovNumberNameFilter = (props) => {
  return <GovNumberNameFF {...props} className={filterInput} />;
};

export { GOV_NUMBER_NAME_NAME };

/**
 * GovNumberNameParams.
 *
 * @returns {object}
 */
export const govNumberNameParams = () => {
  addPreparation(GOV_NUMBER_NAME_NAME, (value) => value.name);
  return {
    [GOV_NUMBER_NAME_NAME]: ObjectParam,
  };
};
