import { Skeleton } from 'core/uiKit/components';
import React from 'react';

/**
 * Функция получения рандомного числа.
 *
 * @param {number} min - Минимальное число.
 * @param {number} max - Максимальное число.
 *
 * @returns {number}
 */
function randomInteger(min, max) {
  let rand = min + Math.random() * (max + 1 - min);
  return Math.floor(rand);
}

/**
 * Лоудер для Элемента списка при скролле.
 *
 * @returns {React.JSX}
 */
export const SkeletonItemTreeMainPage = () => {
  const bgcolor = '#5b5b5b';
  return (
    <div className={'w-100 d-flex align-items-center mx-2 my-2'}>
      <Skeleton variant="rectangular" width={25} height={25} sx={{ bgcolor }} />
      <div className={'w-100 d-flex flex-column justify-content-center'}>
        <Skeleton
          variant="rectangular"
          width={`${50 + randomInteger(1, 30)}%`}
          height={14}
          className={'my-1'}
          sx={{ bgcolor, display: 'block', marginLeft: '.5rem' }}
        />
        <Skeleton
          className={'my-1'}
          variant="rectangular"
          width={`${60 + randomInteger(1, 30)}%`}
          height={14}
          sx={{ bgcolor, marginLeft: '.5rem' }}
        />
      </div>
    </div>
  );
};
