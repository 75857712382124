 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Однолетники, кв.м__.
 *
 * @type {{
 * accessor: 'annuals_area',
 * Header: 'Однолетники, кв.м',
 * }}
 * @augments Cell
 */
export const annuals_area = {
  accessor: 'annuals_area',
  Header: 'Однолетники, кв.м',
  sortable: true,
};
