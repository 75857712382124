import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';

import { useSetupArrangeElementTypeIdField } from './useSetup.ArrangeElementTypeId.Field';

/**
 * Тип (наименование) Select RF.
 *
 * @returns {JSX.Element}
 */
export const ArrangeElementTypeIdField = () => {
  const { disabled, options } = useSetupArrangeElementTypeIdField();
  return (
    <SelectRF
      name="arrange_element_type_id"
      label="Тип (наименование)"
      withAll={false}
      disabled={disabled}
      options={options}
      required={true}
    />
  );
};
