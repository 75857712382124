import {
  FolderIcon,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from 'core/uiKit/material-ui';
import React from 'react';

interface File {
  file_id: string;
  file_name: string;
}

interface FileListInterface {
  files: { file_id: string; file_name: string }[];

  /**
   * Флаг редактирования.
   */
  disabled?: boolean;
  onChange: (files: File[]) => void;
}

/**
 * Список файлов с удалением.
 *
 * @param {FileListInterface} props - Пропсы.
 * @param props.files - Файлы.
 * @class
 */
export const FileList = ({ files, disabled, onChange }: FileListInterface) => {

  /**
   * Функция удаления файла по id.
   *
   * @param fileId - Id файла.
   * @returns {void}
   */
  const deleteFile = (fileId: string) => {
    const fileList = files.filter(({ file_id }) => file_id !== fileId);
    onChange(fileList);
  };

  return (
    <List dense>
      {(files || []).map((item) => (
        <ListItem key={item.file_id}>
          <ListItemIcon>
            <FolderIcon />
          </ListItemIcon>
          <ListItemText>
            <a href={`/api/files/download/${item.file_id}`} download>
              {item.file_name}
            </a>
          </ListItemText>
          {disabled ? null : (
            <IconButton
              data-testId={`deleteIconList-${item.file_id}`}
              className={''}
              onClick={() => deleteFile(item.file_id)}
              title="Удалить файл"
              size={'small'}
            >
              <Icon color={'action'}>delete</Icon>
            </IconButton>
          )}
        </ListItem>
      ))}
    </List>
  );
};
