import { shallow } from 'enzyme';
import React from 'react';
import { vi } from 'vitest';

import MapDiff from './MapDiff';

vi.mock('app/components/map/withMap', () => ({
  WithMapContext: vi.fn(),
  default: vi.fn((comp) => comp),
}));

/**
 * Mock Default.
 *
 * @returns {void}
 */
const mockDefault = () => {
  // withMap.mockImplementation((comp) => comp);
};
describe('🐛 spec MapDiff', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(
      <MapDiff
        {...{
          //

          /**
           *Метод жизненного цикла render.
           *
           * @returns {JSX.Element}
           */
          Map: () => <div>map</div>,
          copy: {},
          etalon: {},
          geodiff: {},
          isMapLoaded: true,
        }}
      />,
    );

    //❓ Assert
    expect(wrapper.text()).toBe('map<LegendMapDiff />');
  });
});
