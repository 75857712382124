import { useChangeFieldValueRF } from 'app/actions/odsObjectActions/useChangeFieldValueRF';
import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import React from 'react';

export const MAF_TYPE_LEVEL_3_ID_NAME = 'maf_type_level3_id';

/**
 * Поле для МАФ __Детализация__.
 *
 * @returns {React.ReactElement}
 */
export const MafTypeLevel3Field = () => {
  const { typeId: parentTypeId } = useGetParentCard();
  const { editMode } = useMode();
  const changeFieldValue = useChangeFieldValueRF();
  const { data } = useGetDictionary('maf_type_level3');

  const mafTypeLevel3 = (data || []).map((item) => {
    return {
      id: item.id,
      name: item.attribute.name,
      parent_ogh_object_type_list: item.attribute.parent_ogh_object_type_list,
      unit_id: item.attribute.unit_id,
    };
  });

  /**
   * Функция происходящая во время изменения.
   *
   * @param {number} mafTypeLevel3Id - Id Детализации.
   */
  const withChange = (mafTypeLevel3Id) => {
    const mafTypeObject = (mafTypeLevel3 || []).find(
      (item) => item.id === mafTypeLevel3Id,
    );
    const unitId = mafTypeObject ? mafTypeObject.unit_id : null;
    changeFieldValue('unit_id', unitId);
  };

  return (
    <SelectRF
      name={MAF_TYPE_LEVEL_3_ID_NAME}
      label={'Детализация'}
      options={
        parentTypeId
          ? mafTypeLevel3?.filter(({ parent_ogh_object_type_list = [] }) =>
              parent_ogh_object_type_list?.includes(parentTypeId),
            )
          : mafTypeLevel3
      }
      disabled={!(editMode && mafTypeLevel3 && mafTypeLevel3.length)}
      withChange={withChange}
    />
  );
};
