import { RANDOM_BOOL, RANDOM_ID } from 'core/forTesting/constants';
import { shallow } from 'enzyme';

import { useList } from './api/useGetList';
import { TableList } from './Table.List';

jest.mock('./api/useGetList');
jest.mock('core/uiKit/components/tables/Table');

/* eslint-disable */
describe('file Table.List.spec', () => {
  it('should text', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useList.mockReturnValue({});
    const CODE = 'code_' + RANDOM_ID;
    const COLUMNS = 'columns_' + RANDOM_ID;
    // Act
    const wrapper = shallow(<TableList code={CODE} columns={COLUMNS} />);
    // Assert
    expect(wrapper.text()).toBe('<Table />');
  });

  it('useList вызывается с code', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useList.mockReturnValue({});
    const CODE = 'code_' + RANDOM_ID;
    const COLUMNS = 'columns_' + RANDOM_ID;

    // Act
    shallow(<TableList code={CODE} columns={COLUMNS} />);

    // Assert
    expect(useList).toHaveBeenCalledWith(CODE);
  });

  it('prop', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const CODE = 'code_' + RANDOM_ID;
    const COLUMNS = 'columns_' + RANDOM_ID;
    const TABLE = 'table_' + RANDOM_ID;
    const IS_LOADING_TABLE = RANDOM_BOOL;
    const COUNT = RANDOM_ID;

    useList.mockReturnValue({
      table: [TABLE],
      isLoadingTable: IS_LOADING_TABLE,
      count: COUNT,
    });

    // Act
    const wrapper = shallow(<TableList code={CODE} columns={[COLUMNS]} />);

    // Assert
    expect(wrapper.prop('columns')).toStrictEqual([COLUMNS]);
    expect(wrapper.prop('data')).toStrictEqual([TABLE]);
    expect(wrapper.prop('isLoading')).toBe(IS_LOADING_TABLE);
    expect(wrapper.prop('total')).toBe(COUNT);
  });
});
