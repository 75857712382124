import { Table, useParamsTable } from 'core/uiKit/components/tables/Table';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';
import React from 'react';

import * as api from './api';
import { columnsEquipmentReport } from './columns.EquipmentReport';
import { useFilterQueryParamsEquipmentReport } from './Filter.EquipmentReport';

/**
 * A.
 *
 * @returns {JSX.Element}
 */
export const TableEquipmentReport = () => {
  const { prepareFilters } = useFilterQueryParamsEquipmentReport();
  const { queryTable } = useParamsTable();

  const { data: table = [], isLoading } = useQueryAdaptor(
    ['fetchEquipmentReport', { ...prepareFilters, ...queryTable }],
    api.fetchEquipmentReport,
  );

  const { data: { count } = {} } = useQueryAdaptor(
    [
      'fetchEquipmentReportCount',
      {
        ...prepareFilters,
        ...queryTable,
      },
    ],
    api.fetchEquipmentReportCount,
  );

  return (
    <Table
      columns={columnsEquipmentReport}
      data={table || []}
      isLoading={isLoading}
      total={count}
    />
  );
};
