import { useSendDocumentData } from 'app/pages/cardsOgh/components/tables/Documents/hooks/useSendDocumentData';
import { getStartDate } from 'app/utils/dateUtils';
import { Dialog } from 'core/uiKit/components/dialogs/Dialog';
import React from 'react';

import { DialogBody } from './DialogBody';
import { validate } from './validate';

/**
 * Диалог добавления файлов в таблицу документов.
 *
 * @param {object} props - Properties.
 * @param {string} props.textHeader - Заголовок диалогового окна.
 * @param {boolean} props.open - Флаг открытия\закрытия.
 * @param {Function} props.cancelDialog - Функция отмены.
 * @param {string|number} props.typeId - Id типа.
 * @param {Function} props.onChange - Функция выбора.
 * @param {Array} props.dataTable - Таблица данных.
 * @param {Array<string>} props.filesFormat - Массив разрешенных файлов.
 * @param {object} props.otherProps - Другие пропсы.
 * @returns {JSX.Element}
 */
export const AddFileDocumentDialog = ({
  textHeader,
  open,
  cancelDialog,
  typeId,
  onChange,
  dataTable,
  filesFormat,
  ...otherProps
}) => {
  const { sendDocumentData, isLoading } = useSendDocumentData(
    cancelDialog,
    onChange,
    dataTable,
  );

  /**
   * Функция submit формы.
   *
   * @param {object} values - Значения формы.
   * @returns {Record<string, string>|null|void}
   */
  const onSubmit = (values = {}) => {
    const errors = validate(values, filesFormat);

    if (errors) {
      return errors;
    }

    const additional = {
      comment: values.comment,
      create_date: getStartDate(),
      type_id: typeId,
    };
    const file = values.files[0];
    return sendDocumentData({
      additional,
      file,
    });
  };

  return (
    <Dialog
      maxWidth={'sm'}
      isOpen={open}
      textHeader={textHeader}
      Body={
        <DialogBody
          onSubmit={onSubmit}
          isLoading={isLoading}
          cancelDialog={cancelDialog}
          filesFormat={filesFormat}
          {...otherProps}
        />
      }
    />
  );
};
