import { ContextDialog } from 'app/pages/cardsOgh/components/DIalogs/Dialogs';
import { Button } from 'core/uiKit/components/buttons/Button';
import { Dialog } from 'core/uiKit/components/dialogs/Dialog';
import React, { useContext } from 'react';

/**
 * GeometryWarningMessage.
 *
 * @returns {JSX.Element}
 */
export const GeometryWarningMessage = () => {
  const { geometryWarningDialog, setGeometryDialog } =
    useContext(ContextDialog);

  /**
   * Close Dialog.
   *
   * @returns {void}
   */
  const closeDialog = () => {
    setGeometryDialog(false);
  };

  return (
    <Dialog
      isOpen={geometryWarningDialog}
      maxWidth={'sm'}
      textHeader={'Не указана геометрия объекта'}
      Body={
        <>
          <p>Отобразите геометрию ОГХ перед сохранением.</p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              color={'primary'}
              variant={'contained'}
              onClick={closeDialog}
            >
              Закрыть
            </Button>
          </div>
        </>
      }
    />
  );
};
