 
/**
 * Перевод данных для поля OznTypeField.
 *
 * @param {{ozn_type_id:number}} formValues - Значение поля из formValues.
 * @returns {{ozn_type_id: {ozn_type: number}}} - Переведенные данные.
 * @example -
 * function createAttribute(formValues) {
 *   return {
 *     ...createdOznTypeAttribute(formValues),
 *   };
 * }
 */
export function createdOznTypeAttribute({ ozn_type_id }) {
  return {
    ozn_type_id: { ozn_type: ozn_type_id },
  };
}
