import { useState } from 'react';

import { useTableVersion } from './hooks/useTableVersion';

/**
 * Setup Versions.
 *
 * @returns {object}
 */
export const useSetupVersions = () => {
  const { table, submitSearch, resetTable, isLoading } = useTableVersion();

  const [openDialog, setOpenDialog] = useState(false);
  const [rows, setRows] = useState([]);
  const [warningMessage, setWarningMessage] = useState('');

  /**
   * Функция сравнения.
   *
   * @returns {void} - Nothing.
   * @example ------
   */
  const startComparison = () => {
    if (rows.length !== 2) {
      setWarningMessage('Выберите ровно 2 версии для сравнения');
      return;
    }
    setWarningMessage('');
    setOpenDialog(true);
  };

  /**
   * Функция закрыть диалоговое окно сравнения.
   *
   * @returns {void} - Nothing.
   * @example ----------
   */
  const closeDialog = () => {
    setOpenDialog(false);
  };

  /**
   * Функция выбора колонок.
   *
   * @param {object} props - Параметры.
   * @param {object} props.selectedFlatRows - Объект колонки.
   * @returns {void} - Nothing.
   * @example -----------
   */
  const selectedRow = ({ selectedFlatRows }) => {
    const preparedRows = selectedFlatRows.map(({ original }) => original);

    setRows(preparedRows);
  };

  return {
    closeDialog,
    isLoading,
    openDialog,
    resetTable,
    rows,
    selectedRow,
    startComparison,
    submitSearch,
    table,
    warningMessage,
  };
};
