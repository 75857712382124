import { COMMON_LOGIN_FORM } from 'config/env';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React, { FC } from 'react';
import { SelectProps } from 'types/inputs';

export const AUTH_URLS_NAME = 'authUrls';

/**
 * Select урлов для авторизации.
 *
 * @param props - Properties.
 * @returns {JSX}
 */
export const AuthUrls: FC<SelectProps> = (props) => {
  const isShow = COMMON_LOGIN_FORM;
  const options = [
    {
      id: '/login',
      name: 'Реестр объектов',
    },
    {
      id: 'path_api_1.0/udo-ppi/j_spring_security_check',
      name: 'Учет подъемных устройств для инвалидов',
    },
    {
      id: 'path_api_1.0/udo-odh/j_spring_security_check',
      name: 'АРМ «Учет ОДХ»	',
    },
    {
      id: 'path_api_1.0/udo-dept/j_spring_security_check',
      name: 'АРМ «Департамент»',
    },
    {
      id: 'path_api_1.0/udo-pref/j_spring_security_check',
      name: 'АРМ «Префектура»',
    },
    {
      id: 'path_api_1.0/udo-cust/j_spring_security_check',
      name: 'АРМ «Заказчик»',
    },
    {
      id: 'path_api_1.0/udo-contr/j_spring_security_check',
      name: 'АРМ «Подрядчик»',
    },
    {
      id: 'path_api_1.0/load-ssp/j_spring_security_check',
      name: 'АРМ «Загрузка данных ССП»',
    },
    {
      id: 'path_api_1.0/udo-yard/j_spring_security_check',
      name: 'АРМ «Учет дворовых территорий»',
    },
    {
      id: 'path_api_1.0/pnt/j_spring_security_check',
      name: 'АРМ «Навигация»',
    },
    {
      id: 'path_api_1.0/garbage/j_spring_security_check',
      name: 'АРМ «Контроль обработки отходов»',
    },
    {
      id: 'path_api_1.0/monitor/j_spring_security_check',
      name: 'АРМ «Мониторинг»',
    },
    {
      id: 'path_api_1.0/udo-telemetry/j_spring_security_check',
      name: 'АРМ «Телеметрический контроль»',
    },
    {
      id: 'path_api_1.0/udo-weather/j_spring_security_check',
      name: 'АРМ «Метеоданные»',
    },
    {
      id: 'login',
      name: 'АРМ «Администрирование»',
    },
  ];
  return isShow ? (
    // @ts-ignore
    <Select
      {...props}
      options={options}
      withAll={false}
      label={'АРМ'}
      name={AUTH_URLS_NAME}
    />
  ) : null;
};
