import { toMsk77 } from './toMsk77';

/**
 * Преобразование координат полигона из geoJSON в мск77.
 *
 * @param polygons - Координаты полигона.
 * @returns Координаты полигона Array<number>[][].
 */
export const polygonToMsk77 = (polygons: Array<number>[][]) => {
  return polygons.map((polygon) =>
    polygon.map((coordinates) => toMsk77(coordinates)),
  );
};
