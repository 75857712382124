import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { shallow } from 'enzyme';
import React from 'react';
import { vi } from 'vitest';

import { LawnArea } from './LawnArea';

vi.mock('app/api/hooks/useGetCurrentCard');

describe('🐛 spec LawnArea', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    useGetCurrentCard.mockReturnValue({
      calc_attribute: {},
    });

    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(<LawnArea />);

    //❓ Assert
    expect(wrapper.text()).toBe('<GroupContainer />');
  });
});
