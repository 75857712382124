/* eslint-disable */
import React, { useMemo } from 'react';
import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';

 
export const ReasonFilter = ({ Component, privilegeCode, ...otherProps }) => {
  const { typeId } = useGetCurrentCard();

  const filter = useMemo(() => {
    return (dict) => {
      return dict.reduce((arr, item) => {
        const { privilege_code, ogh_object_type_list } = item;

        if (privilege_code === privilegeCode && ogh_object_type_list) {
          const mapOgh = ogh_object_type_list.map(
            (item) => item.ogh_object_type_id.ogh_object_type,
          );
          return mapOgh.includes(typeId) ? [...arr, item] : arr;
        }
        return arr;
      }, []);
    };
  }, [typeId, privilegeCode]);

  return <Component filter={filter} {...otherProps} />;
};
