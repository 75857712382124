import { CAR_CUSTOMERS_NAME, CarCustomersFF } from 'core/form/finalForm/fields';
import { objectInArrayParam } from 'core/utils/hooks';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';
import { withDefault } from 'use-query-params';

import { filterInputAutocomplete } from '../filterInputsClasses';

/**
 * Заказчик
 * customers.
 *
 * @returns {*}
 */
export const CarCustomersFilter = () => (
  <CarCustomersFF className={filterInputAutocomplete} />
);

/**
 * ## ID составного объекта
 * Car_customer_list_id..
 *
 * @returns {object}
 */
export const carCustomersParams = () => {
  addPreparation(CAR_CUSTOMERS_NAME, (value) => {
    return value.map((item) => item.id).join(',');
  });

  return {
    [CAR_CUSTOMERS_NAME]: withDefault(objectInArrayParam, [{}]),
  };
};
