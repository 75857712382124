 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Площадь уборки, кв.м__.
 *
 * @type {{ accessor: 'station_clean_area',  Header: 'Площадь уборки, кв.м' }}
 * @augments Cell
 */
export const station_clean_area = {
  accessor: 'station_clean_area',
  Header: 'Площадь уборки, кв.м',
  sortable: true,
};
