import { RANDOM_WORD } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { Form } from 'react-final-form';
import { create } from 'react-test-renderer';
import { vi } from 'vitest';

import { FieldSet } from './FieldSet';

vi.mock('app/api/hooks/useIsCurrentObjectIsExternalSystem');

const actualCompositionOfMovement = {
  Header: 'Фактический состав движения, проценты:',
  columns: [
    {
      Header: 'Легковые автомобили и мотоциклы, микроавтобусы',
      accessor: 'fsd_la',
      decimalScale: 2,
      name: 'fsdLa',
      rowSpan: 2,
    },
    {
      Header: 'Грузовые автомобили грузоподъемностью (включительно), т',
      columns: [
        {
          Header: 'до 2',
          accessor: 'fsd_ga_befor_2',
          decimalScale: 2,
        },
        {
          Header: 'от 2 до 6',
          accessor: 'fsd_ga_2_6',
          decimalScale: 2,
        },
        {
          Header: 'от 6 до 8',
          accessor: 'fsd_ga_6_8',
          decimalScale: 2,
        },
        {
          Header: 'от 8 до 14',
          accessor: 'fsd_ga_8_14',
          decimalScale: 2,
        },
        {
          Header: 'свыше 14',
          accessor: 'fsd_ga_after_14',
          decimalScale: 2,
        },
      ],
      name: 'trucks',
    },
    {
      Header: 'Автопоезда грузоподъемностью (включительно), т',
      columns: [
        {
          Header: 'до 12',
          accessor: 'fsd_road_trainsga_before_12',
          decimalScale: 2,
        },
        {
          Header: 'от 12 до 20',
          accessor: 'fsd_road_trainsga_12_20',
          decimalScale: 2,
        },
        {
          Header: 'от 20 до 30',
          accessor: 'fsd_road_trainsga_20_30',
          decimalScale: 2,
        },
        {
          Header: 'свыше 30',
          accessor: 'fsd_road_trainsga_after_30',
          decimalScale: 2,
        },
      ],
      name: 'roadTrains',
    },
  ],
  decimalScale: 2,
  name: 'actualCompositionOfMovement',
};

describe('file FieldSetSpec', () => {
  it('simple text', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const HEADER = RANDOM_WORD;
    const map = {
      Header: HEADER,
    };

    // Act
    const wrapper = shallow(<FieldSet map={map} />);

    // Assert
    expect(wrapper.text()).toBe(HEADER);
  });

  it('[snapshot]', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const root = create(
      <Form
        onSubmit={vi.fn()}
        initialValues={{}}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FieldSet map={actualCompositionOfMovement} />
          </form>
        )}
      />,
    );

    // Assert
    expect(root).toMatchSnapshot();
  });
});
