import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import { IsOrphanObjectRfField } from './IsOrphanObjectRF.Field';

vi.mock('app/api/hooks/useGetCurrentCard');
vi.mock('app/pages/cardsOgh/hooks/useMode');
vi.mock('app/api/hooks/useIsEditCurrentObjectIsExternalSystem');
vi.mock('app/api/hooks/useIsCurrentObjectIsExternalSystem');

/**
 * Дефолтные моки.
 *
 * @returns {void}
 */
const defaultMock = () => {
  useGetCurrentCard.mockReturnValue({});
  useMode.mockReturnValue({ editMode: true });
  useIsCurrentObjectIsExternalSystem.mockReturnValue(false);
  useIsEditCurrentObjectIsExternalSystem.mockReturnValue(false);
};

describe('IsOrphanObjectRfFieldSpec', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    defaultMock();
    // 🔥 Act

    const wrapper = shallow(<IsOrphanObjectRfField />);
    // ❓ Assert
    expect(wrapper.text()).toBe('<IsOrphanObjectRF />');
  });
  it('disabled not rootId', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    defaultMock();
    // 🔥 Act

    const wrapper = shallow(<IsOrphanObjectRfField />);
    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      disabled: false,
    });
  });
  it('disabled have rootId', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    defaultMock();
    useGetCurrentCard.mockReturnValue({ rootId: 1234 });
    // 🔥 Act

    const wrapper = shallow(<IsOrphanObjectRfField />);
    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      disabled: true,
    });
  });
});
