import { get } from 'app/api/crud';

/**
 * Функция получения данных карточки
 * на вход ид карточки
 * на выходе данные полей карточки
 * по ним можешь получить файлы для подписи.
 *
 * @param {number|string} [oghId] - Ид карточки.
 * @returns {*}
 */
export const getOghCard = (oghId) => {
  if (!oghId) {
    return {};
  }
  return get(`ogh/${oghId}`);
};
