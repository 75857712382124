import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
/* eslint-disable */
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { Field } from 'redux-form';

import { AddRow } from './AddRow';

/**
 *
 */
export const AddRowRF = () => {
  const { editMode } = useEditMode();
  const isCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();

  const visible = !isCurrentObjectIsExternalSystem && editMode;
  return visible ? (
    <Field name="trafficCharacterList" component={AddRow} />
  ) : null;
};
