import { get, post } from 'app/api/crud';
import { getCreatedCurrentCard } from 'app/api/requests/getCreatedCurrentCard';
import { getApiPath } from 'app/utils/api/config';
import { log } from 'core/utils/log';

import * as types from '../constants/actionTypes';

/**
 * A.
 *
 * @param {*} root0 - A.
 * @param {*} root0.pathId - A.
 * @param {*} root0.newExpanded - A.
 * @returns {*}
 */
export function setMapTreeExpanded({ pathId, newExpanded }) {
  return {
    newExpanded,
    pathId,
    type: types.SET_MAP_TREE_EXPANDED,
  };
}

/**
 * A.
 *
 * @param {*} root0 - A.
 * @param {*} root0.pathId - A.
 * @param {*} root0.newExpanded - A.
 * @returns {*}
 */
export function setOghTreeExpanded({ pathId, newExpanded }) {
  return {
    newExpanded,
    pathId,
    type: types.SET_OGH_TREE_EXPANDED,
  };
}

/**
 * A.
 *
 * @param {*} pathId - A.
 * @returns {*}
 */
export function setActiveOghTreeElement(pathId) {
  return {
    pathId,
    type: types.SET_ACTIVE_OGH_TREE_ELEMENT,
  };
}

/**
 * A.
 *
 * @param {*} treeData - A.
 * @returns {*}
 */
export function setMainPageTreeData(treeData) {
  return {
    object: treeData,
    type: types.SET_MAIN_PAGE_TREE_DATA,
  };
}

/**
 * A.
 *
 * @param {*} pathId - A.
 * @returns {*}
 */
export function setActiveMapTreeElement(pathId) {
  return {
    pathId,
    type: types.SET_ACTIVE_MAP_TREE_ELEMENT,
  };
}

/**
 * A.
 *
 * @returns {*}
 */
export function clearTreeElements() {
  return {
    type: types.CLEAR_TREE_ELEMENTS,
  };
}

/**
 * A.
 *
 * @returns {*}
 */
export function requestTreeSelectedElement() {
  return {
    type: types.REQUEST_TREE_SELECTED_ELEMENT,
  };
}

/**
 * A.
 *
 * @param {*} object - A.
 * @returns {*}
 */
export function receiveTreeSelectedElement(object) {
  return {
    object,
    type: types.RECEIVE_TREE_SELECTED_ELEMENT,
  };
}

/**
 * A.
 *
 * @param {*} data - A.
 * @returns {*}
 */
export function fetchTreeSelectedElement(data) {
  return (dispatch) => {
    dispatch(requestTreeSelectedElement());
    return get(`/ogh/${data.id}`).then((json) => {
      return dispatch(receiveTreeSelectedElement(json));
    });
  };
}

/**
 * A.
 *
 * @param {*} id - A.
 * @param {*} startDate - A.
 * @returns {*}
 */
export function fetchPreviewTreeSelectedElement(id, startDate) {
  return (dispatch) => {
    dispatch(requestPreviewTreeSelectedElement());
    return getCreatedCurrentCard(id, startDate).then((json) =>
      dispatch(receivePreviewTreeSelectedElement(json)),
    );
  };
}

/**
 * A.
 *
 * @returns {*}
 */
function requestPreviewTreeSelectedElement() {
  return {
    type: types.REQUEST_PREVIEW_TREE_SELECTED_ELEMENT,
  };
}

/**
 * A.
 *
 * @param {*} object - A.
 * @returns {*}
 */
function receivePreviewTreeSelectedElement(object) {
  return {
    object,
    type: types.RECEIVE_PREVIEW_TREE_SELECTED_ELEMENT,
  };
}

/**
 * A.
 *
 * @returns {*}
 */
export function treeSelectRoot() {
  return {
    type: types.TREE_SELECT_ROOT,
  };
}

/**
 * A.
 *
 * @param {*} object - A.
 * @returns {*}
 */
export function requestDiffObjectTrees(object) {
  return {
    object,
    type: types.REQUEST_DIFF_OBJECT_TREES,
  };
}

/**
 * A.
 *
 * @param {*} object - A.
 * @returns {*}
 */
export function receiveDiffObjectTrees(object) {
  return {
    object,
    type: types.RECEIVE_DIFF_OBJECT_TREES,
  };
}

/**
 * A.
 *
 * @param {*} typeId - A.
 * @param {*} etalon - A.
 * @param {*} copy - A.
 * @returns {*}
 */
export function fetchDiffObjectTrees(typeId, etalon, copy) {
  const data = { copy, etalon, typeId };
  return (dispatch) => {
    dispatch(requestDiffObjectTrees(data));
    return fetch(`${getApiPath()}/versions/treediff`, {
      body: JSON.stringify(data),
      credentials: 'include',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      method: 'POST',
    })
      .then((response) => response.json())
      .then((json) => dispatch(receiveDiffObjectTrees(json)));
  };
}

/**
 * A.
 *
 * @param {*} group - A.
 * @param {*} parent - A.
 * @param {*} tree - A.
 * @returns {*}
 */
export function toggleTreeEditVisibility(group, parent, tree) {
  return {
    group,
    parent,
    tree,
    type: types.TOGGLE_TREE_EDIT_FORM,
  };
}

/**
 * A.
 *
 * @param {*} data - A.
 * @param {*} storeLocation - A.
 * @returns {*}
 */
export function fetchOghTree(data, storeLocation) {
  return (dispatch) => {
    dispatch(requestTree(data));

    return post('/ogh/tree', data)
      .then((json) => dispatch(receiveTree(json, storeLocation || data.pathId)))
      .catch(() => dispatch(receiveTree()));
  };
}

/**
 * A.
 *
 * @param {*} data - A.
 * @returns {*}
 */
function requestTree(data) {
  return {
    data,
    type: types.REQUEST_TREE,
  };
}

/**
 * A.
 *
 * @param {*} object - A.
 * @param {*} pathId - A.
 * @returns {*}
 */
function receiveTree(object, pathId) {
  return {
    object,
    pathId,
    type: types.RECEIVE_TREE,
  };
}

/**
 * A.
 *
 * @param {*} data - A.
 * @returns {*}
 */
export function requestFewTree(data) {
  return {
    data,
    type: types.REQUEST_FEW_TREE,
  };
}

/**
 * A.
 *
 * @param {*} object - A.
 * @param {*} active - A.
 * @returns {*}
 */
export function receiveFewTree(object, active) {
  return {
    active,
    object,
    type: types.RECEIVE_FEW_TREE,
  };
}

/**
 * A.
 *
 * @param {*} data - A.
 * @param {*} active - A.
 * @returns {*}
 */
export function fetchFewTree(data, active) {
  return (dispatch) => {
    dispatch(requestFewTree(data));
    return fetch(`${getApiPath()}/tree/all`, {
      body: JSON.stringify(data),
      credentials: 'include',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      method: 'POST',
    })
      .then((response) => {
        return response.json().catch((err) => {
          log.error(`'${err}' happened, but no big deal!`);
          return [];
        });
      })
      .then((json) => dispatch(receiveFewTree(json, active)));
  };
}

/**
 * A.
 *
 * @returns {*}
 */
export function clearAfterFetchFewTree() {
  return {
    type: types.CLEAR_EXPAND_AFTER_FEW_TREE,
  };
}

/**
 * A.
 *
 * @param {*} object - A.
 * @returns {*}
 */
export function setTreeEditObject(object) {
  return {
    object,
    type: types.SET_TREE_EDIT_OBJECT,
  };
}

/**
 * A.
 *
 * @param {*} data - A.
 * @returns {*}
 */
export function requestSaveTree(data) {
  return {
    data,
    type: types.REQUEST_SAVE_TREE,
  };
}

/**
 * A.
 *
 * @returns {*}
 */
export function receiveSaveTree() {
  return {
    type: types.RECEIVE_SAVE_TREE,
  };
}

/**
 * A.
 *
 * @param {*} oldJournal - A.
 * @param {*} action - A.
 * @returns {*}
 */
export function updateJournal(oldJournal, action) {

  /**
   * A.
   *
   * @param {*} st - A.
   * @param {*} end - A.
   * @returns {*}
   */
  const nullObject = (st, end) => {
    return st > end ? {} : { [st]: null, ...nullObject(st + 1, end) };
  };
  return oldJournal
    ? {
        actions: {
          ...oldJournal.actions,
          [oldJournal.cnt_action + 1]: action,
          ...nullObject(
            oldJournal.cnt_action + 2,
            Object.keys(oldJournal.actions).length,
          ),
        },

        cnt_action: oldJournal.cnt_action + 1,
      }
    : {
        actions: { 1: action },
        cnt_action: 1,
      };
}

/**
 * A.
 *
 * @param {*} root_id - A.
 * @param {*} journal - A.
 * @returns {*}
 */
export function saveJournal(root_id, journal) {
  return {
    journal: journal,
    root_id: root_id,
    type: types.ADD_TO_TREE_JOURNAL,
  };
}

/**
 * A.
 *
 * @param {*} root0 - A.
 * @param {*} root0.rootObjectId - A.
 * @param {*} root0.pathObjectId - A.
 * @param {*} root0.pathObjectTypeId - A.
 * @param {*} data - A.
 * @param {*} objectId - A.
 * @returns {*}
 */
export function receiveAddObjectToTree(
  { rootObjectId, pathObjectId, pathObjectTypeId },
  data,
  objectId,
) {
  return {
    calc_attribute: data.calc_attribute,
    object_tree_branch: data.object_tree_branch[0],
    path_object_id: pathObjectId,
    path_object_type: pathObjectTypeId,
    payload: { objectId },
    re_calc_attribute: {
      ...(data.address_list && { address_list: data.address_list }),
      ...(data.name && { name: data.name, re_calc_name: data.name }),
    },

    root_id: rootObjectId,
    type: types.RECEIVE_ADD_OBJECT_TO_TREE,
  };
}

/**
 * A.
 *
 * @returns {*}
 */
export function treeDataRecalculate() {
  // root_id,
  // root_type,
  // updatedJournal,
  // action,
  // effect_attributes,
  return () => {
    // dispatch(saveJournal(root_id, updatedJournal));
    // return fetch(`${getApiPath()}/tree/calc`, {
    //   headers: {
    //     'Content-Type': 'application/json; charset=utf-8',
    //   },
    //   method: 'POST',
    //   credentials: 'include',
    //   body: JSON.stringify({
    //     cnt_action: updatedJournal.cnt_action,
    //     tree_journal: JSON.stringify(updatedJournal.actions),
    //     effect_attributes:
    //       effect_attributes !== null ? JSON.stringify(effect_attributes) : null,
    //     root_id: root_id,
    //     root_type: root_type,
    //   }),
    // })
    //   .then((response) => {
    //     return response.json().catch((err) => {
    //       log.error(`'${err}' happened, but no big deal!`);
    //       return [];
    //     });
    //   })
    //   .then((json) =>
    //     dispatch(
    //       changeBranchTree({
    //         root_id,
    //         root_type,
    //         action,
    //         data: json,
    //       }),
    //     ),
    //   );
  };
}

/**
 * A.
 *
 * @param {*} map - A.
 * @returns {*}
 */
export function pushToRottenMap(map) {
  return {
    map,
    type: types.PUSH_TO_ROTTEN_MAP,
  };
}

/**
 * A.
 *
 * @param {*} object - A.
 * @returns {*}
 */
export function requestObjectTypeCnt(object) {
  return {
    object,
    type: types.REQUEST_OBJECT_TYPE_CNT,
  };
}

/**
 * A.
 *
 * @param {*} object - A.
 * @returns {*}
 */
export function receiveObjectTypeCnt(object) {
  return {
    object,
    type: types.RECEIVE_OBJECT_TYPE_CNT,
  };
}

/**
 * A.
 *
 * @returns {*}
 */
export const getObjectTypeCntRegistry = () => async () => {
  // dispatch(requestObjectTypeCnt(data));
  // const response = await registryApi.fetchObjectTypeCntRegistry(data);
  // if (response.status === 401) {
  //   // dispatch(push('/login'));
  // } else {
  //   dispatch(receiveObjectTypeCnt(response));
  //   return response;
  // }
};

// todo: push костыль - в дальнейшем переделать

/**
 * A.
 *
 * @param {*} pathId - A.
 * @param {*} push - A.
 * @returns {*}
 */
export const goToOgh = (pathId, push) => async (dispatch) => {
  const url = pathId.replace(/\./g, '/');
  return dispatch(() => push(`/ogh/${url}`));
};
