/* eslint-disable */
import React from 'react';

import { filterInput } from '../filterInputsClasses';
import {
  BUILDINGS_TYPE_NAME,
  BuildingTypeFF,
} from 'core/form/finalForm/fields';
import { NumberParam } from 'use-query-params';

 
export { BUILDINGS_TYPE_NAME };
 
 
/**
 *     Назначение
 *     buildingTypeId
 */
 
export const BuildingTypeFilter = (props) => (
  <BuildingTypeFF {...props} className={filterInput} />
);
 
export const buildingTypeParams = {
  [BUILDINGS_TYPE_NAME]: NumberParam,
};
