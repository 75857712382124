/* eslint-disable */
const cardFormValidators = {
  '': {},
  'properties.name': {
    required: function (value) {
      return !!value;
    },
  },
  'properties.comment': {
    required: function (value) {
      return !!value;
    },
  },
};

export default cardFormValidators;
