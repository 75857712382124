/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import DialogAlert from './DialogAlert';

const propTypes = {
  onAfterHiding: PropTypes.func,
  children: PropTypes.node,
  showCondition: PropTypes.bool,
};

function DialogError({ onAfterHiding, children, showCondition }) {
  return (
    <DialogAlert
      showCondition={showCondition}
      title="Ошибка"
      onAfterHiding={onAfterHiding}
    >
      {children}
    </DialogAlert>
  );
}

DialogError.propTypes = propTypes;

 
export default DialogError;
