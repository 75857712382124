import {
  ChevronRightIcon,
  ExpandMoreIcon,
  TreeViewMUI,
} from 'core/uiKit/material-ui';
import React from 'react';

import { BranchTree } from './BranchTree';
import { СomparisonLink } from './СomparisonLink';

/**
 * Компонент Tree.
 *
 * @param {object} props - Пропсы.
 * @param {*} props.branches - Any.
 * @param {*} props.onClickLink - Any.
 * @returns {JSX.Element|null}
 */
const Tree = ({ branches, onClickLink }) => {
  return Array.isArray(branches) ? (
    <TreeViewMUI
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {branches.map((branch, index) => {
        if (branch.typeOgh === '52') {
          return <СomparisonLink count={branch.count} />;
        }
        return (
          <BranchTree
            key={branch.typeOgh + index}
            onClickLink={onClickLink}
            {...branch}
          />
        );
      })}
    </TreeViewMUI>
  ) : null;
};

export default Tree;
