import './dialogEdit.MovementCharacteristics.scss';

/* eslint-disable */
import { useEditorCardSelector } from 'app/selectors/useSelectors/useEditorCard.Selector';
import { SaveFFDialog } from 'core/form/finalForm/components/dialogs/SaveFF.Dialog';

import { columnsMovementCharacteristicsMap } from '../columns.MovementCharacteristics';
import { FieldSet } from './FieldSet';
import { NumberFieldFFWrapper } from './NumberFieldFF.Wrapper';
import { TextFieldFFWrapper } from './TextFieldFF.Wrapper';
import { validate } from './validate';

/**
 *
 * @param root0
 * @param root0.closeDialog
 * @param root0.isOpen
 * @param root0.onClick
 * @param root0.textHeader
 * @param root0.value
 */
export const DialogEditMovementCharacteristics = ({
  closeDialog,
  isOpen,
  onClick,
  textHeader,
  value,
}) => {
  const {
    odhPlacementAxis,
    actualCompositionOfMovement,
    intensityLa,
    trafficCharacterListNote,
  } = columnsMovementCharacteristicsMap;

  const { layout_length: layoutLength } = useEditorCardSelector();
  return (
    <SaveFFDialog
      textHeader={textHeader}
      isOpen={isOpen}
      validate={validate({ layoutLength })}
      onSubmit={onClick}
      initialValues={value}
      closeDialog={closeDialog}
      maxWidth={'lg'}
      Body={
        <>
          <div className="two-column-container">
            <NumberFieldFFWrapper map={odhPlacementAxis} />
          </div>
          <FieldSet map={actualCompositionOfMovement} />
          <div className="two-column-container mt-3">
            <NumberFieldFFWrapper map={intensityLa} />
            <TextFieldFFWrapper map={trafficCharacterListNote} />
          </div>
        </>
      }
    />
  );
};
