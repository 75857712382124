import { useGetOghType } from 'core/utils/hooks/useGetOghType';

 
/**
 * Проверка доступа сохранения без геометрии. Для родителей и их дочек.
 *
 * @param {number} currentTypeId - Id типа текущей карточки.
 * @param {number} parentTypeId - Id типа родителя.
 * @returns {boolean} - Жопа.
 * @example
 *  let allowedWithoutGeometry = checkingAccessSaveWithoutGeometry(
 *       card?.type_id,
 *       card?.parent_info?.type_id || parentInfo?.typeId,
 *     );
 */
export const useCheckingAccessSaveWithoutGeometry = (
  currentTypeId,
  parentTypeId,
) => {
  const withoutGeometryArr = [useGetOghType('spa')].map(({ id }) => id);

  withoutGeometryArr.includes(currentTypeId); //?
  if (withoutGeometryArr.includes(currentTypeId)) {
    return true;
  }

  return withoutGeometryArr.includes(parentTypeId);
};
