/* eslint-disable */
export default function getTableData(table, columns) {
  const results = {
    table: {
      columns: columns.map((item) => ({
        sortable: false,
        databaseName: null,
        ...item,
        cells: [],
      })),
    },
  };

  table.forEach((item) => {
    results.table.columns.forEach(({ key, cells, mapper }) => {
      let value;
      if (mapper) {
        value = mapper(item[key]);
      } else if (key in item) {
        value = item[key];
      }

      if (key === 'file_name') {
        value = {
          name: value,
          fileId: item.file_id,
        };
      }

      cells.push(value);
    });
  });

  return results;
}
