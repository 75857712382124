import { ImprovementObjectCategoryIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/ImprovementObjectCategoryId.RF';
import React from 'react';

import { useSetupImprovementObjectCategoryIdFiledYard } from './useSetup.ImprovementObjectCategoryId.Filed.Yard';

/**
 *
 * Категория озеленения. Yard.
 *
 * @returns JSX.Element.
 */
export const ImprovementObjectCategoryIdFiledYard = () => {
  const { filter } = useSetupImprovementObjectCategoryIdFiledYard();

  return <ImprovementObjectCategoryIdRF filter={filter} disabled={true} />;
};
