/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import { TextField } from 'core/uiKit/inputs/TextField';

 
export const ID_RFID = 'id_rfid';

 
/* eslint-disable */
/**
 * Комментарий
 *
 * * `label = ID RFID метки`
 * * `maxLength = 2000`
 * @param props
 * @returns <TextField maxLength={2000} label="ID RFID метки" {...props} />
 * @constructor
 */
 
export const IdRfid = (props) => {
  return <TextField maxLength={2000} label="ID RFID метки" {...props} />;
};

IdRfid.propTypes = {
   
   
/**
   * выбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * функция вызова когда выберется итем
   */
  onChange: PropTypes.func,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
   
   
/**
   * значение
   */
  value: PropTypes.any,
};
