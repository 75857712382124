 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Детализация__.
 *
 * @type {{  accessor: 'maf_type_level3_name',  Header: 'Детализация'}}
 * @augments Cell
 */
export const maf_type_level3_name = {
  accessor: 'maf_type_level3_name',
  Header: 'Детализация',
  sortable: true,
};
