import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';

/**
 * Данные таблицы раздела __Благоустройство__.
 *
 * @returns {Array}
 */
export const useGetDataLandscapingTable = () => {
  const { landscaping } = useGetCurrentCard();
  return landscaping || [];
};
