import mapStateToPropsReducer from './mapStateToPropsReducer';

/**
 * Mock Default.
 *
 * @returns {void}
 */
const mockDefault = () => {};

describe('🐛 spec mapStateToPropsReducer', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock

    //🔥 Act
    const res = mapStateToPropsReducer({}, {}, {});

    //❓ Assert
    expect(res).toStrictEqual(expect.any(Function));
  });

  it('🧪 returns', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock

    //🔥 Act
    const res = mapStateToPropsReducer()({}, {}, {});

    //❓ Assert
    expect(res).toStrictEqual({
      initialValues: {
        name_description: null,
        re_calc_name: null,
      },
    });
  });
});
