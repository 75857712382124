// Алгоритм поиска эталона и копии при сравнении объектов или титульных списков
import { parseDate } from 'app/components/common/misc/ConverterFunctions';

/* eslint-disable */
export default function get(firstVersion, secondVersion) {
  return _get(getData(firstVersion, secondVersion));
}

function _get(data) {
  const {
    firstVersion,
    secondVersion,
    firstRowDateFrom,
    secondRowDateFrom,
    firstRowCreateDate,
    secondRowCreateDate,
  } = data;
  if (
    firstRowDateFrom > secondRowDateFrom ||
    (firstRowDateFrom === secondRowDateFrom &&
      firstRowCreateDate > secondRowCreateDate)
  ) {
    return {
      etalon: firstVersion,
      copy: secondVersion,
    };
  }
  return {
    etalon: secondVersion,
    copy: firstVersion,
  };
}

function getData(firstVersion, secondVersion) {
  return {
    firstVersion,
    secondVersion,
    firstRowDateFrom: parseDate(
      firstVersion.date_from || firstVersion.start_date,
    ),
    secondRowDateFrom: parseDate(
      secondVersion.date_from || secondVersion.start_date,
    ),
    firstRowCreateDate: parseDate(firstVersion.create_date),
    secondRowCreateDate: parseDate(secondVersion.create_date),
  };
}
