 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */

import { YesOrNoCell } from 'core/uiKit/components/cells/YesOrNo.Cell';
 
/* eslint-disable */
/**
 * Колонка __Включать в ТС__.
 *
 * @type {{  accessor: 'is_title',  Header: 'Включать в ТС'}}
 * @augments Cell
 */
export const is_title = {
  accessor: 'is_title',
  Header: 'Включать в ТС',
  Cell: YesOrNoCell,
};
