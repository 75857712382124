import { getCurrentDate } from 'app/utils/date/getCurrentDate';
import { ContinueFFDialog } from 'core/form/finalForm/components/dialogs/ContinueFF.Dialog';
import { REAGENT_NAME } from 'core/form/finalForm/fields/prepared/selects/simple/ReagentFF';
import { REAGENT_KIND_NAME } from 'core/form/finalForm/fields/prepared/selects/simple/ReagentKindFF';
import { Validator } from 'core/form/Validator';

import { useSend } from '../api';
import { BodyDialogRefill } from './Body.Dialog.Refill';

/**
 * Validates the Приход ПГР.
 *
 * @param {Record<string, *>} values - The values to validate.
 * @returns {Record<string, string>}
 */
export const formValidation = (values) => {
  const validator = new Validator(values);

  validator.setRequired('documentNum');
  validator.setRequired('quantity');
  validator.setRequired(REAGENT_NAME);
  validator.setRequired(REAGENT_KIND_NAME);

  return validator.getErrors();
};

/**
 * Dialog the Приход ПГР.
 *
 * @param {Record<string, *>} props - The Property.
 * @param {boolean} props.isOpen - Флаг отвечающий за показ диалогового окна.
 * @param {Function} props.onClose - Функция закрытия.
 * @param {Record<string, *>} [props.initialValues] - Начальные данные для формы.
 * @returns {JSX.Element}
 */
export const DialogRefill = ({ isOpen, onClose, initialValues = {} }) => {
  const { onSubmit, isLoading } = useSend('refill', onClose);

  return (
    <ContinueFFDialog
      isOpen={isOpen}
      textHeader={'Приход ПГР'}
      onSubmit={onSubmit}
      validate={formValidation}
      closeDialog={onClose}
      isLoading={isLoading}
      initialValues={{
        targetDateTime: getCurrentDate(),
        ...initialValues,
      }}
      Body={<BodyDialogRefill />}
    />
  );
};

//
// id: 306
// operation_kind: "refill"
// operation_kind_name: "Приход "
// quantity: 0.01
// reagent_id: 2
// reagent_kind_id: 2
// reagent_kind_name: "твердые реагенты"
// reagent_name: "МРКтв"
// target_date: "04.02.2022"
// ui_target_date: "04.02.2022 13:15"
