/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import CollapseButton from '../../components/CollapseButton';
import './collapsible.scss';

const collapsible = (Component) =>
  class Collapsible extends React.Component {
    static propTypes = {
      styles: PropTypes.object,
      showHeaderHeight: PropTypes.number,
      buttonInverted: PropTypes.bool,
    };

    constructor(props) {
      super(props);
      this.state = {
        collapsedPanel: false,
      };
    }

    togglePanelCollapse = () => {
      this.setState({ collapsedPanel: !this.state.collapsedPanel });
    };

    render() {
      const { collapsedPanel } = this.state;
      const { buttonInverted, showHeaderHeight, styles, ...rest } = this.props;

      let showHeaderStyle = {};

      if (this.state.collapsedPanel && showHeaderHeight) {
        showHeaderStyle = { height: showHeaderHeight };
      }

      return (
        <div
          className={cn('collapse-wrapper', {
            'collapse-wrapper--collapsed': collapsedPanel,
          })}
          style={{
            ...showHeaderStyle,
            ...styles,
          }}
        >
          <Component {...rest} />
          <div className={cn('collapse-button-container')}>
            <CollapseButton
              arrowDown={buttonInverted ? !collapsedPanel : collapsedPanel}
              onClick={() => this.togglePanelCollapse()}
            />
          </div>
        </div>
      );
    }
  };

 
export default collapsible;
