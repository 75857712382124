import { VERTICAL_LANDSCAPING_REF_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/VerticalLandscapingRefTypeId';
import React from 'react';
import { Field } from 'react-final-form';

import { VerticalLandscapingRefTypeIdWithFf } from './VerticalLandscapingRefTypeIdWithFF';

/**
 * ## Тип Объектов вертикального озеленения Select FF.
 *
 * @param {object} props - Пропсы.
 * @param {string} [props.className] - Класс.
 * @returns {JSX.Element}
 */
export const VerticalLandscapingRefTypeIdFF = (props) => {
  return (
    <Field
      {...props}
      name={VERTICAL_LANDSCAPING_REF_TYPE_ID_NAME}
      component={VerticalLandscapingRefTypeIdWithFf}
    />
  );
};
