import * as grid from 'app/components/card/common/grid/index';
import IconButton, {
  iconButtonStyle,
  iconStyle,
} from 'core/newComponents/IconButton';
import { ChevronRightIcon, ExpandMoreIcon } from 'core/uiKit/material-ui';
import React from 'react';

const itemContainerStyle = {
  alignItems: 'center',
  display: 'flex',
};

const itemStyle = {
  marginTop: -33,
  paddingLeft: 57,
};

/**
 * Компонент BnsoList.
 *
 * @returns {JSX.Element}
 */
export default class BnsoList extends React.Component {
  state = {
    visible: new Set(),
  };

  /**
   * Компонент renderBnso.
   *
   * @param {object} props - Пропсы.
   * @param {*} props.uuid - Any.
   * @param {string} props.name - Имя.
   * @returns {JSX.Element}
   */
  renderBnso({ uuid, name, ...rest }) {
    const isVisible = this.state.visible.has(uuid);
    return (
      <div>
        <div style={itemContainerStyle}>
          <IconButton
            iconClassName="material-icons"
            iconStyle={iconStyle}
            style={iconButtonStyle}
            onClick={() => {
              if (isVisible) {
                let visible = new Set(...this.state.visible);
                visible.delete(uuid);
                this.setState({ visible });
              } else {
                let visible = new Set(...this.state.visible);
                visible.add(uuid);
                this.setState({ visible });
              }
            }}
          >
            {isVisible ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          </IconButton>
          <h2 style={{ margin: 0 }}>{name}</h2>
        </div>
        <div style={itemStyle}>
          <Bnso visible={isVisible} {...rest} />
        </div>
        <hr />
      </div>
    );
  }

  /**
   * Метод жизненного цикла render.
   *
   * @returns {JSX.Element}
   */
  render() {
    const { list } = this.props;
    return <div>{list && list.map((item) => this.renderBnso(item))}</div>;
  }
}

/**
 * Компонент Bnso.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
function Bnso(props) {
  if (!props.visible) {
    return null;
  }
  const elements = [
    {
      addition: {
        type: 'text',
      },
      component: grid.renderCustomTextField,
      defaultValue: props.gps_code,
      formValue: true,
      id: 'gps_code',
      label: 'Код БНСО',
    },
    {
      addition: {
        type: 'text',
      },
      component: grid.renderCustomTextField,
      defaultValue: props.provider_name,
      formValue: true,
      id: 'provider_name',
      label: 'Поставщик телеметрических услуг',
    },
    {
      addition: {
        type: 'text',
      },
      component: grid.renderCustomTextField,
      defaultValue: props.use_date,
      formValue: true,
      id: 'use_date',
      label: 'Дата ввода в эксплуатацию',
    },
    {
      addition: {
        type: 'text',
      },
      component: grid.renderCustomTextField,
      defaultValue: props.sim_number,
      formValue: true,
      id: 'sim_number',
      label: 'Номер SIM-карты',
    },
    {
      addition: {
        type: 'text',
      },
      component: grid.renderCustomTextField,
      defaultValue: props.gps_model_name,
      formValue: true,
      id: 'gps_model_name',
      label: 'Марка и модель оборудования',
    },
    {
      addition: {
        type: 'text',
      },
      component: grid.renderCustomTextField,
      defaultValue: props.serial_number,
      formValue: true,
      id: 'serial_number',
      label: 'Серийный номер',
    },
    {
      addition: {
        type: 'text',
      },
      component: grid.renderCustomTextField,
      defaultValue: props.gps_state_name,
      formValue: true,
      id: 'gps_state_name',
      label: 'Состояние',
    },
  ];
  return grid.elementFormGrid(elements, 3, '', 1);
}
