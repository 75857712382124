/* eslint-disable */
export const rowSpanCreate = (rowSpan = 1, coll, child = false) => {
  const count = rowSpan - 1;

  if (count === 0) {
    return [
      {
        ...coll,
        id: Math.random(),
        hideHeader: true,
        Header: Math.random(),
      },
    ];
  }

  if (child) {
    return [
      {
        id: Math.random(),
        hideHeader: true,
        columns: rowSpanCreate(count, coll, true),
      },
    ];
  }

  return {
    rowSpan: rowSpan,
    columns: rowSpanCreate(count, coll, true),
  };
};
