import 'assets/styles/registry.out.scss';
/* eslint-disable */
import './Matching.scss';

import { resetMatching } from 'app/actions/odsObjectActions';
import Legend from 'app/components/common/Legend';
import cn from 'classnames';
import { CommentsWithFilesCell } from 'core/uiKit/components/cells/CommentsWithFiles.Cell';
import { Table } from 'core/uiKit/components/tables/Table';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { MatchingBoundariesOghTable } from './MatchingBoundariesOgh.Table';

const status = {
  APPROVED: 'согласовано',
  ON_APPROVAL: 'на согласовании',
  REJECTED: 'отклонено',
};

const legendList = [
  {
    className: 'legend_not_mandatory',
    value: 'не обязательный',
  },
  {
    className: 'legend_add',
    value: status.APPROVED,
  },
  {
    className: 'legend_del',
    value: status.REJECTED,
  },
  {
    className: 'legend_update',
    value: status.ON_APPROVAL,
  },
];

const propTypes = {
  card: PropTypes.object,
  className: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.object,
  dispatch: PropTypes.func,
  mode: PropTypes.object,
  recordId: PropTypes.number,
  selected: PropTypes.object,
};

/**
 * ObjectMatchingHistory.
 *
 */
class ObjectMatchingHistory extends React.Component {
  state = {
    comment: '',
    isDialogMatchingRegistryCommentOpen: false,
  };

  /**
   * Component did update.
   *
   * @param {*} prevProps - Previous props.
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    const { recordId, resetMatching } = this.props;
    if (prevProps.recordId !== recordId) {
      resetMatching();
    }
  }

  /**
   * Hide comment.
   *
   * @returns {void}
   */
  hideComment = () => {
    this.setState({ isDialogMatchingRegistryCommentOpen: false });
  };

  /**
   * Show comment.
   *
   * @param {*} event - Event.
   * @param {*} comment - Comment.
   * @returns {void}
   */
  showComment = (event, comment) => {
    event.preventDefault();
    this.setState({ comment, isDialogMatchingRegistryCommentOpen: true });
  };

  /**
   * Render.
   *
   * @returns {JSX.Element}
   */
  render() {
    const { data } = this.props;

    if (!data || !data.table) {
      return null;
    }

    let trStyles = [];
    data.table.forEach((row) => {
      let stateClassName = '';

      if (parseInt(row.is_mandatory, 10) === 0) {
        stateClassName = 'notMandatory';
      } else if (row.state === status.APPROVED) {
        stateClassName = 'agreedState';
      } else if (row.state === status.REJECTED) {
        stateClassName = 'rejectedState';
      } else if (row.state === status.ON_APPROVAL) {
        stateClassName = 'onAgreementState';
      }
      trStyles.push(cn(stateClassName));
    });

    return (
      data &&
      data.table && (
        <div>
          <MatchingBoundariesOghTable />

          <h2 className={'mt-5'}>Согласование утверждения/закрытия ОГХ</h2>
          <div className="closing-approval-agreemen">
            <Table
              columns={columns}
              data={data.table}
              divClassName="d-flex flex-column h-100 relative t"
              trClassName={trStyles}
            />
          </div>

          <Legend legend={legendList} />
        </div>
      )
    );
  }
}
const columns = [
  {
    Header: 'Итерация',
    accessor: 'num_iter',
    isSort: false,
    width: 100,
  },
  {
    Header: 'Этап',
    accessor: 'nlevel',
    isSort: false,
    width: 50,
  },
  {
    Header: 'Организация',
    accessor: 'org_role_name',
    isSort: false,
    width: 110,
  },
  {
    Header: 'ФИО',
    accessor: 'user_matching',
    isSort: false,
  },
  {
    Header: 'Дата план',
    accessor: 'last_match_date',
    isSort: false,
  },
  {
    Header: 'Дата факт',
    accessor: 'date_matching',
    isSort: false,
  },
  {
    Header: 'Статус',
    accessor: 'state',
    isSort: false,
  },
  {
    Header: 'Причины изменения',
    accessor: 'reason_list',
  },
  {
    Cell: CommentsWithFilesCell,
    Header: 'Комментарий',
    accessor: 'comment',
    isSort: false,
  },
];

/**
 * MapDispatchToProps.
 *
 * @param {*} dispatch - Store dispatch function.
 * @returns {*}
 */
const mapDispatchToProps = (dispatch) => ({
  /**
   *
   */
  resetMatching: () => dispatch(resetMatching()),
});

ObjectMatchingHistory.propTypes = propTypes;

export default connect(null, mapDispatchToProps)(ObjectMatchingHistory);
