import { List, Typography } from 'core/uiKit/material-ui';

import { ItemFile } from './ItemFile';

/**
 * Компонент Body.
 *
 * @param {object} props - Пропсы.
 * @param {string} props.comment - Комментарий.
 * @param {Array} props.fileList - Файлы.
 * @returns {JSX.Element}
 */
export const Body = ({ comment, fileList = [] }) => {
  return (
    <>
      <Typography>{comment}</Typography>
      <List>
        {fileList?.map(({ file_id: fileId, file_name: fileName = '...' }) => (
          <ItemFile key={fileId} {...{ fileId, fileName }} />
        ))}
      </List>
    </>
  );
};
