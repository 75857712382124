import './CheckboxCell.scss';

import { CheckboxMUI } from 'core/uiKit/material-ui';

/**
 * Чекбокс для таблицы.
 *
 * @param {object} props - Пропсы.
 * @param {boolean} props.value - Значение чекбокса.
 * @returns {JSX.Element}
 */
export const CheckboxCell = ({ value = false }) => {
  return (
    <div className={'checkbox__cell--container'}>
      <CheckboxMUI checked={value} disabled={true} />
    </div>
  );
};
