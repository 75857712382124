import { BuildingsTypeSpec } from 'core/uiKit/preparedInputs/selects/simpleSelect/BuildingsTypeSpec';
import React from 'react';

 
/**
 * Компонент BuildingsTypeSpecWithReduxForm.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.input - Инпут приходит из ReduxForm.
 * @returns {JSX.Element} - Возвращает компонент  <BuildingsTypeSpec {...input} {...otherProps} />.
 * @example
 *  <Field
 *       {...otherProps}
 *       name={BUILDINGS_TYPE_SPEC_NAME}
 *       component={BuildingsTypeSpecWithReduxForm}
 *     />
 */
export const BuildingsTypeSpecWithReduxForm = ({ input, ...props }) => {
  return <BuildingsTypeSpec {...input} {...props} />;
};
