import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { shallow } from 'enzyme';
import React from 'react';

import { PlantServiceRecomendationsIdRfTreesShrubs } from './PlantServiceRecomendationsIdRF.TreesShrubs';
jest.mock('app/api/hooks/useIsCurrentObjectIsExternalSystem');

describe('PlantServiceRecomendationsIdRfTreesShrubs', () => {
  const defaultProps = {
    currentValues: {
      characteristic_state_gardening_id: [1, 2],
    },
    editMode: true,
  };

  it('should disable field if useIsCurrentObjectIsExternalSystem returns true', () => {
    (useIsCurrentObjectIsExternalSystem as jest.Mock).mockReturnValue(true);

    const wrapper = shallow(
      <PlantServiceRecomendationsIdRfTreesShrubs {...defaultProps} />,
    );
    expect(wrapper.find('[disabled=true]')).toHaveLength(1);
  });

  it('should disable field if characteristic_state_gardening_id includes specific ids and edit mode is false', () => {
    (useIsCurrentObjectIsExternalSystem as jest.Mock).mockReturnValue(false);
    const props = {
      ...defaultProps,
      editMode: false,
    };

    const wrapper = shallow(
      <PlantServiceRecomendationsIdRfTreesShrubs {...props} />,
    );

    expect(wrapper.find('[disabled=true]')).toHaveLength(1);
  });

  it('should enable field if characteristic_state_gardening_id include specific ids and edit mode is true and useIsCurrentObjectIsExternalSystem returns false', () => {
    (useIsCurrentObjectIsExternalSystem as jest.Mock).mockReturnValue(false);

    const wrapper = shallow(
      <PlantServiceRecomendationsIdRfTreesShrubs {...defaultProps} />,
    );
    expect(wrapper.find('[disabled=false]')).toHaveLength(1);
  });
});
