import { DISTRICT_LIST_NAME } from 'core/uiKit/preparedInputs/selects/multiSelect/DistrictList';
import React from 'react';
import { Field } from 'redux-form';

import { DistrictListWithReduxForm } from './DistrictListWithReduxForm';

/* eslint-disable */
/**
 * Компонент DistrictListRF для работы с редакс формой.
 *
 * @param {object} props - Пропсы.
 * @param {string} props.name - Имя.
 * @returns {React.ReactElement} - Жопа.
 * @example
 * <DistrictListRF {...props}/>
 */
export const DistrictListRF = ({ name, ...otherProps }) => {
  return (
    <Field
      name={DISTRICT_LIST_NAME}
      component={DistrictListWithReduxForm}
      {...otherProps}
    />
  );
};
