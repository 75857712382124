import { SelectFF } from 'core/form/finalForm/fields/default/selects/SelectFF';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';
import { NumberParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

export const UPDATE_REQUIRED_NAME = 'updateRequired';

/**
 * Требует актуализации
 * updateRequired.
 *
 * @returns {JSX.Element}
 */
export const UpdateRequiredFilter = () => {
  const options = [
    { id: 1, name: 'Да' },
    { id: -1, name: 'Нет' },
  ];
  return (
    <SelectFF
      className={filterInput}
      {...{ options }}
      label={'Требует актуализации'}
      name={UPDATE_REQUIRED_NAME}
    />
  );
};

/**
 * Params.
 *
 * @returns {*}
 */
export const updateRequiredParams = () => {
  addPreparation(UPDATE_REQUIRED_NAME, (value) => value > 0);
  return {
    [UPDATE_REQUIRED_NAME]: NumberParam,
  };
};
