import { useGetParentCard } from 'app/api/hooks/useGetParentCard';

/**
 * Проверка является родитель загружен из внешней системы.
 *
 * @returns {boolean}
 */
export const useIsCurrentObjectIsExternalSystem = () => {
  const { attribute: { create_type: createTypeParent } = { create_type: '' } } =
    useGetParentCard();

  return createTypeParent === 'external_system';
};
