// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// NOTE Imports
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import './lib/cadesplugin_api';

import { isTesting } from 'core/utils/getEnvironment';

import CertificatesApi from './сertificatesApi';

// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// NOTE cadesplugin await function
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/* eslint-disable */
const cadespluginOnload = () =>
  isTesting()
    ? {
        getCertsList: (_) => _,
        getCert: (_) => _,
        currentCadesCert: (_) => _,
        signBase64: (_) => _,
        signXml: (_) => _,
        about: (_) => _,
      }
    : (async function cadespluginOnload() {
        try {
          await window.cadesplugin;

          const {
            getCertsList,
            getCert,
            currentCadesCert,
            signBase64,
            signXml,
            about,
          } = CertificatesApi;

          return {
            getCertsList,
            getCert,
            currentCadesCert,
            signBase64,
            signXml,
            about,
          };
        } catch (error) {
          throw new Error(error);
        }
      })();

// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// NOTE Exports
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

 
export default cadespluginOnload;
