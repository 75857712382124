import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { IImprovementObjectCategoryData } from 'core/uiKit/preparedInputs/selects/simpleSelect/ImprovementObjectCategoryId';

/**
 *
 * Setup Категория озеленения. Yard.
 *
 * @returns JSX.Element.
 */
export const useSetupImprovementObjectCategoryIdFiledYard = () => {
  const parentCard = useGetParentCard();

  /**
   * Функция фильтрации.
   *
   * @param data - Данные.
   * @returns Данные.
   */
  const filter = (data: IImprovementObjectCategoryData[]) => {
    return data.filter((item) =>
      item?.parent_ogh_object_type_list?.includes(+parentCard?.type_id),
    );
  };

  return { filter };
};
