import { FieldRF } from 'core/form/reduxForm/helpers/FieldRF';
import {
  IPlanarStructureTypeIdProps,
  PLANAR_STRUCTURE_TYPE_ID_NAME,
  PlanarStructureTypeId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/PlanarStructureTypeId';
import React from 'react';

/**
 * Назначение  Select.RF.
 *
 * @param props - Props.
 * @returns JSX.Element.
 */
export const PlanarStructureTypeIdRF = (props: IPlanarStructureTypeIdProps) => {
  return (
    <FieldRF
      {...props}
      name={PLANAR_STRUCTURE_TYPE_ID_NAME}
      component={PlanarStructureTypeId}
    />
  );
};
