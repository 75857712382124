import * as env from 'config/env';
import { memoize } from 'lodash';

 
/**
 * @deprecated
 * @see env.ODS_API_URL
 */
export const getApiPath = memoize(() => env.ODS_API_URL);

 
/**
 * @deprecated
 * @see env.PRINT_API_URL
 */
export const getPrintPath = memoize(
  () => env.PRINT_API_URL,
);
//
// export const getFrontendVersion = memoize(
//   () => window.FRONTEND_VERSION || env.FRONTEND_VERSION,
// );
// export const getFrontendExecutionDate = memoize(
//   () => window.FRONTEND_EXECUTION_DATE || env.FRONTEND_EXECUTION_DATE,
// );
// export const getFrontendBuildDate = memoize(() => window.FRONTEND_BUILD_DATE);
