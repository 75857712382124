import { shallow } from 'enzyme';
import React from 'react';

import { ContainerCard } from './ContainerCard';

describe('🐛 spec ContainerCard', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = { card: { type_id: 1 }, containerTypes: [] };

    // 🔥 Act
    const wrapper = shallow(<ContainerCard {...props} />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<CardContainer />');
  });

  it('🧪 ContainerTypeIdFieldContainer', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = { card: { type_id: 1 }, containerTypes: [] };

    // 🔥 Act
    const wrapper = shallow(<ContainerCard {...props} />);

    // ❓ Assert
    expect(wrapper.find('ContainerTypeIdFieldContainer').length).toBe(1);
  });
});
