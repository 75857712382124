/* eslint-disable */
import { change, submit, reset } from 'redux-form';
import * as api from 'app/api/basePgm';
import * as types from 'app/constants/actionTypes';
import getValuesForSubmit from 'app/utils/form/getValuesForSubmit';
import {
  viewCard,
  requestCreateCard,
  receiveErrorCreateCard,
  resolveErrorMessage,
  receiveCreateCard,
  requestUpdateCard,
  receiveUpdateCard,
  receiveErrorUpdateCard,
  readyForUpdateCard,
  requestDeleteCard,
  receiveErrorDeleteCard,
  receiveDeleteCard,
} from 'app/actions/odsObjectActions';
import { showError } from 'app/actions/dialog';

export const fetchBasePgmExports = (data) => async (dispatch) => {
  dispatch(requestBasePgmExports());
  const response = await api.fetchExports(data);
  dispatch(receiveBasePgmExports());
  return response;
};

const requestBasePgmExports = () => ({
  type: types.REQUEST_BASE_PGM_EXPORTS,
});

const receiveBasePgmExports = () => ({
  type: types.RECEIVE_BASE_PGM_EXPORTS,
});

export const onSubmit = (values) => async (dispatch) => {
  await dispatch(change('searchExports', 'page', 0));
  const requestValues = getValuesForSubmit(values);
  return dispatch(fetchBasePgmExports(requestValues));
};

export const onSort = (value) => async (dispatch) => {
  await dispatch(change('searchExports', 'sort', value.sort));
  dispatch(submit('searchExports'));
};

export const onLimit = (value) => async (dispatch) => {
  await dispatch(change('searchExports', 'maxRows', value));
  await dispatch(change('searchExports', 'page', 0));
  dispatch(submit('searchExports'));
};

export const onPagination = (value) => async (dispatch) => {
  await dispatch(change('searchExports', 'page', value));
  dispatch(submit('searchExports'));
};

export const onReset = () => async (dispatch) => {
  await dispatch(reset('searchExports'));
  dispatch(submit('searchExports'));
};

export const fetchExport = (id) => async (dispatch) => {
  dispatch(requestCard(id));
  const response = await api.fetchExport(id, {});
  dispatch(receiveCard(response));
  return response;
};

const requestCard = (object) => ({
  type: types.REQUEST_CARD,
  object,
});

const receiveCard = (object) => ({
  type: types.RECEIVE_CARD,
  object,
});

export const setPreviewCard = (data) => (dispatch) => {
  const defaultCard = {
    doc_start_date: data.dateFrom,
    doc_end_date: data.dateTo,
    is_matching: false,
    reagent_kind_id: parseInt(data.reagentKind, 10),
    reagent_id: parseInt(data.reagent, 10),
    base_num: data.baseNum,
    base_name: data.baseName,
    reagent_base_id: parseInt(data.baseRecordId, 10),
    state_id: 2,
  };
  dispatch(receiveCard(defaultCard));
};

export const fetchCreateExportCard = (data) => async (dispatch) => {
  dispatch(requestCreateCard());
  const response = await api.fetchCreateExport(data);
  if (response.errors && response.message) {
    dispatch(receiveErrorCreateCard(resolveErrorMessage(response, data)));
  } else {
    dispatch(
      receiveCreateCard(
        response.id != null
          ? `/pgm/docs/export/${response.id}`
          : `/ogh/${data.reagent_base_id}`,
      ),
    );
  }
  return response.id;
};

export const fetchUpdateExportCard = (data) => async (dispatch) => {
  dispatch(requestUpdateCard());
  const response = await api.fetchUpdateExport(data);
  if (response.errors && response.message) {
    const errorMessage = response.message.toString();
    dispatch(showError(errorMessage));
    dispatch(receiveErrorUpdateCard(resolveErrorMessage(response, data)));
    dispatch(viewCard());
  } else {
    dispatch(receiveUpdateCard(data.id, response.id));
    dispatch(readyForUpdateCard());
    dispatch(fetchExport(response.id));
  }
  return response.id;
};

export const fetchDeleteExportCard = (data) => async (dispatch) => {
  dispatch(requestDeleteCard());
  const response = await api.fetchDeleteExport(data);
  if (response.errors && response.message) {
    dispatch(receiveErrorDeleteCard(response.message));
  } else {
    dispatch(
      receiveDeleteCard(
        `/ogh/${data.basePgmRootId}`,
        'Удаление версии объекта успешно произведено',
      ),
    );
  }
  return response;
};
