/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Field, reduxForm, getFormValues, change } from 'redux-form';
import cn from 'classnames';
import { ButtonBlue, ButtonOutlinedBlue } from 'core/newComponents/Button';

import KeyboardDatePickerRF from 'core/form/reduxForm/fields/default/datePickers/KeyboardDatePickerRF';

import { WithReduxFormSelect } from 'app/components/common/SelectField';

import dateFormatService from 'core/services/dateFormatService';
import { dateToMinDate } from 'app/utils/dateToMinDate';
import { dateFromMaxDate } from 'app/utils/dateFromMaxDate';

import {
  getDefaultDateFrom,
  getDefaultDateTo,
} from 'app/components/card/ogh/BasePgmCard/utils/dateTime';

import getDict from 'app/selectors/getDict';
import getReagents from 'app/selectors/card/getReagents';

import * as actions from 'app/actions/basePgmExports';

const defaultOption = {
  id: '',
  name: 'Все',
};

const SearchSelectField = ({ labelText, options, ...otherProps }) => (
  <div className="align-top mx-3">
    <WithReduxFormSelect
      label={labelText}
      options={[defaultOption, ...options]}
      width="270"
      {...otherProps}
    />
  </div>
);

const SearchDatePickerField = (props) => (
  <div className="align-top mx-3 pt-1">
    <KeyboardDatePickerRF
      width="270"
      {...props}
      onChange={(date) => {
        return props.input.onChange(dateFormatService.formatDate(date));
      }}
    />
  </div>
);

class SearchForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    initialValues: PropTypes.shape({
      dateFrom: PropTypes.object.isRequired,
      dateTo: PropTypes.object.isRequired,
      rootObjectId: PropTypes.number,
    }).isRequired,
    reagentKinds: PropTypes.array,
    reagents: PropTypes.array,
    documentStatuses: PropTypes.array,
    formValues: PropTypes.object.isRequired,
  };

  componentDidMount() {
    if (this.props.initialValues.rootObjectId) {
      this.props.resetForm();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.initialValues.rootObjectId !==
      this.props.initialValues.rootObjectId
    ) {
      this.props.resetForm();
    }
  }

  submit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  render() {
    const { reagentKinds, reagents, formValues, documentStatuses } = this.props;

    return (
      <Form
        className={cn(this.props.className)}
        onSubmit={this.props.handleSubmit(this.submit)}
      >
        <Field
          component={SearchSelectField}
          labelText="Вид ПГР"
          name="reagentKindId"
          options={reagentKinds}
          onChange={() => this.props.changeFieldValue('reagentId', '')}
        />
        <Field
          component={SearchSelectField}
          labelText="Наименование ПГР"
          name="reagentId"
          options={reagents}
        />
        <Field
          component={SearchDatePickerField}
          label="Дата с"
          maxDate={dateFromMaxDate(formValues.dateFrom, formValues.dateTo)}
          name="dateFrom"
        />
        <Field
          component={SearchDatePickerField}
          label="Дата по"
          minDate={dateToMinDate(formValues.dateFrom)}
          name="dateTo"
        />
        <Field
          component={SearchSelectField}
          labelText="Статус"
          name="stateId"
          options={documentStatuses}
        />
        <ButtonBlue className="align-bottom mx-3 my-2" type="submit">
          Поиск
        </ButtonBlue>
        <ButtonOutlinedBlue
          className="align-bottom my-2"
          onClick={this.props.resetForm}
        >
          Сбросить
        </ButtonOutlinedBlue>
      </Form>
    );
  }
}

const getCurrentValues = (state) => getFormValues('searchExports')(state) || {};

const getCurrentReagentKind = (state) => getCurrentValues(state).reagentKind;
const getReagentsForKind = getReagents(getCurrentReagentKind);

const mapStateToProps = (state, ownProps) => ({
  formValues: getCurrentValues(state),
  reagentKinds: getDict('reagentKinds')(state),
  reagents: getReagentsForKind(state),
  documentStatuses: getDict('documentStatuses')(state),
  enableReinitialize: true,
  initialValues: {
    reagentKindId: '',
    reagentId: '',
    dateFrom: getDefaultDateFrom(),
    dateTo: getDefaultDateTo(),
    stateId: '',
    maxRows: 10,
    page: 0,
    sort: 'doc_start_date.desc',
    rootObjectId: ownProps.rootId,
  },
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeFieldValue: (field, value) =>
    dispatch(change('searchExports', field, value)),
  onSubmit: async (values) => {
    const data = await dispatch(actions.onSubmit(values));
    ownProps.updateData(data);
  },
  resetForm: () => dispatch(actions.onReset()),
});

 
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(reduxForm({ form: 'searchExports' })(SearchForm));
