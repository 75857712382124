/* eslint-disable */
import React from 'react';

import {
  LEGAL_PERSON_ID_NAME,
  LegalPersonIdFF,
} from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';
import { NumberParam } from 'use-query-params';

 
 
/**
 * LegalPersonId
 */
 
export const LegalPersonIdFilter = (props) => (
  <LegalPersonIdFF {...props} className={filterInput} />
);

 
export const legalPersonIdParams = {
  [LEGAL_PERSON_ID_NAME]: NumberParam,
};
