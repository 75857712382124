/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import getDisplayName from 'core/hocs/getDisplayName';

const propTypes = {
  className: PropTypes.string,
};

function buttonRed(Component) {
  class ButtonRed extends React.Component {
    render() {
      const { className, ...otherProps } = this.props;
      return (
        <Component className={cn(className, 'Button_Red')} {...otherProps} />
      );
    }
  }

  ButtonRed.propTypes = propTypes;
  ButtonRed.displayName = `buttonRed(${getDisplayName(Component)})`;

  return ButtonRed;
}

 
export default buttonRed;
