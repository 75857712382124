import dateFormatService from 'core/services/dateFormatService';
import { keys, reduce } from 'lodash';

/* eslint-disable */
export default function getValuesForSubmit(formValues) {
  const fields = keys(formValues);

  return reduce(
    fields,
    (result, fieldName) => {
      const value = formValues[fieldName];

      if (value || value === 0) {
        if (value instanceof Date) {
          result[fieldName] = dateFormatService.formatDate(value);
        } else {
          result[fieldName] = value;
        }
      }

      return result;
    },
    {},
  );
}
