import { CheckboxFF } from 'core/form/finalForm/fields/index';
import React from 'react';

export const IS_FILLED_NAME = 'is_filled';

/**
 * Чекбокс __Данные заполнены__.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const IsFilledFf = (props) => {
  return (
    <CheckboxFF
      {...props}
      name={IS_FILLED_NAME}
      label={props.label || 'Данные заполнены'}
    />
  );
};
