 
/**
 * Функция для получения флага мульти селекта.
 *
 * @param {Array} value - Значение.
 * @param {Array} result - Отфильтрованный массив.
 * @param {string} optionKey - Ключ.
 * @returns {boolean} - Возвращает флаг.
 * @example
 * flag = getFlagMultiSelect(value, result, optionKey)
 */
export const getFlagMultiSelect = (
  value,
  result,
  optionKey,
) => {
  return value.every((itemValue) =>
    result.some(
      (itemResult) => itemResult[optionKey] === itemValue,
    ),
  );
};
