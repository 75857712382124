import { parseDateToMoment } from './parseDateToMoment';

/**
 * Форматирует дату формата '20.10.2020' в '2020-10-20T00:00:00.000Z'.
 *
 * @category dateUtils
 * @function parseDateToISOString
 * @description Форматирует дату формата '20.10.2020' в '2020-10-20T00:00:00.000Z'.
 * @param {string} data - Дата.
 * @param {string} [formatDate] - Не обязательный формат принимаемой строки.
 * @returns {string} ISO string data.
 * @example
 * parseDateToISOString('20.10.2020')            //? 2020-10-20T00:00:00.000Z
 * parseDateToISOString('20.10.20', 'DD.MM.YY') //? 2020-10-20T00:00:00.000Z
 */
export const parseDateToISOString = (data, formatDate) => {
  return parseDateToMoment(data, formatDate)?.toISOString();
};
