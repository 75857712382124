import './TabsCardOGHPanel.scss';

import cn from 'classnames';

/**
 * Компонент табы навешивает класс на div.
 *
 * @param {object} props - Пропсы.
 * @param {JSX.Element} props.children - Реакт компонент.
 * @param {boolean} props.show - Флаг скрывать или показывать.
 * @returns {JSX.Element} - Возвращает выбраную табу.
 */
export const TabsCardOGHPanelPreload = ({ children, show }) => {
  return (
    <div
      className={cn({
        'hide-tab-panel': !show,
      })}
    >
      {children}
    </div>
  );
};
