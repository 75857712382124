import { faSignature } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'core/uiKit/components/buttons/Button';
import { Card } from 'core/uiKit/components/Card';
import {
  CardActions,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from 'core/uiKit/material-ui';
import PropTypes from 'prop-types';
import React from 'react';
/* eslint-disable */

export const SignatureList = ({ signatures, clearSignatures }) => {
  return signatures?.length ? (
    <Card className={'my-2'}>
      <CardHeader title={<Typography variant="h6">Подписи</Typography>} />
      <CardContent>
        <List dense>
          {signatures?.map((item) => (
            <ListItem key={item.name}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faSignature} />
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
        </List>
      </CardContent>
      <CardActions>
        <Button
          variant={'outlined'}
          color={'error'}
          className={'ml-auto'}
          onClick={clearSignatures}
          size={'small'}
        >
          Удалить подписи
        </Button>
      </CardActions>
    </Card>
  ) : null;
};

SignatureList.propTypes = {
  signatures: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
    }),
  ),
  clearSignatures: PropTypes.func.isRequired,
};
