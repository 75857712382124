 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Площадь, кв.м__.
 *
 * @type {{ accessor: 'quantity_sqr',  Header: 'Площадь, кв.м' }}
 * @augments Cell
 */
export const quantity_sqr = {
  accessor: 'quantity_sqr',
  Header: 'Площадь, кв.м',
  sortable: true,
};
