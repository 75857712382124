import { Map } from '@2gis/mapgl/types';
import { BBox } from '@turf/helpers';

import { GeometryTypes, Polygon } from '../../../types.d';
import Shape from '../Controls/Shape';

/**
 * Прямоугольник описывающий крайние точки геометрии.
 *
 * @param map - Карта.
 * @param bounds - Границы геометрии.
 */
export default class Box {
  public map: Map;

  public geometry: Polygon;

  /**
   * Конструктор.
   *
   * @param map - Карта.
   * @param bounds - Границы геометрии.
   */
  constructor(map: Map, bounds: BBox) {
    this.map = map;
    this.geometry = this.create(bounds);
  }

  /**
   * Создает полигон.
   *
   * @param bounds - Границы геометрии.
   * @returns Экземпляр Polygon.
   */
  create(bounds: BBox) {
    if (!Shape.mapgl) throw new Error('mapgl on Shape is not initialized');

    const [west, south, east, north] = bounds;
    const coordinates = [
      [
        [west, north],
        [east, north],
        [east, south],
        [west, south],
        [west, north],
      ],
    ];

    this.geometry = new Shape.mapgl.Polygon(this.map, {
      color: '#transparent',
      coordinates,
      interactive: false,
      strokeColor: '#ff5d4350',
      strokeWidth: 1,
      userData: {
        coordinates,
        coordinatesToCheckMouseEvent: coordinates,
        type: GeometryTypes.Polygon,
      },
      zIndex: 9999,
    });

    return this.geometry;
  }

  /**
   * Обновляет полигон.
   *
   * @param bounds - Границы геометрии.
   * @returns Экземпляр Box.
   */
  update(bounds: BBox) {
    this.geometry?.destroy();
    this.create(bounds);
    return this;
  }

  /**
   * Уничтожает контрол.
   *
   */
  destroy() {
    this.geometry?.destroy();
  }
}
