 
export const TECH = 'tech';
/* eslint-disable */
export const DXF = 'dxf';

const fileTypes = {
  [TECH]: {
    'application/pdf': 'pdf',
    'image/jpeg': 'jpeg',
    'image/png': 'png',
    'image/tiff': 'tiff',
  },
  [DXF]: {
    '.dxf': 'dxf',
    'application/x-compressed,application/x-zip-compressed,application/zip':
      'zip-архив с dxf',
  },
};

 
export default class Files {
  static getAllowedFormatsMessage = (fileType) =>
    'Доступные форматы для сохранения: ' +
    Object.values(fileTypes[fileType]).join(', ');

  static getAllowedMIMETypes = (fileType) =>
    Object.keys(fileTypes[fileType]).join(',');
}
