import { get } from 'app/api/crud/get';
import { toast } from 'core/uiKit/components/Toast';
import { snake_case } from 'core/utils/snake_case';

interface IFetchDictionaryData {
  id: number;
  name: string;
  code?: string;
  full_name?: string;
}

/**
 * Метод получения словарей.
 *
 * @param dict - Название словаря.
 * @returns - Словарь.
 * @example ---
 */
export const fetchDictionary = async <TData = IFetchDictionaryData[] | never[]>(
  dict: string,
): Promise<TData> => {
  const code = snake_case(dict);

  let res;
  try {
    res = await get<TData>('/dict', { code });
  } catch (error) {
    res = error;
  }

  // @ts-ignore
  if (res?.error) {
    // @ts-ignore
    toast.error(res?.message);
    return [] as TData;
  }
  if (Array.isArray(res)) {
    return res as TData;
  }
  toast.error(`${code} не тот тип`);
  return [] as TData;
};
