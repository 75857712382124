import { IMPROVEMENT_OBJECT, ODH, OZN, YARD } from 'app/constants/oghTypes';
/* eslint-disable  */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

/**
 *
 * @param condition
 */
export const visibleIf = (condition) => (WrappedComponent) => {
  return function Wrapper(props) {
    return condition(props)
      ? React.createElement(WrappedComponent, { ...props })
      : null;
  };
};

/* eslint-disable */
export const accessDeniedIfNot = (condition) => (WrappedComponent) => {
  return class Wrapper extends Component {
    static propTypes = {
      dispatch: PropTypes.func,
    };

    componentDidMount() {
      // todo: убрал на время
      // if (!condition(this.props)) {
      // this.props.dispatch(push('/403'));
      // }
    }

    render() {
      return React.createElement(WrappedComponent, { ...this.props });
    }
  };
};

export const filterTypesMainPage = (condition) => (WrappedComponent) => {
  return class Wrapper extends Component {
    render() {
      return (
        <WrappedComponent
          ref={(target) => (this.target = target)}
          {...this.props}
          grantedTypes={condition(this.props)}
        />
      );
    }
  };
};

export const addProps = (addition) => (condition) => (WrappedComponent) => {
  return function Wrapper(props) {
    return React.createElement(WrappedComponent, {
      ...props,
      ...(condition(props) && addition),
    });
  };
};

/* eslint-disable */
export const addOghTitleListTypes = () => (WrappedComponent) => {
  const map = {
    view_title_odh: ODH,
    view_title_yard: YARD,
    view_title_improvement_object: IMPROVEMENT_OBJECT,
    view_title_ozn: OZN,
  };

  return function Wrapper(props) {
    const { privileges } = props;

    let oghTypeIds = [];
    for (const key in map) {
      if (privileges.includes(key)) {
        oghTypeIds.push(map[key]);
      }
    }

    return React.createElement(WrappedComponent, {
      ...props,
      oghTypeIds: oghTypeIds,
    });
  };
};
