/* eslint-disable */
import React from 'react';
import './ArrowHeader.scss';
import cn from 'classnames';

 
export const ArrowHeader = ({ onClick, active, className }) => {
  const classIcon = cn('arrow-header-card', className, {
    'arrow-header-card__active': active,
  });
  return (
    <i onClick={onClick} className={classIcon}>
      <svg
        width="26"
        height="26"
        viewBox="0 0 22 22"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M11 22C4.97391 22 0 17.0261 0 11C0 4.97391 4.97391 0 11 0C17.0261 0 22 4.97391 22 11C22 17.0261 17.0261 22 11 22ZM11 0.956522C5.45217 0.956522 0.956522 5.45217 0.956522 11C0.956522 16.5478 5.45217 21.0435 11 21.0435C16.5478 21.0435 21.0435 16.5478 21.0435 11C21.0435 5.45217 16.5478 0.956522 11 0.956522Z" />
        <path d="M11 7L16 13L6 13L11 7Z" />
      </svg>
    </i>
  );
};
