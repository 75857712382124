import { functions } from './functions';

/**
 * @typedef {import('core/utils/hooks/usePreparation/preparation/functions').FnPreparation} FnPreparation - Функия форматирования свойства.
 * @typedef {import('core/utils/hooks/usePreparation/preparation/functions').NewKeyFn} NewKeyFn - Функия форматирования нового имени свойства.
 */

/**
 * Добавление Форматтера.
 *
 * @param {string} key - Ключ/имя инпута.
 * @param {FnPreparation} fn - Функция для обработки значения инпута для отправки на бэк.
 * @param {NewKeyFn} newKey - Новое имя инпута если надо переименовать для отправки на бэк, может быть функцией принимающей на вход value.
 * @returns {void} - Жопа.
 * @example ----
 * addPreparation('defaultValueList', (value) => value > 0);
 * // or
 * addPreparation('defaultValueList', (value) => value > 0, 'newDefaultValue');
 * // or
 * addPreparation('defaultValueList', (value) => value > 0, (value)=>value > 0 ? 'plusValueList' : 'minusValueList');
 */
export const addPreparation = (key = 'key', fn = (_) => _, newKey = key) => {
  if (typeof fn === 'function') {
    functions[key] = { fn, newKey };
  }
};
