import './hints.scss';

import { createNumberedList } from 'app/utils/createNumberedList';

/*eslint max-len: ["off"]*/

export default {
  // Общая площадь в границах ОДХ, кв.м
  asperity: createNumberedList({
    header: 'Сумма количества карточек',
    list: [
      '«Инженерные сооружения», для карточек, у которых «Тип» - «ИДН», «ИДН.ТПУ».',
    ],
  }),

  // Общая площадь в ТС, кв.м
  auto_clean_area: createNumberedList({
    header: 'Рассчитывается как сумма значений',
    list: [
      {
        children: ['"Плоскостные сооружения".', '"Дорожно-тропиночные сети"'],
        item: '"Площадь, кв.м." ("Способ уборки": "Механизированная уборка")',
      },
    ],
  }),

  // Общая площадь ТПУ, кв.м
  auto_footway_area: createNumberedList({
    header: 'Сумма значений полей',
    list: [
      '«Тротуары», «Площадь, кв.м», для карточек, у которых «Способ уборки» - «Механизированная уборка»;',
      '«Прочие территории», «Площадь, кв.м», для карточек, у которых установлен признак «Относится к тротуару», «Способ уборки» - «Механизированная уборка».',
    ],
  }),

  // Проезжая часть всего, кв.м
  axis_length: 'Заполняется пользователем вручную.',
  // Обочины, кв.м
  bar_antinoise: createNumberedList({
    header: 'Сумма значений полей',
    list: [
      '«Ограждения», «Протяженность, п.м», для карточек, у которых установлен признак «Включать в ТС», «Тип» - «Защитный экран», «Защитный экран.ТПУ».',
    ],
  }),

  // Тротуары, кв.м
  bar_concrete: createNumberedList({
    header: 'Сумма значений полей',
    list: [
      '«Ограждения», «Протяженность, п.м», для карточек, у которых установлен признак «Включать в ТС», «Тип» - «Бетонный парапет», «Бетонный парапет.ТПУ».',
    ],
  }),

  // Ограждения, кв.м
  bar_foot: createNumberedList({
    header: 'Сумма значений полей',
    list: [
      '«Ограждения», «Протяженность, п.м», для карточек, у которых установлен признак «Включать в ТС», «Тип» - «Пешеходные ограждения ОРУД до 1 м», «Пешеходные ограждения ОРУД до 1 м.ТПУ».',
    ],
  }),

  // Ограждения, п.м
  bar_fracasso: createNumberedList({
    header: 'Сумма значений полей',
    list: [
      '«Ограждения», «Протяженность, п.м», для карточек, у которых установлен признак «Включать в ТС», «Тип» - «МБО. Фракассо», «МБО. Фракассо.ТПУ».',
    ],
  }),

  // Ограждения, шт.
  bar_new_jersey: createNumberedList({
    header: 'Сумма значений полей',
    list: [
      '«Ограждения», «Протяженность, п.м», для карточек, у которых установлен признак «Включать в ТС», «Тип» - «Стенка «Нью-Джерси», «Стенка «Нью-Джерси».ТПУ».',
    ],
  }),

  // Прочие территории, кв.м
  bar_pipe: createNumberedList({
    header: 'Сумма значений полей',
    list: [
      '«Ограждения», «Протяженность, п.м», для карточек, у которых установлен признак «Включать в ТС», «Тип» - «МБО. Труба», «МБО. Труба.ТПУ».',
    ],
  }),

  //Ограждения, кв.м
  bar_trans: createNumberedList({
    header: 'Сумма значений полей',
    list: [
      '«Ограждения», «Протяженность, п.м», для карточек, у которых установлен признак «Включать в ТС», «Тип» - «МБО. Трансбарьер», «МБО. Трансбарьер.ТПУ».',
    ],
  }),

  // Площадь уборки, кв.м
  bar_wave: createNumberedList({
    header: 'Сумма значений полей',
    list: [
      '«Ограждения», «Протяженность, п.м», для карточек, у которых установлен признак «Включать в ТС», «Тип» - «МБО. Волна», «МБО. Волна.ТПУ».',
    ],
  }),

  // Площадь ручной уборки проезжей части (без парковочного пространства), кв.м
  bicycle_area: createNumberedList({
    header: 'Сумма значений полей',
    list: [
      '«Проезжие части», «Площадь, кв.м», для карточек, у которых «Тип» - «Велодорожка», «Велодорожка.ТПУ»;',
      '«Тротуары», «Площадь, кв.м», для карточек, у которых «Тип» - «Не примыкающий к ПЧ.Велодорожка», «Не примыкающий к ПЧ.Велодорожка.ТПУ», «Примыкающий к ПЧ.Велодорожка», «Примыкающий к ПЧ.Велодорожка.ТПУ».',
    ],
  }),

  // Площадь механизированной уборки проезжей части (без парковочного пространства), кв.м
  bicycle_length: createNumberedList({
    header: 'Сумма значений полей',
    list: [
      '«Проезжие части», «Длина, п.м», для карточек, у которых «Тип» - «Велодорожка», «Велодорожка.ТПУ»;',
      '«Тротуары», «Длина, п.м», для карточек, у которых «Тип» - «Не примыкающий к ПЧ.Велодорожка», «Не примыкающий к ПЧ.Велодорожка.ТПУ», «Примыкающий к ПЧ.Велодорожка»,  «Примыкающий к ПЧ.Велодорожка.ТПУ».',
    ],
  }),

  // Площадь ручной уборки парковочного пространства, кв.м
  bound_stone_length: createNumberedList({
    header: 'Сумма значений полей',
    list: ['«Бортовые камни», «Длина, п.м».'],
  }),

  // Площадь механизированной уборки парковочного пространства, кв.м
  buffer: createNumberedList({
    header: 'Сумма значений полей',
    list: [
      '«Ограждения», «Количество, шт.», для карточек, у которых установлен признак «Включать в ТС», «Тип» - «Буфер безопасности», «Буфер безопасности.ТПУ».',
    ],
  }),

  // Балансодержатель
  cleaning_area: createNumberedList({
    header: 'Сумма значений полей',
    list: [
      '«Проезжие части», «Площадь, кв.м», для карточек, у которых «Способ уборки» - «Механизированная уборка»;',
      '«Проезжие части», «Площадь, кв.м», для карточек, у которых «Способ уборки» - «Ручная уборка»;',
      '«Обочины», «Площадь, кв.м», для карточек, у которых «Способ уборки» - «Механизированная уборка»;',
      '«Обочины», «Площадь, кв.м», для карточек, у которых «Способ уборки» - «Ручная уборка»;',
      '«Тротуары», «Площадь, кв.м», для карточек, у которых «Способ уборки» - «Механизированная уборка»;',
      '«Тротуары», «Площадь, кв.м», для карточек, у которых «Способ уборки» - «Ручная уборка»;',
      '«Прочие территории», «Площадь, кв.м», для карточек, у которых установлен признак «Относится к проезжей части», «Способ уборки» - «Механизированная уборка»;',
      '«Прочие территории», «Площадь, кв.м», для карточек, у которых установлен признак «Относится к проезжей части», «Способ уборки» - «Ручная уборка»;',
      '«Прочие территории», «Площадь, кв.м», для карточек, у которых установлен признак «Относится к тротуару», «Способ уборки» - «Механизированная уборка»;',
      '«Прочие территории», «Площадь, кв.м», для карточек, у которых установлен признак «Относится к тротуару», «Способ уборки» - «Ручная уборка»;',
      '«Остановки общественного транспорта», «Площадь уборки, кв.м».',
    ],
  }),

  // Заказчик для Объектов озеленения
  cleaning_guiding_area: `Сумма значений полей:
1. «Ограждения», «Площадь, кв.м», строки у которых установлен признак «Включать в ТС».`,

  // для любых карточек
  cleaning_guiding_length: `Сумма значений полей:
1. "Ограждения", "Количество, п.м", где в карточках указан "Тип" равен
Стенка "Нью-Джерси", Стенка "Нью-Джерси".ТПУ,
МБО.Волна, МБО.Волна.ТПУ,
МБО.Трансбарьер, МБО.Трансбарьер.ТПУ,
МБО.Труба, МБО.Труба.ТПУ,
МБО. Фракассо, МБО. Фракассо.ТПУ,
Пешеходные ограждения ОРУД до 1 м, Пешеходные ограждения ОРУД до 1 м.ТПУ
Тротуарные столбики, Тротуарные столбики.ТПУ
Защитный экран, Защитный экран.ТПУ
Бетонный парапет, Бетонный парапет.ТПУ
установлен признак "Включать в ТС".`,

  // Площадь вывоза снега, кв.м
  cleaning_guiding_qty: `Сумма значений полей:
1. "Ограждения", "Количество, шт", где в карточках указан "Тип" - "Буфер безопасности", "Буфер безопасности.ТПУ" и установлен признак "Включать в ТС".`,

  // Площадь роторной перекидки, кв.м
  customer_id_OZN:
    'Организация, ответственная за санитарное и техническое содержание объекта городского хозяйства',

  // Количество убираемых остановок, ед.
  declare_length: 'Заполните это поле.',

  // Площадь убираемых остановок, кв.м
  distance: 'Заполняется пользователем вручную.',

  // Стенка Нью-Джерси, п.м
  electro_power: '1 кВт/ч = 1.36 л.с.',

  // Буфер безопасности, ед.
  engin_qty: createNumberedList({
    header: 'Сумма количества карточек',
    list: [
      '«Люки смотровых колодцев и решетки водоприемных колодцев»;',
      '«Инженерные сооружения».',
    ],
  }),

  // ИДН, ед.
  footway_area: createNumberedList({
    header: 'Сумма значений полей',
    list: [
      '«Тротуары», «Площадь, кв.м»;',
      '«Прочие территории», «Площадь, кв.м», для карточек, у которых установлен признак «Относится к тротуару».',
    ],
  }),

  // Знаки, ед.
  footway_pole: createNumberedList({
    header: 'Сумма значений полей',
    list: [
      '«Ограждения», «Протяженность, п.м», для карточек, у которых установлен признак «Включать в ТС», «Тип» - «Тротуарные столбики», «Тротуарные столбики.ТПУ».',
    ],
  }),

  // Информационные щиты, ед.
  guiding_area: createNumberedList({
    header: 'Сумма значений полей',
    list: ['«Ограждения», «Площадь, кв.м».'],
  }),

  // Навигационные указатели, ед.
  guiding_length: createNumberedList({
    header: 'Сумма значений полей',
    list: ['«Ограждения», «Протяженность, п.м».'],
  }),

  // Ограждения, п.м
  guiding_qty: createNumberedList({
    header: 'Сумма значений полей',
    list: ['«Ограждения», «Количество, шт.».'],
  }),

  // Ограждения, шт.
  gutters_length: createNumberedList({
    header: 'Сумма значений полей',
    list: [
      '«Бортовые камни», «Длина, п.м», для карточек, у которых установлен признак «Лотковая зона»;',
      '«Инженерные сооружения», «Двухметровая прилотковая зона, п.м»;',
      '«Ограждения», «Двухметровая прилотковая зона, п.м».',
    ],
  }),

  // Защитная стенка, п.м
  horse_power: '1 кВт/ч = 1.36 л.с.',

  // Общая уборочная площадь, кв.м
  inbound_area: createNumberedList({
    header: 'Сумма значений полей',
    list: [
      '«Проезжие части», «Площадь, кв.м»;',
      '«Обочины», «Площадь, кв.м»;',
      '«Тротуары», «Площадь, кв.м»;',
      '«Прочие территории», «Площадь, кв.м»;',
      '«Трамвайные пути», «Площадь, кв.м», для карточек, у которых установлен признак «Обособленные»;',
      '«Инженерные сооружения», «Площадь (в плане), кв.м», для карточек, у которых установлен признак «Входит в общую площадь ОДХ»;',
      '«Малые архитектурные формы (ОДХ)», «Площадь, кв.м», для карточек, у которых установлен признак «Включать в общую площадь ОДХ»;',
      '«Ограждения», «Площадь, кв.м», для карточек, у которых «Местоположение» - «На цоколе (на своей территории)».',
    ],
  }),

  // Общая площадь объекта, кв.м
  info: createNumberedList({
    header: 'Сумма количества карточек',
    list: [
      '«Знаки, указатели и информационные щиты», для карточек, у которых «Тип» - «Дорожный знак больше 13 кв.м (щит)», «Дорожный знак больше 13 кв.м (щит).ТПУ».',
    ],
  }),

  // Площадь ручной уборки, кв. м
  layout_length: 'Заполняется пользователем вручную.',

  // Площадь механизированной уборки, кв. м
  manual_clean_area: createNumberedList({
    header: 'Рассчитывается как сумма значений',
    list: [
      {
        children: ['"Плоскостные сооружения".', '"Дорожно-тропиночные сети"'],
        item: '"Площадь, кв.м." ("Способ уборки": "Ручная уборка")',
      },
      {
        children: [
          '"Закрытые велопарковки"',
          '"Места сборов отходов"',
          '"Газоны"',
        ],
        item: '"Площадь, кв.м."',
      },
      {
        children: ['"ИОКС типа Отмостка"'],
        item: '"Площадь, кв.м."',
      },
      {
        children: ['"Цветники"'],
        item: '"Общая площадь цветника, кв.м"',
      },
      {
        children: [
          'Элементы организации рельефа типа Лестницы',
          'Элементы благоустройства для маломобильных групп населения типа Пандус',
        ],
        item: 'Количество, кв.м.',
      },
    ],
  }),

  //Протяженность по оси, п.м
  manual_edit: 'Заполняется пользователем вручную.',

  //Протяженность суммарная по пикетажам, п.м
  manual_footway_area: createNumberedList({
    header: 'Сумма значений полей',
    list: [
      '«Тротуары», «Площадь, кв.м», для карточек, у которых «Способ уборки» - «Ручная уборка»;',
      '«Прочие территории», «Площадь, кв.м», для карточек, у которых установлен признак «Относится к тротуару», «Способ уборки» - «Ручная уборка».',
    ],
  }),

  //Протяженность объекта по всем осям, п.м
  margin_area: createNumberedList({
    header: 'Сумма значений полей',
    list: ['«Обочины», «Площадь, кв.м».'],
  }),

  //Протяженность осевой разделительной линии, п.м
  margin_auto_clean_area: createNumberedList({
    header: 'Сумма значений полей',
    list: [
      '«Обочины», «Площадь, кв.м», для карточек, у которых «Способ уборки» - «Механизированная уборка».',
    ],
  }),

  //Протяженность лотков, п.м
  margin_manual_clean_area: createNumberedList({
    header: 'Сумма значений полей',
    list: [
      '«Обочины», «Площадь, кв.м», для карточек, у которых «Способ уборки» - «Ручная уборка».',
    ],
  }),

  //Бортовой камень, п.м
  navigation_pointer: createNumberedList({
    header: 'Сумма количества карточек',
    list: [
      '«Знаки, указатели и информационные щиты», для карточек, у которых «Тип» - «Навигационный указатель (ГУ)», «Навигационный указатель (ГУ).ТПУ».',
    ],
  }),

  //Трамвайные пути, п.м
  oopt_full_area: '1 м² = 0.0001 Га',

  //Дорожные знаки, указатели и информационные указатели, шт.
  other_area: createNumberedList({
    header: 'Сумма значений полей',
    list: ['«Прочие территории», «Площадь, кв.м».'],
  }),

  //Светофорные объекты, шт.
  owner_id: 'Балансодержатель территории',

  //Остановки общественного транспорта, шт.
  pointer: createNumberedList({
    header: 'Сумма количества карточек',
    list: [
      '«Знаки, указатели и информационные щиты», для карточек, у которых «Тип» - «Дорожный знак от 1 до 13 кв.м (указатель)», «Дорожный знак от 1 до 13 кв.м (указатель).ТПУ».',
    ],
  }),

  //Инженерные сооружения, шт
  roadway_area: createNumberedList({
    header: 'Сумма значений полей',
    list: [
      '«Проезжие части», «Площадь, кв.м»;',
      '«Прочие территории», «Площадь, кв.м», для карточек, у которых установлен признак «Относится к проезжей части».',
    ],
  }),

  //Площадь уборки тротуаров мех., кв.м
  roadway_noprkg_auto_clean_area: createNumberedList({
    header: 'Сумма значений полей',
    list: [
      '«Проезжие части», «Площадь, кв.м», для карточек, у которых «Способ уборки» - «Механизированная уборка», «Тип» не «Парковка%»;',
      '«Прочие территории», «Площадь, кв.м», для карточек, у которых установлен признак  «Относится к проезжей части», «Способ уборки» - «Механизированная уборка», «Тип» не «Парковка%».',
    ],
  }),

  //Площадь уборки тротуаров ручн., кв.м
  roadway_noprkg_manual_clean_area: createNumberedList({
    header: 'Сумма значений полей',
    list: [
      '«Проезжие части», «Площадь, кв.м», для карточек, у которых «Способ уборки» - «Ручная уборка», «Тип» не «Парковка%»;',
      '«Прочие территории», «Площадь, кв.м», для карточек, у которых установлен признак «Относится к проезжей части», «Способ уборки» - «Ручная уборка», «Тип» не «Парковка%».   ',
    ],
  }),

  //Дорожные указатели, ед.
  roadway_prkg_auto_clean_area: createNumberedList({
    header: 'Сумма значений полей',
    list: [
      '«Проезжие части», «Площадь, кв.м», для карточек, у которых «Способ уборки» - «Механизированная уборка», «Тип» - «Парковка%»;',
      '«Прочие территории», «Площадь, кв.м», для карточек, у которых установлен признак «Относится к проезжей части», «Способ уборки» - «Механизированная уборка», «Тип» - «Парковка%».',
    ],
  }),

  //Общая площадь велодорожек, кв.м
  roadway_prkg_manual_clean_area: createNumberedList({
    header: 'Сумма значений полей',
    list: [
      '«Проезжие части», «Площадь, кв.м», для карточек, у которых «Способ уборки» - «Ручная уборка», «Тип» - «Парковка%»;',
      '«Прочие территории», «Площадь, кв.м», для карточек, у которых установлен признак «Относится к проезжей части», «Способ уборки» - «Ручная уборка», «Тип» - «Парковка%».',
    ],
  }),

  //Протяженность по пикетажным осям велодорожек, п.м
  rotor_area: 'Заполняется пользователем вручную.',

  // Мощность двигателя в кВт/ч
  sign: createNumberedList({
    header: 'Сумма количества карточек',
    list: [
      '«Знаки, указатели и информационные щиты», для карточек, у которых «Тип» - «Дорожный знак до 1 кв.м», «Дорожный знак до 1 кв.м.ТПУ», «Электрифицированный дорожный знак», «Электрифицированный дорожный знак.ТПУ».',
    ],
  }),

  // Мощность двигателя в л.с.
  snow_area: 'Заполняется пользователем вручную.',

  // Общая площадь ООПТ
  station_clean_area: createNumberedList({
    header: 'Сумма значений полей',
    list: ['«Остановки общественного транспорта», «Площадь уборки, кв.м».'],
  }),

  // Металлические барьерные ограждения Волна, п.м
  station_number: createNumberedList({
    header: 'Сумма количества карточек',
    list: [
      '«Остановки общественного транспорта», где значение поля «Площадь уборки, кв.м» не равно 0.',
    ],
  }),

  // Металлические барьерные ограждения Трансэкострой, п.м
  station_qty: createNumberedList({
    header: 'Сумма количества карточек',
    list: ['«Остановки общественного транспорта».'],
  }),

  // Металлические барьерные ограждения Труба, п.м
  total_area: createNumberedList({
    header: 'Рассчитывается как сумма значений',
    list: [
      {
        children: ['"Объекты капитального строительства"'],
        item: '"Площадь застройки, кв.м."',
      },
      {
        children: [
          '"Газоны"',
          '"Дорожно-тропиночные сети"',
          '"Закрытые велопарковки"',
          '"Иные некапитальные объекты"',
          '"ИОКС типа Архитектурный памятник"',
          '"ИОКС типа Объект монументального искусств"',
          '"ИОКС типа Отмостка"',
          '"Места сборов отходов"',
          '"Некапитальные объекты для обеспечения производственной деятельности по содержанию и ремонту территорий (в том числе базы хранения ПГР на дворовых территориях)"',
          '"Плоскостные сооружения"',
          'Водные сооружения',
        ],
        item: '"Площадь, кв.м."',
      },
      {
        children: ['"Цветники"'],
        item: '"Общая площадь цветника, кв.м"',
      },
      {
        children: [
          'Элементы благоустройства для маломобильных групп населения типа Пандус',
          'Элементы организации рельефа типа Лестницы, Подпорные стены, Габион',
          'Элементы системы функционального обеспечения типа Лоток водосточный (решетка), Шахта вентиляционной камеры.',
        ],
        item: 'Количество, кв.м.',
      },
    ],
  }),

  // Металлические барьерные ограждение Фракассо, п.м
  total_area_ts: createNumberedList({
    header: 'Сумма значений полей',
    list: [
      '«Проезжие части», «Площадь, кв.м»;',
      '«Обочины», «Площадь, кв.м»;',
      '«Тротуары», «Площадь, кв.м»;',
      '«Прочие территории», «Площадь, кв.м».',
    ],
  }),

  // Тротуарные столбики, п.м
  total_clean_area: createNumberedList({
    header: 'Рассчитывается как сумма значений',
    list: [
      {
        children: [
          '"Плоскостные сооружения".',
          '"Дорожно-тропиночные сети"',
          '"Закрытые велопарковки"',
          '"Места сборов отходов"',
          '"Газоны"',
        ],
        item: '"Площадь, кв.м."',
      },
      {
        children: ['"ИОКС типа Отмостка"'],
        item: '"Площадь, кв.м."',
      },
      {
        children: ['"Цветники"'],
        item: '"Общая площадь цветника, кв.м"',
      },
      {
        children: [
          'Элементы организации рельефа типа Лестницы',
          'Элементы благоустройства для маломобильных групп населения типа Пандус',
        ],
        item: 'Количество, кв.м.',
      },
    ],
  }),

  // Бетонный парапет, п.м
  tpu_area: createNumberedList({
    header: 'Сумма значений полей',
    list: [
      '«Проезжие части», «Площадь, кв.м», для карточек, у которых «Тип» заканчивается на «ТПУ»;',
      '«Обочины», «Площадь, кв.м», для карточек, у которых «Тип» заканчивается на «ТПУ»;',
      '«Тротуары», «Площадь, кв.м», для карточек, у которых «Тип» заканчивается на «ТПУ»;',
      '«Прочие территории», «Площадь, кв.м», для карточек, у которых «Тип» заканчивается на «ТПУ»;',
      '«Трамвайные пути», «Площадь, кв.м», для карточек, у которых установлен признак «Обособленные», «Тип» заканчивается на «ТПУ»;',
      '«Инженерные сооружения», «Площадь (в плане), кв.м», для карточек, у которых установлен признак «Входит в общую площадь ОДХ», «Тип» заканчивается на «ТПУ»;',
      '«Малые архитектурные формы (ОДХ)», «Площадь, кв.м», для карточек, у которых установлен признак «Включать в общую площадь ОДХ», «Тип» заканчивается на «ТПУ»;',
      '«Ограждения», «Площадь, кв.м», для карточек, у которых «Местоположение» - «На цоколе (на своей территории)», «Тип» заканчивается на «ТПУ».',
    ],
  }),

  //Пешеходные ограждения, п.м
  traff_light_qty: createNumberedList({
    header: 'Сумма количества карточек',
    list: ['«Светофорные объекты».'],
  }),

  // Площадь уборки обочин, мех., кв.м
  traffic_signs_qty: createNumberedList({
    header: 'Сумма количества карточек',
    list: ['«Знаки, указатели и информационные щиты».'],
  }),

  // Площадь уборки обочин, руч., кв.м
  tram_rails_length: createNumberedList({
    header: 'Сумма значений полей',
    list: ['«Трамвайные пути», «Длина путей, п.м».'],
  }),
};
