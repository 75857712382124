import { get } from 'app/api/crud/get';
import {
  RANDOM_ID,
  RANDOM_NUMBER,
  RANDOM_WORD,
} from 'core/forTesting/constants';
import { vi } from 'vitest';

import { fetchList } from './fetchList';

vi.mock('app/api/crud/get');

describe('file fetchList.spec', () => {
  it('нет id', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    get.mockReturnValue(RANDOM_WORD);
    // Act
    const answer = fetchList();

    // Assert
    expect(answer).toStrictEqual([]);
    expect(get).not.toHaveBeenCalled();
  });

  it('get был вызван', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const RETURN = 'return_' + RANDOM_WORD;
    get.mockReturnValue(RETURN);
    const KEY = RANDOM_WORD;
    const ID = RANDOM_ID;
    const F_ = RANDOM_NUMBER;
    // Act
    const answer = fetchList({ F_, id: ID, key: KEY });

    // Assert
    expect(get).toHaveBeenCalledWith(`ogh/${ID}/${KEY}`, {
      F_,
    });
    expect(answer).toBe(RETURN);
  });
});
