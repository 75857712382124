/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { NumberField } from 'core/uiKit/inputs';

const colorProps = ['primary', 'secondary'];
const variantProps = ['filled', 'outlined', 'standard'];

/* eslint-disable */
export const NumberFieldFF = ({ name, ...otherProps }) => {
  return (
    <Field name={name} type="text" defaultValue={otherProps.defaultValue}>
      {({ input: { value, onChange }, meta }) => {
        return (
          <NumberField
            {...otherProps}
            {...{ name, value, onChange }}
            {...{ meta }}
          />
        );
      }}
    </Field>
  );
};

NumberFieldFF.propTypes = {
  color: PropTypes.oneOf(colorProps),
  dark: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string,
  multiline: PropTypes.bool,
  name: PropTypes.string,
  required: PropTypes.bool,
   
  /* eslint-disable */
/**
   * очищаем инпут с сохранением значения
   */
  clear: PropTypes.bool,
  variant: PropTypes.oneOf(variantProps),
};

NumberFieldFF.defaultProps = {
  color: 'primary',
  dark: false,
  disabled: false,
  helperText: '',
  label: '',
  multiline: false,
  name: 'NumberFieldFF',
  required: false,
  variant: 'standard',
};
