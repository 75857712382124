import { ListDataType } from 'app/pages/cardsOgh/components/RightPanelCard/components/DialogEditButton/listDataType';

/**
 * Функция получения идентификаторов реестра.
 *
 * @param {ListDataType} listData - Данные списка.
 * @returns {Array<number>} - Массив идентификаторов реестра.
 */
export const getIds = (listData: ListDataType): Array<number> => {
  return listData.items.map(({ id }) => id);
};
