export const engineer_building_type = [
  {
    code: 'pool',
    id: 1,
    name: 'Бассейн',
    parent_ogh_object_type_list: [40],
    unit_id: 2,
  },
  {
    code: 'another_objects',
    id: 2,
    name: 'Иные объекты',
    parent_ogh_object_type_list: [38],
    unit_id: 2,
  },
  {
    code: 'fountain_oo',
    id: 3,
    name: 'Фонтан (ОО)',
    parent_ogh_object_type_list: [40],
    unit_id: [2, 5],
  },
  {
    code: 'pedestrian_bridge',
    id: 4,
    name: 'Пешеходный мост',
    parent_ogh_object_type_list: [40, 38],
    unit_id: 2,
  },
  {
    code: 'pier',
    id: 5,
    name: 'Пирс',
    parent_ogh_object_type_list: [38],
    unit_id: 2,
  },
  {
    code: 'berth',
    id: 6,
    name: 'Пирс (причал)',
    parent_ogh_object_type_list: [40],
    unit_id: 2,
  },
  {
    code: 'promenade',
    id: 7,
    name: 'Променад',
    parent_ogh_object_type_list: [40],
    unit_id: 2,
  },
  {
    code: 'dry_fountain_dt',
    id: 8,
    name: 'Сухой фонтан (ДТ)',
    parent_ogh_object_type_list: [38],
    unit_id: 2,
  },
  {
    code: 'fountain_dt',
    id: 9,
    name: 'Фонтан (ДТ)',
    parent_ogh_object_type_list: [38],
    unit_id: 2,
  },
  {
    code: 'dry_fountain_oo',
    id: 10,
    name: 'Сухой фонтан (ОО)',
    parent_ogh_object_type_list: [40],
    unit_id: [2, 5],
  },
];
