import { get } from 'app/api/crud/get';

/**
 * Функция.
 *
 * @param {number} id - Id.
 * @param {object} data - Данные запроса.
 * @returns {Promise<unknown>}
 */
export const tech = (id, data) =>
  get(`/tech/${id}`, data, { parseResponse: false });

/**
 * Функция.
 *
 * @param {number} operationId - Id.
 * @returns {Promise<unknown>}
 */
export const fetchMatchingTech = (operationId) =>
  get(`/tech/matching/${operationId}`, null, { parseResponse: false });

/**
 * Функция правки ошибки.
 *
 * @param {number} id - Id.
 * @returns {*}
 */
export const fetchTech = (id) => id;
