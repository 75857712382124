/**
 * @typedef {import("src/app/pages/cardsOgh/components/RightPanelCard/components/TreeRightPanel/Tree").DataItem} DataItem
 * @typedef {import("types/Card").LogicalStateId} LogicalStateId
 */

import { post } from 'app/api/crud/post';

import { deleteZeroGroup } from '../helpers/deleteZeroGroup';
import { prepareData } from '../helpers/prepareData';

const mapStatuses = [1, 3, 4, 6];

/**
 * Проверить статус, что не 2 и не 5.
 *
 * @param {LogicalStateId} statusId - Идентификатор статуса (logical_state_id).
 * @returns {boolean}
 */
const checkStatus = (statusId) => {
  return mapStatuses.includes(statusId);
};

/**
 * Запрос на получение дерева елементов.
 *
 * @param {{ logicalStateId: LogicalStateId, pathId:number, rootId:number, typeId:number }} data - Данные для запроса.
 * @returns {Promise<Array<DataItem>>}
 */
export const fetchTree = async ({ logicalStateId, pathId, rootId, typeId }) => {
  const res = await post('/ogh/tree', {
    pathId,
    rootId,
    typeId,
  });
  const result = prepareData(res || []);

  if (checkStatus(logicalStateId)) {
    return deleteZeroGroup(result);
  }
  return result;
};
