import { useChangeFieldValueRF } from 'app/actions/odsObjectActions/useChangeFieldValueRF';
import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { MafTypeLevel3Field } from 'app/components/card/ogh/LittleForm/components/MafType/fields/MafTypeLevel3.Field/MafTypeLevel3.Field';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { RANDOM_ID } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

vi.mock('app/pages/cardsOgh/hooks/useMode');
vi.mock('app/api/hooks/useGetParentCard');
vi.mock('app/api/hooks/autocomplete/useGetDictionary', () => ({
  useGetDictionary: vi.fn(),
}));
vi.mock('app/actions/odsObjectActions/useChangeFieldValueRF');

/**
 * Дефолтные моки.
 *
 */
const defaultMock = () => {
  useGetParentCard.mockReturnValue({});
  useMode.mockReturnValue({ editMode: true });
  useChangeFieldValueRF.mockReturnValue(vi.fn());
  useGetDictionary.mockReturnValue({ data: [] });
};

describe('🐛 MafTypeLevel3FieldSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    defaultMock();

    // 🔥 Act
    const wrapper = shallow(<MafTypeLevel3Field />);

    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      disabled: true,
      label: 'Детализация',
      name: 'maf_type_level3_id',
      options: [],
      withChange: expect.any(Function),
    });
    expect(wrapper.text()).toBe('<SelectRF />');
  });
  it('🧪 withChange', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const changeFieldValueRFSpy = vi.fn();
    defaultMock();
    useGetDictionary.mockReturnValue({
      data: [
        {
          attribute: {
            name: 'name',
            parent_ogh_object_type_list: [],
            unit_id: RANDOM_ID,
          },
          id: 1,
        },
      ],
    });
    useChangeFieldValueRF.mockReturnValue(changeFieldValueRFSpy);
    const wrapper = shallow(<MafTypeLevel3Field />);
    const withChange = wrapper.prop('withChange');

    // 🔥 Act
    withChange(1);

    // ❓ Assert
    expect(changeFieldValueRFSpy).toHaveBeenCalledWith('unit_id', RANDOM_ID);
  });
  it('🧪 withChange null', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const changeFieldValueRFSpy = vi.fn();
    defaultMock();
    useGetDictionary.mockReturnValue({
      data: [
        {
          attribute: {
            name: 'name',
            parent_ogh_object_type_list: [],
            unit_id: RANDOM_ID,
          },
          id: 1,
        },
      ],
    });
    useChangeFieldValueRF.mockReturnValue(changeFieldValueRFSpy);
    const wrapper = shallow(<MafTypeLevel3Field />);
    const withChange = wrapper.prop('withChange');

    // 🔥 Act
    withChange(2);

    // ❓ Assert
    expect(changeFieldValueRFSpy).toHaveBeenCalledWith('unit_id', null);
  });
  it('🧪 options', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    defaultMock();
    useGetDictionary.mockReturnValue({
      data: [
        {
          attribute: {
            name: 'name',
            parent_ogh_object_type_list: [1, 2, 3],
            unit_id: RANDOM_ID,
          },
          id: 1,
        },
        {
          attribute: {
            name: 'name2',
            parent_ogh_object_type_list: [2, 3],
            unit_id: RANDOM_ID,
          },
          id: 2,
        },
      ],
    });
    useGetParentCard.mockReturnValue({ typeId: 1 });
    const wrapper = shallow(<MafTypeLevel3Field />);

    // 🔥 Act
    const options = wrapper.prop('options');
    const disabled = wrapper.prop('disabled');

    // ❓ Assert
    expect(options).toStrictEqual([
      {
        id: 1,
        name: 'name',
        parent_ogh_object_type_list: [1, 2, 3],
        unit_id: RANDOM_ID,
      },
    ]);
    expect(disabled).toBeFalsy();
  });
});
