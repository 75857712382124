import {
  elementFormGrid,
  renderCustomTextField,
} from 'app/components/card/common/grid';
import { WithReduxFormSelect } from 'app/components/common/SelectField';
import { ODH_AXIS_TOOLTIP } from 'app/constants/messages';
import { column } from 'app/constants/tables';
import getCoatingTypes from 'app/selectors/card/getCoatingTypes';
import getMafPlacement from 'app/selectors/card/getMafPlacement';
import getFormValues from 'app/selectors/form/getFormValues';
import isMandatory from 'app/utils/card/isMandatory';
import PropTypes from 'prop-types';
/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';

const propTypes = {
  card: PropTypes.object,
  required: PropTypes.bool,
  editMode: PropTypes.bool,
  coatingTypes: PropTypes.array,
  currentCoatingGroup: PropTypes.number,
  changeFieldValue: PropTypes.func,
};

function CharacteristicsTab({
  card,
  editMode,
  required,
  currentCoatingGroup,
  coatingTypes,
  placement,
  changeFieldValue,
  formValues,
}) {
  const {
    type_id: typeId,
    odh_side: odhSides,
    zone_name: zoneName,
    coating_group: coatingGroups,
  } = card;

  return (
    <div id="characteristics">
      {elementFormGrid(
        [
          {
            id: 'odh_axis',
            name: 'odh_axis',
            label: 'Ось',
            editable: editMode,
            formValue: true,
            component: renderCustomTextField,
            addition: {
              type: 'text',
              mask: '99',
              formatChars: {
                9: '[а-яА-Я0-9a-zA-Z]',
              },
              tooltipText: ODH_AXIS_TOOLTIP,
            },
          },
          {
            id: 'odh_side_id',
            name: 'odh_side_id',
            editable: editMode,
            formValue: true,
            component: WithReduxFormSelect,
            addition: {
              label: 'Сторона',
              options: odhSides,
              required: isMandatory(typeId, 'odh_side_id', required),
            },
          },
          {
            id: 'bord_begin',
            name: 'bord_begin',
            // костыль что бы 0 отобразился
            defaultValue: formValues.bord_begin,
            label: 'Начало, м',
            editable: editMode,
            formValue: true,
            component: renderCustomTextField,
            addition: {
              type: 'decimal',
              positive: true,
              zero: false,
              digits: 2,
              required,
            },
          },
          {
            id: 'bord_end',
            name: 'bord_end',
            // костыль что бы 0 отобразился
            defaultValue: formValues.bord_end,
            label: 'Конец, м',
            editable: editMode,
            formValue: true,
            component: renderCustomTextField,
            addition: {
              type: 'decimal',
              positive: true,
              zero: false,
              digits: 2,
              required,
            },
          },
          {
            id: 'zone_name',
            name: 'zone_name',
            label: 'Принадлежность элемента к зоне территории',
            editable: false,
            formValue: true,
            defaultValue: zoneName || '',
            component: renderCustomTextField,
          },
          {
            id: 'placement_id',
            name: 'placement_id',
            editable: editMode,
            formValue: true,
            component: WithReduxFormSelect,
            addition: {
              label: 'Местоположение',
              options: placement,
              required: isMandatory(card.type_id, 'placement_id', required),
            },
          },
        ],
        column.TWO,
        'Местоположение',
        typeId,
      )}

      {elementFormGrid(
        [
          {
            id: 'coating_group_id',
            name: 'coating_group_id',
            editable: editMode,
            formValue: true,
            component: WithReduxFormSelect,
            addition: {
              label: 'Вид покрытия',
              options: coatingGroups,
              required: isMandatory(typeId, 'coating_group_id', required),
              withChange: () => {
                changeFieldValue('coating_type_id', null);
              },
            },
          },
          {
            id: 'coating_type_id',
            name: 'coating_type_id',
            editable: editMode && currentCoatingGroup,
            formValue: true,
            component: WithReduxFormSelect,
            addition: {
              label: 'Вид покрытия (уточнение)',
              options: coatingTypes,
              required,
            },
          },
          {
            id: 'area',
            name: 'area',
            label: 'Площадь, кв.м',
            editable: editMode,
            formValue: true,
            component: renderCustomTextField,
            addition: {
              type: 'decimal',
              positive: true,
              digits: 2,
              required: isMandatory(typeId, 'area', required),
            },
          },
        ],
        column.TWO,
        'Характеристики',
        typeId,
      )}

      {elementFormGrid(
        [
          {
            id: 'description',
            name: 'description',
            label: 'Примечание',
            editable: editMode,
            formValue: true,
            component: renderCustomTextField,
            addition: { type: 'text' },
          },
        ],
        column.ONE,
        '',
        typeId,
      )}
    </div>
  );
}

CharacteristicsTab.propTypes = propTypes;

const mapStateToProps = (state, props) => {
  const formValues = getFormValues(state);
  return {
    coatingTypes: getCoatingTypes(state, props),
    currentCoatingGroup: formValues.coating_group_id,
    placement: getMafPlacement(formValues.placement_id)(state),
    formValues,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeFieldValue: (field, value) =>
    dispatch(change('editorCard', field, value)),
});

 
export default connect(mapStateToProps, mapDispatchToProps)(CharacteristicsTab);
