// noinspection JSUnusedLocalSymbols
/* eslint-disable */

import { RANDOM_ID, RANDOM_WORD } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { create } from 'react-test-renderer';

import { renderEditRowCell } from './renderEditRow.Cell';

jest.mock('../DialogEdit.MovementCharacteristics', () => ({
  /**
   *
   * @param props
   */
  DialogEditMovementCharacteristics: (props) => <div {...props} />,
}));

/* eslint-disable */
describe('file renderEditRowCell', () => {
  it('simple test', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    // eslint-disable-next-line testing-library/render-result-naming-convention
    const Comp = renderEditRowCell({
      id: RANDOM_ID,
      original: RANDOM_WORD,
    });

    // Act
    const wrapper = shallow(<Comp />);

    // Assert
    expect(wrapper.text()).toBe('<DialogEditMovementCharacteristics />');
  });

  it('props', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    // eslint-disable-next-line testing-library/render-result-naming-convention
    const Comp = renderEditRowCell({
      id: 34532,
      original: 'RANDOM_WORD',
    });

    // Act
    const root = create(<Comp />);

    // Assert
    expect(root.toJSON()).toMatchSnapshot();
  });
});
