/* eslint-disable */
import React from 'react';

import {
  MATCHING_CHANGE_TYPES_NAME,
  MatchingChangeTypesFF,
} from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';
import { ArrayParam, withDefault } from 'use-query-params';

 
 
/**
 * Операция
 */
 
export const MatchingChangeTypesFilter = (props) => (
  <MatchingChangeTypesFF {...props} className={filterInput} />
);

 
export const matchingChangeTypesParams = {
  [MATCHING_CHANGE_TYPES_NAME]: withDefault(ArrayParam, []),
};
