import 'assets/styles/common.legend.scss';

/* eslint-disable  */
import cn from 'classnames';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

const propTypes = {
  className: PropTypes.string,
  legend: PropTypes.array,
};

/**
 *
 * @param root0
 * @param root0.legend
 * @param root0.className
 */
const Legend = ({ legend, className }) => (
  <div className={cn('legend', className)}>
    <h6>Легенда</h6>
    <ul className="list-inline mb-0">
      {map(legend, (item) => (
        <li className="list-inline-item" key={item.value}>
          <div className={cn(item.className)} style={item.style} /> {item.value}
        </li>
      ))}
    </ul>
  </div>
);

Legend.propTypes = propTypes;

export default memo(Legend);
