import { NumberParam, useQueryParams, withDefault } from 'use-query-params';
/* eslint-disable */

/**
 * Хук получения типа ОГХ для Списка объектов на главной странице.
 *
 * @returns {{changeTypeId: Function, typeId: number}}
 */
export const useGetTypeIdMainPage = () => {
  const [{ typeId }, setTypeId] = useQueryParams({
    typeId: withDefault(NumberParam, 44),
  });

  /**
   *
   * @param typeId
   */
  const changeTypeId = (typeId) => {
    setTypeId({
      typeId,
    });
  };

  return { changeTypeId, typeId };
};
