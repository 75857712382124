import { YesOrNoCell } from 'core/uiKit/components/cells/YesOrNo.Cell';

/**
 *
 *
 * @typedef {import('core/uiKit/components/tables/Table/Table')} Columns
 * @typedef {Columns} ColumnsTreeShrubsList
 */

/**
 * Колонки TreesShrubsList.
 *
 * @type  {Array<ColumnsTreeShrubsList>} - Колонки.
 */
export const columns = [
  {
    Header: 'Номер участка',
    accessor: 'section_num',
    rowSpan: 3,
  },
  {
    Header: 'Номер биогруппы',
    accessor: 'bio_group_num',
    rowSpan: 3,
  },
  {
    Header: 'Номер растения',
    accessor: 'green_num',
    rowSpan: 3,
  },
  {
    Header: 'Тип насаждения',
    accessor: 'plantation_type',
    rowSpan: 3,
  },
  {
    Header: 'Жизненная форма',
    accessor: 'life_form_type',
    rowSpan: 3,
  },
  {
    Header: 'Вид растения',
    accessor: 'plant_type',
    rowSpan: 3,
  },
  {
    Header: 'Количество растений',
    accessor: 'plant_quantity',
    columns: [
      {
        Header: 'шт.',
        accessor: 'quantity',
        rowSpan: 2,
      },
      {
        Header: 'кв.м',
        accessor: 'area',
        rowSpan: 2,
      },
      {
        Header: 'п.м',
        accessor: 'distance',
        rowSpan: 2,
      },
    ],
  },
  {
    Header: 'Диаметр на высоте 1,3 м., см.',
    accessor: 'diameter',
    rowSpan: 3,
  },
  {
    Header: 'Высота, м.',
    accessor: 'height',
    rowSpan: 3,
  },
  {
    Header: 'Возраст, лет',
    accessor: 'age',
    rowSpan: 3,
  },
  {
    Cell: YesOrNoCell,
    Header: 'Акция «Миллион деревьев» (да/нет)',
    accessor: 'million_trees',
    rowSpan: 3,
  },
  {
    Header: 'Особо ценные',
    accessor: 'valuable_plants_name',
    rowSpan: 3,
  },
  {
    Header: 'Состояние',
    accessor: 'status',
    columns: [
      {
        Header: 'Хорошее',
        accessor: 'good',
        columns: [
          {
            Header: 'Без признаков ослабления',
            accessor: 'not_weak',
          },
        ],
      },
      {
        Header: 'Удовл.',
        accessor: 'satisfactory',
        columns: [
          {
            Header: 'Ослабленные',
            accessor: 'weak',
          },
          {
            Header: 'Сильно ослабленные',
            accessor: 'strongly_weak',
          },
        ],
      },
      {
        Header: 'Неудовл.',
        accessor: 'unsatisfactory',
        columns: [
          {
            Header: 'Усыхающие',
            accessor: 'shrinking',
          },
          {
            Header: 'Сухостой текущего года',
            accessor: 'current_deadwood',
          },
          {
            Header: 'Сухостой прошлых лет',
            accessor: 'past_deadwood',
          },
        ],
      },
    ],
  },
  {
    Header: 'Характеристика состояния',
    accessor: 'characteristic_state_gardening',
    rowSpan: 3,
  },
  {
    Header: 'Рекомендации по уходу',
    accessor: 'plant_service_recomendations',
    rowSpan: 3,
  },
  {
    Cell: YesOrNoCell,
    Header: 'Входит в эксплуатационную площадь (да/нет)',
    accessor: 'no_calc',
    rowSpan: 3,
  },
];
