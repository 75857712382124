// 34 Элементы организации рельефа
/* eslint-disable  */

import { getParseAttribute } from 'app/components/card/common/getParseAttribute';
import { parseDate } from 'app/components/card/common/parse';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import getFormValues from 'app/selectors/form/getFormValues';
import { mandatoryFields } from 'app/utils/card/isMandatory';
import { transformValues } from 'app/utils/form/transformation';
import { COATING_GROUP_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingGroupId';
import { COATING_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingTypeId';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';

import { ReliefComponent } from './Relief/Relief.Component';
import submitCard from './submit';

const dictFields = [
  'arrange_element_type_id',
  'unit_id',
  'material_id',
  COATING_GROUP_ID_NAME,
  COATING_TYPE_ID_NAME,
];

const numericFields = [
  {
    name: 'quantity',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  { name: 'face_area', positive: true, type: 'decimal', zero: true },
];

/**
 * Функция валидации.
 *
 * @param {object} values - Значения формы.
 * @returns {object} - Отвалидированные поля.
 * @example ----
 */
const validate = (values) => ({
  ...validateNumeric(values, numericFields),
});

/**
 * Функция создания атрибутов.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {object} - Возвращают атрибуты.
 * @example ----
 */
const createAttribute = (formValues) => ({
  ...createDocumentsAttribute(formValues),
});

/**
 * Функция получения и установки изначальных значений из State.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {*} - Поля.
 * @example ----
 */
export const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);
  // в функции потому что мок не работает
  const improvementElementDocumentsReducer = documentsReducer({
    oghGroupCode: 'yard_subobject',
  });

  // noinspection DuplicatedCode
  const result = {
    createMode: isCreateMode(state, props),
    editMode: isEditMode(state, props),
    enableReinitialize: true,
    formValues: getFormValues(state),

    initialValues: {
      arrange_element_type_id: parse(
        'arrange_element_type_id.arrange_element_type',
      ),
      coating_face_type_id: parse('coating_face_type_id.coating_type'),
      coating_group_id: parse('coating_group_id.coating_group'),
      coating_type_id: parse('coating_type_id.coating_type'),
      endDate: parseDate(props.card.end_date),
      face_area: parse('face_area'),
      is_diff_height_mark: parse('is_diff_height_mark'),
      material_id: parse('material_id.material'),
      no_calc: parse('no_calc') ? parse('no_calc') : 0,
      quantity: parse('quantity'),
      startDate: parseDate(props.card.start_date),
      totalAreaGeo: parse('total_area_geo'),
      unit_id: parse('unit_id.unit'),
    },

    /**
     *
     * @param formValues
     */
    onSubmit: (formValues) => {
      const { card } = props;
      const values = { ...formValues };

      delete values.parent_name;

      const formValuesWithAttribute = {
        ...values,
        ...createAttribute(values),
      };

      validateRequiredFieldsAsync(
        formValuesWithAttribute,
        mandatoryFields[card.type_id],
      );

      submitCard(
        props,
        transformValues(formValuesWithAttribute, {
          dictFields,
          numericFields,
        }),
      );
    },
    required: isRequired(state, props),
    validate,
  };

  return improvementElementDocumentsReducer(result, state, props);
};

/**
 * Функция получения экшенов.
 *
 * @param {Function} dispatch - Функция диспатч.
 * @returns {{changeFieldValue: mapDispatchToProps.changeFieldValue}} - Экшен changeFieldValue.
 * @example ----
 */
const mapDispatchToProps = (dispatch) => ({
  /**
   *
   * @param field
   * @param value
   */
  changeFieldValue: (field, value) => {
    dispatch(change('editorCard', field, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'editorCard' })(ReliefComponent));
