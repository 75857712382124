import { LinkShortIdCell } from 'core/uiKit/components/cells/LinkShortId.Cell';

import ids from './ids';


/* eslint-disable */
export default [
  {
    id: 'root_id',
    name: 'ID',
    Cell: LinkShortIdCell,
    width: 80,
  },
  {
    id: 'buildings_type',
    name: 'Назначение',
  },
  {
    id: 'buildings_type_spec',
    name: 'Уточнение',
  },
  {
    id: ids.address,
    name: 'Адрес',
    width: 250,
  },
  {
    id: 'build_area',
    name: 'Площадь застройки, кв.м.',
  },
  {
    id: 'object_status_name',
    name: 'Статус',
    width: 110,
  },
  {
    id: 'start_date',
    name: 'Дата начала',
    width: 90,
  },
  {
    id: 'end_date',
    name: 'Дата окончания',
    width: 120,
  },
];
