 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */

import { YesOrNoCell } from 'core/uiKit/components/cells/YesOrNo.Cell';
 
/* eslint-disable */
/**
 * Колонка __Лотковая зона__.
 *
 * @type {{ accessor: 'is_gutter_zone', Header: 'Лотковая зона', Cell: YesOrNoCell }}
 * @augments Cell
 */
export const is_gutter_zone = {
  accessor: 'is_gutter_zone',
  Header: 'Лотковая зона',
  Cell: YesOrNoCell,
  sortable: true,
};
