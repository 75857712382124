import {
  /* eslint-disable  */
  AUTO_CLEANING_TYPE,
  MANUAL_CLEANING_TYPE,
  NO_CLEANING_TYPE,
} from 'app/constants/cleaningTypes';
import isCreateMode from 'app/selectors/card/isCreateMode';
import { get } from 'lodash';
import { createSelector } from 'reselect';

/**
 *
 * @param state
 * @param props
 */
const getAutoCleanArea = (state, props) =>
  get(props, 'card.attribute.auto_clean_area');

/**
 *
 * @param state
 * @param props
 */
const getManualCleanArea = (state, props) =>
  get(props, 'card.attribute.manual_clean_area');

/**
 *
 * @param state
 * @param props
 */
const getCreateMode = (state, props) => isCreateMode(state, props);

export default createSelector(
  [getAutoCleanArea, getManualCleanArea, getCreateMode],
  (autoCleanArea, manualCleanArea, createMode) => {
    let result;

    if (!createMode) {
      if (autoCleanArea) {
        result = AUTO_CLEANING_TYPE;
      } else if (manualCleanArea) {
        result = MANUAL_CLEANING_TYPE;
      } else {
        result = NO_CLEANING_TYPE;
      }
    } else {
      result = null;
    }

    return result;
  },
);
