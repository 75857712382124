/* eslint-disable */
import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import { Vin, VIN_NAME } from 'core/uiKit/preparedInputs';
 
export { VIN_NAME };

const VinForm = ({ input, ...props }) => {
  return <Vin {...input} {...props} />;
};

 
 
/**
 * ## Идентификационный номер (VIN)
 *
 * ### Redux Form Field
 *
 * * `type = textField`
 * * `name = vin`
 */
/* eslint-disable */
export const VinRF = ({ name, ...otherProps }) => {
  return <Field {...otherProps} name={VIN_NAME} component={VinForm} />;
};

VinRF.propTypes = {
   
   
/**
   * быбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
/**
   * placeholder
   */
  placeholder: PropTypes.string,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
};
VinRF.defaultProps = {
  disabled: false,
};
