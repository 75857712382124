import './matchingBoundariesOgh.Table.scss';

import Legend from 'app/components/common/Legend';
import { useUser } from 'app/pages/providers';
import { TableSimple } from 'core/uiKit/components/tables/TableSimple';
import React from 'react';
import { useParams } from 'react-router-dom';

import { useGetBoundaryMatchingHistory } from './api';
import { columnsMatchingBoundariesOghTable } from './columns.MatchingBoundariesOgh.Table';

const status = {
  'на согласовании': 'yellow',
  'устранение замечаний': 'red',
  утверждены: 'green',
};

const legendList = [
  {
    className: 'legend_not_mandatory',
    value: 'Не обязательный',
  },
  {
    className: 'legend_add',
    value: 'Утверждены',
  },
  {
    className: 'legend_del',
    value: 'Устранение замечаний',
  },
  {
    className: 'legend_update',
    value: 'На согласовании',
  },
];

/**
 * Таблица согласования границ ОГХ.
 *
 * @returns {JSX.Element}
 */
export const MatchingBoundariesOghTable = () => {
  const { childId, id } = useParams();
  const { hasPermission } = useUser();

  const isPermission = hasPermission('registry_matching_boundary_ogh');

  const { data: table = [], isLoading } = useGetBoundaryMatchingHistory(
    childId || id,
  );

  const colorRowTable = table.map((item) => {
    return {
      ...item,
      trClassName: status[item?.boundary_name?.toLowerCase()],
    };
  });

  return isPermission ? (
    <>
      <h2>Согласование границ ОГХ</h2>
      <TableSimple
        columns={columnsMatchingBoundariesOghTable}
        data={colorRowTable || []}
        isLoading={isLoading}
        total={table?.length}
      />
      <Legend legend={legendList} />
    </>
  ) : null;
};
