import { log } from 'core/utils/log';
import React from 'react';
import MaskedInput from 'react-text-mask';

 
export const TextMask = React.forwardRef((props, inputRef) => {
  const { mask, formatChars, ...other } = props;
  let isMask = !!mask;
  let maskLocal = mask;
  if (isMask) {
    if (typeof mask === 'string') {
      if (formatChars) {
        maskLocal = mask.split('').map((char) => {
          return new RegExp(formatChars[char]);
        });
      } else {
        log.info(
          'посмотри тут .. здесь не совсем как надо работает. не определено как будет работать маска',
        );
        maskLocal = [/d/];
      }
    }
  }

  return (
    <MaskedInput
      showMask
      guide={false}
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={maskLocal}
    />
  );
});
