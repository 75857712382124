import { DropDown } from 'core/uiKit/components/DropDown';
import { ListItem } from 'core/uiKit/material-ui';

import { useUserNav } from './useUserNav';

/**
 * DropDown User в шапке.
 *
 * @returns {JSX.Element}
 */
export const UserNav = () => {
  const {
    handleGoToUser,
    handleManualLogout,
    nameShown,
    goToChangePasswordPage,
  } = useUserNav();

  return (
    <DropDown
      title={
        <div>
          <span>{nameShown}</span>
          <i style={{ marginLeft: '10px' }}>
            <svg
              width="9"
              height="6"
              viewBox="0 0 9 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M4.5 6L0 7.0663e-07L9 0L4.5 6Z" fill="#C4C4C4" />
            </svg>
          </i>
        </div>
      }
    >
      <ListItem button onClick={handleGoToUser}>
        Профиль
      </ListItem>
      <ListItem button onClick={goToChangePasswordPage}>
        Сменить пароль
      </ListItem>
      <ListItem button onClick={handleManualLogout}>
        Выйти
      </ListItem>
    </DropDown>
  );
};
