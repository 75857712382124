import { useFetchOghTable } from 'app/pages/registry/ogh/api';
import { Table, useParamsTable } from 'core/uiKit/components/tables/Table';

import { getColumnsYard } from './columns.Yard';
import { useFilterQueryParamsYard } from './Filter.Yard';

/**
 * Компонент таблицы __Дворовые территории__.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - Id объекта ОГX текущего.
 * @returns {JSX.Element} - JSX.
 * @example
 * <TableYard typeId={typeId} />
 */
export const TableYard = ({ typeId }) => {
  const { prepareFilters } = useFilterQueryParamsYard();
  const { queryTable } = useParamsTable();

  const queryKey = [
    'tableYard',
    {
      ...queryTable,
      ...prepareFilters,
      typeId,
    },
  ];

  const { table, isLoading, count } = useFetchOghTable(queryKey);
  return (
    <Table
      columns={getColumnsYard() || []}
      data={table || []}
      isLoading={isLoading}
      total={count}
    />
  );
};
