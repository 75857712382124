import { FormCardFF } from 'app/pages/cardsOgh/components/FormCard.FF';
import React from 'react';

import { SubmitDialogValidate } from './dialogs/SubmitDialogValidate/SubmitDialogValidate';
import { TankLeftPanel } from './TankLeftPanel';
import { useSetupTank } from './useSetup.Tank';

/**
 * Карточка Емкости для хранения ПГР.
 *
 * @returns {JSX.Element}
 */
export const Tank = () => {
  const { initialValues, submit } = useSetupTank();

  return (
    <FormCardFF onSubmit={submit} initialValues={initialValues}>
      <SubmitDialogValidate />
      <TankLeftPanel />
    </FormCardFF>
  );
};
