import { log } from 'core/utils/log';
import React, {
  createContext,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import useOnPreviousChange from './hooks/useOnPreviousChange';
import { initialMapglContextValue } from './initialMapglContextValue';
import { useMapglContext } from './MapglContextProvider';
import { GeometryUtils } from './utils';
import { MapService } from './utils/MapService';
import { Polyline } from './utils/Polyline';
import {
  AllGeometries,
  GenericGeometryOptions,
  GenericRawGeometry,
  GeometryTypes,
  MapglContextValue,
  MapglEditorContextValue,
  Polygon,
  RawGeometry,
} from './utils/types.d';

export const MapglEditorContext = createContext<MapglEditorContextValue>({
  ...initialMapglContextValue,

  /**
   * Cancels the current editing mode, resets geometries, destroys shape editor,
   * resets selected geometry, redraws geometries, and sets editing state to false.
   *
   */
  cancelEditing: () => {
    log.warn('cancelEditing is not implemented');
  },

  /**
   * Finishes the current editing mode, destroys shape editor, and redraws geometries.
   *
   */
  finishEditing: () => {
    log.warn('finishEditing is not implemented');
  },

  /**
   * Deletes geometry.
   *
   * @returns Object.
   */
  getAllGeometries: () => {
    log.warn('getAllGeometries is not implemented');
    return {
      circle: [],
      line: [],
      point: [],
      polygon: [],
    } as unknown as AllGeometries;
  },

  /**
   * Gets selected geometry.
   *
   * @returns RawGeometryH.
   */
  getSelectedGeometry: () => {
    log.warn('getSelectedGeometry is not implemented');
    return null;
  },

  /**
   * On geometry change.
   */
  onGeometryChange: () => {
    log.warn('onGeometryChange is not implemented');
  },

  /**
   * Removes geometry.
   *
   */
  removeGeometry: () => {
    log.warn('removeGeometry is not implemented');
  },

  /**
   * Starts drawing geometry.
   *
   * @returns RawGeometry.
   */
  startDrawingGeometry: () => {
    log.warn('startDrawingGeometry is not implemented');
    return null;
  },
});

export type MapglEditorContextProviderProps = {
  isEditing: boolean;
  recordId: number | string;
  mapService: MapService | null;
  geometryUtils: GeometryUtils | null;
  drawGeometries: MapglContextValue['drawGeometries'];
  children: ReactNode;
  setIsEditing: (state: boolean) => void;
};

/**
 * Checks if the given geometry is a Polygon or LayerPolyline.
 *
 * @param {RawGeometry} geometry - The geometry to check.
 * @returns {boolean} True if the geometry is a Polygon or LayerPolyline, false otherwise.
 */
export const isPolygonOrLayerPolyline = (
  geometry?: RawGeometry,
): geometry is Polygon | Polyline =>
  !!(geometry && geometry.userData.type !== GeometryTypes.Point);

/**
 * Finds editable geometries based on the provided map service and editing status.
 *
 * @param {MapService | null} mapService - The map service to retrieve geometries from.
 * @param {boolean} isEditing - A flag indicating if editing is enabled.
 * @returns A function that returns an object containing editable geometries.
 */
export const findEditableGeometries =
  (mapService: MapService | null, isEditing: boolean) => () => {
    if (!isEditing || !mapService) return null;
    const { parent, children } = mapService.layers;
    const point = parent.point
      .filter((point) => point.userData.oghObjectId === mapService.selectedId)
      .concat(
        children.point.filter(
          (point) => point.userData.oghObjectId === mapService.selectedId,
        ),
      );

    const polygon = parent.polygon
      .filter(
        (polygon) => polygon.userData.oghObjectId === mapService.selectedId,
      )
      .concat(
        children.polygon.filter(
          (polygon) => polygon.userData.oghObjectId === mapService.selectedId,
        ),
      );

    const polyline = parent.polyline
      .filter(
        (polyline) => polyline.userData.oghObjectId === mapService.selectedId,
      )
      .concat(
        children.polyline.filter(
          (polyline) => polyline.userData.oghObjectId === mapService.selectedId,
        ),
      );

    return {
      point,
      polygon,
      polyline,
    };
  };

/**
 * Provider for MapglEditorComponent.
 *
 * @param props - React props.
 * @returns React functional component.
 */
export const MapglEditorContextProvider = memo(
  function MapglEditorContextProvider({
    isEditing,
    mapService,
    geometryUtils,
    children,
    setIsEditing,
  }: MapglEditorContextProviderProps) {
    const mapglContext = useMapglContext();

    useOnPreviousChange(
      useMemo(
        () => ({ geometryUtils, isEditing, mapService }),
        [mapService, geometryUtils, isEditing],
      ),
      (prev) => {
        if (
          (prev.mapService !== mapService ||
            prev.geometryUtils !== geometryUtils ||
            prev.isEditing !== isEditing) &&
          geometryUtils
        ) {
          if (mapService && !mapService.editService) {
            mapService?.createEditService(geometryUtils);
          }

          if (mapService && !mapService.drawService) {
            mapService?.createDrawService(geometryUtils);
          }

          if (isEditing && !mapService?.isEditMode) {
            mapService?.setIsEditMode(true);
            mapService?.enableEditing();
          }

          if (!isEditing && mapService?.isEditMode) {
            mapService?.setIsEditMode(false);
            mapService.finishEditing({ isCanceled: true });
          }
        }
      },
    );

    const startDrawingGeometry = useCallback(
      <Tt extends GeometryTypes>(
        type: Tt,
        options: GenericGeometryOptions<GeometryTypes>,
      ): GenericRawGeometry<Tt> | null => {
        // @ts-ignore
        if (!mapgl) {
          log.warn('mapgl is null');
          return null;
        }
        if (!mapService?.map) {
          log.warn('map is null');
          return null;
        }
        if (!mapService.geometryFactories) {
          log.warn('geometry factories is undefined');
          return null;
        }

        const newRawGeometry: RawGeometry | null = null;

        // @ts-ignore
        mapService.startDrawing(type, {
          ...options,
        });

        return newRawGeometry;
      },
      [mapService],
    );

    const deleteGeometry = useCallback(
      (id: string | number) => {
        mapService?.editService?.deleteGeometry(id);
      },
      [mapService],
    );

    /**
     * Cancels the current editing mode, resets geometries, destroys shape editor,
     * resets selected geometry, redraws geometries, and sets editing state to false.
     *
     */
    const cancelEditing = () => {
      mapService?.cancelEditing();
      // mapService?.finishEditing({ isCanceled: true });
      setIsEditing(false);
    };

    /**
     * Finishes the current editing mode, destroys shape editor, and redraws geometries.
     *
     * @param shouldDraw - Флаг для перерисовки геометрий.
     */
    const finishEditing = (shouldDraw = true) => {
      mapService?.finishEditing({ shouldDraw });
      setIsEditing(false);
    };

    /**
     * MapglEditorContextValue методы управления геометриями на карте.
     */
    const value = useMemo<MapglEditorContextValue>(
      // @ts-ignore
      () => ({
        ...mapglContext,
        cancelEditing,
        deleteGeometry,
        finishEditing,

        /**
         *  Get all geometries.
         *
         * @returns Object.
         */
        getAllGeometries: () =>
          ({
            circle: [],
            line: [],
            point: [],
            polygon: [],
          } as unknown as AllGeometries),

        /**
         * Get selected geometry.
         *
         * @returns Object.
         */
        getSelectedGeometry: () => null,
        startDrawingGeometry,
      }),
      // eslint-disable-next-line
      [mapglContext, startDrawingGeometry],
    );

    return (
      /* @ts-ignore */
      <MapglEditorContext.Provider value={value}>
        {children}
      </MapglEditorContext.Provider>
    );
  },
  () => false,
);

/**
 * A custom hook that provides access to the MapglEditorContext.
 *
 * @returns The MapglEditorContext object.
 */
export const useMapglEditorContext = () => {
  const context = useContext(MapglEditorContext);

  if (context === undefined) {
    throw new Error(
      'useMapglServiceContext must be used within a MapglServiceContext',
    );
  }

  return context;
};
