/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { StringParam } from 'use-query-params';

import {
  BUILDINGS_TYPE_SPEC_NAME,
  BuildingsTypeSpecFF,
} from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
 
/**
 *
 * Уточнение типа
 *
 * @param typeId
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
 
export const BuildingsTypeSpecFilter = ({ typeId, ...props }) => {
  const filter = (buildingsTypeSpec) =>
    buildingsTypeSpec.filter((item) => item.ogh_object_type_id === typeId);
  return (
    <BuildingsTypeSpecFF {...props} className={filterInput} filter={filter} />
  );
};

 
export const buildingsTypeSpecParams = {
  [BUILDINGS_TYPE_SPEC_NAME]: StringParam,
};

BuildingsTypeSpecFilter.propTypes = {
  typeId: PropTypes.number.isRequired,
};
