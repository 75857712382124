/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './CollapseButton.scss';

const CollapseButton = ({ onClick = (_) => _, arrowDown = false }) => {
  return (
    <div
      className={cn('collapse-button', {
        'collapse-button-revert': arrowDown,
      })}
      onClick={onClick}
    />
  );
};

CollapseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  arrowDown: PropTypes.bool.isRequired,
};

 
export default CollapseButton;
