import { RANDOM_ID } from 'core/forTesting/constants';
/* eslint-disable */
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import PlantationTab from './PlantationTab';
import {
  FlowersGardenList,
  LawnList,
  RedBookList,
  TreesShrubsList,
  VerticalLandscapingList,
} from './tables';
import { usePlantationTab } from './usePlantationTab';

vi.mock('./tables', () => ({
  /**
   *
   */
  FlowersGardenList: () => <div />,

  /**
   *
   */
  LawnList: () => <div />,

  /**
   *
   */
  RedBookList: () => <div />,

  /**
   *
   */
  TreesShrubsList: () => <div />,

  /**
   *
   */
  VerticalLandscapingList: () => <div />,
}));
vi.mock('./usePlantationTab', () => ({
  usePlantationTab: vi.fn(),
}));

describe('file PlantationTab', () => {
  it('text', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    usePlantationTab.mockReturnValue({});
    // Act
    const wrapper = shallow(<PlantationTab />);
    // Assert
    expect(wrapper.text()).toStrictEqual('<Card />');
  });
  it('props', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    usePlantationTab.mockReturnValue({});
    // Act
    const wrapper = shallow(<PlantationTab />);
    // Assert
    expect(wrapper.props()).toStrictEqual(
      expect.objectContaining({ id: 'plantation' }),
    );
  });

  it('содержит TreesShrubsList c пропсами', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const EXPANDED = 'EXPANDED_' + RANDOM_ID;
    const HANDLE_CHANGE = vi.fn();
    usePlantationTab.mockReturnValue({
      expanded: EXPANDED,
      handleChange: HANDLE_CHANGE,
    });

    // Act
    const wrapper = shallow(<PlantationTab />);
    // Assert
    expect(wrapper.find(TreesShrubsList).props()).toStrictEqual(
      expect.objectContaining({
        expanded: EXPANDED,
        onChange: HANDLE_CHANGE,
      }),
    );
  });

  it('содержит LawnList c пропсами', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const EXPANDED = 'EXPANDED_' + RANDOM_ID;
    const HANDLE_CHANGE = vi.fn();
    usePlantationTab.mockReturnValue({
      expanded: EXPANDED,
      handleChange: HANDLE_CHANGE,
    });

    // Act
    const wrapper = shallow(<PlantationTab />);
    // Assert
    expect(wrapper.find(LawnList).props()).toStrictEqual(
      expect.objectContaining({
        expanded: EXPANDED,
        onChange: HANDLE_CHANGE,
      }),
    );
  });

  it('содержит FlowersGardenList c пропсами', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const EXPANDED = 'EXPANDED_' + RANDOM_ID;
    const HANDLE_CHANGE = vi.fn();
    usePlantationTab.mockReturnValue({
      expanded: EXPANDED,
      handleChange: HANDLE_CHANGE,
    });

    // Act
    const wrapper = shallow(<PlantationTab />);
    // Assert
    expect(wrapper.find(FlowersGardenList).props()).toStrictEqual(
      expect.objectContaining({
        expanded: EXPANDED,
        onChange: HANDLE_CHANGE,
      }),
    );
  });

  it('содержит VerticalLandscapingList c пропсами', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const EXPANDED = 'EXPANDED_' + RANDOM_ID;
    const HANDLE_CHANGE = vi.fn();
    usePlantationTab.mockReturnValue({
      expanded: EXPANDED,
      handleChange: HANDLE_CHANGE,
    });

    // Act
    const wrapper = shallow(<PlantationTab />);
    // Assert
    expect(wrapper.find(VerticalLandscapingList).props()).toStrictEqual(
      expect.objectContaining({
        expanded: EXPANDED,
        onChange: HANDLE_CHANGE,
      }),
    );
  });

  it('содержит RedBookList c пропсами', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const EXPANDED = 'EXPANDED_' + RANDOM_ID;
    const HANDLE_CHANGE = vi.fn();
    usePlantationTab.mockReturnValue({
      expanded: EXPANDED,
      handleChange: HANDLE_CHANGE,
    });

    // Act
    const wrapper = shallow(<PlantationTab />);
    // Assert
    expect(wrapper.find(RedBookList).props()).toStrictEqual(
      expect.objectContaining({
        expanded: EXPANDED,
        onChange: HANDLE_CHANGE,
      }),
    );
  });
});
