import { MafTypeLevel1 } from 'core/uiKit/preparedInputs/selects/simpleSelect/MafTypeLevel1/MafTypeLevel1';

/**
 * Обертка RF для __Вид МАФ__.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.input - Пропсы инпута.
 * @param {object} props.otherProps - Остальные пропсы.
 * @returns {JSX.Element}
 */
export const MafTypeLevel1WithReduxForm = ({ input, ...otherProps }) => {
  return <MafTypeLevel1 {...input} {...otherProps} />;
};
