 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Вид покрытия на сопряжении с проезжей частью__.
 *
 * @type {{ accessor: 'coating_group_road_name',  Header: 'Вид покрытия на сопряжении с проезжей частью' }}
 * @augments Cell
 */
export const coating_group_road_name = {
  accessor: 'coating_group_road_name',
  Header: 'Вид покрытия на сопряжении с проезжей частью',
  sortable: true,
};
