import './checkbox.scss';

import { getFieldError } from 'app/utils/validate';
import {
  CheckboxMUI,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  ThemeProvider,
} from 'core/uiKit/material-ui';
import PropTypes from 'prop-types';
import React from 'react';

import { useCreateCheckboxTheme } from './useCreateCheckboxTheme';

const colorProps = ['default', 'primary', 'secondary'];
const labelPlacementProps = ['bottom', 'end', 'start', 'top'];
const justifyContentProps = [
  'center',
  'start',
  'end',
  'flex-start',
  'flex-end',
  'left',
  'right',
  'space-between',
  'space-around',
  'space-evenly',
  'stretch',
  'safe center',
  'unsafe center',
  'inherit',
  'initial',
  'unset',
];

/**
 * Чекбокс.
 *
 * @param {object} props - Пропсы.
 * @param {string} props.label - Лейбел.
 * @param {boolean} props.dark - Переключатель темной темы.
 * @param {string} props.className - Класс.
 * @param {*} props.labelPlacement - Any.
 * @param {boolean} props.disabled - Флаг активности редактирования.
 * @param {string} props.errorText - Тест ошибки.
 * @param {string} props.helperText - Вспомогательный текст.
 * @param {boolean} props.required - Флаг обязательности поля.
 * @param {object} props.meta - Мета данные.
 * @param {string} props.value - Значения поля.
 * @param {boolean} props.checked - Выбран или нет чекбокс.
 * @param {boolean} props.justifyContent - Выравнивание Flex.
 * @returns {JSX.Element}
 */
export const Checkbox = ({
  label,
  dark,
  className = '',
  labelPlacement,
  disabled,
  errorText,
  helperText,
  required,
  meta = {},
  value,
  checked,
  justifyContent = 'normal',
  ...props
}) => {
  const theme = useCreateCheckboxTheme({ dark });
  const error = getFieldError(meta);
  const isError = !!error || !!errorText;

  return (
    <ThemeProvider theme={theme}>
      <FormControl {...{ className, disabled, error: isError, required }}>
        <FormControlLabel
          style={{ justifyContent }}
          control={<CheckboxMUI {...props} checked={value || checked} />}
          {...{ labelPlacement }}
          label={<FormLabel className={'checkbox-label'}>{label}</FormLabel>}
        />
        <FormHelperText>
          {error || errorText || helperText} &nbsp;
        </FormHelperText>
      </FormControl>
    </ThemeProvider>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  color: PropTypes.oneOf(colorProps),
  dark: PropTypes.bool,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  helperText: PropTypes.string,
  justifyContent: PropTypes.oneOf(justifyContentProps),
  label: PropTypes.string,
  labelPlacement: PropTypes.oneOf(labelPlacementProps),
  name: PropTypes.string,
  required: PropTypes.bool,
};

Checkbox.defaultProps = {
  checked: false,
  color: 'primary',
  dark: false,
  disabled: false,
  label: '',
  labelPlacement: 'end',
  name: 'Checkbox',
  required: false,
  value: false,
};
