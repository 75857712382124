/* eslint-disable  */

/**
 * @deprecated
 * @see src/app/utils/validate/getFieldError
 */

/**
 *
 * @param meta
 */
export const getFieldError = (meta) => {
  const { error, submitError, dirtySinceLastSubmit, submitting } = meta || {};

  if (error) {
    return error;
  }
  if (submitError && !dirtySinceLastSubmit && !submitting) {
    return submitError;
  }
  return undefined;
};
