import CADESCOM from './cadescom';
import CAPICOM from './capicom';
import LOG_LEVEL from './logLevel';
import XML_DSIG_GOST from './XmlDsigGost';

const constants = {
  CADESCOM,
  CAPICOM,
  LOG_LEVEL,
  XML_DSIG_GOST,
};

 
export default constants;
