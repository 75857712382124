import { cellsMainMap } from 'core/uiKit/components/cells/cellsMainMap';

/**
 * @typedef {import('src/core/uiKit/components/tables/Table/Table').Columns} Columns
 */

/**
 * Получение Columns __Деревья/Кустарники__.
 *
 * @returns {Columns} - Жопа.
 * @example ---
 */
export const getColumnsTreesShrubs = () => [
  cellsMainMap.short_root_id, // ID
  cellsMainMap.section_num, // Номер участка
  cellsMainMap.bio_group_num, // Номер биогруппы
  cellsMainMap.green_num, // Номер растения
  cellsMainMap.plantation_type_name, // Тип насаждения
  cellsMainMap.life_form_type_name, // Жизненная форма
  cellsMainMap.plant_type_name, // Вид растения
  cellsMainMap.valuable_plants_name, // Особо ценные
  cellsMainMap.object_status_name, // Статус
  cellsMainMap.sign_date, // Дата подписания
  cellsMainMap.sign_person, // ФИО подписанта
  cellsMainMap.start_date, // Дата начала
  cellsMainMap.end_date, // Дата окончания
  cellsMainMap.parent_object_name, // Наименование родительского объекта
  cellsMainMap.parent_short_root_id, // Идентификатор родительского объекта
  cellsMainMap.parent_okrug_name, // Округ родительского объекта
  cellsMainMap.parent_owner_name, // Балансодержатель родительского объекта
  cellsMainMap.parent_grbs_name, // ГРБС
  cellsMainMap.quantity, // Количество шт.
  cellsMainMap.area, // Площадь, кв.м
  cellsMainMap.distance, // Количество, м.п.
  cellsMainMap.diameter, // Диаметр на высоте 1,3 м., см.
  cellsMainMap.height, // Высота, м.
  cellsMainMap.age, // Возраст, лет
  cellsMainMap.state_gardening_name, // Уточнение состояния
  cellsMainMap.detailed_state_gardening_name, // Уточнение состояния
  cellsMainMap.characteristic_state_gardening_name, // Характеристика состояния
  cellsMainMap.plant_service_recomendations_name, //Рекомендации по уходу
  cellsMainMap.million_trees, // Акция «Миллион деревьев»
  cellsMainMap.is_diff_height_mark, // Разновысотные отметки
  cellsMainMap.included_in_tl, // Входит в ТС
];
