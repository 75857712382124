import { IPath } from 'types/pages';

interface IPathUsers extends IPath {

  /**
   * Привилегия на RegistryRole (пока не известно - дополнить).
   */
  privilegeRegistryRole: string;

  /**
   * Привилегия на Блокировку(под вопросом).
   */
  privilegeLock: string;
}

/**
 *
 * @type {IPath}
 */
export const pathUsers: IPathUsers = {
  name: 'Реестр пользователей',
  path: 'users',
  privilegeEdit: 'edit_user',
  privilegeLock: 'lock_unlock_user',
  privilegeRegistryRole: 'registry_role',
  privilegeView: 'view_user',
};
