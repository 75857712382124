import { MafTypeLevel1RF } from 'core/form/reduxForm/fields/prepared/selects/simple/MafTypeLavel1.RF';
import React from 'react';

/**
 * Поле __Вид МАФ__.
 *
 * @param {object} props - Пропсы.
 * @param {Function} props.getQuantityCharacteristicsProps - Функция getQuantityCharacteristicsProps.
 * @param {Function} props.changeFieldValue - Функция изменения значений полей redux form.
 * @param {number} props.parentTypeId - Тип родительского ОГХ.
 * @param {Array} props.mafTypeLevel1 - Массив справочных значений.
 * @returns {JSX.Element}
 */
export const MafTypeLevel1RFWrapper = ({
  getQuantityCharacteristicsProps,
  changeFieldValue,
  parentTypeId,
  mafTypeLevel1,
  ...otherProps
}) => {
  return (
    <MafTypeLevel1RF
      {...otherProps}
      filter={(dict) => {
        return parentTypeId
          ? (dict || [])?.filter(({ parent_ogh_object_type_list = [] }) =>
              parent_ogh_object_type_list?.includes(parentTypeId),
            )
          : dict;
      }}
      withChange={(mafTypeLevel1Id) => {
        const mafTypeObject = (mafTypeLevel1 || []).find(
          (item) => item.id === mafTypeLevel1Id,
        );
        const unitId = mafTypeObject ? mafTypeObject.unit_id : null;
        const { disabled } = getQuantityCharacteristicsProps(mafTypeLevel1Id);
        changeFieldValue('maf_type_level2_id', null);
        changeFieldValue('maf_type_level3_id', null);
        changeFieldValue('unit_id', unitId);
        if (disabled) {
          changeFieldValue('maf_quantity_characteristics', null);
        }
      }}
    />
  );
};
