import { asyncValidate as nameFieldAsyncValidate } from 'app/components/card/ogh/ImprovementTerritoryCard/ImprovementObjectCard/components/NameField';
import { CardOgh } from 'types/Card';

import { asyncValidate as improvementCategoriesAsyncValidate } from '../components/ImprovementCategories';
import { asyncValidate as ownerAndCustomerFieldsAsyncValidate } from '../components/OwnerAndCustomerFields';
import { asyncValidate as snowAreaAsyncValidate } from '../components/SnowAreaTab';
import { asyncValidate as typeOtherTerritoryAsyncValidate } from '../components/TypeOtherTerritory';

/**
 * Асинхронная валидация формы. Иные объекты благоустройства.
 *
 * @param formValues - Значения формы.
 * @returns Отвалидированные поля.
 */
export const asyncValidateImprovementObject = (formValues: CardOgh) => {
  return {
    ...ownerAndCustomerFieldsAsyncValidate(formValues),
    ...nameFieldAsyncValidate(formValues),
    ...typeOtherTerritoryAsyncValidate(formValues),
    ...snowAreaAsyncValidate(formValues),
    ...improvementCategoriesAsyncValidate(formValues),
  };
};
