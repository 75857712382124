import { useCheckingItIsChildOfImprovementObject } from 'app/pages/registry/ogh/hooks/useCheckingItIsChildOfImprovementObject';
import {
  CustomerFilter,
  customerParams,
  FilterFF,
  OkrugFilter,
  okrugParams,
  OwnerIdFilter,
  ownerIdParams,
  SectionNumFilter,
  sectionNumParams,
} from 'core/form/filterForm';
import { DateFromFilter, dateFromParams } from 'core/form/filterForm/components/dateFilters/DateFromFilter';
import { DateToFilter, dateToParams } from 'core/form/filterForm/components/dateFilters/DateToFilter';
import {
  OghStatusesFilter,
  oghStatusesParams,
} from 'core/form/filterForm/components/miltiSelect/OghStatusesFilter';
import { GrbsIdFilter, grbsIdParams } from 'core/form/filterForm/components/selects/GrbsId.Filter';
import { IdOrNameFilter, idOrNameParams } from 'core/form/filterForm/components/text/IdOrName.Filter';
import {
  IdOrNameParentFilter,
  idOrNameParentParams,
} from 'core/form/filterForm/components/text/IdOrNameParent.Filter';
import { useFilterQueryParams } from 'core/form/filterForm/hooks/useFilterQueryParams';

 
/**
 * @typedef {object} FilterQueryParamsType - Данные QueryParams для фильтра формы FF.
 * @property {object} prepareFilters - Отформатированые данные для бэка.
 * @property {Function} setFilters - Функция для переноса состояния формы в урл.
 * @property {object} filters - Обычные данные формы.
 */

 
/**
 * Хук QueryParams для фильтра Цветники.
 *
 * @returns {FilterQueryParamsType} Фильтры.
 * @example --------
 * const { setFilters, filters, prepareFilters } = useFilterQueryParamsBoardStone()
 */
export const useFilterQueryParamsFlowersGarden = () => {
  return useFilterQueryParams([
    customerParams,
    dateFromParams,
    dateToParams,
    grbsIdParams,
    idOrNameParams,
    idOrNameParentParams,
    oghStatusesParams,
    okrugParams,
    ownerIdParams,
    sectionNumParams,
  ]);
};

 
/**
 * Filter FF реестров __Цветники__.
 *
 * @param {object} params - Parameters.
 * @param {number} params.parentTypeId - Id типа родителя реестра.
 * @returns {JSX.Element} - JSX.
 * @example -----
 */
export const FilterFlowersGarden = ({ parentTypeId }) => {
  const { setFilters, filters } = useFilterQueryParamsFlowersGarden();
  const { isChildOfImprovementObject } = useCheckingItIsChildOfImprovementObject(parentTypeId);
   
/**
 * Фукция submit фильтра формы FF.
 *
 * @param {object} values - Values.
 * @returns {void} - Nothing.
 * @example ---
 */
  const onSubmit = (values) => {
    setFilters(values, 'push');
  };

  /* eslint-disable */
/**
   * Функия сброса формы FF.
   *
   * @param {object} form - Параметры формы.
   * @param {Function} form.reset - Функция сброса формы FF.
   * @returns {void} - Nothing.
   * @example ---------
   */
  const reset = ({ reset }) => {
    reset();
    setFilters({}, 'replace');
  };

  return (
    <>
      <FilterFF {...{ onSubmit, reset, initialValues: filters }}>
        <IdOrNameFilter label={'ID'} />
        <IdOrNameParentFilter />
        <SectionNumFilter />
        <OghStatusesFilter />
        <DateFromFilter />
        <DateToFilter />
        <GrbsIdFilter hidden={isChildOfImprovementObject} />
        <OkrugFilter />
        <CustomerFilter />
        <OwnerIdFilter />
      </FilterFF>
    </>
  );
};
