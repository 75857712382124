import { INTENSITY_NUM_NAME } from 'core/uiKit/preparedInputs/numberField/IntensityNum/index';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { IntensityNumWithReduxForm } from './IntensityNumWithReduxForm';

/**
 * Поле __Интенсивность движения RF__.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const IntensityNumRF = (props) => {
  return (
    <Field
      {...props}
      component={IntensityNumWithReduxForm}
      name={INTENSITY_NUM_NAME}
    />
  );
};

IntensityNumRF.propTypes = {

  /**
   * Быбор темной темы.
   */
  dark: PropTypes.bool,

  /**
   * Заблокировать на редактирование.
   */
  disabled: PropTypes.bool,

  /**
   * Вывод ошибки.
   */
  errorText: PropTypes.string,

  /**
   * Вывод вспомогательного текста.
   */
  helperText: PropTypes.string,

  /**
   * Placeholder.
   */
  placeholder: PropTypes.string,

  /**
   * Вывод информации об обязательном поле.
   */
  required: PropTypes.bool,
};
IntensityNumRF.defaultProps = {
  disabled: false,
};
