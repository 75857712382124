import { createAttribute as createDocumentsAttribute } from 'app/components/card/ogh/DocumentsTab';
import { createAttribute as createAddressListFieldAttribute } from 'app/components/card/ogh/ImprovementTerritoryCard/components/AddressListField';
import { createAttribute as createAquaAreaAttribute } from 'app/components/card/ogh/ImprovementTerritoryCard/components/AquaAreaTab';
import { createAttribute as improvementCategoriesCreateAttribute } from 'app/components/card/ogh/ImprovementTerritoryCard/components/ImprovementCategories';
import { createAttribute as createOwnerAndCustomerFieldsAttribute } from 'app/components/card/ogh/ImprovementTerritoryCard/components/OwnerAndCustomerFields';
import { vi } from 'vitest';

import { REPAIRS_INFO_LIST_NAME } from '../components/tabs/Repairs.Tab/Table.Repairs.Tab';
import { REPAIRS_INFO_LIST_PLAN_NAME } from '../components/tabs/RepairsPlan.Tab';
import { createAttribute } from './createAttribute';

const ids = {
  address: 're_calc_address_list',
  name: 're_calc_name',
};

vi.mock('app/components/card/ogh/DocumentsTab');
vi.mock(
  'app/components/card/ogh/ImprovementTerritoryCard/components/AddressListField',
);
vi.mock(
  'app/components/card/ogh/ImprovementTerritoryCard/components/AquaAreaTab',
);
vi.mock(
  'app/components/card/ogh/ImprovementTerritoryCard/components/ImprovementCategories',
);
vi.mock(
  'app/components/card/ogh/ImprovementTerritoryCard/components/OwnerAndCustomerFields',
);

describe('createAttribute yard', () => {
  it('should', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    createDocumentsAttribute.mockReturnValue({ bb: 1 });
    createAddressListFieldAttribute.mockReturnValue({ cc: 2 });
    createAquaAreaAttribute.mockReturnValue({ dd: 3 });
    improvementCategoriesCreateAttribute.mockReturnValue({
      ee: 4,
    });
    createOwnerAndCustomerFieldsAttribute.mockReturnValue({
      ff: 5,
    });

    // Act
    const res = createAttribute({
      [REPAIRS_INFO_LIST_PLAN_NAME]: 'REPAIRS_INFO_LIST_PLAN_NAME',
      aa: 23,
      repairs_info_list: 'test',
    });

    // Assert
    expect(res).toStrictEqual({
      [REPAIRS_INFO_LIST_NAME]: 'test',
      [REPAIRS_INFO_LIST_PLAN_NAME]: 'REPAIRS_INFO_LIST_PLAN_NAME',
      [ids.address]: null,
      [ids.name]: null,
      bb: 1,
      cc: 2,
      dd: 3,
      ee: 4,
      ff: 5,
    });

    expect(createDocumentsAttribute).toHaveBeenCalledWith({
      [REPAIRS_INFO_LIST_PLAN_NAME]: 'REPAIRS_INFO_LIST_PLAN_NAME',
      aa: 23,
      repairs_info_list: 'test',
    });
    expect(createAddressListFieldAttribute).toHaveBeenCalledWith({
      [REPAIRS_INFO_LIST_PLAN_NAME]: 'REPAIRS_INFO_LIST_PLAN_NAME',
      aa: 23,
      repairs_info_list: 'test',
    });
    expect(createAquaAreaAttribute).toHaveBeenCalledWith({
      [REPAIRS_INFO_LIST_PLAN_NAME]: 'REPAIRS_INFO_LIST_PLAN_NAME',
      aa: 23,
      repairs_info_list: 'test',
    });
    expect(improvementCategoriesCreateAttribute).toHaveBeenCalledWith({
      [REPAIRS_INFO_LIST_PLAN_NAME]: 'REPAIRS_INFO_LIST_PLAN_NAME',
      aa: 23,
      repairs_info_list: 'test',
    });
    expect(createOwnerAndCustomerFieldsAttribute).toHaveBeenCalledWith({
      [REPAIRS_INFO_LIST_PLAN_NAME]: 'REPAIRS_INFO_LIST_PLAN_NAME',
      aa: 23,
      repairs_info_list: 'test',
    });
  });
});
