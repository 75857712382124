import { Checkbox } from 'core/uiKit/inputs';
import React from 'react';

export const LANDSCAPING_MARK_NAME = 'landscaping_mark';

/**
 * CheckBox __Объект включен в план благоустройства__.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const LandscapingMark = (props) => {
  return (
    <Checkbox
      label={'Объект включен в план благоустройства'}
      name={LANDSCAPING_MARK_NAME}
      {...props}
    />
  );
};
