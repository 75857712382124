import 'assets/styles/admin.roles.cardForm.functions.list.scss';

import cn from 'classnames';
import Checkbox from 'core/newComponents/Checkbox';
import IconButton from 'core/newComponents/IconButton';
import {
  KeyboardArrowDownIcon,
  KeyboardArrowRightIcon,
} from 'core/uiKit/material-ui';
import PropTypes from 'prop-types';
import React from 'react';

/* eslint-disable */
export default class List extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    isReadOnly: PropTypes.bool,
    isNextItemsInline: PropTypes.bool,
    isItemsInline: PropTypes.bool,
    model: PropTypes.string,
    form: PropTypes.array,
    levels: PropTypes.array,
    formCheckboxChange: PropTypes.func,
    levelsChange: PropTypes.func,
  };

  render() {
    const {
      isReadOnly,
      isNextItemsInline,
      isItemsInline,
      model = '',
      form,
      levels,
      formCheckboxChange,
      levelsChange,
    } = this.props;

    if (!form || !form.length) {
      return null;
    }

    return (
      <ul className={cn('list-unstyled my-2 list', this.props.className)}>
        {form.map(function (el, index) {
          return (
            <li
              className={cn({ 'list-inline-item': isItemsInline }, 'li')}
              id={`li_${el.id}`}
              key={el.id}
            >
              <IconButton
                className={cn(
                  {
                    'visibility-hidden': !(
                      el.element_list && el.element_list.length
                    ),
                  },
                  'arrow',
                )}
                iconClassName="material-icons"
                onClick={() => {
                  levelsChange({
                    model: `${model}.${index}.isOpened`,
                    value: !levels[index].isOpened,
                  });
                }}
              >
                {levels[index].isOpened ? (
                  <KeyboardArrowRightIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
              <span className={'checkbox'}>
                <Checkbox
                  checked={!!form[index].is_check}
                  disabled={isReadOnly}
                  label={el.name}
                  onChange={(event, isInputChecked) => {
                    formCheckboxChange({
                      model: `${model}.${index}.is_check`,
                      value: isInputChecked ? 1 : 0,
                    });
                  }}
                />
              </span>
              <List
                className={cn(
                  { 'd-none': !levels[index].isOpened },
                  'listNext',
                )}
                form={el.element_list}
                formCheckboxChange={formCheckboxChange}
                isItemsInline={isNextItemsInline}
                isNextItemsInline={true}
                isReadOnly={isReadOnly}
                levels={levels[index].element_list}
                levelsChange={levelsChange}
                model={`${model}.${index}.element_list`}
              />
            </li>
          );
        })}
      </ul>
    );
  }
}
