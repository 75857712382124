/**
 * Учитывать значения родителей и типов объектов, там, где были добавлены новые значения .
 *
 * @param parent_ogh_object_type_list - Массив чисел.
 * @param parentTypeId - Тип.
 * @returns Boolean.
 */
export function checkByParentOghObjectTypeList(
  parent_ogh_object_type_list: number[] | undefined,
  parentTypeId: number,
) {
  if (!Array.isArray(parent_ogh_object_type_list)) {
    return true;
  }

  if (parent_ogh_object_type_list?.includes(parentTypeId)) {
    return true;
  }
}
