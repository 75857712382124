import getFormValues from 'app/selectors/form/getFormValues';
/* eslint-disable  */
import getDict from 'app/selectors/getDict';
import { createSelector } from 'reselect';

/**
 *
 * @param state
 */
const getCarFuncTypeId = (state) => getFormValues(state).car_func_type_id;

export default createSelector(
  [getCarFuncTypeId, getDict('carFuncTypes'), getDict('carEquipmentType')],
  (carFuncTypeId, carFuncTypes, carEquipmentTypes) => {
    let result;
    if (carFuncTypeId || carFuncTypeId === 0) {
      const carFuncType = carFuncTypes.find(
        (item) => item.id === carFuncTypeId,
      );
      if (carFuncType) {
        if (carFuncType.car_equipment_type_list) {
          const list = carFuncType.car_equipment_type_list;
          result = carEquipmentTypes.filter((item) => list.includes(item.code));
        } else {
          result = carEquipmentTypes;
        }
      } else {
        result = [];
      }
    } else {
      result = carEquipmentTypes;
    }
    return result;
  },
);
