import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const PLANT_TYPE_NAME = 'plantType';

/**
 * ## Вид растения.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const PlantType = (props) => {
  const dict = 'plantType';

  const { data = [] } = useGetDictionary(dict);

  return (
    <Select
      {...props}
      options={data}
      label={'Вид растения'}
      name={PLANT_TYPE_NAME}
    />
  );
};
