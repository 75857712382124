/* eslint-disable */
import React from 'react';
import TableAddButton from 'app/components/common/TableAddButton';
import TableDeleteButton from 'app/components/common/TableDeleteButton';
import TableErrorText from 'app/components/common/TableErrorText';
import Table from 'app/components/common/table/Table';
import Checkbox from 'core/newComponents/Checkbox';
import DialogAddCalibrationFile from 'app/components/dialogs/matchingRegistry/technics/DialogAddCalibrationFile';
import HoverInformatrionIcon from 'app/components/common/HoverInformatrionIcon';

// TODO почему уже есть renderFileList. Может стоит сделать generic компонент для обоих случаев

function getTableData(table) {
  const results = {
    table: {
      columns: [
        {
          key: 'file_name',
          screenName: 'Наименование файла',
          sortable: false,
          databaseName: null,
          cells: [],
        },
        {
          key: 'comment',
          screenName: 'Комментарий',
          sortable: false,
          databaseName: null,
          cells: [],
        },
        {
          key: 'create_date',
          screenName: 'Дата присоединения',
          sortable: false,
          databaseName: null,
          cells: [],
        },
      ],
    },
  };

  table.forEach((item) => {
    results.table.columns.forEach((column) => {
      const columnKey = column.key;
      if (columnKey in item) {
        column.cells.push(item[columnKey]);
      }
    });
  });

  return results;
}

/* eslint-disable */
export default function renderFileList2(props) {
  const table = (props.input.value && props.input.value.table) || [];
  const showDialog =
    (props.input.value && props.input.value.showDialog) || false;
  const results = getTableData(table);
  if (!props.disabled) {
    results.table.columns.unshift({
      key: 'delete',
      screenName: '',
      sortable: false,
      databaseName: null,
      cells: [],
    });
    table.forEach((item, index) => {
      results.table.columns[0].cells.push(
        <Checkbox
          checked={item.checked || false}
          onChange={function (event, value) {
            const character = { ...this.table[this.index], checked: value };
            const newTable = this.table.slice();
            newTable.splice(this.index, 1, character);
            this.onChange({ table: newTable, showDialog: false });
          }.bind({
            index: index,
            table: table,
            onChange: props.input.onChange,
          })}
        />,
      );
    });
  }
  return (
    <div style={{ marginBottom: '5px', ...props.style }}>
      <DisplayOldValue {...props} />
      <DialogAddCalibrationFile
        showCondition={showDialog}
        onCancel={() => {
          props.input.onChange({ table, showDialog: false });
        }}
        onSubmit={(newFile) => {
          const value = table.slice();
          if (newFile) {
            value.push({ ...newFile });
          }
          props.input.onChange({ table: value, showDialog: false });
        }}
      />
      <TableAddButton
        isError={props.meta && props.meta.error}
        title="Добавление файла"
        visible={!props.disabled}
        onAdd={() => {
          props.input.onChange({ table, showDialog: true });
        }}
      />
      <TableDeleteButton
        disabled={
          !table.some((item) => {
            return item.checked;
          })
        }
        visible={!props.disabled}
        onRemove={() => {
          const newTable = table.filter((item) => {
            return !item.checked;
          });
          props.input.onChange({ table: newTable, showDialog: false });
        }}
      />
      <TableErrorText
        errorText={(props.meta && props.meta.error) || null}
        visible={!props.disabled}
      />
      <Table
        {...results}
        height={table.length * 50 + 50}
        width={1150 + (props.disabled ? 0 : 50)}
        widths={(name) => {
          return name === '' ? 50 : 380;
        }}
      />
    </div>
  );
}

function DisplayOldValue(props) {
  const oldTable =
    props.oldValue && props.oldValue.table && props.oldValue.table.length > 0
      ? props.oldValue.table
      : null;
  return oldTable ? (
    <HoverInformatrionIcon>
      <Table
        {...getTableData(oldTable)}
        height={oldTable.length * 50 + 50}
        width={1150}
        widths={(name) => {
          return name === '' ? 50 : 280;
        }}
      />
    </HoverInformatrionIcon>
  ) : null;
}
