import * as preloadApi from 'app/api/preload';
import * as types from 'app/constants/actionTypes';
import { createAction } from 'redux-actions';

 
export const preloadData = createAction(types.PRELOAD_DATA, () => ({
  promise: Promise.all([
    preloadApi.fetchOdsDicts(),
    preloadApi.fetchUserData(),
  ]).then((array) => {
    return {
      odsDicts: array[0],
      userData: array[1],
    };
  }),
}));
