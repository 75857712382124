/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

 
export default class SimpleLink extends React.PureComponent {
  static propTypes = {
    href: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  };

  render() {
    const { name, href, ...props } = this.props;
    return (
      <a href={href} {...props}>
        {name}
      </a>
    );
  }
}
