import { useGetAllowedGeometryTypesFF } from 'app/pages/cardsOgh/components/Map/MapEditPanel/hooks/useGetAllowedGeometryTypes';
import { PanelRightCard } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/Panel';
import React from 'react';

/**
 * Класс MapEditPanelWrapper.
 *
 * @returns {JSX.Element}
 */
const MapEditPanelWrapper = () => {
  const allowedGeometryTypes = useGetAllowedGeometryTypesFF();

  return <PanelRightCard allowedGeometryTypes={allowedGeometryTypes} />;
};

export default MapEditPanelWrapper;
