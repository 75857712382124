import { TextFieldFF } from 'core/form/finalForm/fields/default/TextFieldFF';
/* eslint-disable  */
import { StringParam } from 'use-query-params';

import { filterInput } from '../../filterInputsClasses';
import { ID_OR_NAME_NAME } from './ID_OR_NAME_NAME';
import { usePreparationIdOrNameFilter } from './usePreparation.IdOrNameFilter';

/**
 *
 */
export const idOrNameParams = () => {
  return {
    [ID_OR_NAME_NAME]: StringParam,
  };
};

/* eslint-disable */
/**
 * Наименование/ID   IdOrName
 * @param label
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

export const IdOrNameFilter = ({ label = 'Наименование/ID', ...props }) => {
  usePreparationIdOrNameFilter();
  return (
    <TextFieldFF
      {...props}
      className={filterInput}
      label={label}
      name={ID_OR_NAME_NAME}
    />
  );
};
