import { Quantity } from 'core/uiKit/preparedInputs/textField/Quantity';
import React from 'react';

/**
 * Компонент обертка Quantity для Final Form.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.input - Пропсы поля.
 * @param {object} props.otherProps - Остальные пропсы.
 * @returns {JSX.Element} - Возвращает компонет TotalAreaGeo.
 * @example <Field
 *       name={TOTAL_AREA_GEO_NAME}
 *       component={TotalAreaGeoWithFF}
 *       {...otherProps}
 *     />
 */
export const QuantityWithFF = ({ input, ...otherProps }) => {
  return <Quantity {...input} {...otherProps} />;
};
