import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { MultiSelect } from 'core/uiKit/inputs/selects/MultiSelect';
import React from 'react';

export const CHARACTERISTICS_STATE_GARDENING_ID_NAME =
  'characteristic_state_gardening_id';

/**
 * ## Характеристика состояния.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const CharacteristicStateGardeningId = (props) => {
  const dict = 'CharacteristicStateGardening';
  const { data = [] } = useGetDictionary(dict);
  return (
    <MultiSelect
      {...props}
      label={'Характеристика состояния'}
      options={data}
      name={CHARACTERISTICS_STATE_GARDENING_ID_NAME}
    />
  );
};
