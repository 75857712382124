import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const COATING_GROUP_ID_NAME = 'coating_group_id';

/**
 * @typedef {import('types/inputs').SelectProps} SelectProps
 */

/**
 * Вид покрытия на путях.
 *
 * `type = Select`
 * `name = coating_group_id`.
 *
 * @param {SelectProps} props - Пропсы.
 * @returns {JSX.Element}
 */
export const CoatingGroupId = (props) => {
  const { data: options = [] } = useGetDictionary('coating_group');

  return (
    <Select
      label={props.label || 'Вид покрытия'}
      {...props}
      options={options}
      name={COATING_GROUP_ID_NAME}
    />
  );
};
