import { get } from 'app/api/crud';

 
/* eslint-disable */
/**
 * загрузка файла на клиент по id в хранилище
 * @param fileId
 * @returns {Promise<*>}
 */
 
export const downloadFile = (fileId) =>
  get(`/files/download/${fileId}`, null, {
    parseResponse: false,
  });
