/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import { AutocompleteAsync } from 'core/uiKit/inputs/selects/AutocompleteAsync';
import { fetchCarOwners } from './api';

 
export const CAR_EQUIPMENT_OWNER_NAME = 'carEquipmentOwner';

 
 
/**
 * ## Владелец
 *
 * ###### type = `AutocompleteAsync`
 * ###### name = `carEquipmentOwner`
 * ###### name = `car_equipment_owner`
 *
 * ```js
 * import { CarEquipmentOwner, CAR_EQUIPMENT_OWNER_NAME } from 'core/uiKit/preparedInputs';
 * ```
 *
 *
 */
 
export const CarEquipmentOwner = (props) => {
  return (
    <AutocompleteAsync
      label={'Владелец'}
      {...props}
      fetchFunction={fetchCarOwners}
      name={CAR_EQUIPMENT_OWNER_NAME}
    />
  );
};

CarEquipmentOwner.propTypes = {
   
   
/**
   * очищаем инпут с сохранением значения
   */
  clear: PropTypes.bool,
   
   
/**
   * быбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
/**
   * функция вызова когда выберется итем
   */
  onChange: PropTypes.func,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
   
   
/**
   * значение
   */
  value: PropTypes.any,
};
