import './DialogChangeUserPassword.scss';

import { SaveFFDialog } from 'core/form/finalForm/components/dialogs/SaveFF.Dialog';
import { ConfirmPasswordFF } from 'core/form/finalForm/fields/prepared/textField/ConfirmPasswordFF';
import { OldPasswordFF } from 'core/form/finalForm/fields/prepared/textField/OldPasswordFF';
import { PasswordFF } from 'core/form/finalForm/fields/prepared/textField/PasswordFF';
import { Button } from 'core/uiKit/components/buttons/Button';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { useSendDialog } from './useSendDialog';
import { validateDialogChangePassword } from './validateDialogChangePassword';

/* eslint-disable */
export const DialogChangeUserPassword = ({ username }) => {
  const [isOpen, setIsOpen] = useState(false);
  const openDialog = () => setIsOpen(true);
  const closeDialog = () => setIsOpen(false);

  const [send, { isLoading }] = useSendDialog();

  return (
    <>
      <Button color={'primary'} variant={'contained'} onClick={openDialog}>
        Сменить пароль
      </Button>
      <SaveFFDialog
        {...{
          textHeader: 'Смена пароля',
          isLoading,
          isOpen,
          closeDialog: closeDialog,
          validate: validateDialogChangePassword,
          Body: (
            <div className={'display-grid '}>
              {/*// решение что бы не работал автокоплит браузера*/}
              <input type="password" hidden autoComplete={'new-password'} />
              <OldPasswordFF />
              <PasswordFF />
              <ConfirmPasswordFF />
            </div>
          ),
          onSubmit: (values) => {
            send({
              password: values.password,
              oldPassword: values.oldPassword,
              username: username,
            });
          },
        }}
      />
    </>
  );
};
DialogChangeUserPassword.propTypes = {
  username: PropTypes.string,
};
