import {
  CERTIFICATE_NAME,
  FileSignatureCryptoProFF,
  SIGNATURES_NAME,
} from 'core/form/finalForm/fields/prepared/FileSignatureCryptoProFF';
import { CertInfo } from 'core/uiKit/components/CertInfo';
import { FileListSign } from 'core/uiKit/components/FileList.Sign';
import { Paper } from 'core/uiKit/components/Paper';
import { SignatureList } from 'core/uiKit/components/SignatureList';
import { Alert } from 'core/uiKit/material-ui';
import React, { useState } from 'react';
import { useField } from 'react-final-form';

/**
 * Компонент обертка тела диалогового окна ЭП.
 *
 * @param {object} props - Пропсы.
 * @param {React.ReactNode} props.children - Дети.
 * @param {Array<File>} props.files - Массив файлов.
 * @returns {JSX.Element}
 */
export const DialogBodySignActOfCompleted = ({ children, files }) => {
  const { input: { value: certificate } = {} } = useField(CERTIFICATE_NAME);
  const { input: { value: signatures, onChange: onSignaturesChange } = {} } =
    useField(SIGNATURES_NAME);

  const [error, setError] = useState();

  /**
   * Функция получения ошибки.
   *
   * @param {Event} event - Ошибка.
   * @returns {void} - Nothing.
   */
  const callbackError = (event) =>
    setError(event?.toString().replace(/Error: /g, ''));
  return (
    <>
      <CertInfo certificate={certificate} />
      {error ? (
        <Paper className={'my-2'} elevation={6}>
          <Alert severity="error" variant={'filled'}>
            {error}
          </Alert>
        </Paper>
      ) : null}
      <Paper className={'p-4'}>
        <FileSignatureCryptoProFF
          {...{
            callbackError,
            files: files.map(({ file }) => file),
          }}
        />
      </Paper>
      <FileListSign files={files} />
      <SignatureList
        clearSignatures={onSignaturesChange}
        signatures={signatures}
      />
      <div className={'my-4'}>{children}</div>
    </>
  );
};
