import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

import { usePreparationUnitId } from './usePreparationUnitId';

/**
 * Ед. Измерения.
 */
export const UNIT_ID_NAME = 'unit_id';

/**
 * Select Ед. Измерения.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const UnitId = (props) => {
  usePreparationUnitId();
  const dict = 'unit';

  const { data = [] } = useGetDictionary(dict);

  return (
    <Select
      {...props}
      options={data}
      label={'Ед. измерения'}
      name={UNIT_ID_NAME}
    />
  );
};
