import { FilterOperations } from 'app/components/card/ogh/BasePgmCard/components/OperationsTab/components/Filter.Operations';
import TableSimple from 'core/uiKit/components/tables/TableSimple/TableSimple';
import { preparationValues } from 'core/utils/helpers/preparationValues';

import { basePgmOperationsColumns } from './basePgmOperations.Columns';
import { DownloadOperation } from './components/DownloadOperation';
import { Info } from './components/Info';
import { PrintOperation } from './components/Print.Operation';
import { RefillButton } from './components/RefillButton';
import { ShipmentButton } from './components/ShipmentButton';
import { TableWrapper } from './components/TableWrapper/TableWrapper';
import { useBodyOperations } from './useBody.Operations';

/**
 * Tab - ЖУРНАЛ ПРИХОДА / ОТПУСКА.
 *
 * @returns {JSX}
 */
export const OperationsTab = () => {
  const { table, isLoading, submit, cancel, search } = useBodyOperations();
  return (
    <div id="operations" hidden={true} className={'p-3 mb-3'}>
      <FilterOperations {...{ cancel, search, submit }} />
      <Info />
      <div className={'display-flex gap-2 my-2'}>
        <RefillButton />
        <ShipmentButton />
        <PrintOperation data={preparationValues(search)} />
        <DownloadOperation />
      </div>
      <TableWrapper>
        <TableSimple
          columns={basePgmOperationsColumns}
          data={table || []}
          isLoading={isLoading}
        />
      </TableWrapper>
    </div>
  );
};
