import { get } from 'app/api/crud/get';

/**
 * Автокомплит запрос на Дом, корпус, строение.
 *
 * @param data - Данные.
 * @param data.maxRows - Maximum number of rows.
 * @param data.startsWith - Start with.
 * @param data.street_id - Улица. Дополнительный параметр для запроса.
 * @returns Словарь.
 */
export const fetchAddressDetails = (data: {
  street_id: number;
  maxRows: number;
  startsWith: string;
}): Promise<
  {
    id: number;
    name: string;
    address_name: string;
    is_actual: boolean;
    kl: string;
    unad: string;
    unom: string;
  }[]
> =>
  get<
    {
      id: number;
      name: string;
      address_name: string;
      is_actual: boolean;
      kl: string;
      unad: string;
      unom: string;
    }[]
  >('/autocomplete/address_details', data).then((res) => {
    return res.map((item) => ({
      ...item,
      id: item.id,
      name: item.address_name,
    }));
  });
