import { turnIntoCamelCase } from 'app/utils/api';
/* eslint-disable */

/**
 *
 * @param formValues
 */
export const footwayAreaValidation = (formValues) => {
  const { footwayArea, autoFootwayArea, manualFootwayArea, stationCleanArea } =
    turnIntoCamelCase(formValues);

  if (
    +(+footwayArea).toFixed(2) <
    +(+autoFootwayArea + +manualFootwayArea + +stationCleanArea).toFixed(2)
  ) {
    return {
      footway_area:
        'значение параметра «Тротуары, кв.м» должно быть не меньше суммы значений параметров «Площадь уборки тротуаров мех., кв.м», «Площадь уборки тротуаров ручн., кв.м» и «Площадь убираемых остановок, кв.м',
    };
  }
};
