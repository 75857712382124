import { ODH, OZN, YARD } from 'app/constants/oghTypes';
import { filterInput } from 'core/form/filterForm/components/filterInputsClasses';
import { SelectFF } from 'core/form/finalForm/fields/default/selects/SelectFF';
import React from 'react';
import { NumberParam } from 'use-query-params';

import { useSetupTypeIdOgh } from './useSetupTypeIdOgh';

export const PARENT_TYPE_ID_NAME = 'parent_type_id';
const map = [OZN, ODH, YARD];

/**
 * Фильтр тип ОГХ.
 *
 * @returns {JSX.Element}
 */
export const TypeIdOghFilter = () => {
  const { data } = useSetupTypeIdOgh();
  const options = (data || []).filter((item) => map.includes(item.id));

  return (
    <SelectFF
      name={PARENT_TYPE_ID_NAME}
      options={options}
      label={'Тип ОГХ'}
      className={filterInput}
    />
  );
};

/**
 * QueryParam Уточнение.
 *
 * @type {{"[TYPE_ID_NAME]": NumberParam}}
 * @example -----
 *  const [filters, setFilters] = useQueryParams([
 *     ownerIdParams,
 *     idOrNameParentParams,
 *   ]);
 */
export const parentTypeIdParams = {
  [PARENT_TYPE_ID_NAME]: NumberParam,
};
