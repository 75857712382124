import { validateNumeric } from 'app/components/card/common/validation';
/* eslint-disable */
const numericFields = [
  {
    name: 'snow_clean_area',
    type: 'decimal',
    positive: true,
    zero: true,
  },
];

/* eslint-disable */
export default function validate(values) {
  return validateNumeric(values, numericFields);
}
