import { ContinueFFDialog } from 'core/form/finalForm/components/dialogs/ContinueFF.Dialog';
import { toast } from 'core/uiKit/components/Toast';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

import { externalReject } from './api';
import { BodyDialogReject } from './Body.Dialog.Reject';
import { formValidation } from './formValidation';

/* eslint-disable */
export const DialogReject = ({
  isOpen,
  onClose = (_) => _,
}) => {
  const { objectId } = useGetObjectId();

  const [send, { isLoading }] = useMutationAdaptor(
    externalReject,
    {
      onSuccess: () => {
        toast.success(
          'Уведомление будет отправлено пользователю, создавшему объект',
        );
        onClose();
      },
      onError: (value) => {
        toast.error(value.message);
        onClose();
      },
    },
  );

  return (
    <ContinueFFDialog
      {...{
        textHeader:
          'Отклонить проект, созданный внешней системой',
        isLoading,
        isOpen,
        closeDialog: onClose,
        validate: formValidation,
        Body: <BodyDialogReject />,
        onSubmit: (value) => send({ ...value, objectId }),
      }}
    />
  );
};
