/* eslint-disable  */
import { getFieldError } from 'app/utils/validate';
import {
  AutocompleteMUI,
  ChipMUI,
  CircularProgress,
  TextFieldMUI,
} from 'core/uiKit/material-ui';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';
import { get } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';

/**
 * TransformDataBeforeFetch.
 *
 * @param {string} startsWith - Поисковая фраза.
 * @returns {{
 * maxRows: 12,
 * startsWith,
 * }}
 */
const transformDataBeforeFetch = (startsWith = '') => ({
  maxRows: 12,
  startsWith,
});

/**
 * Асинхронное мульти автозаполнение.
 *
 * @param {*} props - The Properties.
 * @param {string} [props.className] - The name of the class.
 * @param {boolean} [props.disabled] - Заблокировать на редактирование.
 * @param {string} [props.errorText] - Текст ошибки.
 * @param {*} props.fetchFunction - Функция запроса.
 * @param {string} [props.helperText] - Вывод вспомогательного текста.
 * @param {string} props.label - The label.
 * @param {*} [props.meta] - The meta.
 * @param {Function} [props.onChange] - Функция вызова когда выберется итем.
 * @param {string} [props.placeholder] - The placeholder.
 * @param {boolean} [props.required] - Вывод информации об обязательном поле.
 * @param {{}} [props.value] - Значение.
 * @returns {JSX.Element}
 */
export const AutocompleteMultiAsync = ({
  className,
  disabled,
  errorText,
  fetchFunction,
  helperText,
  label,
  meta = {},
  onChange: change,
  placeholder,
  required,
  value = [],
}) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [inputValueDebounced] = useDebounce(inputValue, 300);

  const data = transformDataBeforeFetch(inputValueDebounced);
  const { data: res, isLoading } = useQueryAdaptor(
    ['' + fetchFunction.name, data],
    () => fetchFunction(data),
  );

  useMemo(() => {
    setOptions(res);
  }, [res]);

  /**
   * HandleChange.
   *
   * @param {Event} event - Event.
   * @param {{id: number, name: string}} value - Value.
   * @returns {void}
   */
  const handleChange = (event, value) => {
    change(value);
  };
  const error = getFieldError(meta) || errorText;
  const isError = !!error;

  /**
   * GetByName.
   *
   * @param {{}} value - Value.
   * @returns {void}
   */
  const getByName = (value) => get(value, 'name', '');

  return (
    <AutocompleteMUI
      className={className}
      disabled={disabled}
      filterOptions={(options) => options}
      getOptionLabel={(option) => getByName(option)}
      getOptionSelected={(option, value) => {
        if (!value) {
          return [];
        }
        return option?.name === value?.name;
      }}
      limitTags={2}
      loading={isLoading}
      multiple={true}
      options={options || []}
      renderInput={(params) => (
        <TextFieldMUI
          {...params}
          required={required}
          error={isError}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          label={label}
          placeholder={placeholder}
          helperText={error || helperText || <span>&nbsp;</span>}
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <ChipMUI
            label={option.name}
            size="small"
            variant="outlined"
            {...getTagProps({ index })}
          />
        ))
      }
      style={{ width: '100%' }}
      value={value}
      loadingText={'Загрузка...'}
      openText="Открыть"
      closeText="Закрыть"
      clearText="Очистить"
      noOptionsText="Ничего не найдено"
      onChange={handleChange}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
    />
  );
};

AutocompleteMultiAsync.defaultProps = {
  dark: false,
  disabled: false,
  helperText: '',
  label: 'AutocompleteMultAsync',

  /**
   *
   * @param _
   */
  onChange: (_) => _,
  placeholder: 'Начните вводить',
  required: false,
  value: [],
};
