import getFormValues from 'app/selectors/form/getFormValues';
/* eslint-disable  */
import getDict from 'app/selectors/getDict';
import { createSelector } from 'reselect';

/**
 *
 * @param state
 */
const getContainerTypeId = (state) => getFormValues(state).bin_type_id;
const binCode = 'bin';

export default createSelector(
  [getContainerTypeId, getDict('binTypes')],
  (typeId, binTypes) => {
    const binContainerType = binTypes.find((item) => item.id === typeId);
    return !!binContainerType && binContainerType.code === binCode;
  },
);
