import getDict from 'app/selectors/getDict';
/* eslint-disable */
import { get, uniq } from 'lodash';
import { createSelector } from 'reselect';

/**
 *
 * @param state
 * @param ownProps
 */
const getTableRecords = (state, ownProps) => get(ownProps, 'input.value.table');

const getSelectableReagents = createSelector(
  [getTableRecords, getDict('reagents')],
  (tableRecords, reagents) => {
    const reagentIdsToExclude =
      (tableRecords && tableRecords.map((item) => item.reagent_id)) || [];

    return reagents.filter((item) => !reagentIdsToExclude.includes(item.id));
  },
);

export default createSelector(
  [getSelectableReagents, getDict('reagentKinds')],
  (selectableReagents, reagentKinds) => {
    const reagentKindIdsToInclude = uniq(
      selectableReagents.map((item) => item.reagent_kind_id),
    );

    return reagentKinds.filter((item) =>
      reagentKindIdsToInclude.includes(item.id),
    );
  },
);
