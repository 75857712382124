import { shallow } from 'enzyme';
import { vi } from 'vitest';

import Odh from './odh';

vi.mock('dateformat', () => ({
  DateFormat: vi.fn(),
}));

vi.mock('redux-form', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    reduxForm: vi.fn(() => (Component) => {
      return Component;
    }),
  };
});

vi.mock('react-redux', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    connect: vi.fn(() => (value) => {
      return value;
    }),
  };
});

describe('🐛 spec odh', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const wrapper = shallow(<Odh />);

    // Assert
    expect(wrapper.text()).toBe('<OdhCard />');
  });

  it('🧪 should', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    // 🧹 clear mock

    // 🔥 Act
    const wrapper = shallow(<Odh />);

    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      onPropertyTab: expect.any(Function),
      propertyTab: 'characteristics',
    });
  });
});
