import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
/* eslint-disable  */
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { useEffect, useRef } from 'react';

/**
 *
 */
export const useStartEditModeCardCreateEffect = () => {
  const { startEditMode } = useEditMode();
  const startEditModeMemo = useRef(startEditMode);
  const { rootId } = useGetCurrentCard();

  useEffect(() => {
    if (!rootId) {
      startEditModeMemo.current();
    }
  }, [rootId]);
};
