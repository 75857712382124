import {
  AddressFilter,
  addressParams,
  CreateTypeFilter,
  createTypeParams,
  CustomerOznFilter,
  customerOznParams,
  DateFromFilter,
  dateFromParams,
  DateToFilter,
  dateToParams,
  DepartmentFilter,
  departmentParams,
  FilterFF,
  IdOrNameFilter,
  idOrNameParams,
} from 'core/form/filterForm';
import {
  OghStatusesFilter,
  oghStatusesParams,
} from 'core/form/filterForm/components/miltiSelect/OghStatusesFilter';
import { GrbsIdFilter, grbsIdParams } from 'core/form/filterForm/components/selects/GrbsId.Filter';
import {
  GreeningAdditionFilter,
  greeningAdditionParams,
} from 'core/form/filterForm/components/yesOrNot/GreeningAddition.Filter';
import {
  ReApproveRequiredFilter,
  reApproveRequiredParams,
} from 'core/form/filterForm/components/yesOrNot/ReApproveRequired.Filter';
import {
  UpdateRequiredFilter,
  updateRequiredParams,
} from 'core/form/filterForm/components/yesOrNot/UpdateRequired.Filter';
import { preparationSearchFormValues } from 'core/utils/helpers';
import { useQueryParams } from 'core/utils/hooks';

 
/**
 * Hook useFilterQueryParamsYard.
 *
 * @returns {{prepareFilters: {object}, setFilters: Function, filters: {object}}} - Фильтры.
 * @example ---
 */
export const useFilterQueryParamsYard = () => {
  const [filters, setFilters] = useQueryParams([
    addressParams,
    createTypeParams,
    customerOznParams,
    dateFromParams,
    dateToParams,
    departmentParams,
    greeningAdditionParams,
    idOrNameParams,
    grbsIdParams,
    oghStatusesParams,
    reApproveRequiredParams,
    updateRequiredParams,
  ]);

  return {
    filters,
    prepareFilters: preparationSearchFormValues(filters),
    setFilters,
  };
};

 
/**
 * FilterOzn.
 *
 * @returns {JSX.Element} - JSX.
 * @example ---
 */
export const FilterOzn = () => {
  const { setFilters, filters } = useFilterQueryParamsYard();

   
/**
 * OnSubmit.
 *
 * @param {object} values - Values.
 * @returns {Promise<void>}- Промис.
 * @example ---
 */
  const onSubmit = async (values) => {
    setFilters(values, 'push');
  };

  /* eslint-disable */
/**
   * Reset.
   *
   * @param {object} form - The form.
   * @returns {void} - Nothing.
   * @example ---
   */
  const reset = (form) => {
    form.reset();
    setFilters({}, 'replace');
  };
  return (
    <FilterFF {...{ onSubmit, reset, initialValues: filters }}>
      <IdOrNameFilter />
      <OghStatusesFilter />
      <DateFromFilter />
      <DateToFilter />
      <AddressFilter />
      <CustomerOznFilter />
      <DepartmentFilter />
      <CreateTypeFilter />
      <GreeningAdditionFilter />
      <UpdateRequiredFilter />
      <ReApproveRequiredFilter />
      <GrbsIdFilter />
    </FilterFF>
  );
};
