import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { VerticalLandscapingRefTypeIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/VerticalLandscapingRefTypeId.FF';
import { VERTICAL_LANDSCAPING_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/VerticalLandscapingTypeId';
import React from 'react';
import { useField } from 'react-final-form';

import { usePreparationVerticalLandscapingRefTypeId } from './usePreparationVerticalLandscapingRefTypeId';

/**
 * Поле __Вид ЭВО__.
 *
 * @returns {JSX.Element}
 */
export const VerticalLandscapingRefTypeIdField = () => {
  const { editMode } = useEditMode();
  usePreparationVerticalLandscapingRefTypeId();

  const {
    input: { value: verticalLandscapingTypeId },
  } = useField(VERTICAL_LANDSCAPING_TYPE_ID_NAME);

  /**
   * Функция фильтрации.
   *
   * @param {Array} dict - Массив опшинов.
   * @returns {Array}
   */
  const filter = (dict) => {
    return dict.filter(({ vertical_landscaping_type_list }) => {
      return vertical_landscaping_type_list.includes(verticalLandscapingTypeId);
    });
  };

  return (
    <VerticalLandscapingRefTypeIdFF
      disabled={!(editMode && verticalLandscapingTypeId)}
      withAll={false}
      required={true}
      filter={filter}
    />
  );
};
