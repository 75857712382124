import { CleanCategoryId } from 'core/uiKit/preparedInputs/selects/simpleSelect/CleanCategoryId';
import React from 'react';

interface CleanCategoryIdWithReduxFormInterface {
  input: {
    value: string;
  };
  disabled: boolean;
}

/**
 * Обертка поля Категория по уборке для redux form.
 *
 * @param props - Пропсы.
 * @param props.input - Параметры Redux form.
 * @returns {JSX.Element}
 */
export const CleanCategoryIdWithReduxForm = ({
  input,
  ...otherProps
}: CleanCategoryIdWithReduxFormInterface) => {
  return <CleanCategoryId {...input} {...otherProps} />;
};
