import { userFetchDataType } from 'app/pages/providers/UserContext/fetchUserData';
/* eslint-disable  */
import { preparedUserData } from 'app/pages/providers/UserContext/preparedUserData';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery/useQueryAdaptor';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { fetchUserData } from './fetchUserData';

/**
 * Хук компонента UserProvider.
 *
 * @returns {*}
 */
export const useSetupUserProvider = (): {
  hasPermission: (str?: string) => boolean | undefined;
  user: userFetchDataType;
} => {
  const navigate = useNavigate();

  const { data = {} } = useQueryAdaptor(['user'], fetchUserData, {
    // @ts-ignore

    /**
     *
     * @param root0
     * @param root0.password_change_message
     * @param root0.username
     */
    // @ts-ignore
    onSuccess: ({
      password_change_message,
      username,
    }: {
      password_change_message: string | null;
      username: string;
    }) => {
      if (password_change_message) {
        navigate(`/auth/${username}/edit-password`);
      }
    },
  });

  const user = useMemo(() => {
    return preparedUserData(data);
  }, [data]);

  /**
   * Fn hasPermission.
   *
   * @param str - Name permission.
   * @returns Доступно или нет.
   */
  const hasPermission = (str = '') => user?.permission[str];

  return { hasPermission, user };
};
