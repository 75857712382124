import * as technologyVersionsActions from 'app/actions/technologyVersions';
import { connect } from 'react-redux';
import { actions as formActions } from 'react-redux-form';
/* eslint-disable */
const labelStyle = {
  color: 'black',
  fontSize: '14px',
};

const floatingLabelStyle = {
  color: 'black',
  fontSize: '19px',
  whiteSpace: 'nowrap',
};

function mapStateToProps(state) {
  return {
    form: state.rrf.technologyVersions,
    formValidity: state.rrf.forms.technologyVersions.$form.validity,
    labelStyle,
    floatingLabelStyle,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onSubmit: function (values) {
      return dispatch(
        technologyVersionsActions.onSubmit({
          rootId: ownProps.rootId,
          code: ownProps.code,
          typeId: ownProps.typeId,
          ...values,
        }),
      );
    },
    onSubmitClick: function () {
      return dispatch(formActions.submit('rrf.technologyVersions'));
    },
    formChange: function (values) {
      return dispatch(
        formActions.change(
          'rrf.technologyVersions.' + values.key,
          values.value,
        ),
      );
    },
    reset: function () {
      dispatch(formActions.reset('rrf.technologyVersions'));
      dispatch(formActions.change('rrf.technologyVersions.carOwner', null));
      dispatch(formActions.change('rrf.technologyVersions.carCustomer', null));
    },
  };
}

 
export default connect(mapStateToProps, mapDispatchToProps);
