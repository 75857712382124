import { CopyToClipboard } from 'core/uiKit/components/CopyToClipboard';
import React from 'react';

/* eslint-disable */

const pipe = (x, ...fn) => fn.reduce((x, fn) => fn(x), x);

const findRegexp = (regexp) => (str) => {
  return str.match(regexp)[0];
};
const generateArr = (str) => (findStr) => {
  const arr = str.split(findStr);
  return [arr[0], findStr, arr[1]];
};

export const copySelectedGeometryMessage = (str) => {
  const arr = pipe(
    str,
    findRegexp(/рассчитанная по геометрии \([\d]+.[\d]+ [а-я]+.[а-я]+\)/gi),
    findRegexp(/[\d]+.[\d]+/g),
    generateArr(str),
  );

  return (
    <>
      {arr.map((str) => {
        if (Number(str)) {
          return <CopyToClipboard>{str}</CopyToClipboard>;
        }
        return str;
      })}
    </>
  );
};
