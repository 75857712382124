import isIntegerNumber from 'core/utils/isIntegerNumber';
import sumFractionalNumbers from 'core/utils/sumFractionalNumbers';

const PRECISION = 2;
const STEP = 0.8;
const ERROR_MESSAGE = `Итоговое число должно быть суммой целого числа и числа, кратного ${STEP} (например: ${sumFractionalNumbers(
  [STEP, 1],
)}, ${sumFractionalNumbers([1, STEP * 3])} = 1+${sumFractionalNumbers([
  STEP * 3,
])} и т.п.)`;

/* eslint-disable */
export default function validateQuantityStepForSolidReagent(value) {
  let valid;
  let error;
  if (isIntegerNumber(value)) {
    valid = true;
  } else if (isMultipleOf(value, STEP)) {
    valid = true;
  } else {
    let testNum = value;
    while (testNum > STEP) {
      testNum = sumFractionalNumbers([testNum, -1], PRECISION);
      if (isMultipleOf(testNum, STEP)) {
        valid = true;
        break;
      }
    }
    if (!valid) {
      error = ERROR_MESSAGE;
    }
  }
  return error;
}

function isMultipleOf(value, divider) {
  return (
    (Number(value) * Math.pow(10, PRECISION)) %
      (divider * Math.pow(10, PRECISION)) ===
    0
  );
}
