import { get } from 'lodash';

import { key } from './metadata';
/* eslint-disable */
const path = `attribute.${key}.other_improvement_object_type`;

function getOptionValue({ mode, dict }) {
  const defaultOtherImprovementObjectType = 2;
  const value = parseInt(
    get(
      mode,
      'options.otherImprovementObjectType',
      defaultOtherImprovementObjectType,
    ),
    10,
  );
  return dict.find((item) => item.id === value) ? value : null;
}


/* eslint-disable */
export default function () {
  return (result, state, props) => {
    const { card, mode } = props;

    const dict = Object.values(
      state?.odsDicts?.otherImprovementObjectType || {},
    );
    const value =
      get(card, path) ||
      getOptionValue({
        mode,
        dict,
      });

    return {
      ...result,
      otherImprovementObjectType: dict,
      initialValues: {
        ...result.initialValues,
        [key]: value,
      },
    };
  };
}
