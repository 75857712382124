import Files from 'app/domain/Files/Files';
import { DXF } from 'app/domain/Files/Files';
import { DndFileUpload } from 'core/uiKit/inputs';
import PropTypes from 'prop-types';
/* eslint-disable */
import React from 'react';

const style = {
  width: '100%',
};

const propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  disabled: PropTypes.bool,
};

function DxfFileUpload({ input: { value, onChange }, disabled }) {
  const handleAdd = (files) => onChange(files[0]);
  const handleRemove = () => onChange(null);

  return (
    <DndFileUpload
      accept={Files.getAllowedMIMETypes(DXF)}
      add={handleAdd}
      allowedFormatsMessage={Files.getAllowedFormatsMessage(DXF)}
      disabled={disabled}
      files={value || []}
      multiple={false}
      remove={handleRemove}
      style={style}
      text="Переместите DXF-файл или ZIP-архив с инвентаризационным планом ОГХ"
    />
  );
}

DxfFileUpload.propTypes = propTypes;

 
export default DxfFileUpload;
