import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { get } from 'app/api/index';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery/index';

/**
 * Запрос на получение опшинов.
 *
 * @param {object} data - Параметры.
 * @returns {Promise<unknown>}
 */
const fetchOghTypes = (data) => get('/autocomplete/ogh_types', data);

/**
 * Хук получения опшинов для фильтра __Тип__.
 *
 * @param {number} typeId - Тип ОГХ.
 * @returns {{data: unknown}}
 */
export const useGetOptionsSubTypeIdFilter = (typeId) => {
  const parent = useGetParentCard();
  const params = { max_rows: 12, parent_type_id: parent.typeId, typeId };

  const { data } = useQueryAdaptor(['getSubTypeId', params], fetchOghTypes);

  return { data };
};
