import { fetchIntersectionsGeometry } from 'app/api/requests/geometry';
import { GeoJSONCoordinatesFormatter } from 'core/uiKit/components/DTW/contexts/utils';

import * as types from '../../constants/actionTypes';

/**
 * Fetch intersections geometry.
 *
 * @param {object} data - Data.
 * @returns {object}
 */
export const fetchCheckGeometryIntersectionsDTW =
  (data) => async (dispatch) => {
    dispatch(requestCheckGeometryIntersections(data));
    const response = await fetchIntersectionsGeometry(data);
    const formattedResponse = {
      ...response,
      geometry: formatResponseToGeoJSON(response.geometry || []),
    };
    dispatch(receiveCheckGeometryIntersections(formattedResponse));
    return formattedResponse;
  };

/**
 * Reques.
 *
 * @param {number} id - A.
 * @returns {object}
 */
const requestCheckGeometryIntersections = (id) => {
  return {
    id,
    type: types.REQUEST_CHECK_INTERSECTIONS,
  };
};

/**
 * A.
 *
 * @param {object} object - Data.
 * @returns {object}
 */
const receiveCheckGeometryIntersections = (object) => {
  return {
    object,
    type: types.RECEIVE_CHECK_INTERSECTIONS,
  };
};

/**
 * Format response to GeoJSON format.
 *
 * @param {object} response - Response.
 * @returns {object} - Response.
 */
export const formatResponseToGeoJSON = (response) => {
  return response.map((geometryData) => {
    const data = {
      ...geometryData,
      lines:
        geometryData.lines?.map((line) => ({
          ...line,
          coordinates: GeoJSONCoordinatesFormatter.polylineToGeoJSON(
            line.coordinates,
          ),
        })) || null,
      points:
        geometryData.points?.map((point) => ({
          ...point,
          coordinates: GeoJSONCoordinatesFormatter.pointToGeoJSON(
            point.coordinates,
          ),
        })) || null,
      polygons:
        geometryData.polygons?.map((polygon) => ({
          ...polygon,
          coordinates: GeoJSONCoordinatesFormatter.polygonToGeoJSON(
            polygon.coordinates,
          ),
        })) || null,
    };

    if (geometryData && 'child_object' in geometryData) {
      data.child_object = geometryData.child_object
        ? formatResponseToGeoJSON(geometryData.child_object)
        : undefined;
    }

    return data;
  });
};
