import { queryClient } from 'app/routes/QueryClientProvider';

/**
 * Функция очистки кеша хука useGetCurrentCard.
 *
 * @returns {void}
 */
export const clearCurrentCardCash = () => {
  queryClient.invalidateQueries({ queryKey: ['getOghCard'] });
};
