import { SelectFF } from 'core/form/finalForm/fields';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';
import { NumberParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

export const STATUS_NAME = 'status';

/**
 * Статус.
 * Status.
 *
 * @returns {JSX.Element}
 */
export const StatusFilter = () => {
  const options = [
    { id: 1, name: 'Активный' },
    { id: -1, name: 'Заблокирован' },
  ];

  return (
    <SelectFF
      className={filterInput}
      {...{ options }}
      label={'Статус'}
      name={STATUS_NAME}
    />
  );
};

/**
 * Params.
 *
 * @returns {*}
 */
export const statusParams = () => {
  addPreparation(STATUS_NAME, (value) => value > 0);
  return {
    [STATUS_NAME]: NumberParam,
  };
};
