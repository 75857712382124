
 
/**
 * Перевод данных для поля DistrictListField.
 *
 * @param {{district_list:Array}} formValues - Значение поля из formValues.
 * @returns {{district_list: Array}} - Переведенные данные массив с числами [{ bti_object: id }, { bti_object: id }].
 * @example
 * function createAttribute(formValues) {
 *   return {
 *     ...createDistrictListFieldAttribute(formValues),
 *   };
 * }
 */
export function createDistrictListFieldAttribute({ district_list }) {
  return {
    district_list: district_list.map((id) => ({
      bti_object: id,
    })),
  };
}
