import { CLEAN_SUB_CATEGORY_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CleanSubCategoryId';
import React from 'react';
import { Field } from 'redux-form';

import { CleanSubCategoryIdWithReduxForm } from './CleanSubCategoryIdWithReduxForm';

/**
 * Поле __Категория по уборке RF__.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const CleanSubCategoryIdRf = (props) => {
  return (
    <Field
      name={CLEAN_SUB_CATEGORY_ID_NAME}
      component={CleanSubCategoryIdWithReduxForm}
      {...props}
    />
  );
};
