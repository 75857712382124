 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */

import { CheckboxCell } from 'core/uiKit/components/cells/Checkbox.Cell';
 
/* eslint-disable */
/**
 * Колонка __Требует актуализации__.
 *
 * @type {{
 * accessor: 'update_required',
 * Header: 'Требует актуализации',
 * sortable: true,
 * Cell: CheckboxCell,
 * }}
 * @augments Cell
 */
export const update_required = {
  accessor: 'update_required',
  Header: 'Требует актуализации',
  sortable: true,
  Cell: CheckboxCell,
};
