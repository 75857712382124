import { asyncValidateTreesShrubs } from './asyncValidate.TreesShrubs';

describe('asyncValidateTreesShrubs', () => {
  describe('когда plantation_type_id находится в списке и distance является обязательным', () => {
    describe('plantation_type_id = 2091979', () => {
      const plantation_type_id = 2091979;

      it('возвращает ошибку, если distance  и quantity не задан для 2091979', () => {
        // Arrange (всякие моки)
        const values = { height: 2, plantation_type_id };

        // Act
        const result = asyncValidateTreesShrubs(values);

        // Assert
        expect(result).toStrictEqual({
          distance: 'обязательное поле',
          quantity: 'обязательное поле',
        });
      });

      it('не возвращает ошибку distance, если distance задан', () => {
        // Arrange (всякие моки)
        const values = { distance: 2, height: 2, plantation_type_id };

        // Act
        const result = asyncValidateTreesShrubs(values);

        // Assert
        expect(result.distance).toBeUndefined();
      });

      it('не возвращает ошибку quantity, если quantity задан', () => {
        // Arrange (всякие моки)
        const values = { height: 2, plantation_type_id, quantity: 2 };

        // Act
        const result = asyncValidateTreesShrubs(values);

        // Assert
        expect(result.quantity).toBeUndefined();
      });
    });

    describe('plantation_type_id = 2091985', () => {
      const plantation_type_id = 2091985;

      it('возвращает ошибку, если distance и quantity не задан для 2091985', () => {
        // Arrange (всякие моки)
        const values = { height: 2, plantation_type_id };

        // Act
        const result = asyncValidateTreesShrubs(values);

        // Assert
        expect(result).toStrictEqual({
          distance: 'обязательное поле',
          quantity: 'обязательное поле',
        });
      });
      it('не возвращает ошибку distance, если distance задан', () => {
        // Arrange (всякие моки)
        const values = { distance: 2, height: 2, plantation_type_id };

        // Act
        const result = asyncValidateTreesShrubs(values);

        // Assert
        expect(result.distance).toBeUndefined();
      });

      it('не возвращает ошибку quantity, если quantity задан', () => {
        // Arrange (всякие моки)
        const values = { height: 2, plantation_type_id, quantity: 2 };

        // Act
        const result = asyncValidateTreesShrubs(values);

        // Assert
        expect(result.quantity).toBeUndefined();
      });
    });

    describe('plantation_type_id = 2091986', () => {
      const plantation_type_id = 2091986;

      it('возвращает ошибку, если distance и quantity не задан для 2091986', () => {
        // Arrange (всякие моки)
        const values = { height: 2, plantation_type_id };

        // Act
        const result = asyncValidateTreesShrubs(values);

        // Assert
        expect(result).toStrictEqual({
          distance: 'обязательное поле',
          quantity: 'обязательное поле',
        });
      });

      it('не возвращает ошибку distance, если distance задан', () => {
        // Arrange (всякие моки)
        const values = { distance: 2, height: 2, plantation_type_id };

        // Act
        const result = asyncValidateTreesShrubs(values);

        // Assert
        expect(result.distance).toBeUndefined();
      });

      it('не возвращает ошибку quantity, если quantity задан', () => {
        // Arrange (всякие моки)
        const values = { height: 2, plantation_type_id, quantity: 2 };

        // Act
        const result = asyncValidateTreesShrubs(values);

        // Assert
        expect(result.quantity).toBeUndefined();
      });
    });

    describe('plantation_type_id = 2091987', () => {
      const plantation_type_id = '2091987';

      it('возвращает ошибку, если distance и quantity  не задан для 2091987', () => {
        // Arrange (всякие моки)
        const values = { height: 2, plantation_type_id };

        // Act
        const result = asyncValidateTreesShrubs(values);

        // Assert
        expect(result).toStrictEqual({
          distance: 'обязательное поле',
          quantity: 'обязательное поле',
        });
      });

      it('не возвращает ошибку distance, если distance задан', () => {
        // Arrange (всякие моки)
        const values = { distance: 2, height: 2, plantation_type_id };

        // Act
        const result = asyncValidateTreesShrubs(values);

        // Assert
        expect(result.distance).toBeUndefined();
      });

      it('не возвращает ошибку quantity, если quantity задан', () => {
        // Arrange (всякие моки)
        const values = { height: 2, plantation_type_id, quantity: 2 };

        // Act
        const result = asyncValidateTreesShrubs(values);

        // Assert
        expect(result.quantity).toBeUndefined();
      });
    });

    it('не возвращает ошибку, если distance ну задан но plantation_type_id не входит в список', () => {
      // Arrange (всякие моки)
      const values = { height: 2, plantation_type_id: 34 };

      // Act
      const result = asyncValidateTreesShrubs(values);

      // Assert
      expect(result).toStrictEqual({ quantity: 'обязательное поле' });
    });

    it('не возвращает ошибку, если distance ну задан но plantation_type_id вообще нет', () => {
      // Arrange (всякие моки)
      const values = { height: 2 };

      // Act
      const result = asyncValidateTreesShrubs(values);

      // Assert
      expect(result).toStrictEqual({ quantity: 'обязательное поле' });
    });
  });

  describe('когда height является обязательным', () => {
    it('возвращает ошибку, если height не задан', () => {
      // Arrange
      const values = {};

      // Act
      const result = asyncValidateTreesShrubs(values);

      // Assert
      expect(result).toStrictEqual({
        height: 'обязательное поле',
        quantity: 'обязательное поле',
      });
    });

    it('не возвращает ошибку, если height задан', () => {
      // Arrange
      const values = { height: 5 };

      // Act
      const result = asyncValidateTreesShrubs(values);

      // Assert
      expect(result).toStrictEqual({
        quantity: 'обязательное поле',
      });
    });
  });
});
