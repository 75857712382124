import { Validator } from 'core/form/Validator';
import { PLANT_SERVICE_RECOMENDATIONS_ID_NAME } from 'core/uiKit/preparedInputs/selects/multiSelect/PlantServiceRecomendationsId';
import { STATE_GARDENING_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/StateGardeningId';

import { getPlantServiceRecomendationsIdRequired } from './fields/PlantServiceRecomendationsIdRF.TreesShrubs/getPlantServiceRecomendationsIdRequired';

// "Живая изгородь", "однорядная живая изгородь", "двурядная живая изгородь", "многорядная живая изгородь".  вот эти, 0т0о есть, то поле обязательное
const ids = [2091979, 2091985, 2091986, 2091987];

/**
 * Валидация Карточки 43 Деревья/Кустарники.
 *
 * @param values - Значения формы.
 * @param values.distance - Количество, п.м.
 * @param values.plantation_type_id - Тип насаждения.
 * @param values.height - Высота, м.
 * @param values.STATE_GARDENING_ID_NAME - Рекомендации по vv ccvу.
 * @returns {{name: string, value: string}}
 */
export const asyncValidateTreesShrubs = (values: {
  distance?: string | number;
  plantation_type_id?: string | number;
  height?: string | number;
  [STATE_GARDENING_ID_NAME]?: number | string;
}): Record<string, string> => {
  const validator = new Validator(values);

  if (distanceRequired(values)) {
    validator.setRequired('distance');
  }

  if (getPlantServiceRecomendationsIdRequired(values)) {
    validator.setRequired(PLANT_SERVICE_RECOMENDATIONS_ID_NAME);
  }

  validator.setRequired('quantity');

  validator.setRequired('height');

  return validator.getErrors() || {};
};

/**
 * Валидация поля _Количество, п.м._ в зависимости от поля _Тип насаждения_.
 *
 * @param values - Значение формы.
 * @param values.plantation_type_id - Тип насаждения.
 * @returns - Обязательное ли поле.
 */
export const distanceRequired = ({
  plantation_type_id,
}: {
  plantation_type_id?: string | number;
} = {}): boolean => {
  if (plantation_type_id && ids.includes(+plantation_type_id)) {
    return true;
  }
  return false;
};
