/* eslint-disable */
import React from 'react';

import { filterInput } from '../filterInputsClasses';
import { LAWN_TYPE_NAME, LawnTypeFF } from 'core/form/finalForm/fields';
import { NumberParam } from 'use-query-params';

 
export { LAWN_TYPE_NAME };
 
 
/**
 *     Тип газона
 *     lawnTypeId
 */
 
export const LawnTypeFilter = (props) => (
  <LawnTypeFF {...props} className={filterInput} />
);
 
export const lawnTypeParams = {
  [LAWN_TYPE_NAME]: NumberParam,
};
