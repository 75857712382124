
/* eslint-disable */
export default {
  errorMore:
    'Геометрия элемента выходит за границы объекта. Количество объектов > 10. Скорректируйте границы ОГХ.',
  errorOther:
    'Геометрия элемента выходит за границы объекта. Скорректируйте границы ОГХ.',
  errorIntersectionsMore:
    'Ошибка пересечения геометрий. Количество пересекающих геометрий > 10. Скорректируйте границы ОГХ.',
  errorIntersections:
    'Ошибка пересечения геометрий. Скорректируйте границы ОГХ.',
  success: 'Пересечения отсутствуют',
  reonIntersections: 'Найдены пересечения со следующими участками из ИС РЕОН: ',
  neighbours: 'Найдены следующие смежные ОГХ и элементы благоустройства:',
};
