import { FieldFF } from 'core/form/finalForm/helpers/Field.FF';
import {
  GRBS_ID_NAME,
  GrbsId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/GrbsId';
import PropTypes from 'prop-types';

/**
 * Select Final Form. Учредитель/ГРБС .
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const GrbsIdFF = (props) => {
  return <FieldFF {...props} name={GRBS_ID_NAME} component={GrbsId} />;
};

GrbsIdFF.propTypes = {

  /**
   * Выбор темной темы.
   */
  dark: PropTypes.bool,
  disabled: PropTypes.bool,
};
GrbsIdFF.defaultProps = {
  dark: false,
  disabled: false,
};
