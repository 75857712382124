import * as types from '../constants/actionTypes';
import { clearForms } from './clearing';

/* eslint-disable */
export default function forms(state = {}, action) {
  switch (action.type) {
    case types.REMOTE_SUBMIT_START: {
      return {
        ...state,
        [action.object]: { ...state[action.object], isSubmitting: true },
      };
    }
    case types.REMOTE_SUBMIT_DONE: {
      return {
        ...state,
        [action.object]: { ...state[action.object], isSubmitting: false },
      };
    }
    case types.RESET_FORM_START: {
      return {
        ...state,
        [action.object]: { ...state[action.object], isResetting: true },
      };
    }
    case types.RESET_FORM_DONE: {
      return {
        ...state,
        [action.object]: { ...state[action.object], isResetting: false },
      };
    }
    case types.SAVE_MAPS_FORM: {
      return { ...state, mapsForm: action.values };
    }
    case types.LOCATION_CHANGE:
      return clearForms(action, state);
    default:
      return state;
  }
}
