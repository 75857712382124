import { isObject } from 'lodash';


/* eslint-disable */
export default function ({ owner_id, customer_id }) {
  return {
    owner_id: packId(owner_id),
    customer_id: packId(customer_id),
  };
}

function packId(option) {
  let result;
  if (isObject(option)) {
    result = {
      legal_person: parseInt(option.id, 10),
    };
  } else {
    result = null;
  }
  return result;
}
