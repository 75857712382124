import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { IconButton } from 'core/uiKit/material-ui';
import React from 'react';

import { getContent } from './getContent';

/**
 * Кнопка рисовать геометрию.
 *
 * @param {object} props - Пропсы.
 * @param {boolean} props.active - Флаг активности.
 * @param {string} props.geometryType - Тип геометрии.
 * @param {boolean} props.disabled - Флаг disabled.
 * @param {Function} props.onClick - Функция клика.
 * @returns {JSX.Element}
 */
const DrawButton = ({ active, geometryType, disabled, onClick }) => {
  const className = active ? 'active boxArea' : 'icon boxArea';

  const { drawFunction, ...rest } = getContent(geometryType);

  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();
  return (
    <Button
      content={{ ...rest, className }}
      disabled={disabled || !isEditCurrentObjectIsExternalSystem}
      onClick={() => onClick(active, geometryType, drawFunction)}
    ></Button>
  );
};

/**
 * Кнопка.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.content - Контент.
 * @param {Function} props.onClick - Функция клика.
 * @param {boolean} props.disabled - Флаг disabled.
 * @returns {JSX.Element}
 */
const Button = ({ content, onClick, disabled }) => {
  const { title, className, icon } = content;
  return (
    <IconButton
      className={`${className} button-toggle`}
      disabled={disabled}
      title={title}
      onClick={onClick}
    >
      <i className="material-icons">{icon}</i>
    </IconButton>
  );
};

export default DrawButton;
