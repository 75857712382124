import { asyncValidate as ownerAndCustomerFieldsAsyncValidate } from 'app/components/card/ogh/ImprovementTerritoryCard/components/OwnerAndCustomerFields';
import { asyncValidate as snowAreaAsyncValidate } from 'app/components/card/ogh/ImprovementTerritoryCard/components/SnowAreaTab';

/**
 * AsyncValidate.
 *
 * @param formValues - The form values.
 * @returns - Ошибки если они есть.
 */
export function asyncValidate(formValues: object) {
  return {
    ...ownerAndCustomerFieldsAsyncValidate(formValues),
    ...snowAreaAsyncValidate(formValues),
  };
}
