import { REQUIRED_FIELD_MESSAGE } from 'app/constants/messages';


/* eslint-disable */
export default function (formValues) {
  const { name } = formValues;
  let result;
  if (name === null || name === '') {
    result = {
      name: REQUIRED_FIELD_MESSAGE,
    };
  } else {
    result = null;
  }
  return result;
}
