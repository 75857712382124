import { ODS_API_URL } from 'config/env';
import {
  Icon,
  ListItem,
  ListItemIcon,
  ListItemText,
} from 'core/uiKit/material-ui';

/**
 * Компонент ItemFile.
 *
 * @param {object} props - Пропсы.
 * @param {string} props.fileName - Имя файла.
 * @param {*} props.fileId - Id поля.
 * @returns {JSX.Element}
 */
export const ItemFile = ({ fileName, fileId }) => {
  return (
    <ListItem>
      <ListItemIcon>
        <Icon>attach_file</Icon>
      </ListItemIcon>
      <ListItemText
        className={'text-primary'}
        primary={
          <a
            href={`${ODS_API_URL}/files/download/${fileId}`}
            download={fileName}
          >
            {fileName}
          </a>
        }
      />
    </ListItem>
  );
};
