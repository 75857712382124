/* eslint-disable */
import React from 'react';
import { NumberParam } from 'use-query-params';

import { SensorStateFF, SENSOR_STATE_NAME } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
 
/**
 * Состояние –> sensor_state [поле справочник, или если пусто - все]
 */
 
export const SensorStateFilter = (props) => (
  <SensorStateFF {...props} className={filterInput} />
);

 
export { SENSOR_STATE_NAME };

 
export const sensorStateParams = {
  [SENSOR_STATE_NAME]: NumberParam,
};
