import { uploadFile } from 'app/api/requests/uploadFiles';
import { fetchDxfFileLink } from 'app/api/upload';
import dateFormatService from 'core/services/dateFormatService';

/**
 * Отправка прикрепленного файла на сервер.
 *
 * @param {Date} startDate - Действует с.
 * @param {File} file - Файл.
 * @param {number} typeId - Тип ОГХ.
 * @returns {Promise<*|Response>}
 */
export const linkDxfFile = async (startDate, file, typeId) => {
  // чтобы с UI при загрузке DXF для ДТ, ОО и ИОБ в
  // https://test-ods.elocont.ru/api/files/upload
  // передавался type_id=7 (это раздел Инвентаризационный план на вкладке Документы),
  // а для ОДХ type_id =18 (это раздел ПЛАН-СХЕМА ОБЪЕКТА М 1:500).

  let typeIdCusotm;

  switch (typeId) {
    case 38:
    case 39:
    case 40: {
      typeIdCusotm = 7;
      break;
    }
    case 1: {
      typeIdCusotm = 18;
      break;
    }
    default:
  }

  const response = await uploadFile(file, { type_id: typeIdCusotm });
  const { fileId } = response;

  if (fileId) {
    return await fetchDxfFileLink({
      fileId,
      startDate: dateFormatService.format(startDate, 'YYYY-MM-DD'),
    });
  }
};
