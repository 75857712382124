/* eslint-disable */
import React from 'react';

import { PrintButtonOgh } from 'app/pages/components';

import { useFilterQueryParamsBasePgm } from './Filter.BasePgm';

/* eslint-disable */
export const PrintButtonBasePgm = ({ typeId }) => {
  const { prepareFilters = {} } = useFilterQueryParamsBasePgm();

  return <PrintButtonOgh {...{ typeId, prepareFilters }} />;
};
