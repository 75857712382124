/**
 * Validate.
 *
 * @param {*} clean_category_id - V.
 * @param {*} clean_subcategory_id - V.
 * @param {*} options - V.
 * @param {*} isCurrentObjectIsExternalSystem - V.
 * @returns {*}
 */
export default function cleanSubcategoryValidate(
  clean_category_id,
  clean_subcategory_id,
  options,
  isCurrentObjectIsExternalSystem,
) {
  if (isCurrentObjectIsExternalSystem) {
    return null;
  }
  if (
    clean_category_id != null &&
    clean_subcategory_id == null &&
    options.some((item) => +item.category_id === +clean_category_id)
  ) {
    return 'обязательно для поля «Категория по уборке»';
  } else {
    return null;
  }
}
