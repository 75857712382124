import { shallow } from 'enzyme';
import React from 'react';

import { DocumentTab } from './DocumentTab';

describe('🧪 DocumentTab', () => {
  it('should render all expected components', () => {
    //☣️ Arrange (всякие моки)

    //🔥 Act
    const wrapper = shallow(<DocumentTab />);

    //❓ Assert
    expect(wrapper.text()).toBe(
      '<DocumentsConfirmingTheChangesRF /><ActOfCompletedWork /><SituationPlanRF /><InventoryPlanRF /><SymbolsInventoryPlanRF /><PlanFlowerRF /><SketchFlowerRF /><MarkingDrawingRF /><DataCoordOrganizationRF /><OtherDocumentsRF /><OtherLegalDocumentsRF /><ActPerfomWorksRF /><FellingTicketRF />',
    );
  });
});
