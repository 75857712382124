import { useFetchOghTable } from 'app/pages/registry/ogh/api/useFetchOghTable';
import { useParamsTable } from 'core/uiKit/components/tables/Table';

import { useFilterQueryParamsPlanarStructure } from './FilterPlanarStructure';

/**
 * Setup таблицы __Плоскостные сооружения__.
 *
 * @param {object} params - Параметры хука.
 * @param {number} params.typeId - Тип ОГХ.
 * @param {number} params.parentTypeId - Родительский тип ОГХ.
 * @returns {{count: number, isLoading: boolean, table: Array}}
 */
export const useSetupTablePlanarStructure = ({ typeId, parentTypeId }) => {
  const { prepareFilters } = useFilterQueryParamsPlanarStructure();
  const { queryTable } = useParamsTable();

  const queryKey = [
    'TablePlanarStructure',
    {
      ...queryTable,
      ...prepareFilters,
      parentTypeId,
      typeId,
    },
  ];

  const { table = [], isLoading, count = 0 } = useFetchOghTable(queryKey);

  return { count, isLoading, table };
};
