import { TREES_SHRUBS } from 'app/constants/oghTypes';
import getTypeId from 'app/selectors/card/getTypeId';
import getFormValues from 'app/selectors/form/getFormValues';
import getDict from 'app/selectors/getDict';
import getIdCodeMap from 'app/utils/card/getIdCodeMapFromDict';
import { createSelector } from 'reselect';

const formFieldsRules = {
  [TREES_SHRUBS]: {
    control_fields: {
      life_form_type: {
        bush: ['diameter', 'age'],
        liana: ['diameter', 'age'],
        tree: ['area'],
      },
    },
  },
};

/**
 * Селектор getLockedFields.
 *
 * @param {number} typeId - Тип ОГХ.
 * @param {object} lifeFormTypes - Справочник lifeFormTypes.
 * @param {object} formValues - Значения формы.
 * @returns {Array}
 */
export const selector = (typeId, lifeFormTypes, formValues) => {
  const lifeFormTypeMap = getIdCodeMap(lifeFormTypes);

  let result;

  switch (typeId) {
    case TREES_SHRUBS: {
      const controlFields = formFieldsRules[typeId].control_fields;
      const lifeFormTypeCode = lifeFormTypeMap[formValues.life_form_type_id];
      const lifeFormType = controlFields.life_form_type[lifeFormTypeCode];

      if (lifeFormTypeCode && lifeFormType) {
        result = [...lifeFormType];
      } else {
        result = [];
      }

      break;
    }
    default: {
      result = [];
      break;
    }
  }

  return result;
};

export default createSelector(
  [getTypeId, getDict('lifeFormTypes'), getFormValues],
  selector,
);
