import { switchServisMap } from 'core/utils/map/switchServisMap';

import withMapDTW, { WithMapContextDTW } from './withMapDTW';
import withMapEgip, { WithMapContextEgip } from './withMapEgip';

export const WithMapContext = switchServisMap({
  // @ts-ignore
  dtw: WithMapContextDTW,
  egip: WithMapContextEgip,
});

const withMap = switchServisMap({
  dtw: withMapDTW,
  egip: withMapEgip,
});

export default withMap;
