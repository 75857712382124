/* eslint-disable  */
import { PhoneField } from 'core/uiKit/inputs/PhoneField/PhoneField';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

/**
 *
 * @param root0
 * @param root0.input
 */
const PhoneFieldWithReduxForm = ({ input, ...props }) => {
  return <PhoneField {...props} {...input} />;
};

/* eslint-disable */
/**
 * Инпут Redux Form для ввода телефона по маске
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

export const PhoneFieldRF = ({ name, ...otherProps }) => {
  return (
    <Field {...otherProps} name={name} component={PhoneFieldWithReduxForm} />
  );
};

PhoneFieldRF.propTypes = {
  /**
   * очищаем инпут с локальным сохранением значения
   */
  clear: PropTypes.bool,
  dark: PropTypes.bool,
  disabled: PropTypes.bool,

  /* eslint-disable */
  /**
   * Значение по умолчанию
   */
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,

  errorText: PropTypes.string,

  /* eslint-disable */
  /**
   * helpTitle
   */
  helpTitle: PropTypes.node,
  helperText: PropTypes.string,
  label: PropTypes.string,
  multiline: PropTypes.bool,
  name: PropTypes.string,
  required: PropTypes.bool,
};
PhoneFieldRF.defaultProps = {
  name: 'PhoneFieldRF',
  dark: false,
  disabled: false,
  label: 'PhoneFieldFF label',
};
