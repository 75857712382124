import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { useUser } from 'app/pages/providers/UserContext';
import { MultiSelect } from 'core/uiKit/inputs/selects/MultiSelect';
import _get from 'lodash/get';

export const OGH_TYPES_NAME = 'oghTypes';

/**
 * ## Тип ОГХ.
 *
 * @param {*} props - The props.
 * @param {*} props.otherProps - The props.
 * @param {*} props.filter - The props.
 * @returns {JSX.Element}
 */
export const OghTypes = ({ filter = (_) => _, ...otherProps }) => {
  const dict = 'oghTypes';
  const { data = [] } = useGetDictionary(dict);
  const { user = {} } = useUser();

  let options = filter(data);

  const permissionViewOgh = _get(user, 'permission.view.ogh', {});

  if (Array.isArray(user.privileges)) {
    options = options.filter(({ code }) => permissionViewOgh[code] === 1);
  }

  return (
    <MultiSelect
      {...otherProps}
      {...{ options }}
      label={'Тип ОГХ'}
      name={OGH_TYPES_NAME}
    />
  );
};
