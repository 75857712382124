import { postForgotPassword } from 'app/api';
import { TextFieldFF } from 'core/form/finalForm/fields/default/TextFieldFF';
import { Validator } from 'core/form/Validator';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { FormAuthFF } from '../components/Form.Auth.FF';
import { SendButton } from './Send.Button';

/**
 * Форма восстановление пароля через почту.
 *
 * @returns {JSX.Element} - JSX.
 */
export const ForgotPassword = () => {
  const navigate = useNavigate();

  const [send, { isLoading }] = useMutationAdaptor(
    (value) => postForgotPassword(value),
    {
      //

      /**
       * OnSuccess.
       *
       * @param {*} res - Результат.
       * @param {*} variables - Переменные.
       * @returns {void}
       */
      onSuccess: (res, variables) => {
        navigate(`/auth/forgotPassword/${variables.email}`);
      },
    },
  );

  return (
    <FormAuthFF
      Header={'Восстановление пароля'}
      submit={send}
      validate={validation}
      initialValues={{ email: '' }}
      Footer={<SendButton isLoading={isLoading} />}
    >
      <TextFieldFF label={'Email'} name={'email'} required />
    </FormAuthFF>
  );
};

/**
 * Функция валидации восстановление пароля через почту.
 *
 * @param {{email: string}} values - Значения формы.
 * @returns {*}
 */
const validation = (values) => {
  const validator = new Validator(values);

  validator.setRequired('email');

  if (values.email) {
    const regExp =
      /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(
        values.email,
      );

    if (!regExp) {
      validator.addError('email', 'Некорректный email');
    }
  }

  return validator.getErrors();
};
