import { REQUIRED_FIELD_MESSAGE } from 'app/constants/messages';

import { key } from './metadata';


/* eslint-disable */
export default function (formValues) {
  const result = {};
  if (!formValues[key]) {
    result[key] = REQUIRED_FIELD_MESSAGE;
  }
  return result;
}
