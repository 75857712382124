import { DISTRICT_LIST_NAME } from 'core/uiKit/preparedInputs/selects/multiSelect/DistrictList';

 
/**
 * Добавление initialState к полю DistrictListField.
 *
 * @param {object} result - Параметры в редьюсере.
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {object} - Возвращает объект и записывает в initialValues значение поля DistrictListField.
 * @example -
 *  mapStateToPropsReducers: [
 *     districtListFieldReducer,
 *   ],
 */
export function districtListFieldReducer(
  result,
  state,
  props,
) {
  const { card } = props;

  return {
    ...result,
    initialValues: {
      ...result.initialValues,
      [DISTRICT_LIST_NAME]: (
        card?.attribute?.[DISTRICT_LIST_NAME] || []
      ).map(({ bti_object }) => bti_object),
    },
  };
}
