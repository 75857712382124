/* eslint-disable */
import { shallow } from 'enzyme';

import { TableRedBookList } from './Table.RedBookList';
import { columns } from './columns';
import { RANDOM_WORD } from 'core/forTesting/constants';

jest.mock('../Table.List');

 
describe('file Table.RedBookList.spec', () => {
  it('text', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const wrapper = shallow(<TableRedBookList />);
    // Assert
    expect(wrapper.text()).toBe('<TableList />');
  });

  it('props', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const wrapper = shallow(<TableRedBookList code={RANDOM_WORD} />);
    // Assert
    expect(wrapper.props()).toStrictEqual({
      code: RANDOM_WORD,
      columns,
    });
  });
});
