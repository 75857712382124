import { get } from 'lodash';

const fieldName = 'address_list';


/* eslint-disable */
export default function (params = {}) {
  const { id = fieldName } = params;
  return (result, state, props) => {
    const { card } = props;
    const list = get(card, fieldName);

    const value = list
      ? list.map(({ attributes, ...rest }) => ({
          ...rest,
          ...attributes,
        }))
      : null;

    return {
      ...result,
      initialValues: {
        ...result.initialValues,
        [id]: value,
      },
    };
  };
}
