import { DateTimePickerFF as DateTimePickerFFD } from './DateTimePickerFF';
import { KeyboardDatePickerFF as KeyboardDatePickerFFD } from './KeyboardDatePickerFF';
import { KeyboardDateTimePickerFF as KeyboardDateTimePickerFFD } from './KeyboardDateTimePickerFF';

/**
 * @deprecated
 */
export const KeyboardDateTimePickerFF = KeyboardDateTimePickerFFD;

/**
 * @deprecated
 */
export const DateTimePickerFF = DateTimePickerFFD;

/**
 * @deprecated
 */
export const KeyboardDatePickerFF = KeyboardDatePickerFFD;
