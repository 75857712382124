import { getNewsAll } from 'app/api/news/get.NewsAll';
import { queryClient } from 'app/routes/QueryClientProvider';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';
import { DataNews } from 'types/data.News';

/**
 * Хук получения новостей.
 *
 * @returns {object} - Новости.
 */
export const useGetNewsAll = () =>
  useQueryAdaptor<DataNews, Error>(['getNews/All'], getNewsAll);

/**
 * Очищаем кэш query новостей.
 *
 * @returns {void}
 */
export const clearCasheNewsAll = () =>
  queryClient.invalidateQueries({ queryKey: ['getNews/All'] });
