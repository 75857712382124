import { RedBookPlantIdFilter } from 'core/form/filterForm/components/selects/RedBookPlantId.Filter';
import { IdFilter } from 'core/form/filterForm/components/text/ID.Filter';
import React from 'react';

/**
 * Фильтр __Растения красной книги__.
 *
 * @returns {JSX.Element}
 */
export const RedBookPlant = () => {
  return (
    <>
      <IdFilter />
      <RedBookPlantIdFilter />
    </>
  );
};
