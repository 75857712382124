import { setLastMapEvent } from 'app/actions/mapActions';
import { useGetAllowedGeometryTypesRF } from 'app/pages/cardsOgh/components/Map/MapEditPanel/hooks/useGetAllowedGeometryTypes';
import React from 'react';
import { useDispatch } from 'react-redux';

import { Map } from './Map';

/**
 *
 */

/**
 * Контейнерная карточка. Обертка для хуков.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - Контейнерная карточка.
 */
export default React.forwardRef((props, ref) => {
  const dispatch = useDispatch();

  /**
   * Set map event.
   *
   * @param {object} event - Event.
   * @returns {void}
   */
  const setMapEvent = (event) => {
    dispatch(setLastMapEvent(event));
  };

  const allowedGeometryTypes = useGetAllowedGeometryTypesRF();

  return (
    <Map
      {...props}
      {...{
        allowedGeometryTypes,
        ref,
        setMapEvent,
      }}
    />
  );
});
