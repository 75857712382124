import { pathSpa } from 'app/pages/registry/ogh/spa/Spa/path.Spa';
/* eslint-disable  */
import { treeSpa } from 'app/pages/registry/ogh/spa/tree.Spa';
import { pathBuilding } from 'app/pages/registry/ogh/yard/Building/path.Building';
import { pathDts } from 'app/pages/registry/ogh/yard/Dts/path.Dts';
import { pathFlowersGarden } from 'app/pages/registry/ogh/yard/FlowersGarden/path.FlowersGarden';
import { pathFunctionality } from 'app/pages/registry/ogh/yard/Functionality/path.Functionality';
import { pathLawn } from 'app/pages/registry/ogh/yard/Lawn/path.Lawn';
import { pathLittleForm } from 'app/pages/registry/ogh/yard/LittleForm/path.LittleForm';
import { pathOtherOks } from 'app/pages/registry/ogh/yard/OtherOks/path.OtherOks';
import { pathOtherTechPlace } from 'app/pages/registry/ogh/yard/OtherTechPlace/path.OtherTechPlace';
import { pathPlanarStructure } from 'app/pages/registry/ogh/yard/PlanarStructure/path.PlanarStructure';
import { pathPpi } from 'app/pages/registry/ogh/yard/Ppi/path.Ppi';
import { pathRelief } from 'app/pages/registry/ogh/yard/Relief/path.Relief';
import { pathStoragePlace } from 'app/pages/registry/ogh/yard/StoragePlace/path.StoragePlace';
import { pathTechPlace } from 'app/pages/registry/ogh/yard/TechPlace/path.TechPlace';
import { pathTreesShrubs } from 'app/pages/registry/ogh/yard/TreesShrubs/path.TreesShrubs';
import { pathWaterBuilding } from 'app/pages/registry/ogh/yard/WaterBuilding/path.WaterBuilding';
import { pathContainer } from 'app/pages/registry/ogh/сontainer/Container/path.Container';
import { useGetOghType } from 'core/utils/hooks/useGetOghType';
import lazyWithPreload from 'lazy-with-preload';
import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

const Spa = lazyWithPreload(() => import('app/pages/registry/ogh/spa/Spa'));

const PlanarStructure = lazyWithPreload(() =>
  import('app/pages/registry/ogh/yard/PlanarStructure'),
);
const OtherTechPlace = lazyWithPreload(() =>
  import('app/pages/registry/ogh/yard/OtherTechPlace'),
);
const Building = lazyWithPreload(() =>
  import('app/pages/registry/ogh/yard/Building'),
);
const Container = lazyWithPreload(() =>
  import('app/pages/registry/ogh/yard/Container'),
);
const TreesShrubs = lazyWithPreload(() =>
  import('app/pages/registry/ogh/yard/TreesShrubs'),
);
const OtherOks = lazyWithPreload(() =>
  import('app/pages/registry/ogh/yard/OtherOks'),
);
const StoragePlace = lazyWithPreload(() =>
  import('app/pages/registry/ogh/yard/StoragePlace'),
);
const TechPlace = lazyWithPreload(() =>
  import('app/pages/registry/ogh/yard/TechPlace'),
);
const Dts = lazyWithPreload(() => import('app/pages/registry/ogh/yard/Dts'));
const Ppi = lazyWithPreload(() => import('app/pages/registry/ogh/yard/Ppi'));
const Relief = lazyWithPreload(() =>
  import('app/pages/registry/ogh/yard/Relief'),
);
const Functionality = lazyWithPreload(() =>
  import('app/pages/registry/ogh/yard/Functionality'),
);
const LittleForm = lazyWithPreload(() =>
  import('app/pages/registry/ogh/yard/LittleForm'),
);
const Lawn = lazyWithPreload(() => import('app/pages/registry/ogh/yard/Lawn'));
const FlowersGarden = lazyWithPreload(() =>
  import('app/pages/registry/ogh/yard/FlowersGarden'),
);
const WaterBuilding = lazyWithPreload(() =>
  import('app/pages/registry/ogh/yard/WaterBuilding'),
);

/**
 *
 */
const SpaRoute = () => {
  useEffect(() => {
    Spa.preload();
    PlanarStructure.preload();
    OtherTechPlace.preload();
    Building.preload();
    Container.preload();
    TreesShrubs.preload();
    OtherOks.preload();
    StoragePlace.preload();
    TechPlace.preload();
    Dts.preload();
    Ppi.preload();
    Relief.preload();
    Functionality.preload();
    LittleForm.preload();
    Lawn.preload();
    FlowersGarden.preload();
    WaterBuilding.preload();
  });
  const { id: parentTypeId } = useGetOghType(pathSpa.code);

  return (
    <Routes>
      <Route element={<Spa tree={treeSpa} />} path={'/'} exact={true} />
      <Route
        element={<PlanarStructure tree={treeSpa} parentTypeId={parentTypeId} />}
        path={pathPlanarStructure.path}
      />
      <Route
        element={<OtherTechPlace tree={treeSpa} parentTypeId={parentTypeId} />}
        path={pathOtherTechPlace.path}
      />
      <Route
        element={<Building tree={treeSpa} parentTypeId={parentTypeId} />}
        path={pathBuilding.path}
      />
      <Route
        element={<Container tree={treeSpa} parentTypeId={parentTypeId} />}
        path={pathContainer.path}
      />
      <Route
        element={<StoragePlace tree={treeSpa} parentTypeId={parentTypeId} />}
        path={pathStoragePlace.path}
      />
      <Route
        element={<TechPlace tree={treeSpa} parentTypeId={parentTypeId} />}
        path={pathTechPlace.path}
      />
      <Route
        element={<Dts tree={treeSpa} parentTypeId={parentTypeId} />}
        path={pathDts.path}
      />
      <Route
        element={<Ppi tree={treeSpa} parentTypeId={parentTypeId} />}
        path={pathPpi.path}
      />
      <Route
        element={<Relief tree={treeSpa} parentTypeId={parentTypeId} />}
        path={pathRelief.path}
      />
      <Route
        element={<Functionality tree={treeSpa} parentTypeId={parentTypeId} />}
        path={pathFunctionality.path}
      />
      <Route
        element={<LittleForm tree={treeSpa} parentTypeId={parentTypeId} />}
        path={pathLittleForm.path}
      />
      <Route
        element={<Lawn tree={treeSpa} parentTypeId={parentTypeId} />}
        path={pathLawn.path}
      />
      <Route
        element={<FlowersGarden tree={treeSpa} parentTypeId={parentTypeId} />}
        path={pathFlowersGarden.path}
      />
      <Route
        element={<TreesShrubs tree={treeSpa} parentTypeId={parentTypeId} />}
        path={pathTreesShrubs.path}
      />
      <Route
        element={<OtherOks tree={treeSpa} parentTypeId={parentTypeId} />}
        path={pathOtherOks.path}
      />
      <Route
        element={<WaterBuilding tree={treeSpa} parentTypeId={parentTypeId} />}
        path={pathWaterBuilding.path}
      />
    </Routes>
  );
};

export default SpaRoute;
