import { renderCustomTextField } from 'app/components/card/common/grid/index';

/* eslint-disable */
export default function getElements({ editable }) {
  return [
    {
      id: 'reservoir_area',
      name: 'reservoir_area',
      label: 'Водоемы, кв. м',
      editable,
      formValue: true,
      component: renderCustomTextField,
      addition: {
        FormHelperTextProps: {
          error: true,
        },
        type: 'decimal',
        positive: true,
        digits: 2,
      },
      style: {
        paddingBottom: 30,
      },
    },
  ];
}
