import { Select } from 'core/uiKit/inputs/selects/Select';
/* eslint-disable  */
import React from 'react';
import { Field } from 'react-final-form';

/**
 *
 * @param root0
 * @param root0.name
 */
export const SelectFF = ({ name, ...otherProps }) => {
  return (
    <Field name={name}>
      {(props) => {
        return <Select {...props} {...props.input} {...otherProps} />;
      }}
    </Field>
  );
};
SelectFF.defaultProps = {
  dark: false,
  disabled: false,
  label: 'MultiSelectFF label',
  name: 'MultiSelectFF',
};
