import { CoatingGroupIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/CoatingGroupId.FF';
import { COATING_GROUP_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingGroupId';
import React, { useEffect } from 'react';
import { useField } from 'react-final-form';

import { useEditableCoatingFields } from '../useEditable.CoatingFields';

/**
 * Field FF __Вид покрытия__.
 *
 * `name: coating_group_id`.
 *
 * @returns {JSX.Element}
 */
export const CoatingGroupIdFFField = () => {
  const { disabled, required } = useEditableCoatingFields();

  const {
    input: { onChange },
  } = useField(COATING_GROUP_ID_NAME);

  useEffect(() => {
    if (!required) {
      onChange('');
    }

    // eslint-disable-next-line
  }, [required]);

  return (
    <CoatingGroupIdFF
      disabled={!disabled}
      required={required}
      withAll={false}
    />
  );
};
