import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';
import { SelectPreparedProps } from 'types/inputs';

export const PLANAR_STRUCTURE_TYPE_REF_ID_NAME = 'planar_structure_type_ref_id';

export interface IPlanarStructureTypeRefIdData {
  code?: string;
  id?: number;
  name?: string;
  parent_ogh_object_type_list?: number[];
}

export interface IPlanarStructureTypeRefIdProps extends SelectPreparedProps {
  filter?: (
    data: IPlanarStructureTypeRefIdData[],
  ) => IPlanarStructureTypeRefIdData[];
}

/**
 * Селект Уточнение.
 *
 * `type = Select`.
 * `name = planar_structure_type_ref`
 * `name = PLANAR_STRUCTURE_TYPE_REF_NAME`
 * `dict = 'planar_structure_type_ref'`.
 *
 * @param props - Пропсы.
 * @returns JSX.Element.
 */
export const PlanarStructureTypeRefId = (
  props: IPlanarStructureTypeRefIdProps,
) => {
  const dict = 'planar_structure_type_ref';
  const { data = [] } = useGetDictionary<IPlanarStructureTypeRefIdData[]>(dict);

  return (
    <Select
      {...props}
      options={data}
      label={props.label || 'Уточнение'}
      name={PLANAR_STRUCTURE_TYPE_REF_ID_NAME}
    />
  );
};
