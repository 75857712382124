import { useEffect } from 'react';

import { addPreparation } from './preparation/addPreparation';
import { removePreparation } from './preparation/removePreparation';

/**
 * @typedef {import('core/utils/hooks/usePreparation/preparation/functions').FnPreparation} FnPreparation - Функия форматирования свойства.
 * @typedef {import('core/utils/hooks/usePreparation/preparation/functions').NewKeyFn} NewKeyFn - Функия форматирования нового имени свойства.
 */

/**
 * Хук для добавления и удаления Preparation.
 *
 * @param {string} key - Имя/ключ инпута.
 * @param {FnPreparation} fn - Функция форматирования.
 * @param {string} newKey - Новое имя.
 * @returns {void} - Жопа.
 * @example ---
 */
export const usePreparation = (key = 'key', fn, newKey = key) => {
  useEffect(() => {
    addPreparation(key, fn, newKey);
    return () => {
      removePreparation(key);
    };
    // eslint-disable-next-line
  }, []);
};
