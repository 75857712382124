import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { elementFormGrid } from 'app/components/card/common/grid';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useEditorCardSelector } from 'app/selectors/useSelectors/useEditorCard.Selector';
import { useGetCardCalcAttributeSelector } from 'app/selectors/useSelectors/useGetCardCalcAttribute.Selector';
import { shallow } from 'enzyme';
import React from 'react';
import { Mock, vi } from 'vitest';

import { Cleaning } from './Cleaning';

vi.mock('app/selectors/useSelectors/useEditorCard.Selector');
vi.mock('app/api/hooks/useIsEditCurrentObjectIsExternalSystem');
vi.mock('app/pages/cardsOgh/hooks/useMode');
vi.mock('app/selectors/useSelectors/useGetCardCalcAttribute.Selector');
vi.mock('app/components/card/common/grid');

/**
 * Default Mock.
 *
 * @returns {void}
 */
const defaultMock = () => {
  (useMode as Mock).mockReturnValue({ editMode: true });
  (useIsEditCurrentObjectIsExternalSystem as Mock).mockReturnValue(true);
  (useEditorCardSelector as Mock).mockReturnValue({ is_orphan_object: true });
  (useGetCardCalcAttributeSelector as Mock).mockReturnValue({});
  (elementFormGrid as Mock).mockImplementation((_) => _);
};
describe('spec Cleaning', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    defaultMock();
    // 🧹 clear mock

    // 🔥 Act
    const wrapper = shallow(<Cleaning propertyTab={''} />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<TabPanel />');
  });

  it('если есть округления то они все 20', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    defaultMock();

    let digits: number[] = [];
    (elementFormGrid as Mock).mockImplementation((val) => {
      digits = val
        .map(
          (item: { addition?: { digits?: number } }) => item?.addition?.digits,
        )
        .filter(Boolean);
    });

    // 🔥 Act
    shallow(<Cleaning propertyTab={''} />);

    // ❓ Assert
    expect(digits.every((item) => item === 20)).toBeTruthy();
  });
});
