import {
  DETAILED_STATE_GARDENING_ID,
  DetailedStateGardeningId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/DetailedStateGardeningId';
import { Field } from 'redux-form';
/* eslint-disable */

const StateGardeningIdWithReduxForm = ({ input, ...props }) => {
  return <DetailedStateGardeningId {...input} {...props} />;
};

export const DetailedStateGardeningIdRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={DETAILED_STATE_GARDENING_ID}
      component={StateGardeningIdWithReduxForm}
    />
  );
};
