import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';

import { functions } from './functions';

/**
 * Удаляем функцию подготовки.
 *
 * @param {string} key - The key to remove preparation for.
 * @returns {void}
 */
export const removePreparation = (key = 'key') => {
  if (functions) {
    delete functions[camelCase(key)];
    delete functions[snakeCase(key)];
  }
};
