import { SubTypeIdFilter } from 'core/form/filterForm/components/selects/SubTypeId.Filter/index';
import { IdFilter } from 'core/form/filterForm/components/text/ID.Filter';
import React from 'react';

/**
 * Фильтр Малые архитектурные формы и элементы благоустройства.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - Тип ОГХ.
 * @returns {JSX.Element}
 */
export const LittleFormFilter = ({ typeId }) => {
  return (
    <>
      <IdFilter />
      <SubTypeIdFilter typeId={typeId} label={'Вид МАФ'} />
    </>
  );
};
