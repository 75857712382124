/* eslint-disable */
import React, { useState } from 'react';

import { AddButton } from 'core/uiKit/components/buttons/actionsButtons/Add.Button';

import { DialogEditMovementCharacteristics } from '../DialogEdit.MovementCharacteristics';

 
export const AddRow = ({ input }) => {
  const [isOpen, setIsOpen] = useState();
  const onSave = (value) => {
    const valuePrepared = Object.fromEntries(
      // преобразовать в массив, затем map, затем fromEntries обратно объект
      Object.entries(value).map(([key, value]) => {
        if (key === 'traffic_character_list_note') {
          return [key, value];
        }
        return [key, +value];
      }),
    );
    const arr = [...input.value];

    arr.push(valuePrepared);
    input.onChange([...arr]);
    setIsOpen(false);
  };

  return (
    <div className={'mt-2'}>
      <AddButton onClick={() => setIsOpen(true)} />
      <DialogEditMovementCharacteristics
        closeDialog={() => setIsOpen(false)}
        isOpen={isOpen}
        onClick={onSave}
        textHeader={'Добавление'}
        value={{}}
      />
    </div>
  );
};
