import { RANDOM_ID } from 'core/forTesting/constants';
import { generateRandomWord } from 'core/forTesting/generateRandomWord';
import { toast } from 'core/uiKit/components/Toast';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import { externalAccept } from './api';
import { BodyDialogAccept } from './Body.Dialog.Accept';
import { DialogAccept } from './Dialog.Accept';

vi.mock('core/utils/hooks/useGetObjectId');
vi.mock('core/utils/hooks/useReactQuery');
vi.mock('core/uiKit/components/Toast');

describe('file Dialog.Reject.spec', () => {
  it('name', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    useMutationAdaptor.mockReturnValue([1, 3]);
    // Act
    const wrapper = shallow(<DialogAccept />);
    // Assert
    expect(wrapper.text()).toBe('<ContinueFFDialog />');
  });

  it('props ContinueFFDialog', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    useMutationAdaptor.mockReturnValue([1, 3]);

    // Act
    const wrapper = shallow(<DialogAccept />);

    // Assert
    expect(wrapper.props()).toMatchObject({
      isLoading: undefined,
      isOpen: undefined,
      textHeader: 'Согласовать проект, созданный внешней системой',
    });
  });

  it('без props DialogReject', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    useMutationAdaptor.mockReturnValue([1, 3]);

    // Act
    const wrapper = shallow(<DialogAccept />);

    // Assert
    expect(wrapper.props()).toMatchObject({
      Body: <BodyDialogAccept />,
      closeDialog: expect.any(Function),
      isLoading: undefined,
      isOpen: undefined,
      textHeader: 'Согласовать проект, созданный внешней системой',
    });
  });

  it('prop [closeDialog] fn _=>_ DialogReject', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    useMutationAdaptor.mockReturnValue([1, 3]);

    // Act
    const wrapper = shallow(<DialogAccept />);

    // Assert
    expect(wrapper.props().closeDialog(1)).toBe(1);
  });

  it('prop [onSubmit]  DialogReject', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    const send = vi.fn();
    useMutationAdaptor.mockReturnValue([send, 3]);
    const wrapper = shallow(<DialogAccept />);

    // Act
    wrapper.props().onSubmit({ newValue: RANDOM_ID });

    // Assert
    expect(send).toHaveBeenCalledWith({
      newValue: RANDOM_ID,
      objectId: RANDOM_ID,
    });
  });

  it('props DialogReject', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    useMutationAdaptor.mockReturnValue([1, 3]);

    const isOpen = RANDOM_ID % 2 === 0;
    const onClose = vi.fn();

    // Act
    const wrapper = shallow(<DialogAccept {...{ isOpen, onClose }} />);

    // Assert
    expect(wrapper.props()).toMatchObject({
      closeDialog: onClose,
      isLoading: undefined,
      isOpen: isOpen,
      textHeader: 'Согласовать проект, созданный внешней системой',
    });
  });

  it('should useMutationAdaptor toHaveBeenCalledWith', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    useMutationAdaptor.mockReturnValue([1, 3]);

    // Act
    shallow(<DialogAccept />);

    // Assert
    expect(useMutationAdaptor).toHaveBeenCalledWith(externalAccept, {
      onError: expect.anything(),
      onSuccess: expect.anything(),
    });
  });

  it('action onSuccess', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    useMutationAdaptor.mockImplementation((_, { onSuccess }) => {
      onSuccess({ message: 'Ожидаем одобрения второго участника' });
      // onError({ message: 'asdf' });
      return [1, 3];
    });
    const onClose = vi.fn();
    const spyToastSuccess = vi.spyOn(toast, 'success');

    // Act
    shallow(<DialogAccept onClose={onClose} />);

    // Assert
    expect(onClose).toHaveBeenCalledTimes(1);
    expect(spyToastSuccess).toHaveBeenCalledWith(
      'Ваше согласование зафиксировано. Для перевода в статус «Согласован с внешней системой» необходимо получить согласование второго участника процесса.',
    );
  });

  it('action onError', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    const message = generateRandomWord();
    useMutationAdaptor.mockImplementation((_, { onError }) => {
      onError({ message });
      return [1, 3];
    });
    const onClose = vi.fn();
    const spyToastError = vi.spyOn(toast, 'error');

    // Act
    shallow(<DialogAccept onClose={onClose} />);

    // Assert
    expect(onClose).toHaveBeenCalledTimes(1);
    expect(spyToastError).toHaveBeenCalledWith(message);
  });
});
