import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { VerticalLandscapingTypeIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/VerticalLandscapingTypeId.FF';
import { VERTICAL_LANDSCAPING_CLASS_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/VerticalLandscapingClassTypeId';
import { VERTICAL_LANDSCAPING_REF_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/VerticalLandscapingRefTypeId';
import React from 'react';
import { useField } from 'react-final-form';

import { usePreparationVerticalLandscapingTypeId } from './usePreparationVerticalLandscapingTypeId';

/**
 * Select __Ед измерения__.
 *
 * @returns {JSX.Element}
 */
export const VerticalLandscapingTypeIdField = () => {
  const { editMode } = useEditMode();
  usePreparationVerticalLandscapingTypeId();

  const {
    input: { onChange: verticalLandscapingRefTypeIdChange },
  } = useField(VERTICAL_LANDSCAPING_REF_TYPE_ID_NAME);
  const { input: { onChange: verticalLandscapingClassTypeIdChange } = {} } =
    useField(VERTICAL_LANDSCAPING_CLASS_TYPE_ID_NAME);

  return (
    <VerticalLandscapingTypeIdFF
      withChange={() => {
        verticalLandscapingRefTypeIdChange([]);
        verticalLandscapingClassTypeIdChange([]);
      }}
      disabled={!editMode}
      withAll={false}
      required={true}
    />
  );
};
