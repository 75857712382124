import { useCreateTheme } from 'core/uiKit/helpers/useCreateTheme';
/* eslint-disable */
const overrides = {
  MuiFormControlRoot: {
    flexDirection: 'row',
    color: 'black',
  },
  MuiFormControl: {
    root: {
      width: '100%',
    },
  },
  MuiFormControlLabel: {
    root: {
      marginBottom: 0,
      marginTop: 8,
      width: '100%',
    },
    labelPlacementStart: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  MuiFormLabel: {
    root: {
      paddingTop: 8,
    },
  },
  MuiFormHelperText: {
    root: {
      marginTop: 0,
    },
  },
};

 
export const useCreateCheckboxTheme = ({ dark }) => {
  return useCreateTheme({ dark, overrides });
};
