import { AlertDialog } from 'core/uiKit/components/dialogs/Alert.Dialog';
import React from 'react';

import { ReonButtonWithAlert } from './ReonButtonWithAlert';

/**
 * Кнопка __Показать пересечения с данными ИС РЕОН__.
 *
 * @returns {React.ReactElement}
 */
export const ReonButton = () => {
  return (
    <AlertDialog>
      <ReonButtonWithAlert />
    </AlertDialog>
  );
};
