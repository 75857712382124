/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import SimpleLink from 'core/components/SimpleLink';

import './style.scss';

 
export default class LinkListField extends React.PureComponent {
  static propTypes = {
    input: PropTypes.object,
  };

  renderContent() {
    const { props } = this;
    const list = props.input.value || [];
    return (
      <div style={props.style}>
        <label className="ods-link-list__label">{props.label}</label>
        <ul>
          {list.map((item) => (
            <li key={item.name}>
              <SimpleLink href={item.href} name={item.name} />
            </li>
          ))}
        </ul>
      </div>
    );
  }

  render() {
    return this.props.input.value ? this.renderContent() : null;
  }
}
