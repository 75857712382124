import { POINT } from 'app/constants/geometryTypes';

import { ol } from '../../../utils/helpers/ol';

/* eslint-disable */
export default class Transform {
  constructor(map, layer) {
    this.Transform = createTransformInteraction(map, layer);
  }

  turnOn() {
    this.Transform.setActive(true);
  }

  turnOff() {
    this.Transform.setActive(false);
  }

  clearSelection() {
    this.Transform.getFeatures().length = 0;
    this.Transform.overlayLayer_.getSource().clear();
  }
}

const createTransformInteraction = (map, transformLayer) => {
  const transform = new ol.interaction.Transform({
    filter: (feature, layer) =>
      layer === transformLayer && feature.getGeometry().getType() !== POINT,
    translateFeature: false,
    scale: true,
    rotate: true,
    translate: true,
    stretch: true,
  });

  transform.setActive(false);

  map.addInteraction(transform);

  return transform;
};
