import { Paper } from 'core/uiKit/material-ui';
import Draggable from 'react-draggable';

/**
 * Компонент PaperComponent.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export function PaperComponent(props) {
  return (
    <Draggable handle={'#draggable-dialog-title'}>
      <Paper {...props} />
    </Draggable>
  );
}
