import * as adminRolesActions from 'app/actions/adminRoles';
import cn from 'classnames';
import PropTypes from 'prop-types';
/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';

import CardForm from './CardForm';

class CardAdd extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    onSubmit: PropTypes.func,
  };

  render() {
    const { onSubmit } = this.props;

    return (
      <CardForm
        className={cn('container my-4', this.props.className)}
        heading="Создание роли"
        isCreateDateHidden={true}
        onSubmit={onSubmit}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmit: function (values) {
      return dispatch(adminRolesActions.onCreateSubmit(values));
    },
  };
}

 
export default connect(null, mapDispatchToProps)(CardAdd);
