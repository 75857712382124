/* eslint-disable  */
import { useCreateTheme } from 'core/uiKit/helpers';

const overrides = {};

/**
 *
 * @param root0
 * @param root0.dark
 */
export const useCreateCardTheme = ({ dark }) => {
  return useCreateTheme({ dark, overrides });
};
