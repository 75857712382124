import { PlanarStructureTypeIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/PlanarStructureTypeId.RF/PlanarStructureTypeId.RF';
import React from 'react';

import { useSetupPlanarStructureTypeIdField } from './useSetup.PlanarStructureTypeId.Field';

/**
 * Назначение для типа планарной структуры.
 *
 * @returns JSX.Element.
 */
export const PlanarStructureTypeIdField = () => {
  const { filter, isEdit } = useSetupPlanarStructureTypeIdField();

  return (
    <PlanarStructureTypeIdRF
      filter={filter}
      disabled={!isEdit}
      required={true}
    />
  );
};
