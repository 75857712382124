import getDict from 'app/selectors/getDict';
/* eslint-disable  */
import { get } from 'lodash';
import { createSelector } from 'reselect';

/**
 *
 * @param state
 * @param props
 */
const getRequestStateId = (state, props) => get(props, 'card.request_state_id');

const MATCHING_CODE = 'technic_req_processed';

export default createSelector(
  [getDict('technicRequestState'), getRequestStateId],
  (dict, requestStateId) => {
    let result;
    if (requestStateId) {
      const { code } = dict.find((item) => item.id === requestStateId);
      result = MATCHING_CODE === code;
    } else {
      result = false;
    }
    return result;
  },
);
