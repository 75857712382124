import CardMUI from '@mui/material/Card';
import { ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { useCreateCardTheme } from './useCreateCardTheme';

 
/**
 * Компонент провайдер темы.
 *
 * @param {object} props - Пропсы.
 * @param {boolean} props.dark - Влючить выключить темную тему.
 * @param {object} props.otherProps - Остальные пропсы.
 * @returns {JSX.Element} - Возвращает провайдер темы.
 * @example  <Card hidden={true} id={id} className={'p-3 mb-3'}>
 *       {children}
 *     </Card>
 */
const Card = ({ dark = false, ...otherProps }) => {
  const themePaper = useCreateCardTheme({ dark });

  return (
    <ThemeProvider theme={themePaper}>
      <CardMUI {...otherProps} />
    </ThemeProvider>
  );
};

Card.propTypes = {
  dark: PropTypes.bool,
};

export { Card };
