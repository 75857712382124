/* eslint-disable  */
import { NumberField } from 'core/uiKit/inputs/NumberField/NumberField';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

/**
 *
 * @param root0
 * @param root0.input
 */
const NumberFieldWithReduxForm = ({ input, ...props }) => {
  return <NumberField {...props} {...input} />;
};

/**
 *
 * @param root0
 * @param root0.name
 */
export const NumberFieldRF = ({ name, ...otherProps }) => {
  return (
    <Field {...otherProps} name={name} component={NumberFieldWithReduxForm} />
  );
};

NumberFieldRF.propTypes = {
  /**
   * Разрешить отрицательные числа.
   */
  allowNegative: PropTypes.bool,

  /**
   Выбор темной темы.
   */
  dark: PropTypes.bool,

  /**
   * Если он определен, он ограничивается заданной десятичной шкалой.
   */
  decimalScale: PropTypes.number,
  disabled: PropTypes.bool,

  /**
   * Если формат задан как строка хеша, можно вводить числа вместо хеша. Если формат задан как функция, компонент вызывает функцию с неформатированным числом и ожидает форматированного числа.
   * Строка: пример на основе хэша (#### #### #### ####).
   */
  format: PropTypes.string,

  /**
   Label input.
   */
  label: PropTypes.string,

  /**
   * Если маска определена, компонент будет показывать невведенные, помещенные с замаскированным значением.
   */
  mask: PropTypes.string,

  /**
   Name input.
   */
  name: PropTypes.string,

  /**
   * Добавляет префикс перед номером.
   */
  prefix: PropTypes.string,

  /**
   * Добавляет суффикс после числа.
   */
  suffix: PropTypes.string,
};

NumberFieldRF.defaultProps = {
  allowNegative: false,
  dark: false,
  decimalScale: 0,
  disabled: false,
  label: 'NumberFieldFF label',
  name: 'NumberFieldRF',
};
