import env from '@beam-australia/react-env';

import packageJson from '../../package.json';

//
export const PUBLIC_URL = env('PUBLIC_URL');
// if (process.env.NODE_ENV === 'production' && PUBLIC_URL) {
//   // eslint-disable-next-line
//   __webpack_public_path__ = PUBLIC_URL
// }

export const ODS_API_URL =
  window.ODS_API_URL || env('ODS_API_URL') || 'http://for-test/api';

export const DXF_API_URL = window.DXF_API_URL || env('DXF_API_URL');

export const DXF_EXPORT_API_URL =
  window.DXF_EXPORT_API_URL ||
  env('DXF_EXPORT_API_URL') ||
  'http://for-test/api';

export const PRINT_API_URL =
  window.PRINT_API_URL || env('PRINT_API_URL') || 'http://for-test/print';

export const FRONTEND_VERSION_WITH_BUILD = window.FRONTEND_VERSION || '';

/**
 * Версия frontend из package.json.
 */
export const FRONTEND_VERSION = packageJson.version || '';

export const FRONTEND_BUILD_DATE = window.FRONTEND_BUILD_DATE || '';

/**
 * Флаг переключения сервисов картографии.
 */
export const SERVIS_MAP =
  localStorage.getItem('SERVIS_MAP') ||
  window.SERVIS_MAP ||
  env('SERVIS_MAP') ||
  'egip';

/**
 * Временный флаг для показа хитрой авторизации только по флагу ODS-11008.
 */
export const COMMON_LOGIN_FORM =
  window.COMMON_LOGIN_FORM || env('COMMON_LOGIN_FORM');

export const FRONTEND_ENVIRONMENT =
  window.FRONTEND_ENVIRONMENT || env('FRONTEND_ENVIRONMENT');

export const AUTH_1_0_API_URL =
  window.AUTH_1_0_API_URL || env('AUTH_1_0_API_URL');

export const EGIP_PASSWORD = window.EGIP_PASSWORD || env('EGIP_PASSWORD');
export const EGIP_API_URL = window.EGIP_API_URL || env('EGIP_API_URL');
export const EGIP_USER = window.EGIP_USER || env('EGIP_USER');

/**
 * URL API для Новой карты.
 */
export const DTW_API_URL =
  window.DTW_API_URL || env('DTW_API_URL') || 'http://for-test/DTW_API_URL';

/**
 * Логин для Новой карты.
 */
export const DTW_LOGIN = window.DTW_LOGIN || env('DTW_LOGIN');

/**
 * Пароль  для Новой карты.
 */
export const DTW_PASSWORD = window.DTW_PASSWORD || env('DTW_PASSWORD');

//
// let EGIP_PASSWORD;
// let EGIP_API_URL;
// let EGIP_USER;
//
// switch (FRONTEND_ENVIRONMENT) {
//   // case 'prod': {
//   //   EGIP_PASSWORD = '5+/I)31WP2O8';
//   //   EGIP_API_URL = 'https://egip.mos.ru';
//   //   EGIP_USER = 'asu_ods2';
//   //   break;
//   // }
//   default: {
//     // Сделано для локальной развертки чтобы работала карта
//     if (window.location.hostname === 'localhost') {
//       EGIP_PASSWORD = 'U02CVL1P';
//       EGIP_API_URL = 'https://egiptest.mos.ru';
//       EGIP_USER = 'asu_ods2';
//     } else {
//       EGIP_PASSWORD = '5+/I)31WP2O8';
//       EGIP_API_URL = 'https://egip.mos.ru';
//       EGIP_USER = 'asu_ods2';
//     }
//   }
// }
//
// export { EGIP_API_URL, EGIP_PASSWORD, EGIP_USER };

/**
 * Функция для установки значения сервиса картографии в localStorage.
 *
 * @param {'egip'|'dtw'} value - Новое значение.
 * @returns {void}
 */
window.setServisMap = (value) => {
  localStorage.setItem('SERVIS_MAP', value);
};
