import { useChangeFieldValueRF } from 'app/actions/odsObjectActions/useChangeFieldValueRF';
import { useFormValuesCardRF } from 'app/actions/odsObjectActions/useFormValuesCardRF';
import { resetEngineeringStructuresTab } from 'app/components/card/ogh/Odh/components/tabs/EngineeringStructures.Tab/resetEngineeringStructuresTab';
import { CheckboxRF } from 'core/form/reduxForm/fields/default/CheckboxRF';
import React, { useEffect } from 'react';

export const IS_AVD_NAME = 'is_avd';

/**
 * Чекбокс __Автомобильная дорога__.
 *
 * @param props - Пропсы.
 * @returns {JSX.Element}
 */
export const IsAvdRF = (props: object) => {
  const changeField = useChangeFieldValueRF();
  // @ts-ignore
  const { is_avd, ...otherValues } = useFormValuesCardRF();

  useEffect(() => {
    if (!is_avd) {
      resetEngineeringStructuresTab(otherValues, changeField);
    }

    // eslint-disable-next-line
  }, [is_avd]);

  return (
    <CheckboxRF {...props} name={IS_AVD_NAME} label={'Автомобильная дорога'} />
  );
};
