import { IntensityNum } from 'core/uiKit/preparedInputs/numberField/IntensityNum';
import React from 'react';

/**
 * Обертка для поля Интенсивность движения.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.input - Данные поля приходят из redux form.
 * @returns {JSX.Element}
 */
export const IntensityNumWithReduxForm = ({ input, ...otherProps }) => {
  return <IntensityNum {...input} {...otherProps} />;
};
