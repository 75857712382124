import { getRegistryTable } from 'app/api/requests/getRegistryTable';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';

/**
 * Хук получения данных для таблиц реестров.
 *
 * @param {string | Array} queryKey - Данные для запроса.
 * @param {{}?} options - Параметры запроса.
 * @returns {*}
 */
export const useGetRegistry = ([url, queryKey], options) =>
  useQueryAdaptor(
    [url, queryKey],
    () => getRegistryTable(url, queryKey),
    options,
  );
