import { TextField } from 'core/uiKit/inputs/TextField';
import PropTypes from 'prop-types';

 
/* eslint-disable */
/**
 * Инпут для ввода телефона по маске
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
 
export const PhoneField = (props) => {
  return (
    <TextField
      {...props}
      mask={[
        /[1-9]/,
        ' ',
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
      ]}
    />
  );
};

PhoneField.propTypes = {
   
   
/**
   * очищаем инпут с локальным сохранением значения
   */
  clear: PropTypes.bool,
  dark: PropTypes.bool,
  disabled: PropTypes.bool,
   
  /* eslint-disable */
/**
   * Значение по умолчанию
   */
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,

  errorText: PropTypes.string,
   
  /* eslint-disable */
/**
   * helpTitle
   */
  helpTitle: PropTypes.node,
  helperText: PropTypes.string,
  label: PropTypes.string,
  multiline: PropTypes.bool,
  name: PropTypes.string,
  required: PropTypes.bool,
};

TextField.defaultProps = {
  color: 'primary',
  dark: false,
  disabled: false,
  label: '',
  name: 'PhoneField',
  required: false,
  helperText: '',
  multiline: false,
  variant: 'standard',
};
