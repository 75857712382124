 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Местоположение__.
 *
 * @type {{ accessor: 'placement_name',  Header: 'Местоположение', sortable: true }}
 * @augments Cell
 */
export const placement_name = {
  accessor: 'placement_name',
  Header: 'Местоположение',
  sortable: true,
};
