import { LINESTRING, POINT, POLYGON } from 'app/constants/geometryTypes';

import { ol } from '../../../utils/helpers/ol';
import { drawingStyle } from '../mapLayers';

/* eslint-disable */
export default class Draw {
  constructor(map, layer) {
    this.Point = createDrawInteraction(map, layer, POINT);
    this.LineString = createDrawInteraction(map, layer, LINESTRING);
    this.Polygon = createDrawInteraction(map, layer, POLYGON);
    this.Hole = createDrawHoleInteraction(map, layer);

    this.activeInteractions = [];
  }

  setActiveTool(type) {
    this[type].setActive(true);
    this.activeInteractions.push(this[type]);
  }

  turnOffTools() {
    this.activeInteractions.forEach((interaction) =>
      interaction.setActive(false),
    );
    this.activeInteractions = [];
  }

  onDrawStart(type, handler) {
    this[type].on('drawstart', handler);
  }

  onDrawEnd(type, handler) {
    this[type].on('drawend', handler);
  }
}

const createDrawInteraction = (map, layer, type) => {
  const draw = new ol.interaction.Draw({
    source: layer.getSource(),
    type,
    style: drawingStyle,
  });

  draw.setActive(false);
  map.addInteraction(draw);

  return draw;
};

const createDrawHoleInteraction = (map, layer) => {
  const drawHole = new ol.interaction.DrawHole({
    layers: [layer],
  });

  drawHole.setActive(false);
  map.addInteraction(drawHole);

  return drawHole;
};
