/* eslint-disable  */
import 'app/components/common/NavBar/NavBar.scss';
import 'assets/styles/admin.auth.login.scss';

import AjaxLoadingIndicator from 'app/components/common/misc/AjaxLoadingIndicator';
import NavBar from 'app/components/common/NavBar';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router-dom';

const propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

/**
 *
 * @param root0
 * @param root0.className
 * @param root0.children
 */
export const Empty = ({ className, children }) => {
  const { pathname } = useLocation();
  return (
    <div className={cn('d-flex flex-column', 'login', className)}>
      <header>
        <NavBar className={cn('header')} isAuth={false} location={pathname} />
      </header>

      <main
        className={cn(
          'd-flex align-items-center justify-content-center',
          'main',
        )}
      >
        <div className={cn('wrapper')}>{children}</div>
      </main>
      <AjaxLoadingIndicator />
    </div>
  );
};

Empty.propTypes = propTypes;
