/* eslint-disable  */

import React, { useState } from 'react';
const Context = React.createContext();

/**
 *
 * @param root0
 * @param root0.children
 */
const BodyProvider = ({ children }) => {
  const [isSubobjectPanelShow, setIsSubobjectPanelShow] = useState(true);
  const [isShowDialogCreate, setShowDialogCreate] = useState(false);

  /**
   *
   */
  const toggleSubobjectPanelShow = () =>
    setIsSubobjectPanelShow(!isSubobjectPanelShow);

  /**
   *
   * @param value
   */
  const toggleShowDialogCreate = (value) => setShowDialogCreate(value);

  return (
    <Context.Provider
      value={{
        isShowDialogCreate,
        isSubobjectPanelShow,
        toggleShowDialogCreate,
        toggleSubobjectPanelShow,
      }}
    >
      {children}
    </Context.Provider>
  );
};

/* eslint-disable */
export { BodyProvider, Context as BodyContext };
