/* eslint-disable */
export const textFieldStyle = {
  width: '100%',
  marginBottom: '10px',
};

 
export const tooltipFieldStyle = {
  marginTop: '-10px',
};
