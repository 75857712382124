import React from 'react';

/**
 * Подсказка для поля __Стенка Нью-Джерси, п.м__.
 *
 * @returns {JSX.Element}
 */
export const BarnewJerseyHints = () => {
  return (
    <article className="section-hint">
      <header className="section-hint__header">Сумма значений полей:</header>
      <hr className="section-hint__hr" />
      <section style={{ padding: '.2rem' }}>
        <ol style={{ margin: 0, padding: '0 .5rem' }}>
          <li>
            «Ограждения», «Протяженность, п.м», для карточек, у которых
            установлен признак «Включать в ТС», «Тип» - «Стенка «Нью-Джерси»,
            «Стенка «Нью-Джерси».ТПУ».
          </li>
        </ol>
        <footer>
          При формировании государственного задания показатель умножается на 2
        </footer>
      </section>
    </article>
  );
};
