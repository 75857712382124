/* eslint-disable */
import React from 'react';
import DialogChangeEquipment from 'app/components/dialogs/technicsRegistry/equipment/DialogChangeEquipment';
import { ButtonGreen } from 'core/newComponents/Button';

 
export default class EquipmentActionButton extends React.Component {
  state = { open: false };

  render() {
    const ButtonComponent = this.props.component || ButtonGreen;

    return (
      <div style={{ display: 'inline-block' }}>
        <ButtonComponent
          {...this.props}
          onClick={() => {
            this.setState({ open: !this.state.open });
          }}
        >
          {this.props.children}
        </ButtonComponent>
        {this.state.open && (
          <DialogChangeEquipment
            {...this.props}
            showCondition={this.state.open}
            onCancel={() => {
              this.setState({ open: !this.state.open });
            }}
            onSubmit={(planDate, requestType) => {
              this.setState({ open: !this.state.open });
              this.props.onSubmit(planDate, requestType);
            }}
          />
        )}
      </div>
    );
  }
}
