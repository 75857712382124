/* eslint-disable */
import React from 'react';
import { BooleanParam } from 'use-query-params';

import { CheckboxFF } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
export const NOT_INSTALLED_ON_TECH_NAME = 'notInstalledOnTech';

 
export const NotInstalledOnTechFilter = (props) => (
  <CheckboxFF
    {...props}
    className={filterInput}
    label={'Не установлено на технику'}
    name={NOT_INSTALLED_ON_TECH_NAME}
  />
);

 
export const notInstalledOnTechParams = {
  [NOT_INSTALLED_ON_TECH_NAME]: BooleanParam,
};
