import { ORG_TYPE_NAME, OrgTypeFF } from 'core/form/finalForm/fields';
import { FOUNDER_TYPE } from 'core/uiKit/preparedInputs';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import { NumberParam, withDefault } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

/**
 * Тип.
 *
 * @param {*} props - Properties.
 * @returns {JSX.Element}
 */
export const OrgTypeFilter = (props) => (
  <OrgTypeFF {...props} className={filterInput} />
);

export { ORG_TYPE_NAME };

/**
 * Создание параметров фильтрации.
 *
 * @returns {object}
 */
export const orgTypeParams = () => {
  addPreparation(ORG_TYPE_NAME, (value) => (value > 0 ? 1 : 0));
  return {
    [ORG_TYPE_NAME]: withDefault(NumberParam, FOUNDER_TYPE),
  };
};
