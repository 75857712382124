import { RANDOM_BOOL } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import React from 'react';
import { vi } from 'vitest';

import { ArrangeElementTypeIdField } from './ArrangeElementTypeId.Field';
import { useSetupArrangeElementTypeIdField } from './useSetup.ArrangeElementTypeId.Field';

vi.mock('./useSetup.ArrangeElementTypeId.Field');

describe('🐛 spec ArrangeElementTypeId.Field', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    useSetupArrangeElementTypeIdField.mockReturnValue({
      disabled: false,
      options: [],
    });

    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(<ArrangeElementTypeIdField />);

    //❓ Assert
    expect(wrapper.text()).toBe('<SelectRF />');
  });

  it('🧪 props', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    useSetupArrangeElementTypeIdField.mockReturnValue({
      disabled: RANDOM_BOOL,
      options: [12],
    });

    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(<ArrangeElementTypeIdField />);

    //❓ Assert
    expect(wrapper.props()).toStrictEqual({
      disabled: RANDOM_BOOL,
      label: 'Тип (наименование)',
      name: 'arrange_element_type_id',
      options: [12],
      required: true,
      withAll: false,
    });
  });
});
