import { OKRUG_LIST_NAME } from 'core/uiKit/preparedInputs/selects/multiSelect/OkrugList';
import React from 'react';
import { Field } from 'redux-form';

import { OkrugListWithReduxForm } from './OkrugListWithReduxForm';

/* eslint-disable */
/**
 * Компонент OkrugListRF для работы с редах формой.
 *
 * @param {{name: string, otherProps: object}} props - Пропсы.
 * @returns {React.ReactElement} - <Field {...otherProps} name={OKRUG_LIST_NAME} component={OkrugListWithReduxForm}/>.
 * @example  - <OkrugListRF />
 */
export const OkrugListRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={OKRUG_LIST_NAME}
      component={OkrugListWithReduxForm}
    />
  );
};
