import { asyncValidate as departmentTypeFieldAsyncValidate } from '../components/DepartmentTypeField';
import { districtListAsyncValidate } from '../components/DistrictListField';
import { asyncValidate as improvementCategoriesAsyncValidate } from '../components/ImprovementCategories';
import { asyncValidate as nameFieldAsyncValidate } from '../components/NameField';
import { okrugListAsyncValidate } from '../components/OkrugListField';
import { asyncValidate as ownerAndCustomerFieldsAsyncValidate } from '../components/OwnerAndCustomerFields';
import { oznTypeAsyncValidate } from '../components/OznTypeField';
import { asyncValidate as snowAreaAsyncValidate } from '../components/SnowAreaTab';

/**
 * Функция асинхронной валидации валидации OZN.
 *
 * @param {object} formValues - Значения валидации.
 * @returns {object} - Возвращает ошибки или null.
 * @example - validate(values)
 */
export function asyncValidate(formValues) {
  return {
    ...oznTypeAsyncValidate(formValues),
    ...okrugListAsyncValidate(formValues),
    ...districtListAsyncValidate(formValues),
    ...snowAreaAsyncValidate(formValues),
    ...departmentTypeFieldAsyncValidate(formValues),
    ...ownerAndCustomerFieldsAsyncValidate(formValues),
    ...nameFieldAsyncValidate(formValues),
    ...improvementCategoriesAsyncValidate(formValues),
  };
}
