import { AutocompleteAsync } from 'core/uiKit/inputs/selects/AutocompleteAsync';
import React from 'react';

import { fetchProviders } from './api';

export const PROVIDER_ID_NAME = 'providerId';

/**
 * ## Поставщик телеметрических услуг.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const ProviderId = (props) => {
  return (
    <AutocompleteAsync
      label={'Поставщик тел. услуг'}
      {...props}
      fetchFunction={fetchProviders}
      name={PROVIDER_ID_NAME}
    />
  );
};
