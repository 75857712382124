import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import * as geometryTypes from 'app/constants/geometryTypes';

import { checkByParentOghObjectTypeList } from './checkByParentOghObjectTypeList';
import { checkByTemplate } from './checkByTemplate';

interface IValues {
  maf_type_level1_id?: number;
  maf_type_level2_id?: number;
  maf_type_level3_id?: number;
  unit_id?: number;
  [key: string]: unknown;
}

interface ITemplate {
  maf_type_level1_id: number;
  maf_type_level2_id: number;
  maf_type_level3_id: number;
  unit_id: number;
}

interface Attribute {
  geometry_type:
    | 'POLYGON'
    | 'MULTIPOLYGON'
    | 'POINT'
    | 'LINESTRING'
    | 'MULTILINESTRING';
  template: ITemplate;
  parent_ogh_object_type_list?: number[]; // Массив чисел
}

// Тип Данных геометрии
interface IGeometryTypesDict {
  attribute: Attribute[];
  ogh_object_type_id: number;
}

/**
 * Получить разрешенные типы геометрий.
 *
 * @param {IValues} formValues - Значения полей формы.
 * @returns GeometryTypes.
 */
export const useGetAllowedGeometryTypes = (formValues: IValues) => {
  const { typeId } = useGetCurrentCard();
  const { typeId: parentTypeId } = useGetParentCard();

  const dict = 'geometryType';
  const { data: geometryTypesDict } =
    useGetDictionary<IGeometryTypesDict[]>(dict);

  if (!typeId) {
    return [];
  }

  const geometryList =
    geometryTypesDict?.find((item) => item.ogh_object_type_id === typeId)
      ?.attribute || [];

  const geometryEntries = geometryList?.filter((item) =>
    checkByTemplate(formValues, item?.template),
  );

  const geometryEntriesByParentList = geometryEntries?.filter((item) =>
    checkByParentOghObjectTypeList(
      item?.parent_ogh_object_type_list,
      parentTypeId,
    ),
  );

  return [
    ...new Set(
      geometryEntriesByParentList?.map(
        (item) => geometryTypes[item.geometry_type],
      ),
    ),
  ].filter(Boolean);
};
