import { YesOrNoCell } from 'core/uiKit/components/cells/YesOrNo.Cell';

 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Обособленные__.
 *
 * @type {{ accessor: 'detached', Header: 'Обособленные', Cell: YesOrNoCell, }}
 * @augments Cell
 */
export const detached = {
  accessor: 'detached',
  Header: 'Обособленные',
  Cell: YesOrNoCell,
  sortable: true,
};
