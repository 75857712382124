import ids from 'app/utils/getOghRegistryColumns/ids';
import { LinkShortIdCell } from 'core/uiKit/components/cells/LinkShortId.Cell/index';

export const buildingColumns = [
  {
    Cell: LinkShortIdCell,
    Header: 'ID',
    accessor: 'root_id',
    minWidth: 120,
  },
  {
    Header: 'Назначение',
    accessor: 'buildings_type',
  },
  {
    Header: 'Уточнение',
    id: 'buildings_type_spec',
  },
  {
    Header: 'Адрес',
    accessor: ids.address,
    minWidth: 250,
  },
  {
    Header: 'Площадь застройки, кв.м.',
    accessor: 'build_area',
  },
  {
    Header: 'Статус',
    accessor: 'object_status_name',
    minWidth: 110,
  },
  {
    Header: 'Дата начала',
    accessor: 'start_date',
    minWidth: 90,
  },
  {
    Header: 'Дата окончания',
    accessor: 'end_date',
    minWidth: 120,
  },
];
