import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { GroupContainer } from 'core/uiKit/components/card.components/GroupContainer';
import { NumberField } from 'core/uiKit/inputs/NumberField';
import React from 'react';

const elements = [
  {
    id: 'total_lawn_area',
    label: 'Общая площадь газонов, кв.м',
  },
  {
    id: 'lawn_area_without',
    label: 'Площадь газонов за вычетом насаждений, кв.м',
  },
  {
    id: 'usial_lawn_area',
    label: 'Обыкновенный, кв.м',
  },
  {
    id: 'lawn_lawn_area',
    label: 'Луговой / разнотравный, кв.м',
  },
  {
    id: 'parterre_lawn_area',
    label: 'Партерный, кв.м',
  },
  {
    id: 'slope_lawn_area',
    label: 'На откосе, кв.м',
  },
  {
    id: 'lawn_grid_area',
    label: 'На ячеистом основании / экопарковки, кв.м',
  },
  {
    id: 'lawn_other_area',
    label: 'Иного типа, кв.м',
  },
];

/**
 * Площадь газонов.
 *
 * @returns
 */
export const LawnArea = () => {
  const { calc_attribute = {} } = useGetCurrentCard();
  return (
    <GroupContainer title={'Площадь газонов'}>
      <FieldsSplitColumns>
        {elements.map((item) => {
          return (
            <NumberField
              key={item.id}
              name={item.id}
              id={item.id}
              label={item.label}
              // @ts-ignore
              defaultValue={calc_attribute?.[item.id] || 0}
              decimalScale={2}
              disabled={true}
            />
          );
        })}
      </FieldsSplitColumns>
    </GroupContainer>
  );
};
