import './ProjectsTree.scss';

import Tree from 'app/components/common/NavBar/components/Tree/Tree';
import { ListItem, ListItemText } from 'core/uiKit/material-ui';
import { useNavigateRouter } from 'core/utils/router';
import React from 'react';

import { useMain } from './useMain';

/**
 * Компонент ProjectsTree.
 *
 * @returns {JSX.Element}
 */
export const ProjectsTree = () => {
  const navigate = useNavigateRouter();
  const { openDrawer, branches } = useMain();

  /**
   * Функция onClickLink.
   *
   * @param {number} id - Id.
   * @returns {void}
   */
  const onClickLink = (id) => {
    openDrawer(false);
    navigate(`/ogh/${id}`);
  };

  return (
    <ListItem className={'projects-tree projects-tree__wrapper'}>
      <ListItemText primary={'Проекты'} />
      <Tree branches={branches} onClickLink={onClickLink} />
    </ListItem>
  );
};
