import { startDeleteCard } from 'app/actions/odsObjectActions/startDeleteCard';
import { useGetVisibilityButtonsOgh } from 'app/api/hooks/useGetVisibilityButtonsOgh';
import { useGetCardModeSelector } from 'app/selectors/useSelectors/useGetCardMode.Selector';
import { useGetCurrentCardSelector } from 'app/selectors/useSelectors/useGetCurrentCard.Selector';
import { useGetTreeSelector } from 'app/selectors/useSelectors/useGetTree.Selector';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import { useDispatch } from 'react-redux';

 
/* eslint-disable */
/**
 * хук для кнопки удалить огх
 * @function
 * @module src/app/components/card/ogh/index/card/components/DeleteOghButton
 * @category Category
 * @subcategory All
 * @returns {{onDelete: (function(): function(*): Promise<*>), isShow: boolean}}
 */
/* eslint-disable */
export const useDeleteOghButton = () => {
  const dispatch = useDispatch();
  const { objectId } = useGetObjectId();
  const { typeId } = useGetCurrentCardSelector();
  // todo не совсем понятно зачем это надо.
  // если тут будет тру то карточка стирается до диалогового окна
  const selected = useGetTreeSelector();
  const isSelected = selected && Object.keys(selected).length;

  // видимость
  const { editMode } = useGetCardModeSelector();
  const { data: { delete: del } = {} } = useGetVisibilityButtonsOgh(objectId);

  const isShow = !editMode && del;

  const onDelete = () =>
    dispatch(startDeleteCard(objectId, typeId, !isSelected));
  return {
    onDelete,
    isShow,
  };
};
