export const category_sp = [
  {
    code: 'cat_sp_1',
    id: 1,
    name: 'Магистральные городские дороги',
  },
  {
    code: 'cat_sp_2',
    id: 2,
    name: 'Магистральные улицы общегородского значения',
  },
  {
    code: 'cat_sp_3',
    id: 3,
    name: 'Магистральные улицы районного значения',
  },
  {
    code: 'cat_sp_4',
    id: 4,
    name: 'Магистральные дороги районного значения',
  },
  {
    code: 'cat_sp_5',
    id: 5,
    name: 'Улицы и дороги местного значения',
  },
  {
    code: 'cat_sp_6',
    id: 6,
    name: 'Пешеходные улицы и площади',
  },
];
