import { get } from 'app/api/crud';

/**
 * Функция получения файлов для подписи.
 *
 * `На вход ид карточки
 * На выходе список file_id в нашем файлохранилище
 * по ним можешь получить файлы для подписи.`.
 *
 * @param {object} params - Ид карточки.
 * @param {number} params.oghId - Ид карточки.
 * @param {string} params.signType - Тип метода запроса на подписание.
 * @returns {Promise<*>}
 */
export const getFilesForSignature = ({ oghId = '', signType = '' }) =>
  get(`/sign/ogh/${oghId}`, { signType });
