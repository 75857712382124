import {
  BASE_PGM,
  CONTAINER,
  IMPROVEMENT_OBJECT,
  MAF_RFID,
  ODH,
  OOPT,
  OZN,
  YARD,
} from 'app/constants/oghTypes';
import { useUser } from 'app/pages/providers';
import { useGetOghTypes } from 'core/utils/hooks/useGetOghTypes';
import _get from 'lodash/get';

/**
 * Хук получения разрешенных типов ОГХ для Списка объектов для конкретного пользователя.
 *
 * @returns {Array<number>}
 */
export const usePermissionsTypesOgh = () => {
  const oghTypes = useGetOghTypes();
  const { user } = useUser();

  const types = [
    BASE_PGM,
    YARD,
    IMPROVEMENT_OBJECT,
    OOPT,
    CONTAINER,
    ODH,
    OZN,
    MAF_RFID,
  ];

  const permissionViewOgh = _get(user, 'permission.view.ogh', {});
  return types.filter((item) => {
    const code = _get(oghTypes, `${item}.code`);
    return permissionViewOgh[code] === 1;
  });
};
