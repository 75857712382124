import { IMPROVEMENT_OBJECT, ODH, OZN, YARD } from 'app/constants/oghTypes';
import { useGetCardAttribute } from 'app/selectors/useSelectors/useGetCardAttribute.Selector';
import { useGetCurrentCardSelector } from 'app/selectors/useSelectors/useGetCurrentCard.Selector';
import { generateRandomWord } from 'core/forTesting/generateRandomWord';
import { shallow } from 'enzyme';

import { CheckboxUpdatingComponent } from './CheckboxUpdatingComponent';

jest.mock('app/selectors/useSelectors/useGetCardAttribute.Selector');
jest.mock('app/selectors/useSelectors/useGetCurrentCard.Selector');

/* eslint-disable */
describe('title CheckboxUpdatingComponent', () => {
  it.each([YARD, OZN, ODH, IMPROVEMENT_OBJECT])(
    'тип %i карточки есть в массиве',
    (type) => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      const MOCK_GREENING_ADDITION = generateRandomWord();
      useGetCardAttribute.mockReturnValue({
        greeningAddition: MOCK_GREENING_ADDITION,
      });

      useGetCurrentCardSelector.mockReturnValue({
        typeId: type,
      });
      // Act
      const wrapper = shallow(<CheckboxUpdatingComponent />);

      // Assert
      expect(wrapper.text()).toBe('<Checkbox />');
    },
  );

  it('типа карточки нет в массиве', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const MOCK_GREENING_ADDITION = generateRandomWord();
    useGetCardAttribute.mockReturnValue({
      greeningAddition: MOCK_GREENING_ADDITION,
    });
    useGetCurrentCardSelector.mockReturnValue({
      typeId: 30343,
    });
    // Act
    const wrapper = shallow(<CheckboxUpdatingComponent />);

    // Assert
    expect(wrapper.html()).toBeFalsy();
  });

  it('component IdRfidWithReduxForm have children Checkbox', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const MOCK_GREENING_ADDITION = generateRandomWord();
    useGetCardAttribute.mockReturnValue({
      updateRequired: MOCK_GREENING_ADDITION,
    });
    useGetCurrentCardSelector.mockReturnValue({
      typeId: OZN,
    });
    // Act
    const wrapper = shallow(<CheckboxUpdatingComponent />);

    // Assert
    expect(wrapper.props()).toMatchObject({
      label: 'Требует актуализации',
      disabled: true,
      value: MOCK_GREENING_ADDITION,
      name: 'update_required',
    });
  });
});
