import './TabsOGH.scss';

import TabsMUI from '@mui/material/Tabs';
import { Tab } from 'core/uiKit/components/Tab';
import React from 'react';

type TabsType = { label: string; value: string }[];

type TabsProps = {
  value: string;
  tabs: string[];

  /**
   * Определяет дополнительное поведение отображения вкладок:
   * scrollable вызовет свойства прокрутки и позволит осуществлять горизонтальную прокрутку (или пролистывание) панели вкладок
   * fullWidth заставит вкладки расти, чтобы использовать все доступное пространство, которое следует использовать для небольших представлений, как на мобильных устройствах.
   * Standard отобразит состояние по умолчанию.
   * По умолчанию: 'standard'.
   */
  variant?: 'scrollable' | 'fullWidth' | 'standard';
  onChange: (
    value: Extract<TabsType[number], { label: string }>['label'],
  ) => void;
};

/**
 * Tab.
 *
 * @param {TabsProps} props - Properties.
 * @param props.onChange - Обратный вызов срабатывает при изменении значения.
 * @param props.value - Value.
 * @param [props.variant] - Определяет дополнительное поведение отображения вкладок.
 * @returns {JSX.Element}
 */
export const TabsOGH = ({
  value,
  onChange,
  tabs = [],
  variant = 'standard',
}: TabsProps): JSX.Element => {

  /**
   * Обратный вызов срабатывает при изменении значения.
   *
   * @param _ - Event.
   * @param newValue - Value.
   * @returns {void}
   */
  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    onChange(newValue);
  };
  return (
    <TabsMUI
      textColor={'inherit'}
      onChange={handleChange}
      value={value}
      variant={variant}
      classes={{ flexContainer: 'sarstar', indicator: 'indicator-tab' }}
    >
      {tabs.map((title) => {
        return <Tab label={title} value={title} />;
      })}
    </TabsMUI>
  );
};
