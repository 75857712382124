/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import { KeyboardDateTimePicker } from 'core/uiKit/inputs/datePickers/KeyboardDateTimePicker';
 
/* eslint-disable */
/**
 *  ## Выбор даты с временем
 *
 * @deprecated
 * @see используй KeyboardDateTimePicker
 *
 */
 
export const DateTimePicker = (props) => {
  return <KeyboardDateTimePicker {...props} />;
};

DateTimePicker.propTypes = {
   
   
/**
   * выбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
  label: PropTypes.string,
   
   
/**
   * имя селекта в форме
   */
  name: PropTypes.string,
   
   
/**
   * функция вызова когда выберется итем
   */
  onChange: PropTypes.func,

   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
   
   
/**
   * значение ParsableDate
   */
  value: PropTypes.any,
};

DateTimePicker.defaultProps = {
  label: 'DateTimePicker',
  name: 'DateTimePicker',
};
