 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */

import { YesOrNoCell } from 'core/uiKit/components/cells/YesOrNo.Cell';
 
/* eslint-disable */
/**
 * Колонка __Внутридворовое__.
 *
 * @type {{ accessor: 'in_yard',  Header: 'Внутридворовое', sortable: true, }}
 * @augments Cell
 */
export const in_yard = {
  Cell: YesOrNoCell,
  accessor: 'in_yard',
  Header: 'Внутридворовое',
  sortable: true,
};
