import { CoatingTypeRoadIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/CoatingTypeRoadId.RF';
import { shallow } from 'enzyme';
import React from 'react';

import { CoatingTypeRoadIdRFTramRails } from './CoatingTypeRoadIdRF.TramRails';
import { useCoatingTypeRoadIdRFTramRails } from './useCoatingTypeRoadIdRFTramRails';

jest.mock('./useCoatingTypeRoadIdRFTramRails');

describe('CoatingTypeRoadIdRfTramRailsJestSpec', function () {
  it('isCurrentObjectIsExternalSystem false', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const editMode = true;
    const filter = jest.fn();
    const formValues = { coating_group_road_id: 1 };
    const isCurrentObjectIsExternalSystem = false;
    useCoatingTypeRoadIdRFTramRails.mockReturnValue({
      editMode,
      filter,
      formValues,
      isCurrentObjectIsExternalSystem,
    });
    // Act
    const wrapper = shallow(<CoatingTypeRoadIdRFTramRails />);
    // Assert
    expect(
      wrapper.contains(
        <CoatingTypeRoadIdRF
          disabled={false}
          required={true}
          filter={filter}
        />,
      ),
    ).toBeTruthy();
  });

  it('isCurrentObjectIsExternalSystem true', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const editMode = true;
    const filter = jest.fn();
    const formValues = { coating_group_road_id: 1 };
    const isCurrentObjectIsExternalSystem = true;
    useCoatingTypeRoadIdRFTramRails.mockReturnValue({
      editMode,
      filter,
      formValues,
      isCurrentObjectIsExternalSystem,
    });
    // Act
    const wrapper = shallow(<CoatingTypeRoadIdRFTramRails />);

    //?
    // Assert
    expect(
      wrapper.contains(
        <CoatingTypeRoadIdRF disabled={true} required={true} filter={filter} />,
      ),
    ).toBeTruthy();
  });

  it('aisCurrentObjectIsExternalSystem true', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const editMode = true;
    const filter = jest.fn();
    const formValues = {};
    const isCurrentObjectIsExternalSystem = false;
    useCoatingTypeRoadIdRFTramRails.mockReturnValue({
      editMode,
      filter,
      formValues,
      isCurrentObjectIsExternalSystem,
    });
    // Act
    const wrapper = shallow(<CoatingTypeRoadIdRFTramRails />);

    //?
    // Assert
    expect(
      wrapper.contains(
        <CoatingTypeRoadIdRF disabled={true} required={true} filter={filter} />,
      ),
    ).toBeTruthy();
  });
});
