/* eslint-disable */
import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import { CreateType, CREATE_TYPE_NAME } from 'core/uiKit/preparedInputs';

const CreateTypeWithReduxForm = ({ input, ...props }) => {
  return <CreateType {...input} {...props} />;
};

/* eslint-disable */
export const CreateTypeRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={CREATE_TYPE_NAME}
      component={CreateTypeWithReduxForm}
    />
  );
};

 
export { CREATE_TYPE_NAME };
CreateTypeRF.propTypes = {
   
   
/**
   * быбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
/**
   * placeholder
   */
  placeholder: PropTypes.string,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
};
CreateTypeRF.defaultProps = {
  disabled: false,
};
