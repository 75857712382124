import { print } from 'app/api/requests/print';
import { PrintButton } from 'app/pages/components';
import React from 'react';

interface IPrintButtonDialogComparison {
  etalon: {
    id: number | string;
  };
  copy: {
    id: number | string;
  };
  typeId: number;
}

/**
 * Кнопка Печать диалогового окна сравнения.
 *
 * @param props - Пропсы.
 * @param props.etalon - 1-ая выбранная версия.
 * @param props.copy - 2-ая выбранная версия.
 * @param props.typeId - Тип ОГХ.
 * @class
 */
export const PrintButtonDialogComparison = ({
  etalon,
  copy,
  typeId,
}: IPrintButtonDialogComparison) => {
  return (
    <PrintButton
      printFn={async () => {
        await print('different_list', {
          copy: copy.id,
          etalon: etalon.id,
          type_id: typeId,
        });
      }}
      prepareFilters={{}}
    />
  );
};
