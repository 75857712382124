import IconButton from 'core/newComponents/IconButton';
import { RemoveIcon } from 'core/uiKit/material-ui';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Компонент TableDeleteButton.
 *
 */
export default class TableDeleteButton extends React.Component {
  static propTypes = {
    disabled: PropTypes.bool,
    onRemove: PropTypes.func,
    visible: PropTypes.bool,
  };

  /**
   * Метод жизненного цикла render.
   *
   * @returns {JSX.Element}
   */
  render() {
    const { disabled, onRemove, visible } = this.props;
    const iconButtonStyle = {
      display: visible ? 'inline-block' : 'none',
      height: '50px',
      width: '50px',
      zIndex: '50',
    };

    return (
      <IconButton
        aria-label="delete"
        className={'tableButton'}
        disabled={disabled}
        style={iconButtonStyle}
        title="Удалить"
        onClick={onRemove}
      >
        <RemoveIcon />
      </IconButton>
    );
  }
}
