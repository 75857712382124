/* eslint-disable */
export function validateLayoutLength(formValues) {
  const layoutLength = +formValues.layout_length;
  const distance = +formValues.distance;
  const axisLength = +formValues.axis_length;
  const trafficCharacterList = formValues.trafficCharacterList;
  let validate = {};
  if (distance > layoutLength && layoutLength) {
    validate.distance =
      'Протяженность по оси не может быть больше протяженности по всем осям';
  }

  if (axisLength > layoutLength && layoutLength) {
    validate.axis_length =
      'Протяженность осевой разделительной не может быть больше протяженности по всем осям';
  }

  const result = trafficCharacterList?.some(
    (trafficCharacter) => +trafficCharacter.odh_placement_axis > layoutLength,
  );

  if (result) {
    validate.layout_length =
      '«Местоположение створа по основной оси (в точке максимальной загрузки), м» не должно быть больше значения «Протяженность объекта по всем осям, п.м';
  }
  return validate;
}
