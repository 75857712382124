import * as adminRolesActions from 'app/actions/adminRoles';
import cn from 'classnames';
/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import CardForm from './CardForm';

const CardEdit = (props) => {
  let { roleId } = useParams();
  const { onSubmit } = props;
  return (
    <CardForm
      className={cn('container my-4', props.className)}
      heading="Редактирование роли"
      roleId={roleId}
      onSubmit={(values) => onSubmit({ ...values, roleId })}
    />
  );
};

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmit: function (values) {
      return dispatch(
        adminRolesActions.onUpdateSubmit({
          ...values,
        }),
      );
    },
  };
}

 
export default connect(mapStateToProps, mapDispatchToProps)(CardEdit);
