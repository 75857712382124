/* eslint-disable */
import React from 'react';
import { StringParam } from 'use-query-params';

import { TextFieldFF } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

const SECTION_NUMBER_NAME = 'sectionNum';

 
 
/**
 *  Номер участка  sectionNum
 */
 
export const SectionNumFilter = (props) => (
  <TextFieldFF
    label={'Номер участка'}
    {...props}
    className={filterInput}
    name={SECTION_NUMBER_NAME}
  />
);

 
export const sectionNumParams = {
  [SECTION_NUMBER_NAME]: StringParam,
};
