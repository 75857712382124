/* eslint-disable  */
import 'assets/styles/common.compareReport.scss';

import {
  fetchDiffGeometries,
  fetchDiffObjectTrees,
  fetchDiffVersionsProperties,
} from 'app/actions/odsObjectVersionsActions';
import { print } from 'app/api/requests/print';
import VersionProps from 'app/components/common/VersionProps';
import { PrintButton } from 'app/pages/components';
import * as S from 'app/selectors/card';
import getEtalonCopy from 'app/utils/compareReport/index';
import cn from 'classnames';
import { LoadingOverlay } from 'core/components';
import { TabPanel } from 'core/uiKit/components/TabPanel';
import { Tabs } from 'core/uiKit/components/Tabs';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import MapDiff from './MapDiff';
import NestedObjectsDiff from './NestedObjectsDiff';
import PropertiesDiff from './PropertiesDiff';
import { isReportReady } from './selectors';
import { getNowDate } from './utils';

/**
 * CompareReport.
 */
class CompareReport extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    fetchDiffGeometries: PropTypes.func,
    fetchDiffObjectTrees: PropTypes.func,
    fetchDiffVersionsProperties: PropTypes.func,
    geodiff: PropTypes.object,
    propsdiff: PropTypes.array,
    reportIsReady: PropTypes.bool,
    treediff: PropTypes.array,
    typeId: PropTypes.number,
    versions: PropTypes.object,
  };

  state = {
    active: 'properties',
  };

  /**
   * Метод жизненного цикла componentDidMount().
   *
   * @returns {void}
   */
  componentDidMount() {
    const compareReportData = this.getCompareReportData();

    this.props.fetchDiffVersionsProperties(
      this.props.typeId,
      compareReportData.etalon.id,
      compareReportData.copy.id,
    );
    this.props.fetchDiffObjectTrees(
      this.props.typeId,
      compareReportData.etalon.id,
      compareReportData.copy.id,
    );
    this.props.fetchDiffGeometries(
      this.props.typeId,
      compareReportData.etalon.id,
      compareReportData.copy.id,
    );
  }

  /**
   * GetCompareReportData.
   *
   * @returns {{etalon: *, copy: *}|{etalon: *, copy: *}|*}
   */
  getCompareReportData = () => {
    const versions = this.props.versions;
    const id1 = Object.keys(versions)[0];
    const id2 = Object.keys(versions)[1];
    return getEtalonCopy(versions[id1], versions[id2]);
  };

  /**
   * Изменение таба.
   *
   * @param {*} tabValue - Value tab.
   * @returns {void}
   */
  handleChangeTab = (tabValue) => {
    this.setState({
      active: tabValue,
    });
  };

  /**
   * Метод жизненного цикла render().
   *
   * @returns {JSX.Element} - Реакт компонент.
   */
  render() {
    const { propsdiff, treediff, geodiff, reportIsReady } = this.props;
    const compareReportData = this.getCompareReportData();
    const etalon = compareReportData.etalon;
    const copy = compareReportData.copy;

    return (
      <div className={cn('compareReport', this.props.className)}>
        <dl className="w-50 pr-5 ods-version-props">
          <dt>Дата формирования:</dt>
          <dd>{getNowDate()}</dd>
        </dl>
        <LoadingOverlay isHidden={reportIsReady} />
        <div className="d-flex">
          <VersionProps
            className="w-100 pl-5"
            versionId={copy.id}
            versionPeriod={`${copy.start_date} - ${copy.end_date}`}
            versionStatus={copy.object_status_name}
          />
          <VersionProps
            className="w-100 pr-5"
            versionId={etalon.id}
            versionPeriod={`${etalon.start_date} - ${etalon.end_date}`}
            versionStatus={etalon.object_status_name}
          />
        </div>
        <PrintButton
          printFn={() => {
            const compareReportData = this.getCompareReportData();

            print('different_list', {
              copy: compareReportData.copy.id,
              etalon: compareReportData.etalon.id,
              type_id: this.props.typeId,
            });
          }}
          prepareFilters={{}}
        />
        <Tabs
          className="diff__tabs"
          value={this.state.active}
          onChange={this.handleChangeTab}
          tabs={[
            { label: 'Свойства', value: 'properties' },
            { label: 'Вложенные объекты', value: 'nested_objects' },
            { label: 'Карта', value: 'map' },
          ]}
        />
        <TabPanel show={this.state.active === 'properties'}>
          <PropertiesDiff
            className={'compareReport__diff'}
            copy={copy}
            etalon={etalon}
            propsdiff={propsdiff}
          />
        </TabPanel>
        <TabPanel show={this.state.active === 'nested_objects'}>
          <NestedObjectsDiff
            className={'compareReport__diff'}
            copy={copy}
            etalon={etalon}
            treediff={treediff}
          />
        </TabPanel>
        <TabPanel show={this.state.active === 'map'}>
          <MapDiff
            className={'compareReport__diff'}
            copy={copy}
            etalon={etalon}
            geodiff={geodiff}
          />
          
        </TabPanel>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  geodiff: S.getGeodiffPayload,
  propsdiff: S.getPropsDiffPayload,
  reportIsReady: isReportReady,
  treediff: S.getTreediffPayload,
  versions: (state) => state.card.selectedVersions,
});

const dispatchToProps = {
  fetchDiffGeometries,
  fetchDiffObjectTrees,
  fetchDiffVersionsProperties,
};

export default connect(mapStateToProps, dispatchToProps)(CompareReport);
