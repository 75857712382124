import { TextFieldRF } from 'core/form/reduxForm/fields/default/TextFieldRF';
import React from 'react';

import { PrintEquipment } from './PrintEquipment';

const style = {
  alignItems: 'center',
  display: 'flex',
};

/**
 * Компонент EquipmentIndex.
 *
 * @param {*} root0 - A.
 * @param {*} root0.required - A.
 * @returns {JSX.Element}
 */
export const EquipmentIndex = ({ required }) => {
  return (
    <div>
      <TextFieldRF
        name={'equipment_index'}
        label={'Индекс оснащенности'}
        disabled={true}
        required={required}
      />
      <div style={style}>
        <PrintEquipment />
      </div>
    </div>
  );
};
