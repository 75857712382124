import { CertificatePassportOGHApplicationLink } from 'app/components/common/NavBar/links/CertificatePassportOGHApplication.Link';
import { ViewMatchingTemplatesLink } from 'app/components/common/NavBar/links/ViewMatchingTemplatesLink';
import { dialog } from 'app/constants/elementNames';
import * as grants from 'app/grants';
import { pathAdmin } from 'app/pages/admin/path.Admin';
import { useUser } from 'app/pages/providers';
import { pathMatching } from 'app/pages/registry/Matching/path.Matching';
import { pathOgh } from 'app/pages/registry/ogh/Ogh/path.Ogh';
import { pathRegistry } from 'app/pages/registry/path.Registry';
import { pathBnso } from 'app/pages/registry/tech/Bnso/path.Bnso';
import { pathCar } from 'app/pages/registry/tech/Car/path.Car';
import { pathTech } from 'app/pages/registry/tech/path.Tech';
import { pathTitleListRegistry } from 'app/pages/registry/TitleListRegistry/path.TitleListRegistry';
import { pathCarReport } from 'app/pages/report/CarReport/path.CarReport';
import { pathReport } from 'app/pages/report/path.Report';
import { pathSensorsReport } from 'app/pages/report/SensorsReport/path.SensorsReport';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useLocation } from 'react-router-dom';

import {
  EquipmentRegistryLink,
  ExaminationOGHApplicationLink,
  MatchingBoundariesLink,
  NewsLink,
  OghMatchingRegistryNav,
  ReagentNormativeLink,
  ReportNavBNSO,
  ReportNavChangelog,
  TitleListRegistryLink,
  ViewApiSubscriptionsLink,
  ViewRolesLink,
  ViewUsersLink,
} from './links';
import { KnowledgeBaseLink } from './links/KnowledgeBase.Link';
import { NSILink } from './links/NSI.Link';
import { NSIBidLink } from './links/NSIBid.Link';
import NavItem from './NavItem';

const privilegeForMapPage = [
  'view_ogh_odh',
  'view_ogh_container',
  'view_ogh_yard',
  'view_ogh_improvement_object',
  'view_ogh_ozn',
  'view_infr_reagent_storage',
];

const links = {
  admin: ['/a/'],
  ogh: ['/r/ogh', '/r/pgm', '/ogh', '/pgm'],
  pgm: ['/r/pgm', '/pgm/', '/r/reagentnorm'],
  tech: ['/r/tech', '/tech/', '/tech/matching/'],
  title: ['/tl'],
};

/**
 * Активна ли линка.
 *
 * @param {string} location - Location.
 * @param {Array<string>} links - Links.
 * @returns {{"nav-link-active": boolean}} - Жопа.
 */
const isActiveLink = (location, links) => {
  return {
    'nav-link-active': links.some((link) => location.includes(link)),
  };
};

const MapPage = grants.showIfHaveAnyPrivilege(privilegeForMapPage)(
  memo(

    /**
     * Component.
     *
     * @param {object} props - Properties.
     * @param {string} props.location - Location.
     * @returns {JSX.Element} - JSX.
     */
    ({ location }) => (
      <NavItem
        className={location === '/' ? 'nav-link-active' : 'nav-link'}
        link="/"
      >
        На карте
      </NavItem>
    ),
  ),
);

const OghRegistryNav = grants.showOghRegistryNavBarItem(
  memo(

    /**
     * Component.
     *
     * @param {object} props - Properties.
     * @param {string} props.location - Location.
     * @returns {JSX.Element} - JSX.
     */
    ({ location }) => (
      <NavItem
        className={
          location.includes(`/${pathRegistry.path}/${pathOgh.path}`)
            ? 'nav-link-active'
            : 'nav-link'
        }
        link="/r/ogh"
      >
        Реестр объектов
      </NavItem>
    ),
  ),
);

const ReportNavCar = grants.showIfHavePrivilege('reports_technic_car')(() => (
  <NavItem link={`/${pathReport.path}/${pathCarReport.path}`}>
    {pathCarReport.name}
  </NavItem>
));

const ReportNavEquipment = grants.showIfHavePrivilege(
  'reports_technic_equipment',
)(() => <NavItem link="/report/3">Навесное оборудование</NavItem>);

const ReportNavSensor = grants.showIfHavePrivilege('reports_technic_sensor')(
  () => (
    <NavItem link={`/${pathReport.path}/${pathSensorsReport.path}`}>
      {pathSensorsReport.name}
    </NavItem>
  ),
);

const TechnologyMatchingRegistryNav =
  grants.showIfHavePrivilegeToViewAnyTechMatchingRegistry(() => (
    <NavItem link="/r/tech/matching">Реестр согласований техники</NavItem>
  ));

const ExportReagentBase = grants.showIfHavePrivilege('export_reagent_base_pgm')(

  /**
   * Component.
   *
   * @param {object} props - Properties.
   * @param {Function} props.onShow - Function onShow.
   * @returns {JSX.Element} - JSX.
   */
  ({ onShow }) => (
    <NavItem onClick={onShow(dialog.EXPORT_PGM_SHIPMENTS)}>
      Вывоз реагентов с баз ПГР
    </NavItem>
  ),
);

const FullnessBase = grants.showIfHavePrivilege('fullness_base_pgm')(

  /**
   * Component.
   *
   * @param {object} props - Properties.
   * @param {Function} props.onShow - Function onShow.
   * @returns {JSX.Element} - JSX.
   */
  ({ onShow }) => (
    <NavItem onClick={onShow(dialog.EXPORT_PGM_FULLNESS)}>
      Заполненность баз ПГР
    </NavItem>
  ),
);

/**
 * Component.
 *
 * @param {object} props - Properties.
 * @param {string} props.location - Location.
 * @returns {JSX.Element|null} - Жопа.
 */
const TitleNav = ({ location }) => {
  const { hasPermission } = useUser();
  const isShow = hasPermission('view_title');

  return isShow ? (
    <NavItem
      className={
        location === `/${pathRegistry.path}/${pathTitleListRegistry.path}`
          ? 'nav-link-active'
          : 'nav-link'
      }
      id="nav-tl"
      title="Титульные списки"
      type="dropdown"
      onClick={() => null}
    >
      <TitleListRegistryLink />
      {/* TODO Временно скрываем этот пункт меню ODS-4278 */}
      {/*<NavItem link="/tl/all">Данные титульных списков</NavItem>*/}
    </NavItem>
  ) : null;
};

const MatchingRegistryNav = grants.showIfHavePrivilegeToViewAnyMatchingRegistry(
  memo(

    /**
     * Component.
     *
     * @param {object} props - Properties.
     * @param {string} props.location - Location.
     * @returns {JSX.Element} - JSX.
     */
    ({ location }) => (
      <NavItem
        className={
          location.includes(pathMatching.path) ? 'nav-link-active' : 'nav-link'
        }
        id="nav-matching"
        title="Реестр согласований"
        type="dropdown"
      >
        <OghMatchingRegistryNav />
        <TechnologyMatchingRegistryNav />
        <MatchingBoundariesLink />
      </NavItem>
    ),
  ),
);

const TechNav = grants.showIfHaveSomePrivilegeToTechRegistry(
  memo(

    /**
     * Component.
     *
     * @param {object} props - Properties.
     * @param {string} props.location - Location.
     * @returns {JSX.Element} - JSX.
     */
    ({ location }) => (
      <NavItem
        className={
          location.includes(`/${pathRegistry.path}/${pathTech.path}`)
            ? 'nav-link-active'
            : 'nav-link'
        }
        id="nav-technology"
        title="Реестр техники"
        type="dropdown"
      >
        <TechnicRegistry />
        <BnsoRegistry />
        <EquipmentRegistryLink />
      </NavItem>
    ),
  ),
);
const TechnicRegistry = grants.showIfHavePrivilege('view_technic_car')(() => (
  <NavItem link={`/${pathRegistry.path}/${pathTech.path}/${pathCar.path}`}>
    {pathCar.name}
  </NavItem>
));
const BnsoRegistry = grants.showIfHavePrivilege('view_technic_gps')(() => (
  <NavItem link={`/${pathRegistry.path}/${pathTech.path}/${pathBnso.path}`}>
    БНСО
  </NavItem>
));

const PgmNav = grants.showIfHaveSomePrivilegeToPgmRegistry(
  memo(

    /**
     * Component.
     *
     * @param {object} props - Properties.
     * @param {string} props.location - Location.
     * @param {Function} props.onShow - Function onShow.
     * @returns {JSX.Element|null} - Жопа.
     */
    ({ location, onShow }) => (
      <NavItem
        className={cn(isActiveLink(location, links.pgm))}
        id="nav-pgm"
        title="ПГР"
        type="dropdown"
      >
        <ExportReagentBase onShow={onShow} />
        <FullnessBase onShow={onShow} />
        <ReagentNormativeLink />
      </NavItem>
    ),
  ),
);

const ReportNav = grants.showIfHaveSomePrivilegeToReportRegistry(
  memo(

    /**
     * Component.
     *
     * @param {object} props - Properties.
     * @param {string} props.location - Location.
     * @returns {JSX.Element|null} - Жопа.
     */
    ({ location }) => (
      <NavItem
        className={
          location.includes(pathReport.path) ? 'nav-link-active' : 'nav-link'
        }
        id="nav-rep"
        title="Отчеты"
        type="dropdown"
      >
        <ReportNavCar />
        <ReportNavBNSO />
        <ReportNavEquipment />
        <ReportNavSensor />
        <ReportNavChangelog />
      </NavItem>
    ),
  ),
);

const AdministrationNav = grants.showIfHaveSomePrivilegeToAdministration(
  memo(

    /**
     * Component Администрирование.
     *
     * @param {object} props - Properties.
     * @param {string} props.location - Location.
     * @returns {JSX.Element} - JSX.
     */
    ({ location }) => {
      return (
        <NavItem
          className={
            location.includes(pathAdmin.path) || location.includes('/a')
              ? 'nav-link-active'
              : 'nav-link'
          }
          id="nav-adm"
          title="Администрирование"
          type="dropdown"
        >
          <NSILink />
          <ViewRolesLink />
          <ViewUsersLink />
          <ViewMatchingTemplatesLink />
          <ViewApiSubscriptionsLink />
          <NewsLink />
        </NavItem>
      );
    },
  ),
);

/**
 * ПАСПОРТИЗАЦИЯ ОГХ.
 *
 * @param {object} props - Properties.
 * @param {string} props.location - Location.
 * @returns {JSX.Element} - JSX.
 */
const Application = ({ location }) => {
  const { hasPermission } = useUser();
  const isShow = hasPermission('passportization_ogh');

  return isShow ? (
    <NavItem
      className={
        location.includes(pathAdmin.path) || location.includes('/a')
          ? 'nav-link-active'
          : 'nav-link'
      }
      id="nav-adma"
      title="Паспортизация ОГХ"
      type="dropdown"
    >
      <ExaminationOGHApplicationLink />
      <CertificatePassportOGHApplicationLink />
    </NavItem>
  ) : null;
};

const propTypes = {
  onShow: PropTypes.func,
};

/**
 * Component.
 *
 * @param {object} props - Properties.
 * @param {Function} props.onShow - Function onShow.
 * @returns {JSX.Element} - JSX.
 */
function Navigation({ onShow }) {
  const { pathname } = useLocation();

  return (
    <ul className="links">
      <MapPage location={pathname} />
      <OghRegistryNav location={pathname} />
      <TitleNav location={pathname} />
      <MatchingRegistryNav location={pathname} />
      <TechNav location={pathname} />
      <PgmNav location={pathname} onShow={onShow} />
      <ReportNav location={pathname} />

      <Application location={pathname} />
      <AdministrationNav location={pathname} />
      <NSIBidLink />
      <KnowledgeBaseLink />
    </ul>
  );
}

Navigation.propTypes = propTypes;

export default Navigation;
