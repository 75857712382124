import * as types from '../constants/actionTypes';
/* eslint-disable */
const defaultState = {
  versions: false,
  addTitlelist: false,
  oghOrderDetails: {
    active: false,
    orderId: null,
  },
};

/* eslint-disable */
export default function modals(state = defaultState, action) {
  switch (action.type) {
    case types.TOGGLE_VERSIONS_COMPARE_REPORT: {
      return {
        ...state,
        versions: !state.versions,
      };
    }
    case types.TOGGLE_ADD_TITLELIST_FORM: {
      return {
        ...state,
        addTitlelist: !state.addTitlelist,
      };
    }
    case types.TOGGLE_TREE_EDIT_FORM: {
      return {
        ...state,
        treeEdit: action.group || !state.treeEdit,
        groupParent: action.parent || null,
        treeEditTree: action.tree || null,
      };
    }
    case types.TOGGLE_OGH_ORDER_DETAILS: {
      return {
        ...state,
        oghOrderDetails: {
          active: !state.oghOrderDetails.active,
          orderId: action.orderId,
        },
      };
    }
    default:
      return state;
  }
}
