import { validateAdditionalInformation } from 'app/components/card/ogh/ImprovementTerritoryCard/components/AdditionalInformation.Tab/validate.AdditionalInformation';

/* eslint-disable */
describe('file validate.AdditionalInformation', () => {
  it('valid', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act

    // Assert
    expect(
      validateAdditionalInformation({
        ddd: 'ddd',
        222: 'example.com',
        owner_link: 'http://www.example.com#up',

        park_link:
          'http://invalid.com/perl.cgi?key= | http://web-site.com/cgi-bin/perl.cgi?key1=value1&key2',

        oper_link: 'http',
      }),
    ).toStrictEqual({
      park_link: 'введите валидный url',
      oper_link: 'введите валидный url',
    });
  });
  it('valid когда нет нихто или value не строка', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act

    // Assert
    expect(
      validateAdditionalInformation({
        ddd: 'ddd',
        222: 'example.com',

        park_link: 3543,

        oper_link: '',
      }),
    ).toStrictEqual({});
  });

  it.each`
    url                                                                                           | expected
    ${'https://www.example.com'}                                                                  | ${true}
    ${'http://www.example.com'}                                                                   | ${true}
    ${'www.example.com'}                                                                          | ${true}
    ${'example.com'}                                                                              | ${true}
    ${'http://blog.example.com'}                                                                  | ${true}
    ${'http://www.example.com/product'}                                                           | ${true}
    ${'http://www.example.com/products?id=1&page=2'}                                              | ${true}
    ${'http://invalid.com/perl.cgi?key= | http://web-site.com/cgi-bin/perl.cgi?key1=value1&key2'} | ${false}
    ${'http://www.example.com#up'}                                                                | ${true}
    ${'http://www.example.c'}                                                                     | ${true}
    ${'http://255.255.255.255'}                                                                   | ${true}
    ${'hts'}                                                                                      | ${false}
    ${'hts.com'}                                                                                  | ${true}
  `('проверка url | $url должен быть [$expected]', ({ url, expected }) => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const e = !expected
      ? {
          oper_link: 'введите валидный url',
        }
      : {};
    // Act

    // Assert
    expect(
      validateAdditionalInformation({
        oper_link: url,
      }),
    ).toStrictEqual(e);
  });
});
