import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const CLEAN_SUB_CATEGORY_ID_NAME = 'clean_subcategory_id';

/**
 * Поле __Подкатегория по уборке__.
 *
 * @param props - Пропсы.
 * @param props.options - Опшины.
 * @returns {JSX.Element}
 */
export const CleanSubCategoryId = (props: {
  options: { name: string; id: number }[];
}) => {
  return (
    <Select
      {...props}
      options={props.options}
      label={'Подкатегория по уборке'}
      name={CLEAN_SUB_CATEGORY_ID_NAME}
    />
  );
};
