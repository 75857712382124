import { useFetchOghTable } from 'app/pages/registry/ogh/api';
import { useParamsTable } from 'core/uiKit/components/tables/Table';

import { TableOghChildrenProps } from '../../types';
import { useFilterQueryParamsFunctionality } from './Filter.Functionality';

/**
 * Setup Компонентa таблицы __Системы функционального обеспечения__.
 *
 * @param {TableOghChildrenProps} props - Пропсы.
 * @returns Данные для таблицы.
 */
export const useSetupTableFunctionality = ({
  typeId,
  parentTypeId,
}: TableOghChildrenProps) => {
  const { prepareFilters } = useFilterQueryParamsFunctionality();
  const { queryTable } = useParamsTable();

  const queryKey = [
    'TableFunctionality',
    {
      ...queryTable,
      ...prepareFilters,
      parentTypeId,
      typeId,
    },
  ];

  const { table, isLoading, count } = useFetchOghTable(queryKey);

  return { count, isLoading, table };
};
