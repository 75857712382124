import {
  elementFormGrid,
  renderAddressList2,
} from 'app/components/card/common/grid';
import { IsDiffHeightMarkField } from 'app/components/card/ogh/components/fields/checkBoxFields/IsDiffHeightMark.Field';
import { AreaField } from 'app/components/card/ogh/components/fields/numberFields/Area.Field';
import { UNTENANTABLE } from 'app/constants/addressKlKods';
import { column } from 'app/constants/tables';
import getBuildingsTypeSpecByTypeId from 'app/selectors/card/getBuildingsTypeSpecByTypeId';
import getDict from 'app/selectors/getDict';
import isMandatory from 'app/utils/card/isMandatory';
import getHint from 'app/utils/getAddressListHints';
import { NumberFieldRF } from 'core/form/reduxForm/fields/default/NumberField.RF';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import { TextFieldRF } from 'core/form/reduxForm/fields/default/TextFieldRF';
import { NoCalcRF } from 'core/form/reduxForm/fields/prepared/checkboxes/NoCalc.RF';
import { CoatingGroupIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/CoatingGroupId.RF';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/textField/TotalAreaGeo.RF';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import React from 'react';
import { connect } from 'react-redux';

import { CoatingFaceTypeIdField } from '../../components/selects/CoatingFaceTypeId.Field';
import { CoatingTypeIdFiled } from '../../components/selects/CoatingTypeId.Field';

/**
 * Компонет Properties.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - Тайп айди.
 * @param {boolean} props.editMode - Флаг редактирования.
 * @param {boolean} props.required - Обязательность поля.
 * @param {*} props.buildingsTypes - Жопа.
 * @param {*} props.buildingsTypeSpec - Жопа.
 * @param {*} props.isCurrentObjectIsExternalSystem - Жопа.
 * @param {boolean} props.isEditCurrentObjectIsExternalSystem - Создано из внешней системы флаг.
 * @returns {JSX.Element} - Реакт компонент.
 * @example ------.
 */
function Properties({
  typeId,
  editMode,
  required,
  buildingsTypes,
  buildingsTypeSpec,
  isCurrentObjectIsExternalSystem,
  isEditCurrentObjectIsExternalSystem,
}) {
  return (
    <div>
      <FieldsSplitColumns>
        <SelectRF
          label={'Назначение'}
          name={'buildings_type_id'}
          required={isMandatory(typeId, 'buildings_type', required)}
          options={buildingsTypes}
          disabled={true}
        />
        <SelectRF
          label={'Уточнение назначения'}
          name={'buildings_type_spec_id'}
          required={isMandatory(typeId, 'buildings_type_spec_id', required)}
          options={buildingsTypeSpec}
          disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
        />
      </FieldsSplitColumns>
      <FieldsSplitColumns>
        <NoCalcRF disabled={!editMode} />
        <IsDiffHeightMarkField />
      </FieldsSplitColumns>

      <div className={'my-4'}>
        {elementFormGrid(
          [
            {
              addition: {
                address_required: true,
                data: {
                  address: {
                    kl_kod: UNTENANTABLE,
                  },
                },
                hintText: editMode ? getHint('near_address') : null,
                noWrapper: true,
                onlyOne: true,
              },
              component: renderAddressList2,
              editable: editMode && !isCurrentObjectIsExternalSystem,
              formValue: true,
              id: 'address_list',
              label: null,
              name: 'address_list',
            },
          ],
          column.ONE,
          'Адрес БТИ',
          typeId,
        )}
      </div>
      <FieldsSplitColumns>
        <AreaField />
        <TotalAreaGeoRF disabled={true} decimalScale={2} />
        <TextFieldRF
          name={'property'}
          label={'Характеристика'}
          disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
          required={isMandatory(typeId, 'property', required)}
        />
        <CoatingGroupIdRF disabled={!editMode} />
        <CoatingTypeIdFiled />
        <CoatingFaceTypeIdField />
        <NumberFieldRF
          disabled={!editMode}
          label="Площадь облицовки, кв.м"
          name="face_area"
          decimalScale={2}
        />
      </FieldsSplitColumns>
    </div>
  );
}

const getBuildingsTypes = getDict('buildingsType');

/**
 * Функция получения и установки изначальных значений из State.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {object} - Поля.
 * @example ----
 */
const mapStateToProps = (state, props) => {
  const { typeId } = props;
  const getBuildingsTypeSpec = getBuildingsTypeSpecByTypeId(typeId);
  return {
    buildingsTypeSpec: getBuildingsTypeSpec(state),
    buildingsTypes: getBuildingsTypes(state),
  };
};

export default connect(mapStateToProps)(Properties);
