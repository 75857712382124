import { del } from 'app/api/crud/del';
import { get } from 'app/api/crud/get';
import { post } from 'app/api/crud/post';
import { turn_into_snake_case } from 'app/api/crud/turn_into_snake_case';
import { getRegistryTable } from 'app/api/requests/getRegistryTable';
import { ODS_API_URL } from 'config/env';
import { log } from 'core/utils/log';
import __get from 'lodash/get';
import qs from 'query-string';

/* eslint-disable */
const defOptions = {
  credentials: 'include',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

// const textOptions = {
//   credentials: 'include',
//   headers: {
//     Accept: 'text/plain',
//     'Content-Type': 'text/plain',
//   },
// };

function getPostOptions(data) {
  return {
    ...defOptions,
    body: JSON.stringify(data),
    method: 'POST',
  };
}

// function getPostTextOptions(data) {
//   return {
//     ...textOptions,
//     method: 'POST',
//     body: data,
//   };
// }

function getPutOptions(data) {
  return {
    ...defOptions,
    body: JSON.stringify(turn_into_snake_case(data)),
    method: 'PUT',
  };
}

function getDeleteOptions(data) {
  return {
    ...defOptions,
    body: JSON.stringify(data),
    method: 'DELETE',
  };
}

// function getPostOptionsForm(data) {
//   // DEPRECATED
//   let formBody = [];
//   for (let property in data) {
//     const encodedKey = encodeURIComponent(property);
//     const encodedValue = encodeURIComponent(data[property]);
//     formBody.push(encodedKey + '=' + encodedValue);
//   }
//   formBody = formBody.join('&');
//
//   return {
//     ...defOptions,
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/x-www-form-urlencoded',
//     },
//     body: formBody,
//   };
// }

function resParse(response) {
  const contentType = response.headers.get('content-type');
  let result;
  if (contentType && contentType.includes('application/json')) {
    result = parseJson(response);
  } else {
    result = parseText(response);
  }
  return result;
}

function parseJson(response) {
  return response.json().then(function (resData) {
    if (!response.ok) {
      if (response.status === 401) {
        if (!resData.message.startsWith('Authentication Failed')) {
          window.location = '/auth/login';
        }
      }
      throw new Error(__get(resData, 'message', JSON.stringify(resData)));
    }
    return resData;
  });
}

function parseText(response) {
  return response.text().then(function (resData) {
    if (!response.ok) {
      if (response.status === 401) {
        if (!resData.message.startsWith('Authentication Failed')) {
          window.location = '/auth/login';
        }
      }
      throw new Error(resData);
    }
    if (!resData) {
      return '';
    }
    return JSON.parse(resData);
  });
}

const apiPath = ODS_API_URL;

export const getTlRegistry = function (data) {
  return fetch(
    apiPath + `/registry/tl/registry?${qs.stringify(data)}`,
    defOptions,
  ).then(resParse);
};

export const getRoleRegistry = function (data) {
  return fetch(
    apiPath + `/registry/role?${qs.stringify(data)}`,
    defOptions,
  ).then(resParse);
};

export const getMatchingRegistry = function (data) {
  return fetch(
    apiPath + `/registry/matching/objects?${qs.stringify(data)}`,
    defOptions,
  ).then(resParse);
};

export const getTechMatchingRegistry = function (data) {
  return fetch(
    apiPath + `/registry/matching/tech?${qs.stringify(data)}`,
    defOptions,
  ).then(resParse);
};

export const getTechnologyVersions = function (data) {
  return get('/registry/versions/tech', data);
};

export const getTechnologyReport = function (data) {
  const { typeId } = data;
  return get(`/report/${typeId}`, data);
};

export const getTemplateRegistry = function (data) {
  return fetch(
    apiPath + `/registry/matching/template?${qs.stringify(data)}`,
    defOptions,
  ).then(resParse);
};

export const deleteTemplate = function (data) {
  return fetch(
    apiPath + `/matching/?${qs.stringify(data)}`,
    getDeleteOptions(),
  ).then(resParse);
};

export const getTitlelistDetailsRegistry = async (queryParams) => {
  const table = getRegistryTable('/registry/tl/one', queryParams);
  const { count: total } = await getRegistryTable(
    '/registry/tl/one/count',
    queryParams,
  );
  const data = {
    meta: { ...queryParams, total },
    table: await table,
  };

  return { data };
};

export const deleteTitlelist = function (data) {
  log.info('depricated');
  return del('/tl/', data, { jsonBody: true });
};

export const getAdminRole = function (data) {
  const { id } = data;
  return fetch(apiPath + `/roles/${id || ''}`, defOptions).then(resParse);
};

export const postAdminRole = function (data) {
  return fetch(apiPath + '/roles', getPostOptions(data)).then(resParse);
};

export const postCopyRole = function (data) {
  return fetch(apiPath + '/roles/copy', getPostOptions(data)).then(resParse);
};

export const putAdminRole = function (data) {
  return fetch(apiPath + '/roles', getPutOptions(data)).then(resParse);
};

export const deleteAdminRole = function (data) {
  const { id } = data;
  return fetch(apiPath + `/roles/${id}`, getDeleteOptions()).then(resParse);
};

export const getOghOrders = async (queryParams) => {
  const table = getRegistryTable('orders', queryParams);
  const { count: total } = await getRegistryTable('orders/count', queryParams);

  const data = {
    meta: { ...queryParams, total },
    table: await table,
  };

  return { data };
};

export const postForgotPassword = (email) =>
  post('/auth/forgot', email, { parseResponse: false });

export const postResetPassword = (data, tokenNumber) =>
  post(`/auth/changePassword/${tokenNumber}`, data, {
    jsonBody: false,
    parseResponse: false,
    urlencoded: true,
  });

export const postCheckToken = function (tokenNumber) {
  return fetch(apiPath + '/auth/tokens/' + tokenNumber);
};

export * from './crud';
