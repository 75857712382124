import { RANDOM_WORD } from 'core/forTesting/constants';
import { useParamsTable } from 'core/uiKit/components/tables/Table';
import { useState } from 'react';

import { usePlantationTab } from './usePlantationTab';

jest.mock('core/uiKit/components/tables/Table');
jest.mock('react', () => ({
  ...jest.requireActual('react'),
  useState: jest.fn(),
}));

 
describe('file usePlantationTab.spec', () => {
  it('useState был вызван с false', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useState.mockReturnValue([]);
    useParamsTable.mockReturnValue({});

    // Act
    usePlantationTab();
    // Assert
    expect(useState).toHaveBeenCalledWith(false);
  });

  it('возвращает нужное', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const EXPANDED = 'expanded_' + RANDOM_WORD;
    useState.mockReturnValue([EXPANDED]);
    useParamsTable.mockReturnValue({});

    // Act
    const answer = usePlantationTab();
    // Assert
    expect(answer).toStrictEqual({
      expanded: EXPANDED,
      handleChange: expect.any(Function),
    });
  });

  it('handleChange isExpanded true', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const EXPANDED = 'expanded_' + RANDOM_WORD;
    const RESET_PAGINATION = jest.fn();
    const PANEL = 'PANEL_' + RANDOM_WORD;
    const EVENT = 'event_' + RANDOM_WORD;
    const SET_EXPANDED = jest.fn();
    useState.mockReturnValue([EXPANDED, SET_EXPANDED]);
    useParamsTable.mockReturnValue({
      resetPagination: RESET_PAGINATION,
    });

    // Act
    const { handleChange } = usePlantationTab();
    handleChange(PANEL)(EVENT, true);

    // Assert
    expect(RESET_PAGINATION).toHaveBeenCalled();
    expect(SET_EXPANDED).toHaveBeenCalledWith(PANEL);
  });
  it('handleChange isExpanded false', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const EXPANDED = 'expanded_' + RANDOM_WORD;
    const RESET_PAGINATION = jest.fn();
    const PANEL = 'PANEL_' + RANDOM_WORD;
    const EVENT = 'event_' + RANDOM_WORD;
    const SET_EXPANDED = jest.fn();
    useState.mockReturnValue([EXPANDED, SET_EXPANDED]);
    useParamsTable.mockReturnValue({
      resetPagination: RESET_PAGINATION,
    });

    // Act
    const { handleChange } = usePlantationTab();
    handleChange(PANEL)(EVENT, false);

    // Assert
    expect(RESET_PAGINATION).toHaveBeenCalled();
    expect(SET_EXPANDED).toHaveBeenCalledWith(false);
  });
});
