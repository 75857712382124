/* eslint-disable */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchPreloadData } from 'app/actions/auth';
import { Empty } from 'core/uiKit/components';

function Base({ beforeRender, children, loggedIn }) {
  localStorage.setItem('history', window.location.pathname); // сохраним для редиректа после удачного логина

  useEffect(() => {
    if (!loggedIn) {
      beforeRender();
    }
  }, [beforeRender, loggedIn]);

  // todo:  костыль .. так ка надо ждать загрузки стайта из беэка. иначе все ломается
  return loggedIn ? children : <Empty />;
}

const mapStateToProps = (state) => {
  return { loggedIn: state.auth.loggedIn };
};

const mapDispatchToProps = {
  beforeRender: fetchPreloadData,
};

 
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Base);
