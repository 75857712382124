import { get } from 'app/api/crud/get';

/**
 * Автокомплит запрос на округ.
 *
 * @param data - Данные.
 * @param data.maxRows - Maximum number of rows.
 * @param data.startsWith - Start with.
 * @returns Словарь.
 */
export const fetchOkrugDetails = (data: {
  maxRows: number;
  startsWith: string;
}): Promise<{ id: number; name: string }[]> =>
  get<{ id: number; okrug_name: string }[]>(
    '/autocomplete/okrug_details',
    data,
  ).then((res) => res.map((item) => ({ id: item.id, name: item.okrug_name })));
