import { printOgh as print } from 'app/api/requests/print';

/**
 * Функция печати ОГХ.
 *
 * @param {string} code - Url/Name report.
 * @returns {function(*): Promise<*>}
 */
export const printOgh =
  (code = 'putCodeHere') =>
  async (queryParams) =>
    print(code, { fullExport: false, ...queryParams });
