/* eslint-disable */
import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import {
  CarCustomerListId,
  CAR_CUSTOMERS_LIST_ID_NAME,
} from 'core/uiKit/preparedInputs';

 
 
/**
 * ## Заказчики техники
 *
 * ### React Final Form Field
 *
 * * `type = AutocompleteMultiAsync`
 * * `name = car_customer_list_id`
 */
/* eslint-disable */
export const CarCustomerListIdFF = ({ name, input, ...otherProps }) => {
  return (
    <Field name={CAR_CUSTOMERS_LIST_ID_NAME}>
      {({ input, ...props }) => {
        return <CarCustomerListId {...props} {...otherProps} {...input} />;
      }}
    </Field>
  );
};

 
export { CAR_CUSTOMERS_LIST_ID_NAME };

CarCustomerListIdFF.propTypes = {
   
   
/**
   *
   * Выбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
/**
   * placeholder
   */
  placeholder: PropTypes.string,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
};
CarCustomerListIdFF.defaultProps = {
  disabled: false,
};
