import { CUSTOMER_HINT } from 'app/constants/messages';
import getHint from 'app/utils/getHint';
import { CustomerPlaceRF } from 'core/form/reduxForm/fields/prepared/selects/autocompleteAsync/CustomerPlace.RF';
import { OwnerIdPlaceRF } from 'core/form/reduxForm/fields/prepared/selects/autocompleteAsync/OwnerIdPlace.RF/OwnerIdPlace.RF';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { useGetOghType } from 'core/utils/hooks/useGetOghType';

/**
 * Поля Балансодержатель и заказчик.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
function OwnerAndCustomerFields(props) {
  const {
    isOrphanObject,
    mode,
    editable,
    required,
    card,
    isEditCurrentObjectIsExternalSystem,
  } = props;

  const typeId = card.type_id;
  const oghType = useGetOghType(typeId);

  return (
    <FieldsSplitColumns>
      <OwnerIdPlaceRF
        place={oghType.code}
        helpTooltip={getHint('owner_id')}
        disabled={
          !(
            !isOrphanObject &&
            mode &&
            mode.editMode &&
            isEditCurrentObjectIsExternalSystem
          )
        }
        required={required}
      />

      <CustomerPlaceRF
        place={oghType.code}
        helpTooltip={CUSTOMER_HINT}
        disabled={!(editable && isEditCurrentObjectIsExternalSystem)}
        required={required}
      />
    </FieldsSplitColumns>
  );
}

export default OwnerAndCustomerFields;
