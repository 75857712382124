import { userFetchDataType } from 'app/pages/providers/UserContext/fetchUserData';
import React, { ReactNode, useContext } from 'react';

/* eslint-disable  */
import { useSetupUserProvider } from './useSetupUserProvider';

interface ContextData {
  hasPermission: (str?: string) => boolean | undefined;
  user: userFetchDataType;
}

const initContextState: ContextData = {
  /**
   *
   */
  hasPermission: () => undefined,
  user: {},
};

const Context = React.createContext(initContextState);

type UserProviderProps = {
  children: ReactNode;
};

/**
 * UserProvider.
 *
 * @param root0 - Props.
 * @param root0.children - Children.
 * @returns {JSX}
 */
const UserProvider = ({ children }: UserProviderProps) => {
  const { hasPermission, user } = useSetupUserProvider();

  return (
    <Context.Provider
      value={{
        hasPermission,
        user,
      }}
    >
      {children}
    </Context.Provider>
  );
};

/**
 * Хук получения данных о пользователе.
 *
 * @returns {*}
 */
const useUser = (): ContextData => {
  return useContext(Context);
};

export { Context as UserContext, UserProvider, useUser };
