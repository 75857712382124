import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useSelector } from 'react-redux';
import { vi } from 'vitest';

import { useSetupPlanarStructureTypeRefIdField } from './useSetup.PlanarStructureTypeRefId.Field';

vi.mock('app/api/hooks/useGetParentCard');
vi.mock('react-redux');

/**
 * Mock Default.
 *
 * @returns {void}
 */
const mockDefault = () => {
  useGetParentCard.mockReturnValue({});
  useSelector.mockReturnValue('isEdit');
};

describe('🐛 spec useSetupPlanarStructureTypeRefIdField', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock

    //🔥 Act
    const res = useSetupPlanarStructureTypeRefIdField();

    //❓ Assert
    expect(res).toStrictEqual({
      filter: expect.any(Function),
      isEdit: 'isEdit',
    });
  });
});
