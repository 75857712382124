/* eslint-disable  */
import './AutocompleteWithChipAsync.scss';

import { getFieldError } from 'app/utils/validate/getFieldError';
import {
  AutocompleteMUI,
  ChipMUI,
  CircularProgress,
  TextFieldMUI,
  Tooltip,
} from 'core/uiKit/material-ui';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';
import { log } from 'core/utils/log';
import React, { useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';

/**
 * TransformDataBeforeFetch.
 *
 * @param {string} startsWith - Поисковая фраза.
 * @returns {{
 * maxRows: 12,
 * startsWith,
 * }}
 */
const transformDataBeforeFetch = (startsWith = '') => ({
  maxRows: 12,
  startsWith,
});

/**
 * Асинхронное автозаполнение с Chip.
 *
 * @param {*} props - The Properties.
 * @param {string} [props.className] - The name of the class.
 * @param {boolean} [props.clear] - Очищаем инпут с сохранением значения.
 * @param {boolean} [props.disabled] - Заблокировать на редактирование.
 * @param {string} [props.errorText] - Текст ошибки.
 * @param {*} props.fetchFunction - Функция запроса.
 * @param {string} [props.helperText] - Вывод вспомогательного текста.
 * @param {string} props.label - The label.
 * @param {*} [props.meta] - The meta.
 * @param {Function} [props.onChange] - Функция вызова когда выберется итем.
 * @param {string} [props.placeholder] - The placeholder.
 * @param {boolean} [props.required] - Вывод информации об обязательном поле.
 * @param {string} [props.tooltip] - The tooltip.
 * @param {{}} [props.value] - Значение.
 * @returns {JSX.Element}
 */
export const AutocompleteWithChipAsync = ({
  className,
  clear = false,
  disabled,
  errorText,
  fetchFunction,
  helperText,
  label,
  meta = {},
  onChange: change,
  placeholder = '',
  required,
  tooltip = '',
  value = null,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [oldValue, setOldValue] = useState('');
  const [inputValueDebounced] = useDebounce(inputValue, 300);

  const data = transformDataBeforeFetch(inputValueDebounced);

  const { data: res, isLoading } = useQueryAdaptor(
    ['' + fetchFunction.name, data],
    () => fetchFunction(data),
  );

  useEffect(() => {
    if (clear) {
      setOldValue(value);
      change('');
    } else {
      change(oldValue || value);
    }
  }, [change, clear, oldValue, value]);

  useMemo(() => {
    let value = [];
    const maxRows = transformDataBeforeFetch().maxRows;
    if (Array.isArray(res)) {
      value = res;
      if (value.length > maxRows) {
        log.info(
          '!!! Из',
          fetchFunction.name,
          'приходит слишком длинный массив длина',
          value.length,
          'элементов',
        );
        value = value.slice(0, maxRows);
      }
    }
    setOptions(value);
  }, [fetchFunction.name, res]);

  /**
   * HandleChange.
   *
   * @param {Event} event - Event.
   * @param {unknown} value - Value.
   * @returns {void}
   */
  const handleChange = (event, value = []) => {
    setInputValue('');
    if (value.length) {
      change(value.pop());
    } else {
      change();
    }
  };

  const error = getFieldError(meta) || errorText;
  const isError = !!error;

  let valueArr = value || [];
  if (!!value && !Array.isArray(value)) {
    valueArr = [value];
  }
  return (
    <Tooltip title={tooltip}>
      <div className={'mr-3'}>
        <AutocompleteMUI
          className={className}
          disabled={disabled}
          filterOptions={(options) => options}
          getOptionLabel={(option) => option && option.name}
          getOptionSelected={(option, value) => {
            if (!value) {
              return [];
            }
            return option?.name === value?.name;
          }}
          loading={isLoading}
          multiple={true}
          options={options}
          renderInput={(params) => {
            // костыль что бы перехватить введенную строку
            const { onChange } = params.inputProps;

            /**
             *
             * @param event
             */
            params.inputProps.onChange = (event) => {
              setInputValue(event.target.value);
              onChange(event);
            };
            params.inputProps.value = inputValue;

            return (
              <>
                <TextFieldMUI
                  {...params}
                  className={'autocomplete-with-chip-async__form-control'}
                  error={isError}
                  helperText={error || helperText || <span>&nbsp;</span>}
                  variant={'standard'}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  label={label}
                  placeholder={value ? '' : placeholder}
                  required={required}
                />
              </>
            );
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <ChipMUI
                label={option.name}
                size={'small'}
                variant={'outlined'}
                style={{ maxWidth: '100' }}
                {...getTagProps({ index })}
              />
            ))
          }
          value={valueArr}
          loadingText={'Загрузка...'}
          openText="Открыть"
          closeText="Закрыть"
          clearText="Очистить"
          noOptionsText="Ничего не найдено"
          onChange={handleChange}
        />
      </div>
    </Tooltip>
  );
};

AutocompleteWithChipAsync.defaultProps = {
  dark: false,
  disabled: false,
  helperText: '',
  label: 'AutocompleteAsync',

  /**
   *
   * @param _
   */
  onChange: (_) => _,
  placeholder: 'Начните вводить',
  required: false,
  value: [],
};
