import { toDate } from 'app/utils/date/toDate';
import { KeyboardDatePickerFF } from 'core/form/finalForm/fields/default/datePickers/KeyboardDatePickerFF';
import { usePreparation } from 'core/utils/hooks/usePreparation/preparation';

/**
 * Поле стартовой даты.
 *
 * @returns {JSX.Element}
 */
export const KeyboardDateStartComponent = () => {
  usePreparation('startDate', toDate);
  return (
    <KeyboardDatePickerFF
      {...{
        disabled: true,
        label: 'Действует с',
        name: 'startDate',
      }}
    />
  );
};
