import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const INSTALL_DRNO_NAME = 'installDrno';

/**
 * ## Тип навесного оборудования.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const InstallDrno = ({ ...props }) => {
  const options = [
    { id: 'true', name: 'Да' },
    { id: 'false', name: 'Нет' },
  ];

  return (
    <Select
      {...props}
      options={options}
      label={'Не подлежит оснащению ДРНО'}
      name={INSTALL_DRNO_NAME}
    />
  );
};
