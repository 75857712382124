import { ActOfCompletedWork } from 'app/components/card/ogh/ImprovementTerritoryCard/components/ActOfCompletedWork/index';
import { DataCoordOrganizationRF } from 'app/pages/cardsOgh/components/tables/Documents/FieldsRF/preparedFields/DataCoordOrganization.RF';
import { DocumentsConfirmingTheChangesRF } from 'app/pages/cardsOgh/components/tables/Documents/FieldsRF/preparedFields/DocumentsConfirmingTheChanges.RF';
import { MarkingDrawingRF } from 'app/pages/cardsOgh/components/tables/Documents/FieldsRF/preparedFields/MarkingDrawing.RF';
import { OtherLegalDocumentsRF } from 'app/pages/cardsOgh/components/tables/Documents/FieldsRF/preparedFields/OtherLegalDocuments.RF';
import { PlanFlowerRF } from 'app/pages/cardsOgh/components/tables/Documents/FieldsRF/preparedFields/PlanFlower.RF';
import { SituationPlanRF } from 'app/pages/cardsOgh/components/tables/Documents/FieldsRF/preparedFields/SituationPlan.RF';
import { SketchFlowerRF } from 'app/pages/cardsOgh/components/tables/Documents/FieldsRF/preparedFields/SketchFlower.RF';
import { SolutionObjectRF } from 'app/pages/cardsOgh/components/tables/Documents/FieldsRF/preparedFields/SolutionObject.RF';
import { SymbolsInventoryPlanRF } from 'app/pages/cardsOgh/components/tables/Documents/FieldsRF/preparedFields/SymbolsInventoryPlan.RF';
import { InventoryPlanRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/InventoryPlan.RF';
import { OtherDocumentsRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/OtherDocuments.RF';
import React from 'react';

/**
 * YardDocsTab.
 *
 * @returns JSX.Element.
 */
export const YardDocsTab = () => {
  return (
    <div className={'d-none'} id="docs">
      <DocumentsConfirmingTheChangesRF />
      <ActOfCompletedWork />
      <SituationPlanRF />
      <InventoryPlanRF />
      <SymbolsInventoryPlanRF />
      <SolutionObjectRF />
      <PlanFlowerRF />
      <SketchFlowerRF />
      <MarkingDrawingRF />
      <DataCoordOrganizationRF />
      <OtherDocumentsRF />
      <OtherLegalDocumentsRF />
    </div>
  );
};
