import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { useUser } from 'app/pages/providers';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const STAGE_RESOLUTION_NAME = 'stageResolution';

/**
 * Границы.
 *
 * @param {*} props - Properties.
 * @returns {JSX.Element}
 */
export const StageResolution = (props) => {
  const dict = 'boundary';
  const { hasPermission } = useUser();
  const { data: options = [] } = useGetDictionary(dict);

  // todo: hasPermission('registry_matching_boundary_ogh') повесили в рамках задачи
  //  https://jira.elocont.ru/browse/ODS-8181
  //  ODS-8181 Отключение функционала согласования границ
  //  потом убрать и раскомитить тесты
  hasPermission('registry_matching_boundary_ogh');
  return hasPermission('registry_matching_boundary_ogh') ? (
    <Select
      {...props}
      label={'Границы'}
      name={STAGE_RESOLUTION_NAME}
      optionKey={'code'}
      options={options}
    />
  ) : null;
};
