 
export const ACTIVE = 1;
 
export const PROJECT = 2;
 
export const MATCHING_ADD = 3;
 
export const MATCHING_DEL = 4;
 
export const APPROVED_EXTERNAL_SYSTEM = 5;
 
export const CANCEL = 7;
