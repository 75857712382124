import { Map, MapPointerEvent } from '@2gis/mapgl/types';
import bbox from '@turf/bbox';
import transformScale from '@turf/transform-scale';
import { BBox, center, lineString, polygon, Position } from '@turf/turf';

import { GeometryUtils } from '../../..';
import {
  Controls,
  EditorShapeOnChangeEvent,
  GeometryTypes,
  Marker,
  ShapeOptions,
} from '../../../types.d';
import ShapeEditor, { isGeometryPolygon } from '../..';
import Shape from '../Controls/Shape';
import Square from '../Controls/Square';

export type ResizerShape = Shape<Resizer>;

/**
 * Иконка для перемещения геометрии.
 *
 * @param map - Карта.
 * @param coordinates - Координаты точки.
 * @returns Объект с методами для перемещения геометрии.
 */
export default class Resizer {
  public shapes: {
    topLeft: ResizerShape;
    top: ResizerShape;
    topRight: ResizerShape;
    right: ResizerShape;
    bottomRight: ResizerShape;
    bottom: ResizerShape;
    bottomLeft: ResizerShape;
    left: ResizerShape;
  };

  // public flipStatus: FlipStatus = new FlipStatus(false, false);

  public map: Map;

  public geometryUtils: GeometryUtils;

  public shapeEditor: ShapeEditor;

  public hoveredShape: [keyof Resizer['shapes'], ResizerShape] | undefined =
    undefined;

  private zoomEndHandler: () => void;

  /**
   * Создание контрола для изменения размера геометрии.
   *
   * @param map - Эксземпляр карты.
   * @param bounds - Координаты точки.
   * @param geometryUtils - Утилиты геометрии.
   * @param shapeEditor - Редактор геометрий.
   */
  constructor(
    map: Map,
    bounds: BBox,
    geometryUtils: GeometryUtils,
    shapeEditor: ShapeEditor,
  ) {
    const {
      topLeft,
      top,
      topRight,
      right,
      bottomRight,
      bottom,
      bottomLeft,
      left,
    } = this.createSquareCoordinatesByBBox(bounds);

    this.map = map;
    this.geometryUtils = geometryUtils;
    this.shapeEditor = shapeEditor;

    this.shapes = {
      bottom: this.createSquare(map, bottom, [8, 8], { cursor: 'ns-resize' }),
      bottomLeft: this.createSquare(map, bottomLeft, [12, 12], {
        cursor: 'nesw-resize',
      }),
      bottomRight: this.createSquare(map, bottomRight, [12, 12], {
        cursor: 'nwse-resize',
      }),
      left: this.createSquare(map, left, [8, 8], { cursor: 'ew-resize' }),
      right: this.createSquare(map, right, [8, 8], { cursor: ' ew-resize' }),
      top: this.createSquare(map, top, [8, 8], { cursor: 'ns-resize' }),
      topLeft: this.createSquare(map, topLeft, [12, 12], {
        cursor: 'nwse-resize',
      }),
      topRight: this.createSquare(map, topRight, [12, 12], {
        cursor: 'nesw-resize',
      }),
    };

    this.zoomEndHandler = this.onZoomEnd.bind(this);

    map.on('zoomend', this.zoomEndHandler);
  }

  /**
   * Обработчик изменения геометрии по горизонтали и вертикали.
   *
   * @param args - Аргументы.
   * @param args.geometry - Геометрия.
   * @param args.interactingShape - Фигура с которой происходит взаимодействие.
   * @param args.factors - Коэффициенты изменения.
   * @returns Новые границы.
   */
  private getResizedGeometryCoordinates({
    geometry,
    interactingShape: shape,
    factors,
  }: Omit<EditorShapeOnChangeEvent<Resizer>, 'interactingShape'> & {
    interactingShape: ResizerShape;
    factors: number[];
  }) {
    const geometryCoordinates = geometry.userData.coordinates;
    const isPolygon = isGeometryPolygon(geometry);

    const mainGeometryCoordinates = geometryCoordinates;
    const shapeGeometry = shape.getGeometry();

    if (!shapeGeometry) return geometryCoordinates;

    const [factorX, factorY] = factors;

    const turfGeometry = isPolygon
      ? polygon(mainGeometryCoordinates as number[][][])
      : lineString(mainGeometryCoordinates as number[][]);

    if (!this.shapeEditor.mover) return;
    const origin = this.shapeEditor.mover.shape.getGeometry()?.getCoordinates();

    if (!origin) return geometryCoordinates;

    const newGeometryCoordinatesByX = transformScale(turfGeometry, factorX, {
      origin,
    }).geometry.coordinates;
    const newGeometryCoordinatesByY = transformScale(turfGeometry, factorY, {
      origin,
    }).geometry.coordinates;

    const newGeometryCoordinates = isPolygon
      ? (newGeometryCoordinatesByX as Position[][]).map(
          (innerPolygonX, polygonIndex) => {
            const innerPolygonY = (newGeometryCoordinatesByY as Position[][])[
              polygonIndex
            ];
            return innerPolygonX.map((_coordinates, index) => {
              return [_coordinates[0], innerPolygonY[index][1]];
            });
          },
        )
      : newGeometryCoordinatesByX.map((coordinates, index) => {
          return [coordinates[0], newGeometryCoordinatesByY[index][1]];
        });

    return isPolygon ? newGeometryCoordinates : newGeometryCoordinates;
  }

  /**
   * Создает координаты фигур ресайза.
   *
   * @param bounds - Границы геометрии.
   * @returns Координаты контуров геометрии.
   */
  private createSquareCoordinatesByBBox(bounds: BBox) {
    const [west, south, east, north] = bounds;

    return {
      bottom: [(west + east) / 2, south],
      bottomLeft: [west, south],
      bottomRight: [east, south],
      left: [west, (south + north) / 2],
      right: [east, (south + north) / 2],
      top: [(west + east) / 2, north],
      topLeft: [west, north],
      topRight: [east, north],
    };
  }

  /**
   * Создает контрол для изменения размера геометрии.
   *
   * @param map - Экземпляр карты.
   * @param coordinates - Координаты точки.
   * @param size - Размер геометрии.
   * @param options - Настройки.
   * @returns Экземпляр Square.
   */
  private createSquare(
    map: Map,
    coordinates: number[],
    size: [number, number],
    options: Partial<ShapeOptions<Resizer>> = {},
  ) {
    return new Square<Resizer>(map, {
      coordinates: coordinates,
      iconSize: size,
      mapService: this.shapeEditor.mapService,
      size,
      ...options,
      userData: {
        control: this,
        coordinates,
        coordinatesToCheckMouseEvent: [],
        type: GeometryTypes.Point,
        ...options.userData,
      },
    });
  }

  /**
   * Устанавливает стиль курсора при наведении на мувер.
   *
   * @param shapeData - Данные фигуры.
   * @param shapeData."0" - Название фигуры.
   */
  setCursorStyle([shapeName]: [keyof Resizer['shapes'], ResizerShape]) {
    this.shapes[shapeName].setCursorStyle();
  }

  /**
   * Сбрасывает стиль курсора при наведении на мувер.
   *
   * @param shapeName - Название фигуры.
   */
  resetCursorStyle(shapeName?: keyof Resizer['shapes']) {
    if (shapeName) this.shapes[shapeName].setCursorStyle(null);
    else
      Object.values(this.shapes).forEach((shape) => shape.setCursorStyle(null));
  }

  /**
   * Определяет фигуру на которую навели курсор.
   *
   * @param event - Событие.
   * @param event.lngLat - Координаты курсора.
   * @returns Фигура и ее координаты.
   */
  getHoveredShape({ lngLat }: MapPointerEvent) {
    const shapes = Object.entries(this.shapes) as [
      keyof Resizer['shapes'],
      ResizerShape,
    ][];
    this.hoveredShape = shapes.find(([, shape]) =>
      this.geometryUtils.isPointWithinPolygon(
        lngLat,
        shape.coordinatesToCheckMouseEvent,
      ),
    );
    return this.hoveredShape;
  }

  /**
   * Определяет направление изменения геометрии.
   *
   * @param interactingShape - Фигура, на которую навели курсор.
   * @returns Направление изменения геометрии.
   */
  private getDirection(interactingShape: ResizerShape) {
    const direction =
      interactingShape === this.shapes.left ||
      interactingShape === this.shapes.right
        ? 'horizontal'
        : interactingShape === this.shapes.top ||
          interactingShape === this.shapes.bottom
        ? 'vertical'
        : interactingShape === this.shapes.topLeft ||
          interactingShape === this.shapes.topRight ||
          interactingShape === this.shapes.bottomLeft ||
          interactingShape === this.shapes.bottomRight
        ? 'both'
        : 'none';

    const isHorizontal = direction === 'horizontal' || direction === 'both';
    const isVertical = direction === 'vertical' || direction === 'both';

    return {
      direction,
      isHorizontal,
      isVertical,
    };
  }

  /**
   * Определяет нужно ли сжимать фигуру по горизонтали и по вертикали.
   *
   * @param shapeGeometry - Геометрия фигуры, на которую навели курсор.
   * @param interactingShape - Фигура, на которую навели курсор.
   * @param destinationCoordinates - Координаты курсора.
   * @param direction - Направления изменения геометрии.
   * @param direction.isHorizontal - Изменяется ли геометрия по горизонтали.
   * @param direction.isVertical - Изменяется ли геометрия по вертикали.
   * @returns Направление сжатия геометрии.
   */
  private getShrink(
    shapeGeometry: Marker,
    interactingShape: ResizerShape,
    destinationCoordinates: number[],
    {
      isHorizontal,
      isVertical,
    }: { isHorizontal: boolean; isVertical: boolean },
  ) {
    const shrink = {
      // eslint-disable-next-line
      x: !isHorizontal
        ? false
        : interactingShape === this.shapes.left ||
          interactingShape === this.shapes.topLeft ||
          interactingShape === this.shapes.bottomLeft
        ? destinationCoordinates[0] > shapeGeometry.userData.coordinates[0]
        : interactingShape === this.shapes.right ||
          interactingShape === this.shapes.topRight ||
          interactingShape === this.shapes.bottomRight
        ? destinationCoordinates[0] < shapeGeometry.userData.coordinates[0]
        : false,
      // eslint-disable-next-line
      y: !isVertical
        ? false
        : interactingShape === this.shapes.top ||
          interactingShape === this.shapes.topLeft ||
          interactingShape === this.shapes.topRight
        ? destinationCoordinates[1] < shapeGeometry.userData.coordinates[1]
        : interactingShape === this.shapes.bottom ||
          interactingShape === this.shapes.bottomLeft ||
          interactingShape === this.shapes.bottomRight
        ? destinationCoordinates[1] > shapeGeometry.userData.coordinates[1]
        : false,
    };

    return shrink;
  }

  /**
   * Определяет перевернута ли фигура по горизонтали и по вертикали.
   *
   * @param destinationCoordinates - Координаты курсора.
   * @param interactingShape - Фигура, на которую навели курсор.
   * @param geometryCenter - Цент геометрии.
   * @param direction - Направления изменения геометрии.
   * @param direction.isHorizontal - Изменяется ли геометрия по горизонтали.
   * @param direction.isVertical - Изменяется ли геометрия по вертикали.
   * @returns Направление перевернутости геометрии.
   */
  private getFlipped(
    destinationCoordinates: number[],
    interactingShape: ResizerShape,
    geometryCenter: number[],
    {
      isHorizontal,
      isVertical,
    }: { isHorizontal: boolean; isVertical: boolean },
  ) {
    const flipped = {
      // eslint-disable-next-line
      x: !isHorizontal
        ? false
        : interactingShape === this.shapes.left ||
          interactingShape === this.shapes.topLeft ||
          interactingShape === this.shapes.bottomLeft
        ? geometryCenter[0] < destinationCoordinates[0]
        : interactingShape === this.shapes.right ||
          interactingShape === this.shapes.topRight ||
          interactingShape === this.shapes.bottomRight
        ? geometryCenter[0] > destinationCoordinates[0]
        : false,
      // eslint-disable-next-line
      y: !isVertical
        ? false
        : interactingShape === this.shapes.top ||
          interactingShape === this.shapes.topLeft ||
          interactingShape === this.shapes.topRight
        ? geometryCenter[1] > destinationCoordinates[1]
        : interactingShape === this.shapes.bottom ||
          interactingShape === this.shapes.bottomLeft ||
          interactingShape === this.shapes.bottomRight
        ? geometryCenter[1] < destinationCoordinates[1]
        : false,
    };

    // if (flipped.x) this.flipStatus.flipX();
    // if (flipped.y) this.flipStatus.flipY();

    return flipped;
  }

  /**
   * Обработчик изменения размера геометрии.
   *
   * @param args - Аргументы.
   * @param args.controls - Объект контроллеров изменения геометрии.
   * @param args.geometry - Геометрия.
   * @param args.box - Bounding box геометрии.
   * @param args.coordinates - Координаты точки.
   * @param args.interactingShape - Фигура с которой происходит взаимодействие.
   * @returns Измененная геометрия.
   */
  onChange({
    controls,
    geometry,
    box,
    coordinates,
    interactingShape,
  }: EditorShapeOnChangeEvent<Resizer>) {
    // const _ish = interactingShape;
    const { shape } = interactingShape;
    if (!shape) return geometry.userData.coordinates;

    const shapeGeometry = shape.getGeometry();
    if (!shapeGeometry) return geometry.userData.coordinates;

    const isPolygon = isGeometryPolygon(geometry);
    const turfGeometry = isPolygon
      ? polygon(geometry.userData.coordinates)
      : lineString(geometry.userData.coordinates);
    const centerPoint = center(turfGeometry);

    const { direction, isHorizontal, isVertical } = this.getDirection(shape);

    if (direction === 'none') return geometry.userData.coordinates;

    const destinationCoordinates =
      direction === 'both'
        ? coordinates
        : direction === 'horizontal'
        ? [coordinates[0], shapeGeometry.userData.coordinates[1]]
        : [shapeGeometry.userData.coordinates[0], coordinates[1]];

    // x - нужно ли уменьшить геометрию по горизонтали
    // y - нужно ли уменьшить геометрию по вертикали
    const shrink = this.getShrink(shapeGeometry, shape, coordinates, {
      isHorizontal,
      isVertical,
    });

    const leftShapeGeometry = this.shapes.left.getGeometry();
    const rightShapeGeometry = this.shapes.right.getGeometry();
    const topShapeGeometry = this.shapes.top.getGeometry();
    const bottomShapeGeometry = this.shapes.bottom.getGeometry();
    const interactiveShapeGeometry = shape.getGeometry();

    if (
      !leftShapeGeometry ||
      !rightShapeGeometry ||
      !topShapeGeometry ||
      !bottomShapeGeometry ||
      !interactiveShapeGeometry
    ) {
      return geometry.userData.coordinates;
    }

    const boundLeftInPixels = box.map.project(
      leftShapeGeometry.getCoordinates(),
    );
    const boundRightInPixels = box.map.project(
      rightShapeGeometry.getCoordinates(),
    );
    const boundTopInPixels = box.map.project(topShapeGeometry.getCoordinates());
    const boundBottomInPixels = box.map.project(
      bottomShapeGeometry.getCoordinates(),
    );

    const interactingShapeCoordinatesInPixels = box.map.project(
      interactiveShapeGeometry.getCoordinates(),
    );
    const cursorPositionInPixels = box.map.project(destinationCoordinates);

    const xSideLength = Math.abs(boundRightInPixels[0] - boundLeftInPixels[0]);
    const ySideLength = Math.abs(boundTopInPixels[1] - boundBottomInPixels[1]);

    const flipped = this.getFlipped(
      destinationCoordinates,
      shape,
      centerPoint.geometry.coordinates,
      {
        isHorizontal,
        isVertical,
      },
    );

    // вычисляем коэффициенты изменения геометрии в зависимости от расстояния до курсора
    const factorX = isHorizontal
      ? Math.abs(
          (xSideLength +
            // eslint-disable-next-line
            (shrink.x ? -1 : 1) *
              Math.abs(
                cursorPositionInPixels[0] -
                  interactingShapeCoordinatesInPixels[0],
              )) /
            xSideLength,
        )
      : 1;
    const factorY = isVertical
      ? Math.abs(
          (ySideLength +
            // eslint-disable-next-line
            (shrink.y ? -1 : 1) *
              Math.abs(
                cursorPositionInPixels[1] -
                  interactingShapeCoordinatesInPixels[1],
              )) /
            ySideLength,
        )
      : 1;

    const resizedGeometryCoordinates = this.getResizedGeometryCoordinates({
      box,
      controls,
      coordinates: destinationCoordinates,
      // eslint-disable-next-line
      factors: [flipped.x ? -factorX : factorX, flipped.y ? -factorY : factorY],
      geometry,
      interactingShape: shape,
    });

    const newBounds = bbox(
      lineString(
        isPolygon
          ? ShapeEditor.polygonToLineCoordinates(
              resizedGeometryCoordinates as number[][][],
            )
          : (resizedGeometryCoordinates as number[][]),
      ),
    );

    this.updateShapesCoordinates(newBounds);

    box.update(newBounds);

    controls.mover.shape.updateCoordinates({
      lngLat: center(polygon(box.geometry.userData.coordinates)).geometry
        .coordinates,
    });

    this.updateRotatorCoordinates(controls, newBounds);

    // eslint-disable-next-line
    if (flipped.x || flipped.y) {
      this.flipShape(shape, interactingShape, flipped);
    }

    return resizedGeometryCoordinates;
  }

  /**
   * Обновляет координаты ротатора.
   *
   * @param controls - Объект контроллеров изменения геометрии.
   * @param bounds - Границы геометрии.
   */
  private updateRotatorCoordinates(controls: Controls, bounds: BBox) {
    const rotatorGeometry = controls.rotator.shape.getGeometry();

    if (rotatorGeometry) {
      controls.rotator.shape.updateCoordinates({
        lngLat: ShapeEditor.getRotatorCoordinates(this.map, bounds),
      });
    }
  }

  /**
   * Обновляет координаты фигур.
   *
   * @param newBounds - Границы геометрии.
   */
  public updateShapesCoordinates(newBounds: BBox) {
    const {
      topLeft,
      top,
      topRight,
      right,
      bottomRight,
      bottom,
      bottomLeft,
      left,
    } = this.createSquareCoordinatesByBBox(newBounds);

    this.shapes.topLeft.updateCoordinates({ lngLat: topLeft });
    this.shapes.top.updateCoordinates({ lngLat: top });
    this.shapes.topRight.updateCoordinates({ lngLat: topRight });
    this.shapes.right.updateCoordinates({ lngLat: right });
    this.shapes.bottomRight.updateCoordinates({ lngLat: bottomRight });
    this.shapes.bottom.updateCoordinates({ lngLat: bottom });
    this.shapes.bottomLeft.updateCoordinates({ lngLat: bottomLeft });
    this.shapes.left.updateCoordinates({ lngLat: left });
  }

  /**
   * Отражает геометрию.
   *
   * @param currentShape - Объект с текущей фигурой.
   * @param interactingShape - Объект с фигурой.
   * @param interactingShape.shape - Связанная с отраженной геометрией фигура.
   * @param flipped - Объект с флагами отражения геометрии.
   * @param flipped.x - Отражена ли геометрию по оси X.
   * @param flipped.y - Отражена ли геометрию по оси Y.
   */
  private flipShape(
    currentShape: ResizerShape,
    interactingShape: { shape: ResizerShape | null },
    // eslint-disable-next-line
    flipped: { x: boolean; y: boolean },
  ) {
    // eslint-disable-next-line
    if (flipped.x && flipped.y) {
      interactingShape.shape =
        currentShape === this.shapes.topLeft
          ? this.shapes.bottomRight
          : currentShape === this.shapes.topRight
          ? this.shapes.bottomLeft
          : currentShape === this.shapes.bottomLeft
          ? this.shapes.topRight
          : currentShape === this.shapes.bottomRight
          ? this.shapes.topLeft
          : currentShape;

      return;
    }
    // eslint-disable-next-line
    if (flipped.y) {
      interactingShape.shape =
        currentShape === this.shapes.top
          ? this.shapes.bottom
          : currentShape === this.shapes.bottom
          ? this.shapes.top
          : currentShape === this.shapes.topLeft
          ? this.shapes.bottomLeft
          : currentShape === this.shapes.topRight
          ? this.shapes.bottomRight
          : currentShape === this.shapes.bottomLeft
          ? this.shapes.topLeft
          : currentShape === this.shapes.bottomRight
          ? this.shapes.topRight
          : currentShape;

      return;
    }
    // eslint-disable-next-line
    if (flipped.x) {
      interactingShape.shape =
        currentShape === this.shapes.right
          ? this.shapes.left
          : currentShape === this.shapes.left
          ? this.shapes.right
          : currentShape === this.shapes.topLeft
          ? this.shapes.topRight
          : currentShape === this.shapes.topRight
          ? this.shapes.topLeft
          : currentShape === this.shapes.bottomLeft
          ? this.shapes.bottomRight
          : currentShape === this.shapes.bottomRight
          ? this.shapes.bottomLeft
          : currentShape;

      return;
    }
  }

  /**
   * Обработчик изменения размера геометрии.
   *
   */
  onZoomEnd() {
    const shapes = Object.values(this.shapes);
    shapes.forEach((shape) => {
      const coordinates = shape.getGeometry()?.getCoordinates();

      if (coordinates) {
        shape.updateCoordinatesToCheckMouseEvent(coordinates);
      }
    });
  }

  /**
   * Скрывает контрол.
   *
   * @returns {void} Экземпляр Mover.
   */
  hide() {
    this.shapes.topLeft.hide();
    this.shapes.top.hide();
    this.shapes.topRight.hide();
    this.shapes.right.hide();
    this.shapes.bottomRight.hide();
    this.shapes.bottom.hide();
    this.shapes.bottomLeft.hide();
    this.shapes.left.hide();
  }

  /**
   * Показывает контрол.
   *
   * @returns {void} Экземпляр Mover.
   */
  show() {
    this.shapes.topLeft.show();
    this.shapes.top.show();
    this.shapes.topRight.show();
    this.shapes.right.show();
    this.shapes.bottomRight.show();
    this.shapes.bottom.show();
    this.shapes.bottomLeft.show();
    this.shapes.left.show();
  }

  /**
   * Уничтожает контрол.
   *
   * @returns {void} Экземпляр Mover.
   */
  destroy() {
    this.shapes.topLeft.destroy();
    this.shapes.top.destroy();
    this.shapes.topRight.destroy();
    this.shapes.right.destroy();
    this.shapes.bottomRight.destroy();
    this.shapes.bottom.destroy();
    this.shapes.bottomLeft.destroy();
    this.shapes.left.destroy();
  }
}
