import { shallow } from 'enzyme';

import { BodyDialogRepairsInfoListPlan } from './BodyDialog.RepairsInfoListPlan';

describe('BodyDialogRepairsInfoListPlanSpec', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act

    const wrapper = shallow(<BodyDialogRepairsInfoListPlan />);
    // ❓ Assert
    expect(wrapper.text()).toBe(
      '<TextFieldFF /><DatePickerFF /><DatePickerFF /><TextFieldFF /><DndFileUploadFF />',
    );
  });
});
