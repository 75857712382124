/* eslint-disable */
import React from 'react';
import { addCompareEnabledPropIfHaveAnyPrivilege } from 'app/grants/index';
import Versions from './index';


/* eslint-disable */
export default function (types) {
  return addCompareEnabledPropIfHaveAnyPrivilege(
    types.map((item) => `diff_ogh_${item}`),
  )((props) => <Versions {...props} />);
}
