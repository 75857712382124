import { PRIVILEGE_NAME, PrivilegesFF } from 'core/form/finalForm/fields';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';
import { ObjectParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

/**
 * Фильтр привилегий.
 *
 * @returns {JSX.Element}
 */
export const PrivilegesFilter = () => <PrivilegesFF className={filterInput} />;

export { PRIVILEGE_NAME };

/**
 * PrivilegesParams.
 *
 * @returns {object}
 */
export const privilegesParams = () => {
  addPreparation(PRIVILEGE_NAME, (value) => value.id);

  // под старые инпуты
  addPreparation('privilege', (value) => value.id, PRIVILEGE_NAME);
  return {
    [PRIVILEGE_NAME]: ObjectParam,
  };
};
