import { fetchDictionary } from 'app/api/autocomplete/fetchDictionary';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';

/**
 * Хук получения словаря.
 *
 * @category api/hooks.
 * @param {string} dict - Код словаря.
 * @returns - Словарь.
 * @example ----
 * const dict = 'grbs';
 * const { data = , isLoading } = useGetDictionary(dict);
 */
export const useGetDictionary = <TData>(dict: string) => {
  return useQueryAdaptor(['dictionary', dict], fetchDictionary<TData>);
};
