 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Луковичные и клубнелуковичные кроме тюльпанов, нарциссов, кв.м__.
 *
 * @type {{
 * accessor: 'bulbous_area',
 * Header: 'Луковичные и клубнелуковичные кроме тюльпанов, нарциссов, кв.м',
 * }}
 * @augments Cell
 */
export const bulbous_area = {
  accessor: 'bulbous_area',
  Header: 'Луковичные и клубнелуковичные кроме тюльпанов, нарциссов, кв.м',
  sortable: true,
};
