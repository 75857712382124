/* eslint-disable */
import React from 'react';
import { NumberParam } from 'use-query-params';

import { CarSeasonFF, CAR_SEASON_NAME } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
 
/**
 * Сезонность–> car_season [поле справочник, или если пусто - все]
 */
 
export const CarSeasonFilter = (props) => (
  <CarSeasonFF {...props} className={filterInput} />
);

 
export { CAR_SEASON_NAME };

 
export const carSeasonParams = {
  [CAR_SEASON_NAME]: NumberParam,
};
