import { useGetParamsUrl } from 'core/utils/hooks/useGetParamsUrl';

/**
 * Хук на получение стартовой даты и id ОГХ.
 *
 * @category hooks.
 * @description Получаем из урла id созданной карточки.
 * @returns {{startDate, typeId}} - Жопа.
 * @example
 * const {startDate: string, typeId: string} = useGetObjectId()
 */
export const useGetCreatedObjectId = () => {
  const { startDate, typeId } = useGetParamsUrl();
  return { startDate, typeId };
};
