/* eslint-disable */
import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import {
  Reason,
  REASON_NAME,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/Reason';

 
 
/**
 * ## Причина изменения
 * ### React Final Form Field
 *
 * ###### type = `Select`
 *
 *
 * ```js
 * import {
  ReasonFF,
} from 'core/form/finalForm/fields';
 * ```
 *
 *
 */
/* eslint-disable */
export const ReasonFF = ({ name, ...otherProps }) => {
  return (
    <Field name={REASON_NAME}>
      {(props) => {
        return <Reason {...props} {...props.input} {...otherProps} />;
      }}
    </Field>
  );
};

ReasonFF.propTypes = {
   
   
/**
   * очищаем инпут с сохранением значения
   */
  clear: PropTypes.bool,
   
   
/**
   * быбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
/**
   * placeholder
   */
  placeholder: PropTypes.string,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
};
ReasonFF.defaultProps = {
  disabled: false,
};
