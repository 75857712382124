import { getColumnsRepairsPlanTab } from './columns.RepairsPlanTab';
import { DeleteButtonCellRepairsPlan } from './Delete.Button.Cell.RepairsPlan';

describe('ColumnsRepairsPlanTabSpec', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act

    // ❓ Assert
    expect(getColumnsRepairsPlanTab()).toStrictEqual([
      {
        Cell: DeleteButtonCellRepairsPlan,
        Header: '',
        accessor: 'id',
      },
      {
        Header: 'Наименование',
        accessor: 'name',
      },
      {
        Header: 'Дата',
        accessor: 'date',
      },
      {
        Header: 'Характеристика (объемы)',
        accessor: 'property',
      },
      {
        Header: 'Планируемое время проведения работ',
        accessor: 'plan_time',
      },
    ]);
  });
});
