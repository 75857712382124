import { fetchCreate } from 'app/api/ogh';
/* eslint-disable  */
import { useDialogs } from 'app/pages/cardsOgh/components/DIalogs/useDialogs';
import { usePreloaderCard } from 'app/pages/cardsOgh/components/PreloadedCard/usePreloaderCard';
import { useGetTreePanelData } from 'app/pages/cardsOgh/components/RightPanelCard/components/TreeRightPanel/hooks/useGetTreePanelData/useGetTreePanelData';
import { copySelectedGeometryMessage } from 'app/pages/cardsOgh/helperComponents/copySelectedGeometryMessage';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import {
  CREATE_FROM_PARENT,
  useTypeActionCard,
} from 'app/pages/cardsOgh/hooks/useTypeActionCard';
import { toast } from 'core/uiKit/components/Toast';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import { useNavigateRouter } from 'core/utils/router';

/**
 * Хук создания нового паспорта.
 *
 * @returns {{fetchCreateForm: Function}}
 */
export const useCreateForm = () => {
  const { clearEditMode } = useEditMode();
  const navigate = useNavigateRouter();
  const { id } = useGetObjectId();
  const { removeTree } = useGetTreePanelData();
  const { endPreload } = usePreloaderCard();
  const { showAlert } = useDialogs();
  const typeAction = useTypeActionCard();

  const [fetchCreateForm] = useMutationAdaptor((value) => fetchCreate(value), {
    /**
     *
     * @param err
     */
    onError: (err) => {
      endPreload();
      if (err.message.match(/рассчитанная по геометрии/g)) {
        showAlert(copySelectedGeometryMessage(err.message));
      } else {
        showAlert(err.message ? err.message : 'Ошибка при создании паспорта');
      }
    },

    /**
     *
     * @param value
     */
    onSuccess: (value) => {
      endPreload();
      removeTree();
      clearEditMode();
      toast.successDying('Паспорт добавлен', {
        autoClose: 2000,
      });
      switch (typeAction) {
        case CREATE_FROM_PARENT: {
          navigate(`/ogh/${id}/${value.id}`);
          break;
        }
        default: {
          navigate(`/ogh/${value.id}`);
          break;
        }
      }
    },
  });

  return { fetchCreateForm };
};
