import { post } from 'app/api';

 
/**
 * Метод получения таблицы "Вложенные объекты".
 *
 * @param {object} query - Параметры запроса.
 * @returns {Promise<*>} - Возвращает данные таблицы.
 * @example
 * const { data = [], isLoading } = useQueryAdaptor(
 *     [
 *       'getNestedObjectsDiffTable',
 *       {
 *         etalon: etalon.id,
 *         copy: copy.id,
 *         typeId,
 *       },
 *     ],
 *     getNestedObjectsDiffTable,
 *   );
 */
export const getNestedObjectsDiffTable = (query) => {
  return post('/versions/treediff', query);
};
