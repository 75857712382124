/* eslint-disable  */
import { filterInput } from 'core/form/filterForm/components/filterInputsClasses';
import { Button } from 'core/uiKit/components/buttons';
import { Skeleton } from 'core/uiKit/components/skeletons/Skeleton';
import React from 'react';

/**
 *
 */
export const FilterSkeleton = () => {
  return (
    <div>
      <div className={'display-flex flex-wrap'}>
        <Skeleton
          className={filterInput}
          variant="rect"
          width={250}
          height={36}
        />
        <Skeleton
          className={filterInput}
          variant="rect"
          width={250}
          height={36}
        />
        <Skeleton
          className={filterInput}
          variant="rect"
          width={250}
          height={36}
        />
        <Skeleton
          className={filterInput}
          variant="rect"
          width={250}
          height={36}
        />
        <Skeleton
          className={filterInput}
          variant="rect"
          width={250}
          height={36}
        />
        <Skeleton
          className={filterInput}
          variant="rect"
          width={250}
          height={36}
        />
      </div>
      <div className="display-flex justify-content-end py-3">
        <Button
          variant={'contained'}
          color={'primary'}
          className="mr-3"
          type="submit"
        >
          Поиск
        </Button>
        <Button color={'primary'} id={'clear-form'} variant={'outlined'}>
          Сбросить
        </Button>
      </div>
    </div>
  );
};
