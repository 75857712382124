import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { IsDiffHeightMarkRF } from 'core/form/reduxForm/fields';
import React from 'react';

/**
 * CheckBox Field RF __Разновысотные отметки__.
 *
 * @returns
 */
export const IsDiffHeightMarkField = () => {
  const { editMode } = useMode();
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();

  return (
    <IsDiffHeightMarkRF
      // @ts-ignore
      disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
    />
  );
};
