 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Гарантийный срок__.
 *
 * @type {{ accessor: 'guarantee_period',  Header: 'Гарантийный срок' }}
 * @augments Cell
 */
export const guarantee_period = {
  accessor: 'guarantee_period',
  Header: 'Гарантийный срок',
  sortable: true,
};
