import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import isMandatory from 'app/utils/card/isMandatory';
import {
  RANDOM_ID,
  RANDOM_NUMBER,
  RANDOM_WORD,
} from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import { ODH_SIDE_ID_NAME, OdhSideIdField } from './OdhSideId.Field';

vi.mock('app/api/hooks/useGetCurrentCard');
vi.mock('app/api/hooks/useIsEditCurrentObjectIsExternalSystem');
vi.mock('app/pages/cardsOgh/hooks/useMode');
vi.mock('app/utils/card/isMandatory');

describe('🐛 OdhSideIdFieldSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useGetCurrentCard.mockReturnValue({
      odh_side: [],
      typeId: RANDOM_NUMBER,
    });
    useMode.mockReturnValue({ editMode: false });
    useIsEditCurrentObjectIsExternalSystem.mockReturnValue(true);
    isMandatory.mockReturnValue(true);

    // 🔥 Act
    const wrapper = shallow(<OdhSideIdField />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<SelectRF />');
  });
  it('🧪 isMandatory', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useGetCurrentCard.mockReturnValue({
      odh_side: [],
      typeId: RANDOM_NUMBER,
    });
    useMode.mockReturnValue({ editMode: false });
    useIsEditCurrentObjectIsExternalSystem.mockReturnValue(true);
    isMandatory.mockReturnValue(true);

    // 🔥 Act
    shallow(<OdhSideIdField />);
    // ❓ Assert

    expect(isMandatory).toHaveBeenCalledWith(
      RANDOM_NUMBER,
      ODH_SIDE_ID_NAME,
      true,
    );
  });
  it('🧪 props', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useGetCurrentCard.mockReturnValue({
      odh_side: [{ id: RANDOM_ID, name: RANDOM_WORD }],
      typeId: RANDOM_NUMBER,
    });
    useMode.mockReturnValue({ editMode: true });
    useIsEditCurrentObjectIsExternalSystem.mockReturnValue(true);
    isMandatory.mockReturnValue(true);

    // 🔥 Act

    const wrapper = shallow(<OdhSideIdField />);

    // ❓ Assert

    expect(wrapper.props()).toStrictEqual({
      disabled: false,
      label: 'Сторона',
      name: ODH_SIDE_ID_NAME,
      options: [{ id: RANDOM_ID, name: RANDOM_WORD }],
      required: true,
    });
  });
});
