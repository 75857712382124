import * as apiActions from 'app/actions/api';
/* eslint-disable  */
import _ from 'lodash';
import { combineActions, handleActions } from 'redux-actions';

import * as types from '../constants/actionTypes';

const rejectedActionTypes = _.filter(types, function (type) {
  if (type.includes(['API_POST_USER_SIGNIN'])) {
    return false;
  }
  return type.startsWith('API_');
}).map(function (type) {
  return type + '_REJECTED';
});

export default handleActions(
  {
    /**
     *
     * @param state
     * @param action
     */
    [combineActions.apply(null, rejectedActionTypes)]: function (
      state,
      action,
    ) {
      state = {
        ...state,
        lastErrorActionType: action.type.replace(/_REJECTED$/, ''),
      };
      return state;
    },

    /**
     *
     * @param state
     */
    [apiActions.clearLastErrorActionType]: function (state) {
      state = {
        ...state,
        lastErrorActionType: '',
      };
      return state;
    },
  },
  { lastErrorActionType: '' },
);
