import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { OghCardHeaderFF } from 'app/pages/cardsOgh/components/headerFF/OghHeaderFF';
import Versions from 'app/pages/cardsOgh/components/tables/Versions/Versions';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { NoCalcFF } from 'core/form/finalForm/fields/prepared/checkboxes/NoCalc.FF';
import { GreenNumFF } from 'core/form/finalForm/fields/prepared/numberField/GreenNum.FF';
import { SectionNumFF } from 'core/form/finalForm/fields/prepared/numberField/SectionNum.FF/SectionNum.FF';
import { RedBookPlantIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/RedBookPlantId.FF';
import { CardContainer } from 'core/uiKit/components/card.components/CardContainer';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { TabsCardOGH } from 'core/uiKit/components/card.components/TabsCardOGH';
import { TabsCardOGHItem } from 'core/uiKit/components/card.components/TabsCardOGH/TabsCardOGH.Item';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import React from 'react';

/**
 * Левая панель паспорта __Растения Красной книги__.
 *
 * @returns {JSX.Element}
 */
export const RedBookPlantLeftPanel = () => {
  const { editMode } = useMode();
  const { rootId = 0 } = useGetCurrentCard();
  const isCreatedPassport = Boolean(rootId);

  return (
    <CardContainer>
      <OghCardHeaderFF />
      <TabsCardOGH>
        <TabsCardOGHItem tabTitle={'Свойства'}>
          <FieldsSplitColumns>
            <RedBookPlantIdFF disabled={!editMode} required={true} />
            <SectionNumFF required={true} disabled={!editMode} />
            <GreenNumFF required={true} disabled={!editMode} />
            <ParentInfo />
          </FieldsSplitColumns>
          <NoCalcFF disabled={!editMode} />
        </TabsCardOGHItem>
        <TabsCardOGHItem tabTitle={'Версии'} hide={!isCreatedPassport}>
          <Versions />
        </TabsCardOGHItem>
      </TabsCardOGH>
    </CardContainer>
  );
};
