/* eslint-disable  */
import { Button } from 'core/uiKit/components/buttons/Button';
import { Dialog } from 'core/uiKit/components/dialogs/Dialog';
import { useState } from 'react';

import { Body } from './Body';

/**
 *
 * @param root0
 * @param root0.row
 * @param root0.row.original
 * @param root0.row.original.comment
 * @param root0.row.original.file_list
 */
export const CommentsWithFilesCell = ({
  row: { original: { comment, file_list: fileList } = {} } = {},
}) => {
  const [isOpen, setIsOpen] = useState(false);

  /**
   *
   */
  const onClose = () => setIsOpen(false);
  const show = comment || fileList?.length;

  return show ? (
    <>
      <Button onClick={() => setIsOpen(true)}>Показать...</Button>
      <Dialog
        maxWidth={'xs'}
        isOpen={isOpen}
        onClose={onClose}
        Body={<Body {...{ comment, fileList }} />}
        textHeader={'Комментарий'}
      />
    </>
  ) : null;
};
