import { TextFieldFF } from 'core/form/finalForm/fields/default/TextFieldFF';
import { QUANTITY_NAME } from 'core/uiKit/preparedInputs/textField/Quantity';
import React from 'react';

/**
 * Поле __Количество__.
 *
 * @returns {JSX.Element}
 */
export const QuantityField = () => {
  return (
    <TextFieldFF
      name={QUANTITY_NAME}
      label={'Количество'}
      disabled={true}
      required={true}
    />
  );
};
