import { Table } from 'core/uiKit/components/tables/Table';

import { getColumnsDts } from './columns.Dts';
import { useSetupTableDts } from './useSetup.Table.Dts';

/**
 * Компонент таблицы __Дорожно-тропиночные сети__.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - Id типа огх.
 * @param {number} props.parentTypeId - Id типа родителя.
 * @returns {JSX.Element} - JSX.
 * @example -----
 * <TableDts
 *  typeId={typeId}
 *  parentTypeId={3}
 * />
 */
export const TableDts = ({ typeId, parentTypeId }) => {
  const { table, isLoading, count } = useSetupTableDts({
    parentTypeId,
    typeId,
  });

  return (
    <Table
      columns={getColumnsDts()}
      data={table || []}
      isLoading={isLoading}
      total={count}
    />
  );
};
