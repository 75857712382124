import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import ToggleButton from 'app/components/card/common/MapEditPanel/buttons/ToggleButton';
import toggleContent from 'app/components/card/common/MapEditPanel/content/toggle';
import { useMapContext } from 'app/components/map/useMapContext';
import { REON } from 'app/constants/layers';
import { useMapEdit } from 'app/pages/cardsOgh/components/Map/useMapEdit';
import React from 'react';

import { useGetReonIntersections } from './useGetReonIntersections';

/**
 * Кнопка Показать пересечения с данными ИС РЕОН с алертом.
 *
 * @param {object} props - Пропсы.
 * @param {(message: string)=> void} props.showAlert - Функция вызова Алерта.
 * @returns {React.ReactElement}
 */
export const ReonButtonWithAlert = ({ showAlert }) => {
  const { clearLayer, getGeometry, isMapLoaded } = useMapContext();
  const { hasReonIntersections, setHasReonIntersections } = useMapEdit();
  const { recordId } = useGetCurrentCard();
  const { getReonIntersections } = useGetReonIntersections({ showAlert });

  /**
   * Функция checkGeometryReonByButton.
   *
   */
  const checkGeometryReonByButton = () => {
    if (hasReonIntersections) {
      clearLayer(REON);
      setHasReonIntersections(false);
    } else {
      const data = {
        geometry: getGeometry(recordId),
      };
      getReonIntersections(data);
    }
  };

  return (
    <ToggleButton
      disabled={!isMapLoaded}
      active={hasReonIntersections}
      content={toggleContent.reon}
      onClick={checkGeometryReonByButton}
    />
  );
};
