import { useFetchOghTable } from 'app/pages/registry/ogh/api';
import { useParamsTable } from 'core/uiKit/components/tables/Table';

import { useFilterQueryParamsBuilding } from './Filter.Building';

/**
 * Setup таблицы __Объекты капитального строительства__.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - Id текущего ОГХ.
 * @param {number} props.parentTypeId - Id родительского ОГХ.
 * @returns {object} - Объект с данными.
 */
export const useSetupTableBuilding = ({ typeId, parentTypeId }) => {
  const { prepareFilters } = useFilterQueryParamsBuilding();
  const { queryTable } = useParamsTable();

  const queryKey = [
    'TableBuilding',
    {
      ...queryTable,
      ...prepareFilters,
      parentTypeId,
      typeId,
    },
  ];

  const { table = [], isLoading, count = 0 } = useFetchOghTable(queryKey);

  return { count, isLoading, table };
};
