import { MapEventTable } from '@2gis/mapgl/types';
import { Map } from '@2gis/mapgl/types';
import { Evented } from '@2gis/mapgl/types/utils/evented';

/**
 * Class representing an AreaOrPerimeterHint.
 */
class AreaOrPerimeterHint {
  private _element: HTMLDivElement;
  private _isVisible = true;
  private _isDestroyed = false;

  // eslint-disable-next-line
  private _position: { x: number; y: number };
  private _map: Map;
  private _handlers?: Partial<
    Record<keyof MapEventTable, Parameters<Evented<MapEventTable>['on']>[1]>
  >;

  /**
   * Returns the position of the container element.
   *
   * @returns Coordinates of the container element.
   */
  public get position() {
    return this._position;
  }

  /**
   * Returns the container element.
   *
   * @returns HTMLDivElement.
   */
  public get element() {
    return this._element;
  }

  /**
   * Returns if the DOM element is destroyed.
   *
   * @returns Boolean.
   */
  public get isDestroyed() {
    return this._isDestroyed;
  }

  /**
   * Returns the visibility state of the container element.
   *
   * @returns Boolean.
   */
  public get isVisible() {
    return this._isVisible;
  }

  /**
   * Returns the map instance.
   *
   * @returns Map.
   */
  public get map() {
    return this._map;
  }

  /**
   * Returns the event handlers.
   *
   * @returns Object.
   */
  public get handlers() {
    return this._handlers;
  }

  /**
   * Constructor for creating a new div element in the document at the specified position.
   *
   * @param {string|number} text - The text content of the div element.
   * @param {number} x - The horizontal position of the div element.
   * @param {number} y - The vertical position of the div element.
   * @param {Map} map - The map instance.
   * @param {string|number} geometryId - The ID of the geometry.
   * @param {object} handlers - The event handlers.
   */
  constructor(
    text: string | number,
    // eslint-disable-next-line
    x: number,
    // eslint-disable-next-line
    y: number,
    map: Map,
    geometryId: string | number,
    handlers?: AreaOrPerimeterHint['handlers'],
  ) {
    const element = document.createElement('div'); // Create a new div element in the document.

    if (geometryId) element.dataset.geometryId = `${geometryId}`;

    element.classList.add('area-hint');
    element.style.background = 'white';
    element.style.lineHeight = '1';
    element.style.padding = '0 2px';
    element.style.fontSize = '14px';
    element.style.position = 'absolute';
    element.style.top = '0';
    element.style.left = '0';
    // eslint-disable-next-line
    element.style.transform = `translate(${x}px, ${y}px)`;
    element.style.pointerEvents = 'none';
    element.style.zIndex = '100001';
    element.style.border = '2px solid lightgrey';
    element.innerText = `${text}`;

    this._element = element;
    // eslint-disable-next-line
    this._position = { x, y };
    this._isVisible = true;
    this._handlers = handlers;
    this._map = map;
  }

  /**
   * Sets the text content of the container element.
   *
   * @param {string|number} text - The text to set in the container element.
   */
  setText(text: string | number) {
    this.element.innerText = `${text}`;
  }

  /**
   * Sets the position of the container element.
   *
   * @param {number} x - The horizontal position to set.
   * @param {number} y - The vertical position to set.
   * @param shouldUpdateIfInvisible - Whether to update the position if the container element is not visible.
   */
  // eslint-disable-next-line
  setPosition(x: number, y: number, shouldUpdateIfInvisible = false) {
    // eslint-disable-next-line
    this._position = { x, y };

    if (this.isVisible || shouldUpdateIfInvisible) {
      // eslint-disable-next-line
      this.element.style.transform = `translate(${x}px, ${y}px)`;
    }
  }

  /**
   * Appends the container element to the specified element.
   *
   * @param element - The element to append the container element to.
   */
  append(element: HTMLElement) {
    element.append(this.element);
  }

  /**
   * Hides the container element.
   *
   */
  hide() {
    this.element.style.display = 'none';
    this._isVisible = false;
  }

  /**
   * Shows the container element.
   *
   */
  show() {
    // eslint-disable-next-line
    this.element.style.transform = `translate(${this.position.x}px, ${this.position.y}px)`;
    this.element.style.display = 'block';
    this._isVisible = true;
  }

  /**
   * Destroys hint DOM element.
   *
   */
  destroy() {
    this.element.remove();
    this._isDestroyed = true;

    if (this.handlers) {
      Object.entries(this.handlers).forEach(([event, handler]) => {
        this.map.off(event as keyof MapEventTable, handler);
      });
    }
  }

  /**
   * Restores the hint to its initial state.
   *
   * @param {HTMLElement} parent - The parent element.
   */
  restore(parent?: HTMLElement) {
    // eslint-disable-next-line
    this.element.style.transform = `translate(${this.position.x}px, ${this.position.y}px)`;
    this.element.style.display = 'block';
    this._isVisible = true;
    this._isDestroyed = false;

    if (parent) {
      if (this.element.parentElement) this.element.remove();
      parent.append(this.element);
    }

    if (this.handlers && this.element.parentElement) {
      Object.entries(this.handlers).forEach(([event, handler]) => {
        this.map.on(event as keyof MapEventTable, handler);
      });
    }
  }
}

export default AreaOrPerimeterHint;
