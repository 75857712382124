/* eslint-disable */
import React from 'react';
import { StringParam } from 'use-query-params';

import { CreateTypeFF, CREATE_TYPE_NAME } from 'core/form/finalForm/fields';

import { filterInput } from 'core/form/filterForm/components/filterInputsClasses';

 
export const CreateTypeFilter = () => <CreateTypeFF className={filterInput} />;

 
export { CREATE_TYPE_NAME };

 
export const createTypeParams = {
  [CREATE_TYPE_NAME]: StringParam,
};
