import { createIntersectionsMessage } from 'app/utils/card/checkGeometry/helpers/createIntersectionsMessage';
import { getLengthGeometry } from 'app/utils/card/checkGeometry/helpers/getLengthGeometry';
import messages from 'app/utils/card/checkGeometry/messages';

/**
 * Функция fetchCheckGeometryIntersectionsSimple.
 *
 * @param {*} data - Данные.
 * @param {object} props - Пропсы.
 * @param {Function} draw - Отрисовка.
 * @param {Function} showIgnoreIntersectionAlert - Игнорировать алерт.
 * @param {Function} saveObject - Сохранить объект.
 * @returns {void}
 */
export async function fetchCheckGeometryIntersectionsSimple(
  data,
  props,
  draw,
  showIgnoreIntersectionAlert,
  saveObject,
) {
  const object = await props.fetchCheckGeometryIntersections(data);

  const length = getLengthGeometry(object);
  if (length) {
    if (!object.isallow) {
      props.showAlert(
        createIntersectionsMessage(
          object.geometry,
          messages.errorIntersections,
        ),
      );
      draw(object.geometry);
    } else {
      showIgnoreIntersectionAlert(
        saveObject,
        createIntersectionsMessage(
          object.geometry,
          'Пересечение геометрии. Продолжить сохранение?',
        ),
      );
    }
  } else if (object.status && object.status !== 200) {
    props.showAlert(object.message);
  } else {
    saveObject();
  }
}
