import { getRequiredDocumentCloseField } from 'app/components/card/ogh/index/card/components/CloseElectronicSignature/fields/DocumentCloseFileField/getRequiredDocumentCloseField';
import { DndFileUploadFF } from 'core/form/finalForm/fields/prepared/fileUpload/DndFileUploadFF';
import React from 'react';
import { useField } from 'react-final-form';

export const DOCUMENT_CLOSE_FILE_NAME = 'documentCloseFile';

/**
 * Компонент получения документов основания закрытия.
 *
 * @returns {React.ReactElement}
 */
export const DocumentCloseFileField = () => {
  const { input: { value: sendReason = [] } = {} } =
    useField('sendReason') || {};

  return (
    <DndFileUploadFF
      required={getRequiredDocumentCloseField(sendReason)}
      name={DOCUMENT_CLOSE_FILE_NAME}
      disabled={!getRequiredDocumentCloseField(sendReason)}
      text={
        'Требуется документ-основание закрытия паспорта ОГХ. Документ-основание должен быть заверен уполномоченными сотрудниками соответствующих организаций. Допустимые форматы - .JPEG, .PNG, .PDF, .JPG'
      }
    />
  );
};
