/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */

/**
 * Колонка __Назначение__.
 *
 * @type {{ accessor: 'abutment_friends_ogh_object_name',  Header: 'Назначение', rowSpan: 1, sortable: true }}
 * @augments Cell
 */
export const abutment_friends_ogh_object_name = {
  Header: 'Назначение',
  accessor: 'abutment_friends_ogh_object_name',
  rowSpan: 1,
  sortable: true,
};
