import { preparation } from 'core/utils/hooks/usePreparation/preparation';
interface PreparationObject {
  [key: string]: unknown;
}

/**
 * Функция преобразования значений формы в заранее подготовленные изменения.
 *
 * @param values - Значения формы.
 * @returns Переделаные значения.
 */
export function preparationValues<
  Values extends object,
  RETURN_VALUE = PreparationObject,
>(values: Values): RETURN_VALUE {
  const formValues: PreparationObject | Values = {};

  for (const [key, value] of Object.entries(values)) {
    if (value !== undefined && value !== null) {
      const { value: newValue, key: newKey } = preparation(key, value);
      formValues[newKey] = newValue;
    }
  }

  return formValues as RETURN_VALUE;
}
