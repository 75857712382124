import { shallow } from 'enzyme';
import { vi } from 'vitest';

import ImprovementTerritoryCard from './index';

vi.mock('./YardCard', () => ({
  //

  /**
   * YardCard.
   *
   * @returns {JSX.Element}
   */
  YardCard: () => <div>YardCard</div>,
}));
vi.mock('./OznCard', () => ({
  //

  /**
   * OznCard.
   *
   * @returns {JSX.Element}
   */
  OznCard: () => <div>OznCard</div>,
}));
vi.mock('./ImprovementObjectCard', () => ({
  //

  /**
   * ImprovementObjectCard.
   *
   * @returns {JSX.Element}
   */
  ImprovementObjectCard: () => <div>ImprovementObjectCard</div>,
}));

describe('file ImprovementTerritoryCard', () => {
  it.each`
    type_id | expected_props     | expected
    ${38}   | ${{ type_id: 38 }} | ${'<YardCard />'}
    ${39}   | ${{ type_id: 39 }} | ${'<ImprovementObjectCard />'}
    ${40}   | ${{ type_id: 40 }} | ${'<OznCard />'}
    ${389}  | ${undefined}       | ${''}
  `('simple text $expected', ({ type_id, expected, expected_props }) => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const wrapper = shallow(<ImprovementTerritoryCard card={{ type_id }} />);

    // Assert
    expect(wrapper.text()).toBe(expected);
    expect(wrapper.prop('card')).toStrictEqual(expected_props);
  });
});
