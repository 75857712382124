/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import DialogAlert from './DialogAlert';

const UNKNOWN = 'не определен';

const lockIcon = (
  <svg
    enableBackground="new 0 0 792 792"
    fill="#ff3131"
    height="18"
    version="1.1"
    viewBox="0 0 792 792"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
  >
    <path d="m577.33 287.93v-99.36s0-188.57-181.33-188.57c-180.76 0-180.76 187.78-180.76 187.78v100.15h36v-100.15s0-151.78 144.76-151.78 145.33 152.57 145.33 152.57v99.36h36z" />
    <path d="m108 325.48v429.05c0 20.7 16.02 37.476 36.036 37.476h503.93c19.908 0 36.036-16.776 36.036-37.476v-429.05c0-20.7-16.02-37.476-36.036-37.476h-503.93c-19.908 0-36.036 16.776-36.036 37.476zm540-1.476v433.73h-504v-433.73h504z" />
    <path d="m360 574.49v66.672s0.108 37.728 36.072 37.728 35.928-37.728 35.928-37.728v-66.672c21.528-13.032 36-37.404 36-65.34 0-41.652-32.22-75.421-72-75.421s-72 33.769-72 75.421c0 27.9 14.472 52.308 36 65.34z" />
  </svg>
);

const propTypes = {
  dialogLock: {
    onAfterHiding: PropTypes.func,
    info: PropTypes.object,
    showCondition: PropTypes.bool,
  },
  userInfo: {
    email: PropTypes.string,
    error: PropTypes.string,
    fio: PropTypes.string,
  },
};

const defaultProps = {
  showCondition: false,
};

function DialogLock({ onAfterHiding, info, showCondition }) {
  const isError = get(info, 'error');
  return (
    <DialogAlert
      showCondition={showCondition}
      title={<div>{!isError && lockIcon} Выполнение операции невозможно</div>}
      onAfterHiding={onAfterHiding}
    >
      {isError ? <div>{info.error}</div> : <UserInfo info={info} />}
    </DialogAlert>
  );
}

DialogLock.propTypes = propTypes.dialogLock;
DialogLock.defaultProps = defaultProps;

function UserInfo({ info }) {
  return (
    <React.Fragment>
      <b>Объект редактируется другим пользователем.</b>
      <div>
        Пользователь: {info.fio || UNKNOWN} | Email:{' '}
        {info.email ? (
          <a href={`mailto:${info.email}`}>{info.email}</a>
        ) : (
          UNKNOWN
        )}
      </div>
    </React.Fragment>
  );
}

UserInfo.propTypes = propTypes.userInfo;

 
export default DialogLock;
