/* eslint-disable */
import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import { TextField } from 'core/uiKit/inputs';

const TextFieldWithReduxForm = ({ input, ...props }) => {
  return <TextField {...props} {...input} />;
};

 
export const TextFieldRF = ({ name, ...otherProps }) => {
  return (
    <Field {...otherProps} name={name} component={TextFieldWithReduxForm} />
  );
};

TextFieldRF.propTypes = {
   
   
/**
   Выбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   name input
   */
  name: PropTypes.string,
   
  /* eslint-disable */
/**
   label input
   */
  label: PropTypes.string,
  disabled: PropTypes.bool,
};
TextFieldRF.defaultProps = {
  name: 'TextFieldRF',
  dark: false,
  disabled: false,
  label: 'TextFieldFF label',
};
