import formatRootId from 'app/utils/formatRootId';


/* eslint-disable */
export default [
  {
    id: 'root_id',
    name: 'ID',
    Cell: (row) => formatRootId(row.value),
    width: 80,
  },
  {
    id: 'name',
    name: 'Тип',
  },
  {
    id: 'capacity',
    name: 'Объём',
  },
  {
    id: 'area',
    name: 'Площадь',
  },
  {
    id: 'object_status_name',
    name: 'Статус',
    width: 110,
  },
];
