import { useSetupMafTypeLevel2FilterField } from 'app/pages/registry/ogh/MafRfid/filters/MafTypeLevel2Filter.Field/useSetupMafTypeLevel2FilterField';
import { MafTypeLevel2Filter } from 'core/form/filterForm/components/selects/MafTypeLevel2.Filter';
import React from 'react';

/**
 * Фильтр уточнение для реестра МАФ.
 *
 * @returns {JSX.Element}
 */
export const MafTypeLevel2FilterField = () => {
  const { filter, disabled } = useSetupMafTypeLevel2FilterField();

  return <MafTypeLevel2Filter filter={filter} disabled={disabled} />;
};
