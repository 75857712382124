import { fetchIntersectionsGeometry } from 'app/api/requests/geometry';

import * as types from '../../constants/actionTypes';

/**
 * Fetch intersections geometry.
 *
 * @param {object} data - Data.
 * @returns {object}
 */
export const fetchCheckGeometryIntersectionsEgip =
  (data) => async (dispatch) => {
    dispatch(requestCheckGeometryIntersections(data));
    const response = await fetchIntersectionsGeometry(data);
    dispatch(receiveCheckGeometryIntersections(response));
    return response;
  };

/**
 * Reset.
 *
 * @returns {object}
 */
export function resetIntersectionsGeometry() {
  return {
    type: types.RESET_INTERSECTIONS_GEOMETRY,
  };
}

/**
 * Reques.
 *
 * @param {number} id - A.
 * @returns {object}
 */
function requestCheckGeometryIntersections(id) {
  return {
    id,
    type: types.REQUEST_CHECK_INTERSECTIONS,
  };
}

/**
 * A.
 *
 * @param {object} object - Data.
 * @returns {object}
 */
function receiveCheckGeometryIntersections(object) {
  return {
    object,
    type: types.RECEIVE_CHECK_INTERSECTIONS,
  };
}
