/* eslint-disable */
import React from 'react';
import { useFilterQueryParamsMafRfid } from '../Filter.MafRfid';
import { PrintButtonOgh } from 'app/pages/components/index';

/* eslint-disable */
export const PrintButtonMafRfid = ({ typeId }) => {
  const { prepareFilters } = useFilterQueryParamsMafRfid();

  return <PrintButtonOgh {...{ typeId, prepareFilters }} />;
};
