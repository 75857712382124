/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';

import Checkbox from 'core/newComponents/Checkbox';

const getWrapStyle = (style) => ({
  marginTop: '16px',
  marginBottom: '16px',
  ...style,
});

const propTypes = {
  action: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  elementClassName: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.any,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]),
  withChange: PropTypes.func,
  onChange: PropTypes.func,
};

class CheckboxField extends React.Component {
  handleChange = (event, value) => {
    const { action, withChange, onChange } = this.props;
    if (isFunction(action)) {
      action(value);
    }

    if (withChange) {
      withChange(value);
    }
    // в карточках вместо значений true/false используется 1 / 0
    return onChange(value ? 1 : 0);
  };

  render() {
    const {
      className,
      elementClassName,
      disabled,
      label,
      id,
      name,
      style,
      title,
      value,
    } = this.props;

    return (
      <div className={className} style={getWrapStyle(style)} title={title}>
        <Checkbox
          checked={Boolean(value)}
          className={elementClassName}
          disabled={disabled}
          id={id}
          label={label}
          labelClassName="Checkbox_Card"
          labelPlacement="start"
          name={name}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

CheckboxField.propTypes = propTypes;

 
export default CheckboxField;
