/* eslint-disable */
import React from 'react';
import { NumberParam, withDefault } from 'use-query-params';

import {
  DocumentStatusFF,
  DOCUMENT_STATUS_NAME,
} from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
 
/**
 *
 */
 
export const DocumentStatusFilter = (props) => (
  <DocumentStatusFF {...props} className={filterInput} withAll={false} />
);

 
export { DOCUMENT_STATUS_NAME };

 
export const documentStatusParams = {
  [DOCUMENT_STATUS_NAME]: withDefault(NumberParam, 1),
};
