/* eslint-disable  */
export const convertValue = {
  /**
   *
   * @param value
   */
  zero(value) {
    return value ? 1 : 0;
  },
};
