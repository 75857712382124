import {
  cancelDeleteCard,
  cancelEditCard,
  continueEditCard,
  createCard,
  editCard,
  leaveCurrentElement,
  viewCard,
} from 'app/actions/odsObjectActions';
import { setTechTreePaths } from 'app/actions/technologyObject';
import { parseDate } from 'app/components/common/misc/ConverterFunctions';
import DialogCreate from 'app/components/dialogs/common/DialogCreate';
import DialogDelete from 'app/components/dialogs/common/DialogDelete';
import DialogLock from 'app/components/dialogs/common/DialogLock';
import DialogMatching from 'app/components/dialogs/common/DialogMatching';
import DialogUpdate from 'app/components/dialogs/common/DialogUpdate';
import { dialog } from 'app/constants/elementNames';
import { entityCode, operation } from 'app/constants/matching';
import isMatchingTechnic from 'app/selectors/card/tech/isMatchingTechnic';
import { getServerTime } from 'app/utils/dateUtils';
import LeftPanel, { LeftPanelContent } from 'core/components/LeftPanel';
import withShowControls from 'core/hocs/withShowControls';
import { log } from 'core/utils/log';
import { useNavigateRouter } from 'core/utils/router';
import { flow, get } from 'lodash';
import PropTypes from 'prop-types';
import * as queryString from 'query-string';
/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { SubmissionError } from 'redux-form';

import BtnGroup from './components/BtnGroup';

class Panel extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    updateObject: PropTypes.func.isRequired,
    createObject: PropTypes.func.isRequired,
    deleteTechnology: PropTypes.func,
    card: PropTypes.object,
    mode: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.today = getServerTime().set({
      seconds: 0,
      milliseconds: 0,
      minute: 0,
      hour: 0,
    });

    this.tomorrow = getServerTime()
      .set({
        seconds: 0,
        milliseconds: 0,
        minute: 0,
        hour: 0,
      })
      .add({
        days: 1,
      });
  }

  componentWillUnmount() {
    this.props.dispatch(viewCard());
  }

  onClickMatchingConfirmButton = () => {
    this.setState({
      showApproveDialogue: true,
    });
  };

  onClickMatchingRejectButton = () => {
    this.setState({
      showRejectDialogue: true,
    });
  };

  onClickCancelButton = () => {
    const { card, mode, history } = this.props;
    const isCreateMode = (mode && mode.createMode) || false;

    this.props.dispatch(
      leaveCurrentElement(() => {
        if (isCreateMode) {
          history.goBack();
        } else {
          const treePaths = card.tree_paths;
          this.props.dispatch(setTechTreePaths(treePaths));
        }
      }),
    );
  };

  render() {
    const { props } = this;
    const {
      card,
      isMatching,
      mode,
      updateObject,
      createObject,
      deleteTechnology,
      matching,
      matchingRegistryColumns,
      alert,
      isShow,
      onShow,
      onHide,
    } = this.props;

    const cardType = card?.card_type?.trim().toLowerCase();
    const typeId = card.type_id;
    const recordId = card.id;
    const rootId = card.root_id;
    const requestTypeId = card.technic_request_type_id;

    const startDate = card && parseDate(card.start_date);

    log.info('cardType ', cardType);
    let Card;
    try {
      Card = require(`../../${cardType}`).default;
    } catch (e) {
      Card = () => null;
    }

    return (
      <LeftPanel style={this.props.style}>
        <BtnGroup
          cardType={cardType}
          currentCard={card}
          dispatch={props.dispatch}
          isMatching={isMatching}
          mode={mode}
          startDate={startDate}
          today={this.today}
          tomorrow={this.tomorrow}
          viewMatchingTechMode={props.viewMatchingTechMode}
          onClickCancelButton={this.onClickCancelButton}
          onClickMatchingConfirmButton={onShow(dialog.APPROVED_MATCHING)}
          onClickMatchingRejectButton={onShow(dialog.REJECTED_MATCHING)}
        />
        <div className="modal-windows">
          <DialogCreate
            mode={mode}
            onError={() => {
              this.props.dispatch(createCard(null, null));
            }}
          />

          <DialogUpdate
            card={card}
            isMatching={isMatching}
            mode={mode}
            onCancel={() => {
              this.props.dispatch(cancelEditCard(rootId, recordId));
            }}
            onCloseWarning={() => {
              this.props.dispatch(continueEditCard());
            }}
            onOk={(formValues) => {
              this.props.dispatch(
                editCard(formValues.startDate, formValues.endDate),
              );
            }}
          />

          <DialogLock
            info={get(mode, 'lockInfo')}
            showCondition={get(mode, 'editModeDenyOnLock', false)}
            onAfterHiding={() => {
              this.props.dispatch(viewCard());
            }}
          />

          <DialogDelete
            isMatching={isMatching}
            mode={mode}
            onAlertHide={() => {
              this.props.dispatch(viewCard());
            }}
            onCancel={() => {
              this.props.dispatch(cancelDeleteCard(recordId));
            }}
            onSubmit={(formValues) => {
              if (formValues.reason === null || formValues.reason === '') {
                throw new SubmissionError({
                  reason: 'укажите причину удаления',
                });
              }
              const formValuesTransformed = {};
              Object.keys(formValues).forEach((key) => {
                if (key === 'startDate') {
                  formValuesTransformed[key] = new Intl.DateTimeFormat(
                    'ru',
                  ).format(formValues[key]);
                } else {
                  formValuesTransformed[key] = formValues[key];
                }
              });
              deleteTechnology(formValuesTransformed);
            }}
            onSubmitDraft={(formValues) => {
              deleteTechnology(formValues);
            }}
          />

          <DialogMatching
            entityCode={entityCode.TECH}
            id={recordId}
            operation={operation.APPROVED}
            requestTypeId={requestTypeId}
            showCondition={isShow(dialog.APPROVED_MATCHING)}
            title={'Утверждение'}
            typeId={typeId}
            onCancel={onHide(dialog.APPROVED_MATCHING)}
            onOk={onHide(dialog.APPROVED_MATCHING)}
          />

          <DialogMatching
            alertResult={{
              isDone: mode && mode.sentRejectMatching,
              message: mode && mode.message,
            }}
            entityCode={entityCode.TECH}
            id={recordId}
            operation={operation.REJECTED}
            requestTypeId={requestTypeId}
            showCondition={isShow(dialog.REJECTED_MATCHING)}
            title={'Отклонение'}
            typeId={typeId}
            onCancel={onHide(dialog.REJECTED_MATCHING)}
            onOk={onHide(dialog.REJECTED_MATCHING)}
          />
        </div>

        <LeftPanelContent>
          <Card
            alert={alert}
            card={card}
            createFormValue={this.props.createFormValue}
            createObject={createObject}
            deleteTechnology={deleteTechnology}
            matching={matching}
            matchingRegistryColumns={matchingRegistryColumns}
            mode={mode}
            updateObject={updateObject}
          />
        </LeftPanelContent>
      </LeftPanel>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    isMatching: isMatchingTechnic(state, props),
  };
}

const Card = flow(
  withShowControls(dialog.APPROVED_MATCHING, dialog.REJECTED_MATCHING),
  connect(mapStateToProps),
)(Panel);

// todo: костыль на время. создан когда была переделка на новый роут.
// нужно переделать на переменые из роута


 
export default (props) => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigateRouter();
  const createFormValue = queryString.parse(useLocation().search, {
    arrayFormat: 'bracket',
  });

   
  /* eslint-disable */
/**
   * @deprecated
   * @see используй navigate
   */
  const history = { push: navigate };

  return (
    <Card
      {...{ params, location, history, navigate, createFormValue }}
      {...props}
    />
  );
};
