import { getRegistryRoleTable } from 'app/api/requests/getRegistryRoleTable';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';

/**
 * Получение ролей.
 *
 * @category hooks
 * @param {*} queryTable - Параметры запроса.
 * @returns {Promise<*>}
 */
export const useGetRoles = (queryTable) =>
  useQueryAdaptor(['role', queryTable], getRegistryRoleTable);
