import { useFilterShrubs } from 'app/pages/registry/ogh/yard/TreesShrubs/useFilterShrubs';
import { LifeFormTypeFilter } from 'core/form/filterForm/components/selects/LifeFormTypeFilter';
import { PlantationTypeFilter } from 'core/form/filterForm/components/selects/PlantationTypeFilter';
import { PlantTypeFilter } from 'core/form/filterForm/components/selects/PlantTypeFilter';
import { IdFilter } from 'core/form/filterForm/components/text/ID.Filter';
import React from 'react';

/**
 * Фильтр __Деревья кустарники__.
 *
 * @returns {JSX.Element}
 */
export const TreesShrubsFilter = () => {
  const { filterLifeForm, filterPlantForm } = useFilterShrubs();

  return (
    <>
      <IdFilter />
      <PlantationTypeFilter />
      <LifeFormTypeFilter filter={filterLifeForm} />
      <PlantTypeFilter filter={filterPlantForm} />
    </>
  );
};
