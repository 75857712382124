import { Button } from 'core/uiKit/components/buttons/Button';
import { EmailIcon } from 'core/uiKit/material-ui';
import React from 'react';

/**
 * Кнопка Submit  "Отправить".
 *
 * @param {object} props - Параметры.
 * @param {boolean} props.isLoading - Загрузка.
 * @returns JSX.Element.
 */
export const SendButton = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <Button
      color={'info'}
      isLoading={isLoading}
      variant={'contained'}
      type={'submit'}
      startIcon={<EmailIcon />}
    >
      Отправить
    </Button>
  );
};
