import { withFinalForm } from 'core/form/finalForm/helpers/withFinalForm';
import {
  MAF_TYPE_LEVEL_3_ID_NAME,
  MafTypeLevel3,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/MafTypeLevel3';
import { Field } from 'react-final-form';

/**
 * Select FF __Тип уровня 3 МАФ__.
 *
 * @param {*} props - Пропсы.
 * @returns {JSX.Element}
 */
export const MafTypeLevel3FF = (props) => {
  return (
    <Field
      {...props}
      name={MAF_TYPE_LEVEL_3_ID_NAME}
      component={withFinalForm(MafTypeLevel3)}
    />
  );
};
