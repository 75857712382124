import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const ABUTMENT_TYPE_ID_NAME = 'abutment_type_id';

/**
 * Тип элемента сопряжения.
 *
 * @param {object} props - Пропсы .
 * @returns {JSX.Element}
 */
export const AbutmentTypeName = (props) => {
  const dict = 'abutment_type';

  const { data = [] } = useGetDictionary(dict);

  return (
    <Select
      {...props}
      options={data}
      label={'Тип элемента сопряжения'}
      name={ABUTMENT_TYPE_ID_NAME}
    />
  );
};
