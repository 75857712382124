/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './style.scss';

/* eslint-disable */
export default class FieldLabel extends React.Component {
  static defaultProps = {
    required: false,
  };

  static propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    required: PropTypes.bool,
    children: PropTypes.node,
  };

  render() {
    const { props } = this;
    const className = classnames(
      'ods-field-label',
      {
        'ods-field-label--required': props.required,
      },
      props.className,
    );
    return (
      <label className={className} style={props.style}>
        {props.children}
      </label>
    );
  }
}
