import { Button } from 'core/uiKit/components/buttons/Button';
/* eslint-disable  */

/**
 *
 * @param root0
 * @param root0.isLoading
 * @param root0.form
 * @param root0.closeDialog
 * @param root0.textButtonSave
 */
export const ButtonsSaveDialog = ({
  isLoading,
  form,
  closeDialog,
  textButtonSave,
}) => {
  return (
    <>
      <Button
        disabled={isLoading}
        variant={'outlined'}
        onClick={() => {
          form.reset();
          closeDialog();
        }}
      >
        Отменить
      </Button>
      <Button
        color={'success'}
        loading={isLoading}
        variant={'contained'}
        onClick={form.submit}
      >
        {textButtonSave}
      </Button>
    </>
  );
};
