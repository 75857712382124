/* eslint-disable */
import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { BuildingsTypeSpecCard } from 'app/components/card/ogh/other_tech_place/BuildingsTypeSpecCard';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { BuildingsTypeSpecRF } from 'core/form/reduxForm/fields/prepared/selects/simple/BuildingsTypeSpec.RF/BuildingsTypeSpec.RF';
import { RANDOM_BOOL, RANDOM_ID } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import React from 'react';

jest.mock('app/api/hooks/useGetParentCard');
jest.mock('app/pages/cardsOgh/hooks/useEditMode');
jest.mock('app/api/hooks/useGetCurrentCard');

const TYPE_40 = {
  id: 13,
  code: 'architecture',
  name: 'Архитектурный памятник',
  ogh_object_type_id: 41,
  parent_ogh_object_type_list: [40],
};

const TYPE_39 = {
  id: 10,
  code: 'gazebo',
  name: 'Беседка',
  ogh_object_type_id: 41,
  parent_ogh_object_type_list: [39],
};

const TYPE_38 = {
  id: 17,
  code: '17',
  name: 'Вольер',
  ogh_object_type_id: 41,
  parent_ogh_object_type_list: [38],
};

const dict = [TYPE_40, TYPE_39, TYPE_38];

describe('title BuildingsTypeSpecFilter.spec', () => {
  it('render component', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetParentCard.mockReturnValue({ typeId: RANDOM_ID });
    useGetCurrentCard.mockReturnValue({
      typeId: RANDOM_ID,
    });
    useEditMode.mockReturnValue({ editMode: RANDOM_BOOL });
    // Act
    const wrapper = shallow(<BuildingsTypeSpecCard />);

    const filter = wrapper.props().filter;

    // Assert
    expect(
      wrapper.contains(
        <BuildingsTypeSpecRF
          filter={filter}
          withAll={false}
          required={true}
          label={'Уточнение назначения'}
          disabled={!RANDOM_BOOL}
        />,
      ),
    ).toBeTruthy();
  });
  it.each`
    typeId       | expected
    ${38}        | ${[TYPE_38]}
    ${39}        | ${[TYPE_39]}
    ${40}        | ${[TYPE_40]}
    ${undefined} | ${dict}
  `('test filter typeId $typeId', ({ typeId, expected }) => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetParentCard.mockReturnValue({ typeId });
    useEditMode.mockReturnValue({
      editMode: RANDOM_BOOL,
    });
    useGetCurrentCard.mockReturnValue({ typeId: 41 });
    // Act
    const wrapper = shallow(<BuildingsTypeSpecCard />);
    const filter = wrapper.props().filter;

    // Assert
    expect(filter(dict)).toStrictEqual(expected);
  });
});
