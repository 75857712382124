import ApproveObject from 'app/components/dialogs/common/ApproveObject';
import RejectObject from 'app/components/dialogs/common/RejectObject';
import { CreateOgh } from 'app/components/LeftPanel/handleCreateButton';
import { CreateApplicationExaminationButton } from 'app/pages/cardsOgh/components/buttonsCard/components/CreateApplicationExamination.Button';
import { EditOghButton } from 'core/uiKit/components/buttons/actionsButtons/EditOgh.Button';
import { ExportingBusinessPlanButton } from 'core/uiKit/components/buttons/actionsButtons/ExportingBusinessPlan.Button';

import { AcceptExternalObject } from '../AcceptExternalObject';
import { ApproveElectronicSignature } from '../ApproveElectronicSignature';
import ApproveBoundaries from '../Bound/ApproveBoundaries';
import FixProblemsBoundaries from '../Bound/FixProblemsBoundaries';
import SendMatchingBoundaries from '../Bound/SendMatchingBoundaries';
import { CancelButtonHOC } from '../CancelButtonHOC';
import { CloseElectronicSignature } from '../CloseElectronicSignature';
import { CloseOghButton } from '../CloseOghButton';
import { DataExportButton } from '../DataExport.Button';
import { DeleteOghButton } from '../DeleteOghButton';
import { RejectExternalObject } from '../RejectExternalObject';
import { ReturnBack } from '../ReturnBack';
import { SendMatchingOghButton } from '../SendMatchingOgh.Button';
import { SigningOnRenewalOfApprovalDate } from '../SigningOnRenewalOfApprovalDate';

/**
 * Компонент Кнопок объекта ОГХ.
 *
 * @param {object} props - Пропсы компонента.
 * @param {Function} props.handleEditButtonClick - Функция изменения режима редактирования.
 * @returns {React.Element}
 */
export const BtnGroupOGH = ({ handleEditButtonClick }) => {
  return (
    <div className="left-panel__button-group">
      <div className="left-panel__button-group__container">
        <EditOghButton onClick={handleEditButtonClick} />
        <ExportingBusinessPlanButton />
        <DeleteOghButton />
        <CreateOgh />
        <CancelButtonHOC />

        <CloseOghButton />
        <SendMatchingOghButton />
        <SendMatchingBoundaries />
        <RejectObject />
        <ApproveBoundaries />
        <FixProblemsBoundaries />
        <CloseElectronicSignature />
        <SigningOnRenewalOfApprovalDate />

        <RejectExternalObject />
        <AcceptExternalObject />

        <ApproveObject />
        <ApproveElectronicSignature />
        <DataExportButton />
        <CreateApplicationExaminationButton />
      </div>

      <ReturnBack />
    </div>
  );
};
