import { WarningBadge } from 'app/components/card/tech/car/components/Badges/WarningBadgeProcessApproving/WarningBadge';

import carCardTemplate from './car/carCardTemplate';
/* eslint-disable */
const mainTabs = [
  {
    id: 'props-tab',
    label: 'Свойства',
    active: true,
  },
  {
    id: 'equipment-tab',
    label: 'Оборудование',
  },
  {
    id: 'versions-tab',
    label: 'Версии',
    isVisible: (props) => !props.createMode,
  },
];

const tabsCar = [
  {
    id: 'approval-process-tab',
    name: 'Процесс согласования изменений',
    Component: WarningBadge,
  },
];

 
export default carCardTemplate({
  mainTabs,
  tabsCar,
});
