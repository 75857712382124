import { STATE_GARDENING_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/StateGardeningId';

const arr = [
  2, // Удовлетворительное
  3, // Неудовлетворительное
];

/**
 * Валидация поля _Рекомендации по уходу_ .
 *
 * @param values - Falues form.
 * @param values.STATE_GARDENING_ID_NAME - Состояние.
 * @returns {boolean}
 */
export const getPlantServiceRecomendationsIdRequired = (
  values:
    | {
        [STATE_GARDENING_ID_NAME]?: number | string;
      }
    | undefined,
): boolean => {
  return arr.includes(+(values?.[STATE_GARDENING_ID_NAME] || 0));
};
