/* eslint-disable  */

import { isOzn } from 'app/utils/checkOghTypeId';
import { createSelector } from 'reselect';

const CATEGORY_CODE = 'third_category';
const getImprovementOptions = (state, props) =>
  props.card.improvement_object_category;

/**
 * A.
 *
 * @param {*} params - Params.
 * @returns {*}
 */
export default function (params) {
  const { defaultOption, card } = params;
  return createSelector([getImprovementOptions], (options) => {
    if (defaultOption && !options.find((i) => i.id < 0)) {
      options.push(defaultOption);
    }
    if (isOzn(card.type_id)) {
      options = options.filter((i) => i.code.indexOf(CATEGORY_CODE) === -1);
    }
    return options;
  });
}
