/* eslint-disable */
import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import { KeyboardDateTimePicker } from 'core/uiKit/inputs';

const KeyboardDateTimePickerWithReduxForm = ({ input, ...props }) => {
  return <KeyboardDateTimePicker {...input} {...props} />;
};

 
 
/**
 * ## Выбор даты и времени c клавиатуры
 *
 * ### Redux Form Field
 *
 * * `type = KeyboardDateTimePicker`
 *
 */
/* eslint-disable */
export const KeyboardDateTimePickerRF = ({
  name,
  formatData,
  ...otherProps
}) => {
  return (
    <Field
      {...otherProps}
      name={name}
      component={KeyboardDateTimePickerWithReduxForm}
    />
  );
};

KeyboardDateTimePickerRF.propTypes = {
   
   
/**
   * быбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
/**
   * placeholder
   */
  placeholder: PropTypes.string,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
   
  /* eslint-disable */
/**
   name input
   */
  name: PropTypes.string,
   
  /* eslint-disable */
/**
   label input
   */
  label: PropTypes.string,
};
KeyboardDateTimePickerRF.defaultProps = {
  disabled: false,
  name: 'KeyboardDateTimePickerRF',
  label: 'label for KeyboardDateTimePickerRF',
};
