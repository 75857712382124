import { filterInput } from 'core/form/filterForm/components/filterInputsClasses';
import { TextFieldFF } from 'core/form/finalForm/fields/default/TextFieldFF';
import React from 'react';
import { StringParam } from 'use-query-params';

const ID_NAME = 'id';

/**
 * Фильтр ID.
 *
 * @param {object} props - Пропсы.
 * @param {string} props.label - Лейбл.
 * @returns {JSX.Element}
 */
export const IdFilter = ({ label = 'ID', ...props }) => {
  return (
    <TextFieldFF
      {...props}
      className={filterInput}
      label={label}
      name={ID_NAME}
    />
  );
};

/**
 * Параметры для фильтрации __ID__.
 *
 * @returns {object}
 */
export const idFilterParams = () => {
  return {
    [ID_NAME]: StringParam,
  };
};
