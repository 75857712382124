import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const REAGENT_KIND_ID_NAME = 'reagentKindId';

/**
 * Компонент ReagentKindId.
 *
 * @param {object} props - Пропсы.
 * @param {string} props.label - Лейбл.
 * @returns {JSX.Element} - Возвращает компонент селекта.
 * @example
 * <ReagentKindId {...props} />
 */
export const ReagentKindId = ({ label = 'Вид ПГР', ...otherProps }) => {
  const dict = 'reagentKind';
  const { data = [] } = useGetDictionary(dict);

  return (
    <Select
      {...otherProps}
      label={label}
      name={REAGENT_KIND_ID_NAME}
      options={data}
    />
  );
};
