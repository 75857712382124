import { Cell } from 'types/table';

/**
 * Колонка __Характеристика состояния__.
 *
 * @type {{
 * accessor: 'characteristic_state_gardening_name',
 * Header: 'Характеристика состояния',
 * sortable: true,
 * }}
 * @augments Cell
 */
export const characteristic_state_gardening_name: Cell = {
  Header: 'Характеристика состояния',
  accessor: 'characteristic_state_gardening_name',
  sortable: true,
};
