/**
 * @typedef {import("../Tree").SubTypeTree} SubTypeTree
 */

/**
 * Удаляем с пустыми дочерними элементами.
 *
 * @param {Array<SubTypeTree>} arr - Массив дерева элементов.
 * @returns {Array<SubTypeTree>}
 */
export const deleteZeroGroup = (arr) => {
  return arr?.filter(
    ({ subTypes, elements }) => subTypes?.length || elements?.length,
  );
};
