/* eslint-disable  */
/**
 *
 * @param values
 */
export const validateForm = (values) => {
  const errors = {};
  if (!values.quantity) {
    errors.quantity = 'обязательное поле';
  }
  if (!values.tankNum) {
    errors.tankNum = 'обязательное поле';
  }
  if (!values.reagentKindId) {
    errors.reagentKindId = 'обязательное поле';
  }
  if (!values.reagentId) {
    errors.reagentId = 'обязательное поле';
  }

  if (!values.minimumBalancePercent) {
    errors.minimumBalancePercent = 'обязательное поле';
  }
  if (!values.tonnage) {
    errors.tonnage = 'обязательное поле';
  }
  return Object.keys(errors).length ? errors : null;
};
