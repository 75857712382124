import { TOTAL_AREA_GEO_NAME } from 'core/uiKit/preparedInputs/textField/TotalAreaGeo';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';

import { TotalAreaGeoWithFF } from './TotalAreaGeoWithFF';

/* eslint-disable */
/**
 * Компонет TotalAreaGeoFF.
 *
 * @param {object} props - Пропсы.
 * @param {string} props.name - Имя поля.
 * @param {object} props.otherProps - Остальные пропсы.
 * @returns {JSX.Element} - Компонент Field с пропсами.
 * @example <TotalAreaGeoFF {...props}/>
 */
export const TotalAreaGeoFF = ({ name, ...otherProps }) => {
  return (
    <Field
      name={TOTAL_AREA_GEO_NAME}
      component={TotalAreaGeoWithFF}
      {...otherProps}
    />
  );
};

TotalAreaGeoFF.propTypes = {
   
/**
   * Быбор темной темы.
   */
  dark: PropTypes.bool,

   
/**
   * Заблокировать на редактирование.
   */
  disabled: PropTypes.bool,

   
/**
   * Вывод ошибки.
   */
  errorText: PropTypes.string,

   
/**
   * Вывод вспомогательного текста.
   */
  helperText: PropTypes.string,

   
/**
   * Placeholder.
   */
  placeholder: PropTypes.string,

   
/**
   * Вывод информации об обязательном поле.
   */
  required: PropTypes.bool,
};
TotalAreaGeoFF.defaultProps = {
  disabled: false,
};
