 
/* eslint-disable */
/**
 * Иные объекты благоустройства
 */
import { pathImprovementObject } from 'app/pages/registry/ogh/improvementObject/ImprovementObject/path.ImprovementObject';
import { pathOgh } from 'app/pages/registry/ogh/Ogh/path.Ogh';
import { pathRegistry } from 'app/pages/registry/path.Registry';

import {
  AddButtonOgh,
  Body,
  Header,
  Main,
  RegistryToolbar,
  TreePanelOgh,
} from 'app/pages/components';
import { useGetOghTypes } from 'core/utils/hooks';

import { treeImprovementObject } from './tree.ImprovementObject';
import { TableImprovementObject } from './Table.ImprovementObject';
import { FilterImprovementObject } from './Filter.ImprovementObject';
import { DialogCreateImprovementObject } from './DialogCreate.ImprovementObject';
import { PrintButtonImprovementObject } from './PrintButton.ImprovementObject';

const ImprovementObject = () => {
  const oghType = useGetOghTypes('improvementObject');
  const typeId = oghType.id;
  const path = `/${pathRegistry.path}/${pathOgh.path}/${pathImprovementObject.path}`;

  return typeId ? (
    <Main>
      <Header Filter={FilterImprovementObject} />
      <Body>
        <RegistryToolbar showToggle={true} title={oghType.name}>
          <AddButtonOgh typeCode={oghType.code} />
          <PrintButtonImprovementObject typeId={typeId} />
        </RegistryToolbar>

        <div className={'d-flex h-100 overflow-hidden-x'}>
          <TreePanelOgh root={path} tree={treeImprovementObject} />
          <TableImprovementObject typeId={typeId} />
        </div>
        <DialogCreateImprovementObject typeId={typeId} />
      </Body>
    </Main>
  ) : null;
};

 
export default ImprovementObject;
