import { useEffect, useState } from 'react';
/* eslint-disable  */

let listeners = [];
let state = false;

/**
 *
 * @param newState
 */
const setState = (newState) => {
  state = newState;
  listeners.forEach((listener) => {
    listener(state);
  });
};

/**
 *
 */
const useDriver = () => {
  const [, newListener] = useState();
  useEffect(() => {
    listeners.push(newListener);
    return () => {
      listeners = listeners.filter((fn) => fn !== newListener);
    };
  }, []);
  return [state, setState];
};

export default useDriver;
