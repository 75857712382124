import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useGetVisibilityButtonsOgh } from 'app/api/hooks/useGetVisibilityButtonsOgh';
import { useState } from 'react';

/**
 * Setup кнопки Подписать ЭП.
 *
 * @returns {{setOpen: Function, hidden: boolean, open: boolean}}
 */
export const useSetupSignActOfCompletedButton = () => {
  const [open, setOpen] = useState(false);
  const { recordId } = useGetCurrentCard();
  const {
    data: { action_external_object, edit } = {
      action_external_object: false,
      edit: false,
    },
  } = useGetVisibilityButtonsOgh(recordId);
  const hidden = !(action_external_object && edit);

  return { hidden, open, setOpen };
};
