import { Button } from 'core/uiKit/components/buttons';
import React from 'react';
import { Link } from 'react-router-dom';

import { FormAuthFF } from '../components/Form.Auth.FF';

/**
 * Сообщение токена.
 *
 * @param {object} props - Пропсы.
 * @param {string} props.message - Сообщение.
 * @param {boolean} props.isLoading - Флаг ожидания.
 * @returns {JSX.Element}
 */
export const MessageToken = ({ message, isLoading }) => {
  return (
    <FormAuthFF
      Header={message}
      Footer={
        !isLoading ? (
          <Button
            component={Link}
            to={'/auth/login'}
            variant={'contained'}
            color={'primary'}
          >
            Вернуться на главную страницу
          </Button>
        ) : null
      }
    ></FormAuthFF>
  );
};
