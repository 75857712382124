import { PrintButtonOgh } from 'app/pages/components';
import React from 'react';

import { useFilterQueryParamsLawn } from './Filter.Lawn';

/* eslint-disable */
/**
 * Кнопка печати реестра "Газоны".
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - Id типа огх.
 * @param {number} props.parentTypeId - Id типа родителя огх.
 * @returns {JSX.Element} - JSX.
 * @example -----
 */
export const PrintButtonLawn = ({
  typeId,
  parentTypeId,
}) => {
  const { prepareFilters } = useFilterQueryParamsLawn();

  return (
    <PrintButtonOgh
      {...{
        typeId,
        prepareFilters: { ...prepareFilters, parentTypeId },
      }}
    />
  );
};
