import { uploadFile } from 'app/api/requests/uploadFiles';

 
/* eslint-disable */
/**
 * функция загрузки файлов на сервер в ответ id в массиве
 * @function uploadFileReturnArrStorageId
 // * @param {Array<File>} files
 // * @returns {Promise <storageId: Array <number> >}  id в сторадже
 */
 
export const uploadFileReturnArrStorageId = (files = []) => {
  return Promise.all(
    files.map(async (file) => {
      const res = await uploadFile(file);
      const { fileId: storageId } = res || {};

      return storageId;
    }),
  );
};
