import mapgl, { MapEventTable } from '@2gis/mapgl/types';
import {
  GeoJsonSource,
  Map,
  MapOptions,
  MapPointerEvent,
} from '@2gis/mapgl/types';
import bbox from '@turf/bbox';
import center from '@turf/center';
import {
  Feature,
  featureCollection,
  LineString,
  lineString,
  polygon,
  Position,
} from '@turf/helpers';
import {
  along,
  area,
  booleanPointInPolygon,
  length,
  nearestPointOnLine,
  polygonToLineString,
} from '@turf/turf';
import {
  BLUE,
  BLUE_HOVERED,
  RED,
  RED_HOVERED,
} from 'app/services/mapService/constants/markerTypes';
import { getMarkerIcons } from 'app/services/mapService/pointMarkers';
import { log } from 'core/utils/log';
import { MouseEventHandler } from 'react';

import { findEditableGeometries } from '../../MapglEditorContextProvider';
import { GeometryUtils, ORDERED_LAYERS, sizeInPixelsToCoords } from '..';
import AreaOrPerimeterHint from '../AreaOrPerimeterHint';
import DrawService, { DrawEventHandler } from '../DrawService';
import Vertex from '../DrawService/utils/Vertex';
import GeometryFactory from '../GeometryFactory';
import { lighten, transparentize } from '../GeometryFactory/utils';
import { Polyline } from '../Polyline';
import ShapeEditor, { isGeometryPolygon } from '../ShapeEditor';
import Shape from '../ShapeEditor/utils/Controls/Shape';
import {
  DrawGeometryObjects,
  DrawMethod,
  GenericCoordinates,
  GenericGeometryOptions,
  GenericGeometryOptionsFromMapglGeometry,
  GenericMapglGeometry,
  GeometryConstructorName,
  GeometryOptions,
  GeometryToDraw,
  GeometryTypes,
  Layers,
  MappedGeometries,
  MappedGeometryInstances,
  Marker,
  Polygon,
  PolygonOptions,
  PolylineOptions,
  RawGeometry,
  UserData,
} from '../types.d';
import {
  DEFAULT_MAPPED_GEOMETRIES,
  defaultMappedGeometries,
  defaultMappedGeometryInstances,
  mapGeometries,
} from './../index';
import { layerStyles } from './utils';

export const MAP_CENTER: [number, number] = [37.630634, 55.707146];

/**
 * Получение массива геометрий в корректом порядке для обработки событий ховера, клика и т.д.
 *
 * @param type - Тип геометрии.
 * @param layers - Типы слоев геометрий.
 * @returns Массив геометрий.
 */
const mapInstances = <
  Type extends
    | GeometryTypes.Polyline
    | GeometryTypes.Point
    | GeometryTypes.Polygon,
>(
  type: Type,
  //  instances: ReturnType<typeof useGeometryInstances>,
  layers: Record<Layers, MappedGeometryInstances>,
) => {
  // Обязательгный порядок проверки
  return [
    ...layers.originalDiff[type],
    ...layers.copyDiff[type],
    ...layers.districts[type],
    ...layers.reonArea[type],
    ...layers.adjacent[type],
    ...layers.intersections[type],
    ...layers.allChildren[type],
    ...layers.children[type],
    ...layers.selected[type],
    ...layers.parent[type],
  ];
};

export const GEOMETRY_OPTIONS_BY_LAYER = {
  adjacent: GeometryFactory.adjacentOptions,
  allChildren: GeometryFactory.allChildrenOptions,
  changing: GeometryFactory.changingOptions,
  children: GeometryFactory.childOptions,
  copyDiff: GeometryFactory.copyDiffOptions,
  districts: GeometryFactory.districtOptions,
  intersections: GeometryFactory.intersectionOptions,
  originalDiff: GeometryFactory.originalDiffOptions,
  parent: GeometryFactory.parentOptions,
  reonArea: GeometryFactory.reonAreaOptions,
  selected: GeometryFactory.selectedOptions,
};

export const DEFAULT_MAPPED_GEOMETRY_INSTANCES: MappedGeometryInstances = {
  [GeometryTypes.Polygon]: [],
  [GeometryTypes.Polyline]: [],
  [GeometryTypes.Point]: [],
};

/**
 * MapService is a class that provides access to the loaded mapgl library and
 * initializes the constructors of the GeometryFactory class.
 *
 * @param {string} layer - The name of the layer.
 * @param {string} id - Map id.
 * @returns {MapService} MapService.
 */
export class MapService {
  public static mapgl: typeof mapgl | null;

  /**
   * Initializes the Mapgl library and sets up necessary configurations.
   *
   * @param {object} loadedMapgl - The loaded Mapgl library.
   */
  public static initMapgl(loadedMapgl: typeof mapgl) {
    MapService.mapgl = loadedMapgl;
    MapService.isMapLoaded = true;
    GeometryFactory.initializeConstructors(MapService.mapgl);
  }

  /**
   * A function that calculates the center of a line represented by the given coordinates.
   *
   * @param {Array<Position> | Array<Array<number>>} coordinates - The coordinates that define the line.
   * @returns The coordinates of the center of the line.
   */
  public static getLineCenter(coordinates: Position[] | number[][]) {
    const polylineFeature = lineString(coordinates);
    const polylineLength = length(polylineFeature);
    const geometryCenter = along(polylineFeature, polylineLength / 2);
    return geometryCenter.geometry.coordinates;
  }

  /**
   * Get the perimeter of a line.
   *
   * @param {Array<Position> | Array<Array<number>>} coordinates - Description of parameter.
   * @returns Description of return value.
   */
  public static getLinePerimeter(coordinates: Position[] | number[][]) {
    const polylineFeature = lineString(coordinates);
    return length(polylineFeature, { units: 'meters' });
  }

  /**
   * Get the center of a polygon.
   *
   * @param {Array<Array<Position>> | Array<Array<number>>} coordinates - Description of parameter.
   * @returns Description of return value.
   */
  public static getPolygonCenter(coordinates: Position[][] | number[][][]) {
    const polygonFeature = polygon(coordinates);
    return center(polygonFeature);
  }

  /**
   * Get the area of a polygon.
   *
   * @param {Array<Array<Position>> | Array<Array<number>>} coordinates - Description of parameter.
   * @returns Description of return value.
   */
  public static getPolygonArea(coordinates: Position[][] | number[][]) {
    const polygonFeature = polygon(coordinates as Position[][]);
    return area(polygonFeature);
  }

  /**
   * Конвертирование координат курсора в координаты на карте.
   *
   * @param args - Аргументы.
   * @param args.map - Карта.
   * @param args.boundingRect - Bounding rect.
   * @param args.cursor - Позиция курсора из MouseEvent.
   * @param args.cursor.x - X.
   * @param args.cursor.y - Y.
   * @returns Array.
   */
  public static cursorPositionToCoordinates({
    map,
    boundingRect,

    // eslint-disable-next-line
    cursor: { x, y },
  }: {
    map: Map;
    boundingRect?: DOMRect;
    // eslint-disable-next-line
    cursor: { x: MouseEvent['clientX']; y: MouseEvent['clientY'] };
  }) {
    const mapContainer = map.getContainer();
    if (!boundingRect) boundingRect = mapContainer.getBoundingClientRect();

    // eslint-disable-next-line
    const { x: mapX, y: mapY } = boundingRect;
    // eslint-disable-next-line
    return map.unproject([x - mapX, y - mapY]);
  }

  public static isMapLoaded = false;

  public drawService: DrawService | null = null;

  public editService: ShapeEditor | null = null;

  public geometryFactories: {
    point: GeometryFactory;
    polygon: GeometryFactory;
    polyline: GeometryFactory;
  };

  public map: mapgl.Map;

  public isMapLoaded = false;

  public isDrawMode = false;

  public isDrawing = false;

  public isEditMode = false;

  public isEditing = false;

  public selectedId: string | number | null = null;

  public rootId: string | number | null = null;

  public selectedLayerType: Extract<Layers, 'parent' | 'children'> | null =
    null;

  public isGroupSelected = false;

  public hoveredGeometries: MappedGeometryInstances | null = null;

  public clickedGeometries: MappedGeometryInstances | null = null;

  public drawingGeometry: Polyline | Polygon | Marker | null = null;

  public hoveredObject: Polyline | Marker | Polygon | null = null;

  public clickedObject: Polyline | Marker | Polygon | null = null;

  public geoJsonPolylineSource: {
    editing: GeoJsonSource;
    original: GeoJsonSource;
  };

  public layers: {
    [key in Layers]: MappedGeometryInstances;
  };

  public geometriesToHandleMouseEvents: {
    lines: Polyline[];
    polygons: Polygon[];
  } = {
    lines: [],
    polygons: [],
  };

  /**
   * Данные геометрий перед началом рисования/редактирования.
   */
  public cachedGeometriesData: Record<Layers, MappedGeometries> = {
    ...DEFAULT_MAPPED_GEOMETRIES,
  };

  public geometriesData = { ...DEFAULT_MAPPED_GEOMETRIES };

  public onHoverHintElement: {
    closeHint: VoidFunction;
    element: HTMLDivElement | null;
    setOnCloseClickHandler(cb: MouseEventHandler | null): void;
    // eslint-disable-next-line
    setPosition(clickedObject: RawGeometry | null, x: number, y: number): void;
  } | null = null;

  public onClickHintElement: {
    closeHint: VoidFunction;
    element: HTMLDivElement | null;
    setOnCloseClickHandler(cb: MouseEventHandler | null): void;
    // eslint-disable-next-line
    setPosition(clickedObject: RawGeometry | null, x: number, y: number): void;
  } | null = null;

  public mainGeometryForHole: Polygon | null = null;

  public vertex: Vertex | null = null;

  private activeHandlers: {
    type: string;
    // eslint-disable-next-line
    handler: (...args: any[]) => any;
  }[] = [];

  private drawHandlers: {
    type: keyof MapEventTable;
    // eslint-disable-next-line
    handler: (...args: any[]) => any;
  }[] = [];

  private allPolylines: Polyline[] = [];

  /**
   * Constructor for creating a new Map instance.
   *
   * @param {string} id - The ID of the map element.
   * @param {Layers | Array<Layers>} layers - The layers to be added to the map.
   * @param {object} options - The options object containing map settings.
   * @param {number} options.mapCenter - The center of the map.
   * @param {number} options.maxZoom - The maximum zoom level of the map.
   * @param {number} options.zoom - The initial zoom level of the map.
   * @param {object} options.zoomControl - The position of the zoom control.
   * @param {number} [selectedId] - The selected ID, default is null.
   * @returns The created MapService instance.
   */
  constructor(
    id: string,
    layers: Layers | Layers[],
    {
      mapCenter = MAP_CENTER,
      maxZoom = 30,
      zoom = 10,
      zoomControl = 'centerLeft',
    }: {
      mapCenter?: [number, number];
      maxZoom?: number;
      zoom?: number;
      zoomControl?: MapOptions['zoomControl'];
    },
    selectedId: number | null = null,
  ) {
    if (!MapService.mapgl) {
      throw new Error('Mapgl is not initialized');
    }

    this.layers = ([] as Layers[]).concat(layers).reduce(
      (acc, layer) => ({
        ...acc,
        [layer]: DEFAULT_MAPPED_GEOMETRY_INSTANCES,
      }),
      {} as typeof this.layers,
    );

    this.map = new MapService.mapgl.Map(id, {
      center: mapCenter,
      key: '42ad45ce-016d-11eb-9d17-f321695485f2',
      maxZoom,
      zoom,
      zoomControl,
    });

    this.isMapLoaded = true;

    this.selectedId = selectedId;

    this.geometryFactories = {
      [GeometryTypes.Point]: GeometryFactory.createFactory(
        MapService.mapgl,
        this.map,
        'Marker',
      ),
      [GeometryTypes.Polygon]: GeometryFactory.createFactory(
        MapService.mapgl,
        this.map,
        'Polygon',
      ),
      [GeometryTypes.Polyline]: GeometryFactory.createFactory(
        MapService.mapgl,
        this.map,
        'Polyline',
      ),
    };

    this.geoJsonPolylineSource = {
      editing: new MapService.mapgl.GeoJsonSource(this.map, {
        attributes: {
          isEditing: true,
        },
        data: featureCollection([]),
      }),
      original: new MapService.mapgl.GeoJsonSource(this.map, {
        attributes: {
          isEditing: false,
        },
        data: featureCollection([]),
      }),
    };

    this.map.on('styleload', () => {
      this.map.addLayer(layerStyles.parentChanging);
      this.map.addLayer(layerStyles.parentEditing);
      this.map.addLayer(layerStyles.childrenChanging);
      this.map.addLayer(layerStyles.childrenEditing);
      this.map.addLayer(layerStyles.children);
      this.map.addLayer(layerStyles.parent);
      this.map.addLayer(layerStyles.allChildren);
      this.map.addLayer(layerStyles.intersections);
      this.map.addLayer(layerStyles.districts);
      this.map.addLayer(layerStyles.adjacent);
      this.map.addLayer(layerStyles.reonArea);
      this.map.addLayer(layerStyles.copyDiff);
      this.map.addLayer(layerStyles.originalDiff);
      this.map.addLayer(layerStyles.hovered);
      this.map.addLayer(layerStyles.selected);
      this.map.addLayer(layerStyles.clicked);
    });
  }

  /**
   * Sets the selected geometry layer type.
   *
   * @param type - The layer type of the selected geometry.
   */
  public setSelectedLayerType(type: MapService['selectedLayerType']) {
    this.selectedLayerType = type;
  }

  /**
   * Sets the hovered object.
   *
   * @param geometry - The hovered object.
   */
  public setHoveredObject(
    geometry: Polyline | Polygon | Marker | Polygon | null,
  ) {
    this.hoveredObject = geometry;
  }

  /**
   * Sets the clicked object.
   *
   * @param geometry - The clicked object.
   */
  public setClickedObject(geometry: Polyline | Marker | Polygon | null) {
    this.clickedObject = geometry;
  }

  /**
   * Erases the hovered object.
   *
   */
  public eraseHoveredObject() {
    const hoveredObject = this.hoveredObject;
    this.setHoveredGeometries(null);
    this.drawHovered(null, hoveredObject);
    this.setHoveredObject(null);
    this.onHoverHintElement?.setPosition(null, 0, 0);
  }

  /**
   * Erases the clicked object.
   *
   */
  public eraseClickedObject() {
    const clickedObject = this.clickedObject;
    this.setClickedGeometries(null);
    this.drawClicked(null, clickedObject);
    this.setClickedObject(null);
    this.onClickHintElement?.setPosition(null, 0, 0);
  }

  /**
   * Updates the geometries data.
   *
   * @param geometries - The geometries to update.
   * @returns Object.
   */
  public updateCachedGeometriesData(
    geometries: Partial<Record<Layers, DrawGeometryObjects>>,
  ) {
    if (geometries.intersections)
      this.geometriesData.intersections = mapGeometries(
        geometries.intersections,
      );

    if (geometries.districts)
      this.geometriesData.districts = mapGeometries(geometries.districts);

    if (geometries.parent)
      this.geometriesData.parent = mapGeometries(geometries.parent);

    if (geometries.selected)
      this.geometriesData.selected = mapGeometries(geometries.selected);

    if (geometries.children) {
      this.geometriesData.children = mapGeometries(geometries.children);
    }

    if (geometries.allChildren)
      this.geometriesData.allChildren = mapGeometries(geometries.allChildren);

    if (geometries.adjacent)
      this.geometriesData.adjacent = mapGeometries(geometries.adjacent);

    if (geometries.reonArea)
      this.geometriesData.reonArea = mapGeometries(geometries.reonArea);

    if (geometries.copyDiff)
      this.geometriesData.copyDiff = mapGeometries(geometries.copyDiff);

    if (geometries.originalDiff)
      this.geometriesData.originalDiff = mapGeometries(geometries.originalDiff);

    return this.geometriesData;
  }

  /**
   * Updates the geometries data.
   *
   * @param geometries - The geometries to update.
   * @returns Object.
   */
  public updateGeometriesData(
    geometries: Partial<Record<Layers, DrawGeometryObjects>>,
  ) {
    if (geometries.intersections)
      this.geometriesData.intersections = mapGeometries(
        geometries.intersections,
      );

    if (geometries.districts)
      this.geometriesData.districts = mapGeometries(geometries.districts);

    if (geometries.parent)
      this.geometriesData.parent = mapGeometries(geometries.parent);

    if (geometries.selected)
      this.geometriesData.selected = mapGeometries(geometries.selected);

    if (geometries.children) {
      this.geometriesData.children = mapGeometries(geometries.children);
    }

    if (geometries.allChildren)
      this.geometriesData.allChildren = mapGeometries(geometries.allChildren);

    if (geometries.adjacent)
      this.geometriesData.adjacent = mapGeometries(geometries.adjacent);

    if (geometries.reonArea)
      this.geometriesData.reonArea = mapGeometries(geometries.reonArea);

    if (geometries.copyDiff)
      this.geometriesData.copyDiff = mapGeometries(geometries.copyDiff);

    if (geometries.originalDiff)
      this.geometriesData.originalDiff = mapGeometries(geometries.originalDiff);

    return this.geometriesData;
  }

  /**
   * Sets whether a group is selected.
   *
   * @param {boolean} isGroupSelected - The flag indicating if a group is selected.
   */
  public setIsGroupSelected(isGroupSelected: boolean) {
    this.isGroupSelected = isGroupSelected;
  }

  /**
   * Set the selected ID.
   *
   * @param {string | number | null} id - The ID to set.
   */
  public setSelectedId(id: string | number | null) {
    this.selectedId = id;
  }

  /**
   * Set the root ID.
   *
   * @param {string | number | null} id - The ID to set.
   */
  public setRootId(id: string | number | null) {
    this.rootId = id;
  }

  /**
   * Sets the hovered geometries.
   *
   * @param {MappedGeometryInstances | null} hoveredGeometries - All the hovered geometries.
   */
  public setHoveredGeometries(
    hoveredGeometries: MappedGeometryInstances | null,
  ) {
    this.hoveredGeometries = hoveredGeometries;
  }

  /**
   * Sets the clicked geometries.
   *
   * @param {MappedGeometryInstances | null} clickedGeometries - All the clicked geometries.
   */
  public setClickedGeometries(
    clickedGeometries: MappedGeometryInstances | null,
  ) {
    this.clickedGeometries = clickedGeometries;
  }

  /**
   * Draws the geometries.
   *
   * @param {object} data - The data to draw.
   * @param data.newGeometries - The new geometries to draw.
   * @param data.method - The method to draw the geometries.
   * @returns Object.
   */
  public drawGeometries({
    newGeometries = {},
    method = 'replaceAll',
  }: {
    newGeometries?: Partial<Record<Layers, MappedGeometries>>;
    method?: DrawMethod;
  } = {}) {
    this.eraseHoveredObject();
    this.eraseClickedObject();

    const [, defaultLayerInstances] = ORDERED_LAYERS.reduce(
      (acc, layer) => {
        acc[0][layer] = { ...defaultMappedGeometries };
        acc[1][layer] = { ...defaultMappedGeometryInstances };
        return acc;
      },
      [{}, {}] as [
        Record<Layers, MappedGeometries>,
        Record<Layers, MappedGeometryInstances>,
      ],
    );

    const newLayers =
      this.drawLayers(
        { ...this.geometriesData, ...(newGeometries || {}) },
        method,
      ) || defaultLayerInstances;

    return newLayers;
  }

  /**
   * Finish the editing.
   *
   * @param {object} options - The options.
   * @param options.isCanceled - Флаг отмены - если true, то изменения не будут сохранены.
   * @param options.shouldDraw - Следует ли перерисовать геометрии.
   */
  public finishEditing({
    isCanceled = false,
    shouldDraw = true,
  }: { isCanceled?: boolean; shouldDraw?: boolean } = {}) {
    if (isCanceled) {
      this.geometriesData.parent = this.cachedGeometriesData.parent;
      this.geometriesData.children = this.cachedGeometriesData.children;
    }

    const parentGeometries = this.geometriesData.parent;
    const childrenGeometries = this.geometriesData.children;

    /**
     * Установить свойства редактрирования false.
     *
     * @param geometry - Геометрия.
     */
    const setNotEditingProps = (geometry: GeometryToDraw<GeometryTypes>) => {
      if (geometry) {
        geometry.options.userData.isChanging = false;
        geometry.options.userData.isEditing = false;
      }
    };

    [
      ...parentGeometries[GeometryTypes.Point],
      ...parentGeometries[GeometryTypes.Polyline],
      ...parentGeometries[GeometryTypes.Polygon],
      ...childrenGeometries[GeometryTypes.Point],
      ...childrenGeometries[GeometryTypes.Polyline],
      ...childrenGeometries[GeometryTypes.Polygon],
    ].forEach((geometry) => {
      setNotEditingProps(geometry);
    });

    this.editService?.finishEditingGeometry();

    if (shouldDraw) this.drawGeometries({ method: 'replaceAll' });
  }

  /**
   * Set the isDrawing flag.
   *
   * @param {boolean} isDrawing - The flag indicating if a drawing is in progress.
   */
  public setIsDrawing(isDrawing: boolean) {
    if (!this.isDrawMode && isDrawing) {
      throw new Error(
        'MapService is not in draw mode. Set isDrawMode to true first.',
      );
    }

    this.isDrawing = isDrawing;
  }

  /**
   * Set the draw mode.
   *
   * @param {boolean} isDrawMode - The flag indicating if a draw mode is active.
   */
  public setIsDrawMode(isDrawMode: boolean) {
    this.isDrawMode = isDrawMode;

    if (this.isDrawing && !isDrawMode) {
      this.isDrawing = false;
    }
  }

  /**
   * Start the drawing.
   *
   * @param geometryType - Тип геометрии.
   * @param options - Опции геометрии.
   */
  public startDrawing(
    geometryType = GeometryTypes.Polygon,
    options: GeometryOptions & {
      onDrawFinish?: DrawEventHandler;
    },
  ) {
    const { onDrawFinish, ..._options } = options;
    let startCoordinates: number[] | number[][] | number[][][] = [];

    let holeOptions: DrawService['holeOptions'] = null;

    if (geometryType === GeometryTypes.Hole) {
      const editableGeometries = findEditableGeometries(this, this.isEditing)();
      if (!editableGeometries) return;

      this.vertex = new Vertex(
        this.mainGeometryForHole,
        [],
        [0, 0],
        this.map,
        0,
        0,
        this,

        // eslint-disable-next-line
        this.editService?.geometryUtils!,
        {
          isAppended: false,
          isVisible: true,
        },
      );

      /**
       * Handle mouse move event on the map.
       *
       * @param event - Event.
       * @param event.lngLat - Long and lat.
       */
      const handleMouseMove = ({ lngLat }: MapPointerEvent) => {
        this.vertex?.onChange({ coordinates: lngLat });
      };

      this.map.on('mousemove', handleMouseMove);
      this.drawHandlers.push({
        handler: handleMouseMove,
        type: 'mousemove',
      });

      /**
       * Handle mouse down event on the map.
       *
       * @param {MapPointerEvent} lngLat - The longitude and latitude of the mouse pointer event on the map.
       */
      const handleMouseDown = ({ lngLat }: MapPointerEvent) => {
        const clickedGeometry = editableGeometries.polygon?.find((polygon) =>
          this.editService?.geometryUtils.isPointWithinPolygon(
            lngLat,
            polygon.userData.coordinatesToCheckMouseEvent,
          ),
        );

        if (!clickedGeometry) return;

        if (Shape.mapgl) {
          clickedGeometry.destroy();

          if (isGeometryPolygon(clickedGeometry)) {
            this.mainGeometryForHole = this.createGeometry<Polygon>(
              // @ts-ignore
              GeometryTypes?.Polygon,
              {
                ...GeometryFactory.changingOptions.Polygon,
                coordinates: clickedGeometry.userData.coordinates,
                mapService: this,
                userData: {
                  ...GeometryFactory.changingOptions.Polygon.userData,
                  coordinates: clickedGeometry.userData.coordinates,
                  coordinatesToCheckMouseEvent:
                    clickedGeometry.userData.coordinatesToCheckMouseEvent,
                  id: clickedGeometry.userData.id,
                  layerType: clickedGeometry.userData.layerType,
                },
              },
            );

            holeOptions = {
              index: clickedGeometry.userData.coordinates.length,
              mainGeometry: this.mainGeometryForHole,
            };
          }
        }

        if (!this.selectedLayerType) {
          log.warn('Выбранный тип слоя не определен');
        } else {
          this.drawService?.startDrawing(
            GeometryTypes.Polygon,
            {
              coordinates: startCoordinates as number[][][],
              mapService: this,
              onDrawFinish: onDrawFinish,
              // @ts-expect-error
              userData: {
                id: 'drawing',
                layerType: this.selectedLayerType,
                type: GeometryTypes.Polygon,
                ..._options,
                coordinates: startCoordinates as number[][][],
                mapService: this,
              },
            },
            holeOptions,
          );
        }

        /**
         * Обработчик удаляет точку под курсором.
         *
         */
        const handleMouseUp = () => {
          this.vertex?.destroy();
          this.vertex = null;
          this.map.off('mousedown', handleMouseDown);
          this.map.off('mouseup', handleMouseUp);

          this.drawHandlers = this.drawHandlers.filter(
            ({ handler }) =>
              handler !== handleMouseUp && handler !== handleMouseMove,
          );
        };

        this.map.on('mouseup', handleMouseUp);
        this.drawHandlers.push({
          handler: handleMouseUp,
          type: 'mouseup',
        });

        this.map.off('mousemove', handleMouseMove);
        this.drawHandlers = this.drawHandlers.filter(
          ({ handler }) => handler !== handleMouseMove,
        );
      };

      this.map.on('mousedown', handleMouseDown);
      this.drawHandlers.push({
        handler: handleMouseDown,
        type: 'mousedown',
      });

      return;
    }

    // geometryType = geometryType === GeometryTypes.Hole ? GeometryTypes.Polygon : geometryType;

    startCoordinates =
      geometryType === GeometryTypes.Point
        ? // @ts-ignore
          options.coordinates
        : startCoordinates;

    this.drawService?.startDrawing(
      geometryType,
      {
        coordinates:
          geometryType === GeometryTypes.Point
            ? startCoordinates
            : startCoordinates,
        mapService: this,
        onDrawFinish: options?.onDrawFinish,
        // @ts-expect-error
        userData: {
          id: 'drawing',
          layerType: 'children',
          type: geometryType,
          ..._options,
          coordinates: startCoordinates,
          mapService: this,
        },
      },
      holeOptions,
    );
  }

  /**
   * Cancel editing.
   *
   */
  public cancelEditing() {
    this.finishEditing({
      isCanceled: this.drawService?.drawingType !== GeometryTypes.Point,
      shouldDraw: this.drawService?.drawingType !== GeometryTypes.Point,
    });

    this.editService?.destroy();
    this.drawService?.destroy();

    this.setIsDrawMode(false);
    this.setIsEditMode(false);
  }

  /**
   * Cancel drawing.
   *
   */
  public cancelDrawing() {
    this.drawHandlers.forEach(({ type, handler }) => {
      this.map?.off(type, handler);
    });

    this.vertex?.destroy();
    this.vertex = null;

    this.drawHandlers = [];
    this.drawService?.cancelDrawing();

    if (this.mainGeometryForHole) {
      this.mainGeometryForHole.destroy();
      this.mainGeometryForHole = null;

      this.drawGeometries({ method: 'replaceAll' });
    }

    this.isDrawing = false;
  }

  /**
   * Set the edit mode.
   *
   * @param {object} handlers - The event handlers.
   * @param shouldCacheData - The flag indicating if the data should be cached.
   */
  public enableEditing(
    handlers?: Parameters<ShapeEditor['enableEditing']>[0],
    shouldCacheData = true,
  ) {
    if (!this.isEditMode) {
      this.isEditMode = true;
    }
    this.isEditing = true;
    if (shouldCacheData) this.cachedGeometriesData = { ...this.geometriesData };
    this.editService?.enableEditing(handlers);
  }

  /**
   * Set the edit mode.
   *
   * @param {boolean} isEditMode - The flag indicating if a edit mode is active.
   */
  public setIsEditMode(isEditMode: boolean) {
    if (this.isEditing && !isEditMode) {
      this.isEditing = false;
    } else if (this.isEditMode && !isEditMode) {
      this.editService?.disableEditing();
    }
    this.isEditMode = isEditMode;
  }

  /**
   * Get all the polylines.
   *
   * @returns The array of all polylines.
   */
  public getAllPolylines() {
    return this.allPolylines;
  }

  /**
   * Add polylines to the existing set of polylines.
   *
   * @param {Polyline | Array<Polyline>} polylines - Polylines to be added.
   */
  public addPolylines(polylines: Polyline | Polyline[]) {
    polylines = ([] as Polyline[]).concat(polylines);
    this.allPolylines = this.allPolylines.concat(polylines);
  }

  /**
   * Removes the specified polyline(s) from the list of all polylines.
   *
   * @param {Polyline | Array<Polyline>} polylines - The polyline(s) to be removed.
   */
  public removePolylines(polylines: Polyline | Polyline[]) {
    polylines = ([] as Polyline[]).concat(polylines);
    this.allPolylines = this.allPolylines.filter(
      (currentPolyline) =>
        // @ts-ignore
        !polylines.find(
          // @ts-ignore
          (polyline) => polyline.userData.id === currentPolyline.userData.id,
        ),
    );
  }

  /**
   * A function that draws layers based on the provided layers and draw method.
   *
   * @param {Partial<Record<Layers, MappedGeometries>>} layers - The layers to be drawn.
   * @param {DrawMethod} method - The method to use for drawing the layers. Defaults to 'replaceAll'.
   * @returns The updated layers after drawing.
   */
  public drawLayers(
    layers: Partial<Record<Layers, MappedGeometries>>,
    method: DrawMethod = 'replaceAll',
  ) {
    let newLayers = { ...this.layers };

    if (method === 'replaceAll') {
      ORDERED_LAYERS.forEach((layer) => {
        this.erase(this.layers[layer as Layers][GeometryTypes.Point]);
        this.erase(this.layers[layer as Layers][GeometryTypes.Polygon]);
        this.erase(this.layers[layer as Layers][GeometryTypes.Polyline]);
      });
    }

    ORDERED_LAYERS.forEach((layer) => {
      if (!(layer in layers)) return;

      const geometries = layers[layer as Layers];
      if (!geometries) return;
      newLayers = {
        ...newLayers,
        ...this.draw(layer as Layers, geometries, method),
      };
    });

    this.layers = newLayers;

    this.geometriesToHandleMouseEvents = {
      lines: mapInstances(GeometryTypes.Polyline, this.layers),
      polygons: mapInstances(GeometryTypes.Polygon, this.layers),
    };

    return this.layers;
  }

  /**
   * Create a geometry of a given type with the provided options.
   *
   * @param {string} geometryType - The type of geometry to create.
   * @param {object} options - The options for creating the geometry.
   * @returns The created geometry.
   */
  public createGeometry<Type extends RawGeometry>(
    geometryType: GeometryToDraw<
      GenericGeometryOptionsFromMapglGeometry<Type>
    >['type'],
    options: GenericMapglGeometry<Type>,
  ) {
    return this.geometryFactories[geometryType].createGeometry(options);
  }

  /**
   * Create the edit service.
   *
   * @param geometryUtils - The geometry utils instance.
   * @returns ShapeEditor.
   */
  public createEditService(geometryUtils: GeometryUtils) {
    this.editService = new ShapeEditor(this.map, this, geometryUtils);
    return this.editService;
  }

  /**
   * Create the edit service.
   *
   * @param geometryUtils - The geometry utils instance.
   * @returns ShapeEditor.
   */
  public createDrawService(geometryUtils: GeometryUtils) {
    this.drawService = new DrawService(this, geometryUtils);
    return this.drawService;
  }

  /**
   * Generate color based on layer, geometry type, and state.
   *
   * @param {string} layer - The layer type.
   * @param {string} type - The geometry type.
   * @param {object} state - The state object with click, hover, and selection status.
   * @param {boolean} state.isClicked - Whether the geometry is clicked.
   * @param {boolean} state.isHovered - Whether the geometry is hovered.
   * @param {boolean} state.isSelected - Whether the geometry is selected.
   * @returns The colors object containing color and strokeColor.
   */
  private getColorByLayer(
    layer: Layers,
    type: GeometryTypes,
    state: { isClicked: boolean; isHovered: boolean; isSelected: boolean },
  ) {
    const { isClicked, isHovered, isSelected } = state;
    if (isSelected) layer = 'selected';

    const geometryOptionsName =
      type === GeometryTypes.Point
        ? 'Marker'
        : type === GeometryTypes.Polyline
        ? 'Polyline'
        : 'Polygon';

    if (geometryOptionsName === 'Marker') {
      const colors = {
        color: RED,
      };

      if (layer === 'allChildren') {
        if (isClicked && isHovered && isSelected) {
          colors.color = RED_HOVERED;
        } else if (isClicked || isHovered) {
          colors.color = BLUE_HOVERED;
        }
        return colors;
      }

      if (isHovered || isClicked) {
        colors.color = RED_HOVERED;
      }
      return colors;
    }

    if (geometryOptionsName === 'Polyline') {
      const defaultOptions =
        GEOMETRY_OPTIONS_BY_LAYER[layer][geometryOptionsName];
      const colors = {
        color: defaultOptions.color,
      };

      if (isSelected) {
        colors.color = GEOMETRY_OPTIONS_BY_LAYER.selected.Polyline.color;
      }

      if (isClicked) {
        colors.color = transparentize(
          GeometryFactory.hoveredOptions.Polyline.color,
          1,
        );
        return colors;
      }

      if (isHovered) {
        colors.color = transparentize(
          GeometryFactory.hoveredOptions.Polyline.color,
          0.55,
        );
      }
      return colors;
    }

    if (geometryOptionsName === 'Polygon') {
      const defaultOptions =
        GEOMETRY_OPTIONS_BY_LAYER[layer][geometryOptionsName];
      const colors = {
        color: defaultOptions.color,
        strokeColor: defaultOptions.strokeColor,
      };

      if (isHovered) {
        colors.strokeColor = GeometryFactory.hoveredOptions.Polygon.strokeColor;
      }

      if (isClicked) {
        colors.strokeColor = transparentize(
          GeometryFactory.hoveredOptions.Polygon.strokeColor,
          1,
        );
      }

      if (isHovered && isClicked) {
        colors.color = transparentize(
          lighten(colors.color, 0.3) || colors.color,
          0.55,
        );
        return colors;
      }

      if (isHovered) {
        colors.color = lighten(colors.color, 0.3) || colors.color;
        return colors;
      }

      if (isClicked) {
        colors.color = transparentize(
          lighten(colors.color, 0.15) || colors.color,
          0.55,
        );
      }
      return colors;
    }

    return {};
  }

  /**
   * A function to create a hint for either area or perimeter based on the geometry type.
   *
   * @param {object} geometry - The geometry object to create hint for.
   * @returns The hint object for either area or perimeter.
   */
  private createAreaOrPerimeterHint(
    geometry: PolygonOptions | PolylineOptions,
  ) {
    const { userData } = geometry;
    if (userData.type === GeometryTypes.Polygon) {
      const geometryArea = MapService.getPolygonArea(userData.coordinates);
      const polygonCenter = MapService.getPolygonCenter(userData.coordinates);
      const polygonFeature = polygon(userData.coordinates);
      const polygonAsLineString = polygonToLineString(
        polygonFeature,
      ) as Feature<LineString>;

      let hintPointFeature = polygonCenter;

      if (!booleanPointInPolygon(polygonCenter, polygonFeature)) {
        const nearestPoint = nearestPointOnLine(
          polygonAsLineString,
          polygonCenter,
        );
        hintPointFeature = nearestPoint;
      }

      let areaHint: AreaOrPerimeterHint | null = null;

      /**
       * Updates the position of the hint element.
       *
       */
      const updateHintPosition = () => {
        if (areaHint && !areaHint.isDestroyed) {
          // eslint-disable-next-line
          const [x, y] = this.map.project(
            hintPointFeature.geometry.coordinates,
          );
          // eslint-disable-next-line
          areaHint?.setPosition(x, y - 16);
        }
      };

      /**
       * Changes the visibility of the hint element.
       *
       */
      const changeHintVisibility = () => {
        const geometryBbox = bbox(polygonAsLineString);
        const [y1, x1] = this.map.project([geometryBbox[0], geometryBbox[1]]);
        const [y2, x2] = this.map.project([geometryBbox[2], geometryBbox[3]]);
        const polygonAreaInPixels = Math.abs((y2 - y1) * (x2 - x1));

        if (areaHint) {
          if (polygonAreaInPixels > 20_000 && !areaHint.isVisible) {
            areaHint.show();
            return;
          }
          if (polygonAreaInPixels < 20_000 && areaHint.isVisible) {
            areaHint!.hide();
          }
        }
      };

      // eslint-disable-next-line
      const [x, y] = this.map.project(hintPointFeature.geometry.coordinates);
      areaHint = new AreaOrPerimeterHint(
        `${Math.round(geometryArea * 100) / 100} кв. м`,
        // eslint-disable-next-line
        x,
        // eslint-disable-next-line
        y - 16,
        this.map,
        geometry.userData.id,
        {
          move: updateHintPosition,
          zoomend: changeHintVisibility,
        },
      );

      changeHintVisibility();

      this.map.getContainer().appendChild(areaHint.element);
      this.map.on('move', updateHintPosition);
      this.map.on('zoomend', changeHintVisibility);

      this.activeHandlers.push(
        {
          handler: updateHintPosition,
          type: 'move',
        },
        {
          handler: changeHintVisibility,
          type: 'zoomend',
        },
      );

      return areaHint;
    }

    const polylineLengthInMeters = MapService.getLinePerimeter(
      userData.coordinates,
    );
    const lineCenter = MapService.getLineCenter(userData.coordinates);
    // eslint-disable-next-line
    const [x, y] = this.map.project(lineCenter);

    let perimeterHint: AreaOrPerimeterHint | null = null;

    /**
     * Updates the position of the hint element.
     *
     */
    const updateHintPosition = () => {
      if (perimeterHint && !perimeterHint.isDestroyed) {
        // eslint-disable-next-line
        const [x, y] = this.map.project(lineCenter);
        // eslint-disable-next-line
        perimeterHint?.setPosition(x + 7, y - 12 - 16);
      }
    };

    perimeterHint = new AreaOrPerimeterHint(
      `${Math.round(polylineLengthInMeters * 100) / 100} м`,
      // eslint-disable-next-line
      x + 10,
      // eslint-disable-next-line
      y - 15 - 16,
      this.map,
      geometry.userData.id,
      {
        move: updateHintPosition,
      },
    );

    this.map.getContainer().appendChild(perimeterHint.element);
    this.map.on('move', updateHintPosition);

    this.activeHandlers.push({
      handler: updateHintPosition,
      type: 'move',
    });

    return perimeterHint;
  }

  /**
   * Draws geometries on the specified layer using different colors and markers based on their states.
   *
   * @param {Layers} layer - The layer on which the geometries will be drawn.
   * @param {MappedGeometries} geometries - The collection of geometries to be drawn.
   * @param {DrawMethod} method - The method used for drawing, defaults to 'add'.
   * @returns The updated layers after drawing the geometries.
   */
  public draw(
    layer: Layers,
    geometries: MappedGeometries,
    method: DrawMethod = 'add',
  ) {
    //

    /**
     * Generate a marker for a given geometry and layer type.
     *
     * @param {GeometryToDraw<GeometryTypes.Point>} geometry - The geometry to draw the marker on.
     * @param {Layers} layerType - The type of layer the marker belongs to.
     * @returns The created marker.
     */
    const createMarker = (
      geometry: GeometryToDraw<GeometryTypes.Point>,
      layerType: Layers,
    ) => {
      let markerIcons = getMarkerIcons(geometry.options.userData.type_id);
      let currentColor = RED;

      const isGeometryHovered =
        this.hoveredGeometries &&
        this.hoveredGeometries[GeometryTypes.Point].find(
          (point) =>
            geometry.options.userData.id &&
            point.userData.id === geometry.options.userData.id,
        );
      const isGeometryClicked =
        this.clickedGeometries &&
        this.clickedGeometries[GeometryTypes.Point].find(
          (point) =>
            geometry.options.userData.id &&
            point.userData.id === geometry.options.userData.id,
        );

      if (layerType === 'allChildren') {
        const currentColor = BLUE;
        markerIcons = getMarkerIcons(
          geometry.options.userData.type_id,
          currentColor,
        );
      }

      if (isGeometryClicked) {
        markerIcons.icon = markerIcons.hoverIcon;
      }

      if (isGeometryHovered) {
        if (
          this.layers.children[GeometryTypes.Point].find(
            (point) => point.userData.id === geometry.options.userData.id,
          )
        ) {
          currentColor = RED;
          markerIcons.icon = getMarkerIcons(
            geometry.options.userData.type_id,
            currentColor,
          ).hoverIcon;
        } else {
          markerIcons.icon = markerIcons.hoverIcon;
        }
      }

      const coordinatesToCheckMouseEvent = sizeInPixelsToCoords(
        this.map,
        geometry,
      );

      const userData = {
        ...geometry.options.userData,
        coordinatesToCheckMouseEvent,
        currentColor,
        mapService: this,
      };

      const marker = this.createGeometry<Marker>(geometry.type, {
        ...GEOMETRY_OPTIONS_BY_LAYER[layer].Marker,
        ...(this.isGeometrySelected(
          layer,
          // @ts-ignore
          geometry.options.userData.oghObjectId || geometry.options.userData.id,
        )
          ? GEOMETRY_OPTIONS_BY_LAYER.selected.Marker
          : {}),
        ...geometry.options,
        ...markerIcons,
        userData,
      });

      if (geometry.options.userData.markerEvents) {
        (
          Object.keys(geometry.options.userData.markerEvents) as Parameters<
            Marker['on']
          >[0][]
        ).forEach((type) => {
          marker?.on(
            // @ts-ignore
            type,
            // @ts-ignore
            geometry.options.userData.markerEvents[type as string],
          );
        });
      }

      return marker;
    };

    const clicked =
      this.clickedGeometries &&
      Object.values(this.clickedGeometries).find((geometries: RawGeometry[]) =>
        geometries?.find((geometry: RawGeometry) => !!geometry),
      )?.[0];
    const hovered =
      this.hoveredGeometries &&
      Object.values(this.hoveredGeometries).find((geometries: RawGeometry[]) =>
        geometries?.find((geometry: RawGeometry) => !!geometry),
      )?.[0];

    if (method === 'add') {
      this.layers[layer] = {
        [GeometryTypes.Polygon]: this.layers[layer][
          GeometryTypes.Polygon
        ].concat(
          (geometries[GeometryTypes.Polygon] || []).map((geometry) => {
            const isSelected = this.isGeometrySelected(
              layer,
              // @ts-ignore
              geometry.options.userData.oghObjectId ||
                // @ts-ignore
                geometry.options.userData.id,
            );

            const colors = this.getColorByLayer(layer, GeometryTypes.Polygon, {
              isClicked: !!(
                clicked && clicked.userData.id === geometry.options.userData.id
              ),
              isHovered: !!(
                hovered && hovered.userData.id === geometry.options.userData.id
              ),
              isSelected,
            });

            const { isChanging = false } = geometry.options.userData;

            // @ts-ignore
            const options = this.createGeometry<Polygon>(geometry.type, {
              ...GEOMETRY_OPTIONS_BY_LAYER[layer].Polygon,
              ...(isSelected ? GEOMETRY_OPTIONS_BY_LAYER.selected.Polygon : {}),
              ...geometry.options,
              ...colors,
              userData: {
                ...geometry.options.userData,
                ...(isSelected
                  ? {
                      areaOrPerimeterHint: this.createAreaOrPerimeterHint(
                        geometry.options,
                      ),
                    }
                  : {}),
                coordinatesToCheckMouseEvent:
                  geometry.options.userData.coordinates,
                mapService: this,
              },
            });

            options.userData.currentColor = geometry.options.color;
            options.userData.currentStrokeColor =
              clicked?.userData.id === geometry.options.userData.id
                ? isChanging
                  ? GEOMETRY_OPTIONS_BY_LAYER.changing.Polygon.strokeColor
                  : isSelected
                  ? GEOMETRY_OPTIONS_BY_LAYER.selected.Polygon.strokeColor
                  : GEOMETRY_OPTIONS_BY_LAYER[layer].Polygon.strokeColor
                : geometry.options.strokeColor;

            return options;
          }),
        ),
        [GeometryTypes.Polyline]: this.layers[layer][
          GeometryTypes.Polyline
        ].concat(
          (geometries[GeometryTypes.Polyline] || []).map((geometry) => {
            const isSelected = this.isGeometrySelected(
              layer,
              // @ts-ignore
              geometry.options.userData.oghObjectId ||
                // @ts-ignore
                geometry.options.userData.id,
            );

            const colors = this.getColorByLayer(layer, GeometryTypes.Polyline, {
              isClicked: !!(
                clicked && clicked.userData.id === geometry.options.userData.id
              ),
              isHovered: !!(
                hovered && hovered.userData.id === geometry.options.userData.id
              ),
              isSelected,
            });

            const options: GenericGeometryOptions<GeometryTypes.Polyline> = {
              ...GEOMETRY_OPTIONS_BY_LAYER[layer].Polyline,
              ...(this.isGeometrySelected(
                layer,
                // @ts-ignore
                geometry.options.userData.oghObjectId ||
                  // @ts-ignore
                  geometry.options.userData.id,
              )
                ? GEOMETRY_OPTIONS_BY_LAYER.selected.Polyline
                : {}),
              ...geometry.options,
              ...colors,
              userData: {
                ...geometry.options.userData,
                ...(isSelected
                  ? {
                      areaOrPerimeterHint: this.createAreaOrPerimeterHint(
                        geometry.options,
                      ),
                    }
                  : {}),
                coordinatesToCheckMouseEvent:
                  geometry.options.userData.coordinates,
                isSelected,
                mapService: this,
              },
            };

            options.userData.currentColor = geometry.options.color;

            return this.createGeometry<Polyline>(geometry.type, options);
          }),
        ),
        [GeometryTypes.Point]: this.layers[layer][GeometryTypes.Point].concat(
          (geometries[GeometryTypes.Point] || []).map((geometry) => {
            return createMarker(geometry, layer);
          }),
        ),
      };

      return this.layers;
    }

    if (method === 'replaceAll') {
      this.layers[layer] = {
        // drawing polygons
        [GeometryTypes.Polygon]: (geometries[GeometryTypes.Polygon] || []).map(
          (geometry) => {
            const isSelected = this.isGeometrySelected(
              layer,
              // @ts-ignore
              geometry.options.userData.oghObjectId ||
                // @ts-ignore
                geometry.options.userData.id,
            );

            const colors = this.getColorByLayer(layer, GeometryTypes.Polygon, {
              isClicked: !!(
                clicked && clicked.userData.id === geometry.options.userData.id
              ),
              isHovered: !!(
                hovered && hovered.userData.id === geometry.options.userData.id
              ),
              isSelected,
            });

            const { isChanging = false } = geometry.options.userData;

            const options = {
              ...GEOMETRY_OPTIONS_BY_LAYER[layer].Polygon,
              ...(isSelected ? GEOMETRY_OPTIONS_BY_LAYER.selected.Polygon : {}),
              ...geometry.options,
              ...colors,
              ...(isChanging
                ? {
                    color: GEOMETRY_OPTIONS_BY_LAYER.changing.Polygon.color,
                    strokeColor:
                      GEOMETRY_OPTIONS_BY_LAYER.changing.Polygon.strokeColor,
                  }
                : {}),
            };

            // @ts-ignore
            options.userData.currentColor = geometry.options.userData.color;
            // @ts-ignore
            options.userData.currentStrokeColor =
              clicked?.userData.id === geometry.options.userData.id
                ? isSelected
                  ? GEOMETRY_OPTIONS_BY_LAYER.selected.Polygon.strokeColor
                  : GEOMETRY_OPTIONS_BY_LAYER[layer].Polygon.strokeColor
                : isChanging
                ? GEOMETRY_OPTIONS_BY_LAYER.changing.Polygon.strokeColor
                : geometry.options.userData.strokeColor;

            options.userData.mapService = this;

            if (isSelected) {
              options.userData.areaOrPerimeterHint =
                this.createAreaOrPerimeterHint(geometry.options);
            }

            // @ts-ignore
            return this.createGeometry<Polygon>(geometry.type, options);
          },
        ),

        // drawing polyline
        [GeometryTypes.Polyline]: (
          geometries[GeometryTypes.Polyline] || []
        ).map((geometry) => {
          const isSelected = this.isGeometrySelected(
            layer,
            // @ts-ignore
            geometry.options.userData.oghObjectId ||
              // @ts-ignore
              geometry.options.userData.id,
          );

          const colors = this.getColorByLayer(layer, GeometryTypes.Polyline, {
            isClicked: !!(
              clicked && clicked.userData.id === geometry.options.userData.id
            ),
            isHovered: !!(
              hovered && hovered.userData.id === geometry.options.userData.id
            ),
            isSelected,
          });

          const { isChanging = false } = geometry.options.userData;

          const options = {
            ...GEOMETRY_OPTIONS_BY_LAYER[layer].Polyline,
            ...(this.isGeometrySelected(
              layer,
              // @ts-ignore
              geometry.options.userData.oghObjectId ||
                // @ts-ignore
                geometry.options.userData.id,
            )
              ? GEOMETRY_OPTIONS_BY_LAYER.selected.Polyline
              : {}),
            ...geometry.options,
            ...colors,
            ...(isChanging
              ? {
                  color: GEOMETRY_OPTIONS_BY_LAYER.changing.Polyline.color,
                }
              : {}),
            userData: {
              ...geometry.options.userData,
              ...(isSelected
                ? {
                    areaOrPerimeterHint: this.createAreaOrPerimeterHint(
                      geometry.options,
                    ),
                  }
                : {}),
              isSelected,
            },
          };

          options.userData.currentColor = isChanging
            ? GEOMETRY_OPTIONS_BY_LAYER.changing.Polyline.color
            : geometry.options.color;
          options.userData.mapService = this;

          return this.createGeometry<Polyline>(geometry.type, options);
        }),

        // drawing points
        [GeometryTypes.Point]: (geometries[GeometryTypes.Point] || []).map(
          (geometry) => {
            return createMarker(geometry, layer);
          },
        ),
      };

      return this.layers;
    }

    return this.layers;
  }

  /**
   * Получаем новые геометрии отфильтрованные от тех, что нужно стереть.
   *
   * @param {object|Array<object>} geometries - Геометрии.
   * @param shouldRemoveFromLayers - Очищать ли слои.
   */
  public erase(
    geometries: RawGeometry | RawGeometry[],
    shouldRemoveFromLayers = false,
  ) {
    const erased: RawGeometry[] = [];
    ([] as RawGeometry[]).concat(geometries).forEach((geometry) => {
      erased.push(geometry);
      geometry.destroy();
      geometry.userData.areaOrPerimeterHint?.destroy();
    });

    if (shouldRemoveFromLayers) {
      const newLayers = { ...this.layers };

      erased.forEach((geometry) => {
        const { layerType, type } = geometry.userData;
        if (!layerType || !type) return;

        // eslint-disable-next-line
        const _this = this;

        // TS ругается на ошибки, поэтому приходится городить такие конструкции с перегрузкой
        function getNewGeometries(_type: GeometryTypes.Polygon): Polygon[];
        function getNewGeometries(_type: GeometryTypes.Polyline): Polyline[];
        function getNewGeometries(_type: GeometryTypes.Point): Marker[];
        function getNewGeometries(_type: GeometryTypes): never[];

        /**
         * Получаем новые геометрии отфильтрованные от тех, что нужно стереть.
         *
         * @param {object} this - Инстанс MapService.
         * @param {string} _type - Тип геометрий.
         * @returns Новые геометрии.
         */
        function getNewGeometries(
          this: MapService,
          _type: GeometryTypes,
        ): RawGeometry[] | undefined {
          //

          /**
           * A description of the entire function.
           *
           * @param {RawGeometry} _geometry - Description of parameter.
           * @returns Description of return value.
           */
          const filterFn = (_geometry: RawGeometry) =>
            _geometry.userData.id !== geometry.userData.id;

          if (_type === GeometryTypes.Polygon) {
            // @ts-ignore
            return _this.layers[layerType][GeometryTypes.Polygon].filter(
              filterFn,
            );
          } else if (_type === GeometryTypes.Polyline) {
            // @ts-ignore
            return _this.layers[layerType][GeometryTypes.Polyline].filter(
              filterFn,
            );
          } else if (_type === GeometryTypes.Point) {
            // @ts-ignore
            return _this.layers[layerType][GeometryTypes.Point].filter(
              filterFn,
            );
          }

          return [];
        }

        // @ts-ignore
        newLayers[layerType][type] = getNewGeometries(type) || [];
      });

      this.layers = newLayers;
    }
  }

  /**
   * A function that checks if a geometry is selected.
   *
   * @param layer - The layer of the geometry.
   * @param id - The ID of the geometry.
   * @returns True if the geometry is selected, false otherwise.
   */
  public isGeometrySelected = (layer: Layers, id?: string | number | null) => {
    return (
      (!this.isGroupSelected &&
        this.selectedId !== null &&
        this.selectedId === id &&
        layer !== 'allChildren' &&
        layer !== 'copyDiff' &&
        layer !== 'originalDiff') ||
      (this.isGroupSelected && layer === 'children')
    );
  };

  /**
   * A function to create geometry options based on the raw geometry and additional options.
   *
   * @param {RawGeometry} geometry - The raw geometry data.
   * @param {Function} getExtraOptions - Optional function to get extra options.
   * @returns The geometry options object or null if optionsGeometryType is invalid.
   */
  public createGeometryOptions<Type extends GeometryTypes>(
    geometry: RawGeometry,
    getExtraOptions?: (
      optionsGeometryType: GeometryConstructorName,
    ) => object & {
      userData: Partial<UserData<GeometryTypes>>;
    },
  ) {
    const { layerType, type } = geometry.userData;

    const optionsGeometryType =
      type === GeometryTypes.Point
        ? 'Marker'
        : type === GeometryTypes.Polygon
        ? 'Polygon'
        : type === GeometryTypes.Polyline
        ? 'Polyline'
        : null;

    if (!optionsGeometryType) return null;

    const userData = geometry.userData as UserData<Type>;
    const isGeometrySelected = this.isGeometrySelected(
      layerType,
      // @ts-ignore
      userData.oghObjectId || userData.id,
    );

    const extraOptions = getExtraOptions?.(optionsGeometryType);

    return {
      options: {
        ...GeometryFactory.defaultOptions[optionsGeometryType],
        // @ts-ignore
        ...GEOMETRY_OPTIONS_BY_LAYER[layerType][optionsGeometryType],
        ...(isGeometrySelected
          ? GEOMETRY_OPTIONS_BY_LAYER.selected[optionsGeometryType]
          : {}),
        ...extraOptions,
        coordinates: userData.coordinates as GenericCoordinates<Type>,
        userData: {
          ...userData,
          ...extraOptions?.userData,
        },
      },
      type,
    } as unknown as GeometryToDraw<Type>;
  }

  /**
   * A description of the entire function.
   *
   * @param {Partial<Record<Layers, MappedGeometries>>} layersToDraw - Description of the layers to draw.
   */
  private addAndDrawGeometries(
    layersToDraw: Partial<Record<Layers, MappedGeometries>>,
  ) {
    Object.entries(layersToDraw).forEach(([layer, geometries]) => {
      const _layer = layer as Layers;

      const addedPointIndexes: number[] = [];
      const addedPolylineIndexes: number[] = [];
      const addedPolygonIndexes: number[] = [];

      this.layers[_layer] = {
        [GeometryTypes.Point]: geometries.point.length
          ? this.layers[_layer].point.filter((point, index) =>
              geometries.point.find(({ options }) => {
                if (options.userData.id !== point.userData.id) return true;
                addedPointIndexes.push(index);
                return false;
              }),
            )
          : this.layers[_layer].point,
        [GeometryTypes.Polygon]: geometries.polygon.length
          ? this.layers[_layer].polygon.filter((polygon, index) =>
              geometries.polygon.find(({ options }) => {
                if (options.userData.id !== polygon.userData.id) return true;
                addedPolygonIndexes.push(index);
                return false;
              }),
            )
          : this.layers[_layer].polygon,
        [GeometryTypes.Polyline]: geometries.polyline.length
          ? this.layers[_layer].polyline.filter((polyline, index) =>
              geometries.polyline.find(({ options }) => {
                if (options.userData.id !== polyline.userData.id) return true;
                addedPolylineIndexes.push(index);
                return false;
              }),
            )
          : this.layers[_layer].polyline,
      };

      this.draw(_layer, geometries, 'add');

      if (addedPointIndexes.length) {
        addedPointIndexes.forEach((index) => {
          const drawnPoint = this.layers[_layer].point.pop();
          if (drawnPoint) {
            this.layers[_layer].point.splice(index, 0, drawnPoint);
          }
        });
      }

      if (addedPolylineIndexes.length) {
        addedPolylineIndexes.forEach((index) => {
          const drawnPolyline = this.layers[_layer].polyline.pop();
          if (drawnPolyline) {
            this.layers[_layer].polyline.splice(index, 0, drawnPolyline);
          }
        });
      }

      if (addedPolygonIndexes.length) {
        addedPolygonIndexes.forEach((index) => {
          const drawnPolygon = this.layers[_layer].polygon.pop();
          if (drawnPolygon) {
            this.layers[_layer].polygon.splice(index, 0, drawnPolygon);
          }
        });
      }
    });
  }

  /**
   * Generates layers to draw based on the given geometries and extra options.
   *
   * @param {object | Array<object>} geometries - The geometries to create layers for.
   * @param {object} newLayers - The new layers to add the geometries to.
   * @param {object} extraOptions - Extra options for creating the layers.
   * @param {boolean} extraOptions.unmodify - A flag indicating if the geometries should be unmodified.
   * @param {Function} extraOptions.getExtraOptions - A function that returns the extra options for the given geometry type.
   * @returns The updated new layers with added geometries.
   */
  private createLayersToDraw(
    geometries: RawGeometry | RawGeometry[],
    newLayers: Partial<Record<Layers, MappedGeometries>>,
    extraOptions: {
      unmodify: boolean;
      getExtraOptions?: (
        unmodify: boolean,
      ) => (
        optionsGeometryType: GeometryConstructorName,
      ) => object & { userData: Partial<UserData<GeometryTypes>> };
    },
  ) {
    const { unmodify, getExtraOptions } = extraOptions;

    return ([] as RawGeometry[])
      .concat(geometries)
      .reduce((acc: typeof newLayers, geometry: RawGeometry) => {
        const layerType = geometry.userData.layerType as Layers;
        const geometryType = geometry.userData.type;

        if (!newLayers[layerType]) {
          newLayers[layerType] = {
            [GeometryTypes.Point]: [],
            [GeometryTypes.Polygon]: [],
            [GeometryTypes.Polyline]: [],
          };
        }

        if (geometryType === GeometryTypes.Point) {
          const geometryOptions =
            this.createGeometryOptions<GeometryTypes.Point>(
              geometry,
              getExtraOptions?.(unmodify),
            );

          if (!geometryOptions) return acc;

          newLayers[layerType]?.[GeometryTypes.Point].push(geometryOptions);

          // eslint-disable-next-line
          const _this = this;
          (function getPointAndDestroy() {
            _this.layers[layerType][GeometryTypes.Point]
              ?.find(
                (currGeometry) =>
                  currGeometry &&
                  currGeometry?.userData.id === geometry.userData.id,
              )
              ?.destroy();
          })();
        } else if (geometryType === GeometryTypes.Polygon) {
          const geometryOptions =
            this.createGeometryOptions<GeometryTypes.Polygon>(
              geometry,
              getExtraOptions?.(unmodify),
            );
          if (!geometryOptions) return acc;

          newLayers[layerType]?.[GeometryTypes.Polygon].push(geometryOptions);

          // eslint-disable-next-line
          const _this = this;
          (function getPolygonAndDestroy() {
            const _polygon = _this.layers[layerType][
              GeometryTypes.Polygon
            ]?.find(
              (currGeometry) =>
                currGeometry &&
                currGeometry?.userData.id === geometry.userData.id,
            );

            _polygon?.destroy();
            _polygon?.userData?.areaOrPerimeterHint?.destroy();
          })();
        } else if (geometryType === GeometryTypes.Polyline) {
          const geometryOptions =
            this.createGeometryOptions<GeometryTypes.Polyline>(
              geometry,
              getExtraOptions?.(unmodify),
            );
          if (!geometryOptions) return acc;

          newLayers[layerType]?.[GeometryTypes.Polyline].push(geometryOptions);

          // eslint-disable-next-line
          const _this = this;
          (function getPolylineAndDestroy() {
            const _polyline = _this.layers[layerType][
              GeometryTypes.Polyline
            ]?.find(
              (currGeometry) =>
                currGeometry &&
                currGeometry?.userData.id === geometry.userData.id,
            );
            _polyline?.destroy();
            _polyline?.userData?.areaOrPerimeterHint?.destroy();
          })();
        }

        return acc;
      }, newLayers);
  }

  /**
   * Generate new layers for hovered and unhovered geometries and draw them.
   *
   * @param {RawGeometry | Array<RawGeometry> | null} hoveredGeometries - The geometries to be hovered over.
   * @param {RawGeometry | Array<RawGeometry> | null} [unhoveredGeometries] - The geometries to be unhovered.
   */
  public drawHovered(
    hoveredGeometries: RawGeometry | RawGeometry[] | null,
    unhoveredGeometries?: RawGeometry | RawGeometry[] | null,
  ) {
    let newLayers: Partial<Record<Layers, MappedGeometries>> = {};

    /**
     * A function that generates extra options based on the input parameters.
     *
     * @param {boolean} unhover - Indicates if the unhover state is true or false.
     * @returns The generated extra options object.
     */
    const getExtraOptions =
      (unhover: boolean) => (optionsGeometryType: GeometryConstructorName) =>
        unhover
          ? { userData: { isHovered: false } }
          : {
              ...GeometryFactory.hoveredOptions[optionsGeometryType],
              userData: {
                isHovered: true,
              },
            };

    if (unhoveredGeometries) {
      unhoveredGeometries = ([] as RawGeometry[])
        .concat(unhoveredGeometries)
        .map((geometry) => {
          const layerType = geometry.userData.layerType as Layers;
          const geometryType = geometry.userData.type as Exclude<
            GeometryTypes,
            GeometryTypes.Hole
          >;
          const unhoveredGeometry = this.layers[layerType][geometryType].find(
            (currentGeometry) =>
              currentGeometry.userData.id === geometry.userData.id,
          );

          return unhoveredGeometry;
        }) as RawGeometry[];

      newLayers = this.createLayersToDraw(unhoveredGeometries, newLayers, {
        getExtraOptions,
        unmodify: true,
      });
      this.addAndDrawGeometries(newLayers);
      newLayers = {};
    }

    if (!hoveredGeometries) return;

    newLayers = this.createLayersToDraw(hoveredGeometries, newLayers, {
      getExtraOptions,
      unmodify: false,
    });
    this.addAndDrawGeometries(newLayers);
  }

  /**
   * A function that handles drawing clicked geometries on the map.
   *
   * @param {object|Array<object> | null} clickedGeometries - The geometries that were clicked.
   * @param {object|Array<object> | null} [unclickedGeometries] - The geometries that were unclicked.
   */
  public drawClicked(
    clickedGeometries: RawGeometry | RawGeometry[] | null,
    unclickedGeometries?: RawGeometry | RawGeometry[] | null,
  ) {
    let newLayers: Partial<Record<Layers, MappedGeometries>> = {};

    /**
     * Returns extra options based on the unclick flag and the geometry type.
     *
     * @param {boolean} unclick - Flag indicating whether to unclick.
     * @returns The extra options based on the conditions.
     */
    const getExtraOptions =
      (unclick: boolean) => (optionsGeometryType: GeometryConstructorName) =>
        unclick
          ? { userData: { isClicked: false } }
          : {
              ...GeometryFactory.hoveredOptions[optionsGeometryType],
              userData: {
                isClicked: true,
              },
            };

    if (unclickedGeometries) {
      unclickedGeometries = ([] as RawGeometry[])
        .concat(unclickedGeometries)
        .map((geometry) => {
          const layerType = geometry.userData.layerType as Layers;
          const geometryType = geometry.userData.type as Exclude<
            GeometryTypes,
            GeometryTypes.Hole
          >;
          const unhoveredGeometry = this.layers[layerType][geometryType].find(
            (currentGeometry) =>
              currentGeometry.userData.id === geometry.userData.id,
          );

          return unhoveredGeometry;
        }) as RawGeometry[];

      newLayers = this.createLayersToDraw(unclickedGeometries, newLayers, {
        getExtraOptions,
        unmodify: true,
      });
      this.addAndDrawGeometries(newLayers);
      newLayers = {};
    }

    if (!clickedGeometries) return;

    newLayers = this.createLayersToDraw(clickedGeometries, newLayers, {
      getExtraOptions,
      unmodify: false,
    });
    this.addAndDrawGeometries(newLayers);
    newLayers = {};
  }

  /**
   * Generate a zoom level to fit the given geometries on the map.
   *
   * @param {object} geometries - The geometries to fit on the map.
   * @param {object} options - Optional parameters for fitting the bounds.
   */
  public zoomToGeometries(
    geometries: MappedGeometryInstances,
    options?: Parameters<Map['fitBounds']>[1],
  ) {
    if (!this.map) return;

    if (
      !geometries ||
      (!geometries.point?.length &&
        !geometries.polyline?.length &&
        !geometries.polygon?.length)
    ) {
      return;
    }

    let finalGeometry = geometries.polygon.reduce((acc, geometry) => {
      if (!geometry) return acc;
      return [...acc, ...geometry.userData.coordinates.flat(1)];
    }, [] as number[][]);

    finalGeometry = geometries.polyline.reduce((acc, geometry) => {
      if (!geometry) return acc;
      return [...acc, ...geometry.userData.coordinates];
    }, finalGeometry as number[][]);

    finalGeometry = geometries.point.reduce((acc, geometry) => {
      if (!geometry) return acc;
      return [...acc, geometry.userData.coordinates];
    }, finalGeometry as number[][]);

    if (!finalGeometry.length) return;

    if (finalGeometry.length === 1) {
      finalGeometry.push(finalGeometry[0]);
    }

    const bounds = bbox(lineString(finalGeometry));

    if (!bounds.length) return;

    const [north, east, south, west] = bounds;

    this.map.fitBounds(
      {
        northEast: [north, east],
        southWest: [south, west],
      },
      {
        maxZoom: north === south && east === west ? 17 : undefined,
        padding: { bottom: 300, left: 300, right: 300, top: 300 },
        ...options,
      },
    );
  }
}
