import './FormCard.FF.scss';

/* eslint-disable  */
import { ButtonsCard } from 'app/pages/cardsOgh/components/buttonsCard/ButtonsCard';
import { ReturnBack } from 'app/pages/cardsOgh/components/buttonsCard/components/ReturnBack';
import { RightPanelCard } from 'app/pages/cardsOgh/components/RightPanelCard/RightPanelCard';
import { FinalForm } from 'core/form/finalForm/components/FinalForm';
import React from 'react';

import { useSetupFormCardFF } from './useSetup.FormCard.FF';

interface FormCardFFProps<INITIAL_VALUES = Record<string, unknown>> {
  /**
   * Функция валидации.
   */
  validate: (values: INITIAL_VALUES) => Record<string, string> | void;

  /**
   * Дочерние элементы.
   */
  children: React.ReactNode;

  /**
   * Начальное состояние.
   */
  initialValues: INITIAL_VALUES;

  /**
   * Кастомный Обработчик сабмита.
   */
  onSubmit?: (values: INITIAL_VALUES) => void;

  /**
   * Функция подготовка данных для Атрибутов.
   */
  preparingDataForAttributes: () => Record<string, unknown>;

  /**
   * Загрузка.
   */
  loading: boolean;
}

/**
 * Функция формы для карточек ОГХ с проверкой пересечений.
 *
 * @param props - Properties.
 * @param props.validate - Функция валидации.
 * @param props.children - Дочерние элементы.
 * @param props.initialValues - Начальное состояние.
 * @param props.loading - Загрузка.
 * @param props.preparingDataForAttributes - Функция подготовка данных для Атрибутов.
 * @param [props.onSubmit] - Кастомный Обработчик сабмита.
 * @returns JSX.Element.
 */
export const FormCardFF = ({
  validate,
  children,
  initialValues = {},
  loading,
  preparingDataForAttributes,
  onSubmit,
}: FormCardFFProps) => {
  const { toggleLeftPanel, isLoading, classLeftPanelContainer, submitInner } =
    useSetupFormCardFF({
      preparingDataForAttributes,
      validate,
    });

  return isLoading ? null : ( // TODO: сделать прелоадер
    <FinalForm
      onSubmit={onSubmit || submitInner}
      initialValues={initialValues || {}}
    >
      <div className={classLeftPanelContainer}>
        <div className={'left-panel__header'}>
          <div className="buttons__container">
            <ButtonsCard loading={loading} />
          </div>
          <ReturnBack />
        </div>
        <div className={'field__wrapper'}>{children}</div>
      </div>
      <RightPanelCard toggleLeftPanel={toggleLeftPanel} />
    </FinalForm>
  );
};
