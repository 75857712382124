 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */

import { YesOrNoCell } from 'core/uiKit/components/cells/YesOrNo.Cell';
 
/* eslint-disable */
/**
 * Колонка __Относится к тротуару__.
 *
 * @type {{Cell: YesOrNoCell, accessor: 'is_footway_area', Header: 'Относится к тротуару' }}
 * @augments Cell
 */
export const is_footway_area = {
  Cell: YesOrNoCell,
  accessor: 'is_footway_area',
  Header: 'Относится к тротуару',
  sortable: true,
};
