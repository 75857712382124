import {
  OGH_TYPE_SELECT_NAME,
  OghTypeSelectFF,
} from 'core/form/finalForm/fields';
import React from 'react';
import { NumberParam, withDefault } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

/**
 * Компонент OghTypeSelectFilter.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - JSX.
 * @example ----
 */
export const OghTypeSelectFilter = (props) => (
  <OghTypeSelectFF {...props} className={filterInput} />
);

export { OGH_TYPE_SELECT_NAME };

/**
 * @typedef {import('use-query-params').withDefault} withDefault
 * @typedef {import('use-query-params').NumberParam} NumberParam
 */

/**
 * QueryParam Тип обьекта.
 *
 * @type {{"[OGH_TYPE_SELECT_NAME]": NumberParam}}.
 * @example const [filters, setFilters] = useQueryParams([
 *     oghTypeSelectParams,
 *   ]);
 */
export const oghTypeSelectParams = {
  [OGH_TYPE_SELECT_NAME]: NumberParam,
};

/**
 * QueryParam Тип обьекта по умолчанию выбран ОДХ.
 *
 * @type {{"[OGH_TYPE_SELECT_NAME]": withDefault}}.
 * @example const [filters, setFilters] = useQueryParams([
 *     oghTypeSelectParams,
 *   ]);
 */
export const oghTypeSelectDefaultParams = {
  [OGH_TYPE_SELECT_NAME]: withDefault(NumberParam, 1),
};
