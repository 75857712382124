import { debounce } from 'core/utils/helpers/debounce';
import { useRef, useState } from 'react';

/* eslint-disable */

interface UseThrottleLoading {
  /**
   * Флаг лоудера.
   */
  isLoadingRender: boolean;
  /**
   * Функция с задержкой лоудер что бы отложить изменния флага как прекратиться пререрндер в течении 3 секунд.
   */
  setLoadingRender: (v: boolean) => void;
}

/**
 * Хук для управления флагом с поведением "throttle".
 * Флаг устанавливается в true методом setLoadingRender,
 * а затем автоматически сбрасывается в false через 3 секунды если нет больше рендеров,.
 *
 * @returns Текущее значение флага и функция для изменения.
 */
export const useThrottleLoading = (): UseThrottleLoading => {
  const [, set] = useState(true);
  const flagRef = useRef<boolean>(true);

  /**
   * Функция с задержкой лоудер что бы отложить изменния флага как прекратиться пререрндер в течении 3 секунд.
   *
   * @param v - Флаг лоудера.
   * @returns {void} Void.
   */
  const setLoadingRender = (v: boolean): void => {
    // HACK: Сначала длаем флаг v.
    flagRef.current = v;
    // HACK: Затем вызываем рендер.
    set(v);
  };

  as(() => {
    setLoadingRender(false);
  });
  return { isLoadingRender: flagRef.current, setLoadingRender };
};

/**
 * Выключаем лоудер.
 *
 * @param fn - Function.
 * @returns Void.
 */
const setFalse = (fn: () => void) => fn();

const as = debounce(setFalse, 1000);
