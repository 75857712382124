import { RANDOM_BOOL } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import {
  MAF_TYPE_LEVEL_2_ID_NAME,
  MafTypeLevel2IdField,
} from './MafTypeLevel2Id.Field';
import { useSetupMafTypeLevel2IdField } from './useSetup.MafTypeLevel2Id.Field';

vi.mock('./useSetup.MafTypeLevel2Id.Field');

describe('🐛 MafTypeLevel2IdFieldSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    const disabledSpy = RANDOM_BOOL;
    const filterSpy = vi.fn();
    const optionsSpy = [];
    // ☣️ Arrange (всякие моки)
    useSetupMafTypeLevel2IdField.mockReturnValue({
      disabled: disabledSpy,
      filter: filterSpy,
      options: optionsSpy,
    });
    // 🔥 Act
    const wrapper = shallow(<MafTypeLevel2IdField />);

    // ❓ Assert
    expect(wrapper.text()).toStrictEqual('<SelectRF />');
  });
  it('🧪 props', () => {
    expect.hasAssertions();
    const disabledSpy = RANDOM_BOOL;
    const filterSpy = vi.fn();
    const optionsSpy = [];
    // ☣️ Arrange (всякие моки)
    useSetupMafTypeLevel2IdField.mockReturnValue({
      disabled: disabledSpy,
      filter: filterSpy,
      options: optionsSpy,
    });
    // 🔥 Act
    const wrapper = shallow(<MafTypeLevel2IdField />);

    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      disabled: disabledSpy,
      filter: filterSpy,
      label: 'Уточнение',
      name: MAF_TYPE_LEVEL_2_ID_NAME,
      options: optionsSpy,
    });
  });
});
