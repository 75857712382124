 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Сокращенное наименование заказчика__.
 *
 * @type {{ accessor: 'parent_customer_name',  Header: 'Сокращенное наименование заказчика', sortable: true, }}
 * @augments Cell
 */
export const parent_customer_name = {
  accessor: 'parent_customer_name',
  Header: 'Сокращенное наименование заказчика',
  sortable: true,
};
