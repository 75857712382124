/* eslint-disable */
import * as api from 'app/api';
import * as types from 'app/constants/actionTypes';
import getSearchFormValues from 'app/utils/getSearchFormValues';
import $ from 'jquery';
import { actions as formActions } from 'react-redux-form';
import { createAction } from 'redux-actions';

import * as loaderActions from './loader';

export const apiGetRoleRegistry = createAction(
  types.API_GET_ROLE_REGISTRY,
  function (data) {
    return {
      promise: api.getRoleRegistry(data),
    };
  },
);

/* eslint-disable */
export function onSubmit(values) {
  return function (dispatch) {
    dispatch(loaderActions.show());
    dispatch(formActions.change('rrf.roleRegistry.page', 0));

    return dispatch(apiGetRoleRegistry(getSearchFormValues(values))).finally(
      function () {
        dispatch(loaderActions.hide());
      },
    );
  };
}

/* eslint-disable */
export function onReset() {
  return function (dispatch) {
    dispatch(formActions.change('rrf.roleRegistry.name', ''));
    dispatch(formActions.change('rrf.roleRegistry.privilege', ''));
    $('#s-privilege').val(null).trigger('change');
  };
}

/* eslint-disable */
export function onSort(values) {
  return function (dispatch) {
    dispatch(formActions.merge('rrf.roleRegistry', values));
    dispatch(formActions.submit('rrf.roleRegistry'));
  };
}

/* eslint-disable */
export function onLimit(value) {
  return function (dispatch) {
    dispatch(formActions.change('rrf.roleRegistry.maxRows', value));
    dispatch(formActions.change('rrf.roleRegistry.page', 0));
    dispatch(formActions.submit('rrf.roleRegistry'));
  };
}

/* eslint-disable */
export function onPagination(value) {
  return function (dispatch) {
    dispatch(formActions.change('rrf.roleRegistry.page', value));
    dispatch(formActions.submit('rrf.roleRegistry'));
  };
}
