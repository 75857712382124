import { REQUIRED_FIELD_MESSAGE } from 'app/constants/messages';
import { DATE_SURVEY_NAME } from 'core/form/reduxForm/fields/prepared/datePickers/DateSurvey.RF';
import moment from 'moment';

/**
 * Функция валидации паспорта ОДХ.
 *
 * @param {object} values - Значения формы.
 * @returns {*}
 */
export default function validateOdh(values = {}) {
  const passportDate = values?.passport_date;
  const dateSurvey = values?.[DATE_SURVEY_NAME];

  const error = {};

  if (values.is_orphan_object) {
    return error;
  }

  if (!dateSurvey) {
    error[DATE_SURVEY_NAME] = REQUIRED_FIELD_MESSAGE;
  }

  if (!passportDate) {
    error.passport_date = REQUIRED_FIELD_MESSAGE;
  }

  const momentPassportDate = moment(new Date(passportDate));
  const dateSurveyDate = moment(new Date(dateSurvey));

  // В поле "Дата обследования территории" должна быть возможность ввода даты >= значению, указанному в поле "Дата составления/согласования паспорта"
  if (momentPassportDate.isValid() && dateSurveyDate.isValid()) {
    const isBefore = momentPassportDate.isBefore(dateSurveyDate);
    if (isBefore) {
      error[DATE_SURVEY_NAME] =
        'Значение параметра «Дата составления/согласования паспорта» не может быть меньше значения параметра «Дата обследования территории»';
    }
  }
  return error;
}
