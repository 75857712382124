/* eslint-disable */
export function validateValidateCleanArea(formValues) {
  const stationCleanArea = formValues.station_clean_area;
  const area = formValues.area;

  if (stationCleanArea > area) {
    return {
      station_clean_area:
        'Площадь уборки не может быть больше площади остановки',
    };
  }
}
