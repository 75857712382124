import { DistrictListRF } from 'core/form/reduxForm/fields/prepared/selects/multiSelect/DistrictList.RF';
import { OKRUG_LIST_NAME } from 'core/uiKit/preparedInputs/selects/multiSelect/OkrugList';
import React from 'react';

/**
 * Компонент поля DistrictListField.
 *
 * @param {object} props - Пропсы.
 * @returns {React.ReactElement}
 */
export const DistrictListField = (props) => {
  return (
    <DistrictListRF
      disabled={!props.editable}
      required
      filter={(dict) => {
        const okrugs = props?.currentValues?.[OKRUG_LIST_NAME] || [];
        const filteredDict = dict.filter(({ okrug }) =>
          okrugs.includes(okrug[0]),
        );

        return okrugs.length && okrugs[0] !== '' ? filteredDict : dict;
      }}
    />
  );
};
