import { post } from 'app/api/crud/post';
import * as env from 'config/env';
import { handleBrowserDownload } from 'core/utils/helpers/handleBrowserDownload';
import { snakeCase } from 'lodash';

/**
 * Запрос на печать.
 *
 * @param {string} url - Url запроса.
 * @param {object} queryParams - Параметры запроса.
 * @returns {Promise<*>}
 */
const callPrint = async (url, queryParams) => {
  const res = await post(url, queryParams, {
    host: env.PRINT_API_URL,
    jsonBody: false,
    parseResponse: false,
    urlencoded: true,
  });

  return res;
};

/**
 * Печать (сама загрузка документа).
 *
 * @param {string} templateName - Url запроса.
 * @param {object} queryParams - Параметры запроса.
 * @returns {Promise<*>}
 */
export const print = async (templateName, queryParams) => {
  const arr = templateName.split('/');
  const url = arr.reduce((acc, curr) => {
    return `${acc}/${snakeCase(curr)}`;
  }, '');
  const res = await callPrint(url, queryParams);
  handleBrowserDownload(res);
  return res;
};

/**
 * Запрос на печать ОГХ.
 *
 * @param {string} templateName - Url запроса.
 * @param {object} queryParams - Параметры запроса.
 * @returns {Promise<*>}
 */
export const printOgh = async (templateName, queryParams) =>
  print(`ogh_${templateName}`, queryParams);

/**
 * Запрос на печать сравнения титульных списков.
 *
 * @param {object} params - Параметры запроса.
 * @param {object} params.queryParams - Параметры запроса.
 * @param {string} params.type - Тип огх.
 * @returns {Promise<*>}
 */
export const printTlDiff = ({ queryParams, type }) =>
  print(`${type}_tl_diff`, queryParams);
