import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { FaceAreaFF } from 'core/form/finalForm/fields/prepared/numberField/FaceArea.FF';
import { FACE_AREA_NAME } from 'core/uiKit/preparedInputs/numberField/FaceArea';
import { UNIT_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/UnitId';
import React, { useEffect } from 'react';
import { useField } from 'react-final-form';

/**
 * Select Площадь покрытия (облицовка), кв м.
 *
 * @returns {JSX.Element}
 */
export const FaceAreaFFField = () => {
  const { editMode } = useEditMode();
  const {
    input: { value: unitIdValue },
  } = useField(UNIT_ID_NAME);

  const {
    input: { onChange },
  } = useField(FACE_AREA_NAME);

  useEffect(() => {
    if (editMode && unitIdValue === 5) {
      onChange('');
    }

    // eslint-disable-next-line
  }, [unitIdValue, editMode]);

  return (
    <FaceAreaFF disabled={!(editMode && unitIdValue !== 5)} decimalScale={2} />
  );
};
