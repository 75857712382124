 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Состав оборудования, светофор пешеходный, шт.__.
 *
 * @type {{ accessor: 'traff_light_men',  Header: 'Состав оборудования, светофор пешеходный, шт.' }}
 * @augments Cell
 */
export const traff_light_men = {
  accessor: 'traff_light_men',
  Header: 'Состав оборудования, светофор пешеходный, шт.',
  sortable: true,
};
