import { useFetchOghTable } from 'app/pages/registry/ogh/api/useFetchOghTable';
import { useParamsTable } from 'core/uiKit/components/tables/Table/useParamsTable';

import { useFilterQueryParamsOtherTechPlace } from './useFilterQueryParams.OtherTechPlace';

/**
 * Компонент таблицы __Иные некапитальные объекты__.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - Id типа огх.
 * @param {number} props.parentTypeId - Id типа родителя.
 * @returns {*}
 */
export const useSetupTableOtherTechPlace = ({ typeId, parentTypeId }) => {
  const { prepareFilters } = useFilterQueryParamsOtherTechPlace();
  const { queryTable } = useParamsTable();

  const queryKey = [
    'TableOtherTechPlace',
    {
      ...queryTable,
      ...prepareFilters,
      parentTypeId,
      typeId,
    },
  ];

  const { table = [], isLoading, count } = useFetchOghTable(queryKey);

  return {
    count,
    isLoading,
    table,
  };
};
