import { del, get, post, put } from 'app/api/crud';
import { getRegistryTable } from 'app/api/requests/getRegistryTable';

/**
 * A.
 *
 * @param {object} data - A.
 * @returns {*}
 */
export const getAllPgmActualBases = (data) => getRegistryTable('ogh', data);

/**
 * A.
 *
 * @param {object} data - A.
 * @returns {*}
 */
export const fetchOperations = (data) =>
  getRegistryTable('basePgmOperations', data);

/**
 * A.
 *
 * @param {object} data - A.
 * @returns {*}
 */
export const fetchCreateOperation = (data) =>
  post('/pgm/operation', data, { parseResponse: false });

/**
 * A.
 *
 * @param {object} data - A.
 * @returns {*}
 */
export const fetchDeleteOperations = (data) =>
  del('/pgm/operation', data, { parseResponse: false });

/**
 * A.
 *
 * @param {object} data - A.
 * @returns {*}
 */
export const fetchExports = (data) =>
  getRegistryTable('basePgmDocuments', {
    ...data,
    typeCode: 'reagent_export',
  });

/**
 * A.
 *
 * @param {number} id - A.
 * @param {object} data - A.
 * @returns {*}
 */
export const fetchExport = (id, data) =>
  get(`/pgm/document/${id}`, {
    ...data,
    typeCode: 'reagent_export',
  });

/**
 * A.
 *
 * @param {object} data - A.
 * @returns {*}
 */
export const fetchCreateExport = (data) =>
  post('/pgm/document', {
    ...data,
    typeCode: 'reagent_export',
  });

/**
 * A.
 *
 * @param {object} data - A.
 * @returns {*}
 */
export const fetchUpdateExport = (data) =>
  put('/pgm/document', {
    ...data,
    typeCode: 'reagent_export',
  });

/**
 * A.
 *
 * @param {object} data - A.
 * @returns {*}
 */
export const fetchDeleteExport = (data) =>
  del('/pgm/document', {
    ...data,
    typeCode: 'reagent_export',
  });
