import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
/* eslint-disable */
import { TextField } from 'core/uiKit/inputs/TextField';

/**
 *
 * @param root0
 * @param root0.id
 * @param root0.value
 * @param root0.label
 */
const InfoTextField = ({ id, value, label }) => (
  <TextField
    className="align-top mr-3"
    disabled={true}
    id={id}
    label={label}
    value={value}
  />
);

/* eslint-disable */
export const Info = ({ balance = 0, minBalance = 0 }) => {
  const { reagentStorageCapacityList } = useGetCurrentCard();

  return (
    <div className={'container__two_column'}>
      <InfoTextField
        id="balance"
        label="Текущий объем ПГР, т."
        value={calculateBalance(reagentStorageCapacityList)}
      />
      <InfoTextField
        id="min_balance"
        label="Неснижаемый остаток, т."
        value={0}
      />
    </div>
  );
};

/* eslint-disable */
/**
 *
 * @param reagentStorageCapacityList
 * @returns {*}
 */
const calculateBalance = (reagentStorageCapacityList = []) => {
  return (reagentStorageCapacityList || []).reduce(
    (sum, item) => sum + item.quantity,
    0,
  );
};

// была вот эта формула
// calculateBalance(reagentKindId, reagentId, reagentBalanceList) {
//
//
//   let result;
//   if (reagentBalanceList) {
//     if (reagentKindId) {
//       if (reagentId) {
//         const balance = reagentBalanceList.find(
//           (item) => item.reagent_id === reagentId,
//         );
//         result = balance ? balance.quantity : 0;
//       } else {
//         result = reagentBalanceList
//           .filter((item) => item.reagent_kind_id === reagentKindId)
//           .reduce((sum, item) => sum + item.quantity, 0);
//       }
//     } else {
//       result = reagentBalanceList.reduce(
//         (sum, item) => sum + item.quantity,
//         0,
//       );
//     }
//   } else {
//     result = null;
//   }
//   return result;
// }
