import { DialogComparison } from 'app/pages/cardsOgh/components/tables/Versions/DialogComparison/DialogComparison';
import { Button } from 'core/uiKit/components/buttons/Button';
import { LinkIdCell } from 'core/uiKit/components/cells/LinkId.Cell';
import { TableSimple } from 'core/uiKit/components/tables/TableSimple';

import { useSetupTableVersions } from './useSetup.Table.Versions';

/**
 * TableVersions.
 *
 * @param {*} props - Props.
 * @param {*} props.data - Данные для таблицы.
 * @returns {JSX.Element}
 */
export const TableVersions = (props) => {
  const { data } = props;

  const {
    openDialog,
    rows,
    warningMessage,
    startComparison,
    selectedRow,
    closeDialog,
  } = useSetupTableVersions();

  return (
    <>
      {rows?.length === 2 && (
        <DialogComparison
          open={openDialog}
          closeDialog={closeDialog}
          rows={rows}
        />
      )}

      <div className={'table-card-version__wrapper'}>
        <p className={'table-card-version__message'}>{warningMessage}</p>
        <p>
          <Button
            className={'ms-2'}
            variant={'contained'}
            color={'primary'}
            onClick={startComparison}
          >
            Сравнение
          </Button>
        </p>
      </div>
      <div className="d-flex flex-column h-100 relative">
        <TableSimple
          selectedRows={selectedRow}
          checkboxSelection
          columns={columns}
          data={data?.table || []}
        />
      </div>
    </>
  );
};

const columns = [
  {
    Cell: LinkIdCell,
    Header: 'ID версии объекта',
    accessor: 'id',
  },
  {
    Header: 'Наименование',
    accessor: 'name',
  },
  {
    Header: 'Дата начала',
    accessor: 'start_date',
  },
  {
    Header: 'Дата окончания',
    accessor: 'end_date',
  },
  {
    Header: 'Балансодержатель',
    accessor: 'owner_name',
  },
  {
    Header: 'Заказчик',
    accessor: 'customer_name',
  },
  {
    Header: 'Статус',
    accessor: 'object_status_name',
  },
  {
    Header: 'Причины утверждения (утверждение ЭП)',
    accessor: 'reason_list_final',
  },
  {
    Header: 'Причины изменения',
    accessor: 'reason_list',
  },
  {
    Header: 'Комментарий',
    accessor: 'change_comment',
  },
  {
    Header: 'Экспорт в ОДОПМ',
    accessor: 'date_send_odopm',
  },
];
