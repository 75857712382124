/* eslint-disable  */

/**
 *
 * @param dict
 */
export default (dict) => {
  if (dict && Array.isArray(dict)) {
    return dict.reduce((acc, item) => {
      acc[item.id] = item.code;
      return acc;
    }, {});
  }
};
