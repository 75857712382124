import { ContinueFFDialog } from 'core/form/finalForm/components/dialogs/ContinueFF.Dialog';
import { toast } from 'core/uiKit/components/Toast';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

import { externalAccept } from './api';
import { BodyDialogAccept } from './Body.Dialog.Accept';

/**
 * Диалоговое окно кнопки Согласовать.
 *
 * @param {object} props - Пропсы.
 * @param {boolean} props.isOpen - Флаг отображения диалогового окна.
 * @param {Function} props.onClose - Функция закрыть окно.
 * @returns {React.ReactElement}
 */
export const DialogAccept = ({ isOpen, onClose = (_) => _ }) => {
  const { objectId } = useGetObjectId();

  const [send, { isLoading }] = useMutationAdaptor(externalAccept, {

    /**
     * Ошибочный кейс.
     *
     * @param {object} value - Response.
     */
    onError: (value) => {
      toast.error(value.message);
      onClose();
    },

    /**
     * Успешный кейс.
     *
     * @param {object} params - Сообщение.
     * @param {string} params.message - Сообщение.
     */
    onSuccess: ({ message }) => {
      switch (message) {
        case 'Ожидаем одобрения второго участника': {
          toast.success(
            'Ваше согласование зафиксировано. Для перевода в статус «Согласован с внешней системой» необходимо получить согласование второго участника процесса.',
          );
          break;
        }
        case 'Объект согласован': {
          toast.success(
            'Уведомление будет отправлено пользователю, создавшему объект',
          );
          break;
        }
        default: {
          toast.success(message);
        }
      }

      onClose();
    },
  });

  /**
   * Функция сабмита.
   *
   * @param {object} value - Значения формы.
   * @returns {*}
   */
  const onSubmit = (value) => send({ ...value, objectId });

  return (
    <ContinueFFDialog
      {...{
        Body: <BodyDialogAccept />,
        closeDialog: onClose,
        isLoading,
        isOpen,
        onSubmit,
        textHeader: 'Согласовать проект, созданный внешней системой',
      }}
    />
  );
};
