import { useGetRegistryApprovalsTechTable } from 'app/components/card/tech/car/components/Tables/ProcessApprovingTable/api';
/* eslint-disable  */
import { useFilterQueryParamsProcessApproving } from 'app/components/card/tech/car/components/Tables/ProcessApprovingTable/ProcessApprovingTable.Filter';
import {
  RANDOM_BOOL,
  RANDOM_ID,
  RANDOM_NUMBER,
} from 'core/forTesting/constants';
import { useParamsTable } from 'core/uiKit/components/tables/Table/useParamsTable';

import { UseGetDataApprovalsTechTable } from './useGetDataApprovalsTechTable';

jest.mock(
  'app/components/card/tech/car/components/Tables/ProcessApprovingTable/api',
);
jest.mock(
  'app/components/card/tech/car/components/Tables/ProcessApprovingTable/ProcessApprovingTable.Filter',
);
jest.mock('core/uiKit/components/tables/Table/useParamsTable');
jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),

  /**
   *
   */
  useSelector: () => 435234,
}));

describe('title useGetDataApprovalsTechTable.spec', () => {
  it('called with params useGetRegistryApprovalsTechTable', () => {
    expect.hasAssertions();
    const ID = RANDOM_ID;
    const TABLE = [
      { id: 'Таблица 1' },
      { id: 'Таблица 2' },
      { id: 'Таблица 3' },
    ];
    const MAX_ROWS = RANDOM_NUMBER;
    const PAGE = RANDOM_NUMBER;
    const TOTAL = RANDOM_NUMBER;
    // Arrange (всякие моки)
    useParamsTable.mockReturnValue({
      queryTable: { maxRows: MAX_ROWS, page: PAGE },
    });
    useFilterQueryParamsProcessApproving.mockReturnValue({
      prepareFilters: { id: ID },
    });
    useGetRegistryApprovalsTechTable.mockReturnValue({
      data: {
        data: { meta: { total: TOTAL }, table: TABLE },
      },
    });
    // Act

    UseGetDataApprovalsTechTable();
    // Assert

    expect(useGetRegistryApprovalsTechTable).toHaveBeenCalledTimes(1);
    expect(useGetRegistryApprovalsTechTable).toHaveBeenLastCalledWith({
      id: RANDOM_ID,
      maxRows: MAX_ROWS,
      page: PAGE,
      root_id: 435234,
    });
  });
  it('return UseGetDataApprovalsTechTable', () => {
    expect.hasAssertions();
    const ID = RANDOM_ID;
    const TABLE = [
      { id: 'Таблица 1' },
      { id: 'Таблица 2' },
      { id: 'Таблица 3' },
    ];
    const MAX_ROWS = RANDOM_NUMBER;
    const PAGE = RANDOM_NUMBER;
    const TOTAL = RANDOM_NUMBER;
    const LOADING = RANDOM_BOOL;
    // Arrange (всякие моки)
    useParamsTable.mockReturnValue({
      queryTable: { maxRows: MAX_ROWS, page: PAGE },
    });
    useFilterQueryParamsProcessApproving.mockReturnValue({
      prepareFilters: { id: ID },
    });
    useGetRegistryApprovalsTechTable.mockReturnValue({
      data: {
        data: { meta: { total: TOTAL }, table: TABLE },
      },
      isLoading: LOADING,
    });
    // Act

    // Assert
    expect(UseGetDataApprovalsTechTable()).toStrictEqual({
      isLoading: LOADING,
      table: TABLE,
      total: TOTAL,
    });
  });
});
