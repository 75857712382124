import { Cell } from 'types/table';
/* eslint-disable  */

/**
 * Колонка __Статус адреса БТИ__.
 *
 * @type {{ accessor: 'is_actual',  Header: 'Статус адреса БТИ', sortable: true, }}
 */
export const is_actual: Cell<
  ({ value }?: { value?: boolean | null }) => 'актуален' | 'не актуален' | ''
> = {
  /**
   *
   * @param root0
   * @param root0.value
   */
  Cell: ({ value } = {}) => {
    switch (value?.toString()) {
      case 'true':
        return 'актуален';
      case 'false':
        return 'не актуален';
    }

    return '';
  },
  Header: 'Статус адреса БТИ',
  accessor: 'is_actual',
  sortable: true,
};
