import { VERTICAL_LANDSCAPING_CLASS_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/VerticalLandscapingClassTypeId';
import React from 'react';
import { Field } from 'react-final-form';

import { VerticalLandscapingClassTypeIdWithFf } from './VerticalLandscapingClassTypeIdWithFf';

/**
 * ## Детализация Select FF.
 *
 * @param {object} props - Пропсы.
 * @param {string} [props.className] - Класс.
 * @returns {JSX.Element}
 */
export const VerticalLandscapingClassTypeIdFF = (props) => {
  return (
    <Field
      {...props}
      name={VERTICAL_LANDSCAPING_CLASS_TYPE_ID_NAME}
      component={VerticalLandscapingClassTypeIdWithFf}
    />
  );
};
