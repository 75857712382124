import camelCase from 'lodash/camelCase';

/**
 * Переводит все названия свойств в camelCase.
 *
 * @deprecated
 * @see src/app/utils/turnIntoCamelCase
 * @param {object | string | Array<object> | Array<string>} param - Параметр.
 * @returns {{}|*}
 */
export const turnIntoCamelCase = (param) => {
  if (Array.isArray(param)) {
    return param.map((item) => turnIntoCamelCase(item));
  }
  const camelCaseParams = {};
  for (const key in param) {
    camelCaseParams[camelCase(key)] = param[key];
  }

  return camelCaseParams;
};
