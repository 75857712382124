/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

 
export class DropDown extends React.Component {
  static propTypes = {
    id: PropTypes.string,
  };

  componentDidMount() {
    const dropdown = $('#' + this.props.id + ' .dropdown-content');
    $('#' + this.props.id).hover(
      () => dropdown.show(),
      () => dropdown.hide(),
    );
    dropdown.hover(
      () => dropdown.show(),
      () => dropdown.hide(),
    );
  }
}
