import './certInfo.scss';

import cn from 'classnames';
import { Button } from 'core/uiKit/components/buttons/Button';
import { Paper } from 'core/uiKit/components/Paper';
import { ExpandLessIcon, ExpandMoreIcon } from 'core/uiKit/material-ui';
import React, { useState } from 'react';

/**
 * Компонент CertInfo.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.certificate - Сертификат.
 * @returns {JSX.Element}
 */
export const CertInfo = ({ certificate = {} }) => {
  const [isShow, setShow] = useState(false);

  const {
    subjectInfo,
    issuerInfo,
    providerName,
    uniqueContainerName,
    uniqueContainerNameError,
    algorithmName,
    validPeriod: { from, to } = {},
    status,
    statusError,
  } = certificate;

  /**
   * Функция toggleShow.
   *
   * @returns {void}
   */
  const toggleShow = () => {
    setShow(!isShow);
  };

  return (
    <Paper>
      <div className="cert-info">
        <div className={'display-flex justify-content-between'}>
          <h5 className={'cert-info__header'}>Информация о сертификате</h5>
          <Button
            endIcon={isShow ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            onClick={toggleShow}
            size={'small'}
          >
            {isShow ? 'Скрыть' : 'Показать'}
          </Button>
        </div>
        {isShow ? (
          <div className={'cert-info__container'}>
            <label className={'cert-info__prefix'}>Владелец:</label>
            <label>{subjectInfo}</label>
            <label className={'cert-info__prefix'}>Издатель:</label>
            <label>{issuerInfo}</label>
            <label className={'cert-info__prefix'}>Выдан:</label>
            <label>{from}</label>
            <label className={'cert-info__prefix'}>Действителен до:</label>
            <label>{to}</label>
            <label className={'cert-info__prefix'}>Криптопровайдер:</label>
            <label>{providerName}</label>

            <label className={'cert-info__prefix'}>
              Ссылка на закрытый ключ:
            </label>
            <label className={cn({ 'text-danger': uniqueContainerNameError })}>
              {uniqueContainerName}
            </label>

            <label className={'cert-info__prefix'}>Алгоритм ключа:</label>
            <label>{algorithmName}</label>
            <label className={'cert-info__prefix'}> Статус:</label>
            <label className={cn({ 'text-danger': statusError })}>
              {status}
            </label>
          </div>
        ) : null}
      </div>
    </Paper>
  );
};
