import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { OZN } from 'app/constants/oghTypes';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import { CheckboxLandscapingMark } from './CheckboxLandscapingMark';
vi.mock('app/api/hooks/useGetCurrentCard');

describe('CheckboxLandscapingMarkSpec', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useGetCurrentCard.mockReturnValue({ typeId: OZN });
    // 🔥 Act

    const wrapper = shallow(<CheckboxLandscapingMark />);
    // ❓ Assert
    expect(wrapper.text()).toBe('<LandscapingMark />');
  });
  it('props landscaping_mark: null', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useGetCurrentCard.mockReturnValue({
      attribute: { landscaping_mark: null },
      typeId: OZN,
    });
    // 🔥 Act

    const wrapper = shallow(<CheckboxLandscapingMark />);
    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      disabled: true,
      value: false,
    });
  });
  it('props landscaping_mark true', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useGetCurrentCard.mockReturnValue({
      landscaping: [{ landscaping_mark: true }],
      typeId: OZN,
    });
    // 🔥 Act

    const wrapper = shallow(<CheckboxLandscapingMark />);
    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      disabled: true,
      value: true,
    });
  });
  it('not typeId', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useGetCurrentCard.mockReturnValue({});
    // 🔥 Act

    const wrapper = shallow(<CheckboxLandscapingMark />);
    // ❓ Assert
    expect(wrapper.text()).toBe('');
  });
});
