/* eslint-disable */
import React from 'react';
import DndFileUpload from 'app/components/common/DndFileUpload';

const dndFileUploadStyle = {
  float: 'left',
  marginLeft: '24px',
  marginRight: '24px',
};

 
export default class FileUpload extends React.Component {
  onAdd = (files) => {
    this.props.input.onChange(files[0]);
  };

  onRemove = () => {
    this.props.input.onChange(null);
  };

  render() {
    return (
      <DndFileUpload
        add={this.onAdd}
        files={this.props.input.value}
        multiple={false}
        remove={this.onRemove}
        style={dndFileUploadStyle}
        text="Переместите файл технического задания / проекта благоустройства или нажмите для выбора"
      />
    );
  }
}
