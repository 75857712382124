import { TotalAreaGeo } from 'core/uiKit/preparedInputs/textField/TotalAreaGeo';
import React from 'react';

 
/**
 * Комопнент обертка TotalAreaGeoWithFF для Final Form.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.input - Пропсы поля.
 * @param {object} props.otherProps - Остальные пропсы.
 * @returns {JSX.Element} - Возвращает компонет TotalAreaGeo.
 * @example <Field
 *       name={TOTAL_AREA_GEO_NAME}
 *       component={TotalAreaGeoWithFF}
 *       {...otherProps}
 *     />
 */
export const TotalAreaGeoWithFF = ({
  input,
  ...otherProps
}) => {
  return <TotalAreaGeo {...input} {...otherProps} />;
};
