import { get } from 'app/api/crud/get';

/**
 * Функция получения ведомостей.
 *
 * @param {object} object - Параметры.
 * @param {string} object.id - Id паспорта.
 * @param {object} object.params - Остальные параметры.
 * @param {string} object.key - Ключ паспорта.
 * @returns {Promise<*>|Array}
 */
export const fetchList = ({ id, key, ...params } = {}) => {
  if (!id) return [];
  return get(`ogh/${id}/${key}`, params);
};
