import { print } from 'app/api/requests/print';
import { detailing } from 'app/components/card/ogh/ImprovementTerritoryCard/components/EquipmentIndex/detailing';
import { RANDOM_ID, RANDOM_NUMBER } from 'core/forTesting/constants';
import { vi } from 'vitest';

vi.mock('app/api/requests/print');

describe('DetailingSpec', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act
    detailing({ id: RANDOM_ID, typeId: RANDOM_NUMBER });

    // ❓ Assert
    expect(print).toHaveBeenCalledWith('equipment', {
      id: RANDOM_ID,
      typeId: RANDOM_NUMBER,
    });
  });
});
