import { Body, Header, Main, RegistryToolbar } from 'app/pages/components';
import { Skeleton } from 'core/uiKit/components/skeletons/Skeleton';
import { LinearProgress } from 'core/uiKit/material-ui';
import React from 'react';

import { FilterSkeleton } from './Filter.Skeleton';

/**
 * Компонент RegistrySkeleton.
 *
 * @returns {JSX.Element}
 */
export const RegistrySkeleton = () => {
  return (
    <Main>
      <LinearProgress />
      <Header Filter={FilterSkeleton} />
      <Body>
        <RegistryToolbar showToggle={true}></RegistryToolbar>

        <div className={'d-flex h-100 overflow-hidden-x'}>
          <Skeleton variant="rect" width={350} height={200} />
          <div
            className={'d-flex flex-column h-100 overflow-hidden w-100 ml-2'}
          >
            <div
              className={
                'd-flex flex-column h-100 relative common-table-multiTable_multiTable_3fYcL'
              }
            >
              <Skeleton
                variant="rect"
                width={'100%'}
                height={50}
                className={'mb-1'}
              />
              <Skeleton variant="rect" width={'100%'} height={300} />
            </div>
          </div>
        </div>
      </Body>
    </Main>
  );
};
