import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import {
  addPreparation,
  removePreparation,
} from 'core/utils/hooks/usePreparation/preparation';
import { useEffect } from 'react';

export const VALUABLE_PLANTS_ID_NAME = 'valuable_plants_id';

/**
 * Особо ценные.
 *
 * @param {*} props - Properties.
 * @returns {JSX.Element}
 */
export const ValuablePlantsId = (props) => {
  usePreparation();
  const dict = 'valuablePlants';

  const { data: options = [] } = useGetDictionary(dict);

  return (
    <Select
      options={options.map((item) => ({
        id: item.id,
        name: item.attribute.name,
      }))}
      label={'Особо ценные'}
      {...props}
      withAll={false}
      name={VALUABLE_PLANTS_ID_NAME}
    />
  );
};

/**
 * Fn.
 *
 * @returns {void}
 */
const usePreparation = () => {
  useEffect(() => {
    addPreparation(VALUABLE_PLANTS_ID_NAME, (value) => {
      if (value) {
        return { valuable_plants: value };
      }
    });
    return () => {
      removePreparation(VALUABLE_PLANTS_ID_NAME);
    };
  }, []);
};
