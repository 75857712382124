import { useFieldRF } from 'core/form/reduxForm/hooks/useField.RF';
import { RANDOM_BOOL, RANDOM_ID, RANDOM_WORD } from 'core/forTesting/constants';
import { toast } from 'core/uiKit/components/Toast';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery/useMutationAdaptor';
import { vi } from 'vitest';

import { useSendRepairsInfoListPlan } from './useSendRepairsInfoListPlan';

vi.mock('core/utils/hooks/useReactQuery/useMutationAdaptor');
vi.mock('core/form/reduxForm/hooks/useField.RF');
vi.mock('app/utils/date/toDate', () => ({
  //

  /**
   * Мок toDate.
   *
   * @param {*} time - Дата, время.
   * @returns {*}
   */
  toDate: (time) => time,
}));

/**
 * Mock Default.
 *
 * @returns {void}
 */
const mockDefault = () => {
  const sendSubmit = vi.fn();
  useMutationAdaptor.mockReturnValue([sendSubmit, { isLoading: RANDOM_BOOL }]);
  useFieldRF.mockReturnValue({});
};
describe('useSendRepairsInfoListPlan', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    mockDefault();
    const sendSubmit = vi.fn();
    useMutationAdaptor.mockReturnValue([
      sendSubmit,
      { isLoading: RANDOM_BOOL },
    ]);
    // 🔥 Act

    // ❓ Assert
    expect(
      useSendRepairsInfoListPlan({
        closeDialog: vi.fn(),
        onChange: vi.fn(),
        value: {},
      }),
    ).toStrictEqual({
      isLoading: RANDOM_BOOL,
      sendSubmit,
    });
  });

  it('onSuccess', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    mockDefault();
    const onChange = vi.fn();
    useMutationAdaptor.mockImplementation((fn, { onSuccess }) => {
      onSuccess({
        date_end: RANDOM_WORD,
        date_start: RANDOM_WORD,
        file: {
          name: 'Имя нового файла',
        },
        name: RANDOM_WORD,
        property: RANDOM_WORD,
        resFile: {
          fileId: RANDOM_ID,
        },
      });
      return [vi.fn(), { isLoading: false }];
    });

    useFieldRF.mockReturnValue({
      onChange,
      value: [{ date_end: RANDOM_WORD, date_start: RANDOM_WORD }],
    });

    // 🔥 Act
    useSendRepairsInfoListPlan({
      closeDialog: vi.fn(),
    });
    // ❓ Assert
    expect(onChange).toHaveBeenCalledWith([
      {
        date_end: RANDOM_WORD,
        date_start: RANDOM_WORD,
      },
      {
        date_end: RANDOM_WORD,
        date_start: RANDOM_WORD,
        file_list: [
          {
            files: {
              file_id: RANDOM_ID,
              file_name: 'Имя нового файла',
            },
          },
        ],
        id: expect.any(Number),
        name: RANDOM_WORD,
        property: RANDOM_WORD,
      },
    ]);
  });

  it('onError', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    mockDefault();
    const errorDyingSpy = vi.spyOn(toast, 'errorDying');
    useMutationAdaptor.mockImplementation((fn, { onError }) => {
      onError({});
      return [vi.fn(), { isLoading: false }];
    });
    // 🔥 Act
    useSendRepairsInfoListPlan({});
    // ❓ Assert
    expect(errorDyingSpy).toHaveBeenCalledWith('Ошибка при добавлении данных');
  });
});
