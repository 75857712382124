import './AddButton.scss';

import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { Button } from 'core/uiKit/components/buttons/Button';
import { Link } from 'core/uiKit/components/Link';
import { Icon } from 'core/uiKit/material-ui';
import { useGetParamsUrl } from 'core/utils/hooks/useGetParamsUrl';
import React, { FC } from 'react';

import { useSetupAddButton } from './useSetup.AddButton';

interface AddButtonProps {

  /**
   * Flag active button.
   */
  activeButton: boolean;

  /**
   * Code type.
   */
  typeCode: string;

  /**
   * Id типа.
   */
  typeId: number | string;
}

/**
 * Кнопка добавления .
 *
 * @param props - Propertyes.
 * @param props.activeButton - Active Button.
 * @param props.typeCode - Code type.
 * @param props.typeId - Code id.
 * @returns {JSX|null}
 */
export const AddButton: FC<AddButtonProps> = ({
  activeButton,
  typeId,
  typeCode,
}) => {
  const { isShowAdd } = useSetupAddButton({ activeButton, typeCode });
  const { parentId } = useGetParamsUrl();

  const parentCard = useGetParentCard();
  const startDate = parentCard.startDate?.replaceAll('.', '');

  return isShowAdd ? (
    <Button
      // @ts-ignore
      component={Link}
      to={`/ogh/${parentId}/create/${typeId}/${startDate}?edit=1`}
      type={'button'}
      startIcon={<Icon>library_add</Icon>}
      color={'success'}
      variant={'contained'}
      className="edit"
    >
      Добавить
    </Button>
  ) : null;
};
