 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Конец, м__.
 *
 * @type {{  accessor: 'bord_end',  Header: 'Конец, м'}}
 * @augments Cell
 */
export const bord_end = {
  accessor: 'bord_end',
  Header: 'Конец, м',
  sortable: true,
};
