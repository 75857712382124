import { OZN, YARD } from 'app/constants/oghTypes';
import { RANDOM_ID } from 'core/forTesting/constants';
import { vi } from 'vitest';

import { getHintTotalCleanArea } from './hint.totalCleanArea';

vi.mock('app/utils/createNumberedList', () => ({

  /**
   * Мок функции.
   *
   * @param {object} obj - Объект.
   * @returns {object}
   */
  createNumberedList: (obj) => obj,
}));

describe('HintTotalCleanArea', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act

    // ❓ Assert
    expect(getHintTotalCleanArea(RANDOM_ID)).toStrictEqual({
      header: 'Рассчитывается как сумма значений',
      list: [
        {
          children: [
            '"Плоскостные сооружения".',
            '"Дорожно-тропиночные сети"',
            '"Закрытые велопарковки"',
            '"Места сборов отходов"',
            '"Газоны"',
          ],
          item: '"Площадь, кв.м."',
        },
        {
          children: ['"ИОКС типа Отмостка"'],
          item: '"Площадь, кв.м."',
        },
        {
          children: ['"Цветники"'],
          item: '"Общая площадь цветника, кв.м"',
        },
        {
          children: [
            'Элементы организации рельефа типа Лестницы',
            'Элементы благоустройства для маломобильных групп населения типа Пандус',
          ],
          item: 'Количество, кв.м.',
        },
      ],
    });
  });
  it('typeId OZN', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act

    // ❓ Assert
    expect(getHintTotalCleanArea(OZN)).toStrictEqual({
      header:
        'Рассчитывается, как сумма площадей ручной и механизированной уборки по следующим группам элементов',
      list: [
        {
          children: ['Отмостка'],
          item: '«Уборочная площадь объектов капитального строительства, кв.м.»',
        },
        {
          children: [
            'Автопарковка',
            'Детская площадка',
            'Площадка для пожарной техники',
            'Площадка для выгула животных',
            'Площадка для отдыха',
            'Смотровая площадка',
            'Площадка иного типа',
            'Спортивная площадка',
            'Техническая площадка',
            'Экопарковка',
            'Ролледром',
            'Скейтпарк',
            'Амфитеатр',
            'Уличный вольер',
            'Велопарковка открытого типа',
          ],
          item: '«Уборочная площадь плоскостных сооружений, кв.м.»',
        },
        {
          children: [
            'Контейнерная площадка',
            'Бункерная площадка',
            'Площадка стационарного павильона для РСО',
          ],
          item: '«Уборочная площадь мест накопления отходов, кв.м.»',
        },
        {
          children: [
            'Проезд',
            'Тротуар',
            'Технический (технологический) тротуар',
            'Пешеходная дорожка',
            'Беговая дорожка',
            'Велосипедная дорожка',
          ],
          item: '«Уборочная площадь дорожно-тропиночной сети, кв.м»',
        },
        {
          children: [
            'Газон обыкновенный',
            'Газон луговой, разнотравный',
            'Газон партерный',
            'Газон на откосе',
            'Незапечатанная поверхность без дернового покрытия',
          ],
          item: '«Уборочная площадь газонов, кв.м.»',
        },
        {
          children: [
            'Однолетники',
            'Многолетники и розы',
            'Двулетники (виола)',
            'Луковичные и клубнелуковичные кроме тюльпанов, нарциссов',
            'Тюльпаны, нарциссы',
            'Инертный материал (отсыпка)',
            'Декоративные кустарники (как элемент цветника)',
            'Газоны (как элемент цветника)',
          ],
          item: '«Уборочная площадь цветников, кв.м.»',
        },
        {
          children: [
            'Общая площадь посадочных мест в элементах цветочного оформления',
            'Общая площадь посадочных мест в элементах озеленения',
          ],
          item: '«Уборочная площадь посадочных мест в элементах вертикального озеленения, кв.м»',
        },
        {
          children: ['Пандус'],
          item: '«Уборочная площадь элементов благоустройства для маломобильных групп населения, кв.м»',
        },
        {
          children: ['Лестница'],
          item: '«Уборочная площадь элементов организации рельефа, кв.м»',
        },
        {
          children: ['Лоток водосточный', 'Желоб ливневой'],
          item: '«Уборочная площадь элементов СФО, кв.м»',
        },
        {
          children: [
            'Фонтан',
            'Пирс (причал)',
            'Сухой фонтан',
            'Пешеходный мост',
            'Бассейн',
            'Променад',
          ],
          item: '«Уборочная площадь инженерных сооружений, кв. м»',
        },
        {
          children: [
            'Открытый с искусственным льдом',
            'Открытый с естественным льдом',
          ],
          item: '«Уборочная площадь Катков, кв.м»',
        },
      ],
    });
  });
  it('typeId YARD', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act

    // ❓ Assert
    expect(getHintTotalCleanArea(YARD)).toStrictEqual({
      header: 'Рассчитывается как сумма значений',
      list: [
        {
          children: [
            '"Плоскостные сооружения".',
            '"Дорожно-тропиночные сети"',
            '"Закрытые велопарковки"',
            '"Места сборов отходов"',
            '"Газоны"',
            {
              children: ['Пешеходный мост', 'Пирс', 'Cухой фонтан'],
              item: '"Инженерные сооружения"',
            },
          ],
          item: '"Площадь, кв.м."',
        },
        {
          children: ['"ИОКС типа Отмостка"'],
          item: '"Площадь, кв.м."',
        },
        {
          children: ['"Цветники"'],
          item: '"Общая площадь цветника, кв.м"',
        },
        {
          children: [
            'Элементы организации рельефа типа Лестницы',
            'Элементы благоустройства для маломобильных групп населения типа Пандус',
          ],
          item: 'Количество, кв.м.',
        },
      ],
    });
  });
});
