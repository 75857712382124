import withDarkTheme from 'core/hocs/withDarkTheme';

import Select, { action as selectAction, onChangeWith } from './Select';

const DarkSelect = withDarkTheme(Select);

/* eslint-disable */
export { selectAction, onChangeWith, DarkSelect };

 
export default Select;
