 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Возраст, лет__.
 *
 * @type {{
 * accessor: 'age',
 * Header: 'Возраст, лет',
 * sortable: true,
 * }}
 * @augments Cell
 */
export const age = {
  accessor: 'age',
  Header: 'Возраст, лет',
  sortable: true,
};
