import qs from 'query-string';

/**
 * Method getUrlWithParams.
 *
 * @param {string} host - Хост (начальный урл).
 * @param {string} methodUrl - Метод  запроса он же урл.
 * @param {object?} [queryParams] - Параметры.
 * @returns {string}
 */
export const getUrlWithParams = (host, methodUrl, queryParams) => {
  const processedMethodUrl = methodUrl.replace(/^[^/]/, (sl) => `/${sl}`);
  const url = `${host}${processedMethodUrl}`;
  return queryParams ? `${url}?${qs.stringify(queryParams)}` : url;
};
