import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useMapContext } from 'app/components/map/useMapContext';
import { useState } from 'react';

import { useGetDistrictBoundariesDTW } from './useGetDistrictBoundaries.DTW';

/**
 * Компонент кнопки для edit панели в списке объектов для DTW.
 *
 * @returns Методы.
 */
export const useSetupShowDistrictBoundariesDTW = () => {
  const { getGeometry, mapService } = useMapContext();
  const [hasDistrictBoundaries, setHasDistrictBoundaries] =
    useState<boolean>(false);

  const { getDistrictBoundaries } = useGetDistrictBoundariesDTW(
    // @ts-ignore
    setHasDistrictBoundaries,
  );
  const { recordId } = useGetCurrentCard();

  /**
   * Функция переключения ToggleButton из неактивновго в активное и наоборот.
   *
   * @returns {void} - Nothing.
   * @example <ToggleButton
   *       active={hasDistrictBoundaries}
   *       onClick={checkDistrictBoundaries}
   *     />
   */
  const checkDistrictBoundaries = () => {
    if (hasDistrictBoundaries) {
      setHasDistrictBoundaries(false);
      mapService?.updateGeometriesData({
        districts: [],
      });
      mapService?.drawGeometries({ method: 'replaceAll' });
    } else {
      const geometries = getGeometry(recordId);

      const data = {
        geometry: {
          lines: JSON.stringify(geometries.lines),
          points: JSON.stringify(geometries.points),
          polygons: JSON.stringify(geometries.polygons),
        },
      };
      getDistrictBoundaries(data);
    }
  };

  return {
    checkDistrictBoundaries,
    hasDistrictBoundaries,
  };
};
