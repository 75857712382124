// 16 Прочие плоскостные элементыimport CardAbstract from 'app/components/card/common/CardAbstract';
import CardAbstract from 'app/components/card/common/CardAbstract';
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import validate16 from 'app/components/card/common/validation/validate16';
import { IsDiffHeightMarkField } from 'app/components/card/ogh/components/fields/checkBoxFields/IsDiffHeightMark.Field';
import { AreaField } from 'app/components/card/ogh/components/fields/numberFields/Area.Field';
import { BordBeginField } from 'app/components/card/ogh/components/fields/numberFields/BordBegin.Field';
import { BordEndField } from 'app/components/card/ogh/components/fields/numberFields/BordEnd.Field';
import { CoatingGroupIdField } from 'app/components/card/ogh/components/fields/selectFields/CoatingGroupId.FIeld';
import { OdhSideIdField } from 'app/components/card/ogh/components/fields/selectFields/OdhSideId.Field';
import { DescriptionField } from 'app/components/card/ogh/components/fields/textFields/Description.Field';
import { OdhAxisField } from 'app/components/card/ogh/components/fields/textFields/OdhAxis.Field';
import {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import {
  AUTO_CLEANING_TYPE,
  cleaningTypesWithNoCleaning,
  MANUAL_CLEANING_TYPE,
  NO_CLEANING_TYPE,
} from 'app/constants/cleaningTypes';
import getCleaningType from 'app/selectors/card/getCleaningType';
import getCoatingTypes from 'app/selectors/card/getCoatingTypes';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import { isThisViewing } from 'app/selectors/card/isThisViewing';
import getFormValues from 'app/selectors/form/getFormValues';
import filterDictBySprNumber from 'app/utils/card/filterDictBySprNumber';
import isMandatory, { mandatoryFields } from 'app/utils/card/isMandatory';
import { transformValues } from 'app/utils/form/transformation';
import { CheckboxRF } from 'core/form/reduxForm/fields';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import { OtherDocumentsRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/OtherDocuments.RF';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/textField/TotalAreaGeo.RF';
import { CardContainer } from 'core/uiKit/components/card.components/CardContainer';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { GroupContainer } from 'core/uiKit/components/card.components/GroupContainer';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';

import printOghCardHeader from './header/oghHeader';
import submitCard from './submit';
import VersionsTemplate from './versions/VersionsTemplate';

const types = ['other_flat_building'];
const Versions = VersionsTemplate(types);

const tabs = [
  { id: 'characteristics-tab', name: 'Исходные данные' },
  { id: 'docs-tab', name: 'Документы' },
];

const propTypes = {
  card: PropTypes.object,
  changeFieldValue: PropTypes.func,
  coatingTypes: PropTypes.array,
  createMode: PropTypes.bool,
  documents: PropTypes.array,
  editMode: PropTypes.bool,
  formValues: PropTypes.object,
  mode: PropTypes.object,
  required: PropTypes.bool,
};

/**
 * Классовый компонет OtherFlatBuildingCard.
 */
class OtherFlatBuildingCard extends React.Component {

  /**
   * Метод getFlatElementTypeOptions.
   *
   * @param {number} flat_element_type_id - Жопа.
   * @returns {*} - Жопа.
   * @example ----
   */
  getFlatElementTypeOptions(flat_element_type_id) {
    const { type_id, flat_element_type, viewing } = this.props.card;

    if (viewing) {
      return flat_element_type || [];
    }
    const options = filterDictBySprNumber(flat_element_type, type_id);

    if (!options.includes(flat_element_type)) {
      const option = flat_element_type.find(
        (item) => item.id === flat_element_type_id,
      );

      if (option && !options.find(({ id }) => id === option.id)) {
        options.push(option);
      }
    }

    return options || [];
  }

  /**
   * Метод жизненого цикла render().
   *
   * @returns {JSX.Element} - Реакт компонент.
   * @example ----
   */
  render() {
    const {
      card,
      mode,
      createMode,
      editMode,
      formValues,
      coatingTypes,
      required,
      changeFieldValue,
      isEditCurrentObjectIsExternalSystem,
    } = this.props;

    return (
      <CardContainer>
        <CardAbstract>
          <div id="editor-card">
            {printOghCardHeader(card, mode)}
            {card && objectTabs(card.type_id)}

            <div id="props">
              <FieldsSplitColumns>
                <SelectRF
                  disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
                  options={this.getFlatElementTypeOptions(
                    formValues.flat_element_type_id,
                  )}
                  name={'flat_element_type_id'}
                  label={'Тип'}
                  required={isMandatory(
                    card.type_id,
                    'flat_element_type_id',
                    required,
                  )}
                />
                <IsDiffHeightMarkField />
                <ParentInfo />
              </FieldsSplitColumns>
              <Tabs tabs={tabs} />

              <div id="characteristics">
                <GroupContainer title={'Местоположение'}>
                  <FieldsSplitColumns>
                    <OdhAxisField />
                    <OdhSideIdField />
                    <BordBeginField />
                    <BordEndField />
                  </FieldsSplitColumns>
                </GroupContainer>

                <GroupContainer title={'Характеристики'}>
                  <FieldsSplitColumns>
                    <CoatingGroupIdField
                      withChange={() => {
                        changeFieldValue('coating_type_id', null);
                      }}
                    />
                    <SelectRF
                      label={'Вид покрытия (уточнение)'}
                      options={coatingTypes}
                      name={'coating_type_id'}
                      disabled={
                        !(
                          editMode &&
                          get(formValues, 'coating_group_id') &&
                          isEditCurrentObjectIsExternalSystem
                        )
                      }
                      required={isMandatory(
                        card.type_id,
                        'coating_type_id',
                        required,
                      )}
                    />
                    <AreaField />
                    <TotalAreaGeoRF disabled={true} decimalScale={2} />
                  </FieldsSplitColumns>
                </GroupContainer>

                <GroupContainer title={'Уборочные данные'}>
                  <SelectRF
                    label={'Способ уборки'}
                    name={'cleaning_type'}
                    options={cleaningTypesWithNoCleaning}
                    disabled={
                      !(editMode && isEditCurrentObjectIsExternalSystem)
                    }
                    required={isMandatory(
                      card.type_id,
                      'cleaning_type',
                      required,
                    )}
                  />
                </GroupContainer>
                <FieldsSplitColumns>
                  <CheckboxRF
                    name={'is_roadway_area'}
                    label={'Относится к проезжей части'}
                    withChange={() => {
                      changeFieldValue('is_footway_area', 0);
                    }}
                    disabled={
                      !(
                        editMode &&
                        formValues.cleaning_type !== NO_CLEANING_TYPE &&
                        isEditCurrentObjectIsExternalSystem
                      )
                    }
                  />
                  <CheckboxRF
                    name={'is_footway_area'}
                    label={'Относится к тротуару'}
                    withChange={() => {
                      changeFieldValue('is_roadway_area', 0);
                    }}
                    disabled={
                      !(
                        editMode &&
                        formValues.cleaning_type !== NO_CLEANING_TYPE &&
                        isEditCurrentObjectIsExternalSystem
                      )
                    }
                  />
                </FieldsSplitColumns>
                <DescriptionField />
              </div>
              <div hidden={true} id={'docs'}>
                <OtherDocumentsRF />
              </div>
            </div>
            <div hidden={true} id="versions">
              <Versions
                card={card}
                disabled={createMode}
                key={`versions-${card.root_id}`}
              />
            </div>
          </div>
        </CardAbstract>
      </CardContainer>
    );
  }
}

OtherFlatBuildingCard.propTypes = propTypes;

const otherFlatBuildingDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

/**
 * Функция получения и установки изначальных значений из State.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {object} - Поля.
 * @example ----
 */
const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);

  const result = {
    coatingTypes: getCoatingTypes(state, props),

    createMode: isCreateMode(state, props),

    editMode: isEditMode(state, props),
    enableReinitialize: true,

    formValues: getFormValues(state),
    initialValues: {
      area: parse('area'),
      auto_clean_area: parse('auto_clean_area'),
      bord_begin: parse('bord_begin'),
      bord_end: parse('bord_end'),
      cleaning_type: getCleaningType(state, props),
      coating_group_id: parse('coating_group_id.coating_group'),
      coating_type_id: parse('coating_type_id.coating_type'),
      customer_id: parse('customer_id'),
      description: parse('description'),
      endDate: parseDate(props.card.end_date),
      flat_element_type_id: parse('flat_element_type_id.flat_element_type'),
      is_diff_height_mark: parse('is_diff_height_mark'),
      is_footway_area: parse('is_footway_area'),
      is_roadway_area: parse('is_roadway_area'),
      manual_clean_area: parse('manual_clean_area'),
      no_clean_area: parse('no_clean_area'),
      odh_axis: parse('odh_axis'),
      odh_side_id: parse('odh_side_id.odh_side'),
      owner_id: parse('owner_id'),
      parent_name: props.card.parent_info,
      reagent_area: parse('reagent_area'),
      snow_area: parse('snow_area'),
      startDate: parseDate(props.card.start_date),
      totalAreaGeo: parse('total_area_geo'),
    },
    onSubmit: onSubmit.bind({ props }),
    required: isRequired(state, props),

    validate,
    viewing: isThisViewing(state),
  };

  return otherFlatBuildingDocumentsReducer(result, state, props);
};

const numericFields = [
  { name: 'bord_begin', type: 'decimal', zero: true },
  { name: 'bord_end', type: 'decimal', zero: true },
  {
    name: 'area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'auto_clean_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'manual_clean_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'no_clean_area',
    positive: true,
    type: 'decimal',
    zero: true,
  },
  {
    name: 'snow_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'rotor_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'reagent_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
];

const dictFields = [
  'odh_side_id',
  'flat_element_type_id',
  'coating_group_id',
  'coating_type_id',
];

/**
 * Функция валидации.
 *
 * @param {object} values - Значения формы.
 * @returns {object} - Отвалидированные поля.
 * @example ----
 */
function validate(values) {
  return {
    ...validateNumeric(values, numericFields),
    ...validate16(values),
  };
}

/**
 * Функция создания атрибутов.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {object} - Возвращают атрибуты.
 * @example ----
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

/**
 * Функция сабмита.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {void}
 */
export function onSubmit(formValues) {
  const { card } = this.props;
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };

  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[card.type_id],
  );
  formValuesWithAttribute.is_diff_height_mark = Boolean(
    formValuesWithAttribute.is_diff_height_mark,
  );

  formValuesWithAttribute.manual_clean_area = 0;
  formValuesWithAttribute.auto_clean_area = 0;
  formValuesWithAttribute.no_clean_area = 0;

  if (formValuesWithAttribute.cleaning_type === MANUAL_CLEANING_TYPE) {
    formValuesWithAttribute.manual_clean_area = formValuesWithAttribute.area;
  } else if (formValuesWithAttribute.cleaning_type === AUTO_CLEANING_TYPE) {
    formValuesWithAttribute.auto_clean_area = formValuesWithAttribute.area;
  } else {
    formValuesWithAttribute.no_clean_area = formValuesWithAttribute.area;
  }
  delete formValuesWithAttribute.cleaning_type;

  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, {
      dictFields,
      numericFields,
    }),
  );
}

/**
 * Функция получения экшенов.
 *
 * @param {Function} dispatch - Функция диспатч.
 * @returns {{changeFieldValue: mapDispatchToProps.changeFieldValue}} - Экшен changeFieldValue.
 * @example ----
 */
const mapDispatchToProps = (dispatch) => ({

  /**
   * Метод изменения поля.
   *
   * @param {string} field - Имя поля.
   * @param {*} value - Изменяемое значение.
   */
  changeFieldValue: (field, value) => {
    dispatch(change('editorCard', field, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'editorCard' })(OtherFlatBuildingCard));
