import moment from 'moment';

/**
 * Получение Завтрашней даты в формате Fri May 12 2023 10:13:35 GMT+0400 (GMT+04:00).
 *
 * @returns - Завтрашняя дата.
 */
export const getTomorrowDate = (): Date => {
  return moment().add(1, 'day').toDate();
};
