import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const CAR_GROUP_TYPE_NAME = 'carGroupType';

/**
 * ## Группа техники.
 *
 * @param {*} props - Properties.
 * @returns {JSX.Element}
 */
export const CarGroupType = (props) => {
  const dict = 'carFuncTypeGroup';

  const { data: options = [] } = useGetDictionary(dict);

  return (
    <Select
      {...props}
      options={options}
      label={'Группа техники'}
      name={CAR_GROUP_TYPE_NAME}
    />
  );
};
