/* eslint-disable  */
import {
  LIFE_FORM_TYPE_ID_NAME,
  LifeFormTypeId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/LifeFormTypeId';
import { Field } from 'redux-form';

/**
 *
 * @param root0
 * @param root0.input
 */
const LifeFormTypeIdRFIdWithReduxForm = ({ input, ...props }) => {
  return <LifeFormTypeId {...input} {...props} />;
};

/* eslint-disable */
export const LifeFormTypeIdRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={LIFE_FORM_TYPE_ID_NAME}
      component={LifeFormTypeIdRFIdWithReduxForm}
    />
  );
};
