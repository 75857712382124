import getDict from 'app/selectors/getDict';
import { createSelector } from 'reselect';

import getReagentStorageCapacityList from './getReagentStorageCapacityList';

 
export default createSelector(
  [getReagentStorageCapacityList, getDict('reagents')],
  (tableRecords, reagents) => {
    const reagentIdsToInclude = tableRecords.map((item) => item.reagent_id);

    return reagents.filter((item) => reagentIdsToInclude.includes(item.id));
  },
);
