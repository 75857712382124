import { turnIntoCamelCase } from 'app/utils/api';
/* eslint-disable */

/**
 *
 * @param formValues
 */
export const totalAreaValidation = (formValues) => {
  const { totalArea, inboundArea } = turnIntoCamelCase(formValues);
  if (+totalArea > +inboundArea) {
    return {
      inbound_area:
        'значение параметра "Общая площадь в границах ОДХ, кв.м" не может быть меньше значение парметра "Общая площадь в ТС, кв.м"',
    };
  }
};
