 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Вертикальные конструкции. Иные элементы, шт.__.
 *
 * @type {{
 * accessor: 'flowers_vertical_design_qty',
 * Header: 'Вертикальные конструкции. Иные элементы, шт.',
 * }}
 * @augments Cell
 */
export const flowers_vertical_design_qty = {
  accessor: 'flowers_vertical_design_qty',
  Header: 'Вертикальные конструкции. Иные элементы, шт.',
  sortable: true,
};
