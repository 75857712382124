import { useGetVisibilityButtonsOgh } from 'app/api/hooks/useGetVisibilityButtonsOgh';
import { Button } from 'core/uiKit/components/buttons/Button';
import { Icon } from 'core/uiKit/material-ui';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Компонент AcceptExternalObjectOghButton.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.objectId - Id объекта.
 * @returns {JSX.Element}
 */
export const AcceptExternalObjectOghButton = ({ objectId, ...props }) => {
  const { data: { action_external_object: actionExternalObject } = {} } =
    useGetVisibilityButtonsOgh(objectId);

  return actionExternalObject ? (
    <Button
      {...props}
      color={'success'}
      variant={'contained'}
      startIcon={<Icon>done</Icon>}
    >
      Согласовать
    </Button>
  ) : null;
};

export default AcceptExternalObjectOghButton;

AcceptExternalObjectOghButton.propTypes = {

  /**
   * Id версии ОГХ .
   */
  objectId: PropTypes.number.isRequired,
};
