import { useGetDictionary } from 'app/api/hooks/autocomplete/__mocks__/useGetDictionary';
import withMap from 'app/components/map/withMap';
import { ContextProviderDTW } from 'core/uiKit/components/DTW/contexts/ContextProvider.DTW';
import { flow } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { MainPage } from './MainPage';

/**
 * MapStateToProps.
 *
 * @param {*} state - State.
 * @returns {*}
 */
const mapStateToProps = (state) => {
  return {
    // count: state.tree.count,
    active: state.tree.mapActive || null,
    mapsForm: state.forms.mapsForm,
  };
};

const Comp = flow(connect(mapStateToProps), withMap)(MainPage);

/**
 * Ms.
 *
 * @param {*} props - Props.
 * @returns {*}
 */
export default (props) => {
  const navigate = useNavigate();

  /**
   * Go link.
   *
   * @param {string} value - Uri.
   * @returns {void}
   */
  const goToLink = (value) => navigate(value);
  const allOghTypes = useGetDictionary();
  return (
    <ContextProviderDTW>
      <Comp {...props} {...{ allOghTypes }} goToLink={goToLink} />;
    </ContextProviderDTW>
  );
};
