import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { fetchOgh } from './fetchOgh';

/**
 *  UseDispatch.
 *
 * @category useDispatch
 *
 * @function
 * @description Use dispatch запроса карточки ОГХ.
 *
 * @param {number} idOgh - Id ОГХ.
 * @returns {function(number=): function(*): Promise<boolean|*>} Запрос карточки ОГХ.
 *
 * @example
 * import { useFetchOghDispatch } from 'app/actions/odsObjectActions/useFetchOgh.Dispatch';
 *
 *
 * const fetchOgh = useFetchOghDispatch(12);
 * ...
 * fetchOgh()
 *
 * // or
 * const fetchOgh = useFetchOghDispatch();
 * ...
 * fetchOgh(12)
 */
export const useFetchOghDispatch = (idOgh) => {
  const dispatch = useDispatch();
  const objectId = idOgh;

  /**
   * A.
   *
   * @function
   * запрос карточки ОГХ.
   * @param {number} idOgh - Id ОГХ.
   * @returns {*}
   */
  return useCallback(
    (idOgh = objectId) => dispatch(fetchOgh(idOgh)),
    // eslint-disable-next-line
    [dispatch],
  );
};

// return useCallback(
//   (idOgh = objectId) => dispatch(fetchOgh(idOgh)),
//   [dispatch],
// );
// };
