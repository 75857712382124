import RoleCardAdd from 'app/components/admin/roles/CardAdd';
import RoleCardCopy from 'app/components/admin/roles/CardCopy';
import RoleCardEdit from 'app/components/admin/roles/CardEdit';
import RoleCardView from 'app/components/admin/roles/CardView';
import MatchingTechnik from 'app/components/card/tech/index/matching';
import Technik from 'app/components/card/tech/index/view';
import MainPage from 'app/components/MainPage';
import NoMatch from 'app/components/NoMatch';
import { accessDeniedIfDoNotHavePrivilege } from 'app/grants';
import { AdminRoute } from 'app/pages/admin/AdminRoute';
import { pathAdmin } from 'app/pages/admin/path.Admin';
import { AuthRoute } from 'app/pages/auth/AuthRoute';
import { CertificationOfOghRoute } from 'app/pages/certificationOfOgh/CertificationOfOgh.Route';
import { pathCertificationOfOgh } from 'app/pages/certificationOfOgh/path.CertificationOfOgh';
import { KnowledgeBase } from 'app/pages/knowledgeBase/KnowledgeBase';
import { pathKnowledgeBase } from 'app/pages/knowledgeBase/KnowledgeBase/path.KnowledgeBase';
import NewsNotes from 'app/pages/newsNotes/NewsNotes';
import { pathNewsNotes } from 'app/pages/newsNotes/NewsNotes/path.NewsNotes';
import { RegistryRoute } from 'app/pages/registry';
import { pathOgh } from 'app/pages/registry/ogh/Ogh/path.Ogh';
import { pathRegistry } from 'app/pages/registry/path.Registry';
import { ReportRoute } from 'app/pages/report';
import { pathReport } from 'app/pages/report/path.Report';
import { pathTitleList } from 'app/pages/titleList/TitleList/path.TitleList';
import { TitleLIstRoute } from 'app/pages/titleList/TitleLIstRoute';
import { Base } from 'app/routes/Base';
import RoutingBridge from 'app/routes/components/RoutingBridge';
import RoutingBridgeCreateOgh from 'app/routes/components/RoutingBridgeCreateOgh';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { RoutesArr } from 'types/routes';

// @ts-ignore
const RoutesCustom: RoutesArr = [
  {
    component: Base,
    path: '/*',
    routes: [
      {
        component: MainPage,
        exact: true,
        path: '/',
      },
      {
        component: RoutingBridgeCreateOgh,
        path: 'r/ogh/:typeId(\\d+)/create/:startDate',
      },
      {
        component: MatchingTechnik,
        path: 'tech/matching/:id',
      },
      {
        component: NoMatch,
        path: 'tech/matching',
      },
      {
        component: Technik,
        path: 'tech/:id',
      },
      {
        component: NoMatch,
        path: 'tech',
      },
      {
        component: RoleCardView,
        exact: true,
        path: 'a/role/:roleId',
      },
      {
        component: accessDeniedIfDoNotHavePrivilege('create_group')(
          // @ts-ignore
          (props: unknown) => React.createElement(RoleCardAdd, props),
        ),
        path: 'a/add/role',
      },
      {
        component: accessDeniedIfDoNotHavePrivilege('copy_group')(
          (props: object) => React.createElement(RoleCardCopy, props),
        ),
        path: 'a/copy/role/:roleId',
      },
      {
        component: accessDeniedIfDoNotHavePrivilege('edit_group')(
          (props: object) => React.createElement(RoleCardEdit, props),
        ),
        path: 'a/edit/role/:roleId',
      },

      ////////////////////////////
      // сюда нормальные роуту ложим
      ///////////////////////

      {
        component: RegistryRoute,
        path: `${pathRegistry.path}/*`,
      },
      {

        /**
         * Создание ОГХ.
         *
         * @returns JSX.Element.
         */
        component: () => (
          <Routes>
            <Route
              path={':parent/create/:startDate'}
              element={<RoutingBridgeCreateOgh />}
            />
            <Route
              path={':id/create/:typeId/:startDate'}
              element={<RoutingBridgeCreateOgh />}
            />
            <Route
              path={':parent/:child/create/:startDate'}
              element={<RoutingBridgeCreateOgh />}
            />

            <Route path={':id/:childId/:typeId'} element={<RoutingBridge />} />
            <Route
              path={':id/create/:typeId/:startDate'}
              element={<RoutingBridgeCreateOgh />}
            />
            <Route path={':id/:childId'} element={<RoutingBridge />} />
            <Route path={':id'} element={<RoutingBridge />} />
          </Routes>
        ),
        path: `${pathOgh.path}/*`,
      },

      {
        component: ReportRoute,
        path: `${pathReport.path}/*`,
      },
      {
        component: TitleLIstRoute,
        path: `${pathTitleList.path}/*`,
      },
      {
        component: AdminRoute,
        path: `${pathAdmin.path}/*`,
      },
      {
        component: NewsNotes,
        path: `${pathNewsNotes.path}/*`,
      },
      {
        component: CertificationOfOghRoute,
        path: `${pathCertificationOfOgh.path}/*`,
      },
      {
        component: KnowledgeBase,
        path: `${pathKnowledgeBase.path}/:knowledgeBaseCode`,
      },
      {
        component: NoMatch,
        path: '*',
      },
    ],
  },
  {
    component: AuthRoute,
    path: '/auth/*',
  },
];

export default RoutesCustom;
