/* eslint-disable */
import React, { useMemo, useState } from 'react';
import Table from 'core/uiKit/components/tables/Table/Table';
import moment from 'moment';
import RegistryForm from 'app/components/registry/form';

import { useSelector } from 'react-redux';
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useParamsTable } from 'core/uiKit/components/tables/Table';
import {
  useFetchOghTable,
  useFetchOghTableCount,
} from 'app/pages/registry/ogh/api';
import formatRootId from 'app/utils/formatRootId';
import { useTreeAction } from 'app/pages/cardsOgh/components/RightPanelCard/components/ActionTree/ActionTree';

const TREE_FORM = 'treeEditForm';

const custom = {
  idOrName: {},
  typeId: {
    mode: 'day',
  },
  address: {
    mode: 'day',
    id: 'faddress',
  },
  okrug: {
    mode: 'day',
  },
  district: {
    mode: 'day',
  },
  listTypeId: {
    mode: 'day',
  },
  customer: {
    mode: 'day',
  },
  owner: {
    mode: 'day',
  },
  subTypeId: {
    mode: 'day',
    minimum: 0,
  },
  buildingTypeId: {
    mode: 'day',
  },
  status: {
    no: true,
  }, // Убираем для всех типов
  dateFrom: {
    no: true,
  }, // Убираем для всех типов
  dateTo: {
    no: true,
  }, // Убираем для всех типов
  submitButton: {
    primary: true,
  },
};

const columns = [
  {
    accessor: 'id',
    Header: 'ID',
    sortable: true,
    Cell: (row) => formatRootId(row.value),
  },

  {
    accessor: 'address_name',
    Header: 'Адрес',
  },
  {
    accessor: 'object_status_name',
    Header: 'Статус',
    sortable: true,
  },
  {
    accessor: 'create_date',
    Header: 'Дата начала',
    sortable: true,
    Cell: ({ cell }) => {
      return moment(cell.value).format('DD.MM.YYYY HH:mm');
    },
  },
  {
    accessor: 'end_date',
    Header: 'Дата окончания',
    sortable: true,
  },
];

/* eslint-disable */
export const TableAdd = ({ titleTable, setSelectRowsTableAdd }) => {
  const odsDicts = useSelector((state) => state?.odsDicts);
  const { typeId, endDate, startDate, recordId } = useGetParentCard();
  const [values, setValues] = useState({});

  const selectedRow = ({ selectedFlatRows }) => {
    setSelectRowsTableAdd(selectedFlatRows.map(({ original }) => original.id));
  };

  const { queryTable } = useParamsTable();

  const handleRegistryFormSubmit = (formValues) => {
    setValues(formValues);
  };

  const {
    dataDialog: { dataTree, ids },
  } = useTreeAction();

  const queryKey = useMemo(() => {
    return [
      'postTableAdd',
      {
        dateFrom: startDate,
        dateTo: endDate,
        max_rows: queryTable.maxRows,
        page: queryTable.page,
        rootId: recordId,
        rootStartDate: startDate,
        rootType: typeId,
        typePath: dataTree.typeId,
        typeId: dataTree.typeId,
        state_id: 1,
        ...values,
      },
    ];
  }, [dataTree, queryTable, typeId, startDate, endDate, recordId, values]);

  const { data, isLoading } = useFetchOghTable(queryKey);
  const { data: { count } = {} } = useFetchOghTableCount(queryKey);

  const table = useMemo(() => {
    let result = [];
    if (!isLoading) {
      result = data.reduce((arr, item) => {
        if (!ids.includes(item.id)) {
          arr.push(item);
        }
        return arr;
      }, []);
      return result;
    }

    return data;
  }, [data, ids, isLoading]);

  return (
    <div style={{ width: '100%' }}>
      <RegistryForm
        custom={custom}
        dark={false}
        id={TREE_FORM}
        parentTypeId={typeId}
        typeId={dataTree.typeId}
        {...odsDicts}
        onSubmit={handleRegistryFormSubmit}
      />
      <div style={{ height: '30vh', marginTop: '30px' }}>
        <p>{`Список объектов ${titleTable}`}</p>
        <Table
          columns={columns}
          data={table || []}
          total={count}
          isLoading={isLoading}
          checkboxSelection={true}
          selectedRows={selectedRow}
        />
      </div>
    </div>
  );
};
