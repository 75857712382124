/* eslint-disable */
import React from 'react';
import { NumberParam } from 'use-query-params';

import { REAGENT_KIND_NAME, ReagentKindFF } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
 
/**
 * Вид ПГР
 * reagentKind
 *
 * @returns {JSX.Element}
 * @constructor
 */
 
export const ReagentKindFilter = () => (
  <ReagentKindFF className={filterInput} />
);

 
export const reagentKindParams = {
  [REAGENT_KIND_NAME]: NumberParam,
};
