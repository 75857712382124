 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __ГРБС__.
 *
 * @type {{ accessor: 'parent_grbs_name',  Header: 'ГРБС', sortable: true, }}
 * @augments Cell
 */
export const parent_grbs_name = {
  accessor: 'parent_grbs_name',
  Header: 'ГРБС',
  sortable: true,
};
