import { useDispatch } from 'react-redux';
import { change } from 'redux-form';

/**
 * Хук, получения функции изменения значения поля.
 *
 * @returns {function(string, *): void}
 */
export const useChangeFieldValueRF = () => {
  const dispatch = useDispatch();

  return (field, value) => {
    dispatch(change('editorCard', field, value));
  };
};
