/* eslint-disable */
import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import {
  NetElementTypeId,
  NET_ELEMENT_TYPE_ID_NAME,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/NetElementTypeId';

const NetElementTypeIdRFWithReduxForm = ({ input, ...props }) => {
  return <NetElementTypeId {...input} {...props} />;
};

/* eslint-disable */
export const NetElementTypeIdRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={NET_ELEMENT_TYPE_ID_NAME}
      component={NetElementTypeIdRFWithReduxForm}
    />
  );
};

NetElementTypeIdRF.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
};
NetElementTypeIdRF.defaultProps = {
  disabled: false,
};
