import { CommentsWithFilesCell } from 'core/uiKit/components/cells/CommentsWithFiles.Cell';

 
export /* eslint-disable */
const columnsMatchingBoundariesOghTable = [
  {
    accessor: 'num_iter',
    Header: 'Номер итерации',
  },
  {
    accessor: 'owner_neighbour_name',
    Header: 'Организация',
  },
  {
    accessor: 'user_matching',
    Header: 'ФИО согласовавшего пользователя',
  },
  {
    accessor: 'last_matching_date',
    Header: 'Крайняя дата согласования',
  },
  {
    accessor: 'neighbour_resolution_date',
    Header: 'Фактическая дата согласования',
    sortable: true,
  },
  {
    accessor: 'reason_list',
    Header: 'Причины отправки на согласование',
  },
  {
    accessor: 'comment',
    Cell: CommentsWithFilesCell,
    Header: 'Комментарий',
  },
];
