import { getFileList } from 'app/pages/cardsOgh/components/tables/Documents/helpers/getFileList';
import { useSaveCardWithoutGeometry } from 'app/pages/cardsOgh/hooks/useSaveCardWithoutGeometry';

import { useInitialValues } from './hooks/useInitialValues';
/* eslint-disable  */
import { validateForm } from './validateForm';

/**
 * Setup Емкости для хранения ПГР.
 *
 * @returns {object}
 */
export const useSetupTank = () => {
  const save = useSaveCardWithoutGeometry();
  const initialValues = useInitialValues();

  /**
   * <OnSubmit.
   *
   * @param {*} values - Values.
   * @returns {*}
   */

  /**
   *
   * @param values
   */
  const submit = (values) => {
    let errors = validateForm(values);
    if (errors) {
      return errors;
    }

    const attribute = {
      descr: values.descr,
      file_list: getFileList(values),
      minimum_balance_percent: Number(values.minimumBalancePercent),
      quantity: Number(values.quantity),
      reagent_id: { reagent: values.reagentId },
      reagent_kind_id: { reagent_kind: values.reagentKindId },
      tank_num: Number(values.tankNum),
      tonnage: Number(values.tonnage),
    };

    save({ attribute, values });
  };

  return { initialValues, submit };
};
