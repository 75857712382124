import camelCase from 'lodash/camelCase';

 
/* eslint-disable */
/**
 * @category utils
 * @function
 * @description
 * Переводит все названия свойств в camelCase
 * @param {Object|string|Array<Object>|Array<string>} param
 * @returns {{}|*}
 *
 * @example
 * turnIntoCamelCase({snake_case: 1}) //? {snakeCase: 2}
 *
 */
 
export const turnIntoCamelCase = (param) => {
  if (Array.isArray(param)) {
    return param.map((item) => turnIntoCamelCase(item));
  }
  const camelCaseParams = {};
  for (const key in param) {
    camelCaseParams[camelCase(key)] = param[key];
  }

  return camelCaseParams;
};
