import { REPAIRS_INFO_LIST_NAME } from './components/tabs/Repairs.Tab';
import { REPAIRS_INFO_LIST_PLAN_NAME } from './components/tabs/RepairsPlan.Tab';

const tabs = [
  {
    id: 'squares-tab',
    name: 'Площади',
  },

  {
    id: 'plantation-tab',
    name: 'Озеленение',
  },
  {
    id: REPAIRS_INFO_LIST_NAME + '-tab',
    name: 'Ремонты',
  },
  {
    id: REPAIRS_INFO_LIST_PLAN_NAME + '-tab',
    name: 'Проекты',
  },
  {
    id: 'docs-tab',
    name: 'Документы',
  },
  {
    id: 'orders-tab',
    name: 'Ордера на работы',
  },
];
const oznTabs = [
  {
    id: 'squares-tab',
    name: 'Площади',
  },
  {
    id: 'plantation-tab',
    name: 'Озеленение',
  },
  {
    id: 'docs-tab',
    name: 'Документы',
  },
  {
    id: 'orders-tab',
    name: 'Ордера на работы',
  },
  {
    id: 'additional-information-tab',
    name: 'Дополнительная информация',
  },
];

export const yardCardTabs = tabs;

export const improvementObjectCardTabs = tabs;

export const oznCardTabs = oznTabs;
