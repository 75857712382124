import { print } from 'app/api/requests/print';

/**
 * Функция detailing.
 *
 * @param {number} params - Параметры.
 * @param {number} params.id - Идентификатор.
 * @param {number} params.typeId - Тип огх.
 * @returns {Promise<*|Response>}
 */
export const detailing = ({ id, typeId }) => print('equipment', { id, typeId });
