import { VERTICAL_LANDSCAPING_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/VerticalLandscapingTypeId/VerticalLandscapingTypeId';
import { usePreparation } from 'core/utils/hooks/usePreparation/preparation';

/**
 * Хук перевода значений при сохранении для __Вид ЭВО__.
 *
 * @returns {void}
 */
export const usePreparationVerticalLandscapingTypeId = () => {
  usePreparation(VERTICAL_LANDSCAPING_TYPE_ID_NAME, (value) => {
    if (value) {
      return { vertical_landscaping_type: value };
    }
  });
};
