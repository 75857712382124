import { useInitialValues } from './hooks/useInitialValues';

/**
 * Хук useSetup Водных сооружений.
 *
 * @returns {object}
 */
export const useSetupWaterBuilding = () => {
  const initialValues = useInitialValues();

  /**
   * Функция подготовка данных для Атрибутов.
   *
   * @param {*} values - Значения формы.
   * @returns {*} Атрибуты.
   */
  const preparingDataForAttributes = (values) => {
    return {
      area: Number(values.area),
      is_diff_height_mark: values.is_diff_height_mark ? 1 : 0,
      no_calc: values.noCalc ? 1 : 0,
      totalAreaGeo: values.totalAreaGeo,
      water_type_id: { water_type: values.waterTypeId },
    };
  };

  return { initialValues, preparingDataForAttributes };
};
