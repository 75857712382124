/* eslint-disable */
import React from 'react';
import { CheckboxRF } from 'core/form/reduxForm/fields';

 
export const IS_ORPHAN_OBJECT_NAME = 'is_orphan_object';

 
 
/**
 * ## Разновысотные отметки
 *
 * ### Redux Form Field
 * ```js
 * import { IsOrphanObjectRF, IS_ORPHAN_OBJECT_NAME } from 'core/form/finalForm/fields/prepared/checkboxes/IsDiffHeightMark.FF';
 * ```
 *
 * * `type = CheckboxRF`
 * * `name = is_orphan_object`
 */
 
export const IsOrphanObjectRF = (props) => {
  return (
    <CheckboxRF
      {...props}
      label={props.label || 'Объект, переданный в эксплуатацию'}
      name={IS_ORPHAN_OBJECT_NAME}
    />
  );
};
