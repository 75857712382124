import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';

import { getNestedObjectsDiffTable } from './getNestedObjectsDiffTable';

/* eslint-disable */
/**
 * Хук получения таблицы "Вложенные объекты".
 *
 * @param {object} etalon - Объект с которым сравниваем.
 * @param {object} copy - Новая версия.
 * @returns {{isLoading: boolean, table: Array}} - Массив таблицы свойств.
 * @example const { table, isLoading } = useGetNestedObjectsDiffTable(etalon,copy);
 */
export const useGetNestedObjectsDiffTable = (etalon, copy) => {
  const { typeId } = useGetCurrentCard();
  const { data = [], isLoading } = useQueryAdaptor(
    [
      'getNestedObjectsDiffTable',
      {
        etalon: etalon.id,
        copy: copy.id,
        typeId,
      },
    ],
    getNestedObjectsDiffTable,
  );

  const table = data.filter(({ is_equal }) => !is_equal);

  return { table, isLoading };
};
