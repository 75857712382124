import { Cell } from 'core/uiKit/components/tables/Table';

/**
 * Колонка __Площадь облицовки, кв.м__.
 *
 * @type {{ accessor: 'face_area', Header: 'Площадь облицовки, кв.м' }}
 * @augments Cell
 */
export const face_area: Cell = {
  Header: 'Площадь облицовки, кв.м',
  accessor: 'face_area',
  sortable: true,
};
