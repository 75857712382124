import formatRootId from 'app/utils/formatRootId';

import ids from './ids';


/* eslint-disable */
export default [
  {
    id: 'root_id',
    name: 'ID',
    Cell: ({ cell: { value } = {} }) => formatRootId(value),
    width: 80,
  },
  {
    id: ids.address,
    name: 'Адрес',
    width: 250,
  },
  {
    id: 'object_status_name',
    name: 'Статус',
    width: 110,
  },
  {
    id: 'start_date',
    name: 'Дата начала',
    width: 90,
  },
  {
    id: 'end_date',
    name: 'Дата окончания',
    width: 120,
  },
];
