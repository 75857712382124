import { toMsk77 } from './toMsk77';

/**
 * Преобразование координат полилинии из geoJSON в мск77.
 *
 * @param polyline - Координаты полилинии.
 * @returns Координаты полилинии Array<number>[].
 */
export const polylineToMsk77 = (polyline: Array<number>[]) => {
  return polyline.map((coordinates) => toMsk77(coordinates));
};
