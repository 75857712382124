import NoMatch from 'app/components/NoMatch';
import { NSIBidRoute } from 'app/pages/registry/nsiBid/NSI.Bid.Route';
import { pathNsiBid } from 'app/pages/registry/nsiBid/path.NsiBid';
import { pathReagentNorm } from 'app/pages/registry/reagentNorm/ReagentNorm/path.ReagentNorm';
import { RegistrySkeleton } from 'core/uiKit/components';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { pathCertificationOfOgh } from './certificationOfOgh/path.CertificationOfOgh';
import { pathMatchingBoundaries } from './MatchingBoundaries/path.MatchingBoundaries';
import { OghRouteLazy } from './ogh';
import { pathOgh } from './ogh/Ogh/path.Ogh';
import { pathTech } from './tech/path.Tech';
import { pathTitleListRegistry } from './TitleListRegistry/path.TitleListRegistry';

/**
 * LazyWithPreload.
 *
 * @param {*} factory - Factory.
 * @returns {*}
 */
function lazyWithPreload(factory) {
  const Component = lazy(factory);
  Component.preload = factory;
  return Component;
}

const MatchingBoundaries = lazyWithPreload(() =>
  import('./MatchingBoundaries'),
);
const TlRegistry = lazyWithPreload(() => import('./TitleListRegistry'));
const Matching = lazyWithPreload(() => import('./Matching'));
const TechRoute = lazyWithPreload(() => import('./tech'));
const CertificationOfOghRoute = lazyWithPreload(() =>
  import('./certificationOfOgh/CertificationOfOghRoute'),
);

const ReagentNormRoute = lazyWithPreload(() => import('./reagentNorm'));

/**
 * Registry Route.
 *
 * @returns {JSX}
 */
export const RegistryRoute = () => {
  useEffect(() => {
    MatchingBoundaries.preload();
    TlRegistry.preload();
    Matching.preload();
    TechRoute.preload();
    OghRouteLazy.preload();
    ReagentNormRoute.preload();
    CertificationOfOghRoute.preload();
  }, []);

  return (
    <Suspense fallback={<RegistrySkeleton />}>
      <Routes>
        <Route element={<OghRouteLazy />} path={`${pathOgh.path}/*`} />
        <Route
          element={<ReagentNormRoute />}
          path={`${pathReagentNorm.path}/*`}
        />

        <Route element={<Matching />} path={`matching`} />
        <Route element={<NSIBidRoute />} path={`${pathNsiBid.path}/*`} />
        <Route element={<TechRoute />} path={`${pathTech.path}/*`} />
        <Route element={<TlRegistry />} path={pathTitleListRegistry.path} />
        <Route
          element={<MatchingBoundaries />}
          path={pathMatchingBoundaries.path}
        />
        <Route
          element={<CertificationOfOghRoute />}
          path={pathCertificationOfOgh.path + '/*'}
        />

        <Route element={<NoMatch />} path={'*'} />
      </Routes>
    </Suspense>
  );
};
