import { DateCloseField } from 'app/components/card/ogh/index/card/components/CloseElectronicSignature/fields/DateCloseField/DateCloseField';
import { ReasonFilter } from 'app/components/dialogs/common/filters/Reason.Filter';
import { NumberFieldFF } from 'core/form/finalForm/fields';
import { FileSignatureCryptoProFF } from 'core/form/finalForm/fields/prepared/FileSignatureCryptoProFF';
import { DndFileUploadFF } from 'core/form/finalForm/fields/prepared/fileUpload/DndFileUploadFF';
import { SendReasonFF } from 'core/form/finalForm/fields/prepared/selects/multiSelect/SendReasonFF';
import { CommentFF } from 'core/form/finalForm/fields/prepared/textField/CommentFF';
import { CertInfo } from 'core/uiKit/components/CertInfo';
import { FileListSign } from 'core/uiKit/components/FileList.Sign';
import { Paper } from 'core/uiKit/components/Paper';
import { SignatureList } from 'core/uiKit/components/SignatureList';
import { Alert } from 'core/uiKit/material-ui';
import React from 'react';

import { DocumentCloseFileField } from './fields/DocumentCloseFileField';
import useSetup from './useSetup.Form.Dialog.SignatureFiles';

/**
 * Диалог Формы подписания файлов.
 *
 * @returns {JSX.Element}
 */
export const FormDialogSignatureFiles = () => {
  const {
    callbackError,
    certificate,
    error,
    files,
    filesForSignature,
    isUnion,
    onSignaturesChange,
    signatures,
  } = useSetup();

  return (
    <>
      <Paper className={'p-2'}>
        <div className={'display-flex gap-2'}>
          <DateCloseField />
          <ReasonFilter
            privilegeCode={'del_ogh'}
            Component={SendReasonFF}
            withAll={false}
            label={'Причина закрытия'}
            required
          />
        </div>
        <DocumentCloseFileField />
        <CommentFF fullWidth />
        {isUnion ? (
          <NumberFieldFF
            name={'id_ogh_uni'}
            label={'ОГХ, в который входит закрываемый ОГХ.'}
            required
          />
        ) : null}
      </Paper>
      <CertInfo certificate={certificate} />
      {error ? (
        <Paper className={'my-2'} elevation={6}>
          <Alert severity="error" variant={'filled'}>
            {error}
          </Alert>
        </Paper>
      ) : null}
      {filesForSignature.length ? (
        <Paper className={'p-2'}>
          <FileSignatureCryptoProFF
            {...{
              callbackError,
              files: filesForSignature, // самм файлы для подписи
            }}
          />
        </Paper>
      ) : null}
      <FileListSign files={files}>
        <DndFileUploadFF
          text={
            'Добавить еще файлы для подписания. Переместите файлы или нажмите для выбора'
          }
          name={'files'}
        />
      </FileListSign>

      <SignatureList
        clearSignatures={onSignaturesChange}
        signatures={signatures}
      />
    </>
  );
};
