export const ogh_types = [
  {
    code: 'base_pgm',
    full_name: 'Базы хранения ПГР',
    id: 44,
    name: 'Базы хранения ПГР',
  },
  {
    code: 'board_stone',
    full_name: 'Бортовые камни',
    id: 15,
    name: 'Бортовые камни',
  },
  {
    code: 'storage_place',
    full_name: 'Закрытые велопарковки для долгосрочного хранения велосипедов',
    id: 29,
    name: 'Велопарковки',
  },
  {
    code: 'water_building',
    full_name: 'Водные сооружения',
    id: 50,
    name: 'Водные сооружения',
  },
  {
    code: 'lawn',
    full_name: 'Газоны',
    id: 31,
    name: 'Газоны',
  },
  {
    code: 'yard',
    full_name: 'Дворовые территории',
    id: 38,
    name: 'Дворовые территории',
  },
  {
    code: 'trees_shrubs',
    full_name: 'Деревья/Кустарники',
    id: 43,
    name: 'Деревья/Кустарники',
  },
  {
    code: 'dts',
    full_name: 'Дорожно-тропиночные сети',
    id: 30,
    name: 'Дорожно-тропиночные сети',
  },
  {
    code: 'tank',
    full_name: 'Емкости для хранения ПГР',
    id: 45,
    name: 'Емкости для хранения ПГР',
  },
  {
    code: 'road_signs',
    full_name: 'Знаки, указатели и информационные щиты',
    id: 10,
    name: 'Знаки, указатели и информационные щиты',
  },
  {
    code: 'other_engin_construct',
    full_name: 'Инженерные сооружения',
    id: 9,
    name: 'Инженерные сооружения',
  },
  {
    code: 'other_oks',
    full_name: 'Иной объект капитального строительства',
    id: 48,
    name: 'Иной объект капитального строительства',
  },
  {
    code: 'other_tech_place',
    full_name: 'Иные некапитальные объекты',
    id: 41,
    name: 'Иные некапитальные объекты',
  },
  {
    code: 'improvement_object',
    full_name: 'Иные объекты благоустройства',
    id: 39,
    name: 'Иные объекты благоустройства',
  },
  {
    code: 'manholes',
    full_name: 'Люки смотровых колодцев и решетки водоприемных колодцев',
    id: 8,
    name: 'Люки смотровых колодцев и решетки водоприемных колодцев',
  },
  {
    code: 'maf_rfid',
    full_name: 'Малые архитектурные формы',
    id: 52,
    name: 'Малые архитектурные формы',
  },
  {
    code: 'little_form',
    full_name: 'Малые архитектурные формы и элементы благоустройства',
    id: 37,
    name: 'Малые архитектурные формы и элементы благоустройства',
  },
  {
    code: 'maf',
    full_name: 'Малые архитектурные формы и элементы благоустройства (ОДХ)',
    id: 7,
    name: 'Малые архитектурные формы (ОДХ)',
  },
  {
    code: 'container',
    full_name: 'Места накопления отходов',
    id: 26,
    name: 'Места накопления отходов',
  },
  {
    code: 'bridge',
    full_name: 'Мосты',
    id: 47,
    name: 'Мосты',
  },
  {
    code: 'margin',
    full_name: 'Обочины',
    id: 23,
    name: 'Обочины',
  },
  {
    code: 'odh',
    full_name: 'Объекты дорожного хозяйства',
    id: 1,
    name: 'Объекты дорожного хозяйства',
  },
  {
    code: 'building',
    full_name: 'Объекты капитального строительства',
    id: 25,
    name: 'Объекты капитального строительства',
  },
  {
    code: 'ozn',
    full_name: 'Объекты озеленения',
    id: 40,
    name: 'Объекты озеленения',
  },
  {
    code: 'fencing',
    full_name: 'Ограждения',
    id: 11,
    name: 'Ограждения',
  },
  {
    code: 'lampposts',
    full_name: 'Опоры освещения и контактных сетей',
    id: 6,
    name: 'Опоры освещения и контактных сетей',
  },
  {
    code: 'spa',
    full_name: 'Особо охраняемые природные территории',
    id: 49,
    name: 'Особо охраняемые природные территории',
  },
  {
    code: 'stops',
    full_name: 'Остановки общественного транспорта',
    id: 5,
    name: 'Остановки общественного транспорта',
  },
  {
    code: 'planar_structure',
    full_name: 'Плоскостные сооружения',
    id: 27,
    name: 'Плоскостные сооружения',
  },
  {
    code: 'carriageway',
    full_name: 'Проезжие части',
    id: 13,
    name: 'Проезжие части',
  },
  {
    code: 'tech_place',
    full_name:
      'Некапитальные объекты для обеспечения производственной деятельности по содержанию и ремонту территорий (в том числе места хранения ПГР на дворовых территориях)',
    id: 28,
    name: 'Производственные некапитальные объекты',
  },
  {
    code: 'other_flat_building',
    full_name: 'Прочие территории',
    id: 16,
    name: 'Прочие территории',
  },
  {
    code: 'red_book_plant',
    full_name: 'Растения Красной книги',
    id: 51,
    name: 'Растения Красной книги',
  },
  {
    code: 'traffic_light',
    full_name: 'Светофорные объекты',
    id: 12,
    name: 'Светофорные объекты',
  },
  {
    code: 'functionality',
    full_name: 'Системы функционального обеспечения',
    id: 35,
    name: 'Системы функционального обеспечения',
  },
  {
    code: 'emergency_parking',
    full_name: 'Специальная площадка для аварийной остановки автомобилей',
    id: 24,
    name: 'Специальная площадка для аварийной остановки автомобилей',
  },
  {
    code: 'trolleybus_contact_network',
    full_name: 'Трамвайные и троллейбусные контактные сети',
    id: 21,
    name: 'Трамвайные и троллейбусные контактные сети',
  },
  {
    code: 'tram_rails',
    full_name: 'Трамвайные пути',
    id: 20,
    name: 'Трамвайные пути',
  },
  {
    code: 'sidewalks',
    full_name: 'Тротуары',
    id: 14,
    name: 'Тротуары',
  },
  {
    code: 'flowers_garden',
    full_name: 'Цветники',
    id: 32,
    name: 'Цветники',
  },
  {
    code: 'ppi',
    full_name: 'Элементы благоустройства для маломобильных групп населения',
    id: 33,
    name: 'Элементы благоустройства для маломобильных групп населения',
  },
  {
    code: 'relief',
    full_name: 'Элементы организации рельефа',
    id: 34,
    name: 'Элементы организации рельефа',
  },
];
