import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { useCoatingTypeRfTramRails } from 'app/components/card/ogh/tram_rails/fields/CoatingTypeRf.TramRails/useCoatingTypeRfTramRails';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import getFormValues from 'app/selectors/form/getFormValues';
import { RANDOM_BOOL, RANDOM_NUMBER } from 'core/forTesting/constants';
import { useSelector } from 'react-redux';

jest.mock('app/pages/cardsOgh/hooks/useEditMode');
jest.mock('app/api/hooks/useIsCurrentObjectIsExternalSystem');
jest.mock('app/api/hooks/useGetParentCard');
jest.mock('react-redux');
jest.mock('app/selectors/form/getFormValues');

describe('UseCoatingTypeRfTramRailsJestSpec', function () {
  it('test called getFormValues', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useEditMode.mockReturnValue({ editMode: RANDOM_BOOL });
    useIsCurrentObjectIsExternalSystem.mockReturnValue(RANDOM_BOOL);
    useGetParentCard.mockReturnValue({ typeId: RANDOM_NUMBER });
    useSelector.mockImplementation((fn) => {
      fn({ foo: RANDOM_NUMBER });
    });
    // Act
    useCoatingTypeRfTramRails();
    // Assert
    expect(getFormValues).toBeCalledTimes(1);
    expect(getFormValues).toHaveBeenCalledWith({ foo: RANDOM_NUMBER });
  });
  it('test filter no data', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useEditMode.mockReturnValue({ editMode: RANDOM_BOOL });
    useIsCurrentObjectIsExternalSystem.mockReturnValue(RANDOM_BOOL);
    useGetParentCard.mockReturnValue({});
    useSelector.mockReturnValue({});
    // Act
    const { filter } = useCoatingTypeRfTramRails();

    // Assert
    expect(filter([])).toStrictEqual([]);
  });
  it('test filter', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useEditMode.mockReturnValue({ editMode: RANDOM_BOOL });
    useIsCurrentObjectIsExternalSystem.mockReturnValue(RANDOM_BOOL);
    useGetParentCard.mockReturnValue({ typeId: 1 });
    useSelector.mockReturnValue({ coating_group_id: 1 });
    // Act
    const { filter } = useCoatingTypeRfTramRails();

    // Assert
    expect(filter(dict)).toStrictEqual([
      {
        class_id: 22,
        code: 'road_plates',
        group_id: 1,
        id: 53,
        name: 'Плиты дорожные',
        ogh_object_type_list: [
          {
            ogh_object_type_id: 1,
            other_improvement_object_type_list: [],
          },
        ],
      },
      {
        class_id: 22,
        code: 'rubber_cover',
        group_id: 1,
        id: 54,
        name: 'Резина',
        ogh_object_type_list: [
          {
            ogh_object_type_id: 1,
            other_improvement_object_type_list: [],
          },
        ],
      },
      {
        class_id: 22,
        code: 'other_prefabricated_structure',
        group_id: 1,
        id: 49,
        name: 'Сборное покрытие',
        ogh_object_type_list: [
          {
            ogh_object_type_id: 1,
            other_improvement_object_type_list: [],
          },
        ],
      },

      {
        class_id: 22,
        code: 'cement_concrete_tiles',
        group_id: 1,
        id: 68,
        name: 'Цементобетонная плитка',
        ogh_object_type_list: [
          {
            ogh_object_type_id: 1,
            other_improvement_object_type_list: [],
          },
        ],
      },
    ]);
  });
});

const dict = [
  {
    class_id: null,
    code: '76',
    group_id: 2,
    id: 76,
    name: 'TerraWay',
    ogh_object_type_list: [
      {
        ogh_object_type_id: 49,
        other_improvement_object_type_list: [],
      },
      {
        ogh_object_type_id: 38,
        other_improvement_object_type_list: [],
      },
      {
        ogh_object_type_id: 40,
        other_improvement_object_type_list: [],
      },
    ],
  },
  {
    class_id: null,
    code: 'marble_slab',
    group_id: 2,
    id: 61,
    name: 'Мраморные плиты',
    ogh_object_type_list: [
      {
        ogh_object_type_id: 49,
        other_improvement_object_type_list: [],
      },
      {
        ogh_object_type_id: 40,
        other_improvement_object_type_list: [],
      },
      {
        ogh_object_type_id: 38,
        other_improvement_object_type_list: [],
      },
      {
        ogh_object_type_id: 39,
        other_improvement_object_type_list: [],
      },
    ],
  },
  {
    class_id: 22,
    code: 'stone',
    group_id: 2,
    id: 43,
    name: 'Натуральный камень',
    ogh_object_type_list: [
      {
        ogh_object_type_id: 49,
        other_improvement_object_type_list: [],
      },
      {
        ogh_object_type_id: 40,
        other_improvement_object_type_list: [],
      },
      {
        ogh_object_type_id: 38,
        other_improvement_object_type_list: [],
      },
      {
        ogh_object_type_id: 39,
        other_improvement_object_type_list: [],
      },
    ],
  },
  {
    class_id: 22,
    code: 'sand_gravel',
    group_id: 2,
    id: 52,
    name: 'Песчано-гравийная смесь',
    ogh_object_type_list: [
      {
        ogh_object_type_id: 49,
        other_improvement_object_type_list: [],
      },
      {
        ogh_object_type_id: 1,
        other_improvement_object_type_list: [],
      },
      {
        ogh_object_type_id: 40,
        other_improvement_object_type_list: [],
      },
      {
        ogh_object_type_id: 38,
        other_improvement_object_type_list: [],
      },
      {
        ogh_object_type_id: 39,
        other_improvement_object_type_list: [],
      },
    ],
  },
  {
    class_id: 2091977,
    code: 'sandy',
    group_id: 2,
    id: 39,
    name: 'Песчаное',
    ogh_object_type_list: [
      {
        ogh_object_type_id: 49,
        other_improvement_object_type_list: [],
      },
      {
        ogh_object_type_id: 38,
        other_improvement_object_type_list: [],
      },
      {
        ogh_object_type_id: 39,
        other_improvement_object_type_list: [],
      },
      {
        ogh_object_type_id: 40,
        other_improvement_object_type_list: [],
      },
    ],
  },
  {
    class_id: 2091977,
    code: 'plastic',
    group_id: 2,
    id: 8,
    name: 'Пластиковое',
    ogh_object_type_list: [
      {
        ogh_object_type_id: 49,
        other_improvement_object_type_list: [],
      },
      {
        ogh_object_type_id: 38,
        other_improvement_object_type_list: [],
      },
      {
        ogh_object_type_id: 40,
        other_improvement_object_type_list: [],
      },
      {
        ogh_object_type_id: 39,
        other_improvement_object_type_list: [],
      },
    ],
  },
  {
    class_id: 22,
    code: 'road_plates',
    group_id: 1,
    id: 53,
    name: 'Плиты дорожные',
    ogh_object_type_list: [
      {
        ogh_object_type_id: 1,
        other_improvement_object_type_list: [],
      },
    ],
  },
  {
    class_id: 22,
    code: 'rubber_cover',
    group_id: 1,
    id: 54,
    name: 'Резина',
    ogh_object_type_list: [
      {
        ogh_object_type_id: 1,
        other_improvement_object_type_list: [],
      },
    ],
  },
  {
    class_id: 2091977,
    code: 'crumb_rubber',
    group_id: 2,
    id: 9,
    name: 'Резиновая крошка',
    ogh_object_type_list: [
      {
        ogh_object_type_id: 49,
        other_improvement_object_type_list: [],
      },
      {
        ogh_object_type_id: 38,
        other_improvement_object_type_list: [],
      },
      {
        ogh_object_type_id: 39,
        other_improvement_object_type_list: [],
      },
      {
        ogh_object_type_id: 40,
        other_improvement_object_type_list: [],
      },
    ],
  },
  {
    class_id: 22,
    code: 'other_prefabricated_structure',
    group_id: 1,
    id: 49,
    name: 'Сборное покрытие',
    ogh_object_type_list: [
      {
        ogh_object_type_id: 1,
        other_improvement_object_type_list: [],
      },
    ],
  },

  {
    class_id: 22,
    code: 'cement_concrete_tiles',
    group_id: 1,
    id: 68,
    name: 'Цементобетонная плитка',
    ogh_object_type_list: [
      {
        ogh_object_type_id: 1,
        other_improvement_object_type_list: [],
      },
    ],
  },
];
