import React from 'react';

interface ButtonGroupProps {
  children: React.ReactNode;
}

/**
 * Компонент для группировки кнопок.
 *
 * @param props - Входные параметры.
 * @param props.children - Кнопки.
 * @returns Кнопки.
 */
export const ButtonGroup = ({ children }: ButtonGroupProps) => {
  return <div className="d-flex gap-2">{children}</div>;
};
