import { get } from 'lodash';


/* eslint-disable */
export default function (result, state, props) {
  const { card } = props;
  return {
    ...result,
    initialValues: {
      ...result.initialValues,
      snow_clean_area: get(card, 'attribute.snow_clean_area'),
    },
  };
}
