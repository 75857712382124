import { usePrintMafEais } from 'app/pages/registry/ogh/MafRfid/buttons/hooks/usePrintMafEais';
import { getNowDate } from 'app/utils/dateUtils';
import { Button } from 'core/uiKit/components/buttons/Button';
import { CircularProgress, GetAppIcon } from 'core/uiKit/material-ui';
import React from 'react';

/**
 * Комонент DownloadUaisbu.
 *
 * @returns {JSX.Element}
 * @class
 */
export const DownloadUaisbu = () => {
  const { printMafEais, isLoading } = usePrintMafEais();

  /**
   * Функция clickHandler.
   *
   * @returns {void}
   */
  const clickHandler = async () => {
    await printMafEais({ date_to: getNowDate() });
  };

  return (
    <Button
      color={'primary'}
      loading={isLoading}
      startIcon={isLoading ? <CircularProgress size={20} /> : <GetAppIcon />}
      variant={'contained'}
      onClick={clickHandler}
    >
      Скачать данные из УАИС БУ
    </Button>
  );
};
