import { uploadFile } from 'app/api/requests/uploadFiles';
import { RANDOM_ID, RANDOM_WORD } from 'core/forTesting/constants';
import { vi } from 'vitest';

import { sendRepairsInfoListPlan } from './sendRepairsInfoListPlan';

vi.mock('app/api/requests/uploadFiles');

describe('SendRepairsInfoListPlanSpec', () => {
  it('default', async () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    uploadFile.mockResolvedValue({ fileId: RANDOM_ID });
    const params = {
      files: [{ file: [] }],
      foo: RANDOM_WORD,
    };

    // 🔥 Act
    const res = await sendRepairsInfoListPlan(params);

    // ❓ Assert
    expect(res).toStrictEqual({
      file: {
        file: [],
      },
      resFile: {
        fileId: RANDOM_ID,
      },
    });
    expect(uploadFile).toHaveBeenCalledWith({ file: [] });
  });
});
