import { OkrugListRF } from 'core/form/reduxForm/fields/prepared/selects/multiSelect/OkrugList.RF';
import { DISTRICT_LIST_NAME } from 'core/uiKit/preparedInputs/selects/multiSelect/DistrictList';
import React from 'react';
 
/**
 * Компонент поля OkrugListField.
 *
 * @param {object} props - Пропсы.
 * @returns {React.ReactElement} - Компонент OkrugListRF.
 * @example
 * <OznTypeField {...props} />
 */
export const OkrugListField = (props) => {
   
/**
 * Функция изменения поля Район.
 *
 * @returns {void} - Nothing.
 * @example ----
 */
  const withChange = () => {
    const { change } = props;

    change(DISTRICT_LIST_NAME, []);
  };

  return (
    <OkrugListRF
      disabled={!props.editable}
      withChange={withChange}
      required
    />
  );
};
