/* eslint-disable */
export const dialog = {
  ADD_ORGANIZATION: 'DialogAddOrganization',
  ADD_REAGENT_NORM: 'DialogAddReagentNorm',
  APPROVED_MATCHING: 'DialogApprovedMatching',
  REJECTED_MATCHING: 'DialogRejectedMatching',
  SEND_MATCHING: 'DialogSendMatching',
  ASUGF_TITLE_LIST: 'DialogAsugf',
  DELETE_TITLE_LIST: 'DialogDeleteTitleList',
  EXPORT_PGM_SHIPMENTS: 'DialogExportPgmShipments',
  EXPORT_PGM_FULLNESS: 'DialogExportPgmFullness',
  FEED_BACK: 'DialogFeedBack',
};
