/* eslint-disable */
import React from 'react';
import { useField } from 'react-final-form';
import { KeyboardDatePickerFF } from 'core/form/finalForm/fields/default/datePickers/KeyboardDatePickerFF/KeyboardDatePickerFF';
import { WORK_END_DATE } from './WorkEndDate';

 
export const WORK_START_DATE = 'workstartdate';

const WorkStartDate = ({ ...props }) => {
  const dateFromInput = useField(WORK_END_DATE);
  return (
    <KeyboardDatePickerFF
      maxDate={dateFromInput?.input?.value}
      {...props}
      label="Дата начала работ"
      name={WORK_START_DATE}
    />
  );
};

 
export default WorkStartDate;
