import PropTypes from 'prop-types';
import React from 'react';

/* eslint-disable */
export default class FaIcon extends React.Component {
  static propTypes = {
    icon: PropTypes.string,
    className: PropTypes.string,
  };

  render() {
    const { icon, className = '', ...other } = this.props;

    return (
      <i
        aria-hidden={true}
        className={`fa fa-${icon} ${className}`}
        {...other}
      />
    );
  }
}
