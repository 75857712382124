/* eslint-disable */
import { shallow } from 'enzyme';
import { CloseOghButton } from './CloseOgh.Button';
import { useCloseOghButton } from './useCloseOghButton';

jest.mock('./useCloseOghButton');

/* eslint-disable */
describe('file CloseOghButton', () => {
  it('should simple text', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useCloseOghButton.mockReturnValue({
      isShow: true,
      onClose: (_) => _,
    });

    // Act
    const wrapper = shallow(<CloseOghButton />);
    // Assert
    expect(wrapper.text()).toBe('<Button />');
  });

  it('should simple text not comp', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useCloseOghButton.mockReturnValue({
      isShow: false,
      onClose: (_) => _,
    });

    // Act
    const wrapper = shallow(<CloseOghButton />);
    // Assert
    expect(wrapper.text()).toBe('');
  });

  it('should props', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const fn = jest.fn();
    useCloseOghButton.mockReturnValue({
      isShow: true,
      onClose: fn,
    });
    // Icon.fn((props) => props.children);
    // Act
    const wrapper = shallow(<CloseOghButton />);
    // Assert
    expect(wrapper.props()).toMatchObject({
      onClick: fn,
      variant: 'contained',
      children: 'закрыть',
    });
  });
});
