import { CREATE_TYPE_NAME } from 'core/uiKit/preparedInputs';
import { shallow } from 'enzyme';
import React from 'react';

import { CreateTypeField } from './index';

describe('🐛 CreateTypeFieldSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act
    const wrapper = shallow(<CreateTypeField />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<CreateTypeRF />');
    expect(wrapper.props()).toStrictEqual({
      disabled: true,
      name: CREATE_TYPE_NAME,
    });
  });
});
