import { useCallback, useMemo, useReducer, useRef } from 'react';

import { GeometryTypes, MappedGeometryInstances } from '../types.d';

const ACTIONS = {
  ADJACENT: 'adjacent',
  ALL_CHILDREN: 'allChildren',
  CHILDREN: 'children',
  COPY_DIFF: 'copyDiff',
  DISTRICTS: 'districts',
  INTERSECTIONS: 'intersections',
  ORIGINAL_DIFF: 'originalDiff',
  PARENT: 'parent',
  REON_AREA: 'reonArea',
  SELECTED: 'selected',
  SET_ALL: 'setAll',
} as const;

const defaultMappedInstances: MappedGeometryInstances = {
  [GeometryTypes.Point]: [],
  [GeometryTypes.Polygon]: [],
  [GeometryTypes.Polyline]: [],
};

const initialState = {
  adjacent: { ...defaultMappedInstances },
  allChildren: { ...defaultMappedInstances },
  children: { ...defaultMappedInstances },
  copyDiff: { ...defaultMappedInstances },
  districts: { ...defaultMappedInstances },
  intersections: { ...defaultMappedInstances },
  originalDiff: { ...defaultMappedInstances },
  parent: { ...defaultMappedInstances },
  reonArea: { ...defaultMappedInstances },
  selected: { ...defaultMappedInstances },
};

type InitialState = typeof initialState;
type ActionTypes = (typeof ACTIONS)[keyof typeof ACTIONS];

/**
 * Reducer.
 *
 * @param state - Состояние.
 * @param root0 - Объект события.
 * @param root0.type - Тип события.
 * @param root0.payload - Полезная нагрузка.
 * @returns Новое состояние.
 */
const reducer = (
  state: InitialState,
  {
    type,
    payload,
  }: {
    type: ActionTypes;
    payload: InitialState | MappedGeometryInstances;
  },
) => {
  if (type === 'setAll') return { ...state, ...payload };

  return { ...state, [type]: payload };
};

/**
 * Get all instances.
 *
 * @param instances - Состояние.
 * @param type - Тип.
 * @param dispatch - Редьюсер.
 * @returns Все инстансы.
 */
const useGetInstances = (
  instances: InitialState,
  type: Exclude<ActionTypes, 'setAll'>,
  dispatch: React.Dispatch<{
    type: ActionTypes;
    payload: MappedGeometryInstances;
  }>,
): [
  React.MutableRefObject<MappedGeometryInstances>,
  () => MappedGeometryInstances,
  (mappedInstance: MappedGeometryInstances) => void,
] => {
  const instancesRef = useRef(instances[type]);
  const getInstances = useCallback(() => instancesRef.current, []);
  const setInstances = useCallback(
    (mappedInstance: MappedGeometryInstances) => {
      instancesRef.current = mappedInstance;
      dispatch({ payload: mappedInstance, type });
    },
    // eslint-disable-next-line
    [],
  );

  return [instancesRef, getInstances, setInstances];
};

/**
 * Use geometry instances.
 *
 * @returns Инстансы.
 */
export const useGeometryInstances = () => {
  const [instances, dispatch] = useReducer(reducer, initialState);

  const [intersectionsRef, getIntersections, setIntersections] =
    useGetInstances(instances, 'intersections', dispatch);

  const [districtsRef, getDistricts, setDistricts] = useGetInstances(
    instances,
    'districts',
    dispatch,
  );

  const [parentRef, getParent, setParent] = useGetInstances(
    instances,
    'parent',
    dispatch,
  );

  const [selectedRef, getSelected, setSelected] = useGetInstances(
    instances,
    'selected',
    dispatch,
  );

  const [childrenRef, getChildren, setChildren] = useGetInstances(
    instances,
    'children',
    dispatch,
  );

  const [allChildrenRef, getAllChildren, setAllChildren] = useGetInstances(
    instances,
    'allChildren',
    dispatch,
  );

  const [adjacentRef, getAdjacent, setAdjacent] = useGetInstances(
    instances,
    'adjacent',
    dispatch,
  );

  const [reonAreaRef, getReonArea, setReonArea] = useGetInstances(
    instances,
    'reonArea',
    dispatch,
  );

  const [copyDiffRef, getCopyDiff, setCopyDiff] = useGetInstances(
    instances,
    'copyDiff',
    dispatch,
  );

  const [originalDiffRef, getOriginalDiff, setOriginalDiff] = useGetInstances(
    instances,
    'originalDiff',
    dispatch,
  );

  return useMemo(() => {
    const result = {
      adjacent: {
        get: getAdjacent,
        instances: adjacentRef.current,
        set: setAdjacent,
      },
      allChildren: {
        get: getAllChildren,
        instances: allChildrenRef.current,
        set: setAllChildren,
      },
      children: {
        get: getChildren,
        instances: childrenRef.current,
        set: setChildren,
      },
      copyDiff: {
        get: getCopyDiff,
        instances: copyDiffRef.current,
        set: setCopyDiff,
      },
      districts: {
        get: getDistricts,
        instances: districtsRef.current,
        set: setDistricts,
      },
      intersections: {
        get: getIntersections,
        instances: intersectionsRef.current,
        set: setIntersections,
      },
      originalDiff: {
        get: getOriginalDiff,
        instances: originalDiffRef.current,
        set: setOriginalDiff,
      },
      parent: {
        get: getParent,
        instances: parentRef.current,
        set: setParent,
      },
      reonArea: {
        get: getReonArea,
        instances: reonAreaRef.current,
        set: setReonArea,
      },
      selected: {
        get: getSelected,
        instances: selectedRef.current,
        set: setSelected,
      },
    };

    /**
     * Возвращает все геометрии.
     *
     * @returns Объект с геометриями.
     */
    const getAll = () => {
      return Object.entries(result).reduce((acc, [key, value]) => {
        acc[key as ActionTypes] = value.get();
        return acc;
      }, {} as { [key in ActionTypes]: MappedGeometryInstances });
    };

    /**
     *
     * Устанавливает все геометрии.
     *
     * @param allGeometries - Объект с геометриями.
     * @returns Объект с геометриями.
     */
    const setAll = (
      allGeometries: Partial<
        Record<Exclude<ActionTypes, 'setAll'>, MappedGeometryInstances>
      >,
    ) => {
      dispatch({
        payload: { ...getAll(), ...allGeometries },
        type: ACTIONS.SET_ALL,
      });

      adjacentRef.current = allGeometries.adjacent ?? adjacentRef.current;
      allChildrenRef.current =
        allGeometries.allChildren ?? allChildrenRef.current;
      childrenRef.current = allGeometries.children ?? childrenRef.current;
      copyDiffRef.current = allGeometries.copyDiff ?? copyDiffRef.current;
      districtsRef.current = allGeometries.districts ?? districtsRef.current;
      intersectionsRef.current =
        allGeometries.intersections ?? intersectionsRef.current;
      originalDiffRef.current =
        allGeometries.originalDiff ?? originalDiffRef.current;
      parentRef.current = allGeometries.parent ?? parentRef.current;
      reonAreaRef.current = allGeometries.reonArea ?? reonAreaRef.current;
      selectedRef.current = allGeometries.selected ?? selectedRef.current;

      return getAll();
    };

    return {
      ...result,
      getAll,
      setAll,
    };
    // eslint-disable-next-line
  }, [instances]);
};
