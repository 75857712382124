import { AutocompleteAsync } from 'core/uiKit/inputs/selects/AutocompleteAsync';
import { fetchCustomers } from 'core/uiKit/preparedInputs/selects/api';
import React from 'react';

export const CUSTOMER_NAME = 'customerId';

/**
 * ## Заказчик.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const Customer = (props) => {
  return (
    <AutocompleteAsync
      {...props}
      fetchFunction={fetchCustomers()}
      label={'Заказчик'}
      name={CUSTOMER_NAME}
    />
  );
};
