import React from 'react';

/**
 * Cell - Да или Нет.
 * Да - true, Нет - false, Пусто - null.
 *
 * @param props - Properties.
 * @param props.value - Value.
 * @returns {JSX.Element}
 */
export const YesOrNoCell = ({ value }: { value?: boolean }) => {
  const text = value ? 'да' : 'нет';

  return <span>{text}</span>;
};
