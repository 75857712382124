/* eslint-disable */
import React from 'react';
import { StringParam } from 'use-query-params';

import { INSTALL_DUT_NAME, InstallDutFF } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
 
/**
 * Не подлежит оснащению ДУТ
 * installDut
 */
 
export const InstallDutFilter = (props) => (
  <InstallDutFF {...props} className={filterInput} />
);

 
export { INSTALL_DUT_NAME };

 
export const installDutParams = {
  [INSTALL_DUT_NAME]: StringParam,
};
