/* eslint-disable */
import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import {
  MatchingChangeTypes,
  MATCHING_CHANGE_TYPES_NAME,
} from 'core/uiKit/preparedInputs';

 
 
/**
 * ## Операция
 * ```js
 * import {
 *   MatchingChangeTypesFF,
 *   MATCHING_CHANGE_TYPES_NAME,
 * } from 'core/form/finalForm/fields';
 * ```
 *
 * ### React Final Form Field
 *
 * `type = MultiSelect`
 * `name = matchingChangeTypes`
 * `dict = matchingChangeType`
 */
/* eslint-disable */
export const MatchingChangeTypesFF = ({ name, ...otherProps }) => {
  return (
    <Field name={MATCHING_CHANGE_TYPES_NAME}>
      {(props) => {
        return (
          <MatchingChangeTypes {...props} {...props.input} {...otherProps} />
        );
      }}
    </Field>
  );
};

 
export { MATCHING_CHANGE_TYPES_NAME };

MatchingChangeTypesFF.propTypes = {
   
   
/**
   * выбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * функция для фильтрации options
   */
  filter: PropTypes.func,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
};
MatchingChangeTypesFF.defaultProps = {
  dark: false,
  disabled: false,
  errorText: '',
  filter: (_) => _,
  helperText: '',
  required: false,
};
