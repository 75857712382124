/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */

/**
 * Колонка __Округ родительского объекта__.
 *
 * @type {{
 * accessor: 'parent_district_name',
 * Header: 'Район родительского объекта',
 * sortable: true,
 * }}
 * @augments Cell
 */
export const parent_district_name = {
  Header: 'Район родительского объекта',
  accessor: 'parent_district_name',
  sortable: true,
};
