/* eslint-disable  */
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { parseDateToMoment } from 'app/utils/date/parseDateToMoment';
import { TextField } from 'core/uiKit/inputs/TextField';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

/**
 * Выбор даты.
 *
 * @param {object} props - Props.
 * @param {object} props.value - Значение типа Moment.
 * @param {Function} [props.onBlur] - Функция на блюр элемента (просто вытягиваем что бы не передавать в комп а то глюки).
 * @param {string} [props.error] - Ошибки.
 * @param {string} [props.maxDate] - Максимальная дата '01.01.3000'.
 * @param {string} [props.minDate] - Максимальная дата '01.01.3000'.
 * @param {string} [props.minDateMessage] - Сообщение о минимальной дате.
 * @param {string} [props.maxDateMessage] - Сообщение о максимальной дате.
 * @param {boolean} [props.required] - Флаг обязательности поля.
 * @returns {JSX.Element} - JSX.
 * @example -----
 */
export const DatePicker = ({
  value,
  error,
  maxDate,
  minDate,
  minDateMessage,
  maxDateMessage,
  required,
  ...otherProps
}) => {
  const propsData = useMemo(() => {
    return error
      ? {
          error: !!error,
          helperText: error,
        }
      : {
          maxDateMessage,
          minDateMessage,
        };
  }, [error, minDateMessage, maxDateMessage]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'ru'}>
      <DesktopDatePicker
        inputFormat={'DD.MM.YYYY'}
        renderInput={(params) => (
          <TextField
            {...{
              ...params,
              ...propsData,
              inputProps: {
                ...params.inputProps,
                readOnly: true,
              },
              required,
            }}
          />
        )}
        {...otherProps}
        maxDate={parseDateToMoment(maxDate)}
        minDate={parseDateToMoment(minDate)}
        value={value || null}
      />
    </LocalizationProvider>
  );
};

DatePicker.propTypes = {
  /**
   * Быбор темной темы.
   */
  dark: PropTypes.bool,

  /**
   * Заблокировать на редактирование.
   */
  disabled: PropTypes.bool,

  /**
   * Вывод ошибки.
   */
  errorText: PropTypes.string,

  /**
   * Вывод вспомогательного текста.
   */
  helperText: PropTypes.string,
  label: PropTypes.string,

  /**
   * Имя селекта в форме.
   */
  name: PropTypes.string,

  /**
   * Функция вызова когда выберется итем.
   */
  onChange: PropTypes.func,

  /**
   * Вывод информации об обязательном поле.
   */
  required: PropTypes.bool,

  /**
   * Значение ParsableDate.
   */
  value: PropTypes.any,
};

DatePicker.defaultProps = {
  label: 'KeyboardDatePicker',
  maxDate: '01.01.3001',
  maxDateMessage:
    'Дата С не может быть позже даты По. Укажите корректное значение.',
  minDateMessage:
    'Дата По не может быть раньше Даты С. Укажите корректное значение.',
  name: 'KeyboardDatePicker',
};
