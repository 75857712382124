import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useMapContext } from 'app/components/map/useMapContext';
import { FOREGROUND } from 'app/constants/layers';
import { useGeometryMapCurrent } from 'app/pages/cardsOgh/components/Map/MapEditPanel/hooks/useGeometryMapCurrent';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import {
  CREATE_CARD,
  CREATE_FROM_PARENT,
  UPDATE_CARD,
  UPDATE_FROM_PARENT,
  useTypeActionCard,
} from 'app/pages/cardsOgh/hooks/useTypeActionCard';
import { pathOgh } from 'app/pages/registry/ogh/Ogh/path.Ogh';
import { pathRegistry } from 'app/pages/registry/path.Registry';
import { toast } from 'core/uiKit/components/Toast';
import { useGetParamsUrl } from 'core/utils/hooks/useGetParamsUrl';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import { useNavigateRouter } from 'core/utils/router';
import { useState } from 'react';

import { startFetchCansel } from './api/startFetchCansel';

/**
 * Общий хук для Кнопки Отмены.
 *
 * @param resetForm - Функция сброса формы.
 * @returns JSX.
 */
export const useSetupCancelButtonEgip = (resetForm: () => void) => {
  const { editMode, clearEditMode } = useMode();
  const [dialog, setDialog] = useState(false);
  const { rootId, recordId, typeId } = useGetCurrentCard();
  const navigate = useNavigateRouter();
  const type = useTypeActionCard();

  // @ts-ignore
  const { clearLayer, drawCurrentObjectsGeometry } = useMapContext();
  const { dataMapCurrent } = useGeometryMapCurrent();

  const { childId, id, oghRegistry } = useGetParamsUrl();

  /**
   * Назад в реестры.
   *
   * @returns {void}
   */
  const goBackToRegistry = (): void => {
    const urlBack = [
      pathRegistry.path,
      pathOgh.path,
      // @ts-ignore
      oghRegistry?.parent ?? '',
      // @ts-ignore
      oghRegistry?.child ?? '',
    ]
      .filter(Boolean)
      .join('/');

    navigate('/' + urlBack);
  };

  const [sendDialog, { isLoading }] = useMutationAdaptor(
    () => {
      return startFetchCansel({ recordId, rootId, typeId });
    },
    {

      /**
       * Функция для обработки ошибки.
       *
       * @param {object} error - Error.
       * @param {string} error.message - Message Error.
       * @returns {void}
       */
      onError(error?: { message?: string }): void {
        toast.errorDyingLong(
          error?.message
            ? error.message
            : 'Произошла ошибка при отмене паспорта',
        );
      },

      /**
       * Функция при успешной обработки.
       *
       * @returns {void}
       */
      onSuccess() {
        clearEditMode();
        switch (type) {
          case UPDATE_FROM_PARENT: {
            navigate(`/ogh/${id}/${childId}`);
            clearLayer(FOREGROUND);
            drawCurrentObjectsGeometry(dataMapCurrent);
            break;
          }
          case UPDATE_CARD: {
            clearLayer(FOREGROUND);
            drawCurrentObjectsGeometry(dataMapCurrent);
            break;
          }
          default:
            break;
        }
        resetForm();
        setDialog(false);
      },
    },
  );

  /**
   * Function close Dialog.
   *
   * @returns {void}
   */
  const closeDialog = () => {
    setDialog(false);
  };

  /**
   * Handler click button.
   *
   * @returns {void}
   */
  const handleCancelButtonClick = () => {
    switch (type) {
      case CREATE_CARD: {
        return goBackToRegistry();
      }
      case CREATE_FROM_PARENT: {
        return navigate(`/ogh/${id}`);
      }
      default: {
        return setDialog(true);
      }
    }
  };

  return {
    closeDialog,
    dialog,
    editMode,
    handleCancelButtonClick,
    isLoading,
    sendDialog,
  };
};
