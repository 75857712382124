import { CoatingTypeIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/CoatingTypeId.RF';
import React from 'react';

import { useCoatingTypeRfTramRails } from './useCoatingTypeRfTramRails';

/**
 * Обертка для фильтрации поля __Вид покрытия на путях__.
 *
 * @returns {JSX.Element}
 */
export const CoatingTypeRfTramRails = () => {
  const { editMode, filter, formValues, isCurrentObjectIsExternalSystem } =
    useCoatingTypeRfTramRails();
  return (
    <CoatingTypeIdRF
      disabled={
        !(editMode && formValues?.coating_group_id) ||
        isCurrentObjectIsExternalSystem
      }
      required={true}
      filter={filter}
    />
  );
};
