import { RANDOM_WORD } from 'core/forTesting/constants';
import { shallow } from 'enzyme';

import { TextFieldFFWrapper } from './TextFieldFF.Wrapper';

 
describe('file TextFieldFfWrapperSpec', () => {
  it('props', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const map = {
      Header: RANDOM_WORD,
      accessor: RANDOM_WORD + 1,
    };

    // Act
    const wrapper = shallow(<TextFieldFFWrapper map={map} />);

    // Assert
    expect(wrapper.props()).toMatchObject({
      label: RANDOM_WORD,
      name: RANDOM_WORD + 1,
    });
  });

  it('text', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const map = {
      Header: RANDOM_WORD,
      accessor: RANDOM_WORD + 1,
    };

    // Act
    const wrapper = shallow(<TextFieldFFWrapper map={map} />);

    // Assert
    expect(wrapper.text()).toBe('<TextFieldFF />');
  });
});
