/* eslint-disable */
import React from 'react';
import _ from 'lodash';

import Out from 'app/components/registry/Out';

import 'assets/styles/card.tree.treeEdit.scss';

 
export default class Table extends React.Component {
  constructor(props) {
    super(props);
    this.dateFormater = new Intl.DateTimeFormat('ru');
  }

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.data !== this.props.data ||
      nextProps.sort !== this.props.sort ||
      nextProps.checkedRows !== this.props.checkedRows
    );
  }

  getTable() {
    return _.get(this.props, 'data.table') || [];
  }

  renderColumn(row, col) {
    const { props } = this;
    const rowVal = row[col.id];
    let content;

    if (col.id === 'checkbox') {
      content = (
        <input checked={!!props.checkedRows[row.uuid]} type="checkbox" />
      );
    } else if (col.id === 'root_id') {
      content = rowVal;
    } else if (col.id === 'use_date') {
      content = rowVal && this.dateFormater.format(rowVal);
    } else {
      content =
        col.checkbox || _.isBoolean(rowVal) ? (
          <input checked={rowVal} disabled={true} type="checkbox" />
        ) : (
          rowVal
        );
    }

    return (
      <td className={`${col.id}__col`} key={`td-${col.id}`}>
        {content}
      </td>
    );
  }

  renderMain() {
    const table = this.getTable();
    return table.map((row) => this.renderRow(row));
  }

  renderRow(row) {
    const { props } = this;
    return (
      <tr
        key={`tr-${row.uuid}`}
        onClick={() => {
          const isInputChecked = !props.checkedRows[row.uuid];
          props.onCheck(row, isInputChecked);
        }}
      >
        {props.columns.map((col) => this.renderColumn(row, col))}
      </tr>
    );
  }

  render() {
    const { props } = this;
    return (
      <Out
        columns={props.columns}
        data={props.data}
        main={this.renderMain()}
        sort={props.sort}
        onLimit={props.onLimit}
        onPagination={props.onPagination}
        onSort={props.onSort}
      />
    );
  }
}
