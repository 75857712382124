import {
  formatGeometriesToUpdate,
  GeoJSONCoordinatesFormatter,
} from 'core/uiKit/components/DTW/contexts/utils';
import MapService from 'core/uiKit/components/DTW/contexts/utils/MapService';
import { GeometryTypes } from 'core/uiKit/components/DTW/contexts/utils/types.d';
import { v4 } from 'uuid';

/**
 * Функция получения подсказки.
 *
 * @param {Array<string, number>} string - Id; item - Берется из getDistrictBoundaries.
 * @param {MapService} mapService - Сервис карты.
 * @returns {*} - Преобразованные данные.
 * @example
 * const intersections = toArray(data)
 *  intersections.map(createObjectForMap)
 */
export const createObjectForMapDTW = ([id, item], mapService) => {
  const hintFields = [
    {
      key: 'title',
      rawValue: 'Границы районов',
      value: 'Границы районов',
    },
    {
      key: 'full_name',
      rawValue: item.full_name,
      value: `Наименование Района: ${item.full_name}`,
    },
    {
      key: 'name_ao',
      rawValue: item.name_ao,
      value: `Наименование округа: ${item.name_ao}`,
    },
  ];

  const hint = hintFields.filter((item) => item.rawValue);
  const finalHint = {
    object_attribute_hint: hint,
    object_attribute_hint_short: [
      {
        key: 'name',
        value: item.full_name,
      },
    ],
  };
  item.geometry.polygons = item.geometry.polygons
    ? item.geometry.polygons.map((polygon) => ({
        ...polygon,
        coordinates: GeoJSONCoordinatesFormatter.polygonToGeoJSON(
          polygon.coordinates,
        ),
        type: GeometryTypes.Polygon,
      }))
    : null;

  item.geometry.lines = item.geometry.lines
    ? item.geometry.lines.map((line) => ({
        ...line,
        coordinates: GeoJSONCoordinatesFormatter.polylineToGeoJSON(
          line.coordinates,
        ),
        type: GeometryTypes.Polyline,
      }))
    : null;

  item.geometry.points = item.geometry.points
    ? item.geometry.points.map((point) => ({
        ...point,
        coordinates: GeoJSONCoordinatesFormatter.polygonToGeoJSON(
          point.coordinates,
        ),
        type: GeometryTypes.Point,
      }))
    : null;

  item.geometry = {
    ...item.geometry,
    hint: finalHint,
    id,
    layerType: 'districts',
  };

  item.geometry = formatGeometriesToUpdate(
    item.geometry,
    'districts',
    mapService,
  ).map((geometryItem) => ({
    ...geometryItem,
    options: {
      ...geometryItem.options,
      userData: {
        ...geometryItem.options.userData,
        id: `${id}-${v4()}`,
      },
    },
  }));

  return item.geometry;
};
