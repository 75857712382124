/* eslint-disable */
import { shallow } from 'enzyme';

import { RANDOM_ID, RANDOM_WORD } from 'core/forTesting/constants';
import { requiredFields } from './validate';

import { NumberFieldFFWrapper } from './NumberFieldFF.Wrapper';

jest.mock('./validate');
jest.mock('app/api/hooks/useIsCurrentObjectIsExternalSystem');

/* eslint-disable */
describe('file NumberFieldFfWrapperSpec', () => {
  it('props', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const map = {
      Header: RANDOM_WORD,
      accessor: RANDOM_WORD + 1,
      decimalScale: RANDOM_ID,
    };

    // Act
    const wrapper = shallow(<NumberFieldFFWrapper map={map} />);

    // Assert
    expect(wrapper.props()).toMatchObject({
      label: RANDOM_WORD,
      name: RANDOM_WORD + 1,
      defaultValue: 0,
      required: true,
      decimalScale: RANDOM_ID,
    });
  });

  it('text', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const map = {
      Header: RANDOM_WORD,
      accessor: RANDOM_WORD + 1,
      decimalScale: RANDOM_ID,
    };

    // Act
    const wrapper = shallow(<NumberFieldFFWrapper map={map} />);

    // Assert
    expect(wrapper.text()).toBe('<NumberFieldFF />');
  });

  it('requiredFields', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const map = {
      accessor: RANDOM_WORD,
    };
    const pushSpy = jest.spyOn(requiredFields, 'push');
    // Act
    shallow(<NumberFieldFFWrapper map={map} />);

    // Assert
    expect(pushSpy).toHaveBeenCalledWith(RANDOM_WORD);
  });
});
