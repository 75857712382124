import { getRegistryOgh } from 'app/api/requests/getRegistryOgh';
import { getRegistryOghCount } from 'app/api/requests/getRegistryOghCount';

import * as types from '../constants/actionTypes';

/**
 * Action searchInRegistry.
 *
 * @returns {*}
 */
export function searchInRegistry() {
  return {
    type: types.REQUEST_SEARCH_IN_REGISTRY,
  };
}

/**
 * Action receiveSearchInRegistryResults.
 *
 * @param {*} object - Data to fetch.
 * @returns {*}
 */
export function receiveSearchInRegistryResults(object) {
  return {
    object,
    type: types.RECEIVE_SEARCH_IN_REGISTRY_RESULTS,
  };
}

/**
 * Action receiveRegistryForTree.
 *
 * @param {*} object - Data to fetch.
 * @returns {*}
 */
export function receiveRegistryForTree(object) {
  return {
    object,
    type: types.RECEIVE_REGISTRY_FOR_TREE,
  };
}

/**
 * Action receiveRegistryForTreeGroup.
 *
 * @param {*} object - Data to fetch.
 * @returns {*}
 */
export function receiveRegistryForTreeGroup(object) {
  return {
    object,
    type: types.RECEIVE_REGISTRY_FOR_TREE_GROUP,
  };
}

/**
 * Action receiveRegistryActualGroup.
 *
 * @param {*} object - Data to fetch.
 * @returns {*}
 */
export function receiveRegistryActualGroup(object) {
  return {
    object,
    type: types.RECEIVE_REGISTRY_ACTUAL_GROUP,
  };
}

/**
 * Action fetchRegistrySearchResults.
 *
 * @param {string} registryType - Type of registry.
 * @param {*} data - Data to fetch.
 * @param {*} receiver - Receive.
 * @returns {*}
 */
export function fetchRegistrySearchResults(registryType, data, receiver) {
  return async (dispatch) => {
    dispatch(searchInRegistry());

    const table = await getRegistryOgh('', data);
    const { count: total } = await getRegistryOghCount('', data);
    if (receiver) {
      dispatch(
        receiver({
          meta: { ...data, total },
          table,
        }),
      );
    } else {
      if (table === undefined) {
        // window.location = '/login';
      }
      dispatch(
        receiveSearchInRegistryResults({ meta: { ...data, total }, table }),
      );
    }
  };
}

/**
 * Action exportRegistry.
 *
 * @param {string} registryType - Type of registry.
 * @returns {*}
 */
export function exportRegistry(registryType) {
  const apiPath = registryType ? `/export/xlsx/${registryType}` : null;
  return {
    apiPath,
    type: types.EXPORT_REGISTRY,
  };
}
