import { getVisibilityButtonsOgh } from 'app/api/requests/getVisibilityButtonsOgh';
import { queryClient } from 'app/routes/QueryClientProvider';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import { useGetParamsUrl } from 'core/utils/hooks/useGetParamsUrl';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery/useQueryAdaptor';

/**
 * Метод, получения видимости кнопок.
 *
 * @category hooks
 * @param {number | string} [objectId] - Id объекта.
 * @returns {*}
 */
export const useGetVisibilityButtonsOgh = (objectId) => {
  const { parentId } = useGetParamsUrl();
  const { objectId: objectIdLocal } = useGetObjectId();

  return useQueryAdaptor(
    [
      'getVisibilityButtonsOgh',
      { objectId: objectId || objectIdLocal, parentId },
    ],
    getVisibilityButtonsOgh,
    {
      cacheTime: 0,
      staleTime: 0,
    },
  );
};
export const clearCahsGetVisibilityButtonsOgh = queryClient.invalidateQueries({
  queryKey: ['getVisibilityButtonsOgh'],
});
