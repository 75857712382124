 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Подвесные вертикальные конструкции, шт.__.
 *
 * @type {{
 * accessor: 'suspend_vertical_design_qty',
 * Header: 'Подвесные вертикальные конструкции, шт.',
 * }}
 * @augments Cell
 */
export const suspend_vertical_design_qty = {
  accessor: 'suspend_vertical_design_qty',
  Header: 'Подвесные вертикальные конструкции, шт.',
  sortable: true,
};
