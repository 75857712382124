import { uploadFile } from 'app/actions/uploadActions';
import { post } from 'app/api/crud/post';

/**
 * External matching bounds.
 *
 * @param {object} root0 - Properties.
 * @param {Array} root0.files - Files.
 * @param {object} root0.queryParams - Query params.
 * @returns {Promise}
 */
export const externalMatching = async ({ files, ...queryParams }) => {
  let fileIds;
  if (files?.length) {
    fileIds = await Promise.all(
      files.map(async (file) => {
        const contents = await uploadFile(file);
        return contents.fileId;
      }),
    );
  }

  return post('/ogh/boundary/matching/send', { ...queryParams, fileIds });
};

/**
 * Approve external matching bounds.
 *
 * @param {object} queryParams - Query params.
 * @returns {Promise}
 */
export const approveMatchingBounds = async (queryParams) => {
  const response = await post('/ogh/boundary/matching/approve', queryParams);
  return response;
};

/**
 * Reject external matching bounds.
 *
 * @param {object} root0 - Properties.
 * @param {Array} root0.files - Files.
 * @param {object} root0.queryParams - Query params.
 * @returns {Promise}
 */
export const rejectMatchingBounds = async ({ files, ...queryParams }) => {
  let fileIds;
  if (files?.length) {
    fileIds = await Promise.all(
      files.map(async (file) => {
        const contents = await uploadFile(file);
        return contents.fileId;
      }),
    );
  }
  return post('/ogh/boundary/matching/correction', { ...queryParams, fileIds });
};
