import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { ODH_AXIS_TOOLTIP } from 'app/constants/messages';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { TextFieldRF } from 'core/form/reduxForm/fields/default/TextFieldRF';
import React from 'react';

export const ODH_AXIS_NAME = 'odh_axis';

/**
 * Text FieldRF __Ось__.
 *
 * @returns
 */
export const OdhAxisField = () => {
  const { editMode } = useMode();
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();
  return (
    <TextFieldRF
      name={ODH_AXIS_NAME}
      label={'Ось'}
      maxLength={2}
      tooltip={ODH_AXIS_TOOLTIP}
      disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
    />
  );
};
