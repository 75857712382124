/* eslint-disable */
import React from 'react';
import { Body, Header, Main, RegistryToolbar } from 'app/pages/components';

import { TableEquipmentReport } from './Table.EquipmentReport';
import { PrintButtonEquipmentReport } from './PrintButton.EquipmentReport';
import { FilterEquipmentReport } from './Filter.EquipmentReport';

const breadcrumbs = [
  { url: '/report/equipment', name: 'Отчет по навесному оборудованию' },
];

 
export const EquipmentReport = () => {
  return (
    <Main>
      <Header Filter={FilterEquipmentReport} breadcrumbs={breadcrumbs} />
      <Body>
        <RegistryToolbar title={'Отчет по навесному оборудованию'}>
          <PrintButtonEquipmentReport />
        </RegistryToolbar>
        <TableEquipmentReport />
      </Body>
    </Main>
  );
};
