import { showAlert, showNotification } from 'app/actions/dialog';
import {
  afterReceiving,
  cancelEditCard,
  cancelLeaveCurrentElement,
  fetchPreviewCard,
  sendCreateCard,
  sendDeleteCard,
  sendUpdateCard,
  viewCard,
} from 'app/actions/odsObjectActions';
import {
  fetchFewTree,
  fetchTreeSelectedElement,
} from 'app/actions/odsObjectTreeActions';
import { toggleCompareReportVisibility } from 'app/actions/odsObjectVersionsActions';
import { deleteTechnology, fetchTechnik } from 'app/actions/technologyObject';
import { get } from 'lodash';
import { connect } from 'react-redux';

import TechCard from './card';
/* eslint-disable */
function mapStateToProps(state = {}) {
  return {
    card: state.card?.object,
    mode: state.card?.mode,
    versionsModal: state.modals?.versions,
    treeModal: state.modals?.treeEdit,
    treeTechModal: state.modals?.treeEdit,
    groupParent: state.modals?.groupParent,
    treeEditTree: state.modals?.treeEditTree,
    matching: state.matching?.data,
    matchingRegistryColumns: state.tableColumns?.objectMatchingRegistry,
    action: state.matching?.afterAction,
    goToRegistry: state.matching?.goToRegistry,
    parentTypeId: get(state, 'registry.data.meta.parentTypeId'),
  };
}

const mapDispatchToProps = {
  viewCard,
  afterReceiving,
  fetchRootCard: fetchTechnik,
  fetchPreviewCard,
  sendUpdateCard: sendUpdateCard('tech'),
  sendCreateCard: sendCreateCard('tech'),
  sendDeleteCard: sendDeleteCard('tech'),
  cancelEditCard,
  cancelLeaveCurrentElement,
  toggleCompareReportVisibility,
  showAlert,
  showNotification,
  fetchTreeSelectedElement,
  fetchFewTree,
  deleteTechnology,
};

 
export default connect(mapStateToProps, mapDispatchToProps)(TechCard);
