/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */

/**
 * Колонка __Способ уборки__.
 *
 * @type { { accessor: 'clean_type_name', Header: 'Способ уборки' }}
 * @augments Cell
 */
export const clean_type_name = {
  Header: 'Способ уборки',
  accessor: 'clean_type_name',
  sortable: true,
};
