import { getFieldError } from 'app/utils/validate/getFieldError';
/* eslint-disable  */
import { Button } from 'core/uiKit/components/buttons/Button';
import { FileSignatureCryptoPro } from 'core/uiKit/inputs/FileSignatureCriptoPro';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React, { useEffect, useState } from 'react';
import { useField } from 'react-final-form';

import { useGetInfoCert } from './useGetInfoCert';

export const SIGNATURES_NAME = 'signatures';

export const CERTIFICATE_NAME = 'certificate';

/**
 * Получение файла подписи для final form.
 *
 * @param {object} props - Пропсы.
 * @param {Array<File>} props.files - Массив файлов.
 * @param {boolean} props.clear - Флаг очищения подписи.
 * @param {Function} props.callbackError - Функция вызывающаяся если происходит ошибка.
 * @returns {JSX.Element}
 */
export const FileSignatureCryptoProFF = ({ files, clear, callbackError }) => {
  const [certificate, setCertificate] = useState();
  const { input: { onChange: setSignatures } = {}, meta } =
    useField(SIGNATURES_NAME);
  const { input: { onChange: onSelect } = {} } = useField(CERTIFICATE_NAME);
  const { data: signInfo = {} } = useGetInfoCert(certificate);

  const error = getFieldError(meta);

  useEffect(() => {
    onSelect(signInfo);
  }, [Object.entries(signInfo).toString()]);

  /**
   * Функция добавления файлов подписи.
   *
   * @param {Array} signatures - Массив файлов подписи.
   * @returns {void}
   */
  const onChange = (signatures) => {
    const filesSignatures = signatures.map(
      (item) =>
        new File([item.sign], item.fileNameSign, {
          type: 'application/pgp-signature',
        }),
    );
    setSignatures(filesSignatures);
  };

  return (
    <div className={'py-3'}>
      <FileSignatureCryptoPro
        {...{
          /**
           *
           * @param props
           */
          ButtonComponent: (props) => (
            <Button
              {...props}
              color={'primary'}
              variant={'contained'}
              className={'mx-2'}
            >
              Подписать
            </Button>
          ),

          /**
           *
           * @param props
           */
          SelectComponent: (props) => (
            <Select
              {...props}
              error={error}
              errorText={error}
              withAll={false}
              optionKey={'value'}
              optionName={'label'}
              label={'Выберите сертификат для подписи'}
            />
          ),
          callbackError,
          clear, // флаг очищения подписи
          files, // самм файлы для подписи
          onChange,
          onSelect: setCertificate,
        }}
      />
    </div>
  );
};
