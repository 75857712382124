import { FieldRF } from 'core/form/reduxForm/helpers/FieldRF';
import {
  DEPARTMENT_ID_NAME,
  DepartmentId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/DepartmentId';

/**
 * ## Отраслевой ОИВ. ReduxForm
 *
 * @param {object} props - Parameters.
 * @param {string} props.name - Name.
 * @returns {JSX.Element}
 */
export const DepartmentIdRF = (props) => {
  return (
    <FieldRF {...props} name={DEPARTMENT_ID_NAME} component={DepartmentId} />
  );
};
