import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

import { mapCoatingFaceTypeId } from './mapCoatingFaceTypeId';

export const COATING_FACE_TYPE_ID_NAME = 'coating_face_type_id';
interface CoatingFaceTypeIdProps
  extends React.HTMLAttributes<HTMLSelectElement> {}

/**
 * Вид покрытия (облицовка).
 *
 * @param {object} props - Properties.
 * @returns {JSX.Element}
 */
export const CoatingFaceTypeId = (
  props: CoatingFaceTypeIdProps,
): JSX.Element => {
  const dict = 'coatingType';
  const { data = [] } = useGetDictionary(dict);

  const options = mapCoatingFaceTypeId(data) || [];

  return (
    <Select
      {...props}
      label={'Вид покрытия (облицовка)'}
      options={options}
      name={COATING_FACE_TYPE_ID_NAME}
    />
  );
};
