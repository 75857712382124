import { useGetOghTypes } from './useGetOghTypes';

 
/* eslint-disable */
/**
 * @author bad4iz
 * @function
 * @name useGetOghType
 * @description получаем объект тип одх по id или коду
 * @param {string|number} idOrCode id или код строкой (можно CamelCase or snake_case)
 * @returns {{id: number, code: string, name: string, full_name: string}|{}} объект типа одх
 * @example
 * useGetOghTypes() //? массив всех типов
 * useGetOghTypes(47) //? {id: 47, code: 'bridge', name: 'Мосты', full_name: 'Мосты'}
 * useGetOghTypes('bridge') //? {id: 47, code: 'bridge', name: 'Мосты', full_name: 'Мосты'}
 * useGetOghTypes('bridge') //? {id: 47, code: 'bridge', name: 'Мосты', full_name: 'Мосты'}
 * camelCase
 * useGetOghTypes('tram_rails') //? {id: 20, code: 'tram_rails', name: 'Трамвайные пути', full_name: 'Трамвайные пути'}
 * snake_case
 * useGetOghTypes('tramRails') //? {id: 20, code: 'tram_rails', name: 'Трамвайные пути', full_name: 'Трамвайные пути'}
 */
 
export const useGetOghType = (idOrCode) => {
  const oghTypes = useGetOghTypes();

  return oghTypes[idOrCode] || {};
};
