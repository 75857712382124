import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { getNameFile } from 'app/pages/cardsOgh/components/tables/Documents/helpers/getNameFile';
import React from 'react';
import { Field } from 'redux-form';

import { DocumentWithReduxForm } from '../DocumentWithReduxForm';

const ID = 11;
const NAME = getNameFile(ID);
const TITLE = 'Разбивочный чертеж';

/**
 * Таблица документов __Разбивочный чертеж__.
 *
 * @returns {JSX.Element} - JSX.
 * @example
 * <MarkingDrawingRf />
 */
export const MarkingDrawingRF = () => {
  const isCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();

  return (
    <Field
      title={TITLE}
      id={ID}
      name={NAME}
      component={DocumentWithReduxForm}
      disabled={isCurrentObjectIsExternalSystem}
    />
  );
};
