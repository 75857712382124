import { get } from 'app/api/crud/get';

/**
 * Автокомплит Номер по ГОСТ и наименование знака.
 *
 * @param data - Данные.
 * @param data.maxRows - Maximum number of rows.
 * @param data.startsWith - Start with.
 * @returns Словарь.
 */
export const fetchTrafficSignsObject = (data: {
  maxRows: number;
  startsWith: string;
}): Promise<
  {
    id: number;
    name: string;
  }[]
> =>
  get<
    {
      id: number;
      name: string;
    }[]
  >('/autocomplete/traffic_signs_object', data);
