/* eslint-disable */
import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import { TextField } from 'core/uiKit/inputs';

const NAME = 're_calc_name';

const TextFieldWithReduxForm = ({ input, ...props }) => {
  return <TextField {...props} {...input} />;
};

/* eslint-disable */
export const ReCalcNameRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      label={'Наименование'}
      name={NAME}
      disabled={true}
      component={TextFieldWithReduxForm}
    />
  );
};

ReCalcNameRF.propTypes = {
   
   
/**
     Выбор темной темы
     */
  dark: PropTypes.bool,
   
   
/**
     name input
     */
  name: PropTypes.string,
   
  /* eslint-disable */
/**
     label input
     */
  label: PropTypes.string,
  disabled: PropTypes.bool,
};
