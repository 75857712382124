import {
  SEND_REASON_NAME,
  SendReasonFF,
} from 'core/form/finalForm/fields/prepared/selects/multiSelect/SendReasonFF';
import React from 'react';
import { NumberParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

export { SEND_REASON_NAME };

/**
 * Причина
 * send_reason.
 *
 * @param {*} root0 - Properties.
 * @returns {JSX.Element}
 * @class
 */
export const SendReasonFilter = ({ ...props }) => (
  <SendReasonFF {...props} className={filterInput} />
);

export const sendReasonParams = {
  [SEND_REASON_NAME]: NumberParam,
};
