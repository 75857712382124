import { validateCarMandatoryProperties } from 'app/utils/validation';

/* eslint-disable */
export default function validateCarRequiredFieldsAsync(
  formValues,
  mandatoryFields,
  customValidation,
) {
  const errors = validateCarMandatoryProperties(formValues, mandatoryFields);
  Object.assign(errors, customValidation(formValues));
}
