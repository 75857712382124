import { useGetCardSelector as useGetCardSelectorD } from './useGetCard.Selector';
 
export * from './useGetCardAttribute.Selector';
export * from './useGetCardLogicalStateId.Selector';
export * from './useGetCardMode.Selector';

 
 
/**
 * @deprecated
 * @see src/app/selectors/useSelectors/useGetCard.Selector.js:18
 * @type {(function(): {})}
 */
 
export const useGetCardSelector = useGetCardSelectorD;
