/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { NumberParam } from 'use-query-params';

import { SubTypeIdFF, SUB_TYPE_ID_NAME } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
export const SubTypeIdFilter = (props) => (
  <SubTypeIdFF {...props} className={filterInput} />
);

 
export { SUB_TYPE_ID_NAME };

 
export const subTypeIdParams = {
  [SUB_TYPE_ID_NAME]: NumberParam,
};

SubTypeIdFilter.propTypes = {
   
   
/**
   * odsType
   * [camelCase | snakeCase | number]
   */
  odsType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
