import { IdRfidCell } from 'core/uiKit/components/cells/IdRfid.Cell/IdRfid.Cell';
import { LinkShortIdCell } from 'core/uiKit/components/cells/LinkShortId.Cell';
import { YesOrNoCell } from 'core/uiKit/components/cells/YesOrNo.Cell';

export const columnsMafRfid = [
  {
    Cell: LinkShortIdCell,
    Header: 'ID',
    accessor: 'short_root_id',
    sortable: true,
  },
  {
    Header: 'Тип ОГХ',
    accessor: 'parent_type_name',
    sortable: true,
  },
  {
    Header: 'Наименование',
    accessor: 'object_name',
    sortable: true,
  },
  {
    Header: 'Тип',
    accessor: 'object_type_name',
    sortable: true,
  },
  {
    Header: 'Уточнение',
    accessor: 'maf_type_level2_name',
    sortable: true,
  },
  {
    Header: 'Детализация',
    accessor: 'maf_type_level3_name',
    sortable: true,
  },
  {
    Header: 'Статус',
    accessor: 'object_status_name',
    sortable: true,
  },
  {
    Header: 'Дата подписания',
    accessor: 'sign_date',
    sortable: true,
  },
  {
    Header: 'Дата начала',
    accessor: 'start_date',
    sortable: true,
    width: 90,
  },
  {
    Header: 'Дата окончания',
    accessor: 'end_date',
    sortable: true,
    width: 120,
  },
  {
    Header: 'Адрес',
    accessor: 'address',
    sortable: true,
  },
  {
    Header: 'ID RFID метки',
    accessor: 'id_rfid',
    sortable: true,
  },
  {
    Header: 'Дата установки',
    accessor: 'installation_date',
    sortable: true,
  },
  {
    Header: 'Гарантийный срок',
    accessor: 'guarantee_period',
    sortable: true,
  },
  {
    Cell: IdRfidCell,
    Header: 'ID УАИС БУ',
    accessor: 'eais_id',
    sortable: true,
  },
  {
    Header: 'Состояние',
    accessor: 'condition',
    sortable: true,
  },
  {
    Cell: YesOrNoCell,
    Header: 'Данные внесены',
    accessor: 'is_filled',
    sortable: true,
  },
];
