import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import ToggleButton from 'app/components/card/common/MapEditPanel/buttons/ToggleButton';
import toggleContent from 'app/components/card/common/MapEditPanel/content/toggle';
import { useMapContext } from 'app/components/map/useMapContext';
import { NEIGHBOURS } from 'app/constants/layers';
import { Button } from 'core/uiKit/components/buttons/Button';
import { InfoPanel } from 'core/uiKit/components/InfoPanel';
import React, { useState } from 'react';

import { onGetNeighboursButtonPress } from './helpers/onGetNeighboursButtonPress';

const initialState = {
  bodyData: '',
  headerData: '',
  isOpen: false,
};

/**
 * Кнопка показать смежные ОГХ.
 *
 * @returns {JSX.Element}
 */
export const NeighboursButton = () => {
  const { drawNeighboursGeometry, getGeometry, clearLayer, isMapLoaded } =
    useMapContext();
  const { rootId, startDate, endDate, recordId } = useGetCurrentCard();

  const [isLoading, setIsloading] = useState(false);
  const [hasNeighbours, setHasNeighbours] = useState(false);
  const [state, setState] = useState(initialState);

  /**
   * Функция получения показа таблицы смежных ОГХ и отрисовки геометрии.
   *
   * @returns {Promise<void>}
   */
  const handleClickOpen = async () => {
    if (hasNeighbours) {
      clearLayer(NEIGHBOURS);
      setHasNeighbours(false);
    } else {
      const data = {
        endDate,
        geometry: getGeometry(recordId),
        rootId,
        startDate,
      };

      setIsloading(true);
      const { bodyData, headerData } = await onGetNeighboursButtonPress({
        data,
        draw: drawNeighboursGeometry,
        setHasNeighbours,
      });

      setIsloading(false);
      setState({
        bodyData,
        headerData,
        isOpen: true,
      });
    }
  };

  /**
   * Функция закрыть диалоговое окно.
   *
   * @returns {void}
   */
  const closeDialog = () => {
    setState(initialState);
  };

  return (
    <>
      <InfoPanel
        accordion
        isOpen={state.isOpen}
        textHeader={state.headerData}
        Body={state.bodyData}
        ButtonsActions={
          <Button variant={'contained'} onClick={closeDialog} color={'info'}>
            Закрыть
          </Button>
        }
      />
      <ToggleButton
        isLoading={isLoading}
        disabled={!isMapLoaded}
        active={hasNeighbours}
        content={toggleContent.neighbours}
        onClick={handleClickOpen}
      />
    </>
  );
};
