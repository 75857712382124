/* eslint-disable */
import React, { useState } from 'react';
import { Button } from 'core/uiKit/components/buttons/Button';
import { Icon } from 'core/uiKit/components/Icon';
import { DialogDownloadOperation } from 'app/components/card/ogh/BasePgmCard/components/OperationsTab/components/DownloadOperation/Dialog.DownloadOperation';

 
export const DownloadOperation = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        color={'info'}
        variant={'contained'}
        onClick={() => setIsOpen(true)}
        startIcon={<Icon>upload_file</Icon>}
      >
        Загрузить операции из csv-файла
      </Button>
      {isOpen ? (
        <DialogDownloadOperation
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        />
      ) : null}
    </>
  );
};
