import { queryClient } from 'app/routes/QueryClientProvider';
import { ContinueFFDialog } from 'core/form/finalForm/components/dialogs/ContinueFF.Dialog';
import { DndFileUploadFF } from 'core/form/finalForm/fields/prepared/fileUpload/DndFileUploadFF';
import { SendReasonFF } from 'core/form/finalForm/fields/prepared/selects/multiSelect/SendReasonFF';
import { CommentFF } from 'core/form/finalForm/fields/prepared/textField/CommentFF';
import { toast } from 'core/uiKit/components';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import React from 'react';
import { useParams } from 'react-router-dom';

import * as api from './api';
import { filterSendReasonOptions } from './filterSendReasonOptions';
import { formValidation } from './formValidation';

/* eslint-disable */
export const DialogBound = ({
  isOpen,
  onClose = (_) => _,
}) => {
  const { childId, id } = useParams();
  const objectId = childId || id;
  const typeChange = objectId ? 'edit_ogh' : 'create_ogh';

  const [send, { isLoading }] = useMutationAdaptor(
    api.externalMatching,
    {
      onSuccess: () => {
        toast.success('Объект отправлен на согласование');
        queryClient.invalidateQueries(
          'fetchMatchingBoundariesTable',
        );
        queryClient.invalidateQueries(
          'fetchMatchingBoundariesTableCount',
        );
        onClose();
      },
      onError: (value) => {
        toast.error(value.message);
        onClose();
      },
    },
  );

  return (
    <ContinueFFDialog
      {...{
        textHeader:
          'Отправить объект на согласование границ',
        isLoading,
        isOpen,
        closeDialog: onClose,
        validate: formValidation,
        Body: (
          <>
            <SendReasonFF
              filter={(data) =>
                filterSendReasonOptions(data, typeChange)
              }
              required
            />
            <CommentFF />
            <DndFileUploadFF
              text={
                'Переместите файлы или нажмите для выбора'
              }
              name={'files'}
            />
          </>
        ),
        onSubmit: (value) => send({ ...value, objectId }),
      }}
    />
  );
};
