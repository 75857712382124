import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';

import { useGetDisabledDateCloseField } from './useGetDisabled.DateCloseField';

jest.mock('app/api/hooks/useGetCurrentCard');

describe('UseGetDisabledDateCloseFieldJestSpec', function () {
  it('test', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetCurrentCard.mockReturnValue({ startDate: '12.05.2000' });
    // Act
    const flagDisabled = useGetDisabledDateCloseField();
    // Assert
    expect(flagDisabled).toBeFalsy();
  });
});
