/* eslint-disable  */
import getFormValues from 'app/selectors/form/getFormValues';
import { createSelector } from 'reselect';

/**
 *
 * @param state
 * @param field
 */
const isFieldValue = (state, field) => getFormValues(state)[field];

export default createSelector([isFieldValue], (isValue) => {
  let result;
  if (isValue && isValue !== null) {
    result = true;
  } else {
    result = false;
  }
  return result;
});
