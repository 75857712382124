import './Body.News.Dialog.scss';

import { StepContentNews } from 'app/components/dialogs/News.Dialog/StepContent.News';
import { Step, StepLabel, Stepper } from 'core/uiKit/material-ui';
import React from 'react';
import { DataNews } from 'types/data.News';

import { useSetupBodyNewsDialog } from './useSetup.Body.News.Dialog';

/**
 * Диалоговое окно Оповещения Пользователя о Новой новости.
 *
 * @returns {JSX.Element}
 */
export const BodyNewsDialog = () => {
  const { activeStep, handleNext, data } = useSetupBodyNewsDialog();

  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      {(data as DataNews)?.map((step, index) => (
        <Step key={step.id}>
          <StepLabel key={step.id}>
            <p className={'step-label__news-dialog'}>
              {step.create_date}: {step.title}{' '}
            </p>
          </StepLabel>
          <StepContentNews
            key={step.id}
            hidden={activeStep !== index}
            onClick={() => {
              handleNext(step.id);
            }}
          >
            {step.text}
          </StepContentNews>
        </Step>
      ))}
    </Stepper>
  );
};
