/* eslint-disable  */

import './dialog.SigningOnRenewalOfApprovalDate.scss';

import { useGetFilesForSignature } from 'app/api/hooks/signs/useGetFilesForSignature';
import { UPDATE } from 'app/api/requests/signs/signOghSave';
import {
  CERTIFICATE_NAME,
  FileSignatureCryptoProFF,
  SIGNATURES_NAME,
} from 'core/form/finalForm/fields/prepared/FileSignatureCryptoProFF';
import { getValidator } from 'core/form/finalForm/utils/validate';
import { CertInfo } from 'core/uiKit/components/CertInfo';
import { FileListSign } from 'core/uiKit/components/FileList.Sign';
import { Paper } from 'core/uiKit/components/Paper';
import { SignatureList } from 'core/uiKit/components/SignatureList';
import { Alert } from 'core/uiKit/material-ui';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import React, { useState } from 'react';
import { useField } from 'react-final-form';

/**
 * Валидация формы.
 *
 * @param {object} values - Значения формы.
 * @returns {{}|null}
 */
export const formValidation = (values) => {
  const { setRequired, hasErrors, getErrors } = getValidator(values);

  setRequired(
    SIGNATURES_NAME,
    'Выберите сертификат для подписи и нажмите подписать',
  );

  return hasErrors() ? getErrors() : null;
};

/**
 * Компонент FormSigningOnRenewalOfApprovalDate.
 *
 * @returns {JSX.Element}
 */
export const FormSigningOnRenewalOfApprovalDate = () => {
  // noinspection DuplicatedCode
  const { input: { value: certificate } = {} } = useField(CERTIFICATE_NAME);
  const { input: { value: signatures, onChange: onSignaturesChange } = {} } =
    useField(SIGNATURES_NAME);
  const [error, setError] = useState();

  const { objectId } = useGetObjectId();
  const { data = [] } = useGetFilesForSignature(objectId, UPDATE);

  const files = data?.map(({ file }) => file);
  /**
   * Функция callbackError.
   *
   * @param {Event} e - Event.
   * @returns {void}
   */
  const callbackError = (e) => setError(e?.toString().replace(/Error: /g, ''));

  return (
    <>
      <CertInfo certificate={certificate} />
      {error ? (
        <Paper className={'my-2'} elevation={6}>
          <Alert severity="error" variant={'filled'}>
            {error}
          </Alert>
        </Paper>
      ) : null}
      <Paper className={'p-2 mt-2'}>
        <FileSignatureCryptoProFF
          {...{
            callbackError,
            files,
          }}
        />
      </Paper>
      <FileListSign files={files} />
      <SignatureList
        clearSignatures={() => onSignaturesChange()}
        signatures={signatures}
      />
    </>
  );
};
