import toArray from 'app/utils/toArray';
import { createSelector } from 'reselect';

import getDict from './getDict';


/* eslint-disable */
export default function (dictName) {
  return createSelector([getDict(dictName)], (dict) => toArray(dict));
}
