import NavItem from 'app/components/common/NavBar/NavItem';
import { useUser } from 'app/pages/providers';
import { pathBnsoReport } from 'app/pages/report/BnsoReport/path.BnsoReport';
/* eslint-disable */
import React, { lazy, useEffect } from 'react';

function lazyWithPreload(factory) {
  const Component = lazy(factory);
  Component.preload = factory;
  return Component;
}

const ChangeLogRegistry = lazyWithPreload(() =>
  import('app/pages/report/BnsoReport'),
);

 
export const ReportNavBNSO = () => {
  const { hasPermission } = useUser();

  const isShow = hasPermission(pathBnsoReport.privilege);

  useEffect(() => {
    isShow && ChangeLogRegistry.preload();
  }, [isShow]);

  return isShow ? (
    <NavItem link={`/report/${pathBnsoReport.path}`}>
      {pathBnsoReport.name}
    </NavItem>
  ) : null;
};
//
// const ReportNavBNSO = grants.showIfHavePrivilege('reports_technic_gps')(() => (
//   <NavItem link="/report/2">БНСО</NavItem>
// ));
