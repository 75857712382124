import { KeyboardDatePickerFF } from 'core/form/finalForm/fields/default/datePickers/KeyboardDatePickerFF';
import {
  addPreparation,
  removePreparation,
} from 'core/utils/hooks/usePreparation/preparation';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useField } from 'react-final-form';

export const DATE_FROM_NAME = 'dateFrom';

/**
 * DateFromFF.
 *
 * @param {object} props - Props.
 * @returns {Element}
 */
export const DateFromFF = (props) => {
  const dateToInput = useField('dateTo');

  useEffect(() => {
    addPreparation(DATE_FROM_NAME, (value) => {
      let newValue = value;
      if (typeof value === 'object' && moment(value).isValid()) {
        newValue = moment(value).format('DD.MM.YYYY');
      }
      return newValue;
    });
    return () => removePreparation(DATE_FROM_NAME);
  });

  return (
    <KeyboardDatePickerFF
      label="Дата с"
      maxDate={dateToInput?.input?.value}
      {...props}
      name={DATE_FROM_NAME}
    />
  );
};
