/* eslint-disable */
import React from 'react';
import { NumberFieldRF } from 'core/form/reduxForm/fields';
import { SPA } from 'app/constants/oghTypes';
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';

 
export const SpreadDts = ({ editMode }) => {
  const { typeId } = useGetParentCard();

  return [SPA].includes(typeId) ? (
    <NumberFieldRF
      decimalScale={2}
      label={'Протяжённость, км'}
      name={'spread'}
      disabled={editMode}
    />
  ) : null;
};
