const XmlDsigGost = {
   
   
  /**
   * @constant {string} XmlDsigGost3410Url Алгоритм подписи для XmlDsig.
   */
  XmlDsigGost3410Url:
    'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34102001-gostr3411',
   
   
  /**
   * @constant {string} XmlDsigGost3411Url Алгоритм подписи для XmlDsig.
   */
  XmlDsigGost3411Url: 'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr3411',
   
  /* eslint-disable */
  /**
   * @constant {String} XmlDsigGost2012Url256 Алгоритм подписи для XmlDsig.
   */
  XmlDsigGost2012Url256:
    'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34102012-gostr34112012-256',
   
   
  /**
   * @constant {String} XmlDsigGost2012Url256Digest Алгоритм подписи для XmlDsig.
   */
  XmlDsigGost2012Url256Digest:
    'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34112012-256',
   
   
  /**
   * @constant {String} XmlDsigGost2012Url512 Алгоритм подписи для XmlDsig.
   */
  XmlDsigGost2012Url512:
    'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34102012-gostr34112012-512',
   
   
  /**
   * @constant {String} XmlDsigGost2012Url512Digest Алгоритм подписи для XmlDsig.
   */
  XmlDsigGost2012Url512Digest:
    'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34112012-512',
   
   
  /**
   * @constant {String} XmlDsigGost3410UrlObsolete Алгоритм подписи для XmlDsig.
   */
  XmlDsigGost3410UrlObsolete:
    'http://www.w3.org/2001/04/xmldsig-more#gostr34102001-gostr3411',
   
   
  /**
   * @constant {String} XmlDsigGost3411UrlObsolete Алгоритм подписи для XmlDsig.
   */
  XmlDsigGost3411UrlObsolete:
    'http://www.w3.org/2001/04/xmldsig-more#gostr3411',
};

 
export default XmlDsigGost;
