import { post } from 'app/api/crud/post';

/**
 * Хук получения отчета.
 *
 * @param {string} templateName - Имя шаблона отчета.
 * @param {object} queryParams - Параметры запроса.
 * @returns {Promise<object>}
 */
export const getReport = async (templateName, queryParams) => {
  const response = await post(`/report/${templateName}`, queryParams, {
    jsonBody: false,
    urlencoded: true,
  });
  return response.data;
};
