import './MapDiff.scss';

import LegendMapDiff from 'app/components/common/LegendMapDiff';
import withMap, { WithMapContext } from 'app/components/map/withMap';
import { copyColors, originalColors } from 'app/constants/colors';
import formatRootId from 'app/utils/formatRootId';
import compareReportStyles from 'assets/styles/common.compareReport.scss';
import classnames from 'classnames';
import { formatGeometryObjectToUpdate } from 'core/uiKit/components/DTW/contexts/utils';
import { switchServisMap } from 'core/utils/map/switchServisMap';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  Map: PropTypes.func.isRequired,
  className: PropTypes.string,
  copy: PropTypes.object,
  etalon: PropTypes.object,
  geodiff: PropTypes.object,
  isMapLoaded: PropTypes.bool.isRequired,
};

/**
 * Форматирование геометри для сохранения в контексте карты.
 *
 * @param {object} geometries - Геометрии для отрисовки различий.
 * @returns {object} Возвращает объект с геометриями для карты { copyDiff: Array<object>, originalDiff: Array<object>, parent: Array<empty> }.
 */
const formatGeometriesToUpdate = (geometries) => {
  return {
    copyDiff: formatGeometryObjectToUpdate(geometries.copyDiff, 'copyDiff'),
    originalDiff: formatGeometryObjectToUpdate(
      geometries.originalDiff,
      'originalDiff',
    ),
    parent: [],
  };
};

/**
 * Класс MapDiff.
 *
 */
class MapDiff extends React.Component {

  /**
   * Конструктор.
   *
   * @param {object} props - Пропсы.
   */
  constructor(props) {
    super(props);
    this.state = {
      didDraw: false,
      showCopy: true,
      showOriginal: true,
    };
  }
  static contextType = WithMapContext;

  /**
   * Отрисовтаь геометрии на карте.
   *
   * @param {boolean} showCopy - Показывать копию.
   * @param {boolean} showOriginal - Показывать оригинал.
   * @returns {void}
   */
  drawDiffGeometries = (showCopy, showOriginal) => {
    const { didDraw } = this.state;
    const { geodiff, copy, etalon } = this.props;
    const { mapService } = this.context;

    mapService.updateGeometriesData({
      ...formatGeometriesToUpdate({
        copyDiff: showCopy
          ? {
              hint: geodiff.copy_hint,
              id: copy.id,
              layerType: 'copyDiff',
              lines: geodiff.copy_lines,
              points: geodiff.copy_points,
              polygons: geodiff.copy_polygons,
            }
          : {},
        originalDiff: showOriginal
          ? {
              hint: geodiff.original_hint,
              id: etalon.id,
              layerType: 'originalDiff',
              lines: geodiff.original_lines,
              points: geodiff.original_points,
              polygons: geodiff.original_polygons,
            }
          : {},
      }),
      parent: [],
    });

    if (!didDraw) {
      this.setState({
        didDraw: true,
      });
    }

    const instances = mapService.drawGeometries({ method: 'replaceAll' });
    this.zoomToDiffs(instances);
  };

  /**
   * ZoomToGeometries..
   *
   * @param {object} instances - Instans.
   * @returns {void}
   */
  zoomToDiffs = (instances) => {
    const { zoomToGeometries } = this.context;
    zoomToGeometries(
      {
        point: [...instances.originalDiff.point, ...instances.copyDiff.point],
        polygon: [
          ...instances.originalDiff.polygon,
          ...instances.copyDiff.polygon,
        ],
        polyline: [
          ...instances.originalDiff.polyline,
          ...instances.copyDiff.polyline,
        ],
      },
      { padding: { bottom: 15, left: 15, right: 15, top: 15 } },
    );
  };

  /**
   * Метод жизненного цикла componentDidUpdate.
   *
   * @param {object} prewProps - Предыдущие пропсы.
   * @returns {void}
   */
  componentDidUpdate(prewProps) {
    const didUpdate = switchServisMap({
      dtw: this.componentDidUpdateDTW,
      egip: this.componentDidUpdateEgip,
    });
    didUpdate(prewProps);
  }

  /**
   * Метод жизненного цикла componentDidUpdate для EGIP.
   *
   * @returns {void}
   */
  componentDidUpdateEgip = () => {
    const { isMapLoaded, geodiff, copy, etalon } = this.props;
    const { showCopy, showOriginal } = this.state;

    if (isMapLoaded && geodiff) {
      this.context.clearDiff();

      if (showCopy === true) {
        this.context.drawDiffCopyObjectGeometry([
          {
            hint: geodiff.copy_hint,
            id: copy.id,
            lines: geodiff.copy_lines,
            points: geodiff.copy_points,
            polygons: geodiff.copy_polygons,
          },
        ]);
      }

      if (showOriginal === true) {
        this.context.drawDiffOriginalObjectGeometry([
          {
            hint: geodiff.original_hint,
            id: etalon.id,
            lines: geodiff.original_lines,
            points: geodiff.original_points,
            polygons: geodiff.original_polygons,
          },
        ]);
      }

      this.context.zoomToDiff();
    }
  };

  /**
   * Метод жизненного цикла componentDidUpdate для DTW.
   *
   * @param {object} prevProps - Предыдущие пропсы.
   * @returns {void}
   */
  componentDidUpdateDTW = (prevProps) => {
    const { geodiff } = this.props;
    const { showCopy, showOriginal, didDraw } = this.state;
    const { mapService } = this.context;

    if (
      mapService?.isMapLoaded &&
      geodiff &&
      (!didDraw || prevProps.geodiff !== geodiff)
    ) {
      this.drawDiffGeometries(showCopy, showOriginal);
    }
  };

  /**
   * Функция showObjects.
   *
   * @param {object} args - Аргументы.
   * @returns {void}
   */
  showObjects = (args) => {
    if (args.copy === true) {
      this.setState({ showCopy: true });
    } else {
      this.setState({ showCopy: false });
    }

    if (args.original === true) {
      this.setState({ showOriginal: true });
    } else {
      this.setState({ showOriginal: false });
    }

    const drawDiffGeometries = switchServisMap({
      dtw: this.drawDiffGeometries,

      /**
       * Заглушка для египа.
       *
       * @param {object} _ - Аргументы.
       * @returns {object}
       */
      egip: (_) => _,
    });

    drawDiffGeometries(args.copy, args.original);
  };

  /**
   * Метод жизненного цикла render.
   *
   * @returns {JSX.Element}
   */
  render() {
    const { geodiff, etalon, copy } = this.props;

    return (
      <div className={classnames('d-flex flex-column', this.props.className)}>
        <div className="my-3 h-100 overflow-hidden">
          {geodiff &&
            this.props.Map({
              className: 'w-100 h-100 diff__legend',
              editable: false,
              style: {
                height: '100%',
                width: '100%',
              },
            })}
        </div>

        {geodiff && geodiff.is_equal === 0 && (
          <dl>
            <div className="d-inline mr-3">
              <dt className="d-inline">Статус:</dt>{' '}
              <dd className="d-inline">Не равно</dd>
            </div>
            <div className="d-inline">
              <dt className="d-inline">Уточнение статуса:</dt>{' '}
              <dd className="d-inline">
                {geodiff.operation === 'update'
                  ? 'Отличается'
                  : geodiff.operation === 'add'
                  ? 'Новое'
                  : geodiff.operation === 'del'
                  ? 'Удалено'
                  : ''}
              </dd>
            </div>
          </dl>
        )}

        <LegendMapDiff
          action={this.showObjects}
          legend={[
            {
              className: compareReportStyles.legend_add,
              style: {
                background: copyColors.fillColor,
              },
              value: `Версия ID: ${formatRootId(copy?.id)} (${
                copy.object_status_name
              })`,
            },
            {
              className: compareReportStyles.legend_update,
              style: {
                background: originalColors.fillColor,
              },
              value: `Версия ID: ${formatRootId(etalon.id)} (${
                etalon.object_status_name
              })`,
            },
          ]}
        />
      </div>
    );
  }
}

MapDiff.propTypes = propTypes;

export default withMap(MapDiff);
