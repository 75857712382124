import Legend from 'app/components/common/Legend';
import React from 'react';

const legend = [
  {
    className: 'legend_add',
    value: 'новое',
  },
  {
    className: 'legend_del',
    value: 'удаленное',
  },
  {
    className: 'legend_update',
    value: 'отличается',
  },
];

/**
 * Компонент легенды таба свойства.
 *
 * @returns {JSX.Element} - JSX.
 * @example <PropertiesDiffLegend />
 */
export const PropertiesDiffLegend = () => {
  return (
    <Legend
      className={'dialog-comparison-version-card__legend'}
      legend={legend}
    />
  );
};
