import { useChangeFieldValueRF } from 'app/actions/odsObjectActions/useChangeFieldValueRF';
import { useFormValuesCardRF } from 'app/actions/odsObjectActions/useFormValuesCardRF';
import { QuantityRf } from 'core/form/reduxForm/fields/prepared/textField/Quantity.RF';
import { QUANTITY_NAME } from 'core/uiKit/preparedInputs/textField/Quantity';
import React, { useEffect } from 'react';

interface QuantityFieldProps {
  disabled?: boolean;
  required?: boolean;
  digits?: boolean;
}

interface FieldsState {
  unit_id?: number | undefined;
  quantity?: number | undefined;
}

/**
 * Поле __Количество__.
 *
 * @param props - Пропсы.
 * @returns
 */
export const QuantityField = (props: QuantityFieldProps) => {
  const values = useFormValuesCardRF<FieldsState>();
  const onChangeField = useChangeFieldValueRF();

  useEffect(() => {
    if (values.unit_id === 5 && values.quantity !== 1) {
      onChangeField(QUANTITY_NAME, 1);
    }
  }, [values, onChangeField]);

  return (
    <QuantityRf
      //@ts-ignore
      digits={props.digits}
      disabled={props.disabled}
      required={props.required}
    />
  );
};
