import {
  DATE_NAME as DATE_NAMED,
  DateFilter as DateFilterD,
  dateParams as dateParamsD,
} from './DateFilter';
import {
  DATE_FROM_NAME as DATE_FROM_NAME_D,
  DateFromFilter as DateFromFilter_D,
  dateFromParams as dateFromParams_D,
} from './DateFromFilter';
import {
  DATE_TO_NAME as DATE_TO_NAME_D,
  DateToFilter as DateToFilter_D,
  dateToParams as dateToParams_D,
  dateToParamsAddMonth as dateToParamsAddMonth_D,
} from './DateToFilter';

 
/**
 * @deprecated
 * @see src/core/form/filterForm/components/dateFilters/DateFilter
 */
const DATE_NAME = DATE_NAMED;
 
/**
 * @deprecated
 * @see src/core/form/filterForm/components/dateFilters/DateFilter
 */
const DateFilter = DateFilterD;
 
/**
 * @deprecated
 * @see src/core/form/filterForm/components/dateFilters/DateFilter
 */
const dateParams = dateParamsD;

 
/**
 * @deprecated
 * @see src/core/form/filterForm/components/dateFilters/DateFromFilter
 */
const DATE_FROM_NAME = DATE_FROM_NAME_D;
 
/**
 * @deprecated
 * @see src/core/form/filterForm/components/dateFilters/DateFromFilter
 */
const DateFromFilter = DateFromFilter_D;
 
/**
 * @deprecated
 * @see src/core/form/filterForm/components/dateFilters/DateFromFilter
 */
const dateFromParams = dateFromParams_D;

 
/**
 * @deprecated
 * @see src/core/form/filterForm/components/dateFilters/DateToFilter
 */
const DATE_TO_NAME = DATE_TO_NAME_D;
 
/**
 * @deprecated
 * @see src/core/form/filterForm/components/dateFilters/DateToFilter
 */
const DateToFilter = DateToFilter_D;
 
/**
 * @deprecated
 * @see src/core/form/filterForm/components/dateFilters/DateToFilter
 */
const dateToParams = dateToParams_D;
 
/**
 * @deprecated
 * @see src/core/form/filterForm/components/dateFilters/DateToFilter
 */
const dateToParamsAddMonth = dateToParamsAddMonth_D;

export * from './DateFromWithHoursFilter';
export * from './DateToWithHoursFilter';
export * from './DateWithHoursFilter';

export {
  DATE_FROM_NAME,
  DATE_NAME,
  DATE_TO_NAME,
  DateFilter,
  DateFromFilter,
  dateFromParams,
  dateParams,
  DateToFilter,
  dateToParams,
  dateToParamsAddMonth,
};
