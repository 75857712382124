import { shallow } from 'enzyme';
import React from 'react';

import { YardDocsTab } from './YardDocs.Tab';

describe('spec YardOne.Tab', function () {
  it('нет нихто', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const wrapper = shallow(<YardDocsTab />);

    // Assert
    expect(wrapper.text()).toBe(
      '<DocumentsConfirmingTheChangesRF /><ActOfCompletedWork /><SituationPlanRF /><InventoryPlanRF /><SymbolsInventoryPlanRF /><SolutionObjectRF /><PlanFlowerRF /><SketchFlowerRF /><MarkingDrawingRF /><DataCoordOrganizationRF /><OtherDocumentsRF /><OtherLegalDocumentsRF />',
    );
  });
});
