/**
 * Функция форматирования словаря.
 *
 * @param {Array<{id:number, name: string, parent_ogh_object_type_list: Array<{ ogh_object_type_id: { ogh_object_type: number },  other_improvement_object_type_list: Array<{other_improvement_object_type_id: { other_improvement_object_type: number} }>}> }>} dict - Словарь.
 * @returns {Array<{id:number, name: string, parent_ogh_object_type_list: Array<{ ogh_object_type_id: number, other_improvement_object_type_list: Array<number>}> }>} - Жопа.
 * @example -------
 */
export const formatDict = (dict) =>
  dict.map((item) => ({
    id: item?.id,
    name: item?.name,
    parent_ogh_object_type_list: item.parent_ogh_object_type_list.map(
      (plist) => {
        const result = {};

        if (plist?.ogh_object_type_id?.ogh_object_type) {
          result.ogh_object_type_id = plist.ogh_object_type_id.ogh_object_type;
        }
        if (plist?.other_improvement_object_type_list?.length) {
          result.other_improvement_object_type_list =
            plist?.other_improvement_object_type_list?.map(
              (otherList) =>
                otherList?.other_improvement_object_type_id
                  ?.other_improvement_object_type,
            );
        }

        return result;
      },
    ),
  }));
