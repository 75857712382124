import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useMapContext } from 'app/components/map/useMapContext';
import { PARENTS } from 'app/constants/layers';
import { useGeometryMapCurrent } from 'app/pages/cardsOgh/components/Map/MapEditPanel/hooks/useGeometryMapCurrent';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useGetParamsUrl } from 'core/utils/hooks/useGetParamsUrl';

import { useGroup } from '../hooks/useGroup';

interface DrawGeometryParams {
  //

  /**
   * Type Id element.
   */
  typeId: number;
}

/**
 * Хук отрисовки геометрии родитееля и подгруппы элементов.
 *
 * @returns - Функция отрисовки.
 */
export const useDrawGeometryEgip = () => {
  const { drawParentGeometry, clearLayer } = useMapContext();

  const parent = useGetParentCard();
  const { dataMapCurrent = [], currentSuccess } = useGeometryMapCurrent();
  const { actions } = useGetParamsUrl();
  const { getGroupGeometry, isLoadingGroupGeometry } = useGroup();
  const currentCard = useGetCurrentCard();
  const { editMode } = useMode();

  /**
   * Подгрузить геометрию группы.
   *
   * @param {DrawGeometryParams} params - Параметры.
   * @returns {void}
   */
  const drawGeometry = async ({
    typeId,
  }: DrawGeometryParams): Promise<void> => {
    if (currentSuccess && !actions.haveParent) {
      clearLayer(PARENTS);
      drawParentGeometry(dataMapCurrent);
    }

    if (!editMode) {
      const id = parent.isSuccessParent
        ? parent.recordId
        : currentCard.recordId;
      await getGroupGeometry({
        id,
        path_type_id: typeId,
        root_id: id,
        type_id: typeId,
      });
    }
  };
  return { drawGeometry, isLoadingGroupGeometry };
};
