/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { reduce } from 'lodash';

import getDisplayName from './getDisplayName';

const createState = (elementNames) =>
  reduce(
    elementNames,
    (result, name) => ({
      ...result,
      [name]: false,
    }),
    {},
  );

const propTypes = {
  isShow: PropTypes.func,
  props: PropTypes.object,
  onHide: PropTypes.func,
  onShow: PropTypes.func,
};

function withShowControls(...elementNames) {
  return function (Component) {
    class WithShowControls extends React.Component {
      state = createState(elementNames);

      isShow = (elementName) => this.state[elementName];

      handleHide = (elementName) => () => {
        this.setState({ [elementName]: false });
      };

      handleShow = (elementName) => () => {
        this.setState({ [elementName]: true });
      };

      render() {
        const { props } = this;

        return (
          <Component
            {...props}
            isShow={this.isShow.bind(this)}
            onHide={this.handleHide.bind(this)}
            onShow={this.handleShow.bind(this)}
          />
        );
      }
    }

    WithShowControls.displayName = `WithShowControls(${getDisplayName(
      Component,
    )})`;
    WithShowControls.propTypes = propTypes;

    return WithShowControls;
  };
}

 
export default withShowControls;
