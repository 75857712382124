import getDict from 'app/selectors/getDict';
/* eslint-disable  */
import { createSelector } from 'reselect';

/**
 *
 * @param getCurrentReagentKind
 */
export default (getCurrentReagentKind) => {
  return createSelector(
    [getDict('reagents'), getCurrentReagentKind],
    (reagents, currentReagentKindId) => {
      let result;
      if (currentReagentKindId) {
        result = reagents.filter(
          (item) => item.reagent_kind_id === currentReagentKindId,
        );
      } else {
        result = reagents;
      }
      return result;
    },
  );
};
