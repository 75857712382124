import { withFinalForm } from 'core/form/finalForm/helpers/withFinalForm';
import {
  UNIT_ID_NAME,
  UnitId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/UnitId';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * Select FF __Ед. Измерения__.
 *
 * @param {object} props - Пропсы.
 * @param {string} props.name - Имя.
 * @param {object} props.otherProps - Остальные пропсы.
 * @returns {JSX.Element}
 */
export const UnitIdFF = (props) => {
  return (
    <Field name={UNIT_ID_NAME} component={withFinalForm(UnitId)} {...props} />
  );
};
