import { print } from 'app/api/requests/print';
import { MenuItem } from 'core/uiKit/components/Menu/MenuItem';
import { toast } from 'core/uiKit/components/Toast';
import { CircularProgress, Icon } from 'core/uiKit/material-ui';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import React from 'react';

/**
 * MenuItem __Выгрузить в .pdf__.
 *
 * @returns {JSX.Element}
 */
export const UploadToPDFMenuItem = () => {
  const { objectId } = useGetObjectId();

  const [sendDataExport, { isLoading }] = useMutationAdaptor(
    () => print('passport/pdf/' + objectId),
    {

      /**
       * Метод onError.
       *
       * @param {object} error - Ответ с ошибкой.
       * @returns {void}
       */
      onError(error) {
        toast.errorDying(
          error.message ? error.message : 'Ошибка экспорта данных',
        );
      },
    },
  );

  return (
    <MenuItem disabled={isLoading} onClick={sendDataExport}>
      <span className={'me-2'}>
        {isLoading ? <CircularProgress size={20} /> : <Icon>print</Icon>}
      </span>
      Выгрузить в .pdf
    </MenuItem>
  );
};
