/* eslint-disable  */
import './loaderCreateFiles.scss';

/**
 *
 */
export const LoaderCreateFiles = () => {
  return (
    <div className={'loader__file-create'}>
      <div className="boxes">
        <div className="box">
          <div />
          <div />
          <div />
          <div />
        </div>
        <div className="box">
          <div />
          <div />
          <div />
          <div />
        </div>
        <div className="box">
          <div />
          <div />
          <div />
          <div />
        </div>
        <div className="box">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>

      <img src="" alt="" />
    </div>
  );
};
