/* eslint-disable */
import React from 'react';
import { NumberParam } from 'use-query-params';

import { SENSOR_KINDS_NAME } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';
import { SensorKindsFF } from 'core/form/finalForm/fields';

 
 
/**
 *Вид датчика–>sensor_kind [поле справочник, или если пусто - все]
 */
 
export const SensorKindsFilter = (props) => (
  <SensorKindsFF {...props} className={filterInput} />
);

 
export { SENSOR_KINDS_NAME };

 
export const sensorKindsParams = {
  [SENSOR_KINDS_NAME]: NumberParam,
};
