import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const BUILDINGS_TYPE_NAME = 'buildingsTypeId';

/**
 * ## Назначение.
 *
 * @param {*} props - Properties.
 * @returns {JSX.Element}
 */
export const BuildingType = (props) => {
  const dict = 'buildingsType';
  const { data = [] } = useGetDictionary(dict);

  return (
    <Select
      {...props}
      options={data}
      label={'Назначение'}
      name={BUILDINGS_TYPE_NAME}
    />
  );
};
