import { useChangeFieldValueRF } from 'app/actions/odsObjectActions/useChangeFieldValueRF';
/* eslint-disable */
import { useFormValuesCardRF } from 'app/actions/odsObjectActions/useFormValuesCardRF';
import { CleaningTypeRfField } from 'app/components/card/ogh/dts/components/CleaningTypeRF.Field';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { RANDOM_WORD } from 'core/forTesting/constants';
import { CLEANING_TYPE_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CleaningType/index';
import { COATING_GROUP_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingGroupId/index';
import { shallow } from 'enzyme';

jest.mock('app/pages/cardsOgh/hooks/useEditMode');
jest.mock('app/actions/odsObjectActions/useFormValuesCardRF');
jest.mock('app/actions/odsObjectActions/useChangeFieldValueRF');
jest.mock('react', () => ({
  ...jest.requireActual('react'),

  /**
   *
   * @param fn
   */
  useEffect: (fn) => fn(),
}));

describe('CleaningTypeRfFieldJestSpec', function () {
  it('test called changeFieldValue', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const changeFieldValue = jest.fn();

    useEditMode.mockReturnValue({ editMode: true });
    useFormValuesCardRF.mockReturnValue({
      [COATING_GROUP_ID_NAME]: 2,
      foo: RANDOM_WORD,
    });
    useChangeFieldValueRF.mockReturnValue(changeFieldValue);
    // Act
    shallow(<CleaningTypeRfField />);
    // Assert
    expect(changeFieldValue).toBeCalledWith(CLEANING_TYPE_NAME, null);
  });
  it('test called filter fn', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const changeFieldValue = jest.fn();
    const dict = [
      { id: 0, name: 0 },
      { id: 1, name: 1 },
      { id: 2, name: 2 },
    ];

    useEditMode.mockReturnValue({ editMode: true });
    useFormValuesCardRF.mockReturnValue({
      [COATING_GROUP_ID_NAME]: 2,
      foo: RANDOM_WORD,
    });
    useChangeFieldValueRF.mockReturnValue(changeFieldValue);
    // Act
    const wrapper = shallow(<CleaningTypeRfField />);
    const { filter } = wrapper.props();
    //?
    // Assert
    expect(filter(dict)).toStrictEqual([
      { id: 1, name: 1 },
      { id: 2, name: 2 },
    ]);
  });
  it('test called filter fn case 2', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const changeFieldValue = jest.fn();
    const dict = [
      { id: 0, name: 0 },
      { id: 1, name: 1 },
      { id: 2, name: 2 },
    ];

    useEditMode.mockReturnValue({ editMode: true });
    useFormValuesCardRF.mockReturnValue({
      foo: RANDOM_WORD,
    });
    useChangeFieldValueRF.mockReturnValue(changeFieldValue);
    // Act
    const wrapper = shallow(<CleaningTypeRfField />);
    const { filter } = wrapper.props();
    //?
    // Assert
    expect(filter(dict)).toStrictEqual([
      { id: 0, name: 0 },
      { id: 1, name: 1 },
      { id: 2, name: 2 },
    ]);
  });
});
