import getMafTypeLevel1Id from 'app/selectors/card/getMafTypeLevel1Id';
/* eslint-disable  */
import getFormValues from 'app/selectors/form/getFormValues';
import getDict from 'app/selectors/getDict';
import __get from 'lodash/get';
import { createSelector } from 'reselect';

/**
 *
 * @param state
 */
const getMafTypeLevel3Id = (state) => getFormValues(state).maf_type_level3_id;

export default createSelector(
  [
    getDict('units'),
    getDict('mafTypeLevel1'),
    getDict('mafTypeLevel3'),
    getMafTypeLevel1Id,
    getMafTypeLevel3Id,
  ],
  (units, mafTypeLevel1, mafTypeLevel3, mafTypeLevel1Id, mafTypeLevel3Id) => {
    if (!mafTypeLevel1Id) {
      return units;
    }

    const selectLevel1 = (mafTypeLevel1 || []).find(
      (item) => item.id === mafTypeLevel1Id,
    );

    const permittedListForMafTypeLevel1 = __get(selectLevel1, 'unit_list', []);

    if (!mafTypeLevel3Id) {
      return units.filter((item) =>
        permittedListForMafTypeLevel1.includes(item.id),
      );
    }

    const selectLevel3 = (mafTypeLevel3 || []).find(
      (item) => item.id === mafTypeLevel3Id,
    );

    const permittedListForMafTypeLevel3 = __get(selectLevel3, 'unit_id');

    return units.filter((item) =>
      [permittedListForMafTypeLevel3 || permittedListForMafTypeLevel1].includes(
        item.id,
      ),
    );
  },
);
