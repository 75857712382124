import getFormValues from 'app/selectors/form/getFormValues';
/* eslint-disable  */
import { createSelector } from 'reselect';

import getDict from '../getDict';
import getParentTypeId from './getParentTypeId';
import getTypeId from './getTypeId';
import filterByParentTypeId from './utils/filterByParentTypeId';

/**
 *
 * @param state
 */
const getLawnTypeId = (state) => getFormValues(state).lawn_type_id;

export default createSelector(
  [getDict('lawnTypes'), getLawnTypeId, getTypeId, getParentTypeId],
  (dict, lawnTypeId, typeId, parentTypeId) => {
    let result;
    if (typeId !== parentTypeId && parentTypeId) {
      result = filterByParentTypeId({
        dict: dict,
        parentTypeId,
      });
    } else {
      result = dict;
    }
    return result;
  },
);
