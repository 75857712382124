import addFileToList from 'app/utils/addFileToList';

import buildGetDocuments from './selectors/buildGetDocuments';


/* eslint-disable */
export default function ({ oghGroupCode }) {
  const getDocuments = buildGetDocuments(oghGroupCode);
  return (result, state, props) => {
    const { card } = props;
    return {
      ...result,

      initialValues: {
        ...result.initialValues,
        ...getFileLists(card.file_list),
      },

      documents: getDocuments(state),
    };
  };
}

function getFileLists(fileList) {
  let result;
  if (fileList) {
    result = groupFileByOghFileTypeCode(fileList);
  } else {
    result = null;
  }
  return result;
}

function groupFileByOghFileTypeCode(fileList) {
  return fileList.reduce((result, item) => {
    const key = createFileListFormKey(item.type_id);
    if (result[key]) {
      addFileToList(result[key].table, item);
    } else {
      result[key] = {
        table: createFileList(item),
        showDialog: false,
      };
    }
    return result;
  }, {});
}

function createFileListFormKey(oghFileTypeCode) {
  // Если по какой-то причине для документа не указан type_id (null или вообще тэга нет)
  // в таком случае выводить файлы в раздел Иные документы (куда сейчас выводятся файлы с тэгом type_id=14)
  // https://jira.elocont.ru/browse/ODS-8359
  return `file_list_${oghFileTypeCode || 14}`;
}

function createFileList(file) {
  return [
    {
      ...file,
      index: 1,
    },
  ];
}
