import getDict from 'app/selectors/getDict';
import { createSelector } from 'reselect';

const FAULT_FREE_CAR_CONDITION = 2;

 
export default createSelector([getDict('carConditions')], (carConditions) => {
  return Object.values(carConditions).find(
    (item) => Number(item.code) === FAULT_FREE_CAR_CONDITION,
  ).id;
});
