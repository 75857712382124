import * as types from 'app/constants/actionTypes';

/* eslint-disable */
export function receiveRootCard(object, resetTree) {
  return {
    type: types.RECEIVE_CARD,
    resetTree,
    object,
  };
}
