
 
export default [
  {
    id: 'object_id',
    name: 'ID',
  },
  {
    id: 'reagent_base_type_name',
    name: 'Тип базы',
  },
  {
    id: 'okrug_short_name',
    name: 'Округ',
  },
  {
    id: 'district_short_name',
    name: 'Район',
  },
  {
    id: 'base_num',
    name: '№ базы',
  },
  {
    id: 'object_name',
    name: 'Наименование базы',
  },
  {
    id: 'legal_person_short_name',
    name: 'Эксплуатирующая организация',
  },
  {
    id: 'reagent_kind_list',
    name: 'Виды ПГР (макс.объем, т.)',
  },
  {
    id: 'reagent_balance',
    name: 'Остатки, т.',
  },
  {
    id: 'object_status_name',
    name: 'Статус',
  },
  {
    id: 'start_date',
    name: 'Дата начала',
    width: 90,
  },
  {
    id: 'end_date',
    name: 'Дата окончания',
    width: 120,
  },
];
