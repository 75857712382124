import { TableAdd } from 'app/pages/cardsOgh/components/RightPanelCard/components/ActionTree/TableAdd/TableAdd';
import { TableDelete } from 'app/pages/cardsOgh/components/tables/TableDelete';
import { ContinueDialogDeleteTable } from 'app/pages/cardsOgh/components/tables/TableDelete/ContinueDialogDeleteTable';
import { ButtonBlue } from 'core/newComponents/Button';
import { Button } from 'core/uiKit/components/buttons/Button';
import { Dialog } from 'core/uiKit/components/dialogs/Dialog';
import { useParamsTable } from 'core/uiKit/components/tables/Table';
import React, { useContext, useState } from 'react';

/* eslint-disable */

export const ContextActionTree = React.createContext(null);
const ContextSave = React.createContext(null);

const initialValue = {
  dataTree: null,
  ids: [],
  selectRowsTableAdd: [],
  treeTitle: '',
};

/**
 * Функция подготовки данных.
 *
 * @param {*} actions - Экшены.
 * @returns {{tree_actions: unknown, tree_count_actions: number}} - Возвращает подготовленные данные.
 */
const prepareActions = (actions) => {
  return {
    tree_actions: {
      ...Object.values(actions).reduce(
        (obj, { items, pathObjectId, typeId }, i) => {
          return {
            ...obj,
            [i + 1]: {
              object_list: items.reduce(
                (obj, { noCalc, id }) => ({
                  ...obj,
                  [id]: { noCalc: noCalc ? noCalc : 0 },
                }),
                {},
              ),
              path_object_id: pathObjectId,
              path_object_type: typeId,
            },
          };
        },
        {},
      ),
    },
    tree_count_actions: Object.keys(actions).length,
  };
};

/**
 * Компонент дерева.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.children - Дети.
 * @returns {JSX.Element} - Возвращает обертку контекста с диалогом.
 */
export const ActionTree = ({ children }) => {
  const [dataDialog, setDataDialog] = useState(initialValue);
  const [continueDialog, setContinueDialog] = useState(false);
  const { resetPagination } = useParamsTable();
  const [actions, setActions] = useState({});
  const [open, setOpen] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [selectRowsTableDelete, setSelectRowsTableDelete] = useState([]);

  /**
   * Функция выбора.
   *
   * @param {Array<*>} arr - Массив.
   * @returns {void} - Nothing,.
   */
  const setSelectRowsTableAdd = (arr) => {
    setDataDialog((pre) => ({
      ...pre,
      selectRowsTableAdd: arr,
    }));
  };

  /**
   * Функция закрытия диалога.
   *
   * @returns {void} - Nothing.
   */
  const closeDialog = () => {
    resetPagination();
    setOpen(false);
  };

  /**
   * Функция открытия диалога.
   *
   * @returns {void} - Nothing.
   */
  const openDialog = () => setOpen(true);

  /**
   * Функция инициализации диалога.
   *
   * @param {object} dataTree - Массив.
   * @returns {void} - Nothing.
   */
  const setDialog = (dataTree) => {
    openDialog();
    setDataDialog((pre) => ({
      ...pre,
      dataTree,
      ids: dataTree.items.map(({ id }) => id),
      treeTitle: dataTree.title,
    }));
  };

  /**
   * Функция Удаления колонки.
   *
   * @returns {void} - Nothing.
   */
  const removeRow = () => {
    const { ids } = dataDialog;
    setDataDialog((pre) => ({
      ...pre,
      ids: ids.filter((id) => !selectRowsTableDelete.includes(id)),
    }));
    setContinueDialog(false);
  };

  /**
   * Функция подтверждения удаления колонки.
   *
   * @returns {void} - Nothing.
   */
  const removeRowConfirm = () => {
    setContinueDialog(true);
  };

  /**
   * Функция сохранения данных.
   *
   * @returns {void} - Nothing.
   */
  const save = () => {
    const { dataTree, ids } = dataDialog;
    setActions((pre) => ({
      ...pre,
      [dataTree.typeId]: {
        ...dataTree,
        items: dataTable.filter(({ id }) => ids.includes(id)),
      },
    }));
    closeDialog();
  };

  /**
   * Функция добавления колонки.
   *
   * @returns {void} - Nothing.
   */
  const addRow = () => {
    const { ids, selectRowsTableAdd } = dataDialog;
    setDataDialog((pre) => ({
      ...pre,
      ids: [...ids, ...selectRowsTableAdd],
    }));
  };

  return (
    <ContextSave.Provider value={prepareActions(actions)}>
      <ContextActionTree.Provider
        value={{ closeDialog, dataDialog, setDialog }}
      >
        <ContinueDialogDeleteTable
          continueDialog={continueDialog}
          setContinueDialog={setContinueDialog}
          removeRow={removeRow}
        />
        <Dialog
          isOpen={open}
          maxWidth={'lg'}
          textHeader={'Групповое изменение элементов паспорта ОГХ'}
          Body={
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <TableAdd
                titleTable={dataDialog.treeTitle}
                setSelectRowsTableAdd={setSelectRowsTableAdd}
                selectRow={dataDialog}
              />
              <div className="my-3">
                <ButtonBlue
                  className="mx-3"
                  disabled={!dataDialog.selectRowsTableAdd.length}
                  onClick={addRow}
                >
                  ↓ Добавить
                </ButtonBlue>
                <ButtonBlue
                  className="mx-3"
                  disabled={!selectRowsTableDelete.length}
                  onClick={removeRowConfirm}
                >
                  ↑ Исключить
                </ButtonBlue>
              </div>
              <TableDelete
                setDataTable={setDataTable}
                title={dataDialog.treeTitle}
                dataTree={dataDialog.dataTree}
                setSelectRowsTableDelete={setSelectRowsTableDelete}
              />
            </div>
          }
          ButtonsActions={
            <div>
              <Button
                style={{ marginRight: 5 }}
                color={'primary'}
                variant={'outlined'}
                onClick={closeDialog}
              >
                Закрыть
              </Button>
              <Button color={'primary'} variant={'contained'} onClick={save}>
                Готово
              </Button>
            </div>
          }
        />
        {children}
      </ContextActionTree.Provider>
    </ContextSave.Provider>
  );
};

/**
 * Контекст сохранения.
 *
 * @returns {*} - Контекст.
 */
export const useSaveTreeAction = () => {
  return useContext(ContextSave);
};

/**
 * Контекст дерева.
 *
 * @returns {*} - Контекст.
 */
export const useTreeAction = () => {
  return useContext(ContextActionTree);
};
