import { RANDOM_ID, RANDOM_NUMBER } from 'core/forTesting/constants';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery/useMutationAdaptor';
import { shallow } from 'enzyme';
import { useParams } from 'react-router-dom';
import { vi } from 'vitest';

import { PrintEquipmentComp } from './PrintEquipment';

vi.mock('core/utils/hooks/useReactQuery/useMutationAdaptor');
vi.mock('react-router-dom');

/**
 * Дефолтные моки.
 *
 * @returns {void}
 */
const defaultMock = () => {
  useMutationAdaptor.mockReturnValue([vi.fn(), { isLoading: false }]);
  useParams.mockReturnValue({ id: RANDOM_ID });
};

describe('PrintEquipmentSpec', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    defaultMock();
    // 🔥 Act
    const wrapper = shallow(
      <PrintEquipmentComp {...{ isCreateMode: true, typeId: RANDOM_NUMBER }} />,
    );

    // ❓ Assert
    expect(wrapper.text()).toBe('');
  });
  it('isCreateMode', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    defaultMock();
    // 🔥 Act
    const wrapper = shallow(
      <PrintEquipmentComp
        {...{ isCreateMode: false, typeId: RANDOM_NUMBER }}
      />,
    );

    // ❓ Assert
    expect(wrapper.text()).toBe('<PrintButton />');
  });
  it('props', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    defaultMock();
    // 🔥 Act
    const wrapper = shallow(
      <PrintEquipmentComp
        {...{ isCreateMode: false, typeId: RANDOM_NUMBER }}
      />,
    );

    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      isLoading: false,
      onClick: expect.any(Function),
      text: 'Детализация',
    });
  });
  it('onClick', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    defaultMock();
    const sendReportSpy = vi.fn();

    useMutationAdaptor.mockReturnValue([sendReportSpy, { isLoading: false }]);
    useParams.mockReturnValue({ id: RANDOM_ID });
    // 🔥 Act
    const wrapper = shallow(
      <PrintEquipmentComp
        {...{ isCreateMode: false, typeId: RANDOM_NUMBER }}
      />,
    );

    const { onClick } = wrapper.props();
    onClick();

    // ❓ Assert
    expect(sendReportSpy).toHaveBeenCalledWith({
      id: String(RANDOM_ID),
      typeId: String(RANDOM_NUMBER),
    });
  });
});
