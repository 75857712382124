import { SIGNATURES_NAME } from 'core/form/finalForm/fields/prepared/FileSignatureCryptoProFF';
/* eslint-disable */
import { getValidator } from 'core/form/finalForm/utils/validate';

/**
 *
 * @param values
 */
export const formValidationDialogSigningOnRenewalOfApprovalDate = (values) => {
  const { setRequired, hasErrors, getErrors } = getValidator(values);

  setRequired(
    SIGNATURES_NAME,
    'Выберите сертификат для подписи и нажмите подписать',
  );

  return hasErrors() ? getErrors() : null;
};
