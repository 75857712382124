/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import getDisplayName from './getDisplayName';

const propTypes = {
  showCondition: PropTypes.bool,
};

function withCheckBeforeRenderDialog(Component) {
  function WithCheckBeforeRenderDialog({ showCondition, ...rest }) {
    return showCondition ? (
      <Component {...rest} showCondition={showCondition} />
    ) : null;
  }

  WithCheckBeforeRenderDialog.displayName = `WithCheckBeforeRenderDialog(${getDisplayName(
    Component,
  )})`;
  WithCheckBeforeRenderDialog.propTypes = propTypes;

  return WithCheckBeforeRenderDialog;
}

 
export default withCheckBeforeRenderDialog;
