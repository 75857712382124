/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import getDisplayName from './getDisplayName';

const propTypes = {
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any,
  }),
  meta: PropTypes.shape({
    active: PropTypes.bool,
    asyncValidating: PropTypes.bool,
    autofilled: PropTypes.bool,
    dirty: PropTypes.bool,
    error: PropTypes.string,
    form: PropTypes.string,
    initial: PropTypes.any,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitFailed: PropTypes.bool,
    submitting: PropTypes.bool,
    touched: PropTypes.bool,
    valid: PropTypes.bool,
    visited: PropTypes.bool,
  }),
};

function withReduxForm(Component) {
  class WithReduxForm extends React.Component {
    render() {
      const { disabled, input, meta, ...otherProps } = this.props;

      const errorText =
        !disabled && meta && meta.error && meta.submitFailed
          ? { errorText: meta.error }
          : {};

      return (
        <Component
          disabled={disabled}
          onBlur={input && input.onBlur}
          onChange={input && input.onChange}
          onDragStart={input && input.onDragStart}
          onDrop={input && input.onDrop}
          onFocus={input && input.onFocus}
          {...otherProps}
          {...errorText}
          name={input && input.name}
          value={
            input && (input.value || isZero(input.value))
              ? input.value
              : meta && meta.initial
          }
        />
      );
    }
  }

  WithReduxForm.displayName = `WithReduxForm(${getDisplayName(Component)})`;
  WithReduxForm.propTypes = propTypes;

  return WithReduxForm;
}

const isZero = (value) => Number(value) === 0;

 
export default withReduxForm;
