 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Состав оборудования, секция поворотная, шт.__.
 *
 * @type {{ accessor: 'section',  Header: 'Состав оборудования, секция поворотная, шт.' }}
 * @augments Cell
 */
export const section = {
  accessor: 'section',
  Header: 'Состав оборудования, секция поворотная, шт.',
  sortable: true,
};
