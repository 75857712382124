import { COMMENT_NAME } from 'core/form/finalForm/fields/prepared/textField/CommentFF';
import { getValidator } from 'core/form/finalForm/utils/validate';
import { generateRandomWord } from 'core/forTesting/generateRandomWord';

import { formValidation } from './formValidation';

jest.mock('core/form/finalForm/utils/validate');

/* eslint-disable */
describe('file formValidation.SPEC', () => {
  it('getValidator toHaveBeenCalledWith', () => {
    expect.hasAssertions();

    // Arrange (всякие моки)
    const VALUE = generateRandomWord();
    getValidator.mockReturnValue({
      setRequired: jest.fn(),
      hasErrors: jest.fn(),
      getErrors: jest.fn(),
    });

    // Act
    formValidation(VALUE);

    // Assert
    expect(getValidator).toHaveBeenCalledWith(VALUE);
  });

  it('setRequired toHaveBeenCalledWith', () => {
    expect.hasAssertions();

    // Arrange (всякие моки)
    const setRequired = jest.fn();
    const VALUE = generateRandomWord();
    getValidator.mockReturnValue({
      setRequired,
      hasErrors: jest.fn(),
      getErrors: jest.fn(),
    });

    // Act
    formValidation(VALUE);

    // Assert
    expect(setRequired).toHaveBeenCalledWith(COMMENT_NAME);
  });

  it('hasErrors true', () => {
    expect.hasAssertions();

    // Arrange (всякие моки)
    const ERROR = generateRandomWord();
    const setRequired = jest.fn();
    const hasErrors = jest.fn().mockReturnValue(true);
    const getErrors = jest.fn().mockReturnValue(ERROR);
    const VALUE = generateRandomWord();
    getValidator.mockReturnValue({
      setRequired,
      hasErrors,
      getErrors,
    });

    // Assert
    expect(formValidation(VALUE)).toBe(ERROR);
  });

  it('hasErrors false', () => {
    expect.hasAssertions();

    // Arrange (всякие моки)
    const ERROR = generateRandomWord();
    const setRequired = jest.fn();
    const hasErrors = jest.fn().mockReturnValue(false);
    const getErrors = jest.fn().mockReturnValue(ERROR);
    const VALUE = generateRandomWord();
    getValidator.mockReturnValue({
      setRequired,
      hasErrors,
      getErrors,
    });

    // Assert
    expect(formValidation(VALUE)).toBeNull();
  });
});
