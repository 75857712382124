import * as types from 'app/constants/actionTypes';

/* eslint-disable */
export function leaveCurrentElement(handler, currentGroup) {
  return {
    type: types.LEAVE_CURRENT_CARD,
    leavePageHandler: handler,
    currentGroup,
  };
}
