import oldValueConsumer from 'core/hocs/oldValueConsumer';
import withReduxForm from 'core/hocs/withReduxForm';

import CheckboxField from './CheckboxField';

const WithReduxFormCheckbox = withReduxForm(CheckboxField);
const WithOldValueCheckbox = oldValueConsumer(WithReduxFormCheckbox); // используется ТОЛЬКО в разделе "Реестр согласований техники" для выделения полей и показа предыдущего значения

/* eslint-disable */
export { WithReduxFormCheckbox, WithOldValueCheckbox };

 
export default CheckboxField;
