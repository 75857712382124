import './CopyToClipboard.scss';

/* eslint-disable */
import { Icon } from 'core/uiKit/components/Icon';
import { Tooltip } from 'core/uiKit/components/Tooltip';
import { log } from 'core/utils/log';
import { useState } from 'react';

export const CopyToClipboard = ({ children, textForCopy }) => {
  const [text, setText] = useState(false);

  const changeText = () => {
    setText(true);
    setTimeout(() => setText(false), 1333);
  };

  const copyToClipboard = () =>
    navigator.clipboard
      .writeText(textForCopy || children)
      .then(() => {
        // Успех!
        changeText(false);
      })
      .catch((error) => {
        // Неудача :(
        log.info(error);
      });

  const copyToClipboardStop = (e) => {
    e.stopPropagation();
    e.preventDefault();
    return copyToClipboard();
  };

  return (
    <Tooltip
      title={
        <span
          className="copy-to-clipboard__button-text"
          onClick={copyToClipboardStop}
        >
          {text ? (
            <span>
              <Icon fontSize="small" className={'copy-to-clipboard__icon me-1'}>
                published_with_changes
              </Icon>
              <span>Скопировано</span>
            </span>
          ) : (
            <span>
              <Icon fontSize="small" className={'me-1 copy-to-clipboard__icon'}>
                content_copy
              </Icon>
              <span>Копировать</span>
            </span>
          )}
        </span>
      }
      placement="top-end"
      arrow
    >
      <div className={'copy-to-clipboard__text'} onClick={copyToClipboard}>
        {children}
      </div>
    </Tooltip>
  );
};
