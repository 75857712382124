/* eslint-disable  */
const dateValidator = {
  '': {
    /**
     *
     * @param vals
     */
    dateRange: function (vals) {
      if (vals.startDate && vals.endDate && vals.startDate > vals.endDate) {
        return false;
      } else {
        return true;
      }
    },
  },
};

export default dateValidator;
