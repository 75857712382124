import { del } from 'app/api/crud/del';
/* eslint-disable */
import { queryClient } from 'app/routes/QueryClientProvider';
import { ContinueDialog } from 'core/uiKit/components/dialogs/Continue.Dialog';
import { toast } from 'core/uiKit/components/Toast';
import { DeleteIcon, IconButton, Tooltip } from 'core/uiKit/material-ui';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import { useState } from 'react';

/**
 * Функция deleteOperation.
 *
 * @param {Array} ids - Массив идентификаторов.
 * @returns {Promise<*|Response>}
 */
const deleteOperation = (ids) => del('pgm/operation', { ids });

/**
 * Компонент DeleteOperation.
 *
 * @param {object} props - Пропсы.
 * @param {*} props.value - Значение.
 * @returns {JSX.Element}
 */
export const DeleteOperation = ({ value }) => {
  const [open, setOpen] = useState(false);

  const [sendDelete, { isLoading }] = useMutationAdaptor(
    () => deleteOperation(value),
    {
      /**
       *
       * @param root0
       * @param root0.message
       */
      onError: ({ message }) => {
        toast.error(message);
        setOpen(false);
      },

      /**
       *
       */
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['basePgmOperations'] });
        setOpen(false);
      },
    },
  );

  /**
   * Функция openDialogHandler.
   *
   * @returns {void}
   */
  const openDialogHandler = () => {
    setOpen(true);
  };

  /**
   * Функция closeDialogHandler.
   *
   * @returns {void}
   */
  const closeDialogHandler = () => {
    setOpen(false);
  };

  return (
    <>
      <ContinueDialog
        textHeader={'Удалить ?'}
        isOpen={open}
        closeDialog={closeDialogHandler}
        isLoading={isLoading}
        send={sendDelete}
      />
      <Tooltip title="Удалить">
        <IconButton
          size="small"
          color="secondary"
          aria-label="delete"
          onClick={openDialogHandler}
          loading={isLoading}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};
