import { turnIntoCamelCase } from 'app/utils/turnIntoCamelCase';

import { DeleteOperation } from './components/DeleteOperation';
import { EditOperation } from './components/EditOperation';

/* eslint-disable */
export const basePgmOperationsColumns = [
  {
    accessor: 'id',
    Header: '',
    align: 'center',
    Cell: (props) => {
      const {
        value,
        row: { original: originalRow },
      } = props;
      return (
        <div className={'d-flex align-items-center'}>
          <DeleteOperation value={value} />
          <EditOperation originalRow={turnIntoCamelCase(originalRow)} />
        </div>
      );
    },
    minWidth: 30,
  },
  {
    accessor: 'operation_kind_name',
    Header: 'Операция',
  },
  {
    accessor: 'reagent_kind_name',
    Header: 'Вид ПГР',
  },
  {
    accessor: 'reagent_name',
    Header: 'Наименование ПГР',
  },
  {
    accessor: 'quantity',
    Header: 'Количество, т.',
  },
  {
    accessor: 'ui_target_date',
    Header: 'Дата',
  },
  {
    accessor: 'consignee_name',
    Header: 'Получатель ПГР',
  },
  {
    accessor: 'gov_number',
    Header: 'Рег.номер ТрС',
  },
  {
    accessor: 'customer_name',
    Header: 'Организация – заказчик техники',
  },
  {
    accessor: 'comment',
    Header: 'Комментарий',
  },
];
// Запросить URL: https://dev.ods.test.intermb.ru/api/pgm/operation?ids=275
// Запросить URL: https://dev.ods.test.intermb.ru/api/pgm/operation?ids=304
