 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Тип__.
 *
 * @type {{
 * accessor: 'object_type_name',
 * Header: 'Тип',
 * sortable: true,
 * }}
 * @augments Cell
 */
export const object_type_name = {
  accessor: 'object_type_name',
  Header: 'Тип',
  sortable: true,
};
