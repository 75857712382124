import { Validator } from 'core/form/Validator';

/**
 * Проверка на число, число может быть в строке.
 *
 * @param {*} value - Значение которое надо проверить.
 * @returns {boolean} - Возвращаем true, если результат преобразования не является NaN и не равен null.
 */
function isNumber(value) {
  return !isNaN(Number(value)) && !isNaN(parseFloat(value));
}

/**
 * Валидация условия Введенное значение параметра "Диаметр" должно быть кратно 2.
 *
 * @param {object} param - Param.
 * @param {number} [param.diameter] - Значение инпута "Диаметр".
 * @param {number} [param.life_form_type_id] - Значение инпута "Жизненная форма".
 * @param {number} [param.height] - Высота, м.
 * @returns {{}|{diameter: string}}
 */
export const validateDiameterMultipleTwo = ({
  diameter,
  life_form_type_id,
  height,
} = {}) => {
  if (isNumber(diameter) && (diameter < 2 || diameter % 2 !== 0)) {
    return {
      diameter: 'Введенное значение параметра "Диаметр" должно быть кратно 2',
    };
  }

  if (getRequiredValidateDiameter({ height, life_form_type_id })) {
    const validator = new Validator({ diameter });
    validator.setRequired('diameter');

    return validator.getErrors() || {};
  }
  return {};
};

/**
 * Валидация обязательности поля.
 *
 * @param {object} [param] - Param.
 * @param {number} [param.life_form_type_id] - Значение инпута "Жизненная форма" 1-дерево.
 * @param {number} [param.height] - Высота, м.
 * @returns {boolean}
 */
export const getRequiredValidateDiameter = ({
  life_form_type_id,
  height,
} = {}) => {
  if (life_form_type_id === 1) {
    return height && +height >= 1.3;
  }
  return false;
};
