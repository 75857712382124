import { validateCleanSubcategory } from 'app/components/card/common/validation';
import { validateLayoutLength } from 'app/components/card/common/validation';
import buildMandatoriesMessage from 'app/components/card/common/validation/buildMandatoriesMessage';
import validateOdh from 'app/components/card/common/validation/validateOdh';
import { createAttribute as createDocumentsAttribute } from 'app/components/card/ogh/DocumentsTab';
import { validateCleanSpCategoryIdField } from 'app/components/card/ogh/Odh/components/CleanCategory/CleanSpCategoryIdField/validateCleanSpCategoryIdField';
import { validateIntensityNumField } from 'app/components/card/ogh/Odh/components/CleanCategory/IntensityNumField/validateIntensityNumField';
import { mapEngineeringStructuresTab } from 'app/components/card/ogh/Odh/components/tabs/EngineeringStructures.Tab/resetEngineeringStructuresTab';
import { validateEngineeringStructuresTab } from 'app/components/card/ogh/Odh/components/tabs/EngineeringStructures.Tab/validateEngineeringStructuresTab';
import { passDevInitiatorValidation } from 'app/components/card/ogh/Odh/validation/pass_dev_initiator.validation';
import { REQUIRED_FIELD_MESSAGE } from 'app/constants/messages';
import { validateMandatoryProperties } from 'app/utils/validation';
import dateFormatService from 'core/services/dateFormatService';
import { INTENSITY_NUM_NAME } from 'core/uiKit/preparedInputs/numberField/IntensityNum';
import { CATEGORY_SP_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CategorySpId';
import { CLEAN_CATEGORY_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CleanCategoryId';
import { CLEAN_SUB_CATEGORY_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CleanSubCategoryId';
import { log } from 'core/utils/log';
import { findIndex } from 'lodash';
import __snakeCase from 'lodash/snakeCase';
import { SubmissionError } from 'redux-form';

import { REPAIRS_INFO_LIST_NAME } from '../ImprovementTerritoryCard/components/tabs/Repairs.Tab/Table.Repairs.Tab';
import { REPAIRS_INFO_LIST_PLAN_NAME } from './components/tabs/RepairsPlan.Odh.Tab';
import {
  datesField,
  mandatoryFieldNames,
  mandatoryFieldNamesForOrphanObject,
  notIsOrphanField,
  numericFields,
  simpleMandatoryFieldNames,
  simpleMandatoryFieldNamesForOrphanObject,
  tabToFields,
  tabToFieldsForOrphanObject,
} from './odhFields';
import {
  footwayAreaValidation,
  inboundAreaValidation,
  marginAreaValidation,
  roadwayAreaValidation,
  rotorAreaValidation,
  snowAreaValidation,
  totalAreaValidation,
} from './validation';
//
// /**
//  * A.
//  *
//  * @param {*} param0 - P.
//  * @returns {*}
//  */
// const repairsPlanAttributeMapper = ({ file_list, ...item }) => {
//   return {
//     ...item,
//     file_list: file_list
//       ? file_list.map(({ files }) => ({
//         files: files.file_id,
//       }))
//       : [
//         {
//           files: null,
//         },
//       ],
//   };
// };
//

/**
 * S.
 *
 * @param {*} is_orphan_object - S.
 * @param {*} owner_id - S.
 * @returns {*}
 */
const validateOwner = (is_orphan_object, owner_id) => {
  if (
    is_orphan_object === 0 &&
    (owner_id === null || owner_id.id === null || owner_id.id === undefined)
  ) {
    return REQUIRED_FIELD_MESSAGE;
  } else {
    return null;
  }
};

/**
 * S.
 *
 * @param {*} is_orphan_object - A.
 * @param {*} customer_id - A.
 * @returns {*}
 */
const validateCustomer = (is_orphan_object, customer_id) => {
  if (
    is_orphan_object === 0 &&
    (customer_id === null ||
      customer_id.id === null ||
      customer_id.id === undefined)
  ) {
    return REQUIRED_FIELD_MESSAGE;
  } else {
    return null;
  }
};

/**
 * A.
 *
 * @param {*} values - V.
 * @returns {*}
 */
const fullValidator = (values) => {
  const clean_category_id = values.clean_category_id;
  const clean_subcategory_id = values.clean_subcategory_id;
  const intensity = values[INTENSITY_NUM_NAME];
  const category_sp_id = values[CATEGORY_SP_ID_NAME];

  const isOrphanObject = values.is_orphan_object;

  const errors = {
    ...validateOdh(values),
    ...validateEngineeringStructuresTab(values),
    ...passDevInitiatorValidation(values),
    ...validateMandatoryProperties(
      values,
      isOrphanObject
        ? Object.keys(simpleMandatoryFieldNamesForOrphanObject)
        : Object.keys(simpleMandatoryFieldNames),
    ),
    ...validateLayoutLength(values),
    ...marginAreaValidation(values),
    ...roadwayAreaValidation(
      values,
      isOrphanObject
        ? Object.keys(simpleMandatoryFieldNamesForOrphanObject)
        : Object.keys(simpleMandatoryFieldNames),
    ),
    ...footwayAreaValidation(values),
    ...totalAreaValidation(values),
    ...snowAreaValidation(values),
    ...inboundAreaValidation(values),
    ...rotorAreaValidation(values),
  };

  if (clean_category_id === null || clean_category_id === '') {
    errors[CLEAN_CATEGORY_ID_NAME] = REQUIRED_FIELD_MESSAGE;
  }

  const owner_mes = validateOwner(
    values.is_orphan_object || 0,
    values.owner_id,
  );

  if (owner_mes) {
    errors.owner_id = owner_mes;
  }

  const customer_mes = validateCustomer(
    values.is_orphan_object || 0,
    values.customer_id,
  );

  if (customer_mes) {
    errors.customer_id = customer_mes;
  }

  const clean_sub_mes = validateCleanSubcategory(
    clean_category_id,
    clean_subcategory_id,
    values.clean_subcategory,
    values.isCurrentObjectIsExternalSystem,
  );
  if (clean_sub_mes) {
    errors[CLEAN_SUB_CATEGORY_ID_NAME] = clean_sub_mes;
  }
  if (Object.keys(errors).length) {
    log.message('Не проходит валидация по полям', () => {
      log.table(errors);
    });
  }
  validateIntensityNumField(
    errors,
    clean_category_id,
    intensity,
    isOrphanObject,
  );
  validateCleanSpCategoryIdField(
    errors,
    values.is_orphan_object,
    category_sp_id,
  );

  return errors;
};

/**
 * D.
 *
 * @param {*} param0 - R.
 * @returns {*}
 */
const onSubmit =
  ({ props }) =>
  (formValues) => {
    const { mode, card, updateObject, createObject, alert } = props;

    const errors = fullValidator({
      ...formValues,
      clean_subcategory: card.clean_subcategory,
      isCurrentObjectIsExternalSystem: props.isCurrentObjectIsExternalSystem,
      type_id: card.type_id,
    });

    if (Object.keys(errors).length) {
      if (alert) {
        if (formValues.is_orphan_object) {
          alert(
            buildMandatoriesMessage(
              errors,
              tabToFieldsForOrphanObject,
              mandatoryFieldNamesForOrphanObject,
            ),
          );
        } else {
          alert(
            buildMandatoriesMessage(errors, tabToFields, mandatoryFieldNames),
          );
        }
      }
      throw new SubmissionError(errors);
    }

    formValues = {
      ...formValues,

      [REPAIRS_INFO_LIST_NAME]: formValues.repairs_info_list,
      [REPAIRS_INFO_LIST_PLAN_NAME]: formValues[REPAIRS_INFO_LIST_PLAN_NAME],
      // ...createRepairsPlanAttribute(formValues, {
      //   mapper: repairsPlanAttributeMapper,
      // }),
      ...createDocumentsAttribute(formValues),
    };

    const interval = {};
    const attribute = {};
    const calcAttribute = {};
    const is_orphan_object = formValues.is_orphan_object;

    Object.keys(formValues).forEach((key) => {
      // todo: тут переделать на switch

      if (formValues[key] === null || formValues[key] === '') {
        return;
      }

      if (is_orphan_object && notIsOrphanField.includes(key)) {
        return;
      }

      switch (key) {
        case CLEAN_SUB_CATEGORY_ID_NAME: {
          if (formValues[key] !== null && formValues[key] !== '') {
            attribute.clean_subcategory_id = {
              clean_subcategory: formValues[key],
            };
          }
          break;
        }
        case CLEAN_CATEGORY_ID_NAME: {
          if (formValues[key] !== null && formValues[key] !== '') {
            attribute.clean_category_id = {
              clean_category: formValues[key],
            };
          }
          break;
        }
        case CATEGORY_SP_ID_NAME: {
          if (formValues[key] !== null && formValues[key] !== '') {
            attribute[CATEGORY_SP_ID_NAME] = {
              category_sp: formValues[key],
            };
          }
          break;
        }
        default: {
          if (key === 'startDate' || key === 'endDate') {
            interval[key] = dateFormatService.formatDate(formValues[key]);
          } else if (key === 'is_orphan_object') {
            attribute[key] = formValues[key] ? formValues[key] : null;
          } else if (datesField.indexOf(key) !== -1) {
            attribute[key] = dateFormatService.formatDate(formValues[key]);
          } else if (key === 'customer_id') {
            if (formValues[key].id !== null) {
              attribute[key] = {
                legal_person: parseInt(formValues[key].id, 10),
              };
            }
          } else if (key === 'grbs_id') {
            attribute[key] = {
              legal_person: parseInt(formValues[key], 10),
            };
          } else if (key === 'owner_id') {
            if (formValues[key].id !== null) {
              attribute[key] = {
                legal_person: parseInt(formValues[key].id, 10),
              };
            }
          } else if (findIndex(numericFields, ['name', key]) !== -1) {
            attribute[key] = parseFloat(
              String(formValues[key]).replace(/[,]+/g, '.'),
            );
          } else if (
            key === 'axis_length' ||
            key === 'reagent_area' ||
            key === 'rotor_area' ||
            key === 'snow_area' ||
            mapEngineeringStructuresTab.includes(key)
          ) {
            attribute[key] = +formValues[key];
          } else if (
            // distance, rotor_area и snow_area
            key === 'asperity' ||
            key === 'auto_footway_area' ||
            key === 'bar_antinoise' ||
            key === 'bar_concrete' ||
            key === 'bar_foot' ||
            key === 'bar_fracasso' ||
            key === 'bar_new_jersey' ||
            key === 'bar_pipe' ||
            key === 'bar_trans' ||
            key === 'bar_wave' ||
            key === 'buffer' ||
            key === 'cleaning_area' ||
            key === 'footway_area' ||
            key === 'footway_pole' ||
            key === 'gutters_length' ||
            key === 'inbound_area' ||
            key === 'info' ||
            key === 'layout_length' ||
            key === 'manual_footway_area' ||
            key === 'margin_area' ||
            key === 'margin_auto_clean_area' ||
            key === 'margin_manual_clean_area' ||
            key === 'pointer' ||
            key === 'roadway_area' ||
            key === 'roadway_noprkg_auto_clean_area' ||
            key === 'roadway_noprkg_manual_clean_area' ||
            key === 'roadway_prkg_auto_clean_area' ||
            key === 'roadway_prkg_manual_clean_area' ||
            key === 'sign' ||
            key === 'station_clean_area' ||
            key === 'station_number' ||
            key === 'total_area'
          ) {
            calcAttribute[__snakeCase(key)] = +formValues[key];
          } else {
            attribute[__snakeCase(key)] = formValues[key];
          }
        }
      }
    });

    if ((mode && mode.createMode) || false) {
      createObject({
        type: card.type_id,
        ...interval,
        attribute,
        calcAttribute,
      });
    } else {
      updateObject({
        id: card.record_id,
        ...(card.root_id && { rootId: card.root_id }),
        type: card.type_id,
        ...interval,
        attribute,
        calcAttribute,
      });
    }
  };

export default onSubmit;
