import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import { useFieldRF } from 'core/form/reduxForm/hooks/useField.RF';
import { COATING_GROUP_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingGroupId';
import React from 'react';

export const MARGIN_STRENG_TYPE_ID_NAME = 'margin_streng_type_id';

/**
 * Select Field RF __Тип укрепления (уточнение)__.
 *
 * @param props - Пропсы.
 * @returns
 */
export const MarginStrengTypeIdField = (props: object) => {
  const { value: valueCoatingGroupIdName } = useFieldRF(COATING_GROUP_ID_NAME);
  const { editMode } = useMode();
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();
  return (
    <SelectRF
      name={MARGIN_STRENG_TYPE_ID_NAME}
      label={'Тип укрепления (уточнение)'}
      disabled={
        !(
          editMode &&
          isEditCurrentObjectIsExternalSystem &&
          valueCoatingGroupIdName
        )
      }
      {...props}
    />
  );
};
