import { LinkLandscapingCell } from 'core/uiKit/components/cells/Link.Landscaping.Cell';

export const columnsLandscapingTable = [
  {
    Header: 'Год',
    accessor: 'year',
  },
  {
    Cell: LinkLandscapingCell,
    Header: 'Ссылка на план в СОК Благоустройство',
    accessor: 'url',
  },
  {
    Header: 'Плановая дата окончания работ',
    accessor: 'end_date_plan',
  },
  {
    Header: 'Фактическая дата окончания работ',
    accessor: 'end_date_fact',
  },
];
