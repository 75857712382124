import { NumberFieldFF } from 'core/form/finalForm/fields';
import { KeyboardDateTimePickerFF } from 'core/form/finalForm/fields/default/datePickers/KeyboardDateTimePickerFF';
import { TextFieldFF } from 'core/form/finalForm/fields/default/TextFieldFF';
import {
  REAGENT_NAME,
  ReagentFF,
} from 'core/form/finalForm/fields/prepared/selects/simple/ReagentFF';
import {
  REAGENT_KIND_NAME,
  ReagentKindFF,
} from 'core/form/finalForm/fields/prepared/selects/simple/ReagentKindFF';
import { CommentFF } from 'core/form/finalForm/fields/prepared/textField/CommentFF';
import { useField } from 'react-final-form';

/**
 * Приход.
 *
 * @returns {JSX}
 */
export const BodyDialogRefill = () => {
  const {
    input: { value: reagentKindId },
  } = useField(REAGENT_KIND_NAME);

  const {
    input: { onChange: reagentChange },
  } = useField(REAGENT_NAME);

  return (
    <>
      <KeyboardDateTimePickerFF name={'targetDateTime'} label={'Дата'} />
      <div className={'container__two_column'}>
        <ReagentKindFF required={true} withChange={() => reagentChange(null)} />
        <ReagentFF
          required={true}
          filter={(data) =>
            data.filter((item) => item.reagent_kind_id === reagentKindId)
          }
        />
        <NumberFieldFF
          required={true}
          decimalScale={2}
          name={'quantity'}
          label={'Количество, т.'}
        />
        <TextFieldFF required={true} name={'documentNum'} label={'Документ'} />
      </div>
      <CommentFF rows={4} fullWidth={true} />
    </>
  );
};
