import { renderCustomTextField } from 'app/components/card/common/grid';
import renderElementFormGrid from 'app/components/card/common/grid/render/renderElementFormGrid/renderElementFormGrid';
import { column } from 'app/constants/tables';

/* eslint-disable */
export default function Uaisbu() {
  const getElements = () => {
    return [
      {
        id: 'registration_date_bu',
        name: 'registration_date_bu',
        label: 'Дата регистрации',
        formValue: true,
        editable: false,
        component: renderCustomTextField,
      },
      {
        id: 'deregistration_date_bu',
        name: 'deregistration_date_bu',
        label: 'Дата снятия с учета',
        formValue: true,
        editable: false,
        component: renderCustomTextField,
      },
      {
        id: 'car_model_bu',
        name: 'car_model_bu',
        label: 'Данные о марке базового шасси',
        formValue: true,
        editable: false,
        component: renderCustomTextField,
      },
      {
        id: 'inventory_number_bu',
        name: 'inventory_number_bu',
        label: 'Инвентарный номер',
        formValue: true,
        editable: false,
        component: renderCustomTextField,
      },
      {
        id: 'chassis_number_bu',
        name: 'chassis_number_bu',
        label: 'Номер шасси (рамы)',
        formValue: true,
        editable: false,
        component: renderCustomTextField,
      },
      {
        id: 'body_number_bu',
        name: 'body_number_bu',
        label: 'Номер кузова (кабины, прицепа)',
        formValue: true,
        editable: false,
        component: renderCustomTextField,
      },
      {
        id: 'engine_model_bu',
        name: 'engine_model_bu',
        label: 'Модель двигателя',
        formValue: true,
        editable: false,
        component: renderCustomTextField,
      },
      {
        id: 'name_asset_bu',
        name: 'name_asset_bu',
        label: 'Наименование ОС',
        formValue: true,
        editable: false,
        component: renderCustomTextField,
      },
      {
        id: 'car_special_model_bu',
        name: 'car_special_model_bu',
        label: 'Модель техники',
        formValue: true,
        editable: false,
        component: renderCustomTextField,
      },
      {
        id: 'car_func_type_bu',
        name: 'car_func_type_bu',
        label: 'Тип техники',
        formValue: true,
        editable: false,
        component: renderCustomTextField,
      },
      {
        id: 'engine_number_bu',
        name: 'engine_number_bu',
        label: 'Номер двигателя',
        formValue: true,
        editable: false,
        component: renderCustomTextField,
      },
    ];
  };

  return renderElementFormGrid(getElements(), column.TWO);
}
