import * as api from 'app/api';
import * as types from 'app/constants/actionTypes';
import { ODH } from 'app/constants/oghTypes';
import _ from 'lodash';
import moment from 'moment';
import { actions as formActions } from 'react-redux-form';
import { createAction } from 'redux-actions';

import * as loaderActions from './loader';

/* eslint-disable */
export function formChange(values, model) {
  return function (dispatch) {
    dispatch(
      formActions.change(`rrf.report${model}` + values.model, values.value),
    );
  };
}

 
export const apiGetTechReport = createAction(
  types.API_GET_TECHNOLOGY_REPORT,
  function (data) {
    return {
      promise: api.getTechnologyReport(data),
    };
  },
);

/* eslint-disable */
export function onSubmit(values, model) {
  return function (dispatch) {
    dispatch(loaderActions.show());
    dispatch(formActions.change(`rrf.report${model}.page`, 0));

    const requestValues = getSearchFormValues(values);
    const promise = apiGetTechReport(requestValues);
    return dispatch(promise).finally(function () {
      dispatch(loaderActions.hide());
    });
  };
}

/* eslint-disable */
export function onReset(model) {
  return function (dispatch) {
    dispatch(formActions.reset(`rrf.report${model}`));
  };
}

/* eslint-disable */
export function onSort(values, model) {
  return function (dispatch) {
    dispatch(formActions.merge(`rrf.report${model}`, values));
    dispatch(formActions.submit(`rrf.report${model}`));
  };
}

/* eslint-disable */
export function onLimit(value, model) {
  return function (dispatch) {
    dispatch(formActions.change(`rrf.report${model}.maxRows`, value));
    dispatch(formActions.change(`rrf.report${model}.page`, 0));
    dispatch(formActions.submit(`rrf.report${model}`));
  };
}

/* eslint-disable */
export function onPagination(value, model) {
  return function (dispatch) {
    dispatch(formActions.change(`rrf.report${model}.page`, value));
    dispatch(formActions.submit(`rrf.report${model}`));
  };
}

function getSearchFormValues(values) {
  const formValues = { typeId: ODH };

  _.forEach(values, function (value, key) {
    let newKey, newValue;
    if (value) {
      newValue = value;
      newKey = key;
      if (key === 'date') {
        newValue = moment(value).format('DD.MM.YYYY HH:mm');
      } else if (key === 'provider' || key === 'carOwner') {
        newValue = value.id;
      } else if (key === 'customers') {
        newValue = value.map((v) => v.id).join(',');
      } else if (key === 'govNumber') {
        newValue = value.name;
      }
      formValues[newKey] = newValue;
    }
  });

  return formValues;
}
