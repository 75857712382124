import { resetTabs, Tabs } from 'app/components/card/common/tabs';
import React, { useEffect } from 'react';

/**
 * Компонент обертка для очистки таба.
 *
 * @param {object} props - Пропсы.
 * @param {Array} props.tabs - Табы.
 * @returns {JSX.Element}
 */
const TabsComponent = ({ tabs }) => {
  useEffect(() => {
    resetTabs();
  }, []);
  return <Tabs tabs={tabs} />;
};

/**
 * Функция табов.
 *
 * @param {Array} tabs - Табы.
 * @returns {Function}
 */
function PropsTabs(tabs) {
  return () => <TabsComponent tabs={tabs} />;
}

export default PropsTabs;
