import { AccordionPlantationTab } from '../Accordion.PlantationTab';
import { TableLawnList } from './Table.LawnList';

 
/* eslint-disable */
/**
 * Ведомость учета зеленых насаждений (газон)
 * @returns {JSX.Element}
 * @constructor
 */
 
export const LawnList = ({ expanded, onChange }) => {
  return (
    <AccordionPlantationTab
      code={'lawn_list'}
      title={'Ведомость учета зеленых насаждений (газон)'}
      Table={TableLawnList}
      expanded={expanded}
      onChange={onChange}
    />
  );
};
