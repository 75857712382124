import { RANDOM_WORD } from 'core/forTesting/constants';
import { shallow } from 'enzyme';

import { columnsTableVerticalLandscapingList } from './columns.Table.VerticalLandscapingList';
import { TableVerticalLandscapingList } from './Table.VerticalLandscapingList';

describe('TableVerticalLandscapingListSpec', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act

    const wrapper = shallow(
      <TableVerticalLandscapingList code={RANDOM_WORD} />,
    );
    // ❓ Assert
    expect(wrapper.text()).toBe('<TableList />');
  });
  it('props', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act

    const wrapper = shallow(
      <TableVerticalLandscapingList code={RANDOM_WORD} />,
    );
    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      code: RANDOM_WORD,
      columns: columnsTableVerticalLandscapingList,
    });
  });
});
