/* eslint-disable */
import React from 'react';
import Button from 'core/newComponents/Button';
import FileImage from './FileImage';

class ImageInput extends React.Component {
  render() {
    const set = this.props.value || new Set();
    const value = [];
    set.forEach((item) => value.push(item));
    const onChange = this.props.onChange(set);
    const onDelete = this.props.onDelete(set);
    return (
      <div>
        <div
          style={{
            width: '100%',
            maxHeight: '180px',
            overflowY: 'auto',
          }}
        >
          {value.map((item, index) => (
            <div
              // TODO: посмотреть, можно ли поставить вместо индекса какое-нибудь значение изображения (имя?)
              key={index}
              style={{ display: 'inline-block', margin: '5px 10px 5px 0px' }}
            >
              <Attachment attach={item} />
              <div
                style={{
                  textAlign: 'center',
                  height: '18px',
                  width: '100%',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  onDelete(item);
                }}
              >
                <svg
                  fill="rgba(10,10,10,0.8)"
                  height="18"
                  viewBox="0 0 24 24"
                  width="18"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                </svg>
              </div>
            </div>
          ))}
        </div>
        {this.props.errorText && (
          <span style={{ color: '#FF0000', fontSize: '9pt' }}>
            {this.props.errorText}
          </span>
        )}
        <div
          style={{
            marginTop: '5px',
            overflow: 'hidden',
            width: '160px',
            height: '50px',
          }}
          title={`Вложений: ${(value && value.length) || 0}`}
        >
          <Button
            startIcon={
              <svg fill="#FFFFFF" height="36" viewBox="0 0 24 24" width="36">
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z" />
              </svg>
            }
            onClick={() => {
              this.input && this.input.click();
            }}
          >
            Вложение
          </Button>
          <input
            ref={(input) => (this.input = input)}
            style={{
              position: 'relative',
              opacity: '0',
              filter: 'alpha(opacity:0)',
              padding: '0px',
              margin: '0px',
              border: 'none',
            }}
            type="file"
            {...this.props}
            value={null}
            onChange={onChange}
          />
        </div>
      </div>
    );
  }
}

function Attachment(props) {
  return /(.*)((\.jpeg)|(\.jpg)|(\.png)|(\.bmp))$/.test(props.attach.name) ? (
    <FileImage file={props.attach} height="100" />
  ) : (
    <div
      style={{
        height: '100px',
        width: '100px',
        textAlign: 'center',
        border: '1px dotted #AAA',
      }}
    >
      <svg fill="rgba(10,10,10,0.8)" height="48" viewBox="0 0 20 20" width="58">
        <path d="M2 12.5C2 9.46 4.46 7 7.5 7H18c2.21 0 4 1.79 4 4s-1.79 4-4 4H9.5C8.12 15 7 13.88 7 12.5S8.12 10 9.5 10H17v2H9.41c-.55 0-.55 1 0 1H18c1.1 0 2-.9 2-2s-.9-2-2-2H7.5C5.57 9 4 10.57 4 12.5S5.57 16 7.5 16H17v2H7.5C4.46 18 2 15.54 2 12.5z" />
        <path d="M0 0h24v24H0V0z" fill="none" />
      </svg>
      <div>
        {props.attach.name.length > 8
          ? `${props.attach.name.substr(0, 8)}..`
          : props.attach.name}
      </div>
    </div>
  );
}

 
export default ImageInput;
