/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Select from 'core/newComponents/Select';
import TextField from 'core/components/TextField';

import DialogAction from '../../common/DialogAction';

import getDict from 'app/selectors/getDict';
import mafTypeService from 'app/services/mafTypeService';

const propTypes = {
  msoType: PropTypes.shape({
    code: PropTypes.string,
    has_separate_garbage_collection: PropTypes.bool,
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  showCondition: PropTypes.bool,
  table: PropTypes.array,
  title: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

const defaultProps = {
  title: 'Добавление записи',
};

class DialogAddMafMsoRecord extends React.Component {
  state = {};

  componentDidUpdate(prevProps) {
    const { props } = this;
    const typeName = 'Ограждение';
    const concreteType = 'Из бетона';
    const brickType = 'Из кирпича';
    const containerCode = 'container_area';

    const fence = props.mafTypeLevel1.filter(
      (item) => item.name.indexOf(typeName) !== -1,
    );

    const { mafTypeLevel2 } = this.props;
    let material;

    if (mafTypeLevel2 && props.msoType) {
      material =
        props.msoType.code.indexOf(containerCode) !== -1
          ? mafTypeLevel2.filter((i) => i.name.indexOf(concreteType) !== -1)
          : mafTypeLevel2.filter((i) => i.name.indexOf(brickType) !== -1);
    } else {
      material = [];
    }

    if (prevProps.showCondition !== props.showCondition) {
      this.setState({
        maf_type_level1_id: fence[0].id || null,
        maf_type_level2_id: material[0].id || null,
        quantity: null,
      });
    }
  }

  handleCancel = () => {
    const { onCancel } = this.props;
    onCancel();
  };

  handleOk = () => {
    const { maf_type_level1_id, maf_type_level2_id, quantity } = this.state;
    const { onSubmit } = this.props;
    onSubmit({
      maf_type_level1_id: {
        maf_type_level1: maf_type_level1_id,
      },
      maf_type_level2_id: {
        maf_type_level2: maf_type_level2_id,
      },
      quantity,
    });
  };

  isDisabledContinueButton() {
    const { state } = this;
    let result;
    if (state.quantity) {
      result = false;
    } else {
      result = true;
    }
    return result;
  }

  renderMafTypeLevel1() {
    const { props } = this;
    const items = props.mafTypeLevel1;
    return this.renderSelectField({
      id: 'maf_type_level1_id',
      label: 'Вид МАФ',
      items,
    });
  }

  renderMafTypeLevel2() {
    const { mafTypeLevel2 } = this.props;
    const { maf_type_level1_id } = this.state;
    let items;

    if (mafTypeLevel2) {
      items = mafTypeService.filterMafTypeLevel2(
        mafTypeLevel2,
        maf_type_level1_id,
      );
    } else {
      items = [];
    }
    return this.renderSelectField({
      id: 'maf_type_level2_id',
      label: 'Уточнение',
      onChange: (value) => {
        this.setState({
          maf_type_level2_id: value,
        });
      },
      items,
    });
  }

  renderQuantity() {
    return this.renderTextField({
      id: 'quantity',
      label: 'Количество, п.м.',
    });
  }

  renderSelectField({ id, label, items }) {
    return (
      <Select
        disabled={true}
        id={id}
        label={label}
        options={items || []}
        value={this.state[id] || ''}
        width="276"
      />
    );
  }

  renderTextField({ id, label, ...otherProps }) {
    const onChange = (value) => {
      this.setState({
        [id]: value,
      });
    };

    return (
      <TextField
        id={id}
        label={label}
        value={String(this.state[id] || '')}
        onBlur={(event) => {
          const { value } = event.target;
          const number = value === '' ? '' : parseFloatWithComma(value);
          onChange(number);
        }}
        onChange={(event) => {
          const { value } = event.target;
          if (isNumber(value)) {
            onChange(value);
          }
        }}
        {...otherProps}
      />
    );
  }

  render() {
    const { showCondition, title } = this.props;

    return (
      <DialogAction
        className="Dialog-Add-Maf-Mso-Record"
        isDisabledOk={this.isDisabledContinueButton()}
        showCondition={showCondition}
        title={title}
        onCancel={this.handleCancel}
        onOk={this.handleOk}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          {this.renderMafTypeLevel1()}
          {this.renderMafTypeLevel2()}
        </div>
        {this.renderQuantity()}
      </DialogAction>
    );
  }
}

DialogAddMafMsoRecord.propTypes = propTypes;
DialogAddMafMsoRecord.defaultProps = defaultProps;

function parseFloatWithComma(value) {
  value = `${value}`.replace(',', '.');
  const commaIndex = value.indexOf('.');

  if (commaIndex !== -1 && !value[commaIndex + 1]) {
    value = value.slice(0, commaIndex);
  }

  return Number.parseFloat(value);
}

function isNumber(value) {
  return /^\d*[,.]{0,1}\d{0,1}$/.test(`${value}`);
}

const getMafTypeLevel1 = getDict('mafTypeLevel1');
const getMafTypeLevel2 = getDict('mafTypeLevel2');

const mapStateToProps = (state) => {
  return {
    mafTypeLevel1: getMafTypeLevel1(state),
    mafTypeLevel2: getMafTypeLevel2(state),
  };
};

 
export default connect(mapStateToProps)(DialogAddMafMsoRecord);
