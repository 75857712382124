import {
  CAR_CUSTOMERS_LIST_ID_NAME,
  CarCustomerListIdFF,
} from 'core/form/finalForm/fields';
import { objectInArrayParam } from 'core/utils/hooks';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';
import { withDefault } from 'use-query-params';

import { filterInputAutocomplete } from '../filterInputsClasses';

export { CAR_CUSTOMERS_LIST_ID_NAME };

/**
 * Заказчики техники
 * car_customer_list_id.
 *
 * @param {object} props - Properties.
 * @returns {*}
 */
export const CarCustomerListIdFilter = (props) => (
  <CarCustomerListIdFF {...props} className={filterInputAutocomplete} />
);

/**
 * ## ID составного объекта
 * Car_customer_list_id..
 *
 * @returns {object}
 */
export const carCustomerListIdParams = () => {
  addPreparation(CAR_CUSTOMERS_LIST_ID_NAME, (value) => {
    return value.map((item) => item.id).join(',');
  });
  return {
    [CAR_CUSTOMERS_LIST_ID_NAME]: withDefault(objectInArrayParam, [{}]),
  };
};
