import __get from 'lodash/get';
/* eslint-disable */
const items = [
  'place_descr',
  'owner_inn',
  'owner_kpp',
  'owner_ogrn',
  'owner_phone',
  'owner_link',
  'park_descr',
  'park_link',
  'park_phone',
  'oper_fullname',
  'oper_phone',
  'oper_link',
  'schedule',
  'schedule_days',
  'schedule_hours',
  'schedule_comm',
  'park_geo',
  'add_inform',
];

function getValues(card) {
  return items.reduce((result, key) => {
    result[key] = __get(card, `attribute.${key}`);
    return result;
  }, {});
}

 
export const additionalInformationReducer = (result, state, props) => {
  const values = getValues(props.card);
  return {
    ...result,
    initialValues: {
      ...result.initialValues,
      ...values,
    },
  };
};
