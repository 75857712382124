/* eslint-disable */
import React, { useMemo } from 'react';
import { SendReasonFF } from 'core/form/finalForm/fields/prepared/selects/multiSelect/SendReasonFF';
import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';

 
export const SendReasonFilter = () => {
  const { parentId, typeId } = useGetCurrentCard();
  const typeChange = parentId ? 'edit_ogh' : 'create_ogh';

  const filter = useMemo(() => {
    return (dict) => {
      return dict.reduce((arr, item) => {
        const { privilege_code, ogh_object_type_list } = item;
        if (privilege_code === typeChange && ogh_object_type_list) {
          const mapOgh = ogh_object_type_list.map(
            (item) => item.ogh_object_type_id.ogh_object_type,
          );
          return mapOgh.includes(typeId) ? [...arr, item] : arr;
        }
        return arr;
      }, []);
    };
  }, [typeChange, typeId]);

  return (
    <SendReasonFF
      filter={filter}
      name={'reason'}
      required={true}
      withAll={false}
    />
  );
};
