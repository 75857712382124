import { useUser } from 'app/pages/providers';
import { CancelButtonFF } from 'core/form/finalForm/components/buttons/CancelButton.FF';
import { EditButton } from 'core/uiKit/components/buttons/actionsButtons/Edit.Button';
import { SaveButton } from 'core/uiKit/components/buttons/actionsButtons/Save.Button';
import { ButtonGroup } from 'core/uiKit/components/buttons/ButtonGroup';
import React from 'react';

/**
 * Группа кнопок для карточки "Методические материалы".
 *
 * @param {{ isLoading: boolean }} props - Props.
 * @param {boolean} [props.isLoading] - Флаг Загрузки.
 * @returns Группа кнопок для карточки "Методические материалы".
 */
export const ButtonGroupKnowledgeBase = ({
  isLoading,
}: {
  isLoading?: boolean;
}) => {
  const { hasPermission } = useUser();

  if (!hasPermission('edit_knowledge')) {
    return null;
  }

  return (
    <ButtonGroup>
      <SaveButton isLoading={isLoading} />
      <CancelButtonFF isLoading={isLoading} />
      <EditButton isLoading={isLoading} />
    </ButtonGroup>
  );
};
