import { OkrugList } from 'core/uiKit/preparedInputs/selects/multiSelect/OkrugList';

 
/**
 * Компонент обертка.
 *
 * @param {{input: object, props: object}} props - Пропсы.
 * @returns {React.ReactElement} - Хопа.
 * @example
 * <Field
 *   {...otherProps}
 *   name={OKRUG_LIST_NAME}
 *   component={OkrugListWithReduxForm}
 * />
 */
export const OkrugListWithReduxForm = ({
  input,
  ...props
}) => {
  return <OkrugList {...input} {...props} />;
};
