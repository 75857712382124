import {
  CAR_EQUIPMENT_TYPE_ID_NAME,
  CarEquipmentTypeIdFF,
} from 'core/form/finalForm/fields';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';
import { NumberParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

/**
 * Тип навесного оборудования
 * equipment_type_id.
 *
 * @param {*} props - Properties.
 * @returns {JSX.Element}
 */
export const EquipmentTypeFilter = (props) => (
  <CarEquipmentTypeIdFF {...props} className={filterInput} />
);

export { CAR_EQUIPMENT_TYPE_ID_NAME };

/**
 * Создание параметров фильтрации.
 *
 * @returns {object}
 */
export const equipmentTypeParams = () => {
  addPreparation(CAR_EQUIPMENT_TYPE_ID_NAME, (value) => value, 'equipmentType');
  return {
    [CAR_EQUIPMENT_TYPE_ID_NAME]: NumberParam,
  };
};
