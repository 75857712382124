import { ActOfCompletedWorkElectronicSignature } from 'app/pages/cardsOgh/components/tables/Documents/FieldsRF/preparedFields/ActOfCompletedWork.ElectronicSignature';
import { useActOfCompletedElectronicSignatureFlag } from 'app/pages/cardsOgh/components/tables/Documents/FieldsRF/preparedFields/ActOfCompletedWork.ElectronicSignature/hooks/useActOfCompletedElectronicSignatureFlag';
import { ActOfCompletedWorkRF } from 'app/pages/cardsOgh/components/tables/Documents/FieldsRF/preparedFields/ActOfCompletedWorkRF';
import React from 'react';

/**
 * Обертка определяющая какую таблицу выводить для __Акты сдачи-приемки выполненных работ по изготовлению цифровой геоподосновы__.
 *
 * @returns {JSX.Element}
 */
export const ActOfCompletedWork = () => {
  const isActOfCompletedElectronicSignature =
    useActOfCompletedElectronicSignatureFlag();
  return isActOfCompletedElectronicSignature ? (
    <ActOfCompletedWorkElectronicSignature />
  ) : (
    <ActOfCompletedWorkRF />
  );
};
