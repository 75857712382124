import { post } from 'app/api/crud/post';
import { log } from 'core/utils/log';

 
/* eslint-disable */
/**
 * @category Actions
 * @param {number} id ID объекта
 * @param {string} date дата в формате строки 'DD.MM.YYYY'
 * @returns {function(): Promise<*>}
 */
/* eslint-disable */
export function checkObjectParent(id, date) {
  return () => {
    return post('/ogh/check_object_parent/', {
      id,
      date,
    }).catch((err) => {
      log.error(`'${err}' happened, but no big deal!`);
      return null;
    });
  };
}
