import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const DISTRICT_ID_NAME = 'district_id';

/**
 * Select Район.
 *
 * @param {*} props - Properties.
 * @returns {JSX.Element}
 */
export const DistrictId = (props) => {
  const dict = 'districts';
  const { data = [] } = useGetDictionary(dict);

  return (
    <Select
      name={DISTRICT_ID_NAME}
      {...{
        ...props,
        label: 'Район',
        options: data,
      }}
    />
  );
};
