/* eslint-disable */
import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { ID_RFID } from 'core/uiKit/preparedInputs/textField/IdRfid';
import { IdRfidWithReduxForm } from './IdRfidWithReduxForm';

 
/* eslint-disable */
/**
 * Комментарий
 *
 * * `name = ID RFID`
 * @param props
 * @returns <Field {...otherProps} component={IdRfidWithFinalForm} name={ID_RFID} />
 * @constructor
 */
/* eslint-disable */
export const IdRfidRF = ({ name, ...otherProps }) => {
  return (
    <Field {...otherProps} component={IdRfidWithReduxForm} name={ID_RFID} />
  );
};

IdRfidRF.propTypes = {
   
   
/**
   * быбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
/**
   * placeholder
   */
  placeholder: PropTypes.string,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
};
IdRfidRF.defaultProps = {
  disabled: false,
};
