import { get } from 'lodash';
/* eslint-disable  */
import { createSelector } from 'reselect';

/**
 *
 * @param state
 */
const getRecordId = (state) => get(state, 'card.root.record_id', -1);

/**
 *
 * @param state
 */
const getTreeJournal = (state) => get(state, 'tree.journal', null);

export default createSelector(
  [getRecordId, getTreeJournal],
  (recordId, journal) => journal && journal[recordId],
);
