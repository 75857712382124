import * as roleRegistryActions from 'app/actions/roleRegistry';
/* eslint-disable  */
import { handleActions } from 'redux-actions';

export default handleActions(
  {
    /**
     *
     * @param state
     */
    [`${roleRegistryActions.apiGetRoleRegistry}_PENDING`]: function (state) {
      return state;
    },

    /**
     *
     * @param state
     */
    [`${roleRegistryActions.apiGetRoleRegistry}_REJECTED`]: function (state) {
      state = {
        ...state,
        data: {},
      };
      return state;
    },

    /**
     *
     * @param state
     * @param action
     */
    [`${roleRegistryActions.apiGetRoleRegistry}_FULFILLED`]: function (
      state,
      action,
    ) {
      state = {
        ...state,
        data: action.payload.data,
      };
      return state;
    },
  },
  { data: {} },
);
