import { Cell } from 'core/uiKit/components/tables/Table';

/**
 * Колонка __Тип (Уточненный вид ЭВО)__.
 *
 * @type {{
 * accessor: 'vertical_landscaping_ref_type_name',
 * Header: 'Тип (Уточненный вид ЭВО)',
 * sortable: true,
 * }}
 * @augments Cell
 */
export const vertical_landscaping_ref_type_name: Cell = {
  Header: 'Тип',
  accessor: 'vertical_landscaping_ref_type_name',
  sortable: true,
};
