import { parseDate } from 'app/components/card/common/parse';
import getDict from 'app/selectors/getDict';
import toArray from 'app/utils/toArray';
import { get, has, isArray, map, rangeRight } from 'lodash';
const getCarMotorTypes = getDict('carMotorTypes');
const getCarEcologyClasses = getDict('carEcologyClasses');
const getLegalCarRegistration = getDict('legalCarRegistration');
const getCarSeasons = getDict('carSeasons');
const getCarConditions = getDict('carConditions');

const currentYear = new Date().getFullYear();
const carCreateYears = rangeRight(currentYear - 100, currentYear + 1).map(
  (year) => ({
    id: year,
    name: year.toString(),
  }),
);


/* eslint-disable */
export default function (result, state, props) {
  const { card } = props;
  const { oldValues } = result;
  const carConditions = toArray(getCarConditions(state));
  const carConditionId = getCarConditionId({
    card,
    carConditions,
  });

  let car_base_id = card && card.car_base_id;
  if (has(car_base_id, 'value') && has(car_base_id, 'label')) {
    car_base_id = {
      id: car_base_id.value,
      name: car_base_id.label,
    };
  }

  let car_customer_list = card && card.car_customer_list;
  if (
    isArray(car_customer_list) &&
    has(car_customer_list[0], 'value') &&
    has(car_customer_list[0], 'label')
  ) {
    car_customer_list = map(car_customer_list, ({ value, label }) => ({
      id: value,
      name: label,
    }));
  }

  return {
    ...result,

    initialValues: {
      ...result.initialValues,
      car_base_id,
      car_customer_list,
      car_season_id: card.car_season_id,
      not_need_glonass: card.not_need_glonass,
      car_condition_id: carConditionId,
      max_speed: card.max_speed,
      insurance_end_date: parseDate(card.insurance_end_date),
      insured: card.insured,
    },

    oldValues: {
      ...oldValues,
      car_base_id: card.old_car_base_id,
      car_season_id: get(oldValues, 'car_season_id.car_season'),
      car_customer_list: card.old_car_customer_list,
      car_condition_id: get(oldValues, 'car_condition_id.car_condition'),
    },
    carConditions,

    legalCarRegistration: getLegalCarRegistration(state),
    carMotorTypes: getCarMotorTypes(state),
    carEcologyClasses: getCarEcologyClasses(state),
    carSeasons: getCarSeasons(state),
    carCreateYears,
    isLinkedWithGlonass: isLinkedWithGlonass(state),
  };
}

function getCarConditionId({
  card: { car_condition_id, attribute },
  carConditions,
}) {
  let result;
  if (car_condition_id) {
    result = car_condition_id;
  } else {
    const code = get(attribute, 'car_condition_code');
    const item = carConditions.find((item) => item.code === code);
    result = item ? item.id : null;
  }
  return result;
}

function isLinkedWithGlonass(state) {
  const techTreePaths = get(state, 'technologyObject.techTreePaths') || [];
  return techTreePaths.some(
    (item) =>
      item !== '0' && parseInt(item, 10) !== get(state, 'card.root.root_id'),
  );
}
