import { ODS_API_URL } from 'config/env';
import PropTypes from 'prop-types';
/* eslint-disable */
const propTypes = {
  fileName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

function DownloadDocumentation({ fileName, title }) {
  return (
    <a
      className="helper-caption"
      href={`${ODS_API_URL}/files/user_guide/${fileName}`}
      title={title}
    >
      {title}
    </a>
  );
}

DownloadDocumentation.propTypes = propTypes;

 
export default DownloadDocumentation;
