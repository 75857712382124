/* eslint-disable */
import React from 'react';
import { StringParam } from 'use-query-params';

import { TextFieldFF } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
export const USER_CREATE = 'userCreate';

 
/* eslint-disable */
/**
 * ID   UserCreate
 * @param label
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
 
export const UserCreateFilter = ({ label = 'Пользователь', ...props }) => {
  return (
    <TextFieldFF
      {...props}
      className={filterInput}
      label={label}
      name={USER_CREATE}
    />
  );
};

 
export const userCreateParams = {
  [USER_CREATE]: StringParam,
};
