import { KeyboardDatePickerRF } from 'core/form/reduxForm/fields/default/datePickers/KeyboardDatePickerRF';
import React from 'react';

export const DATE_SURVEY_NAME = 'date_survey';

/**
 * Дата обследования территории.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 * @class
 */
export const DateSurveyRf = ({ ...otherProps }) => {
  return (
    <KeyboardDatePickerRF
      label={'Дата обследования территории'}
      name={DATE_SURVEY_NAME}
      {...otherProps}
    />
  );
};
