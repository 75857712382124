import {
  DTS,
  FUNCTIONALITY,
  PLANAR_STRUCTURE,
  PPI,
  RELIEF,
  STORAGE_PLACE,
  TECH_PLACE,
  WATER_BUILDING,
} from 'app/constants/oghTypes';
import {
  AddressFilter,
  addressParams,
} from 'core/form/filterForm/components/autocompleteAsync/AddressFilter';
import { SubTypeIdFilter } from 'core/form/filterForm/components/selects/SubTypeId.Filter';
import { IdFilter } from 'core/form/filterForm/components/text/ID.Filter';
import React, { useEffect } from 'react';

/**
 * Дефолтный фильтр.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - Тип ОГХ.
 * @returns {JSX.Element}
 */
export const DefaultFilter = ({ typeId }) => {
  useEffect(() => {
    addressParams();
  }, []);

  return (
    <>
      <IdFilter />
      {[
        STORAGE_PLACE,
        WATER_BUILDING,
        DTS,
        PLANAR_STRUCTURE,
        FUNCTIONALITY,
        PPI,
        RELIEF,
      ].includes(typeId) ? (
        <SubTypeIdFilter typeId={typeId} />
      ) : null}
      {[TECH_PLACE, STORAGE_PLACE].includes(typeId) ? <AddressFilter /> : null}
    </>
  );
};
