import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { OghCardHeaderFF } from 'app/pages/cardsOgh/components/headerFF/OghHeaderFF';
import { Versions } from 'app/pages/cardsOgh/components/tables/Versions';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { DatePickerFF } from 'core/form/finalForm/fields/default/datePickers/DatePickerFF';
import { TextFieldFF } from 'core/form/finalForm/fields/default/TextFieldFF';
import { IsDiffHeightMarkFf } from 'core/form/finalForm/fields/prepared/checkboxes/IsDiffHeightMark.FF';
import { IsFilledFf } from 'core/form/finalForm/fields/prepared/checkboxes/IsFilled.FF';
import { NoCalcFF } from 'core/form/finalForm/fields/prepared/checkboxes/NoCalc.FF';
import { PlacesAreaFF } from 'core/form/finalForm/fields/prepared/numberField/PlacesArea.FF';
import { PlacesQuantityFF } from 'core/form/finalForm/fields/prepared/numberField/PlacesQuantity.FF';
import { RelatedElementsFlowersGardenIdFF } from 'core/form/finalForm/fields/prepared/numberField/RelatedElementsFlowersGardenId.FF';
import { RelatedElementsTreesShrubsIdFF } from 'core/form/finalForm/fields/prepared/numberField/RelatedElementsTreesShrubsId.FF';
import { ErzStateIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/ErzStateId.FF';
import { MaterialIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/MaterialId.FF/MaterialId.FF';
import { OtherDocumentsFf } from 'core/form/finalForm/fields/prepared/tables/documentTables/OtherDocuments.FF';
import { CardContainer } from 'core/uiKit/components/card.components/CardContainer';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { TabsCardOGH } from 'core/uiKit/components/card.components/TabsCardOGH';
import { TabsCardOGHItem } from 'core/uiKit/components/card.components/TabsCardOGH/TabsCardOGH.Item';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import React from 'react';

import { GuranteePeriodDate } from './components/GuranteePeriodDate';
import { InstallationDate } from './components/InstallationNameDate';
import { LifeTimeDate } from './components/LifeTimeDate';
import { QuantityField } from './components/QuantityField';
import { UnitIdField } from './components/UnitIdField';
import { VerticalLandscapingClassTypeIdField } from './components/VerticalLandscapingClassTypeIdField';
import { VerticalLandscapingRefTypeIdField } from './components/VerticalLandscapingRefTypeIdField';
import { VerticalLandscapingTypeIdField } from './components/VerticalLandscapingTypeIdField';

/**
 * LeftPanel Инженерные сооружения.
 *
 * @returns {JSX.Element}
 */
export const LeftPanelVerticalLandscaping = () => {
  const { rootId = 0 } = useGetCurrentCard();
  const { editMode } = useMode();
  const isCreatedPassport = Boolean(rootId);

  return (
    <CardContainer>
      <OghCardHeaderFF />
      <TabsCardOGH>
        <TabsCardOGHItem tabTitle={'Свойства'}>
          <FieldsSplitColumns>
            <VerticalLandscapingTypeIdField />
            <VerticalLandscapingClassTypeIdField />
            <VerticalLandscapingRefTypeIdField />
            <PlacesQuantityFF disabled={!editMode} required={true} />
            <ParentInfo />
            <PlacesAreaFF disabled={!editMode} required={true} />
            <RelatedElementsFlowersGardenIdFF disabled={true} />
            <RelatedElementsTreesShrubsIdFF disabled={true} />
            <NoCalcFF disabled={!editMode} />
            <IsDiffHeightMarkFf disabled={true} />
          </FieldsSplitColumns>
          <TabsCardOGH>
            <TabsCardOGHItem tabTitle={'Исходные данные'}>
              <FieldsSplitColumns>
                <UnitIdField />
                <QuantityField />
                <MaterialIdFF disabled={!editMode} required={true} />
                <TextFieldFF
                  name={'zone_name'}
                  label={'Принадлежность элемента к зоне территории'}
                  disabled={true}
                />
                <TextFieldFF
                  name={'id_rfid'}
                  label={'ID RFID метки'}
                  disabled={!editMode}
                />
                <InstallationDate />
                <LifeTimeDate />
                <GuranteePeriodDate />
                <TextFieldFF
                  name={'eais_id'}
                  label={'ИД УАИС БУ'}
                  disabled={true}
                />
                <IsFilledFf disabled={!editMode} />
                <ErzStateIdFF disabled={true} />
                <DatePickerFF
                  name={'erz_date'}
                  label={'Дата состояния (из ЕРЗ)'}
                  disabled={true}
                />
              </FieldsSplitColumns>
            </TabsCardOGHItem>
            <TabsCardOGHItem tabTitle={'Документы'}>
              <OtherDocumentsFf />
            </TabsCardOGHItem>
          </TabsCardOGH>
        </TabsCardOGHItem>
        <TabsCardOGHItem tabTitle={'Версии'} hide={!isCreatedPassport}>
          <Versions />
        </TabsCardOGHItem>
      </TabsCardOGH>
    </CardContainer>
  );
};
