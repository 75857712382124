import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { ODH, OZN, YARD } from 'app/constants/oghTypes';
import { LandscapingMark } from 'core/uiKit/preparedInputs/checkboxes/LandscapingMark';
import React from 'react';

/**
 * CheckBox Объект включен в план благоустройства.
 *
 * @returns {JSX.Element}
 */
export const CheckboxLandscapingMark = () => {
  const { landscaping, typeId } = useGetCurrentCard();

  const value = (landscaping || []).some((item) => item?.landscaping_mark);

  return [OZN, ODH, YARD].includes(typeId) ? (
    <LandscapingMark disabled={true} value={value} />
  ) : null;
};
