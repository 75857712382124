/* eslint-disable */
import React, { useState } from 'react';
import './DropDown.scss';
import cn from 'classnames';

 
export const DropDown = ({ children, title, location }) => {
  const [open, setOpen] = useState(false);

  const openHandler = () => {
    setOpen(true);
  };

  const closeHandler = () => {
    setOpen(false);
  };

  return (
    <div
      className={'drop_down_container'}
      onMouseEnter={openHandler}
      onMouseLeave={closeHandler}
    >
      <div className={'drop_down_title'}>{title}</div>
      {open ? (
        <ul
          className={cn('drop_down_container', {
            location_left: location === 'right',
          })}
        >
          {children}
        </ul>
      ) : null}
    </div>
  );
};
