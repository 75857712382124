import { Card } from 'core/uiKit/components/Card';

/**
 * Тело таба.
 *
 * @param {props} props - Properties.
 * @param {number} props.id - Id of the card.
 * @param {*} props.children - Children of the.
 * @param {boolean} props.show - Show the card.
 * @returns {JSX.Element|null}
 */
export const TabBody = ({ id, children, show }) => {
  return show ? (
    <Card hidden={!show} id={id} className={'p-3 mb-3'}>
      {children}
    </Card>
  ) : null;
};
