import {
  Body,
  DialogCreateSimple,
  Header,
  Main,
  RegistryToolbar,
} from 'app/pages/components';
import { PrintButtonPpi } from 'app/pages/registry/ogh/yard/Ppi/PrintButton.Ppi';
import { TreePanelYard } from 'app/pages/registry/ogh/yard/TreePanelYard';
import { useGetOghTypes } from 'core/utils/hooks';
import React from 'react';

import { FilterPpi } from './Filter.Ppi';
import { TablePpi } from './Table.Ppi';

/**
 * Реестр __Элементы благоустройства для маломобильных групп населения__.
 *
 * @param {object} props - Пропсы.
 * @param {Array} props.tree - Дерево.
 * @param {number} props.parentTypeId - TypeId родителя.
 * @returns {JSX.Element}
 */
const Ppi = ({ tree, parentTypeId }) => {
  const oghType = useGetOghTypes('ppi');
  const typeId = oghType.id;

  return (
    <Main>
      <Header
        Filter={<FilterPpi typeId={typeId} parentTypeId={parentTypeId} />}
      />
      <Body>
        <RegistryToolbar showToggle={true} title={oghType.name}>
          <PrintButtonPpi typeId={typeId} parentTypeId={parentTypeId} />
        </RegistryToolbar>

        <div className={'d-flex h-100 overflow-hidden-x'}>
          <TreePanelYard tree={tree} />
          <TablePpi typeId={typeId} parentTypeId={parentTypeId} />
        </div>
        <DialogCreateSimple typeId={typeId} />
      </Body>
    </Main>
  );
};

export default Ppi;
