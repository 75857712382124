/* eslint-disable */
/**
 * Функция подготовки данных дерева для отправки на сервер.
 *
 * @param {object} actions - Экшены.
 * @returns {{tree_actions: unknown, tree_count_actions: number}} - Возвращает подготовленные данные.
 */
export const prepareActionsTree = (actions) => {
  return {
    tree_count_actions: Object.keys(actions).length,
    tree_actions: {
      ...Object.values(actions).reduce(
        (obj, { items, pathObjectId, typeId }, i) => {
          return {
            ...obj,
            [i + 1]: {
              object_list: items.reduce(
                (obj, { noCalc, id }) => ({
                  ...obj,
                  [id]: { noCalc: noCalc ? noCalc : 0 },
                }),
                {},
              ),
              path_object_id: pathObjectId,
              path_object_type: typeId,
            },
          };
        },
        {},
      ),
    },
  };
};
