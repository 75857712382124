/* eslint-disable  */

import { getCurrentDate } from 'app/utils/date/getCurrentDate';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import { useSend } from '../api';
import { DialogShipment } from './Dialog.Shipment';

vi.mock('../api', () => ({
  useSend: vi.fn(() => ({})),
}));
vi.mock('app/utils/date/getCurrentDate');

describe('spec Dialog.Shipment', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useSend.mockReturnValue(() => ({}));
    // 🧹 clear mock

    // 🔥 Act
    const wrapper = shallow(<DialogShipment />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<ContinueFFDialog />');
  });

  it('props', () => {
    expect.hasAssertions();
    // 🧹 clear mock
    useSend.mockReset();

    // ☣️ Arrange (всякие моки)
    useSend.mockReturnValue({ isLoading: 43, onSubmit: 11 });
    getCurrentDate.mockReturnValue(421);

    // 🔥 Act
    const wrapper = shallow(
      <DialogShipment
        initialValues={{
          a: 234,
          consigneeId: 'consigneeId',
          consigneeName: 'consigneeName',
          govNumber: 'govNumber',
          govNumberId: 'govNumberId',
        }}
        isOpen={33}
        onClose={8}
      />,
    );

    // ❓ Assert
    expect(wrapper.prop('isOpen')).toBe(33);
    expect(wrapper.prop('textHeader')).toBe('Отпуск ПГР');
    expect(wrapper.prop('onSubmit')).toBe(11);
    expect(wrapper.prop('validate')).toStrictEqual(expect.any(Function));
    expect(wrapper.prop('closeDialog')).toBe(8);
    expect(wrapper.prop('isLoading')).toBe(43);
    expect(wrapper.prop('initialValues')).toStrictEqual({
      a: 234,
      carCustomer: null,
      consignee: { id: 'consigneeId', name: 'consigneeName' },
      consigneeId: 'consigneeId',
      consigneeName: 'consigneeName',
      govNumber: { id: 'govNumberId', name: 'govNumber' },
      govNumberId: 'govNumberId',
      targetDateTime: 421,
    });

    expect(useSend).toBeCalledWith('shipment', 8);
  });

  it('validate', () => {
    expect.hasAssertions();
    // 🧹 clear mock

    // ☣️ Arrange (всякие моки)

    useSend.mockReturnValue({ isLoading: 43, onSubmit: 11 });
    getCurrentDate.mockReturnValue(421);

    const wrapper = shallow(
      <DialogShipment initialValues={{ a: 234 }} isOpen={33} onClose={8} />,
    );
    // 🔥 Act
    const res = wrapper.prop('validate')({ a: 43 });

    // ❓ Assert
    expect(res).toStrictEqual({
      documentNum: 'обязательное поле',
      quantity: 'обязательное поле',
      reagent: 'обязательное поле',
      reagentKind: 'обязательное поле',
    });
  });
});
