 
/**
 * Функция для получения флага селекта.
 *
 * @param {number} value - Значение.
 * @param {Array} result - Отфильтрованный массив.
 * @param {string} optionKey - Ключ.
 * @returns {boolean} - Возвращает флаг.
 * @example
 * flag = getFlagSelect(result, optionKey, value);
 */
export const getFlagSelect = (value, result, optionKey) => {
  return result.some(
    (itemResult) => itemResult[optionKey] === value,
  );
};
