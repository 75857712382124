import { validateRequiredFieldsAsync } from 'app/components/card/common/validation';
import { createAttribute as createDocumentsAttribute } from 'app/components/card/ogh/DocumentsTab';
import { REQUIRED_ADDRESS_BTI } from 'app/constants/messages';
import { CONTAINER } from 'app/constants/oghTypes';
import { transformValues } from 'app/utils/form/transformation';
import { get, mapValues, pick } from 'lodash';

import submitCard from '../submit';

/**
 * Функция создания атрибутов.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {object} - Возвращают атрибуты.
 * @example ----
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

const dictFields = [
  'container_type_id',
  'location_type_id',
  'coating_type_id',
  'coating_group_id',
  'abutment_type_id',
  'maf_type_id',
];

const ignoreField = [
  're_calc_trees_shrubs_list',
  're_calc_name',
  're_calc_address_list',
  're_calc_flowers_garden_list',
  're_calc_lawn_list',
];

const mandatoryFields = {
  [CONTAINER]: [
    'container_type_id',
    'coating_type_id',
    'coating_group_id',
    'area',
    'address_list',
  ],
};

/**
 * Функция сабмита.
 *
 * @param {object} props - Значения из формы.
 * @param {Array} numericFields - Цифровые поля.
 * @returns {*} - Жопа.
 * @example ----
 */
export const onSubmit = (props, numericFields) => (formValues) => {
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };

  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[props.card.type_id],
    () => {
      const errors = {};
      if (formValuesWithAttribute?.address_list?.length < 1) {
        errors.address_list = REQUIRED_ADDRESS_BTI;
      }
      return errors;
    },
  );

  let valuesWithoutFields = {};
  Object.entries(formValuesWithAttribute).forEach(([key, formValue]) => {
    if (formValue !== null && ignoreField.indexOf(key) === -1) {
      valuesWithoutFields[key] = formValue;
    }
  });
  if (get(formValuesWithAttribute, 'maf_type_list.table[0]')) {
    formValuesWithAttribute.maf_type_list =
      formValuesWithAttribute.maf_type_list.table;
  } else {
    delete formValuesWithAttribute.maf_type_list;
  }

  if (get(formValuesWithAttribute, 'abutment_type_list.table[0]')) {
    formValuesWithAttribute.abutment_type_list =
      formValuesWithAttribute.abutment_type_list.table;
  } else {
    delete formValuesWithAttribute.abutment_type_list;
  }

  const transformed = transformValues(formValuesWithAttribute, {
    custom: {
      address_list: {
        //

        /**
         * Метод transformer.
         *
         * @param {*} value - Значение.
         * @returns {*}
         */
        transformer: (value) => {
          return value
            ? value.map((item) => {
                return mapValues(
                  pick(item, [
                    'okrug_id',
                    'address_id',
                    'street_id',
                    'district_id',
                    'description',
                  ]),
                  (id) => {
                    return { bti_object: id };
                  },
                );
              })
            : null;
        },
      },
    },
    dictFields,
    numericFields,
  });

  submitCard(props, transformed);
};
