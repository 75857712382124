import { DialogEditMovementCharacteristics } from 'app/components/card/ogh/Odh/components/tabs/MovementCharacteristics/DialogEdit.MovementCharacteristics/DialogEdit.MovementCharacteristics';
import { useEditorCardSelector } from 'app/selectors/useSelectors/useEditorCard.Selector';
import { RANDOM_ID, RANDOM_WORD } from 'core/forTesting/constants';
import { shallow } from 'enzyme';

import { validate } from './validate';
jest.mock('app/selectors/useSelectors/useEditorCard.Selector');
jest.mock('./validate');

/* eslint-disable */
describe('file DialogEdit.MovementCharacteristics', () => {
  it('simple test', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useEditorCardSelector.mockReturnValue({});
    // Act
    const wrapper = shallow(
      <DialogEditMovementCharacteristics onClick={(_) => _} value={{}} />,
    );
    // Assert
    expect(wrapper.text()).toBe('<SaveFFDialog />');
  });

  it('props SaveFFDialog', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useEditorCardSelector.mockReturnValue({
      layout_length: RANDOM_ID + 14,
    });
    const onSaveSpy = jest.fn();
    const closeDialogSpy = jest.fn();
    validate.mockImplementation((a) => a);
    // Act
    const wrapper = shallow(
      <DialogEditMovementCharacteristics
        onClick={onSaveSpy}
        value={{ a: RANDOM_ID + 2, b: RANDOM_WORD }}
        isOpen={true}
        closeDialog={closeDialogSpy}
        textHeader={RANDOM_WORD}
      />,
    );

    // Assert
    expect(wrapper.props()).toMatchObject({
      initialValues: { a: RANDOM_ID + 2, b: RANDOM_WORD },
      maxWidth: 'lg',
      validate: { layoutLength: RANDOM_ID + 14 },
      onSubmit: onSaveSpy,
      textHeader: RANDOM_WORD,
      closeDialog: closeDialogSpy,
      Body: expect.anything(),
    });
  });
});
