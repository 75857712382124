import './CardContainer.scss';

import cn from 'classnames';
import React, { useState } from 'react';
import { FC, ReactNode } from 'react';

/**
 * Контейнер инпутов для карточни Огх.
 *
 * @param props - Properties.
 * @param props.children - Дочерние элементы.
 * @param [props.title] - Заголовок блока инпутов.
 * @param [props.isAccordion] - Является ли аккордионом.
 * @param [props.initShow] - Показывать ли сразу или скрывать.
 * @returns {CardContainer}
 */
export const CardContainer: FC<{
  children: ReactNode;
  title?: string;
  isAccordion?: boolean;
  initShow?: boolean;
}> = ({ children, title, isAccordion, initShow = true }) => {
  const [show, setShow] = useState<boolean>(initShow);

  /**
   * Fn.
   *
   * @returns {void}
   */
  const toggleShow = () => {
    if (isAccordion) {
      setShow(!show);
    }
  };
  return (
    <div className={'card-container'}>
      {title ? (
        <h2 onClick={toggleShow}>
          {title}
          {isAccordion ? (
            <svg
              className={cn('card-container__icon', {
                'card-container__icon--active': show,
              })}
              focusable="false"
              aria-hidden="true"
              viewBox="0 0 24 24"
              data-testid="ExpandMoreIcon"
            >
              <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
            </svg>
          ) : null}
        </h2>
      ) : null}
      <div className="card-container__children">{show ? children : ''}</div>
    </div>
  );
};
