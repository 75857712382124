import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { toDate } from 'app/utils/date/toDate';
import { useFieldRF } from 'core/form/reduxForm/hooks/useField.RF';
import { preparationValues } from 'core/utils/helpers/preparationValues';
import { usePreparation } from 'core/utils/hooks/usePreparation';
import { Moment } from 'moment';
import { useState } from 'react';

import { REPAIRS_INFO_LIST_PLAN_NAME } from './RepairsPlan.Tab';

/**
 * Интерфейс для значений формы.
 */
interface ValuesRepairsPlan {
  date: string;
  files: string;
  name: string;
  plan_time: string;
  property: string;
}

/**
 * Интерфейс для значений.
 */
interface RepairsPlan {
  date: Moment;
  files: string;
  name: string;
  plan_time: Moment;
  property: string;
}

/**
 * Setup Кнопка добавления __Проекта__.
 *
 * @returns Setup.
 */
export const useSetupAddButtonRepairsPlanTab = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { editMode } = useMode();
  const { onChange, value: repairsInfoListPlan } = useFieldRF<
    ValuesRepairsPlan[]
  >(REPAIRS_INFO_LIST_PLAN_NAME);

  usePreparation('date', toDate);
  usePreparation('plan_time', toDate);

  /**
   * Обработчик сабмита формы.
   *
   * @param value - Значения формы.
   */
  const onSubmit = (value: RepairsPlan) => {
    onChange([
      ...(repairsInfoListPlan || []),
      preparationValues<RepairsPlan, ValuesRepairsPlan>(value),
    ]);
    setIsOpen(false);
  };

  return { editMode, isOpen, onSubmit, setIsOpen };
};
