import './dialog.ApproveElectronicSignature.scss';

import { useFetchIntersectionsGeometry } from 'app/api/hooks/geometry/useFetchIntersectionsGeometry';
import GeometryCSVLink from 'app/utils/card/checkGeometry/GeometryCSVLink';
import { CancelButton } from 'core/uiKit/components';
import { Button } from 'core/uiKit/components/buttons/Button';
import { Dialog } from 'core/uiKit/components/dialogs/Dialog';
import { LoaderCreateFiles } from 'core/uiKit/components/loaders/LoaderCreateFiles';
import { renderA } from 'core/utils/renders/renderA';
import React from 'react';

/**
 * Диалоговое окно проверки на пересечения.
 *
 * @param {object} props - Пропсы.
 * @param {Function} props.onCloseDialogIntersections - Функция закрытия диалогового окна.
 * @param {Function} props.onContinueDialogIntersections - Функция onContinueDialogIntersections.
 * @param {boolean} props.isOpen - Флаг.
 * @returns {JSX.Element}
 */
export const DialogIntersectionsApproveElectronicSignature = ({
  onCloseDialogIntersections,
  onContinueDialogIntersections,
  isOpen,
}) => {
  const {
    data = {},
    messages = [],
    isLoading,
    objGeoData = [],
  } = useFetchIntersectionsGeometry();

  if (data?.intersected === false) {
    onContinueDialogIntersections();
  }

  const textIntersectionHeader = data?.isallow
    ? 'Обнаружены пересечения геометрий. Продолжить?'
    : 'Ошибка пересечения геометрий. Скорректируйте границы ОГХ.';
  return (
    <Dialog
      isOpen={isOpen}
      closeDialog={onCloseDialogIntersections}
      textHeader={
        isLoading ? 'Проверка на пересечение геометрии' : textIntersectionHeader
      }
      Body={
        isLoading ? (
          <LoaderCreateFiles />
        ) : (
          <div>
            <GeometryCSVLink
              fileName={'IntersectionsGeometry.csv'}
              objects={objGeoData}
            />

            <ul>
              {messages?.length > 0 // todo покрыть тестами
                ? messages.map((message) => (
                    <li key={message?.id}>{renderA(message?.text)}</li>
                  ))
                : null}
            </ul>
          </div>
        )
      }
      ButtonsActions={
        <>
          <CancelButton onClick={onCloseDialogIntersections} />
          {data?.isallow ? (
            <Button
              color={'info'}
              loading={isLoading}
              variant={'contained'}
              onClick={onContinueDialogIntersections}
            >
              Продолжить
            </Button>
          ) : null}
        </>
      }
    />
  );
};
