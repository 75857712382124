import { FaceArea } from 'core/uiKit/preparedInputs/numberField/FaceArea';
import React from 'react';

/**
 * Обертка final Form для поля __Площадь покрытия (облицовка), кв м__.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.input - Значения поля предоставляет FF.
 * @param {object} props.otherProps - Остальные пропсы.
 * @returns {JSX.Element}
 */
export const FaceAreaWithFF = ({ input, ...otherProps }) => {
  return <FaceArea {...input} {...otherProps} />;
};
