/* eslint-disable  */

export default class PaginationData {
  constructor(pagination = {}) {
    this.page = pagination.page ? parseInt(pagination.page, 10) : 0;

    const { max_rows, maxRows } = pagination;

    const mixMaxRows = max_rows || maxRows || 50;

    this.limit = parseInt(mixMaxRows, 10);

    this.total = parseInt(pagination.total, 10);

    this.from = this.page * this.limit + 1;
    this.to = calcTo(this.page, this.limit, this.total);
    this.lastPage =
      Math.floor(this.total / this.limit) +
      (this.total % this.limit === 0 ? 0 : 1) -
      1;

    this.getRange = this.getRange.bind(this);
    this.hasPrevious = this.hasPrevious.bind(this);
  }

  getRange(count) {
    let minFrom = 0;
    let maxTo = this.lastPage;

    let from = this.page - (count - 1) / 2;
    let to = this.page + (count - 1) / 2;

    if (from < minFrom) {
      to += minFrom - from;
    }
    if (to > maxTo) {
      from += maxTo - to;
    }

    from = from < minFrom ? minFrom : from;
    to = to > maxTo ? maxTo : to;

    let range = [];
    for (let i = from; i <= to; i++) {
      range.push(i);
    }
    return range;
  }

  hasPrevious() {
    return this.from !== 1;
  }

  hasNext() {
    return this.to < this.total;
  }
}

/* eslint-disable */
export function calcTo(page, limit, total) {
  return total < limit
    ? total
    : page * limit + (total < (page + 1) * limit ? total % limit : limit);
}
