import { tomorrowDate, tomorrowDateString } from 'app/utils/dateUtils';
import { getFileExtension } from 'core/form/finalForm/utils/common';
import { countBy, get, isEmpty, set, sumBy } from 'lodash';
import moment from 'moment';

import { int2str } from './common';
/* eslint-disable */
/**
 * @deprecated
 * @see  переезжаем в src/core/form/Validator
 * @param values
 * @return {{setMaskError: setMaskError, returnError: (function(): {}|null), setRequired: setRequired, hasErrors: (function(): boolean), setEqualRequired: setEqualRequired, setMinLengthError: setMinLengthError, getErrors: (function(): {}), setStartDateError: setStartDateError, setMaxLengthError: setMaxLengthError, setError: setError, setFileError: setFileError, setNotZero: setNotZero, setInvalidFormatDate: setInvalidFormatDate}}
 */
export const getValidator = (values) => {
  const errors = {};

  const setError = (field, nextError) => {
    const error = get(errors, field);
    if (!error) {
      if (nextError) {
        set(errors, field, nextError);
      }
    }
  };

  const setRequired = (field, message = undefined) => {
    const value = get(values, field);
    setError(field, required(value, message));
  };

  const setEqualRequired = (
    field_one,
    field_two,
    message = 'Несоответствие двух полей',
  ) => {
    const value_one = get(values, field_one);
    const value_two = get(values, field_two);

    if (value_one !== value_two) {
      setError(field_one, message);
      setError(field_two, message);
    }
  };

  const setInvalidFormatDate = (
    field,
    message = 'Недопустимый формат даты',
  ) => {
    if (!moment(values[field]).isValid()) {
      setError(field, message);
    }
  };

  const setStartDateError = (
    field = 'startDate',
    message = `Дата начала действия не может быть раньше ${tomorrowDateString}. Укажите корректное значение`,
  ) => {
    const currentDate = moment(values[field]).format('DD.MM.YYYY');
    if (moment(currentDate).toDate() < moment(tomorrowDate).toDate()) {
      setError(field, message);
    }
  };

  const setNotZero = (field) => {
    const value = get(values, field);
    if (parseFloat(value) === 0) {
      setError(field, 'Поле не может быть равно 0');
    }
  };

  const setMaxLengthError = (field, maxLength) => {
    const value = get(values, field);
    if (value && value.length > maxLength) {
      setError(field, `Максимальная длина поля - ${maxLength}`);
    }
  };

  const setMinLengthError = (field, minLength) => {
    const value = get(values, field);
    if (value && value.length < minLength) {
      setError(field, `Минимальная длина поля - ${minLength}`);
    }
  };

  const setFileError = (field, options = {}) => {
    const { maxMb = 20, maxKb = null, maxFiles = 2, byExtensions } = options;
    const files = get(values, field);

    if (!files) {
      return;
    }

    if (files.length > maxFiles) {
      setError(
        field,
        `Максимальное количество файлов не должно превышать ${maxFiles}`,
      );
      return;
    }

    const extensions = countBy(files, (f) => getFileExtension(f.name));
    const totalSize = sumBy(files, 'size');

    if (maxMb && totalSize > maxMb * 1024 * 1024) {
      setError(
        field,
        `Совокупный размер прикрепленных файлов не должен превышать ${maxMb} Мб`,
      );
      return;
    }
    if (maxKb && totalSize > maxKb * 1024) {
      setError(
        field,
        `Совокупный размер прикрепленных файлов не должен превышать ${maxKb} Kб`,
      );
      return;
    }

    if (typeof byExtensions === 'function') {
      const setFieldError = (msg) => setError(field, msg);
      byExtensions({ setFieldError, extensions, files });
    }
  };

  const setMaskError = (field, maskArr, message = 'не подходит') => {
    const val = get(values, field);
    if (!maskArr.every((item) => item.test(val))) {
      setError(field, message);
    }
  };

  const returnError = () => (hasError(errors) ? errors : null);

  return {
    hasErrors: () => hasError(errors),
    getErrors: () => errors,
    returnError,
    setEqualRequired,
    setError,
    setFileError,
    setMaxLengthError,
    setNotZero,
    setRequired,
    setMaskError,
    setMinLengthError,
    setStartDateError,
    setInvalidFormatDate,
  };
};

/**
 * @deprecated
 * @see  переезжаем в src/core/form/Validator
 * @param meta
 * @return {undefined|*}
 */
export const getFieldError = (meta) => {
  const { error, submitError, dirtySinceLastSubmit, submitting } = meta || {};

  if (error) return error;
  if (submitError && !dirtySinceLastSubmit && !submitting) return submitError;
  return undefined;
};

/**
 * @deprecated
 * @see  переезжаем в src/core/form/Validator
 * @param _v
 * @param message
 * @return {undefined|string}
 */
export const required = (_v, message = 'обязательное поле') => {
  let v = typeof _v === 'number' ? int2str(_v) : _v;
  v = typeof v === 'string' ? v.trim() : v;
  if (Array.isArray(_v)) {
    v = !!_v.length;
  }
  return v ? undefined : message;
};

/**
 * @deprecated
 * @see  переезжаем в src/core/form/Validator
 * @param errors
 * @return {boolean}
 */
export const hasError = (errors) => {
  if (!errors) return false;
  if (isEmpty(errors)) return false;

  let anyError = false;

  JSON.stringify(errors, (k, v) => {
    if (typeof v === 'string' || typeof v === 'number') {
      anyError = true;
    }
    return v;
  });
  return anyError;
};
