/* eslint-disable */

import { validateAdditionalInformation } from '../components/AdditionalInformation.Tab';
import { validate as validateAquaArea } from '../components/AquaAreaTab';
import { validate as validateSnowArea } from '../components/SnowAreaTab';
import { validate } from './validate';

jest.mock('../components/AdditionalInformation.Tab');
jest.mock('../components/AquaAreaTab');
jest.mock('../components/SnowAreaTab');

describe('spec validate', function () {
  it('should', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    validateSnowArea.mockReturnValue({ q: 1 });
    validateAquaArea.mockReturnValue({ w: 2 });
    validateAdditionalInformation.mockReturnValue({ e: 3 });
    // Act

    // Assert
    expect(validate({ a: 32 })).toStrictEqual({ e: 3, q: 1, w: 2 });

    expect(validateSnowArea).toHaveBeenCalledWith({ a: 32 });
    expect(validateAquaArea).toHaveBeenCalledWith({ a: 32 });
    expect(validateAdditionalInformation).toHaveBeenCalledWith({ a: 32 });
  });
});
