import { getFileList } from 'app/pages/cardsOgh/components/tables/Documents/helpers/getFileList';
import { getAttributeSubmit } from 'app/pages/cardsOgh/helpers/getAttributeSubmit';
import { GURANTEE_PERIOD_DATE_NAME } from 'app/pages/cardsOgh/VerticalLandscaping/components/GuranteePeriodDate';
import { INSTALLATION_DATE_NAME } from 'app/pages/cardsOgh/VerticalLandscaping/components/InstallationNameDate';
import { LIFE_TIME_NAME } from 'app/pages/cardsOgh/VerticalLandscaping/components/LifeTimeDate';
import { IS_DIFF_HEIGHT_MARK_NAME } from 'core/form/finalForm/fields/prepared/checkboxes/IsDiffHeightMark.FF';
import { IS_FILLED_NAME } from 'core/form/finalForm/fields/prepared/checkboxes/IsFilled.FF';
import { NO_CALC_NAME } from 'core/form/finalForm/fields/prepared/checkboxes/NoCalc.FF';
import { MATERIAL_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/MaterialId';
import { UNIT_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/UnitId';
import { VERTICAL_LANDSCAPING_CLASS_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/VerticalLandscapingClassTypeId';
import { VERTICAL_LANDSCAPING_REF_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/VerticalLandscapingRefTypeId';
import { VERTICAL_LANDSCAPING_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/VerticalLandscapingTypeId';
import { PLACES_AREA_NAME } from 'core/uiKit/preparedInputs/textField/PlacesArea';
import { PLACES_QUANTITY_NAME } from 'core/uiKit/preparedInputs/textField/PlacesQuantity';
import { QUANTITY_NAME } from 'core/uiKit/preparedInputs/textField/Quantity';

import { useInitialValueVerticalLandscaping } from './hooks/useInitialValue.VerticalLandscaping';

const mapAttribute = [
  INSTALLATION_DATE_NAME,
  VERTICAL_LANDSCAPING_TYPE_ID_NAME,
  MATERIAL_ID_NAME,
  VERTICAL_LANDSCAPING_CLASS_TYPE_ID_NAME,
  VERTICAL_LANDSCAPING_REF_TYPE_ID_NAME,
  NO_CALC_NAME,
  PLACES_QUANTITY_NAME,
  PLACES_AREA_NAME,
  PLACES_QUANTITY_NAME,
  IS_DIFF_HEIGHT_MARK_NAME,
  QUANTITY_NAME,
  UNIT_ID_NAME,
  IS_FILLED_NAME,
  GURANTEE_PERIOD_DATE_NAME,
  LIFE_TIME_NAME,
  'zone_name',
  'id_rfid',
  'eais_id',
  'erz_date',
];

/**
 * Setup Инженерные сооружения.
 *
 * @returns {JSX.Element}
 */
export const useSetupVerticalLandscaping = () => {
  const initialValues = useInitialValueVerticalLandscaping();

  /**
   * Функция подготовка данных для Атрибутов.
   *
   * @param {*} values - Значения формы.
   * @returns {*} Атрибуты.
   */
  const preparingDataForAttributes = (values) => {
    return {
      file_list: getFileList(values),
      ...getAttributeSubmit(values, mapAttribute),
    };
  };

  return { initialValues, preparingDataForAttributes };
};
