import { ContextDialog } from 'app/pages/cardsOgh/components/DIalogs/Dialogs';
import { Button } from 'core/uiKit/components/buttons/Button';
import { Dialog } from 'core/uiKit/components/dialogs/Dialog';
import React, { useContext } from 'react';

/**
 * ShowAlert.
 *
 * @returns {JSX.Element}
 */
export const ShowAlert = () => {
  const { alert, setAlert } = useContext(ContextDialog);

  /**
   * Close the dialog.
   *
   * @returns {void}
   */
  const closeDialog = () => {
    setAlert({ message: '', show: false });
  };

  return (
    <Dialog
      isOpen={alert.show}
      maxWidth={'sm'}
      textHeader={'Сообщение'}
      Body={
        <>
          <p>{alert.message}</p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              minWidth: 400,
            }}
          >
            <Button
              color={'primary'}
              variant={'contained'}
              onClick={closeDialog}
            >
              Закрыть
            </Button>
          </div>
        </>
      }
    />
  );
};
