import { getFileExtension } from './getFileExtension';

/**
 * Функция преобразования типа файла DXF.
 *
 * @param {File} file - Файл.
 * @returns {File} - Возвращает преобразованный dxf файл, либо файл без изменения если расширение не dxf.
 */
export const createFileDXF = (file: File): File => {
  if (getFileExtension(file.name) === '.dxf') {
    return new File([file], file.name, { type: 'image/vnd.dxf' });
  }
  return file;
};
