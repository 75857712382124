import { IMPROVEMENT_OBJECT, ODH, OZN, YARD } from 'app/constants/oghTypes';
/* eslint-disable */
import { useGetCardAttribute } from 'app/selectors/useSelectors/useGetCardAttribute.Selector';
import { useGetCurrentCardSelector } from 'app/selectors/useSelectors/useGetCurrentCard.Selector';
import { Checkbox } from 'core/uiKit/inputs/CheckBox';

/**
 *
 */
export const CheckboxUpdatingComponent = () => {
  const { updateRequired } = useGetCardAttribute();

  const type = useGetCurrentCardSelector().typeId;

  return [YARD, OZN, ODH, IMPROVEMENT_OBJECT].includes(type) ? (
    <Checkbox
      label={'Требует актуализации'}
      name={'update_required'}
      value={updateRequired}
      disabled={true}
    />
  ) : null;
};
