import { useEffect } from 'react';

/**
 * Хук выполнения функции после первого рендера.
 *
 * @param {Function} fn - Функция.
 * @param {Array<*>} deps - Зависимости.
 * @returns {void}
 */
export const useSkipFirstRender = (fn, deps) => {
  useEffect(() => {
    return () => {
      fn();
    };
    // eslint-disable-next-line
  }, deps);
};
