import { useChangeFieldValueRF } from 'app/actions/odsObjectActions/useChangeFieldValueRF';
import { useFormValuesCardRF } from 'app/actions/odsObjectActions/useFormValuesCardRF';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { CleaningTypeRF } from 'core/form/reduxForm/fields/prepared/selects/simple/CleaningType.RF';
import { CLEANING_TYPE_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CleaningType';
import { COATING_GROUP_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingGroupId';
import React, { useEffect } from 'react';

/**
 * Поле способ уборки.
 *
 * @returns {JSX.Element}
 */
export const CleaningTypeRfField = () => {
  const { editMode } = useEditMode();
  const formValues = useFormValuesCardRF();
  const changeFieldValue = useChangeFieldValueRF();

  useEffect(() => {
    if (formValues[COATING_GROUP_ID_NAME] === 2 && editMode) {
      changeFieldValue(CLEANING_TYPE_NAME, null);
    }

    // eslint-disable-next-line
  }, [formValues?.[COATING_GROUP_ID_NAME], editMode]);

  return (
    <CleaningTypeRF
      filter={(dict) => {
        if (formValues[COATING_GROUP_ID_NAME] === 2) {
          return dict.filter((item) => item.id !== 0);
        }
        return dict;
      }}
      disabled={!editMode}
      required
    />
  );
};
