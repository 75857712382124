import { post } from 'app/api';

 
/**
 * Метод получения таблицы свойств.
 *
 * @param {object} query - Параметры запроса.
 * @returns {Promise<*>} - Возвращает данные таблицы.
 * @example
 * const { data = [], isLoading } = useQueryAdaptor(
 *     [
 *       'getPropsDiffTable',
 *       {
 *         etalon: etalon.id,
 *         copy: copy.id,
 *         typeId,
 *       },
 *     ],
 *     getPropsDiffTable,
 *   );
 */
export const getPropsDiffTable = (query) => {
  return post('/versions/propsdiff', query);
};
