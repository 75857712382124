import { Autocomplete } from 'core/uiKit/inputs/selects/Autocomplete';
import { OGH_TYPES_NAME } from 'core/uiKit/preparedInputs';
import { fetchOghTypes } from 'core/uiKit/preparedInputs/api';
import { useGetOghTypes } from 'core/utils/hooks';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';
import get from 'lodash/get';
import React from 'react';

export const SUB_TYPE_ID_NAME = 'subTypeId';

/**
 * ## Тип.
 *
 * @param {*} props - Properties.
 * @param {*} props.odsType - Properties.
 * @param {*} props.otherProps - Properties.
 * @returns {JSX.Element}
 */
export const SubTypeId = ({ odsType, ...otherProps }) => {
  const oghTypes = useGetOghTypes();

  const typeId = get(oghTypes, `${odsType}.id`);

  const { data: options = [] } = useQueryAdaptor(
    [typeId, { typeId }],
    fetchOghTypes,
  );

  return (
    <Autocomplete
      {...otherProps}
      options={options}
      label={'Тип'}
      name={OGH_TYPES_NAME}
    />
  );
};
