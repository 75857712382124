import GeometryCSVLink from 'app/utils/card/checkGeometry/GeometryCSVLink';
import IntersectionsMessage from 'app/utils/card/checkGeometry/IntersectionsMessage';
import React from 'react';

import { createObjGeoData } from './createObjGeoData';
import { getIntersections } from './getIntersections';

/**
 * Функция createIntersectionsMessage .
 *
 * @param {Array} geometries - Геометрия.
 * @param {string} message - Сообщение.
 * @returns {JSX.Element}
 */
export function createIntersectionsMessage(geometries, message) {
  const intersections = getIntersections(geometries);
  const objGeoData = geometries.map(createObjGeoData);

  /**
   * Компонент GeometryCSV.
   *
   * @returns {JSX.Element}
   */

  return (
    <IntersectionsMessage
      GeometryCSVLink={<GeometryCSV objGeoData={objGeoData} />}
      intersections={intersections}
      message={message}
      orderedList={false}
    />
  );
}

/**
 * Компонент GeometryCSV.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.objGeoData - Объект.
 * @returns {JSX.Element}
 */
export const GeometryCSV = ({ objGeoData }) => (
  <GeometryCSVLink
    fileName={'IntersectionsGeometry.csv'}
    objects={objGeoData}
  />
);
