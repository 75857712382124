/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import { Item } from './Item';

import './treePanel.scss';

 
 
/**
 * ```js
 * import { TreePanel } from 'core/uiKit/components';
 * ```
 */
/* eslint-disable */
export const TreePanel = ({ tree = [] }) => {
  return (
    <div className="overflow-auto-y custom-scrollbar tree-panel">
      <ul className="ps-3">
        {tree.map(
          (
            {
              name = '',
              children = [],
              link = '',
              active,
            } = {},
            idx,
          ) => {
            return (
              <div key={name + '_div_' + idx}>
                <Item
                  {...{ name, link, active }}
                  key={link + name}
                />
                {children.length ? (
                  <TreePanel
                    key={name + '_TreePanel_' + idx}
                    tree={children}
                  />
                ) : null}
              </div>
            );
          },
        )}
      </ul>
    </div>
  );
};

TreePanel.propTypes = {
  tree: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      path: PropTypes.string,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          path: PropTypes.string,
          children: PropTypes.array,
        }),
      ),
    }),
  ),
};
