import { withFinalForm } from 'core/form/finalForm/helpers/withFinalForm';
import {
  COATING_GROUP_ID_NAME,
  CoatingGroupId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingGroupId';
import React from 'react';
import { Field } from 'react-final-form';

import { usePreparationCoatingGroupIdFF } from './usePreparation.CoatingGroupId.FF';

/**
 * Select FF __Вид покрытия__.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const CoatingGroupIdFF = (props) => {
  usePreparationCoatingGroupIdFF();
  return (
    <Field
      {...props}
      name={COATING_GROUP_ID_NAME}
      component={withFinalForm(CoatingGroupId)}
    />
  );
};
