export const tabs = [
  {
    isOrphanObject: true,
    label: 'Исходные данные',
    value: 'characteristics',
  },
  {
    isOrphanObject: true,
    label: 'Основные параметры',
    value: 'options',
  },
  {
    isOrphanObject: true,
    label: 'Уборочные данные',
    value: 'cleaning',
  },
  {
    label: 'Характеристики движения (архивные данные)',
    value: 'movement-characteristics',
  },
  {
    label: 'Ремонты',
    value: 'repairs',
  },
  {
    label: 'Проекты',
    value: 'repairs_plan',
  },
  {
    isOrphanObject: true,
    label: 'Документы',
    value: 'docs',
  },
  {
    label: 'Ордера на работы',
    value: 'orders',
  },
  {
    isOrphanObject: true,
    label: 'Инженерные сооружения для ТС',
    value: 'engineering-structures',
  },
];
