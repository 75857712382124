export const BASE_PGM_CODE = 'base_pgm';

export const OOPT_CODE = 'oopt';

export const YARD_CODE = 'yard';

export const IMPROVEMENT_OBJECT_CODE = 'improvement_object';

export const WASTE_COLLECTION_CODE = 'waste_collection_plants';

export const CONTAINER_CODE = 'container';

export const OZN_CODE = 'ozn';

export const ODH_CODE = 'odh';

export const ROAD_FACILITIES_CODE = 'road_facilities';

export const MAF_RFID_CODE = 'maf_rfid';
export const SPA_CODE = 'spa';
