/* eslint-disable  */
import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Колонки таблицы IntersectionTable.
 *
 * @returns {object}
 */
export const getColumnsIntersectionTable = () => {
  return [
    {
      // @ts-ignore

      /**
       *
       * @param root0
       * @param root0.row
       */
      // @ts-ignore
      Cell: ({ row }) => {
        return (
          <Link to={`/ogh/${row?.original?.object_record_id}`}>
            {row?.original?.short_object_id}
          </Link>
        );
      },
      Header: 'ID',
      accessor: 'short_object_id',
    },
    { Header: 'Заказчик', accessor: 'customer_short_name' },
    { Header: 'Наименование', accessor: 'object_name' },
    {
      Header: 'Балансодержатель',
      accessor: 'owner_short_name',
    },
    { Header: 'Статус', accessor: 'state_name' },
    { Header: 'Тип создания объекта', accessor: 'create_type_name' },
    { Header: 'Дата с', accessor: 'start_date' },
    { Header: 'Дата по', accessor: 'end_date' },
  ];
};
