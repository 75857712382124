import { WithMapContext } from 'app/components/map/withMap';
/* eslint-disable  */
import { getLengthGeometry } from 'app/pages/cardsOgh/api/helpers/getLengthGeometry';
import { postIntersection } from 'app/pages/cardsOgh/api/postCheckIntersections';
import { ContextDialog } from 'app/pages/cardsOgh/components/DIalogs/Dialogs';
import { ContextMapEdit } from 'app/pages/cardsOgh/components/Map/EditMapContext';
import { PreloadedCardContext } from 'app/pages/cardsOgh/components/PreloadedCard/contextPrelouder';
import { message } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/helpers/dialogMessage';
import { createIntersectionsMessage } from 'app/utils/card/checkGeometry/helpers/createIntersectionsMessage';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import { useContext } from 'react';

/**
 * Хук на получение функции на проверку пересечений.
 *
 * @returns {object}
 */
export const useIntersections = () => {
  const { setHasIntersections } = useContext(ContextMapEdit);
  const { showAlert } = useContext(ContextDialog);
  const { drawIntersectionsGeometry } = useContext(WithMapContext);
  const { endPreload } = useContext(PreloadedCardContext);
  const [checkIntersection, data] = useMutationAdaptor(postIntersection, {
    /**
     *
     * @param root0
     * @param root0.message
     */
    onError: ({ message = 'Неизвестная ошибка' }) => {
      endPreload();
      showAlert(message);
    },

    /**
     *
     * @param response
     */
    onSuccess: (response) => {
      const length = getLengthGeometry(response);
      if (length) {
        showAlert(
          createIntersectionsMessage(
            response.geometry,
            message.errorIntersections,
          ),
        );
        drawIntersectionsGeometry(response.geometry);
        setHasIntersections(response.intersected);
      } else {
        showAlert(message.success);
      }
      endPreload();
    },
  });

  return { checkIntersection, ...data };
};
