import { useLayoutEffect } from 'react';

const colors = {
  error: 'rgb(235 193 193)',
  success: '#def3de',
};

/**
 * Функция ceilSelectingColoringRow.
 *
 * @param {object} params - Параметры.
 * @param {*} params.waringRow - Action type.
 * @param {*} params.successRow - Action type.
 * @returns {Function} - Функция.
 */
export const ceilSelectingColoringRow = ({ waringRow, successRow }) =>
  function CeilSelectingColoringRow({ value }) {
    useLayoutEffect(() => {
      const trElements = document.querySelectorAll('tr');
      trElements.forEach((item) => {
        switch (value) {
          case successRow: {
            item.style.background = colors.success;
            break;
          }
          case waringRow: {
            item.style.background = colors.error;
            break;
          }
          default:
            break;
        }
      });
    }, [value]);
    return value;
  };
