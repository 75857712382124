/* eslint-disable  */
/**
 *
 * @param privileges
 * @param groupTypeCode
 * @param typeAction
 */
export const checkPrivileges = (privileges, groupTypeCode, typeAction) => {
  switch (typeAction) {
    case 'createOgh': {
      return privileges.includes(`create_ogh_${groupTypeCode}`);
    }
    case 'editOgh': {
      return privileges.includes(`edit_ogh_${groupTypeCode}`);
    }
    default:
      return false;
  }
};
