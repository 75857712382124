import { preloadData } from 'app/actions/preload';
import _ from 'lodash';
import { handleActions } from 'redux-actions';
/* eslint-disable */
const dictsMap = {
  coatingType: {
    name: 'coating_type',
    mapper: mapCoatingType,
  },
  sensorStates: 'sensor_state',
  dtsType: {
    name: 'dts_type',
    mapper: mapDtsType,
  },
  departmentTypes: 'department_type',
  dtsTypeSpec: 'dts_type_spec',
  arrangeElementTypes: {
    name: 'arrange_element_type',
    mapper: mapArrangeElementType,
  },
  gpsStates: 'gps_state',
  mafTypeLevel1: 'maf_type_level1',
  mafTypeLevel2: {
    name: 'maf_type_level2',
    mapper: mapMafTypeLevel2,
  },
  mafTypeLevel3: {
    name: 'maf_type_level3',
    mapper: mapMafTypeLevel3,
  },
  customers: 'customers',
  owners: 'owners',
  units: 'unit',
  legalCarRegistration: 'legal_car_registration',
  carOwnership: 'car_ownership',
  carEquipmentType: 'car_equipment_type',
  carFuncTypes: 'car_func_type',
  plantationTypes: 'plantation_type',
  carMotorTypes: 'car_motor_type',
  carEcologyClasses: 'car_ecology_class',
  carSeasons: 'car_season',
  technicRequestType: 'technic_request_type',
  technicRequestState: 'technic_request_state',
  technicRequestReason: 'technic_request_reason',
  technicFileTypes: 'technik_file_types',
  binTypes: 'bin_type',
  containerTypes: {
    name: 'container_type',
    mapper: mapContainerType,
  },
  buildingsTypeSpec: 'buildings_type_spec',
  buildingsType: 'buildings_type',
  lifeFormTypes: 'life_form_type',
  plantType: 'plant_type',
  abutmentTypes: 'abutment_type',
  techTypes: 'tech_types',
  entities: 'entity',
  carOwners: 'car_owners',
  changeTypes: {
    name: 'change_type',
    mapper: mapChangeTypes,
  },
  oghGroups: {
    name: 'ogh_group',
    mapper: mapOghGroup,
  },
  oghFileTypes: {
    name: 'ogh_file_type',
    mapper: mapOghFileType,
  },
  stateGardening: 'state_gardening',
  lawnTypes: 'lawn_type',
  geometryTypes: {
    name: 'geometry_type',
    mapper: mapGeometryTypes,
  },
  matchingChangeTypes: 'matching_change_type',
  founders: 'founder',
  prefectures: 'prefecture',
  prefChildren: 'pref_children',
  reagentBaseTypes: 'reagent_base_type',
  reagentKinds: 'reagent_kind',
  reagents: 'reagent',
  okrugs: 'okrugs',
  districts: 'districts',
  documentStatuses: 'documents_statuses',
  explicationTypes: 'explication_type',
  ooptInterstatuses: 'oopt_interstatus',
  categoryMsops: 'category_msop',
  ooptMeanings: 'oopt_meaning',
  ooptProfiles: 'oopt_profile',
  ooptStatuses: 'oopt_status',
  ooptCategories: 'oopt_category',
  grbs: 'grbs',
  bikeRefTypes: 'bike_ref_type',
  oghTypes: 'ogh_types',
};

/* eslint-disable */
export default handleActions(
  {
    [`${preloadData}_FULFILLED`]: getOdsDicts,
  },
  {
    tlTypes: {},
    oghStatuses: {},
    districts: [],
    okrugs: [],
    changeTypes: {},
    customers: {},
    owners: {},
    personRoles: {},
  },
);

function getOdsDicts(state, action) {
  const {
    payload: { odsDicts: dicts },
  } = action;

  const result = {
    ...state,
    matchingStatus: {
      ..._.mapKeys(dicts.matching_status, (value) => value.id),
    },
    tlTypes: {
      ..._.mapKeys(dicts.tl_types, (value) => value.id),
    },
    oghStatuses: {
      ..._.mapKeys(dicts.ogh_statuses, (value) => value.id),
    },
    personRoles: {
      ..._.mapKeys(dicts.person_role, (value) => value.id),
    },
    reasons: {
      ..._.mapKeys(dicts.reason, (value) => value.id),
      ..._.mapKeys(dicts.reason, (value) => value.id),
    },
    oghTitleReason: {
      ..._.mapKeys(dicts.ogh_title_reason, (value) => value.id),
    },
    sensorTypes: {
      ..._.mapKeys(dicts.sensor_type, (value) => value.id),
    },
    carFuncTypeGroups: {
      ..._.mapKeys(dicts.car_func_type_group, (value) => value.id),
    },
    carConditions: {
      ..._.mapKeys(dicts.car_condition, (value) => value.id),
    },
    sensorKinds: {
      ..._.mapKeys(dicts.sensor_kind, (value) => value.id),
    },
    equipmentTypes: {
      ..._.mapKeys(dicts.gps_state, (value) => value.id),
    },
    characteristicStateGardening: {
      ..._.mapKeys(dicts.characteristic_state_gardening, (value) => value.name),
    },
    detailedStateGardening: {
      ..._.mapKeys(dicts.detailed_state_gardening, (value) => value.id),
    },
    plantServiceRecomendations: {
      ..._.mapKeys(dicts.plant_service_recomendations, (value) => value.id),
    },
    greeneryType: {
      ..._.mapKeys(dicts.greenery_type, (value) => value.id),
    },
    otherImprovementObjectType: {
      ..._.mapKeys(dicts.other_improvement_object_type, (value) => value.id),
    },
  };

  return Object.assign(result, extractDicts(dicts));
}

function extractDicts(dicts) {
  return Object.entries(dictsMap).reduce((result, [key, value]) => {
    let name;
    let mapper;

    if (_.isObject(value)) {
      name = value.name;
      mapper = value.mapper;
    } else {
      name = value;
    }

    if (_.isFunction(mapper)) {
      result[key] = mapper(dicts[name]);
    } else {
      result[key] = dicts[name];
    }
    return result;
  }, {});
}

function mapDtsType(dict = []) {
  return dict.map((item) => {
    const {
      id,
      attribute: { name, parent_ogh_object_type_list },
    } = item;
    return {
      id,
      name,
      ogh_object_type_list: mapOghOjectTypeList(parent_ogh_object_type_list),
    };
  });
}

/* eslint-disable */
export function mapCoatingType(dict = []) {
  return dict.map((item) => {
    const {
      id,
      attribute: { name, class_id, coating_group_id, ogh_object_type_list },
    } = item;
    return {
      id,
      name,
      class_id: class_id.class,
      group_id: coating_group_id.coating_group,
      ogh_object_type_list: mapOghOjectTypeList(ogh_object_type_list),
    };
  });
}

function mapContainerType(dict = []) {
  return dict.map((item) => {
    const {
      id,
      attribute: { name, code, has_separate_garbage_collection },
    } = item;
    return {
      id,
      name,
      code,
      has_separate_garbage_collection,
    };
  });
}

function mapArrangeElementType(dict = []) {
  return dict.map((item) => {
    const {
      id,
      attribute: {
        name,
        ogh_object_type_id,
        parent_ogh_object_type_list,
        unit_id,
      },
    } = item;
    return {
      id,
      name,
      ogh_object_type_id: ogh_object_type_id.ogh_object_type,
      parent_ogh_object_type_list: mapOghOjectTypeList(
        parent_ogh_object_type_list,
      ),
      unit_id: unit_id.unit,
    };
  });
}

function mapOghOjectTypeList(list = []) {
  return list.map((params) => {
    const { ogh_object_type_id, other_improvement_object_type_list } = params;
    if (ogh_object_type_id) {
      return {
        ogh_object_type_id: ogh_object_type_id.ogh_object_type,
        other_improvement_object_type_list: mapOtherImprovementObjectTypeList(
          other_improvement_object_type_list,
        ),
      };
    }
    return params;
  });
}

function mapOtherImprovementObjectTypeList(list = []) {
  return list.map(({ other_improvement_object_type_id }) => {
    return other_improvement_object_type_id?.other_improvement_object_type;
  });
}

function mapMafTypeLevel2(dict = []) {
  return dict.map((item) => {
    const {
      id,
      attribute: { name, is_material, maf_type_list = [] },
    } = item;
    return {
      id,
      name,
      is_material,
      maf_type_list: maf_type_list.map((item) => {
        return {
          maf_type_level1_id: item.maf_type_level1_id.maf_type_level1,
        };
      }),
    };
  });
}

function mapMafTypeLevel3(dict = []) {
  return dict.map((item) => {
    const {
      id,
      attribute: {
        name,
        maf_type_level1_id,
        parent_ogh_object_type_list,
        unit_id: { unit } = {},
      },
    } = item;

    const unit_id = unit;

    return {
      id,
      name,
      maf_type_level1_id: maf_type_level1_id.maf_type_level1,
      unit_id,
      parent_ogh_object_type_list,
    };
  });
}

function mapChangeTypes(dict = []) {
  return dict.map((item) => ({
    ...item,
    id: item.code,
  }));
}

function mapOghGroup(dict = []) {
  return dict.map((item) => ({
    id: item.id,
    code: item.attribute.code,
    name: item.attribute.name,
    oghFileTypeList: item.attribute.attribute.ogh_file_type_list_id,
  }));
}

function mapOghFileType(dict = []) {
  return dict.map((item) => ({
    id: item.id,
    ...item.attribute,
  }));
}

function mapGeometryTypes(dict = []) {
  const result = {};
   
  dict.map((item) => {
    const id = item.ogh_object_type_id;
    result[id] = item.attribute;
  });
  return result;
}
