import { GroupContainer } from 'core/uiKit/components/card.components/GroupContainer';
import { TableSimple } from 'core/uiKit/components/tables/TableSimple';
import React from 'react';

import { columnsLandscapingTable } from './columns.Landscaping.Table';
import { useGetDataLandscapingTable } from './useGetDataLandscaping.Table';

/**
 * Таблица таба __Благоустройства__.
 *
 * @returns {JSX.Element}
 */
export const LandscapingTable = () => {
  const data = useGetDataLandscapingTable();
  return (
    <GroupContainer title={'Благоустройство'}>
      <TableSimple columns={columnsLandscapingTable} data={data || []} />
    </GroupContainer>
  );
};
