import PropTypes from 'prop-types';
/* eslint-disable */
import React from 'react';

PagingSelect.propTypes = {
  limit: PropTypes.number,
  handleOnChange: PropTypes.func,
};

const options = [10, 20, 50].map((item) => (
  <option key={item} value={item}>
    {item}
  </option>
));

function PagingSelect(props) {
  return (
    <select
      className="form-control"
      name="limit"
      style={{ height: 26, paddingTop: 2, paddingBottom: 2 }}
      value={props.limit}
      onChange={props.handleOnChange}
    >
      {options}
    </select>
  );
}

 
export default PagingSelect;
