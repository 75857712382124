import { Checkbox } from 'core/uiKit/inputs/CheckBox';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * CheckboxFF.
 *
 * @param {*} props - Properties.
 * @param {string} props.name - Name of the.
 * @param {*} props.otherProps - Other properties.
 * @returns {JSX.Element}
 */
export const CheckboxFF = ({ name, ...otherProps }) => {
  return (
    <Field name={name} type="checkbox">
      {(props) => {
        return (
          <Checkbox
            {...props}
            {...props.input}
            {...otherProps}
            value={props.input.checked}
          />
        );
      }}
    </Field>
  );
};
