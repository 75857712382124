import FaIcon from 'app/components/common/FaIcon';
import { useSetupFormResetPassword } from 'app/pages/auth/ResetPassword/useSetup.Form.ResetPassword';
import { TextFieldFF } from 'core/form/finalForm/fields/default/TextFieldFF';
import { Button } from 'core/uiKit/components/buttons/Button';
import React from 'react';

import { FormAuthFF } from '../components/Form.Auth.FF';
import { validateFormResetPassword } from './validate.FormResetPassword';

/**
 * Форма Сброса пароля из почты.
 *
 * @returns {JSX.Element}
 */
export const FormResetPassword = () => {
  const { onSubmit, isLoading } = useSetupFormResetPassword();

  return (
    <FormAuthFF
      Header={'Введите новый пароль'}
      Footer={
        <Button
          className="align-bottom my-3"
          type="submit"
          variant={'contained'}
          color={'primary'}
        >
          {isLoading ? (
            <FaIcon className={'fa-pulse submitIcon'} icon="spinner" />
          ) : (
            <FaIcon className={'submitIcon'} icon="floppy-o" />
          )}
          Изменить пароль
        </Button>
      }
      submit={onSubmit}
      validate={validateFormResetPassword}
    >
      <TextFieldFF name={'password'} label={'Новый пароль'} type={'password'} />
      <TextFieldFF
        name={'confirmPassword'}
        label={'Подтвердите пароль'}
        type={'password'}
      />
    </FormAuthFF>
  );
};
