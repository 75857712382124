import { Button } from 'core/uiKit/components/buttons/Button';
import { Icon } from 'core/uiKit/material-ui';
import React from 'react';

/**
 * Компонент DeleteButton.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const DeleteButton = (props) => {
  return (
    <Button
      {...props}
      color="error"
      variant={'contained'}
      startIcon={<Icon>delete</Icon>}
    >
      Удалить
    </Button>
  );
};
