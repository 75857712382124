import { useGetRegistryApprovalsTechTable } from 'app/components/card/tech/car/components/Tables/ProcessApprovingTable/api';
import { useFilterQueryParamsProcessApproving } from 'app/components/card/tech/car/components/Tables/ProcessApprovingTable/ProcessApprovingTable.Filter';
import { useParamsTable } from 'core/uiKit/components/tables/Table/useParamsTable';
import { useSelector } from 'react-redux';

/* eslint-disable */
export const UseGetDataApprovalsTechTable = () => {
  const id = useSelector((state) => state?.card.root.root_id);
  const { queryTable } = useParamsTable();
  const { prepareFilters } = useFilterQueryParamsProcessApproving();

  const {
    data: { data: { table = [], meta: { total = 0 } = {} } = {} } = {},
    isLoading,
  } = useGetRegistryApprovalsTechTable({
    root_id: id,
    ...queryTable,
    ...prepareFilters,
  });

  return { table, total, isLoading };
};
