import { useDispatch, useSelector } from 'react-redux';
// @ts-ignore
import { change } from 'redux-form';

/**
 * Хук для управления значением поля формы в Redux.
 *
 * @param {string} fieldName - Имя поля формы.
 * @returns Объект, содержащий текущее значение поля формы и функцию для его обновления.
 */
export const useFieldRF = <Values = Record<string, unknown>>(
  fieldName: string,
) => {
  const dispatch = useDispatch();

  // Получаем текущее значение поля формы из Redux
  const fieldValue = useSelector(
    (
      state: {
        form?: { editorCard?: { values?: Record<string, Values> } };
      } = {},
    ) => {
      return state?.form?.editorCard?.values?.[fieldName];
    },
  );

  /**
   * Функция для обновления значения поля формы.
   *
   * @param newValue - Новое значение поля формы.
   */
  const onChange = (newValue: Values) => {
    dispatch(change('editorCard', fieldName, newValue));
  };

  return {
    onChange,
    value: fieldValue,
  };
};
