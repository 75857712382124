import { preloadData } from 'app/actions/preload';
import * as api from 'app/api';
import * as types from 'app/constants/actionTypes';
import { createAction } from 'redux-actions';

/**
 * Action logout.
 *
 * @returns {*}
 */
export const logout = createAction(types.USER_LOGOUT);

/**
 * Action fetchPreloadData.
 *
 * @returns {*}
 */
export const fetchPreloadData = () => async (dispatch) => {
  dispatch(preloadData());
};

/**
 * Action apiPostForgotPassword.
 *
 * @returns {*}
 */
export const apiPostForgotPassword = createAction(
  types.API_POST_FORGOT_PASSWORD,
  function (data) {
    return {
      promise: api.postForgotPassword(data),
    };
  },
);

/**
 * Action forgotPassword.
 *
 * @param {*} email - Response.
 * @returns {*}
 */
export function forgotPassword(email) {
  return (dispatch) => {
    return dispatch(apiPostForgotPassword(email)).then(function () {
      return dispatch(
        () => (window.location = '/auth/forgotPassword/' + email),
      );
    });
  };
}

/**
 * Action resetPassword.
 *
 * @param {*} data - Response.
 * @param {*} tokenNumber - Response.
 * @returns {*}
 */
export function resetPassword(data, tokenNumber) {
  return function (dispatch) {
    return dispatch(apiPostResetPassword(data, tokenNumber)).then(function () {
      return dispatch(() => (window.location = '/auth/login'));
    });
  };
}

/**
 * Action apiPostResetPassword.
 *
 * @returns {*}
 */
export const apiPostResetPassword = createAction(
  types.API_POST_RESET_PASSWORD,
  function (data, tokenNumber) {
    return {
      promise: api.postResetPassword(data, tokenNumber),
    };
  },
);

/**
 * Action apiCheckToken.
 *
 * @returns {*}
 */
export const apiCheckToken = createAction(
  types.API_CHECK_TOKEN,
  function (tokenNumber) {
    return {
      promise: api.postCheckToken(tokenNumber),
    };
  },
);
