import {
  CUSTOMER_PLACE_NAME,
  CustomerPlace,
} from 'core/uiKit/preparedInputs/selects/autocompleteAsync/CustomerPlace/index';
import { Field } from 'redux-form';

/**
 * Обертка Redux form для компонента CustomerPlace.
 *
 * @param {object} params - Пропсы.
 * @param {object} params.input - Объект предоставляемый Redux form.
 * @returns {JSX.Element}
 * @class
 */
const CustomerPlaceWithReduxForm = ({ input, ...props }) => {
  return <CustomerPlace {...input} {...props} />;
};

/**
 * Заказчик для Дворовых территорий.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.otherProps - Остальные пропсы.
 * @returns {JSX.Element}
 */
export const CustomerPlaceRF = ({ ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={CUSTOMER_PLACE_NAME}
      component={CustomerPlaceWithReduxForm}
    />
  );
};
