import * as types from '../constants/actionTypes';

/* eslint-disable */
export function setTableParam(tableId, paramName, paramValue) {
  return {
    type: types.SET_TABLE_PARAM,
    tableId,
    paramName,
    paramValue,
  };
}

/* eslint-disable */
export function setTableParameters(tableId, parameters) {
  return {
    type: types.SET_TABLE_PARAMETERS,
    tableId,
    parameters,
  };
}

/* eslint-disable */
export function resetTableParameters(tableId, parameters) {
  return {
    type: types.RESET_TABLE_PARAM,
    tableId,
    parameters,
  };
}
