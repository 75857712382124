import { useChangeFieldValueRF } from 'app/actions/odsObjectActions/useChangeFieldValueRF';
import { MAF_TYPE_LEVEL_2_ID_NAME } from 'app/components/card/ogh/LittleForm/components/MafType/fields/MafTypeLevel2Id.Field';
import { MAF_TYPE_LEVEL_3_ID_NAME } from 'app/components/card/ogh/LittleForm/components/MafType/fields/MafTypeLevel3.Field';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import { fetchOghTypes } from 'core/uiKit/preparedInputs/api';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';
import React from 'react';

export const MAF_TYPE_LEVEL_1_ID_NAME = 'maf_type_level1_id';

/**
 * Select RF __Вид МАФ__.
 *
 * @param {*} props - Properties.
 * @param {*} props.parentTypeId - Properties.
 * @param {*} props.otherProps - Properties.
 * @returns {JSX.Element}
 */
export const MafTypeLevel1IdField = ({ parentTypeId = 0, ...otherProps }) => {
  const { data: options = [] } = useQueryAdaptor(
    ['optionsMafTypeLevel1AutoComplete', { parentTypeId, typeId: 37 }],
    fetchOghTypes,
    {
      enabled: Boolean(parentTypeId),
    },
  );
  const changeFieldValueRF = useChangeFieldValueRF();
  return (
    <SelectRF
      {...otherProps}
      options={options}
      label={'Вид МАФ'}
      name={MAF_TYPE_LEVEL_1_ID_NAME}
      withChange={() => {
        changeFieldValueRF(MAF_TYPE_LEVEL_2_ID_NAME, '');
        changeFieldValueRF(MAF_TYPE_LEVEL_3_ID_NAME, '');
      }}
    />
  );
};
