import * as loaderActions from 'app/actions/loader';

/* eslint-disable */
export default function isFetching(state = false, action) {
  if (action.type === loaderActions.show.toString()) {
    return true;
  } else if (action.type === loaderActions.hide.toString()) {
    return false;
  } else if (action.type.startsWith('REQUEST')) {
    return true;
  } else if (action.type.startsWith('RECEIVE')) {
    return false;
  }
  return state;
}
