import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { ReagentIdFF } from 'core/form/finalForm/fields';
import React from 'react';
import { useField } from 'react-final-form';

 
/**
 * Компонент фильтрации поля ReagentKindIdFFTank.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - Отфильтрованный компонент.
 * @example <ReagentIdFFTank />
 */
export const ReagentIdFFTank = (props) => {
  const { editMode } = useEditMode();
  const {
    input: { value },
  } = useField('reagentKindId');

   
  /**
   * Функция фильтрации.
   *
   * @param {Array} dict - Массив опшинов.
   * @returns {Array} - Возвращает отфильтрованнй массив.
   * @example <ReagentIdFF
   *       filter={filter}
   *     />
   */
  const filter = (dict) =>
    dict.filter(({ reagent_kind_id }) => reagent_kind_id === value);

  return (
    <ReagentIdFF
      {...props}
      withAll={false}
      filter={filter}
      required={true}
      disabled={!(editMode && value)}
    />
  );
};
