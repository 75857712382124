import { cellsMainMap } from 'core/uiKit/components/cells/cellsMainMap';

/**
 * @typedef {import('src/core/uiKit/components/tables/Table/Table').Columns} Columns
 */

/**
 * Получение колонок В реестре __Растения Красной книги__.
 *
 * @returns {Columns}
 */
export const getColumnsRedBookPlant = () => [
  cellsMainMap.short_root_id, //Id
  {
    ...cellsMainMap.object_type_name,
    Header: 'Вид растения',
  },
  cellsMainMap.section_num, // Номер участка
  cellsMainMap.green_num, // Номер растения
  cellsMainMap.object_status_name, // Статус
  cellsMainMap.start_date, // Дата начала
  cellsMainMap.end_date, // Дата окончания
  {
    ...cellsMainMap.parent_name,
    Header: 'Наименование родительского объекта',
  },
  cellsMainMap.parent_short_root_id, // Идентификатор родительского объекта
  cellsMainMap.parent_okrug_name, // Округ родительского объекта
  cellsMainMap.parent_owner_name, // Балансодержатель
  cellsMainMap.parent_grbs_name, // ГРБС
  cellsMainMap.sign_date, // Дата подписания
  cellsMainMap.sign_person, // ФИО подписанта
  cellsMainMap.included_in_tl, // Входит в ТС
];
