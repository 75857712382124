import { REQUIRED_FIELD_MESSAGE } from 'app/constants/messages';

/* eslint-disable */
export function validateCarMandatoryProperties(valueHolder, mandatoryFields) {
  const errors = {};
  const mandatoryKeys = Object.keys(mandatoryFields);
  if (Array.isArray(mandatoryKeys)) {
    mandatoryKeys.forEach((key) => {
      const field = valueHolder[key];
      if (isEmpty(field) || (field.id !== undefined && isEmpty(field.id))) {
        errors[key] = defaultErrMessage(field);
      }
    });
  }

  return errors;
}

/* eslint-disable */
export function validateMandatoryProperties(valueHolder, mandatoryKeys) {
  const errors = {};
  if (Array.isArray(mandatoryKeys)) {
    mandatoryKeys.forEach((key) => {
      const field = valueHolder[key];
      if (isEmpty(field) || (field.id !== undefined && isEmpty(field.id))) {
        errors[key] = defaultErrMessage(field);
      }
    });
  }

  return errors;
}

/* eslint-disable */
export function isEmpty(value) {
  return (
    value === undefined ||
    value === null ||
    (Array.isArray(value) && value.length === 0) ||
    value === ''
  );
}

/* eslint-disable */
export function defaultErrMessage(field) {
  return Array.isArray(field) ? 'обязательный список' : REQUIRED_FIELD_MESSAGE;
}
