import getOghTypeId from 'app/selectors/card/getOghTypeId';
import getFormValues from 'app/selectors/form/getFormValues';
import getDict from 'app/selectors/getDict';
import { createSelector } from 'reselect';

/* eslint-disable  */
import dict from './dict';

/**
 *
 * @param state
 */
const getCurrentUnit = (state) => {
  const units = getDict('units')(state);
  const currentUnitId = getFormValues(state).unit_id;
  return currentUnitId && units.find((item) => item.id === currentUnitId);
};

export default createSelector(
  [getOghTypeId, getCurrentUnit],
  (cardTypeId, currentUnit) => {
    let result;
    if (currentUnit && dict[cardTypeId]) {
      result = dict[cardTypeId].includes(currentUnit.code);
    } else {
      result = false;
    }
    return result;
  },
);
