/* eslint-disable */
import { shallow } from 'enzyme';
import { PlacementIdRFWrapper } from 'app/components/card/ogh/components/selects/PlacementIdRFWrapper';
import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { placementDict } from 'app/components/card/ogh/components/selects/placementDict';
import {
  FENCING,
  LAMPPOSTS,
  MAF,
  OTHER_ENGIN_CONSTRUCT,
  ROAD_SIGNS,
  TRAFFIC_LIGHT,
} from 'app/constants/oghTypes';

jest.mock('app/api/hooks/useGetCurrentCard');

const MAF_ARR = [
  {
    id: 18,
    code: 'roadway',
    name: 'Проезжая часть',
    fz_spr: [],
  },
  {
    id: 19,
    code: 'sidewalk',
    name: 'Тротуар',
    fz_spr: [],
  },
  {
    id: 20045,
    code: 'other_terr',
    name: 'Прочие территории',
    fz_spr: ['spr13'],
  },
  {
    id: 20080,
    code: 'own_terr',
    name: 'На своей территории (в границе ОДХ)',
    fz_spr: [],
  },
  {
    id: 20048,
    code: '3',
    name: 'Обочина',
    fz_spr: ['spr23', 'spr13'],
  },
  {
    id: 20090,
    code: 'fence',
    name: 'Ограждение',
    fz_spr: [],
  },
];
const LAMPPOSTS_ARR = [
  {
    id: 18,
    code: 'roadway',
    name: 'Проезжая часть',
    fz_spr: [],
  },
  {
    id: 19,
    code: 'sidewalk',
    name: 'Тротуар',
    fz_spr: [],
  },
  {
    id: 20045,
    code: 'other_terr',
    name: 'Прочие территории',
    fz_spr: ['spr13'],
  },
  {
    id: 20070,
    code: 'outside',
    name: 'За границей ОДХ',
    fz_spr: [],
  },
  {
    id: 20048,
    code: '3',
    name: 'Обочина',
    fz_spr: ['spr23', 'spr13'],
  },
  {
    id: 20090,
    code: 'fence',
    name: 'Ограждение',
    fz_spr: [],
  },
  {
    id: 20100,
    code: 'engin_construct',
    name: 'Инженерное сооружение',
    fz_spr: [],
  },
];

const FENCING_ARR = [
  {
    code: '1',
    fz_spr: ['spr23'],
    id: 20046,
    name: 'На газоне',
  },
  {
    code: '2',
    fz_spr: ['spr23'],
    id: 20047,
    name: 'На цоколе (на своей территории)',
  },
  {
    code: '3',
    fz_spr: ['spr23', 'spr13'],
    id: 20048,
    name: 'Обочина',
  },
  {
    code: '4',
    fz_spr: ['spr23', 'spr13'],
    id: 20049,
    name: 'Проезжая часть',
  },
  {
    code: '5',
    fz_spr: ['spr23'],
    id: 20050,
    name: 'Проезжая часть.Мост',
  },
  {
    code: '6',
    fz_spr: ['spr23'],
    id: 20051,
    name: 'Подпорная стенка',
  },
  {
    code: '7',
    fz_spr: ['spr23'],
    id: 20052,
    name: 'Проезжая часть.Путепровод',
  },
  {
    code: '8',
    fz_spr: ['spr23'],
    id: 20053,
    name: 'Проезжая часть.Рампа тоннеля',
  },
  {
    code: '9',
    fz_spr: ['spr23'],
    id: 20054,
    name: 'Проезжая часть.Эстакада',
  },
  {
    code: '10',
    fz_spr: ['spr23', 'spr13'],
    id: 20055,
    name: 'Тротуар',
  },
  {
    code: '11',
    fz_spr: ['spr23'],
    id: 20056,
    name: 'Тротуар.Мост',
  },
  {
    code: '13',
    fz_spr: ['spr23'],
    id: 20058,
    name: 'Тротуар.Путепровод',
  },
  {
    code: '14',
    fz_spr: ['spr23'],
    id: 20059,
    name: 'Тротуар.Рампа тоннеля',
  },
  {
    code: '15',
    fz_spr: ['spr23'],
    id: 20060,
    name: 'Тротуар.Эстакада',
  },
];

 
describe('title PlacementIdRFWrapper', () => {
  it.each`
    typeId                   | titleSelect                                 | expected
    ${MAF}                   | ${'Малые архитектурные формы (ОДХ)'}        | ${MAF_ARR}
    ${LAMPPOSTS}             | ${'Опоры освещения и контактных сетей'}     | ${LAMPPOSTS_ARR}
    ${OTHER_ENGIN_CONSTRUCT} | ${'Инженерные сооружения'}                  | ${MAF_ARR}
    ${ROAD_SIGNS}            | ${'Знаки, указатели и информационные щиты'} | ${LAMPPOSTS_ARR}
    ${TRAFFIC_LIGHT}         | ${'Светофорные объекты'}                    | ${LAMPPOSTS_ARR}
    ${FENCING}               | ${'Ограждения'}                             | ${FENCING_ARR}
    ${1234}                  | ${'undefined'}                              | ${[]}
  `('test arr $titleSelect', ({ typeId, expected }) => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetCurrentCard.mockReturnValue({ typeId });
    // Act
    const wrapper = shallow(<PlacementIdRFWrapper />);

    // Assert
    expect(wrapper.props().filter(placementDict)).toStrictEqual(expected);
  });

  it('render PlacementIdRFWrapper', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetCurrentCard.mockReturnValue({ typeId: MAF });
    // Act
    const wrapper = shallow(
      <PlacementIdRFWrapper disable={true} required={true} withAll={false} />,
    );
    // Assert
    expect(wrapper.props()).toMatchObject({
      disable: true,
      required: true,
      withAll: false,
    });
    expect(wrapper.text()).toBe('<PlacementIdRF />');
  });
});
