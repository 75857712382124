/* eslint-disable */
import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import {
  OtherImprovementObjectType,
  OTHER_IMPROVEMENT_OBJECT_TYPE_NAME,
} from 'core/uiKit/preparedInputs';

 
 
/**
 * ## Тип ИОБ
 * ### React Final Form Field
 *
 * ###### type = `Select`
 * ###### name = `OTHER_IMPROVEMENT_OBJECT_TYPE_NAME`
 *
 *
 * ```js
 * import {
  OtherImprovementObjectTypeFF,
  OTHER_IMPROVEMENT_OBJECT_TYPE_NAME,
} from 'core/form/finalForm/fields';
 * ```
 *
 *
 */
/* eslint-disable */
export const OtherImprovementObjectTypeFF = ({ name, ...otherProps }) => {
  return (
    <Field name={OTHER_IMPROVEMENT_OBJECT_TYPE_NAME}>
      {(props) => {
        return (
          <OtherImprovementObjectType
            {...props}
            {...props.input}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
};

 
export { OTHER_IMPROVEMENT_OBJECT_TYPE_NAME };

OtherImprovementObjectTypeFF.propTypes = {
   
   
/**
   * очищаем инпут с сохранением значения
   */
  clear: PropTypes.bool,
   
   
/**
   * быбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * функция для фильтрации options
   */
  filter: PropTypes.func,
   
  /* eslint-disable */
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
/**
   * placeholder
   */
  placeholder: PropTypes.string,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
};
OtherImprovementObjectTypeFF.defaultProps = {
  disabled: false,
};
