/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { uploadFile } from 'app/actions/uploadActions';
import moment from 'moment';

import Select from 'core/newComponents/Select';

import TextField from 'core/newComponents/TextField';

import DialogAction from '../../common/DialogAction';
import FileUpload from 'app/components/common/DndFileUpload';

import getDict from 'app/selectors/buildDictSelector';

const propTypes = {
  showCondition: PropTypes.bool,
  technicFileTypes: PropTypes.array,
  title: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

const defaultProps = {
  title: 'Добавление файла',
};

class DialogAddCalibrationFile extends React.Component {
  state = {
    fileTypeId: 6,
  };

  handleCancel = () => {
    const { onCancel } = this.props;
    onCancel();
  };

  handleOk = () => {
    const { onSubmit } = this.props;
    const additional = {
      create_date: moment().format('DD.MM.YYYY HH:mm:ss'),
      comment: this.state.comment,
      type_id: this.state.fileTypeId,
    };
    uploadFile(this.state.file, additional).then((res) => {
      const fileId = res.fileId;
      onSubmit({
        file_id: fileId,
        file_name: this.state.file.name,
        ...additional,
      });
      this.setState({
        file: null,
        comment: null,
      });
    });
  };

  render() {
    const { showCondition, title, technicFileTypes } = this.props;

    return (
      <DialogAction
        className="Dialog-Add-Calibration-File"
        showCondition={showCondition}
        title={title}
        onCancel={this.handleCancel}
        onOk={this.handleOk}
      >
        <div style={{ width: '100%' }}>
          <Select
            disabled={true}
            label="Тип файла"
            options={technicFileTypes}
            value={this.state.fileTypeId}
            onChange={(value) => {
              this.setState({ fileTypeId: value });
            }}
          />
        </div>
        <TextField
          fullWidth={true}
          label="Комментарий"
          value={this.state.comment}
          onChange={(e) => {
            this.setState({ comment: e.target.value });
          }}
        />
        <FileUpload
          add={(file) => {
            this.setState({ file: file[0] });
          }}
          errorText={!this.state.file && 'Необходимо выбрать хотя бы один файл'}
          files={this.state.file}
          multiple={false}
          remove={() => {
            this.setState({ file: null });
          }}
          text="Переместите файл или нажмите для выбора"
        />
      </DialogAction>
    );
  }
}

DialogAddCalibrationFile.propTypes = propTypes;
DialogAddCalibrationFile.defaultProps = defaultProps;

const getTechnicFileTypes = getDict('technicFileTypes');

const mapStateToProps = (state) => ({
  technicFileTypes: getTechnicFileTypes(state),
});

 
export default connect(mapStateToProps)(DialogAddCalibrationFile);
