import CardAbstract from 'app/components/card/common/CardAbstract';
/* eslint-disable */
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { objectTabs } from 'app/components/card/common/tabs';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import printOghCardHeader from 'app/components/card/ogh/header/oghHeader';
import submitCard from 'app/components/card/ogh/submit';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import { mandatoryFields } from 'app/utils/card/isMandatory';
import { transformValues } from 'app/utils/form/transformation';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import Properties from './components/Properties';
import Versions from './components/Versions';

const propTypes = {
  card: PropTypes.object.isRequired,
  createMode: PropTypes.bool.isRequired,
  documents: PropTypes.object,
  editMode: PropTypes.bool.isRequired,
  mode: PropTypes.object.isRequired,
  required: PropTypes.bool.isRequired,
};

/**
 * EmergencyParkingCard.
 *
 * @param {*} props - Properties.
 * @param {*} props.card - Card.
 * @param {*} props.mode - Mode.
 * @param {*} props.createMode - Create mode.
 * @param {*} props.editMode - Edit mode.
 * @param {*} props.required - Required.
 * @param {*} props.documents - Documents.
 * @returns {JSX.Element}
 */
function EmergencyParkingCard({
  card,
  mode,
  createMode,
  editMode,
  required,
  documents,
}) {
  const { type_id: typeId } = card;

  return (
    <CardAbstract>
      <div id="editor-card">
        {printOghCardHeader(card, mode)}
        {card && objectTabs(typeId)}
        <Properties
          card={card}
          documents={documents}
          editMode={editMode}
          required={required}
        />
        <Versions card={card} createMode={createMode} />
      </div>
    </CardAbstract>
  );
}

EmergencyParkingCard.propTypes = propTypes;

const emergencyParkingCardDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

/**
 *
 * @param state
 * @param props
 */
const mapStateToProps = (state, props) => {
  const { card } = props;
  const { start_date, end_date } = card;
  const parse = getParseAttribute(props);

  const result = {
    createMode: isCreateMode(state, props),
    editMode: isEditMode(state, props),
    enableReinitialize: true,
    initialValues: {
      area: parse('area'),
      bord_begin: parse('bord_begin'),
      bord_end: parse('bord_end'),
      coating_group_id: parse('coating_group_id.coating_group'),
      coating_type_id: parse('coating_type_id.coating_type'),
      description: parse('description'),
      endDate: parseDate(end_date),
      name: parse('name'),
      odh_axis: parse('odh_axis'),
      odh_side_id: parse('odh_side_id.odh_side'),
      placement_id: parse('placement_id.placement'),
      startDate: parseDate(start_date),
    },

    onSubmit: onSubmit.bind({ props }),
    required: isRequired(state, props),
    validate,
  };

  return emergencyParkingCardDocumentsReducer(result, state, props);
};

const numericFields = [
  { name: 'bord_begin', type: 'decimal', zero: true },
  { name: 'bord_end', type: 'decimal', zero: true },
  { name: 'area', positive: true, type: 'decimal', zero: false },
];

const dictFields = [
  'odh_side_id',
  'coating_group_id',
  'coating_type_id',
  'placement_id',
];

/**
 *
 * @param values
 */
const validate = (values) => validateNumeric(values, numericFields);

/**
 * Create attribute.
 *
 * @param {*} formValues - Form values.
 * @returns {*}
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

/**
 * On submit.
 *
 * @param {*} formValues - Form values.
 * @returns {void}
 */
function onSubmit(formValues) {
  const {
    card: { type_id: typeId },
  } = this.props;
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };

  validateRequiredFieldsAsync(formValuesWithAttribute, mandatoryFields[typeId]);

  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, {
      dictFields,
      numericFields,
    }),
  );
}

export default connect(mapStateToProps)(
  reduxForm({ form: 'editorCard' })(EmergencyParkingCard),
);
