import { log } from 'core/utils/log';
import { switchServisMap } from 'core/utils/map/switchServisMap';
import React, { createContext, ReactNode, useContext, useMemo } from 'react';

import { useDTWAuth, UseDTWAuthResult } from './hooks/useDTWAuth';
import {
  useGetDTWTokens,
  UseGetDTWTokensResult,
} from './hooks/useGetDTWTokens';

export interface MapContextState
  extends UseGetDTWTokensResult,
    UseDTWAuthResult {}

export const DTWContext = createContext<MapContextState>({
  accessToken: '',

  /**
   * Очистка токенов.
   */
  clearTokens: () => {
    log.warn('clearTokens is not implemented');
  },

  /**
   * Получение токенов.
   *
   * @returns Пустая строка.
   */
  getAccessToken: () => {
    log.warn('getAccessToken is not implemented');
    return '';
  },

  /**
   * Получение токена обновления.
   *
   * @returns Пустая строка.
   */
  getRefreshToken: () => {
    log.warn('getRefreshToken is not implemented');
    return '';
  },

  isLoginError: false,
  isLoginLoading: false,
  isRefreshError: false,
  isRefreshLoading: false,
  loginError: null,
  refreshError: null,
  refreshToken: '',

  /**
   * Установка токенов.
   */
  setAccessToken: () => {
    log.warn('setAccessToken is not implemented');
  },

  /**
   * Установка токена обновления.
   */
  setRefreshToken: () => {
    log.warn('setRefreshToken is not implemented');
  },

  /**
   * Установка токенов.
   */
  setTokens: () => {
    log.warn('setTokens is not implemented');
  },
});

/**
 * Провайдер для авторизации в цифровом двойнике.
 *
 * @param param0 - Пропсы.
 * @param param0.children - Реакт нода.
 * @returns Object.
 */
export const ContextProviderDTW = ({ children }: { children: ReactNode }) => {
  const {
    isLoginError,
    isLoginLoading,
    isRefreshError,
    isRefreshLoading,
    loginError,
    refreshError,
  } = useDTWAuth();

  const {
    accessToken,
    clearTokens,
    getAccessToken,
    getRefreshToken,
    refreshToken,
    setAccessToken,
    setRefreshToken,
    setTokens,
  } = useGetDTWTokens();

  const value = useMemo(
    () => ({
      accessToken,
      clearTokens,
      getAccessToken,
      getRefreshToken,
      isLoginError,
      isLoginLoading,
      isRefreshError,
      isRefreshLoading,
      loginError,
      refreshError,
      refreshToken,
      setAccessToken,
      setRefreshToken,
      setTokens,
    }),
    // eslint-disable-next-line
    [
      accessToken,
      refreshToken,

      isLoginLoading,
      isLoginError,
      loginError,

      isRefreshLoading,
      isRefreshError,
      refreshError,
    ],
  );
  return switchServisMap({
    dtw: <DTWContext.Provider value={value}>{children}</DTWContext.Provider>,
    egip: <>{children}</>,
  });
};

/**
 * Хук для получения контекста авторизации в цифровом двойнике.
 *
 * @returns Object.
 */
export const useDTWContext = () => {
  const context = useContext(DTWContext);

  if (context === undefined) {
    throw new Error('useDTWContext must be used within a DTWContext');
  }

  return context;
};
