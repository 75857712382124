import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { MultiSelect } from 'core/uiKit/inputs/selects/MultiSelect';
import React from 'react';

export const SENSOR_TYPE_NAME = 'sensorType';

/**
 * ## Датчики.
 *
 * @param {*} props - Properties.
 * @param {*} props.filter - Properties.
 * @param {*} props.otherProps - Properties.
 * @returns {JSX.Element}
 */
export const SensorType = ({ filter = (_) => _, ...otherProps }) => {
  const dict = 'sensorType';
  const { data = [] } = useGetDictionary(dict);

  const options = filter(data);

  return (
    <MultiSelect
      {...otherProps}
      {...{ options }}
      label={'Датчики'}
      name={SENSOR_TYPE_NAME}
    />
  );
};
