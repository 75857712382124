import { BUILDINGS_TYPE_SPEC_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/BuildingsTypeSpec';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { BuildingsTypeSpecWithReduxForm } from './BuildingsTypeSpecWithReduxForm';

/* eslint-disable */
/**
 * Компонент BuildingsTypeSpecRF.
 *
 * @param {object} props - Пропсы.
 * @param {string} props.name - Имя buildingsTypeSpecId.
 * @param {object} props.otherProps - Остальные пропсы.
 * @returns {JSX.Element} - Возвращает Field.
 * @example <BuildingsTypeSpecRF/>
 */
export const BuildingsTypeSpecRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={BUILDINGS_TYPE_SPEC_NAME}
      component={BuildingsTypeSpecWithReduxForm}
    />
  );
};

BuildingsTypeSpecRF.propTypes = {
   
   
/**
   * быбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
/**
   * placeholder
   */
  placeholder: PropTypes.string,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
};
BuildingsTypeSpecRF.defaultProps = {
  disabled: false,
};
