import { AutocompleteAsync } from 'core/uiKit/inputs/selects/AutocompleteAsync';
import React from 'react';

import { fetchGovNumberTech } from './api';

export const GOV_NUMBER_NAME = 'govNumber';

/**
 * Рег номер.
 *
 * @param {*} props - Properties.
 * @returns {JSX.Element}
 */
export const GovNumber = (props) => {
  return (
    <AutocompleteAsync
      label={'Рег. номер'}
      {...props}
      fetchFunction={fetchGovNumberTech}
      name={GOV_NUMBER_NAME}
    />
  );
};
