import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';

const mapStatuses = [1, 3, 4, 6];

/**
 * Проверить статус.
 *
 * @param {number} statusId - Идентификатор статуса (logical_state_id).
 * @returns {boolean}
 */
const checkStatus = (statusId) => {
  return mapStatuses.includes(statusId);
};

/**
 * Хук, проверки статуса.
 *
 * @returns {boolean}
 */
export const useEditCheckStatus = () => {
  const card = useGetCurrentCard();
  const parent = useGetParentCard();

  const logicalStateId = parent.logicalStateId || card.logicalStateId;

  return checkStatus(logicalStateId);
};
