import { useFetchOghTable } from 'app/pages/registry/ogh/api';
import { useParamsTable } from 'core/uiKit/components/tables/Table';

import { TableOghChildrenProps } from '../../types';
import { useFilterQueryParamsTreesShrubs } from './Filter.TreesShrubs';

/**
 * Setup Компонентa таблицы __Деревья/Кустарники__.
 *
 * @param {TableOghChildrenProps} props - Пропсы.
 * @returns Данные для таблицы.
 */
export const useSetupTableTreesShrubs = ({
  typeId,
  parentTypeId,
}: TableOghChildrenProps) => {
  const { prepareFilters } = useFilterQueryParamsTreesShrubs();
  const { queryTable } = useParamsTable();

  const queryKey = [
    'TableTreesShrubs',
    {
      ...queryTable,
      ...prepareFilters,
      parentTypeId,
      typeId,
    },
  ];

  const { table, isLoading, count } = useFetchOghTable(queryKey);

  return {
    count,
    isLoading,
    table,
  };
};
