export const clean_type = [
  {
    code: 'manual_clean',
    id: 1,
    name: 'Ручная уборка',
  },
  {
    code: 'auto_clean',
    id: 2,
    name: 'Механизированная уборки',
  },
  {
    code: 'no_clean',
    id: 3,
    name: 'Без уборки',
  },
];
