import { parseDateToMoment } from 'app/utils/date/parseDateToMoment';
/* eslint-disable */
import { Edit, IconButton, Tooltip } from 'core/uiKit/material-ui';
import { REAGENT_KIND_NAME, REAGENT_NAME } from 'core/uiKit/preparedInputs';
import React, { useState } from 'react';

import { DialogRefill } from '../RefillButton/Dialog.Refill';
import { DialogShipment } from '../ShipmentButton/Dialog.Shipment';

const mapDialogComponent = new Proxy(
  {
    default: null,
    refill: DialogRefill,
    shipment: DialogShipment,
  },
  {
    /**
     *
     * @param target
     * @param name
     */
    get: (target, name) => {
      return name in target ? target[name] : target.default;
    },
  },
);

/**
 * Компонент EditOperation.
 *
 * @param {object} props - Row.
 * @param {object} props.originalRow - Row.
 * @returns {JSX.Element}
 */
export const EditOperation = ({ originalRow }) => {
  const [isOpen, setIsOpen] = useState(false);
  const DialogEditOperation = mapDialogComponent[originalRow.operationKind];

  const initialValues = {
    ...originalRow,
    [REAGENT_KIND_NAME]: originalRow.reagentKindId,
    [REAGENT_NAME]: originalRow.reagentId,
    targetDateTime: parseDateToMoment(
      originalRow.uiTargetDate,
      'DD.MM.YYYY HH:mm',
    ),
  };
  return (
    <>
      <Tooltip title="Редактировать">
        <IconButton size="small" onClick={() => setIsOpen(true)}>
          <Edit fontSize="inherit" />
        </IconButton>
      </Tooltip>
      {isOpen ? (
        <DialogEditOperation
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          initialValues={initialValues}
        />
      ) : null}
    </>
  );
};
// id: 291
// operationKind: "shipment"
// operationKindName: "Отпуск"
// quantity: 1
// reagent: 2
// reagentId: 2
// reagentKind: 2
// reagentKindId: 2
// reagentKindName: "твердые реагенты"
// reagentName: "МРКтв"
// targetDate: "17.02.2022"
// targetDateTime: Moment {_isAMomentObject: true, _i: '17.02.2022 20:12', _f: 'DD.MM.YYYY', _isUTC: false, _pf: {…}, …}
// uiTargetDate: "17.02.2022 20:12"
//
//
// comment: "Мой камент"
// consignee_name: "Префектура ВАО"
// customer_name: "АвД ВАО"
// gov_number: "ф123бб58"
// id: 100
// operation_kind: "shipment"
// operation_kind_name: "Отпуск"
// quantity: 6
// reagent_id: 3
// reagent_kind_id: 2
// reagent_kind_name: "твердые реагенты"
// reagent_name: "МРтв"
// target_date: "06.08.2019"
// ui_target_date: "06.08.2019 23:59"
