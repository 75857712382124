import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { vi } from 'vitest';

import { useSetupImprovementObjectCategoryIdFiledYard } from './useSetup.ImprovementObjectCategoryId.Filed.Yard';

vi.mock('app/api/hooks/useGetParentCard');

/**
 * Mock Default.
 *
 * @returns {void}
 */
const mockDefault = () => {
  useGetParentCard.mockReturnValue({});
};

describe('🐛 spec useSetup.ImprovementObjectCategoryId.Filed.Yard', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock

    //🔥 Act
    const res = useSetupImprovementObjectCategoryIdFiledYard();

    //❓ Assert
    expect(res).toStrictEqual({
      filter: expect.any(Function),
    });
  });
});
