import FaIcon from 'app/components/common/FaIcon';
import { Button } from 'core/uiKit/components';
import { Paper } from 'core/uiKit/components/Paper';
import React from 'react';
import { Link } from 'react-router-dom';

/**
 * SuccessfulSendingPage.
 *
 * @returns {JSX.Element}
 */
const SuccessfulSendingPage = () => {
  return (
    <Paper dark={true}>
      <div className={'ods-form-bg authForm px-4 py-3'}>
        <div>
          <span>
            <FaIcon className={'submitIcon'} icon="check" />
            Письмо с инструкциями по восстановлению пароля отправлено на
            указанный адрес
          </span>
        </div>
        <div className={'mt-4 display-flex justify-content-end'}>
          <Button
            component={Link}
            to={'/auth/login'}
            variant={'contained'}
            color={'primary'}
          >
            Вернуться на главную страницу
          </Button>
        </div>
      </div>
    </Paper>
  );
};

export default SuccessfulSendingPage;
