import { pathBuilding } from 'app/pages/registry/ogh/yard/Building/path.Building';
import { pathDts } from 'app/pages/registry/ogh/yard/Dts/path.Dts';
import { pathFlowersGarden } from 'app/pages/registry/ogh/yard/FlowersGarden/path.FlowersGarden';
import { pathFunctionality } from 'app/pages/registry/ogh/yard/Functionality/path.Functionality';
import { pathLawn } from 'app/pages/registry/ogh/yard/Lawn/path.Lawn';
import { pathLittleForm } from 'app/pages/registry/ogh/yard/LittleForm/path.LittleForm';
import { pathOtherOks } from 'app/pages/registry/ogh/yard/OtherOks/path.OtherOks';
import { pathOtherTechPlace } from 'app/pages/registry/ogh/yard/OtherTechPlace/path.OtherTechPlace';
import { pathPlanarStructure } from 'app/pages/registry/ogh/yard/PlanarStructure/path.PlanarStructure';
import { pathPpi } from 'app/pages/registry/ogh/yard/Ppi/path.Ppi';
import { pathRelief } from 'app/pages/registry/ogh/yard/Relief/path.Relief';
import { pathStoragePlace } from 'app/pages/registry/ogh/yard/StoragePlace/path.StoragePlace';
import { pathTechPlace } from 'app/pages/registry/ogh/yard/TechPlace/path.TechPlace';
import { pathTreesShrubs } from 'app/pages/registry/ogh/yard/TreesShrubs/path.TreesShrubs';
import { pathContainer } from 'app/pages/registry/ogh/сontainer/Container/path.Container';

/* eslint-disable */
/**
 * Дерево Иные объекты благоустройства.
 *
 * @type {[{path: string},{children: ({path: "container", code: "container"}|{path: string}|{path: string})[], name: string},{path: string},{path: string},{path: string},null,null,null,null,null,null]}
 */
export const treeImprovementObject = [
  pathBuilding,
  {
    name: 'Плоскостные сооружения',
    children: [
      pathContainer,
      pathPlanarStructure,
      pathStoragePlace,
    ],
  },
  pathTechPlace,
  pathOtherTechPlace,
  pathDts,
  pathPpi,
  pathRelief,
  pathFunctionality,
  pathLittleForm,
  {
    name: 'Объекты озеленения',
    children: [
      pathLawn,
      pathFlowersGarden,
      pathTreesShrubs,
    ],
  },
  pathOtherOks,
];
