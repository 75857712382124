 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Состояние (из ЕРЗ)__.
 *
 * @type {{ accessor: 'erz_state',  Header: 'Состояние (из ЕРЗ)' }}
 * @augments Cell
 */
export const erz_state = {
  accessor: 'erz_state',
  Header: 'Состояние (из ЕРЗ)',
  sortable: true,
};
