import { ROLE_NAME, RoleFF } from 'core/form/finalForm/fields';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';
import { ObjectParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

/**
 * Группа.
 *
 * @param {*} params - Параметры компонента.
 * @returns {JSX.Element}
 */
export const RoleFilter = (params) => {
  return <RoleFF {...params} className={filterInput} />;
};

export { ROLE_NAME };

/**
 * RoleParams.
 *
 * @returns {object}
 */
export const roleParams = () => {
  addPreparation(ROLE_NAME, (value) => value.id);
  return {
    [ROLE_NAME]: ObjectParam,
  };
};
