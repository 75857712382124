import { Validator } from 'core/form/Validator';
import { PASSWORD_NAME } from 'core/uiKit/preparedInputs';
import { USER_NAME } from 'core/uiKit/preparedInputs/textField/UserName';

/**
 * Функция валидации формы авторизации.
 *
 * @param values - Значения формы.
 * @param values.PASSWORD_NAME - Пароль пользователя.
 * @param values.USER_NAME - Имя пользователя.
 * @returns
 */
export const validateForm = (values: {
  [PASSWORD_NAME]: string;
  [USER_NAME]: string;
}) => {
  const validate = new Validator(values);

  validate.setRequired(USER_NAME, 'Необходимо ввести имя пользователя');
  validate.setRequired(PASSWORD_NAME, 'Необходимо ввести пароль');

  return validate.getErrors();
};
