import { ContinueDialog } from 'core/uiKit/components';
import { DeleteButton } from 'core/uiKit/components/buttons/actionsButtons/Delete.Button';
import React from 'react';

import useSetup from './useSetup.DeleteButtonCard';

/**
 * This is a React component that renders a delete button for a card object and displays a confirmation
 * dialog before deleting it.
 *
 * @returns {JSX} - The `DeleteButtonCard` component is being returned.
 */
export const DeleteButtonCard = () => {
  const {
    closeDialog,
    data,
    deleteCardHandler,
    dialog,
    editMode,
    isLoading,
    sendDialog,
  } = useSetup();
  return (
    <>
      <ContinueDialog
        closeDialog={closeDialog}
        send={sendDialog}
        isOpen={dialog}
        textHeader={'Удалить версию объекта?'}
        textContinueButton={'Удалить'}
      />
      {!editMode && data?.delete ? (
        <DeleteButton loading={isLoading} onClick={deleteCardHandler} />
      ) : null}
    </>
  );
};
