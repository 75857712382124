import './SearchForm.scss';

import * as ordersActions from 'app/actions/orders';
import { ProgressStatusFF } from 'app/components/card/ogh/OrdersTab/components/ProgressStatusFF';
import WorkEndDate, {
  WORK_END_DATE,
} from 'app/components/card/ogh/OrdersTab/components/WorkEndDate';
import WorkStartDate, {
  WORK_START_DATE,
} from 'app/components/card/ogh/OrdersTab/components/WorkStartDate';
import dateFormatService from 'core/services/dateFormatService';
import { Button } from 'core/uiKit/components';
import PropTypes from 'prop-types';
/* eslint-disable */
import React, { useMemo } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const defaultProps = {
  dark: true,
};

const formStyles = {
  background: 'white',
};

const SearchForm = (props) => {
  const { onSubmit, initialValues } = props;

  
  const initialValuesMemo = useMemo(() => initialValues, []);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValuesMemo}
      render={({ handleSubmit, form }) => {
        return (
          <form
            onSubmit={handleSubmit}
            style={formStyles}
            className={
              'ods-head-form pb-3 ods-header-search-form order-tab__search-form'
            }
          >
            <div className={'order-tab__search-form__container'}>
              <WorkStartDate />
              <WorkEndDate />
              <ProgressStatusFF />
            </div>

            <div className={'order-tab__search-form__container'}>
              <Button variant={'contained'} color={'primary'} type="submit">
                Поиск
              </Button>
              <Button
                color={'primary'}
                id={'clear-form'}
                variant={'outlined'}
                onClick={form.reset}
              >
                Сбросить
              </Button>
            </div>
          </form>
        );
      }}
    />
  );
};

SearchForm.propTypes = propTypes;
SearchForm.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  const { card, tree } = state;
  const cardSelect = tree?.object || card?.object;
  return {
    initialValues: {
      [WORK_START_DATE]: dateFormatService.parseDate(cardSelect.start_date),
      [WORK_END_DATE]: dateFormatService.parseDate(cardSelect.end_date),
      objectId: cardSelect.record_id,
    },
  };
};

function mapDispatchToProps(dispatch) {
  return {
    onSubmit: function (values) {
      const parseDate = {
        [WORK_START_DATE]: dateFormatService.formatDate(
          values[WORK_START_DATE],
        ),
        [WORK_END_DATE]: dateFormatService.formatDate(values[WORK_END_DATE]),
      };
      dispatch(ordersActions.onSubmit({ ...values, ...parseDate }));
    },
  };
}

 
export default connect(mapStateToProps, mapDispatchToProps)(SearchForm);
