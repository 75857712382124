import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';

 
/**
 * Получение имени объекта по типу.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.cell - Параметры колонки.
 * @param {number} props.cell.value - Id объекта ОГХ.
 * @returns {JSX.Element} - JSX.
 * @example
 * {
 *   Cell: OghTypeNameCell,
 *  }
 */
export const OghTypeNameCell = ({
  cell: { value } = {},
}) => {
  const dict = 'oghTypes';
  const { data = [] } = useGetDictionary(dict);

  const { name = '' } =
    data.find(({ id }) => id === value) || {};

  return <span>{name}</span>;
};
