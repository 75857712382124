/* eslint-disable */
import React from 'react';
import { NumberParam } from 'use-query-params';

import {
  CAR_EQUIPMENT_TYPE_ID_NAME,
  CarEquipmentTypeIdFF,
} from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
 
/**
 * Тип навесного оборудования
 * car_equipment_type_id
 */
 
export const CarEquipmentTypeIdFilter = (props) => (
  <CarEquipmentTypeIdFF {...props} className={filterInput} />
);

 
export { CAR_EQUIPMENT_TYPE_ID_NAME };

 
export const carEquipmentTypeIdParams = {
  [CAR_EQUIPMENT_TYPE_ID_NAME]: NumberParam,
};
