import _ from 'lodash';

import * as types from '../constants/actionTypes';

/* eslint-disable */
export default function calcAttributes(state = {}, action) {
  switch (action.type) {
    case types.RECEIVE_CARD: {
      return {
        ...state,
        ...(_.get(action, 'object.calc_attribute', {}) || {}),
      };
    }
    case types.CHANGE_TREE_JOURNAL: {
      return {
        ...state,
        ...(_.get(action, 'calc_attribute', {}) || {}),
      };
    }
    case types.CHANGE_CALC_ATTRIBUTES: {
      return {
        ...state,
        ...(_.get(action, 'calc_attribute', {}) || {}),
      };
    }
    case types.RECEIVE_ADD_OBJECT_TO_TREE: {
      return {
        ...state,
        ...(_.get(action, 'calc_attribute', {}) || {}),
      };
    }
    case types.LOCATION_CHANGE: {
      return {};
    }
    case types.CLEAR_CARD: {
      return {};
    }
    default:
      return state;
  }
}
