import { switchServisMap } from 'core/utils/map/switchServisMap';

import { useActionsMapDTW } from './useActionsMap.DTW';
import { useActionsMapEgip } from './useActionsMap.Egip';

/**
 * Хук SwitchMap.
 *
 * @returns {void}
 */
export const useActionsMap = switchServisMap({
  dtw: useActionsMapDTW,
  egip: useActionsMapEgip,
});
