/**
 * Функция checkField.
 *
 * @param {object} mandatoryFields - Объект из костанты полученный по ключу typeId.
 * @param {string} fieldName - Имя поля.
 * @param {boolean} required - Флаг обязательное поле или нет.
 * @returns {boolean | undefined} Возвращает флаг если поле есть.
 * @example -------
 * checkField(mandatoryFields, fieldName, required)
 */
export const checkField = (mandatoryFields, fieldName, required) => {
  return required && mandatoryFields && mandatoryFields.includes(fieldName);
};
