/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { dialogType } from 'app/constants/dialogTypes';
import { hideDialog } from 'app/actions/dialog';

import DialogAlert from './DialogAlert';
import DialogAction from './DialogAction';
import DialogError from './DialogError';

const dialogComponent = {
  [dialogType.NOTIFICATION]: DialogAlert,
  [dialogType.ERROR_NOTIFICATION]: DialogError,
  [dialogType.CONFIRMATION]: DialogAction,
};

const propTypes = {
  hideDialog: PropTypes.func,
  props: PropTypes.object,
  type: PropTypes.string,
};

const DialogRoot = (propsS) => {
  const getDialogProps = () => {
    const { type, props } = propsS;
    switch (type) {
      case dialogType.NOTIFICATION: {
        return {
          ...props,
          onAfterHiding: handleHide,
        };
      }
      case dialogType.CONFIRMATION: {
        return {
          ...props,
          onOk: handleOk,
          onCancel: handleHide,
        };
      }
      case dialogType.ERROR_NOTIFICATION: {
        return {
          ...props,
          onAfterHiding: props.onAfterHiding || handleHide,
        };
      }
      default: {
        return props;
      }
    }
  };

  const handleOk = () => {
    const { props } = propsS;
    props.onOk();
    handleHide();
  };

  const handleHide = () => {
    const { hideDialog } = propsS;
    hideDialog();
  };

  const { type, props: { children = null } = {} } = propsS;

  if (!type) {
    return null;
  }
  const DialogComponent = dialogComponent[type];

  return <DialogComponent {...getDialogProps()}>{children}</DialogComponent>;
};

DialogRoot.propTypes = propTypes;

const mapStateToProps = (state) => state.dialog || {};

const mapDispatchToProps = {
  hideDialog,
};

 
export default connect(mapStateToProps, mapDispatchToProps)(DialogRoot);
