import './CardContainer.scss';

import React from 'react';

/**
 * Компонент задает отступы для карточки.
 *
 * @param props - Пропсы.
 * @param props.children - Дети.
 * @returns
 */
export const CardContainer = ({ children }: { children: React.ReactNode }) => {
  return <div className={'card-ogh-container'}>{children}</div>;
};
