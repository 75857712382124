import { FilterDialogEditButton } from 'app/pages/cardsOgh/components/RightPanelCard/components/DialogEditButton/Filter.DialogEditButton/Filter.DialogEditButton';
import { TableDelete } from 'app/pages/cardsOgh/components/tables/TableDelete';
import { Button } from 'core/uiKit/components';
import React, { Dispatch, SetStateAction } from 'react';

import { ExcludeButton } from './ExcludeButton';
import { getPermissionNoCalc } from './helpers/getPermissionNoCalc';
import { ListDataType } from './listDataType';

type BodyDialogEditButtonType = {
  account: () => void;
  count: number;
  handleRegistryFormSubmit: (formValues: object) => void;
  isLoading: boolean;
  isLoadingSend: boolean;
  listData: ListDataType;
  notAccount: () => void;
  removeRow: () => void;
  selectRowsTableDelete: number[];
  setSelectRowsTableDelete: Dispatch<SetStateAction<number[]>>;
  table: unknown[];
};

/**
 * Компонент  BodyDialogEditButton.
 *
 * @param {object} props - Пропсы.
 * @param {Function} props.handleRegistryFormSubmit - Функция сабмита фильтрации.
 * @param {Array<number>} props.selectRowsTableDelete - Выбранные ячейки таблицы.
 * @param {Function} props.notAccount - Функция не учитывать.
 * @param {Function} props.account - Функция учитывать.
 * @param {Function} props.removeRow - Удалить колонку.
 * @param {ListDataType} props.listData - Данные из дерева.
 * @param {number} props.count - Счетчик.
 * @param {Array} props.table - Данные таблицы.
 * @param {boolean} props.isLoadingSend - Флаг загрузки при клике 'учитывать' 'не учитывать'.
 * @param {boolean} props.isLoading - Флаг загрузки таблицы.
 * @param {Function} props.setSelectRowsTableDelete - Функция выбора ячейки таблицы.
 * @returns {JSX.Element}
 */
export const BodyDialogEditButton = ({
  account,
  count,
  handleRegistryFormSubmit,
  isLoading,
  isLoadingSend,
  listData,
  notAccount,
  removeRow,
  selectRowsTableDelete,
  setSelectRowsTableDelete,
  table,
}: BodyDialogEditButtonType) => {
  const hasPermissionNoCalc = getPermissionNoCalc(listData.typeId);
  return (
    <>
      <FilterDialogEditButton
        listData={listData}
        handleRegistryFormSubmit={handleRegistryFormSubmit}
      />
      <div
        style={{
          display: 'flex',
          gap: 10,
          marginBottom: 20,
          marginTop: 25,
        }}
      >
        {hasPermissionNoCalc && (
          <>
            <Button
              disabled={!selectRowsTableDelete.length}
              onClick={notAccount}
              color={'warning'}
              loading={isLoadingSend}
              variant={'contained'}
            >
              Не учитывать
            </Button>
            <Button
              disabled={!selectRowsTableDelete.length}
              onClick={account}
              loading={isLoadingSend}
              color={'success'}
              variant={'contained'}
            >
              Учитывать
            </Button>
          </>
        )}

        <ExcludeButton
          disabled={!selectRowsTableDelete.length}
          onClick={removeRow}
          typeCode={listData.typeCode}
        />
      </div>
      <TableDelete
        title={listData.title}
        dataTable={table}
        count={count}
        isLoading={isLoading}
        setSelectRowsTableDelete={setSelectRowsTableDelete}
        typeId={listData.typeId}
      />
    </>
  );
};
