import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';

import { fetchTree } from '../../api/fetchTree';

/**
 * Хук получения дерева.
 *
 * @returns - Флаги и функции.
 */
export const useGetTreePanelData = () => {
  const currentCard = useGetCurrentCard();

  const {
    logicalStateId,
    pathId = null,
    recordId,
    rootId,
    treeName,
    typeId,
    type_id,
  } = useGetParentCard();

  const { data, isLoading, isSuccess, refetch } = useQueryAdaptor(
    [
      'getTree',
      {
        // @ts-ignore
        logicalStateId: logicalStateId || currentCard.logicalStateId,
        pathId,
        rootId: recordId,
        typeId,
      },
    ],
    // @ts-ignore
    fetchTree,
    {
      enabled: !!typeId,
    },
  );

  return {
    isLoading,
    isSuccessTree: isSuccess,
    parentId: recordId,
    // @ts-ignore
    removeTree: typeId && recordId ? refetch : (_) => _,
    treeData: {
      id: rootId,
      items: data,
      open: true,
      recordId: recordId,
      title: treeName,
      typeId: type_id,
    },
  };
};
