const dialogTitleWrapStyles = {
  position: 'relative',
};

// Required CSS-property for the correct display of the Select dropdown list
const overflowStyle = {
  overflowY: 'auto',
};

 
export { dialogTitleWrapStyles, overflowStyle };
