import {
  FUNCTIONALITY,
  LITTLE_FORM,
  PPI,
  RELIEF,
} from 'app/constants/oghTypes';
import { BUSH, NUMBERS, PIECES } from 'app/constants/unitCodes';


/* eslint-disable */
export default {
  [RELIEF]: [PIECES],
  [PPI]: [PIECES],
  [FUNCTIONALITY]: [PIECES],
  [LITTLE_FORM]: [NUMBERS, PIECES, BUSH],
};
