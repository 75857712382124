import { Table } from 'core/uiKit/components/tables/Table';
import React from 'react';

import { TableOghChildrenProps } from '../../types';
import { getColumnsWaterBuilding } from './columns.WaterBuilding';
import { useSetupTableWaterBuilding } from './useSetup.Table.WaterBuilding';

/**
 * Компонент таблицы __Водных сооружений__.
 *
 * @param props - Пропсы.
 * @returns
 */
export const TableWaterBuilding = (props: TableOghChildrenProps) => {
  const { table, isLoading, count } = useSetupTableWaterBuilding(props);

  return (
    <Table
      columns={getColumnsWaterBuilding()}
      data={table || []}
      isLoading={isLoading}
      total={count}
    />
  );
};
