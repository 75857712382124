import {
  Body,
  DialogCreateSimple,
  Header,
  Main,
  RegistryToolbar,
} from 'app/pages/components';
import { TreePanelYard } from 'app/pages/registry/ogh/yard/TreePanelYard';
import { useGetOghTypes } from 'core/utils/hooks';
import React from 'react';

import { FilterDts } from './Filter.Dts';
import { PrintButtonDts } from './PrintButton.Dts';
import { TableDts } from './Table.Dts';

/**
 * Реестр __Дорожно-тропиночные сети__.
 *
 * @param {object} props - Пропсы.
 * @param {Array} props.tree - Дерево.
 * @param {number} props.parentTypeId - TypeId родителя.
 * @returns {JSX.Element}
 */
const Dts = ({ tree, parentTypeId }) => {
  const oghType = useGetOghTypes('dts');
  const typeId = oghType.id;

  return (
    <Main>
      <Header
        Filter={() => <FilterDts typeId={typeId} parentTypeId={parentTypeId} />}
      />
      <Body>
        <RegistryToolbar showToggle={true} title={oghType.name}>
          <PrintButtonDts typeId={typeId} parentTypeId={parentTypeId} />
        </RegistryToolbar>

        <div className={'d-flex h-100 overflow-hidden-x'}>
          <TreePanelYard tree={tree} />
          <TableDts typeId={typeId} parentTypeId={parentTypeId} />
        </div>
        <DialogCreateSimple typeId={typeId} />
      </Body>
    </Main>
  );
};

export default Dts;
