/* eslint-disable */
import React from 'react';
import { ArrayParam } from 'use-query-params';

import { ChangeTypeFF, CHANGE_TYPE_NAME } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
export const ChangeTypeFilter = (otherProps) => (
  <ChangeTypeFF {...otherProps} className={filterInput} />
);

 
export { CHANGE_TYPE_NAME };

 
export const changeTypeParams = {
  [CHANGE_TYPE_NAME]: ArrayParam,
};
