/* eslint-disable */
import React from 'react';

import getDisplayName from './getDisplayName';

function withReduxForm(Component) {
  function withDarkTheme(props) {
    return <Component dark={true} {...props} />;
  }

  withDarkTheme.displayName = `withDarkTheme(${getDisplayName(Component)})`;

  return withDarkTheme;
}

 
export default withReduxForm;
