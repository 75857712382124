import React, { useEffect } from 'react';
/* eslint-disable  */

/**
 *
 * @param root0
 */
const LeftPanelContent = ({ ...props }) => {
  const {
    children: {
      props: { card, redrawObject },
    },
  } = props;

  useEffect(() => {
    if (typeof fn === 'function') {
      redrawObject(card?.record_id);
    }
  }, [card?.record_id]);

  return <div className="ods-core-left-panel__content">{props.children}</div>;
};

export default LeftPanelContent;
