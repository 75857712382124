/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { downloadFile } from 'app/actions/fileDownloadActions';
import { connect } from 'react-redux';

const propTypes = {
  actionPath: PropTypes.string.isRequired,
  method: PropTypes.string,
  queryParams: PropTypes.object,
  downloadFile: PropTypes.func,
  onDownloadComplete: PropTypes.func,
};

const defaultProps = {
  method: 'GET',
};

class FileDownload extends React.Component {
  componentDidMount() {
    this.handleDownload();
  }

  getFormInputs() {
    const { queryParams } = this.props;
    let result;

    if (queryParams === undefined) {
      result = null;
    } else {
      result = map(queryParams, (value, name) => (
        <input key={value} name={name} type="hidden" value={value} />
      ));
    }

    return result;
  }

  handleDownload = async () => {
    const { actionPath, queryParams, downloadFile, onDownloadComplete } =
      this.props;

    this.form && (await downloadFile(actionPath, queryParams));

    onDownloadComplete();
  };

  render() {
    const { actionPath, method } = this.props;

    return (
      <form
        action={actionPath}
        className="hidden"
        method={method}
        ref={(form) => (this.form = form)}
      >
        {this.getFormInputs()}
      </form>
    );
  }
}

FileDownload.propTypes = propTypes;
FileDownload.defaultProps = defaultProps;

 
export default connect(null, { downloadFile })(FileDownload);
