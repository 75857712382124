/* eslint-disable  */
import { getValidator } from 'core/form/finalForm/utils/validate';

/**
 *
 * @param values
 */
export const validateDialogChangePassword = (values) => {
  const passMinSize = 6;
  const {
    setMinLengthError,
    setRequired,
    setEqualRequired,
    hasErrors,
    getErrors,
  } = getValidator(values);
  setRequired('password');
  setRequired('oldPassword');
  setEqualRequired(
    'password',
    'confirmPassword',
    'Пароль и повтор пароля не совпадают',
  );
  setMinLengthError('password', passMinSize);
  return hasErrors() ? getErrors() : null;
};
