import { getIdFromLink } from 'app/utils/card/checkGeometry/helpers/getIdFromLink';
import PropTypes from 'prop-types';
import React from 'react';
import { CSVLink } from 'react-csv';

const propTypes = {
  fileName: PropTypes.string.isRequired,
  objects: PropTypes.array.isRequired,
};

/**
 * Компонент скачивания файла.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.objects - Объект.
 * @param {string} props.fileName - Полное имя .
 * @returns {JSX.Element}
 */
function GeometryCSVLink({ objects, fileName }) {
  const transformedObjects = objects.map(transformAll);

  return (
    <CSVLink data={transformedObjects} filename={fileName} separator={'$'}>
      Скачать геометрию объектов
    </CSVLink>
  );
}

// объектов геометрии может быть больше чем 1 а выводить надо все

/**
 * Функция подготовки файлов для отображения в файле.
 *
 * @param {object} object - Объект.
 * @returns {Omit<*, "geometry">}
 */
const transformAll = (object) => {
  const { geometry, ...transformedObjects } = object;

  transformedObjects.id_ogh = getIdFromLink(transformedObjects.id_ogh);
  transformedObjects.geo_point = geometry.points
    ? JSON.stringify(geometry.points)
    : '';
  transformedObjects.geo_line = geometry.lines
    ? JSON.stringify(geometry.lines)
    : '';
  transformedObjects.geo_poly = geometry.polygons
    ? JSON.stringify(geometry.polygons)
    : '';

  return transformedObjects;
};

GeometryCSVLink.propTypes = propTypes;

export default GeometryCSVLink;
