import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
/* eslint-disable */
import { NumberFieldFF } from 'core/form/finalForm/fields/default/NumberField.FF';

import { requiredFields } from './validate';

/**
 *
 * @param root0
 * @param root0.map
 */
export const NumberFieldFFWrapper = ({ map }) => {
  requiredFields.push(map.accessor);
  const isCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();

  return (
    <NumberFieldFF
      label={map.Header}
      name={map.accessor}
      defaultValue={0}
      disabled={isCurrentObjectIsExternalSystem}
      required={true}
      decimalScale={map.decimalScale}
    />
  );
};
