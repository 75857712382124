/* eslint-disable */
import React from 'react';

import { filterInput } from 'core/form/filterForm/components/filterInputsClasses';
import { GrbsShortNameFF, GRBS_SHORT_NAME } from 'core/form/finalForm/fields';
import { StringParam } from 'use-query-params';

 
export const GrbsShortNameFilter = () => (
  <GrbsShortNameFF className={filterInput} />
);

 
export { GRBS_SHORT_NAME };

 
export const grbsShortNameParams = {
  [GRBS_SHORT_NAME]: StringParam,
};
