import { REQUIRED_FIELD_MESSAGE } from 'app/constants/messages';


/* eslint-disable */
export default function (formValues) {
  const { owner_id, customer_id } = formValues;
  let result = {};
  if (owner_id === null || !owner_id.id) {
    result['owner_id'] = REQUIRED_FIELD_MESSAGE;
  }

  if (customer_id === null || !customer_id.id) {
    result['customer_id'] = REQUIRED_FIELD_MESSAGE;
  }

  return Object.keys(result).length > 0 ? result : null;
}
