import { CoatingTypeIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/CoatingTypeId.FF';
import React from 'react';

import { useSetupCoatingTypeIdFFField } from './useSetup.CoatingTypeIdFF.Field';

/**
 * Field FF __Вид покрытия (уточнение)__.
 *
 * `name: coating_type_id`.
 *
 * @returns {JSX.Element}
 */
export const CoatingTypeIdFFField = () => {
  const { coatingGroupIdValue, disabled, filter, required } =
    useSetupCoatingTypeIdFFField();

  return (
    <CoatingTypeIdFF
      filter={filter}
      withAll={false}
      disabled={!(disabled && coatingGroupIdValue)}
      required={required}
    />
  );
};
