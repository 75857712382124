/* eslint-disable */
import React from 'react';
import { has, flow, get, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions as formActions, Control } from 'react-redux-form';

import DialogAction from '../../common/DialogAction';

import Select from 'core/newComponents/Select';

import { uploadFiles } from 'app/actions/uploadActions';
import Form from 'app/components/common/KeyForm';
import DndFileUpload from 'app/components/common/DndFileUpload';
import { renderCustomTextField } from 'app/components/card/common/grid/index';

import KeyboardDateTimePicker from 'core/uiKit/inputs/datePickers/KeyboardDateTimePicker';

import withCheckBeforeRenderDialog from 'core/hocs/withCheckBeforeRenderDialog';

import { EDIT_TREE, EDIT_CAR } from 'app/constants/techRequestTypes';
import {
  EMPTY_CAR_TYPE_ID,
  REQUIRED_FIELD_MESSAGE,
} from 'app/constants/messages';

import getDict from 'app/selectors/buildDictSelector';
import getFormValues from 'app/selectors/form/getFormValues';
import { currentDateLoshak } from 'app/utils/dateUtils';

const propTypes = {
  carFuncTypeId: PropTypes.number,
  edit: PropTypes.bool,
  form: PropTypes.object,
  formChange: PropTypes.func,
  formSubmit: PropTypes.func,
  formValidity: PropTypes.object,
  name: PropTypes.string,
  noFiles: PropTypes.bool,
  reasonDefault: PropTypes.string,
  showCondition: PropTypes.bool,
  technicRequestReason: PropTypes.arrayOf(PropTypes.object),
  technicRequestType: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

class DialogChangeCar extends React.Component {
  state = {
    isCarTypeIdEmpty: false,
  };

  componentDidMount() {
    const { props } = this;

    props.formChange({
      key: 'dateTime',
      value: currentDateLoshak(),
    });

    props.formChange({
      key: 'comment',
      value: '',
    });

    props.formChange({
      key: 'requestType',
      value: '',
    });

    props.formChange({
      key: 'reason',
      value: this.getInitialReason(),
    });
  }

  componentDidUpdate(prevProps) {
    const { formChange = (_) => _, technicRequestReason } = this.props;
    if (this.props.form.requestType !== prevProps.form.requestType) {
      return this.setState({ isCarTypeIdEmpty: this.checkOnCarTypeId() });
    }
    if (this.props?.form?.reason !== prevProps?.form?.reason) {
      const reasonCode = technicRequestReason.find(
        (item) => item.id === this.props?.form?.reason,
      );
      formChange({ key: 'reasonCode', value: reasonCode?.code });
    }
  }

  onCancel = () => {
    this.removeAllFiles();
    this.props.onCancel();
  };

  onSubmit = (values) => {
    const { formChange, onSubmit } = this.props;
    const files = values.files;
    const promises = uploadFiles(files);
    for (let i = 0; i < files.length; ++i) {
      promises[i].then(() => {
        const file = files[i];
        this.removeFile(file);
      });
    }
    const whenCompleted = Promise.all(promises);
    whenCompleted.then((responses) => {
      const fileIds = responses.map((i) => i.fileId);
      formChange({
        key: 'fileIds',
        value: fileIds,
      });
      onSubmit(values.dateTime, values.requestType, values.reasonCode);
    });
  };

  getInitialReason() {
    const { props } = this;
    let result;
    if (has(props, 'reasonDefault')) {
      result = props.technicRequestReason.find(
        (r) => r.code === props.reasonDefault,
      ).id;
    } else {
      result = null;
    }
    return result;
  }

  checkOnCarTypeId = () => {
    const { carFuncTypeId, form } = this.props;

    return !carFuncTypeId && form.requestType === EDIT_TREE;
  };

  removeAllFiles = () => {
    const { formChange } = this.props;
    formChange({
      key: 'files',
      value: [],
    });
  };

  removeFile = (file) => {
    const { form, formChange } = this.props;
    const value = form.files.filter((f) => f !== file);
    formChange({
      key: 'files',
      value,
    });
  };

  checkOnErrorText = () => {
    const { formValidity } = this.props;
    const { isCarTypeIdEmpty } = this.state;
    const emptyFieldMessage =
      formValidity.requestType === false && REQUIRED_FIELD_MESSAGE;
    const emptyCarTypeIdMessage = isCarTypeIdEmpty && EMPTY_CAR_TYPE_ID;

    return emptyFieldMessage || emptyCarTypeIdMessage || '';
  };

  render() {
    const {
      form,
      formChange,
      formValidity,
      technicRequestReason,
      reasonDefault,
      showCondition,
      formSubmit,
      noFiles,
      name,
      title,
    } = this.props;

    return (
      <DialogAction
        className="Dialog-Change-Car"
        isDisabledOk={form.files && form.files.length === 0}
        showCondition={showCondition}
        title={title}
        onBeforeOk={formSubmit}
        onCancel={this.onCancel}
      >
        <Form
          model="rrf.changeCar"
          validators={{
            '': {
              files: function ({ files }) {
                return noFiles === true || (files && files.length > 0);
              },
              reason: function ({ reason }) {
                return reason !== null && !isNaN(reason);
              },
              requestType: ({ requestType }) => {
                return !this.props.edit || !!requestType;
              },
            },
          }}
          onSubmit={this.onSubmit}
        >
          {this.props.edit ? (
            <Select
              errorText={this.checkOnErrorText()}
              label="Тип запроса"
              options={[
                {
                  id: EDIT_CAR,
                  name: 'Редактирование техники',
                },
                {
                  id: EDIT_TREE,
                  name: 'Изменение связи с навесным оборудованием',
                },
              ]}
              value={form.requestType}
              onChange={(value) => {
                formChange({
                  key: 'requestType',
                  value,
                });
                formChange({
                  key: 'reason',
                  value: null,
                });
              }}
            />
          ) : (
            <TextField
              disabled={true}
              fullWidth={true}
              label="Тип запроса"
              value={name}
            />
          )}
          <div style={{ marginTop: 16, marginBottom: 16 }}>
            <Control
              component={Select}
              controlProps={{
                label: 'Причина изменения',
                options: (technicRequestReason || []).filter((r) => {
                  if (!form.requestType) {
                    return true;
                  }
                  const attribute = r.attribute;
                  const types = (
                    get(attribute, 'technic_request_type_id') || []
                  ).map((e) => e.technic_request_type);
                  const t = (this.props.technicRequestType || []).find(
                    (e) => e.code === form.requestType,
                  );
                  return types.includes(get(t, 'id'));
                }),
                errorText:
                  (formValidity.reason === false && REQUIRED_FIELD_MESSAGE) ||
                  '',
                disabled: !isUndefined(reasonDefault),
              }}
              model=".reason"
            />
          </div>
          <div className="mb-4">
            <Control
              component={DateTimePickerField}
              controlProps={{
                label: 'Дата применения изменений',
                minDate: getMinDate(),
                disabledMinutes: true,
              }}
              model=".dateTime"
            />
          </div>
          <div className="mb-4">
            <Control
              component={TextField}
              controlProps={{
                fullWidth: true,
                label: 'Комментарий',
              }}
              model=".comment"
            />
          </div>
          {noFiles !== true && (
            <DndFileUpload
              add={(files) => {
                const value = [...form.files, ...files];
                formChange({
                  key: 'files',
                  value,
                });
              }}
              errorText={
                formValidity.files === false
                  ? 'Должен быть прикреплен хотя бы один файл'
                  : ''
              }
              files={form.files}
              remove={this.removeFile}
              text="Переместите файлы-основания или нажмите для выбора"
            />
          )}
        </Form>
      </DialogAction>
    );
  }
}

DialogChangeCar.propTypes = propTypes;

function DateTimePickerField({ value, name, error, onChange, ...props }) {
  return (
    <KeyboardDateTimePicker
      meta={{ error: error || null }}
      name={name}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
}

function TextField({ value, name, error, onChange, ...props }) {
  return renderCustomTextField({
    input: {
      value,
      name,
      onChange,
    },
    meta: {
      error: error || null,
    },
    ...props,
  });
}

const getMinDate = () => {
  const minDate = new Date();
  minDate.setHours(minDate.getHours() + 1);
  return minDate;
};

const getTechnicRequestType = getDict('technicRequestType');
const getTechnicRequestReason = getDict('technicRequestReason');

const mapStateToProps = (state) => ({
  form: state.rrf.changeCar,
  formValidity: state.rrf.forms.changeCar.$form.validity,
  technicRequestReason: getTechnicRequestReason(state),
  technicRequestType: getTechnicRequestType(state),
  carFuncTypeId: getFormValues(state).car_func_type_id,
});

const mapDispatchToProps = (dispatch) => ({
  formChange: (values) =>
    dispatch(formActions.change(`rrf.changeCar.${values.key}`, values.value)),
  formSubmit: () => dispatch(formActions.submit('rrf.changeCar')),
});

 
export default flow(
  withCheckBeforeRenderDialog,
  connect(mapStateToProps, mapDispatchToProps),
)(DialogChangeCar);
