/* eslint-disable  */
import { useUser } from 'app/pages/providers';
import { useGetParamsUrl } from 'core/utils/hooks/useGetParamsUrl';

import { RoutingBridgeCreateOgh } from './RoutingBridgeCreateOgh';

/**
 *
 * @param props
 */
const RoutingBridgeCreateOghWithPermission = (props) => {
  const { oghType } = useGetParamsUrl();
  const { hasPermission } = useUser();

  const permission = hasPermission(`create_ogh_${oghType?.code}`);

  // todo доступы вынести в сами карты и использовать RoutePermission
  return permission ? <RoutingBridgeCreateOgh {...props} /> : null;
};

export default RoutingBridgeCreateOghWithPermission;
