import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import isMandatory from 'app/utils/card/isMandatory';
import { NumberFieldRF } from 'core/form/reduxForm/fields';
import React from 'react';

export const AREA_NAME = 'area';

/**
 * Number Field RF __Площадь, кв.м__.
 *
 * @param props - Пропсы.
 * @param props.label - Лейбл.
 * @returns
 */
export const AreaField = (props: { label?: string }) => {
  const { typeId } = useGetCurrentCard();
  const { editMode } = useMode();
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();
  return (
    <NumberFieldRF
      decimalScale={2}
      name={AREA_NAME}
      label={props.label || 'Площадь, кв.м'}
      disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
      required={isMandatory(typeId, AREA_NAME, editMode)}
    />
  );
};
