/* eslint-disable */
import React from 'react';

import { PrintButtonOgh } from 'app/pages/components';

import { useFilterQueryParamsYard } from './Filter.Ozn';

/* eslint-disable */
export const PrintButtonOzn = ({ typeId }) => {
  const { prepareFilters } = useFilterQueryParamsYard();

  return <PrintButtonOgh {...{ typeId, prepareFilters }} />;
};
