import { useSelector } from 'react-redux';

/**
 * Хук получающий выбранное значение поля group_id.
 *
 * @returns {number | undefined}
 */
export const useGetGroupId = () => {
  return useSelector((state) => state?.form?.editorCard?.values?.group_id);
};
