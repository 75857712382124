/* eslint-disable */
import React from 'react';
import { NumberParam } from 'use-query-params';
import { find } from 'lodash';

import { OoptCategoryFF, OOPT_CATEGORY_NAME } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

const level = {
  FEDERAL: 1,
  REGIONAL: 2,
};

 
 
/**
 *     field: 'ooptCategory',
 *     label: 'Категория ООПТ',
 */
 
export const OoptCategoryFilter = (props) => {
  const { formValue: { ooptMeaning } = {} } = props;

  // todo: переделать в более читабельный вид
  const filter = (value) => {
    let filteredOoptCategories = value;
    if (ooptMeaning === level.FEDERAL || ooptMeaning === level.REGIONAL) {
      filteredOoptCategories = filter(value, (it) => {
        return find(it.oopt_meaning_list, { oopt_meaning_id: ooptMeaning });
      });
    }
    return filteredOoptCategories;
  };

  return <OoptCategoryFF className={filterInput} filter={filter} />;
};

 
export const ooptCategoryParams = {
  [OOPT_CATEGORY_NAME]: NumberParam,
};
