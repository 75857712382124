/* eslint-disable */
import React from 'react';
import { NumberParam } from 'use-query-params';

import { ListTypeIdFF, LIST_TYPE_ID_NAME } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
export const ListTypeIdFilter = (props) => (
  <ListTypeIdFF {...props} className={filterInput} />
);

 
export { LIST_TYPE_ID_NAME };

 
export const listTypeIdParams = {
  [LIST_TYPE_ID_NAME]: NumberParam,
};
