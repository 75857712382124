import * as authActions from 'app/actions/auth';
/* eslint-disable  */
import { preloadData } from 'app/actions/preload';
import { toast } from 'core/uiKit/components/Toast';
import { handleActions } from 'redux-actions';

export default handleActions(
  {
    /**
     *
     * @param state
     */
    [`${authActions.apiPostForgotPassword}_PENDING`]: (state) => {
      state = {
        ...state,
        error: false,
        isPending: true,
      };
      return state;
    },

    /**
     *
     * @param state
     */
    [`${authActions.apiPostForgotPassword}_REJECTED`]: (state) => {
      state = {
        ...state,
        error: true,
        isPending: false,
      };
      return state;
    },

    /**
     *
     * @param state
     */
    [`${authActions.apiPostForgotPassword}_FULFILLED`]: (state) => {
      state = {
        ...state,
        error: false,
        isPending: false,
      };
      return state;
    },

    /**
     *
     * @param state
     * @param action
     */
    [`${authActions.apiPostResetPassword}_REJECTED`]: (state, action) => {
      toast.error(action.payload.message ?? 'Неизвестная ошибка');
      state = {
        ...state,
        error: true,
        isPending: false,
      };
      return state;
    },

    /**
     *
     * @param state
     */
    [`${authActions.apiPostResetPassword}_FULFILLED`]: (state) => {
      state = {
        ...state,
        error: '',
        isPending: false,
      };
      return state;
    },

    /**
     *
     * @param state
     */
    [`${authActions.apiPostResetPassword}_PENDING`]: (state) => {
      state = {
        ...state,
        error: '',
        isPending: true,
      };
      return state;
    },

    /**
     *
     * @param state
     */
    [`${authActions.apiCheckToken}_REJECTED`]: (state) => {
      state = {
        ...state,
        error: true,
        isPending: false,
        tokenValid: false,
      };
      return state;
    },

    /**
     *
     * @param state
     */
    [`${authActions.apiCheckToken}_FULFILLED`]: (state) => {
      state = {
        ...state,
        error: false,
        isPending: false,
        tokenValid: true,
      };
      return state;
    },

    /**
     *
     * @param state
     */
    [`${authActions.apiCheckToken}_PENDING`]: (state) => {
      state = {
        ...state,
        error: false,
        isPending: true,
        tokenValid: false,
      };
      return state;
    },

    /**
     *
     * @param state
     */
    [`${preloadData}_PENDING`]: (state) => {
      state = {
        ...state,
        error: '',
        isPending: true,
        loggedIn: false,
      };
      return state;
    },

    /**
     *
     * @param state
     * @param action
     */
    [`${preloadData}_REJECTED`]: (state, action) => {
      let error;
      try {
        error = JSON.parse(action.payload.message);
      } catch (err) {
        error = action.payload.message;
      }
      state = {
        ...state,
        error: error,
        isPending: false,
      };
      return state;
    },

    /**
     *
     * @param state
     * @param action
     */
    [`${preloadData}_FULFILLED`]: (state, action) => {
      const { userData: user } = action.payload;

      state = {
        ...state,
        error: '',
        isPending: false,
        loggedIn: true,
        user,
      };
      return state;
    },

    /**
     *
     * @param state
     */
    [authActions.logout]: (state) => ({
      ...state,
      loggedIn: false,
    }),
  },
  { loggedIn: false, user: { privileges: [] } },
);
