/* eslint-disable */
export const equipmentStyle = {
  display: 'flex',
  alignItems: 'center',
};

 
export const h2Style = {
  margin: 0,
};

/* eslint-disable */
export const wrapperStyle = {
  paddingLeft: 57,
  marginTop: -33,
};

/* eslint-disable */
export const tableStyles = {
  marginTop: 30,
  marginLeft: 30,
  width: '40%',
};

/* eslint-disable */
export const headerColumnStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '2.5rem',
};

 
export const cellStyles = {
  flex: '0 0 auto',
  textAlign: 'center',
};
