import {
  BASE_PGM,
  BIN,
  BOARD_STONE,
  BRIDGE,
  BUILDING,
  CARRIAGEWAY,
  CONTAINER,
  DTS,
  EMERGENCY_PARKING,
  FENCING,
  FLOWERS_GARDEN,
  FUNCTIONALITY,
  IMPROVEMENT_OBJECT,
  LAMPPOSTS,
  LAWN,
  LITTLE_FORM,
  MAF,
  MANHOLES,
  MARGIN,
  ODH,
  OOPT,
  OTHER_ENGIN_CONSTRUCT,
  OTHER_FLAT_BUILDING,
  OTHER_OKS,
  OTHER_TECH_PLACE,
  OZN,
  PLANAR_STRUCTURE,
  PPI,
  RELIEF,
  ROAD_SIGNS,
  SIDEWALKS,
  STOPS,
  STORAGE_PLACE,
  TANK,
  TECH_PLACE,
  TRAFFIC_LIGHT,
  TRAM_RAILS,
  TREES_SHRUBS,
  TROLLEYBUS_CONTACT_NETWORK,
  YARD,
} from 'app/constants/oghTypes';

const flatBuildingAttributes = 'is_diff_height_mark';
const createTypeAttributes = 'create_type_id';
const isIsolated = 'is_isolated';
const isObjectArea = 'is_object_area';


/* eslint-disable */
export default {
  [STOPS]: [flatBuildingAttributes],
  [LAMPPOSTS]: [flatBuildingAttributes],
  [MAF]: [flatBuildingAttributes, isObjectArea],
  [MANHOLES]: [flatBuildingAttributes],
  [OTHER_ENGIN_CONSTRUCT]: [flatBuildingAttributes, isObjectArea],
  [ROAD_SIGNS]: [flatBuildingAttributes],
  [FENCING]: [flatBuildingAttributes],
  [TRAFFIC_LIGHT]: [flatBuildingAttributes],
  [CARRIAGEWAY]: [flatBuildingAttributes],
  [SIDEWALKS]: [flatBuildingAttributes],
  [BOARD_STONE]: [flatBuildingAttributes],
  [OTHER_FLAT_BUILDING]: [flatBuildingAttributes],
  [TRAM_RAILS]: [isIsolated, flatBuildingAttributes],
  [TROLLEYBUS_CONTACT_NETWORK]: [flatBuildingAttributes],
  [MARGIN]: [flatBuildingAttributes],
  [EMERGENCY_PARKING]: [flatBuildingAttributes],
  [BUILDING]: [flatBuildingAttributes],
  [CONTAINER]: [flatBuildingAttributes],
  [PLANAR_STRUCTURE]: [flatBuildingAttributes],
  [TECH_PLACE]: [flatBuildingAttributes],
  [STORAGE_PLACE]: [flatBuildingAttributes],
  [DTS]: [flatBuildingAttributes],
  [LAWN]: [flatBuildingAttributes],
  [FLOWERS_GARDEN]: [flatBuildingAttributes],
  [PPI]: [flatBuildingAttributes],
  [RELIEF]: [flatBuildingAttributes],
  [FUNCTIONALITY]: [flatBuildingAttributes],
  [LITTLE_FORM]: [flatBuildingAttributes],
  [OTHER_TECH_PLACE]: [flatBuildingAttributes],
  [BIN]: [flatBuildingAttributes],
  [TREES_SHRUBS]: [flatBuildingAttributes],
  [BASE_PGM]: [flatBuildingAttributes],
  [TANK]: [flatBuildingAttributes],
  [OOPT]: [flatBuildingAttributes],
  [BRIDGE]: [flatBuildingAttributes],
  [OTHER_OKS]: [flatBuildingAttributes],

  [ODH]: [createTypeAttributes, flatBuildingAttributes],
  [YARD]: [createTypeAttributes, flatBuildingAttributes],
  [IMPROVEMENT_OBJECT]: [createTypeAttributes, flatBuildingAttributes],
  [OZN]: [createTypeAttributes, flatBuildingAttributes],
};
