import { fieldName } from 'app/components/registry/form/constants/fieldNames';
/* eslint-disable  */
import { ODH } from 'app/constants/oghTypes';

/**
 *
 * @param typeId
 */
export const componentFactory = (typeId) => {
  const components = new Set();
  switch (typeId) {
    case ODH:
      components.add(fieldName.grbsShortName);
      break;
    default:
  }
  components.add('submitButton');
  components.add('resetButton');
  return Array.from(components);
};
