import { PrintButtonOgh } from 'app/pages/components';
import PropTypes from 'prop-types';

import { useFilterQueryParamsRedBookPlant } from './Filter.RedBookPlant';

/* eslint-disable */
/**
 * Кнопка печати реестра "Растения Красной книги".
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - Id типа огх.
 * @param {number} props.parentTypeId - Id типа родителя огх.
 * @returns {JSX.Element} - JSX.
 * @example -----
 */
export const PrintButtonRedBookPlant = ({
  typeId,
  parentTypeId,
}) => {
  const { prepareFilters } =
    useFilterQueryParamsRedBookPlant();

  return (
    <PrintButtonOgh
      {...{
        typeId,
        prepareFilters: { ...prepareFilters, parentTypeId },
      }}
    />
  );
};

PrintButtonRedBookPlant.propTypes = {
  typeId: PropTypes.number.isRequired,
};
