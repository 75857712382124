import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { TextFieldRF } from 'core/form/reduxForm/fields/default/TextFieldRF';
import React from 'react';

export const DESCRIPTION_NAME = 'description';

/**
 * Text Field RF __Примечание__.
 *
 * @param props - Пропсы.
 * @param props.label - Лейбл.
 * @returns
 */
export const DescriptionField = (props: { label?: string }) => {
  const { editMode } = useMode();
  return (
    <TextFieldRF
      name={DESCRIPTION_NAME}
      label={props.label || 'Примечание'}
      maxLength={2}
      tooltip={DESCRIPTION_NAME}
      disabled={!editMode}
    />
  );
};
