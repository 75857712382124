// UI заголовок таблицы

import { useCreateReactTableHeadTheme } from 'core/uiKit/components/tables/ReactTable/useCreateReactTableHeadTheme';
import { Checkbox } from 'core/uiKit/inputs';
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  ThemeProvider,
} from 'core/uiKit/material-ui';
import React, { Fragment } from 'react';

import { useParamsReactTable } from './useParamsReactTable';

/**
 * Компонент EnhancedTableHead.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const EnhancedTableHead = (props) => {
  const theme = useCreateReactTableHeadTheme();

  const { query, setQuery } = useParamsReactTable();
  const { sort = '' } = query;

  const {
    checkedAll,
    columns,
    onSelectAllClick,
    onSelectRow,
    rowsCount,
    sortableColumns = [],
  } = props;

  const [nameSort, direction] = sort.split('.');

  /**
   * Функция сортировки по умолчанию и возрастанию.
   *
   * @param {string} name - Имя.
   * @returns {void}
   */
  const sortHandler = (name) => {
    if (nameSort === name) {
      switch (direction) {
        case 'desc':
          setQuery({ sort: `${name}.asc` });
          return;
        case 'asc':
          setQuery({ sort: '' });
          return;
        default:
      }
    }
    setQuery({ sort: `${name}.desc` });
  };

  return (
    <ThemeProvider theme={theme}>
      <TableHead>
        {columns.map((headerGroup) => (
          <TableRow key={headerGroup} {...headerGroup.getHeaderGroupProps()}>
            {onSelectRow !== undefined ? (
              <TableCell
                className="TableCellCheckbox"
                key="checkboxes"
                style={{ paddingLeft: '8px' }}
              >
                {onSelectAllClick !== undefined ? (
                  <Checkbox
                    checked={checkedAll === rowsCount}
                    onChange={onSelectAllClick}
                  />
                ) : null}
              </TableCell>
            ) : null}
            {headerGroup.headers.map((column) => (
              <TableCell
                align={column.numeric ? 'right' : 'left'}
                className={'table_stick-header__th'}
                key={column.id}
                padding={column.disablePadding ? 'none' : 'default'}
                // sortDirection={orderBy === column.id ? order : false}
              >
                {sortableColumns.includes(column.id) ? (
                  <TableSortLabel
                    active={nameSort === column.id}
                    hideSortIcon={true}
                    direction={direction}
                    onClick={() => sortHandler(column.id)}
                  >
                    {column.render('Header')}
                  </TableSortLabel>
                ) : (
                  <Fragment>{column.render('Header')}</Fragment>
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
    </ThemeProvider>
  );
};
