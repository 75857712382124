import { deleteCardAllow } from 'app/actions/odsObjectActions/deleteCardAllow';
import { editCardDidNotLock } from 'app/actions/odsObjectActions/editCardDidNotLock';
import { receiveErrorDeleteCard } from 'app/actions/odsObjectActions/receiveErrorDeleteCard';
import { startEditCardRequest } from 'app/actions/odsObjectActions/startEditCardRequest';
import { getApiPath } from 'app/utils/api/config';

/* eslint-disable */
export function startDeleteCard(record_id, type_id, delete_root) {
  return (dispatch) => {
    dispatch(startEditCardRequest());
    return fetch(
      `${getApiPath()}/card/delete/start?record_id=${record_id}&type_id=${type_id}`,
      {
        credentials: 'include',
      },
    )
      .then((response) => response.json())
      .then((json) => {
        return json.errors && json.errors.length > 0
          ? dispatch(receiveErrorDeleteCard(json.message || json.errors[0]))
          : !json.lockInfo || json.lockInfo.lock
          ? dispatch(deleteCardAllow(json.cardInfo, delete_root))
          : dispatch(editCardDidNotLock(json.lockInfo));
      });
  };
}
