 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Вид покрытия на сопряжении с проезжей частью, уточнение__.
 *
 * @type {{ accessor: 'coating_type_road_name', Header: 'Вид покрытия на сопряжении с проезжей частью, уточнение', }}
 * @augments Cell
 */
export const coating_type_road_name = {
  accessor: 'coating_type_road_name',
  Header: 'Вид покрытия на сопряжении с проезжей частью, уточнение',
  sortable: true,
};
