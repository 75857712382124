import NavItem from 'app/components/common/NavBar/NavItem';
import { useUser } from 'app/pages/providers';
import { pathEquipment } from 'app/pages/registry/tech/Equipment/path.Equipment';
import { lazy, useEffect } from 'react';
/* eslint-disable */
function lazyWithPreload(factory) {
  const Component = lazy(factory);
  Component.preload = factory;
  return Component;
}

const EquipmentRegistry = lazyWithPreload(() =>
  import('app/pages/registry/tech/Equipment'),
);

 
export const EquipmentRegistryLink = () => {
  const { hasPermission } = useUser();

  const isShow = hasPermission(pathEquipment.privilegeView);

  useEffect(() => {
    isShow && EquipmentRegistry.preload();
  }, [isShow]);

  return isShow ? (
    <NavItem link={`/r/tech/${pathEquipment.path}`}>
      Навесное оборудование
    </NavItem>
  ) : null;
};
//
// const EquipmentRegistry = showIfHavePrivilege(
//   'view_technic_equipment',
// )(() => <NavItem link="/r/tech/equipment">Навесное оборудование</NavItem>);
