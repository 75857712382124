import { get } from 'app/api/crud/get';

/**
 * Метод получения опшинов поля Балансодержатель.
 *
 * @param place - Место откуда идет запрос.
 * @returns {Array}
 */
export const fetchOwners = (place: string) => async (data: object) => {
  const res = await get<{ id: string; shortname: string }[]>(
    '/autocomplete/owners',
    { ...data, place },
  );
  return res.map(({ id, shortname }) => ({ id, name: shortname }));
};
