/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';

import { useParamsPagination } from './useParamsPagination';
import { Skeleton } from 'core/uiKit/components/skeletons/Skeleton';

 
export const Nav = ({ pagination }) => {
  const { queryPagination, setQuery } = useParamsPagination();

  const { page } = queryPagination;

  const changeQuery = (params) => setQuery(params);
  // todo: переработать в читаемый вид
  return (
    <div className="nav">
      {pagination.total ? (
        <>
          {pagination.hasPrevious() && (
            <Link
              className="first"
              title="Перейти на первую страницу"
              to="#"
              onClick={(event) => {
                event.preventDefault();
                changeQuery({
                  page: 0,
                });
              }}
            >
              &lt;&lt;
            </Link>
          )}
          {!pagination.hasPrevious() && (
            <span className="first" title="Перейти на первую страницу">
              <em>&lt;&lt;</em>
            </span>
          )}
          {pagination.hasPrevious() && (
            <Link
              className="prev"
              to="#"
              title="Перейти на предыдущую страницу"
              onClick={(event) => {
                event.preventDefault();
                changeQuery({
                  page: page - 1,
                });
              }}
            >
              &lt;
            </Link>
          )}
          {!pagination.hasPrevious() && (
            <span className="prev" title="Перейти на предыдущую страницу">
              <em>&lt;</em>
            </span>
          )}
          {pagination.getRange(9).map((item) => {
            return pagination.page === item ? (
              <span className="goto" key={item + 1}>
                <em>
                  <span>{item + 1}</span>
                </em>
              </span>
            ) : (
              <span className="goto" key={item + 1}>
                <Link
                  to="#"
                  onClick={(event) => {
                    event.preventDefault();
                    changeQuery({
                      page: item,
                    });
                  }}
                >
                  <span>{item + 1}</span>
                </Link>
              </span>
            );
          })}
          {pagination.hasNext() && (
            <Link
              className="next"
              title="Перейти на следующую страницу"
              to="#"
              onClick={(event) => {
                event.preventDefault();
                changeQuery({
                  page: page + 1,
                });
              }}
            >
              &gt;
            </Link>
          )}
          {!pagination.hasNext() && (
            <span className="next" title="Перейти на следующую страницу">
              <em>&gt;</em>
            </span>
          )}
          {pagination.hasNext() && (
            <Link
              className="last"
              title="Перейти на последнюю страницу"
              to="#"
              onClick={(event) => {
                event.preventDefault();
                changeQuery({
                  page: pagination.lastPage,
                });
              }}
            >
              &gt;&gt;
            </Link>
          )}
          {!pagination.hasNext() && (
            <span className="last" title="Перейти на последнюю страницу">
              <em>&gt;&gt;</em>
            </span>
          )}
        </>
      ) : (
        <Skeleton variant="rect" width={395} height={24} />
      )}
    </div>
  );
};
