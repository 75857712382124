import { useEditorCardSelector } from 'app/selectors/useSelectors/useEditorCard.Selector';
import { shallow } from 'enzyme';
import React from 'react';

import { TabsOdh } from './Tabs.Odh';

jest.mock('app/selectors/useSelectors/useEditorCard.Selector');

describe('spec Tabs.Odh', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    (useEditorCardSelector as jest.Mock).mockReturnValue({});
    // 🧹 clear mock

    // 🔥 Act
    const wrapper = shallow(
      <TabsOdh propertyTab={''} onPropertyTab={() => {}} />,
    );

    // ❓ Assert
    expect(wrapper.text()).toBe('<Tabs /><Tabs />');
  });

  it('tabs', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    (useEditorCardSelector as jest.Mock).mockReturnValue({});
    const onPropertyTabSpy = jest.fn();
    // 🧹 clear mock

    // 🔥 Act
    const wrapper = shallow(
      <TabsOdh propertyTab={'propertyTab'} onPropertyTab={onPropertyTabSpy} />,
    );

    // ❓ Assert
    expect(wrapper.childAt(0).props()).toStrictEqual({
      onChange: onPropertyTabSpy,
      tabs: [
        {
          isOrphanObject: true,
          label: 'Исходные данные',
          value: 'characteristics',
        },
        { isOrphanObject: true, label: 'Основные параметры', value: 'options' },
        { isOrphanObject: true, label: 'Уборочные данные', value: 'cleaning' },
        {
          label: 'Характеристики движения (архивные данные)',
          value: 'movement-characteristics',
        },
        { label: 'Ремонты', value: 'repairs' },
      ],
      value: 'propertyTab',
    });
    expect(wrapper.childAt(1).props()).toStrictEqual({
      onChange: onPropertyTabSpy,
      tabs: [
        { label: 'Проекты', value: 'repairs_plan' },
        { isOrphanObject: true, label: 'Документы', value: 'docs' },
        { label: 'Ордера на работы', value: 'orders' },
      ],
      value: 'propertyTab',
    });
  });
});
