import getReagentCapacityTable from '../../selectors/getReagentCapacityTable';


/* eslint-disable */
export default function (result, state) {
  return {
    ...result,
    initialValues: {
      ...result.initialValues,
      reagent_maxstorage_capacity_list: {
        table: getReagentCapacityTable(state),
        showDialog: false,
      },
    },
  };
}
