/* eslint-disable */

import * as S from 'app/selectors/card';
import { createSelector } from 'reselect';

export const isReportReady = createSelector(
  S.getGeodiff,
  S.getPropsDiff,
  S.getTreediff,
  (geoDiff, propsDiff, treeDiff) =>
    geoDiff.isFetched && propsDiff.isFetched && treeDiff.isFetched,
);
