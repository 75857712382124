import { turnIntoCamelCase } from 'app/utils/api';
/* eslint-disable */

/**
 *
 * @param formValues
 */
export const rotorAreaValidation = (formValues) => {
  const { rotorArea, inboundArea } = turnIntoCamelCase(formValues);

  if (+rotorArea > +inboundArea) {
    return {
      rotor_area:
        '«Площадь роторной перекидки, кв.м» не должно превышать значения параметра «Общая площадь в границах ОДХ, кв.м»',
    };
  }
};
