import { createSelector } from 'reselect';

/* eslint-disable  */
import getOrdersData from './getOrdersData';

/**
 *
 * @param state
 */
const getSelectedOrderId = (state) => state.modals.oghOrderDetails.orderId;

/**
 *
 * @param state
 */
const ordersList = (state) => {
  const data = getOrdersData(state);
  return (data && data.table) || [];
};

export default createSelector([getSelectedOrderId, ordersList], (id, list) => {
  let result;
  if (id) {
    result = list.find((item) => item.global_id === id);
  } else {
    result = null;
  }
  return result;
});
