import { post } from 'app/api/crud/post';

/**
 * Параметры query.
 *
 * @typedef {object} dataQueryTable
 * @property {number} rootId - RootId текущуего паспорта получаем из useGetCurrentCard.
 * @property {Date} dateFrom - Дата от.
 * @property {Date} dateTo - Дата по.
 * @property {Array} stateId - Массив .
 * @property {number} typeId - TypeId текущуего паспорта получаем из useGetCurrentCard.
 * @property {object} queryTable - Параметры таблицы сортировка и пагинация.
 */

/**
 * Функция запроса таблицы версии.
 *
 * @param {dataQueryTable} query - Параметры запроса.
 * @returns {Promise<*>} - Возвращает данные таблицы.
 * @example const data = useQueryAdaptor(
 *     ['getVersionsTankTable', dataQueryTable],
 *     getVersionsTable);
 */
export const getVersionsTable = (query) => {
  return post('/registry/versions/ogh', query);
};
