import { editCardClearDialog } from 'app/actions/odsObjectActions';
/* eslint-disable */
import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { parseDate } from 'app/components/card/common/parse';
import Table from 'app/components/common/table/Table';
import DialogWithTableControls from 'app/components/dialogs/common/DialogWithTableControls';
import { parseDateToMoment } from 'app/utils/date/parseDateToMoment';
import KeyboardDatePickerRF from 'core/form/reduxForm/fields/default/datePickers/KeyboardDatePickerRF';
import { TextFieldRF } from 'core/form/reduxForm/fields/default/TextFieldRF';
import Checkbox from 'core/newComponents/Checkbox';
import React from 'react';
import { connect } from 'react-redux';

import { elementFormGrid } from '../index';

/**
 * Компонент renderRepairsTableData.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
function renderRepairsTableData(props) {
  const results = {
    table: {
      columns: [
        {
          cells: [],
          databaseName: null,
          key: 'end_to_end',
          screenName: '№ п/п',
          sortable: false,
        },
        {
          cells: [],
          databaseName: null,
          key: 'name',
          screenName: 'Наименование',
          sortable: false,
        },
        {
          cells: [],
          databaseName: null,
          key: 'date',
          screenName: 'Дата',
          sortable: false,
        },
        {
          cells: [],
          databaseName: null,
          key: 'property',
          screenName: 'Характеристика (объемы)',
          sortable: false,
        },
        {
          cells: [],
          databaseName: null,
          key: 'contract_organization',
          screenName: 'Организация, проводившая работы',
          sortable: false,
        },
        {
          cells: [],
          databaseName: null,
          key: 'guarantee_date',
          screenName: 'Гарантийные сроки',
          sortable: false,
        },
      ],
    },
  };

  const table = (props.input.value && props.input.value.table) || [];
  const showDialog =
    (props.input.value && props.input.value.showDialog) || false;
  const dateFormatter = new Intl.DateTimeFormat('ru');

  table.forEach((item, index) => {
    results.table.columns.forEach((column) => {
      if (column.key === 'end_to_end') {
        column.cells.push(index + 1);
      } else if (column.key === 'date' || column.key === 'guarantee_date') {
        column.cells.push(
          column.key in item &&
            item[column.key] !== null &&
            item[column.key] !== ''
            ? dateFormatter.format(parseDate(item[column.key]))
            : '',
        );
      } else {
        column.cells.push(item[column.key]);
      }
    });
  });

  if (!props.disabled) {
    results.table.columns.unshift({
      cells: [],
      databaseName: null,
      key: 'delete',
      screenName: '',
      sortable: false,
    });
    table.forEach((item, index) => {
      results.table.columns[0].cells.push(
        <Checkbox
          checked={item.checked || false}
          onChange={function (event, value) {
            const character = { ...this.table[this.index], checked: value };
            const newTable = this.table.slice();
            newTable.splice(this.index, 1, character);
            this.onChange({ showDialog: false, table: newTable });
          }.bind({
            index: index,
            onChange: props.input.onChange,
            table: table,
          })}
        />,
      );
    });
  }

  let elements = [
    {
      addition: { type: 'text' },
      component: TextFieldRF,
      editable: true,
      id: 'name',
      label: 'Наименование',
      name: 'name',
    },
    {
      addition: {
        label: 'Дата',
      },
      component: KeyboardDatePickerRF,
      editable: true,
      id: 'date',
      name: 'date',
      style: {
        marginBottom: 23,
      },
    },
    {
      addition: { type: 'text' },
      component: TextFieldRF,
      editable: true,
      id: 'property',
      label: 'Характеристика (объемы)',
      name: 'property',
    },
    {
      addition: { type: 'text' },
      component: TextFieldRF,
      editable: true,
      id: 'contract_organization',
      label: 'Организация, проводившая работы',
      name: 'contract_organization',
    },
    {
      addition: {
        label: 'Гарантийные сроки',
      },
      component: KeyboardDatePickerRF,
      editable: true,
      id: 'guarantee_date',
      name: 'guarantee_date',
    },
  ];
  return (
    <div style={{ marginBottom: '15px', ...props.style }}>
      <DialogWithTableControls
        arrayFields={elements}
        className="Dialog-Add-Repairs-Info"
        enableRemove={table.some((item) => {
          return item.checked;
        })}
        inEdit={!props.disabled}
        nameDialog="add_repairs_info"
        renderFunction={elementFormGrid}
        showCondition={showDialog}
        title="Добавить сведение о ремонтах"
        onAdd={() => {
          props.input.onChange({ showDialog: true, table });
        }}
        onCancel={() => {
          props.input.onChange({ showDialog: false, table });
          props.dispatch(editCardClearDialog());
        }}
        onRemove={() => {
          const newTable = table.filter((item) => {
            return !item.checked;
          });
          props.input.onChange({ showDialog: false, table: newTable });
        }}
        onSubmit={(formValues) => {
          const value = table.slice();
          if (Object.keys(formValues).length !== 0) {
            if (formValues.date) {
              formValues.date = parseDateToMoment(
                formValues.date,
                'yyyy-mm-dd',
              );
            }
            if (formValues.guarantee_date) {
              formValues.guarantee_date = parseDateToMoment(
                formValues.guarantee_date,
                'yyyy-mm-dd',
              );
            }
            value.push(formValues);
          }
          props.input.onChange({ showDialog: false, table: value });
          props.dispatch(editCardClearDialog());
        }}
      />
      <Table
        {...results}
        height={table.length * 50 + 50}
        width={1590 + (props.disabled ? 0 : 50)}
        widths={(name) => {
          return name === '' ? 50 : name === '№ п/п' ? 90 : 300;
        }}
      />
    </div>
  );
}

const Comp = connect()(renderRepairsTableData);

/**
 *
 * @param root0
 * @param root0.disabled
 */
export default ({ disabled, ...props }) => {
  const isCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();
  return (
    <Comp {...props} disabled={disabled || isCurrentObjectIsExternalSystem} />
  );
};
