import { uploadFile } from 'app/api/requests/uploadFiles';
import { toCurrentDateTime } from 'app/utils/date/toCurrentDateTime';

import { postOghSignAct } from './postOghSingAct';

/**
 * Метод подписания файлов.
 *
 * @param {object} values - Параметры.
 * @returns {Promise<void>}
 */
export const signActOfCompletedSave = async (values) => {
  const {
    signatures = [],
    comment = '',
    certificate = {},
    objectId = '',
  } = values;

  const file = signatures[0];
  const additional = {
    create_date: toCurrentDateTime(),
    sign_user: certificate?.subjectInfo,
    type_id: 20,
  };

  if (comment) {
    additional.comment = comment;
  }

  const res = await uploadFile(file, additional);
  await postOghSignAct({ file_ids: [res.fileId], objectId });
};
