/* eslint-disable */
import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import {
  CleaningType,
  CLEANING_TYPE_NAME,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/CleaningType';

const CleaningTypeRFWithReduxForm = ({ input, ...props }) => {
  return <CleaningType {...input} {...props} />;
};

/* eslint-disable */
export const CleaningTypeRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={CLEANING_TYPE_NAME}
      component={CleaningTypeRFWithReduxForm}
    />
  );
};

CleaningTypeRF.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
};
CleaningTypeRF.defaultProps = {
  disabled: false,
};
