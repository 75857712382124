/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { connect } from 'react-redux';
import _ from 'lodash';
import { actions as formActions } from 'react-redux-form';

import { ButtonBlue } from 'core/newComponents/Button';

import Link from 'app/components/common/misc/Link';
import * as technologyVersionsActions from 'app/actions/technologyVersions';
import Out from 'app/components/admin/common/RegistryOut';
import * as codeMapping from './codeMapping';

import getVersionUrlFunction from 'app/utils/card/getVersionUrlFunction';

import 'assets/styles/card.versions.scss';

class Versions extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    data: PropTypes.object,
    columns: PropTypes.array,
    card: PropTypes.object,
    getVersionUrl: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.typeId !== this.props.typeId ||
      _.get(prevProps, 'card.root_id') !== _.get(this.props, 'card.root_id')
    ) {
      this.props.setTableConfig('maxRows', 50);
      this.props.setTableConfig('page', 0);
      this.props.setTableConfig('sort', 'id.asc');
      this.props.fetchData();
    }
  }

  componentWillUnmount() {
    this.props.resetTableConfig();
  }

  renderIdTableField(id, rowVal) {
    const url = this.props.getVersionUrl(rowVal);
    return (
      <td className={cn('id__col')} key={`td-${id}`}>
        <Link to={url}>{rowVal}</Link>
      </td>
    );
  }

  renderRow(row) {
    return (
      <tr key={`tr-${row.id}`}>
        {this.props.columns.map((col) => {
          const rowVal = row[col.id];
          if (col.id === 'id') {
            return this.renderIdTableField(col.id, rowVal);
          } else {
            return this.renderTableField(col.id, rowVal);
          }
        })}
      </tr>
    );
  }

  renderTable() {
    const { columns, data, sort, onSort, onLimit, onPagination } = this.props;
    return (
      <Out
        columns={columns}
        data={data}
        headerFirstCell={null}
        main={data.table.map((row) => this.renderRow(row))}
        sort={sort}
        onLimit={onLimit}
        onPagination={onPagination}
        onSort={onSort}
      />
    );
  }

  renderTableField(id, rowVal) {
    return (
      <td className={cn(id + '__col')} key={`td-${id}`}>
        {rowVal}
      </td>
    );
  }

  render() {
    const { card, data, resetTableConfig } = this.props;
    const SearchForm = codeMapping.codeToSearchForm(card.code);
    return (
      <div
        className={cn(
          'd-flex flex-column h-100 px-4',
          'versions',
          this.props.className,
        )}
        id="versions"
      >
        <h4 className="mt-4">Версии объекта</h4>
        <SearchForm
          code={card.code}
          rootId={card.root_id}
          typeId={card.type_id}
        />
        <div className="my-4">
          <ButtonBlue
            className={cn('clearBtn')}
            onClick={() => {
              resetTableConfig();
            }}
          >
            Сбросить
          </ButtonBlue>
        </div>
        {data && data.table && this.renderTable()}
      </div>
    );
  }
}

const getVersionUrl = getVersionUrlFunction('tech');

function mapStateToProps(state, ownProps) {
  return {
    columns: codeMapping.codeToColumns(ownProps.card.code, state.tableColumns),
    data: state.technologyVersions.data,
    sort: state.rrf.technologyVersions.sort,
    getVersionUrl,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchData: function () {
      return dispatch(formActions.submit('rrf.technologyVersions'));
    },
    onSort: function (values) {
      return dispatch(technologyVersionsActions.onSort(values));
    },
    onLimit: function (value) {
      return dispatch(technologyVersionsActions.onLimit(value));
    },
    onPagination: function (value) {
      return dispatch(technologyVersionsActions.onPagination(value));
    },
    setTableConfig: function (param, value) {
      return dispatch(
        formActions.change('rrf.technologyVersions.' + param, value),
      );
    },
    resetTableConfig: function () {
      dispatch(technologyVersionsActions.onReset('rrf.technologyVersions'));
    },
  };
}

 
export default connect(mapStateToProps, mapDispatchToProps)(Versions);
