import { DeleteButtonCellRepairsTab } from './Delete.Button.Cell.Repairs.Tab';

/**
 * Функция getColumnsTableRepairsTab.
 *
 * @returns Columns.
 */
export const getColumnsTableRepairsTab = () => [
  {
    Cell: DeleteButtonCellRepairsTab,
    Header: '',
    accessor: 'id_delete',
  },
  {
    Header: '№ п/п',
    accessor: 'end_to_end',
  },
  {
    Header: 'Наименование',
    accessor: 'name',
  },
  {
    Header: 'Дата',
    accessor: 'date',
  },
  {
    Header: 'Характеристика (объемы)',
    accessor: 'property',
  },
  {
    Header: 'Организация, проводившая работы',
    accessor: 'contract_organization',
  },
  {
    Header: 'Гарантийные сроки',
    accessor: 'guarantee_date',
  },
];
