import { Cell } from 'core/uiKit/components/tables/Table';

/**
 * Колонка __Исполнитель/Исполнители (по разработке актуализации паспорта)__.
 *
 * @type {{
 * accessor: 'passport_draft_org',
 * Header: 'Исполнитель/Исполнители (по разработке актуализации паспорта)',
 * sortable: true,
 * }}
 * @augments Cell
 */
export const passport_draft_org: Cell = {
  Header: 'Исполнитель/Исполнители (по разработке актуализации паспорта)',
  accessor: 'passport_draft_org',
  sortable: true,
};
