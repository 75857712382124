import { INSTALL_DUT_NAME, InstallDutFF } from 'core/form/finalForm/fields';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';
import { StringParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

/**
 * Не подлежит оснащению ДУТ
 * installDut.
 *
 * @param {*} props - Properties.
 * @returns {JSX.Element}
 */
export const InstallDutReportFilter = (props) => (
  <InstallDutFF {...props} className={filterInput} />
);

export { INSTALL_DUT_NAME };

/**
 * AddInstallDutParams..
 *
 * @returns {object}
 */
export const installDutReportParams = () => {
  addPreparation(INSTALL_DUT_NAME, (value) => value, 'notNeedDut');
  return {
    [INSTALL_DUT_NAME]: StringParam,
  };
};
