 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Состав оборудования, светофор транспортный, шт.__.
 *
 * @type {{ accessor: 'traff_light_car',  Header: 'Состав оборудования, светофор транспортный, шт.' }}
 * @augments Cell
 */
export const traff_light_car = {
  accessor: 'traff_light_car',
  Header: 'Состав оборудования, светофор транспортный, шт.',
  sortable: true,
};
