import getImprovementObjectCategoriesImprovement from 'app/selectors/card/getImprovementObjectCategoriesImprovement';
import getImprovementObjectCategoriesLandscaping from 'app/selectors/card/getImprovementObjectCategoriesLandscaping';


/* eslint-disable */
export default {
  improvement: {
    id: 'improvement_category_id',
    dictionary: 'improvement_category',
    label: 'Категория благоустройства',
    getSelector: getImprovementObjectCategoriesImprovement,
  },
  landscaping: {
    id: 'improvement_object_category_id',
    dictionary: 'improvement_object_category',
    label: 'Категория озеленения',
    getSelector: getImprovementObjectCategoriesLandscaping,
  },
};
