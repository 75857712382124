import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { OZN, SPA } from 'app/constants/oghTypes';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { ImprovementObjectCategoryIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/ImprovementObjectCategoryId.RF/ImprovementObjectCategoryId.RF';
import React from 'react';

const mapImprovementObjectCategory = {
  [OZN]: [1, 2, 4],
  [SPA]: [1, 2, 4],
};

/**
 * Select RF __Категория озеленения__.
 *
 * @returns {React.ReactElement}
 */
export const ImprovementObjectCategoryIdWrapper = () => {
  const { typeId } = useGetCurrentCard();
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();
  const { editMode } = useMode();

  /**
   * Кастомный фильтр.
   *
   * @param {Array} dict - Словарь.
   * @returns {Array}
   */
  const filter = (dict) => {
    return (
      dict?.filter(({ id }) =>
        mapImprovementObjectCategory[typeId].includes(id),
      ) || []
    );
  };

  return (
    <ImprovementObjectCategoryIdRF
      filter={filter}
      required={true}
      withAll={false}
      disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
    />
  );
};
