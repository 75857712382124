import { IMPROVEMENT_OBJECT, ODH, OZN, YARD } from 'app/constants/oghTypes';
import { useGetCardAttribute } from 'app/selectors/useSelectors/useGetCardAttribute.Selector';
import { useGetCurrentCardSelector } from 'app/selectors/useSelectors/useGetCurrentCard.Selector';
import { Checkbox } from 'core/uiKit/inputs/CheckBox';

/* eslint-disable */

/**
 *
 */
export const CheckboxReapprovalComponent = () => {
  const { reapproveRequired } = useGetCardAttribute();
  const type = useGetCurrentCardSelector().typeId;

  return [YARD, OZN, ODH, IMPROVEMENT_OBJECT].includes(type) ? (
    <Checkbox
      label={'Требует повторного утверждения'}
      value={reapproveRequired}
      name={'reapprove_required'}
      disabled={true}
    />
  ) : null;
};
