import {
  AddressFilter,
  addressParams,
} from 'core/form/filterForm/components/autocompleteAsync/AddressFilter';
import { ContainerTypeFilter } from 'core/form/filterForm/components/selects/ContainerTypeFilter';
import { IdFilter } from 'core/form/filterForm/components/text/ID.Filter';
import { InYardFilter } from 'core/form/filterForm/components/yesOrNot/InYardFilter';
import { ParentNameFilter } from 'core/form/filterForm/components/yesOrNot/ParentNameFilter';
import React, { useEffect } from 'react';

/**
 * Фильтр __Места накопления отходов__.
 *
 * @returns {JSX.Element}
 */
export const ContainerFilter = () => {
  useEffect(() => {
    addressParams();
  }, []);
  return (
    <>
      <IdFilter />
      <AddressFilter />
      <ContainerTypeFilter />
      <ParentNameFilter />
      <InYardFilter />
    </>
  );
};
