import { PrintButton } from 'app/pages/components/buttons/PrintButton';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery/useMutationAdaptor';
import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { detailing } from './detailing';

/**
 * Компонент PrintEquipmentComp.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - Тип ОГХ.
 * @param {boolean} props.isCreateMode - Флаг.
 * @returns {JSX.Element|null}
 */
export const PrintEquipmentComp = ({ typeId, isCreateMode }) => {
  const [sendReport, { isLoading }] = useMutationAdaptor(detailing);
  const { id } = useParams();

  if (isCreateMode) {
    return null;
  }

  return (
    <PrintButton
      isLoading={isLoading}
      text={'Детализация'}
      onClick={() => {
        sendReport({ id: String(id), typeId: String(typeId) });
      }}
    />
  );
};

/**
 * Получение значений из state.
 *
 * @param {object} state - Стейт.
 * @returns {{type_id: *, isCreateMode: *}} - XZ.
 */
const mapStateToProps = (state) => ({
  isCreateMode: get(state, 'card.mode.createMode'),
  typeId: get(state, 'card.object.type_id'),
});

export const PrintEquipment = connect(mapStateToProps)(PrintEquipmentComp);
