import { useNavigate as useNavigateRRD } from 'react-router-dom';
import { useParams } from 'react-router-dom';

 

/* eslint-disable */
/**
 * Хук useNavigate возвращает функцию, которая позволяет программно
 * перемещаться, например, после отправки формы.
 * При использовании replace: true навигация заменит текущую запись в стеке
 * истории вместо добавления новой.
 * @returns { Function } navigate
 */

 
export const useNavigateRouter = () => {
  return useNavigateRRD();
};

 
export const withRouter = (WrappedComponent) => (props) => {
  const params = useParams();
  const navigate = useNavigateRouter();
  // etc... other react-router-dom v6 hooks
  return (
    <WrappedComponent
      {...props}
      params={params}
      navigate={navigate}
      // etc...
    />
  );
};
