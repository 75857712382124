import { usePreparation } from 'core/utils/hooks/usePreparation/preparation';

import { RED_BOOK_PLANT_ID_NAME } from './RED_BOOK_PLANT_ID_NAME';

/**
 * Returns an object with 'red_book_plant' property if 'value' is truthy.
 *
 * @returns {{}} Object with 'red_book_plant' property if 'value' is truthy.
 */
export const usePreparationRedBookPlantId = () =>
  usePreparation(RED_BOOK_PLANT_ID_NAME, (value) => {
    if (value) {
      return { red_book_plant: value };
    }
  });
