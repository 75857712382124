import { useSelector } from 'react-redux';

 
/* eslint-disable */
/**
 * Selector получения mode карточки из state
 * @function
 * @category useSelector
 *
 * @description получаем mode
 *
 * @deprecated
 * @see используй useEditMode
 *
 * @import app/selectors/useSelectors/useGetCardMode.Selector
 *
 * @example
 * const mode = useGetCardModeSelector()
 * @returns {object}
 */
 
export const useGetCardModeSelector = () => {
  const mode = useSelector((state) => {
    return state?.card?.mode;
  });
  return mode || {};
};
