import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
/* eslint-disable  */
import { fetchStartEdit } from 'app/api/ogh';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

/**
 * Хук получения функции проверки редактирования.
 *
 * @param {object} params - Параметры.
 * @param {Function} params.openDialog - Открыть диалоговое окно.
 * @param {Function} params.continueEditFn - Функция, продолжения редактирования.
 * @returns {Array}
 */
export const useStartEditMutation = ({ openDialog, continueEditFn }) => {
  const { recordId, rootId, typeId } = useGetCurrentCard();

  return useMutationAdaptor(
    () => fetchStartEdit({ recordId, rootId, typeId }),
    {
      /**
       *
       * @param data
       */
      onSuccess: (data) => {
        if (
          data?.versionInfo?.bid_id &&
          data?.versionInfo?.project_id === null
        ) {
          openDialog(true);
        } else {
          continueEditFn();
        }
      },
    },
  );
};
