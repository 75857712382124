import { Button } from 'core/uiKit/components';
/* eslint-disable */
import { Paper } from 'core/uiKit/components/Paper';
import { useState } from 'react';

import { DialogSignatureFiles } from './Dialog.SignatureFiles';

export default {
  title: 'Example/signatures/DialogSignatureFiles',
};

/**
 *
 * @param args
 */
export const Basic = (args) => {
  const [isOpen, setIsOpen] = useState(true);
  const [step, setStep] = useState(0);
  return (
    <Paper className={'p-5 display-flex flex-wrap gap-2'}>
      <Button
        variant={'contained'}
        color={'success'}
        onClick={() => setIsOpen(true)}
      >
        {isOpen ? 'закрыть' : 'открыть'}
      </Button>
      <strong>Step: </strong> {step}
      <DialogSignatureFiles
        {...args}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        nextStep={() => setStep(step + 1)}
      />
    </Paper>
  );
};

Basic.args = {};
