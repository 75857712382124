/* eslint-disable */
import React from 'react';

/**
 *
 */
export default class EmptyField extends React.Component {
  /**
   *
   */
  render() {
    return <div />;
  }
}
