/* eslint-disable */
import { shallow } from 'enzyme';

import { RANDOM_ID, RANDOM_WORD } from 'core/forTesting/constants';

import { AccordionPlantationTab } from './Accordion.PlantationTab';

jest.mock('./Print.PlantationTab', () => ({
  __esModule: true,
  PrintPlantationTab: () => <div />,
}));

 
describe('file Accordion.PlantationTab.spec', () => {
  it('text show', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const wrapper = shallow(
      <AccordionPlantationTab
        code={RANDOM_WORD}
        expanded={RANDOM_WORD}
        Table={(_) => _}
        onChange={jest.fn()}
      />,
    );
    // Assert
    expect(wrapper.text()).toBe('<PrintPlantationTab /><Table />');
  });

  it('text hidden', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const wrapper = shallow(
      <AccordionPlantationTab
        Table={(_) => _}
        onChange={jest.fn()}
        code={RANDOM_ID}
        expanded={RANDOM_WORD}
      />,
    );
    // Assert
    expect(wrapper.text()).toBe('<PrintPlantationTab />');
  });

  it('Accordion props show', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const CODE = 'code_' + RANDOM_WORD;
    // Act
    const wrapper = shallow(
      <AccordionPlantationTab
        Table={(_) => _}
        onChange={(_) => _}
        code={CODE}
        expanded={CODE}
      />,
    );
    // Assert
    expect(wrapper.prop('className')).toBe('my-1');
    expect(wrapper.prop('expanded')).toBeTruthy();
    expect(wrapper.prop('onChange')).toBe(CODE);
  });

  it('Accordion props hidden', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const CODE = 'code_' + RANDOM_WORD;
    // Act
    const wrapper = shallow(
      <AccordionPlantationTab
        Table={(_) => _}
        onChange={(_) => _}
        code={CODE}
        expanded={CODE + 1}
      />,
    );
    // Assert
    expect(wrapper.prop('className')).toBe('my-1');
    expect(wrapper.prop('expanded')).toBeFalsy();
    expect(wrapper.prop('onChange')).toBe(CODE);
  });
});
