 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Жизненная форма__.
 *
 * @type {{
 * accessor: 'life_form_type_name',
 * Header: 'Жизненная форма',
 * sortable: true,
 * }}
 * @augments Cell
 */
export const life_form_type_name = {
  accessor: 'life_form_type_name',
  Header: 'Жизненная форма',
  sortable: true,
};
