import { ContainerTypeIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/ContainerTypeId.RF';
import React from 'react';

import { useSetupContainerTypeIdFieldContainer } from './useSetup.ContainerTypeId.Field.Container';

/**
 * Тип МНО для контейнера.
 *
 * @returns JSX.Element.
 */
export const ContainerTypeIdFieldContainer = () => {
  const { filter, isEdit } = useSetupContainerTypeIdFieldContainer();
  return <ContainerTypeIdRF filter={filter} disabled={!isEdit} />;
};
