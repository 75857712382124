import { get } from 'lodash';


/* eslint-disable */
export default function (result, state, props) {
  const { card } = props;
  return {
    ...result,
    initialValues: {
      ...result.initialValues,
      reservoir_area: get(card, 'attribute.reservoir_area'),
    },
  };
}
