import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import __camelCase from 'lodash/camelCase';

/**
 * Получаем объект тип одх.
 *
 * @author bad4iz
 * @function
 * @name useGetOghTypes
 * @param {string} [code] - Код строкой (camelCase || snake_case).
 * @returns {*} Объект тип одх oghTypes.
 * @example
 * useGetOghTypes() //? массив всех типов
 * useGetOghTypes(47) //? {id: 47, code: 'bridge', name: 'Мосты', full_name: 'Мосты'}
 * useGetOghTypes('bridge') //? {id: 47, code: 'bridge', name: 'Мосты', full_name: 'Мосты'}
 * useGetOghTypes('bridge') //? {id: 47, code: 'bridge', name: 'Мосты', full_name: 'Мосты'}
 * camelCase
 * useGetOghTypes('tram_rails') //? {id: 20, code: 'tram_rails', name: 'Трамвайные пути', full_name: 'Трамвайные пути'}
 * snake_case
 * useGetOghTypes('tramRails') //? {id: 20, code: 'tram_rails', name: 'Трамвайные пути', full_name: 'Трамвайные пути'}
 */
export const useGetOghTypes = (code) => {
  const dict = 'oghTypes';

  const { data = [] } = useGetDictionary(dict);

  const oghTypes = data.reduce((accumulator, current) => {
    accumulator[__camelCase(current.code)] = current;
    accumulator[current.code] = current;
    accumulator[current.id] = current;
    return accumulator;
  }, {});

  if (code) {
    return oghTypes[code] || {};
  }

  return oghTypes;
};
