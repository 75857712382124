import { useParamsPagination } from 'core/uiKit/components/Pagination/useParamsPagination';
import { StringParam, useQueryParams } from 'use-query-params';

/**
 * Хук для пагинации и сортировки таблицы.
 *
 * @returns {*}
 */
export const useParamsTable = () => {
  const { queryPagination, setQuery: setQueryPagination } =
    useParamsPagination();
  const [query, setQuery] = useQueryParams({
    sort: StringParam,
  });

  /**
   * Функция сброса пагинации и сортировки и количества записей в таблище.
   *
   * @returns {void}
   */
  const resetPagination = () => {
    setQueryPagination({
      maxRows: undefined,
      page: undefined,
    });
    setQuery({
      sort: undefined,
    });
  };

  return {
    query,
    queryTable: { ...queryPagination, ...query },
    resetPagination,
    setQuery,
  };
};
