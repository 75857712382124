import withDarkTheme from 'core/hocs/withDarkTheme';

import Checkbox from './Checkbox';

const DarkCheckbox = withDarkTheme(Checkbox);

 
export { DarkCheckbox };

 
export default Checkbox;
