import { initialValuesYard } from './initialValues.Yard';

/**
 * Mock Default.
 *
 * @returns {void}
 */
const mockDefault = () => {};

describe('🐛 spec initialValues.Yard.spec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock

    //🔥 Act
    const res = initialValuesYard(
      { initialValues: { initial: 'initial' }, result: 'result' },
      {},
      {
        card: {
          attribute: { reservoir_area: 'attribute.reservoir_area' },
          repairs_info_list: 'repairs_info_list',
          repairs_info_list_plan: 'repairs_info_list_plan',
        },
      },
    );

    //❓ Assert
    expect(res).toStrictEqual({
      initialValues: {
        initial: 'initial',
        repairs_info_list: 'repairs_info_list',
        repairs_info_list_plan: 'repairs_info_list_plan',
        reservoir_area: 'attribute.reservoir_area',
      },
      result: 'result',
    });
  });
});
