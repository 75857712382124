import { get } from 'lodash';
import { createSelector } from 'reselect';

 
/**
 * Fn getDataForAddOrEditChildObj.
 *
 * @deprecated
 * @param {object} state - State.
 * @returns {undefined}
 * @example --------
 */
const getDataForAddOrEditChildObj = (state) =>
  get(
    state,
    'card.root.attribute.other_improvement_object_type_id.other_improvement_object_type',
  );
 
/**
 * Fn getDataForEditChildObj.
 *
 * @deprecated
 * @param {object} state - State.
 * @returns {undefined}
 * @example --------
 */
const getDataForEditChildObj = (state) =>
  get(state, 'card.object.parent_info.other_improvement_object_type_id');

 
/**
 * Fn getOtherImprovementObjectTypeId.
 *
 * @deprecated
 * @param {object} state - State.
 * @param {object} props - Props.
 * @returns {undefined}
 * @example --------
 */
const getOtherImprovementObjectTypeId = createSelector(
  [getDataForAddOrEditChildObj, getDataForEditChildObj],
  (addOrEditChildObj, editChildObj) => {
    let result;

    if (editChildObj) {
      result = editChildObj;
    } else {
      result = addOrEditChildObj;
    }

    return result;
  },
);
export default getOtherImprovementObjectTypeId;
