import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const CLEAN_TYPE_ID_NAME = 'clean_type_id';

/**
 * Select __Способ уборки__.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const CleanTypeId = (props) => {
  const dict = 'clean_type';

  const { data = [] } = useGetDictionary(dict);

  return (
    <Select
      {...props}
      label={'Способ уборки'}
      options={data || []}
      name={CLEAN_TYPE_ID_NAME}
    />
  );
};
