// import { elementFormGrid } from 'app/components/card/common/grid';
// import { WithReduxFormSelect } from 'app/components/common/SelectField';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';

// import metadata from './metadata';

/**
 * Категории.
 *
 * @returns {function(*): *}
 */
export default function () {
  // const { cols = 2, title = '' } = params;
  // const element = getElements(params);
  return (props) => {
    const { options } = props;
    return (
      <FieldsSplitColumns>
        <SelectRF
          name={'improvement_category_id'}
          label={'Категория благоустройства'}
          options={options.improvement_category_id}
          disabled={true}
        />
        <SelectRF
          name={'improvement_object_category_id'}
          label={'Категория озеленения'}
          options={options.improvement_object_category_id}
          disabled={true}
        />
        {/*{elementFormGrid(element(props), cols, title, props.card.type_id)}*/}
      </FieldsSplitColumns>
    );
  };
}

// function getElements(params) {
//   return (props) =>
//     Object.entries(metadata).map(([key, item]) => {
//       const { editable = true, formValue = true } = params[key] || {};
//       const elementEditable = editable && props.editable;
//       return {
//         id: item.id,
//         name: item.id,
//         editable: elementEditable,
//         formValue,
//         component: WithReduxFormSelect,
//         addition: {
//           defaultValue: props.initialValues[item.id],
//           label: item.label,
//           options: props.options[item.id],
//           required: elementEditable && props.required,
//         },
//       };
//     });
// }
