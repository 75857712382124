/* eslint-disable */
import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { QuantityPCSReduxForm } from './QuantityPCSReduxForm';
import { QUANTITY_PCS_NAME } from 'core/uiKit/preparedInputs';

 
/* eslint-disable */
/**
 * Комментарий
 *
 * * `name = QUANTITY_PCS_NAME`
 * @param props
 * @returns <Field {...otherProps} component={QuantityPCSReduxForm} name={QUANTITY_PCS_NAME} />
 * @constructor
 */
/* eslint-disable */
export const QuantityPcs = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      component={QuantityPCSReduxForm}
      name={QUANTITY_PCS_NAME}
    />
  );
};

QuantityPcs.propTypes = {
   
   
/**
   * быбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
/**
   * placeholder
   */
  placeholder: PropTypes.string,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
};
QuantityPcs.defaultProps = {
  disabled: false,
};
