export const create_type = [
  {
    code: 'acs_ods',
    id: 1,
    name: 'АСУ ОДС 2.0',
  },
  {
    code: 'external_system',
    id: 2,
    name: 'Внешняя система',
  },
  {
    code: 'upload_dxf',
    id: 3,
    name: 'Загрузка dxf-файла',
  },
];
