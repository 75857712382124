import './form.Auth.FF.scss';

import { FinalForm } from 'core/form/finalForm/components/FinalForm';
import { Paper } from 'core/uiKit/components/Paper';
import { AUTH_URLS_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/AuthUrls';

/**
 * Форма авторизации.
 *
 * @param {*} props - Props.
 * @param {JSX.Element} props.Footer - Футер.
 * @param {string} props.Header - Текст шапки.
 * @param {JSX.Element} props.children - Инпуты.
 * @param {Function} props.submit - Функция сабмит формы.
 * @param {Function} props.validate - Функция валидации.
 * @returns {JSX.Element}
 */
export const FormAuthFF = ({ Footer, Header, children, submit, validate }) => {
  return (
    <Paper dark={true}>
      <header className={'formAuth__header'}>
        <h1>{Header}</h1>
      </header>

      <FinalForm
        onSubmit={(values) => {
          let errors = validate(values);
          if (errors) return errors;
          submit(values);
        }}
        initialValues={{ [AUTH_URLS_NAME]: '/login' }}
      >
        <>
          <main className={'formAuth__main ods-form-bg '}>{children}</main>
          <footer className={'formAuth__footer'}>{Footer}</footer>
        </>
      </FinalForm>
    </Paper>
  );
};
