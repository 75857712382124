import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { UnitIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/UnitId.FF';
import { ENGINEER_BUILDING_TYPE_ID } from 'core/uiKit/preparedInputs/selects/simpleSelect/EngineerBuildingTypeId';
import { useDictEngineerBuildingTypeId } from 'core/uiKit/preparedInputs/selects/simpleSelect/EngineerBuildingTypeId/useDict.EngineerBuildingTypeId';
import React from 'react';
import { useField } from 'react-final-form';

/**
 * Field FF __Ед. Измерения__.
 *
 * `name: unit_id`.
 *
 * @returns {JSX.Element}
 */
export const UnitIdFFField = () => {
  const { editMode } = useEditMode();

  const {
    input: { value: engineerBuildingTypeIdValue },
  } = useField(ENGINEER_BUILDING_TYPE_ID);

  const { data } = useDictEngineerBuildingTypeId();

  const mapUnit = (data || []).find(
    (item) => item.id === engineerBuildingTypeIdValue,
  );

  /**
   * Функция фильтрации по справочнику поля ENGINEER_BUILDING_TYPE_ID.
   *
   * @param {Array} dict - Справочник.
   * @returns {Array}
   */
  const filter = (dict) => {
    return dict.filter(({ id }) => (mapUnit?.unit_id || []).includes(id));
  };

  const editable = editMode && engineerBuildingTypeIdValue;

  return (
    <UnitIdFF
      disabled={!editable}
      required={true}
      filter={filter}
      withAll={false}
    />
  );
};
