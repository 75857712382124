import { useForm } from 'react-final-form';

import { useGetAllowedGeometryTypes } from './useGetAllowedGeometryTypes';

/**
 * Получить разрешенные типы геометрий. Для Final Form.
 *
 * @returns Разрешенные типы геометрий.
 */
export const useGetAllowedGeometryTypesFF = () => {
  const formValues = useForm();
  return useGetAllowedGeometryTypes(formValues);
};
