import DndFileUpload from 'app/components/common/DndFileUpload'; // перенести DndFileUpload в core
import { Comment } from 'core/uiKit/preparedInputs';
import PropTypes from 'prop-types';
/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';

import DialogAction from './DialogAction';

const propTypes = {
  additional: PropTypes.object,
  open: PropTypes.bool,
  title: PropTypes.string,
  uploadFile: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

const defaultProps = {
  title: 'Добавление файла',
};

class DialogAddFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      file: null,
      comment: '',
      isLoading: false,
    };
  }

  handleCancel = () => {
    this.initState();
    this.props.onCancel();
  };

  handleOk = () => {
    const { props, state } = this;
    this.setState({ isLoading: true });
    const additional = {
      ...props.additional,
      create_date: new Intl.DateTimeFormat('ru').format(new Date()),
      comment: state.comment,
    };

    props.uploadFile(state.file, additional).then((res) => {
      const fileId = res.fileId;
      props.onSubmit({
        file_id: fileId,
        file_name: state.file.name,
        ...additional,
      });
      this.initState();
    });
  };

  initState() {
    const state = this.getInitialState();
    this.setState(state);
  }

  render() {
    const { open, title } = this.props;

    return (
      <DialogAction
        className="Dialog-Add-File"
        isDisabledOk={!this.state.file}
        showCondition={open}
        isLoading={this.state.isLoading}
        title={title}
        onCancel={this.handleCancel}
        onOk={this.handleOk}
      >
        <Comment
          value={this.state.comment}
          onChange={(value) => {
            this.setState({
              comment: value,
            });
          }}
          maxLength={1024}
        />
        <DndFileUpload
          accept={this.props.accept}
          add={(file) => {
            this.setState({
              file: file[0],
            });
          }}
          allowedFormatsMessage={this.props.allowedFormatsMessage}
          errorText={
            this.state.file ? '' : 'Необходимо выбрать хотя бы один файл'
          }
          files={this.state.file}
          multiple={false}
          remove={() => {
            this.setState({
              file: null,
            });
          }}
          text="Переместите файл или нажмите для выбора"
        />
      </DialogAction>
    );
  }
}

DialogAddFile.propTypes = propTypes;
DialogAddFile.defaultProps = defaultProps;

 
export default connect()(DialogAddFile);
