/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import FileListField from 'core/components/FileListField';
import { elementFormGrid } from 'app/components/card/common/grid';

const tableStyle = {
  overflow: 'auto',
  width: 'calc(100% - 24px)',
};

const labelStyle = {
  color: '#4a505b',
  textTransform: 'uppercase',
  fontSize: '18px',
  fontWeight: 500,
};

/* eslint-disable */
export default class DocumentsTable extends React.Component {
  static propTypes = {
    id: PropTypes.number,
    code: PropTypes.string,
    name: PropTypes.string,
    typeId: PropTypes.number,
    editable: PropTypes.bool,
    renderAddFileDialog: PropTypes.func,
  };

  getColumns() {
    return [
      {
        key: 'index',
        screenName: '',
        width: 50,
      },
      {
        key: 'file_name',
        screenName: 'Наименование файла',
      },
      {
        key: 'comment',
        screenName: 'Комментарий',
      },
      {
        key: 'create_date',
        screenName: 'Дата присоединения',
      },
    ];
  }

  getElements() {
    const { id: typeId, name, editable, renderAddFileDialog } = this.props;
    const id = `file_list_${typeId}`;
    return [
      {
        id,
        name: id,
        label: name,
        editable,
        style: tableStyle,
        formValue: true,
        component: FileListField,
        addition: {
          labelStyle,
          columns: this.getColumns(),
          renderAddFileDialog,
        },
      },
    ];
  }

  render() {
    const { props } = this;
    const title = '';
    const columns = 1;
    return elementFormGrid(this.getElements(), columns, title, props.typeId);
  }
}
