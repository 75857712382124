import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import {
  FENCING,
  LAMPPOSTS,
  MAF,
  OTHER_ENGIN_CONSTRUCT,
  ROAD_SIGNS,
  TRAFFIC_LIGHT,
} from 'app/constants/oghTypes';
import { PlacementIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/PlacementIdRF';
import React, { useMemo } from 'react';

const options = {
  [FENCING]: [
    20046, 20047, 20048, 20049, 20050, 20051, 20052, 20053, 20054, 20055, 20056,
    20058, 20059, 20060,
  ],
  [LAMPPOSTS]: [18, 19, 20045, 20048, 20070, 20090, 20100],
  [MAF]: [18, 19, 20045, 20048, 20080, 20090],
  [OTHER_ENGIN_CONSTRUCT]: [18, 19, 20045, 20048, 20080, 20090],
  [ROAD_SIGNS]: [18, 19, 20045, 20048, 20070, 20090, 20100],
  [TRAFFIC_LIGHT]: [18, 19, 20045, 20048, 20070, 20090, 20100],
};

/**
 * Обертка фильтрации для __Место размещения__.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const PlacementIdRFWrapper = (props) => {
  const { typeId } = useGetCurrentCard();

  const filter = useMemo(() => {
    return (dict) => {
      return dict.filter(({ id }) =>
        options[typeId] ? options[typeId].includes(id) : false,
      );
    };
  }, [typeId]);

  return (
    <PlacementIdRF
      label={
        [ROAD_SIGNS, OTHER_ENGIN_CONSTRUCT].includes(typeId)
          ? 'Место размещения (исключено с 04.04.2023)'
          : 'Место размещения (наименование плоскостного элемента)'
      }
      filter={filter}
      {...props}
    />
  );
};
