 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Уточнение__.
 *
 * @type {{ accessor: 'buildings_type_spec',  Header: 'Уточнение', sortable: true, }}
 * @augments Cell
 */
export const buildings_type_spec = {
  accessor: 'buildings_type_spec',
  Header: 'Уточнение',
  sortable: true,
};
