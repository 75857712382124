/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Checkbox from 'core/newComponents/Checkbox';

import { WithReduxFormSelect } from 'app/components/common/SelectField';

import Table from 'app/components/common/table/Table';
import DialogWithTableControls from 'app/components/dialogs/common/DialogWithTableControls';
import { editCardClearDialog } from 'app/actions/odsObjectActions';
import TableErrorText from 'app/components/common/TableErrorText';

import {
  renderCustomTextField,
  elementFormGrid,
} from 'app/components/card/common/grid';

import getDict from 'app/selectors/getDict';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';

const mandatoryFields = ['explication_type_id', 'explication_area'];

const dialogFields = (props) => [
  {
    id: 'explication_type_id',
    name: 'explication_type_id',
    editable: true,
    component: WithReduxFormSelect,
    addition: {
      label: 'Вид экспликации земли',
      options: props.explicationTypes,
      required: true,
    },
  },
  {
    id: 'explication_area',
    name: 'explication_area',
    label: 'Площадь, Га',
    editable: true,
    component: renderCustomTextField,
    addition: {
      type: 'decimal',
      positive: true,
      zero: false,
      digits: 2,
      required: true,
    },
  },
];

class TerritoryCharacteristicTable extends React.Component {
  static propTypes = {
    input: PropTypes.object,
  };

  onAdd = () => {
    const table = this.getTable();
    this.props.input.onChange({
      table,
      showDialog: true,
    });
  };

  onCancel = () => {
    const table = this.getTable();
    this.props.input.onChange({
      table,
      showDialog: false,
    });
    this.props.editCardClearDialog();
  };

  onCheckCheckbox(event, value, index) {
    const table = this.getTable();
    const character = {
      ...table[index],
      checked: value,
    };

    const newTable = table.slice();
    newTable.splice(index, 1, character);

    this.props.input.onChange({
      table: newTable,
      showDialog: false,
    });
  }

  onRemove = () => {
    const table = this.getTable();
    const newTable = table.filter((item) => !item.checked);
    this.props.input.onChange({
      table: newTable,
      showDialog: false,
    });
  };

  onSubmit = (formValues) => {
    validateRequiredFieldsAsync(formValues, mandatoryFields);

    const table = this.getTable();
    const value = table.slice();
    if (Object.keys(formValues).length) {
      value.push(formValues);
    }
    this.props.input.onChange({
      table: value,
      showDialog: false,
    });

    this.props.editCardClearDialog();
  };

  getContainerStyle() {
    return {
      marginBottom: '15px',
      ...this.props.style,
    };
  }

  getTable() {
    const { input } = this.props;
    return (input.value && input.value.table) || [];
  }

  render() {
    const { props } = this;

    const resultTable = {
      columns: [
        {
          key: 'explication_type_id',
          screenName: 'Вид экспликации земли',
          sortable: false,
          databaseName: null,
          cells: [],
        },
        {
          key: 'explication_area',
          screenName: 'Площадь, Га',
          sortable: false,
          databaseName: null,
          cells: [],
        },
        {
          key: 'explication_area_percent',
          screenName: 'Площадь, %',
          disabled: true,
          sortable: false,
          databaseName: null,
          cells: [],
        },
      ],
    };

    const table = this.getTable();
    const showDialog =
      (props.input.value && props.input.value.showDialog) || false;

    table.forEach((item) => {
      resultTable.columns.forEach((column) => {
        let cellValue;
        if (column.key === 'explication_type_id') {
          const explicationType = props.explicationTypes.find(
            (type) => type.id === item[column.key],
          );
          cellValue = explicationType && explicationType.name;
        } else {
          cellValue = item[column.key];
        }
        column.cells.push(cellValue);
      });
    });

    if (!props.disabled) {
      resultTable.columns.unshift({
        key: 'delete',
        screenName: '',
        sortable: false,
        databaseName: null,
        cells: [],
      });
      table.forEach((item, index) => {
        resultTable.columns[0].cells.push(
          <Checkbox
            checked={item.checked || false}
            onChange={(event, value) =>
              this.onCheckCheckbox(event, value, index)
            }
          />,
        );
      });
    }

    return (
      <div style={this.getContainerStyle()}>
        <TableErrorText
          errorText={(props.meta && props.meta.error) || null}
          visible={!props.disabled}
        />
        <DialogWithTableControls
          arrayFields={dialogFields(props)}
          className="Dialog-Add-Explication-Type-Info"
          countColumnForm={1}
          customValidate={(formValues) => validate(formValues, props)}
          enableRemove={table.some((item) => item.checked)}
          inEdit={!props.disabled}
          nameDialog="add_explication_type_info"
          renderFunction={elementFormGrid}
          showCondition={showDialog}
          title="Добавление нового вида экспликации земли"
          onAdd={this.onAdd}
          onCancel={this.onCancel}
          onRemove={this.onRemove}
          onSubmit={this.onSubmit}
        />
        <Table
          height={table.length * 50 + 50}
          table={resultTable}
          width={1200 + (props.disabled ? 0 : 50)}
          widths={(name) => (name === '' ? 50 : 400)}
        />
      </div>
    );
  }
}

const validate = (formValues, props) =>
  validateNumeric(formValues, props.numericFields);

const getExplicationTypes = getDict('explicationTypes');

const mapStateToProps = (state) => {
  const numericFields = [
    {
      name: 'explication_area',
      type: 'decimal',
      positive: true,
      zero: false,
    },
    {
      name: 'explication_area_percent',
      type: 'decimal',
      positive: true,
      zero: false,
    },
  ];
  return {
    explicationTypes: getExplicationTypes(state),
    numericFields,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editCardClearDialog: () => {
      dispatch(editCardClearDialog());
    },
  };
};

 
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TerritoryCharacteristicTable);
