/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */ /**
     * Колонка __Диаметр на высоте 1,3 м., см__.
     *
     * @type {{
     * accessor: 'diameter',
     * Header: 'Диаметр на высоте 1,3 м., см',
     * sortable: true,
     * }}
     * @augments Cell
     */
export const diameter = {
  Header: 'Диаметр на высоте 1,3 м, см',
  accessor: 'diameter',
  sortable: true,
};
