import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';
import { Field } from 'redux-form';

/**
 * ## SelectWithReduxForm.
 *
 * @param {*} props - The props.
 * @param {*} props.input - The props.
 * @param {*} props.otherProps - The props.
 * @returns {JSX.Element}
 */
const SelectWithReduxForm = ({ input, ...otherProps }) => {
  return <Select {...otherProps} {...input} />;
};

/**
 * ## SelectRF.
 *
 * @param {*} props - The props.
 * @param {*} props.name - The props.
 * @param {*} props.otherProps - The props.
 * @returns {JSX.Element}
 */
export const SelectRF = ({ name, ...otherProps }) => {
  return <Field {...otherProps} name={name} component={SelectWithReduxForm} />;
};
