import { post } from 'app/api/crud';

import { prepareDataForSaveSign } from './prepareDataForSaveSign';
import { uploadFileReturnObj } from './uploadFileReturnObj';

/**
 * Сохранение подписи и файлов для подписи.
 *
 * @param {object} params - Params.
 * @param {Array<{storageId: string | number, file: File, name: string}>} params.objectCardFiles - A.
 * @param {Array<File>} params.files - Files.
 * @param {Array<File>} params.signatures - Подписи.
 * @param {object} params.certificate - Сертификат который учавствовал в подписи.
 * @param {SAVE|CLOSE|UPDATE} params.signType - Тип метода запроса.
 * @param {*} params.signInfo - Sign Info.
 * @returns {Promise<*>}
 */
export const signOghSave = (params) => async (values) => {
  const {
    certificate = {},
    files = [],
    objectCardFiles = [],
    signatures = [],
    signType,
    ...signInfo
  } = { ...values, ...params };
  const signPerson = certificate.subjectInfo;
  const signsIds = await uploadFileReturnObj(signatures);
  const newFilesIds = await uploadFileReturnObj(files);
  const filesSrc = prepareDataForSaveSign({
    files: [...objectCardFiles, ...(newFilesIds || [])],
    signatures: signsIds,
  });

  return post(`/sign/ogh/${signType}`, {
    files: filesSrc,
    signPerson,
    ...signInfo,
  });
};

/**
 * Утверждение.
 *
 * @typedef {"save"} SAVE
 */
const SAVE = 'save';

/**
 * Закрытие,.
 *
 * @typedef {"close"} CLOSE
 */
const CLOSE = 'close';

/**
 * Переутверждение.
 *
 * @typedef {"update"} UPDATE
 */
const UPDATE = 'update';

export { CLOSE, SAVE, UPDATE };
