 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
   
/* eslint-disable */
/**
 * Колонка __Высота подвески над проезжей частью, м__.
 *
 * @type {{ accessor: 'susp_height',  Header: 'Высота подвески над проезжей частью, м' }}
 * @augments Cell
 */
export const susp_height = {
  accessor: 'susp_height',
  Header: 'Высота подвески над проезжей частью, м',
  sortable: true,
};
