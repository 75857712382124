/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import MaxStorageCapacityTable from '../MaxStorageCapacityTable';

import {
  elementFormGrid,
  renderCustomTextField,
} from 'app/components/card/common/grid';

function getElements(editable, required) {
  return [
    {
      id: 'reagent_maxstorage_capacity_list',
      name: 'reagent_maxstorage_capacity_list',
      label: '',
      editable,
      style: {
        overflow: 'auto',
      },
      formValue: true,
      component: MaxStorageCapacityTable,
    },
    {
      id: 'solid_reagent_area',
      name: 'solid_reagent_area',
      label: 'Площадь для хранения твердых ПГР, кв.м.',
      editable,
      formValue: true,
      component: renderCustomTextField,
      addition: {
        type: 'decimal',
        digits: 2,
        positive: true,
        zero: false,
        required,
      },
    },
  ];
}

const MaxStorageCapacityTab = ({ typeId, editable, required }) => {
  return (
    <div
      id="reagent_maxstorage_capacity"
      style={{ position: 'relative', zIndex: 0 }}
    >
      {elementFormGrid(getElements(editable, required), 1, 'Виды ПГР', typeId)}
    </div>
  );
};

MaxStorageCapacityTab.propTypes = {
  typeId: PropTypes.number.isRequired,
  editable: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
};

 
export default MaxStorageCapacityTab;
