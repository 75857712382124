/* eslint-disable */
import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import { KeyboardDatePicker } from 'core/uiKit/inputs';

const KeyboardDatePickerWithReduxForm = ({ input, ...props }) => {
  return (
    <KeyboardDatePicker error={props?.meta?.error} {...input} {...props} />
  );
};

 
 
/**
 * ## Выбор даты c клавиатуры
 *
 * ### Redux Form Field
 *
 * * `type = KeyboardDatePickerRF`
 *
 */
 
export const KeyboardDatePickerRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={name}
      component={KeyboardDatePickerWithReduxForm}
    />
  );
};

 
export default KeyboardDatePickerRF;

KeyboardDatePickerRF.propTypes = {
   
   
/**
   * быбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
/**
   * placeholder
   */
  placeholder: PropTypes.string,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
   
  /* eslint-disable */
/**
   name input
   */
  name: PropTypes.string,
   
  /* eslint-disable */
/**
   label input
   */
  label: PropTypes.string,
};
KeyboardDatePickerRF.defaultProps = {
  disabled: false,
  name: 'KeyboardDatePickerRF',
  label: 'label for KeyboardDatePickerRF',
};
