import PropTypes from 'prop-types';
import React from 'react';

/* eslint-disable */
export default class TableErrorText extends React.Component {
  static propTypes = {
    errorText: PropTypes.string,
    visible: PropTypes.bool,
  };

  render() {
    const { errorText, visible } = this.props;
    return (
      <div
        style={{
          width: 'auto',
          height: 'auto',
          padding: '1px',
          color: '#FF0000',
          display: visible ? 'inline-block' : 'none',
        }}
      >
        {errorText || ''}
      </div>
    );
  }
}
