import { sendFeedBack } from 'app/actions/navbar';
import Form from 'app/components/common/KeyForm';
import withCheckBeforeRenderDialog from 'core/hocs/withCheckBeforeRenderDialog';
import { ButtonBlue } from 'core/newComponents/Button';
import Dialog from 'core/newComponents/Dialog';
import TextField from 'core/newComponents/TextField';
import { TextFieldWithInputMask } from 'core/newComponents/TextField';
import { ButtonMUI } from 'core/uiKit/material-ui';
import flow from 'lodash/flow';
import forIn from 'lodash/forIn';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { actions as formActions } from 'react-redux-form';

import ImageInput from '../form-inputs/ImageInput';

/* eslint-disable */

/**
 * Компонент SendButton.
 *
 * @param {object} props - Пропсы.
 * @param {Function} props.onClick - Калбек.
 * @returns {JSX.Element}
 */
function SendButton({ onClick }) {
  return (
    <ButtonBlue size="medium" onClick={onClick}>
      Отправить
    </ButtonBlue>
  );
}

/**
 * Компонент CloseButton.
 *
 * @param {object} props - Пропсы.
 * @param {Function} props.onClick - Калбек.
 * @returns {JSX.Element}
 */
function CloseButton({ onClick }) {
  return (
    <ButtonMUI size="medium" variant="outlined" onClick={onClick}>
      Закрыть
    </ButtonMUI>
  );
}

/**
 * Компонент CancelButton.
 *
 * @param {object} props - Пропсы.
 * @param {Function} props.onClick - Калбек.
 * @returns {JSX.Element}
 */
function CancelButton({ onClick }) {
  return (
    <ButtonMUI size="medium" variant="outlined" onClick={onClick}>
      Отмена
    </ButtonMUI>
  );
}

/**
 * Компонент FeedBack.
 *
 * @returns {JSX.Element}
 */
class FeedBack extends React.Component {
  static propTypes = {
    feedbackForm: PropTypes.object,
    formChange: PropTypes.func,
    isSubmittedForm: PropTypes.bool,
    onSubmit: PropTypes.func,
    show: PropTypes.bool,
    userData: PropTypes.object,
  };

  /**
   * Метод жизненого цикла componentDidMount.
   *
   * @returns {void}
   */
  componentDidMount() {
    const userData = this.props.userData || {};
    const { sur_name, first_name, patronymic, email, phone } = userData;
    const full_name = (
      (sur_name || '') +
      ' ' +
      (first_name || '') +
      ' ' +
      (patronymic || '')
    ).trim();
    this.props.formChange('fio', full_name);
    this.props.formChange('email', email || '');
    this.props.formChange('phone', this.maskNumber(phone || ''));
  }

  /**
   * Метод handleClose.
   *
   * @returns {void}
   */
  handleClose = () => {
    const { onHide, onReset } = this.props;
    onHide();
    onReset();
  };

  /**
   * Метод handleSend.
   *
   * @returns {void}
   */
  handleSend = () => {
    this.props.onSubmitClick.bind(this)();
  };

  /**
   * Метод formValidators.
   *
   * @param {*} values - Значения формы.
   * @returns {object}
   */
  formValidators = (values) => {
    const errors = {};
    const emailFormat = /^[\w.\d-_]+@[\w.\d-_]+\.\w{2,4}$/i;
    const phoneFormat = /^\d{1}\s\(\d{3,4}\)\s\d{2,3}-\d{2}-\d{2}$/i;
    if (!values.fio || values.fio === '') {
      errors.fio = 'представьтесь';
    }
    if (!values.subject || values.subject === '') {
      errors.subject = 'введите тему';
    }
    if (!values.body || values.body === '') {
      errors.body = 'введите сообщение';
    }
    if (!values.phone || values.phone === '') {
      errors.phone = 'укажите телефон';
    } else if (!phoneFormat.test(values.phone)) {
      errors.phone = 'не менее 11 цифр';
    }
    if (!values.email || values.email === '') {
      errors.email = 'укажите email';
    } else if (!emailFormat.test(values.email)) {
      errors.email = 'укажите корректный email';
    }
    let sumSize = 0;
    if (values.screens) {
      values.screens.forEach((item) => {
        sumSize += item.size / 1048576;
      });
    }
    if (sumSize > 10) {
      errors.screens = 'суммарный размер не должен превышать 10Мб';
    }
    return errors;
  };

  /**
   * Метод formValidators.
   *
   * @param {*} value - Значение формы.
   * @returns {object}
   */
  maskNumber = (value) => {
    let phone = value.replace(/(\()|(\))|(-)|(\+)|(\s)/g, '');
    let formPhone = '';
    if (/^[0-9]*$/.test(phone)) {
      if (phone.length === 1 || phone.length === 0) {
        formPhone = '8';
      }
      if (phone.length > 1) {
        formPhone =
          '8' +
          ' (' +
          (phone.length > 4 ? phone.substring(1, 4) : phone.substring(1));
      }
      if (phone.length > 4) {
        formPhone =
          formPhone +
          ') ' +
          (phone.length > 7 ? phone.substring(4, 7) : phone.substring(4));
      }
      if (phone.length > 7) {
        formPhone =
          formPhone +
          '-' +
          (phone.length > 9 ? phone.substring(7, 9) : phone.substring(7));
      }
      if (phone.length > 9) {
        formPhone =
          formPhone +
          '-' +
          (phone.length > 9 ? phone.substring(9, 11) : phone.substring(7));
      }
    }
    return formPhone;
  };

  styling = {
    buttonColor: 'rgba(10,10,10,0.8)',
    errorStyle: {
      height: '10px',
    },
    floatingLabelStyle: {
      color: '#000',
      fontSize: '14pt',
    },
    hoverButtonColor: 'rgba(10,10,10,1)',
    labelStyle: {
      color: 'white',
    },
  };

  renderActions = () => {
    const { isSubmittedForm } = this.props;
    return isSubmittedForm ? (
      <CloseButton onClick={this.handleClose} />
    ) : (
      <React.Fragment>
        <CancelButton onClick={this.handleClose} />
        <SendButton onClick={this.handleSend} />
      </React.Fragment>
    );
  };

  /**
   * Метод жизненного цикла render.
   *
   * @returns {JSX.Element}
   */
  render() {
    const { showCondition, feedbackForm, info, isSubmittedForm, formChange } =
      this.props;

    return (
      <Dialog
        actions={this.renderActions()}
        show={showCondition}
        title="Обратная связь"
        onHide={this.handleClose}
      >
        {isSubmittedForm === true ? (
          <div style={{ marginTop: '40px' }}>
            <div style={{ float: 'left' }}>
              <svg fill="#00FF00" height="50" viewBox="0 0 24 24" width="50">
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
              </svg>
            </div>
            <div
              style={{
                color: 'black',
                float: 'left',
                fontSize: '14pt',
                margin: '15px 0px 0px 10px',
              }}
            >
              Сообщение отправлено
            </div>
          </div>
        ) : (
          <Form model="rrf.feedback" onSubmit={this.props.onSubmit}>
            <div className="mb-4">
              <TextField
                fullWidth={true}
                helperText={info.fio && info.fio.errors.message}
                InputProps={{ readOnly: true }}
                label="ФИО"
                name="fio"
                value={feedbackForm.fio}
              />
            </div>
            <div className="mb-4">
              <TextFieldWithInputMask
                alwaysShowMask={true}
                disabled={false}
                fullWidth={true}
                helperText={info.phone && info.phone.errors.message}
                InputLabelProps={{
                  readOnly: true,
                }}
                label="Контактный телефон"
                mask="9 (999) 999-99-99"
                maskChar="X"
                name="phone"
                readOnly={true}
                value={feedbackForm.phone}
              />
            </div>
            <div className="mb-4">
              <TextField
                fullWidth={true}
                helperText={info.email && info.email.errors.message}
                InputProps={{ readOnly: true }}
                label="E-mail"
                name="email"
                value={feedbackForm.email}
              />
            </div>
            <div className="mb-4">
              <TextField
                fullWidth={true}
                helperText={info.subject && info.subject.errors.message}
                label="Тема"
                name="subject"
                value={feedbackForm.subject}
                onChange={(e) => {
                  const value = get(e, 'target.value', '');

                  formChange('subject', value, (str) =>
                    str.length > 128
                      ? `введено ${str.length} знака, максимум 128`
                      : null,
                  );
                }}
              />
            </div>
            <div className="mb-4">
              <TextField
                fullWidth={true}
                helperText={info.body && info.body.errors.message}
                label="Сообщение"
                multiline={true}
                name="body"
                rows={1}
                rowsMax={3}
                value={feedbackForm.body}
                onChange={(e) => {
                  const value = get(e, 'target.value', '');

                  formChange('body', value, (str) =>
                    str.length > 2048
                      ? `введено ${str.length} знака, максимум 2048`
                      : null,
                  );
                }}
              />
            </div>
            <div className="mb-4">
              <ImageInput
                errorText={info.screens && info.screens.errors.message}
                name="screens"
                value={feedbackForm.screens}
                onChange={(values) => (e) => {
                  const files = new Set(values);
                  files.add(e.target.files[0]);
                  formChange('screens', files);
                }}
                onDelete={(values) => (value) => {
                  const files = new Set(values);
                  files.delete(value);
                  formChange('screens', files);
                }}
              />
            </div>
          </Form>
        )}
      </Dialog>
    );
  }
}

/**
 * Функция mapStateToProps.
 *
 * @param {object} state - Стейт.
 * @returns {object}
 */
function mapStateToProps(state) {
  return {
    feedbackForm: get(state, 'rrf.feedback'),
    info: get(state, 'rrf.forms.feedback'),
    isSubmittedForm: get(state, 'navbar.feedbackSubmitted'),
    userData: get(state, 'auth.user'),
  };
}

/**
 * Функция mapDispatchToProps.
 *
 * @param {Function} dispatch - Функция диспатч.
 * @returns {object}
 */
function mapDispatchToProps(dispatch) {
  return {
    formChange: (key, value, constraint) => {
      let message = null;
      if (constraint && (message = constraint(value)) != null) {
        dispatch(formActions.setErrors('rrf.feedback.' + key, { message }));
      } else {
        dispatch(formActions.setErrors('rrf.feedback.' + key, {}));
      }
      return dispatch(formActions.change('rrf.feedback.' + key, value));
    },
    formError: (key, value) => {
      return dispatch(
        formActions.setErrors('rrf.feedback.' + key, { message: value }),
      );
    },
    onReset: () => {
      return dispatch(formActions.reset('rrf.feedback'));
    },
    onSubmit: (values) => {
      return dispatch(
        sendFeedBack({
          ...values,
          agent: navigator.userAgent,
        }),
      );
    },
    onSubmitClick: () => {
      const errors = this.formValidators(this.props.feedbackForm);
      if (!Object.keys(errors).length) {
        return dispatch(formActions.submit('rrf.feedback'));
      } else {
        forIn(errors, (value, key) => {
          this.props.formError(key, value);
        });
      }
    },
  };
}

export default flow(
  withCheckBeforeRenderDialog,
  connect(mapStateToProps, mapDispatchToProps),
)(FeedBack);
