import {
  CircularProgress,
  Icon,
  ListItem,
  ListItemIcon,
  ListItemText,
} from 'core/uiKit/material-ui';
import React from 'react';

/**
 * Компонент ListItemYard.
 *
 * @param {object} props - Пропсы.
 * @param {*} props.text - Any.
 * @param {*} props.loading - Any.
 * @param {*} props.onClick - Any.
 * @returns {JSX.Element}
 */
export const ListItemYard = ({ text, loading = false, onClick = (_) => _ }) => (
  <ListItem
    className={'ml-4'}
    button={true}
    disabled={loading}
    onClick={onClick}
  >
    <ListItemIcon>
      {loading ? <CircularProgress size={20} /> : <Icon>print</Icon>}
    </ListItemIcon>
    <ListItemText primary={text} />
  </ListItem>
);
