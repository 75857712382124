import { FieldRF } from 'core/form/reduxForm/helpers/FieldRF';
import {
  IPlanarStructureTypeRefIdProps,
  PLANAR_STRUCTURE_TYPE_REF_ID_NAME,
  PlanarStructureTypeRefId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/PlanarStructureTypeRefId';
import React from 'react';

/**
 * Уточнение  Select.RF.
 *
 * @param props - Props.
 * @returns JSX.Element.
 */
export const PlanarStructureTypeRefIdRF = (
  props: IPlanarStructureTypeRefIdProps,
) => {
  return (
    <FieldRF
      {...props}
      name={PLANAR_STRUCTURE_TYPE_REF_ID_NAME}
      component={PlanarStructureTypeRefId}
    />
  );
};
