/* eslint-disable */
import React from 'react';

import { TreePanelOgh } from 'app/pages/components';

import { tree } from './tree';

 
export const TreePanelBasePgm = ({ root }) => {
  return <TreePanelOgh root={root} tree={tree} />;
};
