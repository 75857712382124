import { cellsMainMap } from 'core/uiKit/components/cells/cellsMainMap';

/**
 * @typedef {import('src/core/uiKit/components/tables/Table/Table').Columns} Columns
 */

/**
 * Получение Columns __Плоскостные сооружения__.
 *
 * @returns {Columns} - Жопа.
 * @example ---
 */
export const getColumnsPlanarStructure = () => [
  cellsMainMap.short_root_id, // ID
  cellsMainMap.start_date, // Дата начала
  cellsMainMap.end_date, // Дата окончания
  cellsMainMap.sign_date, // Дата подписания
  cellsMainMap.sign_person, // ФИО подписанта
  cellsMainMap.object_status_name, // Статус
  {
    ...cellsMainMap.object_type_name, // Тип (назначение)
    Header: 'Тип (назначение)',
  },
  cellsMainMap.planar_structure_type_ref_name, // Уточнение
  cellsMainMap.group_id, // Является частью составного объекта
  cellsMainMap.parent_object_name, // Наименование родительского объекта
  cellsMainMap.parent_short_root_id, // Идентификатор родительского объекта
  cellsMainMap.parent_okrug_name, // Округ родительского объекта
  cellsMainMap.parent_owner_name, // Балансодержатель родительского объекта
  cellsMainMap.parent_grbs_name, // ГРБС
  cellsMainMap.total_area, // Общая площадь, кв.м
  cellsMainMap.total_area_geo, // Площадь объекта по пространственным координатам, кв.м
  {
    ...cellsMainMap.property, // Характеристика
    Header: 'Характеристика',
  },
  cellsMainMap.coating_group_name, // Вид покрытия
  cellsMainMap.coating_type_name, // Вид покрытия (уточнение)
  cellsMainMap.clean_type, // Способ уборки
  cellsMainMap.is_diff_height_mark, // Разновысотные отметки
  cellsMainMap.included_in_tl, // Входит в ТС
];
