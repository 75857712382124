import { Box, CloseIcon } from 'core/uiKit/material-ui';
import React from 'react';

const styleBox = {
  alignItems: 'center',
  borderBottom: '1px solid #363C4A',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '15px',
};

const styleIcon = {
  cursor: 'pointer',
};

/**
 * Заголовок.
 *
 * @param {object} props - Пропсы.
 * @param {Function} props.onClick - Клик.
 * @param {React.ReactNode} props.children - Дети.
 * @returns {JSX.Element}
 */
const Title = ({ onClick, children }) => {
  return (
    <Box sx={styleBox}>
      <h6 style={{ margin: 0 }}>{children}</h6>
      <CloseIcon style={styleIcon} onClick={onClick} />
    </Box>
  );
};

export default Title;
