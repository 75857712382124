/* eslint-disable  */
/**
 *
 * @param v_
 */
export const int2str = (v_) => (typeof v_ === 'number' ? v_.toString() : v_);

/**
 *
 * @param filename
 */
export const getFileExtension = (filename) => {
  const parts = filename.split('.');
  return parts.length > 1 ? parts[parts.length - 1] : '';
};
