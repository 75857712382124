import { post } from 'app/api/crud/post';

/**
 * Метод сохранения ЭП на сервере.
 *
 * @param {object} params - Параметры.
 * @returns {Promise<*|Response>}
 */
export const postOghSignAct = async (params) => {
  return post('/sign/ogh/signAct', params);
};
