import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { CleanCoverArea } from 'app/components/card/ogh/ImprovementTerritoryCard/components/CleanCoverArea';
import { LawnArea } from 'app/components/card/ogh/ImprovementTerritoryCard/components/LawnArea';
import { DataPassport } from 'app/components/card/ogh/ImprovementTerritoryCard/components/MainParamsTab/DataPassport';
import { getHintAutoCleanArea } from 'app/components/card/ogh/ImprovementTerritoryCard/components/MainParamsTab/hints/hint.autoCleanArea';
import { getHintManualCleanArea } from 'app/components/card/ogh/ImprovementTerritoryCard/components/MainParamsTab/hints/hint.manualСleanArea';
import { getHintTotalArea } from 'app/components/card/ogh/ImprovementTerritoryCard/components/MainParamsTab/hints/hint.totalArea';
import { getHintTotalCleanArea } from 'app/components/card/ogh/ImprovementTerritoryCard/components/MainParamsTab/hints/hint.totalCleanArea';
import { OZN, SPA, YARD } from 'app/constants/oghTypes';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { NumberFieldRF } from 'core/form/reduxForm/fields/default/NumberField.RF';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/textField/TotalAreaGeo.RF';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { GroupContainer } from 'core/uiKit/components/card.components/GroupContainer';
import { NumberField } from 'core/uiKit/inputs/NumberField';
import React from 'react';

/**
 * Таб MainParamsTab.
 *
 * @returns {React.ReactElement}
 */
export const MainParamsTab = () => {
  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();
  const { editMode } = useMode();
  const { typeId, calcAttribute } = useGetCurrentCard();

  return (
    <div id={'squares'}>
      <FieldsSplitColumns>
        <NumberField
          label={'Общая площадь объекта, кв.м'}
          name={'total_area'}
          value={calcAttribute?.total_area || 0}
          disabled={true}
          decimalScale={2}
          helpTitle={getHintTotalArea(typeId)}
        />
        {[YARD, OZN].includes(typeId) ? (
          <TotalAreaGeoRF disabled={true} decimalScale={2} fixedDecimalScale />
        ) : null}
      </FieldsSplitColumns>
      <GroupContainer title={'Уборочная площадь'}>
        <FieldsSplitColumns>
          <NumberField
            decimalScale={2}
            label={'Площадь ручной уборки, кв. м'}
            name={'manual_clean_area'}
            value={calcAttribute?.manual_clean_area || 0}
            disabled={true}
            helpTitle={getHintManualCleanArea(typeId)}
          />
          <NumberField
            decimalScale={2}
            label={'Площадь механизированной уборки, кв. м'}
            name={'auto_clean_area'}
            value={calcAttribute?.auto_clean_area || 0}
            disabled={true}
            helpTitle={getHintAutoCleanArea(typeId)}
          />
          <NumberField
            decimalScale={2}
            label={'Общая уборочная площадь, кв.м'}
            name={'total_clean_area'}
            value={calcAttribute?.total_clean_area || 0}
            disabled={true}
            helpTitle={getHintTotalCleanArea(typeId)}
          />
        </FieldsSplitColumns>
      </GroupContainer>
      <CleanCoverArea />
      <LawnArea />
      <DataPassport />
      <GroupContainer title={'Площадь вывоза снега'}>
        <NumberFieldRF
          required={true}
          decimalScale={2}
          label={'Площадь вывоза снега, кв. м'}
          name={'snow_clean_area'}
          disabled={!editMode}
        />
      </GroupContainer>
      {typeId === SPA ? (
        <GroupContainer title={'Участки, занятые борщевиком'}>
          <NumberFieldRF
            decimalScale={2}
            label={'Участки, занятые борщевиком, кв.м'}
            name={'cow_parsnip_area'}
            disabled={!editMode}
          />
        </GroupContainer>
      ) : null}
      {typeId === SPA ? (
        <GroupContainer title={'Луга и низины'}>
          <NumberFieldRF
            decimalScale={2}
            label={'Луга и низины, кв.м'}
            name={'meadow_lowland_area'}
            disabled={!editMode}
          />
        </GroupContainer>
      ) : null}

      <GroupContainer title={'Водоемы'}>
        <NumberFieldRF
          decimalScale={2}
          label={'Водоемы, кв. м'}
          name={'reservoir_area'}
          disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
        />
      </GroupContainer>
    </div>
  );
};

/**
 * Функция MainParamsTab.
 *
 * @param {*} params - Параметры.
 * @returns {Function}
 */
export default function (params) {
  return (props) => <MainParamsTab {...props} {...params} />;
}
