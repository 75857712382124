import { showAlert, showNotification } from 'app/actions/dialog';
import {
  afterReceiving,
  cancelLeaveCurrentElement,
  viewCard,
} from 'app/actions/odsObjectActions';
import {
  fetchFewTree,
  fetchTreeSelectedElement,
} from 'app/actions/odsObjectTreeActions';
import { toggleCompareReportVisibility } from 'app/actions/odsObjectVersionsActions';
import { fetchMatchingTechnik } from 'app/actions/technologyObject';
import { connect } from 'react-redux';

import TechCard from './card';
/* eslint-disable */
function mapStateToProps(state) {
  return {
    card: state?.card?.object,
    mode: state?.card?.mode,
    versionsModal: state?.modals?.versions,
    treeModal: state?.modals?.treeEdit,
    treeTechModal: state?.modals?.treeEdit,
    groupParent: state?.modals?.groupParent,
    matching: state?.matching?.data,
    matchingRegistryColumns: state?.tableColumns?.objectMatchingRegistry,
    action: state?.matching?.afterAction,
    goToRegistry: state?.matching?.goToRegistry,
    viewMatchingTechMode: true,
  };
}

const mapDispatchToProps = {
  viewCard,
  afterReceiving,
  fetchRootCard: fetchMatchingTechnik,
  cancelLeaveCurrentElement,
  toggleCompareReportVisibility,
  showAlert,
  showNotification,
  fetchTreeSelectedElement,
  fetchFewTree,
};

 
export default connect(mapStateToProps, mapDispatchToProps)(TechCard);
