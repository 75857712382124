import React, { FC, memo } from 'react';
import { InputPreparedProps, InputProps } from 'types/inputs';

//-- WARNING: Плохая практика приводит к незапланированному перерендуру компонента, замечено некорректное поведение на NumberField компонентах

/**
 * Обертка для input FinalForm.
 *
 * @param {FC} Component - Компонент который оборачиваем.
 * @returns - Компонент обернутый в форму FF.
 */
export const withFinalForm = (
  Component: FC<InputPreparedProps & Record<string, unknown>>,
) => {
  const ComponentMemo = memo(
    ({ input, ...props }: { input: InputProps; [key: string]: unknown }) => {
      return <Component {...props} {...input} />;
    },
  );

  // Создаем новый компонент с пользовательским свойством
  ComponentMemo.displayName = '_' + Component.name + '_WithFinalForm';
  return ComponentMemo;
};
