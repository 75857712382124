import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

import { AuthTokensDTW_Type } from '../../types';
import { refreshDTWToken } from './api/refreshDTWToken';
import { useGetDTWTokens } from './useGetDTWTokens';

export type RefreshDTwTokensOptions = Parameters<
  typeof useMutationAdaptor<
    AuthTokensDTW_Type,
    Error,
    Required<AuthTokensDTW_Type['refreshToken']>
  >
>[1];

/**
 * Hook получения токенов авторизации
 * в цифровом двойнике.
 *
 * @returns Результат рефреша токенов в цифровом двойнике { accessToken?: string, refreshToken?: string }.
 */
export const useRefreshDTWTokens = () => {
  const { setTokens, clearTokens } = useGetDTWTokens();

  return useMutationAdaptor<
    AuthTokensDTW_Type,
    Error,
    Required<AuthTokensDTW_Type['refreshToken']>
  >((refreshToken) => refreshDTWToken(refreshToken || ''), {

    /**
     * Обработчик ошибки.
     */
    onError: () => {
      clearTokens();
    },

    /**
     * Обработчик успеха.
     *
     * @param loginData - Данные логина.
     */
    onSuccess: (loginData) => {
      const { accessToken = '', refreshToken = '' } = loginData || {};
      setTokens({ accessToken, refreshToken });
    },
  });
};
