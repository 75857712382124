import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const CLEAN_CATEGORY_ID_NAME = 'clean_category_id';

/**
 * Поле __Категория по уборке__.
 *
 * @param props - Пропсы.
 * @returns {JSX.Element}
 */
export const CleanCategoryId = (props: object) => {
  const { data = [] } = useGetDictionary('clean_category');

  const options = data.map((item) => ({
    id: item.id,
    // @ts-ignore
    isAvd: item.attribute.is_avd,
    // @ts-ignore
    name: item.attribute.name,
  }));

  return (
    <Select
      options={options}
      {...props}
      label={'Категория по уборке'}
      name={CLEAN_CATEGORY_ID_NAME}
    />
  );
};
