import { Button } from 'core/uiKit/components/buttons/Button';
import { Icon } from 'core/uiKit/material-ui';

import { useCloseOghButton } from './useCloseOghButton';

/* eslint-disable */
export const CloseOghButton = () => {
  const { isShow, onClose } = useCloseOghButton();

  return !!isShow ? (
    <Button
      onClick={onClose}
      color="error"
      variant={'contained'}
      startIcon={<Icon>delete</Icon>}
    >
      закрыть
    </Button>
  ) : null;
};
