 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Вертикальные конструкции. Иные элементы, кв.м__.
 *
 * @type {{
 * accessor: 'flowers_vertical_design_area',
 * Header: 'Вертикальные конструкции. Иные элементы, кв.м',
 * }}
 * @augments Cell
 */
export const flowers_vertical_design_area = {
  accessor: 'flowers_vertical_design_area',
  Header: 'Вертикальные конструкции. Иные элементы, кв.м',
  sortable: true,
};
