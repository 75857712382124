export const schedule_status = [
  {
    id: 1,
    name: 'Отправлен на согласование',
  },
  {
    id: 2,
    name: 'Отклонен',
  },
  {
    id: 3,
    name: 'Согласован ОИВ',
  },
  {
    id: 4,
    name: 'Согласован ДПиООС',
  },
  {
    id: 5,
    name: 'Утвержден МКА',
  },
  {
    id: 6,
    name: 'Аннулирован',
  },
  {
    id: 7,
    name: 'Исполнен',
  },
];
