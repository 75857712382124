import { useFetchOghTable } from 'app/pages/registry/ogh/api';
import {
  Table,
  useParamsTable,
} from 'core/uiKit/components/tables/Table';
import React from 'react';

import { getColumnsOzn } from './columns.Ozn';
import { useFilterQueryParamsYard } from './Filter.Ozn';

 
/**
 * Компонент таблицы __Объекты озеленения__.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - Id типа огх.
 * @returns {JSX.Element} - JSX.
 * @example -----
 * <TableOzn
 *  typeId={typeId}
 * />
 */
export const TableOzn = ({ typeId }) => {
  const { prepareFilters } = useFilterQueryParamsYard();
  const { queryTable } = useParamsTable();
  const queryKey = [
    'TableOtherOks',
    {
      ...queryTable,
      ...prepareFilters,
      typeId,
    },
  ];

  const {
    data: table,
    isLoading,
    count,
  } = useFetchOghTable(queryKey);

  return (
    <Table
      columns={getColumnsOzn()}
      data={table || []}
      isLoading={isLoading}
      total={count}
    />
  );
};
