import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { MultiSelect } from 'core/uiKit/inputs/selects/MultiSelect';
import React from 'react';

export const CHANGE_TYPE_NAME = 'change_types';

/**
 * ## Тип изменения.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const ChangeType = (props) => {
  const { filter = (_) => _ } = props;
  const dict = 'changeType';
  const { data = [] } = useGetDictionary(dict);
  const options = filter(data);

  return (
    <MultiSelect
      {...props}
      options={options}
      optionKey={'code'}
      label={'Тип изменения'}
      name={CHANGE_TYPE_NAME}
    />
  );
};
