import './TabsCardPanel.scss';

/**
 * Компонент табы навешивает класс на div.
 *
 * @param {object} props - Пропсы.
 * @param {JSX.Element} props.children - Реакт компонент.
 * @param {boolean} props.show - Флаг скрывать или показывать.
 * @returns {JSX.Element} - Возвращает выбраную табу.
 * @example ----------
 */
export const TabsCardPanel = ({ children, show }) => {
  return show ? children : null;
};
