import '../../../Document.scss';

import { FieldsContainer } from 'app/pages/cardsOgh/components/FieldsContainer';
import { TableSimple } from 'core/uiKit/components/tables/TableSimple';
import React from 'react';

import { columnsActOfCompletedWorkElectronicSignature } from './columns.ActOfCompletedWork.ElectronicSignature';
import { SignActOfCompletedButton } from './SignActOfCompleted.Button';
import { useSetupActOfCompletedWorkElectronicSignature } from './useSetup.ActOfCompletedWork.ElectronicSignature';

/**
 * Подписание документа.
 *
 * @returns {JSX.Element} - JSX.
 */
export const ActOfCompletedWorkElectronicSignature = () => {
  const { dataTable, data, disabled, selectRow } =
    useSetupActOfCompletedWorkElectronicSignature();

  return (
    <FieldsContainer>
      <div className={'table-documents'}>
        <div className={'table-documents-header__container'}>
          <h5 className={'mt-3'}>
            Акты сдачи-приемки выполненных работ по изготовлению цифровой
            геоподосновы
          </h5>
          <div className={'table-documents-header__button-container'}>
            <SignActOfCompletedButton disabled={disabled} />
          </div>
        </div>
        <div>
          <TableSimple
            data={dataTable}
            columns={columnsActOfCompletedWorkElectronicSignature}
            selectedRows={selectRow}
            checkboxSelection={!data.sign_status}
          />
        </div>
      </div>
    </FieldsContainer>
  );
};
