import { CUSTOMER_OZN_NAME, CustomerOznFF } from 'core/form/finalForm/fields';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';
import { ObjectParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

/**
 * Заказчик  ozn   customer
 * place=ozn.
 *
 * @param {*} params - Параметры компонента.
 * @returns {JSX.Element}
 */
export const CustomerOznFilter = (params) => {
  return <CustomerOznFF {...params} className={filterInput} />;
};

export { CUSTOMER_OZN_NAME };

/**
 * CustomerOznParams.
 *
 * @returns {object}
 */
export const customerOznParams = () => {
  addPreparation(CUSTOMER_OZN_NAME, (value) => value.id);
  return {
    [CUSTOMER_OZN_NAME]: ObjectParam,
  };
};
