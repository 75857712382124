/* eslint-disable */
import React from 'react';
import { StringParam } from 'use-query-params';

import { TextFieldFF } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
export const SERIAL_NUMBER_NAME = 'serialNumberName';

 
 
/**
 * Серийный номер
 */
 
export const SerialNumberFilter = () => {
  return (
    <TextFieldFF
      className={filterInput}
      label={'Серийный номер'}
      name={SERIAL_NUMBER_NAME}
    />
  );
};

 
export const serialNumberParams = {
  [SERIAL_NUMBER_NAME]: StringParam,
};
