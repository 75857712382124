import { post } from 'app/api/crud/post';

type ParamsNoCalcType = {
  parentId?: number;
  childIdsList: number[];
  changeAttribute: {
    noCalc: number;
  };
};

/**
 * Fn.
 *
 * @param params - Parameters.
 * @returns {*}
 */
export const postSendNoCalc = (params: ParamsNoCalcType) => {
  return post('/massive_change_attribute', params);
};
