import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Create a client

export const queryClientDefaultForTesting = new QueryClient();

/**
 * A.
 *
 * @param {*} root0 - Props.
 * @param {*} root0.children - Ch.
 * @param {*} root0.queryClient - QueryClient.
 * @returns {*}
 */
export const ReactQueryCacheProviderWrapper = ({
  children,
  queryClient = queryClientDefaultForTesting,
}) => {
  return (
    // Provide the client to your App
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
