import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { AccordionContent } from 'app/pages/cardsOgh/components/RightPanelCard/components/TreeRightPanel/Accordion';
import { ElementTree } from 'app/pages/cardsOgh/components/RightPanelCard/components/TreeRightPanel/Tree';
import formatRootId from 'app/utils/formatRootId';
import { CopyToClipboard } from 'core/uiKit/components/CopyToClipboard';
import { Link } from 'core/uiKit/components/Link';
import { useGetParamsUrl } from 'core/utils/hooks/useGetParamsUrl';
import React, { FC } from 'react';
interface ElementsTreeProps {
  elements: ElementTree[];
}

/**
 * Элемент аккордеона самый нижний. Объект огх.
 *
 * @param props - Props.
 * @param props.elements - Элементы огх.
 * @returns {JSX.Element}
 */
export const ElementsTree: FC<ElementsTreeProps> = ({ elements }) => {
  const { parentId } = useGetParamsUrl();
  const { rootId: currentRootId } = useGetCurrentCard();

  return elements.map(({ rootId, title, id }) => {
    const to = parentId ? `/ogh/${parentId}/${id}` : `/ogh/${id}`;
    return (
      <AccordionContent key={rootId} isActive={+currentRootId === +rootId}>
        {/*// @ts-ignore*/}
        <CopyToClipboard textForCopy={`${formatRootId(rootId)} ${title}`}>
          <Link to={to}>{`${formatRootId(rootId)} ${title}`}</Link>
        </CopyToClipboard>
      </AccordionContent>
    );
  });
};
