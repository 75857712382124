import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';

import { useSetupUnitIdField } from './useSetup.UnitId.Field';

/**
 * Единица измерения Select RF.
 *
 * @returns {JSX.Element}
 */
export const UnitIdField = () => {
  const { units, disabled } = useSetupUnitIdField();

  return (
    <SelectRF
      label={'Единица измерения'}
      options={units}
      name={'unit_id'}
      disabled={disabled}
      required={true}
      withAll={false}
    />
  );
};
