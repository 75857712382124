import asyncValidate from './asyncValidate';

describe('🐛 spec asyncValidate', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)

    // 🧹 clear mock

    //🔥 Act
    const res = asyncValidate({});

    //❓ Assert
    expect(res).toStrictEqual({
      name_description:
        'Обязательно при отсутствии объектов капитального строительства',
    });
  });
});
