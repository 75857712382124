import NavItem from 'app/components/common/NavBar/NavItem';
import { pathAdmin } from 'app/pages/admin/path.Admin';
import { pathRoles } from 'app/pages/admin/Roles/path.Roles';
import { useUser } from 'app/pages/providers';
import { lazy, useEffect } from 'react';
/* eslint-disable */
function lazyWithPreload(factory) {
  const Component = lazy(factory);
  Component.preload = factory;
  return Component;
}

const PatternMatching = lazyWithPreload(() => import('app/pages/admin/Roles'));

 
export const ViewRolesLink = () => {
  const { hasPermission } = useUser();

  const isShow = hasPermission(pathRoles.privilegeView);

  useEffect(() => {
    isShow && PatternMatching.preload();
  }, [isShow]);

  return isShow ? (
    <NavItem link={`/${pathAdmin.path}/${pathRoles.path}`}>
      {pathRoles.name}
    </NavItem>
  ) : null;
};
