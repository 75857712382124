// Component
import { OperationsTab } from './OperationsTab';


/* eslint-disable */
export default {
  title: 'Tabs/OperationsTab',
  decorators: [],
  component: OperationsTab,
};

/* eslint-disable */
export const Basic = (args) => {
  const id = window.operations;
  if (id) {
    id?.removeAttribute('hidden');
  }
  return <OperationsTab />;
};

Basic.args = {};
