/* eslint-disable  */
import { log } from 'core/utils/log';
import { find, get } from 'lodash';
import { createSelector } from 'reselect';

/**
 *
 * @param state
 * @param props
 */
const getCardAttributes = (state, props) => props.card.attribute;

/**
 *
 * @param state
 * @param props
 */
const getBoundStoneMarks = (state, props) => props.card.bound_stone_mark;

/**
 *
 * @param state
 * @param props
 */
const getBoundStoneMaterials = (state, props) => props.card.material;

export default createSelector(
  [getBoundStoneMarks, getBoundStoneMaterials, getCardAttributes],
  (boundStoneMarks, boundStoneMaterials, cardAttributes) => {
    let result;
    const materialId = get(cardAttributes, 'material.material');

    if (materialId) {
      const currentMaterial = find(boundStoneMaterials, {
        id: materialId,
      });
      if (!currentMaterial) {
        log.jopa(
          `cardAttributes.material.material = ${materialId} нет в справочнике boundStoneMaterials `,
          () => {
            log.table(boundStoneMaterials);
          },
        );
      }
      result = currentMaterial?.name;
    } else {
      result = cardAttributes.material_name;
    }

    return result;
  },
);
