/* eslint-disable */
import { isDevelopment, isTesting } from 'core/utils/getEnvironment';
import { banana } from 'core/utils/imageASCII';

const log = {
  _banana: (e, callback) => {
    console.groupCollapsed(
      `%c${banana} \n %c ${e}`,
      'color: #f0ad4e; font-size: 1em; line-height: 1em;',
      'color: red; font-size: 2em;',
    );

    if (typeof callback === 'function') {
      callback();
    } else {
      console.log(callback);
    }

    console.assert(false, 'Вот тут можно глянуть кто вызвал');
    console.log(' ');
    console.groupEnd();
  },
  dev: isDevelopment() ? console.log : (_) => _,
  error: isDevelopment() ? console.error : (_) => _,
  info: isDevelopment() ? console.info : (_) => _,
  message: (e, callback) => {
    console.groupCollapsed(e);

    if (typeof callback === 'function') {
      callback();
    }

    console.assert(false, 'Вот тут можно глянуть кто вызвал');
    console.log(' ');
    console.groupEnd();
  },
  table: console.table,
  /**
   * ВЫвод в консоль Варнинга.
   *
   * @param {string} e - Message.
   * @param {Function} [callback] - Callback.
   * @returns {void}
   */
  warn: (e, callback) => {
    console.groupCollapsed(
      `%c⚠️ \n%c${e}`,
      'color: #f0ad4e; font-size: 3em; line-height: 1em;',
      'color: orange; font-size: 1.5em; margin-top: 1rem; cursor: pointer',
    );

    if (typeof callback === 'function') {
      callback();
    } else {
      console.log(callback);
    }

    console.assert(false, 'Вот тут можно глянуть кто вызвал');
    console.log(' ');
    console.groupEnd();
  },
};

log.banana = isTesting() ? console.log : log._banana;

export { log };
