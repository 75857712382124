import { get } from 'lodash';
/* eslint-disable */
import { createSelector } from 'reselect';

import service from '../services/reagentMaxStorageCapacityService';
import getReagentStorageCapacityList from './getReagentStorageCapacityList';

/**
 *
 * @param state
 */
const getCurrentReagentKindId = (state) =>
  get(state, 'rrf.card.ogh.basePgmOperations.reagentKind');

/**
 *
 * @param state
 */
const getCurrentReagentId = (state) =>
  get(state, 'rrf.card.ogh.basePgmOperations.reagent');

export default createSelector(
  [getCurrentReagentKindId, getCurrentReagentId, getReagentStorageCapacityList],
  (reagentKindId, reagentId, list) =>
    service.calculateBalance(reagentKindId, reagentId, list),
);
