import 'app/components/card/ogh/index/card/RightPanel.scss';
import './MainPage.scss';

import cn from 'classnames';
import React from 'react';

import { InterceptedTypes } from './InterceptedTypes';
import LegendPanel from './LegendPanel';
import { SearchPanel } from './SearchPanel';
import { TreeMainPage } from './Tree.MainPage';
import { useSetupMainPage } from './useSetup.MainPage';

/**
 * MainPage.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX}
 */
export const MainPage = (props) => {
  const { Map } = props;

  const {
    isLoading,
    onChangeTypeId,
    typeId,
    ref,
    onClickToggle,
    open,
    getGeometryFull,
  } = useSetupMainPage();

  return (
    <div className={cn('h-100 position-relative', props.className)}>
      <SearchPanel />
      <div id="right-panel" className="right-panel">
        <div className="right-panel__header">
          <div onClick={onClickToggle} className={`right-panel__tree-toggle`}>
            <h4>Список объектов</h4>
          </div>
        </div>
        <div
          id="right-panel-content"
          ref={ref}
          style={{ display: open ? 'block' : 'none' }}
        >
          <InterceptedTypes
            onChangeTypeId={onChangeTypeId}
            typeId={typeId}
            isLoading={isLoading}
          />
          <TreeMainPage typeId={typeId} getGeometryFull={getGeometryFull} />
        </div>
      </div>

      {Map({
        editable: false,
        style: {
          bottom: 0,
          position: 'absolute',
          top: 0,
          width: '100%',
          zIndex: 0,
        },
        tileLayerRadioButtonClassName: 'tile-layer-wrap-main',
      })}

      <LegendPanel />
    </div>
  );
};
