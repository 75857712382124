// 23 Обочиныimport CardAbstract from 'app/components/card/common/CardAbstract';
import CardAbstract from 'app/components/card/common/CardAbstract';
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import { IsDiffHeightMarkField } from 'app/components/card/ogh/components/fields/checkBoxFields/IsDiffHeightMark.Field';
import { AreaField } from 'app/components/card/ogh/components/fields/numberFields/Area.Field';
import { BordBeginField } from 'app/components/card/ogh/components/fields/numberFields/BordBegin.Field';
import { BordEndField } from 'app/components/card/ogh/components/fields/numberFields/BordEnd.Field';
import { DistanceField } from 'app/components/card/ogh/components/fields/numberFields/Distance.Field';
import { WidthBeginField } from 'app/components/card/ogh/components/fields/numberFields/WidthBegin.Field';
import { WidthEndField } from 'app/components/card/ogh/components/fields/numberFields/WidthEnd.Field';
import { CleaningTypeField } from 'app/components/card/ogh/components/fields/selectFields/CleaningType.Field';
import { CoatingGroupIdField } from 'app/components/card/ogh/components/fields/selectFields/CoatingGroupId.FIeld';
import { FlatElementTypeIdField } from 'app/components/card/ogh/components/fields/selectFields/FlatElementTypeId.Field';
import { MarginStrengTypeIdField } from 'app/components/card/ogh/components/fields/selectFields/MarginStrengTypeId.Field';
import { OdhSideIdField } from 'app/components/card/ogh/components/fields/selectFields/OdhSideId.Field';
import { DescriptionField } from 'app/components/card/ogh/components/fields/textFields/Description.Field';
import { OdhAxisField } from 'app/components/card/ogh/components/fields/textFields/OdhAxis.Field';
import {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import {
  AUTO_CLEANING_TYPE,
  MANUAL_CLEANING_TYPE,
} from 'app/constants/cleaningTypes';
import getCleaningType from 'app/selectors/card/getCleaningType';
import getMarginStrengTypes from 'app/selectors/card/getMarginStrengTypes';
import hasFieldValue from 'app/selectors/card/hasFieldValue';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import getFormValues from 'app/selectors/form/getFormValues';
import { mandatoryFields } from 'app/utils/card/isMandatory';
import { transformValues } from 'app/utils/form/transformation';
import { OtherDocumentsRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/OtherDocuments.RF';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/textField/TotalAreaGeo.RF';
import { CardContainer } from 'core/uiKit/components/card.components/CardContainer';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { GroupContainer } from 'core/uiKit/components/card.components/GroupContainer';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';

import printOghCardHeader from './header/oghHeader';
import submitCard from './submit';
import VersionsTemplate from './versions/VersionsTemplate';

const types = ['margin'];
const Versions = VersionsTemplate(types);

const tabs = [
  { id: 'characteristics-tab', name: 'Исходные данные' },
  { id: 'docs-tab', name: 'Документы' },
];

const propTypes = {
  card: PropTypes.object,
  changeFieldValue: PropTypes.func,
  createMode: PropTypes.bool,
  documents: PropTypes.object,
  editMode: PropTypes.bool,
  formValues: PropTypes.object,
  marginStrengTypes: PropTypes.array,
  mode: PropTypes.object,
  required: PropTypes.bool,
};

/**
 * Классовый компонет MarginCard.
 */
class MarginCard extends React.Component {

  /**
   * Метод жизненого цикла render().
   *
   * @returns {JSX.Element} - Реакт компонент.
   * @example ----
   */
  render() {
    const {
      card,
      changeFieldValue,
      createMode,
      isFieldValue,
      marginStrengTypes,
      mode,
    } = this.props;

    return (
      <CardContainer>
        <CardAbstract>
          <div id="editor-card">
            {printOghCardHeader(card, mode)}
            {card && objectTabs(card.type_id)}

            <div id="props">
              <FieldsSplitColumns>
                <FlatElementTypeIdField />
                <IsDiffHeightMarkField />
                <ParentInfo />
              </FieldsSplitColumns>

              <Tabs tabs={tabs} />

              <div id="characteristics">
                <GroupContainer title={'Местоположение'}>
                  <FieldsSplitColumns>
                    <OdhAxisField />
                    <OdhSideIdField />
                    <BordBeginField />
                    <BordEndField />
                  </FieldsSplitColumns>
                </GroupContainer>
                <GroupContainer title={'Характеристики'}>
                  <FieldsSplitColumns>
                    <CoatingGroupIdField
                      label={'Тип укрепления'}
                      withChange={() => {
                        changeFieldValue('margin_streng_type_id', null);
                      }}
                    />
                    <MarginStrengTypeIdField
                      required={isFieldValue}
                      options={marginStrengTypes}
                    />
                    <DistanceField />
                    <AreaField />
                    <TotalAreaGeoRF disabled={true} decimalScale={2} />
                    <WidthBeginField />
                    <WidthEndField />
                  </FieldsSplitColumns>
                </GroupContainer>
                <GroupContainer title={'Уборочные данные'}>
                  <CleaningTypeField withNoCleaning />
                </GroupContainer>
                <DescriptionField />
              </div>
              <div hidden={true} id={'docs'}>
                <OtherDocumentsRF />
              </div>
            </div>
            <div hidden={true} id="versions">
              <Versions
                card={card}
                disabled={createMode}
                key={`versions-${card.root_id}`}
              />
            </div>
          </div>
        </CardAbstract>
      </CardContainer>
    );
  }
}

MarginCard.propTypes = propTypes;

const marginDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

/**
 * Функция получения и установки изначальных значений из State.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {object} - Поля.
 * @example ----
 */
const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);

  const result = {
    createMode: isCreateMode(state, props),

    editMode: isEditMode(state, props),

    enableReinitialize: true,

    formValues: getFormValues(state),
    initialValues: {
      area: parse('area'),
      auto_clean_area: parse('auto_clean_area'),
      bord_begin: parse('bord_begin'),
      bord_end: parse('bord_end'),
      cleaning_type: getCleaningType(state, props),
      coating_group_id: parse('coating_group_id.coating_group'),
      customer_id: parse('customer_id'),
      description: parse('description'),
      distance: parse('distance'),
      endDate: parseDate(props.card.end_date),
      flat_element_type_id: parse('flat_element_type_id.flat_element_type'),
      is_diff_height_mark: parse('is_diff_height_mark'),
      manual_clean_area: parse('manual_clean_area'),
      margin_streng_type_id: parse('margin_streng_type_id.margin_streng_type'),
      no_clean_area: parse('no_clean_area'),
      odh_axis: parse('odh_axis'),
      odh_side_id: parse('odh_side_id.odh_side'),
      owner_id: parse('owner_id'),
      parent_name: props.card.parent_info,
      rotor_area: parse('rotor_area'),
      snow_area: parse('snow_area'),
      startDate: parseDate(props.card.start_date),
      totalAreaGeo: parse('total_area_geo'),
      width_begin: parse('width_begin'),
      width_end: parse('width_end'),
    },
    isFieldValue: hasFieldValue(state, 'coating_group_id'),
    marginStrengTypes: getMarginStrengTypes(state, props),
    onSubmit: onSubmit.bind({ props }),

    required: isRequired(state, props),
    validate,
  };

  return marginDocumentsReducer(result, state, props);
};

/**
 * Функция создания атрибутов.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {object} - Возвращают атрибуты.
 * @example ----
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

const numericFields = [
  { name: 'bord_begin', type: 'decimal', zero: true },
  { name: 'bord_end', type: 'decimal', zero: true },
  {
    name: 'area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'distance',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'width_begin',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'width_end',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'auto_clean_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'manual_clean_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'no_clean_area',
    positive: true,
    type: 'decimal',
    zero: true,
  },
  {
    name: 'snow_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'rotor_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
];

const dictFields = [
  'odh_side_id',
  'flat_element_type_id',
  'margin_streng_type_id',
  'coating_group_id',
];

/**
 * Функция валидации.
 *
 * @param {*} values - Значения формы.
 * @returns {*}
 */
export const validate = (values) => validateNumeric(values, numericFields);

/**
 * Функция сабмита.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {void} - Жопа.
 * @example ----
 */
export function onSubmit(formValues) {
  const { card } = this.props;
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };

  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[card.type_id],
  );
  formValuesWithAttribute.is_diff_height_mark = Boolean(
    formValuesWithAttribute.is_diff_height_mark,
  );

  formValuesWithAttribute.manual_clean_area = 0;
  formValuesWithAttribute.auto_clean_area = 0;
  formValuesWithAttribute.no_clean_area = 0;

  if (formValuesWithAttribute.cleaning_type === MANUAL_CLEANING_TYPE) {
    formValuesWithAttribute.manual_clean_area = formValuesWithAttribute.area;
  } else if (formValuesWithAttribute.cleaning_type === AUTO_CLEANING_TYPE) {
    formValuesWithAttribute.auto_clean_area = formValuesWithAttribute.area;
  } else {
    formValuesWithAttribute.no_clean_area = formValuesWithAttribute.area;
  }
  delete formValuesWithAttribute.cleaning_type;

  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, {
      dictFields,
      numericFields,
    }),
  );
}

/**
 * Функция получения экшенов.
 *
 * @param {Function} dispatch - Функция диспатч.
 * @returns {{changeFieldValue: mapDispatchToProps.changeFieldValue}} - Экшен changeFieldValue.
 * @example ----
 */
const mapDispatchToProps = (dispatch) => ({

  /**
   * Функция изменения значения формы.
   *
   * @param {string} field - Имя поля.
   * @param {string} value - Значение.
   */
  changeFieldValue: (field, value) => {
    dispatch(change('editorCard', field, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'editorCard' })(MarginCard));
