import { HIDE_DIALOG, SHOW_DIALOG } from 'app/constants/actionTypes';
import { dialogType } from 'app/constants/dialogTypes';
import { renderA } from 'core/utils/renders/renderA';
/* eslint-disable */
const showDialog = (type, props) => ({
  type: SHOW_DIALOG,
  payload: {
    type,
    props: {
      showCondition: true,
      ...props,
    },
  },
});

const hideDialog = () => ({
  type: HIDE_DIALOG,
});

const showAlert = (message, props) => {
  return showDialog(dialogType.NOTIFICATION, {
    ...props,
    children: renderA(message),
  });
};

const showNotification = (message, onOk, onCancel) =>
  showDialog(dialogType.CONFIRMATION, {
    children: message,
    onOk,
    onCancel,
  });

const showError = (message, onAfterHiding) =>
  showDialog(dialogType.ERROR_NOTIFICATION, {
    children: message,
    onAfterHiding,
  });

/* eslint-disable */
export { hideDialog, showAlert, showNotification, showError };
