import { shallow } from 'enzyme';
import React from 'react';
import { vi } from 'vitest';

import { PlanarStructureTypeIdField } from './PlanarStructureTypeId.Field';
import { useSetupPlanarStructureTypeIdField } from './useSetup.PlanarStructureTypeId.Field';

vi.mock('./useSetup.PlanarStructureTypeId.Field');

describe('🐛 spec PlanarStructureTypeId.Field', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    useSetupPlanarStructureTypeIdField.mockReturnValue({});

    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(<PlanarStructureTypeIdField />);

    //❓ Assert
    expect(wrapper.text()).toBe('<PlanarStructureTypeIdRF />');
  });

  it('🧪 props', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    useSetupPlanarStructureTypeIdField.mockReturnValue({
      filter: 'filter',
      isEdit: 'isEdit',
    });

    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(<PlanarStructureTypeIdField />);

    //❓ Assert
    expect(wrapper.props()).toStrictEqual({
      disabled: false,
      filter: 'filter',
      required: true,
    });
  });
});
