/* eslint-disable  */
import { useGetVisibilityButtonsOgh } from 'app/api/hooks';
import { Button } from 'core/uiKit/components/buttons/Button';
import { Icon } from 'core/uiKit/material-ui';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Кнопка "Отклонить границы" Огх.
 *
 * ```js
 import FixProblemsBoundariesOghButton from 'core/uiKit/components/buttons/FixProblemsBoundariesOgh.Button';
 * ```
 * или
 *
 * ```js
 import  { FixProblemsBoundariesOghButton } from 'core/uiKit/components';
 * ```
 *
 * (позже будет переименована в "Отправить замечания по границам").
 *
 * @param {object} props - Пропсы.
 * @param {number} props.objectId - Id объекта.
 * @returns {JSX.Element}
 */
export const FixProblemsBoundariesOghButton = ({ objectId, ...props }) => {
  const {
    data: { fix_problems_boundaries: fixProblemsBoundaries = false } = {},
  } = useGetVisibilityButtonsOgh(objectId);

  return fixProblemsBoundaries ? (
    <Button
      {...props}
      color={'error'}
      variant={'contained'}
      startIcon={<Icon>not_interested</Icon>}
    >
      Отклонить границы
    </Button>
  ) : null;
};

export default FixProblemsBoundariesOghButton;

FixProblemsBoundariesOghButton.propTypes = {
  /**
   * Id версии ОГХ.
   */
  objectId: PropTypes.number.isRequired,
};
