import GeometryCSVLink from 'app/utils/card/checkGeometry/GeometryCSVLink';
import IntersectionsMessage from 'app/utils/card/checkGeometry/IntersectionsMessage';

/**
 * Создание сообщения.
 *
 * @param {Array} array - Массив.
 * @param {string} message - Сообщение.
 * @returns {React.ReactElement}
 */
export const createMessage = (array, message) => {
  const intersections = array.map((item) => formatMessage(item));

  return (
    <IntersectionsMessage
      GeometryCSVLink={
        <GeometryCSVLink fileName={'ReonGeometry.csv'} objects={array} />
      }
      intersections={intersections}
      message={message}
      orderedList={true}
    />
  );
};

/**
 * Функция formatMessage.
 *
 * @param {object} data - Данные.
 * @returns {string}
 */
const formatMessage = (data) => {
  const descr = data.descr;
  const priznSobstv = data.prizn_sobstv;
  const priznPrava = data.prizn_prava;
  const address = data.address;
  return `Кадастровый номер: ${descr};
          Признак собственности: ${priznSobstv};
          Признак оформленного права: ${priznPrava};
          Адрес: ${address}`;
};
