import documentsReducer from 'app/components/card/ogh/DocumentsTab/mapStateToPropsReducer';
/* eslint-disable  */
import {
  RANDOM_BOOL,
  RANDOM_ID,
  RANDOM_NUMBER,
} from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { describe, it, vi } from 'vitest';

import Relief, { mapStateToProps } from './relief';

vi.mock('app/components/card/ogh/DocumentsTab/mapStateToPropsReducer');
vi.mock('app/selectors/card/getQuantityDigits');
vi.mock('app/selectors/card/hasQuantityLock');
vi.mock('./Relief/Relief.Component', () => ({
  /**
   *
   */
  ReliefComponent: () => <div>ReliefComponent</div>,
}));

vi.mock('redux-form', async (importOriginal) => {
  const mod = await importOriginal();

  return {
    ...mod,
    reduxForm: vi.fn(() => (Component) => Component),
  };
});

vi.mock('react-redux', () => ({
  connect: vi.fn(
    (mapStateToProps, mapDispatchToProps) => (Component) => (props) =>
      <Component {...mapStateToProps} {...mapDispatchToProps} {...props} />,
  ),
}));

describe('file relief', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    // mockDefault();
    documentsReducer.mockReturnValue((_) => _);

    const card = {
      attribute: {
        coating_face_type_id: { coating_type: RANDOM_ID },
        coating_group_id: { coating_group: RANDOM_ID },
        coating_type_id: { coating_type: RANDOM_ID },
        face_area: RANDOM_NUMBER,
        is_diff_height_mark: true,
      },
    };

    // 🧹 clear mock
    const wrapper = shallow(<Relief card={card} />);

    //🔥 Act

    //❓ Assert
    expect(wrapper.text()).toBe('<ReliefComponent />');
  });

  it('should initialValues form', () => {
    expect.hasAssertions();

    // Arrange (всякие моки)
    documentsReducer.mockReturnValue((_) => _);

    const card = {
      attribute: {
        coating_face_type_id: { coating_type: RANDOM_ID },
        coating_group_id: { coating_group: RANDOM_ID },
        coating_type_id: { coating_type: RANDOM_ID },
        face_area: RANDOM_NUMBER,
        is_diff_height_mark: true,
      },
    };

    // Act
    const result = mapStateToProps({}, { card });

    // Assert
    expect(result).toStrictEqual({
      createMode: false,
      editMode: false,
      enableReinitialize: true,
      formValues: {},
      initialValues: {
        arrange_element_type_id: undefined,
        coating_face_type_id: RANDOM_ID,
        coating_group_id: RANDOM_ID,
        coating_type_id: RANDOM_ID,
        endDate: null,
        face_area: RANDOM_NUMBER,
        is_diff_height_mark: true,
        material_id: undefined,
        no_calc: 0,
        quantity: undefined,
        startDate: null,
        totalAreaGeo: undefined,
        unit_id: undefined,
      },
      onSubmit: expect.any(Function),
      required: false,
      validate: expect.any(Function),
    });
  });

  it('should initialValues', () => {
    expect.hasAssertions();

    // Arrange (всякие моки)
    documentsReducer.mockReturnValue((_) => _);

    const card = {
      attribute: {
        is_diff_height_mark: RANDOM_BOOL,
        material_id: {
          material: RANDOM_ID,
        },
      },
    };

    // Act
    const result = mapStateToProps({}, { card });

    // Assert
    expect(result).toMatchObject({
      initialValues: {
        is_diff_height_mark: RANDOM_BOOL,
        material_id: RANDOM_ID,
      },
    });
  });
});
