import { fetchProviders } from 'app/api/typeahead';
import Form from 'app/components/common/KeyForm';
import dateValidator from 'app/utils/dateValidator';
import startDateMinDate from 'app/utils/startDateMinDate';
import cn from 'classnames';
import { AutocompleteAsyncRF } from 'core/form/reduxForm/fields/default/selects/AutocompleteAsyncRF';
import { ButtonBlue } from 'core/newComponents/Button';
import TextField from 'core/newComponents/TextField';
import KeyboardDateTimePicker from 'core/uiKit/inputs/datePickers/KeyboardDateTimePicker';
import PropTypes from 'prop-types';
import React from 'react';
import { Control } from 'react-redux-form';

import searchFormWrapper from './SearchForm';

/**
 * RenderField.
 *
 */
class SearchForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    form: PropTypes.object,
    formChange: PropTypes.func,
    formValidity: PropTypes.object,
    onSubmit: PropTypes.func,
    onSubmitClick: PropTypes.func,
    reset: PropTypes.func,
  };

  /**
   * ComponentWillUnmount.
   *
   * @returns {void}
   */
  componentWillUnmount() {
    this.props.reset();
  }

  /**
   * Render.
   *
   * @returns {JSX.Element}
   */
  render() {
    const { form, onSubmit, onSubmitClick, formChange } = this.props;

    return (
      <Form
        className={cn(this.props.className)}
        model="rrf.technologyVersions"
        validators={dateValidator}
        onSubmit={onSubmit}
      >
        <div
          className="align-top mr-3 d-inline-block"
          style={{ marginTop: 10, width: 270 }}
        >
          <AutocompleteAsyncRF
            fetchFunction={fetchProviders}
            label="Поставщик"
            placeholder="Все"
            value={form.provider}
            onChange={(value) => {
              formChange({ key: 'provider', value });
            }}
          />
        </div>

        <div
          className="align-top mr-3 d-inline-block"
          style={{ marginTop: 10, width: 270 }}
        >
          <KeyboardDateTimePicker
            label="Дата с"
            value={form.startDate}
            onChange={(dateTime) => {
              formChange({ key: 'startDate', value: dateTime });
            }}
          />
        </div>

        <div
          className="align-top mr-3 d-inline-block"
          style={{ marginTop: 10, width: 270 }}
        >
          <KeyboardDateTimePicker
            label="Дата по"
            minDate={startDateMinDate(form)}
            value={form.endDate}
            onChange={(dateTime) => {
              formChange({ key: 'endDate', value: dateTime });
            }}
          />
        </div>
        <Control
          component={TextField}
          controlProps={{
            className: 'align-top mr-3 ods-mui-text-field',
            label: 'Пользователь, создавший запрос',
          }}
          model=".userCreate"
          type="text"
        />
        <ButtonBlue
          className="align-bottom my-2"
          type="submit"
          onClick={onSubmitClick}
        >
          Поиск
        </ButtonBlue>
      </Form>
    );
  }
}

export default searchFormWrapper(SearchForm);
