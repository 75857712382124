import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { MultiSelect } from 'core/uiKit/inputs/selects/MultiSelect';
import React from 'react';

export const OKRUG_LIST_NAME = 'okrug_list';

/**
 * ## Округ.
 *
 * `type = MultiSelect`
 * `name = OKRUG_LIST_NAME`
 * `dict = okrugs`.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - JSX.
 * @example ----
 */
export const OkrugList = (props) => {
  const dict = 'okrugs';
  const { data: options = [] } = useGetDictionary(dict);

  return (
    <MultiSelect
      {...props}
      {...{ options }}
      label={'Округ'}
      name={OKRUG_LIST_NAME}
    />
  );
};
