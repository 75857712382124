 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Способ уборки__.
 *
 * @type { { accessor: 'clean_type', Header: 'Способ уборки' }}
 * @augments Cell
 */
export const clean_type = {
  accessor: 'clean_type',
  Header: 'Способ уборки',
  sortable: true,
};
