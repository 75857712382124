import { getReport, getReportCount } from 'app/utils/api';

/**
 * A.
 *
 * @param {*} queryParams - A.
 * @returns {*}
 */
export const fetchEquipmentReport = (queryParams) =>
  getReport('equipment', queryParams);

/**
 * A.
 *
 * @param {*} queryParams - A.
 * @returns {*}
 */
export const fetchEquipmentReportCount = (queryParams) =>
  getReportCount('equipment', queryParams);

/**
 * A.
 *
 * @param {*} queryParams - A.
 * @returns {*}
 */
export const printEquipmentReport = (queryParams) =>
  getReport('technics_report_equipment', queryParams);
