import { OghStatuses } from 'core/uiKit/preparedInputs/selects/multiSelect/OghStatuses';
import React from 'react';
import { Field } from 'react-final-form';
import { DelimitedNumericArrayParam, withDefault } from 'use-query-params';

const OGH_STATUSES_VERSION_TABLE_NAME = 'stateIdVersionsTable';

/**
 * Фильтр версии.
 *
 * @param {*} root0 - Параметры.
 * @returns {JSX.Element}
 */
export const OghStatusesVersionFilter = ({ ...otherProps }) => {
  return (
    <Field name={OGH_STATUSES_VERSION_TABLE_NAME}>
      {(props) => {
        return <OghStatuses {...props} {...props.input} {...otherProps} />;
      }}
    </Field>
  );
};

/**
 * Параметры фильтра версии.
 *
 * @param {Array} initValue - Значение по умолчанию.
 * @returns {object}
 */
export const oghStatusesVersionTableParams = (initValue = [2, 1]) => {
  return {
    [OGH_STATUSES_VERSION_TABLE_NAME]: withDefault(
      DelimitedNumericArrayParam,
      initValue,
    ),
  };
};
