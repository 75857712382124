/* eslint-disable */
import { shallow } from 'enzyme';

import { RANDOM_ID } from 'core/forTesting/constants';

import { TableTreesShrubsList } from './Table.TreesShrubsList';
import { TreesShrubsList } from './TreesShrubsList';

jest.mock('./Table.TreesShrubsList', () => ({
  TableTreesShrubsList: () => <div />,
}));
jest.mock('../Accordion.PlantationTab');

 
describe('file TreesShrubsList.spec', () => {
  it('simple text', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const wrapper = shallow(<TreesShrubsList />);
    // Assert
    expect(wrapper.text()).toBe('<AccordionPlantationTab />');
  });

  it('should props', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const EXPANDED = 'EXPANDED_' + RANDOM_ID;
    const ON_CHANGE = jest.fn();
    // Act
    const wrapper = shallow(
      <TreesShrubsList
        onChange={ON_CHANGE}
        expanded={EXPANDED}
        RANDOM_ID={RANDOM_ID}
      />,
    );

    // Assert
    expect(wrapper.prop('code')).toBe('trees_shrubs_list');
    expect(wrapper.prop('title')).toBe('Перечётная ведомость');
    expect(wrapper.prop('Table')).toBe(TableTreesShrubsList);
    expect(wrapper.prop('expanded')).toBe(EXPANDED);
    expect(wrapper.prop('onChange')).toBe(ON_CHANGE);
    expect(wrapper.prop('RANDOM_ID')).not.toBe(RANDOM_ID);
  });
});
