import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';

/* eslint-disable */
import { getFiles } from './getFiles';

/**
 * Хук useGetFilesForSignature.
 *
 * @description UseQuery, для получения файлов на подпись принимает id ogh или берет из урла.
 * @param {number|string} oghId - Id объекта одх.
 * @param {string} signType - Тип метода запроса на подписание.
 * @param {object} [options] - Опции.
 * @returns {{data: Array<{name: string, storageId: number, file: File}>, isLoading: boolean}}
 * @example
 * const { objectId } = useGetObjectId();
 * const {data, isLoading} = useGetFilesForSignature(objectId, SAVE)
 *
 * const onClose = ()=>{/.../}
 * const {data, isLoading} = useGetFilesForSignature(objectId, SAVE, {onSuccessCallback: onClose, onErrorCallback: onClose})
 */
export const useGetFilesForSignature = (oghId, signType, options = {}) => {
  const {
    onSuccessCallback = (_) => _,
    onErrorCallback = (_) => _,
    ...otherOptions
  } = options;

  return useQueryAdaptor(
    ['GetFilesForSignature', { oghId, signType }],
    getFiles,
    {
      /**
       *
       * @param value
       */
      onError: (value) => {
        onErrorCallback(value);
      },

      /**
       *
       */
      onSuccess: () => {
        onSuccessCallback();
      },

      ...otherOptions,
    },
  );
};
