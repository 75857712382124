import { filterInput } from 'core/form/filterForm/components/filterInputsClasses';
import { USERNAME_NAME, UsernameFF } from 'core/form/finalForm/fields';
import { objectInArrayParam } from 'core/utils/hooks';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';
import { withDefault } from 'use-query-params';

/**
 * Пользователи
 * username.
 *
 * @returns {JSX.Element}
 */
export const UsernameFilter = () => <UsernameFF className={filterInput} />;

export { USERNAME_NAME };

/**
 * ID составного объекта
 * username.
 *
 * @returns {object}
 */
export const usernameParams = () => {
  addPreparation(USERNAME_NAME, (value) => {
    return value.map((item) => item.id).join(',');
  });

  return {
    [USERNAME_NAME]: withDefault(objectInArrayParam, [{}]),
  };
};
