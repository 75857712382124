import TypeOtherTerritory from 'app/components/card/ogh/ImprovementTerritoryCard/components/TypeOtherTerritory/TypeOtherTerritory';
import { shallow } from 'enzyme';

describe('🐛 TypeOtherTerritorySpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = {
      otherImprovementObjectType: [],
    };
    // 🔥 Act
    const wrapper = shallow(<TypeOtherTerritory {...props} />);

    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      disabled: true,
      label: 'Тип',
      name: 'other_improvement_object_type_id',
      options: [],
    });
  });
});
