
/* eslint-disable */
export default function (result, state, props) {
  const { card } = props;

  return {
    ...result,

    initialValues: {
      ...result.initialValues,
      file_list: {
        table: card.file_list ? card.file_list : null,
        showDialog: false,
      },
    },

    oldValues: {
      ...result.oldValues,
      file_list: card.old_file_list,
    },
  };
}
