import { useGetDtsTypes } from 'app/components/card/ogh/dts/useGetDtsTypes';
/* eslint-disable */
import { RANDOM_ID, RANDOM_WORD } from 'core/forTesting/constants';
import { shallow } from 'enzyme';

import Dts from './index';

jest.mock('./Dts', () => ({
  /**
   *
   */
  default: () => <div></div>,
}));
jest.mock('./useGetDtsTypes');

/* eslint-disable */
describe('file index.Dts', () => {
  it('simple text', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetDtsTypes.mockReturnValue([]);

    // Act
    const wrapper = shallow(<Dts />);
    // Assert
    wrapper.text(); //?
    expect(wrapper.text()).toBe('');
  });

  it('props', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetDtsTypes.mockReturnValue(['useGetDtsTypes_' + RANDOM_ID]);
    // Act
    const wrapper = shallow(<Dts number={RANDOM_ID} word={RANDOM_WORD} />);
    // Assert
    wrapper.text();
    expect(wrapper.props()).toStrictEqual({
      number: RANDOM_ID,
      word: RANDOM_WORD,
      dtsTypes: ['useGetDtsTypes_' + RANDOM_ID],
    });
  });
});
