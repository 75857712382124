import { FORMAT_DATE_TIME } from 'app/utils/date/format';
import moment from 'moment';

/**
 * Функция получения текущего времени и даты.
 *
 * @param {string} [formatDate] - Не обязательный формат принимаемой строки.
 * @category dateUtils
 * @function toCurrentDateTime
 * @returns {string}
 * @example
 * const date = toCurrentDateTime(); //? "20.02.2023 16:51"
 */
export const toCurrentDateTime = (formatDate = FORMAT_DATE_TIME) =>
  moment().format(formatDate);
