import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useEditorCardSelector } from 'app/selectors/useSelectors/useEditorCard.Selector';
import { useGetOghType } from 'core/utils/hooks/useGetOghType';
import { shallow } from 'enzyme';
import React from 'react';
import { vi } from 'vitest';

import { PropertiesYardTab } from './Properties.Yard.Tab';

vi.mock('app/api/hooks/useGetCurrentCard');
vi.mock('app/api/hooks/useIsEditCurrentObjectIsExternalSystem');
vi.mock('app/selectors/useSelectors/useEditorCard.Selector');
vi.mock('core/utils/hooks/useGetOghType');
vi.mock('app/pages/cardsOgh/hooks/useMode');

/**
 * Mock Default.
 *
 * @returns {void}
 */
const mockDefault = () => {
  useGetCurrentCard.mockReturnValue({});
  useEditorCardSelector.mockReturnValue({});

  useGetOghType.mockReturnValue({});
  useMode.mockReturnValue({});
};
describe('🐛 spec Property.Yard.Tab', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(<PropertiesYardTab {...{}} />);

    //❓ Assert
    expect(wrapper.text()).toBe(
      '<FieldsSplitColumns /><DepartmentIdRF /><FieldsSplitColumns /><AddressListField />',
    );
  });
});
