import { DownLoadFilesListCell } from 'core/uiKit/components/cells/DownLoadFilesList.Cell/DownLoadFilesList.Cell';

import { getColumnsRepairsPlanTab } from './columns.RepairsPlanTab';
import { DeleteButtonCellRepairsPlan } from './Delete.Button.Cell.RepairsPlan';

describe('ColumnsRepairsPlanTabSpec', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act

    // ❓ Assert
    expect(getColumnsRepairsPlanTab()).toStrictEqual([
      {
        Cell: DeleteButtonCellRepairsPlan,
        Header: '',
        accessor: 'id',
      },
      {
        Header: 'Наименование',
        accessor: 'name',
      },
      {
        Header: 'Дата начала работ',
        accessor: 'date_start',
      },
      {
        Header: 'Дата окончания работ',
        accessor: 'date_end',
      },
      {
        Header: 'Техническое задание (характеристика объемы)',
        accessor: 'property',
      },
      {
        Cell: DownLoadFilesListCell,
        Header: 'Техническое задание / проект благоустройства',
        accessor: 'file_list',
      },
    ]);
  });
});
