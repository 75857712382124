/* eslint-disable */
export default class MafTypeService {
  filterMafTypeLevel2(mafTypeLevel2, mafTypeLevel1Id) {
    return mafTypeLevel2.filter((item) =>
      item.maf_type_list.some(
        (item) => item.maf_type_level1_id === mafTypeLevel1Id,
      ),
    );
  }

  filterMafTypeLevel3(mafTypeLevel3, mafTypeLevel1Id) {
    return mafTypeLevel3.filter(
      (item) => item.maf_type_level1_id === mafTypeLevel1Id,
    );
  }

  getQuantityCharacteristicsProps(mafTypeLevel1, mafTypeLevel1Id) {
    let label = 'Количество мест, шт.';
    let disabled;

    if (mafTypeLevel1Id) {
      const { quantity_name } =
        mafTypeLevel1.find((item) => item.id === mafTypeLevel1Id) || {};
      disabled = !quantity_name;
      if (quantity_name) {
        label = quantity_name;
      }
    } else {
      disabled = true;
    }

    return {
      label,
      disabled,
    };
  }
}
