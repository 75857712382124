 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Уточнение__.
 *
 * @type {{  accessor: 'maf_type_level2_name',  Header: 'Уточнение'}}
 * @augments Cell
 */
export const maf_type_level2_name = {
  accessor: 'maf_type_level2_name',
  Header: 'Уточнение',
  sortable: true,
};
