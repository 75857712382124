import { ERZ_STATE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/ErzStateId/index';
import React from 'react';
import { Field } from 'react-final-form';

import { ErzStateIdWithFF } from './ErzStateIdWithFF';

/**
 * Количество.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const ErzStateIdFF = (props) => {
  return (
    <Field component={ErzStateIdWithFF} name={ERZ_STATE_ID_NAME} {...props} />
  );
};
