import { OznTypeIDRF } from 'core/form/reduxForm/fields/prepared/selects/simple/OznTypeID.RF';
import React from 'react';

 
/**
 * Компонент поля OznTypeField.
 *
 * @param {object} props - Пропсы.
 * @returns {React.ReactElement} - Компонент OznTypeIDRF.
 * @example
 * <OznTypeField {...props} />
 */
export const OznTypeField = (props) => {
  return <OznTypeIDRF required={true} {...props} />;
};
