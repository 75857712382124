import './style.scss';
/* eslint-disable  */

/**
 *
 * @param props
 */
const LeftPanel = (props) => {
  return <div className="ods-core-left-panel">{props.children}</div>;
};
export default LeftPanel;
