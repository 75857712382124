/* eslint-disable  */
import buildDictSelector from 'app/selectors/buildDictSelector';
import { get } from 'lodash';
import { createSelector } from 'reselect';

/**
 *
 * @param state
 */
const getCurrentRequestReasonId = (state) =>
  get(state, 'rrf.changeCar.reason', 'registration');

export default createSelector(
  [getCurrentRequestReasonId, buildDictSelector('technicRequestReason')],
  (currentId, reasons) => {
    const result = reasons.find((item) => item.id === currentId);
    return result ? result.code : null;
  },
);
