import { useFormValuesCardRF } from 'app/actions/odsObjectActions/useFormValuesCardRF';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { IntensityNumRF } from 'core/form/reduxForm/fields/prepared/numberField/IntensityNum.RF';
import React from 'react';

/**
 * Обертка поля Интенсивность движения.
 *
 * @returns {JSX.Element}
 */
export const IntensityNumField = () => {
  const { editMode } = useEditMode();
  const { clean_category_id, is_orphan_object } = useFormValuesCardRF();

  if (is_orphan_object) {
    return null;
  }

  return (
    <IntensityNumRF
      maxLength={6}
      disabled={!editMode}
      required={[1, 2].includes(clean_category_id)}
      helpTitle={
        'Приведенная интенсивность движения к легковому автомобилю (в соответствии с СП 34.13330.2021 «СНиП 2.05.02-85»), автомобилей в час пик суммарно в обоих направлениях (по фактическим измерениям). Заполняется составителем паспорта только для объектов 1-й и 2-й категории для целей уборки или по отдельной заявке Инициатора для объектов других категорий, которые планируется перевести в эти категории.'
      }
    />
  );
};
