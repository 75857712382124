import cn from 'classnames';
import React from 'react';
/* eslint-disable */

/**
 *
 * @param root0
 * @param root0.src
 * @param root0.title
 * @param root0.active
 * @param root0.onClick
 */
export const InterceptedTypeItem = ({ src, title, active, onClick }) => {
  return (
    <li
      onClick={onClick}
      className={cn('', {
        active: active,
      })}
    >
      <img src={src} alt={title} />
      <p>{title}</p>
    </li>
  );
};
