import { getTomorrowDate } from 'app/utils/date/getTomorrowDate';
import { KeyboardDatePickerFF } from 'core/form/finalForm/fields/default/datePickers/KeyboardDatePickerFF';
import {
  addPreparation,
  usePreparation,
} from 'core/utils/hooks/usePreparation/preparation';
import moment from 'moment';
import React from 'react';

export const START_DATE_NAME = 'startDate';

/**
 * Дата начала действия.
 *
 * @param {object} root0 - Properties.
 * @param {Date} root0.minDate - Минимальная дата.
 * @returns {JSX}
 */
export const StartDate = ({ minDate = getTomorrowDate() }) => {
  usePreparation(START_DATE_NAME, (value) => {
    let newValue = value;

    if (moment(value).isValid()) {
      newValue = moment(value).format('DD.MM.YYYY');
    }
    return newValue;
  });

  return (
    <KeyboardDatePickerFF
      label="Дата начала действия"
      minDate={minDate}
      minDateMessage={`Дата начала действия не может быть раньше ${minDate}. Укажите корректное значение`}
      name={START_DATE_NAME}
    />
  );
};

/**
 * Param startDateParams.
 *
 * @returns {void}
 */
export const startDateParams = () => {
  addPreparation(START_DATE_NAME, (value) => {
    let newValue = value;

    if (moment(value).isValid()) {
      newValue = moment(value).format('DD.MM.YYYY');
    }
    return newValue;
  });
};
