import { OWNER_ID_NAME, OwnerIdFF } from 'core/form/finalForm/fields';
import { addPreparation } from 'core/utils/hooks/usePreparation/preparation';
import React from 'react';
import { ObjectParam } from 'use-query-params';

import { filterInput } from '../filterInputsClasses';

/**
 * Балансодержатель.
 *
 * @param {*} props - Properties.
 * @returns {JSX.Element}
 */
export const OwnerIdFilter = (props) => (
  <OwnerIdFF {...props} className={filterInput} />
);

export { OWNER_ID_NAME };

/**
 * OwnerIdParams.
 *
 * @returns {object}
 */
export const ownerIdParams = () => {
  addPreparation(OWNER_ID_NAME, (value) => value.id);

  return {
    [OWNER_ID_NAME]: ObjectParam,
  };
};
