import { useGetRegistry as useGetRegistryD } from 'app/api/hooks/useGetRegistry';

/**
 * Хук получения данных для таблиц реестров.
 *
 * @deprecated
 *
 * TODO: удалить в 54 версии.
 *
 * @param {string | Array} queryKey - Данные для запроса.
 * @param {{}?} options - Параметры запроса.
 * @returns {*}
 */
export const useGetRegistry = useGetRegistryD;
