import { usePreparation } from 'core/utils/hooks/usePreparation';

import { ID_OR_NAME_NAME } from './ID_OR_NAME_NAME';

/**
 * Функция удаления пробелов из строки.
 *
 * @param {string} value - Значения из инпута.
 * @returns {string}
 */
export const trimValue = (value) => value.trim();

/**
 * Выбираем ключи айди или имя.
 *
 * @param {string} value - Значения из инпута.
 * @returns {string}
 */
export const idOrName = (value) => {
  if (isNaN(Number(value))) {
    return 'name';
  }
  return 'id';
};

/**
 * Преобразовываем данные перед отправкой.
 *
 * @returns {void}
 */
export const usePreparationIdOrNameFilter = () =>
  usePreparation(ID_OR_NAME_NAME, trimValue, idOrName);
