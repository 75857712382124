 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Площадь застройки, кв.м.__.
 *
 * @type {{  accessor: 'build_area',  Header: 'Площадь застройки, кв.м.', sortable: true,}}
 * @augments Cell
 */
export const build_area = {
  accessor: 'build_area',
  Header: 'Площадь застройки, кв.м.',
  sortable: true,
};
