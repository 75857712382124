import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useMapContext } from 'app/components/map/useMapContext';
import { DISTRICT_BOUNDARIES } from 'app/constants/layers';
import { useState } from 'react';

import { useGetDistrictBoundariesEgip } from './useGetDistrictBoundaries.Egip';

/**
 * Setup Компонент кнопки для edit панели в списке объектов для Егип.
 *
 * @returns Методы.
 */
export const useSetupShowDistrictBoundariesEgip = () => {
  const { getGeometry, clearLayer } = useMapContext();
  const [hasDistrictBoundaries, setHasDistrictBoundaries] = useState(false);

  const { getDistrictBoundaries } = useGetDistrictBoundariesEgip(
    // @ts-ignore
    setHasDistrictBoundaries,
  );
  const { recordId } = useGetCurrentCard();

  /**
   * Функция переключения ToggleButton из неактивновго в активное и наоборот.
   *
   */
  const checkDistrictBoundaries = () => {
    if (hasDistrictBoundaries) {
      clearLayer(DISTRICT_BOUNDARIES);
      setHasDistrictBoundaries(false);
    } else {
      const data = {
        geometry: getGeometry(recordId),
      };
      getDistrictBoundaries(data);
    }
  };

  return {
    checkDistrictBoundaries,
    hasDistrictBoundaries,
  };
};
