import { RANDOM_ID } from 'core/forTesting/constants';
/* eslint-disable */
import { shallow } from 'enzyme';

import { FlowersGardenList } from './FlowersGardenList';
import { TableFlowersGardenList } from './Table.FlowersGardenList';

jest.mock('./Table.FlowersGardenList', () => ({
  TableFlowersGardenList: (_) => <div />,
}));
jest.mock('../Accordion.PlantationTab');

/* eslint-disable */
describe('file FlowersGardenList.spec', () => {
  it('simple text', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const EXPANDED = 'EXPANDED_' + RANDOM_ID;
    const ON_CHANGE = jest.fn();

    // Act
    const wrapper = shallow(
      <FlowersGardenList onChange={ON_CHANGE} expanded={EXPANDED} />,
    );
    // Assert
    expect(wrapper.text()).toBe('<AccordionPlantationTab />');
  });

  it('should props', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const EXPANDED = 'EXPANDED_' + RANDOM_ID;
    const ON_CHANGE = jest.fn();
    // Act
    const wrapper = shallow(
      <FlowersGardenList onChange={ON_CHANGE} expanded={EXPANDED} />,
    );

    // Assert
    expect(wrapper.props()).toStrictEqual(
      expect.objectContaining({
        code: 'flowers_garden_list',
        title: 'Ведомость учета зеленых насаждений (цветники)',
        Table: TableFlowersGardenList,
        expanded: EXPANDED,
        onChange: ON_CHANGE,
      }),
    );
  });
});
