import Legend from 'app/components/common/Legend';
import formatRootId from 'app/utils/formatRootId';
import classnames from 'classnames';
import { Table } from 'core/uiKit/components/tables/Table';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const legend = [
  {
    className: 'legend_add',
    value: 'новое',
  },
  {
    className: 'legend_del',
    value: 'удаленное',
  },
  {
    className: 'legend_update',
    value: 'отличается',
  },
];

/**
 * PropertiesDiff.
 */
export default class PropertiesDiff extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    copy: PropTypes.object,
    etalon: PropTypes.object,
    propsdiff: PropTypes.array,
  };

  /**
   * FilterEqualProps.
   *
   * @returns {*}
   */
  filterEqualProps() {
    const propsdiff = get(this, 'props.propsdiff', []);
    if (Array.isArray(propsdiff)) {
      return propsdiff.filter((row) => !row.is_equal);
    }
    return [];
  }

  /**
   * FilterEqualProps.
   *
   * @returns {*}
   */
  render() {
    const { props } = this;
    const { etalon, copy } = props;
    const notEqualProps = this.filterEqualProps();

    const columns = [
      {
        Header: '№ п/п',
        accessor: 'index',
      },
      {
        Header: 'Свойство',
        accessor: 'name',
      },
      {
        Header: `${formatRootId(copy.id)} (${copy.object_status_name})`,
        accessor: 'copy_value',
      },
      {
        Header: `${formatRootId(etalon.id)} (${etalon.object_status_name})`,
        accessor: 'original_value',
      },
      {
        Header: '',
        accessor: 'prop_operation',
      },
    ];

    const data = notEqualProps.map(({ operation, ...props }, index) => {
      return {
        index: index + 1,
        prop_operation: (
          <div
            dangerouslySetInnerHTML={{
              __html: '<div class=legend_' + operation + '></div>',
            }}
          />
        ),
        ...props,
      };
    });

    return (
      <div className={classnames('d-flex flex-column')}>
        <Table
          columns={columns}
          data={data}
          sortable_columns={[]}
          stickHeader={true}
        />

        <Legend legend={legend} />
      </div>
    );
  }
}
