/* eslint-disable  */
/**
 *
 * @param WrappedComponent
 */
const getDisplayName = (WrappedComponent) => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};

export default getDisplayName;
