import './LinkShortId.Cell.scss';

import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Cell Ссылки short_root_id || root_id.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.row - Ряд.
 * @returns {JSX.Element}
 */
export const LinkShortIdCell = ({ row }) => {
  return (
    <Link to={`/ogh/${row?.original?.object_id}`} className={'link-short-id'}>
      {row?.original?.short_root_id || row?.original?.root_id}
    </Link>
  );
};
