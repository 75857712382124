import { getRegistryOgh } from 'app/api/requests/getRegistryOgh';

/**
 * Получение количество записей в Таблице-Журнале ОГХ.
 *
 * @param {string} templateName - Имя шаблона.
 * @param {object} queryParams - Параметры запроса.
 * @returns {Promise<*>}
 */
export const getRegistryOghCount = (templateName, queryParams) => {
  const url = templateName ? `${templateName}/count` : 'count';
  return getRegistryOgh(url, queryParams);
};
