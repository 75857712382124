import { CardContainer } from 'app/pages/cardsOgh/components/CardContainer';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { NumberFieldRF } from 'core/form/reduxForm/fields/default/NumberField.RF';
import React from 'react';

import { TabBody } from '../TabBody';

/**
 * Tab Инженерные сооружения для ТС.
 *
 * @param {object} params - Parameters.
 * @param {string} params.propertyTab - Активная вкладка.
 * @returns {JSX}
 */
export const EngineeringStructuresTab = ({
  propertyTab,
}: {
  propertyTab: string;
}) => {
  const { editMode } = useEditMode();
  return (
    <TabBody
      id="engineering-structures"
      show={propertyTab === 'engineering-structures'}
    >
      <CardContainer>
        <NumberFieldRF
          required={true}
          decimalScale={2}
          disabled={!editMode}
          label={'Мосты и путепроводы, шт.'}
          name={'bridge_qty'}
        />
        <NumberFieldRF
          required={true}
          decimalScale={2}
          disabled={!editMode}
          label={'Мосты и путепроводы, п.м'}
          name={'bridge_psc'}
        />
        <NumberFieldRF
          required={true}
          decimalScale={2}
          disabled={!editMode}
          label={'Мосты и путепроводы, кв.м'}
          name={'bridge_sqr'}
        />
        <NumberFieldRF
          required={true}
          decimalScale={2}
          disabled={!editMode}
          label={'Трубы, шт.'}
          name={'tube_qty'}
        />
        <NumberFieldRF
          required={true}
          decimalScale={2}
          disabled={!editMode}
          label={'Трубы, длина по лотку, п.м'}
          name={'tube_psc'}
        />
        <NumberFieldRF
          required={true}
          decimalScale={2}
          disabled={!editMode}
          label={'Снегозащитные посадки, п.м'}
          name={'snow_psc'}
        />
        <NumberFieldRF
          required={true}
          decimalScale={2}
          disabled={!editMode}
          label={'Декоративные посадки, п.м'}
          name={'decor_psc'}
        />
        <NumberFieldRF
          required={true}
          decimalScale={2}
          disabled={!editMode}
          label={'Надземные пешеходные переходы, шт.'}
          name={'pedestrian_qty'}
        />
        <NumberFieldRF
          required={true}
          decimalScale={2}
          disabled={!editMode}
          label={'Надземные пешеходные переходы, п.м'}
          name={'pedestrian_psc'}
        />
        <NumberFieldRF
          required={true}
          decimalScale={2}
          disabled={!editMode}
          label={'Надземные пешеходные переходы, кв.м'}
          name={'pedestrian_sqr'}
        />
        <NumberFieldRF
          required={true}
          decimalScale={2}
          disabled={!editMode}
          label={'Линии наружного освещения, протяженность, п.м'}
          name={'light_psc'}
        />
        <NumberFieldRF
          required={true}
          decimalScale={2}
          disabled={!editMode}
          label={'Линии наружного освещения, опоры, шт.'}
          name={'light_qty'}
        />
        <NumberFieldRF
          required={true}
          decimalScale={2}
          disabled={!editMode}
          label={'Линии наружного освещения, плафоны/ светильники, шт.'}
          name={'lamp_qty'}
        />
        <NumberFieldRF
          required={true}
          decimalScale={2}
          disabled={!editMode}
          label={'Светофорные объекты, шт.'}
          name={'tlight_qty'}
        />
        <NumberFieldRF
          required={true}
          decimalScale={2}
          disabled={!editMode}
          label={'Павильоны ООТ, шт.'}
          name={'pavilion_qty'}
        />
        <NumberFieldRF
          required={true}
          decimalScale={2}
          disabled={!editMode}
          label={'Трансформаторная подстанция, шт.'}
          name={'transform_qty'}
        />
        <NumberFieldRF
          required={true}
          decimalScale={2}
          disabled={!editMode}
          label={'Шкаф наружного освещения, шт.'}
          name={'сase_outside_light_qty'}
        />
        <NumberFieldRF
          required={true}
          decimalScale={2}
          disabled={!editMode}
          label={'Пескобаза, шт.'}
          name={'sandbase_qty'}
        />
        <NumberFieldRF
          required={true}
          decimalScale={2}
          disabled={!editMode}
          label={'Пескобаза, кв.м'}
          name={'sandbase_sqr'}
        />
        <NumberFieldRF
          required={true}
          decimalScale={2}
          disabled={!editMode}
          label={'Пост ГИБДД, шт.'}
          name={'police_qty'}
        />
        <NumberFieldRF
          required={true}
          decimalScale={2}
          disabled={!editMode}
          label={'Пост ГИБДД, кв.м'}
          name={'police_sqr'}
        />
        <NumberFieldRF
          required={true}
          decimalScale={2}
          disabled={!editMode}
          label={
            'Опоры (тросовые под знаки индивидуального проектирования), шт.'
          }
          name={'support_qty'}
        />
        <NumberFieldRF
          required={true}
          decimalScale={2}
          disabled={!editMode}
          label={'Водопропускные лотки, п.м.'}
          name={'water_psc'}
        />
        <NumberFieldRF
          required={true}
          decimalScale={2}
          disabled={!editMode}
          label={'Подпорная стенка, п.м.'}
          name={'wall_psc'}
        />
        <NumberFieldRF
          required={true}
          decimalScale={2}
          disabled={!editMode}
          label={'Тросовое ограждение, п.м.'}
          name={'cable_fancing_psc'}
        />
        <NumberFieldRF
          required={true}
          decimalScale={2}
          disabled={!editMode}
          label={'Урны, шт.'}
          name={'urn_qty'}
        />
      </CardContainer>
    </TabBody>
  );
};
