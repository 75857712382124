import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useMapContext } from 'app/components/map/useMapContext';
import { useGetGeometryLoadMutation } from 'app/pages/cardsOgh/components/Map/api/geometryLoad';
import { useState } from 'react';

import { useDialogShowChildrenButton } from './useDialog.ShowChildrenButton';

/**
 * Hook useShowChildrenButton.
 *
 * @returns {*}
 */
export const useShowChildrenButton = () => {
  const [active, setActive] = useState(false);
  const { recordId, children } = useGetParentCard();
  const { getGeometryLoad } = useGetGeometryLoadMutation();
  const { clearLayer, isMapLoaded } = useMapContext();
  const { isOpen, closeDialog, openDialog } = useDialogShowChildrenButton();

  return {
    active,
    children,
    clearLayer,
    closeDialog,
    getGeometryLoad,
    isMapLoaded,
    isOpen,
    openDialog,
    recordId,
    setActive,
  };
};
