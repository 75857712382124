import './styles.scss';

import cn from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';
/* eslint-disable */
const LoadingOverlay = ({
  isHidden,
  wrapperClassName,
  backgroundClassName,
  spinnerClassName,
}) => {
  if (isHidden) {
    return null;
  }

  return (
    <div className={cn('wrapper', wrapperClassName)}>
      <div className={cn('background', backgroundClassName)} />
      <div className={cn('spinner', spinnerClassName)} />
    </div>
  );
};

LoadingOverlay.propTypes = {
  wrapperClassName: PropTypes.string,
  backgroundClassName: PropTypes.string,
  spinnerClassName: PropTypes.string,
  isHidden: PropTypes.bool,
};

 
export default LoadingOverlay;
