import { get } from 'app/api/crud/get';
import { getRegistryOghCount } from 'app/api/requests/getRegistryOghCount';

/**
 * Кжличество.
 *
 * @param {*} queryParams - QueryParams.
 * @returns {*}
 */
export const getObjectTypeCnt = (queryParams) => {
  return getRegistryOghCount(null, queryParams);
};

// todo: удалить когда будет запрос на пост и переделать на не олд  getObjectTypeCnt

/**
 * Получаем колличество.
 *
 * @param {*} queryParams - QueryParams.
 * @returns {*}
 */
export const getObjectTypeCntOld = (queryParams) => {
  return get('/ogh/count', queryParams, { credentials: 'include' });
};
