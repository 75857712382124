/* eslint-disable  */
/**
 *
 * @param values
 */
export const validate = (values) => {
  const errors = {};
  const dateFrom = new Date(values.dateFrom).toDateString();
  const dateTo = new Date(values.dateTo).toDateString();

  if (new Date(dateFrom) > new Date(dateTo)) {
    errors.dateTo =
      'Дата по не может быть раньше даты с. Укажите корректное значение.';
  }
  return errors;
};
