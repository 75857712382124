import { key } from './metadata';


/* eslint-disable */
export default function (formValues) {
  const value = formValues[key];
  let result;
  if (value) {
    result = {
      other_improvement_object_type: value,
    };
  } else {
    result = null;
  }
  return {
    [key]: result,
  };
}
