import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { DETAILED_STATE_GARDENING_ID } from 'core/uiKit/preparedInputs/selects/simpleSelect/DetailedStateGardeningId';
import { shallow } from 'enzyme';
import React from 'react';

import { CharacteristicStateGardeningIdRFTreesShrubs } from './CharacteristicStateGardeningIdRF.TreesShrubs';

jest.mock('app/api/hooks/useIsCurrentObjectIsExternalSystem', () => {
  return {
    useIsCurrentObjectIsExternalSystem: jest.fn(),
  };
});

describe('CharacteristicStateGardeningIdRFTreesShrubs component', () => {
  const changeFieldValueMock = jest.fn();

  beforeEach(() => {
    jest.clearAllMocks();
  });

  it('should render the component in edit mode', () => {
    // Arrange
    const currentValues = {
      [DETAILED_STATE_GARDENING_ID]: 2,
      state_gardening_id: 1,
    };
    (useIsCurrentObjectIsExternalSystem as jest.Mock).mockReturnValue(false);

    // Act
    const wrapper = shallow(
      <CharacteristicStateGardeningIdRFTreesShrubs
        changeFieldValue={changeFieldValueMock}
        editMode={true}
        currentValues={currentValues}
      />,
    );

    // Assert
    expect(wrapper).toMatchSnapshot();
    expect(wrapper.find({ disabled: false }).prop('withChange')).toBeDefined();
    expect(wrapper.find({ required: false }).prop('filter')([])).toEqual([]);
  });

  it('should render the component in view only mode if editMode is false', () => {
    // Arrange
    (useIsCurrentObjectIsExternalSystem as jest.Mock).mockReturnValue(false);

    // Act
    const wrapper = shallow(
      <CharacteristicStateGardeningIdRFTreesShrubs
        changeFieldValue={changeFieldValueMock}
        editMode={false}
        currentValues={{ state_gardening_id: 1 }}
      />,
    );

    // Assert
    expect(wrapper).toMatchSnapshot();
    expect(wrapper.find({ disabled: true })).toHaveLength(1);
  });

  it('should disable the field when the user role is external system', () => {
    // Arrange
    const currentValues = { state_gardening_id: 1 };
    (useIsCurrentObjectIsExternalSystem as jest.Mock).mockReturnValue(true);

    // Act
    const wrapper = shallow(
      <CharacteristicStateGardeningIdRFTreesShrubs
        changeFieldValue={changeFieldValueMock}
        editMode={true}
        currentValues={currentValues}
      />,
    );

    // Assert
    expect(wrapper.find({ disabled: true })).toHaveLength(1);
  });

  it('should call the changeFieldValue function when withChange prop is called', () => {
    // Arrange
    (useIsCurrentObjectIsExternalSystem as jest.Mock).mockReturnValue(false);
    const wrapper = shallow(
      <CharacteristicStateGardeningIdRFTreesShrubs
        changeFieldValue={changeFieldValueMock}
        editMode={true}
        currentValues={{ state_gardening_id: 1 }}
      />,
    );
    const withChangeFn = wrapper.find({ disabled: true }).prop('withChange');

    // Act
    withChangeFn();

    // Assert
    expect(changeFieldValueMock).toHaveBeenCalled();
  });
});
