import { Validator } from 'core/form/Validator';

/**
 * Валидация формы.
 *
 * @param {{password: string, confirmPassword: string}} values - Значения формы.
 * @returns {Record<string, string>}
 */
export const validateFormResetPassword = (values) => {
  const validate = new Validator(values);

  validate.setRequired('password', 'Необходимо ввести пароль');
  validate.setRequired('confirmPassword', 'Необходимо подтвердить пароль');

  if (values?.confirmPassword !== values?.password) {
    validate.addError('confirmPassword', 'Пароли не совпадают');
    validate.addError('password', 'Пароли не совпадают');
  }

  if (values?.password?.length < 6) {
    validate.addError(
      'confirmPassword',
      'Пароль должен содержать хотя бы 6 символов',
    );
    validate.addError('password', 'Пароль должен содержать хотя бы 6 символов');
  }

  return validate.getErrors();
};
