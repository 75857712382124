/* eslint-disable  */
import { post } from 'app/api/crud/post';

/**
 *
 * @param queryParams
 */
export const changePassword = async (queryParams) =>
  await post('/users/password', queryParams, {
    parseResponse: false,
  });
