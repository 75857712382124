import { Checkbox } from 'core/uiKit/inputs/CheckBox';
import React from 'react';

export const NO_CALC_NAME = 'no_calc';

/**
 * CheckBox __Не учитывать__.
 *
 * @param props - Пропсы.
 * @returns CheckBox __Не учитывать__.
 */
export const NoCalc = (props: React.ComponentProps<typeof Checkbox>) => {
  return <Checkbox {...props} label={'Не учитывать'} name={NO_CALC_NAME} />;
};
