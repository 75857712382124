/**
 * Функция получения изначальных значений таба Инженерные сооружения для ТС.
 *
 * @param {Function} parse - Функция парсинга.
 * @returns {{bridge_qty}}
 */
export const initEngineeringTab = (
  parse: (param: string) => number | string,
) => {
  return {
    bridge_psc: parse('bridge_psc'),
    bridge_qty: parse('bridge_qty'),
    bridge_sqr: parse('bridge_sqr'),
    cable_fancing_psc: parse('cable_fancing_psc'),
    decor_psc: parse('decor_psc'),
    lamp_qty: parse('lamp_qty'),
    light_psc: parse('light_psc'),
    light_qty: parse('light_qty'),
    pavilion_qty: parse('pavilion_qty'),
    pedestrian_psc: parse('pedestrian_psc'),
    pedestrian_qty: parse('pedestrian_qty'),
    pedestrian_sqr: parse('pedestrian_sqr'),
    police_qty: parse('police_qty'),
    police_sqr: parse('police_sqr'),
    sandbase_qty: parse('sandbase_qty'),
    sandbase_sqr: parse('sandbase_sqr'),
    snow_psc: parse('snow_psc'),
    support_qty: parse('support_qty'),
    tlight_qty: parse('tlight_qty'),
    transform_qty: parse('transform_qty'),
    tube_psc: parse('tube_psc'),
    tube_qty: parse('tube_qty'),
    urn_qty: parse('urn_qty'),
    wall_psc: parse('wall_psc'),
    water_psc: parse('water_psc'),
    сase_outside_light_qty: parse('сase_outside_light_qty'),
  };
};
