import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const CONTAINER_TYPE_NAME = 'containerType';

/**
 * ## Тип МСО.
 *
 * `type = Select`
 * `name = containerType`
 * `dict = containerType`.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - JSX.
 */
export const ContainerType = (props) => {
  const dict = 'containerType';

  const { data = [] } = useGetDictionary(dict);

  const options = data.map((option) => ({
    id: option.id,
    name: option.attribute.name,
  }));

  return (
    <Select
      {...props}
      label={'Тип МНО'}
      name={CONTAINER_TYPE_NAME}
      options={options}
    />
  );
};
