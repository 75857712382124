import { SUB_TYPE_ID_NAME } from 'core/uiKit/preparedInputs';
import { MAF_TYPE_LEVEL_3_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/MafTypeLevel3';
import { useSkipFirstRender } from 'core/utils/hooks/useSkipFirstRender';
import { useState } from 'react';
import { useField } from 'react-final-form';

/**
 * Фильтр Детализация для реестра МАФ.
 *
 * @returns {JSX.Element}
 */
export const useSetupMafTypeLevel3FilterField = () => {
  const [haveOptions, setHaveOptions] = useState(false);

  const {
    input: { value: valueSubTypeId },
  } = useField(SUB_TYPE_ID_NAME);

  const {
    input: { onChange },
  } = useField(MAF_TYPE_LEVEL_3_ID_NAME);

  useSkipFirstRender(() => {
    onChange('');
  }, [valueSubTypeId]);

  /**
   * Функция фильтрации поля Уточнения с сайд эффектом если нет справочных значений.
   *
   * @param {object} dict - Справочник.
   * @returns {Array<{id: number, name: string, mafTypeLevel1: Array<number>}> | Array}
   */
  const filter = (dict) => {
    const options = dict.filter((item) =>
      (item?.mafTypeLevel1 || []).includes(valueSubTypeId),
    );
    setHaveOptions(Boolean(options.length));
    return options;
  };

  return { disabled: !haveOptions, filter };
};
