import { getPlantServiceRecomendationsIdRequired } from 'app/components/card/ogh/TreesShrubs/fields/PlantServiceRecomendationsIdRF.TreesShrubs/getPlantServiceRecomendationsIdRequired';
import { PLANT_SERVICE_RECOMENDATIONS_ID_NAME } from 'core/uiKit/preparedInputs/selects/multiSelect/PlantServiceRecomendationsId';

export const mandatoryFieldNamesTreesShrubs = {
  [PLANT_SERVICE_RECOMENDATIONS_ID_NAME]: 'Рекомендации по уходу',
  age: 'Возраст, лет',
  area: 'Площадь, кв.м',
  bio_group_num: 'Номер биогруппы',
  characteristic_state_gardening_id: 'Характеристика состояния',
  detailed_state_gardening_id: 'Уточнение состояния',
  diameter: 'Диаметр на высоте 1,3 м, см.',
  distance: 'Количество, п.м.',
  green_num: 'Номер растения',
  height: 'Высота, м',
  life_form_type_id: 'Жизненная форма',
  plant_type_id: 'Вид растения',
  plantation_type_id: 'Тип насаждения',
  quantity: 'Количество, шт.',
  section_num: 'Номер участка',
  state_gardening_id: 'Состояние',
};

/**
 * Функция получения полей для отображения подсказки об обязательности полей.
 *
 * @param {object} currentValues - Значения формы.
 * @returns {{"Исходные данные": Array<string>}}
 */
export const getFieldsMandatoryMessage = (currentValues) => {
  const arrFields = [
    'age',
    'area',
    'bio_group_num',
    'characteristic_state_gardening_id',
    'detailed_state_gardening_id',
    'diameter',
    'green_num',
    'height',
    'life_form_type_id',
    'plant_type_id',
    'plantation_type_id',
    'quantity',
    'section_num',
    'distance',
    'state_gardening_id',
  ];

  if (getPlantServiceRecomendationsIdRequired(currentValues)) {
    arrFields.push(PLANT_SERVICE_RECOMENDATIONS_ID_NAME);
  }

  return {
    'Исходные данные': arrFields,
  };
};
