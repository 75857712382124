/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */

/**
 * Колонка __Количество, м.п.__.
 *
 * @type {{ accessor: 'distance', Header: 'Количество, м.п.',}}
 * @augments Cell
 */
export const distance = {
  Header: 'Количество, м.п.',
  accessor: 'distance',
  sortable: true,
};
