import { NO_CLEANING_TYPE } from 'app/constants/cleaningTypes';

/* eslint-disable */
export default function validate16(values) {
  const isFootwayArea = values.is_footway_area;
  const isRoadwayArea = values.is_roadway_area;
  const cleaningType = values.cleaning_type;

  if (typeof cleaningType === 'undefined') {
    return {
      cleaning_type: 'обязательное поле',
    };
  }
  if (cleaningType !== NO_CLEANING_TYPE && !(isFootwayArea ^ isRoadwayArea)) {
    return {
      cleaning_type:
        'Для способа уборки "Механизированная уборка" или "Ручная уборка" требуется указать принадлежность',
    };
  }
  return {};
}
