/* eslint-disable */
import React from 'react';
import { NumberParam } from 'use-query-params';

import { TextFieldFF } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
export const ROOT_ID_NAME = 'rootId';

 
 
/**
 *  ID    root_id
 */
 
export const RootIdFilter = () => {
  return (
    <TextFieldFF
      className={filterInput}
      label="ID"
      name={ROOT_ID_NAME}
      type="number"
    />
  );
};

 
export const rootIdParams = {
  [ROOT_ID_NAME]: NumberParam,
};
