import _ from 'lodash';

import * as types from '../constants/actionTypes';
import { clearTree } from './clearing';

/**
 * Редьюсер дерева.
 *
 * @param {object} state - Состояние.
 * @param {object} action - Экшен.
 * @returns {object} - Преобразованное состояние.
 */
export default function tree(state = {}, action) {
  switch (action.type) {
    case types.RECEIVE_TREE: {
      const { object, pathId } = action;
      if (Array.isArray(object) && object.length) {
        return {
          ...state,
          data: {
            ...state.data,
            [pathId]: object,
          },
          dataForModify: {
            ...state.dataForModify,
            [pathId]: object,
          },
        };
      }
      return state;
    }
    case types.RECEIVE_TECH_TREE: {
      return {
        ...state,
        data: action.object,
      };
    }
    case types.RECEIVE_FEW_TREE: {
      return {
        ...state,
        active: action.active,
        data: {
          ...state.data,
          ...action.object.elements,
        },
        dataForModify: {
          ...state.dataForModify,
          ...action.object.elements,
        },
        elements: _.mapValues(action.object.elements, () => {
          return true;
        }),
        groups: _.transform(
          action.object.groups || {},
          (result, typeId, pathId) => {
            result[`${pathId}-${typeId}`] = true;
          },
          {},
        ),
      };
    }
    case types.CLEAR_EXPAND_AFTER_FEW_TREE: {
      return {
        ...state,
        active: undefined,
        elements: undefined,
        groups: undefined,
      };
    }
    case types.EDIT_CARD: {
      return {
        ...state,
        dataForModify: {
          ...state.data,
        },
        journal: null,
      };
    }
    case types.REQUEST_ROLLBACK_TREE_CHANGES: {
      return {
        ...state,
        dataForModify: null,
        journal: null,
      };
    }
    case types.RECEIVE_CREATE_CARD: {
      return {
        ...state,
        data: null,
        dataForModify: null,
        journal: null,
      };
    }
    case types.RECEIVE_UPDATE_CARD: {
      return {
        ...state,
        data: {
          ...state.dataForModify,
        },
        journal: null,
      };
    }
    case types.RECEIVE_TREE_SELECTED_ELEMENT:
      return {
        ...state,
        object: action.object,
      };
    case types.RECEIVE_PREVIEW_TREE_SELECTED_ELEMENT:
      return {
        ...state,
        object: action.object,
      };
    case types.RECEIVE_DELETE_CARD_ALLOW: {
      return action.delete_root
        ? state
        : {
            ...state,
            object: {
              ...state.object,
              ...action.object,
            },
          };
    }
    case types.RECEIVE_EDIT_CARD_ALLOW: {
      return action.root_edit
        ? state
        : {
            ...state,
            object: {
              ...state.object,
              ...action.object,
            },
          };
    }
    case types.TREE_SELECT_ROOT:
      return {
        ...state,
        object: null,
      };
    case types.RECEIVE_CARD:
      return {
        ...state,
        ...(action.resetTree && { object: null }),
      };
    case types.RECEIVE_REGISTRY_FOR_TREE:
      return {
        ...state,
        registry: action.object,
      };
    case types.RECEIVE_REGISTRY_FOR_TREE_GROUP:
      return {
        ...state,
        groupRegistry: action.object,
      };
    case types.RECEIVE_REGISTRY_ACTUAL_GROUP: {
      const table = state.groupRegistry.table.filter(
        (row) =>
          !action.object.table.some((item) => row.object_id === item.rotten_id),
      );
      return {
        ...state,
        groupRegistry: {
          ...state.groupRegistry,
          table: [...action.object.table, ...table],
        },
      };
    }
    case types.REQUEST_SAVE_TREE: {
      return state;
    }
    case types.TOGGLE_TREE_EDIT_FORM: {
      return {
        ...state,
        groupRegistry: {},
        registry: {},
      };
    }
    case types.ADD_TO_TREE_JOURNAL: {
      return {
        ...state,
        journal: {
          ...state.journal,
          [action.root_id]: action.journal,
        },
      };
    }
    case types.RECEIVE_OBJECT_TYPE_CNT: {
      return {
        ...state,
        count: action.object,
      };
    }
    case types.RECEIVE_ADD_OBJECT_TO_TREE: {
      const key =
        (action.root_id || 'null') +
        (action.path_object_id !== null ? '.' + action.path_object_id : '');
      let data;
      if (state.data[key]) {
        data = state.data[key].map((item) => {
          return item.type_path === action.path_object_type
            ? action.object_tree_branch
            : item;
        });
      } else {
        data = [action.object_tree_branch];
      }
      return {
        ...state,
        data: {
          ...state.data,
          [key]: data,
        },
        dataForModify: {
          ...state.dataForModify,
          [key]: data,
        },
      };
    }
    case types.CHANGE_TREE_JOURNAL: {
      const key =
        (action.root_id || 'null') +
        (action.path_object_id !== null ? '.' + action.path_object_id : '');
      if (state.dataForModify[key]) {
        return {
          ...state,
          dataForModify: {
            ...state.dataForModify,
            [key]: state.dataForModify[key].map((item) => {
              return item.type_path === action.path_object_type
                ? action.object_tree_branch
                : item;
            }),
          },
        };
      } else {
        return {
          ...state,
          dataForModify: {
            ...state.dataForModify,
            [key]: [action.object_tree_branch],
          },
        };
      }
    }
    case types.RECEIVE_GEOMETRY_FOR_MAIN_PAGE: {
      return {
        ...state,
        mainPageGeometry: action.object,
      };
    }
    case types.SET_MAP_TREE_EXPANDED: {
      return {
        ...state,
        mapExpanded: action.newExpanded || {
          ...state.mapExpanded,
          [action.pathId]: _.has(state.mapExpanded || {}, action.pathId)
            ? !state.mapExpanded[action.pathId]
            : true,
        },
      };
    }
    case types.SET_OGH_TREE_EXPANDED: {
      return {
        ...state,
        oghExpanded: action.newExpanded || {
          ...state.oghExpanded,
          [action.pathId]: _.has(state.oghExpanded || {}, action.pathId)
            ? !state.oghExpanded[action.pathId]
            : true,
        },
      };
    }
    case types.SET_ACTIVE_MAP_TREE_ELEMENT: {
      return {
        ...state,
        mapActive: action.pathId,
      };
    }
    case types.SET_ACTIVE_OGH_TREE_ELEMENT: {
      return {
        ...state,
        oghActive: action.pathId,
      };
    }
    case types.CLEAR_TREE_ELEMENTS: {
      return {};
    }
    case types.SET_MAIN_PAGE_TREE_DATA: {
      return {
        ...state,
        mainPageTreeData: action.object,
      };
    }
    case types.PUSH_TO_ROTTEN_MAP: {
      return {
        ...state,
        rottenMap: { ...state.rottenMap, ...action.map },
      };
    }
    case types.VIEW_CARD: {
      return {
        ...state,
        rottenMap: null,
      };
    }
    case types.LOCATION_CHANGE:
      return clearTree(action, state);

    case types.SAVE_TREE_ACTIONS: {
      return {
        ...state,
        actionsTree: state.actionsTree
          ? {
              ...state.actionsTree,
              ...action.dataList,
            }
          : { ...action.dataList },
      };
    }
    case types.CLEAR_TREE_ACTIONS: {
      return {
        ...state,
        actionsTree: {},
      };
    }
    default:
      return state;
  }
}
