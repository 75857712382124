import { REQUIRED_FIELD_MESSAGE } from 'app/constants/messages';
import { CATEGORY_SP_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CategorySpId';

/**
 * Валидация Категория по СП 42 13330 2016.
 *
 * @param {object} errors - Объект с ошибками.
 * @param {boolean} isOrphanObject - Объект, переданный в эксплуатацию.
 * @param {number| string} categorySpId - Значение Категория.
 * @returns {void}
 */
export const validateCleanSpCategoryIdField = (
  errors,
  isOrphanObject,
  categorySpId,
) => {
  if (isOrphanObject) {
    return;
  }

  if (
    categorySpId === null ||
    categorySpId === '' ||
    categorySpId === undefined
  ) {
    errors[CATEGORY_SP_ID_NAME] = REQUIRED_FIELD_MESSAGE;
  }
};
