/* eslint-disable  */
import React from 'react';

/**
 *
 * @param root0
 * @param root0.children
 */
export const RegistryTableToolbar = ({ children }) => {
  return <div className={'registry-table-toolbar'}>{children}</div>;
};
