import React from 'react';

import { TableList } from '../../Table.List';
import { columnsTableVerticalLandscapingList } from './columns.Table.VerticalLandscapingList';

/**
 * Таблица __Ведомость учета зеленых насаждений (элементы вертикального озеленения)__.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.code - Код.
 * @returns {JSX.Element}
 */
export const TableVerticalLandscapingList = ({ code }) => {
  return (
    <TableList columns={columnsTableVerticalLandscapingList} code={code} />
  );
};
