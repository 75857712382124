import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

vi.mock('app/api/hooks/useGetCurrentCard');
vi.mock('app/api/hooks/useIsEditCurrentObjectIsExternalSystem');
vi.mock('app/pages/cardsOgh/hooks/useMode');

import { RANDOM_NUMBER } from 'core/forTesting/constants';

import { CoatingGroupIdField } from './CoatingGroupId.Field';

describe('🐛 CoatingGroupIdFieldSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    useIsEditCurrentObjectIsExternalSystem.mockReturnValue(true);
    useMode.mockReturnValue({ editMode: false });
    useGetCurrentCard.mockReturnValue({ typeId: RANDOM_NUMBER });
    // 🔥 Act
    const wrapper = shallow(<CoatingGroupIdField />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<CoatingGroupIdRF />');
  });
});
