/* eslint-disable */
import React from 'react';
import { NumberParam } from 'use-query-params';

import {
  CONTAINER_TYPE_NAME,
  ContainerTypeFF,
} from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
 
/**
 * Тип МСО
 * containerType
 */

 
export const ContainerTypeFilter = () => (
  <ContainerTypeFF className={filterInput} />
);

 
export const containerTypeParams = {
  [CONTAINER_TYPE_NAME]: NumberParam,
};
