import { useGetCurrentCardSelector } from 'app/selectors/useSelectors/useGetCurrentCard.Selector';
import { Select } from 'core/uiKit/inputs/selects/Select';

export const NET_ELEMENT_TYPE_ID_NAME = 'net_element_type_id';

/**
 * F.
 *
 * @param {*} props - Properties.
 * @returns {JSX.Element}
 */
export const NetElementTypeId = (props) => {
  const { netElementType } = useGetCurrentCardSelector();

  return (
    <Select
      {...props}
      label={'Тип'}
      name={NET_ELEMENT_TYPE_ID_NAME}
      options={netElementType}
      withAll={false}
    />
  );
};
