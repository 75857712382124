import './filterStyle.scss';

import { Button } from 'core/uiKit/components/buttons/Button';
import React from 'react';
import { Form } from 'react-final-form';

import { validate } from './validate';

/**
 * FilterFF.
 *
 * @param {*} root0 - Props.
 * @param {*} root0.children - Children.
 * @param {string} root0.className - Class name.
 * @param {*} root0.initialValues - InitialValues.
 * @param {Function} root0.onSubmit - OnSubmit.
 * @param {Function} root0.reset - Reset.
 * @returns {JSX.Element}
 */
export const FilterFF = ({
  children,
  className,
  initialValues,
  onSubmit,
  reset,
}) => {
  return (
    <Form
      initialValues={initialValues}
      validate={validate}
      render={({ handleSubmit, form }) => {
        return (
          <form
            className={'pb-3 filter__form ' + className}
            onSubmit={handleSubmit}
          >
            {children}

            <div className="filter__btn-submit-group">
              <Button variant={'contained'} color={'primary'} type="submit">
                Поиск
              </Button>
              <Button
                className="ms-3"
                color={'primary'}
                id={'clear-form'}
                variant={'outlined'}
                onClick={() => reset(form)}
              >
                Сбросить
              </Button>
            </div>
          </form>
        );
      }}
      onSubmit={onSubmit}
    />
  );
};
