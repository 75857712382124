/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import { NumberField } from 'core/uiKit/inputs';

 
export const QUANTITY_PCS_NAME = 'quantity_pcs';

 
/* eslint-disable */
/**
 * Комментарий
 *
 * * `label = Количество, шт.`
 * @param props
 * @returns <TextField label="Количество, шт." {...props} />
 * @constructor
 */
 
export const QuantityPcs = (props) => {
  return <NumberField label={'Количество, шт.'} {...props} />;
};

QuantityPcs.propTypes = {
   
   
  /**
   * выбор темной темы
   */
  dark: PropTypes.bool,
   
   
  /**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
  /**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
  /**
   * функция вызова когда выберется итем
   */
  onChange: PropTypes.func,
   
   
  /**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
   
   
  /**
   * значение
   */
  value: PropTypes.any,
};
