import { useSelector } from 'react-redux';

/**
 * Хук получения текущих значения формы паспорта.
 *
 * @returns
 */
export const useFormValuesCardRF = <ReturnsTypeValues>() => {
  return useSelector(
    (state: { form: { editorCard: { values: ReturnsTypeValues } } }) =>
      state?.form?.editorCard?.values,
  );
};
