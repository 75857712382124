import * as api from 'app/api/basePgm';
import * as types from 'app/constants/actionTypes';

/* eslint-disable */
export const fetchPgmActualBases = (data) => async (dispatch) => {
  dispatch(requestActualPgmBases());
  const response = await api.getAllPgmActualBases(data);
  dispatch(receiveActualPgmBases());
  return response;
};

function requestActualPgmBases() {
  return {
    type: types.REQUEST_ACTUAL_PGM_BASES,
  };
}

function receiveActualPgmBases() {
  return {
    type: types.RECEIVE_ACTUAL_PGM_BASES,
  };
}
