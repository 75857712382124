
/* eslint-disable */
export default function (result, state, props) {
  const { mode, card } = props;

  return {
    ...result,

    disabled: (mode && mode.createMode) || false,
    startDate: card.start_date,
    endDate: card.end_date,
    recordId: (state.tree.object || state.card.object).record_id,
    selectedOghOrders: state.card.selectedOghOrders,
    ordersTableConfig: state.tables.orders,
  };
}
