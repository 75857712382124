import { REQUIRED_FIELD_MESSAGE } from 'app/constants/messages';


/* eslint-disable */
export default function (formValues) {
  const { department_id } = formValues;
  let result = {};
  if (department_id === null) {
    result['department_id'] = REQUIRED_FIELD_MESSAGE;
  }
  return Object.keys(result).length > 0 ? result : null;
}
