import { useChangeFieldValueRF } from 'app/actions/odsObjectActions/useChangeFieldValueRF';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { CleanCategoryIdRf } from 'core/form/reduxForm/fields/prepared/selects/simple/CleanCategoryId.RF';
import { CLEAN_CATEGORY_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CleanCategoryId';
import React from 'react';
import { useSelector } from 'react-redux';

/**
 * Категория по уборке с фильтрацией.
 *
 * @returns {JSX.Element}
 */
export const CleanCategoryIdField = () => {
  const { editMode } = useEditMode();
  const isAvd = useSelector(
    (state: {
      form: { editorCard: { values: { is_avd: boolean | null } } };
    }) => {
      return state.form.editorCard.values.is_avd;
    },
  );

  const cleanCategoryValue = useSelector(
    (state: {
      form: {
        editorCard: {
          values: { [CLEAN_CATEGORY_ID_NAME]: number | string | null };
        };
      };
    }) => {
      return state.form.editorCard.values[CLEAN_CATEGORY_ID_NAME];
    },
  );

  const changeField = useChangeFieldValueRF();

  /**
   * Функция фильтрации в зависимости от выбранного чекбокса автомобильная дорога.
   *
   * @param dict - Опшины.
   * @returns {*}
   */
  const filter = (dict: { isAvd: boolean; name: string; id: number }[]) => {
    const options = dict.reduce((arr: { id: number; name: string }[], item) => {
      if (!isAvd && !item.isAvd) {
        arr.push(item);
        return arr;
      }
      if (isAvd && item.isAvd) {
        arr.push(item);
      }

      return arr;
    }, []);

    // Костыль для очистки селекта если его значения нет в массиве опшинов
    if (cleanCategoryValue !== '' && options.length) {
      if (options.every((item) => cleanCategoryValue !== item.id)) {
        changeField(CLEAN_CATEGORY_ID_NAME, '');
      }
    }

    return options;
  };

  return (
    <CleanCategoryIdRf
      // @ts-ignore
      filter={filter}
      disabled={!editMode}
      required={true}
    />
  );
};
