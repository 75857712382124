import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { parseDate } from 'app/components/card/common/parse';
import { useGetInitialValuesTablesDocuments } from 'app/pages/cardsOgh/components/tables/Documents/hooks/useGetInitialValuesTables';
import { useFilterQueryParamsVersionDefault } from 'app/pages/cardsOgh/components/tables/Versions/Filters/useFilterQueryParamsVersionDefault';
import { useMemo } from 'react';

/* eslint-disable */
/**
 * Хук установки изначального состояния полей Кмкостей для хранения ПГР.
 *
 * @returns {object} - Возвращаем объект полей по умолчанию.
 * @example useInitialValues()
 */
export const useInitialValues = () => {
  const { startDate, endDate, attribute } = useGetCurrentCard();

  const objTables = useGetInitialValuesTablesDocuments();
  const { filters: filtersVersionTable } = useFilterQueryParamsVersionDefault();
  return useMemo(() => {
    return {
      startDate: parseDate(startDate),
      endDate: parseDate(endDate),
      reagentKindId: attribute?.reagent_kind_id?.reagent_kind,
      reagentId: attribute?.reagent_id?.reagent,
      descr: attribute?.descr,
      tankNum: attribute?.tank_num,
      quantity: attribute?.quantity,
      minimumBalancePercent: attribute?.minimum_balance_percent,
      tonnage: attribute?.tonnage,
      ...objTables,
      ...filtersVersionTable,
    };
    
  }, [attribute, startDate, endDate]);
};
