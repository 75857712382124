import { useSelector } from 'react-redux';

/**
 * Selector получения текущего значения полей.
 *
 * @category hooks
 *
 * @function
 * @description Получаем текущее значение полей.
 * @returns {*}
 */
export const useEditorCardSelector = () => {
  return useSelector((state) => state?.form?.editorCard?.values || {});
};
