import * as types from '../constants/actionTypes';
import { clearRegistry } from './clearing/index';

/* eslint-disable */
export default function registry(state = {}, action) {
  switch (action.type) {
    case types.RECEIVE_SEARCH_IN_REGISTRY_RESULTS:
      return {
        ...state,
        data: action.object,
      };
    case types.SET_ODS_REGISTRY_TYPE_NAVIGATION_MODE:
      return {
        ...state,
        navmode: action.navmode,
      };
    case types.ODS_REGISTRY_RESET:
      return {
        ...state,
        data: undefined,
      };
    case types.EXPORT_REGISTRY:
      return {
        ...state,
        exportPath: action.apiPath,
        exportAdditionalData: action.additionalData,
        isLoading: !!action.apiPath,
      };
    case types.STORE_FILTER_STATE: {
      return {
        ...state,
        filterValues: action.data,
      };
    }
    case types.LOCATION_CHANGE:
      return clearRegistry(action, state);
    default:
      return state;
  }
}
