 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */

import { TypeIdCell } from './TypeIdCell';
 
/* eslint-disable */
/**
 * Колонка __Является частью составного объекта__.
 *
 * @type {{
 * accessor: 'group_id',
 * Header: 'Является частью составного объекта',
 * sortable: true,
 * Cell: TypeIdCell,
 * }}
 * @augments Cell
 */
export const group_id = {
  accessor: 'group_id',
  Header: 'Является частью составного объекта',
  sortable: true,
  Cell: TypeIdCell,
};
