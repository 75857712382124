/* eslint-disable */
import React from 'react';

import KeyboardDatePickerRF from 'core/form/reduxForm/fields/default/datePickers/KeyboardDatePickerRF';
import { renderCustomTextField } from 'app/components/card/common/grid';

import FileUpload from './FileUpload';


/* eslint-disable */
export default [
  {
    id: 'name',
    name: 'name',
    label: 'Наименование',
    editable: true,
    formValue: true,
    component: renderCustomTextField,
    addition: {
      type: 'text',
    },
  },
  {
    id: 'date_start',
    name: 'date_start',
    editable: true,
    component: KeyboardDatePickerRF,
    addition: {
      label: 'Дата начала работ',
    },
  },
  {
    id: 'date_end',
    name: 'date_end',
    editable: true,
    component: KeyboardDatePickerRF,
    addition: {
      label: 'Дата окончания работ',
    },
  },
  {
    id: 'property',
    name: 'property',
    label: 'Характеристика (объемы)',
    editable: true,
    component: renderCustomTextField,
    addition: {
      type: 'text',
    },
  },
  {
    id: 'file',
    name: 'file',
    editable: true,
    component: (props) => <FileUpload {...props} />,
    addition: {
      labelStyle: {
        height: 33,
      },
    },
  },
];
