import getDict from 'app/selectors/getDict';
/* eslint-disable */
import { get } from 'lodash';
import { createSelector } from 'reselect';

const getReagentKinds = getDict('reagentKinds');

/**
 *
 * @param state
 */
const getCurrentReagentKind = (state) => {
  const currentReagentKindId = get(
    state,
    'form.editCardDialogForm.values.reagent_kind_id',
  );
  return (
    currentReagentKindId &&
    getReagentKinds(state).find((item) => item.id === currentReagentKindId)
  );
};

export default createSelector(
  [getCurrentReagentKind],
  (currentReagentKind) =>
    currentReagentKind && currentReagentKind.code === 'solid_reagent',
);
