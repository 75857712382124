import { useGetOghTypes } from 'core/utils/hooks/useGetOghTypes';
import __snakeCase from 'lodash/snakeCase';
import { useParams } from 'react-router-dom';

/**
 * Хук обертка получения параметров из реакт роутер дом. Дабы абстрагироваться.
 *
 * @returns {{
 * objectId:number,
 * id: number,
 * childId: number,
 * applicationId: number,
 * scheduleId: number,
 * parentId: string,
 * knowledgeBaseCode: string,
 * oghRegistry: *,
 * actions: *
 * }}
 */
export const useGetParamsUrl = () => {
  const result = useParams();

  // пока завязался на проверку создания так
  const create = Boolean(result?.startDate);
  const createFromParent = Boolean(result.id && create && result.typeId);

  const actions = {
    createFromParent,
    haveParent: createFromParent || !isNaN(result.childId),
  };

  const params = {
    child: result?.child,
    childCreate: false,
    create: create,
    createFromParent,
    parent: result?.parent,
    parentCreate: false,
    startDate: result.startDate,
  };

  if (create) {
    params.childCreate = !!params?.child;
    params.parentCreate = !params?.child;
    params.isCreated = !Number(params.parent); // не понятно почему именно парент
  }

  const currentTypeNameOgh = params?.child || params?.parent;
  const oghType = useGetOghTypes(
    __snakeCase(currentTypeNameOgh || result.typeId),
  );

  result.oghRegistry = params;
  result.actions = actions;

  if (oghType?.id) {
    result.oghType = oghType;
    result.typeId = oghType?.id;
  }

  result.parentId = result.id || result.parent;
  result.childId = result.childId || result.child;

  return result;
};
