/* eslint-disable */
/**
 * Переводим названия свойств в 🐍 змей.
 *
 * @param {object|null} [queryParams] - Объект, параметров.
 * @returns {string|{}|number|*}
 */
export const turn_into_snake_case = (queryParams) => {
  if (Array.isArray(queryParams)) {
    return queryParams;
  }

  if (typeof queryParams === 'string') {
    return queryParams;
  }
  if (typeof queryParams === 'number') {
    return queryParams;
  }
  const snakeCaseQueryParams = {};

  for (const key in queryParams) {
    if (typeof queryParams[key] !== 'object') {
      snakeCaseQueryParams[snake_case(key)] = queryParams[key];
      continue;
    }

    // если объект пустой, то это null
    if (!queryParams[key]) {
      continue;
    }

    // если массив пустой то отсеиваетм его
    if (queryParams[key].length === 0) {
      continue;
    }

    snakeCaseQueryParams[snake_case(key)] = turn_into_snake_case(
      queryParams[key],
    );
  }

  return snakeCaseQueryParams;
};

/**
 * Переводим в змей.
 *
 * @param {string} key - Строка которую надо перевести в змею.
 * @returns {*}
 */
const snake_case = (key) =>
  key.replace(/[A-Z]/g, (s, i) => (i ? '_' : '') + s.toLowerCase());
