 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */

import { CreateTypeCell } from 'core/uiKit/components/cells/CreateType.Cell';
 
/* eslint-disable */
/**
 * Колонка __Тип создания объекта__.
 *
 * @type {{
 * accessor: 'create_type',
 * Cell: CreateTypeCell,
 * Header: 'Тип создания объекта',
 * sortable: true,
 * }}
 * @augments Cell
 */
export const create_type = {
  accessor: 'create_type',
  Cell: CreateTypeCell,
  Header: 'Тип создания объекта',
  sortable: true,
};
