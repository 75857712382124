import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';

/**
 * Select RF __Отраслевой ОИВ__.
 *
 * @param {*} params - Параметры.
 * @returns {Function}
 */
export default function (params = {}) {
  return (props) => {
    const { editable } = params || null;
    const elementEditable = editable && props.editable;

    return (
      <SelectRF
        name={'department_id'}
        label={'Отраслевой ОИВ'}
        options={props.departmentTypes}
        disabled={!elementEditable}
        required={props.required}
      />
    );
  };
}
