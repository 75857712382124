import { get } from 'lodash';

 
/**
 * Fn getDict.
 *
 * @deprecated
 * @see используй  useGetDictionary();
 * @param {string} name - Жопа.
 * @returns {Function} - Жопа.
 * @example --------
 */
const getDict = (name) => (state) =>
  get(state.odsDicts, name) || [];
export default getDict;
