import getFormValues from 'app/selectors/form/getFormValues';
/* eslint-disable  */
import { get } from 'lodash';
import { createSelector } from 'reselect';

/**
 *
 * @param state
 * @param props
 */
const getNoCleanArea = (state, props) =>
  parseInt(get(props, 'card.no_clean_area'), 10);

/**
 *
 * @param state
 */
const getArea = (state) => getFormValues(state).area;

/**
 *
 * @param state
 */
const getOOTCleanArea = (state) => getFormValues(state).oot_clean_area;

/**
 *
 * @param state
 */
const getAutoCleanArea = (state) => getFormValues(state).auto_clean_area;

/**
 *
 * @param state
 */
const getManualCleanArea = (state) => getFormValues(state).manual_clean_area;

export default createSelector(
  [
    getNoCleanArea,
    getArea,
    getAutoCleanArea,
    getManualCleanArea,
    getOOTCleanArea,
  ],
  (
    noCleanArea,
    area = 0,
    autoCleanArea = 0,
    manualCleanArea = 0,
    ootCleanArea = 0,
  ) => {
    let result;

    const someAreaIsNaN = [
      area,
      autoCleanArea,
      manualCleanArea,
      ootCleanArea,
    ].some((currentArea) => isNaN(currentArea));

    if (noCleanArea) {
      result = noCleanArea;
    } else {
      result = someAreaIsNaN
        ? 0
        : (area * 100 -
            autoCleanArea * 100 -
            manualCleanArea * 100 -
            ootCleanArea * 100) /
          100;
    }

    return result;
  },
);
