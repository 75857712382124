import { DndFileUploadFF } from 'core/form/finalForm/fields/prepared/fileUpload/DndFileUploadFF';
import { SendReasonFF } from 'core/form/finalForm/fields/prepared/selects/multiSelect/SendReasonFF';
import { Alert } from 'core/uiKit/material-ui';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import { FormDialogSignatureFiles } from './Form.Dialog.SignatureFiles';
import useSetup from './useSetup.Form.Dialog.SignatureFiles';

// имитируем зависимости модуля
vi.mock('./useSetup.Form.Dialog.SignatureFiles');

describe('Form.Dialog.SignatureFiles.jestSpec', () => {
  describe('props  почти по дефолту', () => {
    let wrapper;
    const onSignaturesChangeSpy = vi.fn();
    beforeAll(() => {
      useSetup.mockReturnValue({
        callbackError: vi.fn(),
        certificate: 'mockedCertificate',
        error: null,
        files: [6, 26],
        filesForSignature: [],
        isUnion: false,
        onSignaturesChange: onSignaturesChangeSpy,
        signatures: [2, 5, 7],
      });

      // Act
      wrapper = shallow(<FormDialogSignatureFiles />);
    });
    it('props ReasonFilter', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      // Act

      // Assert
      expect(wrapper.find('ReasonFilter').props()).toStrictEqual({
        Component: SendReasonFF,
        label: 'Причина закрытия',
        privilegeCode: 'del_ogh',
        required: true,
        withAll: false,
      });
    });

    it('props CommentFF', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      // Act

      // Assert
      expect(wrapper.find('CommentFF').props()).toStrictEqual({
        fullWidth: true,
      });
    });

    it('props NumberFieldFF', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      // Act

      // Assert
      expect(wrapper.find('NumberFieldFF').exists()).toBeFalsy();
    });

    it('props CertInfo', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      // Act

      // Assert
      expect(wrapper.find('CertInfo').props()).toStrictEqual({
        certificate: 'mockedCertificate',
      });
    });

    it('props Alert', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      // Act

      // Assert
      expect(wrapper.find('Alert').exists()).toBeFalsy();
    });

    it('props FileSignatureCryptoProFF', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      // Act

      // Assert
      expect(wrapper.find('FileSignatureCryptoProFF').exists()).toBeFalsy();
    });

    it('props FileListSign', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      // Act

      // Assert
      expect(wrapper.find('FileListSign').props()).toStrictEqual({
        children: (
          <DndFileUploadFF
            name="files"
            text="Добавить еще файлы для подписания. Переместите файлы или нажмите для выбора"
          />
        ),
        files: [6, 26],
      });
    });

    it('props SignatureList', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      // Act

      // Assert
      expect(wrapper.find('SignatureList').props()).toStrictEqual({
        clearSignatures: onSignaturesChangeSpy,
        signatures: [2, 5, 7],
      });
    });
  });

  describe('props есть значения', () => {
    let wrapper;
    const onSignaturesChangeSpy = vi.fn();
    const callbackErrorSpy = vi.fn();
    beforeAll(() => {
      useSetup.mockReturnValue({
        callbackError: callbackErrorSpy,
        certificate: 'mockedCertificate',
        error: 'sadfasdfsdf',
        files: [6, 26],
        filesForSignature: [3, 5, 6],
        isUnion: true,
        onSignaturesChange: onSignaturesChangeSpy,
        signatures: [2, 5, 7],
      });

      // Act
      wrapper = shallow(<FormDialogSignatureFiles />);
    });

    it('props NumberFieldFF', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      // Act

      // Assert
      expect(wrapper.find('NumberFieldFF').prop('name')).toBe('id_ogh_uni');
      expect(wrapper.find('NumberFieldFF').prop('label')).toBe(
        'ОГХ, в который входит закрываемый ОГХ.',
      );
    });

    it('props Alert', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      // Act

      // Assert
      expect(wrapper.find(Alert).props()).toStrictEqual({
        children: 'sadfasdfsdf',
        severity: 'error',
        variant: 'filled',
      });
    });

    it('props FileSignatureCryptoProFF', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      // Act

      // Assert
      expect(wrapper.find('FileSignatureCryptoProFF').props()).toStrictEqual({
        callbackError: callbackErrorSpy,
        files: [3, 5, 6],
      });
    });
  });
});
