import { RegistrySkeleton } from 'core/uiKit/components';
import React, { Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { CreateEditNsiBidLazy } from './Create.NSIBid';
import { pathCreateNSIBid } from './Create.NSIBid/path.Create.NSIBid';
import { NSIBidLazy } from './index';
import { RequestChangeNsiBidLazy } from './RequestChange.NsiBid';
import { pathRequestChangeNsiBid } from './RequestChange.NsiBid/path.RequestChange.NsiBid';

/**
 * Роут NSI.
 *
 * @returns {JSX.Element} - JSX.
 */
export const NSIBidRoute = () => {
  useEffect(() => {
    NSIBidLazy.preload();
    RequestChangeNsiBidLazy.preload();
    CreateEditNsiBidLazy.preload();
  });

  return (
    <Suspense fallback={<RegistrySkeleton />}>
      <Routes>
        <Route element={<NSIBidLazy />} path={`/`} exact={true} />
        <Route
          element={<CreateEditNsiBidLazy />}
          path={`${pathCreateNSIBid.path}`}
        />
        <Route
          element={<RequestChangeNsiBidLazy />}
          path={`${pathRequestChangeNsiBid.path}/:idRequest`}
        />
      </Routes>
    </Suspense>
  );
};
