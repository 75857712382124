import * as types from 'app/constants/actionTypes';

/* eslint-disable */
export function deleteCardAllow(object, delete_root) {
  return {
    type: types.RECEIVE_DELETE_CARD_ALLOW,
    object,
    delete_root,
  };
}
