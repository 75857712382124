import { renderA } from 'core/utils/renders/renderA';
import React from 'react';

/**
 * Компонент сообщения при вызове метода пересечений.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const IntersectionsMessage = (props) => {

  /**
   * Отрисовка списка пересечений.
   *
   * @returns {JSX.Element}
   */
  const renderListItems = () => {
    return props.intersections.map((item, index) => (
      <li key={index}>{renderA(item)}</li>
    ));
  };

  const { message, orderedList, GeometryCSVLink } = props;
  return (
    <div>
      {message}
      <p>{GeometryCSVLink}</p>
      {orderedList ? (
        <ol>{renderListItems()}</ol>
      ) : (
        <ul>{renderListItems()}</ul>
      )}
    </div>
  );
};

export default IntersectionsMessage;
