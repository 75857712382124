import NavItem from 'app/components/common/NavBar/NavItem';
import { useUser } from 'app/pages/providers';
import { pathExaminationOGHApplication } from 'app/pages/registry/certificationOfOgh/ExaminationOGH.Application/path.ExaminationOGHApplication';
import { pathCertificationOfOgh } from 'app/pages/registry/certificationOfOgh/path.CertificationOfOgh';
import { pathRegistry } from 'app/pages/registry/path.Registry';
import React from 'react';

/**
 * Link Админки новостей в шапке сайта.
 *
 * @returns {JSX.Element | null} - JSX.
 */
export const ExaminationOGHApplicationLink = () => {
  const { hasPermission } = useUser();
  const isShow = hasPermission(pathExaminationOGHApplication.privilegeView);

  return isShow ? (
    <NavItem
      link={`/${pathRegistry.path}/${pathCertificationOfOgh.path}/${pathExaminationOGHApplication.path}`}
    >
      {pathExaminationOGHApplication.name}
    </NavItem>
  ) : null;
};
