import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsEditCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsEditCurrentObjectIsExternalSystem';
import { CUSTOMER_HINT } from 'app/constants/messages';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useEditorCardSelector } from 'app/selectors/useSelectors/useEditorCard.Selector';
import getHint from 'app/utils/getHint';
import { IsDiffHeightMarkRF } from 'core/form/reduxForm/fields';
import { CustomerPlaceRF } from 'core/form/reduxForm/fields/prepared/selects/autocompleteAsync/CustomerPlace.RF';
import { OwnerIdPlaceRF } from 'core/form/reduxForm/fields/prepared/selects/autocompleteAsync/OwnerIdPlace.RF/OwnerIdPlace.RF';
import { DepartmentIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/DepartmentId.RF';
import { ImprovementCategoryIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/ImprovementCategoryId.RF';
import { ReCalcNameRF } from 'core/form/reduxForm/fields/prepared/textField/ReCalcName.RF';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { CreateType } from 'core/uiKit/preparedInputs/selects/simpleSelect/CreateType';
import { GrbsId } from 'core/uiKit/preparedInputs/selects/simpleSelect/GrbsId';
import { useGetOghType } from 'core/utils/hooks/useGetOghType';

import { AddressListField } from '../../components/AddressListField';
import { EquipmentIndex } from '../../components/EquipmentIndex';
import { ImprovementObjectCategoryIdFiledYard } from '../fileds.Yard/ImprovementObjectCategoryId.Filed.Yard';

const ids = {
  address: 're_calc_address_list',
  name: 're_calc_name',
};

/**
 * Свойства Дворовые Территории. Yard.
 *
 * @param {object} props - Parameters.
 * @returns {JSX.Element}
 */
export const PropertiesYardTab = (props) => {
  const card = useGetCurrentCard();

  const isEditCurrentObjectIsExternalSystem =
    useIsEditCurrentObjectIsExternalSystem();

  const { is_orphan_object: isOrphanObject } = useEditorCardSelector();
  const typeId = card.type_id;
  const oghType = useGetOghType(typeId);
  const { editMode } = useMode();

  return (
    <>
      <FieldsSplitColumns>
        <CreateType disabled={true} value={card.attribute?.create_type} />
        <GrbsId
          disabled={true}
          value={card.attribute?.grbs_id?.legal_person}
          label={'Учредитель/ГРБС'}
        />
      </FieldsSplitColumns>
      <DepartmentIdRF disabled={true} />
      <FieldsSplitColumns>
        <OwnerIdPlaceRF
          place={oghType.code}
          helpTooltip={getHint('owner_id')}
          disabled={
            !(
              !isOrphanObject &&
              editMode &&
              isEditCurrentObjectIsExternalSystem
            )
          }
        />
        <CustomerPlaceRF
          place={oghType.code}
          helpTooltip={CUSTOMER_HINT}
          disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
        />
        <ImprovementCategoryIdRF disabled={true} />
        <ImprovementObjectCategoryIdFiledYard disabled={true} />
        <ReCalcNameRF />
        <IsDiffHeightMarkRF
          label={'Разновысотный ОГХ'}
          disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
        />
        <EquipmentIndex />
      </FieldsSplitColumns>
      <AddressListField
        {...props}
        {...{
          editable: false,
          id: ids.address,
          name: ids.address,
        }}
      />
    </>
  );
};
