import { Dialog } from 'core/uiKit/components/dialogs/Dialog';
import React from 'react';

/**
 * SubmitDialogValidate.
 *
 * @returns {JSX.Element}
 */
export const SubmitDialogValidate = () => {
  return (
    <Dialog
      isOpen={false}
      textHeader={'Заполните слдедующие обязательные поля:'}
      Body={
        <>
          <h4>Свойства</h4>
          <ul>
            <li>
              <p>Обязательное поле 1</p>
            </li>
            <li>
              <p>Обязательное поле 2</p>
            </li>
          </ul>
        </>
      }
    />
  );
};
