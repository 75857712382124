/* eslint-disable */
import React from 'react';
import { NumberParam } from 'use-query-params';

import {
  ExplicationTypeFF,
  EXPLICATION_TYPE_NAME,
} from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';

 
export { EXPLICATION_TYPE_NAME };
 
 
/**
 * Вид экспликации земли
 * explicationType
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
 
export const ExplicationTypeFilter = (props) => {
  return <ExplicationTypeFF {...props} className={filterInput} />;
};

 
export const explicationTypeParams = {
  [EXPLICATION_TYPE_NAME]: NumberParam,
};
