import CardAbstract from 'app/components/card/common/CardAbstract';
import { elementFormGrid } from 'app/components/card/common/grid/index';
import { parseDate } from 'app/components/card/common/parse';
import { Tabs } from 'app/components/card/common/tabs';
import { validateNumeric } from 'app/components/card/common/validation';
import buildMandatoriesMessage from 'app/components/card/common/validation/buildMandatoriesMessage';
import DialogAddCarFile from 'app/components/dialogs/technicsRegistry/common/DialogAddCarFile';
import Files from 'app/domain/Files';
import { TECH } from 'app/domain/Files/Files';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import getDict from 'app/selectors/getDict';
import { transformValues } from 'app/utils/form/transformation';
import FileListField from 'core/components/FileListField';
import { find, get, includes, merge, omitBy, pick } from 'lodash';
import moment from 'moment';
/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm, SubmissionError } from 'redux-form';

import { validateMandatoryProperties } from '../../../utils/validation';
import PropsFields, {
  mapStateToPropsReducer as propsFieldsMapStateToPropsReducer,
} from './equipment/components/PropsFields';
import printTechnikCardHeader from './header/technikHeader';
import SensorList from './sensor/SensorList';
import submitCardTech from './submit';
import Versions from './versions';

const tabs = [
  {
    id: 'sensors-tab',
    name: 'Датчики',
  },
  {
    id: 'docs-tab',
    name: 'Документы',
  },
];

const tabsStyle = {
  paddingLeft: '24px',
};
class EquipmentCard extends React.Component {
  onChangeCarEquipmentTypeId = (id, notNeedDut, notNeedDrno) => {
    let not_need_drno = false;
    let not_need_dut = false;
    let capacity = null;
    const { changeFieldValue } = this.props;
    if (this.includesCode(withoutDrnoCodes, id)) {
      if (notNeedDrno) {
        not_need_dut = true;
        not_need_drno = false;
      } else {
        not_need_dut = false;
        not_need_drno = true;
      }
      changeFieldValue('not_need_drno', not_need_drno);
      changeFieldValue('not_need_dut', not_need_dut);
    }

    if (this.includesCode(withoutDutCodes, id)) {
      if (notNeedDut) {
        not_need_dut = false;
        not_need_drno = true;
      } else {
        not_need_dut = true;
        not_need_drno = false;
      }
      changeFieldValue('not_need_dut', not_need_dut);
      changeFieldValue('not_need_drno', not_need_drno);
    }

    if (!this.includesCode(withCapacityCodes, id)) {
      changeFieldValue('capacity', null);
      capacity = null;
    }

    return {
      not_need_drno: not_need_drno,
      not_need_dut: not_need_dut,
      capacity: capacity,
    };
  };

  modifyCarEquipmentOwnerId(car_equipment_owner_id) {
    const oldOptions = car_equipment_owner_id?.value
      ? car_equipment_owner_id
      : '';
    const { label, value } = oldOptions || {};
    return { id: value, name: label };
  }

  modifyDutAndDrno(card, editableNotNeedDrno, editableNotNeedDut) {
    if (card.id) {
      if (editableNotNeedDrno) {
        card.not_need_drno = false;
        card.not_need_dut = true;
      }

      if (editableNotNeedDut) {
        card.not_need_dut = false;
        card.not_need_drno = true;
      }

      if (card.car_equipment_owner_id.label !== undefined) {
        card.car_equipment_owner_id = this.modifyCarEquipmentOwnerId(
          card.car_equipment_owner_id,
        );
      }
    }

    return card;
  }

  includesCode(codes, id) {
    return this.filterEquipmentTypeIds(codes).includes(id);
  }

  filterEquipmentTypeIds(codes) {
    const { carEquipmentType } = this.props;
    const result = [];
    carEquipmentType.forEach(({ id, code }) => {
      if (codes.includes(code)) {
        result.push(id);
      }
    });
    return result;
  }

  renderTabs({ createMode }) {
    return (
      <div className="tabs" style={tabsStyle}>
        <ul>
          <li className="active" id="props-tab">
            Свойства
          </li>
          {createMode ? null : <li id="versions-tab">Версии</li>}
        </ul>
      </div>
    );
  }

  modifyField(card, field, value) {
    card[field] = value;
  }

  render() {
    const { props } = this;
    const {
      card,
      mode,
      currentValues,
      sensorStates,
      required,
      editMode,
      createMode,
    } = props;
    const currentEquipmentTypeId = get(currentValues, 'car_equipment_type_id');
    const typeId = props.card.type_id;
    const visibleCapacity = this.includesCode(
      withCapacityCodes,
      currentEquipmentTypeId,
    );
    const editableNotNeedDrno = this.includesCode(
      withoutDrnoCodes,
      currentEquipmentTypeId,
    );
    const editableNotNeedDut = this.includesCode(
      withoutDutCodes,
      currentEquipmentTypeId,
    );
    const notNeedDrno =
      !editableNotNeedDrno || get(currentValues, 'not_need_drno', true);
    const notNeedDut =
      !editableNotNeedDut || get(currentValues, 'not_need_dut', true);

    return (
      <CardAbstract>
        <div id="editor-card">
          {printTechnikCardHeader({
            card,
            mode,
          })}
          {card &&
            this.renderTabs({
              createMode,
            })}
          <div id="props">
            <PropsFields
              carEquipmentType={props.carEquipmentType}
              carOwnership={props.carOwnership}
              editable={editMode}
              editableNotNeedDrno={editableNotNeedDrno}
              editableNotNeedDut={editableNotNeedDut}
              notNeedDrno={notNeedDrno}
              notNeedDut={notNeedDut}
              required={required}
              typeId={typeId}
              visibleCapacity={visibleCapacity}
              onChangeCarEquipmentTypeId={this.onChangeCarEquipmentTypeId}
              modifyField={this.modifyField}
              card={this.modifyDutAndDrno(
                props.card,
                editableNotNeedDrno,
                editableNotNeedDut,
              )}
            />
            <div>
              <Tabs tabs={tabs} />
              <div id="sensors">
                <SensorList
                  list={this.props.initialValues.sensor_list}
                  mode={mode}
                  sensorStates={sensorStates}
                />
              </div>
              <div hidden={true} id="docs">
                {docs(this.props)}
              </div>
            </div>
          </div>
          <div hidden={true} id="versions">
            {createMode ? null : <Versions card={card} />}
          </div>
        </div>
      </CardAbstract>
    );
  }
}

const getCarOwnership = getDict('carOwnership');
const getTechnicFileTypes = getDict('technicFileTypes');
const getSensorStates = getDict('sensorStates');
const getCarEquipmentType = getDict('carEquipmentType');

function reducedMapStateToProps(state, props) {
  const result = [
    mapStateToPropsReducer,
    propsFieldsMapStateToPropsReducer,
  ].reduce((result, reducer) => reducer(result, state, props), {});
  return result;
}

function mapStateToPropsReducer(result, state, props) {
  return {
    enableReinitialize: true,

    initialValues: {
      id: props.card.id,
      uuid: props.card.uuid,
      startDate: props.card.start_date,
      endDate: props.card.end_date,
      name: props.card.name,
      sensor_list: props.card.sensor_list,
      car_equipment_owner_id: props.card.car_equipment_owner_id,
      file_list: {
        table: props.card.file_list ? props.card.file_list : null,
        showDialog: false,
      },
    },

    carOwnership: getCarOwnership(state),
    sensorStates: getSensorStates(state),
    carEquipmentType: getCarEquipmentType(state),
    technicFileTypes: getTechnicFileTypes(state),

    required: isRequired(state, props),
    editMode: isEditMode(state, props),
    createMode: isCreateMode(state, props),

    technic_id: props.card.id,
    technic_root_id: props.card.root_id,
    technic_update: props.card.update_date,
    currentValues:
      (state.form.editorCard && state.form.editorCard.values) || null,

    onSubmit: onSubmit({
      props,
      techTreePaths: state.technologyObject.techTreePaths,
      changeEquipmentForm: state.rrf.changeEquipment,
      carEquipmentType: getCarEquipmentType(state),
    }),

    validate,
  };
}

function validate(values) {
  return validateNumeric(values, numericFields);
}

const withoutDrnoCodes = ['2', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

const withoutDutCodes = ['1'];

const withCapacityCodes = ['1', '9', '10', '11', '12'];

const mandatoryFields = {
  capacity: [
    'car_equipment_ownership_id',
    'car_equipment_type_id',
    'car_equipment_owner_id',
    'capacity',
  ],
  noCapacity: [
    'car_equipment_ownership_id',
    'car_equipment_type_id',
    'car_equipment_owner_id',
  ],
};

const dictFields = [
  'sensor_kind_id',
  'sensor_type_id',
  'car_equipment_type_id',
];

const numericFields = [
  {
    name: 'capacity',
    type: 'decimal',
    positive: true,
    zero: false,
  },
];

const onSubmit =
  ({ props, techTreePaths, changeEquipmentForm, carEquipmentType }) =>
  (formValues) => {
    const alert = props.alert;
    const technic_id = props.card.id;
    const technic_root_id = props.card.root_id;
    const technic_update = props.card.update_date;
    let formValuesCopy = { ...formValues };
    const equipId = formValuesCopy.car_equipment_type_id;
    const equipCode = equipId
      ? find(carEquipmentType, (item) => item.id === equipId).code
      : '0';

    let errors = {};
    if (props.mode && props.mode.editMode) {
      errors = validateMandatoryProperties(
        formValuesCopy,
        withCapacityCodes.includes(equipCode)
          ? mandatoryFields.capacity
          : mandatoryFields.noCapacity,
      );
    }

    if (Object.keys(errors).length > 0) {
      if (alert) {
        alert(
          buildMandatoriesMessage(
            errors,
            withCapacityCodes.includes(equipCode)
              ? tabToFieldsCapacity
              : tabToFields,
            withCapacityCodes.includes(equipCode)
              ? mandatoryFieldNames.capacity
              : mandatoryFieldNames.noCapacity,
          ),
        );
      }
      throw new SubmissionError(errors);
    }

    if (
      formValuesCopy['file_list'] &&
      formValuesCopy['file_list'].table &&
      formValuesCopy['file_list'].table.length
    ) {
      const fileList = formValuesCopy['file_list'].table.map((item) => ({
        files: item.file_id,
      }));
      formValuesCopy['file_list'] = fileList;
    } else {
      const fileList = [{ files: null }];
      formValuesCopy['file_list'] = fileList;
    }

    formValuesCopy = omitBy(
      formValuesCopy,
      (itemValue) => itemValue === null || itemValue === '',
    );

    const isDate = (key) => {
      return includes(key.toLowerCase(), 'date');
    };

    Object.keys(formValuesCopy).forEach((key) => {
      if (isDate(key) && formValuesCopy[key]) {
        formValuesCopy[key] = parseDate(formValuesCopy[key]);
      }
    });

    const technicAttribute = transformValues(formValuesCopy, {
      dictFields,
      numericFields,
    }).attribute;

    technicAttribute.car_equipment_ownership_id = {
      car_ownership: technicAttribute.car_equipment_ownership_id,
    };

    technicAttribute.not_need_drno = !!technicAttribute.not_need_drno;
    technicAttribute.not_need_dut = !!technicAttribute.not_need_dut;

    let requestTypeCode = 'del_equipment';
    if (props.mode && props.mode.createMode) {
      requestTypeCode = 'add_equipment';
    } else if (props.mode && props.mode.editMode) {
      requestTypeCode = 'edit_equipment';
    }

    const {
      fileIds = [],
      dateTime: startDate,
      comment,
      reason,
    } = changeEquipmentForm;
    const planDate = moment(startDate).format('YYYY-MM-DD HH:mm:ss');

    const template = getTemplate();

    const sensor = {
      requestTypeCode,
      code: props.card.code,
      is_in_car_tree: props.card.is_in_car_tree,
      planDate,
      attribute: {
        technic_request_reason_id: { technic_request_reason: reason },
        technic_name: `${formValuesCopy.sensor_code}`,
        description: comment || '',
        file_list_id: fileIds.map((fileId) => ({ files: fileId })),
      },
      tech: {
        technic_type_code: props.card.code,
        uuid: formValuesCopy.uuid,
        technic_name: `${formValuesCopy.sensor_code}`,
        technic_attribute: pick(
          merge({ ...template }, technicAttribute),
          Object.keys(template),
        ),
        technic_tree: techTreePaths,
        technic_id: technic_id || null,
        technic_root_id: technic_root_id || null,
        technic_update: technic_update || null,
      },
    };
    submitCardTech(props, sensor);
  };

function mapDispatchToProps(dispatch) {
  return {
    changeFieldValue: (field, value) => {
      dispatch(change('editorCard', field, value));
    },
  };
}

function docs(props) {
  const { mode, card, technicFileTypes } = props;

  /* eslint-disable */
  const columns = [
    {
      key: 'file_name',
      screenName: 'Наименование файла',
    },
    {
      key: 'type_id',
      screenName: 'Тип файла',
      mapper: (value) => {
        const result = technicFileTypes.find((item) => item.id === value);
        return result ? result.name : null;
      },
    },
    {
      key: 'comment',
      screenName: 'Комментарий',
    },
    {
      key: 'create_date',
      screenName: 'Дата присоединения',
    },
  ];

  const docs = elementFormGrid(
    [
      {
        id: 'file_list',
        name: 'file_list',
        label: '',
        editable: (mode && mode.editMode) || false,
        style: {
          overflow: 'auto',
          width: 'calc(100% - 24px)',
        },
        formValue: true,
        component: FileListField,
        addition: {
          accept: Files.getAllowedMIMETypes(TECH),
          allowedFormatsMessage: Files.getAllowedFormatsMessage(TECH),
          columns,
          renderAddFileDialog: ({
            accept,
            allowedFormatsMessage,
            open,
            onCancel,
            onSubmit,
          }) => (
            <DialogAddCarFile
              accept={accept}
              allowedFormatsMessage={allowedFormatsMessage}
              showCondition={open}
              onCancel={onCancel}
              onSubmit={onSubmit}
            />
          ),
        },
      },
    ],
    1,
    '',
    card.type_id,
  );

  return docs;
}

const tabToFields = {
  Свойства: [
    'car_equipment_ownership_id',
    'car_equipment_type_id',
    'car_equipment_owner_id',
  ],
};

const tabToFieldsCapacity = {
  Свойства: [
    'car_equipment_ownership_id',
    'car_equipment_type_id',
    'car_equipment_owner_id',
    'capacity',
  ],
};

const mandatoryFieldNames = {
  capacity: {
    car_equipment_ownership_id: 'Тип принадлежности',
    car_equipment_type_id: 'Тип навесного оборудования',
    car_equipment_owner_id: 'Владелец',
    capacity: 'Вместимость, куб. м.',
  },
  noCapacity: {
    car_equipment_ownership_id: 'Тип принадлежности',
    car_equipment_type_id: 'Тип навесного оборудования',
    car_equipment_owner_id: 'Владелец',
  },
};

const getTemplate = () => ({
  car_equipment_owner_id: null,
  inventory_number: null,
  not_need_drno: null,
  not_need_dut: null,
  capacity: null,
  car_equipment_type_id: {
    car_equipment_type: null,
  },
  sensor_input: null,
  file_list: [
    {
      files: null,
    },
  ],
  sensor_calibration_table: [
    {
      dut_value: null,
      liter_value: null,
    },
  ],
  car_equipment_ownership_id: {
    car_ownership: null,
  },
});

 
export default connect(
  reducedMapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'editorCard' })(EquipmentCard));
