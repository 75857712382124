import { useGetCardModeSelector } from 'app/selectors/useSelectors';
import { RANDOM_ID } from 'core/forTesting/constants';
import { shallow } from 'enzyme';

import { EditRowRFCell } from './EditRow.RF.Cell';
import { renderEditRowCell } from './renderEditRow.Cell';

jest.mock('./renderEditRow.Cell');
jest.mock('app/selectors/useSelectors');

/* eslint-disable */
describe('file EditRowRFCell', () => {
  it('editMode props', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    renderEditRowCell.mockReturnValue((_) => _);
    useGetCardModeSelector.mockReturnValue({
      editMode: true,
    });
    // Act
    const wrapper = shallow(<EditRowRFCell />);
    wrapper.text();
    // Assert
    expect(wrapper.props()).toMatchObject({
      name: 'trafficCharacterList',
      component: expect.any(Function),
    });
  });

  it('editMode renderEditRowCell', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetCardModeSelector.mockReturnValue({
      editMode: true,
    });

    // Act
    shallow(<EditRowRFCell row={{ RANDOM_ID }} />);

    // Assert
    expect(renderEditRowCell).toHaveBeenCalledWith({
      RANDOM_ID,
    });
  });

  it('editMode false', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetCardModeSelector.mockReturnValue({
      editMode: false,
    });

    // Act
    const wrapper = shallow(<EditRowRFCell />);

    // Assert
    expect(wrapper.props()).toStrictEqual({});
    expect(renderEditRowCell).toHaveBeenCalledTimes(0);
  });
});
