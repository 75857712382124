import { useGetVisibilityButtonsOgh } from 'app/api/hooks/useGetVisibilityButtonsOgh';
/* eslint-disable  */
import { entityCode, operation } from 'app/constants/matching';
import { useFetchRegistryMatchingHistory } from 'app/pages/titleList/TitleList/api';
import { queryClient } from 'app/routes/QueryClientProvider';
import { ContinueFFDialog } from 'core/form/finalForm/components/dialogs/ContinueFF.Dialog';
import { getValidator } from 'core/form/finalForm/utils/validate';
import RejectObjectOghButton from 'core/uiKit/components/buttons/actionsButtons/RejectObjectOgh.Button';
import { TextFieldMUI } from 'core/uiKit/material-ui';
import { COMMENT_NAME } from 'core/uiKit/preparedInputs/textField/Comment';
import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

/**
 * Валидация формы.
 *
 * @param {object} values - Значения формы.
 * @returns {{}|null}
 */
export const formValidation = (values) => {
  const { setRequired, hasErrors, getErrors } = getValidator(values);
  setRequired(COMMENT_NAME);
  return hasErrors() ? getErrors() : null;
};

/**
 * Компонент RejectObject.
 *
 * @param {object} props - Пропсы.
 * @param {*} props.editMode - Any.
 * @param {*} props.typeId - Any.
 * @param {*} props.approveRequest - Any.
 * @returns {JSX.Element|null}
 */
const RejectObject = ({ editMode, typeId, approveRequest }) => {
  const rejected = operation.REJECTED;
  const [isOpen, setOpen] = useState(false);
  const { childId, id } = useParams();
  const objectId = parseInt(childId || id, 10);
  const oghCode = entityCode.OGH;
  const { data: { reject_object: rejectObject = false } = {} } =
    useGetVisibilityButtonsOgh(objectId);
  const show = !editMode && objectId && rejectObject;

  const { data: table = [], isLoading } = useFetchRegistryMatchingHistory(
    {
      id: id,
      type: oghCode,
      typeId: typeId,
    },
    { enabled: Boolean(show) },
  );

  const beforeLastEntry = table[table.length - 2];
  const beforeLastEntryComment = beforeLastEntry?.comment;

  /**
   * Функция handleClickOpen.
   *
   * @returns {void}
   */
  const handleClickOpen = () => {
    setOpen(true);
  };

  /**
   * Функция handleClose.
   *
   * @returns {void}
   */
  const handleClose = () => {
    setOpen(false);
    queryClient.invalidateQueries({ queryKey: ['getVisibilityButtonsOgh'] });
  };

  /**
   * Функция send.
   *
   * @param {object} values - Значения.
   * @returns {void}
   */
  const send = async (values) => {
    await approveRequest({
      ...{ entityCode: oghCode, id: objectId, operation: rejected },
      typeId,
      ...values,
    });
    handleClose();
  };

  return show ? (
    <>
      <RejectObjectOghButton objectId={objectId} onClick={handleClickOpen} />
      <ContinueFFDialog
        {...{
          Body: (
            <>
              <Field
                name={COMMENT_NAME}
                render={({ input }) => (
                  <TextFieldMUI
                    label={'Комментарий'}
                    multiline={true}
                    rowsMax={13}
                    maxLength={1000}
                    rows={4}
                    className={'mt-3'}
                    variant={'outlined'}
                    {...input}
                  />
                )}
              />
            </>
          ),
          closeDialog: handleClose,
          initialValues: { [COMMENT_NAME]: beforeLastEntryComment },
          isLoading,
          isOpen,
          onSubmit: send,
          textHeader: 'Отклонить добавление объекта',
          validate: formValidation,
        }}
      />
    </>
  ) : null;
};

/**
 * Функция mapStateToProps.
 *
 * @param {object} state - Стейт.
 * @returns {{editMode: *, typeId: *}}
 */
function mapStateToProps(state) {
  return {
    editMode: state.card?.mode?.editMode,
    typeId: state.card?.object?.type_id,
  };
}

/**
 * Функция mapDispatchToProps.
 *
 * @returns {{objectMatching: (function(*): string), approveRequest: (function(*): string)}}
 */
function mapDispatchToProps() {
  return {
    /**
     *
     */
    approveRequest: () => {
      return 'dispatch(approveRequest(data))';
    },

    /**
     *
     */
    objectMatching: () => {
      return 'dispatch(objectMatching(data))';
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RejectObject);
