import { getApiPath } from 'app/utils/api/config';
import _ from 'lodash';

import * as types from '../constants/actionTypes';

/* eslint-disable */
export function requestFeedBack() {
  return {
    type: types.REQUEST_FEED_BACK,
  };
}

/* eslint-disable */
export function receiveFeedBack(object) {
  return {
    type: types.RECEIVE_FEED_BACK,
    object,
  };
}

/* eslint-disable */
export function sendFeedBack(values) {
  return (dispatch) => {
    const data = new FormData();
    const params = { ...values };
    delete params.screens;
    _.keys(params).forEach((key) => data.append(key, params[key]));
    if (values.screens) {
      values.screens.forEach((item) => data.append('images', item));
    }
    dispatch(requestFeedBack());
    return fetch(`${getApiPath()}/email/push`, {
      credentials: 'include',
      method: 'POST',
      body: data,
    })
      .then((response) => response.json())
      .then((json) => dispatch(receiveFeedBack(json)));
  };
}
