/* eslint-disable  */
import React from 'react';

/**
 *
 * @param response
 * @param message
 */
export const createMessageResponse = (response, message = <div></div>) => {
  for (let key in response) {
    if (typeof response[key] === 'object') {
      message = createMessageResponse(response[key], message);
    } else {
      message = (
        <div>
          {message}
          <i>{key}</i>
          <i>:</i>
          {response[key]}
          <br />
        </div>
      );
    }
  }
  return message;
};
