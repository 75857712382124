// Обработка Enter для формы. Если внутри формы есть кнопка type='submit', то этот компонент не нужен, так как Enter работает в этом случае.

import { isEnterKeyPressed } from 'app/utils/keyCodes';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { actions as formActions, Form } from 'react-redux-form';

/* eslint-disable */
export class KeyForm extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    model: PropTypes.string,
    onSubmitClick: PropTypes.func,
  };

  render() {
    const { children, className, model, onSubmitClick, ...other } = this.props;

    return (
      <Form
        className={cn(this.props.className)}
        model={model}
        onKeyDown={function (event) {
          const {
            keyCode,
            // altKey, ctrlKey, metaKey, shiftKey
          } = event;
          if (isEnterKeyPressed(keyCode)) {
            event.preventDefault();
            onSubmitClick();
          }
        }}
        {...other}
      >
        {this.props.children}
      </Form>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onSubmitClick: function () {
      return dispatch(formActions.submit(ownProps.model));
    },
  };
}

 
export default connect(mapStateToProps, mapDispatchToProps)(KeyForm);
