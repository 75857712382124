import { useGetCardSelector } from 'app/selectors/useSelectors/useGetCard.Selector';
import { RANDOM_ID } from 'core/forTesting/constants';
import { shallow } from 'enzyme';

import { KeyboardDateTimeStartComponent } from './KeyboardDateTimeStartComponent';

jest.mock('app/selectors/useSelectors/useGetCard.Selector');

describe('KeyboardDateTimeStartComponent', function () {
  it('test props', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    useGetCardSelector.mockReturnValue({ id: RANDOM_ID });
    // Act

    const wrapper = shallow(<KeyboardDateTimeStartComponent />);

    // Assert
    expect(wrapper.props()).toStrictEqual({
      disabled: true,
      label: 'Действует с',
      name: 'startDate',
    });
  });
  it('test id undefined', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    useGetCardSelector.mockReturnValue({});
    // Act

    const wrapper = shallow(<KeyboardDateTimeStartComponent />);

    // Assert
    expect(wrapper.props()).toStrictEqual({
      disabled: true,
      label: 'Действует с',
      name: null,
    });
  });
});
