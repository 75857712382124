import { Table } from 'core/uiKit/components/tables/Table';
import React from 'react';

import { getColumnsBuilding } from './columns.Building';
import { useSetupTableBuilding } from './useSetup.Table.Building';

/**
 * Компонент таблицы __Объекты капитального строительства__.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - Id текущего ОГХ.
 * @param {number} props.parentTypeId - Id родительского ОГХ.
 * @returns {JSX.Element} - JSX.
 * @example
 * <TableBuilding typeId={typeId} parentTypeId={parentTypeId} />
 */
export const TableBuilding = ({ typeId, parentTypeId }) => {
  const { count, isLoading, table } = useSetupTableBuilding({
    parentTypeId,
    typeId,
  });

  return (
    <Table
      columns={getColumnsBuilding()}
      data={table || []}
      isLoading={isLoading}
      total={count}
    />
  );
};
