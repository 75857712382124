import { shallow } from 'enzyme';
import React from 'react';
import { vi } from 'vitest';

import { ImprovementObjectCategoryIdFieldOzn } from './ImprovementObjectCategoryId.Field.Ozn';
import { useSetupImprovementObjectCategoryIdFieldOzn } from './useSetup.ImprovementObjectCategoryId.Field.Ozn';

vi.mock('./useSetup.ImprovementObjectCategoryId.Field.Ozn');

describe('🐛 spec ImprovementObjectCategoryId.Field.Ozn', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    //☣️ Arrange (всякие моки)
    //
    useSetupImprovementObjectCategoryIdFieldOzn.mockReturnValue({});

    // 🧹 clear mock

    //🔥 Act
    const wrapper = shallow(<ImprovementObjectCategoryIdFieldOzn />);

    //❓ Assert
    expect(wrapper.text()).toBe('<ImprovementObjectCategoryIdRF />');
  });
});
