// 26 Места сбора отходов

import CardAbstract from 'app/components/card/common/CardAbstract';
import {
  elementFormGrid,
  renderAbutmentList,
  renderAddressList2,
  renderMafMsoList,
} from 'app/components/card/common/grid';
import { objectTabs } from 'app/components/card/common/tabs';
import { IsDiffHeightMarkField } from 'app/components/card/ogh/components/fields/checkBoxFields/IsDiffHeightMark.Field';
import { Characteristics } from 'app/components/card/ogh/Container/tabs/Characteristics';
import { NoCalcRF } from 'core/form/reduxForm/fields/prepared/checkboxes/NoCalc.RF';
import { OtherDocumentsRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/OtherDocuments.RF';
import { CardContainer } from 'core/uiKit/components/card.components/CardContainer';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { TabsCard } from 'core/uiKit/components/TabsCard';
import { TabsCardItem } from 'core/uiKit/components/TabsCard/TabsCardItem';
import { Checkbox } from 'core/uiKit/inputs';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import { get } from 'lodash';
import React from 'react';

import { ContainerTypeIdFieldContainer } from '../Container/fields.Container/ContainerTypeId.Filed.Container';
import printOghCardHeader from '../header/oghHeader';
import VersionsTemplate from '../versions/VersionsTemplate';

const types = ['container'];
const Versions = VersionsTemplate(types);

/**
 * Компонет ContainerCard..
 *
 * @param {object} props - Параметры.
 * @returns {JSX.Element}
 */
export const ContainerCard = (props) => {
  const {
    card,
    changeFieldValue,
    editMode,
    formValues,
    inYard,
    isPointGeometry,
    mode,
    required,
    isCurrentObjectIsExternalSystem,
    isEditCurrentObjectIsExternalSystem,
  } = props;

  const tabsEnd = [
    {
      label: 'Исходные данные',
      value: 'characteristics',
    },
    {
      label: 'Элемент сопряжения',
      show: !editMode,
      value: 'abutments',
    },
    {
      label: 'МАФ',
      value: 'mafs',
    },
    {
      label: 'Документы',
      value: 'documents',
    },
  ];

  const { type_id: typeId } = card;
  const msoType = props.containerTypes.find(
    (item) => item.id === formValues.container_type_id,
  );
  const mafListLengthIsZero = !get(formValues, 'maf_type_list.table[0]');
  return (
    <CardContainer>
      <CardAbstract>
        <div id="editor-card">
          {printOghCardHeader(card, mode)}
          {card && objectTabs(typeId)}
          <div id="props">
            <FieldsSplitColumns>
              <ContainerTypeIdFieldContainer />
              <ParentInfo />
              <IsDiffHeightMarkField />
            </FieldsSplitColumns>

            <FieldsSplitColumns>
              <NoCalcRF disabled={!editMode} />
              <Checkbox
                disabled={true}
                id="in_yard"
                label="Внутридворовое"
                name="inYard"
                value={inYard}
              />
            </FieldsSplitColumns>

            {elementFormGrid(
              [
                {
                  addition: {
                    address_required: true,
                    noWrapper: true,
                    onlyOne: true,
                  },
                  component: renderAddressList2,
                  editable: editMode && isEditCurrentObjectIsExternalSystem,
                  formValue: true,
                  id: 'address_list',
                  label: null,
                  name: 'address_list',
                },
              ],
              1,
              'Адрес БТИ',
              typeId,
              {
                required,
              },
            )}

            <TabsCard tabs={tabsEnd}>
              <TabsCardItem tabValue={'characteristics'}>
                <Characteristics
                  {...{
                    card,
                    changeFieldValue,
                    editMode,
                    isCurrentObjectIsExternalSystem,
                    isEditCurrentObjectIsExternalSystem,
                    isPointGeometry,
                    required,
                    typeId,
                  }}
                />
              </TabsCardItem>
              <TabsCardItem tabValue={'abutments'}>
                {elementFormGrid(
                  [
                    {
                      addition: {
                        abutmentTypes: props.abutmentTypes,
                      },
                      component: renderAbutmentList,
                      editable: false,
                      formValue: true,
                      id: 'abutment_type_list',
                      name: 'abutment_type_list',
                      style: {
                        overflow: 'auto',
                      },
                    },
                  ],
                  1,
                  '',
                  typeId,
                )}
              </TabsCardItem>
              <TabsCardItem tabValue={'mafs'}>
                {elementFormGrid(
                  [
                    {
                      addition: {
                        mafListLengthIsZero: mafListLengthIsZero,
                        mafTypeLevel1: props.mafTypeLevel1,
                        mafTypeLevel2: props.mafTypeLevel2,
                        msoType: msoType,
                      },
                      component: renderMafMsoList,
                      editable: editMode && isEditCurrentObjectIsExternalSystem,
                      formValue: true,
                      id: 'maf_type_list',
                      name: 'maf_type_list',
                    },
                  ],
                  1,
                  '',
                  typeId,
                )}
              </TabsCardItem>
              <TabsCardItem tabValue={'documents'}>
                <OtherDocumentsRF
                  disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
                />
              </TabsCardItem>
            </TabsCard>
          </div>
          <div hidden={true} id="versions">
            <Versions
              card={card}
              disabled={props.createMode}
              key={`versions-${card.root_id}`}
            />
          </div>
        </div>
      </CardAbstract>
    </CardContainer>
  );
};
