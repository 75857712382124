import { cellsMainMap } from 'core/uiKit/components/cells/cellsMainMap';

/**
 * @typedef {import('src/core/uiKit/components/tables/Table/Table').Columns} Columns
 */

/**
 * Получение Columns __Газоны__.
 *
 * @returns {Columns} - Жопа.
 * @example ---
 */
export const getColumnsLawn = () => [
  cellsMainMap.short_root_id, // ID
  cellsMainMap.lawn_type_name, // Тип газона
  cellsMainMap.section_num, // Номер участка
  cellsMainMap.object_status_name, // Статус
  cellsMainMap.sign_date, // Дата подписания
  cellsMainMap.sign_person, // ФИО подписанта
  cellsMainMap.start_date, // Дата начала
  cellsMainMap.end_date, // Дата окончания
  cellsMainMap.parent_object_name, // Наименование родительского объекта
  cellsMainMap.parent_short_root_id, // Идентификатор родительского объекта
  cellsMainMap.parent_okrug_name, // Округ родительского объекта
  cellsMainMap.parent_owner_name, // Балансодержатель родительского объекта
  cellsMainMap.parent_grbs_name, // ГРБС
  cellsMainMap.total_lawn_area, // Площадь, кв.м
  cellsMainMap.total_area_geo, // Площадь объекта по пространственным координатам, кв.м
  cellsMainMap.lawn_area_without, // Площадь за вычетом зеленых насаждений, кв.м
  cellsMainMap.state_gardening_name, // Состояние
  cellsMainMap.is_diff_height_mark, // Разновысотные отметки
  cellsMainMap.included_in_tl, // Входит в ТС
];
