import { FORMAT_DATE } from 'app/utils/date/format';
import { countBy, sumBy } from 'lodash';
import moment from 'moment';

/* eslint-disable  */
/**
 * Валидация значений формы.
 */
export class Validator {
  private errors: Record<string, string> = {};

  /**
   * Construct.
   *
   * @param values - The values to validate.
   */
  constructor(private values: Record<string, unknown>) {}

  /**
   * Int to string.
   *
   * @param value - The value.
   * @private
   * @returns - The string.
   */
  private int2str(value: string | number) {
    return typeof value === 'number' ? value.toString() : value;
  }

  /**
   * Set error.
   *
   * @param name - The name.
   * @param errorMessage - The error message.
   * @private
   * @returns {void}
   */
  private setError(name: string, errorMessage?: string): void {
    if (errorMessage) {
      this.errors[name] = errorMessage;
    }
  }

  /**
   * Проверка на обязательность полей.
   *
   * @param value - The value to validate.
   * @param message - The message.
   * @private
   * @returns - Сообщение об ошибке, если есть.
   */
  private required(
    value: unknown,
    message = 'обязательное поле',
  ): string | undefined {
    let v = typeof value === 'number' ? this.int2str(value) : value;
    v = typeof v === 'string' ? v.trim() : v;
    if (Array.isArray(value)) {
      v = !!value.length;
    }
    if (Array.isArray(value) && value[0] === '') {
      v = false;
    }
    return v ? undefined : message;
  }

  /**
   * Получаем ошибки валидации.
   *
   * @returns {Record<string, string> | null}
   * @example
   *  const validator =  new Validator(values);
   *  validator.getErrors()
   */
  getErrors(): Record<string, string> | null {
    return Object.keys(this.errors).length !== 0 ? this.errors : null;
  }

  /**
   * Установить поле как обязательным.
   *
   * @param field - Имя(name) поля для установки его как обязательным.
   * @param [message] - Кастомное сообщение для ошибки.
   * @returns {void}
   * @example
   *  const validator =  new Validator({});
   *  validator.setRequired('password);
   *  validator.getErrors(); // { password: 'обязательное поле',}
   */
  setRequired(field: string, message?: string): void {
    const value = this.values[field];
    this.setError(field, this.required(value, message));
  }

  /**
   * Валидация для файла или файлов.
   *
   * @param field - Имя поля файлового инпута.
   * @param [options] - Дополнительные опции валидации.
   * @param [options.maxMb] - Максимальный размер файла в мб.
   * @param [options.maxKb] - Максимальный размер файла в кб.
   * @param [options.maxGb] - Максимальное количество в гб.
   * @param [options.maxFiles] - Максимальное количество файлов.
   * @param [options.byExtensions] - Разрешенные расширения файлов.
   * @returns {void}
   */
  setFilesValidate(
    field: string,
    options?: {
      maxMb?: number;
      maxKb?: number;
      maxGb?: number;
      maxFiles?: number;
      byExtensions?: string[];
    },
  ): void {
    const {
      maxMb = 20,
      maxKb = null,
      maxGb = null,
      maxFiles = 2,
      byExtensions,
    } = options || {};
    const files = this.values[field];

    if (!files) {
      return;
    }

    // @ts-ignore
    if (files.length > maxFiles) {
      this.setError(
        field,
        `Максимальное количество файлов не должно превышать ${maxFiles}`,
      );
      return;
    }

    // todo переделать
    // @ts-ignore
    const totalSize = sumBy(files, 'size');
    // todo переделать
    // @ts-ignore
    const extensions = countBy(files, (f) => this.getFileExtension(f.name));

    if (maxGb && totalSize > maxGb * 1024 * 1024 * 1024) {
      this.setError(
        field,
        `Совокупный размер прикрепленных файлов не должен превышать ${maxGb} Гб`,
      );
      return;
    }

    if (maxMb && totalSize > maxMb * 1024 * 1024) {
      this.setError(
        field,
        `Совокупный размер прикрепленных файлов не должен превышать ${maxMb} Мб`,
      );
      return;
    }

    if (maxKb && totalSize > maxKb * 1024) {
      this.setError(
        field,
        `Совокупный размер прикрепленных файлов не должен превышать ${maxKb} Kб`,
      );
      return;
    }

    if (Array.isArray(byExtensions)) {
      const everyAllowed = Object.keys(extensions).every((ext) =>
        byExtensions.includes(ext),
      );

      if (!everyAllowed) {
        this.setError(
          field,
          `Некорректный тип файла. Допустимые форматы - ${byExtensions.join(
            ', ',
          )}`,
        );
      }
    }
  }

  /**
   * Получаем расширение файла.
   *
   * @param filename - Имя файла.
   * @returns {string} - Расширение файла.
   */
  private getFileExtension(filename: string): string {
    const parts = filename.split('.');
    return parts.length > 1 ? parts[parts.length - 1] : '';
  }

  /**
   * Добавить ошибку.
   *
   * @param field - Имя поля.
   * @param message - Сообщение ошибки.
   * @returns {void}
   */
  addError(field: string, message: string): void {
    this.setError(field, message);
  }

  /**
   * Проверяем на минимальную дату позже или раньше относительно текущей даты Включительно.
   *
   * @param field - Имя(name) поля для установки его как обязательным.
   * @param [days] - На сколько дней проводить сверку.
   * @param [message] - Кастомное сообщение для ошибки.
   * @returns {void}
   * @example
   *  const validator =  new Validator({});
   *  validator.setRequired('startDate', -1);
   *  validator.getErrors(); // { startDate: 'дата не может быть раньше _____ выберите другую дату',}
   */
  setCheckMinDateRelativeCurrentDate(
    field: string,
    days = 0,
    message?: string,
  ) {
    const value = this.values[field];

    // @ts-ignore
    const formDate = moment(value).startOf('day');

    const checkDate = moment().startOf('day').add(days, 'day');

    const messageError =
      message ??
      'Дата начала действия не может быть раньше ' +
        checkDate.format(FORMAT_DATE) +
        '. Укажите корректное значение';

    if (formDate.isBefore(checkDate)) {
      this.addError(field, messageError);
    }
  }
}
