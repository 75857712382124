import { shallow } from 'enzyme';
import { vi } from 'vitest';

import { DeleteOghButton } from './DeleteOghButton';
import { useDeleteOghButton } from './useDeleteOgh.Button';

vi.mock('./useDeleteOgh.Button');

describe('file DeleteOghButton', () => {
  it('simple text нет кнопки', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useDeleteOghButton.mockReturnValue({});
    // Act
    const wrapper = shallow(<DeleteOghButton />);
    // Assert
    expect(wrapper.text()).toBe('');
  });

  it('simple text есть кнопка', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useDeleteOghButton.mockReturnValue({ isShow: true });

    // Act
    const wrapper = shallow(<DeleteOghButton />);
    // Assert
    expect(wrapper.text()).toBe('<DeleteButton />');
  });

  it('props', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useDeleteOghButton.mockReturnValue({
      isShow: 'isShow',
      onDelete: 'onDelete',
    });

    // Act
    const wrapper = shallow(<DeleteOghButton />);
    // Assert
    expect(wrapper.props()).toStrictEqual({
      onClick: 'onDelete',
    });
  });
});
