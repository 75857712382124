import moment from 'moment/moment';

 
/**
 * Получение текущей даты в формате 'DD.MM.YYYY'.
 *
 * @returns {string} - Жопа.
 * @example -------
 * getNowDate() === 15.11.2022
 */
export const getNowDate = () => {
  const date = Date.now();
  return moment(date).format('DD.MM.YYYY');
};
