import { NumberField } from 'core/uiKit/inputs/NumberField';
import React from 'react';

export const FACE_AREA_NAME = 'face_area';

/**
 * NumberField __Площадь покрытия (облицовка), кв м__.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const FaceArea = (props) => {
  return (
    <NumberField
      label={'Площадь покрытия (облицовка), кв м.'}
      name={FACE_AREA_NAME}
      {...props}
    />
  );
};
