/* eslint-disable  */
import {
  ERZ_STATE_ID_NAME,
  ErzStateId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/ErzStateId/index';
import { Field } from 'redux-form';

/**
 *
 * @param root0
 * @param root0.input
 */
export const ErzStateIdRFWithReduxForm = ({ input, ...props }) => {
  return <ErzStateId {...input} {...props} />;
};

/* eslint-disable */
export const ErzStateIdRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={ERZ_STATE_ID_NAME}
      component={ErzStateIdRFWithReduxForm}
    />
  );
};
