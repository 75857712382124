/* eslint-disable no-unsafe-optional-chaining */
import { ENGINEER_BUILDING } from 'app/constants/oghTypes';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { CoatingFaceTypeIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/CoatingFaceTypeId.FF';
import { COATING_FACE_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingFaceTypeId';
import { UNIT_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/UnitId';
import React, { useEffect } from 'react';
import { useField } from 'react-final-form';

/**
 * Select __Вид покрытия (облицовка)__.
 *
 * @returns {JSX.Element}
 * @class
 */
export const CoatingFaceTypeIdFFField = () => {
  const { editMode } = useEditMode();
  const {
    input: { value: unitIdValue },
  } = useField(UNIT_ID_NAME);

  const {
    input: { onChange },
  } = useField(COATING_FACE_TYPE_ID_NAME);

  /**
   * Функция фильтрации.
   *
   * @param {Array} dict - Справочные значения.
   * @returns {Array}
   */
  const filter = (dict) => {
    return dict.filter((item) =>
      (item?.allowed_object_type_list).includes(ENGINEER_BUILDING),
    );
  };

  useEffect(() => {
    if (editMode && unitIdValue === 5) {
      onChange('');
    }

    // eslint-disable-next-line
  }, [unitIdValue, editMode]);

  return (
    <CoatingFaceTypeIdFF
      disabled={!(editMode && unitIdValue !== 5)}
      filter={filter}
      withAll={false}
    />
  );
};
