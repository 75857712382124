/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */

import { CheckboxCell } from 'core/uiKit/components/cells/Checkbox.Cell';
/**
 * Колонка __Разновысотные отметки__.
 *
 * @type {{
 * accessor: 'is_diff_height_mark',
 * Header: 'Разновысотные отметки',
 * sortable: true
 * }}
 * @augments Cell
 */
export const is_diff_height_mark = {
  Cell: CheckboxCell,
  Header: 'Разновысотные отметки',
  accessor: 'is_diff_height_mark',
  sortable: true,
};
