import { Table } from 'core/uiKit/components/tables/Table';

import { useList } from './api/useGetList';

 
/**
 * @typedef {import('src/app/components/card/ogh/ImprovementTerritoryCard/components/PlantationTab/tables/TreesShrubsList/columns')} ColumnsTreeShrubsList
 */

 
/**
 * Dis.
 *
 * @param {string} props - Хопа.
 * @param {string} props.code - Колонка.
 * @param {ColumnsTreeShrubsList} props.columns - Ds.
 * @returns {JSX.Element} - JSX.
 * @example ---
 */
export const TableList = ({ code, columns }) => {
  const { table, isLoadingTable, count } = useList(code);

  return (
    <Table
      columns={columns}
      data={table || []}
      isLoading={isLoadingTable}
      total={count}
    />
  );
};
