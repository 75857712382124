import { useGetFilesForSignature } from 'app/api/hooks/signs/useGetFilesForSignature';
import { CLOSE } from 'app/api/requests/signs/signOghSave';
import { RANDOM_ID } from 'core/forTesting/constants';
import { Dialog } from 'core/uiKit/components/dialogs/Dialog';
import { LoaderCreateFiles } from 'core/uiKit/components/loaders/LoaderCreateFiles';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import { DialogCloseElectronicSignature } from './Dialog.CloseElectronicSignature';
import { DialogSignatureFiles } from './Dialog.SignatureFiles';

vi.mock('core/utils/hooks/useGetObjectId');
vi.mock('app/api/hooks/signs/useGetFilesForSignature');
vi.mock('core/form/finalForm/utils/validate');
vi.mock('core/uiKit/components/dialogs/Dialog');
vi.mock('./Dialog.SignatureFiles');
vi.mock('core/uiKit/components/loaders/LoaderCreateFiles');

describe('file Dialog.DialogCloseElectronicSignature', () => {
  it('simple text', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    useGetFilesForSignature.mockReturnValue({
      data: [RANDOM_ID],
    });
    const onCloseSpy = vi.fn();

    // Act
    const wrapper = shallow(
      <DialogCloseElectronicSignature onClose={onCloseSpy} />,
    );

    // Assert
    expect(wrapper.text()).toBe('<Dialog /><DialogSignatureFiles />');
  });

  it('useGetFilesForSignature was called with', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    useGetFilesForSignature.mockReturnValue({
      data: [RANDOM_ID],
    });
    const onCloseSpy = vi.fn();

    // Act
    shallow(<DialogCloseElectronicSignature onClose={onCloseSpy} />);

    // Assert
    expect(useGetFilesForSignature).toHaveBeenCalledWith(RANDOM_ID, CLOSE, {
      onErrorCallback: onCloseSpy,
    });
  });

  it('useGetObjectId was called', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    useGetFilesForSignature.mockReturnValue({
      data: [RANDOM_ID],
    });
    const onCloseSpy = vi.fn();

    // Act
    shallow(<DialogCloseElectronicSignature onClose={onCloseSpy} />);

    // Assert
    expect(useGetFilesForSignature).toHaveBeenCalledTimes(1);
  });

  it('props Dialog isOpen', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    useGetFilesForSignature.mockReturnValue({
      data: [RANDOM_ID],
    });
    const onCloseSpy = vi.fn();

    // Act
    const wrapper = shallow(
      <DialogCloseElectronicSignature onClose={onCloseSpy} />,
    );

    // Assert
    expect(wrapper.find(Dialog).props()).toStrictEqual({
      Body: <LoaderCreateFiles />,
      isOpen: false,
      maxWidth: 'xs',
      textHeader: 'Формируются файлы для подписания',
    });
  });

  it('props Dialog isOpen true', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    useGetFilesForSignature.mockReturnValue({ data: [], isLoading: true });
    const onCloseSpy = vi.fn();

    // Act
    const wrapper = shallow(
      <DialogCloseElectronicSignature onClose={onCloseSpy} />,
    );

    // Assert
    expect(wrapper.find(Dialog).props()).toStrictEqual({
      Body: <LoaderCreateFiles />,
      isOpen: true,
      maxWidth: 'xs',
      textHeader: 'Формируются файлы для подписания',
    });
  });

  it('props DialogSignatureFiles isOpen true', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    useGetFilesForSignature.mockReturnValue({
      data: [RANDOM_ID],
    });
    const onCloseSpy = vi.fn();

    // Act
    const wrapper = shallow(
      <DialogCloseElectronicSignature onClose={onCloseSpy} />,
    );

    // Assert
    expect(wrapper.find(DialogSignatureFiles).props()).toStrictEqual({
      isOpen: true,
      onClose: onCloseSpy,
    });
  });

  it('props DialogSignatureFiles isOpen false', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    useGetFilesForSignature.mockReturnValue({});
    const onCloseSpy = vi.fn();

    // Act
    const wrapper = shallow(
      <DialogCloseElectronicSignature onClose={onCloseSpy} />,
    );

    // Assert
    expect(wrapper.find(DialogSignatureFiles).props()).toStrictEqual({
      isOpen: false,
      onClose: onCloseSpy,
    });
  });
});
