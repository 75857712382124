/* eslint-disable */
import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import {
  CoatingTypeRoadId,
  COATING_TYPE_ROAD_ID_NAME,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingTypeRoadId';

const CoatingTypeRoadIdRFWithReduxForm = ({ input, ...props }) => {
  return <CoatingTypeRoadId {...input} {...props} />;
};

/* eslint-disable */
export const CoatingTypeRoadIdRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={COATING_TYPE_ROAD_ID_NAME}
      component={CoatingTypeRoadIdRFWithReduxForm}
    />
  );
};

CoatingTypeRoadIdRF.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
};
CoatingTypeRoadIdRF.defaultProps = {
  disabled: false,
};
