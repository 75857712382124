const arr = [36, 38, 44, 45, 47];

/**
 * Функция определения обязательности поля __Документ-основание закрытия паспорта ОГХ__.
 *
 * @param {Array<number>} valueSendReason - Значение поля SendReason.
 * @returns {boolean}
 */
export const getRequiredDocumentCloseField = (
  valueSendReason: number[] = [],
): boolean => {
  return valueSendReason.some((num) => arr.includes(num));
};
