import { getValidator } from 'core/form/finalForm/utils/validate';
/* eslint-disable */

const requiredFields = [];

/**
 *
 * @param firstValues
 */
const validate = (firstValues) => (otherValues) => {
  const values = { ...firstValues, ...otherValues };

  const { hasErrors, setRequired, getErrors, setError } = getValidator(values);
  // обязательные поля
  requiredFields.map((item) => setRequired(item));

  // параметр «Местоположение створа по основной оси (в точке максимальной загрузки), м» должен быть не больше параметра «Протяженность объекта по всем осям, п.м»;
  const { layoutLength, odh_placement_axis: odhPlacementAxis } = values;
  if (+odhPlacementAxis > +layoutLength) {
    setError(
      'odh_placement_axis',
      '«Местоположение створа по основной оси (в точке максимальной загрузки), м» не должно быть больше значения «Протяженность объекта по всем осям, п.м»',
    );
  }

  // сумма процентов фактического состава движения должна  быть равна 100,00%, либо 0,00 %
  const actualCompositionOfMovementFields = [
    'fsd_la',
    'fsd_ga_befor_2',
    'fsd_ga_2_6',
    'fsd_ga_6_8',
    'fsd_ga_8_14',
    'fsd_ga_after_14',
    'fsd_road_trainsga_before_12',
    'fsd_road_trainsga_12_20',
    'fsd_road_trainsga_20_30',
    'fsd_road_trainsga_after_30',
    'fsd_ot_low_capacity',
    'fsd_ot_medium_capacity',
    'fsd_ot_large_capacity',
    'fsd_ot_articulated_buses_trolleybuses',
  ];
  const sum =
    +actualCompositionOfMovementFields
      .reduce((acc, curr) => acc + +values[curr], 0)
      .toFixed(2) || 0;

  if (sum !== 0 && sum !== 100) {
    actualCompositionOfMovementFields.forEach((item) =>
      setError(
        item,
        'Сумма процентов показателей фактического состава движения должна быть равна 0,00% либо 100,00%.',
      ),
    );
  }

  return hasErrors() ? getErrors() : null;
};

export { requiredFields, validate };
