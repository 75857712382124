 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Уточнение типа__.
 *
 * @type {{
 * accessor: 'bio_group_num',
 * Header: 'Номер био',
 * sortable: true,
 * }}
 * @augments Cell
 */
export const bio_group_num = {
  accessor: 'bio_group_num',
  Header: 'Номер биогруппы',
  sortable: true,
};
