import { PlanarStructureTypeRefId } from 'core/uiKit/preparedInputs/selects/simpleSelect/PlanarStructureTypeRefId';

/**
 * Компонент обертка для поля PlanarStructureTypeRefId.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.input - Данные поля приходит из final form.
 * @param {object} props.otherProps - Остальные пропсы.
 * @returns {JSX.Element} - JSX.
 * @example
 * <Field
 *       name={PLANAR_STRUCTURE_TYPE_REF_NAME}
 *       component={PlanarStructureTypeRefIdWithFF}
 *       {...otherProps}
 *     />
 */
export const PlanarStructureTypeRefIdWithFF = ({ input, ...otherProps }) => {
  return <PlanarStructureTypeRefId {...input} {...otherProps} />;
};
