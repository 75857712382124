import { RED_BOOK_PLANT_ID_NAME } from 'core/form/finalForm/fields/prepared/selects/simple/RedBookPlantId.FF';
import { usePreparation } from 'core/utils/hooks/usePreparation';

/**
 * Use for RedBookPlantId Filter.
 *
 * @returns {object}
 */
export const usePreparationRedBookPlantIdFilter = () =>
  usePreparation(RED_BOOK_PLANT_ID_NAME, (value) => value, 'sub_type_id');
