import {
  BASE_PGM,
  CONTAINER,
  IMPROVEMENT_OBJECT,
  MAF_RFID,
  ODH,
  OOPT,
  OZN,
  YARD,
} from 'app/constants/oghTypes';
import pgmLogo from 'assets/images/mainPage/Базы-хранения-ПГМ-white.png';
import dtLogo from 'assets/images/mainPage/Дворовые-территории-white.png';
import mnoLogo from 'assets/images/mainPage/Места-сбора-отходов-white.png';
import mafLogo from 'assets/images/mainPage/Объекты-благоустройства-white.png';
import odhLogo from 'assets/images/mainPage/Объекты-дорожного-хозяйства-white.png';
import ooLogo from 'assets/images/mainPage/Объекты-озеленения-white.png';
import ooptLogo from 'assets/images/mainPage/ООПТ-white.png';

export const mapTypesOdh = {
  [BASE_PGM]: {
    code: 'base_pgm',
    count: 0,
    id: BASE_PGM,
    src: pgmLogo,
    title: 'БХПГР',
  },
  [CONTAINER]: {
    code: 'container',
    count: 0,
    id: CONTAINER,
    src: mnoLogo,
    title: 'МНО',
  },
  [IMPROVEMENT_OBJECT]: {
    code: 'improvement_object',
    count: 0,
    id: IMPROVEMENT_OBJECT,
    src: mafLogo,
    title: 'ИОБ',
  },
  [MAF_RFID]: {
    code: 'maf_rfid',
    count: 0,
    id: MAF_RFID,
    src: mafLogo,
    title: 'МАФ',
  },
  [ODH]: {
    code: 'odh',
    count: 2,
    id: ODH,
    src: odhLogo,
    title: 'ОДХ',
  },
  [OOPT]: {
    code: 'spa',
    count: 0,
    id: OOPT,
    src: ooptLogo,
    title: 'ООПТ',
  },
  [OZN]: {
    code: 'ozn',
    count: 0,
    id: OZN,
    src: ooLogo,
    title: 'ОО',
  },
  [YARD]: {
    code: 'yard',
    count: 0,
    id: YARD,
    src: dtLogo,
    title: 'ДТ',
  },
};
