/* eslint-disable  */

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as serverInfoActions from 'app/actions/serverInfoActions';
import { ToastProvider } from 'core/uiKit/components/Toast';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from 'react-router-dom';

import { QueryClientProvider } from './QueryClientProvider';
import { QueryParamProviderODS } from './QueryParamProvider.ODS';
import { redirects } from './redirects';
import routes from './routes';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      {redirects.map((redirect, index) => (
        <Route
          path={redirect.from}
          key={index + '_redirects'}
          element={
            <QueryParamProviderODS>
              <Navigate {...redirect} replace />
            </QueryParamProviderODS>
          }
        />
      ))}
      {routes.map((route, i) => (
        <Route
          key={i + route.path}
          path={route.path}
          element={
            <QueryParamProviderODS>
              <route.component routes={route.routes} />
            </QueryParamProviderODS>
          }
        />
      ))}
    </Route>,
  ),
);

/**
 * Компонент роутингов с подключением провайдеров.
 *
 * @param {Function} getServerInfo - Информация сервера.
 * @returns {JSX.Element}
 */
const AppRoutesComp = ({ getServerInfo = (_) => _ }) => {
  useEffect(() => {
    getServerInfo();
  }, [getServerInfo]);
  return (
    <QueryClientProvider>
      <RouterProvider router={router} />
      <ToastProvider />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

AppRoutesComp.propTypes = {
  getServerInfo: PropTypes.func.isRequired,
};

/**
 * Функция mapDispatchToProps.
 *
 * @param {Function} dispatch - Функция dispatch.
 * @returns {*}
 */
function mapDispatchToProps(dispatch) {
  return {
    /**
     * Метод getServerInfo.
     *
     * @returns {Function}
     */
    getServerInfo() {
      return dispatch(serverInfoActions.getServerInfo());
    },
  };
}

export const AppRoutes = connect(null, mapDispatchToProps)(AppRoutesComp);
