import { useFetchOghDispatch } from 'app/actions/odsObjectActions/useFetchOgh.Dispatch';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { queryClient } from 'app/routes/QueryClientProvider';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import { useState } from 'react';

import { AcceptExternalObjectOghButton } from './AcceptExternalObjectOgh.Button';
import { DialogAccept } from './Dialog.Accept';

/**
 * AcceptExternalObject.
 *
 * @returns {JSX.Element}
 */
export const AcceptExternalObject = () => {
  const { objectId } = useGetObjectId();
  const [isOpen, setOpen] = useState(false);
  const { editMode } = useEditMode();
  const fetchOgh = useFetchOghDispatch(objectId);

  /**
   * AcceptExternalObject.
   *
   * @returns {void}
   */
  const handleClickOpen = () => {
    setOpen(true);
  };

  /**
   * AcceptExternalObject.
   *
   * @returns {void}
   */
  const handleClose = () => {
    fetchOgh(objectId);
    setOpen(false);
    queryClient.invalidateQueries({ queryKey: ['getVisibilityButtonsOgh'] });
  };

  const show = !editMode && objectId;

  return show ? (
    <>
      <AcceptExternalObjectOghButton
        objectId={objectId}
        onClick={handleClickOpen}
      />
      <DialogAccept isOpen={isOpen} onClose={handleClose} />
    </>
  ) : null;
};
