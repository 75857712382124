import { Button } from 'core/uiKit/components/buttons/Button';
import { Icon } from 'core/uiKit/material-ui';

/**
 * Компонент BlockButton.
 *
 * @param {object} props - Пропсы.
 * @param {string} props.text - Лейбл.
 * @returns {JSX.Element}
 */
export const BlockButton = ({ text = 'Заблокировать', ...props }) => {
  return (
    <Button
      {...props}
      color="error"
      variant={'contained'}
      startIcon={<Icon>not_interested</Icon>}
    >
      {text}
    </Button>
  );
};
