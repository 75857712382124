import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { useGetTreeMainPage } from '../hooks/useGetTreeMainPage';
import { useGetChildrenTreeMutation } from './useGetChildrenTreeMutation';

/**
 * Setup TreeMainPage.
 *
 * @returns {object}
 */
export const useSetupTreeMainPage = () => {
  const { data, fetchNextPage } = useGetTreeMainPage();
  const { ref, inView } = useInView({ triggerOnce: true });
  const {
    fetchTreeChildren,
    hash,
    isLoading: isLoadingHash,
  } = useGetChildrenTreeMutation();

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  const treeData =
    data?.pages.reduce((arr, page) => {
      (page?.data || []).forEach((item) => {
        arr.push(item);
      });
      return arr;
    }, []) || [];

  return {
    fetchTreeChildren,
    hash,
    isLoadingHash,
    ref,
    treeData,
  };
};
