import { useFieldRF } from 'core/form/reduxForm/hooks/useField.RF';
import { TableSimple } from 'core/uiKit/components/tables/TableSimple';
import React from 'react';

import { getColumnsTableRepairsTab } from './getColumns.Table.Repairs.Tab';

export const REPAIRS_INFO_LIST_NAME = 'repairs_info_list';

/**
 * Tаблица Ремонты.
 *
 * @returns Tаблица.
 */
export const TableRepairsTab = () => {
  const { value: repairsInfoList } = useFieldRF(REPAIRS_INFO_LIST_NAME);

  return (
    <div className="mt-2">
      <TableSimple
        columns={getColumnsTableRepairsTab()}
        data={repairsInfoList || []}
      />
    </div>
  );
};
