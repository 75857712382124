import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const OZN_TYPE_ID_NAME = 'ozn_type_id';

/**
 * ## Тип объекта.
 *
 * `type = Select`.
 *
 * `name = oznType`
 * ` dict = 'ozn_type'`.
 *
 * `name = OZN_TYPE_ID_NAME`.
 *
 * ```js
 * import { District, DISTRICT_NAME } from 'core/uiKit/preparedInputs';
 * ```.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - JSX.
 * @example
 * <OznTypeID {...props}/>
 */
export const OznTypeID = (props) => {
  const dict = 'oznType';

  const { data = [] } = useGetDictionary(dict);

  return (
    <Select
      {...props}
      options={data}
      label={'Тип объекта'}
      name={OZN_TYPE_ID_NAME}
    />
  );
};
