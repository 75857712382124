import './FormCard.FF.scss';

/* eslint-disable  */
import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useMapContext } from 'app/components/map/useMapContext';
import { checkGeometry } from 'app/pages/cardsOgh/helpers/checkGeometry';
import cn from 'classnames';
import { preparationValues } from 'core/utils/helpers/preparationValues';
import { useMemo, useState } from 'react';

import { useSaveCard } from '../../hooks/useSaveCard';
import { useDialogs } from '../DIalogs/useDialogs';

interface UseSetupFormCardFFProps<INITIAL_VALUES = Record<string, unknown>> {
  /**
   * Функция валидации.
   */
  validate: (values: INITIAL_VALUES) => Record<string, string> | void;

  /**
   * Функция подготовка данных для Атрибутов.
   */
  preparingDataForAttributes: (
    values: INITIAL_VALUES,
  ) => Record<string, unknown>;
}

/**
 * Функция формы для карточек ОГХ с проверкой пересечений.
 *
 * @param props - Properties.
 * @param props.validate - Функция валидации.
 * @param props.preparingDataForAttributes - Функция подготовка данных для Атрибутов.
 * @returns JSX.Element.
 */
export const useSetupFormCardFF = ({
  validate,
  preparingDataForAttributes,
}: UseSetupFormCardFFProps) => {
  const [activeLeftPanel, setActiveLeftPanel] = useState(true);

  const classLeftPanelContainer = useMemo(() => {
    return cn('left-card-panel-new__container', {
      'left-card-panel-new__container__hide': !activeLeftPanel,
    });
  }, [activeLeftPanel]);

  /**
   *
   */
  const toggleLeftPanel = () => {
    setActiveLeftPanel((pre) => !pre);
  };

  const { getGeometry } = useMapContext();
  // @ts-ignore
  const { showGeometryWarningDialog } = useDialogs();
  const card = useGetCurrentCard();
  const parent = useGetParentCard();

  const { saveCard } = useSaveCard();

  /**
   *
   * @param values
   */
  const submitInner = (values: Record<string, unknown>) => {
    const errors = validate(values);
    if (errors) {
      return errors;
    }

    const geometry = getGeometry(Number(card.recordId));
    if (checkGeometry(geometry, parent.typeId)) {
      showGeometryWarningDialog();
      return;
    }

    const valuesPreparation = preparationValues(values);
    const attribute = preparingDataForAttributes(valuesPreparation);

    return saveCard({ attribute, values: valuesPreparation });
  };

  return {
    classLeftPanelContainer,
    isLoading: card.isLoading,
    submitInner,
    toggleLeftPanel,
  };
};
