/* eslint-disable */
import React from 'react';

import { filterInput } from '../filterInputsClasses';
import { BooleanParam } from 'use-query-params';
import { CheckboxFF } from 'core/form/finalForm/fields';

 
export const NOT_NEED_BNSO_NAME = 'notNeedGps';

 
/* eslint-disable */
/**
 * Не подлежит оснащению notNeedBnso
 * @returns {JSX.Element}
 * @constructor
 */
 
export const NotNeedBnsoFilter = (props) => (
  <CheckboxFF
    {...props}
    className={filterInput}
    label={'Не подлежит оснащению БНСО'}
    name={NOT_NEED_BNSO_NAME}
  />
);

 
export const notNeedBnsoParams = {
  [NOT_NEED_BNSO_NAME]: BooleanParam,
};
