/* eslint-disable */
import React from 'react';
import _ from 'lodash';

/* eslint-disable */
export default function buildMandatoriesMessage(
  errors,
  tabToFields,
  mandatoryFieldNames,
  title = 'Заполните следующие обязательные поля:',
) {
  function buildList(errors) {
    const result = [];
    _.keys(tabToFields).forEach((tabKey) => {
      const fields = tabToFields[tabKey].filter(
        (x) =>
          x in errors ||
          (errors.clean_category_ids &&
            errors.clean_category_ids[x] !== undefined),
      );
      if (fields.length > 0) {
        result.push(<div style={{ marginTop: 8 }}>[{tabKey}]</div>);
        fields.forEach((f) => {
          result.push(<li>{mandatoryFieldNames[f] || errors[f]}</li>);
        });
      }
    });
    return result;
  }

  return (
    <div>
      <div style={{ marginBottom: 8 }}>{title}</div>
      <div className="nestedDialogForm" style={{ fontSize: 16 }}>
        {buildList(errors)}
      </div>
    </div>
  );
}
