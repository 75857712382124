import { formatDict } from './formatDict';

 
describe('file formatDict', () => {
  const data = [
    {
      id: 32,
      name: 'Велосипедная дорожка',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 38,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 40,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 49,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 39,
          },
          other_improvement_object_type_list: [
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 1,
              },
            },
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 2,
              },
            },
          ],
        },
      ],
    },
    {
      id: 42,
      name: 'Искусственная дорожная неровность',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 38,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 40,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 49,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 39,
          },
          other_improvement_object_type_list: [
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 1,
              },
            },
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 2,
              },
            },
          ],
        },
      ],
    },
    {
      id: 101,
      name: 'Мост пешеходный',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 49,
          },
        },
      ],
    },
    {
      id: 27,
      name: 'Пешеходная дорожка',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 38,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 40,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 49,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 39,
          },
          other_improvement_object_type_list: [
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 1,
              },
            },
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 2,
              },
            },
          ],
        },
      ],
    },
    {
      id: 25,
      name: 'Проезд',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 38,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 40,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 49,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 39,
          },
          other_improvement_object_type_list: [
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 1,
              },
            },
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 2,
              },
            },
          ],
        },
      ],
    },
    {
      id: 43,
      name: 'Технический (технологический) тротуар',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 38,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 40,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 49,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 39,
          },
          other_improvement_object_type_list: [
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 1,
              },
            },
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 2,
              },
            },
          ],
        },
      ],
    },
    {
      id: 26,
      name: 'Тротуар',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 38,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 40,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 49,
          },
        },
        {
          ogh_object_type_id: {
            ogh_object_type: 39,
          },
          other_improvement_object_type_list: [
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 1,
              },
            },
            {
              other_improvement_object_type_id: {
                other_improvement_object_type: 2,
              },
            },
          ],
        },
      ],
    },
    {
      id: 100,
      name: 'Экологические тропы',
      parent_ogh_object_type_list: [
        {
          ogh_object_type_id: {
            ogh_object_type: 49,
          },
        },
      ],
    },
  ];
  it('положительное выполнение', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const res = formatDict(data);
    // Assert
    expect(res).toStrictEqual([
      {
        id: 32,
        name: 'Велосипедная дорожка',
        parent_ogh_object_type_list: [
          {
            ogh_object_type_id: 38,
          },
          {
            ogh_object_type_id: 40,
          },
          {
            ogh_object_type_id: 49,
          },
          {
            ogh_object_type_id: 39,

            other_improvement_object_type_list: [1, 2],
          },
        ],
      },
      {
        id: 42,
        name: 'Искусственная дорожная неровность',
        parent_ogh_object_type_list: [
          {
            ogh_object_type_id: 38,
          },
          {
            ogh_object_type_id: 40,
          },
          {
            ogh_object_type_id: 49,
          },
          {
            ogh_object_type_id: 39,

            other_improvement_object_type_list: [1, 2],
          },
        ],
      },
      {
        id: 101,
        name: 'Мост пешеходный',
        parent_ogh_object_type_list: [
          {
            ogh_object_type_id: 49,
          },
        ],
      },
      {
        id: 27,
        name: 'Пешеходная дорожка',
        parent_ogh_object_type_list: [
          {
            ogh_object_type_id: 38,
          },
          {
            ogh_object_type_id: 40,
          },
          {
            ogh_object_type_id: 49,
          },
          {
            ogh_object_type_id: 39,

            other_improvement_object_type_list: [1, 2],
          },
        ],
      },
      {
        id: 25,
        name: 'Проезд',
        parent_ogh_object_type_list: [
          {
            ogh_object_type_id: 38,
          },
          {
            ogh_object_type_id: 40,
          },
          {
            ogh_object_type_id: 49,
          },
          {
            ogh_object_type_id: 39,
            other_improvement_object_type_list: [1, 2],
          },
        ],
      },
      {
        id: 43,
        name: 'Технический (технологический) тротуар',
        parent_ogh_object_type_list: [
          {
            ogh_object_type_id: 38,
          },
          {
            ogh_object_type_id: 40,
          },
          {
            ogh_object_type_id: 49,
          },
          {
            ogh_object_type_id: 39,

            other_improvement_object_type_list: [1, 2],
          },
        ],
      },
      {
        id: 26,
        name: 'Тротуар',
        parent_ogh_object_type_list: [
          {
            ogh_object_type_id: 38,
          },
          {
            ogh_object_type_id: 40,
          },
          {
            ogh_object_type_id: 49,
          },
          {
            ogh_object_type_id: 39,

            other_improvement_object_type_list: [1, 2],
          },
        ],
      },
      {
        id: 100,
        name: 'Экологические тропы',
        parent_ogh_object_type_list: [
          {
            ogh_object_type_id: 49,
          },
        ],
      },
    ]);
  });
});
