import { useGetVisibilityButtonsOgh as useGetVisibilityButtonsOghD } from './useGetVisibilityButtonsOgh';
 
export * from './useGetRoles';

 
 
/**
 * @deprecated
 * @see src/app/api/hooks/useGetVisibilityButtonsOgh.js:12
 * @type {(function(*): *)}
 */
 
export const useGetVisibilityButtonsOgh = useGetVisibilityButtonsOghD;
