import getFormValues from 'app/selectors/form/getFormValues';
/* eslint-disable  */
import getDict from 'app/selectors/getDict';
import { createSelector } from 'reselect';

/**
 *
 * @param state
 */
const getContainerTypeId = (state) => getFormValues(state).container_type_id;

export default createSelector(
  [getContainerTypeId, getDict('containerTypes')],
  (typeId, containerTypes) => {
    const currentContainerType = containerTypes.find(
      (type) => type.id === typeId,
    );
    return (
      !!currentContainerType &&
      currentContainerType.has_separate_garbage_collection
    );
  },
);
