import { post } from 'app/api/crud';
/* eslint-disable */
import { BASE_PGM } from 'app/constants/oghTypes';
import { toast } from 'core/uiKit/components';
import { GOV_NUMBER_NAME } from 'core/uiKit/preparedInputs';
import { preparationValues } from 'core/utils/helpers/preparationValues';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import {
  addPreparation,
  removePreparation,
} from 'core/utils/hooks/usePreparation/preparation';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import moment from 'moment';
import { useEffect } from 'react';

/**
 * Custom hook for sending data with specific operations.
 *
 * @param {string} operationKind - The type of operation.
 * @param {Function} onClose - Callback function to handle closing.
 * @returns {object} Object containing isLoading flag and onSubmit function.
 */
export const useSend = (operationKind, onClose) => {
  const { objectId } = useGetObjectId();

  useEffect(() => {
    addPreparation('targetDateTime', (value) => {
      let newValue = value;
      if (typeof value === 'object' && moment(value).isValid()) {
        newValue = moment(value).format('DD.MM.YYYY HH:mm');
      }
      return newValue;
    });
    addPreparation(GOV_NUMBER_NAME, (value) => value.id, 'techObjectId');
    return () => {
      removePreparation('govNumber');
      removePreparation(GOV_NUMBER_NAME);
    };
  });

  const [onSubmit, { isLoading }] = useMutationAdaptor(
    (data) =>
      sendPost({
        ...data,
        basePgmRootId: objectId,
        operationKind,
        typeId: BASE_PGM,
      }),
    {
      /**
       *
       * @param root0
       * @param root0.message
       */
      onError: ({ message }) => {
        toast.error(message);
      },

      /**
       *
       */
      onSuccess: () => {
        onClose();
      },
    },
  );

  return { isLoading, onSubmit };
};

/**
 * Send Post.
 *
 * @param {*} data - Data.
 * @returns {Promise<*|Response>}
 */
const sendPost = (data) =>
  post('pgm/operation', {
    car_customer: null,
    comment: null,
    consignee: null,
    id: null,
    tech_object_id: null,
    ...preparationValues(data),
  });
