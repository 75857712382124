// Находится на балансе Учреждения
 
export const CAR_OWNERSHIP_BALANCE = 1;

// Находится на забалансовом учете Учреждения
 
export const CAR_OWNERSHIP_OFF_BALANCE = 4;

// Привлеченная
 
export const CAR_OWNERSHIP_ATTRACTED = 2;
