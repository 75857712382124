import { Cell } from 'types/table';

/**
 * Колонка __Площадь объекта по пространственным координатам, кв.м.__.
 *
 * @type {{
 * accessor: 'detailed_state_gardening_name',
 * Header: 'Уточнение состояния',
 * sortable: true,
 * }}
 * @augments Cell
 */
export const detailed_state_gardening_name: Cell = {
  Header: 'Уточнение состояния',
  accessor: 'detailed_state_gardening_name',
  sortable: true,
};
