import { preparationSearchFormValues as preparationSearchFormValues_D } from './preparationSearchFormValues';

/**
 * @deprecated
 * @see заюзай другой импорт
 * @type {*}
 */
const preparationSearchFormValues = preparationSearchFormValues_D;

export * from './handleBrowserDownload';
export * from './stringMultiplication';

export { preparationSearchFormValues };
