import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const CREATE_TYPE_NAME = 'createType';

/**
 * Тип создания объекта createType.
 *
 * @param {*} props - Properties.
 * @returns {JSX.Element}
 */
export const CreateType = (props) => {
  const dict = 'createType';

  const { data: options = [] } = useGetDictionary(dict);

  return (
    <Select
      optionKey={'code'}
      options={options}
      {...props}
      label={'Тип создания объекта'}
      name={CREATE_TYPE_NAME}
    />
  );
};
