import { TextFieldRF } from 'core/form/reduxForm/fields/default/TextFieldRF';
import { ReCalcNameRF } from 'core/form/reduxForm/fields/prepared/textField/ReCalcName.RF';
import { shallow } from 'enzyme';
import React from 'react';

import NameField from './NameField';

describe('🐛 NameFieldSpec', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act
    const wrapper = shallow(<NameField editable={true} />);

    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      children: [
        <ReCalcNameRF />,
        <TextFieldRF
          name={'name_description'}
          label={'Уточнение наименования'}
          disabled={false}
        />,
      ],
    });
  });
});
