import { isThisViewing } from 'app/selectors/card/isThisViewing';
/* eslint-disable  */
import getFormValues from 'app/selectors/form/getFormValues';
import { createSelector } from 'reselect';

import getDict from '../getDict';
import getOtherImprovementObjectTypeId from './getOtherImprovementObjectTypeId';
import getParentTypeId from './getParentTypeId';
import getTypeId from './getTypeId';
import filterByOtherImprovementObjectTypeId from './utils/filterByOtherImprovementObjectTypeId';
import filterByParentTypeId from './utils/filterByParentTypeId';

/**
 *
 * @param state
 */
const getCoatingGroupId = (state) => getFormValues(state).coating_group_id;

/* eslint-disable */
export default createSelector(
  [
    getDict('coatingType'),
    getCoatingGroupId,
    getTypeId,
    getParentTypeId,
    getOtherImprovementObjectTypeId,
    isThisViewing,
  ],
  (
    dict,
    coatingGroupId,
    typeId,
    parentTypeId,
    otherImprovementObjectTypeId,
    viewing,
  ) => {
    let result;

    if (viewing) {
      return dict;
    }

    if (coatingGroupId) {
      result = dict.filter(
        (item) =>
          item.group_id === coatingGroupId &&
          item.ogh_object_type_list.length > 0,
      );
      if (parentTypeId && typeId !== parentTypeId) {
        if (otherImprovementObjectTypeId) {
          result = filterByOtherImprovementObjectTypeId({
            dict: result,
            parentTypeId,
            otherImprovementObjectTypeId,
          });
        } else {
          result = filterByParentTypeId({
            dict: result,
            parentTypeId,
          });
        }
      }
    } else {
      result = dict;
    }

    return result;

    // фильтр по ogh_object_type_id
    // return result.filter((item) => {
    //   const oghObjectType = item.ogh_object_type_list.find(
    //     (objectType) => objectType.ogh_object_type_id === typeId
    //   );

    //   return Boolean(oghObjectType);
    // });
  },
);
