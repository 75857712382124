import { IdFilter } from 'core/form/filterForm/components/text/ID.Filter/index';
import { SectionNumFilter } from 'core/form/filterForm/index';
import React from 'react';

/**
 * Фильтр __Газоны__.
 *
 * @returns {JSX.Element}
 */
export const FlowersGardenFilter = () => {
  return (
    <>
      <IdFilter />
      <SectionNumFilter />
    </>
  );
};
