import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { parseDate } from 'app/components/card/common/parse';
import { useFilterQueryParamsVersionDefault } from 'app/pages/cardsOgh/components/tables/Versions/Filters/useFilterQueryParamsVersionDefault';

/**
 * Хук установки изначального состояния полей для Инженерных сооружений.
 *
 * @returns {object} - Возвращаем объект полей по умолчанию.
 * @example useInitialValues()
 */
export const useInitialValueEngineerBuilding = () => {
  const { startDate, endDate, attribute } = useGetCurrentCard();

  const { filters: filtersVersionTable } = useFilterQueryParamsVersionDefault();
  return {
    clean_type_id: attribute?.clean_type_id?.clean_type,
    coating_face_type_id: attribute?.coating_face_type_id?.coating_type,
    coating_group_id: attribute?.coating_group_id?.coating_group,
    coating_type_id: attribute?.coating_type_id?.coating_type,
    endDate: parseDate(endDate),
    engineer_building_type_id:
      attribute?.engineer_building_type_id?.engineer_building_type,
    face_area: attribute.face_area,
    is_diff_height_mark: attribute?.is_diff_height_mark,
    material_id: attribute?.material_id?.material,
    noCalc: attribute?.no_calc,
    quantity: attribute.quantity,
    startDate: parseDate(startDate),
    totalAreaGeo: attribute?.total_area_geo,
    unit_id: attribute?.unit_id?.unit,
    // TODO: фильтры
    ...filtersVersionTable,
  };
};
