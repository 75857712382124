import { AddressList2 } from 'app/components/card/common/grid/render/renderAddressList2';
import React from 'react';
import { Field } from 'redux-form';

/**
 * Wrapper Rf Компонент ReduxForm для отображения списка адресов.
 *
 * @param {*} root0 - Входные данные.
 * @param {*} root0.input - Поле ввода.
 * @returns {JSX.Element} - Реакт компонент.
 */
const AddressList2WithReduxForm = ({ input, ...props }) => {
  return <AddressList2 {...props} {...input} input={input} />;
};

/**
 * Компонент ReduxForm для отображения списка адресов.
 *
 * @param {*} root0 - Входные данные.
 * @param {string} [root0.name] - Имя.
 * @returns {JSX.Element} - Реакт компонент.
 */
export const AddressList2RF = ({ name, ...otherProps }) => {
  return (
    <Field {...otherProps} name={name} component={AddressList2WithReduxForm} />
  );
};
