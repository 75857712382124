import moment from 'moment';

import { START_TIME } from './format';

/**
 * Текущая дата.
 *
 * @returns {Date}
 */
export const getCurrentDate = (): Date => moment().set(START_TIME).toDate();
