import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import React from 'react';

/**
 * Cell для __Статус заявки на обследование ОГХ__.
 *
 * @param {object} props - Пропсы Cell.
 * @param {string} props.value - Значение поля.
 * @returns {JSX.Element}
 */
export const BrStatusIdCell = ({ value }) => {
  const { data: dictStatus } = useGetDictionary('bid_status');

  const status = (dictStatus || []).reduce((acc, item) => {
    if (value === item.id) {
      return item.name;
    }
    return acc;
  }, '');

  return <div>{status}</div>;
};
